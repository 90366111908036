import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as BulkImportExportStore from './api/BulkImportExportStore';

export type IBulkImportExportState = IApiComponentState<any, BulkImportExportStore.IBulkImportExportUIState>;

export const actionCreators = createApiComponentActions<BulkImportExportStore.ActionCreators>(BulkImportExportStore.actionCreators);
export type IBulkImportExportActions = typeof actionCreators;
export type IBulkImportExportActionProps = IApiComponentActionProps<IBulkImportExportActions>

export const reducers = createApiComponentReducers("bulkimportexport", BulkImportExportStore.reducer);
export const reducer = combineReducers<IBulkImportExportState>(reducers);
