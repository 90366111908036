
export class SupportDownloadFilesData {
    Name: any;
    Created: string = "";
    Creator: string = "";
    Size: string = "";
    Filters: any;
    Operation: any;
    SupportDownloadFileName: string = "";
    UTC: number = 0;
    ParentFolder: any;
    Modified: string = "";
    Type: string = "";
}

export class SupportDownloadDataSelect {
    Name: string = "";
    ParentFolder:string = ""
}


export class ReportDownloadFilesData {
    Name: any;
    Created: string = "";
    Date: string = "";
    Creator: string = "";
    Size: string = "";
    Filters: any;
    Operation: any;
    ReportDownloadFileName: string = "";
    UTC: number = 0;
    FileID: any;
    id: any;
    Modified: string = "";
    Type: string = "";
    fileName: string = "";
    FileName: any;
    fileTypeName: string = "";
    DateSort: number = 0;
    date: any = {
        dateTime: "",
        dateSort: 0
    };
    clientSidePath: string = "";
    encryptedPath: string = "";
    delete: any;
    deleteChecked: any;
    downloadChecked: any;
    
}

export class ReportDownloadDataSelect {
    id: any;
    date: any = {
        dateTime: "",
        dateSort: 0
    };
    clientSidePath: any;
    encryptedPath: any;
    fileName: string = "";
    fileTypeName: string = "";
    delete: any;
    deleteChecked: any;
    downloadChecked: any;
    
}
