import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';


import { TasksPayload, GraphData } from './ProductivityBar';

export class ProductivityBarDAL {

    public async getDataForGraph(payload: TasksPayload): Promise<GraphData | undefined> {

       

        let productivityBarData: GraphData | undefined;

        try {

           const url = URLs.api + '/api/data/dashboard/productivity';
            await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(payload)
            })
                .then(async (response) => {
                    const data: any = await response.json();
                    switch (response?.status) {
                        case 200: {
                            try {
                                productivityBarData = data as GraphData;
                                console.log('%c ProductivityBarDAL: Success', 'color: green; font-weight: bold', productivityBarData);
                                return productivityBarData
                            } catch (error) {
                                debugger;
                            }

                        }
                    }

                
            })

        } catch (error) {
            console.error(`promise rejected with Error: ${error}`);
        };

       
        return productivityBarData;
       

      
    }
}