import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TableViewModelBuilder } from './TableViewModelBuilder';
import styled from 'styled-components';
//import { MessageBodyContent } from '../../Home/MessageBodyContent';
import { MessageBodyContent } from './MessageBodyContent';

const CellWrapper = styled.div`
{
    /*
    // @ts-ignore: Commented out
    width: ${props => props.width}
    */

    .pinkBackground{
        background-color:#FDDDDD;
    }

    .verticalAlignTop{
        vertical-align:top;
    }

    .url{
        text-decoration:underline;
        color:blue;
        cursor:pointer;
    }

    .fontSizeSmall{
        font-size: 12px;
    }

    .jobDetailsUrl{
        border-bottom: 1px solid;
        color: blue;
        cursor: pointer;
        font-size: 12px;
    }

    .widthSmall{
        width:150px !important;
    }

    .widthMedium{
        width:300px !important;
    }

    .widthLarge{
        width:500px !important;
    }

    .widthExtraLarge{
        width:750px !important;
    }
`;

interface IComponentState {
}

interface IDynamicTableLayoutProps {
    tableMetadata: TableMetadata
}

export const DEFAULT_STATE: IComponentState = {
};

export class DynamicTableLayout extends React.Component<IDynamicTableLayoutProps, IComponentState> {
    static propTypes: {}

    constructor(props: IDynamicTableLayoutProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    componentDidMount() {
        const {
        } = this.props;
    }

    //doNothing(event: MouseEvent<HTMLAnchorElement, MouseEvent>): void {
    doNothing(event: MouseEvent) {
        alert('nothing');
    }

    render() {
        const {
            tableMetadata
        } = this.props;

        // convert table metadata to viewmodel
        let tableViewModel = new TableViewModelBuilder().ConvertMetadataToViewModel(tableMetadata);

        return (
            <table style={{
                /*margin: '0 auto',*/
                textAlign: 'center',
                borderCollapse: 'collapse',
                fontFamily: 'Century Gothic',
                tableLayout: 'fixed'
            }}>
                {tableViewModel.map(x => {
                    return (
                        <tr>
                            {
                                x.Cells.map((x, i, cells) => {
                                    // if not fieldtable then display field in top-level table
                                    if (!x.FieldTable) {
                                        return (
                                            <>
                                                {/* field name */}
                                                <td
                                                    style={{
                                                        backgroundColor: 'white',
                                                        fontWeight: 'bold',
                                                        textAlign: 'left',
                                                        verticalAlign: 'text-top',
                                                        whiteSpace: 'nowrap',
                                                        //setting small width is hack to prevent unwanted cell width expansion
                                                        width: '50px',
                                                    }}>
                                                    {x.FieldName}{x.FieldName && <a>:</a> }
                                                </td>

                                                {/* spacer between field name and value */}
                                                <td style={{ width: 5 }}></td>

                                                {/* field value */}
                                                
                                                    <td className={x.ClassNames}
                                                        colSpan={x.ColSpan}
                                                        rowSpan={x.RowSpan}
                                                        style={{
                                                            backgroundColor: 'white',
                                                            textAlign: 'left',
                                                            paddingLeft: '5px',
                                                            margin: '3px',
                                                            verticalAlign: 'text-top',
                                                            whiteSpace: x.Nowrap ? 'nowrap' : 'normal',
                                                    }}>

                                                        <CellWrapper>

                                                            {x.ClassNames?.toLowerCase() === 'innerhtml' &&
                                                                <MessageBodyContent key={Date.now()} message={x.FieldValue || ''} />
                                                            }

                                                            {x.ClassNames?.toLowerCase() != 'innerhtml' &&
                                                                <a className={x.ClassNames}
                                                                    style={{ position: 'relative', top: 1 }}
                                                                    onClick={() => x.OnClick && x.OnClick()}>
                                                                    {x.FieldValue}
                                                                </a>
                                                            }

                                                        </CellWrapper>
                                                    </td>
                                                
                                                {/* include spacer cell to accomodate next column (unless last item) */}
                                                {(cells.length - 1 != i) &&
                                                    <td style={{ width: 20 }}></td>
                                                }
                                            </>
                                        )
                                    } else if (x.FieldTable) {
                                        return (
                                            <>
                                                {x.FieldName &&
                                                    <>
                                                        {/* write out the field name */}
                                                        <td style={{
                                                            fontWeight: 'bold',
                                                            textAlign: 'left',
                                                            verticalAlign: 'top',
                                                            whiteSpace: 'nowrap',
                                                        }}>
                                                            {x.FieldName}{x.FieldName && <a>:</a> }
                                                        </td>

                                                        {/* spacer */}
                                                        <td style={{ width: 5 }}></td>
                                                    </>
                                                }

                                                <td colSpan={10}>
                                                    <table style={{ border: '1px solid black', borderCollapse: 'collapse', position: 'relative', top: 3 }}>
                                                        {/* build out an html table for the FieldTable */}
                                                        {x.FieldTable.map(y => {
                                                            return (
                                                                <>
                                                                    {/* map out the subtable headers */}
                                                                    <thead style={{
                                                                        backgroundColor: '#9CA1A5',
                                                                    }}>
                                                                        <tr>
                                                                            {y.RowNumber === 1 && y.Cells.map(z => {
                                                                                return (
                                                                                    <th style={{
                                                                                        display: 'table-cell',
                                                                                        border: '1px solid black',
                                                                                        padding: '2px',
                                                                                        color: 'white',
                                                                                        fontWeight: 400,
                                                                                        whiteSpace: 'nowrap',
                                                                                    }}>
                                                                                        {z.FieldName}
                                                                                    </th>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    </thead>

                                                                    {/* map out the subtable rows */}
                                                                    <tbody>
                                                                        <tr>
                                                                            {y.Cells.map(z => {
                                                                                return (
                                                                                    <td
                                                                                        style={{ border: '1px solid black', paddingLeft: '2px' }}>
                                                                                        {z.FieldValue}
                                                                                    </td>
                                                                                )
                                                                            })}
                                                                        </tr>
                                                                    </tbody>
                                                                </>
                                                            )
                                                        })}
                                                    </table>
                                                </td>
                                            </>
                                        )
                                    }
                                })
                            }
                        </tr>
                    )
                })}
            </table>
        )
    }
}

DynamicTableLayout.propTypes = {
    tableMetadata: PropTypes.object
}
