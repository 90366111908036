import { Action, Reducer } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IRestrictAccessUIState {
    selectedRestrictAccess: IRestrictAccessUIData;
    reportData: any,
    filterData: any,

    selectedProfileReport: any,
    selectedFilterItem: any,

    reportRestrictionsData: any,
    thisProfile: any,
    restrictedProfile: any,
    selectedthisProfile: any,
    selectedrestrictedProfile: any
    selectedGroupName: ISelectGroupTypeData,
    selectedrestrictedProfileDisplay: any,
    selectedFilter: any,
    error: any,
    allreports: string,
    errorPopup: boolean,
    errorPopupFiscalPeriod: boolean,
}

export interface ISelectGroupTypeData {
    index: number;
    value: string;
    selected: boolean;

}

export const defaultGroupNoteType: ISelectGroupTypeData = {
    index: 0,
    value: '',
    selected: false
}

export const defaultRestrictAccess: IRestrictAccessUIData = {
    id: '',
    text: '',
};

export const defaultState: IRestrictAccessUIState = {
    selectedRestrictAccess: defaultRestrictAccess,

    reportData: [],
    allreports: '0',
    filterData: [],
    error: false,
    selectedProfileReport: {
        "@ID": "0", "@TypeId": "2", "@ProfileName": "", "Users": "", "Groups": "",
        "Attributes": { "Attribute": { "@ID": "#", "@AttrId": "2000", "@Content": "" } }
    },
    selectedFilterItem: {
        "@ID": "0", "@TypeId": "1", "@ProfileName": "", "Users": "", "Groups": "",
        "Attributes": { "Attribute": [{ "@ID": "#", "@AttrId": "2000", "@Content": "" }] }
    },
    reportRestrictionsData: [],

    thisProfile: [],
    restrictedProfile: [],
    selectedthisProfile: {},
    selectedrestrictedProfile: {},
    selectedGroupName: defaultGroupNoteType,
    selectedrestrictedProfileDisplay: {},
    selectedFilter: '3',
    errorPopup: false,
    errorPopupFiscalPeriod: false,
};

// When we select the group
export interface ISelectGroupType extends ICrudActionData<MCGroupType, ISelectGroupTypeData> { }

export interface ISelectSecurityAccess extends ICrudActionData<SecurityAccessInfoType, IRestrictAccessUIData> { }

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface IRestrictAccessUIData {
    id: string;
    text: string;
};

export const actionCreators = {
    reportData: (results: []) => createDataAction('REPORT_DATA', results),
    selectedProfileReport: (object: {}) => createDataAction('SELCTED_PROFILE_REPORT', object),
    reportRestrictionsData: (results: []) => createDataAction('REPORT_RESTRICTION_DATA', results),
    filterData: (results: []) => createDataAction('FILTER_DATA', results),
    selectedFilterItem: (object: {}) => createDataAction('SELCTED_FILTER_REPORT', object),
    thisProfile: (results: []) => createDataAction('THIS_PROFILE', results),
    restrictedProfile: (results: []) => createDataAction('RESTRICTED_PROFILE', results),
    selectedthisProfile: (object: {}) => createDataAction('SELCTED_THIS_PROFILE', object),
    selectedrestrictedProfile: (object: {}) => createDataAction('SELCTED_RESTRICTED_PROFILE', object),
    selectGroupType: (selectGroupInfo: ISelectGroupType) => createDataAction('SELECT_GROUP_TYPE', selectGroupInfo),
    selectedrestrictedProfileDisplay: (object: {}) => createDataAction('SELCTED_RESTRICTED_PROFILE_DISPLAY', object),
    selectedFilter: (string: '') => createDataAction('SELECTED_FILTER', string),
    isUpdate: (res: boolean) => createDataAction('IS_UPDATE', res),
    error: (res: boolean) => createDataAction('ERROR', res),
    allreports: (string: '') => createDataAction('ALL_REPORTS', string),
    errorPopup: (res: boolean) => createDataAction('ERROR_POPUP', res),
    errorPopupFiscalPeriod: (res: boolean) => createDataAction('ERROR_POPUP_FISCAL_PERIOD', res)
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IRestrictAccessUIState, KnownActions> = (state: IRestrictAccessUIState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'REPORT_DATA':
                return {
                    ...state,
                    reportData: action.data,
                }
                break;
            case 'SELCTED_PROFILE_REPORT':
                return {
                    ...state,
                    selectedProfileReport: action.data,
                }
                break;
            case 'REPORT_RESTRICTION_DATA':
                return {
                    ...state,
                    reportRestrictionsData: action.data,
                }
                break;
            case 'FILTER_DATA':
                return {
                    ...state,
                    filterData: action.data,
                }
                break;
            case 'SELCTED_FILTER_REPORT':
                return {
                    ...state,
                    selectedFilterItem: action.data,
                }
                break;
            case 'THIS_PROFILE':
                return {
                    ...state,
                    thisProfile: action.data,
                }
                break;
            case 'RESTRICTED_PROFILE':
                return {
                    ...state,
                    restrictedProfile: action.data,
                }
                break;

            case 'SELCTED_THIS_PROFILE':
                return {
                    ...state,
                    selectedthisProfile: action.data,
                }
                break;

            case 'SELCTED_RESTRICTED_PROFILE':
                return {
                    ...state,
                    selectedrestrictedProfile: action.data,
                }
                break;
            case 'SELECT_GROUP_TYPE':
                {
                    if (action.data.masterCrud) {
                        console.log("Node selected");
                    }
                }
                break;
            case 'SELCTED_RESTRICTED_PROFILE_DISPLAY':
                return {
                    ...state,
                    selectedrestrictedProfileDisplay: action.data,
                }
                break;
            case 'SELECTED_FILTER':
                return {
                    ...state,
                    selectedFilter: action.data,
                }
                break;
            case 'IS_UPDATE':
                return {
                    ...state,
                    changed: false
                }
                break;
            case 'ERROR':
                return {
                    ...state,
                    error: action.data,
                }
                break;
            case 'ALL_REPORTS':
                return {
                    ...state,
                    allreports: action.data,
                }
                break;
            case 'ERROR_POPUP':
                return {
                    ...state,
                    errorPopup: action.data,
                }
                break;
            case 'ERROR_POPUP_FISCAL_PERIOD':
                return {
                    ...state,
                    errorPopupFiscalPeriod: action.data,
                }
                break;

        }
    }

    return state || defaultState;
}