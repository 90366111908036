import * as browse from './xBrowserXMLLib';
import { func, object } from 'prop-types';
import { syncLogoutUser } from '@scripts/session/Security';

declare var ActiveXObject: (type: string) => any;
export function openPopupWindowByName(sURL:any, sWndName:any) {
    var emWnd;
    emWnd = window.open('', sWndName);

    if (emWnd == null || emWnd.document == null) {
        // if we get here, then the window hasn't been created yet, or it
        // was closed by the user..assurancePageTitle .navbar .container .dropdown-menu
        emWnd = window.open(sURL, sWndName);
    }
    else {
        // the window has already been created, but did the user close it?
        // if so, then reopen it. Otherwise make it the active window.
        if (!emWnd.closed) {
            emWnd.focus();
        } else {
            emWnd = window.open(sURL, sWndName);
        }
    }

    return emWnd;
}

var sAddlInfo = '';

export function getAddlInfo() {
    return sAddlInfo;
}

var posX = 0;
var posy = 0;
// called from default.asp/CFILoad.aspx to keep track of mouseX/Y position 
export function getMouseXY(e: Event) {

    var tempX = 0;
    var tempY = 0;

    tempX = document.body.scrollLeft;
    tempY =  document.body.scrollTop;

    if (tempX < 0) { tempX = 0; }
    if (tempY < 0) { tempY = 0; }
    // set the position values in the mx/my hidden fields
    posX = tempX;
    posy = tempY;
}

//A delay on purpose to prevent the CFI button staying clicked forever
export function openViewErrorWindow(sErrorAddl: any) {
    setTimeout(()=> { openViewErrorWindow2(sErrorAddl); }, 500);
}
// to launch add'l error HTML box as close to the button as possible using mouse position from CFI
export function openViewErrorWindow2(sErrorAddl:any) {
    sAddlInfo = sErrorAddl;
    var w = 400;
    var h = 250;

    var CurrentTop = 0;
    var CurrentLeft = 0;
    // current left and current top positioning w/bottom padding to position box directly over Add'l Info button
    CurrentLeft = posX + window.screenLeft;
    CurrentTop = posy + window.screenTop - 265;

    var wnd = window.open("/Shared/Scripts/ViewErrorInfo.html",
        "formresult",
        "height=" + h + ",width=" + w + ",left=" + CurrentLeft + ",top=" + CurrentTop +
        ",directories=no,location=no,menubar=no,scrollbars=no,status=no,toolbar=no,resizable=no,titlebar=no");

    // delay to render the page and focus the active window
    setTimeout(() => { wnd?.focus(); }, 500);
}

export function GetHelpPath(sHelpFile:any) {
    if (sHelpFile === '') return '';
    var url = encodeURI(myTrim(sHelpFile));
    url = url.replace(/#/g, '%23');
    return '/Support/Help/HELP_Frame.asp?' + url;
}
export function myTrim(x:any) {
    return x.replace(/^\s+|\s+$/gm, '');
}

function createCookie(name: string, value: string, days: any) {
    var expires;

    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString() + "; path=/";
    } else {
        expires = "; path=/";
    }
    document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + ";";
}

export function readCookie(name: string) {
    var nameEQ = encodeURIComponent(name) + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
}

function eraseCookie(name : string) {
    createCookie(name, "", null);
}





export function ClearSecuritySession() {
    var token = sessionStorage.getItem('SecurityInfo');
    if (token != null && token.length > 0)
        syncLogoutUser();

    eraseCookie("SecurityCredentials");
    eraseCookie("Client");

    var sc = readCookie("SecurityCredentials");
    if (sc != null)
        console.log('unable to clear cookies sc: ' + sc);

    // i think this should call LogoutUser() first
    sessionStorage.setItem('SecurityInfo', '');
    sessionStorage.setItem('PageChanged', 'false')
}

export function BigHelp(sURL:any) {
    sURL = GetHelpPath(sURL);
    return window.open(sURL, "BigHelp", "height=500,width=650,left=50,top=100,directories=no,location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,resizable=yes");
}

export function TestHelp(sURL:any) {
    sURL = GetHelpPath(sURL);
    return window.open(sURL, "TestHelp", "height=500,width=650,left=50,top=100,directories=no,location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,resizable=yes");
}

export function ShowPopup(sURL:any) {
    return window.open("/Support/Help/Popup_Frame.asp?" + sURL, "BigHelp", "height=500,width=900,left=50,top=100,directories=no,location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,resizable=yes");
}

//export function BlockTabbing(bShift: any) {
    
//    if (bShift) {
//        if (eve && $.event.keyCode == 9) {
//            return false;
//        }
//    }
//    else {
//        if (!event.shiftKey && event.keyCode == 9) {
//            return false;
//        }
//    }

//    return true;
//}

//export function DownloadDocument(sPath: any) {
//    var encrypt = false;
//    var encryptCheckbox = document.getElementById('encryptDownload');
//    if ((typeof assuranceServicesJQ !== 'undefined')
//        && encryptCheckbox && encryptCheckbox.checked) {
//        encrypt = true;
//    }

//    if (encrypt) {
//        assuranceServicesJQ().encryptDownload(sPath, EncryptDownloadSuccessCallback);
//    } else {
//        DownloadDocumentPt2(sPath);
//    }
//}

export function EncryptDownloadSuccessCallback(data:any) {
    DownloadDocumentPt2(data.resultId);
}

export function DownloadDocumentPt2(sPath: any) {
    //console.log("DownloadDocumentPt2(", sPath, ")");
    //temporary fix for split files
    if (sPath.match(/\.spl$/i)) {
        var re = /\w+\.spl/i;

        alert("Once the file download dialog box appears\nplease rename the file to " + re.exec(sPath) + "\nbefore saving it.");
    }

    //HOTFIX to address issue with client submitting a # within their files
    //viewfile.asp does the unescape of the path
    window.top.location.href = "/Transactions/Save=" + escape(sPath);
    //console.log(escape(sPath));
    //console.log(escape(window.top.location.href));
}

export function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
}

export function requestIdFactory() {
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}



export function goTo(sURL: any, sProcess: any, sStatsFeature: any, sStatsActivity: any) {

    if (sURL.toLowerCase() != window.location.pathname.toLowerCase() || sProcess == "Refresh") {

        if (typeof (sStatsFeature) != "undefined" && sStatsFeature != "" &&
            typeof (sStatsActivity) != "undefined" && sStatsActivity != "") {
            var startTime = (new Date()).getTime();
            var paramStart = sURL.indexOf("?");
            var separator = "?";
            var requestId = requestIdFactory();
            var ammendedUrl = "";

            if (paramStart != -1)
                separator = "&";

            ammendedUrl = sURL + separator + "statsId=" + requestId + "&statsFeature=" + sStatsFeature + "&statsActivity=" + sStatsActivity + "&statsStart=" + startTime;
            window.top.location.href = ammendedUrl;
        }
        else {
            window.top.location.href = sURL;
        }
    }

}


export function HelpWindow(sURL:any) {
    sURL = GetHelpPath(sURL);
    return window.open(sURL, "HelpWindow", "height=400,width=300,left=25,top=50,directories=no,location=no,menubar=no,scrollbars=yes,status=no,toolbar=no,resizable=yes");
}

export function OpenDocument(sPath:any, sTarget:any) {
    var Doc;
    var RegEx = /\.pdf/i;
    var IsPDF = false;

    if (RegEx.test(sPath)) {
        Doc = PDFWindow("", sTarget) || window;
        IsPDF = true;
    }
    else {
        Doc = TXTWindow("", sTarget) || window;
    }

    //HOTFIX to address issue with client submitting a # within their files
    //viewfile.asp does the unescape of the path
    if (IsPDF) {
        window.top.location.href = "/Shared/Pages/PDFViewer.asp?Open=" + escape(sPath);
        
    } else {
        window.top.location.href = "/Transactions/Open=" + escape(sPath);
    }

    

    // issue on View Files when window already opened
    try {
        if( Doc) Doc.focus();
    }
    catch (e) {
    }
}

export function OpenMessageDocument(sPath: any, sTarget: any) {
    var Doc;
    var RegEx = /\.pdf/i;

    if (RegEx.test(sPath)) {
        Doc = PDFWindow("", sTarget) || window;
    }
    else {
        Doc = TXTWindow("", sTarget) || window;
    }

    Doc.location.href = "/Transactions/Open=" + escape(sPath);

    try {
        if (Doc) Doc.focus();
    }
    catch (e) {
    }
}


export const DefaultWindowModalStyle: string = "height=600,width=850,left=50,top=75,directories=no,location=no,menubar=no,resizable=yes,scrollbars=no,status=no,toolbar=no";


export function MessageWindow(sURL: any, sTarget: any) {
    if (typeof (sTarget) == "undefined") {
        sTarget = "_blank";
    }
    sURL = "/Transactions/Open=" + escape(sURL);
    return window.open(sURL, sTarget, DefaultWindowModalStyle);
}

export function MessageSave(sURL: any, sTarget: any) {
    if (typeof (sTarget) == "undefined") {
        sTarget = "_blank";
    }
    sURL = "/Transactions/Save=" + escape(sURL);
    window.top.location.href = "/Transactions/Save=" + escape(sURL);
}

export function PDFWindow(sURL: any, sTarget: any) {
    if (typeof (sTarget) == "undefined") {
        sTarget = "_blank";
    }
    sURL = GetHelpPath(sURL);
    return window.open(sURL, sTarget, DefaultWindowModalStyle);
}

export function PDFSampleWindow(sURL:any, sTarget:any) {
    if (typeof (sTarget) == "undefined") {
        sTarget = "_blank";
    }
    sURL = GetHelpPath(sURL);
    return window.open(sURL, sTarget, DefaultWindowModalStyle);
}

export function SetApplicationState(bState: any) {
    // TODO: Replace this implemenation with something react-friendly.
    // Perhaps injected callback to all children?
    // Global reference to a loading component ?
    // A reducer for Layout which looks for API states that are requesting (doesn't work for direct fetch calls)?
    console.warn("SetApplicationState bypasses state mechanism");
    window.parent.document.body.setAttribute('disabled', bState.toString());

    var Forms = window.parent.document.forms;

    for (var i = 0; i < Forms.length; i++) {
        // setting just form is not enough
        var Elements = Forms[i].elements;

        for (var j = 0; j < Elements.length; j++) {
            if (bState) {
                // restore saved state
                if (Elements[j].getAttribute("ndc_disabled") != null) {
                    Elements[j].setAttribute('disabled',Elements[j].getAttribute("ndc_disabled") == "true" ? "true":"false");
                }
            }
            else {
                // save current state
                Elements[j].setAttribute("ndc_disabled", Elements[j].getAttribute("disabled") || 'true');

                Elements[j].setAttribute('disabled',' true');
            }
        }
    }

    var All = window.parent.document.images;

    for (var i = 0; i < All.length; i++) {
        if (All[i].className == "imageButton") {
            if (bState) {
                // use disabled attribute of associated button element to restore state

                var btnElement = All.namedItem("btn" + All[i].id);
            //All.namedItem("btn" + All[i].id);

                var index = 0;
                if (btnElement != null) {
                    index = btnElement.tabIndex;
                    var Btn = window.parent.document.images[index];

                    if (typeof (Btn) != "undefined") {
                        SetButtonImageState(All[i], !Btn.getAttribute('disabled'));

                        continue;
                    }
                }
                
            }

            SetButtonImageState(All[i], bState);
        }
    }

    document.body.style.cursor = "default";

    if (bState)
        window.status = "";
}

export function ShowBusyBrowser(bDialog:any) {
    var All = window.parent.document.images;

    for (var i = 0; i < All.length; i++) {
        if (All[i].className == "imageButton") {
            SetButtonImageState(All[i], false);
        }
    }

    window.status = "Please wait while the server processes your request...";
    document.body.style.cursor = "wait";

    try {
        ResetSecurityTimer();
    }
    catch (e) {
    }
}

function SetSecurityTimer() {
    var TimerCount = 0;
    var TimerID = setTimeout("window.checkSession();", 60000);
    //SetCookie("TimeOut", "false");
    //TimerID = setTimeout("window.checkSession();", <%= SafeTimeoutDuration() %> - parseInt(60000) );
    
    TimerCount++;
}

export function ResetSecurityTimer() {
    var TimerCount = 0;
    var TimerID = setTimeout("window.checkSession();", 60000);
    clearTimeout(TimerID);
    SetSecurityTimer();
}

export function SubmitForm(sName:any) {
    //ShowBusyBrowser();

    setTimeout("document." + sName + ".submit()", 500);
}

export function TXTWindow(sURL:any, sTarget:any) {
    if (typeof (sTarget) == "undefined") {
        sTarget = "_blank";
    }

    return window.open(sURL, sTarget, "height=600,width=850,left=50,top=100,directories=no,location=no,menubar=yes,resizable=yes,scrollbars=yes,status=no,toolbar=no") || window;
}

export function SetButtonImageState(objImg:any, bState:any) {
    if (bState) {
        objImg.src = objImg.src.replace(/(\w+)(Off)\.(\w{3})$/i, "$1Up.$3");
        objImg.style.cursor = "pointer";
    }
    else {
        objImg.src = objImg.src.replace(/(\w+)(Up|Down|Over|Focus)\.(\w{3})$/i, "$1Off.$3");
        objImg.style.cursor = "default";
    }
}

export function fireEventEx(element:any, eventName:any) {
    if (element == null || eventName == null)
        return;

    if (element.fireEvent) {
        element.fireEvent(eventName);
        return;
    }

    var event = document.createEvent("HTMLEvents");
    event.initEvent(eventName, true, false);

    element.dispatchEvent(event);
}

export function backButtonRefresh() {
    window.history.back();
    window.opener.location.reload();
}