/*
<%
    '------------------------------------------------------------------------------
    ' NDC eHospital Security Function Symbolic Names
    '------------------------------------------------------------------------------
*/
export enum SecurityBits {
    FN_VIEW_CLAIMS = 0, // View Claims
    FN_EDIT_CLAIMS = 1, // Edit Claims
    FN_CREATE_CLAIMS = 2, // Create Claims
    FN_DELETE_CLAIMS_UNRELEASED = 3, // Delete UnReleased Claims
    FN_UNDELETE_CLAIMS = 4, // Undelete Claims
    FN_SUBMIT_CLAIMS = 5, // Submit Claims
    FN_VIEW_USERS = 6, // View Users
    FN_CREATE_USERS = 7, // Create Users
    FN_EDIT_USERS = 8, // Edit Users
    FN_DELETE_USERS = 9, // Delete Users
    FN_VIEW_GROUPS = 10, // View Groups
    FN_CREATE_GROUPS = 11, // Create Groups
    FN_EDIT_GROUPS = 12, // Edit Groups
    FN_DELETE_GROUPS = 13, // Delete Groups
    FN_VIEW_PHYSICIANS = 14, // View Physicians
    FN_CREATE_PHYSICIANS = 15, // Add Physician
    FN_EDIT_PHYSICIANS = 16, // Edit Physician
    FN_DELETE_PHYSICIANS = 17, // Delete Physician
    FN_VIEW_PAYERS = 18, // View Payers
    FN_ADD_PAYERS = 19, // Add Payer
    FN_EDIT_PAYERS = 20, // Edit Payer
    FN_DELETE_PAYERS = 21, // Delete Payer
    FN_VIEW_SPELLINGS = 22, // View Payer Alias
    FN_CREATE_SPELLINGS = 23, // Add Payer Alias
    FN_EDIT_SPELLINGS = 24, // Edit Payer Alias
    FN_DELETE_SPELLINGS = 25, // Delete Payer Alias
    FN_CREATE_FILTERS = 26, // Create Filter
    FN_DELETE_FILTERS = 27, // Delete Filter
    FN_VIEW_BRIDGEMASTER = 28, // View Bridge Master
    FN_EDIT_BRIDGEMASTER = 29, // Edit Bridge Master
    FN_DELETE_BRIDGEMASTER = 30, // Delete Bridge Master
    FN_VIEW_EDITSEVERITIES = 31, // View Edit Severity
    FN_EDIT_EDITSEVERITIES = 32, // Modify Edit Severity
    FN_VIEW_CLAIMCOMPLIANCESETTINGS = 33, // View Claim Compliance Settings
    FN_EDIT_CLAIMCOMPLIANCESETTINGS = 34, // Edit Claim Compliance Settings
    FN_DELETE_CLAIMCOMPLIANCESETTINGS = 35, // Delete Claim Compliance Settings
    FN_VIEW_PAPERCLAIMSSETTINGS = 36, // View Paper Claims Printing Settings
    FN_EDIT_PAPERCLAIMSSETTINGS = 37, // Edit Paper Claims Printing Settings
    FN_VIEW_AUTOSUBMIT = 38, // View Auto-submit Settings
    FN_EDIT_AUTOSUBMIT = 39, // Edit Auto-submit Settings
    FN_DELETE_AUTOSUBMIT = 40, // Delete Auto-submit Settings
    FN_VIEW_AUTOENTRIES = 41, // View Auto-entry Settings
    FN_EDIT_AUTOENTRIES = 42, // Edit Auto-entry Settings
    FN_DELETE_AUTOENTRIES = 43, // Delete Auto-entry Settings
    FN_RELEASE_CLAIMS = 44, // Release Claims
    FN_PRINT_CLAIMS = 45, // Print Claims
    FN_VALIDATE_CLAIMS = 46, // Validate Claims
    FN_REBILL_CLAIMS = 47, // Rebill Claims
    FN_VIEW_REMITS = 48, // View Payer Activity
    FN_PRINT_EOB = 49, // Print EOB
    FN_ACCEPT_REMITS = 50, // Process Payments
    FN_VIEW_REPORTS = 51, // View Reports
    FN_CREATE_REPORTS = 52, // Create Reports
    FN_DUPLICATE_CLAIMS = 53, // Duplicate Claims
    FN_DELETE_REPORTS = 54, // Delete Reports
    FN_BILL_SECONDARY = 55, // Bill Secondary
    FN_VIEW_CLAIM_CHANGES = 56, // View Claim Changes
    FN_EDIT_CLAIM_CHANGES = 57, // Edit Claim Changes
    FN_DELETE_CLAIM_CHANGES = 58, // Delete Claim Changes
    FN_SECONDARY_BILLING_SETUP = 59, // Set up Secondary Billing
    FN_VIEW_AUTOMODULE = 60, // View Auto Modules
    FN_EDIT_AUTOMODULE = 61, // Edit Auto Modules
    FN_DELETE_AUTOMODULE = 62, // Delete Auto Modules
    FN_VIEW_AUTOLIST = 63, // View Auto Lists
    FN_EDIT_AUTOLIST = 64, // Edit Auto Lists
    FN_DELETE_AUTOLIST = 65, // Delete Auto Lists
    FN_VIEW_DOWNLOAD_FILES = 66, // View Download Files
    FN_VIEW_RELEASE_SETUP = 67, // View Release Setup
    FN_EDIT_RELEASE_SETUP = 68, // Modify Release Setup
    FN_DELETE_DOWNLOAD_FILES = 69, // Delete Download Files
    FN_VIEW_FACILITY = 70, // Reserved
    FN_ADD_FACILITY = 71, // Reserved
    FN_EDIT_FACILITY = 72, // Reserved
    FN_DELETE_FACILITY = 73, // Reserved
    FN_VIEW_FILES = 74, // View Files
    FN_VIEW_PAPER_CLAIMS = 75, // View Paper Claims
    FN_DELETE_PAPER_CLAIMS = 76, // Delete Paper Claims
    FN_ALIGN_FORM = 77, // Align Form
    FN_SPINOFF_CLAIM = 78, // Spinoff Claim
    FN_CREATE_FORMTYPESUSED = 79, // Create Form Types Used
    FN_DELETE_FORMTYPESUSED = 80, // Delete Form Types Used
    FN_EDIT_FORMTYPESUSED = 81, // Edit Form Types Used
    FN_VIEW_FORMTYPESUSED = 82, // View Form Types Used
    FN_SUBMIT_ATTACHMENTS = 83, // Submit Attachments
    FN_VIEW_ATTACHMENTS = 84, // View Attachments
    FN_VIEW_AUTOPRINT = 85, // View Auto Print
    FN_EDIT_AUTOPRINT = 86, // Edit Auto Print
    FN_VIEW_PRINTERS = 87, // View Printers
    FN_CREATE_PRINTERS = 88, // Create Printers
    FN_DELETE_PRINTERS = 89, // Delete Printers
    FN_VIEW_FILEDOWNLOAD = 90, // View File Download
    FN_EDIT_FILEDOWNLOAD = 91, // Edit File Download
    FN_VIEW_REPORTACTION = 92, // View Report Action
    FN_EDIT_REPORTACTION = 93, // Edit Report Action
    FN_VIEW_JOBQUEUE = 94, // View Job Queue
    FN_EDIT_JOBQUEUE = 95, // Edit Job Queue
    FN_REMOVE_REMIT = 96, // Remove Remits
    FN_IMPORT_PMFM = 97, // Bulk Transfer Import
    FN_EXPORT_PMFM = 98, // Bulk Transfer Export
    FN_BRIDGE_ROUTINES = 99, // Bridge Routine Maintenance
    FN_CREATE_CIF = 100, // California Claims Inquiry Form
    FN_CIF_SETUP = 101, // California CIF Setup
    FN_VIEW_ACCOUNT_POLICY = 102, // View Account Policy
    FN_EDIT_ACCOUNT_POLICY = 103, // Edit Account Policy
    FN_VIEW_RESTRICT_USER_ACCESS = 104, // View Restrict User Access
    FN_EDIT_RESTRICT_USER_ACCESS = 105, // Edit Restrict User Access
    FN_VIEW_DDE_ACCOUNTS = 106, // Direct Entry: View Accts
    FN_EDIT_DDE_ACCOUNTS = 107, // Direct Entry: Edit Accts
    FN_CREATE_DDE_ACCOUNTS = 108, // Direct Entry: Create Accts
    FN_DELETE_DDE_ACCOUNTS = 109, // Direct Entry: Delete Accts
    FN_REQUEST_DDE_ELIGIBILITY = 110, // Direct Entry: Check Eligibility
    FN_REQUEST_DDE_RELEASE = 111, // Direct Entry: Send Claims
    FN_REQUEST_DDE_VALIDATION = 112, // Direct Entry: Check Edits
    FN_REQUEST_DDE_INQUIRY = 113, // Direct Entry: Check Status
    FN_CHANGE_DIRECT_SUBMIT_STATE = 114, // Direct Entry: Change Release Type
    FN_ACCEPT_WITH_ERRORS = 115, // Accept With Errors
    FN_OVERRIDE_COMPLIANCE_EDITS = 116, // Override Compliance Edits
    FN_DENIAL_MGT_SETUP = 117, // Set up Denial Management
    FN_DENIAL_MGT_VIEW = 118, // View Denial Management
    FN_VIEW_HOLDCODE_MAINTENANCE = 119, // View Hold Code Maintenance
    FN_EDIT_HOLDCODE_MAINTENANCE = 120, // Edit Hold Code Maintenance
    FN_DELETE_HOLDCODE_MAINTENANCE = 121, // Delete Hold Code Maintenance
    FN_VIEW_CSVOPTIONS_MAINTENANCE = 122, // View CSV Options Maintenance
    FN_EDIT_CSVOPTIONS_MAINTENANCE = 123, // Edit CSV Options Maintenance
    FN_VIEW_ADRCOVERLETTER_MAINTENANCE = 124, // View ADR Cover Letter Maintenance
    FN_EDIT_ADRCOVERLETTER_MAINTENANCE = 125, // Edit ADR Cover Letter Maintenance
    FN_VIEW_PHYSMNLETTER_MAINTENANCE = 126, // View Physician Letter Maintenance
    FN_EDIT_PHYSMNLETTER_MAINTENANCE = 127, // Edit Physician Letter Maintenance
    FN_VIEW_CLAIMNOTE_MAINTENANCE = 128, // Claim Notes: View/Create
    FN_EDIT_CLAIMNOTE_MAINTENANCE = 129, // Edit Claim Note Types
    FN_DELETE_CLAIMNOTE_MAINTENANCE = 130, // Delete Claim Note Types
    FN_VIEW_DASHBOARD_MAINTENANCE = 131, // View Dashboard Maintenance
    FN_EDIT_DASHBOARD_MAINTENANCE = 132, // Edit Dashboard Maintenance
    FN_VIEW_DASHBOARD = 133, // View Dashboard
    FN_VIEW_PRODUCTIVITY = 134, // View Productivity
    FN_VIEW_REMITOPTIONS_MAINTENANCE = 135, // View Remit Options
    FN_EDIT_REMITOPTIONS_MAINTENANCE = 136, // Edit Remit Options
    FN_DELETE_REMITOPTIONS_MAINTENANCE = 137, // Delete Remit Options
    FN_PRINT_277 = 138, // Print 277
    FN_PRINT_ADR = 139, // Print ADR Letter
    FN_PRINT_MN = 140, // Print MN Letter
    NDC_TENET_FILETYPES = 141, // Internal:Manage DES File Types
    FN_VIEW_FIELDLEVELSECURITY = 142, // View Field Level Security
    FN_VIEW_ALLOWEDADDRESSES = 143, // View Allowed IP Addresses
    FN_EDIT_ALLOWEDADDRESSES = 144, // Edit Allowed IP Addresses
    FN_EDIT_FIELDLEVELSECURITY = 145, // Edit Field Level Security
    FN_VIEW_NCPDP = 146, // View NCPDP Claim Data
    FN_CANCEL_ELIGIBILITY_REQUEST = 147, // Eligibility: Cancel Request
    FN_MANUAL_ELIGIBILITY_REQUEST = 148, // Eligibility: Manual Request
    FN_PRINT_ELIGIBILITY = 149, // Eligibility: Print Eligibility
    FN_EDIT_FAVORITE_FILTERS = 150, // Workflow: Edit Favorite Filters
    FN_ASSIGN_CLAIM = 151, // Workflow: Assign Claim
    FN_VIEW_CLAIMEVENTNOTIFICATION = 152, // View Claim Event Notification
    FN_EDIT_CLAIMEVENTNOTIFICATION = 153, // Edit Claim Event Notification
    FN_SUPER_ASSIGNER = 154, // Workflow: Super Assigner
    FN_ASSIGN_FAVORITE_FILTER = 155, // Workflow: Assign Favorite Filter
    FN_ASSIGN_ERROR = 156, // Workflow: Manage Assignments
    FN_VIEW_CLAIM_METRICS = 157, // View Claim Metrics
    FN_COMBINE_CLAIMS = 158, // Combine Claims
    FN_VIEW_PAYERELIGIBILITY = 159, // Eligibility: View Profile
    FN_EDIT_PAYERELIGIBILITY = 160, // Eligibility: Edit Profile
    FN_SUBMIT_PAYERELIGIBILITY = 161, // Eligibility: Submit Request
    FN_OVERRIDE_ELIGIBILITY_ERRORS = 162, // Eligibility: Override Errors
    FN_RECON_VIEW_PAYER_PROFILE = 163, // Recon: View Payer Profile
    FN_RECON_EDIT_PAYER_PROFILE = 164, // Recon: Edit Payer Profile
    FN_RECON_VIEW_MANAGER = 165, // Recon: View Manager
    FN_BI_LINK = 166, // Enterprise Reporting Link
    FN_PRINT_CLAIMS_SINGLE = 167, // Print Single Claim
    FN_DELETE_CLAIMS_SINGLE_UNRELEASED = 168, // Delete Single UnReleased Claim
    FN_REBILL_CLAIMS_SINGLE = 169, // Rebill Single Claim
    FN_RECON_VIEW_BATCHLEVEL = 170, // Recon: View Batch Level
    FN_RECON_VIEW_CLAIMLEVEL = 171, // Recon: View Claim Level
    FN_ALLOW_PREBILL = 172, // Allow Pre-Bill Editing
    FN_DELETE_CLAIMS_SINGLE_RELEASED = 173, // Delete Single Released Claim
    FN_EDIT_CUSTOMEDITSCONFIGURATION = 174, // Edit Custom Edits Configuration
    FN_VIEW_CUSTOMEDITSCONFIGURATION = 175, // View Custom Edits Configuration
    FN_EDIT_PAPER_COMMAND = 176, // Configure Paper Commands
    FN_STATE_REPORTING = 177, // State Reporting
    FN_DELETE_CLAIMS_RELEASED = 178, // Delete Released Claims
    FN_VIEW_PREBILL = 179, // View Pre-Bill Filter
    FN_EDIT_PREBILL = 180, // Edit Pre-Bill Filter
    FN_PRINT_WATERMARKOVERRIDE = 181, // Allow Print Watermark Override
    FN_CREATE_DDE_CORRECTION = 182, // Direct Entry: Create Correction Claim
    FN_PARALLON_MANAGE_CROSSOVERS = 183, // Manage Crossover Payers
    FN_RUN_CLAIMCHANGE_FILE = 184, // Process Claim Update File
    FN_SUBMIT_PARALLON_FACILITIES = 185, // Submit Parallon Facilities
    FN_SPLITCLAIM_BY_SERVICELINES = 186, // Split Claim By ServiceLines
    FN_VIEW_ERROR_ADDITIONAL = 187, // View Additional Error Information
    FN_EDIT_ERROR_ADDITIONAL = 188, // Edit Additional Error Information
    FN_SUBMIT_REASONCODES = 189, // Submit Reason Codes
    FN_VIEW_SCHEDULED_EVENTS = 190, // View Scheduled Events
    FN_VIEW_CLAIM_RETRIEVE = 191, // Retrieve Claims
    RPT_CREATE_SYSTEM_REPORTS = 192, // Create System Reports
    RPT_CREATE_CLAIM_REPORTS = 193, // Create Claim Reports
    RPT_CREATE_REMIT_REPORTS = 194, // Create Remit Reports
    RPT_CREATE_SUBMISSION_REPORTS = 195, // Create Submission Reports
    RPT_CREATE_UTILITY_REPORTS = 196, // Create Utility Reports
    RPT_VIEW_SYSTEM_REPORTS = 197, // View System Reports
    RPT_VIEW_CLAIM_REPORTS = 198, // View Claim Reports
    RPT_VIEW_REMIT_REPORTS = 199, // View Remit Reports
    RPT_VIEW_SUBMISSION_REPORTS = 200, // View Submission Reports
    RPT_VIEW_UTILITY_REPORTS = 201, // View Utility Reports
    RPT_DELETE_SYSTEM_REPORTS = 202, // Delete System Reports
    RPT_DELETE_CLAIM_REPORTS = 203, // Delete Claim Reports
    RPT_DELETE_REMIT_REPORTS = 204, // Delete Remit Reports
    RPT_DELETE_SUBMISSION_REPORTS = 205, // Delete Submission Reports
    RPT_DELETE_UTILITY_REPORTS = 206, // Delete Utility Reports
    RPT_CREATE_MANAGEMENT_REPORTS = 230, // Create Management Reports
    RPT_DELETE_MANAGEMENT_REPORTS = 231, // Delete Management Reports
    RPT_VIEW_MANAGEMENT_REPORTS = 232, // View Management Reports
    RPT_CREATE_MISCELLANEOUS_REPORTS = 233, // Create Miscellaneous Reports
    RPT_DELETE_MISCELLANEOUS_REPORTS = 234, // Delete Miscellaneous Reports
    RPT_VIEW_MISCELLANEOUS_REPORTS = 235, // View Miscellaneous Reports
    RPT_CREATE_CUSTOM_REPORTS = 236, // Create Custom Reports
    RPT_DELETE_CUSTOM_REPORTS = 237, // Delete Custom Reports
    RPT_VIEW_CUSTOM_REPORTS = 238, // View Custom Reports
    ADM_RESET_PASSWORDS = 320, // Reset Passwords
    ADM_GRANT_CREATE_CLAIMS = 321, // Grant Create Claims
    ADM_GRANT_DELETE_CLAIMS = 322, // Grant Delete Claims
    ADM_GRANT_SUBMIT_CLAIMS = 323, // Grant Submit Claims
    ADM_GRANT_CREATE_USERS = 324, // Grant Create Users
    ADM_GRANT_DELETE_USERS = 325, // Grant Delete Users
    ADM_GRANT_CREATE_GROUPS = 326, // Grant Create Groups
    ADM_GRANT_DELETE_GROUPS = 327, // Grant Delete Groups
    ADM_GRANT_ADD_ADMIN_PRIVS = 328, // Grant Add Admin Privileges
    ADM_GRANT_REVOKE_ADMIN_PRIVS = 329, // Grant Revoke Admin Privileges
    ADM_GRANT_ADD_PHYS = 330, // Grant Add Physician
    ADM_GRANT_DELETE_PHYS = 331, // Grant Delete Physician
    ADM_GRANT_ADD_PAYER = 332, // Grant Add Payer
    ADM_GRANT_DELETE_PAYER = 333, // Grant Delete Payer
    ADM_GRANT_ADD_PAYER_ALIAS = 334, // Grant Add Payer Alias
    ADM_GRANT_DELETE_PAYER_ALIAS = 335, // Grant Delete Payer Alias
    ADM_GRANT_CREATE_FILTERS = 336, // Grant Create Filters
    ADM_GRANT_DELETE_FILTERS = 337, // Grant Delete Filters
    ADM_GRANT_CREATE_BRIDGEMASTER = 338, // Grant Create Bridge Master Entry
    ADM_GRANT_DELETE_BRIDGEMASTER = 339, // Grant Delete Bridge Master Entry
    ADM_GRANT_RELEASE_CLAIMS = 340, // Grant Release Claims
    ADM_GRANT_PRINT_CLAIMS = 341, // Grant Print Claims
    ADM_GRANT_VALIDATE_CLAIMS = 342, // Grant Validate Claims
    ADM_GRANT_REBILL_CLAIMS = 343, // Grant Rebill Claims
    ADM_GRANT_VIEW_REMITS = 344, // Grant View Remits
    ADM_GRANT_PRINT_EOB = 345, // Grant Print EOB
    ADM_GRANT_ACCEPT_REMITS = 346, // Grant Process Payments
    ADM_GRANT_VIEW_REPORTS = 347, // Grant View Reports
    ADM_GRANT_CREATE_REPORTS = 348, // Grant Create Reports
    ADM_GRANT_DUPLICATE_CLAIMS = 349, // Grant Duplicate Claims
    ADM_GRANT_DELETE_REPORTS = 350, // Grant Delete Reports
    ADM_GRANT_BILL_SECONDARY = 351, // Grant Bill Secondary
    ADM_GRANT_VIEW_CLAIM_CHANGES = 352, // Grant View Claim Changes
    ADM_GRANT_EDIT_CLAIM_CHANGES = 353, // Grant Edit Claim Changes
    ADM_GRANT_DELETE_CLAIM_CHANGES = 354, // Grant Delete Claim Changes
    ADM_GRANT_SECONDARY_BILLING_SETUP = 355, // Grant Set up Secondary Billing
    FN_RECON_EDIT_MANAGER = 356, // Recon: Edit Manager
    FN_REMIT_SUBMIT = 452, // Remit: Submit View
    FN_REMIT_CREATE_POSTING_FILE = 453, // Remit: Create Posting File<
    ADM_GRANT_AUDIT72_CLIENTSETS = 454, // Enable 72-Hour Client Sets
    ADM_GRANT_VIEW_UDE = 511, // Grant User Defined Edits
    ADMRPT_GRANT_CREATE_SYS = 512, // Grant Create System Reports
    ADMRPT_GRANT_DELETE_SYS = 513, // Grant Delete System Reports
    ADMRPT_GRANT_CREATE_CLAIM = 514, // Grant Create Claim Reports
    ADMRPT_GRANT_DELETE_CLAIM = 515, // Grant Delete Claim Reports
    ADMRPT_GRANT_CREATE_REMIT = 516, // Grant Create Remit Reports
    ADMRPT_GRANT_DELETE_REMIT = 517, // Grant Delete Remit Reports
    ADMRPT_GRANT_CREATE_SUB = 518, // Grant Create Submission Reports
    ADMRPT_GRANT_DELETE_SUB = 519, // Grant Delete Submission Reports
    ADMRPT_GRANT_CREATE_UTIL = 520, // Grant Create Utility Reports
    ADMRPT_GRANT_DELETE_UTIL = 521, // Grant Delete Utility Reports
    FN_MANUALLY_MATCH_REMIT = 538, // Manually Match Remits
    FN_CONNECTCENTER_LINK = 618, // ConnectCenter Link
    FN_MANUALLY_UNMATCH_REMIT = 619, // Manually UnMatch Remits
    FN_VIEW_USER_PROFILE = 620, // View User Profile
    FN_EDIT_USER_PROFILE = 621, // Edit User Profile
    FN_VIEW_WORK_GROUP_CONFIG = 622, // View Work Group
    FN_EDIT_WORK_GROUP_CONFIG = 623, // Edit Work Group
    FN_VIEW_WORK_GROUP_DASHBOARD = 624, // View Work Group Dashboard
    FN_EDIT_WORK_GROUP_DASHBOARD = 625, // Edit Work Group Dashboard
    FN_VIEW_REMITQUEUE = 627, // Remit Queue: View
    FN_ACCUPOST_LINK = 628, // AccuPost Link
    FN_AWS_DIGITAL_BILLER = 652, // AWS: Digital Biller
    FN_VIEW_X12_835 = 638, // View X12: 835
    FN_BATCH_CLAIMNOTE = 639, // Claims Notes: Batch rights
    FN_VIEW_ELEC_ATTACHMENTS = 640, // Attach Assist - View
    FN_P2D_REQUEST_SINGLE = 641, // Propensity to Deny:Single Request
    FN_EDIT_ELEC_ATTACHMENTS = 649, // Attach Assist - Edit
    EAAS_VIEW_837_MAPPINGS = 650, // EAAS View 837 Mappings
    FN_VIEW_EDIT_SUITE = 734, // EAAS: View Provider Knowledgepack Configuration
    FN_EDIT_HIS_WORKFLOW_CODES = 737, // Edit HIS Workflow Codes
    FN_RRT_PROFILE_VIEW = 739, // RRT: Profile View
    FN_RRT_PROFILE_EDIT = 740, // RRT: Profile Edit
    FN_SUPPLEMENTALEDITS_VIEW = 741, // View Supplemental Edits
    FN_SUPPLEMENTALEDITS_EDIT = 742, // Edit Supplemental Edits
    FN_REQUEST_SINGLE_CLAIM_STATUS = 743, // Request Single Claim Status
    FN_REQUEST_BATCH_CLAIM_STATUS = 744, // Request Batch Claim Status
    FN_HOLD_CODE_PROCESSING = 745, // Hold Code Processing
    FN_VIEW_MOBILE_RECON_MANAGER = 746, // Mobile: View Recon Manager
    FN_MODIFY_APPEALS = 747, // Modify Appeals
    FN_VIEW_APPEALS = 748, // View Appeals
    FN_VIEW_MOBILE_RECON_TRACKER = 749, // Mobile Recon: View Tracker
    FN_EDIT_MOBILE_RECON_TRACKER = 750, // Mobile Recon: Edit Tracker
    FN_VIEW_ADJ_CLAIM_CONFIG = 751, // Adjustment Claim Configuration: View
    FN_MODIFY_ADJ_CLAIM_CONFIG = 752, // Adjustment Claim Configuration: Modify
    FN_VIEW_MYPRODUCTIVITY = 753, // View My Productivity
    FN_AP_WORKFLOW_PROCESS = 754, // Payment Comparison Process
    FN_AP_WORKFLOW_VIEW = 755, // Payment Clarity Workflow:View
    FN_AP_WORKFLOW_EDIT = 756, // Payment Clarity Workflow:Edit
    FN_RESEQUENCE_PAYERS = 757, // Re-sequence Payers
    FN_ENTMGMT_MASS_UPDATE = 758, // Enterprise Management: Mass Update
    FN_ENTMGMT_VIEW = 759, // Enterprise Management: View Enterprise Manager
    FN_MASS_APPEALS = 760, // Appeals: Mass
    FN_PERFORMANCE_SCORECARD = 761, // Performance Scorecard
    FN_RETRIEVE835 = 767, // Retrieve 835
    NDC_VIEW_CURRENT_ACTIVITY = 768, // Internal:View Active Sessions
    NDC_CREATE_SUBMITTER = 769, // Internal:Create Client
    NDC_SET_PAYER_STATUS = 770, // Internal:Set Payer Status
    NDC_SET_PHYSICIAN_STATUS = 771, // Internal:Set Physician Status
    NDC_VIEW_FIELD_MAP = 775, // Internal:View Field Mappings
    NDC_COMPOSE_MESSAGE = 776, // Internal:Send Messages
    NDC_VIEW_EVENT_LOGS = 777, // Internal:View Event Logs
    NDC_EDIT_FILE_TYPES = 779, // Internal:Edit File Types
    NDC_VIEW_MESSAGES = 781, // Internal:Read Messages
    NDC_IMPORT_CLAIMS = 782, // Internal:Import Claims
    NDC_EXPORT_CLAIMS = 783, // Internal:Export Claims
    NDC_DOWNLOAD_FILES = 784, // Internal:Support Download Files
    NDC_EAAS_EDIT_837_MAPPINGS = 785, // Internal:EAAS Edit 837 Mappings
    NDC_QUEUE_VIEW = 787, // Internal:QueueView
    FN_RRT_VALIDATE_CLAIMS = 789, // RRT: Validate Claims
    NDC_EAAS_TEST_837_MAPPINGS = 791, // Internal:EAAS Test 837 Mappings
    NDC_EDIT_USERS = 792, // Internal:Edit Internal Users
    NDC_DELETE_USERS = 793, // Internal:Delete Internal Users
    NDC_VIEW_REPORTS = 794, // Internal:View Support Reports
    NDC_CREATE_REPORTS = 795, // Internal:Create Support Reports
    NDC_DELETE_REPORTS = 796, // Internal:Delete Support Reports
    NDC_IMPORT_SYSTEM_DATA = 800, // Internal:Import/Export System Data
    NDC_CONFIGURE_SUBMITTER = 801, // Internal:Configure Client
    NDC_VIEW_BRIDGE_LOGS = 802, // Internal:View Bridge Logs
    NDC_BYPASS_FACPHYS = 803, // Internal:Bypass Fac/Phys Lookup
    NDC_CREATE_SPINOFFFIELDMAP = 805, // Internal:Create Spinoff Field Map
    NDC_DELETE_SPINOFFFIELDMAP = 806, // Internal:Delete Spinoff Field Map
    NDC_EDIT_SPINOFFFIELDMAP = 807, // Internal:Edit Spinoff Field Map
    NDC_VIEW_SPINOFFFIELDMAP = 808, // Internal:View Spinoff Field Map
    NDC_CREATE_SPINOFFFORMMAP = 809, // Internal:Create Spinoff Form Map
    NDC_DELETE_SPINOFFFORMMAP = 810, // Internal:Delete Spinoff Form Map
    NDC_EDIT_SPINOFFFORMMAP = 811, // Internal:Edit Spinoff Form Map
    NDC_VIEW_SPINOFFFORMMAP = 812, // Internal:View Spinoff Form Map
    NDC_CREATE_SPLITCLAIMFIELDMAP = 813, // Internal:Create Split Claim Field Map
    NDC_DELETE_SPLITCLAIMFIELDMAP = 814, // Internal:Delete Split Claim Field Map
    NDC_EDIT_SPLITCLAIMFIELDMAP = 815, // Internal:Edit Split Claim Field Map
    NDC_VIEW_SPLITCLAIMFIELDMAP = 816, // Internal:View Split Claim Field Map
    NDC_EDIT_EDIT_SUITE = 817, // Internal:EAAS Provider Knowledgepack Configuration
    NDC_VIEW_REGISTRATIONINFO = 820, // Internal:View Registration Info
    NDC_DELETE_REGISTRATIONINFO = 821, // Internal:Reset Registration Info
    NDC_BRIDGE_KEY_FILES = 822, // Internal:Bridge Key Files
    NDC_FILE_REGISTRATION = 823, // Internal:File Registration
    NDC_UPLOAD_FILE = 825, // Internal:Upload File
    NDC_VIEW_JOBTYPES = 827, // Internal:View Job Types
    NDC_EDIT_JOBTYPES = 828, // Internal:Edit Job Types
    NDC_VIEW_APPSERVERS = 829, // Internal:View App Servers
    NDC_CREATE_APPSERVERS = 830, // Internal:Create App Servers
    NDC_EDIT_APPSERVERS = 831, // Internal:Edit App Servers
    NDC_DELETE_APPSERVERS = 832, // Internal:Delete App Servers
    NDC_ADMINISTRATION_MAINTENANCE = 833, // Internal:Administration Maintenance
    NDC_CHANGE_CLAIM_STATE = 836, // Internal:Change Claim State
    NDC_TENET_NOTES = 837, // Internal:Tenet Notes
    NDC_TENET_FILEMAINTENANCE = 838, // Internal:Tenet File Maintenance
    NDC_DOWNLOAD_BRIDGEARCHIVE = 840, // Internal:Download Bridge Archive
    NDC_PRODUCTUPDATES = 841, // Internal:Product Updates
    NDC_CALCULATE_REMITS = 842, // Internal:Recalculate Remits
    NDC_VIEW_DDE_FI_SETUP = 843, // Internal:Direct Entry: View FI Setup
    NDC_EDIT_DDE_FI_SETUP = 844, // Internal:Direct Entry: Edit FI Setup
    NDC_CREATE_DDE_FI_SETUP = 845, // Internal:Direct Entry: Create FI Setup
    NDC_DELETE_DDE_FI_SETUP = 846, // Internal:Direct Entry: Delete FI Setup
    NDC_EDIT_DDE_SILO = 849, // Internal:Direct Entry: Edit Silo
    EBO_PRINT_OUTSOURCE = 850, // Outsource: Claim + EOB
    EBO_CREATE_EBOEXTRACT = 851, // Outsource: Create Extract File
    EBO_RPT_VIEW_OUTSOURCE_REPORTS = 852, // Outsource: View Reports
    EBO_RPT_CREATE_OUTSOURCE_REPORTS = 853, // Outsource: Create Reports
    EBO_CHANGE_OUTSOURCE_CLAIM = 854, // Outsource: Change Claim State
    NDC_VIEW_STUCK_CLAIMS = 855, // Internal:View Stuck Claims
    NDC_EDIT_STUCK_CLAIMS = 856, // Internal:Edit Stuck Claims
    NDC_VIEWDASHBOARDBI = 861, // Internal:BI Dashboard Grant View
    NDC_RESETDASHBOARD = 862, // Internal:Dashboard Grant Reset
    NDC_ARCHIVECLAIMS = 863, // Internal:Archive Grant Admin
    NDC_EDIT_USERMODULES = 864, // Internal:Edit UserModules
    NDC_DELETE_USERMODULES = 865, // Internal:Delete UserModules
    NDC_CREATE_USERMODULES = 866, // Internal:Create UserModules
    NDC_VIEW_USERMODULES = 867, // Internal:View User Modules
    NDC_VIEW_FUNCTIONS = 868, // Internal:Outsource: View EBO Functions
    NDC_EDIT_FUNCTIONS = 869, // Internal:Outsource: Edit EBO Functions
    FN_TESTMODE = 870, // Test Mode
    NDC_VIEW_GROUPS = 871, // Internal:View Internal User Groups
    NDC_EDIT_GROUPS = 872, // Internal:Edit Internal User Groups
    NDC_NONQUEUEJOB_STARTSTOP = 873, // Internal:NonQueue Job StartStop
    NDC_VIEW_PRODUCTPROFILES = 874, // Internal:View Product Profiles
    NDC_CREATE_PRODUCTPROFILES = 875, // Internal:Create Product Profiles
    NDC_DELETE_PRODUCTPROFILES = 876, // Internal:Delete Product Profiles
    NDC_EDIT_PRODUCTPROFILES = 877, // Internal:Edit Product Profiles
    NDC_VIEW_LINK_CLIENTS_MAINTENANCE = 878, // Internal:View Link Clients Maintenance
    NDC_EDIT_LINK_CLIENTS_MAINTENANCE = 879, // Internal:Edit Link Clients Maintenance
    NDC_VIEW_BANNEDADDRESSES = 880, // Internal:View Banned Addresses Maintenance
    NDC_ADD_BANNEDADDRESSES = 881, // Internal:Add Banned Addresses Maintenance
    NDC_DELETE_BANNEDADDRESSES = 882, // Internal:Delete Banned Addresses Maintenance
    NDC_VIEW_APPLY_SITETEMPLATES = 883, // Internal:View Apply Site Templates
    NDC_AGENT_INSTALL = 884, // Internal:Agent Install
    NDC_CREATE_INTERNALUSER = 885, // Internal:Create Internal User
    NDC_RUN_BRIDGE_ROUTINES = 886, // Internal:Run Bridge Routines
    NDC_VIEW_CLIENTSET = 887, // Internal:View Client Set
    NDC_EDIT_CLIENTSET = 888, // Internal:Edit Client Set
    NDC_PHONE_HOME = 889, // Internal:Phone Home
    NDC_CHANGE_CLIENT_DISPLAY_NAME = 890, // Internal:Change Client Display Name
    NDC_SUPPORT_LOOKUP_EVENT_ID = 891, // Internal:Support Lookup Event Id
    NDC_ENCRYPT_DOWNLOAD = 892, // Internal:Encrypt Download
    NDC_EDIT_REMITQUEUE = 893, // Internal:Remit Queue Edit
    NDC_USER_BETA_PREVIEW = 896, // Internal:User Beta Preview
    FN_CLAIM_ASSIGN_REPORT = 897, // Assignments Report Export
    FN_BATCH_VALIDATE = 898, // Validate Claims: Batch
    FN_VIEW_UDE = 968, // View User Defined Edits
    FN_VIEW_X12_UPLOAD_837 = 542, // View X12 837 from file
    FN_VIEW_X12_UPLOAD_835 = 541, // View X12 835 from file
    FN_VIEW_X12_837 = 540, // View X12 837 From Assurance
    FN_DDEPLUS_LINK = 451, // Direct Entry: DDE+
    NDC_VIEW_PCG_PAYER_MAINTENANCE = 900, // Internal:View PCG Payer Maintenance
    NDC_EDIT_PCG_PAYER_MAINTENANCE = 901 //  Internal:Edit PCG Payer Maintenance
};

export default SecurityBits;
