interface IUiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

export interface IHeaderProps {
    columns: {
    }
}

export interface IRecordProp {
        '@PayerID': number,
        '@DisplayName': string,
        '@Active': string,
        '@Batch': string,
        '@Bridge': string,
        '@CFI': string,
        '@Manual': string,
        '@Direct': string,
        '@Fields': string,
        '@Edits': string,
        '@RTECPID': string,
}

export interface IRowProps {
    record: IRecordProp;
}

export class GridConfig {
    public getColumnHeadersConfig(): Set<IUiclColumn> {
        const columns: IUiclColumn[] = [
            {
                dataName: "_DisplayName",
                text: 'Payer',
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "_Active",
                text: "Active",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "_Batch",
                text: "Batch",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "_Bridge",
                text: "Bridge",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "_CFI",
                text: "CFI",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "_Manual",
                text: "Manual",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "_Direct",
                text: "Direct",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Fields",
                text: "Fields",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Edits",
                text: "Edits",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
        ];
        return new Set(columns);
    }
}
