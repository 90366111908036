import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, SelectDropdownV2 } from 'ui-core';
import styled from 'styled-components';

import { Colors } from '@commonResources/colorVariables';
import { DialogFieldset, DialogLegend, DialogLabel } from '@common/DialogStyles';
import { OKCancelButtons, DialogWrapper } from '@common/DialogWrapper';
import { RadioButtonComponent } from '@common/RadioButton';
import CrudTypes from '../../../commonResources/CrudTypes';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { ApplicationState } from '@store/index';
import { ICookies_Config, ICookies_Container } from '@store/ConfigData';
import { IBatchClaimsPrintingOptionsUIState, IBatchClaimsPrintingOptionsActionProps, actionCreators, validationCallback } from '@store/BatchClaimsPrintingOptions';
import { BoldSelectWrapper } from '../../common/component-styles';

const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

const OptionsLinkButton = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    
    button {
        color: ${Colors.chBlue100};
        text-align: center;
    }
`;

const BlankRow = styled.tr`
    height: 10px!important;
`;

const BCPO_PRINTOPTIONS = {
    UB92:        '0|1011|',
    UB04:        '1|2011|',
    HCFA1500:    '0|1001|',
    CMS1500:     '1|2001|',
    CMS15002012: '2|3001|',
    ADA1999:     '3010|3010|',
    ADA2002:     '3011|3011|',
    ADA2006:     '3012|3012|',
    ADA2012:     '3013|3013|',
    ADA2019:     '3014|3014|'
};

interface IComponentProps {
};

interface IComponentState {
    showPrintOptions: boolean;
    dataLoaded: boolean
}

export const DEFAULT_STATE: IComponentState = { showPrintOptions: false, dataLoaded: true };

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IBatchClaimsPrintingOptionProps = IMergeCrudComponentProps<IBatchClaimsPrintingOptionsUIState, IBatchClaimsPrintingOptionsActionProps, IOwnProps>;

export class BatchClaimsPrintingOptions extends React.PureComponent<IBatchClaimsPrintingOptionProps, IComponentState> {
    // define only once, instead of on every render
    private instructions = <React.Fragment>Use this page to set up a printing option for each type of claim form you print. Select the claim form, then select the print option and click OK.</React.Fragment>;

    constructor(props: IBatchClaimsPrintingOptionProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.onShowHidePrintOptions = this.onShowHidePrintOptions.bind(this);
        this.onSelectProfFormType = this.onSelectProfFormType.bind(this);
        this.onSelectInstFormType = this.onSelectInstFormType.bind(this);
        this.onChangePrintType = this.onChangePrintType.bind(this);
        this.onChangeFontFace = this.onChangeFontFace.bind(this);
        this.onChangeFontSize = this.onChangeFontSize.bind(this);
        this.onChangeInstPrintCommandSet = this.onChangeInstPrintCommandSet.bind(this);
        this.onChangeProfPrintCommandSet = this.onChangeProfPrintCommandSet.bind(this);
        this.getSelectedPrintOptions = this.getSelectedPrintOptions.bind(this);
    }

    public componentDidMount() {

        this.props.action.crud.get({ crudId: CrudTypes.mctiPaperClaimsConfig }, (result: MCPaperClaimsPrinting) => {
            if (validationCallback(result)) {

                if (this.shouldSkipBatchClaimsPrintingOptions(result)) {
                    this.goToFilter("");
                }
                else {
                    this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "GoLive_15002012" }] },
                        (configResult: ICookies_Container) => { this.initializeOptions(this.goLiveCms15002012(configResult), result); },
                        () => { this.initializeOptions("", result); });
                }
                return true;
            }
            else
                return false;

        });
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    goLiveCms15002012(configResult: ICookies_Container) {
        let bGoLiveCMS15002012 = true;
        let goLiveCMS15002012Config = configResult?.config?.find((o: ICookies_Config) => o.name === "GoLive_15002012");
        if (goLiveCMS15002012Config?.value) {
            return goLiveCMS15002012Config.value;
        }
        return "";
    }

    initializeOptions(goLiveCMS15002012Date: string, crud: MCPaperClaimsPrinting) {
        this.props.action.ui.initalizeOptions({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { ...crud, CMS2012LiveDate: goLiveCMS15002012Date }
        });
    }

    shouldSkipBatchClaimsPrintingOptions(crud: MCPaperClaimsPrinting) {
        let dateOption = crud?.PaperClaimsPrintingMaintenanceInfo?.DateOptions?.DateOption;
        if (!dateOption)
            return true;

        let batchClaimPrompt: string = dateOption['@BatchClaimPrompt'];
        if (!batchClaimPrompt)
            return true;

        return (batchClaimPrompt != "1")
    }

    goToFilter(paramValue: string) {
        this.props.history.push('/Claims/Filter/PrintClaims', { parameter: paramValue }); // For example: '|2|1|3|0|1011|2|3001|42|67|'
    }

    getSelectedPrintOptions() {
        let printType = this.props.dataStore.ui.selectedPrintType.value;
        let fontFace = this.props.dataStore.ui.selectedFontFace.value;
        let fontSize = this.props.dataStore.ui.selectedFontSize.value;
        let instCommandSet = this.props.dataStore.ui.selectedInstPrintCommandSet.value;
        let profCommandSet = this.props.dataStore.ui.selectedProfPrintCommandSet.value;
        let result = '|';

        result += (printType === 'Default' ? '-1|' : printType + '|');
        result += (fontFace === 'Default' ? '-1|' : fontFace + '|');
        result += (fontSize === 'Default' ? '-1|' : fontSize + '|');

        result += (this.props.dataStore.ui.selectedInstFormType === 'UB92' ? BCPO_PRINTOPTIONS.UB92 : BCPO_PRINTOPTIONS.UB04);

        switch (this.props.dataStore.ui.selectedProfFormType) {
            case 'HCFA1500':    result += BCPO_PRINTOPTIONS.HCFA1500; break;
            case 'CMS1500':     result += BCPO_PRINTOPTIONS.CMS1500; break;
            case 'CMS15002012': result += BCPO_PRINTOPTIONS.CMS15002012; break;
            case 'ADA1999':     result += BCPO_PRINTOPTIONS.ADA1999; break;
            case 'ADA2002':     result += BCPO_PRINTOPTIONS.ADA2002; break;
            case 'ADA2006':     result += BCPO_PRINTOPTIONS.ADA2006; break;
            case 'ADA2012':     result += BCPO_PRINTOPTIONS.ADA2012; break;
            case 'ADA2019':     result += BCPO_PRINTOPTIONS.ADA2019; break;
        }

        let item = this.props.dataStore.crud.data?.PaperClaimsPrintingMaintenanceInfo.PrintCommandSetList.PrintCommandSet.find(ob => ob["@ID"] === instCommandSet);
        result += (item ? instCommandSet + '|' : '-1|');
        item = this.props.dataStore.crud.data?.PaperClaimsPrintingMaintenanceInfo.PrintCommandSetList.PrintCommandSet.find(ob => ob["@ID"] === profCommandSet);
        result += (item ? profCommandSet + '|' : '-1|');

        console.log('bcpo - parameter:' + result);
        return result;
    }

    public onSelectInstFormType(formtype:string) {
        this.props.action.ui.selectInstFormType(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: formtype 
            });
    }
    
    public onSelectProfFormType(formtype: string) {
        this.props.action.ui.selectProfFormType(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: formtype 
            });
    }

    public onShowHidePrintOptions(e: React.ChangeEvent<HTMLButtonElement>) {
        this.setState({ showPrintOptions: !this.state.showPrintOptions });
    }

    public onChangePrintType(e: any) {
        this.props.action.ui.selectPrintType(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: { label: e.label, value: e.value }
            });
    }

    public onChangeFontFace(e: any) {
         this.props.action.ui.selectFontFace(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: { label: e.label, value: e.value }
            });
    }

    public onChangeFontSize(e: any) {
        this.props.action.ui.selectFontSize(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: { label: e.label, value: e.value }
            });
    }

    public onChangeInstPrintCommandSet(e: any) {
        this.props.action.ui.selectInstPrintCommandSet(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: { label: e.label, value: e.value }
            });
    }

    public onChangeProfPrintCommandSet(e: any) {
        this.props.action.ui.selectProfPrintCommandSet(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: { label: e.label, value: e.value }
            });
    }

    isBusy = () => {
        return (!this.props.dataStore.ui.dataLoaded || this.props.dataStore.crud.dataStatus === 'REQUEST');
    }



    public onDialogOk = () => {
        let paramValue = this.getSelectedPrintOptions();
        this.goToFilter(paramValue);
    }

    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        this.props.history.push('/LandingPage');
    }

    private okCancelButtons = <OKCancelButtons disableOK={false} onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel} />;

    public render() {
 
          return (<DialogWrapper  title='Print Claim Options'
                                instruction={this.instructions}
                                helpUrl='/Support/Help/HELP_Claims_PrintClaimOptions.htm'
                                buttons={this.okCancelButtons}
                                isBusy={this.isBusy()}>
              <ContentWrapper>
                <DialogFieldset>
                    <DialogLegend>Paper Form Type</DialogLegend>
                      <table>
                          <tr>
                              <td>
                                  <DialogLabel id='id-bcpo-inst-label'>Institutional:</DialogLabel>
                              </td>
                              <td>
                          <RadioButtonComponent
                            id="id-bcpo-UB92"
                            label="UB-92"
                            checked={this.props.dataStore.ui.selectedInstFormType === "UB92"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectInstFormType("UB92")}
                            disabled={false}
                                  />
                              </td>
                              <td>
                         <RadioButtonComponent
                            id="id-bcpo-UB04"
                            label="UB-04"
                            checked={this.props.dataStore.ui.selectedInstFormType === "UB04"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectInstFormType("UB04")}
                            disabled={false}
                                  />
                                  </td>
                                  <td></td>
                          </tr>
                          <BlankRow>
                                <td></td>
                          </BlankRow> 
                          <tr>
                                  <td>
                                      <DialogLabel id='id-bcpo-prof-label'>Professional:</DialogLabel>
                                  </td>
                                  <td>
                        <RadioButtonComponent
                            id="id-bcpo-hcfa1500"
                            label="HCFA-1500"
                            checked={this.props.dataStore.ui.selectedProfFormType === "HCFA1500"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectProfFormType("HCFA1500")}
                            disabled={false}
                                      />
                                  </td>
                                  <td>
                         <RadioButtonComponent
                            id="id-bcpo-cms1500"
                            label="CMS-1500"
                            checked={this.props.dataStore.ui.selectedProfFormType === "CMS1500"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectProfFormType("CMS1500")}
                            disabled={false}
                                      />
                                  </td>
                                  <td>
                          <RadioButtonComponent
                            id="id-bcpo-15002012"
                            label="1500-2012"
                            checked={this.props.dataStore.ui.selectedProfFormType === "CMS15002012"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectProfFormType("CMS15002012")}
                            disabled={false}
                                      />
                                      </td>
                              </tr>
                    <tr><td></td>
                              {this.props.dataStore.ui.showADA1999 &&
                            <td><RadioButtonComponent
                                id="id-bcpo-ada1999"
                                label="ADA-1999"
                                checked={this.props.dataStore.ui.selectedProfFormType === "ADA1999"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectProfFormType("ADA1999")}
                                disabled={false}
                            /></td>
                        }
                        {this.props.dataStore.ui.showADA2002 &&
                            <td><RadioButtonComponent
                                id="id-bcpo-ada2002"
                                label="ADA-2002"
                                checked={this.props.dataStore.ui.selectedProfFormType === "ADA2002"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectProfFormType("ADA2002")}
                                disabled={false}
                            /></td>
                        }
                        {this.props.dataStore.ui.showADA2006 &&
                            <td><RadioButtonComponent
                                id="id-bcpo-ada2006"
                                label="ADA-2006"
                                checked={this.props.dataStore.ui.selectedProfFormType === "ADA2006"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectProfFormType("ADA2006")}
                                disabled={false}

                            /></td>
                        }
                    </tr>
                    <tr><td></td>
                        {this.props.dataStore.ui.showADA2012 &&
                            <td><RadioButtonComponent
                                id="id-bcpo-ada2012"
                                label="ADA-2012"
                                checked={this.props.dataStore.ui.selectedProfFormType === "ADA2012"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectProfFormType("ADA2012")}
                                disabled={false}
                            /></td>
                        }
                        {this.props.dataStore.ui.showADA2019 &&
                            <td><RadioButtonComponent
                                id="id-bcpo-ada2019"
                                label="ADA-2019"
                                checked={this.props.dataStore.ui.selectedProfFormType === "ADA2019"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectProfFormType("ADA2019")}
                                disabled={false}
                            /></td>
                        }
                          </tr>
                          <BlankRow>
                              <td></td>
                          </BlankRow> 
                          </table>
                  </DialogFieldset>
                  <OptionsLinkButton>
                <Button
                    domID="bcpo-show-print-options"
                    type="button"
                    size="medium"
                    buttonType="link"
                    className="link-button"
                    onClick={ this.onShowHidePrintOptions }
                    disabled={false}
                >
                      <span id="bcpo-show-print-options-span">Click to adjust <i>print, font type & command set</i></span>
                      </Button>
                  </OptionsLinkButton>
                {this.state.showPrintOptions &&
                    <DialogFieldset>
                        <DialogLegend>Print and Font Type</DialogLegend>
                        <BoldSelectWrapper>
                            <SelectDropdownV2
                            domID="bcpo-print-type"
                            className="dropdown"
                            label="Print Type:"
                            isClearable={false}
                            isSearchable={false}
                            size="small"
                            onChange={this.onChangePrintType}
                            options={this.props.dataStore.ui.printTypeList}
                            initialValue={this.props.dataStore.ui.selectedPrintType}
                            disabled={this.props.dataStore.ui.printTypeDisabled}
                            />
                            <SelectDropdownV2
                                domID="bcpo-font-face"
                                className="dropdown"
                                label="Font Face:"
                                isClearable={false}
                                isSearchable={false}
                                size="small"
                                onChange={this.onChangeFontFace}
                                options={this.props.dataStore.ui.fontFaceList}
                                initialValue={this.props.dataStore.ui.selectedFontFace}
                                disabled={this.props.dataStore.ui.fontDisabled}
                            />
                            <SelectDropdownV2
                                domID="bcpo-font-size"
                                className="dropdown"
                                label="Font Size (pt):"
                                isClearable={false}
                                isSearchable={false}
                                size="small"
                                onChange={this.onChangeFontSize}
                                options={this.props.dataStore.ui.fontSizeList}
                                initialValue={this.props.dataStore.ui.selectedFontSize}
                                disabled={this.props.dataStore.ui.fontDisabled}
                           />
                            <SelectDropdownV2
                                domID="bcpo-inst-print-cmd-set"
                                className="dropdown"
                                label="Institutional Print Command Set:"
                                isClearable={false}
                                isSearchable={false}
                                size="small"
                                onChange={this.onChangeInstPrintCommandSet}
                                options={this.props.dataStore.ui.instPrintCommandSetList}
                                initialValue={this.props.dataStore.ui.selectedInstPrintCommandSet}
                            />
                            <SelectDropdownV2
                                domID="bcpo-prof-print-cmd-set"
                                className="dropdown"
                                label="Professional Print Command Set:"
                                isClearable={false}
                                isSearchable={false}
                                size="small"
                                onChange={this.onChangeProfPrintCommandSet}
                                options={this.props.dataStore.ui.profPrintCommandSetList}
                                initialValue={this.props.dataStore.ui.selectedProfPrintCommandSet}
                            />
                        </BoldSelectWrapper>
                    </DialogFieldset>
                }
              </ContentWrapper>
                </DialogWrapper>);
    }
}

const connectedHoc = connect<IBatchClaimsPrintingOptionsUIState,
    IBatchClaimsPrintingOptionsActionProps,
    IOwnProps,
    IBatchClaimsPrintingOptionProps,
    ApplicationState>(
    createCrudMapStateToProps('batchClaimsPrintingOptions'),
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(BatchClaimsPrintingOptions);

export default withRouter(connectedHoc);



