import * as React from 'react';
import { Input as UICLInput} from 'ui-core';
import styled from 'styled-components';

const PadBottomWrapperNoError = styled.div`
    padding-bottom: 2.375rem;
`;

const PadBottomWrapperNoMessage = styled.div`
    padding-bottom: 1.96875rem;
`;

export interface IArmInputHandlerProps {
    onChange?: React.ChangeEventHandler<HTMLInputElement>, //Do we need to have this as a prop when it's already in UICL Input?
    forceUpperCase?: boolean,
    hasGutter?: boolean,
}

export class Input extends React.Component<IArmInputHandlerProps | any, {}>
{
    static defaultProps: IArmInputHandlerProps = {
    };

    constructor(props: IArmInputHandlerProps) {
        super(props);
        this.state = {
        }
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>)
    {
        e.target.value = e.target.value.toLocaleUpperCase();
        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    //Pads an incoming component with a gutter of the same height as the error message if there is no error message.
    //Also accounts for the extra padding of an Input component in error without an error message.
    checkBottomPadding(fragment: React.ReactFragment) {
        //If we do not have a gutter turned on, do not pad.
        //TODO - consolidate bottom case into this if statement
        if (!this.props.hasGutter)
            return fragment;

        //If we have a gutter but no error, pad the full gutter amount.
        if (!this.props.hasError) {
            return (
                <PadBottomWrapperNoError>
                    {fragment}
                </PadBottomWrapperNoError>
            );
        }

        //If we have a gutter with an error but no message, pad the full amount minus the 0.5em bottom margin
        //the UICL component turns on.
        if (!this.props.errorMessage || this.props.errorMessage.length === 0) {
            return (
                <PadBottomWrapperNoMessage>
                    {fragment}
                </PadBottomWrapperNoMessage>
            );
        }
        return fragment;
    }

    //Check if we need to force the text to all upper case on change
    checkForceUpperCase() {
        if (!this.props.forceUpperCase) {
            return(
                <UICLInput {...this.props}/>
            );
        }

        return (
            this.checkBottomPadding(
                <UICLInput
                    {...this.props}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(e)}/>
            )
        );
    }

    render() {
        return (
            this.checkBottomPadding(
                this.checkForceUpperCase()
            )
        );
    }
};
