import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { ISortIndicator, SortDataType, SortOrder } from "@scripts/util/SortHelper"


export enum FormAction {
    Delete,
    Download,
}
Object.freeze(FormAction);

export enum DataAction {
    Filter,
    Process,
    Sort,
}
Object.freeze(DataAction);

//export enum SortOrder {
//    Ascending,
//    Descending
//}


// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IPaperClaimsUIState {
    formTypesUsed: MCFormsUseds;
    paperClaims: IPaperClaimList;
    selectedPaperClaims: IPaperClaimList;
    selectedFilter: ISelectedFilter;
    selectedFormAction: FormAction;
    currentSort: ISortIndicator;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectedFilter {
    key: string;
    value: string;
};

export const defaultSelectedFilter: ISelectedFilter = {
    key: '',
    value: ''
};

export interface ISelectedProcess {
    FormAction: FormAction;
};

export const defaultSelectedProcess: ISelectedProcess = {
    FormAction: FormAction.Delete,
};

//export interface ISelectedSort {
//    key: string;
//    order: SortOrder;
//};

//export const defaultSelectedSort: ISelectedSort = {
//    key: '',
//    order: SortOrder.Ascending
//};

export interface IFilterData extends ICrudActionData<IARMPaperClaimList, ISelectedFilter> { }
export interface IProcessData extends ICrudActionData<IARMPaperClaimList, ISelectedProcess> { }
export interface ISortData extends ICrudActionData<IARMPaperClaimList, ISortIndicator> { }

export const actionCreators = {
    FilterPaperClaims: (filterDataInfo: IFilterData) => createAction(DataAction.Filter.toString()),
    ProcessPaperClaims: (processDataInfo: IProcessData) => createDataAction(DataAction.Process.toString(), processDataInfo),
    SortPaperClaims: (sortIndicator: ISortIndicator) => createDataAction(DataAction.Sort.toString(), sortIndicator),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IPaperClaimsUIState = {
    formTypesUsed: {
        Form: Array<MCFormsUsed>()
    },
    paperClaims: {
        PaperClaims: Array<IPaperClaim>()
    },
    selectedPaperClaims: {
        PaperClaims: Array<IPaperClaim>()
    },
    selectedFilter: defaultSelectedFilter,
    selectedFormAction: FormAction.Delete,
    currentSort: {
        sortColumn: '',
        sortDataType: SortDataType.String,
        sortDirection: SortOrder.Ascending.toString()
    }
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IPaperClaimsUIState, KnownActions> = (state: IPaperClaimsUIState | undefined, action: KnownActions) => {
    //TO-DO: ??? do we need to add action for "OPEN_MODAL_CONFIRMATION"???
    if (state != undefined && Object.values(DataAction).includes(action.type)) {
        let actionType = action.type;
        let paperClaimAction = <DataAction><unknown>(actionType);
        switch (paperClaimAction) {
            case DataAction.Filter:
                console.log("in DataAction.Filter"); //<<< REMOVE WHEN DONE DEBUGGING!!!
                break;
            case DataAction.Process:
                console.log("in DataAction.Process"); //<<< REMOVE WHEN DONE DEBUGGING!!!
                {
                    //TO-DO: get state of selectedFormAction
                    //let selectInfo: IPaperClaimUIState = state.selectedFilter;
                    //if (action.data.masterCrud && state.selectedFormAction == FormAction.Delete) {
                    if (state.selectedFormAction == FormAction.Delete) {
                        return {
                            ...state,
                            //opAlert: action.data.uiData.value,
                        }
                    }
                    else if (state.selectedFormAction == FormAction.Download) {
                    }
                }
                break;
            case DataAction.Sort:
                console.log("in DataAction.Sort"); //<<< REMOVE WHEN DONE DEBUGGING!!!

                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
