import * as React from 'react';
import styled from 'styled-components';
import { ARMGrid } from '../../common/UICLWrappers/ARMGrid';
import { Typography } from 'ui-core';
import { ARMHeaderCell } from '../../common/UICLWrappers/ARMHeaderCell';
import { ISortIndicator, SortDataType, SortHelper } from '@scripts/util/SortHelper';
import { Colors } from '../../../commonResources/colorVariables';
import { DialogWrapper, OKCancelButtons } from '../../common/DialogWrapper';
import { getRawToken } from "../../../scripts/session/SecurityToken";
import { URLs } from '../../../commonResources/constants';
import AssuranceMenu from '../../common/AssuranceMenu';
import { handleChange, pageLeave } from "@commonResources/userModified";
import _ from 'lodash';

const numberOfSortableHeaders = 4
let sortableHeaderIds: any[] = []
for (let i = 0; i < numberOfSortableHeaders; i++) {
    sortableHeaderIds = [...sortableHeaderIds, "" + _.uniqueId()]
}

const gridID = "SetNPIStatusGrid-id"

const pageSize = 100;


const SetNPIStatusContainerDiv = styled.div`
    width: 822px;
    height: 370px;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const SetNPIStatusGridDiv = styled.div`
    width: 822px;
    height: 335px;
    th {
        z-index: 9;
    }
`;


interface IRowProps {
    record: IRecord
};

interface IRecord {
    "PayerId": string;
    "PayerName": string;
    "ProviderNo": string;
    "NPI": string;
    "Taxonomy": string;
    "NPIFlag": string;
    "FacilityTypeId": string;
    "RowPTR": string;
}

interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    canCreate: boolean,
    canDelete: boolean,
};

interface IComponentState {
    cancelLeave: boolean;
    cursorPos: number;
    inputScrollPos: number;
    NPIStatus: any[];
    Payer: any[];
    records: any[];
    paginatedRecords: any[];
    deltaArray: any[];
    isHoveringOverBack: boolean;
    isHoveringOverFoward: boolean;
    pageIndex: number;
    pageUUID: string;
    sortIndicator: ISortIndicator;
};


export default class SetNPIStatus extends React.Component<IComponentProps, IComponentState> {
    sortableHeaderRefs: { [index: string]: any };
    dropdownRefs: { [index: string]: any };
    firstPageRecordRef: any;

    constructor(props: IComponentProps) {
        super(props)
        this.state = {
            cancelLeave: false,
            cursorPos: -1,
            inputScrollPos: 0,
            NPIStatus: [],
            Payer: [],
            records: [],
            paginatedRecords: [],
            deltaArray: [],
            isHoveringOverBack: false,
            isHoveringOverFoward: false,
            pageIndex: 0,
            pageUUID: "",
            sortIndicator: {
                sortColumn: 'PayerName',
                sortDirection: 'up',
                sortDataType: SortDataType.String
            },
        };
        this.sortableHeaderRefs = [];
        this.dropdownRefs = [];
    }

    public componentDidMount() {
        var url = URLs.api + '/api/crud/73';
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(response => {
                return response.json();
            })
            .then(response => {
                const {
                    NPIStatusList,
                    PayerList
                } = response.FacilityNPIStatusInfo

                let records: any = [];
                if (Array.isArray(NPIStatusList.NPIStatus)) {
                    records = NPIStatusList.NPIStatus.map((status: any) => {
                        const payer: any = PayerList.Payer.find((p: any) => {
                            return status["@PayerId"] === p["@ID"]
                        })

                        let payerName = ""
                        if (payer && payer["@Name"]) {
                            payerName = payer["@Name"]
                        }

                        return {
                            "FacilityTypeId": status["@FacilityTypeId"],
                            "NPI": status["@NPI"],
                            "NPIFlag": status["@NPIFlag"],
                            "PayerId": status["@PayerId"],
                            "ProviderNo": status["@ProviderNo"],
                            "RowPTR": status["@RowPTR"],
                            "Taxonomy": status["@Taxonomy"],
                            "PayerName": payerName,
                        }
                    })
                }
                else {
                    let payer = { "@Name": "" };
                    if (Array.isArray(PayerList.Payer)) {
                        payer = PayerList.Payer.find((p: any) => {
                            return NPIStatusList.NPIStatus["@PayerId"] === p["@ID"]
                        })
                    }
                    else {
                        if (NPIStatusList.NPIStatus["@PayerId"] === PayerList.Payer["@ID"]) {
                            payer = PayerList.Payer;
                        }
                    }

                    let payerName = ""
                    if (payer && payer["@Name"]) {
                        payerName = payer["@Name"]
                    }

                    records.push({
                        "FacilityTypeId": NPIStatusList.NPIStatus["@FacilityTypeId"],
                        "NPI": NPIStatusList.NPIStatus["@NPI"],
                        "NPIFlag": NPIStatusList.NPIStatus["@NPIFlag"],
                        "PayerId": NPIStatusList.NPIStatus["@PayerId"],
                        "ProviderNo": NPIStatusList.NPIStatus["@ProviderNo"],
                        "RowPTR": NPIStatusList.NPIStatus["@RowPTR"],
                        "Taxonomy": NPIStatusList.NPIStatus["@Taxonomy"],
                        "PayerName": payerName,
                    })
                }

                this.setState({
                    NPIStatus: NPIStatusList.NPIStatus,
                    Payer: PayerList.Payer,
                    records,
                    paginatedRecords: this.paginateResults(records, {
                        sortColumn: 'PayerName',
                        sortDirection: 'up',
                        sortDataType: SortDataType.String
                    })
                });
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    };

    public handleSorting = (sortIndicator: ISortIndicator, refKey: string = "") => {
        this.setState({
            sortIndicator,
            paginatedRecords: this.paginateResults(this.state.records, sortIndicator)
        }, () => refKey !== "" && this.sortableHeaderRefs[refKey].focus());
    }

    public getCustomHeader = () => {
        const {
            sortIndicator,
        } = this.state;

        const header = () => <thead>
            <tr>
                <ARMHeaderCell
                    id={sortableHeaderIds[0]}
                    key={sortableHeaderIds[0]}
                    setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[0]] = input}
                    refKey={sortableHeaderIds[0]}
                    style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "250px", minWidth: "250px" }}
                    dataName="PayerName"
                    text="Payer"
                    cellType="text"
                    sortable={true}
                    sortDataType={SortDataType.String}
                    sortHandler={this.handleSorting}
                    sortIndicator={sortIndicator}
                    hasCorrectedTabIndex={true}
                />
                <ARMHeaderCell
                    id={sortableHeaderIds[1]}
                    key={sortableHeaderIds[1]}
                    setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[1]] = input}
                    refKey={sortableHeaderIds[1]}
                    style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "140px", minWidth: "140px" }}
                    dataName="ProviderNo"
                    text="Provider No"
                    cellType="text"
                    sortable={true}
                    sortDataType={SortDataType.String}
                    sortHandler={this.handleSorting}
                    sortIndicator={sortIndicator}
                    hasCorrectedTabIndex={true}
                />
                <ARMHeaderCell
                    id={sortableHeaderIds[2]}
                    key={sortableHeaderIds[2]}
                    setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[2]] = input}
                    refKey={sortableHeaderIds[2]}
                    style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "110px", minWidth: "110px" }}
                    dataName="NPI"
                    text="NPI"
                    cellType="text"
                    sortable={true}
                    sortDataType={SortDataType.String}
                    sortHandler={this.handleSorting}
                    sortIndicator={sortIndicator}
                    hasCorrectedTabIndex={true}
                />
                <ARMHeaderCell
                    id={sortableHeaderIds[3]}
                    key={sortableHeaderIds[3]}
                    setRef={(input: any) => this.sortableHeaderRefs[sortableHeaderIds[3]] = input}
                    refKey={sortableHeaderIds[3]}
                    style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "115px", minWidth: "115px" }}
                    dataName="Taxonomy"
                    text="Taxonomy"
                    cellType="text"
                    sortable={true}
                    sortDataType={SortDataType.String}
                    sortHandler={this.handleSorting}
                    sortIndicator={sortIndicator}
                    hasCorrectedTabIndex={true}
                />
                <ARMHeaderCell
                    style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "190px", minWidth: "190px" }}
                    dataName="NPIFlag"
                    text="NPI Status"
                    hasCorrectedTabIndex={true}
                />
            </tr>
        </thead>
        return header;
    }

    public getCustomRow = () => {
        const row = ({ record }: IRowProps) => {
            const RowPTR = record["RowPTR"];
            const PayerId = Number(record["PayerId"]);
            const facilityTypeId = record["FacilityTypeId"];
            const NPIFlag = Number(record["NPIFlag"]);
            const rowUUID = RowPTR + "" + PayerId
            let initVal;
            if (NPIFlag >= 6)
                initVal = "2";
            else if (NPIFlag >= 3)
                initVal = "1";
            else
                initVal = "0"

            return (
                <tr id={"row-" + rowUUID} className={"row-" + rowUUID} key={"row-" + rowUUID}>
                    <td
                        id={"data1-" + rowUUID} className={"data1-" + rowUUID} key={"data1-" + rowUUID}
                        style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "245px", minWidth: "245px", height: "20px", paddingTop: "3px" }}
                    >
                        {record["PayerName"]}
                    </td>
                    <td
                        id={"data2-" + rowUUID} className={"data2-" + rowUUID} key={"data2-" + rowUUID}
                        style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "135px", minWidth: "135px", height: "20px", paddingTop: "3px" }}
                    >
                        {record["ProviderNo"]}
                    </td>
                    <td
                        id={"data3-" + rowUUID} className={"data3-" + rowUUID} key={"data3-" + rowUUID}
                        style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "105px", minWidth: "105px", height: "20px", paddingTop: "3px" }}
                    >
                        {record["NPI"]}
                    </td>
                    <td
                        id={"data4-" + rowUUID} className={"data4-" + rowUUID} key={"data4-" + rowUUID}
                        style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "110px", minWidth: "110px", height: "20px", paddingTop: "3px" }}
                    >
                        {record["Taxonomy"]}
                    </td>
                    <td
                        id={"data5-" + rowUUID} className={"data5-" + rowUUID} key={"data5-" + rowUUID}
                        style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "185px", minWidth: "185px", height: "20px" }}
                    >
                        <select
                            id={rowUUID}
                            className={rowUUID}
                            name={rowUUID}
                            value={initVal}
                            ref={(input: any) => { this.dropdownRefs[rowUUID] = input }}
                            onChange={(e: any) => this.SetNPIStatus(e, record, rowUUID)}
                            style={{ whiteSpace: "nowrap", overflow: "hidden", maxWidth: "180px", minWidth: "180px" }}
                        >
                            <option value="0">{"Legacy + NPI"}</option>
                            <option value="1">{facilityTypeId == 'PH' ? 'NPI only: Billing/Rendering' : 'NPI only: Facility'}</option>
                            <option value="2">{facilityTypeId == 'PH' ? 'NPI only: All' : 'NPI only: Fac/Phys'}</option>
                        </select>
                    </td>
                </tr>
            );
        }

        return row
    }

    public SetNPIStatus = (e: any, record: IRecord, rowUUID: string) => {
        const nValue = Number(e.target.value)
        const Node = record
        const nPrevValue = Number(Node["NPIFlag"]);
        let nNewValue = nPrevValue;

        if (nValue == 0) {
            if (nPrevValue == 3 || nPrevValue == 6)
                nNewValue = 0;
            else if (nPrevValue == 4 || nPrevValue == 7)
                nNewValue = 1;
        }
        else if (nValue == 1) {
            if (nPrevValue == 0 || nPrevValue == 6)
                nNewValue = 3;
            else if (nPrevValue == 1 || nPrevValue == 7)
                nNewValue = 4;
        }
        else {
            if (nPrevValue == 0 || nPrevValue == 3)
                nNewValue = 6;
            else if (nPrevValue == 1 || nPrevValue == 4)
                nNewValue = 7;
        }

        let records = this.state.records
        const recordIndex = this.state.records.findIndex((r: any) => { return r["RowPTR"] === record["RowPTR"] })
        const recsHasRecInd = recordIndex > -1

        let deltaArray = this.state.deltaArray
        const deltaArrayIndex = this.state.deltaArray.findIndex((r: any) => { return r["@RowPTR"] === record["RowPTR"] })
        const deltaHasRecInd = deltaArrayIndex > -1

        const deltaElement = {
            "@RowPTR": "" + record["RowPTR"],
            "@PayerId": "" + record["PayerId"],
            "@ProviderNo": "" + record["ProviderNo"],
            "@NPI": "" + record["NPI"],
            "@Taxonomy": "" + record["Taxonomy"],
            "@NPIFlag": "" + nNewValue,
        }

        if (recsHasRecInd) {
            if (this.state.records[recordIndex] !== nNewValue) {
                records = [...records.slice(0, recordIndex),
                { ...record, "NPIFlag": nNewValue },
                ...records.slice(recordIndex + 1)]
                if (deltaHasRecInd) {
                    deltaArray = [...deltaArray.slice(0, deltaArrayIndex),
                        deltaElement,
                    ...deltaArray.slice(deltaArrayIndex + 1)]
                } else {
                    deltaArray = [...deltaArray, deltaElement]
                }

                this.setState({
                    deltaArray,
                    records,
                    paginatedRecords: this.paginateResults(records, this.state.sortIndicator)
                }, () => {
                    handleChange()
                    this.dropdownRefs[rowUUID].focus();
                });
            }

        } else {
            console.log("ERROR: NPI status record was not found!!! NPI status was not changed!!!")
        }
    }

    public getPageSelectionButtons = () => {
        const {
            isHoveringOverBack,
            isHoveringOverFoward,
            pageIndex,
            paginatedRecords
        } = this.state;

        const nPages = paginatedRecords.length ? paginatedRecords.length : 1

        const isBackArrowDisabled = (nPages < 2) || (pageIndex < 1)
        const isFowardArrowDisabled = (nPages < 2) || (pageIndex === nPages - 1)

        const isBackArrowGray = isBackArrowDisabled || isHoveringOverBack
        const isFowardArrowGray = isFowardArrowDisabled || isHoveringOverFoward

        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginTop: "5px" }}>
                <div
                    onMouseEnter={() => this.setState({
                        isHoveringOverBack: true
                    })}
                    onMouseLeave={() => this.setState({
                        isHoveringOverBack: false
                    })}
                    onClick={() => {
                        isBackArrowDisabled || this.setState({
                            pageIndex: pageIndex - 1,
                            pageUUID: "" + Date.now()
                        })
                    }} >
                    {
                        isBackArrowGray ?
                            <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamOff.png'} /> :
                            <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamUp.png'} />
                    }
                </div>
                <div style={{ paddingBottom: "3px" }}>{pageIndex + 1 + "/" + nPages}</div>
                <div
                    onMouseEnter={() => this.setState({
                        isHoveringOverFoward: true
                    })}
                    onMouseLeave={() => this.setState({
                        isHoveringOverFoward: false
                    })}
                    onClick={() => {
                        isFowardArrowDisabled || this.setState({
                            pageIndex: pageIndex + 1,
                            pageUUID: "" + Date.now()
                        })
                    }} >
                    {
                        isFowardArrowGray ?
                            <img src={'/Shared/Images/Buttons/But_ArrowRight_creamOff.png'} /> :
                            <img src={'/Shared/Images/Buttons/But_ArrowRight_creamUp.png'} />
                    }
                </div>
            </div>
        );
    };

    public paginateResults = (data: any[], sortIndicator: any) => {

        const sortedData = SortHelper.Sort(
            data,
            sortIndicator
        );

        const nPages = sortedData.length === 0 ? 1 : Math.ceil(sortedData.length / pageSize);
        let pages: any[] = [];
        let dataSlice: any[] = [];
        for (let i = 0; i < nPages; i++) {
            dataSlice = sortedData.slice((i * pageSize), ((i + 1) * pageSize));
            pages = [
                ...pages,
                dataSlice
            ];
        };
        return pages;
    };

    public onOK = () => {
        const {
            deltaArray
        } = this.state;

        const body = {
            FacilityNPIStatusInfo: {
                NPIStatusList: { NPIStatus: deltaArray },
            }
        };

        var url = URLs.api + '/api/crud/73';

        fetch(url, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(body),
        }).then(response => {
            if (response.status === 200) {
                this.props.history.push("/LandingPage");
                console.log("Your PUT request came back with this expected response:", response)
            } else {
                console.log("Your PUT request came back with this unexpected response:", response);
            }
        }).catch(error => {
            console.log('error:', error);
        });
    };

    public onCancel = () => {
        const userModified: any = sessionStorage.getItem("userModified");
        if (userModified === 'true') {
            this.setState({
                cancelLeave: true
            });
        }
        else {
            this.props.history.push('/LandingPage');
        };
    };

    public componentWillUnmount = () => {
        if (this.state.deltaArray.length > 0) {
            pageLeave();
        }
    }

    public render() {
        const {
            pageIndex,
            pageUUID,
            paginatedRecords
        } = this.state;

        const instruction = "Use this screen to assign the NPI status for legacy provider numbers. Click on the column heads to sort the list.";

        const row = this.getCustomRow();
        const header = this.getCustomHeader();
        const columns = new Set([
            { dataName: "PayerName", text: "Payer", sortable: true, cellType: 'text' },
            { dataName: "ProviderNo", text: "Provider No", sortable: true, cellType: 'text' },
            { dataName: "NPI", text: "NPI", sortable: true, cellType: 'text' },
            { dataName: "Taxonomy", text: "Taxonomy", sortable: true, cellType: 'text' },
            { dataName: "NPIFlag", text: "NPI Status", sortable: false, cellType: 'text' },
        ])

        const buttons = <OKCancelButtons
            disableOK={!this.props.canEdit}
            onClickOK={() => this.onOK()}
            onClickCancel={() => this.onCancel()}
        />;
        return (
            <DialogWrapper
                title="Set NPI Status"
                helpUrl={"/Support/Help/HELP_Maint_NPIStatus.htm"}
                instruction={instruction}
                buttons={
                    buttons
                }
            >
                {
                    this.state.cancelLeave &&
                    <AssuranceMenu {...this.props}
                        Cancel={true}
                        MenuUrl=''
                        stayEvent={() => this.setState({
                            cancelLeave: false
                        })} />
                }
                <SetNPIStatusContainerDiv>
                    <SetNPIStatusGridDiv>
                        <ARMGrid
                            key={gridID + pageUUID}
                            domID={gridID + pageUUID}
                            maxHeight={"335px"}
                            isFixedHeader
                            headerComponent={header}
                            columns={columns}
                            rowComponent={row}
                            records={paginatedRecords.length ? paginatedRecords[pageIndex] : []}
                            scrollToTop={false}
                            selectionKey={"RowPTR"}
                            emptyGridMessage=""
                        /*initialSortingKey="PayerName|SORT_ASCENDING" - This breaks custom sorting and must be removed to enable it*/
                        />
                    </SetNPIStatusGridDiv>
                    {this.getPageSelectionButtons()}
                </SetNPIStatusContainerDiv>
            </DialogWrapper>
        );
    };
};
