import { Action, Reducer } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IAutoListUIState {
    selectedAutoList: IAutoListUIData; // left side selection
    selectedItem: MCAL_AutoProcess;    // the node selected by above

    selectedSchedule: MCAL_Event;
    selectedScheduleTab: string;
    selectedScheduleChanged: boolean;

    selectedAllGroups: string[];
    selectedThisGroups: string[];

    selectedAllAutoModules: string[];
    selectedThisAutoModules: string[];

    changed: boolean;

    autoListNameError?: string;
    autoListLagDaysError?: string;
    autoListAutoModuleError?: string;
    autoListAlert?: string;
    autoListRemoveConfirm?: string;
    autoListRemoveScheduleConfirm?: string;

    submitNCSAlert?: string;
}

interface IAutoListUIFieldUpdate {
    value: string;
}

interface IAutoListUIError {
    value: string;
    id?: string;
}

interface IAutoListUIAllGroupsSelected {
    selected: string[];
}

interface IAutoListUIAutoModulesSelected {
    selected: string[];
}

export interface IAutoListUIData {
    //index: string;
    id: string;
    text: string;
};

export interface IAutoListDayOfWeekUIData {
    index: string;
    value: boolean;
};


export const defaultAutoList: IAutoListUIData = {
  //  index: '',
    id: '',
    text: '',
};

const emptyItem: MCAL_AutoProcess = { 
    '@ID': '',
    '@Name': '',
    '@TriggerEventID': '',
    '@Enabled': '',
    '@LimitClaimsByJob': '',
    '@Delete': '',
    Groups: { Group: [] },
    AutoModules: { AutoModule: [] },
    Events: { Event: [] }
};


const emptySchedule: MCAL_Event = {    
    '@ID': '',
    '@Mon': '',
    '@Tue': '',
    '@Wed': '',
    '@Thu': '',
    '@Fri': '',
    '@Sat': '',
    '@Sun': '',
    '@Monthly': '',
    '@Quarterly': '',
    '@LagDays': '',
    '@Time': '00:00:00',
    '@ClientId': '',
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// data action
export interface ISelectAutoList extends ICrudActionData<MCAutoListType, IAutoListUIData> { }
export interface IUpdateAutoListField extends ICrudActionData<MCAutoListType, IAutoListUIFieldUpdate> {}

export interface ISelectSchedule extends ICrudActionData<MCAutoListType, IAutoListUIData> { }
export interface ISelectDayOfWeek extends ICrudActionData<MCAutoListType, IAutoListDayOfWeekUIData> { }
export interface IAddModifySchedule extends ICrudActionData<MCAutoListType, MCAL_Event> {}

export interface IError extends ICrudActionData<MCAutoListType, IAutoListUIError> { }

export interface ISelectGroupList extends ICrudActionData<MCAutoListType, IAutoListUIAllGroupsSelected> { }
export interface ISelectAutoModulesList extends ICrudActionData<MCAutoListType, IAutoListUIAutoModulesSelected> { }

// mess with crud actions
export interface IAddModifyAutoList extends ICrudActionData<MCAutoListType, MCAL_AutoProcess> { }
export interface IRemoveAutoList extends ICrudActionData<MCAutoListType, MCAL_AutoProcess> { }
export interface IAddRemoveButtons extends ICrudActionData<MCAutoListType, boolean> { }

export const actionCreators = {
    
    selectAutoList: (selectInfo: ISelectAutoList) => createDataAction('SELECT_AUTOLIST', selectInfo),
    updateAutoListName: (fieldInfo: IUpdateAutoListField) => createDataAction('UPDATE_AUTOLIST_NAME', fieldInfo),
    updateAutoListEnabled: (fieldInfo: IUpdateAutoListField) => createDataAction('UPDATE_AUTOLIST_ENABLED', fieldInfo),
    updateAutoListTriggerEvent: (fieldInfo: IUpdateAutoListField) => createDataAction('UPDATE_AUTOLIST_TRIGGEREVENT',
        fieldInfo),
    updateAutoListLimitClaimsByJob: (fieldInfo: IUpdateAutoListField) => createDataAction(
        'UPDATE_AUTOLIST_LIMITCLAIMSBYJOB',
        fieldInfo),

    selectSchedule: (selectSchedule: ISelectSchedule) => createDataAction('SELECT_SCHEDULE', selectSchedule),
    updateSelectedScheduleHr: (fieldInfo: IUpdateAutoListField) => createDataAction('UPDATE_SCHEDULE_HR', fieldInfo),
    updateSelectedScheduleMin: (fieldInfo: IUpdateAutoListField) => createDataAction('UPDATE_SCHEDULE_MIN', fieldInfo),
    selectTabSchedule: (fieldInfo: IUpdateAutoListField) => createDataAction('SELECT_TAB_SCHEDULE', fieldInfo),
    updateAutoListLagDays: (fieldInfo: IUpdateAutoListField) => createDataAction('UPDATE_AUTOLIST_LAGDAYS', fieldInfo),
    updateAutoListMonthlyToggle: (fieldInfo: IUpdateAutoListField) => createDataAction('UPDATE_AUTOLIST_MONTHLYTOGGLE', fieldInfo),
    selectDayOfWeek: (fieldInfo: ISelectDayOfWeek ) => createDataAction('UPDATE_DAYOFWEEK', fieldInfo),
    removeAutoListSchedule: (fieldInfo: IUpdateAutoListField ) => createDataAction('REMOVE_AUTOLIST_SCHEDULE', fieldInfo),
    addAutoListSchedule: (addInfo: IAddModifySchedule) => createDataAction('ADD_AUTOLIST_SCHEDULE', addInfo),
    updateAutoListSchedule: (updateInfo: IAddModifySchedule) => createDataAction('UPDATE_AUTOLIST_SCHEDULE', updateInfo),

    selectAllGroups: (selectGroups: ISelectGroupList) => createDataAction('SELECT_ALLGROUPS', selectGroups),
    selectThisGroups: (selectGroups: ISelectGroupList) => createDataAction('SELECT_THISGROUPS', selectGroups),
    removeGroups: (nothing: IAddRemoveButtons) => createDataAction('REMOVE_GROUPS', nothing),
    addGroups: (nothing: IAddRemoveButtons) => createDataAction('ADD_GROUPS', nothing),

    selectAllAutoModules: (selectAutoModules: ISelectAutoModulesList) => createDataAction('SELECT_ALLAUTOMODULES', selectAutoModules),
    selectThisAutoModules: (selectAutoModules: ISelectAutoModulesList) => createDataAction('SELECT_THISAUTOMODULES', selectAutoModules),
    removeAutoModules: (nothing: IAddRemoveButtons) => createDataAction('REMOVE_AUTOMODULES', nothing),
    addAutoModules: (nothing: IAddRemoveButtons) => createDataAction('ADD_AUTOMODULES', nothing),

    moveAutoModulesUp: (nothing: IAddRemoveButtons) => createDataAction('MOVE_AUTOMODULES_UP', nothing),
    moveAutoModulesDown: (nothing: IAddRemoveButtons) => createDataAction('MOVE_AUTOMODULES_DOWN', nothing),

    errorAutoListField: (fieldInfo: IError) => createDataAction('ERROR_AUTOLIST_FIELD', fieldInfo),
    errorAutoListAlert: (fieldInfo: IUpdateAutoListField) => createDataAction('ERROR_AUTOLIST_ALERT', fieldInfo),
    errorAutoListRemoveConfirm: (fieldInfo: IUpdateAutoListField) => createDataAction('ERROR_AUTOLIST_REMOVE_CONFIRM', fieldInfo),
    errorAutoListRemoveScheduleConfirm: (fieldInfo: IUpdateAutoListField) => createDataAction('ERROR_AUTOLIST_REMOVE_SCHEDULE_CONFIRM', fieldInfo),

    addAutoList: (addInfo: IAddModifyAutoList) => createDataAction('ADD_AUTOLIST', addInfo),
    updateAutoList: (updateInfo: IAddModifyAutoList) => createDataAction('UPDATE_AUTOLIST', updateInfo),
    removeAutoList: (removeInfo: IRemoveAutoList) => createDataAction('REMOVE_AUTOLIST', removeInfo),

    sendSubmitNCSAlert: (fieldInfo: IUpdateAutoListField) => createDataAction('NCS_ALERT', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IAutoListUIState = {
    selectedAutoList: defaultAutoList,
    changed: false,
    selectedItem: emptyItem,
    selectedSchedule: emptySchedule,
    selectedScheduleChanged: false,
    selectedScheduleTab: "0",
    selectedAllGroups: [],
    selectedThisGroups: [],
    selectedAllAutoModules: [],
    selectedThisAutoModules: [],
    autoListNameError: undefined,
    autoListLagDaysError: undefined,
    autoListAutoModuleError: undefined,
    autoListAlert: undefined,
    autoListRemoveConfirm: undefined,
    autoListRemoveScheduleConfirm: undefined,
    submitNCSAlert: '',
};

const NAME_ERROR_1: string = "An auto list by that name already exists.  Please type in a different name.";
const LAGDAYS_ERROR_1: string = "Monthly lag days must be between -30 and 30.";
const LAGDAYS_ERROR_2: string = "Quarterly lag days must be between -90 and 90.";
const LAGDAYS_ERROR_3: string = "Only digits are allowed";

function upsertAutoProcess(array: MCAL_AutoProcess[], item: MCAL_AutoProcess) 
{
    let newArray = array.slice();
    var olditem = newArray.find(obj => obj['@ID'] === item['@ID']);
    if (!olditem) {
        newArray.splice(array.length, 0, JSON.parse(JSON.stringify(item)));
    } else {
        olditem['@Name'] = item['@Name'];
        olditem['@TriggerEventID'] = item['@TriggerEventID'];
        olditem['@Enabled'] = item['@Enabled'];
        olditem['@LimitClaimsByJob'] = item['@LimitClaimsByJob'];
    }

    return newArray;
};

function removeAutoProcess(array: MCAL_AutoProcess[], item: MCAL_AutoProcess)
{
    let newArray = array.slice();
    var oldItem = newArray.filter(obj => obj['@ID'] !== item['@ID']);
    if (!oldItem) {
        console.log('attempt to remove an invalid entry ' + item['@ID'] + ' ' + item['@Name']);
    }

    return oldItem;
}

/* SCHEDULES */
function addSchedule(item: MCAL_AutoProcess, toAdd: MCAL_Event, crud: MCAutoListType, monthly: boolean) {
    if (!item.Events)
        item.Events = { Event: [] };
    if (!item.Events.Event)
        item.Events.Event = [];
    else if (!Array.isArray(item.Events.Event)) {
        item.Events.Event = getArray_MCAL_Event(item.Events.Event);
    }

    let newArray = item.Events.Event.slice();
    let olditem = newArray.find(obj => obj['@ID'] === toAdd['@ID']);
    if (olditem) {
        olditem['@Mon'] = monthly ? "N" : toAdd['@Mon'];
        olditem['@Tue'] = monthly ? "N" : toAdd['@Tue'];
        olditem['@Wed'] = monthly ? "N" : toAdd['@Wed'];
        olditem['@Thu'] = monthly ? "N" : toAdd['@Thu'];
        olditem['@Fri'] = monthly ? "N" : toAdd['@Fri'];
        olditem['@Sat'] = monthly ? "N" : toAdd['@Sat'];
        olditem['@Sun'] = monthly ? "N" : toAdd['@Sun'];
        olditem['@Monthly'] = !monthly ? "N" : toAdd['@Monthly'];
        olditem['@Quarterly'] = !monthly ? "N" : toAdd['@Quarterly'];
        olditem['@LagDays'] = !monthly ? "" : toAdd['@LagDays'];
        // simulate the defaulting of Monthly
        if (monthly && olditem['@Monthly'] === 'N' && olditem['@Quarterly'] === 'N')
            olditem['@Monthly'] = "Y";

        olditem['@Time'] = toAdd['@Time'];
    } else {
        if (monthly) {
            toAdd['@Mon'] = "N";
            toAdd['@Tue'] = "N";
            toAdd['@Wed'] = "N";
            toAdd['@Thu'] = "N";
            toAdd['@Fri'] = "N";
            toAdd['@Sat'] = "N";
            toAdd['@Sun'] = "N";
            // simulate the defaulting of Monthly
            if (monthly && toAdd['@Monthly'] !== 'N' && toAdd['@Quarterly'] !== 'N') {
                toAdd['@Monthly'] = "Y";
                toAdd['@Quarterly'] = "N";
            }

        } else {
            toAdd['@Monthly'] = "N";
            toAdd['@Quarterly'] = "N";
            toAdd['@LagDays'] = "";

        }
        newArray.push(toAdd);
    }
    // keep crud updated
    let crudLookupAutoProcess = crud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(ob => ob["@ID"] === item['@ID']);
    if (crudLookupAutoProcess) {
        if (!crudLookupAutoProcess.Events)
            crudLookupAutoProcess.Events = { Event: [] };
        if (!crudLookupAutoProcess.Events.Event || (crudLookupAutoProcess.Events.Event && !Array.isArray(crudLookupAutoProcess.Events.Event)))
            crudLookupAutoProcess.Events.Event = [];
        crudLookupAutoProcess.Events!.Event = JSON.parse(JSON.stringify(newArray));
    }
    
    return newArray;
}

function removeSchedule(item: MCAL_AutoProcess, toRemove: string, crud: MCAutoListType) {
    if (!item.Events)
        item.Events = { Event: [] };
    if (!item.Events.Event || (item.Events.Event && !Array.isArray(item.Events.Event)))
        item.Events.Event = [];

    let newArray = item.Events.Event.slice();
    newArray = newArray.filter((el) => { return toRemove.indexOf(el['@ID']) === -1 });

    // keep crud updated
    let crudLookupAutoProcess = crud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(ob => ob["@ID"] === item['@ID']);
    if (crudLookupAutoProcess) {
        if (!crudLookupAutoProcess.Events)
            crudLookupAutoProcess.Events = { Event: [] };
        if (!crudLookupAutoProcess.Events.Event || (crudLookupAutoProcess.Events.Event && !Array.isArray(crudLookupAutoProcess.Events.Event)))
            crudLookupAutoProcess.Events.Event = [];
        crudLookupAutoProcess.Events!.Event = JSON.parse(JSON.stringify(newArray));
    }

    return newArray;
}

/* GROUPS */
function removeGroups(item: MCAL_AutoProcess, toRemove: string[], crud: MCAutoListType) {
    if (!item.Groups)
        item.Groups = { Group: [] };
    if (!item.Groups.Group || (item.Groups.Group && !Array.isArray(item.Groups.Group)))
        item.Groups.Group = [];

    let newArray = item.Groups.Group.slice();
    newArray = newArray.filter((el) => { return toRemove.indexOf(el['@ID']) === -1 });

    // keep crud updated
    let crudLookupAutoProcess = crud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(ob => ob["@ID"] === item['@ID']);
    if (crudLookupAutoProcess) {
        if (!crudLookupAutoProcess.Groups)
            crudLookupAutoProcess.Groups = { Group: [] };
        if (!crudLookupAutoProcess.Groups.Group || (crudLookupAutoProcess.Groups.Group && !Array.isArray(crudLookupAutoProcess.Groups.Group)))
            crudLookupAutoProcess.Groups.Group = [];
        crudLookupAutoProcess.Groups!.Group = JSON.parse(JSON.stringify(newArray));
    }
    
    return newArray;
}

function addGroups(item: MCAL_AutoProcess, toAdd: string[], crud: MCAutoListType) {
    if (!item.Groups)
        item.Groups = { Group: [] };
    if (!item.Groups.Group || (item.Groups.Group && !Array.isArray(item.Groups.Group)))
        item.Groups.Group = [];

    let newArray = item.Groups.Group.slice();
    for (var i = 0; i < toAdd.length; i++) {
        newArray.push({ '@ID': toAdd[i] });
    }

    // keep crud updated
    let crudLookupAutoProcess = crud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(ob => ob["@ID"] === item['@ID']);
    if (crudLookupAutoProcess) {
        if (!crudLookupAutoProcess.Groups)
            crudLookupAutoProcess.Groups = { Group: [] };
        if (!crudLookupAutoProcess.Groups.Group || (crudLookupAutoProcess.Groups.Group && !Array.isArray(crudLookupAutoProcess.Groups.Group)))
            crudLookupAutoProcess.Groups.Group = [];
        crudLookupAutoProcess.Groups!.Group = JSON.parse(JSON.stringify(newArray));
    }

    return newArray;
}

/* END GROUPS */

/* AUTOMODULES */

function moveAutoModules(up: boolean, item: MCAL_AutoProcess, crud: MCAutoListType, selections: string[]): MCAL_AutoModule[] {

    let crudLookupAutoProcess = crud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(ob => ob["@ID"] === item['@ID']);
    if (!crudLookupAutoProcess ||  // these should all be true at this point because selections is populated, from this list
        !crudLookupAutoProcess.AutoModules ||
        !crudLookupAutoProcess.AutoModules.AutoModule ||
        crudLookupAutoProcess.AutoModules.AutoModule.length <= 0) {
        return [];
    }
    let tempArray: MCAL_AutoModule[] = JSON.parse(JSON.stringify(crudLookupAutoProcess.AutoModules!.AutoModule));

    if (!selections || tempArray.length <= 1 || (selections && selections.length === 0 ||
        selections.length === tempArray.length))
        return tempArray;  // nothing selected or everything selected

    // since my selections don't know the order, and they may not be in order, build a sorted list of ids
    let orderHolder: string[] = [...Array(tempArray.length)].map(x => '');
    tempArray.forEach((taOb) => {
        let taPos = Number(taOb['@Order']);
        orderHolder[taPos - 1] = taOb['@ID'];
    });

    let fails: boolean = false;
    selections.forEach(id => {
        let crudOb = tempArray.find((x) => x['@ID'] === id);
        if (crudOb && !fails) {
            let orderOb = Number(crudOb['@Order']);

            if ((up && orderOb === 1) ||
                (!up && orderOb === tempArray.length))
                fails = true;
            else {
                let toFind = orderOb + (up ? -1 : 1);

                // swap them in the array
                let myTempOrderHolder = orderHolder[toFind - 1];
                orderHolder[toFind - 1] = orderHolder[orderOb - 1];
                orderHolder[orderOb - 1] = myTempOrderHolder;
            }
        }
    });

    if (fails)
        return tempArray;   // can't move up or down so bail on everything, and leave it alone

    // if we made it here, then orderHolder now has the new order, build it and store it
    let sortedArray: MCAL_AutoModule[] = [];
    for (let iii = 0; iii < orderHolder.length; iii++) {
        if (orderHolder[iii].length > 0) {
            sortedArray.push({ '@ID': orderHolder[iii], '@Order': (iii +1).toString() });
        }
    }

    // keep crud updated
    crudLookupAutoProcess.AutoModules!.AutoModule = sortedArray;
    return sortedArray;
}

function removeAutoModules(item: MCAL_AutoProcess, toRemove: string[], crud: MCAutoListType) {
    if (!item.AutoModules)
        item.AutoModules = { AutoModule: [] };
    if (!item.AutoModules.AutoModule || (item.AutoModules.AutoModule && !Array.isArray(item.AutoModules.AutoModule)))
        item.AutoModules.AutoModule = [];

    let newArray = item.AutoModules.AutoModule.slice();
    newArray = newArray.filter((el) => { return toRemove.indexOf(el['@ID']) === -1 });

    // keep crud updated
    let crudLookupAutoProcess = crud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(ob => ob["@ID"] === item['@ID']);
    if (crudLookupAutoProcess) {
        if (!crudLookupAutoProcess.AutoModules)
            crudLookupAutoProcess.AutoModules = { AutoModule: [] };
        if (!crudLookupAutoProcess.AutoModules.AutoModule || (crudLookupAutoProcess.AutoModules.AutoModule && !Array.isArray(crudLookupAutoProcess.AutoModules.AutoModule)))
            crudLookupAutoProcess.AutoModules.AutoModule = [];
        crudLookupAutoProcess.AutoModules!.AutoModule = JSON.parse(JSON.stringify(newArray));
    }

    return newArray;
}

function addAutoModules(item: MCAL_AutoProcess, toAdd: string[], crud: MCAutoListType) {
    if (!item.AutoModules)
        item.AutoModules = { AutoModule: [] };
    if (!item.AutoModules.AutoModule || (item.AutoModules.AutoModule && !Array.isArray(item.AutoModules.AutoModule)))
        item.AutoModules.AutoModule = [];

    let newArray = item.AutoModules.AutoModule.slice();
    let offset = newArray.length + 1;
    for (var i = 0; i < toAdd.length; i++) {
        newArray.push({ '@ID': toAdd[i], '@Order' : (i + offset).toString() });
    }

    // keep crud updated
    let crudLookupAutoProcess = crud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(ob => ob["@ID"] === item['@ID']);
    if (crudLookupAutoProcess) {
        if (!crudLookupAutoProcess.AutoModules)
            crudLookupAutoProcess.AutoModules = { AutoModule: [] };
        if (!crudLookupAutoProcess.AutoModules.AutoModule ||
            (crudLookupAutoProcess.AutoModules.AutoModule && !Array.isArray(crudLookupAutoProcess.AutoModules.AutoModule)))
            crudLookupAutoProcess.AutoModules.AutoModule = [];
        crudLookupAutoProcess.AutoModules.AutoModule = JSON.parse(JSON.stringify(newArray));
    }

    return newArray;
}

/* END AUTOMODULES */

function arrayObjectIndex(arr: any[], id: string) {
    for (var i = 0, len = arr.length; i < len; i++) {
        if (arr[i]['@ID'] === id) return i;
    }
    return -1;
}

function getArray_MCAL_Event(ob: any): MCAL_Event[] {
    if (ob.constructor === Array)
        return ob;
    else {
        if (ob && ob !== undefined) {
            let arr = new Array<MCAL_Event>();
            arr.push(ob);
            return arr;
        }
    }
    return ob;
}
// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IAutoListUIState, KnownActions> = (state: IAutoListUIState | undefined, action: KnownActions) => {
    if (state != undefined) {

        switch (action.type) {

            case 'SELECT_AUTOLIST':      
                if (action.data.masterCrud) {
                    var data = action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    //console.log('SELECT_AUTOLIST: ' + data);

                    if (data && data.Events && data.Events.Event && data.Events.Event.constructor !== Array)
                        data.Events.Event = getArray_MCAL_Event(data.Events.Event);

                    //console.log('setting autolistname to ' + action.data.uiData.text + ' from value of ' + state.selectedItem['@Name']);
                    return {
                        ...state,
                        selectedAutoList: action.data.uiData,            
                        selectedAllGroups: [],
                        selectedThisGroups: [],
                        selectedAllAutoModules: [],
                        selectedThisAutoModules: [],
                        selectedSchedule: emptySchedule,
                        selectedScheduleTab: "0",
                        selectedScheduleChanged: false,
                        selectedItem: data !== undefined ? JSON.parse(JSON.stringify(data)) : JSON.parse(JSON.stringify(emptyItem)),  // copy of the object
                        autoListNameError: undefined,
                        autoListLagDaysError: undefined,
                        autoListAutoModuleError: undefined,
                        submitNCSAlert: undefined,
                    }
                }
                break;

            case 'UPDATE_AUTOLIST_NAME':
         //       console.log('setting selectedItem[name] to ' + action.data.uiData.value + ' from ' + state.selectedItem['@Name']);
                if (state.selectedItem['@Name'] !== action.data.uiData.value) {
                    let temp = state.selectedItem['@Name'];
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@Name': action.data.uiData.value,
                            '@OriginalName': temp
                        },
                    }
                }
                break;

            case 'UPDATE_AUTOLIST_ENABLED':
        //        console.log('setting selectedItem[enabled] to ' + action.data.uiData.value + ' from ' + state.selectedItem['@Enabled']);
                if (state.selectedItem['@Enabled'] !== action.data.uiData.value)
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@Enabled': action.data.uiData.value
                        },
                    }
                break;

            case 'UPDATE_AUTOLIST_TRIGGEREVENT':
           //     console.log('setting selectedItem[TriggerEventID] to ' + action.data.uiData.value + ' from ' + state.selectedItem['@TriggerEventID']);
                if (state.selectedItem['@TriggerEventID'] !== action.data.uiData.value)
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@TriggerEventID': action.data.uiData.value
                        },
                    }
                break;

            case 'UPDATE_AUTOLIST_LIMITCLAIMSBYJOB':
         //      console.log('setting selectedItem[LimitClaimsByJob] to ' + action.data.uiData.value + ' from ' + state.selectedItem['@LimitClaimsByJob']);
                if (state.selectedItem['@LimitClaimsByJob'] !== action.data.uiData.value)
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@LimitClaimsByJob': action.data.uiData.value
                        },
                    }
                break;

         // START OF SCHEDULE STUFF
            case 'SELECT_SCHEDULE':
                if (action.data.masterCrud && state.selectedItem && state.selectedItem.Events && state.selectedItem.Events.Event) {
                    let schData = state.selectedItem.Events.Event.find(ob => { return ob["@ID"] === action.data.uiData.id });
      //              console.log(schData);
                    let tabIndex = "0";
                    if (schData !== undefined) {
                        if (action.data.uiData.text && action.data.uiData.text.length > 12) {
                            const testStr = action.data.uiData.text.substring(6, 11);
                            if (testStr === "Month" || testStr === "Quart")
                                tabIndex = "1";
                        }
                    }
                    return {
                        ...state,
                        selectedScheduleTab: tabIndex,
                        selectedScheduleChanged: false,
                        selectedSchedule: schData !== undefined ? schData : emptySchedule, 
                        autoListLagDaysError: undefined,
                    }    
                }
                break;

            case 'UPDATE_SCHEDULE_HR':
                if (action.data.uiData.value &&
                    action.data.uiData.value.length === 2 &&
                    state.selectedSchedule &&
                    state.selectedSchedule['@Time'] &&
                    state.selectedSchedule['@Time'].length > 6)
                {
                    let valHr = action.data.uiData.value + state.selectedSchedule['@Time'].substring(2);
                    console.log('setting time to ' + valHr);
                    return {
                        ...state,
                        selectedScheduleChanged: true,
                        selectedSchedule: {
                            ...state.selectedSchedule,
                            '@Time': valHr
                        },
                    }
                }
                break;

            case 'UPDATE_SCHEDULE_MIN':
                if (action.data.uiData.value &&
                    action.data.uiData.value.length === 2 &&
                    state.selectedSchedule &&
                    state.selectedSchedule['@Time'] &&
                    state.selectedSchedule['@Time'].length > 6)
                {
                    let tempvalMin = state.selectedSchedule['@Time'];
                    let valMin = tempvalMin.substring(0, 3) + action.data.uiData.value + tempvalMin.substring(5);
                    console.log('setting time to ' + valMin); 
                    return {
                        ...state,
                        selectedScheduleChanged: true,
                        selectedSchedule: {
                            ...state.selectedSchedule,
                            '@Time': valMin
                        }
                    }
                }
                break;

            case 'SELECT_TAB_SCHEDULE':
                if (state.selectedSchedule) {
          //          console.log(action.data.uiData.value);
                    return {
                        ...state,
                        selectedScheduleTab: action.data.uiData.value,
                       }
                }
                break;
                
            case 'UPDATE_AUTOLIST_LAGDAYS':
                if (state.selectedSchedule['@LagDays'] !== action.data.uiData.value) {
                    
                    return {
                        ...state,
                        selectedScheduleChanged: true,
                        selectedSchedule: {
                            ...state.selectedSchedule,
                            '@LagDays': action.data.uiData.value
                        }
                    }
                }
                break;

            case 'UPDATE_AUTOLIST_MONTHLYTOGGLE':
                if (action.data.uiData.value && action.data.uiData.value === "true" || action.data.uiData.value==="false") {
                    return {
                        ...state,
                        selectedScheduleChanged: true,
                        selectedSchedule: {
                            ...state.selectedSchedule,
                            '@Monthly': action.data.uiData.value === "true" ? "Y" : "N",
                            '@Quarterly': action.data.uiData.value === "true" ? "N" : "Y"
                        }
                    }
                }
                break;

            case 'UPDATE_DAYOFWEEK'  :             
                if (state.selectedSchedule) {

                    return {
                        ...state,
                        selectedScheduleChanged: true,
                        selectedSchedule: {
                            ...state.selectedSchedule,
                            [action.data.uiData.index] : (action.data.uiData.value ? "Y" : "N")
                        }
                    }
                }
                break;

            case 'REMOVE_AUTOLIST_SCHEDULE':
                if (action.data.masterCrud && state.selectedSchedule && action.data.uiData.value && action.data.uiData.value.length > 0) {
                    var removedSchedule = removeSchedule(state.selectedItem, action.data.uiData.value, action.data.masterCrud);

                    return {
                        ...state,
                        selectedSchedule: emptySchedule,
                        selectedScheduleTab: "0",
                        selectedScheduleChanged: false,
                        autoListLagDaysError: undefined,
                        autoListRemoveScheduleConfirm: undefined,
                        selectedItem:
                        {
                            ...state.selectedItem,
                            Events: {
                                ...state.selectedItem.Events,
                                Event: removedSchedule
                            }
                        },
                        changed: true,
                    }
                }
                break;

            case 'ADD_AUTOLIST_SCHEDULE':
            case 'UPDATE_AUTOLIST_SCHEDULE':
                if (action.data.masterCrud) { 
                    var newEvent = addSchedule(state.selectedItem, action.data.uiData, action.data.masterCrud, state.selectedScheduleTab === "1");

                    return {
                        ...state,
                        autoListLagDaysError: undefined,
                        selectedSchedule: JSON.parse(JSON.stringify(action.data.uiData)),
                        selectedItem:
                        {
                            ...state.selectedItem,
                            Events: {
                                ...state.selectedItem.Events,
                                Event: newEvent
                            }
                        },
                        changed: true,
                    }
                }
                break;
           
        // END OF SCHEDULE STUFF

            case 'SELECT_ALLGROUPS':
         //       console.log(action.data.uiData.selected);
                return {
                    ...state,
                    selectedAllGroups: action.data.uiData.selected,
                }
                break;
            case 'SELECT_THISGROUPS':
          //      console.log(action.data.uiData.selected);
                return {
                    ...state,
                    selectedThisGroups: action.data.uiData.selected,
                }
                break;

            case 'ADD_GROUPS':
                if (action.data.masterCrud &&
                    state.selectedAllGroups.length > 0) {
                    var allGroups = state.selectedAllGroups.slice();
                    var newGroup = addGroups(state.selectedItem, allGroups, action.data.masterCrud);
                    return {
                        ...state,
                        selectedAllGroups: [],
                        selectedThisGroups: allGroups,
                        selectedItem:
                        {
                            ...state.selectedItem,
                            Groups: {
                                ...state.selectedItem.Groups,
                                Group:  newGroup
                            }
                        },
                        changed: true,
                    }
                }
                break;
            
            case 'REMOVE_GROUPS':
                if (action.data.masterCrud &&
                    state.selectedThisGroups.length > 0 ) {
                    var thisGroups = state.selectedThisGroups.slice();
                    var removedGroup = removeGroups(state.selectedItem, thisGroups, action.data.masterCrud);
                    
                    return {
                        ...state,
                        selectedAllGroups: thisGroups,
                        selectedThisGroups: [],
                        selectedItem:
                        {
                            ...state.selectedItem,
                            Groups: {
                                ...state.selectedItem.Groups,
                                Group:
                                    removedGroup
                            }
                        },
                        changed: true,
                    }
                }
                break;

            case 'MOVE_AUTOMODULES_UP':
            case 'MOVE_AUTOMODULES_DOWN':
                if (action.data.masterCrud && state.selectedThisAutoModules.length > 0) {
                    let newSelectedAMs = moveAutoModules((action.type === 'MOVE_AUTOMODULES_UP'),  state.selectedItem, action.data.masterCrud, state.selectedThisAutoModules);
                    return {
                        ...state,
                        changed: true,
                        selectedItem:
                        {
                            ...state.selectedItem,
                            AutoModules: {
                                ...state.selectedItem.AutoModules,
                                AutoModule: newSelectedAMs
                            }
                        }, 
                    }
                }
                break;

            case 'ERROR_AUTOLIST_REMOVE_CONFIRM':
                return {
                    ...state,
                    autoListRemoveConfirm: action.data.uiData.value,
                }
                break;
            case 'ERROR_AUTOLIST_REMOVE_SCHEDULE_CONFIRM':
                return {
                    ...state,
                    autoListRemoveScheduleConfirm: action.data.uiData.value,
                }
                break;
            case 'ERROR_AUTOLIST_ALERT':
                return {
                    ...state,
                    autoListAlert: action.data.uiData.value,
                }
                break;

            case 'ERROR_AUTOLIST_FIELD':
                var errorMsg = "";
                if (action.data.uiData.id && action.data.uiData.id.length > 0) {
                    switch (action.data.uiData.id) {
                        case 'autoListNameError':
                            return {
                                ...state,
                                autoListNameError: action.data.uiData.value,
                            }
                            break;
                        case 'autoListAutoModuleError':
                            return {
                                ...state,
                                autoListAutoModuleError: action.data.uiData.value,
                            }
                            break;
                    }
                } else {
                    switch (action.data.uiData.value) {
                        case "NameError1":
                            return {
                                ...state,
                                autoListNameError: NAME_ERROR_1,
                            }
                            break;
                        case "LagDaysError1":
                        case "LagDaysError2":
                        case "LagDaysError3":
                            if (action.data.uiData.value === "LagDaysError1")
                                errorMsg = LAGDAYS_ERROR_1;
                            else if (action.data.uiData.value === "LagDaysError2")
                                errorMsg = LAGDAYS_ERROR_2;
                            else if (action.data.uiData.value === "LagDaysError3")
                                errorMsg = LAGDAYS_ERROR_3;

                            if (errorMsg !== "") {
                                return {
                                    ...state,
                                    autoListLagDaysError: errorMsg,
                                }
                            }
                            break;
                    }
                }
                break;

            case 'SELECT_ALLAUTOMODULES':
                return {
                    ...state,
                    selectedAllAutoModules: action.data.uiData.selected,
                }
                break;
            case 'SELECT_THISAUTOMODULES':
                return {
                    ...state,
                    selectedThisAutoModules: action.data.uiData.selected,
                }
                break;

            case 'ADD_AUTOMODULES':
                if (action.data.masterCrud &&
                    state.selectedAllAutoModules.length > 0) {
                    var allAutoModules = state.selectedAllAutoModules.slice();
                    var newAutoModules = addAutoModules(state.selectedItem, allAutoModules, action.data.masterCrud);
                    return {
                        ...state,
                        selectedAllAutoModules: [],
                        selectedThisAutoModules: allAutoModules,
                        selectedItem:
                        {
                            ...state.selectedItem,
                            AutoModules: {
                                ...state.selectedItem.AutoModules,
                                AutoModule: newAutoModules
                            }
                        },
                        changed: true,
                    }
                }

                break;

            case 'REMOVE_AUTOMODULES':
                if (action.data.masterCrud &&
                    state.selectedThisAutoModules.length > 0) {
                    var thisAutoModules = state.selectedThisAutoModules.slice();
                    var removedAutoModules = removeAutoModules(state.selectedItem, thisAutoModules, action.data.masterCrud);

                    return {
                        ...state,
                        selectedAllAutoModules: thisAutoModules,
                        selectedThisAutoModules: [],
                        selectedItem:
                        {
                            ...state.selectedItem,
                            AutoModules: {
                                ...state.selectedItem.AutoModules,
                                AutoModule: removedAutoModules
                            }
                        },
                        changed: true,
                    }
                }

                break;

            case 'ADD_AUTOLIST':
                if (action.data.masterCrud) {
                    action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess =
                        upsertAutoProcess(action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess, action.data.uiData);

                    //                   console.log(action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess);

                    return {
                        ...state,
                        selectedAutoList: {
                            //     index: action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.length,
                            id: action.data.uiData["@ID"],
                            text: action.data.uiData["@Name"]
                        },
                        selectedItem: action.data.uiData,
                        changed: true,
                        selectedSchedule: emptySchedule,
                        selectedScheduleTab: "0",
                        selectedScheduleChanged: false,
                        selectedAllGroups: [],
                        selectedThisGroups: [],
                        selectedAllAutoModules: [],
                        selectedThisAutoModules: [],
                        autoListNameError: undefined,
                        autoListAutoModuleError: undefined,
                    }
                }
                break;
            case 'UPDATE_AUTOLIST':
                if (action.data.masterCrud) {
                    action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess =
                        upsertAutoProcess(action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess, action.data.uiData);

 //                   console.log(action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess);

                    return {
                        ...state,
                        selectedAutoList: {
                            //     index: action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.length,
                            id: action.data.uiData["@ID"],
                            text: action.data.uiData["@Name"]
                        },
                        selectedItem: action.data.uiData,
                        changed: true,
                        autoListNameError: undefined,

                    }
                }
                break;

            case 'REMOVE_AUTOLIST':
                if (action.data.masterCrud) {
                    action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess =
                        removeAutoProcess(action.data.masterCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess, action.data.uiData);

                    return {
                        ...state,
                        selectedAutoList: { id:'', text:''},
                        selectedItem: JSON.parse(JSON.stringify(emptyItem)),
                        selectedSchedule: emptySchedule,
                        selectedScheduleTab: "0",
                        selectedScheduleChanged: false,
                        selectedAllGroups: [],
                        selectedThisGroups: [],
                        selectedAllAutoModules: [],
                        selectedThisAutoModules: [],
                        autoListNameError: undefined,
                        autoListRemoveConfirm: undefined,
                        autoListRemoveScheduleConfirm: undefined,
                        autoListAutoModuleError: undefined,
                        changed: true,
                    }
                }
                break;

            case 'NCS_ALERT':
                return {
                    ...state,
                    submitNCSAlert: action.data.uiData.value,
                }
                break;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
