import * as React from 'react';
import { Colors } from '../../../commonResources/colorVariables';
import styled from 'styled-components';


export interface IARMMessageStyleProps {
    ciamLogin?: boolean;
};

const ARMMessageStyle = styled.div`
   
 ${(props: IARMMessageStyleProps) => props?.ciamLogin ? ` padding: 0 0 5px 0;` : ` padding: 0 0 20px 0;`};
    white-space: pre-line;
    font-size: 12px;
    color: ${Colors.black};
    width:100%;

    .message-error {
        color: ${Colors.errorRed};
        font-weight: bold
    }

    .message-bold {
        font-weight: bold
    }
`;

export interface IARMMessageProps {
    message: string;
    isError?: boolean;
    isBold?: boolean;
    ciamLogin?: boolean;
};

export class ARMMessage extends React.PureComponent<IARMMessageProps> {
    static defaultProps: IARMMessageProps = {
        message: '',
        isError: false,
        isBold: false,
        ciamLogin:false
    };

    constructor(props: IARMMessageProps) {
        super(props);
    }

    public render() {
        return (
            <ARMMessageStyle ciamLogin={this.props?.ciamLogin}>
                <span className={this.props.isError ? "message-error" : (this.props.isBold ? "message-bold" : "")}>
                    {this.props.message}
                </span>
            </ARMMessageStyle>
        );
    }
};
