import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as UseFileStore from './api/UseFileStore';


export type IUseFileState = IApiComponentState<any, UseFileStore.IUseFileUIState>;

export const actionCreators = createApiComponentActions<UseFileStore.ActionCreators>(UseFileStore.actionCreators);
export type IUseFileActions = typeof actionCreators;
export type IUseFileActionProps = IApiComponentActionProps<IUseFileActions>

export const reducers = createApiComponentReducers("useFile", UseFileStore.reducer);
export const reducer = combineReducers<IUseFileState>(reducers);