import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { map } from 'lodash';
import { DialogWrapper, OKCancelButtons, ContentColumnWrapper } from '@common/DialogWrapper';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IManageCrossoversState, IManageCrossoversActionProps, actionCreators, validationCallback } from '@store/ManageCrossoversStore';
import { CrossoverPayer } from '@store/ui/ManageCrossoversUI';
import { handleChange, pageLeave } from '@commonResources/userModified';
import CrudTypes from '@commonResources/CrudTypes';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { Input } from 'ui-core';
import { DropdownChoiceGrid, IDCG_Element } from '@common/DropdownChoiceGrid';
import { ArrowButtons } from '@common/ArrowButtons';
import AssuranceMenu from '../../common/AssuranceMenu';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const PayerListAvailable = styled.div`
    padding-left: 10px;
    margin-left: 25px;
    padding: 5px;
`;

export const PayerListEnabled = styled.div`
    padding: 5px;
`;

export interface IDCG_Payer {
    ID: string,
    Name: string,
    CPID: string,
    Sop: string
}

interface IComponentProps {
    canView: boolean;
};

interface IComponentState {
    files: any;
    isBusy: boolean;
    isAlertOpen: boolean;
    alertMessage: string;
    cancelLeave: boolean,
    filterString: string; 
};

export const DEFAULT_STATE: IComponentState = {
    files: new FormData(),
    isBusy: false,
    isAlertOpen: false,
    alertMessage: '',
    cancelLeave: false,
    filterString: ''
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IManageCrossoversProps = IMergeCrudComponentProps<IManageCrossoversState, IManageCrossoversActionProps, IOwnProps>;

export class ManageCrossovers extends React.Component<IManageCrossoversProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canView: false,
    };

    constructor(props: IManageCrossoversProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiParallonManageCrossovers },
            (result: MCADRCoverLetter) => {
                if (validationCallback(result)) {
                    this.props.action.ui.initalizeMCOptions({
                        masterCrud: result,
                        uiData: result.CrossOverPayerManagement,
                    });
                    return true;
                }
            });
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public errorManageCrossoversCallback(dataStatus: any): boolean {
        var str = 'An error during Manage Crossovers processing call for Error [' + dataStatus + ']';
        console.error(str);
        return true;
    }

    public onMCSubmit(e: React.ChangeEvent<HTMLButtonElement>) {
        console.warn('onMCSubmit()');

        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.CrossOverPayerManagement.EnabledPayers &&
            this.props.dataStore.crud.data.CrossOverPayerManagement.EnabledPayers.EnabledPayer) {
            let EnabledPayers: any = this.props.dataStore?.crud?.data?.CrossOverPayerManagement?.EnabledPayers;
            if (EnabledPayers) {
                console.log('EnabledPayers: we leaving and saving...');
            }

            var enabledPayers =
            {
                EnabledPayers
            };

            this.props.action.crud.update({ crudId: CrudTypes.mctiParallonManageCrossovers, data: enabledPayers });
            this.props.history.push("/LandingPage");
        }
    }

    public onMCCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        console.warn('onMCCancel()');
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public validateManageCrossoverCrud(data: any): boolean {
        let dataCopy: any = data as any;
        return true;
    }

    public onSelectAvailableMCPayer(e: React.ChangeEvent<HTMLSelectElement>) {
        let newAvailablePayers = map(e.target.selectedOptions, 'value');
        this.props.action.ui.selectMCDisabledRecords(newAvailablePayers);
    }

    public onSelectEnabledMCPayer(record: IDCG_Element) {
        this.props.action.ui.selectMCEnabledRecord(record.ID)
    }

    public onRemoveMCPayer(e: React.ChangeEvent<HTMLButtonElement>)
    {
        this.props.action.ui.disableMCPayer({ masterCrud: this.props.dataStore.crud.data, uiData: this.props.dataStore.ui.selectedEnabledPayerId });
    }

    public onAddMCPayer(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.enableMCPayers({ masterCrud: this.props.dataStore.crud.data, uiData: this.props.dataStore.ui.selectedDisabledPayerIds})
    }

    public handleFilter = (str: string) => {
        const { disabledPayers } = this.props.dataStore.ui;
        if (!str) {
            this.props.action.ui.selectMCDisabledRecords([]);
        } else {
            let updatedPayers = disabledPayers.filter((payer: CrossoverPayer) => {
                return payer.name.toLowerCase().startsWith(str.toLowerCase()) == true;
            }).map((payer: CrossoverPayer) => payer.id)
            this.props.action.ui.selectMCDisabledRecords(updatedPayers);
        }
        this.setState({ filterString: str })
    }

    public render() {
        const { dataStore } = this.props;
        var buttons = <OKCancelButtons disableOK={!this.props.canView || this.state.isBusy} disableCancel={this.state.isBusy} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onMCSubmit(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onMCCancel(e)} />;

        return (
            <DialogWrapper title='Manage Crossovers' helpUrl='/Support/Help/HELP_Maint_ManageCrossovers.htm' buttons={buttons} width='fit-content' >
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <PayerListAvailable>
                        <DialogFieldset width='40%'>
                            <DialogLegend>Available Payers (CPID - SOP)</DialogLegend>
                            <ContentColumnWrapper>
                                <Input id='mc-search-input' autoComplete={'off'} value={this.state.filterString} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleFilter(e.target.value)} style={{ marginBottom: '5px' }} />
                                <SelectComponent
                                    autoScrollToSelection={true}
                                    size={13}
                                    width='370px'
                                    multiple={'true'}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        this.onSelectAvailableMCPayer(e)
                                    }}
                                    selectedMultiValue={dataStore.ui.selectedDisabledPayerIds}
                                    optionFields={
                                        {
                                            value: 'id',
                                            text: 'label'
                                        }
                                    }
                                    //  selectedValue={this.props.dataStore.ui.selectedItem['@ModuleID']}
                                    records={dataStore.ui.disabledPayers}
                                />
                            </ContentColumnWrapper>
                        </DialogFieldset>
                    </PayerListAvailable>
                    <ArrowButtons
                        paddingTop='150px'
                        width='60px'
                        margin='0 20px 0 0'
                        toolTipRight=' Add Payers(s) to Enabled Payers'
                        toolTipLeft=' Remove Payers(s) from Enabled Payers'
                        onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                            handleChange()
                            this.onRemoveMCPayer(e)}}
                        onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                            handleChange()
                            this.onAddMCPayer(e)}} />
                    <PayerListEnabled>
                        <DialogFieldset>
                            <DialogLegend>Enabled Payers (CPID - SOP)</DialogLegend>
                            <DropdownChoiceGrid
                                width='350px'
                                height='300px'
                                headerLabels={[{ label: 'Payers' }, { label: 'Threshold' }]}
                                records={this.props.dataStore.ui.enabledPayers}
                                selectedID={this.props.dataStore.ui.selectedEnabledPayerId}
                                crudData={this.props.dataStore.crud.data}
                                onItemSelected={(e: IDCG_Element) => this.onSelectEnabledMCPayer(e)}
                                onChangeThreshold={(e: IDCG_Element) => {
                                    handleChange()
                                    this.props.action.ui.editMCPayerThreshold({ masterCrud: this.props.dataStore.crud.data, uiData: { payerId: e.ID, threshold: e.Threshold } })
                                }}
                            />
                        </DialogFieldset>
                    </PayerListEnabled>
                </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IManageCrossoversState, IManageCrossoversActionProps, IOwnProps, IManageCrossoversProps, ApplicationState>(
    createCrudMapStateToProps('manageCrossovers'),
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ManageCrossovers);

export default withRouter(connectedHoc);
