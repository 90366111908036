import {Reducer} from 'redux';
import {ActionTypes, createAction, createDataAction} from '@scripts/util/ActionHelpers';
import {ICrudActionData} from "@scripts/util/CrudComponentHelpers"
import {deepCopyFunction} from "@commonResources/validations";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IGroupMasterUIState {
    selectedGroupMaster: IGroupMasterUIData; // left side selection
    selectedItem: MCGroup;
    selectedAvailableUsers: string[],
    selectedSelectedUsers: string[],
    selectedAvailableFunctions: string[],
    selectedSelectedFunctions: string[],
    changed: boolean;
    groupMasterNameError?: string;
    groupMasterRemoveConfirm?: string;
    submitNCSAlert?: string;
    deltaCrud: MCGroupDeltaType; 
}

interface IGroupMasterUIFieldUpdate {
    value: string;
}

interface IGroupMasterUIError {
    value: string;
    id?: string;
}

const emptyItem: MCGroup = {
    '@ID': '',
    '@Name': '',
    Functions: { Function: [] },
    Users: { User: [] }
};

const emptyDelta: MCGroupDeltaType = {
    GroupMaintenanceInfo: {
        Groups: { }
    }
}

export interface IGroupMasterUIData {
    id: string;
    text: string;
};

export const defaultGroupMaster: IGroupMasterUIData = {
    id: '',
    text: '',
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

//to-do: 1 of these lines for every action that needs to be performed
export interface ISelectGroupMaster extends ICrudActionData<MCGroupMasterType, IGroupMasterUIData> { }
export interface IAddRemoveButtons extends ICrudActionData<MCGroupMasterType, boolean> { }
interface IGroupMasterUIUsersSelected {
    selectedAvailableUsers: string[];
    selectedSelectedUsers: string[];
}
interface IGroupMasterUIFunctionsSelected {
    selectedAvailableFunctions: string[];
    selectedSelectedFunctions: string[];
}
export interface ISelectUserList extends ICrudActionData<MCGroupMasterType, IGroupMasterUIUsersSelected> { }
export interface ISelectFunctionList extends ICrudActionData<MCGroupMasterType, IGroupMasterUIFunctionsSelected> { }
export interface IUpdateGroupMasterField extends ICrudActionData<MCGroupMasterType, IGroupMasterUIFieldUpdate> { }
export interface IAddModifyGroupMaster extends ICrudActionData<MCGroupMasterType, MCGroup> { }
export interface IError extends ICrudActionData<MCGroupMasterType, IGroupMasterUIError> { }
export interface IRemoveGroupMaster extends ICrudActionData<MCGroupMasterType, MCGroup> { }

export const actionCreators = {
    selectGroupMaster: (selectInfo: ISelectGroupMaster) => createDataAction('GROUP_MASTER_SELECT', selectInfo),
    selectAvailableUsers: (selectUsers: ISelectUserList) => createDataAction('GROUP_MASTER_SELECT_AVAILABLE_USERS', selectUsers),
    selectSelectedUsers: (selectUsers: ISelectUserList) => createDataAction('GROUP_MASTER_SELECT_SELECTED_USERS', selectUsers),
    selectAvailableFunctions: (selectUsers: ISelectFunctionList) => createDataAction('GROUP_MASTER_SELECT_AVAILABLE_FUNCTIONS', selectUsers),
    selectSelectedFunctions: (selectUsers: ISelectFunctionList) => createDataAction('GROUP_MASTER_SELECT_SELECTED_FUNCTIONS', selectUsers),
    addUsers: (nothing: IAddRemoveButtons) => createDataAction('GROUP_MASTER_ADD_USERS', nothing),
    removeUsers: (nothing: IAddRemoveButtons) => createDataAction('GROUP_MASTER_REMOVE_USERS', nothing),
    addFunctions: (nothing: IAddRemoveButtons) => createDataAction('GROUP_MASTER_ADD_FUNCTIONS', nothing),
    removeFunctions: (nothing: IAddRemoveButtons) => createDataAction('GROUP_MASTER_REMOVE_FUNCTIONS', nothing),
    updateGroupMaster: (updateInfo: IAddModifyGroupMaster) => createDataAction('GROUP_MASTER_UPDATE', updateInfo),
    updateGroupMasterName: (fieldInfo: IUpdateGroupMasterField) => createDataAction('GROUP_MASTER_UPDATE_NAME', fieldInfo),
    addGroupMaster: (addInfo: IAddModifyGroupMaster) => createDataAction('GROUP_MASTER_ADD', addInfo),
    errorGroupMasterField: (fieldInfo: IError) => createDataAction('GROUP_MASTER_ERROR_FIELD', fieldInfo),
    errorGroupMasterRemoveConfirm: (fieldInfo: IUpdateGroupMasterField) => createDataAction('GROUP_MASTER_ERROR_REMOVE_CONFIRM', fieldInfo),
    removeGroupMaster: (removeInfo: IRemoveGroupMaster) => createDataAction('GROUP_MASTER_REMOVE', removeInfo),
    sendSubmitNCSAlert: (fieldInfo: IUpdateGroupMasterField) => createDataAction('NCS_ALERT', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IGroupMasterUIState = {
    selectedGroupMaster: defaultGroupMaster,
    changed: false,
    selectedItem: emptyItem,
    selectedAvailableUsers: [],
    selectedSelectedUsers: [],
    selectedAvailableFunctions: [],
    selectedSelectedFunctions: [],
    groupMasterNameError: undefined,
    submitNCSAlert: '',
    deltaCrud: deepCopyFunction(emptyDelta)
};

function upsertGroupMaster(groups: MCGroup[], upsertGroup: MCGroup) {
    let modifiedGroups = groups.slice();
    var existingGroup = modifiedGroups.find(group => group['@ID'] === upsertGroup['@ID']);

    // if group exists
    if (existingGroup) {
        // update the existing group
        existingGroup['@Name'] = upsertGroup['@Name'];
        existingGroup.Users = upsertGroup.Users;
        existingGroup.Functions = upsertGroup.Functions;
    } else {
        // else insert the new group
        modifiedGroups.splice(groups.length, 0, JSON.parse(JSON.stringify(upsertGroup)));
    }
    return modifiedGroups;
}

function upsertGroupMasterDelta(deltaGroups: MCGroupDelta[] | undefined, upsertGroup: MCGroup, existingGroup: MCGroup | undefined) {
    if(!deltaGroups)
        deltaGroups = [];
    let existingDeltaGroup = deltaGroups.find(group => group['@ID'] === upsertGroup['@ID']);

    // if group exists
    if (existingDeltaGroup) {
        // update the existing group, dont add an 'oldName' if it's new
        if (existingGroup && existingGroup["@Name"])
            if (!existingDeltaGroup["@OldName"] && existingDeltaGroup["@ID"].indexOf("#") === -1)
                existingDeltaGroup['@OldName'] = existingGroup["@Name"];
            
        existingDeltaGroup['@Name'] = upsertGroup['@Name'];
    } else {
        // else insert the new group only for delta
        let newDeltaGroup: MCGroupDelta = {
            "@ID": upsertGroup["@ID"],
            "@Name": upsertGroup['@Name'],
        }
        if (existingGroup && existingGroup["@Name"])
            newDeltaGroup["@OldName"] = existingGroup["@Name"];

        deltaGroups.push(newDeltaGroup)
    }

    return deltaGroups;
}

function removeGroupMaster(groups: MCGroup[], groupToRemove: MCGroup) {
    // find the requested group to remove
    const groupToRemoveFound = groups.slice().filter(group => group['@ID'] === groupToRemove['@ID']);

    // if requested group to remove is not found
    if (!groupToRemoveFound) {

        const groupToRemoveId = groupToRemove['@ID'];
        const groupToRemoveName = groupToRemove['@Name'];
        const message = `attempt to remove an invalid entry ${groupToRemoveId} ${groupToRemoveName}`;
        console.log(message);
    }

    return groups.slice().filter(group => group['@ID'] !== groupToRemove['@ID']);
}

function removeGroupMasterDelta(deltaGroups: MCGroupDelta[] | undefined, groupToRemove: MCGroup) {
    //keep delta updated
    if(!deltaGroups)
        deltaGroups = [];
    let deltaGroupRemoveFound = deltaGroups.find(group => group['@ID'] === groupToRemove['@ID']);
    let originalName : string | undefined;
    
    if (deltaGroupRemoveFound) {
        originalName = deltaGroupRemoveFound["@OldName"];
      
        deltaGroups = deltaGroups.slice().filter(group => group['@ID'] !== groupToRemove['@ID']);
        // it's new, don't add it back
        if(deltaGroupRemoveFound["@ID"].indexOf("#") > -1) 
            return deltaGroups;
    }
    // add just the delete element back in for the group-level
    let deleteDeltaGroup: MCGroupDelta = {
        "@ID": groupToRemove["@ID"],
        "@Name": originalName ? originalName : groupToRemove["@Name"],
        "@Delete": "true"
    };
    if(deleteDeltaGroup["@OldName"]) {
        deleteDeltaGroup["@Name"] = deleteDeltaGroup["@OldName"];
        delete deleteDeltaGroup["@OldName"];
    }
    
    deltaGroups.push(deleteDeltaGroup);
    return deltaGroups;
}

function addUsers(existingGroup: MCGroup, newGroupUsers: string[], crud: MCGroupMasterType) {
    // ensure that group users array is initialized
    ensureGroupUsersArray(existingGroup);

    // create a variable for modified users set
    let modifiedGroupUsers = existingGroup.Users.User.slice();

    // push the new group users to the existing group users array
    for (var i = 0; i < newGroupUsers.length; i++) {
        modifiedGroupUsers.push({
            '@ID': newGroupUsers[i],
            '@FirstName': '',
            '@LastName': '',
            '@UserName': '',
            '_ID': newGroupUsers[i],
            '_FirstName': '',
            '_LastName': '',
            '_UserName': ''
        });
    }

    // get the crud group
    let crudGroup = crud.GroupMaintenanceInfo.Groups.Group.find(
        group => group["@ID"] === existingGroup['@ID']);

    if (crudGroup) {
        ensureGroupUsersArray(crudGroup);

        // set the crud group users to the modified set of group users
        crudGroup.Users!.User = JSON.parse(JSON.stringify(modifiedGroupUsers));
    }
    return modifiedGroupUsers;
}

function addUsersDelta(existingGroup: MCGroup, newGroupUsers: string[], deltaGroups: MCGroupDelta[] | undefined){
    if(!deltaGroups)
        deltaGroups = [];
    let existingDeltaGroup = deltaGroups?.find(group => group['@ID'] === existingGroup['@ID']);

    if(existingDeltaGroup){
        let deltaGroupUsers = existingDeltaGroup.Users?.User.slice();
        deltaGroupUsers = deltaGroupUsers ? deltaGroupUsers : [];
        for (let i = 0; i < newGroupUsers.length; i++) {
            let existingDeltaUser = deltaGroupUsers.find(user => user["@ID"] === newGroupUsers[i]);
            if(existingDeltaUser) // it was deleted and added back; remove it
                deltaGroupUsers = deltaGroupUsers.slice().filter(user => user["@ID"] !== newGroupUsers[i]);
            else
                deltaGroupUsers.push({
                    '@ID': newGroupUsers[i]
                });
        }
        if(!existingDeltaGroup.Users)
            existingDeltaGroup.Users = { User: [] };
            existingDeltaGroup.Users.User = deepCopyFunction(deltaGroupUsers);
    }
    else{
        let newDeltaGroup : MCGroupDelta = {
            "@ID" : existingGroup["@ID"]
        }        
        newDeltaGroup.Users = { User: [] };
        let newDeltaGroupUsers : MCGroupUserDelta[] = [];
        for (let i = 0; i < newGroupUsers.length; i++) {
            newDeltaGroupUsers.push({
                '@ID': newGroupUsers[i]
            });
        }
        newDeltaGroup.Users.User = newDeltaGroupUsers;
        deltaGroups.push(newDeltaGroup);        
    }
    
    return deltaGroups;
}

function removeUsers(group: MCGroup, usersToRemove: string[], crud: MCGroupMasterType) {
    // ensure group users array
    ensureGroupUsersArray(group);

    // initialize set of group users to modify
    let modifiedGroupUsers = group.Users.User.slice();

    // filter out the users to remove
    modifiedGroupUsers = modifiedGroupUsers.filter((groupUser) => { return usersToRemove.indexOf(groupUser['@ID']) === -1 });

    // get the crud data group
    let crudGroup = crud.GroupMaintenanceInfo.Groups.Group.find(crudGroups => crudGroups["@ID"] === group['@ID']);
    if (crudGroup) {
        ensureGroupUsersArray(crudGroup);

        // set the crud group with the modified set of group users
        crudGroup.Users!.User = JSON.parse(JSON.stringify(modifiedGroupUsers));
    }

    return modifiedGroupUsers;
}

function removeUsersDelta(existingGroup: MCGroup, removeGroupUsers: string[], deltaGroups: MCGroupDelta[] | undefined ){
    if(!deltaGroups)
        deltaGroups = [];
    let existingDeltaGroup = deltaGroups.find(group => group['@ID'] === existingGroup['@ID']);

    if(existingDeltaGroup){
        let deltaGroupUsers = existingDeltaGroup.Users?.User.slice();
        //let newDeltaUsers = deepCopyFunction(deltaGroupUsers);
        deltaGroupUsers = deltaGroupUsers ? deltaGroupUsers : [];
        for (let i = 0; i < removeGroupUsers.length; i++) {
            let existingDeltaUser = deltaGroupUsers.find(user => user["@ID"] === removeGroupUsers[i]);
            if(existingDeltaUser) {
                // was it added and removed in delta - just remove it
                deltaGroupUsers = deltaGroupUsers.slice().filter(user => user["@ID"] !== removeGroupUsers[i]);
            }
            else
                deltaGroupUsers.push(
                    {
                        "@ID" : removeGroupUsers[i],
                        "@Delete": "true"
                    });
        }
        if(deltaGroupUsers.length > 0) {
            if (!existingDeltaGroup.Users)
                existingDeltaGroup.Users = {User: []};
            
            existingDeltaGroup.Users.User = deepCopyFunction(deltaGroupUsers);
        }
        else {
            if(existingDeltaGroup.Users)
                delete existingDeltaGroup.Users;
        }

    }
    else{
        let newDeltaGroup : MCGroupDelta = {
            "@ID" : existingGroup["@ID"]
        }
        newDeltaGroup.Users = { User: [] };
        let newDeltaGroupUsers : MCGroupUserDelta[] = [];
        for (let i = 0; i < removeGroupUsers.length; i++) {
            newDeltaGroupUsers.push({
                "@ID": removeGroupUsers[i],
                "@Delete": "true"
            });
        }
        newDeltaGroup.Users.User = newDeltaGroupUsers;
        deltaGroups.push(newDeltaGroup);
    }

    return deltaGroups;
}

function addFunctions(group: MCGroup, functionsToAdd: string[], crud: MCGroupMasterType) {
    ensureGroupFunctionsArray(group);

    // initialize the set of group functions to modify
    let modifiedGroupFunctions = group.Functions.Function.slice();

    // push the selected function ids onto the set of functions
    for (var i = 0; i < functionsToAdd.length; i++) {
        modifiedGroupFunctions.push({ '@ID': functionsToAdd[i] });
    }

    // get the crud group
    let crudGroup = crud.GroupMaintenanceInfo.Groups.Group.find(ob => ob["@ID"] === group['@ID']);
    if (crudGroup) {
        ensureGroupFunctionsArray(crudGroup);

        // set the crud group functions to the modified set of group functions
        crudGroup.Functions!.Function = JSON.parse(JSON.stringify(modifiedGroupFunctions));
    }

    return modifiedGroupFunctions;
}

function addFunctionsDelta(existingGroup: MCGroup, newFunctionUsers: string[], deltaGroups: MCGroupDelta[] | undefined){
    if(!deltaGroups)
        deltaGroups = [];
    let existingDeltaGroup = deltaGroups?.find(group => group['@ID'] === existingGroup['@ID']);

    if(existingDeltaGroup){
        let deltaGroupFunctions = existingDeltaGroup.Functions?.Function.slice();
        deltaGroupFunctions = deltaGroupFunctions ? deltaGroupFunctions : [];
        for (let i = 0; i < newFunctionUsers.length; i++) {
            let existingDeltaUser = deltaGroupFunctions.find(func => func["@ID"] === newFunctionUsers[i]);
            if(existingDeltaUser) // it was deleted and added back; remove it
                deltaGroupFunctions = deltaGroupFunctions.slice().filter(func => func["@ID"] !== newFunctionUsers[i]);
            else
                deltaGroupFunctions.push({
                    '@ID': newFunctionUsers[i]
                });
        }
        if(!existingDeltaGroup.Functions)
            existingDeltaGroup.Functions = { Function: [] };
        
        existingDeltaGroup.Functions.Function = deepCopyFunction(deltaGroupFunctions);
    }
    else{
        let newDeltaGroup : MCGroupDelta = {
            "@ID" : existingGroup["@ID"]
        }
        newDeltaGroup.Functions = { Function: [] };
        let newDeltaGroupUsers : MCGroupFunctionDelta[] = [];
        for (let i = 0; i < newFunctionUsers.length; i++) {
            newDeltaGroupUsers.push({
                '@ID': newFunctionUsers[i]
            });
        }
        newDeltaGroup.Functions.Function = newDeltaGroupUsers;
        deltaGroups.push(newDeltaGroup);
    }

    return deltaGroups;
}

function removeFunctions(group: MCGroup, functionsToRemove: string[], crud: MCGroupMasterType) {
    ensureGroupFunctionsArray(group);

    // initialize the set of group functions to modify
    let modifiedGroupFunctions = group.Functions.Function.slice();

    // filter the group functions to remove the specified functions to remove
    modifiedGroupFunctions = modifiedGroupFunctions.filter(
        (groupFunctions) => { return functionsToRemove.indexOf(groupFunctions['@ID']) === -1 });

    // get the crud group
    let crudGroup = crud.GroupMaintenanceInfo.Groups.Group.find(ob => ob["@ID"] === group['@ID']);
    if (crudGroup) {

        // set the crud group functions to the modified set of group functions
        crudGroup.Functions!.Function = JSON.parse(JSON.stringify(modifiedGroupFunctions));
    }

    return modifiedGroupFunctions;
}

function removeFunctionsDelta(existingGroup: MCGroup, removeGroupFunctions: string[], deltaGroups: MCGroupDelta[] | undefined ){
    if(!deltaGroups)
        deltaGroups = [];
    let existingDeltaGroup = deltaGroups.find(group => group['@ID'] === existingGroup['@ID']);

    if(existingDeltaGroup){
        let deltaGroupFunctions = existingDeltaGroup.Functions?.Function.slice();
        //let newDeltaUsers = deepCopyFunction(deltaGroupFunctions);
        deltaGroupFunctions = deltaGroupFunctions ? deltaGroupFunctions : [];
        for (let i = 0; i < removeGroupFunctions.length; i++) {
            let existingDeltaFunc = deltaGroupFunctions.find(func => func["@ID"] === removeGroupFunctions[i]);
            if(existingDeltaFunc) {
                // was it added and deleted in delta - just remove it
                deltaGroupFunctions = deltaGroupFunctions.slice().filter(func => func["@ID"] !== removeGroupFunctions[i]);
            }
            else
                deltaGroupFunctions.push(
                    {
                        "@ID" : removeGroupFunctions[i],
                        "@Delete": "true"
                    });
        }
        if(deltaGroupFunctions.length > 0) {
            if (!existingDeltaGroup.Functions)
                existingDeltaGroup.Functions = {Function: []};
            
            existingDeltaGroup.Functions.Function = deepCopyFunction(deltaGroupFunctions);
        }
        else{ // they were all removed so you dont need this element
            if(existingDeltaGroup.Functions)
                delete existingDeltaGroup.Functions;
        }
    }
    else{
        let newDeltaGroup : MCGroupDelta = {
            "@ID" : existingGroup["@ID"]
        }
        newDeltaGroup.Functions = { Function: [] };
        let newDeltaGroupUsers : MCGroupFunctionDelta[] = [];
        for (let i = 0; i < removeGroupFunctions.length; i++) {
            newDeltaGroupUsers.push({
                "@ID": removeGroupFunctions[i],
                "@Delete": "true"
            });
        }
        newDeltaGroup.Functions.Function = newDeltaGroupUsers;
        deltaGroups.push(newDeltaGroup);
    }

    return deltaGroups;
}

function trimDeltaGroups(deltaCrud: MCGroupDeltaType){
    // if the function and group lists are empty, remove the group if it's not new and the name wasn't updated
    let deltaGroups = deltaCrud.GroupMaintenanceInfo.Groups.Group;
    if(!deltaGroups)
        return;
    
    let trimmedDelta = [];

    for (let i = 0; i < deltaGroups.length; i++) {
        let validGroup = deltaGroups[i]["@Delete"] || deltaGroups[i]["@OldName"] || deltaGroups[i]["@ID"].indexOf("#") > -1;
        let hasFunctions = deltaGroups[i].Functions && deltaGroups[i].Functions?.Function && deltaGroups[i]?.Functions.Function.length > 0;
        let hasUsers = deltaGroups[i].Users && deltaGroups[i].Users?.User && deltaGroups[i]?.Users?.User.length > 0;
        
        if(validGroup || hasFunctions || hasUsers) {
            trimmedDelta.push(deepCopyFunction(deltaGroups[i]));
        }
    }
    return trimmedDelta;
}

function ensureGroupFunctionsArray(group: MCGroup) {
    if (!group.Functions)
        group.Functions = { Function: [] };

    if (!group.Functions.Function) {
        group.Functions.Function = [];
    }

    if (!Array.isArray(group.Functions.Function)) {
        group.Functions.Function = [group.Functions.Function];
    }
    return group;
}

function ensureGroupUsersArray(group: MCGroup) {
    if (!group.Users)
        group.Users = { User: [] };

    /*
    if (!group.Users.User || (group.Users.User &&
        !Array.isArray(group.Users.User)))
        group.Users.User = [];
    */

    if (!group.Users.User) {
        group.Users.User = [];
    }

    if (!Array.isArray(group.Users.User)) {
        group.Users.User = [group.Users.User];
    }

    return group;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IGroupMasterUIState, KnownActions> = (state: IGroupMasterUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        let updatedState = state;
        switch (action.type) {

            case 'GROUP_MASTER_SELECT':
                if (action.data.masterCrud) {
                    var data = action.data.masterCrud.GroupMaintenanceInfo.Groups.Group.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    //console.log(JSON.stringify(data));
                    updatedState = {
                        ...state,
                        selectedItem: data !== undefined ? JSON.parse(JSON.stringify(data)) : JSON.parse(JSON.stringify(emptyItem)),
                        groupMasterNameError: undefined,
                        submitNCSAlert: undefined,
                    }
                    return updatedState;
                }
                break;

            case 'GROUP_MASTER_SELECT_AVAILABLE_USERS':
                updatedState = {
                    ...state,
                    selectedAvailableUsers: action.data.uiData.selectedAvailableUsers,
                    groupMasterNameError: undefined,
                }
                return updatedState;
            case 'GROUP_MASTER_SELECT_SELECTED_USERS':
                updatedState = {
                    ...state,
                    selectedSelectedUsers: action.data.uiData.selectedSelectedUsers,
                    groupMasterNameError: undefined,
                }
                return updatedState;
            case 'GROUP_MASTER_ADD_USERS':
                // if available users have been selected
                if (action.data.masterCrud &&
                    state.selectedAvailableUsers.length > 0) {
                    let selectedAvailableUsers = state.selectedAvailableUsers.slice();
                    let modifiedSelectedUsers = addUsers(state.selectedItem, selectedAvailableUsers, action.data.masterCrud);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = addUsersDelta(state.selectedItem, selectedAvailableUsers, state.deltaCrud.GroupMaintenanceInfo.Groups.Group);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = trimDeltaGroups(state.deltaCrud);
                    return {
                        ...state,
                        selectedAvailableUsers: [],
                        selectedItem:
                        {
                            ...state.selectedItem,
                            Users: {
                                ...state.selectedItem.Users,
                                User: modifiedSelectedUsers
                            }
                        },
                        changed: true,
                        groupMasterNameError: undefined,
                    }
                }
                return state;
            case 'GROUP_MASTER_REMOVE_USERS':
                // if selected group users have been selected
                if (action.data.masterCrud &&
                    state.selectedSelectedUsers.length > 0) {

                    var selectedSelectedUsers = state.selectedSelectedUsers.slice();
                    var modifiedSelectedUsers = removeUsers(state.selectedItem, selectedSelectedUsers, action.data.masterCrud);
                    
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = removeUsersDelta(state.selectedItem, selectedSelectedUsers, state.deltaCrud.GroupMaintenanceInfo.Groups.Group);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = trimDeltaGroups(state.deltaCrud);
                    return {
                        ...state,
                        selectedSelectedUsers: selectedSelectedUsers,
                        selectedThisGroups: [],
                        selectedItem:
                        {
                            ...state.selectedItem,
                            Users: {
                                ...state.selectedItem.Users,
                                User:
                                    modifiedSelectedUsers
                            }
                        },
                        changed: true,
                        groupMasterNameError: undefined,
                    }
                }
                break;
            case 'GROUP_MASTER_SELECT_AVAILABLE_FUNCTIONS':
                return {
                    ...state,
                    selectedAvailableFunctions: action.data.uiData.selectedAvailableFunctions,
                }

            case 'GROUP_MASTER_SELECT_SELECTED_FUNCTIONS':
                return {
                    ...state,
                    selectedSelectedFunctions: action.data.uiData.selectedSelectedFunctions,
                }

            case 'GROUP_MASTER_ADD_FUNCTIONS':

                // if no available functions have been selected then return
                if (action.data.masterCrud &&
                    state.selectedAvailableFunctions.length > 0) {
                    var selectedAvailableFunctions = state.selectedAvailableFunctions.slice();
                    var selectedFunctions = addFunctions(state.selectedItem, selectedAvailableFunctions, action.data.masterCrud);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = addFunctionsDelta(state.selectedItem, selectedAvailableFunctions, state.deltaCrud.GroupMaintenanceInfo.Groups.Group);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = trimDeltaGroups(state.deltaCrud);
                    updatedState =
                    {
                        ...state,
                        selectedAvailableFunctions: [],
                        selectedItem:
                        {
                            ...state.selectedItem,
                            Functions: {
                                ...state.selectedItem.Functions,
                                Function: selectedFunctions
                            }
                        },
                        changed: true,
                    }
                    return updatedState;
                }
                break;

            case 'GROUP_MASTER_REMOVE_FUNCTIONS':

                // if selected group users have been selected
                if (action.data.masterCrud &&
                    state.selectedSelectedFunctions.length > 0) {

                    var selectedSelectedFunctions = state.selectedSelectedFunctions.slice();
                    var removedFunctions = removeFunctions(state.selectedItem, selectedSelectedFunctions, action.data.masterCrud);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = removeFunctionsDelta(state.selectedItem, selectedSelectedFunctions, state.deltaCrud.GroupMaintenanceInfo.Groups.Group);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = trimDeltaGroups(state.deltaCrud);
                    return {
                        ...state,
                        selectedSelectedFunctions: selectedSelectedFunctions,
                        selectedItem:
                        {
                            ...state.selectedItem,
                            Functions: {
                                ...state.selectedItem.Functions,
                                Function:
                                    removedFunctions
                            }
                        },
                        changed: true,
                        groupMasterNameError: undefined,
                    }
                }

                break; 

            case 'GROUP_MASTER_UPDATE':
                if (action.data.masterCrud) {
                    // upsert the group master
                    let existingGroup = action.data.masterCrud.GroupMaintenanceInfo.Groups.Group.find(group => group['@ID'] === action.data.uiData['@ID']);
                    existingGroup = existingGroup ? JSON.parse(JSON.stringify(existingGroup)) : JSON.parse(JSON.stringify(emptyItem));
                    const upsertResults = upsertGroupMaster(action.data.masterCrud.GroupMaintenanceInfo.Groups.Group, action.data.uiData);
                    // set the groups to the modified set of groups
                    action.data.masterCrud.GroupMaintenanceInfo.Groups.Group = upsertResults;
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = upsertGroupMasterDelta(state.deltaCrud.GroupMaintenanceInfo.Groups.Group, action.data.uiData, existingGroup);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = trimDeltaGroups(state.deltaCrud);
                    return {
                        ...state,                        
                        selectedGroupMaster: {
                            id: action.data.uiData["@ID"],
                            text: action.data.uiData["@Name"],
                        },
                        selectedItem: action.data.uiData,
                        changed: true,
                        groupMasterNameError: undefined,
                    }
                }
                break;

            case 'GROUP_MASTER_UPDATE_NAME':

                if (state.selectedItem['@Name'] !== action.data.uiData.value) {
                    let temp = state.selectedItem['@Name'];
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@Name': action.data.uiData.value,
                            '@OriginalName': temp
                        },
                    }
                }
                break;
            case 'GROUP_MASTER_ADD':
                if (action.data.masterCrud) {
                    // add the new group
                    const groups = upsertGroupMaster(action.data.masterCrud.GroupMaintenanceInfo.Groups.Group, action.data.uiData);
                    let existingGroup = action.data.masterCrud.GroupMaintenanceInfo.Groups.Group.find(group => group['@ID'] === action.data.uiData['@ID']);
                    existingGroup = existingGroup ? JSON.parse(JSON.stringify(existingGroup)) : JSON.parse(JSON.stringify(emptyItem));
                    // set the groups to the new set of groups
                    action.data.masterCrud.GroupMaintenanceInfo.Groups.Group = groups;
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = upsertGroupMasterDelta(state.deltaCrud.GroupMaintenanceInfo.Groups.Group, action.data.uiData, existingGroup);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = trimDeltaGroups(state.deltaCrud);
                    return {
                        ...state,
                        selectedGroupMaster: {
                            id: action.data.uiData["@ID"],
                            text: action.data.uiData["@Name"]
                        },
                        selectedItem: action.data.uiData,
                        changed: true,
                        groupMasterNameError: undefined,
                    }
                }
                break;
            case 'GROUP_MASTER_ERROR_FIELD':
                if (action.data.uiData.id && action.data.uiData.id.length > 0) {
                    switch (action.data.uiData.id) {
                        case 'groupMasterNameError':
                            return {
                                ...state,
                                groupMasterNameError: action.data.uiData.value,
                            }
                    }
                }
                break;
            case 'GROUP_MASTER_ERROR_REMOVE_CONFIRM':
                return {
                    ...state,
                    groupMasterRemoveConfirm: action.data.uiData.value,
                }
            case 'GROUP_MASTER_REMOVE':
                
                if (action.data.masterCrud) {
                    action.data.masterCrud.GroupMaintenanceInfo.Groups.Group =
                        removeGroupMaster(action.data.masterCrud.GroupMaintenanceInfo.Groups.Group, action.data.uiData);
                    state.deltaCrud.GroupMaintenanceInfo.Groups.Group = removeGroupMasterDelta(state.deltaCrud.GroupMaintenanceInfo.Groups.Group, action.data.uiData);
                    
                    return {
                        ...state,
                        selectedGroupMaster: { id: '', text: '' },
                        selectedItem: JSON.parse(JSON.stringify(emptyItem)),
                        selectedAvailableUsers: [],
                        selectedSelectedUsers: [],
                        selectedAvailableFunctions: [],
                        selectedSelectedFunctions: [],
                        groupMasterNameError: undefined,
                        groupMasterRemoveConfirm: undefined,
                        changed: true
                    }
                }
                
                break;

            case 'NCS_ALERT':
                return {
                    ...state,
                    submitNCSAlert: action.data.uiData.value,
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return deepCopyFunction(defaultState);
        }
    }

    return state || deepCopyFunction(defaultState);
}
