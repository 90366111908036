import * as React from 'react';
import * as ReactBootstrap from 'react-bootstrap';

//import { Container } from 'react-bootstrap';

import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import errorIcon from '../../content/images/errorIcon.gif';
import { number } from 'prop-types';
import { URLs } from '@commonDevResources/constants';

interface IMessageAlertState {
    expandMessages: boolean;  
    expanded: boolean;
    errorData: [];
}

interface IMessageAlertProps {
    reset?: boolean;
    warningData: object | null;
    errorData: object | null;

}

export default class MessageAlerts extends React.Component<IMessageAlertProps, IMessageAlertState> {

    errorCount: Number;
    errorList: any;
    errorResult: [];
    warningCount: Number;
    warningList: any;
    warningResults: [];
    warningResult: [];
    reset: boolean;

    constructor(props: IMessageAlertProps) {
        super(props);
        this.reset = false;
        this.errorCount = 0;
        this.errorList = null;
        this.errorResult= [];
        this.warningCount = 0;
        this.warningList = null;
        this.warningResults= [];
        this.warningResult= [];

        this.state = {
            expandMessages: false,
            expanded: false,
            errorData:[]
        };
    }   


    getMessagesFromServer() {
        
    }

    componentDidMount() {
        console.log('mounting message alerts')
        this.state = {
            expandMessages: false,
            expanded: false,
            errorData:[]
        };
    }

    isItemDefined(item:any) {
    if (typeof item === 'undefined' || item === null) {
        return false;
    }
    return true;
}

    setMessageList(msgType:any) {
        var msgList;
        var msgResults;
        var Count;

        if (msgType == "Error") {
            msgList = this.errorList;
        }

        if (msgType == "Error") {
            if (this.isItemDefined(this.errorList) && this.errorResult == null)
                this.errorResult = [];

            msgList = this.errorList;
            msgResults = this.errorResult;
        } else {
            if (this.isItemDefined(this.warningList) && this.warningResults == null)
                this.warningResults = [];

            msgList = this.warningList;
            msgResults = this.warningResults;
        }

        if (this.isItemDefined(msgList)) {
            var mapit = msgList; //JSON.parse(msgResults);
            //this.errorCount = mapit.length;
            Count = mapit.length;
            var msgs = mapit.map( (msgItem:any) => {
                //console.log("in like gonga din...");

                //if (isItemDefined(msgItem)) {
                if (this.isItemDefined(msgItem.asyncMsg) && msgItem.asyncMsg) {
                    if (this.isItemDefined(msgItem.ids)) {
                        var token = sessionStorage.getItem('SecurityInfo');
                        var xhr = new XMLHttpRequest();
                        xhr.open('POST', URLs.api + "/api/data/SetMessageReadList", true);
                        xhr.setRequestHeader("Content-Type", "text/xml");
                        xhr.send(msgItem.ids);
                    }
                }
                //}

                if (this.isItemDefined(msgItem.index)) {
                    if (this.isItemDefined(msgItem.msg)) {
                        var classname = "assuranceTopSectionItem";
                        if (msgType == "Warning") classname = classname + " warningMessage ";
                        return <ReactBootstrap.ListGroupItem className={classname} onClick={this.handleClick.bind(this, msgItem)} id={msgItem.index}>{msgItem.index}. {msgItem.msg}</ReactBootstrap.ListGroupItem>;
                    }
                }
            }, this);
            if (msgType == "Error") {
                this.errorCount = Count;
            } else {
                this.warningCount = Count;
            }

            return msgs;
        }
        
    }

    handleClick(msgItem:any) {
        this.reset = true;
        this.errorCount = 0;
        this.warningCount = 0;
        this.setState({
            expandMessages: false
        });
    }
    
    getMessageList(msgType:any) {
        // set onClick here to set the state of the message count to 0 & clear the message list to re-render and clear the errors..
        var msgList = this.setMessageList(msgType);
        var count;
        var message = "Errors";

        if (msgType == "Error") {
            count = this.errorCount;
        } else {
            count = this.warningCount;
            message = "Warnings";
        }
        var headerForNow = message + " found. The following [" + count + "] items require your attention."
        return <ReactBootstrap.Panel defaultExpanded onClick={this.handleClick.bind(this)}>
            <ReactBootstrap.Panel.Heading>{headerForNow}</ReactBootstrap.Panel.Heading>
            <ReactBootstrap.Panel.Body>
            <ReactBootstrap.ListGroup onClick={this.handleClick.bind(this)} fill>
                {msgList}
                </ReactBootstrap.ListGroup>
            </ReactBootstrap.Panel.Body>
        </ReactBootstrap.Panel>;
    }

    render() {
        if (this.reset == false || this.state.expanded == true) {
            if (this.isItemDefined(this.props.errorData)) {
                this.errorList = this.props.errorData;
                this.errorResult = [];
                //console.log("MessageAlerts::renderError: using: this.errorList[" + this.props?.errorData[0]?.msg + "]");
                var messageList = this.getMessageList("Error");
                return <div className="assuranceTopSection3" >
                    {messageList}
                </div>
            }
            else if (this.isItemDefined(this.props.warningData)) {
                this.warningList = this.props.warningData;
                this.warningResults = [];
                //console.log("MessageAlerts::renderWarning: using: this.errorList[" + this.props?.warningData[0]?.msg + "]");
                var messageList = this.getMessageList("Warning");
                return <div className="assuranceTopSection3 warningMessage" >
                    {messageList}
                </div>
            }
            else {
                return null;
            }
        } else {
            this.errorCount = 0;
            this.errorList = null;
            this.reset = false;
            return null;
        }
    }

}