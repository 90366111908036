import * as React from 'react';
import { TextArea as UICLTextArea } from 'ui-core';

export interface IArmTextAreaHandlerProps {
    removeCrLf?: boolean,
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>,
}

export class TextArea extends React.Component<IArmTextAreaHandlerProps | any, {}>
{
    static defaultProps: IArmTextAreaHandlerProps = {
    };

    constructor(props: IArmTextAreaHandlerProps) {
        super(props);
        this.state = {
        }
    }

    onChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        e.target.value = e.target.value.toLocaleUpperCase();

        // note:  scared to do a full whitespace replacement, but it might be needed????
        if (this.props.removeCrLf && e.target.value) {
            e.target.value = e.target.value.split('\r\n').join(' ' );
            e.target.value = e.target.value.split('\n').join(' ');
        }

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    render() {
        return (
            <UICLTextArea
                {...this.props}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.onChange(e)} />
        );
    }
};
