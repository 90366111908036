import * as React from 'react';
import { ARMDatePicker } from '../../../common/DateOrTimeRelated/ARMDatePicker';
import { SelectDropdownV2, Button } from 'ui-core';
import styled from 'styled-components';
import { ISelectionView, ISelectionMeasure, ISelectionDateRange } from "@store/OperationalDashboard/DashboardDefault"


export const DataSelectionWrapper = styled.div`
                display: flex;
                justify-content: start;
                align-items: end;
                padding-right: 2em;
                margin-bottom: 2em;
`;
export const SelectDropDownWrapper = styled.div`
    width: 30%;
    margin-right: .3rem
`

export const DatePickerWrapper = styled.div`
    margin-right: 0.3rem;
    display:flex;
    align-items: end;
    .cal_style{
        display: inline-flex;
    } 
    button{
        margin-bottom: 0.1rem;
    }
`;

export const DatePickerContainer = styled.div`
     margin-right: 0.3rem;
     display: inline-grid;
     label{
        margin-bottom: 0.3rem;
        color: #37474F;
        font-size: 11px;
        font-weight: 600;
        line-height: 1.4em;
        letter-spacing: 0.05em;
        text-transform: uppercase;
     }
`;

interface IComponentState {
    showCustomDatePickers: boolean;
    selectedDateRange: ISelectionDateRange | undefined;
    selectedMeasure: ISelectionMeasure | undefined;
    startDate: string;
    endDate: string;
    isMeasureEnabled: boolean;
};

export const DEFAULT_STATE: IComponentState = {
    showCustomDatePickers: false,
    selectedDateRange: undefined,
    selectedMeasure: undefined,
    startDate: '',
    endDate: '',
    isMeasureEnabled: true
}

interface IComponentProps {
    selectedView: ISelectionView | undefined;
    selectedMeasure: ISelectionMeasure | undefined;
    selectedDateRange: ISelectionDateRange | undefined;
    selectedStartDate: string | undefined;
    selectedEndDate: string | undefined;
    onSelectMeasure: Function | undefined;
    onSelectView: Function | undefined;
    onSelectDateRange: Function | undefined;
    enabled?: boolean;
};


export class CommonDataSelectionComponent extends React.Component<IComponentProps, IComponentState> {
    public constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        const { selectedDateRange, selectedStartDate, selectedEndDate, selectedView, selectedMeasure } = this.props;

        // if lists view disable measure dropdown
        const isListsView = selectedView && selectedView.value === '1'
        // if custom date range - show the date pickers and populate them correctly
        if (selectedDateRange && selectedDateRange.value === '7') {
            this.setState({
                selectedDateRange,
                showCustomDatePickers: true,
                startDate: selectedStartDate ? selectedStartDate : '',
                endDate: selectedEndDate ? selectedEndDate : '',
                isMeasureEnabled: !isListsView,
                selectedMeasure
            })
        } else {
            this.setState({
                isMeasureEnabled: !isListsView,
                selectedMeasure
            })
        }
    }

    public getDateInMMDDYYYYFormat(input: any): string {
        try {
            let month = input.getMonth() + 1;
            let day = input.getDate();
            let year = input.getFullYear();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000";
    }

    public dateSubtractDays(date: any, daysToSubtract: number) {
        try {
            let y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();

            d -= daysToSubtract;

            let xx = new Date(y, m, d);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateSubtractDays');
        }
        return "00/00/0000";
    }

    public dateSubtractMonths(date: any, monthsToSubtract: number) {
        try {
            let y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();

            m -= monthsToSubtract;

            let xx = new Date(y, m, d);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateSubtractDays');
        }
        return "00/00/0000";
    }

    public dateGetFirstDayOfMonth(date: any) {
        try {
            let y = date.getFullYear(),
                m = date.getMonth();

            let xx = new Date(y, m, 1);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateGetFirstDayOfMonth');
        }
        return "00/00/0000";
    }

    public getFirstDayOfWeek(dateObject: Date, firstDayOfWeekIndex: number) {
        try {
            const dayOfWeek = dateObject.getDay(),
                firstDayOfWeek = new Date(dateObject),
                diff = dayOfWeek >= firstDayOfWeekIndex ?
                    dayOfWeek - firstDayOfWeekIndex :
                    6 - dayOfWeek

            firstDayOfWeek.setDate(dateObject.getDate() - diff)
            firstDayOfWeek.setHours(0, 0, 0, 0)

            return this.getDateInMMDDYYYYFormat(firstDayOfWeek)
        }
        catch (e) {
            console.error('Unable to properly format date, getFirstDayOfWeek');
        }
        return "00/00/0000";
    }

    public getMaxDate() {
        const today = new Date();
        let tomorrow = new Date()
        tomorrow.setDate(today.getDate() + 1);
        return tomorrow;
    }

    public render() {
        const { enabled } = this.props
        const isEnabled = enabled === undefined ? true : enabled;
        const { isMeasureEnabled } = this.state;
        const isCustomStartEndDateSet = this.state.startDate !== '' && this.state.endDate !== '';
        const selectViewOptions = [
            { label: 'Charts', value: '0' },
            { label: 'Lists', value: '1' }
        ]

        const selectMeasureOptions = [
            { label: 'Amount & Count', value: '0' },
            { label: 'Amount', value: '1' },
            { label: 'Count', value: '2' }
        ]

        const selectDateRangeOptions = [
            { label: 'Last 90 days', value: '6' },
            { label: 'Last 60 days', value: '5' },
            { label: 'Last 30 days', value: '4' },
            { label: 'Month to date', value: '3' },
            { label: 'Week to date', value: '2' },
            { label: 'Yesterday', value: '1' },
            { label: 'Today', value: '0' },
            { label: 'Custom date range', value: '7' },
        ]

        const onSelectView = async (e: any) => {
            const isListsView = e.value === '1'
            this.setState({
                isMeasureEnabled: !isListsView
            })
            if (this.props.onSelectView) {
                const { selectedMeasure } = this.state;
                // we have to wait for select view to complete an event loop before switching the measure
                await this.props.onSelectView(e)
                // switch the measure to the default if it's not already there.
                if (isListsView && selectedMeasure && selectedMeasure.value !== "0") {
                    if (this.props.onSelectMeasure) this.props.onSelectMeasure(selectMeasureOptions[0])
                }
            }

            
        }

        const onSelectMeasure = (e: any) => {
            this.setState({
                selectedMeasure: e
            })
            if (this.props.onSelectMeasure) this.props.onSelectMeasure(e);
        
        }



        const onSelectDateRangeChange = (e: any) => {
            const isCustomDate = e.value === '7';
            const todaysDate = this.getDateInMMDDYYYYFormat(new Date());

            let startDate = ''
            let endDate = ''

            // calculate start and end dates if needed
            if (!isCustomDate) {
                switch (e.value) {
                    case '7':
                        startDate = '';
                        endDate = '';
                        break;
                    case '6':
                        startDate = this.dateSubtractDays(new Date(), 90);
                        endDate = todaysDate;
                        break;
                    case '5':
                        startDate = this.dateSubtractDays(new Date(), 60);
                        endDate = todaysDate;
                        break;
                    case '4':
                        startDate = this.dateSubtractDays(new Date(), 30);
                        endDate = todaysDate;
                        break;
                    case '3':
                        startDate = this.dateGetFirstDayOfMonth(new Date());
                        endDate = todaysDate;
                        break;
                    case '2':
                        startDate = this.getFirstDayOfWeek(new Date(), 1);
                        endDate = todaysDate;
                        break;
                    case '1':
                        startDate = this.dateSubtractDays(new Date(), 1);
                        endDate = this.dateSubtractDays(new Date(), 1);
                        break;
                    case '0':
                        startDate = todaysDate;
                        endDate = todaysDate;
                        break;
                    default:
                        startDate = todaysDate;
                        endDate = todaysDate;
                        break;
                }
            }


            this.setState({
                selectedDateRange: e,
                startDate,
                endDate,
                showCustomDatePickers: isCustomDate
            })

            // the custom date dropdown doesn't immediately call the action
            // the action in a custom scenario is triggered by the button
            if (!isCustomDate && this.props.onSelectDateRange) {
                this.props.onSelectDateRange(e, startDate, endDate);
            }
        }


        return (
            <>
                <DataSelectionWrapper>
                    <SelectDropDownWrapper>
                        <SelectDropdownV2
                            domID="triggerevent-dropdown-selectView"
                            className="dropdown"
                            label="Select View:"
                            isClearable={false}
                            size='large'
                            onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => { onSelectView(e) }}
                            options={selectViewOptions}
                            enabled={isEnabled}
                            initialValue={this.props.selectedView}
                        />
                    </SelectDropDownWrapper>
                    <SelectDropDownWrapper>
                        <SelectDropdownV2
                            domID="triggerevent-dropdown-selectMeasure"
                            className="dropdown"
                            label="Select Measure:"
                            isClearable={false}
                            size='large'
                            onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => { onSelectMeasure(e) }}
                            options={selectMeasureOptions}
                            disabled={!isEnabled || !isMeasureEnabled}
                            initialValue={this.props.selectedMeasure}
                            
                        />
                    </SelectDropDownWrapper>
                </DataSelectionWrapper>
                <DataSelectionWrapper>
                    <SelectDropDownWrapper>
                        <SelectDropdownV2
                            domID="triggerevent-dropdown-selectDateRange"
                            className="dropdown"
                            label="Select Date Range:"
                            isClearable={false}
                            size='large'
                            onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => { onSelectDateRangeChange(e) }}
                            options={selectDateRangeOptions}
                            enabled={isEnabled}
                            initialValue={this.props.selectedDateRange}
                        />
                    </SelectDropDownWrapper>
                    {this.state.showCustomDatePickers && <DatePickerWrapper>
                        <DatePickerContainer>
                            <label>Start Date</label>
                            <ARMDatePicker
                                domID="dashboard_data_select_start_date"
                                className="cal_style"
                                onInputChange={(startDate: string) => { this.setState({ startDate }) }}
                                allowTime={false}
                                minDate={this.dateSubtractMonths(new Date(), 3)} // 90 days is maximum range
                                assocTo={this.state.endDate}
                                initialDate={this.state.startDate || ''}
                                disableHowLong={true}
                                lockKeyboardInput={true}
                            />
                        </DatePickerContainer>
                        <DatePickerContainer>
                            <label>End Date</label>
                            <ARMDatePicker
                                domID="dashboard_data_select_end_date"
                                className="cal_style"
                                onInputChange={(endDate: string) => { this.setState({ endDate }) }}
                                allowTime={false}
                                minDate={this.dateSubtractMonths(new Date(), 3)}
                                maxDate={this.getDateInMMDDYYYYFormat(this.getMaxDate())}
                                assocFrom={this.state.startDate}
                                initialDate={this.state.endDate || ''}
                                disableHowLong={true}
                                lockKeyboardInput={true}
                            />
                        </DatePickerContainer>
                        <Button
                            domID="CommonDataSelectionDateRangeApply"
                            name="Apply"
                            buttonType="standard"
                            size="small"
                            type="button"
                            className="previewButton"
                            disabled={!isEnabled || !isCustomStartEndDateSet}
                            onClick={() => { if (this.props.onSelectDateRange && isCustomStartEndDateSet) this.props.onSelectDateRange(this.state.selectedDateRange, this.state.startDate, this.state.endDate) }}
                        ></Button>
                    </DatePickerWrapper>
                    }
                </DataSelectionWrapper>
            </>
        )
    }

}
