import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers'; 
import * as AddNoteStore from './api/AddNoteStore';

export type IAddNoteState = IApiComponentState<any, AddNoteStore.IAddNoteUIState>;

export const actionCreators = createApiComponentActions<AddNoteStore.ActionCreators>(AddNoteStore.actionCreators); 
export type IAddNoteActions = typeof actionCreators;
export type IAddNoteActionProps = IApiComponentActionProps<IAddNoteActions>

export const reducers = createApiComponentReducers("addNote", AddNoteStore.reducer); 
export const reducer = combineReducers<IAddNoteState>(reducers);
