import * as React from "react";
import { useState, useEffect, useMemo } from "react";
import { Typography, CircleX, Input } from "ui-core";
import { Colors } from "@commonResources/colorVariables";
import styled from "styled-components";
import _ from "lodash";

/**
 * Note: the colors set on the <option> are given by the underlying OS
 * and can't be overridden with CSS.
 */
const ListContainerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & select {
    background: rgb(248, 248, 248);
    &:focus {
      outline: 1px solid ${Colors.babyBlue};
      border: 1px solid ${Colors.babyBlue};
      box-shadow: inset 0 2px 2px 0 rgb(155 161 169 / 25%);
      background: rgb(250, 250, 250);
    }
  }
  & select > option {
    padding: 2px 10px;
  }
`;

const SearchInputLabel = styled.label`
  margin-bottom: 5px;
  opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};
`;

const SearchInput = styled.input`
  ${Typography.normal};
  ${Typography.LetterSpacing};
  ${Typography.fontFamily};
  color: ${Colors.grey100};
  ${Typography.mediumLarge};
  padding: 9px 8px 8px 10px;
  box-sizing: border-box;
  border: 1px solid #9ba1a9;
  border-radius: 3px;
  box-shadow: inset 0 2px 2px 0 rgb(155 161 169 / 25%);
  &::placeholder {
    color: ${Colors.grey50};
  }
  &:hover {
    border: ${(props: any) =>
      props.disabled ? `` : `1px solid ${Colors.grey100}`};
  }
  &:focus {
    outline: 1px solid ${Colors.babyBlue};
    border: 1px solid ${Colors.babyBlue};
  }
`;

const ClearSearchIconWrapper = styled.div`
  svg {
    position: absolute;
    right: 8px;
    bottom: 7px;
    cursor: ${(props: any) => (props.disabled ? "default" : "pointer")};
    opacity: ${(props: any) => (props.disabled ? 0.5 : 0.75)};
  }
  svg:hover {
    opacity: ${(props: any) => (props.disabled ? 0.5 : 1)};
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 5px;
`;

interface IComponentProps {
  domID?: string;
  width: number;
  height: number;
  records: any[];
  preselectRecords?: any[];
  optionFields?: any;
  multiSelect?: boolean | undefined;
  setSelections: Function;
  placeholder?: string;
  label?: string;
  disabled?: boolean;
  value?: any[];
  initFirstSelected?: boolean | undefined;
  onHandleSearchInputChange?: Function;
}

const FilterableList: React.FC<IComponentProps> = ({
  domID,
  width,
  height,
  records,
  optionFields,
  multiSelect,
  setSelections,
  placeholder,
  label,
  disabled,
  value,
  initFirstSelected,
  onHandleSearchInputChange,
}) => {
  const [filteredRecords, setFilteredRecords] = useState<any[]>([]);
  const [optionRecords, setOptionRecords] = useState<any[]>([]);
  const [searchInputState, setSearchInputState] = useState({
    searchKey: "",
    cursorPos: -1,
  });

  const [storedRecords, setStoredRecords] = useState<any[]>([]);

  // This is effectively equivalent to a ComponentDidMount
  useEffect(() => {
    let options: any = records;

    if (optionFields) {
      options = options.map((r: any) => {
        return {
          ...r,
          id: r[optionFields.id],
          value: r[optionFields.value],
          text: r[optionFields.text],
        };
      });
    }
    setOptionRecords(options);
  }, []);

  useEffect(() => {
    let searchedRecords: any[] = optionRecords;
    if (
      !(
        (searchInputState.searchKey &&
          searchInputState.searchKey.trim() === "") ||
        !searchInputState.searchKey
      )
    ) {
      searchedRecords = optionRecords.reduce((currArry: any, currentValue) => {
        let result = currArry;
        if (
          JSON.stringify(currentValue.text.toLowerCase()).includes(
            searchInputState.searchKey.toLowerCase()
          )
        ) {
          result = [...result, currentValue];
        }
        return result;
      }, []);
    }

    // Preselect the first item in the list
    // Note: this effect will run on mount even without having entered a search key
    if (!!initFirstSelected) {
      let firstItem = searchedRecords[0];
      firstItem && setSelections([firstItem]);
    }

    setFilteredRecords(searchedRecords);
  }, [optionRecords, searchInputState.searchKey]);

  const handleSearchInputChange = (event: any) => {
    const element = event.target;
    let caret = element.selectionStart;
    let value = element.value;

    // Ensure the caret's position is updated
    window.requestAnimationFrame(() => {
      element.setSelectionRange(caret, caret);
    });

    setSearchInputState({
      searchKey: value.toUpperCase(),
      cursorPos: caret,
    });

    if (typeof onHandleSearchInputChange === "function") {
      onHandleSearchInputChange(event);
    }
  };

  // Reset the search key to an empty string
  const handleSearchButtonClick = () => {
    setSearchInputState({ ...searchInputState, searchKey: "" });
  };

  const handleChange = (e: any) => {
    let selectedRecords: any = [];

    if (multiSelect) {
      const storedRecordsOutOfView = storedRecords.filter(
        (storedRecord: any) => {
          return !filteredRecords.find((filteredRecord) => {
            return filteredRecord.id === storedRecord.id;
          });
        }
      );

      selectedRecords = _.map(e.target.selectedOptions, (option: any) => {
        return filteredRecords.find((record: any) => {
          return record.id == option.attributes.value.value; // a number is being compared to a string here. so strict equality won't work
        });
      });

      selectedRecords = [...storedRecordsOutOfView, ...selectedRecords];

      setStoredRecords(selectedRecords);
    } else {
      // Single Select
      selectedRecords = [
        filteredRecords.find((record: any) => {
          return record.id == e.target.value; // a number is being compared to a string here. so strict equality won't work
        }),
      ];
    }
    setSelections && setSelections(selectedRecords);
  };

  return (
    <ListContainerDiv key={domID ?? "" + "-ListContainerDiv"}>
      <SearchWrapper>
        {label && (
          //@ts-ignore
          <SearchInputLabel disabled={disabled}>{label}</SearchInputLabel>
        )}
        <SearchInput
          id={domID ?? "" + "Filterable-Search-Input"}
          name={domID ?? "" + "Filterable-Search-Input"}
          type="text"
          placeholder={placeholder ?? ""}
          value={searchInputState.searchKey}
          onChange={(e: any) => handleSearchInputChange(e)}
          disabled={disabled}
        />
        <ClearSearchIconWrapper
          //@ts-ignore
          disabled={disabled}
          onClick={() => handleSearchButtonClick()}
        >
          <CircleX width={"20px"} />
        </ClearSearchIconWrapper>
      </SearchWrapper>
      <select
        key={domID + "-ListComponentSelect-"}
        id={domID + "-ListComponentSelect"}
        name={domID + "-ListComponentSelect"}
        {...(!!multiSelect ? { multiple: true } : { size: 10 })}
        onChange={(e) => handleChange(e)}
        style={{ width: `${width}px`, height: `${height}px` }}
        // use the store to get the selections
        // If multiselect is true than value must be a scalar
        /*
        value={
          !!multiSelect && value ? value : value && value.length && value[0]
        }*/
        value={value}
        disabled={disabled}
      >
        {filteredRecords &&
          filteredRecords?.map((option: any) => {
            return (
              <option key={option.id} id={option.id} value={option.id}>
                {option.text}
              </option>
            );
          })}
      </select>
    </ListContainerDiv>
  );
};

export default React.memo(FilterableList);
