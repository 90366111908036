import { Action, ActionCreatorsMapObject, MiddlewareAPI, Dispatch, AnyAction } from "redux";
import { getRawToken } from '@scripts/session/SecurityToken';
//Action<T> is already in redux, but it associates T with any. We want T to extend string for TypeScript.
// This file defines additional action interfaces that extend the basic IAction<T>:
// IDataAction allows an action to include a data element of type D. This might include a counter, index, or other action-specific data that needs to be returned to the store.
// IApiAction considers an action that calls a web API. It allows apiParameters to be passed, which may include the method, headers, and body.

export interface IAction<T extends string> extends Action<T> {};

export interface IDataAction<T extends string, D> extends IAction<T> { data: D }
export interface IDataActionExtended<P extends any, T extends string, D> extends IAction<T> { data: D }


export type TStatusTypes = "VALIDATE" | "REQUEST" | "SUCCESS" | "FAIL";
export type TApiStatusTypes = TStatusTypes;

export type TApiStatus = {
    status: TApiStatusTypes,
    responseCode: number,
}
export interface IApiAction<T extends string, D, R extends any = any> extends IDataAction<T, D> {
    url: string,
    status: TApiStatus,
    requestData: RequestInit,
    responseData: R | undefined,
    validationCallback: ValidationCallback<R> | undefined,
    errorCallback: ErrorCallback | undefined,
    validateData?: DataValidationCallback | undefined,
    responseHandler?: ResponseHandler<T, D, R> | undefined,
}
export interface IApiActionExtended<P extends string, T extends string, D, R extends any = any> extends IDataActionExtended<P, T, D> {
    payload: P | undefined,
    url: string,
    status: TApiStatus,
    requestData: RequestInit,
    responseData: R | undefined,
    validationCallback: ValidationCallback<R> | undefined,
    errorCallback: ErrorCallback | undefined,
    validateData?: DataValidationCallback | undefined,
    responseHandler?: ResponseHandler<T, D, R> | undefined,
}

export function createAction<T extends string>(type: T): IAction<T>;
export function createAction(type: string) {
    return {type: type};
}




export function createDataAction<T extends string, D>(type: T, data: D): IDataAction<T, D>;

export function createDataAction(type: string, data?: any) {
    return { type: type, data: data };
}

export type ErrorCallback = (data: any) => data is any;
export type ValidationCallback<T extends any> = (crud: T | any) => crud is T;
export type DataValidationCallback = () => boolean;
export type ResponseHandler<T extends string, D, R extends any> = (api: MiddlewareAPI<Dispatch, any>, action: IApiAction<T, D, R>, reseponse: any, data: R ) => boolean;

export function getApiRequestInit(method: string, body: BodyInit | null = null): RequestInit {
    return {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': getRawToken(),
        },
        body: (method == "GET") ? undefined : body
    };
}

export function createApiActionExtended<P extends any, T extends string, D, V extends any>(payload: P, type: T, url: string, data?: D, validationCallback?: ValidationCallback<V>, errorCallback?: ErrorCallback, requestData?: RequestInit, status?: TApiStatus, responseData?: V, responseHandler?: ResponseHandler<T, D, V>): IApiAction<T, D, V>;
export function createApiActionExtended(payload: any, type: string, url: string, data: any = {}, validationCallback: ValidationCallback<any> | undefined = undefined, errorCallback: ErrorCallback | undefined = undefined, requestData: RequestInit = getApiRequestInit('GET'), status: TApiStatus = { status: "REQUEST", responseCode: -1 }, responseData: any = null, responseHandler: ResponseHandler<string, any, any> | undefined = undefined): IApiActionExtended<any, string, any, any> {
    return { payload: payload, type: type, url: url, data: data, requestData: requestData, status: status, responseData: responseData, validationCallback: validationCallback, errorCallback: errorCallback, responseHandler: responseHandler };
}

export function createApiAction<T extends string, D, V extends any>(type: T, url: string, data?: D, validationCallback?: ValidationCallback<V>, errorCallback?: ErrorCallback, requestData?: RequestInit, status?: TApiStatus, responseData?: V, responseHandler?: ResponseHandler<T, D, V>): IApiAction<T, D, V>;
export function createApiAction(type: string, url: string, data: any = {}, validationCallback: ValidationCallback<any> | undefined = undefined, errorCallback: ErrorCallback | undefined = undefined, requestData: RequestInit = getApiRequestInit('GET'), status: TApiStatus = { status: "REQUEST", responseCode: -1 }, responseData: any = null, responseHandler: ResponseHandler<string, any, any> | undefined = undefined): IApiAction<string, any, any>
{
    return { type: type, url: url, data: data, requestData: requestData, status: status, responseData: responseData, validationCallback: validationCallback, errorCallback: errorCallback, responseHandler: responseHandler};
}

export function createApiBodyAction<T extends string, D, V extends any>(type: T, url: string, data?: D, method?: string, body?: string, validationCallback?: ValidationCallback<V>, errorCallback?: ErrorCallback, status?: TApiStatus, responseData?: V, responseHandler?: ResponseHandler<T, D, V>): IApiAction<T, D>;
export function createApiBodyAction(type: string, url: string, data: any = {}, method: string = "GET", body: BodyInit | null = null, validationCallback: ValidationCallback<any> | undefined = undefined, errorCallback: ErrorCallback | undefined = undefined, status: TApiStatus = { status: "REQUEST", responseCode: -1 }, responseData: any = {}, responseHandler: ResponseHandler<string, any, any> | undefined = undefined) {
    return createApiAction(type, url, data, validationCallback, errorCallback, getApiRequestInit(method, body), status, responseData, responseHandler);
}

export function createApiStatusAction<T extends string, D>(action: IApiAction<T, D>, status: TApiStatus, responseData?: any): IApiAction<T, D>;
export function createApiStatusAction(action: IApiAction<string, any>, status: TApiStatus, responseData: any = null) {
    return { ...action, status: status, responseData: responseData };
}
export function createApiFileUploadAction<T extends string, D, V extends any>(type: T, url: string, files: any, data?: D, validationCallback?: ValidationCallback<V>, errorCallback?: ErrorCallback, requestData?: RequestInit, status?: TApiStatus, responseData?: V): IApiAction<T, D, V>;
export function createApiFileUploadAction(type: string, url: string, files: any, data: any = {}, validationCallback: ValidationCallback<any> | undefined = undefined, errorCallback: ErrorCallback | undefined = undefined, requestData: RequestInit =
    {
        method: 'POST',
        headers: { 'Authorization': getRawToken() },
        body: files,
    }, status: TApiStatus = { status: "REQUEST", responseCode: -1 }, responseData: any = null): IApiAction<string, any, any> {
    return { type: type, url: url, data: data, requestData: requestData, status: status, responseData: responseData, validationCallback: validationCallback, errorCallback: errorCallback };
}

export type ActionTypes<T extends ActionCreatorsMapObject> = ReturnType<T[keyof T]>;
