import { Action, Reducer } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export const emptyItem: MCSB_SecondaryBillingList =
{
    '@SecondaryPayerCheck': '',
    '@HoldCode': '',
    '@SwapInsuredOther': '',
    '@DuplicateFileAllowed': '',
    '@PreviousBillCheck': '',
    '@AcceptLocalPrint': '',
    '@Coinsurance': '',
    '@Deductible': '',
    '@PatLiability': '',
    '@PostDCNYN': '',
    '@PostAdjInfo': '',
    '@HCDClaimEOBFormType': '',
    '@HCDEnabled': '',
    '@AltMatchHoldCode': '',

    SecondaryBilling: [],
};

export const emptySecondaryBillingItem: MCSB_SecondaryBilling =
{
    '@ID': '',
    '@Name': '',
    '@LobID': '',
    '@PayerID': '',
    '@FormType': '',
    '@TypeOfBill': '',
    '@MedicaidSplitClaimLines': '',
    '@EnableMedicaidSplit': 'N',
    '@RemoveZeroPayServices': 'N',
    '@CoinsuranceYN': 'N',
    '@DeductibleYN': 'N',
    '@PatLiabilityYN': 'N',
    '@PostDCNYN': 'N',
    '@PostAdjInfo': '',
    '@HCDClaimEOBFormType': '',
    '@PreviousBillCheckYN': 'N',
    '@AcceptLocalPrintYN': 'N',
};
export interface ISecondaryBillingUIState {
    selectedSecondaryBilling: ISecondaryBillingUIData,
    selectedTabIndex: Number,
    selectedItem: MCSB_SecondaryBillingList,
    secondaryBillingItem: MCSB_SecondaryBilling,
    holdCode1Checked: string,
    holdCode2Checked: string,
    holdCodeList1Disabled: boolean | undefined,
    holdCodeList2Disabled: boolean | undefined,
    acceptLclPrntClaimsDisabled: boolean,
    commercialPayersPointer: string | undefined,
    noncommercialPayersPointer: string | undefined,
    allPayersListPointer: string,
    changed: boolean,
    SecondaryBillingAlert?: string,
    buttonLabel: string,
    buttonDisabled: boolean,
    button2Disable: boolean,
    medicaidLineCount: string,
    nonCommercialSelected: boolean,
    highestSecondaryBillingID: number,
    payerRemoveConfirm: string | undefined,
    medicalRemoveConfirm: string | undefined,
    initialValuesLoaded: boolean,
    mediCalConfirm: string | undefined,
    e: boolean,
    genericAlertOK: any,
    holdCode1Error: string,
    holdCode2Error: string,
    submitNCSAlert?: string,
    cancelWOSave?: boolean,
    clearCommercial: number,
    clearNonCommercial: number,
    oldTab : undefined | number,
}


export interface ISecondaryBillingUIData {
    id: string;
    value: string;
};


interface ISecondaryBillingUIFieldUpdate {
    value: string;
}

export const defaultSecondaryBilling: ISecondaryBillingUIData = {
    id: '',
    value: '',
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectSecondaryBilling extends ICrudActionData<MCSecondaryBillingType, ISecondaryBillingUIData> { }
export interface IUpdateSecondaryBillingField extends ICrudActionData<MCSecondaryBillingType, ISecondaryBillingUIFieldUpdate> { }

export const actionCreators = {
    //selectTab: (tabIndex: Number) => createDataAction('SELECT_TAB', tabIndex),
    selectTab: (fieldInfo: ISelectSecondaryBilling) => createDataAction('SELECT_TAB', fieldInfo),
    updateRemoveButton: (visible: boolean) => createDataAction('UPDATE_REMOVE_BUTTON', visible),
    updateAddButton: (fieldInfo: ISelectSecondaryBilling) => createDataAction('UPDATE_ADD_BUTTON', fieldInfo),
    updateDropdownPayer: (fieldInfo: ISelectSecondaryBilling) => createDataAction('UPDATE_DROPDOWN_PAYER', fieldInfo),
    sendSecondaryBillingAlert: (fieldInfo: IUpdateSecondaryBillingField) => createDataAction('SEND_ALERT', fieldInfo),
    updateCheckBox: (fieldInfo: ISelectSecondaryBilling) => createDataAction('UPDATE_CHECKBOX', fieldInfo),
    updateSelectedPayerCheckBox: (fieldInfo: ISelectSecondaryBilling) => createDataAction('UPDATE_SELECTED_PAYER_CHECKBOX', fieldInfo),
    updateSelectedPayer: (fieldInfo: ISelectSecondaryBilling) => createDataAction('UPDATE_SELECTED_PAYER', fieldInfo),
    updateMediciaidValue: (fieldInfo: ISelectSecondaryBilling) => createDataAction('UPDATE_MEDICIAD_COUNT', fieldInfo),
    updateDropdownList: (fieldInfo: ISelectSecondaryBilling) => createDataAction('UPDATE_DROPDOWN_LIST', fieldInfo),
    isNonCommercial: (fieldInfo: ISelectSecondaryBilling) => createDataAction('NON_COMMERCIAL_SELECTED', fieldInfo), 
    updatePayerByTOB: (fieldInfo: ISelectSecondaryBilling) => createDataAction('UPDATE_RECORD_BY_TOB', fieldInfo),
    addUpdatePayer: (fieldInfo: ISelectSecondaryBilling) => createDataAction('ADD_UPDATE_BUTTON', fieldInfo),
    removePayer: (fieldInfo: ISelectSecondaryBilling) => createDataAction('REMOVE_BUTTON', fieldInfo),
    errorPayerRemoveConfirm: (fieldInfo: IUpdateSecondaryBillingField) => createDataAction('ERROR_PAYER_REMOVE_CONFIRM', fieldInfo),
    errorMediCalRemoveConfirm: (fieldInfo: IUpdateSecondaryBillingField) => createDataAction('ERROR_MEDICAL_REMOVE_CONFIRM', fieldInfo),
    mediCalStoreE: (e: boolean) => createDataAction('MEDICAL_STORE_E', e),
    callAlert: (e: any) => createDataAction('ERROR_ALERT_CONFIRM', e),
    changedPageDefaults: (e: boolean) => createDataAction('CHANGED_DEFAULTS', e),
    loadGlobalValues: (fieldInfo: IUpdateSecondaryBillingField) => createDataAction('LOAD_GLOBAL_VALUES', fieldInfo),
    loadErrorValues: (fieldInfo: ISelectSecondaryBilling) => createDataAction('LOAD_ERROR_VALUES', fieldInfo),
    clearSplitClaimLines: (e: any) => createDataAction('CLEAR_SPLIT_LINES', e),
    sendSubmitNCSAlert: (fieldInfo: IUpdateSecondaryBillingField) => createDataAction('NCS_ALERT', fieldInfo),
    cancelPageButton: (e: any) => createDataAction('CANCEL_WITHOUT_SAVE', e),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: ISecondaryBillingUIState = {
    selectedSecondaryBilling: defaultSecondaryBilling,
    changed: false,
    selectedTabIndex: 0,
    SecondaryBillingAlert: '',
    selectedItem: emptyItem,
    secondaryBillingItem: emptySecondaryBillingItem,
    holdCode1Checked: "N",
    holdCode2Checked: "N",
    holdCodeList1Disabled: undefined,
    holdCodeList2Disabled: undefined,
    acceptLclPrntClaimsDisabled: false,
    commercialPayersPointer: undefined,
    noncommercialPayersPointer: undefined,
    allPayersListPointer: '',
    buttonLabel: "ADD",
    buttonDisabled: true,
    button2Disable: true,
    medicaidLineCount: "",
    nonCommercialSelected: false,
    highestSecondaryBillingID: 1,
    payerRemoveConfirm: undefined,
    medicalRemoveConfirm: undefined,
    mediCalConfirm: undefined,
    e: false,
    genericAlertOK: undefined,
    initialValuesLoaded: false,
    holdCode1Error: "",
    holdCode2Error: "",
    submitNCSAlert: '',
    cancelWOSave: false,
    clearCommercial: 0,
    clearNonCommercial: 0,
    oldTab: undefined,
};

// ----------------

function loadSelectedItem(crud: MCSecondaryBillingType) {

    let crudList = crud.SecondaryBillingSetup.SecondaryBillingList

    if (crudList) {
        let selectedItem = JSON.parse(JSON.stringify(crudList));
        console.log(selectedItem);
    }
    return ;
}

function removeRecordSecondaryBilling(array: MCSB_SecondaryBilling[], item: MCSB_SecondaryBilling) {
    let newArray = array.slice();
    var oldItem = newArray.find(obj => obj['@ID'] === item['@ID']);
    if (!oldItem) {
        newArray.splice(array.length, 0, JSON.parse(JSON.stringify(item)));
    } else {
        newArray = newArray.filter(obj =>  obj['@ID'] != item['@ID'] );
    }
    return newArray;
}

function upsertSecondaryBilling(array: MCSB_SecondaryBilling[], item: MCSB_SecondaryBilling) {
    let newArray = array.slice();
    var oldItem = newArray.find(obj => obj['@ID'] === item['@ID']);
    if (!oldItem) {
        newArray.splice(array.length, 0, JSON.parse(JSON.stringify(item)));
    } else {
        oldItem['@Name'] = item['@Name'];
        oldItem['@AcceptLocalPrintYN'] = item['@AcceptLocalPrintYN'];
        oldItem["@CoinsuranceYN"] = item["@CoinsuranceYN"];
        oldItem["@DeductibleYN"] = item["@DeductibleYN"];
        oldItem["@EnableMedicaidSplit"] = item["@EnableMedicaidSplit"];
        oldItem["@RemoveZeroPayServices"] = item["@RemoveZeroPayServices"];
        oldItem["@FormType"]= item["@FormType"];
        oldItem["@HCDClaimEOBFormType"] = item["@HCDClaimEOBFormType"];
        oldItem["@ID"] = item["@ID"];
        oldItem["@LobID"] = item["@LobID"];
        oldItem["@MedicaidSplitClaimLines"] = item["@MedicaidSplitClaimLines"];
        oldItem["@Name"] = item["@Name"];
        oldItem["@PatLiabilityYN"] = item["@PatLiabilityYN"];
        oldItem["@PayerID"] = item["@PayerID"];
        oldItem["@PostAdjInfo"] = item["@PostAdjInfo"];
        oldItem["@PostDCNYN"] = item["@PostDCNYN"];
        oldItem["@PreviousBillCheckYN"] = item["@PreviousBillCheckYN"];
        oldItem["@TypeOfBill"] = item["@TypeOfBill"];
    }

    return newArray;
}

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<ISecondaryBillingUIState, KnownActions> = (state: ISecondaryBillingUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {

            case 'LOAD_ERROR_VALUES':
                if (action.data.uiData.id && action.data.uiData.id.length > 0) {
                    switch (action.data.uiData.id) {
                        case 'holdcode':
                            return {
                                ...state,
                                holdCode1Error: action.data.uiData.value,
                            }
                            break;
                        case 'altholdcode':
                            return {
                                ...state,
                                holdCode2Error: action.data.uiData.value,
                            }
                            break;
                    }
                }
            case 'LOAD_GLOBAL_VALUES':
                if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup && !state.initialValuesLoaded) {

                    var localPrintValue = action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@AcceptLocalPrint"];
                    if (action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@AcceptLocalPrint"] === "Y" &&
                        action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@HoldCode"] !== "") {
                        action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@AcceptLocalPrint"] = ""
                    }
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@SecondaryPayerCheck': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@SecondaryPayerCheck"],
                            '@HoldCode': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@HoldCode"],
                            '@SwapInsuredOther': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@SwapInsuredOther"],
                            '@DuplicateFileAllowed': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@DuplicateFileAllowed"],
                            '@PreviousBillCheck': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@PreviousBillCheck"],
                            '@AcceptLocalPrint': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@AcceptLocalPrint"],
                            '@Coinsurance': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@Coinsurance"],
                            '@Deductible': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@Deductible"],
                            '@PatLiability': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@PatLiability"],
                            '@PostDCNYN': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@PostDCNYN"],
                            '@PostAdjInfo': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@PostAdjInfo"],
                            '@HCDClaimEOBFormType': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@HCDClaimEOBFormType"],
                            '@HCDEnabled': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@HCDEnabled"],
                            '@AltMatchHoldCode': action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@AltMatchHoldCode"],
                        },
                        initialValuesLoaded: true,
                    }
                } else {
                    return {
                        ...state
                    }
                }
                break;

            case 'UPDATE_CHECKBOX':

                if (action.data.uiData.id && action.data.uiData.id.length > 0) {
                    switch (action.data.uiData.id) {
                        case '@SecondaryPayerCheck':
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@SecondaryPayerCheck'] = action.data.uiData.value;
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@SecondaryPayerCheck': action.data.uiData.value
                                }
                            }
                            break;
                        case '@HoldCode':
                            var localPrnt = state.selectedItem["@AcceptLocalPrint"];
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                if (action.data.uiData.value === "Y" ) {
                                    action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@AcceptLocalPrint'] = "";
                                    localPrnt = "";
                                } else {
                                    action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList["@HoldCode"] = "";  
                                }
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@AcceptLocalPrint': localPrnt,
                                    '@HoldCode': action.data.uiData.value !== "Y" ? "" : state.selectedItem["@HoldCode"]

                                },
                                holdCode1Checked: action.data.uiData.value === "Y" ? action.data.uiData.value : "",
                                holdCodeList1Disabled: action.data.uiData.value === "Y" ? false : true,
                                acceptLclPrntClaimsDisabled: action.data.uiData.value === "Y" ? true : false,
                                holdCode1Error: action.data.uiData.value !== "Y" ? "" : state.holdCode1Error,
                                }
                            break;
                        case '@SwapInsuredOther':
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@SwapInsuredOther'] = action.data.uiData.value;
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@SwapInsuredOther': action.data.uiData.value
                                }
                            }
                            break;
                        case '@AltMatchHoldCode':

                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                if (action.data.uiData.value !== "Y") {
                                    action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@AltMatchHoldCode'] = "";
                                }
                            }

                            return {
                                ...state, selectedItem: {
                                    ...state.selectedItem,
                                    '@AltMatchHoldCode': action.data.uiData.value === "Y" ? state.selectedItem["@AltMatchHoldCode"] : "",
                                },
                                holdCode2Checked: action.data.uiData.value === "Y" ? action.data.uiData.value : "N",
                                holdCodeList2Disabled: action.data.uiData.value === "Y" ? false : true,
                                holdCode2Error: action.data.uiData.value !== "Y" ? "" : state.holdCode2Error,
                            }
                            break;
                        case '@DuplicateFileAllowed':
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@DuplicateFileAllowed'] = action.data.uiData.value;
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@DuplicateFileAllowed': action.data.uiData.value
                                }
                            }
                            break;
                        case '@PreviousBillCheck':
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@PreviousBillCheck'] = action.data.uiData.value;
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@PreviousBillCheck': action.data.uiData.value
                                }
                            }
                            break;
                        case '@AcceptLocalPrint':
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@AcceptLocalPrint'] = action.data.uiData.value;
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@AcceptLocalPrint': action.data.uiData.value
                                }
                            }
                            break;
                        case '@Coinsurance':
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@Coinsurance'] = action.data.uiData.value;
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@Coinsurance': action.data.uiData.value
                                }
                            }
                            break;
                        case '@Deductible':
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@Deductible'] = action.data.uiData.value;
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@Deductible': action.data.uiData.value
                                }
                            }
                            break;
                        case '@PatLiability':
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@PatLiability'] = action.data.uiData.value;
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@PatLiability': action.data.uiData.value
                                }
                            }
                            break;
                        case '@PostDCNYN':
                            if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@PostDCNYN'] = action.data.uiData.value;
                            }
                            return {
                                ...state,
                                selectedItem: {
                                    ...state.selectedItem,
                                    '@PostDCNYN': action.data.uiData.value
                                }
                            }
                            break;
                    }
                }
                break;

            case 'UPDATE_SELECTED_PAYER_CHECKBOX':

                if (action.data.uiData.id && action.data.uiData.id.length > 0) {

                    switch (action.data.uiData.id) {

                        case '@PreviousBillCheckYN':

                            if (state.secondaryBillingItem['@PreviousBillCheckYN'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@PreviousBillCheckYN': action.data.uiData.value,
                                    },
                                    
                                }
                            }
                            break;
                        case '@AcceptLocalPrintYN':

                            if (state.secondaryBillingItem['@AcceptLocalPrintYN'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@AcceptLocalPrintYN': action.data.uiData.value,
                                    },
                                }
                            }
                            break;
                        case '@CoinsuranceYN':

                            if (state.secondaryBillingItem['@CoinsuranceYN'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@CoinsuranceYN': action.data.uiData.value,
                                    },
                                }
                            }
                            break;
                        case '@DeductibleYN':

                            if (state.secondaryBillingItem['@DeductibleYN'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@DeductibleYN': action.data.uiData.value,
                                    },
                                }
                            }
                            break;
                        case '@PatLiabilityYN':

                            if (state.secondaryBillingItem['@PatLiabilityYN'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@PatLiabilityYN': action.data.uiData.value,
                                    },
                                }
                            }
                            break;
                        case '@PostDCNYN':

                            if (state.secondaryBillingItem['@PostDCNYN'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@PostDCNYN': action.data.uiData.value,
                                    },
                                }
                            }
                            break;
                        case '@EnableMedicaidSplit':
                            if (state.secondaryBillingItem['@EnableMedicaidSplit'] !== action.data.uiData.value) {
                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@EnableMedicaidSplit': action.data.uiData.value,
                                    },
                                    medicalRemoveConfirm: undefined,
                                }
                            }
                            break;
                        case '@RemoveZeroPayServices':
                            if (state.secondaryBillingItem['@EnableMedicaidSplit'] === "Y" &&
                                state.secondaryBillingItem['@RemoveZeroPayServices'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@RemoveZeroPayServices': action.data.uiData.value,
                                    }
                                }
                            } else {
                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@RemoveZeroPayServices': 'N',
                                    }
                                }
                            }
                            break;
                    }  
                }
                break;

            case 'UPDATE_SELECTED_PAYER':
                if (action.data.masterCrud) {

                    let item = emptySecondaryBillingItem;
                    var data = action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    if (data) {
                        item = data;
                    }
                    var temp = item["@ID"];

                    return {
                        ...state,
                        //secondaryBillingItem: item,
                        secondaryBillingItem: {
                            ...state.secondaryBillingItem,
                            '@ID': item["@ID"],
                            '@Name': item["@Name"],
                            '@LobID': item["@LobID"],
                            '@PayerID': item["@PayerID"],
                            '@FormType': item["@FormType"],
                            '@TypeOfBill': item["@TypeOfBill"],
                            '@MedicaidSplitClaimLines': item["@MedicaidSplitClaimLines"],
                            '@EnableMedicaidSplit': item["@EnableMedicaidSplit"],
                            '@RemoveZeroPayServices': item["@RemoveZeroPayServices"],
                            '@CoinsuranceYN': item["@CoinsuranceYN"],
                            '@DeductibleYN': item["@DeductibleYN"],
                            '@PatLiabilityYN': item["@PatLiabilityYN"],
                            '@PostDCNYN': item["@PostDCNYN"],
                            '@PostAdjInfo': item["@PostAdjInfo"],
                            '@HCDClaimEOBFormType': item["@HCDClaimEOBFormType"],
                            '@PreviousBillCheckYN': item["@PreviousBillCheckYN"],
                            '@AcceptLocalPrintYN': item["@AcceptLocalPrintYN"],
                        },
                        commercialPayersPointer: item["@LobID"] === "5" ? item["@ID"] : undefined,
                        noncommercialPayersPointer: item["@LobID"] !== "5" ? item["@ID"] : undefined,
                        //clearNonCommercial: item["@LobID"] !== "5" ? 0 : Date.now(),
                        //clearCommercial: item["@LobID"] === "5" ? 0 : Date.now(),
                        /*buttonLabel: "UPDATE",
                        buttonDisabled: false,*/
                        button2Disable: true,
                    }
                }
                break;
            case 'UPDATE_DROPDOWN_PAYER':
                if (action.data.masterCrud) {

                    let item = emptySecondaryBillingItem;
                    var formtype = "P"
                    if (action.data.uiData.value.indexOf("(Inst)") > 0) formtype = "I";
                    var payerID = action.data.uiData.id.replace(/[P,I]/,"")
                    var data = action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling.find(ob =>
                            { return ob["@PayerID"] === payerID && ob["@FormType"] === formtype });
                    if (data) {
                        item = data;
                    } else {
                        var payerObj = action.data.masterCrud.SecondaryBillingSetup.PayerList.Payer.find(ob =>
                                    { return ob["@ID"] === payerID && ob["@FormType"] === formtype });
                        if (payerObj) {
                            item["@Name"] = payerObj["@Name"];
                            item["@PayerID"] = payerObj["@ID"];
                            item["@FormType"] = payerObj["@FormType"];
                            item["@TypeOfBill"] = "D";
                            item["@EnableMedicaidSplit"] = "N";
                            item["@RemoveZeroPayServices"] = "N";
                            item["@PreviousBillCheckYN"] = "Y";
                        }  
                    }
               
                    return {
                        ...state,
                        secondaryBillingItem: {
                            ...state.secondaryBillingItem,
                            '@ID': item["@ID"],
                            '@Name': item["@Name"],
                            '@LobID': item["@LobID"],
                            '@PayerID': item["@PayerID"],
                            '@FormType': item["@FormType"],
                            '@TypeOfBill': item["@TypeOfBill"],
                            '@MedicaidSplitClaimLines': item["@MedicaidSplitClaimLines"],
                            '@EnableMedicaidSplit': item["@EnableMedicaidSplit"],
                            '@RemoveZeroPayServices': item["@RemoveZeroPayServices"],
                            '@CoinsuranceYN': item["@CoinsuranceYN"],
                            '@DeductibleYN': item["@DeductibleYN"],
                            '@PatLiabilityYN': item["@PatLiabilityYN"],
                            '@PostDCNYN': item["@PostDCNYN"],
                            '@PostAdjInfo': item["@PostAdjInfo"],
                            '@HCDClaimEOBFormType': item["@HCDClaimEOBFormType"],
                            '@PreviousBillCheckYN': item["@PreviousBillCheckYN"],
                            '@AcceptLocalPrintYN': item["@AcceptLocalPrintYN"],
                        },
                        allPayersListPointer: action.data.uiData.id
                    }
                }
                break;
            case 'UPDATE_RECORD_BY_TOB':
                if (action.data.masterCrud) {
                    let item = emptySecondaryBillingItem;
                    var localLOB = state.secondaryBillingItem["@LobID"]
                    var data = action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling.find(ob =>
                    {
                        return ob["@PayerID"] === state.secondaryBillingItem["@PayerID"] && ob["@FormType"] === state.secondaryBillingItem["@FormType"] &&
                            ob["@TypeOfBill"] === action.data.uiData.id && (localLOB === "5" ? ob["@LobID"] === "5" : ob["@LobID"]!=="5")
                    });

                    var label = "";
                    if (!data) {
                        label = "ADD";
                        item = state.secondaryBillingItem;
                    } else {
                        label = "UPDATE"
                        item = data;
                    }

                    return {
                        ...state,
                        secondaryBillingItem: {
                            ...state.secondaryBillingItem,
                            '@TypeOfBill': action.data.uiData.id,
                            '@ID': item["@ID"],
                            '@Name': item["@Name"],
                            '@LobID': item["@LobID"],
                            '@PayerID': item["@PayerID"],
                            '@FormType': item["@FormType"],
                            '@MedicaidSplitClaimLines': item["@MedicaidSplitClaimLines"],
                            '@EnableMedicaidSplit': item["@EnableMedicaidSplit"],
                            '@RemoveZeroPayServices': item["@RemoveZeroPayServices"],
                            '@CoinsuranceYN': item["@CoinsuranceYN"],
                            '@DeductibleYN': item["@DeductibleYN"],
                            '@PatLiabilityYN': item["@PatLiabilityYN"],
                            '@PostDCNYN': item["@PostDCNYN"],
                            '@PostAdjInfo': item["@PostAdjInfo"],
                            '@HCDClaimEOBFormType': item["@HCDClaimEOBFormType"],
                            '@PreviousBillCheckYN': item["@PreviousBillCheckYN"],
                            '@AcceptLocalPrintYN': item["@AcceptLocalPrintYN"],
                        },
                        commercialPayersPointer: data && data["@LobID"] === "5" ? data["@ID"] : state.commercialPayersPointer,
                        noncommercialPayersPointer: data && data["@LobID"] !== "5" ? data["@ID"] : state.noncommercialPayersPointer,
                        buttonLabel: label,
                    }
                }
                break;

            case 'SEND_ALERT':
                return {
                    ...state,
                    SecondaryBillingAlert: action.data.uiData.value,
                }
                break;

            case 'SELECT_TAB':

                let item = emptySecondaryBillingItem;
                //If all data commercial/noncommercial is empty will return empty
                let resetId = state.commercialPayersPointer ? state.commercialPayersPointer : state.noncommercialPayersPointer ? state.noncommercialPayersPointer : "";

                if (resetId!=="" && action.data.masterCrud) {
                    var data = action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling.find(ob => { return ob["@ID"] === resetId });
                    if (data) {
                        item = data;
                    }
                }

                let tabIndex :number = Number(action.data.uiData.id);

                return {
                    ...state,
                    secondaryBillingItem: {
                        ...state.secondaryBillingItem,
                        '@ID': item["@ID"],
                        '@Name': item["@Name"],
                        '@LobID': item["@LobID"],
                        '@PayerID': item["@PayerID"],
                        '@FormType': item["@FormType"],
                        '@TypeOfBill': item["@TypeOfBill"],
                        '@MedicaidSplitClaimLines': item["@MedicaidSplitClaimLines"],
                        '@EnableMedicaidSplit': item["@EnableMedicaidSplit"],
                        '@RemoveZeroPayServices': item["@RemoveZeroPayServices"],
                        '@CoinsuranceYN': item["@CoinsuranceYN"],
                        '@DeductibleYN': item["@DeductibleYN"],
                        '@PatLiabilityYN': item["@PatLiabilityYN"],
                        '@PostDCNYN': item["@PostDCNYN"],
                        '@PostAdjInfo': item["@PostAdjInfo"],
                        '@HCDClaimEOBFormType': item["@HCDClaimEOBFormType"],
                        '@PreviousBillCheckYN': item["@PreviousBillCheckYN"],
                        '@AcceptLocalPrintYN': item["@AcceptLocalPrintYN"],
                    },
                    selectedTabIndex: tabIndex,
                    //allPayersListPointer: action.data === state.oldTab && action.data === 0 ? state.allPayersListPointer: "",
                    allPayersListPointer: tabIndex ===  1 ? "" : state.allPayersListPointer ,
                    //commercialPayersPointer: action.data === state.oldTab ?  state.commercialPayersPointer : undefined ,
                    //noncommercialPayersPointer: action.data === state.oldTab ? state.noncommercialPayersPointer : undefined,
                    clearNonCommercial: tabIndex !== state.oldTab && tabIndex === 0 ? Date.now() : state.clearNonCommercial ,
                    clearCommercial: tabIndex !== state.oldTab && tabIndex === 0 ? Date.now() : state.clearCommercial,
                    //oldTab: action.data === 0 ? 0 : 1,
                }
                break;

            case 'UPDATE_REMOVE_BUTTON':

                return {
                    ...state,
                    button2Disable: action.data,
                }
                break;

            case 'UPDATE_ADD_BUTTON':

                return {
                    ...state,
                    buttonDisabled: action.data.uiData.id === "true" ? true : false,
                    buttonLabel: action.data.uiData.value
                }
                break;

            case 'UPDATE_MEDICIAD_COUNT':

                return {
                    ...state,
                    medicaidLineCount: action.data.uiData.value  
                }
                break;

            case 'NON_COMMERCIAL_SELECTED':

                return {
                    ...state,
                    nonCommercialSelected: action.data.uiData.value==="true"? true : false,
                }
                break;

            case 'ADD_UPDATE_BUTTON':

                var newItem = state.secondaryBillingItem

                if (state.buttonLabel === "ADD") {
                    var newName = state.secondaryBillingItem["@Name"]
                    newName = newName.replace(/\([D,O,I]\)/, "")
                    if (state.secondaryBillingItem["@FormType"] !== "P") {
                        newName = newName + " (" + state.secondaryBillingItem["@TypeOfBill"] + ")";
                    }
                    var newID = state.highestSecondaryBillingID++;

                    newItem["@Name"] = newName;
                    newItem["@ID"] = "#" + newID;
                    if (newItem["@LobID"] === "") newItem["@LobID"] = "5";
                    action.data.masterCrud!.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling.push(newItem)

                } else if (state.buttonLabel === "UPDATE") {
                    if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                        action.data.masterCrud!.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling =
                            upsertSecondaryBilling(action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling, state.secondaryBillingItem);
                    }
                } 
                    
                return {
                    ...state,
                    buttonLabel: "UPDATE",
                    commercialPayersPointer: newItem && newItem["@LobID"] === "5" ? newItem["@ID"] : state.commercialPayersPointer,
                    noncommercialPayersPointer: newItem && newItem["@LobID"] !== "5" ? newItem["@ID"] : state.noncommercialPayersPointer,
                    allPayersListPointer: action.data.uiData.id,
                }
                    break;

            case 'REMOVE_BUTTON':

                var newItem = state.secondaryBillingItem
                
                if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                    action.data.masterCrud!.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling =
                        removeRecordSecondaryBilling(action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList.SecondaryBilling, state.secondaryBillingItem);
                }

                return {
                    ...state,
                    //buttonLabel: "UPDATE",
                    commercialPayersPointer: "-1",
                    noncommercialPayersPointer: "-1",
                    allPayersListPointer: "",
                    payerRemoveConfirm: undefined,
                    changed: true
                }
                break;

            case 'UPDATE_DROPDOWN_LIST':

                if (action.data.uiData.id && action.data.uiData.id.length > 0) {

                    switch (action.data.uiData.id) {

                        case '@PostAdjInfo':
                            if (state.secondaryBillingItem['@PostAdjInfo'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@PostAdjInfo': action.data.uiData.value,
                                    },
                                }
                            }
                            break;                                                                          
                        case '@HCDClaimEOBFormType':
                            if (state.secondaryBillingItem['@HCDClaimEOBFormType'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@HCDClaimEOBFormType': action.data.uiData.value,
                                    },
                                }
                            }
                            break;

                        case '@MedicaidSplitClaimLines':
                            if (state.secondaryBillingItem['@MedicaidSplitClaimLines'] !== action.data.uiData.value) {

                                return {
                                    ...state,
                                    secondaryBillingItem: {
                                        ...state.secondaryBillingItem,
                                        '@MedicaidSplitClaimLines': action.data.uiData.value,
                                    },
                                }
                            }
                            break;
                        case '@HoldCode':
                            if (state.selectedItem['@HoldCode'] !== action.data.uiData.value) {

                                var differentCode = false; 
                                if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                    differentCode = action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@HoldCode'] === action.data.uiData.value ? false || state.changed : true;
                                    action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@HoldCode'] = action.data.uiData.value;
                                }
                                return {
                                    ...state,
                                    selectedItem: {
                                        ...state.selectedItem,
                                        '@HoldCode': action.data.uiData.value
                                    },
                                    holdCode1Error: action.data.uiData.value !== "" ? "" : state.holdCode1Error,
                                    changed: differentCode
                                }
                            }
                            break;
                        case '@AltMatchHoldCode':
                            if (state.selectedItem["@AltMatchHoldCode"] !== action.data.uiData.value) {
                                var differentCode = false;
                                if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                    differentCode = action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@AltMatchHoldCode'] === action.data.uiData.value ? false || state.changed : true;
                                    action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@AltMatchHoldCode'] = action.data.uiData.value;
                                }
                                return {
                                    ...state,
                                    selectedItem: {
                                        ...state.selectedItem,
                                        '@AltMatchHoldCode': action.data.uiData.value
                                    },
                                    holdCode2Error: action.data.uiData.value !== "" ? "" : state.holdCode2Error,
                                    changed: differentCode
                                }
                                break;
                            }
                            break;
                        case '@PostAdjInfo*':
                            if (state.selectedItem["@PostAdjInfo"] !== action.data.uiData.value) {
                                var differentCode = false;
                                if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                    differentCode = action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@PostAdjInfo'] === action.data.uiData.value ? false || state.changed : true;
                                    action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@PostAdjInfo'] = action.data.uiData.value;
                                }
                                return {
                                    ...state,
                                    selectedItem: {
                                        ...state.selectedItem,
                                        '@PostAdjInfo': action.data.uiData.value
                                    },
                                    changed: differentCode
                                }
                                break;
                            }
                            break;
                        case '@HCDClaimEOBFormType*':
                            if (state.selectedItem["@HCDClaimEOBFormType"] !== action.data.uiData.value) {
                                var differentCode = false;
                                if (action.data.masterCrud && action.data.masterCrud.SecondaryBillingSetup) {
                                    differentCode = action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@HCDClaimEOBFormType'] === action.data.uiData.value ? false || state.changed : true;
                                    action.data.masterCrud.SecondaryBillingSetup.SecondaryBillingList['@HCDClaimEOBFormType'] = action.data.uiData.value;
                                }

                                return {
                                    ...state,
                                    selectedItem: {
                                        ...state.selectedItem,
                                        '@HCDClaimEOBFormType': action.data.uiData.value
                                    },
                                    changed: differentCode
                                }
                                break;
                            }
                            break;
                    }

                }
                break;
            case 'ERROR_PAYER_REMOVE_CONFIRM':
                return {
                    ...state,
                    payerRemoveConfirm: action.data.uiData.value,
                }
                break;

            case 'ERROR_MEDICAL_REMOVE_CONFIRM':

                return {
                    ...state,
                    medicalRemoveConfirm: action.data.uiData.value,
                }
                break;
            case 'MEDICAL_STORE_E':

                var newVal = (action.data)?"Y":"N"
                return {
                    ...state,
                    secondaryBillingItem: {
                        ...state.secondaryBillingItem,
                        '@EnableMedicaidSplit': newVal,
                    },
                    e: action.data,
                }
                break;
            case 'CLEAR_SPLIT_LINES':

                var newLines = (action.data)
                return {
                    ...state,
                    secondaryBillingItem: {
                        ...state.secondaryBillingItem,
                        '@MedicaidSplitClaimLines': newLines,
                    }
                }
                break;

            case 'ERROR_ALERT_CONFIRM':
                return {
                    ...state,
                    genericAlertOK: action.data,
                }
                break;
            case 'CHANGED_DEFAULTS':
                return {
                    ...state,
                    changed: action.data,
                }
                break;
            case 'NCS_ALERT':
                return {
                    ...state,
                    submitNCSAlert: action.data.uiData.value,
                }
                break;
            case 'CANCEL_WITHOUT_SAVE':
                return {
                    ...state,
                    cancelWOSave: action.data,
                }
                break;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
