/**
 * Date:    03-Apr-19
 * Author:  Luis-Carlos Rodriguez
 * Module:  AssurancePageTitle.txs
 * Purpose: this module is a copy of the legacy AssurancePageTitle.jxs
 *          with a translaltion to TS
 *          Created:
 *          1. created the interfaces for the file
 *          
 * Testing: this module is included with common.js for testing with the new components being
 *          integrated into the ARM.UI clientApp solution
 *          1. conversion in progress
 *
 */
import * as React from 'react'
import * as ReactBootstrap from 'react-bootstrap'
import { LandingPageComponent, IComponentProps } from './LandingPageComponent';
import { URLs } from '@commonDevResources/constants';

// import AssuranceMenu from './fake-components/AssuranceMenu'

//import AssuranceMenu from './assurance.Menu'

interface IProps {
    Name?: string,
    MenuUrl?: string
}

interface ISubmenu  {
    Id: string,
    Type: string,
    Caret: boolean,
    Name: string,
    Link: string,
}


interface IMenuItem {
    Id: string,
    Type?: string,
    Caret?: boolean,
    Name?: string,
    Link?: string,
    Submenu: ISubmenu[],
}

interface IState {
    data?: object,
    userFirstName?: string,
    userLastName?: string,
    brandPath?: string,
    cfiMenu: IMenuItem[],
    LogOffUrl?: string,
    imgPath?: string,
    userRoles: [
        {
            id: number |string,
            roleName: string|undefined|null
        }
    ],
}

const GetCookie = () => console.log('GetCookie')            // LCR-002
const goTo = () => console.log('goTo')                 // LCR-002

export default class AssurancePageTitle extends React.Component<IProps, IState>
{
    constructor(props: IProps) {
        super(props);
        this.state =
            {
                data: {},
                userFirstName: '',
                userLastName: '',
                brandPath: '/default',
                cfiMenu: [],
                userRoles: [
                    {
                        id: 0,
                        roleName: ''
                    }
                ],
            }
    }

    changePasswordUrl = "goTo('/Home/ChangePassword.asp')";







    componentDidMount() {
        console.log("calling AssurancePageTitle.tsx ComponentDidMount()");
        this.getBrandPath();
        console.log("BrandPath"+ this.getBrandPath)
  }
    getBrandPath() {
        console.log("calling after getBrandPath()");
        if (sessionStorage.getItem("brandingPath") !== null) {
            var xhr = new XMLHttpRequest()
            xhr.open('get', URLs.api +"/api/data/securityInfo/brandPath", true);
        xhr.onload = () => {
            var data = JSON.parse(xhr.responseText);
            console.log(data, "checking for Branding path data");
            this.setState({
                brandPath: data.brandPath,
                imgPath: "Content/images/Branding" + data.brandPath + "/HeaderCapLeft.png",
            });
            console.log(data.brandPath);
            sessionStorage.setItem("brandingPath", data.brandPath);
            console.log("page title brandingPath: " + sessionStorage.getItem("brandingPath"));
        };
        xhr.send();
        }
        else {
    let bandPath = sessionStorage.getItem("brandingPath");
    if (bandPath) {
        this.setState({
            brandPath: bandPath,
            imgPath: "Content/images/Branding" + sessionStorage.getItem("brandingPath") + "/HeaderCapLeft.png"
        });
    }
}
}
    getProductName() {
        var linkElement = <a href="http://www.changehealthcare.com/" target="corporate"><img src={this.state.imgPath} alt="" /></a>;

        if (this.state.brandPath === '/Achieve') {
            this.setState({
              imgPath: "Content/images/Branding" + sessionStorage.getItem("brandingPath") + "/LoginLogo.png"
            })
            linkElement = <img src={this.state.imgPath} alt="" />;
        }
        console.log("image path from state", this.state.imgPath)
        return (
            <div id="branding">{linkElement}</div>
        );
    }

    getUserName() {
        if (this.state.userFirstName != null && this.state.userLastName != null) {
            return <span><span className="boldFont">User:</span>&nbsp;{this.state.userFirstName}&nbsp;{this.state.userLastName}</span>;
        }
    }

    persistRoleSelection(eventKey: any) {
        console.log('selected role id:' + eventKey);
        var xhr = new XMLHttpRequest();
        xhr.open('POST', URLs.api + "/api/data/updateuserrole/" + eventKey, true);
        xhr.setRequestHeader("Content-Type", "text/xml");
        xhr.send();
        //        goTo('/Home/UserHome.asp', '');
    }

    getViewAsMenuItems() {
        if (this.state.userRoles && this.state.userRoles.length > 0) {
            var userRoleData = this.state.userRoles;
            var divider = <ReactBootstrap.MenuItem divider />;
            var legacy = <ReactBootstrap.MenuItem eventKey={0} href="#" onSelect={this.persistRoleSelection}>View as Legacy</ReactBootstrap.MenuItem>;
            var menuData = userRoleData.map(role => {  
                if (role.id > 0) return <div>ReactBootstrap.MenuItem eventKey={role.id} goes here </div>
                return <ReactBootstrap.MenuItem eventKey={role.id} href="#" onSelect={this.persistRoleSelection.bind(this)}>View as {role.roleName}</ReactBootstrap.MenuItem>;
            }, this);

            const result = [divider, legacy, menuData];
            return result;
        }
    }

    // menuSelected = (eventKey:string, href:any) => {
    //     eval(eventKey);
    // }

    menuSelected = (eventKey:any, href:any) => {
        eval(eventKey);
    }

    getCfiSubMenuData = ((menuItem: IMenuItem) => {
        return menuItem.Submenu;
    })

    getCfiMenuData = ((data:IMenuItem[]) =>{
        let menuItems:ISubmenu[];
        if (data) {
            //console.log("getMenuData " + data);
            var menuData = data.map( menuItem => {
                //console.log("processing " + menuItem.Name);
                if (menuItem.Type) {
                    switch (menuItem.Type) {
                        case "Menu":
                            menuItems = this.getCfiSubMenuData(menuItem);
                            return <ReactBootstrap.NavDropdown id={menuItem.Id} noCaret={!menuItem.Caret} title={menuItem.Name}>{menuItems}</ReactBootstrap.NavDropdown>;
                        case "Submenu":
                            menuItems = this.getCfiSubMenuData(menuItem);
                            return <ReactBootstrap.NavDropdown id={menuItem.Id} title={menuItem.Name}>{menuItems}</ReactBootstrap.NavDropdown>;
                        case "Item":
                            return <ReactBootstrap.NavItem id={menuItem.Id} eventKey={menuItem.Link} /*[LCR-0411] onSelect={this.menuSelected}*/>{menuItem.Name}</ReactBootstrap.NavItem>;
                        case "Separator":
                            return <ReactBootstrap.NavItem className="divider" href="#" />;
                        default: return ''
                    }
                }
                return ''
            }, this);
            return menuData;
        }
    })

    getCfiMenu = () => {
        return this.getCfiMenuData(this.state.cfiMenu);
    }

    render() {
        // var helpmenu = this.getHelpMenu();
        var helpLink = <span className="boldFont">Help</span>;

        var _this:any = this                                        //[LCR-0414] Used to co-erce this to type IProps from com

        return (
            <LandingPageComponent ComponentClass="assurancePageTitle" {...this.props}>
                <ReactBootstrap.Navbar>
                    <ReactBootstrap.Navbar.Header>
                        <ReactBootstrap.Navbar.Brand>
                            {this.getProductName()}
                        </ReactBootstrap.Navbar.Brand>
                    </ReactBootstrap.Navbar.Header>
                    <ReactBootstrap.Navbar.Collapse>
                        <ReactBootstrap.Nav pullRight>
                            <ReactBootstrap.NavDropdown id={''} eventKey={1} title={this.getUserName()} >
                                <ReactBootstrap.NavItem eventKey={this.changePasswordUrl} href="#" /*onSelect={this.menuSelected}*/>Change Password</ReactBootstrap.NavItem>
                                {this.getViewAsMenuItems()}
                            </ReactBootstrap.NavDropdown>
                            {this.getCfiMenu()}
                            <div>AssuranceMenu Name= HeaderMenu  menuClass= boldFont  itemClass= boldFont  MenuUrl= {URLs.api + '/api/data/GetHeaderMenu'} </div> 
                        </ReactBootstrap.Nav>
                    </ReactBootstrap.Navbar.Collapse>
                </ReactBootstrap.Navbar>
            </LandingPageComponent>
        );
    }
};

// {/*this.getUserName() = luis-carlos*/}

/*
    <ReactBootstrap.Navbar.Header>
        <ReactBootstrap.Navbar.Brand>
            {getProductName()}
        </ReactBootstrap.Navbar.Brand>
    </ReactBootstrap.Navbar.Header>

*/


/**
 * 
 * the render fucntion
 * 
 *       return (
            <div className="assurancePageTitle" style={divStyle}>
                <ReactBootstrap.Navbar>
                    <ReactBootstrap.Navbar.Brand>
                        {this.getProductName()}
                    </ReactBootstrap.Navbar.Brand>
                    <ReactBootstrap.Navbar.Collapse>
                        <Nav >
                            <NavDropdown eventKey={1} title={'luis-carlos'} >
                                <ReactBootstrap.NavItem eventKey={this.changePasswordUrl} href="#" onSelect={this.menuSelected.bind(this)}>Change Password</ReactBootstrap.NavItem>
                                {this.getViewAsReactBootstrap.MenuItems()}
                            </NavDropdown>
                            {this.getCfiMenu()}
                            <AssuranceMenu Name="HeaderMenu" menuClass="boldFont" itemClass="boldFont" MenuUrl="/api/data/GetHeaderMenu" />
                        </Nav>
                    </ReactBootstrap.Navbar.Collapse>
                </ReactBootstrap.Navbar>
            </div>
        );
 */

 // render legacy code

 /**
    renderLegacy() {
        // var helpmenu = this.getHelpMenu();
        //var helpLink = <span className="boldFont">Help</span>;
        var divStyle = evalDivStyle(this);
        return (<div className="assurancePageTitle" style={divStyle}>
                    <ReactBootstrap.Navbar>
                        <ReactBootstrap.Navbar.Header>
                                <ReactBootstrap.Navbar.Brand>
                                    {this.getProductName()}
                                </ReactBootstrap.Navbar.Brand>
                            </ReactBootstrap.Navbar.Header>
                            <ReactBootstrap.Navbar.Collapse>
                                <Nav >
                                <NavDropdown eventKey={1} title={this.getUserName()} >
                                    <ReactBootstrap.NavItem eventKey={this.changePasswordUrl} href="#" onSelect={this.menuSelected.bind(this)}>Change Password</ReactBootstrap.NavItem>
                                            {this.getViewAsReactBootstrap.MenuItems()}
                                </NavDropdown>
                                <AssuranceMenu Name="HeaderMenu" menuClass="boldFont" itemClass="boldFont" MenuUrl="/api/data/GetHeaderMenu" />
                            </Nav>
                        </ReactBootstrap.Navbar.Collapse>
                    </ReactBootstrap.Navbar>
                </div>);

    }
  */
 