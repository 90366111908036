import * as React from 'react';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IViewBridgeLogState, IViewBridgeLogActionProps, actionCreators } from '@store/ViewBridgeLog';
import { URLs } from '@commonDevResources/constants';
import { connect } from 'react-redux';
import { DialogWrapper, DialogButtonOK, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { SortHelper, ISortIndicator, SortDataType, SortOrder } from "@scripts/util/SortHelper"
import styled from 'styled-components';
import { DialogFieldset } from '@common/DialogStyles';
import { ICookies_Config } from '@store/ConfigData';
import { ApplicationState } from '@store/index';
import { SelectDropdownV2, Button } from 'ui-core';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell';
import { getRawToken } from '@scripts/session/SecurityToken';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
        
    #report-id {
        overflow: 'auto',
        padding: '1px',
    }
    #dialog-container{
        align-items: inherit !important;
    }

.dropdownDays{
width:200px !important;
}

table tbody tr > td{
    vertical-align: middle !important;
}

#refresh-bridgeLog: hover{
text-decoration:none !important;
}
button:hover {
background:none;
cursor:pointer;
}


    .EventLabel, .Event {
        vertical-align: inherit;
        width:180px;
    }
    .Client select {
        vertical-align: inherit;
        width:117px;
    }
    .NameLabel, .Name {
        vertical-align: inherit;
        width:400px;
        word-break: break-word;
    }
    .StartLabel, .EndLabel, .Start, .End {
        vertical-align: inherit;
        width:106px;
        word-break: break-word;
    }
     .IntervalLabel, .Interval {
        vertical-align: inherit;
        width:146px;
        word-break: break-word;
    }
    .LagDaysLabel, .LagDays {
        vertical-align: inherit;
        width:148px;
        word-break: break-word;
    }
    .ScheduledLabel, .Scheduled {
        vertical-align: inherit;
        width:206px;
        word-break: break-word;
    }
    .selectDropdown {
        vertical-align: inherit;
    }
    select {
        font-size: 12px;
        font-weight: 600;
    }
    
`;

interface IComponentProps {
    canView: boolean;      // SecurityBits.NDC_VIEW_BRIDGE_LOGS
};

interface IDropDownItem {
    label: string,
    value: string,
    id: string
}

interface IComponentState {
    clientList: IDropDownItem[];
    sortIndicator: {
        sortColumn: string,
        sortDirection: string
    };
    isLoading: boolean;
    isFromSupportTab: boolean;
    isRefreshClicked: boolean;
}

interface HeaderProps { columns: {} }


export interface IRowProps {
    record: {
        client: string,
        date: string,
        time: string,
        process: string,
        thread: string,
        severity: string,
        description: string
    }
}

export const DEFAULT_STATE: IComponentState = {
    clientList: [{ value: '', label: '', id: '' }],
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
    },
    isLoading: false,
    isFromSupportTab: false,
    isRefreshClicked: false
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IViewBridgeLogProps = IMergeCrudComponentProps<IViewBridgeLogState, IViewBridgeLogActionProps, IOwnProps>;


export class ViewBridgeLog extends React.Component<IViewBridgeLogProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canView: false    // SecurityBits.NDC_VIEW_BRIDGE_LOGS
    };

    constructor(props: IViewBridgeLogProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.handleSort = this.handleSort.bind(this);
    }

    public componentDidMount() {
        if (this.props.canView) {
            this.setState({ isRefreshClicked: true });
            this.loadClientsFromServer();
            this.loadBridgeLogDaysAvailable();
            if (this.props.location.pathname.toLowerCase().includes("supporttools/ocilog")) {
                this.setState({ isFromSupportTab: true })
            }
            this.setState({ isLoading: true });
            setTimeout(async () => {
                this.loadBridgeLog("");
            }, 1000)
            
        }
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    loadBridgeLogDaysAvailable() {
        var clientUrl = URLs.api + '/api/data/getBridgeLogDaysAvailable';
        var xhr = new XMLHttpRequest();
        this.setState({ isLoading: true })
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    let data = JSON.parse(responseData);
                    this.props.action.ui.getDateData({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            value: JSON.parse(data)
                        }
                    });
                    this.setState({ isLoading: false })
                } else {
                    console.warn(xhr.status + " response handling " + clientUrl);
                    this.setState({ isLoading: false })
                }
            }
        };
        xhr.open('get', clientUrl, false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();


    }

    loadBridgeLog = (value: string | undefined) => {
        this.setState({ isLoading: true });
        let fromDate = value !== "" ? value : this.props.dataStore.ui.selectDate;
        const splitFromDate = fromDate?.split('/')
        const logDate = splitFromDate !== null && splitFromDate !== undefined ? splitFromDate[0] + "-" + splitFromDate[1] + "-" + splitFromDate[2] : "";
        let isFromNDCSupport = this.state.isFromSupportTab;
        if (logDate !== "") {
            fetch(URLs.api + `/api/data/getBridgeLogEntries/${logDate}/${isFromNDCSupport}`)
                .then(async (response) => {
                    let results: any = await response.json();
                    let jsonData: string = JSON.stringify(results);
                    let data = JSON.parse(jsonData);
                    if (JSON.parse(data).errorfile === "") {
                        this.props.action.ui.getLogData({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                value: {
                                    'errorfile': {
                                        'errorline': []
                                    }
                                }
                            }
                        });
                        this.props.action.ui.getLogFullData({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                value: {
                                    'errorfile': {
                                        'errorline': []
                                    }
                                }
                            }
                        });
                        
                    }
                    else {
                        this.props.action.ui.getLogData({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                value: JSON.parse(data)
                            }
                        });
                        this.props.action.ui.getLogFullData({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                value: JSON.parse(data)
                            }
                        });
                    }
                    setTimeout(() => {
                        this.setState({ isLoading: false });
                    }, 2000);
                    
                })

        }
    }

    getReportListGridColumnHeaders() {
        if (this.state.isFromSupportTab)
            return new Set([
                {
                    dataName: "client",
                    text: 'Client',
                    sortable: true,
                    cellType: "text",
                    isSorted: 1,
                },
                {
                    dataName: "date",
                    text: "Date",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "time",
                    text: "Time",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "process",
                    text: "Process",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "thread",
                    text: "Thread",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "severity",
                    text: "Sev",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "description",
                    text: "Description",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                }
            ]);
        else
            return new Set([

                {
                    dataName: "date",
                    text: "Date",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "time",
                    text: "Time",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "process",
                    text: "Process",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "thread",
                    text: "Thread",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "severity",
                    text: "Sev",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "description",
                    text: "Description",
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                }
            ]);
    }

    public ClientList = (clients: any) => {
        let clientDetails: IDropDownItem[] = [{ value: '', label: '', id: '- Client -' }];
        if (clients !== undefined || clients !== null || clients?.length > 0) {
            clients?.map((cli: any) => {
                clientDetails.push({ value: cli.clientId, label: cli.name, id: cli.id });
            });
        }
        this.setState({ clientList: clientDetails });
    }

    loadClientsFromServer() {
        var clientUrl = URLs.api + '/api/data/securityinfo/securityInfoUserClientAndLinkedClients';
        var xhr = new XMLHttpRequest();
        this.setState({ isLoading: true })
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    let dataClientValues = JSON.parse(responseData);
                    let CID = dataClientValues.filter((itemData: any) => itemData.name === sessionStorage.getItem("Client"));
                    this.ClientList(dataClientValues);

                    if (CID !== undefined && CID !== null && CID.length > 0) {
                        this.props.action.ui.selectClient({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                value: CID[0].clientId,
                                id: CID[0].id
                            }
                        });
                    }

                    this.setState({ isLoading: false })
                } else {
                    console.warn(xhr.status + " response handling " + clientUrl);
                    this.setState({ isLoading: false })
                }
            }
        };
        xhr.open('get', clientUrl, false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    public handleSort(sortIndicator: ISortIndicator) {
        const viewBridgeLogData = this.props.dataStore.ui.logData?.errorfile.errorline;
        let sortedRecords: any = [];
       
        switch (sortIndicator.sortColumn) {
            case 'Client': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewBridgeLogData?.sort((a: any, b: any) => b.client - a.client) :
                    viewBridgeLogData?.sort((a: any, b: any) => a.client - b.client);
            }
                break;
            case 'Date': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewBridgeLogData?.sort((a: any, b: any) => (a.date).toString().localeCompare((b.date).toString())) :
                    viewBridgeLogData?.sort((a: any, b: any) => (b.date).toString().localeCompare((a.date).toString()));
            }
                break;
            case 'Time': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewBridgeLogData?.sort((a: any, b: any) => (a.time).toString().localeCompare((b.time).toString())) :
                    viewBridgeLogData?.sort((a: any, b: any) => (b.time).toString().localeCompare((a.time).toString()));
            }
                break;
            case 'Process': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewBridgeLogData?.sort((a: any, b: any) => (a.process).toString().localeCompare((b.process).toString())) :
                    viewBridgeLogData?.sort((a: any, b: any) => (b.process).toString().localeCompare((a.process).toString()));
            }
                break;
            case 'Thread': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewBridgeLogData?.sort((a: any, b: any) => (a.thread).toString().localeCompare((b.thread).toString())) :
                    viewBridgeLogData?.sort((a: any, b: any) => (b.thread).toString().localeCompare((a.thread).toString()));
            }
                break;
            case 'Severity': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewBridgeLogData?.sort((a: any, b: any) => (a.severity).toString().localeCompare((b.severity).toString())) :
                    viewBridgeLogData?.sort((a: any, b: any) => (b.severity).toString().localeCompare((a.severity).toString()));
            }
                break;
            case 'Description': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewBridgeLogData?.sort((a: any, b: any) => (a.description).toString() - ((b.description).toString())) :
                    viewBridgeLogData?.sort((a: any, b: any) => (b.description).toString() - ((a.description).toString()));
            }
                break;
        }
        this.setState({ sortIndicator });
        this.props.action.ui.getLogData({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: {
                    'errorfile': {
                        'errorline': sortedRecords
                    }
                }
            }
        });
    }

    public getDateDatas() {

        let result: any[] = [];
        if (this.props.dataStore.ui.dateData?.Days?.Day !== undefined && this.props.dataStore.ui.dateData?.Days?.Day !== null && this.props.dataStore.ui.dateData?.Days?.Day.length > 0) {
            this.props.dataStore.ui.dateData?.Days.Day.forEach(ob => {
                if (ob['@Value'])
                    result.push({ label: ob['@Value'], value: ob['@Value'] });
            });
        }
        return result;
    }

    public getDateDataInitial() {
        let result = { value: '', label: '' };
        if (this.props.dataStore.ui.dateData?.Days?.Day !== undefined && this.props.dataStore.ui.dateData?.Days?.Day !== null && this.props.dataStore.ui.dateData?.Days?.Day.length > 0) {
            this.props.dataStore.ui.dateData?.Days.Day?.map((item) => {
                if (item["@Value"] === this.props.dataStore.ui.selectDate) {
                    result.value = item["@Value"];
                    result.label = item["@Value"];
                    return result;
                }
            })

        }
        return result;
    }

    public updateSelectDate(e: any) {
        var _id = e.value;
        var _name = e.label;
        if (_id) {
            this.props.action.ui.selectDate({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: _id
                }
            });
            this.setState({ isRefreshClicked: false})
        }
    }

    getLogRow() {
        const row = ({ record }: IRowProps) =>
            <tr>
                {this.state.isFromSupportTab &&
                    <ARMRowCell value={record.client} />
                }
                <ARMRowCell value={record.date} style={{ verticalAlign: "center" }} />
                <ARMRowCell value={record.time} />

                <ARMRowCell value={record.process} />
                <ARMRowCell value={record.thread} style={{marginLeft:"10px"}} />
                {record.severity.toLowerCase() === "high" &&
                    <ARMRowCell value={record.severity.toUpperCase()} style={{ fontWeight: "bold", color: "red" }} />
                }
                {record.severity.toLowerCase() === "med" &&
                    <ARMRowCell value={record.severity.toUpperCase()} style={{ fontWeight: "bold", color:"#e8ad08" }} />
                }
                {record.severity.toLowerCase() === "low" &&
                    <ARMRowCell value={record.severity.toUpperCase()}  />
                }
                <ARMRowCell value={record.description} />
            </tr >
        return row;
    }

    public onOkViewBridgeLog(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    public onRefreshHandler() {
        this.setState({ isRefreshClicked: true });
        this.loadBridgeLog(this.props.dataStore.ui.selectDate);
    }

    public render() {

        let dateDatas = this.getDateDatas();
        const options = this.state.clientList.map(client => <option
            value={client.id}
            selected={this.props.dataStore.ui.selectClient === client.id}>
            {client.id}
        </option>);

        const clientFilters =
            <select onChange={filter => {
                const value = filter.target.value
                let filterValue: any = [];
                if (filter.target.value === "- Client -") {
                    filterValue = this.props.dataStore.ui.logDataFull?.errorfile?.errorline;
                }
                else {
                    filterValue = this.props.dataStore.ui.logDataFull?.errorfile?.errorline.filter((item: any) => { return item.client === value; });
                }

                this.props.action.ui.getLogData({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: {
                            'errorfile': {
                                'errorline': filterValue,
                            }
                        }
                    }
                });
                this.props.action.ui.selectClient({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: filter.target.value
                    }
                });

            }}>
                {options}
            </select>;


        const header = ({ columns }: HeaderProps) => <thead><tr>
            {
                <React.Fragment>
                    {this.state.isFromSupportTab &&
                        <ARMHeaderCell
                            dataName="Client"
                            text="-Client-"
                            cellType="text"
                            sortable={false}
                            isSorted={0}
                            select={clientFilters}
                        />
                    }
                    <ARMHeaderCell
                        dataName="Date"
                        text="Date"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}

                    />
                    <ARMHeaderCell
                        dataName="Time"
                        text="Time"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Process"
                        text="Process"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Thread"
                        text="Thread"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Severity"
                        text="Sev"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Description"
                        text="Description"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                </React.Fragment>
            }
        </tr></thead>
        const row = this.getLogRow();
        let buttons = <DialogButtonOK onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOkViewBridgeLog(e)} />;
        const instructions = this.state.isFromSupportTab ? <React.Fragment>Use this page to view today's bridge log and archived bridge logs from past days.</React.Fragment> : <React.Fragment> Use this page to view today's bridge log activity and archived bridge logs from past days.<br /> TIP: The bridge log will contain errors if bridge routines fail. Use the log when testing changes to bridge routines.</React.Fragment>;
        return <DialogWrapper title="View Bridge Log" width="1133px" instruction={instructions} buttons={buttons} helpUrl={this.state.isFromSupportTab ? '/SupportTools/Help/SUP_BridgeLog.htm' : '/Support/Help/HELP_BridgeLog.htm'} isBusy={this.state.isLoading} >
            <ContentWrapper>
                <ContentColumnWrapper style={{ padding: "15px" }}>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={{ maxWidth: "215px" }}>
                            <SelectDropdownV2
                                domID="daysdropdown"
                                className="dropdownDays"
                                label="Bridge Log:"
                                width="200px"
                                isClearable={false}
                                onChange={(e: any) => this.updateSelectDate(e)}
                                options={dateDatas}
                                initialValue={this.getDateDataInitial()}

                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper>
                            <ContentRowWrapper style={{ marginTop: "25px" }}>
                                <ContentColumnWrapper style={{ maxWidth: "10px" }}>
                                    <span>
                                        <img src='/shared/images/Icons/RefreshArrow.png' />
                                    </span>
                                </ContentColumnWrapper>
                                <ContentColumnWrapper>
                                    <Button
                                        domID="refresh-bridgeLog"
                                        name="Refresh"
                                        buttonType="link"
                                        size="small"
                                        type="link"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.onRefreshHandler()}>
                                    </Button>
                                </ContentColumnWrapper>
                            </ContentRowWrapper>
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={{ marginTop: "10px" }}>
                            {(this.props.dataStore.ui.logData?.errorfile?.errorline !== undefined && this.props.dataStore.ui.logData?.errorfile?.errorline.length > 0) && this.state.isRefreshClicked ?
                                <ARMGrid
                                    dom-ID="viewBridgeLog"
                                    isFixedHeader={true}
                                    maxHeight='492px'
                                    columns={this.getReportListGridColumnHeaders()}
                                    headerComponent={header}
                                    records={this.props.dataStore.ui.logData?.errorfile.errorline}
                                    rowComponent={row}
                                />
                                :
                                this.props.dataStore.ui.logData?.errorfile?.errorline !== undefined && this.props.dataStore.ui.logData?.errorfile?.errorline.length === 0 && this.props.dataStore.ui.dateData?.Days !== undefined && this.props.dataStore.ui.dateData?.Days?.Day !== undefined && this.props.dataStore.ui.dateData?.Days.Day.length > 0 && this.props.dataStore.ui.dateData?.Days?.Day[0]["@Value"] === this.props.dataStore.ui.selectDate && this.state.isRefreshClicked ? "" :
                                    this.state.isRefreshClicked ? <ARMGrid
                                        dom-ID="viewBridgeLog"
                                        isFixedHeader={true}
                                        maxHeight='492px'
                                        columns={this.getReportListGridColumnHeaders()}
                                        headerComponent={header}
                                        records={this.props.dataStore.ui.logData?.errorfile.errorline}
                                        rowComponent={row}
                                    />
                                        :""
                            }

                            {this.props.dataStore.ui.logData?.errorfile?.errorline !== undefined && this.props.dataStore.ui.logData?.errorfile?.errorline.length === 0 && this.props.dataStore.ui.dateData?.Days !== undefined && this.props.dataStore.ui.dateData?.Days?.Day !== undefined && this.props.dataStore.ui.dateData?.Days.Day.length > 0 && this.props.dataStore.ui.dateData?.Days?.Day[0]["@Value"] === this.props.dataStore.ui.selectDate && this.state.isRefreshClicked &&
                                <span>No bridge activity today.</span>
                            }
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                </ContentColumnWrapper>
            </ContentWrapper>

        </DialogWrapper>
    }

}

var connectedHoc = connect<IViewBridgeLogState, IViewBridgeLogActionProps, IOwnProps, IViewBridgeLogProps, ApplicationState>(
    createCrudMapStateToProps('viewbridgelog'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ViewBridgeLog);

export default withRouter(connectedHoc);