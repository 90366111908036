import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from "styled-components";
import { IMCASimpleErrorRuleState, IMCASimpleErrorRuleActionProps, actionCreators } from '@store/ManageClaimAssignmentsSimpleErrorRules';
import {
    IMergeCrudComponentProps, createCrudMapStateToProps,
    createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { DialogWrapper, ContentRowWrapper, ContentColumnWrapper } from "@common/DialogWrapper";
import { Input, Button, SelectDropdownV2, TabContainer, Grid } from 'ui-core';
import { pageLeave } from '@commonResources/userModified';
import { AssuranceMenu } from '@common/AssuranceMenu';
import { CrudTypes } from '@commonResources/CrudTypes';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { isEmpty } from 'lodash';
import { ARMGridPager } from '@common/UICLWrappers/ARMGridPager';
import { ModalConfirmation, leaveMessage } from '@common/ModalConfirmation';
import { ISortIndicator } from "@scripts/util/SortHelper"
import { ICookies_Config } from '@store/ConfigData';
import {
    IRulesAssignUserInfo, IRulesAssignDepartmentInfo,
    eRulesAssignmentModifyButtonType, RuleAssignmentComponent
} from '@common/RuleAssignmentComponent';

const IMG = styled.img`
    margin-left:5px;
    padding-top: 7px !important;
    cursor: pointer;
`;

const Tip = styled.div`
    overflow: hidden;
    white-space: nowrap;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    #TabContainerResponse
    {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 3px;
        width: 1200px;
        padding: 0px;
    }

    // Tab 1 Without Notes Control Styles
    #UAE-CatFilter {
        margin-right:3px;
        min-width:250px;
        max-width:250px;
    }

    #UAE-FieldFilter {
       margin-right: 3px;
       min-width:170px;
       max-width:170px; 
    }

    #UAE-EditNameFilter {
       min-width:170px;
       max-width:170px; 
    }

    #UAE-Filter-Link {
        padding-left: 0.2em !important;
    }

    #UAE-Clear-Link {
       padding-left: 0.2em !important;
    }
   
    #remove-error {
        margin-top: 5px;
    }
   
    // Tab 2 WithNotes Control Styles
    #AR-CatFilter {
        margin-right:3px;
        min-width:250px;
        max-width:250px;
    }
    
    #AR-FieldFilter {
       margin-right: 3px;
       min-width:170px;
       max-width:170px; 
    }

    #AR-EditNameFilter {
       min-width:170px;
       max-width:170px; 
    }

    #AR-AssignedToFilter {
       min-width:170px;
       max-width:170px; 
       margin-left: 3px;
    }

    #AR-Filter-Link {
        padding-left: 0.2em !important;
    }

    #AR-Clear-Link {
       padding-left: 0.2em !important;
    }

    .selectDropdown__menu{
        width: 370px !important;
    }
`;

const MsgDiv = styled.div`
    width: 100%; height: 25px; 
    text-align: left; 
    margin-top: 10px; 
    margin-bottom: 10px; 
    line-height: initial;
`;

export const SelectButtons = styled.div`
    padding-left: 20px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

const GridWrapper = styled.div`
    #grid-wrapper-UAE-Error-Grid {
        height:400px;
    }

    #grid-wrapper-AR-Rule-Grid {
        height:400px;
    }

    #grid-wrapper-UAE-User-Grid {
        height:200px;
    }

    #grid-wrapper-UAE-Dept-Grid {
        height:200px;
    }

    .empty-row {
        background-color:white;
    }

    thead tr th{
        background-color:#9BA1A9;
        height:12px;
    }

    table tbody tr > td{
        height:12px !important;
        line-height:15px;
    }

    table tbody tr td div{
        min-height:12px;
    }

    .Category, .RuleCategory {
        min-width:250px;
        max-width:250px;
    }

    .Field, .RuleField {
        min-width:170px;
        max-width:170px;
    }

    .EditName, .RuleEditName {
        min-width:170px;
        max-width:170px;
    }

    .Message {
        min-width:400px;
        max-width:400px;
        overflow: hidden;
    }

    .Count {
        min-width:80px;
        max-width:80px;
        direction:rtl;
    }

    .AssignedTo {
        min-width:200px;
        max-width:200px;
        overflow: hidden;
    }

    // User Grid Columns
    .selectedUser {
        min-width:50px;
        max-width:50px;
    }

    .User {
        min-width:250px;
        max-width:250px;
    }

    .AlphaSplit {
        min-width:250px;
        max-width:250px;
    }

    .selectedDept {
        min-width:50px;
        max-width:50px;
    }

    .Department {
        min-width:250px;
        max-width:250px;
    }
`;

const GridWrapperUnassignedErrors = styled.div`
    margin-top: 0.3em;
    width: 100% !important;
    border: black 1px solid;
    background-color: white;
`;

const GridWrapperAssignedRules = styled.div`
    margin-top: 0.3em;
    width: 100% !important;
    border: black 1px solid;
    background-color: white;
`;

export const SelectionErrorMessage = styled.span`
  width: 300px;
  display: block;
  color: ${Colors.digitalRed130};
  ${Typography.small};
  ${Typography.defaultLineHeight};
`;

const AssignmentContainer = styled(RuleAssignmentComponent)`
    width: fit-content;
    margin: 0 auto;
`;

enum eModifyButtonType {
    Add,
    Update
}

export interface IRowProps {
    record: {
        index: string,
        FormType: string,
        Payer: string,
        Field: string,
        EditName: string,
        Message: string,
        AdditionalMsg: string,
    }
}

interface SelectedIndicator {
    pageNumber: number,
    index: string,
}

interface HeaderProps { columns: {} }

interface IComponentProps {
    // Manage Claim Assignments Simple Error Rules Security Bits
    canView: boolean;   // SecurityBits.FN_ASSIGN_ERROR
    canEdit: boolean;   // SecurityBits.FN_ASSIGN_ERROR
    title: string;
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IMCASimpleErrorRuleProps = IMergeCrudComponentProps<IMCASimpleErrorRuleState, IMCASimpleErrorRuleActionProps, IOwnProps>;

interface IComponentState {
    cancelLeave: boolean,

    selectedIndicators: SelectedIndicator[],

    allCategories: MCAErrorCategoryInfo[],
    allFields: MCAFieldInfo[],
    allGenericMessages: MCAGenericMessageInfo[],
    allUsers: MCAUserInfo[],
    allDepartments: MCADepartmentInfo[],

    allUAEs: MCAUAErrorInfo[],
    filteredUAEs: MCAUAErrorInfo[],
    selectedUAEs: MCAUAErrorInfo[],

    selectedUAEUsers: MCAUserInfo[],
    selectedUAEDepartment: MCADepartmentInfo,
    sortIndicator: ISortIndicator,

    allARs: MCARuleInfo[],
    filteredARs: MCARuleInfo[],
    selectedARs: MCARuleInfo[],

    selectedARUsers: MCAUserInfo[],
    selectedARDepartment: MCADepartmentInfo,

    gridUAEPaging: {
        currentPageNumber: number,
        numberOfPages: number,
        recordsPerPage: number,
        totalRecordCount: number
    },

    gridARPaging: {
        currentPageNumber: number,
        numberOfPages: number,
        recordsPerPage: number,
        totalRecordCount: number
    },

    pageIndex: number,
    pageTotal: number,
    isLoading: boolean,
    comingFromCfi: boolean,
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,

    gridUAEPaging: {
        currentPageNumber: 1,
        numberOfPages: 1,
        recordsPerPage: 100,
        totalRecordCount: 0
    },

    gridARPaging: {
        currentPageNumber: 1,
        numberOfPages: 1,
        recordsPerPage: 100,
        totalRecordCount: 0
    },

    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
    },

    selectedIndicators: [],

    allCategories: [],
    allFields: [],
    allGenericMessages: [],
    allUsers: [],
    allDepartments: [],

    allUAEs: [],
    filteredUAEs: [],
    selectedUAEs: [],

    selectedUAEUsers: [],
    selectedUAEDepartment: {
        "@ID": '',
        "@Name": '',
        "@Priority": '',
        deptId: '',
        isSelected: false,
    },

    allARs: [],
    filteredARs: [],
    selectedARs: [],

    selectedARUsers: [],
    selectedARDepartment: {
        "@ID": '',
        "@Name": '',
        "@Priority": '',
        deptId: '',
        isSelected: false,
    },

    pageIndex: 1,
    pageTotal: 1,
    isLoading: true,
    comingFromCfi: false,
}

interface FilterParam {
    Name: string,
    Value: string,
    Type?: string
}

interface IDropDownItem {
    label: string,
    value: string,
}

interface FilterRequest {
    Param: FilterParam[]
}

export class ManageClaimAssignmentsSimpleErrorRules extends React.Component<IMCASimpleErrorRuleProps, IComponentState> {

    readonly MAX_MSG_LENGTH = 160;
    readonly MIN_MSG_LENGTH = 60;
    readonly RECORDS_PER_PAGE = '100';

    clickCount = 0;

    readonly NO_FILTER_MATCH_CRITERIA = "There are no Unassigned Errors that match your criteria. Please modify and search again.";
    readonly NO_UAE_FOUND = "No Unassigned errors found.";
    readonly NO_AR_FOUND = "No Rule Assignments found.";
    readonly SELECT_ROLLUP_FILTER = 'Please filter for Unassigned Errors or select either Category, Field or EditName for Rollup Rule Creation.';
    readonly SELECT_USERORDEPT = 'At least one user or a department must be selected to create a new Claim Assignment Error Rule.';
    readonly MISSING_ROLLUP_FILTER = 'You must select at least Category, Field, or EditName to create a rollup rule.';
    readonly BRIDGE_ROLLUP_NOTALLOWED = 'You cannot create a rollup rule on editname BRIDGE, because the error text is user-specified';

    static defaultProps: IComponentProps = {
        // Manage Claim Assignments Simple Error Rules Security Bits
        canView: false, // SecurityBits.FN_ASSIGN_ERROR
        canEdit: false, // SecurityBits.FN_ASSIGN_ERROR
        title: "Manage Claim Assignment Error Rules",
    };

    constructor(props: IMCASimpleErrorRuleProps) {
        super(props);
        this.state = DEFAULT_STATE;

        this.populatePage = this.populatePage.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.initializeUAEGrid = this.initializeUAEGrid.bind(this);
        this.initializeARGrid = this.initializeARGrid.bind(this);
        this.handlePaging = this.handlePaging.bind(this);
        this.handleSort = this.handleSort.bind(this);
    }

    public componentDidMount() {
        this.props.action.crud.get({ crudId: CrudTypes.mctiClaimAssignmentErrorSimple });
        this.setState({ allUsers: [], allDepartments: [], allCategories: [], isLoading: true })

        this.props.action.configData.getConfigData({
            cookies: [],
            config: [{ name: "MCASimpleQueryDays" }, { name: "MCASimpleRefreshMinutes" }]
        });

        setTimeout(() => {
            this.populatePage()
            this.setState({ isLoading: false }, () => {
                this.buildQueryStringRequest();
            });
        }, 3000);

        pageLeave();
    }

    buildQueryStringRequest = () => {

        //if we are coming back from cfi, we get query string params, we need to apply the filter and take user back to where they were before going to cfi.
        if (this.props.location.search &&
            this.props.location.search.startsWith("?") && !this.props.location.search.startsWith("?statsId")) {
            const qstringParams = this.getQueryStringParams(this.props.location.search);
            let pageNumber = qstringParams.page == undefined || isEmpty(qstringParams.page) ? 1 : qstringParams.page.toString();
            let category: any = qstringParams.category;
            let field: any = qstringParams.field;
            let editName: any = qstringParams.editName;

            this.setState({ comingFromCfi: true });

            if (category == "null") category = null;
            else {
                this.props.action.ui.updateUAECatFilter({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: category }
                });
            }

            if (field == "null") field = null;
            else {
                this.props.action.ui.updateUAEFieldFilter({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: field }
                });
            }

            if (editName == "null") editName = null;
            else {
                this.props.action.ui.updateUAEEditFilter({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: editName }
                });
            }

            this.handlePaging(+pageNumber, category, field, editName);
        }
    }

    public componentWillUnmount() {
        this.setState({ allUsers: [], allDepartments: [], allCategories: [], isLoading: false })
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    getTabs() {
        var tabJson = [
            {
                domID: 'UAETab',
                label: 'Unassigned Errors',
                tabContent: this.UAETab()
            },
            {
                domID: 'ARTab',
                label: 'Assigned Rules',
                tabContent: this.ARTab()
            },
        ];
        return tabJson;
    }

    public handleSort(e: any) {
        const { selectedTabIndex } = this.props.dataStore.ui;
        let sortDirection: string = "ASC";
        let sortColumn: string = e.currentTarget.innerText[0].toUpperCase() + e.currentTarget.innerText.substr(1).toLowerCase();
        sortColumn = selectedTabIndex === 1 && sortColumn !== 'Assigned to' ? "Rule" + sortColumn : sortColumn;

        if (!isEmpty(e.target.className.baseVal)) {
            if (e.target.className.baseVal === "up") {
                sortDirection = (this.state.sortIndicator.sortDirection === "ASC" &&
                    this.state.sortIndicator.sortColumn === sortColumn ||
                    this.state.sortIndicator.sortColumn.includes("Count") ||
                    this.state.sortIndicator.sortColumn.includes("Assigned")) ? "DESC" : "ASC";
            } else if (e.target.className.baseVal === "down") {
                sortDirection = (this.state.sortIndicator.sortDirection === "ASC" &&
                    this.state.sortIndicator.sortColumn === sortColumn ||
                    this.state.sortIndicator.sortColumn.includes("Count") ||
                    this.state.sortIndicator.sortColumn.includes("Assigned")) ? "DESC" : "ASC";
            }
        } else {
            sortDirection = (this.state.sortIndicator.sortDirection === "ASC" &&
                this.state.sortIndicator.sortColumn === sortColumn ||
                this.state.sortIndicator.sortColumn.includes("Count") ||
                this.state.sortIndicator.sortColumn.includes("Assigned")) ? "DESC" : "ASC";
        }

        switch (selectedTabIndex) {
            case 1: {
               if (sortColumn === 'Assigned to') {
                    sortColumn = "AssignedTo";
               }
            }
            break;
            case 0:
            default: {
                if (sortColumn === "Count") {
                    sortColumn = "ErrorCount";
                }
            }
            break;
        }

        this.setState({ isLoading: true }, () => {
                this.setState({
                    sortIndicator: {
                        sortColumn: sortColumn,
                        sortDirection: sortDirection,
                    }
                });
                this.setState({ isLoading: false }, () => { this.applyFilter(false); });
        });
        
    }

    public updateCheckAssignments(e: React.ChangeEvent<HTMLInputElement> | null, obj: MCAUserInfo) {
        const { selectedTabIndex } = this.props.dataStore.ui;
        switch (selectedTabIndex) {
            case 1: {
                if (e !== null) {
                    if (e.target.checked) {
                        this.props.action.ui.updateARUserCheckList({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                id: obj["@ID"]
                            }
                        });
                    }
                    else {
                        this.props.action.ui.updateARUserUnCheckList({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                id: obj["@ID"]
                            }
                        });
                    }
                }
                else {
                    this.props.action.ui.updateARUserCheckList({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            id: obj["@ID"] === "#" ? obj["@UserID"] : obj["@ID"]
                        }
                    });
                }
            }
                break;
            case 0:
            default: {
                if (e !== null) {
                    if (e.target.checked) {
                        this.props.action.ui.updateUAEUserCheckList({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                id: obj["@ID"]
                            }
                        });
                    }
                    else {
                        this.props.action.ui.updateUAEUserUnCheckList({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                id: obj["@ID"]
                            }
                        });
                    }
                }
                else {
                    this.props.action.ui.updateUAEUserCheckList({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            id: obj["@ID"] === "#" ? obj["@UserID"] : obj["@ID"]
                        }
                    });
                }
            }
        }
    }

    public updateCheckAssignmentsUser(obj: MCAUserInfo) {
        const { selectedTabIndex } = this.props.dataStore.ui;
        let userDetail = this.props.dataStore.ui.Users.find(ob => ob["@ID"] === obj["@ID"]);

        switch (selectedTabIndex) {
            case 1: {
                if (userDetail !== undefined) {
                    if (!userDetail.selected) {
                        this.props.action.ui.updateARUserCheckList({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                id: obj["@ID"]
                            }
                        });
                    }
                    else {
                        this.props.action.ui.updateARUserUnCheckList({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                id: obj["@ID"]
                            }
                        });
                    }
                }
                else {
                    this.props.action.ui.updateARUserCheckList({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            id: obj["@ID"]
                        }
                    });
                }
            }
                break;
            case 0:
            default: {
                if (userDetail !== undefined) {
                    if (!userDetail.selected) {
                        this.props.action.ui.updateUAEUserCheckList({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                id: obj["@ID"]
                            }
                        });
                    }
                    else {
                        this.props.action.ui.updateUAEUserUnCheckList({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                id: obj["@ID"]
                            }
                        });
                    }
                }
                else {
                    this.props.action.ui.updateUAEUserCheckList({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            id: obj["@ID"]
                        }
                    });
                }
            }
        }
    }

    public updateAlphaSplit(e: React.ChangeEvent<HTMLInputElement> | null, obj: MCAUserInfo) {
        const { selectedTabIndex } = this.props.dataStore.ui;
        switch (selectedTabIndex) {
            case 1: {
                if (e !== null) {
                    this.props.action.ui.updateARAlphaSplit({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            id: obj["@ID"],
                            alphaSplit: e.target.value.toUpperCase()
                        }
                    });
                }
                else {
                    this.props.action.ui.updateARAlphaSplit({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            id: obj["@ID"] === "#" ? obj["@UserID"] : obj["@ID"],
                            alphaSplit: (obj["@AlphaSplit"] !== null && obj["@AlphaSplit"] !== "" && obj["@AlphaSplit"] !== undefined) ? obj["@AlphaSplit"].toUpperCase() : ""
                        }
                    });
                }
            }
                break;
            case 0:
            default: {
                if (e !== null) {
                    this.props.action.ui.updateUAEAlphaSplit({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            id: obj["@ID"],
                            alphaSplit: e.target.value.toUpperCase()
                        }
                    });
                }
                else {
                    this.props.action.ui.updateUAEAlphaSplit({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            id: obj["@ID"] === "#" ? obj["@UserID"] : obj["@ID"],
                            alphaSplit: (obj["@AlphaSplit"] !== null && obj["@AlphaSplit"] !== "" && obj["@AlphaSplit"] !== undefined) ? obj["@AlphaSplit"].toUpperCase() : ""
                        }
                    });
                }
            }
        }
    }

    populatePage = () => {
        const { allCategories, allUsers, allDepartments, allFields, allGenericMessages } = this.state;
        const { data } = this.props.dataStore.crud;

        if (data) {
            // Category  Population
            if (data.MCAErrorSimpleMaintenanceInfo?.ErrorCategoryList?.ErrorCategory &&
                data.MCAErrorSimpleMaintenanceInfo.ErrorCategoryList.ErrorCategory) {
                if (data.MCAErrorSimpleMaintenanceInfo.ErrorCategoryList.ErrorCategory instanceof Array) {
                    data.MCAErrorSimpleMaintenanceInfo.ErrorCategoryList.ErrorCategory.map((cat: MCAErrorCategoryInfo) => allCategories.push(cat));
                } else {
                    allCategories.push(data.MCAErrorSimpleMaintenanceInfo.ErrorCategoryList.ErrorCategory);
                }
            }

            // Filed Population
            if (data.MCAErrorSimpleMaintenanceInfo?.FieldList?.Field &&
                data.MCAErrorSimpleMaintenanceInfo.FieldList.Field) {
                if (data.MCAErrorSimpleMaintenanceInfo.FieldList.Field instanceof Array) {
                    data.MCAErrorSimpleMaintenanceInfo.FieldList.Field.map((fld: MCAFieldInfo) => allFields.push(fld));
                } else {
                    allFields.push(data.MCAErrorSimpleMaintenanceInfo.FieldList.Field);
                }
            }

            //GenericMessage Population
            if (data.MCAErrorSimpleMaintenanceInfo?.GenericMessageList?.GenericMessage &&
                data.MCAErrorSimpleMaintenanceInfo.GenericMessageList.GenericMessage) {
                if (data.MCAErrorSimpleMaintenanceInfo.GenericMessageList.GenericMessage instanceof Array) {
                    data.MCAErrorSimpleMaintenanceInfo.GenericMessageList.GenericMessage.map((gmsg: MCAGenericMessageInfo) => allGenericMessages.push(gmsg));
                } else {
                    allGenericMessages.push(data.MCAErrorSimpleMaintenanceInfo.GenericMessageList.GenericMessage);
                }
            }

            // User Population
            if (data.MCAErrorSimpleMaintenanceInfo?.UserList?.User &&
                data.MCAErrorSimpleMaintenanceInfo.UserList.User) {
                if (data.MCAErrorSimpleMaintenanceInfo.UserList.User instanceof Array) {
                    data.MCAErrorSimpleMaintenanceInfo.UserList.User.map((user: MCAUserInfo) => allUsers.push(user));
                } else {
                    allUsers.push(data.MCAErrorSimpleMaintenanceInfo.UserList.User);
                }
            }

            // Department Population
            if (data.MCAErrorSimpleMaintenanceInfo?.DepartmentList?.Department &&
                data.MCAErrorSimpleMaintenanceInfo.DepartmentList.Department) {
                if (data.MCAErrorSimpleMaintenanceInfo.DepartmentList.Department instanceof Array) {
                    data.MCAErrorSimpleMaintenanceInfo.DepartmentList.Department.map((dept: MCADepartmentInfo) => allDepartments.push(dept));
                } else {
                    allDepartments.push(data.MCAErrorSimpleMaintenanceInfo.DepartmentList.Department);
                }
            }
        }
    }

    AssignmentsDetails = (department: MCAAssignedDepartment, users: MCAAssignedUserInfo[]): string => {
        let assignments: string = "";
        let assignedUsers: string = "";
        if (users instanceof Array) {
            for (let idx = 0; idx < users.length; idx++) {
                if (isEmpty(assignedUsers)) {
                    assignedUsers = `${this.UserName(users[idx]['@ID'])}`;
                } else {
                    assignedUsers += `,${this.UserName(users[idx]['@ID'])}`;
                };

                if (!isEmpty(assignedUsers) && !isEmpty(users[idx]['@AlphaSplit'])) {
                    assignedUsers += ` (${(users[idx]['@AlphaSplit'])}) `;
                }
            }
        } else {
            assignedUsers = this.UserName(users?.["@ID"]);
            if (!isEmpty(assignedUsers) && !isEmpty(users?.['@AlphaSplit'])) {
                assignedUsers += ` (${(users?.['@AlphaSplit'])}) `;
            }
        }

        if (isEmpty(department) || department?.Department && isEmpty(department?.Department['@ID'])) {
            assignments = `${assignedUsers}`
        } else {
            if (isEmpty(assignedUsers)) {
                assignments = `${this.DeptName(department?.Department?.['@ID'])}`;
            } else {
                let dept: string = this.DeptName(department?.Department?.['@ID']);
                if (dept && !isEmpty(dept)) {
                    assignments = `${dept},${assignedUsers}`;
                }
            }
        }
        return assignments;
    }

    Assignments = (department: MCAAssignedDepartment, users: MCAAssignedUserInfo[]): string => {
        let assignments: string = "";
        let assignedUsers: string = "";
        if (users instanceof Array) {
            for (let idx = 0; idx < users.length; idx++) {
                if (isEmpty(assignedUsers)) {
                    assignedUsers = `${this.UserName(users[idx]['@ID'])}`;
                } else {
                    assignedUsers += `,${this.UserName(users[idx]['@ID'])}`;
                };

                if (!isEmpty(assignedUsers)) {
                    if (!isEmpty(users[idx]['@AlphaSplit'])) {
                        assignedUsers += `*`;
                    }
                }
            }
        } else {
            assignedUsers = this.UserName(users?.["@ID"]);
            if (!isEmpty(assignedUsers)) {
                if (!isEmpty(users?.['@AlphaSplit'])) {
                    assignedUsers += `*`;
                }
            }
        }

        if (isEmpty(department) || department?.Department && isEmpty(department?.Department['@ID'])) {
            assignments = `${assignedUsers}`
        } else {
            if (isEmpty(assignedUsers)) {
                assignments = `${this.DeptName(department?.Department?.['@ID'])}`;
            } else {
                let dept: string = this.DeptName(department?.Department?.['@ID']);
                if (dept && !isEmpty(dept)) {
                    assignments = `${dept},${assignedUsers}`;
                }
            }
        }
        return assignments;
    }

    initializeARGrid = (): any[] => {

        let assignedRuleInfo: MCARuleInfo[] = [];
        const { dataSearch } = this.props.dataStore.crud;
        if (dataSearch?.MCAErrorSimpleMaintenanceInfo?.Rules &&
            dataSearch?.MCAErrorSimpleMaintenanceInfo?.Rules?.Rule) {
            if (dataSearch?.MCAErrorSimpleMaintenanceInfo?.Rules?.Rule instanceof Array) {
                return dataSearch.MCAErrorSimpleMaintenanceInfo.Rules.Rule.map((rule) => {
                    let detailAssignedTo = this.AssignmentsDetails(rule.Departments, rule.Users.User);
                    let assignedTo = this.Assignments(rule.Departments, rule.Users.User);
                    return ({
                        "@RuleCategoryId": rule['@RuleCategoryId'],
                        "@Idx": rule['@Idx'],
                        "@MsgLev1": rule['@MsgLev1'],
                        "@PageNumber": rule['@PageNumber'],
                        "@RuleEditName": rule['@RuleEditName'],
                        "@RuleField": rule['@RuleFieldId'],
                        "@RuleId": rule['@RuleId'],
                        "@RuleFieldId": rule['@RuleFieldId'],
                        Users: JSON.parse(JSON.stringify(rule.Users)),
                        Departments: rule.Departments,
                        RuleCategory: this.CatName(rule['@RuleCategoryId']),
                        RuleField: <><Tip title={rule['@RuleField'] ? rule['@RuleField'] : ""}>{rule['@RuleField'] ? rule['@RuleField'] : ""}</Tip></>,
                        RuleEditName: <><Tip title={rule['@RuleEditName'] ? rule['@RuleEditName'] : ""}>{rule['@RuleEditName'] ? rule['@RuleEditName'] : ""}</Tip></>,
                        AssignedTo: <><Tip title={detailAssignedTo ? detailAssignedTo : ""}>{assignedTo ? assignedTo : ""}</Tip></>,
                        Message: <><Tip title={rule['@MsgLev1']}>{rule['@MsgLev1'] && rule['@MsgLev1'].length > this.MIN_MSG_LENGTH ? (rule['@MsgLev1'].substring(0, this.MIN_MSG_LENGTH).replace(/[\$,]/g, "")) + "..." : rule['@MsgLev1']}</Tip></>,
                    });
                });
            } else {
                let rule: MCARuleInfo = dataSearch.MCAErrorSimpleMaintenanceInfo.Rules.Rule;
                if (rule) {
                    let detailAssignedTo = this.AssignmentsDetails(rule.Departments, rule.Users.User);
                    let assignedTo = this.Assignments(rule.Departments, rule.Users.User);
                    return [{
                        "@RuleCategoryId": rule['@RuleCategoryId'],
                        "@Idx": rule['@Idx'],
                        "@MsgLev1": rule['@MsgLev1'],
                        "@PageNumber": rule['@PageNumber'],
                        "@RuleEditName": rule['@RuleEditName'],
                        "@RuleField": rule['@RuleFieldId'],
                        "@RuleId": rule['@RuleId'],
                        "@RuleFieldId": rule['@RuleFieldId'],
                        Users: JSON.parse(JSON.stringify(rule.Users)),
                        Departments: rule.Departments,
                        RuleCategory: this.CatName(rule['@RuleCategoryId']),
                        RuleField: <><Tip title={rule['@RuleField'] ? rule['@RuleField'] : ""}>{rule['@RuleField'] ? rule['@RuleField'] : ""}</Tip></>,
                        RuleEditName: <><Tip title={rule['@RuleEditName'] ? rule['@RuleEditName'] : ""}>{rule['@RuleEditName'] ? rule['@RuleEditName'] : ""}</Tip></>,
                        AssignedTo: <><Tip title={detailAssignedTo ? detailAssignedTo : ""}>{assignedTo ? assignedTo : ""}</Tip></>,
                        Message: <><Tip title={rule['@MsgLev1']}>{rule['@MsgLev1'] && rule['@MsgLev1'].length > this.MIN_MSG_LENGTH ? (rule['@MsgLev1'].substring(0, this.MIN_MSG_LENGTH).replace(/[\$,]/g, "")) + "..." : rule['@MsgLev1']}</Tip></>,
                    }];
                }
            }
        }

        return assignedRuleInfo;
    }

    CreateRollupConfirmationMessage = () => {

        const { selectedUAECatFilter, selectedUAEFieldFilter, selectedUAEEditFilter } = this.props.dataStore.ui;
        let localArray = [];
        let isUAECatEmpty: boolean = isEmpty(selectedUAECatFilter);
        let isUAEEditEmpty: boolean = isEmpty(selectedUAEEditFilter);
        let isUAEFieldEmpty: boolean = isEmpty(selectedUAEFieldFilter);

        if (!isUAECatEmpty) {
            localArray[localArray.length] = this.CatName(selectedUAECatFilter);
        }

        if (!isUAEEditEmpty) {
            localArray[localArray.length] = selectedUAEEditFilter;
        }

        if (!isUAEFieldEmpty) {
            localArray[localArray.length] = selectedUAEFieldFilter;
        }
        let rollupMessage: string = "";
        if (localArray && localArray.length > 0) {
            rollupMessage = "You are about to create a Rollup rule on ";
            if (localArray.length == 1)
                rollupMessage += localArray[0];
            else if (localArray.length == 2)
                rollupMessage += localArray[0] + " and " + localArray[1];
            else
                rollupMessage += localArray[0] + ", " + localArray[1] + " and " + localArray[2];

            rollupMessage += ". Are you sure you want to continue?";
        }

        this.setRollUpMessage(rollupMessage);
    }

    setRollUpMessage(message: string) {
        this.props.action.ui.rollupMessage(message);
    }

    setRollupValidation(message: string) {
        this.props.action.ui.rollupValidation(message);
    }

    setRollupBridgeValidation(message: string) {
        this.props.action.ui.rollupBridgeValidation(message);
    }

    CreateRollup = () => {
        const { selectedUAEEditFilter, selectedUAECatFilter, selectedUAEFieldFilter } = this.props.dataStore.ui;
        const { filteredUAEs } = this.state;

        let saveErrors: MCASaveSimpleError = {
            MCAErrorSimpleMaintenanceInfo: {
                '@Action': "SaveSimpleMCARequest",
                Rules: { Rule: [] },
            }
        };

        let users = this.rollupUserList();
        let department = this.rollupDepartment();

        let filterBy: MCAUAErrorInfo[] = filteredUAEs;

        // Filter Records based on Rollup Filter
        // Filter Records by Category 
        if (!isEmpty(selectedUAECatFilter)) {

            filterBy = filteredUAEs.filter(err => err['@CategoryId'] === selectedUAECatFilter);
            // Filter Records by Edit Name
            if (!isEmpty(selectedUAEEditFilter)) {
                if (filterBy && filterBy.length > 0) {
                    filterBy = filterBy.filter(err => err.EditName === selectedUAEEditFilter);
                }
            }

            // Filter Records by Field
            if (!isEmpty(selectedUAEFieldFilter)) {
                if (filterBy && filterBy.length > 0) {
                    filterBy = filterBy.filter(err => this.FieldName(err['@FieldId']) === selectedUAEFieldFilter);
                }
            }
        }

        // Fitler Records by Edit Name
        if (!isEmpty(selectedUAEEditFilter)) {
            filterBy = filteredUAEs.filter(err => err.EditName.toLowerCase() === selectedUAEEditFilter.toLowerCase());
            // Filter Records by Category
            if (!isEmpty(selectedUAECatFilter)) {
                if (filterBy && filterBy.length > 0) {
                    filterBy = filterBy.filter(err => err['@CategoryId'] === selectedUAECatFilter);
                }
            }

            // Filter Records by Field
            if (!isEmpty(selectedUAEFieldFilter)) {
                if (filterBy && filterBy.length > 0) {
                    filterBy = filterBy.filter(err => this.FieldName(err['@FieldId']) === selectedUAEFieldFilter);
                }
            }
        }

        // Filter Records by Field
        if (!isEmpty(selectedUAEFieldFilter)) {
            filterBy = filteredUAEs.filter(err => this.FieldName(err['@FieldId']) === selectedUAEFieldFilter);

            // Filter Records by Category
            if (!isEmpty(selectedUAECatFilter)) {
                if (filterBy && filterBy.length > 0) {
                    filterBy = filterBy.filter(err => err['@CategoryId'] === selectedUAECatFilter);
                }
            }

            // Filter Records by Edit Name
            if (!isEmpty(selectedUAEEditFilter)) {
                if (filterBy && filterBy.length > 0) {
                    filterBy = filterBy.filter(err => err.EditName === selectedUAEEditFilter);
                }
            }
        }

        if (filterBy && filterBy.length > 0) {
            let idx: number = 1;
            let errorInfo: MCAUAErrorInfo = filterBy[0];
            if (errorInfo) {
                let rule: MCAUAERule = {
                    "@RuleId": errorInfo['@MCAErrorIdx'],
                    "@CategoryID": isEmpty(selectedUAECatFilter) ? '' : errorInfo['@CategoryId'],
                    "@FieldID": isEmpty(selectedUAEFieldFilter) ? '' : errorInfo['@FieldId'],
                    "@RuleEditName": isEmpty(selectedUAEEditFilter) ? '' : errorInfo.EditName,
                    "@ID": "#" + idx,
                    Users: users && users.User && users.User.length > 0 ? users : null,
                    Departments: department && department.Department && department.Department.length > 0 ? department : null
                }

                saveErrors.MCAErrorSimpleMaintenanceInfo.Rules.Rule.push(rule);

                if (saveErrors && saveErrors.MCAErrorSimpleMaintenanceInfo.Rules.Rule.length > 0) {

                    this.props.action.crud.update({
                        crudId: CrudTypes.mctiClaimAssignmentErrorSimple,
                        data: saveErrors
                    });

                    let clearUAE = filteredUAEs.filter(function (uae) {
                        return filterBy.filter(function (fuae) {
                            return fuae["@MCAErrorIdx"] === uae['@MCAErrorIdx'];
                        }).length == 0
                    });

                    this.setState({ filteredUAEs: clearUAE });
                    this.props.action.ui.filteredUAEs(clearUAE)
                }
            }

            this.applyFilter();
        }
    }

    rollupUserList = () => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        let rollupUsers: MCAUAEUsers = { User: [] };

        switch (selectedTabIndex) {
            case 1: {
                const { selectedARUsers } = this.state;

                if (selectedARUsers) {
                    if (selectedARUsers instanceof Array) {
                        let idx: number = 1;
                        selectedARUsers.forEach(usr => {
                            const user = {
                                '@ID': usr.userId,
                                '@UserID': usr.userId,
                                '@AlphaSplit': usr.alphaSplit
                            };
                            rollupUsers?.User?.push(user);
                            idx++;
                        });
                    }
                }
            }
                break;
            case 0:
            default: {
                const { selectedUAEUsers } = this.state;
                if (selectedUAEUsers) {
                    if (selectedUAEUsers instanceof Array) {
                        let idx: number = 1;
                        selectedUAEUsers.forEach(usr => {
                            const user = {
                                '@ID': '#' + idx,
                                '@UserID': usr.userId,
                                '@AlphaSplit': usr.alphaSplit
                            };
                            rollupUsers?.User?.push(user);
                            idx++;
                        });
                    }
                }
            }
                break;
        }

        return rollupUsers;
    }

    rollupDepartment = () => {
        const { selectedTabIndex } = this.props.dataStore.ui;

        let rollupDepartments: MCAUAEDepartments = { Department: [] };

        switch (selectedTabIndex) {
            case 1: {
                const { selectedARDepartment } = this.state;
                if (selectedARDepartment) {
                    const department: MCAUAEDepartment = {
                        '@ID': selectedARDepartment.deptId ? selectedARDepartment.deptId : '#1',
                        '@DepartmentID': selectedARDepartment.deptId ? selectedARDepartment.deptId : ''
                    };
                    if (!isEmpty(department['@DepartmentID'])) {
                        rollupDepartments?.Department?.push(department);
                    }
                }
            }
                break;
            case 0:
            default: {
                const { selectedUAEDepartment } = this.state;
                if (selectedUAEDepartment) {
                    const department: MCAUAEDepartment = {
                        '@ID': '#',
                        '@DepartmentID': selectedUAEDepartment.deptId ? selectedUAEDepartment.deptId : ''
                    };
                    if (!isEmpty(department['@DepartmentID'])) {
                        rollupDepartments?.Department?.push(department);
                    }
                }
            }
                break;
        }

        return rollupDepartments;
    }

    seletedDepartment = (): MCAUAEDepartments => {
        const { selectedUAEDepartment } = this.state;
        let assignedDepartments: MCAUAEDepartments = { Department: [] };
        if (selectedUAEDepartment) {
            let department: MCAUAEDepartment = {
                '@ID': selectedUAEDepartment.deptId ? selectedUAEDepartment.deptId : '',
                '@DepartmentID': selectedUAEDepartment.deptId ? selectedUAEDepartment.deptId : ''
            };
            if (!isEmpty(department['@ID']) && !isEmpty(department['@DepartmentID'])) {
                assignedDepartments?.Department?.push(department);
            }
        }
        return assignedDepartments;
    }

    CreateNewRule = () => {
        //@ts-ignore
        const { selectedUAEsList } = this.props.dataStore.ui

        const {  selectedUAEUsers, selectedUAEDepartment, filteredUAEs, allFields } = this.state;
        const { selectedUAECatFilter, selectedUAEFieldFilter, selectedUAEEditFilter, rollup_Message, rollup_Validation, rollup_Bridge_Validaiton } = this.props.dataStore.ui;

        const selectedUAEs = selectedUAEsList ?? [];

        if ((selectedUAEs && selectedUAEs.length === 0 && filteredUAEs && filteredUAEs.length === 0) &&
            isEmpty(selectedUAECatFilter) && isEmpty(selectedUAEFieldFilter) && isEmpty(selectedUAEEditFilter)) {
            this.validationMessageForUAE(this.SELECT_ROLLUP_FILTER);
        } else {

            if ((selectedUAEUsers && selectedUAEUsers.length === 0) && selectedUAEDepartment.isSelected === false) {
                this.validationMessageForUAE(this.SELECT_USERORDEPT);
            } else {
                let selectedErrors: MCASaveSimpleError = {
                    MCAErrorSimpleMaintenanceInfo: {
                        '@Action': "SaveSimpleMCARequest",
                        Rules: { Rule: [] },
                    }
                };

                let selectedUsers: MCAUAEUsers = this.rollupUserList();
                let selectedDepartment: MCAUAEDepartments = this.rollupDepartment();

                if (selectedUAEs && selectedUAEs.length > 0) {
                    let idx = 1;
                    if (selectedUAEs && selectedUAEs instanceof Array) {
                        selectedUAEs.forEach(uae => {
                            let rule: MCAUAERule = {
                                "@ID": '#' + idx,
                                "@RuleId": '#' + idx,
                                "@FieldID": uae['@FieldId'],
                                "@ErrCode": uae['@ErrCode'],
                                "@RuleEditName": uae['@EditName'] ? uae['@EditName'] : uae.EditName ? uae.EditName : '',
                                "@MsgLev1": uae['@MsgLev1'],
                                "@CategoryID": uae['@CategoryId'],
                                Users: selectedUsers,
                                Departments: selectedDepartment
                            }
                            selectedErrors.MCAErrorSimpleMaintenanceInfo.Rules.Rule.push(rule);
                            idx++;
                        })
                    }

                    this.props.action.crud.update({
                        crudId: CrudTypes.mctiClaimAssignmentErrorSimple,
                        data: selectedErrors
                    });

                    let clearUAE = filteredUAEs.filter(uae => {
                        return selectedUAEs.filter((suae: { [x: string]: string; EditName: string; }) => {
                            return suae['@CategoryId'] === uae['@CategoryId'] && suae['@FieldId'] === uae['@FieldId'] &&
                                (suae['@EditName'] === uae['@EditName'] || suae.EditName === uae.EditName) &&
                                suae['@MsgLev1'] === uae['@MsgLev1'];
                        }).length == 0
                    });

                    this.setState({ filteredUAEs: [] }, () => {
                        this.setState({ filteredUAEs: clearUAE });
                    });
                    this.props.action.ui.filteredUAEs(clearUAE)


                } else {
                    // UAE is not filtered and Category, Field, Edit are empty 
                    if (filteredUAEs && filteredUAEs.length === 0 &&
                        isEmpty(selectedUAECatFilter) && isEmpty(selectedUAEFieldFilter) && isEmpty(selectedUAEEditFilter)) {
                        this.setRollupValidation(this.SELECT_ROLLUP_FILTER);
                    }
                    if (selectedUAEUsers && selectedUAEUsers.length === 0 && isEmpty(selectedUAEDepartment)) {
                        this.setRollupValidation(this.SELECT_USERORDEPT);
                    } else {
                        // Rollup Requires Category or Field or Edit Name Filter
                        if (isEmpty(selectedUAECatFilter) && isEmpty(selectedUAEFieldFilter) && isEmpty(selectedUAEEditFilter)) {
                            this.setRollupValidation(this.MISSING_ROLLUP_FILTER);
                        }

                        if (!isEmpty(selectedUAEFieldFilter)) {
                            if (allFields && allFields.length > 0) {
                                let fieldIndex = allFields.findIndex(fld => this.FieldName(fld['@ID']) === selectedUAEFieldFilter);
                                if (fieldIndex === -1) {
                                    this.setRollupValidation(`The field ${selectedUAEFieldFilter} entered to create a rollup rule is invalid.`);
                                } else {
                                    this.CreateRollupConfirmationMessage();
                                    if (!rollup_Validation && !isEmpty(rollup_Message)) {
                                        this.CreateRollup();
                                    }
                                }
                            }
                        } else if (!isEmpty(selectedUAECatFilter) || !isEmpty(selectedUAEEditFilter)) {

                            // For Bridge Edit No Rollup
                            if (!isEmpty(selectedUAEEditFilter) && selectedUAEEditFilter.toLowerCase() === "bridge") {
                                this.setRollupBridgeValidation(this.BRIDGE_ROLLUP_NOTALLOWED);
                            } else {
                                if (!rollup_Bridge_Validaiton && isEmpty(rollup_Bridge_Validaiton)) {
                                    this.CreateRollupConfirmationMessage();
                                    if (!rollup_Validation && !isEmpty(rollup_Message)) {
                                        this.CreateRollup();
                                    }
                                }
                            }
                        }
                    }
                }

                this.applyFilter();
            }

            this.deselectAllRows();
            this.setState({ selectedUAEs: [] });
        }
    }

    assignedUsers = (): IRulesAssignUserInfo[] => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        const { allUsers } = this.state;
        let ruleAssignUsers: IRulesAssignUserInfo[] = [];
        if (allUsers) {
            if (allUsers instanceof Array) {
                switch (selectedTabIndex) {
                    case 1: {
                        const { selectedARUsers } = this.state;
                        if (selectedARUsers && selectedARUsers instanceof Array) {
                            allUsers?.forEach(usr => {
                                let selectedUser = selectedARUsers.find(susr => susr.userId === usr['@ID']);

                                let alphasplit: string | undefined;
                                if (selectedUser == undefined) {
                                    alphasplit = usr['alphaSplit']
                                }
                                else {
                                    alphasplit = selectedUser['alphaSplit']
                                }

                                let ruleAssignUser: any = {
                                    selected: selectedUser ? true : false,
                                    user: `${usr['@LastName']} ${usr['@FirstName']} (${usr['@Name']})`,
                                    alphaSplit: alphasplit ? (alphasplit || '') : '',
                                    userId: usr['@ID'] ? usr['@ID'] : '',
                                    isDisabled: false
                                };
                                if (ruleAssignUsers?.length > 0) {
                                    let userIndex = ruleAssignUsers?.findIndex(rau => rau.userId === selectedUser?.['@ID']);
                                    if (userIndex === -1) {
                                        ruleAssignUsers.push(ruleAssignUser);
                                    }
                                } else {
                                    ruleAssignUsers.push(ruleAssignUser);
                                }

                            });
                        } else {
                            allUsers?.forEach(usr => {
                                let selectedUser = selectedARUsers;
                                let ruleAssignUser: any = {
                                    selected: selectedUser ? true : false,
                                    user: `${usr['@LastName']} ${usr['@FirstName']} (${usr['@Name']})`,
                                    alphaSplit: selectedUser ? selectedUser['alphaSplit'] : '',
                                    userId: usr['@ID'] ? usr['@ID'] : '',
                                    isDisabled: false
                                };
                                if (ruleAssignUsers?.length > 0) {
                                    let userIndex = ruleAssignUsers?.findIndex(rau => rau.userId === selectedUser?.['@ID']);
                                    if (userIndex === -1) {
                                        ruleAssignUsers.push(ruleAssignUser);
                                    }
                                } else {
                                    ruleAssignUsers.push(ruleAssignUser);
                                }
                            });
                        }
                        return ruleAssignUsers;
                    }
                    case 0:
                    default: {
                        const { selectedUAEUsers } = this.state;

                        allUsers?.forEach(usr => {
                            let selectedUser = selectedUAEUsers.find(susr => susr.userId === usr['@ID']);
                            let ruleAssignUser: any = {
                                selected: selectedUser ? true : false,
                                user: `${usr['@LastName']} ${usr['@FirstName']} (${usr['@Name']})`,
                                alphaSplit: selectedUser ? selectedUser['alphaSplit'] : '',
                                userId: usr['@ID'] ? usr['@ID'] : '',
                                isDisabled: false
                            };
                            if (ruleAssignUsers?.length > 0) {
                                let userIndex = ruleAssignUsers?.findIndex(rau => rau.userId === selectedUser?.['@ID']);
                                if (userIndex === -1) {
                                    ruleAssignUsers.push(ruleAssignUser);
                                }
                            } else {
                                ruleAssignUsers.push(ruleAssignUser);
                            }
                        });

                        return ruleAssignUsers;
                    }
                }

            }
        }

        return ruleAssignUsers;
    }

    assignedDepartment = (): IRulesAssignDepartmentInfo[] => {
        const { allDepartments } = this.state;

        let ruleAssignDepartments: IRulesAssignDepartmentInfo[] = [];
        if (allDepartments) {
            if (allDepartments instanceof Array) {
                allDepartments.forEach(dept => {
                    let ruleAssignDepartment: IRulesAssignDepartmentInfo = {
                        deptId: dept['@ID'] ? dept['@ID'] : '',
                        name: `${dept['@Name']} (${dept['@Priority']})`,
                        selected: false,
                    };
                    if (ruleAssignDepartments?.length > 0) {
                        let index = ruleAssignDepartments?.findIndex(sdpt => sdpt.deptId === ruleAssignDepartment.deptId);
                        if (index === -1) {
                            ruleAssignDepartments.push(ruleAssignDepartment);
                        }
                    } else {
                        ruleAssignDepartments.push(ruleAssignDepartment);
                    }
                });
            } else {
                let ruleAssignDepartment: IRulesAssignDepartmentInfo = {
                    deptId: allDepartments['@ID'],
                    name: `${allDepartments['@Name']} (${allDepartments['@Priority']})`,
                    selected: false,
                };
                ruleAssignDepartments.push(ruleAssignDepartment);
            }
        }

        return ruleAssignDepartments;
    }

    public updateUsers = (users: IRulesAssignUserInfo[]) => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        const ids = users.map(o => o.userId)
        const filtered = users.filter(({ userId }, index) => !ids.includes(userId, index + 1))

        if (users) {
            switch (selectedTabIndex) {
                case 1: {
                    this.setState({ selectedARUsers: [...filtered] });
                }
                    break;
                case 0:
                default: {
                    this.setState({ selectedUAEUsers: [...filtered] });
                }
                    break;
            }
        }
    }


    public setAlphaSplit(user: IRulesAssignUserInfo, alphaSplit: string) {
        const { selectedTabIndex } = this.props.dataStore.ui;

        if (user) {
            switch (selectedTabIndex) {
                case 1: {
                   if( this.state.selectedARUsers && this.state.selectedARUsers.length){
                    let userIndex: number = this.state.selectedARUsers.findIndex(susr => susr.userId === user.userId);
                    if (userIndex > -1) {
                        let selectedARUsers = this.state.selectedARUsers;
                        selectedARUsers[userIndex].alphaSplit = alphaSplit;
                        selectedARUsers[userIndex]['@AlphaSplit'] = alphaSplit;
                        this.setState({selectedARUsers:selectedARUsers});
                    }
                }
                }
                    break;
                case 0:
                default: {
                    if( this.state.selectedUAEUsers && this.state.selectedUAEUsers.length){
                    let userIndex: number = this.state.selectedUAEUsers.findIndex(susr => susr.userId === user.userId);
                    if (userIndex > -1) {
                        let selectedUAEUsers = this.state.selectedUAEUsers;
                        selectedUAEUsers[userIndex].alphaSplit = alphaSplit;
                        selectedUAEUsers[userIndex]['@AlphaSplit'] = alphaSplit;
                        this.setState({selectedUAEUsers:selectedUAEUsers});
                    }
                }
                }
                    break;
            }
        }

    }

    //..event from radio button
    public updateDepartment = (dept: IRulesAssignDepartmentInfo) => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        if (dept) {
            switch (selectedTabIndex) {
                case 1: {
                    this.setState({
                        //@ts-ignore
                        selectedARDepartment: dept
                    }, () => {
                        this.printState()
                    })
                }
                    break;
                case 0:
                default: {
                    this.setState({
                        //@ts-ignore
                        selectedUAEDepartment: dept
                    }, () => {
                        this.printState()
                    })
                }
            }
        }
    }

    public printState() {
       // console.log(this.state)
    }

    ruleUsers = (rule: MCARuleInfo): MCAAssignedUserInfo[] => {
        let ruleUsers: MCAAssignedUserInfo[] = [];
        if (rule && rule.Users && rule.Users.User) {
            if (rule.Users.User instanceof Array) {
                rule.Users.User.forEach(usr => ruleUsers.push(usr));
            } else {
                ruleUsers.push(rule.Users.User)
            }
        }
        return ruleUsers;
    }

    ruleDepartment = (rule: MCARuleInfo): MCAAssignedDepartment => {
        let ruleDepartment: MCAAssignedDepartment = { Department: { '@Idx': '', '@ID': '', '@Name': '' } };
        if (rule && rule.Departments && rule.Departments.Department) {
            ruleDepartment = rule.Departments;
        }
        return ruleDepartment;
    }

    UAETab = () => {
        const { filteredUAEs, gridUAEPaging, selectedUAEDepartment } = this.state;
        const { selectedUAECatFilter, selectedUAEFieldFilter, selectedUAEEditFilter } = this.props.dataStore.ui;
        return (
            <React.Fragment>
                <ContentWrapper>
                    <ContentColumnWrapper>
                        <ContentRowWrapper>
                            <SelectDropdownV2
                                domID="UAE-CatFilter"
                                className="dropdown"
                                size='small'
                                isClearable={false}
                                isSearchable={true}
                                onChange={(e: any) => this.onChangeCatFilter(e.value)}
                                options={this.CatList()}
                                initialValue={this.getInitialValue(this.CatList(), selectedUAECatFilter ?? {})}
                                disabled={false}
                            />
                            <Input
                                domID="UAE-FieldFilter"
                                className="text-input"
                                maxLength={20}
                                width='150px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeFieldFilter(e.target.value.toUpperCase())}
                                initialValue={selectedUAEFieldFilter}
                            />
                            <Input
                                domID="UAE-EditNameFilter"
                                className="text-input"
                                maxLength={20}
                                width='150px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEditFilter(e.target.value)}
                                initialValue={selectedUAEEditFilter}
                            />
                            <React.Fragment>
                                <span>
                                    <IMG src='/shared/images/Icons/RefreshArrow.png' onClick={() => this.applyFilter()} />
                                </span>
                                <Button
                                    domID="UAE-Filter-Link"
                                    name="Filter"
                                    buttonType="link"
                                    size="small"
                                    type="link"
                                    onClick={(e: React.MouseEvent<HTMLElement>) => this.applyFilter()}>
                                </Button>
                            </React.Fragment>
                            <Button
                                domID="UAE-Clear-Link"
                                name="Clear"
                                buttonType="link"
                                size="small"
                                type="link"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.ClearFilter()}>
                            </Button>
                        </ContentRowWrapper>
                    </ContentColumnWrapper>
                    <ContentColumnWrapper>
                        <ContentRowWrapper>
                            <GridWrapperUnassignedErrors>
                                <GridWrapper>
                                    <Grid
                                        domID="UAE-Error-Grid"
                                        isFixedHeader={true}
                                        maxHeight='385px'
                                        columns={this.UAEGridColumns()}
                                        rowComponent={this.getCustomRowUAES()}
                                        records={filteredUAEs}
                                        onSortGridColumn={(e: any) => { this.handleSort(e) }}
                                        selectionKey='Category'
                                        // the sorting is handled by the api response. This stops the grid from doing it's own sorting:
                                        sortingComparator={() => filteredUAEs}
                                    />
                                </GridWrapper>
                            </GridWrapperUnassignedErrors>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <MsgDiv id="UAE-Grid-Msg">Double Click on any error to view last 10 unreleased claims with that error.
                                <br />** Note: Errors can be removed from claims and may not be reflected in the requested claim list.
                            </MsgDiv>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <div style={{ marginTop: 5, flexGrow: 1, textAlign: 'right', position: 'relative', left: 20, top: -7 }}>
                                <ARMGridPager
                                    currentPageNumber={gridUAEPaging.currentPageNumber}
                                    numberOfPages={gridUAEPaging.numberOfPages}
                                    pagingHandler={this.handlePaging}
                                />
                            </div>
                        </ContentRowWrapper>
                        <ContentColumnWrapper>
                            <ContentColumnWrapper style={{ alignItems: 'center' }} >
                                {/*@ts-ignore*/}
                                <AssignmentContainer
                                    containerTitle='Assignments'
                                    allUsers={this.assignedUsers()}
                                    buttonType={eRulesAssignmentModifyButtonType.Add}
                                    allDepartments={this.assignedDepartment()}
                                    showExpiresOn={false}
                                    enableButtons={true}
                                    onSelectUsersCallback={(users: IRulesAssignUserInfo[]) => this.updateUsers(users)}
                                    onUserAlphaSplitCallback={(user: IRulesAssignUserInfo, alphaSplit: string) => this.setAlphaSplit(user, alphaSplit)}
                                    onNewCallback={this.CreateNewRule}
                                    //@ts-ignore
                                    selectedDepartment={selectedUAEDepartment}
                                    onSelectDepartmentsCallback={(depts: IRulesAssignDepartmentInfo) => this.updateDepartment(depts)}
                                />
                            </ContentColumnWrapper>
                        </ContentColumnWrapper>
                    </ContentColumnWrapper>
                </ContentWrapper>
            </React.Fragment>);
    }

    onlyInLeft = (ruleUsers: MCAAssignedUserInfo[], selectedUsers: MCAAssignedUserInfo[], compareFunction: any) => {
        return ruleUsers.filter((ruleUser: MCAAssignedUserInfo) =>
            !selectedUsers.some((selectedUser: MCAAssignedUserInfo) => compareFunction(ruleUser, selectedUser)));
    }

    isSameUser = (ruleUser: MCAAssignedUserInfo, selectedUser: MCAAssignedUserInfo) => {
        return ruleUser["@ID"] === selectedUser["@ID"] && ruleUser["@AlphaSplit"] === selectedUser["@AlphaSplit"];
    }

    getUsers2Delete = (ruleUsers: MCAAssignedUserInfo[], selectedUsers: MCAAssignedUserInfo[]): MCAAssignedUserInfo[] => {
        let deletedUsers: MCAAssignedUserInfo[] = this.onlyInLeft(ruleUsers, selectedUsers, this.isSameUser);
        return deletedUsers;
    }

    getNewUsers = (ruleUsers: MCAAssignedUserInfo[], selectedUsers: MCAAssignedUserInfo[]): MCAAssignedUserInfo[] => {
        let newUsers: MCAAssignedUserInfo[] = this.onlyInLeft(selectedUsers, ruleUsers, this.isSameUser);
        return newUsers;
    }

    onlyInBoth = (ruleUsers: MCAAssignedUserInfo[], selectedUsers: MCAAssignedUserInfo[], compareFunction: any) => {
        return ruleUsers.filter((ruleUser: MCAAssignedUserInfo) =>
            selectedUsers.some((selectedUser: MCAAssignedUserInfo) => compareFunction(ruleUser, selectedUser)));
    }

    getCommonUsers = (ruleUsers: MCAAssignedUserInfo[], selectedUsers: MCAAssignedUserInfo[]): MCAAssignedUserInfo[] => {
        let commonUsers: MCAAssignedUserInfo[] = this.onlyInBoth(ruleUsers, selectedUsers, this.isSameUser);
        return commonUsers;
    }

    selectedAssignedUserInfo = (): MCAAssignedUserInfo[] => {
        const { selectedARUsers } = this.state;
        let selectedUsers: MCAAssignedUserInfo[] = [];
        if (selectedARUsers && selectedARUsers instanceof Array) {
            selectedARUsers.forEach(susr => {
                let ruleAssignUser: MCAAssignedUserInfo = {
                    selected: susr.selected ? true : false,
                    '@Idx': susr.userId ? susr.userId : '',
                    '@ID': susr.userId ? susr.userId : '',
                    '@AlphaSplit': susr.alphaSplit ? susr.alphaSplit : ''
                };
                let ruleUserIndex: number = selectedUsers.findIndex(susr => susr['@ID'] === ruleAssignUser['@ID']);
                if (ruleUserIndex === -1) {
                    selectedUsers.push(ruleAssignUser);
                }
            });
        }

        return selectedUsers;
    }

    UpdateRules = () => {
        const { selectedARs, selectedARDepartment } = this.state;

        // CRUD Data Structure
        let updateRules: MCASaveSimpleError = {
            MCAErrorSimpleMaintenanceInfo: {
                '@Action': "SaveSimpleMCARequest",
                Rules: { Rule: [] },
            }
        };

        if (selectedARs && selectedARs.length > 0) {
            if (selectedARs && selectedARs instanceof Array) {
                selectedARs.forEach(sar => {
                    let ruleUser = this.ruleUsers(sar);
                    let ruleHasUser: boolean = (ruleUser?.length !== 0) ? true : false;

                    // Check Rule Users in SelectedUsers
                    let selectedUsers = this.selectedAssignedUserInfo();

                    let ruleDepartment = this.ruleDepartment(sar);

                    let ruleHasDept: boolean = (ruleDepartment && ruleDepartment.Department &&
                        !isEmpty(ruleDepartment.Department['@ID']) && !isEmpty(ruleDepartment.Department['@Idx'])) ? true : false;

                    // Check Rule Department and Selected AR Department are same
                    let isDeptChanged: boolean = (selectedARDepartment?.deptId !== ruleDepartment.Department['@ID']);

                    let updatedDepts: MCAUAEDepartments = { Department: [] };

                    // Get Updated Department
                    if (isDeptChanged) {
                        if (ruleHasDept) {
                            updatedDepts.Department.push({ '@ID': ruleDepartment?.Department['@ID'], '@DepartmentID': ruleDepartment?.Department['@ID'], '@Delete': 'true' });
                            if (!isEmpty(selectedARDepartment?.deptId)) {
                                updatedDepts.Department.push({
                                    '@ID': '#',
                                    '@DepartmentID': selectedARDepartment?.deptId ? selectedARDepartment.deptId : ''
                                });
                            }
                        } else {
                            if (!isEmpty(selectedARDepartment?.deptId)) {
                                updatedDepts.Department.push({
                                    '@ID': '#',
                                    '@DepartmentID': selectedARDepartment?.deptId ? selectedARDepartment.deptId : ''
                                });
                            }
                        }
                    }

                    // Check if Rule has no users or no department and no selected new users or no new department
                    let isRemoveRule: boolean = !ruleHasUser && !ruleHasDept && selectedUsers.length === 0 && isEmpty(selectedARDepartment.deptId);

                    // Rule Does not have either user or Department
                    if (isRemoveRule) {
                        this.RemoveRules();
                    } else {
                        this.removeRulesMessage("");

                        let updatedRuleUsers: MCAUAEUsers = { User: [] };

                        // Get Users to Delete
                        let deletedUsers = this.getUsers2Delete(ruleUser, selectedUsers);

                        if (deletedUsers?.length > 0) {
                            deletedUsers.forEach(dusr => updatedRuleUsers?.User?.push({ '@ID': dusr['@ID'], '@UserID': dusr['@ID'], '@Delete': 'true' }));
                        }

                        // New Users to Add
                        let newUsers: MCAAssignedUserInfo[] = this.getNewUsers(ruleUser, selectedUsers);

                        if (newUsers?.length > 0) {
                            newUsers.forEach(nusr => updatedRuleUsers?.User?.push({
                                '@ID': nusr['@ID'], '@UserID': nusr['@ID'],
                                '@AlphaSplit': !isEmpty(nusr['@AlphaSplit']) ? nusr['@AlphaSplit'] : ''
                            }));
                        }

                        // Filter users with no change
                        let commonUsers: MCAAssignedUserInfo[] = this.getCommonUsers(ruleUser, selectedUsers);

                        if (commonUsers?.length) {
                            for (let idx = 0; idx < commonUsers?.length; idx++) {
                                if (updatedRuleUsers?.User) {
                                    let usrIdx = updatedRuleUsers.User.findIndex(uusr =>
                                        uusr['@ID'] === commonUsers[idx]['@ID'] &&
                                        uusr['@AlphaSplit'] === commonUsers[idx]['@AlphaSplit']
                                    );

                                    if (usrIdx > -1) {
                                        updatedRuleUsers?.User?.splice(usrIdx, 1);
                                    }
                                }
                            }
                        }

                        let rule: MCAUAERule = {
                            "@ID": sar['@RuleId'],
                            Users: updatedRuleUsers.User?.length === 0 ? null : updatedRuleUsers,
                            Departments: ruleHasDept && !isDeptChanged ? null : updatedDepts
                        };

                        updateRules.MCAErrorSimpleMaintenanceInfo.Rules.Rule.push(rule);

                        this.clearTab();
                    }
                });

                this.props.action.crud.update({
                    crudId: CrudTypes.mctiClaimAssignmentErrorSimple,
                    data: updateRules
                });

                this.applyFilter();
            }
        }
    }

    RemoveRulesConfirmation = () => {
        let { selectedARs, filteredARs } = this.state;
        let removeRules: MCASaveSimpleError = {
            MCAErrorSimpleMaintenanceInfo: {
                '@Action': "SaveSimpleMCARequest",
                Rules: { Rule: [] },
            }
        };

        if (selectedARs && selectedARs instanceof Array) {
            selectedARs.forEach(ar => {
                let rule: MCAUAERule = {
                    "@ID": ar['@RuleId'],
                    '@Delete': true,
                    Users: { User: [{}] },
                    Departments: { Department: [] }
                }
                removeRules.MCAErrorSimpleMaintenanceInfo.Rules.Rule.push(rule);
            })
        } else {
            let rule: MCAUAERule = {
                "@ID": selectedARs['@RuleId'],
                '@Delete': true,
                Users: { User: [{}] },
                Departments: { Department: [] }
            }

            removeRules.MCAErrorSimpleMaintenanceInfo.Rules.Rule.push(rule);
        }

        if (removeRules && removeRules.MCAErrorSimpleMaintenanceInfo.Rules.Rule.length > 0) {
            this.props.action.crud.update({
                crudId: CrudTypes.mctiClaimAssignmentErrorSimple,
                data: removeRules
            });
        }

        let reminingARs = filteredARs.filter(function (ar) {
            return selectedARs.filter(function (sar) {
                return sar["@Idx"] === ar['@Idx'];
            }).length == 0
        });

        this.deselectAllRows();

        this.setState({ filteredARs: reminingARs, selectedARs: [] });
    }

    RemoveRules = () => {
        const { selectedARs } = this.state;
        const { dataSearch } = this.props.dataStore.crud;

        let removeRules: MCASaveSimpleError = {
            MCAErrorSimpleMaintenanceInfo: {
                '@Action': "SaveSimpleMCARequest",
                Rules: { Rule: [] },
            }
        };

        if (selectedARs && selectedARs instanceof Array) {
            selectedARs.forEach(ar => {
                let rule: MCAUAERule = {
                    "@ID": ar['@RuleId'],
                    '@Delete': true,
                    Users: { User: [{}] },
                    Departments: { Department: [] }
                }
                removeRules.MCAErrorSimpleMaintenanceInfo.Rules.Rule.push(rule);
            })
        } else {
            let rule: MCAUAERule = {
                "@ID": selectedARs['@RuleId'],
                '@Delete': true,
                Users: { User: [{}] },
                Departments: { Department: [] }
            }

            removeRules.MCAErrorSimpleMaintenanceInfo.Rules.Rule.push(rule);
        }

        this.props.action.ui.removeRules({
            masterCrud: dataSearch,
            uiData: {
                selectedARs: selectedARs,
            }
        });
    }

    ARTab = () => {
        const { selectedARCatFilter,
            selectedARFieldFilter, selectedAREditFilter, selectedARAssignedToFilter
        } = this.props.dataStore.ui;

        const { gridARPaging, filteredARs, selectedARDepartment } = this.state;

        return (
            <React.Fragment>
                <ContentWrapper>
                    <ContentColumnWrapper>
                        <ContentRowWrapper>
                            <SelectDropdownV2
                                domID="AR-CatFilter"
                                className="dropdown"
                                size='small'
                                isClearable={false}
                                isSearchable={true}
                                onChange={(e: any) => this.onChangeCatFilter(e.value)}
                                options={this.CatList()}
                                initialValue={this.getInitialValue(this.CatList(), selectedARCatFilter)}
                                disabled={false}
                            />
                            <Input
                                domID="AR-FieldFilter"
                                className="text-input"
                                maxLength={20}
                                width='150px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeFieldFilter(e.target.value.toUpperCase())}
                                initialValue={selectedARFieldFilter}
                            />
                            <Input
                                domID="AR-EditNameFilter"
                                className="text-input"
                                maxLength={20}
                                width='125px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEditFilter(e.target.value)}
                                initialValue={selectedAREditFilter}
                            />
                            <Input
                                domID="AR-AssignedToFilter"
                                className="text-input"
                                maxLength={20}
                                width='125px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAssignedToFilter(e.target.value.toUpperCase())}
                                initialValue={selectedARAssignedToFilter}
                            />
                            <React.Fragment>
                                <span>
                                    <IMG src='/shared/images/Icons/RefreshArrow.png' onClick={() => this.applyFilter()} />
                                </span>
                                <Button
                                    domID="AR-Filter-Link"
                                    name="Filter"
                                    buttonType="link"
                                    size="small"
                                    type="link"
                                    onClick={() => this.applyFilter()}>
                                </Button>
                            </React.Fragment>
                            <Button
                                domID="AR-Clear-Link"
                                name="Clear"
                                buttonType="link"
                                size="small"
                                type="link"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.ClearFilter()}>
                            </Button>
                        </ContentRowWrapper>
                    </ContentColumnWrapper>
                    <ContentColumnWrapper>
                        <ContentColumnWrapper>
                            <GridWrapperAssignedRules>
                                <GridWrapper>
                                    <Grid
                                        domID="AR-Rule-Grid"
                                        isFixedHeader={true}
                                        maxHeight='385px'
                                        columns={this.ARGridColumns()}
                                        records={filteredARs}
                                        onClickThrough={(e: any, record: any) => { this.handleARGridClick(e, record); }}
                                        onSortGridColumn={(e: any) => { this.handleSort(e) }}
                                        selectionKey='RuleCategory'
                                        // the sorting is handled by the api response. This stops the grid from doing it's own sorting:
                                        sortingComparator={() => filteredARs}
                                    />
                                </GridWrapper>
                            </GridWrapperAssignedRules>
                        </ContentColumnWrapper>
                    </ContentColumnWrapper>
                    <ContentRowWrapper>
                        <div style={{ marginTop: 5, flexGrow: 1, textAlign: 'right', position: 'relative', left: 20, top: -7 }}>
                            <ARMGridPager
                                currentPageNumber={gridARPaging.currentPageNumber}
                                numberOfPages={gridARPaging.numberOfPages}
                                pagingHandler={this.handlePaging}
                            />
                        </div>
                    </ContentRowWrapper>
                    <ContentColumnWrapper>
                        <ContentColumnWrapper style={{ alignItems: 'center' }} >
                            {/*@ts-ignore*/}
                            <AssignmentContainer
                                containerTitle='Assignments'
                                allUsers={this.assignedUsers()}
                                buttonType={eRulesAssignmentModifyButtonType.Update}
                                allDepartments={this.assignedDepartment()}
                                showExpiresOn={false}
                                enableButtons={true}
                                onSelectUsersCallback={(users: IRulesAssignUserInfo[]) => this.updateUsers(users)}
                                onUserAlphaSplitCallback={(user: IRulesAssignUserInfo, alphaSplit: string) => this.setAlphaSplit(user, alphaSplit)}
                                onUpdateCallback={this.UpdateRules}
                                onRemoveCallback={this.RemoveRules}
                                //@ts-ignore
                                selectedDepartment={selectedARDepartment}
                                onSelectDepartmentsCallback={(depts: IRulesAssignDepartmentInfo) => this.updateDepartment(depts)}
                            />
                        </ContentColumnWrapper>
                    </ContentColumnWrapper>
                </ContentWrapper>
            </React.Fragment>);
    }

    public onSelectTab(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) {
        this.props.action.ui.selectTabIndex(selectInfo.tabIndex);
        this.clearTab(false)
    }

    clearTab = (allclear: boolean = true) => {
        const { selectedARUsers, selectedUAEUsers, allUsers, selectedUAEDepartment } = this.state;

        if (selectedARUsers && selectedARUsers.length > 0) {
            this.state.selectedARUsers.forEach(suser => {
                suser["@AlphaSplit"] = "";
                suser.alphaSplit = "";
            })
        }

        if (allUsers && allUsers.length > 0) {
            this.state.allUsers.forEach(suser => {
                suser["@AlphaSplit"] = "";
                suser.alphaSplit = "";
            })
        }

        if (allclear) {
         
            if (selectedUAEUsers && selectedUAEUsers.length > 0) {
                this.state.selectedUAEUsers.forEach(suser => {
                    suser["@AlphaSplit"] = "";
                    suser.alphaSplit = "";
                })
            }
        }

        let emptyDept: MCADepartmentInfo = { "@ID": "", "@Name": '', "@Priority": '', isSelected: false, deptId: "" };

        

        this.setState({
            selectedARs: [],
            selectedUAEs: [],
            selectedARUsers: [],
            selectedUAEUsers: allclear ? [] : selectedUAEUsers, 
            selectedARDepartment: emptyDept,
            selectedUAEDepartment: allclear ? emptyDept : selectedUAEDepartment,
            sortIndicator: {
                sortColumn: '',
                sortDirection: ''
            }
        });
    }

    // Filter Methods
    public onChangeCatFilter(category: string) {

        const { selectedTabIndex } = this.props.dataStore.ui;

        switch (selectedTabIndex) {
            case 1:
                this.props.action.ui.updateARCatFilter({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: category }
                });
                break;
            case 0:
            default:
                this.props.action.ui.updateUAECatFilter({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: category }
                });
                break;
        }
    }

    public onChangeFieldFilter(field: string) {
        const { selectedTabIndex } = this.props.dataStore.ui;

        switch (selectedTabIndex) {
            case 1:
                this.props.action.ui.updateARFieldFilter({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: field }
                });
                break;
            case 0:
            default:
                this.props.action.ui.updateUAEFieldFilter({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: field }
                });
                break;
        }
    }

    public onChangeEditFilter(edit: string) {
        const { selectedTabIndex } = this.props.dataStore.ui;

        switch (selectedTabIndex) {
            case 1:
                this.props.action.ui.updateAREditFilter({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: edit }
                });
                break;
            case 0:
            default:
                this.props.action.ui.updateUAEEditFilter({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: edit }
                });
                break;
        }
    }

    public onChangeAssignedToFilter(assignedTo: string) {
        const { selectedTabIndex } = this.props.dataStore.ui;
        if (selectedTabIndex === 1) {
            this.props.action.ui.updateARAssignedToFilter({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { value: assignedTo }
            });
        }
    }

    public CatList = (): IDropDownItem[] => {
        const { allCategories } = this.state;
        let categories: IDropDownItem[] = [{ value: '', label: '' }];
        if (allCategories) {
            if (allCategories instanceof Array) {
                allCategories.map((cat) => {
                    categories.push({ value: cat['@ID'], label: cat['@Name'] });
                });
            } else {
                categories.push({ value: allCategories['@ID'], label: allCategories['@Name'] });
            }
        }

        return categories;
    }

    ClearFilter = () => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        const { dataSearch } = this.props.dataStore.crud;
        switch (selectedTabIndex) {
            case 1: {
                this.props.action.ui.clearARFilters({
                    masterCrud: dataSearch,
                    uiData: {
                        selectedARCatFilter: '',
                        selectedARFieldFilter: '',
                        selectedAREditNameFilter: '',
                        selectedARAssignedToFilter: '',
                    }
                });
            }
                break;
            case 0:
            default: {
                this.props.action.ui.clearUAEFilters({
                    masterCrud: dataSearch,
                    uiData: {
                        selectedUAECatFilter: '',
                        selectedUAEFieldFilter: '',
                        selectedUAEEditNameFilter: '',
                    }
                });
            }
                break;
        }
    }

    deselectAllRows() {
        const { selectedTabIndex} = this.props.dataStore.ui;
        switch (selectedTabIndex) {
            case 1: {
                // clear selected row highlights from ui
                var arGrid = document.getElementById("AR-Rule-Grid");
                if (arGrid != null) {
                    let selectedRows = arGrid.querySelectorAll('.selected');
                    for (let i = 0; i < selectedRows.length; i++) {
                        selectedRows[i].classList.remove('selected');
                    }
                }

                //@ts-ignore
                let newArr = []
                //@ts-ignore
                this.props.action.ui.selectedARs(newArr)
            }
                break;
            case 0:
            default: {
                // clear selected row highlights from ui
                let uaeGrid = document.getElementById("UAE-Error-Grid");
                if (uaeGrid != null) {
                    var selectedRows = uaeGrid.querySelectorAll('.selected');
                    for (let i = 0; i < selectedRows.length; i++) {
                        selectedRows[i].classList.remove('selected');
                    }
                }
                //@ts-ignore
                let newArr = []
                //@ts-ignore
                this.props.action.ui.selectedUAEs(newArr)

            }
                break;
        }
    }

    UAEGridColumns = () => {
        return new Set([
            {
                dataName: "Category",
                text: 'Category',
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Field",
                text: "Field",
                sortable: true,
                cellType: "text",
                isSorted: 0
            },
            {
                dataName: "EditName",
                text: "EditName",
                sortable: true,
                cellType: "text",
                isSorted: 0
            },
            {
                dataName: "Message",
                text: "Message",
                sortable: false,
                cellType: 'text',
                isSorted: 0
            },
            {
                dataName: "ErrorCount",
                text: "Count",
                sortable: true,
                cellType: 'text',
                isSorted: 0
            }
        ]);
    }

    ARGridColumns = () => {
        return new Set([
            {
                dataName: "RuleCategory",
                text: 'Category',
                sortable: true,
                cellType: "text",
                isSorted: 1,
            },
            {
                dataName: "RuleField",
                text: "Field",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "RuleEditName",
                text: "EditName",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "AssignedTo",
                text: "Assigned To",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Message",
                text: "Message",
                sortable: false,
                cellType: 'text',
                isSorted: 0,
            }
        ]);
    }

    private createFilterParam(name: string, value: string, type?: string): FilterParam {
        return type ? { Name: name, Value: value, Type: type } : { Name: name, Value: value };
    }

    //add class name for selected UAE
    initializeUAEGrid = (): any[] => {
        let unassignedErrors: MCAUAErrorInfo[] = [];

        const { dataSearch } = this.props.dataStore.crud;
        if (dataSearch?.MCAUnAssignedErrors?.UnAssignedErrors &&
            dataSearch?.MCAUnAssignedErrors?.UnAssignedErrors?.UnAssignedError) {
            if (dataSearch?.MCAUnAssignedErrors?.UnAssignedErrors?.UnAssignedError instanceof Array) {
                return dataSearch.MCAUnAssignedErrors.UnAssignedErrors.UnAssignedError.map(uae => {
                    return ({
                        Message: <><Tip title={uae['@MsgLev1']}>{uae['@MsgLev1'] && uae['@MsgLev1'].length > this.MIN_MSG_LENGTH ? (uae['@MsgLev1'].substring(0, this.MIN_MSG_LENGTH).replace(/[\$,]/g, "")) + "..." : uae['@MsgLev1']}</Tip></>,
                        Field: uae['@Field'],
                        "@FieldId": uae['@FieldId'],
                        "@CategoryId": uae['@CategoryId'],
                        "@ErrCode": uae['@ErrCode'],
                        EditName: uae['@EditName'],
                        ErrorCount: uae['@ErrorCount'] ? parseInt(uae['@ErrorCount']) : 0,
                        "@MCAErrorIdx": uae['@MCAErrorIdx'],
                        Category: this.CatName(uae['@CategoryId']),
                        "@Idx": uae['@Idx'],
                        "@PageNumber": uae['@PageNumber'],
                        "@MsgLev1": uae['@MsgLev1'],
                    });
                });
            } else {
                let unassignedError = dataSearch.MCAUnAssignedErrors.UnAssignedErrors.UnAssignedError;
                if (unassignedError) {
                    let msg = "";
                    if (unassignedError['@MsgLev1']) {
                        let msgStr = (unassignedError['@MsgLev1']) as string;
                        msg = msgStr.length > this.MIN_MSG_LENGTH ? (msgStr.substring(0, this.MIN_MSG_LENGTH).replace(/[\$,]/g, "")) + "..." : msgStr;
                    }
                    return [{
                        Message: <><Tip title={unassignedError['@MsgLev1']}>{msg}</Tip></>,
                        Field: unassignedError['@Field'],
                        "@FieldId": unassignedError['@FieldId'],
                        "@CategoryId": unassignedError['@CategoryId'],
                        "@ErrCode": unassignedError['@ErrCode'],
                        EditName: unassignedError['@EditName'],
                        ErrorCount: unassignedError['@ErrorCount'] ? parseInt(unassignedError['@ErrorCount']) : 0,
                        "@MCAErrorIdx": unassignedError['@MCAErrorIdx'],
                        Category: this.CatName(unassignedError['@CategoryId']),
                        "@Idx": unassignedError['@Idx'],
                        "@PageNumber": unassignedError['@PageNumber'],
                        "@MsgLev1": unassignedError['@MsgLev1']
                    }];
                }
            }
        }

        return unassignedErrors;
    }

    handlePaging = (pageNumber: number, category: string = "", field: string = "", edit: string = "") => {
        //@ts-ignore
        const { selectedTabIndex, selectedUAEsList, selectedARsList } = this.props.dataStore.ui;
        const { filteredUAEs, gridUAEPaging, filteredARs, gridARPaging } = this.state;
        switch (selectedTabIndex) {
            case 1: {
                const indexStart = (pageNumber - 1) * gridARPaging.recordsPerPage;
                const indexEnd = indexStart + gridARPaging.recordsPerPage;
                const page = filteredARs.slice(indexStart, indexEnd);
                //@ts-ignore
                const selectedARsList = selectedARsList ?? [];
                const ARsList = [...selectedARsList];

                this.setState({
                    filteredARs: page,
                    selectedARs: ARsList,
                    gridARPaging: {
                        currentPageNumber: pageNumber,
                        numberOfPages: gridARPaging.numberOfPages,
                        recordsPerPage: gridARPaging.recordsPerPage,
                        totalRecordCount: gridARPaging.totalRecordCount
                    },
                }, () => {
                    this.applyFilter(false);
                });
            }
            case 0:
            default: {
                const indexStart = (pageNumber - 1) * gridUAEPaging.recordsPerPage;
                const indexEnd = indexStart + gridUAEPaging.recordsPerPage;
                const page = filteredUAEs.slice(indexStart, indexEnd);

                //@ts-ignore
                const selectedUAEsList = selectedUAEsList ?? []
                const UAEsList = [...selectedUAEsList]

                this.setState({
                    filteredUAEs: page,
                    selectedUAEs: UAEsList,
                    gridUAEPaging: {
                        currentPageNumber: pageNumber,
                        numberOfPages: gridUAEPaging.numberOfPages,
                        recordsPerPage: gridUAEPaging.recordsPerPage,
                        totalRecordCount: gridUAEPaging.totalRecordCount
                    },
                }, () => {
                    this.applyFilter(false, pageNumber.toString(), category, field, edit);
                });
            }
        }
    }

    getConfigKey = (key: string): string => {
        let value = this.props.dataStore.configData?.container?.config?.find((o: ICookies_Config) => o.name === key)?.value;
        return value ? value : ""
    }

    getQueryStringParams = (query: string) => {
        return query
            ? (/^[?#]/.test(query) ? query.slice(1) : query)
                .split('&')
                .reduce((params: any, param: string) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
                )
            : {}
    };

    /*
     <Params>
      <Param Name='@intPageSize' Value='20' Type='int' />
      <Param Name='@intStartPage' Value='1' Type='int' />
      <Param Name='@intPageCount' Value='1' Type='int' />
      <Param Name='@intQueryDays' Value='0' Type='int' />
      <Param Name='@intRefreshMinutes' Value='720'  Type='int' />
      <Param Name='@chrRefreshState' Value='Y'   />
    </Params>
    */
    buildRequest = (reset: boolean, page: string = "1", category: string = "", field: string = "", edit: string = ""): FilterRequest => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        const { gridUAEPaging, gridARPaging, sortIndicator } = this.state;

        let pageSize: string = this.RECORDS_PER_PAGE;
        let pageCount: string = "1";
        let refreshMinutes: string = "720";
        let queryDays: string = selectedTabIndex === 1 ? "0" : "10";

        // Check Assurance Config for MCASimpleRefreshMinutes
        let configSimpleRefreshMinutes: string = this.getConfigKey("MCASimpleRefreshMinutes");

        if (!isEmpty(configSimpleRefreshMinutes)) {

            refreshMinutes = configSimpleRefreshMinutes;

            if (+refreshMinutes > 1440 || +refreshMinutes <= 14) {
                refreshMinutes = "720";
            }
        }

        // Check Assurance Config for MCASimpleQueryDays
        let configQueryDays: string = this.getConfigKey("MCASimpleQueryDays");

        if (!isEmpty(configQueryDays)) {

            queryDays = configQueryDays;

            if (+queryDays > 999 || +queryDays <= 0) {
                queryDays = "10";
            }
        }

        // initialize Filter Request
        const filterRequest: FilterRequest = {
            Param: [
                this.createFilterParam("@intPageSize", pageSize, "int"),
                this.createFilterParam("@intPageCount", pageCount, "int"),
                this.createFilterParam("@chrRefreshState", reset ? "Y" : "N"),
            ]
        };

        // set Refresh Minutes
        if (!isEmpty(refreshMinutes)) {
            filterRequest.Param.push(this.createFilterParam("@intRefreshMinutes", refreshMinutes, "int"));
        }

        // set SortBy
        if (!isEmpty(sortIndicator.sortColumn)) {
            filterRequest.Param.push(this.createFilterParam("@chvSortBy", `${sortIndicator.sortColumn} ${sortIndicator.sortDirection}`));
        }

        switch (selectedTabIndex) {
            case 1: {
                
                // start page form AR Grid Paging
                let start: string = reset ? "1" : gridARPaging.currentPageNumber.toString();
                filterRequest.Param.push(this.createFilterParam("@intStartPage", start, "int"));

                // hard code this to 0 for assigned tab to let the proc know which values to return
                filterRequest.Param.push(this.createFilterParam("@intQueryDays", "0", "int"));

                const {
                    selectedARCatFilter,
                    selectedARFieldFilter,
                    selectedAREditFilter,
                    selectedARAssignedToFilter,
                } = this.props.dataStore.ui;

                // Set UI Filters
                if (!isEmpty(selectedARCatFilter)) {
                    filterRequest.Param.push(this.createFilterParam("@intFilterCategory", selectedARCatFilter, "int"));
                }

                if (!isEmpty(selectedARFieldFilter)) {
                    filterRequest.Param.push(this.createFilterParam("@chvFilterField", selectedARFieldFilter.trim()));
                }

                if (!isEmpty(selectedAREditFilter)) {
                    filterRequest.Param.push(this.createFilterParam("@chvFilterEdit", selectedAREditFilter.trim()));
                }

                if (!isEmpty(selectedARAssignedToFilter)) {
                    filterRequest.Param.push(this.createFilterParam("@chvFilterAssignedTo", selectedARAssignedToFilter.trim()));
                }

                return filterRequest;
            }
                break;
            case 0:
            default: {
                
                // start page form UAE Grid Paging
                let start: string = reset ? "1" : page;
                filterRequest.Param.push(this.createFilterParam("@intStartPage", start, "int"));

                // set queryDays
                if (!isEmpty(queryDays)) {
                    filterRequest.Param.push(this.createFilterParam("@intQueryDays", queryDays, "int"));
                }

                let {
                    selectedUAECatFilter,
                    selectedUAEFieldFilter,
                    selectedUAEEditFilter
                } = this.props.dataStore.ui;

                if (!isEmpty(category))
                    selectedUAECatFilter = category;

                if (!isEmpty(field))
                    selectedUAEFieldFilter = field;

                if (!isEmpty(edit))
                    selectedUAEEditFilter = edit;

               // Set UI Filters
                if (!isEmpty(selectedUAECatFilter)) {
                    filterRequest.Param.push(this.createFilterParam("@intFilterCategory", selectedUAECatFilter, "int"));
                }
                if (!isEmpty(selectedUAEFieldFilter)) {
                    filterRequest.Param.push(this.createFilterParam("@chvFilterField", selectedUAEFieldFilter.trim()));
                }
                if (!isEmpty(selectedUAEEditFilter)) {
                    filterRequest.Param.push(this.createFilterParam("@chvFilterEdit", selectedUAEEditFilter.trim()));
                }

                return filterRequest;
            }
        }
    }

    //If this false we don't need to reset the page
    applyFilter(reset: boolean = true, page: string = "1", category: string = "", field: string = "", edit: string = "") {
        this.setState({ isLoading: true })
        const { selectedTabIndex } = this.props.dataStore.ui;
        const { gridUAEPaging, gridARPaging } = this.state;
        let request: FilterRequest = this.buildRequest(reset, page, category, field, edit);

        this.props.action.crud.search({
            crudId: CrudTypes.mctiClaimAssignmentErrorSimple,
            data: JSON.stringify(request)
        });
        const { comingFromCfi } = this.state;
        switch (selectedTabIndex) {
            case 1: {
                setTimeout(async () => {
                    const gridDataForAR = this.initializeARGrid();
                    if (gridDataForAR.length === 0) {
                        this.validationMessageForAR(this.NO_AR_FOUND);
                    } else {
                        gridDataForAR.map((item: MCARuleInfo) => {
                            if (item.Users !== undefined && item.Users.User !== undefined) {
                                if (Array.isArray(item.Users.User)) {
                                    item?.Users?.User?.map((use: MCAUserInfo) => { use.alphaSplit = use["@AlphaSplit"] });
                                } else {
                                    item.Users.User['alphaSplit'] = item?.Users?.User["@AlphaSplit"];
                                }
                            }
                        })
                    }

                    let numberOfPages = 1;
                    numberOfPages = this.getTotalPagesForGrid();
                    if (numberOfPages < 1) numberOfPages = 1;

                    this.deselectAllRows();

                    this.setState({
                        allARs: [...gridDataForAR], filteredARs: [...gridDataForAR], selectedARs: [],
                        gridARPaging: {
                            currentPageNumber: reset ? 1 : gridARPaging.currentPageNumber,
                            numberOfPages: numberOfPages,
                            recordsPerPage: gridARPaging.recordsPerPage,
                            totalRecordCount: gridDataForAR.length
                        }
                    });
                    this.setState({ isLoading: false })
                }, 2500);
            }
                break;
            case 0:
            default: {
                setTimeout(async () => {
                    const gridDataForUAE = this.initializeUAEGrid();
                    if (gridDataForUAE.length === 0 && !comingFromCfi) {
                        this.validationMessageForUAE(this.NO_UAE_FOUND);
                    }
                    let numberOfPages = 1;
                    numberOfPages = this.getTotalPagesForGrid();
                    if (numberOfPages < 1) numberOfPages = 1;

                    this.setState({
                        allUAEs: [...gridDataForUAE], filteredUAEs: [...gridDataForUAE],
                        gridUAEPaging: {
                            currentPageNumber: reset ? 1 : gridUAEPaging.currentPageNumber,
                            numberOfPages: numberOfPages,
                            recordsPerPage: gridUAEPaging.recordsPerPage,
                            totalRecordCount: gridDataForUAE.length
                        },
                        comingFromCfi: false,
                    });
                    this.setState({ isLoading: false })
                }, 2500)
            }
        }
    }

    getTotalPagesForGrid = (): number => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        let gridTotal: number = 1;
        const { dataSearch } = this.props.dataStore.crud;
        gridTotal = (selectedTabIndex === 1) ?
            Number(dataSearch?.MCAErrorSimpleMaintenanceInfo?.['@TotalPages']) :
            Number(dataSearch?.MCAUnAssignedErrors?.Summary['@TotalPages']);
        if (isNaN(gridTotal)) {
            gridTotal = 1;
        }
        return gridTotal;
    }

    public getInitialValue(options: any, selectedValue: any) {
        let getValue = options.filter((res: any) => {
            if (selectedValue != undefined) {
                return res.value == selectedValue
            } else {
                return { label: '', value: '0' };
            }
        });
        return getValue;
    }

    CatName = (id: string): string => {
        const { allCategories } = this.state;
        let categoryName: string = '';
        if (!isEmpty(id)) {
            if (allCategories && allCategories.length > 0) {
                let category = allCategories.find(cat => cat['@ID'] === id);
                categoryName = category?.['@Name'] ? category['@Name'] : '';
            } else {
                if (this.props.dataStore.crud?.data?.MCAErrorSimpleMaintenanceInfo?.ErrorCategoryList?.ErrorCategory) {
                    let category = this.props.dataStore.crud.data.MCAErrorSimpleMaintenanceInfo.ErrorCategoryList.ErrorCategory.find(ec => ec['@ID'] === id);
                    categoryName = category?.['@Name'] ? category['@Name'] : '';
                }
            }
        }
        return categoryName;
    }

    FieldName = (fieldId: string): string => {
        const { allFields } = this.state;
        let fieldName: string = '';
        if (!isEmpty(fieldId)) {
            if (allFields && allFields.length > 0) {
                let field = allFields.find(fld => fld['@ID'] === fieldId);
                fieldName = field?.['@Name'] ? field['@Name'] : '';
            } else {
                if (this.props.dataStore.crud?.data?.MCAErrorSimpleMaintenanceInfo?.FieldList?.Field) {
                    let fldIndex = this.props.dataStore.crud.data.MCAErrorSimpleMaintenanceInfo.FieldList.Field.findIndex(fld => fld['@ID'] === fieldId);
                    if (fldIndex > -1) {
                        fieldName = this.props.dataStore.crud.data.MCAErrorSimpleMaintenanceInfo.FieldList.Field[fldIndex]['@Name'];
                    }
                }
            }
        }
        return fieldName;
    }

    UserName = (userId: string): string => {
        const { allUsers } = this.state;
        let userName: string = '';
        if (userId && !isEmpty(userId)) {
            if (allUsers && allUsers.length > 0) {
                let user = allUsers.find(usr => usr['@ID'] === userId);
                userName = user?.['@Name'] ? user['@Name'] : '';
            } else {
                if (this.props.dataStore.crud?.data?.MCAErrorSimpleMaintenanceInfo?.UserList?.User) {
                    let user = this.props.dataStore.crud.data.MCAErrorSimpleMaintenanceInfo.UserList.User.find(usr => usr['@ID'] === userId);
                    userName = user?.['@Name'] ? user['@Name'] : '';
                }
            }
        }
        return userName;
    }

    DeptName = (id: string): string => {
        let deptName: string = '';
        const { allDepartments } = this.state;
        if (!isEmpty(id)) {
            if (allDepartments && allDepartments.length > 0) {
                let department = allDepartments.find(dept => dept['@ID'] === id);
                deptName = department?.['@Name'] ? department['@Name'] : '';
            } else {
                if (this.props.dataStore.crud?.data?.MCAErrorSimpleMaintenanceInfo?.DepartmentList?.Department) {
                    let department = this.props.dataStore.crud.data.MCAErrorSimpleMaintenanceInfo.DepartmentList.Department.find(usr => usr['@ID'] === id);
                    deptName = department?.['@Name'] ? department['@Name'] : '';
                }
            }
        }
        return deptName;
    }

    selectedDepartment = (department: MCAAssignedDepartmentInfo): MCADepartmentInfo => {
        let departmentInfo: MCADepartmentInfo = { "@ID": "", "@Name": '', "@Priority": '', isSelected: false };
        const { allDepartments } = this.state;
        if (department && allDepartments) {
            let deptInfo = allDepartments.find(dept => dept['@ID'] === department['@ID']);

            if (deptInfo) {
                departmentInfo = deptInfo;
            }
        }
        return departmentInfo;
    }

    selectedUsers = (users: MCAAssignedUserInfo[]): MCAUserInfo[] => {
        const { allUsers } = this.state;
        let usersInfo: MCAUserInfo[] = [];
        if (users instanceof Array) {
            if (users && users.length > 0 && allUsers) {
                for (let idx = 0; idx < users.length; idx++) {
                    const userInfo = allUsers.find(usr => usr['@ID'] === users[idx]['@ID']);
                    if (userInfo) {
                        userInfo.alphaSplit = users[idx]["@AlphaSplit"];
                        usersInfo.push(userInfo);
                    }
                }

            }
        } else {
            const userInfo = allUsers.find(usr => usr?.['@ID'] === users?.['@ID']);
            if (userInfo) {
                userInfo.alphaSplit = users["@AlphaSplit"];
                usersInfo.push(userInfo);
            }
        }
        return usersInfo;
    }

    public setAlert(alertMsg: string) {
        this.props.action.ui.setModalText(alertMsg);
    }

    handleARGridClick = (e: any, record: MCARuleInfo) => {
        const { selectedARs, selectedARUsers } = this.state;

        const tr: HTMLButtonElement = e.target.closest('tr');
        let container = document.querySelector('#ARMUserComponent');
        let checkboxesAll = container?.querySelectorAll('input[type="checkbox"]');
        e.persist();

        let emptyDept: MCADepartmentInfo = { "@ID": "", "@Name": '', "@Priority": '', isSelected: false, deptId: "" };

        if (!tr.classList.contains("selected")) { // select the new row
            tr.classList.add("selected");
            let newArr = [...selectedARs, record]
            this.setState((state, props) => ({
                selectedARs: newArr
            }))

            this.props.action.ui.selectedARs(newArr)

            // Assigned Users
            let assignedUsers: MCAUserInfo[] = this.selectedUsers(record?.Users?.User);
            if (assignedUsers !== undefined && assignedUsers.length > 0) {
                assignedUsers.map((item: MCAUserInfo) => {
                    item.userId = item["@ID"];
                    item.selected = item["@Enabled"] === "1" ? true : false;
                    item.alphaSplit =  item.alphaSplit;

                })
            }
            // Assigned Department
            let assignedDepartment: MCADepartmentInfo = this.selectedDepartment(record?.Departments?.Department);
            if (assignedDepartment !== undefined) {
                assignedDepartment.deptId = assignedDepartment["@ID"];
            }

            this.setState({
                selectedARUsers: [...assignedUsers],
                selectedARDepartment: assignedDepartment
            });

            let rows = document.querySelectorAll('#grid-wrapper-AR-Rule-Grid tbody tr.selected');

            checkboxesAll?.forEach(cb => {
                const input = assignedUsers.find(usr => usr['@ID'] === cb.id);
                if (input) {
                    cb.setAttribute("checked", '');
                    input['@IsChecked'] = rows.length > 0 ? false : true;
                    if (rows.length > 1) {
                        input['alphaSplit'] = '';
                        input['@AlphaSplit'] = '';
                    } 
                }
            });

            if (rows.length === 1) {
                rows[0].classList.add('selected');
            }

            if (rows.length > 1) {
                if (selectedARUsers && selectedARUsers.length > 0) {
                    this.state.selectedARUsers.forEach(suser => {
                        suser["@AlphaSplit"] = "";
                        suser.alphaSplit = "";
                    })
                }

                this.setState({
                    selectedARDepartment: emptyDept,
                    selectedARUsers: [],
                }, () => {
                    this.assignedUsers();
                });
            }

        } else { // UNselect row
            tr.classList.remove("selected");
            const index = selectedARs.findIndex(selectedARs => selectedARs['@RuleId'] === record['@RuleId']);
            let rows = document.querySelectorAll('#grid-wrapper-AR-Rule-Grid tbody tr.selected');
            let assignedUsers: MCAUserInfo[] = this.selectedUsers(record?.Users?.User);
            let assignedDepartment: MCADepartmentInfo = this.selectedDepartment(record?.Departments?.Department);

            this.setState({
                selectedARUsers: assignedUsers ? assignedUsers : [],
                selectedARDepartment: assignedDepartment ? assignedDepartment : emptyDept,
            });
            if (index > -1) {
                checkboxesAll?.forEach(cb => {
                    const input = assignedUsers.find(usr => usr['@ID'] === cb.id);
                    if (input) {
                        input['@IsChecked'] = false;
                        input['alphaSplit'] = '';
                        input['@AlphaSplit'] = '';
                        cb.removeAttribute("checked");
                    }
                });

                let newArr = [...selectedARs]
                newArr.splice(index, 1)
                this.setState((state, props) => ({
                    selectedARs: newArr
                }))

                this.props.action.ui.selectedARs(newArr)


                if (rows.length > 1) {

                    this.setState({
                        selectedARUsers: [],
                        selectedARDepartment: emptyDept,
                    });
                } else {
                    if (rows.length === 1) {
                        this.setState({
                            selectedARUsers: [],
                            selectedARDepartment: emptyDept,
                        });

                        // Assigned Users
                        let assignedUsers: MCAUserInfo[] = this.selectedUsers(newArr[0]?.Users?.User);
                        if (assignedUsers !== undefined && assignedUsers.length > 0) {
                            assignedUsers.map((item: MCAUserInfo) => {
                                item.userId = item["@ID"];
                                item.selected = item["@Enabled"] === "1" ? true : false;
                                item["@AlphaSplit"] = item.alphaSplit;
                            })
                        }
                        // Assigned Department
                        let assignedDepartment: MCADepartmentInfo = this.selectedDepartment(newArr[0]?.Departments?.Department);
                        if (assignedDepartment !== undefined) {
                            assignedDepartment.deptId = assignedDepartment["@ID"];
                        }

                        this.setState({
                            selectedARUsers: [...assignedUsers],
                            selectedARDepartment: assignedDepartment,

                        }, () => {
                            this.assignedUsers();
                            this.assignedDepartment();
                        });

                        let rows = document.querySelectorAll('#grid-wrapper-AR-Rule-Grid tbody tr.selected');
                        let checkboxesAll = container?.querySelectorAll('input[type="checkbox"]:checked');
                        checkboxesAll?.forEach(cb => {
                            const input = assignedUsers.find(usr => usr['@ID'] === cb.id);
                            if (input) {
                                input['@IsChecked'] = rows.length > 0 ? false : true;
                                cb.setAttribute("checked", '');
                            }
                        });

                        if (rows.length === 1) {
                            rows[0].classList.add('selected');
                        }
                    }
                    else if (rows.length === 0) {
                        this.setState({
                            selectedARDepartment: emptyDept,
                            selectedARUsers: [],

                        }, () => {
                            this.assignedUsers();
                        });
                    }
                }
            }

            this.printState();
        }
    }


    public validationMessageForUAE = (message: string) => {
        this.props.action.ui.validationMessageForUAE(message);
    }

    public validationMessageForAR = (message: string) => {
        this.props.action.ui.validationMessageForAR(message);
    }

    rollupMessage(message: string): void {
        this.props.action.ui.rollupMessage(message);
    }

    rollupValidation(message: string): void {
        this.props.action.ui.rollupValidation(message);
    }

    removeRulesMessage(message: string): void {
        this.props.action.ui.removeRulesMessage(message);
    }

    getCustomRowUAES = () => {
        //@ts-ignore
        const { selectedUAEsList } = this.props.dataStore.ui;
        const selectedList = selectedUAEsList ?? [];

        const row = ({ record }: any) =>
            //@ts-ignore
            <tr id={`${record['@MCAErrorIdx']}`}
                //@ts-ignore
                className={selectedList.some(item => item['@MCAErrorIdx'] === record['@MCAErrorIdx']) ? "selected" : ""}
                onClick={(event: React.MouseEvent<HTMLElement>) => this.handleUAEGridClick(event, record)} >
                <React.Fragment>
                    <td id={`1`} style={{ verticalAlign: 'middle', lineHeight: '25px' }} >
                        {record.Category}
                    </td>
                    <td id={`2`} style={{ verticalAlign: 'middle', lineHeight: '25px' }}>
                        {record.Field}
                    </td>
                    <td id={`3`} style={{ verticalAlign: 'middle', lineHeight: '25px' }}>
                        {record.EditName}
                    </td>
                    <td id={`4`} style={{ verticalAlign: 'middle', lineHeight: '25px' }}>
                        <Tip title={record['@MsgLev1']}>
                            {record['@MsgLev1'] && record['@MsgLev1'].length > this.MIN_MSG_LENGTH ? (record['@MsgLev1'].substring(0, this.MIN_MSG_LENGTH).replace(/[\$,]/g, "")) + "..." : record['@MsgLev1']}
                        </Tip>
                    </td>
                    <td id={`5`} style={{ verticalAlign: 'middle', lineHeight: '25px' }}>
                        {record.ErrorCount}
                    </td>
                </React.Fragment>
            </tr>

        return row;
    }

    public handleTest = (e: React.MouseEvent<HTMLElement>, record: any) => {
        //@ts-ignore
        const { selectedUAEsList } = this.props.dataStore.ui 
        const selectedList = selectedUAEsList ?? [];
        let selectedUAEs = [...selectedList]
        const idRecord = record["@MCAErrorIdx"];

        if (selectedUAEs.some(item => item["@MCAErrorIdx"] === idRecord)) {
            const index = selectedUAEs.findIndex(suae => suae["@MCAErrorIdx"] === idRecord);
            if (index > -1) {
                let newArr = [...selectedUAEs]
                newArr.splice(index, 1);
                this.setState((state, props) => ({
                    selectedUAEs: newArr
                }))
                this.props.action.ui.selectedUAEs(newArr)
            }
        } else {
            let newArr = [...selectedUAEs, record]
            this.setState((state, props) => ({
                selectedUAEs: newArr
            }))
            this.props.action.ui.selectedUAEs(newArr)
        }
    };

    handleUAEGridClick = (e: any, record: MCAUAErrorInfo) => {
        //@ts-ignore
        const { selectedUAEsList } = this.props.dataStore.ui
        const selectedList = selectedUAEsList ?? [];
        let selectedUAEs = [...selectedList]
        const idRecord = record["@MCAErrorIdx"];

        this.clickCount++;

        setTimeout(() => {
            if (this.clickCount === 1) {
                if (selectedUAEs.some(item => item["@MCAErrorIdx"] === idRecord)) {
                    const index = selectedUAEs.findIndex(suae => suae["@MCAErrorIdx"] === idRecord);
                    if (index > -1) {
                        let newArr = [...selectedUAEs]
                        newArr.splice(index, 1);
                        this.setState({
                            selectedUAEs: newArr
                        })
                        this.props.action.ui.selectedUAEs(newArr)
                    }
                } else {
                    let newArr = [...selectedUAEs, record]
                    this.setState({
                        selectedUAEs: newArr
                    })
                    this.props.action.ui.selectedUAEs(newArr)
                }
            } else if (this.clickCount === 2) {
                const {
                    selectedUAECatFilter,
                    selectedUAEFieldFilter,
                    selectedUAEEditFilter
                } = this.props.dataStore.ui;

                let errCode = record['@ErrCode'];
                let errCodeNullCheck = false;
                if (errCode) {
                    errCodeNullCheck = (errCode == "0" || errCode == null || errCode == "");
                }
                let payloadRequest = {
                    pageNumber: record['@PageNumber'],
                    fieldId: record['@FieldId'],
                    editName: record['EditName'],
                    filterFieldName: selectedUAEFieldFilter,
                    filterEditName: selectedUAEEditFilter,
                    categoryId: selectedUAECatFilter,
                    msgLev: errCodeNullCheck ? record['Message'].props.children.props.title : null
                };
                this.props.action.ui.setClaimListForCFI(payloadRequest, this.props.history);
            }
            this.clickCount = 0;
        }, 240);
    }

    public render() {
        const instruction = <React.Fragment>Search for claim errors that have been encountered, and assign future claims with those errors to one or more users or departments.<br /> <i>(For more information, click the help button.)</i></React.Fragment>;
        const {
            validation_MessageForUAE, rollup_Message, modalViewedForUAE,
            validation_MessageForAR, modalViewedForAR, removeRules_Message,
            rollup_Validation, rollup_Bridge_Validaiton,
        } = this.props.dataStore.ui;

        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_ClaimAssignment_Errors_List.htm' width='1250px' isBusy={this.state.isLoading}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <TabContainer
                        domID="TabContainerResponse"
                        initialTab={this.props.dataStore?.ui.selectedTabIndex}
                        className='tabcss'
                        onTabChange={(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) => this.onSelectTab(e, selectInfo)}
                        tabs={this.getTabs()}
                        width='900px'
                    />
                </ContentWrapper>
                <ModalConfirmation alertMode={true}
                    isOpen={!!validation_MessageForUAE && !modalViewedForUAE}
                    message={validation_MessageForUAE}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.validationMessageForUAE("")}
                />
                <ModalConfirmation alertMode={true}
                    isOpen={!!rollup_Validation && !modalViewedForUAE}
                    message={rollup_Validation}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setRollupValidation("")}
                />
                <ModalConfirmation alertMode={true}
                    isOpen={!!rollup_Bridge_Validaiton && !modalViewedForUAE}
                    message={rollup_Bridge_Validaiton}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setRollupBridgeValidation("")}
                />
                <ModalConfirmation alertMode={true}
                    isOpen={!!validation_MessageForAR && !modalViewedForAR}
                    message={validation_MessageForAR}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.validationMessageForAR("")}
                />
                <ModalConfirmation
                    isOpen={!!removeRules_Message}
                    message={removeRules_Message}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.removeRulesMessage("")}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.RemoveRulesConfirmation()}
                />
                <ModalConfirmation
                    isOpen={this.props.dataStore.ui.modalText != ""}
                    alertMode={true}
                    message={this.props.dataStore.ui.modalText}
                    onModalToggle={() => this.setAlert('')}
                />
                <ModalConfirmation
                    isOpen={!!rollup_Message}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setRollUpMessage("")}
                    message={rollup_Message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.CreateRollup()}
                />
            </DialogWrapper>
        );
    }

};

var connectedHoc = connect<IMCASimpleErrorRuleState, IMCASimpleErrorRuleActionProps, IOwnProps, IMCASimpleErrorRuleProps, ApplicationState>(
    createCrudMapStateToProps('manageClaimAssignmentsSimpleErrorRules'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ManageClaimAssignmentsSimpleErrorRules);

export default withRouter(connectedHoc);