import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as SecondaryBillingUI from './ui/SecondaryBillingUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';


export type ISecondaryBillingState = ICrudComponentState<MCSecondaryBillingType, SecondaryBillingUI.ISecondaryBillingUIState>;

export const actionCreators = createCrudComponentActions<SecondaryBillingUI.ActionCreators>(SecondaryBillingUI.actionCreators);
export type ISecondaryBillingActions = typeof actionCreators;
export type ISecondaryBillingActionProps = ICrudComponentActionProps<ISecondaryBillingActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiSecondaryBillingSetup, SecondaryBillingUI.reducer);
export const reducer = combineReducers<ISecondaryBillingState>(reducers);

export const validationCallback: ValidationCallback<MCSecondaryBillingType> = (crud: any): crud is MCSecondaryBillingType => {

    let testCrud: MCSecondaryBillingType = crud as MCSecondaryBillingType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCSecondaryBillingType");
    if (validShape) {
        VerifyArray(testCrud?.SecondaryBillingSetup?.SecondaryBillingList, "SecondaryBilling");
        VerifyArray(testCrud?.SecondaryBillingSetup?.PayerList, "Payer");
        VerifyArray(testCrud?.SecondaryBillingSetup?.HoldCodeList, "HoldCode");
        VerifyArray(testCrud?.SecondaryBillingSetup?.HCDClaimEOBFormTypeList, "HCDClaimEOBFormType");
        VerifyArray(testCrud?.SecondaryBillingSetup?.PostAdjInfoList, "PostAdjInfo");

        validShape = ValidateArray(testCrud?.SecondaryBillingSetup?.SecondaryBillingList?.SecondaryBilling, "MCSB_SecondaryBilling");
    }

    return validShape;
}