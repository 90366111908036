import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getRawToken } from "@scripts/session/SecurityToken";

import { IMergeCrudComponentProps} from '@scripts/util/CrudComponentHelpers';

import { URLs } from "@commonResources/constants";
import { ApplicationState } from '@store/index';
import { IMergeComponentProps } from '../../../scripts/util/ReduxHelpers';

/////////////////////////////////////////////////////////////////////////////////////////////

interface ILaunchSendReceiveMailComponentProps {
    canView: boolean,
    apiType: string;
}

interface IMailComponentState { 
}

export const DEFAULT_STATE: IMailComponentState = {
 
};

type IOwnProps = ILaunchSendReceiveMailComponentProps & RouteComponentProps<{}>;
type IILaunchSendReceiveMailProps = IMergeComponentProps<IMailComponentState, ILaunchSendReceiveMailComponentProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class LaunchSendReceiveMail extends React.Component<IILaunchSendReceiveMailProps, IMailComponentState> {

    static defaultProps: ILaunchSendReceiveMailComponentProps = {
        canView: false,
        apiType: "SendReceiveMail",
    };

    constructor(props: IILaunchSendReceiveMailProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        this.LaunchSendReceiveMailApi();
        
    }

    public componentWillUnmount() {
    }
    public LaunchSendReceiveMailApi() {
        
        let result: boolean = false;

        fetch(URLs.api + '/api/data/sendReceiveMail',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: '{}',
            }).then(response => {

                if (response.status === 200) {
                    //console.log(response.json());
                    //return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            }).then((data: any) => {
                result = true;
            })
            .catch(error => {
                sessionStorage.setItem('SystemErrorNotification', 'The Send/Receive Mail request failed. Please try again. If the issue persists, please contact Support.');
                result = false;
            })
            .finally(() => {
                this.props.history.push('/ui/LandingPage');
            });
           
   
    }

    public render()
    {
        
        return null;
    }
};

var connectedHoc = connect<IMailComponentState, IOwnProps, IILaunchSendReceiveMailProps,ApplicationState>(
  null 
) (LaunchSendReceiveMail);


export default withRouter(connectedHoc);