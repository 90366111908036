import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IReportListState, IReportListActionProps, actionCreators } from '@store/ReportList';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { DialogFieldset } from '@common/DialogStyles';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { pageLeave } from '@commonResources/userModified';
import { CustomCheckBox } from '@common/CustomCheckBox';
import { FooterInstructions } from '@common/FooterInstructions';
import { AssuranceMenu } from '@common/AssuranceMenu';
import { ReportFileInfo, ReportFormInfo, ViewReportData } from './ReportListEntities';
import FileViewer from '@common/FileViewer';
import ApiClient from '@commonResources/ApiClient';
import { LinkCell } from '@common/LinkCell';
import { LogMessage } from '../../../scripts/util/LogHelper';
import { ISortIndicator } from '@scripts/util/SortHelper'

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
        
    #report-id {
        overflow: 'auto',
        padding: '1px',
    }

    .Created {
        vertical-align: inherit;
        width:150px;
    }

    .Name, .Creator, .Size {
        vertical-align: inherit;
        width:380px;
    }

    .Creator {
        vertical-align: inherit;
        width:115px;
    }

    .Size {
        vertical-align: inherit;
        width:70px;
    }
    .selectDropdown {
        vertical-align: inherit;
    }

    th.Download { 
        z-index: 20;
    }

    select {
        font-size: 12px;
        font-weight: 600;
    }
    
`;

interface IComponentProps {
    // View Reports Security Bits
    canView: boolean;                       // SecurityBits.FN_VIEW_REPORTS
    canDelete: boolean;                     // SecurityBits.FN_DELETE_REPORTS
    canViewClaimReports: boolean;           // SecurityBits.RPT_VIEW_CLAIM_REPORTS
    canViewRemitReports: boolean;           // SecurityBits.RPT_VIEW_REMIT_REPORTS
    canViewSubmissionReports: boolean;      // SecurityBits.RPT_VIEW_SUBMISSION_REPORTS
    canViewSystemReports: boolean;          // SecurityBits.RPT_VIEW_SYSTEM_REPORTS
    canViewManagementReports: boolean;      // SecurityBits.RPT_VIEW_MANAGEMENT_REPORTS
    canViewMiscellaneousReports: boolean;   // SecurityBits.RPT_VIEW_MISCELLANEOUS_REPORTS
    canViewCustomReports: boolean;          // SecurityBits.RPT_VIEW_CUSTOM_REPORTS
    canViewOutsourceReports: boolean;       // SecurityBits.EBO_RPT_VIEW_OUTSOURCE_REPORTS
    testMode: boolean;
};

interface IComponentState {
    selectedReports: string[]
    cancelLeave: boolean;
    isOpen: boolean;
    selectedOperation: string;
    viewReportsData: ViewReportData[];
    selectedFilterType: string;
    reportFormAPIData: any;
    reportFileAPIData: any;
    fileServerInfo: any;
    sortIndicator: ISortIndicator;
    pageTotal: number;
    pageCount: number;
    diskSpace: number;
}

interface HeaderProps { columns: {} }

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    isOpen: false,
    selectedOperation: 'delete',
    viewReportsData: [],
    selectedFilterType: 'All Reports',
    reportFormAPIData: '',
    reportFileAPIData: '',
    fileServerInfo: '',
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
    },
    pageTotal: 1,
    pageCount: 1,
    diskSpace: 0,
    selectedReports: []
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IReportListProps = IMergeCrudComponentProps<IReportListState, IReportListActionProps, IOwnProps>;

export interface IViewReportComponentProps {
    reports: Array<any>;
    onReportSelect?: any;
};

export interface IViewReportContentProps {
    reportSelect: IViewReportComponentProps;
    onReportSelect?: any;
};

export class ReportList extends React.Component<IReportListProps, IComponentState> {
    protected selectedReports: string[] = [];
    protected selectedOperation: string = '';

    readonly DELETE_REPORT_ALERT = <React.Fragment>
        <p> Are you sure you want to delete the selected files?</p>
        <p>This action cannot be undone.</p></React.Fragment>;
    readonly PAGE_SIZE = 100;

    static defaultProps: IComponentProps = {
        // View Report Security Bits
        canView: false,                     // SecurityBits.FN_VIEW_REPORTS
        canDelete: false,                   // SecurityBits.FN_DELETE_REPORTS
        canViewClaimReports: false,         // SecurityBits.RPT_VIEW_CLAIM_REPORTS
        canViewRemitReports: false,         // SecurityBits.RPT_VIEW_REMIT_REPORTS
        canViewSubmissionReports: false,    // SecurityBits.RPT_VIEW_SUBMISSION_REPORTS
        canViewSystemReports: false,        // SecurityBits.RPT_VIEW_SYSTEM_REPORTS
        canViewManagementReports: false,    // SecurityBits.RPT_VIEW_MANAGEMENT_REPORTS
        canViewMiscellaneousReports: false, // SecurityBits.RPT_VIEW_MISCELLANEOUS_REPORTS
        canViewCustomReports: false,        // SecurityBits.RPT_VIEW_CUSTOM_REPORTS
        canViewOutsourceReports: false,     // SecurityBits.EBO_RPT_VIEW_OUTSOURCE_REPORTS
        testMode: false
    };

    constructor(props: IReportListProps) {
        super(props);
        this.state = DEFAULT_STATE;

        this.handleSort = this.handleSort.bind(this);
        this.buildViewReportsGridData = this.buildViewReportsGridData.bind(this);
        
    }

    public componentDidMount() {
        if (this.props.canView) {
            this.loadViewReports();
        }
        pageLeave();
    }

    public componentWillUnmount() {
        pageLeave();
    }

    delay = async (ms: number) => new Promise(res => setTimeout(res, ms));

    loadViewReports = async () => {
        var url = this.props.testMode ? URLs.api + '/api/data/GetTestReportFormData' : URLs.api + '/api/data/GetReportFormData';

        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    let responseData: string = JSON.stringify(data);
                    this.setState({ reportFormAPIData: JSON.parse(responseData) }, () => { this.getFolderInfo() });
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    };

    deleteFile = async () => {
        var url = URLs.api + '/api/data/DeleteFile';
        console.log('selected Reports for Delete:', this.selectedReports.toString());
        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify({ files: this.state.selectedReports.toString(), testMode: this.props.testMode }),
        })
            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    console.log(`URL: ${url} Response Data: ${JSON.stringify(data)}`);
                    LogMessage("error", `URL: ${url} Response Data: ${JSON.stringify(data)}`);
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    }

    getFolderInfo = async () => {
        var url = this.props.testMode ? URLs.api + '/api/data/GetTestFolderInfo': URLs.api + '/api/data/GetFolderInfo';

        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    let responseData = JSON.stringify(data);
                    this.setState({ reportFileAPIData: JSON.parse(responseData) }, () => { this.getFileServerInfo(); this.buildViewReportsGridData(); });
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    }

    getFileServerInfo = async () => {
        var url = URLs.api + '/api/data/GetFileServerInfo';

        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    let responseData = JSON.stringify(data);
                    this.setState({ fileServerInfo: JSON.parse(responseData) });
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    }

    downloadFiles = async () => {
        var url = URLs.api + '/api/data/DownloadFiles';
        console.log('selected Reports for Download:', this.selectedReports.toString());
        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(this.selectedReports.toString())
        })
            .then(async response => {
                if (response.status === 200) {
                    //const data: any = await response.json();
                    const data: any = await response.text();
                    let responseData = data.replace(/\\\\/g, "\\").replace(/"/g, "");
                    console.log("Report File Path:", responseData);
                    if (responseData.indexOf(".zip") > 0) {
                        let uri = responseData;
                        if (uri.startsWith('"')) {
                            uri = uri.substring(1);
                        }
                        if (uri.endsWith('"')) {
                            uri = uri.substring(0, uri.length - 1);
                        }

                        let segments = responseData.split("\\");
                        let fileName = segments[segments.length - 1];
                        console.log("fileName:[" + fileName + "]uri:[" + uri + "]");
                        ApiClient.downloadZIP(fileName, uri);
                    }
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    }

    getReportForm(reportList: Array<any>, reportID: string, reportType: string): ReportFormInfo {
        let reportFormInfo: ReportFormInfo = new ReportFormInfo();

        if (reportList && reportList.length > 0) {
            const reportFound = reportList.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportType = reportType;
                reportFormInfo.ReportID = reportFound.ID;
            }
        }
        return reportFormInfo;
    }

    getReportFormInfo(reportID: string, reportFormAPIData: any): ReportFormInfo {
        let reportFormInfo: ReportFormInfo = new ReportFormInfo();

        // Check reportID exists in UnreleasedReportList
        let unreleasedReports = reportFormAPIData.UnreleasedReportList as Array<any>;
        if (unreleasedReports && unreleasedReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[0];
            const reportFound = unreleasedReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in ReleasedReportList
        let releasedReports = reportFormAPIData.ReleasedReportList as Array<any>;
        if (releasedReports && releasedReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[1];
            const reportFound = releasedReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in RemitReportList
        let remitReports = reportFormAPIData.RemitReportList as Array<any>;
        if (remitReports && remitReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[2];
            const reportFound = remitReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in SubmissionReportList
        let submissionReports = reportFormAPIData.SubmissionReportList as Array<any>;
        if (submissionReports && submissionReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[3];
            const reportFound = submissionReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in SystemReportList
        let systemReports = reportFormAPIData.SystemReportList as Array<any>;
        if (systemReports && systemReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[4];
            const reportFound = systemReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in MiscellaneousReportList
        let miscellaneousReports = reportFormAPIData.MiscellaneousReportList as Array<any>;
        if (miscellaneousReports && miscellaneousReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[5];
            const reportFound = miscellaneousReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in UtilityReportList
        let utilityReports = reportFormAPIData.UtilityReportList as Array<any>;
        if (utilityReports && utilityReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[6];
            const reportFound = utilityReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in LookupReportList
        let lookupReports = reportFormAPIData.LookupReportList as Array<any>;
        if (lookupReports && lookupReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[7];
            const reportFound = lookupReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in CustomReportList
        let customReports = reportFormAPIData.CustomReportList as Array<any>;
        if (customReports && customReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[8];
            const reportFound = customReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in ClaimVisionReportList
        let claimVisionReports = reportFormAPIData.ClaimVisionReportList as Array<any>;
        if (claimVisionReports && claimVisionReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[9];
            const reportFound = claimVisionReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in ManagementReportList
        let managementReports = reportFormAPIData.ManagementReportList as Array<any>;
        if (managementReports && managementReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[10];
            const reportFound = managementReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in DirectEntryList
        let directReports = reportFormAPIData.DirectReportList as Array<any>;
        if (directReports && directReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[11];
            const reportFound = directReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        // Check reportID exists in OutsourceReportList
        let outsourceReports = reportFormAPIData.OutsourceReportList as Array<any>;
        if (outsourceReports && outsourceReports.length > 0) {
            let reportType: string = Object.keys(reportFormAPIData)[12];
            const reportFound = outsourceReports.find(rpt => rpt.ID === reportID);
            if (reportFound) {
                reportFormInfo.Allow = reportFound.Allow;
                reportFormInfo.Name = reportFound.Name;
                reportFormInfo.ReportID = reportFound.ID;
                reportFormInfo.ReportType = reportType.substring(0, reportType.indexOf("ReportList"));
                return reportFormInfo;
            }
        }

        return reportFormInfo;
    }

    getReportFileInfo(rptRecord: any, reportFormAPIData: any): ReportFileInfo {
        let reportFileInfo: ReportFileInfo = new ReportFileInfo();
        let fileName: string = rptRecord.Name;
        let reportID: string = "";
        let clientID: string = "";
        let userID: string = "";
        let userName: string = "n/a";
        let reportName: string = "";
        let reportType: string = "";
        let allow: number = 0;
        let rptFilter: string = "";
        let isViewable: boolean = true;

        if (rptRecord && rptRecord.Name.length > 0 &&
            +(rptRecord.Size) > 0 &&
            rptRecord.Type != "$$$ File") {

            let nameAttributes: string[] = rptRecord.Name.split('_');
            if (nameAttributes.length > 3
                || rptRecord.Type === "DAT File"
                || nameAttributes[0] === "crdetail"
                || nameAttributes[0] === "Backfeed"
                || nameAttributes[0].substring(0, 6) === "PatBal"
                || nameAttributes[0].substring(0, 6) === "PatBaN"
                || nameAttributes[0] === "RRT"
                || nameAttributes[0] === "Epic277"
                || nameAttributes[1] === "CUFJobResults"
            ) {
                if (nameAttributes[0] === "crdetail") {
                    reportID = "1864";
                } else if (nameAttributes[0] === "Backfeed") {
                    reportID = "1865";
                } else if (nameAttributes[0].substring(0, 6) === "PatBal") {
                    reportID = "1100";
                } else if (nameAttributes[0].substring(0, 6) === "PatBaN") {
                    reportID = "1104";
                } else if (nameAttributes[0] === "RRT") {
                    reportID = "0";
                    clientID = nameAttributes[1] ? nameAttributes[1] : "";
                    reportName = "Regulatory Reporting File";
                    reportType = "Custom";
                    allow = 1;
                    rptFilter = nameAttributes[4] ? nameAttributes[4] : "";
                    if (rptFilter.indexOf(".") === -1) {
                        rptFilter = "";
                        for (var i = 4; i < nameAttributes.length; i++)
                            rptFilter += `${nameAttributes[i]} `;
                    }
                } else if (nameAttributes[0] === "Epic277") {
                    reportID = "0";
                    clientID = nameAttributes[1];
                    reportName = "EPIC 277 CRD File";
                    reportType = "Miscellaneous";
                    allow = 1;
                    rptFilter = "";
                    userName = nameAttributes[2] ? nameAttributes[2].replace("-", "%") : "";
                } else if (nameAttributes[1] === "CUFJobResults") {
                    reportID = "1550";
                    reportName = "CUFJobResults";
                    reportType = "Custom";
                } else {
                    reportID = nameAttributes[0];
                    clientID = nameAttributes[1];
                    userID = nameAttributes[2];
                    userName = nameAttributes[3] ? nameAttributes[3].replace("-", "%") : "";
                }

                let reportFormInfo = this.getReportFormInfo(reportID, reportFormAPIData);

                // We did not find it
                if ((!reportFormInfo || reportFormInfo.ReportID == "") && rptRecord.Type === "DAT File") {
                    userName = "n/a";
                    reportFormInfo = this.getReportFormInfo("1305", reportFormAPIData);  // Retrieve this report form info instead
                }

                // If we found it
                if (reportFormInfo && reportFormInfo.ReportID == reportID) {
                    reportName = reportFormInfo.Name;
                    reportType = reportFormInfo.ReportType; 
                    if (reportType === "Lookup") {
                        reportType = "Miscellaneous";
                    }

                    allow = reportFormInfo.Allow; 

                    reportType = reportType.replace(/^Direct$/, "Direct Entry");

                    switch (reportType) {
                        case "Unreleased":
                        case "Released":
                            isViewable = this.props.canViewClaimReports;
                            break;

                        case "Remit":
                            isViewable = this.props.canViewRemitReports;
                            break;

                        case "Submission":
                            isViewable = this.props.canViewSubmissionReports;
                            break;

                        case "System":
                            isViewable = this.props.canViewSystemReports;
                            break;

                        case "Management":
                            isViewable = this.props.canViewManagementReports;
                            break;

                        case "Miscellaneous":
                            isViewable = this.props.canViewMiscellaneousReports;
                            break;

                        case "Direct Entry":
                            isViewable = true; // TODO:GetCookie("DirectEntry") = "1"
                            break;

                        case "Custom":
                            isViewable = this.props.canViewCustomReports;
                            break;

                        case "Outsource":
                            isViewable = this.props.canViewOutsourceReports;
                            break;

                        default:
                            isViewable = true;
                            break;
                    }
                    // We did not find it
                } else {
                    isViewable = false;
                    if (reportName === "Regulatory Reporting File") {
                        reportName += ` - Profile: ${rptFilter}`;  
                        let inx = reportName.lastIndexOf('.');
                        if (inx > 0) {
                            reportName = reportName.substring(0, inx);
                        }
                        isViewable = this.props.canViewCustomReports;
                    } else if (reportName === "EPIC 277 CRD File") {
                        isViewable = this.props.canViewMiscellaneousReports;
                    }
                }

                if (isViewable) {

                    if (nameAttributes.length === 6 && nameAttributes[0] !== "RRT" ) { 
                        rptFilter = nameAttributes[5];
                        let dotIndex = rptFilter.indexOf('.');
                        if (dotIndex > 0) {
                            rptFilter = rptFilter.substring(0, dotIndex);
                        }
                        while (rptFilter.indexOf('-') > 0) {
                            rptFilter = rptFilter.replace("-", "%");
                        }
                        rptFilter = unescape(rptFilter);
                    }
                    else {
                        rptFilter = "";
                    }
                  
                    reportFileInfo.ReportID = reportID;
                    reportFileInfo.ClientID = clientID;
                    reportFileInfo.ReportName = reportName;
                    reportFileInfo.Allow = allow;
                    reportFileInfo.ReportType = reportType;
                    reportFileInfo.RptFilter = rptFilter;
                    reportFileInfo.UserID = userID;
                    reportFileInfo.UserName = userName;
                    reportFileInfo.FileName = fileName;
                    reportFileInfo.RptSize = rptRecord.Size + " KB";
                    reportFileInfo.RptModified = rptRecord.Modified;
                    reportFileInfo.IsViewable = isViewable;
                    reportFileInfo.UTC = rptRecord.UTC;
                    reportFileInfo.Type = rptRecord.Type;
                }
            }
        }
        return reportFileInfo;
    }

    buildSelectedReportFileInfo(selectedReportType: string = "All Reports", reportFileAPIData: any, reportFormAPIData: any): ReportFileInfo[] {
        let reportFilesInfo: ReportFileInfo[] = [];

        if (selectedReportType && selectedReportType.length > 0 && reportFileAPIData) {
            if (reportFileAPIData && reportFileAPIData.Files instanceof Array && reportFileAPIData.Files.length > 0) {
                for (let idx = 0; idx < reportFileAPIData.Files.length; idx++) {

                    let reportFileInfo: ReportFileInfo = this.getReportFileInfo(reportFileAPIData.Files[idx], reportFormAPIData);

                    if (reportFileInfo.ReportID != "" &&
                        (selectedReportType.toLowerCase() === "all reports" || reportFileInfo.ReportType.toLowerCase() === selectedReportType.toLowerCase())) {

                        const found = reportFilesInfo.some(rptInfo => rptInfo.Allow === reportFileInfo.Allow &&
                            rptInfo.FileName === reportFileInfo.FileName &&
                            rptInfo.ClientID === reportFileInfo.ClientID && rptInfo.IsViewable === reportFileInfo.IsViewable &&
                            rptInfo.ReportID === reportFileInfo.ReportID && rptInfo.ReportName === reportFileInfo.ReportName &&
                            rptInfo.ReportType === reportFileInfo.ReportType && rptInfo.RptFilter === reportFileInfo.RptFilter &&
                            rptInfo.RptModified === reportFileInfo.RptModified && rptInfo.RptSize === reportFileInfo.RptSize &&
                            rptInfo.UserID === reportFileInfo.UserID && rptInfo.UserName === reportFileInfo.UserName);

                        if (!found) {
                            reportFilesInfo.push(reportFileInfo);
                        }
                    }
                }

            }
        }
        return reportFilesInfo;
    }

    NumberFormat = (diskSpace: number): string => {
        return diskSpace.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    buildViewReportsGridData = (reportType: string = "All Reports"): ViewReportData[] => {
        const { reportFileAPIData, reportFormAPIData } = this.state;
        let viewReportsData: ViewReportData[] = this.buildViewReportsGridDataWithParam(reportType, reportFileAPIData, reportFormAPIData);

        this.setState({ viewReportsData }, () => {
            this.setState({
                pageCount: 1,
                pageTotal: Math.ceil(this.state.viewReportsData.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(this.state.viewReportsData.length / this.PAGE_SIZE),
                diskSpace: this.computeDiskSpace(),
            },
                () => {
                    let sortIndicator: ISortIndicator = {
                        sortColumn: 'Created',
                        sortDirection: 'down'
                    };
                    this.handleSort(sortIndicator);
                });
        });

        return viewReportsData;
    }

    buildViewReportsGridDataWithParam = (reportType: string, reportFileAPIData: any, reportFormAPIData: any): ViewReportData[] => {
        let viewReportsData: ViewReportData[] = [];
        const obSecurityClientId = reportFileAPIData.ClientId as number;

        let reportFilesInfo: ReportFileInfo[] = this.buildSelectedReportFileInfo(reportType, reportFileAPIData, reportFormAPIData);
        if (reportFilesInfo && reportFilesInfo.length > 0) {
            for (let idx = 0; idx < reportFilesInfo.length; idx++) {
                let viewReportData: ViewReportData = new ViewReportData();
                if (reportFilesInfo[idx]) {
                    viewReportData.Created = reportFilesInfo[idx].RptModified;
                    viewReportData.UTC = parseFloat(reportFilesInfo[idx].UTC);

                    let fileType: string = "";
                    fileType = reportFilesInfo[idx].Type.substring(0, reportFilesInfo[idx].Type.indexOf(" File"));
                    let clientId: string = reportFilesInfo[idx].ClientID;
                    if (clientId === "") clientId = `${obSecurityClientId}`;
                    let reportFileName: string = reportFilesInfo[idx].FileName.replace(/'/g, "\\'");
                    let folderPathForTestMode: string = this.props.testMode ? "ReportsTestMode" : "Reports";
                    let fileLink: string = '/shared/Open%3D\/Sites/' + clientId + "/" + folderPathForTestMode + "/" + reportFileName;
                    let name = reportFilesInfo[idx].ReportName;
                    if (reportFilesInfo[idx].RptFilter !== "") {
                        name += ` - Filter: ${reportFilesInfo[idx].RptFilter}`;
                    }
                    if (reportFilesInfo[idx].Allow != 0) {
                        viewReportData.Name = <FileViewer key={idx} name={name} fileLink={fileLink} />
                        viewReportData.ReportName = reportFilesInfo[idx].ReportName;
                    } else {
                        viewReportData.Name = reportFilesInfo[idx].ReportName;
                    }

                    viewReportData.Size = reportFilesInfo[idx].RptSize;
                    viewReportData.Filters = reportFilesInfo[idx].ReportType + " (" + fileType + ")";
                    viewReportData.Creator = reportFilesInfo[idx].UserName;
                    viewReportData.Operation = new CustomCheckBox("checkbox", idx);
                    if (reportFilesInfo[idx].Type !== "$$$ File" &&
                        reportFilesInfo[idx].ReportType !== "" &&
                        viewReportData &&
                        viewReportData.Name &&
                        viewReportData.Name !== "") {
                        viewReportsData.push(viewReportData);
                    }
                }
            }
            this.computeDiskSpace();
        }
        return viewReportsData;
    }

    private computeDiskSpace = (): number => {
        const { viewReportsData } = this.state;
        let diskSpace: number = 0;
        if (viewReportsData) {
            for (let idx = 0; idx < viewReportsData.length; idx++) {
                let kbIndex: number = viewReportsData[idx].Size.indexOf(" KB");
                if (kbIndex != -1) {
                    diskSpace += +(viewReportsData[idx].Size.substring(0, kbIndex));
                }
            }
        }
        return diskSpace;
    }

    private getSizeAsNumber(size: string): number {
        let sizeAsNumber: number = 0;
        if (size) {
            let kbIndex: number = size.indexOf(" KB");
            if (kbIndex != -1) {
                sizeAsNumber = +(size.substring(0, kbIndex));
            }
        }
        return sizeAsNumber;
    }

    private getReportNameWithPath(record: any): string {
        let reportName: string = "";
        if (record && record.Name.props.fileLink && record.Name.props.fileLink) {
            let indexOfSites = record.Name.props.fileLink.indexOf('\Sites');
            reportName = record.Name.props.fileLink.substring(indexOfSites).replace(/'/g, "\\'");
        }
        return reportName;
    }

    // builds Selected Reports
    public onSelectReport(isSelected: boolean, record: any) {
        let reportName: string = this.getReportNameWithPath(record);

        if (isSelected !== undefined) {
            if (reportName && reportName.length > 0) {
                reportName = reportName.replace(/\//g, '\\');
                if (isSelected === true) {
                    console.log("add [", reportName, "] to the selected report list.");
                    this.selectedReports.push(reportName);
                } else if (isSelected === false) {
                    if (this.selectedReports && this.selectedReports.length > 0 && this.selectedReports.includes(reportName)) {
                        let index = this.selectedReports.indexOf(reportName);
                        if (index > -1) {
                            this.selectedReports.splice(index, 1);
                        }
                        console.log("remove [", reportName, "] from the list. Report list has ", this.selectedReports.length, " reports");
                    }
                }

                console.log("selected report list has[", this.selectedReports.length, "] reports for Operations");
            }
        }

    }

    getSelectedOperation(): string {
        return this.selectedOperation.length === 0 ? "delete" : this.selectedOperation;
    }

    setSelectedOperation(opIndex: number) {
        this.selectedOperation = (opIndex === 0) ? "delete" : "download";
        this.setState({ selectedOperation: (opIndex === 0) ? "delete" : "download" })
    }

    getSelectedReportType(): string {
        let filter: string = "all reports";
        this.getReportListGridColumnHeaders().forEach(function (header) {
            if (header.text.toLowerCase() === "unreleased" || header.text.toLowerCase() === "released" || header.text.toLowerCase() === "remit" ||
                header.text.toLowerCase() === "submission" || header.text.toLowerCase() === "system" || header.text.toLowerCase() === "management" ||
                header.text.toLowerCase() === "custom" || header.text.toLowerCase() === "outsource" || header.text.toLowerCase() === "direct entry") {
                filter = header.text.toLowerCase();
            }
        });
        return filter;
    }

    public onOKViewReport(e: React.ChangeEvent<HTMLButtonElement>) {
        let selectedOperation: string = this.getSelectedOperation();
        console.log("selected Operation: " + selectedOperation);
        if (this.selectedReports.length === 0 && this.state.viewReportsData.length !== 0)
            this.setState({ isOpen: true })
        else
            if (this.state.selectedOperation !== "") {
                switch (selectedOperation) {
                    case "delete":
                        this.setState({ isOpen: true, selectedReports: this.selectedReports })
                        break;
                    case "download":
                        this.downloadFiles();
                        if (!this.props.testMode)
                            this.props.history.push("/LandingPage");
                        else
                            this.props.history.push("/TestMode/UserHome");
                        break;
                }

            }
    }

    public onCancelViewReport(e: React.ChangeEvent<HTMLButtonElement>) {
        this.setState({ cancelLeave: true })
        if (!this.props.testMode)
            this.props.history.push("/LandingPage");
        else
            this.props.history.push("/TestMode/UserHome");
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    getReportListGridColumnHeaders() {
        return new Set([
            {
                dataName: "Created",
                text: 'Created',
                sortable: true,
                cellType: "text",
                isSorted: 1,
            },
            {
                dataName: "Name",
                text: "Name",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Creator",
                text: "Creator",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Size",
                text: "Size",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Filters",
                text: "All Reports",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Operation",
                text: "Delete",
                sortable: false,
                cellType: "custom",
                isSorted: 0,
            }
        ]);
    }

    public previousPage(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            pageTotal,
            pageCount
        } = this.state;

        if (pageTotal >= pageCount) {
            this.setState({
                pageCount: pageCount - 1,
                viewReportsData: this.buildViewReportsGridData(this.getSelectedReportType()),
                diskSpace: this.computeDiskSpace()
            });
            e.target.value = pageCount.toString();
        }
    }

    public nextPage(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            viewReportsData,
            pageCount,
            pageTotal
        } = this.state;

        if (pageCount < pageTotal) {
            this.setState({
                pageCount: pageCount + 1,
                viewReportsData: viewReportsData.slice(this.PAGE_SIZE),
                diskSpace: this.computeDiskSpace(),
            });
            e.target.value = pageCount.toString();
        }
    }

    private getTime(date?: Date) {
        return date != null ? date.getTime() : 0;
    }

    public handleSort(sortIndicator: ISortIndicator) {
        const { viewReportsData } = this.state;

        let sortedRecords: ViewReportData[] = [];

        switch (sortIndicator.sortColumn) {
            case 'Created': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewReportsData.sort((a, b) => b.UTC - a.UTC):
                    viewReportsData.sort((a, b) => a.UTC - b.UTC);
            }
                break;
            case 'Name': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewReportsData.sort((a, b) => (a.ReportName).toString().localeCompare((b.ReportName).toString())) :
                    viewReportsData.sort((a, b) => (b.ReportName).toString().localeCompare((a.ReportName).toString()));
            }
                break;
            case 'Creator': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewReportsData.sort((a, b) => a.Creator.localeCompare(b.Creator)) :
                    viewReportsData.sort((a, b) => b.Creator.localeCompare(a.Creator));
            }
                break;
            case 'Size': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    viewReportsData.sort((a, b) => this.getSizeAsNumber(a.Size) - this.getSizeAsNumber(b.Size)) :
                    viewReportsData.sort((a, b) => this.getSizeAsNumber(b.Size) - this.getSizeAsNumber(a.Size));
            }
                break;
        }

        this.setState({ sortIndicator, viewReportsData: sortedRecords });
    }

    public showViewReportAlers() {
        return (
            <ModalConfirmation
                isOpen={this.state.isOpen}
                formattedMessage={(
                    <React.Fragment>
                        {this.state.selectedOperation === 'delete' && this.state.selectedReports.length > 0 ? this.DELETE_REPORT_ALERT :
                            <p>Please select report(s) to {this.state.selectedOperation}.</p>}
                    </React.Fragment>)
                }
                onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isOpen: false }) }}
                onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                    this.setState({ isOpen: false })
                    if (this.state.selectedOperation === 'delete' && this.state.selectedReports.length > 0) {
                        this.deleteFile();
                        if (!this.props.testMode)
                            this.props.history.push("/LandingPage");
                        else
                            this.props.history.push("/TestMode/UserHome");
                    }
                }
                }
                onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isOpen: false }) }}
                alertMode={this.state.selectedReports.length === 0}
            />
        );
    }

    public render() {
        var instruction = <React.Fragment>To sort, click on the column heads. To download, select Download from the Delete dropdown, check the reports, and click Continue. To Delete reports, select Delete from the Delete dropdown, check the reports, and click Delete.</React.Fragment>;
        var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKViewReport(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancelViewReport(e)} />;
        // Report Type Filters
        const reportFilterTypeData: { reportFilterType: string }[] = this.props.testMode ? [
            { reportFilterType: 'All Reports' },
            { reportFilterType: 'Unreleased' },
            { reportFilterType: 'Released' },
            { reportFilterType: 'Submission' }] : [
            { reportFilterType: 'All Reports' },
            { reportFilterType: 'Unreleased' },
            { reportFilterType: 'Released' },
            { reportFilterType: 'Remit' },
            { reportFilterType: 'Submission' },
            { reportFilterType: 'System' },
            { reportFilterType: 'Management' },
            { reportFilterType: 'Direct Entry' },
            { reportFilterType: 'Miscellaneous' },
            { reportFilterType: 'Outsource' },
            { reportFilterType: 'Custom' }]

        // Report Type Filter
        const options = reportFilterTypeData.map(flt => <option
            value={flt.reportFilterType}
            selected={this.state.selectedFilterType === flt.reportFilterType}>
            {flt.reportFilterType}
        </option>);

        const reportFilters =
            <select onChange={filter => this.setState(
                {
                    selectedFilterType: filter.target.value,
                    viewReportsData: this.buildViewReportsGridData(filter.target.value),
                    pageCount: 1,
                    pageTotal: Math.ceil(this.state.viewReportsData.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(this.state.viewReportsData.length / this.PAGE_SIZE),
                    diskSpace: this.computeDiskSpace(),
                })}>
                {options}
            </select>;

        const reportOperations =
            <select value={this.state.selectedOperation} onChange={operation => this.setSelectedOperation(operation.target.selectedIndex)}>
                <option value='delete'>Delete</option>
                <option value='download'>Download</option>
            </select>;

        const header = ({ columns }: HeaderProps) => <thead><tr>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="Created"
                        text="Created"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Name"
                        text="Name"
                        cellType="link"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}

                    />
                    <ARMHeaderCell
                        dataName="Creator"
                        text="Creator"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Size"
                        text="Size"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Filters"
                        text="All Reports"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                        select={reportFilters}
                    />
                    <ARMHeaderCell
                        dataName="Operations"
                        text="Delete"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                        select={reportOperations}
                    />
                </React.Fragment>
            }
        </tr></thead>

        let footerMsg: string = '0 report(s) available taking 0 KB';
        if (this.state.viewReportsData) {
            let diskSpace: number = this.computeDiskSpace();
            footerMsg = `${this.state.viewReportsData.length} report(s) available taking ${this.NumberFormat(diskSpace)} KB`;
        }

        let helpUrl = this.props.testMode ? '/TestMode/Support/Help/HELP_ViewingReports_TestMode.htm' : '/Support/Help/HELP_UsingRpts.htm';

        return (
            <DialogWrapper title="View Reports" instruction={instruction} helpUrl={helpUrl} buttons={buttons}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <DialogFieldset height='100%'>
                        <ARMGrid
                            dom-ID="report-id"
                            onClickThrough={(e: React.ChangeEvent<HTMLInputElement>, record: any) => this.onSelectReport(e.target.checked, record)}
                            isFixedHeader={true}
                            maxHeight='492px'
                            columns={this.getReportListGridColumnHeaders()}
                            headerComponent={header}
                            records={this.state.viewReportsData}
                            selectionKey='empty'
                        />
                        <br></br>
                        <FooterInstructions
                            footerMsgs={[footerMsg]}
                            pageCount={this.state.pageCount}
                            pageTotal={this.state.pageTotal}
                            onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.previousPage(e)}
                            onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.nextPage(e)}
                        />
                    </DialogFieldset>
                </ContentWrapper>
                {this.showViewReportAlers()}
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IReportListState, IReportListActionProps, IOwnProps, IReportListProps, ApplicationState>(
    createCrudMapStateToProps('reportList'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ReportList);

export default withRouter(connectedHoc);
