import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ModalLoading, ModalLoadingDiv } from '@common/ModalLoading';


export interface IFilterFormProps {
    filterName?: string,
    filterId: string,
    process?: string,
    parameter?: string,
    filterType: string,
    restrictionsExist?: string,
    xmlRequest?: string,
    onMounted?: any,
    onMessage?: (message: string) => void;
    onNoData?: () => void;
};

export type UserFilterFormProps = IFilterFormProps & RouteComponentProps<{}>;

export class UserFilterForm extends React.Component<UserFilterFormProps>
{
    constructor(props: UserFilterFormProps) {
        super(props);
    }

    async componentDidMount() {
        if (this.props.xmlRequest) {
            await this.fetchFilter(this.props.xmlRequest);
        }
        if (this.props.onMounted) {
            this.props.onMounted();
        }
    }

    async componentDidUpdate(prevProps: any) {
        if (this.props.xmlRequest && this.props.xmlRequest !== prevProps.xmlRequest) {
            await this.fetchFilter(this.props.xmlRequest);
        }
    }

    createPostDataFromProps(): IFilterFormProps {
        return {
            filterId: this.props.filterId,
            filterName: this.props.filterName,
            process: this.props.process,
            parameter: this.props.parameter ? this.props.parameter : "",
            filterType: this.props.filterType,
            restrictionsExist: this.props.restrictionsExist ? this.props.restrictionsExist : "",
        };
    }

    alert(message: string) {
        this.props.onMessage ? this.props.onMessage(message) : alert(message);
    }

    processResponse(data: string) {
        let strResponse = data;
        let bContinue = true;
        console.log('fetchFilter response = ' + strResponse);

        let postData = this.createPostDataFromProps();

        // user access restrictions should preface the responseText on a successful
        // filter, currently "RUA=[Y|N];"
        if (strResponse.substr(0, 4) === "RUA=") {
            postData.restrictionsExist = 'N';
            if (strResponse.substr(4, 1) === "Y") {
                postData.restrictionsExist = 'Y';
            }
            strResponse = strResponse.substr(6);
        }

        // in this event, we check the claim count and stop the processing (restting) if the count is 0 (zero)
        if (strResponse.substr(0, 7) === "CLMCNT=") {
            let nClaimCount = Number(strResponse.substr(7, strResponse.length - 8));
            if (nClaimCount <= 0) {
                bContinue = false;
            }

            strResponse = "";
        }

        if (bContinue) {
            if (strResponse === "") {
                if (this.props.filterId === "0") {
                    postData.filterName = '';
                }
                console.log('Submitting UserFilterForm');
                this.props.history.push('/CFI/Default/', postData);
            }
            else {
                if (this.props.onNoData) this.props.onNoData();
                this.alert("There are no claims that meet your criteria.");
            }
        }
        else {
            // reset the UI for the user to try again (i.e. to find claims to work with to view/edit)
            if (this.props.onNoData) this.props.onNoData();
            this.alert("There are no claims that meet your criteria.");
        }
    }

    public fetchFilter = async (xmlRequest: string) => {
        //TEST: uncomment for local testing
        //this.processResponse("RUA=N;CLMCNT=51;");
        //return;
        //TEST END: uncomment for local testing
        const response = await fetch("/Transactions/XmlCallBack.asp", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: xmlRequest
        });
        const data = await response.text();
        if (response.status === 306) {
            if (this.props.onNoData) this.props.onNoData();
            this.alert("There are no claims that meet your criteria.");
        }
        else {
            this.processResponse(data);
        }
    }

    public render() {
        if (this.props.xmlRequest) {
            //return <ModalLoading fullscreen={true} isOpen={true} />;
            return <ModalLoading isOpen={true} />;
        }
        return null;
    }
}

export default withRouter(UserFilterForm);
