import { Reducer, Action } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"  
import { deepCopyFunction } from '@commonResources/validations';


const DATE_RELEASED_ID: string = "9";
const DATE_ASS_STATUS_DATE_EFF_ID: string = "10";
const DATE_SUBMITDATE_ID: string = "4";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IRSRTFilterUIState {

    filter: APIRSRT_FilterWrapper;  // the visual data of filter
    rrtProfileFilter: APIRSRT_RRTProfileFilterWrapper
    profileDisable: boolean;
    formTypeDisable: boolean;
    sentToStateDisable: boolean;
    notSentToStateDisable: boolean;
    profileName: APIRSRT_FilterSimpleID;
    rsrtAlert?: string,
    bLoading: boolean,
    focusField: string,
    isBusy: boolean,
    clearForms: number,
    renderProfileHelper:number,
    postAction: string,
    filterInitialized: boolean,
    process: string,
    
}


export interface IRSRTFilterUIData {
    index: string;
    value: boolean;
};


interface IRSRTFilterUIFieldUpdate {
    value: string;
}

interface IRSRTFilterUIArrayUpdate {
    value: string[];
}

interface IRSRTFilterUISimpleIDArray {
    selected: APIRSRT_FilterSimpleID[];
}

interface IRSRTFilterUISimpleID {
    selected: APIRSRT_FilterSimpleID;
}

interface IRSRTFilterIDValue {
    selected: APIRSRT_FilterUserField;
}

interface IRSRTFilterUIJSONData {
    data: any
}

interface IRSRTFilterUIIdValue {
    id: any;
    value: string;
}

interface IRSRTFilterUISelect {
    index: number;
    value: string;
    text: string;
}

export const defaultFilter: IRSRTFilterUIData = {
    index: '',
    value: false,
};

interface IRSRTFilterUIFieldData {
    data: any;
    index: number;
}

interface IRSRTFilterDateData {
    data: APIRSRT_FilterDateRange;
    value?: boolean;
}

interface IRSRTFilterUserFieldData {
    id: any;
    value: string;
    index: number;
}

interface IRSRTFilterUIFieldDataEx {
    id: string;
    value?: boolean;
    value2?: boolean;
}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface IRSRT_LoadFilter extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUIFieldData> { }
export interface IRSRT_SelectSimpleIds extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUISimpleIDArray> { }  
export interface IRSRT_SelectSimpleId extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUISimpleID> { }  
export interface IRSRT_IdValue extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUIIdValue> { }  
export interface IRSRT_FieldValue extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUIFieldUpdate> { }
export interface IRSRT_FieldData extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUIData> { }
export interface IRSRT_SelectData extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUISelect> { }
export interface IRSRT_FieldIDValue extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterIDValue> { }
export interface IRSRT_DateField extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterDateData> { }
export interface IRSRT_JsonData extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUIJSONData> { }
export interface IRSRT_UserFieldSpc extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUserFieldData> { }
export interface IRSRT_FieldDataEx extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUIFieldDataEx> { }
export interface IRSRT_UnDelete extends IApiActionData<APIRSRT_ListPopulationContainer, IRSRTFilterUIFieldDataEx> { }

export const actionCreators = {
    loadFilter: (filterInfo: IRSRT_LoadFilter) => createDataAction('RSRT_LOAD_FILTER', filterInfo),
    selectFormTypes: (selectFormTypes: IRSRT_SelectSimpleIds) => createDataAction('RSRT_SELECT_FORMTYPES', selectFormTypes),
    selectSentToState: (selectSentToState: IRSRT_FieldIDValue) => createDataAction('RSRT_SELECT_SENTTOSTATE', selectSentToState),
    selectSubmittedFile: (selectSubmittedFile: IRSRT_SelectSimpleId) => createDataAction('RSRT_SELECT_SUBMITTEDFILE', selectSubmittedFile),
    selectClaimStatus: (status: IRSRT_LoadFilter) => createDataAction('RSRT_SELECT_CLAIM_STATUS', status),
    updateAccountNumber: (data: IRSRT_FieldValue) => createDataAction('RSRT_UPDATE_ACCOUNTNUMBER', data),
    updateFinancialClass: (data: IRSRT_FieldValue) => createDataAction('RSRT_UPDATE_FCLASS', data),
    updateUserField6: (data: IRSRT_FieldValue) => createDataAction('RSRT_UPDATE_USERFIELD6', data),
    updateUserField7: (data: IRSRT_FieldValue) => createDataAction('RSRT_UPDATE_USERFIELD7', data),
    updateUserField8: (data: IRSRT_FieldValue) => createDataAction('RSRT_UPDATE_USERFIELD8', data),
    updateProfile: (updateProfile: IRSRT_SelectSimpleId) => createDataAction('RSRT_UPDATE_PROFILE', updateProfile),
    setClaimState: (data: IRSRT_FieldValue) => createDataAction('RSRT_SET_CLAIMSTATE', data),
    sendRSRTFilterAlert: (value: any) => createDataAction('RSRT_SET_CLAIMALERT', value),
    updateDateField: (data: IRSRT_DateField) => createDataAction('RSRT_UPDATE_DATEFIELD', data),
    disableProfile: (value: boolean) => createDataAction('RSRT_DISABLE_PROFILE', value),
    disableFormTypes: (value: boolean) => createDataAction('RSRT_DISABLE_FORMTYPES', value),
    disableSentToState: (value: boolean) => createDataAction('RSRT_DISABLE_SENTTOSTATE', value),
    disableNotSentToState: (value: boolean) => createDataAction('RSRT_DISABLE_NOTSENTTOSTATE', value),
    setFocusField: (field: string) => createDataAction('RSRT_SET_FOCUSFIELD', field),
    setBusy: (fieldInfo: IRSRT_FieldData) => createDataAction('RSRT_SETBUSY', fieldInfo),  
    setPostAction: (data: string) => createDataAction('RSRT_SET_POSTACTION', data),
    setFilterInitialized: (data: string) => createDataAction('RSRT_SET_FILTERINITIALIZED', data),
    setProcessName: (field: string) => createDataAction('RSRT_SET_PROCESSNAME', field),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const emptyFilter: APIRSRT_FilterWrapper = {
    Filter: {
        '@ID': '0',
        '@Name': '',
        '@Type': 'Claim',
        RRTProfileID: { '@ID': '' },
        SentToStateBatch: { '@ID': '' , '@Name':''},
        AccountNumber: { '@Value': '' },
        DateField: { '@ID': '', '@From': '', '@To': '' },
        UserField6: { '@ID': '25', '@Value': '' }, // ID is 25
        UserField7: { '@ID': '26', '@Value': '' },
        UserField8: { '@ID': '27', '@Value': '' },
        FinancialClass: { '@Value': '' },
        FormTypes: { FormType: [] },
        ClaimStatus: {
            '@Accepted': '', '@Warning': '', '@Rejected': '', '@All': '',
        },
        ClaimState: {'@ID':''},
        SubmittedFile: { '@ID': '' },
        DisplayOrder: { '@ID': '1' },
    }
};

export const emptyRRTProfileFilter: APIRSRT_RRTProfileFilterWrapper = {
    Filter: {
        '@ID': '0',
        '@Type': 'Claim',
        '@SubType': '',
        ServiceType: { '@Inpatient': '', '@Outpatient': '', '@All': 'true' },
        ClaimStatus: {
            '@Accepted': '', '@Warning': '', '@Rejected': '', '@All': '',
        },
        ClaimState: { '@ID': '' },
        TypeOfBill: { '@Value': '' },
        FinancialClass: { '@Value': '' },
        UserField: { '@ID': '36', '@Value': '' }, // ID is 25
        UserField2: { '@ID': '19', '@Value': '' },
        UserField3: { '@ID': '5', '@Value': '' },
        UserField4: { '@ID': '43', '@Value': '' }, // ID is 25
        UserField5: { '@ID': '51', '@Value': '' },
        UserField6: { '@ID': '25', '@Value': '' },
        UserField7: { '@ID': '26', '@Value': '' },
        UserField8: { '@ID': '27', '@Value': '' },
        DateField: { '@ID': '', '@From': '', '@To': '' },
        FormTypes: { FormType: [] },
        BillingOption: {
            '@Primary': '',
            '@Rebill': '',
            '@Secondary': '',
            '@Tertiary': '',
            '@All': '',
        }
    }
};

export const defaultState: IRSRTFilterUIState = {
    filter: deepCopyFunction({ ...emptyFilter }),
    rrtProfileFilter: deepCopyFunction({ ...emptyRRTProfileFilter }),
    profileDisable: false,
    formTypeDisable: false,
    sentToStateDisable: false,
    notSentToStateDisable: false,
    profileName: { '@ID': '' },
    rsrtAlert: '',
    bLoading: true,
    focusField: '',
    isBusy: false,
    clearForms: 0,
    renderProfileHelper: 0,
    postAction: '',
    filterInitialized: false,
    process: '',

};

function getData(ob: any, field: string):string {
    if (!ob)
        return '';

    return ob[field] ?? '';

}

function isUnreleasedChecked(cs: string): boolean {
    var strArr = cs.split(',');
    return (strArr.indexOf('1') > -1 || strArr.indexOf('-1') > -1);
}
function isReleasedChecked(cs: string): boolean {
    var strArr = cs.split(',');
    return (strArr.indexOf('3') > -1 || strArr.indexOf('-3') > -1);
}
function isStateOnlyChecked(cs: string): boolean {
    var strArr = cs.split(',');
    return (strArr.indexOf('11') > -1 || strArr.indexOf('-11') > -1);
}
function isDeletedChecked(cs: string): boolean {
    return cs.indexOf('-') > -1;
}


function checkReleaseDatePopulated(state: any) {
    if ((getData(state?.filter.Filter?.DateField, "@ID") == DATE_RELEASED_ID) &&
        ((state?.activeTab == 3 &&
            getData(state?.filter.Filter?.DateField, "@From") === "01/00/0000") ||
            getData(state?.filter.Filter?.DateField, "@From") === "00/00/0000") &&
        getData(state?.filter.Filter?.DateField, "@To") === "00/00/0000") {
        return true;
    }
    return false;
} 

function getClaimStatus(state: any, e: any) {

    var Accepted, Warning, Rejected, All;

    if (!state.filter.Filter.ClaimStatus) {
        Accepted = Warning = Rejected = All = '';
    } else {
        Accepted = getData(state.filter.Filter.ClaimStatus, "@Accepted");
        Warning = getData(state.filter.Filter.ClaimStatus, "@Warning");
        Rejected = getData(state.filter.Filter.ClaimStatus, "@Rejected");
        All = getData(state.filter.Filter.ClaimStatus, "@All");
    }
    switch (e) {
        case 'Accepted':
            if (All === "true") {
                Accepted = '';
                Warning = 'W';
                Rejected = 'R';
                All = '';
            } else {
                if (Accepted === '') {
                    Accepted = 'A'
                } else Accepted = '';
            }
            break;
        case 'Warning':
            if (All === "true") {
                Accepted = 'A';
                Warning = '';
                Rejected = 'R';
                All = '';
            } else {
                if (Warning === '') {
                    Warning = 'W'
                } else Warning = '';
            }
            break;
        case 'Rejected':
            if (All === "true") {
                Accepted = 'A';
                Warning = 'W';
                Rejected = '';
                All = '';
            } else {
                if (Rejected === '') {
                    Rejected = 'R'
                } else Rejected = '';
            }
            break;
        case 'All':
                Accepted = 'A';
                Warning = 'W';
                Rejected = 'R';
            break;
    }

    if (Accepted === "A" && Warning === "W" && Rejected === "R") { //Nir -- todo when is unreported visible && Unreported === "U"
        Accepted = Warning = Rejected = "";
        All = "true"
    }

    return {
        '@Accepted': Accepted, '@Warning': Warning, '@Rejected': Rejected, '@All': All,
    }

}

function filterCopyFunction(inObject: any, outGoingObject: any): APIRSRT_FilterWrapper {
    let value: any, key;

    if (typeof inObject !== "object" || inObject === null) {
        return inObject // Return the value if inObject is not an object
    }

    for (key in inObject) {
        value = inObject[key]

        // Recursively (deep) copy for nested objects, including arrays
        outGoingObject[key] = deepCopyFunction(value)
    }
    return outGoingObject;
}

function FilterCopy(inObject: any, outGoingObject: APIRSRT_FilterWrapper, process: string) {

    if (typeof inObject !== "object" || inObject === null) {
        return inObject // Return the value if inObject is not an object
    }

    if (getData(inObject.Filter.RRTProfileID, '@ID') != '') {
        outGoingObject.Filter.RRTProfileID['@ID'] = getData(inObject.Filter.RRTProfileID, '@ID');
        return outGoingObject;
    }
    if (getData(inObject.Filter.SentToStateBatch, '@ID') != '') {
         
        outGoingObject.Filter.SentToStateBatch['@ID'] = getData(inObject.Filter.SentToStateBatch, '@ID');
        if (getData(inObject.Filter.SentToStateBatch, '@Name')) {
            outGoingObject.Filter.SentToStateBatch['@Name'] = getData(inObject.Filter.SentToStateBatch, '@Name');
        }

    }
    if (getData(inObject.Filter.AccountNumber, '@Value') != '') {
        outGoingObject.Filter.AccountNumber['@Value'] = getData(inObject.Filter.AccountNumber, '@Value');
    }
    if (getData(inObject.Filter.DateField, '@ID') != '' && getData(inObject.Filter.DateField, '@ID') != '0') {
        outGoingObject.Filter.DateField['@ID'] = getData(inObject.Filter.DateField, '@ID');
        outGoingObject.Filter.DateField['@To'] = getData(inObject.Filter.DateField, '@To');
        outGoingObject.Filter.DateField['@From'] = getData(inObject.Filter.DateField, '@From');
    }
    if (getData(inObject.Filter.UserField6, '@Value') != '') {
        outGoingObject.Filter.UserField6['@ID'] = getData(inObject.Filter.UserField6, '@ID');
        outGoingObject.Filter.UserField6['@Value'] = getData(inObject.Filter.UserField6, '@Value');
    }
    if (getData(inObject.Filter.UserField7, '@Value') != '') {
        outGoingObject.Filter.UserField7['@ID'] = getData(inObject.Filter.UserField7, '@ID');
        outGoingObject.Filter.UserField7['@Value'] = getData(inObject.Filter.UserField7, '@Value');
    }
    if (getData(inObject.Filter.UserField8, '@Value') != '') {
        outGoingObject.Filter.UserField8['@ID'] = getData(inObject.Filter.UserField8, '@ID');
        outGoingObject.Filter.UserField8['@Value'] = getData(inObject.Filter.UserField8, '@Value');
    }
    if (getData(inObject.Filter.FinancialClass, '@Value') != '') {
        outGoingObject.Filter.FinancialClass['@Value'] = getData(inObject.Filter.FinancialClass, '@Value');
    }
    
    if (inObject.Filter.FormTypes &&
        !Array.isArray(inObject.Filter.FormTypes.FormType)) {
        let arr = new Array<APIRSRT_FilterSimpleID>();
        arr.push(JSON.parse(JSON.stringify(inObject.Filter.FormTypes.FormType)));
        outGoingObject.Filter.FormTypes.FormType = arr;
    }

    if (getData(inObject.Filter.ClaimStatus, '@All') != '') {
        outGoingObject.Filter.ClaimStatus['@All'] = getData(inObject.Filter.ClaimStatus, '@All');
    } else {
        if (getData(inObject.Filter.ClaimStatus, '@Accepted') != '') {
            outGoingObject.Filter.ClaimStatus['@Accepted'] = getData(inObject.Filter.ClaimStatus, '@Accepted');
        }
        if (getData(inObject.Filter.ClaimStatus, '@Warning') != '') {
            outGoingObject.Filter.ClaimStatus['@Warning'] = getData(inObject.Filter.ClaimStatus, '@Warning');
        }
        if (getData(inObject.Filter.ClaimStatus, '@Rejected') != '') {
            outGoingObject.Filter.ClaimStatus['@Rejected'] = getData(inObject.Filter.ClaimStatus, '@Rejected');
        }
    }
    if (process != "delete/rrt" && process != "validate/rrt") {
        if (getData(inObject.Filter.ClaimState, '@ID') != '' &&
            (getData(inObject.Filter.ClaimState, '@ID') === '13' || getData(inObject.Filter.ClaimState, '@ID') === '15')) {
            outGoingObject.Filter.ClaimState['@ID'] = getData(inObject.Filter.ClaimState, '@ID');
        }
    }
    if (getData(inObject.Filter.SubmittedFile, '@ID') != '') {
        outGoingObject.Filter.SubmittedFile['@ID'] = getData(inObject.Filter.SubmittedFile, '@ID');
    }

    
    return outGoingObject;
}


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IRSRTFilterUIState, KnownActions> = (state: IRSRTFilterUIState | undefined, action: KnownActions) => {

    if (state != undefined) {

        switch (action.type) {

            case 'RSRT_LOAD_FILTER':
                // console.log('setting filter to ' + JSON.stringify(action.data.uiData.data));

                var testFilter: APIRSRT_FilterWrapper = emptyFilter;
                testFilter = action.data.uiData.data !== undefined ? FilterCopy(action.data.uiData.data, state.filter, state.process) : deepCopyFunction({ ...emptyFilter });  // copy of the object

                return {
                    ...state,
                    filter: testFilter,  //action.data.uiData.data !== undefined ? deepCopyFunction(action.data.uiData.data) : deepCopyFunction({ ...emptyFilter }),  // copy of the object
                    bloading: false,
                    dataRefreshed: true,
                    isBusy: false,
                    rsrtAlert: undefined,
                }
                break;

            case 'RSRT_SELECT_FORMTYPES':

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            FormTypes: {
                                FormType: action.data.uiData.selected,
                            }
                        }
                    }
                }
                break;

            case 'RSRT_UPDATE_PROFILE':

                var item = { '@ID': '0' };
                if (action.data.uiData.selected &&
                    parseInt(action.data.uiData.selected['@ID']) > 0) {
                    item = { '@ID': '' };
                }
                 
                return {
                    ...state,
                    renderProfileHelper: Date.now(),
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            RRTProfileID: action.data.uiData.selected,
                            FormTypes: { FormType: [item] },
                            }
                        }
                    }
              
            case 'RSRT_SELECT_SENTTOSTATE':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            SentToStateBatch:  action.data.uiData.selected,
                        }
                    },

                }
                break;

            case 'RSRT_SELECT_SUBMITTEDFILE':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            SubmittedFile: action.data.uiData.selected
                        }
                    }
                }
                break;

            case 'RSRT_SELECT_CLAIM_STATUS':
                // console.log(action.data.uiData.selected);
                var csData = getClaimStatus(state, action.data.uiData.data);
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ClaimStatus: csData
                        }
                    }
                }
                break;

            case 'RSRT_UPDATE_FCLASS':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            FinancialClass: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RSRT_UPDATE_USERFIELD6':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            UserField6: { '@ID': "25", '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RSRT_UPDATE_USERFIELD7':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            UserField7: { '@ID': "26", '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RSRT_UPDATE_USERFIELD8':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            UserField8: { '@ID': "27", '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'RSRT_SET_CLAIMSTATE':
                

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ClaimState: { '@ID': action.data.uiData.value }, 
                        }
                    }
                }
                break;

            case 'RSRT_SET_CLAIMALERT':

                return {
                    ...state,
                    rsrtAlert: action.data
                }
                break;

            case 'RSRT_UPDATE_ACCOUNTNUMBER':

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            AccountNumber: { '@Value': action.data.uiData.value },
                            
                        }
                    }
                }
                break;

            case 'RSRT_UPDATE_DATEFIELD':
                //       console.log('allowTime ' + action.data.uiData.value);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            DateField: action.data.uiData.data,
                        }
                    }
                }
                break;

            case 'RSRT_DISABLE_PROFILE':

                return {
                    ...state,
                    profileDisable: action.data,
                }
                break;

            case 'RSRT_DISABLE_FORMTYPES':

                let clearFormsKey = action.data ? Date.now() : 0;

                return {
                    ...state,
                    formTypeDisable: action.data,
                    clearForms: clearFormsKey,
                }
                break;

            case 'RSRT_DISABLE_SENTTOSTATE':

                return {
                    ...state,
                    sentToStateDisable: action.data
                }
                break;

            case 'RSRT_DISABLE_NOTSENTTOSTATE':

                return {
                    ...state,
                    sentToStateDisable: action.data
                }
                break;

            case 'RSRT_SET_FOCUSFIELD':
                return {
                    ...state,
                    focusField: action.data

                }
                break;
            case 'RSRT_SET_PROCESSNAME':
                return {
                    ...state,
                    process: action.data

                }
                break;

            case 'RSRT_SETBUSY':
                return {
                    ...state,
                    isBusy: action.data.uiData.value,
                }
                break;

            case 'RSRT_SET_POSTACTION':
                return {
                    ...state,
                    postAction: action.data
                }
                break;

            case 'RSRT_SET_FILTERINITIALIZED':
                return {
                    ...state,
                    postAction: action.data
                }
                break; 

           default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;

        }
    }

    return state || deepCopyFunction(defaultState);
}

