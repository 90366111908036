import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as PayerAliasUI from './ui/PayerAliasUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IPayerAliasState = ICrudComponentState<MCPayerAliasType, PayerAliasUI.IPayerAliasUIState>;

export const actionCreators = createCrudComponentActions<PayerAliasUI.ActionCreators>(PayerAliasUI.actionCreators);
export type IPayerAliasActions = typeof actionCreators;
export type IPayerAliasActionProps = ICrudComponentActionProps<IPayerAliasActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiPayerAlias, PayerAliasUI.reducer);
export const reducer = combineReducers<IPayerAliasState>(reducers);

export const validationCallback: ValidationCallback<MCPayerAliasType> = (crud: any): crud is MCPayerAliasType => {
    let testCrud: MCPayerAliasType = crud;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCPayerAliasType");
    if (validShape) {

        VerifyArray(testCrud?.PayerAliasMaintenanceInfo?.PayerList, "Payer");
        if (validShape && testCrud?.PayerAliasMaintenanceInfo?.PayerList?.Payer) {
            for (let i = 0; i < testCrud?.PayerAliasMaintenanceInfo?.PayerList?.Payer.length; i++) {
                VerifyArray(testCrud?.PayerAliasMaintenanceInfo?.PayerList?.Payer[i], "LOB");
            }
        } 

        //TO-DO: clean up later, software intervention due to bug -- data should be object, even if empty, and VerifyArray should handle that as well
        if (typeof (testCrud?.PayerAliasMaintenanceInfo?.Aliases) === "string") {
            testCrud.PayerAliasMaintenanceInfo.Aliases = {
                Alias: []
            };
        }
        if (typeof (testCrud?.PayerAliasMaintenanceInfo?.IDAliases) === "string") {
            testCrud.PayerAliasMaintenanceInfo.IDAliases = {
                Alias: []
            };
        }
        if (typeof (testCrud?.PayerAliasMaintenanceInfo?.CustomAliases) === "string") {
            testCrud.PayerAliasMaintenanceInfo.CustomAliases = {
                Alias: []
            };
        }

        VerifyArray(testCrud?.PayerAliasMaintenanceInfo?.Aliases, "Alias");
        VerifyArray(testCrud?.PayerAliasMaintenanceInfo?.IDAliases, "Alias");
        VerifyArray(testCrud?.PayerAliasMaintenanceInfo?.CustomAliases, "Alias");
        
        validShape = ValidateArray(testCrud?.PayerAliasMaintenanceInfo?.Aliases?.Alias, "MCPALIAS_Alias");
        if (validShape)
            validShape = ValidateArray(testCrud?.PayerAliasMaintenanceInfo?.IDAliases?.Alias, "MCPALIAS_Alias");
        if (validShape)
            validShape = ValidateArray(testCrud?.PayerAliasMaintenanceInfo?.CustomAliases?.Alias, "MCPALIAS_Alias");
        
    }
    return validShape;
}

export enum NetworkedClientsSyncEnum {
    SYS_FACILITY = 0, //&H00000001&
    SYS_PHYSICIAN = 1, //&H00000002&
    SYS_ALTSPELLINGS = 2, //&H00000004&
    SYS_BRIDGEMASTER = 3, //&H00000008&
    SYS_USER = 4, //&H00000010&
    SYS_GROUP = 5, //&H00000020&
    SYS_SECONDARYBILL = 6, //&H00000040&
    SYS_ERRORSEVERITY = 7, //&H00000080&
    SYS_CLAIMCHANGES = 8, //&H00000100&
    SYS_FILETYPES = 9, //&H00000200&
    SYS_UB92PHYSAE = 10, //&H00000400&
    SYS_PAYERADDRAE = 11, //&H00000800&
    SYS_PAPERCMDS = 12, //&H00001000&
    SYS_CLAIMOPTIONS = 13, //&H00002000&
    SYS_HOLDCODES = 14, //&H00004000&
    SYS_CSVOPTIONS = 15, //&H00008000&
    SYS_REMITIMPORT = 16, //&H00010000&
    SYS_NOTETYPES = 17, //&H00020000&
    SYS_FIELDLEVELSECURITY = 18, //&H00040000&
    SYS_FORMSUSED = 19, //&H00080000&
    SYS_ACCOUNTPOLICY = 20, //&H00100000&
    SYS_CLAIMFILTER = 21, //&H00200000&
    SYS_REMITFILTER = 22, //&H00400000&
    SYS_AUTOMODULES = 24, //&H01000000&
    SYS_AUTOPROCESS = 25, //&H02000000&
    SYS_SPINOFFMAP = 26, //&H04000000&
    SYS_SPLITCLAIMMAP = 27, //&H08000000&
    SYS_ELIGIBILITYPROFILE = 28, //&H10000000&
    SYS_CLAIMEVENTNOTIFY = 29, //&H20000000&
    SYS_PARALLONMANAGECROSSOVERS = 30, //&H40000000&
    SYS_PREBILL = 31, //&H80000000&

    OTHER_SYS_WORKFLOW = 32, //&H100000000&

    SYS_BRIDGEROUTINECOPY = 33, //&H200000000&
    SYS_DELETECLAIMFILTER = 34, //&H400000000&
    SYS_DELETEREMITFILTER = 35, //&H800000000&
    SYS_BRIDGEROUTINEDELETE = 36, //&H1000000000&
}
