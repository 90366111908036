import * as React from 'react';
import { Bar } from 'victory';
import { DigitalColors } from './Colors';

export const CustomBar = ({
    style,
    selectedBar,
    id,
    barRatio,
    x,
    y,
    datum,
    ...rest

}: any) => {
    return (
        <Bar
            {...rest}
            id={id}
            x={x}
            y={y}
            barRatio={selectedBar === id ? .60 : barRatio}
            style={{
                ...style,
                fill: (selectedBar === id) ? DigitalColors.digitalRed100 : datum.fill,
            }}
        />
    )
}