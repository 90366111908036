import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
// HIC - SSN + 2 digit BIC (Beneficary Identity Code)
// DCN - Document Contorl Number 
export interface ISearchClaimUIState {
    patientID: string;
    patientHIC: string;
    patientDCN: string;
    patientMedicalRecordNumber: string;
    patientLastName: string;
    patientFirstName: string;
    patientDateOfBirth: string;
    billType: string;
    reasonCode: string;
    receivedDateFrom: string;
    receivedDateThru: string;
    secondaryClaims: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISearchClaimData {
    patientID: string;
    patientHIC: string;
    patientDCN: string;
    patientMedicalRecordNumber: string;
    patientLastName: string;
    patientFirstName: string;
    patientDateOfBirth: string;
    billType: string;
    reasonCode: string;
    receivedDateFrom: string;
    receivedDateThru: string;
    secondaryClaims: string;
};

export interface ISearchClaim extends ICrudActionData<MCSearchClaim, ISearchClaimData> { }
export interface IModifySearchClaim extends ICrudActionData<MCSearchClaim, MCSearchClaim> { }

export const actionCreators = {
    listAllClaims: (listAllClaims: IModifySearchClaim) => createDataAction('LIST_ALL_CLAIMS', listAllClaims),
    searchStatus: (searchStatus: IModifySearchClaim) => createDataAction('SEARCH_STATUS_CLAIMS', searchStatus),
    searchAll: (searchAll: IModifySearchClaim) => createDataAction('SEARCH_ALL', searchAll),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: ISearchClaimUIState = {
    patientID: '',
    patientHIC: '',
    patientDCN: '',
    patientMedicalRecordNumber: '',
    patientLastName: '',
    patientFirstName: '',
    patientDateOfBirth: '',
    billType: '',
    reasonCode: '',
    receivedDateFrom: '',
    receivedDateThru: '',
    secondaryClaims: '',
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<ISearchClaimUIState, KnownActions> = (state: ISearchClaimUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'LIST_ALL_CLAIMS':
            case 'SEARCH_STATUS_CLAIMS':
            case 'SEARCH_ALL':
                if (action.data.masterCrud) {
                    var searchClaim: MCSearchClaim = action.data.uiData;
                    var newSearchClaimCriteria: ISearchClaimUIState = { ...state };

                    newSearchClaimCriteria.patientID = searchClaim['@PatientID'] || '';
                    newSearchClaimCriteria.patientHIC = searchClaim['@PatientHIC'] || '';
                    newSearchClaimCriteria.patientDCN = searchClaim['@PatientDCN'];
                    newSearchClaimCriteria.patientMedicalRecordNumber = searchClaim['@PatientMedicalRecordNumber'] || '';
                    newSearchClaimCriteria.patientLastName = searchClaim['@PatientLastName'] || '';
                    newSearchClaimCriteria.patientFirstName = searchClaim['@PatientFirstName'] || '';
                    newSearchClaimCriteria.patientDateOfBirth = searchClaim['@PatientDateOfBirth'] || '';
                    newSearchClaimCriteria.billType = searchClaim['@BillType'] || '';
                    newSearchClaimCriteria.reasonCode = searchClaim['@ReasonCode'];
                    newSearchClaimCriteria.receivedDateFrom = searchClaim['@ReceivedDateFrom'] || '';
                    newSearchClaimCriteria.receivedDateThru = searchClaim['@ReceivedDateThru'] || '';
                    newSearchClaimCriteria.secondaryClaims = searchClaim['@SecondaryClaims'] || '';

                    if (searchClaim['@PatientID'] === '') {
                        console.log('Please enter a Patient Control Number.')
                    }

                    if (searchClaim['@PatientHIC'] === '') {
                        console.log('Please enter a Patient HIC.')
                    }

                    if (searchClaim['@PatientDCN'] === '') {
                        console.log('Please enter a Patient DCN.')
                    }

                    if (searchClaim['@PatientMedicalRecordNumber'] === '') {
                        console.log('Please enter a Patient Medical Record Number.')
                    }

                    if (searchClaim['@PatientLastName'] === '') {
                        console.log('Please enter a Patient Last Name.')
                    }

                    if (searchClaim['@PatientFirstName'] === '') {
                        console.log('Please enter a Patient First Name.')
                    }

                    if (searchClaim['@PatientDateOfBirth'] === '') {
                        console.log('Please enter a Patient Date Of Birth.')
                    }

                    if (searchClaim['@BillType'] === '') {
                        console.log('Please enter a Type Of Bill.')
                    }

                    if (searchClaim['@ReasonCode'] === '') {
                        console.log('Please enter a Reason Code.')
                    }

                    if (searchClaim['@ReceivedDateFrom'] === '') {
                        console.log('Please enter a Received Date From.')
                    }

                    if (searchClaim['@ReceivedDateThru'] === '') {
                        console.log('Please enter a Received Date Thru.')
                    }

                    if (searchClaim['@SecondaryClaims'] === '') {
                        console.log('Please enter a Secondary Claims.')
                    }

                    var newState = {
                        ...state,
                        newSearchClaimCriteria
                    }

                    return newState;
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}