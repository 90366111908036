import { css } from 'styled-components';
import Colors from './colorVariables';

export const BoxShadows = {
  lightFade: css`
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .12), 0 1px 5px 0 rgba(0, 0, 0, .2);
  `,
  darkLargeFade: css`
    box-shadow: 0 15px 45px 15px rgba(0, 0, 0, .15);
  `,
  greyFadeInset: css`
    box-shadow: 0 0 0 2px ${Colors.faintSecondaryGrey} inset;
  `,
};

export default BoxShadows;
