//--------------------------------------------------------------------------------------------------------------
//   Function           : IsFieldFilled
//   Purpose            : This function will check to see if a form field is filled.
//   Parameter          : objFormField - This is the form field object you wish to validate to see if it is full.
//                        strFieldName - This is the friendly name of the field you are sending to give the user 
//                        an alert message if the field is not filled in.
//

import _ from "lodash";

//--------------------------------------------------------------------------------------------------------------
export function IsFieldFilled(field : string | undefined) : boolean {
    if (!field)
        return false;

    var nLength = field.length;
    var Char = "";

    if (nLength === 0) {
        return false;
    }

    for (var i = 0; i < nLength; i++) {
        Char = field.charAt(i);
        if (Char !== '' && Char !== '\t') {
            return true;
        }
    }

    return false;
}


//--------------------------------------------------------------------------------------------------------------
//   Function           : IsNumeric
//   Purpose            : This function will check to see if a string is composed of only integers.
//   Parameter          : strValue - This is the string you wish to validate.
//
//--------------------------------------------------------------------------------------------------------------
/*
export function IsNumeric(strValue: string | undefined) {
    return parseInt(strValue || '').toString() === (strValue || '');
}
*/

export function IsNumeric(strValue: string | undefined) {
    // regex to find chars that are *not* numbers 
    const regExpResults = new RegExp("[^0-9]").exec(strValue || '');
    if (regExpResults && regExpResults.length > 0) return false;
    return true;
}

//--------------------------------------------------------------------------------------------------------------
//   Function           : IsAlphaNumeric
//   Purpose            : This function will check to see if a string is composed of only valid characters and
//                        integers.
//   Parameter          : strValue - This is the string you wish to validate.
//
//--------------------------------------------------------------------------------------------------------------
export function IsAlphaNumeric(strValue: string | undefined) {
    if (!strValue)
        return true;

    var ValidCharactersArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ -.";
    var nLength = ValidCharactersArray.length;

    for (var i = 0; i < nLength; i++) {
        if (ValidCharactersArray.indexOf(strValue.charAt(i)) < 0) {
            return false;
        }
    }

    return true;
}

//--------------------------------------------------------------------------------------------------------------
//   Function           : IsUsernameValid
//   Purpose            : This function will check to see if a string is composed of only valid characters and
//                        for a user name.
//   Parameter          : strValue - This is the string you wish to validate.
//
//--------------------------------------------------------------------------------------------------------------
export function IsUsernameValid(strValue: string | undefined) {
    if (!strValue)
        return true;

    var ValidCharactersArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_.";
    var nLength = strValue.length;

    for (var i = 0; i < nLength; i++) {
        if (ValidCharactersArray.indexOf(strValue.charAt(i)) < 0) {
            return false;
        }
    }

    return true;
}

//--------------------------------------------------------------------------------------------------------------
//   Function           : IsPasswordValid
//   Purpose            : This function will check to make sure that the password adheres to the security
//                        policies.
//   Parameter          : strPassword - This is the value of the password.
//						  intLength - This is the minimum length.
//						  bolForceAlphaNum - This is to force alphanumeric.
//                        bolForceUpperLower -This is to force at least one upper and one lower case char.
//                        bolForceSpecialChar- This is to force at least one special character.
//
//--------------------------------------------------------------------------------------------------------------
export function IsPasswordValid(strPassword: string | undefined, intLength: number | undefined, bolForceAlphaNum: string | undefined,
    bolForceUpperLower: string | undefined, bolForceSpecialChar: string | undefined) {

    var validationErrors = ValidatePassword(strPassword, intLength, bolForceAlphaNum, bolForceUpperLower, bolForceSpecialChar);

    if (validationErrors.length > 0) {
        // Just alerting the first error, like previous implementation
        //alert(validationErrors[0]);
        return false;
    }

    return true;
}

//--------------------------------------------------------------------------------------------------------------
//   Function           : ValidatePassword
//   Purpose            : This function will check to make sure that the password adheres to the security
//                        policies.  Returns array of error messages if errors found.
//   Parameter          : strPassword - This is the value of the password.
//						  intLength - This is the minimum length.
//						  bolForceAlphaNum - This is to force alphanumeric.
//                        bolForceUpperLower -This is to force at least one upper and one lower case char.
//                        bolForceSpecialChar- This is to force at least one special character.
//
//--------------------------------------------------------------------------------------------------------------
export function ValidatePassword(strPassword: string | undefined, intLength: number | undefined,
    bolForceAlphaNum: string | undefined, bolForceUpperLower: string | undefined, bolForceSpecialChar: string | undefined) {

    let errors: string[] = [];

    if (!strPassword || !intLength || !bolForceAlphaNum || !bolForceUpperLower || !bolForceSpecialChar)
        return errors;
    
    // check to make sure new password meets length requirements
    if (strPassword.length < intLength || strPassword.length > 64) {
        errors.push("Your password must be between " + intLength + " and 64 characters in length.");
    }

    if (bolForceAlphaNum && bolForceAlphaNum === '1' && strPassword.search(/\d/) == -1) {
        errors.push("Your password must contain at least one number.");
    }
    // check to make sure new password meets upper/lower case requirements
    if (bolForceUpperLower && bolForceUpperLower === '1' && (strPassword.search(/[a-z]/) == -1 || strPassword.search(/[A-Z]/) == -1)) {
        errors.push("Your password must contain at least one uppercase and one lowercase letter.");
    }
    // check to make sure new password meets special char requirements
    if (bolForceSpecialChar && bolForceSpecialChar === '1' && strPassword.search(/[$\~\`\!@#\$%\^&\*\(\)_\+\-=\|<>\?]/) == -1) {
        errors.push("Your password must contain at least one special character(i.e. $,#,&,^...etc.).");
    }

    return errors;
}

//--------------------------------------------------------------------------------------------------------------
//   Function           : IsEMail
//   Purpose            : This function will check to see if an e-mail string is a valid e-mail address.  There
//                        must be a character before and after the @.  There also must be a character before and
//                        after the . in the e-mail string to be valid (ex. a@b.c).
//   Parameter          : strEMail - This is the string you wish to check to see if it is a valid e-mail address.
//
//--------------------------------------------------------------------------------------------------------------
export function IsEMail(strEMail: string | undefined) {

    if (!strEMail)
        return true;

    // There must be at least one character before the @, so we start looking at character position 1 
    let i = 1;
    let nLength = strEMail.length;

    // Look for the @
    while ((i < nLength) && (strEMail.charAt(i) != "@")) {
        i++;
    }

    if ((i >= nLength) || (strEMail.charAt(i) != "@")) {
        //alert("Please enter a valid e-mail address.");
        return false;
    } else {
        i += 2;
    }

    // Look for the .
    while ((i < nLength) && (strEMail.charAt(i) != ".")) {
        i++;
    }

    // There must be at least one character after the .
    if ((i >= nLength - 1) || (strEMail.charAt(i) != ".")) {
        //alert("Please enter a valid e-mail address.");
        return false;
    }

    // Look for " "
    for (let j = 0; j < nLength; j++) {
        if (strEMail.charAt(j) == " ") { // Make sure there are no spaces
            //alert("Please enter a valid e-mail address.  No spaces are allowed.");
            return false;
        }
    }

    return true;
}

//--------------------------------------------------------------------------------------------------------------
//   Function           : IsZipCode
//   Purpose            : This function will check to see if a zip code conforms to the correct format of either
//                        99999 or 999999999.
//   Parameter          : strZipCode - This is the string you wish to check to see if it is a valid zip code.
//
//--------------------------------------------------------------------------------------------------------------
export function IsZipCode(strZipCode: string) {

    if (!IsZipCode)
        return true;

    var ZipCode = new String(strZipCode);
    var nLength = ZipCode.length;
    let errors: string[] = [];

    for (var i = 0; i < nLength; i++) {
        if ((ZipCode.charAt(i) < '0' || ZipCode.charAt(i) > '9')) {
            //alert("This Zip Code must be numeric.  Please enter it again.");
            return false;
        }
    }

    if (nLength != 5 && nLength != 9) {
        // inappropriate length
        //alert("This Zip Code is not the correct length.  Please enter it in one of two formats: 99999 or 999999999");
        return false;
    }

    return errors;
}

//--------------------------------------------------------------------------------------------------------------
//   Function           : IsStringChars
//   Purpose            : This function will check to see if a string is composed of only valid characters.
//   Parameter          : strValue - This is the string you wish to validate.
//
//--------------------------------------------------------------------------------------------------------------
function IsAlpha(strValue: string): boolean {
    var ValidCharactersArray = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ -.";
    var nLength = strValue.length;

    for (var i = 0; i < nLength; i++) {
        if (ValidCharactersArray.indexOf(strValue.charAt(i)) < 0) {
            return false;
        }
    }
    return true;
}

export const deepCopyFunction = (inObject : any) => {
    let outObject : any, value, key;

    if (typeof inObject !== "object" || inObject === null) {
        return inObject // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {}

    for (key in inObject) {
        value = inObject[key]

        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = deepCopyFunction(value)
    }

    return outObject;
}


export function isMissingEndQuote(field: string) {
    var startsWithQuote = (/^"/).test(field);
    var endsWithQuote = (/"$/).test(field);

    if ((startsWithQuote && !endsWithQuote) || (!startsWithQuote && endsWithQuote)) {
        return ("Quotation marks must be used in pairs");
        //return true;
    }

    return '';
}

export function IsValidType(Field : string, Type : string) {
    switch (Type) {
        case "alpha":
            var RegEx = /\d/;

            if (RegEx.test(Field)) {
                return ("Only letters are allowed.");
                //return (false);
            }
   //         else {
   //             Field = Field.replace(/[^\w '\-@,"!:]/g, "");
   //         }
            break;
        case "numeric":
            var RegEx = /[a-zA-Z]/;

            if (RegEx.test(Field)) {
                return ("Only digits are allowed.");
                //return (false);
            }
    //        else {
    //            Field.value = Field.value.replace(/[^\d\.\-,!:]/g, "");
    //        }
            break;
        default: // note: ranges fall in to this as well, so no real validation is occuring for ranged fields...
  //          Field = Field.value.replace(/[^\w '\.\-/@,"!:#*]/g, "");
    }

    return ('');
}




export function dateAdd(date: any, type: string, amount: number) {
    try {
        var y = date.getFullYear(),
            m = date.getMonth(),
            d = date.getDate();
        if (type === 'y') {
            y += amount;
        };
        if (type === 'm') {
            m += amount;
        };
        if (type === 'd') {
            d += amount;
        };
        var xx = new Date(y, m, d);
        return getDateInMMDDYYYYFormat(xx);
    }
    catch (e) {
        console.error('Unable to properly format date, dateAdd');
    }
    return "00/00/0000";
}

export function getDateInMMDDYYYYFormat(input: any): string {
    try {
        var mm = input.getMonth() + 1;
        var dd = input.getDate();
        return (mm < 10 ? '0' + mm : mm) + '/' + (dd < 10 ? '0' + dd : dd) + '/' + input.getFullYear();
    }
    catch (e) {
        console.error('Unable to properly format date');
    }
    return "00/00/0000";
}

/**
 * String form field validations.
 */

export type Validation = {
    check: Function;
    failureMessage: string;
}

export type ControlValidation<FormFields> = {
    id: FormFields;
    displayName: string;
    validations: Validation[];
}

export const fieldIsFilled: Validation = {
    check: (value: string) => value?.trim().length || 0 > 0,
    failureMessage: " must not be blank",
}

export const dynamicFieldIsFilled: (failureMessage: string) => Validation = (failureMessage) => {
    return {
        check: fieldIsFilled.check,
        failureMessage,
    }
}

export const fieldIsAlphaNumeric: Validation = {
    check: IsAlphaNumeric,
    failureMessage: " must consist of alphanumeric characters."
}

export const fieldIsAlpha: Validation = {
    check: IsAlpha,
    failureMessage: " must consist of only letters."
}

export const fieldIsNumeric: Validation = {
    check: IsNumeric,
    failureMessage: " must consist of only numbers."
}

export const fieldIsNotValue: (checkValue: string, failureMessage: string) => Validation = (checkValue, failureMessage) => {
    return {
        check: (value: string) => value !== checkValue,
        failureMessage: failureMessage,
    }
}
