import * as React from 'react';
import styled from 'styled-components';
import { connect, useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '@store/index';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, IconTile } from 'ui-core';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { CommonGraph } from '@commonResources/commonGraphing/CommonGraph';
import { GraphType, Styles } from '@commonResources/commonGraphing/CommonGraph';

const data = [
    {
        x: 'Huygens, Christian',
        y: 200,
        tooltip:
        {
            name: "Huygens, Christian",
            totalClaims: "25 for $8,765.67",
            released: "21 for $4,967.34",
            unreleased: "4 for $89,345.75",
            held: "stuffffff"
        }
    },
    {
        x: 'Volta, Alessandro',
        y: 51,
        tooltip:
        {
            name: "Volta, Alessandro",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Hooke, Robert',
        y: 82,
        tooltip:
        {
            name: "Hooke, Robert",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Newton, Isaac',
        y: 300,
        tooltip:
        {
            name: "Newton, Isaac",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Bohr, Niels',
        y: 100,
        tooltip:
        {
            name: "Bohr, Niels",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Galilei, Galileo',
        y: 40,
        tooltip:
        {
            name: "Galilei, Galileo",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Maxwell, James Clerk',
        y: 6,
        tooltip:
        {
            name: "Maxwell, James Clerk",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Faraday, Michael',
        y: 32,
        tooltip:
        {
            name: "Faraday, Michael",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Curie, Marie',
        y: 374,
        tooltip:
        {
            name: "Curie, Marie",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Feynman, Richard',
        y: 200,
        tooltip:
        {
            name: "Feynman, Richard",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Planck, Max',
        y: 45,
        tooltip:
        {
            name: "Planck, Max",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Tesla, Nikola',
        y: 384,
        tooltip:
        {
            name: "Tesla, Nikola",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Pauli, Wolfgang',
        y: 2,
        tooltip:
        {
            name: "Pauli, Wolfgang",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Heisenberg, Werner',
        y: 300,
        tooltip:
        {
            name: "Heisenberg, Werner",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Dirac, Paul',
        y: 100,
        tooltip:
        {
            name: "Dirac, Paul",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Boltzman, Ludwig',
        y: 40,
        tooltip:
        {
            name: "Boltzman, Ludwig",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Blaise, Pascal',
        y: 65,
        tooltip:
        {
            name: "Blaise, Pascal",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Schrödinger, Erwin',
        y: 32,
        tooltip:
        {
            name: "Schrödinger, Erwin",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Hawkin, Stephen',
        y: 374,
        tooltip:
        {
            name: "Hawkin, Stephen",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
    {
        x: 'Rutherford, Ernest',
        y: 200,
        tooltip:
        {
            name: "Rutherford, Ernest",
            totalClaims: "34 for $128,765.67",
            released: "45 for $13,967.34",
            unreleased: "2 for $34,345.89"
        }
    },
];

export const ContentWrapper = styled.div`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin:15px 25px;
      padding-top: 20px;
      padding-bottom: 100px;
`;

export const SecondColumn = styled.div`
    display:flex;
    flex-direction: column; 
`

export const LefContainer = styled.div`
    margin: 5px 50px 5px 5px;
    padding-top: 20px; 
`

export const RightContainer = styled.div`
    height: auto;
    border: 10px solid;
    border-color: ${Colors.middlestGrey};
    justify-content: center; 
`

export const GraphBox = styled.div`
    width: 500px;
    height: auto;
    display:flex;
    justify-content: flex-end;
`

const Name = styled("div")`
    padding-bottom: 0.2em; 
    text-transform: uppercase; 
    font-size: 11px;
`;

const Data = styled("div")`
    font-size: 9px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Label = styled("div")`
    font-weight: bold;
    color: ${Colors.blue30}
`;

const Value = styled("div")`
    color: white;
`;


const ErrorModalMgsWrapper = styled("div")`
    display: flex;
        justify-content: center;
`

interface IComponentProps {
    canView: boolean;
    canEdit: boolean;
    pageWidth: number;
    pageHeight: number;
};

interface IComponentState {
   
};


export const DEFAULT_STATE: IComponentState = {
    
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;


type IDashboarClaimDetailsProps =  IOwnProps;


export class DashboardClaimDetails extends React.Component<IDashboarClaimDetailsProps, IComponentState> {
    
 

    render() {

        return (
            <ContentWrapper>
                    <LefContainer>
                    <GraphBox>
                        <CommonGraph
                            title={ "Default Title"}
                            subtitle={"Default Subtitle"}
                            label={ "Default Y-Axis"}
                            xLabel={"Bottom label"}
                            data={data}
                            lineData={[{ x: "", y: 0 }]}
                            displayLine={true}
                            lineColor={"green"}
                            colorScheme={"random50"}
                            graphType={'bar'}
                            tooltipData={{
                                tooltipWidth: 200,
                                tooltipOffsetX: 20,
                                tooltipOffsetY: 30,
                                getContent: (tooltipData: any) => {
                                    
                                    return (
                                        <>
                                            <Name>Name</Name>
                                            <Data>
                                                <Label>Total Claims: </Label><Value>totalClaims</Value>
                                            </Data>
                                            <Data>
                                                <Label>Released: </Label><Value>released</Value>
                                            </Data>
                                            <Data>
                                                <Label>Unreleased: </Label><Value>unreleased</Value>
                                            </Data>
                                        </>
                                    )
                                }
                            }}
                        />
                    </GraphBox>
                    </LefContainer>
                <SecondColumn>
                    <RightContainer>
                        <GraphBox>
                            <CommonGraph
                                title={"Default Title"}
                                subtitle={"Default Subtitle"}
                                label={"Default Y-Axis"}
                                xLabel={"Bottom label"}
                                data={data}
                                lineData={[{ x: "", y: 0 }]}
                                displayLine={true}
                                lineColor={"green"}
                                colorScheme={"random50"}
                                graphType={'bar'}
                                tooltipData={{
                                    tooltipWidth: 200,
                                    tooltipOffsetX: 20,
                                    tooltipOffsetY: 30,
                                    getContent: (tooltipData: any) => {

                                        return (
                                            <>
                                                <Name>Name</Name>
                                                <Data>
                                                    <Label>Total Claims: </Label><Value>totalClaims</Value>
                                                </Data>
                                                <Data>
                                                    <Label>Released: </Label><Value>released</Value>
                                                </Data>
                                                <Data>
                                                    <Label>Unreleased: </Label><Value>unreleased</Value>
                                                </Data>
                                            </>
                                        )
                                    }
                                }}
                            />
                        </GraphBox>
                    </RightContainer>
                    <RightContainer>
                        <GraphBox>
                            <CommonGraph
                                title={"Default Title"}
                                subtitle={"Default Subtitle"}
                                label={"Default Y-Axis"}
                                xLabel={"Bottom label"}
                                data={data}
                                lineData={[{ x: "", y: 0 }]}
                                displayLine={true}
                                lineColor={"green"}
                                colorScheme={"random50"}
                                graphType={'bar'}
                                tooltipData={{
                                    tooltipWidth: 200,
                                    tooltipOffsetX: 20,
                                    tooltipOffsetY: 30,
                                    getContent: (tooltipData: any) => {

                                        return (
                                            <>
                                                <Name>Name</Name>
                                                <Data>
                                                    <Label>Total Claims: </Label><Value>totalClaims</Value>
                                                </Data>
                                                <Data>
                                                    <Label>Released: </Label><Value>released</Value>
                                                </Data>
                                                <Data>
                                                    <Label>Unreleased: </Label><Value>unreleased</Value>
                                                </Data>
                                            </>
                                        )
                                    }
                                }}
                            />
                        </GraphBox>
                     </RightContainer>
                </SecondColumn>
            </ContentWrapper> 
            )
    }

}

var connectedHoc = connect()(DashboardClaimDetails);

export default withRouter(connectedHoc);