import * as React from 'react';
//import { useState, useEffect, useRef, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Buttons } from '@common/component-styles';
import { ICookies_Config } from '@store/ConfigData';
import { Button } from 'ui-core';
import * as BasicComponents from '@common/FunctionComponents';
import { DialogWrapper } from '@common/DialogWrapper';
import { IModalConfirmationProps, ModalConfirmation } from '@common/ModalConfirmation';
import { CrudTypes } from '@commonResources/CrudTypes';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { URLs } from '@commonDevResources/constants';
import { DialogFieldset } from '@common/DialogStyles';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMGridPager } from '../../common/UICLWrappers/ARMGridPager';
import { ARMHeaderCell, IARMHeaderCellProps } from '@common/UICLWrappers/ARMHeaderCell';
import { pageLeave } from '@commonResources/userModified';

import { HttpStatus } from '@commonResources/ApiClient';
import ApiClient from '@commonResources/ApiClient';



import { IPaperClaimListState, IPaperClaimListActionProps, actionCreators, validationCallback } from '@store/PaperClaimList';
import { SortHelper, ISortIndicator, SortDataType } from '../../../scripts/util/SortHelper';
import { ModalLoading } from '../../common/ModalLoading';
import FileViewer from '../../common/FileViewer';



const _defaultFilter = "All Paper Claims";


const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    .Created {
        vertical-align: inherit;
        width:150px;
    }

    .Name, .Creator, .Size {
        vertical-align: inherit;
        width:380px;
    }

    .Creator {
        vertical-align: inherit;
        width:115px;
    }

    .Size {
        vertical-align: inherit;
        width:70px;
    }
    .selectDropdown {
        vertical-align: inherit;
    }

    .link {
        ${Typography.defaultLineHeight};
        ${Typography.medium};
        color: ${Colors.brightBlue130};
        padding: 0px;
        text-decoration: underline;
        &: hover {
            text-decoration-color: ${Colors.babyBlue};
            {cursor: pointer;}
        }
    }

    table#gridPaperClaims tbody tr td:last-child { /* this is to work around a UICL Grid bug. */
        background-color: crimson; /* if this is displayed, something did not work */
        display: none;
    }
`;

interface IComponentProps { //confirmed from legacy
    canView: boolean;   // SecurityBits.FN_VIEW_REPORTS
    canDelete: boolean; // SecurityBits.FN_DELETE_PAPER_CLAIMS
    canDownload: boolean; // SecurityBits.FN_VIEW_PAPER_CLAIMS

    //actionOptions: Array<BasicComponents.Option>;
};

interface IComponentState {
    isPageBusy: boolean;
    //columnsSchema: Set<any>;
    selectedFilter: { index: number; value: string; };
    paperClaimList: IPaperClaimList;
    filteredList: IPaperClaimList; //subset of paperClaimList
    pageDisplayList: IPaperClaimList; //subset of filteredList, limited by grid paging
    selectedItemsCount: number;
    filteredListDiskSpace: number;
    sortIndicator: ISortIndicator;
    selectedAction: { index: number; value: string; };
    gridPaging: IGridPaging;
    gridScrollToTop: boolean;

    modalProps: IModalConfirmationProps;
    isButtonContinueDisabled: boolean;
};

export const DEFAULT_STATE: IComponentState = {
    isPageBusy: true,
    //columnsSchema: new Set(),
    selectedFilter: { index: 0, value: _defaultFilter },
    paperClaimList: {
        PaperClaims: []
    },
    filteredList: { //subset of paperClaimList
        PaperClaims: []
    },
    pageDisplayList: { //subset of filteredList
        PaperClaims: []
    },
    selectedItemsCount: 0,
    filteredListDiskSpace: 0,
    //ASRN-3498 CHANGES :  Chrome / Edge View Paper claims is showing 2022 before 2023
    sortIndicator: {
        sortColumn: 'Created',
        sortDirection: 'up',       
        //sortDataType: SortDataType.String,        
        sortDataType: SortDataType.Date,      
    },
    selectedAction: { index: 0, value: 'Delete' },
    gridPaging: {
        currentPageNumber: 1,
        numberOfPages: 1,
        recordsPerPage: 100, //per requirement, set to 100
    },
    gridScrollToTop: false,

    modalProps: {
        isOpen: false,
        showDefaultClose: true,
        onModalToggle: (e: React.MouseEvent<HTMLElement>) => {
            return false;
        },
        message: '',
        formattedMessage: undefined,
        okText: "OK",
        cancelText: "Cancel"

    },
    isButtonContinueDisabled: true,
};


type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IPaperClaimListProps = IMergeCrudComponentProps<IPaperClaimListState, IPaperClaimListActionProps, IOwnProps>;


const Page = {
    Selectors: {
        idButtonContinue: "btnContinue",
        idButtonCancel: "btnCancel",
        idGrid: "gridPaperClaims",
    }
};
Object.freeze(Page);

export class PaperClaimList extends React.Component<IPaperClaimListProps, IComponentState> {

    readonly textContinue: string = "Continue";
    readonly textCancel: string = "Cancel";

    readonly pageTitle: string = "View Paper Claims";

    private columnsSchemaSet: Set<any> = new Set();
    //private formTypesUsed: MCFormsUseds = {
    //    Form: []
    //};
    private filterOptions: Array<BasicComponents.Option> = [
        {
            text: _defaultFilter,
            value: ''
        }
    ]; //subset of formTypesUsed, with initial object added at index 0

    private actionOptions: Array<BasicComponents.Option> = [
        { text: "Delete", value: "Delete" },
        { text: "Download", value: "Download" },
    ];

    private clientId: string;

    static defaultProps: IComponentProps = {
        canView: true,   // SecurityBits.FN_VIEW_REPORTS
        canDelete: true, // SecurityBits.FN_DELETE_REPORTS
        canDownload: true, // SecurityBits.FN_DELETE_REPORTS
    };

    constructor(props: IPaperClaimListProps) {
        super(props);

        this.state = DEFAULT_STATE;
        this.clientId = sessionStorage.getItem("Client")?.split(' ')[0] as string; //because TypeScript
    }

    //shouldComponentUpdate(nextProps: any, nextState: any) {
    //    //if (this.state.filterOptions.length == nextState.filterOptions.length) {
    //    //    return false;
    //    //}
    //    //debugger;
    //    return true;
    //}

    public componentDidMount() {
        pageLeave();

        this.getPaperClaimFormsUsedAsync()
            .then((formTypesUsed) => {
                this.filterOptions = this.getPaperClaimFilterOptions(formTypesUsed);
                //if (this.props.canView) {
                this.refreshGridData();
                //}
            });
        this.actionOptions[this.state.selectedAction.index].selected = true;
    }

    public componentWillUnmount() {
        pageLeave();

        //this.props.action.ui.resetFormsUsed(
        //    {
        //        masterCrud: this.props.dataStore.crud.data,
        //        uiData: {
        //            index: 0,
        //            value: false,
        //        }
        //    });

        //resetCrudComponentState(this.props.action, this.props.dataStore);
    }


    //custom functions below here

    applyRulesForUI = (records: IPaperClaimList) => {
        //TO-DO: refactor inline style, see onCheckboxClick handler
        const inlineStyle = {
            marginLeft: "25px",
        };

        let count = records.PaperClaims.length;
        for (let i = 0; i < count; i++) {
            records.PaperClaims[i]["Action"] = (
                <span style={inlineStyle}>{
                    BasicComponents.Checkbox({
                        className: "table-row-checkbox"
                    }, this.onCheckboxClick)
                }</span>);
        }
    }

    private deletePaperClaims = async (paperClaims: Array<IPaperClaim>): Promise<any> => {
        const endpoint = `${ApiClient.apiBaseURL}/data/claims/deletePaperClaims`;

        return ApiClient.jsonDeleteAsync(endpoint, JSON.stringify(paperClaims))
            .then((response: any) => {
                if (response.status === HttpStatus.OK) {
                    //debugConsole.log('response.status: ', response.status); //<<< REMOVE WHEN DONE DEBUGGING!!!
                }
                else {
                    //debugConsole.log('response if not OK', response.status); //<<< REMOVE WHEN DONE DEBUGGING!!!
                }
            })
            .catch((error) => {
                //debugConsole.log('error: ', error); //<<< REMOVE WHEN DONE DEBUGGING!!!
            });
    }

    downloadPaperClaims = async (paperClaims: Array<IPaperClaim>): Promise<any> => {
        const endpoint = `${ApiClient.apiBaseURL}/data/claims/downloadPaperClaims`;

        return ApiClient.jsonPostAsync(endpoint, JSON.stringify(paperClaims))
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    let rawData = await response.text();
                    if (rawData && rawData.length > 0) {
                        //TO-DO: fix API return value so we don't have to manipulate it here
                        let uri = rawData;
                        if (uri.startsWith('"')) {
                            uri = uri.substring(1);
                        }
                        if (uri.endsWith('"')) {
                            uri = uri.substring(0, uri.length - 1);
                        }
                        let segments = uri.split("\\");
                        let fileName = segments[segments.length - 1]

                        ApiClient.downloadZIP(fileName, uri);
                    }
                }
                else {
                    //debugConsole.log('not good: ', response.status); //<<< REMOVE WHEN DONE DEBUGGING!!!
                }
            })
            .catch((error) => {
                //debugConsole.log('error: ', error); //<<< REMOVE WHEN DONE DEBUGGING!!!
            });
    }

    //getColumnsSchemaSet = (): Set<IARMHeaderCellProps> => { //this code is not yet ready to be implemented because too many required fields on IARMHeaderCellProps
    getColumnsSchemaSet = () => {
        //debugConsole.log('getting columns schema...', null); //<<< REMOVE WHEN DONE DEBUGGING!!!
        //cannot use abridged object declaration because of required fields in the interface, e.g
        //let foo = new Set<IARMHeaderCellProps>([
        //    {
        //        dataName: "Created",
        //        text: 'Created',
        //        cellType: "text",
        //        sortable: true,
        //        isSorted: false,
        //    },
        //]);
        //  ... must use object declaration with all (expanded) props because of required fields in the interface, e.g
        //let bar = new Set<IARMHeaderCellProps>([
        //    {
        //        dataName: "Created",
        //        text: 'Created',
        //        cellType: "text",
        //        sortable: true,
        //        isSorted: false,
        //        select: {},
        //        sortIndicator: {
        //            sortColumn: "",
        //            sortDirection: ""
        //        },
        //        sortHandler: {}
        //    },
        //]);

        //let columnsSchemaSet = new Set<IARMHeaderCellProps>([ //cannot use this code (a typed Set) with abridged declaration until some properties/attributes become optional
        let columnsSchemaSet = new Set([
            {
                dataName: "Created",
                text: 'Created',
                cellType: "text",
                sortable: true,
                sortHandler: this.sortRecords,
                //isSorted: 0,
                sortIndicator: this.state.sortIndicator,
                sortDataType: SortDataType.Date,
            },
            {
                dataName: "Name",
                text: "Name",
                cellType: "custom",
                sortable: false,
                //isSorted: 0,
                //sortIndicator: this.state.sortIndicator,
            },
            {
                dataName: "CreatedBy",
                text: "Creator",
                cellType: "text",
                sortable: true,
                sortHandler: this.sortRecords,
                //isSorted: 0,
                sortIndicator: this.state.sortIndicator,
                sortDataType: SortDataType.String,
            },
            {
                dataName: "Size",
                text: "Size",
                cellType: "text",
                sortable: true,
                sortHandler: this.sortRecords,
                //isSorted: 0,
                sortIndicator: this.state.sortIndicator,
                sortDataType: SortDataType.Number,
            },
            {
                dataName: "DisplayType",
                text: _defaultFilter,
                cellType: "text",
                sortable: false,
                //isSorted: 0,
                //sortIndicator: this.state.sortIndicator,
                select: this.createFragmentForPaperClaimFilter()
            },
            {
                dataName: "Action",
                text: "Delete",
                cellType: "custom",
                sortable: false,
                //isSorted: 0,
                //sortIndicator: this.state.sortIndicator,
                select: this.createFragmentForPaperClaimAction()
            }
        ]);

        return (columnsSchemaSet);
    }

    async getGridData(): Promise<any> {
        const endpoint = `${ApiClient.apiBaseURL}/data/claims/viewPaperClaims`;

        let records: IPaperClaimList;

        return await ApiClient.jsonGetAsync(endpoint)
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    let rawData = await response.text();
                    if (rawData && rawData.length > 0) {
                        //map raw data to json interface
                        let tentativePaperClaims: Array<any> = JSON.parse(rawData);
                        let tentativeItem: any;
                        //
                        let fileNameSegments: string[];
                        let createdBy: string;
                        let claimType: string;
                        let dateSegment: string;
                        let formattedDate: string;
                        let timeSegment: string;
                        let formattedTime: string;
                        let displayFileName: string;
                        let displayType: string;
                        let filter: string = '';
                        let mappedPaperClaims: Array<IPaperClaim> = [];
                        for (var i = 0, count = tentativePaperClaims.length; i < count; i++) {
                            tentativeItem = tentativePaperClaims[i];
                            //from legacy...
                            fileNameSegments = tentativeItem["FileName"].split('_');
                            if (fileNameSegments.length < 4) {
                                continue;
                            }
                            //
                            createdBy = ApiClient.urlDecode(fileNameSegments[0].replace('-', '%'));
                            //
                            claimType = fileNameSegments[1];
                            //
                            //claim type
                            displayType = fileNameSegments[1].toUpperCase();
                            if (displayType === "UB-92" || displayType === "UB-04") {
                                displayType = "UB"
                            }
                            else if (displayType.indexOf("CMS-1500") >= 0 || displayType.indexOf("HCFA-1500") >= 0) {
                                displayType = "1500"
                            }
                            //date
                            dateSegment = fileNameSegments[2];
                            formattedDate = `${dateSegment.substring(0, 2)}/${dateSegment.substring(2, 4)}/${dateSegment.substring(4)}`;
                            //time
                            timeSegment = fileNameSegments[3];
                            formattedTime = `${timeSegment.substring(0, 2)}:${timeSegment.substring(2, 4)}`;
                            //
                            displayFileName = fileNameSegments[4].split('.')[0];
                            if (fileNameSegments.length > 5) {
                                filter = fileNameSegments[5].split('.')[0];
                                displayFileName = `${displayFileName} - Filter: ${ApiClient.urlDecode(filter.replace(/-/g, '%'))}`;
                            }
                            //
                            //let paperClaim = JSON.parse(JSON.stringify(tentativeItem));
                            let uri = tentativeItem["FilePath"];
                            uri = uri.endsWith('\\') ?
                                uri + tentativeItem["FileName"] :
                                uri + '\\' + tentativeItem["FileName"];
                            //let link: any = <span className="link" onClick={() => ApiClient.openPDF(uri)}>{displayFileName}</span>;
                            let link: any = <FileViewer key={i} name={displayFileName} fileLink={'/shared/Open%3D\/' + uri} />

                            mappedPaperClaims.push({
                                'Created': `${formattedDate} ${formattedTime}`,
                                'FileName': tentativeItem["FileName"],
                                'FilePath': tentativeItem["FilePath"],
                                'Name': link,
                                'CreatedBy': `${createdBy}`,
                                'Size': `${Math.round(tentativeItem["FileSize"] / 1024)} KB`,
                                'ClaimType': `${claimType}`,
                                'DisplayType': `${displayType}`,
                                'Filter': filter,
                                //'@Action': JSX.Element,
                            });
                        }

                        records = {
                            PaperClaims: mappedPaperClaims
                        };
                    }
                }
                else {
                    records = {
                        PaperClaims: Array<IPaperClaim>()
                    };
                }
                return records;
            })
            .catch((error) => {
                records = {
                    PaperClaims: Array<IPaperClaim>()
                };
            });
    }

    getPaperClaimFormsUsedAsync = async (): Promise<MCFormsUseds> => {
        const endpoint = `${ApiClient.apiBaseURL}/crud/${CrudTypes.mctiFormsUsed}`;

        let records: MCFormsUseds;
        return ApiClient.jsonGetAsync(endpoint)
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    let rawData = await response.text();
                    if (rawData && rawData.length > 0) {
                        let formsUsedType: MCFormsUsedType = JSON.parse(rawData);
                        records = formsUsedType.FormsUsedMaintenanceInfo.Forms;
                    }
                    else {
                        //debugConsole.log(`raw data from response is empty?`, null); //<<< REMOVE WHEN DONE DEBUGGING!!!
                    }
                }
                else {
                    records = {
                        Form: Array<MCFormsUsed>()
                    };
                }
                return records;
            })
            .catch((error) => {
                return records = {
                    Form: Array<MCFormsUsed>()
                };
            });
    }

    getPaperClaimFilterOptions = (formTypesUsed: MCFormsUseds): Array<BasicComponents.Option> => {
        let options: Array<BasicComponents.Option> = [];
        let filterOptions = this.filterOptions;
        if (!filterOptions || filterOptions.length < 2) { //if not in State (state only has default option), then get from service call...
            let filteredFormTypesUsed: Array<MCFormsUsed> = formTypesUsed.Form.filter(f => f["@Used"] === 'Y');

            options = filteredFormTypesUsed.map((f: MCFormsUsed) => ({
                text: f["@Name"],
                value: f["@Name"]
            }));
            //insert into first index
            options.splice(0, 0, { text: _defaultFilter, value: "" });
            filterOptions = Array.from(new Set(options)); //remove any accidental dups
        }

        return filterOptions;
    }

    public getSelectedItems(): Array<IPaperClaim> {
        let selectedItems: Array<IPaperClaim> = this.state.filteredList.PaperClaims.filter(pc => pc.Selected);

        return selectedItems;
    }


    redirectToHome = () => {
        let defaultState = DEFAULT_STATE;
        this.setState(
            {
                ...defaultState,
                isPageBusy: false,
                selectedAction: { index: 0, value: 'Delete' },
            },
            () => {
                this.props.history.push("/LandingPage");
                //window.location.href = '/LandingPage';
            }
        );
    }

    refreshFilteredListDiskSpace(filteredList: Array<IPaperClaim>) {
        let sum: number = 0;
        for (var i = 0, length = filteredList.length; i < length; i++) {
            sum = sum + parseFloat(filteredList[i].Size);
        }
        return sum;
    }

    async refreshGridData() {
        this.setState({ isPageBusy: true });

        this.getGridData()
            .then((paperClaimList) => {
                let records = paperClaimList;

                let selectedValue = this.filterOptions.find(o => o.selected)?.value;
                if (records.PaperClaims.length > 0 && selectedValue && selectedValue !== _defaultFilter) {
                    let filteredRecords = records.PaperClaims.filter((r: IPaperClaim) => r["DisplayType"] === selectedValue);
                    let diskSpace = this.refreshFilteredListDiskSpace(filteredRecords);
                    this.applyRulesForUI({ PaperClaims: filteredRecords });

                    this.setState({
                        isPageBusy: false,
                        paperClaimList: records,
                        filteredList: {
                            PaperClaims: filteredRecords
                        },
                        selectedItemsCount: 0,
                        filteredListDiskSpace: diskSpace,
                        sortIndicator: this.state.sortIndicator,
                    });

                }
                else {
                    let diskSpace = this.refreshFilteredListDiskSpace(records.PaperClaims);
                    this.applyRulesForUI(records);

                    this.setState({
                        isPageBusy: false,
                        paperClaimList: records,
                        filteredList: records,
                        selectedItemsCount: 0,
                        filteredListDiskSpace: diskSpace,
                        sortIndicator: this.state.sortIndicator,
                    });
                }
            })
            .then(() => {
                this.sortRecords(this.state.sortIndicator);
            })
            .then(() => {
                //this.handlePaging(this.state.gridPaging.currentPageNumber);
            });
    }

    //... and event handlers below here
    handlePaging = (pageNumber: number) => {
        const {
            filteredList,
            gridPaging
        } = this.state;

        // get the requested page of records
        let indexStart = (pageNumber - 1) * gridPaging.recordsPerPage;
        let indexEnd = indexStart + gridPaging.recordsPerPage;
        let pageRecords = filteredList.PaperClaims.slice(indexStart, indexEnd);

        let numberOfPages = Math.ceil(filteredList.PaperClaims.length / gridPaging.recordsPerPage)

        this.setState({
            // (your grid records prop should be bound to filteredRecords)
            pageDisplayList: {
                PaperClaims: pageRecords
            },
            gridPaging: {
                currentPageNumber: pageNumber,
                numberOfPages: numberOfPages,
                recordsPerPage: gridPaging.recordsPerPage,
            },
            gridScrollToTop: false
        });
    }

    onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        //TO-DO: make sure components' props are cleared, in WillUnmount function
        this.redirectToHome();
    }

    onCheckboxClick = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        //TO-DO: refactor, see applyRulesForUI function
        const inlineStyle = {
            marginLeft: "25px",
        };

        let paging = this.state.gridPaging;
        let row = e.currentTarget.closest('tr') as HTMLTableRowElement;
        let arrayIndex = ((paging.currentPageNumber - 1) * paging.recordsPerPage) + (row.rowIndex - 1); //offset for zero-based array index, and account for current "page"
        let rootTable = e.currentTarget.closest('tbody') as HTMLElement;
        let checkboxes = rootTable.querySelectorAll('input[type="checkbox"]:checked') as NodeListOf<HTMLInputElement>;
        let buttonContinue = document.getElementById(Page.Selectors.idButtonContinue) as HTMLButtonElement;
        buttonContinue.disabled = (checkboxes.length < 1);

        //explicitly update child component... (see https://www.freecodecamp.org/news/updating-state-from-child-component-onclick/)
        let paperClaims = [...this.state.filteredList.PaperClaims];
        let paperClaim = paperClaims[arrayIndex];
        paperClaim.Selected = e.currentTarget.checked;
        let props = e.currentTarget.checked ? { checked: "checked" } : {};
        paperClaim.Action = (<span style={inlineStyle}>
            {BasicComponents.Checkbox(props, this.onCheckboxClick)}
        </span>);

        //  ... then save state
        this.setState({
            selectedItemsCount: this.getSelectedItems().length,
            filteredList: { PaperClaims: paperClaims },
            filteredListDiskSpace: this.state.filteredListDiskSpace,
            isButtonContinueDisabled: buttonContinue.disabled,
            gridScrollToTop: false,
        });
    }

    onContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (this.state.selectedAction.value === 'Delete') {
            const promptForDeletion = <>Are you sure you want to delete the selected file(s)?<br />This action cannot be undone.</>;

            //TO-DO: refactor
            let modalProps: IModalConfirmationProps = this.state.modalProps;
            modalProps.isOpen = true;
            modalProps.formattedMessage = promptForDeletion;
            modalProps.onConfirm = (this.onModalConfirmDelete.bind(this));
            modalProps.okText = 'Continue';
            modalProps.cancelText = 'Cancel';
            //
            this.setState({
                modalProps: modalProps
            });
        }
        else { //download file(s)
            let selectedItems = this.getSelectedItems();
            this.downloadPaperClaims(selectedItems)
                .then(() => {
                    //debugConsole.log('successfully downloaded file(s)', null); //<<< REMOVE WHEN DONE DEBUGGING!!!
                    this.redirectToHome();
                });
        }

    }

    onModalConfirmDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (this.state.selectedAction.value === 'Delete') {
            //TO-DO: refactor
            let selectedItems: Array<IPaperClaim> = this.getSelectedItems();

            this.deletePaperClaims(selectedItems)
                .then((response: any) => {
                    //TO-DO: check response?
                    this.refreshGridData();
                });
        }
    }

    onSelectActionChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedIndex = e.target.options.selectedIndex;
        let selectedValue = e.target.value;

        //TO-DO: refactor
        let actionOptions = this.actionOptions;
        let currentIndex = actionOptions.findIndex(o => o.selected === true);
        if (currentIndex >= 0) {
            actionOptions[currentIndex] = {
                text: actionOptions[currentIndex].text,
                value: actionOptions[currentIndex].value
            };
        }
        actionOptions[selectedIndex].selected = true;

        this.setState({
            selectedAction: { index: selectedIndex, value: selectedValue },
            gridScrollToTop: false,
        });
    }

    onSelectFilterChanged = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedIndex = e.target.options.selectedIndex;
        let selectedValue = e.target.value;

        //TO-DO: refactor
        //this.props.action.ui.FilterPaperClaims();
        let filterOptions = this.filterOptions;
        let currentIndex = filterOptions.findIndex(o => o.selected === true);
        if (currentIndex >= 0) {
            filterOptions[currentIndex] = {
                text: filterOptions[currentIndex].text,
                value: filterOptions[currentIndex].value
            };
        }
        filterOptions[selectedIndex].selected = true;

        this.setState({
            selectedFilter: { index: selectedIndex, value: selectedValue },
            gridScrollToTop: false,
        });

        this.refreshGridData();
    }

    sortRecords = (sortIndicator: ISortIndicator) => {
        let sortedRecords = SortHelper.Sort<IPaperClaim>((this.state.filteredList.PaperClaims), sortIndicator)

        this.setState({
            sortIndicator,
            filteredList: {
                PaperClaims: sortedRecords
            },
            gridScrollToTop: true,
        });

        this.handlePaging(1);
    }



    createFragmentForPaperClaimFilter = () => {
        let options: Array<BasicComponents.Option> = this.filterOptions;
        return BasicComponents.Select({ id: "filter" }, options, this.onSelectFilterChanged);
    }

    //item action options
    createFragmentForPaperClaimAction = () => {
        let options: Array<BasicComponents.Option> = this.actionOptions;
        options[this.state.selectedAction.index].selected = true;
        return BasicComponents.Select({ id: "action" }, options, this.onSelectActionChanged);
    }

    //grid headers
    //createFragmentForGridHeaders = (columnsComponent: Array<IARMHeaderCellProps>) => { //this code is not yet ready to be implemented because too many required fields on IARMHeaderCellProps
    createFragmentForGridHeaders = (columnsComponent: any) => {
        return (
            <thead>
                <tr>{
                    (Array.from(columnsComponent.columns)).map((c: any, index: number) => (
                        <ARMHeaderCell
                            key={ `header_${index}` }
                            dataName={c.dataName}
                            text={c.text}
                            cellType={c.cellType}
                            sortable={c.sortable}
                            isSorted={c.isSorted}
                            sortIndicator={c.sortIndicator}
                            sortHandler={c.sortHandler}
                            sortDataType={c.sortDataType}
                            select={c.select} />
                    ))
                }</tr>
            </thead>
        );
    }

    createFragmentForButtons = (options: { disableContinue?: Boolean; disableCancel?: Boolean; }) => {
        return (
            <React.Fragment>
                <Button
                    domID={Page.Selectors.idButtonContinue}
                    name={this.textContinue}
                    disabled={this.state.isButtonContinueDisabled}
                    {...Buttons.Props.Default}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.onContinue(e)}
                />
                <Button
                    domID={Page.Selectors.idButtonCancel}
                    name={this.textCancel}
                    disabled={options.disableCancel || false}
                    {...Buttons.Props.Default}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.onCancel(e)}
                />
            </React.Fragment>
        );
    }

    createFragmentForModal = () => {

        let self = this;
        function justCloseModal(e: React.MouseEvent<HTMLButtonElement | HTMLElement>) {
            let modalProps: IModalConfirmationProps = self.state.modalProps;
            modalProps.isOpen = false;
            self.setState({
                modalProps: modalProps
            });
        }

        return (
            <ModalConfirmation
                isOpen={this.state.modalProps.isOpen}
                showDefaultClose={true}
                formattedMessage={
                    <p>{this.state.modalProps.formattedMessage || this.state.modalProps.message}</p>
                }
                onModalToggle={(e: React.MouseEvent<HTMLElement>) => justCloseModal(e)}
                onConfirm={this.state.modalProps.onConfirm}
                onDeny={(e: React.MouseEvent<HTMLButtonElement>) => justCloseModal(e)}
                alertMode={this.state.modalProps.alertMode || false}
            />
        );
    }

    public render() {

        let instruction = `Click on the column heads to sort the paper claim list.`;
        let helpUrl = `/Support/Help/HELP_ViewPaper.htm`;
        let modalFragment = this.createFragmentForModal();
        let buttonsFragment = this.createFragmentForButtons({});
        this.columnsSchemaSet = this.getColumnsSchemaSet();
        //this.setState({ columnsSchema: columnsSchemaSet });
        let gridStyle = `height='100%'; maxHeight='500px'`;

        //TO-DO: fix footer component or create grid footer component; current component causes React warnings/errors
        let footerMessage: any = '0 files(s) available taking 0 KB';
        if (this.state.filteredList) {
            let diskSpace: number = this.state.filteredListDiskSpace;
            let fileCount: number = this.state.filteredList.PaperClaims.length;
            footerMessage = <span>{fileCount} files(s) available taking {diskSpace} KB &mdash; {this.state.selectedItemsCount} item(s) selected</span>;
        }

        return (
            <DialogWrapper title={this.pageTitle} instruction={instruction} helpUrl={helpUrl} buttons={buttonsFragment}>
                <ContentWrapper>
                    <DialogFieldset>
                        <ARMGrid
                            domID={Page.Selectors.idGrid}
                            onClickThrough={function noRefCheck() { }}
                            isFixedHeader={true}
                            columns={this.columnsSchemaSet}
                            headerComponent={this.createFragmentForGridHeaders}
                            records={this.state.pageDisplayList.PaperClaims}
                            selectionKey={gridStyle}
                            style={gridStyle}
                            scrollToTop={this.state.gridScrollToTop}
                        />
                        <ARMGridPager
                            message={footerMessage}
                            currentPageNumber={this.state.gridPaging.currentPageNumber}
                            numberOfPages={this.state.gridPaging.numberOfPages}
                            pagingHandler={this.handlePaging}
                        />
                    </DialogFieldset>
                </ContentWrapper>
                {modalFragment}
                <ModalLoading isOpen={this.state.isPageBusy} />
            </DialogWrapper>
        );
    }
};

let connectedHoc = connect<IPaperClaimListState, IPaperClaimListActionProps, IOwnProps, IPaperClaimListProps, ApplicationState>(
    createCrudMapStateToProps('paperClaimList'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(PaperClaimList);

export default withRouter(connectedHoc);
