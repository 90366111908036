export function handleChange(): string {
    sessionStorage.setItem('userModified', 'true')
    window.addEventListener("beforeunload", fnleave)
    return "";
}
export function pageLeave(): string {
    sessionStorage.setItem('userModified', 'false')
    sessionStorage.setItem('fileClicked', '')
    window.removeEventListener("beforeunload", fnleave)
    return "";
}
 export function fnleave(e: any) {
    e.preventDefault()
    e.returnValue = ''
}


