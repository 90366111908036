import * as React from "react";
import {DialogWrapper, OKCancelButtons} from '@common/DialogWrapper';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import {CrudTypes} from '@commonResources/CrudTypes';
import {connect} from 'react-redux';
import {ApplicationState} from '@store/index';
import {
    actionCreators,
    IFacilityMasterActionProps,
    IFacilityMasterState,
    validationCallback
} from '@store/FacilityMaster';
import {
    createCrudMapDispatchToProps,
    createCrudMapStateToProps,
    IMergeCrudComponentProps,
    mergeCrudComponentProps, resetCrudComponentState
} from '../../../scripts/util/CrudComponentHelpers';
import {DialogFieldset, DialogLegend} from "../../common/DialogStyles";
import {SelectComponent} from "../../common/SelectComponent";
import {FacilityInformation} from './FacilityInformation/FacilityInformation';
import {PayerInformation} from './PayerInformation/PayerInformation';
import {CrudPermissions} from "./CrudPermissions";
import {DefaultFacilityGenerator} from "./DefaultFacilityGenerator";
import {FacilityManager} from "./FacilityManager";
import _ from "lodash";
import {pageLeave, handleChange} from '@commonResources/userModified';
import {
    Facility,
    List,
    LOBElement,
    MCFacilityMaintenanceInfo,
    MCFacilityMasterTypeDelta
} from "../../../store/api/FacilityMasterType";
import {ICookies_Config} from "../../../store/ConfigData";
import {ModalConfirmation} from "@common/ModalConfirmation";
import { URLs } from "../../../commonResources/constants";
import { getRawToken } from "../../../scripts/session/SecurityToken";
import AssuranceMenu from "@common/AssuranceMenu";

interface IComponentState {
    originalFacilityList: Facility[],
    previousSelectedFacilityOriginal: Facility,
    defaultFacility: Facility,
    originalFacility: Facility,
    selectedFacilityId: string,
    previousSelectedFacilityId: string,
    selectedFacility: Facility,
    selectedPayer: LOBElement,
    facilityListboxData: List[] | undefined,
    maintenanceInfo: MCFacilityMaintenanceInfo | undefined,
    isBusy: boolean,
    validationAggregation: Map<string, boolean>,
    pageIsValid: boolean,
    fieldFocus: string,
    isFacilityInfoSubmit: boolean,
    isFacilityPayerInfoSubmit: boolean,
    payerInfoSubmitCount: number,
    //isFacilityPayerInfoSubmitSequence: boolean,
    cursorPos: number,
    tenetThirdPartyPrintLookup: string
    refreshPayerInfoList: boolean,
    removedFacilityPayerInfoIds: string[],
    deltaCrud: MCFacilityMasterTypeDelta,
    onCopyDupFacSubId: boolean,
    recordCount: number 
    showDeleteFacilityConfirmation: boolean,
    showDeletePayerConfirmation: boolean,
    deleteConfirmationMsg: string,
    hasChanges: boolean,
    cancelLeave: boolean,
    payerIdToDelete: string,
    selectedPayerInfoId: string
}

export const DEFAULT_STATE: IComponentState = {
    originalFacilityList: [DefaultFacilityGenerator.generateDefaultFacility()],
    selectedFacilityId: '0',
    previousSelectedFacilityId: '0',
    previousSelectedFacilityOriginal: DefaultFacilityGenerator.generateDefaultFacility(),
    defaultFacility: DefaultFacilityGenerator.generateDefaultFacility(),
    originalFacility: DefaultFacilityGenerator.generateDefaultFacility(),
    selectedFacility: DefaultFacilityGenerator.generateDefaultFacility(),
    selectedPayer: DefaultFacilityGenerator.generateDefaultPayerInfo(),
    facilityListboxData: undefined,
    maintenanceInfo: undefined,
    isBusy: false,
    validationAggregation: new Map<string, boolean>(),
    pageIsValid: true,
    fieldFocus: '',
    isFacilityInfoSubmit: false,
    isFacilityPayerInfoSubmit: false,
    payerInfoSubmitCount: 0,
    cursorPos: 0,
    tenetThirdPartyPrintLookup: '0',
    refreshPayerInfoList: false,
    removedFacilityPayerInfoIds: [],
    deltaCrud: DefaultFacilityGenerator.generateDefaultDelta(),
    onCopyDupFacSubId: false,
    recordCount: 1,
    showDeleteFacilityConfirmation: false,
    showDeletePayerConfirmation: false,
    deleteConfirmationMsg: '',
    hasChanges: false,
    cancelLeave: false,
    payerIdToDelete: '0',
    selectedPayerInfoId: '0'
};

interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    canCreate: boolean,
    canDelete: boolean,
    canSecCheck1: boolean,
    canSecCheck2: boolean,
    canSecCheck3: boolean,
    title: string,
    ncsIsEnabled: boolean,
    ncsSkipPrompt: boolean,
    ncsPromptText: string,
    ncsListType: string,
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IFacilityMasterProps = IMergeCrudComponentProps<IFacilityMasterState, IFacilityMasterActionProps, IOwnProps>;

export class FacilityMaster extends React.Component<IFacilityMasterProps, IComponentState> {
    selectFacility: SelectComponent | null | undefined;
    facilityInformation: React.RefObject<FacilityInformation> | undefined;

    constructor(props: IFacilityMasterProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.getTenetThirdPartyPrintLookup();

        this.addFacilityMaster = this.addFacilityMaster.bind(this);
        this.updateFacilityMaster = this.updateFacilityMaster.bind(this);
        this.updateSelectedFacility = this.updateSelectedFacility.bind(this);
        this.updateSelectedFacilityPayerInfo = this.updateSelectedFacilityPayerInfo.bind(this);
        this.validationAggregationRoutine = this.validationAggregationRoutine.bind(this);
        this.onClickAddFacilityPayerInfo = this.onClickAddFacilityPayerInfo.bind(this);
        this.onClickUpdateFacilityPayerInfo = this.onClickUpdateFacilityPayerInfo.bind(this);
        this.onClickRemoveFacilityPayerInfo = this.onClickRemoveFacilityPayerInfo.bind(this);
        this.undoFacilityPayerInfoSubmitState = this.undoFacilityPayerInfoSubmitState.bind(this);
        this.resetValidators = this.resetValidators.bind(this);

        this.copyFacilityMaster = this.copyFacilityMaster.bind(this);
        this.removeFacilityMasterPrompt = this.removeFacilityMasterPrompt.bind(this);
        this.removePayerPrompt = this.removePayerPrompt.bind(this);
        this.removeFacilityMaster = this.removeFacilityMaster.bind(this);
        this.updateSelectedPayerInfoId = this.updateSelectedPayerInfoId.bind(this);
        this.facilityInformation = React.createRef();
    }

    onOkClick(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            history,
        } = this.props;
        
        const {
            deltaCrud
        } = this.state;

        //console.log(deltaCrud);
        //this.setState({updating: true });
        
        this.props.action.crud.update({
            crudId: CrudTypes.mctiFacility, 
            data: new FacilityManager().prepareDeltaUpdate(deltaCrud)
        });
        
        history.push('/LandingPage');
    }

    resetValidators() {
        this.setState({
            isFacilityPayerInfoSubmit: false
        })
    }

    addFacilityMaster() {
        const {
            selectedFacility
        } = this.state;

        this.trimOtherFacilityFields(selectedFacility, 'Add');
        this.setState({
            isFacilityInfoSubmit: true,
            fieldFocus: 'Add',
            selectedPayerInfoId: '0'
        },
            this.addFacilityMasterCallback
        )
    }

    addFacilityMasterCallback() {
        const {
            validationAggregation,
            selectedFacility,
            deltaCrud,
            maintenanceInfo,
            facilityListboxData,
            recordCount,
            hasChanges
        } = this.state;

        // any false validation indicators will return false for pageIsValid
        const pageIsValid = Array.from(validationAggregation.values()).includes(false) ? false : true;
        const facilityToAdd = _.cloneDeep(selectedFacility);
        const updatedListboxData = _.cloneDeep(facilityListboxData);
        // update page entity if validation was successful
        if (pageIsValid) {
            new FacilityManager().addFacility(selectedFacility);
            if(facilityToAdd["@ID"] === '0') {
                if(maintenanceInfo && maintenanceInfo.Facilities && maintenanceInfo.Facilities.Facility)
                    facilityToAdd["@ID"] = `#${recordCount.toString()}`;
            }
            maintenanceInfo?.Facilities.Facility.push(facilityToAdd);

            if(maintenanceInfo) // reset initial state to default after added
                maintenanceInfo.Facilities.Facility[0] = DefaultFacilityGenerator.generateDefaultFacility();

            updatedListboxData?.push({
                "@ID": `#${recordCount.toString()}`,
                "@Name": facilityToAdd["@Description"].toUpperCase() + ' - ' + facilityToAdd["@FacilitySubId"]
            });
        }
        
        this.setState({
            pageIsValid,
            deltaCrud: pageIsValid ? new FacilityManager().upsertFacilityDelta(deltaCrud, facilityToAdd) : deltaCrud,
            maintenanceInfo: maintenanceInfo,
            // reset any changes to the facility list
            //selectedFacility: _.cloneDeep(facilityToAdd),
            originalFacilityList: maintenanceInfo ? _.cloneDeep(maintenanceInfo.Facilities.Facility) : this.state.originalFacilityList,
            facilityListboxData: updatedListboxData,
            recordCount: pageIsValid ? recordCount + 1 : recordCount,
            hasChanges: pageIsValid ? true : hasChanges
        }, () => {
            if(pageIsValid)
                this.onSelectFacilityPostUpdate(facilityToAdd["@ID"]);
        });
    }

    copyFacilityMaster() {
        const {
            selectedFacility
        } = this.state;

        this.trimOtherFacilityFields(selectedFacility, 'Copy');
        this.setState({
            isFacilityInfoSubmit: true,
            fieldFocus: 'Copy',
            selectedPayerInfoId: '0',
            isFacilityPayerInfoSubmit: false
        },
            this.copyFacilityMasterCallback
        )
    }

    copyFacilityMasterCallback() {
        const {
            validationAggregation,
            selectedFacility,
            deltaCrud,
            maintenanceInfo,
            facilityListboxData,
            originalFacilityList,
            recordCount,
            hasChanges
        } = this.state;
        
        // any false validation indicators will return false for pageIsValid
        const facilityToAdd = _.cloneDeep(selectedFacility);
        const updatedListboxData = _.cloneDeep(facilityListboxData);
        let dupSubId : boolean = false;
        let iterator: number = recordCount;
        // check if the previous list prior to copy has the same facSubId somewhere
        // exclude empty, it will get validated
        if(originalFacilityList && selectedFacility["@FacilitySubId"]){
            if(originalFacilityList.some(fac => fac["@FacilitySubId"] === facilityToAdd["@FacilitySubId"])) {
                this.validationAggregationRoutine('txtFacilitySubID.Unique', false);
                dupSubId = true;
            }
        }
        // update page entity if validation was successful
        const pageIsValid = Array.from(validationAggregation.values()).includes(false) ? false : true;
        if (pageIsValid) {
            new FacilityManager().addFacility(selectedFacility);
            if(maintenanceInfo && maintenanceInfo.Facilities && maintenanceInfo.Facilities.Facility) {
                facilityToAdd["@ID"] = `#${iterator.toString()}`;
                iterator = iterator + 1;
            }
                
            updatedListboxData?.push({
                "@ID": facilityToAdd["@ID"],
                "@Name": facilityToAdd["@Description"].toUpperCase() + ' - ' + facilityToAdd["@FacilitySubId"]
            })
            // use the old list and add the new entry from the copy action
            if(maintenanceInfo) {
                // do not copy over lob's from the facility you copied from
                facilityToAdd.LOB = [];
                
                maintenanceInfo.Facilities.Facility = originalFacilityList;
                maintenanceInfo.Facilities.Facility.push(facilityToAdd);
            }
            
        }
        
        this.setState({
            pageIsValid,
            deltaCrud: pageIsValid ? new FacilityManager().upsertFacilityDelta(deltaCrud, facilityToAdd) : deltaCrud,
            maintenanceInfo: maintenanceInfo,
            // reset any changes to the facility list
            originalFacilityList: (maintenanceInfo && pageIsValid) ? _.cloneDeep(maintenanceInfo.Facilities.Facility) : this.state.originalFacilityList,
            facilityListboxData: updatedListboxData,
            selectedFacility: _.cloneDeep(facilityToAdd),
            originalFacility: (maintenanceInfo && pageIsValid) ? _.cloneDeep(facilityToAdd) : this.state.originalFacility,
            validationAggregation: validationAggregation,
            onCopyDupFacSubId: dupSubId,
            recordCount: pageIsValid ? iterator : recordCount,
            hasChanges: pageIsValid ? true : hasChanges
        }, () => {
            if(pageIsValid)
                this.onSelectFacilityPostUpdate(facilityToAdd["@ID"]);
        });
    }
    

    updateFacilityMaster() {
        const {
            
            selectedFacility
        } = this.state;

        this.trimOtherFacilityFields(selectedFacility, 'Update');
        this.setState({
            isFacilityInfoSubmit: true,
            fieldFocus: 'Update'
        },
            this.updateFacilityMasterCallback
        )
    }
    
    removeFacilityMasterPrompt(){
        const{
            selectedFacility,
        } = this.state;
        const displayName = selectedFacility["@Description"].toUpperCase() + ' - ' + selectedFacility["@FacilitySubId"];
        const msg = `Do you want to remove ${displayName}? This action cannot be undone.`;
        
        this.setState({
             showDeleteFacilityConfirmation: true,
             deleteConfirmationMsg: msg
        });
    }

    removePayerPrompt(){
        const{
            selectedPayer,
            removedFacilityPayerInfoIds,
            selectedFacility,
            maintenanceInfo
        } = this.state;
        
        let msg : string = '';
        if(selectedFacility){
            if (selectedFacility.LOB && Array.isArray(selectedFacility.LOB)){
                let lobName : string = '';
                let payerName: string = '';
                const payerInfo = selectedFacility.LOB.find(lob => lob["@ID"] === removedFacilityPayerInfoIds[0]);
                if(payerInfo){
                    if(maintenanceInfo && maintenanceInfo.LOBList.LOB && Array.isArray(maintenanceInfo.LOBList.LOB)){
                        const lobDetail = maintenanceInfo.LOBList.LOB.find(l => l["@ID"] === payerInfo["@LOBID"] );
                        lobName = lobDetail ? lobDetail["@Name"] : '';
                    }

                    if(maintenanceInfo && maintenanceInfo.PayerList.Payer && Array.isArray(maintenanceInfo.PayerList.Payer)){
                        const payerDetail = maintenanceInfo.PayerList.Payer.find(l => l["@ID"] === payerInfo["@PayerID"] );
                        payerName = payerDetail ? payerDetail["@Name"] : '';
                    }
                    
                    const displayName = lobName + ' - ' + payerName + ' - ' + payerInfo["@ProviderNo"];
                    msg = `Do you want to remove ${displayName}? This action cannot be undone.`;                    
                }
                    
            }
        }

        this.setState({
            showDeletePayerConfirmation: true,
            deleteConfirmationMsg: msg
        });
    }

    removeFacilityMaster() {
        const {
            selectedFacility,
            deltaCrud,
            maintenanceInfo,
            originalFacilityList,
            facilityListboxData
        } = this.state;
        
        if(maintenanceInfo){
            maintenanceInfo.Facilities.Facility = _.cloneDeep(maintenanceInfo.Facilities.Facility).filter(fac => fac["@ID"] !== selectedFacility["@ID"]);
        }
        let newListBoxData : List[] = [];
        if(facilityListboxData){
            newListBoxData = _.cloneDeep(facilityListboxData);
            newListBoxData = newListBoxData.filter(x => x["@ID"] !== selectedFacility["@ID"]);
        }
        
        this.setState(
            {
                maintenanceInfo: maintenanceInfo,
                originalFacilityList: _.cloneDeep(originalFacilityList).filter(oFac => oFac["@ID"] !== selectedFacility["@ID"]),
                facilityListboxData: newListBoxData,
                selectedFacility: DefaultFacilityGenerator.generateDefaultFacility(),
                selectedPayer: DefaultFacilityGenerator.generateDefaultPayerInfo(),
                originalFacility: DefaultFacilityGenerator.generateDefaultFacility(),
                previousSelectedFacilityId: '0',
                deltaCrud : new FacilityManager().deleteFacilityDelta(deltaCrud, selectedFacility),
                hasChanges: true,
                selectedPayerInfoId: '0'
            },  
            () => { //reset validation back to default state
               this.onSelectFacilityPostUpdate('0');
            }
        )
    }
    
    removePayer(){
        const {
            maintenanceInfo,
            originalFacilityList,
            deltaCrud,
            selectedFacility,
            payerIdToDelete,
            selectedPayer,
            originalFacility,
            selectedPayerInfoId
        } = this.state;
        let facilityPayerId : string = '';
        let newSelectedPayer : LOBElement = DefaultFacilityGenerator.generateDefaultPayerInfo();        
        if(maintenanceInfo) {
            const facilityToDelete = maintenanceInfo.Facilities.Facility.find(fac => fac["@ID"] === selectedFacility["@ID"] );
            if(facilityToDelete && facilityToDelete.LOB){
                if(Array.isArray(facilityToDelete.LOB))
                    facilityToDelete.LOB = facilityToDelete.LOB.slice().filter(payer => payer["@ID"] !== payerIdToDelete);
                facilityPayerId = facilityToDelete["@ID"];
            }
        }

        if(originalFacilityList) {
            const origFacilityToDelete = originalFacilityList.find(fac => fac["@ID"] === selectedFacility["@ID"] );
            if(origFacilityToDelete && origFacilityToDelete.LOB){
                if(Array.isArray(origFacilityToDelete.LOB))
                    origFacilityToDelete.LOB = origFacilityToDelete.LOB.slice().filter(payer => payer["@ID"] !== payerIdToDelete);
                else{
                    // not in an array, single element from load
                    origFacilityToDelete.LOB = [];
                }
            }
        }
        let newSelectedPayerId = '0';
        if(originalFacility){
            if(originalFacility && originalFacility.LOB){
                // only record left that's an object
                if(!Array.isArray(originalFacility.LOB))
                    originalFacility.LOB = [originalFacility.LOB]
                
                // this is the element to remove's original spot in the array
                const originalIdx = originalFacility.LOB.findIndex(lob => lob["@ID"] === selectedPayerInfoId);
                //remove the element  reset the index
                originalFacility.LOB = originalFacility.LOB.slice().filter(payer => payer["@ID"] !== payerIdToDelete);
                
                if((originalFacility.LOB as LOBElement[]).length != 0) {
                    if (originalIdx === (originalFacility.LOB as LOBElement[]).length) {
                        // item is the last in the list, default to item right above it
                        newSelectedPayer = _.cloneDeep((originalFacility.LOB as LOBElement[])[originalIdx - 1]);
                        newSelectedPayerId = newSelectedPayer["@ID"];
                    } else {
                        // default the item below the one just removed to be selected
                        newSelectedPayer = _.cloneDeep((originalFacility.LOB as LOBElement[])[originalIdx]);
                        newSelectedPayerId = newSelectedPayer["@ID"];
                    }
                }
            }
        }
        if(selectedFacility){
            if(selectedFacility && selectedFacility.LOB){
                // only record left that's an object
                if(!Array.isArray(selectedFacility.LOB))
                    selectedFacility.LOB = [selectedFacility.LOB]
                //remove the element
                selectedFacility.LOB = selectedFacility.LOB.slice().filter(payer => payer["@ID"] !== payerIdToDelete);
            }
        }
        
        
        this.setState({
            maintenanceInfo: maintenanceInfo,
            originalFacilityList: originalFacilityList,
            deltaCrud: new FacilityManager().deletePayerDelta(deltaCrud, payerIdToDelete, facilityPayerId),
            removedFacilityPayerInfoIds: [payerIdToDelete],
            selectedFacility: _.cloneDeep(selectedFacility),
            originalFacility: _.cloneDeep(originalFacility),
            selectedPayerInfoId: newSelectedPayerId,
            selectedPayer: newSelectedPayer,
            hasChanges: true,
            // reset submit state
            isFacilityPayerInfoSubmit: false,
            refreshPayerInfoList: true
        })
    }

    updateFacilityMasterCallback() {
        const {
            validationAggregation,
            selectedFacility,
            deltaCrud,
            maintenanceInfo,
            originalFacilityList,
            facilityListboxData,
            recordCount,
            hasChanges
        } = this.state;

        // any false validation indicators will return false for pageIsValid
        const pageIsValid = Array.from(validationAggregation.values()).includes(false) ? false : true;
        
        // update page entity if validation was successful
        if (pageIsValid) {
            new FacilityManager().updateFacility(this.state.selectedFacility);
            if(maintenanceInfo && maintenanceInfo.Facilities.Facility){
                const idx = maintenanceInfo.Facilities.Facility.findIndex(x => x["@ID"] === selectedFacility["@ID"]);
                if(idx > -1){
                    maintenanceInfo.Facilities.Facility[idx] = _.cloneDeep(selectedFacility)
                }
            }
            // update the original facility
            if(originalFacilityList){
                const origIdx = originalFacilityList.findIndex(x => x["@ID"] === selectedFacility["@ID"]);
                if(origIdx > -1){
                    // copy back over the original payers, not part of this update
                    const origPayerList = originalFacilityList[origIdx].LOB;
                    originalFacilityList[origIdx] = _.cloneDeep(selectedFacility);
                    originalFacilityList[origIdx].LOB = origPayerList;
                }
            }
            
            // update the select list's display name
            if(facilityListboxData){
                const listboxIdx = facilityListboxData.findIndex(x => x["@ID"] === selectedFacility["@ID"]);
                facilityListboxData[listboxIdx]["@Name"] = selectedFacility["@Description"].toUpperCase() + ' - ' + selectedFacility["@FacilitySubId"]
            }
        }

        this.setState({
            pageIsValid,
            maintenanceInfo: maintenanceInfo,
            originalFacilityList: originalFacilityList,
            facilityListboxData: facilityListboxData,
            deltaCrud: pageIsValid ? new FacilityManager().upsertFacilityDelta(deltaCrud, selectedFacility) : deltaCrud,
            hasChanges: pageIsValid ? true : hasChanges
        });
    }

    updateSelectedFacility(property: string, value: string, fieldFocus: string, cursorPos: number) {
        const {
            selectedFacility,
        } = this.state;

        //debugger;

        let newFacility = selectedFacility;
        newFacility[property] = value;

        this.trimOtherFacilityFields(newFacility, property);

        this.setState({
            selectedFacility: newFacility,
            onCopyDupFacSubId: false,
            fieldFocus,
            cursorPos,
        });
    }

    updateSelectedFacilityPayerInfo(
        selectedFacilityPayerInfoId: string, property: string, value: string, fieldFocus: string, cursorPos: number) {
        const {
            selectedFacility,
            isFacilityPayerInfoSubmit,
        } = this.state;

        // need to reset submit indicator on first user key update after payer info is valid
        if (isFacilityPayerInfoSubmit && this.getPayerInfoIsValid()) {
            this.setState({
                isFacilityPayerInfoSubmit: false
            }, () => {
                // continue with primary update logic
                this.updateSelectedFacilityPayerInfoCallback(
                    selectedFacilityPayerInfoId, property, value, fieldFocus, cursorPos)
            })
        } else {
            this.updateSelectedFacilityPayerInfoCallback(
                selectedFacilityPayerInfoId, property, value, fieldFocus, cursorPos)
        }
    }

    updateSelectedFacilityPayerInfoCallback(
        selectedFacilityPayerInfoId: string, property: string, value: string, fieldFocus: string, cursorPos: number ) {
        const {
            selectedFacility,
            isFacilityPayerInfoSubmit,
        } = this.state;

        let payerInfoIsValid = this.getPayerInfoIsValid();
        let isFacilityPayerInfoSubmitNew = isFacilityPayerInfoSubmit;

        // if a user updates facility info input and there are no errors in component
        if (payerInfoIsValid) {
            // ensure that isSubmit indicator gets reset
            // this is to hide edit auto-update reflection in payer info list
            isFacilityPayerInfoSubmitNew = false;
        }

        let lob: LOBElement;

        // if triggered via default 'add new' list item
        if (selectedFacilityPayerInfoId == '0') {

            // clone the selected facility
            let newSelectedFacility = _.cloneDeep(selectedFacility);

            if (newSelectedFacility.LOB && !Array.isArray(newSelectedFacility.LOB)) {
                newSelectedFacility.LOB = [newSelectedFacility.LOB]
            }

            // validate that the LOB property is an array
            if (newSelectedFacility.LOB && Array.isArray(newSelectedFacility.LOB)) {

                let lob: LOBElement;

                // add a new lob if one is not already associated
                const newLob = newSelectedFacility.LOB.find(x => x["@ID"] == '0');
                if (!newLob) {
                    lob = DefaultFacilityGenerator.generateDefaultPayerInfo();
                    lob[property] = value;
                    newSelectedFacility.LOB.push(lob);
                }
                // else update existing lob 
                else {
                    // newLob[property] = value;
                    newLob[property] = value;
                }

                this.setState({
                    selectedFacility: newSelectedFacility,
                    isFacilityPayerInfoSubmit: isFacilityPayerInfoSubmitNew,
                    refreshPayerInfoList: isFacilityPayerInfoSubmitNew,
                })
            }
        }
        // else if triggered via edit of existing item
        else if (selectedFacility.LOB && Array.isArray(selectedFacility.LOB)) {

            // get a handle to the lob by filtering by the requested parameter
            let filterResults = selectedFacility.LOB.filter(x => {
                return x["@ID"] == selectedFacilityPayerInfoId
            });
            lob = filterResults[0];

            // update the selected lob with new property value
            lob[property] = value;

            // clone the selected facility
            let newSelectedFacility = _.cloneDeep(selectedFacility);

            // validate that the LOB property is an array
            if (selectedFacility.LOB && Array.isArray(selectedFacility.LOB)) {

                // get a handle to the current lob being edited
                let findIndex = selectedFacility.LOB.findIndex(x => { return x["@ID"] == selectedFacilityPayerInfoId });

                // replace the lob with the updated lob
                (newSelectedFacility?.LOB as LOBElement[]).splice(findIndex, 1, lob);
                this.setState({
                    selectedFacility: newSelectedFacility,
                    isFacilityPayerInfoSubmit: isFacilityPayerInfoSubmitNew,
                    selectedPayer: _.cloneDeep(lob)
                })
            }
        }
        //debugger;
    }

    trimOtherFacilityFields(facility: Facility, fieldName: string) {
        const fieldNames = [
            '@Name',
            '@Description',
            '@Address',
            '@Address2',
            '@City',
            '@ZipCode',
            '@TelephoneNo',
            '@FaxNo',
            '@ServiceStateID',
            '@ServiceZipCode',
            '@FacilitySubId',
            '@FedTaxId',
            '@FedTaxSubId',
            '@UB92AlternateEditMaster',
            '@HCFAAlternateEditMaster',
            '@BillingNumber',
            '@PhysicianAccountNo',
            '@SubmitterCode',
            '@FacilityNPI',
            '@TaxonomyCode',
            '@PayToName',
            '@PayToAddress',
            '@PayToAddress2',
            '@PayToCity',
            '@PayToZipCode',
        ]

        fieldNames.forEach(x => {
            let propVal = '';
            let stringVal = '';
            if (facility && (x != fieldName)) {
                // include guard to exclude non-string properties
                if ((typeof facility[x]) == "string") {
                    facility[x] = ((facility[x] as string) || '').trim();
                }
            }
        });
    }

    // save the current facility
    saveFacility() {
        const {
            selectedFacility,
            deltaCrud
        } = this.state;

        // access child subcomponents
        const originalFacility = selectedFacility;
        const facilityInformationFacility = this.facilityInformation!.current!.state.facility;
        new FacilityManager().saveFacility(
            originalFacility,
            facilityInformationFacility,
            // TODO: include facility refs from other child components
        );
        //console.log(deltaCrud);
    }

    onCancelClick(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({cancelLeave: true})
        } else
            this.props.history.push('/LandingPage');
    }

    componentDidMount() {
        //pageLeave();
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "EnableTenetFeatures" }] });

        this.setState({
            isBusy: true,
            selectedFacility: DefaultFacilityGenerator.generateDefaultFacility()
        }, this.loadData);
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public allowTenetFlag(): string {
        let allowTenetObj = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "EnableTenetFeatures");
        return (allowTenetObj && allowTenetObj.value ? allowTenetObj.value : "");
    }

    getTenetThirdPartyPrintLookup = async () => {
        var url = URLs.api + '/api/data/TenetThirdPartyPrintLookup';
        await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        }).then(response => response.json())
            .then((jsonData) => {
                this.setState({ tenetThirdPartyPrintLookup: jsonData })
            });
    };

    loadData() {
        const {
            action,
            canCreate
        } = this.props;

        // load the data
        const getFacilities = async () => {
            await action.crud.get({ crudId: CrudTypes.mctiFacility }, validationCallback);
            let allowTenet = this.allowTenetFlag();
            let printLookup = this.state.tenetThirdPartyPrintLookup;
            
            const maintenanceInfo = this.props.dataStore.crud.data?.FacilityMaintenanceInfo;

            if (maintenanceInfo) {

                // add default facility
                const defaultFacility = DefaultFacilityGenerator.generateDefaultFacility();
                maintenanceInfo?.Facilities.Facility.push(defaultFacility);

                // sort records by FacilitySubId
                maintenanceInfo?.Facilities.Facility.sort((a, b) => {
                    return a["@FacilitySubId"].localeCompare(b["@FacilitySubId"])
                });

                // build the facility listbox data
                const facilityListboxData: List[] = [];

                // create "add facility" option as first option in listbox regardless of permissions (legacy behavior)
                facilityListboxData.push({
                    "@ID": "0",
                    "@Name": "- ADD A NEW FACILITY -"
                })

                // populate the facility listbox items
                maintenanceInfo?.Facilities.Facility.filter(x => x["@ID"] != '0').forEach(x => {
                    facilityListboxData.push({
                        "@ID": x["@ID"],
                        "@Name": x["@Description"] + ' - ' + x["@FacilitySubId"]
                    });
                });
                
                maintenanceInfo?.PaperClaimsPrintingOptionTypeList.PaperClaimsPrintingOptionType.forEach(ob => {
                    if (allowTenet == "1" && printLookup == "1") {
                        ob["@Name"] = ob["@Name"].replace("by clearinghouse", "by other vendor");
                    }
                });

                this.setState({
                    facilityListboxData,
                    // reset any changes to the facility list
                    originalFacilityList: _.cloneDeep(maintenanceInfo.Facilities.Facility),
                    maintenanceInfo,
                    isBusy: false,
                }, this.onSelectFacilityInit);
            }
        }
        getFacilities();
    }

    // for some reason a programmatic toggle is needed to edit default empty item after page load
    onSelectFacilityInit() {
        const {
            maintenanceInfo,
        } = this.state;

        const facilityList = maintenanceInfo?.Facilities.Facility;
        if (!facilityList || facilityList.length < 2) return;

        let firstFacilityId = facilityList[1]["@ID"];
        const firstFacility = facilityList[1];
        const defaultFacility = facilityList[0];

        this.setState({
            selectedFacility: firstFacility,
            previousSelectedFacilityId: '0',
        }, () => {
            this.setState({
                selectedFacility: defaultFacility,
                previousSelectedFacilityId: firstFacilityId,
            });
        })
    }

    onSelectFacility(e: React.ChangeEvent<HTMLSelectElement>): void {
        const {
            maintenanceInfo,
            defaultFacility,
        } = this.state;

        let newMaintenanceInfo = this.resetFacilityEdits();

        // get a handle to the newly selected facility
        const newSelectedFacility = e.target.value !== "0" ? maintenanceInfo?.Facilities.Facility.find(x =>
            x["@ID"] == e.currentTarget.value) || defaultFacility : DefaultFacilityGenerator.generateDefaultFacility();

        // if selected facility doesnt have payer info
        if (!newSelectedFacility.LOB) {

            // associate a default one
            newSelectedFacility.LOB = DefaultFacilityGenerator.generateDefaultPayerInfo();
        }

        //debugger;

        this.setState({
            maintenanceInfo: newMaintenanceInfo,
            selectedFacility: newSelectedFacility,
            originalFacility: _.cloneDeep(newSelectedFacility),
            // set current item to previous indicator for next onselect
            previousSelectedFacilityId: e.currentTarget.value,
            // default page focus
            fieldFocus: 'NONE',
            isFacilityInfoSubmit: false,
            isFacilityPayerInfoSubmit: false,
            onCopyDupFacSubId: false,
            selectedPayerInfoId: '0'
        });
    }

    onSelectFacilityPostUpdate(idVal: string): void {
        const {
            maintenanceInfo,
            defaultFacility,
        } = this.state;

        let newMaintenanceInfo = this.resetFacilityEdits();

        // get a handle to the newly selected facility
        const newSelectedFacility = maintenanceInfo?.Facilities.Facility.find(x =>
            x["@ID"] == idVal) || defaultFacility;

        this.setState({
            maintenanceInfo: newMaintenanceInfo,
            selectedFacility: newSelectedFacility,
            // set current item to previous indicator for next onselect
            previousSelectedFacilityId: idVal,
            // default page focus
            fieldFocus: 'NONE',
            isFacilityInfoSubmit: false,
            isFacilityPayerInfoSubmit: false,
            onCopyDupFacSubId: false
        });
    }

    resetFacilityEdits() {
        const {
            maintenanceInfo,
            originalFacilityList,
            previousSelectedFacilityId,
        } = this.state;

        let newMaintenanceInfo = { ...maintenanceInfo } as MCFacilityMaintenanceInfo;

        // reset facility edits in original facility list
        // reset facility edits in maintenance info

        let previousSelectedFacilityIndex = originalFacilityList.findIndex(x => x["@ID"] == previousSelectedFacilityId);
        let previousSelectedFacilityOriginal = originalFacilityList.find(x => x["@ID"] == previousSelectedFacilityId) as Facility;

        newMaintenanceInfo.Facilities.Facility.splice(previousSelectedFacilityIndex, 1, _.cloneDeep(previousSelectedFacilityOriginal));

        return newMaintenanceInfo;
    }

    getPermissions(): CrudPermissions {
        const {
            canView,
            canEdit,
            canCreate,
            canDelete,
            canSecCheck1,
            canSecCheck2,
            canSecCheck3,
        } = this.props;

        return {
            canViewFacility: canView,
            canEditFacility: canEdit,
            canCreateFacility: canCreate,
            canDeleteFacility: canDelete,
            canCreatePayer: canSecCheck1,
            canEditPayer: canSecCheck2,
            canDeletePayer: canSecCheck3,
        }
    }

    validationAggregationRoutine(fieldId: string, isValid: boolean) {
        const {
            validationAggregation,
        } = this.state;

        const mapValue = validationAggregation.get(fieldId);
        if (mapValue == undefined || mapValue != isValid) {
            let newValidationAggregation = validationAggregation;
            newValidationAggregation.set(fieldId, isValid);
            // any false validation indicators will return false for pageIsValid
            const pageIsValid = Array.from(validationAggregation.values()).includes(false) ? false : true;
            this.setState({
                validationAggregation: newValidationAggregation,
                pageIsValid
            })
        }
    }

    onClickAddFacilityPayerInfo() {
        const {
            payerInfoSubmitCount
        } = this.state;

        // trigger validation
        this.setState({
            isFacilityPayerInfoSubmit: true,
            payerInfoSubmitCount: payerInfoSubmitCount + 1,
        }, this.onClickAddFacilityPayerInfoCallback)
    }

    onClickAddFacilityPayerInfoCallback() {
        const {
            selectedFacility,
            maintenanceInfo,
            originalFacilityList,
            deltaCrud,
            recordCount
        } = this.state;

        // if payer info isn't valid then return
        if (!this.getPayerInfoIsValid()) {
            this.setState({
                isFacilityPayerInfoSubmit: true
            })
            return;
        }
        let newSelectedPayerId = '0';
        //...else continue with add
        this.updateNewFacilityInfoId(selectedFacility);
        let newPayer = DefaultFacilityGenerator.generateDefaultPayerInfo();
        // the selected facilities last payer is the one added
        if(selectedFacility && Array.isArray(selectedFacility.LOB)){
            newPayer = _.cloneDeep(selectedFacility.LOB[selectedFacility.LOB.length - 1]);
            newPayer = new FacilityManager().upperCasePayerFields(newPayer);
            newPayer["@ID"] = `#${recordCount}`;
            selectedFacility.LOB[selectedFacility.LOB.length - 1] = newPayer;
        }

        if(maintenanceInfo) {
            const facilityToUpdate = maintenanceInfo.Facilities.Facility.find(fac => fac["@ID"] === selectedFacility["@ID"]);
            if(facilityToUpdate){
                if(facilityToUpdate.LOB){
                    if(Array.isArray(facilityToUpdate.LOB)){
                        facilityToUpdate.LOB.push(newPayer);
                    }
                    else{
                        // wasn't an array
                        facilityToUpdate.LOB = [facilityToUpdate.LOB]
                        facilityToUpdate.LOB.push(newPayer);
                    }
                }
                else{ // wasn't defined
                    facilityToUpdate.LOB = [newPayer];
                }
            }
        }
        
        if(originalFacilityList){
            const origFacToUpdate = originalFacilityList.find(fac => fac["@ID"] === selectedFacility["@ID"]);
            if(origFacToUpdate){
                if(origFacToUpdate.LOB){
                    if(Array.isArray(origFacToUpdate.LOB)){
                        origFacToUpdate.LOB.push(newPayer);
                    }
                    else{
                        // wasn't an array
                        origFacToUpdate.LOB = [origFacToUpdate.LOB]
                        origFacToUpdate.LOB.push(newPayer);
                    }
                }
                else{ // wasn't defined
                    origFacToUpdate.LOB = [newPayer];
                }
            }
            
            if(origFacToUpdate && origFacToUpdate.LOB ) {
                const idx = (origFacToUpdate.LOB as LOBElement[]).length - 1;
                newSelectedPayerId = (origFacToUpdate.LOB as LOBElement[])[idx]["@ID"];
            }
        }
        // post add state updates
        this.setState({
            // reset submit state
            isFacilityPayerInfoSubmit: false,
            refreshPayerInfoList: true,
            // update originalFacility ref to reflect latest changes
            originalFacility: _.cloneDeep(selectedFacility),
            deltaCrud: new FacilityManager().upsertPayerDelta(deltaCrud, newPayer, selectedFacility["@ID"]),
            maintenanceInfo: maintenanceInfo,
            originalFacilityList: originalFacilityList,
            selectedPayerInfoId: newSelectedPayerId,
            selectedPayer: _.cloneDeep(newPayer),
            recordCount: recordCount + 1,
            hasChanges: true
        })
    }

    onClickUpdateFacilityPayerInfo(payerInfoId: string) {
        this.setState({
            // set submit to trigger validators
            isFacilityPayerInfoSubmit: true
        }, this.onClickUpdateFacilityPayerInfoCallback1)
    }

    // specifically needed to turn off validators on toggle
    undoFacilityPayerInfoSubmitState() {
        this.setState({
            isFacilityPayerInfoSubmit: false
        });
    }

    onClickUpdateFacilityPayerInfoCallback1() {
        const {
            selectedFacility,
            payerInfoSubmitCount,
            originalFacility,
        } = this.state;

        let refreshPayerInfoList = false;
        let originalFacility2 = originalFacility;

        //debugger;

        // if payer info is valid
        if (this.getPayerInfoIsValid()) {
            // refresh original facility with latest updates
            originalFacility2 = _.cloneDeep(selectedFacility)
        }

        this.setState({
            isFacilityPayerInfoSubmit: true,
            payerInfoSubmitCount: payerInfoSubmitCount + 1,
            refreshPayerInfoList,
            originalFacility: originalFacility2
        }, this.updateFacilityPayerCallback2)
    }

    updateFacilityPayerCallback2(){
        const {
            deltaCrud,
            selectedFacility,
            selectedPayer,
            recordCount,
            maintenanceInfo,
            originalFacilityList
        } = this.state;

        // if payer info isn't valid then return
        if (!this.getPayerInfoIsValid()) {
            this.setState({
                isFacilityPayerInfoSubmit: true
            })
            return;
        }
        
        let lob : LOBElement = DefaultFacilityGenerator.generateDefaultPayerInfo();
        const newFacilityList = _.cloneDeep(maintenanceInfo?.Facilities.Facility);
        const newOrigFacilityList = _.cloneDeep(originalFacilityList);        
        if (selectedFacility.LOB && Array.isArray(selectedFacility.LOB)) {

            // get a handle to the lob by filtering by the requested parameter
            let filterResults = selectedFacility.LOB.filter(x => {
                return x["@ID"] == selectedPayer["@ID"];
            });
            if(filterResults[0])
                lob = filterResults[0];
            
            // update facility ui records
            
            if(newFacilityList) {
                const facilityIdx = newFacilityList.findIndex(fac => fac["@ID"] === selectedFacility["@ID"]);
                let payerIdx : number = -1;
                if(facilityIdx > -1){
                    let payerLobToUpdate = newFacilityList[facilityIdx].LOB;
                    if(payerLobToUpdate){
                        if(Array.isArray(payerLobToUpdate)){
                            payerIdx = payerLobToUpdate.findIndex(x => x["@ID"] === selectedPayer["@ID"]);
                        }
                        if(payerIdx > -1){
                            if(Array.isArray(payerLobToUpdate))
                                payerLobToUpdate[payerIdx] = _.cloneDeep(selectedPayer);
                        }
                    }
                }
                if(maintenanceInfo && newFacilityList)
                    maintenanceInfo.Facilities.Facility = newFacilityList;
            }
            if(newOrigFacilityList){
                const origFacilityIdx = newOrigFacilityList.findIndex(fac => fac["@ID"] === selectedFacility["@ID"]);
                let origPayerIdx : number = -1;
                if(origFacilityIdx > -1){
                    let origPayerLobToUpdate = newOrigFacilityList[origFacilityIdx].LOB;
                    if(origPayerLobToUpdate){
                        if(Array.isArray(origPayerLobToUpdate)){
                            origPayerIdx = origPayerLobToUpdate.findIndex(x => x["@ID"] === selectedPayer["@ID"]);

                            if(origPayerIdx > -1){
                                if(Array.isArray(origPayerLobToUpdate))
                                    origPayerLobToUpdate[origPayerIdx] = _.cloneDeep(selectedPayer);
                            }
                        }
                        // not in an array (single element from load)
                        else{
                            newOrigFacilityList[origFacilityIdx].LOB = [_.cloneDeep(selectedPayer)];
                        }
                    }
                }
                
            }            
        }

        this.setState({
            maintenanceInfo: maintenanceInfo,
            originalFacilityList: newOrigFacilityList,
            deltaCrud: new FacilityManager().upsertPayerDelta(deltaCrud, lob, selectedFacility["@ID"]),
            recordCount: recordCount + 1,
            hasChanges: true
        }); 
    }

    onClearRemoveConfirm() {
       this.setState({
          showDeletePayerConfirmation: false,
          showDeleteFacilityConfirmation: false,
          deleteConfirmationMsg: '' 
       });
    }

    onConfirmRemove() {
        const {
            showDeletePayerConfirmation,
            showDeleteFacilityConfirmation
        } = this.state;
        
        this.setState({
            showDeletePayerConfirmation: false,
            showDeleteFacilityConfirmation: false,
            deleteConfirmationMsg: ''
        }, showDeleteFacilityConfirmation ? this.removeFacilityMaster : this.removePayer); 
    }
    
    onClickCopyFacilityInfo (){
        this.setState({
            isFacilityInfoSubmit: true
        })
    }

    onClickRemoveFacilityPayerInfo(payerInfoId: string) {
        const{
            selectedFacility,
            maintenanceInfo
        } = this.state;

        let msg : string = '';
        if(selectedFacility){
            if (selectedFacility.LOB && Array.isArray(selectedFacility.LOB)){
                let lobName : string = '';
                let payerName: string = '';
                const payerInfo = selectedFacility.LOB.find(lob => lob["@ID"] === payerInfoId);
                if(payerInfo){
                    if(maintenanceInfo && maintenanceInfo.LOBList.LOB && Array.isArray(maintenanceInfo.LOBList.LOB)){
                        const lobDetail = maintenanceInfo.LOBList.LOB.find(l => l["@ID"] === payerInfo["@LOBID"] );
                        lobName = lobDetail ? lobDetail["@Name"] : '';
                    }

                    if(maintenanceInfo && maintenanceInfo.PayerList.Payer && Array.isArray(maintenanceInfo.PayerList.Payer)){
                        const payerDetail = maintenanceInfo.PayerList.Payer.find(l => l["@ID"] === payerInfo["@PayerID"] );
                        payerName = payerDetail ? payerDetail["@Name"] : '';
                    }

                    const displayName = lobName + ' - ' + payerName + ' - ' + payerInfo["@ProviderNo"];
                    msg = `Do you want to remove ${displayName}? This action cannot be undone.`;
                }

            }
        }

        this.setState({
            showDeletePayerConfirmation: true,
            deleteConfirmationMsg: msg,
            payerIdToDelete: payerInfoId
        });
    }

    updateNewFacilityInfoId(facility: Facility) {

        // get max payer info int id
        let payerInfoIntId = 0;
        let maxPayerInfoIntId = 0;
        (facility.LOB as LOBElement[]).forEach(x => {
            if (!this.isUuid(x['@ID'])) {
                payerInfoIntId = parseInt(x['@ID']);
                if (payerInfoIntId > maxPayerInfoIntId) {
                    maxPayerInfoIntId = payerInfoIntId;
                }
            }
        })

        // increment the id for the new payer info
        let payerInfo = (facility.LOB as LOBElement[]).find(x => x["@ID"] == '0');
        if (payerInfo) payerInfo["@ID"] = (maxPayerInfoIntId + 1).toString();
    }
    
    updateSelectedPayerInfoId(payerInfoId : string){
        const {
            selectedPayerInfoId
        } = this.state;
        
        this.setState({
            selectedPayerInfoId : payerInfoId
        } )
    }

    isUuid(value: string) {
        // Regular expression to check if string is a valid UUID
        const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

        // String with valid UUID separated by dash
        //const str = "a24a6ea4-ce75-4665-a070-57453082c256";

        return regexExp.test(value); // true
    }

    getPayerInfoIsValid() {
        const {
            validationAggregation
        } = this.state;

        const validationAggregationPayerInfoKeys =
            Array.from(validationAggregation.keys()).filter(x => x.startsWith('PayerInfo'));

        let payerInfoIsValid = true;
        validationAggregationPayerInfoKeys.forEach(x => {
            if (validationAggregation.get(x) == false) {
                payerInfoIsValid = false;
                return;
            }
        })

        return payerInfoIsValid;
    }

    validateFacilityPayerInfo() {
        this.setState({
            isFacilityPayerInfoSubmit: true
        })
    }

    render() {
        const {
            maintenanceInfo,
            facilityListboxData,
            selectedFacility,
            originalFacility,
            isBusy,
            pageIsValid,
            validationAggregation,
            fieldFocus,
            isFacilityInfoSubmit,
            isFacilityPayerInfoSubmit,
            payerInfoSubmitCount,
            cursorPos,
            refreshPayerInfoList,
            removedFacilityPayerInfoIds,
            selectedPayerInfoId
        } = this.state;

        const permissions = this.getPermissions();

        {/* DialogWrapper Setup Variables - Start */ }

        const helpUrl = '/Support/Help/HELP_Maint_Facility.htm';
        const enableOk = permissions.canEditFacility;
        const buttons = <OKCancelButtons
            disableOK={!enableOk}
            onClickOK={(e) => this.onOkClick(e)}
            onClickCancel={(e) => this.onCancelClick(e)} />;
        const instruction =
            "Use this page to enter each facility at the client site. Select Add New Facility, " +
            "enter all required facility information, click Add.Move to Payers Information, " +
            "add ID information for every payer used by the facility.Click Add between Payer ID sets. " +
            "Last, click OK to send all data to the database. * indicates a required field.";

        {/* DialogWrapper Setup Variables - End */ }

        const facilityInfoKey = 'facilityInfo-' + selectedFacility["@ID"];
        const payerInfoKey = 'payerInfo-' + selectedFacility["@ID"];

        const facilityPayerInfoIsValid = isFacilityPayerInfoSubmit && this.getPayerInfoIsValid();
        if(this.state.hasChanges)
            handleChange();
        return (
            <DialogWrapper
                title='Facility Maintenance'
                isBusy={isBusy}
                helpUrl={helpUrl}
                buttons={buttons}
                instruction={instruction}
                width='800'
            >
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <DialogFieldset>
                    <DialogLegend>Facilities</DialogLegend>
                    {maintenanceInfo &&
                        <SelectComponent
                            //autoFocus={true}
                            title='facilities'
                            size={10}
                            width='300px'
                            height='650px'
                            onSelect={(e) => this.onSelectFacility(e)}
                            optionFields={{
                                value: "@ID",
                                text: "@Name"
                            }}
                            records={facilityListboxData}
                            selectedValue={selectedFacility["@ID"]}
                            ref={(input) => { this.selectFacility = input; }}
                        >
                        </SelectComponent>
                    }
                </DialogFieldset>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <FacilityInformation
                        ref={this.facilityInformation}
                        key={facilityInfoKey}
                        maintenanceInfo={maintenanceInfo}
                        facility={selectedFacility}
                        crudPermissions={permissions}
                        isFacilityInfoSubmit={isFacilityInfoSubmit}
                        payerInfoSubmitCount={payerInfoSubmitCount}
                        facilityAddRoutine={this.addFacilityMaster}
                        facilityUpdateRoutine={this.updateSelectedFacility}
                        validationAggregationRoutine={this.validationAggregationRoutine}
                        validationAggregation={validationAggregation}
                        pageIsValid={pageIsValid}
                        fieldFocus={fieldFocus}
                        addFacilityMaster={this.addFacilityMaster}
                        updateFacilityMaster={this.updateFacilityMaster}
                        removeFacilityMaster={this.removeFacilityMasterPrompt}
                        copyFacilityMaster={this.copyFacilityMaster}
                        cursorPos={this.state.cursorPos}
                        onCopyDupFacSubId={this.state.onCopyDupFacSubId}
                    />
                    <PayerInformation
                        key={payerInfoKey}
                        maintenanceInfo={maintenanceInfo}
                        facility={selectedFacility}
                        originalFacility={originalFacility}
                        crudPermissions={permissions}
                        updateSelectedFacilityPayerInfo={this.updateSelectedFacilityPayerInfo}
                        onClickAddFacilityPayerInfo={this.onClickAddFacilityPayerInfo}
                        onClickUpdateFacilityPayerInfo={this.onClickUpdateFacilityPayerInfo}
                        validationAggregationRoutine={this.validationAggregationRoutine}
                        validationAggregation={validationAggregation}
                        fieldFocus={fieldFocus}
                        cursorPos={cursorPos}
                        isFacilityPayerInfoSubmit={isFacilityPayerInfoSubmit}
                        refreshPayerInfoList={refreshPayerInfoList}
                        facilityPayerInfoIsValid={facilityPayerInfoIsValid}
                        onClickRemoveFacilityPayerInfo={this.onClickRemoveFacilityPayerInfo}
                        removedFacilityPayerInfoIds={removedFacilityPayerInfoIds}
                        validateFacilityPayerInfo={this.validateFacilityPayerInfo}
                        undoFacilityPayerInfoSubmitState={this.undoFacilityPayerInfoSubmitState}
                        resetValidators={this.resetValidators}
                        selectedPayerInfoId={selectedPayerInfoId}
                        updateSelectedPayerInfoId={this.updateSelectedPayerInfoId}
                    />
                </div>
                <ModalConfirmation
                    isOpen={this.state.showDeleteFacilityConfirmation || this.state.showDeletePayerConfirmation}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearRemoveConfirm()}
                    message={this.state.deleteConfirmationMsg}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onConfirmRemove()}
                />                
            </DialogWrapper>
        )
    }
}

var connectedHoc = connect<IFacilityMasterState, IFacilityMasterActionProps, IOwnProps,
    IFacilityMasterProps, ApplicationState>(
        // selects which state properties are merged into the component's props
        createCrudMapStateToProps('facilityMaster'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(FacilityMaster);

export default withRouter(connectedHoc);
