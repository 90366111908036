import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';


export class CreateCifDAL {

/**
 * TODO:
 * - Refactor DAL into a single method that accepts fetch type (GET, POST, UPDATE, DELETE) and payload
 * - Keeping each distinct for now in case the use case diverge
 */

    public async GetCifFormData() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        let clientId = sessionStorage.getItem("Client")?.split(' ')[0];
        xmlData.ClientID = clientId || undefined;

        // init return object variable
        let cifFormData: CifFormData | undefined;

        try {
            const apiUrl = (URLs.api + '/api/data/cif/form');
            await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
            }).then(async (response) => {
                const data: any = await response.json();
                switch (response?.status) {
                    case 200:
                        try {
                            cifFormData = data as CifFormData;
                            console.log('%c DAL CIF Form Fetch Sucess', 'color: green; font-weight: bold', cifFormData);
                          
                            return cifFormData;
                        } catch (error) {
                            debugger;
                        }
                    case 306:
                    default:
                        this.throwError(response, data);
                }
            })
        } catch (error) {
            console.log('CreateCifDAL error = ' + error)
        }

        return cifFormData;

    }

    public async PostCifFormData(data: CifFormDataDetails) {

        let cifFormData: CifFormDataDetails | undefined;

        try {
            const apiUrl = (URLs.api + '/api/data/cif/print');

            await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`,
                },
                body: JSON.stringify(data)
              
            }).then(async (response) => {
                const data: any = await response.json();
                switch (response?.status) {
                    case 200:
                        try {
                            cifFormData = data as CifFormDataDetails;
                            console.log('%c [DAL PRINT API] Success:', 'color: green; font-weight: bold' , data)

                        } catch (error) {
                            debugger;
                        }
                      
                    default:
                        this.throwError(response, data);
                }
            })
        } catch (error) {
            //console.log('[CreateCifDAL] ERROR = '+ error)
        }
        return cifFormData;
    }

    public async GetCnnNumber(url: string) {

        let status: string | undefined;

        try {
            const apiUrl = (URLs.api + url);

            await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Accept': 'applications/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`,
                },
                body: JSON.stringify(url)
            }).then(async (response) => {

                const data: any = await response.json();
                switch (response?.status) {
                    case 200:
                        console.log('API returned data', data);
                    default:
                        this.throwError(response, data);
                }
            })
        } catch (error) {

        }

        return status;

    }


    throwError(response: Response, data: any) {
        const errorInfo = {
            responseStatus: response.status,
            responseStatusText: response.statusText,
            dataMessage: (data && data.message) ? data.message : data
        }
        const errorInfoJson = JSON.stringify(errorInfo);
        const errorMessage = `CifFormApi Error: ${errorInfoJson}`;
        throw new Error(errorMessage);
    }
}


