import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, SelectDropdownV2 } from 'ui-core';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, ContentRowWrapper, OKCancelButtons, DialogInstruction, CenteredContent } from '@common/DialogWrapper';
import { SelectComponent } from '@common/SelectComponent';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { DialogLabel } from '@common/DialogStyles';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { ISweepTimeState, ISweepTimeActionProps, actionCreators, validationCallback } from '@store/SweepTime';
import { DaysOfWeekCheckBoxComponent } from '@common/DateOrTimeRelated/DaysOfWeekCheckboxes'
import { ARMNarrowInput } from '@common/UICLWrappers/ARMNarrowInput';
import { ARMNarrowCheck } from '@common/UICLWrappers/ARMNarrowCheckbox';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '@common/AssuranceMenu';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    
     #sweeptime-daysofweek_label
    {
        padding: 30px 10px 10px 0px;
    }
`;

const SweepTimeTopContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: 5px;
    margin-left: 20px;
    label {
       width:90px !important;
       text-align:end;
    } 

#sweepTime-destination {
        width: 400px;
    }

#sweeptime-enable {
        width: 32px;
    }
`;

const SweepTimeBottomContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding: 0px;
`;

const SweepTimeContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding: 5px;

`;

const SweepTimeBodyContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 548px;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
`;

export const InputContainer = styled.div`
#interval-dropdown {
    width: 55px;
}

#interval-dropdown label {
    width: 130px;
}

#from-dropdown {
    padding-bottom:5px;
    width: 80px;
}

#through-dropdown {
    padding-bottom:5px;
    width: 80px;
}

.interval-lable-minutes {
    padding-top:28px;
    padding-left:5px;
}
`;

export const DropdownLabelBox = styled.div`
    display:flex;
    flex-direction:row;
`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 10px;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
};

interface IComponentState {
    cancelLeave: boolean,
    localTextInput: string,
    blurred: boolean 
}


export const defaultState: IComponentState = { cancelLeave: false, localTextInput: '', blurred: false };

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type ISweepTimeProps = IMergeCrudComponentProps<ISweepTimeState, ISweepTimeActionProps, IOwnProps>;

enum eModifyButtonType {
    Add,
    Update
}

export class SweepTime extends React.Component<ISweepTimeProps, IComponentState> {
    addScheduleItemCount: number = 0;
    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Sweep Time Maintenance"
    };

    constructor(props: ISweepTimeProps) {
        super(props);
        this.state = defaultState;
    }

    deltaCrud: MCSweepTimeType =
        {
            AutoPrintMaintenanceInfo:
            {
                AutoPrintSettings: {
                    AutoPrintSetting: {
                        '@ID': '#',
                        '@Destination': '',
                        '@Enabled': '',
                        Events: {
                            Event: []
                        }
                    }
                }
            }
        }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiAutoPrintMaster }, validationCallback);
    }

    public componentDidUpdate() {
        if (this.state.localTextInput.endsWith(' ') && this.state.blurred) {
            this.setState({localTextInput: this.state.localTextInput.slice().trim(), blurred: false})
        }
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onSelectSweepTime(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectSweepTime(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    value: e.target.value,
                    text: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }


    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.dataStore.crud.data!.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event.length > 0 &&
            this.props.dataStore.crud.data!.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@Destination"] === '') {
            this.props.action.ui.errorSweepTimeField({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: "sweepTimeDestinationError",
                    value: 'A Destination is required.'
                }
            });
            return;
        }

        // prepare delta
        if (this.props.dataStore.crud.data!.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@ID"] !== "") {
            this.deltaCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@ID"] =
                this.props.dataStore.crud.data!.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@ID"];
        }
        this.deltaCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@Destination"] =
            this.props.dataStore.crud.data!.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@Destination"];
        this.deltaCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@Enabled"] =
            this.props.dataStore.crud.data!.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@Enabled"];

        for (var item of this.deltaCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event) {
            if (('' + item['@ID']).indexOf('New[') > -1) {
                item['@ID'] = '#';
            }
        }
        this.props.action.crud.update({ crudId: CrudTypes.mctiAutoPrintMaster, data: this.deltaCrud });

        console.log(JSON.stringify(this.deltaCrud));
        this.props.history.push('/LandingPage');
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {

        const userModified: any = sessionStorage.getItem("userModified");
        if (userModified === 'true') {
            this.setState({ cancelLeave: true });
        }
        else
            this.props.history.push('/LandingPage');
    }

    public getModifyButtonType(): eModifyButtonType {
        switch (this.props.dataStore.ui.selectedSweepTime.index) {
            case 0:
                return eModifyButtonType.Add;
            default:
                return eModifyButtonType.Update;
        }
    }

    public getModifyButtonText() {
        switch (this.getModifyButtonType()) {
            case eModifyButtonType.Add:
                return 'Add';
            default:
                return 'Update';
        }
    }

    public isRemoveButtonDisabled() {
        return (this.props.dataStore.ui.selectedSweepTime.index === 0 || !this.props.canEdit);
    }

    public onClickModifyButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.dataStore.crud.data) {
            handleChange();
            let item = {
                '@ID': '',
                '@Sun': this.props.dataStore.ui.sweepTimeSun,
                '@Mon': this.props.dataStore.ui.sweepTimeMon,
                '@Tue': this.props.dataStore.ui.sweepTimeTue,
                '@Wed': this.props.dataStore.ui.sweepTimeWed,
                '@Thu': this.props.dataStore.ui.sweepTimeThu,
                '@Fri': this.props.dataStore.ui.sweepTimeFri,
                '@Sat': this.props.dataStore.ui.sweepTimeSat,
                '@FromTime': this.props.dataStore.ui.sweepTimeFrom,
                '@ThruTime': this.props.dataStore.ui.sweepTimeThrough,
                '@CheckInterval': this.props.dataStore.ui.sweepTimeInterval,
            };
            switch (this.getModifyButtonType()) {
                case eModifyButtonType.Add:
                    item['@ID'] = "New[" + this.addScheduleItemCount++ + "]";
                    this.props.action.ui.addSweepTime({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: item,
                        delta: this.deltaCrud
                    });
                    break;
                default:
                    item['@ID'] = this.props.dataStore.ui.selectedSweepTime.value;
                    this.props.action.ui.updateSweepTime({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: item,
                        delta: this.deltaCrud
                    });
            }
        }
    }

    public onClickRemoveButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.dataStore.ui.selectedSweepTime.value === "")
            return;


        const msg = `Do you want to remove ${this.props.dataStore.ui.selectedSweepTime.text}? This action cannot be undone.`;
        this.onConfirmRemoveSchedule(msg);
    }

    public onSelectDayOfWeek(e: React.ChangeEvent<HTMLInputElement>, idx: string) {

        this.props.action.ui.selectDayOfWeek(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: idx,
                    value: e.target.checked,
                }
            });

    }

    public updateSweepTimeDestination(val: string) {
        const trimmedVal = val.trim()
        handleChange();
        this.props.action.ui.updateSweepTimeDestination({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: (trimmedVal && trimmedVal.length > 0 ? trimmedVal.toUpperCase() : '').trim()
            }
        });
    }
            

    public updateSweepTimeEnabled(val: boolean) {
        handleChange();
        this.props.action.ui.updateSweepTimeEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val ? "Y" : "N"
            }
        });
    }

    public updateSweepTimeFrom(val: string) {
        this.props.action.ui.updateSweepTimeFrom({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });

    }

    public updateSweepTimeThru(val: string) {
        this.props.action.ui.updateSweepTimeThrough({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public updateSweepTimeInterval(val: string) {
        this.props.action.ui.updateSweepTimeInterval({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public getScheduleName(obj: MCST_Event): string {
        let fromTime = '';
        let thruTime = '';
        if (obj['@FromTime'] && obj['@FromTime'].length > 0)
            fromTime = obj['@FromTime'].substring(0, 5);

        if (obj['@ThruTime'] && obj['@ThruTime'].length > 0)
            thruTime = obj['@ThruTime'].substring(0, 5);

        let days = '';
        if (obj['@Sun'] && obj['@Sun'] === "Y")
            days = days + "Su";
        if (obj['@Mon'] && obj['@Mon'] === "Y")
            days = days + "Mo";
        if (obj['@Tue'] && obj['@Tue'] === "Y")
            days = days + "Tu";
        if (obj['@Wed'] && obj['@Wed'] === "Y")
            days = days + "We";
        if (obj['@Thu'] && obj['@Thu'] === "Y")
            days = days + "Th";
        if (obj['@Fri'] && obj['@Fri'] === "Y")
            days = days + "Fr";
        if (obj['@Sat'] && obj['@Sat'] === "Y")
            days = days + "Sa";



        return fromTime + '-' + thruTime + ' ' + days;
    }

    public getEventData(): any[] {
        let result: any[] = [
            { value: '', label: "- ADD A NEW TIME -" }
        ];

        if (this.props.dataStore.crud.data && this.props.dataStore.crud.data.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event) {
            let mylist: any = this.props.dataStore.crud.data.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event;

            let isMyListArray = Array.isArray(mylist);
            if (!isMyListArray) {
                mylist = [mylist];
            }
            mylist.forEach((obj: MCST_Event) => {
                if (obj['@ID']) {
                    const name = this.getScheduleName(obj);

                    result.push({ value: obj['@ID'], label: name });
                }
            });

            // load initial values (first time only)
            if (!this.props.dataStore.ui.initialValuesLoaded) {
                this.props.action.ui.updateSweepTimeDestination({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: this.props.dataStore.crud.data.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@Destination"].trim()
                    }
                });
                this.setState({ localTextInput: this.props.dataStore.crud.data.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@Destination"].toLowerCase() })
                this.props.action.ui.updateSweepTimeEnabled({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: this.props.dataStore.crud.data.AutoPrintMaintenanceInfo.AutoPrintSettings
                            .AutoPrintSetting["@Enabled"]
                    }
                });
            }
        }

        //console.log(result);
        return result;
    }

    public onConfirmRemoveSchedule(msg: string) {
        this.props.action.ui.errorSweepTimeRemoveScheduleConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onRemoveSchedule() {
        handleChange();
        this.props.action.ui.removeSweepTime({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                '@ID': this.props.dataStore.ui.selectedSweepTime.value
            },
            delta: this.deltaCrud
        });
    }

    public handleKeyDown(e:any){
        if (e.key === " ") {
            e.preventDefault();
        }
    }

    public moveCursorToEnd(id: any) {
        var el: any = document.getElementById(id)
        let trimmedEl = el.value.trim();
        el.focus()
        if (el.value.endsWith(' ')) {
            var len = trimmedEl.length;
            if (el.setSelectionRange && el.selectionStart > len) {
                el.focus();
                el.setSelectionRange(len, len);
            } else if (el.createTextRange) {
                var t = el.createTextRange();
                t.collapse(true);
                t.moveEnd('character', len);
                t.moveStart('character', len);
                t.select();
                
            }
        }
    }

    public trimOnBlur(id: string) {
        let el: any = document.getElementById(id)
        if (el.value.endsWith(' ')) {
            el.value = el.value.slice().trim();
            let newVal = el.value.slice().trim();
            el.setAttribute('value', newVal)
        }
        el.blur()
        this.setState({ blurred: true })
    }






    public render() {
        var instruction = <React.Fragment>Enter a Destination path and set the sweep interval schedule. After using this page, open File Type to finish setting up Auto-Print.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        let fromList = [{ value: '00:00:00', label: '00:00' },
        { value: '01:00:00', label: '01:00' },
        { value: '02:00:00', label: '02:00' },
        { value: '03:00:00', label: '03:00' },
        { value: '04:00:00', label: '04:00' },
        { value: '05:00:00', label: '05:00' },
        { value: '06:00:00', label: '06:00' },
        { value: '07:00:00', label: '07:00' },
        { value: '08:00:00', label: '08:00' },
        { value: '09:00:00', label: '09:00' },
        { value: '10:00:00', label: '10:00' },
        { value: '11:00:00', label: '11:00' },
        { value: '12:00:00', label: '12:00' },
        { value: '13:00:00', label: '13:00' },
        { value: '14:00:00', label: '14:00' },
        { value: '15:00:00', label: '15:00' },
        { value: '16:00:00', label: '16:00' },
        { value: '17:00:00', label: '17:00' },
        { value: '18:00:00', label: '18:00' },
        { value: '19:00:00', label: '19:00' },
        { value: '20:00:00', label: '20:00' },
        { value: '21:00:00', label: '21:00' },
        { value: '22:00:00', label: '22:00' },
        { value: '23:00:00', label: '23:00' }
        ];

        let intervalList = [{ value: '0', label: '0' },
        { value: '5', label: '5' },
        { value: '15', label: '15' },
        { value: '30', label: '30' },
        { value: '45', label: '45' },
        { value: '60', label: '60' }
        ];

        let instructionTop = <React.Fragment><span className='instructionRed'>Warning!</span> To reflect any changes made to the settings, the agent must be stopped and restarted. </React.Fragment>;

      

        var records = this.getEventData();

        return (
            <DialogWrapper title="Auto Print Sweep Time" instruction={instruction} helpUrl='/Support/Help/HELP_MaintAutoPrintSweep.asp' buttons={buttons} isBusy={!this.props.dataStore.crud.data}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <SweepTimeBodyContent>
                        {<CenteredContent><DialogInstruction>{instructionTop}</DialogInstruction></CenteredContent>
                        }
                        <SweepTimeTopContent>
                            <ContentRowWrapper>
                                <ARMNarrowInput
                                    id='sweepTime-destination'
                                    label="Destination:"
                                    labelWidth="90px"
                                    maxLength={256}
                                    onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({ blurred: false })
                                        this.moveCursorToEnd('sweepTime-destination')
                                    }}
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.trimOnBlur('sweepTime-destination')
                                        this.updateSweepTimeDestination(e.target.value.trim())
                                    }}
                                    value={this.state.localTextInput}
                                    hasError={this.props.dataStore.ui.sweepTimeDestinationError && this.props.dataStore.ui.sweepTimeDestinationError !== ''}
                                    errorMessage={this.props.dataStore.ui.sweepTimeDestinationError}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.setState({localTextInput: e.target.value})
                                    }}
                                />
                            </ContentRowWrapper>
                            <ContentRowWrapper>
                                <ARMNarrowCheck
                                    id='sweepTime-enable'
                                    label='Enable:'
                                    checked={this.props.dataStore.ui.sweepTimeEnabled === "Y"}
                                    onChangeSelect={(e: React.ChangeEvent<HTMLInputElement>) => this.updateSweepTimeEnabled(e.target.checked)} />
                            </ContentRowWrapper>
                        </SweepTimeTopContent>


                        <SweepTimeBottomContent>
                            <SelectList>
                                <DialogFieldset>
                                    <DialogLegend>Schedule</DialogLegend>
                                    <ContentRowWrapper>
                                        <SelectComponent
                                            title='sweepTimes'
                                            size={13}
                                            width='250px'
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectSweepTime(e)}
                                            optionFields={{
                                                value: "value",
                                                text: "label"
                                            }}
                                            records={records}
                                            selectedValue={this.props.dataStore.ui.selectedSweepTime.value}
                                        >
                                        </SelectComponent>
                                        <SweepTimeContent>
                                            <SelectActions>
                                                <InputContainer>
                                                    <ContentRowWrapper>
                                                        <DialogLabel
                                                            id='sweeptime-daysofweek_label'
                                                        >Days:</DialogLabel>
                                                        <DaysOfWeekCheckBoxComponent
                                                            id='sweeptime-days'
                                                            checkedSun={this.props.dataStore.ui.sweepTimeSun === 'Y'}
                                                            checkedMon={this.props.dataStore.ui.sweepTimeMon === 'Y'}
                                                            checkedTue={this.props.dataStore.ui.sweepTimeTue === 'Y'}
                                                            checkedWed={this.props.dataStore.ui.sweepTimeWed === 'Y'}
                                                            checkedThu={this.props.dataStore.ui.sweepTimeThu === 'Y'}
                                                            checkedFri={this.props.dataStore.ui.sweepTimeFri === 'Y'}
                                                            checkedSat={this.props.dataStore.ui.sweepTimeSat === 'Y'}
                                                            disabled={false}
                                                            onChangeSun={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Sun')}
                                                            onChangeMon={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Mon')}
                                                            onChangeTue={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Tue')}
                                                            onChangeWed={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Wed')}
                                                            onChangeThu={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Thu')}
                                                            onChangeFri={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Fri')}
                                                            onChangeSat={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Sat')}
                                                        />
                                                    </ContentRowWrapper>

                                                    <SelectDropdownV2
                                                        domID="from-dropdown"
                                                        className="dropdown"
                                                        label="From:"
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        size='medium'
                                                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateSweepTimeFrom(e.value)}
                                                        options={fromList}
                                                        initialValue={{
                                                            value: this.props.dataStore.ui.sweepTimeFrom,
                                                            label: this.props.dataStore.ui.sweepTimeFrom.substring(0, 5)
                                                        }}
                                                    />



                                                    <SelectDropdownV2
                                                        domID="through-dropdown"
                                                        className="dropdown"
                                                        label="Through:"
                                                        isClearable={false}
                                                        isSearchable={true}
                                                        size='medium'
                                                        onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateSweepTimeThru(e.value)}
                                                        options={fromList}
                                                        initialValue={{
                                                            value: this.props.dataStore.ui.sweepTimeThrough,
                                                            label: this.props.dataStore.ui.sweepTimeThrough.substring(0, 5)
                                                        }}
                                                    />
                                                    <DropdownLabelBox>
                                                        <SelectDropdownV2
                                                            domID="interval-dropdown"
                                                            className="dropdown"
                                                            label="Check status every:"
                                                            isClearable={false}
                                                            isSearchable={true}
                                                            size='medium'
                                                            onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateSweepTimeInterval(e.value)}
                                                            options={intervalList}
                                                            initialValue={{
                                                                value: this.props.dataStore.ui.sweepTimeInterval,
                                                                label: this.props.dataStore.ui.sweepTimeInterval
                                                            }}
                                                        />
                                                        <label className="label interval-lable-minutes dkHlnz">MINUTES</label>
                                                    </DropdownLabelBox>

                                                </InputContainer>

                                                <SelectButtons>
                                                    <Button
                                                        domID="addButton"
                                                        name={this.getModifyButtonText()}
                                                        buttonType="standard"
                                                        size="small"
                                                        type="button"
                                                        disabled={!this.props.canEdit}

                                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickModifyButton(e)} />
                                                    <Button
                                                        domID="removeButton"
                                                        name="Remove"
                                                        buttonType="standard"
                                                        size="small"
                                                        type="button"
                                                        disabled={this.isRemoveButtonDisabled()}

                                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickRemoveButton(e)} />
                                                </SelectButtons>


                                            </SelectActions>
                                        </SweepTimeContent>
                                    </ContentRowWrapper>
                                </DialogFieldset>
                            </SelectList>
                        </SweepTimeBottomContent>
                    </SweepTimeBodyContent>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.removeScheduleConfirm && this.props.dataStore.ui.removeScheduleConfirm.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onConfirmRemoveSchedule('')}
                    message={this.props.dataStore.ui.removeScheduleConfirm}
                    onConfirm={() => this.onRemoveSchedule()}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<ISweepTimeState, ISweepTimeActionProps, IOwnProps, ISweepTimeProps, ApplicationState>(
    createCrudMapStateToProps('sweepTime'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(SweepTime);

export default withRouter(connectedHoc);
