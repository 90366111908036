import { Reducer, Action } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"




// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IUseFileUIState {
    selectTabIndex: number;
    clearFocus: boolean;  // if an action is processed, clear the focus/error field

}



const defaultState: IUseFileUIState = {
    clearFocus: false,
    selectTabIndex: 0
};

export interface IUseFileUIData {
    index: string;
    value: boolean;
};
interface IUseFileUIFieldData {
    data: any;
    index: number;
}


export const defaultFilter: IUseFileUIData = {
    index: '',
    value: false,
};


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).





export const actionCreators = {
    setActiveTab: (tabInfo: any) => createDataAction('UF_SET_ACTIVE_TAB', tabInfo),
};


export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];



// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IUseFileUIState, KnownActions> = (state: IUseFileUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        // handle stupid focus issues
        switch (action.type) {
            default:
                state.clearFocus = true;
        }

        switch (action.type) {

            case 'UF_SET_ACTIVE_TAB':
                return {
                    ...state,
                    selectedIndex: action.data.selectedIndex
                }
                break;


            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}

