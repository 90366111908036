import { Reducer, ActionCreatorsMapObject } from 'redux';
import { ICrudActionData } from "../../scripts/util/CrudComponentHelpers";
import { IFormUIState, defaultFormState } from "@store/ui/BaseCrudUI";
import { createDataAction, ActionTypes, IApiAction } from "../../scripts/util/ActionHelpers";

// Properties of action creators specific to the SecurityAccountPolicy view
export interface ISecurityAccountPolicyData {
    data: any;
}

// UI representation of CRUD data. 
export interface ISecurityAccountPolicyUIState extends ISecurityAccountPolicyData, IFormUIState {
};

// merge our custom action data interface with CRUD boilerplate
export interface IModifySecurityAccountPolicy extends ICrudActionData<MCSecurityAccountPolicy, string> {
};

export interface IResetCrudFlag extends ICrudActionData<MCSecurityAccountPolicy, boolean> {
};

export interface IInitializeSecurityAccountPolicy extends ICrudActionData<MCSecurityAccountPolicy, MCChildNode> {
};

// exported for if I get time to do tests, otherwise does not need export
export const actionCreators = {
    initalizeOptions: (rawApiReturn: IInitializeSecurityAccountPolicy) => createDataAction('INIT_SECURITY_ACCOUNT_POLICY', rawApiReturn),
    editData: (securityAccountPolicyInfo: IModifySecurityAccountPolicy) => createDataAction('EDIT_SECURITY_ACCOUNT_POLICY', securityAccountPolicyInfo),
    resetDirtyFlag: (securityAccountPolicyInfo: IResetCrudFlag) => createDataAction('RESET_SECURITY_ACCOUNT_POLICY_DIRTY', securityAccountPolicyInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultSecurityAccountPolicyState: ISecurityAccountPolicyUIState = {
    ...defaultFormState,
    data: {},
}



export const securityAccountPolicyUIReducer: Reducer<ISecurityAccountPolicyUIState, KnownActions> =
    (state: ISecurityAccountPolicyUIState | undefined, action: KnownActions) => {
        const crudData = action.data?.masterCrud?.ClientAccountPolicy;
        const isDirty = true;
        let newState = state && { ...state };
        // reducer doesn't do anything. Keeping it just in case. 
        return newState || defaultSecurityAccountPolicyState;
    }