import { ActionTypes, createAction, createDataAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { Reducer } from 'redux';
import { ComplexAction, ComplexApiAction, IThunkAction, IThunkApiAction } from "@scripts/util/ThunkHelpers";
import { MasterCrudState, actionCreators as masterCrudCreators, ICrudObject } from '../MasterCrud';
import CrudTypes from "@commonResources/CrudTypes";
import { History } from 'history';

// -----------------
// STATE - This defines the type of data maintained in the Redux store. AEPhysicianMaintenanceUI
export interface IAEPhysicianMaintenanceUIState {
    first: string;
    mi: string;
    last: string;
    suffix: string;
    taxID: string;
    faxNo: string;
    idType: string;
    npi: string;
    taxonomy: string;
    formType: string;
    lob: AE_LOB[];

    selectedAEPhysicianMaintenance: IAEPhysicianMaintenanceUIData;
    deltaCrud: AEPhysicianMaintenanceDelta;
    selectedItem: AE_Physician;
    selectedLOBID: AE_LOB;
    changed: boolean;
    leave: boolean;

    aePhysicianMaintenanceAlert?: string;
    aePhysicianRemoveConfirm?: string;
    aeLOBPhysicianRemoveConfirm?: string;

    lastNameError?: string;
    firstNameError?: string;
    faxNoError?: string;
    nbiError?: string;
    taxIDError?: string;
    taxonomyError?: string;
    physicianNoError?: string;
    nbiLOBError?: string;
    taxonomyLOBError?: string;

    selectedTabIndex: number;
    searchResults: AE_Physician[];
    delta: any;
    searchLOBResults: AE_LOB[];

    cboSearchFields: string;
    searchType: string;
    searchFrom: string;
    searchTo: string;
    searchForm: string;

    localSearch: string;
    payerLOBID: string;
    isLOBModelOpen: boolean;

}

export const defaultAEPhysicianMaintenance: IAEPhysicianMaintenanceUIData = {
    id: '',
    text: '',
};

const lob: AE_LOB[] = [];

const emptyItem: AE_Physician = {
    "@ID": '',
    "@First": '',
    "@MI": '',
    "@Last": '',
    "@Suffix": '',
    "@TaxID": '',
    "@FaxNo": '',
    "@IDType": '',
    "@NPI": '',
    "@Taxonomy": '',
    "@FormType": '0',
    "@CustomerKey": '',
    "LOB": lob
};

const emptyItemLOB: AE_LOB = {
    "@ID": '',
    "@PhysicianNo": '',
    "@QC": '',
    "@LOB": '',
    "@Payer": '',
    "@NPI": '',
    "@Taxonomy": '',
    "@Delete": ''
};

const emptyDelta: AEPhysicianMaintenanceDelta = {
    Physicians: {
        Physician: []
    }
}


const results: AE_Physician[] = [];
const lobResults: AE_LOB[] = [];

export const defaultState: IAEPhysicianMaintenanceUIState = {
    first: "",
    mi: "",
    last: "",
    suffix: "",
    taxID: "",
    faxNo: "",
    idType: "",
    npi: "",
    taxonomy: "",
    formType: "",
    lob: [],
    leave: false,

    selectedAEPhysicianMaintenance: defaultAEPhysicianMaintenance,
    selectedItem: emptyItem,
    selectedLOBID: emptyItemLOB,
    changed: false,
    aePhysicianMaintenanceAlert: '',

    lastNameError: undefined,
    firstNameError: undefined,
    faxNoError: undefined,
    nbiError: undefined,
    taxIDError: undefined,
    taxonomyError: undefined,
    physicianNoError: undefined,
    nbiLOBError: undefined,
    taxonomyLOBError: undefined,

    aePhysicianRemoveConfirm: undefined,
    selectedTabIndex: 0,
    searchResults: results,
    delta: [],
    searchLOBResults: lobResults,

    cboSearchFields: "1",
    searchType: "0",
    searchTo: '',
    searchFrom: '',
    searchForm: "0",

    localSearch: '',
    payerLOBID: '',
    isLOBModelOpen: false,
    deltaCrud: JSON.parse(JSON.stringify(emptyDelta)),
};


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface IAEPhysicianMaintenanceUIData {
    id: string;
    text: string;
};

interface IAEPhysicianMaintenanceUIFieldUpdate {
    value: string;
}

interface IAEPhysicianUIError {
    value: string;
    id?: string;
}

interface IAEPhysicianSearch {
    SearchFields: string;
    SearchType: string;
    SearchFrom: string;
    SearchForm: string;
    SearchTo: string;

    SearchData: AE_Physician[];
}

interface ItxtSearch {
    searchData: string;
    resultData: AE_Physician[];
}

interface limitPhysians {
    SearchData: AE_Physician[];
}

export interface ISelectAEPhysicianMaintenance extends ICrudActionData<AEPhysicianMaintenanceType, IAEPhysicianMaintenanceUIData> { }
export interface IUpdateAEPhysicianMaintenanceField extends ICrudActionData<AEPhysicianMaintenanceType, IAEPhysicianMaintenanceUIFieldUpdate> { }
export interface IError extends ICrudActionData<AEPhysicianMaintenanceType, IAEPhysicianUIError> { }

export interface IAddModifyAEPhysician extends ICrudActionData<AEPhysicianMaintenanceType, IAEPhysicianMaintenanceUIData> { }
export interface IRemoveAEPhysician extends ICrudActionData<AEPhysicianMaintenanceType, IAEPhysicianMaintenanceUIData> { }

export interface IMainSearch extends ICrudActionData<AEPhysicianMaintenanceType, IAEPhysicianSearch> { }
export interface IFilterTxtSearch extends ICrudActionData<AEPhysicianMaintenanceType, ItxtSearch> { }

export const actionCreators = {
    selectAEPhysicianMaintenance: (selectInfo: ISelectAEPhysicianMaintenance) => createDataAction('SELECT_AEPHYSICIAN', selectInfo),
    selectLOBAEPhysicianMaintenance: (selectInfo: ISelectAEPhysicianMaintenance) => createDataAction('SELECT_LOBAEPHYSICIAN', selectInfo),

    isUpdate: (res: boolean) => createDataAction('IS_UPDATE', res),
    isLeave: (res: boolean) => createDataAction('IS_LEAVE', res),
    addAEPhysician: (addInfo: IAddModifyAEPhysician) => createDataAction('ADD_AEPHYSICIAN', addInfo),
    updateAEPhysician: (updateInfo: IAddModifyAEPhysician) => createDataAction('UPDATE_AEPHYSICIAN', updateInfo),
    removeAEPhysician: (updateInfo: IRemoveAEPhysician) => createDataAction('REMOVE_AEPHYSICIAN', updateInfo),

    addLOBAEPhysician: (addInfo: IAddModifyAEPhysician) => createDataAction('ADD_LOBAEPHYSICIAN', addInfo),
    updateLOBAEPhysician: (updateInfo: IAddModifyAEPhysician) => createDataAction('UPDATE_LOBAEPHYSICIAN', updateInfo),
    removeLOBAEPhysician: (removeInfo: IRemoveAEPhysician) => createDataAction('REMOVE_LOBAEPHYSICIAN', removeInfo),

    selectTabIndex: (tabIndex: number) => createDataAction('SELECT_AEPHYSICIAN_TAB_INDEX', tabIndex),
    searchResults: (results: []) => createDataAction('SEARCH_AEPHYSICIAN_RESULTS', results),
    searchLOBResults: (results: []) => createDataAction('SEARCH_AEPHYSICIAN_PAYERSPECIFICID', results),

    updateAEPhysicianSearchFields: (val: string) => createDataAction('UPDATE_AEPHYSICIAN_SEARCHFIELDS', val),
    updateAEPhysicianSearchType: (val: string) => createDataAction('UPDATE_AEPHYSICIAN_SEARCHTYPE', val),
    updateAEPhysicianSearchFrom: (val: string) => createDataAction('UPDATE_AEPHYSICIAN_SEARCHFROM', val),
    updateAEPhysicianSearchTo: (val: string) => createDataAction('UPDATE_AEPHYSICIAN_SEARCHTO', val),
    updateAEPhysicianSearchForm: (val: string) => createDataAction('UPDATE_AEPHYSICIAN_SEARCHFORM', val),

    searchSelectedItem: (selectedItem: AE_Physician) => createDataAction('SEARCH_SELECTED_ITEM', selectedItem),
    searchLOBSelectedItem: (selectedLOBItem: AE_LOB) => createDataAction('SEARCH_LOB_SELECTED_ITEM', selectedLOBItem),
    txtSearch: (searchData: ItxtSearch) => createDataAction('TXT_SEARCH', searchData),

    updateAEPhysicianLastName: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_LASTNAME', fieldInfo),
    updateAEPhysicianFirstName: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_FIRSTNAME', fieldInfo),
    updateAEPhysicianMI: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_MI', fieldInfo),
    updateAEPhysicianSuffix: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_Suffix', fieldInfo),
    updateAEPhysicianFormType: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_FORMTYPE', fieldInfo),
    updateAEPhysicianIDType: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_IDTYPE', fieldInfo),
    updateAEPhysicianTaxID: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_TAXID', fieldInfo),
    updateAEPhysicianNPI: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_NPI', fieldInfo),
    updateAEPhysicianTaxonomy: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_TAXONOMY', fieldInfo),
    updateAEPhysicianFaxNo: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_FAXNO', fieldInfo),

    updateAEPhysicianNo: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_NO', fieldInfo),
    updateAEPhysicianLOBQC: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_LOB_QC', fieldInfo),
    updateAEPhysicianLOBLOB: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_LOB_LOB', fieldInfo),
    updateAEPhysicianLOBPayer: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_LOB_PAYER', fieldInfo),
    updateAEPhysicianLOBNPI: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_LOB_NPI', fieldInfo),
    updateAEPhysicianLOBTaxonomy: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('UPDATE_AEPHYSICIAN_LOB_TAXONOMY', fieldInfo),

    errorAEPhysicianField: (fieldInfo: IError) => createDataAction('ERROR_AEPHYSICIAN_FIELD', fieldInfo),
    errorLOBAEPhysicianField: (fieldInfo: IError) => createDataAction('ERROR_LOBAEPHYSICIAN_FIELD', fieldInfo),
    errorAEPhysicianRemoveConfirm: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('ERROR_AEPHYSICIAN_REMOVE_CONFIRM', fieldInfo),
    errorLOBAEPhysicianRemoveConfirm: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('ERROR_LOBAEPHYSICIAN_REMOVE_CONFIRM', fieldInfo),

    sendAEPhysicianMaintenanceAlert: (fieldInfo: IUpdateAEPhysicianMaintenanceField) => createDataAction('SEND_ALERT', fieldInfo),
    search: (fieldInfo: IAEPhysicianSearch) => createDataAction('MAIN_SEARCH', fieldInfo),
    setPayerLOBID: (val: string) => createDataAction('SET_PAYER_LOB_ID', val),
    isLOBModelOpen: (val: boolean) => createDataAction('IS_LOBMODEL_OPEN', val),
    clearDelta: () => createAction('AEPM_CLEAR_DELTA'),

    updateAePhysicians: (deltaObj: AEPhysicianMaintenanceDelta, history: History<any>) => {
        return ComplexApiAction
            .fromAction(masterCrudCreators.update({ crudId: CrudTypes.mctiAEOtherPhysician, data: deltaObj }))
            .withNoThunk()
            .thenRedirect("/LandingPage", history)
            .finish();
    },

    //Limited physicians
    limitPhysians: (fieldInfo: limitPhysians) => createDataAction('LIMIT_PHYSIANS', fieldInfo)
    //....
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];


function upsertAEPhysician(array: AE_Physician[], item: AE_Physician, delta: boolean = true) {
    let newArray = array.slice();
    var oldItem = newArray.find(obj => obj['@ID'] === item['@ID']);

    if (!oldItem) {
        newArray.splice(array.length, 0, JSON.parse(JSON.stringify(item)));
    } else {
        oldItem['@ID'] = item['@ID'];
        oldItem['@First'] = item['@First'];
        oldItem['@MI'] = item['@MI'];
        oldItem['@Last'] = item['@Last'];
        oldItem['@Suffix'] = item['@Suffix'];
        oldItem['@TaxID'] = item['@TaxID'];
        oldItem['@FaxNo'] = item['@FaxNo'];
        oldItem['@IDType'] = item['@IDType'];
        oldItem['@NPI'] = item['@NPI'];
        oldItem['@Taxonomy'] = item['@Taxonomy'];
        oldItem['@FormType'] = item['@FormType'];
        oldItem['@CustomerKey'] = item['@CustomerKey'];
        if (item['@Delete'] === 'true') {
            if (!delta || (delta && oldItem['@ID'].indexOf("#") == -1))
                oldItem['@Delete'] = item['@Delete'];
            else {
                // don't mark as deleted if it was just added, remove it
                newArray = newArray.filter(obj => obj['@ID'] !== item['@ID']);
            }
        }
    }

    return newArray;
}

function upsertLobDelta(deltaPhysicans: AE_Physician[], aePhysician: AE_Physician, lob: AE_LOB) {
    // check the list, if it's there just add our LOB
    let newArray = deltaPhysicans.slice();
    var oldItem = newArray.find(obj => obj['@ID'] === aePhysician['@ID']);
    // if it's there, check for it and add it if not, or overwrite
    if (oldItem) {
        if (!oldItem.LOB)
            oldItem.LOB = [lob];
        else {
            let oldLob = oldItem.LOB.find(x => x["@ID"] === lob["@ID"]);
            if (oldLob) {
                oldLob["@ID"] = lob["@ID"];
                oldLob["@PhysicianNo"] = lob["@PhysicianNo"];
                oldLob["@QC"] = lob["@QC"];
                ///// to update Lob and payer
                oldLob['@LOB'] = lob['@LOB'];
                oldLob['@Payer'] = lob['@Payer'];
                //...
                oldLob["@NPI"] = lob["@NPI"];
                oldLob["@Taxonomy"] = lob["@Taxonomy"];
                if (lob["@Delete"] === 'true') {
                    // don't mark as deleted if it was just added, remove it
                    if (oldLob["@ID"].indexOf("#") == -1)
                        oldLob["@Delete"] = 'true';
                    else
                        oldItem.LOB = oldItem.LOB.filter(obj => obj['@ID'] !== lob['@ID']);
                }
            }
            else
                oldItem.LOB.push(lob);
        }
    }
    else {
        let newPhys = JSON.parse(JSON.stringify(aePhysician));
        newPhys.LOB = JSON.parse(JSON.stringify([lob]));
        newArray.splice(newArray.length, 0, JSON.parse(JSON.stringify(newPhys)));
    }
    return newArray;
}

function upsertAEPhysicianADD(array: AE_Physician[], item: AE_Physician, arrayADD: AE_LOB[]) {
    let newArray = array.slice();
    item.LOB = arrayADD;

    var oldItem = newArray.find(obj => obj['@ID'] === item['@ID']);
    if (!oldItem) {
        newArray.splice(array.length, 0, JSON.parse(JSON.stringify(item)));
    } else {
        oldItem['@ID'] = item['@ID'];
        oldItem['@First'] = item['@First'];
        oldItem['@MI'] = item['@MI'];
        oldItem['@Last'] = item['@Last'];
        oldItem['@Suffix'] = item['@Suffix'];
        oldItem['@TaxID'] = item['@TaxID'];
        oldItem['@FaxNo'] = item['@FaxNo'];
        oldItem['@IDType'] = item['@IDType'];
        oldItem['@NPI'] = item['@NPI'];
        oldItem['@Taxonomy'] = item['@Taxonomy'];
        oldItem['@FormType'] = item['@FormType'];
        oldItem['@CustomerKey'] = item['@CustomerKey'];
        oldItem.LOB = item.LOB;
    }
    return newArray;
}

function upsertLOBAEPhysician(array: AE_LOB[], item: AE_LOB) {
    let newArray = array.slice();
    var oldItem = newArray.find(obj => obj['@ID'] === item['@ID']);
    if (!oldItem) {
        newArray.splice(array.length, 0, JSON.parse(JSON.stringify(item)));
    } else {

        oldItem['@ID'] = item['@ID'];
        oldItem['@PhysicianNo'] = item['@PhysicianNo'];
        oldItem['@QC'] = item['@QC'];
        oldItem['@LOB'] = item['@LOB'];
        oldItem['@Payer'] = item['@Payer'];
        oldItem['@NPI'] = item['@NPI'];
        oldItem['@Taxonomy'] = item['@Taxonomy'];
    }

    return newArray;
}

function removeAEPhysician(array: AE_Physician[], item: AE_Physician) {
    let newArray = array.slice();
    var oldItem = newArray.filter(obj => obj['@ID'] !== item['@ID']);
    if (!oldItem) {
        console.error('attempt to remove an invalid entry ' + item['@ID'] + ' ' + item['@First']);
    }
    return oldItem;
}

function removeLOBAEPhysician(array: AE_LOB[], item: AE_LOB) {
    let newArray = array.slice();
    var oldItem = newArray.filter(obj => obj['@ID'] !== item['@ID']);
    if (!oldItem) {
        console.error('attempt to remove an invalid entry ' + item['@ID'] + ' ' + item['@PhysicianNo']);
    }
    return oldItem;
}

function performTxtSearch(searchData: ItxtSearch) {
    let results: AE_Physician[] = [];
    results = searchData.resultData.filter((item) => { return item["@Last"].startsWith(searchData.searchData) });
    return results;
}

function limitedPhysicians(physicians: limitPhysians) {
    let results: AE_Physician[] = [];
    results = physicians.SearchData
    return results
}

function findTheIndex(arrayToDoSearch: AE_Physician[], textToFind: string): number {
    if (textToFind.length === 1) {
        return 0
    }
    if (arrayToDoSearch.findIndex(item => (item["@Last"].toString() + item["@First"].toString()).startsWith(textToFind)) != -1) {
        return arrayToDoSearch.findIndex(item => (item["@Last"].toString() + item["@First"].toString()).startsWith(textToFind))
    } else {
        const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        if (textToFind.slice(-1) === "Z") {
            if (textToFind.length === 2) {
                let first = textToFind.charAt(0).replace(/ /g, "")
                let indexFirst = alphabet.indexOf(first)
                let newWord = alphabet[indexFirst + 1] + "A"

                return findTheIndex(arrayToDoSearch, newWord)
            } else {
                let secondLast = textToFind.charAt(textToFind.length - 2).replace(/ /g, "")
                let tempIndex = alphabet.indexOf(secondLast)
                let newWord = textToFind.substring(0, textToFind.length - 2) + alphabet[tempIndex + 1]

                return findTheIndex(arrayToDoSearch, newWord)
            }
        } else {
            let lastChart = textToFind.charAt(textToFind.length - 1).replace(/ /g, "")
            let tempIndex = alphabet.indexOf(lastChart)
            let newWord = textToFind.substring(0, textToFind.length - 1) + alphabet[tempIndex + 1]

            return findTheIndex(arrayToDoSearch, newWord)
        }
    }
}

function findTheIndexLast(arrayToDoSearch: AE_Physician[], textToFind: string): number {
    if (textToFind.length === 1) {
        return 0
    }
    if (arrayToDoSearch.findIndex(item => (item["@Last"].toString() + item["@First"].toString()).startsWith(textToFind)) != -1) {
        return arrayToDoSearch.findIndex(item => (item["@Last"].toString() + item["@First"].toString()).startsWith(textToFind))

    } else {
        const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
        if (textToFind.slice(-1) === "A") {
            if (textToFind.length === 2) {
                let first = textToFind.charAt(0).replace(/ /g, "")

                if (first === "A") {
                    let newWord = "A"
                    return findTheIndexLast(arrayToDoSearch, newWord)
                }
                let indexFirst = alphabet.indexOf(first)
                let newWord = alphabet[indexFirst - 1] + "Z"

                return findTheIndexLast(arrayToDoSearch, newWord)
            } else {

                let secondLast = textToFind.charAt(textToFind.length - 2).replace(/ /g, "")
                if (secondLast === "A") {
                    let newWord = textToFind.substring(0, textToFind.length - 1)
                    return findTheIndexLast(arrayToDoSearch, newWord)
                } else {
                    let tempIndex = alphabet.indexOf(secondLast)
                    let newWord = textToFind.substring(0, textToFind.length - 2) + alphabet[tempIndex - 1]

                    return findTheIndexLast(arrayToDoSearch, newWord)
                }
            }
        } else {

            let lastChart = textToFind.charAt(textToFind.length - 1).replace(/ /g, "")
            let tempIndex = alphabet.indexOf(lastChart)
            let newWord = textToFind.substring(0, textToFind.length - 1) + alphabet[tempIndex - 1]

            return findTheIndexLast(arrayToDoSearch, newWord)
        }

    }
}


function performSearch(search: IAEPhysicianSearch) {
    let results: AE_Physician[] = [];
    var SearchFrom: any;
    var SearchTo: AE_Physician[] = [];;
    var resultSearch: any;


    //Begins with & All Physicians
    if (search.SearchFields == "1" && search.SearchType == "0" && search.SearchForm == "0" && search.SearchFrom != "" && search.SearchTo != "") {
        results = []
        const searchFromletter = search.SearchFrom.charCodeAt(0)
        const searchToletter = search.SearchTo.charCodeAt(0)

        if (searchFromletter > searchToletter) {
            return results
        } else {
            var SearchTo = search.SearchData.filter((item) => {
                let searchedLetter = (item["@Last"].toString() + item["@First"].toString()).charCodeAt(0)
                return searchedLetter <= searchToletter && searchedLetter >= searchFromletter
            })

            let spaceRemover = SearchTo.map((item) => {
                let lastName: string = (item["@Last"].toString()).replace(/ +/g, "").replace(/ /g, "")
                let firstName: string = (item["@Last"].toString()).replace(/ +/g, "").replace(/ /g, "")
                return {
                    ...item,
                    "@First": firstName,
                    "@Last": lastName,
                }
            })

            results = [...SearchTo]

            const reverseResults = spaceRemover.reverse()
            let reverseIndexTo = findTheIndexLast(reverseResults, search.SearchTo.replace(/ /g, ""))
            results = results.slice(0, SearchTo.length - reverseIndexTo)

            let indexFrom = findTheIndex(results, search.SearchFrom.replace(/ /g, ""))
            results = results.slice(indexFrom, results.length)
        }
        //
    }
    else if (search.SearchFields == "1" && search.SearchType == "0" && search.SearchForm == "0") {
        results = search.SearchData.filter((item) => {
            return (item["@Last"].toString() + item["@First"].toString()).startsWith(search.SearchFrom.replace(/ /g, ""))
        });
    }
    if (search.SearchFields == "2" && search.SearchType == "0" && search.SearchForm == "0") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            search.SearchData.map(item => {
                let resultsLOB = []
                resultsLOB = item.LOB.filter((itemLOB) => {
                    return itemLOB["@PhysicianNo"].toString().startsWith(search.SearchFrom)
                })
                if (resultsLOB.length >= 1) {
                    results.push(item)
                }
            })
        } else {

            //....
            let resultsToFilter = search.SearchData.filter((item) => {
                return item["LOB"].length > 0
            });

            const searchFromletter = search.SearchFrom.charCodeAt(0)
            const searchToletter = search.SearchTo.charCodeAt(0)

            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            resultsToFilter.map(item => {
                let resultsLOB = []

                item.LOB.map(item => {

                    if (searchFromletter >= 65 && searchFromletter <= 90) {
                        for (var i = searchFromletter; i <= searchToletter; i++) {
                            let elementToAdd = String.fromCharCode(i)
                            if ((item["@PhysicianNo"].toString()).startsWith(elementToAdd)) {
                                resultsLOB.push(item)
                            }
                        }
                    } else {
                        for (var i = numberFrom; i <= numberTo; i++) {
                            let elementToAdd = i.toString()
                            if ((item["@PhysicianNo"].toString()).startsWith(elementToAdd)) {
                                resultsLOB.push(item)
                            }
                        }
                    }
                })

                if (resultsLOB.length >= 1) {
                    results.push(item)
                }
            })
        }
        //
    }
    if (search.SearchFields == "4" && search.SearchType == "0" && search.SearchForm == "0") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            results = search.SearchData.filter((item) => {
                return item["@NPI"] === (search.SearchFrom)
            });
        } else {
            //
            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            let NpiResults: AE_Physician[] = []

            let resultsToFilter = search.SearchData.filter((item) => {
                return item["@NPI"].length > 0
            });

            for (var i = numberFrom; i <= numberTo; i++) {
                let elementToAdd = i.toString()
                resultSearch = resultsToFilter.filter((item) => {
                    return (item["@NPI"].toString()).startsWith(elementToAdd)
                });

                NpiResults = [...NpiResults, ...resultSearch]
            }

            NpiResults.sort((a, b) => a["@Last"].localeCompare(b["@Last"], 'en', { numeric: true }))

            results = [...NpiResults]
        }
        //
    }
    if (search.SearchFields == "8" && search.SearchType == "0" && search.SearchForm == "0") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            results = search.SearchData.filter((item) => {
                return item["@Taxonomy"] === (search.SearchFrom)
            });

        } else { //
            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            let taxonmyResults: AE_Physician[] = []

            let resultsToFilter = search.SearchData.filter((item) => {
                return item["@Taxonomy"].length > 0
            });

            for (var i = numberFrom; i <= numberTo; i++) {
                let elementToAdd = i.toString()
                resultSearch = resultsToFilter.filter((item) => {
                    return (item["@Taxonomy"].toString()).startsWith(elementToAdd)
                });

                taxonmyResults = [...taxonmyResults, ...resultSearch]
            }

            taxonmyResults.sort((a, b) => a["@Last"].localeCompare(b["@Last"], 'en', { numeric: true }))

            results = [...taxonmyResults]
        }

        //

    }
    if (search.SearchFields == "16" && search.SearchType == "0" && search.SearchForm == "0") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            results = search.SearchData.filter((item) => {
                return item["@TaxID"] === (search.SearchFrom)
            });
        } else {
            //
            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            let taxResults: AE_Physician[] = []

            let resultsToFilter = search.SearchData.filter((item) => {
                return item["@TaxID"].length > 0
            });

            for (var i = numberFrom; i <= numberTo; i++) {
                let elementToAdd = i.toString()
                resultSearch = resultsToFilter.filter((item) => {
                    return (item["@TaxID"].toString()).startsWith(elementToAdd)
                });

                taxResults = [...taxResults, ...resultSearch]
            }

            taxResults.sort((a, b) => a["@Last"].localeCompare(b["@Last"], 'en', { numeric: true }))

            results = [...taxResults]
        }
        //
    }

    //Equals & All Physicians
    if (search.SearchFields == "1" && search.SearchType == "1" && search.SearchForm == "0") {
        results = search.SearchData.filter((item) => {
            return (item["@Last"].toString() + item["@First"].toString()) == search.SearchFrom.replace(/ /g, "")
        });
    }
    if (search.SearchFields == "2" && search.SearchType == "1" && search.SearchForm == "0") {
        search.SearchData.map(item => {
            let resultsLOB = []
            resultsLOB = item.LOB.filter((itemLOB) => {
                return itemLOB["@PhysicianNo"].toString().startsWith(search.SearchFrom)
            })
            if (resultsLOB.length >= 1) {
                results.push(item)
            }
        })
    }
    if (search.SearchFields == "4" && search.SearchType == "1" && search.SearchForm == "0") {
        results = search.SearchData.filter((item) => {
            return item["@NPI"] === (search.SearchFrom)
        });
    }
    if (search.SearchFields == "8" && search.SearchType == "1" && search.SearchForm == "0") {
        results = search.SearchData.filter((item) => {
            return item["@Taxonomy"] === (search.SearchFrom)
        });
    }
    if (search.SearchFields == "16" && search.SearchType == "1" && search.SearchForm == "0") {
        results = search.SearchData.filter((item) => {
            return item["@TaxID"] === (search.SearchFrom)
        });
    }

    //BeginsWith & 1500
    if (search.SearchFields == "1" && search.SearchType == "0" && search.SearchForm == "1001" && search.SearchFrom != "" && search.SearchTo != "") {

        //
        results = []
        const searchFromletter = search.SearchFrom.charCodeAt(0)
        const searchToletter = search.SearchTo.charCodeAt(0)

        if (searchFromletter > searchToletter) {
            return results
        } else {

            var SearchTo = search.SearchData.filter((item) => {
                let searchedLetter = (item["@Last"].toString() + item["@First"].toString()).charCodeAt(0)
                return searchedLetter <= searchToletter && searchedLetter >= searchFromletter
            })


            let filterSearch = SearchTo.filter((item: any) => { return (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0") })

            let spaceRemover = filterSearch.map((item) => {
                let lastName: string = (item["@Last"].toString()).replace(/ +/g, "").replace(/ /g, "")
                let firstName: string = (item["@Last"].toString()).replace(/ +/g, "").replace(/ /g, "")
                return {
                    ...item,
                    "@First": firstName,
                    "@Last": lastName,
                }
            })


            results = [...filterSearch]

            const reverseResults = spaceRemover.reverse()
            let reverseIndexTo = findTheIndexLast(reverseResults, search.SearchTo.replace(/ /g, ""))
            results = filterSearch.slice(0, results.length - reverseIndexTo)

            let indexFrom = findTheIndex(results, search.SearchFrom.replace(/ /g, ""))
            results = results.slice(indexFrom, results.length)
        }
        //

    }
    else if (search.SearchFields == "1" && search.SearchType == "0" && search.SearchForm == "1001") {
        results = search.SearchData.filter((item) => {
            return (((item["@Last"].toString() + item["@First"].toString()).startsWith(search.SearchFrom.replace(/ /g, ""))) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }
    if (search.SearchFields == "2" && search.SearchType == "0" && search.SearchForm == "1001") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            search.SearchData.map(item => {
                let resultsLOB = []
                resultsLOB = item.LOB.filter((itemLOB) => {
                    return (((itemLOB["@PhysicianNo"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
                })
                if (resultsLOB.length >= 1) {
                    results.push(item)
                }
            })
        } else {
            //....
            let resultsToFilter = search.SearchData.filter((item) => {
                return ((item["LOB"].length > 0) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });

            const searchFromletter = search.SearchFrom.charCodeAt(0)
            const searchToletter = search.SearchTo.charCodeAt(0)

            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            resultsToFilter.map(item => {
                let resultsLOB = []

                item.LOB.map(item => {

                    if (searchFromletter >= 65 && searchFromletter <= 90) {
                        for (var i = searchFromletter; i <= searchToletter; i++) {
                            let elementToAdd = String.fromCharCode(i)
                            if ((item["@PhysicianNo"].toString()).startsWith(elementToAdd)) {
                                resultsLOB.push(item)
                            }
                        }
                    } else {
                        for (var i = numberFrom; i <= numberTo; i++) {
                            let elementToAdd = i.toString()
                            if ((item["@PhysicianNo"].toString()).startsWith(elementToAdd)) {
                                resultsLOB.push(item)
                            }
                        }
                    }
                })

                if (resultsLOB.length >= 1) {
                    results.push(item)
                }
            })
        }
        //...
    }
    if (search.SearchFields == "4" && search.SearchType == "0" && search.SearchForm == "1001") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            results = search.SearchData.filter((item) => {
                return (((item["@NPI"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });
        } else {
            //
            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            let NpiResults: AE_Physician[] = []

            let resultsToFilter = search.SearchData.filter((item) => {
                return ((item["@NPI"].length > 0) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });

            for (var i = numberFrom; i <= numberTo; i++) {
                let elementToAdd = i.toString()
                resultSearch = resultsToFilter.filter((item) => {
                    return (item["@NPI"].toString()).startsWith(elementToAdd)
                });

                NpiResults = [...NpiResults, ...resultSearch]
            }

            NpiResults.sort((a, b) => a["@Last"].localeCompare(b["@Last"], 'en', { numeric: true }))

            results = [...NpiResults]
        }
        //
    }
    if (search.SearchFields == "8" && search.SearchType == "0" && search.SearchForm == "1001") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            results = search.SearchData.filter((item) => {
                return (((item["@Taxonomy"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });
        } else {
            //
            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            let taxonmyResults: AE_Physician[] = []

            let resultsToFilter = search.SearchData.filter((item) => {
                return ((item["@Taxonomy"].length > 0) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });

            for (var i = numberFrom; i <= numberTo; i++) {
                let elementToAdd = i.toString()
                resultSearch = resultsToFilter.filter((item) => {
                    return (item["@Taxonomy"].toString()).startsWith(elementToAdd)
                });

                taxonmyResults = [...taxonmyResults, ...resultSearch]
            }

            taxonmyResults.sort((a, b) => a["@Last"].localeCompare(b["@Last"], 'en', { numeric: true }))

            results = [...taxonmyResults]
        }
        //

    }
    if (search.SearchFields == "16" && search.SearchType == "0" && search.SearchForm == "1001") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            results = search.SearchData.filter((item) => {
                return (((item["@TaxID"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });
        } else {
            //
            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            let taxResults: AE_Physician[] = []

            let resultsToFilter = search.SearchData.filter((item) => {
                return ((item["@TaxID"].length > 0) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });

            for (var i = numberFrom; i <= numberTo; i++) {
                let elementToAdd = i.toString()
                resultSearch = resultsToFilter.filter((item) => {
                    return (item["@TaxID"].toString()).startsWith(elementToAdd)
                });

                taxResults = [...taxResults, ...resultSearch]
            }

            taxResults.sort((a, b) => a["@Last"].localeCompare(b["@Last"], 'en', { numeric: true }))

            results = [...taxResults]
        }

        //
    }

    //Equals & 1500
    if (search.SearchFields == "1" && search.SearchType == "1" && search.SearchForm == "1001") {
        results = search.SearchData.filter((item) => {
            return (((item["@Last"].toString() + item["@First"].toString()) == (search.SearchFrom.replace(/ /g, ""))) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }
    if (search.SearchFields == "2" && search.SearchType == "1" && search.SearchForm == "1001") {
        search.SearchData.map(item => {
            let resultsLOB = []
            resultsLOB = item.LOB.filter((itemLOB) => {
                return (((itemLOB["@PhysicianNo"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            })
            if (resultsLOB.length >= 1) {
                results.push(item)
            }
        })
    }
    if (search.SearchFields == "4" && search.SearchType == "1" && search.SearchForm == "1001") {
        results = search.SearchData.filter((item) => {
            return (((item["@NPI"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }
    if (search.SearchFields == "8" && search.SearchType == "1" && search.SearchForm == "1001") {
        results = search.SearchData.filter((item) => {
            return (((item["@Taxonomy"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }
    if (search.SearchFields == "16" && search.SearchType == "1" && search.SearchForm == "1001") {
        results = search.SearchData.filter((item) => {
            return (((item["@TaxID"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }

    //BeginsWith & UB
    if (search.SearchFields == "1" && search.SearchType == "0" && search.SearchForm == "1011" && search.SearchFrom != "" && search.SearchTo != "") {

        results = []
        const searchFromletter = search.SearchFrom.charCodeAt(0)
        const searchToletter = search.SearchTo.charCodeAt(0)

        if (searchFromletter > searchToletter) {
            return results
        } else {

            var SearchTo = search.SearchData.filter((item) => {
                let searchedLetter = (item["@Last"].toString() + item["@First"].toString()).charCodeAt(0)
                return searchedLetter <= searchToletter && searchedLetter >= searchFromletter
            })


            let filterSearch = SearchTo.filter((item: any) => { return (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0") })

            let spaceRemover = filterSearch.map((item) => {
                let lastName: string = (item["@Last"].toString()).replace(/ +/g, "").replace(/ /g, "")
                let firstName: string = (item["@Last"].toString()).replace(/ +/g, "").replace(/ /g, "")
                return {
                    ...item,
                    "@First": firstName,
                    "@Last": lastName,
                }
            })


            results = [...filterSearch]

            const reverseResults = spaceRemover.reverse()
            let reverseIndexTo = findTheIndexLast(reverseResults, search.SearchTo.replace(/ /g, ""))
            results = filterSearch.slice(0, results.length - reverseIndexTo)

            let indexFrom = findTheIndex(results, search.SearchFrom.replace(/ /g, ""))
            results = results.slice(indexFrom, results.length)
        }
        //
    }
    else if (search.SearchFields == "1" && search.SearchType == "0" && search.SearchForm == "1011") {
        results = search.SearchData.filter((item) => {
            return (((item["@Last"].toString() + item["@First"].toString()).startsWith(search.SearchFrom.replace(/ /g, ""))) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }
    if (search.SearchFields == "2" && search.SearchType == "0" && search.SearchForm == "1011") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            search.SearchData.map(item => {
                let resultsLOB = []
                resultsLOB = item.LOB.filter((itemLOB) => {
                    return (((itemLOB["@PhysicianNo"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
                })
                if (resultsLOB.length >= 1) {
                    results.push(item)
                }
            })
        } else {
            //....
            let resultsToFilter = search.SearchData.filter((item) => {
                return ((item["LOB"].length > 0) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });

            const searchFromletter = search.SearchFrom.charCodeAt(0)
            const searchToletter = search.SearchTo.charCodeAt(0)

            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            resultsToFilter.map(item => {
                let resultsLOB = []

                item.LOB.map(item => {

                    if (searchFromletter >= 65 && searchFromletter <= 90) {
                        for (var i = searchFromletter; i <= searchToletter; i++) {
                            let elementToAdd = String.fromCharCode(i)
                            if ((item["@PhysicianNo"].toString()).startsWith(elementToAdd)) {
                                resultsLOB.push(item)
                            }
                        }
                    } else {
                        for (var i = numberFrom; i <= numberTo; i++) {
                            let elementToAdd = i.toString()
                            if ((item["@PhysicianNo"].toString()).startsWith(elementToAdd)) {
                                resultsLOB.push(item)
                            }
                        }
                    }
                })

                if (resultsLOB.length >= 1) {
                    results.push(item)
                }
            })
        }
        //...

    }
    if (search.SearchFields == "4" && search.SearchType == "0" && search.SearchForm == "1011") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            results = search.SearchData.filter((item) => {
                return (((item["@NPI"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });
        } else {
            //
            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            let NpiResults: AE_Physician[] = []

            let resultsToFilter = search.SearchData.filter((item) => {
                return ((item["@NPI"].length > 0) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });

            for (var i = numberFrom; i <= numberTo; i++) {
                let elementToAdd = i.toString()
                resultSearch = resultsToFilter.filter((item) => {
                    return (item["@NPI"].toString()).startsWith(elementToAdd)
                });

                NpiResults = [...NpiResults, ...resultSearch]
            }

            NpiResults.sort((a, b) => a["@Last"].localeCompare(b["@Last"], 'en', { numeric: true }))

            results = [...NpiResults]
        }
        //
    }
    if (search.SearchFields == "8" && search.SearchType == "0" && search.SearchForm == "1011") {
        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            results = search.SearchData.filter((item) => {
                return (((item["@Taxonomy"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });
        } else {
            //
            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            let taxonmyResults: AE_Physician[] = []

            let resultsToFilter = search.SearchData.filter((item) => {
                return ((item["@Taxonomy"].length > 0) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });

            for (var i = numberFrom; i <= numberTo; i++) {
                let elementToAdd = i.toString()
                resultSearch = resultsToFilter.filter((item) => {
                    return (item["@Taxonomy"].toString()).startsWith(elementToAdd)
                });

                taxonmyResults = [...taxonmyResults, ...resultSearch]
            }

            taxonmyResults.sort((a, b) => a["@Last"].localeCompare(b["@Last"], 'en', { numeric: true }))

            results = [...taxonmyResults]
        }
        //

    }
    if (search.SearchFields == "16" && search.SearchType == "0" && search.SearchForm == "1011") {

        if (search.SearchFrom === search.SearchTo || search.SearchTo === "") {
            results = search.SearchData.filter((item) => {
                return (((item["@TaxID"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });
        } else {
            //
            const numberFrom = parseInt(search.SearchFrom)
            const numberTo = parseInt(search.SearchTo)

            let taxResults: AE_Physician[] = []

            let resultsToFilter = search.SearchData.filter((item) => {
                return ((item["@TaxID"].length > 0) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            });

            for (var i = numberFrom; i <= numberTo; i++) {
                let elementToAdd = i.toString()
                resultSearch = resultsToFilter.filter((item) => {
                    return (item["@TaxID"].toString()).startsWith(elementToAdd)
                });

                taxResults = [...taxResults, ...resultSearch]
            }

            taxResults.sort((a, b) => a["@Last"].localeCompare(b["@Last"], 'en', { numeric: true }))

            results = [...taxResults]
        }

        //
    }
    //Equals & UB
    if (search.SearchFields == "1" && search.SearchType == "1" && search.SearchForm == "1011") {
        results = search.SearchData.filter((item) => {
            return (((item["@Last"].toString() + item["@First"].toString()) == (search.SearchFrom.replace(/ /g, ""))) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }
    if (search.SearchFields == "2" && search.SearchType == "1" && search.SearchForm == "1011") {
        search.SearchData.map(item => {
            let resultsLOB = []
            resultsLOB = item.LOB.filter((itemLOB) => {
                return (((itemLOB["@PhysicianNo"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
            })
            if (resultsLOB.length >= 1) {
                results.push(item)
            }
        })
    }
    if (search.SearchFields == "4" && search.SearchType == "1" && search.SearchForm == "1011") {
        results = search.SearchData.filter((item) => {
            return (((item["@NPI"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }
    if (search.SearchFields == "8" && search.SearchType == "1" && search.SearchForm == "1011") {
        results = search.SearchData.filter((item) => {
            return (((item["@Taxonomy"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }
    if (search.SearchFields == "16" && search.SearchType == "1" && search.SearchForm == "1011") {
        results = search.SearchData.filter((item) => {
            return (((item["@TaxID"].toString()) == (search.SearchFrom)) && (item["@FormType"] == (search.SearchForm) || item["@FormType"] == "0"))
        });
    }
    return results;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IAEPhysicianMaintenanceUIState, KnownActions> = (state: IAEPhysicianMaintenanceUIState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {

            case 'IS_UPDATE':
                return {
                    ...state,
                    changed: false
                }
                break;

            case 'IS_LEAVE':
                return {
                    ...state,
                    leave: action.data
                }
                break;

            case "SET_PAYER_LOB_ID":
                return {
                    ...state,
                    payerLOBID: action.data
                }
                break;

            case "IS_LOBMODEL_OPEN":
                return {
                    ...state,
                    isLOBModelOpen: action.data
                }
                break;

            case 'MAIN_SEARCH':
                let result = performSearch(action.data)
                return {
                    ...state,
                    searchResults: result,
                    selectedItem: emptyItem,
                    selectedLOBID: emptyItemLOB
                }
                break;
            //Limited Physicians
            case "LIMIT_PHYSIANS":
                let results = limitedPhysicians(action.data)
                return {
                    ...state,
                    searchResults: results
                }
                break;
            //Limited Physicians above
            case 'TXT_SEARCH':
                let result1 = performTxtSearch(action.data)
                return {
                    ...state,
                    searchResults: result1
                }
                break;

            case 'SELECT_AEPHYSICIAN_TAB_INDEX':
                return {
                    ...state,
                    selectedTabIndex: action.data,
                }
                break;

            case 'SEARCH_AEPHYSICIAN_RESULTS':
                return {
                    ...state,
                    searchResults: action.data,
                }
                break;

            case 'SEARCH_AEPHYSICIAN_PAYERSPECIFICID':
                return {
                    ...state,
                    searchLOBResults: action.data,
                }
                break;
            case 'UPDATE_AEPHYSICIAN_SEARCHFIELDS':
                return {
                    ...state,
                    cboSearchFields: action.data,
                }
                break;
            case 'UPDATE_AEPHYSICIAN_SEARCHTYPE':
                return {
                    ...state,
                    searchType: action.data,
                }
                break;
            case 'UPDATE_AEPHYSICIAN_SEARCHFROM':
                return {
                    ...state,
                    searchFrom: action.data,
                }
                break;
            case 'UPDATE_AEPHYSICIAN_SEARCHTO':
                return {
                    ...state,
                    searchTo: action.data,
                }
                break;
            case 'UPDATE_AEPHYSICIAN_SEARCHFORM':
                return {
                    ...state,
                    searchForm: action.data,
                }
                break;

            case 'SEARCH_SELECTED_ITEM':
                return {
                    ...state,
                    selectedItem: action.data == undefined ? emptyItem : action.data,
                    selectedLOBID: emptyItemLOB,
                    lastNameError: '',
                    firstNameError: '',
                    faxNoError: '',
                    nbiError: '',
                    taxIDError: '',
                    taxonomyError: '',
                }
                break;
            case 'SEARCH_LOB_SELECTED_ITEM':
                return {
                    ...state,
                    selectedLOBID: action.data == undefined ? emptyItemLOB : action.data,
                    physicianNoError: '',
                    nbiLOBError: '',
                    taxonomyLOBError: '',
                }
                break;

            case 'SELECT_AEPHYSICIAN':
                if (action.data.masterCrud) {
                    var data = action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    return {
                        ...state,
                        selectedAEPhysicianMaintenance: action.data.uiData,
                        selectedItem: data !== undefined ? JSON.parse(JSON.stringify(data)) : JSON.parse(JSON.stringify(emptyItem)),
                        changed: true,
                        aePhysicianMaintenanceAlert: undefined,

                        lastNameError: '',
                        firstNameError: '',
                        faxNoError: '',
                        nbiError: '',
                        taxIDError: '',
                        taxonomyError: '',
                    }
                }
                break;

            case 'SELECT_LOBAEPHYSICIAN':
                if (action.data.masterCrud) {
                    var data = action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    return {
                        ...state,
                        selectedAEPhysicianMaintenance: action.data.uiData,
                        selectedLOBID: data !== undefined ? JSON.parse(JSON.stringify(data)) : JSON.parse(JSON.stringify(emptyItemLOB)),
                        changed: true,
                        aePhysicianMaintenanceAlert: undefined,
                        physicianNoError: '',
                        nbiLOBError: '',
                        taxonomyLOBError: ''
                    }
                }
                break;

            case 'ADD_AEPHYSICIAN':
                if (action.data.masterCrud && action.data.masterCrud.AEPhysicianMaintenanceInfo) {

                    let newItem: AE_Physician = {
                        ...state.selectedItem,
                        '@ID': action.data.uiData.id
                    };

                    action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician =
                        upsertAEPhysician(action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician, newItem);
                    // keep delta crud updated
                    state.deltaCrud.Physicians.Physician = upsertAEPhysician(state.deltaCrud.Physicians.Physician, newItem);

                    let resultAEPhysician: AE_Physician[] = [newItem];
                    let resultAEPhysicianSearchResults = [...state.searchResults, ...resultAEPhysician];

                    return {
                        ...state,
                        selectedAEPhysicianMaintenance: {
                            id: action.data.uiData.id,
                            text: state.selectedItem["@First"]
                        },
                        selectedItem: {
                            ...state.selectedItem,
                            '@ID': action.data.uiData.id
                        },
                        changed: true,
                        searchResults: resultAEPhysicianSearchResults,
                        lastNameError: '',
                        firstNameError: '',
                        faxNoError: '',
                        nbiError: '',
                        taxIDError: '',
                        taxonomyError: '',
                    }
                }
                break;

            case 'ADD_LOBAEPHYSICIAN':
                if (action.data.masterCrud && action.data.masterCrud.AEPhysicianMaintenanceInfo) {
                    let newItem: AE_LOB = {
                        ...state.selectedLOBID,
                        '@ID': state.payerLOBID
                    };

                    let obj: AE_Physician[];

                    obj = action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician.filter(item => {
                        return item["@ID"] === action.data.uiData.id
                    })

                    let resultLOB: AE_LOB[] = upsertLOBAEPhysician(obj != undefined && obj[0] != undefined && obj[0].LOB != undefined ? obj[0].LOB : [], newItem);

                    action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician =
                        upsertAEPhysicianADD(action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician, state.selectedItem, resultLOB);
                    let resultLOBSelectedItem = { ...state.selectedItem, LOB: resultLOB }

                    let iIndex: number = 0;

                    for (var i = 0; i < state.searchResults.length; i++) {
                        let item = state.searchResults[i];
                        if (action.data.uiData.id == item["@ID"]) {
                            break;
                        }
                        iIndex++;
                    }

                    let resultAEPhysicianLOB = [...state.searchResults];
                    resultAEPhysicianLOB[iIndex] = resultLOBSelectedItem;

                    // keep delta crud updated for lobs
                    state.deltaCrud.Physicians.Physician =
                        upsertLobDelta(state.deltaCrud.Physicians.Physician, resultLOBSelectedItem, newItem)

                    return {
                        ...state,
                        selectedAEPhysicianMaintenance: {
                            id: action.data.uiData.id,
                            text: state.selectedLOBID["@PhysicianNo"]
                        },
                        selectedLOBID: {
                            ...state.selectedLOBID,
                            '@ID': state.payerLOBID
                        },
                        searchResults: resultAEPhysicianLOB,
                        selectedItem: resultLOBSelectedItem,
                        changed: true,
                        searchLOBResults: resultLOB,
                        physicianNoError: '',
                        nbiLOBError: '',
                        taxonomyLOBError: '',
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN':
                if (action.data.masterCrud && action.data.masterCrud.AEPhysicianMaintenanceInfo) {
                    let newItem: AE_Physician = {
                        ...state.selectedItem,
                        '@ID': action.data.uiData.id
                    };
                    action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician =
                        upsertAEPhysician(action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician, newItem);
                    //keep delta crud updated for phys
                    state.deltaCrud.Physicians.Physician = upsertAEPhysician(state.deltaCrud.Physicians.Physician, newItem, true);

                    let iIndex: number = 0;

                    for (var i = 0; i < state.searchResults.length; i++) {
                        let item = state.searchResults[i];
                        if (action.data.uiData.id == item["@ID"]) {
                            break;
                        }
                        iIndex++;
                    }

                    let resultAEPhysicianUpdate = [...state.searchResults];
                    resultAEPhysicianUpdate[iIndex] = {
                        ...state.selectedItem,
                        '@ID': action.data.uiData.id
                    };

                    return {
                        ...state,
                        selectedAEPhysicianMaintenance: {
                            id: action.data.uiData.id,
                            text: state.selectedItem["@First"]
                        },
                        selectedItem: newItem,
                        searchResults: resultAEPhysicianUpdate,
                        changed: true,
                        lastNameError: '',
                        firstNameError: '',
                        faxNoError: '',
                        nbiError: '',
                        taxIDError: '',
                        taxonomyError: '',
                    }
                }
                break;

            case 'UPDATE_LOBAEPHYSICIAN':
                if (action.data.masterCrud && action.data.masterCrud.AEPhysicianMaintenanceInfo) {
                    let newItem: AE_LOB = {
                        ...state.selectedLOBID,
                        '@ID': state.payerLOBID
                    };

                    let obj: AE_Physician[];

                    let GetAEPhysician = action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians != undefined &&
                        (action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician != undefined
                            && action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician.length > 0)
                    if (GetAEPhysician) {
                        obj = action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians?.Physician?.filter(item => {
                            return item?.LOB != undefined && item?.LOB.length > 0 && item?.LOB?.filter(val => { return val["@ID"] == action.data.uiData.id })
                        })

                        action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician[0].LOB =
                            upsertLOBAEPhysician(obj[0].LOB, newItem);

                    }

                    let iIndex: number = 0;
                    for (var i = 0; i < state.selectedItem["LOB"].length; i++) {
                        let item = state.selectedItem["LOB"][i];
                        if (state.payerLOBID == item["@ID"]) {
                            break;
                        }
                        iIndex++;
                    }

                    let lobItem = state.selectedItem;
                    lobItem["LOB"][iIndex] = state.selectedLOBID

                    let iIndexLOB: number = 0;
                    for (var i = 0; i < state.searchResults.length; i++) {
                        let item = state.searchResults[i];
                        if (action.data.uiData.id == item["@ID"]) {
                            break;

                        }
                        iIndexLOB++;
                    }

                    let resultAEPhysicianLOBUpdate = [...state.searchResults];
                    resultAEPhysicianLOBUpdate[iIndexLOB] = lobItem;

                    // keep delta crud updated for lobs
                    state.deltaCrud.Physicians.Physician =
                        upsertLobDelta(state.deltaCrud.Physicians.Physician, lobItem, newItem)

                    return {
                        ...state,
                        selectedAEPhysicianMaintenance: {
                            id: state.payerLOBID,
                            text: newItem["@PhysicianNo"]
                        },
                        selectedItem: lobItem,
                        searchResults: resultAEPhysicianLOBUpdate,
                        changed: true,

                        physicianNoError: undefined,
                        nbiLOBError: undefined,
                        taxonomyLOBError: undefined,
                    }
                }
                break;

            case 'REMOVE_AEPHYSICIAN':
                {
                    if (action.data.masterCrud) {
                        let selItem: any = state.selectedItem;

                        let iIndex: number = -1;
                        iIndex = state.searchResults.indexOf(selItem);
                        let resultAEPhysicianRemove = [...state.searchResults];
                        if (iIndex === -1) {
                            for (let index = 0; index < state.searchResults.length; index++) {
                                if (resultAEPhysicianRemove[index]["@ID"] === selItem["@ID"]) {
                                    iIndex = index
                                }
                            }
                        }

                        let resultRemoved: AE_Physician[];
                        let delta: any;
                        if (iIndex > -1) {
                            resultRemoved = resultAEPhysicianRemove.splice(iIndex, 1)

                            action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician =
                                action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician.filter(item => item["@ID"] !== resultRemoved[0]["@ID"])
                            action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians["@Count"] =
                                (parseInt(action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians["@Count"]) - 1).toString()
                            resultRemoved[0]['@Delete'] = 'true'
                            delta = state.delta
                            delta.push(resultRemoved[0])
                            // keep delta crud updated
                            state.deltaCrud.Physicians.Physician = upsertAEPhysician(state.deltaCrud.Physicians.Physician, resultRemoved[0]);
                        }
                        if (iIndex === state.searchResults.length - 1) {
                            iIndex = iIndex - 1
                        }
                        if (resultAEPhysicianRemove.length < 1) {
                            return {
                                ...state,
                                searchResults: resultAEPhysicianRemove,
                                selectedItem: emptyItem,
                                selectedLOBID: emptyItemLOB,
                                changed: true,
                                delta: delta
                            };
                        }
                        else {
                            return {
                                ...state,
                                searchResults: resultAEPhysicianRemove,
                                selectedItem: resultAEPhysicianRemove[iIndex],
                                selectedLOBID: emptyItemLOB,
                                changed: true,

                                delta: delta
                            };
                        }
                    }

                }
                break;

            case 'REMOVE_LOBAEPHYSICIAN':
                {
                    if (action.data.masterCrud) {

                        let selItem: AE_Physician = state.selectedItem;
                        let selLobItem: AE_LOB = state.selectedLOBID;
                        let iIndex: number = -1;
                        let iLObindex: number = -1;

                        iIndex = state.searchResults.findIndex((item: AE_Physician) => item["@ID"] === selItem["@ID"]);
                        iLObindex = state.selectedItem.LOB.indexOf(selLobItem);
                        if (iLObindex === -1) {
                            for (let index = 0; index < state.selectedItem.LOB.length; index++) {
                                if (state.selectedItem.LOB[index]["@ID"] === selLobItem["@ID"]) {
                                    iLObindex = index
                                }
                            }
                        }
                        let resultAEPhysicianLOBRemove = [...state.searchResults];
                        let resNew: any;
                        let delta: any;
                        let updatedSelectedItem: any;
                        if (iLObindex > -1) {
                            resNew = selItem.LOB.splice(iLObindex, 1)

                            action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician =
                                upsertAEPhysicianADD(action.data.masterCrud.AEPhysicianMaintenanceInfo.Physicians.Physician, state.selectedItem, selItem.LOB);
                            resultAEPhysicianLOBRemove[iIndex] = selItem;
                            resNew[0]['@Delete'] = 'true'
                            updatedSelectedItem = { ...state.selectedItem }
                            updatedSelectedItem.LOB.push({ ...resNew[0] })
                            if (iLObindex >= updatedSelectedItem.LOB.filter((item: any) => item["@Delete"] !== 'true').length) {
                                iLObindex = updatedSelectedItem.LOB.filter((item: any) => item["@Delete"] !== 'true').length - 1
                            }
                            delta = state.delta
                            delta.push({ ...updatedSelectedItem })
                            // keep delta crud updated for lobs
                            state.deltaCrud.Physicians.Physician =
                                upsertLobDelta(state.deltaCrud.Physicians.Physician, updatedSelectedItem, resNew[0])
                        }
                        resultAEPhysicianLOBRemove = [...state.searchResults];
                        const deletdLength = selItem.LOB.filter(item => item["@Delete"] !== 'true')
                        if (deletdLength.length < 1) {
                            return {
                                ...state,
                                searchResults: resultAEPhysicianLOBRemove,
                                selectedItem: resultAEPhysicianLOBRemove[iIndex],
                                selectedLOBID: emptyItemLOB,
                                changed: true,
                                delta: delta
                            };
                        }
                        else {
                            return {
                                ...state,
                                searchResults: resultAEPhysicianLOBRemove,
                                selectedItem: resultAEPhysicianLOBRemove[iIndex],
                                selectedLOBID: updatedSelectedItem.LOB.filter((item: any) => item["@Delete"] !== 'true')[iLObindex],
                                changed: true,
                                delta: delta

                            };
                        }
                    }
                }
                break;


            case 'ERROR_AEPHYSICIAN_FIELD':
                var errorMsg = "";
                if (action.data.uiData.id && action.data.uiData.id.length > 0) {
                    switch (action.data.uiData.id) {
                        case 'LastName':
                            return {
                                ...state,
                                lastNameError: action.data.uiData.value,
                            }
                            break;
                        case 'FirstName':
                            return {
                                ...state,
                                firstNameError: action.data.uiData.value,
                            }
                            break;
                        case 'FaxNo':
                            return {
                                ...state,
                                faxNoError: action.data.uiData.value,
                            }
                            break;
                        case 'NPI':
                            return {
                                ...state,
                                nbiError: action.data.uiData.value,
                            }
                            break;
                        case 'TaxID':
                            return {
                                ...state,
                                taxIDError: action.data.uiData.value,
                            }
                            break;
                        case 'Taxonomy':
                            return {
                                ...state,
                                taxonomyError: action.data.uiData.value,
                            }
                            break;
                    }
                }
                break;

            case 'ERROR_LOBAEPHYSICIAN_FIELD':
                var errorMsg = "";
                if (action.data.uiData.id && action.data.uiData.id.length > 0) {
                    switch (action.data.uiData.id) {

                        case 'PhysicianNoLOB':
                            return {
                                ...state,
                                physicianNoError: action.data.uiData.value,
                            }
                            break;
                        case 'NPILOB':
                            return {
                                ...state,
                                nbiLOBError: action.data.uiData.value,
                            }
                            break;

                        case 'TaxonomyLOB':
                            return {
                                ...state,
                                taxonomyLOBError: action.data.uiData.value,
                            }
                            break;

                    }
                }
                break;


            case 'UPDATE_AEPHYSICIAN_LASTNAME':
                if (state.selectedItem['@Last'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        lastNameError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@Last': action.data.uiData.value.trim(),
                            lastNameError: undefined,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_FIRSTNAME':
                if (state.selectedItem['@First'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        firstNameError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@First': action.data.uiData.value.trim(),
                            firstNameError: undefined,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_MI':
                if (state.selectedItem['@MI'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@MI': action.data.uiData.value.trim(),
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_Suffix':
                if (state.selectedItem['@Suffix'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@Suffix': action.data.uiData.value.trim(),
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_FORMTYPE':
                if (state.selectedItem['@FormType'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@FormType': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_IDTYPE':
                if (state.selectedItem['@IDType'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@IDType': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_TAXID':
                if (state.selectedItem['@TaxID'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        taxIDError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@TaxID': action.data.uiData.value.trim(),
                            taxIDError: undefined,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_NPI':
                if (state.selectedItem['@NPI'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        nbiError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@NPI': action.data.uiData.value.trim(),
                            nbiError: undefined,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_TAXONOMY':
                if (state.selectedItem['@Taxonomy'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        taxonomyError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@Taxonomy': action.data.uiData.value.trim(),
                            taxonomyError: undefined,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_FAXNO':
                if (state.selectedItem['@FaxNo'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        faxNoError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@FaxNo': action.data.uiData.value.trim(),
                            faxNoError: undefined,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_NO':
                if (state.selectedLOBID['@PhysicianNo'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        physicianNoError: undefined,
                        selectedLOBID: {
                            ...state.selectedLOBID,
                            '@PhysicianNo': action.data.uiData.value.trim(),
                            physicianNoError: undefined,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_LOB_QC':
                if (state.selectedLOBID['@QC'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedLOBID: {
                            ...state.selectedLOBID,
                            '@QC': action.data.uiData.value.trim(),
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_LOB_LOB':
                if (state.selectedLOBID['@LOB'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedLOBID: {
                            ...state.selectedLOBID,
                            '@LOB': action.data.uiData.value.trim(),
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_LOB_PAYER':
                if (state.selectedLOBID['@Payer'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedLOBID: {
                            ...state.selectedLOBID,
                            '@Payer': action.data.uiData.value.trim(),
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_LOB_NPI':
                if (state.selectedLOBID['@NPI'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        nbiLOBError: undefined,
                        selectedLOBID: {
                            ...state.selectedLOBID,
                            '@NPI': action.data.uiData.value.trim(),
                            nbiLOBError: undefined,
                        },
                    }
                }
                break;

            case 'UPDATE_AEPHYSICIAN_LOB_TAXONOMY':
                if (state.selectedLOBID['@Taxonomy'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        taxonomyLOBError: undefined,
                        selectedLOBID: {
                            ...state.selectedLOBID,
                            '@Taxonomy': action.data.uiData.value.trim(),
                            taxonomyLOBError: undefined,
                        },
                    }
                }
                break;


            case 'ERROR_AEPHYSICIAN_REMOVE_CONFIRM':
                return {
                    ...state,
                    aePhysicianRemoveConfirm: action.data.uiData.value,
                }
                break;

            case 'ERROR_LOBAEPHYSICIAN_REMOVE_CONFIRM':
                return {
                    ...state,
                    aeLOBPhysicianRemoveConfirm: action.data.uiData.value,
                }
                break;

            case 'SEND_ALERT':
                return {
                    ...state,
                    aePhysicianMaintenanceAlert: action.data.uiData.value,
                }
                break;
            case 'AEPM_CLEAR_DELTA':
                return {
                    ...state,
                    deltaCrud: JSON.parse(JSON.stringify(emptyDelta))
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;

        }
    }

    return state || defaultState;
}
