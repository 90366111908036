import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ManageDepartmentAssignmentsUI from './ui/ManageClaimAssigmentsUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IErrorRuleDepartmentState = ICrudComponentState<ErrorRulesDepartmentType, ManageDepartmentAssignmentsUI.IManageDepartmentUIState>;

export const actionCreators = createCrudComponentActions<ManageDepartmentAssignmentsUI.ActionCreators>(ManageDepartmentAssignmentsUI.actionCreators);
export type IErrorRuleDepartmentActions = typeof actionCreators;
export type IErrorRuleDepartmentActionProps = ICrudComponentActionProps<IErrorRuleDepartmentActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimAssignmentDepartment, ManageDepartmentAssignmentsUI.reducer);
export const reducer = combineReducers<IErrorRuleDepartmentState>(reducers);

export const validationCallback: ValidationCallback<ErrorRulesDepartmentType> = (crud: any): crud is ErrorRulesDepartmentType => {
    let testCrud: ErrorRulesDepartmentType = crud as ErrorRulesDepartmentType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "ErrorRulesDepartmentType");
    if (validShape) {

        if (testCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments as any === "") {
            testCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments = { ErrorRuleDepartment: new Array<ErrorRuleDepartment>() };
        }

    

        if (testCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments &&
            !Array.isArray(testCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment)) {
            let arr = new Array<ErrorRuleDepartment>();
            arr.push(JSON.parse(JSON.stringify(testCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment)));
            testCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment = arr;
        }

        VerifyArray(testCrud.ErrorRuleDepartmentInfo?.UserList, "User");
    }
    /*
    // ts-transformer-keys is one approach for validation. It is not strictly required, and in some cases be undesirable. Once instance where it would be undesireable is when
    // working with optional data. The keys<>() function includes optional fields in the array it returns, but doesn't indicate if the field is optional or not, yet it is acceptable
    // for raw data to exclude such field information. In these cases, an approach without ts-transformer-keys might be desired (or perhaps ts-transformer-keys-2 would work, or
    // our own custom transformer)
    // Note that keys<>() could still be used to return an array of fields, and optional fields removed here.
    // Alternatively, keys<>() could be left unused and an array of fields provided to ValidateObject, as well.
    // Approach without ts-transformer-keys
    // Other options include:
    //   - using converting TS to JSON Schema and using JSON Schema
    //   - using ts-io, ajv, or runtypes; however, allof these replace the interface declaraction in TypeScript with their own,
    //     leaving the interface to be derived from their implementation, vs deriving what is needed at runtime from TypeScript.
    //   - using ts-transformer-keys-2, which includes support for optional parameters and types. however, this is simply a github project and does not appear to be maintained.
    //   - using typescript-runtime-types, but does not seem very active.
    let testCrud: ErrorRulesDepartmentsUsers = crud as ErrorRulesDepartmentsUsers;
    let validShape: boolean =
        (testCrud?.ErrorRuleDepartmentInfo !== undefined)
    &&  (testCrud?.ErrorRuleDepartmentInfo?.ErrorRuleDepartments !== undefined);
    if (validShape) {
        VerifyArray(testCrud?.ErrorRuleDepartmentInfo?.ErrorRuleDepartments, "NoteType");
    }
    */
    return validShape;
}
