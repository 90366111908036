import { getRawToken } from '@scripts/session/SecurityToken';
import { URLs } from '@commonDevResources/constants';
import { DownloadDocumentPt2, OpenDocument, PDFWindow, DefaultWindowModalStyle } from '@commonResources/window';


export const debugConsole = {
    /**
     * example: debugConsole.log("Hello, World!", null)
     * example: debugConsole.log("Hello, World!", { "Greet": "Hello", "Audience": "World", "Punctuation": "!"})
     * @param message
     * @param anObject
     */
    log: function (message: string, anObject: any) {

        if (!anObject) {
            if (typeof (anObject) === 'undefined') {
                console.log(`${message} : (object is not defined)`);
            }
            else if (anObject === null) {
                console.log(`${message}`);
            }
            else {
                console.log(`${message}`);
            }
            return;
        }

        if (typeof (anObject) === "string") {
            console.log(`${message} : ${anObject}`);
            return;
        }

        if (Array.isArray(anObject)) {
            console.log(`${message} : ${JSON.stringify(anObject)}`);
            return;
        }

        if (anObject instanceof Set) {
            console.log(`${message} : is a "Set" object`);
            console.log(JSON.stringify(Array.from(anObject)));
            return;
        }
    }
}


/**
 * see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status for standards as defined by IETF (Internet Engineering Task Force)
 * see https://docs.microsoft.com/en-us/troubleshoot/iis/http-status-code for HTTP status codes suppoerted by Microsoft IIS
 * commented out the codes taht IIS can't handle
 * */
//TODO: add more HTML status codes as needed
export enum HttpStatus {
    OK = 200,
    Create = 201, 
    Accepted = 202,
    "Moved Permanently" = 301,
    Found = 302,
    "Bad Request" = 400,
    Unauthorized = 401,
    Forbidden = 403,
    "Not Found" = 404,
    "Method Not Allowed" = 405,
    "Request Timeout" = 408,
    //"Payload Too Large" = 413,
    //"Unsupported Media Type" = 415,
    //"I'm a teapot" = 418, //yes, this is a real code; see https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/418, and https://tools.ietf.org/html/rfc7168#section-2.3.3
    //"Upgrade Required" = 426,
    //"Too Many Requests" = 429,
    //"Unavailable For Legal Reasons" = 451,
    "Internal Server Error" = 500,
    "Not Implemented" = 501,
    "Bad Gateway" = 502,
    "Service Unavailable" = 503,
    //"Network Authentication Required" = 511,
}
Object.freeze(HttpStatus);

/**
 * to get string value, use index, then .toString() method, e.g. HttpRequestMethod[method].toString(),
 * where index is simply the enum, e.g. HttpRequestMethod.GET is index zero
 * */
export enum HttpRequestMethod {
    GET,
    POST,
    PUT,
    DELETE,
}
Object.freeze(HttpRequestMethod);

//values for XHR ready states
export enum ReadyState {
    UNSENT = 0, // initial state
    OPENED = 1, // open called
    HEADERS_RECEIVED = 2, // response headers received
    LOADING = 3, // response is loading (a data packet is received)
    DONE = 4, // request complete
};
Object.freeze(ReadyState);

class ApiClient {
    static baseUrl = `${URLs.api || window.location.origin}`; /* TODO: get from config file */
    static apiBaseURL = ApiClient.baseUrl.endsWith('/api') ? ApiClient.baseUrl : `${ApiClient.baseUrl}/api`;

    static requestHeaders = {
        "jsonHeaders": {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*"
        },
        "no-cache" : {
            "Pragma": "no-cache",
            "Cache-Control": "no-cache,no-store,must-revalidate,private"
        },
        "xmlHeaders": {
            "Accept": "text/html, application/xhtml+xml, application/xml;q=0.9, */*;q=0.8",
            "Content-Type": "text/xml",
            "Access-Control-Allow-Origin": "*"
        },
    }


    static instance: ApiClient | undefined;
    constructor() {
        if (!ApiClient.instance) {
            ApiClient.instance = new ApiClient();
        }

        ApiClient.baseUrl = `${window.location.origin}`; /* TODO: get from config file */
        ApiClient.apiBaseURL = ApiClient.baseUrl.endsWith('/api') ? ApiClient.baseUrl : `${ApiClient.baseUrl}/api`;

        return ApiClient.instance;
    }


    //methods
    static urlDecode (encodedString: string) {
        return decodeURIComponent((`${encodedString}`).replace(/\+/g, '%20'));
    }

    static isValidUrl(url: string) {
        if (typeof (url) === 'undefined' || url.length < 11) { //11 is min number of characters need for valid URL
            return false;
        }
        if (url.startsWith('http://localhost')) {
            return true;
        }
        //see https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url
        var urlPattern = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        let regex = new RegExp(urlPattern, 'i');
        return regex.test(url);
    }




    
    //TypeScript can't do JSON, e.g. "headers: JSON" or "payload?: JSON" -- 5+ years and running!!!  (see https://github.com/Microsoft/TypeScript/issues/1897)
    static async jsonGetAsync(endpoint: string, payload?: any, headers: any = ApiClient.requestHeaders.jsonHeaders, callback?: (response: any) => any) {
        //if IE is ever ditched, can use the new Request class/object (or maybe write polyfill) -- see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_your_own_request_object
        return ApiClient.sendAsync(endpoint, HttpRequestMethod.GET, headers, payload, callback);
    }

    static async jsonDeleteAsync(endpoint: string, payload?: any, headers: any = ApiClient.requestHeaders.jsonHeaders, callback?: (response: any) => any) {
        //if IE is ever ditched, can use the new Request class/object (or maybe write polyfill) -- see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_your_own_request_object
        return ApiClient.sendAsync(endpoint, HttpRequestMethod.DELETE, headers, payload, callback);
    }

    static async jsonPostAsync(endpoint: string, payload?: any, headers: any = ApiClient.requestHeaders.jsonHeaders, callback?: (response: any) => any) {
        //if IE is ever ditched, can use the new Request class/object (or maybe write polyfill) -- see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_your_own_request_object
        return ApiClient.sendAsync(endpoint, HttpRequestMethod.POST, headers, payload, callback);
    }

    /**
     *
     * @param endpoint = required URL or URI string
     * @param headers = optional Http Request headers as a JSON object. (defaults to standard JSON request header if none provided)
     * @param payload = optional data, in formatted string(XML or JSON), to send to endpoint
     * @param callback = optional function to invoke after getting a
     */
    static async sendAsync(endpoint: string, method: HttpRequestMethod, headers: any, payload?: any, callback?: (response: any) => any) {
        if (!ApiClient.isValidUrl(endpoint)) {
            debugConsole.log("Not a valid endpoint; please check endpoint URL", null); //<<< REMOVE WHEN DONE DEBUGGING!!!
            return;
        }

        /*
        "Authorization": `${getRawToken()}`,
        credentials: "include",
        "Access-Control-Allow-Origin": API.baseUrl;
        */
        headers["Authorization"] = `${getRawToken()}`;

        let _method = HttpRequestMethod[method].toString();

        //if IE is ever ditched, can use the new Request class/object (or maybe write polyfill) -- see https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#supplying_your_own_request_object
        if (callback && typeof (callback) === 'function') {
            fetch(endpoint,
                {
                    method: _method,
                    headers: headers,
                    body: payload
                }
            ).then((response) => {
                callback(response);
            });

            return;
        }

        return fetch(endpoint,
            {
                method: _method,
                headers: headers,
                body: payload
            }
        );
    }

    //TO-DO: look at downloads for binary
    /**
     * call to emulate download click of a document
     * @param filename = string name of file to download
     * @param uri = string URI of the text resource to download.  This parameter will be URI encoded before the request is sent.
     */
    static async downloadFile(filename: string, uri: string) {
        if (!uri.endsWith(filename)) {
            uri = uri.endsWith('\\') ?
                uri + filename :
                uri + '\\' + filename;
        }
        uri = encodeURIComponent(uri.replace(/\\/g, '/'));
        let href: string = `/Shared/Pages/ViewFile.asp?save=${uri}`; //TO-DO: need to update this

        let element = document.createElement('a');
        element.style.display = 'none'; element.setAttribute('href', href);
        element.setAttribute('download', filename);
        document.body.appendChild(element);

        element.click(); //simulate download click

        document.body.removeChild(element);
    }

    /**
     * Download a ZIP file from a file server.
     * @param filename
     * @param uri fully qualified path of the file, including the filename
     */
    static downloadZIP(filename: string, uri: string) {
        ApiClient.downloadFile(filename, uri);
    }

    static downloadPDF(filename: string, uri: string) {
        ApiClient.downloadFile(filename, uri);
    }

    /**
     * originally ported from legacy
     * @param uri
     * @param target
     */
    static openPDF(uri: string, target: string = "_blank") {
        uri = uri.replace(/\\/g, '/');
        let href: string = `/Shared/Pages/PDFViewer.asp?Open=${encodeURIComponent(uri)}`;
        let pdfWindow = window.open(href, `${target}_1`, DefaultWindowModalStyle);
        pdfWindow?.focus();

        //Chrome browser blocks any extra windows/tabs from opening-- only one new window allowed
        //window.location.href = href;
        //
        //let helpWindow = PDFWindow(uri, `${target}_2`) || window;
        //helpWindow?.focus();
        //OpenDocument(uri, target); 
    }
}
Object.seal(ApiClient)

//webpack can't handle this... 
//export default await ApiClient;
//so must simplify and use this...
export default ApiClient;


