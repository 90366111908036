import { Reducer } from 'redux';
import { ICrudActionData } from "../../scripts/util/CrudComponentHelpers";
import { IFormUIState, defaultFormState } from "@store/ui/BaseCrudUI";
import { createDataAction, ActionTypes } from "../../scripts/util/ActionHelpers";

// Properties of action creators specific to the DenialManagement view
export interface IDenialManagementData {
    selectedGroupID: string;
    groupName: string;
    selectedAvailableCodes: MCDMReasonCode[];
    selectedThisGroupCodes: MCDMGroupReasonCode[];
    thisGroupCodes: MCDMGroupReasonCode[], // List of codes currently in the right pane
}


// UI representation of CRUD data. 
export interface IDenialManagementUIState extends IDenialManagementData, IFormUIState { };

// merge our custom action data interface with CRUD boilerplate
export interface IDenialManagementUIData {
    id: string;
    text: string;
};

interface IDenialManagementUISelected {
    selectedCodes: MCDMReasonCode[];
}
export interface ISelectCodes extends ICrudActionData<MCDMReasonCodes, IDenialManagementUISelected> { }
export interface ISelectThisGroupCodes extends ICrudActionData<MCDMGroupReasonCodes, IDenialManagementUISelected> { }
export interface IModifyDenialManagement extends ICrudActionData<MCDMReasonCodes, string> { };
export interface IResetCrudFlag extends ICrudActionData<MCDMReasonCodes, boolean> { };
export interface IInitializeDenialManagement extends ICrudActionData<MCDMReasonCodes, MCDMReasonCodeMaintenanceInfo> { };

export interface ISelectCodeGroup extends ICrudActionData<MCDMReasonCodes, IDenialManagementUIData> { }
export interface IAddRemoveButtons extends ICrudActionData<MCDMReasonCodes, boolean> { }

export const actionCreators = {    
    initalizeOptions: (rawApiReturn: IInitializeDenialManagement) => createDataAction('INIT_DENIAL_MANAGEMENT', rawApiReturn),
    updateGroupName: (selectInfo: IModifyDenialManagement) => createDataAction('DMRPT_GROUP_NAME_UPDATE', selectInfo),
    selectCodeGroup: (selectInfo: ISelectCodeGroup) => createDataAction('DMRPT_CODE_GROUP_SELECT', selectInfo),
    selectAvailableCodes: (selectInfo: ISelectCodes) => createDataAction('DMRPT_AVAILABLE_CODES_SELECT', selectInfo),
    selectThisGroupCodes: (selectInfo: ISelectThisGroupCodes) => createDataAction('DMRPT_THISGROUP_CODES_SELECT', selectInfo), 
    addCodes: (nothing: IAddRemoveButtons) => createDataAction('DMRPT_ADD_CODE_TO_THIS_GROUP', nothing),
    removeCodes: (nothing: IAddRemoveButtons) => createDataAction('DMRPT_REMOVE_CODE_FROM_THIS_GROUP', nothing),
    addOrUpdateGroup: (nothing: IAddRemoveButtons) => createDataAction('DMRPT_ADD_OR_UPDATE_GROUP', nothing),
    removeGroup: (nothing: IAddRemoveButtons) => createDataAction('DMRPT_REMOVE_GROUP', nothing),
    resetDirtyFlag: (denialManagementInfo: IResetCrudFlag) => createDataAction('RESET_DENIAL_MANAGEMENT_DIRTY', denialManagementInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>['type'];

const emptyItem: MCDMReasonCodesGroup = {
    '@ID': '',
    '@Name': '',
    ReasonCodes: { ReasonCode: [] },
};

export const defaultDenialManagementState: IDenialManagementUIState = {
    ...defaultFormState,
    selectedGroupID: '', 
    groupName: '',
    thisGroupCodes: [], 
    selectedAvailableCodes: [],
    selectedThisGroupCodes: [],
}

export function mapUiStateToCrud<T extends IDenialManagementData>(state: T): MCDMReasonCodeMaintenanceInfo {
    const childNode: MCDMReasonCodeMaintenanceInfo = {
        Groups: { Group: [] },
        ReasonCodeList: { ReasonCode: [] },
    //    '@Name': state.name,
    };
    return childNode;
};

export function mapCrudToUiState(crud: MCDMReasonCodeMaintenanceInfo): IDenialManagementUIState {
    return {
        isDirty: false,
        selectedGroupID: '', //emptyItem,
        groupName: '',
        thisGroupCodes: [], 
        selectedAvailableCodes: [],
        selectedThisGroupCodes: [],
    }
}

function addGroupCodes(groupID: string, previousCodes: MCDMGroupReasonCode[], codesToAdd: MCDMReasonCode[], crud: MCDMReasonCodes): MCDMGroupReasonCode[] {
    let newCodes: MCDMGroupReasonCode[] = previousCodes.slice();
    for (var i = 0; i < codesToAdd.length; i++) {
        newCodes.push({ '@ID': codesToAdd[i]['@ID'], '@ReasoncodeName': codesToAdd[i]['@Name'] });
    }
    return newCodes;
}

function removeGroupCodes(groupID: string, previousCodes: MCDMGroupReasonCode[], codesToRemove: MCDMGroupReasonCode[], crud: MCDMReasonCodes): MCDMGroupReasonCode[] {
    let newCodes: MCDMGroupReasonCode[] = previousCodes.slice();
    for (var j = previousCodes.length - 1; j >= 0; j--) {
        for (var i = 0; i < codesToRemove.length; i++) {
            if (previousCodes[j]['@ID'] === codesToRemove[i]['@ID']) {
                newCodes.splice(j, 1);
            }
        }
    }

    return newCodes;
}

// Returns the original group ID or the temporary group id if this is a new group 
function addOrUpdateGroupObject(crud: MCDMReasonCodes, groupID: string, newGroupName: string, newGroupCodes: MCDMGroupReasonCode[]): string {
    let returnGroupId: string = groupID;
    let crudGroup = crud.ReasonCodeMaintenanceInfo.Groups.Group.find(ob => ob["@ID"] === groupID);
    // Update
    if (crudGroup) {
        if (newGroupName != crudGroup['@Name'])
            crudGroup['@Name'] = newGroupName;
        crudGroup.ReasonCodes.ReasonCode = newGroupCodes;
    }
    // Add
    else {
        let newID = 1;
        while (crud.ReasonCodeMaintenanceInfo.Groups.Group.find(g => g['@ID'] == '#' + newID.toString()))
            newID++;
        returnGroupId = '#' + newID.toString()
        crud.ReasonCodeMaintenanceInfo.Groups.Group.push(
            {
                '@ID': returnGroupId,
                '@Name': newGroupName,
                ReasonCodes: { ReasonCode: newGroupCodes }
            });
    }
    return returnGroupId;
}

function removeGroupObject(crud: MCDMReasonCodes, groupID: string) : string {
    let crudGroupIndex = crud.ReasonCodeMaintenanceInfo.Groups.Group.findIndex(ob => ob["@ID"] === groupID);
    if (crudGroupIndex != -1) {
        crud.ReasonCodeMaintenanceInfo.Groups.Group.splice(crudGroupIndex, 1);
        let length = crud.ReasonCodeMaintenanceInfo.Groups.Group.length;
        if (length > 0) {
            return crud.ReasonCodeMaintenanceInfo.Groups.Group[length-1]['@ID'];
        }
    }
    return "";
}

export const denialManagementUIReducer: Reducer<IDenialManagementUIState, KnownActions> = (state: IDenialManagementUIState | undefined, action: KnownActions) => {
    const isDirty = true;
    let updatedState = state && { ...state };

    if (state) {
        switch (action.type) {
            case 'INIT_DENIAL_MANAGEMENT':
                {
                    const crud = action.data.uiData;
                    if (crud) return mapCrudToUiState(crud);
                    break;
                }
            case 'DMRPT_CODE_GROUP_SELECT':
                if (action.data.masterCrud) {
                    var selectedGroupRecord = action.data.masterCrud.ReasonCodeMaintenanceInfo.Groups.Group.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    updatedState = {
                        ...state,
                        selectedGroupID: selectedGroupRecord !== undefined ? selectedGroupRecord['@ID'] : '',
                        groupName: selectedGroupRecord != undefined ? selectedGroupRecord["@Name"] : "",
                        thisGroupCodes: selectedGroupRecord  != undefined? selectedGroupRecord?.ReasonCodes.ReasonCode : emptyItem.ReasonCodes.ReasonCode,
                    }
                }
                break; 

            case 'DMRPT_GROUP_NAME_UPDATE':
                updatedState = {
                    ...state,
                    groupName: action.data.uiData,
                }
               break;

            case 'DMRPT_AVAILABLE_CODES_SELECT':
                updatedState = {
                    ...state,
                    selectedAvailableCodes: action.data.uiData.selectedCodes,
                }
                break;

            case 'DMRPT_THISGROUP_CODES_SELECT':
                updatedState = {
                    ...state,
                    selectedThisGroupCodes: action.data.uiData.selectedCodes.map(
                        code => {
                            let groupCode: MCDMGroupReasonCode = { '@ID': code['@ID'], '@ReasoncodeName': code['@Name'] };
                            return groupCode;
                        }),
                }
                break;

            case 'DMRPT_ADD_CODE_TO_THIS_GROUP':
                if (state.selectedAvailableCodes.length > 0 && action.data.masterCrud) {
                    var updatedCodeList = addGroupCodes(state.selectedGroupID, state.thisGroupCodes, state.selectedAvailableCodes, action.data.masterCrud);
                    updatedState =
                    {
                        ...state,
                        selectedAvailableCodes: [],
                        thisGroupCodes: updatedCodeList,
                    }
               }
                break;

            case 'DMRPT_REMOVE_CODE_FROM_THIS_GROUP':
                if (state.selectedThisGroupCodes.length > 0 && action.data.masterCrud) {
                    var updatedCodeList = removeGroupCodes(state.selectedGroupID, state.thisGroupCodes, state.selectedThisGroupCodes, action.data.masterCrud);
                    updatedState =
                    {
                        ...state,
                        selectedThisGroupCodes: [],
                        thisGroupCodes: updatedCodeList,
                    }
                }
                break;

            case 'DMRPT_ADD_OR_UPDATE_GROUP':
                if (action.data.masterCrud) {
                    let newSelectedGroupId = addOrUpdateGroupObject(action.data.masterCrud, state.selectedGroupID, state.groupName, state.thisGroupCodes);

                    updatedState = {
                        ...state,
                        selectedGroupID: newSelectedGroupId,
                        selectedAvailableCodes: [],
                        selectedThisGroupCodes: [],
                    }
                }
               break;
                
            case 'DMRPT_REMOVE_GROUP':
                if (action.data.masterCrud) {
                    let newSelectedGroupId = removeGroupObject(action.data.masterCrud, state.selectedGroupID);
                    let newSelectedThisGroupCodes: MCDMGroupReasonCode[] = [];
                    let newSelectedGroupName: string = '';
                    let newSelectedGroup = action.data.masterCrud.ReasonCodeMaintenanceInfo.Groups.Group.find(ob => ob["@ID"] === newSelectedGroupId);
                    if (newSelectedGroup) {
                        newSelectedThisGroupCodes = newSelectedGroup.ReasonCodes.ReasonCode;
                        newSelectedGroupName = newSelectedGroup['@Name'];
                    }
                    updatedState = {
                        ...state,
                        selectedGroupID: newSelectedGroupId,
                        groupName: newSelectedGroupName,
                        selectedAvailableCodes: [],
                        selectedThisGroupCodes: [],
                        thisGroupCodes: newSelectedThisGroupCodes,
                    }
                }
                break;

            case 'RESET_DENIAL_MANAGEMENT_DIRTY':
                updatedState = { ...state, isDirty: false };
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }
    return updatedState || defaultDenialManagementState;
}