import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getRawToken } from "@scripts/session/SecurityToken";

import { IMergeCrudComponentProps} from '@scripts/util/CrudComponentHelpers';

import { URLs } from "@commonResources/constants";
import { ApplicationState } from '@store/index';
import { IMergeComponentProps } from '../../../scripts/util/ReduxHelpers';

/////////////////////////////////////////////////////////////////////////////////////////////

interface IDigitalBillerSchedulerComponentProps {
    canView: boolean,
    apiType: string;
}

interface IDigitalBillerSchedulerComponentState { 
}

export const DEFAULT_STATE: IDigitalBillerSchedulerComponentState = {
 
};

type IOwnProps = IDigitalBillerSchedulerComponentProps & RouteComponentProps<{}>;
type IDigitalBillerSchedulerProps = IMergeComponentProps<IDigitalBillerSchedulerComponentState, IDigitalBillerSchedulerComponentProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class LaunchDigitalBillerScheduler extends React.Component<IDigitalBillerSchedulerProps, IDigitalBillerSchedulerComponentState> {

    static defaultProps: IDigitalBillerSchedulerComponentProps = {
        canView: false,
        apiType: "DigitalBillerScheduler",
    };

    constructor(props: IDigitalBillerSchedulerProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }

       this.NavigateToScheduler();
       this.props.history.push('/LandingPage');
   }

    public componentWillUnmount() {
    }

    public NavigateToScheduler() {
        console.log('Send request to retrieve scheduler url. URLs.api: [' + URLs.api + ']');
        fetch(URLs.api + '/api/data/digitalBiller/getSchedulerUrl',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
            }
        ).then(async response => {
            const data: any = await response.json();
            if (response.status === 200 && data != null) {
                console.log(`getSchedulerUrl. Response Data: ${JSON.stringify(data)}`);
                return data;
            }
            return "";
        }).then((jsonData) => {
            if (jsonData == "") { 
                console.log('SystemErrorNotification', 'The Scheduler could not be launched. If this continues, please contact support.');
                sessionStorage.setItem('SystemErrorNotification', 'The Scheduler could not be launched. If this continues, please contact support.');
            }
            else {
                console.log(`getSchedulerUrl. Navigating to ${jsonData}`);
                window.open(jsonData);
            }
        }).catch(error => {
            sessionStorage.setItem('SystemErrorNotification', 'The Scheduler could not be launched. If this continues, please contact support.');
        }).finally(() => {
        });
    }


    public render()
    {
        return null;
    }
};

var connectedHoc = connect<IDigitalBillerSchedulerComponentState, IOwnProps, IDigitalBillerSchedulerProps,ApplicationState>(
  null 
) (LaunchDigitalBillerScheduler);


export default withRouter(connectedHoc);