import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as SearchClaimUI from './ui/SearchClaimUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type ISearchClaimState = ICrudComponentState<MCSearchClaim, SearchClaimUI.ISearchClaimUIState>;

export const actionCreators = createCrudComponentActions<SearchClaimUI.ActionCreators>(SearchClaimUI.actionCreators);
export type ISearchClaimActions = typeof actionCreators;
export type ISearchClaimActionProps = ICrudComponentActionProps<ISearchClaimActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimNotes, SearchClaimUI.reducer);
export const reducer = combineReducers<ISearchClaimState>(reducers);