import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';


import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';

import { IClaimFilterState, IClaimFilterActions, IClaimFilterActionProps, actionCreators/*, validationCallback */ } from '@store/ClaimFilter';

import { ClaimFilter, IClaimFilterComponentProps } from './ClaimFilter';
import { SecurityBits } from '../../../commonResources/SecurityFunctionList';



export interface IReprocessMCARulesComponentState {
};


//---------------------------------------------------------------


export const DEFAULT_STATE: IReprocessMCARulesComponentState = {};


type IOwnProps = IClaimFilterComponentProps & RouteComponentProps<{}>;

type IClaimFilterProps = IMergeApiComponentProps<IClaimFilterState, IClaimFilterActionProps, IOwnProps>;

export class ReprocessMCARules extends React.Component<IClaimFilterProps, IReprocessMCARulesComponentState>
{

    static defaultProps: IClaimFilterComponentProps = {
        canEdit: true,
        canView: false,
        canCreate: true,
        ncsIsEnabled: false,

        process: "reprocessmcarules",
        parameter: {},
        apiType: "ClaimFilter",
        title: "Reprocess Claim Error Rules",
        executeJS: undefined,
        execNode: "MultiClaim",
        execAction: "ReprocessMCARules",
        execProcess: "ReprocessMCARules",
        extraInstruction: "Click Continue to reprocess the claims without viewing the claim list.",

        claimStateRestrictions: ['', '1'],

    };

    constructor(props: IClaimFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }

        //     console.log('can edit ' + this.props.canEdit + ' can view ' + this.props.canView);

    }


    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }


    public onOk = (baseThis: any, param1: any): boolean => {
        // alert(baseThis.props.apiType + ':' + param1 + ':' + this.props.apiType);
        return true;
    }

    public render() {
        return (
            <div>
                <ClaimFilter
                    {...this.props}

                />
            </div>

        );
    }
};



var connectedHoc = connect<IClaimFilterState, IClaimFilterActionProps, IOwnProps, IClaimFilterProps, ApplicationState>(
    createApiMapStateToProps('claimFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(ReprocessMCARules);

export default withRouter(connectedHoc);
