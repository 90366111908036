import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as AutoModuleUI from './ui/AutoModuleUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IAutoModuleState = ICrudComponentState<MCAutoModuleType, AutoModuleUI.IAutoModuleUIState>;

export const actionCreators = createCrudComponentActions<AutoModuleUI.ActionCreators>(AutoModuleUI.actionCreators); 
export type IAutoModuleActions = typeof actionCreators;
export type IAutoModuleActionProps = ICrudComponentActionProps<IAutoModuleActions>

//https://ncjamieson.com/dont-export-const-enums/
export enum AUTOMODULE_PROPS {
    PARAM_EXPORTFORMAT = "-e",
    PARAM_FORMTYPE = "-f",
    PARAM_REPORTFORMAT = "-o",
    PARAM_REPORTGROUPING = "-g",
    PARAM_WORKFLOWFILTER = "-a",

    PARAM_PRINTFORMTYPE = "-x",
    PARAM_PRINTTYPE = "-k",
    PARAM_FONTFACE = "-l",
    PARAM_FONTSIZE = "-s",
    PARAM_DELIMITER = "-d",
    PARAM_QUALIFIER = "-q",
    PARAM_FILTERTYPEFLAG = "-t",

    PARAM_IPAPERCOMMANDSET = "-u",
    PARAM_PPAPERCOMMANDSET = "-h",
    PARAM_IPAPERFORMTYPE = "-w",
    PARAM_PPAPERFORMTYPE = "-z",

    MODULETYPE_REPORT = "1",
    MODULETYPE_RELEASE = "2",
    MODULETYPE_SPINOFF = "3",
    MODULETYPE_REBILL = "4",
    MODULETYPE_SECONDARY = "5",
    MODULETYPE_DELETE = "6",
    MODULETYPE_UNDELETE = "7",
    MODULETYPE_MAIL = "8",
    MODULETYPE_PAPERCLAIMS = "9",
    MODULETYPE_EXPORT = "10",
    MODULETYPE_IMACS = "11",
    MODULETYPE_NOTE = "12",
    MODULETYPE_DIRECTENTRY = "13",
    MODULETYPE_PAPERCLAIMSALT = "14",
    MODULETYPE_OUTSOURCE = "15",
    MODULETYPE_VALIDATE = "16",
    MODULETYPE_CHECKELIG = "17",
    MODULETYPE_RELEASEHCD = "18",
    MODULETYPE_CLAIMVISION = "19",
    MODULETYPE_REPROCESSMCA = "20",
    MODULETYPE_RELEASESDR = "21",
    MODULETYPE_RRT_FILE = "22",
    MODULETYPE_ECSBATCHSTATUS = "23",
    MODULETYPE_PAYMENTCOMPARISONPROCESS = "24",
    MODULETYPE_REMITPOSTINGFILE = "25",
    MODULETYPE_ATTACHMENT = "26"
}


export const reducers = createCrudComponentReducers(CrudTypes.mctiAutoModuleMaster, AutoModuleUI.reducer);
export const reducer = combineReducers<IAutoModuleState>(reducers);

export const validationCallback: ValidationCallback<MCAutoModuleType> = (crud: any): crud is MCAutoModuleType => {
    let testCrud: MCAutoModuleType = crud as MCAutoModuleType;

    let validShape: boolean = ValidateJSONSchema(testCrud, "MCAutoModuleType");
    if (validShape) {
        // FormList - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.FormList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.FormList = {Form: new Array<MCAM_Form>()};
        }
        // FormList: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.FormList.Form && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.FormList.Form))
        {
            let arr = new Array<MCAM_Form>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.FormList.Form))));
            testCrud.AutoModuleMaintenanceInfo.FormList.Form = arr;
        }

        // ModuleList - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.ModuleList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.ModuleList = {Module: new Array<MCAM_Module>()};
        }
        // ModuleList: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.ModuleList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.ModuleList.Module))
        {
            let arr = new Array<MCAM_Form>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.ModuleList.Module))));
            testCrud.AutoModuleMaintenanceInfo.ModuleList.Module = arr;
        }

        // FilterList - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.FilterList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.FilterList = {Filter: new Array<MCAM_Filter>()};
        }
        // FilerList: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.FilterList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.FilterList.Filter))
        {
            let arr = new Array<MCAM_Filter>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.FilterList.Filter))));
            testCrud.AutoModuleMaintenanceInfo.FilterList.Filter = arr;
        }

        // ReportActionlist - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.ReportActionList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.ReportActionList = {ReportAction: new Array<MCAM_ReportAction>()};
        }
        // ReportActionlist: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.ReportActionList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.ReportActionList.ReportAction))
        {
            let arr = new Array<MCAM_ReportAction>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.ReportActionList.ReportAction))));
            testCrud.AutoModuleMaintenanceInfo.ReportActionList.ReportAction = arr;
        }

        // ReportActionlist - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.ReportActionList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.ReportActionList = {ReportAction: new Array<MCAM_ReportAction>()};
        }
        // ReportActionlist: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.ReportActionList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.ReportActionList.ReportAction))
        {
            let arr = new Array<MCAM_ReportAction>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.ReportActionList.ReportAction))));
            testCrud.AutoModuleMaintenanceInfo.ReportActionList.ReportAction = arr;
        }

        // PrinterList - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.PrinterList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.PrinterList = {Printer: new Array<MCAM_Printer>()};
        }
        // PrinterList: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.PrinterList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.PrinterList.Printer))
        {
            let arr = new Array<MCAM_Printer>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.PrinterList.Printer))));
            testCrud.AutoModuleMaintenanceInfo.PrinterList.Printer = arr;
        }

        // FormTypeList - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.FormTypeList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.FormTypeList = {FormType: new Array<MCAM_FormType>()};
        }
        // FormTypeList: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.FormTypeList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.FormTypeList.FormType))
        {
            let arr = new Array<MCAM_FormType>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.FormTypeList.FormType))));
            testCrud.AutoModuleMaintenanceInfo.FormTypeList.FormType = arr;
        }

        // FontFaceList - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.FontFaceList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.FontFaceList = {FontFace: new Array<MCAM_FontFace>()};
        }
        // FontFaceList: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.FontFaceList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.FontFaceList.FontFace))
        {
            let arr = new Array<MCAM_FontFace>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.FontFaceList.FontFace))));
            testCrud.AutoModuleMaintenanceInfo.FontFaceList.FontFace = arr;
        }

        // FontSizeList - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.FontSizeList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.FontSizeList = {FontSize: new Array<MCAM_FontSize>()};
        }
        // FontSizeList: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.FontSizeList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.FontSizeList.FontSize))
        {
            let arr = new Array<MCAM_FontSize>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.FontSizeList.FontSize))));
            testCrud.AutoModuleMaintenanceInfo.FontSizeList.FontSize = arr;
        }

        // AutoModules - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.AutoModules as any === ""){
            testCrud.AutoModuleMaintenanceInfo.AutoModules = {AutoModule: new Array<MCAM_AutoModule>()};
        }
        // AutoModules: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.AutoModules && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.AutoModules.AutoModule))
        {
            let arr = new Array<MCAM_AutoModule>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.AutoModules.AutoModule))));
            testCrud.AutoModuleMaintenanceInfo.AutoModules.AutoModule = arr;
        }

        // PrintOptionsFormType - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList = {PrintOptionsFormType: new Array<MCAM_PrintOptionFormType>()};
        }
        // PrintOptionsFormType: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType))
        {
            let arr = new Array<MCAM_PrintOptionFormType>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType))));
            testCrud.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType = arr;    
        }

        // PaperCommandSetList - no leaf array element
        if(testCrud.AutoModuleMaintenanceInfo.PaperCommandSetList as any === ""){
            testCrud.AutoModuleMaintenanceInfo.PaperCommandSetList = {PaperCommandSet: new Array<MCAM_PaperCommandSet>()};
        }
        // PrintOptionsFormType: leaf element is not an array
        if (testCrud.AutoModuleMaintenanceInfo.PaperCommandSetList && !Array.isArray(testCrud.AutoModuleMaintenanceInfo.PaperCommandSetList.PaperCommandSet))
        {
            let arr = new Array<MCAM_PaperCommandSet>();
            arr.push(JSON.parse(JSON.stringify((testCrud.AutoModuleMaintenanceInfo.PaperCommandSetList.PaperCommandSet))));
            testCrud.AutoModuleMaintenanceInfo.PaperCommandSetList.PaperCommandSet = arr;
        }
        
    }
    return validShape;
}