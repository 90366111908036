import * as React from 'react';

import styled from 'styled-components';

export const ScheduleRow = styled.div`
    color: #000000;
    font-size: 10pt;
`;

export interface IScheduleColors {
    on: string;
    off: string;
}

export interface ISchedule {
    Sun: string;
    Mon: string;
    Tue: string;
    Wed: string;
    Thu: string;
    Fri: string;
    Sat: string;
    FromTime: string;
    ThruTime: string;
}

export interface IScheduleProps {
    key: string;
    schedule: ISchedule;
}

export interface IScheduleState {
    disabled: boolean;
}

export interface IScheduleDayProps {
    on: string;
    label: string;
    last: boolean;
    colors: IScheduleColors;
}

export interface IScheduleDayState {
    disabled: boolean;
}

export class ScheduleDay extends React.Component<IScheduleDayProps, IScheduleDayState> {
    public render() {
        let color = this.props.on == 'N' ? this.props.colors.off : this.props.colors.on;
        let margin = this.props.last ? '20px' : '4px';
        return (
            <span style={{ color: color, marginRight: margin }}>{this.props.label}</span>
        );
    }
}

export class ReadOnlySchedule extends React.Component<IScheduleProps, IScheduleState> {
    public render() {
        const colors: IScheduleColors = {
            on: '#000000',
            off: '#fff5db',
        };

        return (
            <ScheduleRow>
                <ScheduleDay on={this.props.schedule.Sun} colors={colors} label={'Su'} last={false} />
                <ScheduleDay on={this.props.schedule.Mon} colors={colors} label={'Mo'} last={false} />
                <ScheduleDay on={this.props.schedule.Tue} colors={colors} label={'Tu'} last={false} />
                <ScheduleDay on={this.props.schedule.Wed} colors={colors} label={'We'} last={false} />
                <ScheduleDay on={this.props.schedule.Thu} colors={colors} label={'Th'} last={false} />
                <ScheduleDay on={this.props.schedule.Fri} colors={colors} label={'Fr'} last={false} />
                <ScheduleDay on={this.props.schedule.Sat} colors={colors} label={'Sa'} last={true} />
                <span>{this.props.schedule.FromTime.substr(0, 5)} - {this.props.schedule.ThruTime.substr(0, 5)}</span>
            </ScheduleRow>
        );
    }
}