const MODAL_Z_INDEX = 900;
export const DROP_DOWN_MAIN = 900;
export default {
    leftStrip: `
    z-index: 10;
  `,
    dropdownMain: `
    z-index: 900;
  `,
    barWrapper: `
    z-index: 901;
  `,
    zIndexThree: `
    z-index: 3;
  `,
    zIndexFour: `
    z-index: 4;
  `,
    modal: `
   z-index: ${MODAL_Z_INDEX};
  `,
    banner: `
    z-index: ${MODAL_Z_INDEX + 1};
  `,
    masthead: `
    z-index: 888;
  `,
};
