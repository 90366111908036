import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers'; 
import * as ManualEligibilityCheckStore from './api/ManualEligibilityCheckStore';

export type IManualEligibilityCheckState = IApiComponentState<any, ManualEligibilityCheckStore.IManualEligibilityCheckUIState>;

export const actionCreators = createApiComponentActions<ManualEligibilityCheckStore.ActionCreators>(ManualEligibilityCheckStore.actionCreators); 
export type IManualEligibilityCheckActions = typeof actionCreators;
export type IManualEligibilityCheckActionProps = IApiComponentActionProps<IManualEligibilityCheckActions>

export const reducers = createApiComponentReducers("ManualEligibilityCheck", ManualEligibilityCheckStore.reducer); 
export const reducer = combineReducers<IManualEligibilityCheckState>(reducers);
