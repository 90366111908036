import * as React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps } from '@scripts/util/CrudComponentHelpers';
import { IRemoveRemitsState, IRemoveRemitsActionProps, actionCreators } from '@store/RemoveRemits';
import { ApplicationState } from '@store/index';
import { connect } from 'react-redux';
import { CrudTypes } from '@commonResources/CrudTypes';
import { Tabs, SelectDropdown, Input, Button, Typography } from 'ui-core';
import { Colors } from '@commonResources/colorVariables';
import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { SortHelper, ISortIndicator, SortDataType } from '@scripts/util/SortHelper';
import { ISampleTreeviewData } from './ISampleTreeviewData';
import { RemoveRemitsResultsGrid } from './RemoveRemitsResultsGrid';
import { getRawToken } from "@scripts/session/SecurityToken";
import { URLs } from '@commonDevResources/constants';
import _ from 'lodash';
import AssuranceMenu from '../../common/AssuranceMenu';
import { ModalConfirmation } from '@common/ModalConfirmation';

export const initTab = 0;

export const pageSize = 50;


export const InitLOBSelectionObj = {
    '@ID': "",
    '@Name': '- Select LOB -'
}

export const tabSelectionData = [
    {
        domID: 'id-0',
        label: 'Search'
    },
    {
        domID: 'id-1',
        label: 'Results'
    }
]


const RemoveRemitsContainerDiv = styled.div`
    width: 1032px;
    height: 500px;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
`;

const ResultsTabContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
`;

const ResultsTabGridContentDiv = styled.div`
    color: ${ Colors.grey100 };
    ${ Typography.small };
    ${ Typography.defaultLineHeight };
    ${ Typography.LetterSpacing };
    ${ Typography.uppercase};
`;

const ResultsTabChinContentDiv = styled.div`
    display: flex;
    justify-content: space-between; 
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
`;

const ReasonContainerDiv = styled.div`
    display: flex;
    align-items: center;
`;

const ReasonInputDiv = styled.div`
    width: 400px;
    height: 32px;
    padding-left: 5px;
    position: relative;
`;

const SearchTabContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 250px;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
`;

const FieldRowContainerDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const FromLableDiv = styled.div`
    width: 115px;
`;

const FieldWapperDiv = styled.div`
    width: 250px;
    height: 32px;
`;

const ThruLableDiv = styled.div`
    width: 45px;
    text-align: center;
`;

const SearchButtonDiv = styled.div`
    margin-left: 285px;
    margin-right: 5px;
`;


interface searchResultsObj {
    "count": string;
    "remits": any[];
};

interface IComponentProps {
};

interface paginatedSearchResult {
    childData: any[];
    parentData: any[];
};

interface IComponentState {
    activeTab: number;
    cancelLeave: boolean;
    isConfirmationModalOpen: boolean;
    isValidationModalOpen: boolean;
    reasonInputError: boolean;
    reasonInputText: string;
    isHoveringOverBack: boolean;
    isHoveringOverFoward: boolean;
    searchResults: searchResultsObj;
    paginatedSearchResults: paginatedSearchResult[];
    pageIndex: number;
    LOBList: any[];
    LOBSelection: string;
    fromImportDate: string;
    thruImportDate: string;
    fromPaymentDate: string;
    thruPaymentDate: string;
    fromPaymentNumber: string;
    thruPaymentNumber: string;
    fromProviderID: string;
    thruProviderID: string;
    fromTaxID: string;
    thruTaxID: string;
    dateUUID: string;
    resultsUUID: string;
    treeviewData: ISampleTreeviewData | undefined;
    expandClicked: boolean;
    parentRemoveCheckedIds: string[];
    childRemoveCheckedIds: string[];
    parentRemoveDisabledIds: string[];
    childRemoveDisabledIds: string[];
    expandedParentIds: string[];
    inputValue: string;
    sortIndicator: ISortIndicator;
    childRemoveCheckedObjs: any[];
};


type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IRemoveRemitsProps = IMergeCrudComponentProps<IRemoveRemitsState, IRemoveRemitsActionProps, IOwnProps>;


export class RemoveRemits extends React.Component<IRemoveRemitsProps, IComponentState> {

    state = {
        activeTab: initTab,
        cancelLeave: false,
        isConfirmationModalOpen: false,
        isValidationModalOpen: false,
        reasonInputError: false,
        reasonInputText: "",
        isHoveringOverBack: false,
        isHoveringOverFoward: false,
        searchResults: {
            "count": "0",
            "remits": []
        },
        paginatedSearchResults: [{
            childData: [],
            parentData: [],
        }],
        pageIndex: 0,
        LOBList: [InitLOBSelectionObj],
        LOBSelection: "",
        fromImportDate: "",
        thruImportDate: "",
        fromPaymentDate: "",
        thruPaymentDate: "",
        fromPaymentNumber: "",
        thruPaymentNumber: "",
        fromProviderID: "",
        thruProviderID: "",
        fromTaxID: "",
        thruTaxID: "",
        dateUUID: _.uniqueId(),
        resultsUUID: "" + Date.now(),
        treeviewData: undefined,
        expandClicked: false,
        parentRemoveCheckedIds: [],
        childRemoveCheckedIds: [],
        parentRemoveDisabledIds: [],
        childRemoveDisabledIds: [],
        expandedParentIds: [],
        inputValue: '',
        sortIndicator: {
            sortColumn: 'importDate',
            sortDirection: 'up',
            sortDataType: SortDataType.Date
        },
        childRemoveCheckedObjs: [],
    };

    public async componentDidMount() {
        let searchRequest = {
            Param: [
                {
                    Name: "@chvDataType",
                    Value: "1"
                }
            ]
        };

        await this.props.action.crud.search({ crudId: CrudTypes.mctiRemoveRemits, data: JSON.stringify(searchRequest) });
        const ListOfLOBs = this.props.dataStore.crud.dataSearch === undefined ? [] : this.props.dataStore.crud.dataSearch.ManageRemitMaintenanceInfo.RemitLOBList.RemitLOB;

        this.setState({
            activeTab: initTab,
            childRemoveCheckedObjs: [],
            cancelLeave: false,
            isConfirmationModalOpen: false,
            isValidationModalOpen: false,
            reasonInputError: false,
            reasonInputText: "",
            isHoveringOverBack: false,
            isHoveringOverFoward: false,
            searchResults: {
                "count": "0",
                "remits": []
            },
            paginatedSearchResults: [{
                childData: [],
                parentData: [],
            }],
            pageIndex: 0,
            LOBSelection: "",
            fromPaymentDate: "",
            thruPaymentDate: "",
            fromPaymentNumber: "",
            thruPaymentNumber: "",
            fromProviderID: "",
            thruProviderID: "",
            fromTaxID: "",
            thruTaxID: "",
            dateUUID: _.uniqueId(),
            resultsUUID: "" + Date.now(),
            treeviewData: undefined,
            expandClicked: false,
            parentRemoveCheckedIds: [],
            childRemoveCheckedIds: [],
            parentRemoveDisabledIds: [],
            childRemoveDisabledIds: [],
            expandedParentIds: [],
            inputValue: '',
            sortIndicator: {
                sortColumn: 'importDate',
                sortDirection: 'up',
                sortDataType: SortDataType.Date
            },
            fromImportDate: this.getDateByDays(-7),
            thruImportDate: this.getDateByDays(0),
            LOBList: [...this.state.LOBList, ...ListOfLOBs],
        });
    };
    
    public async componentWillUnmount() {
        this.setState({
            activeTab: initTab,
            childRemoveCheckedObjs: [],
            cancelLeave: false,
            isConfirmationModalOpen: false,
            isValidationModalOpen: false,
            reasonInputError: false,
            reasonInputText: "",
            isHoveringOverBack: false,
            isHoveringOverFoward: false,
            searchResults: {
                "count": "0",
                "remits": []
            },
            paginatedSearchResults: [{
                childData: [],
                parentData: [],
            }],
            pageIndex: 0,
            LOBList: [InitLOBSelectionObj],
            LOBSelection: "",
            fromImportDate: "",
            thruImportDate: "",
            fromPaymentDate: "",
            thruPaymentDate: "",
            fromPaymentNumber: "",
            thruPaymentNumber: "",
            fromProviderID: "",
            thruProviderID: "",
            fromTaxID: "",
            thruTaxID: "",
            dateUUID: _.uniqueId(),
            resultsUUID: "" + Date.now(),
            treeviewData: undefined,
            expandClicked: false,
            parentRemoveCheckedIds: [],
            childRemoveCheckedIds: [],
            parentRemoveDisabledIds: [],
            childRemoveDisabledIds: [],
            expandedParentIds: [],
            inputValue: '',
            sortIndicator: {
                sortColumn: 'importDate',
                sortDirection: 'up',
                sortDataType: SortDataType.Date
            },
        });
    };

    public getPageSelectionButtons = () => {
        const {
            isHoveringOverBack,
            isHoveringOverFoward,
            paginatedSearchResults,
            pageIndex
        } = this.state;

        const nPages = paginatedSearchResults.length

        const isBackArrowDisabled = (nPages < 2) || (pageIndex < 1)
        const isFowardArrowDisabled = (nPages < 2) || (pageIndex === nPages - 1)

        const isBackArrowGray = isBackArrowDisabled || isHoveringOverBack
        const isFowardArrowGray = isFowardArrowDisabled || isHoveringOverFoward

        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div
                    onMouseEnter={() => this.setState({
                        isHoveringOverBack: true
                    })}
                    onMouseLeave={() => this.setState({
                        isHoveringOverBack: false
                    })}
                    onClick={() => {
                        isBackArrowDisabled || this.setState({
                            pageIndex: pageIndex - 1,
                            resultsUUID: "" + Date.now(),
                            expandedParentIds: [],
                        })
                    }} >
                    {
                        isBackArrowGray ?
                            <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamOff.png'} /> :
                            <img src={'/Shared/Images/Buttons/But_ArrowLeft_creamUp.png'} />
                    }
                </div>
                <div style={{ paddingBottom: "5px" }}>{pageIndex + 1 + "/" + nPages}</div>
                <div
                    onMouseEnter={() => this.setState({
                        isHoveringOverFoward: true
                    })}
                    onMouseLeave={() => this.setState({
                        isHoveringOverFoward: false
                    })}
                    onClick={() => {
                        isFowardArrowDisabled || this.setState({
                            pageIndex: pageIndex + 1,
                            resultsUUID: "" + Date.now(),
                            expandedParentIds: [],
                        })
                    }} >
                    {
                        isFowardArrowGray ?
                            <img src={'/Shared/Images/Buttons/But_ArrowRight_creamOff.png'} /> :
                            <img src={'/Shared/Images/Buttons/But_ArrowRight_creamUp.png'} />
                    }
                </div>
            </div>
        );
    };

    public setResultsGridState = (obj: object, funct: () => any = () => { }) => {
        this.setState({ ...this.state, ...obj }, funct);
    };

    public getChildRemoveCheckedObjs = () => {
        const {
            paginatedSearchResults,
            childRemoveCheckedIds,
            childRemoveCheckedObjs
        } = this.state;

        const remitsForRemovalIds = childRemoveCheckedIds.map((id: string) => {
            return id.replace("cbxRemove-child-", "");
        });

        let newChildRemoveCheckedObjs: any[] = [];
        paginatedSearchResults.forEach((resultPage: any) => {

            let remitsForRemoval: any[] = [];
            resultPage.childData.forEach((child: any) => {
                if (remitsForRemovalIds.includes(child.id)) {
                    let parent = resultPage.parentData.find((parentData: any) => parentData.id === child.parentId);
                    if (parent) {
                        remitsForRemoval = [...remitsForRemoval, {
                            "id": child.id,
                            "fileName": parent.fileName,
                            "importDate": parent.importDate,
                            "paymentDate": child.paymentDate,
                            "paymentNo": child.paymentNo,
                            "providerNo": child.providerNo,
                            "secondary": child.secondary,
                            "matched": child.matched,
                            "unmatched": child.unmatched,
                            "amount": child.amount
                        }];
                    };
                };
            });

            newChildRemoveCheckedObjs = [...newChildRemoveCheckedObjs, ...remitsForRemoval];
        });


        const uniqueChildRemoveCheckedObjs = _.uniqBy([...childRemoveCheckedObjs, ...newChildRemoveCheckedObjs], "id").filter(obj => remitsForRemovalIds.includes(obj.id))

        return uniqueChildRemoveCheckedObjs;
    };

    public onClear = () => {
        this.setState({
            LOBSelection: "",
            fromImportDate: this.getDateByDays(-7),
            thruImportDate: this.getDateByDays(0),
            fromPaymentDate: "",
            thruPaymentDate: "",
            fromPaymentNumber: "",
            thruPaymentNumber: "",
            fromProviderID: "",
            thruProviderID: "",
            fromTaxID: "",
            thruTaxID: "",
            dateUUID: _.uniqueId()
        });
    };

    public onSearch = async () => {
        const {
            LOBSelection,
            fromImportDate,
            thruImportDate,
            fromPaymentDate,
            thruPaymentDate,
            fromPaymentNumber,
            thruPaymentNumber,
            fromProviderID,
            thruProviderID,
            fromTaxID,
            thruTaxID
        } = this.state;

        const bodyObj = {
            remitLob: LOBSelection,
            importDateFrom: fromImportDate,
            importDateTo: thruImportDate === "" ? fromImportDate : thruImportDate,
            paymentDateFrom: fromPaymentDate,
            paymentDateTo: thruPaymentDate === "" ? fromPaymentDate : thruPaymentDate,
            paymentNumberFrom: fromPaymentNumber,
            paymentNumberTo: thruPaymentNumber === "" ? fromPaymentNumber : thruPaymentNumber,
            providerIdFrom: fromProviderID,
            providerIdTo: thruProviderID === "" ? fromProviderID : thruProviderID,
            taxIdFrom: fromTaxID,
            taxIdTo: thruTaxID === "" ? fromTaxID : thruTaxID
        };

        await fetch(URLs.api + '/api/data/remits/remove/search/',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(bodyObj),
            }).then(response => {
                return response.json();
            }).then((data) => {
                if (data.count) {
                    this.setState({
                        activeTab: 1,
                        pageIndex: 0,
                        searchResults: data,
                        paginatedSearchResults: this.paginateResults(data, {
                            sortColumn: 'importDate',
                            sortDirection: 'up',
                            sortDataType: SortDataType.Date
                        }),
                        resultsUUID: "" + Date.now(),
                        expandedParentIds: [],
                        thruImportDate: thruImportDate === "" ? fromImportDate : fromImportDate === "" ? "" : thruImportDate,
                        thruPaymentDate: thruPaymentDate === "" ? fromPaymentDate : fromPaymentDate === "" ? "" : thruPaymentDate,
                        thruPaymentNumber: thruPaymentNumber === "" ? fromPaymentNumber : fromPaymentNumber === "" ? "" : thruPaymentNumber,
                        thruProviderID: thruProviderID === "" ? fromProviderID : fromProviderID === "" ? "" : thruProviderID,
                        thruTaxID: thruTaxID === "" ? fromTaxID : fromTaxID === "" ? "" : thruTaxID,
                        childRemoveCheckedObjs: [ ...this.getChildRemoveCheckedObjs()]
                    });
                } else {
                    this.setState({
                        activeTab: 1,
                        pageIndex: 0,
                        searchResults: {
                            "count": "0",
                            "remits": []
                        },
                        paginatedSearchResults: [{
                            childData: [],
                            parentData: [],
                        }],
                        resultsUUID: "" + Date.now(),
                        thruImportDate: thruImportDate === "" ? fromImportDate : thruImportDate,
                        thruPaymentDate: thruPaymentDate === "" ? fromPaymentDate : thruPaymentDate,
                        thruPaymentNumber: thruPaymentNumber === "" ? fromPaymentNumber : thruPaymentNumber,
                        thruProviderID: thruProviderID === "" ? fromProviderID : thruProviderID,
                        thruTaxID: thruTaxID === "" ? fromTaxID : thruTaxID,
                        childRemoveCheckedObjs: [...this.getChildRemoveCheckedObjs()]
                    });
                };

            }).catch(error => {
                console.log('error:', error);
            });
    }

    public formatResults = (data: any) => {
        const formattedResults = {
            parentData: data.map((remit: any) => {
                const {
                    amount,
                    count,
                    fileName,
                    id,
                    importDate,
                    paymentSource,
                } = remit
                return ({
                    amount,
                    count,
                    fileName,
                    id,
                    importDate,
                    paymentSource,
                })
            }),
            childData: data.map((remit: any) => {
                const parentId = remit.id
                return (remit["payments"].map((payment: any) => {
                    const {
                        amount,
                        id,
                        matched,
                        paymentDate,
                        paymentNo,
                        providerNo,
                        secondary,
                        taxId,
                        unmatched,
                    } = payment;
                    return ({
                        amount,
                        id,
                        matched,
                        parentId,
                        paymentDate,
                        paymentNo,
                        providerNo,
                        secondary,
                        taxId,
                        unmatched,
                    });
                }));
            }).flat(),
        };
        return formattedResults;
    };

    public paginateResults = (data: any, sortIndicator: any) => {
        const sortedData = SortHelper.Sort(
            data.remits,
            sortIndicator
        );
        const nPages = sortedData.length === 0 ? 1 : Math.ceil(sortedData.length / pageSize);
        let pages: any[] = [];
        let dataSlice: any[] = [];
        let formattedDataSlice;
        for (let i = 0; i < nPages; i++) {
            dataSlice = sortedData.slice((i * pageSize), ((i + 1) * pageSize));
            formattedDataSlice = this.formatResults(dataSlice);
            pages = [
                ...pages,
                formattedDataSlice
            ];
        };
        return pages;
    };

    public getDateByDays = (nDays: number) => {
        let newDate = new Date();
        const resultMilliseconds = newDate.setDate(newDate.getDate() + nDays);
        const resultDate = new Date(resultMilliseconds).toLocaleString().split(',')[0];
        return resultDate;
    };

    public getMaxDateTimeByDateStr = (dateStr: string) => {
        let newDate = new Date(dateStr);
        const resultMilliseconds = newDate.setDate(newDate.getDate() + 1);
        const resultDate = this.getDateInMMDDYYYYHHMMSSFormat(new Date(resultMilliseconds));
        return resultDate;
    };

    public getDateTimeByYears = (nYears: number) => {
        let newDate = new Date();
        const resultMilliseconds = newDate.setFullYear(newDate.getFullYear() + nYears);
        const resultDate = this.getDateInMMDDYYYYHHMMSSFormat(new Date(resultMilliseconds));
        return resultDate;
    };

    public getDateInMMDDYYYYHHMMSSFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var hour = input.getHours();
            var year = input.getFullYear();
            var minute = input.getMinutes();
            var seconds = input.getSeconds();

            return this.doubleDigitify(month) + '/' + this.doubleDigitify(day) + '/' + year + ' ' + this.doubleDigitify(hour) + ':' + this.doubleDigitify(minute) + ':' + this.doubleDigitify(seconds);
        }
        catch (e) {
            console.error('Unable to properly format date');
        };
        return "00/00/0000 00:00:00";
    };

    public doubleDigitify = (num: number) => {
        return (num < 10 ? '0' + num : num);
    };

    public onOK = (e: React.ChangeEvent<HTMLButtonElement>) => {
        const { childRemoveCheckedIds, reasonInputText } = this.state
        if (childRemoveCheckedIds.length > 0) {
            if (reasonInputText === "") {
                this.setState({
                    reasonInputError: true
                });
            } else {
                this.setState({
                    isConfirmationModalOpen: true,
                    reasonInputError: false
                });
            }
        } else {
            this.setState({
                isValidationModalOpen: true,
                reasonInputError: false
            });
        };
    };

    public onConfirm = async () => {
        const {
            childRemoveCheckedIds,
            reasonInputText
        } = this.state;

        const newChildRemoveCheckedObjs = this.getChildRemoveCheckedObjs()

        const body = { "reason": reasonInputText, "remits": newChildRemoveCheckedObjs};

        await fetch(URLs.api + '/api/data/remits/remove', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(body),
        }).then(response => {
            if (response.status === 200) {
                this.props.history.push("/LandingPage");
                //console.log("Your post request came back with this expected response:", response)
            } else {
                console.log("Your post request came back with this unexpected response:", response);
            }
        }).catch(error => {
            console.log('error:', error);
        });
    };

    public onCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        const userModified: any = sessionStorage.getItem("userModified");
        if (userModified === 'true') {
            this.setState({
                cancelLeave: true
            });
        }
        else
            this.props.history.push('/LandingPage');
    };

    public render() {
        const {
            activeTab,
            isConfirmationModalOpen,
            isValidationModalOpen,
            reasonInputError,
            reasonInputText,
            searchResults,
            paginatedSearchResults,
            pageIndex,
            LOBList,
            LOBSelection,
            fromImportDate,
            thruImportDate,
            fromPaymentDate,
            thruPaymentDate,
            fromPaymentNumber,
            thruPaymentNumber,
            fromProviderID,
            thruProviderID,
            fromTaxID,
            thruTaxID,
            dateUUID,
            resultsUUID,
        } = this.state;

        const instruction = <React.Fragment>
            <span style={{ color: "#d84040" }}>
                <b>
                    Do not remove a remittance file that has not completed the import process. If necessary, use Job Queue to check the status of the import.
                </b>
            </span>
            <br />
            To remove a file, first use Search to find the file: Enter the search criteria and click Search. When the searchResults list displays, select the remit file to remove and enter a reason for removal. Click OK to remove.
            </React.Fragment>;
        const buttons = <OKCancelButtons
            onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)}
            onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)}
        />;

        const LOBOptions = LOBList.map(LOB => {
            return { value: LOB["@ID"], label: LOB["@Name"] };
        });
        
        return (
            <DialogWrapper
                title="Remove Remits"
                helpUrl={"/Support/Help/HELP_RMTRemove.htm"}
                instruction={instruction}
                buttons={
                    buttons
                }
            >
                {
                    this.state.cancelLeave &&
                    <AssuranceMenu {...this.props}
                        Cancel={true}
                        MenuUrl=''
                        stayEvent={() => this.setState({
                            cancelLeave: false
                        })} />
                }
                <RemoveRemitsContainerDiv>
                    <Tabs
                        key={"Tabs" + activeTab}
                        initialTab={activeTab}
                        onTabSelect={(e: React.ChangeEvent<HTMLButtonElement>, index: any) => {
                            this.setState({
                                activeTab: index.selectedTabIndex
                            })
                        }}
                        tabs={tabSelectionData}
                    />
                    {this.state.activeTab ?
                        <ResultsTabContainerDiv>
                            <ResultsTabGridContentDiv>
                                <RemoveRemitsResultsGrid
                                    key={resultsUUID}
                                    treeviewData={paginatedSearchResults[pageIndex]}
                                    setResultsGridState={this.setResultsGridState}
                                    onSort={(sortIndicator: any) => { return this.paginateResults(searchResults, sortIndicator) }}
                                    resultsGridState={{ ...this.state, treeviewData: paginatedSearchResults[pageIndex] }}
                                />
                            </ResultsTabGridContentDiv>
                            <ResultsTabChinContentDiv>
                                <ReasonContainerDiv>
                                    *Reason:
                                    <ReasonInputDiv>
                                        <Input
                                            value={reasonInputText}
                                            onChange={(e: React.ChangeEvent<HTMLButtonElement>) => this.setState({
                                                reasonInputText: e.target.value
                                            })}
                                            onBlur={() => this.setState({
                                                reasonInputText: reasonInputText.trim(),
                                                reasonInputError: reasonInputText === "" ? reasonInputError : false
                                            })}
                                            domID={_.uniqueId()}
                                            maxLength={50}
                                            errorMessage="Please enter a reason for removing the selected payment(s)."
                                            hasError={reasonInputError}
                                        />
                                    </ReasonInputDiv>
                                </ReasonContainerDiv>
                                {this.state.childRemoveCheckedIds.length + " payment(s) selected"}
                                {this.getPageSelectionButtons()}
                            </ResultsTabChinContentDiv>
                        </ResultsTabContainerDiv>
                        :
                        <SearchTabContainerDiv>
                            <FieldRowContainerDiv>
                                <FromLableDiv>
                                    LOB:
                                </FromLableDiv>
                                <FieldWapperDiv>
                                    <SelectDropdown
                                        key={LOBSelection + _.uniqueId()}
                                        domID={LOBSelection + _.uniqueId()}
                                        initialValue={LOBSelection}
                                        onChange={(e: { target: { value: any; }; }) => { this.setState({ LOBSelection: e.target.value }) }}
                                        options={LOBOptions}
                                    />
                                </FieldWapperDiv>
                            </FieldRowContainerDiv>
                            <FieldRowContainerDiv>
                                <FromLableDiv>
                                    Import Date:
                                </FromLableDiv>
                                <FieldWapperDiv>
                                    <div>
                                        <ARMDatePicker
                                            domID={"fromImportDate" + dateUUID}
                                            key={"fromImportDate" + dateUUID}
                                            onInputChange={(fromImportDate: string) => { this.setState({ fromImportDate }) }}
                                            allowTime={false}
                                            minDate={this.getDateTimeByYears(-1)}
                                            maxDate={this.getMaxDateTimeByDateStr(thruImportDate)}
                                            initialDate={fromImportDate}
                                            disableHowLong={true}
                                            lockKeyboardInput
                                        />
                                    </div>
                                </FieldWapperDiv>
                                <ThruLableDiv>
                                    Thru
                                </ThruLableDiv>
                                <FieldWapperDiv>
                                    <div>
                                        <ARMDatePicker
                                            domID={"thruImportDate" + dateUUID}
                                            key={"thruImportDate" + dateUUID}
                                            onInputChange={(thruImportDate: string) => { this.setState({ thruImportDate }) }}
                                            allowTime={false}
                                            minDate={fromImportDate}
                                            maxDate={this.getMaxDateTimeByDateStr(this.getDateTimeByYears(2))}
                                            initialDate={thruImportDate}
                                            disableHowLong={true}
                                            lockKeyboardInput
                                        />
                                    </div>
                                </FieldWapperDiv>
                            </FieldRowContainerDiv>
                            <FieldRowContainerDiv>
                                <FromLableDiv>
                                    Payment Date:
                                </FromLableDiv>
                                <FieldWapperDiv>
                                    <div>
                                        <ARMDatePicker
                                            domID={"fromPaymentDate" + dateUUID}
                                            key={"fromPaymentDate" + dateUUID}
                                            onInputChange={(fromPaymentDate: string) => { this.setState({ fromPaymentDate }) }}
                                            allowTime={false}
                                            minDate={this.getDateTimeByYears(-1)}
                                            maxDate={this.getMaxDateTimeByDateStr(thruPaymentDate)}
                                            initialDate={fromPaymentDate}
                                            disableHowLong={true}
                                            lockKeyboardInput
                                        />
                                    </div>
                                </FieldWapperDiv>
                                <ThruLableDiv>
                                    Thru
                                </ThruLableDiv>
                                <FieldWapperDiv>
                                    <div>
                                        <ARMDatePicker
                                            domID={"thruPaymentDate" + dateUUID}
                                            key={"thruPaymentDate" + dateUUID}
                                            onInputChange={(thruPaymentDate: string) => { this.setState({ thruPaymentDate }) }}
                                            allowTime={false}
                                            minDate={fromPaymentDate}
                                            maxDate={this.getMaxDateTimeByDateStr(this.getDateTimeByYears(2))}
                                            initialDate={thruPaymentDate}
                                            disableHowLong={true}
                                            lockKeyboardInput
                                        />
                                    </div>
                                </FieldWapperDiv>
                            </FieldRowContainerDiv>
                            <FieldRowContainerDiv>
                                <FromLableDiv>
                                    Payment Number:
                                </FromLableDiv>
                                <FieldWapperDiv>
                                    <Input
                                        value={fromPaymentNumber}
                                        onChange={(e: React.ChangeEvent<HTMLButtonElement>) => this.setState({
                                            fromPaymentNumber: e.target.value
                                        })}
                                        onBlur={() => this.setState({
                                            fromPaymentNumber: fromPaymentNumber.trim()
                                        })}
                                        domID={_.uniqueId()}
                                    />
                                </FieldWapperDiv>
                                <ThruLableDiv>
                                    Thru
                                </ThruLableDiv>
                                <FieldWapperDiv>
                                    <Input
                                        value={thruPaymentNumber}
                                        onChange={(e: React.ChangeEvent<HTMLButtonElement>) => this.setState({
                                            thruPaymentNumber: e.target.value
                                        })}
                                        onBlur={() => this.setState({
                                            thruPaymentNumber: thruPaymentNumber.trim()
                                        })}
                                        domID={_.uniqueId()}
                                    />
                                </FieldWapperDiv>
                            </FieldRowContainerDiv>
                            <FieldRowContainerDiv>
                                <FromLableDiv>
                                    Provider ID:
                                </FromLableDiv>
                                <FieldWapperDiv>
                                    <Input
                                        value={fromProviderID}
                                        onChange={(e: React.ChangeEvent<HTMLButtonElement>) => this.setState({
                                            fromProviderID: e.target.value
                                        })}
                                        onBlur={() => this.setState({
                                            fromProviderID: fromProviderID.trim()
                                        })}
                                        domID={_.uniqueId()}
                                    />
                                </FieldWapperDiv>
                                <ThruLableDiv>
                                    Thru
                                </ThruLableDiv>
                                <FieldWapperDiv>
                                    <Input
                                        value={thruProviderID}
                                        onChange={(e: React.ChangeEvent<HTMLButtonElement>) => this.setState({
                                            thruProviderID: e.target.value
                                        })}
                                        onBlur={() => this.setState({
                                            thruProviderID: thruProviderID.trim()
                                        })}
                                        domID={_.uniqueId()}
                                    />
                                </FieldWapperDiv>
                            </FieldRowContainerDiv>
                            <FieldRowContainerDiv>
                                <FromLableDiv>
                                    Tax ID:
                                </FromLableDiv>
                                <FieldWapperDiv>
                                    <Input
                                        value={fromTaxID}
                                        onChange={(e: React.ChangeEvent<HTMLButtonElement>) => this.setState({
                                            fromTaxID: e.target.value
                                        })}
                                        onBlur={() => this.setState({
                                            fromTaxID: fromTaxID.trim()
                                        })}
                                        domID={_.uniqueId()}
                                    />
                                </FieldWapperDiv>
                                <ThruLableDiv>
                                    Thru
                                </ThruLableDiv>
                                <FieldWapperDiv>
                                    <Input
                                        value={thruTaxID}
                                        onChange={(e: React.ChangeEvent<HTMLButtonElement>) => this.setState({
                                            thruTaxID: e.target.value
                                        })}
                                        onBlur={() => this.setState({
                                            thruTaxID: thruTaxID.trim()
                                        })}
                                        domID={_.uniqueId()}
                                    />
                                </FieldWapperDiv>
                            </FieldRowContainerDiv>
                            <FieldRowContainerDiv>
                                <SearchButtonDiv>
                                    <Button
                                        buttonType="standard"
                                        name='SEARCH'
                                        onClick={this.onSearch}
                                        disabled={false}
                                        size="medium"
                                        type="button"
                                    />
                                </SearchButtonDiv>
                                <div>
                                    <Button
                                        buttonType="standard"
                                        name='CLEAR'
                                        onClick={this.onClear}
                                        disabled={false}
                                        size="medium"
                                        type="button"
                                    />
                                </div>
                            </FieldRowContainerDiv>
                        </SearchTabContainerDiv>
                        }
                </RemoveRemitsContainerDiv>
                <ModalConfirmation
                    title={"Message from webpage"}
                    isOpen={isValidationModalOpen}
                    alertMode={true}
                    onModalToggle={() => this.setState({
                        isValidationModalOpen: false
                    })}
                    formattedMessage={"Please select payments."}
                />
                <ModalConfirmation
                    title={"Message from webpage"}
                    isOpen={isConfirmationModalOpen}
                    alertMode={false}
                    onDeny={() => this.setState({
                        isConfirmationModalOpen: false
                    })}
                    onConfirm={this.onConfirm}
                    formattedMessage={"Remove " + this.state.childRemoveCheckedIds.length + " payments. Removing payments is not reversible. Click OK to remove these payments, Cancel to stop."}
                />
            </DialogWrapper>
        );
    };
};

var connectedHoc = connect<IRemoveRemitsState, IRemoveRemitsActionProps, IOwnProps, IRemoveRemitsProps, ApplicationState>(
    createCrudMapStateToProps('removeRemits'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(RemoveRemits);

export default withRouter(connectedHoc);