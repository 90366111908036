import { Reducer, ActionCreatorsMapObject } from 'redux';
import { get } from "lodash";
import { createSelector } from 'reselect';
import { createAction, createDataAction, createApiAction, createApiBodyAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { URLs } from '@commonDevResources/constants';
import * as LOCAL_DATA from '../../components/Dashboard/Default/DashboardClaimsData.json';
import { OperationView, OperationMeasure, OperationDateRange, OperationFilter } from "@store/OperationalDashboard/DrillDownView"
import { ISelectionView, ISelectionMeasure, ISelectionDateRange, IDashboardFilterState } from "@store/OperationalDashboard/DashboardDefault"
import { MeterObj } from "@components/Dashboard/Default/MeterRowComponent"
import { ApplicationState } from "@store/index"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IDashboardClaimState extends IDashboardFilterState {
    claimData?: any;
    activePayload?: any;
}

//getCiamUserDetails: (userInfo: ISelectUser) => createApiAction('GET_CIAM_USER_DETAILS', `${URLs.api}/api/data/ciam/getUser/${userInfo.uiData.value}`, userInfo),

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    getClaimData: (operationFilter: OperationFilter) => createApiBodyAction('GET_CLAIM_DATA', `${URLs.api}/api/data/dashboard/operations`, undefined, "POST", JSON.stringify(operationFilter)),
    getLocalClaimData: () => createAction('GET_LOCAL_CLAIM_DATA'),
    setActivePayload: (filter: any) => createDataAction('SET_ACTIVE_PAYLOAD', filter),
    resetState: () => createAction('DASHBOARD_CLAIM_RESET_STATE'),
    selectView: (selectedView: ISelectionView) => createDataAction('DASHBOARD_CLAIM_SELECT_VIEW', selectedView),
    selectMeasure: (selectedMeasure: ISelectionMeasure) => createDataAction('DASHBOARD_CLAIM_SELECT_MEASURE', selectedMeasure),
    selectDateRange: (selectedDateRange: ISelectionDateRange, startDate: string, endDate: string) => createDataAction('DASHBOARD_CLAIM_SELECT_DATE_RANGE', { selectedDateRange, startDate, endDate}),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IDashboardClaimState = {

    selectedView: { label: 'Charts', value: '0' },
    selectedMeasure: { label: 'Amount & Count', value: '0' },
    selectedDateRange: { label: 'Last 90 days', value: '6' },
    selectedStartDate:'',
    selectedEndDate: '',
    uiLock: "Unlocked",
    activePayload: {},
};


// ----------------
// REDUCER - For a given state and action returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IDashboardClaimState, KnownActions> = (state: IDashboardClaimState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'DASHBOARD_CLAIM_RESET_STATE':
                return defaultState;
            case 'DASHBOARD_CLAIM_SELECT_VIEW':
                {
                    return {
                        ...state,
                        selectedView: action.data,
                    }
                }
            case 'DASHBOARD_CLAIM_SELECT_MEASURE':
                {

                    return {
                        ...state,
                        selectedMeasure: action.data,
                    }
                }
            case 'DASHBOARD_CLAIM_SELECT_DATE_RANGE':
                {

                    return {
                        ...state,
                        selectedDateRange: action.data.selectedDateRange,
                        selectedStartDate: action.data.startDate,
                        selectedEndDate: action.data.endDate
                    }
                }
            case 'GET_LOCAL_CLAIM_DATA':
                return {
                    ...state,
                    claimData: JSON.parse(JSON.stringify(LOCAL_DATA)).default
                }
            case 'GET_CLAIM_DATA':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                   
                        return {
                            ...state,
                            //claimData: action.responseData,
                            claimData: JSON.parse(JSON.stringify(action.responseData)),
                        }
                    case "FAIL":
                        console.log("Error: failed to fetch Claim data");
                        return {
                            ...state,
                            claimData: JSON.parse(JSON.stringify(action.responseData)),
                        }
                    default:
                        // The following line guarantees that every action in the KnownAction union has been covered by a case above
                        // const exhaustiveCheck: never = action;
                        return state;
                }
            case 'SET_ACTIVE_PAYLOAD':
                //console.log("The chart has been clicked and active payload is: ", action.data);
                return {
                    ...state,
                    activePayload: action.data,
                }
            default:
                return state;
        }
    }

    return state || defaultState;
}