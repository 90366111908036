import React from 'react';
import MeterComponent from '../../../commonResources/commonGraphing/meterComponent/MeterComponent';
import styled from 'styled-components';
import { ApplicationState } from "@store/index"

export interface MeterObj {

  footer: string,
  header: string,
  high: number,
  low: number,
  range: number,
  value: number
}


interface IComponentProps {
  selectors: {
    getMeterData: (state: ApplicationState) => MeterObj[]
  }
};

const MeterWrapper = styled("div")`
    display: flex;
    flex-direction: row;
    div {
        flex-basis: 25%;
    }
`;



const MeterRowComponent = (props:any) => {

    const { meterData } = props;
    //console.log("Hello World! The meter data is: ", meterData);

    if (meterData) {
        return (
            <MeterWrapper>
                {meterData.map((meter: any) => 
                    <MeterComponent
                        key={meter.id}
                        low={meter.low}
                        high={meter.high}
                        value={meter.value}
                        header={meter.title}
                        footer={meter.bottomTitle}
                    />
                )}
            </MeterWrapper>
        )
    } else {
        return (<></>)
    }

}

export default MeterRowComponent;
