import * as React from 'react';
import { Grid, Button, Typography } from 'ui-core';
import styled from 'styled-components';
import { ContentRowWrapper, DialogInstruction, CenteredContent } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset, DialogLegend3 } from '@common/DialogStyles';
import { RadioButtonComponent } from '@common/RadioButton';
import { SelectComponent } from '@common/SelectComponent';
import { ICCIPayerSelectionData, IPayerSelectionUpdateRequest, ICCIPayerSelectionLocalState } from '@store/ui/ClaimOptionsUI';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    #id-cci-payer-fieldset {
        padding-left: 10px;
        padding-right: 10px;
    }

    #id-cci-payer-form-type-fieldset {
        margin-left: 0px;
    }

    #id-cci-payer-cpt-group-fieldset { 
        margin-right: 0px;
    }

    #id-cci-payer-payer-or-alias {
        padding-left: 15px;
        padding-right: 10px;
        padding-bottom: 8px;
    }

    #lobPayerSelect .option-class, #cptGroupSelect .option-class {
        ${Typography.small};
}
`;

const GridWrapperCCIPayerSelectionNotes = styled.div`
    border: black 1px solid;
`;

const GridWrapper = styled.div`

    #grid-wrapper-id-cci-payer-selection-options-grid {
        height:170px;
    }

    .empty-row{
        background-color:white;
    }

    thead tr th{
        background-color:#9BA1A9;
        height:20px;
    }

    table tbody tr > td{
       height: auto;
    }

    table tbody tr td div{
        min-height:15px;
        ${Typography.small};
    }

   .LOB {
        width:150px;
    }

    .PayerOrAlias {
        width:250px;
    }

    .CPTGroup {
        width: 505px;
    }

    .Form {
        min-width:30px;
        max-width:30px;
    }
 `;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    canDelete: boolean,

    displayAlertMessage(msg: string): void,

    localState?: ICCIPayerSelectionLocalState,
    onLocalStateChange(request: ICCIPayerSelectionLocalState): void,

    payerSelectionOptions: ICCIPayerSelectionData,
    updateCCIPayerSelectionOptions(request: IPayerSelectionUpdateRequest): void,
};

interface IComponentState {
    selectedCCIID : string, // selected row id

    // Form Type
    selectedFormType: string,

    // LOB, Payer of Alias
    selectedPayerOption: string,  
    payerList: MCClaimOptionsIDName[],
    selectedLOBs: string[],
    selectedPayers: string[],
    selectedAliases: string[],

    // CPT Group
    cptGroupList: MCClaimOptionsIDName[],
    selectedCPTGroups: string[],
}

export class CCIPayerSelectionOptions extends React.Component<IComponentProps, IComponentState> {

    private instructions = <React.Fragment><span className='instructionRed'> Note! </span> The payer lists on the Medical Necessity and CCI tab may take a moment to load due to the extensive list of payers.</React.Fragment>;

    constructor(props: IComponentProps) {
        super(props);

        let allGroupsElement = {
            '@ID': '-1',
            '@Name': '- All CPT Groups -',
        };
        let cptGroupList: any[] = [allGroupsElement];
        cptGroupList = cptGroupList.concat(this.props.payerSelectionOptions.CPTGroupList);

        this.state = {
            selectedCCIID: (props.localState?.selectedCCIID ? props.localState.selectedCCIID : ''),
            selectedFormType: (props.localState?.selectedFormType ? props.localState.selectedFormType : ''),
            selectedPayerOption: (props.localState?.selectedPayerOption ? props.localState.selectedPayerOption : ''),
            selectedLOBs: (props.localState?.selectedLOBs ? props.localState.selectedLOBs : []),
            selectedPayers: (props.localState?.selectedPayers ? props.localState.selectedPayers : []),
            selectedAliases: (props.localState?.selectedAliases ? props.localState.selectedAliases : []),
            selectedCPTGroups: (props.localState?.selectedCPTGroups ? props.localState.selectedCPTGroups : ["-1"]),
            payerList: (props.localState?.selectedPayerOption &&
                props.localState?.selectedFormType ? this.getLOBPayerAliasList(props.localState.selectedPayerOption, props.localState.selectedFormType, props.localState?.selectedCCIID ? props.localState.selectedCCIID : '') : []),
            cptGroupList: cptGroupList,
        };
    }

    public componentDidMount() {
        // If user left the payer selection page while he had a row selected, and he comes back to that page, then highlight the previously selected row
        if (this.props.localState?.selectedCCIID && this.props.localState.selectedCCIID != '') {
            this.highlightSelectedRowByID(this.props.localState.selectedCCIID);
        }
    }

    public componentWillUnmount() {
    }

    CCIPayerSelectionGridColumns = () => {

        let headers = new Set();

        const FormElement = {
            dataName: "LOB",
            text: 'LOB',
            sortable: false,
            cellType: "custom",
            isSorted: 0,
        };

        headers.add(FormElement);

        const PayerElement = {
            dataName: "PayerOrAlias",
            text: "Payer/Alias",
            sortable: false,
            cellType: "custom",
            isSorted: 0,
        };

        headers.add(PayerElement);

        const FieldElement = {
            dataName: "CPTGroup",
            text: "CPT Group",
            sortable: false,
            cellType: "custom",
            isSorted: 0,
        };

        headers.add(FieldElement);

        const EditNameElement = {
            dataName: "Form",
            text: "Form",
            sortable: false,
            cellType: "custom",
            isSorted: 0,
        };

        headers.add(EditNameElement);

        return headers;
    }

    private getSelectedPayers(option: string, editData: any): string[] {
        switch (option) {
             case "PAYER": {
                const values = editData.Payers.Payer.map((payer: any) => payer['@ID']);
                return values;
            }
                default: {
                return [];
            }
        }
        return [];
    }

    private getSelectedLOBs(option: string, editData: any): string[] {
        switch (option) {
              case "LOB": {
                  const values = editData.LOBs.LOB.map((lob: any) => lob['@ID']);
                  return values;
              }
             default: {
                return [];
            }
        }
        return [];
    }

    private getSelectedAliases(option: string, editData: any): string[] {
        switch (option) {
             case "ALIAS": {
                const values = editData.Aliases.Alias.map((alias: any) => alias['@ID']);
                return values;
            }
            default: {
                return [];
            }
        }
        return [];
    }

    private getSelectedCPTGroups(editData: any): string[] {
        if (editData.CPTGroups.CPTGroup && editData.CPTGroups.CPTGroup.length > 0) {
            const values = editData.CPTGroups.CPTGroup.map((group: any) => group['@ID']);
            if (values && values.length > 0)
                return values;
        }
        return ['-1'];
    }


    private isAlreadyAddedLOB(lobId: string, selectedCCIId: string, formType: string): boolean {
        let cciEditFilteredList = this.props.payerSelectionOptions.CCIEdits.filter(cciedit => cciedit['@ID'] != selectedCCIId && (formType == 'P' ? cciedit['@Professional'] == 'Y' : cciedit['@Institutional'] == 'Y'));

        let bLobFound = false;
        cciEditFilteredList.forEach((cciEdit: any) => {
            if (!bLobFound) {
                if (Array.isArray(cciEdit.LOBs.LOB)) {
                    let lobFound = cciEdit.LOBs.LOB.find((lob: any) => lob['@ID'] == lobId);
                    if (lobFound)
                        bLobFound = true;
                }
            }
        });
        return bLobFound;
    }

    private isAlreadyAddedPayer(payerId: string, selectedCCIId: string, formType: string): boolean {
        let cciEditFilteredList = this.props.payerSelectionOptions.CCIEdits.filter(cciedit => cciedit['@ID'] != selectedCCIId && (formType == 'P' ? cciedit['@Professional'] == 'Y' : cciedit['@Institutional'] == 'Y'));

        let bPayerFound = false;
        cciEditFilteredList.forEach((cciEdit: any) => {
            if (!bPayerFound) {
                if (Array.isArray(cciEdit.Payers.Payer)) {
                    let payerFound = cciEdit.Payers.Payer.find((payer: any) => payer['@ID'] == payerId);
                    if (payerFound)
                        bPayerFound = true;
                }
            }
        });
        return bPayerFound;
    }

    private isAlreadyAddedAlias(aliasId: string, selectedCCIId: string, formType: string): boolean {
        let cciEditFilteredList = this.props.payerSelectionOptions.CCIEdits.filter(cciedit => cciedit['@ID'] != selectedCCIId && (formType == 'P' ? cciedit['@Professional'] == 'Y' : cciedit['@Institutional'] == 'Y'));

        let bAliasFound = false;
        cciEditFilteredList.forEach((cciEdit: any) => {
            if (!bAliasFound) {
                if (Array.isArray(cciEdit.Aliases.Alias)) {
                    let aliasFound = cciEdit.Aliases.Alias.find((alias: any) => alias['@ID'] == aliasId);
                    if (aliasFound)
                        bAliasFound = true;
                }
            }
        });
        return bAliasFound;
    }

    private getLOBPayerAliasList(option: string, formType: string, selectedCCIId: string) {
        switch (option) {
            case "LOB": {
                // Return all the LOB that have not yet been added to a setting for the formType, unless it is the currently selected setting.
                let lobList = this.props.payerSelectionOptions.LOBList.filter(lob => lob['@Name'].toUpperCase() != "MEDICARE");
                let filteredLobList = lobList.filter(lob => !this.isAlreadyAddedLOB(lob['@ID'], selectedCCIId, formType));
                return filteredLobList;
            }
            case "PAYER": {
                // Return all the payers that have not yet been added to a setting for the formType, unless it is the currently selected setting.
                let payerList = this.props.payerSelectionOptions.PayerList.filter(payer => payer['@Type'] != (formType == 'P'?'H':'P'));
                let filteredPayerList = payerList.filter(payer => !this.isAlreadyAddedPayer(payer['@ID'], selectedCCIId, formType));
                return filteredPayerList;
            }

            case "ALIAS": {
                // Return all the aliases that have not yet been added to a setting for the formType, unless it is the currently selected setting.
                let aliasList = this.props.payerSelectionOptions.AliasList.filter(alias => alias['@Type'] != (formType == 'P' ? 'H' : 'P'));
                let filteredAliasList = aliasList.filter(alias => !this.isAlreadyAddedAlias(alias['@ID'], selectedCCIId, formType));
                return filteredAliasList;
            }
        }
        return [];
    }
  
    private checkForSuppressedOptions(editData: any) {
        if (editData['@Professional'] == 'Y') {
            if (this.props.payerSelectionOptions.SuppressCCIBP) {
                // I think that in the IE page, the message would show when they changed the suppress in the main tab 
                // and the CCI edit custom settings rows would not be dynamically disabled, so we should not need these messages here.
                //this.props.displayAlertMessage("Professional Medical Necessity Edits will not apply because Suppress Professional is currently checked.");
                return true;
            }
        } else {
            if (this.props.payerSelectionOptions.SuppressCCIAI) {
               // this.props.displayAlertMessage("Professional Medical Necessity Edits will not apply because Suppress Institutional is currently checked.");
                return true;
            }
        }
        return false;
    }

    highlightSelectedRowByID(cciId: string) {
        var cciPayerSelectionGrid = document.getElementById("id-cci-payer-selection-options-grid");
        if (cciPayerSelectionGrid != null) {
            let query = "tr[data-testid='id-cci-ps-row-" + cciId + "']";
            var selectedRow = cciPayerSelectionGrid.querySelector(query);
            if (selectedRow)
                selectedRow.classList.add('selected');
        }
    }

    deselectAllRows() {
        // clear selected row highlights from ui
        var cciPayerSelectionGrid = document.getElementById("id-cci-payer-selection-options-grid");
        if (cciPayerSelectionGrid != null) {
            var selectedRows = cciPayerSelectionGrid.querySelectorAll('.selected');
            for (var i = 0; i < selectedRows.length; i++) {
                selectedRows[i].classList.remove('selected');
            }
        }
    }

    highlightSelectedRow(target: any) {
        if (target != null) {
            this.deselectAllRows();
            // set selected class to grid row
            target.className = 'selected';
        }
    }

    public onSelectCCIRow(e: any, record: any) {
        if (record && record.ID) {

            let editData = this.props.payerSelectionOptions.CCIEdits.find(edit => edit['@ID'] == record.ID);
            if (editData) {
                if (this.checkForSuppressedOptions(editData))
                    return;

                this.highlightSelectedRow(e.currentTarget);

                let ID = editData['@ID'];
                let lobPayerAliastOption = (editData?.LOBs?.LOB != null && editData.LOBs.LOB.length>0 ? "LOB" :
                    (editData?.Payers?.Payer != null && editData.Payers.Payer.length>0 ? "PAYER" : "ALIAS"));

                let newSelectedData = {
                    selectedCCIID: ID,
                    selectedFormType: (editData['@Professional'] == "Y" ? "P" : "H"),
                    selectedPayerOption: lobPayerAliastOption,
                    selectedLOBs: this.getSelectedLOBs(lobPayerAliastOption, editData),
                    selectedPayers: this.getSelectedPayers(lobPayerAliastOption, editData),
                    selectedAliases: this.getSelectedAliases(lobPayerAliastOption, editData),
                    selectedCPTGroups: this.getSelectedCPTGroups(editData),
                };

                this.props.onLocalStateChange({ ...this.props.localState, ...newSelectedData });
                this.setState({
                    ...newSelectedData,
                    payerList: this.getLOBPayerAliasList(lobPayerAliastOption, (editData['@Professional'] == 'Y' ? 'P' : 'H'), ID),
                });
            }         
        }
    }

    public onFormTypeChange(newFormType: string) {
        if (this.state.selectedFormType == newFormType || newFormType == '')
            return;        

        let payerOption = this.state.selectedPayerOption;
        if (payerOption == '')
            payerOption = "LOB";

        let newSelectedData = {
            selectedFormType: newFormType,
            selectedPayerOption: payerOption,
            selectedLOBs: [],
            selectedPayers: [],
            selectedAliases: [],
        };

        this.props.onLocalStateChange({
            ...this.props.localState,
            ...newSelectedData,
        });

        this.setState({
            ...newSelectedData,
            payerList: this.getLOBPayerAliasList(payerOption, newFormType, this.state.selectedCCIID),
        });
    }

    public onChangePayerOption(value: string) {
        if (this.state.selectedPayerOption == value || value == '')
            return;

        let newSelectedData = {
            selectedPayerOption: value,
        };

        this.props.onLocalStateChange({
            ...this.props.localState,
            ...newSelectedData,
        });

        this.setState({
            ...newSelectedData,
            payerList: this.getLOBPayerAliasList(value, this.state.selectedFormType, this.state.selectedCCIID),
        })

    }

    private validateFields() {
        if (this.state.selectedFormType == '') {
            this.props.displayAlertMessage('Please select a Form Type.');
            return false;
        }

        if (this.state.selectedPayerOption == "LOB" && this.state.selectedLOBs.length == 0) {
            this.props.displayAlertMessage('Please select a LOB.');
            return false;
        }

        if (this.state.selectedPayerOption == "PAYER" && this.state.selectedPayers.length == 0) {
            this.props.displayAlertMessage('Please select a Payer.');
            return false;
        }

        if (this.state.selectedPayerOption == "ALIAS" && this.state.selectedAliases.length == 0) {
            this.props.displayAlertMessage('Please select an Alias.');
            return false;
        }

        if (this.state.selectedCPTGroups.length == 0) { // should not go there...
            this.props.displayAlertMessage('Please select a CPT Group.');
            return false;
        }

        return true;
    }

    public onSelectPayer(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0)
            return [];

        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)   
                result.push(e.target.options[i].value);
        }
        switch (this.state.selectedPayerOption) {
            case 'LOB':
                this.props.onLocalStateChange({ ...this.props.localState, selectedLOBs: result, });
                this.setState({ selectedLOBs: result, });
                break;
            case 'PAYER':
                this.props.onLocalStateChange({ ...this.props.localState, selectedPayers: result, });
                this.setState({ selectedPayers: result, });
                break;
            case 'ALIAS':
                this.props.onLocalStateChange({ ...this.props.localState, selectedAliases: result, });
                this.setState({ selectedAliases: result, });
                break;
            default:
                break;
        }
    }

    public onSelectCPTGroup(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0)
            return [];

        let result = [];

        for (let i = 1; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }

        if (e.target.options[0].selected && result.length == 0)
            result.push(e.target.options[0].value);

        this.props.onLocalStateChange({ ...this.props.localState, selectedCPTGroups: result, });
        this.setState({ selectedCPTGroups: result, })
    }

    public onClickAddUpdateButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (!this.validateFields())
            return false;

        let action: "ADD"|"UPDATE"|"REMOVE" = "UPDATE";
        if (this.state.selectedCCIID == '') 
            action = "ADD";
 
        let payerOption = this.state.selectedPayerOption;
        let lobs: MCClaimOptionsSelectedLOBs = { LOB: [] };
        let payers: MCClaimOptionsSelectedPayers = { Payer: [] }; 
        let aliases: MCClaimOptionsSelectedAliases = { Alias: [] };
        let cptGroups: MCClaimOptionsSelectedCPTGroups = { CPTGroup: [] };
 
        switch (payerOption) {
            case "LOB":
                lobs.LOB = this.state.selectedLOBs.map((lob) => {
                    let newElt: MCClaimOptionsID = { '@ID': lob }; return newElt;
                });
                break;
            case "PAYER":
                payers.Payer = this.state.selectedPayers.map((payer) => {
                    let newElt: MCClaimOptionsID = { '@ID': payer }; return newElt;
                });
                break;
            case "ALIAS":
                aliases.Alias = this.state.selectedAliases.map((alias) => {
                    let newElt: MCClaimOptionsID = { '@ID': alias }; return newElt;
                });;
                break;
            default:
                break;
        }

        cptGroups.CPTGroup = this.state.selectedCPTGroups.filter(group => group != '-1').map((group) => {
            let newElt: MCClaimOptionsID = { '@ID': group }; return newElt;
        })

        let data: MCClaimOptionsCCIEdit = { 
            '@ID': this.state.selectedCCIID,
            '@Professional': (this.state.selectedFormType == 'P'?'Y':'N'),
            '@Institutional': (this.state.selectedFormType == 'H' ? 'Y' : 'N'),
            LOBs: lobs,
            Payers: payers,
            Aliases: aliases,
            CPTGroups: cptGroups,
        }

        let request: IPayerSelectionUpdateRequest = { Action: action, Value: data };
        this.props.updateCCIPayerSelectionOptions(request);

        this.clearEntries();
    }

    private clearEntries() {
        this.deselectAllRows();

        let newSelectedData = {
            selectedCCIID: '',
            selectedFormType: '',
            selectedPayerOption: '',
            selectedLOBs: [],
            selectedPayers: [],
            selectedAliases: [],
            selectedCPTGroups: ["-1"],
        };

        this.props.onLocalStateChange({
            ...this.props.localState,
            ...newSelectedData,
        });

        this.setState({
            ...newSelectedData,
            payerList: [],
        });
    }

    public onClickClearButton() {
         this.clearEntries();
    }

    public onClickRemoveButton(e: React.ChangeEvent<HTMLButtonElement>) {
 
        this.deselectAllRows();

        let request: IPayerSelectionUpdateRequest = { Action: "REMOVE", Value: this.state.selectedCCIID };
        this.props.updateCCIPayerSelectionOptions(request);

        let newSelectedData = {
            selectedCCIID: '',
            selectedFormType: '',
            selectedPayerOption: '',
            selectedLOBs: [],
            selectedPayers: [],
            selectedAliases: [],
            selectedCPTGroups: ["-1"],
        };

        this.props.onLocalStateChange({
            ...this.props.localState,
            ...newSelectedData,
        });

        this.setState({
            ...newSelectedData,
            payerList: [],
        });
    }

    private getDataName(dataId: string, dataArray: any[], notFoundText: string) {
        if (dataArray && dataArray.length > 0) {
            let lobObject = dataArray.find(data => data['@ID'] == dataId);
            if (lobObject)
                return lobObject['@Name'];
        }
        return notFoundText;
    }

    private styles = {
        disabledStyle: {
            color: 'lightgrey',
        },
        cptTableStyle: {
            border: '0',
            cellspacing: '0',
            cellpadding: '0',
        },
        cptTdStyle: {
            fontSize: '8pt',
            paddingLeft: '0pt',
            paddingRight: '0pt',
        }
    }

    private renderRowLOBData(lobs: any[], id: string, disabled:boolean) {     
      if (lobs && lobs.length > 0) { 
            return lobs.map((lob, index) => {  
                let key = 'cci-ps-lob-' + id + '-' + index;
                let notFoundText = 'Unknown LOB ' + lob['@ID'];
                if (disabled)
                    return (<span style={this.styles.disabledStyle} key={key}>{this.getDataName(lob['@ID'], this.props.payerSelectionOptions.LOBList, notFoundText)}<br /></span>)
                return (<span key={key}>{this.getDataName(lob['@ID'], this.props.payerSelectionOptions.LOBList, notFoundText)}<br /></span>)
            })
        }
        else {
            let key = 'cci-ps-lob-' + id ;
            return (<span key={key}></span>);
        }
    }
    private renderRowPayerOrAlias(payers: any[], aliases: any[], id: string, disabled: boolean) {
        if (payers && payers.length > 0) {
           return payers.map((payer, index) => {
               let key = 'cci-ps-payer-' + id + '-' + index;
               let notFoundText = 'Unknown Payer ' + payer['@ID'];
               if (disabled)
                   return (<span style={this.styles.disabledStyle} key = { key } > { this.getDataName(payer['@ID'], this.props.payerSelectionOptions.PayerList, notFoundText) } < br /></ span>)
               return (<span key={key}>{this.getDataName(payer['@ID'], this.props.payerSelectionOptions.PayerList, notFoundText)}<br /></span>)
            })
        }
        else if (aliases && aliases.length > 0) {
            if (aliases && aliases.length > 0) {
                return aliases.map((alias, index) => {
                    let key = 'cci-ps-alias-' + id + '-' + index;
                    let notFoundText = 'Unknown Alias ' + alias['@ID'];
                    if (disabled)
                        return (<span style={this.styles.disabledStyle} key={key}>{this.getDataName(alias['@ID'], this.props.payerSelectionOptions.AliasList, notFoundText)}<br /></span>)
                    return (<span key={key}>{this.getDataName(alias['@ID'], this.props.payerSelectionOptions.AliasList, notFoundText)}<br /></span>)
                })
            }
        }
        else {
            let key = 'cci-ps-payeroralias-' + id;
            return (<span key={key}></span>);
        }
    }

    private renderRowCPTGroupData(groups: any[], id: string, disabled: boolean) {
        if (groups && groups.length > 0) {
            return groups.map((group, index) => {
                let key = 'cci-ps-cptgroup-' + id + '-' + index;
                let notFoundText = 'Unknown CPT Group ' + group['@ID'];
                if (disabled)
                    return (<span style={this.styles.disabledStyle} key={key}>{this.getDataName(group['@ID'], this.props.payerSelectionOptions.CPTGroupList, notFoundText)}<br /></span>)
                return (<span key={key}>{this.getDataName(group['@ID'], this.props.payerSelectionOptions.CPTGroupList, notFoundText)}<br /></span>)
            });
        }
        else {
            let key = 'cci-ps-cptgroup-' + id;
            if (disabled)
                return (<span style={this.styles.disabledStyle} key={key}>'All CPT Groups'</span>); 
            return (<span key={key}>All CPT Groups</span>);
        }
    }

    private renderRowForm(professional: string, id: string, disabled: boolean) {
        if (disabled)
            return (<span id={'id-cci-ps-form-' + id} style={this.styles.disabledStyle} key={'cci-ps-form-' + id}>{professional == 'Y' ? 'PRO' : 'INST'}</span>);
        return (<span id={'id-cci-ps-form-' + id} key={'cci-ps-form-' + id}>{professional == 'Y' ? 'PRO' : 'INST'}</span>);
    }

    private GetGridData(): any[] {
        let gridData: any[] = [];
        if (this.props.payerSelectionOptions && this.props.payerSelectionOptions.CCIEdits) {

            this.props.payerSelectionOptions.CCIEdits.forEach(cciEdit => {
                let disabled = this.props.payerSelectionOptions.SuppressCCIBP && cciEdit['@Professional'] == 'Y' ||
                    this.props.payerSelectionOptions.SuppressCCIAI && cciEdit['@Professional'] != 'Y';

                let rowData = {
                    ID: cciEdit['@ID'],
                    LOB: this.renderRowLOBData(cciEdit.LOBs.LOB, cciEdit['@ID'], disabled) , 
                    PayerOrAlias: this.renderRowPayerOrAlias(cciEdit.Payers.Payer, cciEdit.Aliases.Alias, cciEdit['@ID'], disabled),
                    CPTGroup: this.renderRowCPTGroupData(cciEdit.CPTGroups.CPTGroup, cciEdit['@ID'], disabled),
                    Form: this.renderRowForm(cciEdit['@Professional'], cciEdit['@ID'], disabled),
                };
                gridData.push(rowData);
              
            });

        }
        return gridData;
    }

    CCIDisabledRowIDs = () => {

        let rows = new Set();

        if (this.props.payerSelectionOptions && this.props.payerSelectionOptions.CCIEdits) {
            if (this.props.payerSelectionOptions.SuppressCCIBP || this.props.payerSelectionOptions.SuppressCCIAI)
            this.props.payerSelectionOptions.CCIEdits.forEach(cciEdit => {
                if (cciEdit['@Professional'] == 'Y' && this.props.payerSelectionOptions.SuppressCCIBP ||
                    cciEdit['@Professional'] != 'Y' && this.props.payerSelectionOptions.SuppressCCIAI) {
                    rows.add(cciEdit['@ID']);
                }
            });
        }
        return rows;
    }

    public render() {
        if (!this.props.canView)
            return <div></div>;

        let CCIPayerSelectionData: any[] = this.GetGridData();
        return (
                <ContentWrapper id={"content_wrapper_PayerSelectionClaimsOptions"}> 
                        <DialogFieldset id='id-cci-payer-fieldset'>
                            <DialogLegend3 helpUrl='/Support/Help/HELP_Maint_ClaimOptionsCCIEdits.htm'>CCI Edit Custom Settings</DialogLegend3>
                            <GridWrapperCCIPayerSelectionNotes>
                                <GridWrapper>
                                    <Grid
                                        domID="id-cci-payer-selection-options-grid"
                                        dataTestId="id-cci-ps"
                                        isFixedHeader={true}
                                        maxHeight='170px'
                                        columns={this.CCIPayerSelectionGridColumns()}
                                        records={CCIPayerSelectionData}
                                        initialDisabledItemIds={this.CCIDisabledRowIDs()}  // this is not working though.... at least with onClickTHrough...
                                        onClickThrough={(e: any, record: any) => { this.onSelectCCIRow(e, record) }}
                                        selectionKey='ID'
                                    />
                                 </GridWrapper>
                            </GridWrapperCCIPayerSelectionNotes>
                  <div>
                    <ContentRowWrapper>
                       <DialogFieldset id='id-cci-payer-form-type-fieldset'>
                       <DialogLegend>Form Type</DialogLegend>
                            <RadioButtonComponent
                                id="id-cci-payer-prof-radio"
                                label="Professional"
                                width='140px'
                                disabled={this.props.payerSelectionOptions.SuppressCCIBP || !this.props.canEdit}
                                checked={this.state.selectedFormType == "P"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.onFormTypeChange("P"); }}
                            />
                            <RadioButtonComponent
                                id="id-cci-payer-inst-radio"
                                label="Institutional"
                                width='140px'
                                disabled={this.props.payerSelectionOptions.SuppressCCIAI || !this.props.canEdit}
                                checked={this.state.selectedFormType == "H"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.onFormTypeChange("H"); }}
                            />
                        </DialogFieldset>
                        <DialogFieldset id='id-cci-payer-lob-or-payer-fieldset'>
                            <DialogLegend>LOB or Payer</DialogLegend>
                            <ContentRowWrapper id='id-cci-payer-payer-or-alias'>
                              <RadioButtonComponent
                                id="id-cci-payer-lob-radio"
                                label="LOB"
                                width='80px'
                                disabled={this.state.selectedFormType == '' || !this.props.canEdit}
                                checked={this.state.selectedPayerOption == "LOB"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePayerOption("LOB")}
                            />
                            <RadioButtonComponent
                                id="id-cci-payer-payer-radio"
                                label="Payer"
                                width='80px'
                                disabled={this.state.selectedFormType == '' || !this.props.canEdit}
                                checked={this.state.selectedPayerOption == "PAYER"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePayerOption("PAYER")}
                            />
                            <RadioButtonComponent
                                id="id-cci-payer-alias-radio"
                                label="Alias"
                                width='80px'
                                disabled={this.state.selectedFormType == '' || !this.props.canEdit}
                                checked={this.state.selectedPayerOption == "ALIAS"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePayerOption("ALIAS")}
                            />
                            </ContentRowWrapper>
                            <SelectComponent
                                title='lobPayerSelect'
                                size={8}
                                width='270px'
                                 optionFields={{
                                    value: "@ID",
                                    text: "@Name",
                                }}
                                disabled={this.state.selectedFormType == '' || !this.props.canEdit}
                                records={this.state.payerList}
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPayer(e)}
                                selectedMultiValue={this.state.selectedPayerOption == "LOB" ? this.state.selectedLOBs :
                                        (this.state.selectedPayerOption == "PAYER" ? this.state.selectedPayers :
                                        (this.state.selectedPayerOption == "ALIAS" ? this.state.selectedAliases : []))
                                        }
                                multiple='true'
                            ></SelectComponent>
                        </DialogFieldset>

                        <DialogFieldset id='id-cci-payer-cpt-group-fieldset'>
                            <DialogLegend>CPT Group</DialogLegend>
                            <SelectComponent
                                title='cptGroupSelect'
                                size={10}
                                width='550px'
                                optionFields={{
                                    value: "@ID",
                                    text: "@Name",
                                }}
                                records={this.state.cptGroupList}
                                disabled={ !this.props.canEdit}
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectCPTGroup(e)}
                                selectedMultiValue={this.state.selectedCPTGroups}
                                multiple='true'
                            ></SelectComponent>
                    </DialogFieldset>
                                
                    </ContentRowWrapper>
                        <ContentRowWrapper>
                            <SelectButtons>
                            <Button
                                domID="id-cci-add-button"
                                name={this.state.selectedCCIID == '' || !this.props.canEdit ? "Add" : "Update"}
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canView || !this.props.canEdit}
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickAddUpdateButton(e)}}
                            />
                            <Button
                                domID="id-cci-clear-button"
                                name="Clear"
                                buttonType="standard"
                                size="small"
                                type="button"
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickClearButton(/*e*/)}}
                            />
                            <Button
                                domID="id-cci-remove-button"
                                name="Remove"
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canView || !this.props.canDelete || this.state.selectedCCIID == ''} 
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickRemoveButton(e) }}
                                />
                            </SelectButtons>
                        </ContentRowWrapper>
                      </div>
                </DialogFieldset>   
                <CenteredContent><DialogInstruction>{this.instructions}</DialogInstruction></CenteredContent>
                </ContentWrapper>
        );
    }
};


