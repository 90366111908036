import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';

// uicl
import { Grid } from 'ui-core';

import styled from 'styled-components';

// commonResources
import { URLs } from '@commonDevResources/constants';
import { pageLeave } from '@commonResources/userModified';

// common
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { Input } from '@common/UICLWrappers/ARMInput';
import AssuranceMenu from '../../common/AssuranceMenu';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';

// store includes
import { ApplicationState } from '@store/index';
import { ISelectReasonCodeState, ISelectReasonCodeActionProps, actionCreators } from '@store/SelectReasonCode';


export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

const GridWrapper = styled.div`
    .empty-row{
        background-color:white;
    }

    thead tr th{
        background-color:#9BA1A9;
        height:20px;
    }

    table tbody tr > td{
        height:20px;
    }

    table tbody tr td div{
        min-height:1px;
    }
`;

const InputContainer = styled.div`
    padding:7px;
`;

/////////////////////////////////////////////////////////////////////////////////////////////

export interface ISelectReasonCodeComponentProps {
    canEdit: boolean,
    canView: boolean,
    apiType: string;
    process: string;
    accessList: Array<boolean>;
}

interface ISelectReasonCodeParams {
    Process: string;
}

interface IComponentState {
    cancelLeave: boolean,
    selectedCodeName: string,
    descriptionText: string,
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    selectedCodeName: '',
    descriptionText: ''
};

type IOwnProps = ISelectReasonCodeComponentProps & RouteComponentProps<ISelectReasonCodeParams>;
type ISelectReasonCodeProps = IMergeApiComponentProps<ISelectReasonCodeState, ISelectReasonCodeActionProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class SelectReasonCode extends React.Component<ISelectReasonCodeProps, IComponentState> {

    static defaultProps: ISelectReasonCodeComponentProps = {
        canEdit: false,
        canView: false,
        apiType: "SelectReasonCode",
        process: "",
        accessList: [false, false],
    };

    constructor(props: ISelectReasonCodeProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        let process = this.props.match.params.Process;
        var processKey = '';
        if (process.toLowerCase() === "rebill")
            processKey = "rebl";
        else if (process.toLowerCase() === "delete")
            processKey = "dele";

        pageLeave();

        this.setBusy(true);
        this.props.action.api.loadData(URLs.api + '/api/data/securityInfo/reasonCodes/' + processKey, this.validationCallback.bind(this), this.errorCallback.bind(this));
    }

    public componentWillUnmount() {
        pageLeave();
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        let process = this.props.match.params.Process;
        var code = (this.props.dataStore.ui.selectedCode == undefined || this.props.dataStore.ui.selectedCode.length == 0 ? "" : this.props.dataStore.ui.selectedCode);
        this.props.history.push('/Claims/Filter/' + process, { parameter: code });
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/LandingPage');
    }

    highlightSelectedRow(target: any) {
        if (target != null) {
            // clear selected row highlights from ui
            var rcGrid = document.getElementById("reason-codes-grid");
            if (rcGrid != null) {
                var selectedRows = rcGrid.querySelectorAll('.selected');
                for (var i = 0; i < selectedRows.length; i++) {
                    selectedRows[i].classList.remove('selected');
                }
            }
            // set selected class to grid row
            target.className = 'selected';
        }
    }

    public onSelectReasonCode(e: any, record: any) {
        if (record) {

            this.highlightSelectedRow(e.currentTarget);

            this.props.action.ui.selectReasonCode(
                {
                    apiData: this.props.dataStore.api.data,
                    uiData: {
                        code: record.code,
                        description: record.description
                    }
                });
        }
    }

    public validationCallback(data: any): boolean {
       this.setBusy(false);
       return true;
    }

    public errorCallback(dataStatus: string): boolean {
        this.setBusy(false);
        var str = 'An error occurred retrieving reason codes. Error: ' + dataStatus;
        console.error(str);
        return true;
    }

    public setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    getGridColumnHeaders() {
        return new Set([
                {
                    dataName: "code",
                    text: 'Code',
                    sortable: true,
                    cellType: "text",
                    isSorted: 0,
                },
                {
                    dataName: "description",
                    text: "Description",
                    sortable: true,
                    cellType: "text",
                    isSorted: 1,
                },
            ]);
    }

    public render()
    {
        var instruction = <React.Fragment>Select a reason code. Then, click OK to use the filter and apply the reason code to the claims.<br /> <i>(For more information, click the help button.)</i></React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canView || !this.props.dataStore.ui.changed} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        var process = this.props.match.params.Process;
        var titleText = "View " + process + " Reason Codes";
        var apiData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];
        var codes : any = [];
        if (apiData && apiData.codes) {
            codes = apiData.codes ? apiData.codes : codes;
            if (codes) {
                if (codes.length == 0)
                {
                    this.props.history.push('/Claims/Filter/' + process, { parameter: ''});
                }
            }
        }
        if(apiData.codes)
        return (
            <DialogWrapper title={titleText} instruction={instruction} helpUrl='/Support/Help/HELP_SubmittedReasonCodes.htm' buttons={buttons} isBusy={this.props.dataStore.ui.isBusy || this.props.dataStore.api.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <ContentWrapper>
                      <SelectList>
                        <DialogFieldset height='100%'>
                            <GridWrapper>
                                <Grid
                                    columns={this.getGridColumnHeaders()}
                                    domID='reason-codes-grid'
                                    isFixedHeader={true}
                                    maxHeight='400px'
                                    records={codes}
                                    onClickThrough={(e: any, record: any) => { this.onSelectReasonCode(e, record) }}
                                    emptyGridMessage='No Reason Codes Available.'
                                />
                            </GridWrapper>
                         </DialogFieldset>
                        </SelectList>
                    </ContentWrapper>
                </ContentWrapper>
             </DialogWrapper>
        );
        else return null;
    }
};

var connectedHoc = connect<ISelectReasonCodeState, ISelectReasonCodeActionProps, IOwnProps, ISelectReasonCodeProps, ApplicationState>(
    createApiMapStateToProps('selectReasonCode'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(SelectReasonCode);


export default withRouter(connectedHoc);