import * as React from 'react';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { ARMGrid } from '../../common/UICLWrappers/ARMGrid';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';
import { ContentRowWrapper } from '@common/DialogWrapper';
import _ from 'lodash';

interface IComponentProps {
    data: any;
    closeModal: () => void;
    searchText: string;
    onOK: (updatedMaintenanceInfo: any) => void;
    maintenanceInfo: any;
};

interface IComponentState {

    groupList: any,
    localPayerList: PayerListItem[],
    selectedPayers: PayerListItem[],

};

interface UiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
    width?: number,
};

interface RowProps {
    record: {
        id: number;
        payerName: string;
        on: boolean;
        rtecpid: string;
    }
}

interface PayerListItem {
    id: number,
    name: string,
    on: boolean,
    rtecpid: string;

}

const DEFAULT_STATE = {
    groupList: [],
    localPayerList: [],
    selectedPayers: [],
    
};


const CenteredCellGrid = styled(ARMGrid)`

  && table {
    border-spacing: 0;
    background: ${Colors.grey10};
    width: 480px;
  }

  && thead {
     background: ${Colors.grey10};
     padding-right: 0px;
  }

  && th {
    background: ${Colors.grey10};
  }

  && span.th-no-sort {
    padding-right: 0px;
    text-align: center;
  }

  && tr:hover {
    background: ${Colors.brightBlue10} !important;
    cursor:pointer;
 }

 ${({ on }: any) => on && `
    background: ${Colors.digitalOrange50} !important;
`}

tr.on {
     background: ${Colors.digitalOrange50} !important;
}

&& td {
    text-align: left;
    vertical-align: middle;
    padding: 6px;
    background: none;
}`;

/*
 * NOTE: We allow the component to manage its own local state 
 *  We rehydrate the selected items to Client Payer recoreds onOK
 * 
 */

export default class AddtionalPayers extends React.Component<IComponentProps, IComponentState> {

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {

        if (this.props.data.length > 0 && this.state.localPayerList.length === 0) {    
            //@ts-ignore
            let groupListItems: GroupListItem[] = (this.props.data).map((ele, index, array) => {
                let eligiblityProfiles = this.props.maintenanceInfo.EligibilityPayerProfiles.PayerProfile;
                const enrollmentReq = eligiblityProfiles.find((profile: any) => profile["@RTECPID"] === ele["@RTECPID"]);
                const asterix = enrollmentReq && enrollmentReq["@EnrollmentRequired"] === "Y" ? '*' : '';
                const record = { id: ele['@ID'], name: `${ele['@DisplayName']} (${ele['@RTECPID']})${asterix}`, on: false, rtecpid: ele['@RTECPID'] };
                return record;
            });

            this.setState({
                localPayerList: groupListItems
            });
        }

    }

    public componentDidUpdate() {}

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
       
        const { onOK, maintenanceInfo } = this.props; // onOK is passed in from parent though props
      
        const { EligibilityPayerProfiles } = maintenanceInfo;

        const selectedAvailablePayers = this.state.selectedPayers.map((el) => {
            const profile = EligibilityPayerProfiles.PayerProfile.find((n: any) => n["@RTECPID"] === el.rtecpid)
            return {
                '@RTECPID': profile["@RTECPID"] ? profile["@RTECPID"] : '',
                '@PayerID': el.id,
                '@Name': el.name.substring(0, el.name.indexOf("(") - 1),
                '@DisplayName': el.name,
                '@ID': el.id,
            }
        });
        
        onOK( selectedAvailablePayers );
      
    }

    public toggleChecked(e: React.MouseEvent<HTMLTableRowElement> | React.ChangeEvent<HTMLInputElement>, targetIndex: number, targetRTECPID: string) {

        // Make mutable copy before reassignment
        let mutableCopy = [...this.state.localPayerList];

        // Find the index of the clicked on element based on its ID
        let selectedIndex = mutableCopy.findIndex(element => element.id === targetIndex && element.rtecpid === targetRTECPID);

        if (selectedIndex >= 0) {
            mutableCopy[selectedIndex].on = !mutableCopy[selectedIndex].on;
        }
        // Filter the items for those that are selected
        const selectedItems = mutableCopy.filter((el) => el.on === true);

        this.setState({
            localPayerList: mutableCopy,
            selectedPayers: selectedItems
        })
    }


    public toggleCheckbox(e: React.ChangeEvent<HTMLInputElement>, targetIndex: number) {
        // This is redundant for now as the table row handles the selection
        e.preventDefault();
    }

    public getGridColumnHeaders() {
        const columns: UiclColumn[] = [
            {
                dataName: "on",
                text: "Add",
                sortable: false,
                cellType: "text",
                isSorted: 0
            },
            {
                dataName: "@DisplayName",
                text: "Payer",
                sortable: false,
                cellType: "text",
                isSorted: 0
            },
            
        ]

        return new Set(columns);

    }

    public getCustomRow() {
        const row = ({ record }: RowProps) =>
            <tr
                id={`${record.id}-${record.rtecpid}-grouplist-row`}
                key={`${record.id}-${record.rtecpid}`}
                className={record.on ? 'on' : ''}
                onClick={(e: React.MouseEvent<HTMLTableRowElement>) => this.toggleChecked(e, record.id, record.rtecpid)}
            >
                <td style={{ textAlign: "center" }}>
                    <input
                        id={`${record.id}-${record.rtecpid}-chk`}
                        type='checkbox'
                        checked={!!record.on}
                        name="apply"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.toggleCheckbox(e, record.id)}
                    />
                </td>
                <td title={record.payerName}>
                    {record.payerName}
                </td>
            </tr>

        return row;

    }

    buttons = <OKCancelButtons
        disableOK={false}
        onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onOK(e) }}
        onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => { this.props.closeModal() }}
    />

    render() {
        return (
            <React.Fragment>
                <DialogWrapper
                    title="Pick Eligibility Payers"
                    helpUrl=""
                    buttons={this.buttons}
                    width={`500px`}
                    marginTop={`0px`}
                >
                    <ContentRowWrapper>
                        <CenteredCellGrid
                            domID="additional-payers-grid"
                            key="AdditionalPayersGrid"
                            isFixedHeader={true}
                            maxHeight='400px'
                            emptyGridMessage='No Payers Found'
                            selectionKey='uuid'
                            columns={this.getGridColumnHeaders()}
                            height={200}
                            rowComponent={this.getCustomRow()}
                            scrollToTop={false}
                            records={this.state.localPayerList.map((record: PayerListItem, index: number) => {
                                return {
                                    id: record.id,
                                    payerName: record.name,
                                    on: record.on,
                                    rtecpid: record.rtecpid,
                                    uuid: `${record.id}-${record.rtecpid}`
                                }
                            })}
                        />
                    </ContentRowWrapper>

                </DialogWrapper>

            </React.Fragment>
        )
    }

}