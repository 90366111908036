import * as React from "react";
import { DialogFieldset, DialogLegend } from "../../../common/DialogStyles";
import { CrudPermissions } from "../CrudPermissions";
import { DefaultFacilityGenerator } from "../DefaultFacilityGenerator";
import { RequiredFieldValidator } from "../Validators/RequiredFieldValidator";
import { RegExValidator } from "../Validators/RegExValidator";
import { Utility } from "../Utility";
import { GenericValidator } from "../Validators/GenericValidator";
import { IsAlphaNumeric, IsNumeric } from "../../../../commonResources/validations";
import { Facility, List, MCFacilityMaintenanceInfo } from "../../../../store/api/FacilityMasterType";

import helpIcon from '@content/images/QMark_blue.png';
import * as wind from '@commonResources/window';

interface IComponentProps {
    maintenanceInfo: MCFacilityMaintenanceInfo | undefined,
    facility: Facility,
    crudPermissions: CrudPermissions,
    isFacilityInfoSubmit: boolean,
    payerInfoSubmitCount: number,
    validationAggregation: Map<string, boolean>,
    validationAggregationRoutine: Function,
    facilityUpdateRoutine: Function,
    fieldFocus: string,
    cursorPos: number,
    onCopyDupFacSubId: boolean
};

interface IComponentState {
    originalFacility: Facility,
    facility: Facility,
    isFacilityInfoSubmit: boolean
}

export const DEFAULT_STATE: IComponentState = {
    originalFacility: DefaultFacilityGenerator.generateDefaultFacility(),
    facility: DefaultFacilityGenerator.generateDefaultFacility(),
    isFacilityInfoSubmit: false,
}

export class General extends React.Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props);
    }

    getIDTypesDropdown() {
        const {
            maintenanceInfo,
            fieldFocus,
            facilityUpdateRoutine,
            facility,
        } = this.props;

        // prepend a default option to the start of the ID Types list
        let idTypes: List[] = [{ "@ID": "0", "@Name": "- Select ID Type -" }].concat(
            maintenanceInfo?.IDTypeList.IDType || []);

        return (
            <select
                id='IDTypeID'
                autoFocus={fieldFocus == 'IDTypeID'}
                style={{ height: 26 }}
                onChange={x => {
                    facilityUpdateRoutine("@IDTypeID", x.currentTarget.value, 'IDTypeID')
                }}
                value={facility["@IDTypeID"]}
            >
                {idTypes && idTypes.map(x => {
                    return <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                })}
            </select>
        );
    }

    getFacilityTypesDropdown() {
        const {
            maintenanceInfo,
            fieldFocus,
            facilityUpdateRoutine,
            facility,
            payerInfoSubmitCount,
        } = this.props;

        // prepend a default option to the start of the ID Types list
        let facilityTypes: List[] = [{ "@ID": "0", "@Name": "- Select Facility Type -" }].concat(
            maintenanceInfo?.FacilityTypeList.FacilityType || []);

        return (
            <select
                id='FacilityTypeID'
                autoFocus={fieldFocus == 'FacilityTypeID'}
                style={{ height: 26 }}
                onChange={x => {
                    facilityUpdateRoutine("@FacilityTypeID", x.currentTarget.value, 'FacilityTypeID');
                }}
                value={facility["@FacilityTypeID"]}
            >
                {facilityTypes && facilityTypes.map(x => {
                    return <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                })}
            </select>
        );
    }

    getEnrollmentTypesDropdown() {
        const {
            maintenanceInfo,
            fieldFocus,
            facilityUpdateRoutine,
            facility,
        } = this.props;

        // prepend a default option to the start of the ID Types list
        let enrollmentTypes: List[] =
            [{ "@ID": "0", "@Name": "- Select Enrollment Type -" }].concat(
                maintenanceInfo?.EnrollmentTypeList.EnrollmentType || []);

        return (
            <select
                id='EnrollmentTypeID'
                autoFocus={fieldFocus == 'EnrollmentTypeID'}
                style={{ height: 26 }}
                onChange={x => {
                    facilityUpdateRoutine("@EnrollmentTypeID", x.currentTarget.value, 'EnrollmentTypeID')
                }}
                value={facility["@EnrollmentTypeID"]}
            >
                {enrollmentTypes && enrollmentTypes.map(x => {
                    return <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                })}
            </select>
        );
    }

    getPrintOptionsDropdown() {
        const {
            maintenanceInfo,
            fieldFocus,
            facilityUpdateRoutine,
            facility,
        } = this.props;

        // prepend a default option to the start of the Print Options list
        let printOptionTypes: List[] =
            [{ "@ID": "0", "@Name": "- Select Print Option -" }].concat(
                maintenanceInfo?.PaperClaimsPrintingOptionTypeList.PaperClaimsPrintingOptionType || []);

        return (
            <select
                id='PaperClaimsPrintingOptionTypeID'
                autoFocus={fieldFocus == 'PaperClaimsPrintingOptionTypeID'}
                style={{ height: 26 }}
                onChange={x => {
                    facilityUpdateRoutine(
                        "@PaperClaimsPrintingOptionTypeID",
                        x.currentTarget.value,
                        'PaperClaimsPrintingOptionTypeID')
                }}
                value={facility["@PaperClaimsPrintingOptionTypeID"]}
            >
                {printOptionTypes && printOptionTypes.map(x => {
                    return <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                })}
            </select>
        );
    }

    getStatesDropdown() {
        const {
            maintenanceInfo,
            fieldFocus,
            facilityUpdateRoutine,
            facility,
        } = this.props;

        // prepend a default option to the start of states
        let states: List[] = [{ "@ID": "0", "@Name": "- Select State -" }].concat(
            // filter out redundant blank record with id = 0
            maintenanceInfo?.StateList?.State.filter(x => x["@ID"] != '0') || []);

        return (
            <select
                id='ServiceStateID'
                autoFocus={fieldFocus == 'ServiceStateID'}
                style={{ height: 26 }}
                onChange={x => {
                    facilityUpdateRoutine("@ServiceStateID", x.currentTarget.value, 'ServiceStateID')
                }}
                value={facility["@ServiceStateID"]}
            >
                { states && states.map(x => {
                    return <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                })}
            </select>
        );
    }

    inputOnChange(x: React.ChangeEvent<HTMLInputElement>) {
        const {
            facilityUpdateRoutine,
        } = this.props;

        const inputId = x.currentTarget.id;
        const fieldId = '@' + inputId;
        const inputValue = x.currentTarget.value;
        const cursorPos = x.target.selectionStart;

        facilityUpdateRoutine(fieldId, inputValue, inputId, cursorPos)
    }

    inputOnBlur(x: React.FocusEvent<HTMLInputElement>) {
        const {
            facilityUpdateRoutine,
        } = this.props;

        const inputId = x.currentTarget.id;
        const fieldId = '@' + inputId;

        // remove leading and trailing spaces from the input value per legacy
        let inputValue = x.currentTarget.value.trim();
        x.currentTarget.value = inputValue;

        // cursor pos of 0 selects all text in the input
        facilityUpdateRoutine(fieldId, inputValue, inputId, 0);
    }

    render() {
        const {
            validationAggregation,
            validationAggregationRoutine,
            facilityUpdateRoutine,
            fieldFocus,
            maintenanceInfo,
            cursorPos,
            facility,
            isFacilityInfoSubmit,
        } = this.props;

        const ddlStates = this.getStatesDropdown();
        const ddlIDTypes = this.getIDTypesDropdown();
        const ddlFacilityTypes = this.getFacilityTypesDropdown();
        const ddlEnrollmentTypes = this.getEnrollmentTypesDropdown();
        const ddlPrintOptions = this.getPrintOptionsDropdown();

        // dup facility sub id logic
        const filterResults = maintenanceInfo?.Facilities.Facility.filter(x =>
            x["@FacilitySubId"] == facility["@FacilitySubId"] &&
            // exclude current selected facility
            x["@ID"] != facility["@ID"] &&
            // filter out empty string scenario
            x["@FacilitySubId"].length > 0);
        const isDupFacilitySubId = (filterResults && filterResults.length > 0) || false;

        const facilitySubIdTrigger =
            isFacilityInfoSubmit &&
            !facility["@FacilitySubId"];
            

        return (
            <>
                <table>
                    <tbody>
                        {/* Service State */}
                        <tr>
                            <td className='fieldLabel'>Service State:</td>
                            <td style={{ width: 5 }}></td>
                            <td >{ddlStates}</td>
                        </tr>

                        {/* Service ZIP */}
                        <tr>
                            <td className='fieldLabel' >Service ZIP:</td>
                            <td style={{ width: 5 }}></td>
                            <td>
                                <input
                                    id='ServiceZipCode'
                                    autoFocus={fieldFocus == 'ServiceZipCode'}
                                    type="text"
                                    value={facility["@ServiceZipCode"]}
                                    onChange={x => {
                                        this.inputOnChange(x);
                                    }}
                                    onBlur={x => {
                                        this.inputOnBlur(x);
                                    }}
                                    maxLength={9}
                                />
                            </td>
                        </tr>

                        {/* AlphaNumeric is allowed in legacy */}
                        <RegExValidator
                            fieldId={'ServiceZipCode'}
                            fieldName={'ServiceZipCode.RegEx.AlphaNumeric'}
                            trigger={
                                isFacilityInfoSubmit &&
                                !IsAlphaNumeric(facility["@ServiceZipCode"])
                            }
                            message={'Service ZIP must consist of valid characters'}
                            style={{ width: 250 }}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        {/* Facility SubID */}
                        <tr>
                            <td className='fieldLabel' >* Facility SubID:</td>
                            <td style={{ width: 5 }}></td>
                            <td>
                                <input
                                    id='FacilitySubId'
                                    autoFocus={fieldFocus == 'FacilitySubId'}
                                    type="text"
                                    value={facility["@FacilitySubId"]}
                                    onChange={x => {
                                        this.inputOnChange(x);
                                    }}
                                    onBlur={x => {
                                        this.inputOnBlur(x);
                                    }}
                                    maxLength={5}
                                />
                            </td>
                        </tr>

                        <RequiredFieldValidator
                            fieldId={'txtFacilitySubID.Required'}
                            fieldName={'Facility SubID'}
                            triggers={[
                                facilitySubIdTrigger
                            ]}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        <RegExValidator
                            fieldId={'txtFacilitySubID.Numeric'}
                            fieldName={'Facility SubID'}
                            trigger={
                                isFacilityInfoSubmit &&
                                facility["@FacilitySubId"] != '0' &&
                                !IsNumeric(facility["@FacilitySubId"])
                            }
                            message={'Facility SubID must be numeric'}
                            style={{ width: 250 }}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        <GenericValidator
                            fieldId={'txtFacilitySubID.Unique'}
                            fieldName={'Facility SubID'}
                            trigger={isDupFacilitySubId || this.props.onCopyDupFacSubId}
                            message={
                                'A Facility with the Facility SubID "' + facility["@FacilitySubId"] +
                                '" already exists.  Please enter a unique Facility SubID.'
                            }
                            style={{ width: 250 }}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        {/* Fed Tax ID */}
                        <tr>
                            <td className='fieldLabel' >* Fed Tax ID:</td>
                            <td style={{ width: 5 }}></td>
                            <td>
                                <input
                                    id='FedTaxId'
                                    autoFocus={fieldFocus == 'FedTaxId'}
                                    type="text"
                                    value={facility["@FedTaxId"]}
                                    onChange={x => {
                                        this.inputOnChange(x);
                                    }}
                                    onBlur={x => {
                                        this.inputOnBlur(x);
                                    }}
                                    maxLength={10}
                                />
                            </td>
                        </tr>

                        <RequiredFieldValidator
                            fieldId={'txtFedTaxID'}
                            fieldName={'Fed Tax ID'}
                            triggers={[
                                isFacilityInfoSubmit && !facility["@FedTaxId"],
                            ]}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        {/* Fed Tax SubID */}
                        <tr>
                            <td className='fieldLabel' >Fed Tax SubID:</td>
                            <td style={{ width: 5 }}></td>
                            <td>
                                <input
                                    id='FedTaxSubId'
                                    autoFocus={fieldFocus == 'FedTaxSubId'}
                                    type="text"
                                    value={facility["@FedTaxSubId"]}
                                    onChange={x => {
                                        this.inputOnChange(x);
                                    }}
                                    onBlur={x => {
                                        this.inputOnBlur(x);
                                    }}
                                    maxLength={4}
                                />
                            </td>
                        </tr>

                        {/* Taxonomy */}
                        <tr>
                            <td className='fieldLabel' >Taxonomy:</td>
                            <td style={{ width: 5 }}></td>
                            <td>
                                <input
                                    id='TaxonomyCode'
                                    autoFocus={fieldFocus == 'TaxonomyCode'}
                                    type="text"
                                    value={facility["@TaxonomyCode"]}
                                    onChange={x => {
                                        this.inputOnChange(x);
                                    }}
                                    onBlur={x => {
                                        this.inputOnBlur(x);
                                    }}
                                    maxLength={10}
                                />

                                <img onClick={x => wind.BigHelp('/Support/Help/HELP_TaxonomyCodes.htm')} 
                                     src='/Shared/Images/Icons/QMark_blue.png'
                                     style={{ position: 'relative', left: 5, top: 3, cursor: 'pointer', width:'17px', height: '17px'}}/>
                            </td>
                        </tr>

                        <RegExValidator
                            fieldId={'txtTaxonomy.AlphaNumeric'}
                            fieldName={'Taxonomy'}
                            message={'Taxonomy must consist of valid characters'}
                            trigger={
                                isFacilityInfoSubmit &&
                                !IsAlphaNumeric(facility["@TaxonomyCode"])
                            }
                            style={{ width: 275 }}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        {/* Facility NPI */}
                        <tr>
                            <td className='fieldLabel' >Facility NPI:</td>
                            <td style={{ width: 5 }}></td>
                            <td>
                                <input
                                    id='FacilityNPI'
                                    autoFocus={fieldFocus == 'FacilityNPI'}
                                    type="text"
                                    value={facility["@FacilityNPI"]}
                                    onChange={x => {
                                        this.inputOnChange(x);
                                    }}
                                    onBlur={x => {
                                        this.inputOnBlur(x);
                                    }}
                                    maxLength={10}
                                />
                            </td>
                        </tr>

                        <RegExValidator
                            fieldId={'txtFacilityNPI.AlphaNumeric'}
                            fieldName={'Facility NPI'}
                            message={'Facility NPI must consist of valid characters'}
                            trigger={
                                isFacilityInfoSubmit &&
                                !IsAlphaNumeric(facility["@FacilityNPI"])
                            }
                            style={{ width: 275 }}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        {/* ID Type */}
                        <tr>
                            <td className='fieldLabel' >* ID Type:</td>
                            <td style={{ width: 5 }}></td>
                            <td>{ddlIDTypes}</td>
                        </tr>

                        <RequiredFieldValidator
                            fieldId={'ddlIDType'}
                            fieldName={'ID Type'}
                            triggers={[
                                isFacilityInfoSubmit && ['', '0'].includes(facility["@IDTypeID"])
                            ]}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        {/* Facility Type */}
                        <tr>
                            <td className='fieldLabel'>* Facility Type:</td>
                            <td style={{ width: 5 }}></td>
                            <td>{ddlFacilityTypes}</td>
                        </tr>

                        <RequiredFieldValidator
                            fieldId={'ddlFacilityTypes'}
                            fieldName={'Facility Type'}
                            triggers={[
                                isFacilityInfoSubmit && ['', '0'].includes(facility["@FacilityTypeID"])
                            ]}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        {/* Enrollment Type */}
                        <tr>
                            <td className='fieldLabel'>* Enrollment Type:</td>
                            <td style={{ width: 5 }}></td>
                            <td>{ddlEnrollmentTypes}</td>
                        </tr>

                        <RequiredFieldValidator
                            fieldId={'ddlEnrollmentType'}
                            fieldName={'Enrollment Type'}
                            triggers={[
                                isFacilityInfoSubmit && ['', '0'].includes(facility["@EnrollmentTypeID"])
                            ]}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        <GenericValidator
                            fieldId={'ddlEnrollmentType.AncillaryRule'}
                            fieldName={'Enrollment Type'}
                            message={'Ancillary enrollment type can only be used for PH facilities'}
                            trigger={
                                isFacilityInfoSubmit && facility["@EnrollmentTypeID"] == '3' && facility["@FacilityTypeID"] != '2'
                            }
                            style={{ width: 250 }}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        {/* Print Option */}
                        <tr>
                            <td className='fieldLabel'>* Print Options:</td>
                            <td style={{ width: 5 }}></td>
                            <td>{ddlPrintOptions}</td>
                        </tr>

                        <RequiredFieldValidator
                            fieldId={'ddlPrintOptions'}
                            fieldName={'Print Option'}
                            triggers={[
                                isFacilityInfoSubmit && ['', '0'].includes(facility["@PaperClaimsPrintingOptionTypeID"])
                            ]}
                            validationAggregation={validationAggregation}
                            validationAggregationRoutine={validationAggregationRoutine}
                        />

                        {/* Sort Service Options */}
                        <tr>
                            <td></td>
                            <td></td>
                            <td style={{ fontWeight: 'bold' }}>
                                <input
                                    id='SLSort'
                                    autoFocus={fieldFocus == 'SLSort'}
                                    type='checkbox'
                                    checked={facility["@SLSort"] === 'Y'}
                                    onChange={x => {
                                        facilityUpdateRoutine(
                                            "@SLSort",
                                            facility["@SLSort"] === 'Y' ? 'N' : 'Y',
                                            'SLSort')
                                    }}
                                />
                                Sort Service Lines
                            </td>
                        </tr>

                        {/* For GA (11), NC (30), LA (20), PA (41) */}
                        {['11', '30', '20', '41'].includes(facility["@ServiceStateID"]) &&

                            /* Submitter Code */
                            <tr>
                                <td className="fieldLabel">Submitter Code:</td>
                                <td></td>
                                <td>
                                    <input
                                        id='SubmitterCode'
                                        autoFocus={fieldFocus == 'SubmitterCode'}
                                        type="text"
                                        value={facility["@SubmitterCode"]}
                                        onChange={x => {
                                            this.inputOnChange(x);
                                        }}
                                        onBlur={x => {
                                            this.inputOnBlur(x);
                                        }}
                                        maxLength={2}
                                    />
                                </td>
                            </tr>
                        }

                        {/* For IL (16), NY (37), PA (41), SC (44), TX (47) */}
                        {['16', '37', '41', '44', '47'].includes(facility["@ServiceStateID"]) &&
                            <>
                                {/* Billing No */}
                                <tr>
                                    <td className="fieldLabel">Billing No:</td>
                                    <td></td>
                                    <td>
                                        <input
                                            id='BillingNumber'
                                            autoFocus={fieldFocus == 'BillingNumber'}
                                            type="text"
                                            value={facility["@BillingNumber"]}
                                            onChange={x => {
                                                this.inputOnChange(x);
                                            }}
                                            onBlur={x => {
                                                this.inputOnBlur(x);
                                            }}
                                            maxLength={4}
                                        />
                                    </td>
                                </tr>

                                {/* Phys Acct No */}
                                <tr>
                                    <td className="fieldLabel">Phys Acct No:</td>
                                    <td></td>
                                    <td>
                                        <input
                                            id='PhysicianAccountNo'
                                            autoFocus={fieldFocus == 'PhysicianAccountNo'}
                                            type="text"
                                            value={facility["@PhysicianAccountNo"]}
                                            onChange={x => {
                                                this.inputOnChange(x);
                                            }}
                                            onBlur={x => {
                                                this.inputOnBlur(x);
                                            }}
                                            maxLength={5}
                                        />
                                    </td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>

                <DialogFieldset>
                    <DialogLegend>Alternate Paper Edit Masters</DialogLegend>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <table>
                            <tbody>
                                {/* 1500 */}
                                <tr>
                                    <td className="fieldLabel">1500:</td>
                                    <td>
                                        <input
                                            id='HCFAAlternateEditMaster'
                                            autoFocus={fieldFocus == 'HCFAAlternateEditMaster'}
                                            type="text"
                                            value={facility["@HCFAAlternateEditMaster"]}
                                            onChange={x => {
                                                this.inputOnChange(x);
                                            }}
                                            onBlur={x => {
                                                this.inputOnBlur(x);
                                            }}
                                            maxLength={8}
                                        />
                                    </td>
                                </tr>

                                {/* UB */}
                                <tr>
                                    <td className="fieldLabel">UB:</td>
                                    <td>
                                        <input
                                            id='UB92AlternateEditMaster'
                                            autoFocus={fieldFocus == 'UB92AlternateEditMaster'}
                                            type="text"
                                            value={facility["@UB92AlternateEditMaster"]}
                                            onChange={x => {
                                                this.inputOnChange(x);
                                            }}
                                            onBlur={x => {
                                                this.inputOnBlur(x);
                                            }}
                                            maxLength={8}
                                        /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </DialogFieldset>

            </>
        );
    }
}
