import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ClaimTrackUI from './ui/ClaimTrackingUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';
import { has, isPlainObject } from 'lodash';
import { VerifyParentAndArray } from '../scripts/util/ValidationHelpers';

// replaces functionality of ChangeTracking.asp

export type IClaimTrackTypeState = ICrudComponentState<MCClaimTrackingType, ClaimTrackUI.IClaimTrackTypeUIState>;

export const actionCreators = createCrudComponentActions<ClaimTrackUI.ActionCreators>(ClaimTrackUI.actionCreators);
export type IClaimTrackTypeActions = typeof actionCreators;
export type IClaimTrackTypeActionProps = ICrudComponentActionProps<IClaimTrackTypeActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimChangesConfig, ClaimTrackUI.reducer);
export const reducer = combineReducers<IClaimTrackTypeState>(reducers);

export const validationCallback: ValidationCallback<MCClaimTrackingType> = (crud: any): crud is MCClaimTrackingType => {
    if (!isPlainObject(crud) || !has(crud, 'ClaimChangesMaintenanceInfo')) return false;
    VerifyParentAndArray(crud.ClaimChangesMaintenanceInfo, 'Changes', 'Change');
    VerifyParentAndArray(crud.ClaimChangesMaintenanceInfo, 'FieldList', 'Field');
    VerifyParentAndArray(crud.ClaimChangesMaintenanceInfo, 'FormTypeList', 'FormType');
    return true;
}
