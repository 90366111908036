import { Reducer } from 'redux';
import { createAction, createApiAction, createApiBodyAction, ActionTypes, TApiStatusTypes, ValidationCallback, ErrorCallback } from '@scripts/util/ActionHelpers';
import { URLs } from '@commonDevResources/constants';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IApiObject<T extends any> {
    apiType: string,
    dataStatus?: TApiStatusTypes;
    data?: T;
    data2?: T;
    errorData?: any;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface ApiState<T extends any> extends IApiObject<T> { };



export const actionCreators = {
    //loadData: (url: string) => createApiAction('LOAD_DATA', `${URLs.api}${url}`, url),
    loadData: (url: string, validationCallback?: ValidationCallback<any>, errorCallback?: ErrorCallback) =>
        createApiAction('API_LOAD_DATA', `${url}`, url, validationCallback, errorCallback),
    loadData2: (url: string, data: string, method: 'PUT' | 'POST' | 'GET' = 'POST', validationCallback?: ValidationCallback<any>, errorCallback?: ErrorCallback) =>
        createApiBodyAction('API_LOAD_DATA2', `${url}`, data, method, data, validationCallback, errorCallback),  // NOTE the body does not have JSON.stringify performed
       
    loadTestData: () => createAction('API_LOAD_TEST_DATA'),  // for debugging purposes only
    reset: () => createAction('API_RESET'),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];


export function DEFAULT_STATE(apiType: string): ApiState<any> {
    return {
        apiType: apiType,
        data: undefined,
        data2: undefined,
        dataStatus: undefined,
        errorData: undefined,
    };
}


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export type ApiReducer = (
    defaultApiState: ApiState<any>,
    state: ApiState<any> | undefined,
    action: KnownActions
) => ApiState<any>


// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.

export const apiReducer: ApiReducer = (defaultApiState: ApiState<any>, state: ApiState<any> | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'API_LOAD_DATA':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return state;
                    case "SUCCESS":
                    case "FAIL":
                        return {
                            ...state,
                            dataStatus: action.status.status,
                            data: action.responseData,
                        }
                    default:
                        const exhaustiveCheck: never = action.status.status;
                }
                break;
            case 'API_LOAD_DATA2':
                switch (action.status.status) {
                case "VALIDATE":
                case "REQUEST":
                    return state;
                case "SUCCESS":
                        return {
                            ...state,
                            dataStatus: action.status.status,
                            data2: action.responseData,
                        }
                case "FAIL":  // test this out and consider changes to above to do the same - prevents trying to load error data as data
                    return {
                        ...state,
                        dataStatus: action.status.status,
                        errorData: action.responseData,
                    }
                default:
                    const exhaustiveCheck: never = action.status.status;
                }
                break;

            case 'API_LOAD_TEST_DATA':
                {
                    return {
                        ...state,
                        dataStatus: "SUCCESS",
                        data: {
                            records: [
                                {
                                    "typeOfBill": "110",
                                    "count": 5,
                                    "charges": 1000.00,
                                },
                                {
                                    "typeOfBill": "13G",
                                    "count": 10,
                                    "charges": 25000.00,
                                },
                                {
                                    "typeOfBill": "124",
                                    "count": 12,
                                    "charges": 450000.00,
                                }
                            ]
                        }
                    }
                }
                
            case 'API_RESET':
                return defaultApiState;

                
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
        }
    }

    return state || defaultApiState;
}
export function createReducer(apiType: string): Reducer<ApiState<any>, KnownActions> {
    let defaultApiState = DEFAULT_STATE(apiType);
    return (state: ApiState<any> | undefined, action: KnownActions) => apiReducer(defaultApiState, state, action);
}