import { Action, Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes, createAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { IsFieldFilled } from '@commonResources/validations';
import { URLs } from '@commonResources/constants';
import { isEmpty } from 'lodash';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IAEIUIState {
    selectedTabIndex: number,
    // Without Notes - Filter
    selectedFilterFormForWON: string,
    selectedFilterPayerForWON: string,
    selectedFilterFieldForWON: string,
    selectedFilterEditForWON: string,

    // Without Notes Edit Note 
    selectedAdditionalInfoForWON: string,
    selectedENCriteriaFieldNameForWON: string,
    selectedENCriteriaDVARFieldNameForWON: string,
    selectedENCriteriaExpressionForWON: string,
    selectedENCriteriaExpressionValueForWON: string,

    additionalMsg_Validation_ErrorForWON?: string,
    criteriaValue_Validation_ErrorForWON?: string,
    errorWithoutNote_Validation_ErrorForWON?: string,
    validation_ErrorForWON?: string

    // With Notes - Filter
    selectedFilterFormForWN: string,
    selectedFilterPayerForWN: string,
    selectedFilterFieldForWN: string,
    selectedFilterEditForWN: string,

    // With Notes Edit Note
    selectedAdditionalInfoForWN: string,
    selectedENCriteriaFieldNameForWN: string,
    selectedENCriteriaDVARFieldNameForWN: string,
    selectedENCriteriaExpressionForWN: string,
    selectedENCriteriaExpressionValueForWN: string,

    searchResultsForWithNotes: MCAEIWithNotes;
    searchResultsForWithoutNotes: MCAEIWONotes;

    selectedEditNote: ISelectEditNote;
    selectedEditCriteria: string;

    additionalMsg_Validation_ErrorForWN?: string,
    criteriaField_Validation_ErrorForWN?: string,
    criteriaExpression_Validation_ErrorForWN?: string,
    criteriaValue_Validation_ErrorForWN?: string,
    onlyOneNoCriteriaFieldAllowed_ForWN?: string,
    errorWithNote_Validation_ErrorForWN?: string,
    validation_ErrorForWN?: string,

    modalViewed?: boolean,
    rollUpConfirmationMessage?: string,
    fieldIdForRollUpRule?: string,

    removeWNRecordsConfirmationMessage?: string,
    removeCriteriaConfirmationMessage?: string,
}

interface ISelectEditNote {
    index: number;
    value: string;
    text: string;
    id: string;
    editNote: any;
    additionalMsg: string;
};

const defaultEditNote: ISelectEditNote = {
    index: 0,
    value: '',
    text: '',
    id: '',
    editNote: {},
    additionalMsg: '',
};

class ManageHelpNotesValidationErrors {
    public static readonly additionalInformationEmpty: string = "Please enter Additional Information";
    public static readonly selectCriteriaFieldToRemove: string = "Please select a criteria field to remove";
    public static readonly selectedCriteriaFieldRemoveConfirmation: string = "You have chosen to remove the selected criteria. This action cannot be undone.Are you sure you want to continue?";
    public static readonly validateUnassignedErrorsSelectionMsg: string = "Please select Unassigned Errors or enter either Field or EditName for Rollup Note Insertion.";

    public static readonly validateNoteWithSameCriteriaMsg: string = "There is already a note with these same criteria. Please choose different criteria, expression or value.";
    public static readonly validateNONASCIICharacterMsg: string = "The pasted text had non-ascii characters that were removed.";

    public static readonly criteriaFieldEmpty: string = "Please select a criteria field to add";
    public static readonly criteriaExpressionEmpty: string = "Please select either the 'IN LIST' or the 'NOT IN LIST' option.";
    public static readonly criteriaValueEmpty: string = "There is no Criteria Value Field";
    public static readonly onlyOneNoCriteriaMsg: string = "Please select a different criteria. Only one NoCriteria is allowed";

    public static readonly noUnassignedError: string = "No Unassigned errors found";
    public static readonly noErrors: string = "No Errors With Notes found.";

    public static readonly rollupEditNameRequired: string = "EditName or (EditName and FieldName) is required for rollup.";
    public static readonly rollupEditNameNotValid: string = "The Edit Name that you have entered is not valid for rollup";
    public static readonly rollupFieldNameValid: string = "The Field Name that you have entered is not valid for rollup";
    public static readonly rollupConflict: string = "The rule you are trying to create conflicts with an existing rule. Review the Help for more information.";


};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface AEISearchFields {
    PageSize: string;
    StartPage: string;
    PageCount: string;
    QueryDays: string;
    RefreshMinutes: string;
    RefreshState: string;
    FilterDataForm?: string;
    FilterPayer?: string;
    FilterField?: string;
    FilterEdit?: string;
}

export const defaultSearchFields: AEISearchFields = {
    PageSize: '',
    StartPage: '',
    PageCount: '',
    QueryDays: '',
    RefreshMinutes: '',
    RefreshState: ''
}

export const defaultWON: MCAEIWONotes = {
    Errors: {
        Error: [{
            '@PageNumber': '',
            '@Idx': '',
            '@EditErrorIdx': '',
            '@FieldId': '',
            '@FieldName': '',
            '@EditName': '',
            '@ErrCode': '',
            '@CPTGroupId': '',
            '@DataForm': '',
            '@PayerName': '',
            '@MsgLev1': '',
            '@ErrorCount': '',

            _PageNumber: '',
            Index: '',
            _EditErrorIdx: '',
            _FieldId: '',
            Field: '',
            EditName: '',
            _ErrCode: '',
            _CPTGroupId: '',
            FormType: '',
            Payer: '',
            Message: '',
            _ErrorCount: '',
        }]
    },
    CriteriaFieldList: {
        CriteriaField: [{
            '@FieldName': '',
            '@DVARName': '',
            _FieldName: '',
            _DVARName: '',
        }]
    },
    ExpressionList: {
        Expression: [{
            "@Expression": '',
            _Expression: '',
        }]
    },
    Summary: {
        "@TotalPages": '',
        _TotalPages: '',
    }
}

interface IAEIUIFieldUpdate {
    value: string;
}

interface IAEIWNCriteria {
    additionalInfo: string,
    criteriaField: string,
    criteriaExpression: string,
    criteriaValue: string,
}

export const defaultWN: MCAEIWithNotes = {
    '@TotalPages': '',
    _TotalPages: '',
    Errors: {
        Error: [{
            '@ID': '',
            '@PageNumber': '',
            '@ErrorClientAdditionalId': '',
            '@FieldName': '',
            '@DataForm': '',
            '@PayerName': '',
            '@EditName': '',
            '@MsgLev1': '',

            _PageNumber: '',
            AdditionalMsg: '',
            Field: '',
            FormType: '',
            Payer: '',
            EditName: '',
            Message: '',
        }]
    },
    CriteriaFieldList: {
        CriteriaField: [{
            '@FieldName': '',
            '@DVARName': '',
            _FieldName: '',
            _DVARName: '',
        }]
    },
    CriteriaList: {
        Criteria: [{
            '@AdditionalMsg': '',
            '@ErrorClientAdditionalMessageId': '',
            '@FieldName': '',
            '@FieldDVARName': '',
            '@Expression': '',
            '@CriteriaData': '',
            '@ErrorClientAdditionalId': ''
        }]
    }
}

// TAB 0 - Without Notes
export interface ISearchWithoutNote extends ICrudActionData<MCAEIErrorType, MCAEIWONotes> { }
export interface IModifyFilterForWithoutNote extends ICrudActionData<MCAEIErrorType, MCAEIWONUserData> { }

//TAB 1 - With Notes
export interface ISearchWithNote extends ICrudActionData<MCAEIErrorType, MCAEIWithNotes> { }
export interface IModifyFilterFormWithNote extends ICrudActionData<MCAEIErrorType, MCAEIWNUserData> { }
export interface IModifyEditCriteria extends ICrudActionData<MCAEIErrorType, MCAEICriteria> { }
// Search Field Update
export interface IUpdateErrorsSearchField extends ICrudActionData<MCAEIErrorType, IAEIUIFieldUpdate> { }

export interface ISelectAEIENCriteria extends ICrudActionData<MCAEIWithNotes, ISelectEditNote> { }

export const actionCreators = {
    selectTabIndex: (tabIndex: number) => createDataAction('SELECT_TAB_INDEX', tabIndex),

    // WON Actions
    updateWONFormFilter: (filterForm: IUpdateErrorsSearchField) => createDataAction('UPDATE_WON_FORM_FILTER', filterForm), // Select WON Form Filter
    updateWONPayerFilter: (payer: string) => createDataAction('UPDATE_WON_PAYER', payer), // Update WON Payer
    updateWONFieldFilter: (field: string) => createDataAction('UPDATE_WON_FIELD', field), // Update WON Field
    updateWONEditFilter: (edit: string) => createDataAction('UPDATE_WON_EDITNAME', edit), // Update WON Edit

    // WON Search Actions
    searchWONErrors: (searchInfo: ISearchWithoutNote) => createDataAction('SEARCH_WON_ERRORS', searchInfo), // Default Search
    searchWONErrorsUsingForm: (searchFieldInfo: IUpdateErrorsSearchField) => createDataAction('SEARCH_WON_ERRORS_USING_FORM', searchFieldInfo),  // Search Errors using Form
    searchWONErrorsUsingPayer: (searchFieldInfo: IUpdateErrorsSearchField) => createDataAction('SEARCH_WON_ERRORS_USING_PAYER', searchFieldInfo), // Search Errors using Payer
    searchWONErrorsUsingField: (searchFieldInfo: IUpdateErrorsSearchField) => createDataAction('SEARCH_WON_ERRORS_USING_FIELD', searchFieldInfo), // Search Errors using Field
    searchWONErrorsUsingEditName: (searchFieldInfo: IUpdateErrorsSearchField) => createDataAction('SEARCH_WON_ERRORS_USING_EDITNAME', searchFieldInfo),  // Search using Edit

    clearWONFilters: (clear: IModifyFilterForWithoutNote) => createDataAction("CLEAR_WON_FILTERS", clear), // Clear WON Filters

    // WON Edit Note Data Action

    // WON EN Button Action
    addWONEditNote: (addInfo: IModifyFilterForWithoutNote) => createDataAction('ADD_WON_EDITNOTE', addInfo),

    // WON EN AdditionalInfo Description and Criteria Field/Expression Actions
    updateWONAddInfoDesc: (additionalInfo: string) => createDataAction('UPDATE_WON_ADDINFO_DESC', additionalInfo),
    selectedWONCriteriaField: (criteriaField: ISelectAEIENCriteria) => createDataAction('SELECT_WON_CRITERIA_FIELD', criteriaField), // Select WON Criteria Field
    selectedWONCriteriaExp: (criteriaExpression: IUpdateErrorsSearchField) => createDataAction('SELECT_WON_CRITERIA_EXP', criteriaExpression), // Select WON Criteria Expression
    updateWONCriteriaValue: (criteriaValue: string) => createDataAction('UPDATE_WON_CRITERIA_VALUE', criteriaValue), // Select WON Criteria Value
    //WON Error Details
    errorWONValidationMessage: (errorMessage: string) => createDataAction('ERROR_WON_VALIDATION', errorMessage), // Error validation clear for WON

    // WN Actions
    updateWNFormFilter: (filterForm: IUpdateErrorsSearchField) => createDataAction('UPDATE_WN_FORM_FILTER', filterForm), // Select WN Form Filter
    updateWNPayerFilter: (payer: string) => createDataAction('UPDATE_WN_PAYER', payer), // Update WN Payer
    updateWNFieldFilter: (field: string) => createDataAction('UPDATE_WN_FIELD', field), // Update WN Field
    updateWNEditFilter: (edit: string) => createDataAction('UPDATE_WN_EDITNAME', edit), // Update WN Edit

    // WN Search Actions
    searchWNErrors: (searchInfo: ISearchWithNote) => createDataAction('SEARCH_WN_ERRORS', searchInfo), // Default Search
    searchWNErrorsUsingForm: (searchFieldInfo: IUpdateErrorsSearchField) => createDataAction('SEARCH_WN_ERRORS_USING_FORM', searchFieldInfo), // Search Errors using Form
    searchWNErrorsUsingPayer: (searchFieldInfo: IUpdateErrorsSearchField) => createDataAction('SEARCH_WN_ERRORS_USING_PAYER', searchFieldInfo), // Search Errors using Payers
    searchWNErrorsUsingField: (searchFieldInfo: IUpdateErrorsSearchField) => createDataAction('SEARCH_WN_ERRORS_USING_FIELD', searchFieldInfo), // Search Errors using Field
    searchWNErrorsUsingEditName: (searchFieldInfo: IUpdateErrorsSearchField) => createDataAction('SEARCH_WN_ERRORS_USING_EDITNAME', searchFieldInfo), // Search Errors using Edit

    clearWNFilters: (clear: IModifyFilterFormWithNote) => createDataAction("CLEAR_WN_FILTERS", clear), // Clear WN Filters

    removeErrors: (removeErrors: IModifyFilterFormWithNote) => createDataAction('REMOVE_WN_ERRORS', removeErrors),    // Remove WN Errors
    // WN Edit Note Data Action
    selectedWNENCriteria: (criteria: ISelectAEIENCriteria) => createDataAction('SELECT_WN_EN_CRITERIA', criteria), // Select WN Edit Note Criteria 

    // WN EN Button Actions
    addEditNoteCriteria: (addInfo: IModifyFilterFormWithNote) => createDataAction('ADD_WN_EDITNOTE_CRITERIA', addInfo),    // Add WN Edit Note Criteria
    updateEditNoteCriteria: (updateInfo: IModifyFilterFormWithNote) => createDataAction('UPDATE_WN_EDITNOTE_CRITERIA', updateInfo),    // Update WN Edit Criteria
    removeEditNoteCriteria: (updateInfo: IModifyFilterFormWithNote) => createDataAction('REMOVE_WN_EDITNOTE_CRITERIA', updateInfo),    // Remove WN Edit Criteria

    // WN EN Criteria Actions
    updateWNAddInfoDesc: (additionalInfo: string) => createDataAction('UPDATE_WN_ADDINFO_DESC', additionalInfo),
    selectedWNCriteriaField: (criteriaField: ISelectAEIENCriteria) => createDataAction('SELECT_WN_CRITERIA_FIELD', criteriaField), // Select WN Criteria Field
    selectedWNCriteriaExp: (criteriaExpression: IUpdateErrorsSearchField) => createDataAction('SELECT_WN_CRITERIA_EXP', criteriaExpression), // Select WN Criteria Expression
    updateWNCriteriaValue: (criteriaValue: string) => createDataAction('UPDATE_WN_CRITERIA_VALUE', criteriaValue), // Select WN Criteria Value

    //WN Error Details
    errorWNValidationMessage: (errorMessage: string) => createDataAction('ERROR_WN_VALIDATION', errorMessage), // Error validation clear for WN

    //Rollup rule confirmation
    setRollUpConfirmationMessage: (message: string) => createDataAction('SET_ROLLUP_CONFIRMATION_MESSAGE', message),
    verifyEditField: (editName: string, fieldName: string) => createApiBodyAction('VERIFY_EDITFIELD',
        `${URLs.api}/api/data/VerifyEditField`,
        undefined,
        "POST",
        JSON.stringify(editName + "|" + fieldName)),

    setRemoveWNRecordsConfirmationMessage: (message: string) => createDataAction('SET_REMOVE_WNRECORDS_CONFIRMATION_MSG', message),
    setRemoveCriteriaConfirmationMessage: (message: string) => createDataAction('SET_REMOVE_EDITCRITERIA_CONFIRMATION_MSG', message),

};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IAEIUIState = {
    selectedTabIndex: 0,

    // Without Notes
    selectedFilterFormForWON: '',
    selectedFilterPayerForWON: '',
    selectedFilterFieldForWON: '',
    selectedFilterEditForWON: '',

    selectedAdditionalInfoForWON: '',
    selectedENCriteriaFieldNameForWON: '',
    selectedENCriteriaDVARFieldNameForWON: '',
    selectedENCriteriaExpressionForWON: 'INLIST',
    selectedENCriteriaExpressionValueForWON: 'INLIST',

    additionalMsg_Validation_ErrorForWON: undefined,
    criteriaValue_Validation_ErrorForWON: undefined,
    errorWithoutNote_Validation_ErrorForWON: undefined,
    validation_ErrorForWON: undefined,
    modalViewed: true,
    // With Notes
    selectedFilterFormForWN: '',
    selectedFilterPayerForWN: '',
    selectedFilterFieldForWN: '',
    selectedFilterEditForWN: '',

    selectedAdditionalInfoForWN: '',
    selectedENCriteriaFieldNameForWN: '',
    selectedENCriteriaDVARFieldNameForWN: '',
    selectedENCriteriaExpressionForWN: 'INLIST',
    selectedENCriteriaExpressionValueForWN: '',

    searchResultsForWithNotes: defaultWN,
    searchResultsForWithoutNotes: defaultWON,

    selectedEditNote: defaultEditNote,
    selectedEditCriteria: '',

    additionalMsg_Validation_ErrorForWN: undefined,
    criteriaField_Validation_ErrorForWN: undefined,
    criteriaExpression_Validation_ErrorForWN: undefined,
    criteriaValue_Validation_ErrorForWN: undefined,
    onlyOneNoCriteriaFieldAllowed_ForWN: undefined,
    errorWithNote_Validation_ErrorForWN: undefined,
};

function CreateConfirmMsg(editName: string, fieldName: string) {
    var localArray = [];

    if (editName != null && editName != "")
        localArray[localArray.length] = editName;

    if (fieldName != null && fieldName != "")
        localArray[localArray.length] = fieldName;

    let msg = `You are about to create a Rollup rule on `;
    if (localArray.length == 1)
        msg += localArray[0];
    else if (localArray.length == 2)
        msg += `${localArray[0]} and ${localArray[1]}`;
    else
        msg += `${localArray[0]}, ${localArray[1]} and ${localArray[2]}`;

    msg += `. Are you sure you want to continue?`;

    return msg;
}

function sameCriteria(criterias: MCAEICriteria[] | any, selectedCriteriaField: string,
    selectedExpression: string, selectedExpressionValue: string): boolean {
    let matchedCriteiras: MCAEICriteria[] = [];
    let matched: boolean = false;
    if (criterias && criterias.length > 0) {
        matchedCriteiras = criterias && criterias.length > 0 ? criterias.filter((ctr: MCAEICriteria) => {
            if (!isEmpty(ctr['@CriteriaData']) && !isEmpty(selectedCriteriaField) && !isEmpty(selectedExpression)) {
                let criteriaDesc: string = ctr['@CriteriaData'];
                let criteriaInfo: Array<string> = criteriaDesc.split('-');
                if (criteriaInfo && criteriaInfo.length > 2) {
                    let ctrField = criteriaInfo[0];
                    let ctrExpression = criteriaInfo[1];
                    let ctrExpressionValue = criteriaInfo[2];

                    return (ctrField === selectedCriteriaField &&
                        ctrExpression === selectedExpression &&
                        ctrExpressionValue === selectedExpressionValue);
                }
            }
        }) : [];
    }

    matched = (matchedCriteiras && matchedCriteiras.length > 0);
    return matched;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IAEIUIState, KnownActions> = (state: IAEIUIState = defaultState, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_TAB_INDEX':
                return {
                    ...state,
                    selectedTabIndex: action.data,
                    selectedFilterFormForWON: state.selectedFilterFormForWON,
                    selectedFilterFormForWN: state.selectedFilterFormForWN,
                }
            // Without Filter
            case 'SEARCH_WON_ERRORS': {
                let aeiWithoutNoteFilter: MCAEIWONotes = action.data.uiData;
                if (action.data.masterCrud) {
                    return {
                        ...state,
                        searchResultsForWithoutNotes: {
                            Errors: action.data.masterCrud.AEIHelpNoteInfo.withoutNotes.Errors,
                            CriteriaFieldList: action.data.masterCrud.AEIHelpNoteInfo.withoutNotes.CriteriaFieldList,
                            ExpressionList: action.data.masterCrud.AEIHelpNoteInfo.withoutNotes.ExpressionList,
                            Summary: action.data.masterCrud.AEIHelpNoteInfo.withoutNotes.Summary,
                        },
                        selectedTabIndex: 0,
                    }
                }
            }
                break;
            case 'UPDATE_WON_FORM_FILTER': {
                if (state.selectedFilterFormForWON !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedFilterFormForWON: action.data.uiData.value,
                    }
                }
            }
                break;
            case 'UPDATE_WON_PAYER': {
                if (state.selectedFilterPayerForWON !== action.data) {
                    return {
                        ...state,
                        selectedFilterPayerForWON: action.data,
                    }
                }
            }
                break;
            case 'UPDATE_WON_FIELD': {
                if (state.selectedFilterFieldForWON !== action.data) {
                    return {
                        ...state,
                        selectedFilterFieldForWON: action.data,
                    }
                }
            }
                break;
            case 'UPDATE_WON_EDITNAME': {
                if (state.selectedFilterEditForWON !== action.data) {
                    return {
                        ...state,
                        selectedFilterEditForWON: action.data,
                    }
                }
            }
                break;
            case 'SEARCH_WON_ERRORS_USING_FORM': {
                if (state.selectedFilterFormForWON !== action.data.uiData.value) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedFilterFormForWON: action.data.uiData.value,
                        },
                    }
                }
            }
                break;
            case 'SEARCH_WON_ERRORS_USING_PAYER': {
                if (state.selectedFilterPayerForWON !== action.data.uiData.value) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedFilterPayerForWON: action.data.uiData.value,
                        },
                    }
                }
            }
                break;
            case 'SEARCH_WON_ERRORS_USING_FIELD': {
                if (state.selectedFilterFieldForWON !== action.data.uiData.value) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedFilterFieldForWON: action.data.uiData.value,
                        },
                    }
                }
            }
                break;
            case 'SEARCH_WON_ERRORS_USING_EDITNAME': {
                if (state.selectedFilterEditForWON !== action.data.uiData.value) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedFilterEditForWON: action.data.uiData.value,
                        },
                    }
                }
            }
                break;
            case 'CLEAR_WON_FILTERS': {
                return {
                    ...state,
                    selectedFilterFormForWON: '',
                    selectedFilterPayerForWON: '',
                    selectedFilterFieldForWON: '',
                    selectedFilterEditForWON: '',
                }
            }
                break;
            case 'ADD_WON_EDITNOTE': {
                let newState: IAEIUIState = { ...state };

                newState.additionalMsg_Validation_ErrorForWON = undefined;
                newState.criteriaValue_Validation_ErrorForWON = undefined;
                newState.errorWithoutNote_Validation_ErrorForWON = undefined;
                newState.validation_ErrorForWON = undefined;
                newState.modalViewed = true;

                let wonUIData = action.data.uiData;

                // Validate Additional Message
                if (wonUIData && !IsFieldFilled(wonUIData['@AdditionalInfo'].trim()) && !IsFieldFilled(state.selectedAdditionalInfoForWON.trim())) {
                    newState.validation_ErrorForWON = ManageHelpNotesValidationErrors.additionalInformationEmpty;
                    newState.additionalMsg_Validation_ErrorForWON = ManageHelpNotesValidationErrors.additionalInformationEmpty;
                    newState.modalViewed = false;
                    return newState;
                }

                // Validate Criteria Expression Value
                if (wonUIData && !IsFieldFilled(wonUIData['@CriteriaValue'].trim()) && !IsFieldFilled(state.selectedENCriteriaExpressionValueForWON.trim()) && state.selectedENCriteriaFieldNameForWON != "No Criteria") {
                    newState.validation_ErrorForWON = ManageHelpNotesValidationErrors.criteriaValueEmpty;
                    newState.criteriaValue_Validation_ErrorForWON = ManageHelpNotesValidationErrors.criteriaValueEmpty;
                    newState.modalViewed = false;
                    return newState;
                }

                // Validate Error Note Selection
                if (wonUIData && wonUIData.selectedWONotes.length === 0) {
                    if (!IsFieldFilled(wonUIData.selectedFilterEditForWON) && !IsFieldFilled(wonUIData.selectedFilterFieldForWON)) {
                        newState.validation_ErrorForWON = ManageHelpNotesValidationErrors.validateUnassignedErrorsSelectionMsg;
                        newState.modalViewed = false;
                        return newState;
                    }
                }

                return newState;
            }
                break;
            case 'UPDATE_WON_ADDINFO_DESC': {
                let newState: IAEIUIState = {
                    ...state,
                    validation_ErrorForWON: undefined,
                    additionalMsg_Validation_ErrorForWON: undefined,
                    modalViewed: true,
                };

                if (state.selectedAdditionalInfoForWON !== action.data) {
                    let newValue: string = action.data;
                    newValue = newValue.replace(/[^\x00-\x7F]/g, "");
                    if (newValue != action.data) {
                        newState.validation_ErrorForWON = ManageHelpNotesValidationErrors.validateNONASCIICharacterMsg;
                        newState.modalViewed = false;
                    }
                    newState.selectedAdditionalInfoForWON = newValue;
                }

                return newState;
            }
                break;
            case 'SELECT_WON_CRITERIA_FIELD': {
                const { text, value } = action.data.uiData

                if (state.selectedENCriteriaFieldNameForWON !== text ||
                    state.selectedENCriteriaDVARFieldNameForWON !== value) {
                    return {
                        ...state,
                        selectedENCriteriaFieldNameForWON: text,
                        selectedENCriteriaDVARFieldNameForWON: value,
                    }
                }
            }
                break;
            case 'SELECT_WON_CRITERIA_EXP': {
                if (state.selectedENCriteriaExpressionForWON !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedENCriteriaExpressionForWON: action.data.uiData.value === "" ?
                            defaultState.selectedENCriteriaExpressionForWON :
                            action.data.uiData.value,
                    }
                }
            }
                break;
            case 'UPDATE_WON_CRITERIA_VALUE': {
                let newState: IAEIUIState = { ...state };
                newState.criteriaValue_Validation_ErrorForWON = undefined;

                if (state.selectedENCriteriaExpressionValueForWON !== action.data) {
                    newState.selectedENCriteriaExpressionValueForWON = action.data;
                }
                return newState;
            }
                break;
            // With Filters
            case 'SEARCH_WN_ERRORS': {
                if (action.data.masterCrud) {
                    return {
                        ...state,
                        searchResultsForWithNotes: {
                            Errors: action.data.masterCrud.AEIHelpNoteInfo.withNotes.Errors,
                            CriteriaFieldList: action.data.masterCrud.AEIHelpNoteInfo.withNotes.CriteriaFieldList,
                            CriteriaList: action.data.masterCrud.AEIHelpNoteInfo.withNotes.CriteriaList,
                            ExpressionList: action.data.masterCrud.AEIHelpNoteInfo.withNotes.ExpressionList,
                            UnitList: action.data.masterCrud.AEIHelpNoteInfo.withNotes.UnitsList,
                            "@TotalPages": action.data.masterCrud.AEIHelpNoteInfo.withNotes['@TotalPages'] ?? '',
                            _TotalPages: action.data.masterCrud.AEIHelpNoteInfo.withNotes['@TotalPages'] ?? ''
                        },
                        selectedTabIndex: 1,
                    }
                }
            }
                break;
            case 'UPDATE_WN_FORM_FILTER': {
                if (state.selectedFilterFormForWN !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedFilterFormForWN: action.data.uiData.value,
                    }
                }
            }
                break;
            case 'UPDATE_WN_PAYER': {
                if (state.selectedFilterPayerForWN !== action.data) {
                    return {
                        ...state,
                        selectedFilterPayerForWN: action.data,
                    }
                }
            }
                break;
            case 'UPDATE_WN_FIELD': {
                if (state.selectedFilterFieldForWN !== action.data) {
                    return {
                        ...state,
                        selectedFilterFieldForWN: action.data,
                    }
                }
            }
                break;
            case 'UPDATE_WN_EDITNAME': {
                if (state.selectedFilterEditForWN !== action.data) {
                    return {
                        ...state,
                        selectedFilterEditForWN: action.data,
                    }
                }
            }
                break;
            case 'SEARCH_WN_ERRORS_USING_FORM': {
                if (state.selectedFilterFormForWN !== action.data.uiData.value) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedFilterFormForWN: action.data.uiData.value,
                        },
                    }
                }
            }
                break;
            case 'SEARCH_WN_ERRORS_USING_PAYER': {
                if (state.selectedFilterPayerForWN !== action.data.uiData.value) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedFilterPayerForWN: action.data.uiData.value,
                        },
                    }
                }
            }
                break;
            case 'SEARCH_WN_ERRORS_USING_FIELD': {
                if (state.selectedFilterFieldForWN !== action.data.uiData.value) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedFilterFieldForWN: action.data.uiData.value,
                        },
                    }
                }
            }
                break;
            case 'SEARCH_WN_ERRORS_USING_EDITNAME': {
                if (state.selectedFilterEditForWN !== action.data.uiData.value) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedFilterEditForWN: action.data.uiData.value,
                        },
                    }
                }
            }
                break;
            case 'CLEAR_WN_FILTERS': {
                return {
                    ...state,
                    selectedFilterFormForWN: '',
                    selectedFilterPayerForWN: '',
                    selectedFilterFieldForWN: '',
                    selectedFilterEditForWN: '',
                }
            }
                break;
            case 'UPDATE_WN_ADDINFO_DESC': {
                let newState: IAEIUIState = {
                    ...state,
                    validation_ErrorForWN: undefined,
                    additionalMsg_Validation_ErrorForWN: undefined,
                    modalViewed: true,
                };

                if (state.selectedAdditionalInfoForWN !== action.data) {
                    let newValue: string = action.data;
                    newValue = newValue.replace(/[^\x00-\x7F]/g, "");
                    if (newValue != action.data) {
                        newState.validation_ErrorForWN = ManageHelpNotesValidationErrors.validateNONASCIICharacterMsg;
                        newState.modalViewed = false;
                    }
                    newState.selectedAdditionalInfoForWN = newValue;
                }

                return newState;
            }
                break;
            case 'SELECT_WN_EN_CRITERIA': {

                let newState: IAEIUIState = {
                    ...state,
                    additionalMsg_Validation_ErrorForWN: undefined,
                    criteriaField_Validation_ErrorForWN: undefined,
                    criteriaExpression_Validation_ErrorForWN: undefined,
                    criteriaValue_Validation_ErrorForWN: undefined,
                    onlyOneNoCriteriaFieldAllowed_ForWN: undefined,
                    errorWithNote_Validation_ErrorForWN: undefined,
                    validation_ErrorForWN: undefined,
                    selectedEditCriteria: '',
                };

                if (action.data.uiData && action.data.masterCrud) {
                    let additionalMsg: string = action.data.uiData.additionalMsg;

                    if (action.data.masterCrud.CriteriaList && action.data.masterCrud.CriteriaList.Criteria) {
                        let criteriaIndex = action.data.masterCrud.CriteriaList.Criteria.find(crt => crt['@ErrorClientAdditionalMessageId'] === action.data.uiData.value);
                        if (criteriaIndex !== undefined) {
                            additionalMsg = criteriaIndex["@AdditionalMsg"];
                        } else {
                            additionalMsg = "";
                        }
                    }

                    let criteriaNotes: string = action.data.uiData.text;

                    if (criteriaNotes) {
                        if (!isEmpty(criteriaNotes)) {
                            if (criteriaNotes.indexOf('-') > 0) {
                                let criteriaInfo: Array<string> = action.data.uiData.text.split('-');
                                let criteriaField: string = criteriaNotes.split('-')[0];
                                let criteriaExpression: string = "";
                                let criteriaValue: string = "";

                                if (criteriaInfo.length > 2) {
                                    criteriaExpression = criteriaNotes.split('-')[1];
                                    criteriaValue = criteriaNotes.split('-')[2];
                                }

                                return {
                                    ...state,
                                    selectedENCriteriaFieldForWN: criteriaField,
                                    selectedENCriteriaExpressionForWN: criteriaExpression,
                                    selectedENCriteriaExpressionValueForWN: criteriaValue,
                                    selectedAdditionalInfoForWN: additionalMsg,
                                    selectedEditNote: action.data.uiData.editNote,
                                    selectedEditCriteria: action.data.uiData.text,
                                }

                            } else if (criteriaNotes === "No Criteria") {

                                return {
                                    ...state,
                                    selectedENCriteriaFieldForWN: '',
                                    selectedENCriteriaExpressionForWN: 'INLIST',
                                    selectedENCriteriaExpressionValueForWN: '',
                                    selectedAdditionalInfoForWN: additionalMsg,
                                    selectedEditNote: action.data.uiData.editNote,
                                    selectedEditCriteria: action.data.uiData.text,
                                }

                            } else if (criteriaNotes === "-- Add New Criteria --") {
                                return {
                                    ...state,
                                    selectedENCriteriaFieldForWN: '',
                                    selectedENCriteriaExpressionForWN: 'INLIST',
                                    selectedENCriteriaExpressionValueForWN: '',
                                    selectedAdditionalInfoForWN: '',
                                    selectedEditNote: {},
                                    selectedEditCriteria: action.data.uiData.text,
                                }
                            }
                        }
                    }

                    return {
                        ...state,
                        selectedENCriteriaFieldForWN: '',
                        selectedENCriteriaExpressionForWN: '',
                        selectedENCriteriaExpressionValueForWN: '',
                        selectedAdditionalInfoForWN: '',
                        selectedEditNote: {},
                    }
                }

                return newState;
            }
                break;
            case 'ADD_WN_EDITNOTE_CRITERIA':
            case 'UPDATE_WN_EDITNOTE_CRITERIA': {
                let newState: IAEIUIState = {
                    ...state,
                    additionalMsg_Validation_ErrorForWN: undefined,
                    criteriaField_Validation_ErrorForWN: undefined,
                    criteriaExpression_Validation_ErrorForWN: undefined,
                    criteriaValue_Validation_ErrorForWN: undefined,
                    onlyOneNoCriteriaFieldAllowed_ForWN: undefined,
                    errorWithNote_Validation_ErrorForWN: undefined,
                    validation_ErrorForWN: undefined,
                    modalViewed: true
                };

                if (action.data.uiData) {
                    // Validate Additional Message
                    if (!IsFieldFilled(action.data.uiData['@AdditionalInfo']) && !IsFieldFilled(state.selectedAdditionalInfoForWN)) {
                        return {
                            ...state,
                            additionalMsg_Validation_ErrorForWN: ManageHelpNotesValidationErrors.additionalInformationEmpty,
                            validation_ErrorForWN: ManageHelpNotesValidationErrors.additionalInformationEmpty,
                            modalViewed: false,
                        }
                    }

                    // Validate Criteria Field Value
                    if (!IsFieldFilled(action.data.uiData['@CriteriaValue'].trim()) &&
                        !IsFieldFilled(state.selectedENCriteriaExpressionValueForWN.trim()) && state.selectedENCriteriaFieldNameForWN != "No Criteria") {
                        return {
                            ...state,
                            criteriaValue_Validation_ErrorForWN: ManageHelpNotesValidationErrors.criteriaValueEmpty,
                            validation_ErrorForWN: ManageHelpNotesValidationErrors.criteriaValueEmpty,
                            modalViewed: false
                        }
                    }

                    // Validate Error Note Selection
                    if (action.data.uiData.selectedWNotes) {

                        if (action.data.uiData.selectedWNotes.length > 0) {

                            // Only one No Criteria validation
                            if (state.selectedENCriteriaFieldNameForWN === action.data.uiData['@CriteriaFieldName'] &&
                                state.selectedENCriteriaFieldNameForWN === 'No Criteria' && action.type !== 'UPDATE_WN_EDITNOTE_CRITERIA' &&
                                action.data.uiData['@CriteriaFieldName'] === 'No Criteria') {

                                if (action.data.uiData.editNoteCriterias && action.data.uiData.editNoteCriterias.length > 0) {
                                    let noCriteriaIndex = action.data.uiData.editNoteCriterias.findIndex(ctr => ctr['@CriteriaData'] === "No Criteria");
                                    if (noCriteriaIndex > -1) {
                                        return {
                                            ...state,
                                            validation_ErrorForWN: ManageHelpNotesValidationErrors.onlyOneNoCriteriaMsg,
                                            modalViewed: false,
                                        }
                                    }
                                }
                            }

                            if (action.type === 'ADD_WN_EDITNOTE_CRITERIA') {
                                // Same Criteria not allowed valdaiton
                                if (state.selectedENCriteriaFieldNameForWN === action.data.uiData['@CriteriaFieldName'] &&
                                    state.selectedENCriteriaExpressionForWN === action.data.uiData['@CriteriaExpression'] &&
                                    state.selectedENCriteriaExpressionValueForWN === action.data.uiData['@CriteriaValue']) {
                                    if (action.data.uiData.editNoteCriterias && action.data.uiData.editNoteCriterias.length > 0) {
                                        let filterNoCriteria = action.data.uiData.editNoteCriterias.filter(ctr => ctr['@FieldDVARName'] !== '');
                                        let isSameCriteria: boolean = sameCriteria(filterNoCriteria,
                                            state.selectedENCriteriaFieldNameForWN,
                                            state.selectedENCriteriaExpressionForWN,
                                            state.selectedENCriteriaExpressionValueForWN);

                                        if (isSameCriteria) {
                                            return {
                                                ...state,
                                                validation_ErrorForWN: ManageHelpNotesValidationErrors.validateNoteWithSameCriteriaMsg,
                                                modalViewed: false,
                                            }
                                        } else {
                                            return newState;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                return newState;
            }
                break;
            case 'SELECT_WN_CRITERIA_FIELD': {
                if (state.selectedENCriteriaFieldNameForWN !== action.data.uiData.text ||
                    state.selectedENCriteriaDVARFieldNameForWN !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedENCriteriaFieldNameForWN: action.data.uiData.text,
                        selectedENCriteriaDVARFieldNameForWN: action.data.uiData.value,
                    }
                }
            }
                break;
            case 'SELECT_WN_CRITERIA_EXP': {
                if (state.selectedENCriteriaExpressionForWN !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedENCriteriaExpressionForWN: isEmpty(action.data.uiData.value) ?
                            defaultState.selectedENCriteriaExpressionForWN :
                            action.data.uiData.value,
                    }
                }
            }
                break;
            case 'UPDATE_WN_CRITERIA_VALUE': {
                let newState: IAEIUIState = {
                    ...state,
                    criteriaValue_Validation_ErrorForWN: undefined,
                };
                if (state.selectedENCriteriaExpressionValueForWN !== action.data) {
                    return {
                        ...state,
                        selectedENCriteriaExpressionValueForWN: action.data
                    }
                }
                return newState;
            }
                break;
            case 'ERROR_WON_VALIDATION': {
                if (state.validation_ErrorForWON !== action.data) {
                    return {
                        ...state,
                        validation_ErrorForWON: action.data,
                        modalViewed: !state.modalViewed,
                    }
                }
            }
                break;
            case 'SET_ROLLUP_CONFIRMATION_MESSAGE': {
                if (state.rollUpConfirmationMessage !== action.data) {
                    return {
                        ...state,
                        rollUpConfirmationMessage: action.data,
                    }
                }
            }
                break;
            case 'VERIFY_EDITFIELD':
                {
                    switch (action.status.status) {
                        case "SUCCESS":
                            if (!action.responseData.EditName) {
                                return {
                                    ...state,
                                    validation_ErrorForWON: ManageHelpNotesValidationErrors.rollupEditNameNotValid,
                                    modalViewed: !state.modalViewed,
                                }
                            }
                            if (action.responseData.FieldId !== "") {
                                if (action.responseData.FieldId === "-1") {
                                    return {
                                        ...state,
                                        validation_ErrorForWON: ManageHelpNotesValidationErrors.rollupFieldNameValid,
                                    }
                                }
                            }
                            if (!action.responseData.EditField) {
                                return {
                                    ...state,
                                    validation_ErrorForWON: ManageHelpNotesValidationErrors.rollupConflict,
                                    modalViewed: !state.modalViewed,
                                }
                            }

                            let confirmMsg = CreateConfirmMsg(state.selectedFilterEditForWON, state.selectedFilterFieldForWON);
                            return {
                                ...state,
                                fieldIdForRollUpRule: action.responseData.FieldId,
                                rollUpConfirmationMessage: confirmMsg,
                            }

                        case "FAIL":
                            return {
                                ...state,
                                validation_ErrorForWON: "An error occurred during validation, please try again later",
                                modalViewed: !state.modalViewed,
                            }
                    };
                }
                break;
            case 'REMOVE_WN_ERRORS': {

                let newState: IAEIUIState = {
                    ...state,
                    removeWNRecordsConfirmationMessage: undefined
                };

                let wnUIData = action.data.uiData;

                // Validate Error Note Selection
                if (wnUIData) {
                    if (wnUIData.selectedWNotes.length > 0) {
                        let moreSelectionMsg: string = (wnUIData.selectedWNotes.length > 1) ? `notes along with all their criteria` : `note along with all its criteria`;
                        return {
                            ...state,
                            removeWNRecordsConfirmationMessage: `You have chosen to remove ${wnUIData.selectedWNotes.length} ${moreSelectionMsg}. This action cannot be undone. Are you sure you want to continue?`,
                        }
                    }
                }

                return newState;
            }
                break;
            case 'ERROR_WN_VALIDATION': {
                if (state.validation_ErrorForWN !== action.data) {
                    return {
                        ...state,
                        validation_ErrorForWN: action.data,
                        modalViewed: !state.modalViewed,
                    }
                }
            }
                break;
            case 'SET_REMOVE_WNRECORDS_CONFIRMATION_MSG': {
                if (state.removeWNRecordsConfirmationMessage !== action.data) {
                    return {
                        ...state,
                        removeWNRecordsConfirmationMessage: action.data,
                    }
                }
            }
            break;
            case 'REMOVE_WN_EDITNOTE_CRITERIA': {
                let newState: IAEIUIState = {
                    ...state,
                    removeCriteriaConfirmationMessage: undefined,
                    modalViewed: true
                };

                if (action.data.uiData && action.data.masterCrud && action.data.masterCrud.ErrorsWithNotes && action.data.masterCrud.ErrorsWithNotes.CriteriaList &&
                    action.data.masterCrud.ErrorsWithNotes.CriteriaList.Criteria) {

                    let filteredCriteria = action.data.masterCrud.ErrorsWithNotes.CriteriaList.Criteria.find(clst => (
                        clst['@ErrorClientAdditionalId'] === action.data.uiData.selectedNoteErrorClientAdditionalId &&
                        clst['@ErrorClientAdditionalMessageId'] === action.data.uiData.selectedCriteriaClientAdditionalMsgId));

                    if (filteredCriteria != undefined) {
                        for (let i = 0; i < action.data.masterCrud.ErrorsWithNotes.CriteriaList.Criteria.length; i++) {
                            if (action.data.masterCrud.ErrorsWithNotes.CriteriaList.Criteria[i] !== undefined) {
                                if (action.data.masterCrud.ErrorsWithNotes.CriteriaList.Criteria[i]['@ErrorClientAdditionalMessageId'] === action.data.uiData.selectedCriteriaClientAdditionalMsgId && action.data.masterCrud.ErrorsWithNotes.CriteriaList.Criteria[i]['@ErrorClientAdditionalId'] === action.data.uiData.selectedNoteErrorClientAdditionalId) {
                                    action.data.masterCrud.ErrorsWithNotes.CriteriaList.Criteria.splice(i, 1);
                                }
                            }
                        }
                    }
                }

                let selectedEditNoteChange = state.selectedEditNote;
                if (selectedEditNoteChange !== undefined) {
                    selectedEditNoteChange.value = "";
                    selectedEditNoteChange.text = '-- Add New Criteria --';
                }
                // Validate Error Note Selection
                if (action.data.uiData) {
                    return {
                        ...state,
                        selectedEditNote: selectedEditNoteChange,
                        removeCriteriaConfirmationMessage: ManageHelpNotesValidationErrors.selectedCriteriaFieldRemoveConfirmation,
                        modalViewed: false
                    }
                } else {
                    return {
                        ...state,
                        selectedEditNote: selectedEditNoteChange,
                        removeCriteriaConfirmationMessage: ManageHelpNotesValidationErrors.selectCriteriaFieldToRemove,
                        modalViewed: false
                    }
                }

                return newState;
            }
                break;
            case 'SET_REMOVE_EDITCRITERIA_CONFIRMATION_MSG': {
                if (state.removeCriteriaConfirmationMessage !== action.data) {
                    return {
                        ...state,
                        removeCriteriaConfirmationMessage: action.data,
                    }
                }
            }
                break;
        }
    }

    return state || defaultState;
}
