import * as React from 'react';
import { Input as UICLInput } from 'ui-core';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';



interface IInputLabelProps {
    labelWidth: string;
};

const InputLabel = styled.label<IInputLabelProps>`
  display: block;
  margin-bottom: 0.3rem;
  color: ${Colors.grey100};
  ${Typography.ARMFontFamily};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  ${Typography.bold};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: left;
  padding-right: 2px;
  padding-top: 3px;

  ${(p) => p.labelWidth && `width: ${p.labelWidth} `};


`;

// NOTE: the width change if labelWidth is set, is hardcoded for remitfilter, otherwise the width can be changed.
interface IARMNarrowInputWrapperProps {
    width?: string;
    //    disabled: boolean;
    hasError?: boolean;
    labelWidth?: string;
};

export const ARMNarrowInputWrapper = styled.div<IARMNarrowInputWrapperProps>`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin: 0 auto;
    cursor: pointer;
    min-width: 20px;
    min-height:25px;
    max-height:35px;
    
    .narrow_input > input{
        padding-top: 2px;
        height: 22px !important;
        padding-bottom: 1px;
        margin-left:4px;
        ${(p) => p.width && `width: ${p.width}  `};
    }
      
    .narrow_input {
        ${(p) => p.width && `width: ${p.width}  `};
    }

 
  ${(p) => p.hasError && `border: 1px  solid red  `};
  ${(p) => p.hasError && `margin-bottom :'25px  `};
  
  ${(p) => (p.labelWidth == '160px' || p.labelWidth == '0px') && `.narrow_input { width: 280px;} `};

`;
//float: right; -- .narrow_input
export interface IArmNarrowInputProps {
    id: string;
    domID: string;
    label?: string;
    width?: string;
    disabled: boolean;
    hasError: boolean;
    labelWidth?: string;
}



export class ARMNarrowInput extends React.Component<IArmNarrowInputProps | any, {}>
{
    static defaultProps: IArmNarrowInputProps = {
        id: 'narrow_input_default_id',
        domID: '',
        hasError: false,
        disabled: false
    };

    constructor(props: IArmNarrowInputProps) {
        super(props);
        this.state = {
        }
    }

    render() {

        const { label, ...childProps } = this.props;
        const { labelWidth, ...childProps2 } = childProps;


        return (
            <span style={this.props.hasError ? { marginBottom: '10px' } : {}}>
                <ARMNarrowInputWrapper
                    id={this.props.domID?.length > 0 ? this.props.domID : this.props.id + "_wrapper"}
                    width={this.props.width}
                    labelWidth={this.props.labelWidth}
                >
                    <InputLabel
                        id={this.props.domID?.length > 0 ? this.props.domID : this.props.id + "_label"}
                        className={'arm_input_label_before'}
                        labelWidth={this.props.labelWidth}
                    >
                        {this.props.label}
                    </InputLabel>
                    <UICLInput  {...childProps2} className={'narrow_input'} disabled={this.props.disabled == true ? "disabled" : ""} />


                </ARMNarrowInputWrapper>
            </span>




        );
    }
};
