import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IConfigureSubmitterState, IConfigureSubmitterActionProps, actionCreators, validationCallback } from '@store/ConfigureSubmitter';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper, DialogButton, DialogButtonOK } from '@common/DialogWrapper';
import { ARMLabel } from '../../Transactions/Logon/styles/ARMLoginForm';
import { RadioButtonComponent } from '@common/RadioButton';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { ARMNarrowInput } from '../../common/UICLWrappers/ARMNarrowInput';
import { ARMNarrowDropdown } from '../../common/UICLWrappers/ARMNarrowDropdown';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import _ from 'lodash';
import { Tabs } from 'ui-core';
import { ICookies_Config } from '../../../store/ConfigData';
import { URLs } from '../../../commonResources/constants';
import { IsFieldFilled } from '@commonResources/validations';
import { ModalConfirmation } from '../../common/ModalConfirmation';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
      
    #dialogfieldset {
       width:100%;
       height:100%;
    }
    #rowspace {
        margin-top: 8px
    }
    #configure-label {
        margin-left: 6px;
        margin-top: 4px
        width: 258px;
    }
    #configure-radiobutton {      
        margin-right: 575px
    }
    #facility-physician-radiobutton{      
        margin-right: 434px
    }
    #idUseBoth_wrapper{      
       width: 76px;
    }
    #lob-select {
        width:300px;
    }
    #export-dialogfieldset {
        width: 100%;
        height: 100%;
    }
    #triple-radiobutton{      
        margin-right: 435px
    }
    #addon-eligibilitytype {      
        margin-right: 275px
    }
    #idDisableEligibilityType_wrapper {      
        width: 0px;
    }
    #idEnableEligibilityType_wrapper {      
        margin-right: 201px;
    }
    #addon-textbox-secondaryDestOMS {      
        margin-right: 575px
        margin-left: 19px;
    }
    #addon-textbox-secondaryDestBill {      
        margin-right: 575px
        margin-left: 16px;
    }
    #addon-radiobutton-pcgedits {      
        margin-right: 160px
    }
    #secondary-radiobutton {      
        margin-right: 553px
    }
`;


const ExportClaimChangesContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    
    padding: 10px;
    justify-content: flex-end;
 
    div.text-input input[type='text']{
        height: 30px;
    }
   
    #hospitalid {
        width: 344px;
        text-transform: uppercase;  
        margin-left: 13px;
    }
    #hostid {
        width: 344px;
        text-transform: uppercase;  
        margin-left: 0px;
    }
    #hostid_label {
        width: 90px;
    }
    #tablename {
        width: 344px;
        text-transform: uppercase;  
        margin-left: 0px;
    }
     #tablename_label {
        width: 90px;
    }
    #ARMNarrowDropdown {
        margin-left: 32px; 
    }
    #scaledserver {
        width: 100px;        
    }
    #servicecentername {
        width: 304px;
        text-transform: uppercase;  
    }
`;

const ExportClaimChangesContent1 = styled.div`
    #input-pcgtenant {
        width: 318px;
        text-transform: uppercase;
    }
`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
    canSecCheck1: boolean,
    canSecCheck2: boolean,
};

interface IComponentState {
    isUpdate: boolean,
    updating: boolean,
    cancelLeave: boolean,
    isFacilityLookup1: boolean;
    isFacilityLookup2: boolean;
    facilityLookupValue: string;
    isDES: boolean;
    isMCAMethod: boolean;
    mcaMethodValue: string;
    isEditSuitesEnabled: boolean;
    secondaryDestinationValue: string;
    eligibilityEnabledValue: string;
    enableDentalValue: string;
    enableDigitalBillerAutomationValue: string;
    enablePCGEditsValue: string;
}

interface HeaderProps { columns: {} }

export const DEFAULT_STATE: IComponentState = {
    isUpdate: false,
    updating: false,
    cancelLeave: false,
    isFacilityLookup1: false,
    isFacilityLookup2: false,
    facilityLookupValue: "",
    isDES: false,
    isMCAMethod: false,
    mcaMethodValue: "",
    isEditSuitesEnabled: false,
    secondaryDestinationValue: "",
    eligibilityEnabledValue: "",
    enableDentalValue: "",
    enableDigitalBillerAutomationValue: "",
    enablePCGEditsValue: "",
};

interface CS_GenericLookupList {
    '@ID': string;
    '@Name': string;
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IConfigureSubmitterProps = IMergeCrudComponentProps<IConfigureSubmitterState, IConfigureSubmitterActionProps, IOwnProps>;

export class ConfigureSubmitter extends React.Component<IConfigureSubmitterProps, IComponentState> {
    static addItemCount: number = 0;
    protected selectedOperation: string = '';
    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canSecCheck1: false,
        canSecCheck2: false,
        title: "Configure Client",
    };

    constructor(props: IConfigureSubmitterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        pageLeave();
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "EnableTenetFeatures" }, { name: "CSFN_ISClaimVision" }] });
        this.getSecondaryDestination();
        this.getEligibilityEnabled();
        this.getEnableDental();
        this.getEnableDigitalBillerAutomation();
        this.getEnablePCGEdits();

        this.props.action.crud.get({ crudId: CrudTypes.mctiConfigureSubmitter }, (result: ConfigureSubmitterInfoType) => {
            if (validationCallback(result)) {
                this.props.action.ui.initalizeConfigureSubmitter({
                    masterCrud: result,
                    uiData: result.ConfigureSubmitter,
                });
                return true;
            }
        });
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    /* -----------------------------------  */
    /* --------  ACTION METHODS ----------  */
    /* -----------------------------------  */

    public onSelectTab(e: React.MouseEvent<HTMLButtonElement>, selObj: any) {
        this.props.action.ui.selectTabIndex(selObj.selectedTabIndex);
    }

    public onChangeCheckFilter(checkFilter: string) {
        handleChange()
        this.props.action.ui.updateCheckFilter({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: checkFilter }
        });
    }

    public onChangeFacilityLookup(facilityLookup: string) {
        handleChange()
        let curdAudit72FacilityConfiguration = this.props.dataStore.ui.audit72FacilityConfiguration
        let curdFacilityLookup = this.props.dataStore.ui.facilityLookup

        if (curdFacilityLookup == "1" && facilityLookup == "0" && curdAudit72FacilityConfiguration == "2") {
            this.setState({ facilityLookupValue: facilityLookup })
            this.setState({ isFacilityLookup1: true })
        } else if (curdFacilityLookup == "0" && facilityLookup != "0" && curdAudit72FacilityConfiguration == "1") {
            this.setState({ facilityLookupValue: facilityLookup })
            this.setState({ isFacilityLookup2: true })
        } else {
            this.props.action.ui.updateFacilityLookup({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { value: facilityLookup }
            });
        }
    }

    public onChangeCFIProviderNumberUpdatable(cfiProviderNumberUpdatable: string) {
        handleChange()
        this.props.action.ui.updateCFIProviderNumberUpdatable({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: cfiProviderNumberUpdatable }
        });
    }

    public onChangeLOBByName(lobByName: string) {
        handleChange()
        this.props.action.ui.updateLOBByName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: lobByName }
        });
    }

    public onChangePhysAutoEntry(physAutoEntry: string) {
        handleChange()
        this.props.action.ui.updatePhysAutoEntry({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: physAutoEntry }
        });
    }

    public onChangeNCPDP(ncpdp: string) {
        handleChange()
        this.props.action.ui.updateNCPDP({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: ncpdp }
        });
    }

    public onChangeDES(des: string) {
        handleChange()
        if (des == "1") {
            this.setState({ isDES: true })
        } else {
            this.props.action.ui.updateDES({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { value: des }
            });
        }
    }

    public onChangeThirdPartyPrint(thirdPartyPrint: string) {
        handleChange()
        this.props.action.ui.updateThirdPartyPrint({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: thirdPartyPrint }
        });
    }

    public onChangeDashboardEnabled(dashboardEnabled: string) {
        handleChange()
        this.props.action.ui.updateDashboardEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: dashboardEnabled }
        });
    }

    public onChangeReconEnabled(reconEnabled: string) {
        handleChange()
        this.props.action.ui.updateReconEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: reconEnabled }
        });
    }

    public onChangeCPIDLookup(cpiDLookup: string) {
        handleChange()
        this.props.action.ui.updateCPIDLookup({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: cpiDLookup }
        });
    }

    public onChangeECARLookup(ecarLookup: string) {
        handleChange()
        this.props.action.ui.updateECARLookup({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: ecarLookup }
        });
    }

    public onChangeMCAMethod(mcaMethod: string) {
        handleChange()
        let curdMCAMethod = this.props.dataStore.ui.mcaMethod
        if ((curdMCAMethod == "1" && mcaMethod == "0") || (curdMCAMethod == "0" && mcaMethod == "1")) {
            this.setState({ mcaMethodValue: mcaMethod })
            this.setState({ isMCAMethod: true })
        } else {
            this.props.action.ui.updateMCAMethod({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { value: mcaMethod }
            });
        }
    }

    public onChangeMCAPayerConfigMethod(mcaPayerConfigMethod: string) {
        handleChange()
        this.props.action.ui.updateMCAPayerConfigMethod({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: mcaPayerConfigMethod }
        });
    }

    public onChangeSDREnabled(sdrEnabled: string) {
        handleChange()
        this.props.action.ui.updateSDREnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: sdrEnabled }
        });
    }

    public onChangeMobileEnabled(mobileEnabled: string) {
        handleChange()
        this.props.action.ui.updateMobileEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: mobileEnabled }
        });
    }

    public onChangeAccupostLinkEnabled(accupostLinkEnabled: string) {
        handleChange()
        this.props.action.ui.updateAccupostLinkEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: accupostLinkEnabled }
        });
    }

    public onChangeSubmitRemitFileEnabled(submitRemitFileEnabled: string) {
        handleChange()
        this.props.action.ui.updateSubmitRemitFileEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: submitRemitFileEnabled }
        });
    }

    public onChangeEnforceCIAMLogin(enforceCIAMLogin: string) {
        handleChange()
        this.props.action.ui.updateEnforceCIAMLogin({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: enforceCIAMLogin }
        });
    }

    SelectedSDRTypeList(e: any) {
        handleChange()
        this.props.action.ui.selectedSDRTypeList({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: e.target.value }
        });
    }

    SelectedProductProfileList(e: any) {
        handleChange()
        this.props.action.ui.selectedProductProfileList({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: e.target.value }
        });
    }

    public getGenericLookupList_Array(lookupList: CS_GenericLookupList[] | null) {
        let result: any[] = [];
        lookupList?.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getProductProfileLookupList_Array(lookupList: CS_GenericLookupList[] | null) {
        let result: any[] = [];
        lookupList?.forEach(ob => (ob['@Name']) ? result.push({ label: ob['@Name'], value: ob['@ID'] })
            : result.push({ label: '', value: ob['@ID'] }));
        return result;
    }

    onChangeHospitalId(hospitalId: string) {
        handleChange()
        this.props.action.ui.updateHospitalId({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: hospitalId.trim()
            }
        });
    }

    onChangeFieldName(fieldName: string) {
        handleChange()
        this.props.action.ui.updateFieldName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: fieldName.trim()
            }
        });
    }

    onChangeTableName(tableName: string) {
        handleChange()
        this.props.action.ui.updateTableName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: tableName.trim()
            }
        });
    }

    onChangeParallonSCName(parallonSCName: string) {
        handleChange()
        this.props.action.ui.updateParallonSCName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: parallonSCName.trim()
            }
        });
    }

    //ADD ON
    onChangeSiteAccess(siteAccess: string) {
        handleChange()
        this.props.action.ui.updateSiteAccess({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: siteAccess
            }
        });
    }

    onChangeRelease(release: string) {
        handleChange()
        this.props.action.ui.updateRelease({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: release.trim()
            }
        });
    }

    onChangeMedicareDDE(medicareDDE: string) {
        handleChange()
        this.props.action.ui.updateMedicareDDE({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: medicareDDE.trim()
            }
        });
    }

    onChangeMCDEEnabled(mcdeEnabled: string) {
        handleChange()
        this.props.action.ui.updateMCDEEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: mcdeEnabled.trim()
            }
        });
    }

    onChangeFilePassThrough(filePassThrough: string) {
        handleChange()
        this.props.action.ui.updateFilePassThrough({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: filePassThrough.trim()
            }
        });
    }

    onChangePreBillEnabled(preBillEnabled: string) {
        handleChange()
        this.props.action.ui.updatePreBillEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: preBillEnabled.trim()
            }
        });
    }

    onChangeEligibilityType(eligibilityType: string) {
        handleChange()
        this.props.action.ui.updateEligibilityType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: eligibilityType.trim()
            }
        });
    }

    onChangeEnhancedClaimStatusEnabled(enhancedClaimStatusEnabled: string) {
        handleChange()
        this.props.action.ui.updateEnhancedClaimStatusEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: enhancedClaimStatusEnabled.trim()
            }
        });
    }

    onChangeSecondaryDest(secondaryDest: string) {
        handleChange()
        this.props.action.ui.updateSecondaryDest({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: secondaryDest.trim()
            }
        });
    }

    onChangeOMSId(omsId: string) {
        handleChange()
        this.props.action.ui.updateOMSId({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: omsId.trim()
            }
        });
    }

    onChangeBillId(billId: string) {
        handleChange()
        this.props.action.ui.updateBillId({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: billId.trim()
            }
        });
    }

    onChangeBIEnabled(biEnabled: string) {
        handleChange()
        this.props.action.ui.updateBIEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: biEnabled.trim()
            }
        });
    }

    onChangeHCDEnabled(hcdEnabled: string) {
        handleChange()
        this.props.action.ui.updateHCDEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: hcdEnabled.trim()
            }
        });
    }

    onChangeHCDOMSId(hcdOMSId: string) {
        handleChange()
        this.props.action.ui.updateHCDOMSId({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: hcdOMSId.trim()
            }
        });
    }

    onChangeClearinghousePrintEnabled(clearinghousePrintEnabled: string) {
        handleChange()
        this.props.action.ui.updateClearinghousePrintEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: clearinghousePrintEnabled.trim()
            }
        });
    }

    onChangeAppealsEnabled(appealsEnabled: string) {
        handleChange()
        this.props.action.ui.updateAppealsEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: appealsEnabled.trim()
            }
        });
    }

    onChangeAttachmentsEnabled(attachmentsEnabled: string) {
        handleChange()
        this.props.action.ui.updateAttachmentsEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: attachmentsEnabled.trim()
            }
        });
    }

    onChangeEditSuitesEnabled(editSuitesEnabled: string) {
        handleChange()
        if (editSuitesEnabled == "0") {
            this.setState({ isEditSuitesEnabled: true })
        } else {
            this.props.action.ui.updateEditSuitesEnabled({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: editSuitesEnabled.trim()
                }
            });
        }
    }

    onChangeAnticipatedPaymentEnabled(anticipatedPaymentEnabled: string) {
        handleChange()
        this.props.action.ui.updateAnticipatedPaymentEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: anticipatedPaymentEnabled.trim()
            }
        });
    }

    onChangePropensityToDenyEnabled(propensityToDenyEnabled: string) {
        handleChange()
        this.props.action.ui.updatePropensityToDenyEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: propensityToDenyEnabled.trim()
            }
        });
    }

    onChangeEnterpriseManagementEnabled(enterpriseManagementEnabled: string) {
        handleChange()
        this.props.action.ui.updateEnterpriseManagementEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: enterpriseManagementEnabled.trim()
            }
        });
    }

    onChangeAssuranceDentalEnabled(assuranceDentalEnabled: string) {
        handleChange()
        this.props.action.ui.updateAssuranceDentalEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: assuranceDentalEnabled.trim()
            }
        });
    }

    onChangeEnableDigitalBillerAutomation(enableDigitalBillerAutomation: string) {
        handleChange()
        this.props.action.ui.updateEnableDigitalBillerAutomation({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: enableDigitalBillerAutomation.trim()
            }
        });
    }

    onChangeDDEPlusLinkEnabled(ddePlusLinkEnabled: string) {
        handleChange()
        this.props.action.ui.updateDDEPlusLinkEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: ddePlusLinkEnabled.trim()
            }
        });
    }

    onChangePCGEditsEnabled(pcgEditsEnabled: string) {
        handleChange()
        this.props.action.ui.updatePCGEditsEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: pcgEditsEnabled.trim()
            }
        });
    }

    onChangePCGTenantId(pcgTenantId: string) {
        handleChange()
        this.props.action.ui.updatePCGTenantId({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: pcgTenantId.trim()
            }
        });
    }

    public getSecondaryDestination = async () => {
        const url = URLs.api + '/api/data/securityInfo/getSecondaryDestination';
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ secondaryDestinationValue: jsonData });
            })
    }

    public getEligibilityEnabled = async () => {
        const url = URLs.api + '/api/data/securityInfo/getEligibilityEnabled';
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ eligibilityEnabledValue: jsonData });
            })
    }

    public getEnableDental = async () => {
        const url = URLs.api + '/api/data/securityInfo/getEnableDental';
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ enableDentalValue: jsonData });
            })
    }

    public getEnableDigitalBillerAutomation = async () => {
        const url = URLs.api + '/api/data/securityInfo/getEnableDigitalBillerAutomation';
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ enableDigitalBillerAutomationValue: jsonData });
            })
    }

    public getEnablePCGEdits = async () => {
        const url = URLs.api + '/api/data/securityInfo/getEnablePCGEdits';
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ enablePCGEditsValue: jsonData });
            })
    }

    public sendCommandError(id: string, msg: string) {
        if (!id || (id && id.length === 0) || !msg || (msg && msg.length === 0))
            return;

        this.props.action.ui.errorCommand({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { id: id, value: msg }
        });
    }

    /* -----------------------------------  */
    /* ------  BUTTON RELATED METHODS ----  */
    /* -----------------------------------  */
    public crudLoaded(): boolean {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.ConfigureSubmitter
        )
            return true;
        return false;
    }


    public validateEntry(): boolean {
        if (!this.crudLoaded())
            return false;
        let requiredFieldErrorFound = false;

        if (!IsFieldFilled(this.props.dataStore.ui.fieldName)) {
            this.sendCommandError('ValidateFieldName', "This field must not be blank.");
            requiredFieldErrorFound = true;
        }
        if (!IsFieldFilled(this.props.dataStore.ui.tableName)) {
            this.sendCommandError('ValidateTableName', "This field must not be blank.");
            requiredFieldErrorFound = true;
        }
        if (this.props.dataStore.ui.hcdEnabled == "1") {
            if (!IsFieldFilled(this.props.dataStore.ui.hcdOMSId)) {
                this.sendCommandError('ValidateHCDOMSId', "This field cannot be left blank and must be 6 characters in length.");
                requiredFieldErrorFound = true;
            }
            else if (this.props.dataStore.ui.hcdOMSId.length > 0 && this.props.dataStore.ui.hcdOMSId.length < 6) {
                this.sendCommandError('ValidateHCDOMSId', "This field must be 6 characters in length.");
                requiredFieldErrorFound = true;
            }
        }
        if (this.props.dataStore.ui.secondaryDest == "1" || this.props.dataStore.ui.secondaryDest == "2") {
            if (this.props.dataStore.ui.omsId == "") {
                this.props.dataStore.ui.omsId = 'INT264';
            }
            else if (this.props.dataStore.ui.omsId.length > 0 && this.props.dataStore.ui.omsId.length < 6) {
                this.sendCommandError('ValidateOMSId', "This field must be 6 characters in length.");
                requiredFieldErrorFound = true;
            }
        }
        if (this.props.dataStore.ui.secondaryDest == "2") {
            if (!IsFieldFilled(this.props.dataStore.ui.billId)) {
                this.sendCommandError('ValidateBillId', "This field cannot be left blank and must be 6 characters in length.");
                requiredFieldErrorFound = true;
            }
            else if (this.props.dataStore.ui.billId.length > 0 && this.props.dataStore.ui.billId.length < 6) {
                this.sendCommandError('ValidateBillId', "This field must be 6 characters in length.");
                requiredFieldErrorFound = true;
            }
        }
        if (this.props.dataStore.ui.pcgEditsEnabled == "1") {
            if (!IsFieldFilled(this.props.dataStore.ui.pcgTenantId)) {
                this.sendCommandError('ValidatePCGTenantId', "PCG Tenant ID cannot be left blank and must be atleast 8 characters in length.");
                requiredFieldErrorFound = true;
            }
            else if (this.props.dataStore.ui.pcgTenantId.length > 0 && this.props.dataStore.ui.pcgTenantId.length < 8) {
                this.sendCommandError('ValidatePCGTenantId', "PCG Tenant ID must be atleast 8 characters in length.");
                requiredFieldErrorFound = true;
            }
        }
        if (requiredFieldErrorFound)
            return false;

        return true;
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {

        if (!this.validateEntry())
            return;

        if (this.props.dataStore.ui.siteAccess == "2") {
            this.props.action.ui.updateSiteSuspend({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: "1"
                }
            });
            this.props.action.ui.updateSiteAccess({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: "0"
                }
            });
            this.props.action.ui.setSiteAccess(-1);
        }
        else if (this.props.dataStore.ui.siteAccess == "1") {
            this.props.action.ui.updateSiteAccess({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: "1"
                }
            });
            this.props.action.ui.updateSiteSuspend({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: "0"
                }
            });
            this.props.action.ui.setSiteAccess(1)
        } else {
            this.props.action.ui.updateSiteAccess({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: "0"
                }
            });
            this.props.action.ui.updateSiteSuspend({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: "0"
                }
            });
            this.props.action.ui.setSiteAccess(0)
        }

        let secondaryDest = this.props.dataStore.ui.secondaryDest
        if (secondaryDest.length > 0) {
            for (let idx = 0; idx < secondaryDest.length; idx++) {
                this.props.action.ui.setCidType(idx);
                break;
            }
        }

        this.props.action.ui.updateCrudData({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: ''
            }
        });

        this.props.action.crud.update({ crudId: CrudTypes.mctiConfigureSubmitter, data: this.props.dataStore.crud.data });
        this.props.history.push('/LandingPage');
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }
    /* -----------------------------------  */
    /* -----  DATA HELP METHODS ----------  */
    /* -----------------------------------  */


    public allowTenetFlag(): string {
        let allowTenetObj = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "EnableTenetFeatures");
        return (allowTenetObj && allowTenetObj.value ? allowTenetObj.value : "");
    }

    public csfnISClaimVisionFlag(): string {
        let csfnISClaimVisionObj = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "CSFN_ISClaimVision");
        return (csfnISClaimVisionObj && csfnISClaimVisionObj.value ? csfnISClaimVisionObj.value : "");
    }

    /* -----------------------------------  */
    /* -------------  RENDER -------------  */
    /* -----------------------------------  */

    public render() {

        let tabList: ITabItem[] = [{ label: 'Setup', domID: 'id-Setup' }, { label: 'Contracted Add On', domID: 'id-ContractedAddOn' }];

        let allowTenet = this.allowTenetFlag();
        let csfnISClaimVision = this.csfnISClaimVisionFlag();
        let secondaryDestConfigValue = this.state.secondaryDestinationValue;
        let eligibilityEnabledConfigValue = this.state.eligibilityEnabledValue;
        let enableDentalConfigValue = this.state.enableDentalValue;
        let enableDigitalBillerConfigValue = this.state.enableDigitalBillerAutomationValue;
        let enablePCGEditsConfigValue = this.state.enablePCGEditsValue;

        var instruction = <React.Fragment>Use this page to turn on access and release at the end of enrollment and to set up other submitter information that may be required.</React.Fragment>;
        var buttons = <OKCancelButtons
            disableOK={!this.props.canEdit}
            onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)}
            onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;


        return (
            <React.Fragment >
                <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/SUP_SubmitterConfig.htm' buttons={buttons} isBusy={(!this.props.dataStore.crud.data) || this.state.updating}>
                    {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                    <ContentWrapper>
                        <ContentRowWrapper>
                            <DialogFieldset id='dialogfieldset'>
                                <Tabs
                                    tabs={tabList}
                                    onTabSelect={(e: React.MouseEvent<HTMLButtonElement>, selObj: any) => this.onSelectTab(e, selObj)}
                                    selectedTabIndex={this.props.dataStore.ui.selectedTabIndex}>
                                </Tabs>
                                {this.props.dataStore.ui.selectedTabIndex !== 1 ?
                                    (
                                        <ContentWrapper>
                                            <ContentColumnWrapper>
                                                <ContentRowWrapper style={{ marginTop: "15px" }}>
                                                    <DialogLabel id='configure-label'>Re-Filter On Get Next:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.checkFilter === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCheckFilter("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.checkFilter === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCheckFilter("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <ContentRowWrapper id='facility-physician-radiobutton'>
                                                        <DialogLabel id='configure-label'>Facility/Physician Lookup:</DialogLabel>
                                                        <RadioButtonComponent
                                                            id="idUseBoth"
                                                            label="Use Both"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.facilityLookup === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeFacilityLookup("1")}
                                                            disabled={!this.props.canSecCheck2}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idBypassPhys"
                                                            label="Bypass Phys"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.facilityLookup === "2"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeFacilityLookup("2")}
                                                            disabled={!this.props.canSecCheck2}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idBypassBoth"
                                                            label="Bypass Both"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.facilityLookup === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeFacilityLookup("0")}
                                                            disabled={!this.props.canSecCheck2}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>CFI Provider Number Update:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.cfiProviderNumberUpdatable === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCFIProviderNumberUpdatable("0")}
                                                            disabled={this.props.dataStore.ui.facilityLookup === "1" || this.props.dataStore.ui.facilityLookup === "2"}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.cfiProviderNumberUpdatable === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCFIProviderNumberUpdatable("1")}
                                                            disabled={this.props.dataStore.ui.facilityLookup === "1" || this.props.dataStore.ui.facilityLookup === "2"}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Determine LOB by Payer Name:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.lobByName === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeLOBByName("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.lobByName === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeLOBByName("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Physician AutoEntry:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Use"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.physAutoEntry === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePhysAutoEntry("1")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Bypass"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.physAutoEntry === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePhysAutoEntry("0")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>NCPDP:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.ncpdp === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeNCPDP("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.ncpdp === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeNCPDP("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                {(allowTenet === '1') ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <DialogLabel id='configure-label'>DES:</DialogLabel>
                                                        <ContentRowWrapper id='configure-radiobutton'>
                                                            <RadioButtonComponent
                                                                id="idDisable"
                                                                label="Test"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.des === "0"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeDES("0")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idEnable"
                                                                label="Live"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.des === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeDES("1")}
                                                                disabled={false}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }
                                                {(allowTenet === '1') ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <DialogLabel id='configure-label'>Third Party Print:</DialogLabel>
                                                        <ContentRowWrapper id='configure-radiobutton'>
                                                            <RadioButtonComponent
                                                                id="idDisable"
                                                                label="Disable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.thirdPartyPrint === "0"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeThirdPartyPrint("0")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idEnable"
                                                                label="Enable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.thirdPartyPrint === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeThirdPartyPrint("1")}
                                                                disabled={false}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Dashboard:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.dashboardEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeDashboardEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.dashboardEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeDashboardEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Reconciliation:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.reconEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeReconEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.reconEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeReconEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>CPID Lookup:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.cpiDLookup === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCPIDLookup("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.cpiDLookup === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCPIDLookup("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>ECAR Lookup:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.ecarLookup === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeECARLookup("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.ecarLookup === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeECARLookup("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>MCA Method:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Search"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.mcaMethod === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMCAMethod("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="List"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.mcaMethod === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMCAMethod("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>MCA Payer Config Method:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.mcaPayerConfigMethod === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMCAPayerConfigMethod("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.mcaPayerConfigMethod === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMCAPayerConfigMethod("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Regulatory Reporting Tool:</DialogLabel>
                                                    <ContentRowWrapper style={{ marginRight: "269px" }}>
                                                        <RadioButtonComponent
                                                            id="idDisable4"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.sdrEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSDREnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable4"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.sdrEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSDREnabled("1")}
                                                            disabled={false}
                                                        />
                                                        <ARMNarrowDropdown
                                                            title={""}
                                                            className="dropdown"
                                                            isLabel={true}
                                                            width="300px"
                                                            multiple="false"
                                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                this.SelectedSDRTypeList(e)
                                                            }}
                                                            records={this.getGenericLookupList_Array(this.props.dataStore.ui.sdrTypeList)}
                                                            optionFields={{
                                                                value: "value",
                                                                text: "label"
                                                            }}
                                                            disabled={this.props.dataStore.ui.sdrEnabled === "0"}
                                                            selectedValue={this.props.dataStore.ui.sdrType}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Mobile Access:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.mobileEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMobileEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.mobileEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMobileEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>AccuPost Link:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.accupostLinkEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAccupostLinkEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.accupostLinkEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAccupostLinkEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Submit Remit File:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.submitRemitFileEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSubmitRemitFileEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.submitRemitFileEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSubmitRemitFileEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Enforce CIAM Log-in:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.enforceCIAMLogin === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEnforceCIAMLogin("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.enforceCIAMLogin === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEnforceCIAMLogin("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogFieldset id='export-dialogfieldset' width='100%' >
                                                        <DialogLegend>Export Claim Changes</DialogLegend>
                                                        <ExportClaimChangesContent>
                                                            <ARMNarrowInput
                                                                domID='txt_HospitalId'
                                                                id='hospitalid'
                                                                classNameValue="text-input"
                                                                label="Hospital ID:"
                                                                maxLength={50}
                                                                initialValue={this.props.dataStore.ui.hospitalId}
                                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHospitalId(e.target.value)}
                                                            />
                                                            <div style={{ height: "6px" }}></div>
                                                            <ARMNarrowInput
                                                                id='hostid'
                                                                classNameValue="text-input"
                                                                label="*Host ID:"
                                                                maxLength={50}
                                                                initialValue={this.props.dataStore.ui.fieldName}
                                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeFieldName(e.target.value)}
                                                                hasError={this.props.dataStore.ui.fieldNameError && this.props.dataStore.ui.fieldNameError !== ''}
                                                                errorMessage={this.props.dataStore.ui.fieldNameError}
                                                            />
                                                            <div style={{ height: "6px" }}></div>
                                                            <ARMNarrowInput
                                                                id='tablename'
                                                                classNameValue="text-input"
                                                                label="*Table Name:"
                                                                maxLength={50}
                                                                initialValue={this.props.dataStore.ui.tableName}
                                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeTableName(e.target.value)}
                                                                hasError={this.props.dataStore.ui.tableNameError && this.props.dataStore.ui.tableNameError !== ''}
                                                                errorMessage={this.props.dataStore.ui.tableNameError}

                                                            />
                                                        </ExportClaimChangesContent>
                                                    </DialogFieldset>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <ExportClaimChangesContent>
                                                        <ARMNarrowDropdown
                                                            label={"Product Profile:"}
                                                            className="dropdown"
                                                            isLabel={true}
                                                            width="300px"
                                                            multiple="false"
                                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                this.SelectedProductProfileList(e)
                                                            }}
                                                            records={this.getProductProfileLookupList_Array(this.props.dataStore.ui.productProfileList)}
                                                            optionFields={{
                                                                value: "value",
                                                                text: "label"
                                                            }}
                                                            selectedValue={this.props.dataStore.ui.productProfileId}
                                                        />
                                                        <div style={{ height: "6px" }}></div>
                                                        <ContentRowWrapper>
                                                            <ContentColumnWrapper>
                                                                <DialogLabel id='scaledserver'>Scaled Server:</DialogLabel>
                                                            </ContentColumnWrapper>
                                                            <ContentColumnWrapper>
                                                                <ARMLabel style={{ textAlign: "left", width: "320px", marginRight: "507px" }}>{this.props.dataStore.ui.serverName}</ARMLabel>
                                                            </ContentColumnWrapper>
                                                        </ContentRowWrapper>
                                                        <div style={{ height: "6px" }}></div>
                                                        <ARMNarrowInput
                                                            id='servicecentername'
                                                            classNameValue="text-input"
                                                            label="Service Center Name:  "
                                                            maxLength={30}
                                                            initialValue={this.props.dataStore.ui.parallonSCName}
                                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeParallonSCName(e.target.value)}
                                                        />
                                                    </ExportClaimChangesContent>
                                                </ContentRowWrapper>

                                            </ContentColumnWrapper>
                                        </ContentWrapper>
                                    ) :
                                    (
                                        <ContentWrapper>
                                            <ContentColumnWrapper>
                                                <ContentRowWrapper style={{ marginTop: "15px" }}>
                                                    <ContentRowWrapper id='triple-radiobutton'>
                                                        <DialogLabel id='configure-label'>Site Access:</DialogLabel>
                                                        <RadioButtonComponent
                                                            id="idUseBoth"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.siteAccess === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSiteAccess("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idBypassPhys"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.siteAccess === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSiteAccess("1")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idBypassBoth"
                                                            label="Suspend"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.siteAccess === "2"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSiteAccess("2")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Release</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.release === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeRelease("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.release === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeRelease("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Direct Entry:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.medicareDDE === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMedicareDDE("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.medicareDDE === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMedicareDDE("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                {(this.props.dataStore.ui.medicareDDE == "1") ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <DialogLabel id='configure-label'>&nbsp; &nbsp; Enhanced DE Check Status:</DialogLabel>
                                                        <ContentRowWrapper id='configure-radiobutton'>
                                                            <RadioButtonComponent
                                                                id="idDisable"
                                                                label="Disable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.mcdeEnabled === "0"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMCDEEnabled("0")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idEnable"
                                                                label="Enable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.mcdeEnabled === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeMCDEEnabled("1")}
                                                                disabled={false}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }

                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>File Pass-Through:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.filePassThrough === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeFilePassThrough("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.filePassThrough === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeFilePassThrough("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Pre-Bill:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.preBillEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePreBillEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.preBillEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePreBillEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                {(eligibilityEnabledConfigValue === '1') ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <DialogLabel id='configure-label'>Eligibility Type:</DialogLabel>
                                                        <ContentRowWrapper id='addon-eligibilitytype'>
                                                            <RadioButtonComponent
                                                                id="idDisableEligibilityType"
                                                                label="None"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.eligibilityType === "0"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEligibilityType("0")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idEnableEligibilityType"
                                                                label="Change Healthcare RTE"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.eligibilityType === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEligibilityType("1")}
                                                                disabled={false}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }

                                                <ContentRowWrapper id='rowspace'>
                                                    <ContentRowWrapper id='triple-radiobutton'>
                                                        <DialogLabel id='configure-label'>Enhanced Claim Status:</DialogLabel>
                                                        <RadioButtonComponent
                                                            id="idUseBoth"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.enhancedClaimStatusEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEnhancedClaimStatusEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idBypassPhys"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.enhancedClaimStatusEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEnhancedClaimStatusEnabled("1")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idBypassBoth"
                                                            label="CFI Only"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.enhancedClaimStatusEnabled === "2"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEnhancedClaimStatusEnabled("2")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                <ContentRowWrapper id='rowspace'>
                                                    <ContentRowWrapper id='secondary-radiobutton'>
                                                        <DialogLabel id='configure-label'>Secondary Destination:</DialogLabel>
                                                        <RadioButtonComponent
                                                            id="idUseBoth"
                                                            label="Local Print"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.secondaryDest === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSecondaryDest("0")}
                                                            disabled={false}
                                                        />
                                                        {(secondaryDestConfigValue === 'Outsource') ?
                                                            <RadioButtonComponent
                                                                id="idBypassPhys"
                                                                label="Outsource"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.secondaryDest === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSecondaryDest("1")}
                                                                disabled={false}
                                                            />
                                                            :
                                                            <RadioButtonComponent
                                                                id="idBypassBoth"
                                                                label="PDS Print"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.secondaryDest === "2"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeSecondaryDest("2")}
                                                                disabled={false}
                                                            />
                                                        }
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>

                                                {(this.props.dataStore.ui.secondaryDest == "1" || this.props.dataStore.ui.secondaryDest == "2") ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <ContentRowWrapper id='addon-textbox-secondaryDestOMS'>
                                                            <ARMNarrowInput
                                                                id='servicecentername'
                                                                classNameValue="text-input"
                                                                label="Secondary PDS OMS Id:"
                                                                maxLength={6}
                                                                initialValue={this.props.dataStore.ui.omsId}
                                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeOMSId(e.target.value)}
                                                                hasError={this.props.dataStore.ui.omsIdError && this.props.dataStore.ui.omsIdError !== ''}
                                                                errorMessage={this.props.dataStore.ui.omsIdError}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }

                                                {(this.props.dataStore.ui.secondaryDest == "2") ?

                                                    <ContentRowWrapper id='rowspace'>
                                                        <ContentRowWrapper id='addon-textbox-secondaryDestBill'>
                                                            <ARMNarrowInput
                                                                id='servicecentername'
                                                                classNameValue="text-input"
                                                                label="*Secondary PDS Bill Id:"
                                                                maxLength={6}
                                                                initialValue={this.props.dataStore.ui.billId}
                                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeBillId(e.target.value)}
                                                                hasError={this.props.dataStore.ui.billError && this.props.dataStore.ui.billError !== ''}
                                                                errorMessage={this.props.dataStore.ui.billError}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>

                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }


                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Acuity Revenue Cycle Analytics™:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.biEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeBIEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.biEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeBIEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Hard Copy Direct:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.hcdEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHCDEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.hcdEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHCDEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                {(this.props.dataStore.ui.hcdEnabled == "1") ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <ContentRowWrapper id='addon-textbox-secondaryDestOMS'>
                                                            <ARMNarrowInput
                                                                id='servicecentername'
                                                                classNameValue="text-input"
                                                                label="HCD PDS OMS Id:"
                                                                maxLength={30}
                                                                initialValue={this.props.dataStore.ui.hcdOMSId}
                                                                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeHCDOMSId(e.target.value)}
                                                                hasError={this.props.dataStore.ui.hcdOMSIdError && this.props.dataStore.ui.hcdOMSIdError !== ''}
                                                                errorMessage={this.props.dataStore.ui.hcdOMSIdError}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }
                                                {(csfnISClaimVision === '1') ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <DialogLabel id='configure-label'>Clearinghouse Print:</DialogLabel>
                                                        <ContentRowWrapper id='configure-radiobutton'>
                                                            <RadioButtonComponent
                                                                id="idDisable"
                                                                label="Disable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.clearinghousePrintEnabled === "0"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeClearinghousePrintEnabled("0")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idEnable"
                                                                label="Enable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.clearinghousePrintEnabled === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeClearinghousePrintEnabled("1")}
                                                                disabled={false}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Appeals:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.appealsEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAppealsEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.appealsEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAppealsEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Attach Assist:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.attachmentsEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAttachmentsEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.attachmentsEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAttachmentsEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                {(this.props.canSecCheck1) ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <ContentRowWrapper id='triple-radiobutton'>
                                                            <DialogLabel id='configure-label'>Provider Knowledge Packs:</DialogLabel>
                                                            <RadioButtonComponent
                                                                id="idUseBoth"
                                                                label="Disable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.editSuitesEnabled === "0"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEditSuitesEnabled("0")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idBypassPhys"
                                                                label="Enable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.editSuitesEnabled === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEditSuitesEnabled("1")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idBypassBoth"
                                                                label="EAAS Only"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.editSuitesEnabled === "2"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEditSuitesEnabled("2")}
                                                                disabled={false}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Payment Clarity Workflow:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.anticipatedPaymentEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAnticipatedPaymentEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.anticipatedPaymentEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAnticipatedPaymentEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Propensity To Deny:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.propensityToDenyEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePropensityToDenyEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.propensityToDenyEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePropensityToDenyEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>Enterprise Configuration Management:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.enterpriseManagementEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEnterpriseManagementEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.enterpriseManagementEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEnterpriseManagementEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                {(enableDentalConfigValue === '1') ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <DialogLabel id='configure-label'>Dental:</DialogLabel>
                                                        <ContentRowWrapper id='configure-radiobutton'>
                                                            <RadioButtonComponent
                                                                id="idDisable"
                                                                label="Disable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.assuranceDentalEnabled === "0"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAssuranceDentalEnabled("0")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idEnable"
                                                                label="Enable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.assuranceDentalEnabled === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAssuranceDentalEnabled("1")}
                                                                disabled={false}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }
                                                {(enableDigitalBillerConfigValue === '1') ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <DialogLabel id='configure-label'>Digital Biller:</DialogLabel>
                                                        <ContentRowWrapper id='configure-radiobutton'>
                                                            <RadioButtonComponent
                                                                id="idDisable"
                                                                label="Disable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.enableDigitalBillerAutomation === "0"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEnableDigitalBillerAutomation("0")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idEnable"
                                                                label="Enable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.enableDigitalBillerAutomation === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeEnableDigitalBillerAutomation("1")}
                                                                disabled={false}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }
                                                <ContentRowWrapper id='rowspace'>
                                                    <DialogLabel id='configure-label'>DDE+:</DialogLabel>
                                                    <ContentRowWrapper id='configure-radiobutton'>
                                                        <RadioButtonComponent
                                                            id="idDisable"
                                                            label="Disable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.ddePlusLinkEnabled === "0"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeDDEPlusLinkEnabled("0")}
                                                            disabled={false}
                                                        />
                                                        <RadioButtonComponent
                                                            id="idEnable"
                                                            label="Enable"
                                                            className="radio-button"
                                                            checked={this.props.dataStore.ui.ddePlusLinkEnabled === "1"}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeDDEPlusLinkEnabled("1")}
                                                            disabled={false}
                                                        />
                                                    </ContentRowWrapper>
                                                </ContentRowWrapper>
                                                {(enablePCGEditsConfigValue == "1") ?
                                                    <ContentRowWrapper id='rowspace'>
                                                        <DialogLabel id='configure-label'>PCG Edits:</DialogLabel>
                                                        <ContentRowWrapper id='addon-radiobutton-pcgedits'>

                                                            <RadioButtonComponent
                                                                id="idDisable"
                                                                label="Disable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.pcgEditsEnabled === "0"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePCGEditsEnabled("0")}
                                                                disabled={false}
                                                            />
                                                            <RadioButtonComponent
                                                                id="idEnable"
                                                                label="Enable"
                                                                className="radio-button"
                                                                checked={this.props.dataStore.ui.pcgEditsEnabled === "1"}
                                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePCGEditsEnabled("1")}
                                                                disabled={false}
                                                            />
                                                            {(this.props.dataStore.ui.pcgEditsEnabled == "1") ?
                                                                <ExportClaimChangesContent1>
                                                                    <ARMNarrowInput
                                                                        id='input-pcgtenant'
                                                                        classNameValue="text-input"
                                                                        label="PCG Tenant ID:"
                                                                        maxLength={20}
                                                                        initialValue={this.props.dataStore.ui.pcgTenantId}
                                                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangePCGTenantId(e.target.value)}
                                                                        hasError={this.props.dataStore.ui.pcgTenantIdError && this.props.dataStore.ui.pcgTenantIdError !== ''}
                                                                        errorMessage={this.props.dataStore.ui.pcgTenantIdError}
                                                                    />
                                                                </ExportClaimChangesContent1> :
                                                                <div style={{ marginLeft: "414px", marginBottom: "25px" }}></div>
                                                            }
                                                        </ContentRowWrapper>
                                                    </ContentRowWrapper>
                                                    :
                                                    <ContentRowWrapper ></ContentRowWrapper>
                                                }

                                            </ContentColumnWrapper>
                                        </ContentWrapper>
                                    )
                                }
                            </DialogFieldset>
                        </ContentRowWrapper>
                    </ContentWrapper>

                    <ModalConfirmation
                        isOpen={this.state.isFacilityLookup1}
                        formattedMessage={

                            (<div>
                                <p>Do you really want to turn off bypass of the facility lookup?</p>
                                <p> You will need to verify the Audit72 settings for this client, the settings are for specific facilities.</p>
                            </div>)

                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isFacilityLookup1: false }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.props.action.ui.updateFacilityLookup({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: { value: this.state.facilityLookupValue }
                            });
                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isFacilityLookup1: false }) }}
                        okText={"Yes"}
                        cancelText={"No"}
                    />
                    <ModalConfirmation
                        isOpen={this.state.isFacilityLookup2}
                        formattedMessage={

                            (<div>
                                <p>Do you really want to turn off bypass of the facility lookup?</p>
                                <p> You will need to verify the Audit72 settings for this client, the settings are for a facility bypass client.</p>
                            </div>)

                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isFacilityLookup2: false }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.props.action.ui.updateFacilityLookup({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: { value: this.state.facilityLookupValue }
                            });
                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isFacilityLookup2: false }) }}
                        okText={"Yes"}
                        cancelText={"No"}
                    />
                    <ModalConfirmation
                        isOpen={this.state.isDES}
                        formattedMessage={(
                            <div>
                                {<p>Would you like to remove test data before going live?</p>}
                            </div>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isDES: false }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.props.action.ui.updateDES({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: { value: '1' }
                            });
                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.props.action.ui.updateDES({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: { value: '1' }
                            });
                        }
                        }
                        showDefaultClose={true}
                        okText={"Yes"}
                        cancelText={"No"}
                    />
                    <ModalConfirmation
                        isOpen={this.state.isMCAMethod}
                        formattedMessage={(
                            <div>
                                <p>Changing the MCA Method from  {this.state.mcaMethodValue == "0" ? "Search" : "List"}  will remove all existing error rules. This action cannot be undone and users will need to log out of the system to see the changes</p>
                                <p>  Do you really want to continue?</p>
                            </div>
                        )}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isMCAMethod: false }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.props.action.ui.updateMCAMethod({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: { value: this.state.mcaMethodValue }
                            });
                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isMCAMethod: false }) }}
                    />
                    <ModalConfirmation
                        isOpen={this.state.isEditSuitesEnabled}
                        formattedMessage={(
                            <div>
                                {<p>Disabling Provider Knowledge Packs will remove any assignments for this client.</p>}
                            </div>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isEditSuitesEnabled: false }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.props.action.ui.updateEditSuitesEnabled({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: { value: "0" }
                            });
                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isEditSuitesEnabled: false }) }}
                    />

                </DialogWrapper>
            </React.Fragment >
        );
    }
};

var connectedHoc = connect<IConfigureSubmitterState, IConfigureSubmitterActionProps, IOwnProps, IConfigureSubmitterProps, ApplicationState>(
    createCrudMapStateToProps('configureSubmitter'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ConfigureSubmitter);

export default withRouter(connectedHoc);
