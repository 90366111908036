import * as React from 'react';
import { Colors } from '../../../commonResources/colorVariables';
import styled from 'styled-components';

const ARMCopyrightStyle = styled.div`
    background-color: ${Colors.darkestSecondaryGrey};
    padding: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;
    text-align: right;
    white-space: pre-line;
    font-size: 9px;
    color: ${Colors.white};
    width:100%;
    font-family: 'Optum Sans C','Century Gothic',AppleGothic,sans-serif;
`;

interface IComponentProps {
};

export class ARMCopyright extends React.PureComponent<IComponentProps> {
    constructor(props: IComponentProps) {
        super(props);
    }

    public render() {

        return (
            <ARMCopyrightStyle>
                &copy; 2024 Optum, Inc. or its affiliates. All rights reserved.
            </ARMCopyrightStyle>
        );
    }
};
