import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll } from 'lodash';
import { Button } from 'ui-core';
import styled from 'styled-components';

import { DialogFieldset, DialogLegend, HelpIcon } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { Labels as LabelStyles } from '@common/component-styles';
import { Input } from '@common/UICLWrappers/ARMInput';
import { handleChange, pageLeave } from '@commonResources/userModified';
import { OKCancelButtons, DialogWrapper, ContentColumnWrapper, ContentRowWrapper } from '@common/DialogWrapper';
import { ModalConfirmation } from '@common/ModalConfirmation';
import AssuranceMenu from '../../common/AssuranceMenu';
import { ArrowButtons } from '@common/ArrowButtons';
import CrudTypes from '../../../commonResources/CrudTypes';

import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { ApplicationState } from '@store/index';
import { IDenialManagementUIState, IDenialManagementActionProps, actionCreators, validationCallback } from '@store/DenialManagement';

const CodeGroupsDiv = styled.div`
    height: 200px;
    width:300px;

    #id-0,
    #id-1
    {
       box-shadow: 0 0 0 0 black!important;
       padding-top: 1px;
       padding-bottom: 1px;
    }
`

const RightPanelDiv = styled.div`
    margin-left: 10px;
    margin-top: 21px;
    
    .text-input input {
        text-transform: uppercase;
        width:200px;
    }

    #dmAllCodes, #dmThisGroup {
        height: 120px;
    }
`;

const ButtonsDiv = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IComponentProps {
    canView: boolean;
    canEdit: boolean;
};

interface IComponentState {
    cancelLeave: boolean;
    showAlert: boolean;
    alertMessage: string;
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    showAlert: false,
    alertMessage: '',
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IDenialManagementProps = IMergeCrudComponentProps<IDenialManagementUIState, IDenialManagementActionProps, IOwnProps>;

export class DenialManagement extends React.PureComponent<IDenialManagementProps, IComponentState> {
 
    // define only once, instead of on every render
    private instructions = <React.Fragment>Set up accountability groups and assign denial codes to them. Only denial codes assigned to a group will be included on the denial management reports.</React.Fragment>;

    constructor(props: IDenialManagementProps) {
        super(props);
        this.state = DEFAULT_STATE;
        bindAll(this, [/*methods here*/]);
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }

        pageLeave();

        this.props.action.crud.get({ crudId: CrudTypes.mctiReasonCodes }, (result: MCDMReasonCodes) => {
            if (validationCallback(result)) {
                this.props.action.ui.initalizeOptions({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: result.ReasonCodeMaintenanceInfo,
                });
                return true;
            }
        });
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    isBusy = () => {
        return (this.props.dataStore.crud.dataStatus === 'REQUEST');
    }

    private okCancelButtons = <OKCancelButtons disableOK={!this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onOk(e); }} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onCancel(e); }}/>;

    public onOk(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);
        this.props.history.push("/LandingPage");
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    private getCodeGroups() {
        var addCodeGroupItem: MCDMReasonCodesGroup[] = [{
            '@ID': '',
            '@Name': '- ADD A NEW CODE GROUP -',
            ReasonCodes: {
                ReasonCode: []
            },
        }];

        var dataGroup = this.props.dataStore.crud.data ? addCodeGroupItem.concat(this.props.dataStore.crud.data.ReasonCodeMaintenanceInfo.Groups.Group) : addCodeGroupItem;
        return dataGroup;
    }

    private IsReasonCodeInList(rsID: string, codes: MCDMGroupReasonCode[]): boolean {
        if (!codes || codes.length == 0)
            return false;

        let list = codes.filter(c => c['@ID'] == rsID);
        if (list && list.length > 0)
            return true;
        return false;
    }

    private IsReasonCodeAlreayInAGroup(rsID: string) {
        let list = this.props.dataStore.crud.data?.ReasonCodeMaintenanceInfo.Groups.Group.filter(g => this.IsReasonCodeInList(rsID, g.ReasonCodes.ReasonCode))
        if (list && list.length > 0)
            return true;
        return false;
    }

    private getAvailableCodes() {
        let availableCodes: MCDMReasonCode[] = [];

        if (this.props.dataStore.crud.data) {
            let codesToRemove: MCDMGroupReasonCode[] = this.props.dataStore.ui.thisGroupCodes;
            // Let's make sure the codes are not in any groups, nor has been added to the current group (before user clicked Add or Update)
            availableCodes = this.props.dataStore.crud.data.ReasonCodeMaintenanceInfo.ReasonCodeList.ReasonCode.filter(
                rs => !this.IsReasonCodeInList(rs['@ID'], codesToRemove) && !this.IsReasonCodeAlreayInAGroup(rs['@ID']));
        }
        return availableCodes;
    }

    private onSelectCodeGroup(e: React.ChangeEvent<HTMLSelectElement>) {
        const {
            dataStore,
            action
        } = this.props;

        const codeGroupValue = e.target.value;
        const codeGroupName = e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : "";

        action.ui.selectCodeGroup(
            {
                masterCrud: dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: codeGroupValue,
                    text: codeGroupName
                }
            });
    }

    private getSelectedCodes(e: React.ChangeEvent<HTMLSelectElement>): MCDMReasonCode[] {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected) {
                let code: MCDMReasonCode = { '@ID': e.target.options[i].value, '@Name': e.target.options[i].text };
                result.push(code);
            }
        }
        return result;
    }

    private getSelectedThisGroupCodes(e: React.ChangeEvent<HTMLSelectElement>): MCDMGroupReasonCode[] {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected) {
                let code: MCDMGroupReasonCode = { '@ID': e.target.options[i].value, '@ReasoncodeName': e.target.options[i].text };
                result.push(code);
            }
        }
        return result;
    }

    private onSelectAvailableCodes(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAvailableCodes(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selectedCodes: this.getSelectedCodes(e),
                }
            });
    }

    private onSelectThisGroupCodes(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectThisGroupCodes(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selectedCodes: this.getSelectedThisGroupCodes(e),
                }
            });
    }

    public onAddCodes(e: React.ChangeEvent<HTMLButtonElement>): void {
        this.props.action.ui.addCodes({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public onRemoveCodes(e: React.ChangeEvent<HTMLButtonElement>): void {
        this.props.action.ui.removeCodes({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public onClickAddUpdateButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.dataStore.ui.groupName == "") {  
            this.setState({ showAlert: true, alertMessage: "Please enter a code group name." });
            return;
        }

        if (this.props.dataStore.crud.data?.ReasonCodeMaintenanceInfo.Groups.Group.find(
            g => g['@Name'] == this.props.dataStore.ui.groupName && g['@ID'] != this.props.dataStore.ui.selectedGroupID) != undefined) {
            this.setState({ showAlert: true, alertMessage: "A code group by that name already exists. Please type in a different name."});
           return;
        }

        handleChange();
        this.props.action.ui.addOrUpdateGroup({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public onClickRemoveButton(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.action.confirm.openConfirm("Do you want to remove " + this.props.dataStore.ui.groupName + "? This action cannot be undone.", 
            () => this.onClickConfirmRemoveGroup());
    }
    
    public onClickConfirmRemoveGroup() {
        handleChange();
        this.props.action.ui.removeGroup({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>)
    {
        this.props.action.confirm.closeConfirm();
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    public render() {
        if (!this.props.canView) return (<div>User rights not established to view this activity.</div>);

        let codeGroups = this.getCodeGroups();
        let availableCodes = this.getAvailableCodes();

        return (
            <React.Fragment>
                <DialogWrapper title='Denial Management'
                    instruction={this.instructions}
                    helpUrl='/Support/Help/HELP_Maint_DenialMgt.htm'
                    buttons={this.okCancelButtons}
                    isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}
                >
               {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                    <ContentColumnWrapper style={{ flex: "none" }}>
                        <CodeGroupsDiv>
                            <DialogFieldset>
                            <DialogLegend>Code Groups</DialogLegend>
                                <SelectComponent
                                    width={'280px'}
                                    height={'220px'}
                                    optionFields={{
                                    value: "@ID",
                                    text: "@Name"
                                    }}
                                    selectedValue={this.props?.dataStore?.ui?.selectedGroupID !== undefined ? this.props?.dataStore?.ui?.selectedGroupID : ''}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectCodeGroup(e)}
                                    records={codeGroups}
                                />
                            </DialogFieldset>
                        </CodeGroupsDiv>
                    </ContentColumnWrapper>
                    <ContentColumnWrapper>
                        <RightPanelDiv>
                            <Input
                                id="name"
                                width="200px"
                                className="text-input"
                                label="Code Group Name:"
                                maxLength={20}
                                initialValue={this.props.dataStore.ui.groupName}
                                onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    if (e.target.value != this.props.dataStore.ui.groupName)
                                        this.props.action.ui.updateGroupName({ masterCrud: this.props.dataStore.crud.data, uiData: e.target.value.toUpperCase().trim() });
                                }}
                             />

                            <ContentRowWrapper>
                                <ContentColumnWrapper style={{ flex: "none" }}>
                                    <div style={{ width: "170px", paddingTop: "5px" }}>
                                        <LabelStyles.InputText inline marginBottom="0" >
                                            All Codes:
                                            <HelpIcon
                                                title=""
                                                helpUrl="/Support/Help/HELP_DYN.asp?1"
                                            />
                                        </LabelStyles.InputText>
                                        <SelectComponent
                                            className="dmAllCodes"
                                            width="170px"
                                            height="120px"
                                            multiple='true'
                                            optionFields={{
                                                value: "@ID",
                                                text: "@Name"
                                            }}

                                            records={availableCodes}
                                            selectedMultiValue={this.props.dataStore.ui.selectedAvailableCodes.map(c => c['@ID'])}
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAvailableCodes(e)}
                                          />

                                    </div>
                                </ContentColumnWrapper>
                                <ContentColumnWrapper style={{ flex: "none" }}>
                                    <div style={{ width: "55px" }}>

                                        <ContentRowWrapper>
                                            <ArrowButtons
                                                paddingTop='50px'
                                                width='55px' //todo - this prop doesn't seem to do anything - must adjust margin instead
                                                margin='0 15px 0 0'
                                                toolTipLeft='Add Code'
                                                toolTipRight='Remove Code'
                                                onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRemoveCodes(e)}
                                                onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onAddCodes(e)}
                                                disabled={!this.props.canView || !this.props.canEdit}
                                            />
                                        </ContentRowWrapper>
                                    </div>
                                </ContentColumnWrapper>
                                <ContentColumnWrapper style={{ flex: "none" }}>
                                    <div style={{ width: "170px", paddingTop: "8px" }}>
                                        <SelectComponent
                                            className="dmThisGroup"
                                            label="This Group:"
                                            width="170px"
                                            height="120px"
                                            optionFields={{
                                                value: "@ID",
                                                text: "@ReasoncodeName"
                                            }}
                                            selectedMultiValue={this.props.dataStore.ui.selectedThisGroupCodes.map(c => c['@ID'])}
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectThisGroupCodes(e)}
                                            records={this.props.dataStore.ui.thisGroupCodes}
                                            multiple='true'
                                        />
                                    </div>
                                </ContentColumnWrapper>
                            </ContentRowWrapper>
                            <ContentRowWrapper>
                                <ButtonsDiv>
                                    <Button
                                        domID="co-cci-gen-add-btn"
                                        name={this.props.dataStore.ui.selectedGroupID == '' ? "ADD" : "UPDATE"}
                                        size="small"
                                        buttonType="standard"
                                        type="button"
                                        disabled={!this.props.canView || !this.props.canEdit}
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickAddUpdateButton(e) }}
                                    />
                                    <Button
                                        domID="co-cci-gen-remove-btn"
                                        name='REMOVE'
                                        size="small"
                                        buttonType="standard"
                                        type="button"
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickRemoveButton(e) }}
                                        disabled={this.props.dataStore.ui.selectedGroupID == '' || !this.props.canView || !this.props.canEdit}
                                    />
                                </ButtonsDiv>
                            </ContentRowWrapper>

                        </RightPanelDiv>
`                   </ContentColumnWrapper>
                `   <ModalConfirmation
                        isOpen={this.props.dataStore.confirm.isOpen}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                        message={this.props.dataStore.confirm.message}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}                       
                    />
                    <ModalConfirmation
                        alertMode={true}
                        isOpen={this.state.showAlert}
                        message={this.state.alertMessage}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ alertMessage: '', showAlert: false })}
                    />
                </DialogWrapper>
                </React.Fragment>);
    }
}

const connectedHoc = connect<IDenialManagementUIState,
    IDenialManagementActionProps,
    IOwnProps,
    IDenialManagementProps,
    ApplicationState>(
        createCrudMapStateToProps('denialManagement'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(DenialManagement);

export default withRouter(connectedHoc);
