import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ClaimTotalsUI from './ui/ClaimTotalsUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IClaimTotalsState = ICrudComponentState<MCClaimNoteType, ClaimTotalsUI.IClaimTotalsUIState>;
export const actionCreators = createCrudComponentActions<ClaimTotalsUI.ActionCreators>(ClaimTotalsUI.actionCreators);
export type IClaimTotalsActions = typeof actionCreators;
export type IClaimTotalsActionProps = ICrudComponentActionProps<IClaimTotalsActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimNotes, ClaimTotalsUI.reducer);
export const reducer = combineReducers<IClaimTotalsState>(reducers);

export interface IClaimTotalsResponse {
    status: number,
    data?: string,
}