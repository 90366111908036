import { ActionTypes, createDataAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { Reducer } from 'redux';

// -----------------
// STATE - This defines the type of data maintained in the Redux store. AutoSubmitMaintenanceUI
export interface IAutoSubmitMaintenanceUIState {
    AutoSubmitMaintenanceInfo: any;
    selectedItem: any;
}

export const defaultState: IAutoSubmitMaintenanceUIState = {
    AutoSubmitMaintenanceInfo: {},
    selectedItem: ''
}

export const defaultAutoSubmitMaintenance: IAutoSubmitMaintenanceUIData = {
    id: '',
    text: '',
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface IAutoSubmitMaintenanceUIData {
    id: string;
    text: string;
};


export interface ISelectAutoSubmitMaintenance extends ICrudActionData<AutoSubmitMaintenanceType, IAutoSubmitMaintenanceUIData> { }

export const actionCreators = {
    selectAEPhysicianMaintenance: (selectInfo: ISelectAutoSubmitMaintenance) => createDataAction('SELECT_AUTOSUBMIT', selectInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.

export const reducer: Reducer<IAutoSubmitMaintenanceUIState, KnownActions> = (state: IAutoSubmitMaintenanceUIState | undefined, action: KnownActions) => {

    return state || defaultState;
}