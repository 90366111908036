
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ActionCreators, actionCreators /*, validationCallback */, IDashboardEligibilityState } from '@store/OperationalDashboard/DashboardEligibility';
import { OperationFilter } from '@store/OperationalDashboard/DrillDownView';
import { ISelectionView, ISelectionMeasure, ISelectionDateRange } from "@store/OperationalDashboard/DashboardDefault"

import { CommonDataSelectionComponent } from './DataSelection/CommonDataSelectionComponent';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CommonGraph } from '@commonResources/commonGraphing/CommonGraph';
import ReadOnlyList from '@commonResources/ReadOnlyList';
import { createMapDispatchToProps, IMergeComponentProps, mergeComponentProps, IActionCreatorMap, mapStatesToProps, mapStateToProps, IApplicationStateMap } from '@scripts/util/ReduxHelpers';
import ClaimsMeter from './MeterRowComponent';

const graphWidth = 480;

interface IComponentProps {
    canView: boolean;
    canEdit: boolean;
    pageWidth: number;
    pageHeight?: number | string;
};

const Page = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
`

const AllGraphsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    column-gap: 30px;
    row-gap: 30px;
    align-items: start;
    
`
const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column; 

table#tableInitialResponseARMGrid thead tr th:first-child {
    min-width: 238px !important;
    padding-left: 30px !important;
    min-height: 400px !important;
}
#tableInitialResponseARMGrid tbody tr td {
    padding: 8px !important;
}

table#tableInitialResponseARMGrid tbody tr td:first-child:not(.selection-column){
    padding-left:30px !important;
}

table#tableErrorCategoryARMGrid thead tr th:first-child {
    min-width: 238px !important;
    padding-left: 30px !important;
}
#tableErrorCategoryARMGrid tbody tr td {
    padding: 8px !important;
    
}

table#tableErrorCategoryARMGrid tbody tr td:first-child:not(.selection-column){
    padding-left:20px !important;
}

#tableErrorCategoryARMGrid tbody tr td:not(:first-child){
    text-align: center;

}

#tableErrorCategoryARMGrid tbody tr td:nth-child(3){
    text-align: end;
    
}

table#tableInitialResponse thead tr th:first-child {
    min-width: 238px !important;
    padding-left: 30px !important;
    min-height: 400px !important;
}
#tableInitialResponse tbody tr td {
    padding: 8px !important;
}

table#tableInitialResponse tbody tr td:first-child:not(.selection-column){
    padding-left:30px !important;
}

table#tableErrorCategory thead tr th:first-child {
    min-width: 238px !important;
    padding-left: 30px !important;
}
#tableErrorCategory tbody tr td {
    padding: 8px !important;
    
}

table#tableErrorCategory tbody tr td:first-child:not(.selection-column){
    padding-left:20px !important;
}

table tbody tr td:not(:first-child){
    text-align: center;

}

table tbody tr td:nth-child(3){
    text-align: end;
    
}

td:empty{
    display: none;
}

th:empty{
    display: none;
}

[cursor="pointer"] > div{
    width: 100% !important;
}

table thead tr th:not(:first-child) > span{
    text-align: center;
}

table thead tr th:nth-child(3) > span{
    text-align: end;
    padding-right: 0px;
}

`

const GraphContainer = styled.div`
    width: ${graphWidth}px;
    height: 100%;
    background-color: none;
    display: flex;
    justify-content: center;
`;

const HeaderTitle = styled.div`
    width: ${graphWidth}px;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: ${Colors.blue70};
    color: ${Colors.white};
    ${Typography.extraLarge};
    ${Typography.ARMFontFamily};
    font-weight: 600;
    padding: 5px 10px;
`;

const Value = styled("div")`
    color: white;
`;


interface IComponentState {
};


export const DEFAULT_STATE: IComponentState = {
}



type IOwnProps = IComponentProps & RouteComponentProps<{}>;


type IEligibilityProps = IMergeComponentProps<IApplicationStateMap<"dashboardEligibility" | "drillDownView">, IActionCreatorMap<"dashboardEligibility" | "drillDownView">, IOwnProps>;


export class DashboardEligibility extends React.Component<IEligibilityProps, IComponentState> {


    constructor(props: any) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        const { getEligibilityData } = this.props.action?.dashboardEligibility
        const { selectedMeasure, selectedDateRange, selectedStartDate, selectedEndDate, selectedView } = this.props.dataStore?.dashboardEligibility
        let data: OperationFilter = {
            tab: "1",
            view: selectedView && selectedView.value,
            measure: selectedMeasure && selectedMeasure.value,
            dateRange: selectedDateRange && selectedDateRange.value,
            drillPage: "0",
            fromDate: selectedDateRange && selectedDateRange.value === "7" ? selectedStartDate && selectedStartDate : "",
            thruDate: selectedDateRange && selectedDateRange.value === "7" ? selectedEndDate && selectedEndDate : "",
            stampDateTime: "",
            useTestData: ""
        }
        getEligibilityData && getEligibilityData(data);
    }

    public meterHeaderData() {
        let meterData: string[] = [
            "Unreleased Pending Eligibility Count",
            "Unreleased Pending Eligibility Amount",
            "Released Pending Eligibility Count",
            "Released Pending Eligibility Amount"
        ]
        return meterData;
    }

    public getPieData(item: any) {

        // Get labels
        let labels = item?.data?.map((el: any) => {
            if (el.dataPoints[0]) {
                return { x: el.dataPoints[0].label }
            }
        })

        // Extract value for each corresponding label
        let values = labels?.map((el: any, index: number) => {
            if (item.data[index].dataPoints) {
                let filtered = item.data[index].dataPoints.filter((point: any) => point.dataPointType === "Percent");
                if (filtered.length) {
                    el.y = filtered[0].value;
                    el.x = filtered[0].value + "%";
                    return el;
                }
            }
        })
        return (values === undefined || values[0] === undefined) ? [] : values;
    }

    public getPieLegendData(item: any) {

        // Get labels
        let labels = item?.data?.map((el: any) => {
            if (el.dataPoints[0]) {
                return { x: el.dataPoints[0].label }
            }
        })

        // Extract legend for each corresponding label
        let values = labels?.map((el: any, index: number) => {
            if (item.data[index].dataPoints) {
                let filtered = item.data[index].dataPoints.filter((point: any) => point.dataPointType === "Percent");
                if (filtered.length) {
                    el.name = filtered[0].label;
                    el.symbol = {
                        fill: this.props.dataStore.dashboardEligibility.selectedMeasure.value === "2" ? "blue" : "green"
                    };
                    return el;
                }
            }
        })

        return (values === undefined || values[0] === undefined) ? [] : values;
    }

    public getColumnNames(item: any) {
        if (item !== "") {
            // Get the column values
            let values = item?.listHeader.columns.map((col: any, index: Number) => {
                col.dataName = "@data" + index;
                col.text = col.text;

                return col;

            })
            return values[0] === undefined ? [] : values;
        }
        else
            return [];
    }

    public getRowValuesErrorCategory(item: any) {
        if (item !== "") {
            // Get the column values
            let values = item?.data.map((row: any, index: Number) => {
                let obj: any = "@data" + index;
                obj = {
                    text: row.text
                }
                return obj;

            })
            // Note: if there is no bar data it will return an array of undefined so don't emit data
            //return mockBarData;
            //console.log("The return value for Column Names is: ", (values[0] === undefined) ? [] : values);
            return values[0] === undefined ? [] : values;
        }
        else
            return [];
    }

    public getRowValues(item: any) {

        if (item !== "") {
            // Get the column values
            let objResult: any = [];
            let values = item?.data.map((row: any) => {

                let result: any = {};
                row.columnData.map((col: any, index: Number) => {
                    let objName = "@data" + index;
                    let objInsideValue = {
                        [objName]: {
                            text: col.text
                        }
                    }
                    result = Object.assign(result, objInsideValue);
                })

                return objResult.push(result);
            })
            let sortedResults = objResult.sort((a: any, b: any) => (b["@data1"]["text"]).toString() - ((a["@data1"]["text"]).toString()));
            return sortedResults;
        }
        else
            return [];
    }

    public getRootTooltip(item: any) {
        return item.toolTip;
    }

    public onSelectViewHandler = (e: ISelectionView) => {
        const { selectView, getEligibilityData } = this.props.action?.dashboardEligibility
        const { selectedMeasure, selectedDateRange, selectedStartDate, selectedEndDate } = this.props.dataStore?.dashboardEligibility
        selectView && selectView(e);
        setTimeout(() => {

            let data: OperationFilter = {
                tab: "1",
                view: e.value,
                measure: selectedMeasure && selectedMeasure.value,
                dateRange: selectedDateRange && selectedDateRange.value,
                drillPage: "0",
                fromDate: selectedDateRange && selectedDateRange.value === "7" ? selectedStartDate && selectedStartDate : "",
                thruDate: selectedDateRange && selectedDateRange.value === "7" ? selectedEndDate && selectedEndDate : "",
                stampDateTime: "",
                useTestData: ""
            }
            let data1: OperationFilter = {
                tab: "1",
                view: "0",
                measure: "0",
                dateRange: "0",
                drillPage: "0",
                fromDate: "",
                thruDate: "",
                stampDateTime: "",
                useTestData: ""
            }
            getEligibilityData && getEligibilityData(data1);
            getEligibilityData && getEligibilityData(data);
        }, 1000);

    }

    public onSelectMeasureHandler = (e: ISelectionMeasure) => {

        const { selectMeasure, getEligibilityData } = this.props.action?.dashboardEligibility
        const { selectedView, selectedDateRange, selectedStartDate, selectedEndDate } = this.props.dataStore?.dashboardEligibility
        selectMeasure && selectMeasure(e);
        setTimeout(() => {
            let data: OperationFilter = {
                tab: "1",
                view: selectedView && selectedView.value,
                measure: e.value,
                dateRange: selectedDateRange && selectedDateRange.value,
                drillPage: "0",
                fromDate: selectedDateRange && selectedDateRange.value === "7" ? selectedStartDate && selectedStartDate : "",
                thruDate: selectedDateRange && selectedDateRange.value === "7" ? selectedEndDate && selectedEndDate : "",
                stampDateTime: "",
                useTestData: ""
            }
            let data1: OperationFilter = {
                tab: "1",
                view: "0",
                measure: "0",
                dateRange: "0",
                drillPage: "0",
                fromDate: "",
                thruDate: "",
                stampDateTime: "",
                useTestData: ""
            }
            getEligibilityData && getEligibilityData(data1);
            getEligibilityData && getEligibilityData(data);
        }, 500);
    }

    public onSelectDateRangeHandler = (e: ISelectionDateRange, startDate: string, endDate: string) => {
        const { selectDateRange, getEligibilityData } = this.props.action?.dashboardEligibility
        const { selectedView, selectedMeasure } = this.props.dataStore?.dashboardEligibility
        selectDateRange && selectDateRange(e, startDate, endDate);
        setTimeout(() => {
        let data: OperationFilter = {
            tab: "1",
            view: selectedView && selectedView.value,
            measure: selectedMeasure && selectedMeasure.value,
            dateRange: e.value,
            drillPage: "0",
            fromDate: startDate,
            thruDate: endDate,
            stampDateTime: "",
            useTestData: ""
        }
            let data1: OperationFilter = {
                tab: "1",
                view: "0",
                measure: "0",
                dateRange: "0",
                drillPage: "0",
                fromDate: "",
                thruDate: "",
                stampDateTime: "",
                useTestData: ""
            }
            getEligibilityData && getEligibilityData(data1);
        getEligibilityData && getEligibilityData(data);
        }, 500);
    }

    public isChartClickable(item: any) {

        //console.log("Is chart clickable? ", !!item.filter);

        return !!item.filter;
    }

    public getRootActionPayload(item: any) {
        return item.filter;
    }

    navToDrillDown = (rootActionPayload: any) => {
        const {
            drillDownTo,
        } = this.props.action.drillDownView;
        const filter = this.getRootActionPayload(rootActionPayload);
        drillDownTo(filter, { persistanceFilter: filter });
    }

    public render() {
        const {
            eligibilityData,
            selectedView,
            selectedMeasure,
            selectedDateRange,
            selectedStartDate,
            selectedEndDate,
            uiLock,
        } = this.props.dataStore?.dashboardEligibility



        let graphs: any = undefined;

        let charts: any = undefined;
        let listforErrorCategory: any = undefined;
        let listforInitEligResponses: any = undefined;

        if (eligibilityData) {
            graphs = eligibilityData.items;
            // Filter out the graphs
            charts = graphs.filter((graph: any) => graph.outputType === 'PieChart');

            // Filter returns an array
            listforErrorCategory = graphs.filter((graph: any) => graph.outputType === 'List' && graph.filter.drillPage === "EligErrorCategory");
            listforInitEligResponses = graphs.filter((graph: any) => graph.outputType === 'List' && graph.filter.drillPage === "InitEligResponses");

        };


        return (
            <>

                {eligibilityData && <ClaimsMeter meterData={eligibilityData.meters} />}
    
                <CommonDataSelectionComponent
                    selectedView={selectedView}
                    selectedMeasure={selectedMeasure}
                    selectedDateRange={selectedDateRange}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    onSelectView={(e: ISelectionView) => this.onSelectViewHandler(e)}
                    onSelectMeasure={(e: ISelectionMeasure) => this.onSelectMeasureHandler(e)}
                    onSelectDateRange={(e: ISelectionDateRange, startDate: string, endDate: string) => this.onSelectDateRangeHandler(e, startDate, endDate)}
                    enabled={!!uiLock}
                />
                <Page>
                    <AllGraphsContainer>
                        <GraphWrapper key={'errorCategory'}>
                            <ReadOnlyList
                                domID="tableErrorCategory"
                                title={listforErrorCategory !== undefined && (<div style={{ display: "flex", flexDirection: "column" }}>
                                        <HeaderTitle>{listforErrorCategory[0].headerTitle}</HeaderTitle>
                                        <div style={{ marginTop: "15px", height: "14px", textAlign: "center" }}>
                                            {listforErrorCategory[0].title}
                                        </div>
                                    </div>)}
                                onClick={listforErrorCategory !== undefined && listforErrorCategory.length > 0 && this.isChartClickable(listforErrorCategory[0]) ?
                                    ()=>this.navToDrillDown(listforErrorCategory[0]) :
                                    () => { }}
                                columns={this.getColumnNames(listforErrorCategory !== undefined ? listforErrorCategory[0] : "")}
                                records={this.getRowValues(listforErrorCategory !== undefined ? listforErrorCategory[0] : "")}
                                tooltip={listforErrorCategory !== undefined ? listforErrorCategory[0].headerTitle : ""}
                            />
                        </GraphWrapper>
                    </AllGraphsContainer>
                    <AllGraphsContainer>
                        {
                            selectedView && selectedView.value === "0" && charts && charts.map((chart: any, index: number) =>
                                <GraphWrapper key={`${index}-graph`}>
                                    <HeaderTitle>{chart.headerTitle}</HeaderTitle>
                                    <GraphContainer>
                                        <CommonGraph
                                            title={chart.title}
                                            graphType={'pie'}
                                            data={this.getPieData(chart)}
                                            actionPayload={this.getRootActionPayload(chart)}
                                            legendData={this.getPieLegendData(chart)}
                                            colorScheme={this.props.dataStore?.dashboardEligibility.selectedMeasure.value === "2" ? "blue" : "green"}
                                            graphClickHandler={this.isChartClickable(chart) ? ()=>this.navToDrillDown(chart) : () => { }}
                                            barClickHandler={this.isChartClickable(chart) ? ()=>this.navToDrillDown(chart) : () => { }}
                                            tooltipData={{
                                                tooltipWidth: 200,
                                                tooltipOffsetX: 20,
                                                tooltipOffsetY: 30,
                                                getContent: (tooltipData: any) => {
                                                    return (
                                                        <>
                                                            <Value>{this.getRootTooltip(chart)}</Value>
                                                        </>
                                                    )
                                                }
                                            }}
                                        />
                                    </GraphContainer>
                                </GraphWrapper>
                            )
                        }

                    </AllGraphsContainer>
                    <AllGraphsContainer>
                        {

                            selectedView && selectedView.value === "1" && listforInitEligResponses !== undefined && listforInitEligResponses.length > 0 &&
                            <GraphWrapper key={'initEligResponses'}>
                                <ReadOnlyList
                                    domID="tableInitialResponse"
                                    title={listforInitEligResponses !== undefined ?
                                        (<div style={{ display: "flex", flexDirection: "column" }}>
                                            <HeaderTitle>{listforInitEligResponses[0].headerTitle}</HeaderTitle>
                                            <div style={{ marginTop: "15px", height: "14px", textAlign: "center" }}>
                                                {listforInitEligResponses[0].title}
                                            </div>
                                        </div>) : <></>}
                                    onClick={this.isChartClickable(listforInitEligResponses[0]) ?
                                        ()=>this.navToDrillDown(listforInitEligResponses[0]) :
                                        () => { }}
                                    columns={this.getColumnNames(listforInitEligResponses !== undefined ? listforInitEligResponses[0] : "")}
                                    records={this.getRowValues(listforInitEligResponses !== undefined ? listforInitEligResponses[0] : "")}
                                    tooltip={listforInitEligResponses !== undefined ? listforInitEligResponses[0].headerTitle : ""}
                                />
                            </GraphWrapper>
                        }
                    </AllGraphsContainer>
                </Page>

            </>
        );
    }
};

var connectedHoc = connect(
    mapStatesToProps([
        "drillDownView",
        "dashboardEligibility",
        "dashboardDefault"
    ]), // Selects which state properties are merged into the component's props
    createMapDispatchToProps([
        "drillDownView",
        "dashboardEligibility",
        "dashboardDefault"
    ]), // Selects which action creators are merged into the component's props
    mergeComponentProps
)(DashboardEligibility);

export default withRouter(connectedHoc);

