import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as DashboardContactsUI from './ui/DashboardContactsUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IDashboardContactsState = ICrudComponentState<MCDashboardMaintenance, DashboardContactsUI.IDashboardContactsUiState>;

export const actionCreators = createCrudComponentActions<DashboardContactsUI.ActionCreators>(DashboardContactsUI.actionCreators);
export type IDashboardContactsActions = typeof actionCreators;
export type IDashboardContactsActionProps = ICrudComponentActionProps<IDashboardContactsActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiDashboard, DashboardContactsUI.reducer);
export const reducer = combineReducers<IDashboardContactsState>(reducers);

export const validationCallback: ValidationCallback<MCDashboardMaintenance> = (crud: any): crud is MCDashboardMaintenance => {
    let testCrud: MCDashboardMaintenance = crud as MCDashboardMaintenance;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCDashboardMaintenance");
    if (validShape) {
        
        if (testCrud.DashboardMaintenanceInfo.ContactList as any === "") {
            testCrud.DashboardMaintenanceInfo.ContactList = { ContactEmail: new Array<MCDashboardContact>() };
        }
       
        if (testCrud.DashboardMaintenanceInfo.ContactList &&
            !Array.isArray(testCrud.DashboardMaintenanceInfo.ContactList.ContactEmail)) {
            let arr = new Array<MCDashboardContact>();
            arr.push(JSON.parse(JSON.stringify(testCrud.DashboardMaintenanceInfo.ContactList.ContactEmail)));
            testCrud.DashboardMaintenanceInfo.ContactList.ContactEmail = arr;
        }
    }
    /*
    // ts-transformer-keys is one approach for validation. It is not strictly required, and in some cases be undesirable. Once instance where it would be undesireable is when
    // working with optional data. The keys<>() function includes optional fields in the array it returns, but doesn't indicate if the field is optional or not, yet it is acceptable
    // for raw data to exclude such field information. In these cases, an approach without ts-transformer-keys might be desired (or perhaps ts-transformer-keys-2 would work, or
    // our own custom transformer)
    // Note that keys<>() could still be used to return an array of fields, and optional fields removed here.
    // Alternatively, keys<>() could be left unused and an array of fields provided to ValidateObject, as well.
    // Approach without ts-transformer-keys
    // Other options include:
    //   - using converting TS to JSON Schema and using JSON Schema
    //   - using ts-io, ajv, or runtypes; however, allof these replace the interface declaraction in TypeScript with their own,
    //     leaving the interface to be derived from their implementation, vs deriving what is needed at runtime from TypeScript.
    //   - using ts-transformer-keys-2, which includes support for optional parameters and types. however, this is simply a github project and does not appear to be maintained.
    //   - using typescript-runtime-types, but does not seem very active.
    let testCrud: MCDashboardMaintenance = crud as MCDashboardMaintenance;
    let validShape: boolean =
        (testCrud?.DashboardMaintenanceInfo !== undefined)
    &&  (testCrud?.DashboardMaintenanceInfo?.ContactList !== undefined);
    if (validShape) {
        VerifyArray(testCrud?.DashboardMaintenanceInfo?.ContactList, "DashboardContact");
    }
    */
    return validShape;
}
