import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../../commonResources/typography';
import { Colors } from '../../commonResources/colorVariables';
//import { BoxShadows } from '../../commonResources/boxShadows';
import { Borders } from '../../commonResources/borders';
import { Button, LoadingIndicator } from 'ui-core';
import { IImageButtonImage, ImageButton } from '../common/ImageButton';
import { ModalLoading } from '@common/ModalLoading'
import * as wind from '../../commonResources/window';

import ARMHelpIconOff from '../../content/images/Buttons/But_HelpOff.png';
import ARMHelpIconUp from '../../content/images/Buttons/But_HelpUp.png';
import ARMHelpIconOver from '../../content/images/Buttons/But_HelpOver.png';
import ARMHelpIconDown from '../../content/images/Buttons/But_HelpDown.png';


interface IDialogContainerProps {
    domId?: string;
    widthValue?: string;
    marginTop?: string;
}

export const CenteredContent = styled.div`
    display: block;
    text-align: center;
`;

//${BoxShadows.lightFade};

export const DialogContainer = styled.div.attrs((props: IDialogContainerProps) => {
    var idObj = (props.domId) ?
        {
            id: props.domId
        } : {};
    return { ...idObj };
})`
    position: relative;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: stretch;
    text-align: left;
    margin-top: ${props => !!props.marginTop ? props.marginTop : "40px"}

    ${(props: IDialogContainerProps) => props.widthValue && `width: ${props.widthValue}`};
`;

export const DialogCaption = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: ${Colors.darkestSecondaryGrey};
`;

export const DialogCaptionText = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    ${Typography.default};
    ${Typography.defaultLineHeight};
    color: ${Colors.white};
    padding: 10px;
    text-align: left;
    letter-spacing: 1px;
`;

export const DialogCaptionHelp = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    ${Typography.large};
    ${Typography.defaultLineHeight};
    color: ${Colors.white};
    padding: 0px;
    text-align: right;

    .help-button {
        padding: 0px;
        padding-top: 10px;
        padding-right: 10px;
    }
`;

interface IDialogBodyProps {
    paddingTop?: string | undefined
}

export const DialogBody = styled.div<IDialogBodyProps>`
    ${Typography.ARMFontFamily};
    padding: 5px;
    background-color: ${Colors.grey10};
    display: flex;
    justify-content: center;
    ${(p) => p.paddingTop ? `padding-top: ${p.paddingTop}` : 'padding-top: 5px'};
    ${Borders.darkestSecondaryGrey};
`;


export const DialogFooter = styled.div`
    ${Typography.ARMFontFamily};
    background-color: ${Colors.darkestSecondaryGrey};
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 1px;
    height: 100%;
    width: 100%;

    button {
        margin: 4px;
        width: 110px;
        text-align: center;
    }
    
`;

export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const ContentColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

interface FlexBoxModifierProps {
    flexGap: string;
    alignItems: string;
    justifyContent: string;
    flex: string;
};

//intended to be used with a flex-box styled component in the "as" attribute
export const FlexBoxModifier = styled.div<FlexBoxModifierProps>`
    gap: ${(p) => p.flexGap};
    align-items: ${(p) => p.alignItems};
    justify-content: ${(p) => p.justifyContent};
    flex?: ${p => p.flex}
`;

interface IDialogInstructionProps {
    instructionWidth?: string;
};

export const DialogInstruction = styled.div<IDialogInstructionProps>`
    display: inline-block;
    ${Typography.ARMFontFamily};
    ${Typography.mediumLarge};
    color: ${Colors.black};
    background-color: ${Colors.grey15};
    ${Borders.grey50}
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 2px;
    text-align: left;
    width:  ${(p) => p.instructionWidth ? `${p.instructionWidth}` : '80%'};


    .instructionRed{
        color: ${ Colors.darkRed};
        ${ Typography.ARMFontFamily};
        ${ Typography.bold };
    }
`;

export const HighlightedColumnWrapper = styled(ContentColumnWrapper)`
    background-color: ${Colors.grey15};
    border: 1px solid ${Colors.defaultDark};
    row-gap: 10px;
    padding: 10px;
`;

//TODO: why doesn't ${Typography.mediumLarge} work?
export const SmallerTextColumn = styled(HighlightedColumnWrapper)`${Typography.mediumLarge};`;

export const ContentRowFloatLeft = styled(ContentRowWrapper)`
    justify-content: flex-start;
    column-gap: 20px;
`;

export const ContentRowFloatRight = styled(ContentRowWrapper)`
    justify-content: flex-end;
    column-gap: 20px;
`;

export const ContentRowJustify = styled(ContentRowWrapper)`
    justify-content:space-between
    column-gap: 20px;
`;


interface IDialogWrapperPropsOnly {
    title: string;
    instruction?: string | JSX.Element;
    buttons?: JSX.Element;
    helpUrl?: string;
    helpCallback?(): void;
    width?: string;
    isBusy?: boolean;
    domId?: string;
    dropdown: JSX.Element | undefined;
    instructionWidth: string | undefined;
    paddingTop: string | undefined;
    marginTop?: string | undefined;
};

type IDialogWrapperProps = React.PropsWithChildren<IDialogWrapperPropsOnly>;

interface IDialogWrapperState {
};

export const DEFAULT_STATE: IDialogWrapperState = {
};

export interface IDialogButtonProps {
    domID: string;
    name: string;
    onClick: React.ChangeEventHandler<HTMLButtonElement>;
    disable: boolean;
};

export const DialogButton: React.FunctionComponent<IDialogButtonProps> = ({ domID, name, disable, onClick/* will this work?, ...restProps*/ }) => {
    return (
        <Button
            domID={domID}
            name={name}
            disabled={disable}
            buttonType="deEmphasizedReversed"
            size="small"
            type="button"
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => onClick(e)} />
    );
};

export const DialogButtonOK: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "okButton", name = "OK", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonCancel: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "cancelButton", name = "Cancel", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonDELETE: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "deleteButton", name = "DELETE", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonLoad: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "loadButton", name = "Load", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonSave: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "saveButton", name = "Save", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonRefine: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "refineButton", name = "Refine", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonSubmit: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "submitButton", name = "Continue", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const DialogButtonOKSubmit: React.FunctionComponent<Partial<IDialogButtonProps>> = ({ domID = "okSubmitButton", name = "Submit", disable = false, onClick }) => {
    return (
        <DialogButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};


export interface IDefaultButtonProps {
    onClickOK?: React.ChangeEventHandler<HTMLButtonElement>;
    onClickCancel: React.ChangeEventHandler<HTMLButtonElement>;
    onClickLoad?: React.ChangeEventHandler<HTMLButtonElement>;
    onClickSave?: React.ChangeEventHandler<HTMLButtonElement>;
    onClickSubmit?: React.ChangeEventHandler<HTMLButtonElement>;
    disableOK?: boolean;
    disableCancel?: boolean;
    disableLoad?: boolean;
    disableSave?: boolean;
    disableSubmit?: boolean;
    nameOverride?: string;
};

export const OKCancelButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
  return (
        <React.Fragment>
          <DialogButtonOK onClick={props.onClickOK} name={props.nameOverride || "Ok" } disable={props.disableOK || false}/>
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false}/>
        </React.Fragment>
    );
}

export const SubmitCancelButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <DialogButtonOKSubmit onClick={props.onClickSubmit} disable={props.disableSubmit || false} />
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false} />
        </React.Fragment>
    );
}

export const DeleteCancelButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <DialogButtonDELETE onClick={props.onClickOK} disable={props.disableOK || false} />
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false} />
        </React.Fragment>
    );
}

export const FilterFourButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <DialogButtonLoad onClick={props.onClickLoad ? props.onClickLoad : () => {}} disable={props.disableLoad || false} />
            <DialogButtonSave onClick={props.onClickSave ? props.onClickSave : () => { }} disable={props.disableSave || false} />
            <DialogButtonRefine onClick={props.onClickOK} disable={props.disableOK || false} />
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false} />
        </React.Fragment>
    );
}

export const FilterFiveButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <DialogButtonLoad onClick={props.onClickLoad ? props.onClickLoad : () => { }} disable={props.disableLoad || false} />
            <DialogButtonSave onClick={props.onClickSave ? props.onClickSave : () => { }} disable={props.disableSave || false} />
            <DialogButtonRefine onClick={props.onClickOK ? props.onClickOK : () => { }} disable={props.disableOK || false} />
            <DialogButtonSubmit onClick={props.onClickSubmit} name={props.nameOverride ? props.nameOverride : undefined} disable={props.disableSubmit || false} />
            <DialogButtonCancel onClick={props.onClickCancel} disable={props.disableCancel || false} />
        </React.Fragment>
    );
}

export class DialogWrapper extends React.Component<IDialogWrapperProps, IDialogWrapperState> {
    static defaultProps: IDialogWrapperProps = {
        title: '',
        dropdown: undefined,
        instructionWidth: undefined,
        paddingTop: undefined,
        marginTop: undefined,
    };

    static helpIcon: IImageButtonImage = {
        Off: ARMHelpIconOff,
        Up: ARMHelpIconUp,
        Over: ARMHelpIconOver,
        Down: ARMHelpIconDown,
    }

    constructor(props: IDialogWrapperProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public getInstruction()
    {
        return this.props.instruction ? <DialogInstruction instructionWidth={this.props.instructionWidth}>{this.props.instruction}</DialogInstruction> : null;
    }

    public getHelpIcon()
    {
        if (this.props.helpUrl || this.props.helpCallback)
        {
            return (
                <DialogCaptionHelp>
                    <ImageButton
                        Title={this.props.title + 'HelpIcon'}
                        image={DialogWrapper.helpIcon}
                        className="help-button"
                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickHelpIcon(e)} />
                 </DialogCaptionHelp>
            );
        }
        return null;
    }

    public onClickHelpIcon(e: React.ChangeEvent<HTMLButtonElement>)
    {
        e.preventDefault();
        if (this.props.helpUrl) wind.BigHelp(this.props.helpUrl);
        if (this.props.helpCallback) this.props.helpCallback();
    }

    public render() {
        const {
            dropdown
        } = this.props;

        var instruction = this.getInstruction();
        var helpIcon = this.getHelpIcon();
        let domIdName = this.props.domId ? this.props.domId : 'dialog-container';
        var domId =
        {
                domId: domIdName
        }
        return (
            <CenteredContent>
                <DialogContainer {...domId} widthValue={this.props.width} marginTop={this.props.marginTop} >
                    <DialogCaption>
                        <DialogCaptionText>
                            {this.props.title}
                            {dropdown}
                        </DialogCaptionText>
                        {helpIcon}
                    </DialogCaption>
                    <DialogBody paddingTop={this.props.paddingTop}>
                        {this.props.children}
                    </DialogBody>
                    <DialogFooter>
                        {this.props.buttons}
                    </DialogFooter>
                    <ModalLoading isOpen={this.props.isBusy} />
                </DialogContainer>
                <ContentColumnWrapper/>
                {instruction}
                
            </CenteredContent>
        );
    }
};
