import { combineReducers, Reducer } from 'redux';
import {
    ICrudComponentState,
    ICrudComponentActionProps,
    createCrudComponentActions,
    createCrudComponentReducers
} from '@scripts/util/CrudComponentHelpers';
import * as FacilityMasterUI from './ui/FacilityMasterUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';
import { Facility, MCFacilityMasterType } from '@store/api/FacilityMasterType';

export type IFacilityMasterState = ICrudComponentState<MCFacilityMasterType, FacilityMasterUI.IFacilityMasterUIState>;

export const actionCreators = createCrudComponentActions<FacilityMasterUI.ActionCreators>(FacilityMasterUI.actionCreators);
export type IFacilityMasterActions = typeof actionCreators;
export type IFacilityMasterActionProps = ICrudComponentActionProps<IFacilityMasterActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiFacility, FacilityMasterUI.reducer);
export const reducer = combineReducers<IFacilityMasterState>(reducers);

export const validationCallback: ValidationCallback<MCFacilityMasterType> = (crud: any): crud is MCFacilityMasterType => {
    ensureEntityInitialization(crud);
    // return validateEntityInitialization(crud);
    return crud;
}

function ensureEntityInitialization(crud: MCFacilityMasterType) {
    ensureEntityFacilitiesArray(crud);
    ensureEntityStateListArray(crud);
    // ensureLOBArray(crud);
}

function ensureEntityFacilitiesArray(crud: MCFacilityMasterType) {

    const info = crud.FacilityMaintenanceInfo;

    // ensure Facilities
    let facilities = info.Facilities;
    if (!facilities) {
        info.Facilities = { Facility: [] }
        return;
    }

    // ensure Facilities.Facility
    if (!facilities.Facility) {
        info.Facilities.Facility = [];
        return;
    }

    // facilities: not an array
    if (!Array.isArray(facilities.Facility)) {
        let arr = new Array<Facility>();
        arr.push(JSON.parse(JSON.stringify(facilities.Facility)));
        info.Facilities.Facility = arr;
    }

    /*
    // ensure Facilities as array
    if (facilities.Facility.constructor !== Array) {
        let arr = [];
        arr.push(JSON.parse(JSON.stringify(facilities)));
        facilities.Facility = arr;
        return;
    }
    */
}

function ensureLOBArray(crud: MCFacilityMasterType) {

    const info = crud.FacilityMaintenanceInfo;

    if (!Array.isArray(crud.FacilityMaintenanceInfo.LOBList)) {
        if (crud.FacilityMaintenanceInfo.LOBList.LOB) {
            info.LOBList.LOB = []
            info.LOBList.LOB.concat(crud.FacilityMaintenanceInfo.LOBList.LOB);
        } else {
            info.LOBList.LOB = []
        }
    }
}

function ensureEntityStateListArray(crud: MCFacilityMasterType) {
    /*
    let stateList = crud.FacilityMaintenanceInfo.StateList;

    if (!Array.isArray(stateList)) {
        stateList = [stateList];
    }
    */

    /*
    if (info.StateList. !== Array) {
        let arr = [];
        arr.push(JSON.parse(JSON.stringify(facilities)));
        facilities.Facility = arr;
        // facilities = arr;
        return;
    }
    */
    return;
}

/*
function ensureEntityUserListArray(crud: MCGroupMasterType) {

    // ensure UserList
    let info = crud.GroupMaintenanceInfo;
    let users = info.UserList;
    if (!users) {
        info.UserList = { User: [] };
        return;
    }

    // ensure UserList.User
    const needsUsers = !users.hasOwnProperty("User");
    if (needsUsers) {
        users.User = [];
        return;
    }

    // ensure UserList.User as array
    if (users.User.constructor !== Array) {
        let arr = [];
        arr.push(JSON.parse(JSON.stringify(users.User)));
        users.User = arr;
        return;
    }
}

function ensureEntityFunctionListArray(crud: MCGroupMasterType) {

    // ensure FunctionList
    let info = crud.GroupMaintenanceInfo;
    let functions = info.FunctionList;
    if (!functions) {
        info.FunctionList = { Function: [] };
        return;
    }

    // ensure FunctionList.Function
    const needsFunctions = !functions.hasOwnProperty("Function");
    if (needsFunctions) {
        functions.Function = [];
        return;
    }

    // ensure FunctionList.Function as array
    if (functions.Function.constructor !== Array) {
        let arr = [];
        arr.push(JSON.parse(JSON.stringify(functions.Function)));
        functions.Function = arr;
        return;
    }
}

function validateEntityInitialization(crud: MCGroupMasterType) {
    if (!ValidateJSONSchema(crud, "MCGroupMasterType")) return false;
    return true;
}
*/
