import * as React from 'react';
import { Input, SelectDropdownV2, Typography } from 'ui-core';

import styled from 'styled-components';

import { ContentRowWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { CheckBoxComponent } from '../../common/CheckBox';

import { IDuplicateClaimsData, IDuplicateClaimsUpdateRequest } from '@store/ui/ClaimOptionsUI';

import { Colors } from '@commonResources/colorVariables';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    width: 745px;
    
    #id-dup-ub-enable-chk_wrapper, #id-dup-1500-enable-chk_wrapper {    
        margin-left:10px;
    }


`;

export const DupPaddingDiv = styled.div`
    padding-left: 30px;
`;

export const DupPaddingMatchDiv = styled.div`
    padding-left: 70px;
`;

export const customControlStyles = styled.div` => ({
    height: 20 });
`;

export const DuplicateClaimsErrorMessage = styled.span`
    width: 300px;
    display: block;
    margin-left: 15px;
    color: ${Colors.digitalRed130};
    ${Typography.small};
    ${Typography.defaultLineHeight};
`;
export interface IComponentProps {
    canViewDuplicateClaimsOptions: boolean,
    canEditDuplicateClaimsOptions: boolean,
    optionsUB: IDuplicateClaimsData,
    options1500: IDuplicateClaimsData,
    updateDuplicateClaimsOptions(request: IDuplicateClaimsUpdateRequest): void,
};


interface IComponentState {
}

export const DEFAULT_STATE: IComponentState = {
};

export class DuplicateClaimsOptions extends React.Component<IComponentProps, IComponentState> {


    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
    }

    public componentWillUnmount() {
    }

    public render() {

        if (!this.props.canViewDuplicateClaimsOptions)
            return <div></div>;

        return (
                <ContentWrapper id={"content_wrapper_DuplicateClaimsOptions"}>
                  <DialogFieldset id='dupclaims-ub-fieldset'>
                    <DialogLegend>UB</DialogLegend>
                       <CheckBoxComponent
                           id='id-dup-ub-enable-chk'
                           label='Enable UB'
                           width='260px'
                           disabled={!this.props.canEditDuplicateClaimsOptions}
                           checked={this.props.optionsUB.isEnabled}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                           }}
                        />
                       {(this.props.optionsUB.medRecNbEnabledError != undefined && this.props.optionsUB.medRecNbEnabledError.length > 0) &&
                       <DuplicateClaimsErrorMessage>{this.props.optionsUB.medRecNbEnabledError}</DuplicateClaimsErrorMessage>
                       }

                       <DupPaddingDiv>
                      <CheckBoxComponent
                           id='id-dup-ub-medrec-chk'
                           label='Medical Record Number'
                           width='260px'
                           disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isMedRecNbEnabled}
                            hasError={this.props.optionsUB.medRecNbEnabledError != undefined && this.props.optionsUB.medRecNbEnabledError !== ''}
                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isMedRecNbEnabled", Value: e.target.checked };
                           this.props.updateDuplicateClaimsOptions(request) }}
                       />
                       <CheckBoxComponent
                            id='id-dup-ub-patcntl-chk'
                            label='Patient Control Number'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isPCNEnabled}
                            hasError={this.props.optionsUB.pcnEnabledError != undefined && this.props.optionsUB.pcnEnabledError !== ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isPCNEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-ub-svcdates-chk'
                            label='Service Dates'
                            width='260px'
                            //disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            disabled={true}
                            checked={this.props.optionsUB.isServiceDatesEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isServiceDatesEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />

                        <CheckBoxComponent
                            id='id-dup-ub-patlastname-chk'
                            label='Patient Last Name'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isPatLastNameEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isPatLastNameEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request) }}
                        />
                        <CheckBoxComponent
                            id='id-dup-ub-payer-chk'
                            label='Payer'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isPayerEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isPayerEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request) }}
                        />
                        <CheckBoxComponent
                            id='id-dup-ub-payind-chk'
                            label='Payer Indicator'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isPayerIndEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isPayerIndEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request) }}
                        />

                        <CheckBoxComponent
                            id='id-dup-ub-billtype-chk'
                            label='Bill Type'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isBillTypeEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isBillTypeEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request) }}
                        />
                        <CheckBoxComponent
                            id='id-dup-ub-totamt-chk'
                            label='Total Dollar Amount'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isTotAmountEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isTotAmountEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request) }}
                        />
                        <CheckBoxComponent
                            id='id-dup-ub-prindiag-chk'
                            label='Principal Diagnosis Code'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isPrinDiagCodeEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isPrinDiagCodeEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request) }}
                        />
                        <CheckBoxComponent
                            id='id-dup-ub-prinproc-chk'
                            label='Principal Procedure Code'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isPrinProcCodeEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isPrinProcCodeEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request) }}
                        />
                        <CheckBoxComponent
                            id='id-dup-ub-facsubid-chk'
                            label='Facility Sub ID'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isFacSubIdEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "UB", Name: "@isFacSubIdEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request) }}
                        />
                    </DupPaddingDiv>
                   </DialogFieldset>

                   <DialogFieldset id='relclaims-1500-fieldset'>
                       <DialogLegend>1500</DialogLegend>
                    <CheckBoxComponent
                        id='id-dup-1500-enable-chk'
                        label='Enable 1500'
                        width='260px'
                        disabled={!this.props.canEditDuplicateClaimsOptions}
                        checked={this.props.options1500.isEnabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isEnabled", Value: e.target.checked };
                            this.props.updateDuplicateClaimsOptions(request)
                        }}
                    />
                    {(this.props.options1500.medRecNbEnabledError != undefined && this.props.options1500.medRecNbEnabledError.length > 0) &&
                        <DuplicateClaimsErrorMessage>{this.props.options1500.medRecNbEnabledError}</DuplicateClaimsErrorMessage>
                    }

                    <DupPaddingDiv>
                        <CheckBoxComponent
                            id='id-dup-1500-medrec-chk'
                            label='Medical Record Number'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isMedRecNbEnabled}
                            hasError={this.props.options1500.medRecNbEnabledError != undefined && this.props.options1500.medRecNbEnabledError !== ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isMedRecNbEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-patcntl-chk'
                            label='Patient Control Number'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isPCNEnabled}
                            hasError={this.props.options1500.pcnEnabledError != undefined && this.props.options1500.pcnEnabledError !== ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isPCNEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-svcdates-chk'
                            label='Service Dates'
                            width='260px'
                            //disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            disabled={true}
                            checked={this.props.options1500.isServiceDatesEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isServiceDatesEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-patlastname-chk'
                            label='Patient Last Name'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isPatLastNameEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isPatLastNameEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-payer-chk'
                            label='Payer'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isPayerEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isPayerEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-payind-chk'
                            label='Payer Indicator'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isPayerIndEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isPayerIndEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-totamt-chk'
                            label='Total Dollar Amount'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isTotAmountEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isTotAmountEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-prindiag-chk'
                            label='Principal Diagnosis Code'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isPrinDiagCodeEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isPrinDiagCodeEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-prinproc-chk'
                            label='Principal Procedure Code'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isPrinProcCodeEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isPrinProcCodeEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-facsubid-chk'
                            label='Facility Sub ID'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isFacSubIdEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isFacSubIdEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                        <CheckBoxComponent
                            id='id-dup-1500-frequency-chk'
                            label='Frequency'
                            width='260px'
                            disabled={!this.props.canEditDuplicateClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isFrequencyEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IDuplicateClaimsUpdateRequest = { Type: "1500", Name: "@isFrequencyEnabled", Value: e.target.checked };
                                this.props.updateDuplicateClaimsOptions(request)
                            }}
                        />
                    </DupPaddingDiv>
                    </DialogFieldset>
                </ContentWrapper>
        );
    }
};


