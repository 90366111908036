import * as React from 'react';
import styled from 'styled-components';
import { IImageButtonImage, ImageButton } from '../common/ImageButton';

import ArrowUpUp from '../../content/images/Buttons/But_ArrowUp_creamUp.png';
import ArrowUpDown from '../../content/images/Buttons/But_ArrowUp_creamDown.png';
import ArrowUpFocux from '../../content/images/Buttons/But_ArrowUp_creamFocus.png';
import ArrowUpOff from '../../content/images/Buttons/But_ArrowUp_creamOff.png';
import ArrowUpOver from '../../content/images/Buttons/But_ArrowUp_creamOver.png';

import ArrowDownUp from '../../content/images/Buttons/But_ArrowDown_creamUp.png';
import ArrowDownDown from '../../content/images/Buttons/But_ArrowDown_creamDown.png';
import ArrowDownFocux from '../../content/images/Buttons/But_ArrowDown_creamFocus.png';
import ArrowDownOff from '../../content/images/Buttons/But_ArrowDown_creamOff.png';
import ArrowDownOver from '../../content/images/Buttons/But_ArrowDown_creamOver.png';

export interface IArrowButtonsProps {
    title: string;                                          // title of buttons
    width?: string;                                         // width for your screen
    paddingTop?: string;                                    // moving it down if needed
    paddingLeft?: string;
    className?: string;                                     // classname if needed
    toolTipUp?: string;                                     // tooltip?
    toolTipDown?: string;                                   // tooltip?
    disabled?: boolean;                     
    direction?: string;                                      // flex direction - row | column
    onClick?: React.ChangeEventHandler<HTMLButtonElement>;  // action taken (see FieldLevelSecurity.TSX for usage)
    onUpBtnClick?: React.ChangeEventHandler<HTMLButtonElement>;
    onDownBtnClick?: React.ChangeEventHandler<HTMLButtonElement>; 
};

export interface IArrowButtonsState {
    disabled: boolean;
};

export interface IArrowButtonsWrapperProps {
    widthValue?: string;
    paddingTopValue?: string;
    paddingLeftValue?: string;
    directionValue?: string;
};

export const ArrowButtonsWrapper = styled.div`
    display: flex;
    flex: 0;
    flex-direction: row;
    min-height: 40px;   
    min-width: 35px;

    ${(props: IArrowButtonsWrapperProps) => props.widthValue && `width: ${props.widthValue}`};
    ${(props: IArrowButtonsWrapperProps) => props.paddingTopValue && `padding-top: ${props.paddingTopValue}`};
    ${(props: IArrowButtonsWrapperProps) => props.paddingLeftValue && `padding-left: ${props.paddingLeftValue}`};

    .arrow-button {
        min-width: 35px;
    }
`;

export class UpDownArrowButtonsRow extends React.Component<IArrowButtonsProps, IArrowButtonsState>
{
    static defaultProps: IArrowButtonsProps = {
        title: "ArrowButtons",
        width: '5px',
        paddingTop: '5px',
        paddingLeft: '5px',
        className: 'arrow-button',
        toolTipUp: 'Arrow Up',
        toolTipDown: 'Arrow Down',
        disabled: false,
    };

    static arrowUpIcon: IImageButtonImage = {
        Off: ArrowUpOff,
        Over: ArrowUpOver,
        Up: ArrowUpUp,
        Down: ArrowUpDown,
    }

    static arrowDownIcon: IImageButtonImage = {
        Off: ArrowDownOff,
        Over: ArrowDownOver,
        Up: ArrowDownUp,
        Down: ArrowDownDown,
    }

    constructor(props: IArrowButtonsProps) {
        super(props);
    }

    public getArrowUpIcon() {
        return (
            <ImageButton
                Title='upArrow'
                tooltip={this.props.toolTipUp}
                altText={this.props.toolTipUp}
                image={UpDownArrowButtonsRow.arrowUpIcon}
                disabled={this.props.disabled}
                className="arrow-button"
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    if (this.props.onClick !== undefined) {
                        this.props.onClick(e);
                    } else {
                        if (this.props.onUpBtnClick !== undefined) {
                            this.props.onUpBtnClick(e);
                        }
                    }
                }}
            />
        );
    }

    public getArrowDownIcon() {
        return (
            <ImageButton
                Title='downArrow'
                tooltip={this.props.toolTipDown}
                altText={this.props.toolTipDown}
                image={UpDownArrowButtonsRow.arrowDownIcon}
                className="arrow-button"
                disabled={this.props.disabled}
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    if (this.props.onClick !== undefined) {
                        this.props.onClick(e);
                    } else {
                        if (this.props.onDownBtnClick !== undefined) {
                            this.props.onDownBtnClick(e);
                        }
                    }
                }}
            />
        );
    }

    render() {
        var arrowUpIcon = this.getArrowUpIcon();
        var arrowDownIcon = this.getArrowDownIcon();

        return (
            <ArrowButtonsWrapper
                id={this.props.title}                widthValue={this.props.width}                paddingTopValue={this.props.paddingTop}                paddingLeftValue={this.props.paddingLeft}                className={this.props.className}            >
                {arrowDownIcon}
                {arrowUpIcon}
            </ArrowButtonsWrapper>
        );
    }
};