import React from "react";
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';

/*
 * INTERFACES
 */
interface IVerifyProps {
    parent: any,
};

/*
 * STYLED COMPONENTS
 */

const FailedItem = styled.div`
   margin-left: 20px; 
`;

export const MessagePrompt = styled.div`
    margin-top: 20px;
    margin-bottom: 200px;
`;

export const VerifiedClaimYesNo = styled.span`
    margin-left: 5px;
    a {
        color: ${Colors.chBlue100};
    };
`;


/**
 *
 *  VERIFY CLAIMS SCREEN
 *  This is the first screen that loads to provide feedback on which checks pass or pail
 *
 */

export const VerifyClaimsScreen = ({ parent }: IVerifyProps) => {

    let verifiedClaimsItems = parent.state.data!.requirementChecks;

    const items = verifiedClaimsItems.map((item: any, index: number) => {
        return (
            <React.Fragment key={index}>
                <div>{item.success ? '✔️' : '❌'} {item.description}</div>
                {!item.success && <FailedItem>{item.failMessage}</FailedItem>}
            </React.Fragment>
        )
    }

    );
    const handleYesProceed = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        parent.setState({ isProceedAnyway: true });
        parent.setState({ activeItemIndex: 1 });
        parent.setState({ isYesClicked: true });
    };

    const handleNoProceed = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        // return to Claim screen
        parent.props.history.push(parent.referrerPage);
    };

    return (
        < React.Fragment>
            <div>
                {items}
            </div>
            <MessagePrompt>
                {(!parent.state.areAllClaimsVerified && !parent.state.isYesClicked ) && (
                    <div>Do you still want to create a CIF for this claim?
                        <VerifiedClaimYesNo>
                            <a href="#" onClick={e => { handleYesProceed(e) }}>Yes</a>
                            <span>&nbsp;</span>
                            <a href="#" onClick={e => { handleNoProceed(e) }}>No</a>
                        </VerifiedClaimYesNo>
                    </div>
                )}
            </MessagePrompt>
        </React.Fragment>
    );
}
