import * as React from 'react';
import { DialogWrapper } from '../common/DialogWrapper';
import styled from 'styled-components';
import _ from 'lodash';

import FilterableTable from '@commonResources/FilterableTable/FilterableTable';
import { columnsv1, columnsv2, columnsv3, recordsv1, recordsv2, recordsv3 } from '@commonResources/FilterableTable/SampleData'

const ListContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

interface IComponentProps {
};

interface IComponentState {
};



export default class FilterableTableDemo extends React.Component<IComponentProps, IComponentState> {
    render() {
        return (
            <DialogWrapper title="Filterable Table Demo" >
                <ListContainerDiv>
                    <FilterableTable
                        maxHeight="150px" // optional maxHeight prop
                        domID="Test@123" // recommened but optional domID
                        columns={columnsv2}
                        records={recordsv2}
                    />
                </ListContainerDiv>
            </ DialogWrapper>
        )
    }
}