import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";

import {
  DialogWrapper,
  OKCancelButtons,
  ContentRowWrapper,
} from "@common/DialogWrapper";
import { ModalConfirmation } from "@common/ModalConfirmation";

import {
  IMergeCrudComponentProps,
  createCrudMapStateToProps,
  createCrudMapDispatchToProps,
  mergeCrudComponentProps,
  resetCrudComponentState,
} from "@scripts/util/CrudComponentHelpers";

import { connect } from "react-redux";
import { ApplicationState } from "@store/index";

import { ARMNarrowBrowse } from "@common/ARMNarrowBrowse";
import { Typography } from "@commonResources/typography";
import { Colors } from "@commonResources/colorVariables";
import { pageLeave } from "@commonResources/userModified";
import AssuranceMenu from "@common/AssuranceMenu";
import { SelectDropdownV2 } from "ui-core";
import { DialogFieldset, DialogLegend } from "@common/DialogStyles";

import {
  ISubmitDESFilesUploadState,
  ISubmitDESFilesUploadActionProps,
  actionCreators,
  fileTypeDataValidationCallback,
  uploadDESFilesValidationCallback,
} from "@store/SubmitDESFiles";

export const FileUploadWrapper = styled.div`
  .separator {
    height: 1px;
    background-color: #737d85;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;

  #file-upload-top,
  #file-upload-bottom {
    width: 800px;
  }

  #fileupload {
    padding: 8px !important;
  }

  #file-type-top-select {
    width: 250px;
    margin-left: 5px;
  }
  #fileupload-bottom,
  #fileupload-top {
    margin-right: 6px;
    margin-top: -3px;
  }
`;

export const SlimLabel = styled.div`
  display: block;
  color: #37474f;
  font-size: 11.5px;
  line-height: 1.4em;
  font-weight: 600;
  -webkit-letter-spacing: 0.5px;
  -moz-letter-spacing: 0.5px;
  -ms-letter-spacing: 0.5px;
  letter-spacing: 0.5px;
  margin-right: 5px;
  text-align: end;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

// TODO - this may need to be deleted
export const UpdatingDialogContainer = styled.div`
  width: 1000px;
  height: 100px;
  border: 1px solid grey;
`;

// TODO - this may need to be deleted
export const UpdatingStatusMsg = styled.div`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.black};
  ${Typography.headingSmall};
  ${Typography.ARMFontFamily};
`;

interface IComponentProps {
  canView: boolean;
  redirectUrl: string;
}

interface IComponentState {
  files: any;
  isUpdating: boolean;
  isAlertOpen: boolean;
  alertMessage: string;
  cancelLeave: boolean;
  fileTypes: any;
}

export const DEFAULT_STATE: IComponentState = {
  files: new FormData(),
  isUpdating: false,
  isAlertOpen: false,
  alertMessage: "",
  cancelLeave: false,
  fileTypes: null,
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type ISubmitDESFilesUploadProps = IMergeCrudComponentProps<
  ISubmitDESFilesUploadState,
  ISubmitDESFilesUploadActionProps,
  IOwnProps
>;

export class DESFileUpload extends React.Component<
  ISubmitDESFilesUploadProps,
  IComponentState
> {
  static defaultProps: IComponentProps = {
    canView: false,
    redirectUrl: "",
  };

  constructor(props: ISubmitDESFilesUploadProps) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  public componentDidMount() {
    this.fetchDESFormTypes();
    this.setState({ files: new FormData()})
    if (!this.props.canView) {
      this.props.history.push("/LandingPage");
    }

    pageLeave();
  }

  public componentDidUpdate() {
    if (this.props.dataStore.ui.fileTypes && !this.state.fileTypes) {
      const fileTypes = this.getLookupListIntialItem(
        this.props.dataStore.ui.fileTypes,
        null
      );
      this.onUpdateFileTypeTop(fileTypes[0]);
      this.onUpdateFileTypeBottom(fileTypes[0]);

      fileTypes && this.setState({ fileTypes });
    }
    console.log(this.props.dataStore.ui);
  }

  public async fetchDESFormTypes() {
    this.props.action.ui.getFileTypeData(fileTypeDataValidationCallback);
  }

  public validate() {
    if (
      this.props.dataStore.ui.fileUploadTopName ||
      this.props.dataStore.ui.fileUploadBottomName
    ) {
      return true;
    }
    return false;
  }

  public async uploadFiles() {
    const data = [];

    this.props.dataStore.ui.fileUploadTopName &&
      this.props.dataStore.ui.fileTypeTop["@ID"] &&
      data.push(this.props.dataStore.ui.fileTypeTop["@ID"]);
    this.props.dataStore.ui.fileUploadBottomName &&
      this.props.dataStore.ui.fileTypeBottom["@ID"] &&
      data.push(this.props.dataStore.ui.fileTypeBottom["@ID"]);

    this.props.action.ui.uploadDESFiles(
      data,
      this.state.files,
      uploadDESFilesValidationCallback
    );
  }

  public onOKSubmit(e: React.ChangeEvent<HTMLButtonElement>) {
    if (this.validate()) {
      try {
        this.uploadFiles();
      } catch (e) {
        console.log(e);
      } finally {
        this.props.history.push("/LandingPage");
      }
    }
  }

  public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
    const userModified: any = sessionStorage.getItem("userModified");
    if (userModified === "true") {
      this.setState({ cancelLeave: true });
    } else this.props.history.push("/LandingPage");
  }

  public componentWillUnmount() {
    pageLeave();
    resetCrudComponentState(this.props.action, this.props.dataStore);
  }

  public uploadFileUploadTop(uploadedfile: any, index: any) {
    if (uploadedfile.target.files) {
      const uploadData = new FormData();
      uploadData.append("file", uploadedfile.target.files);
      const files = this.state.files;
      files?.delete(`file[${index}]`);
      files.append(`file[${index}]`, uploadedfile.target.files[0]);
      this.props.action.ui.selectFileUploadTop({
        masterCrud: this.props.dataStore.crud.data,
        uiData: {
          value: uploadedfile.target.files[0],
          name: uploadedfile.target.files[0].name,
        },
      });
      this.setState({ files: files });
    }
  }

  public uploadFileUploadBottom(uploadedfile: any, index: any) {
    if (uploadedfile.target.files) {
      console.log(uploadedfile);
      const uploadData = new FormData();
      uploadData.append("file", uploadedfile.target.files);
      const files = this.state.files;
      files?.delete(`file[${index}]`);
      files.append(`file[${index}]`, uploadedfile.target.files[0]);

      this.props.action.ui.selectFileUploadBottom({
        masterCrud: this.props.dataStore.crud.data,
        uiData: {
          value: uploadedfile.target.files[0],
          name: uploadedfile.target.files[0].name,
        },
      });
      this.setState({ files: files });
    }
  }

  public onUpdateFileTypeTop(e: any | undefined, id: string = "") {
    const result = {
      "@ID": id.length > 0 ? id : e && e.value ? e.value : "0",
      "@Name": e && e.label ? e.label : "",
    };
    this.onFileTypeChangeTop(result);
  }

  public onFileTypeChangeTop(e: any) {
    this.props.action.ui.selectFileTypeTop({
      uiData: {
        value: e,
      },
    });
  }

  public onUpdateFileTypeBottom(e: any | undefined, id: string = "") {
    const result = {
      "@ID": id.length > 0 ? id : e && e.value ? e.value : "0",
      "@Name": e && e.label ? e.label : "",
    };
    this.onFileTypeChangeBottom(result);
  }

  public onFileTypeChangeBottom(e: any) {
    this.props.action.ui.selectFileTypeBottom({
      uiData: {
        value: e,
      },
    });
  }

  public apiDataAvailable(): boolean {
    if (this.props.dataStore.ui && this.props.dataStore.ui.fileTypes) {
      return true;
    }
    return false;
  }

  public getLookupListIntialItem(
    lookupList: any | null,
    initialItem: string[] | null
  ) {
    if (!this.apiDataAvailable() || !lookupList) return [];

    let result: any[] = [];

    if (initialItem) {
      result.push({ label: initialItem[0], value: initialItem[1] });
    }

    for (let index in lookupList) {
      let obj = lookupList[index][0];

      if (obj["Name"]) {
        result.push({ label: obj["Name"], value: obj["Id"] });
      }
    }

    return result;
  }

  public onFileUploadInputChange = (e: any) => {
    switch (e.target.id) {
      case "file-upload-top":
        if (e.target.value !== this.props.dataStore.ui.fileUploadTopName) {
          const files = this.state.files;
          files?.delete(`file[1]`);
          this.setState({ files });

          this.props.action.ui.selectFileUploadTop({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
              value: "",
              name: "",
            },
          });
        }
        break;
      case "file-upload-bottom":
        if (e.target.value !== this.props.dataStore.ui.fileUploadBottomName) {
          const files = this.state.files;
          files?.delete(`file[2]`);
          this.setState({ files });

          this.props.action.ui.selectFileUploadBottom({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
              value: "",
              name: "",
            },
          });
        }
        break;
      default:
        console.log(`Sorry, something went wrong.`);
    }
  };

  public render() {
    const instruction = (
      <>
        Browse to the file you want to submit. On OK, the file is submitted to
        DES for processing.
      </>
    );

    const buttons = (
      <OKCancelButtons
        disableOK={!this.props.canView}
        onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) =>
          this.onOKSubmit(e)
        }
        onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) =>
          this.onCancel(e)
        }
      />
    );

    const { fileTypes } = this.state;
    const options = fileTypes ? fileTypes : [];
    const initialValue = fileTypes ? fileTypes[0] : {};

    return (
      <FileUploadWrapper>
        <DialogWrapper
          title="Submit Files"
          width="1000px"
          instructionWidth="1000px"
          instruction={instruction}
          helpUrl="/Support/Help/HELP_SubmitAttachment.asp"
          buttons={buttons}
          isBusy={this.props.dataStore.crud.dataStatus === "REQUEST"}
        >
          {this.state.cancelLeave && (
            <AssuranceMenu
              {...this.props}
              Cancel={true}
              MenuUrl=""
              stayEvent={() => this.setState({ cancelLeave: false })}
            />
          )}

          {!this.state.isUpdating ? (
            <DialogFieldset
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <DialogLegend>DES</DialogLegend>
              <ContentWrapper>
                <ContentRowWrapper>
                  <SlimLabel>File Type:</SlimLabel>
                  <SelectDropdownV2
                    domID="file-type-top"
                    className="top-dropdown"
                    isClearable={false}
                    isSearchable={false}
                    size="small"
                    width="250x"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      this.onUpdateFileTypeTop(e)
                    }
                    options={options}
                    initialValue={initialValue}
                  />
                </ContentRowWrapper>
              </ContentWrapper>

              <ContentWrapper>
                <ContentRowWrapper>
                  <SlimLabel>File:</SlimLabel>
                  <ARMNarrowBrowse
                    inputId="file-upload-top"
                    classNameValue="text-input"
                    maxLength={300}
                    initialValue={this.props.dataStore.ui.fileUploadTopName}
                    browseId="fileupload-top"
                    browseInputId="file-top"
                    onChange={(e: any) => this.uploadFileUploadTop(e, 1)}
                    inputChange={this.onFileUploadInputChange}
                    browseColumnStyleValue={{
                      marginTop: "5px",
                      marginLeft: "10px",
                    }}
                    divBrowseStyle={{ marginTop: "10px", marginLeft: "0px" }}
                    canEdit={true}
                  />
                </ContentRowWrapper>
              </ContentWrapper>

              <div className="separator" />

              <ContentWrapper>
                <ContentRowWrapper>
                  <SlimLabel>File Type:</SlimLabel>
                  <SelectDropdownV2
                    domID="file-type-top"
                    className="top-dropdown"
                    isClearable={false}
                    isSearchable={false}
                    size="small"
                    width="250x"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      this.onUpdateFileTypeBottom(e)
                    }
                    options={options}
                    initialValue={initialValue}
                  />
                </ContentRowWrapper>
              </ContentWrapper>

              <ContentWrapper>
                <ContentRowWrapper>
                  <SlimLabel>File:</SlimLabel>
                  <ARMNarrowBrowse
                    inputId="file-upload-bottom"
                    classNameValue="text-input"
                    maxLength={300}
                    initialValue={this.props.dataStore.ui.fileUploadBottomName}
                    browseId="fileupload-bottom"
                    browseInputId="file-bottom"
                    onChange={(e: any) => this.uploadFileUploadBottom(e, 2)}
                    inputChange={this.onFileUploadInputChange}
                    browseColumnStyleValue={{
                      marginTop: "5px",
                      marginLeft: "10px",
                    }}
                    divBrowseStyle={{ marginTop: "10px", marginLeft: "0px" }}
                    canEdit={true}
                  />
                </ContentRowWrapper>
              </ContentWrapper>
            </DialogFieldset>
          ) : (
            // TODO - this may need to be deleted
            <UpdatingDialogContainer>
              <UpdatingStatusMsg>
                Uploading DES files, please wait..
              </UpdatingStatusMsg>
            </UpdatingDialogContainer>
          )}

          <ModalConfirmation
            isOpen={this.state.isAlertOpen}
            formattedMessage={
              <React.Fragment>
                <p>{this.state.alertMessage}</p>
              </React.Fragment>
            }
            onModalToggle={(e: React.MouseEvent<HTMLElement>) => {
              this.setState({ isAlertOpen: false, alertMessage: "" });
            }}
            onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
              this.setState({ isAlertOpen: false, alertMessage: "" });
            }}
            onDeny={(e: React.MouseEvent<HTMLButtonElement>) => {
              this.setState({ isAlertOpen: false });
            }}
            alertMode={true}
          />
        </DialogWrapper>
      </FileUploadWrapper>
    );
  }
}

const connectedHoc = connect<
  ISubmitDESFilesUploadState,
  ISubmitDESFilesUploadActionProps,
  IOwnProps,
  ISubmitDESFilesUploadProps,
  ApplicationState
>(
  createCrudMapStateToProps("submitDESFiles"), // Selects which state properties are merged into the component's props
  createCrudMapDispatchToProps(actionCreators),
  mergeCrudComponentProps
)(DESFileUpload);

export default withRouter(connectedHoc);
