import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '../../../commonResources/colorVariables';
import { ARMLoginWrapper, ARMLoginBody, ARMLoginForm, FormRow } from '../Logon/styles/ARMLoginForm';
import { Button, Input, Typography } from 'ui-core';
import { URLs } from '../../../commonResources/constants';

interface CiamProps{ };

interface CiamState {
    portalEndpointURL: string;
};

const LoginPrompt = styled.div`
    padding: 10px 0 5px 0;
    white-space: pre-line;
    font-size: 12px;
    color: ${Colors.black};
    width:100%;
`
const OrSpan = styled.span`
    padding: 5px 10px; 
    font-size: 15px; 
    background-color: rgb(255, 255, 255);
    margin-top: 10px;
`;

const OrDiv = styled.div`
    width: 100%; height: 10px; 
    text-align: center; 
    margin-top: 20px; 
    margin-bottom: 20px; 
    border-bottom-color: black; 
    border-bottom-width: 1px; 
    border-bottom-style: solid;
`;

export default class CiamPortal extends React.Component<CiamProps, CiamState>{

    constructor(props: CiamProps) {
        super(props);
    }

    public componentDidMount() {
        this.getEndPointURL();
    }

    public getEndPointURL = async () => {
        var url = URLs.api + '/api/data/securityInfo/getPortalEndpointURL';
        let endpointURL = ''
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                endpointURL = jsonData;
            });
        this.setState({ portalEndpointURL: endpointURL });
        return endpointURL;
    };

    public getPortalEndpoint(e: React.ChangeEvent<HTMLButtonElement>) {
        e.preventDefault();
        let ciamLinkURL = this.state.portalEndpointURL + "/AuthLogin";
        window.location.href = ciamLinkURL;
    }

    public render() {
        return (
            <>
                <LoginPrompt>Log in with</LoginPrompt>
                <FormRow>
                    <div>
                        <Button
                            domID="ciam-button"
                            type="button"
                            size="medium"
                            buttonType="link"
                            className="link-button align-top"
                            onClick={(e: any) => this.getPortalEndpoint(e)}
                            disabled={false}
                        >
                            <span id="ciam">Customer Identity Access Management (CIAM)</span>
                        </Button>
                    </div>
                </FormRow>
                <OrDiv id="orSeparator">
                    <OrSpan>OR</OrSpan>
                </OrDiv>
            </>
        )
    }

}