import { combineReducers } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as SweepTimeUI from './ui/SweepTimeUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type ISweepTimeState = ICrudComponentState<MCSweepTimeType, SweepTimeUI.ISweepTimeUiState>;

export const actionCreators = createCrudComponentActions<SweepTimeUI.ActionCreators>(SweepTimeUI.actionCreators);
export type ISweepTimeActions = typeof actionCreators;
export type ISweepTimeActionProps = ICrudComponentActionProps<ISweepTimeActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiAutoPrintMaster, SweepTimeUI.reducer);
export const reducer = combineReducers<ISweepTimeState>(reducers);


export const validationCallback: ValidationCallback<MCSweepTimeType> = (crud: any): crud is MCSweepTimeType => {
    let testCrud: MCSweepTimeType = crud as MCSweepTimeType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCSweepTimeType");
    if (validShape) {
        if (testCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events as any === "") {
            testCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events = { Event: new Array<MCST_Event>() };
        }
        if (testCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events &&
            !Array.isArray(testCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event)) {
            let arr = new Array<MCST_Event>();
            arr.push(JSON.parse(JSON.stringify(testCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event)));
            testCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event = arr;
        }
    }
    return validShape;
}