import { Reducer, Dispatch, bindActionCreators, ActionCreatorsMapObject } from 'redux';
import { MapStateToPropsParam, MapDispatchToPropsParam, MapDispatchToPropsFunction } from 'react-redux';
import * as Api from '@store/Api';
import { ApiDataState, ApiDataStateKeys } from '@store/index';
import * as CommonUI from '@store/CommonUI';
import * as ModalConfirmationUI from '@store/ModalConfirmation';
import * as ConfigData from '@store/ConfigData';


interface IApiComponentBase<C extends any, U extends any, S extends any = any, X extends any = any> {
    api: C;
    ui: U;
    confirm: S;
    configData: X;
};



export type IApiComponentState<T extends any, U extends any> = IApiComponentBase<Api.ApiState<T>, U>; 
export type IApiComponentActions<U extends any> = IApiComponentBase<Api.ActionCreators, U>;
export type IApiComponentReducers<U extends Reducer<any | undefined, any>> = IApiComponentBase<Reducer<Api.ApiState<any>, Api.KnownActions>, U>;

export function createApiComponentActions<U>(ui: U | CommonUI.ActionCreators): IApiComponentActions<U | CommonUI.ActionCreators> {
    return {
        api: Api.actionCreators,
        ui: { ...ui, ...CommonUI.actionCreators },
        confirm: ModalConfirmationUI.actionCreators,
        configData: ConfigData.actionCreators
    };
};

export function createApiComponentReducers<U>(apiType: string, ui: Reducer<U, any>): IApiComponentReducers<Reducer<U, any>> {
    return {
        api: Api.createReducer(apiType),
        ui: CommonUI.createReducer(apiType, ui),
        confirm: ModalConfirmationUI.reducer,
        configData: ConfigData.reducer
    };
}

export function resetApiComponentState<T extends any, S extends any, C extends ActionCreatorsMapObject, A extends IApiComponentActions<C | CommonUI.ActionCreators>>(apiActions: IApiComponentActionProps<A>, apiState: IApiComponentState<T, S>): void {
    apiActions.api.reset();
    apiActions.ui.reset(apiState.api.apiType);
    apiActions.configData.reset();
}





// The generic case - however, we really want it to be based on our ApplicationState so we pick a valid key.
//export function createMapStateToProps<TStateProps, TOwnProps, State>(stateField: keyof State): MapStateToPropsParam<TStateProps, TOwnProps, State> {
//    return (state: State, ownProps: TOwnProps): any => {
//        return state[stateField];
//    }
//}

export function createApiMapStateToProps<TOwnProps, State extends ApiDataState>(stateField: ApiDataStateKeys): MapStateToPropsParam<IApiComponentState<any, any>, TOwnProps, State> {

    return (state: State, ownProps: TOwnProps): IApiComponentState<any, any> => {
        return state[stateField];
    }
}


export type IApiComponentActionProps<U extends any> = IApiComponentBase<ActionCreatorsMapObject<Api.ActionCreators>, ActionCreatorsMapObject<U>>


export function createApiMapDispatchToProps<TOwnProps>(actionCreators: IApiComponentActions<any>): MapDispatchToPropsFunction<IApiComponentActionProps<any>, TOwnProps> {
    return (dispatch: Dispatch, ownProps: TOwnProps): IApiComponentActionProps<any> => {
        return {
            api: bindActionCreators<Api.ActionCreators, ActionCreatorsMapObject<Api.ActionCreators>>(actionCreators.api, dispatch),
            ui: bindActionCreators(actionCreators.ui, dispatch),
            confirm: bindActionCreators(actionCreators.confirm, dispatch),
            configData: bindActionCreators(actionCreators.configData, dispatch)
        };
    }
}

export type IMergeApiComponentProps<S extends IApiComponentState<any, any>, A extends IApiComponentActionProps<any>, O extends {}> = O & {
    dataStore: S;
    action: A;
};


export function mergeApiComponentProps<S extends IApiComponentState<any, any>, A extends IApiComponentActionProps<any>, O extends {}>(state: S, action: A, ownProps: O): IMergeApiComponentProps<S, A, O> {
    return Object.assign({}, ownProps, {
        dataStore: state,
        action: action
    });
}



export interface IApiActionData<T extends any, D extends any> {
    apiData?: T;
    api?: T;
    uiData: D;
}

