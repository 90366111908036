export interface IHeaderProps {
    columns: {
    }
}

export interface IRowProps {
    record: {
        id: number;
        fileName: string;
    }
}

interface IUiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

export class GridConfig {
    public static getColumnsConfig(): Set<IUiclColumn> {
        const columns: IUiclColumn[] = [
            {
                text: 'File Name',
                dataName: 'fileName',
                sortable: true,
                cellType: 'text',
                // isSorted: 0,
            },
        ];
        return new Set(columns);
    }
}
