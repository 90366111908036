import { Action, Reducer } from "redux";
import { createAction, createDataAction, ActionTypes } from "@scripts/util/ActionHelpers";
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export function enforceArray<T>(node: T[]): T[] {

    if (Array.isArray(node))
        return node;

    const array: T[] = [];
    return array.concat(node);
}

export interface IAliasCredentialUIModel {
    id: string;
    aliasId: string;
    aliasName: string;
    userName: string;
    password: string;
}

export interface IPrintOptionsList {
  printOptionList: MCPrintOption[];
}

export interface IDateOptions{
    DateOption:MCDateOption
}


export interface IPaperClaimsPrintingMaintenanceUIState {
    selectedClaimType: MCClaimType;
    selectedFormType: MCFormType;
    selectedFontFace: MCFontFace;
    selectedFontSize: MCFontSize;
    selectedDateOption: MCDateOption;
    selectedPrintOption: MCPrintOption;
    printOptionList: any;
    initialValuesLoaded: boolean;
    batchClaimReport: boolean;
    singleClaimReport: boolean;
    viewEditDate: string;
    CLSPrintDate: string;
    localPrintDate: string;
}



const defaultClaimType: MCClaimType = {
    '@ID': '',
    '@Name': ''
}

const defaultFormType: MCFormType = {
    '@ID': '',
    '@Name': ''
}

const defaultFontFace: MCFontFace = {
    '@ID': '',
    '@Name': ''
}

const defaultFontSize: MCFontSize = {
    '@ID': '',
    '@Name': '',
    '@FontAdjust': ''
}

const defaultDateOption: MCDateOption = {
    '@ID': '',
    '@BatchClaimPrompt': '',
    '@SingleClaimPrompt': '',
    '@CMSPrintDate': '',
    '@CMSClsPrintDate':'',
    '@CMSViewDate': ''
}

const defaultPrintOption: MCPrintOption = {
    "@ID": '',
    "@ClaimTypeID": '',
    "@PrintTypeID": '',
    "@FontFaceID": '',
    "@FontSizeID": ''
}




export interface IUpdatePrintOptions extends ICrudActionData<MCPaperClaimsPrinting, IPrintOptionsList > { }
export interface IUpdateDateOptions extends ICrudActionData<MCPaperClaimsPrinting, IDateOptions > { }

export const actionCreators = {
    updatePrintOptions: (printOption: any) => createDataAction('UPDATE_PRINT_OPTION', printOption),
    onToggleBatchClaimReport: (toggleValue:any) => createDataAction('TOGGLE_BATCH_CLAIM', toggleValue),
    onToggleSingleClaimReport: (toggleValue: any) => createDataAction('TOGGLE_SINGLE_CLAIM', toggleValue),
    updatePrintType: (value: IUpdatePrintOptions) => createDataAction('UPDATE_PRINT_TYPE', value),
    updateFontFace: (value: IUpdatePrintOptions) => createDataAction('UPDATE_FONT_FACE', value),
    updateFontSize: (value: IUpdatePrintOptions) => createDataAction('UPDATE_FONT_SIZE', value),
    selectPrintOptions: (printOption: any) => createDataAction('SELECT_PRINT_OPTION', printOption),
    updateViewEditDate: (value: any) => createDataAction('UPDATE_VIEW_EDIT_DATE', value),
    updateLocatPrintDate: (value: any) => createDataAction('UPDATE_LOCAL_PRINT_DATE', value),
    updateCLSPrintDate: (value: any) => createDataAction('UPDATE_CLS_PRINT_DATE', value),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>["type"];

export const defaultState: IPaperClaimsPrintingMaintenanceUIState = {
    selectedClaimType: defaultClaimType,
    selectedFontFace: defaultFontFace,
    selectedDateOption: defaultDateOption,
    selectedFormType: defaultFormType,
    selectedFontSize: defaultFontSize,
    selectedPrintOption: defaultPrintOption,
    printOptionList: [],
    initialValuesLoaded: false,
    batchClaimReport: false,
    singleClaimReport: false,
    viewEditDate: '',
    CLSPrintDate: '',
    localPrintDate: ''

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IPaperClaimsPrintingMaintenanceUIState, KnownActions> = (state: IPaperClaimsPrintingMaintenanceUIState | undefined, action: KnownActions) => {
    if (state != undefined) {

        switch (action.type) {
            case 'UPDATE_PRINT_OPTION':
                {

                    if (action.data.masterCrud) {
                        let selectedClaimType = action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType.find(
                            (ob: any) => ob['@ID'] === action.data.uiData.printOption["@ClaimTypeID"]);

                        let selectedFormType = action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType.find(
                            (ob: any) => ob['@ID'] === action.data.uiData.printOption["@PrintTypeID"]);

                        let selectedFontSize = action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize.find(
                            (ob: any) => ob['@ID'] === action.data.uiData.printOption["@FontSizeID"]);

                        let selectedFontFace = action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace.find(
                            (ob: any) => ob['@ID'] === action.data.uiData.printOption["@FontFaceID"]);

                        let selectedPrintOption = action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.PrintOptionList.PrintOption[0];
                        
                        let DateOptions = {};

                        if(!action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions){
                            DateOptions = {
                                DateOption:{
                                    '@CMSViewDate':'04/01/2014',
                                    '@CMSPrintDate':'04/01/2014',
                                    '@CMSClsPrintDate':'04/01/2014',
                                    '@BatchClaimPrompt': false,
                                    '@SingleClaimPrompt': false
                                }
                            }
                        }else{
                            DateOptions = action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions;
                        }
                        return {
                            ...state,
                            DateOptions: DateOptions,
                            selectedClaimType: selectedClaimType,
                            selectedFormType: selectedFormType,
                            selectedFontSize: selectedFontSize,
                            selectedFontFace: selectedFontFace,
                            selectedPrintOption: selectedPrintOption,
                            printOptionList: action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.PrintOptionList,
                            initialValuesLoaded: true,
                            viewEditDate: action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions && action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions.DateOption['@CMSViewDate'] || '04/01/2014',
                            localPrintDate: action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions && action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions.DateOption['@CMSPrintDate'] || '04/01/2014',
                            CLSPrintDate: action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions && action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions.DateOption['@CMSClsPrintDate']  || '04/01/2014',
                            batchClaimReport: action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions && action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions.DateOption['@BatchClaimPrompt'] || false,
                            singleClaimReport:  action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions && action.data.masterCrud.PaperClaimsPrintingMaintenanceInfo.DateOptions.DateOption['@SingleClaimPrompt'] || false
                        }
                    }
                    break;
                }
            case 'TOGGLE_BATCH_CLAIM':
                {

                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            batchClaimReport: action.data.uiData.DateOptions.DateOption['@BatchClaimPrompt']
                        }
                    }
                    break;
                }
            case 'TOGGLE_SINGLE_CLAIM':
                {

                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            singleClaimReport: action.data.uiData.DateOptions.DateOption['@SingleClaimPrompt']
                        }
                    }
                    break;
                }
            case 'UPDATE_VIEW_EDIT_DATE':
                {

                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            viewEditDate: action.data.uiData.DateOptions.DateOption['@CMSViewDate']
                        }
                    }
                    break;
                }
            case 'UPDATE_LOCAL_PRINT_DATE':
                {

                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            localPrintDate: action.data.uiData.DateOptions.DateOption['@CMSPrintDate']
                        }
                    }
                    break;
                }
            case 'UPDATE_CLS_PRINT_DATE':
                {

                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            CLSPrintDate: action.data.uiData.DateOptions.DateOption['@CMSClsPrintDate']
                        }
                    }
                    break;
                }
            case 'UPDATE_PRINT_TYPE':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            printOptionList: action.data.uiData.printOptionList
                        }
                    }
                    break;
                }
            case 'UPDATE_FONT_FACE':
                {

                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            printOptionList: action.data.uiData.printOptionList
                        }
                    }
                    break;
                }
            case 'UPDATE_FONT_SIZE':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            printOptionList: action.data.uiData.printOptionList
                        }
                    }
                    break;
                }
            case 'SELECT_PRINT_OPTION':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            selectedPrintOption: action.data.uiData.printOption
                        }
                    }
                    break;

                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // const exhaustiveCheck: never = action;
                return defaultState;
        }

    }

    return state || defaultState;
}
