import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "@store/index";
import { ReportCreateRequests } from "@components/Reports/ReportCreate/ReportCreate"

import {
    IMergeApiComponentProps,
    createApiMapStateToProps,
    createApiMapDispatchToProps,
    mergeApiComponentProps,
    resetApiComponentState,
} from "@scripts/util/ApiDataHelpers";

import {
    IRSRTFilterState,
    IRSRTFilterActions,
    IRSRTFilterActionProps,
    actionCreators /*, validationCallback */,
} from "@store/RSRTFilter";

import {
    RSRTFilter,
    IRSRTFilterComponentProps,
} from "@components/Claims/Filter/RSRTFilter";

export interface IRRTFilterFactoryComponentState { }

//---------------------------------------------------------------

export const DEFAULT_STATE: IRRTFilterFactoryComponentState = {};

type IOwnProps = IRSRTFilterComponentProps & RouteComponentProps<{}>;

type IRSRTFilterProps = IMergeApiComponentProps<
    IRSRTFilterState,
    IRSRTFilterActionProps,
    IOwnProps
>;

export class RRTFilterFactory extends React.Component<
    IRSRTFilterProps,
    IRRTFilterFactoryComponentState
> {
    static defaultProps: IRSRTFilterComponentProps = {
        canEdit: true,
        canView: true,
        canCreate: true,
        ncsIsEnabled: false,
        process: "",
        parameter: {},
        apiType: "",
        title: "",
        executeJS: undefined,
        execNode: undefined,
        execAction: undefined,
        execProcess: undefined,
        extraInstruction: "",
        //claimStateRestrictions: ['', '1', '3', '11'],
    };

    constructor(props: IRSRTFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log("user did not have rights to " + this.props.title);
            this.props.history.push("/LandingPage");
        }
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onOk = (baseThis: any, param1: any): boolean => { 
        return true;
    };

    public doesParameterContainReportRequestValue(parameter: any, values: string[]): boolean {
        var result = false;
        if (parameter && parameter.ReportRequests && parameter.ReportRequests.ReportRequest &&
            Array.isArray(parameter.ReportRequests.ReportRequest)) {
            var findResult = parameter.ReportRequests.ReportRequest.find((obj: any) => {
                return values.indexOf(obj['@ID']) > -1;
            });
            result = findResult !== undefined;

        }
        return result;
    }

    public filterFactory = (props: any) => {
        const clone = { ...RRTFilterFactory.defaultProps, ...props };

        // console.clear();
        // console.log(props.location.pathname);

        switch (props.location.pathname) {
            case "/Claims/RSRTFilter/Custom/ViewEdit/RRT":

                clone.apiType = "RSRTFilter";
                clone.title = props.location.state?.title ? props.location.state.title:  "View/Modify Regulatory Reporting Claims";
                clone.process = props.location.state?.process ? props.location.state.process :"rsrtcustom";
                clone.execNode = props.location.state?.execNode ? props.location.state.execNode : 'Report';
                clone.extraInstruction = props.location.state?.extraInstruction ? props.location.state.extraInstruction : "Click Continue to create the report without viewing the claim list.";
                clone.execAction =  props.location.state?.execAction ? props.location.state.execAction : undefined;
                clone.parameter = props.location.state?.parameter ? props.location.state.parameter : {};
                if (this.doesParameterContainReportRequestValue(clone.parameter, ['2505'])) {
                    clone.instruction =
                        "When you turn on an option, all claims that match will be in your working group. Turn on options and enter information until you have created the most precise set of requirements. Click Refine to check the group of claims before you continue."
                }
                break;
            case "/Claims/RSRTFilter/ViewEdit/RRT":
                clone.apiType = "RSRTFilter";
                clone.title = "View/Modify Regulatory Reporting Claims";
                clone.process = "viewedit/rrt";
                clone.execNode = 'viewedit/rrt';
                clone.instruction =
                    "When you turn on an option, all claims that match will be in your working group. Turn on options and enter information until you have created the most precise set of requirements. Click Refine to check the group of claims before you continue."
                break;
            case "/Claims/RSRTFilter/Validate/RRT":
                clone.apiType = "RSRTFilter";
                clone.title = "Validate Regulatory Reporting Claims";
                clone.process = "validate/rrt";
                clone.execNode = 'MultiClaim';
                clone.execAction = 'ValidationRRT';
                clone.execProcess = 'Revalidate';
                clone.instruction =
                    "When you turn on an option, all claims that match will be in your working group. Turn on options and enter information until you have created the most precise set of requirements. Click Refine to check the group of claims before you continue.";
                break;
            case "/Claims/RSRTFilter/Delete/RRT":
                clone.apiType = "RSRTFilter";
                clone.title = "Delete Regulatory Reporting Claims";
                clone.process = "delete/rrt";
                clone.execNode = 'MultiClaim';
                clone.execAction = 'DeleteRRTClaim';
                clone.execProcess = 'Delete/rsrt';
                clone.instruction =
                    "When you turn on an option, all claims that match will be in your working group. Turn on options and enter information until you have created the most precise set of requirements. Click Refine to check the group of claims before you continue.";
                break;
            default:
                break;
        }

        return clone;
    };

    public render() {
        const props = this.filterFactory(this.props);

        return (
            <div>
                <RSRTFilter {...props} />
            </div>
        );
    }
}

const connectedHoc = connect<
    IRSRTFilterState,
    IRSRTFilterActionProps,
    IOwnProps,
    IRSRTFilterProps,
    ApplicationState
>(
    createApiMapStateToProps("rsrtFilter"),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(RRTFilterFactory);

export default withRouter(connectedHoc);
