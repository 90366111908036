import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ClaimInquiryFormUI from './ui/ClaimInquiryFormUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IClaimInquiryFormState = ICrudComponentState<MCCIF_ClaimInquiryFormType, ClaimInquiryFormUI.IClaimInquiryFormUIState>;

export const actionCreators = createCrudComponentActions<ClaimInquiryFormUI.ActionCreators>(ClaimInquiryFormUI.actionCreators);
export type IClaimInquiryFormActions = typeof actionCreators;
export type IClaimInquiryFormActionProps = ICrudComponentActionProps<IClaimInquiryFormActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiCreateCIF, ClaimInquiryFormUI.reducer);
export const reducer = combineReducers<IClaimInquiryFormState>(reducers);

