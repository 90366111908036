import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '../scripts/util/ApiDataHelpers';
import * as SiteFileStore from './api/SiteFileStore';


export type ISiteFileState = IApiComponentState<any, SiteFileStore.ISiteFileUIState>;

export const actionCreators = createApiComponentActions<SiteFileStore.ActionCreators>(SiteFileStore.actionCreators);
export type ISiteFileActions = typeof actionCreators;
export type ISiteFileActionProps = IApiComponentActionProps<ISiteFileActions>

export const reducers = createApiComponentReducers("siteFile", SiteFileStore.reducer);
export const reducer = combineReducers<ISiteFileState>(reducers);