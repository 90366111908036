import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as DeleteFilterUI from './ui/DeleteFilterUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IDeleteFilterState = ICrudComponentState<MCClaimNoteType, DeleteFilterUI.IDeleteFilterUIState>;

export const actionCreators = createCrudComponentActions<DeleteFilterUI.ActionCreators>(DeleteFilterUI.actionCreators);
export type IDeleteFilterActions = typeof actionCreators;
export type IDeleteFilterActionProps = ICrudComponentActionProps<IDeleteFilterActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimNotes, DeleteFilterUI.reducer);
export const reducer = combineReducers<IDeleteFilterState>(reducers);

export interface IClaimFilterResponse {
    status: number,
    data?: string,
}