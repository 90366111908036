import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { FilterRegenerate } from '../../components/Reports/FilterRegenerate/FilterRegenerate';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IVBLogUIState {
    selectDate?: string;
    logData?: VBLogsData;
    logDataFull?: VBLogsData;
    clientData: CData[];
    selectClient?: string;
    dateData?: DateDataDays;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface ISelectedData {
    index: number;
    value: string;
    text: string;
};

export const defaultOperation: ISelectedData = {
    index: 0,
    value: '',
    text: '',
}

export interface IViewBridgeLogData {
    value: VBLogsData;
};

export const defaultViewBridgeLogData: IViewBridgeLogData = {
    value: {
        'errorfile': {
            'errorline': []
        }
    }
}

export interface IDateData {
    value: DateDataDays; 
}

export const defaultDateData: IDateData = {
    value: {
        'Days': {
            'Day': [],
            '@Count': ""
        }
    }
}

export interface IClientDataDetail {
    value: CData[];
};
const results: CData[] = [];

export const defaultClientData: IClientDataDetail = {
    value: results
}

export interface ISelectOperation extends ICrudActionData<VBLog, ISelectedData> { }
export interface ILogData extends ICrudActionData<VBLogsData, IViewBridgeLogData> { }
export interface IClientData extends ICrudActionData<VBLogsData, IClientDataDetail> { }
export interface IDateDataDetail extends ICrudActionData<DateData, IDateData> { }

export const actionCreators = {
    selectDate: (selectInfo: ISelectOperation) => createDataAction('SELECT_DATE', selectInfo),
    selectClient: (selectInfo: ISelectOperation) => createDataAction('SELECT_CLIENT', selectInfo),
    getLogData: (logData: ILogData) => createDataAction('GET_LOG_DATA', logData),
    getLogFullData: (logData: ILogData) => createDataAction('GET_LOG_FULL_DATA', logData),
    getClientData: (clientData: IClientData) => createDataAction('GET_CLIENT_DATA', clientData),
    getDateData: (dateDate: IDateDataDetail) => createDataAction('GET_DATE_DATA', dateDate),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IVBLogUIState = {
    selectDate: '',
    logData: {
        'errorfile': {
            'errorline': []
        }
    },
    logDataFull: {
        'errorfile': {
            'errorline': []
        }
    },
    selectClient: '',
    clientData: [],
    dateData: {
        'Days': {
            'Day': [],
            '@Count': ""
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IVBLogUIState, KnownActions> = (state: IVBLogUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_DATE':
                return {
                    ...state,
                    selectDate: action.data.uiData.value,
                }
            case 'SELECT_CLIENT':
                return {
                    ...state,
                    selectClient: action.data.uiData.value,
                }
            case 'GET_CLIENT_DATA':
                return {
                    ...state,
                    clientData: action.data.uiData.value,
                }

            case 'GET_DATE_DATA':
                let dateValue = (new Date().getMonth() + 1) + "/" + (new Date().getDate()) + "/" + (new Date().getFullYear());
                let dataForDate = action.data.uiData.value;
                let filteredData = dataForDate.Days?.Day.filter((item) => {
                    return item["@Value"] === dateValue;
                })
                if (filteredData !== undefined && filteredData !== null && filteredData.length === 0) {
                    dataForDate.Days?.Day.push({ "@Value": dateValue });
                }
                let dayValues = dataForDate.Days.Day.reverse();
                dataForDate.Days.Day = dayValues;
                return {
                    ...state,
                    dateData: dataForDate,
                    selectDate: action.data.uiData.value.Days.Day[0]["@Value"]
                }
            case 'GET_LOG_DATA':
                let logDataValue = JSON.parse(JSON.stringify(action.data.uiData.value));
                if (logDataValue !== undefined && logDataValue !== null) {
                    if (logDataValue.errorfile !== "") {
                        if (logDataValue.errorfile?.errorline.length === undefined) {
                            let obj = {
                                "date": logDataValue.errorfile?.errorline.date,
                                "time": logDataValue.errorfile?.errorline.time,
                                "process": logDataValue.errorfile?.errorline.process,
                                "thread": logDataValue.errorfile?.errorline.thread,
                                "client": logDataValue.errorfile?.errorline.client,
                                "severity": logDataValue.errorfile?.errorline.severity,
                                "description": logDataValue.errorfile?.errorline.description
                            }
                            logDataValue.errorfile.errorline = [];
                            logDataValue.errorfile.errorline.push(obj);
                        }
                        logDataValue.errorfile?.errorline?.map((item: any) => {
                            if (!isNaN(item.severity)) {
                                item.severity = item.severity === "1" ? "High" : item.severity === "2" ? "Med" : "Low";
                            }
                        })
                    }
                }
                return {
                    ...state,
                    logData: logDataValue
                }
            case 'GET_LOG_FULL_DATA':
                let logDataFullValue = JSON.parse(JSON.stringify(action.data.uiData.value));
                if (logDataFullValue !== undefined && logDataFullValue !== null) {
                    if (logDataFullValue.errorfile !== "") {
                        if (logDataFullValue.errorfile?.errorline.length === undefined) {
                            let obj = {
                                "date": logDataFullValue.errorfile?.errorline.date,
                                "time": logDataFullValue.errorfile?.errorline.time,
                                "process": logDataFullValue.errorfile?.errorline.process,
                                "thread": logDataFullValue.errorfile?.errorline.thread,
                                "client": logDataFullValue.errorfile?.errorline.client,
                                "severity": logDataFullValue.errorfile?.errorline.severity,
                                "description": logDataFullValue.errorfile?.errorline.description
                            }
                            logDataFullValue.errorfile.errorline = [];
                            logDataFullValue.errorfile?.errorline?.push(obj);
                        }
                        logDataFullValue.errorfile?.errorline?.map((item: any) => {
                            if (!isNaN(item.severity)) {
                                item.severity = item.severity === "1" ? "High" : item.severity === "2" ? "Med" : "Low";
                            }
                        })
                    }
                }
                return {
                    ...state,
                    logDataFull: logDataFullValue
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                return state;
        }
    }

    return state || defaultState;
}
