export const forceToArray = (input: any) => {
    if (Array.isArray(input)) {
        return input
    } else if (typeof input === 'object') {
        return [input]
    } else {
        return []
    }
}


export const getGraphLegendData = (item: any) => {

    const doesExist = (accumulator: any, currentValue: any, key: any) => accumulator.map((a: any) => a[key]).includes(currentValue[key])

    const uniqueLegendData = item.data.reduce((accumulator: any, currentValue: any) => {
        return [...accumulator, ...currentValue.dataPoints]
    }, []).reduce((accumulator: any, currentValue: any) => {
        if (doesExist(accumulator, currentValue, "label") && doesExist(accumulator, currentValue, "color")) {
            return accumulator;
        } else {
            return [...accumulator, currentValue];
        }
    }, []);

    return uniqueLegendData.map((uld: any) => {
        return {
            dataPointType: uld.dataPointType,
            name: uld.label,
            symbol: {
                fill: uld.color,
                type: "square"
            }
        }
    });
}


export const getGraphBarData = (item: any, graphIndex: number = 0) => {

    // Get labels
    let labels = item.data.map((el: any) => {
        if (el.text) {
            return { x: el.text }
        }
    })

    // Extract y-value for each corresponding label
    let values = labels.map((el: any, index: number) => {

        if (item.data[index].dataPoints) {
            let filtered = item.data[index].dataPoints.filter((point: any) => point.dataPointType === "Bar");
            if (filtered.length) {
                el.y = filtered[0].value;
                el.actionPayload = filtered[0].filter;
                el.tooltip = filtered[0].toolTip;
                el.fill = filtered[0].color;
                el.id = filtered[0].id;
                return el;
            }
        }
    })

    // Note: if there is no bar data it will return an array of undefined so don't emit data
    //return mockBarData;
    return values[0] === undefined ? [] : values;
}

    // NOTE: item passed in is a chart
export const getGraphLineData = (item: any, index: number = 0) => {

    // The array to collect labels
    let myArray: any = [];

    let data = item.data;

    if (data && data.length) {
        data.map((d: any) => {
            //console.log(`The label is: ${d.text}`);
            myArray.push({ x: d.text, y: (d.dataPoints && d.dataPoints.length) && d.dataPoints.filter((point: any) => point.dataPointType === "Line") });
        })
    }
    // The Y value now contains an array of datapoints,
    // if it is greater than 1 then clone it and nest
    let loopValue = (!!myArray[0] && !!myArray[0].y) ? myArray[0].y.length : 0;

    let newArray = [];

    for (let i = 0; i < loopValue; i++) {
        let tmp = new Array();
        for (let j = 0; j < myArray.length; j++) {
            tmp[j] = {
                x: myArray[j].x,
                y: myArray[j].y[i].value,
                actionPayload: myArray[j].y[i].filter,
                tooltip: myArray[j].y[i].toolTip,
                fill: myArray[j].y[i].color,
                id: myArray[j].y[i].id
            }
        }
        newArray.push(tmp);
    }

    // the line chart wants at least 1 array in the newArray:
    if (newArray.length === 0) {
        newArray.push([])
    }

    //console.log("The line data array is: ", newArray);

    return newArray;
}