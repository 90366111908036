import { URLs } from '@commonDevResources/constants';
import { getRawToken } from "../../scripts/session/SecurityToken";

export class MessagesAPI {
    async getMessages(type: string): Promise<MessagesType> {
        const url = URLs.api + '/api/data/AsyncMessages?msgOptions=' + type.toUpperCase();
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
        });
        const data: any = await response.json();
        if (response.status === 306) {
            console.error(data); // debuke says: why this 306? PASSWORD RESET CODE: 306...
        }
        let messagesType = data as MessagesType;
        messagesType.messageList.forEach(x => {
            x.messageDateTimeFormatted = this.formatDateTime(x.messageDateTime);
        });

        return messagesType;
    }

    async markAsRead(messageIds: string[]) {
        const url = URLs.api + '/api/data/AsyncMessages/markRead';
        const body = JSON.stringify({ messageIds: messageIds });
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body
        });
        const data: any = await response.json();
        if (response.status === 306) console.error(data);
    }

    async deleteMessages(deleteType: string, messageIds: string[]): Promise<void> {
        const url = URLs.api + '/api/data/AsyncMessages/delete';
        const body = this.buildDeletePostBody(deleteType, messageIds);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body
        });

        const data: any = await response.json();
        if (response.status === 306) console.error(data);
    }

    buildDeletePostBody(deleteType: string, messageIds: string[]) {
        const postBody =
        {
            // REQUIRED:All|Client|User|Selected
            'deleteType': deleteType, 
            // Required if using "Selected"
            'messageIds': messageIds
        }
        return JSON.stringify(postBody);
    }

    formatDateTime(messageDateTime: Date) {
        const date = new Date(messageDateTime);
        const month = date.toLocaleString('default', { month: 'long' });
        const day = date.getDate();
        const year = date.getFullYear();
        const time = date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        const messageDateTimeFormatted = `${month} ${day} ${year} ${time}`;
        return messageDateTimeFormatted;
    }
}
