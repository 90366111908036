//--------------------------------------------------------------------------------------------------------------
//   Function           : IsPasswordValid
//   Purpose            : This function will check to make sure that the password adheres to the security
//                        policies.
//   Parameter          : strPassword - This is the value of the password.
//						  intLength - This is the minimum length.
//						  bolForceAlphaNum - This is to force alphanumeric.
//                        bolForceUpperLower -This is to force at least one upper and one lower case char.
//                        bolForceSpecialChar- This is to force at least one special character.
//
//--------------------------------------------------------------------------------------------------------------
export function IsPasswordValid(strPassword: string, intLength: number, bolForceAlphaNum: number, bolForceUpperLower: number, bolForceSpecialChar: number) {
	let validationErrorMsgs: string[] = [];
	// check to make sure new password meets length requirements
	if (strPassword.length < intLength || strPassword.length > 64) {
		//alert("Your password must be between " + intLength + " and 64 characters in length.");
		//return false;
		validationErrorMsgs = [...validationErrorMsgs, "Must be between " + intLength + " and 64 chars in length"];
	}

	if (bolForceAlphaNum && bolForceAlphaNum === 1 && strPassword.search(/\d/) == -1) {
		//alert("Your password must contain at least one number.");
		//return false;
		validationErrorMsgs = [...validationErrorMsgs, "Must be at least 1 number 0-9"];
	}
	// check to make sure new password meets upper/lower case requirements
	if (bolForceUpperLower && bolForceUpperLower === 1 && (strPassword.search(/[a-z]/) == -1 || strPassword.search(/[A-Z]/) == -1)) {
		//alert("Your password must contain at least one uppercase and one lowercase letter.");
		//return false;
		validationErrorMsgs = [...validationErrorMsgs, "Must be at least 1 uppercase \nand 1 lowercase char A-Z"];
	}
	// check to make sure new password meets special char requirements
	if (bolForceSpecialChar && bolForceSpecialChar === 1 && strPassword.search(/[$\~\`\!@#\$%\^&\*\(\)_\+\-=\|<>\?]/) == -1) {
		//alert("Your password must contain at least one special character(i.e. $,#,&,^...etc.).");
		//return false;
		validationErrorMsgs = [...validationErrorMsgs, "Must be at least 1 special char: \n~ ` ! @ # $ % ^ & * ( ) _ + - = | < > ?"];
	}

	//return true;
	return validationErrorMsgs;
}