import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IDeleteFilterUIState {
    selectedFilter: ISelectFilterData;
    submitNCSAlert?: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectFilterData {
    index: number;
    value: string;
    text: string;
};

export const defaultFilter: ISelectFilterData = {
    index: 0,
    value: '',
    text: '',
};

export interface ISelectFilter extends ICrudActionData<MCClaimNoteType, ISelectFilterData>{ }
export interface IModifyFilter extends ICrudActionData<MCClaimNoteType, MCNoteType> { }

export const actionCreators = {
    selectFilter: (selectInfo: ISelectFilter) => createDataAction('SELECT_FILTER', selectInfo),
    deleteFilter: (updateInfo: IModifyFilter) => createDataAction('DELETE_FILTER', updateInfo),
    sendSubmitNCSAlert: (selectInfo: ISelectFilter) => createDataAction('NCS_ALERT', selectInfo),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IDeleteFilterUIState = {
    selectedFilter: defaultFilter,
    submitNCSAlert: '',
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IDeleteFilterUIState, KnownActions> = (state: IDeleteFilterUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_FILTER':
            {
                if (action.data.masterCrud) {
                    //uiData.index is the UI-based index from the Select component; it may not be a 1:1 match to the index of the array of items in CRUD since some might have @Deleted = 1.
                    //Need to look up by text since new items could have been added with an @ID not yet set.
                    let noteTypeData = action.data.masterCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.find(existingNoteType => existingNoteType["@Name"] === action.data.uiData.text);
                    return {
                        ...state,
                        selectedNoteType: action.data.uiData,
                        submitNCSAlert: undefined,
                    }
                }
                break;
            }
            case 'DELETE_FILTER':
            {
                    if (action.data.masterCrud) {
                        let selectInfo: ISelectFilterData = state.selectedFilter;
                        let newIndex: number = selectInfo.index;
                        let uiIndex: number = 0;
                        let newCrudIndex: number | undefined = undefined;
                        let removed: boolean = false;
                        let newIndexSet: boolean = false;
                        action.data.masterCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.forEach((existingNoteType, crudIndex) => {
                        if (newIndexSet)
                        {
                            return;
                        }
                        if (existingNoteType["@Name"] === selectInfo.text) {
                            existingNoteType["@Deleted"] = '1';
                            removed = true;
                        }
                        else if (existingNoteType["@Deleted"] !== '1') {
                            newCrudIndex = crudIndex;
                            uiIndex++;
                            if (removed) {
                                newIndexSet = true;
                            }
                        }
                        
                    });
                    return {
                        ...state,
                        selectedNoteType: {
                            index: uiIndex,
                            value: newCrudIndex ? action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[newCrudIndex]["@ID"] : '',
                            text: newCrudIndex ? action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[newCrudIndex]["@Name"] : '',
                        },
                        noteTypeName: newCrudIndex ? action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[newCrudIndex]["@Name"] : '',
                        noteTypeDescription: newCrudIndex ? action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[newCrudIndex]["@Description"] : '',
                    };
                }
                break;
            }
            break;
            case 'NCS_ALERT':
                return {
                    ...state,
                    submitNCSAlert: action.data.uiData.value,
                }
            break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
