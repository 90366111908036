import * as React from "react";
import styled from "styled-components";
import { connect, useSelector, useDispatch } from "react-redux";
import { ApplicationState } from "@store/index";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Input, IconTile, Button } from "ui-core";

import {
  ActionCreators,
  actionCreators,
  selectors,
  Selection,
  IDashboardAssignedClaimsState,
} from "@store/OperationalDashboard/DashboardAssignedClaims";
import { AssignedClaimsDataSelection } from "./DataSelection/AssignedClaimsDataSelectionComponent";
import ClaimsTypeFilter from "./AssignedClaimsFilter";
import AssignClaimsDetailGrid from "./ClaimsDetails";
import UserSelection from "./DataSelection/UserComboSelection";
import { DownloadDocumentPt2 } from "@commonResources/window";
import { CommonGraph } from "@commonResources/commonGraphing/CommonGraph";
import { HtmlTooltip } from "@commonResources/commonGraphing/HtmlTooltip";
import { ARMGrid } from "@common/UICLWrappers/ARMGrid";
import { ARMHeaderCell } from "@common/UICLWrappers/ARMHeaderCell";
import { CheckBoxComponent } from "@common/CheckBox";
import { DialogWrapper, OKCancelButtons } from "@common/DialogWrapper";
import { Colors } from "@commonResources/commonGraphing/Colors";
import { ModalConfirmation } from "@common/ModalConfirmation";
import { dollarFormatter } from "./utils/formatter";

export const SampleComponentDiv = styled.div`
  border: solid black 1px;
  margin: 10px;
  padding: 10px;
`;

const GraphWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const GraphContainer = styled.div`
  width: 600px;
  height: 100%;
  background-color: none;
  display: flex;
  justify-content: center;
`;

const Name = styled("div")`
  padding-bottom: 0.2em;
  text-transform: uppercase;
  font-size: 11px;
`;

const Data = styled("div")`
  font-size: 9px;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Label = styled("div")`
  font-weight: bold;
  color: ${Colors.blue30};
`;

const Value = styled("div")`
  color: white;
`;

const GridTitle = styled("div")`
  font-weight: bold;
  display: flex;
  justify-content: center;
  font-size: 20px;
  padding-bottom: 10px;
`;

const GridWrapper = styled.div`
  #grid-wrapper-workgroup-grid {
    max-height: 600px;
    background-color: white;
  }

  thead tr th {
    background-color: #9ba1a9;
    height: 12px;
    padding-right: 0px !important;
  }

  th.User > span {
    padding-left: 5px !important;
  }

  th.TotalClaims {
    min-width: 150px;
    max-width: 150px;
  }

  th.TotalClaims > span {
    text-align: right;
    padding-right: 0px !important;
  }

  th.TotalClaims {
    min-width: 150px;
    max-width: 150px;
  }

  th.Amount > span {
    text-align: right;
    padding-right: 0px !important;
  }

  table tbody tr > td {
    height: 12px !important;
    line-height: 15px;
  }

  table tbody tr td div {
    min-height: 12px;
  }

  .User {
    min-width: 250px;
    max-width: 250px;
    overflow: hidden;
    padding-left: 5px !important;
  }

  .TotalClaims {
    min-width: 150px;
    max-width: 150px;
    direction: rtl;
    padding-right: 0px !important;
  }

  .Amount {
    min-width: 150px;
    max-width: 150px;
    direction: rtl;
    padding-right: 0px !important;
  }
`;

const ErrorModalMgsWrapper = styled("div")`
  display: flex;
  justify-content: center;
`;

interface IComponentProps {
  canView: boolean;
  canEdit: boolean;
  pageWidth: number;
  pageHeight: number;
}

interface IComponentState {
  chartTitle: string;
  yLabel: string;
  selectedClaimsData: any[];
  selectedClaimUser: string;
  selectedIndex: number;
  myGroups: any;
}

export const DEFAULT_STATE: IComponentState = {
  chartTitle: "WorkLoad",
  yLabel: "Claims To Be Reviewed",
  selectedClaimsData: [],
  selectedClaimUser: "",
  selectedIndex: 0,
  myGroups: null,
};

type GraphDataFormattedItem = {
  x: string;
  y: number | undefined;
  id: number;
  fill: string;
  tooltip: {
    name: string;
    totalClaims: string;
    released: string;
    unreleased: string;
  };
  actionPayload: {
    userId: any;
  };
};

type GraphDataFormatted = GraphDataFormattedItem[];

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IAssignedClaimsProps = ActionCreators &
  IDashboardAssignedClaimsState &
  IOwnProps;

// TODO: pass down with as props
class DashboardAssignedClaims extends React.Component<
  IAssignedClaimsProps,
  IComponentState
> {
  readonly deptTableStyle: React.CSSProperties = {
    width: "100%",
    padding: "5px",
    background: "#EBECED",
  };

  readonly deptTabelHeaderStyle: React.CSSProperties = {
    backgroundColor: "#9ba1a9",
    padding: "6px",
    color: "white",
  };

  readonly deptTableHeaderCellStyle: React.CSSProperties = {
    padding: "10px",
    textAlign: "left",
    paddingLeft: "25px",
    fontSize: "12px",
  };

  readonly spanStyle: React.CSSProperties = {
    fontSize: "14px",
    padding: "6px",
  };

  readonly countSpanStyle: React.CSSProperties = {
    fontSize: "14px",
    padding: "6px",
    float: "right",
  };
  readonly amountSpanStyle: React.CSSProperties = {
    fontSize: "14px",
    padding: "6px",
    float: "right",
  };
  static defaultProps: any = {
    canView: false,
    canEdit: false,
    title: "",
  };

  constructor(props: any) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  public componentDidMount() {
    const { location } = this.props;

    if (location.search !== "?cfi") {
      this.props.getAllWorkgroups();
      this.getInitialGraphData();
    }
  }

  public selectOwnGroup() {
    let { workGroupData, workGroupSummaryData, selectedGroup, selectGroup } =
      this.props;

    if (workGroupData && workGroupSummaryData) {
      let myGroupList = workGroupData
        .filter((d: any) => {
          if (d.owner !== null) {
            return (
              Number(d.owner.id) === Number(workGroupSummaryData[0].userId)
            );
          }
        })
        .map((d: any) => {
          return { label: d.name, value: "" + d.id };
        })
        .reverse();

      const selection =
        myGroupList !== undefined ? myGroupList[0] : selectGroup;

      return selection;
    } else {
      return {};
    }
  }

  public componentDidUpdate(prevProps: any) {
    const { getWorkgroupSummaryInit, getWorkgroupSummary, selectedGroup } =
      this.props;

    if (prevProps.selectedGroup !== selectedGroup) {
      if (selectedGroup.label === "My Claims") {
        getWorkgroupSummaryInit();
        const title = `My Workload`;
        this.setState({
          chartTitle: title,
        });
      } else {
        getWorkgroupSummary(selectedGroup.value);
        const title = `${selectedGroup.label} Workload`;
        this.setState({
          chartTitle: title,
        });
      }
    }
    if (document.querySelector("#Claim-Summary-Grid > tbody tr")) {
      // document.querySelector('#Claim-Summary-Grid > tbody tr').classList.add('selected');
    }
  }

  public getInitialGraphData() {
    if (this.props.selectedGroup.value == "-1") {
      this.props.getWorkgroupSummaryInit();
    }
  }
  public updateDepartment(e: React.ChangeEvent<HTMLInputElement>, index: any) {
    this.setState({ selectedIndex: index });
  }

  //event for updateDepartmentSpan
  public updateDepartmentSpan(
    e: React.ChangeEvent<HTMLInputElement>,
    dept: any
  ) {
    const { name, value } = e.target;
  }

  setSelectedClaimsData = (userId: any) => {
    let data = this.props.workGroupSummaryData.filter(
      (summaryData: any) => summaryData.userId === userId
    );
    let selectedClaimsData: any[] = [
      {
        claims: "Total Claims",
        count: data[0].totalClaims,
        amount: data[0].totalClaimAmount,
        selected: "5",
      },
      {
        claims: "Released Claims",
        count: data[0].releasedClaims,
        amount: data[0].releasedClaimAmount,
        selected: "7",
      },
      {
        claims: "Unreleased Claims",
        count: data[0].unreleasedClaims,
        amount: data[0].unreleasedClaimsAmount,
        selected: "8",
      },
    ];
    this.setState({
      selectedClaimsData: selectedClaimsData,
      selectedClaimUser: data[0].userName,
      selectedIndex: 0,
    });
    this.props.selectUser({
      userId: userId,
      groupId: this.props.selectedGroup.value,
    });
  };

  handleWorkgroupGridClick = (e: any, record: []) => {
    this.setSelectedClaimsData(record["UserId"]);
    this.props.getClaimsDetails(record["UserId"]);
  };

  workgroupGridColumns = () => {
    return new Set([
      {
        dataName: "User",
        text: "User",
        sortable: true,
        cellType: "text",
        isSorted: 1,
      },
      {
        dataName: "TotalClaims",
        text: "Total Claims",
        cellType: "text",
        isSorted: 0,
      },
      {
        dataName: "Amount",
        text: "Amount",
        cellType: "text",
        isSorted: 0,
      },
    ]);
  };

  public render() {
    const {
      workGroupData,
      exportToCsvPath,
      pageWidth,
      uiLock,
      selectGroup,
      selectedView,
      workGroupSummaryData,
      filterInput,
    } = this.props;
    let groupList: Selection[] = [];
    let selectedGroup: Selection = this.props.selectedGroup;

    if (workGroupData !== undefined) {
      if (!this.state.myGroups && workGroupData && workGroupSummaryData) {
        const myGroups = this.selectOwnGroup();
        this.setState({ myGroups: myGroups ? myGroups : {} });
      }

      groupList = workGroupData.map((d: any) => {
        return { label: d.name, value: "" + d.id };
      });

      if (
        selectedGroup.value === "-1" &&
        workGroupData.length &&
        this.state.myGroups
      ) {
        if (Object.keys(this.state.myGroups).length) {
          selectGroup(this.state.myGroups);
        } else {
          const myClaimsSelection = groupList.find((g: Selection) => {
            return g.label === "My Claims";
          });

          if (myClaimsSelection !== undefined) {
            selectedGroup = myClaimsSelection;
          }
          if (uiLock === "Unlocked" && exportToCsvPath === "") {
            selectGroup(selectedGroup);
          }
        }
      }
    }

    let graphData = workGroupSummaryData ?? [];

    let gridData = workGroupSummaryData ?? [];
    const gridDataFormatted: [] = gridData.map((item: any, index: any) => {
      return {
        UserId: item.userId,
        User: item.userName,
        TotalClaims: item.totalClaims
          .toString()
          .replace(/(.)(?=(\d{3})+$)/g, "$1,"),
        Amount: dollarFormatter(Number(item.totalClaimAmount)),
      };
    });

    let hasSingleClaimValue = true;

    if (
      workGroupSummaryData === undefined ||
      (workGroupSummaryData.length === 1 &&
        workGroupSummaryData[0].totalClaims === 0)
    ) {
      hasSingleClaimValue = false;
    }

    const dataLength = graphData.length;

    const smallerWidth = graphData.length > 3 ? "650px" : "460px";

    //if (dataLength === 1) {
    //    graphData = [...graphData,
    //        {
    //            releasedClaimAmount: "0.0000",
    //            releasedClaims: "",
    //            totalClaimAmount: "0.0000",
    //            totalClaims:"",
    //            unreleasedClaims: "",
    //            unreleasedClaimsAmount: "0.0000",
    //            userId: "",
    //            userName: "",
    //        }
    //    ];
    //}

    const graphDataFormatted: GraphDataFormatted = graphData.map(
      (item: any, index: any) => {
        let container = {
          x: "",
          y: 0,
          id: "",

          tooltip: {
            name: "",
            totalClaims: "",
            released: "",
            unreleased: "",
          },
          actionPayload: { userId: "" },
        };

        let totalClaimAmount = parseFloat(item.totalClaimAmount).toLocaleString(
          "en-US",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        );
        let releasedClaimAmount = parseFloat(
          item.releasedClaimAmount
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        let unreleasedClaimsAmount = parseFloat(
          item.unreleasedClaimsAmount
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        const totalClaims = item.totalClaims;
        container.id = item.userId;
        container.x = item.userName;
        container.y = totalClaims;

        container.tooltip["name"] = item.userName;
        container.tooltip[
          "totalClaims"
        ] = ` ${item.totalClaims} for $${totalClaimAmount}`;
        container.tooltip[
          "released"
        ] = ` ${item.releasedClaims} for $${releasedClaimAmount}`;
        container.tooltip[
          "unreleased"
        ] = ` ${item.unreleasedClaims} for $${unreleasedClaimsAmount}`;
        container.actionPayload["userId"] = item.userId;

        return container;
      }
    );

    const isErrorModalOpen =
      this.props.uiLock === "Error: something went wrong" ? true : false;

    return (
      <React.Fragment>
        <AssignedClaimsDataSelection
          groupList={groupList}
          selectedGroup={selectedGroup}
          selectedView={selectedView}
          onExportToCsv={() => DownloadDocumentPt2(exportToCsvPath)}
          onSelectGroup={(e: Selection) => this.props.selectGroup(e)}
          onSelectView={(e: Selection) => this.props.selectView(e)}
          enabled={!!uiLock}
        />
        <GraphWrapper>
          <GraphContainer style={{ width: smallerWidth }}>
            {dataLength > 0 &&
              hasSingleClaimValue &&
              selectedView.value == "charts" && (
                <CommonGraph
                  title={this.state.chartTitle || "Chart Title"}
                  data={graphDataFormatted}
                  graphType={"bar"}
                  colorScheme={"random100"}
                  label={this.state.yLabel || "Y label"}
                  barClickHandler={(actionPayload) => {
                    const id = actionPayload.userId;

                    this.setSelectedClaimsData(id);
                    this.props.getClaimsDetails(id);
                  }}
                  tooltipData={{
                    tooltipWidth: 200,
                    tooltipOffsetX: 20,
                    tooltipOffsetY: 30,
                    getContent: (tooltipData: any) => {
                      const { name, totalClaims, released, unreleased } =
                        tooltipData;
                      return (
                        <>
                          <Name>{name}</Name>
                          <Data>
                            <Label>Total Claims: </Label>
                            <Value>{totalClaims}</Value>
                          </Data>
                          <Data>
                            <Label>Released: </Label>
                            <Value>{released}</Value>
                          </Data>
                          <Data>
                            <Label>Unreleased: </Label>
                            <Value>{unreleased}</Value>
                          </Data>
                        </>
                      );
                    },
                  }}
                />
              )}
            {dataLength > 0 &&
              hasSingleClaimValue &&
              selectedView.value == "lists" && (
                <GridWrapper>
                  <GridTitle>{this.state.chartTitle}</GridTitle>
                  <ARMGrid
                    domID="workgroup-grid"
                    isFixedHeader={true}
                    columns={this.workgroupGridColumns()}
                    records={gridDataFormatted}
                    onClickThrough={(
                      e: React.MouseEvent<HTMLElement>,
                      record: any
                    ) => {
                      this.handleWorkgroupGridClick(e, record);
                    }}
                    selectionKey="User"
                  />
                </GridWrapper>
              )}
            {(dataLength === 0 || hasSingleClaimValue === false) && (
              <h2>No data to show</h2>
            )}
          </GraphContainer>
        </GraphWrapper>

        {this.props.selectedClaimUser && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: pageWidth - 60,
              }}
            >
              <ClaimsTypeFilter />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: pageWidth - 10,
                margin: "8px 0px",
              }}
            >
              <Input
                domID="AssignedClaims-FieldFilter"
                className="text-input"
                label="Search Claims"
                maxLength={40}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  this.props.filterClaimsDetails(e.target.value);
                }}
                initialValue={filterInput}
              />
              <UserSelection />
              <div style={{ marginTop: 20 }}>
                <Button
                  domID="AssignedClaims_UserExportToCsv"
                  name="Export to CSV"
                  buttonType="standard"
                  size="small"
                  type="button"
                  className="previewButton"
                  disabled={this.props.exportToCsvUserPath === ""}
                  onClick={() => {
                    DownloadDocumentPt2(this.props.exportToCsvUserPath);
                  }}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: pageWidth - 60,
              }}
            >
              <AssignClaimsDetailGrid {...this.props} />
            </div>
          </>
        )}
        <ModalConfirmation
          onOK={(e) => {
            this.props.clearUILockError();
          }}
          isOpen={isErrorModalOpen}
          alertMode={true}
          formattedMessage={
            <ErrorModalMgsWrapper>
              An error occurred while trying to get My Work Group data.
            </ErrorModalMgsWrapper>
          }
        />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return state.dashboardAssignedClaims;
}

var connectedHoc = connect<
  IDashboardAssignedClaimsState,
  ActionCreators,
  IAssignedClaimsProps,
  ApplicationState
>(
  mapStateToProps, // Selects which state properties are merged into the component's props
  actionCreators // Selects which action creators are merged into the component's props
)(DashboardAssignedClaims);

export default withRouter(connectedHoc);
