import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IPaperCommandsState, IPaperCommandsActionProps, actionCreators, validationCallback } from '@store/PaperCommands';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper, DialogButton, DialogButtonOK } from '@common/DialogWrapper';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMNarrowDropdown } from '../../common/UICLWrappers/ARMNarrowDropdown';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import FilterableList from "@commonResources/FilterableList/FilterableList";
import { Colors } from "@commonResources/colorVariables";
import { Typography } from '@commonResources/typography';
import { Input, Button, SelectDropdownV2 } from 'ui-core';
import { RadioButtonComponent } from '@common/RadioButton';
import { AddPrintCommandModal } from './AddPrintCommandModal';
import { CheckBoxComponent } from '@common/CheckBox';
import { ModalComponent } from '@common/ModalComponent';
import { AddPayerModal } from './AddPayerModal';
import { AddTypeOfBillModal } from './AddTypeOfBillModal';
import { ARMNarrowInput } from '@common/UICLWrappers/ARMNarrowInput';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IsFieldFilled } from '@commonResources/validations';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';

const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    #select_formtype{
        width: 250px !important;
    }
    #select_papercommandsets{
        width: 250px !important;
    }

    #row_additionalpapercommand{
        height: 740px;
    }

    .Action, .Row, .Occ, Len {
        vertical-align: inherit;
        width:100px;
    }
    .Field, .Value {
        vertical-align: inherit;
        width:156px;
    }
    .Condition {
        vertical-align: inherit;
        width:254px;
    }
`;

const FormTypeContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    width: 282px;
   
    #dialog_formtype {
       margin-left: 1px;
       margin-top: 0px;
    }
    #dialog_papercommandsets {
       margin-left: 1px;
       margin-top: 2px;
    }
    #ddlPaperCommandSets {
       width: 270px !important;
       padding-left: 1px;
       margin-top: 5px;
    }
`;

const PaperCommandContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    min-height: 180px;
    width: 955px; 
    padding: 3px;
    height: 591px;

    div.text-input input[type='text']{
       height: 30px;
    }
`;

export const FilterableListWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ListContainerDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  color: ${Colors.black};
  ${Typography.default};
  ${Typography.normal};
  ${Typography.LetterSpacing};
  ${Typography.fontFamily};

  & select > option {
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
    font-size: 13px !important;
  }

  & label {
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
    ${Typography.uppercase};
  }

    #remitfilter_providernpi_select {
        width:250px !important;
    }
    #remitfilter_providerno {
        width:250px !important;
    }
`;

export const PaperCommandGrid = styled.div`
     height: 332px; 
     width:942px;
     margin-top: 2px;
`;

export const Command = styled.div`
    #command-dialogfieldset {
        height: 387px;
        width: 942px;
        margin-top: 15px;
    }
    #action {
             width: 196px;     
        }
    #field {
             width: 196px;        
        }
    #occurrence {
             width: 196px;        
        }
    #function {
             width: 196px;        
        }
    #value {
             width: 196px;        
        }
    #field-level {
             margin-top: 5px;
             width: 205px;
        }
    #default-dialogfieldset{
            height:206px;
            width:85px;
            margin-left: 4px;
        }
    #red-pdf-dialogfieldset{
            height:263px;
            width:85px;
            margin-left: 7px;
        }
    #gray-pdf-dialogfieldset{
            height:263px;
            width:85px;
            margin-left: 7px;
        }
    #condition-dialogfieldset{
            height:137px;
            width:419px;
            margin-left: 7px;
        }
    #default-level {
            margin-top: 5px;
            width: 72px
        }
    #redpdf-level {
            margin-top: 5px;
            width: 72px
        }
    #graypdf-level {
            margin-top: 5px;
            width: 72px
        }
    #condition-level {
            margin-top: 5px;
            width: 72px;
            height: 30px;
        }
    #payer-radio-button{
            width: 28px;
        }
    .select-label-class{
            height:26px;
        }
    #payer-link{
            margin-top: 5px;
        }
    #payer-ddl label{
        width:82px !important;
}

`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    margin-top:10px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
        font-size: 10px !important;
    }
`;

const LabelDiv = styled.label`
    font-size:12px;
    margin-top: 2px;
    margin-right: 20px;
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string
};

interface IDropdownItem {
    label: string,
    value: string
}

interface IComponentState {
    isUpdate: boolean,
    updating: boolean,
    activeModal: JSX.Element | undefined,
    customModalWidth: string,
    cancelLeave: boolean,
}

interface PrintCommandSet {
    '@ID': string;
    '@ClaimTypeId': string;
    '@Name': string;
    '@IsDefault': string;
}

interface APC_GenericLookupList {
    '@ID': string;
    '@Name': string;
}

interface HeaderProps { columns: {} }

export const DEFAULT_STATE: IComponentState = {
    isUpdate: false,
    updating: false,
    activeModal: undefined,
    customModalWidth: "",
    cancelLeave: false,
};

interface PC_GridRecord {
    record: {
        ActionDisplay: string;
        Action: string;
        Field: string;
        Row: string;
        Col: string;
        Occ: string;
        Len: string;
        Function: string;
        Value: string;
        Condition: string;
        FieldID: string;
        SetName: string;
        PDFRowRed: string;
        PDFColRed: string;
        PDFLenRed: string;
        PDFSpcRed: string;
        PDFRowGrey: string;
        PDFColGrey: string;
        PDFLenGrey: string;
        PDFSpcGrey: string;
        id: string;
        DataForm: string;
        Inx: string;
    }
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IPaperCommandsProps = IMergeCrudComponentProps<IPaperCommandsState, IPaperCommandsActionProps, IOwnProps>;

export class PaperCommands extends React.Component<IPaperCommandsProps, IComponentState> {
    static addItemCount: number = 0;
    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Paper Commands"
    };

    constructor(props: IPaperCommandsProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.onClickAddPrintCommandSetNew = this.onClickAddPrintCommandSetNew.bind(this);
        this.onClickSaveAsPrintCommandSetSaveAs = this.onClickSaveAsPrintCommandSetSaveAs.bind(this);
        this.onClickSavePayer = this.onClickSavePayer.bind(this);
        this.onClickSaveTypeOfBill = this.onClickSaveTypeOfBill.bind(this);
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiPaperClaimsConfig }, validationCallback);
        this.props.action.ui.getPaperCommand();
        this.props.action.ui.loadPaperFunctionList((sessionStorage.getItem("ClientAlias")) ? sessionStorage.getItem("ClientAlias") : 0);
        this.props.action.ui.getTypeOfBills();
    }

    public componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps?.dataStore?.crud?.data?.PaperClaimsPrintingMaintenanceInfo?.PrintCommandSetList?.PrintCommandSet?.length !==
            this.props?.dataStore?.crud?.data?.PaperClaimsPrintingMaintenanceInfo?.PrintCommandSetList?.PrintCommandSet?.length) {

            this.props.action.ui.loadFormTypeDetails({
                masterCrud: this.props.dataStore.crud.data
            });
        }
        if (this.props?.dataStore?.ui?.isDeleteSuccess) {
            let filteredPaperCommand = this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand?.[0];
            this.onClickDeletePrintCommandSet(filteredPaperCommand);
        }
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        let paperCommands = JSON.stringify(this.props.dataStore.ui.paperCommands);
        this.props.action.ui.savePaperCommands(paperCommands);
        this.props.history.push('/LandingPage');
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }
    /* -----------------------------------  */
    /* -----  DATA HELP METHODS ----------  */
    /* -----------------------------------  */

    public getDetailsFormType() {

        let records: any[] = [];
        this.props.dataStore.ui?.searchResults?.map((item: any) => {
            if ((item.claimTypeName.toUpperCase() != "CA251C") && (item.claimTypeName.toUpperCase() != "MRAN TYPE 30") && (item.claimTypeName.toUpperCase() != "MRAN TYPE 31") && (item.claimTypeName.toUpperCase() != "MRAN TYPE 50")) {
                records.push({ label: item.claimTypeName, value: item.claimTypeId })
            }
        })
        return records;
    }

    public getDetailsPaperCommandSets() {

        let records: any[] = [];
        this.props.dataStore.ui?.paperCommandSets?.forEach(ob => {
            if (ob['@Name'])
                records.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return records;
    }

    public onSelectFormType(event: any) {
        const userModified: any = sessionStorage.getItem("userModified");
        if (userModified === 'true' && this.props.dataStore.ui.isPrintCommandSetModifies == 'true') {
            this.setState({ cancelLeave: true })
        }
        else {
            this.clearCommandSection();
            this.props.dataStore.ui.fieldID = "";
            if (event.length > 0) {
                this.props.action.ui.setPaperCommandSets({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        paperCommandSets: []
                    }
                });
                this.props.action.ui.selectedFormType({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: event[0].id.toString()
                    }
                });
                let paperCommandSets: PrintCommandSet[] | undefined = [];
                let crudDataForPaperCommandSets = JSON.parse(JSON.stringify(this.props.dataStore.crud.data?.PaperClaimsPrintingMaintenanceInfo?.PrintCommandSetList?.PrintCommandSet))
                paperCommandSets = crudDataForPaperCommandSets?.filter((item: PrintCommandSet) => {
                    return item["@ClaimTypeId"] === event[0]?.id.toString();
                });
                if (paperCommandSets) {
                    paperCommandSets = this.sortPrintCommandset(paperCommandSets, event[0]?.id.toString());
                }
                this.props.action.ui.setPaperCommandSets({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        paperCommandSets: paperCommandSets
                    }
                });

                this.props.action.ui.selectedPaperCommandSetsItem({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: paperCommandSets !== undefined ? paperCommandSets[0]?.["@ID"] : ""
                    }
                });

                this.props.action.ui.getPaperCommandsFiltered({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        id: paperCommandSets !== undefined ? paperCommandSets[0]?.["@ID"] : "",
                        value: paperCommandSets !== undefined ? paperCommandSets[0]?.["@Name"] : ""
                    }
                });
            }

            this.props.action.ui.selectedWhenCondition({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ''
                }
            });
            this.props.action.ui.selectedTypeOfBill({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ''
                }
            });

            this.props.action.ui.selectedPayer({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ''
                }
            });
            this.props.action.ui.selectedConditionRadio({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ''
                }
            });
        }
    }
    public get_SelectedItem(lookupList: APC_GenericLookupList[] | null, idToFind: string | undefined): IDropdownItem {
        let item = lookupList?.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        }
        return result;
    }

    public getGenericLookupList_Array(lookupList: APC_GenericLookupList[] | null) {
        let result: any[] = [];
        lookupList?.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getGenericLookupList_Array_Function(lookupList: APC_GenericLookupList[] | null) {
        let result: any[] = [];
        lookupList?.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'].replace(/^F_/, ""), value: ob['@ID'] });
        });
        return result;
    }

    public getSelectedFormType(): string[] {
        let items: string[] = [];
        this.props.dataStore.ui?.searchResults?.forEach(ob => {
            if (ob.claimTypeId.toString() === this.props.dataStore.ui.selectedFormType) {
                items.push(ob.claimTypeId);
            }
        });

        if (items.length == 0) { items.push('') }
        return items;
    }

    public getSelectedPaperCommandSet(): string[] {
        let items: string[] = [];
        this.props.dataStore.ui?.paperCommandSets?.forEach(ob => {
            if (ob['@ID'].toString() === this.props.dataStore.ui.selectedPaperCommandSetsId) {
                items.push(ob['@ID'].toString());
            }
        });

        if (items.length == 0) { items.push('') }
        return items;
    }

    getPaperCommandGridColumnHeaders() {
        return new Set([
            {
                dataName: "Action",
                text: 'Action',
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Field",
                text: "Field",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Row",
                text: "Row",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Col",
                text: "Col",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Occ",
                text: "Occ",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Len",
                text: "Len",
                sortable: false,
                cellType: "custom",
                isSorted: 0,
            },
            {
                dataName: "Function",
                text: "Function",
                sortable: false,
                cellType: "custom",
                isSorted: 0,
            },
            {
                dataName: "Value",
                text: "Value",
                sortable: false,
                cellType: "custom",
                isSorted: 0,
            },
            {
                dataName: "Condition",
                text: "Condition",
                sortable: false,
                cellType: "custom",
                isSorted: 0,
            }
        ]);
    }

    public getFunction_SelectedItem(lookupList: APC_GenericLookupList[] | null, idToFind: string | undefined): IDropdownItem {
        if (idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);

        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Name'].replace(/^F_/, "");
            result.value = item['@ID'];
        } else {
            result.label = '- Select -';
            result.value = '0';
        }
        return result;
    }

    public sortPrintCommandset(sortPrintCommandSets: PrintCommandSet[], claimTypeId: string) {
        let sortedPrintCommandSet: any[] = [];
        if (sortPrintCommandSets !== undefined) {

            let defaultCommandSet = sortPrintCommandSets.find(x => x?.["@IsDefault"] == "-1" && x["@ClaimTypeId"] == claimTypeId);
            if (defaultCommandSet) {
                sortedPrintCommandSet.push(defaultCommandSet)
            }
            sortPrintCommandSets.filter((item: PrintCommandSet) => {
                return item["@IsDefault"] !== "-1" && item["@ClaimTypeId"] === claimTypeId
            }).sort(
                (p1: PrintCommandSet, p2: PrintCommandSet) => (p1["@Name"].toUpperCase() > p2["@Name"].toUpperCase()) ? 1 : (p1["@Name"].toUpperCase() < p2["@Name"].toUpperCase()) ? -1 : 0).map(a => sortedPrintCommandSet.push(a));
        }
        return sortedPrintCommandSet;
    }

    getCustomRow() {
        const row = ({ record }: PC_GridRecord) =>
            <tr id={record.id} key={record.id} role="button"
                className={record.id === this.props.dataStore.ui.selectedCommandRow ? 'selected' : ''}
                onClick={(event) => this.onRowClick(event, record)}>
                {
                    <React.Fragment>
                        <ARMRowCell value={record.ActionDisplay} />
                        <ARMRowCell value={record.Field} />
                        <ARMRowCell value={record.Row} />
                        <ARMRowCell value={record.Col} />
                        <ARMRowCell value={record.Occ} />
                        <ARMRowCell value={record.Len} />
                        <ARMRowCell value={record.Function} />
                        <ARMRowCell value={record.Value} />
                        <ARMRowCell value={record.Condition} />
                    </React.Fragment>
                }
            </tr>
        return row;
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, record: GridRecordDetails) {
        this.clearCommandSection();
        if (record.Condition) {
            var conditionSplit = record.Condition.split("=", 2);
            if (conditionSplit && conditionSplit.length > 1) {
                if (conditionSplit[0].toUpperCase() == "TYPEOFBILL") {
                    this.props.action.ui.addTypeOfBill({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            value: conditionSplit[1]
                        }
                    });
                    this.props.action.ui.selectedConditionRadio({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            value: 'TypeOfBill'
                        }
                    });
                    if (this.props.dataStore.ui.typeOfBills?.TypeOfBills) {
                        let filteredTypeOfBill = this.props.dataStore.ui.typeOfBills?.TypeOfBills.filter(x => x["@Name"] == conditionSplit[1])
                        this.props.action.ui.selectedTypeOfBill({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                value: filteredTypeOfBill[0]?.["@ID"]
                            }
                        });
                    }

                }
                if (conditionSplit[0].toUpperCase() == "PAYER") {
                    this.props.action.ui.addPayer({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            value: conditionSplit[1]
                        }
                    });

                    this.props.action.ui.selectedConditionRadio({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            value: 'Payer'
                        }
                    });

                    if (this.props.dataStore.ui.listPopulation?.ListPopulation?.PayerList?.Payer) {
                        let filteredPayer = this.props.dataStore.ui.listPopulation?.ListPopulation?.PayerList?.Payer.filter(x => x["@Name"] == conditionSplit[1])
                        this.props.action.ui.selectedPayer({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                value: filteredPayer[0]?.["@ID"]
                            }
                        });
                    }
                }
            }
            else {
                this.props.action.ui.selectedConditionRadio({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: 'When'
                    }
                });
                this.props.action.ui.selectedWhenCondition({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: record.Condition
                    }
                });
            }
        }
        else {
            this.props.action.ui.selectedConditionRadio({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ''
                }
            });
            this.props.action.ui.selectedWhenCondition({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ''
                }
            });
            this.props.action.ui.selectedTypeOfBill({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ''
                }
            });

            this.props.action.ui.selectedPayer({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ''
                }
            });
        }
        this.props.action.ui.setAction({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                action: record.Action
            }
        });
        this.props.action.ui.setFieldID({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                fieldID: record.FieldID
            }
        });
        this.props.action.ui.setOcc({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                occ: record.Occ
            }
        });

        this.props.action.ui.setValue({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: record.Value
            }
        });

        let selectedFunctionList = this.props?.dataStore?.ui?.listPopulation?.ListPopulation?.FunctionList?.Function.filter(item => item["@Name"] === record.Function);
        let funnctionID = selectedFunctionList != undefined && selectedFunctionList.length > 0 ? selectedFunctionList?.[0]["@ID"] : "";

        this.props.action.ui.setFunction({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                function: funnctionID
            }
        });
        //Default
        this.props.action.ui.setRow({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                row: record.Row
            }
        });
        this.props.action.ui.setCol({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                col: record.Col
            }
        });
        this.props.action.ui.setLan({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                len: record.Len
            }
        });
        //Red PDF
        this.props.action.ui.setPDFRowRed({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfRowRed: record.PDFRowRed
            }
        });
        this.props.action.ui.setPDFColRed({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfColRed: record.PDFColRed
            }
        });
        this.props.action.ui.setPDFLenRed({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfLenRed: record.PDFLenRed
            }
        });
        this.props.action.ui.setPDFSpcRed({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfSpcRed: record.PDFSpcRed
            }
        });
        //Gray PDF
        this.props.action.ui.setPDFRowGrey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfRowGrey: record.PDFRowGrey
            }
        });
        this.props.action.ui.setPDFColGrey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfColGrey: record.PDFColGrey
            }
        });
        this.props.action.ui.setPDFLenGrey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfLenGrey: record.PDFLenGrey
            }
        });
        this.props.action.ui.setPDFSpcGrey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfSpcGrey: record.PDFSpcGrey
            }
        });
        this.props.action.ui.setDataForm({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                dataForm: record.DataForm
            }
        });
        this.props.action.ui.setINX({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                inx: record.Inx
            }
        });
        this.props.action.ui.setPDFColGrey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfColGrey: record.PDFColGrey
            }
        });
        this.props.action.ui.setSelectedCommandRow({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                selectedCommandRow: record.id
            }
        });
    }

    getGridLoadDetailsByPaperCommandSet(e: any) {
        this.clearCommandSection();
        let paperCommandSet: any;
        if (e.length > 0)
            paperCommandSet = e[0];
        this.props.action.ui.selectedPaperCommandSetsItem({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: paperCommandSet.id,
                value: paperCommandSet.label
            }
        });
        this.props.action.ui.getPaperCommandsFiltered({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: paperCommandSet.label,
                id: paperCommandSet.id
            }
        });

        this.props.action.ui.selectedWhenCondition({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: ''
            }
        });
        this.props.action.ui.selectedTypeOfBill({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: ''
            }
        });

        this.props.action.ui.selectedPayer({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: ''
            }
        });
        this.props.action.ui.selectedConditionRadio({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: ''
            }
        });
    }

    public getAction() {
        let setvalue = [{ label: 'add', value: 'AddMap' },
        { label: 'add sl', value: 'AddMapSL' },
        { label: 'constant', value: 'Constant' },
        { label: 'custom', value: 'CustomMap' },
        { label: 'delete', value: 'DelMap' },
        { label: 'remap', value: 'ReMap' },
        { label: 'remap sl', value: 'ReMapSL' }]
        return setvalue;
    }

    public getActionItem() {
        let setvalue = [{ label: 'add', value: 'AddMap' },
        { label: 'add sl', value: 'AddMapSL' },
        { label: 'constant', value: 'Constant' },
        { label: 'custom', value: 'CustomMap' },
        { label: 'delete', value: 'DelMap' },
        { label: 'remap', value: 'ReMap' },
        { label: 'remap sl', value: 'ReMapSL' }]

        let getValue = setvalue.filter(res => {
            if (this.props.dataStore.ui.action != undefined) {
                return res.value == this.props.dataStore.ui.action
            }
            else {
                return { label: 'add', value: 'AddMap' };
            }
        });
        if (getValue.length == 0)
            return { label: 'add', value: 'AddMap' };

        return getValue;
    }

    public setActionDisplay(actionDisplay: string) {

        switch (actionDisplay) {
            case "AddMap":
                actionDisplay = 'add'
                break;
            case "AddMapSL":
                actionDisplay = 'add sl'
                break;
            case "Constant":
                actionDisplay = 'constant'
                break;
            case "CustomMap":
                actionDisplay = 'custom'
                break;
            case "DelMap":
                actionDisplay = 'delete'
                break;
            case "ReMap":
                actionDisplay = 'remap'
                break;
            case "ReMapSL":
                actionDisplay = 'remap sl'
                break;
            default:
                actionDisplay = ''
                break;
        }
        return actionDisplay;
    }

    public getTitle() {
        const selectedFormTypeValue = this.getSelectedFormType()
        const getClaimTypeId = this.props.dataStore.ui?.searchResults?.filter(item => item.claimTypeId === selectedFormTypeValue[0])
        if (getClaimTypeId?.length > 0) {
            let title = getClaimTypeId[0].claimTypeName

            switch (title) {
                case "UB":
                    title = 'UB-92'
                    break;
                case "1500":
                    title = 'HCFA-1500'
                    break;
            }
            return title + " " + this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand[0]?.["@SetName"];
        }
        if (this.props.dataStore.ui?.searchResults?.length > 0) {
            let title = this.props.dataStore.ui?.searchResults[0].claimTypeName

            switch (title) {
                case "UB":
                    title = 'UB-92'
                    break;
                case "1500":
                    title = 'HCFA-1500'
                    break;
            }
            return title + " " + this.props.dataStore.ui.selectedPaperCommandSetsSetName;
        }
    }

    public getFunction() {
        let result: any;
        let action = this.props.dataStore.ui.action != "" ? this.props.dataStore.ui.action : "AddMap";
        if (action === "CustomMap") {
            result = this.props?.dataStore?.ui?.listPopulation?.ListPopulation?.FunctionList?.Function.filter(item => item["@Type"] === '3' || item["@Type"] === '2')
        }
        else if (action === "AddMap" || action === "AddMapSL" || action === "ReMap" || action === "ReMapSL")
            result = this.props?.dataStore?.ui?.listPopulation?.ListPopulation?.FunctionList?.Function.filter(item => item["@Type"] === '1')
        else
            result = this.props?.dataStore?.ui?.listPopulation?.ListPopulation?.FunctionList?.Function

        return result;
    }

    public actionDefaut() {
        let action = "";
        if (this.props.dataStore.ui.action == "")
            action = "AddMap";
        return action;
    }

    public valueFieldDisable(): boolean {
        let isfieldDisabled: boolean = false;
        if (this.props.dataStore.ui.action === "AddMap" || this.props.dataStore.ui.action === "AddMapSL" || this.props.dataStore.ui.action === "DelMap" || this.props.dataStore.ui.action === "ReMap" || this.props.dataStore.ui.action === "ReMapSL" || this.props.dataStore.ui.action == "") {
            isfieldDisabled = true;
        }
        //F_CONVERT = 205, F_STRIPCHARS =207
        if ((this.props.dataStore.ui.function == "205" || this.props.dataStore.ui.function == "207") && (this.props.dataStore.ui.action == "AddMap" || this.props.dataStore.ui.action === "AddMapSL" || this.props.dataStore.ui.action == "ReMap" || this.props.dataStore.ui.action == "ReMapSL" || this.props.dataStore.ui.action == "")) {
            isfieldDisabled = false;
        }
        return isfieldDisabled;
    }

    public clearCommandSection() {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true' && this.props.dataStore.ui.isPrintCommandSetModifies == 'true') {
            this.setState({ cancelLeave: true })
        }
        else {
            this.props.dataStore.ui.selectedCommandRow = "";
            this.props.dataStore.ui.action = "AddMap";
            this.props.dataStore.ui.fieldID = "";
            this.props.dataStore.ui.occ = "";
            this.props.dataStore.ui.function = "";
            this.props.dataStore.ui.value = "";
            this.props.dataStore.ui.row = "";
            this.props.dataStore.ui.col = "";
            this.props.dataStore.ui.len = "";
            this.props.dataStore.ui.pdfRowRed = "";
            this.props.dataStore.ui.pdfColRed = "";
            this.props.dataStore.ui.pdfLenRed = "";
            this.props.dataStore.ui.pdfSpcRed = "";
            this.props.dataStore.ui.pdfRowGrey = "";
            this.props.dataStore.ui.pdfColGrey = "";
            this.props.dataStore.ui.pdfLenGrey = "";
            this.props.dataStore.ui.pdfSpcGrey = "";
            this.props.dataStore.ui.inx = "";
            this.props.dataStore.ui.commandFieldError = "";
            this.props.dataStore.ui.commandValueError = "";
            this.props.dataStore.ui.selectedConditionRadio = "";
            this.props.dataStore.ui.selectedPayer = "";
            this.props.dataStore.ui.selectedTypeOfBill = "";
            this.props.dataStore.ui.selectedWhenCondition = "";
        }
    }

    public clearCommandActionSelected() {
        this.props.dataStore.ui.fieldID = "";
        this.props.dataStore.ui.occ = "";
        this.props.dataStore.ui.function = "";
        this.props.dataStore.ui.value = "";
        this.props.dataStore.ui.row = "";
        this.props.dataStore.ui.col = "";
        this.props.dataStore.ui.len = "";
        this.props.dataStore.ui.pdfRowRed = "";
        this.props.dataStore.ui.pdfColRed = "";
        this.props.dataStore.ui.pdfLenRed = "";
        this.props.dataStore.ui.pdfSpcRed = "";
        this.props.dataStore.ui.pdfRowGrey = "";
        this.props.dataStore.ui.pdfColGrey = "";
        this.props.dataStore.ui.pdfLenGrey = "";
        this.props.dataStore.ui.pdfSpcGrey = "";
        this.props.dataStore.ui.commandFieldError = "";
        this.props.dataStore.ui.commandValueError = "";
        this.props.dataStore.ui.selectedConditionRadio = "";
        this.props.dataStore.ui.selectedPayer = "";
        this.props.dataStore.ui.selectedTypeOfBill = "";
        this.props.dataStore.ui.selectedWhenCondition = "";
    }

    onClickPrintCommandSet(buttonName: string) {
        let paperCommandSet = {
            '@ID': "",
            '@ClaimTypeId': this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand[0]?.Form["@ID"],
            '@Name': "",
            '@IsDefault': ""
        }
        if (buttonName == "CreateNew") {
            this.setState({
                customModalWidth: "100%",
                activeModal: <AddPrintCommandModal
                    closeModal={() => this.setState({ activeModal: undefined, customModalWidth: "" })}
                    printCommandSet={paperCommandSet}
                    onSave={(paperCommandSet: any) => this.onClickAddPrintCommandSetNew(paperCommandSet)}
                />
            });
        }
        if (buttonName == "SaveAs") {
            this.setState({
                customModalWidth: "100%",
                activeModal: <AddPrintCommandModal
                    closeModal={() => this.setState({ activeModal: undefined, customModalWidth: "" })}
                    printCommandSet={paperCommandSet}
                    onSave={(paperCommandSet: any) => this.onClickSaveAsPrintCommandSetSaveAs(paperCommandSet)}
                />
            });
        }
        if (buttonName == "Delete") {
            let filteredPaperCommand = this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand?.[0];
            let setName = filteredPaperCommand?.["@SetName"];
            let isDefault = filteredPaperCommand?.["@IsDefault"];
            if (window.confirm('Are you sure you want to delete the selected Print Command Set?\nThis action cannot be undone.')) {
                if (setName == "Standard") {
                    alert("Cannot delete the Standard Print Command Set.");
                    return;
                }
                if (isDefault == "true") {
                    alert("Cannot delete the Print Command Set selected as Default.\nTo delete, please set another Print Command Set as the Default.");
                    return;
                }
                let PaperCommands = JSON.stringify(filteredPaperCommand);
                this.props.action.ui.DeletePaperCommand(PaperCommands);
                //this.onClickDeletePrintCommandSet(filteredPaperCommand);
            }
        }
    }

    onClickAddPrintCommandSetNew(paperCommandSet: any) {
        if (this.ValidateSetName(paperCommandSet["@Name"], paperCommandSet["@ClaimTypeId"])) {
            this.props.action.ui.AddPaperCommandSetToNewList({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    Name: paperCommandSet["@Name"],
                    ClaimTypeId: paperCommandSet["@ClaimTypeId"],
                    IsDefault: paperCommandSet["@IsDefault"], //== "-1" ? "true" : "false",
                    ID: paperCommandSet["@ID"],
                    ActionType: "Add",
                }
            });

            if (paperCommandSet["@IsDefault"] == "-1") {
                this.props.action.ui.DeSelectPrintCommandSet({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        Name: paperCommandSet["@Name"],
                        ClaimTypeId: paperCommandSet["@ClaimTypeId"],
                        IsDefault: paperCommandSet["@IsDefault"], //== "-1" ? "true" : "false",
                        ActionType: "DeSelect"
                    }
                });
            }
        }
        handleChange();
    }

    onClickSaveAsPrintCommandSetSaveAs(paperCommandSet: any) {
        if (this.ValidateSetName(paperCommandSet["@Name"], paperCommandSet["@ClaimTypeId"])) {
            this.props.action.ui.AddPaperCommandSetToNewList({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    Name: paperCommandSet["@Name"],
                    ClaimTypeId: paperCommandSet["@ClaimTypeId"],
                    IsDefault: paperCommandSet["@IsDefault"],// == "-1" ? "true" : "false",
                    ID: paperCommandSet["@ID"],
                    ActionType: "SaveAs"
                }
            });

            if (paperCommandSet["@IsDefault"] == "-1") {
                this.props.action.ui.DeSelectPrintCommandSet({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        Name: paperCommandSet["@Name"],
                        ClaimTypeId: paperCommandSet["@ClaimTypeId"],
                        IsDefault: paperCommandSet["@IsDefault"],// == "-1" ? "true" : "false",
                        ActionType: "DeSelect"
                    }
                });
            }
        }
        handleChange();
    }

    onClickDeletePrintCommandSet(filteredPaperCommand: any) {
        if (this.props?.dataStore?.ui?.isDeleteSuccess?.toLocaleLowerCase() === "true") {
            this.props.action.ui.DeletePaperCommandSet({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    Name: filteredPaperCommand?.["@SetName"],
                    ClaimTypeId: filteredPaperCommand?.Form["@ID"],
                    IsDefault: filteredPaperCommand?.["@IsDefault"],
                    //ID: filteredPaperCommand["@ID"],
                    ActionType: "Delete"
                }
            });
        }
        else {
            alert(this.props?.dataStore?.ui?.isDeleteSuccess);
        }
        this.props.action.ui.ResetDeleteSuccessMessage({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {}
        });
    }

    DefaultCheckboxChangeEvent(e: any, selectedPaperCommand: any) {
        let filteredPaperCommand = this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand?.[0];
        if (selectedPaperCommand["@IsDefault"] === "true") {
            alert("Cannot de-select the Default Print Command Set.\nPlease select another Print Command Set.");
        }
        else {
            this.props.action.ui.DeSelectPrintCommandSet({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    Name: filteredPaperCommand?.["@SetName"],
                    ClaimTypeId: filteredPaperCommand?.Form["@ID"],
                    IsDefault: String(e.target.checked) ? "-1" : "0",
                    ActionType: "DeSelect"
                }
            });
        }
        handleChange();
    }

    ValidateSetName(newName: string, claimTypeId: string) {
        var objPaperCommandsList = this.props.dataStore.ui.paperCommandSets;
        var length = objPaperCommandsList.length;
        if (length == 50) {
            alert('The Print Command Set limit of 50 has been reached for this form type.');
            return false;
        }
        if (/[^a-zA-Z0-9]/.test(newName)) {
            alert('Please enter only alphanumeric for the new Print Command Set Name.');
            return false;
        }

        if (length != 0) {
            // Loop through the PrintCommandSets select list
            if (objPaperCommandsList.find((item: PrintCommandSet) => item["@Name"] == newName && item["@ClaimTypeId"] == claimTypeId)) {
                alert('The Print Command Set Name ' + newName + ' already exists.\nPlease use a unique name.');
                return false;
            }
        }
        return true;
    }

    onClickAddConditionList(conditionName: string) {
        if (conditionName == "Payer") {
            let payer = {
                '@ID': "",
                '@Name': ""
            }
            this.setState({
                customModalWidth: "100%",
                activeModal: <AddPayerModal
                    closeModal={() => this.setState({ activeModal: undefined, customModalWidth: "" })}
                    payer={payer}
                    onSave={(payer: any) => this.onClickSavePayer(payer)}
                />
            });
        }
        if (conditionName == "TypeOfBill") {
            let typeOfBill = {
                '@ID': "",
                '@Name': ""
            }
            this.setState({
                customModalWidth: "100%",
                activeModal: <AddTypeOfBillModal
                    closeModal={() => this.setState({ activeModal: undefined, customModalWidth: "" })}
                    typeOfBill={typeOfBill}
                    onSave={(typeOfBill: any) => this.onClickSaveTypeOfBill(typeOfBill)}
                />
            });

        }
        handleChange();
    }

    onClickSavePayer(payer: any) {

        if (payer) {
            this.props.action.ui.addPayer({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: payer?.["@Name"]
                }
            });
            handleChange();
        }
    }

    onClickSaveTypeOfBill(typeOfBill: any) {

        if (typeOfBill) {
            this.props.action.ui.addTypeOfBill({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: typeOfBill?.["@Name"]
                }
            });
            handleChange();
        }
    }

    SelectedPayer(e: any) {
        this.props.action.ui.selectedPayer({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: e.target.value
            }
        });
    }

    SelectedTypeOfBill(e: any) {
        this.props.action.ui.selectedTypeOfBill({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: e.target.value
            }
        });
    }

    onChangeCondition_RadioButton(conditionName: string) {
        this.props.action.ui.selectedConditionRadio({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: conditionName
            }
        });
        handleChange();
    }

    onChangeWhenTextBox(whenValue: string) {
        this.props.action.ui.selectedWhenCondition({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: whenValue.trim()
            }
        });
        handleChange();
    }

    public sendCommandError(id: string, msg: string) {
        if (!id || (id && id.length === 0) || !msg || (msg && msg.length === 0))
            return;

        this.props.action.ui.errorCommand({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { id: id, value: msg }
        });
    }

    public validateEntry(): boolean {

        let requiredFieldErrorFound = false;
        let action = this.props.dataStore.ui.action != "" ? this.props.dataStore.ui.action : "AddMap";

        if (action == "AddMap" || action == "AddMapSL" || action == "DelMap" || action == "ReMap" || action == "ReMapSL") {
            if (!IsFieldFilled(this.props.dataStore.ui.fieldID)) {
                this.sendCommandError('CommandField', "Please enter a field ID or name.");
                requiredFieldErrorFound = true;
            }
        }

        if (action == "Constant" || action == "CustomMap") {
            if (!IsFieldFilled(this.props.dataStore.ui.value)) {
                this.sendCommandError('CommandValue', "Please enter a value.");
                requiredFieldErrorFound = true;
            }
        }
        //F_CONVERT = 205, F_STRIPCHARS =207
        if ((this.props.dataStore.ui.function == "205" || this.props.dataStore.ui.function == "207") && action == "AddMap") {
            if (!IsFieldFilled(this.props.dataStore.ui.value)) {
                this.sendCommandError('CommandValue', "Please enter a value.");
                requiredFieldErrorFound = true;
            }
        }

        if (requiredFieldErrorFound)
            return false;

        return true;
    }

    //Add or Update command validate data
    public onClickModifyButton(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified == 'true' && this.props.dataStore.ui.isPrintCommandSetModifies == 'true') {
            this.setState({ cancelLeave: true })
        }
        else {

            if (!this.validateEntry())
                return;

            let field = this.props.dataStore.ui.fieldID != undefined ? this.props.dataStore.ui.fieldID.replace(/[^\w'\.\-]/g, "") : "";
            let row = this.props.dataStore.ui.row != undefined ? this.props.dataStore.ui.row.replace(/[^\d\+\-]/g, "") : "";
            let column = this.props.dataStore.ui.col != undefined ? this.props.dataStore.ui.col.replace(/[^\d\+\-]/g, "") : "";
            let occurrence = this.props.dataStore.ui.occ != undefined ? this.props.dataStore.ui.occ.replace(/[^\d\*\-]/g, "") : "";
            let length = this.props.dataStore.ui.len != undefined ? this.props.dataStore.ui.len.replace(/\D/g, "") : "";
            let value = this.props.dataStore.ui.value != undefined ? this.props.dataStore.ui.value : "";
            let pdfredrow = this.props.dataStore.ui.pdfRowRed != undefined ? this.props.dataStore.ui.pdfRowRed.replace(/[^\d\+\-\.]/g, "") : "";
            let pdfredcol = this.props.dataStore.ui.pdfColRed != undefined ? this.props.dataStore.ui.pdfColRed.replace(/[^\d\+\-\.]/g, "") : "";
            let pdfredlen = this.props.dataStore.ui.pdfLenRed != undefined ? this.props.dataStore.ui.pdfLenRed.replace(/\D/g, "") : "";
            let pdfredspc = this.props.dataStore.ui.pdfSpcRed != undefined ? this.props.dataStore.ui.pdfSpcRed.replace(/[^0-9.-]/g, "") : "";
            let pdfgreyrow = this.props.dataStore.ui.pdfRowGrey != undefined ? this.props.dataStore.ui.pdfRowGrey.replace(/[^\d\+\-\.]/g, "") : "";
            let pdfgreycol = this.props.dataStore.ui.pdfColGrey != undefined ? this.props.dataStore.ui.pdfColGrey.replace(/[^\d\+\-\.]/g, "") : "";
            let pdfgreylen = this.props.dataStore.ui.pdfLenGrey != undefined ? this.props.dataStore.ui.pdfLenGrey.replace(/\D/g, "") : "";
            let pdfgreyspc = this.props.dataStore.ui.pdfSpcGrey != undefined ? this.props.dataStore.ui.pdfSpcGrey.replace(/[^0-9.-]/g, "") : "";
            let action = this.props.dataStore.ui.action != undefined ? this.props.dataStore.ui.action : "";
            if (this.props.dataStore.ui.function != "205" && this.props.dataStore.ui.function != "207") // F_CONVERT = 205, F_STRIPCHARS = 207
                value = value.replace(/[^\w '\.\-]/g, "");
            let functionList = this.props?.dataStore?.ui?.listPopulation?.ListPopulation?.FunctionList?.Function.filter(item => item["@ID"] === this.props.dataStore.ui.function);
            let func = functionList != undefined && functionList.length > 0 ? functionList?.[0]["@Name"] : "";

            if (row == "")
                row = "-1";
            if (column == "")
                column = "-1";
            if (occurrence == "")
                occurrence = "0";
            if (length == "")
                length = "-1";
            if (pdfgreyrow == "")
                pdfgreyrow = "-1";
            if (pdfgreycol == "")
                pdfgreycol = "-1";
            if (pdfgreylen == "")
                pdfgreylen = "-1";
            if (pdfgreyspc == "")
                pdfgreyspc = "0";
            if (pdfredrow == "")
                pdfredrow = "-1";
            if (pdfredcol == "")
                pdfredcol = "-1";
            if (pdfredlen == "")
                pdfredlen = "-1";
            if (pdfredspc == "")
                pdfredspc = "0";
            if (action == "")
                action = "AddMap"

            var Parameter = "";
            if (this.props.dataStore.ui.selectedConditionRadio == "Payer") {
                let payer = this.props.dataStore.ui.selectedPayer != undefined ? this.props.dataStore.ui.selectedPayer : "";
                if (payer == "") {
                    let payerListFiltered = this.props.dataStore.ui.listPopulation?.ListPopulation.PayerList.Payer.filter(item => item["@ID"] === '1')
                    payer = payerListFiltered != undefined && payerListFiltered.length > 0 ? payerListFiltered?.[0]["@ID"] : "";
                }
                Parameter = (" /PAYER=" + payer);
            }
            else if (this.props.dataStore.ui.selectedConditionRadio == "TypeOfBill") {
                let typeOfbill = this.props.dataStore.ui.selectedTypeOfBill != undefined ? this.props.dataStore.ui.selectedTypeOfBill : "";
                if (typeOfbill == "") {
                    let typeOfBillListFiltered = this.props.dataStore.ui.typeOfBills?.TypeOfBills?.filter(x => x["@ID"] === "I");
                    typeOfbill = typeOfBillListFiltered != undefined && typeOfBillListFiltered.length > 0 ? typeOfBillListFiltered?.[0]["@ID"] : "";
                }
                Parameter = (" /TYPEOFBILL=" + typeOfbill);
            }
            else if (this.props.dataStore.ui.selectedConditionRadio == "When") {
                let when = this.props.dataStore.ui.selectedWhenCondition != undefined ? this.props.dataStore.ui.selectedWhenCondition : "";
                Parameter = (" /WHEN=" + when);
            }

            var concatenateCommand: any;
            switch (action) {
                case "AddMap":
                    concatenateCommand = ("ADDMAP /ID=" + field + " /ROW=" + row + " /COL=" + column + " /OCC=" + occurrence + " /LEN=" + length + " /PDFREDROW=" + pdfredrow + " /PDFREDCOL=" + pdfredcol + " /PDFREDLEN=" + pdfredlen + " /PDFREDSPC=" + pdfredspc + " /PDFGREYROW=" + pdfgreyrow + " /PDFGREYCOL=" + pdfgreycol + " /PDFGREYLEN=" + pdfgreylen + " /PDFGREYSPC=" + pdfgreyspc + " /TYPE=C /FUNC=" + func + " /VALUE=" + value + Parameter);
                    break;
                case "AddMapSL":
                    concatenateCommand = ("ADDMAP /ID=" + field + " /ROW=" + row + " /COL=" + column + " /OCC=" + occurrence + " /LEN=" + length + " /PDFREDROW=" + pdfredrow + " /PDFREDCOL=" + pdfredcol + " /PDFREDLEN=" + pdfredlen + " /PDFREDSPC=" + pdfredspc + " /PDFGREYROW=" + pdfgreyrow + " /PDFGREYCOL=" + pdfgreycol + " /PDFGREYLEN=" + pdfgreylen + " /PDFGREYSPC=" + pdfgreyspc + " /TYPE=SL /FUNC=" + func + " /VALUE=" + value + Parameter);
                    break;
                case "Constant":
                    concatenateCommand = ("CONSTANT /ROW=" + row + " /COL=" + column + " /LEN=" + length + " /PDFREDROW=" + pdfredrow + " /PDFREDCOL=" + pdfredcol + " /PDFREDLEN=" + pdfredlen + " /PDFREDSPC=" + pdfredspc + " /PDFGREYROW=" + pdfgreyrow + " /PDFGREYCOL=" + pdfgreycol + " /PDFGREYLEN=" + pdfgreylen + " /PDFGREYSPC=" + pdfgreyspc + " /VALUE=" + value + Parameter);
                    break;
                case "CustomMap":
                    concatenateCommand = ("SETOPT /" + func + "=" + value + Parameter);
                    break;
                case "DelMap":
                    concatenateCommand = ("DELMAP /ID=" + field + " /ROW=" + row + " /COL=" + column + " /OCC=" + occurrence + " /LEN=" + length + " /PDFREDROW=" + pdfredrow + " /PDFREDCOL=" + pdfredcol + " /PDFREDLEN=" + pdfredlen + " /PDFREDSPC=" + pdfredspc + " /PDFGREYROW=" + pdfgreyrow + " /PDFGREYCOL=" + pdfgreycol + " /PDFGREYLEN=" + pdfgreylen + " /PDFGREYSPC=" + pdfgreyspc + Parameter);
                    break;
                case "ReMap":
                    concatenateCommand = ("REMAP /ID=" + field + " /ROW=" + row + " /COL=" + column + " /OCC=" + occurrence + " /LEN=" + length + " /PDFREDROW=" + pdfredrow + " /PDFREDCOL=" + pdfredcol + " /PDFREDLEN=" + pdfredlen + " /PDFREDSPC=" + pdfredspc + " /PDFGREYROW=" + pdfgreyrow + " /PDFGREYCOL=" + pdfgreycol + " /PDFGREYLEN=" + pdfgreylen + " /PDFGREYSPC=" + pdfgreyspc + " /TYPE=C /FUNC=" + func + " /VALUE=" + value + Parameter);
                    break;
                case "ReMapSL":
                    concatenateCommand = ("REMAP /ID=" + field + " /ROW=" + row + " /COL=" + column + " /OCC=" + occurrence + " /LEN=" + length + " /PDFREDROW=" + pdfredrow + " /PDFREDCOL=" + pdfredcol + " /PDFREDLEN=" + pdfredlen + " /PDFREDSPC=" + pdfredspc + " /PDFGREYROW=" + pdfgreyrow + " /PDFGREYCOL=" + pdfgreycol + " /PDFGREYLEN=" + pdfgreylen + " /PDFGREYSPC=" + pdfgreyspc + " /TYPE=SL /FUNC=" + func + " /VALUE=" + value + Parameter);
                    break;
            }
            //Update command validate data
            if (this.props.dataStore.ui.selectedCommandRow != "") {
                this.props.action.ui.validatePaperCommand(this.props.dataStore.ui.dataForm, this.props.dataStore.ui.inx, concatenateCommand);
            }
            else {
                //Add command validate data
                let addINXCount = this.inxCount();
                this.props.action.ui.validatePaperCommand(this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand[0]?.Form["@ID"], addINXCount, concatenateCommand);
            }
        }
    }

    public onClickRemoveButton(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true' && this.props.dataStore.ui.isPrintCommandSetModifies == 'true')
            this.setState({ cancelLeave: true })
        else
            this.props.action.ui.removePaperCommand();
    }

    public inxCount() {
        let values: any = [];
        if (this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand !== undefined &&
            this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand.length > 0) {
            this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand.map((item: PaperCommandInner) => {
                if (item.Form?.Cmd !== null && item.Form?.Cmd !== undefined && Array.isArray(item.Form?.Cmd?.Command)) {
                    item.Form?.Cmd?.Command.map(papercommand => {
                        let inx: GridRecordINXDetails = {
                            INX: papercommand !== undefined ? papercommand["@INX"] : "",
                        }
                        values.push(inx?.INX);
                    })
                }
                else if (item.Form?.Cmd !== null && item.Form?.Cmd !== undefined && !Array.isArray(item.Form?.Cmd?.Command)) {
                    let inx: GridRecordINXDetails = {
                        INX: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["@INX"] : "",
                    }
                    values.push(inx?.INX);
                }
            });
        }

        let result = [];
        for (let i = 0; i < values.length; ++i) {
            if (0 <= values[i]) {
                result[values[i]] = true;
            }
        }
        for (let i = 1; i <= result.length; ++i) {
            if (undefined === result[i]) {
                return i;
            }
        }
        return 1
    }

    public updateCommand_Action(val: string) {
        this.clearCommandActionSelected();

        this.props.action.ui.setAction({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                action: val.trim()
            }
        });
    }

    public updateCommand_fieldID(val: string) {
        this.props.action.ui.setFieldID({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                fieldID: val.trim()
            }
        });
    }

    public updateCommand_occ(val: string) {
        this.props.action.ui.setOcc({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                occ: val.trim()
            }
        });
    }

    public updateCommand_function(val: string) {

        this.props.action.ui.setFunction({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                function: val.trim()
            }
        });

        if (this.props.dataStore.ui.action === "CustomMap") {
            let commandValue = "";
            if (val != "") {
                let ub92 = this.props.dataStore.ui?.searchResults?.length > 0 && this.props.dataStore.ui?.searchResults?.[0].claimTypeName === 'UB-92'
                //MOVE001 = 1003, RCODE4 = 1014, TBILL4 = 1017, MAP_LEGACY_2_VCODES = 1018
                if ((val === '1003' || val === '1014' || val === '1017' || val === '1018') && !ub92)
                    commandValue = 'No';
                else
                    commandValue = 'Yes';
            }
            this.props.action.ui.setValue({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: commandValue.trim()
                }
            });
        }
    }

    public updateCommand_value(val: string) {
        this.props.action.ui.setValue({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val.trim()
            }
        });
    }

    public updateCommand_row(val: string) {
        this.props.action.ui.setRow({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                row: val.trim()
            }
        });
    }

    public updateCommand_col(val: string) {
        this.props.action.ui.setCol({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                col: val.trim()
            }
        });
    }
    public updateCommand_len(val: string) {
        this.props.action.ui.setLan({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                len: val.trim()
            }
        });
    }
    public updateCommand_pdfRowRed(val: string) {
        this.props.action.ui.setPDFRowRed({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfRowRed: val.trim()
            }
        });
    }
    public updateCommand_pdfColRed(val: string) {
        this.props.action.ui.setPDFColRed({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfColRed: val.trim()
            }
        });
    }
    public updateCommand_pdfLenRed(val: string) {
        this.props.action.ui.setPDFLenRed({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfLenRed: val.trim()
            }
        });
    }
    public updateCommand_pdfSpcRed(val: string) {
        this.props.action.ui.setPDFSpcRed({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfSpcRed: val.trim()
            }
        });
    }
    public updateCommand_pdfRowGrey(val: string) {
        this.props.action.ui.setPDFRowGrey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfRowGrey: val.trim()
            }
        });
    }
    public updateCommand_pdfColGrey(val: string) {
        this.props.action.ui.setPDFColGrey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfColGrey: val.trim()
            }
        });
    }
    public updateCommand_pdfLenGrey(val: string) {
        this.props.action.ui.setPDFLenGrey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfLenGrey: val.trim()
            }
        });
    }
    public updateCommand_pdfSpcGrey(val: string) {
        this.props.action.ui.setPDFSpcGrey({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                pdfSpcGrey: val.trim()
            }
        });
    }

    public setAlert(alertMsg: string) {
        this.props.action.ui.setModalText(alertMsg);
    }


    /* -----------------------------------  */
    /* -------------  RENDER -------------  */
    /* -----------------------------------  */

    public render() {
        const header = ({ columns }: HeaderProps) => <thead><tr>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="Action"
                        text="Action"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                    />
                    <ARMHeaderCell
                        dataName="Field"
                        text="Field"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                    />
                    <ARMHeaderCell
                        dataName="Row"
                        text="Row"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                    />
                    <ARMHeaderCell
                        dataName="Col"
                        text="Col"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                    />
                    <ARMHeaderCell
                        dataName="Occ"
                        text="Occ"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                    />
                    <ARMHeaderCell
                        dataName="Len"
                        text="Len"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                    />
                    <ARMHeaderCell
                        dataName="Function"
                        text="Function"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                    />
                    <ARMHeaderCell
                        dataName="Value"
                        text="Value"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                    />
                    <ARMHeaderCell
                        dataName="Condition"
                        text="Condition"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                    />
                </React.Fragment>
            }
        </tr></thead>;

        const rowPaperCommand = this.getCustomRow();
        let gridRecords: GridRecordDetails[] = [];
        if (this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand !== undefined &&
            this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand.length > 0) {
            let payerList = this.props.dataStore.ui.listPopulation?.ListPopulation?.PayerList?.Payer;
            let typeOfBillList = this.props.dataStore.ui.typeOfBills?.TypeOfBills;
            this.props.dataStore.ui.paperCommandsFiltered?.PaperCommands?.PaperCommand.map((item: PaperCommandInner) => {
                if (item.Form?.Cmd !== null && item.Form?.Cmd !== undefined && Array.isArray(item.Form?.Cmd?.Command)) {
                    item.Form?.Cmd?.Command.map(papercommand => {

                        let pId = "";
                        let condVal = "";
                        let condOperator = "";
                        let val = "";
                        if (papercommand && papercommand["Condition"]) {

                            pId = papercommand["Condition"]["@ID"];
                            condVal = papercommand["Condition"]["@Val"];
                            condOperator = papercommand["Condition"]["@Operator"];
                            if (pId === "PAYER") {
                                let payerListFiltered = payerList?.filter(
                                    x => x["@ID"] === condVal);
                                let name = payerListFiltered != undefined && payerListFiltered.length > 0 ? payerListFiltered?.[0]["@Name"] : condVal;
                                val = name ? name : '';
                            }
                            else if (pId === 'TYPEOFBILL') {
                                let typeOfBillListFiltered = typeOfBillList?.filter(
                                    x => x["@ID"] === condVal);
                                let name = typeOfBillListFiltered != undefined && typeOfBillListFiltered.length > 0 ? typeOfBillListFiltered?.[0]["@Name"] : condVal;
                                val = name ? name : '';
                            }
                            val = pId + condOperator + val;
                        }
                        else {
                            if (papercommand["When"])
                                val = papercommand["When"]["@OrgCond"];
                        }

                        let gridRecord: GridRecordDetails = {
                            ActionDisplay: papercommand !== undefined ? papercommand["@Name"] : "",
                            Action: papercommand !== undefined ? papercommand["@Name"] : "",
                            Field: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@Name"] : "" : "",
                            Row: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@Row"] : "" : "",
                            Col: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@Col"] : "" : "",
                            Occ: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@Occ"] : "" : "",
                            Len: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@Len"] : "" : "",
                            Function: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@Func"] : "" : "",
                            Value: papercommand !== undefined ? papercommand["Function"] ? papercommand["Function"]["@Val"] : "" : "",
                            FieldID: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@FieldID"] : "" : "",
                            PDFRowRed: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@PDFRowRed"] : "" : "",
                            PDFColRed: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@PDFColRed"] : "" : "",
                            PDFLenRed: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@PDFLenRed"] : "" : "",
                            PDFSpcRed: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@PDFSpcRed"] : "" : "",
                            PDFRowGrey: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@PDFRowGrey"] : "" : "",
                            PDFColGrey: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@PDFColGrey"] : "" : "",
                            PDFLenGrey: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@PDFLenGrey"] : "" : "",
                            PDFSpcGrey: papercommand !== undefined ? papercommand["Field"] !== undefined ? papercommand["Field"]["@PDFSpcGrey"] : "" : "",
                            SetName: item["@SetName"],
                            Condition: val,
                            DataForm: item["Form"] != undefined ? item["Form"]["@ID"] : "",
                            Inx: papercommand !== undefined ? papercommand["@INX"] : "",
                            id: "",
                        }
                        if (gridRecord.Action === 'CustomMap') {
                            gridRecord.Field = papercommand !== undefined ? papercommand["Function"] ? papercommand["Function"]["@Name"] : "" : "";
                            gridRecord.Function = papercommand !== undefined ? papercommand["Function"] ? papercommand["Function"]["@Name"] : "" : "";
                        }
                        if (gridRecord.Action === 'Constant') {
                            gridRecord.Value = papercommand !== undefined ? papercommand["Field"] ? papercommand["Field"]["@Val"] : "" : "";
                        }
                        if (gridRecord.Action === 'DelMap') {
                            gridRecord.Field = papercommand !== undefined ? papercommand["Field"] ? papercommand["Field"]["@FieldID"] : "" : "";
                        }
                        gridRecord.ActionDisplay = this.setActionDisplay(gridRecord.ActionDisplay);
                        gridRecords.push(gridRecord);
                    })
                }
                else if (item.Form?.Cmd !== null && item.Form?.Cmd !== undefined && !Array.isArray(item.Form?.Cmd?.Command)) {

                    let pId = "";
                    let condVal = "";
                    let condOperator = "";
                    let val = "";
                    if (item.Form.Cmd.Command && item.Form.Cmd.Command["Condition"]) {

                        pId = item.Form.Cmd.Command["Condition"]["@ID"];
                        condVal = item.Form.Cmd.Command["Condition"]["@Val"];
                        condOperator = item.Form.Cmd.Command["Condition"]["@Operator"];
                        if (pId === "PAYER") {
                            let payerListFiltered = payerList?.filter(x => x["@ID"] === condVal);
                            let name = payerListFiltered != undefined && payerListFiltered.length > 0 ? payerListFiltered?.[0]["@Name"] : condVal;
                            val = name ? name : '';
                        }
                        else if (pId === 'TYPEOFBILL') {
                            let typeOfBillListFiltered = typeOfBillList?.filter(x => x["@ID"] === condVal);
                            let name = typeOfBillListFiltered != undefined && typeOfBillListFiltered.length > 0 ? typeOfBillListFiltered?.[0]["@Name"] : condVal;
                            val = name ? name : '';
                        }
                        val = pId + condOperator + val;
                    }
                    else {
                        if (item.Form.Cmd.Command?.["When"])
                            val = item.Form.Cmd.Command?.["When"]["@OrgCond"];
                    }

                    let gridRecord: GridRecordDetails = {
                        ActionDisplay: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["@Name"] : "",
                        Action: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["@Name"] : "",
                        Field: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] !== undefined ? item.Form.Cmd.Command["Field"]["@Name"] : "" : "",
                        Row: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] !== undefined ? item.Form.Cmd.Command["Field"]["@Row"] : "" : "",
                        Col: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] !== undefined ? item.Form.Cmd.Command["Field"]["@Col"] : "" : "",
                        Occ: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] !== undefined ? item.Form.Cmd.Command["Field"]["@Occ"] : "" : "",
                        Len: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] !== undefined ? item.Form.Cmd.Command["Field"]["@Len"] : "" : "",
                        Function: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] !== undefined ? item.Form.Cmd.Command["Field"]["@Func"] : "" : "",
                        Value: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Function"] ? item.Form.Cmd.Command["Function"]["@Val"] : "" : "",
                        FieldID: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@FieldID"] : "" : "",
                        PDFRowRed: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@PDFRowRed"] : "" : "",
                        PDFColRed: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@PDFColRed"] : "" : "",
                        PDFLenRed: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@PDFLenRed"] : "" : "",
                        PDFSpcRed: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@PDFSpcRed"] : "" : "",
                        PDFRowGrey: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@PDFRowGrey"] : "" : "",
                        PDFColGrey: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@PDFColGrey"] : "" : "",
                        PDFLenGrey: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@PDFLenGrey"] : "" : "",
                        PDFSpcGrey: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@PDFSpcGrey"] : "" : "",
                        SetName: item["@SetName"],
                        Condition: val,
                        id: "",
                        DataForm: item?.Form["@ID"],
                        Inx: item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["@INX"] : "",
                    }
                    if (gridRecord.Action === 'CustomMap') {
                        gridRecord.Field = item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Function"] ? item.Form.Cmd.Command["Function"]["@Name"] : "" : "";
                        gridRecord.Function = item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Function"] ? item.Form.Cmd.Command["Function"]["@Name"] : "" : "";
                    }
                    if (gridRecord.Action === 'Constant') {
                        gridRecord.Value = item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@Val"] : "" : "";
                    }
                    if (gridRecord.Action === 'DelMap') {
                        gridRecord.Field = item.Form.Cmd.Command !== undefined ? item.Form.Cmd.Command["Field"] ? item.Form.Cmd.Command["Field"]["@FieldID"] : "" : "";
                    }
                    gridRecord.ActionDisplay = this.setActionDisplay(gridRecord.ActionDisplay);
                    gridRecords.push(gridRecord);
                }
            });
        }

        var instruction = <React.Fragment>Choose the form type to view all the associated paper command sets. Click on any set to view the commands associated with it.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        return (
            <React.Fragment >
                <DialogWrapper title={this.props.title + ' ' + this.getTitle()} instruction={instruction} helpUrl='/Support/Help/SUP_EditPaperCMD.htm' buttons={buttons} isBusy={(!this.props.dataStore.crud.data) || this.state.updating}>
                    {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                    <ContentWrapper id={"content_wrapper_PaperCommands"}>
                        <ContentRowWrapper id={'row_additionalpapercommand'}>
                            <DialogFieldset id={'dialog_additionalpapercommand'}>
                                <FormTypeContent>
                                    <DialogFieldset id={'dialog_formtype'}>
                                        <DialogLegend>Form Type</DialogLegend>
                                        <ContentRowWrapper style={{ width: "250px" }}>
                                            <FilterableListWrapper>
                                                <ListContainerDiv>
                                                    <FilterableList
                                                        key={JSON.stringify(this.getDetailsFormType())}
                                                        domID={'select_formtype'}
                                                        width={250}
                                                        height={262}
                                                        records={this.getDetailsFormType()}
                                                        optionFields={{
                                                            id: "value",
                                                            value: "label",
                                                            text: "label",
                                                        }}
                                                        setSelections={(e: any) => this.onSelectFormType(e)}
                                                        placeholder="Filter This List"
                                                        value={this.getSelectedFormType()}
                                                    />
                                                </ListContainerDiv>
                                            </FilterableListWrapper>
                                        </ContentRowWrapper>
                                    </DialogFieldset>
                                    <DialogFieldset id={'dialog_papercommandsets'}>
                                        <DialogLegend>Paper Command Set</DialogLegend>
                                        <ContentRowWrapper style={{ width: "250px" }}>
                                            <FilterableListWrapper>
                                                <ListContainerDiv>
                                                    <FilterableList
                                                        key={JSON.stringify(this.getDetailsPaperCommandSets())}
                                                        domID={'select_papercommandsets'}
                                                        width={250}
                                                        height={226}
                                                        records={this.getDetailsPaperCommandSets()}
                                                        optionFields={{
                                                            id: "value",
                                                            value: "label",
                                                            text: "label",
                                                        }}
                                                        setSelections={(e: any) => this.getGridLoadDetailsByPaperCommandSet(e)}
                                                        placeholder="Filter This List"
                                                        value={this.getSelectedPaperCommandSet()}
                                                        disabled={this.props?.dataStore?.ui?.isSpecificFormType == "true"}
                                                    />
                                                </ListContainerDiv>
                                            </FilterableListWrapper>
                                        </ContentRowWrapper>
                                        <ContentRowWrapper style={{ marginTop: "5px" }}>
                                            <CheckBoxComponent
                                                id='default-checkBox'
                                                labelBefore=' Default'
                                                checked={this.props?.dataStore?.ui?.paperCommandsFiltered?.PaperCommands?.PaperCommand[0]["@IsDefault"] == "true"}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.DefaultCheckboxChangeEvent(e, this.props?.dataStore?.ui?.paperCommandsFiltered?.PaperCommands?.PaperCommand[0])}
                                                disabled={this.props?.dataStore?.ui?.isSpecificFormType == "true"}
                                            />
                                            <LabelDiv>
                                                <React.Fragment>{this.props?.dataStore?.ui?.paperCommandSetCount}/50</React.Fragment>
                                            </LabelDiv>
                                        </ContentRowWrapper>
                                        <SelectButtons style={{ width: "246px" }}>
                                            <Button
                                                domID="createNew-btn"
                                                name={"CREATE NEW"}
                                                buttonType="standard"
                                                size="small"
                                                type="button"
                                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                    this.onClickPrintCommandSet("CreateNew");
                                                }}
                                                disabled={this.props?.dataStore?.ui?.isSpecificFormType == "true"}
                                            />
                                            <Button
                                                domID="saveAs-btn"
                                                name={"SAVE AS"}
                                                buttonType="standard"
                                                size="small"
                                                type="button"
                                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                    this.onClickPrintCommandSet("SaveAs");
                                                }}
                                                disabled={this.props?.dataStore?.ui?.isSpecificFormType == "true"}
                                            />
                                            <Button
                                                domID="delete-btn"
                                                name={"DELETE"}
                                                buttonType="standard"
                                                size="small"
                                                type="button"
                                                disabled={this.props?.dataStore?.ui?.paperCommandsFiltered?.PaperCommands?.PaperCommand[0]["@IsDefault"] === "true" || this.props?.dataStore?.ui?.isSpecificFormType == "true"}

                                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                    this.onClickPrintCommandSet("Delete");
                                                }}
                                            />
                                        </SelectButtons>
                                    </DialogFieldset>
                                </FormTypeContent>
                            </DialogFieldset>

                            <PaperCommandContent>
                                <ContentWrapper id={"content_wrapper_PaperCommands"} >
                                    <PaperCommandGrid>
                                        <ContentRowWrapper >
                                            <ARMGrid
                                                isFixedHeader={true}
                                                maxHeight='340px'
                                                columns={this.getPaperCommandGridColumnHeaders()}
                                                headerComponent={header}
                                                records={gridRecords}
                                                scrollToTop={false}
                                                domID="btnContinue"
                                                rowComponent={rowPaperCommand}
                                            />
                                        </ContentRowWrapper>
                                    </PaperCommandGrid>

                                    <ContentRowWrapper>
                                        <Command>
                                            <DialogFieldset id='command-dialogfieldset'>
                                                <DialogLegend>Command</DialogLegend>
                                                <ContentRowWrapper style={{ width: "180px" }}>
                                                    <ContentColumnWrapper >
                                                        <ContentRowWrapper id="field-level">
                                                            <SelectDropdownV2
                                                                disabled={this.props.dataStore.ui.selectedCommandRow != ""}
                                                                domID="action"
                                                                className="dropdown"
                                                                label="Action:"
                                                                isClearable={false}
                                                                isSearchable={true}
                                                                size='small'
                                                                onChange={(e: any) => this.updateCommand_Action(e.value)}
                                                                options={this.getAction()}
                                                                initialValue={this.getActionItem()}
                                                            />
                                                        </ContentRowWrapper>
                                                        <ContentRowWrapper id="field-level">
                                                            <Input
                                                                disabled={this.props.dataStore.ui.action === "Constant" || this.props.dataStore.ui.action === "CustomMap"}
                                                                domID="field"
                                                                id="field"
                                                                className="text-input"
                                                                label="Field:"
                                                                maxLength={21}
                                                                hasError={this.props.dataStore.ui.commandFieldError && this.props.dataStore.ui.commandFieldError !== ''}
                                                                errorMessage={this.props.dataStore.ui.commandFieldError}
                                                                initialValue={this.props.dataStore.ui.fieldID}
                                                                onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    this.updateCommand_fieldID(e.target.value.trim())
                                                                }}
                                                            />
                                                        </ContentRowWrapper>
                                                        <ContentRowWrapper id="field-level">
                                                            <Input
                                                                disabled={this.props.dataStore.ui.action === "Constant" || this.props.dataStore.ui.action === "CustomMap"}
                                                                domID="occurrence"
                                                                id="occurrence"
                                                                className="text-input"
                                                                label="Occurrence:"
                                                                maxLength={3}
                                                                initialValue={this.props.dataStore.ui.occ}
                                                                onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    this.updateCommand_occ(e.target.value.trim())
                                                                }}
                                                            />
                                                        </ContentRowWrapper>
                                                        <ContentRowWrapper id="field-level">
                                                            <SelectDropdownV2
                                                                disabled={this.props.dataStore.ui.action === "Constant" || this.props.dataStore.ui.action === "DelMap"}
                                                                domID="function"
                                                                className="dropdown"
                                                                label="function:"
                                                                isClearable={false}
                                                                isSearchable={true}
                                                                size='small'
                                                                onChange={(e: any) => this.updateCommand_function(e.value)}
                                                                options={this.getGenericLookupList_Array_Function(this.getFunction())}
                                                                initialValue={this.getFunction_SelectedItem(this.getFunction(), this.props.dataStore.ui.function)}
                                                            />
                                                        </ContentRowWrapper>
                                                        <ContentRowWrapper id="field-level">
                                                            <Input
                                                                disabled={this.valueFieldDisable()}
                                                                domID="value"
                                                                id="value"
                                                                className="text-input"
                                                                label="Value:"
                                                                maxLength={21}
                                                                hasError={this.props.dataStore.ui.commandValueError && this.props.dataStore.ui.commandValueError !== ''}
                                                                errorMessage={this.props.dataStore.ui.commandValueError}
                                                                initialValue={this.props.dataStore.ui.value}
                                                                onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                    this.updateCommand_value(e.target.value.trim())
                                                                }}
                                                            />
                                                        </ContentRowWrapper>
                                                    </ContentColumnWrapper>

                                                    <ContentColumnWrapper style={{ width: "100px" }} >
                                                        <DialogFieldset id='default-dialogfieldset'>
                                                            <DialogLegend>Default</DialogLegend>
                                                            <ContentRowWrapper id="default-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="default-row"
                                                                    id="default-row"
                                                                    className="text-input"
                                                                    label="Row:"
                                                                    maxLength={2}
                                                                    initialValue={this.props.dataStore.ui.row}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_row(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="default-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="default-col"
                                                                    id="default-col"
                                                                    className="text-input"
                                                                    label="Col:"
                                                                    maxLength={2}
                                                                    initialValue={this.props.dataStore.ui.col}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_col(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="default-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="default-len"
                                                                    id="default-len"
                                                                    className="text-input"
                                                                    label="Len:"
                                                                    maxLength={3}
                                                                    initialValue={this.props.dataStore.ui.len}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_len(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                        </DialogFieldset>
                                                    </ContentColumnWrapper>

                                                    <ContentColumnWrapper style={{ width: "100px" }}>
                                                        <DialogFieldset id='red-pdf-dialogfieldset'>
                                                            <DialogLegend>Red PDF</DialogLegend>
                                                            <ContentRowWrapper id="redpdf-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="redpdf-row"
                                                                    id="redpdf-row"
                                                                    className="text-input"
                                                                    label="Row:"
                                                                    maxLength={4}
                                                                    initialValue={this.props.dataStore.ui.pdfRowRed}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_pdfRowRed(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="redpdf-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="redpdf-col"
                                                                    id="redpdf-col"
                                                                    className="text-input"
                                                                    label="Col:"
                                                                    maxLength={4}
                                                                    initialValue={this.props.dataStore.ui.pdfColRed}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_pdfColRed(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="redpdf-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="redpdf-len"
                                                                    id="redpdf-len"
                                                                    className="text-input"
                                                                    label="Len:"
                                                                    maxLength={3}
                                                                    initialValue={this.props.dataStore.ui.pdfLenRed}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_pdfLenRed(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="redpdf-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="redpdf-len"
                                                                    id="redpdf-len"
                                                                    className="text-input"
                                                                    label="Spc:"
                                                                    maxLength={5}
                                                                    initialValue={this.props.dataStore.ui.pdfSpcRed}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_pdfSpcRed(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                        </DialogFieldset>
                                                    </ContentColumnWrapper>

                                                    <ContentColumnWrapper style={{ width: "100px" }}>
                                                        <DialogFieldset id='gray-pdf-dialogfieldset'>
                                                            <DialogLegend>Gray PDF</DialogLegend>
                                                            <ContentRowWrapper id="graypdf-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="graypdf-row"
                                                                    id="graypdf-row"
                                                                    className="text-input"
                                                                    label="Row:"
                                                                    maxLength={4}
                                                                    initialValue={this.props.dataStore.ui.pdfRowGrey}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_pdfRowGrey(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="graypdf-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="graypdf-col"
                                                                    id="graypdf-col"
                                                                    className="text-input"
                                                                    label="Col:"
                                                                    maxLength={4}
                                                                    initialValue={this.props.dataStore.ui.pdfColGrey}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_pdfColGrey(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="graypdf-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="graypdf-len"
                                                                    id="graypdf-len"
                                                                    className="text-input"
                                                                    label="Len:"
                                                                    maxLength={3}
                                                                    initialValue={this.props.dataStore.ui.pdfLenGrey}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_pdfLenGrey(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="graypdf-level">
                                                                <Input
                                                                    disabled={this.props.dataStore.ui.action === "CustomMap" || this.props.dataStore.ui.action === "DelMap"}
                                                                    domID="graypdf-spc"
                                                                    id="graypdf-spc"
                                                                    className="text-input"
                                                                    label="Spc:"
                                                                    maxLength={5}
                                                                    initialValue={this.props.dataStore.ui.pdfSpcGrey}
                                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                        this.updateCommand_pdfSpcGrey(e.target.value.trim())
                                                                    }}
                                                                />
                                                            </ContentRowWrapper>
                                                        </DialogFieldset>
                                                    </ContentColumnWrapper>

                                                    <ContentColumnWrapper>
                                                        <DialogFieldset id='condition-dialogfieldset'>
                                                            <DialogLegend>Condition</DialogLegend>
                                                            <ContentRowWrapper id="payer_Row-Wrapper">
                                                                <ContentColumnWrapper id="payer-radio-button">
                                                                    <RadioButtonComponent
                                                                        id="payer"
                                                                        checked={this.props.dataStore.ui.selectedConditionRadio == "Payer"}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCondition_RadioButton("Payer")}
                                                                        disabled={false}
                                                                        width="10px"
                                                                    />
                                                                </ContentColumnWrapper >
                                                                <ContentColumnWrapper id="payer-ddl_Wrapper" style={{ marginLeft: "-25px" }}>
                                                                    <ARMNarrowDropdown
                                                                        title={""}
                                                                        label={"Payer:"}
                                                                        className="dropdown"
                                                                        isLabel={true}
                                                                        width="244px"
                                                                        multiple="false"
                                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                            this.SelectedPayer(e)
                                                                        }}
                                                                        records={this.getGenericLookupList_Array(this.props.dataStore.ui.listPopulation !== undefined &&
                                                                            this.props.dataStore.ui.listPopulation !== null ?
                                                                            this.props.dataStore.ui.listPopulation.ListPopulation.PayerList.Payer : null)}
                                                                        optionFields={{
                                                                            value: "value",
                                                                            text: "label"
                                                                        }}
                                                                        disabled={this.props.dataStore.ui.selectedConditionRadio !== "Payer"}
                                                                        selectedValue={this.props.dataStore.ui.selectedPayer}
                                                                    />
                                                                </ContentColumnWrapper >
                                                                <ContentColumnWrapper >
                                                                    <Button
                                                                        domID="new-link_payer"
                                                                        name="New"
                                                                        buttonType="link"
                                                                        size="medium"
                                                                        type="link"
                                                                        disabled={this.props.dataStore.ui.selectedConditionRadio !== "Payer"}
                                                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickAddConditionList("Payer")}
                                                                    />

                                                                </ContentColumnWrapper >
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="typeOfBill_Row_Wrapper">
                                                                <ContentColumnWrapper id="typeOfBill-radio-button_Wrapper">
                                                                    <RadioButtonComponent
                                                                        id="typeOfBill_RadioButton"
                                                                        checked={this.props.dataStore.ui.selectedConditionRadio == "TypeOfBill"}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCondition_RadioButton("TypeOfBill")}
                                                                        disabled={false}
                                                                        width="10px"
                                                                    />
                                                                </ContentColumnWrapper >
                                                                <ContentColumnWrapper id="typeOfBill-ddlWrappper" style={{ marginLeft: "3px" }}>
                                                                    <ARMNarrowDropdown
                                                                        title={""}
                                                                        label={"Type Of Bill:"}
                                                                        className="dropdown"
                                                                        isLabel={true}
                                                                        width="244px"
                                                                        multiple="false"
                                                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                            this.SelectedTypeOfBill(e)
                                                                        }}
                                                                        records={this.getGenericLookupList_Array(this.props.dataStore.ui.typeOfBills !== undefined &&
                                                                            this.props.dataStore.ui.typeOfBills !== null ?
                                                                            this.props.dataStore.ui.typeOfBills.TypeOfBills : null)}
                                                                        optionFields={{
                                                                            value: "value",
                                                                            text: "label"
                                                                        }}
                                                                        disabled={this.props.dataStore.ui.selectedConditionRadio !== "TypeOfBill"}
                                                                        selectedValue={this.props.dataStore.ui.selectedTypeOfBill}
                                                                    />
                                                                </ContentColumnWrapper >

                                                                <ContentColumnWrapper >
                                                                    <Button
                                                                        domID="new-link_TypeOfBill"
                                                                        name="New"
                                                                        buttonType="link"
                                                                        size="medium"
                                                                        type="link"
                                                                        disabled={this.props.dataStore.ui.selectedConditionRadio !== "TypeOfBill"}
                                                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickAddConditionList("TypeOfBill")}
                                                                    />

                                                                </ContentColumnWrapper >
                                                            </ContentRowWrapper>
                                                            <ContentRowWrapper id="when_Wrapper">
                                                                <ContentColumnWrapper id="when_Radio_Wrapper">
                                                                    <RadioButtonComponent
                                                                        id="when_radio-button"
                                                                        checked={this.props.dataStore.ui.selectedConditionRadio == "When"}
                                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeCondition_RadioButton("When")}
                                                                        disabled={false}
                                                                        width="10px"
                                                                    />
                                                                </ContentColumnWrapper >
                                                                <ContentColumnWrapper id="when_text_Wrapper" style={{ marginLeft: "-97px" }}>
                                                                    <ARMNarrowInput
                                                                        domID='txt_When'
                                                                        label='WHEN:'
                                                                        initialValue={this.props.dataStore.ui.selectedWhenCondition}
                                                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeWhenTextBox(e.target.value)}
                                                                        type='search'
                                                                        width='244px'
                                                                        disabled={this.props.dataStore.ui.selectedConditionRadio !== "When"}
                                                                    />
                                                                </ContentColumnWrapper >
                                                            </ContentRowWrapper>
                                                        </DialogFieldset>
                                                    </ContentColumnWrapper>
                                                </ContentRowWrapper>

                                                <SelectButtons >
                                                    <Button
                                                        domID="addButton"
                                                        name={(this.props.dataStore.ui.selectedCommandRow != "") ? "Update" : "Add"}
                                                        buttonType="standard"
                                                        size="small"
                                                        type="button"
                                                        disabled={!this.props.canEdit}
                                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                            this.setState({ isUpdate: true })
                                                            handleChange()
                                                            this.onClickModifyButton(e)
                                                        }}
                                                    />
                                                    <Button
                                                        domID="clearButton"
                                                        name="Clear"
                                                        buttonType="standard"
                                                        size="small"
                                                        type="button"
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                            this.setState({ isUpdate: true })
                                                            this.clearCommandSection();
                                                        }}
                                                    />
                                                    <Button
                                                        domID="removeButton"
                                                        name="Remove"
                                                        buttonType="standard"
                                                        size="small"
                                                        type="button"
                                                        disabled={this.props.dataStore.ui.selectedCommandRow == ""}
                                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                            this.setState({ isUpdate: true })
                                                            handleChange()
                                                            this.onClickRemoveButton(e)
                                                        }}
                                                    />
                                                </SelectButtons>

                                            </DialogFieldset>
                                        </Command>
                                    </ContentRowWrapper>
                                </ContentWrapper>
                            </PaperCommandContent>
                        </ContentRowWrapper>
                    </ContentWrapper>
                </DialogWrapper>
                <ModalComponent
                    component={this.state.activeModal}
                    isOpen={this.state.activeModal !== undefined}
                    onModalToggle={() => { }}
                    showDefaultClose={false}
                />
                <ModalConfirmation
                    isOpen={this.props.dataStore.ui.modalText != ""}
                    alertMode={true}
                    message={this.props.dataStore.ui.modalText}
                    onModalToggle={() => this.setAlert('')}
                />
            </React.Fragment >
        );
    }
};

var connectedHoc = connect<IPaperCommandsState, IPaperCommandsActionProps, IOwnProps, IPaperCommandsProps, ApplicationState>(
    createCrudMapStateToProps('paperCommands'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(PaperCommands);

export default withRouter(connectedHoc);
