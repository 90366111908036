import { Reducer } from 'redux';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { IFormUIState, defaultFormState } from "@store/ui/BaseCrudUI";
import { createDataAction, ActionTypes, IApiAction, createAction } from "@scripts/util/ActionHelpers";

interface HISType {
    '@ID': string;
    '@Name': string;
    '@Selected': string;
}

interface HISFormType {
    '@ID': string;
    '@Name': string;
    '@TypeId': string;
}

export interface HISField {
    '@ID': string;
    '@Name': string;
    '@TypeId': string;
    '@FormId': string;
    '@Included': string;
}



export interface FieldOption {
    value: string; 
    text: string; 
}

// Properties of action creators specific to the view
export interface IBackfeedConfigData {
    selectedTypeId: string;
    selectedFormId: string;
    typeList: HISType[]; 
    formTypeList: HISFormType[];
    selectedFields: HISField[];
    fieldList: HISField[];
    isBusy: boolean;
    isUpdate: boolean; 
    fieldRecords: FieldOption[];
    includedFieldRecords: FieldOption[];
    showModal: boolean; 
    memoizedFields: MemoizedFieldValue[];
}

// This memoization idea struck me as a tidy way to track changes to the crud while keeping the UI state separable 
// These memo items consist of top level meta-data about the object and a HISFieldCrudChangeData attribute which contains the crud submission data 
// These memo items are initialized  when the crud is first mapped to the UI state on mounting the compnent


// In essence, the idea is to keep a running list of the changes on the UI by tracking them on an array of memoized items
// Then compare for difference an "original included value" that was given by the crud initially   to an aquired "included" value which results from a user made update and which is added to the memoed item once a user makes a change
// When the user updates an item, an ID will also be added to those items which are changed to the top level of the memo object. This will allow us to fetch only those specific items which have changed for the specific selected form and type ids and field IDs.


// When it comes time to submit the data, the function filterCrudChanges will filter the memoed items by getting all of those which have had several matching conditions satisfied
//  The resulting filtered memo items will then have their crud submission data attributes decorated appropriately with a "@Delete" attribute prior to submission if a right to left change was made
// Or added to the  changes array as is if the original incldued value is not the same as the new includion value (signifying a change from left to right) 
// When it comes time to submit the data, we discard the top-level memo data and submit an array of the HIsFieldCrudChangeData objects

export interface MemoizedFieldValue {
    originalIncludedVal: string;
    included?: string;
    typeId: string;
    formId: string;
    ID?: string;
    HISFieldCrudChangeData: HISFieldCrudChangeData;
}

export interface HISFieldCrudChangeData {
    '@ID': string;
    '@TypeId': string;
    '@FormId': string;
    '@Delete'?: 'true';
}

export interface SelectedFieldData {
    selectedFields: HISField[];
    included: '0' | '1';
}

// UI representation of CRUD data. 
export interface IBackfeedConfigUIState extends IBackfeedConfigData, IFormUIState {};

export interface IInitializeBackfeedConfig extends ICrudActionData<MCHISFieldConfiguration, MCHISFieldConfiguration> {};

export const actionCreators = {
    initalizeData: (rawApiReturn: IInitializeBackfeedConfig) => createDataAction('BKFD_INIT', rawApiReturn),
    selectType: (type: '') => createDataAction('BKFD_SELECT_TYPE', type),
    selectForm: (form: '') => createDataAction('BKFD_SELECT_FORM', form),
    updateSelectedFields: (fieldData: SelectedFieldData) => createDataAction('BKFD_UPDATE_SELECT_FIELD', fieldData),
    selectFields: (fields: HISField[]) => createDataAction('BKFD_SELECTED_FIELDS', fields)
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultBackfeedConfigState: IBackfeedConfigUIState = {
    ...defaultFormState,
    selectedTypeId: '',
    selectedFormId: '',
    typeList: [],
    formTypeList: [],
    fieldList: [],
    isBusy: false,
    isUpdate: false,
    selectedFields: [],
    fieldRecords: [],
    includedFieldRecords: [],
    showModal: false,
    memoizedFields: []
}



export interface HISCrudChanges {
    Changes: {
        Change: HISFieldCrudChangeData[]
    } 
}

// TODO: typed as any bc we don't yet have the format of the crud save call
export function mapUiStateToCrud<T extends IBackfeedConfigData>(state: T): any {
    const result = {};
    return result;
};


export function filterCrudChanges(memoizedFields: MemoizedFieldValue[], selectedForms: string[], selectedType: string): HISFieldCrudChangeData[] {
    const result: HISFieldCrudChangeData[] = [];
    memoizedFields.forEach(field => {
        if (selectedForms.includes(field['formId']) && field.typeId === selectedType && field.originalIncludedVal !== field.included && field.ID === field.HISFieldCrudChangeData['@ID']) {
            if (field.originalIncludedVal === '1' && field.included === '0') {
                // if true and originalVal === '1', add the "@Delete='true' property to the HISCrudChangeData object and push it to the Changes
                result.push({
                    ...field.HISFieldCrudChangeData,
                    '@Delete': 'true'
                })
            } else if (selectedForms.includes(field['formId']) && field.typeId === selectedType && field.originalIncludedVal === '0' && field.included == '1') {
                // otherwise we return the field data as is
                result.push(field.HISFieldCrudChangeData);
            }
        } 
        else if (field['typeId'] !== selectedType && (field.originalIncludedVal === '1' || field.included === '1')) {
            result.push({
                ...field.HISFieldCrudChangeData,
                '@Delete': 'true'
            })
        }

    })
    return result; 
} 

function mapCrudFieldsToMemoState(fields: HISField[]): MemoizedFieldValue[] {
    return fields.map(field => {
        return {
            originalIncludedVal: field['@Included'],
            typeId: field['@TypeId'],
            formId: field['@FormId'],
            HISFieldCrudChangeData: {
                '@ID': field['@ID'],
                '@TypeId': field['@TypeId'],
                '@FormId': field['@FormId'],
            }
        }
    })
}

export function mapCrudToUiState(crud: MCHISFieldConfigurationInfo): IBackfeedConfigUIState {
    const { TypeList, FormTypeList, FieldList } = crud;
    let item = TypeList.Type.find(ob => ob['@Selected'] === '1') || TypeList.Type.find(ob => ob['@ID'] === '1');
    const firstType = (item != undefined ? item['@ID'] : '1')
    const formId = FormTypeList.FormType.find(formType => formType['@TypeId'] === firstType)['@ID'] || '1'
    const firstForm = FormTypeList?.FormType?.length > 0 && formId ? formId : '1';
    return {
        selectedTypeId: (item != undefined ? item['@ID'] : ''),
        selectedFormId: firstForm,
        typeList: TypeList.Type,
        formTypeList: FormTypeList.FormType,
        fieldList: FieldList.Field,
        isDirty: false,
        isBusy: false,
        isUpdate: false,
        selectedFields: [],
        fieldRecords: FieldList.Field.filter(f => f['@TypeId'] == firstType && f['@FormId'] == firstForm && f['@Included'] == '0')?.map(field => { return { text: field['@Name'], value: field['@ID'] } }),
        includedFieldRecords: FieldList.Field.filter(f => f['@TypeId'] == firstType && f['@FormId'] == firstForm && f['@Included'] == '1')?.map(field => { return { text: field['@Name'], value: field['@ID'] } }),
        showModal: false,
        memoizedFields: mapCrudFieldsToMemoState(FieldList.Field)
    };
}


export const backfeedConfigUIReducer: Reducer<IBackfeedConfigUIState, KnownActions> =
    (state: IBackfeedConfigUIState | undefined, action: KnownActions) => {
        const isDirty = true;
        let newState = state && { ...state };

        if (state) {
            switch (action.type) {
                case 'BKFD_INIT':
                {
                    const crud = action.data.uiData;
                    if (crud) {
                        return mapCrudToUiState(crud.HISFieldConfiguration);
                    }
                    break;
                    }
                case 'BKFD_SELECT_TYPE':
                    const forms = state.formTypeList.filter(t => t['@TypeId'] == action.data);
                    const firstFormId = (forms.length > 0 ? forms[0]['@ID'] : '');
                    return {
                        ...state,
                        selectedTypeId: action.data,
                        selectedFormId: firstFormId,
                        selectedFields: [],
                        includedFieldRecords: state.fieldList.filter(f => f['@TypeId'] == action.data && f['@FormId'] == firstFormId && f['@Included'] == '1')?.map(field => { return { text: field['@Name'], value: field['@ID'] } }),
                        fieldRecords: state.fieldList.filter(f => f['@TypeId'] == action.data && f['@FormId'] == firstFormId && f['@Included'] == '0')?.map(field => { return { text: field['@Name'], value: field['@ID'] } })
                    }

                case 'BKFD_SELECT_FORM':
                    return {
                        ...state,
                        selectedFormId: action.data,
                        selectedFields: [],
                        includedFieldRecords: state.fieldList.filter(f => f['@TypeId'] == state.selectedTypeId && f['@FormId'] == action.data && f['@Included'] == '1')?.map(field => { return { text: field['@Name'], value: field['@ID'] } }),
                        fieldRecords: state.fieldList.filter(f => f['@TypeId'] == state.selectedTypeId && f['@FormId'] == action.data && f['@Included'] == '0')?.map(field => { return { text: field['@Name'], value: field['@ID'] } })
                    }
                case 'BKFD_SELECTED_FIELDS':
                    return {
                        ...state,
                        selectedFields: action.data
                    }
                case 'BKFD_UPDATE_SELECT_FIELD': {
                    const newState = { ...state };

                    if (action.data === null) return state; 
                    action.data.selectedFields.forEach((field: HISField, index) => {
                        const foundField = newState.fieldList.find(stateField => {
                            return stateField['@ID'] === field['@ID'] && field['@TypeId'] === stateField['@TypeId'] &&
                                field['@FormId'] === stateField['@FormId'];

                        })

                        if (foundField) {
                            foundField['@Included'] = action.data.included;
                            const foundMemoField = newState.memoizedFields.find(memoizedField => memoizedField.HISFieldCrudChangeData['@ID'] === foundField['@ID']
                                && memoizedField.HISFieldCrudChangeData['@TypeId'] === foundField['@TypeId'] &&
                                memoizedField.HISFieldCrudChangeData['@FormId'] === foundField['@FormId']
                            );
                            if (foundMemoField) {
                                foundMemoField['ID'] = foundField['@ID'];
                                foundMemoField['included'] = action.data.included;

                            }
                        }
                    
                    })
                    return {
                        ...newState, selectedFields: [],
                        memoizedFields: newState.memoizedFields,
                        isDirty: true, 
                        includedFieldRecords: newState.fieldList.filter(f => f['@TypeId'] == newState.selectedTypeId && f['@FormId'] == newState.selectedFormId && f['@Included'] == '1')?.map(field => { return { text: field['@Name'], value: field['@ID'] } }),
                        fieldRecords: newState.fieldList.filter(f => f['@TypeId'] == state.selectedTypeId && f['@FormId'] == state.selectedFormId && f['@Included'] == '0')?.map(field => { return { text: field['@Name'], value: field['@ID'] } })
                    }
                }
                default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;
            }
        }
        return newState || defaultBackfeedConfigState;
    }