import * as React from 'react';
import styled from 'styled-components';

export interface IComponentProps {
    Name?: string;
    Order?: number;
    ComponentClass?: string;
    W?: number;
    H?: number;
    Padding?: string;
    Parent?: string;
}

const ComponentStyle = styled.div.attrs((props: IComponentProps) => {
        var nameObj = (props.Name != null) ?
        {
            id: props.Name,
        } : {};
        var classObj = (props.ComponentClass != null) ?
        {
            className: props.ComponentClass,
        } : {};
        return { ...nameObj, ...classObj };
    }
)`
    
    ${(props: IComponentProps) => props.hasOwnProperty("Padding") && `
        padding: ${ (props.Padding && props.Padding.length >= 0) ? props.Padding : '3px'};
    `};
    ${(props: IComponentProps) => props.hasOwnProperty("Order") && `
        order: ${props.Order};
    `};
    ${(props: IComponentProps) => props.hasOwnProperty("W") && `
        width: ${ (props.W && props.W >= 0) ? props.W + 'px' : '100%'};
    `};
    ${(props: IComponentProps) => props.hasOwnProperty("H") && `
        height: ${ (props.H && props.H >= 0) ? props.H + 'px' : (props.H == -1) ? '100%' : 'auto'};
    `};
`;

export class LandingPageComponent extends React.PureComponent<IComponentProps> {
    static defaultProps: IComponentProps = {
    };

    constructor(props: IComponentProps) {
        super(props);
    }

    public render() {
        return (
            <ComponentStyle {...this.props}>
                {this.props.children}
            </ComponentStyle>
        );
    }
};
