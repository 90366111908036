import { URLs } from '@commonDevResources/constants';

export function saveComponentState(id: any, data: any) {
    if (!id || !data) return;
    try {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', URLs.api + "/api/data/SaveUIComponent/" + id, true);
        xhr.setRequestHeader("Content-Type", "application/json");
        xhr.send(data);
    }
    catch (exception) {
        console.log('unable to save component state. ' + id);
        console.log(data);
    }


}