import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';

import { Tabs, Input, Button, SelectDropdownV2 } from 'ui-core';

import { pageLeave } from '@commonResources/userModified';

import {
    Buttons,
    ContentWrapperStyle as ContentWrapper,
    Labels as LabelStyles,
    ArmTabContainer
} from '@common/component-styles';
import { DialogWrapper, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { IModalConfirmationProps, ModalConfirmation } from '@common/ModalConfirmation';
import { ARMDatePicker as DatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { FormatDateAsString } from '../../../scripts/util/FormatHelpers';

import { ApplicationState } from '@store/index';
import { IViewManualEligibilityState, IViewManualEligibilityActionProps, actionCreators } from '@store/ViewManualEligibility';
import ApiClient, { HttpStatus } from '@commonResources/ApiClient';
import { ARMGrid } from '../../common/UICLWrappers/ARMGrid';
import { ARMHeaderCell } from '../../common/UICLWrappers/ARMHeaderCell';
import { SortHelper, ISortIndicator, SortDataType } from '@scripts/util/SortHelper';
import "./ViewManualEligibility.css"

//import { SelectPayerComponent } from '@common/SelectPayer';


//===========================================================================================


const Page = {
    Selectors: {
        idGrid: "searchResults",
        idSelectPayer: "selectOptionsPayers",
        idInsuredIdFrom: "insuredIdFrom",
        idInsuredIdTo: "insuredIdThrough",
        idLastNameFrom: "lastNameFrom",
        idLastNameTo: "lastNameThrough",
        idButtonSearch: "btnSSearch",
        idButtonClear: "btnClear",
        idButtonCancel: "btnCancel",
        idTabSearch: "tabSearch",
        idTabResult: "tabResults",
    },
    Default: {
        selectedPayerOption:
        {
            value: "",
            label: "- Select a Payer -"
        },
        inputState: {
            value: "",
            isDisabled: false
        },
        dateToday: {
            value: `${new Date().toLocaleDateString()}`,
            isDisabled: false
        },
    }
};
Object.freeze(Page);
Object.freeze(Page.Selectors);
Object.freeze(Page.Default);
//Object.freeze(Page.Default.dateToday);
Object.seal(Page);

//===========================================================================================

interface IComponentProps {
    canView: boolean;
    canPrint: boolean;
}

interface IComponentState {
    [index: string]: any;
    isPageBusy: boolean;
    selectedTabIndex: number;
    modalProps: IModalConfirmationProps;
    sortIndicator: ISortIndicator;

    //form inputs
    selectPayerOptions: Array<ISelectPayerOption>;
    selectedPayerOption: ISelectPayerOption;
    insuredIdFrom: IInputState;
    insuredIdThrough: IInputState;
    lastNameFrom: IInputState;
    lastNameThrough: IInputState;
    dateFrom: IInputState;
    dateThrough: IInputState;
    viewResults: Array<IEligibilitySearchResponse>;
}

const DEFAULT_STATE: IComponentState = {
    isPageBusy: true,
    selectedTabIndex: 0,
    modalProps: {
        isOpen: false,
        showDefaultClose: true,
        onModalToggle: (() => {
            return false;
        }),
        message: "",
        formattedMessage: undefined,
        onConfirm: undefined,
        onDeny: undefined,
        onOK: undefined,
        okText: "OK",
        cancelText: "Cancel"
    },
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
        sortDataType: SortDataType.String
    },

    //form inputs
    selectPayerOptions: [
        {
            ...Page.Default.selectedPayerOption
        }
    ],
    selectedPayerOption: { ...Page.Default.selectedPayerOption },
    insuredIdFrom: { ...Page.Default.inputState },
    insuredIdThrough: { ...Page.Default.inputState },
    lastNameFrom: { ...Page.Default.inputState },
    lastNameThrough: { ...Page.Default.inputState },
    dateFrom: { ...Page.Default.dateToday },
    dateThrough: { ...Page.Default.dateToday },
    viewResults: [],
};
Object.freeze(DEFAULT_STATE);


type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IViewManualEligibilityProps = IMergeApiComponentProps<IViewManualEligibilityState, IViewManualEligibilityActionProps, IOwnProps>;


//===========================================================================================

export class ViewManualEligibility extends React.Component<IViewManualEligibilityProps, IComponentState> {

    //private targetLanguage:ISO_LANG = ISO_LANG.EN;
    private readonly text = {
        pageTitle: "View Manual Eligibility Requests",
        Ok: "OK",
        Cancel: "Cancel",
        Search: "Search",
        Clear: "Clear",
        Print: "Print",

        "No Print Privilege": "You do not have security clearance to print a manual eligibility request",

        footerInstructions: `On the Search Tab, enter search criteria and click the "Search" button to see a list of Manual Eligibility Requests that match.<br />Click the "Clear" button to reset form data.<br />On the Results tab click on one of the results to see the request and response in a printable format.`,
    };


    private readonly tabs: Array<ITabItem> = [
        {
            domID: Page.Selectors.idTabSearch,
            label: "Search"
        },
        {
            domID: Page.Selectors.idTabResult,
            label: "Results"
        },
    ];

    private columnsSchemaSet: Set<any> = new Set();


    //-----------------------------------------------------------

    constructor(props: IViewManualEligibilityProps) {
        super(props);
        this.state = { ...DEFAULT_STATE };

        this.bindEventHandlers();
    }

    public componentDidMount () {
        if (!this.props.canView) {
            this.redirectToHome();
        }
        pageLeave();



        this.resetPageState(() => {
            this.refreshDataAsync()
                .then(() => {

                    //--------------------------------
                    //TO-DO: refactor
                    const firstTab = document.getElementById(this.tabs[0].domID) as HTMLElement;
                    firstTab.click();
                    //--------------------------------

                })
                .catch((error: string) => {
                    console.error(`Error while refreshing data for ViewManualEligibility: ${error}`);
                })
                .finally(() => {
                    this.setState({
                        isPageBusy: false,
                    });
                });

        });
    }

    public componentWillUnmount () {
        pageLeave();
        resetApiComponentState(this.props.action, this.props.dataStore);
    }


    //-----------------------------------------------------------
    //supporting, private functions

    /* function to bind common eventhandlers to components "this" reference; declare binding here to add to class/_proto instead of object instance */
    bindEventHandlers () {
        this.handleInputChanged = this.handleInputChanged.bind(this);
        this.handleInputChangedDateFrom = this.handleInputChangedDateFrom.bind(this);
        this.handleInputChangedDateThrough = this.handleInputChangedDateThrough.bind(this);
        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleRowSelect = this.handleRowSelect.bind(this);
    }


    private closeModal = () => {
        this.setState({
            isPageBusy: false,
            modalProps: { ...DEFAULT_STATE.modalProps }
        });
    }

    getColumnsSchemaSet = () => {
        let columnsSchemaSet = new Set([
            {
                dataName: "InsuredName",
                text: 'Insured Name',
                cellType: "text",
                sortable: true,
                sortHandler: this.sortRecords,
                //isSorted: 0,
                sortIndicator: this.state.sortIndicator,
                sortDataType: SortDataType.String,
            },
            {
                dataName: "PatientName",
                text: "Patient Name",
                cellType: "text",
                sortable: true,
                sortHandler: this.sortRecords,
                //isSorted: 0,
                sortIndicator: this.state.sortIndicator,
                sortDataType: SortDataType.String,
            },
            {
                dataName: "RequestDate",
                text: "Request Date",
                cellType: "text",
                sortable: false,
            },
            {
                dataName: "PayerName",
                text: "Payer",
                cellType: "text",
                sortable: false,
            },
            {
                dataName: "InsuredId",
                text: "Insured Id",
                cellType: "text",
                sortable: false,
            },
            {
                dataName: "StatusOfRequest",
                text: "Status of Request",
                cellType: "text",
                sortable: false,
            },
        ]);

        return (columnsSchemaSet);
    }

    private getFormInputAsJson = (): IEligibilitySearchRequest => {
        let dateFrom = this.state.dateFrom?.value?.length > 0 ? new Date(this.state.dateFrom?.value) : undefined;
        let dateTo = this.state.dateThrough?.value?.length > 0 ? new Date(this.state.dateThrough?.value) : undefined;

        let request: IEligibilitySearchRequest = {
            "PayerId": this.state.selectedPayerOption.ID,
            "InsuredIdFrom": this.state.insuredIdFrom.value,
            "InsuredIdTo": this.state.insuredIdThrough.value,
            "LastNameFrom": this.state.lastNameFrom.value,
            "LastNameTo": this.state.lastNameThrough.value,
            "DateFrom": dateFrom,
            "DateTo": dateTo,
        };

        return request;
    }

    private async getPayerOptionsAsync (): Promise<any> {
        let records: Array<ISelectPayerOption>;

        const endpoint = `${ApiClient.apiBaseURL}/data/Eligibility/GetClaimPayersAsync?getPayerFields=false`;
        return await ApiClient.jsonGetAsync(endpoint)
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    let json = await response.json();
                    if (json?.length > 0) {
                        records = Array.isArray(json) ? json : [json];

                        for (let i = 0, length = records.length; i < length; i++) {
                            records[i].value = `${records[i].ID}`;
                            records[i].label = records[i].DisplayName;
                        }
                        records.splice(0, 0, DEFAULT_STATE.selectPayerOptions[0]);
                    }
                    else {
                        records = [DEFAULT_STATE.selectPayerOptions[0]];
                    }

                    return Promise.resolve(records);
                }
                else {
                    console.error(`Attempt to retrieve Payers failed, status: ${response.status}.`);
                    return Promise.resolve([]);
                }
            })
            .catch((error) => {
                console.error(`Error while attempting to retrieve Payers failed, error: ${error}.`);
                return Promise.resolve([]);
            });
    };

    private async printSelectionAsync (selectedItem: IEligibilitySearchResponse): Promise<any> {
        if (!this.props.canPrint) {
            this.showModalMessages(this.text["No Print Privilege"]);
            return;
        }

        const transactionId = selectedItem?.EligibilityTransactionId || 0;
        if (transactionId < 1) {
            const message = "Invalid transaction ID";
            console.error(message);
            return;
        }

        let errorMessage = "";
        const endpoint = `${ApiClient.apiBaseURL}/data/Print271ByTrans/${transactionId}`;
        return ApiClient.jsonGetAsync(endpoint)
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    const jsonString = await response.json();
                    if (jsonString?.length > 0) {
                        let jsonResponse = JSON.parse(jsonString);
                        let printResponse: IPrintResponse = Array.isArray(jsonResponse.PrintResponses) ?
                            jsonResponse.PrintResponses[0] :
                            jsonResponse.PrintResponses.PrintResponse;

                        if (printResponse["@Error"].trim().length > 0) {
                            errorMessage = `Error while trying to retrive print information: ${printResponse["@Error"]}`;
                            console.error(errorMessage);
                        }
                        else if (printResponse["@Path"].trim().length === 0) {
                            errorMessage = `Error while trying to retrive print information: invalid path`;
                            console.error(errorMessage);
                        }
                        else {
                            //ApiClient.openPDF(printResponse["@Path"]);
                            window.document.open(`/shared/Open=${encodeURIComponent(printResponse["@Path"])}`, 'FormAlignment', 'width=1273,height=1250');
                            return Promise.resolve(true);
                        }
                    }
                    else {
                        errorMessage = `No print information available.`;
                        console.error(errorMessage);
                    }

                    //hopefully should never get here...
                    return Promise.reject(errorMessage);
                }
                else {
                    //check for error message
                    let errorMessage = await response.text();
                    console.error(`Attempt to request print data failed, status: ${response.status}; error message: ${errorMessage || ''}.`);
                    return Promise.reject(errorMessage);
                }
            })
            .catch((error) => {
                let errorMessage = `Error while attempting to request print, error: ${error}.`;
                console.error(errorMessage);
                return Promise.reject(errorMessage);
            });
    }

    private redirectToHome = () => {
        this.resetPageState(() => {
            //window.history.replaceState(null, 'Private', '/LandingPage'); //remove from history 
            this.props.history.push("/LandingPage", null);
            //window.location.href = '/LandingPage';
        });
    }

    private refreshDataAsync = async (): Promise<any> => {
        return await this.getPayerOptionsAsync()
            .then((selectPayerOptions: Array<ISelectPayerOption>) => {
                if (selectPayerOptions.length > 0) {
                    this.setState({
                        selectPayerOptions: selectPayerOptions
                    }, () => {
                        Promise.resolve(true);
                    })
                }
                else {
                    Promise.reject(`refreshData: no data retrieved`);
                }
            })
            .catch((error: string) => {
                const message = `error trying to retrieve data for Payer options: ${error}`;
                console.error(message);
                Promise.reject(message);
            });
    };

    private resetPageState = (callback?: () => void) => {
        const now = new Date();
        //unfortunately the default date won't stick from initial page load , so must recreate here
        const today = {
            value: now.toLocaleDateString(),
            isDisabled: false
        };

        //preserve data if possible so we don't have to make another call to the server
        //selectPayerOptions: this.state.selectPayerOptions,
        const newState = {
            ...DEFAULT_STATE,
            dateFrom: { ...today },
            dateThrough: { ...today },
        };

        this.setState(
            {
                ...newState
            },
            () => {
                if (typeof (callback) === 'function') {
                    callback();
                }
            }
        );
    }

    /**
     * Business Logic to enable/disable controls based on selected payer.
     * @param selectedPayerOption
     */
    private selectPayerOption (selectedPayerOption: IManualEligibilityCheckPayer) {
        this.setState({
            selectedPayerOption: selectedPayerOption,
        });
    }

    private showModalMessages = (messages: Array<string> | string) => {
        if (messages.length === 0) {
            return;
        }

        let array: Array<string> = !Array.isArray(messages) ? [messages] : messages;

        if (array.length > 0) {
            let i = 0;
            const formattedMessage = <>{ array.map((message) => <div key={ ++i }>{ message }</div>) }</>; //because React

            let modalProps: IModalConfirmationProps = { ...this.state.modalProps };
            modalProps.isOpen = true;
            modalProps.formattedMessage = formattedMessage;
            modalProps.onDeny = () => this.closeModal();
            modalProps.alertMode = true;
            //
            this.setState({
                modalProps: modalProps
            });
        }
    }

    sortRecords = (sortIndicator: ISortIndicator) => {
        let sortedRecords = SortHelper.Sort<IEligibilitySearchResponse>((this.state.viewResults), sortIndicator)

        this.setState({
            sortIndicator,
            viewResults: sortedRecords,
            gridScrollToTop: true,
        });

        //this.handlePaging(1); //no paging on this page
    }

    private submitSearchAsync = async (): Promise<any> => {

        if (!this.props.canView) {
            const message = "insufficient privilege(s)";
            console.error(message);
            return Promise.reject(message);
        }

        const endpoint = `${ApiClient.apiBaseURL}/data/Eligibility/LookupManualEligibilityRequests`;
        const payload = `${JSON.stringify(this.getFormInputAsJson())}`;
        return ApiClient.jsonPostAsync(endpoint, payload)
            .then(async (response: any) => {
                if (response.status === HttpStatus.OK) {
                    const jsonResponse = await response.json();
                    if (jsonResponse) {
                        let eligibilitySearchResponse = (Array.isArray(jsonResponse) ? jsonResponse : [jsonResponse]) as Array<IEligibilitySearchResponse>;

                        this.setState({
                            viewResults: eligibilitySearchResponse
                        });

                        return Promise.resolve(true);
                    }

                    //should not get here
                    let message = `Failed to capture a response.`;
                    console.error(message);
                    return Promise.reject(message);
                }
                else {
                    const rawData = await response.text();
                    const message = `Attempt to submit Manual Eligibility Check was unsuccessful -- status: ${response.status} : ${rawData}.`;
                    console.error(message);
                    return Promise.reject(message);
                }
            })
            .catch((error) => {
                const message = `Error while attempting to retrieve Payers failed, error: ${error}.`;
                console.error(message);
                return Promise.reject(message);
            });
    }

    //

    //-----------------------------------------------------------
    //event handlers


    /**
     * generic on input (text) changed event handler.
     * purpose: make input text uppercase
     * @param e
     */
    handleInputChanged (e: React.FocusEvent<HTMLInputElement>) {
        const targetId = e.currentTarget.id;
        const targetValue = e.currentTarget.value;

        //idea for using setState with dynamic key borrowed from https://stackoverflow.com/questions/46771248/react-setstate-with-dynamic-key
        //and https://stackoverflow.com/questions/29280445/reactjs-setstate-with-a-dynamic-key-name
        this.setState({
            [targetId]: {
                value: targetValue
            }
        });
    }

    handleInputChangedDateFrom (e: string) {
        //TO-DO: consider refactoring, make more generic handler
        let tmpStateObject = this.state.dateFrom;
        tmpStateObject.value = e as string;
        this.setState({
            dateFrom: { ...tmpStateObject }
        });
    }

    handleInputChangedDateThrough (e: string) {
        //TO-DO: consider refactoring, make more generic handler
        let tmpStateObject = this.state.dateThrough;
        tmpStateObject.value = e as string;
        this.setState({
            dateThrough: { ...tmpStateObject }
        });
    }


    /**
     * generic button click event handler.
     * 
     * @param e
     */
    handleButtonClick (e: React.MouseEvent<HTMLButtonElement>) {
        //The UICL component "Button" does not pass the expected standard event type with a value for the button clicked
        this.setState({
            isPageBusy: true
        });

        const psuedoValue = e.currentTarget.textContent;
        switch (psuedoValue) {
            case this.text.Search:
                this.submitSearchAsync()
                    .then((response: any) => {
                        const resultsTab = document.getElementById(this.tabs[1].domID) as HTMLElement;
                        resultsTab.click();
                    })
                    .catch((message: string) => {
                        console.error(`unknown error in ViewManualEligibility::handleButtonClick - ${message}`);
                        const searchTab = document.getElementById(this.tabs[0].domID) as HTMLElement;
                        searchTab.click();
                    })
                    .finally(() => {
                        this.setState({
                            isPageBusy: false,
                        });
                    });
                break;
            case this.text.Clear:
                this.resetPageState(() => {
                    this.refreshDataAsync()
                        .finally(() => {
                            this.setState({ isPageBusy: false });
                        });
                });
                break;
            case this.text.Cancel:
                this.redirectToHome();
                break;
            default:
                console.error(`Unknown action in ViewManualEligibility::handleButtonClick - "${psuedoValue}"`)
                this.setState({
                    isPageBusy: false
                });
                break;
        }
    }

    /**
     * Handles a row-click event
     * @param e : React.MouseEvent for an html table row (tr) element
     * @param record: the record (in the Array<any> of the ARMGrid component's "records" prop ) that is associated with the row that was clicked
     */
    async handleRowSelect (e: React.MouseEvent<HTMLTableRowElement>, record: IEligibilitySearchResponse | undefined) {
        this.setState({
            isPageBusy: true,
        });

        await this.printSelectionAsync(record as IEligibilitySearchResponse)
            .finally(() => {
                this.setState({
                    isPageBusy: false
                });
            });

        return;
    }

    /**
    * The UICL component "SelectDropdownV2" does not pass the expected TypeScript React.ChangeEvent<HTMLSelectElement> type.
    * Instead, it returns an IManualEligibilityCheckPayer object (whatever object type is used for the source options array).
    * @param e
    */
    public handleSelectPayerOption = (e: IManualEligibilityCheckPayer) => {
        this.selectPayerOption(e);
    };

    public handleSelectTab = (event: React.MouseEvent<HTMLElement>, index: number) => {
        //not trusting the second parameter of the component that raised the event
        let selectedTabId = event.currentTarget.id;
        let selectedIndex = this.tabs.findIndex(t => t.domID === selectedTabId);
        
        this.setState(
            {
                isPageBusy: true,
                selectedTabIndex: selectedIndex
            },
            () => {
                this.setState({ isPageBusy: false });
            }
        );
    }



    //-----------------------------------------------------------
    //rendering

    renderGridHeaders = (columnsComponent: any) => {
        let i: number = 0;
        return (
            <thead>
                <tr>{
                    (Array.from(columnsComponent.columns)).map((c: any, index: number) => (
                        <ARMHeaderCell
                            key={ `header_${index}` }
                            dataName={ c.dataName }
                            text={ c.text }
                            cellType={ c.cellType }
                            sortable={ c.sortable }
                            isSorted={ c.isSorted }
                            sortIndicator={ c.sortIndicator }
                            sortHandler={ c.sortHandler }
                            sortDataType={ c.sortDataType }
                            select={ c.select } />
                    ))

                }</tr>
            </thead>
        );
    }

    renderModal = () => {
        return (
            <ModalConfirmation
                isOpen={ this.state.modalProps.isOpen }
                showDefaultClose={ true }
                formattedMessage={
                    <div>{ this.state.modalProps.formattedMessage || this.state.modalProps.message }</div>
                }
                onModalToggle={ this.closeModal }
                onOK={ this.state.modalProps.onOK || this.closeModal }
                onConfirm={ this.state.modalProps.onConfirm }
                onDeny={ this.state.modalProps.onDeny || this.closeModal }
                alertMode={ this.state.modalProps.alertMode || false }
                okText={ this.state.modalProps.okText || this.text.Ok }
                cancelText={ this.state.modalProps.cancelText || this.text.Cancel }
            />
        );
    }

    renderPageButtons = () => {
        return (
            <Button
                domID={ Page.Selectors.idButtonCancel }
                name={ this.text.Cancel }
                { ...Buttons.Props.PageDefault }
                onClick={ this.handleButtonClick }
            />
        );
    }

    renderTabSearch = () => {
        const columnStyle = { width: "50%", margin: "0" };
        const prettyRowStyle = { padding: "5px 0 15px 10px", margin: "0 0 0 5px" };
        const prettyLabelStyle = { margin: "10px 15px 0 15px" };
        //date logic is from legacy
        const today = new Date();
        const minDate = new Date(today.getDate() - 365); //minDate.toLocaleDateString()
        const maxDate = today; //maxDate.toLocaleDateString()
        const inputMaxLength = 35; //from legacy

        return (
            <>
                <br />
                <ContentRowWrapper style={ prettyRowStyle }>
                    <div style={ columnStyle }>
                        <SelectDropdownV2
                            domID={ Page.Selectors.idSelectPayer }
                            className="dropdown"
                            label="Payer:"
                            size='medium'
                            isClearable={ false }
                            isSearchable={ true }
                            onChange={ this.handleSelectPayerOption }
                            options={ this.state.selectPayerOptions }
                            initialValue={ this.state.selectedPayerOption }
                            disabled={ false }
                        />
                        {/* <SelectPayerComponent
                            domID={ Page.Selectors.idSelectPayer }
                            selectedPayerOption={ this.state.selectedPayerOption }
                            onSelectPayerOption={ this.onSelectPayerOption }
                            isDisabled={ false }
                        />*/}
                    </div>
                </ContentRowWrapper>

                <LabelStyles.InputText style={prettyLabelStyle} inline={false}>Insured Id:</LabelStyles.InputText>
                <ContentRowWrapper style={ prettyRowStyle } >
                    <Input
                        className="margin-padding"
                        domID={ Page.Selectors.idInsuredIdFrom }
                        maxLength={ inputMaxLength }
                        initialValue={ this.state.insuredIdFrom.value }
                        onBlur={ this.handleInputChanged }
                        disabled={ false }
                    />
                    <LabelStyles.Default style={prettyLabelStyle}>Thru</LabelStyles.Default>
                    <Input
                        className="margin-padding"
                        domID={ Page.Selectors.idInsuredIdTo }
                        maxLength={ inputMaxLength }
                        initialValue={ this.state.insuredIdThrough.value }
                        onBlur={ this.handleInputChanged }
                        disabled={ false }
                    />
                </ContentRowWrapper>

                <LabelStyles.InputText style={prettyLabelStyle} inline={false}>Last Name:</LabelStyles.InputText>
                <ContentRowWrapper style={ prettyRowStyle }>
                    <Input
                        className="margin-padding"
                        domID={ Page.Selectors.idLastNameFrom }
                        maxLength={ inputMaxLength }
                        initialValue={ this.state.lastNameFrom.value }
                        onBlur={ this.handleInputChanged }
                        disabled={ false }
                    />
                    <LabelStyles.Default style={prettyLabelStyle}>Thru</LabelStyles.Default>
                    <Input
                        className="margin-padding"
                        domID={ Page.Selectors.idLastNameTo }
                        maxLength={ inputMaxLength }
                        initialValue={ this.state.lastNameThrough.value }
                        onBlur={ this.handleInputChanged }
                        disabled={ false }
                    />
                </ContentRowWrapper>

                <LabelStyles.InputText style={prettyLabelStyle} inline={false}>Date of Request:</LabelStyles.InputText>
                <ContentRowWrapper style={ prettyRowStyle }>
                    {/* assocTo={ this.state.dateFrom.value } */ }
                    <DatePicker
                        domID="dateFrom"
                        className="cal_style"
                        onInputChange={ this.handleInputChangedDateFrom }
                        allowTime={ false }
                        minDate={ minDate.toLocaleDateString() }
                        maxDate={ this.state.dateThrough.value }
                        initialDate={ this.state.dateFrom.value }
                        disableHowLong={ true }
                        disabled={ this.state.dateFrom.isDisabled }
                    />
                    <LabelStyles.Default style={prettyLabelStyle}>Thru</LabelStyles.Default>
                        {/* assocTo={ this.state.dateThrough.value } */ }
                    <DatePicker
                        domID="dateThrough"
                        className="cal_style"
                        onInputChange={ this.handleInputChangedDateThrough }
                        allowTime={ false }
                        minDate={ this.state.dateFrom.value }
                        maxDate={ maxDate.toLocaleDateString() }
                        initialDate={ this.state.dateThrough.value }
                        disableHowLong={ true }
                        disabled={ this.state.dateThrough.isDisabled }
                    />
                </ContentRowWrapper>

                <ContentRowWrapper style={ prettyRowStyle }>
                    <br />
                    <div style={ { ...columnStyle, textAlign: "center" } }>
                        <Button
                            domID={ Page.Selectors.idButtonSearch }
                            name={ this.text.Search }
                            { ...Buttons.Props.Form }
                            onClick={ this.handleButtonClick }
                        />
                        &nbsp;
                        <Button
                            domID={ Page.Selectors.idButtonClear }
                            name={ this.text.Clear }
                            { ...Buttons.Props.Form }
                            onClick={ this.handleButtonClick }
                        />
                    </div>
                </ContentRowWrapper>
            </>
        );
    }

    renderTabResults = () => {
        this.columnsSchemaSet = this.getColumnsSchemaSet();

        return (
            <>
                <ARMGrid
                    domID={ Page.Selectors.idGrid }
                    onClickThrough={ this.handleRowSelect }
                    isFixedHeader={ true }
                    headerComponent={ this.renderGridHeaders }
                    columns={ this.columnsSchemaSet }
                    records={ this.state.viewResults }
                    selectionKey={ 'EligibilityTransactionId' }
                    scrollToTop={ false }
                    maxHeight={ '495px' }
                />
            </>
        );
    }


    public render () {
        const helpUrl: string = `/Support/Help/HELP_Eligibility_SearchManualRequests.htm`;

        let pageButtons = this.renderPageButtons();
        const modalFragment = this.renderModal();
        //for more tabs, should use an array of JSX.Element with selectedTabIndex
        let currentTab: JSX.Element = this.state.selectedTabIndex === 0 ? this.renderTabSearch() : this.renderTabResults();
        let instruction: JSX.Element = <div style={ { textAlign: "center" } } dangerouslySetInnerHTML={ { __html: this.text.footerInstructions } }></div>

        return (
            <DialogWrapper title={ this.text.pageTitle } instruction={ instruction } helpUrl={ helpUrl } buttons={ pageButtons } isBusy={ this.state.isPageBusy }>
                <ContentWrapper>
                    <ArmTabContainer tabs={ this.tabs }>
                        <Tabs
                            tabs={ this.tabs }
                            onTabSelect={ this.handleSelectTab }
                            initialTab={ 1 }
                            selectedTabIndex={ this.state.selectedTabIndex }
                        />
                    </ArmTabContainer>
                    <ContentColumnWrapper style={ {
                        minHeight: "495px",
                        display: "block"
                    } }>
                        { currentTab }
                    </ContentColumnWrapper>
                </ContentWrapper>
                { modalFragment }
            </DialogWrapper>
        );
    }
};




const connectedHoc = connect<IViewManualEligibilityState, IViewManualEligibilityActionProps, IOwnProps, IViewManualEligibilityProps, ApplicationState>(
    createApiMapStateToProps('viewManualEligibility'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(ViewManualEligibility);


export default withRouter(connectedHoc);