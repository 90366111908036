import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import { ActionCreators, SupplementalEditsMaintenanceUIState, actionCreators as semActionCreators, reducer as semReducer } from "@store/ui/SupplementalEditsMaintenanceUI";
import { CrudTypes } from '@commonResources/CrudTypes';

export type ISupplementalEditMaintenanceState = ICrudComponentState<SupplementalEditMaintenanceInfo, SupplementalEditsMaintenanceUIState>;

export const actionCreators = createCrudComponentActions<ActionCreators>(semActionCreators);
export type SupplementalEditsMaintenanceActions = typeof actionCreators;
export type SupplementalEditsMaintenanceActionsProps = ICrudComponentActionProps<SupplementalEditsMaintenanceActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiSupplementalEdits, semReducer);
export const reducer = combineReducers<ISupplementalEditMaintenanceState>(reducers);