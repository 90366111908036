import { Reducer } from "redux";
import { createAction, ActionTypes } from "../scripts/util/ActionHelpers";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface FilterableListDemoState {
  selectedRecords: any[];
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
  setSelectedRecords: (records: any) => {
    return { type: "SET_SELECTED_RECORDS", data: records };
  },
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>["type"];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<FilterableListDemoState, KnownActions> = (
  state: FilterableListDemoState | undefined,
  action: KnownActions
) => {
  if (state != undefined) {
    switch (action.type) {
      case "SET_SELECTED_RECORDS":
        let recordsToStore = action.data;
        // We can merge records from other potential lists or override with the current list
        // The selection is an object so we need to map it
        if (typeof recordsToStore[0] === "object") {
          recordsToStore = recordsToStore.map((record: any) => record.id + "");
        }
        return { selectedRecords: recordsToStore };
      default:
        return state;
    }
  }
  return state || { selectedRecords: [] };
};
