import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getRawToken } from "@scripts/session/SecurityToken";

import { IMergeCrudComponentProps} from '@scripts/util/CrudComponentHelpers';

import { URLs } from "@commonResources/constants";
import { ApplicationState } from '@store/index';
import { IMergeComponentProps } from '../../../scripts/util/ReduxHelpers';

/////////////////////////////////////////////////////////////////////////////////////////////

interface ILaunchPerformanceScorecardComponentProps {
    canView: boolean,
    apiType: string;
}

interface IMailComponentState { 
}

export const DEFAULT_STATE: IMailComponentState = {
 
};

type IOwnProps = ILaunchPerformanceScorecardComponentProps & RouteComponentProps<{}>;
type IILaunchPerformanceScorecardProps = IMergeComponentProps<IMailComponentState, ILaunchPerformanceScorecardComponentProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class LaunchPerformanceScorecard extends React.Component<IILaunchPerformanceScorecardProps, IMailComponentState> {

    static defaultProps: ILaunchPerformanceScorecardComponentProps = {
        canView: false,
        apiType: "PerformanceScorecard",
    };

    constructor(props: IILaunchPerformanceScorecardProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        window.open('https://analyticscenter.changehealthcare.com/rcm/assurance/scorecard')
        this.props.history.push('/LandingPage');
    }

    public componentWillUnmount() {
    }

    public LaunchPerformanceScorecardApi() {
    }

    public render()
    {
        return null;
    }
};

var connectedHoc = connect<IMailComponentState, IOwnProps, IILaunchPerformanceScorecardProps,ApplicationState>(
  null 
) (LaunchPerformanceScorecard);


export default withRouter(connectedHoc);