import { combineReducers, Reducer } from "redux";
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from "@scripts/util/CrudComponentHelpers";
import * as DirectEntryAccountUI from "./ui/DirectEntryAccountUI";
import { CrudTypes } from '@commonResources/CrudTypes';

export type IDirectEntryAccountState = ICrudComponentState<MCDirectEntryAccount, DirectEntryAccountUI.IDirectEntryAccountUIState>;

export const actionCreators = createCrudComponentActions<DirectEntryAccountUI.ActionCreators>(DirectEntryAccountUI.actionCreators);
export type IDirectEntryAccountActions = typeof actionCreators;
export type IDirectEntryAccountActionProps = ICrudComponentActionProps<IDirectEntryAccountActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiDDEAccountMaint, DirectEntryAccountUI.reducer);
export const reducer = combineReducers<IDirectEntryAccountState>(reducers);