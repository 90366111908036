import { Reducer } from 'redux';
import { createApiAction, createAction, createApiBodyAction, ActionTypes, ValidationCallback, ErrorCallback } from '@scripts/util/ActionHelpers';
import { URLs } from '@commonDevResources/constants';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IConfigDataState {
    container: ICookies_Container
}

export interface IConfigData_Cookie {
    'name': string;
    'value'?: string;
}

export interface ICookies_Config {
    'name': string;
    'value'?: string;
}

export interface ICookies_Container {
    cookies?: IConfigData_Cookie[];
    config?: ICookies_Config[];
   
}
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    // NOTE: the configman data is in a whitelist on API side (IsValidConfigSettingForUI())
    getConfigData: (data: ICookies_Container, validationCallback?: ValidationCallback<any>, errorCallback?:ErrorCallback) => createApiBodyAction('GET_CONFIGDATA', `${URLs.api}/api/data/securityInfo/getCookies`, data, 'POST', JSON.stringify(data), validationCallback, errorCallback),
    setCookie: (data: IConfigData_Cookie) => createApiBodyAction('SET_COOKIE', `${URLs.api}/api/data/securityInfo/setCookie`, data, 'POST', JSON.stringify(data)),
    reset: () => createAction('CLEAR_CONFIGDATA'),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultConfigDataState: IConfigDataState = {
    container: {
        cookies: [],
        config: [],
    } };



// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export function processCookiesAction(state: IConfigDataState, action: KnownActions): IConfigDataState {
    switch (action.type) {
        case 'GET_CONFIGDATA':
        case 'SET_COOKIE':
            switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                    return state;
                case "SUCCESS":
                case "FAIL":
                    return {
                        container: action.responseData
                    }
                default:
                    const exhaustiveCheck: never = action.status.status;
            }
            break;
    }
    return state;
}

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IConfigDataState, KnownActions> = (state: IConfigDataState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'GET_CONFIGDATA':
            case 'SET_COOKIE':
                return processCookiesAction(state, action);
                break;
            case 'CLEAR_CONFIGDATA':
                return defaultConfigDataState;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
        }
    }

    return state || defaultConfigDataState;
}
