import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IAcceptRemitsUIState {
    selectedRemit: ISelectAcceptRemitsData;
    remitState: string;
}

interface IRemitUIStateUpdate {
    value: string;
    index: number;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectAcceptRemitsData {
    index: number;
    value: string;
    text: string;
};

export const defaultRemit: ISelectAcceptRemitsData = {
    index: 0,
    value: '',
    text: '',
};

export interface ISelectRemits extends ICrudActionData<MCARMRemit, ISelectAcceptRemitsData> { }
export interface IUpdateRemits extends ICrudActionData<MCARMRemit, IRemitUIStateUpdate> { }

export const actionCreators = {
    selectRemitState: (selectInfo: ISelectRemits) => createDataAction('SELECT_REMIT_STATE', selectInfo),
    updateRemitState: (remitState: IUpdateRemits) => createDataAction('UPDATE_REMIT_STATE', remitState),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IAcceptRemitsUIState = {
    selectedRemit: defaultRemit,
    remitState: '',
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IAcceptRemitsUIState, KnownActions> = (state: IAcceptRemitsUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_REMIT_STATE':
                {
                    if (action.data.masterCrud) {
                        //uiData.index is the UI-based index from the Select component; it may not be a 1:1 match to the index of the array of items in CRUD since some might have @Deleted = 1.
                        //Need to look up by text since new items could have been added with an @ID not yet set.
                        let remitData = action.data.masterCrud.RemitList.Remit.find(xRemit => xRemit["@ID"] === action.data.uiData.text);
                        return {
                            ...state,
                            initialValue: action.data.uiData.value,
                        }
                    }
                }
                break;
            case 'UPDATE_REMIT_STATE':
                {
                    if (action.data.masterCrud && state.remitState !== action.data.uiData.value) {
                        action.data.masterCrud.RemitList.Remit[action.data.uiData.index]["@State"] = action.data.uiData.value;
                        return {
                            ...state,
                            remitState: action.data.uiData.value,
                        }
                    }
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
