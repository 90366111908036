import { Reducer } from 'redux';
import { createDataAction, ActionTypes, createApiAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { IsFieldFilled, IsAlphaNumeric, IsUsernameValid, IsPasswordValid, ValidatePassword } from '@commonResources/validations';
import { URLs } from '@commonDevResources/constants';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IUserMaintenanceUIState {
    selectedUser: ISelectUserData;
    loginName: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    eMail: string;
    userRoleId: string;
    interactive: string;
    claimViewer: string;
    clientSet: string;
    featurePreview: string;
    itemName: string;
    itemValue: string;
    status: string;
    failedAttempts: string;
    passwordExpiresIn: string;
    lastLogonDate: string;
    filterProfile: string;
    reportProfile: string;
    userProfile: string;
    userType: string;
    cloneUserId: string;
    cloneUserName: string;

    loginName_userError?: string;
    firstName_userError?: string;
    lastName_userError?: string;
    password_userError?: string;
    confirmPassword_userError?: string;
    email_userError?: string;
    ciamUserName_userError?: string;
    emailConfirmed?: string;
    linkedToCiam: string;
    userInCiamJob: boolean;
    ciamActionSucceeded: boolean;
    userAcknowledged: boolean;
    ciamFirstName?: string;
    ciamLastName?: string;
    ciamUserName?: string;
    ciamMdmId?: string;
    ciamAccountStatus?: string;
    ciamLastPwdResetDate?: string;
    ciamLastWelcomeEmailDate?: string;
    modalText?: string;
    isUnlinkEnabled: boolean;
    isLinkEnabled: boolean;
    isBusy: boolean;
    newlyLinkedToCiamList?: [];
    modifiedUserList: string[];
    ciamUserNameButtonEnabled: boolean;
    ciamUserNameConfirmationModalText?: string;
    ciamUserNameConfirmationModalOpen: boolean;
    ciamUserNameOld: string;
    usersInCiamJob: [];
    retrievedCiamUserRecord: boolean;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

interface ISelectUserData {
    index: number;
    value: string;
    text: string;
};

const defaultUser: ISelectUserData = {
    index: 0,
    value: '',
    text: '',
};

interface IUserUIFieldUpdate {
    value: string;
}

interface IUserUICheckUpdate {
    value: boolean;
}


class UserFieldErrors {
    public static readonly blankLoginName: string = "User Name must not be blank.";
    public static readonly allowedInLoginName: string = "This value can only include letters, numbers, and the listed special characters: - _.";
    public static readonly allowedLoginNameLength: string = "Your user name must be between 5 and 12 characters in length.";
    public static readonly deletedUserExists: string = "A deleted user by that login name already exists.";

    public static readonly blankFirstName: string = "First Name must not be blank.";
    public static readonly invalidName: string = "The value you typed in must consist of valid characters.";

    public static readonly blankLastName: string = "Last Name must not be blank.";

    public static readonly blankPassword: string = "Password must not be blank.";
    public static readonly invalidPasswordLength: string = "Your password must be between 8 and 64 characters in length.";
    public static readonly invalidCasePassword: string = "Your password must contain at least one uppercase and one lowercase letter.";
    public static readonly specialCharPassword: string = "Your password must contain at least one special character(i.e. $,#,&,^...etc.).";
    public static readonly matchPassword: string = "The passwords did not match.";

    public static readonly valideMail: string = "Please enter a valid e-mail address.";
    public static readonly requiredEmail: string = "Email Address is required.";

    public static readonly existingUserName: string = "A user by that name already exists. Please type in a different name.";
    public static readonly existingUserEmail: string = "A user by that E-Mail address already exists. Please type in a different E-Mail address.";
    public static readonly ciamProvisioningErrorMessage: string = "An error occurred while linking the selected user(s) to CIAM. Please try again or contact support to resolve the issue.";
    public static readonly ciamUnProvisioningErrorMessage: string = "An error occurred while unlinking the selected user(s) from CIAM. Please try again or contact support to resolve the issue.";
    public static readonly ciamResetPasswordErrorMessage: string = "An error occurred while sending the reset password email. Please try again later.";
    public static readonly ciamResetPasswordSuccessMessage: string = "Reset password email sent to the user.";
    public static readonly ciamResendWelcomeEmailErrorMessage: string = "An error occurred while re-sending the welcome email. Please try again later.";
    public static readonly ciamResendWelcomeEmailSuccessMessage: string = "Welcome email re-sent to the user.";
};

export interface ISelectUser extends ICrudActionData<MCARMUser, ISelectUserData> { }
export interface IModifyUser extends ICrudActionData<MCARMUser, MCUser> { }
export interface IUpdateUser extends ICrudActionData<MCARMUser, IUserUIFieldUpdate> { }
export interface IUpdateCheckUser extends ICrudActionData<MCARMUser, IUserUICheckUpdate> { }

export const actionCreators = {
    getAssuranceUserDetails: (selectInfo: ISelectUser) => createDataAction('GET_ASSURANCE_USER_DETAILS', selectInfo),
    getMultiAssuranceUserDetails: (selectInfo: ISelectUser) => createDataAction('GET_MULTI_ASSURANCE_USER_DETAILS', selectInfo),
    addUser: (updateInfo: IModifyUser) => createDataAction('ADD_USER', updateInfo),
    updateUser: (updateInfo: IModifyUser) => createDataAction('UPDATE_USER', updateInfo),
    removeUser: (updateInfo: ISelectUser) => createDataAction('REMOVE_USER', updateInfo),
    cloneUser: (updateInfo: ISelectUser) => createDataAction('CLONE_USER', updateInfo),
    restoreUser: (updateInfo: IModifyUser) => createDataAction('RESTORE_USER', updateInfo),

    updateLoginName: (loginName: string) => createDataAction('UPDATE_LOGINNAME', loginName),
    updateFirstName: (firstName: string) => createDataAction('UPDATE_FIRSTNAME', firstName),
    updateLastName: (lastName: string) => createDataAction('UPDATE_LASTNAME', lastName),
    verifyCiamUserName: (ciamUser: ISelectUser) => createDataAction('VERIFY_CIAMUSERNAME', ciamUser),
    updateCiamUserNameConfirmationModal: (isOpen: boolean) => createDataAction('UPDATE_CIAMUSERNAME_CONFIRMATION_MODAL', isOpen),
    updateCiamUserNameConfirmationText: (modalText: string) => createDataAction('UPDATE_CIAMUSERNAME_CONFIRMATION_MODAL_TEXT', modalText),

    updatePassword: (password: string) => createDataAction('UPDATE_PASSWORD', password),
    updateConfirmPassword: (confPassword: string) => createDataAction('UPDATE_CONFPASSWORD', confPassword),
    updateEmail: (eMail: string) => createDataAction('UPDATE_EMAIL', eMail),
    updateLinkEnabled: (link: boolean) => createDataAction('UPDATE_LINK_ENABLED', link),
    updateUnlinkEnabled: (unlink: boolean) => createDataAction('UPDATE_UNLINK_ENABLED', unlink),

    updateEmailConfirmed: (updateInfo: ISelectUser) => createDataAction('UPDATE_EMAIL_CONFIRMED', updateInfo),
    updateInteractiveUser: (updateInfo: IUpdateUser) => createDataAction('UPDATE_INTERACTIVE', updateInfo),
    updateClaimViewerUser: (updateInfo: IUpdateUser) => createDataAction('UPDATE_CLAIMVIEWER', updateInfo),
    updateCloneUser: (updateInfo: IUpdateUser) => createDataAction('CLEAR_CLONEUSER', updateInfo),

    updateUserRole: (userRoleId: IUpdateUser) => createDataAction('UPDATE_USERROLE', userRoleId),
    updateClientSet: (clientSet: IUpdateUser) => createDataAction('UPDATE_CLIENTSET', clientSet),
    updateFeaturePreview: (featurePreview: IUpdateUser) => createDataAction('UPDATE_FEATUREPREVIEW', featurePreview),
    updateAccountStatus: (status: IUpdateUser) => createDataAction('UPDATE_ACCOUNTSTATUS', status),
    getCiamUserDetails: (userInfo: ISelectUser) => createApiAction('GET_CIAM_USER_DETAILS', `${URLs.api}/api/data/ciam/getUser/${userInfo.uiData.value}`, userInfo),
    
    getUserCiamJobStatus: (userInfo: ISelectUser) => createApiAction('GET_USER_CIAM_JOB_STATUS', `${URLs.api}/api/data/ciam/getUserCiamJobStatus/${userInfo?.uiData?.text}`, userInfo),
    linkToCiam: (userInfo: ISelectUser) => createApiBodyAction('LINK_TO_CIAM', `${URLs.api}/api/data/ciam/provisionClientUserJson1`, userInfo, "POST", userInfo?.uiData?.text),
    linkToCiamUsingJobQueue: (userInfo: ISelectUser) => createApiBodyAction('LINK_TO_CIAM_USING_JOB_QUEUE', `${URLs.api}/api/data/ciam/provisionClientUserJson`, userInfo, "POST", userInfo?.uiData?.text),
    unlinkCiam: (userInfo: ISelectUser) => createApiBodyAction('UNLINK_CIAM', `${URLs.api}/api/data/ciam/unprovision1`, userInfo, "POST", userInfo?.uiData?.text),
    unlinkCiamUsingJobQueue: (userInfo: ISelectUser) => createApiBodyAction('UNLINK_CIAM_USING_JOB_QUEUE', `${URLs.api}/api/data/ciam/unprovision`, userInfo, "POST", userInfo?.uiData?.text),
    ciamResendWelcomeEmail: (userId: string) => createApiBodyAction('RESEND_WELCOME_EMAIL', `${URLs.api}/api/data/ciam/resendWelcomeEmail/${userId}`, undefined, "POST", undefined),
    ciamResetPassword: (userId: string) => createApiBodyAction('RESET_PASSWORD', `${URLs.api}/api/data/ciam/resetuser/${userId}`, undefined, "POST", undefined),
    updateCiamUserName: (userInfo: ISelectUser) => createApiBodyAction('UPDATE_CIAM_USERNAME', `${URLs.api}/api/data/ciam/updateUserEmail/${userInfo?.uiData?.value}`, userInfo, "POST", userInfo?.uiData?.text),
    setModalText: (modalText: string) => createDataAction('SET_MODAL_TEXT', modalText),
    setBusy: (isBusy: boolean) => createDataAction('UM_SET_BUSY', isBusy),
    updateCiamJobStatus: (userInfo: ISelectUser) => createApiBodyAction('UPDATE_CIAM_JOB_STATUS', `${URLs.api}/api/data/ciam/updateUserCiamJobStatus/${userInfo?.uiData?.value}`, userInfo, "POST", userInfo?.uiData?.text),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IUserMaintenanceUIState = {
    selectedUser: defaultUser,
    loginName: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    eMail: "",
    userRoleId: "0",
    interactive: "1",
    claimViewer: "0",
    clientSet: "-1",
    status: "0",
    featurePreview: "",
    itemName: "",
    itemValue: "",
    failedAttempts: "",
    passwordExpiresIn: "",
    lastLogonDate: "",
    filterProfile: "",
    reportProfile: "",
    userProfile: "",
    userType: "",
    cloneUserId: "",
    cloneUserName: "",
    emailConfirmed: "0",
    linkedToCiam: "0",
    userInCiamJob: false,
    ciamActionSucceeded: true,
    userAcknowledged: true,
    ciamFirstName: "",
    ciamLastName: "",
    ciamUserName: "",
    ciamMdmId: "",
    ciamAccountStatus: "",
    ciamLastPwdResetDate: "",
    ciamLastWelcomeEmailDate: "",
    modalText: "",
    isLinkEnabled: false,
    isUnlinkEnabled: false,
    isBusy: false,
    modifiedUserList: [],
    ciamUserNameButtonEnabled: false,
    ciamUserNameConfirmationModalText: '',
    ciamUserNameConfirmationModalOpen: false,
    ciamUserNameOld: '',
    usersInCiamJob: [],
    retrievedCiamUserRecord: false,
};


function getFeaturePreview(itemName: string, itemValue: string): string {
    let featurePreview: string = "";
    if (itemName === "CFI_Mode") {
        if (itemValue === "Legacy") {
            featurePreview = "CFI Legacy Only";
        } else if (itemValue === "Legacy+") {
            featurePreview = "CFI Legacy Plus";
        } else {
            featurePreview = "";
        }
    }
    return featurePreview;
}

function getClaimViewer(userType: string): string {
    return (userType === "C") ? "1" : "0";
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IUserMaintenanceUIState, KnownActions> = (state: IUserMaintenanceUIState | undefined, action: KnownActions) => {
    
    if (state != undefined) {
        switch (action.type) {
            case 'GET_ASSURANCE_USER_DETAILS':
                {
                    if (action.data.masterCrud) {
                        let userData = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                        let featurePreview: string = userData ? getFeaturePreview(userData["@ItemName"], userData["@ItemValue"]) || "" : "";
                        let claimViewer: string = userData ? getClaimViewer(userData["@UserType"]) : "0";
                        return {
                            ...state,
                            selectedUser: action.data.uiData,
                            loginName: userData ? userData["@LoginName"] || "" : "",
                            firstName: userData ? userData["@FirstName"] || "" : "",
                            lastName: userData ? userData["@LastName"] || "" : '',
                            password: userData ? userData["@Password"] || "" : "",
                            confirmPassword: userData ? userData["@ConfirmPassword"] || "" : "",
                            eMail: userData ? userData["@EMail"] || "" : "",
                            userRoleId: userData ? userData["@UserRoleId"] || "" : "",
                            interactive: userData ? userData["@Interactive"] || "1" : "1",
                            claimViewer: claimViewer,
                            clientSet: userData ? userData["@ClientSetId"] || "-1" : "-1",
                            itemName: userData ? userData["@ItemName"] || "" : "",
                            itemValue: userData ? userData["@ItemValue"] || "" : "",
                            featurePreview: featurePreview,
                            status: userData ? userData["@Status"] || "0" : "0",
                            failedAttempts: userData ? userData["@FailedAttempts"] || "" : "",
                            passwordExpiresIn: userData ? userData["@PWExpiring"] || "" : "",
                            lastLogonDate: userData ? userData["@LastLogonDate"] || "" : "",
                            filterProfile: userData ? userData["@FilterProfile"] || "" : "",
                            reportProfile: userData ? userData["@ReportProfile"] || "" : "",
                            cloneUserId: userData ? userData["@CloneUserId"] || "" : "",
                            cloneUserName: userData ? userData["@CloneUserName"] || "" : "",
                            userProfile: userData ? userData["@UserProfileName"] || "" : "",
                            emailConfirmed: userData ? userData["@EmailConfirmed"] || "" : "",
                            linkedToCiam: userData ? userData["@LinkedToCIAM"] || "" : "",
                            loginName_userError: undefined,
                            firstName_userError: undefined,
                            lastName_userError: undefined,
                            password_userError: undefined,
                            confirmPassword_userError: undefined,
                            email_userError: undefined,
                            isLinkEnabled: userData? true : false,
                            isUnlinkEnabled: userData && userData["@LinkedToCIAM"] == "1" ? true : false,
                            ciamFirstName: "",
                            ciamLastName: "",
                            ciamUserName: "",
                            ciamMdmId: "",
                            ciamAccountStatus: "",
                            ciamLastPwdResetDate: "",
                            ciamLastWelcomeEmailDate: "",
                        }
                    }
                }
                break;
            case 'GET_MULTI_ASSURANCE_USER_DETAILS':
                {
                    if (action.data.masterCrud) {
                        let userData = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                        let featurePreview: string = userData ? getFeaturePreview(userData["@ItemName"], userData["@ItemValue"]) || "" : "";
                        let claimViewer: string = userData ? getClaimViewer(userData["@UserType"]) : "0";
                        return {
                            ...state,
                            selectedUser: action.data.uiData,
                            loginName: userData ? userData["@LoginName"] || "" : "",
                            firstName: userData ? userData["@FirstName"] || "" : "",
                            lastName: userData ? userData["@LastName"] || "" : '',
                            password: userData ? userData["@Password"] || "" : "",
                            confirmPassword: userData ? userData["@ConfirmPassword"] || "" : "",
                            eMail: userData ? userData["@EMail"] || "" : "",
                            userRoleId: userData ? userData["@UserRoleId"] || "" : "",
                            interactive: userData ? userData["@Interactive"] || "1" : "1",
                            claimViewer: claimViewer,
                            clientSet: userData ? userData["@ClientSetId"] || "-1" : "-1",
                            itemName: userData ? userData["@ItemName"] || "" : "",
                            itemValue: userData ? userData["@ItemValue"] || "" : "",
                            featurePreview: featurePreview,
                            status: userData ? userData["@Status"] || "0" : "0",
                            failedAttempts: userData ? userData["@FailedAttempts"] || "" : "",
                            passwordExpiresIn: userData ? userData["@PWExpiring"] || "" : "",
                            lastLogonDate: userData ? userData["@LastLogonDate"] || "" : "",
                            filterProfile: userData ? userData["@FilterProfile"] || "" : "",
                            cloneUserId: userData ? userData["@CloneUserId"] || "" : "",
                            cloneUserName: userData ? userData["@CloneUserName"] || "" : "",
                            reportProfile: userData ? userData["@ReportProfile"] || "" : "",
                            userProfile: userData ? userData["@UserProfileName"] || "" : "",
                            emailConfirmed: userData ? userData["@EmailConfirmed"] || "" : "",
                            linkedToCiam: userData ? userData["@LinkedToCIAM"] || "" : "",
                            loginName_userError: undefined,
                            firstName_userError: undefined,
                            lastName_userError: undefined,
                            password_userError: undefined,
                            confirmPassword_userError: undefined,
                            email_userError: undefined,
                            isLinkEnabled: true,
                            isUnlinkEnabled: true,
                            ciamFirstName: "",
                            ciamLastName: "",
                            ciamUserName: "",
                            ciamMdmId: "",
                            ciamAccountStatus: "",
                            ciamLastPwdResetDate: "",
                            ciamLastWelcomeEmailDate: "",
                        }
                    }
                }
                break;
            case 'GET_CIAM_USER_DETAILS':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            if (action.data.masterCrud) {
                                let userData = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                                let featurePreview: string = userData ? getFeaturePreview(userData["@ItemName"], userData["@ItemValue"]) || "" : "";
                                let claimViewer: string = userData ? getClaimViewer(userData["@UserType"]) : "0";
                                let ciamResponse: any = action.responseData;
                                userData["@LinkedToCIAM"] = "1";

                                return {
                                    ...state,
                                    selectedUser: action.data.uiData,
                                    loginName: userData ? userData["@LoginName"] || "" : "",
                                    firstName: userData ? userData["@FirstName"] || "" : "",
                                    lastName: userData ? userData["@LastName"] || "" : '',
                                    password: userData ? userData["@Password"] || "" : "",
                                    confirmPassword: userData ? userData["@ConfirmPassword"] || "" : "",
                                    eMail: userData ? userData["@EMail"] || "" : "",
                                    userRoleId: userData ? userData["@UserRoleId"] || "" : "",
                                    interactive: userData ? userData["@Interactive"] || "1" : "1",
                                    claimViewer: claimViewer,
                                    clientSet: userData ? userData["@ClientSetId"] || "-1" : "-1",
                                    itemName: userData ? userData["@ItemName"] || "" : "",
                                    itemValue: userData ? userData["@ItemValue"] || "" : "",
                                    featurePreview: featurePreview,
                                    status: userData ? userData["@Status"] || "0" : "0",
                                    failedAttempts: userData ? userData["@FailedAttempts"] || "" : "",
                                    passwordExpiresIn: userData ? userData["@PWExpiring"] || "" : "",
                                    lastLogonDate: userData ? userData["@LastLogonDate"] || "" : "",
                                    filterProfile: userData ? userData["@FilterProfile"] || "" : "",
                                    cloneUserId: userData ? userData["@CloneUserId"] || "" : "",
                                    cloneUserName: userData ? userData["@CloneUserName"] || "" : "",
                                    reportProfile: userData ? userData["@ReportProfile"] || "" : "",
                                    userProfile: userData ? userData["@UserProfileName"] || "" : "",
                                    emailConfirmed: userData ? userData["@EmailConfirmed"] || "" : "",
                                    linkedToCiam: userData ? userData["@LinkedToCIAM"] || "" : "",
                                    loginName_userError: undefined,
                                    firstName_userError: undefined,
                                    lastName_userError: undefined,
                                    password_userError: undefined,
                                    confirmPassword_userError: undefined,
                                    email_userError: undefined,
                                    ciamFirstName: ciamResponse?.ciamIdentity?.firstName,
                                    ciamLastName: ciamResponse?.ciamIdentity?.lastName,
                                    ciamUserName: ciamResponse?.ciamIdentity?.email,
                                    ciamMdmId: ciamResponse?.ciamIdentity?.company ? ciamResponse.ciamIdentity.company.mdmAccountGlobalID : "",
                                    ciamAccountStatus: ciamResponse?.ciamIdentity?.isLocked ? "Locked" : (ciamResponse?.ciamIdentity?.isFrozen ? "Disabled" : "Enabled"),
                                    ciamLastPwdResetDate: ciamResponse?.resetPwdEmailSentDateString.substring(0, 8) == "1/1/0001" ? "Never" : ciamResponse.resetPwdEmailSentDateString,
                                    ciamLastWelcomeEmailDate: ciamResponse?.resendWelcomeEmailDateString.substring(0, 8) == "1/1/0001" ? "Never" : ciamResponse.resendWelcomeEmailDateString,
                                    isBusy: false,
                                    isLinkEnabled: true,
                                    isUnlinkEnabled: true,
                                    ciamUserNameButtonEnabled: false,
                                    retrievedCiamUserRecord: true
                                }
                            }
                        case "FAIL":
                            alert("Failed to get CIAM info (" + action.status.responseCode + ")");
                            if (action.data.masterCrud) {
                                let userData = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                                let featurePreview: string = userData ? getFeaturePreview(userData["@ItemName"], userData["@ItemValue"]) || "" : "";
                                let claimViewer: string = userData ? getClaimViewer(userData["@UserType"]) : "0";
                                if (userData && action.status.responseCode == 400)
                                    userData["@LinkedToCIAM"] = ""; // since we failed to get CIAM info, user might not be linked?

                                return {
                                    ...state,
                                    selectedUser: action.data.uiData,
                                    loginName: userData ? userData["@LoginName"] || "" : "",
                                    firstName: userData ? userData["@FirstName"] || "" : "",
                                    lastName: userData ? userData["@LastName"] || "" : '',
                                    password: userData ? userData["@Password"] || "" : "",
                                    confirmPassword: userData ? userData["@ConfirmPassword"] || "" : "",
                                    eMail: userData ? userData["@EMail"] || "" : "",
                                    userRoleId: userData ? userData["@UserRoleId"] || "" : "",
                                    interactive: userData ? userData["@Interactive"] || "1" : "1",
                                    claimViewer: claimViewer,
                                    clientSet: userData ? userData["@ClientSetId"] || "-1" : "-1",
                                    itemName: userData ? userData["@ItemName"] || "" : "",
                                    itemValue: userData ? userData["@ItemValue"] || "" : "",
                                    featurePreview: featurePreview,
                                    status: userData ? userData["@Status"] || "0" : "0",
                                    failedAttempts: userData ? userData["@FailedAttempts"] || "" : "",
                                    passwordExpiresIn: userData ? userData["@PWExpiring"] || "" : "",
                                    lastLogonDate: userData ? userData["@LastLogonDate"] || "" : "",
                                    filterProfile: userData ? userData["@FilterProfile"] || "" : "",
                                    cloneUserId: userData ? userData["@CloneUserId"] || "" : "",
                                    cloneUserName: userData ? userData["@CloneUserName"] || "" : "",
                                    reportProfile: userData ? userData["@ReportProfile"] || "" : "",
                                    userProfile: userData ? userData["@UserProfileName"] || "" : "",
                                    emailConfirmed: userData ? userData["@EmailConfirmed"] || "" : "",
                                    linkedToCiam: userData ? userData["@LinkedToCIAM"] || "" : "",
                                    loginName_userError: undefined,
                                    firstName_userError: undefined,
                                    lastName_userError: undefined,
                                    password_userError: undefined,
                                    confirmPassword_userError: undefined,
                                    email_userError: undefined,
                                    isBusy: false,
                                    isLinkEnabled: true,
                                    isUnlinkEnabled: true,
                                    ciamUserNameButtonEnabled: false,
                                    retrievedCiamUserRecord: action.status.responseCode == 400 ? true : false // allow unlink if CIAM status is unknown
                                }
                            }
                        };
                    }
                break; 
            case 'GET_USER_CIAM_JOB_STATUS':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            let result: any = action.responseData;
                            let ciamActionSucceeded: any = result.ciamActionSucceeded;
                            let userAcknowledged: any = result.userAcknowledged;

                            if (result != undefined) { 
                                if (result.userInCiamJob === true) {
                                    return {
                                        ...state,
                                        userInCiamJob: true,
                                        ciamActionSucceeded: ciamActionSucceeded,
                                        userAcknowledged: userAcknowledged,
                                    }
                                }
                                else {
                                    //if we just linked to ciam and previously weren't, then display ciam user
                                    if (result.linkedToCiam === true) {
                                        if (action.data.masterCrud) {
                                            let userData = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                                            let featurePreview: string = userData ? getFeaturePreview(userData["@ItemName"], userData["@ItemValue"]) || "" : "";
                                            let claimViewer: string = userData ? getClaimViewer(userData["@UserType"]) : "0";
                                            userData["@LinkedToCIAM"] = "1";
                                            return {
                                                ...state,
                                                selectedUser: action.data.uiData,
                                                loginName: userData ? userData["@LoginName"] || "" : "",
                                                firstName: userData ? userData["@FirstName"] || "" : "",
                                                lastName: userData ? userData["@LastName"] || "" : '',
                                                password: userData ? userData["@Password"] || "" : "",
                                                confirmPassword: userData ? userData["@ConfirmPassword"] || "" : "",
                                                eMail: userData ? userData["@EMail"] || "" : "",
                                                userRoleId: userData ? userData["@UserRoleId"] || "" : "",
                                                interactive: userData ? userData["@Interactive"] || "1" : "1",
                                                claimViewer: claimViewer,
                                                clientSet: userData ? userData["@ClientSetId"] || "-1" : "-1",
                                                itemName: userData ? userData["@ItemName"] || "" : "",
                                                itemValue: userData ? userData["@ItemValue"] || "" : "",
                                                featurePreview: featurePreview,
                                                status: userData ? userData["@Status"] || "0" : "0",
                                                failedAttempts: userData ? userData["@FailedAttempts"] || "" : "",
                                                passwordExpiresIn: userData ? userData["@PWExpiring"] || "" : "",
                                                lastLogonDate: userData ? userData["@LastLogonDate"] || "" : "",
                                                filterProfile: userData ? userData["@FilterProfile"] || "" : "",
                                                reportProfile: userData ? userData["@ReportProfile"] || "" : "",
                                                cloneUserId: userData ? userData["@CloneUserId"] || "" : "",
                                                cloneUserName: userData ? userData["@CloneUserName"] || "" : "",
                                                userProfile: userData ? userData["@UserProfileName"] || "" : "",
                                                emailConfirmed: userData ? userData["@EmailConfirmed"] || "" : "",
                                                linkedToCiam: "1",
                                                loginName_userError: undefined,
                                                firstName_userError: undefined,
                                                lastName_userError: undefined,
                                                password_userError: undefined,
                                                confirmPassword_userError: undefined,
                                                email_userError: undefined,
                                                isLinkEnabled: true,
                                                isUnlinkEnabled: true,
                                                userInCiamJob: false,
                                                ciamActionSucceeded: ciamActionSucceeded,
                                                userAcknowledged: userAcknowledged,
                                            }
                                        }
                                    }
                                    if (result.linkedToCiam === false) {
                                        if (action.data.masterCrud) {
                                            let userData = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                                            let featurePreview: string = userData ? getFeaturePreview(userData["@ItemName"], userData["@ItemValue"]) || "" : "";
                                            let claimViewer: string = userData ? getClaimViewer(userData["@UserType"]) : "0";
                                            userData["@LinkedToCIAM"] = "0";
                                            return {
                                                ...state,
                                                selectedUser: action.data.uiData,
                                                loginName: userData ? userData["@LoginName"] || "" : "",
                                                firstName: userData ? userData["@FirstName"] || "" : "",
                                                lastName: userData ? userData["@LastName"] || "" : '',
                                                password: userData ? userData["@Password"] || "" : "",
                                                confirmPassword: userData ? userData["@ConfirmPassword"] || "" : "",
                                                eMail: userData ? userData["@EMail"] || "" : "",
                                                userRoleId: userData ? userData["@UserRoleId"] || "" : "",
                                                interactive: userData ? userData["@Interactive"] || "1" : "1",
                                                claimViewer: claimViewer,
                                                clientSet: userData ? userData["@ClientSetId"] || "-1" : "-1",
                                                itemName: userData ? userData["@ItemName"] || "" : "",
                                                itemValue: userData ? userData["@ItemValue"] || "" : "",
                                                featurePreview: featurePreview,
                                                status: userData ? userData["@Status"] || "0" : "0",
                                                failedAttempts: userData ? userData["@FailedAttempts"] || "" : "",
                                                passwordExpiresIn: userData ? userData["@PWExpiring"] || "" : "",
                                                lastLogonDate: userData ? userData["@LastLogonDate"] || "" : "",
                                                filterProfile: userData ? userData["@FilterProfile"] || "" : "",
                                                reportProfile: userData ? userData["@ReportProfile"] || "" : "",
                                                cloneUserId: userData ? userData["@CloneUserId"] || "" : "",
                                                cloneUserName: userData ? userData["@CloneUserName"] || "" : "",
                                                userProfile: userData ? userData["@UserProfileName"] || "" : "",
                                                emailConfirmed: userData ? userData["@EmailConfirmed"] || "" : "",
                                                linkedToCiam: "0",
                                                loginName_userError: undefined,
                                                firstName_userError: undefined,
                                                lastName_userError: undefined,
                                                password_userError: undefined,
                                                confirmPassword_userError: undefined,
                                                email_userError: undefined,
                                                isLinkEnabled: true,
                                                isUnlinkEnabled: false,
                                                userInCiamJob: false,
                                                ciamActionSucceeded: ciamActionSucceeded,
                                                userAcknowledged: userAcknowledged,
                                            }
                                        }
                                    }
                                }
                            }
                        case "FAIL":
                            return {
                                ...state,
                                isBusy: false,
                                userInCiamJob: false,
                                ciamActionSucceeded: ciamActionSucceeded,
                                userAcknowledged: userAcknowledged,
                            }
                    };
                }
                break;
            case 'ADD_USER':
            case 'UPDATE_USER':
                {
                    if (action.data.masterCrud) {
                        let user: MCUser = action.data.uiData;
                        let newStateData: IUserMaintenanceUIState = { ...state };
                        newStateData.loginName_userError = undefined;
                        newStateData.firstName_userError = undefined;
                        newStateData.lastName_userError = undefined;
                        newStateData.password_userError = undefined;
                        newStateData.confirmPassword_userError = undefined;
                        newStateData.email_userError = undefined;

                        if (!IsFieldFilled(user["@LoginName"])) {
                            newStateData.loginName_userError = UserFieldErrors.blankLoginName;
                            return newStateData;
                        }

                        if (!IsUsernameValid(user["@LoginName"])) {
                            newStateData.loginName_userError = UserFieldErrors.allowedInLoginName;
                            return newStateData;
                        }

                        if (user["@LoginName"] && (user["@LoginName"].length < 5 || user["@LoginName"].length > 12)) {
                            newStateData.loginName_userError = UserFieldErrors.allowedLoginNameLength;
                            return newStateData;
                        }

                        if (user["@LoginName"] && user["@LoginName"].indexOf(' ') >= 0) {
                            newStateData.loginName_userError = UserFieldErrors.allowedInLoginName;
                            return newStateData;
                        }

                        if (!IsFieldFilled(user["@FirstName"])) {
                            newStateData.firstName_userError = UserFieldErrors.blankFirstName;
                            return newStateData;
                        }

                        if (!IsAlphaNumeric(user["@FirstName"])) {
                            newStateData.firstName_userError = UserFieldErrors.invalidName;
                            return newStateData;
                        }

                        if (!IsFieldFilled(user["@LastName"])) {
                            newStateData.lastName_userError = UserFieldErrors.blankLastName;
                            return newStateData;
                        }

                        if (!IsAlphaNumeric(user["@LastName"])) {
                            newStateData.lastName_userError = UserFieldErrors.invalidName;
                            return newStateData;
                        }

                        let clientPolicies: MCAccountPolicy[] = action.data.masterCrud.UserMaintenanceInfo.ClientAccountPolicies.ClientAccountPolicy;

                        let userType:string = user["@Interactive"] === "1" && user["@ClaimViewer"] === "1" ? "C" : "U";


                        let clientPolicyIndex: number = clientPolicies.findIndex(cp => cp["@UserType"] === userType);

                        if (clientPolicyIndex !== -1) {
                            let clientPolicy: MCAccountPolicy = clientPolicies[clientPolicyIndex];

                            if (action.type === 'ADD_USER' && !IsFieldFilled(user["@Password"])) {
                                newStateData.password_userError = UserFieldErrors.blankPassword;
                                return newStateData;
                            }

                            if (!IsPasswordValid(user["@Password"], Number(clientPolicy["@MinLength"]), clientPolicy["@AlphaNumeric"],
                                clientPolicy["@UpperLowerCase"], clientPolicy["@SpecialChar"])) {
                                if (action.type === 'ADD_USER') {
                                    if (user["@Password"] && (user["@Password"].length < 8 || user["@Password"].length > 64)) {
                                        newStateData.password_userError = UserFieldErrors.invalidPasswordLength;
                                        return newStateData;
                                    } else if (user["@Password"].search(/[a-z]/) === -1 || user["@Password"].search(/[A-Z]/) === -1) {
                                        newStateData.password_userError = UserFieldErrors.invalidCasePassword;
                                        return newStateData;
                                    } else if (user["@Password"].search(/[$\~\`\!@#\$%\^&\*\(\)_\+\-=\|<>\?]/) === -1) {
                                        newStateData.password_userError = UserFieldErrors.specialCharPassword;
                                        return newStateData;
                                    } 
                                } else if (action.type === 'UPDATE_USER' && (user["@Password"] !== "" || user["@ConfirmPassword"] !== "")) {
                                    if (user["@Password"] && (user["@Password"].length < 8 || user["@Password"].length > 64)) {
                                        newStateData.password_userError = UserFieldErrors.invalidPasswordLength;
                                        return newStateData;
                                    } else if (user["@Password"] && (user["@Password"].search(/[a-z]/) === -1 || user["@Password"].search(/[A-Z]/) === -1)) {
                                        newStateData.password_userError = UserFieldErrors.invalidCasePassword;
                                        return newStateData;
                                    } else if (user["@Password"] && (user["@Password"].search(/[$\~\`\!@#\$%\^&\*\(\)_\+\-=\|<>\?]/) === -1)) {
                                        newStateData.password_userError = UserFieldErrors.specialCharPassword;
                                        return newStateData;
                                    } 
                                } 
                            } 

                            if (user["@Password"] != user["@ConfirmPassword"]) {
                                newStateData.confirmPassword_userError = UserFieldErrors.matchPassword;
                                return newStateData;
                            }

                            let isUserInteractiveOnly: boolean = user["@Interactive"] === "1" && user["@ClaimViewer"] !== "1";

                            let mailformat = /\S+@\S+\.\S+/; 
                            if (user["@EMail"]) {
                                if (user["@EMail"] !== "") {
                                    if (user["@EMail"].indexOf('@') === -1 ||
                                        user["@EMail"].indexOf('.') === -1 ||
                                        !user["@EMail"].match(mailformat)) {
                                        newStateData.email_userError = UserFieldErrors.valideMail;
                                        return newStateData;
                                    } 
                                } else if (isUserInteractiveOnly) {
                                    if (clientPolicy["@EmailRequired"] === '1' && !IsFieldFilled(user["@EMail"])) {
                                        newStateData.email_userError = UserFieldErrors.requiredEmail;
                                        return newStateData;
                                    }
                                }
                            } 
                        }

                        // Set User Type based on Interactive and ClaimViewer Option
                        user["@UserType"] = userType;

                        // Check for duplicate Login Name
                        let duplicateUser = action.data.masterCrud!.UserMaintenanceInfo.Users.User.find(xuser => xuser["@LoginName"].toUpperCase() === user["@LoginName"].toUpperCase() && xuser["@ID"] !== user["@ID"]);
                        if (duplicateUser) {
                            if (duplicateUser["@Delete"] === 'true') {
                                // Should already be handled by the component, this is just a failsafe.
                                newStateData.loginName_userError = UserFieldErrors.deletedUserExists;
                                return newStateData;
                            }
                            else {
                            newStateData.loginName_userError = UserFieldErrors.existingUserName;
                            return newStateData;
                        }
                        }

                        // Check for duplicate Email
                        if (user["@EMail"] != "") {
                            let duplicateEmail = action.data.masterCrud!.UserMaintenanceInfo.Users.User.find(xuser => xuser["@EMail"].toUpperCase() === user["@EMail"].toUpperCase() && xuser["@ID"] != user["@ID"]);
                            if (duplicateEmail) {
                                newStateData.email_userError = UserFieldErrors.existingUserEmail;
                                return newStateData;
                            }
                        }

                        if (!(newStateData.loginName_userError || newStateData.firstName_userError || newStateData.lastName_userError ||
                            newStateData.password_userError || newStateData.confirmPassword_userError)) {
                            let newIndex = state.selectedUser.index;
                            if (action.type == 'ADD_USER') {
                                action.data.masterCrud!.UserMaintenanceInfo.Users.User.push(user);
                                newIndex = action.data.masterCrud!.UserMaintenanceInfo.Users.User.length;
                                newStateData.selectedUser = {
                                    index: newIndex,  // Does not matter, items are selected by ID
                                    value: user["@ID"],
                                    text: user["@LoginName"]
                                };
                                newStateData.isLinkEnabled = true;
                            } else {
                                newIndex = state.selectedUser.index;
                                let selectedUser = action.data.masterCrud!.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === user["@ID"]);
                                if (selectedUser) {
                                    selectedUser["@LoginName"] = user["@LoginName"];
                                    selectedUser["@FirstName"] = user["@FirstName"];
                                    selectedUser["@LastName"] = user["@LastName"];
                                    selectedUser["@Password"] = user["@Password"];
                                    if (selectedUser["@LinkedToCIAM"] === "1")
                                        selectedUser["@EMail"] = (state.ciamUserName != null && state.ciamUserName.length > 0) ? state.ciamUserName : user["@EMail"];
                                    else
                                        selectedUser["@EMail"] = user["@EMail"];
                                    selectedUser["@UserRoleId"] = user["@UserRoleId"];

                                    if (action.data.uiData["@FeaturePreview"] === "- Select Feature -") {
                                        selectedUser["@ItemName"] = "CFI_Mode";
                                        selectedUser["@ItemValue"] = "";
                                    } else {
                                        selectedUser["@ItemName"] = user["@ItemName"];
                                        selectedUser["@ItemValue"] = user["@ItemValue"];
                                        selectedUser["@FeaturePreview"] = getFeaturePreview(selectedUser["@ItemName"], selectedUser["@ItemValue"]);
                                    }

                                    selectedUser["@Interactive"] = user["@Interactive"];
                                    selectedUser["@ClientSetId"] = user["@ClientSetId"];
                                    selectedUser["@EmailConfirmed"] = user["@EmailConfirmed"];
                                    selectedUser["@CloneUserId"] = user["@CloneUserId"];
                                    selectedUser["@CloneUserName"] = user["@CloneUserName"];
                                    
                                    selectedUser["@Status"] = user["@Status"];

                                    selectedUser["@UserType"] = user["@UserType"];
                                
                                } else {
                                    return { ...state };
                                }
                            }

                            newStateData.selectedUser = {
                                index: newIndex,
                                value: user["@ID"],
                                text: user["@LoginName"]
                            };

                            newStateData.loginName = user["@LoginName"];
                            newStateData.firstName = user["@FirstName"];
                            newStateData.lastName = user["@LastName"];
                            newStateData.password = user["@Password"];
                            newStateData.eMail = user["@EMail"];
                            newStateData.userRoleId = user["@UserRoleId"];
                            newStateData.interactive = user["@Interactive"];
                            newStateData.clientSet = user["@ClientSetId"];
                            newStateData.emailConfirmed = user["@EmailConfirmed"];
                            newStateData.cloneUserId = user["@CloneUserId"];
                            newStateData.cloneUserName = user["@CloneUserName"];

                            if (action.data.uiData["@FeaturePreview"] === "- Select Feature -") {
                                newStateData.itemName = "CFI_Mode";
                                newStateData.itemValue = "";
                            } else {
                                newStateData.featurePreview = getFeaturePreview(user["@ItemName"], user["@ItemValue"]);
                                newStateData.itemName = user["@ItemName"];
                                newStateData.itemValue = user["@ItemValue"];
                            }

                            newStateData.status = user["@Status"];

                            newStateData.userType = user["@UserType"];

                            var modifiedUserIds = state.modifiedUserList;
                            if (modifiedUserIds.indexOf(user["@ID"].toString()) === -1)
                                modifiedUserIds.push(user["@ID"].toString());
                            newStateData.modifiedUserList = modifiedUserIds;
                             
                        }
                        return newStateData;
                    }
                }
                break;
            case 'REMOVE_USER':
                {
                    if (action.data.masterCrud) {

                        let selectedUser = action.data.masterCrud!.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                        if (selectedUser) {
                            selectedUser["@Delete"] = "true";
                            selectedUser["@EmailConfirmed"] = "0";
                        }
                        var modifiedUserIds = state.modifiedUserList;
                        if (modifiedUserIds.indexOf(action.data.uiData.value) === -1)
                            modifiedUserIds.push(action.data.uiData.value);

                        let displayUser = {
                            index: 0,
                            value: '',
                            text: ''
                        }
                        return {
                            ...state,
                            selectedUser: displayUser,
                            isBusy: false,
                            loginName: "",
                            firstName: "",
                            lastName: "",
                            password: "",
                            confirmPassword: "",
                            eMail: "",
                            userRoleId: "",
                            interactive: "1",
                            clientSet: "-1",
                            itemName: "",
                            itemValue: "",
                            featurePreview: "",
                            status: "0",
                            failedAttempts: "",
                            passwordExpiresIn: "",
                            lastLogonDate: "",
                            filterProfile: "",
                            reportProfile: "",
                            userProfile: "",
                            emailConfirmed: "",
                            linkedToCiam: "",
                            loginName_userError: undefined,
                            firstName_userError: undefined,
                            lastName_userError: undefined,
                            password_userError: undefined,
                            confirmPassword_userError: undefined,
                            email_userError: undefined,
                            isLinkEnabled: false,
                            isUnlinkEnabled: false,
                            modifiedUserList: modifiedUserIds,
                            ciamUserNameButtonEnabled: false,
                        }
                    }
                }
                break;
            case 'CLONE_USER':
                {
                    if (action.data.masterCrud) {

                        let selectedUser = action.data.masterCrud!.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                        if (selectedUser) {

                            let featurePreview: string = getFeaturePreview(selectedUser["@ItemName"], selectedUser["@ItemValue"]);

                            let displayUser = {
                                index: 0,
                                value: '',
                                text: ''
                            }
                            return {
                                ...state,
                                selectedUser: displayUser,
                                isBusy: false,
                                loginName: "",
                                firstName: "",
                                lastName: "",
                                password: "",
                                confirmPassword: "",
                                eMail: "",
                                userRoleId: selectedUser["@UserRoleId"],
                                interactive: selectedUser["@Interactive"],
                                clientSet: selectedUser["@ClientSetId"],
                                featurePreview: featurePreview,
                                status: "0",
                                failedAttempts: "",
                                passwordExpiresIn: "",
                                lastLogonDate: "",
                                filterProfile: "",
                                reportProfile: "",
                                userProfile: selectedUser["@UserProfileName"],
                                emailConfirmed: "",
                                linkedToCiam: "",
                                loginName_userError: undefined,
                                firstName_userError: undefined,
                                lastName_userError: undefined,
                                password_userError: undefined,
                                confirmPassword_userError: undefined,
                                email_userError: undefined,
                                cloneUserId: selectedUser["@ID"],
                                cloneUserName: selectedUser["@LoginName"],
                                isLinkEnabled: false,
                                isUnlinkEnabled: false,
                                ciamUserNameButtonEnabled: false,
                            }
                        }
                    }
                }
            case 'UPDATE_LOGINNAME':
                {
                    if (state.loginName !== action.data) {
                        return {
                            ...state,
                            loginName: action.data,
                        }
                    }
                }
                break;
            case 'UPDATE_FIRSTNAME':
                {
                    if (state.firstName !== action.data) {
                        return {
                            ...state,
                            firstName: action.data,
                        }
                    }
                }
                break;
            case 'UPDATE_LASTNAME':
                {
                    if (state.lastName !== action.data) {
                        return {
                            ...state,
                            lastName: action.data,
                        }
                    }
                }
                break;
            case 'UPDATE_PASSWORD':
                {
                    if (state.password !== action.data) {
                        return {
                            ...state,
                            password: action.data,
                        }
                    }
                }
                break;
            case 'UPDATE_CONFPASSWORD':
                {
                    if (state.confirmPassword !== action.data) {
                        return {
                            ...state,
                            confirmPassword: action.data,
                        }
                    }
                }
                break;
            case 'VERIFY_CIAMUSERNAME':
                {
                    let userData = action.data.masterCrud!.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                    let userOrigEmail = userData ? userData["@EMail"] || "" : "";
                    if (state.ciamUserNameOld !== "")
                        userOrigEmail = state.ciamUserNameOld;
                    if (userOrigEmail !== action.data.uiData.value) {
                        let userEmail: string = action.data.uiData.value;
                        let mailformat = /\S+@\S+\.\S+/;
                        if (!userEmail || userEmail == "" ||
                            userEmail.indexOf('@') === -1 ||
                            userEmail.indexOf('.') === -1 ||
                            !userEmail.match(mailformat)
                        ) {
                            return {
                                ...state,
                                ciamUserNameButtonEnabled: false,
                                ciamUserName_userError: UserFieldErrors.valideMail,
                            }
                        }

                        return {
                            ...state,
                            ciamUserName: action.data.uiData.value,
                            ciamUserNameButtonEnabled: true,
                            ciamUserName_userError: ''
                        }
                    }
                    return {
                        ...state,
                        ciamUserNameButtonEnabled: false,
                        ciamUserName_userError: ''
                    }
                }
                break;
            case 'UPDATE_EMAIL':
                {
                    if (state.eMail !== action.data) {
                        return {
                            ...state,
                            eMail: action.data,
                            emailConfirmed: "0",
                        }
                    }
                }
                break;
            case 'UPDATE_LINK_ENABLED':
                {
                    if (state.isLinkEnabled !== action.data) {
                        return {
                            ...state,
                            isLinkEnabled: action.data
                        }
                    }
                }
                break;
            case 'UPDATE_UNLINK_ENABLED':
                {
                    if (state.isUnlinkEnabled !== action.data) {
                        return {
                            ...state,
                            isUnlinkEnabled: action.data
                        }
                    }
                }
                break;
            case 'UPDATE_EMAIL_CONFIRMED':
                {
                    let userEmail: string = action.data.uiData.text;
                    let mailformat = /\S+@\S+\.\S+/; 
                    if (!userEmail || userEmail == "" ||
                        userEmail.indexOf('@') === -1 ||
                        userEmail.indexOf('.') === -1 ||
                        !userEmail.match(mailformat)
                    ) {
                        return {
                            ...state,
                            email_userError: UserFieldErrors.valideMail,
                            emailConfirmed: "0",
                        } 
                    }

                    return {
                        ...state,
                        emailConfirmed: action.data.uiData.value ? "1" : "0",
                    }
                    
                }
                break;
            case 'UPDATE_USERROLE':
                {
                    if (state.userRoleId !== action.data.uiData.value) {
                        return {
                            ...state,
                            ...state.selectedUser,
                            userRoleId: action.data.uiData.value,
                        }
                    }
                }
                break;
            case 'UPDATE_INTERACTIVE':
                {
                    if (state.interactive !== action.data.uiData.value) {
                        return {
                            ...state,
                            interactive: action.data.uiData.value ? "1" : "0",
                        }
                    }
                }
                break;
            case 'UPDATE_CLAIMVIEWER':
                {
                    if (state.claimViewer !== action.data.uiData.value) {
                        return {
                            ...state,
                            claimViewer: action.data.uiData.value ? "1" : "0",
                        }
                    }
                }
                break;
        case 'CLEAR_CLONEUSER':
                {
                    return {
                        ...state,
                        cloneUserId: "",
                        cloneUserName: ""
                    }
                }
                break;
            case 'UPDATE_CLIENTSET':
                {
                    if (state.clientSet !== action.data.uiData.value) {
                        return {
                            ...state,
                            ...state.selectedUser,
                            clientSet: action.data.uiData.value,
                        }
                    }
                }
                break;
            case 'UPDATE_FEATUREPREVIEW':
                {
                    if (state.itemValue !== action.data.uiData.value || state.featurePreview === undefined) {
                        if (action.data.uiData.value === "Legacy" || state.featurePreview === undefined) {
                            return {
                                ...state,
                                ...state.selectedUser,
                                itemName: "CFI_Mode",
                                itemValue: action.data.uiData.value,
                                featurePreview: 'CFI Legacy Only'
                            }
                        } else if (action.data.uiData.value === "Legacy+" || state.featurePreview === undefined) {
                            return {
                                ...state,
                                ...state.selectedUser,
                                itemName: "CFI_Mode",
                                itemValue: action.data.uiData.value,
                                featurePreview: 'CFI Legacy Plus'
                            }
                        } else {
                            return {
                                ...state,
                                ...state.selectedUser,
                                itemName: action.data.uiData.value,
                                itemValue: action.data.uiData.value,
                                featurePreview: '- Select Feature -',
                            }
                        }
                    }
                }
                break;
            case 'UPDATE_ACCOUNTSTATUS':
                {
                    if (state.status !== action.data.uiData.value) {
                        return {
                            ...state,
                            status: action.data.uiData.value,
                        }
                    }
                }
                break;
            case 'RESTORE_USER':
                {
                    if (action.data.masterCrud) {
                        let uiIndex: number = 0;
                        var restoreUser = action.data.masterCrud.UserMaintenanceInfo.Users.User.find((xusr, index) => {
                            if (xusr["@Deleted"] !== "true") {
                                uiIndex++;
                            }
                            return (xusr["@LoginName"] === action.data.uiData["@LoginName"]);
                        });
                        uiIndex++;  // Increment for the existing note type (above loop won't find it because it's still '1'), which will be the new UI index.
                        
                        if (restoreUser) {
                            var modifiedUserIds = state.modifiedUserList;
                            if (modifiedUserIds.indexOf(restoreUser["@ID"].toString()) === -1)
                                modifiedUserIds.push(restoreUser["@ID"].toString());

                            restoreUser["@Deleted"] = 'false';
                            return {
                                ...state,
                                selectedNoteType: {
                                    index: uiIndex,
                                    value: restoreUser["@ID"],
                                    text: restoreUser["@LoginName"],
                                },
                                loginName: restoreUser["@LoginName"],
                                firstName: restoreUser["@FirstName"],
                                lastName: restoreUser["@LastName"],
                                password: restoreUser["@Password"] || "",
                                confPassword: restoreUser["@ConfirmPassword"] || "",
                                eMail: restoreUser["@EMail"] || "",
                                userRoleId: restoreUser["@UserRoleId"] || "",
                                interactive: restoreUser["@Interactive"] || "1",
                                claimViewer: restoreUser["@ClaimViewer"] || "1",
                                clientSet: restoreUser["@ClientSetId"] || '',
                                itemName: restoreUser["@ItemName"] || "",
                                itemValue: restoreUser["@ItemValue"] || "",
                                status: restoreUser["@Status"] || "",
                                modifiedUserList: modifiedUserIds
                            };
                        }
                    }
                }
                break;
            case 'SET_MODAL_TEXT':
                {
                    return {
                        ...state,
                        modalText: action.data,
                    }
                }
            case 'UPDATE_CIAMUSERNAME_CONFIRMATION_MODAL_TEXT':
                {
                    return {
                        ...state,
                        ciamUserNameConfirmationModalText: action.data,
                    }
                }
            case 'UPDATE_CIAMUSERNAME_CONFIRMATION_MODAL':
                {
                    return {
                        ...state,
                        ciamUserNameConfirmationModalOpen: action.data,
                    }
                }
            case 'LINK_TO_CIAM':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            let provisionResult: any = action.responseData;
                            //let newStateData: IUserMaintenanceUIState = { ...state };
                            if (!provisionResult.users) {
                                return {
                                    ...state,
                                    modalText: UserFieldErrors.ciamProvisioningErrorMessage,
                                    isBusy: false
                                }
                            }
                            if (provisionResult.users.length <= 0) {
                                return {
                                    ...state,
                                    isBusy: false,
                                }
                            }
                            let displayUser = {
                                index: 0,
                                value: '',
                                text: ''
                            }

                            
                            var errorCount = 0;
                            var userNames = [];
                            var newLinksToCiamList = [];
                            var modifiedUserIds = state.modifiedUserList;
                            
                            if (action.data.masterCrud) {
                                for (var i = 0; i < provisionResult.users.length; i++) {
                                    //if new user, replace id from NEW[n] to the real number based on the email address
                                    var userEmail = provisionResult.users[i].userEmail;
                                    var userId = provisionResult.users[i].userId;
                                    var linkedToCiam = provisionResult.users[i].linkedToCiam;
                                    var message = provisionResult.users[i].message;

                                    //UpdateProvisionedData(userEmail, userId, linkedToCiam, message);
                                    let selectedUser = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@EMail"] === userEmail);
                                    if (selectedUser) {
                                        var newUser = false;
                                        if (selectedUser["@ID"]?.startsWith("#"))
                                            newUser = true;
                                        selectedUser["@ID"] = userId.toString();
                                        selectedUser["@LinkedToCIAM"] = linkedToCiam ? "1" : "0";
                                        // do not consider this a modified user needing to be saved, database was already updated by API

                                        if (newUser) {//provision call added user to assurance db, so we need to remove it from crud to not get added twice
                                            newLinksToCiamList.push(userId.toString());
                                        }

                                        if (!linkedToCiam) {
                                            errorCount++;
                                            userNames.push(userEmail + " - " + message?.substring(0, 250));
                                        }
                                    }
                                }
                            }
                            
                            if (errorCount > 0) {
                                var msg = "Could not link the following " + errorCount + " user(s) to CIAM.\n" + userNames.join('\r\n');
                                return {
                                    ...state,
                                    modalText: msg,
                                    selectedUser: displayUser,
                                    isBusy: false,
                                    newlyLinkedToCiamList: newLinksToCiamList,
                                    loginName: "",
                                    firstName: "",
                                    lastName: "",
                                    password: "",
                                    confirmPassword: "",
                                    eMail: "",
                                    userRoleId: "",
                                    interactive: "1",
                                    clientSet: "-1",
                                    itemName: "",
                                    itemValue: "",
                                    featurePreview: "",
                                    status: "0",
                                    failedAttempts: "",
                                    passwordExpiresIn: "",
                                    lastLogonDate: "",
                                    filterProfile: "",
                                    reportProfile: "",
                                    userProfile: "",
                                    emailConfirmed: "",
                                    linkedToCiam: "",
                                    loginName_userError: undefined,
                                    firstName_userError: undefined,
                                    lastName_userError: undefined,
                                    password_userError: undefined,
                                    confirmPassword_userError: undefined,
                                    email_userError: undefined,
                                    isLinkEnabled: false,
                                    isUnlinkEnabled: false,
                                    modifiedUserList: modifiedUserIds,
                                    ciamUserNameButtonEnabled: false,
                                }
                            }
                            return {
                                ...state,
                                selectedUser: displayUser,
                                isBusy: false,
                                newlyLinkedToCiamList: newLinksToCiamList,
                                loginName: "",
                                firstName: "",
                                lastName: "",
                                password: "",
                                confirmPassword: "",
                                eMail: "",
                                userRoleId: "",
                                interactive: "1",
                                clientSet: "-1",
                                itemName: "",
                                itemValue: "",
                                featurePreview: "",
                                status: "0",
                                failedAttempts: "",
                                passwordExpiresIn: "",
                                lastLogonDate: "",
                                filterProfile: "",
                                reportProfile: "",
                                userProfile: "",
                                emailConfirmed: "",
                                linkedToCiam: "",
                                loginName_userError: undefined,
                                firstName_userError: undefined,
                                lastName_userError: undefined,
                                password_userError: undefined,
                                confirmPassword_userError: undefined,
                                email_userError: undefined,
                                isLinkEnabled: false,
                                isUnlinkEnabled: false,
                                modifiedUserList: modifiedUserIds,
                                ciamUserNameButtonEnabled: false,
                            }

                        case "FAIL":
                            return {
                                ...state,
                                modalText: UserFieldErrors.ciamProvisioningErrorMessage,
                                isBusy: false
                            }
                    };
                }
                break;
            case 'LINK_TO_CIAM_USING_JOB_QUEUE':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            let provisionResult: any = action.responseData;
                            //let newStateData: IUserMaintenanceUIState = { ...state };
                            if (!provisionResult.users) {
                                return {
                                    ...state,
                                    modalText: UserFieldErrors.ciamProvisioningErrorMessage,
                                    isBusy: false
                                }
                            }
                            if (provisionResult.users.length <= 0) {
                                return {
                                    ...state,
                                    isBusy: false,
                                }
                            }
                            let displayUser = {
                                index: 0,
                                value: '',
                                text: ''
                            }


                            var errorCount = 0;
                            var userNames = [];
                            var newLinksToCiamList = [];
                            var modifiedUserIds = state.modifiedUserList;

                            if (action.data.masterCrud) {
                                for (var i = 0; i < provisionResult.users.length; i++) {
                                    //if new user, replace id from NEW[n] to the real number based on the email address
                                    var userEmail = provisionResult.users[i].userEmail;
                                    var userId = provisionResult.users[i].userId;
                                    var jobInserted = provisionResult.users[i].jobInserted;
                                    var message = provisionResult.users[i].message;

                                    //UpdateProvisionedData(userEmail, userId, linkedToCiam, message);
                                    let selectedUser = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@EMail"] === userEmail);
                                    if (selectedUser) {
                                        var newUser = false;
                                        if (selectedUser["@ID"]?.startsWith("#"))
                                            newUser = true;
                                        selectedUser["@ID"] = userId.toString();
                                        // do not consider this a modified user needing to be saved, database was already updated by API

                                        if (newUser) {//provision call added user to assurance db, so we need to remove it from crud to not get added twice
                                            newLinksToCiamList.push(userId.toString());
                                        }

                                        if (!jobInserted) {
                                            errorCount++;
                                            userNames.push(userEmail + " - " + message?.substring(0, 250));
                                        }
                                    }
                                }
                            }

                            if (errorCount > 0) {
                                var msg = "Could not link the following " + errorCount + " user(s) to CIAM.\n" + userNames.join('\r\n');
                                return {
                                    ...state,
                                    modalText: msg,
                                    selectedUser: displayUser,
                                    isBusy: false,
                                    newlyLinkedToCiamList: newLinksToCiamList,
                                    loginName: "",
                                    firstName: "",
                                    lastName: "",
                                    password: "",
                                    confirmPassword: "",
                                    eMail: "",
                                    userRoleId: "",
                                    interactive: "1",
                                    clientSet: "-1",
                                    itemName: "",
                                    itemValue: "",
                                    featurePreview: "",
                                    status: "0",
                                    failedAttempts: "",
                                    passwordExpiresIn: "",
                                    lastLogonDate: "",
                                    filterProfile: "",
                                    reportProfile: "",
                                    userProfile: "",
                                    emailConfirmed: "",
                                    linkedToCiam: "",
                                    loginName_userError: undefined,
                                    firstName_userError: undefined,
                                    lastName_userError: undefined,
                                    password_userError: undefined,
                                    confirmPassword_userError: undefined,
                                    email_userError: undefined,
                                    isLinkEnabled: false,
                                    isUnlinkEnabled: false,
                                    modifiedUserList: modifiedUserIds,
                                    ciamUserNameButtonEnabled: false,
                                }
                            }
                            var msg = "CIAM Provisioning Batch Job has been added to the job queue for the selected user(s). Please check the job details for results.";
                            return {
                                ...state,
                                modalText: msg,
                                selectedUser: displayUser,
                                isBusy: false,
                                newlyLinkedToCiamList: newLinksToCiamList,
                                loginName: "",
                                firstName: "",
                                lastName: "",
                                password: "",
                                confirmPassword: "",
                                eMail: "",
                                userRoleId: "",
                                interactive: "1",
                                clientSet: "-1",
                                itemName: "",
                                itemValue: "",
                                featurePreview: "",
                                status: "0",
                                failedAttempts: "",
                                passwordExpiresIn: "",
                                lastLogonDate: "",
                                filterProfile: "",
                                reportProfile: "",
                                userProfile: "",
                                emailConfirmed: "",
                                linkedToCiam: "",
                                loginName_userError: undefined,
                                firstName_userError: undefined,
                                lastName_userError: undefined,
                                password_userError: undefined,
                                confirmPassword_userError: undefined,
                                email_userError: undefined,
                                isLinkEnabled: false,
                                isUnlinkEnabled: false,
                                modifiedUserList: modifiedUserIds,
                                ciamUserNameButtonEnabled: false,
                            }

                        case "FAIL":
                            return {
                                ...state,
                                modalText: UserFieldErrors.ciamProvisioningErrorMessage,
                                isBusy: false
                            }
                    };
                }
                break;
            case 'UNLINK_CIAM':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            let unprovisionResult: any = action.responseData;
                            //let newStateData: IUserMaintenanceUIState = { ...state };
                            if (!unprovisionResult.users) {
                                return {
                                    ...state,
                                    modalText: UserFieldErrors.ciamUnProvisioningErrorMessage,
                                    isBusy: false
                                }
                            }
                            if (unprovisionResult.users.length <= 0) {
                                return {
                                    ...state,
                                    isBusy: false,
                                }
                            }
                            let displayUser = {
                                index: 0,
                                value: '',
                                text: ''
                            }


                            var errorCount = 0;
                            var userNames = [];
                            var modifiedUserIds = state.modifiedUserList;
                            if (action.data.masterCrud) {
                                for (var i = 0; i < unprovisionResult.users.length; i++) {
                                    var userId = unprovisionResult.users[i].userId.toString();
                                    let linkedToCiam : boolean = unprovisionResult.users[i].linkedToCiam ? true : false;
                                    var message = unprovisionResult.users[i].message;

                                    //UpdateProvisionedData(userEmail, userId, linkedToCiam, message);
                                    let selectedUser = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === userId);
                                    if (selectedUser) {
                                        selectedUser["@LinkedToCIAM"] = linkedToCiam ? "1" : "0";
                                        // do not consider this a modified user needing to be saved, database was already updated by API

                                        if (linkedToCiam) {
                                            errorCount++;
                                            userNames.push(userEmail + " - " + message?.substring(0, 250));
                                        }
                                    }
                                }
                            }

                            if (errorCount > 0) {
                                var msg = "Could not unlink the following " + errorCount + " user(s) from CIAM.\n" + userNames.join('\r\n');
                                return {
                                    ...state,
                                    modalText: msg,
                                    selectedUser: displayUser,
                                    isBusy: false,
                                    loginName: "",
                                    firstName: "",
                                    lastName: "",
                                    password: "",
                                    confirmPassword: "",
                                    eMail: "",
                                    userRoleId: "",
                                    interactive: "1",
                                    clientSet: "-1",
                                    itemName: "",
                                    itemValue: "",
                                    featurePreview: "",
                                    status: "0",
                                    failedAttempts: "",
                                    passwordExpiresIn: "",
                                    lastLogonDate: "",
                                    filterProfile: "",
                                    reportProfile: "",
                                    userProfile: "",
                                    emailConfirmed: "",
                                    linkedToCiam: "",
                                    loginName_userError: undefined,
                                    firstName_userError: undefined,
                                    lastName_userError: undefined,
                                    password_userError: undefined,
                                    confirmPassword_userError: undefined,
                                    email_userError: undefined,
                                    isLinkEnabled: false,
                                    isUnlinkEnabled: false,
                                    modifiedUserList: modifiedUserIds,
                                    ciamUserNameButtonEnabled: false,
                                }
                            }
                            return {
                                ...state,
                                selectedUser: displayUser,
                                isBusy: false,
                                loginName: "",
                                firstName: "",
                                lastName: "",
                                password: "",
                                confirmPassword: "",
                                eMail: "",
                                userRoleId: "",
                                interactive: "1",
                                clientSet: "-1",
                                itemName: "",
                                itemValue: "",
                                featurePreview: "",
                                status: "0",
                                failedAttempts: "",
                                passwordExpiresIn: "",
                                lastLogonDate: "",
                                filterProfile: "",
                                reportProfile: "",
                                userProfile: "",
                                emailConfirmed: "",
                                linkedToCiam: "",
                                loginName_userError: undefined,
                                firstName_userError: undefined,
                                lastName_userError: undefined,
                                password_userError: undefined,
                                confirmPassword_userError: undefined,
                                email_userError: undefined,
                                isLinkEnabled: false,
                                isUnlinkEnabled: false,
                                modifiedUserList: modifiedUserIds,
                                ciamUserNameButtonEnabled: false,
                            }

                        case "FAIL":
                            return {
                                ...state,
                                modalText: UserFieldErrors.ciamUnProvisioningErrorMessage,
                                isBusy: false
                            }
                    };
                }
                break;
            case 'UNLINK_CIAM_USING_JOB_QUEUE':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            let displayUser = {
                                index: 0,
                                value: '',
                                text: ''
                            }                            
                            var modifiedUserIds = state.modifiedUserList;
                            return {
                                ...state,
                                modalText: action?.responseData?.message,
                                selectedUser: displayUser,
                                isBusy: false,
                                loginName: "",
                                firstName: "",
                                lastName: "",
                                password: "",
                                confirmPassword: "",
                                eMail: "",
                                userRoleId: "",
                                interactive: "1",
                                clientSet: "-1",
                                itemName: "",
                                itemValue: "",
                                featurePreview: "",
                                status: "0",
                                failedAttempts: "",
                                passwordExpiresIn: "",
                                lastLogonDate: "",
                                filterProfile: "",
                                reportProfile: "",
                                userProfile: "",
                                emailConfirmed: "",
                                linkedToCiam: "",
                                loginName_userError: undefined,
                                firstName_userError: undefined,
                                lastName_userError: undefined,
                                password_userError: undefined,
                                confirmPassword_userError: undefined,
                                email_userError: undefined,
                                isLinkEnabled: false,
                                isUnlinkEnabled: false,
                                modifiedUserList: modifiedUserIds,
                                ciamUserNameButtonEnabled: false,
                            }

                        case "FAIL":
                            return {
                                ...state,
                                modalText: UserFieldErrors.ciamUnProvisioningErrorMessage,
                                isBusy: false
                            }
                    };
                }
                break;
            case 'RESEND_WELCOME_EMAIL':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            let resendWelcomeResult: any = action.responseData;
                            let successful: any = resendWelcomeResult?.successful;
                            if (successful) {
                                return {
                                    ...state,
                                    modalText: UserFieldErrors.ciamResendWelcomeEmailSuccessMessage,
                                    isBusy: false
                                }
                            }
                            else {
                                var parsedError = resendWelcomeResult?.errorMessages[0];
                                var errMsg = 'Error! An error occurred while re-sending the welcome email: ' + parsedError.err;
                                return {
                                    ...state,
                                    modalText: errMsg,
                                    isBusy: false
                                }
                            }
                        case "FAIL":
                            return {
                                ...state,
                                modalText: UserFieldErrors.ciamResendWelcomeEmailErrorMessage,
                                isBusy: false
                            }
                    };
                }
                break;
            case 'RESET_PASSWORD':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            let resetResult: any = action.responseData;
                            let successful: any = resetResult?.successful;
                            if (successful) {
                                return {
                                    ...state,
                                    modalText: UserFieldErrors.ciamResetPasswordSuccessMessage,
                                    isBusy: false
                                }
                            }
                            else {
                                var parsedError = resetResult?.errorMessages[0];
                                var errMsg = 'Error! An error occurred while sending the reset password email: ' + parsedError.err;
                                return {
                                    ...state,
                                    modalText: errMsg,
                                    isBusy: false
                                }
                            }
                        case "FAIL":
                            return {
                                ...state,
                                modalText: UserFieldErrors.ciamResetPasswordErrorMessage,
                                isBusy: false
                            }
                    };
                }
                break;
            case 'UPDATE_CIAM_USERNAME':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            let result: any = action.responseData;
                            let successful: any = result?.successful;
                            if (successful) {
                                if (action.data.masterCrud) {
                                    let selectedUser = action.data.masterCrud.UserMaintenanceInfo.Users.User.find(xuser => xuser["@ID"] === action.data.uiData.value);
                                    if (selectedUser) {
                                        selectedUser["@EMail"] = (state.ciamUserName != null) ? state.ciamUserName : selectedUser["@EMail"];
                                    }
                                }
                                return {
                                    ...state,
                                    modalText: "User's email address has been updated successfully.",
                                    isBusy: false,
                                    ciamUserNameButtonEnabled: false,
                                    ciamUserNameOld: state.ciamUserName,
                                }
                            }
                            else {
                                var parsedError = result?.errorMessages?.[0];
                                var errMsg = 'Error! An error occurred while updating the user email: ' + parsedError.err;
                                return {
                                    ...state,
                                    modalText: errMsg,
                                    isBusy: false
                                }
                            }
                        case "FAIL":
                            return {
                                ...state,
                                modalText: 'Error! An error occurred while updating the user email. Please try again later.',
                                isBusy: false
                            }
                    };
                }
                break;
            case 'UPDATE_CIAM_JOB_STATUS':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            return state;
                        case "SUCCESS":
                            let result: any = action.responseData;
                            let successful: any = result?.successful;
                            if (successful) {
                                return {
                                    ...state,
                                    userAcknowledged: true,
                                    isBusy: false
                                }
                            }
                            else {
                                var parsedError = result?.errorMessages[0];
                                var errMsg = 'Error! An error occurred while clearing error message: ' + parsedError.err;
                                return {
                                    ...state,
                                    modalText: errMsg,
                                    isBusy: false
                                }
                            }
                        case "FAIL":
                            return {
                                ...state,
                                modalText: 'Error! An error occurred while clearing error message. Please try again later.',
                                isBusy: false
                            }
                    };
                }
                break;
            case 'UM_SET_BUSY':
                return {
                    ...state,
                    isBusy: action.data,
                }
            
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
