import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import _ from 'lodash';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IManageDepartmentUIState {
    selectedDepartment: ISelectDepartmentData;
    departmentCode: string;
    departmentPriority: string;
    availableUsers: ErrorRuleDepartment;
    selectedUsers: ErrorRuleDepartment;
    selectedUser: User;
    collection: string[];
    collectionRemoval: string[];
    displayName: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectDepartmentData {
    index: number;
    value: string;
    text: string;
    displayName: string;
};

interface IUIUsersSelected {
    availableUsers: string[];
    selectedUsers: string[];
}

interface ISelected{
    selected: string[];
}

export const defaultDepartment: ISelectDepartmentData = {
    index: 0,
    value: '',
    text: '',
    displayName:''
};

export interface ISelectedUserData {
  '@Name' : string
  '@ID' : string
  '@Firstname' : string
  '@Lastname' : string
  '@Enabled' : string
  '@AlphaSplit' : string
};




export interface ISelectDepartment extends ICrudActionData<ErrorRulesDepartmentType, ISelectDepartmentData> { }
export interface IModifyDeptType extends ICrudActionData<ErrorRulesDepartmentType, ErrorRuleDepartment> { }


export interface IUserList extends ICrudActionData<ErrorRulesDepartmentType, IUIUsersSelected> { }
export interface ICollected extends ICrudActionData<ErrorRulesDepartmentType, ISelected> { }
export interface ISelectedUserData extends ICrudActionData<ErrorRulesDepartmentType, ISelectedUserData> { }


export const actionCreators = {
    selectDepartment: (selectDeptInfo: ISelectDepartment) => createDataAction('SELECT_DEPT', selectDeptInfo),
   // updateDepartmentCode: (departmentCode: IModifyDeptType) => createDataAction('UPDATE_DEPT_NAME', departmentCode),
   // updateDepartmentPriority: (departmentPriority: IModifyDeptType) => createDataAction('UPDATE_DEPT_PRIORITY', departmentPriority),
    updateDepartmentCode: (departmentCode: string) => createDataAction('UPDATE_DEPT_NAME', departmentCode),
    updateDepartmentPriority: (departmentPriority: string) => createDataAction('UPDATE_DEPT_PRIORITY', departmentPriority),
    updateAlphaSplit: (alphaSplit: ISelectedUserData) => createDataAction('CHANGE_ALPHA_SPLIT', alphaSplit),
    availableUsers: (selectUsers: string[]) => createDataAction('SELECTED_AVAILABLE_USERS', selectUsers),
    selectedUsers: (selectUsers: string[]) => createDataAction('SELECTED_DEPT_USERS', selectUsers),
    selectedUser: (selectedUsers: ISelectedUserData) => createDataAction('SELECTED_USER', selectedUsers),
    addDept: (updateInfo: IModifyDeptType) => createDataAction('ADD_DEPT', updateInfo),
    updateDept: (updateInfo: IModifyDeptType) => createDataAction('UPDATE_DEPT', updateInfo),
    addUsers: (addUsers: IModifyDeptType) => createDataAction('MC_ADD_USER', addUsers),
    removeUsers: (removeUsers: IModifyDeptType) => createDataAction('MC_REMOVE_USER', removeUsers),
    removeDept: (removeDept: ISelectDepartment) => createDataAction('MC_REMOVE_DEPT', removeDept),
    resetDept: (resetDept: any) => createDataAction('MC_RESET_DEPT', resetDept),
    resetAlpha: (resetAlpha: any) => createDataAction('MC_RESET_ALPHA', resetAlpha),
}

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

const emptyDept: ErrorRuleDepartment = {
    '@ID': '',
    '@Priority': '',
    '@Code': '',
    '@DisplayName':'',
    Users : []
};


const emptyUser: User = {
    '@ID' : '',
    '@Name': '',
    '@Enabled': '',
    '@FirstName' : '',
    '@LastName': '',
    '@AlphaSplit': ''

}

interface IUpdateAlphaSplit {
    '@ID': string;
    '@AlphaSplit': string;
}



export const defaultState: IManageDepartmentUIState = {
    selectedDepartment: defaultDepartment,
    departmentCode: '',
    departmentPriority: '',
    availableUsers: emptyDept,
    selectedUsers: emptyDept,
    selectedUser: emptyUser,
    collection:[],
    collectionRemoval: [],
    displayName:''
};

function updateAlphaSplit(dept: ErrorRuleDepartment, userData: IUpdateAlphaSplit) {

    let item = new Array<any>();
    item.push(dept.Users)

    //single user assigned
    if (!Array.isArray(item[0]['User'])) {

        dept.Users = JSON.parse(JSON.stringify({ User: { '@ID': userData["@ID"], '@AlphaSplit': userData["@AlphaSplit"] } }));

    //many users assigned    
    } else {
        item[0]['User'].forEach(user => {
            if (user['@ID'] === userData["@ID"]) {
                user['@AlphaSplit'] = userData["@AlphaSplit"];
            }

        });

    }

}

function addToDept(usersToAdd: string[], crud: ErrorRuleDepartment) {

    let newUsersList = new Array<any>();
    let temp = new Array<any>();
    let orphan = new Array<any>();

    if (usersToAdd.length !== undefined) {

        usersToAdd.forEach(user => {
            newUsersList.push({ '@ID': user, '@AlphaSplit': '' })
        });

        if (crud.Users !== undefined) {
            temp.push(crud.Users);

          //  console.log('ADD_TOO_DEPT_CRUD', crud.Users)

            if (!Array.isArray(temp[0]['User'])) {
                //shortcircuit single object
                if (temp[0]['User'] != undefined) {
                     newUsersList.push({ '@ID': temp[0]['User']['@ID'], '@AlphaSplit': temp[0]['User']['@AlphaSplit'] });
                    // newUsersList.push({ '@ID': usersToAdd, '@AlphaSplit': '' });


                   // console.log('notarra', crud.Users)
                }
            } else {
                temp[0]['User'].forEach(user => {
                    newUsersList.push({ '@ID': user['@ID'], '@AlphaSplit': user['@AlphaSplit'] })
                });
            }
        }

       // console.log('addToDept-newUsers', newUsersList)
        crud.Users = JSON.parse(JSON.stringify({ User: newUsersList }))
        return newUsersList;

    } 

}


function removeFromDept(usersToRemove: string[], crud: ErrorRuleDepartment, allUsers : any) {

    let userList  = new Array<any>();
    let temp = new Array<any>();
    let final = new Array<any>();
    let results = new Array<any>();




    if (usersToRemove.length !== undefined) {


        if (crud.Users !== undefined) {
            final.push(crud.Users);
            temp = final[0]['User'];
            userList.push(allUsers);

        //if the user being removed is a disabled (*)user
        //changed the enabled flag to a new status (3) representing a
        //limbo state whereas asterisk users are "momentarily" seen at all in alluser list.
            usersToRemove.forEach(id => {
                let foundUser = _.find(userList[0]['User'], { '@ID': id });

                if (foundUser['@Name'].includes("*")) {
                    _.remove(userList[0]['User'], { '@ID': foundUser['@ID']});
                    foundUser['@Enabled'] = "3";
                    userList[0]['User'].unshift(foundUser)
                }
            })
   

            if (Array.isArray(temp)) {
              results = temp.filter(item => !usersToRemove.includes(item['@ID']));
            }
        }

        crud.Users = JSON.parse(JSON.stringify({ User: results }))
        return results;

    }

}

function displayNameToDeptCode(displayName: string) {
    let code = _.split(displayName, ' - ', 1)
   // console.log('COdE',code)
    return code[0];
}

function displayNameToPriority(displayName: string) {
    let priority = _.split(displayName, ' - ')

   // console.log(priority[0])
    return priority[0];
}






// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IManageDepartmentUIState, KnownActions> = (state: IManageDepartmentUIState = defaultState, action: KnownActions) => {

    switch (action.type) {
        case 'SELECT_DEPT':
            {
                if (action.data.masterCrud) {

                    let departmentData = action.data.masterCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.find(dept => dept["@Code"] === displayNameToDeptCode(action.data.uiData.text));

      



                    //  let data= action.data.masterCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment[0]

                  //  console.log('fixDeptData', action.data.masterCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment)

              

                    return {
                        ...state,
                        selectedDepartment: action.data.uiData,
                        departmentCode: departmentData ? departmentData["@Code"] || '' : '',
                        departmentPriority: departmentData ? departmentData["@Priority"] || '' : '',
                        displayName: departmentData ? departmentData['@DisplayName'] || '' : '',
                        selectedUsers: departmentData ? departmentData : emptyDept,
                        selectedUser: emptyUser,
                        availableUsers: departmentData ? departmentData : emptyDept,
                        collection: state.collection ? state.collection : [],
                        collectionRemoval: state.collectionRemoval ? state.collectionRemoval : []
                    }
                }
            }
            break;
        case 'SELECTED_USER':
            {
                if (action.data.masterCrud) {
                    let user = action.data.uiData
                    return {
                        ...state,
                        selectedUser: {
                            '@Name': user['@Name'],
                            '@Enabled': user['@Enabled'],
                            '@FirstName': user['@Firstname'],
                            '@LastName': user['@Lastname'],
                            '@ID': user['@ID'],
                            '@AlphaSplit': user['@AlphaSplit']
                        },
                    }
                }
            }
            break;
        case 'SELECTED_AVAILABLE_USERS':
            {
                if (action.data) {

                    return {
                        ...state,
                        collection: action.data,
                    }
                }
            }
            break;

        case 'SELECTED_DEPT_USERS':
            {
                if (action.data) {

                    return {
                        ...state,
                        collectionRemoval: action.data,
                    }
                }
            }
            break;

        case 'CHANGE_ALPHA_SPLIT':

            if (action.data.masterCrud) {

                let selectDept: ISelectDepartmentData = state.selectedDepartment
                let userData: IUpdateAlphaSplit = action.data.uiData
 
                let data = action.data.masterCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.find(dept => dept["@Code"] === displayNameToDeptCode(selectDept.text))

                if (data?.Users !== undefined) {
                     updateAlphaSplit(data, userData)
                }

               // console.log('USERDATA',userData)

                return {
                    ...state,
                    selectedUser: {
                        ...state.selectedUser,
                        "@AlphaSplit": userData['@AlphaSplit']
                    }
                }
            }

            break;

        case 'MC_ADD_USER':

            if (action.data.masterCrud) {

                let selectDept: ISelectDepartmentData = state.selectedDepartment
                let deptData: ErrorRuleDepartment = action.data.uiData
                let data = action.data.masterCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.find(dept => dept["@Code"] === displayNameToDeptCode(selectDept.text))
                let final;

                if (data !== undefined) {
                     final = addToDept(state.collection, data);
                }
                return {
                    ...state,
                    selectedUsers: {
                        ...state.selectedUsers,
                        '@ID': deptData["@ID"],
                        '@Code': deptData['@Code'],
                        "@Priority": deptData['@Priority'],
                        "@DisplayName": deptData['@DisplayName'],
                        "Users": JSON.parse(JSON.stringify({ User: final }))
                    },
                    collection: []
                    
                }   
            }


            break;

        case 'MC_REMOVE_USER':

            if (action.data.masterCrud) {

                let selectDept: ISelectDepartmentData = state.selectedDepartment
                let deptData: ErrorRuleDepartment = action.data.uiData
                let data = action.data.masterCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.find(dept => dept["@Code"] === displayNameToDeptCode(selectDept.text))
                let allUsers = action.data.masterCrud.ErrorRuleDepartmentInfo.UserList
                let final;

                if (data !== undefined) {
                    final = removeFromDept(state.collectionRemoval, data, allUsers);
                }
                return {
                    ...state,
                    selectedUsers: {
                        ...state.selectedUsers,
                        '@ID': deptData["@ID"],
                        '@Code': deptData['@Code'],
                        "@Priority": deptData['@Priority'],
                        "@DisplayName": deptData['@DisplayName'],
                        "Users": JSON.parse(JSON.stringify({ User: final }))
                    },
                    collectionRemoval:[]
                    
                }
            }


            break;

        case 'MC_REMOVE_DEPT':
            if (action.data.masterCrud) {

                let selectDept: ISelectDepartmentData = state.selectedDepartment

                let data = action.data.masterCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.filter(dept => dept["@Code"] !== displayNameToDeptCode(selectDept.text))
           

                if (data !== undefined) {
                    action.data.masterCrud.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment = JSON.parse(JSON.stringify(data))
                }

            return {
                ...state,
            }
            }
            break;
        case 'MC_RESET_DEPT':
            if (action.data) {

                return {
                    ...state,
                    departmentCode: "",
                    departmentPriority: "-SELECT PRIORITY-"
                }
            }
            break;

        case 'MC_RESET_ALPHA':
                return {
                    ...state,
                    selectedUser: {
                        ...state.selectedUser,
                        "@ID": "",
                        "@Name":"",
                        "@AlphaSplit": ""
                    }
                }
            break;

        case 'UPDATE_DEPT_NAME':
            return {
                ...state,
                departmentCode: action.data
            }
            break;
        case 'UPDATE_DEPT_PRIORITY':
          if (action.data) {
            return {
                ...state,
                departmentPriority: action.data       
            }
        }
            break;


        case 'ADD_DEPT':
        case 'UPDATE_DEPT':
            {
                if (action.data.masterCrud) {
                    let selectDept: ISelectDepartmentData = state.selectedDepartment;
                    let deptType: ErrorRuleDepartment = action.data.uiData;
                    let current = {...state}
                    

                    let newStateData: IManageDepartmentUIState = { ...state };

                    if (1===1)
                    {
                        if (action.type == 'ADD_DEPT') {
                            action.data.masterCrud!.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.push(deptType);

                            newStateData.selectedDepartment = {
                                index: action.data.masterCrud!.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.length+1,
                                value: deptType["@ID"],
                                text: deptType["@Code"],
                                displayName: deptType["@Code"] + " - " + (deptType['@Priority']!=="" ? deptType['@Priority']:"0"),
                            };
                        }
                        else {

                            let selectedDept = action.data.masterCrud!.ErrorRuleDepartmentInfo.ErrorRuleDepartments.ErrorRuleDepartment.find(existingDept => existingDept["@Code"] === displayNameToDeptCode(selectDept.text));

                            if (selectedDept) {
                                selectedDept["@ID"] = deptType["@ID"];
                                selectedDept["@Code"] = deptType["@Code"];
                                selectedDept["@Priority"] = displayNameToPriority(current.departmentPriority)
                                selectedDept["@DisplayName"] = deptType["@DisplayName"]
                            }
                            else {
                                return { ...state };
                            }
                        }

                        newStateData.selectedDepartment.text = deptType['@Code'];
                        newStateData.selectedDepartment.value = deptType["@ID"];
                        newStateData.departmentPriority = displayNameToPriority(current.departmentPriority);
                        newStateData.displayName = deptType["@DisplayName"]
                    }   
                    return newStateData;
                }
                break;
            }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
           //const exhaustiveCheck: never = action;
            return state;
    }

    return state || defaultState
}
