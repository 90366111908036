import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, Toggle, TextArea, Button, Checkbox, SelectDropdownV2, Tabs } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { URLs } from '@commonDevResources/constants';

import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ArrowButtons } from '@common/ArrowButtons';
import { ErrorableFieldsetComponent } from '@common/ErrorableFieldset';
import { TimeScheduleComponent } from '@common/DateOrTimeRelated/TimeSchedule';
import { UpDownArrowButtonsRow } from '@common/UpDownArrowButtonsRow';
import { UpDownArrowButtons } from '@common/UpDownArrowButtons';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { DaysOfWeekCheckBoxComponent } from '@common/DateOrTimeRelated/DaysOfWeekCheckboxes';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '@common/AssuranceMenu';

import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IAutoListState, IAutoListActions, IAutoListActionProps, actionCreators, validationCallback } from '@store/AutoList';
import { defaultState as defaultUIState } from '@store/ui/AutoListUI';
import { CheckBoxComponent } from '@common/CheckBox';

import { ICookies_Config } from '@store/ConfigData';

const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    width: 950px;
    min-height: 610px;

    #autoLists {
        height: 100%;
    }

    #mytabcontainer >  div
    { 
	    -webkit-box-shadow: none !important;
	    -moz-box-shadow: none !important;
	    box-shadow: none !important;
    }

    #autolist-days_wrapper
    {
        padding: 10px 20px 0 0;
        
    }

    #autolist-daysofweek_label
    {
        
        padding: 40px 20px 10px 20px;
    }
    #autolist-monthlytoggle_wrapper{
        padding-top: 30px;
        padding-left: 5px;
        width: 180px;
    }
    #autolist-lagdays_wrapper{
        width: 120px;
        padding: 10px 20px 5px 20px;
    }
    #autolist-lagdays{
        width: 60px;
    }
    #triggerevent-dropdown{
        padding-top: 10px;
    }

    #SelectedAutoModules_wrapper{
        width: 360px;
    }
`;

const AutoListLeftContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    width: 350px;
`;

const AutoListContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

`;

const TabContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    
    margin-left: 5px;
    border: 1px solid ${Colors.grey50};
    padding: 5px;

    #autolist-tab-monthly,
    #autolist-tab-daily {
        box-shadow: 0 0 0 0 black !important;
        
    }


    #autolist-tab-monthly,
    #autolist-tab-daily {
        padding-top: 1px;
        padding-bottom: 1px;
        
    }

    #autolist-tab-monthly > label,
    #autolist-tab-daily > label {     
        margin-bottom: 0px;
        ${Typography.small};
    }

`;

const MonthlyToggleWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    
`;

const LagDaysWrapper = styled.div`

`;

const EnabledButtonWrapper = styled.div`
    padding-left: 20px;
`;

const LimitClaimsByJobButtonWrapper = styled.div`
    padding-left: 20px;
    padding-top: 8px;
    
`;

const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    #triggerevent-name {
        width: 350px;
        
    }
    #autolist-name {
        width: 420px;
    }

    #autolist-enabled{    
        padding-left: 10px;
margin-left:5px;
    }
    #autolist-enabled_label {
        padding-bottom: 5px;
    }

`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;
const SortButtonWrapper = styled.div`
    

`;
// ---------------------------------

interface ITriggerEventItem {
    label: string,
    value: string,
}

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
};

interface IComponentState {
    cancelLeave: boolean
}

export const DEFAULT_STATE: IComponentState = {cancelLeave: false};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IAutoListProps = IMergeCrudComponentProps<IAutoListState, IAutoListActionProps, IOwnProps>;

export class AutoList extends React.Component<IAutoListProps, IComponentState> {
    static addItemCount: number = 0;
    static addScheduleItemCount: number = 0;

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Auto List",
    };

    constructor(props: IAutoListProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }
        pageLeave();
        // http://localhost:81/api/data/securityInfo/getCookies
        // request:     {"cookies":[{"name":"EnhancedClaimStatusEnabled"},{"name":"DirectEntry"}],"config":[{"name":"EnableTenetFeatures"}]}
        // response:    {"cookies":[{"name":"EnhancedClaimStatusEnabled","value":"0"},{"name":"DirectEntry","value":"1"}],"config":[{"name":"EnableTenetFeatures","value":"1"}]}
        this.props.action.configData.getConfigData({ cookies: [{ name: "EnhancedClaimStatusEnabled" }, { name: "DirectEntry" }], config: [{ name: "EnableTenetFeatures" }] });

        //console.log('componentDidMount reading crud data');
        this.props.action.crud.get({ crudId: CrudTypes.mctiAutoProcessMaster }, validationCallback);

        console.log(this.props.dataStore.crud.data);
    }

    public componentWillUnmount() {
        console.log('AutoList::componentWillUnmount');
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public getSelectedOptions( e : React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }
        return result;
    }
 
    public onSelectAllAutoModules(e: React.ChangeEvent<HTMLSelectElement>) {
        
        this.props.action.ui.selectAllAutoModules(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selected: this.getSelectedOptions(e),
                }
            });
    }

    public onSelectThisAutoModules(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectThisAutoModules(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selected: this.getSelectedOptions(e),
                }
            });
    }

    public onSelectAllGroups(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAllGroups(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selected: this.getSelectedOptions(e),
                        }
            });
    }

    public onSelectThisGroups(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectThisGroups(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selected: this.getSelectedOptions(e),
                }
            });
    }

    public onSelectAutoList(e: React.ChangeEvent<HTMLSelectElement>) {
        console.log(this.props.dataStore.crud.data);
        this.props.action.ui.selectAutoList(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                   text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public onSelectSchedule(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectSchedule(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }

    public updateAutoList_Name(val: string) {
        this.props.action.ui.updateAutoListName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: (val && val.length > 0 ? val.toUpperCase()  : '')
            }
        });
    }

    public updateAutoList_Enabled(val: boolean) {
        this.props.action.ui.updateAutoListEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val ? "Y" : "N"
            }
        });
    }

    public updateAutoList_TriggerEvent(val: string) {
        if (this.validate_TriggerEventChange(val)) {
            this.props.action.ui.updateAutoListTriggerEvent({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: val
                }
            });
        }
    }

    public updateAutoList_LimitClaimsByJob(val: boolean) {
        this.props.action.ui.updateAutoListLimitClaimsByJob({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val ? "Y" : "N"
            }
        });
    }

    public updateSelectedScheduleHr(val: string) {
        this.props.action.ui.updateSelectedScheduleHr({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public updateSelectedScheduleMin(val: string) {
        this.props.action.ui.updateSelectedScheduleMin({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public onTabSelect(index: number) {
        this.props.action.ui.selectTabSchedule(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: index.toString()
                }
            });
    }

    public updateAutoList_LagDays(val: string) {
        this.props.action.ui.updateAutoListLagDays({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public updateAutoList_MonthlyToggle(val: any) {
        this.props.action.ui.updateAutoListMonthlyToggle({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: (!val.isToggled).toString()
            }
        });
    }

    public onSelectDayOfWeek(e: React.ChangeEvent<HTMLInputElement>, idx: string) {

        this.props.action.ui.selectDayOfWeek(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: idx,
                    value: e.target.checked,
                }
            });
    }

    public onClickModifyScheduleButton(e: React.ChangeEvent<HTMLButtonElement>) {
        let item = JSON.parse(JSON.stringify(this.props.dataStore.ui.selectedSchedule));

        const itemMonthly = this.props.dataStore.ui.selectedSchedule["@Monthly"];
        const itemQuarterly = this.props.dataStore.ui.selectedSchedule["@Quarterly"];
        const itemLagDays = this.props.dataStore.ui.selectedSchedule["@LagDays"];
        if ((itemQuarterly === "Y" || itemMonthly === "Y") && itemLagDays.length > 0) {
            let errorToSend = "";
            const reg = /^-?\d*$/;
            if (!reg.test(itemLagDays)) {
                errorToSend = "LagDaysError3";
            }

            const daysN: number = parseInt(itemLagDays);
            if (itemQuarterly === "Y") {
                if (daysN > 90 || daysN < -90) {
                    errorToSend = "LagDaysError2";
                }
            } else {
                if (daysN > 30 || daysN < -30) {
                    errorToSend = "LagDaysError1";
                }
            }

            if (errorToSend !== "") {
                this.props.action.ui.errorAutoListField({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: { value: errorToSend }
                });
                return;
            }
        }

        if (this.props.dataStore.ui.selectedSchedule['@ID'] !== "") {
            this.props.action.ui.updateAutoListSchedule({ masterCrud: this.props.dataStore.crud.data, uiData: item });
        } else {
            item['@ID'] = "#" + AutoList.addScheduleItemCount++;
            this.props.action.ui.addAutoListSchedule({ masterCrud: this.props.dataStore.crud.data, uiData: item });
        }
    }

    public onRemoveScheduleButton() {
        if (this.props.dataStore.ui.selectedSchedule['@ID'] !== "") {
            this.props.action.ui.removeAutoListSchedule({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { value: this.props.dataStore.ui.selectedSchedule['@ID'] }
            });
        }
    }

    public onClickModifyButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoProcesses ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess) return;


        //holdCodeNameError: undefined,
        const selectedItemID = this.props.dataStore.ui.selectedItem['@ID'];
        let selectedItemName = this.props.dataStore.ui.selectedItem['@Name'];
        selectedItemName = selectedItemName ? selectedItemName.trim() : '';

        // empty
        if (selectedItemName.length === 0) {
            this.props.action.ui.errorAutoListField({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { id: "autoListNameError", value: 'Auto List Name must not be blank.' }
            });
            return;
        }

        const conflict = this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess
            .find(obj => obj['@ID'] !== selectedItemID && obj['@Name'] === selectedItemName);

        if (conflict !== undefined) {
            this.props.action.ui.errorAutoListField({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { value: "NameError1" }
            });
            return;
        }

        let triggerEventID = this.props.dataStore.ui.selectedItem['@TriggerEventID'];
        let enabledFlag = this.props.dataStore.ui.selectedItem['@Enabled'];

        if (selectedItemID !== "") {
            let temp = this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(
                obj => obj['@ID'] === selectedItemID);
            if (!temp) return;
            let item = JSON.parse(JSON.stringify(temp));

            item['@Name'] = selectedItemName;
            item['@TriggerEventID'] = triggerEventID;
            item['@Enabled'] = enabledFlag;
            item['@LimitClaimsByJob'] = this.props.dataStore.ui.selectedItem['@LimitClaimsByJob'];

            this.props.action.ui.updateAutoList({ masterCrud: this.props.dataStore.crud.data, uiData:item });
        } else {
            //defaults
            if (triggerEventID === "") {
                let tempEventId = this.getTriggerEvent_SelectedItem();
                triggerEventID = tempEventId.value;
            }

            let item = {
                '@ID': "#" + AutoList.addItemCount++,
                '@Name': selectedItemName,
                '@TriggerEventID': triggerEventID,
                '@Enabled': enabledFlag === "" ? "Y": enabledFlag,
                '@LimitClaimsByJob': this.props.dataStore.ui.selectedItem['@LimitClaimsByJob'],   
                Groups: {},
                AutoModules: {},
                Events: {},
            };

            this.props.action.ui.addAutoList({ masterCrud: this.props.dataStore.crud.data, uiData:item });
        }
    }

    public onRemoveButton(e:any) {

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoProcesses ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess) return;

        if (this.props.dataStore.ui.selectedItem['@ID'] !== "") {
            let temp = this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.find(
                obj => obj['@ID'] === this.props.dataStore.ui.selectedItem['@ID']);
            if (!temp) return;
            let item = JSON.parse(JSON.stringify(temp));

            item['@Name'] = this.props.dataStore.ui.selectedItem['@Name'];
            item['@Delete'] = "true";

            this.props.action.ui.removeAutoList({ masterCrud: this.props.dataStore.crud.data, uiData: item });
        }
    }

    public onRemoveGroups(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.removeGroups({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public onAddGroups(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.addGroups({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public onRemoveAutoModules(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.removeAutoModules({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public validate_TriggerEventChange(triggerId:string) {
        if (!(this.props.dataStore.ui.selectedItem.AutoModules &&
            this.props.dataStore.ui.selectedItem.AutoModules.AutoModule &&
            this.props.dataStore.ui.selectedItem.AutoModules.AutoModule.length > 0))
            return true;
        
        let triggerName = this.getTriggerEvent_Name_From_ID(triggerId);
        if (triggerName.length === 0) return true;

        let am: string[] = [];
        this.props.dataStore.ui.selectedItem.AutoModules.AutoModule.forEach(x => am.push(x['@ID']));

        let returnVal =
            this.validation_ModulesValidForTrigger(triggerName, am);

        if (returnVal.length > 0) {
            this.onAlert(returnVal);
            return false;
        }

        return true;
    }

    public validate_AutoModuleAdd( ) {
        let triggerName = this.getTriggerEvent_Name();

        let returnVal =
            this.validation_ModulesValidForTrigger(triggerName, this.props.dataStore.ui.selectedAllAutoModules);

        if (returnVal.length > 0) {
            this.onAlert(returnVal);
            /*  jmc - seemed like a good idea at the time, but mananging all the errors is more than i need to do
             this.props.action.ui.errorAutoListField({
                masterCrud: this.props.dataStore.crud.data,
                uiData: { id: "autoListAutoModuleError", value: returnVal }
            });*/

            return false;
        }

        return true;
    }

    public validation_ModulesValidForTrigger(triggerName: string, autoModules: string[]): string {
        if (triggerName && triggerName.length > 0) triggerName = triggerName.toUpperCase();
        if (autoModules.length === 0)
            return "";

        var allAutoModules = autoModules.slice();
        let lookup: MCAL_AutoModuleListAutoModule[] = this.getArray_MCAL_AutoModuleListAutoModule(this.props.dataStore.crud.data!.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule);

        let violationFound: string = "";
        allAutoModules.forEach((ob: any) => {
            let am = lookup.find(obLookup => obLookup['@ID'] === ob);
            if (am && am['@ModuleDesc'] && am['@ModuleDesc'].length > 0) {
                let moduleDesc = am['@ModuleDesc'].toUpperCase();
                if ((triggerName === "CLAIMS RELEASED" &&
                    (moduleDesc === "RELEASE ACCEPTED CLAIMS" || moduleDesc === "RELEASE HCD CLAIMS")) ||
                    (triggerName === "DE ELIGIBILITY CHECKED" && moduleDesc === "DE CHECK ELIGIBILITY") ||
                    (triggerName === "DE EDITS CHECKED" &&
                    (moduleDesc === "DE CHECK EDITS" || moduleDesc === "DE CHECK ELIGIBILITY")) ||
                    (triggerName === "DE CLAIMS SENT" &&
                    (moduleDesc === "DE SEND CLAIMS" ||
                    moduleDesc === "DE CHECK ELIGIBILITY" ||
                    moduleDesc === "DE CHECK EDITS")) ||
                    (triggerName === "DE STATUS CHECKED" && moduleDesc === "DE CHECK STATUS") ||
                    (triggerName === "OUTSOURCED CLAIMS RELEASED" && moduleDesc === "RELEASE OUTSOURCED CLAIMS") ||
                    (triggerName === "SECONDARY CLAIMS GENERATED FROM AII" &&
                    (moduleDesc === "DE CHECK STATUS" || moduleDesc === "SECONDARY Bill CLAIMS")) ||
                    (triggerName === "STATE REPORTING CLAIMS RELEASED" &&
                    moduleDesc === "RELEASE STATE REPORTING CLAIMS") ||
                    (triggerName === "REGULATORY REPORTING" && moduleDesc === "CREATE REGULATORY FILE")) {

                        violationFound += "Error: " + moduleDesc + " cannot be triggered by " + triggerName + ".";
                }
            }

        });

        return violationFound;
    }

    public onAddAutoModules(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.validate_AutoModuleAdd()) {
            this.props.action.ui.addAutoModules({ masterCrud: this.props.dataStore.crud.data, uiData: true });
        }
    }

    public onMoveAutoModulesUp(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.moveAutoModulesUp({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public onMoveAutoModulesDown(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.moveAutoModulesDown({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public onClearRemoveConfirm(e: any) {
        this.onConfirmRemove('');
    }

    public onClickRemoveButton(e: any) {
        if (this.props.dataStore.ui.selectedItem['@ID'] === "")
            return;

        const msg = "Do you want to remove '" + this.props.dataStore.ui.selectedItem['@Name'] + "' ? This action cannot be undone.";
        this.onConfirmRemove(msg);
    }

    public onConfirmRemove(msg: string) {
        this.props.action.ui.errorAutoListRemoveConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    // remove schedule confirmation box
    public onClearRemoveScheduleConfirm(e: any) {
        this.onConfirmRemoveSchedule('');
    }

    public onClickRemoveScheduleButton(e: any) {
        if (this.props.dataStore.ui.selectedSchedule['@ID'] === "")
            return;

        const name = this.getScheduleName(this.props.dataStore.ui.selectedSchedule);
        const msg = "Do you want to remove '" + name + "' ? This action cannot be undone.";
        this.onConfirmRemoveSchedule(msg);
    }

    public onConfirmRemoveSchedule(msg: string) {
        this.props.action.ui.errorAutoListRemoveScheduleConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public onClearAlert(e: any) {
        this.onAlert("");
    }

    public onAlert(msg: string) {
        this.props.action.ui.errorAutoListAlert({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }

    public validateForm_QuarterlyRequiredCheck(): boolean {
        let quarterlyRequiredAMs: Array<MCAL_AutoModuleListAutoModule> = [];
        this.props.dataStore.crud.data!.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule.forEach(
            (ob: any) => {
                if (ob['@QuarterlyRequired'] === "Y")
                    quarterlyRequiredAMs.push(ob);
            });

        // if there are any quarterlyRequired automodules
        if (quarterlyRequiredAMs.length > 0) {
            let suspectAutoLists: Array<any> = [];

            // see if any autolists use one of those automodules
            for (var i = 0; i < quarterlyRequiredAMs.length; i++) {
                const am = quarterlyRequiredAMs[i];
                let add:Boolean = false;
                this.props.dataStore.crud!.data!.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.some(
                    (obj: MCAL_AutoProcess) => {
                        if (obj.AutoModules!.AutoModule.find(ob1 => ob1['@ID'] === am['@ID'])) {
                            console.log('AutoList: automodule requires a quarterly scedule ' + obj['@Name'] + ' because of ' + am['@Name'] + '.');
                            suspectAutoLists.push({ 'al': obj, 'am': am['@Name']});
                            add = true;
                            return true;
                        }
                        return false;
                    }
                );

                if (add === true) {                    
                    continue;  // don't bother adding it more than once
                }
            }

            // do the autolists requiring quarterly, have that
            for (var j = 0; j < suspectAutoLists.length; j++) {
                let badEvent = suspectAutoLists[j].al.Events!.Event.find((ob: any) => ob['@Quarterly'] === "N");

                if (badEvent) {
                    let msg = "The Auto List '" + suspectAutoLists[j].al['@Name'] + "' must run on a quarterly schedule to use the Iowa Duplicate Check specified in the RRT profile for Auto Module '" + suspectAutoLists[j].am + "'.";
                    this.onAlert(msg);
                    console.log('autolist - quarterly required - sending alert ' + msg);
                    return false;
                }
            }
        }

        return true;
    }

    public validateForm(): boolean {
        if (!this.validateForm_QuarterlyRequiredCheck())
            return false;

        return true;
    }

    public onOKSubmitAutoList(e: React.ChangeEvent<HTMLButtonElement>) {

        if (!this.validateForm())
            return;

        //console.log(this.props.dataStore.crud.data);
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);
        this.props.history.push('/LandingPage');
    }

    public onExit(e: any) {
        console.log('onExit: bye...')
        //const location = {
        //    pathname: '/LandingPage',
        //    state: { fromDashboard: true }
        //}

        //this.props.history.push('/LandingPage');
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public buildInvalidList(): string[] {
        let badList: string[] = [];
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AutoProcessMaintenanceInfo &&
            this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoModuleList &&
            this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule) {

            let allowTenet = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "EnableTenetFeatures");
            let directEntry = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "DirectEntry");
            let ecs = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "EnhancedClaimStatusEnabled");

            this.props.dataStore.crud.data!.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule.forEach(obj => {
                if (allowTenet && allowTenet.value === "0" && (obj['@ModuleType'] === "11" || obj['@ModuleType'] === "12")) {
                    badList.push(obj['@ID']);
                }

                if (directEntry && directEntry.value === "0" && obj['@ModuleType'] === "13") {
                    badList.push(obj['@ID']);
                }
                if (ecs && (ecs.value === "0" || ecs.value === "2") && obj['@ModuleType'] === "23") {
                    badList.push(obj['@ID']);
                }
            });

        }
        return badList;
    }

    public getAutoListsForLeftSideDisplay() {
        let addItem: MCAL_AutoProcess[] = [
            {
                '@ID': '',
                '@Name': '- ADD A NEW AUTO LIST -',
                '@TriggerEventID': '',
                '@Enabled': '',
                '@LimitClaimsByJob': '',
                Groups: { Group: [] },
                AutoModules: { AutoModule: [] },
                Events: { Event: [] }
            }
        ];

        let invalidList = this.buildInvalidList();
        
        let data = this.props.dataStore.crud.data ? addItem.concat(this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.filter((i) => {
            // cna't be deleted
            let result = !i['@Delete'] || (i['@Delete'] && i['@Delete'] !== "true");

            // can't be non-allowed
            if (result && invalidList.length > 0 && i.AutoModules && i.AutoModules.AutoModule && i.AutoModules.AutoModule.length >0) {
                let hasinvalidItem =
                    i.AutoModules.AutoModule.some((element) => (invalidList.find(x => x === element['@ID']) !== undefined));
                if (hasinvalidItem)
                    result = false;

                if(!result)
                    console.log(i['@Name'] + ' being removed as an invalid autolist for this scenario');
            }

            return (result);
        })) : addItem;

        return data;
    }

    /*
    '@ID': string;
    '@Name': string;
    '@TriggerEventID': string;
    '@Enabled'?: string;
    '@LimitClaimsByJob'?: string;
    Groups?: MCAL_Groups;
    AutoModules?: MCAL_AutoModules;
    Events?: MCAL_Events;
    */
    public getGroupFromLookupList(id: string) {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo) return null;
        if (id === "") return null;

        const result = this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.GroupList.Group.find(ob => { return ob["@ID"] === id });
        console.log('found group with id '+ id + ' :' + (result? result['@Name'] : ''));
        return result || null;
    }

    public getArray_MCAL_Group(ob: any): MCAL_Group[] {
        if (ob.constructor === Array)
            return ob;
        else {
            if (ob && ob !== undefined) {
                let arr = new Array<MCAL_Group>();
                arr.push(ob);
                return arr;
            }
        }
        return ob;
    }

    public getArray_MCAL_GroupListGroup(ob: any): MCAL_GroupListGroup[] {
        if (ob.constructor === Array)
            return ob;
        else {
            if (ob && ob !== undefined) {
                let arr = new Array<MCAL_GroupListGroup>();
                arr.push(ob);
                return arr;
            }
        }
        return ob;
    }

    public getArray_MCAL_AutoModuleListAutoModule(ob: any): MCAL_AutoModuleListAutoModule[] {
        if (ob.constructor === Array)
            return ob;
        else {
            if (ob && ob !== undefined) {
                let arr = new Array<MCAL_AutoModuleListAutoModule>();
                arr.push(ob);
                return arr;
            }
        }
        return ob;
    }

    public getArray_MCAL_AutoModule(ob: any): MCAL_AutoModule[] {
        if (ob.constructor === Array)
            return ob;
        else {
            if (ob && ob !== undefined) {
                let arr = new Array<MCAL_AutoModule>();
                arr.push(ob);
                return arr;
            }
        }
        return ob;
    }

    public getArray_MCAL_Event(ob: any): MCAL_Event[] {
        if (ob.constructor === Array)
            return ob;
        else {
            if (ob && ob !== undefined) {
                let arr = new Array<MCAL_Event>();
                arr.push(ob);
                return arr;
            }
        }
        return ob;
    }

    public getScheduleName(obj: MCAL_Event): string {
        let name: string = '';
        let time = '';
        if (obj['@Time'] && obj['@Time'].length > 0)
            time = obj['@Time'].substring(0, 5);

        let days = '';
        if (obj['@Sun'] && obj['@Sun'] === "Y")
            days = days + "Su";
        if (obj['@Mon'] && obj['@Mon'] === "Y")
            days = days + "Mo";
        if (obj['@Tue'] && obj['@Tue'] === "Y")
            days = days + "Tu";
        if (obj['@Wed'] && obj['@Wed'] === "Y")
            days = days + "We";
        if (obj['@Thu'] && obj['@Thu'] === "Y")
            days = days + "Th";
        if (obj['@Fri'] && obj['@Fri'] === "Y")
            days = days + "Fr";
        if (obj['@Sat'] && obj['@Sat'] === "Y")
            days = days + "Sa";

        if (days.length === 0) {
            if (obj['@Monthly'] && obj['@Monthly'] === "Y")
                days = "Monthly";
            else if (obj['@Quarterly'] && obj['@Quarterly'] === "Y")
                days = "Quarterly";

            if (days.length > 0) {
                if (obj['@LagDays'] &&
                    obj['@LagDays'].length > 0 &&
                    obj['@LagDays'] !== "0" &&
                    obj['@LagDays'] !== "-1") {
                    days = days + " " + obj['@LagDays'] + " Lag Days";
                }
            } else
                days = "NoDays";
        }

        return time + ' ' + days;
    }

    public getScheduleData(): any[] {
        let result: any[] = [
            { value: '', label: "- ADD A NEW TIME -" }
        ];

        if (this.props.dataStore.ui.selectedItem.Events && this.props.dataStore.ui.selectedItem.Events.Event) {
            let mylist = this.getArray_MCAL_Event(this.props.dataStore.ui.selectedItem.Events.Event);

            mylist.forEach(obj => {
                if (obj['@ID']) {
                    const name = this.getScheduleName(obj);

                    result.push({ value: obj['@ID'], label: name });
                }
            });
        }

        //console.log(result);
        return result;
    }
   
    public getGroupData(inGroup: boolean):MCAL_GroupListGroup[] {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.GroupList ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.GroupList.Group) return [];

        let inArray: string[] = [];
        if (this.props.dataStore.ui.selectedItem.Groups && this.props.dataStore.ui.selectedItem.Groups.Group) {
            let mygroup = this.getArray_MCAL_Group(this.props.dataStore.ui.selectedItem.Groups.Group);

            mygroup.forEach(obj => {
                if (obj['@ID']) inArray.push(obj['@ID']);
            });
        }
        
        let result: MCAL_GroupListGroup[] = [];

        let lookup = this.getArray_MCAL_GroupListGroup(this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.GroupList.Group);
        lookup.forEach(ob => {
            if (ob['@ID'] &&
                (inGroup && inArray && inArray.indexOf(ob['@ID']) > -1) ||
                 !inGroup && (!inArray || inArray.indexOf(ob['@ID']) === -1))
                result.push(ob);
        });

        //console.log(result);
        return result;
    }

    public getAutoModulesData(inList: boolean): MCAL_AutoModuleListAutoModule[] {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoModuleList ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule) return [];

        let inArray: string[] = [];
        if (this.props.dataStore.ui.selectedItem.AutoModules && this.props.dataStore.ui.selectedItem.AutoModules.AutoModule) {
            let mylist = this.getArray_MCAL_AutoModule(this.props.dataStore.ui.selectedItem.AutoModules.AutoModule);
            mylist.forEach(obj => {
                if (obj['@ID']) inArray.push(obj['@ID']);
            });
        }
        let invalidList = this.buildInvalidList();
        let result: MCAL_AutoModuleListAutoModule[] = [];

        let lookup = this.getArray_MCAL_AutoModuleListAutoModule(this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule);
        lookup.forEach(ob => {
            if (ob['@ID'] &&
                (inList && inArray && inArray.indexOf(ob['@ID']) > -1) ||
                !inList && (!inArray || inArray.indexOf(ob['@ID']) === -1)) {

                // can't be invalid
                if (invalidList.length > 0) {
                    if (invalidList.find(x => x === ob['@ID']) !== undefined)
                        return;
                }
                result.push(ob);
            }
                
        });

        //console.log(result);
        return result;
    }

    // function just to pass to sort to give automodules back in "@Order"
    public orderFunction(a: MCAL_AutoModule, b: MCAL_AutoModule):number {
        let aO = a['@Order'];
        let bO = b['@Order'];
        let aN:number = 99999;
        let bN:number = 99999;
        if (aO && aO.length > 0) aN = Number(aO);
        if (bO && bO.length > 0) bN = Number(bO);

        return aN - bN;
    }

    public getSortedAutoModulesDataInOnly(): MCAL_AutoModuleListAutoModule[] {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoModuleList ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule) return [];

        let inArray: MCAL_AutoModule[] = [];
        if (this.props.dataStore.ui.selectedItem.AutoModules && this.props.dataStore.ui.selectedItem.AutoModules.AutoModule) {
            let mylist = this.getArray_MCAL_AutoModule(this.props.dataStore.ui.selectedItem.AutoModules.AutoModule);

            mylist.forEach(obj => {
                if (obj['@ID']) inArray.push(obj);
            });
        }

        inArray.sort((a, b) => this.orderFunction(a,b));

        let result: MCAL_AutoModuleListAutoModule[] = [];
        let lookup = this.getArray_MCAL_AutoModuleListAutoModule(this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule);
        inArray.forEach(ob => {
            if (ob['@ID'] && lookup) {
                let foundOb = lookup.find(x => x['@ID'] === ob['@ID']);
                if( foundOb) result.push(foundOb);
            }
        });

        //console.log(result);
        return result;
    }

    public getTriggerEventArray() {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent
        ) return [];

        let result: any[] =  [];

        this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }
    
    public getSchedule(type: string, time: string){
        let result = { value: '00', label: '00' };
        if (!time || (time && time.length < 5))
            return result;


        if (type == 'hr') {
            const val = time.substring(0, 2);
            result.label = val;
            result.value = val;
        } else {
            const val = time.substring(3, 5);
            result.label = val;
            result.value = val;
        }
        return result;
    }

    public getTriggerEvent_SelectedItem(): ITriggerEventItem {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.ui.selectedItem || !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent
        ) return { value: '', label: '' };

        let item = this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent.find(
            ob => ob['@ID'] === this.props.dataStore.ui.selectedItem["@TriggerEventID"] );
        let result = { value:'', label:''};
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label =
                this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent[0]['@Name'];
            result.value =
                this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent[0]['@ID'];
        }

        return result;
    }

    public getTriggerEvent_Name_From_ID(id: string) {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList ||
            !this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent
        ) return '';

        let trigger = this.props.dataStore.crud.data.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent.find(
            ob => ob["@ID"] === id);

        return trigger ? trigger['@Name'] : '';
    }

    public getTriggerEvent_Name() {
        let val = this.getTriggerEvent_SelectedItem();
        if (val && val.label)
            return val.label;
        return'';
    }

    public render() {

        const instruction = <React.Fragment>To add a new auto-list: Give it a name, choose the trigger event, attach the groups that can use it, move the auto-modules into the Active list, and click OK to save. You can also change the order of the auto-modules on the list.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.dataStore.ui.changed || !this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKSubmitAutoList(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        const triggereventname = this.getTriggerEvent_Name();

        let data = this.getAutoListsForLeftSideDisplay();
        let inGroup = this.getGroupData(true);
        let notInGroup = this.getGroupData(false);
        let triggerEvents = this.getTriggerEventArray();
        let inAutoModules = this.getSortedAutoModulesDataInOnly();
        let notInAutoModules = this.getAutoModulesData(false);
        let okText = "Yes";
        let cancelText = "No";

        if (this.props.dataStore.ui.changed)
          handleChange()
        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_AutoList.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper id={"content_wrapper_AutoList"}>
                    <AutoListLeftContent>
                        <DialogFieldset>
                            <DialogLegend>Auto Lists</DialogLegend>
                            <SelectComponent
                                title='autoLists'
                                size={31}
                                width='325px'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAutoList(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@Name"
                                }}
                                records={data}
                                selectedValue={this.props.dataStore.ui.selectedAutoList.id}>
                            </SelectComponent>
                        </DialogFieldset>
                    </AutoListLeftContent>
                    <AutoListContent>
                        <SelectActions>
                            <ContentRowWrapper>
                                <Input
                                    domID="autolist-name"
                                    className="text-input"
                                    label="*Auto List Name:"
                                    maxLength={50}
                                    initialValue={this.props.dataStore.ui.selectedItem['@Name']}
                                    hasError={this.props.dataStore.ui.autoListNameError && this.props.dataStore.ui.autoListNameError !== ''}
                                    errorMessage={this.props.dataStore.ui.autoListNameError}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateAutoList_Name(e.target.value)}/>

                                <EnabledButtonWrapper>
                                    <CheckBoxComponent
                                        id='autolist-enabled'
                                        labelBefore=' ENABLED:&nbsp;&nbsp;'
                                        checked={this.props.dataStore.ui.selectedItem['@Enabled'] !== "N"}
                                        disabled={false}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAutoList_Enabled(e.target.checked)} />

                                </EnabledButtonWrapper>
                            </ContentRowWrapper>
                            <SelectDropdownV2
                                domID="triggerevent-dropdown"
                                className="dropdown"
                                label="*Trigger Event:"
                                // disabled={false}

                                isClearable={false}
                                isSearchable={true}
                                size='medium'

                                width='400px'
                                onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateAutoList_TriggerEvent(e.value)}

                                options={triggerEvents}

                                initialValue={this.getTriggerEvent_SelectedItem()}/>
                            {triggereventname && triggereventname.startsWith('Claims Bridged With Key') && 
                            <LimitClaimsByJobButtonWrapper>
                                <CheckBoxComponent
                                    id='autolist-limitclaimsbyjob'
                                    labelBefore=' ADD BRIDGE KEY TO AUTO MODULES THAT CONTAIN UNRELEASED CLAIM REPORTS:&nbsp;&nbsp;  '
                                    width='480px'
                                    checked={this.props.dataStore.ui.selectedItem['@LimitClaimsByJob'] === "Y"}
                                    disabled={false}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAutoList_LimitClaimsByJob(e.target.checked)}
                                    />
                            </LimitClaimsByJobButtonWrapper>
                            }

                            <SelectButtons>
                                <Button
                                    domID="addButton"
                                    name={this.props.dataStore.ui.selectedItem['@ID'] !== "" ? "Update": "Add"}
                                    buttonType="standard"
                                    size="small"
                                    type="button"
                                    disabled={!this.props.canEdit } 

                                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickModifyButton(e)}/>
                                <Button
                                    domID="removeButton"
                                    name="Remove"
                                    buttonType="standard"
                                    size="small"
                                    type="button"
                                    disabled={!this.props.canEdit || this.props.dataStore.ui.selectedItem['@ID'] === ""}

                                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickRemoveButton(e)}/>
                            </SelectButtons>
                        </SelectActions>

                        {triggereventname && triggereventname.startsWith('Scheduled Event') &&
                        <ErrorableFieldsetComponent 
                            domID='autolist-schedule_fieldset'
                            legend='Schedule'

                             >
                            <ContentRowWrapper>
                                <SelectComponent
                                    title='autolist-schedule'
                                    size={6}
                                    width='220px'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectSchedule(e)}
                                    optionFields={{
                                        value: "value",
                                        text: "label"
                                    }}
                                    
                                    records={this.getScheduleData()}
                                    selectedValue={this.props.dataStore.ui.selectedSchedule['@ID']}

                                ></SelectComponent>
                                <TabContainer
                                    id='mytabcontainer'
                                >
                                    <ContentColumnWrapper>
                                    <Tabs
                                            tabs={[{ label: 'Daily', domID: 'autolist-tab-daily' },
                                            { label: 'Monthly/Quarterly', domID: 'autolist-tab-monthly' }]}
                                            onTabSelect={(e: any, index: any) => { this.onTabSelect(index.selectedTabIndex) }}
                                            initialTab={this.props.dataStore.ui.selectedScheduleTab === "1" ? 1 : 0}
                                        />
                                        {this.props.dataStore.ui.selectedScheduleTab !== '1' ? (
                                            <ContentRowWrapper>
                                                <DialogLabel
                                                    id='autolist-daysofweek_label'
                                                >Days:</DialogLabel>        
                                                <DaysOfWeekCheckBoxComponent
                                                    id='autolist-days'
                                                    checkedSun={this.props.dataStore.ui.selectedSchedule['@Sun'] === 'Y'}
                                                    checkedMon={this.props.dataStore.ui.selectedSchedule['@Mon'] === 'Y'}
                                                    checkedTue={this.props.dataStore.ui.selectedSchedule['@Tue'] === 'Y'}
                                                    checkedWed={this.props.dataStore.ui.selectedSchedule['@Wed'] === 'Y'}
                                                    checkedThu={this.props.dataStore.ui.selectedSchedule['@Thu'] === 'Y'}
                                                    checkedFri={this.props.dataStore.ui.selectedSchedule['@Fri'] === 'Y'}
                                                    checkedSat={this.props.dataStore.ui.selectedSchedule['@Sat'] === 'Y'}
                                                    disabled={false}
                                                    onChangeSun={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Sun')}
                                                    onChangeMon={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Mon')}
                                                    onChangeTue={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Tue')}
                                                    onChangeWed={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Wed')}
                                                    onChangeThu={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Thu')}
                                                    onChangeFri={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Fri')}
                                                    onChangeSat={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectDayOfWeek(e, '@Sat')}
                                                />
                                            </ContentRowWrapper>
                                        ) : (
                                            <ContentRowWrapper>
                                                <MonthlyToggleWrapper id='autolist-monthlytoggle_wrapper'>
                                                    <Toggle
                                                        leftLabel="Monthly"
                                                        rightLabel="Quarterly"
                                                        initialValue={this.props.dataStore.ui.selectedSchedule['@Quarterly'] === 'Y'}
                                                        onToggle={(e: any, data: any) => this.updateAutoList_MonthlyToggle(data)}
                                                        />
                                                    </MonthlyToggleWrapper>
                                                <LagDaysWrapper id='autolist-lagdays_wrapper'>
                                                <Input
                                                    domID="autolist-lagdays"
                                                    className="text-input"
                                                    label="Lag Days:"
                                                    maxLength={3}
                                                    initialValue={this.props.dataStore.ui.selectedSchedule['@LagDays']}
                                                    hasError={this.props.dataStore.ui.autoListLagDaysError && this.props.dataStore.ui.autoListLagDaysError !== ''}
                                                    errorMessage={this.props.dataStore.ui.autoListLagDaysError}

                                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateAutoList_LagDays(e.target.value)} />
                                                </LagDaysWrapper>
                                            </ContentRowWrapper>
                                        )}

                                    </ContentColumnWrapper>
                                    
                                        
                                 </TabContainer>

                            </ContentRowWrapper>
                            <TimeScheduleComponent
                                id='autolist_time'
                                onChangeHr={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateSelectedScheduleHr(e.value)}
                                onChangeMin={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => this.updateSelectedScheduleMin(e.value)}
                                label='At: '
                                width='150px'
                                disabled={false}
                                separator=' : '
                                setSelectedValHr={this.getSchedule('hr', this.props.dataStore.ui.selectedSchedule['@Time'])}
                                setSelectedValMin={this.getSchedule('min', this.props.dataStore.ui.selectedSchedule['@Time'])}
                            ></TimeScheduleComponent>
                            <SelectButtons>
                                <Button
                                    domID="autolist_schedule_addButton"
                                    name={this.props.dataStore.ui.selectedSchedule['@ID'] !== "" ? "Update" : "Add"}
                                    buttonType="standard"
                                    size="small"
                                    type="button"
                                    disabled={!this.props.canEdit}

                                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickModifyScheduleButton(e)}

                                />
                                <Button
                                    domID="autolist_schedule_removeButton"
                                    name="Remove"
                                    buttonType="standard"
                                    size="small"
                                    type="button"
                                    disabled={!this.props.canEdit || this.props.dataStore.ui.selectedSchedule['@ID'] === ""}

                                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickRemoveScheduleButton(e)}

                                />
                            </SelectButtons>
                            
                       </ErrorableFieldsetComponent>
                        }

                        <DialogFieldset>
                            <DialogLegend>Groups</DialogLegend>
                            <ContentRowWrapper>

                                <SelectComponent
                                    label='ALL GROUPS:'
                                    title='AllGroups'
                                    size={5}
                                    width='250px'
                                    multiple='true'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAllGroups(e)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name"
                                    }}
                                    records={notInGroup}
                                    selectedMultiValue={this.props.dataStore.ui.selectedAllGroups}>
                                </SelectComponent>
                                <ArrowButtons
                                    paddingTop='30px'
                                    width='60px'
                                    margin='0 20px 0 0'
                                    toolTipRight=' Add Group(s) to Selected Auto List'
                                    toolTipLeft=' Remove Group(s) from Selected Auto List'
                                    onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRemoveGroups(e)}
                                    onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onAddGroups(e)}/>
                                <SelectComponent
                                    label='This AUTO LIST:'
                                    title='SelectedGroups'
                                    size={5}
                                    width='250px'
                                    multiple='true'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectThisGroups(e)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name"
                                    }}
                                    records={inGroup}
                                    selectedMultiValue={this.props.dataStore.ui.selectedThisGroups}>
                                </SelectComponent>
                            </ContentRowWrapper>
                        </DialogFieldset>

                        <ErrorableFieldsetComponent
                            domID='autolist-automodules_fieldset'
                            legend='Auto Modules'
                            //hasError={!!this.props.dataStore.ui.autoListAutoModuleError && this.props.dataStore.ui.autoListAutoModuleError !== ''}
                            //errorMessage={this.props.dataStore.ui.autoListAutoModuleError}
                        >
                            <ContentColumnWrapper>

                                <SelectComponent
                                    label='All Auto Modules:'
                                    title='AllAutoModules'
                                    size={5}
                                    width='360px'
                                    multiple='true'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAllAutoModules(e)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name"
                                    }}
                                    records={notInAutoModules}
                                    selectedMultiValue={this.props.dataStore.ui.selectedAllAutoModules}>
                                </SelectComponent>
                                <UpDownArrowButtonsRow
                                    paddingTop='10px'
                                    paddingLeft='120px'
                                    width='360px'
                                    
                                    toolTipUp=' Add Auto Module(s) to Selected Auto List'
                                    toolTipDown=' Remove Auto Module(s) from Selected Auto List'
                                    onUpBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRemoveAutoModules(e)}
                                    onDownBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onAddAutoModules(e)}/>
                                <ContentRowWrapper>
                                    <SelectComponent
                                        label='This Auto List:'
                                        title='SelectedAutoModules'
                                        size={5}
                                        width='360px'
                                        multiple='true'
                                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectThisAutoModules(e)}
                                        optionFields={{
                                            value: "@ID",
                                            text: "@Name"
                                        }}
                                        records={inAutoModules}
                                        selectedMultiValue={this.props.dataStore.ui.selectedThisAutoModules}>
                                    </SelectComponent>
                                    <SortButtonWrapper>
                                        <UpDownArrowButtons
                                            paddingTop='35px'
                                            width='185px'
                                            toolTipUp=' Move Auto Module(s) Up'
                                            toolTipDown=' Move Auto Module(s) Down'
                                            onUpBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onMoveAutoModulesUp(e)}
                                            onDownBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onMoveAutoModulesDown(e)}/>
                                    </SortButtonWrapper>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                        </ErrorableFieldsetComponent>
                        <ModalConfirmation
                            isOpen={!!this.props.dataStore.ui.autoListAlert && this.props.dataStore.ui.autoListAlert.length > 0}
                            onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                            message={this.props.dataStore.ui.autoListAlert}
                            onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                        />
                        <ModalConfirmation
                            isOpen={!!this.props.dataStore.ui.autoListRemoveConfirm && this.props.dataStore.ui.autoListRemoveConfirm.length > 0}
                            onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearRemoveConfirm(e)}
                            message={this.props.dataStore.ui.autoListRemoveConfirm}
                            onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveButton(e)}
                        />
                        <ModalConfirmation
                            isOpen={!!this.props.dataStore.ui.autoListRemoveScheduleConfirm && this.props.dataStore.ui.autoListRemoveScheduleConfirm.length > 0}
                            onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearRemoveScheduleConfirm(e)}
                            message={this.props.dataStore.ui.autoListRemoveScheduleConfirm}
                            onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveScheduleButton()}
                        />
                    </AutoListContent>
                </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IAutoListState, IAutoListActionProps, IOwnProps, IAutoListProps, ApplicationState>(
    createCrudMapStateToProps('autoList'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(AutoList);

export default withRouter(connectedHoc);
