
import * as React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
//import { FormattedMessage, defineMessages } from 'react-intl';
import styled from 'styled-components';
import { Button, Modal } from 'ui-core';
//import Modal from './Modal';
//import * as ModalStore from '../../store/Modal';
//import { updateTokenSilently, getUser } from 'auth-management';
//import * as UserStore from '../../store/User';
import { MODAL_ANIMATION } from '@commonResources/timings';
import { Colors } from '@commonResources/colorVariables';
import stackingContext from '@commonResources/stackingContext';
import {ClearSecuritySession} from '../../commonResources/window';


const SILENT_REFRESH_MODAL = 'SILENT_REFRESH_MODAL';

const ModalFooterStyles = styled.div`
  button:first-child {
    margin-right: 0.5em;
  }
`;

const ModalStyle = styled.div`
    text-align: center;
`;

const silentModalWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${Colors.darkerSecondaryGrey};
  ${stackingContext.modal};
  opacity: 0;
  visibility: hidden;
  overflow: auto;
  transition: opacity ${MODAL_ANIMATION}ms, visibility ${MODAL_ANIMATION}ms,
    backdrop-filter ${MODAL_ANIMATION}ms;

  &.active {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
`;


//function createMessages(timeRemaining: number)
//{
//    var dt: Date = new Date(timeRemaining);
//    return defineMessages({
//        sessionWillExpireMessage: {
//            id: 'uiclient.views.Security.sessionWillExpireMessage',
//            defaultMessage: `Your session will expire in ${dt.getUTCMinutes()}:${dt.getUTCSeconds()}.Click 'CONTINUE' to resume your session.`,
//        }
//    });
//}

interface IComponentProps {
    resetTimer: (() => void);
    timer: number;
    closeModal: (() => void);
}

interface IState {
    isRefreshing: boolean;
    timeRemaining: number;
}

interface IStateProps {
}

const INITIAL_STATE: IState = {
    isRefreshing: false,
    timeRemaining: 5 * 60 * 1000,
};

type ISilentRefreshModalProps = IComponentProps & IStateProps;// & UserStore.ActionCreators;

class SilentRefreshModal extends React.Component<ISilentRefreshModalProps, IState> {

    private refreshTimeout: number;
    private refreshTimer: number = 0;

    constructor(props: ISilentRefreshModalProps) {
        super(props);

        this.state = {
            ...INITIAL_STATE,
            timeRemaining: this.props.timer * 60 * 1000
        };
        this.refreshTimeout = 500;
    }

    componentDidMount() {
        this.refreshTimer = setInterval(() => this.onRefreshTimer(), this.refreshTimeout);
    }

    componentWillUnmount() {
        clearInterval(this.refreshTimer)
    }

    onRefreshTimer() {
        this.setState({ timeRemaining: this.state.timeRemaining - this.refreshTimeout });
        // If timer has reached 0, clear session
        if (this.state.timeRemaining <= 0) ClearSecuritySession();
    }

    continue() {
        if(this.state.timeRemaining < 0){
            // we log out
            this.props.closeModal();
        }
        else
            this.props.resetTimer();
        
        // auth-management version
        //updateTokenSilently(
        //    () => { },
        //    () => {
        //        this.props.resetTimer();
        //        this.props.setUser && this.props.setUser(getUser());
        //        this.props.pushLocation && this.props.pushLocation(window.location.pathname);
        //    },
        //    () => {
        //        this.props.pushLocation && this.props.pushLocation('/Transaction/Logon');
        //    }
        //);
        //this.setState({ isRefreshing: true }, () => this.props.resetTimer());
    }

    closeModal() {
        //ModalStore.actionCreators.closeModal(SILENT_REFRESH_MODAL);
        this.props.closeModal();
        //this.setState({isRefreshing: false})
    }

    getMessage(): string {
        var time: string = '00:00';
        if (this.state.timeRemaining > 0) {
            var dt: Date = new Date(this.state.timeRemaining);
            var min: string = dt.getUTCMinutes().toString();
            var sec: string = dt.getUTCSeconds().toString();
            time = `${min.padStart(2, '0')}:${sec.padStart(2, '0')}`;
        }
        return time === '00:00' ? 'Your session has expired. Close this window to return to logon screen.' :
            `Your session will expire in ${time}. Click 'CONTINUE' to keep working.`;
    }

    render() {
        let message = this.getMessage();
        return (
            <Modal
                title="Extend Session"
                domID={SILENT_REFRESH_MODAL}
                isOpen={true}
                wrapperComponent={silentModalWrapper}
                onModalToggle={() => this.closeModal()}>
                <ModalStyle>
                    <div>
                        <h3>Extend Session</h3>
                    </div>
                    <div>
                        <p>{message}</p>
                    </div>
                    <ModalFooterStyles>
                        <Button
                            name="Continue"
                            buttonType="emphasized"
                            disabled={this.state.isRefreshing}
                            onClick={() => this.continue()}
                            size="large"
                            domID="silent-refresh-modal-continue-button"
                        />
                        {/*<Button*/}
                        {/*    name="Cancel"*/}
                        {/*    buttonType="standard"*/}
                        {/*    disabled={this.state.isRefreshing}*/}
                        {/*    onClick={() => this.closeModal()}*/}
                        {/*    size="large"*/}
                        {/*    domID="silent-refresh-modal-cancel-button"*/}
                        {/*/>*/}
                    </ModalFooterStyles>
                </ModalStyle>
            </Modal>
        );
    }
}

const mapStateToProps = (state: IState, ownProps: IComponentProps): IStateProps => ({
});

const mapDispatchToProps = (dispatch: Dispatch, ownProps: IComponentProps) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SilentRefreshModal);
