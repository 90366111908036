export function polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number,
): { x: number; y: number } {
    const angleInRadians: number = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
        x: centerX + (radius * Math.cos(angleInRadians)),
        y: centerY + (radius * Math.sin(angleInRadians))
    };
}

export function describeArc(x: number, y: number, radius: number, spread: number, startAngle: number, endAngle: number) {


    const innerStart = polarToCartesian(x, y, radius, endAngle);
    const innerEnd = polarToCartesian(x, y, radius, startAngle);
    const outerStart = polarToCartesian(x, y, radius + spread, endAngle);
    const outerEnd = polarToCartesian(x, y, radius + spread, startAngle);

    const largeArcFlag: string = endAngle - startAngle <= 180 ? '0' : '1';
    
    var d = [
        "M", outerStart.x, outerStart.y,
        "A", radius + spread, radius + spread, 0, largeArcFlag, 0, outerEnd.x, outerEnd.y,
        "L", innerEnd.x, innerEnd.y,
        "A", radius, radius, 0, largeArcFlag, 1, innerStart.x, innerStart.y,
        "L", outerStart.x, outerStart.y, "Z"
    ].join(" ");

    return d;
}

export function shortestAngle(alpha: number, beta: number) {
    const phi = Math.abs(beta - alpha) % 360; // Will be either the distance or 360-distance
    const distance = phi > 180 ? 360 - phi : phi;
    return distance;

}

export function signedAngle(target: number, source: number) {
    return (target - source) + 180 % 360 - 180;
}