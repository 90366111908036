import * as React from 'react';
import { bindAll } from 'lodash';
import { Button, SelectDropdownV2, Grid } from 'ui-core';
import styled from 'styled-components';
import { DialogFieldset, DialogLegend, DialogLegend3 } from '@common/DialogStyles';
import { Input } from '@common/UICLWrappers/ARMInput';
import { ContentColumnWrapper, ContentRowWrapper} from '@common/DialogWrapper';
import { IMNGeneralOptionsData, IGeneralUpdateRequest, IMNGeneralOptionsLocalState } from '../../../store/ui/ClaimOptionsUI';


const MNGeneralSelectionContent = styled.div`   
    .text-input input {
        text-transform: uppercase;
        width:359px;
    }

    .text-input-right input {
        text-transform: uppercase;
        width: 312px;
    }

    .text-input-bottom-right input
    {
        text-transform: uppercase;
        width: 325px;
    }
 
    .fieldLabel{
        text-transform: uppercase;
        font-weight: bold;
        text-align: right;
        white-space: nowrap;
    }
`;

const GridWrapper = styled.div`

    width:inherit;
    max-height:500px;
    overflow-y:auto;
    border: black 1px solid;  
    background-color: white;

    #grid-wrapper-co-mn-gen-codeset-suppression-grid {

        height:180px;

        .empty-row{
            background-color:white;
        }

        table {
            table-layout: fixed;
            width: 100%;
        }

       thead tr th{
            background-color:#9BA1A9;
            height: 20px !important;
            min-height: 20px;
            margin-top: 0px;
            margin-bottom: 0px;
            min-width: 400px;
            max-width: 400px;
        }

        table tbody tr > td{
            padding-left:0px;
            padding-right: 0px;
            height: 20px;
            overflow: hidden;
            white-space: nowrap;
        }

        table tbody tr > td > div {
            min-height:20px;
            height:20px;
        }

        table tbody tr:hover{
            cursor:pointer !important;
        }

        ._RevCodes, ._ProcCodes {
            min-width:490px;
            max-width:490px;
            width: 490px;
            padding-top:0px;
            padding-left:15px;
        }
`;

const CodeSetSuppressionBottomSection = styled.div`
     margin-top: 5px;
`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IMNGeneralExclusionGridItem extends MCClaimOptionsMNExclusion {
    _RevCodes: string;
    _ProcCodes: string;
};

interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    canDelete: boolean,

    generalOptions: IMNGeneralOptionsData,
    updateMNGeneralOptions(request: IGeneralUpdateRequest): void,
    displayAlertMessage(msg: string): void,
    localState?: IMNGeneralOptionsLocalState,
    onLocalStateChange(request: IMNGeneralOptionsLocalState): void,
};

interface IComponentState {
    selectedMNExclusionId: string,
    revenueCodes: string,
    procedureCodes: string,
}

export const DEFAULT_STATE: IComponentState = {
    selectedMNExclusionId: '',
    revenueCodes: '',
    procedureCodes: '',
}

interface UiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

export interface FieldType {
    domId?: string;
    className: string;
    type: string;
    label: string;
    forceuppercase?: string;
    maxLength?: number;
    initialValue: any;
    hasError?: any;
    onBlur?: any;
    onChange?: any;
    value?: any;
    errorMessage?: string;
}

export default class MedNecGeneralSelectionOptions extends React.Component<IComponentProps, IComponentState> {

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
        bindAll(this);

        this.state = {
            selectedMNExclusionId: (props.localState?.selectedMNExclusionId ? props.localState.selectedMNExclusionId : ''),
            revenueCodes: (props.localState?.revenueCodes ? props.localState.revenueCodes : ''),
            procedureCodes: (props.localState?.procedureCodes ? props.localState.procedureCodes : ''),
        };
    }

    public componentDidMount() {
        // If user left the general options page while he had a row selected, and he comes back to that page, then highlight the previously selected row
        if (this.props.localState?.selectedMNExclusionId && this.props.localState.selectedMNExclusionId != '') {
            this.highlightSelectedRowByID(this.props.localState.selectedMNExclusionId);
        }
    }

    public componentWillUnmount() {
    }

    private ServiceLineChargeOptions =
        [{ label: 'Disable', value: '0' },
         { label: 'SL_TOT = 0', value: '1' },
         { label: 'SL_TOT = SL_NCC', value: '2' }];

    private getServiceLineChargeOption(value: string | undefined) {
        let item = this.ServiceLineChargeOptions.find(ob => ob['value'] === value);

        if (item) {
            return item;
        } else {
            return this.ServiceLineChargeOptions[0];
        }
    }

    //[{ "@ID": "1", "@RevCodes": "ASDFASDF", "@ProcCodes": "234234" }]
    private getGridColumnHeaders() {
        return new Set([
            {
                dataName: "_RevCodes",
                text: 'Revenue Code(s)',
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "_ProcCodes",
                text: "Procedure Code(s)",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
        ]);
    }

    public getGridData(): IMNGeneralExclusionGridItem[] {
        // set _Fields to support uicl grid column width setting
        let list = this?.props?.generalOptions?.exclusionList ? this.props.generalOptions.exclusionList : [];
        let newList: IMNGeneralExclusionGridItem[] = [];
        list.forEach(item => {
            let newItem: IMNGeneralExclusionGridItem = { ...item, _RevCodes: item['@RevCodes'], _ProcCodes: item['@ProcCodes']};
            newList.push(newItem);
        })
        return newList;
    }

    public onFieldChange(value: string, fieldName: string) {
        const request: IGeneralUpdateRequest = { Action: "UPDATEFIELD", Name: fieldName, Value: value };
        this.props.updateMNGeneralOptions(request);
    }

    public onSelectMNExclusionRow(e: any, record: any) {
        if (record && record['@ID']) {
            let mnexclusion = this.props.generalOptions.exclusionList.find(item => item['@ID'] == record['@ID']);
            if (mnexclusion) {
                this.highlightSelectedRow(e.currentTarget);

                this.props.onLocalStateChange({
                    ...this.props.localState,
                    selectedMNExclusionId: record['@ID'],
                    revenueCodes: mnexclusion['@RevCodes'],
                    procedureCodes: mnexclusion['@ProcCodes'],
                });

                this.setState({
                    selectedMNExclusionId: record['@ID'],
                    revenueCodes: mnexclusion['@RevCodes'],
                    procedureCodes: mnexclusion['@ProcCodes'],
                });
            }
        }
    }

    highlightSelectedRowByID(rowId: string) {
        var mnGeneralOptionsGrid = document.getElementById("co-mn-gen-codeset-suppression-grid");
        if (mnGeneralOptionsGrid != null) {
            let query = "tr[data-testid='id-mn-gen-row-" + rowId + "']";
            var selectedRow = mnGeneralOptionsGrid.querySelector(query);
            if (selectedRow)
                selectedRow.classList.add('selected');
        }
    }

    private highlightSelectedRow(target: any) {
        if (target != null) {
            this.deselectAllRows();
            // set selected class to grid row
            target.className = 'selected';
        }
    }

    private deselectAllRows() {
        // clear selected row highlights from ui
        var mnPayerSelectionGrid = document.getElementById("co-mn-gen-codeset-suppression-grid");
        if (mnPayerSelectionGrid != null) {
            var selectedRows = mnPayerSelectionGrid.querySelectorAll('.selected');
            for (var i = 0; i < selectedRows.length; i++) {
                selectedRows[i].classList.remove('selected');
            }
        }
    }

    public onClickAddUpdateButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (!this.validateFields())
            return false;

        let action: "ADD" | "UPDATE" | "REMOVE" = "UPDATE";
        if (this.state.selectedMNExclusionId == '')
            action = "ADD";

        let data: MCClaimOptionsMNExclusion = {
            '@ID': this.state.selectedMNExclusionId,
            '@RevCodes': this.state.revenueCodes,
            '@ProcCodes': this.state.procedureCodes,
        }

        let request: IGeneralUpdateRequest = { Action: action, Value: data, Name: '' };
        this.props.updateMNGeneralOptions(request);

        this.clearEntries();
    }

    private validateFields() {
        if (this.state.procedureCodes == '') {
            this.props.displayAlertMessage('Please enter a Procedure Code.');
            return false;
        }
        if (this.state.revenueCodes == '') {
            this.props.displayAlertMessage('Please enter a Revenue Code.');
            return false;
        }
        let existingItem = this.props.generalOptions.exclusionList.find(obj =>
            obj['@RevCodes'] == this.state.revenueCodes && obj['@ProcCodes'] == this.state.procedureCodes);
        if (existingItem) {
            this.props.displayAlertMessage('Please enter different criteria as this criteria already exists.');
            return false;
        }
        return true;
    }

    public onClickClearButton() {
        this.clearEntries();
    }

    private clearEntries() {
        this.deselectAllRows();

        this.props.onLocalStateChange({
            ...this.props.localState,
            selectedMNExclusionId: '',
            procedureCodes: '',
            revenueCodes: ''
        });

        this.setState({
            selectedMNExclusionId: '',
            procedureCodes: '',
            revenueCodes: '',
        });
    }

    public onClickRemoveButton(e: React.ChangeEvent<HTMLButtonElement>) {
        this.deselectAllRows();

        let request: IGeneralUpdateRequest = { Action: "REMOVE", Value: this.state.selectedMNExclusionId, Name:'' };
        this.props.updateMNGeneralOptions(request);

        this.setState({
            selectedMNExclusionId: '',
            procedureCodes: '',
            revenueCodes: '',
        });
    }

    public renderFieldColumns(fieldData: FieldType[], title?: string, titleWidth?: string) {
        return (
            <div style={{ flex: '1 1 auto', marginLeft: '15px', marginRight: '15px' }}>
                { title && titleWidth ? <div style={{ width: titleWidth, background: '#9BA1A9' }}>
                    <p style={{
                        color: '#37474F',
                        fontSize: '13px',
                        lineHeight: '1.4em',
                        fontWeight: 600,
                        textAlign: 'center',
                        marginTop: '0px',
                        marginBottom: '5px'
                    }}>{title}</p>
                </div> : null}
                <table style={{ marginRight: '0px' }}>
                    <tbody>
                        {fieldData.map(field => {
                            if (field.type === 'text') {
                                return (<tr><td className='fieldLabel'>{field.label}</td><td style={{ width: 5 }}></td><td>
                                    <Input
                                        domID={field.domId}
                                        className={field.className}
                                        initialValue={field.initialValue}
                                        maxLength={field.maxLength}
                                        size="small"
                                        hasError={field.hasError}
                                        onBlur={field.onBlur}
                                        errorMessage={field.errorMessage} />
                                </td></tr>)
                            }
                            else if (field.type = 'dropdown') {
                                return (<tr><td className='fieldLabel'>{field.label}</td><td style={{ width: 5 }}></td><td>
                                    <div style={{ width: '200px' }}>
                                        <SelectDropdownV2
                                            domID={field.domId}
                                            className={field.className}
                                            isClearable={false}
                                            isSearchable={false}
                                            onChange={field.onChange}
                                            options={this.ServiceLineChargeOptions}
                                            initialValue={field.initialValue}
                                            size="small"
                                        />
                                    </div></td></tr>)
                            }
                        })}
                    </tbody>
                </table>
            </div>

        )
    }

    public render() {
        if (!this.props.canView)
            return <div></div>;

        let codeSetSuppressions: IMNGeneralExclusionGridItem[] = this.getGridData();
        return (
            <MNGeneralSelectionContent>
            <DialogFieldset width={'inherit'}>
                <DialogLegend3 helpUrl={'/Support/Help/HELP_Maint_ClaimOptions_MNTab.htm'}> Field Suppression </DialogLegend3>
                <ContentColumnWrapper>
                    <ContentRowWrapper style={{ justifyContent: 'space-between' }}>
                        {this.renderFieldColumns([
                            {
                                domId: 'co-mn-gen-revexception', className: 'text-input', maxLength: 128,
                                label: 'Revenue Code:', type: 'text', initialValue: this.props.generalOptions.revenueException,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) =>  {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.revenueException)
                                        this.onFieldChange(e.target.value, "@RevenueException");
                                }
                            },
                            {
                                domId: 'co-mn-gen-occexception', className: 'text-input', maxLength: 128,
                                label: 'Occurrence Code:', type: 'text', initialValue: this.props.generalOptions.occurrenceException,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.occurrenceException)
                                        this.onFieldChange(e.target.value, "@OccurrenceException");
                                }
                            },
                            {
                                domId: 'co-mn-gen-condexception', className: 'text-input', maxLength: 128,
                                label: 'Condition Code:', type: 'text', initialValue: this.props.generalOptions.conditionException,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.conditionException)
                                        this.onFieldChange(e.target.value, "@ConditionException");
                                }
                            },
                            {
                                domId: 'co-mn-gen-finexception', className: 'text-input', maxLength: 128,
                                label: 'Financial Class:', type: 'text', initialValue: this.props.generalOptions.financialClassException,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.financialClassException)
                                        this.onFieldChange(e.target.value, "@FinancialClassException");
                                }
                            },
                            {
                                domId: 'co-mn-gen-tob', className: 'text-input', maxLength: 128,
                                label: 'Type of Bill:', type: 'text', initialValue: this.props.generalOptions.typeOfBillException,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.typeOfBillException)
                                        this.onFieldChange(e.target.value, "@TypeOfBillException");
                                }
                            }],
                            'CLAIM LEVEL', '480px')}
                        <ContentColumnWrapper>
                            {this.renderFieldColumns([{
                                domId: 'co-mn-gen-revcode', className: 'text-input-right', maxLength: 128,
                                label: 'Revenue Code:', type: 'text', initialValue: this.props.generalOptions.revenueSLException,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.revenueSLException)
                                        this.onFieldChange(e.target.value, "@RevenueSLException");
                                }
                            },
                            {
                                domId: 'co-mn-gen-instslmod', className: 'text-input-right', maxLength: 128,
                                label: 'Institutional SL Modifier:', type: 'text', initialValue: this.props.generalOptions.instSLModifier,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.instSLModifier)
                                        this.onFieldChange(e.target.value, "@InstitutionalSLModifier");
                                },
                            },
                            {
                                domId: 'co-mn-gen-instslproc', className: 'text-input-right', maxLength: 128,
                                label: 'Institutional SL Procedure:', type: 'text', initialValue: this.props.generalOptions.instSLProcedure,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.instSLProcedure)
                                        this.onFieldChange(e.target.value, "@InstitutionalSLProcedure");
                                },
                            }], 'SERVICE LINE LEVEL', '481px')}
                            <div style={{ marginTop: '15px' }}>
                                {this.renderFieldColumns([{
                                    domId: 'co-mn-gen-profslmod', className: 'text-input-right', maxLength: 128,
                                    label: 'Professional SL Modifier:', type: 'text', initialValue: this.props.generalOptions.profSLModifier,
                                    onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                        e.target.value = e.target.value.toUpperCase().trim();
                                        if (e.target.value != this.props.generalOptions.profSLModifier)
                                            this.onFieldChange(e.target.value, "@ProfessionalSLModifier");
                                    }
                                    },
                                    {
                                        domId: 'co-mn-gen-profslproc', className: 'text-input-right', maxLength: 128,
                                        label: 'Professional SL Procedure:', type: 'text', initialValue: this.props.generalOptions.profSLProcedure,
                                        onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                            e.target.value = e.target.value.toUpperCase().trim();
                                            if (e.target.value != this.props.generalOptions.profSLProcedure)
                                                this.onFieldChange(e.target.value, "@ProfessionalSLProcedure");
                                        }
                                    },
                                    {
                                        domId: 'co-mn-gen-service-line-dropdown', className: 'dropdown',
                                        label: 'Service Line Charge:', type: 'dropdown', initialValue: this.getServiceLineChargeOption(this.props.generalOptions.nonCoveredException),
                                        onChange: (e: any) => {
                                            if (e.value != this.props.generalOptions.nonCoveredException)
                                                this.onFieldChange(e.value, "@NonCoveredException");
                                        }
                                    }])}
                            </div>
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                    <DialogFieldset>
                        <DialogLegend>Code Set Suppression</DialogLegend>
                        <GridWrapper>
                            <Grid
                                records={codeSetSuppressions}
                                columns={this.getGridColumnHeaders()}
                                domID='co-mn-gen-codeset-suppression-grid'
                                dataTestId="id-mn-gen"
                                isFixedHeader={true}
                                maxHeight='400px'
                                size="small"
                                onClickThrough={(e: any, record: any) => { this.onSelectMNExclusionRow(e, record) }}
                                emptyGridMessage=' '
                                // grid works without selectionKey
                                // but uicl grid would throw warning
                                selectionKey='@ID'
                            />
                        </GridWrapper>
                        <CodeSetSuppressionBottomSection>
                             <ContentColumnWrapper>
                                <ContentRowWrapper style={{ justifyContent: 'space-between', marginLeft: '15px' }}>
                                        <table><tbody><tr><td className='fieldLabel'>Revenue Code(s):</td><td>
                                        <Input
                                        domID='co-mn-gen-rev-code-btm'
                                        className='text-input'
                                        initialValue={this.state.revenueCodes}
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            let revCodes = e.target.value.toUpperCase().trim();
                                            this.props.onLocalStateChange({ ...this.props.localState, revenueCodes: revCodes });
                                            this.setState({ revenueCodes: revCodes });
                                        }}
                                        maxLength={128}
                                        size="small"
                                    /></td></tr></tbody></table>
                                    <table><tbody><tr><td className='fieldLabel'>Procedure Code(s):</td><td>
                                    <Input
                                        domID='co-mn-gen-proc-code-btm'
                                        className='text-input-bottom-right'
                                        initialValue={this.state.procedureCodes}
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            let procCodes = e.target.value.toUpperCase().trim();
                                            this.props.onLocalStateChange({ ...this.props.localState, procedureCodes: procCodes });
                                            this.setState({ procedureCodes: procCodes });
                                        }}
                                        maxLength={128}
                                        size="small"
                                    />
                                    </td></tr></tbody></table>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                <SelectButtons>
                                       <Button
                                        domID="co-mn-gen-add-btn"
                                                name={this.state.selectedMNExclusionId == '' || !this.props.canEdit ? "Add" : "Update"}
                                        size="small"
                                        buttonType="standard"
                                        type="button"
                                        disabled={!this.props.canView || !this.props.canEdit}
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickAddUpdateButton(e) }}
                                        />
                                        <Button
                                            domID="co-mn-gen-clear-btn"
                                            name='CLEAR'
                                            size="small"
                                            buttonType="standard"
                                            type="button"
                                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickClearButton() }}

                                        /> 
                                        <Button
                                            domID="co-mn-gen-remove-btn"
                                            name='REMOVE'
                                            size="small"
                                            buttonType="standard"
                                            type="button"
                                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickRemoveButton(e) }}
                                            disabled={!this.props.canView || !this.props.canDelete || !this.props.canEdit || this.state.selectedMNExclusionId == ''} 
                                        />
                                </SelectButtons>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                        </CodeSetSuppressionBottomSection>
                    </DialogFieldset>
                </ContentColumnWrapper>
                </DialogFieldset >
                </MNGeneralSelectionContent>
        );
    }
};

