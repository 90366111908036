import { Reducer, Action } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ISubmitReasonCodesUIState {
    reasonCodesFileName: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface IUploadReasonCodesUpdate {
    value: string;
};
export interface ISubmitReasonCodesData extends IApiActionData<any, IUploadReasonCodesUpdate> { }


export const actionCreators = {
    selectSubmitReasonCodesFile: (selectInfo: ISubmitReasonCodesData) => createDataAction('CHANGE_REASON_CODE_FILE', selectInfo),
};



export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: ISubmitReasonCodesUIState = {
    reasonCodesFileName: '',
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ISubmitReasonCodesUIState, KnownActions> = (state: ISubmitReasonCodesUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'CHANGE_REASON_CODE_FILE':
                {
                    return {
                        ...state,
                        reasonCodesFileName: action.data.uiData.value
                    }
                }
               
            default:
                return state;
        }
    }
    return state || defaultState;
}

