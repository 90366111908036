import * as React from 'react';
import { map, noop, sortBy, uniqueId } from 'lodash';
import { SelectComponent } from '../SelectComponent';

export interface IMultiSelectForSimpleValuesProps {
    alphabetize: boolean;
    className: string;
    label:string;
    defaultOption?: string;
    onChange: Function;
    optionList: APICF_SimpleList[];
    selectedIds: string[];
    size?: number;
    title?: string;
    tooltip?: boolean;
}

export interface IMultiSelectForSimpleValuesState {

}

export default class MultiSelectForSimpleValues extends React.PureComponent<IMultiSelectForSimpleValuesProps,
    IMultiSelectForSimpleValuesState> {
    static defaultProps = {
        alphabetize: false,
        className: 'dropdown',
        onChange: noop,
        optionList: [],
        selectedIds: [],
        tooltip: false,
    }

    constructor(props: IMultiSelectForSimpleValuesProps) {
        super(props);
        this.handleOptionSelect = this.handleOptionSelect.bind(this);
    }

    private handleOptionSelect(e: React.ChangeEvent<HTMLSelectElement>) {
        console.log('selected', e.target.selectedOptions);
        const selectedOptions = map(e.target.selectedOptions, 'value');
        if (this.props.onChange) this.props.onChange(selectedOptions);
    }

    public render() {
        const { alphabetize, className, defaultOption, label, optionList, selectedIds, size, title=uniqueId('multi-select-'), tooltip } = this.props;
        const options = { value: 'ID', text: 'Name', title: 'Name' };
        let records: APICF_SimpleList[] = optionList;
        if (alphabetize) {
            records = sortBy<APICF_SimpleList[]>(records, 'Name') as APICF_SimpleList[];
        }
        let selectedValues = selectedIds;
        let defaultSelection = ['-1'];
        if (!!defaultOption) {
            const defaultOptionAndValue = { ID: '-1', Name: defaultOption };
            records = [defaultOptionAndValue, ...records];
            if (!selectedValues.length) selectedValues = defaultSelection;
        }
        return (
            <SelectComponent 
                autoScrollToSelection
                className={className}
                label={label}
                multiple="true"
                onSelect={this.handleOptionSelect}
                optionFields={options}
                records={records}
                selectedMultiValue={selectedValues}
                size={size}
                title={title}
                tooltip={tooltip}
            />
        );
    }
};