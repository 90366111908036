import { Reducer } from 'redux';
import { createDataAction, ActionTypes, TApiStatusTypes } from '@scripts/util/ActionHelpers';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UIState<U extends any> { };

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    reset: (crudId: number | string) => createDataAction('RESET_UI', { crudId: crudId }),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export type CommonUIReducer = (
    crudId: number | string,
    uiReducer: Reducer<any | undefined, any>,
    state: any | undefined,
    action: KnownActions
) => any | undefined;

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const commonUIReducer: CommonUIReducer = (crudId: number | string, uiReducer: Reducer<any | undefined, any>, state: any | undefined, action: KnownActions): any | undefined => {
    if (state != undefined) {
        switch (action.type) {
        case 'RESET_UI':
            if (crudId == action.data.crudId ) {
                return uiReducer(undefined, action);
            }

        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            //const exhaustiveCheck: never = action;
        }
    }

    return uiReducer(state, action);
}

export function createReducer(crudId: number | string, uiReducer: Reducer<any | undefined, any>): Reducer<any | undefined, any> {
    return (state: any | undefined, action: any) => commonUIReducer(crudId, uiReducer, state, action);
}
