import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, SelectDropdownV2 } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { Input } from '@common/UICLWrappers/ARMInput';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { defaultState as defaultUIState } from '@store/ui/PayerAddressUI';
import { IPayerAddressState, IPayerAddressActions, IPayerAddressActionProps, actionCreators, validationCallback } from '@store/PayerAddress';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    .has-error span{
    width:240px !important    
}
`;

export const customControlStyles = styled.div` => ({
    height: 50 });
`;

export const SelectList = styled.div`
    padding: 15px;
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 15px;
    #payerName-input {
        width: 242px;
    }
    #groupName-input {
        width: 242px;
    }
    #address1-input {
        width: 242px;
    }
    #address2-input {
        width: 242px;
    }
    #city-input {
        width: 242px;
    }
    #zipCode-input {
        width: 242px;
    }
    #select-stateList-id {
        width:65px;
    }
.selectDropdown__control
{
    width:70px !important;
}
`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IComponentProps { 
    canView: boolean;
    canEdit: boolean;
    canDelete: boolean;
};

interface IComponentState {
    cancelLeave: boolean
};

interface IDropDownItem {
    label: string,
    value: string,
}

export const DEFAULT_STATE: IComponentState = {cancelLeave: false};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IPayerAddressProps = IMergeCrudComponentProps<IPayerAddressState, IPayerAddressActionProps, IOwnProps>;

enum eModifyButtonType {
    Add,
    Update
}

export class PayerAddress extends React.Component<IPayerAddressProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canDelete: false,
        canView: false,
        canEdit: false, 
    };

    constructor(props: IPayerAddressProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        pageLeave();
        if (this.props.canView) {
            this.props.action.crud.get({ crudId: CrudTypes.mctiAEOtherPayer }, validationCallback);
        }
    }
    
    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onSelectPayer(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectPayer(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    value: e.target.value,
                    text: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public onOKPayerAddress(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);
        this.props.history.push("/LandingPage");
    }

    public onCancelPayerAddress(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public getModifyButtonType(): eModifyButtonType {
        return (this.props.dataStore.ui.selectedPayer.index == 0) ? eModifyButtonType.Add : eModifyButtonType.Update;
    }

    public getModifyButtonText(): string {
        return this.getModifyButtonType() == eModifyButtonType.Add ? 'Add' : 'Update';
    }

    public isRemoveButtonDisabled(): boolean {
        return (this.props.dataStore.ui.selectedPayer.index == 0 || !this.props.canDelete);
    }

    public onClickModifyButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo) return;

        let length = (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo &&
            this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.Payers &&
            this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.Payers.Payer &&
            this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.Payers.Payer.length) || 0;

        switch (this.getModifyButtonType()) {
            case eModifyButtonType.Add:
                {
                    this.props.action.ui.addPayer({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            "@ID": "#" + (length + 1),
                            "@Name": this.props.dataStore.ui.payerName,
                            "@GroupName": this.props.dataStore.ui.groupName,
                            "@Address1": this.props.dataStore.ui.address1,
                            "@Address2": this.props.dataStore.ui.address2,
                            "@City": this.props.dataStore.ui.city,
                            "@State": this.props.dataStore.ui.state,
                            "@ZipCode": this.props.dataStore.ui.zipCode
                        }
                    });
                }
                break;
            default:
                {
                    this.props.action.ui.updatePayer({ masterCrud: this.props.dataStore.crud.data, uiData: true });
                    this.props.action.ui.updatePayer({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            "@ID": this.props.dataStore.ui.selectedPayer.value,
                            "@Name": this.props.dataStore.ui.payerName,
                            "@GroupName": this.props.dataStore.ui.groupName,
                            "@Address1": this.props.dataStore.ui.address1,
                            "@Address2": this.props.dataStore.ui.address2,
                            "@City": this.props.dataStore.ui.city,
                            "@State": this.props.dataStore.ui.state,
                            "@ZipCode": this.props.dataStore.ui.zipCode,

                        }
                    });
                }
                break;
        }
    }

    public onClickRemovePayer(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.action.confirm.openConfirm("Do you want to remove " + this.props.dataStore.ui.selectedPayer.text + "? This action cannot be undone.");
    }

    public onRemovePayer(e: React.MouseEvent<HTMLButtonElement>) {
        this.onClickConfirmRemovePayer();
    }

    public onClickConfirmRemovePayer() {
        handleChange()
        this.props.action.ui.removePayer({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                updateInfo: {
                    "@ID": this.props.dataStore.ui.selectedPayer.value,
                    "@Name": this.props.dataStore.ui.payerName,
                    "@GroupName": this.props.dataStore.ui.groupName,
                    "@Address1": this.props.dataStore.ui.address1,
                    "@Address2": this.props.dataStore.ui.address2,
                    "@City": this.props.dataStore.ui.city,
                    "@State": this.props.dataStore.ui.state,
                    "@ZipCode": this.props.dataStore.ui.zipCode,
                }
            }
        });
    }

    public onClickRemoveButton(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.action.confirm.openConfirm("Do you want to remove " + this.props.dataStore.ui.payerName + "? This action cannot be undone.", () => this.onClickConfirmRemovePayer());
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    public onClickConfirmUndeletePayer(payer: MCPA_Payer) {
        this.props.action.ui.restorePayer({
            masterCrud: this.props.dataStore.crud.data,
            uiData: payer
        });
    }

    public onStateChange(e: any) {

        this.props.action.ui.updateState({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: e.label }
        });
    }

    public getStateList() {
        let lookupList = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo &&
            this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.StateList && this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.StateList.State);

        if (!lookupList)
            return [];

        let result: any[] = [];

        lookupList.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getSelectedState(lookupList: MCState[] | null, idToFind: string | undefined): IDropDownItem {
        let isStateCrudLoaded = (this.props.dataStore.crud.data && this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo &&
            this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.StateList && this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.StateList.State) ? true : false;

        if (!isStateCrudLoaded || !lookupList || lookupList.length === 0 || idToFind === ''
        ) return { value: '', label: '' };
        let item: IDropDownItem = lookupList.filter(ob => ob["@Name"] === idToFind).map(l => { return { label: l["@Name"], value: l["@ID"] } })[0];
        return item;
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }


    public render() {
        var width: React.CSSProperties = {
            width: '500px'
        }

        let addItem: MCPayerItem[] = [{
            '@ID': '',
            '@DisplayName': '- Add New Payer Address -',

        }];
        let payers: MCPayerItem[] = [];

        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo &&
            this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.Payers &&
            this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.Payers.Payer) {
            let listPayers: MCPA_Payer[] = [];
            if (!Array.isArray(this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.Payers.Payer)) {
                listPayers.push(this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.Payers.Payer);
            }
            else
                listPayers = this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.Payers.Payer;

            payers = listPayers
                        .map((payer: { [x: string]: any; }) => {
                    let dispPayer: MCPayerItem = {
                        '@ID': payer["@ID"],
                        '@DisplayName': (payer["@GroupName"] ? payer["@Name"] + ' - ' + payer["@GroupName"] : payer["@Name"])
                    };
                    return dispPayer;
                });
        }
        payers = addItem.concat(payers);
        var instruction = <React.Fragment>Use this page to add payer addresses to the auto-entry list that displays in the claim form. You can also change an existing entry or delete an entry that is no longer used. * indicates a required field.<br /></React.Fragment>;
        var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKPayerAddress(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancelPayerAddress(e)} />;

        let isAddUpdatePayerEnabled: boolean = this.getModifyButtonType() === eModifyButtonType.Add || 
                                            this.getModifyButtonType() === eModifyButtonType.Update && this.props.canEdit ? false : true;

        return (
            <DialogWrapper title="Auto Entry Payer Address Maintenance" instruction={instruction} helpUrl='/Support/Help/HELP_AE_Setup.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <SelectList>
                        <DialogFieldset height='100%'>
                            <DialogLegend>Payers</DialogLegend>
                            <SelectComponent
                                title='Payers'
                                size={20}
                                width='350px'
                                height='100%'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPayer(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@DisplayName",
                                }}
                                records={payers}
                                selectedValue={this.props.dataStore.ui.selectedPayer.value}
                            >
                            </SelectComponent>
                        </DialogFieldset>
                    </SelectList>
                    <SelectActions>
                        <Input
                            domID="payerName-input"
                            label="*Payer Name:"
                            forceuppercase="true"
                            maxLength={60}
                            initialValue={this.props.dataStore.ui.payerName}
                            hasError={this.props.dataStore.ui.payerName_payerError && this.props.dataStore.ui.payerName_payerError !== ''}
                            errorMessage={this.props.dataStore.ui.payerName_payerError}
                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updatePayerName(e.target.value.toUpperCase())}
                        />
                        <Input
                            domID="groupName-input"
                            label="Group Name:"
                            forceuppercase="true"
                            maxLength={18}
                            initialValue={this.props.dataStore.ui.groupName}
                            hasError={this.props.dataStore.ui.groupName_payerError && this.props.dataStore.ui.groupName_payerError.trim() !== ""}
                            errorMessage={this.props.dataStore.ui.groupName_payerError}
                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateGroupName(e.target.value.toUpperCase())}
                        />
                        <Input
                            domID="address1-input"
                            label="*Address Line 1:"
                            forceuppercase="true"
                            maxLength={55}
                            initialValue={this.props.dataStore.ui.address1}
                            hasError={this.props.dataStore.ui.address1_payerError && this.props.dataStore.ui.address1_payerError.trim() !== ""}
                            errorMessage={this.props.dataStore.ui.address1_payerError}
                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateAddress1(e.target.value.toUpperCase())}
                        />
                        <Input
                            domID="address2-input"
                            label="Address Line 2:"
                            forceuppercase="true"
                            maxLength={55}
                            initialValue={this.props.dataStore.ui.address2}
                            hasError={this.props.dataStore.ui.address2_payerError && this.props.dataStore.ui.address2_payerError.trim() !== ""}
                            errorMessage={this.props.dataStore.ui.address2_payerError}
                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateAddress2(e.target.value.toUpperCase())}
                        />
                        <Input
                            domID="city-input"
                            label="*City:"
                            forceuppercase="true"
                            maxLength={30}
                            initialValue={this.props.dataStore.ui.city}
                            hasError={this.props.dataStore.ui.city_payerError && this.props.dataStore.ui.city_payerError.trim() !== ""}
                            errorMessage={this.props.dataStore.ui.city_payerError}
                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateCity(e.target.value.toUpperCase())}
                        />
                        <SelectDropdownV2
                            styles={{ control: customControlStyles }}
                            domID="select-stateList-id"
                            className="dropdown"
                            label="*State:"
                            isClearable={false}
                            isSearchable={true}
                            size='small'
                            hasError={this.props.dataStore.ui.state_payerError && this.props.dataStore.ui.state_payerError !== ""}
                            errorMessage={this.props.dataStore.ui.state_payerError}
                            onChange={(e: any) => this.onStateChange(e)}
                            options={this.getStateList()}
                            initialValue={this.getSelectedState(
                                (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo
                                    ? null : this.props.dataStore.crud.data.AEOtherPayerMaintenanceInfo.StateList.State),
                                this.props.dataStore.ui.state)}
                            
                        />
                        <Input
                            domID="zipCode-input"
                            label="*ZIP Code:"
                            forceuppercase="true"
                            maxLength={9}
                            initialValue={this.props.dataStore.ui.zipCode}
                            hasError={this.props.dataStore.ui.zipCode_payerError && this.props.dataStore.ui.zipCode_payerError.trim() !== ""}
                            errorMessage={this.props.dataStore.ui.zipCode_payerError}
                            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateZipCode(e.target.value.toUpperCase())} />

                        <SelectButtons>
                            <Button
                                domID="automation-id"
                                name={this.getModifyButtonText()}
                                size="small"
                                buttonType="standard"
                                disabled={isAddUpdatePayerEnabled}
                                type="button"
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                    handleChange()
                                    this.onClickModifyButton(e)
                                }}
                            />
                            <Button
                                domID="automation-id"
                                name="REMOVE"
                                size="small"
                                buttonType="standard"
                                type="button"
                                disabled={this.isRemoveButtonDisabled()}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickRemovePayer(e)}

                            />
                        </SelectButtons>
                    </SelectActions>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemovePayer(e)}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IPayerAddressState, IPayerAddressActionProps, IOwnProps, IPayerAddressProps, ApplicationState>(
    createCrudMapStateToProps('payerAddress'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(PayerAddress);
export default withRouter(connectedHoc);