import * as React from 'react';
import { noop } from 'lodash';
import styled from 'styled-components';
import {IARMDatePickerProps, ARMDatePicker, ARMDatePickerErrorMessage} from '@common/DateOrTimeRelated/ARMDatePicker';
import { ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';

export type InnerDateFieldProps = Omit<IARMDatePickerProps,
    'assocTo'|
    'assocFrom' |
    'domID' |
    'initialDate' |
    'label' |
    'onInputChange'
    >;
export interface IARMDateRangeProps extends InnerDateFieldProps {
    direction?: 'vertical' | 'horizontal';
    domIdPrefix?: string;
    initialFrom: string;
    initialTo: string;
    labelFrom?: string;
    labelTo?: string;
    onFromChange?: Function;
    onToChange?: Function;
}

export interface IARMDateRangeState {

}

// Note you need to manually add this class to get the input grayed out
// It doesn't actually prevent the manual input. Use lockKeyboardInput for that
const preventManualInput = `
    .prevent-manual-input .DateInput:after {
        content: ' ';
        position: absolute;
        left: -5px;
        right: 0;
        top: 4px;
        bottom: 2px;
        background: rgba(200, 200, 200, .3);
        cursor: not-allowed;
        border-radius: 4px;
    }
`;

interface IRangeDisplayProps {
    maxWidth?: string;
}
// TODO: should min-width be on here?
const DateRangeRow = styled.div<IRangeDisplayProps>`
    display: flex;
    flex-direction: row;
    gap: 10px;
    ${preventManualInput}
    max-width: ${p => p.maxWidth?? '550px'}
`;
const DateRangeColumn = styled(ContentColumnWrapper)<IRangeDisplayProps>`
    gap: 10px;
    ${preventManualInput}
    max-width: ${p => p.maxWidth?? '550px'}
`;

export default class ARMDateRange extends React.PureComponent<IARMDateRangeProps, IARMDateRangeState> {
    constructor(props: IARMDateRangeProps) {
        super(props);
    }

    static defaultProps = {
        domIdPrefix: 'date-range-',
        initialFrom: '',
        initialTo: '',
        onFromChange: noop,
        onToChange: noop,
    }

    public renderDateFields() {
        // hasError gets passed through to highlight both fields, but we handle the error message in this component, if applicable
        const { domIdPrefix, errorMessage, initialFrom, initialTo, labelFrom, labelTo, onFromChange, onToChange, ...otherProps } = this.props;
        return (
            <React.Fragment>
                <ARMDatePicker 
                    assocTo={initialTo}
                    domID={`${domIdPrefix}from`}
                    initialDate={initialFrom}
                    label={labelFrom}
                    onInputChange={onFromChange}
                    {...otherProps}
                />   
                <ARMDatePicker 
                    assocFrom={initialFrom}
                    domID={`${domIdPrefix}to`}
                    initialDate={initialTo}
                    label={labelTo}
                    onInputChange={onToChange}
                    {...otherProps}
                />    
            </React.Fragment>
        );
    }

    public render() {
        const {hasError, errorMessage} = this.props;
        if (this.props.direction === 'vertical') {
            return (
                <DateRangeColumn>
                    {this.renderDateFields()}
                    {(hasError && errorMessage) && <ARMDatePickerErrorMessage >{errorMessage}</ARMDatePickerErrorMessage>}
                </DateRangeColumn>
            );
        }
        return (
            <ContentColumnWrapper>
                <DateRangeRow>{this.renderDateFields()}</DateRangeRow>
                {(hasError && errorMessage) && <ARMDatePickerErrorMessage >{errorMessage}</ARMDatePickerErrorMessage>}
            </ContentColumnWrapper>
        );
    }
};