import { Reducer, Action } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"  

//import { IReportCreateState, IReportCreateActions, IReportCreateActionProps, actionCreators, validationCallback } from '@store/ReportCreateStore';
//import { defaultState as defaultUIState } from '@store/ui/ReportCreateUI';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IReportCreateUIState {
    //reportList: APIRC_ReportCreateWrapper;  // the visual data of the reportlist

    reportCreateErrorHandler?: string;
    selectedReportType: {}; //}APIRC_ReportDetails; // the node selected by above

    //isPdf: boolean;   
    //isCsv: boolean;   
    //isXml: boolean;   
    //isTxt: boolean;   
    //isOther: boolean; 
    viewReportList: ViewReportList[];

    changed: boolean;
    isBusy: boolean;
}

export interface IReportCreateUIData {
    id: string;
    index: string;
    value: boolean;
}

export const defaultFilter: IReportCreateUIData = {
    id: '',
    index: '',
    value: false,
}

interface IReportCreateUIStatusMessage {
    value: string;
}

const emptyCreateReportItem: APIRC_ReportDetails = {
    "@Name": '',
};

export class ViewReportList {
    Name: string = "";
    PDF?: any;
    CSV?: any;
    XML?: any;
    TXT?: any;
    Other?: any;
}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ICR_LoadReportList extends IApiActionData<APIRC_ReportCreateWrapper, IReportCreateUIData> { }
export interface ICF_FieldValue extends IApiActionData<APICF_ListPopulationContainer, IReportCreateUIStatusMessage> { }

export const actionCreators = {
    selectCreateReportTypeList: (reportList: ICR_LoadReportList) => createDataAction('CR_SELECT_REPORTLIST', reportList),
    displayErrorMessage: (fieldInfo: ICF_FieldValue) => createDataAction('CR_ERROR_HANDLER_CONFIRM', fieldInfo),
    setBusy: (fieldInfo: ICF_FieldValue) => createDataAction('CREATE_REPORT_SETBUSY', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IReportCreateUIState = {
    //reportList: null,
    reportCreateErrorHandler: '',
    selectedReportType: emptyCreateReportItem,
    viewReportList: [],
    changed: false,
    isBusy: false,
};

// ----------------
// misc functions - 
// debuke says: FUS & CTV are in "other", so you can have multiple checkboxes inside Other... 
//
function getReportListFormState(api: APIRC_ReportCreateWrapper, id: string): any {

    let processRCFormState: IReportCreateUIState = {
        changed: false,
        isBusy: false,
        selectedReportType: {},
        viewReportList: [],
    }

    let reportType = "Unreleased";
    /*
    let singleReport: ViewReportList = {};

    let reportOf = api.UnreleasedReportList;
    switch (id) {
        case 'Released':
            reportOf = api.ReleasedReportList;
            break;
        case 'Remit':
            reportOf = api.RemitReportList;
            break;
        case 'Submission':
            reportOf = api.SubmissionReportList;
            break;
        case 'System':
            reportOf = api.SystemReportList;
            break;
        case 'Miscellaneous':
            reportOf = api.MiscellaneousReportList;
            break;
        case 'Utility':
            reportOf = api.UtilityReportList;
            break;
        case 'Lookup':
            reportOf = api.LookupReportList;
            break;
        case 'Custom':
            reportOf = api.CustomReportList;
            break;
        case 'ClaimVision':
            reportOf = api.ClaimVisionReportList;
            break;
        case 'Management':
            reportOf = api.ManagementReportList;
            break;
        case 'Direct':
            reportOf = api.DirectReportList;
            break;
        case 'Outsource':
            reportOf = api.OutsourceReportList;
            break;
    }

    //switch (id) {
    //    case 'ReleasedClaim':
            //let reportStateArr: any = Object.values(api!.ReleasedReportList);
            if (reportOf && reportOf?.length > 0) {
                for (let reportIndex = 0; reportIndex < reportOf.length; reportIndex++) {
                    //let unreleasedReports = api.UnreleasedReportList as Array<any>;
                        //processRCFormState.selectedReportType = api.UnreleasedReportList;
                    singleReport.Name = reportOf.Name;
                    if (reportOf.ReportInfo && reportOf.ReportInfo.Formats && reportOf.ReportInfo.Formats.length) {
                        if (reportOf && reportOf.length && reportOf.length > 0) {
                            let reportType = reportOf[reportIndex].ReportInfo.Type;
                            let reportAuditNo = reportOf[reportIndex].ReportInfo.AuditNo;
                            switch (reportType) {
                                case 'PDF':
                                    singleReport.PDF = true;
                                break;
                                case 'CSV':
                                    singleReport.CSV = true;
                                    break;
                                case 'XML':
                                    singleReport.XML = true;
                                    break;
                                case 'TXT':
                                    singleReport.TXT = true;
                                    break;
                                case 'Other':
                                    singleReport.Other = true;
                                    break;
                            }
                        }
                    }

                }
                processRCFormState.viewReportList.push(singleReport);
            }
    //}
    // first, which type of report is it?
        */

    return processRCFormState;
}

function getReportCreateFormState(currentItem: any, api: APIRC_ReportCreateWrapper) {
    

}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IReportCreateUIState, KnownActions> = (state: IReportCreateUIState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {

            case 'CR_SELECT_REPORTLIST':
                if (action.data.api) {
                    let reportListFormState = getReportListFormState(action.data.api, action.data.uiData.id);
                    //let data = action.data.api.AutoModuleMaintenanceInfo.ModuleList.Module.find(ob => { return ob["@Name"] === action.data.uiData.id });
                    return {
                        ...state,
                        ...reportListFormState,
                        reportCreateErrorHandler: undefined
                    }
                }
                break;

            case 'CR_ERROR_HANDLER_CONFIRM':
                return {
                    reportCreateErrorHandler: action.data.uiData.value,
                }
                break;
            case 'CREATE_REPORT_SETBUSY':
                return {
                    isBusy: action.data.uiData.value,
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;

        }
    }

    return state || defaultState;
}