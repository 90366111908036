import * as React from "react";
import { DialogFieldset, DialogLegend } from "../../../common/DialogStyles";
import { Facility, LOBElement, MCFacilityMaintenanceInfo } from "@store/api/FacilityMasterType";
import { CrudPermissions } from "../CrudPermissions";
import styled from "styled-components";
import { Button } from 'ui-core';
import { PayerInfoCol1 } from './PayerInfoCol1';
import { PayerInfoCol2 } from './PayerInfoCol2';
import { DefaultFacilityGenerator } from "../DefaultFacilityGenerator";
import _ from "lodash";

export const ComponentWrapper = styled.div`
    .fieldLabel{
        font-weight: bold;
        text-align: right;
        white-space: nowrap;
    }

    input {
        text-transform: uppercase;
        white-space:pre-wrap;
    }
`;

interface IComponentProps {
    maintenanceInfo: MCFacilityMaintenanceInfo | undefined,
    facility: Facility,
    crudPermissions: CrudPermissions,
    fieldFocus: string,
    updateSelectedFacilityPayerInfo: Function,
    validationAggregation: Map<string, boolean>,
    validationAggregationRoutine: Function,
    cursorPos: number,
    isFacilityPayerInfoSubmit: boolean,
    onClickAddFacilityPayerInfo: Function,
    onClickUpdateFacilityPayerInfo: Function,
    refreshPayerInfoList: boolean,
    originalFacility: Facility,
    facilityPayerInfoIsValid: boolean,
    onClickRemoveFacilityPayerInfo: Function,
    removedFacilityPayerInfoIds: string[],
    validateFacilityPayerInfo: Function,
    undoFacilityPayerInfoSubmitState: Function,
    resetValidators: Function,
    selectedPayerInfoId: string,
    updateSelectedPayerInfoId: Function
};

interface IComponentState {
    isFacilityPayerInfoSubmit: boolean,
    selectedFacilityPayerInfoId: string,
    selectedFacilityPayerInfo: LOBElement | undefined,
    originalPayerInfoList: LOBElement[] | undefined,
};

export class PayerInformation extends React.Component<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props);

        this.state = {
            isFacilityPayerInfoSubmit: false,
            selectedFacilityPayerInfoId: '0',
            selectedFacilityPayerInfo: undefined,
            originalPayerInfoList: undefined,
        }
    }

    getPayerInfoIsValid() {
        const {
            validationAggregation
        } = this.props;

        const validationAggregationPayerInfoKeys =
            Array.from(validationAggregation.keys()).filter(x => x.startsWith('PayerInfo'));

        let payerInfoIsValid = true;
        validationAggregationPayerInfoKeys.forEach(x => {
            if (validationAggregation.get(x) == false) {
                payerInfoIsValid = false;
                return;
            }
        })

        return payerInfoIsValid;
    }

    getPayerInfoConcatenatedName(lob: LOBElement) {
        const {
            maintenanceInfo
        } = this.props;

        if (!maintenanceInfo) return;

        // get the lob name
        let lobName = '';
        let lobFilterList = maintenanceInfo!.LOBList.LOB.filter(x => x["@ID"] == lob["@LOBID"]);
        if (lobFilterList.length) {
            lobName = lobFilterList[0]["@Name"];
        }

        // get the payer name
        let payerName = '';
        let payerFilterList = maintenanceInfo!.PayerList.Payer.filter(x => x["@ID"] == lob["@PayerID"]);
        if (payerFilterList.length) {
            payerName = payerFilterList[0]["@Name"];
        }

        // get the provider number
        let providerNumber = lob["@ProviderNo"].toUpperCase();

        // return the concatenated name
        return `${lobName} - ${payerName} - ${providerNumber}`;
    }

    setSelectedFacilityPayerInfoDefault() {
        this.setState({
            selectedFacilityPayerInfoId: '0',
            selectedFacilityPayerInfo: DefaultFacilityGenerator.generateDefaultPayerInfo()
        });
    }

    setSelectedFacilityPayerInfo(x: React.ChangeEvent<HTMLSelectElement>) {
        const {
            facility,
            originalFacility,
            resetValidators,
        } = this.props;

        const {
            selectedFacilityPayerInfoId
        } = this.state;

        // format LOB as array if needed
        if (facility.LOB && !Array.isArray(facility.LOB)) {
            facility.LOB = [facility.LOB]
        }

        // reset validators
        resetValidators();

        // get the previous payer info id
        const previousFacilityPayerInfoId = selectedFacilityPayerInfoId;

        if (facility && facility.LOB && Array.isArray(facility.LOB)) {
            
            this.resetPreviousPayerInfo(facility, originalFacility, previousFacilityPayerInfoId);

            // get the selected payer info id
            const selectedFacilityPayerInfoId2 = x.currentTarget[x.currentTarget.selectedIndex].id;

            // get the selected payer info object by id
            const selectedFacilityPayerInfo =
                facility.LOB.filter(y => y["@ID"] == selectedFacilityPayerInfoId2)[0];

            this.setState({
                selectedFacilityPayerInfoId: selectedFacilityPayerInfoId2,
                selectedFacilityPayerInfo
            });
        }
    }

    resetPreviousPayerInfo(facility: Facility, originalFacility: Facility, previousFacilityPayerInfoId: string) {
        const {
            resetValidators
        } = this.props;

        if (facility && facility.LOB && Array.isArray(facility.LOB)) {

            // format originalFacility LOB as array if needed
            if (originalFacility.LOB && !Array.isArray(originalFacility.LOB)) {
                originalFacility.LOB = [originalFacility.LOB]
            }

            // get the original payer info for previous payer info
            const originalPayerInfoIndex = (originalFacility.LOB as LOBElement[])?.findIndex(x => x['@ID'] == previousFacilityPayerInfoId);

            // for persisted payerinfos
            if (originalPayerInfoIndex > -1) {
                let originalPayerInfo = (originalFacility.LOB as LOBElement[])?.find(x => x['@ID'] == previousFacilityPayerInfoId);
                if (previousFacilityPayerInfoId != '0') {
                    // reset the payer info to original and replace in set for appropriate toggle support
                    facility.LOB.splice(originalPayerInfoIndex, 1, ({ ...originalPayerInfo } as LOBElement));
                }
            }
            // generate and associate empty default payer info for id = 0 for both lists
            let findIndex = (facility.LOB as LOBElement[])?.findIndex(x => x['@ID'] == '0');
            if (findIndex > -1) {
                (facility.LOB as LOBElement[])?.splice(findIndex, 1, DefaultFacilityGenerator.generateDefaultPayerInfo());
            }
            let findOrigIndex = (originalFacility.LOB as LOBElement[])?.findIndex(x => x['@ID'] == '0');
            if (findOrigIndex > -1) {
                (originalFacility.LOB as LOBElement[])?.splice(findIndex, 1, DefaultFacilityGenerator.generateDefaultPayerInfo());
            }
        }
    }

    getPayerInfoList(refreshPayerInfoList: boolean) {
         const {
            facility,
            isFacilityPayerInfoSubmit,
            facilityPayerInfoIsValid,
            removedFacilityPayerInfoIds, 
             selectedPayerInfoId
        } = this.props;

        const {
            originalFacility
        } = this.props;

        let originalPayerInfoList = (originalFacility.LOB as LOBElement[]);
        let originalPayerInfoList2 = originalPayerInfoList;

        // if this is a submit and payer info is valid
        if (isFacilityPayerInfoSubmit && facilityPayerInfoIsValid) {

            // get the latest updates from the primary edit facility
            let cloneFacility = _.cloneDeep(facility);

            // if facility info is not in proper array then convert it to one
            if (cloneFacility.LOB && !Array.isArray(cloneFacility.LOB)) {
                cloneFacility.LOB = [cloneFacility.LOB]
                originalPayerInfoList2 = cloneFacility.LOB;

            } else {
                // else payer info is in a proper array
                originalPayerInfoList2 = (cloneFacility.LOB as LOBElement[]);
            }

        // else this is not the result of a successful submit so display orignal payer info data
        } else {
            if (originalPayerInfoList2 && !Array.isArray(originalPayerInfoList2)) {
                originalPayerInfoList2 = [originalPayerInfoList2]
            }
        }

        // filter out the payer info items marked for removal
        if (removedFacilityPayerInfoIds && originalPayerInfoList2) {
            originalPayerInfoList2 = originalPayerInfoList2.filter(x => !removedFacilityPayerInfoIds.includes(x["@ID"]));
        }

        return(
            <table style={{ width: 900 }}>
                <tbody>
                    <tr>
                        <td>
                            <select size={6} style={{ width: '100%' }}
                                onChange={x => {
                                    // revert validation errors on toggle
                                    this.setSelectedFacilityPayerInfo(x);
                                    this.props.updateSelectedPayerInfoId(x.currentTarget[x.currentTarget.selectedIndex].id);
                                }}
                                defaultValue='0'
                                    value={this.props.selectedPayerInfoId}
                                >
                                <option id="0" key="0" value="0">- ADD A NEW PAYER -</option>
                                {originalPayerInfoList2 &&
                                    originalPayerInfoList2.filter(x => x["@ID"] != '0').map(x => {
                                        return (
                                            <option id={x["@ID"]} value={x["@ID"]}>
                                                {
                                                    this.getPayerInfoConcatenatedName(x)
                                                }
                                            </option>
                                        )
                                 })}
                            </select>
                        </td>
                    </tr>
                </tbody>
            </table>
        )
    }

    componentDidMount() {
        this.ensureOriginalPayerInfoList();
    }

    // ensure that original payer info list is maintained in state for reference
    ensureOriginalPayerInfoList() {
        const {
            facility
        } = this.props;

        const {
            originalPayerInfoList,
        } = this.state;

        if (!originalPayerInfoList) {
            let cloneFacility = _.cloneDeep(facility);

            // ensure proper payerinfo array
            if (cloneFacility.LOB && !Array.isArray(cloneFacility.LOB)) {
                cloneFacility.LOB = [cloneFacility.LOB]
            }

            this.setState({
                originalPayerInfoList: cloneFacility.LOB
            })
        }
    }

    render() {
        const {
            maintenanceInfo,
            facility,
            isFacilityPayerInfoSubmit,
            updateSelectedFacilityPayerInfo,
            onClickAddFacilityPayerInfo,
            onClickUpdateFacilityPayerInfo,
            cursorPos,
            validationAggregation,
            validationAggregationRoutine,
            fieldFocus,
            refreshPayerInfoList,
            onClickRemoveFacilityPayerInfo,
            validateFacilityPayerInfo,
            crudPermissions,
            selectedPayerInfoId
        } = this.props;

        const {
            selectedFacilityPayerInfoId,
        } = this.state;

        // get a handle to the selected facility payer info
        let selectedFacilityPayerInfo = DefaultFacilityGenerator.generateDefaultPayerInfo();

        if (facility.LOB && !Array.isArray(facility.LOB)) {
            facility.LOB = [facility.LOB]
        }

        if (facility.LOB && Array.isArray(facility.LOB) && facility.LOB.length) {
            const findResult = facility.LOB.find(x => x["@ID"] == selectedPayerInfoId);
            if (findResult) {
                selectedFacilityPayerInfo = findResult;
            }
        }

        // get the payer info list for the selected facility
        const payerInfoList = this.getPayerInfoList(refreshPayerInfoList);

        // build the key values for child components
        const payerInfoCol1Key = 'payerInfoCol1-' + selectedPayerInfoId;
        const payerInfoCol2Key = 'payerInfoCol2-' + selectedPayerInfoId;
        const disableAdd = facility['@ID'] === "0" || !crudPermissions.canCreatePayer;
        const disableUpdate = facility['@ID'] === "0" || !crudPermissions.canEditPayer;
        const disableRemove = selectedPayerInfoId === "0" || !crudPermissions.canDeletePayer;
        return (
            <ComponentWrapper>
                <DialogFieldset>
                    <DialogLegend>Payer Information</DialogLegend>
                    {payerInfoList}

                    <div>

                        <div style={{display:'flex'}}>
                            <div style={{display:'inline-block'}}>
                                <PayerInfoCol1
                                    key={payerInfoCol1Key}
                                    maintenanceInfo={maintenanceInfo}
                                    facility={facility}
                                    isFacilityPayerInfoSubmit={isFacilityPayerInfoSubmit}
                                    fieldFocus={fieldFocus}
                                    updateSelectedFacilityPayerInfo={updateSelectedFacilityPayerInfo}
                                    validationAggregation={validationAggregation}
                                    validationAggregationRoutine={validationAggregationRoutine}
                                    cursorPos={cursorPos}
                                    selectedFacilityPayerInfoId={selectedPayerInfoId}
                                    selectedFacilityPayerInfo={selectedFacilityPayerInfo}
                                />
                            </div>
                            <div style={{ width: 25, display: 'inline-block' }}></div>
                            <div style={{ display: 'inline-block' }}>
                                <PayerInfoCol2
                                    key={payerInfoCol2Key}
                                    maintenanceInfo={maintenanceInfo}
                                    facility={facility}
                                    isFacilityPayerInfoSubmit={isFacilityPayerInfoSubmit}
                                    fieldFocus={fieldFocus}
                                    updateSelectedFacilityPayerInfo={updateSelectedFacilityPayerInfo}
                                    validationAggregation={validationAggregation}
                                    validationAggregationRoutine={validationAggregationRoutine}
                                    cursorPos={cursorPos}
                                    selectedFacilityPayerInfoId={selectedPayerInfoId}
                                    selectedFacilityPayerInfo={selectedFacilityPayerInfo}
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 40 }}>
                            <div style={{
                                display: 'flex', justifyContent: 'space-between',
                                width: selectedFacilityPayerInfo["@ID"] == '0' ? 130 : 158
                            }}>
                                {selectedFacilityPayerInfo['@ID'] == '0' &&
                                    <Button
                                        domID="add-facility-payer-info-button"
                                        name={"Add"}
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                            onClickAddFacilityPayerInfo();
                                        }}
                                        disabled={disableAdd}
                                    />
                                }

                                <div style={{width:25}}></div>

                                {selectedFacilityPayerInfo['@ID'] != '0' &&
                                    <Button
                                        domID="update-facility-payer-info-button"
                                        name={"Update"}
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                            // debugger;
                                            // set submit prop to trigger validators
                                            // validateFacilityPayerInfo();
                                            onClickUpdateFacilityPayerInfo(selectedPayerInfoId);
                                        }}
                                        disabled={disableUpdate}
                                    />
                                }

                                <div style={{ width: 25 }}></div>

                                <Button
                                    domID="remove-facility-payer-info-button"
                                    name={"Remove"}
                                    buttonType="standard"
                                    size="small"
                                    type="button"
                                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                        onClickRemoveFacilityPayerInfo(selectedPayerInfoId)
                                    }}
                                    disabled={disableRemove}
                                />
                            </div>
                        </div>

                    </div>

                </DialogFieldset>
            </ComponentWrapper>
        );
    }
}
