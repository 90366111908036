import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import {  ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';

export interface ICheckBoxComponentProps {
    id: string;
    checked: boolean;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    label?: string;
    labelBefore?: string;
    labelAbove?: string;
    width?: string;
    disabled: boolean;
    darkerGreyOut?: boolean; 
    hasError: boolean;
    sizeValue?: string;
    labelType?: string;
    lablePadding?: string;
    greyText?: boolean;
    nowrap?: boolean;
};

export interface ICheckBoxComponentState {
    disabled: boolean;
};

export interface ICheckBoxWrapperProps {
    widthValue?: string;
    hasError?: boolean;
    large?: boolean;
    compact?: boolean;
    greyText?: boolean;
    lablePadding?: string;
    nowrap?: boolean;
};

export interface ICheckBoxInputProps {
    hasError?: boolean;
    disabled: boolean;
    darkerGreyOut?: boolean; 
    large?: boolean;
    compact?: boolean;
};
//--------------------------------------------------------------

export const CheckBoxWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin: 0 auto;
    cursor: pointer;
    
    ${(props: ICheckBoxWrapperProps) => props.greyText ? `color: #37474F` : `color: inherit`};
    ${(props: ICheckBoxWrapperProps) => props.large ? `min-width: 30px` : `min-width: 20px`};
    ${(props: ICheckBoxWrapperProps) => props.large ? `height: 30px` : `min-height: 20px`}; 
    ${(props: ICheckBoxWrapperProps) => props.widthValue && `width: ${props.widthValue}`}; 
`;


export const CheckBoxInput = styled.input`
    cursor: pointer;
    position: relative;
    opacity: 1 !important;
    padding-right: 10px;

    ${(props: ICheckBoxInputProps) => props.large ? `min-width: 30px` : `min-width: 14px`};
    ${(props: ICheckBoxInputProps) => props.large ? `max-width: 30px`  : `max-width: 14px`};
    ${(props: ICheckBoxInputProps) => props.large ? `height: 14px` : `height: 14px`};
 	
    :before {
         content: "";
         display: block;
         position: absolute;
         top: 0;
         left: 0;
         border: 1px solid ${Colors.darkGrey};
         background-color: white;

         ${(props: ICheckBoxInputProps) => props.disabled && `background-color: ${props.darkerGreyOut ? Colors.middleGrey : Colors.lightestGrey} !important`};
         ${(props: ICheckBoxInputProps) => props.disabled && `border: 1px solid ${Colors.lightGrey} !important`};
         ${(props: ICheckBoxInputProps) => props.hasError && `border: 1px solid ${Colors.darkRed}`};
         ${(props: ICheckBoxInputProps) => props.large ? `width: 30px` : `width: 14px`};
         ${(props: ICheckBoxInputProps) => props.large ? `height: 30px` : `height: 14px`};

	}

    :checked:after {
         content: "";
         display: block;
         border: solid ${Colors.black};         
         -webkit-transform: rotate(45deg);
         -ms-transform: rotate(45deg);
         transform: rotate(45deg);
         position: absolute;
         top: 2px;

         ${(props: ICheckBoxInputProps) => props.large ? `left: 8px` : `left: 5px`};
         ${(props: ICheckBoxInputProps) => props.large ? `width: 13px` : `width: 5px`};
         ${(props: ICheckBoxInputProps) => props.large ? `height: 22px` : `height: 10px`};
         ${(props: ICheckBoxInputProps) => props.large ? `border-width: 0 4px 4px 0` : `border-width: 0 2px 2px 0`};
	}
`;

export const CheckBoxLabel = styled.label`
    padding-top: 2px;
    
    ${Typography.ARMFontFamily};
    ${(props: ICheckBoxWrapperProps) => props.large ? `${Typography.large}` : `font-size: 10pt`};
    ${(props: ICheckBoxWrapperProps) => props.compact && props.compact == true ? `padding-left: 3px, font-size: 11px` : `padding-left: 15px`};
    ${(props: ICheckBoxWrapperProps) => props.compact && props.compact == true ? `font-weight: 400 !important` : `${Typography.bold}`}; 
    ${(props: ICheckBoxWrapperProps) => props.nowrap && `white-space: nowrap`};
`;

export const CheckBoxLabelBefore = styled.label`
    ${(props: any) => props.lablePadding ? `padding-left: ${props.lablePadding}` : `padding-left: 15px`};
    padding-top: 2px;
    
    color: ${Colors.grey100};
    
    ${Typography.ARMFontFamily};
    ${(props: ICheckBoxWrapperProps) => props.large ? `${Typography.large}` : `${Typography.small}`};
    ${(props: ICheckBoxWrapperProps) => props.compact ? `font-weight: 400, font-size: 11px` : `${Typography.bold}`};
 
`;


const CheckBoxLabelAbove = styled.label`
    padding-left: 2px;
    padding-top: 2px;

    color: ${Colors.grey100};
    
    ${Typography.ARMFontFamily};
    ${(props: ICheckBoxWrapperProps) => props.large ? `${Typography.large}` : `${Typography.small}`};
    ${(props: ICheckBoxWrapperProps) => props.compact ? `font-weight: 400, font-size: 11px` : `${Typography.bold}`};
`;



export class CheckBoxComponent extends React.Component<ICheckBoxComponentProps, ICheckBoxComponentState>
{
    static defaultProps: ICheckBoxComponentProps = {
        id: "",
    	checked: false,
    	width: "100px",
        disabled: false,
        hasError: false,
        sizeValue: undefined,
        labelType: undefined,
        greyText: false,
        nowrap: false
    };

    constructor(props: ICheckBoxComponentProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
    }


    renderAbove() {
        return (
            <CheckBoxWrapper
                greyText={this.props.greyText}
                widthValue={this.props.width}
                hasError={this.props.hasError}
                id={this.props.id + "_wrapper"}
                large={this.props.sizeValue == 'large' ? true : undefined} 
                compact={this.props.labelType == 'compact' ? true : undefined} 
            >          
                <ContentColumnWrapper>
                    <CheckBoxLabelAbove
                        id={this.props.id + "_label"}
                        large={this.props.sizeValue == 'large' ? true : undefined}
                        compact={this.props.labelType == 'compact' ? true : undefined}
                        className={'arm_checkbox_label_above'}
                        nowrap={this.props.nowrap}
                    >
                        {this.props.labelAbove}
                    </CheckBoxLabelAbove>
                    <CheckBoxInput
                        id={this.props.id}
                        type="checkbox"
                        checked={this.props.checked}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChange) this.props.onChange(e) }}
                        disabled={this.props.disabled}
                        hasError={this.props.hasError}
                        large={this.props.sizeValue == 'large' ? true : undefined} 
                        compact={this.props.labelType == 'compact' ? true : undefined} 

                />
                    <span/>
                </ContentColumnWrapper>   
            </CheckBoxWrapper>
            );

    }

    renderBefore() {
        return (
            <CheckBoxWrapper
                greyText={this.props.greyText}
                widthValue={this.props.width}
                hasError={this.props.hasError}
                id={this.props.id + "_wrapper"}
                large={this.props.sizeValue == 'large' ? true : undefined} 
                compact={this.props.labelType == 'compact' ? true : undefined} 
            >
                <CheckBoxLabelBefore
                    id={this.props.id + "_label"}
                    lablePadding={this.props.lablePadding}
                    large={this.props.sizeValue == 'large' ? true : undefined}
                    compact={this.props.labelType == 'compact' ? true : undefined} 
                    className={'arm_checkbox_label_before'}
                    nowrap={this.props.nowrap}
                >
                    {this.props.labelBefore}
                </CheckBoxLabelBefore>
                <CheckBoxInput
                    id={this.props.id}
                    type="checkbox"
                    checked={this.props.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChange) this.props.onChange(e) }}
                    disabled={this.props.disabled}
                    hasError={this.props.hasError}
                    large={this.props.sizeValue == 'large' ? true : undefined} 
                    compact={this.props.labelType == 'compact' ? true : undefined} 
                />
                <span />

            </CheckBoxWrapper>
        );

    }
    renderOriginal() {
        return (
            <CheckBoxWrapper
                greyText={this.props.greyText}
                widthValue={this.props.width}
                hasError={this.props.hasError}
                id={this.props.id + "_wrapper"}
                large={this.props.sizeValue == 'large' ? true : undefined} 
                compact={this.props.labelType == 'compact' ? true : undefined} 
            >
                <CheckBoxInput
                    darkerGreyOut={this.props.darkerGreyOut}
                    id={this.props.id}
                    type="checkbox"
                    checked={this.props.checked}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { if (this.props.onChange) this.props.onChange(e) }}
                    disabled={this.props.disabled}
                    hasError={this.props.hasError}
                    large={this.props.sizeValue == 'large' ? true : undefined} 
                    compact={this.props.labelType == 'compact' ? true : undefined} 
                />
                <span />
                <CheckBoxLabel
                    id={this.props.id + "_label"}
                    large={this.props.sizeValue == 'large' ? true : undefined}
                    compact={this.props.labelType == 'compact' ? true : undefined} 
                    className={'arm_checkbox_label'}
                    nowrap={this.props.nowrap}
                >
                    {this.props.label}
                </CheckBoxLabel>
            </CheckBoxWrapper>
        );

    }

    render() {
        if (this.props.labelAbove && this.props.labelAbove.length > 0) {
            return this.renderAbove();
        } else if (this.props.labelBefore && this.props.labelBefore.length > 0) {
            return this.renderBefore();
        } else {
            return this.renderOriginal();
        }
    }
};