import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IReportListUIState {
    viewReportAlert?: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface ISelectedOperationData {
    index: number;
    value: string;
    text: string;
};

export const defaultOperation: ISelectedOperationData = {
    index: 0,
    value: '',
    text: '',
}

export interface IReportOperation extends ICrudActionData<MCRLReport, ISelectedOperationData> { }

export const actionCreators = {
    sendReportListAlert: (operationInfo: IReportOperation) => createDataAction('SEND_REPORT_ALERT', operationInfo),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IReportListUIState = {
    viewReportAlert: ''
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IReportListUIState, KnownActions> = (state: IReportListUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SEND_REPORT_ALERT':
                return {
                    ...state,
                    viewReportAlert: action.data.uiData.value,
                }            
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                return state;
        }
    }

    return state || defaultState;
}
