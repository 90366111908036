import * as React from 'react';
import styled from 'styled-components';
import { Radio } from 'ui-core';
import Typography from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { ContentRowWrapper } from "../DialogWrapper";

export interface RadioGroupItem<T> {value:T; labelAfter:string, name: string};
export interface RadioGroupProps<T> extends React.HTMLAttributes<HTMLDivElement> {
    items: RadioGroupItem<T>[];
    initialValue: T;
    onGroupChange: (value:T) => void;
    label?: string;
}

export interface IMoreCss {css?:string};

//just put any additional css you want into the css prop and away you go
const ARMContentRowJustify = styled.div<IMoreCss>`
    font-family: ${Typography.ARMFontFamily};
    display: flex;
    gap: 7px;
    ${p=>p.css}
`;

const InputLabel = styled.label`
  display: block;
  margin-bottom: 0.3rem;
  color: ${Colors.grey100};
  ${Typography.ARMFontFamily};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  ${Typography.bold};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  padding-right: 2px;
`;

function ArmRadioButtonGroup <T>(props: RadioGroupProps<T>) {
    const [selectedItem, setSelectedItem] = React.useState<RadioGroupItem<T>>();
    React.useEffect(() => {
        if (selectedItem) props.onGroupChange(selectedItem.value);
    }, [selectedItem]);

    const checkedItem = selectedItem || props.items.find((item) => item.value === props.initialValue);
    return (
        <ARMContentRowJustify className={props.className} id={props.id}>
            {props.label && <InputLabel className='radio-group-label'>{props.label}</InputLabel>}
            {props.items.map((item, idx) => (
                <Radio checked={checkedItem?.value === item.value} value={item.value} labelAfter={item.labelAfter} key={idx} name={item.name} domID={`radio-${item.name}`} onChange={() => setSelectedItem(item as RadioGroupItem<T>)}  />
            ))}
        </ARMContentRowJustify>
    );
}

export default ArmRadioButtonGroup;