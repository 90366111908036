import * as React from 'react';
import { LandingPageComponent, IComponentProps } from './LandingPageComponent';
import { Modal, Badge } from 'react-bootstrap';
import { RouteComponentProps } from 'react-router-dom';
import { Input, LayoutControl, Modal as ModelProdectUpdates } from 'ui-core';
import MessageAlerts from './MessageAlerts';
import ARMQuickSearch from './ARMQuickSearch';
import styled from 'styled-components';
import { URLs } from '@commonDevResources/constants';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { switchClient, ISwitchClientModel } from '@scripts/session/Security';
import { parseXML } from '@commonResources/xBrowserXMLLib';
import { goTo } from '@commonResources/window';
import { getRawToken } from '@scripts/session/SecurityToken';


const ModelBodyProdectUpdates = styled.div`
    background: #d3d3d34d;
`;

const InnerComponentWrapper = styled.div`
    padding-bottom: 1.5em;
    padding-top: 2em;
`;

export const AssuranceTopSection2 = styled.div`
    height: 37px;
    border-top-left-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border: 1px;
    background-color: #0f0f59;
    font-size: 11px;
    display: flex;
    width: 100%;
    color: #ffffff;
    line-height: 2.1em;
`;

export const ModelBottom = styled.div`
    display: inline;
    margin-bottom: 0.3rem;
    color: #737d85;
    font-family: 'Optum Sans C','Century Gothic',AppleGothic,sans-serif;
    font-size: 11px;
    line-height: 1.4em;
    font-weight: 600;
    letter-spacing: 0.5px;
    white-space: pre-wrap;
    margin-left: 100px;
`;

export const ModelTop = styled.div`
    display: inline;
    margin-bottom: 0.3rem;
    color: #0f0f59;
    font-family: 'Core Sans C','Century Gothic',AppleGothic,sans-serif;
    font-size: 13px;
    line-height: 1.4em;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-left: 1%;
`;

const ModalTitle = styled.div`
    font-weight: bold;
    font-size: 13px !important;
    color: #0f0f59;
    font-family: "Core Sans C", "Century Gothic", "AppleGothic", sans-serif !important;
    position: absolute;
    width: 610px;
    top: 250px;
    right: 350px;
    bottom: 0;
    left: 650px;
`;

const ModalBody = styled.div`
    border: 1px solid #9ba1a9;
    border-radius: 0px;
    width: 610px;
    max-height: 590px;
    font-family: "Core Sans C", "Century Gothic", "AppleGothic", sans-serif !important;
 	position: absolute;
    top: 280px;
    right: 350px;
    bottom: 0;
    left: 650px;
    z-index: 10040;
    overflow: auto;
    overflow-y: auto;
    background-color: Window;

    #clientSearchInput {
        padding: 12px 20px;
        border: 1px solid gray;
        border-radius: 1px;
        box-sizing: border-box;
    }

    #clientSearchList {
        overflow-y: scroll;
        overflow-y: auto;
        height: 350px;
        font-size: 12px !important;
        cursor: default !important;
        width: 100%;
    }
`;

export const ModelCaption = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: ${Colors.lighterSecondaryGrey};
`;

export const ModelCaptionText = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    ${Typography.default};
    ${Typography.defaultLineHeight};
    color: ${Colors.darkestSecondaryGrey};
    padding: 10px;
    text-align: left;
    letter-spacing: 1px;
`;

export interface IARMTopSectionProps extends IComponentProps { }

interface IARMTopSectionState {
    clientName: string;
    isCIAMUser: boolean;
    messageErrorAlerts?: any;
    messageWarningAlerts?: any;
    showClientsModal: boolean;
    showProductUpdatesModal: boolean;
    clientCount: string;
    clientSearchText: string;
    xmlRSSList: Document | null;
    displayProductUpdatesBadge: boolean;
    client?: any;
    errorCode?: number;
}

type IProps = IARMTopSectionProps & RouteComponentProps<{}>;

export default class ARMTopSection extends React.Component<IProps, IARMTopSectionState> {

    protected dataForSwitchClients: any;

    constructor(props: IProps) {
        super(props);
        this.state =
        {
            clientName: "",
            isCIAMUser: false,
            messageErrorAlerts: null,
            messageWarningAlerts: null,
            showClientsModal: false,
            showProductUpdatesModal: false,
            clientCount: '',
            clientSearchText: '',
            xmlRSSList: null,
            displayProductUpdatesBadge: false,
            client: null,
            errorCode: undefined,
        }

        this.dataForSwitchClients = '';
        this.onClientSearchInputChange = this.onClientSearchInputChange.bind(this);
        this.openClientsModal = this.openClientsModal.bind(this);
        this.goToClient = this.goToClient.bind(this);
        this.openProductUpdatesModal = this.openProductUpdatesModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    onClientSearchInputChange(event: React.ChangeEvent<HTMLInputElement>) {
        this.setState({ clientSearchText: event.target.value });
    }

    componentDidMount() {
        this.getCookieInfoFromServer();
        this.loadClientsFromServer();
        this.getRSSConfigKeys();
    }

    getCookieInfoFromServer() {
        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/GetCookieInformation", true);
        xhr.onload = () => {
            var data = JSON.parse(xhr.responseText);
            this.setState({
                clientName: data.Client,
                clientCount: data.ClientCount,
                isCIAMUser: data.IsCiamLogon === "true",
            });
            sessionStorage.setItem("Client", data.Client);
        };
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();

    }

    setMessageAlerts(msg: string, messageType: string = "") {
        var ma = [{ "index": "1", "msg": msg }];

        if (messageType && messageType == "Warning") {
            this.setState({
                messageWarningAlerts: ma
            });
        }
        else {
            this.setState({
                messageErrorAlerts: ma
            });
        }
    }

    closeModal() {
        this.setState({ showClientsModal: false, showProductUpdatesModal: false, clientSearchText: '' });
    }

    openClientsModal() {
        const { isCIAMUser, clientCount } = this.state;
        (isCIAMUser && Number(clientCount) > 0) ? goTo('/SelectSiloCID.asp?SC', '', '', '') : this.setState({ showClientsModal: true });
    }

    openProductUpdatesModal() {
        this.setState({ showProductUpdatesModal: true });
    }

    switchClientModel = {
        ClientAlias: '',
        ClientName: '',
    };

    async switchClient(switchModel: ISwitchClientModel) {
        document.body.style.cursor = "wait";
        const loginResult = await switchClient(switchModel);
        this.closeModal();
        switch (loginResult.status) {
            case 200:
                this.setState({ clientName: switchModel.ClientName });
                this.props.history.push({ pathname: '/empty' });
                this.props.history.replace({ pathname: '/LandingPage' });

                if (loginResult && loginResult.body) {
                    var responseData = loginResult.body.replace(/\"/g, '');;
                    sessionStorage.setItem('Client', responseData);
                    this.setState({ client: responseData });
                }
                break;
            case 306:
                // Password expired or reset
                this.props.history.push({
                    pathname: "/Home/ChangePassword",
                    state: { warningMessage: loginResult.body }
                });
                break;
        }
        document.body.style.cursor = "default";
    }

    goToClient(event: React.MouseEvent<HTMLTableRowElement>) {
        let selectedRow: number = event.currentTarget.sectionRowIndex;
        if (event.currentTarget && event.currentTarget.parentNode && event.currentTarget.parentNode.childNodes[selectedRow]) {
            let clientId: string = "";
            let clientName: string = "";
            if (event.currentTarget.parentNode.childNodes[selectedRow].childNodes.length > 1) {
                clientId = event.currentTarget.parentNode.childNodes[selectedRow].childNodes[0].textContent as string;
                clientName = event.currentTarget.parentNode.childNodes[selectedRow].childNodes[1].textContent as string;
            }
            this.switchClientModel = {
                ClientAlias: clientId,
                ClientName: clientName
            };
            this.switchClient(this.switchClientModel);
        }
    }

    loadClientsFromServer() {
        var clientUrl = URLs.api + '/api/data/securityinfo/securityInfoUserClientList';
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    this.dataForSwitchClients = JSON.parse(responseData);
                } else {
                    console.warn(xhr.status + " response handling " + clientUrl);
                    this.setState({ errorCode: xhr.status });
                }
            }
        };
        xhr.open('get', clientUrl, false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    buildClientList() {
        let clients = this.dataForSwitchClients.userClients;
        let clientList = '';
        let key = 0;
        if (this.dataForSwitchClients.userClients && clients.length > 0) {
            clientList = clients.map((listItem: any) => {
                key++;
                if (listItem.name.toLowerCase().indexOf(this.state.clientSearchText.toLowerCase()) == -1 &&
                    listItem.id.toLowerCase().indexOf(this.state.clientSearchText.toLowerCase()) == -1)
                    return;

                return <tr key={key} onDoubleClick={this.goToClient}><td>{listItem.id}</td><td>{listItem.name}</td></tr>;
            });
        }
        return clientList;
    }

    getRSSConfigKeys() {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    var rssConfig = JSON.parse(responseData);
                    this.BuildFeedUrl(rssConfig.RSSURLConfig, rssConfig.RSSDBScale, rssConfig.RSSInternal, rssConfig.RSSSilo, rssConfig.RSSDaysLimit, rssConfig.RSSClient);
                }
                else {
                    console.warn(xhr.status + " response handling /api/data/securityInfo/rssConfiguration");
                    this.setState({ errorCode: xhr.status });
                }
            }
        };
        xhr.open('get', URLs.api + '/api/data/securityInfo/rssConfiguration', true);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    BuildFeedUrl(url: string, dbscale: string, rssinternal: string, rsssilo: string, rssdayslimit: string, rssclient: string) {

        let data = "strurl=" + escape(url) + "&rssdayslimit=" + rssdayslimit + "&dbscale=" + dbscale + "&rssinternal=" + rssinternal + "&rsssilo=" + rsssilo + "&rssclient=" + rssclient;
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    if (responseData != null) {
                        this.checkForRSSFeeds(responseData);
                    }
                }
                else {
                    console.warn(xhr.status + " response handling /common/Shared/Includes/CacheRss.aspx");
                    this.setState({ errorCode: xhr.status });
                }
            }
        };

        xhr.open('POST', '/common/Shared/Includes/CacheRss.aspx', true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send(data);
    }

    checkForRSSFeeds(responseData: string) {
        var xmlDoc = parseXML(responseData);
        let NodeList = xmlDoc.evaluate("/rss/channel/item", xmlDoc, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
        this.setState({
            xmlRSSList: xmlDoc,
            displayProductUpdatesBadge: (NodeList.snapshotLength > 0) ? true : false,
        });
    }

    modalData() {
        if (this.state.xmlRSSList && this.state.displayProductUpdatesBadge) {
            let NodeList = this.state.xmlRSSList.evaluate("/rss/channel/item", this.state.xmlRSSList, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
            let genHtml = [];

            if (NodeList.snapshotLength > 0) {
                for (let i = 0; i < NodeList.snapshotLength; i++) {
                    let node = NodeList.snapshotItem(i);
                    if (node) {
                        var title = '';
                        var dDate = '';
                        var desc = '';
                        for (var j = 0; j < node.childNodes.length; j++) {
                            switch (node.childNodes.item(j).nodeName) {
                                case "title":
                                    title = this.htmlDecode(node.childNodes.item(j).textContent);
                                    break;
                                case "dDate":
                                    dDate = this.htmlDecode(node.childNodes.item(j).textContent);
                                    break;
                                case "description":
                                    desc = this.htmlDecode(node.childNodes.item(j).textContent);
                                    break;
                            }
                        }
                        genHtml.push(<tr><td><b>{title}</b></td><td>{dDate}</td></tr>);
                        genHtml.push(<tr><td colSpan={2}>{desc}</td></tr>);
                    }
                }
                return genHtml;
            }
            else
                return <tr>No updates found, please try again later.</tr>;
        } else
            return <tr>No updates found, please try again later.</tr>;
    }

    htmlDecode(input: string | null): string {
        if (input) {
            let e = document.createElement('div');
            e.innerHTML = input;
            return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue || '';
        }
        return '';
    }

    public onClickClose(e: React.ChangeEvent<HTMLInputElement>) {
        this.closeModal();
    }


    render() {
        let displayClass = Number(this.state.clientCount) > 1 ? "clientDisplayName" : "clientDisplayNameDisabled";

        return <LandingPageComponent Padding='0px' {...this.props}>
            <div className="assuranceTopSection1">
                <span className="title">Home Page</span>

                <div className="childDiv">
                    {this.state.displayProductUpdatesBadge &&
                        <div className="productUpdatesDiv">
                            <Badge style={{ padding: "4px", fontSize: "13px", color: "white", borderRadius: "20px" }} onClick={this.openProductUpdatesModal}>Product Updates</Badge>
                        </div>
                    }

                    <ModelProdectUpdates isOpen={this.state.showProductUpdatesModal} showDefaultClose={true} onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ showProductUpdatesModal: !this.state.showProductUpdatesModal })}>
                        <ModelBodyProdectUpdates >
                            <InnerComponentWrapper>
                                <ModelTop> Product Updates </ModelTop>
                                <div className="popupScrollingList" style={{ marginBottom: "2%", marginTop: "2%", paddingLeft: "5px" }}>
                                    <table className="productUpdatesTable">
                                        <tbody>{this.modalData()}</tbody>
                                    </table>
                                </div>
                                <ModelBottom> &copy; 2024 Optum, Inc. or its affiliates. All rights reserved. </ModelBottom>
                            </InnerComponentWrapper>
                        </ModelBodyProdectUpdates>
                    </ModelProdectUpdates>

                    <div className={displayClass}>
                        <a onClick={this.openClientsModal}>
                            <b>Client: </b>{this.state.clientName}
                        </a>
                    </div>
                    <Modal show={this.state.showClientsModal} onHide={this.closeModal}>
                        <ModalTitle>
                            <ModelCaption>
                                <ModelCaptionText>{'Client Search Options'}</ModelCaptionText>
                                {<LayoutControl
                                    buttonType="close"
                                    domID="close-id"
                                    onClick={(e: React.ChangeEvent<HTMLInputElement>) => this.onClickClose(e)}
                                    tooltipPosition="top-center"
                                    hideTooltip='false'
                                />}
                            </ModelCaption>
                        </ModalTitle>
                        <ModalBody>
                            <div className="clientLabel">Client ID / Client Name</div>
                            <Input
                                className="clientsearch-input"
                                domID="clientSearchInput"
                                size="small"
                                label=""
                                placeholder="Filter by Client ID or Client Name"
                                value={this.state.clientSearchText}
                                onChange={this.onClientSearchInputChange} />
                            <div className="clientSearchList">
                                <table className="clientListTable">
                                    <tbody>{this.buildClientList()}</tbody>
                                </table>
                            </div>
                        </ModalBody>
                    </Modal>
                </div>
            </div>

            <AssuranceTopSection2>
                <ARMQuickSearch onMessageAlert={(message: string, type: string) => this.setMessageAlerts(message, type)} />
            </AssuranceTopSection2>
            <MessageAlerts errorData={this.state.messageErrorAlerts} warningData={this.state.messageWarningAlerts}></MessageAlerts>
        </LandingPageComponent>;
    }
};
