import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IJobQueueUIState {
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// Job Type
export interface ISelectedJobType {
    index: number;
    value: string;
    text: string;
};
export const defaultJobType: ISelectedJobType = {
    index: 0,
    value: '',
    text: '',
}
// State
export interface ISelectedJobstate {
    index: number;
    value: string;
    text: string;
};
export const defaultJobState: ISelectedJobstate = {
    index: 0,
    value: '',
    text: '',
}
// Client ID
export interface ISelectedClientId {
    index: number;
    value: string;
    text: string;
};
export const defaultClientId: ISelectedClientId = {
    index: 0,
    value: '',
    text: '',
}
// Server
export interface ISelectedServer {
    index: number;
    value: string;
    text: string;
};
export const defaultServer: ISelectedServer = {
    index: 0,
    value: '',
    text: '',
}

export interface IJobQueueJobType extends ICrudActionData<MCJQJob, ISelectedJobType> { }
export interface IJobQueueJobState extends ICrudActionData<MCJQJob, ISelectedJobstate> { }
export interface IJobQueueClient extends ICrudActionData<MCJQJob, ISelectedClientId> { }
export interface IJobQueueServer extends ICrudActionData<MCJQJob, ISelectedServer> { }

export const actionCreators = {
    FilterJobQueueByJobType: (jobType: IJobQueueJobType) => createDataAction('FILTER_BY_JOBTYPE', jobType),
    FilterJobQueueByJobState: (jobState: IJobQueueJobState) => createDataAction('FILTER_BY_STATE', jobState),
    FilterJobQueueByCID: (clientId: IJobQueueClient) => createDataAction('FILTER_BY_CID', clientId),
    FilterJobQueueByServer: (server: IJobQueueServer) => createDataAction('FILTER_BY_SERVER', server),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IJobQueueUIState = {
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IJobQueueUIState, KnownActions> = (state: IJobQueueUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'FILTER_BY_JOBTYPE':
                return {
                    ...state,
                }
            case 'FILTER_BY_STATE':
                return {
                    ...state,
                }
            case 'FILTER_BY_CID':
                return {
                    ...state,
                }
            case 'FILTER_BY_SERVER':
                return {
                    ...state,
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                return state;
        }
    }

    return state || defaultState;
}

