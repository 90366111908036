import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as RemoveRemitsUI from './ui/RemoveRemitsUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IRemoveRemitsState = ICrudComponentState<MCManageRemitMaintenance, RemoveRemitsUI.IRemoveRemitsUIState>;

export const actionCreators = createCrudComponentActions<RemoveRemitsUI.ActionCreators>(RemoveRemitsUI.actionCreators);
export type IRemoveRemitsActions = typeof actionCreators;
export type IRemoveRemitsActionProps = ICrudComponentActionProps<IRemoveRemitsActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiRemoveRemits, RemoveRemitsUI.reducer);
export const reducer = combineReducers<IRemoveRemitsState>(reducers);

export const validationCallback: ValidationCallback<MCManageRemitMaintenance> = (crud: any): crud is MCManageRemitMaintenance => {
    let testCrud: MCManageRemitMaintenance = crud as MCManageRemitMaintenance;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCManageRemitMaintenance");
    return validShape;
}
