import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';
import { IImageButtonImage, ImageButton } from '../../common/ImageButton';

import ArrowLeftUp from '../../../content/images/Buttons/But_ArrowLeft_creamUp.png';
import ArrowLeftDown from '../../../content/images/Buttons/But_ArrowLeft_creamDown.png';
import ArrowLeftOver from '../../../content/images/Buttons/But_ArrowLeft_creamOver.png';
import ArrowLeftOff from '../../../content/images/Buttons/But_ArrowLeft_creamOff.png';

import ArrowRightUp from '../../../content/images/Buttons/But_ArrowRight_creamUp.png';
import ArrowRightDown from '../../../content/images/Buttons/But_ArrowRight_creamDown.png';
import ArrowRightOver from '../../../content/images/Buttons/But_ArrowRight_creamOver.png';
import ArrowRightOff from '../../../content/images/Buttons/But_ArrowRight_creamOff.png';

const PagerStyle = styled.div`

    font-size: 13px;

    .pagerSpacer{
        display: inline-block;
        width: 5px;
    }

    .inlineDiv{
        display:'inline-block';
    }

    .previousDisabled{
        display: inline-block;
        color: grey;
        cursor: auto;
    }

    .previousEnabled{
        display: inline-block;
        color: black;
        cursor: pointer;
    }

    .nextDisabled{
        display: inline-block;
        color: grey;
        cursor: 'auto';
    }

    .nextEnabled{
        display: inline-block;
        color: black;
        cursor: pointer;
    }

    .currentPage{
        display: inline-block;
        width: 15px; 
        height: 15px;
        text-align: center;
        font-weight: bold;
    }

    .notCurrentPage{
        display: inline-block;
        width: 15px;
        height: 15px; 
        text-align: center;
        cursor: pointer;
    }
`;

export interface IARMGridPagerProps {
    pageCount: number,
    currentPageNumber: number,
    pagingHandler: object,
    message?: any,
  
}

interface IComponentState {
    backClicked: boolean,
    forwardClicked: boolean
}

export const DEFAULT_STATE: IComponentState = {
    backClicked: false,
    forwardClicked: false 
};

export class ARMGridPager extends React.Component<IARMGridPagerProps | any, IComponentState>
{
    constructor(props: IARMGridPagerProps) {
        super(props);
    }

    static propTypes: {};

    static arrowLeftIcon: IImageButtonImage = {
        Off: ArrowLeftOff,
        Over: ArrowLeftOver,
        Up: ArrowLeftUp,
        Down: ArrowLeftDown,
    }

    static arrowRightIcon: IImageButtonImage = {
        Off: ArrowRightOff,
        Over: ArrowRightOver,
        Up: ArrowRightUp,
        Down: ArrowRightDown,
    }

    public getArrowLeftIcon() {
        const {
            currentPageNumber,
        } = this.props;

        const previousDisabled = currentPageNumber === 1;
        const previousPageNumber = currentPageNumber - 1;

        return (
            <ImageButton
                Title='leftArrow'
                tooltip={'Previous'}
                altText={'Previous'}
                image={ARMGridPager.arrowLeftIcon}
                className="arrow-button"
                disabled={previousDisabled}
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                        this.setPage(previousPageNumber, 'back');
                }}
            />
        );
    }

    public getArrowRightIcon() {
        const {
            currentPageNumber,
            numberOfPages
        } = this.props;

        const nextPageNumber = currentPageNumber + 1;
        const nextDisabled = numberOfPages === currentPageNumber;

        return (
            <ImageButton
                Title='rightArrow'
                tooltip={'Next'}
                altText={'Next'}
                image={ARMGridPager.arrowRightIcon}
                className="arrow-button"
                disabled={nextDisabled}
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                        this.setPage(nextPageNumber, 'forward');
                }}
            />
        );
    }

    setPage(pageNumber: number, buttonClicked?: string) {
        if (pageNumber === 0) return;
        this.props.pagingHandler(pageNumber);
        this.setState({ backClicked: buttonClicked === 'back' ? true : false, forwardClicked: buttonClicked === 'forward' ? true : false })
    }

    render() {
        const {
            currentPageNumber,
            numberOfPages,
            message
        } = this.props;

        let arrowLeftIcon = this.getArrowLeftIcon();
        let arrowRightIcon = this.getArrowRightIcon();

        let useRightAlign: boolean = message?.length > 0 || typeof(message) === 'object';
        const rightAlign: React.CSSProperties = {
            display: 'inline',
            position: 'absolute',
            right: '0'
        };

        return (
            <PagerStyle>
                <div style={{display:'inline'}}>
                    {message}
                </div>
                <div style={ useRightAlign === true ? rightAlign: undefined  }>
                    <div style={{ display:'inline-block', position: 'relative', left: 10 }}>
                        {arrowLeftIcon}
                    </div>
                    <div style={{ display: 'inline-block', position: 'relative', top: -5 }}>
                        {currentPageNumber}/{numberOfPages}
                    </div>
                    <div style={{ display: 'inline-block', position: 'relative', left: -10 }}>
                        {arrowRightIcon}
                    </div>
                </div>
            </PagerStyle>
        );
    }
}

ARMGridPager.propTypes = {
    pageCount: PropTypes.number,
    currentPageNumber: PropTypes.number,
    pagingHandler: PropTypes.func
}
