import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IBulkImportExportUIState {
    ExportPhysician?: boolean;
    ExportFacility?: boolean;
    ExportPhysicianAE?: boolean;
    ExportPayerAlias?: boolean;
    Delimiter?: Number;
    HeaderRow?: Number;
    LastNameFrom?: string;
    LastNameTo?: string;
    FirstNameFrom?: string;
    FirstNameTo?: string;
    TaxIDFrom?: string;
    TaxIDTo?: string;
    KeyNoFrom?: string;
    KeyNoTo?: string;
    PhysicianNoFrom?: string;
    PhysicianNoTo?: string;
    LOBFrom?: string;
    LOBTo?: string;
    Import?: Number;
    PhysicianFileName?: string;
    PhysicianAEFileName?: string;
    PayerAliasFileName?: string;
    FacilityFileName?: string;
}

export interface IBulkImportExportUIData {
    value: boolean;
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
interface ISelectBulkImportExportData {
    value: string;
};

export const defaultBulkImportExport: ISelectBulkImportExportData = {
    value: '',
};

interface IInputBulkImportExportData {
    value: string;
};

export const defaultInputBulkImportExport: IInputBulkImportExportData = {
    value: '',
};

interface ICheckBulkImportExportData {
    value: boolean;
};

export const defaultCheckBulkImportExport: ICheckBulkImportExportData = {
    value: false,
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IBulkImportExportUIState = {
    ExportPhysician: false,
    ExportFacility: false,
    ExportPhysicianAE: false,
    ExportPayerAlias: false,
    Delimiter: 0,
    HeaderRow: 0,
    LastNameFrom: "",
    LastNameTo: "",
    FirstNameFrom: "",
    FirstNameTo: "",
    TaxIDFrom: "",
    TaxIDTo: "",
    KeyNoFrom: "",
    KeyNoTo: "",
    PhysicianNoFrom: "",
    PhysicianNoTo: "",
    LOBFrom: "",
    LOBTo: "",
    Import: 0,
    PhysicianFileName: "",
    PhysicianAEFileName: "",
    PayerAliasFileName: "",
    FacilityFileName: ""
};

export interface ISelectBulkImportExport extends IApiActionData<BulkImportExportType, ISelectBulkImportExportData> { }
export interface IInputBulkImportExport extends IApiActionData<BulkImportExportType, IInputBulkImportExportData> { }
export interface ICheckBulkImportExport extends IApiActionData<BulkImportExportType, ICheckBulkImportExportData> { }

export interface ISO_FieldData extends IApiActionData<APICF_ListPopulationContainer, IBulkImportExportUIData> { }

export const actionCreators = {
    checkExportFacility: (selectInfo: ICheckBulkImportExport) => createDataAction('CHECK_EXPORT_FACILITY', selectInfo),
    checkExportPhysician: (selectInfo: ICheckBulkImportExport) => createDataAction('CHECK_EXPORT_PHYSICIAN', selectInfo),
    checkExportPhysicianAutoEntry: (selectInfo: ICheckBulkImportExport) => createDataAction('CHECK_EXPORT_PHYSICIAN_AUTO_ENTRY', selectInfo),
    checkExportPayerAlias: (selectInfo: ICheckBulkImportExport) => createDataAction('CHECK_EXPORT_PAYER_ALIAS', selectInfo),
    selectFieldDelimiter: (selectInfo: ISelectBulkImportExport) => createDataAction('SELECT_FIELD_DELIMITER', selectInfo),
    checkHeaderRow: (selectInfo: ICheckBulkImportExport) => createDataAction('CHECK_HEADER_ROW', selectInfo),
    changeFirstNameFrom: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_FIRST_NAME_FROM', selectInfo),
    changeFirstNameTo: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_FIRST_NAME_TO', selectInfo),
    changeLastNameFrom: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_LAST_NAME_FROM', selectInfo),
    changeLastNameTo: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_LAST_NAME_TO', selectInfo),
    changeTaxIDFrom: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_TAX_ID_FROM', selectInfo),
    changeTaxIDTo: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_TAX_ID_TO', selectInfo),
    changeKeyNoFrom: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_KEY_NO_FROM', selectInfo),
    changeKeyNoTo: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_KEY_NO_TO', selectInfo),
    changePhysicianNoFrom: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_PHYSICIAN_NO_FROM', selectInfo),
    changePhysicianNoTo: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_PHYSICIAN_NO_TO', selectInfo),
    changeLOBFrom: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_LOB_FROM', selectInfo),
    changeLOBTo: (selectInfo: IInputBulkImportExport) => createDataAction('CHANGE_LOB_TO', selectInfo),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IBulkImportExportUIState, KnownActions> = (state: IBulkImportExportUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_FIELD_DELIMITER':
                let newStateDataFieldDelimiter: IBulkImportExportUIState = { ...state };
                newStateDataFieldDelimiter.Delimiter = action.data.uiData.value !== undefined && action.data.uiData.value !== "" ? parseInt(action.data.uiData.value) : 0;
                return newStateDataFieldDelimiter;
            case 'CHECK_HEADER_ROW':
                let newStateDataHeaderRow: IBulkImportExportUIState = { ...state };
                newStateDataHeaderRow.HeaderRow = action.data.uiData.value !== undefined ? action.data.uiData.value ? 1 : 0 : 0;
                return newStateDataHeaderRow;
            case 'CHECK_EXPORT_FACILITY':
                let newStateDataExportFacility: IBulkImportExportUIState = { ...state };
                newStateDataExportFacility.ExportFacility = action.data.uiData.value;
                return newStateDataExportFacility;
            case 'CHECK_EXPORT_PHYSICIAN':
                let newStateDataExportPhysician: IBulkImportExportUIState = { ...state };
                newStateDataExportPhysician.ExportPhysician = action.data.uiData.value;
                return newStateDataExportPhysician;
            case 'CHECK_EXPORT_PHYSICIAN_AUTO_ENTRY':
                let newStateDataExportPhysicianAE: IBulkImportExportUIState = { ...state };
                newStateDataExportPhysicianAE.ExportPhysicianAE = action.data.uiData.value;
                return newStateDataExportPhysicianAE;
            case 'CHECK_EXPORT_PAYER_ALIAS':
                let newStateDataExportPayerAlias: IBulkImportExportUIState = { ...state };
                newStateDataExportPayerAlias.ExportPayerAlias = action.data.uiData.value;
                return newStateDataExportPayerAlias;
            case 'CHANGE_LAST_NAME_FROM':
                let newStateDataLastNameFrom: IBulkImportExportUIState = { ...state };
                newStateDataLastNameFrom.LastNameFrom = action.data.uiData.value;
                return newStateDataLastNameFrom;
            case 'CHANGE_LAST_NAME_TO':
                let newStateDataLastNameTo: IBulkImportExportUIState = { ...state };
                newStateDataLastNameTo.LastNameTo = action.data.uiData.value;
                return newStateDataLastNameTo;
            case 'CHANGE_FIRST_NAME_FROM':
                let newStateDataFirstNameFrom: IBulkImportExportUIState = { ...state };
                newStateDataFirstNameFrom.FirstNameFrom = action.data.uiData.value;
                return newStateDataFirstNameFrom;
            case 'CHANGE_FIRST_NAME_TO':
                let newStateDataFirstNameTo: IBulkImportExportUIState = { ...state };
                newStateDataFirstNameTo.FirstNameTo = action.data.uiData.value;
                return newStateDataFirstNameTo;
            case 'CHANGE_TAX_ID_FROM':
                let newStateDataTaxIDFrom: IBulkImportExportUIState = { ...state };
                newStateDataTaxIDFrom.TaxIDFrom = action.data.uiData.value;
                return newStateDataTaxIDFrom;
            case 'CHANGE_TAX_ID_TO':
                let newStateDataTaxIDTo: IBulkImportExportUIState = { ...state };
                newStateDataTaxIDTo.TaxIDTo = action.data.uiData.value;
                return newStateDataTaxIDTo;
            case 'CHANGE_KEY_NO_FROM':
                let newStateDataKeyNoFrom: IBulkImportExportUIState = { ...state };
                newStateDataKeyNoFrom.KeyNoFrom = action.data.uiData.value;
                return newStateDataKeyNoFrom;
            case 'CHANGE_KEY_NO_TO':
                let newStateDataKeyNoTo: IBulkImportExportUIState = { ...state };
                newStateDataKeyNoTo.KeyNoTo = action.data.uiData.value;
                return newStateDataKeyNoTo;
            case 'CHANGE_PHYSICIAN_NO_FROM':
                let newStateDataPhysicianNoFrom: IBulkImportExportUIState = { ...state };
                newStateDataPhysicianNoFrom.PhysicianNoFrom = action.data.uiData.value;
                return newStateDataPhysicianNoFrom;
            case 'CHANGE_PHYSICIAN_NO_TO':
                let newStateDataPhysicianNoTo: IBulkImportExportUIState = { ...state };
                newStateDataPhysicianNoTo.PhysicianNoTo = action.data.uiData.value;
                return newStateDataPhysicianNoTo;
            case 'CHANGE_LOB_FROM':
                let newStateDataLOBFrom: IBulkImportExportUIState = { ...state };
                newStateDataLOBFrom.LOBFrom = action.data.uiData.value;
                return newStateDataLOBFrom;
            case 'CHANGE_LOB_TO':
                let newStateDataLOBTo: IBulkImportExportUIState = { ...state };
                newStateDataLOBTo.LOBTo = action.data.uiData.value;
                return newStateDataLOBTo;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}

