import { SelectOption, AvailableSelectedMap } from './Shared';
import { forEach } from "lodash";
import { MCCPPayerInfo, MCEligibilityPayerInfo, MCEligibilityPayerMaintenanceInfo } from '@scripts/@types/MasterCrud/EligibilityProfile';

export class AvailableSelectedHelper {

    getAvailablePayers(
        maintenanceInfo: MCEligibilityPayerMaintenanceInfo,
        payerNameFilter: string,
        availableSelectedMap: AvailableSelectedMap): SelectOption[] {

        // TODO - will need to figure out best available/selected implementation
        // need to consider:
        // 1 -filtered row state

        /*
        // init vars
        const clientPayers = maintenanceInfo.ClientPayers.Payer;
        const clientPayersEligEnabled = clientPayers?.filter(x => x['@EligEnabled'] === 'Y');
        const clientPayersNotEligEnabled = clientPayers?.filter(x => x['@EligEnabled'] === 'N');

        // build standard available payers list
        let options: SelectOption[] = [];
        let optionIsRemoved = false;
        let text: string;

        // include the options that the user has added via ui
        availableSelectedMap.addedIds.forEach(x => {
            text = clientPayersEligEnabled.find(y => y["@PayerID"] === x)?.["@DisplayName"] || '';
            options.push({ value: x, text });
        })

        // remove the options that the user has removed via ui
        clientPayersNotEligEnabled?.forEach(x => {
            optionIsRemoved = availableSelectedMap.removedIds.includes(x['@PayerID']);
            if (!optionIsRemoved) options.push({ value: x['@PayerID'], text: x['@DisplayName'] });
        });

        // filter the options based on user filter input
        let options = options.filter(x => x.text.toLowerCase().includes(availablePayersFilter.toLowerCase()));

        // return the sorted options
        return options.sort((a, b) => {
            return a.text.localeCompare(b.text.toString())
        })
        */

        //return [];
        let prevClientPayers = maintenanceInfo.ClientPayers.Payer;

        //@ts-ignore
        const clientPayers = prevClientPayers.map(item => {
            let container = {
                ...item,
                "concaKey": ""
            }
        
            container.concaKey = item["@RTECPID"] + item["@PayerID"];
        
            return container;
        })
        

        const clientPayersEligEnabled = clientPayers?.filter(x => x['@EligEnabled'] === 'N');
        
        let options: SelectOption[] = [];
        clientPayersEligEnabled.filter(x =>
            x["@DisplayName"].toUpperCase().includes(payerNameFilter.toUpperCase())).forEach(x => {
               
                let enrollmentReq = maintenanceInfo.EligibilityPayerProfiles.PayerProfile.find((profile: any) => profile["@RTECPID"] === x["@RTECPID"]);
                let showEnrollReqd = '';
                let showEnrollReqFlag
                if (enrollmentReq) {
                    showEnrollReqd = enrollmentReq["@EnrollmentRequired"] === "Y" ? '*' : '';
                    showEnrollReqFlag = enrollmentReq["@EnrollmentRequired"] === "Y"
                }
                //@ts-ignore
                options.push({ text: `${x["@DisplayName"]} (${x["@RTECPID"]})${showEnrollReqd}`, value: x["concaKey"], flagEnroll: showEnrollReqFlag })
            });

        //console.log('options', options);

        return options;

        //

        /* old asp logic */
        /* 
        let payerId = '';
        let RTECPID = '';
        let value = '';
        let text = '';
        let displayName = '';
        let enrollmentRequired = false;
        let enrollmentRequiredIndicator = '';
        let options: SelectOption[] = [];
        clientPayersNotEligEnabled?.forEach(x => {
            RTECPID = x['@RTECPID'];
            enrollmentRequired = !(eligibilityPayerProfiles?.find(x => x['@RTECPID'] === RTECPID)?.['@EnrollmentRequired'] === 'N');
            enrollmentRequiredIndicator = enrollmentRequired ? '*' : '';
            payerId = x['@PayerID'];
            value = `${payerId} ^ ${RTECPID}`;
            displayName = x['@DisplayName'];
            text = `${displayName} (${RTECPID})${enrollmentRequiredIndicator}`;
            options.push({ value, text });
        });
        return this.filterAvailablePayers(options, availablePayersFilter);
        */
    }

    getEnabledPayers(
        maintenanceInfo: MCEligibilityPayerMaintenanceInfo,
        availableSelectedMap: AvailableSelectedMap): MCCPPayerInfo[] {

        // get all payers from maint info
        let prevClientPayers = maintenanceInfo.ClientPayers.Payer[0];

        //@ts-ignore
        const clientPayers = prevClientPayers.map(item => {
            let container = {
                ...item,
                "concaKey": ""
            }

            container.concaKey = item["@RTECPID"] + item["@PayerID"];

            return container;
        })

        // add css-stylable columns to result set
        this.configureStylableColumnNames(clientPayers);

        // basic rowset filter for available/selected (enabled Y/N)
        const clientPayersNotEligEnabled = clientPayers?.filter(x => x['@EligEnabled'] === 'N');
        let clientPayersEligEnabled = clientPayers?.filter(x => x['@EligEnabled'] === 'Y');

        this.addSelectedAvailableRows(clientPayersNotEligEnabled, clientPayersEligEnabled, availableSelectedMap)
        this.removeSelectedEnabledRows(clientPayersEligEnabled, availableSelectedMap);
        clientPayersEligEnabled.forEach(payer => {
            let enrollmentReq = maintenanceInfo.EligibilityPayerProfiles.PayerProfile.find((profile: any) => profile["@RTECPID"] === payer["@RTECPID"]);
            let showEnrollReqd = '';
            if (enrollmentReq) {
                showEnrollReqd = enrollmentReq["@EnrollmentRequired"] === "Y" ? '*' : '';
            }
            payer["FullName"] = `${payer["@DisplayName"]} (${payer["@RTECPID"]})${showEnrollReqd}`;
        });

  
        return clientPayersEligEnabled;
    }

    getNotEnabledPayers(
        maintenanceInfo: MCEligibilityPayerMaintenanceInfo,
        availableSelectedMap: AvailableSelectedMap): MCCPPayerInfo[] {
        
        let prevClientPayers = maintenanceInfo.ClientPayers.Payer;

        // get all payers from maintenance info
        //@ts-ignore
        const clientPayers = prevClientPayers.map(item => {
            let container = {
                ...item,
                "concaKey": ""
            }

            container.concaKey = item["@RTECPID"] + item["@PayerID"];

            return container;
        })

        // add css-stylable columns to result set
        this.configureStylableColumnNames(clientPayers);

        // basic rowset filter for available/selected (enabled Y/N)
        const clientPayersNotEligEnabled = clientPayers?.filter(x => x['@EligEnabled'] === 'N');
        let clientPayersEligEnabled = clientPayers?.filter(x => x['@EligEnabled'] === 'Y');

        this.addSelectedAvailableRows(clientPayersNotEligEnabled, clientPayersEligEnabled, availableSelectedMap)
        this.removeSelectedEnabledRows(clientPayersEligEnabled, availableSelectedMap);

        return clientPayersNotEligEnabled;
    }


    /**
     * 
     * @param maintenanceInfo
     * @param availableSelectedmap
     * 
     *  NOTE: Since the EligEnabled flag is used to cull the Client Payers List into two groups
     *  (Available Payers & Enabled Payers), the unfiltered list contains the items we need to derive
     *  the Additional Payers list
     */

    getAdditionalPayers(
        maintenanceInfo: MCEligibilityPayerMaintenanceInfo,
        availableSelectedmap: AvailableSelectedMap): MCEligibilityPayerInfo[] {
        const eligibilityPayers = maintenanceInfo.EligibilityPayers.Payer; // Contains the exhaustive list of all payers (1200)

        let prevClientPayers = maintenanceInfo.ClientPayers.Payer;

        // get all payers from maintenance info
        //@ts-ignore
        const clientPayers = prevClientPayers.map(item => {
            let container = {
                ...item,
                "concaKey": ""
            }

            container.concaKey = item["@RTECPID"] + item["@PayerID"];

            return container;
        })

        // We filter the Eligibility Payers based on our Client PayerID and RTECPID
        const additionalPayers = eligibilityPayers.filter((ele) => !(!!clientPayers.find((val) => val["@PayerID"] === ele["@ID"] && val["@RTECPID"] === ele["@RTECPID"])));
        //console.log("The additional Payers are: ", additionalPayers, additionalPayers.length);
        // console.log("Eligibility Payers are: ", eligibilityPayers);       console.log("this.state", this.state)
        // console.log("The clientPayers are: ", clientPayers);
       
        return additionalPayers;
    }


    addSelectedAvailableRows(
        clientPayersNotEligEnabled: MCCPPayerInfo[],
        clientPayersEligEnabled: MCCPPayerInfo[],
        availableSelectedMap: AvailableSelectedMap) {

        // add the rows which user has added via ui
        let availableRow: MCCPPayerInfo | undefined;
        availableSelectedMap.addedIds.forEach(x => {
            availableRow = clientPayersNotEligEnabled.find(y => y["concaKey"] == x);
            if (availableRow) clientPayersEligEnabled.push(availableRow);
        });

        // sort results by display name and return
        return clientPayersEligEnabled.sort((a, b) => {
            return a["@DisplayName"].localeCompare(b["@DisplayName"].toString())
        })
    }

    removeSelectedEnabledRows(
        clientPayersEligEnabled: MCCPPayerInfo[],
        availableSelectedMap: AvailableSelectedMap) {

        // remove the rows which user has removed via ui
        const selectedEnabledRowsRemoved = clientPayersEligEnabled.filter(x => !availableSelectedMap.removedIds.includes(x["@RTECPID"] + x["@PayerID"]));

        // sort results by display name and return
        return selectedEnabledRowsRemoved.sort((a, b) => {
            return a["@DisplayName"].localeCompare(b["@DisplayName"].toString())
        })
    }

    removeUnselectedRows() {

    }

    // create duplicate fields (replace '@' with '_') to support dependency for uicl grid column width css
    configureStylableColumnNames(payers: MCCPPayerInfo[]) {
        payers.forEach(x => {
            x['@DisplayName'] = x['@DisplayName'];
            x['@Active'] = x['@Active'];
            x['@Batch'] = x['@Batch'];
            x['@Bridge'] = x['@Bridge'];
            x['@CFI'] = x['@CFI'];
            x['@Manual'] = x['@Manual'];
            x['@Direct'] = x['@Direct'];
        })
    }
}

