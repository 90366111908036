import * as React from 'react';
import styled from 'styled-components';
import { isEqual, compact, uniqueId } from 'lodash';
//import { InputLabel } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';


export interface IOptionComponentProps {
    title?: string;
    value: string;
    text: string;
    deleted?: string;
    selected?: string;
    disabled?: string;
    fav?: string;
    red?: string;
};

export interface ISelectComponentProps {
    title: string;
    records: Array<any>;
    optionFields: IOptionComponentProps;
    size?: number;
    width?: string;
    height?: string;
    disabled?: boolean;
    onSelect?: React.ChangeEventHandler<HTMLSelectElement>;
    onDoubleClick?: any;
    className?: string;
    selectedValue?: string;
    selectedMultiValue?: string[];
    autoScrollToSelection: boolean;
    label?: string;
    multiple?: string;
    tooltip?: boolean;
    style?: any;
};

export interface ISelectComponentState {
    disabled: boolean;
    needsScroll: boolean;
};

export interface ISelectWrapperProps {
    widthValue?: string;
    heightValue?: string;
    onDoubleClick?: any;
};

export interface ISelectWrapperDivProps {
    className?: string;
};

const SelectWrapperDiv = styled.div`
    flex: 1 1 auto;
    ${(props: ISelectWrapperDivProps) => props.className && `className: ${props.className}`};
`;

export const SelectWrapper = styled.select`
    ${(props: ISelectWrapperProps) => props.widthValue && `width: ${props.widthValue}`};
    ${(props: ISelectWrapperProps) => props.heightValue && `height: ${props.heightValue}`};

    overflow-y: auto;
    overflow-x: auto;
    position: relative;

    .option-class{
          color: ${Colors.grey100};
          ${Typography.ARMFontFamily};
          ${Typography.large};
          ${Typography.defaultLineHeight};
          

          margin: 0.1rem;
          display: table-row;
    }
    .option-fav {
        color: blue !important;
    }
    
    .option-red {
        color: ${Colors.digitalRed150} !important;
    }
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 0.3rem;
  color: ${Colors.grey100};
  ${Typography.ARMFontFamily};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  ${Typography.bold};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export class SelectComponent extends React.Component<ISelectComponentProps, ISelectComponentState>
{
    private selectElement: React.RefObject<HTMLSelectElement>;
    private selectOptions: HTMLOptionElement[] = [];
    static defaultProps: ISelectComponentProps = {
        autoScrollToSelection: false,
        title: "SelectComponent",
        records: [],
        optionFields: {
            value: "value",
            text: "text",
            selected: "selected",
            disabled: undefined,
            title: undefined,
            fav: undefined,
            red: undefined
        },
        size: 10,
        disabled: false,
        tooltip: false,
        label: '',
        style: {},
    };

    constructor(props: ISelectComponentProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
            needsScroll: false,
        }
        this.selectElement = React.createRef();
    }

    componentDidMount() {
        if (!this.props.autoScrollToSelection) return;
        let needsScroll = false;

        if (this.isMultiple()) {
            needsScroll = !!this.props.selectedMultiValue && !!this.props.selectedMultiValue.length;
        } else {
            needsScroll = !!this.props.selectedValue;
        }
        // component has not rendered yet, schedule update
        if (needsScroll) this.setState({needsScroll: true});
    }

    getSnapshotBeforeUpdate(prevProps: ISelectComponentProps): string[] {
        //with this many devs, we can't assume no one is mutating this array somewhere, clone it
        return [...(prevProps.selectedMultiValue || [])];
    }

    componentDidUpdate(prevProps: ISelectComponentProps, prevState:ISelectComponentState, snapshot: string[]) {
        if (!this.props.autoScrollToSelection) return;
        // if selected options were changed, scroll top one into view
        let needsScroll = this.state.needsScroll;
        if (this.isMultiple()) {
            if (!isEqual(this.props.selectedMultiValue, snapshot)) {
                needsScroll = true;
            }
        } else {
            if (!isEqual(this.props.selectedValue, prevProps.selectedValue)) {
                needsScroll = true;
            }
        };
        if (needsScroll) {
            this.scrollToFirstSelectedOption();
            this.setState({ needsScroll: false });
        }
        // if the number of records was reduced, React would fill the old indices with null, remove these
        this.selectOptions = compact(this.selectOptions);
        // react has a bug where if you set a value to null, it doesn't clear the selected state on the options.
        // react gives errors if you try to set selected on the options in jsx so 🤷‍ 🤐
        if (!this.isMultiple() && !this.props.selectedValue) {
            this.selectOptions.forEach((option) => option.selected = false);
        }
        if (this.isMultiple() && (!this.props.selectedMultiValue || !this.props.selectedMultiValue.length)) {
            this.selectOptions.forEach((option) => option.selected = false);
        }
    }

    isMultiple(): boolean {
        if (this.props.multiple && this.props.multiple.length > 0 && this.props.multiple !== "false")
            return true;
        return false;
    }

    getData(record: any, index: any) : boolean {
        if (index === undefined)
            return false;
        return record[index];
    }

    storeOptionElRef(ref: HTMLOptionElement, index: number) {
        this.selectOptions[index] = ref;
    }

    scrollToFirstSelectedOption() {
        if (!!this.selectElement.current) {
            const selectedOptionElements = this.selectOptions.filter((option: HTMLOptionElement) => option?.selected);
            const shouldScroll = !selectedOptionElements.some(element => {
                return this.isOptionVisibleInSelect(element, this.selectElement.current as HTMLSelectElement);
            });
            // console.log(this.selectOptions.map((option: HTMLOptionElement) => `${option?.innerHTML} ${option?.value} ${option?.offsetTop}`).join('\n'));
            if (shouldScroll) {
                const scrollPosition = selectedOptionElements[0]?.offsetTop ?? 0;
                /*  for some reason if you don't use behavior 'smooth' you don't visually 
                    see this change even though the pos changes numerically 
                */
                this.selectElement.current.scrollTo({ top: scrollPosition, left: 0, behavior: 'smooth' });
            }
        }
    }

    isOptionVisibleInSelect(option: HTMLOptionElement, select: HTMLSelectElement): boolean {
        // console.log('select', select.scrollTop, select.clientHeight, select.scrollTop + select.clientHeight);
        // console.log('option', option.offsetTop, option.clientHeight, option.offsetTop + option.clientHeight);
        // option above visible area 
        if (select.scrollTop >= option.offsetTop + option.clientHeight) return false;
        // option below visible area
        if (select.scrollTop + select.clientHeight < option.offsetTop + option.clientHeight) return false;
        // option inside visible area
        return true;
    }

    renderOptions() {
        
        const options = this.props.records.map((record, index) => {
            
            // TODO: Do we need to check for @Deleted here?
            if (!(this.props.optionFields.deleted && (record[this.props.optionFields.deleted] == "1")))
            {
                let isDisabled : boolean = false;
                if(record.disabled && record.disabled == 'true'){
                        isDisabled = true;
                }
                
                return <option
                    className={'option-class' +
                        (this.getData(record, this.props.optionFields.fav) == true ? ' option-fav' : '') +
                        (this.getData(record, this.props.optionFields.red) ? ' option-red' : '')
                    }
                    disabled={isDisabled}
                    onDoubleClick={(e: any) => { if (!isDisabled && this.props.onDoubleClick) { this.props.onDoubleClick(e) } }}
                    key={record[this.props.optionFields.value]}
                    ref={(option: HTMLOptionElement) => this.storeOptionElRef(option, index)}
                    title={this.props.tooltip === true ? record[this.props.optionFields.text] : ''} // add an option field for the title?
                    value={record[this.props.optionFields.value]}>{record[this.props.optionFields.text]}</option>
            }
        });
        return (
            <React.Fragment>
                {options}
            </React.Fragment>
            );
    }

    render() {
        return (
            <SelectWrapperDiv className={this.props.className}  >
                {this.props.label && this.props.title ? <InputLabel htmlFor={this.props.title}>{this.props.label}</InputLabel> : null}
                <SelectWrapper
                    style={this.props.style}
                    id={this.props.title}
                    name={this.props.title}
                    widthValue={this.props.width}
                    heightValue={this.props.height}
                    disabled={this.props.disabled}
                    {...(this.isMultiple() ? { multiple: true } : null)}
                    size={this.props.size}
                    {...(this.isMultiple() ? { value: this.props.selectedMultiValue } : { value: this.props.selectedValue })}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { if (this.props.onSelect) this.props.onSelect(e) }}
                    ref={this.selectElement}
                    onClick={e=>e.stopPropagation()}
                >
                    {this.renderOptions()}
                    </SelectWrapper>
                </SelectWrapperDiv>
        );
    }
};