import * as React from 'react';
import { bindAll } from 'lodash';
import { Button, SelectDropdownV2, Grid } from 'ui-core';
import styled from 'styled-components';
import { DialogFieldset, DialogLegend, DialogLegend3 } from '@common/DialogStyles';
import { Input } from '@common/UICLWrappers/ARMInput';
import { ContentColumnWrapper, ContentRowWrapper, CenteredContent, DialogInstruction} from '@common/DialogWrapper';
import { ICCIGeneralOptionsData, IGeneralUpdateRequest, ICCIGeneralOptionsLocalState } from '../../../store/ui/ClaimOptionsUI';


const CCIGeneralSelectionContent = styled.div`   
    .text-input input {
        text-transform: uppercase;
        width:342px;
    }

    .text-input-right input {
        text-transform: uppercase;
        width:330px;
    }
 
    .fieldLabel{
        text-transform: uppercase;
        font-weight: bold;
        text-align: right;
        white-space: nowrap;
    }

    #id-cci-gen-grid-dialogfieldset {
        margin-top: 30px;
     }
`;

const GridWrapper = styled.div`

    width:inherit;
    max-height:500px;
    overflow-y:auto;
    border: black 1px solid;  
    background-color: white;

    #grid-wrapper-co-cci-gen-codeset-suppression-grid {

        height:174px;

        .empty-row{
            background-color:white;
        }

        table {
            table-layout: fixed;
            width: 100%;
        }

       thead tr th{
            background-color:#9BA1A9;
            height: 20px !important;
            min-height: 20px;
            margin-top: 0px;
            margin-bottom: 0px;
            min-width: 400px;
            max-width: 400px;
        }

        table tbody tr > td{
            padding-left:0px;
            padding-right: 0px;
            height: 20px;
            overflow: hidden;
            white-space: nowrap;
        }

        table tbody tr > td > div {
            min-height:20px;
            height:20px;
        }

        table tbody tr:hover{
            cursor:pointer !important;
        }

        ._RevCodes, ._ProcCodes {
            min-width:425px;
            max-width:425px;
            width: 425px;
            padding-top:0px;
            padding-left:15px;
        }
        
        ._LOBType {
            min-width:130px;
            max-width:130px;
            width: 130px;
            padding-top:0px;
            padding-left:15px;            
        }
`;

const CodeSetSuppressionBottomSection = styled.div`
     margin-top: 5px;

     .text-input input {
        text-transform: uppercase;
        width:410px;
    }

    #co-cci-gen-lob-type-btm {
        width: 150px;
    }

`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface ICCIGeneralExclusionGridItem extends MCClaimOptionsCCIExclusion {
    _RevCodes: string;
    _ProcCodes: string;
    _LOBType: string;
};

interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    canDelete: boolean,

    generalOptions: ICCIGeneralOptionsData,
    updateCCIGeneralOptions(request: IGeneralUpdateRequest): void,
    displayAlertMessage(msg: string): void,
    localState?: ICCIGeneralOptionsLocalState,
    onLocalStateChange(request: ICCIGeneralOptionsLocalState): void,
};

interface IComponentState {
    selectedCCIExclusionId: string,
    revenueCodes: string,
    procedureCodes: string,
    lobType: string,
}

export const DEFAULT_STATE: IComponentState = {
    selectedCCIExclusionId: '',
    revenueCodes: '',
    procedureCodes: '',
    lobType:'0',
}

interface UiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

export interface FieldType {
    domId?: string;
    className: string;
    type: string;
    label: string;
    forceuppercase?: string;
    maxLength?: number;
    initialValue: any;
    hasError?: any;
    onBlur?: any;
    onChange?: any;
    value?: any;
    errorMessage?: string;
}

export class CCIGeneralSelectionOptions extends React.Component<IComponentProps, IComponentState> {

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
        bindAll(this);

        this.state = {
            selectedCCIExclusionId: (props.localState?.selectedCCIExclusionId ? props.localState.selectedCCIExclusionId : ''),
            revenueCodes: (props.localState?.revenueCodes ? props.localState.revenueCodes : ''),
            procedureCodes: (props.localState?.procedureCodes ? props.localState.procedureCodes : ''),
            lobType: (props.localState?.lobType ? props.localState.lobType : '0'),
        };
    }

    public componentDidMount() {
        // If user left the general options page while he had a row selected, and he comes back to that page, then highlight the previously selected row
        if (this.props.localState?.selectedCCIExclusionId && this.props.localState.selectedCCIExclusionId != '') {
            this.highlightSelectedRowByID(this.props.localState.selectedCCIExclusionId);
        }
    }

    public componentWillUnmount() {
    }

    private LOBTypeOptions =
        [{ label: 'All', value: '0' },
         { label: 'Medicaid', value: '1' },
         { label: 'Non-Medicaid', value: '2' }];

    private getLOBTypeOption(value: string | undefined) {
        let item = this.LOBTypeOptions.find(ob => ob['value'] === value);

        if (item) {
            return item;
        } else {
            return this.LOBTypeOptions[0];
        }
    }

    private getGridColumnHeaders() {
        return new Set([
            {
                dataName: "_RevCodes",
                text: 'Revenue Code(s)',
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "_ProcCodes",
                text: "Procedure Code(s)",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "_LOBType",
                text: "LOB Type",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
        ]);
    }

    public getGridData(): ICCIGeneralExclusionGridItem[] {
        let list = this?.props?.generalOptions?.exclusionList ? this.props.generalOptions.exclusionList : [];
        let newList: ICCIGeneralExclusionGridItem[] = [];
        list.forEach(item => {
            let newItem: ICCIGeneralExclusionGridItem = { ...item, _RevCodes: item['@RevCodes'], _ProcCodes: item['@ProcCodes'], _LOBType: this.getLOBTypeOption(item['@LOBType']).label };
            newList.push(newItem);
        })
        return newList;
    }

    public onFieldChange(value: string, fieldName: string) {
        const request: IGeneralUpdateRequest = { Action: "UPDATEFIELD", Name: fieldName, Value: value };
        this.props.updateCCIGeneralOptions(request);
    }

    public onSelectCCIExclusionRow(e: any, record: any) {
        if (record && record['@ID']) {
            let cciexclusion = this.props.generalOptions.exclusionList.find(item => item['@ID'] == record['@ID']);
            if (cciexclusion) {
                this.highlightSelectedRow(e.currentTarget);

                this.props.onLocalStateChange({
                    ...this.props.localState,
                    selectedCCIExclusionId: record['@ID'],
                    revenueCodes: cciexclusion['@RevCodes'],
                    procedureCodes: cciexclusion['@ProcCodes'],
                    lobType: cciexclusion['@LOBType'],
                });

                this.setState({
                    selectedCCIExclusionId: record['@ID'],
                    revenueCodes: cciexclusion['@RevCodes'],
                    procedureCodes: cciexclusion['@ProcCodes'],
                    lobType: cciexclusion['@LOBType'],
                });
            }
        }
    }

    highlightSelectedRowByID(rowId: string) {
        var cciGeneralOptionsGrid = document.getElementById("co-cci-gen-codeset-suppression-grid");
        if (cciGeneralOptionsGrid != null) {
            let query = "tr[data-testid='id-cci-gen-row-" + rowId + "']";
            var selectedRow = cciGeneralOptionsGrid.querySelector(query);
            if (selectedRow)
                selectedRow.classList.add('selected');
        }
    }

    private highlightSelectedRow(target: any) {
        if (target != null) {
            this.deselectAllRows();
            // set selected class to grid row
            target.className = 'selected';
        }
    }

    private deselectAllRows() {
        // clear selected row highlights from ui
        var cciPayerSelectionGrid = document.getElementById("co-cci-gen-codeset-suppression-grid");
        if (cciPayerSelectionGrid != null) {
            var selectedRows = cciPayerSelectionGrid.querySelectorAll('.selected');
            for (var i = 0; i < selectedRows.length; i++) {
                selectedRows[i].classList.remove('selected');
            }
        }
    }

    public onClickAddUpdateButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (!this.validateFields())
            return false;

        let action: "ADD" | "UPDATE" | "REMOVE" = "UPDATE";
        if (this.state.selectedCCIExclusionId == '')
            action = "ADD";

        let data: MCClaimOptionsCCIExclusion = {
            '@ID': this.state.selectedCCIExclusionId,
            '@RevCodes': this.state.revenueCodes,
            '@ProcCodes': this.state.procedureCodes,
            '@LOBType': this.state.lobType,
        }

        let request: IGeneralUpdateRequest = { Action: action, Value: data, Name: '' };
        this.props.updateCCIGeneralOptions(request);

        this.clearEntries();
    }

    private validateFields() {
        if (this.state.procedureCodes == '') {
            this.props.displayAlertMessage('Please enter a Procedure Code.');
            return false;
        }
        if (this.state.revenueCodes == '') {
            this.props.displayAlertMessage('Please enter a Revenue Code.');
            return false;
        }
        if (this.state.lobType != '0' && this.state.lobType != '1' && this.state.lobType != '2') {
            this.props.displayAlertMessage('Please select a Line of Business Type.');
            return false;
        }

        let existingItem = this.props.generalOptions.exclusionList.find(obj =>
            obj['@RevCodes'] == this.state.revenueCodes && obj['@ProcCodes'] == this.state.procedureCodes && obj['@LOBType'] == this.state.lobType);
        if (existingItem) {
            this.props.displayAlertMessage('Please enter different criteria as this criteria already exists.');
            return false;
        }
        return true;
    }

    public onClickClearButton() {
        this.clearEntries();
    }

    private clearEntries() {
        this.deselectAllRows();

        this.props.onLocalStateChange({
            ...this.props.localState,
            selectedCCIExclusionId: '',
            procedureCodes: '',
            revenueCodes: '',
            lobType: '0',
        });

        this.setState({
            selectedCCIExclusionId: '',
            procedureCodes: '',
            revenueCodes: '',
            lobType: '0',
        });
    }

    public onClickRemoveButton(e: React.ChangeEvent<HTMLButtonElement>) {
        this.deselectAllRows();

        let request: IGeneralUpdateRequest = { Action: "REMOVE", Value: this.state.selectedCCIExclusionId, Name:'' };
        this.props.updateCCIGeneralOptions(request);

        this.setState({
            selectedCCIExclusionId: '',
            procedureCodes: '',
            revenueCodes: '',
            lobType: '0',
        });
    }

    public renderFieldColumns(fieldData: FieldType[], title?: string, titleWidth?: string) {
        return (
            <div style={{ flex: '1 1 auto', marginLeft: '15px', marginRight: '15px'}}>
                { title && titleWidth ? <div style={{ width: titleWidth, background: '#9BA1A9' }}>
                    <p style={{
                        color: '#37474F',
                        fontSize: '13px',
                        lineHeight: '1.4em',
                        fontWeight: 600,
                        textAlign: 'center',
                        marginTop: '0px',
                        marginBottom: '5px'
                    }}>{title}</p>
                </div> : null}
                <table style={{ marginRight: '0px' }}>
                    <tbody>
                        {fieldData.map(field => {
                            if (field.type === 'text') {
                                return (<tr><td className='fieldLabel'>{field.label}</td><td style={{ width: 5 }}></td><td>
                                    <Input
                                        domID={field.domId}
                                        className={field.className}
                                        initialValue={field.initialValue}
                                        maxLength={field.maxLength}
                                        size="small"
                                        hasError={field.hasError}
                                        onBlur={field.onBlur}
                                        errorMessage={field.errorMessage} />
                                </td></tr>)
                            }
                        })}
                    </tbody>
                </table>
            </div>

        )
    }

    private instructions = <React.Fragment><span className='instructionRed'> Note! </span> The payer lists on the Medical Necessity and CCI tab may take a moment to load due to the extensive list of payers.</React.Fragment>;
    public render() {
        if (!this.props.canView)
            return <div></div>;

        let codeSetSuppressions: ICCIGeneralExclusionGridItem[] = this.getGridData();
        return (
            <CCIGeneralSelectionContent>
            <DialogFieldset width={'inherit'}>
                <DialogLegend3 helpUrl={'/Support/Help/HELP_Maint_ClaimOptionsCCIEdits.htm'}> Field Suppression </DialogLegend3>
                <ContentColumnWrapper>
                    <ContentRowWrapper style={{ justifyContent: 'space-between' }}>
                        {this.renderFieldColumns([
                            {
                                domId: 'co-cci-gen-bill-type', className: 'text-input', maxLength: 128,
                                label: 'Bill Type:', type: 'text', initialValue: this.props.generalOptions.billType,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) =>  {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.billType)
                                        this.onFieldChange(e.target.value, "@BillType");
                                }
                            },
                            {
                                domId: 'co-cci-gen-custom-mod', className: 'text-input', maxLength: 128,
                                label: 'Custom Modifier:', type: 'text', initialValue: this.props.generalOptions.customModifier,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.customModifier)
                                        this.onFieldChange(e.target.value, "@CustomModifier");
                                }
                            },
                            {
                                domId: 'co-cci-gen-finandial-class', className: 'text-input', maxLength: 128,
                                label: 'Financial Class:', type: 'text', initialValue: this.props.generalOptions.financialClass,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.financialClass)
                                        this.onFieldChange(e.target.value, "@FinancialClass");
                                }
                            }],
                            'NON-MEDICAID EXCEPTIONS', '458px')}
                        <ContentColumnWrapper>
                            {this.renderFieldColumns([{
                                domId: 'co-cci-gen-med-bill-type', className: 'text-input-right', maxLength: 128,
                                label: 'Bill Type (Medicaid):', type: 'text', initialValue: this.props.generalOptions.billTypeMed,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.billTypeMed)
                                        this.onFieldChange(e.target.value, "@BillTypeMedicaid");
                                }
                            },
                            {
                                domId: 'co-cci-gen-med-custom-mod', className: 'text-input-right', maxLength: 128,
                                label: 'Custom Modifier (Medicaid):', type: 'text', initialValue: this.props.generalOptions.customModifierMed,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.customModifierMed)
                                        this.onFieldChange(e.target.value, "@CustomModifierMedicaid");
                                },
                            },
                            {
                                domId: 'co-cci-gen-med-financical-class', className: 'text-input-right', maxLength: 128,
                                label: 'Financial Class (Medicaid):', type: 'text', initialValue: this.props.generalOptions.financialClassMed,
                                onBlur: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                    e.target.value = e.target.value.toUpperCase().trim();
                                    if (e.target.value != this.props.generalOptions.financialClassMed)
                                        this.onFieldChange(e.target.value, "@FinancialClassMedicaid");
                                },
                            }], 'MEDICAID EXCEPTIONS', '510px')}
                    
                        </ContentColumnWrapper>
                    </ContentRowWrapper>
                        <DialogFieldset id='id-cci-gen-grid-dialogfieldset'>
                        <DialogLegend>Code Set Suppression</DialogLegend>
                        <GridWrapper>
                            <Grid
                                records={codeSetSuppressions}
                                columns={this.getGridColumnHeaders()}
                                domID='co-cci-gen-codeset-suppression-grid'
                                dataTestId="id-cci-gen"
                                isFixedHeader={true}
                                maxHeight='400px'
                                size="small"
                                onClickThrough={(e: any, record: any) => { this.onSelectCCIExclusionRow(e, record) }}
                                emptyGridMessage=' '
                                // grid works without selectionKey
                                // but uicl grid would throw warning
                                selectionKey='@ID'
                            />
                        </GridWrapper>
                        <CodeSetSuppressionBottomSection>
                             <ContentColumnWrapper>
                                <ContentRowWrapper style={{ justifyContent: 'center', marginLeft: '15px' }}>
                                        <table><tbody><tr><td className='fieldLabel'>Revenue Code(s):</td><td>
                                        <Input
                                        domID='co-cci-gen-rev-code-btm'
                                        className='text-input'
                                        initialValue={this.state.revenueCodes}
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            let revCodes = e.target.value.toUpperCase().trim();
                                            this.props.onLocalStateChange({ ...this.props.localState, revenueCodes: revCodes });
                                            this.setState({ revenueCodes: revCodes });
                                        }}
                                        maxLength={128}
                                        size="small"
                                    /></td></tr>
                                    <tr><td className='fieldLabel'>Procedure Code(s):</td><td>
                                    <Input
                                        domID='co-cci-gen-proc-code-btm'
                                        className='text-input'
                                        initialValue={this.state.procedureCodes}
                                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            let procCodes = e.target.value.toUpperCase().trim();
                                            this.props.onLocalStateChange({ ...this.props.localState, procedureCodes: procCodes });
                                            this.setState({ procedureCodes: procCodes });
                                        }}
                                        maxLength={128}
                                        size="small"
                                    />
                                   </td></tr>
                                   <tr><td className='fieldLabel'>LOB Type:</td><td>
                                    <SelectDropdownV2
                                        domID='co-cci-gen-lob-type-btm'
                                        isClearable={false}
                                        isSearchable={false}
                                        onChange={(e: any) => {
                                            let lob = e.value;
                                            this.props.onLocalStateChange({ ... this.props.localState, lobType: lob });
                                            this.setState({ lobType: lob });
                                        }}
                                        options={this.LOBTypeOptions}
                                        initialValue={this.getLOBTypeOption(this.state.lobType)}
                                        size="small"
                                    />
                                    </td></tr></tbody></table>
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                <SelectButtons>
                                       <Button
                                        domID="co-cci-gen-add-btn"
                                        name={this.state.selectedCCIExclusionId == '' || !this.props.canEdit ? "Add" : "Update"}
                                        size="small"
                                        buttonType="standard"
                                        type="button"
                                        disabled={!this.props.canView || !this.props.canEdit}
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickAddUpdateButton(e) }}
                                        />
                                        <Button
                                            domID="co-cci-gen-clear-btn"
                                            name='CLEAR'
                                            size="small"
                                            buttonType="standard"
                                            type="button"
                                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickClearButton() }}
                                        /> 
                                        <Button
                                            domID="co-cci-gen-remove-btn"
                                            name='REMOVE'
                                            size="small"
                                            buttonType="standard"
                                            type="button"
                                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onClickRemoveButton(e) }}
                                            disabled={!this.props.canView || !this.props.canDelete || !this.props.canEdit || this.state.selectedCCIExclusionId == ''} 
                                        />
                                </SelectButtons>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                        </CodeSetSuppressionBottomSection>
                    </DialogFieldset>
                </ContentColumnWrapper>
                </DialogFieldset >
                <CenteredContent><DialogInstruction>{this.instructions}</DialogInstruction></CenteredContent>
                </CCIGeneralSelectionContent>
        );
    }
};

