import { Reducer, ActionCreatorsMapObject } from 'redux';
import { castArray } from 'lodash';
import { ICrudActionData } from "../../scripts/util/CrudComponentHelpers";
import { IFormUIState, defaultFormState, ICanHazErrors } from "@store/ui/BaseCrudUI";
import { createDataAction, createApiAction, createApiBodyAction, ActionTypes, IApiAction, ValidationCallback } from "../../scripts/util/ActionHelpers";
import { URLs } from '@commonDevResources/constants';

export interface IReportFilterData {
    dateFrom: string;
    dateTo: string;
    datePmtFrom: string;
    datePmtTo: string;
    checkNumber: string;
    providerNum: string;
    providerNPI: string;
    ICNum: string;
    selectedUserIds: string[];
    selectedUserActions: string[];
    selectedLobs: string[];
    selectedFacilities: string[];
    ListPopulation: APIRep_ReportFilterList | undefined;
    formsUsedList: MCFormsUsed[];
    selectedFormId: string;
    newFilterName: string;
    selectedFilterData: APICF_SimpleList;
}

export interface IReportFilterErrors {
    failedReportBatch?: string;
    invalidCheckNum?: string;
    invalidProviderNum?: string;
    invalidProviderNpi?: string;
    invalidIcNum?: string;
}

// UI representation of CRUD data. 
export interface IReportFilterUIState extends IReportFilterData, IFormUIState , ICanHazErrors<IReportFilterErrors>{
    isBusy: boolean;
};

// merge our custom action data interface with CRUD boilerplate
export interface IModifyReportFilter extends ICrudActionData<MCReportFilter, string> {
};
export interface IModifyReportFilterMultiValue<T> extends ICrudActionData<MCReportFilter, T[]> {
};

export interface IResetCrudFlag extends ICrudActionData<MCReportFilter, boolean> {
};

export interface IInitializeFormsUsedList extends ICrudActionData<MCReportFilter, MCFormsUsed[]> {
};

// exported for if I get time to do tests, otherwise does not need export
export const actionCreators = {
    initFormsUsed: (rawApiReturn: IInitializeFormsUsedList) => createDataAction('INIT_REPORT_FILTER_FORMS_USED', rawApiReturn),
    editDateFrom: (reportFilterInfo: IModifyReportFilter) => createDataAction('EDIT_REPORT_FILTER_DATE_FROM',
        reportFilterInfo),
    editDateTo: (reportFilterInfo: IModifyReportFilter) => createDataAction('EDIT_REPORT_FILTER_DATE_TO', reportFilterInfo ),
    editPaymentDateTo: (newDate: string) => createDataAction('EDIT_REPORT_FILTER_PMT_DATE_TO', newDate),
    editPaymentDateFrom: (newDate: string) => createDataAction('EDIT_REPORT_FILTER_PMT_DATE_FROM', newDate),
    editCheckNumber: (value: string) => createDataAction('EDIT_REPORT_FILTER_CHECK_NUMBER', value),
    editProviderNumber: (value: string) => createDataAction('EDIT_REPORT_FILTER_PROVIDER_NUMBER', value),
    editProviderNPINumber: (value: string) => createDataAction('EDIT_REPORT_FILTER_PROVIDER_NPI_NUMBER', value),
    editICNumber: (value: string) => createDataAction('EDIT_REPORT_FILTER_INTERNAL_CONTROL_NUMBER', value),
    editSelectedUsers: (reportFilterInfo: IModifyReportFilterMultiValue<string>) => createDataAction('EDIT_REPORT_FILTER_SELECTED_USERS', reportFilterInfo),
    editSelectedUserActions: (reportFilterInfo: IModifyReportFilterMultiValue<string>) => createDataAction('EDIT_REPORT_FILTER_SELECTED_USER_ACTIONS', reportFilterInfo),
    editSelectedLOBs: (reportFilterInfo: IModifyReportFilterMultiValue<string>) => createDataAction('EDIT_REPORT_FILTER_SELECTED_LOBS', reportFilterInfo),
    editSelectedFacilities: (reportFilterInfo: IModifyReportFilterMultiValue<string>) => createDataAction('EDIT_REPORT_FILTER_SLECTED_FACILITIES', reportFilterInfo),
    editSelectedFormId: (value: string) => createDataAction('EDIT_REPORT_SELECTED_FORM_ID', value),
    clearFilterError: (reportErrorInfo: IModifyReportFilter) => createDataAction('CLEAR_FILTER_ERROR', reportErrorInfo),
    getFormData: (callback?: ValidationCallback<any>) => createApiAction('GET_REPORT_FORM_DATA', URLs.api + '/api/data/GetReportFilterFormData', undefined, callback),
    executeReportProcess: (xmlData: APIXMLCB_XmlCallBackModel, success: any /*todo, replace with thunk*/,) =>
        createApiBodyAction('GENERATE_REPORT_FROM_DATERANGE', `${URLs.api}/api/data/PostMultiClaim`, xmlData, 'POST',
            JSON.stringify(xmlData), success),
    loadSelectedFilter: (xmlData: APIXMLCB_XmlCallBackModel) => createApiBodyAction('LOAD_SELECTED_FILTER_DATA',
        URLs.api + '/api/data/getFilter', xmlData, 'POST',
        JSON.stringify(xmlData)),
    saveFilter: (xmlData: APIXMLCB_XmlCallBackModel, callback?: ValidationCallback<any>) => createApiBodyAction('SAVE_FILTER_DATA',
        URLs.api + '/api/data/filterList', xmlData, 'POST', JSON.stringify(xmlData), callback),
    deleteReportFilter: (xmlData: APIXMLCB_XmlCallBackModel, callback?: ValidationCallback<any>) => createApiBodyAction('DELETE_REPORT_FILTER',
        URLs.api + '/api/data/DeleteFilter', xmlData, 'POST', JSON.stringify(xmlData), callback),
    updateNewFilterName: (newName: string) => createDataAction("UPDATE_NEW_NAME_FILTER", newName),
    updateSelectedFilter: (selected: APICF_SimpleList) => createDataAction('UPDATE_SELECTED_FILTER', selected),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>['type'];


export const defaultReportFilterState: IReportFilterUIState = {
    ...defaultFormState,
    isDirty: false,
    dateFrom: '',
    dateTo: '',
    datePmtFrom: '',
    datePmtTo: '',
    checkNumber: '',
    providerNum: '',
    providerNPI: '',
    ICNum: '',
    isBusy: false,
    ListPopulation: undefined,

    selectedUserIds: [],
    selectedUserActions: [],
    selectedLobs: [],
    selectedFacilities: [],
    newFilterName: '',
    selectedFilterData: { 'Name': '', 'ID': '-1' },
    formsUsedList: [],
    selectedFormId: '',
}


function ExtractSimpleList(incoming: APIRep_ReturnedFilter[]): APICF_SimpleList[]  {
    if (incoming) {
        return incoming.map((filter) => { return { 'ID': filter['@ID'], 'Name': filter['@Name'] } });
    }
    return [];
}

const validateFieldInputLength = (fieldValue: string, charCount: number, fieldName: string): string | undefined => {
    if (!fieldValue.length || fieldValue.length >= charCount) return undefined;
    return `${fieldName} should be a minimum of ${charCount} characters.`;
}

export const reportFilterUIReducer: Reducer<IReportFilterUIState, KnownActions> =
    (state: IReportFilterUIState | undefined, action: KnownActions) => {
        let newState = state && { ...state };
        let errors: IReportFilterErrors;

        if (state) {
            switch (action.type) {
                case 'INIT_REPORT_FILTER_FORMS_USED':
                {
                    const crud = action.data.uiData;
                        console.log(action.data);
                    newState = {...state, formsUsedList: crud, selectedFormId: ''};
                    break;
                }
                case 'EDIT_REPORT_FILTER_DATE_FROM':
                {
                    newState = { ...state, dateFrom: action.data.uiData };
                    break;
                }
                case 'EDIT_REPORT_FILTER_DATE_TO':
                {
                    newState = { ...state, dateTo: action.data.uiData };
                    break;
                }
                case 'EDIT_REPORT_FILTER_PMT_DATE_TO':
                {
                    newState = { ...state, datePmtTo: action.data };
                    break;
                }
                case 'EDIT_REPORT_FILTER_PMT_DATE_FROM':
                {
                    newState = { ...state, datePmtFrom: action.data };
                    break;
                }
                case 'EDIT_REPORT_FILTER_CHECK_NUMBER':
                {
                    errors = {...state.errors, invalidCheckNum: validateFieldInputLength(action.data, 3, 'Check Number')};
                    newState = { ...state, checkNumber: action.data, errors };
                    break;
                }
                case 'EDIT_REPORT_FILTER_PROVIDER_NUMBER':
                {
                    errors = {...state.errors, invalidProviderNum: validateFieldInputLength(action.data, 3, 'Provider Number')};
                    newState = { ...state, providerNum: action.data, errors };
                    break;
                }
                case 'EDIT_REPORT_FILTER_PROVIDER_NPI_NUMBER':
                {
                    errors = {...state.errors, invalidProviderNpi: validateFieldInputLength(action.data, 3, 'Provider NPI')};
                    newState = { ...state, providerNPI: action.data, errors };
                    break;
                }
                case 'EDIT_REPORT_FILTER_INTERNAL_CONTROL_NUMBER':
                {
                    errors = {...state.errors, invalidIcNum: validateFieldInputLength(action.data, 3, 'Internal Control Number')};
                    newState = { ...state, ICNum: action.data, errors };
                    break;
                }
                case 'EDIT_REPORT_SELECTED_FORM_ID': {
                    newState = {...state, selectedFormId: action.data};
                    break;
                }
                case 'CLEAR_FILTER_ERROR':
                {
                    //TODO: clear individual errors based on payload
                    newState = { ...state, errors: undefined };
                    break;
                }
                case 'UPDATE_NEW_NAME_FILTER':
                {
                    newState = { ...state, newFilterName: action.data };
                    break;
                }
                case 'EDIT_REPORT_FILTER_SELECTED_USERS':
                {
                    newState = { ...state, selectedUserIds: action.data.uiData };
                    break;
                }
                case 'EDIT_REPORT_FILTER_SELECTED_USER_ACTIONS':
                {
                    newState = { ...state, selectedUserActions: action.data.uiData };
                    break;
                }
                case 'EDIT_REPORT_FILTER_SELECTED_LOBS':
                {
                    newState = { ...state, selectedLobs: action.data.uiData };
                    break;
                }
                case 'EDIT_REPORT_FILTER_SLECTED_FACILITIES':
                {
                    newState = { ...state, selectedFacilities: action.data.uiData };
                    break;
                }
                case 'UPDATE_SELECTED_FILTER':
                {
                    newState = { ...state, selectedFilterData: action.data, newFilterName: '' };
                    break;
                }
                case 'GET_REPORT_FORM_DATA':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            break;

                        case "SUCCESS":
                        {
                            const responseData: APIRep_FormDataListPopulationContainer = action?.responseData as APIRep_FormDataListPopulationContainer;
                            if (responseData) {
                                newState = { ...state, ListPopulation: responseData.ListPopulation }
                                console.dir(newState);
                            } else {
                                console.log('where\'s ma data???');
                            }
                            break;
                        }
                        case "FAIL":
                        {
                            console.log(action, 'Failed to retrieve list population data');
                            break;
                        }
                    };
                    break;
                }
                case 'GENERATE_REPORT_FROM_DATERANGE':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                            newState = {...state, isBusy: true, errors: undefined}
                            break;

                        case "SUCCESS":
                        {
                            newState = { ...state, isBusy: false }

                            break;
                        }
                        case "FAIL":
                        {
                            // todo: get title from request data, replace this with thunk anyway
                            newState = {
                                ...state,
                                isBusy: false,
                                errors: { failedReportBatch: 'An error occurred processing your report' }
                            };
                            break;
                        }
                    };
                    break;
                }
                case 'LOAD_SELECTED_FILTER_DATA':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                        {
                            newState = { ...state, isBusy: true }
                            break;
                        }
                        case "SUCCESS":
                        {
                            const responseData: APIRep_StoredFilterSettingsContainer = action?.responseData as APIRep_StoredFilterSettingsContainer;
                            const incoming: APIRep_StoredFilterSettings | undefined = responseData?.Filter;
                            if (!incoming) {
                                // need a validation function on the result so we don't need this and inline checks below
                                console.warn("type mismatch when loading filter");
                            } else {
                                const incomingUsers: APICF_FilterSimpleID | APICF_FilterSimpleID[] = incoming.Users?.User || [];
                                // if multiple users are selected, we get an array of users, otherwise the User property contains a User object
                                // cast to array to normalize this
                                const selectedUserIds = castArray<APICF_FilterSimpleID>(incomingUsers).map((user) => user['@ID']);
                                const incomingActions:APIRep_SelectedIndex | APIRep_SelectedIndex[]= incoming.UserActions?.UserAction || [];
                                const selectedUserActions = castArray<APIRep_SelectedIndex>(incomingActions).map((action) => action['@ID']);
                                const incomingLobs:APIRep_SelectedIndex | APIRep_SelectedIndex[]= incoming.LOBs?.LOB || [];
                                const selectedLobs = castArray<APIRep_SelectedIndex>(incomingLobs).map((lob) => lob['@ID']);
                                const incomingFacilities:APIRep_SelectedIndex | APIRep_SelectedIndex[]= incoming.Facilities?.Facility || [];
                                const selectedFacilities = castArray<APIRep_SelectedIndex>(incomingFacilities).map((facility) => facility['@ID']);
                                newState = {
                                    ...state,
                                    isBusy: false,
                                    errors: undefined,
                                    dateFrom: incoming['@DateFrom'],
                                    dateTo: incoming['@DateTo'],
                                    datePmtFrom: incoming['@DatePmtFrom'],
                                    datePmtTo: incoming['@DatePmtTo'],
                                    checkNumber: incoming['@CheckNumber'],
                                    providerNum: incoming['@ProviderNum'],
                                    providerNPI: incoming['@ProviderNPI'],
                                    ICNum: incoming['@ICNum'],
                                    selectedLobs,
                                    selectedFacilities,
                                    selectedUserIds,
                                    selectedUserActions,
                                }
                            }
                            break;
                        }
                        case "FAIL":
                        {
                            // todo: get title from request data, replace this with thunk anyway
                            newState = {
                                ...state,
                                isBusy: false,
                                errors: { failedReportBatch: 'An error occurred loading your filter' }
                            };
                            break;
                        }
                    }
                    break;
                }
                case 'SAVE_FILTER_DATA':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                        {
                            newState = { ...state, isBusy: true }
                            break;
                        }
                        case "SUCCESS":
                        {
                            const responseData: APIRep_ResponseContainer = action?.responseData as APIRep_ResponseContainer;
                            if (responseData) {
                                var filterData = responseData.filterData as APIRep_ReturnedFilterDataArray;

                                newState = { ...state, isBusy: false }

                                // copy new list into to newState.ListPopulation.FilterList
                                if (newState.ListPopulation && filterData) {
                                    newState.ListPopulation.FilterList = ExtractSimpleList(filterData.ListPopulation.FilterList.Filter);
                                }

                                newState.selectedFilterData = { 'Name': '', 'ID': '-1' };
                                newState.newFilterName = '';

                                console.dir(newState);
                            } else {
                                console.log('where\'s ma data???');
                            }
                            break;
                        }
                        case "FAIL":
                        {
                            console.log(action, 'Saving Filter: Failed to retrieve list population data');
                            newState = { ...state, isBusy: false }
                            break;
                        }
                    }

                    break;
                }                
                case 'DELETE_REPORT_FILTER':
                {
                    switch (action.status.status) {
                        case "REQUEST":
                        {
                            newState = { ...state, isBusy: true }
                            break;
                        }
                        case "SUCCESS":
                        {
                            const responseData: any = action?.responseData;
                            if (responseData) {
                                newState = {
                                    ...state, isBusy: false, errors:undefined
                                }

                                if (responseData.returnCode === 0) {
                                    var filterData = responseData.filterData as APIRep_ReturnedFilterDataArray;

                                    // copy new list into to newState.ListPopulation.FilterList
                                    if (newState.ListPopulation && filterData) {
                                        newState.ListPopulation.FilterList = ExtractSimpleList(filterData.ListPopulation.FilterList.Filter);
                                    }
                                    newState.selectedFilterData = { 'Name': '', 'ID': '-1' };
                                    newState.newFilterName = '';
                                } else {
                                    newState.errors = { failedReportBatch: responseData.errorDescription }
                                }

                                console.dir(newState);
                            } else {
                                console.log('where\'s ma data???');
                            }
                            break;
                        }
                        case "FAIL":
                        {
                            console.log(action, 'Deleting Filter: Failed to retrieve list population data');
                            newState = { ...state, isBusy: false }
                            break;
                        }
                    };

                    break;
                }
                default:
                {
                    // The following line guarantees that every action in the KnownAction union has been covered by a case above 
                    const exhaustiveCheck: never = action;
                    return state;
                }
            }
        }
        return newState || defaultReportFilterState;
    }