import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { MCFacilityMasterType } from '@store/api/FacilityMasterType';

export interface IFacilityMasterUIState {
}

export const defaultState: IFacilityMasterUIState = {
}

export interface IFacilityMasterUIData {
};

export interface IFacilityUIPlaceholder extends ICrudActionData<MCFacilityMasterType, IFacilityMasterUIState> { }

export const actionCreators = {
    placeholder: (placeholder: IFacilityUIPlaceholder) => createDataAction('FACILITY_MASTER_UI_PLACEHOLDER', placeholder),
}

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const reducer: Reducer<IFacilityMasterUIState, KnownActions> = (state: IFacilityMasterUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        let updatedState = state;
        switch (action.type) {
            case 'FACILITY_MASTER_UI_PLACEHOLDER':
                if (action.data.masterCrud) {
                    return updatedState;
                }
                break;
        }
    }
    return defaultState;
}
