import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ChangePasswordUI from "@store/ui/ChangePasswordUI";
import { CrudTypes } from '@commonResources/CrudTypes';

export type IChangePasswordState = ICrudComponentState<MCARMUser, ChangePasswordUI.IChangePasswordUIState>;

export const actionCreators = createCrudComponentActions<ChangePasswordUI.ActionCreators>(ChangePasswordUI.actionCreators);
export type IChangePasswordActions = typeof actionCreators;
export type IChangePasswordActionProps = ICrudComponentActionProps<IChangePasswordActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiUsers, ChangePasswordUI.reducer);
export const reducer = combineReducers<IChangePasswordState>(reducers);