import { css } from 'styled-components';
import { Colors } from './colorVariables';

export const Borders = {
  darkestSecondaryGrey: css`
    border: 1px solid ${Colors.darkestSecondaryGrey};
  `,
  lightGrey: css`
    border: 1px solid ${Colors.lightGrey};
  `,
  lighterGrey: css`
    border: 1px solid ${Colors.lighterGrey};
  `,
  transparentLighterGrey: css`
    border: 1px solid ${Colors.transparentLighterGrey};
 `,
  secondary30: css`
    border: 1px solid ${Colors.secondary30};
  `,
  lightSecondaryGrey: css`
    border: 1px solid ${Colors.lightSecondaryGrey};
  `,
  middleSecondaryGrey: css`
    border: 1px solid ${Colors.middleSecondaryGrey};
  `,
  faintSecondaryGrey: css`
    border: 1px solid ${Colors.faintSecondaryGrey};
  `,
  blueGrey: css`
    border: 1px solid ${Colors.blueGrey};
  `,
  noBorder: css`
    border: none;
  `,
  onlyBottom: css`
    border-top: none;
    border-left: none;
    border-right: none;
  `,
  onlyTop: css`
    border-bottom: none;
    border-left: none;
    border-right: none;
  `,
  onlyLeft: css`
    border-top: none;
    border-bottom: none;
    border-right: none;
  `,
  onlyRight: css`
    border-top: none;
    border-bottom: none;
    border-left: none;
  `,
  onlyTopAndBottom: css`
    border-right: none;
    border-left: none;
  `,
  onlyLeftAndRight: css`
    border-top: none;
    border-bottom: none;
  `,
  thick: css`
    border-width: 2px;
  `,
  thicker: css`
    border-width: 3px;
  `,
  thickest: css`
    border-width: 4px;
  `,
  extraSmallBorderRadius: css`
    border-radius: 2px;
  `,
  smallBorderRadius: css`
    border-radius: 3px;
  `,
  largeBorderRadius: css`
    border-radius: 20px;
  `,
  extraLargeBorderRadius: css`
    border-radius: 32px;
  `,
  circularBorderRadius: css`
    border-radius: 50%;
  `,

  // 6.7.0
    chBlue100: css`
    border: 1px solid ${Colors.chBlue100};
  `,
    grey100: css`
    border: 1px solid ${Colors.grey100};
  `,
    grey30: css`
    border: 1px solid ${Colors.grey30};
  `,
    grey20: css`
    border: 1px solid ${Colors.grey20};
  `,
    transparentGrey20: css`
    border: 1px solid ${Colors.transparentGrey20};
  `,
    grey70: css`
    border: 1px solid ${Colors.grey70};
  `,
    grey50: css`
    border: 1px solid ${Colors.grey50};
  `,
/*
    noBorder: css`
    border: none;
  `,
    onlyBottom: css`
    border-top: none;
    border-left: none;
    border-right: none;
  `,
    onlyTop: css`
    border-bottom: none;
    border-left: none;
    border-right: none;
  `,
    onlyLeft: css`
    border-top: none;
    border-bottom: none;
    border-right: none;
  `,
    onlyRight: css`
    border-top: none;
    border-bottom: none;
    border-left: none;
  `,
    onlyTopAndBottom: css`
    border-right: none;
    border-left: none;
  `,
    onlyLeftAndRight: css`
    border-top: none;
    border-bottom: none;
  `,
    thick: css`
    border-width: 2px;
  `,
    thicker: css`
    border-width: 3px;
  `,
    thickest: css`
    border-width: 4px;
  `,
    extraSmallBorderRadius: css`
    border-radius: 2px;
  `,
    smallBorderRadius: css`
    border-radius: 3px;
  `,
    largeBorderRadius: css`
    border-radius: 20px;
  `,
    extraLargeBorderRadius: css`
    border-radius: 32px;
  `,
    circularBorderRadius: css`
    border-radius: 50%;
  `,
*/
    pillBorderRadius: css`
    border-radius: 2em;
  `,

};

export default Borders;
