import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as AutoListUI from './ui/AutoListUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IAutoListState = ICrudComponentState<MCAutoListType, AutoListUI.IAutoListUIState>;

export const actionCreators = createCrudComponentActions<AutoListUI.ActionCreators>(AutoListUI.actionCreators);
export type IAutoListActions = typeof actionCreators;
export type IAutoListActionProps = ICrudComponentActionProps<IAutoListActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiAutoProcessMaster, AutoListUI.reducer);
export const reducer = combineReducers<IAutoListState>(reducers);

export const validationCallback: ValidationCallback<MCAutoListType> = (crud: any): crud is MCAutoListType => {
    let testCrud: MCAutoListType = crud as MCAutoListType;

    let validShape: boolean = ValidateJSONSchema(testCrud, "MCAutoListType");
    if (validShape) {
        if (testCrud.AutoProcessMaintenanceInfo?.AutoModuleList as any === "") {
            testCrud.AutoProcessMaintenanceInfo.AutoModuleList = { AutoModule: new Array<MCAL_AutoModuleListAutoModule>() };
        }

        if (testCrud.AutoProcessMaintenanceInfo.AutoModuleList &&
            !Array.isArray(testCrud.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule)) {
            let arr = new Array<MCAL_AutoModuleListAutoModule>();
            arr.push(JSON.parse(JSON.stringify(testCrud.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule)));
            testCrud.AutoProcessMaintenanceInfo.AutoModuleList.AutoModule = arr;
        }
        if (testCrud.AutoProcessMaintenanceInfo?.TriggerEventList as any === "") {
            testCrud.AutoProcessMaintenanceInfo.TriggerEventList = { TriggerEvent: new Array<MCAL_TriggerEvent>() };
        }

        if (testCrud.AutoProcessMaintenanceInfo.TriggerEventList &&
            !Array.isArray(testCrud.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent)) {
            let arr = new Array<MCAL_TriggerEvent>();
            arr.push(JSON.parse(JSON.stringify(testCrud.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent)));
            testCrud.AutoProcessMaintenanceInfo.TriggerEventList.TriggerEvent = arr;
        }
        if (testCrud.AutoProcessMaintenanceInfo?.GroupList as any === "") {
            testCrud.AutoProcessMaintenanceInfo.GroupList = { Group: new Array<MCAL_GroupListGroup>() };
        }

        if (testCrud.AutoProcessMaintenanceInfo.GroupList &&
            !Array.isArray(testCrud.AutoProcessMaintenanceInfo.GroupList.Group)) {
            let arr = new Array<MCAL_GroupListGroup>();
            arr.push(JSON.parse(JSON.stringify(testCrud.AutoProcessMaintenanceInfo.GroupList.Group)));
            testCrud.AutoProcessMaintenanceInfo.GroupList.Group = arr;
        }
        if (testCrud.AutoProcessMaintenanceInfo?.AutoProcesses as any === "") {
            testCrud.AutoProcessMaintenanceInfo.AutoProcesses = { AutoProcess: new Array<MCAL_AutoProcess>() };
        }

        if (testCrud.AutoProcessMaintenanceInfo.AutoProcesses &&
            !Array.isArray(testCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess)) {
            let arr = new Array<MCAL_AutoProcess>();
            arr.push(JSON.parse(JSON.stringify(testCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess)));
            testCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess = arr;
        }

        if (validShape && testCrud?.AutoProcessMaintenanceInfo?.AutoProcesses?.AutoProcess)
        {
            for (let i = 0; i < testCrud.AutoProcessMaintenanceInfo.AutoProcesses.AutoProcess.length; i++) {
                VerifyParentAndArray(testCrud?.AutoProcessMaintenanceInfo?.AutoProcesses?.AutoProcess[i], "Groups", "Group");
                VerifyParentAndArray(testCrud?.AutoProcessMaintenanceInfo?.AutoProcesses?.AutoProcess[i], "AutoModules", "AutoModule");
                VerifyParentAndArray(testCrud?.AutoProcessMaintenanceInfo?.AutoProcesses?.AutoProcess[i], "Events", "Event");
            }
        }
// this isn't handling optional params....so ... how to use it?  perhaps someday we can rewrite keys instead of using a 3rd-party library
// Note: ValidateJSONSchema CAN handle optional params now (replaced keys transformer, 9/10/2021) Left commented pending review.
 //       validShape = ValidateJSONSchema(testCrud?.AutoProcessMaintenanceInfo?.AutoProcesses?.AutoProcess, "MCAL_AutoProcess");

    }

    return validShape;
}