import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, TextArea, Button } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { SelectComponent } from '../../common/SelectComponent';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IHoldCodeState, IHoldCodeActions, IHoldCodeActionProps, actionCreators, validationCallback } from '@store/HoldCode';
import { defaultState as defaultUIState } from '@store/ui/HoldCodeUI';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { getRawToken } from '@scripts/session/SecurityToken';
import { URLs } from '@commonDevResources/constants';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    height: 300px;
`;

export const EmptyHeader = styled.div``;

export const SelectList = styled.div`
    padding: 5px;
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    #holdCodeDescription {
        width: 350px;
        height: 100px;
    }
    #holdcode-name {
        width: 80px;
    }

`;

export const InputContainer = styled.div`
    height:250px;
`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const DialogFieldset = styled.fieldset`
    width: initial;
    border: 1px solid ${Colors.darkGrey};
    padding: 4px;
`;

export const DialogLegend = styled.legend`
    width: initial;
    border: initial;
    margin-bottom: initial;
    color: ${Colors.darkGrey};
    ${Typography.medium};
    ${Typography.ARMFontFamily};
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
    ncsIsEnabled: boolean,
    ncsSkipPrompt: boolean,
    ncsPromptText: string,
    ncsListType: string,
 };

interface IComponentState {
    cancelLeave: boolean
}

export const DEFAULT_STATE: IComponentState = {cancelLeave: false};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IHoldCodeProps = IMergeCrudComponentProps<IHoldCodeState, IHoldCodeActionProps, IOwnProps>;

enum eModifyButtonType {
    Add,
    Update
}

export class HoldCode extends React.Component<IHoldCodeProps, IComponentState> {
    readonly NCSQuestion: string = 'Do you want to update all enterprise sites?';

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Hold Code Maintenance",
        ncsIsEnabled: false,
        ncsSkipPrompt: false,
        ncsPromptText: "",
        ncsListType: "",
    };

    constructor(props: IHoldCodeProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiHoldCode }, validationCallback);
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onSelectHoldCode(e: React.ChangeEvent<HTMLSelectElement>) {
            this.props.action.ui.selectHoldCode(
                {
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        index: e.target.options.selectedIndex,
                        value: e.target.value,
                        text: e.target.options[e.target.options.selectedIndex].textContent
                    }
                });
    }

    public onSubmitNCS(e: any) {
        console.log('onSubmitNCS::HoldCode');
        this.props.action.crud.updateWithDeltaNcs(this.props.dataStore.crud, false);
        this.props.history.push('/LandingPage');
    }


    public onClearNCS(e: any) {
        console.log('onClearNCS::HoldCode');
        this.props.action.ui.sendSubmitNCSAlert({ uiData: { value: '' } });
    }

    public onDenyNCS(e: any) {
        console.log('onDenyNCS::HoldCode');
        this.props.action.ui.sendSubmitNCSAlert({ uiData: { value: '' } });
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud, false);
        this.props.history.push('/LandingPage');
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.ncsIsEnabled) {
            this.props.action.ui.sendSubmitNCSAlert({ uiData: { value: this.props.ncsPromptText } });
        } else {
            this.props.action.crud.updateWithDelta(this.props.dataStore.crud, false);
            this.props.history.push('/LandingPage');
        }
        
    }
    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public getModifyButtonType(): eModifyButtonType {
        switch (this.props.dataStore.ui.selectedHoldCode.index) {
        case 0:
            return eModifyButtonType.Add;
            break;
        default:
            return eModifyButtonType.Update;
            break;
        }
    }

    public getModifyButtonText() {
        switch (this.getModifyButtonType()) {
        case eModifyButtonType.Add:
            return 'Add';
        default:
            return 'Update';
        }
    }

    public isRemoveButtonDisabled() {
        return (this.props.dataStore.ui.selectedHoldCode.index == 0 || !this.props.canEdit);
    }

    public onClickModifyButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.dataStore.crud.data) {
            switch (this.getModifyButtonType()) {
            case eModifyButtonType.Add:
                this.props.action.ui.addHoldCode({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        '@ID': '#' + (this.props.dataStore.crud.data.HoldCodeMaintenanceInfo.HoldCodes.HoldCode.length + 1),
                        '@Name': this.props.dataStore.ui.holdCodeName,
                        '@Description': this.props.dataStore.ui.holdCodeDescription
                    }
                });
                break;
            default:
                this.props.action.ui.updateHoldCode({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        '@ID': this.props.dataStore.ui.selectedHoldCode.value,
                        '@Name': this.props.dataStore.ui.holdCodeName,
                        '@Description': this.props.dataStore.ui.holdCodeDescription
                    }
                });
            }
        }
    }

    public onClearRemoveConfirm(e: any) {
        this.onConfirmRemove('');
    }
    public onClickRemoveButton(e: any) {
        const msg = "Do you want to remove '" + this.props.dataStore.ui.holdCodeName + "'? This action cannot be undone.";
        this.onConfirmRemove(msg);
    }
    public onConfirmRemove(msg: string) {
        this.props.action.ui.promptHoldCodeRemoveConfirm({ uiData: { value: msg } });
    }
    
    public onConfirmRemoveButton(e: any) {
        this.onConfirmRemove('');
        this.props.action.ui.removeHoldCode({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                '@ID': this.props.dataStore.ui.selectedHoldCode.value,
                '@Name': this.props.dataStore.ui.holdCodeName,
                '@Description': this.props.dataStore.ui.holdCodeDescription
            }
        });
    }

    public render() {
        var instruction = <React.Fragment>Use this page to add descriptions to Hold Codes. The descriptions will display in the Held Claims reports.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        var addItem: MCHoldCode[] = [{
            '@ID': '',
            '@Name': '- ADD A NEW HOLD CODE -',
            '@Description': '- ADD A NEW HOLD CODE -',
        }];

        let okText = "Yes";
        let cancelText = "No";

        var data = this.props.dataStore.crud.data ? addItem.concat(this.props.dataStore.crud.data.HoldCodeMaintenanceInfo.HoldCodes.HoldCode) : addItem;
        return (
            <DialogWrapper title="Hold Code Maintenance" instruction={instruction} helpUrl='/Support/Help/HELP_Maint_HoldCodes.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <SelectList>
                        <DialogFieldset>
                            <DialogLegend>Hold Codes</DialogLegend>
                            <SelectComponent
                                title='holdCode'
                                size={13}
                                width='300px'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectHoldCode(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@Name"
                                }}
                                records={data}
                                selectedValue={this.props.dataStore.ui.selectedHoldCode.value}
                            >
                            </SelectComponent>

                        </DialogFieldset>
                    </SelectList>
                    <SelectActions>
                        <InputContainer>
                            <Input
                                domID="holdcode-name"
                                className="text-input"
                                label="*Hold Code Name:"
                                maxLength={4} 
                                initialValue={this.props.dataStore.ui.holdCodeName}
                                //deferState={{ value: this.state.username }}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateHoldCodeName(e.target.value)}
                                //disabled={this.state.disabled}
                                hasError={this.props.dataStore.ui.holdCodeNameError && this.props.dataStore.ui.holdCodeNameError !== ''}
                                errorMessage={this.props.dataStore.ui.holdCodeNameError}
                            />
                            <TextArea
                                placeholder="256 characters max"
                                domID="holdCodeDescription"
                                className="holdCodeDescription"
                                label="*Description:"
                                initialValue={this.props.dataStore.ui.holdCodeDescription}
                                //placeholder="Placeholder"
                                maxLength={256}
                                disabled={false}
                                readOnly={false}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.props.action.ui.updateHoldCodeDescription(e.target.value)}
                                hasError={this.props.dataStore.ui.holdCodeDescriptionError && this.props.dataStore.ui.holdCodeDescriptionError !== ''}
                                errorMessage={this.props.dataStore.ui.holdCodeDescriptionError}
                            />
                        </InputContainer>
                        <SelectButtons>
                            <Button
                                domID="addButton"
                                name={this.getModifyButtonText()}
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canEdit}
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                    handleChange()
                                    this.onClickModifyButton(e)
                                }}
                            />
                            <Button
                                domID="removeButton"
                                name="Remove"
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={this.isRemoveButtonDisabled()}
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                    handleChange()
                                    this.onClickRemoveButton(e)
                                }}
                            />
                        </SelectButtons>
                    </SelectActions>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.submitNCSAlert && this.props.dataStore.ui.submitNCSAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearNCS(e)}
                    message={this.props.dataStore.ui.submitNCSAlert}
                    okText={okText}
                    cancelText={cancelText}
                    showDefaultClose={true}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onSubmitNCS(e)}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onDenyNCS(e)}
                />
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.holdCodeRemoveConfirm && this.props.dataStore.ui.holdCodeRemoveConfirm.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearRemoveConfirm(e)}
                    message={this.props.dataStore.ui.holdCodeRemoveConfirm}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onConfirmRemoveButton(e)}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IHoldCodeState, IHoldCodeActionProps, IOwnProps, IHoldCodeProps, ApplicationState>(
    createCrudMapStateToProps('holdCode'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(HoldCode);

export default withRouter(connectedHoc);
