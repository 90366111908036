import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as PaperCommandsUI from './ui/PaperCommandsUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';

export type IPaperCommandsState = ICrudComponentState<PaperCommandsInfoType, PaperCommandsUI.IPaperCommandsUIState>;
export const actionCreators = createCrudComponentActions<PaperCommandsUI.ActionCreators>(PaperCommandsUI.actionCreators);
export type IPaperCommandsActions = typeof actionCreators;
export type IPaperCommandsActionProps = ICrudComponentActionProps<IPaperCommandsActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiPaperClaimsConfig, PaperCommandsUI.reducer);
export const reducer = combineReducers<IPaperCommandsState>(reducers);

export interface IPaperCommandsResponse {
    status: number,
    data?: string,
}

export const validationCallback: ValidationCallback<PaperCommandsInfoType> = (crud: any): crud is PaperCommandsInfoType => {
    return true;
}