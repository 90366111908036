import { combineReducers } from 'redux';
import * as BatchClaimsPrintingOptionsUI from '@store/ui/BatchClaimsPrintingOptionsUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type IBatchClaimsPrintingOptionsUIState = ICrudComponentState<MCPaperClaimsPrinting, BatchClaimsPrintingOptionsUI.IBatchClaimsPrintingOptionsUIState>;
export const actionCreators = createCrudComponentActions<BatchClaimsPrintingOptionsUI.ActionCreators>(BatchClaimsPrintingOptionsUI.actionCreators);
export type IBatchClaimsPrintingOptionsActions = typeof actionCreators;
export type IBatchClaimsPrintingOptionsActionProps = ICrudComponentActionProps<IBatchClaimsPrintingOptionsActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiPaperClaimsConfig, BatchClaimsPrintingOptionsUI.batchClaimsPrintingOptionsUIReducer);
export const reducer = combineReducers<IBatchClaimsPrintingOptionsUIState>(reducers);

export const validationCallback: ValidationCallback<MCPaperClaimsPrinting> = (crud: any): crud is MCPaperClaimsPrinting => {
    let testCrud: MCPaperClaimsPrinting = crud as MCPaperClaimsPrinting;
    let validShape = ValidateJSONSchema(testCrud, "MCPaperClaimsPrinting");
    return validShape;
};