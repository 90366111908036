import * as React from 'react';
import * as wind from '../../commonResources/window';
import { LandingPageComponent, IComponentProps } from './LandingPageComponent';
import { ARMComponentError } from './ARMComponentError';
import { ARMComponentLoading } from './ARMComponentLoading';
import { ARMComponentMessage } from './ARMComponentMessage';
import AssuranceDateControl from './AssuranceDateControl';
import { getItemStartDate, getItemEndDate } from '../../scripts/session/SessionHelpers';
import { FormatCurrency } from '../../scripts/util/FormatHelpers';
import { URLs } from '@commonDevResources/constants';
import { saveComponentState } from '@scripts/util/LandingPageHelpers'
import { createMapDispatchToProps, IMergeComponentProps, mergeComponentProps, IActionCreatorMap, mapStatesToProps } from '@scripts/util/ReduxHelpers';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Typography } from '@commonResources/typography';
import { CommonGraph } from '@commonResources/commonGraphing/CommonGraph';
import ReadOnlyList from '@commonResources/ReadOnlyList';
import { Colors } from '../../commonResources/colorVariables';
import { OperationFilter, defaultFilter, dateRanges, OperationDateRange } from "@store/OperationalDashboard/DrillDownView"
import { getRawToken } from '@scripts/session/SecurityToken';


const jqplot = require('../../scripts/jqPlot/jquery.jqplot.js');
//const jqplot = require('../../scripts/jqPlot/jquery.jqplot.min.js');
const jqplotPieRenderer = require('../../scripts/jqPlot/jqplot.pieRenderer.js');
const jqplotCategoryAxisRenderer = require('../../scripts/jqPlot/jqplot.categoryAxisRenderer.js');
const jqplotBarRenderer = require('../../scripts/jqPlot/jqplot.barRenderer.js');
const jqplotHighlighter = require('../../scripts/jqPlot/jqplot.highlighter.js');
const jqplotPointLabels = require('../../scripts/jqPlot/jqplot.pointLabels.js');

export interface IARMClaimCreationStateProps extends IComponentProps {
    id?: string | number;
    dateRange?: OperationDateRange | undefined;
}

interface IARMClaimCreationStateState {
    data?: any | null;
    noDataColor?: string;
    dateRange?: OperationDateRange | undefined;
    errorCode?: any;
    userCanView?: boolean;
    dateRangeValue: string;
    optionsData?: any;
    rows?: any;
}


const ClaimCreationStateComponentHeader = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    ${Typography.ARMFontFamily};
   
    #RAPClaimCreationState_armDateCtrl {
        display: inline-block !important;
        float: left;
        width: 160px;
        height: 20px;
    };
    #RAPClaimCreationState_armDateCtrl * {
        ${Typography.small}; 
        ${Typography.smallLineHeight};
    };

 .selectDropdown__container {
    margin-top: -3px;
};



`;


type IProps = IARMClaimCreationStateProps & RouteComponentProps<{}>;

class ARMClaimCreationState extends React.Component<IProps, IARMClaimCreationStateState>
{
    constructor(props: IProps)
    {
        super(props);
        
        this.state =
        {
            data: null,
            userCanView: true,
            errorCode: null,
            optionsData: [{ name: "Last 90 days", value: "6" }, { name: "Last 60 days", value: "5" }, { name: "Last 30 days", value: "4" }, { name: "Month to date", value: "3" }, { name: "Week to date", value: "2" }, { name: "Yesterday", value: "1" }, { name: "Today", value: "0" }, { name: "Custom Date Range", value: "7" }],
            rows: [],
            noDataColor: undefined,
            dateRangeValue: "Today"
        }
    }

    buildChart() {
        if (this.state.data) {
            var data: any[] = [];
            if (this.state.data.length > 0) {
                var sliceColor: any[] = [];
                var chartData = this.state.data.map((sliceItem: any) => {
                    var status = [sliceItem.Category,
                    sliceItem.Count,
                    sliceItem.Amount,
                    sliceItem.Color];

                    data.push(status);
                    sliceColor.push(sliceItem.Color);
                });

                console.log("Hello World, the data is: ", data);

                var options = {
                    title: 'Claim Creation State',
                    gridPadding: { top: 10, bottom: 10, left: 0, right: 0 },
                    seriesDefaults: {
                        shadow: false,
                        renderer: jQuery.jqplot.PieRenderer,
                        rendererOptions: {
                            showDataLabels: false,
                            sliceMargin: 3
                        }
                    },
                    seriesColors: sliceColor,
                    grid: { borderWidth: 0, shadow: false, backgroundColor: 'transparent' }
                };

                $.jqplot('claimChart', [data], options);
            }
            else {
                this.setState({ noDataColor: "#ffffff" });
                $('#pieChart').attr("class", "disabled");

                $.jqplot('claimChart', [[["No Data Available", 1]]], {
                    title: 'Claim Creation State',
                    gridPadding: { top: 10, bottom: 10, left: 0, right: 0 },
                    seriesDefaults: {
                        shadow: false,
                        renderer: jQuery.jqplot.PieRenderer,
                        rendererOptions: {
                            //showDataLabels: true,
                            dataLabels: 'label',
                            dataLabelNudge: -35
                        }
                    },
                    seriesColors: ['#c3c5cd'],
                    grid: { borderWidth: 0, shadow: false, backgroundColor: 'transparent' }
                });
            }
            this.addChartDetails(data);
        }
    }

    loadChartData() {
        //TODO: clientStatsService.startTracking("Claim Creaton State|GetClaimCreationStateData");
        var chartUrl = URLs.api + "/api/data/GetClaimCreationStateData/" + this.state.dateRange;

        if (this.state.dateRange == '7' && sessionStorage.getItem(this.props.Name + "Start") != null && sessionStorage.getItem(this.props.Name + "End") != null)
            chartUrl = chartUrl + "/" + sessionStorage.getItem(this.props.Name + "Start") + "/" + sessionStorage.getItem(this.props.Name + "End");


        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                //TODO: clientStatsService.stopTracking("Claim Creaton State|GetClaimCreationStateData");
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    var chartData = JSON.parse(responseData);



                    this.setState(
                        { data: chartData },
                        () => { this.buildChart() });
                }
                else {
                    console.warn(xhr.status + " response handling " + chartUrl);
                    this.setState({ errorCode: xhr.status });
                }
            }
        };

        xhr.open('get', chartUrl, true);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    componentDidMount()
    {
       
      this.checkUserRights();
    }

    initChart() {
        if (this.state.userCanView) {
            // TODO: Load data.

            if (this.props.dateRange == undefined || (this.props.dateRange == '7' && (sessionStorage.getItem(this.props.Name + "Start") == null || sessionStorage.getItem(this.props.Name + "End") == null))) {
                this.setState({
                    dateRange: '0',
                    dateRangeValue: "Today"
                }, () => { this.loadChartData() });
            }
            else
            {
                var dateRangeValue = "";
                var dateRange: OperationDateRange = '0';
                this.state.optionsData.map((option: any) => {
                    if (option.value == this.props.dateRange) {
                        dateRangeValue = option.name;
                        dateRange = option.value;

                        if (this.props.dateRange == '7') {
                            if (sessionStorage.getItem(this.props.Name + "Start") != null && sessionStorage.getItem(this.props.Name + "End") != null) {
                                dateRangeValue = getItemStartDate(this.props.Name) + "-" + getItemEndDate(this.props.Name);
                            }
                            else {
                                dateRangeValue = "Today"
                                dateRange = '0';
                            }
                        }
                    }
                });
                
                this.setState({
                            dateRange: dateRange,
                        dateRangeValue: dateRangeValue}, () => {this.loadChartData();});
            }  
        }         
    }

    checkUserRights() {
        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/userrights/userPermission/133", true); // FN_VIEW_DASHBOARD = 133 per S-46118 
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.onload = () => {
      //      console.log("userPermission: [" + xhr.responseText + "]");

            var data: any = {};
            if (xhr.responseText && xhr.responseText.length > 0)
                data = JSON.parse(xhr.responseText);

            this.setState({ userCanView: data && data.userPermission ? data.userPermission : false },
                () => { setTimeout(() => this.initChart(), 20); });

        };
        xhr.send();
    }
    
    addChartDetails(chartData: any) {
        //var table = document.getElementById("my-final-table");
        if (chartData.length > 0) {
            var keyCount = 0;
            var rowData = chartData.map((chartItem: any) => {
                keyCount++;
                //var row = table.insertRow();
                var cellStyle: React.CSSProperties = {};
                cellStyle.borderColor = chartItem[3];
                cellStyle.backgroundColor = chartItem[3];
                cellStyle.float = 'left';
                cellStyle.width = '10px';
                cellStyle.height = '10px';
                var style1: React.CSSProperties = {};
                style1.paddingLeft = "15px";
                var cell1 = <div><div className='jqplot-table-legend-swatch' style={cellStyle}></div><div style={style1}>{chartItem[0]}</div></div>;
                var style: React.CSSProperties = {};
                style.textAlign = "right";
                return <tr key={keyCount}><td>{cell1}</td><td style={style}>{Number(chartItem[1]).toLocaleString()}</td><td style={style}>{FormatCurrency(chartItem[2])}</td></tr>;
            });
            this.setState({ rows: rowData });
        } else {
            var style: React.CSSProperties = {};
            style.textAlign = "center";
            style.height = "100px";
            this.state.rows.push(<tr><td colSpan={3} style={style}><ARMComponentMessage message="No Data Available"/></td></tr>);
            this.setState({ rows: this.state.rows });
        }
    }

    saveCurrentState() {
 //      console.log('saving props wih surret state');
        var temp = JSON.parse(JSON.stringify(this.props));
        var saveThis = { ...temp, dateRange: this.state.dateRange };
//        console.log(JSON.stringify(saveThis));
        saveComponentState(this.props.id, JSON.stringify(saveThis));
    }

    onDateChange(event: any, selectedValue: string){
        this.setState(
                {
                    dateRange: event.value,
                    dateRangeValue: selectedValue,
                    data: null,
                    rows: [],
                    errorCode: null,
                    noDataColor: undefined,
                },
                () => {
                    this.loadChartData();
                    this.saveCurrentState();
                });
    }

    redirectLink() {
        console.log(this.state, 'state')
        var filter: OperationFilter = { ...defaultFilter };
        filter.drillPage = "ClaimCreationState";
        filter.dateRange = this.state.dateRange || "0";
        if ((this.state.dateRange == '7') && (sessionStorage.getItem(this.props.Name + "Start") != null) && (sessionStorage.getItem(this.props.Name + "End") != null)) {
            filter.fromDate = sessionStorage.getItem(this.props.Name + "Start")?.replace(/-/g, "/");
            filter.thruDate = sessionStorage.getItem(this.props.Name + "End")?.replace(/-/g, "/");
        }
        this.props.action.drillDownView.drillDownTo(filter);
    }

    renderComponent()
    {
        if (this.state.data != null) {
            var statusStyle: React.CSSProperties = {};
            statusStyle.paddingLeft = '20px';
            var rightAlign: React.CSSProperties = {};
            rightAlign.textAlign = "right";
            return (<div>
                        <a id="pieChart"  onClick={this.redirectLink.bind(this)}>
                            <div id="claimChart" className="smallComponent"></div>
                        </a>
                        <div className="legendClass">
                            <table id="my-final-table">
                                <thead>
                                    <tr>
                                        <th style={statusStyle}>Status</th>
                                        <th style={rightAlign}>Count</th>
                                        <th style={rightAlign}>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>{this.state.rows}</tbody>
                            </table>
                        </div>
                    </div>);
         }
        else if (this.state.errorCode != null) {
                return this.renderComponentError();
        }
        else
        {
            return this.renderComponentLoading();
        }
    }

    renderComponentError() {
        return (<ARMComponentError />);
    }

    renderComponentLoading() {
        //(<div className="RAPAdSpaceItem"> <img src="Content/images/loadingIcon.gif" /> </div>);
        return (<ARMComponentLoading />);
    }

    renderNoRights() {
        var noRightsStyle: React.CSSProperties = {
            position: 'inherit', 
            textAlign: 'center', 
            height : '100%', 
            width: '100%'
        };
        return (
            <LandingPageComponent ComponentClass={this.props.Name} {...this.props}>
                <div className="componentHeader" />
                <div className="RAPClaimCreationStateLoader">
                    <div style={noRightsStyle} className="noDataColor"><ARMComponentMessage message="User Does Not Have Rights To View Dashboard"/></div>
                </div>
            </LandingPageComponent>
        );
    }
    
    render()
    {
        if (!this.state.userCanView) {
            return this.renderNoRights();
        } else {
            var className = this.props.Name + " flex-componentcontainer " + this.props.Name + this.props.Order;
            var noDataBackground: React.CSSProperties = {};
            noDataBackground.backgroundColor = this.state.noDataColor;

            var name = (this.props.Name) ? this.props.Name : "RAPClaimCreationState";

            return (
                <LandingPageComponent ComponentClass={className} {...this.props}>
                    <ClaimCreationStateComponentHeader id="header" className="componentHeader" >
                        <AssuranceDateControl
                            Name={name}
                            dateCtrl="true"
                            helpCtrl="true" helpLink="HELP_DASHBOARD_OPSCLAIMCHART.HTM"
                            minDays={90}
                            data={this.state.optionsData}
                            onDateChange={this.onDateChange.bind(this)}
                            dateRange={this.state.dateRange}
                            dateRangeValue={this.state.dateRangeValue} />
                    </ClaimCreationStateComponentHeader>
                    <div style={noDataBackground} className="smallOuterComponent"> {this.renderComponent()}</div>
                </LandingPageComponent>
            );
        }
    }
};


var connectedHoc = connect(
    mapStatesToProps([
        "dashboardDefault",
        "dashboardClaim",
        "drillDownView"
    ]), // Selects which state properties are merged into the component's props
    createMapDispatchToProps([
        "dashboardDefault",
        "dashboardClaim",
        "drillDownView"
    ]), // Selects which action creators are merged into the component's props
    mergeComponentProps
)(ARMClaimCreationState);

export default withRouter(connectedHoc);