export class SortHelper {
    public static Sort<T extends Sortable>(records: T[], sortIndicator: ISortIndicator): T[] {
        let sortedRecords: T[] = [];

        // sort by string
        
        if (sortIndicator.sortDataType === SortDataType.String) {
            if (sortIndicator.sortDirection == 'up') {            
                sortedRecords = records.sort((a, b) => a[sortIndicator.sortColumn].toString().localeCompare(b[sortIndicator.sortColumn].toString()));
            } else {
                sortedRecords = records.sort((a, b) => b[sortIndicator.sortColumn].toString().localeCompare(a[sortIndicator.sortColumn].toString()));
            }

            // sort by number
        } else if (sortIndicator.sortDataType === SortDataType.Number) {
            if (sortIndicator.sortDirection == 'up') {
                sortedRecords = records.sort((a, b) => parseFloat(a[sortIndicator.sortColumn].toString()) - parseFloat(b[sortIndicator.sortColumn].toString()));
            } else {
                sortedRecords = records.sort((a, b) => parseFloat(b[sortIndicator.sortColumn].toString()) - parseFloat(a[sortIndicator.sortColumn].toString()));
            }

            // sort by date
			//ASRN-3498 CHANGES : Chrome / Edge View Paper claims is showing 2022 before 2023

        } else if (sortIndicator.sortDataType === SortDataType.Date) {
            const defaultDateString = '01/01/0001';
            if (sortIndicator.sortDirection == 'up') {
                sortedRecords = records.sort((a, b) =>
                    new Date((b[sortIndicator.sortColumn] || defaultDateString).toString()).getTime() -
                    new Date((a[sortIndicator.sortColumn] || defaultDateString).toString()).getTime());
            } else {
                sortedRecords = records.sort((a, b) =>
                    new Date((a[sortIndicator.sortColumn] || defaultDateString).toString()).getTime() -
                    new Date((b[sortIndicator.sortColumn] || defaultDateString).toString()).getTime());
            }
        }
        return sortedRecords;
    }

    // works like lodash sortBy - Sortable may only contain strings, so we need to to parse as part of compare
	//ASRN-3498 CHANGES : Chrome / Edge View Paper claims is showing 2022 before 2023
    public static MultiSort<T extends Sortable>(records: T[], sortIndicators: MultiSortIndicator): T[] {
        let sortedRecords: T[] = [];
        const defaultDateString = '01/01/0001';
        const dash = require("lodash");
        const sortbyColumns = dash.sortBy(sortIndicators.items,
            [function (o: MultiSortIndicatorItem) { return o.sequence; }])
			
		
        sortedRecords = records.sort((a, b) => {
            var result: number = 0;        // assume items are equivalent
            for (var index = 0; result === 0 && index < sortbyColumns.length; ++index) {
                const curInd = sortbyColumns[index].sortIndicator;
                if (curInd?.sortColumn === null || curInd.sortColumn === "") { continue; }
                if (curInd.sortDataType === SortDataType.String) {
                    result = a[curInd.sortColumn].toString().localeCompare(b[curInd.sortColumn].toString());
                } else if (curInd.sortDataType === SortDataType.Number) {
                    result = parseFloat(a[curInd.sortColumn].toString()) - parseFloat(b[curInd.sortColumn].toString());
                } else if (curInd.sortDataType === SortDataType.Date) {
                    result = new Date((b[curInd.sortColumn] || defaultDateString).toString()).getTime() -
                        new Date((a[curInd.sortColumn] || defaultDateString).toString()).getTime();
                }
                
                result = (curInd.sortDirection === 'up') ? result : -result;
            }
            return result;
        })

        return sortedRecords;
    }
}

export interface ISortIndicator {
    sortColumn: string;
    sortDirection: string;
    sortDataType?: SortDataType;
}

export interface MultiSortIndicator {
    items: MultiSortIndicatorItem[]
}

export interface MultiSortIndicatorItem {
    sequence: number,
    sortIndicator: ISortIndicator
}

export enum SortDataType {
    String = 1,
    Number,
    Date
}

export enum SortOrder {
    Ascending,
    Descending
}