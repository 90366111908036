import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as DownloadFilesUI from './ui/DownloadFilesUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';

export type IDownloadFilesState = ICrudComponentState<DownloadFilesInfoType, DownloadFilesUI.IDownloadFilesUIState>;
export const actionCreators = createCrudComponentActions<DownloadFilesUI.ActionCreators>(DownloadFilesUI.actionCreators);
export type IDownloadFilesActions = typeof actionCreators;
export type IDownloadFilesActionProps = ICrudComponentActionProps<IDownloadFilesActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiPaperClaimsConfig, DownloadFilesUI.reducer);
export const reducer = combineReducers<IDownloadFilesState>(reducers);

export interface IDownloadFilesResponse {
    status: number,
    data?: string,
}

export const validationCallback: ValidationCallback<DownloadFilesInfoType> = (crud: any): crud is DownloadFilesInfoType => {
    return true;
}