import { combineReducers } from 'redux';
import { castArray, compact, isPlainObject, keys as _keys, mapValues} from 'lodash';
import * as ReportFilterUI from '@store/ui/ReportFilterUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from
    '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type IReportFilterUIState = ICrudComponentState<MCReportFilter, ReportFilterUI.IReportFilterUIState>;
export const actionCreators = createCrudComponentActions<ReportFilterUI.ActionCreators>(ReportFilterUI.actionCreators);
export type IReportFilterActions = typeof actionCreators;
export type IReportFilterActionProps = ICrudComponentActionProps<IReportFilterActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiFormsUsed, ReportFilterUI.reportFilterUIReducer);
export const reducer = combineReducers<IReportFilterUIState>(reducers);

export const validationCallback: ValidationCallback<MCReportFilter> = (crud: any): crud is MCReportFilter => {
    let testCrud: MCReportFilter = crud as MCReportFilter;
    let validShape = isPlainObject(testCrud);
    return validShape;
};

export const filterFormDataValidationCallback: ValidationCallback<APIRep_FormDataListPopulationContainer> =
    (response: any): response is APIRep_FormDataListPopulationContainer => {
        const testResponse: APIRep_FormDataListPopulationContainer = response;

        // rewritten bc ValidateObject and enforceArray were broken in The Great Webpack Upgrade™ and we really didn't need it here.
        const validShape = !!testResponse && isPlainObject(testResponse);

        if (validShape) {
            if (!testResponse.ListPopulation) {
                return false;
            }
            const { ListPopulation } = testResponse;
            //@ts-ignore for some reason TS thinks castArray will return a Boolean
            testResponse.ListPopulation = mapValues<APIRep_ReportFilterList>(ListPopulation, castArray);
        }
        return validShape;
    };

export const filterResponseValidationCallback: ValidationCallback<APIRep_ResponseContainer> =
    (response: any): response is APIRep_ResponseContainer => {
        let testResponse: APIRep_ResponseContainer = response as APIRep_ResponseContainer;
        let validShape = isPlainObject(testResponse);

        if (validShape) {
            if (testResponse.returnCode === 0) {
                let jsonString: string = response.filterData as string;
                testResponse.filterData = JSON.parse(jsonString) as APIRep_ReturnedFilterDataArray;

                if (!testResponse.filterData.ListPopulation) {
                    testResponse.filterData.ListPopulation = {
                        FilterList: { Filter: [] },
                    }
                }

                let filterList: APIRep_FilterListContainer = testResponse.filterData.ListPopulation.FilterList;
                const theFilters = filterList.Filter;
                if (filterList.Filter)  {
                    filterList.Filter = compact(castArray<APIRep_ReturnedFilter>(theFilters));
                }
            }
        }
        return validShape;
    }

export const massageFormsUsed: ValidationCallback<MCFormsUsedType> = (crud: unknown): crud is MCFormsUsedType => {
    const testCrud = crud as MCFormsUsedType;
    let validShape = isPlainObject(testCrud) && _keys(crud).indexOf('FormsUsedMaintenanceInfo') > -1;
    if (validShape) {
        const fmi = testCrud.FormsUsedMaintenanceInfo;
        validShape = isPlainObject(fmi) && _keys(fmi).indexOf('Forms') > -1;
        if (validShape) {
            const theForms = fmi.Forms.Form;
            fmi.Forms.Form = compact(castArray<MCFormsUsed>(theForms));
            validShape = !!fmi.Forms.Form.length;
            if (validShape) {
                fmi.Forms.Form = fmi.Forms.Form.map((form) => {
                    switch(form['@Name']) {
                        case 'UB':
                            return { ...form, '@Name': 'UB-92' };
                        case '1500':
                            return { ...form, '@Name': 'HCFA-1500' };
                        default:
                            return form;
                    }
                });
            }
        }
    }
    return validShape;
}