import * as React from 'react';
import { Button } from 'ui-core';

export interface IImageButtonImage {
    Off: any;
    Up: any;
    Over?: any;
    Down?: any;
}

export interface IImageButtonProps {
    Title: string;
    image: IImageButtonImage;
    disabled?: boolean;
    altText?: string;
    tooltip?: string;
    onClick?: React.ChangeEventHandler<HTMLButtonElement>;
    className?: string;
}

export interface IImageButtonState {
    disabled: boolean;
}

export class ImageButton extends React.Component<IImageButtonProps, IImageButtonState>
{
    static defaultProps: IImageButtonProps = {
        Title: "imageButton",
        disabled: false,
        image: {
            Off: undefined,
            Up: undefined,
        },
    };

    constructor(props: IImageButtonProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
    }

    handleImageInfo(event: React.MouseEvent<HTMLImageElement, MouseEvent>, image: any) {
        if (!this.state.disabled) {
            if (image) {
                event.currentTarget.src = image;
            }
        }
    }

    render() {
        const { disabled } = this.props;
        return (
            <Button
                domID={this.props.Title}
                type="button"
                size="medium"
                buttonType="unstyled"
                className={this.props.className}
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (this.props.onClick) this.props.onClick(e) }}
                disabled={disabled}
            >
                <img src={disabled ? this.props.image.Off : this.props.image.Up}
                    title={this.props.tooltip}
                    alt={this.props.altText}
                    onMouseOver={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => { this.handleImageInfo(event, this.props.image.Over) }}
                    onMouseOut={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => { this.handleImageInfo(event, this.props.image.Up) }}
                    onMouseDown={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => { this.handleImageInfo(event, this.props.image.Down) }}
                    onMouseUp={(event: React.MouseEvent<HTMLImageElement, MouseEvent>) => { this.handleImageInfo(event, this.props.image.Up) }} />
            </Button>
        );
    }
};