import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as RemitWorkingGroupDisplayStore from './api/RemitWorkingGroupDisplayStore';

//  needed?  API calls should be native JSON and therefore, not need to fix arrays, but possible other data massaging will be needed
//import { ValidationCallback } from '@scripts/util/ActionHelpers';
//import { keys } from 'ts-transformer-keys';
//import { ValidateObject, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';


export type IRemitWorkingGroupDisplayState = IApiComponentState<any, RemitWorkingGroupDisplayStore.IRemitWorkingGroupDisplayUIState>;

export const actionCreators = createApiComponentActions<RemitWorkingGroupDisplayStore.ActionCreators>(RemitWorkingGroupDisplayStore.actionCreators);
export type IRemitWorkingGroupDisplayActions = typeof actionCreators;
export type IRemitWorkingGroupDisplayActionProps = IApiComponentActionProps<IRemitWorkingGroupDisplayActions>

export const reducers = createApiComponentReducers("remitWorkingGroupDisplay", RemitWorkingGroupDisplayStore.reducer);
export const reducer = combineReducers<IRemitWorkingGroupDisplayState>(reducers);


