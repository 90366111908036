import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as ReportCreateStore from './api/ReportCreateStore';

export type IReportCreateState = IApiComponentState<any, ReportCreateStore.IReportCreateUIState>;

export const actionCreators = createApiComponentActions<ReportCreateStore.ActionCreators>(ReportCreateStore.actionCreators);
export type IReportCreateActions = typeof actionCreators;
export type IReportCreateActionProps = IApiComponentActionProps<IReportCreateActions>

export const reducers = createApiComponentReducers("reportCreate", ReportCreateStore.reducer);
export const reducer = combineReducers<IReportCreateState>(reducers);



