export interface IHeaderProps {
    columns: {
    }
}

export interface IRowProps {
    record: {
        messageDateTimeFormatted: string;
        id: number;
        clientId: number;
        userId: number;
        groupId: number;
        isRead: boolean;
        messageDateTime: Date;
        messageType: number;
        messageSeverity: number;
        messageSubject: string;
        message: string;
    }
}

interface IUiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

export class GridConfig {
    public static getColumnsConfig(): Set<IUiclColumn> {
        const columns: IUiclColumn[] = [
            {
                text: 'Received',
                dataName: 'messageDateTimeFormatted',
                sortable: true,
                cellType: 'text',
                isSorted: 1,
            },
            {
                text: 'Subject',
                dataName: 'message',
                sortable: true,
                cellType: 'text',
                isSorted: 0,
            },
            {
                text: 'Delete',
                dataName: 'delete',
                sortable: false,
                cellType: 'text',
                isSorted: 0,
            },
        ];
        return new Set(columns);
    }
}
