import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { get } from 'lodash';
import { actionCreators, ActionCreators, OperationFilter, IDrillDownViewDefaultState } from '@store/OperationalDashboard/DrillDownView';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CommonGraph, GraphType } from '@commonResources/commonGraphing/CommonGraph';
import CfiIcon from './CfiIcon.svg';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { ModalConfirmation } from '@common/ModalConfirmation';
import CountdownTimer from '@commonResources/CountdownTimer';
import ReadOnlyList from '@commonResources/ReadOnlyList';
import { Accordion, CollapsePanel, CollapsePanelTitle, CollapsePanelDetails } from 'ui-core';
import { URLs } from '@commonDevResources/constants';
import { saveStateToSession } from '../../../SessionStorage';
import { getGraphLegendData, getGraphBarData, getGraphLineData } from './utils/dataParsers';
import { destroyStateInSession } from '../../../SessionStorage';
import { createMapDispatchToProps, mapDispatchToProps, IMergeComponentProps, mergeComponentProps, IActionCreatorMap, mapStatesToProps, mapStateToProps, IApplicationStateMap } from '@scripts/util/ReduxHelpers';

const domID = Date.now() + "" + Math.random()

const colorScheme = "blue70";
const graphType: GraphType = "bar";
const lineColor: string = "#bada55";
const pageWidth = 1000;

export const ContentWrapper = styled.div`
    display: flex;
    width: 100vw;
    justify-content: center;
    margin-top: 30px;
    ${Typography.default};
    ${Typography.ARMFontFamily};
    ${Typography.normal};
    ${Typography.defaultLineHeight};
    ${Typography.defaultHeight};
`;

const ChartColumnRow = styled.div`
    margin-left: 16px;
    margin-bottom: 16px;
    width: ${(props: IStyledProps) => props.width}px;
`;

const ChartColumn = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 16px;
`;

export const HistoryGraphs = styled.div`
    width: ${pageWidth / 3}px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 20px; 
    padding-bottom: 20px;
`;

// 
   
export const CollapsibleContentWrapper = styled.div`
    #accordian-section-title{
        span{
            color: ${Colors.black};
            ${Typography.default};
            ${Typography.bold};
            ${Typography.LetterSpacing};
            ${Typography.fontFamily};
            ${Typography.defaultLineHeight};
            ${Typography.defaultHeight};
        }
    }
    #accordion-section + div {
        border: none;
    }
`

interface IComponentProps {
    drillDownFilter: OperationFilter;
    storeObj: any;
};

interface IStyledProps {
    width: number;
};

interface IComponentState {
    isCfiModalOpen: boolean,
    cfiModalTimeout: number,
    cfiTimer: any,
    startTimer: boolean,
    resetTimer: boolean,
};

export const DEFAULT_STATE: IComponentState = {
    isCfiModalOpen: false,
    cfiModalTimeout: 5,
    cfiTimer: undefined,
    startTimer: false,
    resetTimer: false,
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IDrillDownViewProps = IMergeComponentProps<IDrillDownViewDefaultState, ActionCreators, IOwnProps>;


const CollapsibleContent = ({ children, id, title, expanded, onChange, ...props }: any) => {
    return (
        <CollapsibleContentWrapper>
                <CollapsePanel domID={`accordion-section-${id}`} expanded={expanded} onChange={onChange}>
                    <CollapsePanelTitle domID="accordian-section-title">
                        {title}
                    </CollapsePanelTitle>
                    <CollapsePanelDetails>
                        {children}
                    </CollapsePanelDetails>
                </CollapsePanel>
        </CollapsibleContentWrapper>

    )
}



export class DrillDownView extends React.Component<IDrillDownViewProps, IComponentState> {

    redirectUrl: string | undefined = undefined;

    state = DEFAULT_STATE;

    componentDidMount = () => {
        const { location, history, dataStore } = this.props;
        const { storeObj, drillDownDataArray } = dataStore;
        const { drillDownTo, saveCollapsed } = this.props.action;

        destroyStateInSession();
        if (!drillDownDataArray.length) {
            if (storeObj && storeObj.persistanceFilter && location.search === "?cfi") {
                saveCollapsed(true);
                drillDownTo(storeObj.persistanceFilter);
            } else {
                saveCollapsed(false);
                history.push("/Dashboard/default");
            }
        }
    }

    public handleTimeOut(timeLeft: number) {
        this.setState({ startTimer: false, resetTimer: false });

        if (timeLeft === 0) {
            this.redirectToCFI()
        };
    }

    redirectToCFI = () => {
        // Save the collapsed state to retrieve it on return from CFI
        let collapsedData = this.props.dataStore.collapsedData;
        saveStateToSession({ drillDownView: { storeObj: this.props.dataStore.storeObj, collapsedData, drillDownDataArray: [] } })
        this.redirectUrl !== undefined && this.props.history.push({ pathname: '/CFI/Default', state: { cfiReturnInfo: "arm.ui/operations/dashboard/drilldown" } });
    }

    // Parse the data to pull out line data
    public getChartData(item: any, type: string) {
        // Get labels
        let labels = item.data.map((el: any) => {
            if (type !== "Percent") {
                if (el.text) {
                    return { x: el.text };
                };
            }
            else {
                if (el.dataPoints[0]) {
                    return { x: el.dataPoints[0].label }
                }
            }
        });

        // Extract y-value for each corresponding label
        let values = labels.map((el: any, index: number) => {

            if (item.data[index].dataPoints) {
                let filtered = item.data[index].dataPoints.filter((point: any) => point.dataPointType === type);
                if (filtered.length) {
                    el.y = filtered[0].value;
                    el.actionPayload = filtered[0].filter;
                    el.tooltip = filtered[0].toolTip;
                    el.x = filtered[0].value + "%";
                    el.id = filtered[0].id;

                    return el;
                };
            };
        });
        return values[0] === undefined ? [] : values;
    };

    public getPieLegendData(item: any) {
        // Get labels
        let labels = item?.data?.map((el: any) => {
            if (el.dataPoints[0]) {
                return { x: el.dataPoints[0].label }
            }
        })

        // Extract legend for each corresponding label
        let values = labels?.map((el: any, index: number) => {
            if (item.data[index].dataPoints) {
                let filtered = item.data[index].dataPoints.filter((point: any) => point.dataPointType === "Percent");
                if (filtered.length) {
                    el.name = filtered[0].label;
                    el.tooltip = filtered[0].toolTip;
                    el.actionPayload = filtered[0].filter;
                    el.symbol = {
                        fill: filtered[0].color.toLowerCase().includes("green") ? "green" : "blue"
                    };

                    return el;
                }
            }
        })

        return (values === undefined || values[0] === undefined) ? [] : values;
    }

    public getPieColorScheme(item: any) {
        let color: string = "blue";
        // Get labels
        let labels = item?.data?.map((el: any) => {
            if (el.dataPoints[0]) {
                return { x: el.dataPoints[0].label }
            }
        })

        // Extract legend for each corresponding label
        labels?.map((el: any, index: number) => {
            if (item.data[index].dataPoints) {
                let filtered = item.data[index].dataPoints.filter((point: any) => point.dataPointType === "Percent");
                if (filtered.length) {
                    color = filtered[0].color.toLowerCase().includes("green") ? "green" : "blue";
                }
            }
        })
        return color;
    }

    getGraph = (chartData: any) => {
        const barGraphDataArry = getGraphBarData(chartData);
        const lineGraphDataArry = getGraphLineData(chartData);
        const pieGraphDataArry = this.getChartData(chartData, "Percent");
        let colorSchemeForPie = this.getPieColorScheme(chartData);
        const isCFI = Object.keys(pieGraphDataArry).length === 0 ? !!(chartData.data[0]?.filter?.cfi) : false;

        const legendData: any = getGraphLegendData(chartData);
        const lineColorArray = legendData.reduce((result: any, value: any) => {
            return ((value.dataPointType === "Line") ? [...result, value.symbol.fill] : result)
        }, [])

        return (
            <>
                {Object.keys(pieGraphDataArry).length === 0 && <CommonGraph
                    title={chartData.title}
                    xLabel={chartData.bottomTitle}
                    label={chartData.leftTitle}
                    displayLine={true}
                    data={barGraphDataArry}
                    lineData={lineGraphDataArry}
                    barClickHandler={this.onDataClick}
                    pointClickHandler={this.onDataClick}
                    colorScheme={colorScheme}
                    graphType={graphType as GraphType}
                    legendData={legendData}
                    lineColorArray={lineColorArray}
                    tooltipData={{
                        tooltipWidth: 300,
                        tooltipOffsetX: 20,
                        tooltipOffsetY: 30,
                        getContent: (tooltipData: any) => {
                            return (
                                <div>
                                    <div>{typeof tooltipData === "string" && tooltipData}</div>
                                    {isCFI &&
                                        (<div>
                                            <img src={CfiIcon} alt="View CFI" />
                                            {` View CFI`}
                                        </div>)}
                                </div>
                            );
                        }
                    }}
                    disableDataEvents={false}

                />
                }
                {pieGraphDataArry.length > 0 && <CommonGraph
                    title={chartData.title}
                    graphType={'pie'}
                    data={pieGraphDataArry}
                    legendData={this.getPieLegendData(chartData)}
                    barClickHandler={this.onDataClick}
                    colorScheme={colorSchemeForPie}
                    tooltipData={{
                        tooltipWidth: 200,
                        tooltipOffsetX: 20,
                        tooltipOffsetY: 30,
                        getContent: (tooltipData: any) => {
                            return (
                                <div>
                                    <div>{typeof tooltipData === "string" && tooltipData}</div>
                                    {isCFI &&
                                        (<div>
                                            <img src={CfiIcon} alt="View CFI" />
                                            {` View CFI`}
                                        </div>)}
                                </div>
                            )
                        }
                    }}
                    disableDataEvents={false}
                />}
            </>)
    };

    getListRecords = (data: any[]) => {
        return data.map((d) => {
            return this.getListColumnData(d.columnData);
        });
    };

    onCollapseClick = (e: any, expanded: any) => {
        const textContent = e.target.textContent;
        const domElement = e.target;
        const title = textContent !== '' ? textContent :  domElement.closest('span').querySelector('span').textContent;
        this.props.action.expandListColumn(title, expanded);
        
    }

    onDataClick = (filter: any) => {
        const initStoreObj = this.props.dataStore.storeObj;
        const isCFI = filter && filter.cfi ? filter.cfi : false;
        if (isCFI) {
            this.props.dataStore.saveCollapsed = true;
            this.setState({
                isCfiModalOpen: true,
                startTimer: true,
            }, () => this.props.action.drillDownTo(filter));
        } else {
            this.props.dataStore.saveCollapsed = false;
            this.props.action.drillDownTo(filter, { ...(initStoreObj ? initStoreObj : {}), persistanceFilter: filter });
        };
    };

    getListColumnData = (columnData: any[]) => {
        return columnData.map((cd) => {
            const { filter, text, isWrap } = cd;
            if (filter) {
                return {
                    text,
                    isWrap,
                    onClick: () => this.onDataClick(filter)
                }
            } else {
                return {
                    text,
                    isWrap
            }
            }
        }).reduce((a, v, i) => ({ ...a, ["@data" + i]: v }), {})
    }

    getListColumns = (columns: any[], width: number) => {
        return columns.map((c, index) => {
            const { sortable, text } = c;
            return {
                dataName: "@data" + index,
                text,
                sortable,
                SortDataType: index ? "Number" : "String",
                rightAlignText: index,
                width: (width / columns.length)
            };
        });
    };

    getList = (chartData: any, width: number) => {
        const columns = this.getListColumns(chartData.listHeader.columns, width);
        const records = this.getListRecords(chartData.data);
        return (
            <ReadOnlyList
                title={chartData.title} // optional header prop
                domID={domID} // recommened but optional domID
                columns={columns}
                records={records} />
        )
    }

    componentWillUnmount = () => {
        this.props.action.resetState();
    };

    getDataDisplay = (chart: any, width: number) => {
        switch (chart.outputType) {
            case 'List':
                return this.getList(chart, width);

            case 'Chart':
                return this.getGraph(chart);

            case 'PieChart':
                return this.getGraph(chart);

        }
    }

    render() {
        const { collapsedData } = this.props.dataStore;
        const { isCfiModalOpen, cfiModalTimeout, startTimer, resetTimer } = this.state;
        const initDrillDownDataArray = this.props.dataStore.drillDownDataArray;
        let drillDownDataArray = initDrillDownDataArray.length ? initDrillDownDataArray : [];
        let chartData = drillDownDataArray.length ? drillDownDataArray[drillDownDataArray.length - 1] : undefined;

        if (chartData && chartData.redirectUrl) {

            this.redirectUrl = chartData.redirectUrl;

            drillDownDataArray = drillDownDataArray.slice(0, -1);

            chartData = drillDownDataArray[drillDownDataArray.length - 1];

        };
        return (
            <ContentWrapper>
                <ChartColumn>
                    {drillDownDataArray && drillDownDataArray.filter((g) => { return g.column === 1 })
                        .sort((a, b) => { return a.row < b.row ? -1 : 1; })
                        .map((chart, index) => {
                            const width = (pageWidth / 3) * 2
                            const isCollapsible = get(chart, ['collapsible'], false);
                            if (isCollapsible) {
                                const expanded = collapsedData.includes(chart.title);
                                return (

                                    <ChartColumnRow width={width} key={index}>
                                        <CollapsibleContent {...chart} onChange={this.onCollapseClick} expanded={expanded}>
                                            {this.getDataDisplay({ ...chart, title:undefined }, width)}
                                        </CollapsibleContent>
                                    </ChartColumnRow>


                                )
                            }
                            return (
                                <ChartColumnRow width={width} key={index}>
                                    {this.getDataDisplay(chart, width)}
                                </ChartColumnRow>
                            )
                        })}
                </ChartColumn>
                <ChartColumn>
                    {drillDownDataArray && drillDownDataArray.filter((g) => { return g.column === 2 }).sort((a, b) => { return a.row < b.row ? -1 : 1; }).map((chart, index) => {
                        const width = (pageWidth / 3)
                        return (<ChartColumnRow width={width} key={index}>
                            {this.getDataDisplay(chart, width)}
                        </ChartColumnRow>)

                    })}
                </ChartColumn>
                <ModalConfirmation
                    title={`Alert!`}
                    isOpen={isCfiModalOpen}
                    alertMode={false}
                    onDeny={() => {

                        this.setState({
                            isCfiModalOpen: false,
                            startTimer: false,
                            resetTimer: true,

                        });
                    }}
                    onConfirm={this.redirectToCFI}
                    formattedMessage={`Redirecting to CFI in ${cfiModalTimeout} seconds...`}
                    component={<CountdownTimer
                        startTimer={startTimer}
                        resetTimer={resetTimer}
                        callback={(time: number) => this.handleTimeOut(time)}
                        seconds={cfiModalTimeout}
                        size={60}
                        strokeBgColor={`${Colors.grey10}`}
                        strokeColor={`${Colors.chBlue100}`}
                        strokeWidth={8} />}
                />
            </ContentWrapper>
        )

    };

};

var connectedHoc = connect(
    mapStateToProps("drillDownView"),
    mapDispatchToProps(actionCreators),
    mergeComponentProps
)(DrillDownView);

export default withRouter(connectedHoc);