import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as WorkingGroupDisplayStore from './api/WorkingGroupDisplayStore';

//  needed?  API calls should be native JSON and therefore, not need to fix arrays, but possible other data massaging will be needed
//import { ValidationCallback } from '@scripts/util/ActionHelpers';
//import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';


export type IWorkingGroupDisplayState = IApiComponentState<any, WorkingGroupDisplayStore.IWorkingGroupDisplayUIState>;

export const actionCreators = createApiComponentActions<WorkingGroupDisplayStore.ActionCreators>(WorkingGroupDisplayStore.actionCreators);
export type IWorkingGroupDisplayActions = typeof actionCreators;
export type IWorkingGroupDisplayActionProps = IApiComponentActionProps<IWorkingGroupDisplayActions>

export const reducers = createApiComponentReducers("workingGroupDisplay", WorkingGroupDisplayStore.reducer);
export const reducer = combineReducers<IWorkingGroupDisplayState>(reducers);


