import { combineReducers } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as SubmitReasonCodesStore from './api/SubmitReasonCodesStore'

export type ISubmitReasonCodesState = IApiComponentState<any, SubmitReasonCodesStore.ISubmitReasonCodesUIState>;

export const actionCreators = createApiComponentActions<SubmitReasonCodesStore.ActionCreators>(SubmitReasonCodesStore.actionCreators);
export type ISubmitReasonCodesActions = typeof actionCreators;
export type ISubmitReasonCodesActionProps = IApiComponentActionProps<ISubmitReasonCodesActions>

export const reducers = createApiComponentReducers("submitReasonCodes", SubmitReasonCodesStore.reducer);
export const reducer = combineReducers<ISubmitReasonCodesState>(reducers);
