import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getRawToken } from "../../../scripts/session/SecurityToken";

import { IMergeCrudComponentProps } from '../../../scripts/util/CrudComponentHelpers';

import { URLs } from "../../../commonResources/constants";
import { ApplicationState } from '../../../store/index';
import { IMergeComponentProps } from '../../../scripts/util/ReduxHelpers';
import ApiClient from '@commonResources/ApiClient';

/////////////////////////////////////////////////////////////////////////////////////////////

interface ILookUpDcnProps {
    canView: boolean,
}

interface ILookupDcnState {
}

export const DEFAULT_STATE: ILookupDcnState = {

};

type IOwnProps = ILookUpDcnProps & RouteComponentProps<{}>;
type IILaunchSendReceiveMailProps = IMergeComponentProps<ILookupDcnState, ILookUpDcnProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class LookUpDcn extends React.Component<IILaunchSendReceiveMailProps, ILookupDcnState> {

    static defaultProps: ILookUpDcnProps = {
        canView: false,
    };

    constructor(props: IILaunchSendReceiveMailProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }

        document.body.style.cursor = "wait";
        this.SendStartDigitalBillerRequest();
    }

    public componentWillUnmount() {
        document.body.style.cursor = "default";
    }

    // Hosted solution
    public SendStartDigitalBillerRequest() {
        console.log('URLs.api: [' + URLs.api + ']');
        fetch(URLs.api + '/api/data/digitalBiller/start',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
            }
        ).then(response => {
            var homeMessage = '';
            var messageType = 'SystemWarningNotification';
            if (response.status === 200) {
                homeMessage = 'The Look Up DCN request has been started!';
                console.log(homeMessage);
            } else {
                const errorDetail = response.status === 401 ? 'This client or user is not authorized for this silo.' : 'An unexpected error has occurred.';
                homeMessage = `The Look Up DCN request failed! ${errorDetail}. If this continues, please contact support.`;
                messageType = 'SystemErrorNotification';
                console.log(homeMessage, response.json);
            }
            sessionStorage.setItem(messageType, homeMessage);
            this.props.history.push('/ui/LandingPage');
        }).catch(error => {
            sessionStorage.setItem('SystemErrorNotification', 'The Look Up DCN request failed! If this continues, please contact support.');
        }).finally(() => {
            this.props.history.push('/ui/LandingPage');
        });
    }

    public render() {
        return null;
    }
};

var connectedHoc = connect<ILookupDcnState, IOwnProps, IILaunchSendReceiveMailProps, ApplicationState>(
    null
)(LookUpDcn);


export default withRouter(connectedHoc);