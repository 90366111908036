import { DownloadDocumentPt2 } from '@commonResources/window';


export class FileDownloader {

    async downloadFile(uri: string) {
        console.log('uri', uri)

        DownloadDocumentPt2(uri);
    }
}