import { Reducer, ActionCreatorsMapObject, Dispatch, AnyAction } from 'redux';
import { ActionTypes, createDataAction, createAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { ComplexApiAction, IThunkAction, IThunkApiAction, IThunkResult } from "@scripts/util/ThunkHelpers";
import { MasterCrudState, actionCreators as masterCrudCreators, ICrudObject } from '../MasterCrud';
import { History } from 'history';
import { URLs } from '@commonDevResources/constants';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IMCASimpleErrorRuleUIState {
    selectedTabIndex: number,

    // Unassigned Errors - Filter
    selectedUAECatFilter: string,
    selectedUAEFieldFilter: string,
    selectedUAEEditFilter: string,

    selectedUAEs: MCAUAErrorInfo[],
    selectedUAEUsers: MCAAssignedUserInfo[],
    selectedUAEDepartment: string,
    validation_MessageForUAE?: string,
    rollup_Message?: string,
    rollup_Validation?: string,
    rollup_Bridge_Validaiton?: string,
    
    // Assigned Rules - Filter
    selectedARCatFilter: string,
    selectedARFieldFilter: string,
    selectedAREditFilter: string,
    selectedARAssignedToFilter: string,

    selectedARs: MCARuleInfo[],
    selectedARUsers: MCAAssignedUserInfo[],
    selectedARDepartment: MCAAssignedDepartmentInfo,
    validation_MessageForAR?: string,
    removeRules_Message?: string,

    rulesSearchResults: MCARuleInfo;
    errorsSearchResults: MCAUAErrorInfo;

    Users: MCAAssignedUserInfo[];
    Departments: MCAAssignedDepartmentInfo[];
    Department: string;

    modalViewedForUAE?: boolean
    modalViewedForAR?: boolean
    modalText?: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface SearchFields {
    PageSize: string;
    StartPage: string;
    PageCount: string;
    QueryDays: string;
    RefreshMinutes: string;
    RefreshState: string;

    FilterCategory?: string;
    FilterField?: string;
    FilterEdit?: string;
    FilterAssignedTo?: string;
}

interface IMCAUIUserUpdate {
    id: string;
    user: string;
    alphaSplit: string;
}

interface IMCAUIDepartmentUpdate {
    id: string;
    department: string;
    priority: string;
}

export const defaultSearch: SearchFields = {
    PageSize: '',
    StartPage: '',
    PageCount: '',
    QueryDays: '',
    RefreshMinutes: '',
    RefreshState: ''
}

interface ITextField {
    value: string;
}

const defaultUAE: MCAUAErrorInfo = {
    '@PageNumber': '',
    '@Idx': '',
    '@MCAErrorIdx': '',
    '@FieldId': '',
    '@Field': '',
    '@EditName': '',
    '@ErrCode': '',
    '@CategoryId': '',
    'Category': '',
    '@MsgLev1': '',
    '@ErrorCount': '',

    'Field': '',
    'EditName': '',
    'Message': '',
    'Count': '',
}

const defaultAR: MCARuleInfo = {
    '@PageNumber': '',
    '@Idx': '',
    '@RuleId': '',
    '@RuleFieldId': '',
    '@RuleField': '',
    '@RuleCategoryId': '',
    '@RuleEditName': '',
    'AssignedTo': '',
    '@MsgLev1': '',
    'Category': '',
    'Field': '',
    'EditName': '',
    'Message': '',
    Users: { User: [{ selected: false, "@ID": '', "@Idx": '', "@AlphaSplit": '' }] },
    Departments: { Department: { "@ID": '', "@Idx": '', "@Name": ''} },
}

// Search Field Update
export interface IUpdateSearchField extends ICrudActionData<MCASimpleErrorRuleType, ITextField> { }

// TAB 0 - Unassigned Errors
export interface ISearchUAEFilter extends ICrudActionData<MCASimpleErrorRuleType, MCAUAErrorInfo> { }
export interface IModifyUAEFilter extends ICrudActionData<MCASimpleErrorRuleType, MCSelectionData> { }

export interface IUpdateUAEUserField extends ICrudActionData<MCASimpleErrorRuleType, IMCAUIUserUpdate> { }
export interface IUpdateUAEDeptField extends ICrudActionData<MCASimpleErrorRuleType, IMCAUIDepartmentUpdate> {}

//TAB 1 - Assigned Rules
export interface ISearchARFilter extends ICrudActionData<MCASimpleErrorRuleType, MCARuleInfo> { }
export interface IModifyARFilter extends ICrudActionData<MCASimpleErrorRuleType, MCSelectionData> { }

export interface IUpdateARUserField extends ICrudActionData<MCASimpleErrorRuleType, IMCAUIUserUpdate> { }
export interface IUpdateARDeptField extends ICrudActionData<MCASimpleErrorRuleType, IMCAUIDepartmentUpdate> { }

export const actionCreators = {
    selectTabIndex: (tabIndex: number) => createDataAction('SELECT_TAB_INDEX', tabIndex),

    // UAE Actions
    updateUAECatFilter: (catFilter: IUpdateSearchField) => createDataAction('UPDATE_UAE_CAT', catFilter), // Select UAE Category
    updateUAEFieldFilter: (fieldFilter: IUpdateSearchField) => createDataAction('UPDATE_UAE_FIELD', fieldFilter), // Update UAE Field
    updateUAEEditFilter: (editNameFilter: IUpdateSearchField) => createDataAction('UPDATE_UAE_EDITNAME', editNameFilter), // Update UAE Edit Name
    setClaimListForCFI: (selection: any, history: History<any>) => {
        let cfiData = { cfiReturnInfo: "arm.ui/administration/manageclaimassignmentssimpleerrorrules" };
        return ComplexApiAction
            .fromAction(createApiBodyAction('SET_MCA_CLAIM_LIST', `${URLs.api}/api/data/setSimpleMCAClaimList`, undefined, 'POST', JSON.stringify(selection)))
            .addThunk((
                    dispatch: Dispatch<AnyAction>,
                    apiResult: IThunkApiAction<string, any, any>): IThunkResult<void> => {
                    if (apiResult.status.status === 'SUCCESS' && apiResult.responseData && Number(apiResult.responseData) > 0) {
                            return { success: true }
                    }
                    return {success: false}
                }
            )
            .thenRedirect("/CFI/Default?Page=" + selection.pageNumber + "&Category=" + selection.categoryId + "&Field=" + selection.filterFieldName + "&Editname=" + selection.filterEditName, history, cfiData)
            .finish();
    },

    // UAE Search Actions
    searchUAErrors: (searchInfo: ISearchUAEFilter) => createDataAction('SEARCH_UAE', searchInfo), // Default Search
    searchUAErrorsUsingCat: (searchFieldInfo: ISearchUAEFilter) => createDataAction('SEARCH_UAE_BY_CAT', searchFieldInfo),  // Search Errors using Category
    searchUAErrorsUsingField: (searchFieldInfo: ISearchUAEFilter) => createDataAction('SEARCH_UAE_BY_FIELD', searchFieldInfo), // Search Errors using Field
    searchUAErrorsUsingEditName: (searchFieldInfo: ISearchUAEFilter) => createDataAction('SEARCH_UAE_BY_EDIT', searchFieldInfo),  // Search using Edit Name

    clearUAEFilters: (clear: IModifyUAEFilter) => createDataAction("CLEAR_UAE_FILTERS", clear), // Clear UAE Filters

    // UAE - User Assignment 
    updateUAEUserCheckList: (selectInfo: IUpdateUAEUserField) => createDataAction('CHECK_UAE_USER', selectInfo),
    updateUAEUserUnCheckList: (selectInfo: IUpdateUAEUserField) => createDataAction('UNCHECK_UAE_USER', selectInfo),
    updateUAEAlphaSplit: (selectInfo: IUpdateUAEUserField) => createDataAction('UPDATE_UAE_ALPHA_SPLIT', selectInfo),

    // UAE - Department Assignment
    updateUAEDepartmentCheckList: (selectInfo: IUpdateUAEDeptField) => createDataAction('CHECK_UAE_DEPT', selectInfo),
    updateUAEDepartmentUnCheckList: (selectInfo: IUpdateARDeptField) => createDataAction('UNCHECK_UAE_DEPT', selectInfo),

     // Error validation clear for UAE
    validationMessageForUAE: (message: string) => createDataAction('UAE_VALIDATION_MESSAGE', message),
    rollupMessage: (message: string) => createDataAction('ROLLUP_MESSAGE', message),
    rollupValidation: (message: string) => createDataAction('ROLLUP_VALIDATION_MESSAGE', message),
    rollupBridgeValidation: (message: string) => createDataAction('ROLLUP_BRIDGE_VALIDATION_MESSAGE', message),
    // AR Actions
    updateARCatFilter: (catFilter: IUpdateSearchField) => createDataAction('UPDATE_AR_CAT', catFilter), // Select AR Category Filter
    updateARFieldFilter: (fieldFilter: IUpdateSearchField) => createDataAction('UPDATE_AR_FIELD', fieldFilter), // Update AR Field
    updateAREditFilter: (editNameFilter: IUpdateSearchField) => createDataAction('UPDATE_AR_EDITNAME', editNameFilter), // Update AR Edit Name
    updateARAssignedToFilter: (assignedToFilter: IUpdateSearchField) => createDataAction('UPDATE_AR_ASSIGNEDTO', assignedToFilter), // Update AR AssignedTo

    // AR Search Actions
    searchARErrors: (searchInfo: ISearchARFilter) => createDataAction('SEARCH_AR', searchInfo), // Default Search
    searchARUsingCategory: (searchFieldInfo: ISearchARFilter) => createDataAction('SEARCH_AR_BY_CAT', searchFieldInfo), // Search Rule using Category
    searchARUsingField: (searchFieldInfo: ISearchARFilter) => createDataAction('SEARCH_AR_BY_FIELD', searchFieldInfo), // Search Rule using Field
    searchARUsingEditName: (searchFieldInfo: ISearchARFilter) => createDataAction('SEARCH_AR_BY_EDIT', searchFieldInfo), // Search Rule using Edit
    searchARUsingAssignedTo: (searchFieldInfo: ISearchARFilter) => createDataAction('SEARCH_AR_BY_ASSIGNEDTO', searchFieldInfo), // Search Rule using AssignedTo

    clearARFilters: (clear: IModifyARFilter) => createDataAction("CLEAR_AR_FILTERS", clear), // Clear AR Filters
   
    removeRules: (removeRules: IModifyARFilter) => createDataAction('REMOVE_RULES', removeRules),    // Remove Assigned Rules
    removeRulesMessage: (message: string) => createDataAction('REMOVERULES_MESSAGE', message),

    // AR - User Assignment
    updateARUserCheckList: (selectInfo: IUpdateARUserField) => createDataAction('CHECK_AR_USER', selectInfo),
    updateARUserUnCheckList: (selectInfo: IUpdateARUserField) => createDataAction('UNCHECK_AR_USER', selectInfo),
    updateARAlphaSplit: (selectInfo: IUpdateARUserField) => createDataAction('UPDATE_AR_ALPHA_SPLIT', selectInfo),

    // AR - Department Assignment
    updateARDepartmentCheckList: (selectInfo: IUpdateARDeptField) => createDataAction('CHECK_AR_DEPT', selectInfo),
    updateARDepartmentUnCheckList: (selectInfo: IUpdateARDeptField) => createDataAction('UNCHECK_AR_DEPT', selectInfo),

    // Error validation clear for AR
    validationMessageForAR: (message: string) => createDataAction('AR_VALIDATION_MESSAGE', message),
    setModalText: (modalText: string) => createDataAction('SET_MODAL_TEXT', modalText),

    //save selected  selectedARs
    selectedUAEs: (selectedUAEsList: any) => createDataAction('ADD_SELECTED_UAE_S', selectedUAEsList),
    selectedARs: (selectedARs: any) => createDataAction('ADD_SELECTED_AR_S', selectedARs),
    filteredUAEs: (filteredUAEsList: any) => createDataAction('UPDATE_FILTERED_UAES', filteredUAEsList)
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IMCASimpleErrorRuleUIState = {
    selectedTabIndex: 0,

    // UAE - Filters
    selectedUAECatFilter: '',
    selectedUAEFieldFilter: '',
    selectedUAEEditFilter: '',

    selectedUAEs: [],
    selectedUAEUsers: [],
    selectedUAEDepartment: '',
    validation_MessageForUAE: undefined,
    rollup_Message: undefined,
    rollup_Validation: undefined,

    // AR - Filters
    selectedARCatFilter: '',
    selectedARFieldFilter: '',
    selectedAREditFilter: '',
    selectedARAssignedToFilter: '',

    selectedARs: [],
    selectedARUsers: [],
    selectedARDepartment: {
        "@ID": '',
        "@Idx": '',
        "@Name": '',
    },

    rulesSearchResults: defaultAR,
    errorsSearchResults: defaultUAE,
    validation_MessageForAR: undefined,
    removeRules_Message: undefined,

    Users: [],
    Department: '',
    Departments: [],
    modalViewedForUAE: true,
    modalViewedForAR: true,
    modalText: "",
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IMCASimpleErrorRuleUIState, KnownActions> = (state: IMCASimpleErrorRuleUIState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_TAB_INDEX':
                return {
                    ...state,
                    selectedTabIndex: action.data,
                }
            // UAE Filter
            case 'SEARCH_UAE': {
                if (action.data.masterCrud) {
                    return {
                        ...state,
                        selectedTabIndex: 0,
                    }
                }
            }
            break;
            case 'SET_MCA_CLAIM_LIST': {
                switch (action.status.status) {
                    case "SUCCESS":
                        {
                            if (Number(action.responseData) == 0) {
                                return {
                                    ...state,
                                    modalText: "There are no unreleased claims that match the error criteria."
                                }
                            }
                        }
                    case "FAIL":
                        return {
                            ...state,
                            modalText: action.responseData.length > 0 ? action.responseData : "An error occurred while setting the claim list. Please try again later."
                        }
                };
                break;                
            }
            break;
            case 'SET_MODAL_TEXT':{
                    return {
                        ...state,
                        modalText: action.data,
                    }
            }
            case 'UPDATE_UAE_CAT': {
                if (state.selectedUAECatFilter !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedUAECatFilter: action.data.uiData.value,
                    }
                }
            }
            break;
            case 'UPDATE_UAE_FIELD': {
                if (state.selectedUAEFieldFilter !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedUAEFieldFilter: action.data.uiData.value,
                    }
                }
            }
            break;
            case 'UPDATE_UAE_EDITNAME': {
                if (state.selectedUAEEditFilter !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedUAEEditFilter: action.data.uiData.value,
                    }
                }
            }
                break;
            case 'SEARCH_UAE_BY_CAT': {
                if (state.selectedUAECatFilter !== action.data.uiData['@CategoryId']) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedUAECatFilter: action.data.uiData['@CategoryId'],
                        },
                    }
                }
            }
                break;
            case 'SEARCH_UAE_BY_FIELD': {
                if (state.selectedUAEFieldFilter !== action.data.uiData['@Field']) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedUAEFieldFilter: action.data.uiData['@Field'],
                        },
                    }
                }
            }
            break;
            case 'SEARCH_UAE_BY_EDIT': {
                if (state.selectedUAEEditFilter !== action.data.uiData['@EditName']) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedUAEEditFilter: action.data.uiData['@EditName'],
                        },
                    }
                }
            }
                break;
            case 'CLEAR_UAE_FILTERS': {
                return {
                    ...state,
                    selectedUAECatFilter: '',
                    selectedUAEFieldFilter: '',
                    selectedUAEEditFilter: '',
                }
            }
            case 'CHECK_UAE_USER': {
                return {
                    ...state,
                }
            }
            case 'UNCHECK_UAE_USER': {
                return {
                    ...state,
                }
            }
            case 'UPDATE_UAE_ALPHA_SPLIT': {
                return {
                    ...state,
                }
            }
            case 'CHECK_UAE_DEPT': {
                return {
                    ...state,
                }
            }
            case 'UNCHECK_UAE_DEPT': {
                return {
                    ...state,
                }
            }
            case 'UAE_VALIDATION_MESSAGE': {
                if (state.validation_MessageForUAE !== action.data) {
                    return {
                        ...state,
                        validation_MessageForUAE: action.data,
                        modalViewedForUAE: !state.modalViewedForUAE,
                    }
                }
            }
            break;
            case 'ROLLUP_MESSAGE': {
                if (state.rollup_Message !== action.data) {
                    return {
                        ...state,
                        rollup_Message: action.data,
                        modalViewedForUAE: !state.modalViewedForUAE,
                    }
                }
            }
            break;
            case 'ROLLUP_VALIDATION_MESSAGE': {
                if (state.rollup_Validation !== action.data) {
                    return {
                        ...state,
                        rollup_Validation: action.data,
                        modalViewedForUAE: !state.modalViewedForUAE,
                    }
                }
            }
            break;
            case 'ROLLUP_BRIDGE_VALIDATION_MESSAGE': {
                if (state.rollup_Bridge_Validaiton !== action.data) {
                    return {
                        ...state,
                        rollup_Bridge_Validaiton: action.data,
                        modalViewedForUAE: !state.modalViewedForUAE,
                    }
                }
            }
                break;

            case 'REMOVERULES_MESSAGE': {
                if (state.removeRules_Message !== action.data) {
                    return {
                        ...state,
                        removeRules_Message: action.data,
                        modalViewedForAR: !state.modalViewedForAR,
                    }
                }
            }
            break;
            case 'SEARCH_AR': {
                if (action.data.masterCrud) {
                    return {
                        ...state,
                        selectedTabIndex: 1,
                    }
                }
            }
                break;
            case 'UPDATE_AR_CAT': {
                if (state.selectedARCatFilter !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedARCatFilter: action.data.uiData.value,
                    }
                }
            }
            break;
            case 'UPDATE_AR_FIELD': {
                if (state.selectedARFieldFilter !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedARFieldFilter: action.data.uiData.value,
                    }
                }
            }
                break;
            case 'UPDATE_AR_EDITNAME': {
                if (state.selectedAREditFilter !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedAREditFilter: action.data.uiData.value,
                    }
                }
            }
                break;
            case 'UPDATE_AR_ASSIGNEDTO': {
                if (state.selectedARAssignedToFilter !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedARAssignedToFilter: action.data.uiData.value,
                    }
                }
            }
            break;
            case 'SEARCH_AR_BY_CAT': {
                if (state.selectedARCatFilter !== action.data.uiData['@RuleCategoryId']) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedARCatFilter: action.data.uiData['@RuleCategoryId'],
                        },
                    }
                }
            }
                break;
            case 'SEARCH_AR_BY_FIELD': {
                if (state.selectedARFieldFilter !== action.data.uiData['@RuleFieldId']) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedARFieldFilter: action.data.uiData['@RuleField'],
                        },
                    }
                }
            }
                break;
            case 'SEARCH_AR_BY_EDIT': {
                if (state.selectedAREditFilter !== action.data.uiData['@RuleEditName']) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedAREditFilter: action.data.uiData['@RuleEditName'],
                        },
                    }
                }
            }
                break;
            case 'SEARCH_AR_BY_ASSIGNEDTO': {
                if (state.selectedARAssignedToFilter !== action.data.uiData['@Idx']) {
                    return {
                        ...state,
                        searchFields: {
                            ...state,
                            selectedARAssignedToFilter: action.data.uiData['@Idx'],
                        },
                    }
                }
            }
            break;
            case 'CLEAR_AR_FILTERS': {
                return {
                    ...state,
                    selectedARCatFilter: '',
                    selectedARFieldFilter: '',
                    selectedAREditFilter: '',
                    selectedARAssignedToFilter: '',
                }
            }
            case 'CHECK_AR_USER': {
                return {
                    ...state,
                }
            }
            case 'UNCHECK_AR_USER': {
                return {
                    ...state,
                }
            }
            case 'UPDATE_AR_ALPHA_SPLIT': {
                return {
                    ...state,
                }
            }
            case 'CHECK_AR_DEPT': {
                return {
                    ...state,
                }
            }
            case 'UNCHECK_AR_DEPT': {
                return {
                    ...state,
                }
            }
            case 'REMOVE_RULES': {
                let newState: IMCASimpleErrorRuleUIState = {
                    ...state,
                    validation_MessageForAR: undefined
                };

                let arUIData = action.data.uiData;

                // Validate Error Note Selection
                if (arUIData && arUIData?.selectedARs) {
                    if (arUIData?.selectedARs?.length > 0) {
                        let singleorMultple: string = arUIData?.selectedARs?.length > 1 ? "rules" : "rule";
                        return {
                            ...state,
                            removeRules_Message: `You have chosen to remove ${arUIData.selectedARs.length} ${singleorMultple}. This action cannot be undone. Are you sure you want to continue?`,
                        }
                    }
                }

                return newState;
            }
            case 'AR_VALIDATION_MESSAGE': {
                if (state.validation_MessageForAR !== action.data) {
                    return {
                        ...state,
                        validation_MessageForAR: action.data,
                        modalViewedForAR: !state.modalViewedForAR,
                    }
                }
            }
            break;
            case 'ADD_SELECTED_UAE_S': {
                return {
                    ...state,
                    selectedUAEsList : action.data,
                }
            }
            case 'ADD_SELECTED_AR_S': {
                return {
                    ...state,
                    selectedARsList: action.data,
                }
            }
            case 'UPDATE_FILTERED_UAES': {
                return {
                    ...state,
                    filteredUAEsList : action.data,
                }
            }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
