import * as React from 'react';
import { Modal, Button, SectionHeader } from 'ui-core';
import styled from 'styled-components';

const ModalBody = styled.div`
`;

const InnerComponentWrapper = styled.div`
    padding-bottom: 1.5em;
    padding-top: 2em;
`;

const ButtonRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export interface ModalComponentWrapperProps {
    isOpen: boolean;
    showDefaultClose: boolean;
    onModalToggle: React.MouseEventHandler<HTMLElement>;
    title?: string;
    component: React.ReactNode;
    alertMode?: boolean;
    onConfirm?: React.MouseEventHandler<HTMLButtonElement>;
    onDeny?: React.MouseEventHandler<HTMLButtonElement>;
    onOK?: React.MouseEventHandler<HTMLButtonElement>;
    okText?: string;
    cancelText?: string;
};

interface IComponentState {
    visible: boolean;
}

export class ModalComponentWrapper extends React.Component<ModalComponentWrapperProps, IComponentState> {

    static defaultProps: ModalComponentWrapperProps = {
        isOpen: true,
        showDefaultClose: false,
        onModalToggle: (e: React.MouseEvent<HTMLElement>) => false,
        title: '',
        component: null,
        alertMode: false,
        okText: "OK",
        cancelText: "Cancel",
    };

    constructor(props: ModalComponentWrapperProps) {
        super(props);

        this.state = {
            visible: true,
        };
    }

    closeModal() {
        this.setState({ visible: false });
    }

    //public onClickX(e: React.ChangeEvent<HTMLButtonElement>) {
    //    this.props.isOpen = false;
    //}

    // debuke says: button: onClick: { (e: React.ChangeEvent<HTMLButtonElement>) => this.onClickX(e) }
    render() {
        return (
            <Modal isOpen={this.props.isOpen} showDefaultClose={this.props.showDefaultClose} onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.props.onModalToggle(e)}>
                    <ModalBody>
                        <SectionHeader
                            domID="modal-confirmation-message"
                        title={this.props.title ? this.props.title : ModalComponentWrapper.defaultProps.title}
                            //buttonConfig={{
                            //    buttonType: 'standard',
                            //    size: 'small',
                            //    text: 'X'
                            //}}
                        />
                    <InnerComponentWrapper>
                        {this.props.component}
                    </InnerComponentWrapper>
                    {!this.props.alertMode &&
                         <ButtonRow>
                                <Button
                                    name={this.props.okText}
                                    buttonType="emphasized"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => { this.props.onModalToggle(e); if (this.props.onConfirm) { this.props.onConfirm(e); } }}
                                    size="small"
                                    domID="modal-confirmation-confirm-button"
                                />
                                <Button
                                    name={this.props.cancelText}
                                    buttonType="standard"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => { this.props.onModalToggle(e); if (this.props.onDeny) { this.props.onDeny(e); } }}
                                    size="small"
                                    domID="modal-confirmation-cancel-button"
                                />
                            </ButtonRow>
                        }
                    {this.props.alertMode &&
                         <ButtonRow>
                                <Button
                                    name="OK"
                                    buttonType="emphasized"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => { this.props.onModalToggle(e); if (this.props.onOK) { this.props.onOK(e); } }}
                                    size="small"
                                    domID="modal-confirmation-confirm-button"
                                />
                            </ButtonRow>
                    }
                    </ModalBody>
                </Modal>
        );
    }
}
