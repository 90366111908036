import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as UserMaintenanceUI from './ui/UserMaintenanceUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type IUserMaintenanceState = ICrudComponentState<MCARMUser, UserMaintenanceUI.IUserMaintenanceUIState>;

export const actionCreators = createCrudComponentActions<UserMaintenanceUI.ActionCreators>(UserMaintenanceUI.actionCreators);
export type IUserMaintenanceActions = typeof actionCreators;
export type IUserMaintenanceActionProps = ICrudComponentActionProps<IUserMaintenanceActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiUsers, UserMaintenanceUI.reducer);
export const reducer = combineReducers<IUserMaintenanceState>(reducers);

export const validationCallback: ValidationCallback<MCARMUser> = (crud: any): crud is MCARMUser => {
    let testCrud: MCARMUser = crud as MCARMUser;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCARMUser");
    if (validShape) {
        if (testCrud.UserMaintenanceInfo?.Users as any === "") {
            testCrud.UserMaintenanceInfo.Users = { User: new Array<MCUser>() };
        }
        if (testCrud.UserMaintenanceInfo.Users &&
            !Array.isArray(testCrud.UserMaintenanceInfo.Users.User)) {
            let arr = new Array<MCUser>();
            arr.push(JSON.parse(JSON.stringify(testCrud.UserMaintenanceInfo.Users.User)));
            testCrud.UserMaintenanceInfo.Users.User = arr;
        }
    }
    return validShape;
}