import * as React from 'react';
import { connect } from 'react-redux';
import { ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { Input, Button, Tabs } from 'ui-core';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { SelectComponent } from '../../common/SelectComponent';
import { PmSearchRequest, PhysicianInfoList, getBasicSearchRequest, DEFAULT_PHYSICIAN_INFO, PhysicianInfo } from
    '@store/ui/PhysicianMaintenanceUI';
import { ContentWrapper, RowWrapper, SelectButtons, BeforeWrapper, ToolTip } from './PhysicianStyles';
import styled from 'styled-components';
 
import { ArmTabContainer } from '@common/component-styles';
const Spacer = styled.div`
    width: 1rem;
`;

const HideOverflow = styled.div`
    overflow-x: hidden !important;
`;

/**
 *  A simple object that encapsulates a starting search point and an optional
 *  upper limit.
 */
interface FromAndTo {
    from: string,
    to?: string,
}

/**
 * An object encapsulating all of the from/to options to search on.
 */
interface SearchControlValues {
    lastName?: FromAndTo,
    firstName?: FromAndTo,
    taxId?: FromAndTo,
    keyNo?: FromAndTo,
    physicianNo?: FromAndTo,
}

interface ISearchComponentProps {
    physicians: PhysicianInfoList,
    selectedPhysician: PhysicianInfo,
    physicianCount: number,
    lobCount: number,
    physicianCountLimit: number,
    selectedTab: number,
    selectTab(tabId: number): void,
    searchPhysicians(request: PmSearchRequest): void,
    selectPhysician(internalId: string): void,
}

interface ISearchComponentState {
    currentSearchParameters: SearchControlValues,
    showCountMessage: boolean,
    resultsTabSearch: string,
}

export const DEFAULT_STATE: ISearchComponentState = {
    currentSearchParameters: {},
    showCountMessage: false,
    resultsTabSearch: "",
}

/**
 * Checks and fixes incomplete FromAndTo fields in the Search Tab Box.
 * 
 * @param fieldPair The FromAndTo object being checked.
 */
function fixBlankFromOrTo(fieldPair: FromAndTo | undefined) {
    if (!fieldPair) return;
    const isFromPopulated: boolean = Boolean(fieldPair.from.trim());
    const isToPopulated: boolean = Boolean(fieldPair.to?.trim());
    if (isFromPopulated !== isToPopulated)
        fieldPair.to = fieldPair?.from ?? "";
}

// Type combining both the ui reducer state props and the local component props.
export type SearchTabProps = ISearchComponentProps;

export class PhysicianSearchTabBox extends React.Component<SearchTabProps, ISearchComponentState>
{
    constructor(props: SearchTabProps) {
        super(props);
        this.state = { ...DEFAULT_STATE, showCountMessage: !Boolean(props.selectedTab) };
        this.onClickClear = this.onClickClear.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
    }

    /**
     * On search button click, this method updates the search parameter values appropriately,
     * creates the search request, passes the request to the function in the props, and sets the state
     * with the updated search values.
     */
    public onClickSearch(): void {
        const finalSearchValues: SearchControlValues = this.updateSearchParams();
        const searchRequest: PmSearchRequest = this.createSearchRequest(finalSearchValues);
        // Set the state to use the updated search values, then call the searchPhysicians function
        // in the props. Also don't forget to clear out the Results search in local state.
        this.setState
        (
            {
                showCountMessage: false,
                resultsTabSearch: "",
                currentSearchParameters: {
                    ...finalSearchValues
                },
            },
            () => { this.props.searchPhysicians(searchRequest); }
        );
    }

    /**
     * Creates a PmSearchRequest that can be passed to a MasterCRUD search call to perform a 
     * Physician Maintenance Search.
     * @param searchParameters
     */
    private createSearchRequest(searchParameters: SearchControlValues): PmSearchRequest {
        // Initialize a search request with our default parameters added.
        const searchRequest: PmSearchRequest = getBasicSearchRequest(this.props.physicianCountLimit);

        // Add any optional search parameters in searchParameters if the FROM criteria
        // is populated.
        if (searchParameters?.lastName?.from) {
            searchRequest.Param = searchRequest.Param.concat
            (
                [
                    { Name: "@chvLastNameFrom", Value: searchParameters.lastName.from },
                    { Name: "@chvLastNameTo", Value: searchParameters?.lastName?.to ?? "" }
                ]);
        }

        if (searchParameters?.firstName?.from) {
            searchRequest.Param = searchRequest.Param.concat
            (
                [
                    { Name: "@chvFirstNameFrom", Value: searchParameters.firstName.from },
                    { Name: "@chvFirstNameTo", Value: searchParameters?.firstName?.to ?? "" }
                ]);
        }

        if (searchParameters?.taxId?.from) {
            searchRequest.Param = searchRequest.Param.concat
            (
                [
                    { Name: "@chvTaxIDFrom", Value: searchParameters.taxId.from },
                    { Name: "@chvTaxIDTo", Value: searchParameters?.taxId?.to ?? "" }
                ]);
        }

        if (searchParameters?.keyNo?.from) {
            searchRequest.Param = searchRequest.Param.concat
            (
                [
                    { Name: "@chvKeyNoFrom", Value: searchParameters.keyNo.from },
                    { Name: "@chvKeyNoTo", Value: searchParameters?.keyNo?.to ?? "" }
                ]);
        }

        if (searchParameters?.physicianNo?.from) {
            searchRequest.Param = searchRequest.Param.concat
            (
                [
                    { Name: "@chvPhysicianNoFrom", Value: searchParameters.physicianNo.from },
                    { Name: "@chvPhysicianNoTo", Value: searchParameters?.physicianNo?.to ?? "" }
                ]);
        }

        return searchRequest;
    }

    /**
     * Updates all the "to" properties in the search parameters to either the "from" value
     * (if the "to" is blank and the "from" is populated) or to an empty string (if the "to"
     * value is populated and the "from" value is blank).
     * 
     * @returns {SearchControlValues} The updated search parameter object.
     */
    private updateSearchParams(): SearchControlValues {

        // Get a copy of the current search parameters.
        const searchParams: SearchControlValues = { ...this.state.currentSearchParameters };

        //Check the various search params and ensure they are properly populated.
        fixBlankFromOrTo(searchParams.lastName);
        fixBlankFromOrTo(searchParams.firstName);
        fixBlankFromOrTo(searchParams.taxId);
        fixBlankFromOrTo(searchParams.keyNo);
        fixBlankFromOrTo(searchParams.physicianNo);

        return searchParams;
    }

    /**
     * Clears out all of the fields in the Search box.
     */
    public onClickClear(): void {
        this.setState({ currentSearchParameters: {}, showCountMessage: false });
    }

    /**
     * Takes in the current search string in the Results search input and sees if there are
     * any physician display names that start with the value. If not, defaults to the Physician Info
     * default which should be the - Add - entry. Method then looks up the associated internal id, 
     * records the string of text for preservation purposes, and sets the selected physician to the 
     * target physician. 
     * 
     * @param e 
     * {React.ChangeEvent<HTMLInputElement>} The event fired from an OnKeyUp change in the results search box.
     */
    public onChangeResultsSearch(e: React.ChangeEvent<HTMLInputElement>): void {

        // Grab any text present.
        const searchText: string = e?.target?.value;

        // If we do not have a valid start text, we need to select the default value.
        if (!Boolean(searchText)) {

            this.setState(
                { resultsTabSearch: searchText },
                () => this.props.selectPhysician(DEFAULT_PHYSICIAN_INFO.internalId));
            return;
        }

        // Get the current starting text for the results search input. If it is undefined
        // or null, default to an empty string.
        const upperSearchText: string = searchText.toUpperCase() ?? "";

        // Initialize an internal ID using the -Add- option as the default option.
        let selectedInternalId: string = "";

        // Try and find the first physician whose displayName starts with the 
        // start text.
        let selectedPhysician = this.props.physicians.physicians.find
        (
            (p) => {
                return p.displayName.toUpperCase().startsWith(upperSearchText);
            });

        // If we found a physician, we need to select them.
        if (selectedPhysician) {

            // Get the internal id for the selected physician. If for some reason the physician does not
            // have an internal id, use the default value as a fallback.
            selectedInternalId = selectedPhysician?.internalId ?? DEFAULT_PHYSICIAN_INFO.internalId;

            this.setState(
                { resultsTabSearch: searchText },
                () => this.props.selectPhysician(selectedInternalId));
        }

        // If we did not find a valid physician, we just need to track the search text.
        else {
            // Track the start text to preserve it if the user changes tabs, then pass the id up.
            this.setState({ resultsTabSearch: searchText });
        }
    }

    public render() {
        const searchMessage: string = `Add a new physician or search any of your ${this.props.physicianCount} existing
        physicians(${this.props.lobCount} IDs)`;
        const tabs = [{ label: 'Search', domID: 'id-Search' }, { label: 'Results', domID: 'id-Results' }];
        return (<DialogFieldset id='physicians-fieldset'>
                    <DialogLegend>Physicians</DialogLegend>
            <RowWrapper>
                <ArmTabContainer tabs={ tabs } id="ArmTabContainer">
                    <Tabs
                        tabs={tabs }
                        onTabSelect={(e: React.MouseEvent<HTMLButtonElement>, selObj: any) => {
                        if (this.state.showCountMessage) {
                                this.setState
                                    (
                                        {
                                            showCountMessage: false
                                        },
                                        () => this.props.selectTab(selObj.selectedTabIndex)
                                    );
                            }
                            else {
                                this.props.selectTab(selObj.selectedTabIndex);
                            }
                        }}
                        selectedTabIndex={this.props.selectedTab}
                        initialTab={this.props.selectedTab}
                    />
                    </ArmTabContainer>
                    </RowWrapper>
                    {this.props.selectedTab === 0
                        ? <ContentWrapper>
                              <ContentColumnWrapper>
                                  {this.state.showCountMessage
                            && //Conditional render of the message if we need to show it.
                            <RowWrapper>
                                {searchMessage}
                            </RowWrapper>
                        }
                              </ContentColumnWrapper>
                              <ContentColumnWrapper>
                                  <ContentRowWrapper>
                                      <RowWrapper>
                                          <Input
                                              domID="search-panel-last-name-from"
                                              className="text-input"
                                              label="Last Name:"
                                              maxLength={35}
                                              width='100%'
                                    initialValue={this.state?.currentSearchParameters?.lastName?.from ?? ""}
                                    autoComplete="off"
                                              onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.setState
(
    {
        currentSearchParameters: {
            ...this.state.currentSearchParameters,
            lastName: {
                from: e.target.value.trim(),
                to: this.state?.currentSearchParameters?.lastName?.to ?? ""
            }
        }
    })}/>
                            </RowWrapper>
                            <Spacer />
                                      <RowWrapper>
                                          <Input
                                              domID="search-panel-last-name-to"
                                              className="text-input"
                                              label="Thru:"
                                              maxLength={35}
                                    width='100%'
                                    autoComplete="off"
                                              initialValue={this.state?.currentSearchParameters?.lastName?.to ?? ""}
                                              onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.setState
                                        (
                                            {
                                                currentSearchParameters: {
                                                    ...this.state.currentSearchParameters,
                                                    lastName: {
                                                        from: this.state.currentSearchParameters.lastName?.from ?? "",
                                                        to: e.target.value?.trim(),
                                                    }
                                                }
                                            })}/>
                                      </RowWrapper>
                                  </ContentRowWrapper>
                              </ContentColumnWrapper>
                              <ContentColumnWrapper>
                                  <ContentRowWrapper >
                                      <RowWrapper>
                                          <Input
                                              domID="search-panel-first-name-from"
                                              className="text-input"
                                              label="First Name:"
                                              maxLength={25}
                                    width='200px'
                                    autoComplete="off"
                                              initialValue={this.state?.currentSearchParameters?.firstName?.from ?? ""}
                                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.setState
                                        (
                                            {
                                                currentSearchParameters: {
                                                    ...this.state.currentSearchParameters,
                                                    firstName: {
                                                        from: e.target.value?.trim(),
                                                        to: this.state?.currentSearchParameters?.firstName?.to ?? ""
                                                    }
                                                }
                                            })
                                    }/>
                            </RowWrapper>
                            <Spacer />
                                      <RowWrapper>
                                          <Input
                                              domID="search-panel-first-name-to"
                                              className="text-input"
                                    label="Thru:"
                                    autoComplete="off"
                                              maxLength={25}
                                              width='200px'
                                              initialValue={this.state?.currentSearchParameters?.firstName?.to ?? ""}
                                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.setState
                                        (
                                            {
                                                currentSearchParameters: {
                                                    ...this.state.currentSearchParameters,
                                                    firstName: {
                                                        from: this.state?.currentSearchParameters?.firstName?.from ?? "",
                                                        to: e.target.value?.trim()
                                                    }
                                                }
                                            })}/>
                                      </RowWrapper>
                                  </ContentRowWrapper>
                              </ContentColumnWrapper>
                              <ContentColumnWrapper>
                                  <ContentRowWrapper >
                                      <RowWrapper>
                                          <Input
                                              domID="search-panel-tax-id-from"
                                              className="text-input"
                                              label="Tax ID:"
                                    maxLength={15}
                                    autoComplete="off"
                                              width='200px'
                                              initialValue={this.state?.currentSearchParameters?.taxId?.from ?? ""}
                                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.setState
                                        (
                                            {
                                                currentSearchParameters: {
                                                    ...this.state.currentSearchParameters,
                                                    taxId: {
                                                        to: this.state?.currentSearchParameters?.taxId?.to ?? "",
                                                        from: e.target.value?.trim()
                                                    }
                                                }
                                            })}/>
                            </RowWrapper>
                            <Spacer />
                                      <RowWrapper>
                                          <Input
                                              domID="search-panel-tax-id-to"
                                              className="text-input"
                                              label="Thru:"
                                    maxLength={15}
                                    autoComplete="off"
                                              width='200px'
                                              initialValue={this.state?.currentSearchParameters?.taxId?.to ?? ""}
                                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.setState
                                        (
                                            {
                                                currentSearchParameters: {
                                                    ...this.state.currentSearchParameters,
                                                    taxId: {
                                                        from: this.state?.currentSearchParameters?.taxId?.from ?? "",
                                                        to: e.target.value?.trim(),
                                                    }
                                                }
                                            })}/>
                                      </RowWrapper>
                                  </ContentRowWrapper>
                              </ContentColumnWrapper>
                              <ContentColumnWrapper>
                                  <ContentRowWrapper>
                                      <RowWrapper>
                                          <Input
                                              label="Key No:"
                                              domID="search-panel-key-no-from"
                                              className="text-input"
                                    maxLength={20}
                                    autoComplete="off"
                                              width='200px'
                                              initialValue={this.state?.currentSearchParameters?.keyNo?.from ?? ""}
                                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.setState
                                        (
                                            {
                                                currentSearchParameters: {
                                                    ...this.state.currentSearchParameters,
                                                    keyNo: {
                                                        from: e.target.value?.trim(),
                                                        to: this.state?.currentSearchParameters?.keyNo?.to ?? "",
                                                    }
                                                }
                                            })}/>
                            </RowWrapper>
                            <Spacer />
                                      <RowWrapper>
                                          <Input
                                              domID="search-panel-key-no-to"
                                              className="text-input"
                                              label="Thru:"
                                    maxLength={20}
                                    autoComplete="off"
                                              width='200px'
                                              initialValue={this.state?.currentSearchParameters?.keyNo?.to ?? ""}
                                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.setState
                                        (
                                            {
                                                currentSearchParameters: {
                                                    ...this.state.currentSearchParameters,
                                                    keyNo: {
                                                        to: e.target.value?.trim(),
                                                        from: this.state?.currentSearchParameters?.keyNo?.from ?? "",
                                                    }
                                                }
                                            })}/>
                                      </RowWrapper>
                                  </ContentRowWrapper>
                              </ContentColumnWrapper>
                              <ContentColumnWrapper>
                                  <ContentRowWrapper>
                                      <RowWrapper>
                                          <Input
                                              label="Physician No:"
                                              domID="search-panel-physician-no-from"
                                              className="text-input"
                                    maxLength={38}
                                    autoComplete="off"
                                              width='200px'
                                              initialValue={this.state?.currentSearchParameters?.physicianNo?.from ?? ""}
                                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.setState
                                        (
                                            {
                                                currentSearchParameters: {
                                                    ...this.state.currentSearchParameters,
                                                    physicianNo: {
                                                        from: e.target.value?.trim(),
                                                        to: this.state?.currentSearchParameters?.physicianNo?.to ?? "",
                                                    }
                                                }
                                            })}/>
                            </RowWrapper>
                            <Spacer />
                                      <RowWrapper>
                                          <Input
                                              domID="search-panel-physician-no-to"
                                              className="text-input"
                                              label="Thru:"
                                    maxLength={38}
                                    autoComplete="off"
                                              width='200px'
                                              initialValue={this.state?.currentSearchParameters?.physicianNo?.to ?? ""}
                                              onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.setState
                                        (
                                            {
                                                currentSearchParameters: {
                                                    ...this.state.currentSearchParameters,
                                                    physicianNo: {
                                                        to: e.target.value?.trim(),
                                                        from: this.state?.currentSearchParameters?.physicianNo?.from ?? "",
                                                    }
                                                }
                                            })}/>
                                      </RowWrapper>
                                  </ContentRowWrapper>
                            <SelectButtons>
                                <ToolTip>
                                    <Button
                                        domID="pm-search-button"
                                        name="Search"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={this.onClickSearch}
                                    />
                                    <span className="text">Search</span>
                                </ToolTip>
                                <ToolTip>
                                    <Button
                                        domID="pm-clear-button"
                                        name="Clear"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={this.onClickClear}
                                    />
                                    <span className="text">Clear</span>
                                </ToolTip>
                            </SelectButtons>
                              </ContentColumnWrapper>
                              <ContentColumnWrapper>
                                  <BeforeWrapper>
                                      Before beginning a second search, click OK to save changes.
                        </BeforeWrapper>
                              </ContentColumnWrapper>
                          </ContentWrapper>
                        : <ContentWrapper>
                              <ContentRowWrapper>
                                  <ContentColumnWrapper >
                                      <Input
                                          domID="searchresults-textsearch"
                                          className="text-input"
                                          autoComplete="off"
                                          onChange={this.onChangeResultsSearch.bind(this)}
                                          initialValue={this.state.resultsTabSearch}/>
                                  </ContentColumnWrapper>
                              </ContentRowWrapper>
                              <ContentColumnWrapper>
                        <RowWrapper>
                            <HideOverflow>
                                      <SelectComponent
                                title='add-a-new-physician'
                                size={6}
                                width='330px'
                                height='500px'
                                          onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    this.props.selectPhysician(e.target.value)
                                }}
                                          optionFields={{
                                    value: "internalId",
                                    text: "displayName",
                                }}
                                          records={this.props.physicians.physicians}
                                          disabled={false}
                                    selectedValue={this.props.selectedPhysician.internalId} />
                                </HideOverflow>
                                  </RowWrapper>
                              </ContentColumnWrapper>
                          </ContentWrapper>

                    }
                </DialogFieldset>);
    }
}