import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IClaimNoteTypeUIState {
    selectedNoteType: ISelectNoteTypeData;
    noteTypeName: string;
    noteTypeDescription: string;
    noteTypeNameError?: string;
    noteTypeDescriptionError?: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectNoteTypeData {
    index: number;
    value: string;
    text: string;
};

export const defaultNoteType: ISelectNoteTypeData = {
    index: 0,
    value: '',
    text: '',
};

export interface ISelectNoteType extends ICrudActionData<MCClaimNoteType, ISelectNoteTypeData>{ }

export interface IModifyNoteType extends ICrudActionData<MCClaimNoteType, MCNoteType> { }

export const actionCreators = {
    selectNoteType: (selectInfo: ISelectNoteType) => createDataAction('SELECT_NOTE_TYPE', selectInfo),
    updateNoteTypeName: (noteTypeName: string) => createDataAction('UPDATE_NOTE_TYPE_NAME', noteTypeName),
    updateNoteTypeDescription: (updateNoteTypeDescription: string) => createDataAction('UPDATE_NOTE_TYPE_DESCRIPTION', updateNoteTypeDescription),
    addNoteType: (updateInfo: IModifyNoteType) => createDataAction('ADD_NOTE_TYPE', updateInfo),
    updateNoteType: (updateInfo: IModifyNoteType) => createDataAction('UPDATE_NOTE_TYPE', updateInfo),
    removeNoteType: (updateInfo: IModifyNoteType) => createDataAction('REMOVE_NOTE_TYPE', updateInfo),
    restoreNoteType: (noteType: IModifyNoteType) => createDataAction('RESTORE_NOTE_TYPE', noteType),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IClaimNoteTypeUIState = {
    selectedNoteType: defaultNoteType,
    noteTypeName: '',
    noteTypeDescription: '',
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IClaimNoteTypeUIState, KnownActions> = (state: IClaimNoteTypeUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_NOTE_TYPE':
            {
                if (action.data.masterCrud) {
                    //uiData.index is the UI-based index from the Select component; it may not be a 1:1 match to the index of the array of items in CRUD since some might have @Deleted = 1.
                    //Need to look up by text since new items could have been added with an @ID not yet set.
                    //var noteTypeData = action.data.masterCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[action.data.uiData.index - 1];
                    let noteTypeData = action.data.masterCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.find(existingNoteType => existingNoteType["@Name"] === action.data.uiData.text);
                    return {
                        ...state,
                        selectedNoteType: action.data.uiData,
                        noteTypeName: noteTypeData ? noteTypeData["@Name"] || '' : '',
                        noteTypeDescription: noteTypeData ? noteTypeData["@Description"] || '' : '',
                        noteTypeNameError: undefined,
                        noteTypeDescriptionError: undefined,
                    }
                }
                break;
            }
            case 'UPDATE_NOTE_TYPE_NAME':
                return {
                    ...state,
                    noteTypeName: action.data,
                }
                break;
            case 'UPDATE_NOTE_TYPE_DESCRIPTION':
                
                return {
                    ...state,
                    noteTypeDescription: action.data,
                }
                break;

            case 'ADD_NOTE_TYPE':
            case 'UPDATE_NOTE_TYPE':
            {
                if (action.data.masterCrud) {
                    let selectInfo: ISelectNoteTypeData = state.selectedNoteType;
                    let noteType: MCNoteType = action.data.uiData;
                    let newStateData: IClaimNoteTypeUIState = { ...state };
                    newStateData.noteTypeNameError = undefined;
                    newStateData.noteTypeDescriptionError = undefined;
                    if (noteType["@Name"] === '') {
                        newStateData.noteTypeNameError = 'Please enter a note type name.';
                        return newStateData;
                    }
                    if (noteType["@Description"] === '') {
                        newStateData.noteTypeDescriptionError = 'Please enter a description.';
                        return newStateData;
                    }
                    if (noteType["@Description"].length > 256) {
                        newStateData.noteTypeDescriptionError = 'Please enter a shorter description.';
                        return newStateData;
                    }
                    var duplicate = action.data.masterCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.find(existingNoteType => ((existingNoteType["@Name"] === noteType["@Name"]) && (existingNoteType["@ID"] !== noteType["@ID"])));
                    if (duplicate) {
                        if (duplicate["@Deleted"] === '1') {
                            // Should already be handled by the component, this is just a failsafe.
                            newStateData.noteTypeNameError = "A deleted note type by that name already exists.";
                            return newStateData;
                        }
                        else {
                            newStateData.noteTypeNameError = "A note type by that name already exists. Please type in a different name.";
                            return newStateData;
                        }
                    }
                    if (!(newStateData.noteTypeNameError || newStateData.noteTypeDescriptionError))
                    {
                        if (action.type == 'ADD_NOTE_TYPE')
                        {
                            action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.push(noteType);
                            newStateData.selectedNoteType = {
                                index: -1,  // Does not matter; items are selected by name.
                                value: noteType["@ID"],
                                text: noteType["@Name"]
                            };
                        }
                        else
                        {
                            let selectedNoteType = action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.find(existingNoteType => existingNoteType["@Name"] === selectInfo.text);
                            if (selectedNoteType) {
                                selectedNoteType["@Name"] = noteType["@Name"];
                                selectedNoteType["@Description"] = noteType["@Description"];
                            }
                            else
                            {
                                return { ...state };
                            }
                        }
                        newStateData.selectedNoteType = {
                            index: -1,  // Does not matter; items are selected by name.
                            value: noteType["@ID"],
                            text: noteType["@Name"]
                        };
                        newStateData.noteTypeName = noteType["@Name"];
                        newStateData.noteTypeDescription = noteType["@Description"];
                    }
                    return newStateData;
                }
                break;
            }
            case 'REMOVE_NOTE_TYPE':
            {
                if (action.data.masterCrud) {
                    let selectInfo: ISelectNoteTypeData = state.selectedNoteType;
                    var updatedNoteType: MCNoteType = action.data.uiData;
                    let newIndex: number = selectInfo.index;
                    let uiIndex: number = 0;
                    let newCrudIndex: number | undefined = undefined;
                    let removed: boolean = false;
                    let newIndexSet: boolean = false;
                    action.data.masterCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.forEach((existingNoteType, crudIndex) => {
                        //state.selectedNoteType
                        if (newIndexSet)
                        {
                            return;
                        }
                        if (existingNoteType["@Name"] === selectInfo.text) {
                            existingNoteType["@Deleted"] = '1';
                            removed = true;
                        }
                        else if (existingNoteType["@Deleted"] !== '1') {
                            newCrudIndex = crudIndex;
                            uiIndex++;
                            if (removed) {
                                newIndexSet = true;
                            }
                        }
                        
                    });
                    return {
                        ...state,
                        selectedNoteType: {
                            index: uiIndex,
                            value: newCrudIndex ? action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[newCrudIndex]["@ID"] : '',
                            text: newCrudIndex ? action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[newCrudIndex]["@Name"] : '',
                        },
                        noteTypeName: newCrudIndex ? action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[newCrudIndex]["@Name"] : '',
                        noteTypeDescription: newCrudIndex ? action.data.masterCrud!.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType[newCrudIndex]["@Description"] : '',
                    };
                }
                break;
            }
            case 'RESTORE_NOTE_TYPE':
                if (action.data.masterCrud) {
                    let uiIndex: number = 0;
                    var restoreNoteType = action.data.masterCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.find((existingNoteType, index) => {
                        if (existingNoteType["@Deleted"] !== '1') {
                            uiIndex++;
                        }
                        return (existingNoteType["@Name"] === action.data.uiData["@Name"]);
                    });
                    uiIndex++;  // Increment for the existing note type (above loop won't find it because it's still '1'), which will be the new UI index.
                    if (restoreNoteType)
                    {
                        restoreNoteType["@Deleted"] = '0';
                        return {
                            ...state,
                            selectedNoteType: {
                                index: uiIndex,
                                value: restoreNoteType["@ID"],
                                text: restoreNoteType["@Name"],
                            },
                            noteTypeName: restoreNoteType["@Name"],
                            noteTypeDescription: restoreNoteType["@Description"],
                        };
                    }
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
