import * as React from 'react';
import { Route, Redirect, RouteProps } from 'react-router';
//import { isAuthenticated, timeouts, logout } from 'auth-management';
import SilentRefreshModal from './SilentRefreshModal';
import { LoadingIndicator } from 'ui-core';
import { URLs } from '@commonDevResources/constants';
import { AuthCheck } from '@scripts/session/Security';
import styled from 'styled-components';
import { getRawToken } from '@scripts/session/SecurityToken';
import { AssuranceMenu } from './AssuranceMenu';
import FooterComponent from './Footer';

type TPrivateStatusTypes = 'INQUIRY' | 'AUTHORIZED' | 'DENIED' | 'TIMEOUT';

interface IPrivateRouteState {
    status: TPrivateStatusTypes;
    showModal: boolean;
    // change this timer to choose how many minutes before logout the user will be prompted
    timer: number;
}

const INITIAL_STATE: IPrivateRouteState = {
    status: 'INQUIRY',
    showModal: false,
    // change this timer to choose how many minutes before logout the user will be prompted
    timer: 2,
};

interface IPrivateRouteProps extends RouteProps {
    menuName: string;
    redirectUrl?: string;
    allowChrome?: boolean;  // for LegacyRoute
    bitCreate?: number;
    bitEdit?: number;
    bitPrint?: number;
    bitView?: number;
    bitViewDownloads?: number;
    bitDelete?: number;
    bitRestrictView?: number;
    bitViewClientSet?: number;
    bitEditClientSet?: number;
    bitBetaPreview?: number;
    bitCheck1?: number;
    bitCheck2?: number;
    bitCheck3?: number;
    bitNcsIsEnabled?: boolean;
    bitNcsSkipPrompt?: boolean;
    bitNcsPromptText?: string;
    bitNcsListType?: string;
    path?: string;
    bitList?: Array<number>;
    bitViewList?: Array<number>;
    // View Reports Security Bits
    bitViewClaimReports?: number;
    bitViewRemitReports?: number;
    bitViewSubmissionReports?: number;
    bitViewSystemReports?: number;
    bitViewManagementReports?: number;
    bitViewMiscellaneousReports?: number;
    bitViewCustomReports?: number;
    bitViewOutsourceReports?: number;
    redirectNativeUrl?: string;
    legacyPath?: string;
    displayTestMode?: boolean;
}

const LoadingWrapper = styled.div`
    position: relative;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    text-align: left;
    width: 100%;
    height: 100%;
`;

export class PrivateRoute extends Route<IPrivateRouteProps> {
    //let ncsIsEnabled = '';
    static defaultProps = {
        redirectUrl: '/Transactions/Logon',
        redirectNativeUrl: '/LandingPage',
        menuName: 'Full',
        displayTestMode: false,
    }

    protected securityTimer?: number;
    protected warningTimer?: number;

    constructor(props: IPrivateRouteProps, context: any) {
        super(props, context);
        this.state = {
            ...INITIAL_STATE,
        };
    }

    componentDidMount() {
        this.performCheckToken();
        if (super.componentDidMount) super.componentDidMount();
    }

    componentDidUpdate(prevProps: Readonly<IPrivateRouteProps>, prevState: Readonly<any>, snapshot?: any) {
        // New route, verify our token is still valid (logging in elsewhere would invalidate it, for instance)
        // But is this really necessary...
        //this.checkToken();
        if (super.componentDidUpdate) super.componentDidUpdate(prevProps, prevState, snapshot);
    }

    componentWillUnmount() {
        this.clearSecurityTimers();
        if (super.componentWillUnmount) super.componentWillUnmount();
    }

    performCheckToken() {
        const uri = `${URLs.api}/api/data/securityInfo/logonPageAccessStatus?path=${escape(this.props.path as string)}`;
        return fetch(uri,
            {
                method: 'GET',
                headers: {
                    'Accept': 'text/plain',
                    'Authorization': `${getRawToken()}`
                }
            }).then(async response => {
                try {
                    const data: any = await response.json();
                    if (response.status === 200) {
                        this.setAuthorized('AUTHORIZED', data.duration);
                    } else if (response.status === 400) {
                        this.setAuthorized('NOTFOUND', data.duration);
                    } else if (response.status === 403) {
                        this.setAuthorized('NOACCESS', data.duration);
                    } else {
                        //user attempted to switch to a CIAM-enforced client from legacy login
                        if(data.ciamPrompt && data.ciamPrompt === 1)
                            sessionStorage.setItem('ciamPrompt', '2');
                        
                        this.setState({ showModal: false, status: 'DENIED' });
                    }
                }
                catch
                {
                    this.setState({ showModal: false, status: 'DENIED' });
                }
            });
    }

    cancelWarning() {
        //console.log('Cancel warning');
        this.clearWarningTimer();
        this.setState({ showModal: false, status: 'TIMEOUT' });
    }

    renewSession() {
        //console.log('Renew Session');
        this.clearSecurityTimers();
        this.performCheckToken();
        //this.setState({ showModal: false});
    }

    setAuthorized(status: string, duration: number) {
        if (this.state.status !== status || this.state.showModal) {
            //console.log('Authorized');
            this.setState({
                status: status,
                showModal: false,
                timer: duration <= 150000 ? .5 : this.state.timer // 30s expiry warning countdown on shorter durations (ex. reset pwd)
            });
            this.startTimer(duration);
        }
    }

    shouldRedrawMenu() {
        const {
            location
        } = this.props;
        return location && location.state && location.state.redrawMenu && location.state.redrawMenu;
    }

    startTimer = (duration: number) => {
        this.clearSecurityTimers();
        //let token = getSecurityToken();
        //console.log('getSecurityToken - Duration: ' + duration);
        this.securityTimer = setTimeout(() => this.onSecurityTimeout(), duration);
        this.warningTimer = setTimeout(() => this.onWarningTimeout(), duration - this.state.timer * 60 * 1000);
        //this.securityTimer = setTimeout(() => this.onSecurityTimeout(), 130000);
        //this.warningTimer = setTimeout(() => this.onWarningTimeout(), 130000 - this.state.timer * 60 * 1000);
        // This will try to get storage.get('token'), which, if null, will throw an error.
        // TODO: Comment out for now, pending CIAM integration.
        /*
        timeouts.setAuthTimeouts(
            () => { this.setState({ showModal: true }); },
            logout,
            this.state.timer);
            */
    }

    clearSecurityTimers() {
        this.clearWarningTimer();
        this.clearSecurityTimer();
    }

    clearWarningTimer() {
        window.clearTimeout(this.warningTimer);
    }

    clearSecurityTimer() {
        //timeouts.clearAuthTimeouts();
        window.clearTimeout(this.securityTimer);
    }

    onWarningTimeout() {
        this.setState({ showModal: true });
    }

    onSecurityTimeout() {
        console.warn('Session timeout.');
    }

    /*
        sync version...

        NCS:
        {
        'sessionId': '682eb1a6-8131-451d-86db-8e022251d86b',
        'enabled': true,
        'skipPrompt': false,
        'prompt': 'Do you want to update all enterprise sites?',
        'listType': null (1 or null?)
        }

        TBD: 
            - need to add others of this type when the page is added, currently only GroupMaintenace is being checked...
              this is OK since it's hardcoded in GroupMaintance, now it's just checked here...
    */
    ncsCheck() {
        let requestPath = '/api/data/ncs/settings';
        let methodOf = 'get';
        if (this.props.path && this.props.path.indexOf('GroupMaintenance') >= 0) {
            requestPath += '/1';
            methodOf = 'post';
        }

        var xhr = new XMLHttpRequest();
        xhr.open(methodOf, URLs.api + requestPath, false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send(null);

        if (xhr.status !== 200)
            return null;

        return JSON.parse(xhr.responseText);
    };


    renderRoute(props: any) {
        //return this.isAuthenticated() ? this.renderComponent(props) : this.renderRedirect(props);

        switch (this.state.status) {
            case 'INQUIRY':
                return this.renderInquiry();
            case 'NOTFOUND':
                return this.renderNotFound();
            case 'NOACCESS':
                return this.renderNoAccessRedirect();
            case 'AUTHORIZED':
                return this.renderComponent(props);
            case 'DENIED':
            case 'TIMEOUT':
            default:
                return this.renderRedirect();
        };
    }

    renderInquiry() {
        //return (<ModalLoadingDiv />); // Looks rather strange - screen is white with no content anyway, just display the indicator centered on the screen.
        return <LoadingWrapper><LoadingIndicator /></LoadingWrapper>;
    }

    renderComponent(props: any) {
        let canCreate = false;
        let canView = false;
        let canEdit = false;
        let canDelete = false;
        let canRestrictView = false;
        let canViewClientSet = false;
        let canEditClientSet = false;
        let canBetaPreview = false;
        let canSecCheck1 = false;
        let canSecCheck2 = false;
        let canSecCheck3 = false;
        let hasViewBit = false;
        let ncsIsEnabled = false;
        let ncsSkipPrompt = false;
        let ncsPromptText = 'Do you want to update all enterprise sites?';
        let ncsListType = '';
        let accessList = []; // each page will need to track their own index on this access array...

        // View Reports Security Bits
        let canViewClaimReports = false;
        let canViewRemitReports = false;
        let canViewSubmissionReports = false;
        let canViewSystemReports = false;
        let canViewManagementReports = false;
        let canViewMiscellaneousReports = false;
        let canViewCustomReports = false;
        let canViewOutsourceReports = false;

        let ncsData = this.ncsCheck();

        // TODO: Pass map of security necessary for the page to the component.
        //       Look it up here(api call) so that is done once for the whole page.
        //       See CheckToken also.
        if (this.props.bitCreate && this.props.bitCreate >= 0)
            canCreate = AuthCheck(this.props.bitCreate);

        // hopefully this is the start of a beautiful relationship between this code and the developer...
        if (this.props.bitList && this.props.bitList.length > 0) {
            for (let bitIdx = 0; bitIdx < this.props.bitList.length; bitIdx++) {
                let canDoIt = AuthCheck(this.props.bitList[bitIdx]);
                accessList.push(canDoIt); // transpile complete... :)
            }
        }

        if (this.props.bitViewList && this.props.bitViewList.length > 0) {
            for (let bitIdx = 0; bitIdx < this.props.bitViewList.length; bitIdx++) {
                let canDoIt = AuthCheck(this.props.bitViewList[bitIdx]);
                accessList.push(canDoIt);
                if (canDoIt) {
                    canView = true;
                }
            }
            hasViewBit = true;
        }

        // NOTE:  view claims bit is an oddball
        if (this.props.bitView === 0 || (this.props.bitView && this.props.bitView >= 0)) {
            canView = AuthCheck(this.props.bitView);
            hasViewBit = true;
            if (this.props.location?.pathname.toLowerCase() === '/remits/workinggroupdisplay') {
                canView = true;
                hasViewBit = false;
            }
        }
        if (this.props.bitEdit === 0 || (this.props.bitEdit && this.props.bitEdit >= 0))
            canEdit = AuthCheck(this.props.bitEdit);

        if (this.props.bitDelete && this.props.bitDelete >= 0)
            canDelete = AuthCheck(this.props.bitDelete);

        let canPrint = (this.props?.bitPrint || -1) >= 0 ? AuthCheck(this.props.bitPrint as number) : false;

        if (this.props.bitRestrictView && this.props.bitRestrictView >= 0)
            canRestrictView = AuthCheck(this.props.bitRestrictView);
        if (this.props.bitViewClientSet && this.props.bitViewClientSet >= 0)
            canViewClientSet = AuthCheck(this.props.bitViewClientSet);
        if (this.props.bitEditClientSet && this.props.bitEditClientSet >= 0)
            canEditClientSet = AuthCheck(this.props.bitEditClientSet);
        if (this.props.bitBetaPreview && this.props.bitBetaPreview >= 0)
            canBetaPreview = AuthCheck(this.props.bitBetaPreview);

        if (this.props.bitCheck1 && this.props.bitCheck1 >= 0)
            canSecCheck1 = AuthCheck(this.props.bitCheck1);
        if (this.props.bitCheck2 && this.props.bitCheck2 >= 0)
            canSecCheck2 = AuthCheck(this.props.bitCheck2);
        if (this.props.bitCheck3 && this.props.bitCheck3 >= 0)
            canSecCheck3 = AuthCheck(this.props.bitCheck3);

        // View Reports Security Bits
        if (this.props.bitViewClaimReports && this.props.bitViewClaimReports >= 0)
            canViewClaimReports = AuthCheck(this.props.bitViewClaimReports);

        if (this.props.bitViewRemitReports && this.props.bitViewRemitReports >= 0)
            canViewRemitReports = AuthCheck(this.props.bitViewRemitReports);

        if (this.props.bitViewSubmissionReports && this.props.bitViewSubmissionReports >= 0)
            canViewSubmissionReports = AuthCheck(this.props.bitViewSubmissionReports);

        if (this.props.bitViewSystemReports && this.props.bitViewSystemReports >= 0)
            canViewSystemReports = AuthCheck(this.props.bitViewSystemReports);

        if (this.props.bitViewManagementReports && this.props.bitViewManagementReports >= 0)
            canViewManagementReports = AuthCheck(this.props.bitViewManagementReports);

        if (this.props.bitViewMiscellaneousReports && this.props.bitViewMiscellaneousReports >= 0)
            canViewMiscellaneousReports = AuthCheck(this.props.bitViewMiscellaneousReports);

        if (this.props.bitViewCustomReports && this.props.bitViewCustomReports >= 0)
            canViewCustomReports = AuthCheck(this.props.bitViewCustomReports);

        if (this.props.bitViewOutsourceReports && this.props.bitViewOutsourceReports >= 0)
            canViewOutsourceReports = AuthCheck(this.props.bitViewOutsourceReports);

        const Component = this.props.component;
        if (Component != undefined && (hasViewBit == false || (hasViewBit == true && canView == true))) {
            // This is a bit of a hack as Component does not have these defined.
            let componentProps: any = {
                canCreate: (this.props.bitCreate && this.props.bitCreate >= 0) ? canCreate : undefined,
                canView: (this.props.bitView != undefined && this.props.bitView >= 0)
                    ? canView
                    : (this.props.bitViewList != undefined && this.props.bitViewList.length > 0 ? canView : undefined),
                canEdit: (this.props.bitEdit != undefined && this.props.bitEdit >= 0) ? canEdit : undefined,
                canDelete: (this.props.bitDelete && this.props.bitDelete >= 0) ? canDelete : undefined,
                canPrint: (this.props?.bitPrint || -1) >= 0 ? canPrint : undefined,
                canRestrictView: (this.props.bitRestrictView && this.props.bitRestrictView >= 0) ? canRestrictView : undefined,
                canViewClientSet: (this.props.bitViewClientSet && this.props.bitViewClientSet >= 0) ? canViewClientSet : undefined,
                canEditClientSet: (this.props.bitEditClientSet && this.props.bitEditClientSet >= 0) ? canEditClientSet : undefined,
                canBetaPreview: (this.props.bitBetaPreview && this.props.bitBetaPreview >= 0) ? canBetaPreview : undefined,
                canSecCheck1: (this.props.bitCheck1 && this.props.bitCheck1 >= 0) ? canSecCheck1 : undefined,
                canSecCheck2: (this.props.bitCheck2 && this.props.bitCheck2 >= 0) ? canSecCheck2 : undefined,
                canSecCheck3: (this.props.bitCheck3 && this.props.bitCheck3 >= 0) ? canSecCheck3 : undefined,
                ncsIsEnabled: ncsData ? ncsData.enabled : undefined,
                ncsSkipPrompt: ncsData ? ncsData.skipPrompt : undefined,
                ncsPromptText: ncsData ? ncsData.prompt : undefined,
                ncsListType: ncsData ? ncsData.listType : undefined,
                allowChrome: this.props.allowChrome,
                accessList: (this.props.bitList || this.props.bitViewList) ? accessList : undefined,
                ...(this.props.displayTestMode && this.props.displayTestMode == true) && { testMode: true },
                ////     testMode: (this.props.displayTestMode && this.props.displayTestMode == true) ? true : undefined,

                // View Reports Security Bits
                canViewClaimReports: (this.props.bitViewClaimReports && this.props.bitViewClaimReports >= 0) ? canViewClaimReports : undefined,
                canViewRemitReports: (this.props.bitViewRemitReports && this.props.bitViewRemitReports >= 0) ? canViewRemitReports : undefined,
                canViewSubmissionReports: (this.props.bitViewSubmissionReports && this.props.bitViewSubmissionReports >= 0) ? canViewSubmissionReports : undefined,
                canViewSystemReports: (this.props.bitViewSystemReports && this.props.bitViewSystemReports >= 0) ? canViewSystemReports : undefined,
                canViewManagementReports: (this.props.bitViewManagementReports && this.props.bitViewManagementReports >= 0) ? canViewManagementReports : undefined,
                canViewMiscellaneousReports: (this.props.bitViewMiscellaneousReports && this.props.bitViewMiscellaneousReports >= 0) ? canViewMiscellaneousReports : undefined,
                canViewCustomReports: (this.props.bitViewCustomReports && this.props.bitViewCustomReports >= 0) ? canViewCustomReports : undefined,
                canViewOutsourceReports: (this.props.bitViewOutsourceReports && this.props.bitViewOutsourceReports >= 0) ? canViewOutsourceReports : undefined,
            };

            var componentName = this.props.component?.displayName ? this.props.component.displayName : (this.props.component?.name ? this.props.component?.name : 'unknown');
            if (componentName === 'CfiRoute') {
                return (
                    <React.Fragment>
                        <Component
                            {...props}
                            {...componentProps}
                        />
                    </React.Fragment>
                );
            } else {
                var menuName = (this.props.displayTestMode && this.props.displayTestMode == true) ? 'TestMode' : this.props.menuName;

                if (this.shouldRedrawMenu()) {
                    // Need to get menus from database, not cache. The purpose of the key is to force the redraw ( and thus the RH.API call ) to happen.
                    return (
                        <React.Fragment key='redrawMenu'>
                            <AssuranceMenu {...props} Name='Menu' MenuUrl={URLs.api + '/api/data/Get' + menuName + 'Menu/Force'} Top='calc(${MASTHEAD_HEIGHT} +2)px' testMode={this.props.displayTestMode} />
                            <Component
                                {...props}
                                {...componentProps}
                            />
                            <FooterComponent />
                        </React.Fragment>
                    );
                }
                else {
                    return (
                        <React.Fragment>
                            <AssuranceMenu {...props} Name='Menu' MenuUrl={URLs.api + '/api/data/Get' + menuName + 'Menu'} Top='calc(${MASTHEAD_HEIGHT} +2)px' testMode={this.props.displayTestMode} />
                            <Component
                                {...props}
                                {...componentProps}
                            />
                            <FooterComponent />
                        </React.Fragment>
                    );
                }
            }


        }
        if (hasViewBit == true && canView == false) {
            //            sessionStorage.setItem('SecurityInfo', '');
            //     ClearSecuritySession();
            
            return this.renderRedirect();
        }
        return null;
    }

    renderRedirect() {
        return (<Redirect to={{ pathname: this.props.redirectUrl, state: { from: this.props.location } }} />);
    }

    renderNotFound() {
        sessionStorage.setItem('SystemErrorNotification', 'The page you are trying was not found in routeControl.json.');
        return (<Redirect to={{ pathname: this.props.redirectNativeUrl, state: { from: this.props.location } }} />);
    }

    renderNoAccessRedirect() {
        if (this.props.legacyPath)
            return (<Redirect to={{ pathname: this.props.legacyPath, state: { from: this.props.location } }} />);

        sessionStorage.setItem('SystemErrorNotification', 'The page you are trying to access is not currently supported by your browser.');
        return (<Redirect to={{ pathname: this.props.redirectNativeUrl, state: { from: this.props.location } }} />);
    }

    render() {
        let { component, redirectUrl, allowChrome, ...rest } = this.props;
        let silentRefreshModal = this.state.showModal ? <SilentRefreshModal resetTimer={() => this.renewSession()} timer={this.state.timer} closeModal={() => this.cancelWarning()} /> : null;
        return (
            <React.Fragment>
                <Route {...rest} render={(props: any) => this.renderRoute(props)} />
                {silentRefreshModal}
            </React.Fragment>
        )
    }
}

export default PrivateRoute;
