/*  Helpers for creating popups (that live outside the React world)
 */
export const ARMBasicStyles = `body {
                font-family: 'Core Sans C', 'Century Gothic', AppleGothic, sans-serif;
            }`;

export const getHTMLContents = (title: string, body:string, css: string = ARMBasicStyles): string => (`<html>
    <head>
        <title>${title}</title>
        <style>
            ${css}
        </style>
    </head>
    <body>${body}</body>
</html>`);