import { combineReducers, Reducer } from 'redux';
import * as DenialManagementUI from '@store/ui/DenialManagementUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
//import { keys } from 'ts-transformer-keys';
import { ValidateObject } from '@scripts/util/ValidationHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IDenialManagementUIState = ICrudComponentState<MCDMReasonCodes, DenialManagementUI.IDenialManagementUIState>;
export const actionCreators = createCrudComponentActions<DenialManagementUI.ActionCreators>(DenialManagementUI.actionCreators);
export type IDenialManagementActions = typeof actionCreators;
export type IDenialManagementActionProps = ICrudComponentActionProps<IDenialManagementActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiReasonCodes, DenialManagementUI.denialManagementUIReducer);
export const reducer = combineReducers<IDenialManagementUIState>(reducers);

export const validationCallback: ValidationCallback<MCDMReasonCodes> = (crud: any): crud is MCDMReasonCodes => {
    let testCrud: MCDMReasonCodes = crud as MCDMReasonCodes;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCDMReasonCodes");

    if (validShape) {
        const info = testCrud.ReasonCodeMaintenanceInfo;
        if (info.Groups as any === "") {
            let groups = {} as any;
            info.Groups = groups;
        }

        if (info.Groups.Group == undefined || info.Groups.Group as any === "") {
            let group = [] as any;
            info.Groups.Group = group;
        }

        if (info.Groups &&
            !Array.isArray(info.Groups.Group)) {
            let arr: MCDMReasonCodesGroup[] = [];
            arr.push(JSON.parse(JSON.stringify(info.Groups.Group)));
            info.Groups.Group = arr;
        }

        info.Groups.Group.forEach((g) => {
            if (g.ReasonCodes == undefined || g.ReasonCodes as any === "") {
                let reasonCodes = {} as any;
                g.ReasonCodes = reasonCodes;
            }

            if (g.ReasonCodes.ReasonCode == undefined || g.ReasonCodes.ReasonCode as any === "") {
                let code = [] as any;
                g.ReasonCodes.ReasonCode = code;
            }

            if (!Array.isArray(g.ReasonCodes.ReasonCode)) {
                let arr: MCDMGroupReasonCode[] = [];
                arr.push(JSON.parse(JSON.stringify(g.ReasonCodes.ReasonCode)));
                g.ReasonCodes.ReasonCode = arr;
            }
        });

        if (info.ReasonCodeList as any === "") {
            let codeList = {} as any;
            info.ReasonCodeList = codeList;
        }

        if (info.ReasonCodeList.ReasonCode == undefined || info.ReasonCodeList.ReasonCode as any === "") {
            let code = [] as any;
            info.ReasonCodeList.ReasonCode = code;
        }

        if (!Array.isArray(info.ReasonCodeList.ReasonCode)) {
            let arr: MCDMReasonCode[] = [];
            arr.push(JSON.parse(JSON.stringify(info.ReasonCodeList.ReasonCode)));
            info.ReasonCodeList.ReasonCode = arr;
        }
    }
    return validShape;
};