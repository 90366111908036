import _ from "lodash";
import * as React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { SelectDropdownV2 } from "ui-core";
import { ApplicationState } from "@store/index";
import { URLs } from "@commonDevResources/constants";
import { CheckBoxComponent } from "@common/CheckBox";
import { getRawToken } from "@scripts/session/SecurityToken";
import { ModalConfirmation } from "@common/ModalConfirmation";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DialogWrapper, OKCancelButtons } from "@common/DialogWrapper";
import {
  IMergeApiComponentProps,
  createApiMapStateToProps,
  createApiMapDispatchToProps,
  mergeApiComponentProps,
} from "@scripts/util/ApiDataHelpers";

import {
  ISiteTemplateTransferState,
  ISiteTemplateTransferActionProps,
  siteTranferValidationCallback,
  siteTranferValidationCallbackExt,
  actionCreators,
} from "@store/SiteTemplateTransfer";

const calculateFlag = (feature: any) => Math.pow(2, feature);

enum NetworkedClientsSyncEnum {
  SYS_FACILITY = 0, //&H00000001&
  SYS_PHYSICIAN = 1, //&H00000002&
  SYS_ALTSPELLINGS = 2, //&H00000004& -> PayerAliasAuditInfo
  SYS_BRIDGEMASTER = 3, //&H00000008& -> BridgeMasterAuditInfo
  SYS_USER = 4, //&H00000010&
  SYS_GROUP = 5, //&H00000020& -> GroupMasterAuditInfo
  SYS_SECONDARYBILL = 6, //&H00000040&
  SYS_ERRORSEVERITY = 7, //&H00000080&
  SYS_CLAIMCHANGES = 8, //&H00000100&
  SYS_FILETYPES = 9, //&H00000200&
  SYS_UB92PHYSAE = 10, //&H00000400&
  SYS_PAYERADDRAE = 11, //&H00000800&
  SYS_PAPERCMDS = 12, //&H00001000& -> PaperMapsAuditInfo
  SYS_CLAIMOPTIONS = 13, //&H00002000&
  SYS_HOLDCODES = 14, //&H00004000& -> HoldCodeAuditInfo
  SYS_CSVOPTIONS = 15, //&H00008000& -> CSVOptionsAuditInfo
  SYS_REMITIMPORT = 16, //&H00010000&
  SYS_NOTETYPES = 17, //&H00020000&
  SYS_FIELDLEVELSECURITY = 18, //&H00040000&
  SYS_FORMSUSED = 19, //&H00080000& -> FormsUsedAuditInfo
  SYS_ACCOUNTPOLICY = 20, //&H00100000& -> SecurityAccountPolicyAuditInfo
  SYS_CLAIMFILTER = 21, //&H00200000& -> ClaimFilterAuditInfo
  SYS_REMITFILTER = 22, //&H00400000& -> RemitFilterAuditInfo
  SYS_AUTOMODULES = 24, //&H01000000& - > AutomoduleAuditInfo
  SYS_AUTOPROCESS = 25, //&H02000000& -> AutolistsAuditInfo
  SYS_SPINOFFMAP = 26, //&H04000000& -> SpinoffFormMapAuditInfo
  SYS_SPLITCLAIMMAP = 27, //&H08000000& -> SplitClaimFieldMapAuditInfo
  SYS_ELIGIBILITYPROFILE = 28, //&H10000000& -> EligibilityProfileAuditInfo
  SYS_CLAIMEVENTNOTIFY = 29, //&H20000000&
  SYS_PARALLONMANAGECROSSOVERS = 30, //&H40000000&
  SYS_PREBILL = 31, //&H80000000&
  OTHER_SYS_WORKFLOW = 32, //&H100000000&
  SYS_BRIDGEROUTINECOPY = 33, //&H200000000&
  SYS_DELETECLAIMFILTER = 34, //&H400000000&
  SYS_DELETEREMITFILTER = 35, //&H800000000&
  SYS_BRIDGEROUTINEDELETE = 36, //&H1000000000&
}

const BoldLabel = styled.div`
  font-weight: 600;
  margin: 8px 0;
`;

const TableContainer = styled.div`
  border: 1px solid #000;
  width: 100%;
  height: 488px;
  height: 100%;
  overflow: auto;
`;

const TableHeader = styled.div`
  background-color: #9ba1a9;
  border-left: solid 1px #ffffff;
  color: #ffffff;
  font-weight: bold;
  height: 25px;
  padding-left: 5px;
  display: flex;
  align-items: center;
`;

const TableContent = styled.div`
  overflow: auto;
  background-color: white;
  tbody {
    tr:nth-child(odd) {
      background-color: white;
    }
    tr:nth-child(even) {
      background-color: #f2f3f4;
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
`;

const SiteTemplateTransferContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 5px;
  min-height: 180px;
  width: 1000px;
  padding: 10px;
`;

interface IComponentProps {
  canEdit: boolean;
  canView: boolean;
  title: string;
}

interface IComponentState {
  cancelLeave: boolean;
  selectedModuleTypes: any;

  clientName: string;
  serverName?: string;
  clientCount: string;
  errorCode?: number;
  client?: any;
  clientString: string;
  allClients: Array<any>;
  userRoles: [
    {
      id: number | string;
      roleName: string | undefined | null;
    }
  ];
  brandDefault: boolean;
  isCIAMUser: boolean;
  userClients: any;
}

export const DEFAULT_STATE: IComponentState = {
  cancelLeave: false,
  selectedModuleTypes: [],
  clientName: "",
  serverName: "",
  clientCount: "",
  errorCode: undefined,
  client: null,
  clientString: "",
  allClients: [],
  userRoles: [
    {
      id: 0,
      roleName: "",
    },
  ],
  brandDefault: false,
  isCIAMUser: false,
  userClients: null,
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type ISiteTemplateTransferProps = IMergeApiComponentProps<
  ISiteTemplateTransferState,
  ISiteTemplateTransferActionProps,
  IOwnProps
>;

export class SiteTemplateTransfer extends React.Component<
  ISiteTemplateTransferProps,
  IComponentState
> {
  static defaultProps: IComponentProps = {
    canEdit: false,
    canView: false,
    title: "Site Template Transfer",
  };
  checkBoxRef: React.RefObject<any>;
  tableTypeRef: React.RefObject<any>;
  tableDateRef: React.RefObject<any>;
  tableNameRef: React.RefObject<any>;

  constructor(props: ISiteTemplateTransferProps) {
    super(props);
    this.state = DEFAULT_STATE;
    this.checkBoxRef = React.createRef();
    this.tableTypeRef = React.createRef();
    this.tableDateRef = React.createRef();
    this.tableNameRef = React.createRef();
  }

  public componentDidMount() {
    console.clear();
    if (!this.props.canView) {
      console.log("user did not have rights to " + this.props.title);
      this.props.history.push("/LandingPage");
    }

    this.props.action.configData.getConfigData({
      cookies: [],
      config: [{ name: "EnableTenetFeatures" }],
    });

    this.getCookieInfoFromServer();
    this.loadClientsFromServer();
    this.props.action.ui.getSiteTemplateFormData(siteTranferValidationCallback);
  }

  public componentDidUpdate() {
    if (
      this.state.clientName &&
      Object.keys(this.props.dataStore.ui.LastActivityMaintenanceInfo).length &&
      this.props.dataStore.ui.LastActivityMaintenanceInfo.HISWorkflowAudit
        .HISWorkflowAuditInfo["@DateTime"] === "Unknown"
    ) {
      this.props.action.ui.getXtraTemplateFormData(
        sessionStorage.getItem("ClientAlias"),
        siteTranferValidationCallbackExt
      );
    }
    // console.log(this.props.dataStore);
  }

  public componentWillUnmount() {
    this.props.action.ui.resetUiState();
    this.setState({ ...DEFAULT_STATE });
  }

  public getCookieInfoFromServer() {
    let payload: any = {};
    const xhr = new XMLHttpRequest();
    xhr.open("get", URLs.api + "/api/data/GetCookieInformation", true);
    xhr.onload = () => {
      const data = JSON.parse(xhr.responseText);
      payload = {
        clientName: data.Client,
        clientCount: data.ClientCount,
        serverName: data.Server,
        clientString: data.Client,
        isCIAMUser: data.IsCiamLogon === "true",
      };
      this.setState(payload);
      sessionStorage.setItem("Client", data.Client);
    };
    const token = sessionStorage.getItem("SecurityInfo");
    xhr.setRequestHeader("Authorization", `${token}`);
    xhr.send();
    return payload;
  }

  public loadClientsFromServer() {
    const clientUrl =
      URLs.api + "/api/data/securityinfo/securityInfoUserClientList";
    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      if (xhr.readyState == xhr.DONE) {
        if (xhr.status == 200) {
          const userClients: any = _.map(
            JSON.parse(xhr.responseText).userClients,
            (data) => ({ label: data.name, value: data.clientId })
          );
          userClients.unshift({ label: "- Select Client -", value: undefined });
          this.setState({ userClients });
        } else {
          console.warn(xhr.status + " response handling " + clientUrl);
          this.setState({ errorCode: xhr.status });
        }
      }
    };
    xhr.open("get", clientUrl, false);
    const token = sessionStorage.getItem("SecurityInfo");
    xhr.setRequestHeader("Authorization", `${token}`);
    xhr.send();
  }

  public mapKeyToType = (key: any) => {
    switch (key) {
      case "GroupMasterAuditInfo":
        return "Group Master";
      case "BridgeMasterAuditInfo":
        return "Bridge Master";
      case "AutomoduleAuditInfo":
        return "Auto Modules";
      case "AutolistsAuditInfo":
        return "Auto Processes";
      case "ClaimFilterAuditInfo":
        return "Saved Claim Filters";
      case "RemitFilterAuditInfo":
        return "Saved Remit Filters";
      case "FormsUsedAuditInfo":
        return "Forms Used";
      case "SecurityAccountPolicyAuditInfo":
        return "Account Policy";
      case "PayerAliasAuditInfo":
        return "Payer Alias";
      case "CSVOptionsAuditInfo":
        return "CSV Options";
      case "HoldCodeAuditInfo":
        return "Hold Code Maintenance";
      case "PaperMapsAuditInfo":
        return "Paper Maps";
      case "BridgeTBLAuditInfo":
        return "Bridge TBL";
      case "Bridge307FilesAuditInfo":
        return "Bridge 307 Files";
      case "ASCFilesAuditInfo":
        return "ASC/EPI 3x7 Files";

      case "SpinoffFormMapAuditInfo":
        return "Spin Off Map";
      case "SplitClaimFieldMapAuditInfo":
        return "Split Claim Map";
      case "EligibilityProfileAuditInfo":
        return "Eligibility Profile";
      case "ClaimEventAuditInfo":
        return "Claim Event";

      case "HISWorkflowAuditInfo":
        return "HIS Workflow";
      case "RestrictUserSecurityAccessAuditInfo":
        return "User Security Access";
      default:
        return key;
    }
  };

  public onClientChange(e: any | undefined, id: string = "") {
    this.props.action.ui.selectClient({
      api: this.props.dataStore.api.data,
      uiData: {
        selected: e,
      },
    });
  }

  public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
    this.props.history.push("/LandingPage");
  }

  public onClearAlert(e: any) {
    this.props.action.ui.sendSiteTemplateTransferAlert({
      uiData: {
        value: "",
      },
    });
  }

  public onSubmit() {
    let lJobNumber = 0;
    let lBridge = 0;
    let lOtherNumber = 0;

    // must have a destination client selected
    if (
      this.props.dataStore.ui.selectedClient === "" ||
      !this.props.dataStore.ui.selectedClient.value
    ) {
      this.props.action.ui.sendSiteTemplateTransferAlert({
        uiData: {
          value: "You must select a destination client to apply changes to.",
        },
      });
      return;
    }
    // destination client cannot be the source client
    if (
      this.props.dataStore.ui.selectedClient.label === this.state.clientName
    ) {
      this.props.action.ui.sendSiteTemplateTransferAlert({
        uiData: {
          value:
            "The destination client you have selected is the template client, or current client.  Please select a different client to apply a template toward.",
        },
      });
      return;
    }

    _.map(this.state.selectedModuleTypes, (type) => {
      const key = Object.keys(type)[0];
      let job: any = "";
      switch (key) {
        case "GroupMasterAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_GROUP;
          lJobNumber += calculateFlag(job);
          break;
        case "BridgeMasterAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_BRIDGEMASTER;
          lJobNumber += calculateFlag(job);
          break;
        case "AutomoduleAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_AUTOMODULES;
          lJobNumber += calculateFlag(job);
          break;
        case "AutolistsAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_AUTOPROCESS;
          lJobNumber += calculateFlag(job);
          break;
        case "ClaimFilterAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_CLAIMFILTER;
          lJobNumber += calculateFlag(job);
          break;
        case "RemitFilterAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_REMITFILTER;
          lJobNumber += calculateFlag(job);
          break;
        case "FormsUsedAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_FORMSUSED;
          lJobNumber += calculateFlag(job);
          break;
        case "SecurityAccountPolicyAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_ACCOUNTPOLICY;
          lJobNumber += calculateFlag(job);
          break;
        case "PayerAliasAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_ALTSPELLINGS;
          lJobNumber += calculateFlag(job);
          break;
        case "CSVOptionsAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_CSVOPTIONS;
          lJobNumber += calculateFlag(job);
          break;
        case "HoldCodeAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_HOLDCODES;
          lJobNumber += calculateFlag(job);
          break;
        case "PaperMapsAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_PAPERCMDS;
          lJobNumber += calculateFlag(job);
          break;
        case "BridgeTBLAuditInfo":
          lBridge += 0x0001;
          break;
        case "Bridge307FilesAuditInfo":
          lBridge += 0x0002;
          break;
        case "ASCFilesAuditInfo":
          lBridge += 0x0004;
          break;
        case "SpinoffFormMapAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_SPINOFFMAP;
          lJobNumber += calculateFlag(job);
          break;
        case "EligibilityProfileAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_ELIGIBILITYPROFILE;
          lJobNumber += calculateFlag(job);
          break;
        case "ClaimEventAuditInfo":
          job = NetworkedClientsSyncEnum.SYS_CLAIMEVENTNOTIFY;
          lJobNumber += calculateFlag(job);
          break;
        case "HISWorkflowAuditInfo":
          lOtherNumber = lJobNumber;
          lOtherNumber += calculateFlag(
            NetworkedClientsSyncEnum.OTHER_SYS_WORKFLOW
          );
          // lJobNumber = lOtherNumber;
          break;
        default:
          break;
      }
    });

    if (lJobNumber === 0 && lBridge === 0 && lOtherNumber === 0) {
      this.props.action.ui.sendSiteTemplateTransferAlert({
        uiData: {
          value: "You must select at least one item to Apply.",
        },
      });
      return;
    }

    const confirmResponse = confirm(
      "Are you sure you want to apply these items to the destination client?"
    );

    if (confirmResponse != true) {
      return;
    }

    // const HISWorkflowAuditInfoONLY =
    //   "HISWorkflowAuditInfo" in this.state.selectedModuleTypes[0] &&
    //   this.state.selectedModuleTypes.length === 1;
    // && !HISWorkflowAuditInfoONLY

    if (lJobNumber > 0 || lBridge > 0) {
      this.XMLCallBack(108, lJobNumber, lBridge);
    }
    if (lOtherNumber !== 0) {
      this.XMLCallBack(201, lJobNumber, lBridge);
    }
  }

  public onCheckBoxChange = (ele: any) => {
    const index = this.state.selectedModuleTypes.indexOf(ele);

    if (index === -1) {
      this.setState({
        selectedModuleTypes: [...this.state.selectedModuleTypes, ele],
      });
    } else {
      const clone = this.state.selectedModuleTypes;
      clone.splice(index, 1);
      this.setState({ selectedModuleTypes: clone });
    }
  };

  public async XMLCallBack(
    JobType: number,
    lJobNumber: number,
    lBridge: number
  ) {
    const xmlData: any = {};

    const clientObj = this.state.userClients.filter(
      (data: any) => data.label === this.state.clientName
    )[0];

    xmlData.Flags = lJobNumber;
    xmlData.Process = `${lBridge}`;
    xmlData.DestinationClientId = this.props.dataStore.ui.selectedClient.value;
    xmlData.ClientID = clientObj.value;
    xmlData.JobTypeId = JobType;

    await fetch(URLs.api + "/api/data/CreateSiteTemplateJob", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${getRawToken()}`,
      },
      body: JSON.stringify(xmlData),
    })
      .then((response) => {
        if (response.status === 200) {
          return response;
        } else {
          throw new Error(response.statusText);
        }
      })
      .catch((error) => {
        const str =
          "An error occurred processing call for " +
          this.props.title +
          ".  Error: " +
          "Unable to fetch API.";
        console.error(str);
      })
      .finally(() => {
        this.props.history.push("/LandingPage");
      });
  }

  public render() {
    const instruction = (
      <>
        Site Templates are used to speed up the setup time required for new
        clients. The site you are logged into is used as a Template to apply to
        a destination client.
      </>
    );

    const buttons = (
      <OKCancelButtons
        disableOK={false}
        onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onSubmit()}
        onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) =>
          this.onCancel(e)
        }
      />
    );

    return (
      <DialogWrapper
        title={this.props.title}
        instruction={instruction}
        helpUrl="/SupportTools/Help/SUP_Maint_SiteTemplate.htm"
        buttons={buttons}
      >
        <ContentWrapper id="content_wrapper_SiteTemplateTransfer">
          <SiteTemplateTransferContent>
            <BoldLabel>
              From the current client, select the options to apply to the
              destination client:
            </BoldLabel>
            <TableContainer id="table_container">
              <TableContent>
                <table
                  style={{
                    width: "100%",
                    padding: "5px",
                    background: "#EBECED",
                    borderCollapse: "collapse",
                  }}
                >
                  <thead
                    style={{
                      backgroundColor: "#9ba1a9",
                      padding: "5px",
                      color: "white",
                      position: "absolute",
                      zIndex: 1,
                      display: "inherit",
                    }}
                  >
                    <tr>
                      <td>
                        <TableHeader
                          style={{
                            width: `${
                              this.checkBoxRef?.current?.offsetWidth || "35"
                            }px`,
                            borderLeft: "none",
                          }}
                        ></TableHeader>
                      </td>
                      <td>
                        <TableHeader
                          style={{
                            width: `${
                              this.tableTypeRef?.current?.offsetWidth || "448"
                            }px`,
                          }}
                        >
                          Type
                        </TableHeader>
                      </td>
                      <td>
                        <TableHeader
                          style={{
                            width: `${
                              this.tableDateRef?.current?.offsetWidth || "243"
                            }px`,
                          }}
                        >
                          Last Change Date
                        </TableHeader>
                      </td>
                      <td>
                        <TableHeader
                          style={{
                            width: `${
                              this.tableNameRef?.current?.offsetWidth - 8 ||
                              "244"
                            }px`,
                          }}
                        >
                          User name
                        </TableHeader>
                      </td>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      marginTop: "25px",
                      display: "inherit",
                      width: "100%",
                      overflow: "hidden",
                      padding: "20px",
                    }}
                  >
                    {_.map(
                      this.props.dataStore.ui.LastActivityMaintenanceInfo,
                      (ele: any, index: number) => {
                        const KEY = Object.keys(ele)[0];
                        const record = ele[KEY];
                        if (record)
                          return (
                            <tr
                              key={
                                "_" + Math.random().toString(36).substr(2, 9)
                              }
                              style={{ height: "30px" }}
                            >
                              <td ref={this.checkBoxRef}>
                                <CheckBoxComponent
                                  id={ele.userName}
                                  width={"20px"}
                                  checked={
                                    this.state.selectedModuleTypes.indexOf(
                                      ele
                                    ) !== -1
                                  }
                                  onChange={(e) => this.onCheckBoxChange(ele)}
                                />
                              </td>
                              <td ref={this.tableTypeRef}>
                                <span
                                  style={{
                                    fontSize: "13px",
                                    padding: "6px",
                                  }}
                                >
                                  {this.mapKeyToType(KEY)}
                                </span>
                              </td>
                              <td ref={this.tableDateRef}>
                                <span
                                  style={{
                                    fontSize: "13px",
                                    padding: "6px",
                                  }}
                                >
                                  {record["@DateTime"]}
                                </span>
                              </td>
                              <td ref={this.tableNameRef}>
                                <span
                                  style={{
                                    fontSize: "13px",
                                    padding: "6px",
                                  }}
                                >
                                  {record["@UserName"]}
                                </span>
                              </td>
                            </tr>
                          );
                      }
                    )}
                  </tbody>
                </table>
              </TableContent>
            </TableContainer>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginTop: "15px",
              }}
            >
              <div
                style={{
                  width: "20%",
                }}
              >
                <BoldLabel>Select Destination Client:</BoldLabel>
              </div>
              <div
                style={{
                  width: "80%",
                }}
              >
                <SelectDropdownV2
                  domID="st_select_client"
                  className="dropdown"
                  isClearable={false}
                  isSearchable={false}
                  disabled={!this.state.userClients}
                  onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                    this.onClientChange(e)
                  }
                  options={this.state.userClients}
                  initialValue={
                    this.state.userClients ? this.state.userClients[0] : []
                  }
                />
              </div>
            </div>
          </SiteTemplateTransferContent>
        </ContentWrapper>
        <ModalConfirmation
          isOpen={
            !!this.props.dataStore.ui.SiteTemplateTransferAlert &&
            this.props.dataStore.ui.SiteTemplateTransferAlert.length > 0
          }
          onModalToggle={(e: React.MouseEvent<HTMLElement>) =>
            this.onClearAlert(e)
          }
          message={this.props.dataStore.ui.SiteTemplateTransferAlert}
          onConfirm={(e: React.MouseEvent<HTMLButtonElement>) =>
            this.onClearAlert(e)
          }
        />
      </DialogWrapper>
    );
  }
}

const connectedHoc = connect<
  ISiteTemplateTransferState,
  ISiteTemplateTransferActionProps,
  IOwnProps,
  ISiteTemplateTransferProps,
  ApplicationState
>(
  createApiMapStateToProps("siteTemplateTransfer"),
  createApiMapDispatchToProps(actionCreators),
  mergeApiComponentProps
)(SiteTemplateTransfer);

export default withRouter(connectedHoc);
