import * as React from 'react';
import { Colors } from '../../../commonResources/colorVariables';
import styled from 'styled-components';
import { Logo, SectionTitle } from 'ui-core';
import { URLs } from '@commonDevResources/constants';

const ARMLogoHeaderStyle = styled.div`
  background-color: ${Colors.darkestSecondaryGrey};
  padding: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const BrandingHeaderStyle = styled.div`
  background-color: ${Colors.white};
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 3px solid ${Colors.darkestSecondaryGrey};
  img {
    max-width: 100%;
    max-height: 100%;
    transform: translateZ(0);  /* Chrome image aliasing */
  }
`;


const ARMLogoHeaderStyleBox = styled.div`
  padding: 0px;
`;

const ARMLoginLogo = styled.div`
	vertical-align:middle;
`;

const ARMLoginTitle = styled.span`
    vertical-align: middle;
`;

const ARMLoginInnerTitle = styled.div`
    padding-left:20px;
    font-size: 12px;
    color: ${Colors.white};
    border-left: 1px solid ${Colors.white};
    white-space: pre-line;
    width: 100%;
`;

interface IComponentProps {
    title: string;
};

interface IComponentState {
    brandingLoaded : boolean,
    brandingPath : string
}

export class ARMLogoHeader extends React.PureComponent<IComponentProps, IComponentState> {
    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            brandingLoaded: false,
            brandingPath: 'Default'
        };
    }

    componentDidMount() {
        this.getBrandPath();
    }
    
    getBrandPath() {
        let baseBrandPath = '/shared/images/Branding';
        let brand = "/Default";
        if (sessionStorage.getItem("brandingPath") === null || sessionStorage.getItem("brandingPath") === "undefined") {
            var xhr = new XMLHttpRequest()
            xhr.open('get', URLs.api + "/api/data/securityInfo/brandPath", true);
            xhr.onload = () => {
                try {
                    var data = JSON.parse(xhr.responseText);
                    if (data.BrandPath === "/Achieve") {
                        brand = data.BrandPath;
                    }
                    sessionStorage.setItem("brandingPath", data.BrandPath);
                    this.setState({
                        brandingLoaded: true,
                        brandingPath: baseBrandPath + brand + '/LoginLogo.png'
                    })
                }
                catch(e){
                    console.error("Unable to retrieve brand path. Defaulting..");
                    this.setState({brandingLoaded: true});
                }
            };
            xhr.send();
        }
        else {
            let brand = sessionStorage.getItem("brandingPath");
            if (brand) {
                this.setState({
                    brandingLoaded: true,
                    brandingPath: baseBrandPath + brand + '/LoginLogo.png'
                });
            }
        }
    }
    
    public defaultLogo(){
        
        return <ARMLogoHeaderStyle>
                    <ARMLoginLogo><Logo /></ARMLoginLogo>
                  <ARMLoginTitle><ARMLoginInnerTitle>{this.props.title}</ARMLoginInnerTitle></ARMLoginTitle>
                </ARMLogoHeaderStyle>;
    }
    
    public brandPathLogo(){
       return <BrandingHeaderStyle><img src={this.state.brandingPath} alt='logo'/></BrandingHeaderStyle>
    }

    public render() {
        let logo = this.defaultLogo();
        
        if(this.state.brandingLoaded) {
            if (this.state.brandingPath.indexOf('Achieve') > -1)
                logo = this.brandPathLogo();

            return (
                <ARMLogoHeaderStyleBox>
                    {logo}
                </ARMLogoHeaderStyleBox>
            );
        }
        return null;
    }
};
