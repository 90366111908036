import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, Modal, LoadingIndicator, Tabs } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { Input } from '@common/UICLWrappers/ARMInput';
import { handleChange, pageLeave } from '@commonResources/userModified';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IPayerStatusRuleState, IPayerStatusRuleActions, IPayerStatusRuleActionProps, actionCreators, validationCallback } from '@store/ManageClaimAssignmentsPayerStatusRules';
import { CheckBoxComponent } from '@common/CheckBox';
import { RadioButtonComponent } from '@common/RadioButton';
import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ICookies_Config } from '@store/ConfigData';
import { ModalConfirmation } from '@common/ModalConfirmation';
import AssuranceMenu from '@common/AssuranceMenu';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const EmptyHeader = styled.div``;

export const FirstColumnWrapper = styled.div`
    width:400px !important;
    padding: 5;
`;

const TableContent = styled.div`
    height:150px !important;
    overflow: auto;
    background-color: white;
    
`;

const ManageClaimContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 0px;
    min-height: 180px;
    min-height: 180px;
    #department-code,.select{
    width:1000px;
min-width:950px;
    margin-bottom:5px;
}
 var sc = { label: 'Status Code', domID: 'tab-sc' };
        var cc = { label: 'Category 277 Code', domID: 'tab-cc' };
        var rl = { label: 'Recon Level', domID: 'tab-rl' };
        var rsc = { label: 'Remit Status Code', domID: 'tab-rsc' };
        var mrc = { label: 'Medicare Reason Code', domID: 'tab-mrc' };
 #tab-sc,
 #tab-cc,
 #tab-rl,
 #tab-rsc,
 #tab-mrc
    {
       box-shadow: 0 0 0 0 black!important;
       padding-top: 1px;
       padding-bottom: 1px;
    }


     #tab-sc > label,
 #tab-cc > label,
 #tab-rl > label,
 #tab-rsc > label,
 #tab-mrc > label
     {
         margin-bottom: 0px;
         ${ Typography.small};
     }
#alphaSplit
{
height:20px !important;
}

.option-class{
    font-size: 10pt !important;
}

.tableRowSelected{
    background-color: #ffd352;
}

.tableRowNotSelected{
    background-color: white;
}

table tbody tr.tableRowNotSelected:hover td{
    background-color: #EBEBEB;
cursor: default;
}

`;

export const SelectList = styled.div`
    padding: 5px;
`;

export const RightAlignContent = styled.div`
    align-self: flex-end;
`;


export const ArrowButtonsAlignmentAdjustment = styled.div`
    position: relative;
    left: -8px;
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
   input {
        width: 300px;
    }

`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const DialogColumnFieldset = styled.fieldset`
    border: 1px solid ${Colors.darkGrey};
    padding: 5px;
    margin: 5px;
    display: flex;
     flex: 1 1 auto;
    flex-direction: row;
`;

interface IComponentProps {
};

interface HeaderProps { columns: {} }

interface IComponentState {
    cancelLeave: boolean,
    assignmentArray: AssignmentData[],
    rowStyleValue: boolean,
    activeTab: number,
    activeTabId: string,
    isAddButton: boolean,
    isDeleteButton: boolean,
    isUpdateButton: boolean,
    isLoaded: boolean,
    currentClickedRowID: any[],
    isAlertOpen: boolean,
    alertMessage: string,
    reconciliation: string
};

export class AssignmentData {

    UserId: string = "";
    UserName: string = "";
    AlphaSplit: string = "";
}


export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false, assignmentArray: [], rowStyleValue: false,
    activeTab: 0, activeTabId: 'tab-sc', isAddButton: false, isDeleteButton: true, isLoaded: false, currentClickedRowID: [],
    isAlertOpen: false, alertMessage: "", isUpdateButton: true, reconciliation: "0"
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IClaimNoteTypeProps = IMergeCrudComponentProps<IPayerStatusRuleState, IPayerStatusRuleActionProps, IOwnProps>;

enum eModifyButtonType {
    Add,
    Update
}

interface IDropdownItem {
    label: string,
    value: string,
}

export class ManageClaimAssignmentsPayerStatusRules extends React.Component<IClaimNoteTypeProps, IComponentState> {

    static defaultProps: IComponentProps = {
    };

    constructor(props: IClaimNoteTypeProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public async componentDidMount() {
        this.props.action.ui.clearAll({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: "clear"
            }
        });
        this.setState({ isLoaded: true, isUpdateButton: true, isDeleteButton: true, currentClickedRowID: [] });
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiClaimAssignment }, validationCallback);

        this.props.action.configData.getConfigData({ cookies: [{ name: "Reconciliation" }], config: [] });
        setTimeout(async () => {
            this.props.action.ui.initialPayerStatusRules({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: this.props.dataStore.crud.data?.ClaimAssignmentInfo?.StatusRules?.StatusRule
                }
            });
            await this.ClaimFacility();
        }, 10000)

    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onClickRemoveButton(e: React.MouseEvent<HTMLButtonElement>) {

        handleChange();
        this.setState({ isLoaded: true });
        this.props.action.ui.deletePayerStatusRules({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                data: this.state.currentClickedRowID
            }
        });
        this.setState({ isUpdateButton: true, isDeleteButton: true, currentClickedRowID: [] });
        this.ClaimFacility();
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        let statusRules = [];
        if (this.props.dataStore.ui.StatusRulesModified !== undefined) {

            let statusRuleList = JSON.parse(JSON.stringify(this.props.dataStore.ui.StatusRulesModified));
            for (let i = 0; i < statusRuleList.length; i++) {
                let obj = statusRuleList[i];

                let initialStatusRule = this.props.dataStore.crud.originalData?.ClaimAssignmentInfo?.StatusRules?.StatusRule.find(ob => ob["@ID"] === obj["@ID"]);


                obj["Users"] = {
                    User: obj.Users
                }
                let departmentList = [];
                if (initialStatusRule !== undefined) {
                    if (initialStatusRule.Departments !== undefined) {
                        if (initialStatusRule.Departments["@ID"] === obj.Departments["@ID"]) {
                            departmentList.push(obj.Departments);
                        }
                        else {
                            departmentList.push({ "@DepartmentID": initialStatusRule.Departments["@ID"], "@ID": initialStatusRule.Departments["@ID"], "@Delete": true });
                            if (obj.Departments["@ID"] !== undefined && obj.Departments["@ID"] != "0")
                                departmentList.push({ "@DepartmentID": obj.Departments["@ID"], "@ID": "#" });
                        }
                    }
                }
                else {
                    if (obj.Departments["@ID"] !== undefined && obj.Departments["@ID"] != "0")
                        departmentList.push({ "@DepartmentID": obj.Departments["@ID"], "@ID": "#" });
                }
                obj["Departments"] = {
                    Department: departmentList
                }
                let facilityList = [];
                if (initialStatusRule !== undefined) {
                    if (initialStatusRule.Facilities !== undefined) {
                        if (initialStatusRule.Facilities["@ID"] === obj.Facilities["@ID"]) {
                            facilityList.push(obj.Facilities);
                        }
                        else {
                            facilityList.push({ "@FacilityID": initialStatusRule.Facilities["@ID"], "@ID": initialStatusRule.Facilities["@ID"], "@Delete": true });
                            if (obj.Facilities["@ID"] !== undefined && obj.Facilities["@ID"] != "0")
                                facilityList.push({ "@FacilityID": obj.Facilities["@ID"], "@ID": "#" });
                        }
                    }
                }
                else {
                    if (obj.Facilities["@ID"] !== undefined && obj.Facilities["@ID"] != "0")
                        facilityList.push({ "@FacilityID": obj.Facilities["@ID"], "@ID": "#" });
                }
                obj["Facilities"] = {
                    Facility: facilityList
                }
                statusRules.push(obj);
            }
        }

        const data = {
            crudId: this.props.dataStore.crud.crudId,
            data: {
                ClaimAssignmentInfo: {
                    StatusRules: {
                        StatusRule: statusRules
                    }
                }
            }
        }
        this.setState({ currentClickedRowID: [], isUpdateButton: true, isDeleteButton: true });


        this.props.action.crud.update(data);
        setTimeout(() => {
            this.props.history.push('/LandingPage')
        }, 100)
    }

    //Get API to find Facility is available or not 
    public ClaimFacility = async () => {
        var url = URLs.api + '/api/crud/36';
        var facilityData: any = {};
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        })
            .then(async response => {
                const data: any = await response.json();
                if (response.status === 200) {
                    let dataValue = JSON.stringify(data);
                    facilityData = JSON.parse(dataValue);
                    this.props.action.ui.updateFacilityLookup({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            value: facilityData["ConfigureSubmitter"]["@FacilityLookup"]
                        }
                    });
                    this.setState({ isLoaded: false });
                    return data;
                }
            })
            .catch(error => {
                this.setState({ isLoaded: false });
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public crudLoaded(): boolean {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.ClaimAssignmentInfo &&
            this.props.dataStore.crud.data.ClaimAssignmentInfo.PayerList
        )
            return true;
        return false;
    }

    public getNameLookupList_Array(lookupList: MCAPSR_GenericNameLookupList[] | null, initialDefaultValue: string) {

        if (!this.crudLoaded() ||
            !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];
        result.push({ label: initialDefaultValue, value: '0' });
        lookupList.forEach(ob => {
            if (ob['@Name'])
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getMessageLookupList_Array(lookupList: MCAPSR_GenericMessageLookupList[] | null, initialDefaultValue: string) {

        if (!this.crudLoaded() ||
            !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];
        result.push({ label: initialDefaultValue, value: '0' });
        lookupList.forEach(ob => {
            if (ob['@Message'])
                result.push({ label: ob['@Message'], value: ob['@ID'] });
        });
        return result;
    }

    public getName_SelectedItem(lookupList: MCAPSR_GenericNameLookupList[] | null, idToFind: string | undefined, initialDefaultValue: string): IDropdownItem {

        if (!this.crudLoaded() ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Name'];
            result.value = item['@ID'];
        } else {
            result.label = initialDefaultValue;
            result.value = '0';
        }

        return result;
    }

    public getMessage_SelectedItem(lookupList: MCAPSR_GenericMessageLookupList[] | null, idToFind: string | undefined, initialDefaultValue: string): IDropdownItem {

        if (!this.crudLoaded() ||
            idToFind === undefined || !lookupList || lookupList.length === 0
        ) return { value: '', label: '' };

        let item = lookupList.find(
            ob => ob['@ID'] === idToFind);
        let result = { value: '', label: '' };
        if (item) {
            result.label = item['@Message'];
            result.value = item['@ID'];
        } else {
            result.label = initialDefaultValue;
            result.value = '0';
        }

        return result;
    }

    public updateMCAPSR(val: string | undefined, type: string) {
        let reconciliation = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "Reconciliation");
        switch (type) {
            case "PAYER":
                this.props.action.ui.updatePayer({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: val
                    }
                });

                break;
            case "FACILITY":
                this.props.action.ui.updateFacility({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: val
                    }
                });

                break;
            case "STATUSCODE":
                this.props.action.ui.updateStatusCode({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: val
                    }
                });
                this.setState({ activeTabId: "tab-sc", activeTab: 0 });
                break;
            case "CATEGORY277CODE":
                this.props.action.ui.updateCategory277Code({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: val
                    }
                });
                this.setState({ activeTabId: "tab-cc", activeTab: 1 });
                break;
            case "RECONLEVEL":
                if (reconciliation !== undefined && reconciliation.value === "1") {
                    this.props.action.ui.updateReconLevel({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            value: val
                        }
                    });
                    this.setState({ activeTabId: "tab-rl", activeTab: 2 });
                }
                else {
                    this.props.action.ui.updateReconLevel({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            value: "0"
                        }
                    });
                }
                break;
            case "REMITCODE":
                this.props.action.ui.updateRemitCode({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: val
                    }
                });
                if (reconciliation !== undefined && reconciliation.value === "0")
                    this.setState({ activeTabId: "tab-rsc", activeTab: 2 });
                else
                    this.setState({ activeTabId: "tab-rsc", activeTab: 3 });
                break;
            case "DDEERRORCODE":
                this.props.action.ui.updateDDEErrorCode({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: val
                    }
                });
                if (reconciliation !== undefined && reconciliation.value === "0")
                    this.setState({ activeTabId: "tab-mrc", activeTab: 3 });
                else
                    this.setState({ activeTabId: "tab-mrc", activeTab: 4 });
                break;
            case "EXPIRESON":
                this.props.action.ui.updateExpiresOn({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: val
                    }
                });
                break;
        }
    }

    public updateCheckAssignments(e: React.ChangeEvent<HTMLInputElement> | null, obj: MCAPSR_UserLookupList) {
        if (e !== null) {
            if (e.target.checked) {
                this.props.action.ui.updateUserCheckList({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        id: obj["@ID"]
                    }
                });
            }
            else {
                this.props.action.ui.updateUserUnCheckList({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        id: obj["@ID"]
                    }
                });
            }
        }
        else {
            this.props.action.ui.updateUserCheckList({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: obj["@ID"] === "#" ? obj["@UserID"] : obj["@ID"]
                }
            });
        }
    }

    public updateCheckAssignmentsUser(obj: MCAPSR_UserLookupList) {

        let userDetail = this.props.dataStore.ui.Users.find(ob => ob["@ID"] === obj["@ID"]);
        if (userDetail !== undefined) {
            if (!userDetail["@IsChecked"]) {
                this.props.action.ui.updateUserCheckList({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        id: obj["@ID"]
                    }
                });
            }
            else {
                this.props.action.ui.updateUserUnCheckList({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        id: obj["@ID"]
                    }
                });
            }
        }
        else {
            this.props.action.ui.updateUserCheckList({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: obj["@ID"]
                }
            });
        }
    }

    public updatAlphaSplit(e: React.ChangeEvent<HTMLInputElement> | null, obj: MCAPSR_UserLookupList) {
        if (e !== null) {
            this.props.action.ui.updateAlphaSplit({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: obj["@ID"],
                    alphaSplit: e.target.value.toUpperCase()
                }
            });
        }
        else {
            this.props.action.ui.updateAlphaSplit({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: obj["@ID"] === "#" ? obj["@UserID"] : obj["@ID"],
                    alphaSplit: (obj["@AlphaSplit"] !== null && obj["@AlphaSplit"] !== "" && obj["@AlphaSplit"] !== undefined) ? obj["@AlphaSplit"].toUpperCase() : ""
                }
            });
        }
    }

    public selectComponentDataMessage(lookupList: MCAPSR_GenericMessageLookupList[], initialValue: string) {
        let addItem: MCAPSR_GenericMessageLookupList[] = [{
            '@ID': '',
            '@Message': initialValue,

        }];

        let dataValues: MCAPSR_GenericMessageLookupList[] = [];

        let listDataValues: MCAPSR_GenericMessageLookupList[] = [];
        if (!Array.isArray(lookupList)) {
            listDataValues.push(lookupList);
        }
        else
            listDataValues = lookupList;

        dataValues = listDataValues
            .map((item: { [x: string]: any; }) => {
                let dispPayer: MCAPSR_GenericMessageLookupList = {
                    '@ID': item["@ID"],
                    '@Message': item["@ID"] + " - " + item["@Message"]
                };
                return dispPayer;
            });

        dataValues = addItem.concat(dataValues);
        return dataValues;
    }

    public selectComponentDataName(lookupList: MCAPSR_GenericNameLookupList[], initialValue: string) {
        let addItem: MCAPSR_GenericMessageLookupList[] = [{
            '@ID': '',
            '@Message': initialValue,

        }];

        let dataValues: MCAPSR_GenericMessageLookupList[] = [];
        if (lookupList.length > 0) {
            dataValues = lookupList
                .map((item: { [x: string]: any; }) => {
                    let dispPayer: MCAPSR_GenericMessageLookupList = {
                        '@ID': item["@ID"],
                        '@Message': initialValue === "- Select Facility -" ? item["@Name"] + " (" + item["@ID"] + ")" : item["@Name"]
                    };
                    return dispPayer;
                });

            dataValues = addItem.concat(dataValues);
        }
        return dataValues;
    }

    public updateDepartment(e: React.ChangeEvent<HTMLInputElement> | null, obj: MCAPSR_DepartmentLookupList) {

        if (e != null) {
            this.props.action.ui.updateDepartment({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: obj["@ID"]
                }
            });
        }
        else {
            this.props.action.ui.updateDepartment({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: obj["@ID"]
                }
            });
        }
    }

    public updateDepartmentSpan(obj: MCAPSR_DepartmentLookupList) {
        if (this.props.dataStore.ui.Department !== obj["@ID"]) {
            this.props.action.ui.updateDepartment({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: obj["@ID"]
                }
            });
        }
        else {
            this.props.action.ui.updateDepartment({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: obj["@ID"]
                }
            });
        }
    }

    public getDateInMMDDYYYYFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var year = input.getFullYear();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000";
    }

    public getDateInMMDDYYYYHHMMSSFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var hour = input.getHours();
            var year = input.getFullYear();
            var minute = input.getMinutes();
            var seconds = input.getSeconds();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year + ' ' + hour + ':' + minute + ':' + seconds;
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000 00:00:00";
    }

    public CodeValueMessage(codeType: string, codeValue: string) {
        switch (codeType) {
            case "1":
                var selectedValue = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.ReconLevelList?.ReconLevel.find(ob => ob["@ID"] === codeValue);
                if (selectedValue != undefined) {
                    return selectedValue["@Message"];
                }
                break;
            case "2":
                var selectedValue = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.Category277CodeList?.Category277Code.find(ob => ob["@ID"] === codeValue);
                if (selectedValue != undefined) {
                    return selectedValue["@Message"];
                }
                break;
            case "3":
                var selectedValue = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.StatusCodeList?.StatusCode.find(ob => ob["@ID"] === codeValue);
                if (selectedValue != undefined) {
                    return selectedValue["@Message"];
                }
                break;
            case "4":
                var selectedValue = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.RemitStatusCodeList?.RemitStatusCode.find(ob => ob["@ID"] === codeValue);
                if (selectedValue != undefined) {
                    if (selectedValue["@ID"] === "DENIED") {
                        return "All unassigned denials (status 4)";
                    }
                    else
                        return selectedValue["@Message"];
                }
                break;
            case "5":
                var selectedValue = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.DDEErrorCodeList?.DDEErrorCode.find(ob => ob["@ID"] === codeValue);
                if (selectedValue != undefined) {
                    if (selectedValue["@ID"] === "DENIED") {
                        return "All unassigned Denied";
                    }
                    else if (selectedValue["@ID"] === "RTP") {
                        return "All unassigned Returned To Provider";
                    }
                    else
                        return selectedValue["@Message"];
                }
                break;
        }
    }

    public AssignedToMessage(department: any[], userDetails: MCA_UserList) {
        let activeDepartment: any = { "@ID": "0" };
        if (department.length !== undefined) {
            for (let i = 0; i < department.length; i++) {
                if (!department[i]["@Delete"]) {
                    activeDepartment = department[i];
                }
            }
        }
        else {
            activeDepartment = department;
        }
        var departmentDetails = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.DepartmentList?.Department?.find(ob => ob["@ID"] === activeDepartment["@ID"]);
        var user = "";
        if (userDetails.User !== undefined) {
            if (userDetails.User.length > 0) {
                userDetails.User.map((item: any) => {
                    let userNameDetails = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.UserList?.User?.find(ob => ob["@ID"] === (item["@ID"] === "#" ? item["@UserID"] : item["@ID"]));
                    if (item["@IsChecked"] === true) {
                        if (item["@AlphaSplit"] !== undefined && item["@AlphaSplit"] !== "") {
                            user += userNameDetails?.["@Name"] + "*, ";
                        }
                        else {
                            user += userNameDetails?.["@Name"] + ", ";
                        }
                    }

                })
            }
            else {
                let user1 = JSON.parse(JSON.stringify(userDetails.User));
                let userNameDetails = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.UserList?.User?.find(ob => ob["@ID"] === (user1["@ID"] === "#" ? user1["@UserID"] : user1["@ID"]));
                if (user1["@IsChecked"] === true) {
                    if (user1["@AlphaSplit"] !== undefined && user1["@AlphaSplit"] !== "") {
                        user += userNameDetails?.["@Name"] + "*, ";
                    }
                    else {
                        user += userNameDetails?.["@Name"] + ", ";
                    }
                }


            }
        }
        else {
            let userData = JSON.parse(JSON.stringify(userDetails));
            userData?.map((item: any) => {
                let userNameDetails = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.UserList?.User?.find(ob => ob["@ID"] === (item["@ID"] === "#" ? item["@UserID"] : item["@ID"]));
                if (item["@IsChecked"] === true) {
                    if (item["@AlphaSplit"] !== undefined && item["@AlphaSplit"] !== "" && item["@IsChecked"] === true) {
                        user += userNameDetails?.["@Name"] + "*, ";
                    }
                    else {
                        user += userNameDetails?.["@Name"] + ", ";
                    }
                }

            })
        }

        var assignedTo = departmentDetails !== undefined ? departmentDetails?.["@Name"] + " " + user.substring(0, user.length - 2) : "" + user.substring(0, user.length - 2);
        return assignedTo;
    }

    public getFacilities(facility: any[]) {
        let activeFacility: any = { "@ID": "0", "@Name": "" };
        if (facility.length !== undefined) {
            for (let i = 0; i < facility.length; i++) {
                if (!facility[i]["@Delete"]) {
                    activeFacility = facility[i];
                }
            }
        }
        else {
            activeFacility = facility;
        }
        var facilityDetails = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.FacilityList.Facility.find(ob => ob["@ID"] === activeFacility["@ID"]);
        return facilityDetails !== undefined ? facilityDetails["@ID"] : "All";
    }

    public OnrowChanged = (obj: MCAPSR_StatusRulesList | null) => {

        let isSingleEntryForUpdate = this.setClassNameChange(obj);
        let updateObj: any = {};
        if (isSingleEntryForUpdate) {
            this.props.dataStore.ui?.StatusRules?.map((item) => {
                if (item["@IndexId"] === this.state.currentClickedRowID[0]) {
                    updateObj = item;
                }
            })
            switch (updateObj?.["@CodeType"]) {
                case "1":
                    this.updateMCAPSR(updateObj?.["@Code"], "RECONLEVEL");
                    break;
                case "2":
                    this.updateMCAPSR(updateObj?.["@Code"], "CATEGORY277CODE");
                    break;
                case "3":
                    this.updateMCAPSR(updateObj?.["@Code"], "STATUSCODE");
                    break;
                case "4":
                    this.updateMCAPSR(updateObj?.["@Code"], "REMITCODE");
                    break;
                case "5":
                    this.updateMCAPSR(updateObj?.["@Code"], "DDEERRORCODE");
                    break;
            }

            if (updateObj != undefined) {
                if (updateObj?.["@Payer"] !== undefined && updateObj?.["@Payer"] !== "") {
                    let payerDetail = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.PayerList?.Payer.find(ob => ob["@Name"].toLowerCase() === updateObj?.["@Payer"].toLowerCase());
                    if (payerDetail !== undefined) {
                        this.updateMCAPSR(payerDetail["@ID"], "PAYER");
                    }
                }
                if (updateObj?.["@Expires"] !== undefined && updateObj?.["@Expires"] !== "") {
                    this.updateMCAPSR(updateObj?.["@Expires"], "EXPIRESON");
                }
            }

            if (updateObj !== undefined) {
                this.props.action.ui.updateId({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: updateObj?.["@ID"]
                    }
                });
                this.props.action.ui.updateIndexId({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: updateObj?.["@IndexId"]
                    }
                });
                let data = JSON.parse(JSON.stringify(updateObj?.Users));

                this.props.action.ui.clearUserList({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: ""
                    }
                });
                if (data.length !== undefined) {
                    if (data?.length !== undefined) {
                        data?.map((item: any) => {
                            if (item["@IsChecked"] === true) {
                                this.updateCheckAssignments(null, item);
                                this.updatAlphaSplit(null, item);
                            }
                        })

                    }
                    else {
                        this.updateCheckAssignments(null, data?.User);
                        this.updatAlphaSplit(null, data?.User);
                    }
                }
                else {
                    data?.map((item: any) => {
                        if (item["@IsChecked"] === true) {
                            this.updateCheckAssignments(null, item);
                            this.updatAlphaSplit(null, item);
                        }
                    })
                }

            }
            if (updateObj?.Departments !== undefined) {
                var department = JSON.parse(JSON.stringify(updateObj?.Departments));
                this.updateDepartment(null, department);
            }

            if (updateObj?.Facilities !== undefined) {
                var facility = JSON.parse(JSON.stringify(updateObj?.Facilities));
                this.props.action.ui.updateFacility({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: facility !== undefined ? facility["@ID"] : "0"
                    }
                });
            }
        }
        else {
            this.updateMCAPSR("0", "DDEERRORCODE");
            this.updateMCAPSR("0", "REMITCODE");
            this.updateMCAPSR("0", "RECONLEVEL");
            this.updateMCAPSR("0", "CATEGORY277CODE");
            this.updateMCAPSR("0", "STATUSCODE");
            this.updateMCAPSR("0", "PAYER");
            this.updateMCAPSR("", "EXPIRESON");
            this.props.action.ui.updateId({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: "0"
                }
            });
            this.props.action.ui.updateIndexId({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: 0
                }
            });
            this.props.action.ui.clearUserList({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: ""
                }
            });
            this.updateDepartment(null, { "@ID": "0", "@Name": "", "@Priority": "" });
            this.props.action.ui.updateFacility({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: "0"
                }
            });

        }

    }

    public onClickModifyButton(e: React.ChangeEvent<HTMLButtonElement>) {

        if (this.props.dataStore.crud.data) {

            if (this.ValidateControls(false)) {
                this.setState({ isLoaded: true });
                handleChange();
                this.props.action.ui.updatePayerStatusRules({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: "update"
                    }
                });
                this.setState({ currentClickedRowID: [], isUpdateButton: true, isDeleteButton: true });
                this.ClaimFacility();
            }
        }
    }

    public onClickAddButton(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.props.dataStore.crud.data) {

            if (this.ValidateControls(true)) {
                this.setState({ isLoaded: true });
                handleChange();
                this.props.action.ui.addpayerStatusRules({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        value: "add"
                    }
                });
                this.setState({ currentClickedRowID: [], isUpdateButton: true, isDeleteButton: true });

                this.ClaimFacility();
            }
        }
    }

    public ExpiryDateValidation(input: any): Boolean {
        try {
            let inputDate = new Date(input);

            var month = inputDate.getMonth() + 1;
            var day = inputDate.getDate();
            var year = inputDate.getFullYear();
            var currentMonth = new Date().getMonth() + 1;
            var currentDay = new Date().getDate();
            var currentYear = new Date().getFullYear();
            let currentDate = new Date((currentMonth < 10 ? '0' + currentMonth : currentMonth) + '/' + (currentDay < 10 ? '0' + currentDay : currentDay) + '/' + currentYear)
            let expiryDate = new Date((month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year);
            if (expiryDate <= currentDate) {
                return false;
            }
            else {
                return true;
            }


        }
        catch (e) {
            console.error('Unable to properly format date');
            return false;
        }
    }

    public ValidateControls(isAdd: boolean) {
        let isValid = false;
        let code = 0;
        let codeValue = "";
        let codeTypeValue = "";
        if (this.props.dataStore.ui.ExpiresOn !== "") {
            if (!this.ExpiryDateValidation(this.props.dataStore.ui.ExpiresOn)) {
                this.setState({ isAlertOpen: true, alertMessage: "Expires date must be greater than today" });
                return false;
            }
        }
        if (this.props.dataStore.ui.StatusCode !== "0" && this.props.dataStore.ui.StatusCode !== "") {
            code += 1;
            codeValue = this.props.dataStore.ui.StatusCode !== undefined ? this.props.dataStore.ui.StatusCode : "";
            codeTypeValue = "3";
        }
        if (this.props.dataStore.ui.Category277Code !== "0" && this.props.dataStore.ui.Category277Code !== "") {
            code += 1;
            codeValue = this.props.dataStore.ui.Category277Code !== undefined ? this.props.dataStore.ui.Category277Code : "";
            codeTypeValue = "2";
        }
        let reconciliation = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "Reconciliation");
        if (reconciliation !== undefined && reconciliation.value === "1") {
            if (this.props.dataStore.ui.ReconLevelCode !== "0" && this.props.dataStore.ui.ReconLevelCode !== "") {
                code += 1;
                codeValue = this.props.dataStore.ui.ReconLevelCode !== undefined ? this.props.dataStore.ui.ReconLevelCode : "";
                codeTypeValue = "1";
            }
        }
        if (this.props.dataStore.ui.RemitCode !== "0" && this.props.dataStore.ui.RemitCode !== "") {
            code += 1;
            codeValue = this.props.dataStore.ui.RemitCode !== undefined ? this.props.dataStore.ui.RemitCode : "";
            codeTypeValue = "4";
        }
        if (this.props.dataStore.ui.DDEErrorCode !== "0" && this.props.dataStore.ui.DDEErrorCode !== "") {
            code += 1;
            codeValue = this.props.dataStore.ui.DDEErrorCode !== undefined ? this.props.dataStore.ui.DDEErrorCode : "";
            codeTypeValue = "5";
        }
        if (code === 0) {
            isValid = false;
            if (reconciliation !== undefined && reconciliation.value === "1") {
                this.setState({ isAlertOpen: true, alertMessage: "A Recon Level, Status Code, 277 Category or Remit Code or Medicare Reason Code must be selected to add or update an assignment" });
            }
            else {
                this.setState({ isAlertOpen: true, alertMessage: "A Status Code, 277 Category or Remit Code or Medicare Reason Code must be selected to add or update an assignment" });
            }
        }
        else if (code > 1) {
            isValid = false;
            if (reconciliation !== undefined && reconciliation.value === "1") {
                this.setState({ isAlertOpen: true, alertMessage: "Only one code (Recon Level, Status Code, 277 Category or Remit Code or Medicare Reason Code) can be used per assignment" });
            }
            else {
                this.setState({ isAlertOpen: true, alertMessage: "Only one code (Status Code, 277 Category or Remit Code or Medicare Reason Code) can be used per assignment" });
            }
        }
        else {
            let currentPayerStatusAssignmentsData = this.props.dataStore.ui.StatusRules;
            let isDuplicate = false;
            let isHavingFacility = false;
            let isHavingNewFacility = false;
            if (this.props.dataStore.ui.IndexId === 0) {
                if (currentPayerStatusAssignmentsData !== undefined && currentPayerStatusAssignmentsData.length > 0) {
                    currentPayerStatusAssignmentsData.map((currentData) => {
                        let payerDetail = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.PayerList?.Payer.find(ob => ob["@ID"] === this.props.dataStore.ui.Payer);
                        if (payerDetail !== undefined) {
                            if (payerDetail["@Name"] === currentData["@Payer"] && currentData["@Code"] === codeValue && currentData["@CodeType"] === codeTypeValue) {
                                isDuplicate = true;
                            }
                            if (currentData["Facilities"] !== undefined && currentData["Facilities"]["@ID"] !== "0") {
                                if (currentData["Facilities"]["@ID"] === this.props.dataStore.ui.Facility) {
                                    isHavingFacility = true;
                                }
                                else {
                                    isHavingFacility = false;
                                }
                            }
                            else if (currentData["Facilities"] !== undefined && currentData["Facilities"]["@ID"] === "0") {
                                if (payerDetail["@Name"] === currentData["@Payer"] && currentData["@Code"] === codeValue && currentData["@CodeType"] === codeTypeValue) {
                                    isDuplicate = true;
                                }
                            }

                        }
                        else {
                            if (currentData["@Payer"] === "") {
                                if (currentData["@Payer"] === "" && currentData["@Code"] === codeValue && currentData["@CodeType"] === codeTypeValue) {
                                    isDuplicate = true;
                                }
                            }
                        }

                    })
                    if (isDuplicate) {
                        if (isHavingFacility) {
                            this.setState({ isAlertOpen: true, alertMessage: "Assignment already exists for the selected Payer/Code/Facility" });
                            isValid = false;
                        }
                        else {
                            this.setState({ isAlertOpen: true, alertMessage: "Assignment already exists for the selected Payer/Code" });
                            isValid = false;
                        }
                    }
                    else {
                        isValid = true;
                    }
                }
                else
                    isValid = true;
            }
            else {
                if (currentPayerStatusAssignmentsData !== undefined && currentPayerStatusAssignmentsData.length > 0) {
                    if (!isAdd) {
                        currentPayerStatusAssignmentsData.map((currentData) => {
                            if (currentData["@IndexId"] !== this.props.dataStore.ui.IndexId) {
                                let payerDetail = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.PayerList?.Payer.find(ob => ob["@ID"] === this.props.dataStore.ui.Payer);
                                if (payerDetail !== undefined) {
                                    if (payerDetail["@Name"] === currentData["@Payer"] && currentData["@Code"] === codeValue && currentData["@CodeType"] === codeTypeValue) {
                                        isDuplicate = true;
                                    }
                                    if (currentData["Facilities"] !== undefined && currentData["Facilities"]["@ID"] !== "0") {
                                        if (currentData["Facilities"]["@ID"] === this.props.dataStore.ui.Facility) {
                                            isHavingFacility = true;
                                        }
                                        else {
                                            isHavingNewFacility = false;
                                        }
                                    }
                                }
                                else {
                                    if (currentData["@Payer"] === "") {
                                        if (currentData["@Payer"] === "" && currentData["@Code"] === codeValue && currentData["@CodeType"] === codeTypeValue) {
                                            isDuplicate = true;
                                        }
                                    }
                                }
                            }
                        })
                    }
                    else {
                        currentPayerStatusAssignmentsData.map((currentData) => {
                            if (currentData["@IndexId"] === this.props.dataStore.ui.IndexId) {
                                let payerDetail = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.PayerList?.Payer.find(ob => ob["@ID"] === this.props.dataStore.ui.Payer);
                                if (payerDetail !== undefined) {
                                    if (payerDetail["@Name"] === currentData["@Payer"] && currentData["@Code"] === codeValue && currentData["@CodeType"] === codeTypeValue) {
                                        isDuplicate = true;
                                    }
                                    if (currentData["Facilities"] !== undefined && currentData["Facilities"]["@ID"] !== "0") {

                                        if (currentData["Facilities"]["@ID"] === this.props.dataStore.ui.Facility) {
                                            isHavingFacility = true;
                                        }
                                        else {
                                            if (this.props.dataStore.ui.Facility === "0" || this.props.dataStore.ui.Facility === "") {
                                                isHavingNewFacility = false;
                                            }
                                            else
                                                isHavingNewFacility = true;

                                        }
                                    }

                                }
                                else {
                                    if (currentData["@Payer"] === "") {
                                        if (currentData["@Payer"] === "" && currentData["@Code"] === codeValue && currentData["@CodeType"] === codeTypeValue) {
                                            isDuplicate = true;
                                        }
                                    }
                                }
                            }
                        })
                    }
                    if (isDuplicate) {
                        if (isHavingFacility) {
                            this.setState({ isAlertOpen: true, alertMessage: "Assignment already exists for the selected Payer/Code/Facility" });
                            isValid = false;
                        }
                        else {
                            this.setState({ isAlertOpen: true, alertMessage: "Assignment already exists for the selected Payer/Code" });
                            isValid = false;
                        }

                    }
                    else {
                        isValid = true;
                    }
                }
                else
                    isValid = true;
            }

        }
        return isValid;
    }

    public getTabs(): any {

        var tabs = [];
        var sc = { label: 'Status Code', domID: 'tab-sc' };
        var cc = { label: '277 Category', domID: 'tab-cc' };
        var rl = { label: 'Recon Level', domID: 'tab-rl' };
        var rsc = { label: 'Remit Code', domID: 'tab-rsc' };
        var mrc = { label: 'Medicare Reason Code', domID: 'tab-mrc' };
        tabs.push(sc);
        tabs.push(cc);
        let reconciliation = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "Reconciliation");
        if (reconciliation !== undefined && reconciliation.value === "1") {
            tabs.push(rl);
        }

        tabs.push(rsc);
        tabs.push(mrc);
        return tabs;
    }

    public setActiveTab(id: string, index: any) {
        this.setState({ activeTabId: id, activeTab: index.selectedTabIndex });

    }

    public onTabSelect(event: any, index: any) {
        if (event !== undefined) {
            this.setActiveTab(event.currentTarget.id, index);
        }
    }

    public getInitialTab(): number {
        return this.state.activeTab;
    }

    public setClassNameChange = (obj: any | undefined) => {
        let data: any = [];
        data = this.state.currentClickedRowID;
        let datAlreadyExist = data.find((ob: any) => ob === obj?.["@IndexId"]);
        if (datAlreadyExist !== undefined) {
            const index = data.indexOf(obj?.["@IndexId"]);
            if (index > -1) {
                data.splice(index, 1);
            }
        }
        else {
            data.push(obj?.["@IndexId"]);
        }
        if (data !== undefined && data.length > 1) {
            this.setState({ isDeleteButton: false, isUpdateButton: true })
        }
        else if (data !== undefined && data.length === 0) {
            this.setState({ isDeleteButton: true, isUpdateButton: true })
        }
        else {
            this.setState({ isDeleteButton: false, isUpdateButton: false })
        }
        this.setState({ currentClickedRowID: data });
        if (data !== undefined && data.length > 1) {
            return false;
        }
        else {
            return data !== undefined && data.length === 0 ? false : true;
        }
    }

    public render() {
        var tableStyle: React.CSSProperties = {
            backgroundColor: 'white',
            width: "100%",
            padding: "5px"
        }
        var tableStyleDepartment: React.CSSProperties = {
            backgroundColor: 'white',
            width: "100%",
            padding: "5px"
        }
        var tabelHeaderStyle: React.CSSProperties = {
            backgroundColor: '#9ba1a9',
            padding: "6px",
            color: "white"
        }

        var tableHeaderCellStyle: React.CSSProperties = {
            padding: "5px",
            textAlign: "left",
            fontSize: "12px",

        }

        var tableHeaderCellStyleDepartment: React.CSSProperties = {
            padding: "5px",
            textAlign: "center",
            width: "85%",
            fontSize: "12px"
        }

        var tableHeaderCellStyleCurrentAssignment: React.CSSProperties = {
            padding: "5px",
            textAlign: "center",
            fontSize: "12px"
        }
        var tableHeaderCellStyleCurrentAssignmentPayer: React.CSSProperties = {
            padding: "5px",
            textAlign: "center",
            fontSize: "12px",
            width: "150px"
        }
        var tableHeaderCellStyleCurrentAssignmentCode: React.CSSProperties = {
            padding: "5px",
            textAlign: "center",
            fontSize: "12px",
            width: "75px"
        }
        var tableHeaderCellStyleCurrentAssignmentMessage: React.CSSProperties = {
            padding: "5px",
            textAlign: "center",
            fontSize: "12px",
            width: "550px"
        }
        var tableHeaderCellStyleCurrentAssignmentAssigned: React.CSSProperties = {
            padding: "5px",
            textAlign: "center",
            fontSize: "12px",
            width: "300px"
        }

        var spanStyle: React.CSSProperties = {
            fontSize: "11px"
        }

        var spanStyleCenter: React.CSSProperties = {
            fontSize: "11px",
            textAlign: "center"
        }

        var tableRowStyle: React.CSSProperties = {
            backgroundColor: "white"
        }

        var tableRowSelectedStyle: React.CSSProperties = {
            backgroundColor: "red"
        }

        var instruction = <>Search for payer status information and assign future claims with that information to one or more users or departments.<br /> <i>(For more information, click the help button.)</i></>;
        var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;


        let assignments: any = [];
        if (this.props.dataStore.crud.data !== undefined) {
            assignments = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.UserList?.User?.map((item) => {

                let itemValue = item['@LastName'] + " " + item['@FirstName'] + " (" + item['@Name'] + ")";
                let selectedObject = this.props.dataStore.ui.Users?.find(ob => ob["@ID"] === item["@ID"]);
                return (<tr>
                    <td>
                        <CheckBoxComponent
                            id='remit-immediate'
                            label=''
                            width="20px"
                            checked={selectedObject !== undefined ? selectedObject["@IsChecked"] : false}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateCheckAssignments(e, item)}
                        />
                    </td>
                    <td onClick={(e) => this.updateCheckAssignmentsUser(item)}><span style={spanStyle} >{itemValue}</span></td>
                    <td> <Input
                        domID="alphaSplit"
                        maxLength={40}
                        initialValue={selectedObject !== undefined ? selectedObject["@AlphaSplit"] : ""}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updatAlphaSplit(e, item)}
                    /></td>
                </tr>)
            })
        }

        let departments: any = [];
        if (this.props.dataStore.crud.data !== undefined) {
            departments = this.props.dataStore.crud.data?.ClaimAssignmentInfo?.DepartmentList?.Department?.map((item) => {

                return (<tr>
                    <td>
                        <RadioButtonComponent
                            id="enabled-account-id"
                            width="35px"
                            checked={this.props.dataStore.ui.Department === item["@ID"]}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateDepartment(e, item)}
                            disabled={false}
                        /></td>
                    <td onClick={(e) => this.updateDepartmentSpan(item)}><span style={spanStyle}>{item["@Name"] + " (" + item["@Priority"] + ")"}</span></td>
                </tr>
                )
            });
        }

        let addItemMedicare: MCAPSR_GenericMessageLookupList[] = [{
            '@ID': '',
            '@Message': '- Select Medicare Reason Code -',

        }];

        let medicareReasonCodes: MCAPSR_GenericMessageLookupList[] = [];

        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.ClaimAssignmentInfo &&
            this.props.dataStore.crud.data.ClaimAssignmentInfo.DDEErrorCodeList &&
            this.props.dataStore.crud.data.ClaimAssignmentInfo.DDEErrorCodeList.DDEErrorCode) {
            let listMedicareReasonCodes: MCAPSR_GenericMessageLookupList[] = [];
            if (!Array.isArray(this.props.dataStore.crud.data.ClaimAssignmentInfo.DDEErrorCodeList.DDEErrorCode)) {
                listMedicareReasonCodes.push(this.props.dataStore.crud.data.ClaimAssignmentInfo.DDEErrorCodeList.DDEErrorCode);
            }
            else
                listMedicareReasonCodes = this.props.dataStore.crud.data.ClaimAssignmentInfo.DDEErrorCodeList.DDEErrorCode;

            medicareReasonCodes = listMedicareReasonCodes
                .map((errorCode: { [x: string]: any; }) => {
                    let dispPayer: MCAPSR_GenericMessageLookupList = {
                        '@ID': errorCode["@ID"],
                        '@Message': errorCode["@Message"]
                    };
                    return dispPayer;
                });
        }
        medicareReasonCodes = addItemMedicare.concat(medicareReasonCodes);


        let currentPayerAssignments: any = [];
        if (this.props.dataStore.ui?.StatusRules !== undefined) {

            currentPayerAssignments = this.props.dataStore.ui?.StatusRules?.map((item: any) => {

                let classNameData = this.state.currentClickedRowID.find(ob => ob === item["@IndexId"]);
                return (<tr onClick={(e) => { this.OnrowChanged(item); }} className={(classNameData !== undefined && classNameData === item["@IndexId"]) ? 'tableRowSelected' : 'tableRowNotSelected'}>
                    <td>
                        <span style={spanStyle}>{item["@Payer"] !== "" ? item["@Payer"] : "All Payers"}</span>
                    </td>
                    <td style={spanStyleCenter}><span style={spanStyle}>{item["@Code"]}</span>
                    </td>
                    <td style={spanStyle}><span style={spanStyle}>{this.CodeValueMessage(item["@CodeType"], item["@Code"])}</span>
                    </td>
                    <td><span style={spanStyle}>{this.AssignedToMessage(item["Departments"], item.Users !== "" ? item.Users : [])}</span>
                    </td>
                    <td style={spanStyleCenter}><span style={spanStyle}>{item["@Expires"] !== undefined && item["@Expires"] !== "" ? item["@Expires"] : "Never"}</span>
                    </td>
                    <td style={spanStyleCenter}><span style={spanStyle}>{this.getFacilities(item["Facilities"])}</span>
                    </td>
                </tr>)
            })
        }
        let reconciliation = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "Reconciliation");

        return (
            <>
                <DialogWrapper title="Manage Claim Assignments Payer Status Rules" width='1200px' instruction={instruction} helpUrl='/Support/Help/HELP_Maint_ClaimAssignment_PayerStatus.htm' buttons={buttons} isBusy={this.state.isLoaded}>
                    {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                    <ManageClaimContent>
                        <DialogFieldset id='assignmentsstatusrules-fieldset'>
                            <DialogLegend>Payer Status Code Assignment</DialogLegend>
                            <ContentRowWrapper style={{ width: "100%" }}>
                                <ContentColumnWrapper style={{ marginLeft: "5px", width: "28%" }}>
                                    <ContentRowWrapper>
                                        <SelectComponent
                                            title='Payer'
                                            label='Payer'
                                            size={35}
                                            height='150px'
                                            width='100%'
                                            selectedValue={this.props?.dataStore?.ui?.Payer !== undefined ? this.props?.dataStore?.ui?.Payer : ''}
                                            onSelect={(e: any) => { this.updateMCAPSR(e.target.value, "PAYER") }}
                                            optionFields={{
                                                value: "@ID",
                                                text: "@Message"
                                            }}
                                            tooltip={true}
                                            records={this.selectComponentDataName(!this.props.dataStore.crud.data ||
                                                !this.props.dataStore.crud.data.ClaimAssignmentInfo
                                                ? [] : this.props.dataStore.crud.data.ClaimAssignmentInfo.PayerList.Payer, '- All Payers -')}
                                        ></SelectComponent>
                                    </ContentRowWrapper>
                                    <ContentRowWrapper>
                                        <ARMDatePicker
                                            domID="expireson"
                                            label="Expires On"
                                            className="cal_style"

                                            allowTime={false}
                                            onInputChange={(e: any) => { this.updateMCAPSR(e, "EXPIRESON") }}
                                            minDate={this.getDateInMMDDYYYYFormat(new Date())}
                                            initialDate={this.props?.dataStore?.ui?.ExpiresOn !== undefined ? this.props?.dataStore?.ui?.ExpiresOn : ''}
                                            disableHowLong={true}
                                        />
                                    </ContentRowWrapper>
                                </ContentColumnWrapper>
                                <ContentColumnWrapper style={{ marginLeft: "20px", width: "72%" }}>
                                    <ContentRowWrapper id={"tab-container"} style={{ maxHeight: '30px' }}>
                                        <ContentColumnWrapper>
                                            <Tabs
                                                tabs={this.getTabs()}
                                                onTabSelect={(e: any, index: number) => { this.onTabSelect(e, index) }}
                                                initialTab={this.getInitialTab()}
                                            />
                                        </ContentColumnWrapper>
                                    </ContentRowWrapper>
                                    { /****** STATUS CODE TAB ******/}
                                    {this.state.activeTab === 0 && this.state.activeTabId === "tab-sc" &&
                                        <ContentRowWrapper>
                                            <ContentColumnWrapper style={{ marginTop: "10px" }}>
                                                <SelectComponent
                                                    title='Status Code'
                                                    label=''
                                                    size={35}
                                                    height='190px'
                                                    width='100%'
                                                    tooltip={true}
                                                    selectedValue={this.props?.dataStore?.ui?.StatusCode !== undefined ? this.props?.dataStore?.ui?.StatusCode : ''}
                                                    onSelect={(e: any) => this.updateMCAPSR(e.target.value, "STATUSCODE")}
                                                    optionFields={{
                                                        value: "@ID",
                                                        text: "@Message"
                                                    }}
                                                    records={this.selectComponentDataMessage(!this.props.dataStore.crud.data ||
                                                        !this.props.dataStore.crud.data.ClaimAssignmentInfo
                                                        ? [] : this.props.dataStore.crud.data.ClaimAssignmentInfo.StatusCodeList.StatusCode, '- Select Status Code -')}
                                                ></SelectComponent>
                                            </ContentColumnWrapper>
                                        </ContentRowWrapper>
                                    }
                                    { /****** 277 CATEGORY TAB ******/}
                                    {this.state.activeTab === 1 && this.state.activeTabId === "tab-cc" &&
                                        <ContentRowWrapper>
                                            <ContentColumnWrapper style={{ marginTop: "10px" }}>
                                                <SelectComponent
                                                    title='277 Category'
                                                    label=''
                                                    size={35}
                                                    height='190px'
                                                    width='100%'
                                                    tooltip={true}
                                                    selectedValue={this.props?.dataStore?.ui?.Category277Code !== undefined ? this.props?.dataStore?.ui?.Category277Code : ''}
                                                    onSelect={(e: any) => this.updateMCAPSR(e.target.value, "CATEGORY277CODE")}
                                                    optionFields={{
                                                        value: "@ID",
                                                        text: "@Message"
                                                    }}
                                                    records={this.selectComponentDataMessage(!this.props.dataStore.crud.data ||
                                                        !this.props.dataStore.crud.data.ClaimAssignmentInfo
                                                        ? [] : this.props.dataStore.crud.data.ClaimAssignmentInfo.Category277CodeList.Category277Code, '- Select 277 Category -')}
                                                ></SelectComponent>
                                            </ContentColumnWrapper>
                                        </ContentRowWrapper>
                                    }
                                    { /****** RECON LEVEL TAB ******/}
                                    {this.state.activeTab === 2 && this.state.activeTabId === "tab-rl" &&
                                        <ContentRowWrapper>
                                            <ContentColumnWrapper style={{ marginTop: "10px" }}>
                                                <SelectComponent
                                                    title='Recon Level'
                                                    label=''
                                                    size={35}
                                                    height='190px'
                                                    width='100%'
                                                    tooltip={true}
                                                    selectedValue={this.props?.dataStore?.ui?.ReconLevelCode !== undefined ? this.props?.dataStore?.ui?.ReconLevelCode : ''}
                                                    onSelect={(e: any) => this.updateMCAPSR(e.target.value, "RECONLEVEL")}
                                                    optionFields={{
                                                        value: "@ID",
                                                        text: "@Message"
                                                    }}
                                                    records={this.selectComponentDataMessage(!this.props.dataStore.crud.data ||
                                                        !this.props.dataStore.crud.data.ClaimAssignmentInfo
                                                        ? [] : this.props.dataStore.crud.data.ClaimAssignmentInfo.ReconLevelList.ReconLevel, '- Select Recon Level -')}
                                                ></SelectComponent>
                                            </ContentColumnWrapper>
                                        </ContentRowWrapper>
                                    }


                                    { /****** REMIT CODE TAB ******/}
                                    {this.state.activeTab === (reconciliation !== undefined && reconciliation.value === "1" ? 3 : 2) && this.state.activeTabId === "tab-rsc" &&
                                        <ContentRowWrapper>
                                            <ContentColumnWrapper style={{ marginTop: "10px" }}>
                                                <SelectComponent
                                                    title='Remit Code'
                                                    label=''
                                                    size={35}
                                                    height='190px'
                                                    width='100%'
                                                    tooltip={true}
                                                    selectedValue={this.props?.dataStore?.ui?.RemitCode !== undefined ? this.props?.dataStore?.ui?.RemitCode : ''}
                                                    onSelect={(e: any) => this.updateMCAPSR(e.target.value, "REMITCODE")}
                                                    optionFields={{
                                                        value: "@ID",
                                                        text: "@Message"
                                                    }}
                                                    records={this.selectComponentDataMessage(!this.props.dataStore.crud.data ||
                                                        !this.props.dataStore.crud.data.ClaimAssignmentInfo
                                                        ? [] : this.props.dataStore.crud.data.ClaimAssignmentInfo.RemitStatusCodeList.RemitStatusCode, '- Select Remit Code -')}
                                                ></SelectComponent>
                                            </ContentColumnWrapper>
                                        </ContentRowWrapper>
                                    }

                                    { /****** MEDICARE REASON CODE TAB ******/}
                                    {this.state.activeTab === (reconciliation !== undefined && reconciliation.value === "1" ? 4 : 3) && this.state.activeTabId === "tab-mrc" &&
                                        <ContentRowWrapper>
                                            <ContentColumnWrapper style={{ marginTop: "10px" }}>
                                                <SelectComponent
                                                    title='Medicare Reason Code'
                                                    label=''
                                                    size={35}
                                                    height='190px'
                                                    width='100%'
                                                    tooltip={true}
                                                    selectedValue={this.props?.dataStore?.ui?.DDEErrorCode !== undefined ? this.props?.dataStore?.ui?.DDEErrorCode : ''}
                                                    onSelect={(e: any) => this.updateMCAPSR(e.target.value, "DDEERRORCODE")}
                                                    optionFields={{
                                                        value: "@ID",
                                                        text: "@Message"
                                                    }}
                                                    records={this.selectComponentDataMessage(!this.props.dataStore.crud.data ||
                                                        !this.props.dataStore.crud.data.ClaimAssignmentInfo
                                                        ? [] : this.props.dataStore.crud.data.ClaimAssignmentInfo.DDEErrorCodeList.DDEErrorCode, '- Select Medicare Reason Code -')}
                                                ></SelectComponent>
                                            </ContentColumnWrapper>
                                        </ContentRowWrapper>
                                    }
                                </ContentColumnWrapper>
                            </ContentRowWrapper>

                            <ContentRowWrapper>
                                <ContentColumnWrapper>
                                    <DialogFieldset id='assignmentsstatusrules-fieldset' widthValue="800px">
                                        <DialogLegend>Assignment</DialogLegend>
                                        <ContentRowWrapper>
                                            <ContentColumnWrapper>
                                                <TableContent>
                                                    <table style={tableStyle}>
                                                        <thead style={tabelHeaderStyle}>
                                                            <th>
                                                            </th>
                                                            <th style={tableHeaderCellStyle}>User
                                        </th>
                                                            <th style={tableHeaderCellStyle}>Alpha Split
                                        </th>
                                                        </thead>
                                                        <tbody>
                                                            {assignments}
                                                        </tbody>
                                                    </table>
                                                </TableContent>
                                            </ContentColumnWrapper>
                                            <ContentColumnWrapper>
                                                <TableContent>
                                                    <table style={tableStyleDepartment}>
                                                        <thead style={tabelHeaderStyle}>
                                                            <th>
                                                            </th>
                                                            <th style={tableHeaderCellStyleDepartment}>Department
                                        </th>

                                                        </thead>
                                                        <tbody>
                                                            {departments}
                                                        </tbody>
                                                    </table>
                                                </TableContent>
                                            </ContentColumnWrapper>
                                        </ContentRowWrapper>
                                    </DialogFieldset>
                                </ContentColumnWrapper>
                                {this.props.dataStore.ui.FacilityLookup === "1" ?
                                    <ContentColumnWrapper>
                                        <DialogFieldset id='assignmentsstatusrules-fieldset' widthValue="95%">
                                            <DialogLegend>Facilities</DialogLegend>
                                            <ContentRowWrapper>
                                                {this.props?.dataStore?.ui?.FacilityLookup !== undefined ? this.props?.dataStore?.ui?.FacilityLookup === "1" &&
                                                    <ContentColumnWrapper>
                                                        <SelectComponent
                                                            title='Facility'
                                                            label=''
                                                            size={35}
                                                            width='100%'
                                                            height='150px'
                                                            tooltip={true}
                                                            selectedValue={this.props?.dataStore?.ui?.Facility !== undefined ? this.props?.dataStore?.ui?.Facility : ''}
                                                            onSelect={(e: any) => this.updateMCAPSR(e.target.value, "FACILITY")}
                                                            optionFields={{
                                                                value: "@ID",
                                                                text: "@Message"
                                                            }}
                                                            records={this.selectComponentDataName(!this.props.dataStore.crud.data ||
                                                                !this.props.dataStore.crud.data.ClaimAssignmentInfo
                                                                ? [] : this.props.dataStore.crud.data.ClaimAssignmentInfo.FacilityList.Facility, '- Select Facility -')}
                                                        ></SelectComponent>
                                                    </ContentColumnWrapper>
                                                    : ""}
                                            </ContentRowWrapper>
                                        </DialogFieldset>
                                    </ContentColumnWrapper>
                                    : ""}
                            </ContentRowWrapper>
                            <ContentRowWrapper>
                                <SelectButtons>
                                    <Button
                                        domID="MCAPSR_addButton"
                                        name="ADD"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        disabled={this.state.isAddButton}
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickAddButton(e)}
                                    />
                                    <Button
                                        domID="MCAPSR_addButton"
                                        name="UDPATE"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        disabled={this.state.isUpdateButton}
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickModifyButton(e)}
                                    />
                                    <Button
                                        domID="MCAPSR_removeButton"
                                        name="Remove"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickRemoveButton(e)}
                                        disabled={this.state.isDeleteButton}
                                    />
                                </SelectButtons>
                            </ContentRowWrapper>
                        </DialogFieldset>

                        <ContentRowWrapper>
                            <DialogFieldset id='currentAssignments-fieldset' widthValue="100%">
                                <DialogLegend>Current Payer Status Assignments</DialogLegend>
                                <TableContent>
                                    <table style={tableStyle} id="currentAssignments">
                                        <thead style={tabelHeaderStyle}>
                                            <th style={tableHeaderCellStyleCurrentAssignmentPayer}>
                                                Payer
                                        </th>
                                            <th style={tableHeaderCellStyleCurrentAssignmentCode}>Code
                                        </th>
                                            <th style={tableHeaderCellStyleCurrentAssignmentMessage}>Message
                                        </th>
                                            <th style={tableHeaderCellStyleCurrentAssignmentAssigned}>Assigned To
                                        </th>
                                            <th style={tableHeaderCellStyleCurrentAssignment}>Expires
                                        </th>
                                            <th style={tableHeaderCellStyleCurrentAssignment}>Facilities
                                            </th>
                                        </thead>
                                        <tbody>
                                            {currentPayerAssignments}
                                        </tbody>
                                    </table>
                                </TableContent>
                            </DialogFieldset>
                        </ContentRowWrapper>
                    </ManageClaimContent>
                    <ModalConfirmation
                        isOpen={this.state.isAlertOpen}
                        formattedMessage={(
                            <React.Fragment>
                                <p>{this.state.alertMessage}.</p>
                            </React.Fragment>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isAlertOpen: false, alertMessage: "" }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.setState({ isAlertOpen: false, alertMessage: "" })

                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isAlertOpen: false }) }}
                        alertMode={this.state.isAlertOpen}
                    />
                </DialogWrapper>
            </>
        );
    }
};

var connectedHoc = connect<IPayerStatusRuleState, IPayerStatusRuleActionProps, IOwnProps, IClaimNoteTypeProps, ApplicationState>(
    createCrudMapStateToProps('manageClaimAssignmentsPayerStatusRules'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ManageClaimAssignmentsPayerStatusRules);

export default withRouter(connectedHoc);
