import { Reducer } from 'redux';
import { createAction, createDataAction, createApiBodyAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { URLs } from '@commonDevResources/constants';
import { OperationFilter } from "@store/OperationalDashboard/DrillDownView"
import { ISelectionView, ISelectionMeasure, ISelectionDateRange, IDashboardFilterState } from "@store/OperationalDashboard/DashboardDefault"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IDashboardEligibilityState extends IDashboardFilterState {
    eligibilityData?: any;
    meterData?: any;
}


//getCiamUserDetails: (userInfo: ISelectUser) => createApiAction('GET_CIAM_USER_DETAILS', `${URLs.api}/api/data/ciam/getUser/${userInfo.uiData.value}`, userInfo),

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    getEligibilityData: (operationFilter: OperationFilter) => createApiBodyAction('GET_ELIGIBILITY_DATA', `${URLs.api}/api/data/dashboard/operations`, undefined, "POST", JSON.stringify(operationFilter)),
    resetState: () => createAction('DASHBOARD_ELIGIBILITY_RESET_STATE'),
    selectView: (selectedView: ISelectionView) => createDataAction('DASHBOARD_ELIGIBILITY_SELECT_VIEW', selectedView),
    selectMeasure: (selectedMeasure: ISelectionMeasure) => createDataAction('DASHBOARD_ELIGIBILITY_SELECT_MEASURE', selectedMeasure),
    selectDateRange: (selectedDateRange: ISelectionDateRange, startDate: string, endDate: string) => createDataAction('DASHBOARD_ELIGIBILITY_SELECT_DATE_RANGE', { selectedDateRange, startDate, endDate }),

};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IDashboardEligibilityState = {
    selectedView: { label: 'Charts', value: '0' },
    selectedMeasure: { label: 'Count', value: '2' },
    selectedDateRange: { label: 'Last 90 days', value: '6' },
    selectedStartDate: '',
    selectedEndDate: '',
    uiLock: "Unlocked"
};


// ----------------
// REDUCER - For a given state and actio returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IDashboardEligibilityState, KnownActions> = (state: IDashboardEligibilityState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'DASHBOARD_ELIGIBILITY_RESET_STATE':
                return defaultState;
            case 'DASHBOARD_ELIGIBILITY_SELECT_VIEW':
                {
                    return {
                        ...state,
                        selectedView: action.data,
                    }
                }
            case 'DASHBOARD_ELIGIBILITY_SELECT_MEASURE':
                {

                    return {
                        ...state,
                        selectedMeasure: action.data,
                    }
                }
            case 'DASHBOARD_ELIGIBILITY_SELECT_DATE_RANGE':
                {

                    return {
                        ...state,
                        selectedDateRange: action.data.selectedDateRange,
                        selectedStartDate: action.data.startDate,
                        selectedEndDate: action.data.endDate
                    }
                }
            case 'GET_ELIGIBILITY_DATA':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        return {
                            ...state,
                            eligibilityData: JSON.parse(JSON.stringify(action.responseData)),
                        }
                    case "FAIL":
                        return {
                            ...state,
                            eligibilityData: JSON.parse(JSON.stringify(action.responseData)),
                        }
                    default:
                        // The following line guarantees that every action in the KnownAction union has been covered by a case above
                        // const exhaustiveCheck: never = action;
                        return state;
                }

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
