import styled from 'styled-components';
import { Typography } from '@commonResources/typography';


export const GridWrapper = styled.div`    
    #grid-wrapper-add-facility-multiselect-grd {
        ${Typography.ARMFontFamily};
    }
`
export const CheckBoxContainer = styled.div`
    .pm_checkbox_label_container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-right: 10px;
     }

    .label_left { 
        padding-right: 5px !important;
    }
    .label_right {
        padding-right: 10px !important;
    }

    .pm_checkbox_label {
        padding-top: 2px;
        font-family: 'Core Sans C', 'Century Gothic', AppleGothic, sans - serif;
        font-size: 10pt; 
        font-weight: 600;
   }

    #pm_checkbox_cci_left_wrapper, #pm_checkbox_cci_right_wrapper,
    #pm_checkbox_mn_left_wrapper, #pm_checkbox_mn_right_wrapper {
        width: auto;
      
    }

    #pm_checkbox_cci_left,
    #pm_checkbox_mn_left {
        margin-left: 10px;
        margin-left: 0px;
        label {
            padding: 0px;
        }
    }
    #pm_checkbox_cci_left_label {
        padding-right: 27px; 
    }
    #pm_checkbox_mn_left_label {
        padding-right: 30px; 
    }

    #pm_checkbox_cci_left,
    #pm_checkbox_mn_left { 
        label {
            padding: 0px;
        }
    }

`;


export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

export const WarningWrapper = styled.div`
    text-align: left;
    border: 1px solid #000;
    margin: 0px 50px;
    padding-top: 0px;
    width:950px;
    p {
      margin: 5px;
    }
`;

export const RedSpan = styled.span`
    color: red; 
`;


export const PhysicianMaintenanceLeftContent = styled.div`
    display: flex;
    flex: 1 1 auto;   
    flex-direction: column;
    padding: 5px;
    
    width: 340px;
    width: 150px;

    #physicians-fieldset {
        width:300px;
        input {
            height: auto;
        }
    }

    #ArmTabContainer {
        width: inherit;
    }
 
    #id-Search, #id-Results { 
        width: 160px;
    }
 
    #last-nam,#first-value,#tax-id,#key-no,#physician-no {
        width: 160px;
        
        padding-right: 10px;
        margin-right: 10px;
     }
    #thru,#thru1,#thru2,#thru3,#thru4 {
        width: 160px;
         
       
    }
    #searchresults-textsearch {
        width: 330px;
         padding-right: 10px;
        margin-top: 10px;
        margin-bottom: 5px;
    }
`;
export const PhysicianDetailsLeftWrapper = styled.div`
    width: 415px;
    padding-left: 0px;
`;

export const ButtonWrapper = styled.span`
   z-index:100;
`;
export const Facilitywrapper = styled.div`
    padding-top:20px;
`;
export const Helpwrapper = styled.div`
    padding-top:20px;
    position: relative;
    z-index: 1;
#btnCategory,#btnCategory:hover,#btnStatus,#btnStatus:hover
                {
                    padding-left:0px !important;
                    width:35px !important;
                    background-color:#ebeced !important;
                    border:1px solid #ebeced;
                }
                #btnCategory svg,#btnStatus svg
                {
                    width:35px;
                    height:30px;
                }
`;

export const PhysicianMaintenanceContent = styled.div`
    input {
        height: auto;
    }
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    padding-top: 15px;

    min-height: 180px;
    width: 800px;

    #first-name {
        width: 250px;
        padding-right: 10px;
        margin-right: 10px;
    }
    #middle-initial {
        width: 40px;
        padding-right: 10px;
        margin-right: 10px;
    }
    #last-name{
        width: 340px;
    }
    #address-type { 
        width: 450px;
        padding-right: 10px;
        margin-right: 10px;
    }
    #address-type2 { 
        width: 190px;
        padding-right: 10px; 
    }
    #city-type {
        width: 240px;
        padding-right: 10px;
        margin-right: 10px;
     }
    #state-type {
        width: 145px;
        padding-right: 10px;
       }
    #zip-type {
        width: 255px;
    }
    #telephone,#npi-value {
        width: 215px;
        padding-right: 10px;
        margin-right: 10px;
    }

    #taxonomy-value {
        width: 150px;
        margin-right: 10px;
    }
    #taxid-value,#ssn-value {
        width: 190px;
        padding-right: 10px;
        margin-right: 10px;
    }
    #id-type {
      width: fit-content;  
    }
    #id-type-select {
        width: 250px;
        margin: 0px;
        max-width: inherit;
    }
    
    #keyno {
        width: 240px;
        padding-right: 10px;
        margin-right: 10px;
    }
    #physican-no {
        width: 235px;
        padding-right: 10px;
        margin-right: 10px;
    }
    #description {
        width: 150px;
        padding-right: 10px;
        margin-right: 10px;
    }
    #payer-description {
        margin-right: 10px;
        width: 310px;
    }

    #npi1 {
        width: 350px;
        width: 120px;
        margin-right: 10px;
    }
 
    #taxonomy {
        width: 150px;
        margin-right: 10px;
    }
    #lob {
        width:190px;
    }
    #facility_Payer-select {
        // margin-left: 10px
    }
    #facility_Payer{ 
        width: 685px;
    }
    
    #AddIcon {
        margin-top: 5px;
    }
    #Phy_payer{     
        width: 250px; 
    }
    #pm-payer-lob-dropdown{
        width: 10.5em;
    }
    #specialty-code {
        width: 215px;
        padding: 0px 10px;
        // margin-right: 10px; 
    }
    #account-test {
        padding-right: 10px;
        margin-right: 10px;
        padding-top: 20px;
    }

`;
export const PhysicianDetailsRightWrapper = styled.div`
    padding-left:0px;
    width: 350px;
    height: fit-content;
    #groupno,
    #groupsuffix,
    #doctorno, 
    #locCode {
         width: 150px;
    }
    #billing-dept,
    #acct-no,
    #id-code,
    #dept-flag {
        width: 150px;
    }
`;

export const RowWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    // width: 100%;
    #account_status {
        padding-top: 20px;
        font-size:10px;
        font-family: 'Core Sans C','Century Gothic',AppleGothic,sans-serif;
        line-height: 25px;
    }
`;

export const ToolTip = styled.div` 
    position: relative;
    display: inline-block;  

    .text {
      ${Typography.ARMFontFamily};
      visibility: hidden;
      width: max-content;
      background-color: white;
      color: black;
      text-align: center;
      padding: 5px; 
      font-size: 15px;
      position: absolute;
      z-index: 1;
      top: -50px;
      left: 0;
      right: 0;
      text-align: center;
      border: 1px solid black;
    }
 
    &:hover .text {
      visibility: visible;
      transition-delay: 1s;
    }
`
export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const BeforeWrapper = styled.div`
    text-align: left;
    border: 1px solid #000; 
    padding-left:5px;
    width:330px;
`;

export const CheckboxWrapper = styled.div`
    padding-top: 25px;
    width: 0px;
    padding-bottom: 5px;
    padding-top: 21px;
    padding-left: 6px; 
    label {
        font-size: 15px;
        margin-right: 5px;
        padding-left: 5px;
    }
`;

export const TextWrapper = styled.div`
    text-align: left;
    width:190px;
`;

export const EmptyWrapper = styled.div`
    width:180px;
`;

export const ModalWrapper = styled.div`
    height: 500px;
`;

export const ModalFilterListBody = styled.div`
    justify-content: center;

    #filterlist-filtername{
        width: 360px;
    }
`;

export const ModalFilterListInnerContent = styled.div`
    display: inline-block;
    margin: 0 auto;
`;


export const ModalFilterListDivide = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.normal};
    ${Typography.default};
    margin: 20px;
    text-align: center;

`;

export const ModalFilterListMessageWrapper = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.normal};
    ${Typography.default};
    padding-bottom: 20px;
    text-align: left;
    width: 360px;
`;

export const ButtonRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 6px;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const ModalFilterListSelectList = styled.div`
    padding: 5px;
    overflow-y: scroll;
    height: 460px;
`;
