interface File_Types {
    '@Name': string;
    '@ID': string;
};

import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as FileTypeUI from './ui/FileTypeUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IFileTypeState = ICrudComponentState<MCFileTypeSettingsMaintenanceInfo, FileTypeUI.IFileTypeUIState>;

export const actionCreators = createCrudComponentActions<FileTypeUI.ActionCreators>(FileTypeUI.actionCreators);
export type IFileTypeActions = typeof actionCreators;
export type IFileTypeActionProps = ICrudComponentActionProps<IFileTypeActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiFileTypeConfig, FileTypeUI.reducer);
export const reducer = combineReducers<IFileTypeState>(reducers);

export const validationCallback: ValidationCallback<MCFileTypeSettingsMaintenanceInfo> = (crud: any): crud is MCFileTypeSettingsMaintenanceInfo => {
    let testCrud: MCFileTypeSettingsMaintenanceInfo = crud as MCFileTypeSettingsMaintenanceInfo;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCFileTypeSettingsMaintenanceInfo");
    if (validShape) {
        VerifyArray(testCrud?.FileDownloadMaintenanceInfo?.ReportActionList, "ReportAction");
        VerifyArray(testCrud?.FileDownloadMaintenanceInfo?.PrinterList, "Printer");
        VerifyArray(testCrud?.FileDownloadMaintenanceInfo?.FileSettings, "FileSetting");

    }
    return validShape;
}