import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getRawToken } from "@scripts/session/SecurityToken";

import { IMergeCrudComponentProps } from '@scripts/util/CrudComponentHelpers';

import { URLs } from "@commonResources/constants";
import { ApplicationState } from '@store/index';
import { IMergeComponentProps } from '../../../scripts/util/ReduxHelpers';

/////////////////////////////////////////////////////////////////////////////////////////////

interface IAgentClientComponentProps {
    canView: boolean,
    apiType: string;
}

interface IAgentClientComponentState {
}

export const DEFAULT_STATE: IAgentClientComponentState = {

};

type IOwnProps = IAgentClientComponentProps & RouteComponentProps<{}>;
type IIAgentClientProps = IMergeComponentProps<IAgentClientComponentState, IAgentClientComponentProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class AgentClientInstall extends React.Component<IIAgentClientProps, IAgentClientComponentState> {

    static defaultProps: IAgentClientComponentProps = {
        canView: false,
        apiType: "AgentClientInstall",
    };

    constructor(props: IIAgentClientProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        
        } else {
            this.LaunchAgentClientInstall();
        }


    }

    public componentWillUnmount() {
    }

    public LaunchAgentClientInstall() {

        var cap = "Note: You are about to install the auto agent client, running this process multiple times may cause unknown behavior. If you are upgrading an existing Agent installed as a program, make sure you exit the program before continuing. If you are upgrading an existing Agent installed as a service, make sure you uninstall the service before continuing.";
        var response = confirm(cap)
        if (response != true) {
            setTimeout(() => {
                this.props.history.push('/LandingPage')
            }, 100);
        }


    }

    public render() {
        return (
            <div id='agent-install-wrapper' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '26px' }}>
                <iframe id='agent-install-iframe' style={{ height: '420px', width: '600px' }} title="static_html" src="../../../AgentClient/publish.htm"></iframe>
            </div>
        )


    }
};

var connectedHoc = connect<IAgentClientComponentState, IOwnProps, IIAgentClientProps, ApplicationState>(
    null
)(AgentClientInstall);


export default withRouter(connectedHoc);