import { combineReducers } from "redux";
import {
  ICrudComponentState,
  ICrudComponentActionProps,
  createCrudComponentActions,
  createCrudComponentReducers,
} from "@scripts/util/CrudComponentHelpers";
import * as SubmitDESFilesUI from "./ui/SubmitDESFilesUI";
import { CrudTypes } from "@commonResources/CrudTypes";
import { ValidationCallback } from "@scripts/util/ActionHelpers";
import { castArray, isPlainObject, keys as _keys, mapValues } from "lodash";

export type ISubmitDESFilesUploadState = ICrudComponentState<
  MCClaimNoteType,
  SubmitDESFilesUI.ISubmitDESFilesUploadUIState
>;

export const actionCreators =
  createCrudComponentActions<SubmitDESFilesUI.ActionCreators>(
    SubmitDESFilesUI.actionCreators
  );

export type ISubmitDESFilesUploadActions = typeof actionCreators;
export type ISubmitDESFilesUploadActionProps =
  ICrudComponentActionProps<ISubmitDESFilesUploadActions>;

export const reducers = createCrudComponentReducers(
  CrudTypes.mctiDESFileTypes,
  SubmitDESFilesUI.reducer
);

export const reducer = combineReducers<ISubmitDESFilesUploadState>(reducers);

export const validationCallback: ValidationCallback<MCReportFilter> = (
  crud: any
): crud is MCReportFilter => {
  let testCrud: MCReportFilter = crud as MCReportFilter;
  let validShape = isPlainObject(testCrud);
  return validShape;
};



/////


/////
export const fileTypeDataValidationCallback: ValidationCallback<APISubmitDESFileTypesContainer> =
  (response: any): response is APISubmitDESFileTypesContainer => {
    const testResponse: APISubmitDESFileTypesContainer = response;

    // rewritten bc ValidateObject and enforceArray were broken in The Great Webpack Upgrade™ and we really didn't need it here.
    const validShape = !!testResponse && isPlainObject(testResponse);

    if (validShape) {
      if (!testResponse.DESFileTypes) {
        return false;
      }
      const { DESFileTypes } = testResponse;
      //@ts-ignore for some reason TS thinks castArray will return a Boolean
      testResponse.DESFileTypes = mapValues<any>(DESFileTypes, castArray);
    }

    return validShape;
  };

export const uploadDESFilesValidationCallback: ValidationCallback<APIUploadDESFilesContainer> =
    (response: any): response is APIUploadDESFilesContainer => {
     const testResponse: string = response;

    if (testResponse === "success") {
      return true;
    } else {
      return false;
    }
  };

export const fileTypeDataValidationCallback_OG: ValidationCallback<APIRep_FormDataListPopulationContainer> =
  (response: any): response is APIRep_FormDataListPopulationContainer => {
    const testResponse: APIRep_FormDataListPopulationContainer = response;

    // rewritten bc ValidateObject and enforceArray were broken in The Great Webpack Upgrade™ and we really didn't need it here.
    const validShape = !!testResponse && isPlainObject(testResponse);

    if (validShape) {
      if (!testResponse.ListPopulation) {
        return false;
      }
      const { ListPopulation } = testResponse;
      // @ts-ignore for some reason TS thinks castArray will return a Boolean
      testResponse.ListPopulation = mapValues<APIRep_ReportFilterList>(
        ListPopulation,
        castArray
      );
    }
    return validShape;
  };
