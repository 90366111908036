import * as React from 'react';
import styled from 'styled-components';
import { CheckBoxComponent } from './CheckBox';

const TableContent = styled.div`
    height:225px !important;
    overflow: auto;
    background-color: white;
`;

export interface IARMDeptData {
    deptId: string;
    name: string;
    selected?: boolean;
    updateDepartmentSpan?: (val: string) => void | undefined;
}

export interface IARMDeptComponentProps {
    heading: string[];
    depts: IARMDeptData[];
}

export interface ISelectARMDeptComponentProps {
    depts: IARMDeptComponentProps;
    innerDeptWidth: innerDeptWidth; 
    selectedDepartment: IARMDeptData; 
    selectDepartmentHandler?(item: IARMDeptData): void,
}

export interface ISelectARMDeptComponentState {
    heading: string[];
    depts: IARMDeptData[];
    selectedDept: IARMDeptData;
}

type innerDeptWidth = string;


export class ARMDepartmentComponent extends React.Component<ISelectARMDeptComponentProps, ISelectARMDeptComponentState> {

    static defaultProps: ISelectARMDeptComponentProps = {
        depts: {
            heading: [],
            depts: []
        },
        innerDeptWidth:"inherit",
        selectedDepartment: {
            deptId: "",
            name: "",
            selected: false
        },
    }

    constructor(props: ISelectARMDeptComponentProps) {
        super(props);
    }

    readonly deptTableStyle: React.CSSProperties = {
        backgroundColor: "white",
        width: "100%",
        padding: "5px"
    };

    readonly deptTabelHeaderStyle: React.CSSProperties = {
        backgroundColor: '#9ba1a9',
        padding: "6px",
        color: "white",
        width: this.props.innerDeptWidth
    };

    readonly deptTableHeaderCellStyle: React.CSSProperties = {
        padding: "10px",
        textAlign: "left",
        fontSize: "12px",
        width: this.props.innerDeptWidth
    };

    readonly spanStyle: React.CSSProperties = {
        fontSize: "11px"
    };

    public componentDidMount() {
    }
        
    //..event from radio button
    public updateDepartment(e: React.ChangeEvent<HTMLInputElement>, item: any) {

        const emptyItem = {
            deptId: "",
            name: "",
            selected: false
        };
       
        if (this.props?.selectDepartmentHandler) {
            if (e.currentTarget.checked) {
                const newItem = { ...item, selected: true };
                this.props.selectDepartmentHandler(newItem);
            } else {
                this.props.selectDepartmentHandler(emptyItem);
            }
        }
    }

    //event for updateDepartmentSpan
    public updateDepartmentSpan(e: React.ChangeEvent<HTMLInputElement>, dept: IARMDeptData) {
        const { name, value } = e.target;

        if (this.props?.depts.depts[0]?.updateDepartmentSpan) {
            this.props.depts.depts[0].updateDepartmentSpan(name);
        }
    }

    render() {

        return (
            <TableContent>
                <table style={this.deptTableStyle}>
                    <thead style={this.deptTabelHeaderStyle}>
                        <tr>
                            {this.props.depts.heading.map(head => <th key={'_' + Math.random().toString(36).substr(2, 9)} style={this.deptTableHeaderCellStyle}>{head}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.depts.depts.map(dept => <tr key={'_' + Math.random().toString(36).substr(2, 9) }>
                            <td>
                                <CheckBoxComponent
                                    id={dept.deptId}
                                    width={"20px"}
                                    checked={this.props.selectedDepartment.deptId === dept.deptId}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateDepartment(e, dept)}
                                />
                            </td>
                            {/*@ts-ignore*/}
                            <td onClick={(e: React.ChangeEvent<HTMLInputElement>) => this.updateDepartmentSpan(e, dept) }><span style={this.spanStyle}>{dept.name}</span></td>
                        </tr>)}
                    </tbody>
                </table>
            </TableContent>
        );
    }
}