import { combineReducers, Reducer } from 'redux';
import * as PhysicianMnLetterUI from '@store/ui/PhysicianMnLetterUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from
    '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type IPhysicianMnLetterUIState = ICrudComponentState<MCPhysicianMnLetter, PhysicianMnLetterUI.IPhysicianMnLetterUIState>;
export const actionCreators = createCrudComponentActions<PhysicianMnLetterUI.ActionCreators>(PhysicianMnLetterUI.actionCreators);
export type IPhysicianMnLetterActions = typeof actionCreators;
export type IPhysicianMnLetterActionProps = ICrudComponentActionProps<IPhysicianMnLetterActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiPhysMNCoverLetter, PhysicianMnLetterUI.physicianMnLetterUIReducer);
export const reducer = combineReducers<IPhysicianMnLetterUIState>(reducers);

export const validationCallback: ValidationCallback<MCPhysicianMnLetter> = (crud: any): crud is MCPhysicianMnLetter => {
    let testCrud: MCPhysicianMnLetter = crud as MCPhysicianMnLetter;
    let validShape = ValidateJSONSchema(testCrud, "MCPhysicianMnLetter");
    return validShape;
};