import { combineReducers } from "redux";

import {
  IApiComponentState,
  IApiComponentActionProps,
  createApiComponentActions,
  createApiComponentReducers,
} from "@scripts/util/ApiDataHelpers";
import { castArray, isPlainObject, keys as _keys, mapValues } from "lodash";
import { ValidationCallback } from "@scripts/util/ActionHelpers";
import * as ReapplySiteTemplateUI from "@store/ui/ReapplySiteTemplateUI";

export type IReapplySiteTemplateState = IApiComponentState< MCRST_ReapplySiteTemplateType, ReapplySiteTemplateUI.IReapplySiteTemplateUIState>;
export const actionCreators = createApiComponentActions<ReapplySiteTemplateUI.ActionCreators>(ReapplySiteTemplateUI.actionCreators);
export type IReapplySiteTemplateActions = typeof actionCreators;
export type IReapplySiteTemplateActionProps = IApiComponentActionProps<IReapplySiteTemplateActions>;

export const reducers = createApiComponentReducers("reapplySiteTemplate",ReapplySiteTemplateUI.reducer);
export const reducer = combineReducers<IReapplySiteTemplateState>(reducers);
