import * as React from 'react';
import {
    VictoryChart,
    VictoryLegend,
    VictoryAxis,
    VictoryLabel,
    VictoryBar,
    VictoryLine,
    VictoryScatter,
    VictoryPie,
    Bar,
    Circle,
    VictoryGroup,
} from 'victory';

import {
    Colors,
    DigitalColors,
    colorShades,
    shades
} from './Colors';

import { Typography } from '@commonResources/typography';
import { CustomBar } from './CustomBar';
import { CustomPoint } from './CustomPoint';
import { HtmlTooltip } from '../../commonResources/commonGraphing/HtmlTooltip';

/**
 * title - the chart title
 * label - the label for the y-axis
 * data - the data in the below form (fill is not required if not available it will choose default based on scheme)
 * colorScheme - one of "color":string, "color & shade":string+number, "numeric shade":number, "random" or "random & shade".
 * 
 * data has the following structure:
        const data = [
            { x: 'Discenza, Anthony', y: 45, fill: "#4bb2c5" },
            { x: 'Dicenza, Ashlie', y: 512, fill: "#95e2b7" },
            { x: 'Faraguna, Alenupdaye', y: 82, fill: "#8d9aff" },
            { x: 'Kirk, James', y: 300, fill: "orchid" },
        ] 
 */

export type ChartData = {
    id: string;
    fill?: string;
    x: string | number;
    y: number;
    tooltip?: any;
    actionPayload?: any;
};

type ChartIcon = {
    fill: string;
    type: "diamond" | "plus" | "minus" | "square" | "star" | "triangleUp" | "triangleDowm";
}

export type LegendData = {
    name: string;
    symbol: ChartIcon;
};


export type Styles = {
    parent?:
    {
        background?: string,
        boxSizing?: string;
        display?: string;
        padding?: number;
        fontFamily?: string;
    };
    chart?:
    {
        background?: {
            fill?: string;
            stroke?: string;
            strokeWidth?: number;
            filter?: string;
        }
    };
    axis?: {
        grid?: {
            stroke?: string;
            strokeWidth?: number;
            opacity?: number;
        };
        tickLabels?: {
            fontSize?: number;
            padding?: number;
            fill?: string;
            angle?: number;
        }
    };

    axisDependent?: {
        axis?: { stroke?: string };
        grid?: {
            stroke?: string;
            strokeWidth?: number;
            opacity?: number;
        };
        axisLabel?: {
            fontSize?: number;
            fill?: string;
            padding?: number;
        };
        ticks?: {
            stroke?: string;
            size?: number;
        };
        tickLabels?: {
            fontSize?: number;
            padding?: number;
            fill?: string;
        };
    };

    title?: {
        textAnchor?: "start" | "middle" | "end" | "inherit" | (() => {});
        verticalAnchor?: "start" | "middle" | "end" | "inherit" | (() => {});
        fill?: string;
        fontFill?: string;
        fontFamily?: string;
        fontSize?: string;
        fontWeight?: string;
    };

    subtitle?: {
        textAnchor?: "start" | "middle" | "end" | "inherit" | (() => {});
        verticalAnchor?: "start" | "middle" | "end" | "inherit" | (() => {});
        fill?: string;
        fontFamily?: string;
        fontSize?: string;
        fontWeight?: string;
    };

    bar?: {
        data?: {
            fill?: ({ datum }: { datum: { fill: string } }) => {};
            fillOpacity?: number,
            filter?: string;
        };
    }
} | any;

export type GraphType = "bar" | "line" | "pie";

interface IComponentProps {
    title?: string;
    subtitle?: string;
    label?: string;
    xLabel?: string;
    data: ChartData[];
    lineData?: ChartData[][] | undefined;
    legendData?: LegendData[] | undefined;
    displayLine?: boolean;
    lineColorArray?: string[];
    styles?: Styles;
    colorScheme?: string;
    graphType: GraphType;
    tooltipData: any;
    barClickHandler?: (id: any) => void;
    pointClickHandler?: (id: any) => void;
    labelClickHandler?: (id: any) => void;
    graphClickHandler?: (target: any) => void;
    paletteId?: number;
    actionPayload?: any;
    colorSchemeArray?: string[];
    disableDataEvents?: boolean;
}

interface IIndexable {
    [key:string]: any;
}

type ClientRect = {
    x: number;
    y: number;
    width: number;
    height: number;
    top: number;
    bottom: number;
    left: number;
    right: number;
}

interface IComponentState {
    x: number;
    y: number;
    coords: ClientRect;
    datum: any;
    visible: boolean;
    selectedBar: string | number | null;
    selectedPoint: string | number | null;
}

const DEFAULT_STATE: IComponentState = {
    x: 0,
    y: 0,
    coords: { x: 0, y: 0, width: 0, height: 0, top: 0, bottom: 0, left: 0, right: 0 },
    datum: {},
    visible: false,
    selectedBar: null,
    selectedPoint: "",  
}

export class CommonGraph extends React.Component<IComponentProps, IComponentState> {

    static colorSchemes = [...colorShades, "random"];
    static shades = [null, ...shades];
    static digitalColors = { ...DigitalColors };
    static colors: typeof Colors = Colors;
    randomNum: number = Math.random();

    public setCoords(e: any) {
        const coords = document.getElementById(`parentContainer${this.randomNum}`)?.getBoundingClientRect();

        this.setState({ x: e.clientX, y: e.clientY, coords: coords as ClientRect });
    }

    public constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
        if (props.paletteId) {
            this.randomNum = props.paletteId;
        };
    }

    public componentDidMount() {
        const coords = document.getElementById(`parentContainer${this.randomNum}`)?.getBoundingClientRect();

        this.setState({ coords: coords as ClientRect });
    }


    static defaultStyles: Styles =
        {
            // The enclosing SVG parent
            parent: {
                background: Colors.brightBlue15,
                boxSizing: "border-box",
                display: "inline",
                padding: 0,
                // fontFamily: `${Typograpy.ARMFontFamily}` // causing errors
                fontFamily: "'Core Sans C', 'Century Gothic', AppleGothic, sans- serif",
                fontSize: "14px",
                border: "1px solid #bcdfef",
            },
            // Styles for the chart
            chart: {
                background: {
                    fill: "white",
                    stroke: DigitalColors.digitalGrey50,
                    strokeWidth: 1,
                    filter: "url(#dropshadow)"
                }
            },
            axis:
            {
                grid: { stroke: DigitalColors.digitalGrey50, strokeWidth: 0.5, opacity: 0.5 },
                tickLabels: {
                    fontSize: 11, padding: 5, fill: DigitalColors.digitalGrey70, angle: -45, textAnchor: "end",
                    verticalAnchor: "middle"
                },

            },
            axisDependent: {

                axis: { stroke: "black" },
                grid: { stroke: DigitalColors.digitalGrey50, strokeWidth: 0.5, opacity: 0.5 },
                axisLabel: { fontSize: 16, fill: DigitalColors.digitalGrey70, padding: 65 },
                ticks: { stroke: DigitalColors.digitalGrey50, size: 5 },
                tickLabels: { fontSize: 12, padding: 5, fill: DigitalColors.digitalGrey50 }
            },
            title: {
                textAnchor: "middle",
                verticalAnchor: "middle",
                fill: "#000000",
                fontFill: "#000000",
                fontFamily: "inherit",
                fontSize: "20px",
                fontWeight: "bold"
            },
            subtitle: {
                textAnchor: "middle",
                verticalAnchor: "middle",
                fill: DigitalColors.digitalGrey70,
                fontFamily: "inherit",
                fontSize: "14px",
                fontWeight: "bold"
            }
        }

    shuffle(array: any[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(this.randomNum * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    getColor(colorScheme: string) {
        //console.log("The color Scheme:", colorScheme);
        let colors = Object.keys(Colors);
        //console.log("All colors:", colors);

        // Does the color scheme include any digits?
        let digits: number[] | number | undefined = (colorScheme
            .match(/\d+\.\d+|\d+\b|\d+(?=\w)/g) || [])
            .map(v => +v);

        let word: string[] | string | null = colorScheme ? colorScheme.match(/([a-z]+)/gi) : '';

        if (word) {
            //console.log("The word is:", word[0]);
        }

        digits = (digits.length > 0) ? digits[0] : undefined;

        //console.log("The shade is:", digits);

        // Generate a color set according to color and shade passed in
        let colorSet: any[] = [];

        if (word && digits) {
            // All graphs will be a single color
            //console.log("A single shade was requested");
            if (word[0] !== "random") colorSet.push(colorScheme);
            else {
                //console.log("Random action called");
                // randomize within a shade
                // TODO: fix null
                //@ts-ignore
                colorSet = colors.filter((color) => +color.match(/\d+\.\d+|\d+\b|\d+(?=\w)/g)[0] === digits)
                this.shuffle(colorSet);
            };
        } else if (word) {
            // generate palatte of colors for a given shade
            // select only odd members, for better color separation
            if (word.toString() !== "random") {
                //@ts-ignore
                colorSet = colors.filter((color) => color.includes(word))
                    .filter((_, index) => index % 2 !== 0);
            } else {
                // send a shuffled array 
                //console.log("Randomizing ALL colors...");
                this.shuffle(colors);
                colorSet = [...colors];
            }
        } else if (digits) {
            // generate palette of colors from all colors of a given shade
            // @ts-ignore
            colorSet = colors.filter((color) => +color.match(/\d+\.\d+|\d+\b|\d+(?=\w)/g)[0] === digits)
        }

        //let colorSet = colors.filter((color) => color.includes(colorScheme));
        //console.log("color set", colorSet);
        // Map colors back to hex and retrieve only odd colors, for better color separation
        let hexColors = colorSet.map(color => Colors[color])

        //.filter((el, index) => index % 2 !== 0);
        //console.log("hex colors:", hexColors);
        return hexColors;
    }

    // Return hex values for given color palette
    mapToColor(data: any, colorScheme: string) {
        // get array of useful colors
        let colors = this.getColor(colorScheme);
        let tmpIndex = 0;

        return data.map((el: any, index: number) => {
            // if index exceeds bounds wrap
            tmpIndex = index % colors.length;
            let fill = el.fill ?? colors[tmpIndex];
            //console.log("index ->", index);
            return { ...el, fill }

        })
    }

    getBarData() {
        // Victory Charts has a bug where a data set with a single item will render as a skinny line
        // To fix this we add a dummy item when there is only a single datum
        let { data, colorScheme } = this.props;

        if (colorScheme) {
            data = this.mapToColor(data, colorScheme);
            //console.log(data);
        }

        //console.log("The bar data is: ", data);

        if (data && data.length === 1) {
            // add dummy item to datum
            return [...data, {x: '', y: null, fill: '#fff' } ]
        }

        return data;
    }

    getBarGraph() {

        let { title, subtitle, label, data, styles, colorScheme, displayLine, lineColorArray, legendData, tooltipData, barClickHandler, graphClickHandler, pointClickHandler, disableDataEvents } = this.props;
        let lineData = this.props.lineData ? this.props.lineData : [[]]

        let noBarDataToPlot = false;

        // set defaults if undefined
        displayLine = displayLine ?? false;
        if (lineColorArray) {
            lineColorArray = lineColorArray;
        } else {
            lineColorArray = ["bada55"];
        }
        

        const hasLegend = legendData && legendData.length > 0;


        //console.log("Legend Data: ", legendData)
        //console.log("Checked is: ", displayLine);

        // use default styles if none are passed in
        styles = styles ?? CommonGraph.defaultStyles;
        // 
        if (colorScheme) {
            data = this.mapToColor(data, colorScheme);
            //console.log(data);
        }
        // These establish the ratio more than exact pixel width or heights
        const viewBoxWidth = 600;
        const viewBoxHeight = 600; // was 300

        const legendWidth = 50;

        const maxData = 20; // the maximum number of data elements

        const max = Math.max(viewBoxHeight, viewBoxWidth);
        const min = Math.min(viewBoxHeight, viewBoxWidth);

        const delta = (max - min) / (maxData - 2);

        const width = Math.round(min + (data.length * delta));
        const height = Math.round(max - (data.length * delta));

        //console.log("The width is: ", width);

        // TODO: calculate an intelligent padding based on data points
        const domainPaddingLeft = Math.round(50 * height / width);
        const domainPaddingRight = Math.round(50 * height / width);

        //console.log(this.state.datum);
        //console.log("Left: ", domainPaddingLeft, "Right: ", domainPaddingRight )
        //console.log( "W: ", width, "H: ", height);

        // Initialize Line Values
        let maxYLine = 0;
        let minYLine = 0;

        // Initialize Bar Values
        let maxYBar = 0;
        let minYBar = 0;

        maxYBar = Math.max.apply(null, [...(data.map((e) => e.y)), maxYBar]);
        minYBar = Math.min.apply(null, [...(data.map((e) => e.y)), minYBar]);

        // console.log("The largest value for bar data is: ", maxYBar);
        // console.log("The smallest value for bar data is: ", minYBar);
 
        // Get Max Value for the Line Graphs
        if (lineData) {
            let values:any = [];

            lineData.forEach((lineChart) => lineChart.map((e) => values.push(e.y)));
            maxYLine = Math.max.apply(null, [...values, maxYLine]);
            minYLine = Math.min.apply(null, [...values, minYLine]);
        }

        //console.log("The largest value for line data is: ", maxYLine);
        //console.log("The smallest value for line data is: ", minYLine);

        let maxY = Math.max(maxYBar, maxYLine);
        let minY = Math.min(minYBar, minYLine);

        //console.log("The largest value overall is: ", maxY);
        //console.log("The smallest value overall is: ", minY);


        const calculateInterval = (range:number) => {
            let x = Math.pow(10.0, Math.floor(Math.log10(range)));
            if (range / (x / 2.0) >= 10)
                return x / 2.0;
            else if (range / (x / 5.0) >= 10)
                return x / 5.0;
            else
                return x / 10.0;
        }
        let spread = maxY - minY;
        let step = calculateInterval(spread);

        // Avoid divide by 0 numbers by setting step to 1 if spread = 0
        if (spread <= 0) {
            spread = 9;
            step = 1;
            noBarDataToPlot = true;
        }

        // Add extra step to add some overhead buffer so that data max points aren't at the top edge of graph 
        let newRange = (Math.ceil(spread / step) * step) + step;


        //console.log("Min value: ", minY, " | Max value: ", maxY, " | New Range", newRange, "| Step: ", step );

        // Function to create a range of tick values using calculated step size
        const getTickValues = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => +(start + (i * step)).toFixed(2));

        
        const getCombinedTooltip = (id: number) => {
            //console.log("The data is", data);
            // TODO: Allow override of content format with custom data 
            return { tooltip: { ...data[id].tooltip } }
        }
        const eventProps: any = {}

        // Conditionally Render Props

        if (disableDataEvents) {
            eventProps.events = [
                {
                    // Trigger events for graph as a whole being clicked
                    childName: 'all',
                    target: 'parent',
                    eventHandlers:
                    {
                        onClick: ({ target }: any) => {
                            // Call the passed in click handler when any graph element has been clicked
                            graphClickHandler && graphClickHandler(target);
                            //console.log("Target node:", target.nodeName);
                            if (target.nodeName !== 'rect') return;
                            // clear bar & point selection
                            this.setState({ selectedBar: null, selectedPoint: null });
                        },
                        onMouseEnter: () => {
                            this.setState({ visible: true })
                        },
                        onMouseLeave: () => {
                            this.setState({ visible: false })
                        }
                    }
                },
            ]
        } else {

            if (data && data.length) {
                eventProps.events = [
                    {
                        // reset selection on Click of white space 
                        childName: 'all',
                        target: 'parent',
                        eventHandlers:
                        {
                            onClick: ({ target }: any) => {
                                //console.log("Clearing selection...");
                                if (target.nodeName !== 'rect') return;
                                // clear bar & point selection
                                this.setState({ selectedBar: null, selectedPoint: null })
                            }
                        },
                    },
                    {
                        // capture when data in the chart has been clicked
                        childName: 'bar-graph',
                        target: 'data',
                        eventHandlers: {
                            // set selectedBar component state
                            onClick: (_: any, { id, datum }: any) => {
                                // Store selected bar
                                if (this.state.selectedBar === datum.id) {
                                    this.setState({ selectedBar: null })
                                } else {
                                    this.setState({ selectedBar: datum.id })
                                    this.setState({ selectedBar: datum.id })
                                }
                                barClickHandler && barClickHandler(datum.actionPayload);
                            },
                            onMouseEnter: () => [
                                {
                                    target: "data",
                                    mutation: (props: any) => {
                                        const fill = props.style && props.style.fill;
                                        this.setState({ datum: props.datum, visible: true });
                                        return { style: { fill: fill, opacity: 1, stroke: "red", strokeWidth: 2 } }
                                    }
                                }
                            ],
                            onMouseLeave: () => [
                                {
                                    target: "data",
                                    mutation: (props: any) => {
                                        //console.log("mm: rollover type: ", this.state.rollOverType);
                                        const fill = props.style && props.style.fill;
                                        this.setState({ visible: false });
                                        return { style: { fill: fill, opacity: 1 } }
                                    }
                                }
                            ],

                        }
                    },
                    // Hover states for labels on x-axis
                    // TODO: set tooltip data to be combined of line & graph
                    // This is causing warnings in console, so commenting out until a better solution is found
                    /*
                    {
                        childName: 'all',
                        target: 'labels',
                        eventHandlers:
                        {
                            onMouseEnter: () => [
                                {
                                    
                                    target: 'labels',
                                    mutation: (props: any) => {
                                        //console.log("The tooltip content is:", tooltipData.getContent());
                                        //this.setState({ datum: getCombinedTooltip(props.datum -1), visible: true});
                                        return { style: {fill: "red", fontSize: "11px"}}
                                    }
                                }
                            ],
                            
                            // Hover state for leave
                            onMouseLeave: (target: any) => [
                                {
                                    target: "labels",
                                    mutation: (props: any) => {
                                        this.setState({ visible: false });
                                        return { style: { fill: "grey", fontSize: "11px" } }
                                    }
                                }
    
                            ],
    
                        }
                        
                    } */
                ]

            }
           
        }

        return (
            <div
                id={`parentContainer${this.randomNum}`}
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "none"
                }}
                onMouseMove={(e) => { this.setCoords(e) }}
            >
                <HtmlTooltip
                    left={this.state.x - this.state.coords.left}
                    top={this.state.y - this.state.coords.top}
                    tooltipWidth={tooltipData.tooltipWidth || 150}
                    tooltipOffsetX={tooltipData.tooltipOffsetX}
                    tooltipOffsetY={tooltipData.tooltipOffsetY}
                    visible={this.state.visible}
                    data={this.state.datum.tooltip || {}}
                    getContent={tooltipData.getContent}
                />
                <svg style={styles.parent} viewBox={`0 0 ${width + legendWidth} ${height}`}>
                    {/* Drop Shadow Effect */}
                    <filter id="dropshadow" height="130%">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                        <feOffset dx="2" dy="2" result="offsetblur" />
                        <feComponentTransfer>
                            <   feFuncA type="linear" slope="0.5" />
                        </feComponentTransfer>
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                    {/* Chart Title */}
                    <VictoryLabel x={width / 2} y={subtitle ? 40 : 50} style={styles.title} text={title} />

                    {/* Chart Sub Title */}
                    <VictoryLabel x={width / 2} y={60} style={styles.subtitle} text={subtitle} />
                    
                    {/* Chart Container */}
                    <VictoryChart
                        domainPadding={{ x: [domainPaddingLeft, domainPaddingRight] }}
                        width={width}
                        height={height}
                        padding={{ left: 100, bottom: 150, top: 100, right: 50 }}
                        style={styles.chart}
                        standalone={false}
                        {...eventProps}         
                    >

                        {/* Victory Legend */}
                        {hasLegend && (
                            <VictoryLegend x={width - 40} y={100}
                                centerTitle
                                orientation="vertical"
                                gutter={20}
                                style={{
                                    border: { stroke: "none" },
                                    title: { fontSize: 18 },
                                    labels: { fontSize: 11 }
                                }}

                                data={legendData}
                            />
                        )}

                        {/* X-axis */}
                        <VictoryAxis
                            name="xLabels"
                            style={styles.axis}
                            axisLabelComponent={<VictoryLabel
                                textAnchor="end"
                                events={{ onClick: (evt) => alert("x: " + evt.clientX )}}
                            />}
                            
                            tickFormat={(tick) => `${tick}`.toUpperCase()}
                        />

                        {/* Y-axis */}
                        <VictoryAxis
                            dependentAxis
                            style={styles.axisDependent}
                            label={label}
                            tickValues={getTickValues(0, newRange, step)}
                        />

                        {/* The Bar Chart */}

                       <VictoryBar
                            key={`bar-${Date.now()}`}
                            data={this.getBarData()}
                            dataComponent={<CustomBar selectedBar={this.state.selectedBar} />}
                            barRatio={.75}
                            name={"bar-graph"}
                            cornerRadius={3}
                            style={styles.bar}
                        />
                       
                       
                        {/* The Line Graph */}
                        {lineData && lineData.length && this.emitLine()}


                        {/* Chart Sub Title */}
                        {noBarDataToPlot && <VictoryLabel x={width / 2 + domainPaddingLeft / 2} y={height / 2 - 44} style={styles.subtitle} text={"No Bar Data To Display"} />}
                         
                    </VictoryChart>
                </svg>
            </div>
        )
    }

    emitLine() {
        const { displayLine, lineColorArray, pointClickHandler, disableDataEvents } = this.props;
        let lineData = this.props.lineData ? this.props.lineData : [[]]

        return (
          
            lineData && lineData.length && lineData.map((data, index) => (
                <VictoryGroup key={`line-${Date.now()}`}>
                    <VictoryLine
                        data={data}
                        name='line-graph'
                        style={{
                           
                            data: { stroke: lineColorArray && lineColorArray[index], strokeWidth: 3 }
                        }}
                    />
                    <VictoryScatter
                        style={{
                            data: { fill:  lineColorArray && lineColorArray[index] }
                        }}
                        name="scatter-graph"
                        data={data}
                        dataComponent={
                            <CustomPoint
                                color={lineColorArray && lineColorArray[index]}
                                selectedPoint={this.state.selectedPoint}
                                setState={(obj: any) => this.setState(obj)}
                                clickHandler={(payload: any) => pointClickHandler && pointClickHandler(payload)}
                                displayLine={true}
                                disableDataEvents={disableDataEvents}
                            />
                        }
                    />
                    </VictoryGroup>
                    
                )
        
            )
        )
              
    }

    emitBar() {
        let { data, styles, colorScheme } = this.props;
        styles = styles ?? CommonGraph.defaultStyles;
        if (colorScheme) {
            data = this.mapToColor(data, colorScheme);
            //console.log(data);
        }
        return (<VictoryBar
            key={`bar-${Date.now()}`}
            data={data}
            dataComponent={<CustomBar selectedBar={this.state.selectedBar} />}
            barRatio={.75}
            name={"bar-graph"}
            cornerRadius={3}
            style={styles.bar}
        />)
    }

    getLineGraph() {
        let { title, subtitle, label, data, styles, colorScheme, displayLine, lineColorArray } = this.props;

        //console.log("Checked is: ", displayLine);

        styles = styles ?? CommonGraph.defaultStyles;

        if (colorScheme) {
            data = this.mapToColor(data, colorScheme);
            //console.log(data);
        }
        // These establish the ratio more than exact pixel width or heights
        const viewBoxWidth = 600;
        const viewBoxHeight = 350;

        const maxData = 20; // the maximum number of data elements

        const max = Math.max(viewBoxHeight, viewBoxWidth);
        const min = Math.min(viewBoxHeight, viewBoxWidth);

        const delta = (max - min) / (maxData - 2);

        const width = Math.round(min + (data.length * delta));
        const height = Math.round(max - (data.length * delta));

        const domainPaddingLeft = Math.round(20 * height / width);
        const domainPaddingRight = Math.round(20 * height / width);

        //console.log("Left: ", domainPaddingLeft, "Right: ", domainPaddingRight )
        //console.log( "W: ", width, "H: ", height);

        const maxY = Math.max.apply(null, (data.map((e) => e.y)));
        const minY = Math.min.apply(null, (data.map((e) => e.y)));
        let step = Math.round((maxY - minY) / 10);
        step = Math.ceil((step + 1) / 10) * 10; // round to neartest 10s
        //console.log("Max value: ", maxY, "Min value: ", minY, "Step: ", step );

        // Sequence generator 
        const range = (start: number, stop: number, step: number) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + (i * step))

        return (
            <svg style={styles.parent} viewBox={`0 0 ${width} ${height}`}>
                {/* Drop Shadow Eff */}
                <filter id="dropshadow" height="130%">
                    <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                    <feOffset dx="2" dy="2" result="offsetblur" />
                    <feComponentTransfer>
                        <   feFuncA type="linear" slope="0.5" />
                    </feComponentTransfer>
                    <feMerge>
                        <feMergeNode />
                        <feMergeNode in="SourceGraphic" />
                    </feMerge>
                </filter>
                {/* Chart Title */}
                <VictoryLabel x={width / 2} y={20} style={styles.title} text={title} />

                {/* Chart Sub Title */}
                <VictoryLabel x={width / 2} y={40} style={styles.subtitle} text={subtitle} />

                {/* Chart Container */}
                <VictoryChart

                    // we increase the padding as the data points diminish
                    domainPadding={{ x: [domainPaddingLeft, domainPaddingRight] }}
                    width={width}
                    height={height}
                    // add padding for labels
                    padding={{ left: 80, bottom: 100, top: 50, right: 50 }}
                    style={styles.chart}
                    standalone={false}
                >
                    {/* X-axis */}
                    <VictoryAxis
                        style={styles.axis}
                        tickLabelComponent={<VictoryLabel textAnchor="end" />}
                        tickFormat={(tick) => `${tick}`.toUpperCase()}
                    />
                    {/* Y-axis */}
                    <VictoryAxis
                        dependentAxis
                        style={styles.axisDependent}
                        label={label}
                        tickValues={range(0, maxY + step, step)}
                    />

                    {/* Line Overlay */}

                    <VictoryLine

                        data={data}
                        style={{
                            //@ts-ignore
                            data: { stroke: () => displayLine ? lineColorArray[0] : "none" },
                        }}
                    />
                    <VictoryScatter

                        style={{
                            //@ts-ignore
                            data: { fill: () => displayLine ? lineColorArray[0] : "none" }
                        }}
                        size={3}
                        data={data}
                    />
                </VictoryChart>
            </svg>
        )
    }

    static defaultStylesForPie: Styles =
        {
            // The enclosing SVG parent
            parent: {
                background: "#FFFFFF",
                boxSizing: "border-box",
                display: "inline",
                padding: 0,
                // fontFamily: `${Typograpy.ARMFontFamily}` // causing errors
                fontFamily: "'Core Sans C', 'Century Gothic', AppleGothic, sans- serif",
                fontSize: "14px",
            },

            axis:
            {
                grid: { stroke: DigitalColors.digitalGrey50, strokeWidth: 0.5, opacity: 0.5 },
                tickLabels: {
                    fontSize: 11, padding: 5, fill: DigitalColors.digitalGrey70, angle: -45, textAnchor: "end",
                    verticalAnchor: "middle"
                },

            },
            axisDependent: {

                axis: { stroke: "black" },
                grid: { stroke: DigitalColors.digitalGrey50, strokeWidth: 0.5, opacity: 0.5 },
                axisLabel: { fontSize: 16, fill: DigitalColors.digitalGrey70, padding: 50 },
                ticks: { stroke: DigitalColors.digitalGrey50, size: 5 },
                tickLabels: { fontSize: 12, padding: 5, fill: DigitalColors.digitalGrey50 }
            },
            title: {
                textAnchor: "middle",
                verticalAnchor: "middle",
                fill: "#000000",
                fontFill: "#000000",
                fontFamily: "inherit",
                fontSize: "20px",
                fontWeight: "bold"
            },
            subtitle: {
                textAnchor: "middle",
                verticalAnchor: "middle",
                fill: DigitalColors.digitalGrey70,
                fontFamily: "inherit",
                fontSize: "14px",
                fontWeight: "bold"
            }
        }

    getPieGraph() {
        let { title, subtitle, data, styles, colorScheme, tooltipData, legendData, graphClickHandler, barClickHandler } = this.props;
        const width = 600;
        const height = 600;


        styles = styles ?? CommonGraph.defaultStylesForPie;
        const hasLegend = legendData && legendData.length > 0;

        // Set to overlay color, if set
        if (legendData && hasLegend && colorScheme) {
            for (let i = 0; i < legendData.length; i++) {
                legendData[i].symbol["fill"] = this.getColor(colorScheme)[i];
            }
        }
        if (colorScheme) {
            data = this.mapToColor(data, colorScheme);
            
        }

        return (
            <div
                id={`parentContainer${this.randomNum}`}
                style={{
                    position: "relative",
                    width: "100%",
                    height: "100%",
                    backgroundColor: "none"
                }}
                onMouseMove={(e) => { this.setCoords(e) }}
            >
                <HtmlTooltip
                    left={this.state.x - this.state.coords.left}
                    top={this.state.y - this.state.coords.top}
                    tooltipWidth={tooltipData.tooltipWidth || 150}
                    tooltipOffsetX={tooltipData.tooltipOffsetX}
                    tooltipOffsetY={tooltipData.tooltipOffsetY}
                    visible={this.state.visible}
                    data={this.state.datum.tooltip || {}}
                    getContent={tooltipData.getContent}
                />
                <svg style={styles.parent} viewBox={`0 0 ${width} ${height}`}>
                    {/* Chart Title */}
                    <VictoryLabel x={width / 2} y={40} style={styles.title} text={title} />

                    {/* Chart Sub Title */}
                    <VictoryLabel x={width / 2} y={40} style={styles.subtitle} text={subtitle} />
                    <VictoryChart
                        domainPadding={{ x: [0, 100] }}
                        width={width}
                        height={height}
                        padding={{ left: 10, bottom: 150, top: 120, right: 50 }}
                        standalone={false}
                       
                    >
                        {hasLegend && (
                            <VictoryLegend x={width - 200} y={100}
                                centerTitle
                                orientation="vertical"
                                gutter={20}
                                style={{
                                    border: { stroke: "none" },
                                    title: { fontSize: 18 },
                                    labels: { fontSize: 11 }
                                }}
                                events={[
                                    {
                                        // capture when data in the chart has been clicked in order to reset color to defaults
                                        childName: 'pie-graph',
                                        target: 'data',
                                        eventHandlers: {
                                            // set selectedBar component state
                                            onClick: (_: any, { id, datum }: any) => {
                                                debugger
                                                // Store selected bar
                                                if (this.state.selectedBar === id) {
                                                    this.setState({ selectedBar: null })
                                                } else {
                                                    this.setState({ selectedBar: id })
                                                }
                                                barClickHandler && barClickHandler(datum.actionPayload);
                                            },
                                            onMouseEnter: () => [
                                                {
                                                    target: "data",
                                                    mutation: (props) => {
                                                        
                                                        const fill = props.style && props.style.fill;
                                                        this.setState({ datum: props.datum, visible: true });
                                                        return { style: { fill: fill, opacity: 1, cursor: "pointer" } }
                                                    }
                                                }
                                            ],
                                            onMouseLeave: () => [
                                                {
                                                    target: "data",
                                                    mutation: (props) => {
                                                        const fill = props.style && props.style.fill;
                                                        console.log("FillValue", fill);
                                                        this.setState({ visible: false });
                                                        return { style: { fill: fill, opacity: 1, cursor: "pointer" } }
                                                    }
                                                }
                                            ],

                                        }
                                    },
                                    {
                                        // capture when data in the chart has been clicked in order to reset color to defaults
                                        childName: 'pie-graph',
                                        target: 'labels',
                                        eventHandlers: {
                                            // set selectedBar component state
                                            onClick: (_: any, { id, datum }: any) => {

                                                // Store selected bar
                                                if (this.state.selectedBar === id) {
                                                    this.setState({ selectedBar: null })
                                                } else {
                                                    this.setState({ selectedBar: id })
                                                }
                                                barClickHandler && barClickHandler(datum.actionPayload);
                                            },
                                            onMouseEnter: () => [
                                                {
                                                    target: "labels",
                                                    mutation: (props) => {

                                                        const fill = props.style && props.style.fill;
                                                        this.setState({ datum: props.datum, visible: true });
                                                        return { style: { fill: fill, opacity: 1, cursor: "pointer" } }
                                                    }
                                                }
                                            ],
                                            onMouseLeave: () => [
                                                {
                                                    target: "labels",
                                                    mutation: (props) => {
                                                        const fill = props.style && props.style.fill;
                                                        //console.log("FillValue", fill);
                                                        this.setState({ visible: false });
                                                        return { style: { fill: fill, opacity: 1, fontSize: "11px", cursor: "pointer" } }
                                                    }
                                                }
                                            ],

                                        }
                                    }
                                ]}

                                data={legendData}
                            />
                        )}
                        <VictoryPie
                            width={500}
                            height={500}
                            innerRadius={70}

                            data={data}
                            colorScale={colorScheme && this.getColor(colorScheme) || this.getColor("blue")}
                            padding={{ left: 100, top: 130 }}
                            standalone={false}
                            events={[
                                {
                                    // Trigger events for graph as a whole being clicked
                                    childName: 'all',
                                    target: 'parent',
                                    eventHandlers: {
                                        onClick: ({ target }: any) => {
                                            // Call the passed in click handler when any graph element has been clicked
                                            graphClickHandler && graphClickHandler(target);
                                            //console.log("Target node:", target.nodeName);
                                            if (target.nodeName !== 'rect') return;
                                            // clear bar & point selection
                                        },
                                    }
                                },
                                {
                                    // capture when data in the chart has been clicked in order to reset color to defaults
                                    childName: 'pie-graph',
                                    target: 'data',
                                    eventHandlers: {
                                        // set selectedBar component state
                                        onClick: (_: any, { id, datum }: any) => {
                                            // Store selected bar
                                            if (this.state.selectedBar === id) {
                                                this.setState({ selectedBar: null })
                                            } else {
                                                this.setState({ selectedBar: id })
                                            }
                                            barClickHandler && barClickHandler(datum.actionPayload);
                                        },
                                        onMouseEnter: () => [
                                            {
                                                target: "data",
                                                mutation: (props) => {
                                                    const fill = props.style && props.style.fill;
                                                    this.setState({ datum: props.datum, visible: true });
                                                    return { style: { fill: fill, opacity: 1, stroke:fill, strokeWidth: 1 } }
                                                }
                                            }
                                        ],
                                        onMouseLeave: () => [
                                            {
                                                target: "data",
                                                mutation: (props) => {
                                                    const fill = props.style && props.style.fill;
                                                    this.setState({ visible: false });
                                                    return { style: { fill: fill, opacity: 1 } }
                                                }
                                            }
                                        ],

                                    }
                                }
                            ]}
                        />
                        <VictoryAxis style={{ axis: { stroke: "none" } }} tickFormat={() => ''}></VictoryAxis>
                    </VictoryChart>
                </svg>
            </div>)
    }


    render() {
        const { graphType } = this.props;

        switch (graphType) {

            case "bar":
                return this.getBarGraph();
            case "line":
                return this.getLineGraph();
            case "pie":
                return this.getPieGraph();
            default:
                return <h1>No Graph type specified</h1>
        }
    }
}