import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { ILeftRightContentItem } from '../../components/common/LeftRightListsComponent';

// replaces functionality of ChangeTracking.asp

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IClaimTrackTypeUIState {
    selectedTrackType: ISelectTrackTypeData;
    TrackTypeName: string;
    formTypeId: string,
    formTypeName: string;
    fieldRecords: IFormFieldList[];
    isDirty: boolean;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectTrackTypeData {
    index: number;
    value: string;
    text: string;
};

export const defaultTrackType: ISelectTrackTypeData = {
    index: 0,
    value: '',
    text: '',
};

interface CTInitResult {
    uiData: {
        rawApiReturn: any;
        pathname: string;
    } 
}

export interface IFormFieldList {
    id: string,
    text: string,
    tracked: boolean,
    selected: boolean,
    wasTracked: boolean,            // original setting of tracked in crud
}

export interface ISelectTrackType extends ICrudActionData<MCClaimTrackingType, ISelectTrackTypeData> { }

export interface IModifyTrackType extends ICrudActionData<MCClaimTrackingType, MCClaimType> { }

export const actionCreators = {
    initalizeData: (CTInitData: CTInitResult) => createDataAction('CLMTRK_CT_INIT', CTInitData),
    selectTrackType: (selectInfo: ISelectTrackType) => createDataAction('CLMTRK_SELECT_NOTE_TYPE', selectInfo),
    selectLeftItems: (items: ILeftRightContentItem[]) => createDataAction('CLMTRK_SELECT_LEFT_ITEMS', items),
    moveSelectionToRight: () => createAction('CLMTRK_MOVE_SELECTION_TO_RIGHT'),
    selectRightItems: (items: ILeftRightContentItem[]) => createDataAction('CLMTRK_SELECT_RIGHT_ITEMS', items),
    moveSelectionToLeft: () => createAction('CLMTRK_MOVE_SELECTION_TO_LEFT'),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IClaimTrackTypeUIState = {
    selectedTrackType: defaultTrackType,
    TrackTypeName: '',
    fieldRecords: [],
    formTypeId: '',
    formTypeName: '',
    isDirty: false,
};


function parseFormByPathname(pathname: string): string {
    return pathname.slice(pathname.lastIndexOf('/') + 1)
}

export function mapCrudToUiState(crud: any, pathname: string): any {
//    console.dir(crud);
    const { Changes, FormTypeList, FieldList } = crud;
    const parsedFormType = parseFormByPathname(pathname);
    const formRow = FormTypeList.FormType.find((i: ClaimTrackingFormType) => i['@ID'] === parsedFormType);
    const formName = formRow ? formRow['@Name'] : '';
    const changeArray: IChangeType[] = Changes?.Change ?? [];
    return {
        fieldRecords: FieldList.Field.filter((field: ClaimTrackingType) => field['@FormType'] === parsedFormType).map((field: ClaimTrackingType) => {
            const crudTracked = changeArray.find((change: IChangeType) => change['@ID'] === field['@ID'] && change['@FormTypeID'] === field['@FormType']) ? true : false;
            return {
                id: field['@ID'],
                text: field['@Name'],
                selected: false,  
                tracked: crudTracked,
                wasTracked: crudTracked,
            }
        }),
        formTypeId: parsedFormType,
        formTypeName: formName,
        isDirty: false,
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IClaimTrackTypeUIState, KnownActions> = (state: IClaimTrackTypeUIState | undefined, action: KnownActions) =>  {

    if (state != undefined) {
        switch (action.type) {
            case 'CLMTRK_CT_INIT':
                {
                    const crud = action.data.uiData.rawApiReturn;
                    //console.log(action, 'a')
                    if (crud) {
                        return mapCrudToUiState(crud.ClaimChangesMaintenanceInfo, action.data.uiData.pathname);
                    }
                    break;
                }
            case 'CLMTRK_SELECT_LEFT_ITEMS':
            case 'CLMTRK_SELECT_RIGHT_ITEMS':
                {
                    const items = action.data;
                    const selectedIds = items.map(i => i.id);
                    const fieldRecords = state.fieldRecords.map(i => {
                        const selected = selectedIds.includes(i.id);
                        return { ...i, selected };
                    })
                    return { ...state, fieldRecords };
                }
            case 'CLMTRK_MOVE_SELECTION_TO_RIGHT':
                {
                    const fieldRecords = state.fieldRecords.map(i => {
                        if (i.tracked) {
                            return { ...i };
                        } else {
                            const tracked = i.selected;
                            const selected = false;
                            return { ...i, tracked, selected };
                        }
                    })
                    return { ...state, fieldRecords, isDirty: true };
                }
            case 'CLMTRK_MOVE_SELECTION_TO_LEFT':
                {
                    const fieldRecords = state.fieldRecords.map(i => {
                        if (i.tracked === false) {
                            return { ...i };
                        } else {
                            const tracked = !i.selected;
                            const selected = false;
                            return { ...i, tracked, selected };
                        }
                    })
                    return { ...state, fieldRecords, isDirty: true };
                }
            default:
                return state;
        }
    }

    return state || defaultState;
}
