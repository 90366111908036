import { Reducer } from "redux";
import { createDataAction, ActionTypes } from "@scripts/util/ActionHelpers";

import {
  createApiAction,
  createApiActionExtended,
  ValidationCallback,
  createAction,
} from "@scripts/util/ActionHelpers";
import { URLs } from "@commonDevResources/constants";
import { IApiActionData } from "@scripts/util/ApiDataHelpers";
import { Dictionary, NumericDictionary } from "lodash";

// STATE - This defines the type of data maintained in the Redux store.

export interface ISiteTemplateTransferUIData {
  [key: string]:
    | Dictionary<any>
    | NumericDictionary<any>
    | string
    | undefined
    | null;
}

export interface ISelectSiteClientName {
  [key: string]:
    | Dictionary<any>
    | NumericDictionary<any>
    | string
    | undefined
    | null;
}

export interface ISiteTemplateTransferUIState {
  SiteTemplateTransferAlert?: string;
  selectedClient: any;
  LastActivityMaintenanceInfo: ISiteTemplateTransferUIData;
}

interface ISiteTemplateTransferUIFieldUpdate {
  value: string;
}

export const defaultSiteTemplateTransfer: ISiteTemplateTransferUIData = {};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const obSecurity: ISiteTemplateTransferUIData = {
  ClientAlias: "",
};

obSecurity.ClientAlias = "9980";

interface IClaimFilterUISimpleIDArray {
  selected: APICF_FilterSimpleID[];
}

const URLify = (string: any) => {
  return string.trim().replace(/\s/g, "%20");
};

export interface ISelectSiteTemplateTransfer
  extends IApiActionData<
    MCSiteTemplateTransferType,
    ISiteTemplateTransferUIData
  > {}

export interface IUpdateSiteTemplateTransferField
  extends IApiActionData<
    MCSiteTemplateTransferType,
    ISiteTemplateTransferUIFieldUpdate
  > {}

export interface ISelectSiteTemplateTransferSimpleId
  extends IApiActionData<
    MCSiteTemplateTransferType,
    IClaimFilterUISimpleIDArray
  > {}

export const actionCreators = {
  resetUiState: () => createAction("RESET_UI_STATE"),
  sendSiteTemplateTransferAlert: (
    fieldInfo: IUpdateSiteTemplateTransferField
  ) => createDataAction("SEND_ST_ALERT", fieldInfo),
  selectClient: (selectClient: ISelectSiteTemplateTransferSimpleId) =>
    createDataAction("ST_SELECT_CLIENT", selectClient),
  getSiteTemplateFormData: (callback?: ValidationCallback<any>) =>
    createApiAction(
      "GET_SITE_TRANFER_DATA",
      URLs.api + "/api/data/GetSiteTemplateFormData",
      undefined,
      callback
    ),
  getXtraTemplateFormData: (
    clientAlias: ISelectSiteClientName,
    callback?: ValidationCallback<any>
  ) =>
    createApiActionExtended(
      clientAlias,
      "GET_EXTRA_SITE_TRANFER_DATA",
      URLify(URLs.api + `/api/data/productexport/audit/${clientAlias}`),
      undefined,
      callback
    ),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>["type"];

export const defaultState: ISiteTemplateTransferUIState = {
  LastActivityMaintenanceInfo: defaultSiteTemplateTransfer,
  SiteTemplateTransferAlert: "",
  selectedClient: "",
};

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.

export const formatLastActivityInfo = (obj: any) => {
  const payload: any = { LastActivityMaintenanceInfo: {} };
  const { LastActivityMaintenanceInfo: info } = payload;

  const faux = {
    "@UserName": "unknown",
    "@DateTime": "unknown",
    "@Action": "I",
  };

  const getAnswer = (obj: any, key: any) => (obj[key] ? obj : { [key]: faux });

  const {
    GroupMasterAudit,
    BridgeMasterAudit,
    AutoModulesAudit,
    AutolistsAudit,
    ClaimFiltersAudit,
    RemitFiltersAudit,
    FormsUsedAudit,
    SecurityAccountPolicyAudit,
    PayerAliasAudit,
    CSVOptionsAudit,
    HoldCodeAudit,
    PaperMapsAudit,
    BridgeTBLAudit,
    Bridge307FilesAudit,
    ASCFilesAudit,
    SpinoffFormMapAudit,
    SplitClaimFieldMapAudit,
    EligibilityProfileAudit,
    ClaimEventAudit,
    HISWorkflowAudit,
  } = obj.LastActivityMaintenanceInfo;

  info.GroupMasterAudit = getAnswer(GroupMasterAudit, "GroupMasterAuditInfo");
  info.BridgeMasterAudit = getAnswer(
    BridgeMasterAudit,
    "BridgeMasterAuditInfo"
  );
  info.AutoModulesAudit = getAnswer(AutoModulesAudit, "AutomoduleAuditInfo");
  info.AutolistsAudit = getAnswer(AutolistsAudit, "AutolistsAuditInfo");
  info.ClaimFiltersAudit = getAnswer(ClaimFiltersAudit, "ClaimFilterAuditInfo");
  info.RemitFiltersAudit = getAnswer(RemitFiltersAudit, "RemitFilterAuditInfo");
  info.FormsUsedAudit = getAnswer(FormsUsedAudit, "FormsUsedAuditInfo");
  info.SecurityAccountPolicyAudit = getAnswer(
    SecurityAccountPolicyAudit,
    "SecurityAccountPolicyAuditInfo"
  );

  info.PayerAliasAudit = getAnswer(PayerAliasAudit, "PayerAliasAuditInfo");
  info.CSVOptionsAudit = getAnswer(CSVOptionsAudit, "CSVOptionsAuditInfo");
  info.HoldCodeAudit = getAnswer(HoldCodeAudit, "HoldCodeAuditInfo");
  info.PaperMapsAudit = getAnswer(PaperMapsAudit, "PaperMapsAuditInfo");

  // MANUAL ENTRY
  info.BridgeTBLAudit = BridgeTBLAudit || {
    BridgeTBLAuditInfo: faux,
  };

  info.Bridge307FilesAudit = Bridge307FilesAudit || {
    Bridge307FilesAuditInfo: faux,
  };

  info.ASCFilesAudit = ASCFilesAudit || {
    ASCFilesAuditInfo: faux,
  };
  // MANUAL ENTRY

  info.SpinoffFormMapAudit = getAnswer(
    SpinoffFormMapAudit,
    "SpinoffFormMapAuditInfo"
  );
  info.SplitClaimFieldMapAudit = getAnswer(
    SplitClaimFieldMapAudit,
    "SplitClaimFieldMapAuditInfo"
  );
  info.EligibilityProfileAudit = getAnswer(
    EligibilityProfileAudit,
    "EligibilityProfileAuditInfo"
  );

  // MANUAL ENTRY
  info.ClaimEventAudit = ClaimEventAudit || {
    ClaimEventAuditInfo: faux,
  };
  info.HISWorkflowAudit = HISWorkflowAudit || {
    HISWorkflowAuditInfo: {
      "@UserName": "Unknown",
      "@DateTime": "Unknown",
      "@Action": "I",
    },
  };
  // MANUAL ENTRY
  return payload;
};

export const reducer: Reducer<ISiteTemplateTransferUIState, KnownActions> = (
  state: ISiteTemplateTransferUIState | undefined,
  action: KnownActions
) => {
  if (state != undefined) {
    switch (action.type) {
      case "ST_SELECT_CLIENT":
        return {
          ...state,
          selectedClient: action.data.uiData.selected,
        };
      case "RESET_UI_STATE":
        return {
          ...state,
          LastActivityMaintenanceInfo: defaultSiteTemplateTransfer,
        };
      case "GET_SITE_TRANFER_DATA": {
        switch (action.status.status) {
          case "REQUEST":
            break;
          case "SUCCESS": {
            const responseData: any = action?.responseData;

            if (responseData) {
              const obj = JSON.parse(responseData);
              const payload: any = formatLastActivityInfo(obj);
              return {
                ...state,
                LastActivityMaintenanceInfo:
                  payload.LastActivityMaintenanceInfo,
              };
            } else {
              console.log("where's ma data???");
            }
            break;
          }
          case "FAIL": {
            console.log(action, "Failed to GET_SITE_TRANFER_DATA");
            break;
          }
        }
        break;
      }
      case "GET_EXTRA_SITE_TRANFER_DATA": {
        switch (action.status.status) {
          case "REQUEST":
            break;
          case "SUCCESS": {
            const responseData: any = action?.responseData;

            if (responseData && responseData.length) {
              return {
                ...state,
                LastActivityMaintenanceInfo: {
                  ...state.LastActivityMaintenanceInfo,
                  HISWorkflowAudit: {
                    HISWorkflowAuditInfo: {
                      "@UserName": responseData[0].Username,
                      "@DateTime": responseData[0].LastChangeDate,
                      "@Action": "I",
                    },
                  },
                },
              };
            } else {
              console.log("where's ma data???");
            }
            break;
          }
          case "FAIL": {
            console.log(action, "Failed to GET_EXTRA_SITE_TRANFER_DATA");
            break;
          }
        }

        break;
      }
      case "SEND_ST_ALERT":
        return {
          ...state,
          SiteTemplateTransferAlert: action.data.uiData.value,
        };
      default:
        return state;
    }
  }

  return state || defaultState;
};
