import * as React from 'react';
import styled, { css } from 'styled-components';
import errorIcon from '../../content/images/errorIcon.gif';

export const ARMMessageListComponentContainer = styled.div.attrs(() => {
})`
    background-color: #FDF6E3;
    width: 100%;
    height: 100%;
`;

export const ARMMessageListComponentContent = styled.div.attrs(() => {
})`
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ARMMessageListComponentIcon = styled.div.attrs(() => {
})`
    width: 100%;
`;

export const ARMMessageListComponentText = styled.div.attrs(() => {
})`
    font-size: 13px;
    color: #d84040;
    font-weight: bold;
`;

export const msgListLegacyPageStyle: React.CSSProperties = { width: '100%', border: '0', borderSpacing: '2px', borderCollapse: 'separate' };//, cellSpacing: '0', cellPadding: '0' };
export const msgListLegacyPageStyleNoMessages: React.CSSProperties = { boxSizing: 'border-box', width: '100%', height: '290px', minHeight: '17px', border: '0', borderSpacing: '0px', borderCollapse: 'separate' };//, cellSpacing: '0', cellPadding: '0' };
//export const notificationStyle: React.CSSProperties = { height: '100%', verticalAlign: 'bottom' };

//export const msgRootTableStyle: React.CSSProperties = { width: '100%', border: '0', };//cellSpacing: '0', cellPadding: '0' };
export const msgContentStyle: React.CSSProperties = { width: '100%', minHeight: '10px', border: '0', height: '290px' };//onMouseOut: 'event.cancelBubble = true;', cellSpacing: '0', cellPadding: '0',  };
export const msgListLegacyRowStyleToday: React.CSSProperties = { height: '15px', lineHeight: '15px', cursor: 'pointer', backgroundColor: '#FFF5DB', borderSpacing: '0px', borderCollapse: 'separate', border: '1px green' };
export const msgListLegacyRowStyleNotToday: React.CSSProperties = { height: '15px', lineHeight: '15px', cursor: 'pointer', backgroundColor: '#E1E2E6', borderSpacing: '0px', borderCollapse: 'separate', border: '1px green' };
export const msgListLegacyReadStatusStyle: React.CSSProperties = { height: '15px', lineHeight: '15px', padding: '2px 0px 0px 0px' };
export const msgListLegacyItemStyle: React.CSSProperties = { color: '000000', paddingLeft: '5px', whiteSpace: 'nowrap', verticalAlign: 'center', fontSize: '15px' };
export const msgListLegacyItemStyleFlex: React.CSSProperties = { color: '000000', paddingLeft: '5px', whiteSpace: 'nowrap', verticalAlign: 'center', fontSize: '15px', display: 'inline-flex' };
//export const msgListBlankStyle: React.CSSProperties = { height: '2px', backgroundColor: 'EFEFEF' };
export const msgAlignMiddleStyle: React.CSSProperties = { verticalAlign: 'middle', float: 'left', fontSize: '15px' };
export const msgSubjectStyle: React.CSSProperties = { width: '500px', color: 'blue', overflow: 'hidden', paddingLeft: '5px' };
export const msgListRSSListStyle: React.CSSProperties = { width: '100%', height: '100%', backgroundColor: '#FFF5DB' };
export const msgListRSSDivStyle: React.CSSProperties = { height: '290px', display: 'none', overflowX: 'hidden', overflowY: 'auto', minHeight: '17px' };
export const msgListRSSTableStyle: React.CSSProperties = { width: '607px', height: '100%', display: 'none', minWidth: '596px', border: '0' };// cellspacing="0" };
export const msgListRSSSubTableStyle: React.CSSProperties = { width: '100%', borderColor: '#fff5db', border: '2' };//cellspacing="0" cellpadding="0" };
export const msgListRSSMiniTableStyle: React.CSSProperties = { width: '100%', height: '100%', border: '0' };//cellspacing="0" cellpadding="0"
export const msgListRSSRowStyle: React.CSSProperties = { height: '19px', backgroundColor: '#FFF5DB' };
//export const msgListFooterStyle: React.CSSProperties = { marginRight: '5px', verticalAlign: 'bottom' };
//export const msgListNotificationStyle: React.CSSProperties = { width: '100%', height: '100%', textAlign: 'right', verticalAlign: 'bottom' };
//export const msgListButtonUpStyle: React.CSSProperties = { padding: '4px 3px 0px 0px' };
export const msgListLegacyContentStyle: React.CSSProperties = { height: '100%', display: 'block', overflowX: 'hidden', overflowY: 'auto', minHeight: '17px', maxHeight: '335px' };
export const msgListSubjectHeaderStyle: React.CSSProperties = { fontSize: '13.33px', height: '100%', width: '100%', borderColor: '#fff5db', minHeight: '17px', borderSpacing: '0px', borderCollapse: 'separate', };//, cellspacing="0" cellpadding="0" };
export const msgListLegacySubjectListStyle: React.CSSProperties = { minHeight: '17px' };
export const msgListPaddingRight2px: React.CSSProperties = { paddingRight: '2px' };
//export const msgListRefreshButton: React.CSSProperties = { padding: '2px', width: '15%', display: 'inline' };
export const messageTopContentContainerStyle: React.CSSProperties = { height: '100%', width: '100%', maxHeight: '25px', overflowX: 'hidden', overflowY: 'auto', border: 'none' };//'3px red double' };// cellspacing="0" cellpadding="0"
export const messageTopContentContainerBarStyle: React.CSSProperties = { maxHeight: '25px' };//'3px red double' };// cellspacing="0" cellpadding="0"
export const msgListTableStyle: React.CSSProperties = { height: '100%', width: '100%', overflowX: 'hidden', borderSpacing: '0px', borderCollapse: 'separate', overflowY: 'scroll' };// cellspacing="0" cellpadding="0"
export const msgListRecipientStyle: React.CSSProperties = { color: 'black', backgroundColor: '#FFFFFF', };
export const msgListActionsStyle: React.CSSProperties = { color: 'blue', padding: '2px', height: '17px', fontSize: '10pt' };
export const msgListCurrentServerTimeStyle: React.CSSProperties = { color: 'black', width: '300px', paddingLeft: '3px', height: '17', textAlign: 'right' };
export const msgListCurrentServerTimeDivStyle: React.CSSProperties = { padding: '2px', fontSize: '10pt', position: 'relative', left: '20px'};
export const msgListBlackArrow: React.CSSProperties = { verticalAlign: 'bottom', marginLeft: '5px' };
export const msgListUnderline: React.CSSProperties = { textDecoration: 'underline' };

export const VAlignTopStyle: React.CSSProperties = { verticalAlign: 'top', borderSpacing: '0px', borderCollapse: 'separate' };

export const Width585: React.CSSProperties = { width: '585px' };

export const colSpan2 = 2;

export class ARMMessageListComponent extends React.PureComponent<{}>
{

    render() {
        return (
            <ARMMessageListComponentContainer>
                <ARMMessageListComponentContent>
                    <ARMMessageListComponentIcon><img src={errorIcon} /></ARMMessageListComponentIcon>
                    <ARMMessageListComponentText>Unable to load element</ARMMessageListComponentText>
                </ARMMessageListComponentContent>
            </ARMMessageListComponentContainer>
        );
    }
}
