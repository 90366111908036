import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as UserEmailConfirmStore from './api/UserEmailConfirmStore';

export type IUserEmailConfirmState = IApiComponentState<any, UserEmailConfirmStore.IUserEmailConfirmUIState>;

export const actionCreators = createApiComponentActions<UserEmailConfirmStore.ActionCreators>(UserEmailConfirmStore.actionCreators);
export type IUserEmailConfirmActions = typeof actionCreators;
export type IUserEmailConfirmActionProps = IApiComponentActionProps<IUserEmailConfirmActions>

export const reducers = createApiComponentReducers("userEmailConfirm", UserEmailConfirmStore.reducer);
export const reducer = combineReducers<IUserEmailConfirmState>(reducers);
