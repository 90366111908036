import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, TextArea, Button } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';

import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IFormsUsedState, IFormsUsedActions, IFormsUsedActionProps, actionCreators, validationCallback } from '@store/FormsUsed';
import { defaultState as defaultUIState } from '@store/ui/FormsUsedUI';
import { CheckBoxComponent } from '@common/CheckBox';

import { ModalConfirmation } from '@common/ModalConfirmation';
import { ICookies_Config } from '@store/ConfigData';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';


export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

`;

export const FormsUsedContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    min-height: 180px;
    width: 310px;
    padding: 10px;

`;


interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
};

interface IComponentState {
    cancelLeave: boolean
}

export const DEFAULT_STATE: IComponentState = {cancelLeave: false};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IFormsUsedProps = IMergeCrudComponentProps<IFormsUsedState, IFormsUsedActionProps, IOwnProps>;



export class FormsUsed extends React.Component<IFormsUsedProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Form Types Used Maintenance",
    };

    constructor(props: IFormsUsedProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }



    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }
        pageLeave();
   //     console.log('can edit ' + this.props.canEdit + ' can view ' + this.props.canView);
        this.props.action.configData.getConfigData({ cookies: [{ name: "FormTypes"}], config: [{name: "EnableTenetFeatures"}]});
        this.props.action.crud.get({ crudId: CrudTypes.mctiFormsUsed }, validationCallback);
    }

    public componentWillUnmount() {

        this.props.action.ui.resetFormsUsed(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: 0,
                    value: false,
                }
            });
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);


    }

    private renderCheckbox(item: MCFormsUsed) {
        {
            if (item["@Used"] === "X" || item["@Used"] === "M")
                return null;


            return (
                <React.Fragment key={item["@ID"]}>
                    <CheckBoxComponent
                        id={item["@ID"]}
                        checked={(item["@Used"] === "Y")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onSelectFormsUsed(e)}
                        disabled={!this.props.canEdit}
                        label={item["@Name"]}
                        width="140px"
                        nowrap={true}
                    />
                </React.Fragment>
            );
        }
    }

    public renderCheckboxes = () => {
        if (!this.props.dataStore.crud.data) return null;
        if (!this.props.dataStore.crud.data.FormsUsedMaintenanceInfo) return null;
        if (!this.props.dataStore.crud.data.FormsUsedMaintenanceInfo.Forms) return null;
        if (!this.props.dataStore.crud.data.FormsUsedMaintenanceInfo.Forms.Form) return null;

        if (this.props.dataStore.ui &&
            this.props.dataStore.ui.changed &&
            this.props.dataStore.ui.delta &&
            this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo &&
            this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms &&
            this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms.Form) {
            return this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms.Form.map((item: MCFormsUsed) => this.renderCheckbox(item));
        } else {
            return this.props.dataStore.crud.data.FormsUsedMaintenanceInfo.Forms.Form.map((item: MCFormsUsed) => this.renderCheckbox(item));
        }
    };


    public validateForm(): boolean {
        // should have mapped instead  :(
        const el1001 =
            this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms.Form.find(
                (element) => element["@ID"] === '1001') ;
        const el3010 =
            this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms.Form.find(
                (element) => element["@ID"] === '3010');
        const el3011 =
            this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms.Form.find(
                (element) => element["@ID"] === '3011');
        const el3012 =
            this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms.Form.find(
                (element) => element["@ID"] === '3012');

        if (el1001 && el1001["@Used"] === 'N' &&
            ((el3010 && el3010["@Used"] === 'Y') ||
                (el3011 && el3011["@Used"] === 'Y') ||
                (el3012 && el3012["@Used"] === 'Y'))) {
            this.onAlert('The 1500 form is required when a Dental form is selected.');
            return false;
        }

        return true;

    }


    public onSelectFormsUsed(e: React.ChangeEvent<HTMLInputElement>) {
        handleChange()
        this.props.action.ui.selectFormsUsed(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.id,
                    value: e.target.checked,
                }
            });
        
    }

    public buildAndSetCookie() {
        console.log(this.props.dataStore.ui.delta);

        let newCookVal = "";
        let allowX = this.props.dataStore.configData.container.config.find((o : ICookies_Config) => o.name === "EnableTenetFeatures");

        let formArray : MCFormsUsed[] = [];
        this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms.Form.forEach((form) => {
            let toAdd = false;
            if (allowX && allowX.value === "1") {
                if (form['@Used'] === 'X') {
                    let dupe = this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms.Form.find(
                        x => x['@Used'] === 'Y' && x['@Name'] === form['@Name']);

                    if (dupe) return;
                    toAdd = true;
                }
            }

            if (!toAdd && form['@Used'] === 'M') {
                let dupe = this.props.dataStore.ui.delta.FormsUsedMaintenanceInfo.Forms.Form.find(
                    x => x['@Used'] === 'Y' && x['@Name'] === form['@Name']);

                if (dupe) return;
                toAdd = true;
            }
            if (!toAdd && form['@Used'] === 'A' || form['@Used'] === 'Y') {
                toAdd = true;
            }

            if (toAdd)
                formArray.push(form);
        });

        formArray.forEach((item) => {
            newCookVal += (newCookVal.length !== 0 ? "," : "") + item['@ID'] + "," + item['@Name'] + "," + item['@Type'];
        });

        console.log("new cookie is " + newCookVal);
        this.props.action.configData.setCookie({ name: "FormTypes", value: newCookVal});

    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
    
        if (!this.validateForm())
            return;

     //   console.log(this.props.dataStore.ui.delta);


        this.buildAndSetCookie();
        
        this.props.action.crud.update({ crudId: CrudTypes.mctiFormsUsed, data: this.props.dataStore.ui.delta });

        this.props.history.push( '/LandingPage' );
    }


    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }
    public onClearAlert(e: any) {
        this.onAlert("");
    }
    public onAlert(msg: string) {
        this.props.action.ui.sendFormsUsedAlert({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }


    public render() {
        var instruction = <React.Fragment>Only checked forms are displayed in your options. To hide a form, uncheck it. Do not check a previously unused form without consulting Customer Support.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.dataStore.ui.changed || !this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_FormTypes.asp' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper id={"content_wrapper_formsused"}>
                    <FormsUsedContent>
                        {this.renderCheckboxes()}
                    </FormsUsedContent>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.formsUsedAlert && this.props.dataStore.ui.formsUsedAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                    message={this.props.dataStore.ui.formsUsedAlert}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                />
            </DialogWrapper>
        );
    }
};



var connectedHoc = connect<IFormsUsedState, IFormsUsedActionProps, IOwnProps, IFormsUsedProps, ApplicationState>(
    createCrudMapStateToProps('formsUsed'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(FormsUsed);

export default withRouter(connectedHoc);
