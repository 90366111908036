import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as HoldCodeUI from './ui/HoldCodeUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IHoldCodeState = ICrudComponentState<MCHoldCodeType, HoldCodeUI.IHoldCodeUIState>;

export const actionCreators = createCrudComponentActions<HoldCodeUI.ActionCreators>(HoldCodeUI.actionCreators);
export type IHoldCodeActions = typeof actionCreators;
export type IHoldCodeActionProps = ICrudComponentActionProps<IHoldCodeActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiHoldCode, HoldCodeUI.reducer);
export const reducer = combineReducers<IHoldCodeState>(reducers);


export const validationCallback: ValidationCallback<MCHoldCodeType> = (crud: any): crud is MCHoldCodeType => {
    let testCrud: MCHoldCodeType = crud as MCHoldCodeType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCHoldCodeType");
    if (validShape) {
         // VerifyArray - VerifyObject blowing up when we try to check "" type.. 
        // Hold Codes: no leaf array element
        if (testCrud.HoldCodeMaintenanceInfo?.HoldCodes as any === "") {
            testCrud.HoldCodeMaintenanceInfo.HoldCodes = {HoldCode: new Array<MCHoldCode>()};
        }
        // Hold Codes: leaf element is not an array
        if (testCrud.HoldCodeMaintenanceInfo.HoldCodes &&
            !Array.isArray(testCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode)) 
        {
            let arr = new Array<MCHoldCode>();
            arr.push(JSON.parse(JSON.stringify(testCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode)));
            testCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode = arr;
        }
    }
    return validShape;
}