import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper } from '@common/DialogWrapper';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IFileUploadState, IFileUploadActionProps, actionCreators } from '@store/FileUpload';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ARMNarrowBrowse } from '@common/ARMNarrowBrowse';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '@common/AssuranceMenu';
import { ICookies_Config } from "@store/ConfigData";

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

#file-upload{
    width:800px;
}
#fileupload{
    padding:8px !important;
}

`;

export const UpdatingDialogContainer = styled.div`
    width: 1000px;
    height: 100px;
    border: 1px solid grey;
`;

export const UpdatingStatusMsg = styled.div`
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.black};
    ${Typography.headingSmall};
    ${Typography.ARMFontFamily};
`;

interface IComponentProps {
    canView: boolean;
    redirectUrl: string;
};

interface IComponentState {
    files: any;
    isUpdating: boolean;
    isAlertOpen: boolean;
    alertMessage: string;
    cancelLeave: boolean;
};

export const DEFAULT_STATE: IComponentState = {
    files: new FormData(),
    isUpdating: false,
    isAlertOpen: false,
    alertMessage: "",
    cancelLeave: false
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IFileUploadProps = IMergeCrudComponentProps<IFileUploadState, IFileUploadActionProps, IOwnProps>;

export class FileUpload835 extends React.Component<IFileUploadProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canView: false,
        redirectUrl: ""
    };

    constructor(props: IFileUploadProps) {
        super(props);
        this.state = DEFAULT_STATE;
        console.log(this.props.location.pathname);
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        this.props.action.configData.getConfigData({
            cookies: [{ name: "ViewX12Enabled" }],
            config: []
        });
        pageLeave();
    }

    public validate() {
        if (this.props.dataStore.ui.fileUpload === "") {
            this.setState({ isAlertOpen: true, alertMessage: "Please select a file to upload!" });
            return false;
        }
        return true;
    }

    protected GoToProcess = React.createRef<HTMLFormElement>();

    public renderLegacyGoTo() {
        return (
            <form ref={this.GoToProcess} action={"/Administration/AdminPortal.asp"} name="GoToProcess" method={"POST"}>
                <input type="hidden" name="Process" value={"viewx12remitfromfile"} />
            </form>
        );
    }

    public onOKSubmit(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.validate()) {

            this.setState({ isUpdating: true });
            fetch(URLs.api + '/api/data/x12835Upload',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `${getRawToken()}`
                    },
                    body: this.state.files,
                })
                .then(async (response) => {
                    // get json response here
                    let data: any = await response.json();
                    if (data !== "") {
                        if (data.message === "" || data.message === undefined) {
                            this.setState({ isUpdating: false });
                            if (this.GoToProcess) {
                                this.GoToProcess.current?.submit();
                            }

                        }
                        else {
                            this.setState({ isUpdating: false });
                            this.setState({ isAlertOpen: true, alertMessage: data.message + "." });

                        }
                    }

                    else {
                        this.setState({ isUpdating: false });
                        this.setState({ isAlertOpen: true, alertMessage: "Error while uploading the 835 file. Please try again." });
                    }
                })
                .catch(error => {
                    console.error('Upload Error: ' + error);
                });
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public uploadFileUpload(uploadedfile: any, index: any) {
        const uploadData = new FormData()
        uploadData.append('file', uploadedfile.target.files)
        const files = this.state.files
        files?.delete(`file[${index}]`)
        files.append(`file[${index}]`, uploadedfile.target.files[0])
        this.props.action.ui.selectFileUpload(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: uploadedfile.target.files[0].name
                }
            });
        this.setState({ files: files })
    }

    public render() {
        var width: React.CSSProperties = {
            width: '300px'
        }
        var instruction = <React.Fragment>In order to upload a file, Browse to the location of the file and click the OK button. The upload process may take several minutes depending on file size.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canView} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKSubmit(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        let viewX12 = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "ViewX12Enabled");
        if (viewX12 !== undefined) {
            if (viewX12.value !== "1") {
                this.props.history.push('/LandingPage');
            }
        }
        return (
            <>
                <DialogWrapper title="Upload 835 File" width="1000px" instructionWidth="1000px" instruction={instruction} helpUrl='/Support/Help/REMIT_X12_UPLOAD.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                    {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                    {!this.state.isUpdating ? (
                        <ContentWrapper>
                            <ContentRowWrapper style={{ marginTop: "5px", marginLeft: "5px" }}>
                                <ARMNarrowBrowse labelValue="File:"
                                    inputId="file-upload"
                                    classNameValue="text-input"
                                    maxLength={300}
                                    initialValue={this.props.dataStore.ui.fileUpload}
                                    disabledInput={true}
                                    browseId="fileupload"
                                    browseInputId="file"
                                    onChange={(e: any) => this.uploadFileUpload(e, 1)}
                                    styleValue={{ marginTop: "15px", marginLeft: "10px" }}
                                    browseColumnStyleValue={{ marginTop: "5px", marginLeft: "10px" }}
                                    divBrowseStyle={{ marginTop: '10px', marginLeft:'0px' }}
                                    canEdit={true}
                                >
                                </ARMNarrowBrowse>
                            </ContentRowWrapper>
                        </ContentWrapper>

                    ) : (
                            <UpdatingDialogContainer>
                                <UpdatingStatusMsg>
                                    Uploading 835 changes, please wait..
                                </UpdatingStatusMsg>
                            </UpdatingDialogContainer>
                        )}

                    <ModalConfirmation
                        isOpen={this.state.isAlertOpen}
                        formattedMessage={(
                            <React.Fragment>
                                <p>{this.state.alertMessage}</p>
                            </React.Fragment>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isAlertOpen: false, alertMessage: "" }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.setState({ isAlertOpen: false, alertMessage: "" })

                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isAlertOpen: false }) }}
                        alertMode={true}
                    />
                </DialogWrapper>
                {this.renderLegacyGoTo()}
            </>
        );
    }
};

var connectedHoc = connect<IFileUploadState, IFileUploadActionProps, IOwnProps, IFileUploadProps, ApplicationState>(
    createCrudMapStateToProps('fileUpload'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(FileUpload835);

export default withRouter(connectedHoc);
