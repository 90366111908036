import { Action, Reducer } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IFormsUsedUIState {
    selectedFormsUsed: IFormsUsedUIData;
    delta: MCFormsUsedType;
    changed: boolean;
    formsUsedAlert?: string;
}


export interface IFormsUsedUIData {
    index: string;
    value: boolean;
};


interface IFormsUsedUIFieldUpdate {
    value: string;
}

export const defaultFormsUsed: IFormsUsedUIData = {
    index: '',
    value: false,
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectFormsUsed extends ICrudActionData<MCFormsUsedType, IFormsUsedUIData> { }
export interface IUpdateFormsUsedField extends ICrudActionData<MCFormsUsedType, IFormsUsedUIFieldUpdate> { }

export const actionCreators = {
    selectFormsUsed: (selectInfo: ISelectFormsUsed) => createDataAction('SELECT_FORM', selectInfo),
    resetFormsUsed: (selectInfo: ISelectFormsUsed) => createDataAction('RESET_FORMSUSED_DELTA', selectInfo),
    sendFormsUsedAlert: (fieldInfo: IUpdateFormsUsedField) => createDataAction('SEND_ALERT', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IFormsUsedUIState = {
    selectedFormsUsed: defaultFormsUsed,
    changed: false,
    delta: { FormsUsedMaintenanceInfo: { Forms: { Form: [] } }, },
    formsUsedAlert: '',

};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IFormsUsedUIState, KnownActions> = (state: IFormsUsedUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'RESET_FORMSUSED_DELTA':
                return {
                    ...state,
                    changed: false,
                    formsUsedAlert: '',
                    delta: { FormsUsedMaintenanceInfo: { Forms: { Form: [] } }, },
                }
                break;
            case 'SELECT_FORM':
                if (action.data.masterCrud) {
                    if (state.delta.FormsUsedMaintenanceInfo &&
                        state.delta.FormsUsedMaintenanceInfo.Forms &&
                        state.delta.FormsUsedMaintenanceInfo.Forms.Form &&
                        state.delta.FormsUsedMaintenanceInfo.Forms.Form.length === 0)
                        state.delta = action.data.masterCrud;
                    return {
                        ...state,
                        changed: true,
                        formsUsedAlert: '',
                        delta:
                        {
                            ...state.delta,
                            FormsUsedMaintenanceInfo: {
                                ...state.delta.FormsUsedMaintenanceInfo,
                                Forms: {
                                    ...state.delta.FormsUsedMaintenanceInfo.Forms,
                                    Form: state.delta.FormsUsedMaintenanceInfo.Forms.Form.map((item) => {
                                        if (item["@ID"] === action.data.uiData.index) {
                                            var val = action.data.uiData.value ? "Y" : "N";
                                            item["@Used"] = val;
                                        }
                                        return item;
                                    })
                                }
                            }
                        }
                    }


                }
                break;

            case 'SEND_ALERT':
                return {
                    ...state,
                    formsUsedAlert: action.data.uiData.value,
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
            //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
