import * as React from "react";
import { DialogFieldset, DialogLegend } from "../../../common/DialogStyles";
import { CrudPermissions } from "../CrudPermissions";
import styled from "styled-components";
import { RequiredFieldValidator } from "../Validators/RequiredFieldValidator";
import { Button } from 'ui-core';

import helpIcon from '@content/images/QMark_blue.png';
import * as wind from '@commonResources/window';

//@store prevented component changes from getting reflected
import { Facility, List, LOBElement, MCFacilityMaintenanceInfo } from "../../../../store/api/FacilityMasterType";
import { RegExValidator } from "../Validators/RegExValidator";
import { GenericValidator } from "../Validators/GenericValidator";

interface IComponentProps {
    maintenanceInfo: MCFacilityMaintenanceInfo | undefined,
    facility: Facility,
    isFacilityPayerInfoSubmit: boolean,
    fieldFocus: string,
    updateSelectedFacilityPayerInfo: Function,
    validationAggregation: Map<string, boolean>,
    validationAggregationRoutine: Function,
    cursorPos: number,
    selectedFacilityPayerInfoId: string,
    selectedFacilityPayerInfo: LOBElement
};

interface IComponentState {
    //
}

export class PayerInfoCol2 extends React.Component<IComponentProps, IComponentState> {

    getPlanCodesDropdown() {
        const {
            maintenanceInfo,
            selectedFacilityPayerInfo,
            cursorPos,
            fieldFocus,
            updateSelectedFacilityPayerInfo,
            selectedFacilityPayerInfoId,
        } = this.props;

        const selectedValue = selectedFacilityPayerInfo && selectedFacilityPayerInfo['@PlanCodeID'];
        return (
            <select
                id='PlanCodeID'
                autoFocus={fieldFocus == 'PlanCodeID'}
                style={{ height: 26 }}
                onChange={x => {
                    updateSelectedFacilityPayerInfo(
                        selectedFacilityPayerInfoId, '@PlanCodeID', x.currentTarget.value, 'PlanCodeID', cursorPos)
                }}
                value={selectedValue}
            >
                <option id=''>- Select Plan Code -</option>
                {maintenanceInfo?.PlanCodeList.PlanCode.map(x => {
                    return (
                        <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                    )
                })}
            </select>
        )
    }

    getEnrollmentTypesDropdown() {
        const {
            maintenanceInfo,
            selectedFacilityPayerInfo,
            cursorPos,
            fieldFocus,
            selectedFacilityPayerInfoId,
            updateSelectedFacilityPayerInfo,
        } = this.props;

        const selectedValue = selectedFacilityPayerInfo && selectedFacilityPayerInfo['@EnrollmentTypeID'];

        return (
            <select
                id='EnrollmentTypeID'
                autoFocus={fieldFocus == 'EnrollmentTypeID'}
                style={{ height: 26 }}
                onChange={x => {
                    updateSelectedFacilityPayerInfo(
                        selectedFacilityPayerInfoId, '@EnrollmentTypeID', x.currentTarget.value, 'EnrollmentTypeID', cursorPos)
                }}
                value={selectedValue}
            >
                <option id='' key='ddl-enrollment-type-default' value=''>- Select Enrollment Type -</option>
                {maintenanceInfo?.EnrollmentTypeList.EnrollmentType.map(x => {
                    return (
                        <option key={x["@ID"]} value={x["@ID"]}>{x["@Name"]}</option>
                    )
                })}
            </select>
        )
    }

    render() {
        const {
            facility,
            selectedFacilityPayerInfo,
            selectedFacilityPayerInfoId,
            updateSelectedFacilityPayerInfo,
            cursorPos,
            isFacilityPayerInfoSubmit,
            validationAggregation,
            validationAggregationRoutine,
        } = this.props;

        const ddlPlanCodes = this.getPlanCodesDropdown();
        const ddlEnrollmentTypes = this.getEnrollmentTypesDropdown();

        const enrollmentTypeRequiredTrigger =
            isFacilityPayerInfoSubmit &&
            (selectedFacilityPayerInfo &&
                selectedFacilityPayerInfo['@EnrollmentTypeID'].length == 0) || false;

        const isEmptyRecordSelection = facility["@ID"] == '0' && selectedFacilityPayerInfoId == '0';
        const isEmptyFacilityTypeSelected =
            facility["@FacilityTypeID"] == '' || facility["@FacilityTypeID"] == '0';
        const hpFacilityTypeSelected = facility["@FacilityTypeID"] == '1'; // 1 = HP

        //debugger;

        /* CCIBP */
        let displayCCIBPDisabled = true;
        let displayCCIBPUnchecked = true;
        // disable and uncheck for empty facility or facility type
        if ((isEmptyRecordSelection || isEmptyFacilityTypeSelected) || hpFacilityTypeSelected) {
            displayCCIBPDisabled = true;
            displayCCIBPUnchecked = true;
        } else {
            displayCCIBPDisabled = false;
            displayCCIBPUnchecked = false;
        }

        /* MNBP */
        let displayMNBPDisabled = true;
        let displayMNBPUnchecked = true;
        // disable and uncheck for empty facility or facility type
        if ((isEmptyRecordSelection || isEmptyFacilityTypeSelected) || hpFacilityTypeSelected) {
            displayMNBPDisabled = true;
            displayMNBPUnchecked = true;
        } else {
            displayMNBPDisabled = false;
            displayMNBPUnchecked = false;
        }

        /* CCIAI */
        let displayCCIAIDisabled = true;
        let displayCCIAIUnchecked = true;
        // disable and uncheck for empty facility or facility type
        if ((isEmptyRecordSelection || isEmptyFacilityTypeSelected) || !hpFacilityTypeSelected) {
            displayCCIAIDisabled = true;
            displayCCIAIUnchecked = true;
        } else {
            displayCCIAIDisabled = false;
            displayCCIAIUnchecked = false;
        }

        /* MNAI */
        let displayMNAIDisabled = true;
        let displayMNAIUnchecked = true;
        // disable and uncheck for empty facility or facility type
        if ((isEmptyRecordSelection || isEmptyFacilityTypeSelected) || !hpFacilityTypeSelected) {
            displayMNAIDisabled = true;
            displayMNAIUnchecked = true;
        } else {
            displayMNAIDisabled = false;
            displayMNAIUnchecked = false;
        }

        //displayCCIBPUnchecked
        //debugger;

        return (
            <table>
                <tbody>
                    <tr>
                        <td className="fieldLabel">Plan Code:</td>
                        <td style={{ width: 5 }}></td>
                        <td>{ddlPlanCodes}</td>
                    </tr>

                    <tr>
                        <td className="fieldLabel">* Enrollment Type:</td>
                        <td style={{ width: 5 }}></td>
                        <td>{ddlEnrollmentTypes}</td>
                    </tr>

                    <RequiredFieldValidator
                        fieldId={'PayerInfo.EnrollmentTypeID'}
                        fieldName={'Enrollment Type ID'}
                        triggers={[
                            enrollmentTypeRequiredTrigger
                        ]}
                        style={{ position: 'relative', left: 3 }}
                        validationAggregation={validationAggregation}
                        validationAggregationRoutine={validationAggregationRoutine}
                    />

                    <GenericValidator
                        fieldId={'PayerInfo.EnrollmentTypeID.GenericValidator'}
                        fieldName={'Enrollment Type ID'}
                        trigger={
                            isFacilityPayerInfoSubmit &&
                            // ancillary enrollment type
                            selectedFacilityPayerInfo['@EnrollmentTypeID'] == '3' &&
                            // non-ph facility type
                            facility['@FacilityTypeID'] != '2'
                        }
                        message={'Ancillary enrollment type can only be used for PH facilities'}
                        style={{width:200}}
                        validationAggregation={validationAggregation}
                        validationAggregationRoutine={validationAggregationRoutine}
                    />

                    <tr>
                        <td className="fieldLabel">Account Status:</td>
                        <td style={{ width: 5 }}></td>
                        <td>
                            <span>
                                {selectedFacilityPayerInfo['@AccountStatus'] == 'T' ? 'Test' : ''}
                                {selectedFacilityPayerInfo['@AccountStatus'] == 'L' ? 'Live' : ''}
                            </span>
                        </td>
                    </tr>

                    <RequiredFieldValidator
                        fieldId={'PayerInfo.AccountStatus'}
                        fieldName={'Account Status'}
                        triggers={[
                            isFacilityPayerInfoSubmit &&
                            !selectedFacilityPayerInfo['@AccountStatus']
                        ]}
                        style={{ position: 'relative', left: 3 }}
                        validationAggregation={validationAggregation}
                        validationAggregationRoutine={validationAggregationRoutine}
                    />

                    {/* display for medicare lob */}
                    {selectedFacilityPayerInfo['@LOBID'] == '1' &&
                        <tr>
                            <td rowSpan={4} colSpan={3} style={{ verticalAlign: 'top' }}>
                                <DialogFieldset>
                                    <DialogLegend>Compliance Edit Suppression</DialogLegend>
                                    <table style={{ fontWeight: 'bold' }}>
                                        <tbody>
                                            <tr>
                                                <td></td>
                                                <td style={{ paddingLeft: 25 }}>CCI</td>
                                                <td style={{ paddingLeft: 25 }}>MN</td>
                                            </tr>
                                            <tr>
                                                <td>Suppress Professional</td>
                                                <td style={{ paddingLeft: 25, textAlign: 'center' }}>
                                                <input
                                                        type="checkbox"
                                                        checked={
                                                                !displayCCIBPUnchecked &&
                                                                selectedFacilityPayerInfo['@SuppressCCIBP'] == 'Y'
                                                        }
                                                        disabled={displayCCIBPDisabled}
                                                        onChange={x => {
                                                            //debugger;
                                                            updateSelectedFacilityPayerInfo(
                                                                selectedFacilityPayerInfoId,
                                                                '@SuppressCCIBP',
                                                                selectedFacilityPayerInfo['@SuppressCCIBP'] == 'Y' ? 'N' : 'Y',
                                                                'SuppressCCIBP',
                                                                cursorPos)
                                                        }}
                                                    />
                                                </td>
                                                <td style={{ paddingLeft: 25, textAlign: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            !displayMNBPUnchecked &&
                                                            selectedFacilityPayerInfo['@SuppressMNBP'] == 'Y'
                                                        }
                                                        disabled={displayMNBPDisabled}
                                                        onChange={x =>
                                                            updateSelectedFacilityPayerInfo(
                                                                selectedFacilityPayerInfoId,
                                                                '@SuppressMNBP',
                                                                selectedFacilityPayerInfo['@SuppressMNBP'] == 'Y' ? 'N' : 'Y',
                                                                'SuppressMNBP',
                                                                cursorPos)
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Suppress Institutional</td>
                                                <td style={{ paddingLeft: 25, textAlign: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            !displayCCIAIUnchecked &&
                                                            selectedFacilityPayerInfo['@SuppressCCIAI'] == 'Y'
                                                        }
                                                        disabled={displayCCIAIDisabled}
                                                        onChange={x =>
                                                            updateSelectedFacilityPayerInfo(
                                                                selectedFacilityPayerInfoId,
                                                                '@SuppressCCIAI',
                                                                selectedFacilityPayerInfo['@SuppressCCIAI'] == 'Y' ? 'N' : 'Y',
                                                                'SuppressCCIAI',
                                                                cursorPos)
                                                        }
                                                    />
                                                </td>
                                                <td style={{ paddingLeft: 25, textAlign: 'center' }}>
                                                    <input
                                                        type="checkbox"
                                                        checked={
                                                            !displayMNAIUnchecked &&
                                                            selectedFacilityPayerInfo['@SuppressMNAI'] == 'Y'
                                                        }
                                                        disabled={displayMNAIDisabled}
                                                        onChange={x =>
                                                            updateSelectedFacilityPayerInfo(
                                                                selectedFacilityPayerInfoId,
                                                                '@SuppressMNAI',
                                                                selectedFacilityPayerInfo['@SuppressMNAI'] == 'Y' ? 'N' : 'Y',
                                                                'SuppressMNAI',
                                                                cursorPos)
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </DialogFieldset>
                            </td>
                        </tr>
                    }
                </tbody>
            </table >
        )
    }
}
