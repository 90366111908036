import * as React from 'react';
import { Route } from 'react-router';
import * as RoutesModule from './routes';

import './css/site.css'

let routes = RoutesModule.routes;

export default () => (
    <React.Fragment>
    { routes }
    </React.Fragment>
);
