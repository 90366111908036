import {
    Facility,
    FacilityDelta,
    LOBElement,
    LOBElementDelta,
    MCFacilityMasterTypeDelta
} from "@store/api/FacilityMasterType";
import _ from "lodash";
import {Record} from "immutable";

export class FacilityManager {
    public saveFacility(
        originalFacility: Facility,
        facilityInformationFacility: Facility) {
        originalFacility["@Description"] = facilityInformationFacility["@Description"];
    }

    public addFacility(facility: Facility) {
        // TODO: trim() all textbox values to be saved
    }

    public updateFacility(facility: Facility) {
        // TODO: trim() all textbox values to be saved
    }

    public removeFacility(facilityId: string) {

    }
    
    // handles inserting or updating an existing facility to the delta object
    public upsertFacilityDelta(deltaObj: MCFacilityMasterTypeDelta, facilityToUpsert: Facility) : MCFacilityMasterTypeDelta {

        const deltaFacility = this.upperCaseFacilityFields(_.cloneDeep(facilityToUpsert));
        const newDelta = _.cloneDeep(deltaObj);
        // do not add/modify existing LOBS for a facility update
        deltaFacility.LOB = [];
        // overwrite existing value, if there (keep LOBS that were modified on delta)       
        if(newDelta.FacilityMaintenanceInfo.Facilities && newDelta.FacilityMaintenanceInfo.Facilities.Facility){
            const idx = newDelta.FacilityMaintenanceInfo.Facilities.Facility.findIndex(x => x["@ID"] === deltaFacility["@ID"])
            if(idx > -1){
                let existingDeltaLobs = _.cloneDeep(newDelta.FacilityMaintenanceInfo.Facilities.Facility[idx].LOB);               
                newDelta.FacilityMaintenanceInfo.Facilities.Facility[idx] = deltaFacility as FacilityDelta;
                newDelta.FacilityMaintenanceInfo.Facilities.Facility[idx].LOB = existingDeltaLobs;
                    
                return newDelta;
            }
        } 
        // new facilities with a # were inserted in this session, or it wasn't there
        newDelta.FacilityMaintenanceInfo.Facilities.Facility.push(deltaFacility as FacilityDelta);
        return newDelta;
    }

    // creates a "Delete" entry on the facility delta object
    public deleteFacilityDelta(deltaObj: MCFacilityMasterTypeDelta, facilityToDelete: Facility) : MCFacilityMasterTypeDelta {
        const newDelta = _.cloneDeep(deltaObj);

        if(newDelta.FacilityMaintenanceInfo.Facilities && newDelta.FacilityMaintenanceInfo.Facilities.Facility){
            
            // this was added in this session, just remove it from delta
            if(facilityToDelete["@ID"].indexOf("#") > -1){
                newDelta.FacilityMaintenanceInfo.Facilities.Facility =
                    newDelta.FacilityMaintenanceInfo.Facilities.Facility.slice().filter(facility => facility["@ID"] !== facilityToDelete["@ID"]);
            }
            else{
                const deletedFacility : FacilityDelta = {
                    "@ID" : facilityToDelete["@ID"],
                    "@Delete": "true"
                }
                // updated the data but are deleting it anyway
                const idx = newDelta.FacilityMaintenanceInfo.Facilities.Facility.findIndex(x => x["@ID"] === facilityToDelete["@ID"])
                if(idx > -1){
                    newDelta.FacilityMaintenanceInfo.Facilities.Facility[idx] = deletedFacility;
                }
                else {
                    newDelta.FacilityMaintenanceInfo.Facilities.Facility.push(deletedFacility);
                }
            }
        }
        return newDelta;
    }

    // inserts or updates a payer entry on the facility delta object
    public upsertPayerDelta(deltaObj: MCFacilityMasterTypeDelta, payerToUpsert: LOBElement, facilityId: string): MCFacilityMasterTypeDelta {
        const newDelta = _.cloneDeep(deltaObj);
        // check if facility is already there
        payerToUpsert = this.upperCasePayerFields(payerToUpsert);

        // facility delta already exists
        if (newDelta.FacilityMaintenanceInfo.Facilities && newDelta.FacilityMaintenanceInfo.Facilities.Facility) {
            const idx = newDelta.FacilityMaintenanceInfo.Facilities.Facility.findIndex(x => x["@ID"] === facilityId)
            if (idx > -1) {
                // does the payer exist
                let payerLob = newDelta.FacilityMaintenanceInfo.Facilities.Facility[idx].LOB;
                let payerIdx: number = -1;
                if (payerLob) {
                    if (Array.isArray(payerLob)) {
                        payerIdx = payerLob.findIndex(x => x["@ID"] === payerToUpsert["@ID"]);
                        if (payerIdx > -1) {
                            payerLob[payerIdx] = _.cloneDeep(payerToUpsert) as LOBElementDelta;
                            newDelta.FacilityMaintenanceInfo.Facilities.Facility[idx].LOB = payerLob;
                            return newDelta;
                        }
                        else{
                            payerLob.push(_.cloneDeep(payerToUpsert) as LOBElementDelta);
                            return newDelta;
                        }
                    }
                }
                payerLob = new Array<LOBElementDelta>();
                payerLob.push(_.cloneDeep(payerToUpsert) as LOBElementDelta);
                newDelta.FacilityMaintenanceInfo.Facilities.Facility[idx].LOB = payerLob;
                return newDelta;
            }
        }
        // otherwise need to add the facility id ref and payer
        const newFacilityPayer: FacilityDelta = {
            "@ID": facilityId,
            LOB: [_.cloneDeep(payerToUpsert) as LOBElementDelta]
        }
        newDelta.FacilityMaintenanceInfo.Facilities.Facility.push(newFacilityPayer);
        return newDelta;
    }

    // adds a "Delete" entry on a payer for a facility->payer delta object
    public deletePayerDelta(deltaObj: MCFacilityMasterTypeDelta, payerId: string, facilityId: string) : MCFacilityMasterTypeDelta {
        const newDelta = _.cloneDeep(deltaObj);
        // check if facility is already there
       
        // facility delta already exists
        if(newDelta.FacilityMaintenanceInfo.Facilities && newDelta.FacilityMaintenanceInfo.Facilities.Facility){
            const idx = newDelta.FacilityMaintenanceInfo.Facilities.Facility.findIndex(x => x["@ID"] === facilityId)
            if(idx > -1){
                // does the payer exist
                let payerLob = newDelta.FacilityMaintenanceInfo.Facilities.Facility[idx].LOB;
                let payerIdx : number = -1;
                if(payerLob){
                    const deletedPayer : LOBElementDelta = {
                        "@ID": payerId,
                        "@Delete": "true"
                    };
                    if(Array.isArray(payerLob)){
                        payerIdx = payerLob.findIndex(x => x["@ID"] === payerId)
                    }
                    if(payerIdx > -1){
                        // if payer was added in this session, just remove the entry
                        if(payerLob[payerIdx]["@ID"].indexOf("#") > -1){
                            payerLob = payerLob.slice().filter(payer => payer["@ID"] !== payerId);
                        }
                        else {
                            payerLob[payerIdx] = deletedPayer;
                        }
                        newDelta.FacilityMaintenanceInfo.Facilities.Facility[idx].LOB = payerLob;
                    }
                    else{
                        payerLob.push(deletedPayer);
                    }
                }
                return newDelta;
            }
        }
        // otherwise need to add the facility id ref and payer
        const newFacilityPayer: FacilityDelta = {
            "@ID": facilityId,
            LOB: [{"@ID": payerId, "@Delete": "true"}]
        }
        newDelta.FacilityMaintenanceInfo.Facilities.Facility.push(newFacilityPayer);
        return newDelta;
    }
    
    public prepareDeltaUpdate(deltaCrud: MCFacilityMasterTypeDelta ) {
        
        // remove all numerics from # (new) entries, delta doesn't need it
        if(deltaCrud.FacilityMaintenanceInfo.Facilities.Facility){
            if(deltaCrud.FacilityMaintenanceInfo.Facilities.Facility.length > 0){
                for (let i = 0; i < deltaCrud.FacilityMaintenanceInfo.Facilities.Facility.length; i++) {
                    if(deltaCrud.FacilityMaintenanceInfo.Facilities.Facility[i]["@ID"].indexOf("#") > -1){
                        deltaCrud.FacilityMaintenanceInfo.Facilities.Facility[i]["@ID"] = "#";
                    }
                    
                    let payers =  deltaCrud.FacilityMaintenanceInfo.Facilities.Facility[i].LOB;
                    if(payers && Array.isArray(payers)){
                        if(payers.length === 0)
                            delete deltaCrud.FacilityMaintenanceInfo.Facilities.Facility[i].LOB;
                        else {
                            for (let j = 0; j < payers.length; j++) {
                                if (payers[j]["@ID"].indexOf("#") > -1)
                                    payers[j]["@ID"] = "#";
                            }
                        }
                    }
                    else{ // empty lob or object causes error
                        delete deltaCrud.FacilityMaintenanceInfo.Facilities.Facility[i].LOB;
                    }
                }
            }
        }
        
        return _.cloneDeep(deltaCrud);
    }

    upperCaseFacilityFields(facility: Facility) : Facility {
        const fieldNames = [
            '@Name',
            '@Description',
            '@Address',
            '@Address2',
            '@City',
            '@ZipCode',
            '@TelephoneNo',
            '@FaxNo',
            '@ServiceStateID',
            '@ServiceZipCode',
            '@FacilitySubId',
            '@FedTaxId',
            '@FedTaxSubId',
            '@UB92AlternateEditMaster',
            '@HCFAAlternateEditMaster',
            '@BillingNumber',
            '@PhysicianAccountNo',
            '@SubmitterCode',
            '@FacilityNPI',
            '@TaxonomyCode',
            '@PayToName',
            '@PayToAddress',
            '@PayToAddress2',
            '@PayToCity',
            '@PayToZipCode',
        ]

        fieldNames.forEach(x => {
            // include guard to exclude non-string properties
            if (facility[x] && (typeof facility[x]) == "string") {
                facility[x] = ((facility[x] as string) || '').toUpperCase();
            }
        });
        
        return facility;
    }

    upperCasePayerFields(payer: LOBElement) : LOBElement {
        const fieldNames = [
            '@ProviderNo',
            '@PayerID',
            '@LOBID',
            '@Description',
            '@PlanCodeID',
            '@EnrollmentTypeID',
            '@SetToDefault',
            '@NPI',
            '@Taxonomy',
            '@NPIFlag'
        ]

        fieldNames.forEach(x => {
            // include guard to exclude non-string properties
            if (payer[x] && (typeof payer[x]) == "string") {
                payer[x] = ((payer[x] as string) || '').toUpperCase();
            }
        });

        return payer;
    }
}
