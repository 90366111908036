import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import styled from 'styled-components';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { ICookies_Config } from '@store/ConfigData';
import { defaultState as defaultUIState } from '@store/api/RemitWorkingGroupDisplayStore';
import { IRemitWorkingGroupDisplayState, IRemitWorkingGroupDisplayActions, IRemitWorkingGroupDisplayActionProps, actionCreators/*, validationCallback */ } from '@store/RemitWorkingGroupDisplay';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { Input, TextArea, Button, Grid, Caret } from 'ui-core';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { ARMGrid } from '../../common/UICLWrappers/ARMGrid';
import { ARMGridPager } from '../../common/UICLWrappers/ARMGridPager';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ChangeEvent } from 'react';
import { SortHelper, ISortIndicator, SortDataType } from '@scripts/util/SortHelper';

interface FilterJsonObject {
    "RemitIDList": {
        "Remit": {
            "@ID": string,
            "@SequenceNo": number
        }[
        ]
    }
}

interface UiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

interface SelectedRemitIndicator {
    pageNumber: number,
    remitID: string,
    paymentAmt: number,
    totalCharges: number,
    paymentId: string,
}

interface HeaderProps {
    columns: {
    }
}

export const DetailWrapper = styled.div`
    table {
        width:100%;
    }

    table thead th {
        font-weight:bold;
        width: '300px';
    }

    table tr td {
        background-color:white;
    }
`;

export const GridWrapper = styled.div`

    width:1375px;
    overflow-y: hidden !important;

    .selectedRow{
        background-color:yellow !important;
    }

    .unselectedRow{
        background-color:white;
    }

    #grid-wrapper-remit-list-grid {

        height:300px;

        table tbody tr > td:first-child:not(.select-checkbox){
            padding-left:30px;
        }


        table > thead > tr > th {
/*
            height:32px;
            background-color:#9BA1A9 !important;
            font-weight:bold;
            font-family:"Core Sans C","Century Gothic","AppleGothic",sans-serif;
            position:sticky;
            top:0;
*/
        }


        #thPayer{
            padding-bottom:3.5px;
        }

        table tbody tr > td{
            height:32px !important;
            padding-top:8px;
        }
    }

    table tbody tr:hover{
        background-color:#edf9ff !important;
        cursor:pointer !important;
    }

    max-height:500px;
    overflow-y:scroll;
    background-color:white;
    border: black 1px solid;

    .empty-row{
        background-color:white !important;
    }

    .patientName {
        color: red;
        min-width:250px;
        max-width:250px;
        overflow:hidden;
    }

    .controlNo {
        min-width:125px;
        max-width:125px;
    }

    .totalCharges {
        min-width:150px;
        max-width:150px;
    }

    .typeOfBill {
        min-width:80px;
        max-width:80px;
    }

    .formName {
        min-width:80px;
        max-width:80px;
    }

    .payerName {
        width:300px;
        overflow:hidden;
    }

    table tbody tr > td{
        height:25px !important;
    }

    table tbody tr td div{
        min-height:1px;
    }
`;

interface IComponentProps {
    canEdit: boolean
    canView: boolean,
    title: string,
};

interface IComponentState {
    isBusy: boolean,
    process: any,
    parameter: any,
    remitTitle: string,
    securityClearance: string,
    execNode: string,
    execAction: string,
    execProcess: string,
    execAttribs: string,
    permission: string,
    remits: WGDBPRemit[],
    filteredRemits: WGDBPRemit[],
    remitIdList: WGDBPRemit[],
    selectedRemitIndicators: SelectedRemitIndicator[],
    mouseOverRemitId: string,
    sortIndicator: ISortIndicator,
    selectedColumnName: string,
    selectedSortDataType: SortDataType,
    gridPaging: {
        currentPageNumber: number,
        numberOfPages: number,
        recordsPerPage: number,
        totalRecordCount: number
    },
    scrollToTop: boolean,
    filterName: string,
    filterAlert: string,
};

interface HeaderProps {
    columns: {

    }
}

interface RowProps {
    record: {
        remitID: string,
        patientName: string,
        controlNo: string,
        hicNo: string,
        importDate: string,
        providerNo: string,
        lobCode: string,
        payer: string,
        remitStatus: string,
        ioPatient: string,
        typeOfBill: string,
        totalCharges: string,
        paymentAmt: string,
        paymentNo: string,
        paymentDate: string,
        stmtFromDate: string,
        stmtThruDate: string,
        secBillDate: string,
        fileName: string,
    }
}

const DEFAULT_STATE: IComponentState = {
    isBusy: true,
    process: 'view',
    parameter: {},
    remitTitle: '',
    securityClearance: '',
    execNode: '',
    execAction: '',
    execProcess: '',
    execAttribs: '',
    permission: '',
    remits: [],
    filteredRemits: [],
    remitIdList: [],
    selectedRemitIndicators: [],
    mouseOverRemitId: '',
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
        sortDataType: SortDataType.String,
    },
    selectedColumnName: 'providerNo',
    selectedSortDataType: SortDataType.Number,
    gridPaging: {
        currentPageNumber: 1,
        numberOfPages: 1,
        recordsPerPage: 100,
        totalRecordCount: 0
    },
    scrollToTop: false,
    filterName: '',
    filterAlert: '',
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IRemitWorkingGroupDisplayProps = IMergeApiComponentProps<IRemitWorkingGroupDisplayState, IRemitWorkingGroupDisplayActionProps, IOwnProps>;


/////////////////////////////////////////////////////////////////////////////////////////////

export const ContentWrapper = styled.div`
`;

export const RemitWorkingGroupDisplayContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    min-height: 200px;
    width: 1400px;
    padding: 10px;
`;

export class WorkingGroupDisplayByPayment extends React.Component<IRemitWorkingGroupDisplayProps, IComponentState> {

    columnNameMap = [
        { value: 'payer', text: 'Payer', sortDataType: SortDataType.String },
        { value: 'paymentNo', text: 'Payment No', sortDataType: SortDataType.String },
        { value: 'totalCharges', text: 'Total charges', sortDataType: SortDataType.Number },
        { value: 'paymentAmt', text: 'Pmt Amt', sortDataType: SortDataType.Number },
        { value: 'fileName', text: 'File Name', sortDataType: SortDataType.String },
        { value: 'importDate', text: 'Import Date', sortDataType: SortDataType.Date },
        { value: 'paymentDate', text: 'Payment Date', sortDataType: SortDataType.Date },
        //{ value: 'hicNo', text: 'HIC no', sortDataType: SortDataType.String },
        //{ value: 'ioPatient', text: 'I/O patient', sortDataType: SortDataType.String },
        { value: 'lobCode', text: 'LOB Code', sortDataType: SortDataType.String },
        { value: 'providerNo', text: 'Provider No', sortDataType: SortDataType.String },
        //{ value: 'remitStatus', text: 'Remit status', sortDataType: SortDataType.String },
        //{ value: 'secBillDate', text: 'Sec bill date', sortDataType: SortDataType.String },
        //{ value: 'stmtFromDate', text: 'Stmt from date', sortDataType: SortDataType.Date },
        //{ value: 'stmtThruDate', text: 'Stmt thru date', sortDataType: SortDataType.Date },
        //{ value: 'typeOfBill', text: 'Type of bill', sortDataType: SortDataType.String }
    ];

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: '' //set dynamically in render()
    };

    constructor(props: IRemitWorkingGroupDisplayProps) {
        super(props);


        const {
            process, execProcess, execNode, execAction, parameter, title, filterName
        } = this.props.location.state;

       process.toLowerCase().includes("postingfile")

        let newSelectedColumnName = sessionStorage.getItem("RemitByPaymentFilterSelectedColumnName");

        this.state = {
            isBusy: true,
            process: process?.toLowerCase()?.includes("postingfile") ? process + 'batch' : process,
            remitTitle: title == undefined || title == '' ? 'Refine Remits' : title,
            securityClearance: 'FN_VALIDATE_CLAIMS',
            execNode: execNode == undefined || execNode == '' ? 'MultiRemit' : execNode,
            execAction: execAction == undefined || execAction == '' ? 'Validation' : execAction,
            execProcess: execProcess == undefined || execProcess == '' ? 'Reedit' : execProcess,
            execAttribs: parameter,
            parameter: parameter,
            permission: 'FN_VALIDATE_CLAIMS',
            remits: [],
            filteredRemits: [],
            remitIdList: [],
            selectedRemitIndicators: [],
            mouseOverRemitId: '',
            selectedColumnName: newSelectedColumnName ?? 'paymentDate',
            selectedSortDataType: SortDataType.String,
            sortIndicator: {
                sortColumn: '',
                sortDirection: 'up',
                sortDataType: SortDataType.String
            },
            gridPaging: {
                currentPageNumber: 1,
                numberOfPages: 1,
                recordsPerPage: 100,
                totalRecordCount: 0
            },
            scrollToTop: false,
            filterName: filterName,
            filterAlert: '',
        }

        this.handleSorting = this.handleSorting.bind(this);
        this.handlePaging = this.handlePaging.bind(this);
    }



    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }

        this.props.action.configData.getConfigData({
            cookies: [],
            config: [
                { name: "RemitPostingFile_MaxCLPs" }
            ],
        });

        this.getRemits(true);
    }

    setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    public errorCallback(dataStatus: string): boolean {
        var str = 'RemitWorkingGroupDisplay - An error occurred processing call for ' + this.props.title + ".  Error: " + dataStatus
        console.error(str);
        this.onDisplayRemitWorkingGroupDisplayError(str);

        return true;
    }

    public onDisplayRemitWorkingGroupDisplayError(msg: string) {
        // TODO this.props.action.ui.displayErrorMessage({ api: this.props.dataStore.api.data, uiData: { value: msg } });
    }

    getRemits(onLoad: boolean) {
        const {
            gridPaging
        } = this.state;

        //const {
        //    testMode
        //} = this.props.location.state && this.props.location.state.testMode;

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        let clientId = sessionStorage.getItem("Client")?.split(' ')[0];
        xmlData.ClientID = clientId;
        xmlData.TestMode = false; // testMode;

        // show spinner before fetch
        this.setState({ isBusy: true });

        fetch(URLs.api + '/api/data/GetUserRemitListEx/' + gridPaging.currentPageNumber,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                if (response.status === 200) {
                    let myRemits: WGDBPRemit[] = data.remitPayments;
                    let myRemitIdList: WGDBPRemit[] = data.remitIdList
                    myRemits.forEach((item) => {
                        //x.hicNo.length > 15 ? x.hicNo = x.hicNo.substr(0, 15 - 3) + '...' : x.hicNo;
                        //if (item.payer.length > 35) {
                        //    item.payer = item.payer.substr(0, 35 - 3) + '...';
                        //}

                        //item.paymentAmt = item.paymentAmt.match(/^-?\d+(?:\.\d{0,2})?/)[0]
                        let formatPaymentAmt = Number(item.paymentAmt).toFixed(2);
                        let formatTotalCharges = Number(item.totalCharges).toFixed(2);

                        item.totalCharges = String(formatTotalCharges);
                        item.paymentAmt = String(formatPaymentAmt);
                        item.paymentId = item.paymentId;

                        const toAddRemitIdList = myRemitIdList.find(element => element.paymentId === item.paymentId)
                        item = Object.assign(item, {
                            ...item,
                            remitID: toAddRemitIdList?.remitID
                        })
                    });
                    // build the gridPaging numbers
                    let numberOfPages = 1;
                    //numberOfPages = Math.round(parseInt(data.totalRemits) / gridPaging.recordsPerPage);
                    numberOfPages = Math.ceil(parseInt(data.totalRemits) / gridPaging.recordsPerPage);
                    if (numberOfPages < 1) numberOfPages = 1;

                    //filter load remits for recordsPerPage
                    let onLoadRecordsPerPage: WGDBPRemit[] = []
                    if (onLoad === true) {
                        onLoadRecordsPerPage = myRemits.slice(0, gridPaging.recordsPerPage)
                    } else {
                        onLoadRecordsPerPage = myRemits
                    }

                    this.setState({
                        remits: myRemits,
                        filteredRemits: onLoadRecordsPerPage,
                        remitIdList: myRemitIdList,
                        mouseOverRemitId: '',   //myRemits[0].remitId,
                        selectedColumnName: window.sessionStorage.RemitByPaymentFilterSelectedColumnName ? window.sessionStorage.RemitByPaymentFilterSelectedColumnName : 'providerNo',
                        gridPaging: {
                            currentPageNumber: gridPaging.currentPageNumber,
                            numberOfPages: numberOfPages,
                            recordsPerPage: gridPaging.recordsPerPage,
                            totalRecordCount: Number(data.totalRemits)
                        },
                        isBusy: false // hide spinner
                    }, () => {
                        //this.handleSorting({
                        //    sortColumn: this.state.selectedColumnName ?? 'payer',
                        //    sortDirection: 'up',
                        //    sortDataType: this.sortDataTypeOnLoad(this.state.selectedColumnName)
                        //    //sortDataType: SortDataType.String
                        //});
                    }
                    );
                    return;
                } else if (response.status === 306) {
                    return;
                } else {
                    let errorMessage =
                        `WGD: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`
                    throw new Error(errorMessage);
                }
            })
            .catch(error => {
                console.error('WGD: ' + error);
            })
            .finally(() => {
                this.setState({ isBusy: false });
            });
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public sortDataTypeOnLoad = (selectedColumnName: string) => {
        switch (selectedColumnName) {
            case 'providerNo': {
                return SortDataType.String
            }
                break;
            case 'paymentDate': {
                return SortDataType.Date
            }
                break;
            case 'fileName': {
                return SortDataType.String
            }
                break;
            case 'importDate': {
                return SortDataType.Date
            }
                break;
            case 'lobCode': {
                return SortDataType.Number
            }
                break;
            default: {
                return SortDataType.String
            }
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    getGridColumnHeaders() {
        const columns: UiclColumn[] = [
            {
                dataName: "payer",
                text: 'Payer',
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "paymentNo",
                text: "Payment No",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "totalCharges",
                text: "Total charges",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "paymentAmt",
                text: "Paymount amt",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
        ];

        const selectedColumn = this.getSelectedColumn();
        columns.push(
            selectedColumn
        );
        return new Set(columns);
    }

    getSelectedColumn() {
        const selectableColumns: UiclColumn[] = [
            {
                dataName: "fileName",
                text: "File Name",
            },
            {
                dataName: "lobCode",
                text: "LOB Code",
            },
            {
                dataName: "paymentNo",
                text: "Payment No",
            },
            {
                dataName: "paymentDate",
                text: "Payment Date",
            },
            {
                dataName: "importDate",
                text: "Import Date",
            },
            {
                dataName: "providerNo",
                text: "Provider no",
            }
        ];

        selectableColumns.map(x => {
            x.sortable = true;
            x.cellType = 'text';
            x.isSorted = 0
        });
        return selectableColumns.filter(x => x.dataName === this.state.selectedColumnName)[0];
    }

    handlePaging(pageNumber: number) {
        const {
            remits,
            gridPaging
        } = this.state;

        // get the requested page of records
        const indexStart = (pageNumber - 1) * gridPaging.recordsPerPage;
        const indexEnd = indexStart + gridPaging.recordsPerPage;
        const page = remits.slice(indexStart, indexEnd);
        this.setState({
            filteredRemits: page,
            gridPaging: {
                currentPageNumber: pageNumber,
                numberOfPages: gridPaging.numberOfPages,
                recordsPerPage: gridPaging.recordsPerPage,
                totalRecordCount: gridPaging.totalRecordCount
            },
            // scroll to top of new grid page
            scrollToTop: true,
        }, () => {
            //this.getRemits(false);
            // restore user scroll pos persistence
            this.setState({ scrollToTop: false })
        });
    }

    handleSorting(sortIndicator: ISortIndicator) {
        const {
            filteredRemits
        } = this.state;

        let sortedRemits = SortHelper.Sort(filteredRemits, sortIndicator);

        this.setState({
            sortIndicator,
            filteredRemits: sortedRemits
        });
    }

    displaySelectedColumn(event: ChangeEvent<HTMLSelectElement>) {
        const selectedSortDataType = this.getSelectedSortDataType(event.target.value);
        this.setState({
            selectedColumnName: event.target.value,
            selectedSortDataType
        });
        sessionStorage.setItem("RemitByPaymentFilterSelectedColumnName", event.target.value);
    }

    getColumnNameOptions() {
        let columnNameOptions: { value: string, text: string }[] = [];
        this.columnNameMap.forEach(x => {
            columnNameOptions.push({
                value: x.value,
                text: x.text
            })
        });
        
        return columnNameOptions;
    }

    getSelectedSortDataType(columnName: string) {
        const selectedColumnMap = this.columnNameMap.find(x => x.value === columnName);
        return selectedColumnMap?.sortDataType || SortDataType.String;
    }

    getCustomHeader() {
        const {
            sortIndicator,
            selectedSortDataType
        } = this.state;

        const columnNames = this.getColumnNameOptions();
        const options = columnNames.slice(4).map(x => <option key={x.value} value={x.value}>
            {x.text}
        </option>);

        const ddlColumnNames = <select value={this.state.selectedColumnName}
            onChange={x => { this.displaySelectedColumn(x) }}>{options}</select>

        const header = ({ columns }: HeaderProps) => <thead className="WGDBP-grid-header"><tr key={Math.random()}>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="payer"
                        text="Payer"
                        cellType="text"
                        sortable={true}
                        // choose from String, Number or Date
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="paymentNo"
                        text="Payment No"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="totalCharges"
                        text="Total charges"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Number}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="paymentAmt"
                        text="Payment Amt"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Number}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName={this.state.selectedColumnName}
                        text="Provider No"
                        cellType="text"
                        sortable={true}
                        sortHandler={this.handleSorting}
                        sortDataType={selectedSortDataType}
                        sortIndicator={sortIndicator}
                        select={ddlColumnNames}
                    />
                </React.Fragment>
            }
        </tr></thead>

        return header;
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {

        const {
            filteredRemits,
            selectedRemitIndicators,
            gridPaging
        } = this.state;

        // get the index of the selected row from the selected remit indicators
        let selectedStartIndex = selectedRemitIndicators
            .map(function (x) { return x.remitID; })
            .indexOf(event.currentTarget.id);

        let newSelectedRemitIndicators: SelectedRemitIndicator[] = selectedRemitIndicators;

        // if the clicked row was previously selected
        if (selectedStartIndex > -1) {
            // then remove it
            newSelectedRemitIndicators.splice(selectedStartIndex, 1);
        } else {
            // get a handle to the selected remit
            //console.log(this.state.filteredRemits)
            const remit = filteredRemits.find(x => x.remitID == event.currentTarget.id);

            let paymentAmt = 0;
            if (remit) paymentAmt = parseFloat(remit.paymentAmt);

            let totalCharges = 0;
            if (remit) totalCharges = parseFloat(remit.totalCharges);

            // add a selected remit indicator for the selected row
            newSelectedRemitIndicators.push({
                pageNumber: gridPaging.currentPageNumber,
                remitID: event.currentTarget.id,
                paymentAmt,
                totalCharges,
                paymentId: remit?.paymentId ?? '',
            });
        }

        // update state with the latest set of selected remit indicators
        this.setState({
            selectedRemitIndicators: newSelectedRemitIndicators
        });
    }

    onMouseOver(remitId: string) {
        const {
            mouseOverRemitId
        } = this.state;

        if (mouseOverRemitId == remitId) return;

        this.setState({
            mouseOverRemitId: remitId,
            scrollToTop: false,
        });
    }

    getCustomRow() {
        const {
            selectedColumnName,
            selectedRemitIndicators,
        } = this.state;

        const row = ({ record }: RowProps) =>
            <tr id={record.remitID} key={record.remitID} role="button"
                className={selectedRemitIndicators.find(x => x.remitID === record.remitID) ? 'selected' : ''}
                onMouseOver={() => this.onMouseOver(record.remitID)}
                onClick={(x) => this.onRowClick(x)}>
                {
                    <React.Fragment>
                        <ARMRowCell value={record.payer} style={{ width: "75%", height: 15, overflow: 'hidden', wordBreak: 'break-all' }} />
                        <ARMRowCell value={record.paymentNo} />
                        <ARMRowCell value={record.totalCharges} />
                        <ARMRowCell value={record.paymentAmt} />
                        {selectedColumnName === 'fileName' && <ARMRowCell value={record.fileName} style={{ overflow: 'hidden' }} />}
                        {selectedColumnName === 'importDate' && <ARMRowCell value={record.importDate} />}
                        {/*{selectedColumnName === 'hicNo' && <ARMRowCell value={record.hicNo} />}*/}
                        {/*{selectedColumnName === 'ioPatient' && <ARMRowCell value={record.ioPatient} />}*/}
                        {selectedColumnName === 'lobCode' && <ARMRowCell value={record.lobCode} />}
                        {selectedColumnName === 'providerNo' && <ARMRowCell value={record.providerNo} />}
                        {/*{selectedColumnName === 'remitStatus' && <ARMRowCell value={record.remitStatus} />}*/}
                        {/*{selectedColumnName === 'secBillDate' && <ARMRowCell value={record.secBillDate} />}*/}
                        {/*{selectedColumnName === 'stmtFromDate' && <ARMRowCell value={record.stmtFromDate} />}*/}
                        {/*{selectedColumnName === 'stmtThruDate' && <ARMRowCell value={record.stmtThruDate} />}*/}
                        {/*{selectedColumnName === 'typeOfBill' && <ARMRowCell value={record.typeOfBill} />}*/}
                        {selectedColumnName === 'paymentDate' && <ARMRowCell value={record.paymentDate} />}
                    </React.Fragment>
                }
            </tr>
        return row;
    }

    getDetailPanel() {
        const {
            filteredRemits,
            mouseOverRemitId
        } = this.state;

        const mouseOverRemit = filteredRemits.filter(x => x.remitID === mouseOverRemitId)[0];
        return (
            <DialogFieldset>
                <DialogLegend>Payment Details</DialogLegend>
                <DetailWrapper>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Payer
                                </th>
                                <th>
                                    LOB Code
                                </th>
                                {/*<th>*/}
                                {/*    Control No*/}
                                {/*</th>*/}
                                {/*<th>*/}
                                {/*    HIC No*/}
                                {/*</th>*/}
                                <th>
                                    Provider No
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {mouseOverRemit && mouseOverRemit.payer}
                                </td>
                                <td>
                                    {mouseOverRemit && mouseOverRemit.lobCode}
                                </td>
                                {/*<td>*/}
                                {/*    {mouseOverRemit && mouseOverRemit.controlNo}*/}
                                {/*</td>*/}
                                {/*<td>*/}
                                {/*    {mouseOverRemit && mouseOverRemit.hicNo}*/}
                                {/*</td>*/}
                                <td>
                                    {mouseOverRemit && mouseOverRemit.providerNo}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {/*<table>*/}
                    {/*    <thead>*/}
                    {/*        <tr>*/}
                    {/*            <th>*/}
                    {/*                LOB Code*/}
                    {/*            </th>*/}
                    {/*            <th>*/}
                    {/*                LOB Payer*/}
                    {/*            </th>*/}
                    {/*            <th>*/}
                    {/*                Remit Status*/}
                    {/*            </th>*/}
                    {/*            <th>*/}
                    {/*                I/O Patient*/}
                    {/*            </th>*/}
                    {/*            <th>*/}
                    {/*                Type Of Bill*/}
                    {/*            </th>*/}
                    {/*            <th>*/}
                    {/*                Total Charges*/}
                    {/*            </th>*/}
                    {/*        </tr>*/}
                    {/*    </thead>*/}
                    {/*    <tbody>*/}
                    {/*        <tr>*/}
                    {/*            <td>*/}
                    {/*                {mouseOverRemit && mouseOverRemit.lobCode}*/}
                    {/*            </td>*/}
                    {/*            <td>*/}
                    {/*                {mouseOverRemit && mouseOverRemit.lobPayer}*/}
                    {/*            </td>*/}
                    {/*            <td>*/}
                    {/*                {mouseOverRemit && mouseOverRemit.remitStatus}*/}
                    {/*            </td>*/}
                    {/*            <td>*/}
                    {/*                {mouseOverRemit && mouseOverRemit.ioPatient}*/}
                    {/*            </td>*/}
                    {/*            <td>*/}
                    {/*                {mouseOverRemit && mouseOverRemit.typeOfBill}*/}
                    {/*            </td>*/}
                    {/*            <td>*/}
                    {/*                {mouseOverRemit && mouseOverRemit.totalCharges}*/}
                    {/*            </td>*/}
                    {/*        </tr>*/}
                    {/*    </tbody>*/}
                    {/*</table>*/}
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    Payment No
                                </th>
                                <th>
                                    Payment Date
                                </th>
                                <th>
                                    Import Date
                                </th>
                                <th>
                                    Total Charges
                                </th>
                                <th>
                                    Payment Amt
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {mouseOverRemit && mouseOverRemit.paymentNo}
                                </td>
                                <td>
                                    {mouseOverRemit && mouseOverRemit.paymentDate}
                                </td>
                                <td>
                                    {mouseOverRemit && mouseOverRemit.importDate}
                                </td>
                                <td>
                                    {mouseOverRemit && mouseOverRemit.totalCharges}
                                </td>
                                <td>
                                    {mouseOverRemit && mouseOverRemit.paymentAmt}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table>
                        <thead>
                            <tr>
                                <th>
                                    FileName
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {mouseOverRemit && mouseOverRemit.fileName}&nbsp;
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </DetailWrapper>
            </DialogFieldset>
        );
    }

    selectThese() {
        const {
            filteredRemits,
            selectedRemitIndicators,
            gridPaging
        } = this.state;

        // first remove any of the current page remits from the list
        const newSelectedRemitIndicators: SelectedRemitIndicator[] =
            selectedRemitIndicators.filter(x => x.pageNumber !== gridPaging.currentPageNumber);

        // for each remit in the current grid page
        filteredRemits.forEach(x => {

            // add a selected remit indicator
            newSelectedRemitIndicators.push({
                pageNumber: gridPaging.currentPageNumber,
                remitID: String(x.remitID),
                paymentAmt: parseFloat(x.paymentAmt),
                totalCharges: parseFloat(x.totalCharges),
                paymentId: x.paymentId ?? '',
            });
        });

        // replace the selected remit indicators in state
        this.setState({
            selectedRemitIndicators: newSelectedRemitIndicators
        });
    }

    selectNone() {
        const {
            selectedRemitIndicators,
            gridPaging
        } = this.state;

        // select none should only deselect the rows for the current page
        const newSelectedRemitIndicators: SelectedRemitIndicator[] =
            selectedRemitIndicators.filter(x => x.pageNumber != gridPaging.currentPageNumber);

        this.setState({
            selectedRemitIndicators: newSelectedRemitIndicators
        });
    }

    public getSummaryPanel() {
        const {
            gridPaging,
            filteredRemits,
            selectedRemitIndicators
        } = this.state;

        let pageRowStartNumber = 0;
        if (gridPaging.currentPageNumber > 1) {
            pageRowStartNumber = ((gridPaging.currentPageNumber - 1) * gridPaging.recordsPerPage) + 1;
        } else if (filteredRemits.length) {
            pageRowStartNumber = 1;
        }

        const pageRowEndNumber = Math.min(gridPaging.currentPageNumber * gridPaging.recordsPerPage,
            gridPaging.totalRecordCount);

        // calculate 'viewing total worth'
        const viewingTotalWorth = filteredRemits.reduce(function (accumulator, remit) {
            return accumulator + Number.parseFloat(remit.paymentAmt);
        }, 0)

        // calculate 'selected total worth'
        const selectedTotalWorth = selectedRemitIndicators.reduce(function (accumulator, remitIndicator) {
            return accumulator + remitIndicator.totalCharges;
        }, 0)

        return (
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Viewing</td>
                            <td>
                                <span style={{ backgroundColor: 'white' }}>
                                    {pageRowStartNumber} - {pageRowEndNumber}
                                </span>
                            </td>
                            <td>worth</td>
                            <td>
                                <span style={{ backgroundColor: 'white' }}>
                                    ${viewingTotalWorth.toFixed(2)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Selected</td>
                            <td>
                                <span style={{ backgroundColor: 'white' }}>
                                    {selectedRemitIndicators.length} / {gridPaging.totalRecordCount}
                                </span>
                            </td>
                            <td>worth</td>
                            <td style={{ textAlign: 'left' }}>
                                <div style={{ backgroundColor: 'white', width: '100%' }}>
                                    ${selectedTotalWorth.toFixed(2)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }


    getActionButtons() {

        const {
            selectedRemitIndicators,
            process
        } = this.state;

        return (
            <div>
                <Button
                    domID="btnGoBack"
                    name="Go Back"
                    buttonType="deEmphasizedReversed"
                    size="small"
                    type="button"
                    onClick={() => { window.sessionStorage.setItem("tabType", "Advanced"); window.history.go(-1) }}
                />
                <Button
                    domID="btnContinue"
                    name="Continue"
                    buttonType="deEmphasizedReversed"
                    size="small"
                    type="button"
                    disabled={selectedRemitIndicators.length < 1}
                    // NOTE: further research needed, trying to avoid user spamming the button and creating multiple jobs
                    onClick={(e: any) => { if (!e.currentTarget.disabled) { e.currentTarget.disabled = true; this.onContinue() } }}
                />
                <Button
                    domID="btnCancel"
                    name="Cancel"
                    buttonType="deEmphasizedReversed"
                    size="small"
                    type="button"
                    onClick={() => { this.props.history.push("/LandingPage"); }}
                />
            </div>
        );
    }

    getFilterJsonObject() {
        const {
            selectedRemitIndicators,
            remitIdList,
        } = this.state;

        let filterJsonObject: FilterJsonObject =
        {
            "RemitIDList": {
                "Remit": [
                ]
            }
        }

        selectedRemitIndicators.forEach((x, i) => {
            remitIdList.forEach((z, nI) => {
                if (x.paymentId === z.paymentId && x.paymentId !== '') {
                    const remit = {
                        "@ID": String(z.remitID),
                        "@SequenceNo": Number(z.sequenceNo ?? i)
                    }
                    filterJsonObject["RemitIDList"]["Remit"].push(remit);
                }

            });
        });

        return JSON.stringify(filterJsonObject);
    }

    public getConfigValue(flag: string): string {
        let configObj = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === flag);
        return (configObj && configObj.value ? configObj.value : "");
    }

    onContinue() {

        if (!this.state.process.toLowerCase().includes("postingfile")) {
            this.onAlert("An error occurred.  Invalid Process received.");
            return;
        }

        const {
            selectedRemitIndicators
        } = this.state;

        var workingGroup: any = this.getFilterJsonObject();
        var jsonRemitIdList = JSON.parse(workingGroup);
        var remitCount: number = (jsonRemitIdList && jsonRemitIdList?.RemitIDList && jsonRemitIdList?.RemitIDList.length ? jsonRemitIdList?.RemitIDList.length : 0);

        console.log('workinggroupdisplaybypayment has remit count ' + remitCount);


        var nMaxCount = Number(this.getConfigValue("RemitPostingFile_MaxCLPs"));
        if (nMaxCount == NaN) nMaxCount = 0;
        if (nMaxCount != 0 && remitCount > nMaxCount) {
            this.onAlert("Claim Payment Count cannot exceed " + nMaxCount + " claims.");
            return;
        }



        this.setWorkingGroup();

         
    }



    setWorkingGroup() {
        /*
    var objResponse = XMLCallBack('<Filter Action="SetWorkingGroup" Type="Remit" Process="' + process + '" Convert="True">' + workingGroup + '</Filter>', false);
         if (objResponse.responseText == "" || objResponse.responseText.indexOf('RUA=') != -1) {
            ...create job
         }
         */

        const {
            testMode
        } = this.props.location.state;

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        let clientId = sessionStorage.getItem("Client")?.split(' ')[0];
        xmlData.ClientID = clientId || undefined;
        xmlData.Process = this.state.process.toLowerCase();
        xmlData.Type = "Remit";
        xmlData.ViewOrigin = '/workinggroupdisplay.asp';  // see note in type def
        xmlData.TestMode = testMode;
        xmlData.Convert = 'True';
        
        xmlData.FilterJsonData = this.getFilterJsonObject();

        fetch(URLs.api + '/api/data/SetWorkingGroup',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`,
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();

                if (response.status === 306) {
                    // this.onAlert(data.message);  // error is in message
                    return;
                } else if (response.status === 200) {
                    // this.displayRemitViewer(data);
                    console.log('response from SetWorkingGroup');
                    console.dir(data);

                    this.executeMultiRemitProcess();
                } else {
                    throw new Error(`RemitFilter: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`);
                }
            })
            .catch(error => {
                console.error('RemitFilter: ' + error);
                //this.errorCallback("Unable to process filter.");
            })
            .finally(() => {
                //this.setBusy(false);
            });
    }

    public async executeMultiRemitProcess() {

        //function ValidatePostingFileRequest(process) {
        //       var maxCount = <%= obSecurity.Config(-1, "RemitPostingFile_MaxCLPs") %>;
        //       if (Selection.Count > maxCount) {
        //           alert("Claim Payment Count cannot exceed " + maxCount + " claims.");
        //           SetApplicationState(true);
        //           return;
        //       }

        //       var objResponse = XMLCallBack('<Filter Action="SetWorkingGroup" Type="Remit" Process="' + process + '" Convert="True">' + GetWorkingGroup() + '</Filter>', false);
        //       if (objResponse.responseText == "" || objResponse.responseText.indexOf("RUA=") != -1) {
        //           XMLCallBack('<MultiClaim Action="RemitPostingFile" Type="Remit" Process="' + process + '" Convert="True">' + GetWorkingGroup() + '</MultiClaim>', true);
        //       } else {
        //           SetApplicationState(true);
        //           TrapUnknownError('Could not get remit list.', 'XmlCallBack', 'N/A');
        //       }
        //   }

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.state.execAction || (this.state.execAction && this.state.execAction.length == 0))
            return;

        var jsonData: any = {};
        jsonData.MultiClaim = {};
        jsonData.MultiClaim['@Action'] = "RemitPostingFile";
        jsonData.MultiClaim['@Type'] = "Remit";
        jsonData.MultiClaim['@Process'] = this.state.process.toLowerCase();
        jsonData.MultiClaim['@Convert'] = "True";


        var remitIdList = this.getFilterJsonObject();
        var jsonRemitIdList = JSON.parse(remitIdList);
        jsonData.MultiClaim.RemitIDList = jsonRemitIdList.RemitIDList;

        // from claimfilter jsonData.MultiClaim = Object.assign(jsonData.MultiClaim, JSON.parse(this.getFilterData()));


        xmlData.Action = "RemitPostingFile";
        xmlData.Type = "Remit";
        xmlData.Convert = "True"; 
        xmlData.Process = this.state.process.toLowerCase();
        xmlData.ViewOrigin = '/remits/workinggroupdisplay.asp';  // see note in type def


        xmlData.FilterJsonData = JSON.stringify(jsonData);

        // comment this out...debug info only
        console.log(xmlData)
        console.log(jsonData)
        console.log(`this.state.execNode -> ${this.state.execNode}`)

        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    console.log('RemitWorkingGroupDisplay:status 200');
                    console.dir(xmlData);
                    this.goToHomePage();
                    return;
                } else {
                    console.log('RemitWorkingGroupDisplay:status' + response.status + ' ' + response.statusText);
                    throw new Error('RemitWorkingGroupDisplay: error in PostMultiClaim: ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('RemitWorkingGroupDisplay: ' + error);
                this.errorCallback("RemitWorkingGroupDisplay: error in PostMultiClaim.");
            })
            .finally(() => {
                console.log('RemitWorkingGroupDisplay:finally');
                //this.setBusy(false);
            });
    }



    public onAlert(msg: string) {
        this.setState({ filterAlert: msg });

    }
    public goToHomePage() {
        this.props.history.push("/LandingPage");
    }

    public render() {
        const {
            filteredRemits,
            gridPaging,
            isBusy,
            scrollToTop,
            remitTitle,
            filterName
        } = this.state;

        let combinedTitle = remitTitle;
        if (combinedTitle === "View Remits" && filterName != '') {
            combinedTitle += ' "' + filterName + '"';
        }
        const columns = this.getGridColumnHeaders();
        const header = this.getCustomHeader();
        const row = this.getCustomRow();
        const detailPanel = this.getDetailPanel();
        var instruction = <React.Fragment>You may sort the list and choose some or all entries. Once you have selected the entries you wish to include in your posting file(s), click Continue.
            </React.Fragment>;
        const buttons = this.getActionButtons();
        const summaryPanel = this.getSummaryPanel();
        const titleToRender = "Refine Remit List >> " + combinedTitle;

        return (
            <DialogWrapper
                title={titleToRender}
                instruction={instruction}
                instructionWidth={'60%'}
                helpUrl='/Support/Help/HELP_RMT_REFINEPMTGROUP.htm'
                buttons={buttons}
                isBusy={isBusy}
            >
                <ContentWrapper id={"content_wrapper_RemitWorkingGroupDisplay"}>
                    <RemitWorkingGroupDisplayContent>
                        <GridWrapper>
                            <ARMGrid
                                records={filteredRemits}
                                columns={columns}
                                domID='remit-list-grid'
                                isFixedHeader={true}
                                maxHeight='400px'
                                headerComponent={header}
                                rowComponent={row}
                                emptyGridMessage=' '
                                // grid works without selectionKey
                                // but uicl grid would throw warning
                                selectionKey='lobPayer'
                                scrollToTop={scrollToTop}
                            />
                        </GridWrapper>
                        <div style={{ height: 5 }}></div>
                        <div style={{ display: 'flex', flex: '0 0 100 %' }}>
                            <div style={{ flexGrow: 1 }}>
                                <Button
                                    domID="selectTheseButton"
                                    name="SELECT THESE"
                                    buttonType="standard"
                                    size="small"
                                    type="button"
                                    onClick={() => { this.selectThese() }}
                                />
                                <div style={{ width: 5, display: 'inline-block' }}></div>
                                <Button
                                    domID="selectNoneButton"
                                    name="SELECT NONE"
                                    buttonType="standard"
                                    size="small"
                                    type="button"
                                    onClick={() => { this.selectNone() }}
                                />
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                {summaryPanel}
                            </div>
                            <div style={{ marginTop: 5, flexGrow: 1, textAlign: 'right', position: 'relative', left: 20, top: -7 }}>
                                <ARMGridPager
                                    currentPageNumber={gridPaging.currentPageNumber}
                                    numberOfPages={gridPaging.numberOfPages}
                                    pagingHandler={this.handlePaging}
                                />
                            </div>
                        </div>
                        <div style={{ height: 15 }}></div>
                        <div>
                            {detailPanel}
                        </div>
                    </RemitWorkingGroupDisplayContent>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={!!this.state.filterAlert && this.state.filterAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onAlert('')}
                    message={this.state.filterAlert}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onAlert('')}
                />
            </DialogWrapper>
        );
    }

};

var connectedHoc = connect<IRemitWorkingGroupDisplayState, IRemitWorkingGroupDisplayActionProps, IOwnProps, IRemitWorkingGroupDisplayProps, ApplicationState>(
    createApiMapStateToProps('remitWorkingGroupDisplay'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(WorkingGroupDisplayByPayment);

export default withRouter(connectedHoc);
