import { Reducer, Dispatch, AnyAction } from 'redux';
import { createDataAction, ActionTypes, createAction, createApiFileUploadAction} from '@scripts/util/ActionHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { actionCreators as masterCrudCreators } from '../MasterCrud';
import { ComplexApiAction } from '@scripts/util/ThunkHelpers';
import { URLs } from '@commonDevResources/constants';
import { History } from 'history';
import { getRawToken } from '@scripts/session/SecurityToken';


// -----------------
// STATE - This defines the type of data maintained in the Redux store.


export interface SubmitRemitsUiState {
    remitLobs: ISelectOption[],
    originalLobs: ISelectOption[],
    uploadFile: FormData,
    fileName: string,
    loading: boolean,
    selectedLob?: string,
    redirectHome: boolean,
}

export interface SubmitRemitFileRequest {
    FileName: string,
    RemitLobId: string,
    Files: any,
}

const DEFAULT_REMIT_LOBS = [{ label: '- Select A REMIT LOB -', value: '' }];

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

//DEFAULTS
export const defaultState: SubmitRemitsUiState = {
    remitLobs: DEFAULT_REMIT_LOBS,
    originalLobs: [],
    uploadFile: new FormData(),
    fileName: '',
    loading: true,
    redirectHome: false,
};

//ACTION DEFINITIONS

export const actionCreators = {
    loadRemitLobs: () =>
        ComplexApiAction.fromAction(
            masterCrudCreators.search({ crudId: CrudTypes.mctiRemitOptionsSetup, data: JSON.stringify({ Param: [] }) }))
            .changeType('SRF_LOAD_REMIT_LOBS')
            .withNoThunk()
            .finish(),
    uploadFile: (uploadedFile: any) => createDataAction('SRF_UPLOAD_FILE', uploadedFile),
    deleteCurrentFile: () => createAction('SRF_DELETE_FILE'),
    filterLobs: (searchText: string) => createDataAction('SRF_FILTER_LOBS', searchText),
    selectLob: (id: string) => createDataAction('SRF_SELECT_LOB', id),
    submitFile: (request: SubmitRemitFileRequest) => createApiFileUploadAction(
        'SRF_SUBMIT_FILE',
        `${URLs.api}/api/data/remits/submitFile/${request.RemitLobId}?fileName=${JSON.stringify(request.FileName)}`,
        request.Files
    ),
}

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

function ForceArray<T>(item: T[] | T): T[] {
    if (Array.isArray(item))
        return item as T[];
    else
        return [{ ...item }];
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<SubmitRemitsUiState, KnownActions> =
    (state: SubmitRemitsUiState | undefined, action: KnownActions) => {

        if (state != undefined) {

            // Have to expand the list of remitLobs to get a deeper copy of the list.
            const newState: SubmitRemitsUiState = { ...state, uploadFile: state.uploadFile };

            switch (action.type) {
                case 'SRF_LOAD_REMIT_LOBS':
                    switch (action.status.status) {
                        case 'REQUEST':
                            newState.loading = true;
                            return newState;

                        case 'SUCCESS':
                            const lobList = ForceArray(action.responseData.RemitLobInfo?.RemitLobList?.RemitLob ?? []);
                            newState.remitLobs = DEFAULT_REMIT_LOBS;
                            if (lobList.length > 0) {
                                // Get the original list of LOBs we display in the Remit LOBs box. We need to preserve
                                // the original in case 
                                newState.originalLobs = lobList.map(lob => {
                                    return { value: lob['@ID'], label: lob['@Name'] };
                                });

                                // If we have any Remit LOBs, we need to add them to our list.
                                newState.remitLobs = newState.remitLobs.concat(newState.originalLobs);
                            }
                            newState.loading = false;
                            return newState;

                        case 'FAIL':
                            const messageSuffix: string = action?.status?.responseCode
                                ? `status code ${action?.status?.responseCode}.`
                                : "no response code!";
                            console.log(`The response to the remit lobs search was a FAIL with ${messageSuffix}`);
                            newState.loading = false;
                            return newState;
                    }
                case 'SRF_UPLOAD_FILE':
                    const uploadedFile = action.data;

                    // If we have an actual file uploaded, we need to change the upload data to point to it.
                    // If we did not get a file, we need to just return the previous state.
                    if ((uploadedFile?.target?.files?.length ?? 0) > 0) {
                        newState.uploadFile = new FormData();
                        newState.uploadFile.append('file', uploadedFile.target.files[0]);
                        newState.fileName = uploadedFile.target.files[0].name;
                    }
                    return newState;


                case 'SRF_DELETE_FILE':
                    newState.fileName = '';
                    newState.uploadFile = new FormData();
                    return newState;

                case 'SRF_FILTER_LOBS':
                    // Based on original code, we only show the original options if we have exactly an empty string.
                    if ((action?.data ?? '') !== '') {
                        // Kick the search text down to lower case so we only have to do it once. Then filter options based a contains
                        // in the text. Using legacy page logic here for an exact functionality match.
                        const lowerCaseSearchText: string = action.data.toLowerCase();
                        newState.remitLobs = newState.originalLobs.filter(lob => {
                            const lowerName: string = lob.label.toLowerCase();
                            return lowerName === lowerCaseSearchText || (lowerName.indexOf(lowerCaseSearchText) > -1);
                        })
                    }
                    else {
                        // If the search field is empty, revert to the original lists.
                        newState.remitLobs = DEFAULT_REMIT_LOBS.concat(newState.originalLobs);
                    }

                    return newState;

                case 'SRF_SELECT_LOB':
                    newState.selectedLob = action?.data ?? '';
                    return newState;

                case 'SRF_SUBMIT_FILE':
                    switch (action.status.status) {
                        case 'REQUEST':
                            newState.loading = true;
                            return newState;

                        case 'SUCCESS':
                            newState.loading = false;
                            newState.redirectHome = true;
                            return newState;

                        case 'FAIL':
                            newState.loading = false;
                            return newState;
                    }
                default:
                    // The following line guarantees that every action in the KnownAction union has been covered by a case above
                    return state;
            }
        }

        return defaultState;
    }