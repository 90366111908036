import * as React from 'react';


import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';

const Container = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: ${Colors.chRed100};
    color: ${Colors.white};
    cursor: pointer;
    text-align: center;
    z-index: 9999;

    .chevron {
        position:relative;
        display:block;
        height:20px;
        margin-top: 10px;
    }

    .chevron:hover {
        animation: slide 1s alternate ease infinite;
    }

    @keyframes slide {
        0% {
            transform: translateY(0px);
        }
        70% {
            transform: translateY(-3px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    .chevron::before,
    .chevron::after {
        position:absolute;
        display:block;
        content:"";
        border: 10px solid transparent;
        left:10px;
    }

    .chevron::before {
        bottom: 7px;
        border-bottom-color:${Colors.white}
    }

    .chevron::after {
        bottom: 2px;
        border-bottom-color:${Colors.chRed100};
    }
`;

interface IComponentProps{ };
interface IComponentState {
    target: any;
    visible: boolean;
};

class ScrollToTop extends React.Component<IComponentProps, IComponentState> {

    constructor(props: IComponentProps) {
        super(props);
        this.state = { target: undefined, visible: false };
    }

    public componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, true);
    }

    handleScroll = (event: Event) => {
        // Note: the passed in target is div#main_content
        //console.log(event);

        if (event.target) {
            // console.log("Target is", event.target);
            //@ts-ignore
            const { scrollTop } = event.target;
            // console.log("Scroll pos is:", scrollTop);
            if (scrollTop > 0) {
                this.setState({ visible: true });
            } else {
                this.setState({ visible: false });
            }
            this.setState({ target: event.target })
        }
    }

    scrollToTop = (e: React.MouseEvent<HTMLDivElement>) => {
       
        //console.log("Scrolling to top...");
        if (this.state.target) {
            //this.state.target.scrollTop = 0;
            this.state.target.scrollTo({ top: 0, behavior: 'smooth' });
        }
        
    }

    public render() {
        const { visible } = this.state;

        return (
            <>
                {visible && <Container onClick={this.scrollToTop}>
                    <i className="chevron"></i>
                </Container>}
            </>
        )
    }

}

export default ScrollToTop;