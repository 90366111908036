import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, createApiBodyAction, createAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { URLs } from '@commonDevResources/constants';
import { get } from 'lodash';
import { ApplicationState } from '..';
import { push } from 'connected-react-router';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IDateRanges
{
    '0': 'today';
    '1': 'yesterday';
    '2': 'weektodate';
    '3': 'monthtodate';
    '4': "last30days";
    '5': 'last60days';
    '6': 'last90days';
    '7': 'customDateRange'
}

export const dateRanges: IDateRanges = {
    '0': 'today',
    '1': 'yesterday',
    '2': 'weektodate',
    '3': 'monthtodate',
    '4': "last30days",
    '5': 'last60days',
    '6': 'last90days',
    '7': 'customDateRange'
}

export type OperationTab = "0" | "1" | "Claim" | "Eligibility";  // See Assemblies\RelayHealth.API.Data\Entities\Dashboard\OperationsTab.cs
export type OperationView = "0" | "1" | "charts" | "lists";  // See Assemblies\RelayHealth.API.Data\Entities\Dashboard\OperationsView.cs
export type OperationMeasure = "0" | "1" | "2" | "All" | "Amount" | "Count";  // See Assemblies\RelayHealth.API.Data\Entities\Dashboard\OperationsMeasure.cs
export type OperationDateRange = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "today" | "yesterday" | "weektodate" | "monthtodate" | "last30days" | "last60days" | "last90days" | "customDateRange"; // See Assemblies\RelayHealth.API.Data\Entities\Dashboard\OperationsDateRange.cs
export type OperationDrillPage = "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "None" | "ClaimCreationState" | "UnreleasedClaimAge" | "CreateRelRatio" | "HeldClaims" | "ErrorCategory" | "EligErrorCategory" | "InitEligResponses";  // See Assemblies\RelayHealth.API.Data\Entities\Dashboard\OperationsDrillPage.cs

export interface OperationFilter {
    tab: OperationTab;
    view: OperationView;
    measure: OperationMeasure;
    dateRange?: undefined | OperationDateRange;
    drillPage: OperationDrillPage;
    fromDate?: string;
    thruDate?: string;
    stampDateTime: string;
    useTestData: string;
}

export interface IDrillDownViewDefaultState {
    drillDownDataArray: any[];
    storeObj: any;
    collapsedData: string[];
    saveCollapsed: boolean;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    resetState: () => createAction('DRILL_DOWN_VIEW_RESET_STATE'),
    drillDownTo: (filter: OperationFilter, storeObj: any = undefined) => createApiBodyAction('DRILL_DOWN_TO', `${URLs.api}/api/data/dashboard/operations`, { filter, storeObj }, "POST", JSON.stringify(filter), undefined, undefined, undefined, undefined, (api: any, action: any, response: any, data: any): boolean => {
        if (response.ok) {
            api.dispatch(push(`/Dashboard/DrillDownView`));
        }
        return true
    }),
    expandListColumn: (title: string, expanded: boolean) => createDataAction('EXPAND_LIST_COLUMN', { title, expanded }),
    saveCollapsed: (saveBool: boolean) => createDataAction('SAVE_COLLAPSED', {saveBool}),
}

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultFilter: OperationFilter = {
    tab: "Claim",
    view: "charts",
    measure: "All",
    dateRange: "last90days",
    drillPage: "0",
    fromDate: "",
    thruDate: "",
    stampDateTime: "",
    useTestData: ""
}

export const defaultState: IDrillDownViewDefaultState = {
    drillDownDataArray: [],
    storeObj: undefined,
    collapsedData: [],
    saveCollapsed: false,
};


const forceToArray = (input: any) => {
    if (Array.isArray(input)) {
        return input
    } else if (typeof input === 'object') {
        return [input]
    } else {
        return []
    }
}

// ----------------
// REDUCER - For a given state and actio returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IDrillDownViewDefaultState, KnownActions> = (state: IDrillDownViewDefaultState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'DRILL_DOWN_TO':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return state
                    case "SUCCESS":
                        const { items } = action.responseData;
                        let drillDownDataArray = state.drillDownDataArray;
                        if (items) { 
                            return {
                                ...state,
                                drillDownDataArray: forceToArray(items),
                                storeObj: action.data.storeObj ? action.data.storeObj : state.storeObj,
                                collapsedData: state.saveCollapsed ? state.collapsedData : []    
                            }
                        } else {
                            return {
                                ...state,
                                drillDownDataArray: [...drillDownDataArray, action.responseData],
                                storeObj: action.data.storeObj ? action.data.storeObj : state.storeObj,
                                collapsedData: state.saveCollapsed ? state.collapsedData : []
                            }
                        }
                        
                    case "FAIL":
                        console.log("Error: failed to fetch Claim data");
                        return state
                    default:
                        // The following line guarantees that every action in the KnownAction union has been covered by a case above
                        // const exhaustiveCheck: never = action;
                        return state;
                }
            case 'DRILL_DOWN_VIEW_RESET_STATE':
                return defaultState;

            case 'EXPAND_LIST_COLUMN':
                const { title, expanded } = action.data;
                if(expanded){
                    const updatedCollapsedData = state.collapsedData.concat([title])
                    return {
                        ...state,
                        collapsedData: updatedCollapsedData
                    }
                }else{
                    const updatedCollapsedData = state.collapsedData.filter((str) => {
                        return str !== title;
                    })
                    return {
                        ...state,
                        collapsedData: updatedCollapsedData
                    }
                }
            case 'SAVE_COLLAPSED':
                const { saveBool } = action.data;
                return {
                    ...state,
                    saveCollapsed: saveBool,
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
