import styled, { css } from 'styled-components';

export const LandingPageBody = styled.div`
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    
`;

export const ARMComponentItemContainer = styled.div.attrs(() => {
})`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
`;

export const ARMComponentItemTop = styled.div.attrs(() => {
})`
    display: flex;
    flex: 1 1 auto;
`;

interface IARMComponentItemContentProps {
    disabled?: boolean;
}

const disabled = css`
    pointer-events: none;
    cursor: default;
    opacity: 0.6;
`;

export const ARMComponentItemContent = styled.div.attrs(() => {
})`
    display: flex;
    flex: 0 1 auto;
    ${(props: IARMComponentItemContentProps) => props.disabled && `
        ${disabled}
    `};
`;

export const ARMComponentItemBottom = styled.div.attrs(() => {
})`
    display: flex;
    flex: 1 1 auto;
`;
