import styled, { css } from 'styled-components';
import { Colors } from '../../../commonResources/colorVariables';

const flexInnerContainer = css`
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-flow: row wrap;
`;

/* Override the default bootstrap behavior where horizontal description lists 
will truncate terms that are too long to fit in the left column 
*/
const bootstrapDl = css`
    .dl-horizontal dt {
        white-space: normal;
`;

/* Set width on the form input elements since they're 100% wide by default */
const formElements = css`
    input,
    select,
    textarea {
        max-width: 280px;
    }
`;

export const LandingPageContainer = styled.div.attrs(() => {
    return {id: "LandingPageContainer"};
})`
    ${flexInnerContainer}
    align-content: flex-start;
    width: 960px;
    font-family:"Core Sans C", "Century Gothic", "AppleGothic", sans-serif;

    input { 
        text-transform: uppercase;
    }

    ${bootstrapDl}
    ${formElements}

    @media only screen and (max-width: 767px) {
        .nofloat {
            float: none;
            padding: 10px 15px;
        }
    }
}
`;

export const LandingPageContainerLegacy = styled.div.attrs(() => {
    return { id: "LandingPageContainer" };
})`
    ${flexInnerContainer}
    xxxalign-content: flex-start;
    align-content: center;
    width: 1140px;
    height: 730px;
    padding: 5px;
    margin-top: 30px;
    font-family:"Core Sans C", "Century Gothic", "AppleGothic", sans-serif;

    input { 
        text-transform: uppercase;
    }

    ${bootstrapDl}
    ${formElements}

    @media only screen and (max-width: 767px) {
        .nofloat {
            float: none;
            padding: 10px 15px;
        }
    }
}
`;

export const TestModeLandingPageContainer = styled.div.attrs(() => {
    return { id: "LandingPageContainer" };
})`
    ${flexInnerContainer}
    xxxalign-content: flex-start;
    align-content: center;
    width: 1140px;
    height: 505px;
    padding: 5px;
    margin-top: 30px;
    font-family:"Core Sans C", "Century Gothic", "AppleGothic", sans-serif;

    input { 
        text-transform: uppercase;
    }

    ${bootstrapDl}
    ${formElements}

    @media only screen and (max-width: 767px) {
        .nofloat {
            float: none;
            padding: 10px 15px;
        }
    }
}
`;

export const LandingPageTop = styled.div.attrs(() => {
    return {id: "LandingPageTop"};
})`
    ${flexInnerContainer}
    margin-top: 30px;
    padding: 0px !important;
`;

export const LandingPageBody = styled.div.attrs(() => {
	return { id: "LandingPageBody" };
})`
    ${flexInnerContainer}
    border: 1px;
    border-color: #0f0f59;
    border-top-style: solid;
    border-left-style: solid;
    border-right-style: solid;
    align-items: flex-start;
    
`;

export const LandingPageComponentsLegacy = styled.div.attrs(() => {
    return { id: "LandingPageComponents" };
})`
    ${flexInnerContainer}
    width:1040px;
    
    align-items: center;

    #ARMQuickLinksPanel{    
        max-width: 390px !important;
        width: 390px !important;
    }

    #tabsNotifications{
        max-width: 620px !important;
        width: 620px !important;
    }
    .MessageAlerts,
    .ARMMyClaims {
        max-width: 625px;
        width: 625px;
    }
    .ARMMyClaims {
    }


`;

export const TestModeLandingPageComponentsLegacy = styled.div.attrs(() => {
    return { id: "TestModeLandingPageComponents" };
})`
    ${flexInnerContainer}
    width:1040px;
    
    align-items: stretch;

    #ARMQuickLinksPanel{    
        max-width: 429px !important;
        width: 429px !important;
    }
`;

export const LandingPageComponents = styled.div.attrs(() => {
    return { id: "LandingPageComponents" };
})`
    ${flexInnerContainer}
    width:768px;margin-top:3px;margin-right:3px;margin-bottom:3px;
    align-items: flex-start;

    .RAPMyClaimsBiller {
        border: 1px solid #c3c5cd;
    }
    .RAPMyClaimsSupervisor {
        border-style: solid;
        border-width: 1px 0px 1px 1px;
        border-color: ${Colors.grey30};
    }

`;

export const LandingPageBottom = styled.div.attrs(() => {
	return { id: "LandingPageBottom" };
})`
    ${flexInnerContainer}
    height: 51px;
    width: 100%;
    border: 1px solid #0f0f59;
    border-top: 1px solid #c3c5cd;
    align-items: flex-start;
    background-color: #efefef;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
`;
/*
return (<div id="LandingPage" className="flex-innercontainer">
    <div key="LandingPageTop" id="LandingPageTop" className="flex-innercontainer">{landingPageTop}</div>
    <div key="LandingPageBody" id="LandingPageBody" className="flex-innercontainer landingPageBody">{landingPageBody}
        <div id="LandingPageComponents" className="flex-innercontainer landingPage-sub" style={divStyle}>{landingPageComponents}</div>
    </div>
    <div key="LandingPageBodyBottomGrayArea" id="LandingPageBodyBottomGrayArea" className="flex-innercontainer landingPageBodyBottomGrayArea" style={divStyle}></div>
</div>);
*/