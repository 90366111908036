import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import _ from 'lodash';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IRemoveRemitsUIState {
    activeTab: number;
    reasonInputText: string;
    isHoveringOverBack: boolean;
    isHoveringOverFoward: boolean;
    resultsList: any[];
    pageIndex: number;
    resultSelectionsForRemoval: any[];
    LOBList: MCRemitLOB[];
    LOBSelectionIndex: number;
    fromImportDate: string;
    thruImportDate: string;
    fromPaymentDate: string;
    thruPaymentDate: string;
    fromPaymentNumber: string;
    thruPaymentNumber: string;
    fromProviderID: string;
    thruProviderID: string;
    fromTaxID: string;
    thruTaxID: string;
    dateUUID: string;

};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const defaultRemoveRemits: IRemoveRemitsUIState = {
    activeTab: 0,
    reasonInputText: "",
    isHoveringOverBack: false,
    isHoveringOverFoward: false,
    resultsList: [],
    pageIndex: 0,
    resultSelectionsForRemoval: [],
    LOBList: [],
    LOBSelectionIndex: 0,
    fromImportDate: "",
    thruImportDate: "",
    fromPaymentDate: "",
    thruPaymentDate: "",
    fromPaymentNumber: "",
    thruPaymentNumber: "",
    fromProviderID: "",
    thruProviderID: "",
    fromTaxID: "",
    thruTaxID: "",
    dateUUID: _.uniqueId(),
};

export interface IModifyLOBList extends ICrudActionData<MCManageRemitMaintenance, IRemoveRemitsUIState> {
    LOBList: MCRemitLOB[];
}
export interface IModifyResultsList extends ICrudActionData<MCManageRemitMaintenance, IRemoveRemitsUIState> { }

export const actionCreators = {
    updateLOBList: (updateInfo: IModifyLOBList) => createDataAction('UPDATE_LOB_LIST', updateInfo),
    updateResultsList: (updateInfo: IModifyResultsList) => createDataAction('UPDATE_RESULTS_LIST', updateInfo)
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IRemoveRemitsUIState = defaultRemoveRemits;

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IRemoveRemitsUIState, KnownActions> = (state: IRemoveRemitsUIState = defaultState, action: KnownActions) => {

    switch (action.type) {
        case 'UPDATE_LOB_LIST':
            {
                return {
                    ...state,
                    LOBList: [...state.LOBList, ...action.data.LOBList]
                }
            }
        case 'UPDATE_RESULTS_LIST':
            {
                return {
                    ...state
                }
            }
        default:
            return state;
    }
}