import * as React from 'react';
import styled from 'styled-components';
import { IImageButtonImage, ImageButton } from '../common/ImageButton';
import CompanyLogo from '../../content/images/LandingPage/CompanyLogoSmall.png';
import { URLs } from '@commonDevResources/constants';
import { IComponentProps } from '@components/LandingPage/LandingPageComponent';
import { switchClient, ISwitchClientModel } from '@scripts/session/Security';
import { RouteComponentProps, withRouter, Router } from 'react-router-dom';
import { goTo } from '@commonResources/window';
import { getRawToken } from '@scripts/session/SecurityToken';

const CopyRightImageWrapper = styled.div`
    text-align: right;
    width:100%;
    padding-top: 5px;
    
    image: CompanyLogo;
    display:flex;
    align-content: right;
    justify-content: flex-end;

    button{
        margin-right: 10px;
    }
`;

const CenteredContentFlex = styled.div`
    margin-bottom: 50px;
    margin-top: 20px;
    display: flex;
    align-content: center;
    justify-content: center;

`;



const BottomWrapper = styled.div`
    max-width: 1310px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

`;

const BottomLineWrapper = styled.div`
    max-width: 1000px;
    white-space: nowrap;
    width: 800px;
    height: 40px;

    float: left;
    text-align: left;
    font-size: 9pt;
    font-style: normal;
    color: #000000;
    cursor: default ;
    display: table;
    vertical-align: bottom;


`;

const ServerWrapper = styled.div`
    padding-right: 10px;
    font-size: 9pt;
    display:table-cell;
    font-style: normal;
    vertical-align: bottom;
    overflow:hidden;
`;

const ClientWrapper = styled.div`
    padding-right: 10px;
    font-size: 9pt;
    display:table-cell;
    font-style: normal;
    vertical-align: bottom;
`;

const UserWrapper = styled.div`
    padding-right: 10px;
    font-size: 9pt;
    display:table-cell;
    font-style: normal;
    vertical-align: bottom
`;

const CopyRightLingoWrapper = styled.div`
    width: 300px;
    float: right;
    text-align: right;
    font-size: 9pt;
    font-style: normal;
    color: #737d85;
    display:table-cell;
`;

export interface IFooterProps extends IComponentProps {};

interface IFooterState {
    clientName: string;
    serverName?: string;
    clientCount: string;
    errorCode?: number
    client?: any;
    clientString: string;
    allClients: Array<any>;
    userRoles: [
        {
            id: number | string,
            roleName: string | undefined | null
        }
    ],
    brandDefault: boolean;
    isCIAMUser: boolean;
};

type IProps = IFooterProps & RouteComponentProps<{}>; ;

class FooterComponent extends React.Component<IProps, IFooterState>
{
    protected dataForSwitchClients: any;
    protected dataForSwitchUsers: any;
    protected userPointer: string;
    protected clientPointer: number;
    protected clientByPass: number;
    protected selectedName: string;
    
    constructor(props: IProps) {
        super(props);
        this.state = {
            clientName: "",
            serverName: "",
            clientCount: '',
            errorCode: undefined,
            client: null,
            clientString: '',
            allClients: [],
            userRoles: [
                {
                    id: 0,
                    roleName: ''
                }
            ],
            brandDefault: false,
            isCIAMUser: false
        }
        
        this.dataForSwitchClients = '';
        this.dataForSwitchUsers = '';
        this.userPointer = "";
        this.clientPointer = -1;
        this.clientByPass = -1;
        this.selectedName = "";

        this.goToClient = this.goToClient.bind(this);
        this.persistRoleSelection = this.persistRoleSelection.bind(this);
        this.SwitchClientClickedView = this.SwitchClientClickedView.bind(this);
        this.RecipientComboBox_onClick = this.RecipientComboBox_onClick.bind(this);
        this.UserComboBox_onClick = this.UserComboBox_onClick.bind(this);
    }

    RecipientComboBox_onClick(event: React.ChangeEvent<HTMLSelectElement>) {
        this.clientPointer = event.currentTarget.selectedIndex;
        this.SetSelectedName(event.currentTarget.selectedIndex);
        this.setState({
            clientName: (this.state.allClients[event.currentTarget.selectedIndex])
        });
    }

    SetSelectedName(index: any) {
        var selectedIndex = this.state.allClients[index];
        this.selectedName = selectedIndex[0];
    }
    
    UserComboBox_onClick(event: React.ChangeEvent<HTMLSelectElement>) {
        this.userPointer = (event.currentTarget.options[event.currentTarget.options.selectedIndex].id)
    }

    componentDidMount() {
        this.getCookieInfoFromServer();
        this.loadClientsFromServer();
        this.loadUserRoles();
        this.getBranding();
    }
    
    getBranding() {
        let brandPath: string | null = "Default"; // show the logo
        if (sessionStorage.getItem("brandingPath") === null || sessionStorage.getItem("brandingPath") === "undefined") {
            let url = URLs.api + '/api/data/securityInfo/brandPath';
            fetch(url)
                .then((response) => response.json())
                .then((jsonData) => {
                    brandPath = jsonData.BrandPath;
                    sessionStorage.setItem("brandingPath", jsonData.BrandPath);
                })
                .catch(error => {
                    console.error("Unable to retrieve brand path. Defaulting..");
                });
        }
        else{
            let brand = sessionStorage.getItem("brandingPath");
            brandPath = brand ? brand : "Default";
        }
        if(brandPath.indexOf("Default") > -1)
            this.setState({brandDefault : true})
    }

    loadUserRoles() {
        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/GetSupportUserRoles", true);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.onload = () => {
            const data = JSON.parse(xhr.responseText);
            this.dataForSwitchUsers = JSON.parse(xhr.responseText);
            this.setState({
                userRoles: data
            }); 
        };
        xhr.send();
    }

    buildUserList(skip: boolean) {
        let usersRoles = this.dataForSwitchUsers
        let userList = '';
        let key = 0;
        if (usersRoles[0] !== undefined) {
            userList = usersRoles.map((listItem: any) => {
                var newRoleName = "";
                  key++;
                if (listItem.roleName == "No User Role Assigned") {
                    newRoleName = "View As Legacy";
                } else {
                    newRoleName = listItem.roleName;
                }
                this.state.userRoles.push(listItem.id, listItem.roleName)
                return <option key={key} id={listItem.id} value={listItem.roleName} >{newRoleName}</option>
            });
        }
        return userList;
    }

    ForcedRefresh() {
        //this.props.history.push({ pathname: '/empty' });
        //this.props.history.replace("/LandingPage");
        return;
    }


    persistRoleSelection(event: React.MouseEvent<HTMLInputElement>) {
        var xhr = new XMLHttpRequest();
        xhr.open('POST', URLs.api + "/api/data/updateuserrole/" + this.userPointer, false);
        xhr.setRequestHeader("Content-Type", "text/xml");
        xhr.send();
        this.setState({
            userRoles:[ {
                id: 0,
                roleName: ''
            }]
        },()=> this.ForcedRefresh()); 
        this.clientPointer = this.clientByPass;
        this.goToClient(event);// This needs to be fixed to refresh page on its own
    }

    loadClientsFromServer() {
        var clientUrl = URLs.api + '/api/data/securityinfo/securityInfoUserClientList';
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    this.dataForSwitchClients = JSON.parse(responseData);
                } else {
                    console.warn(xhr.status + " response handling " + clientUrl);
                    this.setState({ errorCode: xhr.status });
                }
            }
        };
        xhr.open('get', clientUrl, false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    getCookieInfoFromServer() {
        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/GetCookieInformation", true);
        xhr.onload = () => {
            var data = JSON.parse(xhr.responseText);
            this.setState({
                clientName: data.Client,
                clientCount: window.location.href.toLowerCase().indexOf("useremailconfirm") > -1 ? 1 : data.ClientCount,
                serverName: data.Server,
                clientString: data.Client,
                isCIAMUser: data.IsCiamLogon === "true",
            });
            sessionStorage.setItem("Client", data.Client);
        };
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();

    }

    switchClientModel = {
        ClientAlias: '',
        ClientName: '',
    };

    static copyRightIcon: IImageButtonImage = {
        Off: CompanyLogo,
        Over: CompanyLogo,
        Up: CompanyLogo,
        Down: CompanyLogo
    }

    public getCopyRightIcon() {
        return (
            <ImageButton
                Title='copyrightlogo'
                image={FooterComponent.copyRightIcon}
                className="copyright-icon"
                disabled={false}
            />
        );
    }

    buildClientList() {
            let clients = this.dataForSwitchClients.userClients;
            let clientList = '';
            let key = 0;
            let singleClient = [];
            if (this.dataForSwitchClients.userClients && clients.length > 0) {
                clientList = clients.map((listItem: any) => {
                    key++;
                    singleClient = [listItem.name, listItem.id];
                    this.state.allClients.push(singleClient)
                    if ((this.state.clientName) == listItem.name) {
                        if (this.clientByPass == -1) {
                            this.clientByPass = key-1;
                        }
                        this.SetSelectedName(this.clientByPass);
                        sessionStorage.setItem("ClientAlias", listItem.id);
                        //return <option key={listItem.id} id={listItem.id} value={listItem.name} selected> {listItem.name}</option>
                    }
                        return <option key={listItem.id} id={listItem.id} value={listItem.name} > {listItem.name}</option>
                });
            }   
            return clientList;
    }

    async switchClient(switchModel: ISwitchClientModel) {
        document.body.style.cursor = "wait";
        const loginResult = await switchClient(switchModel);
        switch (loginResult.status) {
  
            case 200:
                this.setState({ clientName: switchModel.ClientName });
                this.props.history.push({ pathname: '/empty' });
                this.props.history.replace("/LandingPage");
                if (loginResult && loginResult.body) {
                    var responseData = loginResult.body.replace(/\"/g, '');
                    sessionStorage.setItem('Client', responseData);
                    this.setState({ client: responseData });
                }
                break;
            case 306:
                // Password expired or reset
                this.props.history.push({
                    pathname: "/Home/ChangePassword",
                    state: { warningMessage: loginResult.body }
                });
                break;
        }
        document.body.style.cursor = "default";
        this.SwitchClientViewback();// This not necessary 
    }

    goToClient(event: React.MouseEvent<HTMLInputElement>) {
        let singleClient = [];
        singleClient = this.state.allClients[this.clientPointer]
        this.switchClientModel = {
            ClientAlias: singleClient[1],
            ClientName: singleClient[0]
        };
        this.switchClient(this.switchClientModel);
    }

    SwitchClientClickedView(event: MouseEvent) {
        const { isCIAMUser, clientCount } = this.state;
        if (isCIAMUser && Number(clientCount) > 1) {
            goTo("/SelectSiloCID.asp?SC", "", "", "");
        } else {
            if (Number(clientCount) > 1) {
                var clientLabel = document.getElementById("_ClientLabel");
                if (clientLabel != null) {
                    clientLabel.style.textDecoration = "none";
                }
                var buttonLabel = document.getElementById("_SwitchButton");
                if (buttonLabel != null) {
                    buttonLabel.style.visibility = "visible";
                }
                var selectLabel = document.getElementById("clientName");
                if (selectLabel != null) {
                    selectLabel.style.visibility = "hidden";
                    selectLabel.style.display = "none";
                }
                var comboBoxLabel = document.getElementById("_RecipientComboBox");
                if (comboBoxLabel != null) {
                    comboBoxLabel.style.visibility = "visible";
                    comboBoxLabel.style.width = "auto";
                }
            }
        }
    }

    ConditionalSwitch() {
        if (!this.state.userRoles || !this.state.userRoles.length) {
            var userLabel = document.getElementById("_UserLabel");
            if (userLabel != null) {
                userLabel.style.textDecoration = "none";
            }
        }
        if (Number(this.state.clientCount) > 1) {
            var clientLabel = document.getElementById("_ClientLabel");
            if (clientLabel != null) {
                clientLabel.style.textDecoration = "underline"; 
            }
        }
    }

    SwitchUserClickedView(event: MouseEvent) {

        if (this.state.userRoles && this.state.userRoles.length &&
            (this.props.location.pathname.indexOf("Landing") > -1) || (this.props.location.pathname.indexOf("Home")>-1)){

            var clientLabel = document.getElementById("_UserLabel");
            if (clientLabel != null) {
                clientLabel.style.textDecoration = "none";
            }
            var buttonLabel = document.getElementById("_UserSwitchButton");
            if (buttonLabel != null) {
                buttonLabel.style.visibility = "visible";
            }
            var selectLabel = document.getElementById("userName");
            if (selectLabel != null) {
                selectLabel.style.visibility = "hidden";
                selectLabel.style.display = "none";
            }
            var comboBoxLabel = document.getElementById("_UserComboBox");
            if (comboBoxLabel != null) {
                comboBoxLabel.style.visibility = "visible";
                comboBoxLabel.style.width = "auto";
                var selectOptions = comboBoxLabel.children;
                if (selectOptions != null) {
                    this.userPointer = (selectOptions[0].id); 
                }
            } 
        }
    }

    SwitchClientViewback() {
        var clientLabel = document.getElementById("_ClientLabel");
        if (clientLabel != null) {
            clientLabel.style.textDecoration = "underline";
        }
        var buttonLabel = document.getElementById("_SwitchButton");
        if (buttonLabel != null) {
            buttonLabel.style.visibility = "hidden";
        }
        var selectLabel = document.getElementById("clientName");
        if (selectLabel != null) {
            selectLabel.style.visibility = "visible";
            selectLabel.style.display = "block";
        }
        var comboBoxLabel = document.getElementById("_RecipientComboBox");
        if (comboBoxLabel != null) {
            comboBoxLabel.style.visibility = "hidden";
            comboBoxLabel.style.width = "0px";
        }
    }
    
    render() {

        var selectBoxStyle: React.CSSProperties = { color: 'black', backgroundColor: '#FFFFFF', visibility: 'hidden', width: '0px', height: '17px',
            borderLeft: '#000000 1px solid', borderRight: '#000000 1px solid', borderTop: '#000000 1px solid', borderBottom: '#000000 1px solid',
            padding: '0px',margin: '0px', 
        };
        var clientStyles: React.CSSProperties = { cursor: 'pointer', fontSize: '9pt' };
        var hiddenStyles: React.CSSProperties = { visibility: 'hidden', height: '17px', padding: '0px', margin: '0px', border: '0px'};
        var clientNameStyles: React.CSSProperties = { display: 'block', };
        var copyRightIcon = this.getCopyRightIcon();
        var switchButtonStyles: React.CSSProperties = {
            fontSize: '8pt', textTransform: 'none', backgroundColor: '#FFFFFF',
            borderLeft: '#000000 1px solid', borderRight: '#000000 1px solid', borderTop: '#000000 1px solid', borderBottom: '#000000 1px solid'
        };
        var tableStyle: React.CSSProperties = {
            borderCollapse: 'collapse', border: '0px', borderSpacing:'0px'
        };
        var userStyles: React.CSSProperties = { cursor: 'pointer', textDecoration: 'underline', fontSize: '9pt' };
        var optionState = this.selectedName;
        const userName = sessionStorage.getItem("Username");


        return (
            <CenteredContentFlex>
                <BottomWrapper {...this.props}>
                    { this.state.brandDefault &&
                        <CopyRightImageWrapper>{copyRightIcon}</CopyRightImageWrapper>
                    }
                <BottomLineWrapper>
                    <ServerWrapper className="serverDisplayName" >
                        <a><b>Server: </b></a>{this.state.serverName}
                    </ServerWrapper>
                    {this.ConditionalSwitch()}
                    <ClientWrapper className="clientDisplayName"  >
                        <table style={tableStyle}>
                            <tbody>
                                <tr>
                                    <td>       
                                        <span id="_ClientLabel" onClick={(e: any) => this.SwitchClientClickedView(e)} style={clientStyles}><b>Client: </b></span>
                                    </td>
                                    <td>
                                        <a id="clientName" style={clientNameStyles}>{this.state.clientName}</a>
                                    </td>
                                    <td id="clientList"  >
                                        <select id="_RecipientComboBox"
                                            value={optionState}
                                                style={selectBoxStyle}
                                                onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.RecipientComboBox_onClick(event)}>
                                            {this.buildClientList()} 
                                        </select>
                                    </td>
                                    <td id="_SwitchButton" style={hiddenStyles}>
                                            <input
                                                style={switchButtonStyles}
                                                type="button"
                                                value="Switch"
                                                onClick={(e: React.MouseEvent<HTMLInputElement>) => this.goToClient(e)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </ClientWrapper>
                    <UserWrapper>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <span id="_UserLabel" onClick={(e: any) => this.SwitchUserClickedView(e)} style={userStyles}><b>User: </b></span>
                                    </td>
                                    <td>
                                        <a id="userName" >{userName}</a>
                                    </td>
                                    <td id="userList"  >
                                        <select id="_UserComboBox"
                                            style={selectBoxStyle}
                                            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => this.UserComboBox_onClick(event)}>
                                            {this.buildUserList(false)}
                                        </select>
                                    </td>
                                    <td id="_UserSwitchButton" style={hiddenStyles}>
                                        <input
                                            style={switchButtonStyles}
                                            type="button"
                                            value="Switch"
                                            onClick={(e: React.MouseEvent<HTMLInputElement>) => this.persistRoleSelection(e)} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </UserWrapper>
                        <div style={{ clear: 'both' }} />
                        <CopyRightLingoWrapper style={{ whiteSpace: 'pre-wrap', fontFamily:'Optum Sans C' }} className="copyrightLingo" font-size="9pt" > &copy; 2024 Optum, Inc. or its affiliates. All rights reserved.
                        <div style={{ clear: 'both' }} />
                    </CopyRightLingoWrapper>
                </BottomLineWrapper>


              </BottomWrapper> 
              
           </CenteredContentFlex>
        ); 
    }
};

export default withRouter(FooterComponent);