import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ManageClaimAssignmentsPayerStatusRulesUI from './ui/ManageClaimAssignmentsPayerStatusRulesUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IPayerStatusRuleState = ICrudComponentState<MCAPayerStatusRulesType, ManageClaimAssignmentsPayerStatusRulesUI.IMCAPayerStatusRulesUIState>;

export const actionCreators = createCrudComponentActions<ManageClaimAssignmentsPayerStatusRulesUI.ActionCreators>(ManageClaimAssignmentsPayerStatusRulesUI.actionCreators);
export type IPayerStatusRuleActions = typeof actionCreators;
export type IPayerStatusRuleActionProps = ICrudComponentActionProps<IPayerStatusRuleActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimAssignment, ManageClaimAssignmentsPayerStatusRulesUI.reducer);
export const reducer = combineReducers<IPayerStatusRuleState>(reducers);

export const validationCallback: ValidationCallback<MCAPayerStatusRulesType> = (crud: any): crud is MCAPayerStatusRulesType => {

    let testCrud: MCAPayerStatusRulesType = crud as MCAPayerStatusRulesType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCAPayerStatusRulesType");

    if (validShape) {
        VerifyArray(testCrud?.ClaimAssignmentInfo?.PayerList, "Payer");
        VerifyArray(testCrud?.ClaimAssignmentInfo?.StatusCodeList, "StatusCode");
        VerifyArray(testCrud?.ClaimAssignmentInfo?.Category277CodeList, "Category277Code");
        VerifyArray(testCrud?.ClaimAssignmentInfo?.ReconLevelList, "ReconLevel");
        VerifyArray(testCrud?.ClaimAssignmentInfo?.RemitStatusCodeList, "RemitStatusCode");
        VerifyArray(testCrud?.ClaimAssignmentInfo?.DDEErrorCodeList, "DDEErrorCode");
        VerifyArray(testCrud?.ClaimAssignmentInfo?.FacilityList, "Facility");
        VerifyArray(testCrud?.ClaimAssignmentInfo?.UserList, "User");
        VerifyArray(testCrud?.ClaimAssignmentInfo?.DepartmentList, "Department");
        if (testCrud.ClaimAssignmentInfo?.StatusRules as any === "") {
            testCrud.ClaimAssignmentInfo.StatusRules = { StatusRule: new Array<MCAPSR_StatusRulesList>() };
        }
        if (testCrud.ClaimAssignmentInfo.StatusRules &&
            Array.isArray(testCrud.ClaimAssignmentInfo.StatusRules.StatusRule)) {
            testCrud.ClaimAssignmentInfo.StatusRules.StatusRule = ValidatArrayDetails(testCrud.ClaimAssignmentInfo.StatusRules.StatusRule);
        }
        else {
            let arr = new Array<MCAPSR_StatusRulesList>();
            if (typeof (testCrud.ClaimAssignmentInfo.StatusRules.StatusRule) === "object") {
                let obj = JSON.parse(JSON.stringify(testCrud.ClaimAssignmentInfo.StatusRules.StatusRule));
                arr.push({
                    "@CurrentStatusRuleId": 1,
                    "@ID": obj["@ID"],
                    '@Payer': obj["@Payer"],
                    '@CodeType': obj["@CodeType"],
                    '@Code': obj["@Code"],
                    '@Expires': obj["@Expires"],
                    'Users': obj["Users"],
                    'Departments': obj["Departments"],
                    'Facilities': obj["Facilities"]
                })

                testCrud.ClaimAssignmentInfo.StatusRules.StatusRule = ValidatArrayDetails(arr);

            }
        }
    }



    return validShape;
}

export const ValidatArrayDetails = (arrayDetail: any) => {
    let j = 0;
    for (let i = 0; i < arrayDetail.length; i++) {
        arrayDetail[i]["@CurrentStatusRuleId"] = j + 1;
        j++;
        if (arrayDetail[i] &&
            !Array.isArray(arrayDetail[i].Users)) {
            let usersData = JSON.parse(JSON.stringify(arrayDetail[i].Users));
            if (usersData !== "") {
                if (Array.isArray(usersData.User)) {
                    let arr = new Array<MCAPSR_UserLookupList>();
                    for (let j = 0; j < usersData.User.length; j++) {

                        arr.push({
                            '@IndexId': j + 1,
                            '@ID': usersData.User[j]["@ID"],
                            '@Name': "",
                            '@Enabled': true,
                            '@FirstName': "",
                            '@LastName': "",
                            '@AlphaSplit': usersData.User[j]["@AlphaSplit"],
                            '@IsChecked': true,
                            '@UserID': usersData.User[j]["@ID"],
                            '@Delete': false
                        });
                    }
                    arrayDetail[i].Users = arr;

                }
                else {
                    let arr = new Array<MCAPSR_UserLookupList>();
                    arr.push({
                        '@IndexId': j + 1,
                        '@ID': usersData.User["@ID"],
                        '@Name': "",
                        '@Enabled': true,
                        '@FirstName': "",
                        '@LastName': "",
                        '@AlphaSplit': usersData.User["@AlphaSplit"],
                        '@IsChecked': true,
                        '@UserID': usersData.User["@ID"],
                        '@Delete': false
                    });
                    arrayDetail[i].Users = arr;
                }
            }
            else {
                let arr = new Array<MCAPSR_UserLookupList>();
                arrayDetail[i].Users = arr;
            }

        }
        if (arrayDetail[i] &&
            !Array.isArray(arrayDetail[i].Departments)) {
            let departmentsData = JSON.parse(JSON.stringify(arrayDetail[i].Departments));
            if (departmentsData === "") {
                arrayDetail[i].Departments = { "@ID": "0", "@Name": "", "@Priority": "" };
            }
            else {

                arrayDetail[i].Departments = departmentsData.Department;
            }

        }
        if (arrayDetail[i] &&
            !Array.isArray(arrayDetail[i].Facilities)) {
            let facilitiesData = JSON.parse(JSON.stringify(arrayDetail[i].Facilities));
            if (facilitiesData === "") {
                arrayDetail[i].Facilities = { "@ID": "0", "@Name": "" };
            }
            else {
                arrayDetail[i].Facilities = facilitiesData.Facility;
            }

        }
    }
    return arrayDetail;
}