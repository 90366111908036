import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as PaperClaimListUI from './ui/PaperClaimListUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IPaperClaimListState = ICrudComponentState<IARMPaperClaimList, PaperClaimListUI.IPaperClaimsUIState>;

export const actionCreators = createCrudComponentActions<PaperClaimListUI.ActionCreators>(PaperClaimListUI.actionCreators);
export type IPaperClaimListActions = typeof actionCreators;
export type IPaperClaimListActionProps = ICrudComponentActionProps<IPaperClaimListActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiPaperClaimsConfig, PaperClaimListUI.reducer);
export const reducer = combineReducers<IPaperClaimListState>(reducers);


export const validationCallback: ValidationCallback<MCFormsUsedType> = (crud: any): crud is MCFormsUsedType => {
    return true;
/*
    let testCrud: MCFormsUsedType = crud as MCFormsUsedType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCFormsUsedType");
    validShape = validShape && ValidateJSONSchema(testCrud?.FormsUsedMaintenanceInfo, "MCFormsUsedMaintenanceInfo");
    validShape = validShape && ValidateJSONSchema(testCrud?.FormsUsedMaintenanceInfo?.Forms, "MCFormsUseds");
    if (validShape) {
        VerifyArray(testCrud?.FormsUsedMaintenanceInfo?.Forms, "Form");
        validShape = ValidateArray(testCrud?.FormsUsedMaintenanceInfo?.Forms?.Form, "MCFormsUsed");
    }

    return validShape;
*/
}
