import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { IClaimFilterState, IClaimFilterActionProps, actionCreators } from '@store/ClaimFilter';
import { ClaimFilter, IClaimFilterComponentProps } from './ClaimFilter';

export interface IAddNoteFilterComponentState { };

export const DEFAULT_STATE: IAddNoteFilterComponentState = {};

type IOwnProps = IClaimFilterComponentProps & RouteComponentProps<{}>;

type IClaimFilterProps = IMergeApiComponentProps<IClaimFilterState, IClaimFilterActionProps, IOwnProps>;

export class ViewX12ClaimFromAssurance extends React.Component<IClaimFilterProps, IAddNoteFilterComponentState>
{

    static defaultProps: IClaimFilterComponentProps = {
        canEdit: false,
        canView: false,
        canCreate: false,
        ncsIsEnabled: false,
        process: "viewx12claim",
        execNode: "",
        execAction: "",
        execProcess: "",
        parameter: "some dummy",
        apiType: "Claim",
        title: "X12 Viewer",
        executeJS: undefined,
        extraInstruction: "Enter criteria to locate the claim file you wish to view. Only one claim file can be selected and viewed. Click Refine to view list of filter results, or click Continue to view file.",
        helpUrl: "/Support/Help/HELP_FilterX12Claim.htm"
    };

    constructor(props: IClaimFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onOk = (baseThis: any, param1: any): boolean => {
        return true;
    }

    public render() {
        
        return (
            <div>
                <ClaimFilter
                    {...this.props}
                    executeJS={this.onOk}
                />
                
            </div>

        );
    }
};

var connectedHoc = connect<IClaimFilterState, IClaimFilterActionProps, IOwnProps, IClaimFilterProps, ApplicationState>(
    createApiMapStateToProps('claimFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(ViewX12ClaimFromAssurance);

export default withRouter(connectedHoc);
