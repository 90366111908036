import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IAddNoteUIState {
    selectedNoteName: string;  // Name of note selected in list
    selectedNoteIndex: string; // Index of the note selected in list (maybe not needed)
    noteDescription: string;   // Description of the note showing in the text box

    isBusy: boolean;
    changed: boolean;
}

export interface IAddNoteUIData {
    index: string;
    value: boolean;
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//export interface IAN_LoadAddNote extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIFieldData> { }
interface ISelectNoteData {
    index: string;
    noteName: string;
    noteDescription: string;
};

export const defaultNote: ISelectNoteData = {
    index: '',
    noteName: '',
    noteDescription: '',
};

interface IClickOKData {
    goToFilter: boolean;
};

export const defaultClickOK: IClickOKData = {
    goToFilter: false
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IAddNoteUIState = {
    selectedNoteName: '',
    selectedNoteIndex: '',
    noteDescription: '',
    isBusy: false,
    changed: false,
};

export interface ISelectNote extends IApiActionData<MCAddNote, ISelectNoteData> { }

export interface IAN_FieldData extends IApiActionData<APICF_ListPopulationContainer, IAddNoteUIData> { }

export const actionCreators = {
    selectNote: (selectInfo: ISelectNote) => createDataAction('AN_SELECT_NOTE', selectInfo), 
    updateNoteDescription: (noteDescription: ISelectNote) => createDataAction('AN_UPDATE_DESCRIPTION', noteDescription),
    setBusy: (fieldInfo: IAN_FieldData) => createDataAction('AN_SETBUSY', fieldInfo),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IAddNoteUIState, KnownActions> = (state: IAddNoteUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {

            case 'AN_SELECT_NOTE':
                let newStateData: IAddNoteUIState = { ...state };
                newStateData.selectedNoteName = action.data.uiData.noteName;
                newStateData.selectedNoteIndex = action.data.uiData.index;
                newStateData.noteDescription = action.data.uiData.noteDescription;
                console.log('AN_SELECT_NOTE:' + newStateData.noteDescription);
                newStateData.changed = (action.data.uiData.noteDescription != undefined && action.data.uiData.noteDescription.length != 0 ? true : false);
                return newStateData;

            case 'AN_UPDATE_DESCRIPTION':
                let newStateData2: IAddNoteUIState = { ...state };
                newStateData2.selectedNoteName = "";
                newStateData2.selectedNoteIndex = "";
                newStateData2.noteDescription = action.data.uiData.noteDescription;
                newStateData2.changed = (action.data.uiData.noteDescription != undefined && action.data.uiData.noteDescription.length != 0 ? true : false);
                return newStateData2;

            case 'AN_SETBUSY':
                return {
                    ...state,
                    isBusy: action.data.uiData.value,
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}

