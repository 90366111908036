import * as React from 'react';
import styled from 'styled-components';

interface IProps {
    helpCtrl?: string;
}

const TopContainer = styled.div.attrs({ className: 'componentTop' })`
    width: '100%';
    padding: '10px';
`;

const HelpControl = styled.div.attrs({})`
    ${(props: IProps) => props.helpCtrl && props.helpCtrl == 'true' && `
        float: 'right';
    `};
`;

export class AssuranceComponentTop extends React.Component<IProps, {}>
{
    // in the future a help url can be specified and added to button
    constructor(props: IProps)
    {
        super(props);
    }

    getHelpCtrl() {
        var useHelp = 'false';
        if ( this.props.helpCtrl != undefined )
            useHelp = this.props.helpCtrl;
            
        if (useHelp == 'true') {
            return <HelpControl><button className="helpButton">?</button></HelpControl>; 
        }
    }

    render()
    {
        return <TopContainer {...this.props}>
                {this.getHelpCtrl()}
               </TopContainer>;
    }
};