import { Reducer, ActionCreatorsMapObject } from 'redux';
import { keyBy } from 'lodash';
import { IDCG_Element } from '@components/common/DropdownChoiceGrid';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { IFormUIState, defaultFormState } from "@store/ui/BaseCrudUI";
import { createDataAction, ActionTypes, IApiAction } from "@scripts/util/ActionHelpers";

// Properties of action creators specific to the ManageCrossovers view
export interface CrossoverPayer {
    id: string;
    name: string;
    cpId: string;
    sop: string;
    label: string;
    isEnabled: boolean;
    threshold?: string;
}
export interface IManageCrossoversData {
    payersList: CrossoverPayer[];
    disabledPayers: CrossoverPayer[];
    enabledPayers: IDCG_Element [];
    originalEnabledPayerInfo: { [key: string]: string }; // TODO: need to type this this so props are in payersList.id probably ThisType will help
    selectedEnabledPayerId: string; 
    selectedDisabledPayerIds: string[];
}

// UI representation of CRUD data. 
export interface IManageCrossoversUIState extends IManageCrossoversData, IFormUIState {
};

export interface IEnabledPayerThresholdEditData {payerId: string; threshold: string};
// merge our custom action data interface with CRUD boilerplate
export interface IModifyManageCrossovers extends ICrudActionData<MCCrossoverPayerManagement, string> {
};

export interface IModifyPayerThreshold extends ICrudActionData<MCCrossoverPayerManagement, IEnabledPayerThresholdEditData> {};

export interface IMovePayer extends ICrudActionData<MCCrossoverPayerManagement, string> {};
export interface IMovePayers extends ICrudActionData<MCCrossoverPayerManagement, string[]> {};

export interface IInitializeManageCrossovers extends ICrudActionData<MCCrossoverPayerManagement, MCCrossoverMaintenancePayerInfo> {
};

// exported for if I get time to do tests, otherwise does not need export
export const actionCreators = {
    initalizeMCOptions: (rawApiReturn: IInitializeManageCrossovers) => createDataAction('INIT_MANAGE_CROSSOVER', rawApiReturn),
    editMCPayerThreshold: (editData: IModifyPayerThreshold) => createDataAction('EDIT_MANAGE_CROSSOVER_THRESHOLD', editData),
    enableMCPayers: (payersWithCrud: IMovePayers) => createDataAction('MANAGE_CROSSOVER_ADD_PAYERS', payersWithCrud),
    disableMCPayer: (payerWithCrud: IMovePayer) => createDataAction('MANAGE_CROSSOVER_REMOVE_PAYER', payerWithCrud),
    resetMCDirtyFlag: (flagValue: boolean) => createDataAction('RESET_MANAGE_CROSSOVER_DIRTY', flagValue),
    selectMCEnabledRecord: (enabledPayerRecordId: string) => createDataAction("SELECT_ENABLED_MC_RECORD", enabledPayerRecordId),
    selectMCDisabledRecords: (disabledPayerRecordIds: string[]) => createDataAction("SELECT_DISABLED_MC_RECORDS", disabledPayerRecordIds),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultManageCrossoversState: IManageCrossoversUIState = {
    ...defaultFormState,
    /*temp to have something to see on screen */
    payersList: [],
    disabledPayers: [],
    enabledPayers: [],
    originalEnabledPayerInfo: {},
    selectedEnabledPayerId: '',
    selectedDisabledPayerIds: [],
}

const uiPayerIdToCrudPayerId = (id: string):string => id.split('-').shift() || id;

export function mapMCUiStateToCrud<T extends IManageCrossoversData>(state: T): MCEnabledPayer[] {
    const enabledPayersDelta: MCEnabledPayer[] = state.enabledPayers.map(payer => ({
        '@ID': uiPayerIdToCrudPayerId(payer.ID),
        '@SOP': payer.Sop,
        '@Threshold': payer.Threshold
    }));
    const previouslyEnabledPayers = state.payersList.filter(payer => state.originalEnabledPayerInfo[payer.id] && !state.enabledPayers.find(ePayer => ePayer.ID === payer.id));
    const payersToDelete = previouslyEnabledPayers.map(payer => {
        return {
            '@ID': uiPayerIdToCrudPayerId(payer.id),
            '@SOP': payer.sop,
            '@Threshold': state.originalEnabledPayerInfo[payer.id],
            '@Delete': 'true',
        };
    });
    return [...enabledPayersDelta, ...payersToDelete];
};

const mcPayerToId = (mcPayer: MCCrossoverPayer | MCEnabledPayer): string =>`${mcPayer['@ID']}-${mcPayer['@SOP']}`;

export function mapMCCrudToUiState(crud: MCCrossoverMaintenancePayerInfo): IManageCrossoversUIState {
    //hash so we don't have to loop through to find given Payers
    const enabledById = keyBy(crud.EnabledPayers.EnabledPayer, mcPayerToId);
    const selectedEnabledPayerId = '';
    const payersList = crud.PayerList.Payer.map(payer => ({
        id: `${payer['@ID']}-${payer['@SOP']}`,
        sop: payer['@SOP'],
        cpId: payer['@CPID'],
        name: payer['@Name'],
        label: `${payer['@Name']} (${payer['@CPID']} - ${payer['@SOP']})`,
        isEnabled: !!enabledById[mcPayerToId(payer)],
    }));
    const enabledCrossoverPayers = payersList.filter(payer => payer.isEnabled);
    const disabledPayers = payersList.filter(payer => !payer.isEnabled);
    const enabledPayers = enabledCrossoverPayers.map(payer => ({
        ID: payer.id,
        Label: payer.label,
        Name: payer.name,
        Sop: payer.sop,
        Threshold: enabledById[payer.id]['@Threshold'],
    }));
    const originalEnabledPayerInfo = enabledPayers.reduce((info, payer) => ({...info, [payer.ID]: payer.Threshold }), {});
    return {
        isDirty: false,
        payersList,
        disabledPayers,
        enabledPayers,
        originalEnabledPayerInfo,
        selectedEnabledPayerId,
        selectedDisabledPayerIds: [],
    }
}


export const manageCrossoversUIReducer: Reducer<IManageCrossoversUIState, KnownActions> = (state: IManageCrossoversUIState | undefined, action: KnownActions) => {
    const isDirty = true;
    let newState = state && { ...state };

    if (state) {
        switch (action.type) {
            case 'INIT_MANAGE_CROSSOVER':
            {
                const crud = action.data.uiData;
                if (crud) return mapMCCrudToUiState(crud);
                break;
            }
            case 'EDIT_MANAGE_CROSSOVER_THRESHOLD':
            {
                const crudData = action.data?.masterCrud?.CrossOverPayerManagement;
                const { uiData } = action.data;
                const enabledPayers = state.enabledPayers.map(payer => payer.ID === uiData.payerId ? {...payer, Threshold: uiData.threshold}: payer);
                const originalEnabledPayerInfo = state.originalEnabledPayerInfo[uiData.payerId] ? {...state.originalEnabledPayerInfo, [uiData.payerId]: uiData.threshold} : state.originalEnabledPayerInfo;
                newState = { ...state,isDirty, originalEnabledPayerInfo, enabledPayers, selectedEnabledPayerId: uiData.payerId };
                if (crudData) crudData.EnabledPayers.EnabledPayer = mapMCUiStateToCrud(newState);
                break;
            }
            case 'RESET_MANAGE_CROSSOVER_DIRTY':
            {
                newState = { ...state, isDirty: false };
                break;
            }
            case 'MANAGE_CROSSOVER_ADD_PAYERS': {
                const payers = state.payersList.filter(payer => action.data.uiData.includes(payer.id));
                const newEnabledPayers = payers.map(payer => (
                    {
                        ID: payer.id,
                        Label: payer.label,
                        Name: payer.name,
                        Sop: payer.sop,
                        Threshold: payer.threshold ?? '14',
                    }
                ))
                const enabledPayers = state.enabledPayers.concat(newEnabledPayers);
                const enabledPayerById = keyBy(enabledPayers, 'ID');
                const disabledPayers = state.payersList.filter(payer => !enabledPayerById[payer.id]);

                newState = { ...state, isDirty, enabledPayers, disabledPayers, selectedDisabledPayerIds: [], selectedEnabledPayerId: '', };
                const crudData = action.data?.masterCrud?.CrossOverPayerManagement;
                if (crudData) crudData.EnabledPayers.EnabledPayer = mapMCUiStateToCrud(newState);
                break;
            }
            case 'MANAGE_CROSSOVER_REMOVE_PAYER': {
                let payerId: string = action.data.uiData;
                const ePayer = state.enabledPayers.find(payer => payer.ID === payerId);
                const payersList = state.payersList.map(payer => payer.id === payerId ? { ...payer, threshold: ePayer?.Threshold ?? '14', isEnabled: false } : payer);
                //console.log(payersList); // debuke says: do NOT keep in...
                const enabledPayers = state.enabledPayers.filter(payer => payer.ID != payerId);
                const disabledPayers = payersList.filter(payer => !payer.isEnabled);
                
                newState = { ...state, isDirty, payersList, disabledPayers, enabledPayers, selectedDisabledPayerIds: [], selectedEnabledPayerId: '',};
                
                const crudData = action.data?.masterCrud?.CrossOverPayerManagement;
                if (crudData) crudData.EnabledPayers.EnabledPayer = mapMCUiStateToCrud(newState);
                break;
            }
            case 'SELECT_ENABLED_MC_RECORD': {
                const newState = { ...state, selectedEnabledPayerId: action.data };
                return newState; 
            }
            case 'SELECT_DISABLED_MC_RECORDS': {
                const newState = { ...state, selectedDisabledPayerIds: action.data };
                return newState; 
            }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }
    return newState || defaultManageCrossoversState;
};
