import { combineReducers, Reducer } from 'redux';
import * as ReportOptionsUI from '@store/ui/ReportOptionsUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '../scripts/util/ValidationHelpers';

export type IReportOptionsUIState = ICrudComponentState<MCReportOptions, ReportOptionsUI.IReportOptionsUIState>;
export const actionCreators = createCrudComponentActions<ReportOptionsUI.ActionCreators>(ReportOptionsUI.actionCreators);
export type IReportOptionsActions = typeof actionCreators;
export type IReportOptionsActionProps = ICrudComponentActionProps<IReportOptionsActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiCSVOption, ReportOptionsUI.reportOptionsUIReducer);
export const reducer = combineReducers<IReportOptionsUIState>(reducers);

export const validationCallback: ValidationCallback<MCReportOptions> = (crud: any): crud is MCReportOptions => {
    let testCrud: MCReportOptions = crud as MCReportOptions;
    let validShape = ValidateJSONSchema(testCrud, "MCReportOptions");
    return validShape;
};