
import * as React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ActionCreators, actionCreators /*, validationCallback */, IDashboardClaimState } from '@store/OperationalDashboard/DashboardClaim';
import { OperationFilter } from '@store/OperationalDashboard/DrillDownView';
import { ISelectionView, ISelectionMeasure, ISelectionDateRange } from "@store/OperationalDashboard/DashboardDefault"

import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CommonGraph, LegendData } from '@commonResources/commonGraphing/CommonGraph';
import _ from 'lodash';


import { CommonDataSelectionComponent } from './DataSelection/CommonDataSelectionComponent';
import ClaimsMeter from './MeterRowComponent';
import { createMapDispatchToProps, IMergeComponentProps, mergeComponentProps, IActionCreatorMap, mapStatesToProps, mapStateToProps, IApplicationStateMap } from '@scripts/util/ReduxHelpers';
import ReadOnlyList from '@commonResources/ReadOnlyList';
import { getGraphLegendData, getGraphBarData, getGraphLineData } from './utils/dataParsers';

const graphWidth = 480;

let intervalID: number;

interface IComponentProps {
    canView: boolean;
    canEdit: boolean;
    pageWidth: number;
    pageHeight?: number | string | undefined;
};

interface IComponentState {
};

export const DEFAULT_STATE: IComponentState = {
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IDashboardClaimProps = IMergeComponentProps<IApplicationStateMap<"dashboardClaim" | "drillDownView">, IActionCreatorMap<"dashboardClaim" | "drillDownView">, IOwnProps>;

const Page = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
`

const AllGraphsContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    column-gap: 30px;
    row-gap: 30px;
    align-items: start;
`;

const GraphWrapper = styled.div`
    display: flex;
    flex-direction: column; 
`;

const GraphContainer = styled.div`
    width: ${graphWidth}px;
    height: 100%;
    background-color: none;
    display: flex;
    justify-content: center;
`;

const HeaderTitle = styled.div`
    width: ${graphWidth}px;
    height: 40px;
    display: flex;
    align-items: center;
    background-color: ${Colors.blue70};
    color: ${Colors.white};
    ${Typography.extraLarge};
    ${Typography.ARMFontFamily};
    font-weight: 600;
    padding: 5px 10px;
`;




const Name = styled("div")`
    padding-bottom: 0.2em; 
    text-transform: uppercase; 
    font-size: 11px;
`;

const Data = styled("div")`
    font-size: 9px;
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const Label = styled("div")`
    font-weight: bold;
    color: ${Colors.blue30}
`;

const Value = styled("div")`
    font-size: 12px;
    color: white;
`;

const NoDataBannerText = styled.div`
    color: ${Colors.black};
    ${Typography.headingSmall};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
    ${Typography.defaultLineHeight};
    ${Typography.defaultHeight};
    display: flex;
    align-items: center;
    width: auto;
`;


export class DashboardClaim extends React.Component<IDashboardClaimProps, IComponentState> {

    constructor(props: any) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        this.getNewClaimData()
        intervalID = setInterval(
            () => this.getNewClaimData(), 5000 * 60
        );
    }

    getNewClaimData = () => {
        const dashboardClaimActions = this.props.action?.dashboardClaim;
        const dashboardClaimData = this.props.dataStore.dashboardClaim;
        dashboardClaimActions && dashboardClaimActions.getClaimData({
            tab: "0",
            view: dashboardClaimData?.selectedView && dashboardClaimData?.selectedView.value,
            measure: dashboardClaimData?.selectedMeasure && dashboardClaimData?.selectedMeasure.value,
            dateRange: dashboardClaimData?.selectedDateRange && dashboardClaimData?.selectedDateRange.value,
            drillPage: "0",
            fromDate: dashboardClaimData?.selectedDateRange && dashboardClaimData?.selectedDateRange.value === "7" ? dashboardClaimData?.selectedStartDate && dashboardClaimData?.selectedStartDate : "",
            thruDate: dashboardClaimData?.selectedDateRange && dashboardClaimData?.selectedDateRange.value === "7" ? dashboardClaimData?.selectedEndDate && dashboardClaimData?.selectedEndDate : "",
            stampDateTime: "",
            useTestData: ""
        })
    }

    public isChartClickable(item: any) {

        //console.log("Is chart clickable? ", !!item.filter);

        return !!item.filter;
    }

    public getRootActionPayload(item: any) {
        //const newFilter = { ...item.filter, view: "Lists" }
        return item.filter;
    }

    public getRootTooltip(item: any) {
        return item.toolTip;
    }


    public onSelectViewHandler = (e: ISelectionView) => {
        const { selectView, getClaimData } = this.props.action?.dashboardClaim;
        const { selectedMeasure, selectedDateRange, selectedStartDate, selectedEndDate } = this.props.dataStore?.dashboardClaim;
        selectView && selectView(e);
        let data: OperationFilter = {
            tab: "0",
            view: e.value,
            measure: selectedMeasure && selectedMeasure.value,
            dateRange: selectedDateRange && selectedDateRange.value,
            drillPage: "0",
            fromDate: selectedDateRange && selectedDateRange.value === "7" ? selectedStartDate && selectedStartDate : "",
            thruDate: selectedDateRange && selectedDateRange.value === "7" ? selectedEndDate && selectedEndDate : "",
            stampDateTime: "",
            useTestData: ""
        }
       
        getClaimData && getClaimData(data);
    }

    public onSelectMeasureHandler = (e: ISelectionMeasure) => {
        return new Promise((res) => {
            const { selectMeasure, getClaimData } = this.props.action?.dashboardClaim;
            const { selectedView, selectedDateRange, selectedStartDate, selectedEndDate } = this.props.dataStore?.dashboardClaim;
            selectMeasure && selectMeasure(e);
            let data: OperationFilter = {
                tab: "0",
                view: selectedView && selectedView.value,
                measure: e.value,
                dateRange: selectedDateRange && selectedDateRange.value,
                drillPage: "0",
                fromDate: selectedDateRange && selectedDateRange.value === "7" ? selectedStartDate && selectedStartDate : "",
                thruDate: selectedDateRange && selectedDateRange.value === "7" ? selectedEndDate && selectedEndDate : "",
                stampDateTime: "",
                useTestData: ""
            }

            getClaimData && getClaimData(data);
            res(true)
        })
        
    }

    public onSelectDateRangeHandler = (e: ISelectionDateRange, startDate: string, endDate: string) => {
        const { selectDateRange, getClaimData } = this.props.action?.dashboardClaim;
        const { selectedView, selectedMeasure } = this.props.dataStore?.dashboardClaim;
        selectDateRange && selectDateRange(e, startDate, endDate);
        let data: OperationFilter = {
            tab: "0",
            view: selectedView && selectedView.value,
            measure: selectedMeasure && selectedMeasure.value,
            dateRange: e.value,
            drillPage: "0",
            fromDate: startDate,
            thruDate: endDate,
            stampDateTime: "",
            useTestData: ""
        };
        getClaimData && getClaimData(data);
    }

    getGraph = (graph: any, index: number) => {
        /* Actions from Default */
        const {
            drillDownTo,
        } = this.props.action.drillDownView;
        const legendData: any = getGraphLegendData(graph);
        const lineColorArray = legendData.reduce((result: any, value: any) => {
            return ((value.dataPointType === "Line") ? [...result, value.symbol.fill] : result)
        }, [])

        return (<GraphWrapper key={`${index}-graph`}>
            <HeaderTitle>{graph.headerTitle}</HeaderTitle>

            <GraphContainer>
                {graph &&
                    <CommonGraph
                        title={graph.title}
                        xLabel={graph.bottomTitle}
                        label={graph.leftTitle}

                        legendData={legendData}

                        graphType={'bar'}
                        data={getGraphBarData(graph)}
                        lineData={getGraphLineData(graph)}
                    lineColorArray={lineColorArray}
                        displayLine={true}

                        actionPayload={this.getRootActionPayload(graph)}
                        disableDataEvents={this.isChartClickable(graph)}

                        graphClickHandler={this.isChartClickable(graph) ? (target) => {
                            drillDownTo(this.getRootActionPayload(graph));
                        } : () => { }}
                        barClickHandler={(actionPayload) => console.log('[barClickHandler]: The bar has been clicked with payload: ', actionPayload)}
                        labelClickHandler={(actionPayload) => console.log('a label has been clicked! ', actionPayload)}
                        pointClickHandler={(actionPayload) => console.log('a point has been clicked! ', actionPayload)}
                        tooltipData={{
                            tooltipWidth: 180,
                            tooltipOffsetX: 20,
                            tooltipOffsetY: 30,

                            getContent: (tooltipData: any) => {
                                //const { tooltipValue } = tooltipData;
                                return (
                                    <>
                                        <Value>{this.getRootTooltip(graph)}</Value>
                                    </>
                                )
                            }
                        }}

                    />
                }
            </GraphContainer>
        </GraphWrapper>)
    }

    getListRecords = (data: any[]) => {
        return data.map((d) => {
            return this.getListColumnData(d.columnData);
        });
    };

    getListColumnData = (columnData: any[]) => {
        return columnData.map((cd) => {
            const { filter, text } = cd;
            const isCFI = filter && filter.cfi ? filter.cfi : false;
            const onDataClick = () => {
                if (isCFI) {
                    this.setState({
                        isCfiModalOpen: true,
                        startTimer: true,
                    }, () => this.props.action.drillDownView.drillDownTo(filter));
                } else {
                    this.props.action.drillDownView.drillDownTo(filter);
                };
            };
            if (filter) {
                return {
                    text,
                    onClick: onDataClick
                }
            } else {
                return { text }
            }
        }).reduce((a, v, i) => ({ ...a, ["@data" + i]: v }), {})
    }

    getListColumns = (columns: any[], graphWidth: number, graphIndex: number) => {
        return columns.map((column, columnIndex) => {
            const { sortable, text } = column;
            const columnFract = (graphWidth / 8)
            let width = columnFract;
            if (columnIndex === 0) {
                width = columnFract * 4
            } else if (columnIndex === 2) {
                width = columnFract * 3
            } else {
                width = columnFract
            }

            return {
                dataName: "@data" + columnIndex,
                text,
                sortable,
                rightAlignText: columnIndex,
                width
            };
        });
    };

    getList = (chartData: any, width: number, index: number) => {
        const {
            drillDownTo,
        } = this.props.action.drillDownView;
        const columns = this.getListColumns(chartData.listHeader.columns, width, index);
        const records = this.getListRecords(chartData.data);
        const key = index + "" + Date.now() + "" + Math.random() + "" + _.uniqueId()

        return (
            <ReadOnlyList
                key={key}
                title={
                    (<div style={{ display: "flex", flexDirection: "column" }}>
                        <HeaderTitle>{chartData.headerTitle}</HeaderTitle>
                        <div style={{ marginTop: "15px", height: "14px" }}>
                            {chartData.title}
                        </div>
                    </div>)
                } // optional header prop
                domID={key+"-graph-"}
                onClick={this.isChartClickable(chartData) ?
                    () => {
                        drillDownTo(this.getRootActionPayload(chartData) )
                    } :
                    () => { }}
                tooltip={""+this.getRootTooltip(chartData)}
                columns={columns}
                records={records} />
        )
    }

    getGraphsJSXArray = (graphs: any[]) => {
        return (
            graphs.map((graph: any, index: number) => {
                switch (graph.outputType) {
                    case 'List':
                        return this.getList(graph, graphWidth, index);
                    case 'Chart':
                        return this.getGraph(graph, index)
                }
            })
        )
    }

    componentWillUnmount = () => {
        clearInterval(intervalID);
    }

    public render() {

        

        /* Retrieve Stored Data From Dashboard Default*/
        const {
            drillDownDataArray,
        } = this.props.dataStore.drillDownView;


        /* Retrieve Stored Data From Dashboard Claim*/
        const {
            claimData,
            selectedView,
            selectedMeasure,
            selectedDateRange,
            selectedStartDate,
            selectedEndDate,
            uiLock } = this.props.dataStore.dashboardClaim;

        let graphs: any = [];

        if (claimData) {
            graphs = claimData.items ?? [];
        };

        return (
            <>
                {claimData && <ClaimsMeter meterData={claimData.meters} />}
                <CommonDataSelectionComponent
                    selectedView={selectedView}
                    selectedMeasure={selectedMeasure}
                    selectedDateRange={selectedDateRange}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    onSelectView={(e: ISelectionView) => this.onSelectViewHandler(e)}
                    onSelectMeasure={(e: ISelectionMeasure) => this.onSelectMeasureHandler(e)}
                    onSelectDateRange={(e: ISelectionDateRange, startDate: string, endDate: string) => this.onSelectDateRangeHandler(e, startDate, endDate)}
                    enabled={!!uiLock}
                />
                <Page>
                    {
                        (graphs.length > 0) ?
                            <AllGraphsContainer>{this.getGraphsJSXArray(graphs)}</AllGraphsContainer> :
                            <NoDataBannerText><p>No records to display at this time.</p></NoDataBannerText>
                    }
                </Page>
            </>
        );
    }
};

var connectedHoc = connect(
    mapStatesToProps([
        "drillDownView",
        "dashboardClaim"
    ]), // Selects which state properties are merged into the component's props
    createMapDispatchToProps([
        "drillDownView",
        "dashboardClaim"
    ]), // Selects which action creators are merged into the component's props
    mergeComponentProps
)(DashboardClaim);

export default withRouter(connectedHoc);
