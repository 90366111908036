import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from 'styled-components';

const RowCell = styled.div`
.tooltip {
  position: relative;
  padding: 3px; 
}
.tooltip .tooltiptext {
    visibility: hidden;
    border: 1px solid black;
    background: white;
    overflow:visible;
    background-color: lightyellow;
    width:inherit;

    /* Position the tooltip */
    position: absolute;
    z-index: 10;
    padding: 2px 2px;
    top:-18px;
    left:90%
    display:inline-block;
    margin-right:10px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
`

export interface IARMRowCellProps {
}

export class ARMRowCell extends React.Component<IARMRowCellProps | any, { show: any }>
{
    constructor(props: IARMRowCellProps) {
        super(props);
        this.state = {
            show: false
        }
    }

    public handleMouseHover(status: string) {
        if (status === 'in') {
            setTimeout(this.setState({ show: true }), 10000)
        } else {
            this.setState({ show: false })
        }
    }

    static propTypes: {};
    render() {
        const {
            style,
            displayTooltip
        } = this.props;
        return (
            <td>
                <RowCell className="cell-component-wrapper tooltip">

                    <div className="no-margin  tooltip" style={style}>
                        {this.props.value}
                        {displayTooltip && this.props.value.length >= 5 ? <div className='tooltip'> <span className="tooltiptext">{this.props.value}</span> </div> : null}
                    </div>
                </RowCell>
            </td>
        );
    }
};



ARMRowCell.propTypes = {
    value: PropTypes.string,
    style: PropTypes.object,
    displayTooltip: PropTypes.bool
}
