import { Reducer, Dispatch, AnyAction } from 'redux';
import {
    ActionTypes,
    createApiAction,
    createDataAction
} from '@scripts/util/ActionHelpers';
import { URLs } from "@commonResources/constants";
import { ComplexApiAction, IThunkAction, IThunkApiAction, IThunkResult } from "@scripts/util/ThunkHelpers";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IPayerMaintainceUIState {
    selectedPayer: any;
    payersData: any[];
    payerData: any[];
    isExist: boolean;
    isLoadingPayer: boolean;
    addedSuccessful: boolean;
    isBusy: boolean;
    statusMessage: string;
    modalText?: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//export interface IAN_LoadUserEmailConfirm extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIFieldData> { }
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IPayerMaintainceUIState = {
    selectedPayer: null,
    payersData: [],
    payerData: [],
    isExist: false,
    addedSuccessful: false,
    isLoadingPayer: false,
    isBusy: false,
    statusMessage: '',
    modalText: ""
};

export const actionCreators = {
    setSelectedPayer: (selectedPayer: string) => createDataAction('PCG_SET_SELECTED_PAYER', selectedPayer),
    removeSelectedPayer: () => createDataAction('PCG_REMOVE_SELECTED_PAYER', null),
    getPayerNames: () => createApiAction('PCG_GET_PAYER_NAMES', `${URLs.api}/api/data/pcg/getpayernames`),
    getPayerDetails: (id: string) => createApiAction('PCG_GET_DETAILS', `${URLs.api}/api/data/pcg/getecarpayernames/${id}`, id),
    addPayerDetails: (id: string, name: string) => createApiAction('PCG_ADD_DETAILS', `${URLs.api}/api/data/pcg/insertpayer/${id}/${name}`, {id,name}),
    setModalText: (modalText: string) => createDataAction('SET_MODAL_TEXT', modalText),
    deletePayer: (selectedPayer: string) => createApiAction('PCG_DELETE_PAYER', `${URLs.api}/api/data/pcg/deletepayer/${selectedPayer}`),
    deletePayerAndReload: (selectedPayer: string) => {
        return ComplexApiAction
            .fromAction(actionCreators.deletePayer(selectedPayer))
            .addThunk((
                dispatch: Dispatch<AnyAction>,
                apiResult: IThunkApiAction<string, any, any>): IThunkResult<void> => {
                if (apiResult.status.status === 'SUCCESS' && apiResult.responseData) {
                    dispatch(actionCreators.getPayerNames());                    
                    return { success: true }
                }
                return { success: false }
            }
            )
            .finish();
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IPayerMaintainceUIState, KnownActions> = (state: IPayerMaintainceUIState | undefined, action: KnownActions) => {
    if (state != undefined) { 
        switch (action.type) {
            case 'PCG_SET_SELECTED_PAYER':
                return {
                    ...state,
                    selectedPayer: action.data,
                }
                break;
            case 'PCG_REMOVE_SELECTED_PAYER':
                return {
                    ...state,
                    selectedPayer: action.data,
                }
                break;
            case 'PCG_GET_PAYER_NAMES':
                switch (action.status.status) {
                    case "REQUEST":
                        break;

                    case "SUCCESS":
                        {
                            let responseData: any = action?.responseData;
                            if (responseData.payersData) {
                                return {
                                    ...state,
                                    payersData: responseData.payersData,
                                    statusMessage: '',
                                    isBusy: false,
                                }
                            }
                        }
                    case "FAIL":
                        {
                            return {
                                ...state,
                                statusMessage: 'There was an issue obtaining payers names.',
                                isBusy: false
                            }
                        }
                }
                break;
            case 'PCG_GET_DETAILS':
                switch (action.status.status) {
                    case "REQUEST":
                        return {
                            ...state,
                            isLoadingPayer: true,
                        }
                        break;

                    case "SUCCESS":
                        {
                            let responseData: any = action?.responseData;
                            if (responseData) {
                                return {
                                    ...state,
                                    payerData: responseData.ecarPayersData,
                                    isLoadingPayer: false,
                                    isExist: state.payersData.some(x => x.tpId == action.data),
                                    isBusy: false,
                                    statusMessage: '',
                                }
                            }
                            break;
                        }
                    case "FAIL":
                        {
                            return {
                                ...state,
                                statusMessage: 'An error occurred while getting the payer details. Please try again later.',
                                isLoadingPayer: false,
                                isBusy: false,
                            }
                        }
                }
                break;
            case 'PCG_DELETE_PAYER':
                switch (action.status.status) {
                    case "REQUEST":
                        break;

                    case "SUCCESS":
                        {
                            // 200 back but may have error detail
                            let responseData = action?.responseData;
                            if (responseData) {
                                return {
                                    ...state,
                                    selectedPayer: null,
                                    statusMessage: '',
                                    payerData:[],
                                    modalText:'Payer was removed successfully.',
                                    isBusy: false,
                                }
                            }
                            break;
                        }
                    case "FAIL":
                        {
                            return {
                                ...state,
                                statusMessage: 'Error with delete payer request. Please try again.',
                                isBusy: false,
                            }
                        }
                }
                break;
            case 'SET_MODAL_TEXT':
                {
                    return {
                        ...state,
                        modalText: action.data,
                    }
                }
            case 'PCG_ADD_DETAILS':
                switch (action.status.status) {
                    case "REQUEST":
                        break;

                    case "SUCCESS":
                        {
                            // 200 back but may have error detail
                            let responseData = action?.responseData;
                            if (responseData) {
                                return {
                                    ...state,
                                    addedSuccessful: true,
                                }
                            }
                            break;
                        }
                    case "FAIL":
                        {
                            return {
                                ...state,
                                addedSuccessful: false,
                                statusMessage: 'Error with adding payer request. Please try again.',
                                isBusy: false,
                            }
                        }
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;
        }
    }
    return state || defaultState;
}

