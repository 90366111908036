import { Reducer } from 'redux';
import { mapKeys, hasIn } from 'lodash';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { IFormUIState, defaultFormState, ICanHazErrors } from "@store/ui/BaseCrudUI";
import { createDataAction, ActionTypes, ValidationCallback, createApiBodyAction } from "@scripts/util/ActionHelpers";
import { URLs } from '@commonDevResources/constants';

// Properties of action creators specific to the ADRCoverLetter view
export interface IADRCoverLetterData {
    contactName: string;
    contactNum: string;
    contactFax: string;
    comments: string;
    regularMail: string;
    priorityMail: string;
    addInfo: string;
}

// shape of the errors (needed for FormUIState generic optional errors prop)
//Note length-related errors not implemented due to fields' maxLength preventing long entries
export interface IADRCoverLetterErrors {
    incorrectCommentLength?: string;
    incorrectRMLength?: string;
    incorrectPMLength?: string;
    pdfWriteError?: string;
}

// UI representation of CRUD data. 
export interface IADRCoverLetterUIState extends IADRCoverLetterData, IFormUIState, ICanHazErrors<IADRCoverLetterErrors> {
    isBusy: boolean;
    pdfPath: string;
};

// merge our custom action data interface with CRUD boilerplate
export interface IModifyADRCoverLetter extends ICrudActionData<MCADRCoverLetter, string> {
};

export interface IResetCrudFlag extends ICrudActionData<MCADRCoverLetter, boolean> {
};

export interface IInitializeADRCoverLetter extends ICrudActionData<MCADRCoverLetter, MCAdrCoverLetterNode> {
};

// exported for if I get time to do tests, otherwise does not need export
export const actionCreators = {
    initalizeOptions: (rawApiReturn: IInitializeADRCoverLetter) => createDataAction('INIT_ADR_COVER_LETTER', rawApiReturn),
    editContactName: (adrCoverLetterInfo: IModifyADRCoverLetter) => createDataAction('EDIT_ADR_COVER_LETTER_CONTACT_NAME', adrCoverLetterInfo),
    editContactNum: (adrCoverLetterInfo: IModifyADRCoverLetter) => createDataAction('EDIT_ADR_COVER_LETTER_CONTACT_NUM', adrCoverLetterInfo),
    editContactFax: (adrCoverLetterInfo: IModifyADRCoverLetter) => createDataAction('EDIT_ADR_COVER_LETTER_CONTACT_FAX', adrCoverLetterInfo),
    editComments: (adrCoverLetterInfo: IModifyADRCoverLetter) => createDataAction('EDIT_ADR_COVER_LETTER_COMMENTS', adrCoverLetterInfo),
    editRegularMail: (adrCoverLetterInfo: IModifyADRCoverLetter) => createDataAction('EDIT_ADR_COVER_LETTER_REGULAR_MAIL', adrCoverLetterInfo),
    editPriorityMail: (adrCoverLetterInfo: IModifyADRCoverLetter) => createDataAction('EDIT_ADR_COVER_LETTER_PRIORITY_MAIL', adrCoverLetterInfo),
    editAddInfo: (adrCoverLetterInfo: IModifyADRCoverLetter) => createDataAction('EDIT_ADR_COVER_LETTER_ADD_INFO', adrCoverLetterInfo),
    previewPdf: (pdfData: MCAdrCoverLetterNode, callback: ValidationCallback<IPreviewReportResponse>) => createApiBodyAction('GENERATE_ADR_COVERLETTER_PREVIEW',
        `${URLs.api}/api/data/letter/ADR`,
        pdfData,
        'POST',
        JSON.stringify(mapKeys(pdfData, (value, key) => key.replace('@', ''))),
        callback
    ),
    resetDirtyFlag: (adrCoverLetterInfo: IResetCrudFlag) => createDataAction('RESET_ADR_COVER_LETTER_DIRTY', adrCoverLetterInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultADRCoverLetterState: IADRCoverLetterUIState = {
    ...defaultFormState,
    contactName: '',
    contactNum: '',
    contactFax: '',
    comments: '',
    regularMail: '',
    priorityMail: '',
    addInfo: '',
    pdfPath: '',
    isBusy: true,
}

export function mapUiStateToCrud<T extends IADRCoverLetterData>(state: T): MCAdrCoverLetterNode {
    const adrCoverLetterNode: MCAdrCoverLetterNode = {
        '@ContactName': state.contactName,
        '@ContactNum': state.contactNum,
        '@ContactFax': state.contactFax,
        '@Comments': state.comments,
        '@RegularMail': state.regularMail,
        '@PriorityMail': state.priorityMail,
        '@AddInfo': state.addInfo,
    };
    return adrCoverLetterNode;
};

export function mapCrudToUiState(crud: MCAdrCoverLetterNode): IADRCoverLetterUIState {
    return {
        isDirty: false,
        isBusy: false,
        errors: {},
        pdfPath: '',
        contactName: crud['@ContactName'],
        contactNum: crud['@ContactNum'],
        contactFax: crud['@ContactFax'],
        comments: crud['@Comments'],
        regularMail: crud['@RegularMail'],
        priorityMail: crud['@PriorityMail'],
        addInfo: crud['@AddInfo'],
    }
}

export const adrCoverLetterInfoUIReducer: Reducer<IADRCoverLetterUIState, KnownActions> =
    (state: IADRCoverLetterUIState | undefined, action: KnownActions) => {
        const isDirty = true;
        let newState = state && { ...state };

        if (state) {
            switch (action.type) {
            case 'INIT_ADR_COVER_LETTER':
            {
                const crud = action.data.uiData;
                if (crud) {
                    return mapCrudToUiState(crud);
                } else {
                    //need to repair our masterCrud so our edits in mapUiStateToCrud have a place to go
                    //we need to make sure to keep the masterCrud root object bc we don't have any way to reach out to the larger tree
                    if (hasIn(action.data, 'masterCrud')) {
                        action.data.masterCrud!.ADRCoverLetterMaintenanceInfo = {
                            ADRCoverLetter: mapUiStateToCrud(defaultADRCoverLetterState)
                        };
                    }
                    return { ...state, isBusy: false };
                }
                break;
            }
            case 'EDIT_ADR_COVER_LETTER_CONTACT_NAME':
            {
                const crudData = action.data?.masterCrud?.ADRCoverLetterMaintenanceInfo;
                newState = { ...state, contactName: action.data.uiData, isDirty };
                if (crudData) crudData.ADRCoverLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_ADR_COVER_LETTER_CONTACT_NUM':
            {
                const crudData = action.data?.masterCrud?.ADRCoverLetterMaintenanceInfo;
                newState = { ...state, contactNum: action.data.uiData, isDirty };
                if (crudData) crudData.ADRCoverLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_ADR_COVER_LETTER_CONTACT_FAX':
            {
                const crudData = action.data?.masterCrud?.ADRCoverLetterMaintenanceInfo;
                newState = { ...state, contactFax: action.data.uiData, isDirty };
                if (crudData) crudData.ADRCoverLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_ADR_COVER_LETTER_COMMENTS':
            {
                const crudData = action.data?.masterCrud?.ADRCoverLetterMaintenanceInfo;
                newState = { ...state, comments: action.data.uiData, isDirty };
                if (crudData) crudData.ADRCoverLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_ADR_COVER_LETTER_REGULAR_MAIL':
            {
                const crudData = action.data?.masterCrud?.ADRCoverLetterMaintenanceInfo;
                newState = { ...state, regularMail: action.data.uiData, isDirty };
                if (crudData) crudData.ADRCoverLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_ADR_COVER_LETTER_PRIORITY_MAIL':
            {
                const crudData = action.data?.masterCrud?.ADRCoverLetterMaintenanceInfo;
                newState = { ...state, priorityMail: action.data.uiData, isDirty };
                if (crudData) crudData.ADRCoverLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_ADR_COVER_LETTER_ADD_INFO':
            {
                const crudData = action.data?.masterCrud?.ADRCoverLetterMaintenanceInfo;
                newState = { ...state, addInfo: action.data.uiData, isDirty };
                if (crudData) crudData.ADRCoverLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'RESET_ADR_COVER_LETTER_DIRTY':
                newState = { ...state, isDirty: false };
                break;
            case 'GENERATE_ADR_COVERLETTER_PREVIEW':
                switch (action.status.status) {
                case 'REQUEST':
                    newState = { ...state, isBusy: true }
                    break;
                case 'SUCCESS':
                    const pdfPath = action.responseData.path;
                    /* This error gets propagated to the newly opened window
                    const error = action.responseData.error; */
                    newState = { ...state, isBusy: false, pdfPath }
                    break;
                case 'FAIL':
                    newState = { ...state, isBusy: false, errors: { pdfWriteError: 'PDF preview could not be created due to server error.' } }
                    break;
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
            }
        }
        return newState || defaultADRCoverLetterState;
    }