import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ISelectReasonCodeUIState {
    selectedCode: string;  // Name of code selected in list
    selectedDescription: string;   // Description of the reason code selected in the list

    isBusy: boolean;
    changed: boolean;
}

export interface ISelectReasonCodeUIData {
    index: string;
    value: boolean;
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

interface ISelectReasonCodeData {
    code: string;
    description: string;
};

export const defaultReasonCode: ISelectReasonCodeData = {
    code: '',
    description: '',
};

interface IClickOKData {
    goToFilter: boolean;
};

export const defaultClickOK: IClickOKData = {
    goToFilter: false
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: ISelectReasonCodeUIState = {
    selectedCode: '',
    selectedDescription: '',
    isBusy: false,
    changed: false,
};

export interface ISelectReasonCode extends IApiActionData<MCSelectReasonCode, ISelectReasonCodeData> { }

export interface IRC_FieldData extends IApiActionData<APICF_ListPopulationContainer, ISelectReasonCodeUIData> { }

export const actionCreators = {
    selectReasonCode: (selectInfo: ISelectReasonCode) => createDataAction('RC_SELECT_REASON_CODE', selectInfo),
    setBusy: (fieldInfo: IRC_FieldData) => createDataAction('RC_SETBUSY', fieldInfo),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ISelectReasonCodeUIState, KnownActions> = (state: ISelectReasonCodeUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {

            case 'RC_SELECT_REASON_CODE':
                let newStateData: ISelectReasonCodeUIState = { ...state };
                newStateData.selectedCode = action.data.uiData.code;
                newStateData.selectedDescription = action.data.uiData.description;
                console.log('RC_SELECT_REASON_CODE:' + newStateData.selectedDescription);
                newStateData.changed = (action.data.uiData.description != undefined && action.data.uiData.description.length != 0 ? true : false);
                return newStateData;

            case 'RC_SETBUSY':
                return {
                    ...state,
                    isBusy: action.data.uiData.value,
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}

