import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import styled from 'styled-components';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { ICookies_Config } from '@store/ConfigData';
import { defaultState as defaultUIState } from '@store/api/WorkingGroupDisplayStore';
import { IWorkingGroupDisplayState, IWorkingGroupDisplayActions, IWorkingGroupDisplayActionProps, actionCreators/*, validationCallback */ } from '@store/WorkingGroupDisplay';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { Input, TextArea, Button, Grid, Caret } from 'ui-core';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMGridPager } from '@common/UICLWrappers/ARMGridPager';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ChangeEvent } from 'react';
import { SortHelper, ISortIndicator, SortDataType, SortOrder } from "@scripts/util/SortHelper"
import Big from 'big.js';

interface FilterJsonObject {
    "ClaimIDList": {
        "Claim": {
            "@ID": string,
            "@SequenceNo": number
        }[
        ]
    }
}

interface UiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

interface SelectedClaimIndicator {
    pageNumber: number,
    claimId: string,
    totalCharges: number,
}

interface HeaderProps {
    columns: {
    }
}

export const DetailWrapper = styled.div`
    table {
        width:100%;
    }

    table thead th {
        font-weight:bold;
        width: '300px';
    }

    table tr td {
        background-color:white;
    }
`;

export const GridWrapper = styled.div`

    width:1185px;
    overflow-y: hidden !important;

    .selectedRow{
        background-color:yellow !important;
    }

    .unselectedRow{
        background-color:white;
    }

    #grid-wrapper-claim-list-grid {

        height:200px;

        table tbody tr > td:first-child:not(.select-checkbox){
            padding-left:30px;
        }


        table > thead > tr > th {
/*
            height:32px;
            background-color:#9BA1A9 !important;
            font-weight:bold;
            font-family:"Core Sans C","Century Gothic","AppleGothic",sans-serif;
            position:sticky;
            top:0;
*/
        }


        #thPayer{
            padding-bottom:3.5px;
        }

        table tbody tr > td{
            height:32px !important;
            padding-top:8px;
        }
    }

    table tbody tr:hover{
        background-color:#edf9ff !important;
        cursor:pointer !important;
    }

    max-height:500px;
    overflow-y:scroll;
    background-color:white;
    border: black 1px solid;

    .empty-row{
        background-color:white !important;
    }

    .patientName {
        color: red;
        min-width:250px;
        max-width:250px;
        overflow:hidden;
    }

    .controlNo {
        min-width:250px;
        max-width:250px;
    }

    .totalCharges {
        min-width:150px;
        max-width:150px;
    }

    .typeOfBill {
        min-width:80px;
        max-width:80px;
    }

    .formName {
        min-width:92px;
        max-width:92px;
    }

    .payerName {
        width:300px;
        overflow:hidden;
    }

    table tbody tr > td{
        height:25px !important;
    }

    table tbody tr td div{
        min-height:1px;
    }
`;

interface IComponentProps {
    canEdit: boolean
    canView: boolean,
    title: string,
};

interface IComponentState {
    showUndeleteModal: boolean,
    showDeleteModal: boolean,
    showRRTDeleteModal:boolean,
    showCopyClaimsToTestModal: boolean,
    isBusy: boolean,
    process: any,
    parameter: any,
    claimTitle: string,
    securityClearance: string,
    execNode: string,
    execAction: string,
    execProcess: string,
    execAttribs: string,
    permission: string,
    claims: WGDClaim[],
    filteredClaims: WGDClaim[],
    selectedClaimIndicators: SelectedClaimIndicator[],
    mouseOverClaimId: string,
    sortIndicator: ISortIndicator,
    selectedColumnName: string,
    selectedSortDataType: SortDataType,
    gridPaging: {
        currentPageNumber: number,
        numberOfPages: number,
        recordsPerPage: number,
        totalRecordCount: number
    },
    scrollToTop: boolean,
    isAlertOpen: boolean,
    alertMessage: string
};

interface HeaderProps {
    columns: {

    }
}

interface RowProps {
    record: {
        claimId: string,
        patientName: string,
        controlNo: string,
        totalCharges: number,
        typeOfBill: string,
        formName: string,
        admitDate: string,
        admitDiag: string,
        birthDate: string,
        bridgeFileName: string,
        claimStatus: string,
        eligibilityRejectReason: string,
        facilityId: string,
        financialClass: string,
        payerInd: string,
        payerName: string,
        payerRequestStatus: string,
        primaryDiag: string,
        printClaimAtCls: string,
        releaseDate: string,
        stmtFromDate: string,
        stmtThruDate: string,
        submitDate: string,
        transmitFlag: string
    }
}

export const DEFAULT_STATE: IComponentState = {
    showUndeleteModal: false,
    showDeleteModal: false,
    showRRTDeleteModal: false,
    showCopyClaimsToTestModal: false,
    isBusy: true,
    process: '',
    parameter: {},
    claimTitle: '',
    securityClearance: '',
    execNode: '',
    execAction: '',
    execProcess: '',
    execAttribs: '',
    permission: '',
    claims: [],
    filteredClaims: [],
    selectedClaimIndicators: [],
    mouseOverClaimId: '',
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
        sortDataType: SortDataType.String
    },
    selectedColumnName: 'payerName',
    selectedSortDataType: SortDataType.String,
    gridPaging: {
        currentPageNumber: 1,
        numberOfPages: 1,
        recordsPerPage: 100,
        totalRecordCount: 0
    },
    scrollToTop: false,
    isAlertOpen: false,
    alertMessage: ""
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IWorkingGroupDisplayProps = IMergeApiComponentProps<IWorkingGroupDisplayState, IWorkingGroupDisplayActionProps, IOwnProps>;


/////////////////////////////////////////////////////////////////////////////////////////////

export const ContentWrapper = styled.div`
`;

export const WorkingGroupDisplayContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    min-height: 200px;
    width: 1200px;
    padding: 10px;
`;

export class WorkingGroupDisplay extends React.Component<IWorkingGroupDisplayProps, IComponentState> {

    columnNameMap = [
        { value: 'admitDate', text: 'Admit date', sortDataType: SortDataType.Date },
        { value: 'admitDiag', text: 'Admit diag', sortDataType: SortDataType.String },
        { value: 'birthDate', text: 'Birth date', sortDataType: SortDataType.Date },
        { value: 'bridgeFileName', text: 'Bridge File Name', sortDataType: SortDataType.String },
        { value: 'claimStatus', text: 'Claim Status', sortDataType: SortDataType.String },
        { value: 'eligibilityRejectReason', text: 'Eligibility reject reason', sortDataType: SortDataType.String },
        { value: 'facilityId', text: 'Facility Id', sortDataType: SortDataType.String },
        { value: 'financialClass', text: 'Financial class', sortDataType: SortDataType.String },
        { value: 'payerInd', text: 'Payer ind', sortDataType: SortDataType.String },
        { value: 'payerName', text: 'Payer name', sortDataType: SortDataType.String },
        { value: 'payerRequestStatus', text: 'Payer request status', sortDataType: SortDataType.String },
        { value: 'primaryDiag', text: 'Primary diag', sortDataType: SortDataType.String },
        { value: 'printClaimAtCls', text: 'Print claim at cls', sortDataType: SortDataType.String },
        { value: 'releaseDate', text: 'Release date', sortDataType: SortDataType.Date },
        { value: 'stmtFromDate', text: 'Stmt from date', sortDataType: SortDataType.Date },
        { value: 'stmtThruDate', text: 'Stmt thru date', sortDataType: SortDataType.Date },
        { value: 'submitDate', text: 'Submit date', sortDataType: SortDataType.Date },
        { value: 'transmitFlag', text: 'Transmit flag', sortDataType: SortDataType.String }
    ]

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: '' //set dynamically in render()
    };

    constructor(props: IWorkingGroupDisplayProps) {
        super(props);

        const {
            process, execProcess, execNode, execAction, parameter, title, execAttribs
        } = this.props.location.state;

        this.validateImplementation(process);

        let claimTitle = title;
        if (process === 'reprocessmcarules') {
            claimTitle = 'Reprocess Rules for Manage Claim Assignments';
        }

        this.state = {
            showUndeleteModal: false,
            showDeleteModal: false,
            showRRTDeleteModal: false,
            showCopyClaimsToTestModal: false,
            isBusy: true,
            process,
            claimTitle: claimTitle == undefined || claimTitle == '' ? 'Refine Claims' : claimTitle,
            securityClearance: 'FN_VALIDATE_CLAIMS',
            execNode: execNode == undefined || execNode == '' ? 'MultiClaim' : execNode,
            execAction: execAction == undefined || execAction == '' ? 'Validation' : execAction,
            execProcess: execProcess == undefined || execProcess == '' ? 'View/Edit' : execProcess,
            execAttribs: execAttribs == undefined || execAttribs == '' ? parameter : execAttribs,
            parameter: parameter,
            permission: 'FN_VALIDATE_CLAIMS',
            claims: [],
            filteredClaims: [],
            selectedClaimIndicators: [],
            mouseOverClaimId: '',
            selectedColumnName: 'payerName',
            selectedSortDataType: SortDataType.String,
            sortIndicator: {
                sortColumn: '',
                sortDirection: '',
                sortDataType: SortDataType.String
            },
            gridPaging: {
                currentPageNumber: 1,
                numberOfPages: 1,
                recordsPerPage: 100,
                totalRecordCount: 0
            },
            scrollToTop: false,
            isAlertOpen: false,
            alertMessage: ""
        }

        this.handleSorting = this.handleSorting.bind(this);
        this.handlePaging = this.handlePaging.bind(this);
    }

    // provide parameter validation for processes that are not supported
    validateImplementation(process: string) {
        const supportedProcesses = [
            'view/edit',
            'print',
            'addnote',
            'copyclaimstotest',
            'unreleased',
            'released',
            'deleted',
            'deleted_dep',
            'custom',
            'analysis',
            'compliance',
            'direct',
            'rebill',
            'reedit',
            'dep',
            'depoutsource',
            'checkeligibility',
            'canceleligibility',
            'delete',
            'undelete',
            'manual',
            'submit',
            'reprocessmcarules',
            'reprocesspayerstatusrules',
            'reprocessuserprofiles',
            'secondary',
            'validate',
            'changetransmitd',
            'changetransmits',
            'eligibility',
            'status',
            'claimstatusrequest',
            'deslstatus',
            'holdcodeprocessing',
            'runbridgeroutines',
            'spinoff',
            'reprinteoboutsource',
            'eoboutsource',
            '271',
            '277',
            'printeobdetail',
            'printeobitemized',
            'printeobmnra',
            'printeob',
            'printmran',
            'portal',
            'printcaltc',
            'eob',
            'des',
            'viewx12claim',
            'export28',
            'export7m',
            'exportms4',
            'delete/rrt',
            'validate/rrt',
            'viewedit/rrt',
            'rsrtcustom',
            'reprinthcdclaimeob',
        ];

        if (!supportedProcesses.includes(process.toLowerCase())) {
            const message = `process ${process} is not supported`;
            alert(message);
        }
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }

        /*
            since they are pushing the page using history, we have to get the data from the incoming state...
            var processValue: string = this.props.history.location.state?.process;
            let title: any = this.props.history.location.state?.title;
            let actionOf: any = this.props.history.location.state?.execAction;
            let nodeOf: any = this.props.history.location.state?.execNode;
            let instructOf: any = this.props.history.location.state?.extraInstruction;
        debugger;
        */

        this.getClaims();

        // JMC needed this.props.action.configData.getConfigData({ cookies: [{ name: "FormTypes" }], config: [{ name: "EnableTenetFeatures" }] });
        // JMC  this.props.action.api.loadData('/api/data/GetClaimFilterFormData', this.validationCallback.bind(this), this.errorCallback.bind(this));
    }

    setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    public errorCallback(dataStatus: string): boolean {
        var str = 'WorkingGroupDisplay - An error occurred processing call for ' + this.props.title + ".  Error: " + dataStatus
        console.error(str);
        this.onDisplayWorkingGroupDisplayError(str);

        return true;
    }

    public onDisplayWorkingGroupDisplayError(msg: string) {
        // TODO this.props.action.ui.displayErrorMessage({ api: this.props.dataStore.api.data, uiData: { value: msg } });
    }

    getClaims() {
        const {
            gridPaging,
            process
        } = this.state;
        if (process === "viewx12claim") {
            this.setState({ selectedColumnName:"admitDate" })
        }
        const {
            testMode
        } = this.props.location.state;

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        let clientId = sessionStorage.getItem("Client")?.split(' ')[0];
        xmlData.ClientID = clientId || undefined;
        xmlData.TestMode = testMode;
        // show spinner before fetch
        this.setState({ isBusy: true });

        fetch(URLs.api + '/api/data/GetUserList/' + gridPaging.currentPageNumber,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                if (response.status === 200) {
                    // get the claims
                    let myClaims: WGDClaim[] = data.claims;

                    myClaims.forEach(x => {
                        // comma required for empty names per tim
                        x.patientName = x.lastName + ', ' + x.firstName;
                    });

                    // build the gridPaging numbers
                    let numberOfPages = 1;
                    numberOfPages = Math.ceil(parseInt(data.totalClaims) / gridPaging.recordsPerPage);
                    if (numberOfPages < 1) numberOfPages = 1;


                    this.setState({
                        claims: myClaims,
                        filteredClaims: myClaims,
                        mouseOverClaimId: myClaims[0].claimId,
                        gridPaging: {
                            currentPageNumber: gridPaging.currentPageNumber,
                            numberOfPages: numberOfPages,
                            recordsPerPage: gridPaging.recordsPerPage,
                            totalRecordCount: data.totalClaims
                        },
                        isBusy: false // hide spinner
                    }, () => {
                        // 05/05/2021 - jason has indicated that the raw rows will always be returned in the appropriate sort order
                        /*
                    this.handleSorting({
                        sortColumn: 'patientName',
                        sortDirection: 'up',
                        sortDataType: SortDataType.String
                    });
                        */
                    }
                    );
                    return;
                } else if (response.status === 306) {
                    return;
                } else {
                    let errorMessage =
                        `WGD: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`
                    throw new Error(errorMessage);
                }
            })
            .catch(error => {
                console.error('WGD: ' + error);
            })
            .finally(() => {
                this.setState({ isBusy: false });
            });
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    getGridColumnHeaders() {
        const columns: UiclColumn[] = [
            {
                dataName: "patientName",
                text: 'Patient name',
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "controlNo",
                text: "Control no",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "totalCharges",
                text: "Total charges",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "typeOfBill",
                text: "TOB",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "formName",
                text: "Form",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
        ];

        const selectedColumn = this.getSelectedColumn();
        columns.push(
            selectedColumn
        );

        return new Set(columns);
    }

    getSelectedColumn() {
        const selectableColumns: UiclColumn[] = [
            {
                dataName: "admitDate",
                text: "Admit date",
            },
            {
                dataName: "admitDiag",
                text: "Admit diag",
            },
            {
                dataName: "birthDate",
                text: "Birth date",
            },
            {
                dataName: "bridgeFileName",
                text: "Bridge file name",
            },
            {
                dataName: "claimStatus",
                text: "Claim status",
            },
            {
                dataName: "eligibilityRejectReason",
                text: "Eligibility reject reason",
            },
            {
                dataName: "facilityId",
                text: "Facility id",
            },
            {
                dataName: "financialClass",
                text: "Financial class",
            },
            {
                dataName: "payerInd",
                text: "Payer ind",
            },
            {
                dataName: "payerName",
                text: "Payer Name",
            },
            {
                dataName: "payerRequestStatus",
                text: "Payer Request Status",
            },
            {
                dataName: "primaryDiag",
                text: "Primary diag",
            },
            {
                dataName: "printClaimAtCls",
                text: "Print claim at cls",
            },
            {
                dataName: "releaseDate",
                text: "Release date",
            },
            {
                dataName: "stmtFromDate",
                text: "Statement from date",
            },
            {
                dataName: "stmtThruDate",
                text: "Statement thru date",
            },
            {
                dataName: "submitDate",
                text: "Submit date",
            },
            {
                dataName: "transmitFlag",
                text: "Transmit flag",
            },
        ]

        selectableColumns.map(x => {
            x.sortable = true;
            x.cellType = 'text';
            x.isSorted = 0
        });
        return selectableColumns.filter(x => x.dataName === this.state.selectedColumnName)[0];
    }

    handlePaging(pageNumber: number) {
        const {
            claims,
            gridPaging
        } = this.state;

        // get the requested page of records
        const indexStart = (pageNumber - 1) * gridPaging.recordsPerPage;
        const indexEnd = indexStart + gridPaging.recordsPerPage;
        const page = claims.slice(indexStart, indexEnd);

        this.setState({
            filteredClaims: page,
            gridPaging: {
                currentPageNumber: pageNumber,
                numberOfPages: gridPaging.numberOfPages,
                recordsPerPage: gridPaging.recordsPerPage,
                totalRecordCount: gridPaging.totalRecordCount
            },
            // scroll to top of new grid page
            scrollToTop: true,
        }, () => {
            this.getClaims();
            // restore user scroll pos persistence
            this.setState({ scrollToTop: false })
        });
    }

    handleSorting(sortIndicator: ISortIndicator) {
        const {
            filteredClaims
        } = this.state;

        let sortedClaims = SortHelper.Sort(filteredClaims, sortIndicator);

        this.setState({
            sortIndicator,
            filteredClaims: sortedClaims
        });
    }

    displaySelectedColumn(event: ChangeEvent<HTMLSelectElement>) {
        const selectedSortDataType = this.getSelectedSortDataType(event.target.value);

        this.setState({
            selectedColumnName: event.target.value,
            selectedSortDataType
        });
    }

    getColumnNameOptions() {
        let columnNameOptions: { value: string, text: string }[] = [];
        this.columnNameMap.forEach(x => {
            columnNameOptions.push({
                value: x.value,
                text: x.text
            })
        });
        return columnNameOptions;
    }

    getSelectedSortDataType(columnName: string) {
        const selectedColumnMap = this.columnNameMap.find(x => x.value === columnName);
        return selectedColumnMap?.sortDataType || SortDataType.String;
    }

    getCustomHeader() {
        const {
            sortIndicator,
            selectedSortDataType
        } = this.state;

        const columnNames = this.getColumnNameOptions();
        const options = columnNames.map(x => <option key={x.value} value={x.value}>
            {x.text}
        </option>);

        const ddlColumnNames = <select value={this.state.selectedColumnName}
            onChange={x => { this.displaySelectedColumn(x) }}>{options}</select>

        const header = ({ columns }: HeaderProps) => <thead><tr key={Math.random()}>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="patientName"
                        text="Patient name"
                        cellType="text"
                        sortable={true}
                        // choose from String, Number or Date
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="controlNo"
                        text="Control no"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="totalCharges"
                        text="Total charges"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Number}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="typeOfBill"
                        text="TOB"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="formName"
                        text="Form"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName={this.state.selectedColumnName}
                        text="Payer name"
                        cellType="select"
                        sortable={true}
                        sortDataType={selectedSortDataType}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        select={ddlColumnNames}
                    />
                </React.Fragment>
            }
        </tr></thead>

        return header;
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {

        const {
            filteredClaims,
            selectedClaimIndicators,
            gridPaging
        } = this.state;

        // get the index of the selected row from the selected claim indicators
        let selectedStartIndex = selectedClaimIndicators
            .map(function (x) { return x.claimId; })
            .indexOf(event.currentTarget.id);

        let newSelectedClaimIndicators: SelectedClaimIndicator[] = selectedClaimIndicators;

        // if the clicked row was previously selected
        if (selectedStartIndex > -1) {
            // then remove it
            newSelectedClaimIndicators.splice(selectedStartIndex, 1);
        } else {
            // get a handle to the selected claim
            const claim = filteredClaims.find(x => x.claimId == event.currentTarget.id);

            let totalCharges = 0;
            if (claim) totalCharges = parseFloat(claim.totalCharges);

            // add a selected claim indicator for the selected row
            newSelectedClaimIndicators.push({
                pageNumber: gridPaging.currentPageNumber,
                claimId: event.currentTarget.id,
                totalCharges
            });
        }

        // update state with the latest set of selected claim indicators
        this.setState({
            selectedClaimIndicators: newSelectedClaimIndicators
        });
    }

    onMouseOver(claimId: string) {
        const {
            mouseOverClaimId
        } = this.state;

        if (mouseOverClaimId == claimId) return;

        this.setState({
            mouseOverClaimId: claimId,
            scrollToTop: false,
        });
    }

    getCustomRow() {
        const {
            selectedColumnName,
            selectedClaimIndicators,
        } = this.state;

        const row = ({ record }: RowProps) =>
            <tr id={record.claimId} key={record.claimId} role="button"
                className={selectedClaimIndicators.find(x => x.claimId === record.claimId) ? 'selected' : ''}
                onMouseOver={() => this.onMouseOver(record.claimId)}
                onClick={(x) => this.onRowClick(x)}>
                {
                    <React.Fragment>
                        <ARMRowCell value={record.patientName} style={{ width: 250, overflow: 'hidden' }} />
                        <ARMRowCell value={record.controlNo} />
                        <ARMRowCell value={record.totalCharges} />
                        <ARMRowCell value={record.typeOfBill} />
                        <ARMRowCell value={record.formName} />
                        {selectedColumnName === 'payerName' && <ARMRowCell value={record.payerName} style={{ overflow: 'hidden' }} />}
                        {selectedColumnName === 'admitDate' && <ARMRowCell value={record.admitDate} />}
                        {selectedColumnName === 'admitDiag' && <ARMRowCell value={record.admitDiag} />}
                        {selectedColumnName === 'birthDate' && <ARMRowCell value={record.birthDate} />}
                        {selectedColumnName === 'bridgeFileName' && <ARMRowCell value={record.bridgeFileName} />}
                        {selectedColumnName === 'claimStatus' && <ARMRowCell value={record.claimStatus} />}
                        {selectedColumnName === 'eligibilityRejectReason' && <ARMRowCell value={record.eligibilityRejectReason} />}
                        {selectedColumnName === 'facilityId' && <ARMRowCell value={record.facilityId} />}
                        {selectedColumnName === 'financialClass' && <ARMRowCell value={record.financialClass} />}
                        {selectedColumnName === 'payerInd' && <ARMRowCell value={record.payerInd} />}
                        {selectedColumnName === 'payerRequestStatus' && <ARMRowCell value={record.payerRequestStatus} />}
                        {selectedColumnName === 'primaryDiag' && <ARMRowCell value={record.primaryDiag} />}
                        {selectedColumnName === 'printClaimAtCls' && <ARMRowCell value={record.printClaimAtCls} />}
                        {selectedColumnName === 'releaseDate' && <ARMRowCell value={record.releaseDate} />}
                        {selectedColumnName === 'stmtFromDate' && <ARMRowCell value={record.stmtFromDate} />}
                        {selectedColumnName === 'stmtThruDate' && <ARMRowCell value={record.stmtThruDate} />}
                        {selectedColumnName === 'submitDate' && <ARMRowCell value={record.submitDate} />}
                        {selectedColumnName === 'transmitFlag' && <ARMRowCell value={record.transmitFlag} />}
                    </React.Fragment>
                }
            </tr>
        return row;
    }

    getDetailPanel() {
        const {
            filteredClaims,
            mouseOverClaimId
        } = this.state;

        const mouseOverClaim = filteredClaims.filter(x => x.claimId == mouseOverClaimId)[0];

        return (
            <DetailWrapper>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Last Name
                            </th>
                            <th>
                                First Name
                            </th>
                            <th>
                                Birth Date
                            </th>
                            <th>
                                Control No
                            </th>
                            <th>
                                Total Charges
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {mouseOverClaim && mouseOverClaim.lastName}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.firstName}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.birthDate}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.controlNo}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.totalCharges}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Claim ID
                            </th>
                            <th>
                                Type of Bill
                            </th>
                            <th>
                                Form Name
                            </th>
                            <th>
                                Submit Date
                            </th>
                            <th>
                                Release Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {mouseOverClaim && mouseOverClaim.claimId}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.typeOfBill}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.formName}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.submitDate}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.releaseDate}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Payer Name
                            </th>
                            <th>
                                Claim Status
                            </th>
                            <th>
                                Admit Date
                            </th>
                            <th>
                                Stmt From Date
                            </th>
                            <th>
                                Stmt Thru Date
                            </th>
                            <th>
                                Admit Diag
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {mouseOverClaim && mouseOverClaim.payerName}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.claimStatus}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.admitDate}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.stmtFromDate}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.stmtThruDate}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.admitDiag}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Primary Diag
                            </th>
                            <th>
                                Facility ID
                            </th>
                            <th>
                                Financial Class
                            </th>
                            <th>
                                Payer Ind
                            </th>
                            <th>
                                Print Claim at CLS
                            </th>
                            <th>
                                Transmit Flag
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {mouseOverClaim && mouseOverClaim.primaryDiag}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.facilityID}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.financialClass}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.payerInd}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.printClaimAtCLS}
                            </td>
                            <td>
                                {mouseOverClaim && mouseOverClaim.transmitFlag}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Bridge File Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {mouseOverClaim && mouseOverClaim.bridgeFileName}&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DetailWrapper>
        );
    }

    selectThese() {
        const {
            filteredClaims,
            selectedClaimIndicators,
            gridPaging
        } = this.state;

        const newSelectedClaimIndicators = selectedClaimIndicators;
        let isPushed = false;

        // for each claim in the current grid page
        filteredClaims.forEach(x => {

            isPushed = selectedClaimIndicators.filter(y =>
                y.pageNumber == gridPaging.currentPageNumber &&
                y.claimId == x.claimId &&
                y.totalCharges == parseFloat(x.totalCharges)
            ).length > 0;

            if (!isPushed) {
                // add a selected claim indicator
                newSelectedClaimIndicators.push({
                    pageNumber: gridPaging.currentPageNumber,
                    claimId: x.claimId,
                    totalCharges: parseFloat(x.totalCharges)
                })
            }
        });

        // replace the selected claim indicators in state
        this.setState({
            selectedClaimIndicators: newSelectedClaimIndicators
        });
    }

    selectNone() {
        const {
            selectedClaimIndicators,
            gridPaging
        } = this.state;

        // select none should only deselect the rows for the current page
        const newSelectedClaimIndicators: SelectedClaimIndicator[] =
            selectedClaimIndicators.filter(x => x.pageNumber != gridPaging.currentPageNumber);

        this.setState({
            selectedClaimIndicators: newSelectedClaimIndicators
        });
    }

    public getSummaryPanel() {
        const {
            gridPaging,
            filteredClaims,
            selectedClaimIndicators
        } = this.state;

        let pageRowStartNumber = 0;
        if (gridPaging.currentPageNumber > 1) {
            pageRowStartNumber = ((gridPaging.currentPageNumber * gridPaging.recordsPerPage) - gridPaging.recordsPerPage) + 1;
        } else if (filteredClaims.length) {
            pageRowStartNumber = 1;
        }

        let pageRowEndNumber = 0;
        if (gridPaging.totalRecordCount < gridPaging.recordsPerPage) {
            pageRowEndNumber = gridPaging.totalRecordCount;
        } else {
            pageRowEndNumber =
                gridPaging.currentPageNumber * gridPaging.recordsPerPage
        }

        // calculate 'viewing total worth'
        const viewingTotalWorth = filteredClaims.reduce(function (accumulator, claim) {
            return Big(accumulator).plus(claim.totalCharges).toNumber();
        }, 0)

        // calculate 'selected total worth'
        const selectedTotalWorth = selectedClaimIndicators.reduce(function (accumulator, claimIndicator) {
            return Big(accumulator).plus(claimIndicator.totalCharges).toNumber();
        }, 0)

        return (
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Viewing</td>
                            <td>
                                <span style={{ backgroundColor: 'white' }}>
                                    {pageRowStartNumber} - {pageRowEndNumber}
                                </span>
                            </td>
                            <td>worth</td>
                            <td>
                                <span style={{ backgroundColor: 'white' }}>
                                    ${viewingTotalWorth.toFixed(2)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Selected</td>
                            <td>
                                <span style={{ backgroundColor: 'white' }}>
                                    {selectedClaimIndicators.length} / {gridPaging.totalRecordCount}
                                </span>
                            </td>
                            <td>worth</td>
                            <td style={{ textAlign: 'left' }}>
                                <div style={{ backgroundColor: 'white', width: '100%' }}>
                                    ${selectedTotalWorth.toFixed(2)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    getActionButtons() {
        const {
            selectedClaimIndicators,
            process,
            showUndeleteModal,
            showDeleteModal,
            showRRTDeleteModal,
            showCopyClaimsToTestModal,
        } = this.state;

        return (
            <div>
                <Button
                    domID="btnGoBack"
                    name="Go Back"
                    buttonType="deEmphasizedReversed"
                    size="small"
                    type="button"
                    onClick={() => { window.sessionStorage.setItem("tabType", "Advanced"); window.history.go(-1) }}
                />
                <Button
                    domID="btnContinue"
                    name={process == "view/edit" || process=="viewedit/rrt" ? "View Claims" : "Continue"}
                    buttonType="deEmphasizedReversed"
                    size="small"
                    type="button"
                    disabled={selectedClaimIndicators.length < 1}
                    // NOTE: further research needed, trying to avoid user spamming the button and creating multiple jobs
                    onClick={(e: any) => {
                        if (!e.currentTarget.disabled) {
                            e.currentTarget.disabled = true;

                            if (process.toLowerCase() == 'undelete') {
                                this.setState({ showUndeleteModal: !showUndeleteModal })
                                e.currentTarget.disabled = false;
                            }
                            else if (process.toLowerCase() == 'delete') {
                                this.setState({ showDeleteModal: !showDeleteModal })
                                e.currentTarget.disabled = false;
                            }
                            else if (process.toLowerCase() == 'delete/rrt') {
                                this.setState({ showRRTDeleteModal: !showRRTDeleteModal })
                                e.currentTarget.disabled = false;
                            }
                            else if (process.toLowerCase() == 'copyclaimstotest') {
                                this.setState({ showCopyClaimsToTestModal: !showCopyClaimsToTestModal })
                                e.currentTarget.disabled = false;
                            }
                            else {
                                this.onContinue()
                            }
                        }

                    }}
                />

                <Button
                    domID="btnCancel"
                    name="Cancel"
                    buttonType="deEmphasizedReversed"
                    size="small"
                    type="button"
                    onClick={() => { this.props.history.push("/LandingPage"); }}
                />

                <ModalConfirmation
                    title={"Undelete Claim(s)"}
                    isOpen={showUndeleteModal}
                    message={`Are you sure that you want to restore the selected claim(s)`}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={true}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ showUndeleteModal: !showUndeleteModal })}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => { this.onContinue() }}
                />
                <ModalConfirmation
                    title={"Delete Claim(s)"}
                    isOpen={showDeleteModal}
                    message={`Are you sure that you want to delete the selected claim(s)?`}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={true}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ showDeleteModal: !showDeleteModal })}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => { this.onContinue() }}
                />
                <ModalConfirmation
                    title={"Delete Regulatory Reporting Claim(s)"}
                    isOpen={showRRTDeleteModal}
                    message={`Are you sure that you want to delete the selected Regulatory Reporting claim(s)?`}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={true}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ showRRTDeleteModal: !showRRTDeleteModal })}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => { this.onContinue() }}
                />
                <ModalConfirmation
                    title={"Copy Claims To Test"}
                    isOpen={showCopyClaimsToTestModal}
                    message={`Are you sure that you want to copy the selected claim(s) to test?`}
                    okText={"Yes"}
                    cancelText={"No"}
                    showDefaultClose={true}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ showCopyClaimsToTestModal: !showCopyClaimsToTestModal })}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => { this.onContinue() }}
                />
            </div>
        );
    }

    getFilterJsonObject() {
        const {
            selectedClaimIndicators
        } = this.state;

        let filterJsonObject: FilterJsonObject =
        {
            "ClaimIDList": {
                "Claim": [
                ]
            }
        }

        selectedClaimIndicators.forEach((x, i) => {
            const claim = {
                "@ID": x.claimId,
                "@SequenceNo": i + 1
            }
            filterJsonObject["ClaimIDList"]["Claim"].push(claim);
        });

        return JSON.stringify(filterJsonObject);
    }

    onContinue() {
 
        let switchOf: string = this.state.execNode;
        if (switchOf == "view/edit") {
            this.viewClaims();
            return;
        }
        if (switchOf == "viewedit/rrt") {
            this.viewClaims();
            return;
        }
        else if (this.state.process == "viewx12claim") {
            this.viewClaims();
            return;
        }
        else if (this.state.process == "rsrtcustom") {
            this.executeReportProcess();
            return;
        }
        /* else if (this.state.process == 'deleted_dep') {
            switchOf = 'MultiClaim'
        }*/
        switch (switchOf) {
            case "MultiClaim":
            case "PaperClaim":
                this.executeMultiClaimProcess();
                break;
            case "ClaimChange":
                this.executeClaimChangeProcess();
                break;
            case "Report":
                this.executeReportProcess();
                break;
            case "ChangeTransmit":
                this.executeChangeTransmitProcess();
                break;
            case "ChangeClaimState":
            case "Export":
                alert('todo implement');
                break;
        }
    }

    viewClaims() {
        this.setWorkingGroup();
    }

    displayClaimViewer() {

        // cfProps = claim filter props = props passed from CF into WGD
        const cfProps = this.props.location.state;

        // cfProps = CF postData
        this.props.history.push('/CFI/Default', cfProps);
    }

    redirectToAdminPortal() {
        fetch(URLs.api + '/api/data/Claims/FileCountFromFilter',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                }
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                console.log("DataEntered in Data", data);
                if (data === 1) {
                    fetch(URLs.api + '/api/data/VerifyX12File',
                        {
                            method: 'GET',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': `${getRawToken()}`
                            }
                        })
                        .then(async (response) => {
                            // get json response here
                            let internalData: any = await response.json();
                            if (internalData.toString().indexOf("Not Found") == -1)
                            {
                                if (this.GoToProcess) {
                                    this.GoToProcess.current?.submit();
                                }
                            }
                            else {
                                this.setState({ isAlertOpen: true, alertMessage: "File Not Found", selectedClaimIndicators: [] });
                                return false;
                            }
                        })
                        .catch(error => {
                            console.error('ClaimFilter: ' + error);
                            this.errorCallback("Unable to process filter.");
                        })
                        .finally(() => {
                            //this.setBusy(false);
                        });
                }
                else if (data === 0) {
                    this.setState({ isAlertOpen: true, alertMessage: "The selected items did not return a claim file, please refine your filter", selectedClaimIndicators: [] });
                    return false;
                }
                else {
                    this.setState({ isAlertOpen: true, alertMessage: "Too many results returned, please narrow your search criteria or use refine to select a single file", selectedClaimIndicators: [] });
                    return false;
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to process filter.");
            })
            .finally(() => {
                //this.setBusy(false);
            });
    }

    setWorkingGroup() {
        /*
         var objResponse = XMLCallBack('<Filter Action="SetWorkingGroup" Type="Claim" Process="View/Edit">' + GetWorkingGroup() + '</Filter>', false);
         if (objResponse.responseText == "" || objResponse.responseText.indexOf('RUA=') != -1) {
             goTo("/CFI/Default.asp");
         }
         */

        const {
            testMode
        } = this.props.location.state;

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        let clientId = sessionStorage.getItem("Client")?.split(' ')[0];
        xmlData.ClientID = clientId || undefined;
        xmlData.Process = this.state.process === "viewx12claim" ? "viewx12claim" : "View/Edit";
        xmlData.Type = "Claim";
        xmlData.ViewOrigin = '/WorkingGroupDisplay.asp';  // see note in type def
        xmlData.TestMode = testMode;
        xmlData.FilterJsonData = this.getFilterJsonObject();

        fetch(URLs.api + '/api/data/SetWorkingGroup',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`,
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();

                if (response.status === 306) {
                    // this.onAlert(data.message);  // error is in message
                    return;
                } else if (response.status === 200) {
                    // this.displayClaimViewer(data);
                     if (this.state.process !== "viewx12claim")
                        this.displayClaimViewer();
                    else
                        this.redirectToAdminPortal();
                } else {
                    throw new Error(`ClaimFilter: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`);
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                //this.errorCallback("Unable to process filter.");
            })
            .finally(() => {
                //this.setBusy(false);
            });
    }

    public async executeMultiClaimProcess() {
        // todo: replace all the places we repeatedly calculate this with this var
        const lcExecProcess = this.state.execProcess.toLowerCase();
        // AddNote example of what we used to send before UICL:
        //<MultiClaim Action="AddNote" Type="Claim" Process="AddNote" ParamString="	une autre note	UNE AUTRE	">	une autre note	UNE AUTRE
        //    <ClaimIDList><Claim ID="4F55C3A4-5A6E-44DD-AB7E-AB036856A5F4" SequenceNo="7" /></ClaimIDList>
        //</MultiClaim>

        var xmlData: APIXMLCB_XmlCallBackModel = {};


        // validations on inputs before making call - i do not know that any of these are required
        if (!this.state.execAction || (this.state.execAction && this.state.execAction.length == 0))
            return;


        var jsonData: any = {};
        jsonData.MultiClaim = {};
        jsonData.MultiClaim['@Action'] = this.state.execAction;
        jsonData.MultiClaim['@Type'] = "Claim";  // hardcoded in filter.asp
        jsonData.MultiClaim['@Process'] = this.state.execProcess;

        if (this.state.execProcess.toLowerCase() == 'rebill' || this.state.execProcess.toLowerCase() == 'delete')
            jsonData.MultiClaim['@ReasonCode'] = Object.keys(this.state.execAttribs).length > 0 ? this.state.execAttribs : '';
        else if (this.state.execAttribs && this.state.execAttribs.length > 0 && this.state.execAction !== "SpinOffClaim")
            jsonData.MultiClaim['@ParamString'] = this.state.execAttribs;
        else if (this.state.execAction === "SpinOffClaim") {
            jsonData.MultiClaim['@Param'] = this.state.execAttribs;
        }
        if (['dep', 'deleted_dep', 'depoutsource', 'reprinthcdclaimeob'].includes(lcExecProcess)) {
            if (this.state.parameter && this.state.parameter?.ReportRequests) {
                jsonData.MultiClaim.ReportRequests = this.state.parameter?.ReportRequests;
            }
        }

        var claimIdList = this.getFilterJsonObject();
        var jsonClaimIdList = JSON.parse(claimIdList);
        jsonData.MultiClaim.ClaimIDList = jsonClaimIdList.ClaimIDList;

        xmlData.Action = this.state.execAction ? this.state.execAction : "";
        xmlData.Process = this.state.execProcess ? this.state.execProcess : "";
        xmlData.ViewOrigin = '/Claims/workinggroupdisplay.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

      //  console.log(JSON.stringify(jsonData, null, 2))
      //  console.log(xmlData)

        //this.setBusy(true); 
        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    console.log('WorkingGroupDisplay:status 200');
                    this.goToHomePage();
                    return;
                } else {
                    console.log('WorkingGroupDisplay:status' + response.status + ' ' + response.statusText);
                    throw new Error('WorkingGroupDisplay: error in PostMultiClaim: ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('WorkingGroupDisplay: ' + error);
                this.errorCallback("WorkingGroupDisplay: error in PostMultiClaim.");
            })
            .finally(() => {
                console.log('WorkingGroupDisplay:finally');
                //this.setBusy(false);
            });
    }

    public async executeReportProcess() {
        var xmlData: APIXMLCB_XmlCallBackModel = {};

        if (this.state.execNode != 'Report' ) {
            return;
        }

        // see componenentDidMount for comments on this...
        // what is on the wire, coming in
        //var processValue: string = this.props.history.location.state?.process;
        //let title: any = this.props.history.location.state?.title;
        //let actionOf: any = this.props.history.location.state?.execAction;
        //let nodeOf: any = this.props.history.location.state?.execNode;
        //let instructOf: any = this.props.history.location.state?.extraInstruction;

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.state.execAction || (this.state.execAction && this.state.execAction.length == 0))
            return;

        var jsonData: any = {};
        jsonData.Report = {}; // execNode
        jsonData.Report['@Action'] = this.props.history.location.state?.execAction;
        jsonData.Report['@Type'] = 'Claim';  // hardcoded in filter.asp
        jsonData.Report['@Process'] = this.props.history.location.state.process;
        //jsonData.Report['@ParamString'] = this.state.execAttribs;

        if (this.state.parameter && this.state.parameter?.ReportRequests) {
            jsonData.Report.ReportRequests = this.state.parameter?.ReportRequests;
        }

        var claimIdList = this.getFilterJsonObject();
        var jsonClaimIdList = JSON.parse(claimIdList);
        jsonData.Report.ClaimIDList = jsonClaimIdList.ClaimIDList;

        xmlData.Action = this.state.execAction ? this.state.execAction : '';
        xmlData.ViewOrigin = '/Claims/workinggroupdisplay.asp';  // see note in type def
        xmlData.Process = this.props.history.location.state.process;
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        console.log('** executeApiReportCreateProcess(WGD) clicked **');
        console.log('action[' + this.state.execAction + '] process[' + this.state.execProcess + '] title[' + this.state.claimTitle + '] node[' + this.state.execNode + ']');
        console.log(jsonData.Report);

        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    console.log('WorkingGroupDisplay:status 200');
                    this.goToHomePage();
                    return;
                } else {
                    console.log('WorkingGroupDisplay:status' + response.status + ' ' + response.statusText);
                    throw new Error('WorkingGroupDisplay: error in PostMultiClaim: ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('WorkingGroupDisplay: ' + error);
                this.errorCallback('WorkingGroupDisplay: error in PostMultiClaim.');
            })
            .finally(() => {
                console.log('WorkingGroupDisplay:finally');
            });

    }

    public async executeChangeTransmitProcess() {
        var xmlData: APIXMLCB_XmlCallBackModel = {};

        if (this.state.execNode != "ChangeTransmit") {
            return;
        }

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.state.execAction || (this.state.execAction && this.state.execAction.length == 0))
            return;


        var jsonData: any = {};
        jsonData.ChangeTransmit = {};
        jsonData.ChangeTransmit['@Action'] = this.state.execAction;
        jsonData.ChangeTransmit['@Type'] = "Claim";  // hardcoded in filter.asp
        jsonData.ChangeTransmit['@Process'] = this.state.execProcess;

        var claimIdList = this.getFilterJsonObject();
        var jsonClaimIdList = JSON.parse(claimIdList);
        jsonData.ChangeTransmit.ClaimIDList = jsonClaimIdList.ClaimIDList;

        xmlData.Action = this.state.execAction ? this.state.execAction : "";
        xmlData.Process = this.state.execProcess ? this.state.execProcess : "";
        xmlData.ViewOrigin = '/Claims/workinggroupdisplay.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        console.log(JSON.stringify(jsonData, null, 2))
        console.log(xmlData)

        //this.setBusy(true); 
        await fetch(URLs.api + '/api/data/PostChangeTransmit',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    console.log('WorkingGroupDisplay:status 200');
                    this.goToHomePage();
                    return;
                } else {
                    console.log('WorkingGroupDisplay:status' + response.status + ' ' + response.statusText);
                    throw new Error('WorkingGroupDisplay: error in PostChangeTransmit: ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('WorkingGroupDisplay: ' + error);
                this.errorCallback("WorkingGroupDisplay: error in PostChangeTransmit.");
            })
            .finally(() => {
                console.log('WorkingGroupDisplay:finally');
                //this.setBusy(false);
            });
    }

    public async executeClaimChangeProcess() {
        // AddNote example of what we used to send before UICL:
        //<ClaimChange Action="AddNote" Type="Claim" Process="AddNote" ParamString="	une autre note	UNE AUTRE	">	une autre note	UNE AUTRE
        //    <ClaimIDList><Claim ID="4F55C3A4-5A6E-44DD-AB7E-AB036856A5F4" SequenceNo="7" /></ClaimIDList>
        //</ClaimChange>
        var xmlData: APIXMLCB_XmlCallBackModel = {};

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.state.execAction || (this.state.execAction && this.state.execAction.length == 0))
            return;

        var jsonData: any = {};
        jsonData.ClaimChange = {};
        jsonData.ClaimChange['@Action'] = this.state.execAction;
        jsonData.ClaimChange['@Type'] = "Claim";  // hardcoded in filter.asp
        jsonData.ClaimChange['@Process'] = this.state.execProcess;

        if (this.state.execProcess.toLowerCase() == 'rebill' || this.state.execProcess.toLowerCase() == 'delete')
            jsonData.ClaimChange['@ReasonCode'] = Object.keys(this.state.execAttribs).length > 0 ? this.state.execAttribs : '';
        else if (this.state.execAttribs && this.state.execAttribs.length > 0 && this.state.execAction !== "SpinOffClaim")
            jsonData.ClaimChange['@ParamString'] = this.state.execAttribs;
        else if (this.state.execAction === "SpinOffClaim") {
            jsonData.ClaimChange['@Param'] = this.state.execAttribs;
        }

        var claimIdList = this.getFilterJsonObject();
        var jsonClaimIdList = JSON.parse(claimIdList);
        jsonData.ClaimChange.ClaimIDList = jsonClaimIdList.ClaimIDList;

        xmlData.Action = this.state.execAction ? this.state.execAction : "";
        xmlData.Process = this.state.execProcess ? this.state.execProcess : "";
        xmlData.ViewOrigin = '/Claims/workinggroupdisplay.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        console.log(JSON.stringify(jsonData, null, 2))
        console.log(xmlData)

        //this.setBusy(true); 
        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    console.log('WorkingGroupDisplay:status 200');
                    this.goToHomePage();
                    return;
                } else {
                    console.log('WorkingGroupDisplay:executeClaimChangeProcess status' + response.status + ' ' + response.statusText);
                    throw new Error('WorkingGroupDisplay:executeClaimChangeProcess error in PostMultiClaim: ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('WorkingGroupDisplay:executeClaimChangeProcess ' + error);
                this.errorCallback("WorkingGroupDisplay:executeClaimChangeProcess error in PostMultiClaim.");
            })
            .finally(() => {
                console.log('WorkingGroupDisplay:executeClaimChangeProcess finally');
                //this.setBusy(false);
            });
    }

    public goToHomePage() {
        this.props.history.push("/LandingPage");
    }

    protected GoToProcess = React.createRef<HTMLFormElement>();
    public renderLegacyGoTo() {
        return (
            <form ref={this.GoToProcess} action={"/Administration/AdminPortal.asp"} name="GoToProcess" method={"POST"}>
                <input type="hidden" name="Process" value={"viewx12claim"} />
            </form>
        );
    }

    public render() {
        const {
            filteredClaims,
            gridPaging,
            isBusy,
            scrollToTop,
            claimTitle
        } = this.state;

        const columns = this.getGridColumnHeaders();
        const header = this.getCustomHeader();
        const row = this.getCustomRow();
        const detailPanel = this.getDetailPanel();

        var instruction = <React.Fragment>Claims are displayed in groups of 100. You can sort each group, pick some or all of them, and move on to the next group to pick up claims from that group. Work through all of the groups until you have selected all the claims you need then click Continue."
            </React.Fragment>;
        const buttons = this.getActionButtons();
        const summaryPanel = this.getSummaryPanel();
        const title = "Refine Claim List >> " + claimTitle;

        return (
            <>
                <DialogWrapper
                    title={title}
                    instruction={instruction}
                    helpUrl='/Support/Help/HELP_RefineGroup.htm'
                    buttons={buttons}
                    isBusy={isBusy}
                >
                    <ContentWrapper id={"content_wrapper_workinggroupdisplay"}>
                        <WorkingGroupDisplayContent>
                            <GridWrapper>
                                <ARMGrid
                                    records={filteredClaims}
                                    columns={columns}
                                    domID='claim-list-grid'
                                    isFixedHeader={true}
                                    maxHeight='400px'
                                    headerComponent={header}
                                    rowComponent={row}
                                    emptyGridMessage=' '
                                    // grid works without selectionKey
                                    // but uicl grid would throw warning
                                    selectionKey='patientName'
                                    scrollToTop={scrollToTop}
                                />
                            </GridWrapper>
                            <div style={{ height: 5 }}></div>
                            <div style={{ display: 'flex', flex: '0 0 100 %' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <Button
                                        domID="selectTheseButton"
                                        name="SELECT THESE"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={() => { this.selectThese() }}
                                    />
                                    <div style={{ width: 5, display: 'inline-block' }}></div>
                                    <Button
                                        domID="selectNoneButton"
                                        name="SELECT NONE"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={() => { this.selectNone() }}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {summaryPanel}
                                </div>
                                <div style={{ marginTop: 5, flexGrow: 1, textAlign: 'right', position: 'relative', left: 20, top: -7 }}>
                                    <ARMGridPager
                                        currentPageNumber={gridPaging.currentPageNumber}
                                        numberOfPages={gridPaging.numberOfPages}
                                        pagingHandler={this.handlePaging}
                                    />
                                </div>
                            </div>
                            <div style={{ height: 15 }}></div>
                            <div>
                                {detailPanel}
                            </div>
                        </WorkingGroupDisplayContent>
                    </ContentWrapper>
                    <ModalConfirmation
                        isOpen={this.state.isAlertOpen}
                        formattedMessage={(
                            <React.Fragment>
                                <p>{this.state.alertMessage}.</p>
                            </React.Fragment>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isAlertOpen: false, alertMessage: "" }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.setState({ isAlertOpen: false, alertMessage: "" })

                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isAlertOpen: false }) }}
                        alertMode={true}
                    />
                </DialogWrapper>
                {this.renderLegacyGoTo()}
            </>
        );
    }

};

var connectedHoc = connect<IWorkingGroupDisplayState, IWorkingGroupDisplayActionProps, IOwnProps, IWorkingGroupDisplayProps, ApplicationState>(
    createApiMapStateToProps('workingGroupDisplay'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(WorkingGroupDisplay);

export default withRouter(connectedHoc);