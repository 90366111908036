import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers'; 
import * as ClaimFilterStore from './api/ClaimFilterStore';

//  needed?  API calls should be native JSON and therefore, not need to fix arrays, but possible other data massaging will be needed
//  import { ValidationCallback } from '@scripts/util/ActionHelpers';
//  import { keys } from 'ts-transformer-keys';
//  import { ValidateObject, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';


export type IClaimFilterState = IApiComponentState<any, ClaimFilterStore.IClaimFilterUIState>;

export const actionCreators = createApiComponentActions<ClaimFilterStore.ActionCreators>(ClaimFilterStore.actionCreators); 
export type IClaimFilterActions = typeof actionCreators;
export type IClaimFilterActionProps = IApiComponentActionProps<IClaimFilterActions>

export const reducers = createApiComponentReducers("claimFilter", ClaimFilterStore.reducer); 
export const reducer = combineReducers<IClaimFilterState>(reducers);



