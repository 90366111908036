import { combineReducers } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as AllowedIpMaintenanceUI from './ui/AllowedIpMaintenanceUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IAllowedIpMaintenanceState = ICrudComponentState<MCRemoteIpAddress, AllowedIpMaintenanceUI.IAllowedIpMaintenanceUiState>;

export const actionCreators = createCrudComponentActions<AllowedIpMaintenanceUI.ActionCreators>(AllowedIpMaintenanceUI.actionCreators);
export type IAllowedIpMaintenanceActions = typeof actionCreators;
export type IAllowedIpMaintenanceActionProps = ICrudComponentActionProps<IAllowedIpMaintenanceActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiRemoteAddressesMaster, AllowedIpMaintenanceUI.reducer);
export const reducer = combineReducers<IAllowedIpMaintenanceState>(reducers);

export const validationCallback: ValidationCallback<MCRemoteIpAddress> = (crud: any): crud is MCRemoteIpAddress => {
    const testCrud: MCRemoteIpAddress = crud as MCRemoteIpAddress;

    const info = testCrud.RemoteAddressesMaintenanceInfo;
    const needsRemoteAddresses = !info.hasOwnProperty("RemoteAddresses") ||
                                    (!info.RemoteAddresses.hasOwnProperty("RemoteAddress"));
    if (needsRemoteAddresses) {
        info.RemoteAddresses =
            { RemoteAddress: new Array<MCRemoteAddress>() };
    }

    if (info.RemoteAddresses.RemoteAddress &&
        info.RemoteAddresses.RemoteAddress.constructor !== Array) {
        let arr = new Array<MCRemoteAddress>();
        arr.push(JSON.parse(JSON.stringify(info.RemoteAddresses.RemoteAddress)));
        info.RemoteAddresses.RemoteAddress = arr;
    }

    let validShape: boolean = ValidateJSONSchema(testCrud, "MCRemoteIpAddress");
    validShape = validShape && ValidateJSONSchema(testCrud?.RemoteAddressesMaintenanceInfo, "MCRemoteAddressesMaintenanceInfo");
    validShape = validShape && ValidateJSONSchema(testCrud?.RemoteAddressesMaintenanceInfo?.RemoteAddresses, "MCRemoteAddresses");
    //if (validShape) {
    //    validShape = ValidateArray(testCrud?.RemoteAddressesMaintenanceInfo?.RemoteAddresses?.RemoteAddress, "MCRemoteAddress");
    //}

    return validShape;
}