import React, { useEffect, useState, useMemo, useRef } from 'react';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { Input, Typography } from 'ui-core';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { Colors } from '@commonResources/colorVariables';
import styled from 'styled-components';
import { get, uniqueId, isEmpty, has } from 'lodash';
import { handleChange } from '../../../commonResources/userModified';

const UpdateRequestFieldsModalContainerDiv = styled.div`
    width: 950px;
    height: 365px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
`;


const TD = styled.td`
  height: 21px;
  margin: 0px;
  padding: 0px;
  text-align: center; 
  padding-top: 6px !important;
`

const ContentWrapper = styled.div`
     width: 100%;
`

interface IRowProps {
    record: IRecordProp
};

interface IRecordProp {
    "@ID"?: string;
    "@DisplayName": string;
    "@SubscriberSearchCriteria": string;
    "@DependantSearchCriteria": string;
    "@PayerSearchRanking"?: string;
    "@Required": string;
    "@SendWhenPresent": string;
    "@DoNotSend"?: string;
    "@Type": string;
    "@Name": string;
    "@Send": string;
}

interface IPayer {
    "@Active": string;
    "@Batch": string;
    "@Bridge": string;
    "@CFI": string;
    "@Direct": string;
    "@DisplayName": string;
    "@EligEnabled": string;
    "@ID": string;
    "@Manual": string;
    "@Name": string;
    "@PayerID": string;
    "@RTECPID": string;
    Edits?: any;
    Fields?: any;
};

interface PayerProfile {
    "@RTECPID": string;
    "@EnrollmentRequired": string;
    Fields: {
        Field: PayerProfileFields[]
    };
    "Edits": string;
}


interface PayerProfileFields {
    "@Type": string;
    "@Name": string;
    "@SubscriberSearchCriteria": string;
    "@DependantSearchCriteria": string;
    "@DisplayName": string;
    "@SequenceNumber": string;
    "@Required": string;
    "@Send": string;
    "@DoNotSend"?: string;
}


interface IComponentProps {
    closeModal: () => void;
    maintenanceInfo: any;
    payerId: string;
    rtecpId: string;
    onOK: (updatedMaintenanceInfo: any) => void;
    fieldEdits: any;
    id: string;
};


const forceToArray = (input: any) => {
    if (Array.isArray(input)) {
        return input
    } else if (typeof input === 'object') {
        return [input]
    } else {
        return []
    }
}


const UpdateRequestFieldsModal = ({ onOK, maintenanceInfo, closeModal, payerId, rtecpId, fieldEdits, id }: IComponentProps) => {
    const myRef = useRef(null)
    const [payer, setPayer] = useState<IPayer | {}>({});
    const [records, setRecord] = useState<any>([]);
    const [fieldUpdates, setFieldUpdates] = useState<any>({});
    const [count, setCount] = useState<any>(0);
    const payerDisplayName = get(payer, ['@DisplayName'], '');
    const [sortKey, setSortKey] = useState("");


    useEffect(() => {
        const newPayer = maintenanceInfo.ClientPayers.Payer.find((e: IPayer) => e["@PayerID"] === payerId && e["@RTECPID"] === rtecpId)
        const tempField = maintenanceInfo.EligibilityPayerProfiles.PayerProfile.find((e: PayerProfile) => e["@RTECPID"] === newPayer["@RTECPID"]).Fields.Field
        let fieldsWithEdits = get(newPayer, ["Fields", "Field"], []);
        fieldsWithEdits = forceToArray(fieldsWithEdits)
        const field = Array.isArray(tempField) ? [...tempField] : [];
        const newRecords = field.map((f: PayerProfileFields) => {

            const type = f["@Type"];
            const updatedField = fieldsWithEdits.find((i: any) => i["@Type"] === type);
            const hasBothCriteria = (f["@SubscriberSearchCriteria"] !== "") && (f["@DependantSearchCriteria"] !== "")
            const uni = updatedField !== undefined ? updatedField["@ID"] : "" + uniqueId();
            return {
                "@ID": uni,
                "@Name": f["@Name"],
                "@Type": f["@Type"],
                "@DisplayName": f["@DisplayName"],
                "@SubscriberSearchCriteria": f["@SubscriberSearchCriteria"],
                "@DependantSearchCriteria": f["@DependantSearchCriteria"],
                "@PayerSearchRanking": f["@SubscriberSearchCriteria"] + (hasBothCriteria ? "," : "") + f["@DependantSearchCriteria"],
                "@Required": f["@Required"],
                "@Send": f["@Send"],
                "@DoNotSend": updatedField !== undefined ? updatedField["@Required"] === "N" && updatedField["@Send"] === "N" ? "Y" : "N" : "N",
                "@fieldRequired": f["@Required"],
                ...(updatedField && updatedField)
            }
        })
        setPayer(newPayer)
        setRecord(newRecords)
    }, [])


    const recordsWithUpdates = useMemo(() => {

        const editsObj = get(fieldEdits, [id], {});
        let updatedRecords = records.map((obj: PayerProfileFields) => {
            const localUpdates = get(fieldUpdates, [`${obj["@Type"]}`], {});
            const prevUpdates = get(editsObj, [`${obj["@Type"]}`], {});
            return {
                ...obj,
                ...prevUpdates,
                ...localUpdates
            }
        })
        return updatedRecords

    }, [fieldUpdates, records]);


    const initialRecords = useMemo(() => {
        return records.map((r: PayerProfileFields) => r);
    }, [records])



    const handleSubmit = (e: React.ChangeEvent<HTMLButtonElement>) => {
        handleChange();
        const isObjectEmpty = isEmpty(fieldUpdates)
        if (isObjectEmpty) {
            closeModal();
        } else {
            const fields = get(fieldEdits, [id], {});
            const updates = {
                ...fieldEdits,
                [id]: {
                    ...fields,
                    ...fieldUpdates
                }
            }

            onOK(updates, payerId, rtecpId)
            closeModal();
        }
    }

    const handleCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        closeModal();
    }

    const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string) => {
        const selectedField = recordsWithUpdates.find((rec: PayerProfileFields) => {
            return rec["@Type"] === e.target.name
        })

        const resetedObj = {
            "@Type": selectedField["@Type"],
            "@DoNotSend": "N",
            "@Required": "N",
            "@Send": "N"
        }

        const updatedField = {
            ...fieldUpdates,
            [e.target.name]: {
                ...resetedObj,
                [fieldName]: selectedField[fieldName] === "Y" ? "N" : "Y"
            }
        }
        setFieldUpdates(updatedField)
    }


    const onSortGridColumn = (e, state) => {
        // e.stopPropagation();
        setCount(count + 1)
        setSortKey(state.sortingKey)
        // console.log("sortState", state)
    }

    const sortingComparator = (columnDataName: string, sortDirection: string, records) => {

        let sortedArr = records.slice()
        if (sortDirection && columnDataName) {
            if (sortDirection === 'SORT_ASCENDING') {
                sortedArr = records.sort((a, b) => b[columnDataName].localeCompare(a[columnDataName]))
            } else {
                sortedArr = records.sort((a, b) => a[columnDataName].localeCompare(b[columnDataName]))
            }
        }
        return sortedArr;
    }


    useEffect(() => {
        if (count > 0) {
            const tableToScroll = document.getElementById("tefth564646st-id");
            if (tableToScroll?.parentElement) {
                tableToScroll.parentElement.scrollTo({ top: 0, behavior: "smooth" })
            }
        }

    }, [count])

    const getCustomRow = () => {
        const row = ({ record }: IRowProps) => {

            const recordId = record["@ID"];
            const initalValues = initialRecords.find((obj: IRecordProp) => obj["@ID"] === recordId);

            return (
                <tr id={recordId} key={recordId}>
                    {
                        <>
                            <TD style={{ textAlign: "unset" }}>
                                {record["@DisplayName"]}
                            </TD>
                            <TD>
                                <ContentWrapper>
                                    {record["@PayerSearchRanking"]}
                                </ContentWrapper>
                            </TD>
                            <TD>
                                <ContentWrapper>
                                    <input
                                        id={"suppress_checkbox_" + recordId}
                                        type="checkbox"
                                        name={record["@Type"]}
                                        checked={record["@Required"] === "Y" ? true : false}
                                        disabled={initalValues["@fieldRequired"] === "Y" ? true : false}
                                        onChange={(e) => handleCheckBoxChange(e, "@Required")}
                                    />
                                </ContentWrapper>
                            </TD>
                            <TD>
                                <ContentWrapper>
                                    <input
                                        id={"suppress_checkbox_" + recordId}
                                        type="checkbox"
                                        disabled={initalValues["@fieldRequired"] === "Y" ? true : false}
                                        name={record["@Type"]}
                                        checked={(record["@Send"] === "Y" && initalValues["@fieldRequired"] !== "Y") ? true : false}
                                        onChange={(e) => handleCheckBoxChange(e, "@Send")}
                                    />
                                </ContentWrapper>
                            </TD>
                            <TD>
                                <ContentWrapper>
                                    <input
                                        id={"suppress_checkbox_" + recordId}
                                        type="checkbox"
                                        disabled={initalValues["@fieldRequired"] === "Y" ? true : false}
                                        name={record["@Type"]}
                                        checked={(record["@DoNotSend"] === "Y" && initalValues["@fieldRequired"] !== "Y") ? true : false}
                                        onChange={(e) => handleCheckBoxChange(e, "@DoNotSend")}
                                    />
                                </ContentWrapper>
                            </TD>
                        </>
                    }
                </tr>
            );
        }

        return row
    }



    return (
        <DialogWrapper
            title="Update Eligibility Payer Request Fields"
            helpUrl="/Support/Help/HELP_Maint_EligibilityData.htm"
            buttons={
                <OKCancelButtons
                    onClickOK={handleSubmit}
                    onClickCancel={handleCancel}
                />
            }
            marginTop="0px"
            width="950px"
        >
            <UpdateRequestFieldsModalContainerDiv>
                <Input domID="tefth56464d6st-id" label="Payer" initialValue={payerDisplayName} disabled />
                <div style={{ height: "300px" }} id="test">
                    <ARMGrid
                        columns={new Set([
                            { dataName: "@DisplayName", text: "Field Name", sortable: true, isSorted: 0, },
                            { dataName: "@PayerSearchRanking", text: "Payer Search Ranking", sortable: true, isSorted: 0 },
                            { dataName: "@Required", text: "Required", sortable: false, isSorted: 0 },
                            { dataName: "@SendWhenPresent", text: "Send When Present", sortable: false, isSorted: 0 },
                            { dataName: "@DoNotSend", text: "Do Not Send", sortable: false, isSorted: 0 },
                        ])}
                        isFixedHeader
                        maxHeight="300px"
                        domID="tefth564646st-id"
                        selectionKey="@ID"
                        rowComponent={getCustomRow()}
                        records={recordsWithUpdates}
                        scrollToTop={false}
                        onSortGridColumn={onSortGridColumn}
                        initialSortingKey={sortKey}
                        sortingComparator={sortingComparator}
                        ref={myRef}

                    />
                </div>
            </UpdateRequestFieldsModalContainerDiv>
        </DialogWrapper>
    )
}


export default UpdateRequestFieldsModal;

