import React from "react";
import { describeArc } from "../utils/utils";
import { VictoryPolarAxis, VictoryLabel } from "victory";

// PASSED IN PROPS (Required at top)
interface IComponentProps {
  value: number;
  low: number;
  high: number;
  header?: string;
  footer?: string;
  diameter?: number;
  range?: number;
  isBoundariesDisabled?: boolean;
}

// GLOBAL CONSTANTS
const DEFAULT_DIAMETER = 350; // The diameter (in pixels of the meter)
const SAFE_COLOR = { red: 45, green: 137, blue: 1 }; // The color for the safe (green) boundary
const WARNING_COLOR = { red: 243, green: 217, blue: 49 }; // The warning for the warning (amber) boundary
const DANGER_COLOR = { red: 168, green: 29, blue: 29 }; // The danger for the danger (red) boundary
const NEUTRAL_COLOR = { red: 200, green: 200, blue: 200 }; // Neutral color for when disabled is on
const ANGLE_START = -105; // The overall start angle in degrees (on the left) of the meter (where 0 is at 12 o'clock)
const ANGLE_END = 105; // The overall end angle in degress (on the right) of the meter (where 0 is at 12 o'clock)
const FIXED_DIAMETER = 500; // The diameter within which to fit the meter component

// Utility fucntion to convert individual components to equivalent hex values
function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? "0" + hex : hex;
}

// Fit function: Utitliy function to fit a value from an old range into a new range
const fit = (
  value: number,
  oldmin: number,
  oldmax: number,
  newmin: number,
  newmax: number
): number => {
  return newmin + ((value - oldmin) * (newmax - newmin)) / (oldmax - oldmin);
};

const TICK_LIMIT = 12;
// Utility funciton to create a step interval for a given range that gives a reasonable spread
const calculateInterval = (range: number) => {
  let x = Math.pow(10.0, Math.floor(Math.log10(range)));
  if (range / (x / 2.0) >= 10) return x / 2.0;
  else if (range / (x / 5.0) >= 10) return x / 5.0;
  else return x / 10.0;
};

// Utility function to set ticks
const setTicks = (range: number, value: number) => {
  // Round range to nearest tenth
  let newRange = (Math.ceil(range) * 10) / 10;

  let step = calculateInterval(newRange);

  let totalTicks = () => Math.round(newRange / step + 1);
  let tickValues = Array.from(
    { length: totalTicks() },
    (_, i) => +(i * step).toFixed(2)
  );

  // Reduce the array until it matches our criteria
  while (tickValues.length > TICK_LIMIT) {
    // We're abusing the reduce filter as defined in order to thin the array, so prevent typescript error
    //@ts-ignore
    let newArr = tickValues.reduce((prev: any[], cur, index, arr) => {
      if (index % 2 === 0) {
        prev.push(cur);
      }
      return prev;
    }, []);
    tickValues = newArr;
  }

  const lastTickValue = tickValues[tickValues.length - 1];
  const penultimateValue = tickValues[tickValues.length - 2];
  let interval = lastTickValue - penultimateValue;
  // Add an interval to tickmark if value should exceed range
  if (value > lastTickValue) {
    tickValues.push(+(lastTickValue + interval).toFixed(2));
  }

  // We normalize the value to be in the range of 0 to 1
  const normalizedValue = value / tickValues[tickValues.length - 1];

  return { tickValues, newRange, normalizedValue };
};

const MeterComponent = (props: IComponentProps) => {
  const getProps = () => {
    let { value, high, low, isBoundariesDisabled } = props;
    let { range, header, footer, diameter } = props;

    // Set defaults for undefined
    value = value ?? 0;
    high = high ?? 0.333;
    low = low ?? 0.666;
    isBoundariesDisabled = isBoundariesDisabled ?? false;

    // Set defaults if none are defined
    range = range ?? Math.max(high + low, value);
    diameter = diameter ?? DEFAULT_DIAMETER;
    header = header ?? "";
    footer = footer ?? "";

    return {
      range,
      diameter,
      header,
      footer,
      value,
      high,
      low,
      isBoundariesDisabled,
    };
  };

  const { range, diameter, header, footer, value } = getProps();

  // Create the hex colors from the individual color components
  const neutralColorHex =
    "#" +
    componentToHex(NEUTRAL_COLOR.red) +
    componentToHex(NEUTRAL_COLOR.green) +
    componentToHex(NEUTRAL_COLOR.blue);
  const safeColorHex =
    "#" +
    componentToHex(SAFE_COLOR.red) +
    componentToHex(SAFE_COLOR.green) +
    componentToHex(SAFE_COLOR.blue);
  const warningColorHex =
    "#" +
    componentToHex(WARNING_COLOR.red) +
    componentToHex(WARNING_COLOR.green) +
    componentToHex(WARNING_COLOR.blue);
  const dangerColorHex =
    "#" +
    componentToHex(DANGER_COLOR.red) +
    componentToHex(DANGER_COLOR.green) +
    componentToHex(DANGER_COLOR.blue);

  const formatToSciNot = (num: number) => {
    const numInSciNot: { coefficient: number; exponent: number } = {
      coefficient: 0,
      exponent: 0,
    };
    [numInSciNot.coefficient, numInSciNot.exponent] = num
      .toExponential()
      .split("e")
      .map((item) => Number(item));
    //console.log(`${numInSciNot.coefficient} x 10^${numInSciNot.exponent}`);

    return numInSciNot;
  };

  const getMaxExponent = (arr: number[]) => {
    const val = Math.max(...arr.map((el) => formatToSciNot(el).exponent));
    return val;
  };

  // Draw the meter boundaries
  const drawBoundaries = () => {
    let { low, high, isBoundariesDisabled } = getProps();

    let startAngle = 0;
    let normalizedLow = low / tickValues[tickValues.length - 1];
    let normalizedHigh = high / tickValues[tickValues.length - 1];

    // Note: 0 degrees is at 12 o'clock
    const getAngleStart = (index: number) => {
      switch (index) {
        case 0:
          // SAFE
          return ANGLE_START;
        case 1:
          // WARNING
          startAngle = fit(normalizedLow, 0, 1, ANGLE_START, ANGLE_END);
          return startAngle;
        case 2:
          // DANGER
          startAngle = fit(normalizedHigh, 0, 1, ANGLE_START, ANGLE_END);
          return startAngle;
        default:
          return null;
      }
    };

    // Define the boundaries as percentage of total range
    let boundaries = [
      { color: safeColorHex, angleStart: getAngleStart(0) },
      { color: warningColorHex, angleStart: getAngleStart(1) },
      { color: dangerColorHex, angleStart: getAngleStart(2) },
    ];

    if (isBoundariesDisabled) {
      boundaries[0].color = neutralColorHex;
      boundaries = boundaries.slice(0, 1);
    }
    return boundaries.map((item, index) => (
      <path
        id={`#boundary_${index + 1}`}
        fill={item.color}
        key={index + 1 + Date.now()}
        stroke={item.color}
        strokeWidth={0}
        d={describeArc(
          250,
          250,
          65,
          60,
          item?.angleStart ?? ANGLE_START,
          ANGLE_END
        )}
      />
    ));
  };

  const { tickValues, normalizedValue } = setTicks(range, value);

  // Calculate the suffix that includes the multiplier for the tick values
  const getValueMultiplier = (): string => {
    let suffix = "";

    let mult = getMaxExponent(tickValues);
    if (mult === 0) suffix = "";
    suffix = mult ? `1${Array(mult).fill(0).join("")}` : "";
    suffix = suffix.replace(/\B(?=(\d{3})+(?!\d))/g, ","); // dollar format

    return mult ? "x" + suffix : "";
  };

  // Calculate a needle rotation from the passed in value
  const needleRotation = fit(normalizedValue, 0, 1, ANGLE_START, ANGLE_END);

  return (
    <div style={{ width: diameter }}>
      <svg viewBox={`0 0 ${FIXED_DIAMETER} ${FIXED_DIAMETER - 100}`}>
        <filter id="dropshadow" height="130%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
          <feOffset dx="2" dy="2" result="offsetblur" />
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5" />
          </feComponentTransfer>
          <feMerge>
            <feMergeNode />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>

        {/* Circle that displays status  - add to modify */}
        <circle cx={250} cy={250} r={20} id="color-display" fill={"white"} />

        {/* The Color Boundaries */}
        {drawBoundaries()}

        {/* The Upper Label & Multiplier*/}
        <VictoryLabel
          x={FIXED_DIAMETER / 2}
          y={40}
          style={{
            textAnchor: "middle",
            verticalAnchor: "middle",
            fill: "#000000",
            fontFamily: "inherit",
            fontSize: "20px",
            fontWeight: "bold",
          }}
          text={header + getValueMultiplier()}
        />

        {/* The Tick Marks Display*/}
        <g transform={`translate(75, 75)`}>
          <VictoryPolarAxis
            width={FIXED_DIAMETER - 150}
            height={FIXED_DIAMETER - 150}
            startAngle={-15}
            endAngle={195}
            tickValues={tickValues}
            labelPlacement="vertical"
            style={{
              axis: { stroke: "black", strokeWidth: 4 },
              grid: {
                stroke: ({ tick }) => (tick % 2 == 0 ? "black" : "#1e110c"),
                strokeWidth: ({ tick }) => (tick % 2 == 0 ? 1.5 : 1),
              },
              tickLabels: { fontSize: 18, padding: 24, fontWeight: "bold" },
            }}
            innerRadius={110}
            tickFormat={(t) => {
              //Reverse the range to fit Victory Charts Numbering Scheme
              let offset = tickValues[tickValues.length - 1];
              let newT = +(offset - t).toFixed(2);

              // Claculate the offset between the current and max exponent and multiply through to get new value
              newT = +(
                formatToSciNot(+newT).coefficient *
                Math.pow(
                  10,
                  -(getMaxExponent(tickValues) - formatToSciNot(+newT).exponent)
                )
              ).toFixed(2);

              return newT;
            }}
            standalone={false}
          />
        </g>
        {/* The Numeric Value Display*/}
        <VictoryLabel
          x={FIXED_DIAMETER / 2}
          y={350}
          style={{
            textAnchor: "middle",
            verticalAnchor: "middle",
            fill: "#000000",
            fontFamily: "inherit",
            fontSize: "20px",
            fontWeight: "bold",
          }}
          text={footer}
        />
        {/* The Needle */}
        <g transform={`rotate(${needleRotation}, 250, 250)`}>
          <path
            d="M243.150027,252 L243.039819,251.277564 C243.013492,251.021964 243,250.762557 243,250.5 C243,250.134178 245.333333,208.300844 250,125 C255.333333,208.265679 258,250.099012 258,250.5 C258,250.77414 257.985292,251.044844 257.956622,251.311367 L257.849973,252 C257.155067,255.42336 254.128437,258 250.5,258 C246.871563,258 243.844933,255.42336 243.150027,252 Z"
            id="needle"
            fill="#000"
            filter={"url(#dropshadow)"}
          ></path>
        </g>
      </svg>
    </div>
  );
};

export default MeterComponent;