import { Reducer, ActionCreatorsMapObject, Dispatch, AnyAction } from 'redux';
import { createDataAction, ActionTypes, createApiAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { cloneDeep, flatMap, groupBy, intersectionWith, keyBy, partition, uniqBy, uniqueId, Dictionary, update } from 'lodash';
import { IsFieldFilled, IsAlphaNumeric, IsUsernameValid, IsPasswordValid, ValidatePassword } from '@commonResources/validations';
import CrudTypes from '@commonResources/CrudTypes';
import { ComplexAction, ComplexApiAction, IThunkAction, IThunkResult, IThunkApiAction } from "@scripts/util/ThunkHelpers";
import { MasterCrudState, actionCreators as masterCrudCreators } from '../MasterCrud';
import { isArray } from 'lodash';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IInternalUserUIState {
    isDirty: boolean;
    tabSelectionState: 'support' | 'outsource';
    selectedSupportUser: ISelectedUser;
    selectedOutsourceUser: ISelectedUser;
    selectedUser: ISelectedUser;
    internalUsers: ISelectedUser[];
    newSupportUser: INewUser;
    newOutsourceUser: INewUser;
    status: string;
    userType: string;
    outsourceUsers: ISelectedUser[];
    supportUsers: ISelectedUser[];
    loginName_userError?: string;
    firstName_userError?: string;
    lastName_userError?: string;
    password_userError?: string;
    confirmPassword_userError?: string;
    email_userError?: string;
    emailConfirmed?: string;
    modalText?: string;
    isBusy: boolean;
    clientAccountPolicies: any;

}
// clientAccountPolicies should be MNAccountPolicy[], used for input validations

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

interface ISelectUserData {
    index: number;
    value: string;
    text: string;
};


export interface ISelectedUser {
    isDirty: boolean;
    updated?: boolean;
    removed?: boolean;
    added?: boolean;
    userType: 'support' | 'outsource';
    id: string;
    lastName: string;
    firstName: string;
    loginName: string;
    extension: string;
    password: string;
    status: string;
    lastLogonDate: string;
    interactive: string;
    PWExpiring: string;
    failedAttempts: string;
    itemName?: string;
    itemValue?: string;
    confirmedPassword: string;
    email: string;
    displayName: string;
    featurePreview?: string;
}

interface INewUser {
    removed?: boolean;
    added?: boolean;
    loginName_userError?: string;
    firstName_userError?: string;
    lastName_userError?: string;
    password_userError?: string;
    confirmPassword_userError?: string;
    email_userError?: string;
    emailConfirmed?: string;
    id?: string;
    lastName: string;
    firstName: string;
    loginName: string;
    extension: string;
    password: string;
    status: string;
    lastLogonDate: string;
    interactive: string;
    PWExpiring: string;
    failedAttempts: string;
    itemName?: string;
    confirmedPassword?: string;
    email: string;
    featurePreview?: string;
}

const defaultSelectedUser: ISelectedUser = {
    isDirty: false,
    updated: false,
    id: 'default',
    userType: 'support',
    lastName: '',
    firstName: '',
    loginName: '',
    extension: '',
    password: '',
    status: '0',
    lastLogonDate: '',
    interactive: '1',
    PWExpiring: '',
    failedAttempts: '',
    confirmedPassword: '',
    email: '',
    featurePreview: '',
    displayName: '-ADD A NEW USER-'
};

const defaultNewUser: INewUser = {
    id: '',
    lastName: '',
    firstName: '',
    loginName: '',
    extension: '',
    password: '',
    status: '',
    lastLogonDate: '',
    interactive: '',
    PWExpiring: '',
    failedAttempts: '',
    confirmedPassword: '',
    email: '',
}


interface IInternalUserUIFieldUpdate {
    value: string;
}

interface IInternalUserUICheckUpdate {
    value: boolean;
}


export class InternalUserFieldErrors {
    public static readonly blankLoginName: string = "Login Name must not be blank.";
    public static readonly allowedInLoginName: string = "This value can only include letters, numbers, and the listed special characters: - _.";
    public static readonly allowedLoginNameLength: string = "Your login name must be between 5 and 12 characters in length.";
    public static readonly deletedUserExists: string = "A deleted user by that login name already exists.";

    public static readonly blankFirstName: string = "First Name must not be blank.";
    public static readonly invalidName: string = "The value you typed in must consist of valid characters.";

    public static readonly blankLastName: string = "Last Name must not be blank.";

    public static readonly blankPassword: string = "Password must not be blank.";
    public static readonly invalidPasswordLength: string = "Your password must be between 8 and 64 characters in length.";
    public static readonly invalidCasePassword: string = "Your password must contain at least one uppercase and one lowercase letter.";
    public static readonly specialCharPassword: string = "Your password must contain at least one special character(i.e. $,#,&,^...etc.).";
    public static readonly matchPassword: string = "The passwords did not match.";

    public static readonly valideMail: string = "Please enter a valid email.";
    public static readonly requiredEmail: string = "Email Address is required.";

    public static readonly existingUserName: string = "A user by that name already exists. Please type in a different name.";
    public static readonly existingUserEmail: string = "A user by that E-Mail address already exists. Please type in a different E-Mail address.";

};

export interface ISelectInternalUser extends ICrudActionData<MCARMInternalUser, ISelectUserData> { }
export interface IModifyInternalUser extends ICrudActionData<MCARMInternalUser, MCInternalUser> { }
export interface IUpdateInternalUser extends ICrudActionData<MCARMInternalUser, IInternalUserUIFieldUpdate> { }
export interface IUpdateCheckInternalUser extends ICrudActionData<MCARMInternalUser, IInternalUserUICheckUpdate> { }

interface ISupportUserData {
    uiData: MCInternalUser[];
    type: "SET_INTERNALUSERS";
}

export interface ISelectAccountStatus {
    userId: string;
    status: '0' | '1' | '2';
}

export interface IModifyTextInput {
    id: string;
    text: string;
    attributeName: 'loginName' | 'lastName' | 'firstName' | 'extension' | 'password' | 'confirmedPassword' | 'email';
}

export interface IRemoveUser {
    userId: string;
}

export interface IAddUser {
    userInfo: ISelectedUser;
}

export interface IUpdateUserInteractive {
    userId: string;
    interactionStatus: string;
}

interface IUpdateFeaturePreview {
    ItemValue: string;
    ItemName: string;
    userId: string;
}

function sortArrayAtIndex(arrayOfUsers: ISelectedUser[]) {
    // this helper is necessary to ensure that when a new user is added, the new internal user array state is predictably sorted
    // so that the user objects are alphabetetized and the two defaults are in place.
    const filterSortArr = arrayOfUsers.filter(user => user.id != 'default').sort((a, b) => (a.loginName.toLowerCase() > b.loginName.toLowerCase() ? 1 : -1));
    const defaultsToAppend = [{ ...defaultSelectedUser }, { ...defaultSelectedUser, userType: 'outsource' }];
    return defaultsToAppend.concat(filterSortArr);
}

const forceToArray = (input: any) => {
    if (Array.isArray(input)) {
        return input
    } else if (typeof input === 'object') {
        return [input]
    } else {
        return []
    }
}

export function mapCrudToUIState(crud: MCInternalUserInfo, state: IInternalUserUIState, userType: string) {

    const mappedUsers = forceToArray(crud.Users.User).map(user => {
        return {
            isDirty: false,
            userType: userType,
            id: user['@ID'],
            firstName: user['@FirstName'],
            lastName: user['@LastName'],
            loginName: user['@LoginName'],
            extension: user['@Extension'],
            password: user['@Password'],
            status: user['@Status'],
            lastLogonDate: user['@LastLogonDate'],
            interactive: user['@Interactive'],
            PWExpiring: user['@PWExpiring'],
            failedAttempts: user['@FailedAttempts'],
            itemName: user['@ItemName'] || '',
            itemValue: user['@ItemValue'] || '',
            confirmedPassword: '',
            email: user['@Email'] ? user['@Email'] : '',
            displayName: user['@LoginName']
        }
    })

    return { ...state, clientAccountPolicies: crud.ClientAccountPolicy, internalUsers: [...state.internalUsers, ...mappedUsers], isBusy: false }
}


// const crudTemplate = {
//     NdcUserMaintenanceInfo: {
//        Users: {
//           User: {
//              "@ID": "141",
//              "@Delete": "true"
//           }
//        }
//     },
//     EBOUserMaintenanceInfo: {
//        Users: [
//           {
//              "@ID": "New[0]",
//              "@Status": "0",
//              "@FirstName": "NEW",
//              "@LastName": "NEW",
//              "@LoginName": "NewOutsource",
//              "@Extension": "",
//              "@Email": "",
//              "@Interactive": "0",
//              "@Password": "P@ssword1",
//              "@ItemName": "CFI_Mode",
//              "@ItemValue": "Legacy+"
//           },
//           {
//              "@ID": "New[1]",
//              "@Status": "0",
//              "@FirstName": "TEST",
//              "@LastName": "TEST",
//              "@LoginName": "testout",
//              "@Extension": "",
//              "@Email": "",
//              "@Interactive": "1",
//              "@Password": "P@ssword1",
//              "@ItemName": "CFI_Mode",
//              "@ItemValue": "Legacy+"
//           }
//        ]
//     }
//  };

const crudTemplate = {
    NdcUserMaintenanceInfo: {
        Users: {
            User: {
                "@ID": "",
                "@Delete": ""
            }
        }
    },
    EBOUserMaintenanceInfo: {
        Users: [

        ]
    }
};


export function mapUIStatetoCrud(crud: any, state: IInternalUserUIState) {
    // TO DO: construct outgoing crud object from state e.g removed tag ---> @Deleted key etc. 
}

export const actionCreators = {
    initializeIntUserState: () => ComplexApiAction
        .fromAction(
            masterCrudCreators.get({ crudId: CrudTypes.mctiNdcUserMaster }))
        .addThunk(
            (dispatch: Dispatch<AnyAction>, actionResult: IThunkApiAction<'GET_CRUD', any>)
                : IThunkResult<void> => {
                const supportUserResults = actionResult
                    ?.responseData;
                if (actionResult.status.status === 'SUCCESS' && supportUserResults) {
                    dispatch(actionCreators.setInternalUsers({ crudInfo: actionResult.responseData.NdcUserMaintenanceInfo, userType: 'support' }));
                    return { success: true };
                }
                else {
                    return { success: false };
                }
            }).then(ComplexApiAction.fromAction(
                masterCrudCreators.get({ crudId: CrudTypes.mctiOutsourceUserMaster }))
                .addThunk(
                    (dispatch: Dispatch<AnyAction>, actionResult: IThunkApiAction<'GET_CRUD', any>)
                        : IThunkResult<void> => {
                        const outsourceUserResults = actionResult
                            ?.responseData;
                        if (actionResult.status.status === 'SUCCESS' && outsourceUserResults) {
                            dispatch(actionCreators.setInternalUsers({ crudInfo: actionResult.responseData.EBOUserMaintenanceInfo, userType: 'outsource' }));
                            return { success: true };
                        }
                        else {
                            return { success: false };
                        }
                    })
            )
        .finish(),
    selectInternalUser: (userId: string) => createDataAction('SELECT_INTERNAL_USER', userId),
    updateTextInput: (textInfo: IModifyTextInput) => createDataAction('UPDATE_IU_TEXT_INPUT', textInfo),
    updateFeaturePreview: (textInfo: IModifyTextInput) => createDataAction('UPDATE_FEATURE_PREVIEW', textInfo),
    updateInternalUser: (userId: string) => createDataAction('UPDATE_INTERNAL_USER', userId),
    updateInteractiveState: (userInfo: IUpdateUserInteractive) => createDataAction('UPDATE_IU_INTERACTIVE_STATE', userInfo),
    removeInternalUser: (removedUserInfo: IRemoveUser) => createDataAction('REMOVE_INTERNAL_USER', removedUserInfo),
    setTabSelectionState: (selectionState: string) => createDataAction('SET_TAB_SELECTION', selectionState),
    addInternalUser: (addedUserInfo: IAddUser) => createDataAction('ADD_NEW_INTERNAL_USER', addedUserInfo),
    setInternalUserAccountStatus: (accountInfo: ISelectAccountStatus) => createDataAction('SET_INTERNAL_USER_ACCOUNT_STATUS', accountInfo),
    setInternalUsers: (crudData: any) => createDataAction('SET_INTERNAL_USERS', crudData),
    setIUFeaturePreview: (uiData: IUpdateFeaturePreview) => createDataAction('SET_IU_FEATURE_PREVIEW', uiData),
    setOutsourceUsers: (outsourceUserInfo: any) => createDataAction('SET_OUTSOURCE_USERS', outsourceUserInfo),
    setBusy: (isBusy: boolean) => createDataAction('UM_SET_BUSY', isBusy),
    restoreUser: (updateInfo: IModifyInternalUser) => createDataAction('RESTORE_USER', updateInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IInternalUserUIState = {
    isDirty: false,
    isBusy: true,
    tabSelectionState: 'support',
    selectedUser: defaultSelectedUser,
    selectedSupportUser: defaultSelectedUser,
    selectedOutsourceUser: defaultSelectedUser,
    newSupportUser: { ...defaultNewUser },
    newOutsourceUser: { ...defaultNewUser },
    internalUsers: [{ ...defaultSelectedUser }, { ...defaultSelectedUser, userType: 'outsource' }],
    status: "0",
    outsourceUsers: [],
    supportUsers: [],
    userType: "",
    emailConfirmed: "0",
    modalText: "",
    clientAccountPolicies: []
};

function isNewId(id: string): boolean {
    return id.search(/new-[0-9+]/) > -1;
}

function getFeaturePreview(itemName: string, itemValue: string): string {
    let featurePreview: string = "";
    if (itemName === "CFI_Mode") {
        if (itemValue === "Legacy") {
            featurePreview = "CFI Legacy Only";
        } else if (itemValue === "Legacy+") {
            featurePreview = "CFI Legacy Plus";
        } else {
            featurePreview = "";
        }
    }
    return featurePreview;
}

function getClaimViewer(userType: string): string {
    return (userType === "C") ? "1" : "0";
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IInternalUserUIState, KnownActions> = (state: IInternalUserUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SET_TAB_SELECTION': {
                return { ...state, tabSelectionState: action.data, selectedUser: { ...defaultSelectedUser, userType: action.data } }
            }
            case 'SET_INTERNAL_USERS': {
                return mapCrudToUIState(action.data.crudInfo, state, action.data.userType)
            }
            case 'SELECT_INTERNAL_USER': {
                return { ...state, selectedUser: state.internalUsers.find(user => user.id == action.data) }
            }
            case 'SET_INTERNAL_USER_ACCOUNT_STATUS': {
                const newState = { ...state };
                const selectedUser = { ...newState.internalUsers.find(user => user.id === action.data.userId) }
                selectedUser.status = action.data.status
                return { ...newState, selectedUser }
            }
            case 'REMOVE_INTERNAL_USER': {
                const newState = { ...state };
                const updatedUsers = [...newState.internalUsers];
                const selectedUser = newState.internalUsers.findIndex(user => user.id === action.data.userId);
                updatedUsers[selectedUser].removed = true;
                return { ...newState, selectedUser: defaultSelectedUser, internalUsers: updatedUsers };
            }
            case 'UPDATE_IU_TEXT_INPUT': {
                const newState = { ...state };
                return { ...newState, selectedUser: { ...newState.selectedUser, [action.data.attributeName]: action.data.text } };
            }
            case 'UPDATE_FEATURE_PREVIEW': {
                {
                    if (state.selectedUser.itemValue !== action.data.uiData.value || state.selectedUser.featurePreview === undefined) {
                        let newState = { ...state };
                        let selectedInternalUser = state.internalUsers.find((item) => {
                            return item.id === state.selectedUser.id;
                        });
                        if (action.data.uiData.value === "Legacy" || state.selectedUser.featurePreview === undefined) {
                            if (selectedInternalUser && selectedInternalUser !== undefined) {
                                selectedInternalUser.itemName = "CFI_Mode";
                                selectedInternalUser.itemValue = action.data.uiData.value;
                                selectedInternalUser.featurePreview = 'CFI Legacy Only';
                                newState.selectedUser.itemName = "CFI_Mode";
                                newState.selectedUser.itemValue = action.data.uiData.value;
                                newState.selectedUser.featurePreview = 'CFI Legacy Only';
                            }
                            return {
                                ...newState
                            }
                        } else if (action.data.uiData.value === "Legacy+" || state.selectedUser.featurePreview === undefined) {
                            if (selectedInternalUser && selectedInternalUser !== undefined) {
                                selectedInternalUser.itemName = "CFI_Mode";
                                selectedInternalUser.itemValue = action.data.uiData.value;
                                selectedInternalUser.featurePreview = 'CFI Legacy Plus';
                                newState.selectedUser.itemName = "CFI_Mode";
                                newState.selectedUser.itemValue = action.data.uiData.value;
                                newState.selectedUser.featurePreview = 'CFI Legacy Plus';
                            }
                            return {
                                ...newState
                            }
                        } else {
                            if (selectedInternalUser && selectedInternalUser !== undefined) {
                                selectedInternalUser.itemName = action.data.uiData.value;
                                selectedInternalUser.itemValue = action.data.uiData.value;
                                selectedInternalUser.featurePreview = '- Select Feature -';
                                newState.selectedUser.itemName = "CFI_Mode";
                                newState.selectedUser.itemValue = action.data.uiData.value;
                                newState.selectedUser.featurePreview = '- Select Feature -';
                            }
                            return {
                                ...newState
                            }
                        }
                    }
                }
            }


            case 'UPDATE_IU_INTERACTIVE_STATE': {
                const newState = { ...state };
                let updateSelectedUser = newState.selectedUser
                updateSelectedUser.interactive = action.data.interactionStatus;
                return { ...newState, selectedUser: updateSelectedUser };

            }
            case 'UPDATE_INTERNAL_USER': {
                const newState = { ...state };
                newState.loginName_userError = undefined;
                newState.firstName_userError = undefined;
                newState.lastName_userError = undefined;
                newState.password_userError = undefined;
                newState.confirmPassword_userError = undefined;
                newState.email_userError = undefined;
                if (!IsFieldFilled(state.selectedUser.loginName)) {
                    newState.loginName_userError = InternalUserFieldErrors.blankLoginName;
                    return newState;
                }

                if (!IsUsernameValid(state.selectedUser.loginName)) {
                    newState.loginName_userError = InternalUserFieldErrors.allowedInLoginName;
                    return newState;
                }

                if (state.selectedUser.loginName && (state.selectedUser.loginName.length < 5 || state.selectedUser.loginName.length > 12)) {
                    newState.loginName_userError = InternalUserFieldErrors.allowedLoginNameLength;
                    return newState;
                }

                if (state.selectedUser.loginName && state.selectedUser.loginName.indexOf(' ') >= 0) {
                    newState.loginName_userError = InternalUserFieldErrors.allowedInLoginName;
                    return newState;
                }

                if (!IsFieldFilled(state.selectedUser.firstName)) {
                    newState.firstName_userError = InternalUserFieldErrors.blankFirstName;
                    return newState;
                }

                if (!IsAlphaNumeric(state.selectedUser.firstName)) {
                    newState.firstName_userError = InternalUserFieldErrors.invalidName;
                    return newState;
                }

                if (!IsFieldFilled(state.selectedUser.lastName)) {
                    newState.lastName_userError = InternalUserFieldErrors.blankLastName;
                    return newState;
                }

                if (!IsAlphaNumeric(state.selectedUser.lastName)) {
                    newState.lastName_userError = InternalUserFieldErrors.invalidName;
                    return newState;
                }
                let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (state.selectedUser.email) {
                    if (state.selectedUser.email !== "") {
                        if (state.selectedUser.email.indexOf('@') === -1 ||
                            state.selectedUser.email.indexOf('.') === -1 ||
                            !state.selectedUser.email.match(mailformat)) {
                            newState.email_userError = InternalUserFieldErrors.valideMail;
                            return newState;
                        }
                    }
                }
                let duplicateUser = state.internalUsers.find(xuser => xuser.loginName.toUpperCase() === state.selectedUser.loginName.toUpperCase() && xuser.id !== state.selectedUser.id && xuser.userType === state.selectedUser.userType);
                if (duplicateUser) {
                    if (!duplicateUser.removed) {
                        newState.loginName_userError = InternalUserFieldErrors.existingUserName;
                        return newState;
                    }
                }
                if (state.selectedUser.email != "") {
                    let duplicateEmail = state.internalUsers.find(xuser => xuser.email.toUpperCase() === state.selectedUser.email.toUpperCase() && xuser.id != state.selectedUser.id && xuser.userType === state.selectedUser.userType);
                    if (duplicateEmail) {
                        newState.email_userError = InternalUserFieldErrors.existingUserEmail;
                        return newState;
                    }
                }
                const updatedUsers = [...newState.internalUsers];
                const updateIndex = newState.internalUsers.findIndex(user => user.id === action.data && user.userType === state.tabSelectionState);
                updatedUsers[updateIndex] = state.selectedUser;
                updatedUsers[updateIndex].updated = true;
                updatedUsers[updateIndex].displayName = state.selectedUser.loginName;
                return {
                    ...newState, internalUsers: updatedUsers
                };
            }
            case "ADD_NEW_INTERNAL_USER": {
                const newState = { ...state };
                newState.loginName_userError = undefined;
                newState.firstName_userError = undefined;
                newState.lastName_userError = undefined;
                newState.password_userError = undefined;
                newState.confirmPassword_userError = undefined;
                newState.email_userError = undefined;

                if (!IsFieldFilled(state.selectedUser.loginName)) {
                    newState.loginName_userError = InternalUserFieldErrors.blankLoginName;
                    return newState;
                }

                if (!IsUsernameValid(state.selectedUser.loginName)) {
                    newState.loginName_userError = InternalUserFieldErrors.allowedInLoginName;
                    return newState;
                }

                if (state.selectedUser.loginName && (state.selectedUser.loginName.length < 5 || state.selectedUser.loginName.length > 12)) {
                    newState.loginName_userError = InternalUserFieldErrors.allowedLoginNameLength;
                    return newState;
                }

                if (state.selectedUser.loginName && state.selectedUser.loginName.indexOf(' ') >= 0) {
                    newState.loginName_userError = InternalUserFieldErrors.allowedInLoginName;
                    return newState;
                }

                if (!IsFieldFilled(state.selectedUser.firstName)) {
                    newState.firstName_userError = InternalUserFieldErrors.blankFirstName;
                    return newState;
                }

                if (!IsAlphaNumeric(state.selectedUser.firstName)) {
                    newState.firstName_userError = InternalUserFieldErrors.invalidName;
                    return newState;
                }

                if (!IsFieldFilled(state.selectedUser.lastName)) {
                    newState.lastName_userError = InternalUserFieldErrors.blankLastName;
                    return newState;
                }

                if (!IsAlphaNumeric(state.selectedUser.lastName)) {
                    newState.lastName_userError = InternalUserFieldErrors.invalidName;
                    return newState;
                }
                if (!IsFieldFilled(state.selectedUser.password)) {
                    newState.password_userError = InternalUserFieldErrors.blankPassword;
                    return newState;
                }


                if (state.selectedUser.password && (state.selectedUser.password.length < 8 || state.selectedUser.password.length > 64)) {
                    newState.password_userError = InternalUserFieldErrors.invalidPasswordLength;
                    return newState;
                } else if (state.selectedUser.password.search(/[a-z]/) === -1 || state.selectedUser.password.search(/[A-Z]/) === -1) {
                    newState.password_userError = InternalUserFieldErrors.invalidCasePassword;
                    return newState;
                } else if (state.selectedUser.password.search(/[$\~\`\!@#\$%\^&\*\(\)_\+\-=\|<>\?]/) === -1) {
                    newState.password_userError = InternalUserFieldErrors.specialCharPassword;
                    return newState;
                }

                if (state.selectedUser.password != state.selectedUser.confirmedPassword) {
                    newState.confirmPassword_userError = InternalUserFieldErrors.matchPassword;
                    return newState;
                }
                let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

                if (state.selectedUser.email) {
                    if (state.selectedUser.email !== "") {
                        if (state.selectedUser.email.indexOf('@') === -1 ||
                            state.selectedUser.email.indexOf('.') === -1 ||
                            !state.selectedUser.email.match(mailformat)) {
                            newState.email_userError = InternalUserFieldErrors.valideMail;
                            return newState;
                        }
                    }
                }
                // Check for duplicate Login Name
                let duplicateUser = state.internalUsers.find(xuser => xuser.loginName.toUpperCase() === state.selectedUser.loginName.toUpperCase() && xuser.id !== state.selectedUser.id && xuser.userType === state.selectedUser.userType);
                if (duplicateUser) {
                    if (!duplicateUser.removed) {
                        newState.loginName_userError = InternalUserFieldErrors.existingUserName;
                        return newState;
                    }
                }
                if (state.selectedUser.email != "") {
                    let duplicateEmail = state.internalUsers.find(xuser => xuser.email.toUpperCase() === state.selectedUser.email.toUpperCase() && xuser.id != state.selectedUser.id && xuser.userType === state.selectedUser.userType);
                    if (duplicateEmail) {
                        newState.email_userError = InternalUserFieldErrors.existingUserEmail;
                        return newState;
                    }
                }
                const updatedUsers = newState.internalUsers;
                const addedUser = { ...state.selectedUser, added: true, id: uniqueId('#-'), displayName: action.data.userInfo.loginName, userType: state.tabSelectionState };
                addedUser.userType = state.tabSelectionState;
                updatedUsers.push(addedUser);
                return {
                    ...newState, internalUsers: updatedUsers,
                    selectedUser: { ...addedUser, userType: state.tabSelectionState }
                };
            }
            case 'SET_IU_FEATURE_PREVIEW': {
                const newState = { ...state };
                const updatedUser = { ...newState.internalUsers.find(user => user.id === action.data.userId) }
                updatedUser.itemName = action.data.ItemName;
                updatedUser.itemValue = action.data.ItemValue;
            }
            case 'UM_SET_BUSY':
                return {
                    ...state,
                    isBusy: action.data,
                }
            case 'RESTORE_USER':
                {
                    if (action.data.masterCrud) {
                        let uiIndex: number = 0;
                        var restoreUser = state.internalUsers.find((xusr, index) => {
                            if (xusr.removed !== true) {
                                uiIndex++;
                            }
                            return (xusr.loginName === action.data.uiData["@LoginName"]);
                        });
                        uiIndex++;  // Increment for the existing note type (above loop won't find it because it's still '1'), which will be the new UI index.

                        if (restoreUser) {
                            restoreUser.removed = false;
                            return {
                                ...state,
                                selectedUser: restoreUser,

                            };
                        }
                    }
                }
                break;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
