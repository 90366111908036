import { Reducer, Action } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ISubmitParallonFacilitiesUIState {
    parallonFacilitiesFileName: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface IUploadParallonFacilitiesUpdate {
    value: string;
};
export interface ISubmitParallonFacilitiesData extends IApiActionData<any, IUploadParallonFacilitiesUpdate> { }


export const actionCreators = {
    selectSubmitParallonFacilitiesFile: (selectInfo: ISubmitParallonFacilitiesData) => createDataAction('CHANGE_PARALLON_FACILITIES_FILE', selectInfo),
};



export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: ISubmitParallonFacilitiesUIState = {
    parallonFacilitiesFileName: '',
};


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ISubmitParallonFacilitiesUIState, KnownActions> = (state: ISubmitParallonFacilitiesUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'CHANGE_PARALLON_FACILITIES_FILE':
                {
                    return {
                        ...state,
                        parallonFacilitiesFileName: action.data.uiData.value
                    }
                }

            default:
                return state;
        }
    }
    return state || defaultState;
}

