import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as SampleApiDataGridUI from './api/SampleApiDataGridUI';

export type ISampleApiDataGridState = IApiComponentState<any, SampleApiDataGridUI.ISampleApiDataGridUIState>;

export const actionCreators = createApiComponentActions<SampleApiDataGridUI.ActionCreators>(SampleApiDataGridUI.actionCreators);
export type ISampleApiDataGridActions = typeof actionCreators;
export type ISampleApiDataGridActionProps = IApiComponentActionProps<ISampleApiDataGridActions>

export const reducers = createApiComponentReducers("sampleApiDataGrid", SampleApiDataGridUI.reducer);
export const reducer = combineReducers<ISampleApiDataGridState>(reducers);

