import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { Dropzone } from 'ui-core';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper } from '@common/DialogWrapper';
import { RemitLobs } from './RemitLobs';
import { ARMNarrowBrowse } from '@common/ARMNarrowBrowse';
import { getRawToken } from '@scripts/session/SecurityToken';
import { SubmitRemitFileRequest } from '@store/ui/SubmitRemitsUI';
import { URLs } from '@commonDevResources/constants';
import { pageLeave } from '@commonResources/userModified';

import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';

import { SubmitRemitsUiState, SubmitRemitsActionProps, actionCreators } from
    '@store/SubmitRemits';

//
//HARD-CODED STYLES
//
// Need to include width 100% when setting "display: flex" for IE 11
//https://stackoverflow.com/questions/21600345/flexbox-and-internet-explorer-11-displayflex-in-html
const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
`;


//
// INTERFACE, TYPE, AND CLASS DEFINITIONS
//
interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    title: string,
}

export const DEFAULT_STATE: IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type ISubmitRemitsProps = IMergeCrudComponentProps<SubmitRemitsUiState, SubmitRemitsActionProps, IOwnProps>;


interface IComponentState {

}


export class SubmitRemits extends React.Component<ISubmitRemitsProps, IComponentState> {
    static defaultProps: IComponentProps = {
        canEdit: true,
        canView: true,
        title: "Submit Remit File",
    };

    constructor(props: ISubmitRemitsProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }
    componentDidMount() {
        this.props.action.ui.loadRemitLobs();
    }

    async componentDidUpdate() {
        if (this.props.dataStore.ui.redirectHome) {

            //const uri = `${URLs.api}/api/data/securityInfo/logonPageAccessStatus?path=${escape()}`;
            //return fetch(uri,
            //    {
            //        method: 'GET',
            //        headers: {
            //            'Accept': 'text/plain',
            //            'Authorization': `${getRawToken()}`
            //        }
            //    }).then(async response => {
            //        const data: any = await response.json();
            //    });
            this.props.history.push("/LandingPage");
        }
    }

    // TODO - Replace this with a thunk redirect.  Current issue is that we get a 401 when we check page auth for landing page.
    // Need to see what the difference is between the redirect here and the one in User Password Hints.
    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    //Kick off the validate -> submit -> redirect action chain.
    // ReSharper disable once InconsistentNaming
    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        const fileName: string = this.props.dataStore.ui.fileName;
        const selectedLob: string = this.props.dataStore.ui.selectedLob ?? '';
        if (Boolean(fileName) && Boolean(selectedLob)) {
            //this.submitFileAndRedirect(
            //    { FileName: fileName, RemitLobId: selectedLob, Files: this.props.dataStore.ui.uploadFile });
            this.props.action.ui.submitFile({
                FileName: fileName,
                RemitLobId: selectedLob,
                Files: this.props.dataStore.ui.uploadFile,
            }, this.props.history);
        }
        else
            console.log("Tried to submit without a file and/or a LOB selected.")
    }

    // TODO - Fix Redux method and move this call over there. Problem is currently that the logonPageAccess fails to revalidate.
    //public submitFileAndRedirect(request: SubmitRemitFileRequest) {
    //        fetch(`${URLs.api}/api/data/remits/submitFile/${request.RemitLobId}?fileName=${JSON.stringify(request.FileName)}`,
    //        {
    //            method: 'POST',
    //            headers: {
    //                'Authorization': `${getRawToken()}`
    //            },
    //            body: request.Files,
    //        })
    //        .then(async (response) => {
    //            // get json response here
    //            let data: any = await response.json();
    //            this.props.history.push("/LandingPage");
    //        })
    //        .catch(error => {
    //            console.error('WGD: ' + error);
    //            this.setState({ isUpdating: false });
    //        });
    //}

    //If the user wants to cancel, kick them back to the landing page.
    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    public render() {
        // Instructions displayed at the bottom of the page.
        const bottomInstructions = <React.Fragment>In order to upload a file, Browse to the location of the file and click the OK button. The upload process may take several minutes depending on the size of the file that is being uploaded.</React.Fragment>;

        //Buttons in the dialog wrapper footer.
        const buttons =
            <OKCancelButtons
                disableOK={!Boolean(this.props.dataStore.ui.fileName) || !Boolean(this.props.dataStore.ui.selectedLob)}
                onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)}
                onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        return (
            <DialogWrapper title="Submit Remit File" helpUrl='/Support/Help/HELP_RMT_SubmitRemitFile.htm' width="36.344rem" instruction={bottomInstructions} buttons={buttons} isBusy={this.props.dataStore.ui.loading}>
                <ContentWrapper>
                    <ContentRowWrapper style={{ marginBottom: "10px" }}>
                        <ARMNarrowBrowse
                            labelValue="Remit File:"
                            inputId="remit-file-upload"
                            classNameValue="text-input"
                            maxLength={450}
                            initialValue={this.props.dataStore.ui?.fileName ?? ''}
                            disabledInput={true}
                            browseId="remitfileupload"
                            browseInputId="remitfile"
                            onChange={(e: any) => this.props.action.ui.uploadFile(e)}
                            styleValue={{ marginTop: "20px", marginLeft: "10px" }}
                            inputColumnStyleValue={{ marginTop: "10px", marginLeft: "5px", width: "455px" }}
                            browseColumnStyleValue={{ marginTop: "5px", marginLeft: "100px" }}
                            divBrowseStyle={{ marginTop: '7px' }}
                            canEdit                        
                            />
                    </ContentRowWrapper>
                    <RemitLobs
                        remitLobs={this.props.dataStore.ui.remitLobs}
                        selectedLob={this.props.dataStore.ui.selectedLob}
                        filterFunction={this.props.action.ui.filterLobs}
                        selectFunction={this.props.action.ui.selectLob}
                    />
                </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<SubmitRemitsUiState,
    SubmitRemitsActionProps,
    IOwnProps,
    ISubmitRemitsProps,
    ApplicationState>(
        createCrudMapStateToProps('submitRemits'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(SubmitRemits);

export default withRouter(connectedHoc);