export class JobType {
    private id: string = '';
    private name: string = '';

    get ID(): string {
        return this.id;
    }
    set ID(value: string) {
        this.id = value;
    }

    get Name(): string {
        return this.name;
    }
    set Name(value: string) {
        this.name = value;
    }

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class JobState {
    private id: string = '';
    private name: string = '';

    get ID(): string {
        return this.id;
    }
    set ID(value: string) {
        this.id = value;
    }

    get Name(): string {
        return this.name;
    }
    set Name(value: string) {
        this.name = value;
    }
    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class Client {
    private id: string = '';
    private name: string = '';

    get ID(): string {
        return this.id;
    }
    set ID(value: string) {
        this.id = value;
    }

    get Name(): string {
        return this.name;
    }
    set Name(value: string) {
        this.Name = value;
    }

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class Server {
    private id: string = '';
    private name: string = '';

    get ID(): string {
        return this.id;
    }
    set ID(value: string) {
        this.id = value;
    }
    get Name(): string {
        return this.name;
    }
    set Name(value: string) {
        this.name = value;
    }
    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class Command {
    private id: string = '';
    private name: string = '';

    get ID(): string {
        return this.id;
    }
    set ID(value: string) {
        this.id = value;
    }
    get Name(): string {
        return this.name;
    }
    set Name(value: string) {
        this.name = value;
    }
    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

export class ChildJob {
    private id: string = '';
    private serverName: string = '';
    private stateName: string = '';
    private jobName: string = '';
    private clientId: string = '';
    private clientAlias: string = '';
    private enabled: string = '';
    private progressTotal: string = '';
    private progressCurrent: string = '';
    private lastStatusDateTime: string = '';
    private endDateTime: string = '';
    private startDateTime: string = '';
    private queuedDate: string = '';
    private createDate: string = '';
    private serverId: string = '';
    private userId: string = '';
    private userName: string = '';
    private stateId: string = '';
    private typeId: string = '';
    private grandChildJob: Array<ChildJob> = new Array<ChildJob>();
    private commandList: Array<Command> = new Array<Command>();

    public get ID(): string {
        return this.id;
    }
    public set ID(value: string) {
        this.id = value;
    }

    public get ServerName(): string {
        return this.serverName;
    }
    public set ServerName(value: string) {
        this.serverName = value;
    }

    public get StateName(): string {
        return this.stateName;
    }
    public set StateName(value: string) {
        this.stateName = value;
    }

    public get JobName(): string {
        return this.jobName;
    }
    public set JobName(value: string) {
        this.jobName = value;
    }

    public get ClientId(): string {
        return this.clientId;
    }
    public set ClientId(value: string) {
        this.clientId = value;
    }

    public get ClientAlias(): string {
        return this.clientAlias;
    }
    public set ClientAlias(value: string) {
        this.clientAlias = value;
    }
    public get Enabled(): string {
        return this.enabled;
    }
    public set Enabled(value: string) {
        this.enabled = value;
    }

    public get ProgressTotal(): string {
        return this.progressTotal;
    }
    public set ProgressTotal(value: string) {
        this.progressTotal = value;
    }

    public get ProgressCurrent(): string {
        return this.progressCurrent;
    }
    public set ProgressCurrent(value: string) {
        this.progressCurrent = value;
    }

    public get LastStatusDateTime(): string {
        return this.lastStatusDateTime;
    }
    public set LastStatusDateTime(value: string) {
        this.lastStatusDateTime = value;
    }

    public get EndDateTime(): string {
        return this.endDateTime;
    }
    public set EndDateTime(value: string) {
        this.endDateTime = value;
    }

    public get StartDateTime(): string {
        return this.startDateTime;
    }
    public set StartDateTime(value: string) {
        this.startDateTime = value;
    }

    public get QueuedDate(): string {
        return this.queuedDate;
    }
    public set QueuedDate(value: string) {
        this.queuedDate = value;
    }

    public get CreateDate(): string {
        return this.createDate;
    }
    public set CreateDate(value: string) {
        this.createDate = value;
    }

    public get ServerId(): string {
        return this.serverId;
    }
    public set ServerId(value: string) {
        this.serverId = value;
    }

    public get UserId(): string {
        return this.userId;
    }
    public set UserId(value: string) {
        this.userId = value;
    }

    public get UserName(): string {
        return this.userName;
    }
    public set UserName(value: string) {
        this.userName = value;
    }

    public get StateId(): string {
        return this.stateId;
    }
    public set StateId(value: string) {
        this.stateId = value;
    }

    public get TypeId(): string {
        return this.typeId;
    }
    public set TypeId(value: string) {
        this.typeId = value;
    }

    public get CommandList(): Array<Command> {
        return this.commandList;
    }
    public set CommandList(value: Array<Command>) {
        this.commandList = value;
    }

    public get GrandChildJobList(): Array<ChildJob> {
        return this.grandChildJob;
    }
    public set GrandChildJobList(value: Array<ChildJob>) {
        this.grandChildJob = value;
    }
}

export class Job {
    private id: string = '';
    private serverName: string = '';
    private stateName: string = '';
    private jobName: string = '';
    private clientId: string = '';
    private clientAlias: string = '';
    private enabled: string = '';
    private progressTotal: string = '';
    private progressCurrent: string = '';
    private lastStatusDateTime: string = '';
    private endDateTime: string = '';
    private startDateTime: string = '';
    private queuedDate: string = '';
    private createDate: string = '';
    private serverId: string = '';
    private userId: string = '';
    private userName: string = '';
    private stateId: string = '';
    private typeId: string = '';
    private commandList: Array<Command> = new Array<Command>();
    private childJob: Array<ChildJob> = new Array<ChildJob>();

    public get ID(): string {
        return this.id;
    }
    public set ID(value: string) {
        this.id = value;
    }

    public get ServerName(): string {
        return this.serverName;
    }
    public set ServerName(value: string) {
        this.serverName = value;
    }

    public get StateName(): string {
        return this.stateName;
    }
    public set StateName(value: string) {
        this.stateName = value;
    }

    public get JobName(): string {
        return this.jobName;
    }
    public set JobName(value: string) {
        this.jobName = value;
    }

    public get ClientId(): string {
        return this.clientId;
    }
    public set ClientId(value: string) {
        this.clientId = value;
    }

    public get ClientAlias(): string {
        return this.clientAlias;
    }
    public set ClientAlias(value: string) {
        this.clientAlias = value;
    }
    public get Enabled(): string {
        return this.enabled;
    }
    public set Enabled(value: string) {
        this.enabled = value;
    }

    public get ProgressTotal(): string {
        return this.progressTotal;
    }
    public set ProgressTotal(value: string) {
        this.progressTotal = value;
    }

    public get ProgressCurrent(): string {
        return this.progressCurrent;
    }
    public set ProgressCurrent(value: string) {
        this.progressCurrent = value;
    }

    public get LastStatusDateTime(): string {
        return this.lastStatusDateTime;
    }
    public set LastStatusDateTime(value: string) {
        this.lastStatusDateTime = value;
    }

    public get EndDateTime(): string {
        return this.endDateTime;
    }
    public set EndDateTime(value: string) {
        this.endDateTime = value;
    }

    public get StartDateTime(): string {
        return this.startDateTime;
    }
    public set StartDateTime(value: string) {
        this.startDateTime = value;
    }

    public get QueuedDate(): string {
        return this.queuedDate;
    }
    public set QueuedDate(value: string) {
        this.queuedDate = value;
    }

    public get CreateDate(): string {
        return this.createDate;
    }
    public set CreateDate(value: string) {
        this.createDate = value;
    }

    public get ServerId(): string {
        return this.serverId;
    }
    public set ServerId(value: string) {
        this.serverId = value;
    }

    public get UserId(): string {
        return this.userId;
    }
    public set UserId(value: string) {
        this.userId = value;
    }

    public get UserName(): string {
        return this.userName;
    }
    public set UserName(value: string) {
        this.userName = value;
    }

    public get StateId(): string {
        return this.stateId;
    }
    public set StateId(value: string) {
        this.stateId = value;
    }

    public get TypeId(): string {
        return this.typeId;
    }
    public set TypeId(value: string) {
        this.typeId = value;
    }

    public get CommandList(): Array<Command> {
        return this.commandList;
    }
    public set CommandList(value: Array<Command>) {
        this.commandList = value;
    }

    public get ChildJob(): Array<ChildJob> {
        return this.childJob;
    }
    public set ChildJob(value: Array<ChildJob>) {
        this.childJob = value;
    }
}

export class Jobs {
    private jobList: Array<Job> = new Array<Job>();
    private start: string = "";
    private total: string = "";

    public get JobList(): Array<Job> {
        return this.jobList;
    }
    public set JobList(value: Array<Job>) {
        this.jobList = value;
    }
    public get Start(): string {
        return this.start;
    }
    public set Start(value: string) {
        this.start = value;
    }
    public get Total(): string {
        return this.total;
    }
    public set Total(value: string) {
        this.total = value;
    }
}

export interface ILoadJobQueueRequest {
    "StartRecord": string;
    "JobsPerPage": string;
    "JobTypeId": string;
    "JobStateId": string;
    "ClientId"?: string;
    "AppServerId"?: string;
    "OrderBy": string;
    "OrderType": string;
    "QueueFrom": string;
    "QueueTo": string;
}

export class JobQueueData {

    AppServerId: string = "";
    AppServerName: string = "";
    ChildJobs: JobQueueData[] = [];

    ClientId: string = "";
    ClientAlias: string = "";
    
    JobName: any;
    Name: string = "";
    CommandList: any;
    CreateDate: string = "";
    Enabled: string = "";
    EndDateTime: string = "";
    ID: string = "";

    LastStatusDateTime: string = "";
    ProgressCurrent: string = "";
    ProgressTotal: string = "";
    QueuedDate: string = "";
    StartDateTime: string = "";

    StateId: string = "";
    StateName: any;
    StateValue: string = "";

    TypeId: string = "";

    UserId: string = "";
    UserName: string = "";

    Start: string = "";
    Total: string = "";
}

export interface IInsertJobRequest {
    "ID": string;
    "Command": string;
    "Server": string;
}

export interface IRowProps {
    record: {
        JobName: any,
        StateName: string,
        ClientAlias: string,
        AppServerName?: string,
        UserName: string,
        QueuedDate: string,
        StartDateTime: string,
        EndDateTime: string,
        CommandList?: any
    }
}