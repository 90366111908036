import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { Button } from 'ui-core';
import { RadioButtonComponent } from '@common/RadioButton';
import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { ARMUserComponent, IARMUserData } from '@common/ARMUserComponent';
import { ARMDepartmentComponent, IARMDeptData } from '@common/ARMDepartmentComponent';
import { DialogFieldset, DialogLegend, HelpIcon } from '@common/DialogStyles';
import { ContentColumnWrapper, ContentRowWrapper, FlexBoxModifier } from '@common/DialogWrapper';
import { bindAll } from 'lodash';

export enum eRulesAssignmentModifyButtonType {
    Add,
    Update
}

export interface IRulesAssignUserInfo extends IARMUserData {
}

export interface IRulesAssignDepartmentInfo extends IARMDeptData {
}

export interface IRuleAssignmentComponentProps {
    className?: string,
    containerTitle: string,
    allUsers: IRulesAssignUserInfo[],
    allDepartments: IRulesAssignDepartmentInfo[],
    showExpiresOn?: boolean,
    expiresOnValue?: string,        // 'mm/dd/yyyy' format, blank shows format in display, undefined shows 'today'
    buttonType: eRulesAssignmentModifyButtonType,
    enableButtons: boolean,
    onNewCallback?(selectedUsers: IRulesAssignUserInfo[], selectedDepartment: IRulesAssignDepartmentInfo, expiresOn?: string): void,
    onUpdateCallback?(selectedUsers: IRulesAssignUserInfo[], selectedDepartment: IRulesAssignDepartmentInfo, expiresOn?: string): void,
    onRemoveCallback?(selectedUsers: IRulesAssignUserInfo[], selectedDepartment: IRulesAssignDepartmentInfo, expiresOn?: string): void,
    onSelectUsersCallback?(items: IRulesAssignUserInfo[]): void,
    onUserAlphaSplitCallback?(item: IRulesAssignUserInfo, newValue: string): void,
    onSelectDepartmentsCallback?(item: IRulesAssignDepartmentInfo): void,
    onUpdateExpiresOnCallback?(item?: string): void,
    selectedDepartment: IRulesAssignDepartmentInfo; 
}

export interface IRuleAssignmentComponentState {

}

export const RuleAssignmentComponentContainerDiv = styled.div<Partial<IRuleAssignmentComponentProps>>`
    className?: classname
    display: flex;
    flex: 1 1 auto;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
    display: flex;
    flex-direction: column;
`;

export const ButtonRow = styled.div`
    padding-bottom:2px;
`;

export class RuleAssignmentComponent extends React.Component<IRuleAssignmentComponentProps, IRuleAssignmentComponentState> {
    public selectedUsers: IRulesAssignUserInfo[];
    public selectedDepartment: IRulesAssignDepartmentInfo;
    public expiresOn: string | undefined;

    constructor(props: IRuleAssignmentComponentProps) {
        super(props)
        //console.log('RuleAssignmentComponent props: '); 
        //console.dir(props);

        this.selectedUsers = [];
        this.selectedDepartment = undefined
        this.expiresOn = undefined;

        bindAll(this, ['onClickNew', 'onClickUpdate', 'onClickRemove', 'selectDepartment', 'selectUser', 'userAlphaSplitChanged']);
    }

    public selectDepartment(item: IRulesAssignDepartmentInfo) {
        //console.log('selectDepartment was called by subcomponent');
        //console.dir(items);
        this.selectedDepartment = item;
        if (this.props?.onSelectDepartmentsCallback) {
            this.props.onSelectDepartmentsCallback(item);
        }
    }

    public selectUser(items: IRulesAssignUserInfo[]) {
        //console.log('selectUser was called by subcomponent');
        //console.dir(items);
        this.selectedUsers = items;
        if (this.props?.onSelectUsersCallback) {
            this.props.onSelectUsersCallback(items);
        }
    }

    public userAlphaSplitChanged(item: IRulesAssignUserInfo, newValue: string) {
        //console.log('userAlphaSplitChanged was called by subcomponent - newValue: ' + newValue);
        //console.dir(item);
        if (this.props?.onUserAlphaSplitCallback) {
            this.props.onUserAlphaSplitCallback(item, newValue);
        }
    }

    public onExpireDateChanged(value: string) {
        this.expiresOn = value;
        if (this.props.onUpdateExpiresOnCallback) {
            this.props.onUpdateExpiresOnCallback(value);
        }
    }

    public renderExpireOn() {
        if (this.props?.showExpiresOn) {
            return (
                <ARMDatePicker
                    domID="rule-assign-expire"
                    className="cal_style"
                    onInputChange={(e: any) => this.onExpireDateChanged(e)}
                    allowTime={true}
                    label="Expires On:"
                    initialDate={this.props.expiresOnValue}
                />
            )
        }
    }

    public onClickNew(e: React.SyntheticEvent<HTMLButtonElement>) {
        if (this.props?.onNewCallback) {
            this.props?.onNewCallback(this.selectedUsers, this.selectedDepartment, this.expiresOn);
        }
    }

    public onClickUpdate(e: React.SyntheticEvent<HTMLButtonElement>) {
        if (this.props?.onUpdateCallback) {
            this.props?.onUpdateCallback(this.selectedUsers, this.selectedDepartment, this.expiresOn);
        }
    }

    public onClickRemove(e: React.SyntheticEvent<HTMLButtonElement>) {
        if (this.props?.onRemoveCallback) {
            this.props?.onRemoveCallback(this.selectedUsers, this.selectedDepartment, this.expiresOn);
        }
    }

    public renderButtons() {
        if (this.props?.buttonType === eRulesAssignmentModifyButtonType.Add) {
            return (
                <ButtonRow>
                    <Button name="NEW" onClick={this.onClickNew} domID="ra-new-btn" disabled={!this.props.enableButtons} />
                </ButtonRow>
            )
        } else {
            return (
                <React.Fragment>
                    <ButtonRow>
                        <FlexBoxModifier as={ContentRowWrapper} flexGap="8px" justifyContent="left">
                            <Button name="UPDATE" onClick={this.onClickUpdate} domID="ra-update-btn" disabled={!this.props.enableButtons} />
                            <Button name="REMOVE" onClick={this.onClickRemove} domID="ra-remove-btn" disabled={!this.props.enableButtons} />
                        </FlexBoxModifier>
                    </ButtonRow>
                </React.Fragment>
            )

        }
    }

    public render() {
        const title: string = this.props.containerTitle;
        let assignableUsers = this.props?.allUsers ?? [];
        let assignableDepartment = this.props?.allDepartments ?? [];
        let selectedDepartment = this.props?.selectedDepartment ?? this.selectedDepartment

        return (
            <RuleAssignmentComponentContainerDiv className={this.props.className}>
                <DialogFieldset>
                    <DialogLegend>{title}</DialogLegend>
                    <FlexBoxModifier as={ContentColumnWrapper} flexGap="8px">
                        <FlexBoxModifier as={ContentRowWrapper} flexGap="8px" justifyContent="center">
                            <ARMUserComponent
                                users={{
                                    headings: [
                                        { title: '', maxWidth: '' },
                                        { title: 'User', maxWidth: '' },
                                        { title: 'Alpha Split', maxWidth: '95px' }
                                    ],
                                    users: assignableUsers,
                                }}
                                onSelectCallback={this.selectUser}
                                onAlphaSplitChangedCallback={this.userAlphaSplitChanged}
                            />
                            <ARMDepartmentComponent
                                depts={{
                                    heading: ['', 'Department'],
                                    depts: assignableDepartment,
                                }}
                                selectedDepartment={selectedDepartment}
                                selectDepartmentHandler={(item: any) => this.selectDepartment(item)}
                            />
                        </FlexBoxModifier>
                        <FlexBoxModifier as={ContentRowWrapper} flexGap="8px" justifyContent="center" alignItems="flex-end">
                            {this.renderExpireOn()}
                            {this.renderButtons()}
                        </FlexBoxModifier>
                    </FlexBoxModifier>
                </DialogFieldset>
            </RuleAssignmentComponentContainerDiv>
        );
    };
}
