import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as RemitImportOptionsUI from './ui/RemitImportOptionsUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IRemitImportOptionsState = ICrudComponentState<MCRemitImportOptionsType, RemitImportOptionsUI.IRemitImportOptionsUIState>;

export const actionCreators = createCrudComponentActions<RemitImportOptionsUI.ActionCreators>(RemitImportOptionsUI.actionCreators);
export type IRemitImportOptionsActions = typeof actionCreators;
export type IRemitImportOptionsActionProps = ICrudComponentActionProps<IRemitImportOptionsActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiRemitOptionsSetup, RemitImportOptionsUI.reducer);
export const reducer = combineReducers<IRemitImportOptionsState>(reducers);

export const validationCallback: ValidationCallback<MCRemitImportOptionsType> = (crud: any): crud is MCRemitImportOptionsType => {
    let testCrud: MCRemitImportOptionsType = crud as MCRemitImportOptionsType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCRemitImportOptionsType");
    if (validShape) {
        if (testCrud.RemitImportMaintenanceInfo?.RemitImports as any === "") {
            testCrud.RemitImportMaintenanceInfo.RemitImports = { RemitImport: new Array<MCMR_RemitImport>() };
        }
        if (testCrud.RemitImportMaintenanceInfo.RemitImports &&
            !Array.isArray(testCrud.RemitImportMaintenanceInfo.RemitImports.RemitImport)) {
            let arr = new Array<MCMR_RemitImport>();
            arr.push(JSON.parse(JSON.stringify(testCrud.RemitImportMaintenanceInfo.RemitImports.RemitImport)));
            arr[0]["@Delete"] = 'false';
            testCrud.RemitImportMaintenanceInfo.RemitImports.RemitImport = arr;
        }
        else {
            testCrud.RemitImportMaintenanceInfo.RemitImports.RemitImport.forEach((item) => {
                item["@Delete"] = 'false'
            });
        }

        if (testCrud.RemitImportMaintenanceInfo?.RemitLobList as any === "") {
            testCrud.RemitImportMaintenanceInfo.RemitLobList = { RemitLob: new Array<MCMR_RemitLob>() };
        }
        if (testCrud.RemitImportMaintenanceInfo.RemitLobList &&
            !Array.isArray(testCrud.RemitImportMaintenanceInfo.RemitLobList.RemitLob)) {
            let arr = new Array<MCMR_RemitLob>();
            arr.push(JSON.parse(JSON.stringify(testCrud.RemitImportMaintenanceInfo.RemitLobList.RemitLob)));
            testCrud.RemitImportMaintenanceInfo.RemitLobList.RemitLob = arr;
        }

        if (testCrud.RemitImportMaintenanceInfo?.RemitImportOverrideSettings as any === "") {
            testCrud.RemitImportMaintenanceInfo.RemitImportOverrideSettings = { RemitImportOverrideSetting: { "@ID": "", "@ISA06Value": "" } }
        }
    }

    return validShape;
}