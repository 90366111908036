import * as React from 'react';
import { RouteComponentProps, withRouter, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper, DialogButtonOK, DialogButtonCancel } from '@common/DialogWrapper';
import { SelectComponent } from '@common/SelectComponent';

import {
    createApiMapDispatchToProps,
    createApiMapStateToProps,
    IMergeApiComponentProps,
    mergeApiComponentProps, resetApiComponentState
} from '@scripts/util/ApiDataHelpers';

import { ModalConfirmation } from '@common/ModalConfirmation';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IPcgPayerMaintenanceActionProps, IPcgPayerMaintenanceState, actionCreators } from '@store/PcgPayerMaintenance';
import AssuranceMenu from '../../common/AssuranceMenu';
import { getRawToken } from '@scripts/session/SecurityToken';
import { URLs } from '../../../commonResources/constants';
import { Grid, IGridColumn, IGridProps } from './Grid';
import { SortDataType } from '../../../scripts/util/SortHelper';
import { PayerDetailsGrid } from './PayerDetailsGrid';
import { size } from 'lodash';
import { GridConfig } from './GridConfig';
import { PayerDropdown } from './PayerDropdown';
import { createCrudMapDispatchToProps, createCrudMapStateToProps, IMergeCrudComponentProps, mergeCrudComponentProps, resetCrudComponentState } from '../../../scripts/util/CrudComponentHelpers';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-grow:initial;
    flex-direction: column;
`;
export const ContentWrapper1 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-grow:initial;
    flex-direction: row;
`;

export const ContentWrapper2 = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-grow:initial;
    flex-direction: row;
    min-width: 600px;
    .option-class {
        font-size: 1.0rem !important;
    }
`;
export const Table = styled.table`
     width: 100%;
    border-collapse: collapse;
    td, th {
        padding: 0.75rem;
        border-top: 1px solid #dee2e6;
        border-left: 1px solid #dee2e6;
    }
    tr td:first-child, 
    tr th:first-child {
        border-left: none !important;
    } 

`;

export const SelectList = styled.div`
    
`;

const DropdownBtn = styled("button")`
    background: #c3c5cd;
    border-radius: 0px;
    border: none;
    display: block;
    width: 100%;
    color: black;
    padding: 5px;
    font-size: 11px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 5px;
    position: relative;
    &.asc:after {
        display: inline-block;
        margin-right: 0.55em;
        margin-top: 0.55em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.5em solid;
        border-right: 0.5em solid transparent;
        border-bottom: 0;
        border-left: 0.5em solid transparent;
        position: absolute;
        right: 5px;
        top: 5px;
    }
    &.desc:after {
        display: inline-block;
        margin-right: 0.55em;
        margin-top: 0.25em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0;
        border-right: 0.5em solid transparent;
        border-bottom: 0.5em solid;
        border-left: 0.5em solid transparent;
        position: absolute;
        right: 5px;
        top: 5px;
    }
        `
export const SortButton = (props: any) => {
    const [sort, setSort] = React.useState('asc');
    const { onSort = () => { } } = props;
    const onClick = () => {
        setSort(sort == 'asc' ? 'desc' : 'asc');
        onSort(sort == 'asc' ? 'desc' : 'asc')
    }
    return (<>
        <DropdownBtn className={sort} onClick={onClick}>{props.children}</DropdownBtn>
    </>)
}
export const BottomButton = styled('button')`
    background: #0F0F59;
    color: white;
    border: 1px solid white;
    border-radius: 3px;
    :disabled {
        background-color: transparent;
    border-color: #3F607F;
    color: #3F607F;
  cursor: pointer;
    pointer-events: none;
    }
`;
export const ToastContainer = styled('div')`
    position: fixed;
    right: 10px;
    top: 10px;
    width: 250px;
    z-index: 99999;
    border: 1px solid transparent;
    .toast {
        background: #e2e3e5;
        border-radius: 8px;
        padding: 10px;
        border-color: #d6d8db;
        color: #383d41;
    }
    .toast.success {
        background: #d4edda;
        border-color: #c3e6cb;
        color: #155724;
    }
    .toast.error {
        background: #f8d7da;
        border-color: #f5c6cb;
        color: #721c24;
    }
    .toast.info {
        background: #004085;
        border-color: #cce5ff;
        color: #b8daff;
    }
    .toast.warn {
        background: #fff3cd;
        border-color: #ffeeba;
        color: #856404;
    }
    .toast-head {
        font-weight: bold;
    }
    .toast-message {
        font-size: 13px;
    }
`
export const Toast = (props: any) => {
    const { onClose = () => { }, type = 'success', message = '', heading = 'Success', timeout = 5000 } = props;
    setTimeout(() => {
        onClose()
    }, timeout)
    return (<>
        <ToastContainer>
            <div className={`toast ${type}`}>
                <div className="toast-head">{heading}</div>
                <div className="toast-message" >{message}</div>
            </div>
        </ToastContainer>
    </>)
}
export const AddRemoveCancel = (props: any) => {
    const { onAdd = (e: any) => { }, onRemove = (e: any) => { }, onCancel = (e: any) => { }, selectedPayer = null, canEdit = false } = props;

    return (<>
        <DialogButtonOK onClick={onAdd} name={"Add"} disable={!canEdit} />
        <DialogButtonOK onClick={onRemove} name={"Remove"} disable={selectedPayer == null || !canEdit} />
        <DialogButtonCancel onClick={onCancel} name={props.nameOverride || "Cancel"} />
    </>)
}

export const LoaderContainer = styled('div')`
    display: inlinr-flex;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50px;
    height: 50px;
    margin: -30px 0 0 -30px;
    -webkit-animation: spin 1s linear infinite;
    -moz-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    @-moz-keyframes spin {
        100% { -moz-transform: rotate(360deg);}
    }
    @-webkit-keyframes spin {
        100% { -webkit-transform: rotate(360deg);}
    }
    @keyframes spin {
        100% { 
            -webkit-transform: rotate(360deg);
            tranform: rotate(360deg);    
        }
    }
 }
`;

export const Loader = () => {
    return <>
        <LoaderContainer><svg className="null rotating" width="50px" height="50px" viewBox="-6 -6 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg" stroke="#C3C5CD" role="img" aria-label="loader"><g fill="none" fill-rule="evenodd"><g transform="translate(1 1)" stroke-width="4"><circle stroke-opacity=".3" cx="18" cy="18" r="18"></circle><path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(285.765 18 18)"></path></g></g></svg></LoaderContainer>
    </>
}

export const columns: IGridColumn[] = [
    {
        field: 'cpId',
        display: 'CPID',
        dataType: SortDataType.String,
        sortable: true,
    },
    {
        field: 'payerName',
        display: 'Payer Name',
        dataType: SortDataType.String,
        sortable: true,
    },

]

export const DEFAULT_STATE: IComponentState = {
    deleteConfirmation: false,
    gridOptions: {
        columns,
        rows: [],
    }

};

interface IComponentProps {
    canView: boolean;   // SecurityBits.FN_VIEW_USERS
    canEdit: boolean;   // SecurityBits.FN_EDIT_USERS
};

interface IComponentState {
    toastType?: string,
    toastMessage?: string,
    toastHeading?: string,
    showToast?: boolean,
    toastTimeout?: number,
    deleteConfirmation?: boolean
    gridOptions: IGridProps
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IPcgPayerMaintenanceProps = IMergeCrudComponentProps<IPcgPayerMaintenanceState, IPcgPayerMaintenanceActionProps, IOwnProps>;

class PcgPayerMaintenance extends React.Component<IPcgPayerMaintenanceProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canView: false,   // SecurityBits.FN_VIEW_USERS
        canEdit: false,   // SecurityBits.FN_EDIT_USERS
    };

    constructor(props: IPcgPayerMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        this.props.action.ui.getPayerNames();
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onAdd(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/SupportTools/PCGPayerMaintenanceAdd');
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/LandingPage');
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>, key: string) {
        this.setState(s => {
            const st: any = { ...s };
            st[key] = !st[key];
            return st;
        });
    }

    public setSelectedPayer(tpId: string) {
        this.props.action.ui.setSelectedPayer(tpId)
        this.props.action.ui.getPayerDetails(tpId);
    }

    async onDelete() {
        this.props.action.ui.deletePayerAndReload(this.props.dataStore.ui.selectedPayer);
    }

    onSort(e: any) {
        this.props.dataStore.ui.payersData.sort((a, b) => e === 'asc' ? a.tpIs > b.tpId ? 1 : -1 : a.tpId > b.tpId ? -1 : 1);
    }

    public setAlert(alertMsg: string) {
        this.props.action.ui.setModalText(alertMsg);
    }


    public render() {
        let instruction = <React.Fragment>To add a new supported PCG Payer ID, click Add and Check the number.  To remove a supported PCG Payer ID, click the value and Remove.</React.Fragment>;
        let buttons = <AddRemoveCancel
            selectedPayer={this.props.dataStore.ui.selectedPayer}
            canEdit={this.props.canEdit}
            onRemove={(e: any) => { this.onToggleConfirmation(e, 'deleteConfirmation') }}
            onAdd={(e: any) => { this.onAdd(e) }}
            onCancel={(e: any) => { this.onCancel(e) }}
        />

        return (<>
            <DialogWrapper title='PCG Supported PayerIDs' instruction={instruction} helpUrl='/Content/SupportTools/SUP_ClientSetup_PCGPayerMaintenance.htm' buttons={buttons} isBusy={this.props.dataStore?.ui?.isBusy}>
                <ContentWrapper style={{ background: 'white' }}>
                    <ContentWrapper2>
                        {this.state.showToast && <Toast timeout={this.state.toastTimeout} type={this.state.toastType} heading={this.state.toastHeading} message={this.state.toastMessage} onClose={() => { this.setState({ showToast: false }) }}></Toast>}
                        {/*    <SelectList style={{ maxWidth: '185px', width: `185px`, fontSize:'13px'}}>*/}
                        {/*        <DialogFieldset>*/}
                        {/*            <SortButton onSort={(e: any) => { this.onSort(e); }}>PCG Payer ID</SortButton>*/}
                        {/*            <SelectList style={{ maxWidth: '185px', width: `185px` }}>*/}
                        {/*                <DialogFieldset style={{ height: '98.5%', maxHeight: '452px', overflow: 'auto', position: 'relative' }}>*/}
                        {/*                    <PayerDropdown setSelectedPayer={this.setSelectedPayer.bind(this)} rows={this.props.dataStore.ui.payersData} /> */}
                        {/*                </DialogFieldset>*/}
                        {/*            </SelectList>*/}

                        {/*        </DialogFieldset>*/}

                        {/*</SelectList>*/}
                        <div style={{ maxWidth: '185px', width: `185px`, maxHeight: '452px', overflow: 'auto', position: 'relative' }}>
                            <PayerDropdown setSelectedPayer={this.setSelectedPayer.bind(this)} rows={this.props.dataStore.ui.payersData} />
                        </div>

                        <SelectList style={{ maxWidth: '450px', width: 'calc(100% - 175px)' }} >
                            <DialogFieldset style={{ height: '98.5%', maxHeight: '452px', overflow: 'auto', position: 'relative' }}>
                                <DialogLegend>Selected Payer Information</DialogLegend>
                                {this.props.dataStore.ui.isLoadingPayer && < Loader />}
                                <PayerDetailsGrid rows={this.props.dataStore.ui.payerData} />
                            </DialogFieldset>
                        </SelectList>
                    </ContentWrapper2>
                </ContentWrapper>
            </DialogWrapper>
            <ModalConfirmation
                isOpen={this.state.deleteConfirmation}
                onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e, 'deleteConfirmation')}
                formattedMessage={(
                    <div>
                        <p>Confirm removal of PCG Payer ID - <b>{this.props.dataStore.ui.selectedPayer}</b>?</p>
                        <p>Removing this ID will remove ability for Assurance to apply PCG edits on claims for this Payer ID for all customers.</p>
                    </div>)
                }
                onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => { this.onDelete() }}
            />
            <ModalConfirmation
                isOpen={this.props.dataStore.ui.modalText != ""}
                alertMode={true}
                message={this.props.dataStore.ui.modalText}
                onModalToggle={() => this.setAlert('')}
            />



        </>
        );
    }
};

let connectedHoc = connect<IPcgPayerMaintenanceState, IPcgPayerMaintenanceActionProps, IOwnProps, IPcgPayerMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps('pcgPayerMaintenance'),   // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),   // Selects which action creators are merged into the component's props
    mergeCrudComponentProps
)(PcgPayerMaintenance);

export default withRouter(connectedHoc);
