import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ViewBridgeLogUI from './ui/ViewBridgeLogUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IViewBridgeLogState = ICrudComponentState<VBLogsData, ViewBridgeLogUI.IVBLogUIState>;

export const actionCreators = createCrudComponentActions<ViewBridgeLogUI.ActionCreators>(ViewBridgeLogUI.actionCreators);
export type IViewBridgeLogActions = typeof actionCreators;
export type IViewBridgeLogActionProps = ICrudComponentActionProps<IViewBridgeLogActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimNotes, ViewBridgeLogUI.reducer);
export const reducer = combineReducers<IViewBridgeLogState>(reducers);
