import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IClaimTotalsUIState {
    clientId: string;
    unreleased: string;
    releasing: string;
    released: string;
    held: string;
    bridging: string;
    de: string;
    deleteCount: string;
    outSourceMarked: string;
    outSourceFilesCreated: string;
    outSourceSending: string;
    outSourced: string;
    stateReportingOnly: string;
    disableStateReportingOnly: boolean;
    testUnreleased: string;
    testReleased: string;
    testReleasing: string;
    testDeleteCount: string;
    rrtUnsent: string;
    rrtSending: string;
    rrtSent: string;
    rrtDeleted: string;
    rrtEnabled: string;
    totalClaims: string;
    selectedClient: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectClientData {
    value: string;
    text: string;
};

export const defaultFilter: ISelectClientData = {
    value: '',
    text: '',
};

export interface IClaimTotalsModel {
    ClaimTotalsModel: ClaimTotalsModel;
}

export const ClaimTotalsModel: IClaimTotalsModel = {
    ClaimTotalsModel: {
        clientId: "",
        unreleased: "",
        releasing: "",
        released: "",
        held: "",
        bridging: "",
        de: "",
        deleteCount: "",
        outSourceMarked: "",
        outSourceFilesCreated: "",
        outSourceSending: "",
        outSourced: "",
        stateReportingOnly: "",
        disableStateReportingOnly: false,
        testUnreleased: "",
        testReleased: "",
        testReleasing: "",
        testDeleteCount: "",
        rrtUnsent: "",
        rrtSending: "",
        rrtSent: "",
        rrtDeleted: "",
        totalClaims: "",
        rrtEnabled: "",
    }
};

export interface ISelectFilter extends ICrudActionData<ClaimTotalsType, ISelectClientData> { }
export interface IUpdateDetail extends ICrudActionData<ClaimTotalsType, IClaimTotalsModel> { }

export const actionCreators = {
    selectClient: (selectInfo: ISelectFilter) => createDataAction('SELECT_CLIENT', selectInfo),
    updateDetail: (updateInfo: IUpdateDetail) => createDataAction('UPDATE_DETAIL', updateInfo),
    selectedClient: (string: '') => createDataAction('SELECTED_CLIENT', string),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IClaimTotalsUIState = {
    clientId: "",
    unreleased: "",
    releasing: "",
    released: "",
    held: "",
    bridging: "",
    de: "",
    deleteCount: "",
    outSourceMarked: "",
    outSourceFilesCreated: "",
    outSourceSending: "",
    outSourced: "",
    stateReportingOnly: "",
    disableStateReportingOnly: false,
    testUnreleased: "",
    testReleased: "",
    testReleasing: "",
    testDeleteCount: "",
    rrtUnsent: "",
    rrtSending: "",
    rrtSent: "",
    rrtDeleted: "",
    totalClaims: "",
    selectedClient: "",
    rrtEnabled: ""
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IClaimTotalsUIState, KnownActions> = (state: IClaimTotalsUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_CLIENT':
                if (action.data.uiData.value === "") {
                    return {
                        ...state,
                        clientId: action.data.uiData.value,
                        id: action.data.uiData.value,
                        unreleased: defaultState.unreleased,
                        releasing: defaultState.releasing,
                        released: defaultState.released,
                        held: defaultState.held,
                        bridging: defaultState.bridging,
                        deleteCount: defaultState.deleteCount,
                        de: defaultState.de,
                        outSourceMarked: defaultState.outSourceMarked,
                        outSourceFilesCreated: defaultState.outSourceFilesCreated,
                        outSourceSending: defaultState.outSourceSending,
                        outSourced: defaultState.outSourced,
                        stateReportingOnly: defaultState.stateReportingOnly,
                        disableStateReportingOnly: false,
                        testUnreleased: defaultState.testUnreleased,
                        testReleased: defaultState.testReleased,
                        testReleasing: defaultState.testReleasing,
                        testDeleteCount: defaultState.testDeleteCount,
                        rrtUnsent: defaultState.rrtUnsent,
                        rrtSending: defaultState.rrtSending,
                        rrtSent: defaultState.rrtSent,
                        rrtDeleted: defaultState.rrtDeleted,
                        totalClaims: defaultState.totalClaims,
                        rrtEnabled: defaultState.rrtEnabled
                    }
                }
                else {
                    return {
                        ...state,
                        clientId: action.data.uiData.value,
                        id: action.data.uiData.value,
                    }
                }
                break;
            case 'UPDATE_DETAIL':
                if (action.data.uiData.ClaimTotalsModel !== null) {
                    return {
                        ...state,
                        unreleased: action.data.uiData.ClaimTotalsModel.unreleased,
                        releasing: action.data.uiData.ClaimTotalsModel.releasing,
                        released: action.data.uiData.ClaimTotalsModel.released,
                        held: action.data.uiData.ClaimTotalsModel.held,
                        bridging: action.data.uiData.ClaimTotalsModel.bridging,
                        deleteCount: action.data.uiData.ClaimTotalsModel.deleteCount,
                        de: action.data.uiData.ClaimTotalsModel.de,
                        outSourceMarked: action.data.uiData.ClaimTotalsModel.outSourceMarked,
                        outSourceFilesCreated: action.data.uiData.ClaimTotalsModel.outSourceFilesCreated,
                        outSourceSending: action.data.uiData.ClaimTotalsModel.outSourceSending,
                        outSourced: action.data.uiData.ClaimTotalsModel.outSourced,
                        stateReportingOnly: action.data.uiData.ClaimTotalsModel.stateReportingOnly,
                        disableStateReportingOnly: true,
                        testUnreleased: action.data.uiData.ClaimTotalsModel.testUnreleased,
                        testReleased: action.data.uiData.ClaimTotalsModel.testReleased,
                        testReleasing: action.data.uiData.ClaimTotalsModel.testReleasing,
                        testDeleteCount: action.data.uiData.ClaimTotalsModel.testDeleteCount,
                        rrtUnsent: action.data.uiData.ClaimTotalsModel.rrtUnsent,
                        rrtSending: action.data.uiData.ClaimTotalsModel.rrtSending,
                        rrtSent: action.data.uiData.ClaimTotalsModel.rrtSent,
                        rrtDeleted: action.data.uiData.ClaimTotalsModel.rrtDeleted,
                        totalClaims: action.data.uiData.ClaimTotalsModel.totalClaims,
                        rrtEnabled: action.data.uiData.ClaimTotalsModel.rrtEnabled,
                    }
                }
                else {
                    return {
                        ...state,
                        unreleased: defaultState.unreleased,
                        releasing: defaultState.releasing,
                        released: defaultState.released,
                        held: defaultState.held,
                        bridging: defaultState.bridging,
                        deleteCount: defaultState.deleteCount,
                        de: defaultState.de,
                        outSourceMarked: defaultState.outSourceMarked,
                        outSourceFilesCreated: defaultState.outSourceFilesCreated,
                        outSourceSending: defaultState.outSourceSending,
                        outSourced: defaultState.outSourced,
                        stateReportingOnly: defaultState.stateReportingOnly,
                        disableStateReportingOnly: false,
                        testUnreleased: defaultState.testUnreleased,
                        testReleased: defaultState.testReleased,
                        testReleasing: defaultState.testReleasing,
                        testDeleteCount: defaultState.testDeleteCount,
                        rrtUnsent: defaultState.rrtUnsent,
                        rrtSending: defaultState.rrtSending,
                        rrtSent: defaultState.rrtSent,
                        rrtDeleted: defaultState.rrtDeleted,
                        totalClaims: defaultState.totalClaims,
                        rrtEnabled: defaultState.rrtEnabled
                    }
                }
                break;
            case 'SELECTED_CLIENT':
                return {
                    ...state,
                    slectedClient: action.data,
                }
                break;
            default:
                return state;
        }
    }

    return state || defaultState;
}
