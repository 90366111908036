import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as HoldCodeProcessingUI from './ui/HoldCodeProcessingUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IHoldCodeProcessingState = ICrudComponentState<HoldCodeProcessing, HoldCodeProcessingUI.IHoldCodeProcessingUIState>;

export const actionCreators = createCrudComponentActions<HoldCodeProcessingUI.ActionCreators>(HoldCodeProcessingUI.actionCreators);
export type IHoldCodeProcessingActions = typeof actionCreators;
export type IHoldCodeProcessingActionProps = ICrudComponentActionProps<IHoldCodeProcessingActions>
export const reducers = createCrudComponentReducers(CrudTypes.mcti277ResponsesResults, HoldCodeProcessingUI.reducer);
export const  reducer = combineReducers<IHoldCodeProcessingState>(reducers);

