import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ReportListUI from './ui/ReportListUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IReportListState = ICrudComponentState<MCARMRLReport, ReportListUI.IReportListUIState>;

export const actionCreators = createCrudComponentActions<ReportListUI.ActionCreators>(ReportListUI.actionCreators);
export type IReportListActions = typeof actionCreators;
export type IReportListActionProps = ICrudComponentActionProps<IReportListActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimNotes, ReportListUI.reducer);
export const reducer = combineReducers<IReportListState>(reducers);
