import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'ui-core';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { Input } from '@common/UICLWrappers/ARMInput';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IDashboardContactsState, IDashboardContactsActionProps, actionCreators, validationCallback } from '@store/DashboardContacts';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
`;

const ContactInputDiv = styled.div`
    width: 350px;
    margin-top: 11px;
`;

export const SelectButtons = styled.div`
    display: flex; 
    justify-content: center;
    margin-top: 11px;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
};

interface IComponentState {
    cancelLeave: boolean
};

export const defaultState: IComponentState = {cancelLeave: false};

type OwnProps = IComponentProps & RouteComponentProps<{}>;

type DashboardContactsProps = IMergeCrudComponentProps<IDashboardContactsState, IDashboardContactsActionProps, OwnProps>;

enum EModifyButtonType {
    Add,
    Update
}

export class DashboardContacts extends React.Component<DashboardContactsProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
    };

    constructor(props: DashboardContactsProps) {
        super(props);
        this.state = defaultState;
    }

    insertDeltaIndex: number = 0;

    deltaCrud: MCARMDashboardMaintenanceDelta = 
    {
        DashboardMaintenanceInfo: 
        {
            DashboardChartList: {
                Chart: [],
            },
            Events: {
                Event: [],
            },
            ContactList: {
                ContactEmail: [],
            }
        }

    }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiDashboard }, validationCallback);
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onSelectContact(e: React.ChangeEvent<HTMLSelectElement>)
    {
        //var x = this.props.action.ui;// as UIKnownActions;
        this.props.action.ui.selectContact(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    value: e.target.value,
                    text: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public onOk(e: React.ChangeEvent<HTMLButtonElement>) {
        for (var item of this.deltaCrud.DashboardMaintenanceInfo.ContactList.ContactEmail) {
            if (('' + item['@ID']).indexOf('New[') > -1) {
                item['@ID'] = '#';
            }
        }

        this.props.action.crud.update({ crudId: CrudTypes.mctiDashboard, data: this.deltaCrud });

        this.props.history.push("/LandingPage");
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified");
        if (userModified === 'true') {
            this.setState({ cancelLeave: true });
        }
        else
            this.props.history.push('/LandingPage');
    }

    public getModifyButtonType(): EModifyButtonType {
        switch (this.props.dataStore.ui.selectedContact.index) {
            case 0:
                return EModifyButtonType.Add;
            default:
                return EModifyButtonType.Update;
        }
    }

    public getModifyButtonText() {
        switch (this.getModifyButtonType()) {
            case EModifyButtonType.Add:
                return 'Add';
            default:
                return 'Update';
        }
    }

    public isRemoveButtonDisabled() {
        return (this.props.dataStore.ui.selectedContact.index === 0);
    }

    public onClickModifyButton(e: React.MouseEvent<HTMLButtonElement>) {
        if (this.props.dataStore.crud.data) {
                switch (this.getModifyButtonType()) {
                    case EModifyButtonType.Add:
                        this.props.action.ui.addContact({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                '@ID': 'New[' + (this.insertDeltaIndex++) + ']',
                                '@Name': this.props.dataStore.ui.contactName,
                                '@Email': this.props.dataStore.ui.contactEmail
                            },
                            delta: this.deltaCrud
                        });
                        break;
                    default:
                        this.props.action.ui.updateContact({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                '@ID': this.props.dataStore.ui.selectedContact.value,
                                '@Name': this.props.dataStore.ui.contactName,
                                '@Email': this.props.dataStore.ui.contactEmail
                            },
                            delta: this.deltaCrud
                        });
                }
        }
    }

    public onClickRemoveButton(e: React.MouseEvent<HTMLButtonElement>)
    {
        this.props.action.confirm.openConfirm("Do you want to remove " + this.props.dataStore.ui.contactName + "? This action cannot be undone.", () => this.onClickConfirmRemoveContact());
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        handleChange();
        this.props.dataStore.confirm.confirmCallback();
    }

    public onClickConfirmRemoveContact()
    {
        this.props.action.ui.removeContact({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                '@ID': this.props.dataStore.ui.selectedContact.value,
                '@Name': this.props.dataStore.ui.contactName,
                '@Email': this.props.dataStore.ui.contactEmail
            },
            delta: this.deltaCrud
        });
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>)
    {
        this.props.action.confirm.closeConfirm();
    }

    isChangePending() {
        if (this.deltaCrud && 
            this.deltaCrud.DashboardMaintenanceInfo && 
            this.deltaCrud.DashboardMaintenanceInfo.ContactList && 
            this.deltaCrud.DashboardMaintenanceInfo.ContactList.ContactEmail &&
            this.deltaCrud.DashboardMaintenanceInfo.ContactList.ContactEmail.length > 0 ) {
            return true;
        }
        return false;
    }

    public render()
    {
        var instruction = <React.Fragment>Use Notification Contacts to add and maintain notification contacts for Dashboard.  Both the E-mail Address and Display Name are required.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.isChangePending() || !this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOk(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        var addItem: MCDashboardContact[] = [{
            '@ID': '',
            '@Name': '- ADD A NEW CONTACT -',
            '@Email': '- ADD A NEW CONTACT -',
        }];
        var data: any = this.props.dataStore.crud.data ? addItem.concat(this.props.dataStore.crud.data.DashboardMaintenanceInfo.ContactList.ContactEmail) : addItem;
        return (
            <DialogWrapper title="Notification Contacts" instruction={instruction} helpUrl='/Support/Help/HELP_Maint_DashboardNotification.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                <SelectList>
            	    <DialogFieldset height='100%'>
                        <DialogLegend>Contacts</DialogLegend>
                            <SelectComponent
                                title='DashboardContacts'
                                size={21}
                                width='240px'
                                height='100%'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectContact(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@Name"
                                }}
                                records={data}
                                selectedValue={this.props.dataStore.ui.selectedContact.value}
                            >
                        </SelectComponent>
                    </DialogFieldset>
                </SelectList>
                <SelectActions>
                    <ContactInputDiv>
                        <Input
                            domID="ContactEmail"
                            label="*E-Mail:"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateContactEmail(e.target.value)}
                            maxLength={100}
                            initialValue={this.props.dataStore.ui.contactEmail}
                            hasError={this.props.dataStore.ui.contactEmailError && this.props.dataStore.ui.contactEmailError !== ''}
                            errorMessage={this.props.dataStore.ui.contactEmailError}
                        />
                    </ContactInputDiv>
                    <ContactInputDiv>
                        <Input
                            domID="ContactName"
                            label="*Display as:"
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.props.action.ui.updateContactName(e.target.value)}
                            maxLength={80}
                            initialValue={this.props.dataStore.ui.contactName}
                            hasError={this.props.dataStore.ui.contactNameError && this.props.dataStore.ui.contactNameError !== ''}
                            errorMessage={this.props.dataStore.ui.contactNameError}
                        />
                    </ContactInputDiv>
                        <SelectButtons>
                            <Button
                                domID="addButton"
                                name={this.getModifyButtonText()}
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canEdit}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    handleChange();
                                    this.onClickModifyButton(e);
                                }}
                            />
                            <Button
                                domID="removeButton"
                                name="Remove"
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canEdit || this.isRemoveButtonDisabled()}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickRemoveButton(e)} />
                        </SelectButtons>
                </SelectActions>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IDashboardContactsState, IDashboardContactsActionProps, OwnProps, DashboardContactsProps, ApplicationState>(
    createCrudMapStateToProps('dashboardContacts'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(DashboardContacts);

export default withRouter(connectedHoc);
