import { get } from 'lodash';
import { Reducer, ActionCreatorsMapObject, Dispatch, AnyAction } from 'redux';
import { createDataAction, createApiAction, createAction, createApiBodyAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ComplexApiAction, IThunkAction, IThunkApiAction, IThunkResult } from "@scripts/util/ThunkHelpers";
import { URLs } from '@commonDevResources/constants';
import { dollarFormatter } from '@components/Dashboard/Default/utils/formatter';
import { claimsDetailColumn } from "@components/Dashboard/Default/utils/constants";
import { createSelector } from "reselect"
import { History } from 'history';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface Selection {
    label: string;
    value: string;
}
export interface UserGroupSelection {
    userId: string;
    groupId: string;
}
export interface Owner {
    action: any; // will need to define the Type properly later when we know what the data looks like
    id: string;
    name: string;
    optionType: object;
    properties: object;
    serializedOptionType: string;
};
export interface IDashboardAssignedClaimsDetailsRequestData {
    "id": string,
    "assignedUserId": number,
    "sortOrder": string,
    "patientFirstName": string,
    "patientLastName": string,
    "totalCharges": number,
    "typeOfBill": string,
    "controlNo": string,
    "formType": string,
    "payerName": string,
    "payerIndicator": string,
    "releaseDate": string,
    "statementFromDate": string,
    "statementThruDate": string
    "assignedDate": string,
    "daysAssigned": number,
    "systemCode": number,
    "assignmentType": string,
    "assignedUserCount": number,
    "claimId": string
}
export interface IDashboardAssignedClaimsDetails {
    patientName: string,
    controlNo: string,
    totalCharges: string,
    typeOfBill: string,
    formType: string,
    payerName: string,
    assigned: string,
    sortOrder: string,
    releaseDate: string
    systemCode: number
}
export interface WorkGroup {
    id: number;
    name: string;
    owner: Owner;
    users: any; // will need to define the Type properly later when we know what the data looks like
}
export interface IDashboardAssignedClaimsState {
    workGroupSummaryData?: any; // will need to define the Type properly later when we know what the data looks like
    workGroupData: WorkGroup[] | undefined;
    claimsAssignedToUser?: any; // will need to define the Type properly later when we know what the data looks like
    userAssignmentList?: any; // will need to define the Type properly later when we know what the data looks like
    selectedGroup: Selection;
    selectedView: Selection;
    exportToCsvPath: string;
    exportToCsvUserPath: string;
    pageIndex: Number;
    pagelength: Number;
    claimTypeSelection: ClaimTypes;
    sortOrder: string;
    userSelection: Number;
    filterInput: string;
    uiLock: string;
    selectedClaimUser: string;
    claimsDataStatus: string;
    claimsData: IDashboardAssignedClaimsDetails[] | [],
    // assignableUsers: any
}
export type ClaimTypes = "Total Claims" | "Released Claims" | "Unreleased Claims";
export const DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES = {
    GET_CLAIMS_DETAILS: "DASHBOARD_ASSIGNED_CLAIMS_GET_CLAIMS_DETAILS",
    FILTER_CLAIMS_DETAILS: "FILTER_CLAIMS_DETAILS",
    FILTER_CLAIMS_DETAILS_BY_TYPE: "FILTER_CLAIMS_DETAILS_BY_TYPE",
    GET_UTILITY_DATA: "GET_UTILITY_DATA",
    CLEAR_UI_LOCK_ERROR: "CLEAR_UI_LOCK_ERROR",
    SORT_CLAIMS_DETAILS: "DASHBOARD_ASSIGNED_CLAIMS_SET_SORT_CLAIMS_DETAILS",
    SET_ASSIGNED_USER: "DASHBOARD_SET_ASSIGNED_USER"
}
export const forceToArray = (input: any) => {
    if (Array.isArray(input)) {
        return input
    } else if (typeof input === 'object') {
        return [input]
    } else {
        return []
    }
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    selectGroup: (group: Selection) => createApiAction('DASHBOARD_ASSIGNED_CLAIMS_SELECT_GROUP', `${URLs.api}/api/data/AssignedClaimsListByGroup/${group.label === "My Claims" ? "-1" : group.value}`, group),
    selectView: (selectedView: Selection) => createDataAction('DASHBOARD_ASSIGNED_CLAIMS_SELECT_VIEW', selectedView),
    getAllWorkgroups: () => createApiAction('DASHBOARD_ASSIGNED_CLAIMS_GET_ALL_WORKGROUPS', `${URLs.api}/api/data/allworkgroups/`),
    getClaimsDetails: (userId: string) => createApiAction(DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.GET_CLAIMS_DETAILS, `${URLs.api}/api/data/claims/${userId}`, userId),
    filterClaimsDetails: (filterString: string) => createDataAction(DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.FILTER_CLAIMS_DETAILS, filterString),
    filterClaimsByType: (claimType: string) => createDataAction(DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.FILTER_CLAIMS_DETAILS_BY_TYPE, claimType),
    resetState: () => createAction('DASHBOARD_ASSIGNED_CLAIMS_RESET_STATE'),
    getWorkgroupSummaryInit: () => createApiAction('DASHBOARD_ASSIGNED_CLAIMS_GET_WORK_GROUP_SUMMARY_INIT', `${URLs.api}/api/data/workgroupsummary/`),
    getWorkgroupSummary: (groupID: string = "0") => createApiAction('DASHBOARD_ASSIGNED_CLAIMS_GET_WORK_GROUP_SUMMARY', `${URLs.api}/api/data/workgroupsummary/${groupID}`, groupID),
    getUtilityData: () => createApiAction(DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.GET_UTILITY_DATA, `${URLs.api}/api/data/utility/?options=AssignUsers`),
    clearUILockError: () => createAction(DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.CLEAR_UI_LOCK_ERROR),
    sortClaimsData: (sortIndicator: any) => createDataAction(DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.SORT_CLAIMS_DETAILS, sortIndicator),
    setAssignedUser: (userObj) => createDataAction(DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.SET_ASSIGNED_USER, userObj),
    selectUser: (userGroup: UserGroupSelection) => createApiAction('DASHBOARD_ASSIGNED_CLAIMS_SELECT_USER', `${URLs.api}/api/data/AssignedClaimsListByUser/${userGroup.userId}/${userGroup.groupId}`, userGroup),
    setClaimListForCFI: (selection: any, history: History<any>) => {
        let cfiData = { cfiReturnInfo: "arm.ui/operations/dashboard" };
        return ComplexApiAction
            .fromAction(createApiBodyAction('SET_DASHBOARD_ASSIGNED_VIEW_CLAIM_LIST', `${URLs.api}/api/data/claims/${selection.id}?claimId=${selection.claimId}&claimType=${selection.claimType}&sortOrder=${selection.sortOrder}`, undefined, 'PUT', JSON.stringify(selection)))
            .addThunk((
                dispatch: Dispatch<AnyAction>,
                apiResult: IThunkApiAction<string, any, any>): IThunkResult<void> => {
                if (apiResult.status.status === 'SUCCESS') {
                    return { success: true }
                }
                return { success: false }
            }
            )
            .thenRedirect("/CFI/DEFAULT", history, cfiData)
            .finish();
    },
    reassingClaimsList: (selectedClaimsSet: any) => createDataAction('DASHBOARD_ASSIGNED_CLAIM_REASSIGN_CLAIMS_LIST', selectedClaimsSet),
    reassignClaims: (response: any) => createDataAction('DASHBOARD_REASSING_CLAIMS_CALL', response),

};


// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];
export const defaultState: IDashboardAssignedClaimsState = {
    workGroupSummaryData: undefined, // will need to define the Type properly later when we know what the data looks like
    workGroupData: undefined,
    claimsAssignedToUser: undefined, // will need to define the Type properly later when we know what the data looks like
    userAssignmentList: undefined, // will need to define the Type properly later when we know what the data looks like
    selectedGroup: { label: "My Claims", value: "-1" },
    selectedView: { label: 'Charts', value: 'charts' },
    exportToCsvPath: "",
    exportToCsvUserPath: "",
    pageIndex: 0,
    pagelength: 0,
    claimTypeSelection: "Total Claims",
    sortOrder: "patientName|SORT_DESCENDING",
    userSelection: 0,
    filterInput: "",
    uiLock: "Unlocked",
    selectedClaimUser: '',
    claimsDataStatus: '',
    claimsData: [],
    assignableUsers: [],
    selectedAssignedUser: null,
};
// ----------------
// REDUCER - For a given state and actio returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IDashboardAssignedClaimsState, KnownActions> = (state: IDashboardAssignedClaimsState | undefined, action: any) => {
    if (state != undefined) {
        switch (action.type) {
            case 'DASHBOARD_ASSIGNED_CLAIMS_SELECT_GROUP':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                            selectedGroup: action.data,
                            uiLock: "",
                            selectedClaimUser: '',
                            claimsData: [],
                            claimsDataStatus: ''
                        }
                    case "SUCCESS":
                        return {
                            ...state,
                            selectedGroup: action.data,
                            exportToCsvPath: action.responseData,
                            uiLock: "Unlocked",
                        }
                    case "FAIL":
                        return {
                            ...state,
                            selectedGroup: action.data,
                            uiLock: "Error: something went wrong"
                        }
                }
                break;
            case 'DASHBOARD_ASSIGNED_CLAIMS_SELECT_USER':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                            exportToCsvUserPath: action.responseData
                        }
                    case "SUCCESS":
                        return {
                            ...state,
                            exportToCsvUserPath: action.responseData
                        }
                    case "FAIL":
                        return {
                            ...state,
                            uiLock: "Error: something went wrong"
                        }
                }
                break;               
            case 'DASHBOARD_ASSIGNED_CLAIMS_GET_ALL_WORKGROUPS':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                            uiLock: ""
                        }
                    case "SUCCESS":
                        return {
                            ...state,
                            uiLock: "Unlocked",
                            workGroupData: forceToArray(action.responseData)
                        };
                    case "FAIL":
                        return {
                            ...state,
                            uiLock: "Error: something went wrong"
                        }
                }
                break;
            case 'DASHBOARD_ASSIGNED_CLAIMS_SELECT_VIEW':
                return {
                    ...state,
                    selectedView: action.data,
                }
                break;
            case 'SET_DASHBOARD_ASSIGNED_VIEW_CLAIM_LIST': {
                switch (action.status.status) {
                    case "SUCCESS":
                        {
                            if (Number(action.responseData) == 0) {
                                return {
                                    ...state,
                                   // modalText: "There are no claims that match the error criteria."---Match with IE
                                }
                            }
                        }
                    case "FAIL":
                        return {
                            ...state,
                           // modalText: action.responseData.length > 0 ? action.responseData : "An error occurred while setting the claim list. Please try again later."
                        }
                };
                break;
            }
            case DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.GET_CLAIMS_DETAILS:
                switch (action.status.status) {
                    case "REQUEST":
                        // Insert any pre-response code here.
                        return {
                            ...state,
                            selectedClaimUser: action.data,
                            claimsDataStatus: 'PENDING',
                            // the space is a hack that is needed to force the AssignUser select component to reset
                            selectedAssignedUser: ' ',
                            filterInput: ''
                        };
                    case "SUCCESS":
                        const responseData = get(action, ['responseData'], []);
                        const flattenArr = responseData.map((dataObj: any) => {
                            const { assignedClaim, ...rest } = dataObj;
                            return {
                                ...assignedClaim,
                                ...rest
                            }
                        })
                        return {
                            ...state,
                            claimsData: flattenArr,
                            claimTypeSelection: "Total Claims",
                            claimsDataStatus: 'SUCCESS'
                        }
                    default:
                        console.log("Unhandled request claimsDetails")
                }
                return state
            case DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.FILTER_CLAIMS_DETAILS:
                return {
                    ...state,
                    filterInput: action.data
                }
            case DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.SORT_CLAIMS_DETAILS:
                return {
                    ...state,
                    sortOrder: action.data
                }
            case DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.FILTER_CLAIMS_DETAILS_BY_TYPE:
                return {
                    ...state,
                    claimTypeSelection: action.data
            }

            case DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.GET_UTILITY_DATA:
                switch (action.status.status) {
                    // case "REQUEST":
                    //     // Insert any pre-response code here.
                    //     return {
                    //         ...state,
                    //         selectedClaimUser: action.data,
                    //         claimsDataStatus: 'PENDING'
                    //     };
                    case "SUCCESS":
                        return {
                            ...state,
                            assignableUsers: action.responseData.assignUsers
                        }
                    default:
                        return state
                    
                }
            case DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.SET_ASSIGNED_USER:
                return {
                    ...state,
                    selectedAssignedUser: action.data
                }
            case 'DASHBOARD_ASSIGNED_CLAIMS_GET_WORK_GROUP_SUMMARY_INIT':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        return {
                            ...state,
                            workGroupSummaryData: action.responseData
                        };
                    case "FAIL":
                        return {
                            ...state,
                            uiGraphData: "Error: something went wrong"
                        }
                }
                break;
            case DASHBOARD_ASSIGNED_CLAIM_ACTION_TYPES.CLEAR_UI_LOCK_ERROR:
                return {
                    ...state,
                    uiLock: ''
            }
            case 'DASHBOARD_ASSIGNED_CLAIMS_GET_WORK_GROUP_SUMMARY':
                switch (action.status.status) {
                    case "VALIDATE":
                    case "REQUEST":
                        return {
                            ...state,
                        }
                    case "SUCCESS":
                        return {
                            ...state,
                            workGroupSummaryData: action.responseData
                        };
                    case "FAIL":
                        return {
                            ...state,
                            uiGraphData: "Error: something went wrong"
                        }
                }
                break;
 
            case 'OPERATIONAL_DASHBOARD_SELECT_TAB':
                return {
                    ...state,
                    selectedAssignedUser: defaultState.selectedAssignedUser,
                    filterInput: defaultState.filterInput,
                    claimTypeSelection: defaultState.claimTypeSelection,
                    claimsData: defaultState.claimsData,
                    // claimTypeSelection: "Total Claims",
                    claimsDataStatus: defaultState.claimsDataStatus,
                    selectedClaimUser: defaultState.selectedClaimUser

                }
            case 'DASHBOARD_ASSIGNED_CLAIM_REASSIGN_CLAIMS_LIST':
                let claimsList = [];
               claimsList = [...action.data]
                return {
                    ...state,
                    reassignedClaimsList: claimsList,
                }
            case 'DASHBOARD_REASSING_CLAIMS_CALL':
                return {
                    ...state,
                    reassingConfirmation: action.data
                };
            case 'DASHBOARD_ASSIGNED_CLAIMS_RESET_STATE':
                return defaultState;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // const exhaustiveCheck: never = action;
                return state;
        }
    }
    return state || defaultState;
}
export const dashboardAssignedClaimsSelector = (state: any) => state.dashboardAssignedClaims
export const claimsDataSelector = (state: any) => state.dashboardAssignedClaims.claimsData;
export const claimsColumnSortSelector = (state: any) => state.dashboardAssignedClaims.claimsSort;
export const claimTypeFilterSelector = (state: any) => state.dashboardAssignedClaims.claimTypeSelection;
export const claimUserFilterSelector = (state: any) => state.dashboardAssignedClaims.claimTypeSelection;
export const claimDataStatusSelector = (state: any) => state.dashboardAssignedClaims.claimsDataStatus;
export const selectedClaimUserSelector = (state: any) => state.dashboardAssignedClaims.selectedClaimUser;
export const workGroupDataSelector = (state: any) => get(state, ['dashboardAssignedClaims', 'workGroupSummaryData'], []);
export const assignableUserSelector = (state: any) => state.dashboardAssignedClaims.assignableUsers;
export const selectGroupSelector = (state: any) => state.dashboardAssignedClaims.selectedGroup;
export const assignUsersOptionSelector = createSelector(assignableUserSelector, (userArr) => {
    return userArr.map((obj) => {
        return {
            label: obj.name,
            value: obj.id
        }
    })
})

export const assignedUserSelector = (state: any) => state.dashboardAssignedClaims.selectedAssignedUser;
export const isAssignedUserSelectedSelector = createSelector(assignedUserSelector, (user) => {
    let selectedUser = user;
    if (typeof user === 'string') {
        selectedUser = user.trim();
    }
    if (selectedUser) {
        return true
    }
    return false
});

export const selectedSortOrderSelector = createSelector(dashboardAssignedClaimsSelector, (obj) => {

    const sortedObj = obj.sortOrder

    return sortedObj;

})

export const selectedClaimUserNameSelector = createSelector(
    workGroupDataSelector,
    selectedClaimUserSelector,
    (workGroupSummary, userClaimId) => {
        const arr = workGroupSummary.filter((obj: any) => {
            return obj.userId === userClaimId;
        })
        const userObj = arr[0];
        return get(userObj, ['userName'], '')
    }
)
const userWorkGroupSummarySelector = createSelector(
    workGroupDataSelector,
    selectedClaimUserSelector,
    (workGroupSummary, userId) => {
        let userClaimData = workGroupSummary.filter((summaryData: any) => summaryData.userId === userId);
        const data = userClaimData[0];
        const totalClaims = get(data, ['totalClaims'], null);
        const totalClaimAmount = get(data, ['totalClaimAmount'], null);
        const releasedClaims = get(data, ['releasedClaims'], null);
        const releasedClaimAmount = get(data, ['releasedClaimAmount'], null);
        const unreleasedClaims = get(data, ['unreleasedClaims'], null);
        const unreleasedClaimsAmount = get(data, ['unreleasedClaimsAmount'], null);
        let selectedClaimsData: any[] = [
            { claims: 'Total Claims', count: totalClaims, amount: totalClaimAmount, selected: '5' },
            { claims: 'Released Claims', count: releasedClaims, amount: releasedClaimAmount, selected: '7' },
            { claims: 'Unreleased Claims', count: unreleasedClaims, amount: unreleasedClaimsAmount, selected: '8' },
        ]
      return selectedClaimsData
    }
)
export const claimInputFilterSelector = (state: any) => {
    let filterInput = state.dashboardAssignedClaims.filterInput;
    return filterInput.toLowerCase().trim()
}
const formattedClaimsDataSelector = createSelector(
    claimsDataSelector,
    (claimsData) => {
        const formattedData = claimsData.map((obj: IDashboardAssignedClaimsDetailsRequestData) => {
            const { patientFirstName, patientLastName, payerName, controlNo, formType, typeOfBill, daysAssigned, claimId, totalCharges, releaseDate, assignedUserCount, assignmentType, systemCode, assignedUserId, id } = obj;
            return {
                patientName: `${titleCase(patientLastName)}, ${titleCase(patientFirstName)}` + "\n\r" + (systemCode === 1 ? "(Unreleased)" : "") + (systemCode === 3 ? "(Released)" : ""),
                controlNo,
                // totalCharges,
                totalCharges: dollarFormatter(totalCharges),
                typeOfBill,
                formType,
                id,
                payerName,
                assigned: `${daysAssigned} days, ${assignedUserCount} user` + (assignedUserCount > 1 ? "s" : "")  + "\n\r" +
                    (assignmentType === "UAC" ? "Manual" : "") +
                    (assignmentType === "PUAC" ? "Manual" : "") +
                    (assignmentType === "SUAC" ? "Manual" : "") +
                    (assignmentType === "ERA" ? "Error" : "") +
                    (assignmentType === "PAC" ? "Payer" : "") +
                    (assignmentType === "SRA" ? "Payer" : "") ,
                releaseDate,
                selected: `${systemCode}_${id}`, //Math.random(),
                ViewCFI: {
                    buttonType: "deEmphasized",
                    name: "View Claim",
                    className: 'claimButton',
                },
                claimId,
                systemCode,
                assignedUserId
            }
        })
        return formattedData;
    }
)
export const columnsWithSortingSelector = (state: any) => claimsDetailColumn;
// @todo move this function
// function isEmpty(str: string) {
//     return (!str || str.length === 0);
// }



//@todo move this function
export function filterIt(arr: IDashboardAssignedClaimsDetails[], searchKey: string) {
   return arr.filter(obj => Object.values(obj).map((val) => val.toString().toLowerCase()).some(objVal => objVal.includes(searchKey)))
}

export function titleCase(str: string) {
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}

export const selectFilteredClaims = createSelector(
    formattedClaimsDataSelector,
    claimTypeFilterSelector,
    claimInputFilterSelector,
    (claimsArr, claimType, claimInput) => {
        let filterArr: IDashboardAssignedClaimsDetails[] = claimsArr.slice(0);

        filterArr.forEach((obj) => {

            //console.log("The object is ", obj);
            obj.selected = `${obj.systemCode}_${obj.id}` // Math.random()
        })

        if(claimType === "Released Claims"){
                filterArr = filterArr.filter((obj) => {
                    return obj.systemCode === 3
                })
        }else if(claimType === "Unreleased Claims"){
                filterArr = filterArr.filter((obj) => { 
                    return obj.systemCode === 1
                })
        }
 
        if (claimInput) {
            filterArr = filterIt(filterArr, claimInput)
        }

        //console.log("The filtered data is: ", filterArr);

        return filterArr
    }
)

export const reassingClaimsSelector = (state: any) => state.dashboardAssignedClaims.reassignedClaimsList; 
export const reassingClaimsSetSelector = (state: any) => {
    return new Set(state.dashboardAssignedClaims.reassignedClaimsList)
}; 



export const selectors = {
    //hack for now rename to correct name
    claimsDataSelector: claimsDataSelector,
    selectClaimDetailData: formattedClaimsDataSelector,
    selectFilteredClaims: selectFilteredClaims,
    columnsWithSortingSelector: columnsWithSortingSelector,
    claimDataStatusSelector: claimDataStatusSelector,
    selectedClaimUserSelector: selectedClaimUserSelector,
    userWorkGroupSummarySelector: userWorkGroupSummarySelector,
    claimTypeFilterSelector: claimTypeFilterSelector,
    selectedClaimUserNameSelector: selectedClaimUserNameSelector,
    assignableUserSelector: assignableUserSelector,
    selectGroupSelector: selectGroupSelector,
    assignUsersOptionSelector: assignUsersOptionSelector,
    assignedUserSelector: assignedUserSelector,
    selectedSortOrderSelector: selectedSortOrderSelector,
    isAssignedUserSelectedSelector: isAssignedUserSelectedSelector,
    reassingClaimsSelector: reassingClaimsSelector,
    reassingClaimsSetSelector: reassingClaimsSetSelector,
    formattedClaimsDataSelector: formattedClaimsDataSelector,
    claimInputFilterSelector: claimInputFilterSelector,
    workGroupDataSelector: workGroupDataSelector,
    dashboardAssignedClaimsSelector: dashboardAssignedClaimsSelector,
}