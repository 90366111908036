import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button } from 'ui-core';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { Input } from '@common/UICLWrappers/ARMInput';
import { TextArea } from '@common/UICLWrappers/ARMTextArea';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IClaimNoteTypeState, IClaimNoteTypeActionProps, actionCreators, validationCallback } from '@store/ClaimNoteType';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    #ClaimNoteDescription {
        width: 350px;
        height: 100px;
    }

`;

const ClaimNoteInputDiv = styled.div`
    margin-top: 11px;
`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    margin-top: 11px;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
};

interface IComponentState {
    cancelLeave: boolean
};

export const defaultState: IComponentState = {cancelLeave: false};

type OwnProps = IComponentProps & RouteComponentProps<{}>;

type ClaimNoteTypeProps = IMergeCrudComponentProps<IClaimNoteTypeState, IClaimNoteTypeActionProps, OwnProps>;

enum EModifyButtonType {
    Add,
    Update
}

export class ClaimNoteType extends React.Component<ClaimNoteTypeProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
    };

    constructor(props: ClaimNoteTypeProps) {
        super(props);
        this.state = defaultState;
    }

    public componentDidMount() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiClaimNotes }, validationCallback);
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onSelectNoteType(e: React.ChangeEvent<HTMLSelectElement>)
    {
        //var x = this.props.action.ui;// as UIKnownActions;
        this.props.action.ui.selectNoteType(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    value: e.target.value,
                    text: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public onOk(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);
        this.props.history.push("/LandingPage");
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified");
        if (userModified === 'true') {
            this.setState({ cancelLeave: true });
        }
        else
            this.props.history.push('/LandingPage');
    }

    public getModifyButtonType(): EModifyButtonType {
        switch (this.props.dataStore.ui.selectedNoteType.index) {
            case 0:
                return EModifyButtonType.Add;
            default:
                return EModifyButtonType.Update;
        }
    }

    public getModifyButtonText() {
        switch (this.getModifyButtonType()) {
            case EModifyButtonType.Add:
                return 'Add';
            default:
                return 'Update';
        }
    }

    public isRemoveButtonDisabled() {
        return (this.props.dataStore.ui.selectedNoteType.index === 0);
    }

    public onClickModifyButton(e: React.MouseEvent<HTMLButtonElement>) {
        if (this.props.dataStore.crud.data) {
            let duplicate = this.props.dataStore.crud.data.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.find(existingNoteType => existingNoteType["@Name"] === this.props.dataStore.ui.noteTypeName);
            if (duplicate && duplicate["@Deleted"] === '1') {
                let noteType: MCNoteType = duplicate;
                this.props.action.confirm.openConfirm("A deleted note type by that name already exists. Click OK to undelete it.", () => this.onClickConfirmUndeleteNote(noteType));
            }
            else {
                switch (this.getModifyButtonType()) {
                    case EModifyButtonType.Add:
                        this.props.action.ui.addNoteType({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                '@ID': '#' + (this.props.dataStore.crud.data.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType.length + 1),
                                '@Name': this.props.dataStore.ui.noteTypeName,
                                '@Description': this.props.dataStore.ui.noteTypeDescription,
                                '@Deleted': '0'
                            }
                        });
                        break;
                    default:
                        this.props.action.ui.updateNoteType({
                            masterCrud: this.props.dataStore.crud.data,
                            uiData: {
                                '@ID': this.props.dataStore.ui.selectedNoteType.value,
                                '@Name': this.props.dataStore.ui.noteTypeName,
                                '@Description': this.props.dataStore.ui.noteTypeDescription,
                                '@Deleted': '0'
                            }
                        });
                }
            }
        }
    }

    public onClickRemoveButton(e: React.MouseEvent<HTMLButtonElement>)
    {
        this.props.action.confirm.openConfirm("Do you want to remove " + this.props.dataStore.ui.noteTypeName + "? This action cannot be undone.", () => this.onClickConfirmRemoveNote());
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        handleChange();
        this.props.dataStore.confirm.confirmCallback();
    }

    public onClickConfirmUndeleteNote(noteType: MCNoteType)
    {
        this.props.action.ui.restoreNoteType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: noteType
        });
    }

    public onClickConfirmRemoveNote()
    {
        this.props.action.ui.removeNoteType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                updateInfo: {
                    '@ID': this.props.dataStore.ui.selectedNoteType.value,
                    '@Name': this.props.dataStore.ui.noteTypeName,
                    '@Description': this.props.dataStore.ui.noteTypeDescription,
                    '@Deleted': '0',
                }
            }
        });
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>)
    {
        this.props.action.confirm.closeConfirm();
    }

    public render()
    {
        var instruction = <React.Fragment>The Name and Description created here display in the claim history after the note is added to the claim.<br /> <i>(For more information, click the help button.)</i></React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOk(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        var addItem: MCNoteType[] = [{
            '@ID': '',
            '@Name': '- ADD A CLAIM NEW NOTE TYPE -',
            '@Description': '- ADD A CLAIM NEW NOTE TYPE -',
        }];
        var data: any = this.props.dataStore.crud.data ? addItem.concat(this.props.dataStore.crud.data.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType) : addItem;
        return (
            <DialogWrapper title="Claim Note Type Maintenance" instruction={instruction} helpUrl='/Support/Help/HELP_Maint_NoteTypes.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                <SelectList>
            	    <DialogFieldset height='100%'>
                        <DialogLegend>Claim Note Types</DialogLegend>
                            <SelectComponent
                                title='ClaimNoteTypes'
                                size={10}
                                width='300px'
                                height='100%'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectNoteType(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@Name",
                                    deleted: "@Deleted"
                                }}
                                records={data}
                                selectedValue={this.props.dataStore.ui.selectedNoteType.value}
                            >
                        </SelectComponent>
                    </DialogFieldset>
                </SelectList>
                <SelectActions>
                    <ClaimNoteInputDiv>
                        <Input
                            domID="note-type-name"
                            label="*Note Type Name:"
                            forceUpperCase={true}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateNoteTypeName(e.target.value)}
                            maxLength={35}
                            initialValue={this.props.dataStore.ui.noteTypeName}
                            hasError={this.props.dataStore.ui.noteTypeNameError && this.props.dataStore.ui.noteTypeNameError !== ''}
                            errorMessage={this.props.dataStore.ui.noteTypeNameError}
                    />
                    </ClaimNoteInputDiv>
                    <ClaimNoteInputDiv>
                        <TextArea
                            domID="ClaimNoteDescription"
                            label="*Description:"
                            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.props.action.ui.updateNoteTypeDescription(e.target.value)}
                            maxLength={256}
                            removeCrLf={true}
                            disabled={false}
                            readOnly={false}
                            initialValue={this.props.dataStore.ui.noteTypeDescription}
                            hasError={this.props.dataStore.ui.noteTypeDescriptionError && this.props.dataStore.ui.noteTypeDescriptionError !== ''}
                            errorMessage={this.props.dataStore.ui.noteTypeDescriptionError}
                        />
                    </ClaimNoteInputDiv>
                        <SelectButtons>
                            <Button
                                domID="addButton"
                                name={this.getModifyButtonText()}
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canEdit}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    handleChange();
                                    this.onClickModifyButton(e);
                                }}
                            />
                            <Button
                                domID="removeButton"
                                name="Remove"
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canEdit || this.isRemoveButtonDisabled()}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickRemoveButton(e)} />
                        </SelectButtons>
                </SelectActions>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IClaimNoteTypeState, IClaimNoteTypeActionProps, OwnProps, ClaimNoteTypeProps, ApplicationState>(
    createCrudMapStateToProps('claimNoteType'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ClaimNoteType);

export default withRouter(connectedHoc);
