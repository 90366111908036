import { ISampleTreeviewData, ISampleTreeviewParentData, ISampleTreeviewChildData } from './ISampleTreeviewData';

export class TreeViewDataHelper {

    getSampleTreeViewData() {
        const sampleTreeviewParentData: ISampleTreeviewParentData[] = [
            {
                id: 1,
                fileName: 'test-file-1.txt'
            },
            {
                id: 2,
                fileName: 'test-file-2.txt'
            }
        ]

        const sampleTreeviewChildData: ISampleTreeviewChildData[] = [
            {
                id: 1,
                parentId: 1,
                paymentDate: '05/01/2021'
            },
            {
                id: 2,
                parentId: 1,
                paymentDate: '05/02/2021'
            },
            {
                id: 3,
                parentId: 2,
                paymentDate: '05/03/2021'
            }
        ]

        const treeViewData: ISampleTreeviewData = {
            parentData: sampleTreeviewParentData,
            childData: sampleTreeviewChildData
        }

        return treeViewData;
    }
}
