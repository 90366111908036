import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getRawToken } from "@scripts/session/SecurityToken";
import styled from "styled-components";
import { DialogWrapper, OKCancelButtons } from "@common/DialogWrapper";
import { URLs } from "@commonDevResources/constants";
import _ from "lodash";
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState, } from "@scripts/util/ApiDataHelpers";
import { connect } from "react-redux";
import { ApplicationState } from "@store/index";
import { IReapplySiteTemplateState, IReapplySiteTemplateActions, IReapplySiteTemplateActionProps, actionCreators //, reapplySiteTremplateValidationCallback,
} from "@store/ReapplySiteTemplate";
import { defaultState as defaultUIState, ReapplySiteTemplateRow } from "@store/ui/ReapplySiteTemplateUI";
import { CheckBoxComponent } from "@common/CheckBox";
import { ModalConfirmation } from "@common/ModalConfirmation";
import refreshIcon from '@content/images/LandingPage/refresharrow.png';
import { ARMGridPager } from '@common/UICLWrappers/ARMGridPager';



const TableContainer = styled.div`
  border: 1px solid #000;
  width: 100%;
  height: 396px;
  overflow: auto;
`;

const TableHeader = styled.div`
  background-color: #9ba1a9;
  border-left: solid 1px #ffffff;
  color: #ffffff;
  font-weight: bold;
  height: 25px;
  padding-left: 5px;
  display: flex;
  align-items: center;
`;

const TableContent = styled.div`
  overflow: auto;
  background-color: white;
  tbody {
    tr:nth-child(odd) {
      background-color: white;
    }
    tr:nth-child(even) {
      background-color: #f2f3f4;
    }
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
`;

export const ReapplySiteTemplateContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 5px;
  min-height: 180px;
  width: 1050px;
  padding: 10px;
`;
export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

// ------------------------------------

interface IComponentProps {
    canEdit: boolean;
    canView: boolean;
    title: string;
}

interface IComponentState {
    cancelLeave: boolean;
    selectedTableRows: ReapplySiteTemplateRow[];
    allTableRows: ReapplySiteTemplateRow[]
    gridPaging: {
        currentPageNumber: number,
        numberOfPages: number,
        recordsPerPage: number,
        totalRecordCount: number
    },
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    selectedTableRows: [],
    allTableRows: [],
    gridPaging: {
        currentPageNumber: 1,
        numberOfPages: 1,
        recordsPerPage: 100,
        totalRecordCount: 0
    },
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IReapplySiteTemplateProps = IMergeApiComponentProps< IReapplySiteTemplateState,IReapplySiteTemplateActionProps,IOwnProps>;

export class ReapplySiteTemplate extends React.Component<IReapplySiteTemplateProps, IComponentState> {
    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Re-Apply Site Template",
    };

    constructor(props: IReapplySiteTemplateProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.handlePaging = this.handlePaging.bind(this);
    }

    public componentWillMount() {
        this.props.action.ui.resetUiState();
        this.setState({ ...DEFAULT_STATE });
    }

    public componentDidMount() {

        if (!this.props.canView) {
            console.log("user did not have rights to " + this.props.title);
            this.props.history.push("/LandingPage");
        }

        this.props.action.api.loadData(URLs.api + '/api/data/GetReApplySiteTemplateFormData', this.validationCallback.bind(this), this.errorCallback.bind(this));
    }

    public componentWillUnmount() {
       
        this.setState({ ...DEFAULT_STATE });
    }

    public componentDidUpdate() {

        if (this.props.dataStore.ui.isIntialDataLoaded && !this.props.dataStore.ui.initionalPageSet ) {
            
            let allRows = this.props.dataStore.ui.TableRows;
            var testme = Array.isArray(allRows);
            let totalRecords = this.props.dataStore.ui.TableRows.length;
            let numberOfPages = 1;
            numberOfPages = Math.ceil(totalRecords / this.state.gridPaging.recordsPerPage);
            if (numberOfPages < 1) numberOfPages = 1

            const indexStart = 0;
            const indexEnd = this.state.gridPaging.recordsPerPage;
            const page = allRows.slice(indexStart, indexEnd);
            //this.handlePaging(1);//initional load

            this.setState({
                allTableRows: this.props.dataStore.ui.TableRows,
                selectedTableRows: page,
                gridPaging: {
                    currentPageNumber: 1,
                    numberOfPages: numberOfPages,
                    recordsPerPage: this.state.gridPaging.recordsPerPage,
                    totalRecordCount: this.props.dataStore.ui.TableRows.length
                },
            });
            this.props.action.ui.updateInitialPage(true);
        }

    }

    public errorCallback(dataStatus: string): boolean {
        var str =
            "An error occurred processing call for " +
            this.props.title +
            ".  Error: " +
            dataStatus;
        console.error(str);
        // this.onDisplayClaimFilterError(str);

        return true;
    }

    public validationCallback(data: any): boolean {

        var test: MCRST_ReapplySiteTemplateType = JSON.parse(data);

        if (test && test.SiteTemplatesInfo && test.SiteTemplatesInfo.LastSiteTemplateInfo &&
            test.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate &&
            (test.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate !== undefined &&
                test.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate !== null)) {
            if (!Array.isArray(test.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate)) {

                //When a single item is passed it's not an array. We need to create
                let notAry: MCRST_LastSiteTemplate = test.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate;
                test.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate = [];
                test.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate.push(notAry);
            }
            if (Array.isArray(test.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate)) {
                this.props.action.ui.loadInitialData(({ apiData: data, uiData: test }));
            } else {
                //problem
                console.log("This is the data" + data);
                console.log("This is parsed data" + test);
            }

        }
        return true;
    }

    public validateForm(): boolean {

        return true;
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        if (!this.validateForm()) return;

        var counter = 0;

        //recount to address defect RASS-36324
        this.state.allTableRows.forEach((ob) => { if (ob.selected)counter++})

        var errorMsg = "Are you sure you want to reapply these " + counter + " clients?"

        this.onAlert(errorMsg);
    }

    public updateSelectAll(selectAll : boolean) {

        this.props.action.ui.updateAllSelected(selectAll);
        let tempRows = ([...this.state.allTableRows])
        tempRows = this.state.allTableRows.map(row => {
            return { ...row, selected: true }     
        })

        let selected = this.state.selectedTableRows.map(row => {
            return { ...row, selected: true }
        })

        this.setState({
            selectedTableRows: selected,
            allTableRows: tempRows,
        });
    }

    public updateRowSelection(row : any) {

        this.props.action.ui.updateSelectedRows(({ uiData: row }));
    }

    public processReapplySiteTemplates() {

        this.onClearAlert("");

        const reqData: any = [];

        _.map(
            this.props.dataStore.ui.TableRows,
            (row: ReapplySiteTemplateRow, index: number) => {

                if (row.selected) {
                    if (row.hasFlags) {
                        reqData.push({Flags: row.Flags, Process: row.Process, DestinationClientId: row.DestinationClientID, ClientAlias: row.LogonAlias, ClientID: row.ClientID, JobTypeId: 108});
                    }
                    if (row.hasPas) {
                        reqData.push({Flags: row.Flags, Process: row.Process, DestinationClientId: row.DestinationClientID, ClientAlias: row.LogonAlias, ClientID: row.ClientID, JobTypeId: 201});
                    }
                }
            });
        this.XMLCallBack(reqData);

        this.props.history.push("/LandingPage");
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }
    public onClearAlert(e: any) {
        this.onAlert("");
    }
    public onAlert(msg: string) {

        this.props.action.ui.sendReapplySiteTemplateAlert({
            uiData: {
                value: msg,
            },
        });
    }

    public async XMLCallBack(reqData: []) {

        await fetch(URLs.api + "/api/data/CreateReapplySiteTemplateJobs", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `${getRawToken()}`,
            },
            body: JSON.stringify(reqData),
        })
            .then((response) => {
                if (response.status === 200) {
                    return response;
                    //console.log(response);
                } else {
                    throw new Error(response.statusText);
                }
            })
            .catch((error) => {
                const str =
                    "An error occurred processing call for " +
                    this.props.title +
                    ".  Error: " +
                    "Unable to fetch API.";
                console.error(str);
            })
            .finally(() => {
                //this.props.history.push("/LandingPage");
            });
    }

    handlePaging(pageNumber: number) {
        const {
            allTableRows,
            gridPaging
        } = this.state

        // get the requested page of records
        const indexStart = (pageNumber - 1) * gridPaging.recordsPerPage;
        const indexEnd = indexStart + gridPaging.recordsPerPage;
        const page = allTableRows.slice(indexStart, indexEnd);

        this.setState({
            selectedTableRows: page,
            gridPaging: {
                currentPageNumber: pageNumber,
                numberOfPages: gridPaging.numberOfPages,
                recordsPerPage: gridPaging.recordsPerPage,
                totalRecordCount: gridPaging.totalRecordCount,
            },
        });
    }

    public render() {
        const instruction = (
            <>
                Select the clients that need Site Template updates and click OK.
            </>
        );

        const buttons = (
            <OKCancelButtons
                disableOK={false}
                onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)}
                onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) =>this.onCancel(e)
                }
            />
        );
        
        const {
            selectedTableRows,
            gridPaging
        } = this.state;

        return (
            <DialogWrapper
                title={this.props.title}
                instruction={instruction}
                helpUrl="/SupportTools/Help/SUP_Maint_TemplateReapply.htm"
                buttons={buttons}
            >
                <ContentWrapper id="content_wrapper_ReapplySiteTemplate">
                    <ReapplySiteTemplateContent>
                        <TableContainer id="table_container">
                            <TableContent>
                                <table style={{ width: "100%", padding: "5px",background: "#EBECED",borderCollapse: "collapse"}}>
                                    <thead
                                        style={{
                                            backgroundColor: "#9ba1a9",
                                            padding: "5px",
                                            color: "white",
                                            position: "absolute",
                                            zIndex: 1,
                                            display: "inherit",
                                        }}
                                    >
                                        <tr>
                                            <td>
                                                <TableHeader style={{ width:  "35px", borderLeft: "none",}}></TableHeader>
                                            </td>
                                            <td>
                                                <TableHeader style={{ width: "270px"}}>
                                                    Client Name
                                                </TableHeader>
                                            </td>
                                            <td>
                                                <TableHeader style={{ width:  "160px"}}>
                                                    Date Last Applied
                                                </TableHeader>
                                            </td>
                                            <td>
                                                <TableHeader style={{ width:  "540px"}}>
                                                    Flags
                                                </TableHeader>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody
                                        style={{
                                            marginTop: "25px",
                                            display: "inherit",
                                            width: "100%",
                                            overflow: "hidden",
                                            padding: "20px",
                                        }}
                                    >
                                        {_.map(
                                            selectedTableRows,
                                            (row: ReapplySiteTemplateRow , index: number) => {
                                                
                                                if (row)
                                                    return (
                                                        <tr key={"_" + Math.random().toString(36).substr(2, 9)}>
                                                            <td style={{ marginLeft: "10px", width: "35px" }}>
                                                                <CheckBoxComponent
                                                                    id={row.JobTypeId.toString()}
                                                                    width={"20px"}
                                                                    checked={row.selected}
                                                                    onChange={(e) => this.updateRowSelection(row)}
                                                                    
                                                                />
                                                            </td>
                                                            <td style={{ width: "270px", whiteSpace: 'nowrap', overflow: "hidden" }} >
                                                                <span style={{width: "270px", fontSize: "13px", padding: "4px", display: "inline-block"}} >
                                                                    {row.ClientName}
                                                                </span>
                                                            </td>
                                                            <td style={{ width: "160px" }}  >
                                                                <span style={{fontSize: "13px", padding: "4px"}} >
                                                                    {row.DateLastApplied}
                                                                </span>
                                                            </td>
                                                            <td style={{ width: "540px", whiteSpace: 'nowrap', overflow: "hidden" }}>
                                                                <span style={{width: "540px", fontSize: "13px", padding: "4px", display: "inline-block"}} >
                                                                    {row.displayFlags}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    );
                                            }
                                        )}
                                    </tbody>
                                </table>
                            </TableContent>
                        </TableContainer>
                        <ContentRowWrapper style={{ paddingTop: "20px" }} >
                        <div style={{ cursor: 'pointer' }}
                            onClick={() => this.updateSelectAll(true)}>
                            <img src={refreshIcon} style={{ left: -4, top: 4, position: 'relative' }} />
                            <a style={{ textDecoration: 'underline', color: 'blue' }}>Select All</a>
                        </div>
                        <div style={{ marginTop: 5, flexGrow: 1, textAlign: 'right', position: 'relative', left: 20, top: -7 }}>
                            <ARMGridPager
                                currentPageNumber={gridPaging.currentPageNumber}
                                numberOfPages={gridPaging.numberOfPages}
                                pagingHandler={this.handlePaging}
                        />
                        </div>
                        </ContentRowWrapper>
                        
                    </ReapplySiteTemplateContent>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={ !!this.props.dataStore.ui.reapplySiteTemplateAlert && this.props.dataStore.ui.reapplySiteTemplateAlert.length > 0 }
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) =>  this.onClearAlert(e)}
                    message={this.props.dataStore.ui.reapplySiteTemplateAlert}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => { this.processReapplySiteTemplates() }}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                />
                
            </DialogWrapper>
        );
    }
}

const connectedHoc = connect<IReapplySiteTemplateState, IReapplySiteTemplateActionProps, IOwnProps, IReapplySiteTemplateProps,
    ApplicationState>(
    createApiMapStateToProps("reapplySiteTemplate"), // Selects which state properties are merged into the component's props
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(ReapplySiteTemplate);

export default withRouter(connectedHoc);
