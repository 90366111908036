import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { FileUploadType } from '@scripts/@types/MasterCrud/FileUpload';

export interface IFileUploadUIState {
    fileUpload: string;
}

export interface IFileUpload {
    value: string;
};

export interface IFileUploadData extends ICrudActionData<FileUploadType, IFileUpload> { }

export const actionCreators = {
    selectFileUpload: (selectInfo: IFileUploadData) => createDataAction('CHANGE_FILE_UPLOAD', selectInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IFileUploadUIState = {
    fileUpload: '',
};

export const reducer: Reducer<IFileUploadUIState, KnownActions> = (state: IFileUploadUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'CHANGE_FILE_UPLOAD':
                {
                    return {
                        ...state,
                        fileUpload: action.data.uiData.value
                    }
                    break;
                }
            default:
                return state;
        }
    }

    return state || defaultState;
}
