import { AnyAction, Dispatch } from "redux";
import { batch } from "react-redux";
import { IAction, IDataAction, IApiAction, TApiStatus, ValidationCallback, ErrorCallback } from
    '@scripts/util/ActionHelpers';
import { History } from 'history';

// THUNK INTERFACE DEFINITIONS
/**
 * Wrapper class for thunk results that allows onSuccess/onFail branching and the ability to
 * pass the results of one function into the next.
 * @param {any} ReturnType The type of the expected value result of a thunk function.
 */
export interface IThunkResult<ReturnType extends any> {
    success: boolean,
    value?: ReturnType,
}

/**
 * The standard thunk function for Thunk API Actions. Only runs when the API status is SUCCESS/FAIL.
 */
export type AppThunk<ReturnType = any> = {
    (dispatch: Dispatch<AnyAction>, previousResult?: any, extraArguments?: any): IThunkResult<ReturnType>;
}

export type AnyThunkAction<T extends string, D = any, R extends unknown = any, ReturnType extends unknown = any> =
    IThunkAction<T> | IThunkDataAction<T, D> | IThunkApiAction<T, D, R, ReturnType>

/**
 * An action that dispatches actions or executes functions inside the middleware.
 *
 * @param {string} T The string literal name of the action.
 * @param {any} ReturnType The type of the thunk result's inner value. Defaults to any.
 */
export interface IThunkAction<T extends string, ThunkResult = void, TExtraArgs extends any = any> extends IAction<T> {
    thunk: AppThunk<ThunkResult>,
    result?: ThunkResult,
    lastResult?: any,
    extraArgs?: TExtraArgs,
    nextAction?: any,
    onFailAction?: any,
}

/**
 * An action with additional data that dispatches actions or executes functions inside the middleware.
 *
 * @param {string} T The string literal name of the action.
 * @param {any} D The type of the data passed into the thunk action.
 * @param {any} ReturnType The type of the thunk result's inner value. Defaults to any.
 */
export interface IThunkDataAction<T extends string, D, ThunkResult = void, TExtraArgs extends any = any> extends
    IDataAction<T, D>,
    IThunkAction<T, ThunkResult, TExtraArgs> { }

/**
 * An API action with a thunk to dispatch after the API result has been determined.
 *
 * @param {string} T The string literal name of the action.
 * @param {any} D The type of the data passed into the thunk action.
 * @param {any} R The type of the API response returned. Defaults to any.
 * @param {any} ReturnType The type of the thunk result's inner value. Defaults to any.
 */
export interface IThunkApiAction<
    T extends string,
    D,
    ThunkResult = void,
    R extends any = any,
    TExtraArgs extends any = any> extends
    IApiAction<T, D, R>,
    IThunkDataAction<T, D, ThunkResult, TExtraArgs> {
}

/**
 * Decorator function that enforces having a static method on a class.
 */
export function staticDecorator<T>() {
    return (constructor: T) => { };
}

/**
 * Interface used to enforce the requirement of having a static fromAction method
 * to allow for easier copy creation.
 */
interface IComplexActionStaticBase<
    T extends string,
    PlainSimpleActionType,
    ComplexActionType> {
    fromAction(plainAction: PlainSimpleActionType): ComplexActionType,
}

type NextComplexAction<T extends string, NextPlainType> =
    IComplexActionBase<T, NextPlainType, any, any, any>;

/**
 * The base interface for complex actions. Allows for the complex-specific properties
 * to be inherited into child interfaces without redefinition thanks to generic typing.
 */
export interface IComplexActionBase<
    T extends string,
    FinalPlainObjectType,
    NextChildType extends IComplexActionBase<string, NextPlainType, any, any, any>,
    ThunkResultType extends any = any,
    NextPlainType extends any = undefined> extends IAction<T> {
    thunk: AppThunk<ThunkResultType> | undefined,
    noThunk: boolean,
    extraArgs?: any,
    addThunk<InnerThunkResult>(thunk: AppThunk<InnerThunkResult>): IComplexActionBase<T, FinalPlainObjectType, NextChildType, InnerThunkResult, NextPlainType>,
    withExtraArgs(extraArgs: any): IComplexActionBase<T, FinalPlainObjectType, NextChildType, ThunkResultType, NextPlainType>,
    finish(): FinalPlainObjectType,
    then<NewNextType extends IComplexActionBase<string, NewNextPlain, any, any, any>, NewNextPlain extends any>(nextAction: NewNextType): IComplexActionBase<T, FinalPlainObjectType, NewNextType, ThunkResultType, NewNextPlain>,
    thenRedirect(url: string, history: History<any>, stateData?: any): IComplexActionBase<T, FinalPlainObjectType, IComplexActionBase<'THUNK_REDIRECT', IThunkAction<string, any, any>, any, any, any>, ThunkResultType, IThunkAction<string, any, any>>,
    nextAction?: NextPlainType,
    nextComplexAction: NextChildType | undefined,
    previousResult?: ThunkResultType,
    withNoThunk(): IComplexActionBase<T, FinalPlainObjectType, NextChildType, ThunkResultType, NextPlainType>,
    finishAsBatch(): IThunkAction<'BATCH_THUNK_ACTION'>,
    changeType<NewType extends string>(newType: NewType): IComplexActionBase<NewType, IThunkAction<NewType> | IThunkDataAction<NewType, ThunkResultType, any> | IThunkApiAction<NewType, any, ThunkResultType, any, any>, NextChildType, ThunkResultType, NextPlainType>,
    //onFailThen(onFailAction: any): IComplexActionBase<T, FinalPlainObjectType, NextChildType, ThunkResultType, NextPlainType>,
}

// COMPLEX ACTION CLASS IMPLEMENTATIONS.

abstract class ComplexActionBase<
    T extends string,
    ChildActionType,
    FinalPlainObjectType,
    NextChildType extends IComplexActionBase<string, NextPlainType, any, any, any>,
    ThunkResultType extends any = any,
    NextPlainType extends any = undefined> implements
    IComplexActionBase<T, FinalPlainObjectType, NextChildType, ThunkResultType, NextPlainType>
{
    // Properties
    /**
     * The type of action this is. Should be a string unique to the action.
     */
    public type: T;

    /**
     * The thunk for this action. A thunk is a function that will be called before the action is passed
     * to the reducer. If the thunk is undefined, this will be treated like a plain Redux action.
     */
    public thunk: AppThunk<ThunkResultType> | undefined;

    /**
     * A subsequent plain action that should be dispatched to the pipeline by the middleware if this action completes
     * successfully.
     */
    public nextAction?: NextPlainType;

    /**
     * A flag to identify complex actions that do not have a thunk so that it can be ignored when trying to 
     * find the applicable action in addThunk.
     */
    public noThunk: boolean;

    /**
     * The complex representation of the action that will be dispatched after this one. This property will be removed
     * from the final plain object in the finish call.
     */
    public nextComplexAction: NextChildType | undefined;

    /**
     * Any extra arguments that need to be passed to the thunk in order for it to execute properly.
     */
    public extraArgs: any | undefined;

    public onFailAction: any | undefined;

    // Protected Constructor for common values.
    /**
     * A protected constructor for shared usage by our complex actions. Takes in a type, an optional thunk,
     * and an optional object containing any additional arguments that should be passed to the thunk.
     * @param type A string unique to this action that says what it is.
     * @param thunk A function run before the reducer that can dispatch other actions or manipulate the current action.
     * @param extraArgs An object containing any extra arguments that need to be passed to the thunk function.
     * @param nextActions An array of AnyActions that define subsequent actions.
     */
    protected constructor(
        type: T,
        thunk?: AppThunk<ThunkResultType>,
        nextComplexAction?: NextChildType,
        extraArgs?: any) {
        this.type = type;
        this.thunk = thunk;
        this.extraArgs = extraArgs;
        this.nextComplexAction = nextComplexAction;
        this.noThunk = false;
    }

    // Methods
    // NOTE - All of these methods will cast objects to the compile-time defined ChildActionType and FinalPlainObjectType
    // types. These types are defined when we extend the base class.  It is very important that we get the extends call
    // correct for each type.

    /**
     * Adds a thunk to this action if no thunk is defined or if this action does not have a next action.  If a thunk is already 
     * defined and a next action is defined, this will pass the new thunk down the chain until it hits an action that does not
     * have a thunk or does not have a next action.
     * 
     * @param thunk A thunk that will be run as a part of this action.
     *
     */
    public addThunk<TResultType extends any>(newThunk: AppThunk<TResultType>)
        : IComplexActionBase<T, FinalPlainObjectType, NextChildType, TResultType, NextPlainType> {
        const unboxedThis = this as any;
        // If this action has a next action already has a thunk or is marked as an action without a thunk, pass the thunk down.
        if ((this.thunk || this.noThunk) && this.nextComplexAction) {
            unboxedThis.nextComplexAction = this.nextComplexAction.addThunk(newThunk);

        }
        else {
            // If this action has not been marked as a noThunk action,
            if (!this.noThunk)
                unboxedThis.thunk = newThunk;
            else
                console.log("Error - tried to add a thunk to an action that is marked as noThunk!");
        }
        return unboxedThis as IComplexActionBase<T, FinalPlainObjectType, NextChildType, TResultType, NextPlainType>;
    }

    //public onFailThen(failAction: any): IComplexActionBase<T, FinalPlainObjectType, NextChildType, ThunkResultType, NextPlainType> {

    //}

    /**
     *  Enables the noThunk flag and removes any thunk attached to this action so that this action is ignored when 
     *  trying to determine where an added thunk goes.  
     */
    public withNoThunk(): IComplexActionBase<T, FinalPlainObjectType, NextChildType, ThunkResultType, NextPlainType> {
        this.noThunk = true;
        this.thunk = undefined;
        return this;
    }

    /**
     * Adds an extraArgs object to the action that will be passed to the thunk function.
     * @param extraArgs An object that will be passed to the thunk function.
     */
    public withExtraArgs(extraArgs: any): IComplexActionBase<T, FinalPlainObjectType, NextChildType, ThunkResultType, NextPlainType> {
        this.extraArgs = extraArgs;
        return this;
    }

    abstract changeType<NewType extends string>(newType: NewType): IComplexActionBase<NewType, IThunkAction<NewType> | IThunkDataAction<NewType, ThunkResultType, any> | IThunkApiAction<NewType, any, ThunkResultType, any, any>, NextChildType, ThunkResultType, NextPlainType>;
    //public changeType<NewType extends string, NewFinalObjectType>(newType: NewType): IComplexActionBase<NewType, NewFinalObjectType, NextChildType, ThunkResultType, NextPlainType> {
    //    const unboxedThis = this as any;
    //    unboxedThis.type = newType;
    //    return unboxedThis as IComplexActionBase<NewType, NewFinalObjectType, NextChildType, ThunkResultType, NextPlainType>;
    //}

    public then<NewNextType extends IComplexActionBase<string, NewNextPlain, any, any, any>, NewNextPlain extends any = undefined>(nextAction: NewNextType): IComplexActionBase<T, FinalPlainObjectType, NewNextType, ThunkResultType, NewNextPlain> {
        const unboxedThis = this as any;

        // If this complex action has a next action, we need to pass it down the chain until we hit an
        // action that doesn't have a next action setup.
        unboxedThis.nextComplexAction = this.nextComplexAction ? this.nextComplexAction.then(nextAction) : nextAction;

        return unboxedThis as IComplexActionBase<T, FinalPlainObjectType, NewNextType, ThunkResultType, NewNextPlain>;
    }

    public thenRedirect(url: string, history: History<any>, stateData?: any): IComplexActionBase<T, FinalPlainObjectType, IComplexActionBase<'THUNK_REDIRECT', IThunkAction<string, any, any>, any, any, any>, ThunkResultType, IThunkAction<string, any, any>> {
        const redirectAction = new ComplexAction('THUNK_REDIRECT').addThunk(
            (dispatch: Dispatch<AnyAction>, previousAction: any, extraArgs: any): IThunkResult<boolean> => {
                if (extraArgs.history && extraArgs.url) {
                    extraArgs.history.push(url, stateData);
                };
                return { success: true };
            }).withExtraArgs({ history: history, url: url });
        return this.then(redirectAction);
    }

    public finish(): FinalPlainObjectType {
        if (this.nextComplexAction)
        // Strip out all of the functions and complex actions with destructuring and return the object as a simple POJO
        {
            this.nextAction = this.nextComplexAction.finish();
        }
        const { addThunk, withExtraArgs, then, finish, nextComplexAction, noThunk, withNoThunk, finishAsBatch, ...action } = this;
        return action as any as FinalPlainObjectType;
    }


    /**
     *  Experimental method that tries to batch together all of the calls to prevent unnecessary re-renders.
     *  TODO - Add optional string param that acts as the final batch action name.
     */
    public finishAsBatch(): IThunkAction<'BATCH_THUNK_ACTION'> {
        // Finish the current action so we have it and all its links as plain objects.
        let currentAction = this.finish() as any;
        let actionList: any[] = [];

        // Build the list of actions in the chain.
        while (currentAction.nextAction) {
            const nextAction = currentAction.nextAction;
            currentAction.nextAction = undefined;
            actionList.push(currentAction);
            currentAction = nextAction;
        }
        actionList.push(currentAction);

        // Return a thunk that batches together all of the dispatch calls for the action chain.
        return {
            type: 'BATCH_THUNK_ACTION',
            thunk: (dispatch: Dispatch<AnyAction>) => {
                batch(() => {
                    console.log("Dispatching batched thunk actions.")
                    for (let action of actionList) {
                        console.log("Dispatched action in batch");
                        dispatch(action)
                    };
                });
                return { success: true };
            }
        }
    }
}

/**
 * An IAction with function definitions for fluent coding. Must call finish()
 * to get to a plain object for redux.
 */
//export interface IComplexAction<
//        T extends string,
//        NextChildType extends NextComplexAction<NextChildType, NextPlainType>,
//        ThunkResultType extends any = void,
//        NextPlainType extends any = undefined>
//    extends
//    IThunkAction<T, ThunkResultType>,
//    IComplexActionBase<
//        IComplexAction<T, NextChildType, ThunkResultType, NextPlainType>,
//        IThunkAction<T, ThunkResultType>,
//        NextChildType,
//        ThunkResultType,
//        NextPlainType> {
//    };

// Base implementation is fulfilled by the base object. See ComplexActionBase for details.
@staticDecorator<IComplexActionStaticBase<string, IAction<string>, ComplexAction<string, any, unknown, unknown>>>()
export class ComplexAction<
    T extends string,
    NextChildType extends IComplexActionBase<T, NextPlainType, any, any, any>,
    ThunkResultType extends any = any,
    NextPlainType extends any = undefined>
    extends ComplexActionBase
    <
    T,
    ComplexAction<T, NextChildType, ThunkResultType, NextPlainType>,
    IThunkAction<T, ThunkResultType>,
    NextChildType,
    ThunkResultType,
    NextPlainType>
{
    // Properties
    // **INHERITED PROPERTIES**
    // type: T
    // thunk: AppDispatch | undefined
    // extraArgs: any

    // Constructor
    public constructor(type: T, thunk?: AppThunk<ThunkResultType>, nextAction?: NextChildType, extraArgs?: any) {
        super(type, thunk, nextAction, extraArgs);
    }


    //Methods
    // **INHERITED METHODS**
    // addThunk(thunk: AppThunk): IComplexAction<T>
    // withExtraArgs(extraArgs: any): IComplexAction<T>
    // then(nextAction: AnyAction): IComplexAction<T>
    // finish(): IThunkDataAction<T,D>
    public static fromAction<
        T2 extends string,
        TNextActionType extends IComplexActionBase<T2, TNextPlainActionType, any, any, any>,
        TThunkResult extends any = any,
        TNextPlainActionType extends any = undefined>(
            baseAction: IAction<T2>,
            thunk?: AppThunk<TThunkResult>,
            nextAction?: TNextActionType,
            extraArgs?: any
        ):
        ComplexAction<T2, TNextActionType, TThunkResult, TNextPlainActionType> {
        return new ComplexAction
            (
                baseAction.type,
                thunk,
                nextAction,
                extraArgs);
    }

    public changeType<NewType extends string>(newType: NewType)
        : IComplexActionBase<NewType, IThunkAction<NewType>, NextChildType, ThunkResultType, NextPlainType> {
        const unboxedThis = this as any;
        unboxedThis.type = newType;
        return unboxedThis as IComplexActionBase<NewType, IThunkAction<NewType>, NextChildType, ThunkResultType, NextPlainType>;
    }

}


@staticDecorator<IComplexActionStaticBase<string, IDataAction<string, any>, ComplexDataAction<string, any, any, any>>>()
export class ComplexDataAction<
    T extends string,
    D,
    NextChildType extends IComplexActionBase<T, NextPlainType, any, any, any>,
    ThunkResultType extends any = any,
    NextPlainType extends any = undefined>
    extends ComplexActionBase
    <
    T,
    ComplexDataAction<T, D, NextChildType, ThunkResultType, NextPlainType>,
    IThunkDataAction<T, D, ThunkResultType>,
    NextChildType,
    ThunkResultType,
    NextPlainType>
{
    // Properties
    // **INHERITED PROPERTIES**
    // type: T
    // thunk: AppDispatch | undefined
    // extraArgs: any
    public data: D;

    // Constructor
    constructor(type: T, data: D, thunk?: AppThunk<ThunkResultType>, nextAction?: NextChildType, extraArgs?: any,) {
        super(type, thunk, nextAction, extraArgs);
        this.data = data;
    }

    //Methods
    // **INHERITED METHODS**
    // addThunk(thunk: AppThunk): IComplexDataAction<T,D>
    // withExtraArgs(extraArgs: any): IComplexDataAction<T,D>
    // finish(): IThunkDataAction<T,D>
    public static fromAction<
        T2 extends string,
        TNextChildType extends IComplexActionBase<T2, TNextPlainType, any, any, any>,
        D2,
        TThunkResult extends any = any,
        TNextPlainType extends any = undefined>(
            baseAction: IDataAction<T2, D2>,
            thunk?: AppThunk<TThunkResult>,
            nextAction?: TNextChildType,
            extraArgs?: any
        ):
        ComplexDataAction<T2, D2, TNextChildType, TThunkResult, TNextPlainType> {
        return new ComplexDataAction(baseAction.type, baseAction.data, thunk, nextAction, extraArgs);
    }

    public changeType<NewType extends string>(newType: NewType)
        : IComplexActionBase<NewType, IThunkDataAction<NewType, any>, NextChildType, ThunkResultType, NextPlainType> {
        const unboxedThis = this as any;
        unboxedThis.type = newType;
        return unboxedThis as IComplexActionBase<NewType, IThunkDataAction<NewType, any>, NextChildType, ThunkResultType, NextPlainType>;
    }
}

@staticDecorator<IComplexActionStaticBase<string, IDataAction<string, any>, ComplexApiAction<string, any, any, any, any>>>()
export class ComplexApiAction<
    T extends string,
    D extends any,
    R,
    NextChildType extends IComplexActionBase<T, NextPlainType, any, any, any>,
    ThunkResultType extends any = any,
    NextPlainType extends any = undefined>
    extends ComplexActionBase
    <
    T,
    ComplexApiAction<T, D, R, NextChildType, ThunkResultType, NextPlainType>,
    IThunkApiAction<T, D, ThunkResultType, R>,
    NextChildType,
    ThunkResultType,
    NextPlainType>
{
    // Properties
    // **INHERITED PROPERTIES**
    // type: T
    // thunk: AppDispatch | undefined
    // extraArgs: any
    public data: D;
    public url: string;
    public status: TApiStatus;
    public requestData: RequestInit;
    public responseData: R | undefined;
    public validationCallback: ValidationCallback<R> | undefined;
    public errorCallback: ErrorCallback | undefined;
    public thunkBeforeApi?: boolean;

    // Constructor
    public constructor(
        type: T,
        data: D,
        url: string,
        status: TApiStatus,
        requestData: RequestInit,
        responseData?: R,
        validationCallback?: ValidationCallback<R>,
        errorCallback?: ErrorCallback,
        thunk?: AppThunk<ThunkResultType>,
        thunkBeforeApi?: boolean,
        nextAction?: NextChildType,
        extraArgs?: any) {
        super(type, thunk, extraArgs, nextAction);
        this.data = data;
        this.url = url;
        this.status = status;
        this.requestData = requestData;
        this.responseData = responseData;
        this.validationCallback = validationCallback;
        this.errorCallback = errorCallback;
        this.thunkBeforeApi = thunkBeforeApi;
    }

    //Methods
    // **INHERITED METHODS**
    // addThunk(thunk: AppThunk): IComplexApiAction<T,D,R>
    // withExtraArgs(extraArgs: any): IComplexApiAction<T,D,R>
    // finish(): IThunkApiAction<T,D,R>
    public static fromAction<
        T2 extends string,
        D2,
        R2,
        TNextChildType extends IComplexActionBase<T2, TNextPlainType, any, any, any>,
        TThunkResult extends any = any,
        TNextPlainType extends any = undefined>(
            baseAction: IThunkApiAction<T2, D2, TThunkResult, R2> | IApiAction<T2, D2, R2>,
            thunkbeforeApi?: boolean,
            thunk?: AppThunk<TThunkResult>,
            nextAction?: TNextChildType,
            extraArgs?: any):
        ComplexApiAction<T2, D2, R2, TNextChildType, TThunkResult, TNextPlainType> {
        function isThunkAction(action: IThunkApiAction<T2, D2, TThunkResult, R2> | IApiAction<T2, D2, R2>)
            : action is IThunkApiAction<T2, D2, TThunkResult, R2> {
            return (action as IThunkApiAction<T2, D2, TThunkResult, R2>)?.thunk !== undefined;
        }

        return new ComplexApiAction<T2, D2, R2, TNextChildType, TThunkResult, TNextPlainType>
            (
                baseAction.type,
                baseAction.data,
                baseAction.url,
                baseAction.status,
                baseAction.requestData,
                baseAction?.responseData,
                baseAction?.validationCallback,
                baseAction?.errorCallback,
                isThunkAction(baseAction) ? baseAction?.thunk : thunk,
                thunkbeforeApi,
                nextAction,
                extraArgs);
    }


    public changeType<NewType extends string>(newType: NewType)
        : IComplexActionBase<NewType, IThunkApiAction<NewType, any, ThunkResultType, any, any>, NextChildType, ThunkResultType, NextPlainType> {
        const unboxedThis = this as any;
        unboxedThis.type = newType;
        return unboxedThis as IComplexActionBase<NewType, IThunkApiAction<NewType, any, ThunkResultType, any, any>, NextChildType, ThunkResultType, NextPlainType>;
    }
}

// UTIL THUNK FUNCTIONS
export function createThunkApiStatusAction<T extends string, D>(
    action: IThunkApiAction<T, D, any>,
    status: TApiStatus,
    responseData: any): IThunkApiAction<T, D, any>;
export function createThunkApiStatusAction<T extends string, D>(
    action: IThunkApiAction<T, D, any>,
    status: TApiStatus,
    responseData: any): IThunkApiAction<T, D, any> {
    const newAction = { ...action };
    newAction.status = status;
    newAction.responseData = responseData;
    return newAction;
}

export function addThunkToAction<FinalActionType>(innerAction: AnyAction, thunk: AppThunk, extraArguments?: any):
    FinalActionType;
export function addThunkToAction<FinalActionType>(innerAction: AnyAction, thunk: AppThunk, extraArguments?: any):
    FinalActionType {
    const newAction = innerAction as any;
    newAction.thunk = thunk;
    if (extraArguments) {
        newAction.extraArguments = extraArguments;
    }
    return newAction as FinalActionType;
}