import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { SelectGroupComponent } from '@common/SelectGroupComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { ISubmitClaimUpdateFileState, ISubmitClaimUpdateFileActionProps, actionCreators } from '@store/SubmitClaimUpdateFile';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ARMNarrowBrowse } from '@common/ARMNarrowBrowse';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '@common/AssuranceMenu';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

export const UpdatingDialogContainer = styled.div`
    width: 400px;
    height: 300px;
    border: 1px solid grey;
`;

export const UpdatingStatusMsg = styled.div`
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${Colors.black};
    ${Typography.headingSmall};
    ${Typography.ARMFontFamily};
`;

interface IComponentProps {
    canView: boolean;
};

interface IComponentState {
    files: any;
    isUpdating: boolean;
    isAlertOpen: boolean;
    alertMessage: string;
    cancelLeave: boolean;
};

export const DEFAULT_STATE: IComponentState = {
    files: new FormData(),
    isUpdating: false,
    isAlertOpen: false,
    alertMessage: "",
    cancelLeave: false
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type ISubmitClaimUpdateFileProps = IMergeCrudComponentProps<ISubmitClaimUpdateFileState, ISubmitClaimUpdateFileActionProps, IOwnProps>;

export class SubmitClaimUpdateFile extends React.Component<ISubmitClaimUpdateFileProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canView: false,
    };

    constructor(props: ISubmitClaimUpdateFileProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        pageLeave();
    }

    public validate() {
        if (this.props.dataStore.ui.uploadSubmitClaimUpdate === "") {
            this.setState({ isAlertOpen: true, alertMessage: "Please select a file to upload!" });
            return false;
        }
        return true;
    }

    public onOKSubmit(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.validate()) {

            this.setState({ isUpdating: true });
            fetch(URLs.api + '/api/data/SubmitClaimUpdateFile',
                {
                    method: 'POST',
                    headers: {
                        'Authorization': `${getRawToken()}`
                    },
                    body: this.state.files,
                })
                .then(async (response) => {
                    // get json response here
                    let data: any = await response.json();
                    if (data === "success") {
                        this.setState({ isUpdating: false });
                        this.props.history.push('/LandingPage');
                    }
                    else if (data === "fileerror") {
                        this.setState({ isUpdating: false });
                        this.setState({ isAlertOpen: true, alertMessage: "Please select a valid file type" });
                    }
                    else {
                        this.setState({ isUpdating: false });
                        this.setState({ isAlertOpen: true, alertMessage: "Error while uploading the submit claim update file. Please try again" });
                    }
                })
                .catch(error => {
                    console.error('WGD: ' + error);
                    //this.setState({ isUpdating: false });
                });
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public uploadSubmitClaimUpdateFile(uploadedfile: any, index: any) {
        handleChange();
        const uploadData = new FormData()
        uploadData.append('file', uploadedfile.target.files)
        const files = this.state.files
        files?.delete(`file[${index}]`)
        files.append(`file[${index}]`, uploadedfile.target.files[0])
        this.props.action.ui.selectSubmitClaimUpdateFile(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: uploadedfile.target.files[0].name
                }
            });
        this.setState({ files: files })
    }

    public render() {
        var width: React.CSSProperties = {
            width: '300px'
        }
        var instruction = <React.Fragment>Browse to the Claim Update File you want to submit.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canView} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKSubmit(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        return (
            <DialogWrapper title="Submit Claim Update File" instruction={instruction} helpUrl='/Support/Help/HELP_Maint_SubmitClaimUpdateFile.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                {!this.state.isUpdating ? (
                    <ContentWrapper>
                        <ContentRowWrapper style={{ marginTop: "5px", marginLeft: "5px" }}>
                            <ARMNarrowBrowse labelValue="File:"
                                inputId="file-upload"
                                classNameValue="text-input"
                                maxLength={300}
                                initialValue={this.props.dataStore.ui.uploadSubmitClaimUpdate}
                                disabledInput={true}
                                browseId="fileupload"
                                browseInputId="file"
                                onChange={(e: any) => this.uploadSubmitClaimUpdateFile(e, 1)}
                                styleValue={{ marginTop: "20px", marginLeft: "5px" }}
                                inputColumnStyleValue={{ marginTop: "10px", marginLeft: "5px" }}
                                browseColumnStyleValue={{ marginTop: "5px", marginLeft: "10px" }}
                                divBrowseStyle={{ marginTop: '7px' }}
                                acceptType=".cuf,.xml"
                                canEdit={true}
                            >
                            </ARMNarrowBrowse>
                        </ContentRowWrapper>
                    </ContentWrapper>
                ) : (
                        <UpdatingDialogContainer>
                            <UpdatingStatusMsg>
                                Uploading Submit claim changes, please wait..
                                </UpdatingStatusMsg>
                        </UpdatingDialogContainer>
                    )}
                <ModalConfirmation
                    isOpen={this.state.isAlertOpen}
                    formattedMessage={(
                        <React.Fragment>
                            <p>{this.state.alertMessage}</p>
                        </React.Fragment>)
                    }
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isAlertOpen: false, alertMessage: "" }) }}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.setState({ isAlertOpen: false, alertMessage: "" })

                    }
                    }
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isAlertOpen: false }) }}
                    alertMode={true}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<ISubmitClaimUpdateFileState, ISubmitClaimUpdateFileActionProps, IOwnProps, ISubmitClaimUpdateFileProps, ApplicationState>(
    createCrudMapStateToProps('submitClaimUpdateFile'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(SubmitClaimUpdateFile);

export default withRouter(connectedHoc);
