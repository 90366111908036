import { Reducer } from 'redux';
import { ICrudActionData } from "../../scripts/util/CrudComponentHelpers";
import { createDataAction, ActionTypes } from "../../scripts/util/ActionHelpers";

const BCPO_DEFAULT_INST_FORM_TYPE = "UB04";
const BCPO_DEFAULT_PROF_FORM_TYPE_CMS15002012 = "CMS15002012";
const BCPO_DEFAULT_PROF_FORM_TYPE_CMS1500 = "CMS1500";
const BCPO_FORM_TYPE_ADA1999 = "ADA1999";
const BCPO_FORM_TYPE_ADA2002 = "ADA2002";
const BCPO_FORM_TYPE_ADA2006 = "ADA2006";
const BCPO_FORM_TYPE_ADA2012 = "ADA2012";
const BCPO_FORM_TYPE_ADA2019 = "ADA2019";


export interface IBCPOOption {
    label: string,
    value: string
}

export interface IBatchClaimsPrintingOptionsData {
    // Page Form Types
    showADA1999: boolean;
    showADA2002: boolean;
    showADA2006: boolean;
    showADA2012: boolean;
    showADA2019: boolean;

    selectedInstFormType: string;
    selectedProfFormType: string;

    // Print and Font Type
    printTypeList: any[];
    fontFaceList: any[];
    fontSizeList: any[];
    instPrintCommandSetList: any[];
    profPrintCommandSetList: any[];

    printTypeDisabled: boolean;
    fontDisabled: boolean;

    selectedPrintType: IBCPOOption;
    selectedFontFace: IBCPOOption;
    selectedFontSize: IBCPOOption;
    selectedInstPrintCommandSet: IBCPOOption;
    selectedProfPrintCommandSet: IBCPOOption;

    dataLoaded: boolean;
}

// UI representation of CRUD data.
export interface IBatchClaimsPrintingOptionsUIState extends IBatchClaimsPrintingOptionsData { }
export interface IBCPOCrudAndConfig extends MCPaperClaimsPrinting { CMS2012LiveDate: string; }

// merge our custom action data interface with CRUD boilerplate
export interface IBCPOInitialize extends ICrudActionData<MCPaperClaimsPrinting, IBCPOCrudAndConfig> { }
export interface IBCPOSelectFormType extends ICrudActionData<MCPaperClaimsPrinting, string> { }
export interface IBCPOSelectOption extends ICrudActionData<MCPaperClaimsPrinting, IBCPOOption> { }

// exported for if I get time to do tests, otherwise does not need export
export const actionCreators = {
    initalizeOptions: (rawApiReturn: IBCPOInitialize) => createDataAction('INIT_BCPO_OPTIONS', rawApiReturn),
    selectInstFormType: (selectInfo: IBCPOSelectFormType) => createDataAction('SELECT_INST_FORM_TYPE', selectInfo),
    selectProfFormType: (selectInfo: IBCPOSelectFormType) => createDataAction('SELECT_PROF_FORM_TYPE', selectInfo),
    selectPrintType: (selectOption: IBCPOSelectOption) => createDataAction('SELECT_PRINT_TYPE', selectOption),
    selectFontFace: (selectOption: IBCPOSelectOption) => createDataAction('SELECT_FONT_FACE', selectOption),
    selectFontSize: (selectOption: IBCPOSelectOption) => createDataAction('SELECT_FONT_SIZE', selectOption),
    selectInstPrintCommandSet: (selectOption: IBCPOSelectOption) => createDataAction('SELECT_INST_PRINTCOMMANDSET', selectOption),
    selectProfPrintCommandSet: (selectOption: IBCPOSelectOption) => createDataAction('SELECT_PROF_PRINTCOMMANDSET', selectOption),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;

export const defaultBatchClaimsPrintingOptionsState: IBatchClaimsPrintingOptionsUIState = {
    showADA1999: false,
    showADA2002: false,
    showADA2006: false,
    showADA2012: false,
    showADA2019: false,

    selectedInstFormType: "UB04",
    selectedProfFormType: "",

    printTypeList: [],
    fontFaceList: [],
    fontSizeList: [],
    instPrintCommandSetList: [],
    profPrintCommandSetList: [],
    printTypeDisabled: false,
    fontDisabled: false,

    selectedPrintType: { label: "", value: "" },
    selectedFontFace: { label: "", value: "" },
    selectedFontSize: { label: "", value: "" },
    selectedInstPrintCommandSet: { label: "", value: "" },
    selectedProfPrintCommandSet: { label: "", value: "" },

    dataLoaded: false,
}

const defaultOption: any = { label: "Default", value: -1 };

const BCPO_FORMCLAIMTYPES = {
    UB92: '1011',
    UB04: '2011',
    HCFA1500: '1001',
    CMS1500: '2001',
    CMS15002012: '3001',
    ADA1999: '3010',
    ADA2002: '3011',
    ADA2006: '3012',
    ADA2012: '3013',
    ADA2019: '3014'
};

function getClaimTypeId(formType: string) {
    var claimTypeId = '';
    switch (formType) {
        // Institutional
        case "UB92":
            claimTypeId = BCPO_FORMCLAIMTYPES.UB92;
            break;
        case "UB04":
            claimTypeId = BCPO_FORMCLAIMTYPES.UB04;
            break;

        // Professional
        case "HCFA1500":
            claimTypeId = BCPO_FORMCLAIMTYPES.HCFA1500;
            break;
        case "CMS1500":
            claimTypeId = BCPO_FORMCLAIMTYPES.CMS1500;
            break;
        case "CMS15002012":
            claimTypeId = BCPO_FORMCLAIMTYPES.CMS15002012
            break;
        case "ADA1999":
            claimTypeId = BCPO_FORMCLAIMTYPES.ADA1999;
            break;
        case "ADA2002":
            claimTypeId = BCPO_FORMCLAIMTYPES.ADA2002;
            break;
        case "ADA2006":
            claimTypeId = BCPO_FORMCLAIMTYPES.ADA2006;
            break;
        case "ADA2012":
            claimTypeId = BCPO_FORMCLAIMTYPES.ADA2012;
            break;
        case "ADA2019":
            claimTypeId = BCPO_FORMCLAIMTYPES.ADA2019;
            break;
        default:  // should not go here
            claimTypeId = "";
            break;
    }
    return claimTypeId;
}

function isADAForm(formType: string) {
    return formType.startsWith("ADA");
}

function getList(crudList: any[]) {
    let result: any[] = [defaultOption];
    if (!crudList)
        return result;

    crudList.forEach(ob => {
        if (ob['@Name'])
            result.push({ label: ob['@Name'], value: ob['@ID'] });
    });
    return result;
}


function getPrintTypes(crud: MCPaperClaimsPrintingMaintenanceInfo) {
    return getList(crud.FormTypeList?.FormType);
}

function getFontFaces(crud: MCPaperClaimsPrintingMaintenanceInfo) {
    return getList(crud.FontFaceList?.FontFace);
}

function getFontSizes(crud: MCPaperClaimsPrintingMaintenanceInfo) {
    return getList(crud.FontSizeList?.FontSize);
}

function getPrintCommandSetList(formType: string, crud: MCPaperClaimsPrintingMaintenanceInfo) {
    let result: any[] = [];

    var claimTypeId = getClaimTypeId(formType);
    if (claimTypeId == "") return result; // Should not happen

    let cmdSetList = (crud && crud && crud.PrintCommandSetList && crud.PrintCommandSetList.PrintCommandSet);

    if (!cmdSetList) {
        result.push({ label: "Standard", value: "-1" });
        return result;
    }

    cmdSetList.forEach(ob => {
        if (ob['@ClaimTypeId'] && ob['@ClaimTypeId'] == claimTypeId)
            result.push({ label: ob['@Name'], value: ob['@ID'] });
    });

    return result;
}

function getDefaultPrintCommandSet(formType: string, crud: MCPaperClaimsPrintingMaintenanceInfo) {
    let defaultCommandSetId: string = "";
    let defaultCommandSetLabel: string = "";

    var claimTypeId = getClaimTypeId(formType);
    if (claimTypeId == "") return { label: defaultCommandSetLabel, value: defaultCommandSetId }; // Should not happen

    let cmdSetList = crud?.PrintCommandSetList?.PrintCommandSet;

    if (!cmdSetList) {
        return { label: defaultCommandSetLabel, value: defaultCommandSetId };
    }

    var defaultValue = cmdSetList.filter(ob => ob['@ClaimTypeId'] == claimTypeId && ob['@IsDefault'] == "-1")
    if (defaultValue && defaultValue.length == 1) {
        defaultCommandSetId = defaultValue[0]['@ID'];
        defaultCommandSetLabel = defaultValue[0]['@Name'];
    }
    return { label: defaultCommandSetLabel, value: defaultCommandSetId };
}

function isInPast(date: string) {

    var dtDate = new Date(date);
    var dtNow = new Date();

    if (dtDate.getFullYear() < dtNow.getFullYear())
        return true;

    if (dtDate.getFullYear() === dtNow.getFullYear()) {
        if (dtDate.getMonth() < dtNow.getMonth())
            return true;

        if (dtDate.getMonth() === dtNow.getMonth()) {
            if (dtDate.getDay() < dtNow.getDay())
                return true;
        }
    }
    return false;
}

function getDefaultProfFormType(CMS2012LiveDate: string, crud: MCPaperClaimsPrintingMaintenanceInfo) {
    if (crud?.DateOptions?.DateOption && crud.DateOptions.DateOption['@CMSPrintDate']) {
        return (isInPast(crud.DateOptions.DateOption['@CMSPrintDate']) ?
            BCPO_DEFAULT_PROF_FORM_TYPE_CMS15002012:BCPO_DEFAULT_PROF_FORM_TYPE_CMS1500);
    }
    return (isInPast(CMS2012LiveDate) ? BCPO_DEFAULT_PROF_FORM_TYPE_CMS15002012 : BCPO_DEFAULT_PROF_FORM_TYPE_CMS1500);
}

function showForm(formType: string, crud: MCPaperClaimsPrintingMaintenanceInfo) {
    let claimTypeId = getClaimTypeId(formType)
    let printOptionList = crud?.PrintOptionList?.PrintOption;
    if (printOptionList) {
        let result = printOptionList.filter(ob => ob['@ClaimTypeID'] == claimTypeId)
        if (result && result.length >= 1)
            return true;
    }
    return false;
}

function mapCrudToUiState(initData: IBCPOCrudAndConfig): IBatchClaimsPrintingOptionsUIState {
     
    if (!initData.PaperClaimsPrintingMaintenanceInfo)
        return defaultBatchClaimsPrintingOptionsState;
    
    var defaultInstFormType = BCPO_DEFAULT_INST_FORM_TYPE;
    var defaultProfFormType = getDefaultProfFormType(initData.CMS2012LiveDate, initData.PaperClaimsPrintingMaintenanceInfo);

    return {
        
        selectedInstFormType: defaultInstFormType,
        selectedProfFormType: defaultProfFormType,
        instPrintCommandSetList: getPrintCommandSetList(defaultInstFormType, initData.PaperClaimsPrintingMaintenanceInfo),
        selectedInstPrintCommandSet: getDefaultPrintCommandSet(defaultInstFormType, initData.PaperClaimsPrintingMaintenanceInfo),
        selectedProfPrintCommandSet: getDefaultPrintCommandSet(defaultProfFormType, initData.PaperClaimsPrintingMaintenanceInfo),
        profPrintCommandSetList: getPrintCommandSetList(defaultProfFormType, initData.PaperClaimsPrintingMaintenanceInfo),

        showADA1999: showForm(BCPO_FORM_TYPE_ADA1999, initData.PaperClaimsPrintingMaintenanceInfo),
        showADA2002: showForm(BCPO_FORM_TYPE_ADA2002, initData.PaperClaimsPrintingMaintenanceInfo),
        showADA2006: showForm(BCPO_FORM_TYPE_ADA2006, initData.PaperClaimsPrintingMaintenanceInfo),
        showADA2012: showForm(BCPO_FORM_TYPE_ADA2012, initData.PaperClaimsPrintingMaintenanceInfo),
        showADA2019: showForm(BCPO_FORM_TYPE_ADA2019, initData.PaperClaimsPrintingMaintenanceInfo),
        printTypeList: getPrintTypes(initData.PaperClaimsPrintingMaintenanceInfo),
        fontFaceList: getFontFaces(initData.PaperClaimsPrintingMaintenanceInfo),
        fontSizeList: getFontSizes(initData.PaperClaimsPrintingMaintenanceInfo),
        selectedPrintType: defaultOption,
        selectedFontFace: defaultOption,
        selectedFontSize: defaultOption,
        printTypeDisabled: false,
        fontDisabled: false,
        dataLoaded: true,
    }
}
export const batchClaimsPrintingOptionsUIReducer: Reducer<IBatchClaimsPrintingOptionsUIState, KnownActions> = (state: IBatchClaimsPrintingOptionsUIState | undefined, action: KnownActions) => {
    let newState = state && { ...state };

    if (state) {
        switch (action.type) {
            case 'INIT_BCPO_OPTIONS':
                {
                    const initData = action.data.uiData;
                    if (initData) return mapCrudToUiState(initData);
                    break;
                }

            case 'SELECT_INST_FORM_TYPE':
                const crudInst = action.data?.masterCrud?.PaperClaimsPrintingMaintenanceInfo;
                if (crudInst) {
                    var formType = action.data.uiData;
                    var profFormType = state.selectedProfFormType;
                        return {
                            ...state,
                            selectedInstFormType: action.data.uiData,
                            instPrintCommandSetList: getPrintCommandSetList(formType, crudInst),
                            selectedInstPrintCommandSet: getDefaultPrintCommandSet(formType, crudInst),
                            selectedProfPrintCommandSet: getDefaultPrintCommandSet(profFormType, crudInst),
                        }
                }
                break;

            case 'SELECT_PROF_FORM_TYPE':
                const crudProf = action.data?.masterCrud?.PaperClaimsPrintingMaintenanceInfo;
                if (crudProf) {
                    var formType = action.data.uiData;
                    var instFormType = state.selectedInstFormType;
                    var isADA = isADAForm(formType);
                    if (isADA)
                        return {
                            ...state,
                            selectedProfFormType: action.data.uiData,
                            profPrintCommandSetList: getPrintCommandSetList(formType, crudProf),
                            selectedProfPrintCommandSet: getDefaultPrintCommandSet(formType, crudProf),
                            selectedInstPrintCommandSet: getDefaultPrintCommandSet(instFormType, crudProf),

                            selectedPrintType: { label: "Default", value: "-1" },
                            selectedFontFace: { label: "Default", value: "-1" },
                            selectedFontSize: { label: "Default", value: "-1" },
                            printTypeDisabled: true,
                            fontDisabled: true,
                        }
                    else
                  return {
                        ...state,
                        selectedProfFormType: action.data.uiData,
                        profPrintCommandSetList: getPrintCommandSetList(formType, crudProf), 
                        selectedProfPrintCommandSet: getDefaultPrintCommandSet(formType, crudProf),
                        selectedInstPrintCommandSet: getDefaultPrintCommandSet(instFormType, crudProf),

                        printTypeDisabled: false,
                        fontDisabled: ((state.selectedPrintType.value === "3"|| state.selectedPrintType.value === "4")?true:false),
                    }
                }
                break;

            case 'SELECT_PRINT_TYPE':
                const crudForPrintType = action.data?.masterCrud?.PaperClaimsPrintingMaintenanceInfo;
                if (crudForPrintType) {
                    var printType = action.data.uiData;
                    return {
                        ...state,
                        selectedPrintType: printType,
                        fontDisabled: (printType.value === "3" || printType.value === "4"),
                    }
                }
                break;

            case 'SELECT_FONT_FACE':
                return {
                    ...state,
                    selectedFontFace: action.data.uiData,
                }
                break;

            case 'SELECT_FONT_SIZE':
                return {
                    ...state,
                    selectedFontSize: action.data.uiData,
                }
                break;

            case 'SELECT_INST_PRINTCOMMANDSET':
                return {
                    ...state,
                    selectedInstPrintCommandSet: action.data.uiData,
                }
                break;

            case 'SELECT_PROF_PRINTCOMMANDSET':
                return {
                    ...state,
                    selectedProfPrintCommandSet: action.data.uiData,
                }
                break;
         }
    }
    return newState || defaultBatchClaimsPrintingOptionsState;
}