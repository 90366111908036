import { combineReducers } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as SubmitParallonFacilitiesStore from './api/SubmitParallonFacilitiesStore'

export type ISubmitParallonFacilitiesState = IApiComponentState<any, SubmitParallonFacilitiesStore.ISubmitParallonFacilitiesUIState>;

export const actionCreators = createApiComponentActions<SubmitParallonFacilitiesStore.ActionCreators>(SubmitParallonFacilitiesStore.actionCreators);
export type ISubmitParallonFacilitiesActions = typeof actionCreators;
export type ISubmitParallonFacilitiesActionProps = IApiComponentActionProps<ISubmitParallonFacilitiesActions>

export const reducers = createApiComponentReducers("submitParallonFacilities", SubmitParallonFacilitiesStore.reducer);
export const reducer = combineReducers<ISubmitParallonFacilitiesState>(reducers);
