import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { IsFieldFilled, IsZipCode } from '@commonResources/validations';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IPayerAddressUIState {
    payerName: string;
    groupName: string;
    address1: string;
    address2: string;
    city: string;
    selectedPayer: ISelectPayerData;
    state: string;
    zipCode: string;
    payerName_payerError?: string;
    groupName_payerError?: string;
    address1_payerError?: string;
    address2_payerError?: string;
    city_payerError?: string;
    state_payerError?: string;
    zipCode_payerError?: string;

}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


interface ISelectPayerData {
    index: number;
    value: string;
    text: string;
};

export const defaultPayer: ISelectPayerData = {
    index: 0,
    value: '',
    text: '',
};

interface IPayerUIFieldUpdate {
    value: string;
}

class PayerFieldErrors {
    public static readonly blankPayerName: string = "Payer Name must not be blank.";
    public static readonly deletedPayerExists: string = "A deleted payer by that payer name already exists.";
    public static readonly blankAddress1: string = "Address Line1 must not be blank.";

    public static readonly blankcity: string = "City must not be blank.";
    public static readonly blankState: string = "State must not be blank.";
    public static readonly blankZipCode: string = "Zip Code must not be blank.";
    public static readonly validZipCode: string = "Zip Code must be numeric.  Please enter it again.";
    public static readonly isZipCode: string = "Zip Code is not the correct length.  Please enter it in one of two formats: 99999 or 999999999";

    public static readonly existingPayerName: string = "This Payer/Group Combination already exists. Please type in a different Payer or Group Name.";
};

export interface ISelectPayer extends ICrudActionData<MCPayerAddress, ISelectPayerData> { }
export interface IModifyPayer extends ICrudActionData<MCPayerAddress, MCPA_Payer> { }
export interface IUpdatePayer extends ICrudActionData<MCPayer, IPayerUIFieldUpdate> { }

export const actionCreators = {
    selectPayer: (selectInfo: ISelectPayer) => createDataAction('SELECT_PAYER', selectInfo),
    addPayer: (updateInfo: IModifyPayer) => createDataAction('ADD_PAYER', updateInfo),
    updatePayer: (updateInfo: IModifyPayer) => createDataAction('UPDATE_PAYER', updateInfo),
    removePayer: (updateInfo: IModifyPayer) => createDataAction('REMOVE_PAYER', updateInfo),
    restorePayer: (updateInfo: IModifyPayer) => createDataAction('RESTORE_PAYER', updateInfo),
    updatePayerName: (payerName: string) => createDataAction('UPDATE_PAYER_NAME', payerName),
    updateGroupName: (groupName: string) => createDataAction('UPDATE_GROUP_NAME', groupName),
    updateAddress1: (address1: string) => createDataAction('UPDATE_ADDRESS_LINE1', address1),
    updateAddress2: (address2: string) => createDataAction('UPDATE_ADDRESS_LINE2', address2),
    updateCity: (city: string) => createDataAction('UPDATE_CITY_NAME', city),
    updateZipCode: (zipcode: string) => createDataAction('UPDATE_ZIP_CODE', zipcode),
    updateState: (stateID: IUpdatePayer) => createDataAction('UPDATE_STATE', stateID),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IPayerAddressUIState = {
    payerName: '',
    groupName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zipCode: '',
    selectedPayer: defaultPayer,
};

export const reducer: Reducer<IPayerAddressUIState, KnownActions> = (state: IPayerAddressUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_PAYER':
                {
                    if (action.data.masterCrud) {
                        
                        let payers:MCPA_Payer[] = [];
                        //  action.data.uiData.text.substring(0, action.data.uiData.text.indexOf("-"));
                        if (!Array.isArray(action.data.masterCrud.AEOtherPayerMaintenanceInfo.Payers.Payer))
                            payers.push(action.data.masterCrud.AEOtherPayerMaintenanceInfo.Payers.Payer);
                        else
                            payers = action.data.masterCrud.AEOtherPayerMaintenanceInfo.Payers.Payer;
                        
                       let payerData =  payers?.find(existingPayer => existingPayer["@ID"] === action.data.uiData.value);

                        return {
                            ...state,
                            selectedPayer: action.data.uiData,
                            payerName: payerData ? payerData["@Name"] || '' : '',
                            groupName: payerData ? payerData["@GroupName"] || '' : '',
                            address1: payerData ? payerData["@Address1"] || '' : '',
                            address2: payerData ? payerData["@Address2"] || '' : '',
                            city: payerData ? payerData["@City"] || '' : '',
                            zipCode: payerData ? payerData["@ZipCode"] || '' : '',
                            state: payerData ? payerData["@State"] || '' : '',

                            payerName_payerError: '',
                            groupName_payerError: '',
                            address1_payerError: '',
                            address2_payerError: '',
                            state_payerError: '',
                            city_payerError: '',
                            zipCode_payerError: '',
                        }
                    }
                }
                break;
            case 'ADD_PAYER':
            case 'UPDATE_PAYER':
                {
                    
                    if (action.data.masterCrud) {
                        let payer: MCPA_Payer = action.data.uiData;
                        
                        let newStateData: IPayerAddressUIState = { ...state };
                        newStateData.payerName_payerError = undefined;
                        newStateData.groupName_payerError = undefined;
                        newStateData.address1_payerError = undefined;
                        newStateData.address2_payerError = undefined;
                        newStateData.city_payerError = undefined;
                        newStateData.state_payerError = undefined;
                        newStateData.zipCode_payerError = undefined; 

                        if (!IsFieldFilled(payer["@Name"])) {
                            
                            newStateData.payerName_payerError = PayerFieldErrors.blankPayerName;
                            return newStateData;
                        }

                        if (!IsFieldFilled(payer["@Address1"])) {
                            newStateData.address1_payerError = PayerFieldErrors.blankAddress1;
                            return newStateData;
                        }
                        if (!IsFieldFilled(payer["@City"])) {
                            newStateData.city_payerError = PayerFieldErrors.blankcity;
                            return newStateData;
                        }

                        if (!IsFieldFilled(payer["@State"])) {
                            newStateData.state_payerError = PayerFieldErrors.blankState;
                            return newStateData;
                        }

                        if (!IsFieldFilled(payer["@ZipCode"])) {
                            newStateData.zipCode_payerError = PayerFieldErrors.blankZipCode;
                            return newStateData;
                        }
                        let i = 0;
                        if (payer["@ZipCode"] && (payer["@ZipCode"].charAt(i) < '0' || payer["@ZipCode"].charAt(i) > '9')) {
                            newStateData.zipCode_payerError = PayerFieldErrors.validZipCode;
                            return newStateData;
                        }

                        if (!IsZipCode(payer["@ZipCode"])) {
                            newStateData.zipCode_payerError = PayerFieldErrors.isZipCode;
                            return newStateData;
                        }
                        if (!Array.isArray(action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer))
                            action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer = [action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer];

                        if ((action.data.masterCrud?.AEOtherPayerMaintenanceInfo.Payers?.Payer?.length || 0) !== 0) {
                            let duplicatePayer = action.data.masterCrud?.AEOtherPayerMaintenanceInfo.Payers?.Payer?.find(xpayer => xpayer["@Name"].toUpperCase() === payer["@Name"].toUpperCase()
                                && xpayer["@GroupName"].toUpperCase() === payer["@GroupName"].toUpperCase() && xpayer["@ID"] !== payer["@ID"]);
                            if (duplicatePayer) {
                                newStateData.payerName_payerError = PayerFieldErrors.existingPayerName;
                                return newStateData;
                            }
                        }


                        if (!(newStateData.payerName_payerError || newStateData.groupName_payerError || newStateData.address1_payerError ||
                            newStateData.address2_payerError || newStateData.city_payerError || newStateData.zipCode_payerError)) {

                            let newIndex = state.selectedPayer.index;
                            if (action.type === 'ADD_PAYER') {
                                if ((action.data.masterCrud?.AEOtherPayerMaintenanceInfo.Payers.Payer||[]).length === 0)
                                    action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer = [];
                               
                                if (!Array.isArray(action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer))
                                    action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer = [action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer];
                                
                                action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer.push(payer);
                                newIndex = action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer.length;
                                newStateData.selectedPayer = {
                                    index: newIndex,
                                    value: payer["@ID"],
                                    text: payer["@Name"]
                                }; 
                                return newStateData;
                            } else {
                                newIndex = state.selectedPayer.index;
                                
                                let payersData:any[] = [];
                                if (!Array.isArray(action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer))
                                    payersData.push(action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer);
                                else
                                    payersData = action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer;
                                let selectedPayer = payersData.find(xpayer => xpayer["@ID"] === payer["@ID"]);
                                if (selectedPayer) {
                                    selectedPayer["@Name"] = payer["@Name"];
                                    selectedPayer["@GroupName"] = payer["@GroupName"];
                                    selectedPayer["@Address1"] = payer["@Address1"];
                                    selectedPayer["@Address2"] = payer["@Address2"];
                                    selectedPayer["@City"] = payer["@City"];
                                    selectedPayer["@ZipCode"] = payer["@ZipCode"];
                                    selectedPayer["@State"] = payer["@State"];

                                    newStateData.selectedPayer = {
                                        index: newIndex,
                                        value: payer["@ID"],
                                        text: payer["@Name"]
                                    };

                                    newStateData.payerName = payer["@Name"];
                                    newStateData.groupName = payer["@GroupName"];
                                    newStateData.address1 = payer["@Address1"];
                                    newStateData.address2 = payer["@Address2"];
                                    newStateData.city = payer["@City"];
                                    newStateData.state = payer["@State"];
                                    newStateData.zipCode = payer["@ZipCode"];
                                    return newStateData;
                                }
                            }
                        }
                    }
                }
                break;

            case 'REMOVE_PAYER':
                {
                    if (action.data.masterCrud) {

                        let selectedListPayer: ISelectPayerData = state.selectedPayer;
                        let newIndex: number = selectedListPayer.index;
                        
                       // let newCrudIndex: number | undefined = undefined;
                        if (!Array.isArray(action.data.masterCrud.AEOtherPayerMaintenanceInfo.Payers.Payer))
                            action.data.masterCrud.AEOtherPayerMaintenanceInfo.Payers.Payer = [];
                        else
                        action.data.masterCrud.AEOtherPayerMaintenanceInfo.Payers.Payer.splice(newIndex - 1, 1);

                        let length: number = action.data.masterCrud.AEOtherPayerMaintenanceInfo.Payers.Payer.length+1;

                        let newCrudIndex: number = newIndex  === length  ? length - 1 : newIndex === 1 ? 0 : newIndex ;
                         
                        return {
                            ...state,
                            selectedPayer: {
                                index: newCrudIndex,
                                value: newCrudIndex ? action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer[newCrudIndex-1]["@ID"] : '',
                                text: newCrudIndex ? action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer[newCrudIndex-1]["@Name"] : '',
                            },
                            payerName: newCrudIndex ? action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer[newCrudIndex-1]["@Name"] : '',
                            groupName: newCrudIndex ? action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer[newCrudIndex-1]["@GroupName"] : '',
                            address1: newCrudIndex ? action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer[newCrudIndex-1]["@Address1"] : '',
                            address2: newCrudIndex ? action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer[newCrudIndex-1]["@Address2"] : '',
                            city: newCrudIndex ? action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer[newCrudIndex-1]["@City"] : '',
                            zipCode: newCrudIndex ? action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer[newCrudIndex-1]["@ZipCode"] : '',
                            state: newCrudIndex ? action.data.masterCrud!.AEOtherPayerMaintenanceInfo.Payers.Payer[newCrudIndex-1]["@State"] : '',
                        };
                    }

                }
                break;
            case 'UPDATE_PAYER_NAME':
                {
                    if (state.payerName !== action.data) {
                        return {
                            ...state,
                            payerName: action.data,
                        }
                    }
                }
                break;
            case 'UPDATE_GROUP_NAME':
                {

                    return {
                        ...state,
                        groupName: action.data,
                    }

                }
                break;

            case 'UPDATE_ADDRESS_LINE1':
                {

                    return {
                        ...state,
                        address1: action.data,
                    }

                }
                break;
            case 'UPDATE_ADDRESS_LINE2':
                {

                    return {
                        ...state,
                        address2: action.data,
                    }

                }
                break;
            case 'UPDATE_CITY_NAME':
                {
                    return {
                        ...state,
                        city: action.data,
                    }

                }
                break;
            case 'UPDATE_STATE':
                { 
                    return {
                        ...state,
                        ...state.selectedPayer,
                        state: action.data.uiData.value,
                    }
                }
                break;

            case 'UPDATE_ZIP_CODE':
                {
                    return {
                        ...state,
                        zipCode: action.data,
                    }
                }
                break;


            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                // const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}