import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as AcceptRemitsUI from './ui/AcceptRemitsUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateObject, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IAcceptRemitsState = ICrudComponentState<MCARMRemit, AcceptRemitsUI.IAcceptRemitsUIState>;
export const actionCreators = createCrudComponentActions<AcceptRemitsUI.ActionCreators>(AcceptRemitsUI.actionCreators);
export type IAcceptRemitsActions = typeof actionCreators;
export type IAcceptRemitsActionProps = ICrudComponentActionProps<IAcceptRemitsActions>
export const reducers = createCrudComponentReducers(CrudTypes.mctiAcceptRemits, AcceptRemitsUI.reducer);
export const reducer = combineReducers<IAcceptRemitsState>(reducers);