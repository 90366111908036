import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import styled from 'styled-components';

// commonResources
import { URLs } from '@commonDevResources/constants';
import { pageLeave } from '@commonResources/userModified';

// common
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { Input } from '@common/UICLWrappers/ARMInput';
import AssuranceMenu from '../../common/AssuranceMenu';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';

// store includes
import { ApplicationState } from '@store/index';
import { ISpinOffState, ISpinOffActionProps, actionCreators, validationCallback } from '@store/SpinOff';
import CrudTypes from '../../../commonResources/CrudTypes';
import { SelectComponent } from '../../common/SelectComponent';
import { ContentRowWrapper, ContentColumnWrapper } from '../../Administration/FileType/FileType';
import { AdjacentLabel } from '../../common/AdjacentLabel';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    #ClaimNoteText {
        width: 900px;
        height: 30px;
    }
`;

export const SelectList = styled.div`
    padding: 5px;
`;

const GridWrapper = styled.div`
    .empty-row{
        background-color:white;
    }

    thead tr th{
        background-color:#9BA1A9;
        height:20px;
    }

    table tbody tr > td{
        height:20px;
    }

    table tbody tr td div{
        min-height:1px;
    }
`;

const InputContainer = styled.div`
    padding:7px;
`;

/////////////////////////////////////////////////////////////////////////////////////////////

interface ISpinOffComponentProps {
    canEdit: boolean,
    canView: boolean
}

interface IComponentState {
    cancelLeave: boolean;
    selectedNoteName: string,
    descriptionText: string,
    IsFromPageLoad: boolean
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    selectedNoteName: '',
    descriptionText: '',
    IsFromPageLoad: true,
};

type IOwnProps = ISpinOffComponentProps & RouteComponentProps<{}>;
type ISpinOffProps = IMergeApiComponentProps<ISpinOffState, ISpinOffActionProps, IOwnProps>;

/////////////////////////////////////////////////////////////////////////////////////////////

export class SpinOff extends React.Component<ISpinOffProps, IComponentState> {

    static defaultProps: ISpinOffComponentProps = {
        canEdit: false,
        canView: false,
    };

    constructor(props: ISpinOffProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        pageLeave();
        this.setBusy(true);
        this.props.action.api.loadData(URLs.api + '/api/crud/' + CrudTypes.mctiSpinOffFormMap, validationCallback);
    }

    public componentWillUnmount() {
        pageLeave();
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        let fromTypeRecords: any = [];
        let toTypeRecords: any = [];
        var spinOffData: any;
        spinOffData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];
        if (this.props.dataStore.ui.FromFormId === "") {
            spinOffData?.SpinOffSelectionInfo?.SpinOffFormMaps?.SpinOffFormMap?.map((item: any) => {
                let fromType = spinOffData?.SpinOffSelectionInfo?.FormTypeList?.FormType.find((ob: any) => ob["@ID"] === item["@FromFormID"]);
                if (fromType !== undefined && fromType["@Used"] === "Y") {
                    let dataAlreadyExists = [];
                    dataAlreadyExists = fromTypeRecords.filter((type: any) => {
                        return type["@ID"] === fromType["@ID"];
                    })
                    if (dataAlreadyExists.length === 0)
                        fromTypeRecords.push({ "@ID": fromType["@ID"], "@Name": fromType["@Name"] });
                }
            });
            if (fromTypeRecords.length > 0)
                toTypeRecords = this.getTypeRecords(fromTypeRecords[0]["@ID"])
        }
        var delim = '\t';
        var spinOffData: any;
        spinOffData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];
        let fromType = this.props.dataStore.ui.FromFormId !== "" ? this.props.dataStore.ui.FromFormId : fromTypeRecords[0]["@ID"];
        let toType = this.props.dataStore.ui.ToFormId !== "" ? this.props.dataStore.ui.ToFormId : toTypeRecords[0]["@ID"];
        if (spinOffData !== undefined) {
            let fromFormType = spinOffData?.SpinOffSelectionInfo?.FormTypeList?.FormType?.find((ob: any) => ob["@ID"] === fromType)?.["@Name"];
            let toFormType = spinOffData?.SpinOffSelectionInfo?.FormTypeList?.FormType?.find((ob: any) => ob["@ID"] === toType)?.["@Name"];
            var parameterValue = fromType + delim + toType + delim + fromFormType + delim + toFormType;
            this.props.history.push('/Claims/Filter/SpinOff', { parameter: parameterValue });
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push('/LandingPage');
    }

    public onSelectFormFormId(val: string) {
        this.props.action.ui.selectFormType({
            apiData: this.props.dataStore.api.data,
            uiData: {
                Id: val
            }
        });
        var spinOffData: any;
        let toTypeId = "";
        spinOffData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];
        if (spinOffData !== undefined) {
            spinOffData?.SpinOffSelectionInfo?.SpinOffFormMaps?.SpinOffFormMap?.map((item: any) => {
                if (item["@FromFormID"] === val) {
                    var spinOffData: any;
                    let toTypeRecords: any = [];
                    spinOffData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];
                    if (spinOffData !== undefined) {
                        spinOffData?.SpinOffSelectionInfo?.SpinOffFormMaps?.SpinOffFormMap?.map((item: any) => {
                            if (item["@FromFormID"] === val) {
                                let toType = spinOffData?.SpinOffSelectionInfo?.FormTypeList?.FormType.find((ob: any) => ob["@ID"] === item["@ToFormID"]);
                                let dataAlreadyExists = [];
                                dataAlreadyExists = toTypeRecords.filter((type: any) => {
                                    return type["@ID"] === toType["@ID"];
                                })
                                if (dataAlreadyExists.length === 0)
                                    toTypeRecords.push({ "@ID": toType["@ID"], "@Name": toType["@Name"] });
                            }
                        });
                    }
                    if (toTypeRecords.length > 0) {
                        this.props.action.ui.selectToType({
                            apiData: this.props.dataStore.api.data,
                            uiData: {
                                Id: toTypeRecords[0]["@ID"]
                            }
                        });
                        toTypeId = toTypeRecords[0]["@ID"];
                    }
                }
            });
            spinOffData?.SpinOffSelectionInfo?.SpinOffFormMaps?.SpinOffFormMap?.map((item: any) => {
                if (item["@FromFormID"] === val && item["@ToFormID"] === toTypeId) {
                    this.props.action.ui.selectPostFunc({
                        apiData: this.props.dataStore.api.data,
                        uiData: {
                            Name: item["@PostFunc"]
                        }
                    });
                }
            });
        }
    }

    public onSelectFormFormIdOnLoad(val: string) {

        if (this.props.dataStore.ui.FromFormId === '') {
            this.props.action.ui.selectFormType({
                apiData: this.props.dataStore.api.data,
                uiData: {
                    Id: val
                }
            });
            var spinOffData: any;
            spinOffData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];
            if (spinOffData !== undefined) {
                spinOffData?.SpinOffSelectionInfo?.SpinOffFormMaps?.SpinOffFormMap?.map((item: any) => {
                    if (item["@FromFormID"] === val) {
                        this.props.action.ui.selectPostFunc({
                            apiData: this.props.dataStore.api.data,
                            uiData: {
                                Name: item["@PostFunc"]
                            }
                        });
                    }
                });
            }
        }

    }

    public onSelectToFormId(val: string, fromId: string) {
        this.props.action.ui.selectToType({
            apiData: this.props.dataStore.api.data,
            uiData: {
                Id: val
            }
        });
        var spinOffData: any;
        spinOffData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];
        spinOffData?.SpinOffSelectionInfo?.SpinOffFormMaps?.SpinOffFormMap?.map((item: any) => {
            if (item["@FromFormID"] === fromId && item["@ToFormID"] === val) {
                this.props.action.ui.selectPostFunc({
                    apiData: this.props.dataStore.api.data,
                    uiData: {
                        Name: item["@PostFunc"]
                    }
                });
            }
        });
    }

    public getTypeRecords(value: string | undefined) {
        var spinOffData: any;
        let toTypeRecords: any = [];
        spinOffData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];
        if (spinOffData !== undefined) {
            spinOffData?.SpinOffSelectionInfo?.SpinOffFormMaps?.SpinOffFormMap?.map((item: any) => {

                if (item["@FromFormID"] === value) {
                    let toType = spinOffData?.SpinOffSelectionInfo?.FormTypeList?.FormType.find((ob: any) => ob["@ID"] === item["@ToFormID"]);
                    let dataAlreadyExists = [];
                    dataAlreadyExists = toTypeRecords.filter((type: any) => {
                        return type["@ID"] === toType["@ID"];
                    })
                    if (dataAlreadyExists.length === 0)
                        toTypeRecords.push({ "@ID": toType["@ID"], "@Name": toType["@Name"] });
                }
            });
        }
        return toTypeRecords;
    }

    public setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    public selectPostFunction(fromId: string, toId: string) {
        var spinOffData: any;
        let toTypeId = "";
        spinOffData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];
        if (spinOffData !== undefined) {
            spinOffData?.SpinOffSelectionInfo?.SpinOffFormMaps?.SpinOffFormMap?.map((item: any) => {
                if (item["@FromFormID"] === fromId && item["@ToFormID"] === toId) {
                    toTypeId = item["@PostFunc"];
                }
            });
        }
        return toTypeId;
    }

    public render() {
        var instruction = <React.Fragment>Select a source form (From) and a target form (To).</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        var spinOffData: any;
        spinOffData = this.props.dataStore.api.data ? this.props.dataStore.api.data : [];

        let fromTypeRecords: any = [];
        let toTypeRecords: any = [];

        if (spinOffData !== undefined) {
            spinOffData?.SpinOffSelectionInfo?.SpinOffFormMaps?.SpinOffFormMap?.map((item: any) => {
                let fromType = spinOffData?.SpinOffSelectionInfo?.FormTypeList?.FormType.find((ob: any) => ob["@ID"] === item["@FromFormID"]);
                if (fromType !== undefined && fromType["@Used"] === "Y") {
                    let dataAlreadyExists = [];
                    dataAlreadyExists = fromTypeRecords.filter((type: any) => {
                        return type["@ID"] === fromType["@ID"];
                    })
                    if (dataAlreadyExists.length === 0)
                        fromTypeRecords.push({ "@ID": fromType["@ID"], "@Name": fromType["@Name"] });
                }
            });
            if (fromTypeRecords.length > 0)
                toTypeRecords = this.getTypeRecords(fromTypeRecords[0]["@ID"])
        }
        return (
            <DialogWrapper title="Spin Off" width="300px" instruction={instruction} helpUrl='/Support/Help/HELP_ClaimsSpinOff.htm' buttons={buttons} isBusy={!this.props.dataStore.api.data}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper style={{ marginLeft: "20px" }}>
                    <ContentRowWrapper>
                        <SelectComponent
                            label="From"
                            title='FromType'
                            width='100px'
                            height='75px'
                            selectedValue={this.props.dataStore.ui.FromFormId}
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                this.onSelectFormFormId(e.target.value);
                            }}
                            optionFields={{
                                value: "@ID",
                                text: "@Name"
                            }}
                            records={fromTypeRecords}
                        ></SelectComponent>
                        <SelectComponent
                            label="To"
                            title='ToType'
                            width='100px'
                            height='75px'
                            selectedValue={this.props.dataStore.ui.ToFormId}
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                this.onSelectToFormId(e.target.value, this.props.dataStore.ui.FromFormId !== "" ? this.props.dataStore.ui.FromFormId : fromTypeRecords[0]["@ID"]);
                            }}
                            optionFields={{
                                value: "@ID",
                                text: "@Name"
                            }}
                            records={this.props.dataStore.ui.FromFormId !== "" ? this.getTypeRecords(this.props.dataStore.ui.FromFormId) : (fromTypeRecords.length > 0 ? this.getTypeRecords(fromTypeRecords[0]["@ID"]) : [])}

                        ></SelectComponent>
                    </ContentRowWrapper>
                    <ContentRowWrapper style={{ marginTop: "10px", marginBottom: "5px" }}>
                        <label style={{ fontSize: "11pt", fontWeight: "bold" }}>PostFunc: </label>
                        <label style={{ fontSize: "11pt" }}>{this.props.dataStore.ui.PostFunc !== "" ? this.props.dataStore.ui.PostFunc :
                            (fromTypeRecords.length > 0 && toTypeRecords.length > 0) ? this.selectPostFunction(fromTypeRecords[0]["@ID"], toTypeRecords[0]["@ID"]) : ""}</label>

                    </ContentRowWrapper>
                </ContentWrapper>
            </DialogWrapper>
        );
    }

};

var connectedHoc = connect<ISpinOffState, ISpinOffActionProps, IOwnProps, ISpinOffProps, ApplicationState>(
    createApiMapStateToProps('spinoff'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(SpinOff);


export default withRouter(connectedHoc);