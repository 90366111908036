import { Reducer, Dispatch, AnyAction } from 'redux';
import { ActionTypes, createDataAction, createAction, createApiStatusAction } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { MasterCrudState, actionCreators as masterCrudCreators } from '../MasterCrud';
import { ComplexApiAction, IThunkResult, IThunkApiAction } from '@scripts/util/ThunkHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { now } from 'moment';
import { SupplementalEditsMaintenance } from '../../components/Administration/SupplementalEditsMaintenance/SupplementalEditsMaintenance';
import { forceToArray } from '../../commonResources/utilityDataParsers';
// -----------------
// STATE - This defines the type of data maintained in the Redux store.
// Name format should be "{Page Name}UiState"
export interface SupplementalEditsMaintenanceUIState {
    nameText: string,
    shortDescText: string,
    longDescText: string,
    searchButton: boolean,
    clearButton: boolean,
    selectGrid: boolean,
    description: string,
    selectMultiple: string,
    DVARName: string,
    Expression: string,
    input: string,
    supplementEditCriteria: [],
    criteriaFieldList: MCCriteriaField[],
    expressionList: MCExpression[],
    criteriaList: MCCriteria[],
    supplementEditCriteriaId: string,
    addButton: boolean,
    removeButton: boolean,
    okButton: boolean,
    cancelButton: boolean,
    edits: EditRow[],
    loading: boolean,
    selectedEdit: EditRow | undefined,
    supplementalEditList: MCSupplementalEditListDetail[],

}


export interface SeToggle {
    altTextForCenterToggle: string,
    domID: string,
    initialValue: boolean,
    leftLabel: string,
    onToggle: Function,
    rightLabel: string,
}

export interface EditRow extends MCSupplementalEdit {
    EnabledToggle: SeToggle,
    RequiredToggle: SeToggle
}

export interface SeSearchParam {
    Name: string,
    Value: string,
    Type?: string,
}

export interface SeSearchRequest {
    Param: SeSearchParam[],
}

//DEFAULTS
export const defaultState: SupplementalEditsMaintenanceUIState = {
    nameText: "",
    shortDescText: "",
    longDescText: "",
    searchButton: false,
    clearButton: false,
    selectGrid: false,
    description: "",
    selectMultiple: "",
    DVARName: "",
    Expression: "",
    input: "",
    supplementEditCriteria: [],
    criteriaFieldList: [],
    expressionList: [],
    criteriaList: [],
    supplementEditCriteriaId: "0",
    addButton: false,
    removeButton: false,
    okButton: false,
    cancelButton: false,
    edits: [],
    loading: false,
    selectedEdit: undefined,
    supplementalEditList: []
};

export interface SupplementEditCriteria {
    id: string,
    value: string
}

export const defaultStateSupplementEditCriteria: SupplementEditCriteria = {
    id: "",
    value: ""
}


//ACTION DEFINITIONS
//export interface MyDataAction extends ICrudActionData<MCStarterTemplateCrud, StarterTemplateUiState> {
//}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// Note - Prefix all actions with a unique abbreviation of your page
export const actionCreators = {
    // Page validation action. Stick the BL logic in here to make the UI a little cleaner and slimmer.
    createDataAction: (actionStringParam: string) =>
        createDataAction('ES_MY_DATA_ACTION', { stringParam2: actionStringParam }),
    updateSelectedEdit: (editName: string) =>
        createDataAction('ES_UPDATE_SELECTED_EDIT', editName),
    clearSearchFields: () => createAction('ES_CLEAR_SEARCH_FIELDS'),
    clearAllData: () => createAction('ES_CLEAR_ALL_DATA'),
    updateRows: (crud: SupplementalEditMaintenanceInfo) => createDataAction('SEM_UPDATE_ROWS', crud),
    updateEdits: (crud: EditRow[]) => createDataAction('UPDATE_EDITS', crud),
    updateDVARName: (crud: string) => createDataAction('UPDATE_DVAR_NAME', crud),
    updateExpression: (crud: string) => createDataAction('UPDATE_EXPRESSION', crud),
    updateInput: (crud: string) => createDataAction('UPDATE_INPUT', crud),
    updateSupplementEditCriteriaId: (crud: string) => createDataAction('UPDATE_SUPPLEMENT_EDIT_CRITERIA_ID', crud),
    addSupplementsEditCriteria: (crud: SupplementEditCriteria | never) => createDataAction('ADD_SUPPLEMENT_EDIT_CRITERIA', crud),
    updateSupplementsEditCriteria: (crud: SupplementEditCriteria | never) => createDataAction('UPDATE_SUPPLEMENT_EDIT_CRITERIA', crud),
    removeSupplementsEditCriteria: (crud: SupplementEditCriteria | never) => createDataAction('REMOVE_SUPPLEMENT_EDIT_CRITERIA', crud),
    enableSupplementEdit: (crud: MCSupplementalEditListDetail | never) => createDataAction('ENABLE_SUPPLEMENT_EDIT', crud),
    enableRequiredData: (crud: MCSupplementalEditListDetail | never) => createDataAction('ENABLE_REQUIRED_DATA', crud),
    updateLoadingFlag: (newFlag: boolean) => createDataAction('SEM_UPDATE_LOADING_FLAG', newFlag),
    updateDropdowns: () => createAction('SEM_UPDATE_DROPDOWN'),
    updateNameText: (crud: string) => createDataAction('UPDATE_NAME_TEXT', crud),
    updateShortDescText: (crud: string) => createDataAction('UPDATE_SHORT_DESC_TEXT', crud),
    updateLongDescText: (crud: string) => createDataAction('UPDATE_LONG_DESC_TEXT', crud),
    clearSupplementsEditCriteria: (crud: string) => createDataAction('CLEAR_SUPPLEMENT_EDIT_CRITERIA', crud),
    searchAndUpdateRows: (searchRequest: SeSearchRequest) =>
        ComplexApiAction
            .fromAction(
                masterCrudCreators.search({ crudId: CrudTypes.mctiSupplementalEdits, data: JSON.stringify(searchRequest) }))
            .addThunk(
                (dispatch: Dispatch<AnyAction>, actionResult: IThunkApiAction<'SEARCH_CRUD', SupplementalEditMaintenanceInfo>)
                    : IThunkResult<void> => {
                    dispatch(actionCreators.updateLoadingFlag(true));
                    // Dispatch a non-thunk version of the result so that MasterCrud reducer can handle it properly
                    //dispatch(createApiStatusAction(actionResult, actionResult.status, actionResult.responseData));
                    // Get the list of supplemental edits if it exists; undefined otherwise.
                    const edits: MCSupplementalEdit[] | undefined = actionResult
                        ?.responseData;
                    // If everything looks good, dispatch an action to update the rows and return.
                    if (actionResult.status.status === 'SUCCESS' && edits) {
                        dispatch(actionCreators.updateRows(actionResult.responseData));
                        dispatch(actionCreators.updateLoadingFlag(false));
                        return { success: true };
                    }
                    // If something is incorrect, log the info here.
                    // TODO - Remove this before committing to QA - WMR.
                    else {
                        dispatch(actionCreators.updateLoadingFlag(false));
                        return { success: false };
                    }

                })
            .finish(),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<SupplementalEditsMaintenanceUIState, KnownActions> =
    (state: SupplementalEditsMaintenanceUIState | undefined, action: KnownActions) => {

        if (state != undefined) {
            const newState: SupplementalEditsMaintenanceUIState = {
                ...state,
            };

            switch (action.type) {
                case "ES_CLEAR_SEARCH_FIELDS":
                    newState.nameText = "";
                    newState.longDescText = "";
                    newState.shortDescText = "";
                    return newState;

                case "ES_CLEAR_ALL_DATA":
                    newState.supplementalEditList = [];
                    newState.criteriaList = [];
                    newState.edits = [];
                    newState.criteriaFieldList = [];
                    newState.DVARName = "";
                    newState.Expression = "";
                    newState.description = "";
                    newState.supplementEditCriteriaId = "0";
                    return newState;

                case "SEM_UPDATE_ROWS":
                    if (action?.data?.SupplementalEditMaintenanceInfo?.CriteriaFieldList?.CriteriaField) {
                        newState.criteriaFieldList = action.data.SupplementalEditMaintenanceInfo.CriteriaFieldList.CriteriaField;
                        newState.expressionList = action.data.SupplementalEditMaintenanceInfo.ExpressionList.Expression;
                        newState.criteriaList = action.data.SupplementalEditMaintenanceInfo?.CriteriaList?.Criteria !== undefined ? action.data.SupplementalEditMaintenanceInfo.CriteriaList.Criteria : [];

                    }

                    if (action?.data?.SupplementalEditMaintenanceInfo?.SupplementalEditList?.SupplementalEdit) {
                        if (!Array.isArray(action.data.SupplementalEditMaintenanceInfo.SupplementalEditList.SupplementalEdit)) {
                            let dataSupplementEdit: MCSupplementalEdit[] = [];
                            let supplementEdit = action.data.SupplementalEditMaintenanceInfo.SupplementalEditList.SupplementalEdit;
                            let object = {
                                '@ID': supplementEdit['@ID'],
                                '@EditName': supplementEdit['@EditName'],
                                '@Description': supplementEdit['@Description'],
                                '@LongDescription': supplementEdit['@LongDescription'],
                                '@RuleEnabled': supplementEdit['@RuleEnabled'],
                                '@DRLClientRulesInx': supplementEdit['@DRLClientRulesInx'],
                                '@RuleRequired': supplementEdit['@RuleRequired']
                            }
                            dataSupplementEdit.push(object);
                            action.data.SupplementalEditMaintenanceInfo.SupplementalEditList.SupplementalEdit = dataSupplementEdit;
                        }
                        newState.edits = action.data.SupplementalEditMaintenanceInfo.SupplementalEditList.SupplementalEdit.map(
                            edit => {
                                // For Rule Enabled - disabled is 0, enabled is 1.
                                const enabled: boolean = (edit['@RuleEnabled'] ?? "0") === "1";
                                // For Rule Required - disabled is -1, enabled is 0.
                                const required: boolean = edit["@RuleRequired"] === "-1" ? true : false;
                                return {
                                    EnabledToggle: {
                                        altTextForCenterToggle: `${edit['@ID']}-${edit['@EditName']}-Enable-Rule-Toggle`,
                                        domID: `${edit['@EditName']}-enable-toggle`,
                                        initialValue: enabled,
                                        leftLabel: "",
                                        onToggle: () => enabled,
                                        rightLabel: "",
                                    },
                                    RequiredToggle: {
                                        altTextForCenterToggle: `${edit['@ID']}-${edit['@EditName']}-Require-Rule-Toggle`,
                                        domID: `${edit['@EditName']}-require-toggle`,
                                        initialValue: required,
                                        leftLabel: "",
                                        onToggle: () => required,
                                        rightLabel: "",
                                    },
                                    updatedEdit: {
                                        value: edit['@EditName'],
                                        toolTip: edit['@EditName'],
                                        name: "Name",
                                        id: edit["@EditName"]
                                    },

                                    updatedDesc: {
                                        value: edit['@Description'],
                                        toolTip: edit['@LongDescription'],
                                        name: "Description",
                                        id: edit["@EditName"]
                                    },
                                    "@RuleRequired": "@RuleRequired" in edit ? edit["@RuleRequired"] : "0",
                                    "@DRLClientRulesInx": "@DRLClientRulesInx" in edit ? edit["@DRLClientRulesInx"] : "0",


                                    ...edit,
                                }
                            });
                    }
                    else {
                        newState.edits = [];
                    }
                    return newState;

                case 'SEM_UPDATE_LOADING_FLAG':
                    
                    newState.loading = action.data;
                    return newState;

                case 'ES_UPDATE_SELECTED_EDIT':
                    let newStateSelectedEdit = { ...state };
                    if (newStateSelectedEdit?.criteriaList) {
                        let criteriaListData: any = [];
                        if (!Array.isArray(newStateSelectedEdit.criteriaList)) {
                            criteriaListData.push(newStateSelectedEdit.criteriaList);
                        }
                        else {
                            newStateSelectedEdit.criteriaList.map((itemData) => {
                                criteriaListData.push(itemData);
                            })
                        }
                        let editsValue = newStateSelectedEdit.edits.find((obj) => {
                            return obj["@EditName"] === action.data;
                        })
                        let supplementalEditCriteria: any = [];
                        if (editsValue !== undefined && editsValue !== null) {
                            let rulesReferenceInx = "@ID" in editsValue ? editsValue["@ID"] : "0";
                            let clientRulesInx = "@DRLClientRulesInx" in editsValue ? editsValue["@DRLClientRulesInx"] : "0";
                            if (criteriaListData !== undefined && criteriaListData !== null && criteriaListData.length > 0) {
                                
                                if (clientRulesInx !== "0") {
                                    criteriaListData.forEach((item: any) => {
                                        if (item["@DRLRulesReferenceInx"] === rulesReferenceInx && item["@DRLClientRulesInx"] === clientRulesInx && !item["@Delete"]) {
                                            let dvarName = newState.criteriaFieldList.find((obj) => {
                                                return obj["@DVARName"] === item["@FieldDVARName"];
                                            })
                                            let objectValue = {
                                                id: item["@DRLClientRulesInx"] + "-" + item["@DRLRulesReferenceInx"] + "-" + (dvarName !== undefined && dvarName !== null ? dvarName["@FieldName"] : "") + "-" + item["@Expression"] + "-" + item["@CriteriaData"],
                                                value: (dvarName !== undefined && dvarName !== null ? dvarName["@FieldName"] : "") + "-" + item["@Expression"] + "-" + item["@CriteriaData"],
                                            }
                                            supplementalEditCriteria.push(objectValue);
                                        }
                                    });
                                }
                                else {
                                    criteriaListData.forEach((item: any) => {
                                        let dvarName = newState.criteriaFieldList.find((obj) => {
                                            return obj["@DVARName"] === item["@FieldDVARName"];
                                        })
                                        if (item["@DRLRulesReferenceInx"] === rulesReferenceInx && item["@FieldName"] === dvarName?.["@FieldName"] && !item["@Delete"]) {

                                            let objectValue = {
                                                id: item["@DRLClientRulesInx"] + "-" + item["@DRLRulesReferenceInx"] + "-" + (dvarName !== undefined && dvarName !== null ? dvarName["@FieldName"] : "") + "-" + item["@Expression"] + "-" + item["@CriteriaData"],
                                                value: (dvarName !== undefined && dvarName !== null ? dvarName["@FieldName"] : "") + "-" + item["@Expression"] + "-" + item["@CriteriaData"],
                                            }
                                            supplementalEditCriteria.push(objectValue);
                                        }
                                    });
                                }

                            }

                            newStateSelectedEdit.supplementEditCriteria = supplementalEditCriteria;
                        }
                    }
                    newStateSelectedEdit.selectedEdit = state.edits.find(edit => edit['@EditName'] === action.data);
                    return newStateSelectedEdit;

                case "UPDATE_DVAR_NAME":
                    let newStateDataDVARName = { ...state };
                    newStateDataDVARName.DVARName = action.data;
                    return newStateDataDVARName;
                case "UPDATE_EXPRESSION":
                    let newStateDataExpression = { ...state };
                    newStateDataExpression.Expression = action.data;
                    return newStateDataExpression;
                case "UPDATE_INPUT":
                    let newStateDataInput = { ...state };
                    newStateDataInput.input = action.data;
                    return newStateDataInput;
                case "UPDATE_EDITS":
                    let newStateDataEdits = { ...state };

                    if (action.data.length > 0) {
                        let editsData = action.data.map(
                            (edit) => {
                                // For Rule Enabled - disabled is 0, enabled is 1.
                                const enabled: boolean = (edit['@RuleEnabled'] ?? "0") === "1";
                                // For Rule Required - disabled is -1, enabled is 0.
                                const required: boolean = edit["@RuleRequired"] === "-1" ? true : false;

                                return {
                                    EnabledToggle: {
                                        altTextForCenterToggle: `${edit['@ID']}-${edit['@EditName']}-Enable-Rule-Toggle`,
                                        domID: `${edit['@EditName']}-enable-toggle`,
                                        initialValue: enabled,
                                        leftLabel: "",
                                        onToggle: () => enabled,
                                        rightLabel: "",
                                    },
                                    RequiredToggle: {
                                        altTextForCenterToggle: `${edit['@ID']}-${edit['@EditName']}-Require-Rule-Toggle`,
                                        domID: `${edit['@EditName']}-require-toggle`,
                                        initialValue: required,
                                        leftLabel: "",
                                        onToggle: () => required,
                                        rightLabel: "",
                                    },
                                    updatedEdit: {
                                        value: edit['@EditName'],
                                        toolTip: edit['@EditName'],
                                        name: "Name",
                                        id: edit["@EditName"]
                                    },

                                    updatedDesc: {
                                        value: edit['@Description'],
                                        toolTip: edit['@LongDescription'],
                                        name: "Description",
                                        id: edit["@EditName"]
                                    },
                                    "@RuleRequired": "@RuleRequired" in edit ? edit["@RuleRequired"] : "0",

                                    ...edit,
                                }
                            });

                        newStateDataEdits.edits = editsData;
                    }
                    else {
                        newStateDataEdits.edits = [];
                    }
                    return newStateDataEdits;
                case "UPDATE_NAME_TEXT":
                    let newStateDataNameText = { ...state };
                    newStateDataNameText.nameText = action.data;
                    return newStateDataNameText;
                case "UPDATE_SHORT_DESC_TEXT":
                    let newStateDataShortDescText = { ...state };
                    newStateDataShortDescText.shortDescText = action.data;
                    return newStateDataShortDescText;
                case "UPDATE_LONG_DESC_TEXT":
                    let newStateDataLongDescText = { ...state };
                    newStateDataLongDescText.longDescText = action.data;
                    return newStateDataLongDescText;
                case "UPDATE_SUPPLEMENT_EDIT_CRITERIA_ID":
                    let newStateDataSupplementEditCriteriaId = { ...state };
                    newStateDataSupplementEditCriteriaId.supplementEditCriteriaId = action.data;
                    return newStateDataSupplementEditCriteriaId;
                case "ADD_SUPPLEMENT_EDIT_CRITERIA":
                    let newStateDataSupplementEditCriteria = { ...state };
                    let supplementEditCriteria: any = newStateDataSupplementEditCriteria.supplementEditCriteria;
                    if (newStateDataSupplementEditCriteria.supplementEditCriteria !== null && newStateDataSupplementEditCriteria.supplementEditCriteria !== undefined) {
                        //let alreadyAddedValue = newStateDataSupplementEditCriteria.supplementEditCriteria.filter((obj: any) => {
                        //    return obj.value === action.data.value;
                        //})
                        //if (alreadyAddedValue !== null && alreadyAddedValue !== undefined && alreadyAddedValue.length > 0) {
                        //    //do something
                        //}
                        //else {
                        supplementEditCriteria.push({ id: action.data.id, value: action.data.value });

                    }
                    let actionValueSupplement = action.data.id.split('-');

                    newStateDataSupplementEditCriteria.supplementEditCriteria = supplementEditCriteria;
                    let criteriaList: MCCriteria[] = [];
                    if (!Array.isArray(newStateDataSupplementEditCriteria.criteriaList)) {
                        criteriaList.push(newStateDataSupplementEditCriteria.criteriaList);
                    }
                    else {
                        criteriaList = newStateDataSupplementEditCriteria.criteriaList;
                    }
                    let criteriaFieldDetail = newStateDataSupplementEditCriteria.criteriaFieldList.find((obj) => {
                        return obj["@FieldName"] === actionValueSupplement[2];
                    })
                    let objectValue: MCCriteria = {
                        "@CriteriaData": actionValueSupplement[4],
                        "@DRLClientRulesInx": actionValueSupplement[0],
                        "@DRLRulesReferenceInx": actionValueSupplement[1],
                        "@Expression": actionValueSupplement[3],
                        "@FieldDVARName": criteriaFieldDetail !== undefined ? criteriaFieldDetail?.["@DVARName"] : "",
                        "@FieldName": criteriaFieldDetail !== undefined ? criteriaFieldDetail?.["@FieldName"] : "",
                        "@Add": true,
                        "@Delete": false,
                        "@Update": false
                    }
                    criteriaList.push(objectValue);
                    newStateDataSupplementEditCriteria.criteriaList = criteriaList;
                    newStateDataSupplementEditCriteria.supplementEditCriteriaId = actionValueSupplement[0] + "-" + actionValueSupplement[1] + "-" + actionValueSupplement[2] + "-" + actionValueSupplement[3] + "-" + actionValueSupplement[4];
                    return newStateDataSupplementEditCriteria;
                case "UPDATE_SUPPLEMENT_EDIT_CRITERIA":
                    let newStateDataSupplement = { ...state };
                    let actionId = action.data.id.split('-');
                    let actionValue = action.data.value.split('-');
                    let dvarName = newStateDataSupplement.criteriaFieldList.find((val) => {
                        if (val["@FieldName"] === actionValue[0])
                            return val["@DVARName"];
                    });
                    let criteriaListDetail = forceToArray(newStateDataSupplement.criteriaList);
                    if (criteriaListDetail.length > 0) {
                        criteriaListDetail.forEach((obj) => {
                            if (obj["@DRLClientRulesInx"] === actionId[0] && obj["@DRLRulesReferenceInx"] === actionId[1] && obj["@FieldDVARName"] === dvarName?.["@DVARName"]) {
                                obj["@Expression"] = actionValue[1];
                                obj["@CriteriaData"] = actionValue[2];

                                obj["@FieldDVARName"] = dvarName !== undefined ? dvarName?.["@DVARName"] : "";
                                obj["@Add"] = obj["@DRLClientRulesInx"] === "0" ? true : false;
                                obj["@Delete"] = false;
                                obj["@Update"] = obj["@DRLClientRulesInx"] !== "0" ? true : false;
                                newStateDataSupplement.DVARName = dvarName !== undefined ? dvarName?.["@DVARName"] : "";
                                newStateDataSupplement.Expression = actionValue[1];
                                newStateDataSupplement.input = actionValue[2];
                                let supplementEditCriteria = newStateDataSupplement.supplementEditCriteria;
                                supplementEditCriteria.forEach((editCriteria: any) => {
                                    let actionIdValue = editCriteria.id.split('-');
                                    if (actionIdValue[0] === actionId[0] && actionIdValue[1] === actionId[1] && actionIdValue[2] === actionId[2]) {
                                        editCriteria.id = actionId[0] + "-" + actionId[1] + "-" + (dvarName !== undefined ? dvarName?.["@FieldName"] : "") + "-" + actionValue[1] + "-" + actionValue[2];
                                        editCriteria.value = (dvarName !== undefined ? dvarName?.["@FieldName"] : "") + "-" + actionValue[1] + "-" + actionValue[2];
                                    }
                                })
                                newStateDataSupplement.supplementEditCriteria = supplementEditCriteria;
                                newStateDataSupplement.supplementEditCriteriaId = actionId[0] + "-" + actionId[1] + "-" + (dvarName !== undefined ? dvarName?.["@FieldName"] : "") + "-" + actionValue[1] + "-" + actionValue[2];
                            }
                        });
                        newStateDataSupplement.criteriaList = criteriaListDetail;
                    }
                    return newStateDataSupplement;
                case "REMOVE_SUPPLEMENT_EDIT_CRITERIA":
                    let newStateRemoveSupplementEditCriteria = { ...state };
                    let supplementEditCriteriaValue: any = newStateRemoveSupplementEditCriteria.supplementEditCriteria;
                    let deletedSupplementEditCriteria: any = {};
                    for (let i = 0; i < supplementEditCriteriaValue.length; i++) {
                        if (supplementEditCriteriaValue[i].id === action.data.id && supplementEditCriteriaValue[i].value === action.data.value) {
                            deletedSupplementEditCriteria = supplementEditCriteriaValue[i];
                            supplementEditCriteriaValue.splice(i, 1);

                        }
                    }
                    newStateRemoveSupplementEditCriteria.supplementEditCriteria = supplementEditCriteriaValue;
                    if (supplementEditCriteriaValue.length > 0) {
                        newStateRemoveSupplementEditCriteria.supplementEditCriteriaId = supplementEditCriteriaValue[0].id;
                        let dvarNameValue = newStateRemoveSupplementEditCriteria.criteriaFieldList.find((val) => {
                            if (val["@FieldName"] === supplementEditCriteriaValue[0].id.split('-')[2])
                                return val["@DVARName"];
                        });
                        newStateRemoveSupplementEditCriteria.DVARName = dvarNameValue !== undefined ? dvarNameValue?.["@DVARName"] : "";
                        newStateRemoveSupplementEditCriteria.Expression = supplementEditCriteriaValue[0].id.split('-')[3];
                        newStateRemoveSupplementEditCriteria.input = supplementEditCriteriaValue[0].id.split('-')[4];
                    }
                    else {
                        newStateRemoveSupplementEditCriteria.supplementEditCriteriaId = "0";
                        newStateRemoveSupplementEditCriteria.DVARName = "";
                        newStateRemoveSupplementEditCriteria.Expression = "";
                        newStateRemoveSupplementEditCriteria.input = "";
                    }
                    let criteriaListDetails = forceToArray(newStateRemoveSupplementEditCriteria.criteriaList);
                    if (criteriaListDetails.length > 0) {
                        let dvarNameValueDelete = newStateRemoveSupplementEditCriteria.criteriaFieldList.find((val) => {
                            if (val["@FieldName"] === deletedSupplementEditCriteria.id.split('-')[2])
                                return val["@DVARName"];
                        });
                        criteriaListDetails.forEach((obj, index) => {
                            if (obj["@DRLClientRulesInx"] === deletedSupplementEditCriteria.id.split('-')[0] && obj["@DRLRulesReferenceInx"] === deletedSupplementEditCriteria.id.split('-')[1] && obj["@FieldDVARName"] === dvarNameValueDelete?.["@DVARName"]) {

                                if (obj["@DRLClientRulesInx"] === "0") {
                                    criteriaListDetails.splice(index, 1);
                                }
                                else {
                                    obj["@Add"] = false;
                                    obj["@Update"] = false;
                                    obj["@Delete"] = true;
                                }
                            }
                        });
                        newStateRemoveSupplementEditCriteria.criteriaList = forceToArray(criteriaListDetails);
                    }
                    return newStateRemoveSupplementEditCriteria;
                case "ENABLE_SUPPLEMENT_EDIT":
                    let enableSupplementEdit = { ...state };
                    let isAdded: boolean = false;

                    let supplementEditList = enableSupplementEdit.supplementalEditList;
                    if (supplementEditList !== undefined && supplementEditList.length > 0) {

                        supplementEditList.forEach((item, index) => {
                            if (item["@EditName"] === action.data["@EditName"]) {
                                isAdded = true;
                                item["@RuleEnabled"] = action.data["@RuleEnabled"];
                                if (action.data["@RuleEnabled"] === "0") {
                                    item["@RuleRequired"] = action.data["@RuleRequired"];
                                }
                            }
                        })
                        if (!isAdded) {
                            supplementEditList.push(action.data);
                        }
                    }
                    else {
                        supplementEditList.push(action.data);
                    }

                    enableSupplementEdit.supplementalEditList = supplementEditList;
                    return enableSupplementEdit;
                case "CLEAR_SUPPLEMENT_EDIT_CRITERIA":

                    let clearSupplementEditCriteria = { ...state };
                    let clearSupplementEditCriteriaDetails: any = [];
                    if (!Array.isArray(clearSupplementEditCriteria.criteriaList)) {
                        clearSupplementEditCriteriaDetails.push(clearSupplementEditCriteria.criteriaList);
                    }
                    else {
                        clearSupplementEditCriteriaDetails = clearSupplementEditCriteria.criteriaList;
                    }
                    if (clearSupplementEditCriteriaDetails !== undefined && clearSupplementEditCriteriaDetails !== null && clearSupplementEditCriteriaDetails.length > 0) {
                        clearSupplementEditCriteriaDetails.forEach((obj: any) => {
                            if (obj["@DRLClientRulesInx"] !== "0" && obj["@DRLRulesReferenceInx"] === action.data) {
                                obj["@Delete"] = true;
                            }
                            if (obj["@DRLClientRulesInx"] === "0" && obj["@Add"]) {
                                obj["@Delete"] = true;
                                obj["@Add"] = false;
                            }

                        })
                    }
                    clearSupplementEditCriteria.supplementEditCriteria = [];
                    clearSupplementEditCriteria.criteriaList = clearSupplementEditCriteriaDetails;
                    clearSupplementEditCriteria.supplementEditCriteriaId = "0";
                    clearSupplementEditCriteria.DVARName = "0";
                    clearSupplementEditCriteria.Expression = "0";
                    clearSupplementEditCriteria.input = "";
                    return clearSupplementEditCriteria;

                case "ENABLE_REQUIRED_DATA":
                    let enableRequiredData = { ...state };
                    let isAddedData: boolean = false;

                    let supplementEditListData = enableRequiredData.supplementalEditList;
                    if (supplementEditListData !== undefined && supplementEditListData.length > 0) {

                        supplementEditListData.forEach((item, index) => {
                            if (item["@EditName"] === action.data["@EditName"]) {
                                isAddedData = true;
                                item["@RuleRequired"] = action.data["@RuleRequired"];
                                item["@RuleEnabled"] = action.data["@RuleEnabled"];
                            }
                        })
                        if (!isAddedData) {
                            supplementEditListData.push(action.data);
                        }
                    }
                    else {
                        supplementEditListData.push(action.data);
                    }
                    enableRequiredData.supplementalEditList = supplementEditListData;
                    return enableRequiredData;
                default:
                    return state;
            }
        }

        return defaultState;
    };