import * as React from 'react';
import { URLs } from '@commonResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';

import styled from 'styled-components';
import { Input } from 'ui-core';

import { ContentRowWrapper } from '@common/DialogWrapper';
import { SelectGroupComponent } from '@common/SelectGroupComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';


const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    #SelectGroupComponent{
        height: 30px;
    font-size: 13px;
    padding-left: 6px;
    }
`;

const BrowseButton = styled.label`
    margin-left: 4% !important;
    margin-top: 22% !important;
    padding-top: 7% !important;
    height: 26px;
    outline: none;
    font-size: 11px;
    margin: 0;
    text-align: left;
    background: none;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 600;
    border-radius: 3px;
    padding: 0px 1em;
    max-width: 100%;
    display: inline-block;
    background-color: #37474F;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-family: 'Core Sans C',sans-serif;
    border: 1px solid transparent;
`;

const UploadSiteFileContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    min-height: 180px;
    width: 680px; 
    padding: 10px;


    div.text-input input[type='text']{
        height: 30px;
    }
    div.text-input.disabled{
        opacity:1;
        label{
            color:#37474F;
        }
    }
    #submit-claims-center {
        margin-left: 170px;
    } 
    #target {
         width: 300px;
         margin-right: 18px;
        
    }
    #file-type {
         width: 300px;
         margin-right: 18px;
         margin-top: 5px;
         margin-bottom: 6px;
    }
    #unique-identifier {
         width: 300px;
         margin-bottom: 4px;
    }
    #file-upload {
         width: 300px;
         margin-top: -2px;
         margin-bottom: 4px;
    }
`;

interface IComponentProps {
    canView: boolean,
    filesData:any,
    currentDirectory: string,
    currentDirective: string,
    onFileUploadSave:Function,
    setBusyLoader:Function,
    ncsIsEnabled: boolean,
    fileToCheckin: string,
    clearFileCheckin:Function,
    ncsSkipPrompt:boolean,
    ncsPromptText:string,
    ncsListType: string

};

interface IComponentState {
    error: boolean,
    options: any,
    note: string,
    upload: any,
    files: any,
    cancelLeave: boolean,
    isOpen:boolean,
    isNewFile:any,
    ncsFile:any,
    isOpenOnError:boolean,
    errorMessage:string,
    loader:boolean,
    target:any,
    isFileUploadPopup:boolean
}

export const DEFAULT_STATE: IComponentState = {
    error: false,
    options: [{ label: 'Register File',items:[{ label: '  Add new file to ', value: 'new' }]}, { label: 'Check In File', items: [] }],
    note: '',
    upload: [{ label: 'FILE:', fileName: '', file: new FormData() }],
    files: new FormData(),
    cancelLeave: false,
    isOpen:false,
    isNewFile:'1',
    ncsFile:'1',
    isOpenOnError:false,
    errorMessage:'',
    loader:false,
    target:'new',
    isFileUploadPopup:false
};

export class UploadSiteFile extends React.Component<any, IComponentState> {
    static addItemCount: number = 0;

    static defaultProps: IComponentProps = {
        canView: false,
        filesData:{},
        currentDirectory: "",
        currentDirective: "",
        onFileUploadSave:function() { },
        setBusyLoader:function(){ },
        ncsIsEnabled: false,
        ncsSkipPrompt: false,
        ncsPromptText: "",
        ncsListType: "",
        fileToCheckin: "",
        clearFileCheckin: function () {}
    };

    constructor(props: any) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentWillMount(){
        let files = this.props.filesData.Files;
        let user:any = sessionStorage?.getItem('Username');
        let options = this.state.options;
        let items:any[] =[];

        if (user?.toUpperCase().startsWith('NDC\\')) {
            user = user.substring(4,user.length);
        }
    
        if(files)
            files.forEach((element:any) => {
                if (element?.Owner && element.Owner?.toUpperCase() === user?.toUpperCase() ){
                    items.push({ label: element.FileName, value: element.FileName });
                 }
            });

        options[0]['items'][0]['label'] = '  Add new file to ' + this.props.currentDirectory;
        options[1]['items'] = items;
        this.setState({options:options});
        
    }

    public componentWillUnmount() {
        this.props.clearFileCheckin();
    }

    public componentDidMount() {

         this.setState({
             upload: [{ label: '*File:', fileName: '', file: new FormData() }],
             files: new FormData(),
             target:'new',
             note:'',
             isFileUploadPopup:false
         })
    }

    /* -----------------------------------  */
    /* ------  BUTTON RELATED METHODS ----  */
    /* -----------------------------------  */


    public onToggleModal() {
        const fileLabel: any = []
        this.state.upload.map((item: any) => {
            if (item.fileName !== '') {
                fileLabel.push(item.labelKey)
            }
        })
        let fileurl = URLs.api + '/api/data/UploadBridgeFile?newFile=' + this.state.isNewFile +
            '&directive=' + this.props.currentDirective +
            '&note=' + this.state.note +
            '&applyNCS=' + this.state.ncsFile;
        this.props.setBusyLoader(true);

        fetch(fileurl, {
            method: 'POST',
            headers: {
                'Authorization': `${getRawToken()}`
            },
            body: this.state.files
        }).then((response) => response.json())
        .then((jsonData) => {
            this.props.setBusyLoader(false);
            if (jsonData.ErrorMessage) {
                this.setState({isOpenOnError:true,errorMessage:jsonData.ErrorMessage, isFileUploadPopup:true})
            } else {
                 this.props.onFileUploadSave();
                document.getElementById('site-file-use-tab')?.click();
            }
        });
        }

    public onClearAlert() {
        this.setState({isOpen:false});
    }

    public onClearErrorAlert() {
        this.setState({isOpenOnError:false, errorMessage:''});
        if(this.state.isFileUploadPopup){
            this.props.onFileUploadSave();
            document.getElementById('site-file-use-tab')?.click();
        }
    }

    public onSave() {
        if (this.props.fileToCheckin != "") {
            this.setState({
                isNewFile: '0',
                target: this.props.fileToCheckin,
                isFileUploadPopup: false
            });
        }

        if (this.state.upload[0].fileName === '') {
            this.setState({isOpenOnError:true, errorMessage:'Please select a file to upload and automatically register.'});
            return ;           
        }

        if (this.props.fileToCheckin !== "" && this.state.upload[0].fileName.toLowerCase() !== this.props.fileToCheckin.toLowerCase()) {
            this.setState({ isOpenOnError: true, errorMessage: 'The file you entered must be the same as the target.' });
            return;
        }

        if (this.state.target !== 'new' && this.state.upload[0].fileName.toLowerCase() !== this.state.target.toLowerCase()) {
            this.setState({ isOpenOnError: true, errorMessage: 'The file you entered must be the same as the target.' });
            return;
        }


        if(this.props.ncsIsEnabled) {
            this.setState({isOpen:true});
        } else {
            this.setState({ncsFile:'0'},()=>{
                this.onToggleModal();
            })
        }
        
    }

    public uploadFile(uploadedfile: any, index: any) {
        if(uploadedfile?.target?.files[0]?.size > 5000000){
            this.setState({isOpenOnError:true, errorMessage:'The file size should  not exceed 5 MB.'});
            return ;           
        }
        const uploadData = new FormData()
        uploadData.append('file', uploadedfile.target.files)
        const files = this.state.files;
        files?.delete(`file[${index}]`);
        files.append(`file[${index}]`, uploadedfile.target.files[index])
        let upload = [this.state.upload[index]];
        upload[index].fileName = uploadedfile?.target?.files[index]?.name || '';
        this.setState({ upload: upload, files: files })
    }


    public onToggleMediCalCheckbox(e:any, val:any){
        this.setState({ncsFile:e},()=>{
            this.onToggleModal();
        })    
    }


    /* -----------------------------------  */
    /* -------------  RENDER -------------  */
    /* -----------------------------------  */
    public render() {
        var popupStyles: React.CSSProperties = { alignContent: 'center', justifyContent: 'center', textAlign:'center', color:'red'  };
        var confirmMessageStyle: React.CSSProperties = { alignContent: 'center', justifyContent: 'center', textAlign: 'center', color: 'black' };

        const {
            fileToCheckin
        } = this.props

        return (
                <ContentWrapper id={"content_wrapper_submitclaims"} >
                    <ContentRowWrapper>
                    <UploadSiteFileContent>
                        <React.Fragment>
                                <ContentRowWrapper>
                                    <SelectGroupComponent
                                        size={1}
                                        label='*target:'
                                        width='300px' 
                                        optionGroups={{
                                            label: "value",
                                            items: [{
                                                value: "value",
                                                text: "text",
                                            }]
                                        }}
                                        onSelect={(e) => {
                                            this.setState({isNewFile : e.target.value === 'new' ? '1':'0',target:e.target.value})
                                        }}
                                        records={this.state.options}
                                        selectedValue={fileToCheckin !== "" ? fileToCheckin : this.state.options[0]['items']['value']}
                                    />
                                </ContentRowWrapper>

                                {this.state.upload.map((item: any, i: any) => {
                                    const id = 'fileupload' + i;
                                    return (
                                        <ContentRowWrapper>
                                            <Input
                                                domID="file-upload"
                                                className="text-input"
                                                label={item.label}
                                                maxLength={80}
                                                initialValue={item.fileName}
                                                onChange={(e: any) => {
                                                    const upload = this.state.upload
                                                    upload[i].fileName = e.target.value
                                                    this.setState({
                                                        upload: upload
                                                    })
                                                }}
                                                disabled={true}
                                                style={{ width: 650 }}
                                            />
                                            <div id={i} style={{ marginTop: '3px' }}>
                                                <BrowseButton id="secondary-claims-ID" htmlFor={id} style={{ backgroundColor: '#EBECED', border: 'solid 1px #9ba1a9', color: '#37474F'}}>Browse</BrowseButton>
                                                <Input id={id} type='file' style={{ display: 'none' }} onChange={(e: any) => this.uploadFile(e, i)} />
                                            </div>
                                        </ContentRowWrapper>
                                    )
                                })}

                                <ContentRowWrapper>
                                    <Input
                                        domID="unique-identifier"
                                        className="text-input"
                                        label="Note:"
                                        maxLength={80}
                                        initialValue={this.state.note}
                                        value={this.state.note}
                                        onChange={(e: any) => {
                                            this.setState({ note: e.target.value })
                                        }}
                                        style={{ width: 450 }}
                                    />
                                </ContentRowWrapper>

                                <ContentRowWrapper style={{ justifyContent: 'center', width: 300 }}>
                                    <div id="save-button-wrapper" style={{ marginTop: '3px' }}>
                                        <BrowseButton id="save-button" onClick={(e: React.MouseEvent<HTMLLabelElement>) => this.onSave()}>SAVE</BrowseButton>
                                    </div>
                                </ContentRowWrapper>
                            </React.Fragment>
                    </UploadSiteFileContent>
                    </ContentRowWrapper>
                    
                    <ModalConfirmation
                        isOpen={this.state.isOpen}
                        alertMode={false}
                        okText='Yes'
                        cancelText='No'
                        showDefaultClose={true}
                        formattedMessage={(
                            <div style={popupStyles}>
                                <p>Warning: This action cannot be undone </p>
                                <p>Before selecting  Yes:</p>
                                <p>Comfirm that files on other sites are not currently locked by another user.
                                </p>
                                <p>Confirm that this files is appropriate to share across sites.
                                </p>
                                <br/>
                                <p>Only select Yes if all enterprise sites require the identical file.</p> 
                                <p style={confirmMessageStyle}>{this.props.ncsPromptText} </p>
                            </div>)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onToggleMediCalCheckbox('1', "")}
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onToggleMediCalCheckbox('0', "")}
                        onModalToggle={() => this.onClearAlert()}>
                    </ModalConfirmation>
                    <ModalConfirmation
                        isOpen={this.state.isOpenOnError}
                        alertMode={true}
                        message={this.state.errorMessage}
                        onModalToggle={() => this.onClearErrorAlert()}
                    />

                </ContentWrapper>
        );
    }
};

export default UploadSiteFile;
