import * as React from 'react';
import styled, { css } from 'styled-components';
import errorIcon from '../../content/images/errorIcon.gif';

export const ARMComponentErrorContainer = styled.div.attrs(() => {
})`
    background-color: #FDF6E3;
    width: 100%;
    height: 100%;
`;

export const ARMComponentErrorContent = styled.div.attrs(() => {
})`
    display: inline-block;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const ARMComponentErrorIcon = styled.div.attrs(() => {
})`
    width: 100%;
`;

export const ARMComponentErrorText = styled.div.attrs(() => {
})`
    font-size: 13px;
    color: #d84040;
    font-weight: bold;
`;


export class ARMComponentError extends React.PureComponent<{}>
{
    render() {
        return (
            <ARMComponentErrorContainer>
                <ARMComponentErrorContent>
                    <ARMComponentErrorIcon><img src={errorIcon} /></ARMComponentErrorIcon>
                    <ARMComponentErrorText>Unable to load element</ARMComponentErrorText>
                </ARMComponentErrorContent>
            </ARMComponentErrorContainer>
        );
    }
}
