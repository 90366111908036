import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IAutoSubmitMaintenanceState, IAutoSubmitMaintenanceActions, IAutoSubmitMaintenanceActionProps, actionCreators, validationCallback } from '@store/AutoSubmitMaintenance';
import { ICookies_Config } from '@store/ConfigData';

import { Input, Button, SelectDropdownV2 } from 'ui-core';
import { DialogWrapper, OKCancelButtons, DialogInstruction, CenteredContent, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { SelectComponent } from '../../common/SelectComponent';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { CheckBoxComponent } from '../../common/CheckBox';
import { ARMLabel } from '../../Transactions/Logon/styles/ARMLoginForm';
import { ARMNarrowInput } from '../../common/UICLWrappers/ARMNarrowInput';
import { ARMNarrowCheck } from '../../common/UICLWrappers/ARMNarrowCheckbox';
import { ARMNarrowDropdown } from '../../common/UICLWrappers/ARMNarrowDropdown';

const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

const ContentSectionWrapper = styled.div`
display:flex;
flex : 1 1 auto;
flex-direction: column;
margin-left: -50px;
    label {
       width:200px !important;
       text-align:end;
    } 
    padding-right:30px
`;

export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding: 3px;

`;

const AutoSubmitMaintenanceLeftContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    width: 415px;
   
    #left-dialogfieldset {
        padding-bottom: 4px !important;
        height: 100%;
     }   
    #limit-search-to-label {
        margin-left: 2px;
        margin-bottom: 4px;
    }  
`;

const AutoSubmitMaintenanceRightContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    width: 550px; 
    padding: 10px;
    justify-content: flex-end;
 
    div.text-input input[type='text']{
        height: 30px;
    }
     
    #name {
        width: 300px;
        text-transform: uppercase;  
        padding-left: 5px !important;
    }
    #claim-file-mask {
         width: 300px;
         padding-left: 5px !important;
    }
    #submission {
         width: 300px;
    }
    #file-type {
         width: 300px;
    }
    #unique-identifier {
         width: 300px;
    }
    #content-filemask {
         width: 300px;
    }
    #enable {         
        width: 32px;
    }
    #enable-batching {         
        width: 32px;
    }
    #days-name {         
        outline: 2px solid;
        height: 14px;
        width: 14px;
        opacity: 1 !important;
        min-width: 14px;
        min-height: 14px 
    }
    #from {
         width: 90px;
         margin-right: 18px;
    }
    #checkstatus {
         width: 150x;
         margin-right: 18px;
    }
    #schedule-list {
        margin-bottom: 8px;    
    }
    #new-schedule {
        width: 178px;   
    }      
`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    height: 65px !important;
    align-items: flex-start;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

const SelectButtonsInner = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    padding-top: 10px;
    align-items: flex-end;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    canSecCheck1: boolean,
    title: string
};

interface IComponentState {
    error: boolean,
    errorNameExist: boolean,
    submission: { label: any, value: any },
    fileType: { label: any, value: any },
    isUpdate: boolean,
    cancelLeave: boolean,
    selectedItem: any,
    Items: any,
    selectedSchedulerItem: any,
    removeItem: boolean,
    removeScheduleItem: boolean,
    scheduleIdExist: boolean,
    maxID: any
    maxSchedulerID: any,
    bridgeModal: boolean
}

export const DEFAULT_STATE: IComponentState = {
    error: false,
    errorNameExist: false,
    submission: { label: 'Single', value: 'Single' },
    fileType: { label: '- Select File Type -', value: '' },
    isUpdate: false,
    cancelLeave: false,
    removeItem: false,
    removeScheduleItem: false,
    selectedItem: {
        "@ID": '0',
        "@Name": '',
        "@BridgeKeyID": '',
        "@FileMask": '',
        "@Enabled": 'N',
        "@ContentType": '',
        "@BatchFiles": 'N',
        Events: {
            Event: []
        },
        ContentFileMasks: { ContentFileMask: [] }
    },
    Items: [],
    selectedSchedulerItem: {
        "@ID": '0',
        "@Mon": 'N',
        "@Tue": 'N',
        "@Wed": 'N',
        "@Thu": 'N',
        "@Fri": 'N',
        "@Sat": 'N',
        "@Sun": 'N',
        "@FromTime": '00:00:00',
        "@ThruTime": '00:00:00',
        "@CheckInterval": '0',
        "@ClientId": sessionStorage.getItem("Client"),
        "@Name": '- ADD A NEW TIME -'
    },
    scheduleIdExist: false,
    maxID: '-1',
    maxSchedulerID: '',
    bridgeModal: false
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IAutoSubmitMaintenanceProps = IMergeCrudComponentProps<IAutoSubmitMaintenanceState, IAutoSubmitMaintenanceActionProps, IOwnProps>;

export class AutoSubmitMaintenance extends React.Component<IAutoSubmitMaintenanceProps, IComponentState> {
    static addItemCount: number = 0;

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canSecCheck1: false,
        title: "Set Up Agent"
    };

    constructor(props: IAutoSubmitMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        pageLeave();
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "EnableTenetFeatures" }] });
        this.props.action.crud.get({ crudId: CrudTypes.mctiAutoSubmit }, validationCallback)
    }

    public componentDidUpdate() {
        if (this.state.selectedItem["@BridgeKeyID"] === '' && this.props.dataStore.crud.data !== undefined && Array.isArray(this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey)) {
            const json = {
                "@ID": '0',
                "@Name": '- ADD A NEW AUTO SUBMIT -',
                "@BridgeKeyID": this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey[0]["@ID"],
                "@FileMask": '',
                "@Enabled": '',
                "@ContentType": '0',
                "@BatchFiles": '',
                "Events": { Event: [] },
                "ContentFileMasks": { ContentFileMask: [] }
            }
            this.setState({ selectedItem: json })
        }
        if (this.crudLoaded()) {
            if (this.state.Items.length === 0 && this.props.dataStore.crud !== undefined) {
                if (this.props.dataStore.crud.originalData?.AutoSubmitMaintenanceInfo?.AutoSubmits.AutoSubmit !== undefined) {
                    let items: any = this.props.dataStore.crud.originalData?.AutoSubmitMaintenanceInfo?.AutoSubmits.AutoSubmit
                    const selectedItem = {
                        "@ID": '0',
                        "@Name": '',
                        "@BridgeKeyID": '',
                        "@FileMask": '',
                        "@Enabled": 'N',
                        "@ContentType": '0',
                        "@BatchFiles": 'N',
                        Events: {
                            Event: []
                        },
                        "ContentFileMasks": { ContentFileMask: [] }
                    }
                    let max = { ...items[0] };

                    for (let i = 0; i < items.length; i++) {
                        if (max == null || parseInt(items[i]['@ID']) > parseInt(max['@ID']))
                            max = items[i];
                    }
                    if (items.length > 0)
                        this.setState({
                            selectedSchedulerItem: {
                                "@ID": '0',
                                "@Mon": 'N',
                                "@Tue": 'N',
                                "@Wed": 'N',
                                "@Thu": 'N',
                                "@Fri": 'N',
                                "@Sat": 'N',
                                "@Sun": 'N',
                                "@FromTime": '00:00:00',
                                "@ThruTime": '00:00:00',
                                "@CheckInterval": '0',
                                "@ClientId": sessionStorage.getItem("Client"),
                                "@Name": '- ADD A NEW TIME -'
                            },
                            Items: this.props.dataStore.crud.originalData?.AutoSubmitMaintenanceInfo?.AutoSubmits.AutoSubmit, selectedItem: selectedItem, maxID: max['@ID']
                        })
                }
            }
        }
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    /* -----------------------------------  */
    /* ------  BUTTON RELATED METHODS ----  */
    /* -----------------------------------  */

    public crudLoaded(): boolean {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo &&
            this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.AutoSubmits &&
            this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.AutoSubmits.AutoSubmit
        )
            return true;
        return false;
    }

    public crudBridgeKeyLoaded(): boolean {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo &&
            this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList &&
            this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey
        )
            return true;
        return false;
    }

    /* -----------------------------------  */
    /* -----  DATA HELP METHODS ----------  */
    /* -----------------------------------  */

    public getGenericLookupList_Array(lookupList: AEP_GenericLookupList[] | null) {
        if (!this.crudBridgeKeyLoaded() ||
            !lookupList || lookupList.length === 0)
            return [];

        let result: any[] = [];

        lookupList.forEach(ob => {
            if (ob['@Name'])
                result.push({ text: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getFromToItem() {
        let setvalue = [
            { label: '00:00', value: '00:00:00' },
            { label: '01:00', value: '01:00:00' },
            { label: '02:00', value: '02:00:00' },
            { label: '03:00', value: '03:00:00' },
            { label: '04:00', value: '04:00:00' },
            { label: '05:00', value: '05:00:00' },
            { label: '06:00', value: '06:00:00' },
            { label: '07:00', value: '07:00:00' },
            { label: '08:00', value: '08:00:00' },
            { label: '09:00', value: '09:00:00' },
            { label: '10:00', value: '10:00:00' },
            { label: '11:00', value: '11:00:00' },
            { label: '12:00', value: '12:00:00' },
            { label: '13:00', value: '13:00:00' },
            { label: '14:00', value: '14:00:00' },
            { label: '15:00', value: '15:00:00' },
            { label: '16:00', value: '16:00:00' },
            { label: '17:00', value: '17:00:00' },
            { label: '18:00', value: '18:00:00' },
            { label: '19:00', value: '19:00:00' },
            { label: '20:00', value: '20:00:00' },
            { label: '21:00', value: '21:00:00' },
            { label: '22:00', value: '22:00:00' },
            { label: '23:00', value: '23:00:00' },
        ]
        return setvalue;
    }

    public getSelectedSchedulerItem() {
        const selectedSchedulerItem = {
            "@ID": '0',
            "@Mon": 'N',
            "@Tue": 'N',
            "@Wed": 'N',
            "@Thu": 'N',
            "@Fri": 'N',
            "@Sat": 'N',
            "@Sun": 'N',
            "@FromTime": '00:00:00',
            "@ThruTime": '00:00:00',
            "@CheckInterval": '0',
            "@ClientId": sessionStorage.getItem("Client"),
            "@Name": '- ADD A NEW TIME -'
        }
        return selectedSchedulerItem;
    }

    public allowTenetFlag(): string {
        let allowTenetObj = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "EnableTenetFeatures");
        return (allowTenetObj && allowTenetObj.value ? allowTenetObj.value : "");
    }

    public onOKAutoSubmit(e: React.ChangeEvent<HTMLButtonElement>) {
        let updateddata = this.props.dataStore.crud.data
        if (updateddata !== undefined) {
            const items = this.state.Items.filter((item: any) => item['@ID'] !== '0')
            items.map((item: any) => {
                if (parseInt(item['@ID']) > parseInt(this.state.maxID)) {
                    item['@ID'] = '#'
                }
                if (item['@Delete'] !== true) {
                    if (item['@ContentType'].includes('1'))
                        item.ContentFileMasks.ContentFileMask = item.ContentFileMasks.ContentFileMask.slice(0, 3)
                    else if (item['@ContentType'].includes('3'))
                        item.ContentFileMasks.ContentFileMask = item.ContentFileMasks.ContentFileMask.slice(0, 2)
                }

                if (item.Events !== undefined)
                    if (Array.isArray(item.Events.Event)) {
                        item.Events.Event.map((itemName: any) => {
                            if (this.state.maxSchedulerID !== '')
                                if (parseInt(itemName['@ID']) > parseInt(this.state.maxSchedulerID) && itemName['@Delete'] !== true) {
                                    itemName['@ID'] = '#'
                                }
                            delete itemName['@Name']
                        })
                    }
            })
            if (updateddata.AutoSubmitMaintenanceInfo.AutoSubmits.AutoSubmit !== undefined)
                updateddata.AutoSubmitMaintenanceInfo.AutoSubmits.AutoSubmit = items
            else
                updateddata.AutoSubmitMaintenanceInfo.AutoSubmits = { AutoSubmit: items }

            delete updateddata.AutoSubmitMaintenanceInfo.BridgeKeyList

            const data = {
                crudId: this.props.dataStore.crud.crudId,
                data: updateddata,
                dataStatus: this.props.dataStore.crud.dataStatus,
                originalData: this.props.dataStore.crud.originalData
            }
            this.props.action.crud.update(data)
        }
        setTimeout(() => {
            this.props.history.push('/LandingPage')
        }, 2000)
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public getScheduleItems() {
        let addItem: object[] = [
            {
                "@ID": '0',
                "@Mon": 'N',
                "@Tue": 'N',
                "@Wed": 'N',
                "@Thu": 'N',
                "@Fri": 'N',
                "@Sat": 'N',
                "@Sun": 'N',
                "@FromTime": '00:00:00',
                "@ThruTime": '00:00:00',
                "@CheckInterval": '0',
                "@ClientId": sessionStorage.getItem("Client"),
                "@Name": '- ADD A NEW TIME -'
            }
        ];

        if (this.state.selectedItem.Events !== "" && this.state.selectedItem.Events !== undefined)
            if (Array.isArray(this.state.selectedItem.Events.Event)) {
                this.state.selectedItem.Events.Event.sort((a: any, b: any) => a['@ID'] - b['@ID']).map((item: any) => {
                    const json = item

                    if (json['@Sun'] === 'N' && json['@Mon'] === 'N' && json['@Tue'] === 'N' && json['@Wed'] === 'N' && json['@Thu'] === 'N' && json['@Fri'] === 'N' && json['@Sat'] === 'N')
                        json['@Name'] = json['@FromTime'].substr(0, 5) + '-' + json['@ThruTime'].substr(0, 5) + ' No Days'
                    else
                        json['@Name'] = json['@FromTime'].substr(0, 5) + '-' + json['@ThruTime'].substr(0, 5) + ' ' + (json['@Sun'] == 'Y' ? 'Su' : '') + (json['@Mon'] == 'Y' ? 'Mo' : '') + (json['@Tue'] == 'Y' ? 'Tu' : '') + (json['@Wed'] == 'Y' ? 'We' : '') + (json['@Thu'] == 'Y' ? 'Th' : '') + (json['@Fri'] == 'Y' ? 'Fr' : '') + (json['@Sat'] == 'Y' ? 'Sa' : '')

                    addItem.push(json)
                })
            }
            else if (this.state.selectedItem.Events.Event !== undefined) {
                const json = this.state.selectedItem.Events.Event
                if (json['@Sun'] === 'N' && json['@Mon'] === 'N' && json['@Tue'] === 'N' && json['@Wed'] === 'N' && json['@Thu'] === 'N' && json['@Fri'] === 'N' && json['@Sat'] === 'N')
                    json['@Name'] = json['@FromTime'].substr(0, 5) + '-' + json['@ThruTime'].substr(0, 5) + ' No Days'
                else
                    json['@Name'] = json['@FromTime'].substr(0, 5) + '-' + json['@ThruTime'].substr(0, 5) + ' ' + (json['@Sun'] == 'Y' ? 'Su' : '') + (json['@Mon'] == 'Y' ? 'Mo' : '') + (json['@Tue'] == 'Y' ? 'Tu' : '') + (json['@Wed'] == 'Y' ? 'We' : '') + (json['@Thu'] == 'Y' ? 'Th' : '') + (json['@Fri'] == 'Y' ? 'Fr' : '') + (json['@Sat'] == 'Y' ? 'Sa' : '')

                if (json['@Delete'] !== true)
                    addItem.push(json)
            }
        return addItem;
    }

    public getAutoSubmitItems() {
        if (this.props.dataStore.crud.data && this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey !== undefined) {
            let addItem: object[] = [
                {
                    "@ID": '0',
                    "@Name": '- ADD A NEW AUTO SUBMIT -',
                    "@BridgeKeyID": this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey[0]["@ID"],
                    "@FileMask": '',
                    "@Enabled": '',
                    "@ContentType": '0',
                    "@BatchFiles": '',
                    "Events": { Event: [] },
                    "ContentFileMasks": { ContentFileMask: [] }
                }
            ];
            if (Array.isArray(this.state.Items)) {

                this.state.Items.map((item: any) => {
                    const json = item

                    if (parseInt(this.state.maxID) >= parseInt(item['@ID']))
                        addItem.push(json)
                })
                const items = [... this.state.Items]
                items.sort((a: any, b: any) => a['@ID'] - b['@ID']).map((item: any) => {
                    const json = item

                    if (parseInt(this.state.maxID) < parseInt(item['@ID']))
                        addItem.push(json)
                })
            }
            else if (this.state.Items !== undefined) {
                const json = this.state.Items

                if (json['@Delete'] !== true)
                    addItem.push(json)
            }
            return addItem;
        }
        else if (this.props.dataStore.crud.data && this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList?.BridgeKey === undefined) {
            return []
        }
        else {
            return []
        }
    }

    public onClickModifyAutoSubmitButton(contentFileMasks: any, autoSubmitItems: any, fileType: any) {
        let error = false
        if (this.state.selectedItem['@Name'] === '' || this.state.selectedItem["@Name"] === '- ADD A NEW AUTO SUBMIT -' ||
            (this.state.Items.filter((item: any) =>
                item['@Name'] === this.state.selectedItem['@Name'] && item['@ID'] !== this.state.selectedItem['@ID']).length > 0)) {
            error = true
        }
        if (this.state.selectedItem['@FileMask'] === '') {
            error = true
        }
        const selectedItem = this.state.selectedItem

        if (selectedItem['@ContentType'] !== '2' && selectedItem.ContentFileMasks !== '' && selectedItem.ContentFileMasks?.ContentFileMask?.length < contentFileMasks.length) {
            contentFileMasks.map((item: any, i: any) => {
                const json = { '@ID': i, '@Mask': '' }
                selectedItem.ContentFileMasks.ContentFileMask.push(json)
            })
        }

        if (selectedItem['@ContentType'] !== '2' && Array.isArray(this.state.selectedItem.ContentFileMasks.ContentFileMask)) {
            contentFileMasks.map((item: any, i: any) => {
                if (contentFileMasks[i].label.includes('*') && selectedItem.ContentFileMasks.ContentFileMask[i]['@Mask'] === '')
                    error = true
            })
        }
        if (selectedItem['@ContentType'] === '2' && (((!Array.isArray(selectedItem.ContentFileMasks?.ContentFileMask) && selectedItem.ContentFileMasks?.ContentFileMask['@Mask'] === '') ||
            (!Array.isArray(selectedItem.ContentFileMasks?.ContentFileMask) && selectedItem.ContentFileMasks?.ContentFileMask['@Mask'] === undefined)) ||
            (Array.isArray(selectedItem.ContentFileMasks?.ContentFileMask) && selectedItem.ContentFileMasks.ContentFileMask[0]['@Mask'] === ''))) {
            error = true
        }
        if (error) {
            if ((this.state.Items.filter((item: any) =>
                item['@Name'] === this.state.selectedItem['@Name'] && item['@ID'] !== this.state.selectedItem['@ID']).length > 0))
                this.setState({ errorNameExist: true })
            this.setState({ error: true })
        }
        else {
            const items: any = autoSubmitItems
            const updatedItems: any = []
            let selectedItem = { ...this.state.selectedItem }
            for (let i = 0; i < items.length; i++) {
                if (this.state.selectedItem["@ID"] === items[i]["@ID"]) {
                    if (this.state.selectedItem["@ID"] === '0') {
                        let max = items[0];
                        for (let i = 0; i < autoSubmitItems.length; i++) {
                            if (max == null || parseInt(items[i]['@ID']) > parseInt(max['@ID']))
                                max = autoSubmitItems[i];
                        }
                        const id = parseInt(max['@ID']) + 1
                        if (selectedItem["@ContentType"] === '1')
                            selectedItem.ContentFileMasks.ContentFileMask.slice(0, 2)
                        else if (selectedItem["@ContentType"] === '3')
                            selectedItem.ContentFileMasks.ContentFileMask.slice(0, 2)

                        selectedItem["@ID"] = id.toString()
                        if (selectedItem["@BridgeKeyID"] === '')
                            selectedItem["@BridgeKeyID"] = fileType.value;
                        updatedItems.push({ ...selectedItem })
                    }
                    else
                        updatedItems.push({ ...this.state.selectedItem })
                }
                else if (items[i]["@ID"] !== '0') {
                    updatedItems.push(autoSubmitItems[i])
                }
            }

            this.setState({ isUpdate: true, Items: [...updatedItems], selectedSchedulerItem: this.getSelectedSchedulerItem(), selectedItem })
            handleChange()
        }
    }

    public onClickRemoveAutoSubmitButton() {
        const items: any = []
        this.state.Items.map((item: any) => {
            const json = item

            if (parseInt(this.state.maxID) >= parseInt(item['@ID']))
                items.push(json)
        })

        this.state.Items.sort((a: any, b: any) => a['@ID'] - b['@ID']).map((item: any) => {
            const json = item

            if (parseInt(this.state.maxID) < parseInt(item['@ID']))
                items.push(json)
        })

        const updatedItems: any = []
        let nextItem: any = []
        for (let i = 0; i < items.length; i++) {
            if (this.state.selectedItem["@ID"] === items[i]["@ID"]) {
                const selectedItem = { '@ID': this.state.selectedItem['@ID'], "@Delete": true }
                updatedItems.push({ ...selectedItem })
                const index = items.filter((item: any) => item["@Delete"] !== true).findIndex((item: any) => item['@ID'] === this.state.selectedItem['@ID'])
                if (items.filter((item: any) => item["@Delete"] !== true).findIndex((item: any) => item['@ID'] === this.state.selectedItem['@ID']) === items.filter((item: any) => item["@Delete"] !== true).length - 1)
                    nextItem = items.filter((item: any) => item["@Delete"] !== true)[index - 1]
                else
                    nextItem = items.filter((item: any) => item["@Delete"] !== true)[index + 1]
            }
            else {
                updatedItems.push(items[i])
            }
        }

        this.setState({ isUpdate: true, Items: updatedItems, selectedSchedulerItem: this.getSelectedSchedulerItem(), removeItem: !this.state.removeItem })
        handleChange()
        setTimeout(() => {
            if (nextItem === undefined && this.props.dataStore.crud.data !== undefined) {
                nextItem = {
                    "@ID": '0',
                    "@Name": '- ADD A NEW AUTO SUBMIT -',
                    "@BridgeKeyID": this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey[0]["@ID"],
                    "@FileMask": '',
                    "@Enabled": '',
                    "@ContentType": '',
                    "@BatchFiles": '',
                    "Events": { Event: [] },
                    "ContentFileMasks": { ContentFileMask: [] }
                }
            }
            this.setState({ selectedItem: { ...nextItem } })
        }, 150)
    }

    public onClickModifyScheduleButton(ScheduleItems: any) {
        const items = this.state.Items
        const updatedItems: any = []
        const SchedulerArray: any = []

        for (let i = 0; i < items.length; i++) {
            if (items[i]['@Delete'] !== true)
                if (Array.isArray(this.state.Items[i].Events?.Event)) {
                    this.state.Items[i].Events.Event.map((item: any) => {
                        const json = item
                        json['@Name'] = json['@FromTime'] + '-' + json['@ThruTime']
                        SchedulerArray.push(json)
                    })
                }
                else if (this.state.Items[i].Events?.Event !== undefined) {
                    const json = this.state.Items[i].Events.Event
                    json['@Name'] = json['@FromTime'] + '-' + json['@ThruTime']
                    SchedulerArray.push(json)
                }
        }

        let selectedAutoSubmit = this.state.selectedItem
        for (let i = 0; i < items.length; i++) {
            let updatedList: any = []
            if (this.state.selectedItem["@ID"] === this.state.Items[i]["@ID"]) {
                ScheduleItems.map((selectedValue: any, i: any) => {
                    if (selectedValue["@ID"] === this.state.selectedSchedulerItem["@ID"] && selectedValue["@ID"] !== '0') {
                        const json = this.state.selectedSchedulerItem
                        updatedList.push(json)
                    }
                    else if (selectedValue["@ID"] === this.state.selectedSchedulerItem["@ID"] && selectedValue["@ID"] === '0') {
                        const json = this.state.selectedSchedulerItem
                        let max;
                        for (let i = 0; i < SchedulerArray.length; i++) {
                            if (max == null || parseInt(SchedulerArray[i]['@ID']) > parseInt(max['@ID']))
                                max = SchedulerArray[i];
                        }
                        let id = 1
                        if (this.state.maxSchedulerID === '') {
                            if (max === undefined) {
                                this.setState({ maxSchedulerID: '-1' })
                            }
                            else {
                                this.setState({ maxSchedulerID: max['@ID'] })
                            }
                        }
                        if (max !== undefined) {
                            id = parseInt(max['@ID']) + 1
                        }

                        json['@ID'] = id.toString()
                        updatedList.push(json)
                    }
                    else if (selectedValue["@ID"] !== '0') {
                        updatedList.push(selectedValue)
                    }
                }
                )
                let selectedItem = this.state.Items[i]
                if (selectedItem.Events === '') {
                    selectedItem.Events = { Event: [] }
                }
                selectedItem.Events.Event = updatedList
                updatedItems.push({ ...selectedItem })

                if (selectedAutoSubmit.Events === '') {
                    selectedAutoSubmit.Events = { Event: [] }
                }
                selectedAutoSubmit.Events.Event = updatedList
            }
            else {
                updatedItems.push(this.state.Items[i])
            }
        }

        this.setState({ selectedItem: selectedAutoSubmit, isUpdate: true, Items: [...updatedItems], selectedSchedulerItem: { ... this.state.selectedSchedulerItem } })
        handleChange()
    }

    public onClickRemoveScheduleButton(ScheduleItems: any) {
        setTimeout(() => {
            const items = this.state.Items
            const updatedItems: any = []
            let selectedSchedulerItem = this.state.selectedSchedulerItem

            let selectedAutoSubmit = this.state.selectedItem
            for (let i = 0; i < items.length; i++) {
                let updatedList: any = []
                if (this.state.selectedItem["@ID"] === this.state.Items[i]["@ID"]) {
                    ScheduleItems.map((selectedValue: any, i: any) => {
                        if (selectedValue["@ID"] === this.state.selectedSchedulerItem["@ID"] && selectedValue["@ID"] !== '0') {
                            const json = this.state.selectedSchedulerItem
                            json['@Delete'] = true
                            updatedList.push(json)
                            const filteredSelectedvalue = ScheduleItems.filter((item: any) => item['@Delete'] !== true)
                            const index = filteredSelectedvalue.findIndex((item: any) => item["@ID"] === selectedValue["@ID"])
                            if (index === filteredSelectedvalue.length - 1) {
                                selectedSchedulerItem = filteredSelectedvalue[index - 1]
                            }
                            else {
                                selectedSchedulerItem = filteredSelectedvalue[index + 1]
                            }
                        }
                        else if (selectedValue["@ID"] !== '0') {
                            updatedList.push(selectedValue)
                        }
                    }
                    )
                    let selectedItem = this.state.Items[i]
                    if (selectedItem.Events === '') {
                        selectedItem.Events = { Event: [] }
                    }
                    selectedItem.Events.Event = updatedList
                    updatedItems.push({ ...selectedItem })

                    if (selectedAutoSubmit.Events === '') {
                        selectedAutoSubmit.Events = { Event: [] }
                    }
                    selectedAutoSubmit.Events.Event = updatedList
                }
                else {
                    updatedItems.push(this.state.Items[i])
                }
            }
            this.setState({ selectedItem: selectedAutoSubmit, isUpdate: true, Items: updatedItems, selectedSchedulerItem: { ...selectedSchedulerItem } })
            handleChange()
        }, 150)
    }

    /* -----------------------------------  */
    /* -------------  RENDER -------------  */
    /* -----------------------------------  */

    public render() {
        var instruction = <React.Fragment>The Auto-Submit Agent must be installed on your computer. If not installed, call Customer Support for installation information. Your auto-submit schedule has been optimized for your site. Do not change it unless directed to do so by Product Support.</React.Fragment>;
        let instructionTop = <React.Fragment><span className='instructionRed'>Warning!</span> To reflect any changes made to the settings, the agent must be stopped and restarted. </React.Fragment>;

        var buttons = <OKCancelButtons disableOK={!this.props.canEdit || !this.state.isUpdate} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => { this.onOKAutoSubmit(e) }} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        let allowTenet = this.allowTenetFlag();

        const autoSubmitItems = this.getAutoSubmitItems();
        const scheduleItems = this.getScheduleItems();
        const selectedSchedulerItems = this.getSelectedSchedulerItem();

        const record = []
        if (this.props.dataStore.crud.originalData?.AutoSubmitMaintenanceInfo?.AutoSubmits !== undefined) {
            record.push(this.props.dataStore.crud.originalData?.AutoSubmitMaintenanceInfo?.AutoSubmits)
        }

        let fileType: any = ''
        if (this.props.dataStore.crud.data !== undefined && Array.isArray(this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey))
            fileType = {
                label: this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey[0]["@Name"],
                value: this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey[0]["@ID"]
            }

        if (this.props.dataStore.crud.data !== undefined && this.state.selectedItem["@BridgeKeyID"] !== '' && Array.isArray(this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey)) {
            const BridgeKey: any = this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey.find(item => item["@ID"] === this.state.selectedItem["@BridgeKeyID"])
            if (BridgeKey !== undefined)
                fileType = { label: BridgeKey["@Name"], value: BridgeKey['@ID'] }
        }

        let SUBMISSION = {
            text: 'Single', value: '0', selected: 'selected', disabled: undefined, title: undefined, fav: undefined
        }

        let ContentFileMasks: any[] = []
        switch (this.state.selectedItem["@ContentType"]) {
            case '0':
                SUBMISSION = { text: 'Single', value: '0', selected: 'selected', disabled: undefined, title: undefined, fav: undefined }
                break;

            case '1':
                SUBMISSION = { text: 'Series HIS System', value: '1', selected: 'selected', disabled: undefined, title: undefined, fav: undefined }

                if (Array.isArray(this.state.selectedItem.ContentFileMasks.ContentFileMask)
                    && this.state.selectedItem.ContentFileMasks.ContentFileMask.length >= 3)
                    ContentFileMasks = [
                        {
                            label: '*Job Level File:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[0]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[0]["@ID"]
                        },
                        {
                            label: '*Bill Level File:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[1]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[1]["@ID"]
                        },
                        {
                            label: '*Claim Level File:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[2]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[2]["@ID"]
                        }
                    ]
                else
                    ContentFileMasks = [
                        { label: '*Job Level File:', Mask: '', ID: 0 },
                        { label: '*Bill Level File:', Mask: '', ID: 1 },
                        { label: '*Claim Level File:', Mask: '', ID: 2 }
                    ]
                break;

            case '2':
                SUBMISSION = {
                    text: 'Star/PBAR HIS System', value: '2', selected: 'selected', disabled: undefined, title: undefined, fav: undefined
                }
                if (this.state.selectedItem.ContentFileMasks.ContentFileMask["@Mask"] !== undefined)
                    ContentFileMasks = [
                        {
                            label: '*Data File:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask["@ID"]
                        }
                    ]
                else if (Array.isArray(this.state.selectedItem.ContentFileMasks.ContentFileMask) && this.state.selectedItem.ContentFileMasks.ContentFileMask.length > 0)
                    ContentFileMasks = [
                        {
                            label: '*Data File:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[0]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[0]["@ID"]
                        }
                    ]
                else
                    ContentFileMasks = [
                        { label: '*Data File:', Mask: '', ID: 0 }
                    ]
                break;

            case '3':
                SUBMISSION = {
                    text: 'TPA HIS System', value: '3', selected: 'selected', disabled: undefined, title: undefined, fav: undefined
                }
                if (Array.isArray(this.state.selectedItem.ContentFileMasks.ContentFileMask)
                    && this.state.selectedItem.ContentFileMasks.ContentFileMask.length >= 2)
                    ContentFileMasks = [
                        {
                            label: '*Claim Data File:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[0]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[0]["@ID"]
                        },
                        {
                            label: '*Service Data File:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[1]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[1]["@ID"]
                        },
                    ]
                else
                    ContentFileMasks = [
                        { label: '*Claim Data File:', Mask: '', ID: 0 },
                        { label: '*Service Data File:', Mask: '', ID: 1 },
                    ]
                break;
            case '4':
                SUBMISSION = { text: 'McKesson Proprietary', value: '4', selected: 'selected', disabled: undefined, title: undefined, fav: undefined }

                if (Array.isArray(this.state.selectedItem.ContentFileMasks.ContentFileMask)
                    && this.state.selectedItem.ContentFileMasks.ContentFileMask.length > 5)

                    ContentFileMasks = [
                        {
                            label: '*RES File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[0]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[0]["@ID"]
                        },
                        {
                            label: '*INS File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[1]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[1]["@ID"]
                        },
                        {
                            label: '*EMP File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[2]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[2]["@ID"]
                        },
                        {
                            label: '*PHY File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[3]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[3]["@ID"]
                        },
                        {
                            label: '*MIS File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[4]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[4]["@ID"]
                        },
                        {
                            label: '*CHG File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[5]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[5]["@ID"]
                        },
                        {
                            label: '*ASC File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[6]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[6]["@ID"]
                        },
                        {
                            label: '*VAR File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[7]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[7]["@ID"]
                        },
                        {
                            label: 'TPA File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[8]["@Mask"] === undefined ? '' :
                                this.state.selectedItem.ContentFileMasks.ContentFileMask[8]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[8]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[8]["@ID"]
                        },
                        {
                            label: '*ADD File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[9]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[9]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[9]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[9]["@ID"]
                        },
                        {
                            label: 'ATM File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[10]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[10]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[10]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[10]["@ID"]
                        },
                        {
                            label: 'REM File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[11]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[11]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[11]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[11]["@ID"]
                        },
                        {
                            label: 'NDC File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[12]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[12]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[12]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[12]["@ID"]
                        },
                        {
                            label: 'FFI File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[13]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[13]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[13]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[13]["@ID"]
                        },
                        {
                            label: 'PROV File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[14]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[14]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[14]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[14]["@ID"]
                        },
                        {
                            label: 'DEN File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[15]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[15]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[15]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[15]["@ID"]
                        },
                        {
                            label: 'EPRM File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[16]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[16]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[16]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[16]["@ID"]
                        },
                        {
                            label: 'CHA File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[17]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[17]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[17]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[17]["@ID"]
                        },
                        {
                            label: 'ADJ File Mask:', Mask: this.state.selectedItem.ContentFileMasks.ContentFileMask[18]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[18]["@Mask"],
                            ID: this.state.selectedItem.ContentFileMasks.ContentFileMask[18]["@Mask"] === undefined ? '' : this.state.selectedItem.ContentFileMasks.ContentFileMask[18]["@ID"]
                        }
                    ]
                else
                    ContentFileMasks = [
                        {
                            label: '*RES File Mask:', Mask: '',
                            ID: 0
                        },
                        {
                            label: '*INS File Mask:', Mask: '',
                            ID: 1
                        },
                        {
                            label: '*EMP File Mask:', Mask: '',
                            ID: 2
                        },
                        {
                            label: '*PHY File Mask:', Mask: '',
                            ID: 3
                        },
                        {
                            label: '*MIS File Mask:', Mask: '',
                            ID: 4
                        },
                        {
                            label: '*CHG File Mask:', Mask: '',
                            ID: 5
                        },
                        {
                            label: '*ASC File Mask:', Mask: '',
                            ID: 6
                        },
                        {
                            label: '*VAR File Mask:', Mask: '',
                            ID: 7
                        },
                        {
                            label: 'TPA File Mask:', Mask: '',
                            ID: 8
                        },
                        {
                            label: '*ADD File Mask:', Mask: '',
                            ID: 9
                        },
                        {
                            label: 'ATM File Mask:', Mask: '',
                            ID: 10
                        },
                        {
                            label: 'REM File Mask:', Mask: '',
                            ID: 11
                        },
                        {
                            label: 'NDC File Mask:', Mask: '',
                            ID: 12
                        },
                        {
                            label: 'FFI File Mask:', Mask: '',
                            ID: 13
                        },
                        {
                            label: 'PROV File Mask:', Mask: '',
                            ID: 14
                        },
                        {
                            label: 'DEN File Mask:', Mask: '',
                            ID: 15
                        },
                        {
                            label: 'EPRM File Mask:', Mask: '',
                            ID: 16
                        },
                        {
                            label: 'CHA File Mask:', Mask: '',
                            ID: 17
                        },
                        {
                            label: 'ADJ File Mask:', Mask: '',
                            ID: 18
                        }
                    ]
                break;
        }
        SUBMISSION.selected = "selected"
        SUBMISSION.disabled = undefined
        SUBMISSION.title = undefined
        SUBMISSION.fav = undefined
        let BridgeKey: any = []
        if (this.props.dataStore.crud.data !== undefined && Array.isArray(this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey))
            BridgeKey = [...BridgeKey, ...this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey]


        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_AutoSubmit.asp' buttons={buttons} isBusy={!this.props.dataStore.crud.data}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper id={"content_wrapper_AutoSubmitMaintenance"}>
                    <CenteredContent><DialogInstruction>{instructionTop}</DialogInstruction></CenteredContent>
                    <ContentRowWrapper>
                        <AutoSubmitMaintenanceLeftContent>
                            <DialogFieldset id='left-dialogfieldset'>
                                <DialogLegend>Auto Submit</DialogLegend>
                                <ContentWrapper>
                                    <ContentRowWrapper>
                                        <ContentColumnWrapper style={{ marginLeft: "6px" }}>
                                            <SelectComponent
                                                title='autosubmit'
                                                size={(allowTenet === '1' ? 35 : 30)}
                                                width='370px'
                                                height='513px'
                                                selectedValue={this.state.selectedItem["@ID"]}
                                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                    let searchResultsSelectedValue = autoSubmitItems.filter((selectedValue: any) => selectedValue["@ID"] == e.target.value)

                                                    if (searchResultsSelectedValue !== undefined) {
                                                        const selectedItem: any = { ...searchResultsSelectedValue[0] }
                                                        if (selectedItem["@ContentType"] === '2' && Array.isArray(selectedItem.ContentFileMasks.ContentFileMask))
                                                            selectedItem.ContentFileMasks = {
                                                                ContentFileMask: { '@ID': selectedItem.ContentFileMasks.ContentFileMask[0]['@ID'], '@Mask': selectedItem.ContentFileMasks.ContentFileMask[0]['@Mask'] }
                                                            }

                                                        this.setState({ selectedItem, selectedSchedulerItem: selectedSchedulerItems, error: false, errorNameExist: false })
                                                    }
                                                }}
                                                optionFields={{
                                                    value: "@ID",
                                                    text: "@Name"
                                                }}
                                                records={autoSubmitItems.filter((item: any) => item['@Delete'] !== true)}
                                            ></SelectComponent>
                                        </ContentColumnWrapper>
                                    </ContentRowWrapper>
                                </ContentWrapper>
                            </DialogFieldset>
                        </AutoSubmitMaintenanceLeftContent>

                        <AutoSubmitMaintenanceRightContent>
                            <ContentSectionWrapper>
                                <ContentRowWrapper>
                                    <ARMNarrowInput
                                        id="name"
                                        classNameValue="text-input"
                                        label="*Name:"
                                        maxLength={35}
                                        initialValue={this.state.selectedItem["@Name"] !== '- ADD A NEW AUTO SUBMIT -' ? this.state.selectedItem["@Name"] : ''}

                                        hasError={this.state.error && (this.state.selectedItem["@Name"] === '' || this.state.selectedItem["@Name"] === '- ADD A NEW AUTO SUBMIT -') ||
                                            this.state.errorNameExist && (this.state.Items.filter((item: any) =>
                                                item['@Name'] === this.state.selectedItem['@Name'] && item['@ID'] !== this.state.selectedItem['@ID']).length > 0)}

                                        errorMessage={this.state.errorNameExist ? 'This Auto Submit already exists. Please type in a different name.' : this.state.error && 'Name must not be blank.'}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            const selectedItem = this.state.selectedItem
                                            selectedItem["@Name"] = e.target.value.toUpperCase()
                                            this.setState({ selectedItem, errorNameExist: false })
                                        }}
                                    />
                                </ContentRowWrapper>

                                <ContentRowWrapper>
                                    <ARMNarrowDropdown
                                        title={this.state.selectedItem["@ContentType"]}
                                        label={"Submission:"}
                                        className="dropdown"
                                        isLabel={true}
                                        width="300px"
                                        multiple="false"
                                        onSelect={(e: any) => {
                                            const stringItem = JSON.stringify(this.state.selectedItem)
                                            const selectedItem = JSON.parse(stringItem)
                                            selectedItem["@ContentType"] = e.target.value
                                            const index = this.state.Items.findIndex((item: any) => {
                                                return item['@ID'] === selectedItem['@ID']
                                            })

                                            if (e.target.value === '2') {
                                                if (selectedItem.ContentFileMasks !== '')
                                                    selectedItem.ContentFileMasks.ContentFileMask = {}
                                                else
                                                    selectedItem.ContentFileMasks = {
                                                        ContentFileMask: {}
                                                    }
                                            }
                                            else if (selectedItem.ContentFileMasks !== '')
                                                selectedItem.ContentFileMasks.ContentFileMask = []
                                            else
                                                selectedItem.ContentFileMasks = { ContentFileMask: [] }
                                            if (Array.isArray(this.state.Items) && index > -1)
                                                if (this.state.Items[index]['@ID'] === selectedItem['@ID'] && this.state.Items[index]['@ContentType'] === e.target.value) {
                                                    const stringFyItem = JSON.stringify(this.state.Items[index])
                                                    const foundItem = JSON.parse(stringFyItem)
                                                    selectedItem.ContentFileMasks.ContentFileMask = foundItem.ContentFileMasks.ContentFileMask
                                                }

                                            this.setState({ selectedItem, error: false })
                                        }}

                                        records={[
                                            { text: 'Single', value: '0' },
                                            { text: 'Series HIS System', value: '1' },
                                            { text: 'Star/PBAR HIS System', value: '2' },
                                            { text: 'TPA HIS System', value: '3' },
                                            { text: 'McKesson Proprietary', value: '4' }]}
                                        optionFields={{
                                            value: "value",
                                            text: "text"
                                        }}
                                        selectedValue={this.state.selectedItem["@ContentType"]}
                                    />
                                </ContentRowWrapper>

                                <ContentRowWrapper>

                                    <ARMNarrowCheck
                                        id="enable"
                                        label='Enable:'
                                        checked={this.state.selectedItem["@Enabled"] === 'Y'}
                                        onChangeSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const selectedItem = this.state.selectedItem
                                            selectedItem["@Enabled"] = e.target.checked ? 'Y' : 'N'
                                            this.setState({ selectedItem })
                                        }
                                        }
                                    />

                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <ARMNarrowDropdown
                                        title={this.state.selectedItem["@BridgeKeyID"]}
                                        label={"*File Type:"}
                                        className="dropdown"
                                        isLabel={true}
                                        width="300px"
                                        multiple="false"
                                        onSelect={(e: any) => {
                                            const selectedItem = this.state.selectedItem
                                            selectedItem["@BridgeKeyID"] = e.target.value
                                            this.setState({ selectedItem })
                                        }}
                                        records={this.getGenericLookupList_Array((!this.props.dataStore.crud.data ||
                                            !this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo
                                            ? null : BridgeKey))}
                                        optionFields={{
                                            value: "value",
                                            text: "text"
                                        }}
                                        selectedValue={this.state.selectedItem["@BridgeKeyID"]}
                                    />
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <ARMNarrowInput
                                        id="claim-file-mask"
                                        classNameValue="text-input"
                                        label={this.state.selectedItem["@ContentType"] !== '4' ? "*Claim File Mask:" : "*PAT File Mask:"}
                                        maxLength={256}
                                        initialValue={this.state.selectedItem["@FileMask"]}
                                        hasError={this.state.error && (this.state.selectedItem["@FileMask"] === '')}
                                        errorMessage={'File Mask must not be blank.'}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                            const selectedItem = this.state.selectedItem
                                            selectedItem["@FileMask"] = e.target.value
                                            this.setState({ selectedItem })
                                        }}
                                    />
                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <ARMNarrowCheck
                                        id="enable-batching"
                                        label='Enable Batching:'
                                        checked={this.state.selectedItem["@BatchFiles"] === 'Y'}
                                        onChangeSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const selectedItem = this.state.selectedItem
                                            selectedItem["@BatchFiles"] = e.target.checked ? 'Y' : 'N'
                                            this.setState({ selectedItem })
                                        }
                                        }
                                    />
                                </ContentRowWrapper>

                                {ContentFileMasks.map((item: any, i: any) => {
                                    return (
                                        <ContentRowWrapper>
                                            <ARMNarrowInput style={{ paddingLeft: '5px' }}
                                                id={i}
                                                inputWidth="300px"
                                                width="300px"
                                                className="text-input"
                                                label={item.label}
                                                maxLength={256}
                                                initialValue={item.Mask}
                                                hasError={this.state.error && item.Mask === '' && item.label.includes('*')}
                                                errorMessage={this.state.selectedItem['@ContentType'] !== '4' ? 'Please submit all additional files.' : 'Please submit all required files.'}
                                                onChange={(e: any) => {
                                                    const stringItem = JSON.stringify(this.state.selectedItem)
                                                    const selectedItem = JSON.parse(stringItem)
                                                    if (selectedItem['@ContentType'] !== '2') {
                                                        if (selectedItem.ContentFileMasks.ContentFileMask.length !== ContentFileMasks.length) {
                                                            ContentFileMasks.map((item: any, i: any) => {
                                                                const json = { '@ID': i, '@Mask': '' }
                                                                selectedItem.ContentFileMasks.ContentFileMask.push(json)
                                                            })
                                                        }
                                                        selectedItem.ContentFileMasks.ContentFileMask[i]['@ID'] = i
                                                        selectedItem.ContentFileMasks.ContentFileMask[i]['@Mask'] = e.target.value

                                                    }
                                                    else {
                                                        selectedItem.ContentFileMasks.ContentFileMask['@ID'] = i
                                                        selectedItem.ContentFileMasks.ContentFileMask['@Mask'] = e.target.value
                                                    }
                                                    this.setState({ selectedItem })
                                                }}
                                            />
                                        </ContentRowWrapper>
                                    )
                                })}
                            </ContentSectionWrapper>
                            <ContentRowWrapper>
                                <SelectButtonsInner>
                                    <Button
                                        domID="addButton"
                                        name={(this.state.selectedItem != undefined && this.state.selectedItem['@ID'] > 0) ? "Update" : "Add"}
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        disabled={!this.props.canEdit}
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                            this.onClickModifyAutoSubmitButton(ContentFileMasks, autoSubmitItems, fileType)
                                        }}
                                    />
                                    <Button
                                        domID="removeButton"
                                        name="Remove"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        disabled={!this.props.canSecCheck1 || (this.state.selectedItem != undefined && this.state.selectedItem['@ID'] === '0')}
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            this.setState({ removeItem: !this.state.removeItem })
                                        }}
                                    />
                                </SelectButtonsInner>
                            </ContentRowWrapper>
                            <DialogFieldset>
                                <DialogLegend>Schedule</DialogLegend>
                                <ContentRowWrapper id='schedule-list'>
                                    <ContentColumnWrapper id='new-schedule'>
                                        <SelectComponent
                                            title='scheduleIDs'
                                            size={5}
                                            width='285px'
                                            height='100%'
                                            selectedValue={this.state.selectedSchedulerItem['@ID']}
                                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                let searchResultsSelectedValue = scheduleItems.filter((selectedValue: any) => selectedValue["@ID"] == e.target.value)
                                                if (searchResultsSelectedValue !== undefined) {
                                                    this.setState({ selectedSchedulerItem: { ...searchResultsSelectedValue[0] } })
                                                }
                                            }}
                                            optionFields={{
                                                value: "@ID",
                                                text: "@Name"
                                            }}
                                            records={scheduleItems.filter((item: any) => item['@Delete'] !== true)}
                                        ></SelectComponent>
                                    </ContentColumnWrapper>
                                    <ContentColumnWrapper style={{ marginLeft: '23%' }}>
                                        <div style={{ marginBottom: '2%', marginRight: '7%' }}>
                                            <ContentRowWrapper >
                                                <ARMLabel style={{ marginBottom: '0%' }}>Days:</ARMLabel>
                                            </ContentRowWrapper >
                                            <ContentRowWrapper >
                                                <CheckBoxComponent
                                                    id="days-name-su"
                                                    labelAbove='Su'
                                                    checked={this.state.selectedSchedulerItem['@Sun'] === 'Y'}
                                                    width='5%'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const selectedItem = this.state.selectedSchedulerItem

                                                        selectedItem["@Sun"] = e.target.checked ? 'Y' : 'N'
                                                        this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                    }
                                                    }
                                                />
                                                <CheckBoxComponent
                                                    id="days-name-mo"
                                                    labelAbove='Mo'
                                                    checked={this.state.selectedSchedulerItem['@Mon'] === 'Y'}
                                                    width='5%'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const selectedItem = this.state.selectedSchedulerItem
                                                        selectedItem["@Mon"] = e.target.checked ? 'Y' : 'N'
                                                        this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                    }
                                                    }
                                                />
                                                <CheckBoxComponent
                                                    id="days-name-tu"
                                                    labelAbove='Tu'
                                                    checked={this.state.selectedSchedulerItem['@Tue'] === 'Y'}
                                                    width='5%'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const selectedItem = this.state.selectedSchedulerItem
                                                        selectedItem["@Tue"] = e.target.checked ? 'Y' : 'N'
                                                        this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                    }
                                                    }
                                                />
                                                <CheckBoxComponent
                                                    id="days-name-we"
                                                    labelAbove='We'
                                                    checked={this.state.selectedSchedulerItem['@Wed'] === 'Y'}
                                                    width='5%'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const selectedItem = this.state.selectedSchedulerItem
                                                        selectedItem["@Wed"] = e.target.checked ? 'Y' : 'N'
                                                        this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                    }
                                                    }
                                                />
                                                <CheckBoxComponent
                                                    id="days-name-th"
                                                    labelAbove='Th'
                                                    checked={this.state.selectedSchedulerItem['@Thu'] === 'Y'}
                                                    width='5%'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const selectedItem = this.state.selectedSchedulerItem

                                                        selectedItem["@Thu"] = e.target.checked ? 'Y' : 'N'
                                                        this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                    }
                                                    }
                                                />
                                                <CheckBoxComponent
                                                    id="days-name-fr"
                                                    labelAbove='Fr'
                                                    checked={this.state.selectedSchedulerItem['@Fri'] === 'Y'}
                                                    width='5%'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const selectedItem = this.state.selectedSchedulerItem

                                                        selectedItem["@Fri"] = e.target.checked ? 'Y' : 'N'
                                                        this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                    }
                                                    }
                                                />
                                                <CheckBoxComponent
                                                    id="days-name-sa"
                                                    labelAbove='Sa'
                                                    checked={this.state.selectedSchedulerItem['@Sat'] === 'Y'}
                                                    width='5%'
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const selectedItem = this.state.selectedSchedulerItem

                                                        selectedItem["@Sat"] = e.target.checked ? 'Y' : 'N'
                                                        this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                    }
                                                    }
                                                />
                                            </ContentRowWrapper>
                                        </div>
                                        <ContentRowWrapper >
                                            <SelectDropdownV2
                                                domID="from"
                                                className="dropdown"
                                                label="From:"
                                                isClearable={false}
                                                isSearchable={true}
                                                size='large'
                                                onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => {
                                                    const selectedItem = this.state.selectedSchedulerItem
                                                    selectedItem["@FromTime"] = e.value
                                                    this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                }
                                                }
                                                options={this.getFromToItem()}
                                                value={this.state.selectedSchedulerItem['@FromTime'] !== undefined && { label: this.state.selectedSchedulerItem['@FromTime'].substr(0, 5), value: this.state.selectedSchedulerItem['FromTime'] }}
                                                initialValue={this.state.selectedSchedulerItem['@FromTime'] !== undefined && { label: this.state.selectedSchedulerItem['@FromTime'].substr(0, 5), value: this.state.selectedSchedulerItem['FromTime'] }}
                                            />
                                            <SelectDropdownV2
                                                domID="from"
                                                className="dropdown"
                                                label="Through:"
                                                isClearable={false}
                                                isSearchable={true}
                                                size='large'
                                                onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => {
                                                    const selectedItem = this.state.selectedSchedulerItem

                                                    selectedItem["@ThruTime"] = e.value
                                                    this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                }
                                                }
                                                options={this.getFromToItem()}
                                                value={this.state.selectedSchedulerItem['@ThruTime'] !== undefined && { label: this.state.selectedSchedulerItem['@ThruTime'].substr(0, 5), value: this.state.selectedSchedulerItem['ThruTime'] }}
                                                initialValue={this.state.selectedSchedulerItem['@ThruTime'] !== undefined && { label: this.state.selectedSchedulerItem['@ThruTime'].substr(0, 5), value: this.state.selectedSchedulerItem['ThruTime'] }}
                                            />
                                        </ContentRowWrapper>

                                        <ContentRowWrapper >
                                            <ARMLabel style={{ marginTop: '6px', marginBottom: '0px' }}>Check status every (minutes)</ARMLabel>
                                        </ContentRowWrapper >
                                        <ContentRowWrapper >
                                            <SelectDropdownV2
                                                domID="from"
                                                className="dropdown"
                                                label=""
                                                isClearable={false}
                                                isSearchable={true}
                                                size='small'
                                                onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => {
                                                    const selectedItem = this.state.selectedSchedulerItem
                                                    selectedItem["@CheckInterval"] = e.value
                                                    this.setState({ selectedSchedulerItem: { ...selectedItem } })
                                                }
                                                }
                                                options={[
                                                    { label: '0', value: '0' },
                                                    { label: '.5', value: '-30' },
                                                    { label: '1', value: '1' },
                                                    { label: '5', value: '5' },
                                                    { label: '15', value: '15' },
                                                    { label: '30', value: '30' },
                                                    { label: '45', value: '45' },
                                                    { label: '60', value: '60' },
                                                ]}
                                                initialValue={{ label: this.state.selectedSchedulerItem["@CheckInterval"] === '-30' ? '.5' : this.state.selectedSchedulerItem["@CheckInterval"], value: this.state.selectedSchedulerItem["@CheckInterval"] }}
                                            />
                                        </ContentRowWrapper >
                                    </ContentColumnWrapper>
                                </ContentRowWrapper>

                                <ContentRowWrapper >
                                    <SelectButtons >
                                        <Button
                                            domID="addButton"
                                            name={(this.state.selectedItem != undefined && this.state.selectedSchedulerItem['@ID'] > 0) ? "Update" : "Add"}
                                            buttonType="standard"
                                            size="small"
                                            type="button"
                                            disabled={!this.props.canEdit || this.state.selectedItem['@ID'] === '0'}
                                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                const json = this.state.selectedSchedulerItem
                                                if (json['@Sun'] === 'N' && json['@Mon'] === 'N' && json['@Tue'] === 'N' && json['@Wed'] === 'N' && json['@Thu'] === 'N' && json['@Fri'] === 'N' && json['@Sat'] === 'N')
                                                    json['@Name'] = json['@FromTime'].substr(0, 5) + '-' + json['@ThruTime'].substr(0, 5) + ' No Days'
                                                else
                                                    json['@Name'] = json['@FromTime'].substr(0, 5) + '-' + json['@ThruTime'].substr(0, 5) + ' ' + (json['@Sun'] == 'Y' ? 'Su' : '') + (json['@Mon'] == 'Y' ? 'Mo' : '') + (json['@Tue'] == 'Y' ? 'Tu' : '') + (json['@Wed'] == 'Y' ? 'We' : '') + (json['@Thu'] == 'Y' ? 'Th' : '') + (json['@Fri'] == 'Y' ? 'Fr' : '') + (json['@Sat'] == 'Y' ? 'Sa' : '')

                                                let scheduleNoExisit = scheduleItems.filter((item: any) => item['@Name'] === json['@Name'] && item['@ID'] !== this.state.selectedSchedulerItem['@ID'] && item['@Delete'] !== true)
                                                if (scheduleNoExisit.length === 0) {
                                                    this.onClickModifyScheduleButton(scheduleItems)
                                                }
                                                else {
                                                    this.setState({ scheduleIdExist: true })
                                                }
                                            }}
                                        />
                                        <Button
                                            domID="removeButton"
                                            name="Remove"
                                            buttonType="standard"
                                            size="small"
                                            type="button"
                                            disabled={!this.props.canSecCheck1 || this.state.selectedSchedulerItem['@ID'] === '0'}
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                this.setState({ removeScheduleItem: !this.state.removeScheduleItem })
                                            }
                                            }
                                        />
                                    </SelectButtons>
                                </ContentRowWrapper>
                            </DialogFieldset>
                        </AutoSubmitMaintenanceRightContent>
                    </ContentRowWrapper>
                </ContentWrapper>

                <ModalConfirmation
                    isOpen={this.state.removeItem}

                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ removeItem: !this.state.removeItem })}
                    formattedMessage={(
                        <div>
                            <p>Do you want to remove {this.state.selectedItem['@Name']}? This action cannot be undone.</p>
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.onClickRemoveAutoSubmitButton()
                    }}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.setState({ removeItem: !this.state.removeItem })}
                />

                <ModalConfirmation
                    isOpen={this.state.removeScheduleItem}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ removeScheduleItem: !this.state.removeScheduleItem })}
                    formattedMessage={(
                        <div>
                            <p>Do you want to remove {this.state.selectedSchedulerItem['@Name']}? This action cannot be undone.</p>
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.onClickRemoveScheduleButton(scheduleItems)
                    }}
                />

                <ModalConfirmation
                    isOpen={this.state.scheduleIdExist}
                    alertMode
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ scheduleIdExist: !this.state.scheduleIdExist })}
                    formattedMessage={(
                        <div>
                            <p>The Scheduled Event you wish to add already exists.</p>
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.setState({ scheduleIdExist: !this.state.scheduleIdExist })}
                />
                <ModalConfirmation
                    isOpen={!this.state.bridgeModal && this.props.dataStore.crud.data !== undefined && !Array.isArray(this.props.dataStore.crud.data.AutoSubmitMaintenanceInfo.BridgeKeyList.BridgeKey)}
                    alertMode
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => {
                        this.props.history.push('/Administration/BridgeMaintenance');
                        this.setState({ bridgeModal: !this.state.bridgeModal })
                    }
                    }
                    formattedMessage={(
                        <div>
                            <p>You must Add a Bridge Key BEFORE you can Configure Agent. You will now be redirected to Bridge Maintenance.</p>
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.props.history.push('/Administration/BridgeMaintenance');
                        this.setState({ bridgeModal: !this.state.bridgeModal })

                    }}
                />

            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IAutoSubmitMaintenanceState, IAutoSubmitMaintenanceActionProps, IOwnProps, IAutoSubmitMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps('autoSubmitMaintenance'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(AutoSubmitMaintenance);

export default withRouter(connectedHoc);
