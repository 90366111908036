import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { SortHelper, ISortIndicator, SortDataType, SortOrder } from "@scripts/util/SortHelper";
import { URLs } from '@commonDevResources/constants';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';
import { DownloadDocumentPt2 } from '@commonResources/window';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import * as React from 'react';

import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components'
import { VictoryAxis, VictoryBar, VictoryChart, VictoryTheme, VictoryTooltip } from 'victory';
import { getRawToken } from '../../../../scripts/session/SecurityToken';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { actionCreators, IViewProductivityActionProps, IViewProductivityState } from '@store/ViewProductivity';
import ScrollToTop from '@common/ScrollToTop';
import { ProductivityBarDAL } from "./ProductivityBarDAL";


const GlobalStyle = createGlobalStyle`
  body {
    height: auto; 
  }
`

const CodeBlock = styled.div`
  ${Typography.ARMFontFamily};
  display: block;
  text-align: center;
  ${Typography.bold};
  white-space: pre;
  padding: 10px;
`;

const CodeNoData = styled.div`
  ${Typography.ARMFontFamily};
  display: block;
  text-align: center;
  ${Typography.bold};
  white-space: pre;
  padding: 100px;
  color:  ${Colors.errorRed};
`;

const CodeBlockSmall = styled.div`
 ${Typography.ARMFontFamily};
 ${Typography.bold};
 ${Typography.default}; 
 white-space: pre;
 padding: 5px;
 text-align: center;
`;

const CodeBlockVertical = styled.span`
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    padding-top: 440px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
`

const CodeBlockVerticalSmall = styled.span`
    ${Typography.ARMFontFamily};
    ${Typography.bold}
    ${Typography.default}; 
    padding-top: 35px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
`

export const ContentWrapper = styled.div`
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin:15px 25px;
      padding-top: 10px;
      padding-bottom: 100px;
`;

export const BarColumn = styled.div`
       display: flex;
       width:100%;
       padding:10px;
`
export const OneColumn = styled.div`
      display: flex;
      justify-content: center;
      width:100%;
`
export const UniqueColumn = styled.div`
      width:750px;
`
export const BarBox = styled.div`
    display:flex;
    width:auto;
    height:100% !important;
`

export const BarBoxSmall = styled.div`
    width: 600px;
    height: auto;
    display:flex;
    justify-content: flex-end;
`

export const BarBoxLeft = styled.div`
    width: 800px;
    height: auto;
    display:flex;
    justify-content: flex-end;
`

export const TwoColumn = styled.div`
    display:flex;
    flex-direction: column; 
`
export const RightContainer = styled.div`
    width:800px;
    height: auto;
    border: 10px solid;
    border-color: ${Colors.middlestGrey};
    display: flex;
    justify-content: center; 
`
export const InsideRightContainer = styled.div`
    text-align: center;
`

export const LefContainer = styled.div`
    margin: 5px;
    padding-top: 20px; 
`
export const InsideLeftContainer = styled.div`
    text-align: center;
`
export const TableContainer = styled.div`
    width: 950px;
    height: auto;
    margin: 5px; 
`

export const ChangeTable = styled.div`
    height: auto;
    padding-bottom: 20px;
    
`
export const InsideTable = styled.span`
  
`

export const LinkSpan = styled.span`
   cursor:pointer;
   color:${Colors.chBlue70};
   text-decoration:underline;
`

export const BlackLine = styled.div`
    background:${Colors.black};
    height 2px; 
    width: auto;
`
export const AlignedTd = styled.td`
    vertical-align: middle
`

const ProductivityCellGrid = styled(ARMGrid)`

    && table {
        border-spacing: 0;
        border: 1px ${Colors.grey30};
      }
 
    && thead {
         padding-right: 0px;
         border: 1px ${Colors.grey30};
      }

    && th {
         background: ${Colors.grey20};
      }
  
    && tr {
         & button {
            color:  ${Colors.chBlue70};
        }
`


export const BackButton = styled.button`
    
    margin-top: 40px;
    margin-left: 40px;
    height: 30px;
   
    background: ${Colors.brightBlue115};
    color: ${Colors.white};
    ${Typography.ARMFontFamily};
    ${Typography.bold}
    ${Typography.default};
    border: none;
    padding: 5px 10px;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    position: relative;
    border-radius: 0 5px 5px 0;

    text-transform: uppercase;
    cursor: pointer;

     &:hover {
        opacity: 0.8;
     }

    &:before {
        content: '';
        position: absolute;
        left: -8px;
        top: 0px;
        width: 0;
        height: 0;
        border-right: 8px solid ${Colors.brightBlue115};
        border-top: 15px solid transparent;
        border-bottom: 15px solid transparent;
    }

    &:disabled {
        opacity: 0.1;
        cursor: pointer;
    }
  
`


/**
 * TYPES
 */

type Export = {
    export: boolean;
}

type User = number;

type Filter = {
    fromDate: string;
    taskId: number;
    tasks: number[];
    thruDate: string;
    users: User[];
    user: User;
    workDate?: string;
}

type ChangesFilter = Filter & {
    claimId: string;
    changesUser: string;
};

type PatientFilter = Filter & {
    cfi: boolean;
    claimId: string;
}

type PatientName = {
    filter: PatientFilter;
    text: string;
}

type DisplayLink = {
    text: string;
}

type ClaimRecord = {
    ctrlNo: string;
    displayClaimChangesLink: null | DisplayLink;
    medRecNo: string;
    patientName: PatientName;
    payer: string;
    status: string;
    totalAmt: string | number;
    typeOfBill: string;
}

type MappedPatient = ClaimRecord & {
    name: string;
    idClaim: string;
};

type CurrencyClaim = ClaimRecord & {
    totalAmt: number;
};

type ClaimLink = ClaimRecord & {
    linkChangeText: string;
};

type ClaimFilter = {
    filter: Filter & Export;
}

type ExcelExport = {
    filter: ClaimFilter
    text: string;
}

type SupData = {
    count: number;
    id: string;
    labelText: string;
    toolTip: string;
}

type GraphProps = {
    title: string;
    leftTitle: string;
    bottomTitle: string;
}

type TasksData = Filter & SupData;

type Tasks = GraphProps & {
    data: TasksData[];
};


type UsersData = Filter & SupData;

type Users = GraphProps & {
    data: UsersData[];
};

type DaysData = Filter & SupData;

type Days = GraphProps & {
    data: DaysData[];
};

type TitleCaption = {
    title: string;
    topCaption: string;
}

type Claims = TitleCaption & {
    data: ClaimRecord[];
    exportToExcel: ExcelExport;
};

type ViewAllLink = {
    text: string;
    filter: ChangesFilter;

}

type ChangeDataObject = TitleCaption & {
    fieldDesc: string;
    fieldIndex: string;
    prevData: string;
    currData: string;
    userName: string;
    stampDate: string;
    stampTime: string;

}


type RemoveChangesObject = {
    text: string;
    filter: ChangesFilter;
}

type Changes = {
    title: string;
    data: ChangeDataObject[];
    userName: string;
    topCaption: string;
    viewAllLink: ViewAllLink;
    removeChangesLink: RemoveChangesObject;
}

export type GraphData = {
    tasks: Tasks;
    users?: Users;
    days?: Days;
    claims?: Claims;
    changes?: Changes;
}

export type TasksPayload = Filter | ClaimFilter | ChangesFilter;


/**
 * INTERFACES
 */

interface HeaderProps {
    columns: {
    }
}

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
};


interface IComponentState {

    oneColumn: boolean;
    twoColumn: boolean;

    leftUsersChart: boolean;
    leftDaysChart: boolean;

    rightUsersChart: boolean;
    rightDaysChart: boolean;

    leftTable: boolean;
    dayAndHour: string;

    todaysDate: string;
    previousDate: string;

    selectedTask: number | null | undefined;
    selectedUser: number | null | undefined;
    selectedDate: number | null | undefined;

    dataClaimsRecords: any[];
    tasksHistory: GraphData[];
    dataChangeRecords: ChangeDataObject[] | null;

    tasksPayload: Filter | ChangesFilter | ClaimFilter | null | undefined;
    excelPayload: ClaimFilter | null | undefined;

    cfiPayload: null;
    viewChanges: boolean;
    changesPayload: ChangesFilter | null;

    sortIndicator: ISortIndicator;
    selectedSortDataType: SortDataType;
    selectedColumnName: string;

    dataFirstGraph: Tasks | null;
    dataChanges: Changes | null | undefined;
    dataUsers: Users | null | undefined;
    dataDays: Days | null | undefined;
    dataClaims: Claims | null | undefined;
}

interface RowProps {
    record: {
        name: string,
        ctrlNo: number,
        medRecNo: string,
        typeOfBill: string,
        status: string,
        payer: string,
        totalAmt: string,
        linkChangeText: string,
        index: number,
    }
};


export const DEFAULT_STATE: IComponentState = {
    oneColumn: true,
    twoColumn: false,
    leftUsersChart: true,
    leftDaysChart: false,
    rightDaysChart: false,
    rightUsersChart: false,
    leftTable: false,
    dayAndHour: "",
    todaysDate: "",
    previousDate: "",
    selectedTask: null,
    selectedUser: null,
    selectedDate: null,
    dataFirstGraph: null,
    dataUsers: null,
    dataDays: null,
    dataClaims: null,
    dataClaimsRecords: [],
    dataChangeRecords: null,
    tasksPayload: null,
    tasksHistory: [],
    excelPayload: null,
    cfiPayload: null,
    viewChanges: false,
    changesPayload: null,
    dataChanges: null,
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
        sortDataType: SortDataType.String
    },
    selectedSortDataType: SortDataType.String,
    selectedColumnName: 'name'
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IViewProductivityProps = IMergeCrudComponentProps<IViewProductivityState, IViewProductivityActionProps, IOwnProps>;

const allDisplayedUserTasksValues: number[] = [4, 32, 2, 16, 8, 1];

const displayedUserTasksValues: any = {
    all: false,
    printedClaims: 4,
    deletedClaims: 32,
    changedClaims: 2,
    viewedClaims: 16,
    validatedClaims: 8,
    createdClaims: 1
}


export class ProductivityBar extends React.Component<IViewProductivityProps, IComponentState, IOwnProps> {


    productivityBarDAL: ProductivityBarDAL = new ProductivityBarDAL();

    columnNameMap = [
        { value: 'name', text: 'Patient Name', sortDataType: SortDataType.String },
        { value: 'ctrlNo', text: 'Control Number', sortDataType: SortDataType.Number },
        { value: 'medRecNo', text: 'MedRe#', sortDataType: SortDataType.Number },
        { value: 'typeOfBill', text: 'TOB', sortDataType: SortDataType.String },
        { value: 'status', text: 'Status', sortDataType: SortDataType.String },
        { value: 'payer', text: 'Payer', sortDataType: SortDataType.String },
        { value: 'totalAmt', text: 'Total Amt', sortDataType: SortDataType.Number },
    ]

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Productivity Bar"
    }

    constructor(props: IViewProductivityProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.handleSorting = this.handleSorting.bind(this);
    }

    public componentDidMount() {
        let payload; 
        if (Object.keys(this.props.dataStore.ui.resultObj).length > 0) {
            if (this.props.location.state === 'work_productivity') {
                const { resultObj  } = this.props.dataStore.ui; 
                payload = { users: [resultObj.UserId], thruDate: resultObj.ThruDate, fromDate: resultObj.FromDate, tasks: [4, 32, 2, 16, 8, 1] }
            }
            // We reach out to ViewProductivity's store to get the slice of data we need to stand up the page
            else  payload = this.props.dataStore.ui.resultObj.filter
            //const payloadHistory = [...this.state.tasksHistory, payload]

            //console.log("payload componentDidMount", payload)
            this.setState({
                tasksPayload: payload,
                //tasksHistory: payloadHistory
            });
        }
        // Otherwise, if the store data is not available, it means the page has been torn down 
        // So we fetch the data object from SessionStorage
        else {
            const lastPayload = sessionStorage.getItem('tasksPayload');
            const historyPayload = sessionStorage.getItem('tasksHistory');

            if (lastPayload) {
                const task = JSON.parse(lastPayload) as Filter;
                const history = JSON.parse(historyPayload as string);

                this.setState({
                    tasksPayload: task,
                    tasksHistory: history
                });
            }
        }

        this.getDayAndHour();

    }

    public componentWillUnmount() {
        // Save filter to localStorage before context is destroyed
        const { tasksPayload, tasksHistory } = this.state;
        sessionStorage.setItem('tasksPayload', JSON.stringify(tasksPayload));
        sessionStorage.setItem('tasksHistory', JSON.stringify(tasksHistory));
    }

    public getDayAndHour = async () => {

        let datetime = new Date().toLocaleString();
        let date = new Date().toLocaleString().split(",");

        var datePreviousMonth = new Date();

        datePreviousMonth.setDate(datePreviousMonth.getDate() - 30);

        let previousDate = datePreviousMonth.toLocaleDateString();


        await this.setState({
            dayAndHour: datetime,
            todaysDate: date[0],
            previousDate: previousDate
        }, () => {
            if (this.state.tasksPayload != null) {
                this.getDataforGraphs();
            }
        })
        return datetime
    }


    public getDataforGraphs = async () => {
        console.log(this.props, 'props')
        let payload = this.state.tasksPayload;
        console.log(this.state, 'STATE')
        /*
        if (sessionStorage.getItem('tasksPayload')) {
            console.log("We're coming back from CFI");
            payload = null;
            this.getDataFromHistory();
        }
        */

        if (payload) {
            this.productivityBarDAL.getDataForGraph(payload)
                .then((response) => {
                    if (response) {

                        const data: GraphData = response;

                        const { tasks, days, users, claims, changes } = response;
                        console.log(tasks, days, users, claims, changes, 'response')
                        if (claims) {

                            let newClaimsData = claims.data.map((record: ClaimRecord) => ({
                                name: record.patientName.text,
                                idClaim: record.patientName.filter.claimId,
                                ...record
                            }));

                            let newClaimsDataParseNames = newClaimsData.map((patient: MappedPatient) => {
                                if (patient.name === ", ") {
                                    return { ...patient, name: "N/A" };
                                } else {
                                    return patient;
                                }
                            })

                            let newClaimsWithCurrencyFormat: CurrencyClaim[] = newClaimsDataParseNames.map((record: ClaimRecord) => {

                                let numberToFormat = (record.totalAmt as string).replace(/[^0-9.]/g, '');
                                let formatedAmount = parseFloat(numberToFormat);

                                return {
                                    ...record,
                                    totalAmt: formatedAmount,
                                }
                            })

                            this.setState({
                                dataClaimsRecords: newClaimsWithCurrencyFormat
                            })


                            if (claims.data[0].displayClaimChangesLink != null || claims.data[0].displayClaimChangesLink != undefined) {


                                let newClaimsDataWithLink: ClaimLink[] = newClaimsWithCurrencyFormat.map((person: ClaimRecord) => {
                                    let linkText = person.displayClaimChangesLink ? person.displayClaimChangesLink.text : '';
                                    return {
                                        ...person,
                                        linkChangeText: linkText
                                    }
                                });

                                this.setState({
                                    dataClaimsRecords: newClaimsDataWithLink
                                })
                            }

                        }


                        if (changes) {
                            let dataChangeRecords: ChangeDataObject[] = changes.data;

                            this.setState({
                                dataChangeRecords
                            })
                        }

                        const payloadHistory = [...this.state.tasksHistory, data];
                        this.setState({
                            dataFirstGraph: tasks,
                            dataUsers: users,
                            dataDays: days,
                            dataClaims: claims,
                            dataChanges: changes,
                            viewChanges: false,
                            tasksHistory: payloadHistory,
                        });

                        //console.log("this state:", this.state)

                    }
                })
        }
    }

    getDataforChange = async () => {
        var url = URLs.api + '/api/data/dashboard/productivity';

        let payload = this.state.tasksPayload;

        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(payload)
        })

            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    let responseData: string = JSON.stringify(data);
                    const responseDataParse = JSON.parse(responseData);

                    const { tasks, days, users, claims, changes } = responseDataParse;


                    if (claims) {

                        let newClaimsData = claims.data.map((person: ClaimRecord) => ({
                            name: person.patientName.text,
                            idClaim: person.patientName.filter.claimId,
                            ...person
                        }));


                        let newClaimsDataCheckNames = newClaimsData.map((person: MappedPatient) => {
                            if (person.name === ", ") {
                                return { ...person, name: "N/A" }
                            } else {
                                return person
                            }
                        })


                        let newClaimsWithCurrencyFormat: CurrencyClaim[] = newClaimsDataCheckNames.map((person: ClaimRecord) => {
                            // Strip string currency representation of all commas
                            let cleanString = (person.totalAmt as string).replace(/[^0-9.]/g, '');
                            let formatedAmount = parseFloat(cleanString);
                            return {
                                ...person,
                                totalAmt: formatedAmount,
                                ctrlNo: parseInt(person.ctrlNo) || 0,
                                medRecNo: parseInt(person.medRecNo) || 0,
                            }
                        })

                        this.setState({
                            dataClaimsRecords: newClaimsWithCurrencyFormat
                        })

                        if (claims.data[0].displayClaimChangesLink != null || claims.data[0].displayClaimChangesLink != undefined) {


                            let newClaimsDataWithLink: ClaimLink[] = newClaimsWithCurrencyFormat.map((person: ClaimRecord) => {
                                let linkText = person.displayClaimChangesLink ? person.displayClaimChangesLink.text : '';
                                return {
                                    ...person,
                                    linkChangeText: linkText
                                }
                            });

                            this.setState({
                                dataClaimsRecords: newClaimsDataWithLink
                            })
                        }

                    }

                    if (changes) {
                        let dataChangeRecords = changes.data;

                        this.setState({
                            dataChangeRecords: dataChangeRecords
                        })
                    }

                    const payloadHistory: any = [...this.state.tasksHistory, responseDataParse]
                    this.setState({
                        dataFirstGraph: tasks,
                        dataUsers: users,
                        dataDays: days,
                        dataClaims: claims,
                        dataChanges: changes,
                        viewChanges: true,
                        tasksHistory: payloadHistory
                    });

                    //console.log("this state: line 681", this.state)
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    }


    public getExcel = async () => {
        var url = URLs.api + '/api/data/dashboard/productivity';

        let payload = this.state.excelPayload;

        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(payload)
        })

            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    let responseData: string = JSON.stringify(data);
                    const responseDataParse = JSON.parse(responseData);

                    if (responseDataParse) {
                        DownloadDocumentPt2(`/${responseDataParse.exportFilePath}`);
                    }
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    }


    public getCFI = async (data: any) => {
        var url = URLs.api + '/api/data/dashboard/productivity';

        const getFilter = this.state.dataClaimsRecords.filter(person => person.idClaim === data.id);

        let payload = getFilter[0].patientName.filter;

        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(payload)
        })

            .then(async response => {
                if (response.status === 200) {
                    const data: any = await response.json();
                    let responseData: string = JSON.stringify(data);
                    const responseDataParse = JSON.parse(responseData);



                    if (responseDataParse) {

                        this.redirect(responseDataParse.redirectUrl + "?cfiReturnInfo=/Dashboard/productivityBar")
                    }
                }
            })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
            });
    }

    public redirect = (link: string) => {
        let url = link;

        setTimeout(() => {
            this.props.history.push(url)
        }, 200)
    }

    public getDataFromHistory = () => {

        // Return the last element from the history array and shorten array by one element

        //console.log("Getting data from history...");
        const { tasksHistory } = this.state;

        const history: GraphData = tasksHistory.slice(-1)[0];

        //console.log("line 911: ", { history})

        const truncatedTasksHistory = tasksHistory.slice(0, -1);

        //console.log("Truncated history", truncatedTasksHistory);

        this.setState({ tasksHistory: truncatedTasksHistory }, () => {
            if (history) {

                const { tasks, days, users, claims, changes } = history;

                if (claims) {

                    let newClaimsData = claims.data.map((record: ClaimRecord) => ({
                        name: record.patientName.text,
                        idClaim: record.patientName.filter.claimId,
                        ...record
                    }));

                    let newClaimsDataParseNames = newClaimsData.map((patient: MappedPatient) => {
                        if (patient.name === ", ") {
                            return { ...patient, name: "N/A" };
                        } else {
                            return patient;
                        }
                    })

                    let newClaimsWithCurrencyFormat: CurrencyClaim[] = newClaimsDataParseNames.map((record: ClaimRecord) => {

                        let numberToFormat = (record.totalAmt as string).replace(/[^0-9.]/g, '');
                        let formatedAmount = parseFloat(numberToFormat);

                        return {
                            ...record,
                            totalAmt: formatedAmount,
                        }
                    })

                    this.setState({
                        dataClaimsRecords: newClaimsWithCurrencyFormat
                    })


                    if (claims.data[0].displayClaimChangesLink != null || claims.data[0].displayClaimChangesLink != undefined) {


                        let newClaimsDataWithLink: ClaimLink[] = newClaimsWithCurrencyFormat.map((person: ClaimRecord) => {
                            let linkText = person.displayClaimChangesLink ? person.displayClaimChangesLink.text : '';
                            return {
                                ...person,
                                linkChangeText: linkText
                            }
                        });

                        this.setState({
                            dataClaimsRecords: newClaimsDataWithLink
                        })
                    }

                }

                if (changes) {
                    let dataChangeRecords: ChangeDataObject[] = changes.data;

                    this.setState({
                        dataChangeRecords
                    })
                }

                this.setState({
                    dataFirstGraph: tasks,
                    dataUsers: users,
                    dataDays: days,
                    dataClaims: claims,
                    dataChanges: changes,
                    viewChanges: false,
                });

                console.log("Back button final state:", this.state)

            } else {
                const url = this.props.location.state === 'work_productivity' ? '/LandingPage' : '/Dashboard/productivity_selection'
                this.props.history.push(url);
            }
        })



    }

    public clickHandler = (event: React.SyntheticEvent<any>, props: any) => {

        //console.log("Click handler passed in props:", props);

        this.setState({
            tasksPayload: props.datum.filter
        }, () => {
            this.getDataforGraphs();
        })
    };

    public handleCFI = (event: React.MouseEvent<HTMLElement>, data: any) => {

        this.getCFI(data);

    };

    public handleChanges = (event: React.MouseEvent<HTMLElement>, data: any) => {


        const getFilter = this.state.dataClaimsRecords.filter(person => person.idClaim === data.id);
        const changesPayload = getFilter[0].displayClaimChangesLink.filter;


        this.setState({
            viewChanges: true,
            tasksPayload: changesPayload
        }, () => {
            this.getDataforChange();
        })

    };

    public removeChanges = (event: React.MouseEvent) => {


        const removeFilter: ChangesFilter | undefined = this.state.dataChanges?.removeChangesLink.filter;

        this.setState({
            tasksPayload: removeFilter
        }, () => {
            this.getDataforGraphs();
        })
    }

    public viewAllChanges = (event: React.MouseEvent) => {

        const viewAllFilter = this.state.dataChanges?.viewAllLink.filter;

        this.setState({
            viewChanges: true,
            tasksPayload: viewAllFilter
        }, () => {
            this.getDataforChange();
        })
    }

    public exportToExcel = (event: React.MouseEvent) => {

        const exportToExcel = this.state.dataClaims?.exportToExcel.filter;


        this.setState({
            excelPayload: exportToExcel
        }, () => {
            this.getExcel();
        })
    }


    public CONFIGChange = {
        records: [
            {
                fieldDesc: "Patient_LastName (10282, P_LNAME)",
                fieldIndex: '0',
                prevData: 'patient 1',
                currData: "DOE",
                userName: "dlfrisbie",
                stampDate: "08/03/2021",
                stampTime: "08:29:55"
            },
            {
                fieldDesc: "Patient_LastName (10282, P_LNAME)",
                fieldIndex: '0',
                prevData: 'patient 1',
                currData: "DOE",
                userName: "dlfrisbie",
                stampDate: "08/03/2021",
                stampTime: "08:29:55"
            }
        ],
        columns: new Set([
            {
                dataName: 'fieldDesc',
                text: 'Field Desc',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'fieldIndex',
                text: 'Field Index',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'prevData',
                text: 'Previous Data',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'currData',
                text: 'Current Data',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'userName',
                text: 'User',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'stampDate',
                text: 'Date',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'stampTime',
                text: 'Time',
                sortable: true,
                isSorted: 0,
            },

        ]),
        selectionKey: 'caseId',
        domID: 'test-id',
        dataTestId: 'test-gridFixed',
        selectAllCheckboxDomID: 'select-all-test-id'
    };

    public handleSorting(sortIndicator: ISortIndicator) {

        console.log(sortIndicator)
    }

    public getRecords = () => {

        return this.state.dataClaimsRecords

            .map((record: any, index: number) => {

                return {
                    id: record.idClaim,
                    name: record.name,
                    ctrlNo: record.ctrlNo,
                    medRecNo: record.medRecNo,
                    typeOfBill: record.typeOfBill,
                    status: record.status,
                    payer: record.payer,
                    totalAmt: record.totalAmt,
                    linkChangeText: record.linkChangeText,
                    index: index,
                }
            })
            .filter((item): item is any => !!item)

    }

    public getGridColumnHeaders = () => {

        return new Set([
            {
                dataName: 'name',
                text: 'Patient Name',
                sortable: true,
                isSorted: 0,

            },
            {
                dataName: 'ctrlNo',
                text: 'Ctrl#',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'medRecNo',
                text: 'MedRec#',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'typeOfBill',
                text: 'TOB',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'status',
                text: 'Status',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'payer',
                text: 'Payer',
                sortable: true,
                isSorted: 0,
            },
            {
                dataName: 'totalAmt',
                text: 'Total Amt',
                sortable: true,
                isSorted: 0,
            },

        ]);

    }

    public getColumnNameOptions() {
        let columnNameOptions: { value: string, text: string }[] = [];
        this.columnNameMap.forEach(x => {
            columnNameOptions.push({
                value: x.value,
                text: x.text
            })
        });
        return columnNameOptions;
    }

    public displaySelectedColumn(event: React.ChangeEvent<HTMLSelectElement>) {
        const selectedSortDataType = this.getSelectedSortDataType(event.target.value);

        this.setState({
            selectedColumnName: event.target.value,
            selectedSortDataType
        });
    };

    public getSelectedSortDataType(columnName: string) {
        const selectedColumnMap = this.columnNameMap.find(x => x.value === columnName);
        return selectedColumnMap?.sortDataType || SortDataType.String;
    }

    public getCustomHeader() {
        const {
            sortIndicator,
            selectedSortDataType
        } = this.state;

        const columnNames = this.getColumnNameOptions();
        const options = columnNames.map(x => <option key={x.value} value={x.value}>
            {x.text}
        </option>);

        const ddlColumnNames = <select value={this.state.selectedColumnName}
            onChange={x => { this.displaySelectedColumn(x) }}>{options}</select>

        const header = ({ columns }: HeaderProps) => <thead><tr key={Math.random()}>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="patientName"
                        text="Patient name"
                        cellType="text"
                        sortable={true}
                        // choose from String, Number or Date
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="controlNo"
                        text="Control no"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="totalCharges"
                        text="Total charges"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Number}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="typeOfBill"
                        text="TOB"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="formName"
                        text="Form"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName={this.state.selectedColumnName}
                        text="Payer name"
                        cellType="select"
                        sortable={true}
                        sortDataType={selectedSortDataType}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        select={ddlColumnNames}
                    />
                </React.Fragment>
            }
        </tr></thead>

        return header;
    }

    public getCustomRow = () => {

        const row = ({ record }: RowProps) =>

            <tr id={`${record.index}`} style={{ padding: '5px' }}>
                <React.Fragment>

                    <td style={{ verticalAlign: 'middle' }}>
                        <LinkSpan
                            id={`${record.index}-name`}

                            onClick={(event: React.MouseEvent<HTMLElement>) => this.handleCFI(event, record)}
                        >
                            {record.name}
                        </LinkSpan>
                    </td>

                    <td id={`${record.index}-ctrlNo`} style={{ verticalAlign: 'middle' }}>
                        <InsideTable>{record.ctrlNo}</InsideTable>
                    </td>

                    <td id={`${record.index}-medRecNo`} style={{ verticalAlign: 'middle' }}>
                        <InsideTable>{record.medRecNo}</InsideTable>
                    </td>

                    <td id={`${record.index}-typeOfBill`} style={{ verticalAlign: 'middle' }}>
                        {record.typeOfBill}
                    </td>

                    <td id={`${record.index}-status`} style={{ verticalAlign: 'middle' }}>
                        {record.status}
                    </td>

                    <td id={`${record.index}-payer`} style={{ verticalAlign: 'middle' }}>
                        {record.payer}
                    </td>

                    <td id={`${record.index}-totalAmt`} style={{ textAlign: 'right', verticalAlign: 'middle' }}>
                        <InsideTable>{`${parseFloat(record.totalAmt)
                            .toLocaleString('en-US',
                                {
                                    style: 'currency',
                                    currency: 'USD'
                                }
                            )}`}</InsideTable>
                    </td>
                    <td id={`${record.index}-linkChangeText`}
                        style={{ borderLeft: 2, borderColor: `${Colors.black}`, verticalAlign: 'middle' }}
                    >
                        <LinkSpan

                            onClick={(event: React.MouseEvent<HTMLElement>) => this.handleChanges(event, record)}
                        >
                            {record.linkChangeText}
                        </LinkSpan>
                    </td>
                </React.Fragment>
            </tr>
        return row;
    }


    render() {

        console.log("this.state.dataFirstGraph", this.state.dataFirstGraph);
        let alternativeTitle = `Work Productivity from ${this.state.previousDate} to ${this.state.todaysDate}\n ${this.state.dayAndHour}`;

        let { title, data, bottomTitle, leftTitle } = this.state.dataFirstGraph || {};

        let isCount: string[] = [];
        data?.forEach(function (item) {
            if (item.count == 0)
                isCount.push(item.id)
        })

        let divEnable = true;
        if (data?.length === isCount.length) {
            divEnable = false;
        }

        let newTitle = (title === undefined || title === null) ? alternativeTitle : title;

        let { title: userTitle, data: userData, bottomTitle: bottomTitleUser, leftTitle: leftTitleUser } = this.state.dataUsers || {};

        let domainTaskData = 25

        let userBarWidth;
        let domainPaddingUser = 10
        if (userData != null && userData != undefined) {

            const refineUserData = userData.filter(user => user.count > 0);

            if (refineUserData.length === 0 || refineUserData.length === 1) {
                userBarWidth = 180
                domainPaddingUser = 60
            }
            if (refineUserData.length === 2) {
                userBarWidth = 110
                domainPaddingUser = 60
            }
            if (refineUserData.length > 2 && refineUserData.length < 5) {
                userBarWidth = 50
                domainPaddingUser = 30
            }
            if (refineUserData.length >= 5 && refineUserData.length < 8) {
                userBarWidth = 30
                domainPaddingUser = 25
            }
            if (refineUserData.length >= 8 && refineUserData.length < 11) {
                userBarWidth = 20
                domainPaddingUser = 20
            }
            if (refineUserData.length >= 11 && refineUserData.length < 15) {
                userBarWidth = 15
            }
            if (refineUserData.length >= 15 && refineUserData.length < 20) {
                userBarWidth = 11
            }
            if (refineUserData.length >= 20) {
                userBarWidth = 8
            }
        };

        let { title: dayTitle, data: dayData, bottomTitle: bottomTitleDay, leftTitle: leftTitleDay } = this.state.dataDays || {};

        let dayBarWidth;
        let domainPaddingDay = 10

        if (dayData != null && dayData != undefined) {
            if (dayData.length === 0 || dayData.length === 1) {
                dayBarWidth = 180
                domainPaddingDay = 60
            }
            if (dayData.length === 2) {
                dayBarWidth = 110
                domainPaddingDay = 60
            }
            if (dayData.length > 1 && dayData.length < 5) {
                dayBarWidth = 50
                domainPaddingDay = 30
            }
            if (dayData.length >= 5 && dayData.length < 8) {
                dayBarWidth = 20
                domainPaddingDay = 25
            }
            if (dayData.length >= 8 && dayData.length < 11) {
                dayBarWidth = 30
                domainPaddingDay = 20
            }
            if (dayData.length >= 11 && dayData.length < 15) {
                dayBarWidth = 15
            }
            if (dayData.length >= 15 && dayData.length < 20) {
                dayBarWidth = 11
            }
            if (dayData.length >= 20) {
                dayBarWidth = 8
            }
        };

        let { title: claimTitle, data: claimData, topCaption } = this.state.dataClaims || {};

        let { title: changesTitle, topCaption: topCaptionChanges, userName, removeChangesLink, viewAllLink } = this.state.dataChanges || {};


        let removeChangesLinkText = (this.state.dataChanges === null || this.state.dataChanges === undefined) ? "..." : removeChangesLink?.text;


        let regardlessTitle: string | null = "...";

        if (this.state.dataChanges != null && this.state.dataChanges != undefined) {
            if (this.state.dataChanges.viewAllLink != null) {

                regardlessTitle = this.state.dataChanges.viewAllLink.text;
            } else {

                regardlessTitle = null;
            }
        }

        let changeRecords = (this.state.dataChangeRecords === null || this.state.dataChangeRecords === undefined) ? [] :

            this.state.dataChangeRecords.map((record: any, index: number) => {

                return {
                    currData: record.currData,
                    fieldDesc: record.fieldDesc,
                    fieldIndex: record.fieldIndex,
                    prevData: record.prevData,
                    stampDate: record.stampDate,
                    stampTime: record.stampTime,
                    userName: record.userName,
                    index: index,
                }
            });

        let exportText = (this.state.dataClaims === null || this.state.dataClaims === undefined) ? "" : this.state.dataClaims.exportToExcel.text;

        const oneColumn = this.state.dataUsers === undefined;
        const twoColumn = this.state.dataUsers != undefined;
        const leftUsersChart = this.state.dataUsers != undefined && this.state.dataDays === undefined && this.state.dataClaims === undefined;
        const leftDaysChart = this.state.dataUsers != undefined && this.state.dataDays != undefined && this.state.dataClaims === undefined;
        const leftTable = this.state.dataUsers != undefined && this.state.dataDays != undefined && this.state.dataClaims != undefined;
        const rightUsersChart = this.state.dataUsers != undefined && this.state.dataDays != undefined;
        const rightDaysChart = this.state.dataUsers != undefined && this.state.dataDays != undefined && this.state.dataClaims != undefined;
        const viewChanges = this.state.viewChanges;

        return (
            <React.Fragment>
                <GlobalStyle />
                <ScrollToTop />
                <BackButton
                    id="prodBar-But"
                    onClick={this.getDataFromHistory}
                >Back</BackButton>
                <ContentWrapper id="contentWrapper">
                    {oneColumn &&
                        <OneColumn>
                            <UniqueColumn>
                                <CodeBlock>{newTitle}</CodeBlock>
                                {divEnable &&
                                    <BarBox >
                                        <CodeBlockVertical>
                                            {leftTitle}
                                        </CodeBlockVertical>
                                        <VictoryChart
                                            // adding the material theme provided with Victory
                                            theme={VictoryTheme.material}
                                            domainPadding={domainTaskData}
                                            width={400}

                                            padding={{ left: 90, top: 0, bottom: 80, right: 20 }}

                                            style={{
                                                parent: {
                                                    border: 'none',
                                                    backgroundColor: `${Colors.white}`,
                                                    height: '100%'
                                                }
                                            }}

                                            events={[
                                                {
                                                    childName: 'all',
                                                    target: 'data',
                                                    eventHandlers: {
                                                        onClick: this.clickHandler,
                                                    },
                                                },
                                            ]}
                                        >
                                            <VictoryAxis
                                                style={{
                                                    axis: { stroke: `${Colors.black}` },
                                                    tickLabels: {
                                                        fontSize: '9px',
                                                        angle: -45,
                                                        padding: 5,
                                                        textAnchor: 'end',
                                                        fill: `${Colors.black}`,

                                                    },
                                                    ticks: {
                                                        stroke: `${Colors.black}`
                                                    },
                                                }}
                                            />
                                            <VictoryAxis
                                                style={{
                                                    axis: { stroke: `${Colors.black}` },
                                                    grid: { stroke: `${Colors.black}`, strokeWidth: 0.8 },
                                                    tickLabels: { fontSize: '9px', fill: `${Colors.black}` },
                                                    ticks: { stroke: `${Colors.black}` },
                                                }}
                                                dependentAxis
                                            />

                                            <VictoryBar
                                                data={data}
                                                style={{ data: { fill: `${Colors.blueGraph}`, width: 40 } }}
                                                x="labelText"
                                                y="count"
                                                labels={({ datum }) => `${datum.toolTip}`}

                                                labelComponent={
                                                    <VictoryTooltip
                                                        flyoutWidth={120}
                                                        flyoutHeight={25}
                                                        cornerRadius={0}
                                                        pointerLength={20}
                                                        flyoutStyle={{
                                                            stroke: "#838383",
                                                            strokeWidth: 2,
                                                            fill: "#FFFFFF"
                                                        }}
                                                        style={{
                                                            fill: "#838383",
                                                            fontSize: 8,
                                                            fontWeight: 500,
                                                            textAnchor: "middle"
                                                        }}
                                                    />
                                                }

                                            />

                                        </VictoryChart>
                                    </BarBox>
                                }
                                {divEnable &&
                                    <CodeBlock>
                                        <p style={{ fontWeight: 600 }}>{bottomTitle}</p>
                                    </CodeBlock>
                                }{!divEnable &&
                                    <CodeNoData>{"No Data Available"}</CodeNoData>
                                }


                            </UniqueColumn>
                        </OneColumn>
                    }
                    {twoColumn &&
                        <BarColumn>
                            <TwoColumn>
                                {leftTable &&
                                    // Graph for the table of the left
                                    <TableContainer>
                                        {viewChanges &&
                                            <ChangeTable>
                                                <CodeBlockSmall>{changesTitle}</CodeBlockSmall>
                                                <p>{topCaptionChanges}</p>
                                                <ProductivityCellGrid
                                                    {...this.CONFIGChange}
                                                    maxHeight='400px'
                                                    isFixedHeader
                                                    records={changeRecords}

                                                />
                                                <br />
                                                {regardlessTitle != null && <p><LinkSpan onClick={this.viewAllChanges}>{regardlessTitle}</LinkSpan></p>}
                                                <p><LinkSpan onClick={this.removeChanges}>{removeChangesLinkText}</LinkSpan></p>
                                                <BlackLine></BlackLine>
                                            </ChangeTable>
                                        }

                                        <div>
                                            <CodeBlockSmall>{claimTitle}</CodeBlockSmall>
                                            <p>{topCaption}</p>
                                            <LinkSpan onClick={this.exportToExcel}>{exportText}</LinkSpan>
                                            <ProductivityCellGrid

                                                maxHeight='400px'
                                                rowComponent={this.getCustomRow()}
                                                isFixedHeader={true}
                                                records={this.getRecords()}
                                                columns={this.getGridColumnHeaders()}
                                                selectionKey={'ctrlNo'}
                                                domID='productivity-grid'
                                                emptyGridMessage=' '
                                                scrollToTop={false}
                                            />
                                        </div>

                                    </TableContainer>
                                }
                                {leftUsersChart &&
                                    // Graph for users chart on the left
                                    <LefContainer>
                                        <InsideLeftContainer>
                                            <CodeBlockSmall>{userTitle}</CodeBlockSmall>
                                            <BarBoxLeft>
                                                <CodeBlockVerticalSmall>
                                                    {leftTitleUser}
                                                </CodeBlockVerticalSmall>
                                                <VictoryChart
                                                    // adding the material theme provided with Victory
                                                    theme={VictoryTheme.material}
                                                    domainPadding={domainPaddingUser}
                                                    padding={{ left: 90, top: 0, bottom: 80, right: 20 }}
                                                    style={{
                                                        parent: {
                                                            border: 'none',
                                                            backgroundColor: `${Colors.white}`,
                                                            height: '100%'
                                                        }
                                                    }}

                                                    events={[
                                                        {
                                                            childName: 'all',
                                                            target: 'data',
                                                            eventHandlers: {
                                                                onClick: this.clickHandler,
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <VictoryAxis
                                                        style={{
                                                            tickLabels: {
                                                                fontSize: '9px',
                                                                angle: -45,
                                                                padding: 5,
                                                                textAnchor: 'end',
                                                                fill: `${Colors.black}`,

                                                            },
                                                            ticks: {
                                                                stroke: `${Colors.black}`
                                                            },
                                                        }}

                                                    />
                                                    <VictoryAxis
                                                        style={{
                                                            axis: { stroke: `${Colors.black}` },
                                                            grid: { stroke: `${Colors.black}`, strokeWidth: 0.8 },
                                                            tickLabels: { fontSize: '7px', fill: `${Colors.black}` },
                                                            ticks: { stroke: `${Colors.black}` },
                                                        }}
                                                        dependentAxis
                                                    />
                                                    <VictoryBar
                                                        style={{ data: { fill: `${Colors.blueGraph}`, width: userBarWidth } }}
                                                        data={userData}
                                                        x="labelText"
                                                        y="count"
                                                        labels={({ datum }) => `${datum.toolTip}`}

                                                        labelComponent={
                                                            <VictoryTooltip
                                                                flyoutWidth={90}
                                                                flyoutHeight={20}
                                                                cornerRadius={0}
                                                                pointerLength={10}
                                                                pointerWidth={5}
                                                                flyoutStyle={{
                                                                    stroke: "#838383",
                                                                    strokeWidth: 1,
                                                                    fill: "#FFFFFF"
                                                                }}
                                                                style={{
                                                                    fill: "#838383",
                                                                    fontSize: 6,
                                                                    fontWeight: 500,
                                                                    textAnchor: "middle"
                                                                }}
                                                            />
                                                        }

                                                    />
                                                </VictoryChart>
                                            </BarBoxLeft>
                                            <CodeBlockSmall>{bottomTitleUser}</CodeBlockSmall>
                                        </InsideLeftContainer>
                                    </LefContainer>
                                }
                                {leftDaysChart &&
                                    // Graph for days chart on the left
                                    <LefContainer>
                                        <InsideLeftContainer >
                                            <CodeBlockSmall>{dayTitle}</CodeBlockSmall>

                                            <BarBoxLeft>
                                                <CodeBlockVerticalSmall>
                                                    {leftTitleDay}
                                                </CodeBlockVerticalSmall>
                                                <VictoryChart
                                                    // adding the material theme provided with Victory
                                                    theme={VictoryTheme.material}
                                                    domainPadding={domainPaddingDay}

                                                    padding={{ left: 90, top: 0, bottom: 80, right: 20 }}

                                                    style={{
                                                        parent: {
                                                            border: 'none',
                                                            backgroundColor: `${Colors.white}`,
                                                            height: '100%'
                                                        }
                                                    }}

                                                    events={[
                                                        {
                                                            childName: 'all',
                                                            target: 'data',
                                                            eventHandlers: {
                                                                onClick: this.clickHandler,
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <VictoryAxis
                                                        style={{
                                                            axis: { stroke: `${Colors.black}` },
                                                            tickLabels: {
                                                                fontSize: '9px',
                                                                angle: -45,
                                                                padding: 5,
                                                                textAnchor: 'end',
                                                                fill: `${Colors.black}`,
                                                            },
                                                            ticks: { stroke: `${Colors.black}` },
                                                        }}

                                                    />

                                                    <VictoryAxis
                                                        style={{
                                                            axis: { stroke: `${Colors.black}` },
                                                            grid: { stroke: `${Colors.black}`, strokeWidth: 0.8 },
                                                            tickLabels: { fontSize: '7px', fill: `${Colors.black}` },
                                                            ticks: { stroke: `${Colors.black}` },
                                                        }}
                                                        dependentAxis
                                                    />
                                                    <VictoryBar
                                                        style={{ data: { fill: `${Colors.blueGraph}`, width: dayBarWidth } }}
                                                        data={dayData}
                                                        x="labelText"
                                                        y="count"

                                                        labels={({ datum }) => `${datum.toolTip}`}

                                                        labelComponent={
                                                            <VictoryTooltip
                                                                flyoutWidth={90}
                                                                flyoutHeight={20}
                                                                cornerRadius={0}
                                                                pointerLength={10}
                                                                pointerWidth={5}
                                                                flyoutStyle={{
                                                                    stroke: "#838383",
                                                                    strokeWidth: 1,
                                                                    fill: "#FFFFFF"
                                                                }}
                                                                style={{
                                                                    fill: "#838383",
                                                                    fontSize: 6,
                                                                    fontWeight: 500,
                                                                    textAnchor: "middle"
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </VictoryChart>
                                            </BarBoxLeft>
                                            <CodeBlockSmall>Days(s)</CodeBlockSmall>
                                        </InsideLeftContainer>
                                    </LefContainer>
                                }
                            </TwoColumn>
                            <TwoColumn>
                                <RightContainer>
                                    <InsideRightContainer>
                                        {/* Graph for all the tasks*/}
                                        <CodeBlockSmall>{title}</CodeBlockSmall>
                                        <BarBoxSmall>
                                            <CodeBlockVerticalSmall>
                                                <span style={{ fontSize: '13px' }}>{leftTitle}</span>
                                            </CodeBlockVerticalSmall>
                                            <VictoryChart
                                                // adding the material theme provided with Victory
                                                theme={VictoryTheme.material}
                                                domainPadding={domainTaskData}
                                                padding={{ left: 90, top: 0, bottom: 80, right: 20 }}
                                                style={{
                                                    parent: {
                                                        border: 'none',
                                                        backgroundColor: `${Colors.white}`,
                                                        height: '100%'
                                                    }
                                                }}


                                                events={[
                                                    {
                                                        childName: 'all',
                                                        target: 'data',
                                                        eventHandlers: {
                                                            onClick: this.clickHandler,
                                                        },
                                                    },
                                                ]}
                                            >
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: `${Colors.black}` },
                                                        tickLabels: {
                                                            fontSize: '9px',
                                                            angle: -45,
                                                            padding: 5,
                                                            textAnchor: 'end',
                                                            fill: `${Colors.black}`,

                                                        },
                                                        ticks: {
                                                            stroke: `${Colors.black}`
                                                        },
                                                    }}
                                                    tickValues={[1, 2, 3, 4, 5, 6]}
                                                />
                                                <VictoryAxis
                                                    style={{
                                                        axis: { stroke: `${Colors.black}` },
                                                        grid: { stroke: `${Colors.black}`, strokeWidth: 0.8 },
                                                        tickLabels: { fontSize: '6px', fill: `${Colors.black}` },
                                                        ticks: { stroke: `${Colors.black}` },
                                                    }}
                                                    dependentAxis
                                                />

                                                <VictoryBar
                                                    style={{ data: { fill: `${Colors.blueGraph}`, width: 35 } }}
                                                    data={data}
                                                    x="labelText"
                                                    y="count"
                                                    labels={({ datum }) => `${datum.toolTip}`}

                                                    labelComponent={
                                                        <VictoryTooltip
                                                            flyoutWidth={90}
                                                            flyoutHeight={20}
                                                            cornerRadius={0}
                                                            pointerLength={10}
                                                            pointerWidth={5}
                                                            flyoutStyle={{
                                                                stroke: "#838383",
                                                                strokeWidth: 1,
                                                                fill: "#FFFFFF"
                                                            }}
                                                            style={{
                                                                fill: "#838383",
                                                                fontSize: 6,
                                                                fontWeight: 500,
                                                                textAnchor: "middle"
                                                            }}
                                                        />
                                                    }
                                                />
                                            </VictoryChart>
                                        </BarBoxSmall>
                                        <CodeBlockSmall>{bottomTitle}</CodeBlockSmall>
                                    </InsideRightContainer>
                                </RightContainer>
                                {rightUsersChart &&
                                    //Graph for the user on the right
                                    <RightContainer>
                                        <InsideRightContainer>
                                            <CodeBlockSmall>{userTitle}</CodeBlockSmall>

                                            <BarBoxSmall>
                                                <CodeBlockVerticalSmall>
                                                    <span style={{ fontSize: '13px' }}>{leftTitleUser}</span>
                                                </CodeBlockVerticalSmall>
                                                <VictoryChart
                                                    // adding the material theme provided with Victory
                                                    theme={VictoryTheme.material}
                                                    domainPadding={domainPaddingUser}
                                                    padding={{ left: 90, top: 0, bottom: 80, right: 20 }}
                                                    style={{
                                                        parent: {
                                                            border: 'none',
                                                            backgroundColor: `${Colors.white}`,
                                                            height: '100%'
                                                        }
                                                    }}

                                                    events={[
                                                        {
                                                            childName: 'all',
                                                            target: 'data',
                                                            eventHandlers: {
                                                                onClick: this.clickHandler,
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <VictoryAxis
                                                        style={{
                                                            tickLabels: {
                                                                fontSize: '9px',
                                                                angle: -45,
                                                                padding: 5,
                                                                textAnchor: 'end',
                                                                fill: `${Colors.black}`,

                                                            },
                                                            ticks: {
                                                                stroke: `${Colors.black}`
                                                            },
                                                        }}

                                                    />
                                                    <VictoryAxis
                                                        style={{
                                                            axis: { stroke: `${Colors.black}` },
                                                            grid: { stroke: `${Colors.black}`, strokeWidth: 0.8 },
                                                            tickLabels: { fontSize: '6px', fill: `${Colors.black}` },
                                                            ticks: { stroke: `${Colors.black}` },
                                                        }}
                                                        dependentAxis
                                                    />
                                                    <VictoryBar
                                                        style={{ data: { fill: `${Colors.blueGraph}`, width: userBarWidth } }}
                                                        data={userData}
                                                        x="labelText"
                                                        y="count"

                                                        labels={({ datum }) => `${datum.toolTip}`}

                                                        labelComponent={
                                                            <VictoryTooltip
                                                                flyoutWidth={90}
                                                                flyoutHeight={20}
                                                                cornerRadius={0}
                                                                pointerLength={10}
                                                                pointerWidth={5}
                                                                flyoutStyle={{
                                                                    stroke: "#838383",
                                                                    strokeWidth: 1,
                                                                    fill: "#FFFFFF"
                                                                }}
                                                                style={{
                                                                    fill: "#838383",
                                                                    fontSize: 6,
                                                                    fontWeight: 500,
                                                                    textAnchor: "middle"
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </VictoryChart>
                                            </BarBoxSmall>

                                            <CodeBlockSmall>{bottomTitleUser}</CodeBlockSmall>
                                        </InsideRightContainer>
                                    </RightContainer>
                                }

                                {rightDaysChart &&
                                    //Graph for the days on the right
                                    <RightContainer>
                                        <InsideRightContainer>
                                            <CodeBlockSmall>{dayTitle}</CodeBlockSmall>

                                            <BarBoxSmall>
                                                <CodeBlockVerticalSmall>
                                                    <span style={{ fontSize: '13px' }}>{leftTitleDay}</span>
                                                </CodeBlockVerticalSmall>
                                                <VictoryChart
                                                    // adding the material theme provided with Victory
                                                    theme={VictoryTheme.material}
                                                    domainPadding={domainPaddingDay}
                                                    padding={{ left: 90, top: 0, bottom: 80, right: 20 }}
                                                    style={{
                                                        parent: {
                                                            border: 'none',
                                                            backgroundColor: `${Colors.white}`,
                                                            height: '100%'
                                                        }
                                                    }}

                                                    events={[
                                                        {
                                                            childName: 'all',
                                                            target: 'data',
                                                            eventHandlers: {
                                                                onClick: this.clickHandler,
                                                            },
                                                        },
                                                    ]}
                                                >
                                                    <VictoryAxis
                                                        style={{
                                                            tickLabels: {
                                                                fontSize: '9px',
                                                                angle: -45,
                                                                padding: 5,
                                                                textAnchor: 'end',
                                                                fill: `${Colors.black}`,

                                                            },
                                                            ticks: {
                                                                stroke: `${Colors.black}`
                                                            },
                                                        }}

                                                    />
                                                    <VictoryAxis
                                                        style={{
                                                            axis: { stroke: `${Colors.black}` },
                                                            grid: { stroke: `${Colors.black}`, strokeWidth: 0.8 },
                                                            tickLabels: { fontSize: '6px', fill: `${Colors.black}` },
                                                            ticks: { stroke: `${Colors.black}` },
                                                        }}
                                                        dependentAxis
                                                    />

                                                    <VictoryBar
                                                        style={{ data: { fill: `${Colors.blueGraph}`, width: dayBarWidth } }}
                                                        data={dayData}
                                                        x="labelText"
                                                        y="count"
                                                        labels={({ datum }) => `${datum.toolTip}`}

                                                        labelComponent={
                                                            <VictoryTooltip
                                                                flyoutWidth={90}
                                                                flyoutHeight={20}
                                                                cornerRadius={0}
                                                                pointerLength={10}
                                                                pointerWidth={5}
                                                                flyoutStyle={{
                                                                    stroke: "#838383",
                                                                    strokeWidth: 1,
                                                                    fill: "#FFFFFF"
                                                                }}
                                                                style={{
                                                                    fill: "#838383",
                                                                    fontSize: 6,
                                                                    fontWeight: 500,
                                                                    textAnchor: "middle"
                                                                }}
                                                            />
                                                        }
                                                    />
                                                </VictoryChart>

                                            </BarBoxSmall>

                                            <CodeBlockSmall>{bottomTitleDay}</CodeBlockSmall>
                                        </InsideRightContainer>
                                    </RightContainer>
                                }

                            </TwoColumn>
                        </BarColumn>
                    }
                </ContentWrapper>
            </React.Fragment>
        )
    }
}


var connectedHoc = connect<IViewProductivityState, IViewProductivityActionProps, IOwnProps, IViewProductivityProps, ApplicationState>(
    createCrudMapStateToProps('ViewProductivity'),
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ProductivityBar)

export default withRouter(connectedHoc);