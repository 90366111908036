import * as React from 'react';
import { Input, SelectDropdownV2, Typography } from 'ui-core';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';

import { ContentRowWrapper } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { CheckBoxComponent } from '../../common/CheckBox';
import { RadioButtonComponent } from '@common/RadioButton';

import { IRelatedClaimsData, IRelatedClaimsUpdateRequest } from '@store/ui/ClaimOptionsUI';


export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    width: 745px;

    #id-rel-ub-enable-chk_wrapper, #id-rel-1500-enable-chk_wrapper {    
        margin-left:10px;
    }

    #id-rel-ub-medrec-chk_wrapper, #id-rel-1500-medrec-chk_wrapper,
    #id-rel-ub-pcn-chk_wrapper, #id-rel-1500-pcn-chk_wrapper,
    #id-rel-ub-svcdates-chk_wrapper, #id-rel-1500-svcdates-chk_wrapper,
    #id-rel-ub-dob-chk_wrapper, #id-rel-1500-dob-chk_wrapper {    
        margin-left:40px;
    }

    #id-rel-ub-pcn-exactmatch-radio_wrapper, #id-rel-1500-pcn-exactmatch-radio_wrapper,
    #id-rel-ub-pcn-seqmatch-radio_wrapper, #id-rel-1500-pcn-seqmatch-radio_wrapper,
    #id-rel-ub-pcn-delmatch-radio, #id-rel-1500-pcn-delmatch-radio {
        margin-bottom: 8px;
    }

    #id-rel-ub-pcn-del-txt, #id-rel-1500-pcn-del-txt {
        width: 15px;
    }

   #id-rel-ub-pcn-maskfixedlength-dropdown, #id-rel-1500-pcn-maskfixedlength-dropdown {
         width: 120px;
    }

    #id-rel-ub-pcn-del-txt,  #id-rel-1500-pcn-del-txt {
        padding-left: 0px;
        padding-right:0px;
        width: 20px;
        margin-right: 100px;
    }
`;

export const RelPaddingDiv = styled.div`
    padding-left: 5px;
    margin-top: 8px;
`;

export const RelPaddingMatchDiv = styled.div`
    margin-top: 7px;
    padding-left: 70px;
`;

export const customControlStyles = styled.div` => ({
    height: 20 });
`;

export const RelatedClaimsErrorMessage = styled.span`
    width: 300px;
    display: block;
    margin-left: 15px;
    color: ${Colors.digitalRed130};
    ${Typography.small};
    ${Typography.defaultLineHeight};
`;

export interface IComponentProps {
    canViewRelatedClaimsOptions: boolean,
    canEditRelatedClaimsOptions: boolean,
    optionsUB: IRelatedClaimsData,
    options1500: IRelatedClaimsData,
    updateRelatedClaimsOptions(request: IRelatedClaimsUpdateRequest): void,
};

interface IComponentState {
}

export const DEFAULT_STATE: IComponentState = {
};

export class RelatedClaimsOptions extends React.Component<IComponentProps, IComponentState> {

    private seqMatchOptions = [
        { label: 'Last 2 digits', value: '2' },
        { label: 'Last 3 digits', value: '3' },
        { label: 'Last 4 digits', value: '4' },
    ];

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }


    public componentDidMount() {
    }

    public FixeLengthList_SelectedItem(idToFind: string | undefined) {
        let item = this.seqMatchOptions.find(ob => ob['value'] === idToFind);

        if (item) {
            return item;
        } else {
            return this.seqMatchOptions[0];
        }
    }

    componentWillUnmount() {
    }


    public render() {

        if (!this.props.canViewRelatedClaimsOptions)
            return <div></div>;

        return (
            <ContentWrapper id={"content_wrapper_RelatedClaimsOptions"}>
                <DialogFieldset height='290px' id='relclaims-ub-fieldset' width="50%">
                    <DialogLegend>UB</DialogLegend>
                    <CheckBoxComponent
                        id='id-rel-ub-enable-chk'
                        label='Enable UB'
                        width='260px'
                        disabled={!this.props.canEditRelatedClaimsOptions}
                        checked={this.props.optionsUB.isEnabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@isEnabled", Value: e.target.checked };
                            this.props.updateRelatedClaimsOptions(request)
                        }
                        }
                    />
                    {(this.props.optionsUB.serviceDatesEnabledError != undefined && this.props.optionsUB.serviceDatesEnabledError.length > 0) &&
                        <RelatedClaimsErrorMessage>{this.props.optionsUB.medRecNbEnabledError}</RelatedClaimsErrorMessage>
                    }
                    {((this.props.optionsUB.serviceDatesEnabledError == undefined || this.props.optionsUB.serviceDatesEnabledError.length == 0) &&
                        (this.props.optionsUB.medRecNbEnabledError != undefined && this.props.optionsUB.medRecNbEnabledError.length > 0))
                        &&
                        <RelatedClaimsErrorMessage>{this.props.optionsUB.medRecNbEnabledError}</RelatedClaimsErrorMessage>
                    }
                    <RelPaddingDiv>
                        <CheckBoxComponent
                            id='id-rel-ub-medrec-chk'
                            label='Medical Record Number'
                            width='260px'
                            disabled={!this.props.canEditRelatedClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isMedRecNbEnabled}
                            hasError={this.props.optionsUB.medRecNbEnabledError != undefined && this.props.optionsUB.medRecNbEnabledError !== ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@isMedRecNbEnabled", Value: e.target.checked };
                                this.props.updateRelatedClaimsOptions(request)
                            }
                            }
                        />
                        <CheckBoxComponent
                            id='id-rel-ub-pcn-chk'
                            label='Patient Control Number'
                            width='260px'
                            disabled={!this.props.canEditRelatedClaimsOptions || !this.props.optionsUB.isEnabled}
                            hasError={this.props.optionsUB.pcnEnabledError != undefined && this.props.optionsUB.pcnEnabledError !== ''}
                            checked={this.props.optionsUB.isPCNEnabled}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@isPCNEnabled", Value: e.target.checked };
                                this.props.updateRelatedClaimsOptions(request)
                            }
                            }
                        />
                        <RelPaddingMatchDiv>
                            <RadioButtonComponent
                                id="id-rel-ub-pcn-exactmatch-radio"
                                label="Exact Match"
                                width='150px'
                                disabled={!this.props.canEditRelatedClaimsOptions || !this.props.optionsUB.isEnabled || !this.props.optionsUB.isPCNEnabled}
                                checked={this.props.optionsUB.isPCNEnabled && this.props.optionsUB.PCNMatchType == "0"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@PCNMatchTypeExact", Value: e.target.checked };
                                    this.props.updateRelatedClaimsOptions(request)
                                }
                                }
                            />
                            <ContentRowWrapper>
                                <div style={{ marginTop: '5px' }}>

                                    <RadioButtonComponent
                                        id="id-rel-ub-pcn-seqmatch-radio"
                                        label="Sequence Match"
                                        width='150px'
                                        disabled={!this.props.canEditRelatedClaimsOptions || !this.props.optionsUB.isEnabled || !this.props.optionsUB.isPCNEnabled}
                                        checked={this.props.optionsUB.isPCNEnabled && this.props.optionsUB.PCNMatchType == "1"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@PCNMatchTypeSeq", Value: e.target.checked };
                                            this.props.updateRelatedClaimsOptions(request)
                                        }
                                        }
                                    />
                                </div>
                                <SelectDropdownV2
                                    styles={{ control: customControlStyles }}
                                    domID="id-rel-ub-pcn-maskfixedlength-dropdown"
                                    className="dropdown"
                                    label=""
                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'
                                    width="30px"
                                    options={this.seqMatchOptions}
                                    disabled={!this.props.canEditRelatedClaimsOptions || !this.props.optionsUB.isEnabled || !this.props.optionsUB.isPCNEnabled || this.props.optionsUB.PCNMatchType != "1"}
                                    initialValue={this.FixeLengthList_SelectedItem(this.props.optionsUB.PCNMaskFixedLength)}
                                    hasError={this.props.optionsUB.maskFixedLengthError != undefined && this.props.optionsUB.maskFixedLengthError != ''}
                                    errorMessage={this.props.optionsUB.maskFixedLengthError}
                                    onChange={(e: any) => {
                                        const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@PCNMaskFixedLength", Value: e.value };
                                        this.props.updateRelatedClaimsOptions(request)
                                    }
                                    }
                                />
                            </ContentRowWrapper>
                            <ContentRowWrapper style={{marginTop: '3px'}}>
                                    <RadioButtonComponent
                                        id="id-rel-ub-pcn-delmatch-radio"
                                        label="Delimiter Match"
                                        width='150px'
                                        disabled={!this.props.canEditRelatedClaimsOptions || !this.props.optionsUB.isEnabled || !this.props.optionsUB.isPCNEnabled}
                                        checked={this.props.optionsUB.isPCNEnabled && this.props.optionsUB.PCNMatchType == "2"}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@PCNMatchTypeDelim", Value: e.target.checked };
                                            this.props.updateRelatedClaimsOptions(request)
                                        }
                                        }
                                    />
                                <Input
                                    height='20px'
                                    style={{height: '22px'} }
                                    domID="id-rel-ub-pcn-del-txt"
                                    className="text-input"
                                    label=""
                                    maxLength={1}
                                    width='25px'
                                    disabled={!this.props.canEditRelatedClaimsOptions || !this.props.optionsUB.isEnabled || !this.props.optionsUB.isPCNEnabled || this.props.optionsUB.PCNMatchType != "2"}
                                    initialValue={this.props.optionsUB.PCNDelim}
                                    hasError={this.props.optionsUB.delimError != undefined && this.props.optionsUB.delimError !== ''}
                                    errorMessage={this.props.optionsUB.delimError}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@PCNDelim", Value: e.target.value };
                                        this.props.updateRelatedClaimsOptions(request)
                                    }
                                    }
                                />
                            </ContentRowWrapper>
                        </RelPaddingMatchDiv>
                        <div style={{marginTop: '5px'}}>
                        <CheckBoxComponent
                            id='id-rel-ub-svcdates-chk'
                            label='Service Dates'
                            width='260px'
                            disabled={!this.props.canEditRelatedClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isServiceDatesEnabled}
                            hasError={this.props.optionsUB.serviceDatesEnabledError != undefined && this.props.optionsUB.serviceDatesEnabledError != ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@isServiceDatesEnabled", Value: e.target.checked };
                                this.props.updateRelatedClaimsOptions(request)
                            }
                            }
                        />
                        <CheckBoxComponent
                            id='id-rel-ub-dob-chk'
                            label='Date of Birth'
                            width='260px'
                            disabled={!this.props.canEditRelatedClaimsOptions || !this.props.optionsUB.isEnabled}
                            checked={this.props.optionsUB.isDateOfBirthEnabled}
                            hasError={this.props.optionsUB.dateOfBirthEnabledError != undefined && this.props.optionsUB.dateOfBirthEnabledError != ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IRelatedClaimsUpdateRequest = { Type: "UB", Name: "@isDateOfBirthEnabled", Value: e.target.checked };
                                this.props.updateRelatedClaimsOptions(request)
                            }
                            }
                            />
                        </div>
                    </RelPaddingDiv>
                </DialogFieldset>
                <DialogFieldset height='290px' id='relclaims-1500-fieldset' width="50%">
                    <DialogLegend>1500</DialogLegend>
                    <CheckBoxComponent
                        id='id-rel-1500-enable-chk'
                        label='Enable 1500'
                        width='260px'
                        disabled={!this.props.canEditRelatedClaimsOptions}
                        checked={this.props.options1500.isEnabled}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@isEnabled", Value: e.target.checked };
                            this.props.updateRelatedClaimsOptions(request)
                        }
                        }
                    />
                    {(this.props.options1500.serviceDatesEnabledError != undefined && this.props.options1500.serviceDatesEnabledError.length > 0) &&
                        <RelatedClaimsErrorMessage>{this.props.options1500.medRecNbEnabledError}</RelatedClaimsErrorMessage>
                    }
                    {((this.props.options1500.serviceDatesEnabledError == undefined || this.props.options1500.serviceDatesEnabledError.length == 0) &&
                        (this.props.options1500.medRecNbEnabledError != undefined && this.props.options1500.medRecNbEnabledError.length > 0))
                        &&
                        <RelatedClaimsErrorMessage>{this.props.options1500.medRecNbEnabledError}</RelatedClaimsErrorMessage>
                    }

                    <RelPaddingDiv>
                        <CheckBoxComponent
                            id='id-rel-1500-medrec-chk'
                            label='Medical Record Number'
                            width='260px'
                            disabled={!this.props.canEditRelatedClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isMedRecNbEnabled}
                            hasError={this.props.options1500.medRecNbEnabledError != undefined && this.props.options1500.medRecNbEnabledError !== ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@isMedRecNbEnabled", Value: e.target.checked };
                                this.props.updateRelatedClaimsOptions(request)
                            }
                            }
                        />
                        <CheckBoxComponent
                            id='id-rel-1500-pcn-chk'
                            label='Patient Control Number'
                            width='260px'
                            disabled={!this.props.canEditRelatedClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isPCNEnabled}
                            hasError={this.props.options1500.pcnEnabledError != undefined && this.props.options1500.pcnEnabledError !== ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@isPCNEnabled", Value: e.target.checked };
                                this.props.updateRelatedClaimsOptions(request)
                            }
                            }
                            />
                        <RelPaddingMatchDiv>
                            <RadioButtonComponent
                                id="id-rel-1500-pcn-exactmatch-radio"
                                label="Exact Match"
                                width='150px'
                                disabled={!this.props.canEditRelatedClaimsOptions || !this.props.options1500.isEnabled || !this.props.options1500.isPCNEnabled}
                                checked={this.props.options1500.isPCNEnabled && this.props.options1500.PCNMatchType == "0"}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@PCNMatchTypeExact", Value: e.target.checked };
                                    this.props.updateRelatedClaimsOptions(request)
                                }
                                }
                            />
                            <ContentRowWrapper >
                                <RadioButtonComponent
                                    id="id-rel-1500-pcn-seqmatch-radio"
                                    label="Sequence Match"
                                    width='150px'
                                    disabled={!this.props.canEditRelatedClaimsOptions || !this.props.options1500.isEnabled || !this.props.options1500.isPCNEnabled}
                                    checked={this.props.options1500.isPCNEnabled && this.props.options1500.PCNMatchType == "1"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@PCNMatchTypeSeq", Value: e.target.checked };
                                        this.props.updateRelatedClaimsOptions(request)
                                    }
                                    }
                                />
                                <SelectDropdownV2
                                    domID="id-rel-1500-pcn-maskfixedlength-dropdown"
                                    className="dropdown"
                                    label=""
                                    isClearable={false}
                                    isSearchable={true}
                                    size='small'
                                    width="30px"
                                    options={this.seqMatchOptions}
                                    disabled={!this.props.canEditRelatedClaimsOptions || !this.props.options1500.isEnabled || !this.props.options1500.isPCNEnabled || this.props.options1500.PCNMatchType != "1"}
                                    hasError={this.props.options1500.maskFixedLengthError != undefined && this.props.options1500.maskFixedLengthError != ''}
                                    errorMessage={this.props.options1500.maskFixedLengthError}
                                    initialValue={this.FixeLengthList_SelectedItem(this.props.options1500.PCNMaskFixedLength)}
                                    onChange={(e: any) => {
                                        const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@PCNMaskFixedLength", Value: e.value };
                                        this.props.updateRelatedClaimsOptions(request)
                                    }
                                    }
                                />
                            </ContentRowWrapper>
                            <ContentRowWrapper style={{marginTop: '3px'}}>
                                <RadioButtonComponent
                                    id="id-rel-1500-pcn-delmatch-radio"
                                    label="Delimiter Match"
                                    width='150px'
                                    disabled={!this.props.canEditRelatedClaimsOptions || !this.props.options1500.isEnabled || !this.props.options1500.isPCNEnabled}
                                    checked={this.props.options1500.isPCNEnabled && this.props.options1500.PCNMatchType == "2"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@PCNMatchTypeDelim", Value: e.target.checked };
                                        this.props.updateRelatedClaimsOptions(request)
                                    }
                                    }
                                />
                                <Input
                                    domID="id-rel-1500-pcn-del-txt"
                                    className="text-input"
                                    label=""
                                    style={{ height: '22px' }}

                                    height='20px'
                                    maxLength={1}
                                    disabled={!this.props.canEditRelatedClaimsOptions || !this.props.options1500.isEnabled || !this.props.options1500.isPCNEnabled || this.props.options1500.PCNMatchType != "2"}
                                    initialValue={this.props.options1500.PCNDelim}
                                    hasError={this.props.options1500.delimError != undefined && this.props.options1500.delimError !== ''}
                                    errorMessage={this.props.options1500.delimError}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                        const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@PCNDelim", Value: e.target.value };
                                        this.props.updateRelatedClaimsOptions(request)
                                    }
                                    }
                                    />
                            </ContentRowWrapper>
                        </RelPaddingMatchDiv>
                        <div style={{marginTop: '5px'}}>
                        <CheckBoxComponent
                            id='id-rel-1500-svcdates-chk'
                            label='Service Dates'
                            width='260px'
                            disabled={!this.props.canEditRelatedClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isServiceDatesEnabled}
                            hasError={this.props.options1500.serviceDatesEnabledError != undefined && this.props.options1500.serviceDatesEnabledError != ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@isServiceDatesEnabled", Value: e.target.checked };
                                this.props.updateRelatedClaimsOptions(request)
                            }
                            }
                        />
                        <CheckBoxComponent
                            id='id-rel-1500-dob-chk'
                            label='Date of Birth'
                            width='260px'
                            disabled={!this.props.canEditRelatedClaimsOptions || !this.props.options1500.isEnabled}
                            checked={this.props.options1500.isDateOfBirthEnabled}
                            hasError={this.props.options1500.dateOfBirthEnabledError != undefined && this.props.options1500.dateOfBirthEnabledError != ''}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const request: IRelatedClaimsUpdateRequest = { Type: "1500", Name: "@isDateOfBirthEnabled", Value: e.target.checked };
                                this.props.updateRelatedClaimsOptions(request)
                            }
                            }
                            />
                        </div>
                    </RelPaddingDiv>
                </DialogFieldset>


            </ContentWrapper>
        );
    }
};

