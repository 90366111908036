import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as BridgeMaintenanceUI from './ui/BridgeMaintenanceUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IBridgeMaintenanceState = ICrudComponentState<MCBridgeMaintenanceType, BridgeMaintenanceUI.IBridgeMaintenanceUIState>;

export const actionCreators = createCrudComponentActions<BridgeMaintenanceUI.ActionCreators>(BridgeMaintenanceUI.actionCreators);
export type IBridgeMaintenanceActions = typeof actionCreators;
export type IBridgeMaintenanceActionProps = ICrudComponentActionProps<IBridgeMaintenanceActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiBridge, BridgeMaintenanceUI.reducer);
export const reducer = combineReducers<IBridgeMaintenanceState>(reducers);

export const validationCallback: ValidationCallback<MCBridgeMaintenanceType> = (crud: any): crud is MCBridgeMaintenanceType => {
    let testCrud: MCBridgeMaintenanceType = crud as MCBridgeMaintenanceType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCBridgeMaintenanceType");
    if (validShape) {
        VerifyArray(testCrud?.BridgeMaintenanceInfo?.ClaimTypeList, "ClaimType");
        VerifyArray(testCrud?.BridgeMaintenanceInfo?.FileTypeList, "FileType");
        VerifyArray(testCrud?.BridgeMaintenanceInfo?.FileHeaderTypeList, "FileHeaderType");
        VerifyArray(testCrud?.BridgeMaintenanceInfo?.ClaimHeaderTypeList, "ClaimHeaderType");
        VerifyArray(testCrud?.BridgeMaintenanceInfo?.ClaimBodyTypeList, "ClaimBodyType");
        VerifyArray(testCrud?.BridgeMaintenanceInfo?.LineFormatTypeList, "LineFormatType");
        VerifyArray(testCrud?.BridgeMaintenanceInfo?.PagingRoutineTypeList, "PagingRoutineType");
        VerifyArray(testCrud?.BridgeMaintenanceInfo?.DESFileTypeList, "DESFileType");

        VerifyArray(testCrud?.BridgeMaintenanceInfo?.Bridges, "Bridge");
        validShape = ValidateArray(testCrud?.BridgeMaintenanceInfo?.Bridges?.Bridge, "MCB_Bridge");

    }

    return validShape;
}