import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Caret } from 'ui-core';
import { SortDataType, ISortIndicator } from '@scripts/util/SortHelper';

export interface IARMHeaderCellProps {
    dataName:string,
    text:string,
    cellType:string,
    sortable:boolean,
    select: object,
    sortIndicator: ISortIndicator,
    sortDataType: SortDataType,
    sortHandler: object
}

interface IComponentState {
}

export const DEFAULT_STATE: IComponentState = {
};

export class ARMHeaderCell extends React.Component<IARMHeaderCellProps | any, IComponentState>
{
    constructor(props: IARMHeaderCellProps) {
        super(props);
    }

    static propTypes: {};

    SortGrid() {
		const {setRef, refKey, sortHandler} = this.props
        let newSortIndicator = this.getNewSortIndicator();
        setRef && refKey ? sortHandler(newSortIndicator, refKey) : sortHandler(newSortIndicator);
    }

    getNewSortIndicator(): ISortIndicator {
        const {
            dataName,
            sortIndicator,
            sortDataType
        } = this.props;

        let newSortIndicator : ISortIndicator = {
            sortColumn: '',
            sortDirection: '',
            sortDataType: sortDataType
        };

        newSortIndicator.sortColumn = dataName;
        if (sortIndicator.sortColumn === dataName) {
            newSortIndicator.sortDirection = sortIndicator.sortDirection === 'up' ? 'down' : 'up';
        } else {
            // default sort direction should be 'up' if last sort column was different
            newSortIndicator.sortDirection = 'up';
        }
        return newSortIndicator;
    }

    render() {
        const {
            dataName,
            text,
            sortable,
            select,
            sortIndicator,
            setRef,
            hasCorrectedTabIndex
        } = this.props;

        let sortDirection = '';
        if (sortIndicator && sortIndicator.sortColumn === dataName) {
            sortDirection = sortIndicator.sortDirection;
        } else {
            sortDirection = 'none';
        }

        return (
            
            <th className={dataName}>
                <div>
                    {select &&
                        <button
                            ref={button => { setRef && setRef(button) }}
                            tabIndex={!hasCorrectedTabIndex || sortIndicator ? 0 : -1}
                        >
                            {select}
                            {sortable &&
                                <span onClick={() => { this.SortGrid() }} style={{ position: 'relative', top: 1, left: 21 }}>
                                    <Caret width="80%" height="80%" className={sortDirection}></Caret>
                                </span>
                            }
                        </button>
                    }

                    {!select && text &&
                        <button
                            ref={button => { setRef && setRef(button) }}
                            tabIndex={!hasCorrectedTabIndex || sortIndicator ? 0 : -1}
                            onClick={() => { this.SortGrid() }}
                        >
                            {text}
                            {sortable &&
                                <span>
                                    <Caret width="80%" height="80%" className={sortDirection}></Caret>
                                </span>
                            }
                        </button>
                    }
                </div>
            </th>
        );
    }
};

ARMHeaderCell.propTypes = {
    dataName: PropTypes.string,
    text: PropTypes.string,
    cellType: PropTypes.string,
    sortable: PropTypes.bool,
    sortIndicator: PropTypes.object,
    sortDataType: PropTypes.number,
    sortHandler: PropTypes.func
}
