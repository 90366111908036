import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';

export class JobDetailsDAL {
    public async GetJobDetailsData(jobId: string){
        var xmlData: APIXMLCB_XmlCallBackModel = {};
        let clientId = sessionStorage.getItem("Client")?.split(' ')[0];
        xmlData.ClientID = clientId || undefined;

        // init return object variable
        let jobDetailsData: JobDetailsData | undefined;

        try {
            const apiUrl = (URLs.api + '/api/data/JobDetails/' + jobId);
            await fetch(apiUrl,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': `${getRawToken()}`
                    },
                }).then(async (response) => {
                    const data: any = await response.json();
                    switch (response?.status) {
                        case 200:
                            try {
                                jobDetailsData = data as JobDetailsData;
                            } catch (error) {
                            }
                            break;
                        case 306:
                        default:
                            this.throwError(response, data);
                    }
                })
        } catch (error) {
            console.log('JobDetailsDAL error = ' + error);
        }
        return jobDetailsData;
    }

    throwError(response: Response, data: any) {
        const errorInfo = {
            responseStatus: response.status,
            responseStatusText: response.statusText,
            dataMessage: (data && data.message) ? data.message : data
        }
        const errorInfoJson = JSON.stringify(errorInfo);
        const errorMessage = `JobDetailsApi Error: ${errorInfoJson}`;
        throw new Error(errorMessage);
    }
}
