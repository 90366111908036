import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as ViewProductivityUI from './ui/ViewProductivityUI';
import CrudTypes from '@commonResources/CrudTypes';
import { createCrudComponentReducers, ICrudComponentState, createCrudComponentActions , ICrudComponentActionProps } from '@scripts/util/CrudComponentHelpers';


export type IViewProductivityState = ICrudComponentState<any, any>;

export const actionCreators = createCrudComponentActions<ViewProductivityUI.ActionCreators>(ViewProductivityUI.actionCreators);
export type IViewProductivityActions = typeof actionCreators;
export type IViewProductivityActionProps = ICrudComponentActionProps<IViewProductivityActions>

export const reducers = createCrudComponentReducers (CrudTypes.mctiUsersGroup, ViewProductivityUI.reducer);
export const reducer = combineReducers<IViewProductivityState>(reducers);

