import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as RestrictAccessUI from './ui/RestrictAccessUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IRestrictAccessState = ICrudComponentState<SecurityAccessInfoType, RestrictAccessUI.IRestrictAccessUIState>;
export const actionCreators = createCrudComponentActions<RestrictAccessUI.ActionCreators>(RestrictAccessUI.actionCreators);
export type IRestrictAccessActions = typeof actionCreators;
export type IRestrictAccessActionProps = ICrudComponentActionProps<IRestrictAccessActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiSecurityAccess, RestrictAccessUI.reducer);
export const reducer = combineReducers<IRestrictAccessState>(reducers);

export const validationCallback: ValidationCallback<SecurityAccessInfoType> = (crud: any): crud is SecurityAccessInfoType => {
    let testCrud: SecurityAccessInfoType = crud as SecurityAccessInfoType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "SecurityAccessInfoType");
    if (validShape) {
        VerifyArray(testCrud?.SecurityAccessInfo?.UserList, "User");
        VerifyArray(testCrud?.SecurityAccessInfo?.GroupList, "Group");
        VerifyArray(testCrud?.SecurityAccessInfo?.FormTypeList, "FormType");
        VerifyArray(testCrud?.SecurityAccessInfo?.ClaimLOBList, "ClaimLOB");
        VerifyArray(testCrud?.SecurityAccessInfo?.RemitLOBList, "RemitLOB");
        VerifyArray(testCrud?.SecurityAccessInfo?.PayerList, "Payer");
        VerifyArray(testCrud?.SecurityAccessInfo?.FacilityList, "Facility");
        VerifyArray(testCrud?.SecurityAccessInfo?.ProviderList, "Provider");
        VerifyArray(testCrud?.SecurityAccessInfo?.ProviderNPIList, "ProviderNPI");
        VerifyArray(testCrud?.SecurityAccessInfo?.NoteTypeList, "NoteType");
        VerifyArray(testCrud?.SecurityAccessInfo?.NoteAuthorList, "NoteAuthor");
        VerifyArray(testCrud?.SecurityAccessInfo?.ErrorCategoryList, "ErrorCategory");
        VerifyArray(testCrud?.SecurityAccessInfo?.ReportList, "Report");
        VerifyArray(testCrud?.SecurityAccessInfo?.ObjectList, "Object");
        VerifyArray(testCrud?.SecurityAccessInfo?.AccessList, "Objects");
    }

    return validShape;
}