import { Action, Reducer } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IBridgeMaintenanceUIState {
    selectedBridgeMaintenance: IBridgeMaintenanceUIData;
    selectedItem: MCB_Bridge;    // the node selected by above

    changed: boolean;

    bridgeMaintenanceAlert?: string;

    // errors - need a better way to handle?  maybe?
    bridgeRemoveConfirm?: string;
    bridgeNameError?: string;
    bridgeKeyError?: string;
    maxPagesError?: string;
    fileHeaderCountError?: string;
    claimHeaderCountError?: string;
    claimBodyCountError?: string;
    lineFormatCountError?: string;
    v5NSFRecordIdLengthError?: string;
    v5NSFRecordLengthError?: string;
    desFileTypeIdError?:string;
}

export const defaultBridgeMaintenance: IBridgeMaintenanceUIData = {
    id: '',
    text: '',
};

const emptyItem: MCB_Bridge = {
    '@ID': '',
    '@Name': '',
    '@BridgeKey': '',
    '@ClaimTypeID': '1001', 
    '@FileTypeID': '1',
    '@FileHeaderTypeID':'0',
    '@FileHeaderCount': '',
    '@FileHeaderTerminator': '',
    '@ClaimHeaderTypeID': '0',
    '@ClaimHeaderCount': '',
    '@ClaimHeaderTerminator':'',
    '@ClaimBodyTypeID': '0',
    '@ClaimBodyCount': '',
    '@ClaimBodyTerminator': '',
    '@LineFormatTypeID': '0',
    '@LineFormatCount': '',
    '@LineFormatTerminator': '',
    '@V5NSFRecordIdLength': '',  
    '@V5NSFRecordLength': '',
    '@V5NSFHeaderEndRecord': '',
    '@PagingRoutineTypeID': '1',
    '@MaxPages': '',
    '@ConversionProcess': '',
    '@DESFileTypeID': '0',
    '@SDRType': '0',
};

export const defaultState: IBridgeMaintenanceUIState = {
    selectedBridgeMaintenance: defaultBridgeMaintenance,
    selectedItem: emptyItem,

    changed: false,
    bridgeMaintenanceAlert: '',

    bridgeNameError: undefined,
    bridgeKeyError: undefined,
    maxPagesError: undefined,
    bridgeRemoveConfirm: undefined,
    fileHeaderCountError: undefined,
    claimHeaderCountError: undefined,
    claimBodyCountError: undefined,
    lineFormatCountError: undefined,
    v5NSFRecordIdLengthError: undefined,
    v5NSFRecordLengthError: undefined,
    desFileTypeIdError: undefined,
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface IBridgeMaintenanceUIData {
    id: string;
    text: string;
};

interface IBridgeMaintenanceUIFieldUpdate {
    value: string;
}

interface IBridgeUIError {
    value: string;
    id?: string;
}

export interface ISelectBridgeMaintenance extends ICrudActionData<MCBridgeMaintenanceType, IBridgeMaintenanceUIData> { }
export interface IUpdateBridgeMaintenanceField extends ICrudActionData<MCBridgeMaintenanceType, IBridgeMaintenanceUIFieldUpdate> { }
export interface IError extends ICrudActionData<MCBridgeMaintenanceType, IBridgeUIError> { }

export interface IAddModifyBridge extends ICrudActionData<MCBridgeMaintenanceType, IBridgeMaintenanceUIData> { }
export interface IRemoveBridge extends ICrudActionData<MCBridgeMaintenanceType, IBridgeMaintenanceUIData> { }

export const actionCreators = {
    selectBridgeMaintenance: (selectInfo: ISelectBridgeMaintenance) => createDataAction('SELECT_BRIDGE', selectInfo),


    addBridge: (addInfo: IAddModifyBridge) => createDataAction('ADD_BRIDGE', addInfo),
    updateBridge: (updateInfo: IAddModifyBridge) => createDataAction('UPDATE_BRIDGE', updateInfo),
    removeBridge: (removeInfo: IRemoveBridge) => createDataAction('REMOVE_BRIDGE', removeInfo),

    updateBridgeKey: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_KEY', fieldInfo),
    updateBridgeName: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_NAME', fieldInfo),
    updateBridgeClaimType: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_CLAIMTYPE', fieldInfo),
    updateBridgeFileType: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_FILETYPE', fieldInfo),
    updateBridgePagingRoutine: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_PAGINGROUTINE', fieldInfo),
    updateBridgeMaxPages: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_MAXPAGES', fieldInfo),
    updateBridgeConversionProcess: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_CONVERSIONPROCESS', fieldInfo),
    updateBridgeFileHeaderTypeID: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_FILEHEADERTYPEID', fieldInfo),
    updateBridgeFileHeaderCount: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_FILEHEADERCOUNT', fieldInfo),
    updateBridgeFileHeaderTerminator: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_FILEHEADERTERMINATOR', fieldInfo),
    updateBridgeClaimHeaderTypeID: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_CLAIMHEADERTYPEID', fieldInfo),
    updateBridgeClaimHeaderCount: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_CLAIMHEADERCOUNT', fieldInfo),
    updateBridgeClaimHeaderTerminator: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_CLAIMHEADERTERMINATOR', fieldInfo),
    updateBridgeClaimBodyTypeID: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_CLAIMBODYTYPEID', fieldInfo),
    updateBridgeClaimBodyCount: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_CLAIMBODYCOUNT', fieldInfo),
    updateBridgeClaimBodyTerminator: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_CLAIMBODYTERMINATOR', fieldInfo),
    updateBridgeLineFormatTypeID: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_LINEFORMATTYPEID', fieldInfo),
    updateBridgeLineFormatCount: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_LINEFORMATCOUNT', fieldInfo),
    updateBridgeLineFormatTerminator: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_LINEFORMATTERMINATOR', fieldInfo),
    updateBridgeV5NSFRecordIdLength: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_V5NSFRECORDIDLENGTH', fieldInfo),
    updateBridgeV5NSFRecordLength: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_V5NSFRECORDLENGTH', fieldInfo),
    updateBridgeV5NSFHeaderEndRecord: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_V5NSFHEADERENDRECORD', fieldInfo),

    updateBridgeDESFileTypeID: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_DESFILETYPEID', fieldInfo),
    updateBridgeSDRType: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('UPDATE_BRIDGE_SDRTYPE', fieldInfo),

    errorBridgeField: (fieldInfo: IError) => createDataAction('ERROR_BRIDGE_FIELD', fieldInfo),
    errorBridgeRemoveConfirm: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('ERROR_BRIDGE_REMOVE_CONFIRM', fieldInfo),

    sendBridgeMaintenanceAlert: (fieldInfo: IUpdateBridgeMaintenanceField) => createDataAction('SEND_ALERT', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];



function upsertBridge(array: MCB_Bridge[], item: MCB_Bridge) {
    let newArray = array.slice();
    var oldItem = newArray.find(obj => obj['@ID'] === item['@ID']);
    if (!oldItem) {
        newArray.splice(array.length, 0, JSON.parse(JSON.stringify(item)));
    } else {
      //  oldItem = JSON.parse(JSON.stringify(item)); doesn't work
        oldItem['@Name'] = item['@Name'];
        oldItem['@BridgeKey'] = item['@BridgeKey'];
        oldItem['@ClaimTypeID'] = item['@ClaimTypeID'];
        oldItem['@FileTypeID'] = item['@FileTypeID'];
        oldItem['@FileHeaderTypeID'] = item['@FileHeaderTypeID'];
        oldItem['@FileHeaderCount'] = item['@FileHeaderCount'];
        oldItem['@FileHeaderTerminator'] = item['@FileHeaderTerminator'];
        oldItem['@ClaimHeaderTypeID'] = item['@ClaimHeaderTypeID'];
        oldItem['@ClaimHeaderCount'] = item['@ClaimHeaderCount'];
        oldItem['@ClaimHeaderTerminator'] = item['@ClaimHeaderTerminator'];
        oldItem['@ClaimBodyTypeID'] = item['@ClaimBodyTypeID'];
        oldItem['@ClaimBodyCount'] = item['@ClaimBodyCount'];
        oldItem['@ClaimBodyTerminator'] = item['@ClaimBodyTerminator'];
        oldItem['@LineFormatTypeID'] = item['@LineFormatTypeID'];
        oldItem['@LineFormatCount'] = item['@LineFormatCount'];
        oldItem['@LineFormatTerminator'] = item['@LineFormatTerminator'];
        oldItem['@V5NSFRecordIdLength'] = item['@V5NSFRecordIdLength'];
        oldItem['@V5NSFRecordLength'] = item['@V5NSFRecordLength'];
        oldItem['@V5NSFHeaderEndRecord'] = item['@V5NSFHeaderEndRecord'];
        oldItem['@PagingRoutineTypeID'] = item['@PagingRoutineTypeID'];
        oldItem['@MaxPages'] = item['@MaxPages'];
        oldItem['@ConversionProcess'] = item['@ConversionProcess'];
        oldItem['@DESFileTypeID'] = item['@DESFileTypeID'];
        oldItem['@SDRType'] = item['@SDRType'];

    }

    return newArray;
}

function removeBridge(array: MCB_Bridge[], item: MCB_Bridge) {
    let newArray = array.slice();
  //  console.log('about to remove ' + item['@Name'] + ' (' + newArray.length + ').');
    var oldItem = newArray.filter(obj => obj['@ID'] !== item['@ID']);
    if (!oldItem) {
        console.log('attempt to remove an invalid entry ' + item['@ID'] + ' ' + item['@Name']);
    }
    return oldItem;
}

// this function is needed because the lookup list for pagingrtn and desfiletype is filtered by claimtype id (1001,1011,etc.,)
// so if the claimtype selection was changed, attempt to find a valid entry to move to
function findNewPagingRtnId(id: string, prevSelectedItem: MCB_Bridge, crudData: MCBridgeMaintenanceInfo): string {
    let pagingRtnDefaultId = "1";
    if (!(crudData &&
        crudData.PagingRoutineTypeList &&
        crudData.PagingRoutineTypeList.PagingRoutineType &&
        crudData.PagingRoutineTypeList.PagingRoutineType.constructor === Array))
        return pagingRtnDefaultId;
    
    let pgRtnId = prevSelectedItem['@PagingRoutineTypeID'];
    let pgRtnLookup = crudData.PagingRoutineTypeList.PagingRoutineType.find(o => o['@ID'] === pgRtnId);
    if (pgRtnLookup) {

        if (pgRtnLookup['@ClaimTypeId'] === id) // claim type still valid just return same value
            return pgRtnId || pagingRtnDefaultId;
        else {
            let cmsid = pgRtnLookup['@CMSID']; // try to get the equivalent
            let match = crudData.PagingRoutineTypeList.PagingRoutineType.find(o => o['@CMSID'] === cmsid && o['@ClaimTypeId'] === id);
            if (!match) {  // just get the first of that type
                match = crudData.PagingRoutineTypeList.PagingRoutineType.find(o => o['@ClaimTypeId'] === id);
            }
            return (match && match['@ID'] ? match['@ID'] : pagingRtnDefaultId);
        }
    }
    
    return pagingRtnDefaultId;
}

// 0 seems to be the default for des/non-tenet clients
function findNewDesFileType(id: string, prevSelectedItem: MCB_Bridge, crudData: MCBridgeMaintenanceInfo): string {
    let desFileTypeDefaultId = "0";
    if (!(crudData &&
        crudData.PagingRoutineTypeList &&
        crudData.PagingRoutineTypeList.PagingRoutineType &&
        crudData.PagingRoutineTypeList.PagingRoutineType.constructor === Array))
        return desFileTypeDefaultId;

    let desId = prevSelectedItem['@DESFileTypeID'];

    if (desId === desFileTypeDefaultId) // not setup/not tenet
        return desFileTypeDefaultId;

    let desLookup = crudData.DESFileTypeList.DESFileType.find(o => o['@ID'] === desId);
    if (desLookup) {
        
        if (desLookup['@ClaimTypeID'] === id) 
            return desId || desFileTypeDefaultId;  // claim type still valid just return same value
        else {
            let match = crudData.DESFileTypeList.DESFileType.find(o => o['@ClaimTypeID'] === id);

            return (match && match['@ID'] ? match['@ID'] : desFileTypeDefaultId);
        }
    }

    return desFileTypeDefaultId;
}


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IBridgeMaintenanceUIState, KnownActions> = (state: IBridgeMaintenanceUIState | undefined, action: KnownActions) => {
 
    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_BRIDGE':
                if (action.data.masterCrud) {


                    var data = action.data.masterCrud.BridgeMaintenanceInfo.Bridges.Bridge.find(ob => { return ob["@ID"] === action.data.uiData.id });
              //      console.log(data);

                    

                    return {
                        ...state,
                        selectedBridgeMaintenance: action.data.uiData,

                        selectedItem: data !== undefined ? JSON.parse(JSON.stringify(data)) : JSON.parse(JSON.stringify(emptyItem)),  // copy of the object


                        changed: true,
                        bridgeMaintenanceAlert: undefined,

                        bridgeNameError: undefined,
                        bridgeKeyError: undefined,
                        maxPagesError: undefined,
                        fileHeaderCountError: undefined,
                        claimHeaderCountError: undefined,
                        claimBodyCountError: undefined,
                        lineFormatCountError: undefined,
                        v5NSFRecordIdLengthError: undefined,
                        v5NSFRecordLengthError: undefined,
                        desFileTypeIdError: undefined,
                    }
                }
                break;

            case 'ADD_BRIDGE':
                if (action.data.masterCrud && action.data.masterCrud.BridgeMaintenanceInfo) {
                   let newItem : MCB_Bridge = {
                        ...state.selectedItem,
                        '@ID': action.data.uiData.id
                    };
                    action.data.masterCrud.BridgeMaintenanceInfo.Bridges.Bridge =
                        upsertBridge(action.data.masterCrud.BridgeMaintenanceInfo.Bridges.Bridge, newItem);
                    
                    return {
                        ...state,
                        selectedBridgeMaintenance: {
                            id: action.data.uiData.id,
                            text: state.selectedItem["@Name"]
                        },
                        selectedItem: {
                            ...state.selectedItem,
                            '@ID': action.data.uiData.id
                        },
                        changed: true,

                        bridgeNameError: undefined,
                        bridgeKeyError: undefined,
                        maxPagesError: undefined,
                        fileHeaderCountError: undefined,
                        claimHeaderCountError: undefined,
                        claimBodyCountError: undefined,
                        lineFormatCountError: undefined,
                        v5NSFRecordIdLengthError: undefined,
                        v5NSFRecordLengthError: undefined,
                        desFileTypeIdError: undefined,
                    }
                }
                break;
            case 'UPDATE_BRIDGE':
                if (action.data.masterCrud && action.data.masterCrud.BridgeMaintenanceInfo) {
                    let newItem : MCB_Bridge = {
                        ...state.selectedItem,
                        '@ID': action.data.uiData.id
                    };
                    action.data.masterCrud.BridgeMaintenanceInfo.Bridges.Bridge =
                        upsertBridge(action.data.masterCrud.BridgeMaintenanceInfo.Bridges.Bridge, newItem);


                    return {
                        ...state,
                        selectedBridgeMaintenance: {
                            id: action.data.uiData.id,
                            text: state.selectedItem["@Name"]
                        },

                        changed: true,

                        bridgeNameError: undefined,
                        bridgeKeyError: undefined,
                        maxPagesError: undefined,
                        fileHeaderCountError: undefined,
                        claimHeaderCountError: undefined,
                        claimBodyCountError: undefined,
                        lineFormatCountError: undefined,
                        v5NSFRecordIdLengthError: undefined,
                        v5NSFRecordLengthError: undefined,
                        desFileTypeIdError: undefined,

                    }
                }
                break;

            case 'REMOVE_BRIDGE':
                if (action.data.masterCrud) {
                    let newItem :MCB_Bridge = {
                        ...state.selectedItem,
                        '@ID': action.data.uiData.id
                    };
                    action.data.masterCrud.BridgeMaintenanceInfo.Bridges.Bridge =
                        removeBridge(action.data.masterCrud.BridgeMaintenanceInfo.Bridges.Bridge, newItem);

                    return {
                        ...state,
                        selectedBridgeMaintenance: { id: '', text: '' },
                        selectedItem: JSON.parse(JSON.stringify(emptyItem)),

                        changed: true,

                        bridgeNameError: undefined,
                        bridgeKeyError: undefined,
                        maxPagesError: undefined,
                        bridgeRemoveConfirm: undefined,
                        fileHeaderCountError: undefined,
                        claimHeaderCountError: undefined,
                        claimBodyCountError: undefined,
                        lineFormatCountError: undefined,
                        v5NSFRecordIdLengthError: undefined,
                        v5NSFRecordLengthError: undefined,
                        desFileTypeIdError: undefined,
                    }
                }
                break;

            case 'ERROR_BRIDGE_FIELD':
                var errorMsg = "";
                if (action.data.uiData.id && action.data.uiData.id.length > 0) {
                    switch (action.data.uiData.id) {
                        case 'BridgeName':
                            return {
                                ...state,
                                bridgeNameError: action.data.uiData.value,
                            }
                            break;
                        case 'BridgeKey':
                            return {
                                ...state,
                                bridgeKeyError: action.data.uiData.value,
                            }
                            break;
                        case 'MaxPages':
                            return {
                                ...state,
                                maxPagesError: action.data.uiData.value,
                            }
                            break;


                        case 'FileHeaderCount':
                            return {
                                ...state,
                                fileHeaderCountError: action.data.uiData.value,
                            }
                            break;
                        case 'ClaimHeaderCount':
                            return {
                                ...state,
                                claimHeaderCountError: action.data.uiData.value,
                            }
                            break;
                        case 'ClaimBodyCount':
                            return {
                                ...state,
                                claimBodyCountError: action.data.uiData.value,
                            }
                            break;
                        case 'LineFormatCount':
                            return {
                                ...state,
                                lineFormatCountError: action.data.uiData.value,
                            }
                            break;
                        case 'V5NSFRecordIdLength':
                            return {
                                ...state,
                                v5NSFRecordIdLengthError: action.data.uiData.value,
                            }
                            break;
                        case 'V5NSFRecordLength':
                            return {
                                ...state,
                                v5NSFRecordLengthError: action.data.uiData.value,
                            }
                            break;
                        case 'DESFileTypeID':
                            return {
                                ...state,
                                desFileTypeIdError: action.data.uiData.value,
                            }
                            break;

                    }
                }
                break;

            case 'UPDATE_BRIDGE_KEY':
                if (state.selectedItem['@BridgeKey'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        bridgeKeyError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@BridgeKey': action.data.uiData.value,
                            bridgeKeyError: undefined,
                        },
                    }
                }
                break;


            case 'UPDATE_BRIDGE_NAME':
                if (state.selectedItem['@Name'] !== action.data.uiData.value) {
                    let temp = state.selectedItem['@Name'];
                    return {
                        ...state,
                        bridgeNameError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@Name': action.data.uiData.value,
                            '@OriginalName': temp,
                            
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_CLAIMTYPE':
                if (action.data.masterCrud && action.data.masterCrud.BridgeMaintenanceInfo
                    && state.selectedItem['@ClaimTypeID'] !== action.data.uiData.value) {
 
                    let pagingRtn: string = findNewPagingRtnId(action.data.uiData.value, state.selectedItem, action.data.masterCrud.BridgeMaintenanceInfo);
                    let desValue: string = findNewDesFileType(action.data.uiData.value, state.selectedItem, action.data.masterCrud.BridgeMaintenanceInfo);
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@ClaimTypeID': action.data.uiData.value,
                            '@PagingRoutineTypeID': pagingRtn,
                            '@DESFileTypeID' : desValue,
                        },
                    }
                }
                break;


            case 'UPDATE_BRIDGE_FILETYPE':
                if (state.selectedItem['@FileTypeID'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@FileTypeID': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_PAGINGROUTINE':
                if (state.selectedItem['@PagingRoutineTypeID'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@PagingRoutineTypeID': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_MAXPAGES':
                if (state.selectedItem['@MaxPages'] !== action.data.uiData.value) {
     //               console.log('updating max pages to ' + action.data.uiData.value);
                    return {
                        ...state,
                        maxPagesError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@MaxPages': action.data.uiData.value,
                            
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_CONVERSIONPROCESS':
                if (state.selectedItem['@ConversionProcess'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@ConversionProcess': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_FILEHEADERTYPEID':
                if (state.selectedItem['@FileHeaderTypeID'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@FileHeaderTypeID': action.data.uiData.value,
                        },
                    }
                }
                break;
                
                    
            case 'UPDATE_BRIDGE_FILEHEADERCOUNT':
                if (state.selectedItem['@FileHeaderCount'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        fileHeaderCountError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@FileHeaderCount': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_FILEHEADERTERMINATOR':
                if (state.selectedItem['@FileHeaderTerminator'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@FileHeaderTerminator': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_CLAIMHEADERTYPEID':
                if (state.selectedItem['@ClaimHeaderTypeID'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@ClaimHeaderTypeID': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_CLAIMHEADERCOUNT':
                if (state.selectedItem['@ClaimHeaderCount'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        claimHeaderCountError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@ClaimHeaderCount': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_CLAIMHEADERTERMINATOR':
                if (state.selectedItem['@ClaimHeaderTerminator'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@ClaimHeaderTerminator': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_CLAIMBODYTYPEID':
                if (state.selectedItem['@ClaimBodyTypeID'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@ClaimBodyTypeID': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_CLAIMBODYCOUNT':
                if (state.selectedItem['@ClaimBodyCount'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        claimBodyCountError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@ClaimBodyCount': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_CLAIMBODYTERMINATOR':
                if (state.selectedItem['@ClaimBodyTerminator'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@ClaimBodyTerminator': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_LINEFORMATTYPEID':
                if (state.selectedItem['@LineFormatTypeID'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@LineFormatTypeID': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_LINEFORMATCOUNT':
                if (state.selectedItem['@LineFormatCount'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        lineFormatCountError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@LineFormatCount': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_LINEFORMATTERMINATOR':
                if (state.selectedItem['@LineFormatTerminator'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@LineFormatTerminator': action.data.uiData.value,
                        },
                    }
                }
                break;

             
           case 'UPDATE_BRIDGE_V5NSFRECORDIDLENGTH':
                if (state.selectedItem['@V5NSFRecordIdLength'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        v5NSFRecordIdLengthError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@V5NSFRecordIdLength': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_V5NSFRECORDLENGTH':
                if (state.selectedItem['@V5NSFRecordLength'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        v5NSFRecordLengthError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@V5NSFRecordLength': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_V5NSFHEADERENDRECORD':
                if (state.selectedItem['@V5NSFHeaderEndRecord'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@V5NSFHeaderEndRecord': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_DESFILETYPEID':
                if (state.selectedItem['@DESFileTypeID'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        desFileTypeIdError: undefined,
                        selectedItem: {
                            ...state.selectedItem,
                            '@DESFileTypeID': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'UPDATE_BRIDGE_SDRTYPE':
                if (state.selectedItem['@SDRType'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@SDRType': action.data.uiData.value,
                        },
                    }
                }
                break;

            case 'ERROR_BRIDGE_REMOVE_CONFIRM':
                return {
                    ...state,
                    bridgeRemoveConfirm: action.data.uiData.value,
                }
                break;
                
            case 'SEND_ALERT':
                return {
                    ...state,
                    bridgeMaintenanceAlert: action.data.uiData.value,
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
