import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';
import { SelectDropdownV2 } from 'ui-core';

export interface ITimeScheduleComponentProps {
    id: string;
    onChangeHr?: React.ChangeEventHandler<HTMLInputElement>;
    onChangeMin?: React.ChangeEventHandler<HTMLInputElement>;
    label?: string;
    width?: string;
    disabled: boolean;
    separator?: string;   // what goes between the hour and minute
    setSelectedValHr?: any;
    setSelectedValMin?: any;
};

export interface ITimeScheduleComponentState {
    disabled: boolean;
};

export interface ITimeScheduleWrapperProps {
    widthValue?: string;
};

//---------------------------------------------------------------

export const TimeScheduleWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    height: 35px;
    padding: 10px;
    
    margin: 0 auto;
    min-width: 200px; 
    ${(props: ITimeScheduleWrapperProps) => props.widthValue && `width: ${props.widthValue}`};

    .selectdropdown_timeschedule{
        width: 60px;
    }
`;

export const TimeScheduleLabel = styled.label`
    padding-right: 10px;
    padding-top: 4px;
    cursor: pointer;
    font-size: 10pt;
    
`;

export const SeparatorWrapper = styled.div`
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 4px;
    cursor: pointer;
    font-size: 10pt;
`;




export class TimeScheduleComponent extends React.Component<ITimeScheduleComponentProps, ITimeScheduleComponentState>
{
    static defaultProps: ITimeScheduleComponentProps = {
        id: "",
        width: "200px",
        disabled: false,
    };

    constructor(props: ITimeScheduleComponentProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
        
    }

    render() {


        return (
            <TimeScheduleWrapper
                widthValue={this.props.width}
            >
                <TimeScheduleLabel
                    id={(this.props.id && this.props.id.length > 0 ? this.props.id + "_label" : '')}
                    >
                    {this.props.label}
                </TimeScheduleLabel>
                <SelectDropdownV2
                    domID={(this.props.id && this.props.id.length > 0 ? this.props.id + "_hr" : 'selectdropdown_timeschedule_hr')}
                    className="selectdropdown_timeschedule"
                    disabled={this.props.disabled}

                    isClearable={false}
                    isSearchable={true}
                    size='small'

                    
                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => { if (this.props.onChangeHr) this.props.onChangeHr(e) }}
                    initialValue={this.props.setSelectedValHr ? this.props.setSelectedValHr : ''}
                    options={[{ label: '00', value: '00' },{ label: '01', value: '01' },{ label: '02', value: '02' },
                        { label: '03', value: '03' },{ label: '04', value: '04' },{ label: '05', value: '05' },
                        { label: '06', value: '06' },{ label: '07', value: '07' },{ label: '08', value: '08' },
                        { label: '09', value: '09' },{ label: '10', value: '10' },{ label: '11', value: '11' },
                        { label: '12', value: '12' },{ label: '13', value: '13' },{ label: '14', value: '14' },
                        { label: '15', value: '15' },{ label: '16', value: '16' },{ label: '17', value: '17' },
                        { label: '18', value: '18' },{ label: '19', value: '19' },{ label: '20', value: '20' },
                        { label: '21', value: '21' },{ label: '22', value: '22' },{ label: '23', value: '23' },
                    ]}

                />
                <SeparatorWrapper>
                {this.props.separator}
                </SeparatorWrapper>
                <SelectDropdownV2
                    domID={(this.props.id && this.props.id.length > 0 ? this.props.id + "_min" : 'selectdropdown_timeschedule_min')}
                    className="selectdropdown_timeschedule"
                    disabled={this.props.disabled}
                    
                    isClearable={false}
                    isSearchable={true}
                    size='small'

                    
                    onChange={(e: any/* React.ChangeEvent<HTMLSelectElement>*/) => { if (this.props.onChangeMin) this.props.onChangeMin(e) }}
                    initialValue={this.props.setSelectedValMin ? this.props.setSelectedValMin : '00'}
                    options={[{ label: '00', value: '00' },
                                { label: '05', value: '05' },
                                { label: '10', value: '10' },
                                { label: '15', value: '15' },
                                { label: '20', value: '20' },
                                { label: '25', value: '25' },
                                { label: '30', value: '30' },
                                { label: '35', value: '35' },
                                { label: '40', value: '40' },
                                { label: '45', value: '45' },
                                { label: '50', value: '50' },
                                { label: '55', value: '55' }
                        ]}

                 />




            </TimeScheduleWrapper>
        );
    }
};