import { Reducer, Action } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"




// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IRemitWorkingGroupDisplayUIState {

    filterAlert?: string;
}


export interface IRemitWorkingGroupDisplayUIData {
    index: string;
    value: boolean;
};


interface IRemitWorkingGroupDisplayUIFieldUpdate {
    value: string;
}

export const defaultFilter: IRemitWorkingGroupDisplayUIData = {
    index: '',
    value: false,
};


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IRemitWorkingGroupDisplayUIState = {
    filterAlert: '',
};



export const actionCreators = {

    sendFilterAlert: (fieldInfo: string) => createDataAction('SEND_ALERT', fieldInfo),
};





// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IRemitWorkingGroupDisplayUIState, KnownActions> = (state: IRemitWorkingGroupDisplayUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {


            case 'SEND_ALERT':
                return {
                    ...state,
                    filterAlert: action.data,
                }
                break;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}

