import { Checkbox } from 'ui-core';
import React from 'react';

export class CustomCheckBox {
    private sortBy: string = "";
    private toRender: any;
    private opSelected: boolean = false;

    constructor(sortBy: string, index: number, styleIt?: any, nameId?: string, keyOf?: string, postLabel?: string, disableIt?: string) {
        this.sortBy = sortBy;
        let checkID = 'ops-input-' + index;

        let disableCheckbox: boolean = false;
        if (disableIt && disableIt?.length > 0) {
            if (disableIt == '0')
                disableCheckbox = true;
        }

        let hideIt: boolean = false;
        if (styleIt && styleIt?.visibility)
            hideIt = styleIt.visibility == 'hidden' ? true : false; // need conditional css...

        let cbName: string = 'checkbox';
        if (nameId && nameId?.length > 0) {
            cbName = nameId + '-checkbox';
        }

        let newKey: string = '';
        if (keyOf && keyOf?.length > 0) {
            newKey = keyOf;
        }

        let newLabel: string = '';
        if (postLabel && postLabel?.length > 0) {
            newLabel = postLabel;
        }

        if (newKey && newKey.length > 0) {
            if (hideIt) // also init it to unchecked for the ones that send in styles...
                this.toRender = <Checkbox key={newKey} id={checkID} name={cbName} style={styleIt} labelAfter={newLabel} checked={this.SelectedOption} disabled={disableCheckbox} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.OpsSelected(e.target.checked)} />;
            else if (styleIt)
                this.toRender = <Checkbox key={newKey} id={checkID} name={cbName} style={styleIt} labelAfter={newLabel} checked={this.SelectedOption} className='table-row-checkbox' wrapperClass='table-row-wrapper' labelBefore='' disabled={disableCheckbox} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.OpsSelected(e.target.checked)} />;
            else
                this.toRender = <Checkbox key={newKey} id={checkID} name={cbName} labelAfter={newLabel} className='table-row-checkbox' labelBefore='' checked={this.SelectedOption} disabled={disableCheckbox} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.OpsSelected(e.target.checked)} />;
        } else {
            if (hideIt) // also init it to unchecked for the ones that send in styles...
                this.toRender = <Checkbox id={checkID} name={cbName} style={styleIt} labelBefore='' checked={this.SelectedOption} disabled={disableCheckbox} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.OpsSelected(e.target.checked)} />;
            else if (styleIt)
                this.toRender = <Checkbox id={checkID} name={cbName} style={styleIt} checked={this.SelectedOption} className='table-row-checkbox' wrapperClass='table-row-wrapper' labelBefore='' disabled={disableCheckbox} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.OpsSelected(e.target.checked)} />;
            else
                this.toRender = <Checkbox id={checkID} name={cbName} className='table-row-checkbox' labelBefore='' checked={this.SelectedOption} disabled={disableCheckbox} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.OpsSelected(e.target.checked)} />;
        }
    }

    get SelectedOption() {
        return this.opSelected;
    }
    set SelectedOption(value: boolean) {
        this.opSelected = value;
    }

    public OpsSelected(isChecked: boolean) {
        this.SelectedOption = isChecked;
    }
}