import { Action, Reducer, ActionCreatorsMapObject } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IRemitImportOptionsUIState {
    selectedLOB: ISelectLOBData;   
    selectedItem: ISelectedLOBList; 
    overrideISA06: IRemitImportOverrideSettingData;
    selectedAutoAccept: ISelectAutoAccept;
    remitImportOptions: ISelectRemitImportOptions;
    crossover: string;
    excludeLOB: string;
    bestMatch: string;
    alternateMatch: string;
    submitNCSAlert?: string;
}
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectLOBData {
    index: number;
    value: string;
    text: string;
};

export interface ISelectedLOBList {
    '@ID': string;
    '@Name': string;
};

export interface ISelectAutoAccept {
    value: string;
    text: string;
}

const emptyLOBItem: ISelectedLOBList = {
    '@ID': '',
    '@Name': ''
};

export const defaultSelectLOB: ISelectLOBData = {
    index: 0,
    value: '',
    text: '',
};

const defaultAutoAccept: ISelectAutoAccept = {
    value: 'A',
    text: 'Automatically'
}

export interface IRemitImportOverrideSettingData {
    '@ID': string,
    '@ISA06Value': string
}

export interface IRemitLOBListData {
    id: string;
    text: string;
};

const emptyItem: IRemitImportOverrideSettingData = {
    '@ID': '',
    '@ISA06Value': ''
};

export interface ISelectCrossover {
    value: string;
}

const defaultCheckboxValue: string = "N";

export interface ISelectRemitImportOptions {
    crossover: string;
    bestMatch: string;
    excludeLOB: string;
    alternateMatch: string;
}

const defaultRemitImportOptions: ISelectRemitImportOptions = {
    crossover: "N",
    bestMatch: "N",
    excludeLOB: "N",
    alternateMatch: "N"
}

export interface ICopyOptions {
    bestMatch: string;
    excludeLOB: string;
    alternateMatch: string;
}

export interface IModifyRemitLOBType extends ICrudActionData<MCRemitImportOptionsType, MCMR_RemitImport> { }
export interface ISelectLOB extends ICrudActionData<MCRemitImportOptionsType, ISelectLOBData> { }
export interface ISelectLOBList extends ICrudActionData<MCRemitImportOptionsType, ISelectedLOBList> { }
export interface ISelectAutoAcceptList extends ICrudActionData<MCRemitImportOptionsType, ISelectAutoAccept> { }
export interface ISelectOverrideISA extends ICrudActionData<MCRemitImportOptionsType, IRemitImportOverrideSettingData> { }
export interface ISelectRemitImportList extends ICrudActionData<MCRemitImportOptionsType, ISelectRemitImportOptions> { }

export interface ISelectCheckbox extends ICrudActionData<MCRemitImportOptionsType, ISelectCrossover> { }
export interface ICopyOptionsList extends ICrudActionData<MCRemitImportOptionsType, ICopyOptions> { }

export const actionCreators = {
    addRemitLOB: (updateInfo: IModifyRemitLOBType) => createDataAction('ADD_REMIT_LOB', updateInfo),
    updateRemitLOB: (updateInfo: IModifyRemitLOBType) => createDataAction('UPDATE_REMIT_LOB', updateInfo),
    selectRemitLOB: (selectInfo: ISelectLOB) => createDataAction('SELECT_REMIT_LOB', selectInfo),
    selectLOBList: (selectInfo: ISelectLOBList) => createDataAction('SELECT_LOB_LIST', selectInfo),
    selectAutoAccept: (selectInfo: ISelectAutoAcceptList) => createDataAction('SELECT_AUTO_ACCEPT', selectInfo),
    updateOverrideISA: (selectInfo: ISelectOverrideISA) => createDataAction('UPDATE_OVERRIDE_ISA', selectInfo), 
    removeRemitLOB: (updateInfo: IModifyRemitLOBType) => createDataAction('REMOVE_REMIT_LOB', updateInfo),
    restoreRemitLOB: (updateInfo: IModifyRemitLOBType) => createDataAction('RESTORE_REMIT_LOB', updateInfo),
    updateRemitImportOptions: (selectInfo: ISelectRemitImportList) => createDataAction('UPDATE_REMIT_OPTIONS', selectInfo),

    updateCrossover: (selectInfo: ISelectCheckbox) => createDataAction('UPDATE_CROSSOVER', selectInfo),
    updateExcludeLOB: (selectInfo: ISelectCheckbox) => createDataAction('UPDATE_EXCLUDELOB', selectInfo),
    updateBestMatch: (selectInfo: ISelectCheckbox) => createDataAction('UPDATE_BESTMATCH', selectInfo),
    updateAlternateMatch: (selectInfo: ISelectCheckbox) => createDataAction('UPDATE_ALTERNATEMATCH', selectInfo),
    copyMatchingCriteria: (selectInfo: ICopyOptionsList) => createDataAction('COPY_MATCHING_CRITERIA', selectInfo),
    sendSubmitNCSAlert: (selectInfo: ISelectLOB) => createDataAction('NCS_ALERT', selectInfo),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultstate: IRemitImportOptionsUIState = {
    selectedLOB: defaultSelectLOB,
    selectedItem: emptyLOBItem,
    selectedAutoAccept: defaultAutoAccept,
    overrideISA06: emptyItem,
    crossover: defaultCheckboxValue,
    excludeLOB: defaultCheckboxValue,
    bestMatch: defaultCheckboxValue,
    alternateMatch: defaultCheckboxValue,
    remitImportOptions: defaultRemitImportOptions
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IRemitImportOptionsUIState, KnownActions> = (state: IRemitImportOptionsUIState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'ADD_REMIT_LOB':
                {
                    if (action.data.masterCrud) {
                        let newRemitLOB: MCMR_RemitImport = action.data.uiData; 
                        let newStateData: IRemitImportOptionsUIState = { ...state };
                        action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport.push(newRemitLOB);
                        newStateData.selectedLOB = {
                            index: -1,  // Does not matter; items are selected by name.
                            value: newRemitLOB["@ID"],
                            text: newStateData.selectedItem['@Name']
                        }

                        return newStateData;
                    }
                    break;

                }
            case 'UPDATE_REMIT_LOB':
                {
                    if (action.data.masterCrud) {
                        let selectedRemitLOB: ISelectLOBData = state.selectedLOB;
                        let updateRemitLOB = action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport.find(existingRemitLOB => existingRemitLOB["@ID"] === selectedRemitLOB.value);
                        let newStateData: IRemitImportOptionsUIState = { ...state };
                        if (updateRemitLOB) {
                            updateRemitLOB["@ID"] = selectedRemitLOB["value"];
                            updateRemitLOB["@AutoAccept"] = action.data.uiData["@AutoAccept"];
                            updateRemitLOB["@AlternateMatch"] = action.data.uiData["@AlternateMatch"];
                            updateRemitLOB["@BestMatch"] = action.data.uiData["@BestMatch"];
                            updateRemitLOB["@Crossover"] = action.data.uiData["@Crossover"];
                            updateRemitLOB["@ExcludeLOB"] = action.data.uiData["@ExcludeLOB"];
                        }
                        else {
                            return { ...state };
                        }

                        newStateData.selectedLOB = {
                            index: -1,  // Does not matter; items are selected by name.
                            value: updateRemitLOB["@ID"],
                            text: newStateData.selectedItem['@Name']
                        }
                        newStateData.selectedAutoAccept = state.selectedAutoAccept;
                        newStateData.alternateMatch = action.data.uiData["@AlternateMatch"];
                        newStateData.bestMatch = action.data.uiData['@BestMatch'];
                        newStateData.crossover = action.data.uiData['@Crossover'];
                        newStateData.excludeLOB = action.data.uiData['@ExcludeLOB'];

                        return newStateData;
                    }
                    break;
                }
            case 'NCS_ALERT':
                return {
                    ...state,
                    submitNCSAlert: action.data.uiData.value,
                }
                break;
            case 'SELECT_REMIT_LOB':
                {
                    if (action.data.masterCrud) {
                        let selectedLOB:any = action.data.masterCrud.RemitImportMaintenanceInfo.RemitImports.RemitImport.find(existingNoteType => existingNoteType["@ID"] === action.data.uiData['value']);
                        const getAutoAccept = (val: string) => {
                            switch (val) {
                                case 'A':
                                    return 'Automatically';
                                case 'M':
                                    return 'Manually';
                                case 'N':
                                    return 'Never';
                                default: return "";
                            }
                        }
                        const selectedItem: ISelectedLOBList = { "@ID": selectedLOB?.["@ID"], ["@Name"]: selectedLOB?.["@Name"] };
                        const selectedAutoAccept: ISelectAutoAccept = { value: selectedLOB?.["@AutoAccept"], text: getAutoAccept(selectedLOB?.["@AutoAccept"]) }; 
                        return {
                            ...state,
                            selectedLOB: action.data.uiData,
                            selectedAutoAccept: selectedLOB ? selectedAutoAccept : defaultAutoAccept,
                            crossover: selectedLOB ? selectedLOB["@Crossover"] : defaultCheckboxValue,
                            bestMatch: selectedLOB ? selectedLOB["@BestMatch"] : defaultCheckboxValue,
                            alternateMatch: selectedLOB ? selectedLOB["@AlternateMatch"] : defaultCheckboxValue,
                            excludeLOB: selectedLOB ? selectedLOB["@ExcludeLOB"] : defaultCheckboxValue,
                            selectedItem: selectedItem,
                        }
                    }
                break;
                }
            case 'REMOVE_REMIT_LOB': {
                if (action.data.masterCrud) {
                    let selectRemitLOB: ISelectLOBData = state.selectedLOB;
                    let newIndex: number = selectRemitLOB.index;
                    let uiIndex: number = 0;
                    let newCrudIndex: number | undefined = undefined;
                    let removed: boolean = false;
                    let newIndexSet: boolean = false;
                    
                    action.data.masterCrud.RemitImportMaintenanceInfo.RemitImports.RemitImport.forEach((existingRemitLOB, crudIndex) => {
                        if (newIndexSet) {
                            return;
                        }
                        if (existingRemitLOB["@ID"] === selectRemitLOB["value"]) {
                            existingRemitLOB["@Delete"] = 'true';
                            removed = true;
                        }
                        else if (existingRemitLOB["@Delete"] !== 'true') {
                            newCrudIndex = crudIndex;
                            uiIndex++;
                            if (removed) {
                                newIndexSet = true;
                            }
                        }
                    });
                    //if (removed) {
                    //    action.data.masterCrud.RemitImportMaintenanceInfo.RemitImports.RemitImport.splice(newIndex - 1, 1);
                    //}
                    return {
                        ...state,
                        selectedLOB: {
                            index: uiIndex,
                            value: newCrudIndex ? action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport[newCrudIndex]["@ID"] : '',
                            text: newCrudIndex ? action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport[newCrudIndex]["@ID"] : '',
                        },
                        selectedItem: {
                            "@ID": newCrudIndex ? action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport[newCrudIndex]["@ID"] : '',
                            "@Name": ""
                        },
                        selectedAutoAccept: {
                            value: newCrudIndex ? action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport[newCrudIndex]["@AutoAccept"] : '',
                            text: ""
                        },
                        alternateMatch: newCrudIndex ? action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport[newCrudIndex]["@AlternateMatch"] : '',
                        bestMatch: newCrudIndex ? action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport[newCrudIndex]["@BestMatch"] : '',
                        crossover: newCrudIndex ? action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport[newCrudIndex]["@Crossover"] : '',
                        excludeLOB: newCrudIndex ? action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImports.RemitImport[newCrudIndex]["@ExcludeLOB"] : '',
                    };
                }
                break;
            }
            case 'SELECT_LOB_LIST':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            selectedItem: action.data.uiData
                        }
                    }
                    break;
                }
            case 'UPDATE_OVERRIDE_ISA':
                {
                    if (action.data.masterCrud) {
                        action.data.masterCrud!.RemitImportMaintenanceInfo.RemitImportOverrideSettings.RemitImportOverrideSetting["@ISA06Value"] = action.data.uiData["@ISA06Value"].trimEnd();
                        return {
                            ...state,
                            overrideISA06: {
                                '@ID': state.overrideISA06["@ID"],
                                '@ISA06Value': action.data.uiData["@ISA06Value"].trimEnd()
                            }
                        }
                    }
                    break;
                }
            case 'SELECT_AUTO_ACCEPT':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            selectedAutoAccept: action.data.uiData
                        }
                    }
                    break;
                }
            case 'UPDATE_CROSSOVER':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                           crossover: action.data.uiData.value ? "Y" : "N"
                        }
                    }
                    break;
                }
            case 'UPDATE_EXCLUDELOB':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            excludeLOB: action.data.uiData.value ? "Y" : "N"
                        }
                    }
                    break;
                }
            case 'UPDATE_BESTMATCH':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            bestMatch: action.data.uiData.value ? "Y" : "N"
                        }
                    }
                    break;
                }
            case 'UPDATE_ALTERNATEMATCH':
                {
                    if (action.data.masterCrud) {
                        return {
                            ...state,
                            alternateMatch: action.data.uiData.value ? "Y" : "N"
                        }
                    }
                    break;
                }
            case 'COPY_MATCHING_CRITERIA':
                {
                    if (action.data.masterCrud) {
                        action.data.masterCrud.RemitImportMaintenanceInfo.RemitImports.RemitImport.forEach((existingRemitLOB) => {
                            existingRemitLOB["@AlternateMatch"] = action.data.uiData["alternateMatch"];
                            existingRemitLOB["@BestMatch"] = action.data.uiData["bestMatch"];
                            existingRemitLOB["@ExcludeLOB"] = action.data.uiData["excludeLOB"];
                        });
                        return {
                            ...state
                        }
                    }
                    break;
                }
            case 'RESTORE_REMIT_LOB':
                {
                    if (action.data.masterCrud) {
                        let uiIndex: number = 0;
                        var restoreRemitLob = action.data.masterCrud?.RemitImportMaintenanceInfo.RemitImports.RemitImport.find((existingRemitLob, index) => {
                            if (existingRemitLob["@Delete"] !== 'true') {
                                uiIndex++;
                            }
                            return (existingRemitLob["@ID"] === action.data.uiData["@ID"]);
                        });
                        uiIndex++;  // Increment for the existing note type (above loop won't find it because it's still '1'), which will be the new UI index.
                        const getAutoAccept = (val: string) => {
                            switch (val) {
                                case 'A':
                                    return 'Automatically';
                                case 'M':
                                    return 'Manually';
                                case 'N':
                                    return 'Never';
                                default: return " ";
                            }
                        }
                        
                        if (restoreRemitLob) {
                            restoreRemitLob["@Delete"] = 'false';
                            let remitListItem: any = action.data.masterCrud.RemitImportMaintenanceInfo.RemitLobList.RemitLob.find((lob) => {
                                return lob["@ID"] === action.data.uiData["@ID"]
                            });
                            const selectedAutoAccept: ISelectAutoAccept = { value: restoreRemitLob["@AutoAccept"], text: getAutoAccept(restoreRemitLob["@AutoAccept"]) }; 
                            return {
                                ...state,
                                selectedLOB: {
                                    index: uiIndex,
                                    value: restoreRemitLob["@ID"],
                                    text: remitListItem["@Name"]
                                },
                                selectedItem: {
                                    "@ID": restoreRemitLob["@ID"],
                                    "@Name":  " ",
                                },
                                selectedAutoAccept: selectedAutoAccept,
                                crossover: restoreRemitLob["@Crossover"],
                                bestMatch: restoreRemitLob["@BestMatch"],
                                alternateMatch: restoreRemitLob["@AlternateMatch"],
                                excludeLOB: restoreRemitLob["@ExcludeLOB"]
                            };
                        }
                    }

                    break;
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;
        }
    }
    return state || defaultstate;
}
