import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as AEPhysicianMaintenanceUI from './ui/AEPhysicianMaintenanceUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IAEPhysicianMaintenanceState = ICrudComponentState<AEPhysicianMaintenanceType, AEPhysicianMaintenanceUI.IAEPhysicianMaintenanceUIState>;

export const actionCreators = createCrudComponentActions<AEPhysicianMaintenanceUI.ActionCreators>(AEPhysicianMaintenanceUI.actionCreators);
export type IAEPhysicianMaintenanceActions = typeof actionCreators;
export type IAEPhysicianMaintenanceActionProps = ICrudComponentActionProps<IAEPhysicianMaintenanceActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiAEOtherPhysician, AEPhysicianMaintenanceUI.reducer);
export const reducer = combineReducers<IAEPhysicianMaintenanceState>(reducers);

export const validationCallback: ValidationCallback<AEPhysicianMaintenanceType> = (crud: any): crud is AEPhysicianMaintenanceType => {
    let testCrud: AEPhysicianMaintenanceType = crud as AEPhysicianMaintenanceType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "AEPhysicianMaintenanceType");
    if (validShape) {
        VerifyArray(testCrud?.AEPhysicianMaintenanceInfo?.IDTypeList, "IDType");
        VerifyArray(testCrud?.AEPhysicianMaintenanceInfo?.LOBList, "LOB");
        VerifyArray(testCrud?.AEPhysicianMaintenanceInfo?.Payers, "Payer");
        VerifyArray(testCrud?.AEPhysicianMaintenanceInfo?.Physicians, "Physician");

        if (validShape && testCrud?.AEPhysicianMaintenanceInfo?.Physicians?.Physician) {
            for (let i = 0; i < testCrud?.AEPhysicianMaintenanceInfo?.Physicians?.Physician.length; i++) {
                VerifyArray(testCrud?.AEPhysicianMaintenanceInfo?.Physicians?.Physician[i], "LOB");
            }
        }  
    }
    return validShape;
}