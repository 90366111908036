import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import styled from 'styled-components';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { IRemitWorkingGroupDisplayState, IRemitWorkingGroupDisplayActions, IRemitWorkingGroupDisplayActionProps, actionCreators/*, validationCallback */ } from '@store/RemitWorkingGroupDisplay';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { Input, TextArea, Button, Grid, Caret } from 'ui-core';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMGridPager } from '@common/UICLWrappers/ARMGridPager';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import { ChangeEvent } from 'react';
import { SortHelper, ISortIndicator, SortDataType, SortOrder, MultiSortIndicator, MultiSortIndicatorItem } from "@scripts/util/SortHelper";
import { ModalConfirmation } from '@common/ModalConfirmation';
import { SecurityBits } from "@commonResources/SecurityFunctionList";

interface FilterJsonObject {
    "RemitIDList": {
        "Remit": {
            "@ID": string,
            "@SequenceNo": number
        }[
        ]
    }
}

export const FooterContent = styled.div`
        ${Typography.ARMFontFamily};
        background-color: ${Colors.darkestSecondaryGrey};
        display: flex;
        flex: 1 1 auto;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding: 1px;
        height: 100%;
        width: 100%;
    `;

const FooterStatusLeft = styled.div`
        display: flex;
        flex: 1 1 auto;
        flex-flow: row nowrap;
        justify-content: left;
        background-color: ${Colors.darkestSecondaryGrey};
        color: ${Colors.white};
    `;

const FooterStatusRightHidden = styled.div`
        display: flex;
        flex: 1 1 auto;
        flex-flow: row nowrap;
        justify-content: right;
        background-color: ${Colors.darkestSecondaryGrey};
        color: ${Colors.darkestSecondaryGrey};
    `;

interface UiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

interface SelectedRemitIndicator {
    pageNumber: number,
    remitId: string,
    paymentAmount: number,
    totalCharges: number,
}

interface HeaderProps {
    columns: {
    }
}

export const DetailWrapper = styled.div`
    table {
        width:100%;
    }

    table thead th {
        font-weight:bold;
        width: '300px';
    }

    table tr td {
        background-color:white;
    }
`;

export const GridWrapper = styled.div`

    width:1375px;
    overflow-y: hidden !important;

    .selectedRow{
        background-color:yellow !important;
    }

    .unselectedRow{
        background-color:white;
    }

    #grid-wrapper-remit-list-grid {

        height:300px;

        table tbody tr > td:first-child:not(.select-checkbox){
            padding-left:30px;
        }


        table > thead > tr > th {
/*
            height:32px;
            background-color:#9BA1A9 !important;
            font-weight:bold;
            font-family:"Core Sans C","Century Gothic","AppleGothic",sans-serif;
            position:sticky;
            top:0;
*/
        }


        #thPayer{
            padding-bottom:3.5px;
        }

        table tbody tr > td{
            height:32px !important;
            padding-top:8px;
        }
    }

    table tbody tr:hover{
        background-color:#edf9ff !important;
        cursor:pointer !important;
    }

    max-height:500px;
    overflow-y:scroll;
    background-color:white;
    border: black 1px solid;

    .empty-row{
        background-color:white !important;
    }

    .patientName {
        color: red;
        min-width:250px;
        max-width:250px;
        overflow:hidden;
    }

    .controlNo {
        min-width:125px;
        max-width:125px;
    }

    .totalCharges {
        min-width:150px;
        max-width:150px;
    }

    .typeOfBill {
        min-width:80px;
        max-width:80px;
    }

    .formName {
        min-width:80px;
        max-width:80px;
    }

    .payerName {
        width:300px;
        overflow:hidden;
    }

    table tbody tr > td{
        height:25px !important;
    }

    table tbody tr td div{
        min-height:1px;
    }
`;

interface IComponentProps {
    canEdit: boolean
    canView: boolean,
    title: string,
};

interface IComponentState {
    isBusy: boolean,
    process: any,
    parameter: any,
    remitTitle: string,
    securityClearance: string,
    execNode: string,
    execAction: string,
    execProcess: string,
    execAttribs: string,
    permission: string,
    remits: WGDRemit[],
    filteredRemits: WGDRemit[],
    selectedRemitIndicators: SelectedRemitIndicator[],
    mouseOverRemitId: string,
    sortIndicator: ISortIndicator,
    selectedColumnName: string,
    selectedSortDataType: SortDataType,
    gridPaging: {
        currentPageNumber: number,
        numberOfPages: number,
        recordsPerPage: number,
        totalRecordCount: number
    },
    scrollToTop: boolean,
    filterName: string,
    restrictionsExist: string,
    isAlertOpen: boolean,
    alertMessage: string
};

interface HeaderProps {
    columns: {

    }
}

interface RowProps {
    record: {
        remitId: string,
        patientName: string,
        controlNo: string,
        hicNo: string,
        providerNo: string,
        lobCode: string,
        lobPayer: string,
        remitStatus: string,
        ioPatient: string,
        typeOfBill: string,
        totalCharges: string,
        paymentAmount: string,
        paymentNo: string,
        paymentDate: string,
        stmtFromDate: string,
        stmtThruDate: string,
        secBillDate: string,
        fileName: string,
    }
}
// Why is this exported?
export const DEFAULT_STATE: IComponentState = {
    isBusy: true,
    process: 'view',
    parameter: {},
    remitTitle: '',
    securityClearance: '',
    execNode: '',
    execAction: '',
    execProcess: '',
    execAttribs: '',
    permission: '',
    remits: [],
    filteredRemits: [],
    selectedRemitIndicators: [],
    mouseOverRemitId: '',
    sortIndicator: {
        sortColumn: '',
        sortDirection: 'up',
        sortDataType: SortDataType.String,
    },
    selectedColumnName: 'paymentNo',
    selectedSortDataType: SortDataType.String,
    gridPaging: {
        currentPageNumber: 1,
        numberOfPages: 1,
        recordsPerPage: 100,
        totalRecordCount: 0
    },
    scrollToTop: false,
    filterName: '',
    restrictionsExist: 'N',
    isAlertOpen: false,
    alertMessage: ""
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IRemitWorkingGroupDisplayProps = IMergeApiComponentProps<IRemitWorkingGroupDisplayState, IRemitWorkingGroupDisplayActionProps, IOwnProps>;


/////////////////////////////////////////////////////////////////////////////////////////////

export const ContentWrapper = styled.div`
`;

export const RemitWorkingGroupDisplayContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    min-height: 200px;
    width: 1400px;
    padding: 10px;
`;

export class RemitWorkingGroupDisplay extends React.Component<IRemitWorkingGroupDisplayProps, IComponentState> {

    columnNameMap = [
        { value: 'patientName', text: 'Patient name', sortDataType: SortDataType.String },
        { value: 'controlNo', text: 'Control no', sortDataType: SortDataType.String },
        { value: 'totalCharges', text: 'Total charges', sortDataType: SortDataType.Number },
        { value: 'paymentAmount', text: 'Pmt amt', sortDataType: SortDataType.Number },
        { value: 'paymentDate', text: 'Pmt date', sortDataType: SortDataType.Date },
        { value: 'fedTaxId', text: 'Federal Tax Id', sortDataType: SortDataType.String },
        { value: 'fileName', text: 'File Name', sortDataType: SortDataType.String },
        { value: 'hicNo', text: 'HIC no', sortDataType: SortDataType.String },
        { value: 'ioPatient', text: 'I/O patient', sortDataType: SortDataType.String },
        { value: 'lobCode', text: 'LOB Code', sortDataType: SortDataType.String },
        { value: 'paymentNo', text: 'Payment no', sortDataType: SortDataType.Number },
        { value: 'providerNo', text: 'Provider no', sortDataType: SortDataType.String },
        { value: 'remitStatus', text: 'Remit status', sortDataType: SortDataType.String },
        { value: 'secBillDate', text: 'Sec bill date', sortDataType: SortDataType.String },
        { value: 'stmtFromDate', text: 'Stmt from date', sortDataType: SortDataType.Date },
        { value: 'stmtThruDate', text: 'Stmt thru date', sortDataType: SortDataType.Date },
        { value: 'typeOfBill', text: 'Type of bill', sortDataType: SortDataType.String }        
    ];

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: '' //set dynamically in render()
    };

    constructor(props: IRemitWorkingGroupDisplayProps) {
        super(props);


        const {
            process, execProcess, execNode, execAction, parameter, title, filterName, restrictionsExist
        } = this.props.location.state;

        this.validateRemitFilterImplementation(process);

        let newSelectedColumnName = sessionStorage.getItem("RemitFilterSelectedColumnName");

        this.state = {
            isBusy: true,
            process,
            remitTitle: title == undefined || title == '' ? 'Refine Remits' : title,
            securityClearance: 'FN_VALIDATE_CLAIMS',
            execNode: execNode == undefined || execNode == '' ? 'MultiRemit' : execNode,
            execAction: execAction == undefined || execAction == '' ? 'Validation' : execAction,
            execProcess: execProcess == undefined || execProcess == '' ? 'Reedit' : execProcess,
            execAttribs: parameter,
            parameter: parameter,
            permission: 'FN_VALIDATE_CLAIMS',
            remits: [],
            filteredRemits: [],
            selectedRemitIndicators: [],
            mouseOverRemitId: '',
            selectedColumnName: newSelectedColumnName ?? 'paymentNo',
            selectedSortDataType: SortDataType.String,
            sortIndicator: {
                sortColumn: '',
                sortDirection: 'up',
                sortDataType: SortDataType.String
            },
            gridPaging: {
                currentPageNumber: 1,
                numberOfPages: 1,
                recordsPerPage: 100,
                totalRecordCount: 0
            },
            scrollToTop: false,
            filterName: filterName,
            restrictionsExist: restrictionsExist,
            isAlertOpen: false,
            alertMessage: ""
        }

        this.handleSorting = this.handleSorting.bind(this);
        this.handlePaging = this.handlePaging.bind(this);
    }

    // display TODO alert for requested processes which are not yet implemented
    validateRemitFilterImplementation(process: string) {
        switch (process.toLowerCase()) {

            case 'view':
            case 'view/edit':
            case 'addnote':
            case 'unreleased':
            case 'released':
            case 'deleted':
            case 'custom':
            case 'analysis':
            case "compliance":
            case "direct":
            case "rebill":
            case "reedit":
            case "viewx12remit":
            case "postingfile":
            case "postingfilebypayer":
            case "postingfilebypayment":
            case "remit":
            case "retrieve835":
            case "denial":
                //implemented
                break;
            default:
                // const message = "TODO – Implement Refine Remit Filter for " + process + " (see WorkingGroup.tsx)";
                const message = "An error has occurred. Please try again.";
                alert(message);
        }
    }

    public generateFilterProps = (props: any) => {
        const clone = { ...props };
        const processDetail = sessionStorage.getItem('process');
        sessionStorage.removeItem('process');
        switch (processDetail) {
            case "remit":
            case "management":
            case "denial":
                clone.canView = this.AuthCheck(SecurityBits.FN_CREATE_REPORTS);
                break;
            case "view":
                clone.canView = this.AuthCheck(SecurityBits.FN_VIEW_REPORTS);
                break;
            case "retrieve835":
                clone.canView = this.AuthCheck(SecurityBits.FN_RETRIEVE835);
                break;
            case "postingfile":
            case "postingfilebypayer":
            case "postingfilebypayment":
                clone.canView = this.AuthCheck(SecurityBits.FN_REMIT_CREATE_POSTING_FILE);
                break;
            case "match":
                clone.canView = this.AuthCheck(SecurityBits.FN_MANUALLY_MATCH_REMIT);
                break;
            case "feedantpay":
                clone.canView = this.AuthCheck(SecurityBits.FN_CREATE_REPORTS);
                break;
            case "viewx12remit":
                clone.canView = this.AuthCheck(SecurityBits.FN_VIEW_X12_835);
                break;
            default:
                clone.canView = false;
                break;

        }

        return clone;
    };

    
    AuthCheck = (privilege: number): boolean => {

        var xhr = new XMLHttpRequest();
        xhr.open('get', URLs.api + "/api/data/userrights/userPermission/" + privilege, false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send(null);

        if (xhr.status !== 200)
            return false;

        var data = JSON.parse(xhr.responseText);
        return data.userPermission;
    };

    public componentDidMount() {
        const props = this.generateFilterProps(this.props);
        if (!props.canView) {
            console.log('user did not have rights to ' + props.title);
            this.props.history.push('/LandingPage');
        }

        this.getRemits();

        // JMC needed this.props.action.configData.getConfigData({ cookies: [{ name: "FormTypes" }], config: [{ name: "EnableTenetFeatures" }] });
        // JMC  this.props.action.api.loadData('/api/data/GetClaimFilterFormData', this.validationCallback.bind(this), this.errorCallback.bind(this));
    }

    setBusy(val: boolean) {
        this.props.action.ui.setBusy({ api: this.props.dataStore.api.data, uiData: { value: val } });
    }

    public errorCallback(dataStatus: string): boolean {
        var str = 'RemitWorkingGroupDisplay - An error occurred processing call for ' + this.props.title + ".  Error: " + dataStatus
        console.error(str);
        this.onDisplayRemitWorkingGroupDisplayError(str);

        return true;
    }

    public onDisplayRemitWorkingGroupDisplayError(msg: string) {
        // TODO this.props.action.ui.displayErrorMessage({ api: this.props.dataStore.api.data, uiData: { value: msg } });
    }

    getRemits() {
        const {
            gridPaging
        } = this.state;

        //const {
        //    testMode
        //} = this.props.location.state && this.props.location.state.testMode;

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        let clientId = sessionStorage.getItem("Client")?.split(' ')[0];
        xmlData.ClientID = clientId;
        xmlData.TestMode = false; // testMode;

        // show spinner before fetch
        this.setState({ isBusy: true });

        fetch(URLs.api + '/api/data/GetUserRemitList/' + gridPaging.currentPageNumber,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                if (response.status === 200) {
                    console.log('response from GetUserRemitList');
                    console.dir(data);
                    // get the remits
                    let myRemits: WGDRemit[] = data.remits;

                    myRemits.forEach(x => {
                        // truncate fields that exceed specified max length (15 and 35) 
                        if (x.hicNo.length > 15) x.hicNo = x.hicNo.substr(0, 15 - 3) + '...';
                        if (x.lobPayer.length > 35) x.lobPayer = x.lobPayer.substr(0, 35 - 3) + '...';
                        // comma required for empty names per tim
                        x.patientName = x.lastName + ', ' + x.firstName;
                    });

                    // build the gridPaging numbers
                    let numberOfPages = parseInt(data.pageCount);
                    //       numberOfPages = Math.round(parseInt(data.totalRemits) / gridPaging.recordsPerPage);
                    if (numberOfPages < 1) numberOfPages = 1;


                    this.setState({
                        remits: myRemits,
                        filteredRemits: myRemits,
                        mouseOverRemitId: '',   //myRemits[0].remitId,
                        gridPaging: {
                            currentPageNumber: gridPaging.currentPageNumber,
                            numberOfPages: numberOfPages,
                            recordsPerPage: gridPaging.recordsPerPage,
                            totalRecordCount: data.totalRemits
                        },
                        isBusy: false // hide spinner
                    }, () => {
                        this.handleSorting({
                            sortColumn: '',
                            sortDirection: 'up',
                            sortDataType: SortDataType.String
                        });
                    }
                    );
                    return;
                } else if (response.status === 306) {
                    return;
                } else {
                    let errorMessage =
                        `WGD: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`
                    throw new Error(errorMessage);
                }
            })
            .catch(error => {
                console.error('WGD: ' + error);
            })
            .finally(() => {
                this.setState({ isBusy: false });
            });
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    getGridColumnHeaders() {
        const columns: UiclColumn[] = [
            {
                dataName: "patientName",
                text: 'Patient name',
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "controlNo",
                text: "Control no",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "totalCharges",
                text: "Total charges",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "paymentAmount",
                text: "Pmt amt",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
        ];

        const selectedColumn = this.getSelectedColumn();
        columns.push(
            selectedColumn
        );

        columns.push(
            {
                dataName: "paymentDate",
                text: "Pmt date",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
        );

        return new Set(columns);
    }

    getSelectedColumn() {
        const selectableColumns: UiclColumn[] = [
            {
                dataName: "fedTaxId",
                text: "Federal Tax Id",
            },
            {
                dataName: "fileName",
                text: "File Name",
            },
            {
                dataName: "hicNo",
                text: "HIC no",
            },
            {
                dataName: "ioPatient",
                text: "I/O patient",
            },
            {
                dataName: "lobCode",
                text: "LOB Code",
            },
            {
                dataName: "paymentNo",
                text: "Payment no",
            },
            {
                dataName: "providerNo",
                text: "Provider no",
            },
            {
                dataName: "remitStatus",
                text: "Remit status",
            },
            {
                dataName: "secBillDate",
                text: "Sec bill date",
            },
            {
                dataName: "stmtFromDate",
                text: "Statement from date",
            },
            {
                dataName: "stmtThruDate",
                text: "Statement thru date",
            },
            {
                dataName: "typeOfBill",
                text: "Type of bill",
            }            
        ];

        selectableColumns.map(x => {
            x.sortable = true;
            x.cellType = 'text';
            x.isSorted = 0
        });

        return selectableColumns.filter(x => x.dataName === this.state.selectedColumnName)[0];
    }

    handlePaging(pageNumber: number) {
        const {
            remits,
            gridPaging
        } = this.state;

        // get the requested page of records
        const indexStart = (pageNumber - 1) * gridPaging.recordsPerPage;
        const indexEnd = indexStart + gridPaging.recordsPerPage;
        const page = remits.slice(indexStart, indexEnd);

        this.setState({
            filteredRemits: page,
            gridPaging: {
                currentPageNumber: pageNumber,
                numberOfPages: gridPaging.numberOfPages,
                recordsPerPage: gridPaging.recordsPerPage,
                totalRecordCount: gridPaging.totalRecordCount
            },
            // scroll to top of new grid page
            scrollToTop: true,
        }, () => {
            this.getRemits();
            // restore user scroll pos persistence
            this.setState({ scrollToTop: false })
        });
    }

    GetMultiSortIndicator(sortIndicator: ISortIndicator): MultiSortIndicator {
        var multiInd: MultiSortIndicator = { items: [] };
        var direction = sortIndicator.sortDirection;
        switch (sortIndicator.sortColumn) {
            case '':
                {
                    break;
                }
            case 'patientName':
                {
                    multiInd.items = [
                        { sequence: 0, sortIndicator: { sortColumn: 'lastName', sortDirection: direction, sortDataType: SortDataType.String, } },
                        { sequence: 1, sortIndicator: { sortColumn: 'firstName', sortDirection: 'up', sortDataType: SortDataType.String, } },
                        { sequence: 2, sortIndicator: { sortColumn: 'totalCharges', sortDirection: 'up', sortDataType: SortDataType.Number, } },
                    ]
                    break;
                }
            case 'controlNo':
                {
                    multiInd.items = [
                        { sequence: 0, sortIndicator: { sortColumn: 'controlNo', sortDirection: direction, sortDataType: SortDataType.Number, } },
                        { sequence: 1, sortIndicator: { sortColumn: 'lastName', sortDirection: 'up', sortDataType: SortDataType.String, } },
                        { sequence: 2, sortIndicator: { sortColumn: 'firstName', sortDirection: 'up', sortDataType: SortDataType.String, } },
                    ]
                    break;
                }
            case 'totalCharges':
                {
                    multiInd.items = [
                        { sequence: 0, sortIndicator: { sortColumn: 'totalCharges', sortDirection: direction, sortDataType: SortDataType.Number, } },
                        { sequence: 1, sortIndicator: { sortColumn: 'lastName', sortDirection: 'up', sortDataType: SortDataType.String, } },
                        { sequence: 2, sortIndicator: { sortColumn: 'firstName', sortDirection: 'up', sortDataType: SortDataType.String, } },
                    ]
                    break;
                }
            case 'paymentAmount':
                {
                    multiInd.items = [
                        { sequence: 0, sortIndicator: { sortColumn: 'paymentAmount', sortDirection: direction, sortDataType: SortDataType.Number, } },
                        { sequence: 1, sortIndicator: { sortColumn: 'lastName', sortDirection: 'up', sortDataType: SortDataType.String, } },
                        { sequence: 2, sortIndicator: { sortColumn: 'firstName', sortDirection: 'up', sortDataType: SortDataType.String, } },
                    ]
                    break;
                }
            case 'paymentDate':
                {
                    multiInd.items = [
                        { sequence: 0, sortIndicator: { sortColumn: 'paymentDate', sortDirection: direction, sortDataType: SortDataType.Date, } },
                        { sequence: 1, sortIndicator: { sortColumn: 'lastName', sortDirection: 'up', sortDataType: SortDataType.String, } },
                        { sequence: 2, sortIndicator: { sortColumn: 'firstName', sortDirection: 'up', sortDataType: SortDataType.String, } },
                    ]
                    break;
                }
            default:    // most columns don't have secondary sort order
                multiInd.items = [{ sequence: 0, sortIndicator: sortIndicator, }];
                break;
        }
        return multiInd;
    }

    handleSorting(sortIndicator: ISortIndicator) {
        var filteredRemits: WGDRemit[] = this.state.filteredRemits;
        var multiSortInd = this.GetMultiSortIndicator(sortIndicator);

        if (multiSortInd?.items?.length > 0) {
            const sortedRemits = SortHelper.MultiSort(filteredRemits, multiSortInd);

            this.setState({
                sortIndicator,
                filteredRemits: sortedRemits
            });
        }
    }

    displaySelectedColumn(event: ChangeEvent<HTMLSelectElement>) {
        const selectedSortDataType = this.getSelectedSortDataType(event.target.value);

        this.setState({
            selectedColumnName: event.target.value,
            selectedSortDataType
        });
        sessionStorage.setItem("RemitFilterSelectedColumnName", event.target.value);
    }

    getColumnNameOptions() {
        let columnNameOptions: { value: string, text: string }[] = [];
        this.columnNameMap.forEach(x => {
            columnNameOptions.push({
                value: x.value,
                text: x.text
            })
        });
        return columnNameOptions;
    }

    getSelectedSortDataType(columnName: string) {
        const selectedColumnMap = this.columnNameMap.find(x => x.value === columnName);
        return selectedColumnMap?.sortDataType || SortDataType.String;
    }

    getCustomHeader() {
        const {
            sortIndicator,
            selectedSortDataType
        } = this.state;

        const columnNames = this.getColumnNameOptions();
        const options = columnNames.slice(5).map(x => <option key={x.value} value={x.value}>
            {x.text}
        </option>);

        const ddlColumnNames = <select value={this.state.selectedColumnName}
            onChange={x => { this.displaySelectedColumn(x) }}>{options}</select>

        const header = ({ columns }: HeaderProps) => <thead><tr key={Math.random()}>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="patientName"
                        text="Patient name"
                        cellType="text"
                        sortable={true}
                        // choose from String, Number or Date
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="controlNo"
                        text="Control no"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="totalCharges"
                        text="Total charges"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.Number}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="paymentAmount"
                        text="Pmt amt"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName={this.state.selectedColumnName}
                        text="Payment no"
                        cellType="select"
                        sortable={true}
                        sortDataType={selectedSortDataType}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                        select={ddlColumnNames}
                    />
                    <ARMHeaderCell
                        dataName="paymentDate"
                        text="Pmt date"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                </React.Fragment>
            }
        </tr></thead>

        return header;
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {

        const {
            filteredRemits,
            selectedRemitIndicators,
            gridPaging
        } = this.state;

        // get the index of the selected row from the selected remit indicators
        let selectedStartIndex = selectedRemitIndicators
            .map(function (x) { return x.remitId; })
            .indexOf(event.currentTarget.id);

        let newSelectedRemitIndicators: SelectedRemitIndicator[] = selectedRemitIndicators;

        // if the clicked row was previously selected
        if (selectedStartIndex > -1) {
            // then remove it
            newSelectedRemitIndicators.splice(selectedStartIndex, 1);
        } else {
            // get a handle to the selected remit
            const remit = filteredRemits.find(x => x.remitId == event.currentTarget.id);

            let paymentAmount = 0;
            if (remit) paymentAmount = parseFloat(remit.paymentAmount);

            let totalCharges = 0;
            if (remit) totalCharges = parseFloat(remit.totalCharges);

            // add a selected remit indicator for the selected row
            newSelectedRemitIndicators.push({
                pageNumber: gridPaging.currentPageNumber,
                remitId: event.currentTarget.id,
                paymentAmount,
                totalCharges,
            });
        }

        // update state with the latest set of selected remit indicators
        this.setState({
            selectedRemitIndicators: newSelectedRemitIndicators
        });
    }

    onMouseOver(remitId: string) {
        const {
            mouseOverRemitId
        } = this.state;

        if (mouseOverRemitId == remitId) return;

        this.setState({
            mouseOverRemitId: remitId,
            scrollToTop: false,
        });
    }

    getCustomRow() {
        const {
            selectedColumnName,
            selectedRemitIndicators,
        } = this.state;

        const row = ({ record }: RowProps) =>
            <tr id={record.remitId} key={record.remitId} role="button"
                className={selectedRemitIndicators.find(x => x.remitId === record.remitId) ? 'selected' : ''}
                onMouseOver={() => this.onMouseOver(record.remitId)}
                onClick={(x) => this.onRowClick(x)}>
                {
                    <React.Fragment>
                        <ARMRowCell value={record.patientName} style={{ width: 250, height: 15, overflow: 'hidden', wordBreak: 'break-all' }} />
                        <ARMRowCell value={record.controlNo} />
                        <ARMRowCell value={record.totalCharges} />
                        <ARMRowCell value={record.paymentAmount} />
                        {selectedColumnName === 'fedTaxId' && <ARMRowCell value={record.fedTaxId} />}
                        {selectedColumnName === 'fileName' && <ARMRowCell value={record.fileName} style={{ overflow: 'hidden' }} />}
                        {selectedColumnName === 'hicNo' && <ARMRowCell value={record.hicNo} />}
                        {selectedColumnName === 'ioPatient' && <ARMRowCell value={record.ioPatient} />}
                        {selectedColumnName === 'lobCode' && <ARMRowCell value={record.lobCode} />}
                        {selectedColumnName === 'paymentNo' && <ARMRowCell value={record.paymentNo} />}
                        {selectedColumnName === 'providerNo' && <ARMRowCell value={record.providerNo} />}
                        {selectedColumnName === 'remitStatus' && <ARMRowCell value={record.remitStatus} />}
                        {selectedColumnName === 'secBillDate' && <ARMRowCell value={record.secBillDate} />}
                        {selectedColumnName === 'stmtFromDate' && <ARMRowCell value={record.stmtFromDate} />}
                        {selectedColumnName === 'stmtThruDate' && <ARMRowCell value={record.stmtThruDate} />}
                        {selectedColumnName === 'typeOfBill' && <ARMRowCell value={record.typeOfBill} />}
                        <ARMRowCell value={record.paymentDate} />
                    </React.Fragment>
                }
            </tr>
        return row;
    }

    getDetailPanel() {
        const {
            filteredRemits,
            mouseOverRemitId
        } = this.state;

        const mouseOverRemit = filteredRemits.filter(x => x.remitId === mouseOverRemitId)[0];
        return (
            <DetailWrapper>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Patient Last Name
                            </th>
                            <th>
                                Patient First Name
                            </th>
                            <th>
                                Control No
                            </th>
                            <th>
                                HIC No
                            </th>
                            <th>
                                Provider No
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {mouseOverRemit && mouseOverRemit.lastName}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.firstName}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.controlNo}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.hicNo}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.providerNo}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>
                                LOB Code
                            </th>
                            <th>
                                LOB Payer
                            </th>
                            <th>
                                Remit Status
                            </th>
                            <th>
                                I/O Patient
                            </th>
                            <th>
                                Type Of Bill
                            </th>
                            <th>
                                Total Charges
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {mouseOverRemit && mouseOverRemit.lobCode}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.lobPayer}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.remitStatus}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.ioPatient}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.typeOfBill}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.totalCharges}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>
                                Payment Amount
                            </th>
                            <th>
                                Payment No
                            </th>
                            <th>
                                Payment Date
                            </th>
                            <th>
                                Stmt From Date
                            </th>
                            <th>
                                Stmt Thru Date
                            </th>
                            <th>
                                Sec Bill Date
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {mouseOverRemit && mouseOverRemit.paymentAmount}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.paymentNo}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.paymentDate}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.stmtFromDate}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.stmtThruDate}
                            </td>
                            <td>
                                {mouseOverRemit && mouseOverRemit.secBillDate}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <thead>
                        <tr>
                            <th>
                                File Name
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {mouseOverRemit && mouseOverRemit.fileName}&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
            </DetailWrapper>
        );
    }

    selectThese() {
        const {
            filteredRemits,
            selectedRemitIndicators,
            gridPaging
        } = this.state;

        // first remove any of the current page remits from the list
        const newSelectedRemitIndicators: SelectedRemitIndicator[] =
            selectedRemitIndicators.filter(x => x.pageNumber !== gridPaging.currentPageNumber);

        // for each remit in the current grid page
        filteredRemits.forEach(x => {

            // add a selected remit indicator
            newSelectedRemitIndicators.push({
                pageNumber: gridPaging.currentPageNumber,
                remitId: x.remitId,
                paymentAmount: parseFloat(x.paymentAmount),
                totalCharges: parseFloat(x.totalCharges),
            });
        });

        // replace the selected remit indicators in state
        this.setState({
            selectedRemitIndicators: newSelectedRemitIndicators
        });
    }

    selectNone() {
        const {
            selectedRemitIndicators,
            gridPaging
        } = this.state;

        // select none should only deselect the rows for the current page
        const newSelectedRemitIndicators: SelectedRemitIndicator[] =
            selectedRemitIndicators.filter(x => x.pageNumber != gridPaging.currentPageNumber);

        this.setState({
            selectedRemitIndicators: newSelectedRemitIndicators
        });
    }

    public getSummaryPanel() {
        const {
            gridPaging,
            filteredRemits,
            selectedRemitIndicators
        } = this.state;

        let pageRowStartNumber = 0;
        if (gridPaging.currentPageNumber > 1) {
            pageRowStartNumber = ((gridPaging.currentPageNumber - 1) * gridPaging.recordsPerPage) + 1;
        } else if (filteredRemits.length) {
            pageRowStartNumber = 1;
        }

        const pageRowEndNumber = Math.min(gridPaging.currentPageNumber * gridPaging.recordsPerPage,
            gridPaging.totalRecordCount);

        // calculate 'viewing total worth'
        const viewingTotalWorth = filteredRemits.reduce(function (accumulator, remit) {
            if (!isNaN(parseFloat(remit?.paymentAmount)))
                return accumulator + Number.parseFloat(remit.paymentAmount);
            return accumulator;
        }, 0)

        // calculate 'selected total worth'
        const selectedTotalWorth = selectedRemitIndicators.reduce(function (accumulator, remitIndicator) {
            return accumulator + remitIndicator.totalCharges;
        }, 0)

        return (
            <div>
                <table>
                    <tbody>
                        <tr>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Viewing</td>
                            <td>
                                <span style={{ backgroundColor: 'white' }}>
                                    {pageRowStartNumber} - {pageRowEndNumber}
                                </span>
                            </td>
                            <td>worth</td>
                            <td>
                                <span style={{ backgroundColor: 'white' }}>
                                    ${viewingTotalWorth.toFixed(2)}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ textAlign: 'right', fontWeight: 'bold' }}>Selected</td>
                            <td>
                                <span style={{ backgroundColor: 'white' }}>
                                    {selectedRemitIndicators.length} / {gridPaging.totalRecordCount}
                                </span>
                            </td>
                            <td>worth</td>
                            <td style={{ textAlign: 'left' }}>
                                <div style={{ backgroundColor: 'white', width: '100%' }}>
                                    ${selectedTotalWorth.toFixed(2)}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    getActionButtons() {

        const {
            selectedRemitIndicators,
            process,
            restrictionsExist,
        } = this.state;

        return (
            <FooterContent>
                <FooterStatusLeft>{restrictionsExist === "Y" ? "User Access Restricted" : ""}</FooterStatusLeft>
                <div>
                    <Button
                        domID="btnGoBack"
                        name="Go Back"
                        buttonType="deEmphasizedReversed"
                        size="small"
                        type="button"
                        onClick={() => { window.sessionStorage.setItem("tabType", "Advanced"); window.history.go(-1) }}
                    />
                    <Button
                        domID="btnContinue"
                        name={process == "view/edit" ? "View Remits" : "Continue"}
                        buttonType="deEmphasizedReversed"
                        size="small"
                        type="button"
                        disabled={selectedRemitIndicators.length < 1}
                        // NOTE: further research needed, trying to avoid user spamming the button and creating multiple jobs

                        onClick={(e: any) => { if (!e.currentTarget.disabled) { e.currentTarget.disabled = true; this.onContinue() } }}
                    />
                    <Button
                        domID="btnCancel"
                        name="Cancel"
                        buttonType="deEmphasizedReversed"
                        size="small"
                        type="button"
                        onClick={() => { this.props.history.push("/LandingPage"); }}
                    />
                </div>
                <FooterStatusRightHidden>{restrictionsExist === "Y" ? "User Access Restricted" : ""}</FooterStatusRightHidden>
            </FooterContent>
        );
    }

    getFilterJsonObject() {
        const {
            selectedRemitIndicators
        } = this.state;

        let filterJsonObject: FilterJsonObject =
        {
            "RemitIDList": {
                "Remit": [
                ]
            }
        }

        selectedRemitIndicators.forEach((x, i) => {
            const remit = {
                "@ID": x.remitId,
                "@SequenceNo": i + 1
            }
            filterJsonObject["RemitIDList"]["Remit"].push(remit);
        });

        return JSON.stringify(filterJsonObject);
    }

    onContinue() {
        if (this.state.process === "view/edit" || this.state.process === "view") {
            this.viewRemits();
            return;
        } else if (this.state.process === "viewx12remit") {
            this.viewRemits();
            return;
        }

        console.log(`this.state.execNode -> ${this.state.execNode}`);

        switch (this.state.execNode) {
            case "MultiRemit":
                this.executeMultiRemitProcess();
                break;
            case "Report":
                this.executeRemitReportProcess();
                break;
            case "PaperClaim":
            case "ClaimChange":
            case "ChangeClaimState":
            case "Export":
                alert('todo implement');
                break;
        }
    }

    viewRemits() {
        this.setWorkingGroup();
    }

    redirectToAdminPortal() {
        let selectedRemitIndicatorsNew: SelectedRemitIndicator[];
        selectedRemitIndicatorsNew = this.state.selectedRemitIndicators;
        fetch(URLs.api + '/api/data/remits/FileCountFromFilter',
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                }
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();
                if (data === "1") {
                    if (this.GoToProcess) {
                        this.GoToProcess.current?.submit();
                    }
                }
                else if (data === "0") {
                    this.setState({ isAlertOpen: true, alertMessage: "The selected items did not return a remit file, please refine your filter", selectedRemitIndicators: selectedRemitIndicatorsNew });
                    let Continue = document?.getElementById("btnContinue");
                    if (Continue !== null) {
                        Continue.removeAttribute("disabled");
                    }
                    return false;
                }
                else {
                    this.setState({ isAlertOpen: true, alertMessage: "Too many results returned, please narrow your search criteria", selectedRemitIndicators: selectedRemitIndicatorsNew });
                    let Continue = document?.getElementById("btnContinue");
                    if (Continue !== null) {
                        Continue.removeAttribute("disabled");
                    }
                    return false;
                }
            })
            .catch(error => {
                console.error('ClaimFilter: ' + error);
                this.errorCallback("Unable to process filter.");
            })
            .finally(() => {
                //this.setBusy(false);
            });
    }

    displayRemitViewer() {

        // cfProps = remit filter props = props passed from CF into WGD
        const cfProps = this.props.location.state;

        // cfProps = CF postData
        this.props.history.push('/CFI/Default', cfProps);
    }

    setWorkingGroup() {
        /*
         var objResponse = XMLCallBack('<Filter Action="SetWorkingGroup" Type="Remit" Process="View/Edit">' + GetWorkingGroup() + '</Filter>', false);
         if (objResponse.responseText == "" || objResponse.responseText.indexOf('RUA=') != -1) {
             goTo("/CFI/Default.asp");
         }
         */

        const {
            testMode
        } = this.props.location.state;

        var xmlData: APIXMLCB_XmlCallBackModel = {};
        let clientId = sessionStorage.getItem("Client")?.split(' ')[0];
        xmlData.ClientID = clientId || undefined;
        xmlData.Process = this.state.process === "viewx12remit" ? "viewx12remit" : "View/Edit";
        xmlData.Type = "Remit";
        xmlData.ViewOrigin = '/workinggroupdisplay.asp';  // see note in type def
        xmlData.TestMode = testMode;
        xmlData.Convert = 'True';
        xmlData.FilterJsonData = this.getFilterJsonObject();

        fetch(URLs.api + '/api/data/SetWorkingGroup',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`,
                },
                body: JSON.stringify(xmlData),
            })
            .then(async (response) => {
                // get json response here
                let data: any = await response.json();

                if (response.status === 306) {
                    // this.onAlert(data.message);  // error is in message
                    return;
                } else if (response.status === 200) {
                    // this.displayRemitViewer(data);
                    console.log('response from SetWorkingGroup');
                    console.dir(data);

                    if (this.state.process !== "viewx12remit")
                        this.displayRemitViewer();
                    else
                        this.redirectToAdminPortal();
                } else {
                    throw new Error(`RemitFilter: unable to run filter ${response.status}. ${response.statusText}:  ${(data && data.message ? data.message : data)}`);
                }
            })
            .catch(error => {
                console.error('RemitFilter: ' + error);
                //this.errorCallback("Unable to process filter.");
            })
            .finally(() => {
                //this.setBusy(false);
            });
    }

    public async executeMultiRemitProcess() {

        //function ValidatePostingFileRequest(process) {
        //       var maxCount = <%= obSecurity.Config(-1, "RemitPostingFile_MaxCLPs") %>;
        //       if (Selection.Count > maxCount) {
        //           alert("Claim Payment Count cannot exceed " + maxCount + " claims.");
        //           SetApplicationState(true);
        //           return;
        //       }

        //       var objResponse = XMLCallBack('<Filter Action="SetWorkingGroup" Type="Remit" Process="' + process + '" Convert="True">' + GetWorkingGroup() + '</Filter>', false);
        //       if (objResponse.responseText == "" || objResponse.responseText.indexOf("RUA=") != -1) {
        //           XMLCallBack('<MultiClaim Action="RemitPostingFile" Type="Remit" Process="' + process + '" Convert="True">' + GetWorkingGroup() + '</MultiClaim>', true);
        //       } else {
        //           SetApplicationState(true);
        //           TrapUnknownError('Could not get remit list.', 'XmlCallBack', 'N/A');
        //       }
        //   }

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        if (this.state.execNode != "MultiRemit") {
            return;
        }

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.state.execAction || (this.state.execAction && this.state.execAction.length == 0))
            return;


        var jsonData: any = {};
        jsonData.MultiClaim = {};
        jsonData.MultiClaim['@Action'] = this.state.execAction;
        jsonData.MultiClaim['@Type'] = "Remit";  // hardcoded in filter.asp
        jsonData.MultiClaim['@Process'] = this.state.execProcess;

        if (this.state.execProcess.toLowerCase() == 'rebill')
            jsonData.MultiClaim['@ReasonCode'] = Object.keys(this.state.execAttribs).length > 0 ? this.state.execAttribs : '';
        else if (this.state.execAttribs && this.state.execAttribs.length > 0)
            jsonData.MultiClaim['@ParamString'] = this.state.execAttribs;

        var remitIdList = this.getFilterJsonObject();
        var jsonRemitIdList = JSON.parse(remitIdList);
        // jsonData.MultiRemit.RemitIDList = jsonRemitIdList.RemitIDList;
        jsonData.MultiClaim.RemitIDList = jsonRemitIdList.RemitIDList;

        xmlData.Action = this.state.execAction ? this.state.execAction : "";
        xmlData.ViewOrigin = '/remits/workinggroupdisplay.asp';  // see note in type def

        //XMLCallBack('<Filter Action="Retrieve835" Type="Remit" Process="retrieve835" Convert="True">' + GetWorkingGroup() + '</Filter>', true);
        if (this.state.process.toLowerCase().includes("retrieve835")) {
            jsonData.MultiClaim['@Action'] = "Retrieve835";
            jsonData.MultiClaim['@Type'] = "Remit";
            jsonData.MultiClaim['@Process'] = this.state.process.toLowerCase();
            jsonData.MultiClaim['@Convert'] = "True";

            xmlData.Action = "Retrieve835"
            xmlData.Type = "Remit"
            xmlData.Convert = "True"
            xmlData.Process = this.state.process.toLowerCase();
        }

        //XMLCallBack('<Filter Action="SetWorkingGroup" Type="Remit" Process="' + process + '" Convert="True">' + GetWorkingGroup() + '</Filter>', false);
        if (this.state.process.toLowerCase().includes("postingfile")) {
            jsonData.MultiClaim['@Action'] = "RemitPostingFile";
            jsonData.MultiClaim['@Type'] = "Remit";
            jsonData.MultiClaim['@Process'] = this.state.process.toLowerCase();
            jsonData.MultiClaim['@Convert'] = "True";

            xmlData.Action = "RemitPostingFile"
            xmlData.Type = "Remit"
            xmlData.Convert = "True"
            xmlData.Process = this.state.process.toLowerCase();
        }

        xmlData.FilterJsonData = JSON.stringify(jsonData);
        console.log(`JSON DATA`)
        console.log(xmlData)
        console.log(jsonData)

        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    console.log('RemitWorkingGroupDisplay:status 200');
                    console.dir(xmlData);
                    this.goToHomePage();
                    return;
                } else {
                    console.log('RemitWorkingGroupDisplay:status' + response.status + ' ' + response.statusText);
                    throw new Error('RemitWorkingGroupDisplay: error in PostMultiClaim: ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('RemitWorkingGroupDisplay: ' + error);
                this.errorCallback("RemitWorkingGroupDisplay: error in PostMultiClaim.");
            })
            .finally(() => {
                console.log('RemitWorkingGroupDisplay:finally');
                //this.setBusy(false);
            });
    }

    public async executeRemitReportProcess() {

        var xmlData: APIXMLCB_XmlCallBackModel = {};

        if (this.state.execNode != "Report") {
            return;
        }

        // validations on inputs before making call - i do not know that any of these are required
        if (!this.state.execAction || (this.state.execAction && this.state.execAction.length == 0))
            return;

        var jsonData: any = {};
        jsonData.Report = {}; // execNode
        jsonData.Report['@Action'] = this.state.execAction;
        jsonData.Report['@Type'] = "Remit";  // hardcoded in filter.asp
        jsonData.Report['@Process'] = this.state.execProcess;
        //        jsonData.MultiClaim = {}; // execNode
        //        jsonData.MultiClaim['@ParamString'] = this.state?.execAttribs;

        if (this.state.parameter && this.state.parameter?.ReportRequests) {
            jsonData.Report.ReportRequests = this.state.parameter?.ReportRequests;
        }

        var remitIdList = this.getFilterJsonObject();
        var jsonRemitIdList = JSON.parse(remitIdList);
        jsonData.Report.RemitIDList = jsonRemitIdList.RemitIDList;

        xmlData.Action = this.state.execAction ? this.state.execAction : "";
        xmlData.ViewOrigin = '/workinggroupdisplay.asp';  // see note in type def
        xmlData.FilterJsonData = JSON.stringify(jsonData);

        await fetch(URLs.api + '/api/data/PostMultiClaim',
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(xmlData),
            }).then(response => {
                if (response.status === 200) {
                    console.log('RemitWorkingGroupDisplay:status 200');
                    this.goToHomePage();
                    return;
                } else {
                    console.log('RemitWorkingGroupDisplay:status' + response.status + ' ' + response.statusText);
                    throw new Error('RemitWorkingGroupDisplay: error in PostMultiRemit: ' + response.status + ' ' + response.statusText);
                }
            })
            .catch(error => {
                console.error('RemitWorkingGroupDisplay: ' + error);
                this.errorCallback("RemitWorkingGroupDisplay: error in PostMultiRemit.");
            })
            .finally(() => {
                console.log('RemitWorkingGroupDisplay:finally');
            });

    }


    public goToHomePage() {
        this.props.history.push("/LandingPage");
    }

    protected GoToProcess = React.createRef<HTMLFormElement>();

    public renderLegacyGoTo() {
        return (
            <form ref={this.GoToProcess} action={"/Administration/AdminPortal.asp"} name="GoToProcess" method={"POST"}>
                <input type="hidden" name="Process" value={"viewx12remit"} />
            </form>
        );
    }

    public render() {

        const {
            filteredRemits,
            gridPaging,
            isBusy,
            scrollToTop,
            remitTitle,
            filterName
        } = this.state;

        let combinedTitle = remitTitle;
        if (combinedTitle === "View Remits" && filterName != '') {
            combinedTitle += ' "' + filterName + '"';
        }
        const columns = this.getGridColumnHeaders();
        const header = this.getCustomHeader();
        const row = this.getCustomRow();
        const detailPanel = this.getDetailPanel();
        var instruction = this.state.process !== "viewx12remit" ? <React.Fragment> Remits are displayed in groups of 100. You can sort each group, pick some or all of them, and move on to the next group to pick up remits from that group.Work through all of the groups until you have selected all the remits you need then click Continue."
            </React.Fragment> : <React.Fragment>Claim payment information is displayed in groups of 100. You can select one or more with the same File Name and click Continue to view file, or click Go Back to return to Advance Filter Screen. Only one file can be viewed at a time."
            </React.Fragment> ;
        const buttons = this.getActionButtons();
        const summaryPanel = this.getSummaryPanel();
        const titleToRender = "Refine Remit List >> " + combinedTitle;

        return (
            <>
                <DialogWrapper
                    title={titleToRender}
                    instruction={instruction}
                    helpUrl='/Support/Help/HELP_RefineGroup.htm'
                    buttons={buttons}
                    isBusy={isBusy}
                >
                    <ContentWrapper id={"content_wrapper_RemitWorkingGroupDisplay"}>
                        <RemitWorkingGroupDisplayContent>
                            <GridWrapper>
                                <ARMGrid
                                    records={filteredRemits}
                                    columns={columns}
                                    domID='remit-list-grid'
                                    isFixedHeader={true}
                                    maxHeight='400px'
                                    headerComponent={header}
                                    rowComponent={row}
                                    emptyGridMessage=' '
                                    // grid works without selectionKey
                                    // but uicl grid would throw warning
                                    selectionKey='patientName'
                                    scrollToTop={scrollToTop}
                                />
                            </GridWrapper>
                            <div style={{ height: 5 }}></div>
                            <div style={{ display: 'flex', flex: '0 0 100 %' }}>
                                <div style={{ flexGrow: 1 }}>
                                    <Button
                                        domID="selectTheseButton"
                                        name="SELECT THESE"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={() => { this.selectThese() }}
                                    />
                                    <div style={{ width: 5, display: 'inline-block' }}></div>
                                    <Button
                                        domID="selectNoneButton"
                                        name="SELECT NONE"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={() => { this.selectNone() }}
                                    />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {summaryPanel}
                                </div>
                                <div style={{ marginTop: 5, flexGrow: 1, textAlign: 'right', position: 'relative', left: 20, top: -7 }}>
                                    <ARMGridPager
                                        currentPageNumber={gridPaging.currentPageNumber}
                                        numberOfPages={gridPaging.numberOfPages}
                                        pagingHandler={this.handlePaging}
                                    />
                                </div>
                            </div>
                            <div style={{ height: 15 }}></div>
                            <div>
                                {detailPanel}
                            </div>
                        </RemitWorkingGroupDisplayContent>
                    </ContentWrapper>
                    <ModalConfirmation
                        isOpen={this.state.isAlertOpen}
                        formattedMessage={(
                            <React.Fragment>
                                <p>{this.state.alertMessage}.</p>
                            </React.Fragment>)
                        }
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isAlertOpen: false, alertMessage: "" }) }}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                            this.setState({ isAlertOpen: false, alertMessage: "" })

                        }
                        }
                        onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isAlertOpen: false }) }}
                        alertMode={true}
                    />
                </DialogWrapper>
                {this.renderLegacyGoTo()}
            </>
        );
    }

};

var connectedHoc = connect<IRemitWorkingGroupDisplayState, IRemitWorkingGroupDisplayActionProps, IOwnProps, IRemitWorkingGroupDisplayProps, ApplicationState>(
    createApiMapStateToProps('remitWorkingGroupDisplay'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(RemitWorkingGroupDisplay);

export default withRouter(connectedHoc);
