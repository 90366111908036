import { Reducer, Dispatch, bindActionCreators, ActionCreatorsMapObject } from 'redux';
import { MapStateToPropsParam, MapDispatchToPropsParam, MapDispatchToProps, MapDispatchToPropsFunction } from 'react-redux';
import * as MasterCrud from '@store/MasterCrud';
import * as ModalConfirmationUI from '@store/ModalConfirmation';
import { CrudState, CrudStateKeys } from '@store/index';
import * as ConfigData from '@store/ConfigData';
import * as CommonUI from '@store/CommonUI';

interface ICrudComponentBase<C extends any, U extends any, S extends any = any, X extends any = any> {
    crud: C;
    ui: U;
    confirm: S;
    configData: X;
};

export type ICrudComponentState<T extends any, U extends any> = ICrudComponentBase<MasterCrud.MasterCrudState<T>, U>;
export type ICrudComponentActions<U extends any> = ICrudComponentBase<MasterCrud.ActionCreators, U, ModalConfirmationUI.ActionCreators, ConfigData.ActionCreators>;
export type ICrudComponentReducers<U extends Reducer<any | undefined, any>> = ICrudComponentBase<Reducer<MasterCrud.MasterCrudState<any>, MasterCrud.KnownActions>, U, Reducer<ModalConfirmationUI.IModalConfirmationState, ModalConfirmationUI.KnownActions>, Reducer<ConfigData.IConfigDataState, ConfigData.KnownActions>>;

export function createCrudComponentActions<U>(ui: U): ICrudComponentActions<CommonUI.ActionCreators & U> {
// This helpler provides a means to inject the CommonUI action creators. This avoids having to add them individually in each UI file.
// This approach wraps the ui reducer call (see createCrudComponentReducers below) and *ASSUMES* that the ui reducer will return a default UI state 
// if an undefined state is provided. It does, however, avoid a separate RESET_UI action for each UI. An alternative might be to have
// CommonUI simply stand on its own and have its reducer injected just like all the others, having its own commonUi member in ICrudComponentBase.
    return {
        crud: MasterCrud.actionCreators,
        ui: { ...ui, ...CommonUI.actionCreators },
        confirm: ModalConfirmationUI.actionCreators,
        configData: ConfigData.actionCreators
    };
};

export function createCrudComponentReducers<U>(crudId: number, ui: Reducer<U, any>): ICrudComponentReducers<Reducer<U, any>> {
    return {
        crud: MasterCrud.createReducer(crudId),
        ui: CommonUI.createReducer(crudId, ui),
        confirm: ModalConfirmationUI.reducer,
        configData: ConfigData.reducer
    };
}

export function resetCrudComponentState<T extends any, S extends any, C extends any>(crudActions: ICrudComponentActionProps<CommonUI.ActionCreators & C>, crudState: ICrudComponentState<T, S>): void {
    crudActions.crud.reset(crudState.crud.crudId);
    crudActions.ui.reset(crudState.crud.crudId);
    crudActions.configData.reset();
}

// The generic case - however, we really want it to be based on our ApplicationState so we pick a valid key.
//export function createMapStateToProps<TStateProps, TOwnProps, State>(stateField: keyof State): MapStateToPropsParam<TStateProps, TOwnProps, State> {
//    return (state: State, ownProps: TOwnProps): any => {
//        return state[stateField];
//    }
//}

export function createCrudMapStateToProps<TOwnProps, State extends CrudState>(stateField: CrudStateKeys): MapStateToPropsParam<ICrudComponentState<any, any>, TOwnProps, State> {
    return (state: State, ownProps: TOwnProps): ICrudComponentState<any, any> => {
        return state[stateField];
    }
}

export type ICrudComponentActionProps<U extends any> = ICrudComponentBase<ActionCreatorsMapObject<MasterCrud.ActionCreators>, ActionCreatorsMapObject<U>, ActionCreatorsMapObject<ModalConfirmationUI.ActionCreators>, ActionCreatorsMapObject<ConfigData.ActionCreators>>

export function createCrudMapDispatchToProps<TOwnProps>(actionCreators: ICrudComponentActions<any>): MapDispatchToPropsFunction<ICrudComponentActionProps<any>, TOwnProps> {
    return (dispatch: Dispatch, ownProps: TOwnProps): ICrudComponentActionProps<any> => {
        return {
            crud: bindActionCreators<MasterCrud.ActionCreators, ActionCreatorsMapObject<MasterCrud.ActionCreators>>(actionCreators.crud, dispatch),
            ui: bindActionCreators(actionCreators.ui, dispatch),
            confirm: bindActionCreators<ModalConfirmationUI.ActionCreators, ActionCreatorsMapObject<ModalConfirmationUI.ActionCreators>>(actionCreators.confirm, dispatch),
            configData: bindActionCreators<ConfigData.ActionCreators, ActionCreatorsMapObject<ConfigData.ActionCreators>>(actionCreators.configData, dispatch)
        };
    }
}

export type IMergeCrudComponentProps<S extends ICrudComponentState<any, any>, A extends ICrudComponentActionProps<any>, O extends {}> = O & {
    dataStore: S;
    action: A;
};

export function mergeCrudComponentProps<S extends ICrudComponentState<any, any>, A extends ICrudComponentActionProps<any>, O extends {}>(state: S, action: A, ownProps: O): IMergeCrudComponentProps<S, A, O> {
    return Object.assign({}, ownProps, {
        dataStore: state,
        action: action
    });
}

export interface ICrudActionData<T extends any, D extends any> {
    masterCrud?: T;
    uiData: D;
}
