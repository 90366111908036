import * as React from 'react';
import { RouteComponentProps, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll, findIndex, flatMap } from 'lodash';
import { Button, TabContainer, Tabs } from 'ui-core';
import styled from 'styled-components';
import { SelectComponent } from '@common/SelectComponent';
import { ARMNarrowInput } from '@common/UICLWrappers/ARMNarrowInput';
import {Input} from '@components/common/UICLWrappers/ARMInput';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { DialogWrapper, FlexBoxModifier, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { ModalConfirmation, leaveMessage, IModalConfirmationProps } from '@common/ModalConfirmation';
import CrudTypes from '@commonResources/CrudTypes';
import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState,
    ICrudActionData
} from '@scripts/util/CrudComponentHelpers';
import { IFormModalState, defaultFormModalState } from '@store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import { IClaimEventNotificationState, 
    IClaimEventNotificationActionProps,
    actionCreators, 
    validationCallback 
} from  '@store/ClaimEventNotification';
import {IClaimEventConfiguration} from '@store/ui/ClaimEventNotificationUI';
import { SelectAllOrNoneComponent } from '@common/SelectAllOrNoneComponent';
import {SizedContainer, ArmTabContainer}from '@components/common/component-styles';
import { handleChange, pageLeave } from '@commonResources/userModified';

const SearchStyle = styled.div`
    padding: 25px 10px 0 0;
    position: relative;
    .search-btn {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    flex 0 1 10px;
`;

const LeftPane = styled.div`
    display: flex;
    flex: 0 1 0;
    flex-direction: column;
    width: 300px;
    #description-input {
        width: 218px;
    }
    #description-input_wrapper {
        margin-top: 8px;
    }
`;


const LongMessage = styled.div`padding: 0 2em`;

interface IComponentProps {
    canView: boolean;
    canEdit: boolean;
};
type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IClaimEventNotificationProps = IMergeCrudComponentProps<IClaimEventNotificationState, IClaimEventNotificationActionProps, IOwnProps>;

interface IComponentState extends IFormModalState {
    showErrorModal: boolean; 
    showRemoveModal: boolean; 
    // we have two modals--this controls the one that does internal validation popups
    modalProps: Partial<IModalConfirmationProps>;
}

interface ISelectionOption {
    text: string | null;
    value: string;
    index: number; 
}

interface ITabItem {
    label: string;
    domID: string;
}

const defaultModalProps: Partial<IModalConfirmationProps> = {
    isOpen: false,
    showDefaultClose: false,
    alertMode: false,
};

const defaultState = {
    ...defaultFormModalState,
    showErrorModal: false,
    showRemoveModal: false, 
    modalProps: { ...defaultModalProps },
    allSelectedFacils: true
}

class ClaimEventNotification extends React.PureComponent<IClaimEventNotificationProps, IComponentState> {
    constructor(props: IClaimEventNotificationProps) {
        super(props);

        //deliberately mutating this bc we couldn't access this when it was created
        defaultModalProps.onModalToggle = () => this.setState({ modalProps: {...defaultModalProps}});
        defaultModalProps.onConfirm = () => this.setState({ modalProps: {...defaultModalProps}});
        defaultModalProps.onDeny = () => this.setState({ modalProps: {...defaultModalProps}});
        defaultModalProps.onOK = () => this.setState({ modalProps: {...defaultModalProps}});

        this.state = defaultState;
        bindAll(this, [
            'addConfiguration', 'onRemoveConfigurationClicked', 'selectConfiguration', 'updateConfigurationDisplayName',
            'selectTab', 'recoverFromSaveError', 'resetPageState', 'promptToDeselectPayers', 'removeConfiguration',
            'toggleSpecificPayersItem', 'toggleSpecificPayersItems', 'togglePayerSpecificCodesItem', 'togglePayerSpecificCodesItems',
            'validateConfiguration',
        ]);
    }
    // define only once, instead of on every render
    private instructions = <React.Fragment>Select which status history information you want to export in the Claim Event Notification File. Facilities cannot be selected for more than one configuration. Disabled facilities have already been selected under a different configuration.
<i> (For more information, click the help button.) </i></React.Fragment>;

    public componentDidMount() {
        this.resetPageState();
    }
                    

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    // for some reason manual bind and bindAll did not work with these functions, so using alternate syntax 
    public onDialogOk = () => {
        // only validate configs that have been added
        if (this.props.dataStore.ui.selectedClaimConfiguration.id !== this.props.dataStore.ui.claimEventConfigurations[0].id) {
            const errorModal = this.validateConfiguration(this.props.dataStore.ui.selectedClaimConfiguration);
            if (errorModal) {
                return this.setState({modalProps: errorModal});
            }
        }
        this.props.action.ui.updateClaimEventNotificationConfigs(this.props.dataStore.ui, this.props.history);
    }

    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        if (this.props.dataStore.ui.isDirty) {
            this.props.action.confirm.openConfirm({ message: '' });
        } else {
            this.onModalOk();
        }
    }

    // closes are you sure you want to leave without saving and navigates
    public onModalOk = () => {
        this.props.history.push('/LandingPage');
    }

    // closes internal modal
    public onModalClose = () => {
        this.setState({ modalProps: {...defaultModalProps} });
    }

    private baseModifyData = {
        masterCrud: this.props.dataStore.crud.data,
    };

    addConfiguration() {
        const errorModal = this.validateConfiguration(this.props.dataStore.ui.selectedClaimConfiguration);
        if (errorModal) {
            this.setState({modalProps: errorModal});
        } else {
            handleChange();
            this.props.action.ui.addClaimEventNotificationOption({...this.baseModifyData, uiData: this.props.dataStore.ui.selectedClaimConfiguration});
        }
    }

    onRemoveConfigurationClicked() {
        const {selectedClaimConfiguration, claimEventConfigurations} = this.props.dataStore.ui;
        const selectedIndex = claimEventConfigurations.findIndex(config => config.id===selectedClaimConfiguration.id);
        //by default, we just remove the configuration
        let confirmCallback = () => this.removeConfiguration(selectedIndex);
        // there's currently a bug in the backend and we want to prevent the user from hitting this, so we change the callback to prompt them to fix it
        if (selectedClaimConfiguration.specificPayers.some(payer => payer.isSelected)) {
            confirmCallback = this.promptToDeselectPayers;
        }
        this.setState({
            modalProps: {
                ...defaultModalProps,
                alertMode: false,
                isOpen: true,
                message: `Are you sure you want to delete ${this.props.dataStore.ui.selectedClaimConfiguration.description}?`,
                onConfirm: confirmCallback,
                okText: 'Yes',
                cancelText: 'No',
            }
        });
    }

    removeConfiguration(selectedIndex:number) {
        handleChange();
        this.setState({modalProps: defaultModalProps},
            /* TODO: This should probably just be changed to not take any params--we know what's selected in the store */
            () => this.props.action.ui.removeClaimEventNotificationOption({...this.baseModifyData, uiData: selectedIndex})
        )
    }

    promptToDeselectPayers() {
        this.setState({
            modalProps: {
                ...defaultModalProps,
                alertMode: true,
                isOpen: true,
                formattedMessage: this.deleteWillFailMessage,
                onOK: () => {console.log('hi') ; this.props.action.ui.selectTab(1)},
            }
        });
    }

    selectConfiguration(e: React.ChangeEvent<HTMLSelectElement>) {
        const {selectedIndex} = e.target.options;
        const {claimEventConfigurations, selectedClaimConfiguration} = this.props.dataStore.ui;
        if (selectedIndex === 0) {
            if (!claimEventConfigurations[0].facilities.some(facility => !facility.isDisabled) && !selectedClaimConfiguration.facilities.some(facility => !facility.isDisabled && ! facility.isSelected)) {
                this.setState({
                    modalProps: {
                        ...defaultModalProps,
                        isOpen: true,
                        alertMode: true,
                        message: 'A new config cannot be added because all facilities are already selected under existing configs. You must remove a facility from an existing config before you can add a new config.',
                    }
                });
                return;
            }
        }
        this.props.action.ui.selectClaimEventNotificationOption({...this.baseModifyData, uiData: selectedIndex});
    }

    updateConfigurationDisplayName(e: React.ChangeEvent<HTMLInputElement>) {
        handleChange();
        this.props.action.ui.updateClaimEventNotificationDisplayName(e.target.value);
    }

    getCrudActionInput<T>(data: T): ICrudActionData<MCClaimEventNotification, T> {
        // @ts-ignore typescript, I'll deal with you later
        if (typeof data === 'string') data = data.trim();
        return {...this.baseModifyData, uiData: data};
    }

    toggleSpecificPayersItem(id: string) {
        handleChange();
        this.props.action.ui.toggleSpecificPayersItem(this.getCrudActionInput<string>(id));
    }

    toggleSpecificPayersItems(newValue: boolean) {
        handleChange();
        this.props.action.ui.toggleSpecificPayersItems(this.getCrudActionInput<boolean>(newValue));
    }


    togglePayerSpecificCodesItem(id: string) {
        handleChange();
        this.props.action.ui.togglePayerSpecificCodesItem(this.getCrudActionInput<string>(id));
    }

    togglePayerSpecificCodesItems(newValue: boolean) {
        handleChange();
        this.props.action.ui.togglePayerSpecificCodesItems(this.getCrudActionInput<boolean>(newValue));
    }

    selectTab(event: React.MouseEvent<HTMLElement>, index: {selectedTabIndex:number}) {
        this.props.action.ui.selectTab(index.selectedTabIndex);
    }

    validateConfiguration(config: IClaimEventConfiguration): Partial<IModalConfirmationProps> | undefined {
        if (!config.displayName) return {
            ...defaultModalProps,
            isOpen: true,
            alertMode: true,
            message: 'Please enter a description.',
        };
        if (!config.facilities.some(facility => facility.isSelected)) {
            return {
                ...defaultModalProps,
                isOpen: true,
                alertMode: true,
                message: 'You must select at least one facility.',
            }
        }
        if (!flatMap(config.statusHistories).some(statusHistory => statusHistory.isSelected) && !config.claimNotes.some(note => note.isSelected)) {
            return {
                ...defaultModalProps,
                isOpen: true,
                alertMode: true,
                message: 'You must select at least one status code or claim note.',
            }
        }
        // counterintuitively, this means it's valid
        return undefined;
    }

    recoverFromSaveError() {
        this.props.action.ui.setCrudSaveError();
        this.resetPageState();
    }

    resetPageState() {
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiClaimEventNotification },
            (result: any) => {
                if (validationCallback(result)) {
                    this.props.action.ui.initializeClaimEventNotif(result)
                    return result;
                }
            }
        );
    }

    public renderTab() {
        const { selectedClaimConfiguration, tabIndex, searchTerm } = this.props.dataStore.ui;
        const {
            toggleStatusHistoryItems, toggleStatusHistoryItem, 
            toggleClaimNotesItem, toggleClaimNotesItems, 
            setSearchTerm, doSearch,
        } = this.props.action.ui;
            
        const {
            toggleSpecificPayersItem, toggleSpecificPayersItems,
            togglePayerSpecificCodesItem, togglePayerSpecificCodesItems,
        } = this;

        // The label is "Select None" when we consider Select All to have been selected.
        // The rule on these is you should see "Select All" if there is any item unselected
        const payersSelectAllIsSelected = selectedClaimConfiguration.specificPayers.every(option => option.isSelected);
        const payerSpecificCodesSelectAllIsSelected = selectedClaimConfiguration.visiblePayerSpecificCodes.every(option => option.isSelected);
        const localCodesIsSelectAll = selectedClaimConfiguration.statusHistories.LocalCodes.every(option => option.isSelected);
        const clearingHouseIsSelectAll = selectedClaimConfiguration.statusHistories.ClearinghouseCodes.every(option => option.isSelected);
        const payerCodesIsSelectAll = selectedClaimConfiguration.statusHistories.PayerCodes.every(option => option.isSelected);
        const status227CodesIsSelectAll = selectedClaimConfiguration.statusHistories.Status277Codes.every(option => option.isSelected);
        const claimNotesIsSelectAll = selectedClaimConfiguration.claimNotes.every(option => option.isSelected);
        const disabledClass = !this.props.canEdit ? 'is-disabled': '';
        switch (tabIndex) {
            case 0:
                return (
                    <FlexBoxModifier as={ContentRowWrapper} flexGap="20px" style={{ height: '100%' }}>
                        <ContentColumnWrapper style={{flex: '0 0'}}>
                            <SelectAllOrNoneComponent 
                                title='Local Codes' 
                                height='120px' 
                                selectAllIsSelected={localCodesIsSelectAll}
                                setAllOrNone={(toggleValue: boolean) => {
                                    toggleStatusHistoryItems({...this.baseModifyData, uiData:{section: 'LocalCodes', toggleValue}});
                                    handleChange();
                                }}
                                onCheck={(id: string) => {
                                    toggleStatusHistoryItem({...this.baseModifyData, uiData:{section: 'LocalCodes', id}});
                                    handleChange();
                                }}
                                records={selectedClaimConfiguration.statusHistories.LocalCodes}
                                className={disabledClass}
                            />
                            <SelectAllOrNoneComponent title='Clearinghouse Codes' 
                                height='120px' 
                                setAllOrNone={(toggleValue: boolean) => {
                                    toggleStatusHistoryItems({...this.baseModifyData, uiData:{section: 'ClearinghouseCodes', toggleValue}});
                                    handleChange();
                                }} 
                                onCheck={(id: string) => {
                                    toggleStatusHistoryItem({...this.baseModifyData, uiData:{section: 'ClearinghouseCodes', id}});
                                    handleChange();
                                }}
                                records={selectedClaimConfiguration.statusHistories.ClearinghouseCodes}
                                selectAllIsSelected={clearingHouseIsSelectAll}
                                className={disabledClass}
                            />
                        </ContentColumnWrapper>
                        <ContentColumnWrapper  style={{flex: '0 0'}}>
                            <SelectAllOrNoneComponent title='Payer Codes' 
                                height='120px' 
                                selectAllIsSelected={payerCodesIsSelectAll}
                                setAllOrNone={(toggleValue: boolean) => toggleStatusHistoryItems({...this.baseModifyData, uiData:{section: 'PayerCodes', toggleValue}})} 
                                onCheck={(id: string) => {
                                    toggleStatusHistoryItem({...this.baseModifyData, uiData:{section: 'PayerCodes', id}});
                                    handleChange();
                                }}
                                records={selectedClaimConfiguration.statusHistories.PayerCodes}
                                className={disabledClass}
                            />
                            <SelectAllOrNoneComponent title='277 Category Codes'
                                height='120px'
                                selectAllIsSelected={status227CodesIsSelectAll}
                                setAllOrNone={(toggleValue: boolean) => {
                                    toggleStatusHistoryItems({...this.baseModifyData, uiData:{section: 'Status277Codes', toggleValue}})
                                    handleChange();
                                }}
                                onCheck={(id: string) => toggleStatusHistoryItem({...this.baseModifyData, uiData:{section: 'Status277Codes', id}})} 
                                records={selectedClaimConfiguration.statusHistories.Status277Codes}
                                className={disabledClass}
                            />
                            <SelectAllOrNoneComponent title='Claim Notes'
                                height='120px'
                                selectAllIsSelected={claimNotesIsSelectAll}
                                setAllOrNone={(toggleValue: boolean) => {
                                    toggleClaimNotesItems({...this.baseModifyData, uiData:toggleValue})
                                    handleChange();
                                }}
                                onCheck={(id:string) => toggleClaimNotesItem({...this.baseModifyData, uiData:id})} 
                                records={selectedClaimConfiguration.claimNotes}
                                className={disabledClass}
                            />
                        </ContentColumnWrapper>
                    </FlexBoxModifier>)

            case 1:
                return (
                        <ContentRowWrapper style={{ height: '100%' }}>
                            <ContentColumnWrapper>
                                <SearchStyle>
                                    <Input
                                        autoComplete='off'
                                        width='100%'
                                        maxLength={30}
                                        domID="Search"
                                        id='search-input'
                                        className="text-input"
                                        label='Payer Search:'
                                        initialValue={searchTerm}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.currentTarget.value)}
                                    />
                                    <Button className='search-btn'
                                        domID="id-cen-search-button"
                                        name="SEARCH"
                                        buttonType="standard"
                                        width='60px'
                                        type="button"
                                        onClick={doSearch}
                                    />
                                </SearchStyle>
                                <SelectAllOrNoneComponent title='Available Payers' height='500px'
                                    onCheck={toggleSpecificPayersItem} 
                                    records={selectedClaimConfiguration.specificPayers}
                                    selectAllIsSelected={payersSelectAllIsSelected}
                                    setAllOrNone={toggleSpecificPayersItems} 
                                    className={disabledClass}
                                />
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <SelectAllOrNoneComponent title='Payer Specific Codes' height='560px'
                                    onCheck={togglePayerSpecificCodesItem}
                                    records={selectedClaimConfiguration.visiblePayerSpecificCodes}
                                    selectAllIsSelected={payerSpecificCodesSelectAllIsSelected}
                                    setAllOrNone={togglePayerSpecificCodesItems}
                                    className={disabledClass}
                                />
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                )

        }
     
    }

    private tabList: ITabItem[] = [{ label: 'Change Healthcare Codes', domID: 'id-CHC' }, { label: 'Payer Specific Codes', domID: 'id-payer-spec' }];
    private deleteWillFailMessage = <LongMessage>Please remove all Payers in the Payer Specific Codes tab. Save your changes, and then remove the configuration</LongMessage>;
    public render() {
        const buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onDialogOk()} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onDialogCancel(e)} disableOK={this.props.dataStore.ui.isDirty && !this.props.canEdit} />;
        const { tabIndex, claimEventConfigurations, isBusy, selectedClaimConfiguration, selectedConfigAction, crudSaveError} = this.props.dataStore.ui;
        const {openConfirm, closeConfirm} = this.props.action.confirm;
        const {facilities} = selectedClaimConfiguration;
        // at one point we would also pop up a modal based on Redux state, but we aren't doing that now
        let modalProps = this.state.modalProps;
        return (<DialogWrapper title='Claim Event Notification Maintenance'
            instruction={this.instructions}
            helpUrl='/Support/Help/HELP_Report_ClaimEvent.htm'
            width={'990px'}
            buttons={buttons}
            isBusy={isBusy}
        >
            <FlexBoxModifier as={ContentRowWrapper} flexGap='20px'>
                <LeftPane>
                    <SizedContainer as={DialogFieldset} percentWidth='99%' >
                        <DialogLegend>Saved Configuration</DialogLegend>
                        <SelectComponent
                            size={10} width='100%'
                            optionFields={{text: 'description', value:'id'}}
                            records={claimEventConfigurations}
                            selectedValue={selectedClaimConfiguration.id}
                            onSelect={this.selectConfiguration}
                        />
                        <FlexBoxModifier as={ContentRowWrapper} justifyContent='center' flexGap='20px'>
                            <Button
                                domID="id-cen-add-button"
                                name="ADD"
                                buttonType="standard"
                                width='60px'
                                type="button"
                                disabled={selectedConfigAction!=='ADD' || !this.props.canEdit}
                                onClick={this.addConfiguration}
                            />
                            <Button
                                domID="id-cen-remove-button"
                                name="REMOVE"
                                width='60px'
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={selectedConfigAction!=='REMOVE' || !this.props.canEdit}
                                onClick={this.onRemoveConfigurationClicked}
                            />
                        </FlexBoxModifier>
                    </SizedContainer>
                    <ARMNarrowInput
                        autoComplete='off'
                        id='description-input'
                        className="text-input"
                        label='Description:'
                        initialValue={selectedClaimConfiguration.displayName}
                        onBlur={this.updateConfigurationDisplayName}
                        disabled={!this.props.canEdit}
                    />
                    <SelectAllOrNoneComponent 
                        height='200px'
                        title='Facilities'
                        selectAllIsSelected={facilities.some(facility => facility.isSelected)}
                        setAllOrNone={this.props.action.ui.toggleFacilitiesItems}
                        onCheck={this.props.action.ui.toggleFacilitiesItem}
                        records={facilities}
                        className={this.props.canEdit? '': 'is-disabled'}
                    />
                </LeftPane>
                <ContentColumnWrapper style={{height: '750px'}} id='tab-container'>
                    <SizedContainer as={ArmTabContainer} tabs={this.tabList} percentWidth='100%' >
                        <Tabs tabs={this.tabList} onTabSelect={this.selectTab} selectedTabIndex={tabIndex} initialTab={tabIndex} />
                    </SizedContainer>
                    {this.renderTab()}
                </ContentColumnWrapper>
            </FlexBoxModifier>
            <ModalConfirmation
                isOpen={this.props.dataStore.confirm.isOpen}
                onModalToggle={closeConfirm}
                formattedMessage={leaveMessage}
                okText='Leave'
                cancelText='Stay'
                onConfirm={this.onModalOk}
            />
            <ModalConfirmation
                {...modalProps}
            />
        </DialogWrapper>);
    }
}

const connectedHoc = connect<IClaimEventNotificationState,
    IClaimEventNotificationActionProps,
    IOwnProps,
    IClaimEventNotificationProps,
    ApplicationState>(
    createCrudMapStateToProps('claimEventNotification'),
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ClaimEventNotification);
export default withRouter(connectedHoc);
