import { Reducer } from 'redux';
import { createAction, ActionTypes } from '../scripts/util/ActionHelpers';

export enum AuthType {
    LOGON_READY = "LOGON_READY",
    FORGOT_PASSWORD = "FORGOT_PASSWORD",
    EMAIL_REQUEST_RESPONSE = "EMAIL_REQUEST_RESPONSE",
    REVERIFY = "REVERIFY",
    SECURITY_QUESTION = "SECURITY_QUESTION",
    PASSWORD_RESET = "PASSWORD_RESET",
}

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AuthenticationState {
    current: AuthType
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    transition: (to: AuthType) => {
        return { type: 'TRANSITION', data: to }
    },
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<AuthenticationState, KnownActions> = (state: AuthenticationState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'TRANSITION':
                return { current: action.data };
            default:
                return state;
        }
    }
    return state || { current: AuthType.LOGON_READY}
}