
import React from "react";
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';

/**
 * INTERFACES
 */
interface IProgressiveHeadings {
    progressiveHeadings: Array<{ label: string, ID: string | number }>,
    activeItemIndex: string | number,
    parent: any,
};

/**
 * STYLED COMPONENTS
 */

interface ProgressiveHeadingProps {
    active: boolean;
}

export const ProgressiveHeading = styled.span<ProgressiveHeadingProps>`
    font-family: ${Typography.ARMFontFamily};
    margin-right: 20px;
    text-shadow: 0px 1px 1px #FFFFFF;
    color: #999999;
    cursor: pointer;
    ${(p) => p.active && `font-weight: 600; color: black; text-shadow: none`};
`;

export const ProgressiveHeadings = ({ progressiveHeadings, activeItemIndex, parent }: IProgressiveHeadings) => {

    const headings = progressiveHeadings.map((heading, index) => {

        return (<ProgressiveHeading
            key={heading.ID}
            active={index === activeItemIndex}
            onClick={() => {
                // We limit clicking to first two header items 
                // Disable clicking after form is printed
                if (index === 0 && !parent.state.isPrinted) {

                    parent.setState({ activeItemIndex: 0 });

                    //parent.setState({ isProceedAnyway: false })
                    /*
                    if (parent.state.areAllClaimsVerified) {
                        parent.setState({ isYesClicked: true })
                        parent.setState({isProceedAnyway: true})
                    }*/
                }

                if (index === 1 && !parent.state.isPrinted) {

                    parent.setState({ activeItemIndex: 1 });
                    //parent.setState({ isProceedAnyway: true })
                }
            }}
        >
            {`${index + 1}. `}{heading.label}
        </ProgressiveHeading>)
    })

    return (
        <React.Fragment>
            {headings}
        </React.Fragment>
    );

}