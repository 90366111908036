import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as PayerAddressUI from './ui/PayerAddressUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IPayerAddressState = ICrudComponentState<MCPayerAddress, PayerAddressUI.IPayerAddressUIState>;

export const actionCreators = createCrudComponentActions<PayerAddressUI.ActionCreators>(PayerAddressUI.actionCreators);
export type IPayerAddressActions = typeof actionCreators;
export type IPayerAddressActionProps = ICrudComponentActionProps<IPayerAddressActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiAEOtherPayer, PayerAddressUI.reducer);
export const reducer = combineReducers<IPayerAddressState>(reducers);

export const validationCallback: ValidationCallback<MCPayerAddress> = (crud: any): crud is MCPayerAddress => {
    let testCrud: MCPayerAddress = crud as MCPayerAddress;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCPayerAddress");
    if (validShape) {
             
           if (testCrud.AEOtherPayerMaintenanceInfo?.Payers === null) {
            testCrud.AEOtherPayerMaintenanceInfo.Payers = { Payer: new Array<MCPA_Payer>() };
        }

        if (testCrud.AEOtherPayerMaintenanceInfo.Payers &&
            !Array.isArray(testCrud.AEOtherPayerMaintenanceInfo.Payers.Payer)) {
           let arr = new Array<MCPA_Payer>();
             arr.push(JSON.parse(JSON.stringify(testCrud.AEOtherPayerMaintenanceInfo.Payers.Payer)));
        }
    }
    return validShape;
}