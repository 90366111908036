import * as React from 'react';
// pipe dream: import React, { useState } from 'react';
import * as wind from '../../commonResources/window';
import styled, { css } from 'styled-components';
import { Colors } from '../../commonResources/colorVariables';
import { Typography } from '../../commonResources/typography';
import { LandingPageComponent, IComponentProps, } from './LandingPageComponent';
import { ARMComponentError } from './ARMComponentError';
import * as MsgListStyles from './ARMMessageListComponent';
import { MessageBodyContent } from '../Home/MessageBodyContent';
import { ARMLandingPageHeaderWrapper } from './ARMLandingPageHeader';
import { URLs } from '@commonDevResources/constants';
import { getRawToken, getSecurityToken } from '@scripts/session/SecurityToken';

import refreshIcon from '@content/images/LandingPage/refresharrow.png'
import msgListImgNewMessage from "@content/images/LandingPage/Message/NewMessage.gif";
import msgListImgOldMessage from "@content/images/LandingPage/Message/OldMessage.gif";

import msgListImgSeverityWarning from "@content/images/LandingPage/Message/Warning.gif";
import msgListImgSeverityInfoUp from "@content/images/LandingPage/Message/infoUp.gif";
import msgListImgSeverityGreen from "@content/images/LandingPage/Message/SeverityLevelsGreen.gif";
import msgListImgSeverityOrange from "@content/images/LandingPage/Message/SeverityLevelsOrange.gif";
import msgListImgSeverityRed from "@content/images/LandingPage/Message/SeverityLevelsRed.gif";
import msgListImgLink from "@content/images/LandingPage/Message/link.gif";

import blackArrowIcon from '@content/images/LandingPage/Message/black_arrow.gif'
import FileViewer from '../common/FileViewer';
import { ReactElement } from 'react';
import _ from 'lodash';
import ErrorBoundary from '@common/ErrorBoundary';

export const MessageContentContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    font-size: ${Typography.small};
    width: 100%;
    height: 308px;
    overflow-Y: auto
    }
`;

export const DialogContainerTop = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: ${Typography.small};
    width: 100 %;
    max-height: 25px;
    }
`;

export const DialogContainerBottom = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: ${Typography.small};
    width: 99%;
    max-height: 17px;
    }
`;

export const RefreshLink = styled.div`
    cursor: pointer;
    font-size: 12px;
    padding-left: 2px;
`;

export const ContentColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: 348px;
`;

export const MessageClickLink = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    ${ Typography.defaultLineHeight};

    .link-button {
    ${Typography.defaultLineHeight};
    ${ Typography.medium};
    color: ${Colors.white};
    padding: 0px;
    &:hover {
        text-decoration-color: ${Colors.babyBlue};
    }
    }
`;

export const MessageTypeDropdownWrapper = styled.div`
    background-color: ${Colors.white};
    border-radius: 3px;
`;

interface IARMMessageProps extends IComponentProps {
}

interface IARMMessageState {
    highlightedRowId: number;
    rowClicked: boolean;

    errorCode?: any | null;
    msgError?: any | null;
    msgErrorInfo?: any | null;
    msgType?: any | null;
    msgResponse?: any | null;
    selectedMessageId: string;
}

type dataRef = {
    Data: [];
    Ticks: [];
    UserId: Number;
    FromDate: Date;
    ThruDate: Date;
    CountTicks: any;
    AmountTicks: any;
};

export class MessageRow {
    id: number;
    clientId: number;
    groupId: number;
    isRead: boolean;
    message: string;
    messageDateTime: string;
    messageSeverity: eSeverity;
    messageSubject: string;
    messageType: number;
    msgTitle: string;
    userId: number;
    index: number;
    isRowHighLighted: boolean;

    constructor() {
        this.id = -1;
        this.clientId = -1;
        this.groupId = -1;
        this.isRead = false;
        this.message = '';
        this.messageDateTime = '';
        this.messageSeverity = eSeverity.Information;
        this.messageSubject = '';
        this.messageType = -1;
        this.msgTitle = '';
        this.userId = -1;
        this.index = -1;
        this.isRowHighLighted = false;
    }
}

enum eSeverity {
    Information = 0,
    Warning = 1,
    Minor = 2,
    Major = 3,
    Critical = 4
}

// stole from the MyClaims component...
export function MessageListTableRow(props: { data: MessageRow, serverDate: any, onClickMsg: any, onRowEnter: any, onRowExit: any, setSelectedMessageId: any }) {
    const { data, serverDate, onClickMsg, onRowEnter, onRowExit, setSelectedMessageId } = props;

    //alert("serverDate " + serverDate);
    var altRead = "Read";

    //e.g. "2020-10-26T10:06:00"
    let msgDate = data?.messageDateTime;
    let severityOf = data?.messageSeverity;

    // used for server time on top left (_serverTime): let timeOf = serverDate.substr(6, 5);
    let dateOf = serverDate.substr(0, 5);

    let firstTime = msgDate?.toLowerCase().indexOf('t');
    let msgTime = msgDate?.substr(firstTime + 1, 5);
    let firstDash = msgDate?.indexOf('-');
    msgDate = msgDate?.substr(firstDash + 1, 5);
    msgDate = msgDate?.replace('-', '/');

    const [highlightedRowId, setHighlightedRowId] = React.useState(0);
    let highLightRow = highlightedRowId == data.id ? true : false;

    function handleClickMsg() {
        onClickMsg(data);
    }

    function handleMouseOver() {
        setHighlightedRowId(data.id);
        onRowEnter(data);
    }

    function handleMouseOut() {
        setHighlightedRowId(-1);
        onRowExit(data);
    }

    let isToday = msgDate == dateOf ? true : false;

    let daterId = data?.id?.toString();
    let shortTitleOf = data?.message;
    if (shortTitleOf?.length > 67)
        shortTitleOf = shortTitleOf?.substr(0, 67) + '...';

    let subjectClass = 'msgListLegacySubject'; // in LandingPage.css  // debuke says: this good?
    let rowClass = 'msgListLegacyRow';

    let todayStyle = isToday == true ? MsgListStyles.msgListLegacyRowStyleToday : MsgListStyles.msgListLegacyRowStyleNotToday;
    let readStatusIcon = data?.isRead == false ? msgListImgNewMessage : msgListImgOldMessage;
    let dateTitle = isToday == true ? 'Today' : msgDate;
    let severityIcon = msgListImgSeverityInfoUp;
    let severityAlt = "Information";
    switch (severityOf) {
        case eSeverity.Warning:
            severityAlt = "Warning";
            severityIcon = msgListImgSeverityWarning;
            break;
        case eSeverity.Minor:
            severityAlt = "Minor";
            severityIcon = msgListImgSeverityGreen;
            break;
        case eSeverity.Major:
            severityAlt = "Major";
            severityIcon = msgListImgSeverityOrange;
            break;
        case eSeverity.Critical:
            severityAlt = "Critical";
            severityIcon = msgListImgSeverityRed;
            break;
    }

    let linkText = data?.messageSubject; // e.g. <a href='javascript:window.parent.OpenDocument(' /Sites / 6 / Reports /1502_6_72_dbuchanan_20200909095132737.pdf', 'ePREMISReport')' > Payer Address Auto Entry</a >
    let linkTag = linkText?.match(/<a\shref=.javascript:[^>]+/i);

    // debuke says: testmode here when we in the testmode page...

    const patternDownload = 'DownloadDocument(\'';
    let startDoc = -1, endDoc = -1;
    startDoc = linkText?.indexOf('OpenDocument(\'');
    if (startDoc < 0) { // did not find OpenDocument, look for DownloadDocument pattern
        startDoc = linkText?.indexOf(patternDownload);
        if (startDoc >= 0) { // found DownloadDocument pattern.
            startDoc = startDoc + patternDownload.length + 1;
            endDoc = linkText?.indexOf("')", startDoc + 2);
        }
    }
    else { //found OpenDocument pattern
        startDoc = startDoc + 15;
        endDoc = linkText?.indexOf('\',', startDoc + 2);
    }
    const doc = linkText?.substr(startDoc - 1, endDoc - startDoc + 1); // get mixed case version...

    // Grab where the extension is via index
    //let docExtIndex: number = 0
    //const docDepIndex: number = doc.indexOf('.dep');
    //const docPdfIndex: number = doc.indexOf('.pdf');
    //const docXmlIndex: number = doc.indexOf('.xml');
    //const docTxtIndex: number = doc.indexOf('.txt');
    //const docCsvIndex: number = doc.indexOf('.csv')

    // Checks for extension
    //if (docDepIndex > 0) {
    //    docExtIndex = docDepIndex;
    //} else if (docPdfIndex > 0) {
    //    docExtIndex = docPdfIndex;
    //} else if (docXmlIndex > 0) {
    //    docExtIndex = docXmlIndex
    //} else if (docTxtIndex > 0) {
    //    docExtIndex = docTxtIndex
    //} else if (docCsvIndex > 0) {
    //    docExtIndex = docCsvIndex
    //}

    linkText += ' onclick="event.cancelBubble = true;"';
    //const fileLink: string = '/shared/Open%3D\/' + doc.substring(0, docExtIndex + 4); // will need to account for other extensions; Currently for: .dep, .pdf, .xml, .txt, .csv
    const fileLink: string = '/shared/Open%3D\/' + doc
    if (!isToday) {
        return (
            <tr id={daterId} className={rowClass} style={{ height: '15px', lineHeight: '15px', cursor: 'pointer', backgroundColor: ((highLightRow) ? Colors.defaultLight : Colors.fainterGrey), borderSpacing: '0px', borderCollapse: 'separate', border: '1px green' }} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <td id={"MessageImgHomeID"} style={MsgListStyles.msgListLegacyReadStatusStyle} onClick={() => setSelectedMessageId(daterId)} >
                    <img alt={altRead} src={readStatusIcon} />
                </td>
                <td title={dateTitle} style={MsgListStyles.msgListLegacyItemStyle} onClick={handleClickMsg} >{msgDate}</td>
                <td style={MsgListStyles.msgListLegacyItemStyleFlex} onClick={handleClickMsg} >
                    <img style={MsgListStyles.msgAlignMiddleStyle} alt={severityAlt} src={severityIcon} />
                    <div title={shortTitleOf} className={subjectClass} style={MsgListStyles.msgSubjectStyle}>{shortTitleOf}</div>
                </td>
                {(linkTag &&
                    <td style={MsgListStyles.msgListPaddingRight2px}>
                        <FileViewer name={<img src={msgListImgLink} alt="Open" />} key={daterId} fileLink={fileLink} />
                    </td>)
                }
                {(!linkTag &&
                    <td style={MsgListStyles.msgListPaddingRight2px} onClick={handleClickMsg} >
                    </td>)
                }
            </tr>
        );
    } else {
        return (
            <tr id={daterId} className={rowClass} style={{ height: '15px', lineHeight: '15px', cursor: 'pointer', backgroundColor: ((highLightRow) ? Colors.defaultLight : Colors.digitalOrange15), borderSpacing: '0px', borderCollapse: 'separate', border: '1px green' }} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
                <td id={"MessageImgHomeID"} style={MsgListStyles.msgListLegacyReadStatusStyle} onClick={() => setSelectedMessageId(daterId)} >
                    <img alt={altRead} src={readStatusIcon} />
                </td>
                <td title={dateTitle} style={MsgListStyles.msgListLegacyItemStyle} onClick={handleClickMsg} >{msgTime}</td>
                <td style={MsgListStyles.msgListLegacyItemStyle} onClick={handleClickMsg} >
                    <img style={MsgListStyles.msgAlignMiddleStyle} alt={severityAlt} src={severityIcon} />
                    <div title={shortTitleOf} className={subjectClass} style={MsgListStyles.msgSubjectStyle}>{shortTitleOf}</div>
                </td>
                {(linkTag &&
                    <td style={MsgListStyles.msgListPaddingRight2px}>
                        <FileViewer name={<img src={msgListImgLink} alt="Open" />} key={daterId} fileLink={fileLink} />
                    </td>)
                }
                {(!linkTag &&
                    <td style={MsgListStyles.msgListPaddingRight2px} onClick={handleClickMsg} >
                    </td>)
                }
            </tr>
        );
    }
    // debuke says: make sure you fix the today by copying down the top and changing highlight color to Today!
}

export default class ARMMessageComponent extends React.Component<IARMMessageProps, IARMMessageState>
{
    static _serverTime: string = '';

    constructor(props: IARMMessageProps) {
        super(props);

        var initStyle: { [k: string]: any } = {};
        initStyle.backgroundColor = Colors.defaultLight;//"#c3c5cd";

        this.state = {
            msgError: null,
            msgErrorInfo: null,
            msgType: 'All',
            highlightedRowId: -1,
            rowClicked: false,
            selectedMessageId: "",
        };
    }

    async componentDidMount() {
        let clientId = sessionStorage.getItem("Client");

        let msgExists = sessionStorage.getItem("SystemErrorNotification");
        if (msgExists && msgExists.length > 0) {
            sessionStorage.setItem('SystemErrorNotification', '');
            alert(msgExists);
        }

        await this.fetchMessages();
    }

    componentWillUnmount() {
        console.warn('ARMMessageComponent::componentWillUnmount()');
    }


    Messages_onClick() {
    }

    MessageGoToLink_onClick() {
        wind.goTo("/ARM.UI/Home/Messages", '', '', '');
    }

    MessagesRefreshButton_onClick() {
        console.log('refreshing Message Component...');
        this.setState({ msgResponse: '{}' });
        setTimeout(() => { this.fetchMessages() }, 50);
    }

    MessageList_onClick_Message(item: any) {
        wind.goTo("/ARM.UI/Home/Messages/" + item.id, '', '', '');
    }


    // this rowData is not the html event, it's just the data row from the json results...
    MessageList_handleMouseOver(event: any) {
        //Required Handler
    }

    MessageList_handleMouseOut(event: any) {
        //Required Handler
    }

    /*
    example message coming back from call:
    <Messages Total="0" NumPages="0" PageNum="0" ServerTime="07/20 09:38">
        <Message ID="3000000004" ClientID="7" UserID="73" Type="2" Severity="0" IsRead="0" Date="07/20/2020" Time="09:38:13" DateTime="Jul 20 2020  9:38AM">
            <Subject>
                <![CDATA[Claim Status report generated successfully - Filter:  N/A]]>
            </Subject>
            <Body>
                <![CDATA[<a href='javascript:window.parent.OpenDocument("/Sites/7/Reports/1201_7_73_dbuchanan_20200720093813021.pdf", "ePREMISReport")'>Claim Status - Filter:  N/A</a>]]>
            </Body>
        </Message>
    </Messages>
     */
    processResponse(data: any) {
        let jsonResponse = data;
        this.setState({
            msgResponse: jsonResponse
        });
    }

    /*
    XMLHttpRequest.readyState:
        0   : UNSENT            :   Client has been created. open() not called yet.
        1   : OPENED            :   open() has been called.
        2   : HEADERS_RECEIVED  :   send() has been called, and headers and status are available.
        3   : LOADING           :   Downloading; responseText holds partial data.
        4   : DONE              :   The operation is complete.
     */
    fetchMessages = async () => {
        var typeOf = 'All'; // default
        var messageType = typeOf?.toUpperCase();
        if (this.state.msgType != '' && this.state.msgType != null) {
            typeOf = this.state.msgType;
            messageType = typeOf?.toUpperCase();
        }
        var urlOf = URLs.api + '/api/data/AsyncMessages?msgOptions=' + messageType;
        try {
            
            const response = await fetch(urlOf, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
            });
            const data = await response.text();
            if (response.status === 306) {
                console.error(data); // debuke says: why this 306? PASSWORD RESET CODE: 306...
            }
            else {
                this.processResponse(data);
            }
        } catch (e) {
            console.log(e);
        } 

    }

    RecipientComboBox_onClick(control: any) {
        /*
            Options:
            All
            User
            Client

            append : Subject- 
            to the front of each...
         */

        console.log("control.target.value: " + control.target.value);
        let msgOption = control.target.value;

        // debuke says: test mode -Test is needed here...

        this.setState({ msgType: msgOption }, this.fetchMessages);
    }

    renderMessagesError() {
        return (<ARMComponentError />);
    }

    renderMsgBox() {
        let weHaveMsgs = false;
        let jsonMsgList: any;
        // TODO: should this be renderMessagesError()? Note, will affect unit tests
        const serverError = <div style={{ marginTop: 30 }}>Server Error</div>;
        if (this.state.msgResponse != 'undefined' && this.state.msgResponse != null && this.state.msgResponse != '') {
            try {
                //if server sends an html error response instead of JSON, that will bork JSON.parse
                jsonMsgList = JSON.parse(this.state.msgResponse as string);
                weHaveMsgs = true;
                if (jsonMsgList.messageList && jsonMsgList.messageList.length > 0) {
                    console.log("ARMMessageComponent::jsonMsgList::length: " + jsonMsgList.messageList.length);
                }
            } catch (e) {
                return serverError;
            }
        }

        // TODO: we probably need a larger refactor than I'm doing here, but will affect the entire design of the component
        // For some reason even though JSON.parse throws the error, the catch doesn't hit and the try finishes. Check and fix
        if (typeof jsonMsgList !== 'object' && weHaveMsgs) return serverError;

        // tbd: let testMode = false;
        if (weHaveMsgs && Object.keys(jsonMsgList).length >0) {
            let serverDate = jsonMsgList?.serverTime ?? '';
            
            ARMMessageComponent._serverTime = serverDate.substr(6, 5);

            if (!jsonMsgList.messageList || (jsonMsgList.messageList && jsonMsgList.messageList.length == 0)) {
                return <div id="tabsNotifications" className="msgListLegacyPageStyleNoMessages" style={MsgListStyles.msgListLegacyPageStyle}>No recent messages available.</div>
            } else {
                return (
                        <table className="msgListLegacyPage" style={MsgListStyles.msgListLegacyPageStyle}>
                            <tbody>
                            {jsonMsgList.messageList.map((row: any, index: any) =>
                                <div>
                                    {this.state.selectedMessageId === row?.id?.toString()
                                        ? this.messageBodyPreview(row?.messageSubject)
                                        : null }
                                    <MessageListTableRow
                                        key={row.id + Date.now()}
                                        data={row}
                                        serverDate={serverDate}
                                        onClickMsg={this.MessageList_onClick_Message.bind(this)}
                                        onRowEnter={this.MessageList_handleMouseOver.bind(this)}
                                        onRowExit={this.MessageList_handleMouseOut.bind(this)}
                                        setSelectedMessageId={(selectedMessageId: string) => {
                                            this.setState({
                                                selectedMessageId
                                            })
                                        }}/>
                                </div>
                            )}
                            </tbody>
                        </table>
                );
            }
        }
        return (<div id="test-noRecords"/>);
    }

    messageBodyPreview = (messageSubject: string) => {
        document.addEventListener("click", this.onClickOutsidePreview);

        return (
            <div
                id={"MessageImgHomeID"}
                key={Date.now()} style={{
                    position: "absolute",
                    overflowY: 'scroll',
                    width: "500px",
                    height: "200px",
                    top: "100px",
                    left: "20px",
                    border: "1px solid",
                    backgroundColor: Colors.fainterGrey
                }}>
                <MessageBodyContent isPreview message={messageSubject} />
            </div>
        )
    }

    onClickOutsidePreview = (event: any) => {
        let targetIsPreviewElement = event.target.id === "MessageImgHomeID"
        let parentIsPreviewElement = event.target.closest("#MessageImgHomeID")

        if (!parentIsPreviewElement && !targetIsPreviewElement) {
            this.setState({
                selectedMessageId: ""
            }, () => document.removeEventListener("click", this.onClickOutsidePreview))
        }
    }

    renderTopMessageComponents() {
        const securityInfo = getSecurityToken();
        let userName = sessionStorage.getItem("Username");
        let clientAlias = sessionStorage.getItem("ClientAlias");

        return (
            <div id="topMessageComponents" style={MsgListStyles.messageTopContentContainerBarStyle} >
                <table className="messageTopContentContainer" style={MsgListStyles.messageTopContentContainerStyle} >
                    <tbody>
                        <tr className="contentActions" style={MsgListStyles.VAlignTopStyle}>
                            <td style={MsgListStyles.msgListActionsStyle}>
                                <span>Most recent messages for   </span>

                                <select name="RecipientComboBox" id="RecipientComboBox" style={MsgListStyles.msgListRecipientStyle} onChange={this.RecipientComboBox_onClick.bind(this)}>
                                    <option id="All" value="All">All</option>
                                    <option id="User" value="User">{userName}</option>
                                    <option id="Client" value="Client">CID {clientAlias}</option>
                                </select>
                            </td>
                            <td style={MsgListStyles.msgListCurrentServerTimeStyle}>
                                <div id="ServerTimeText" style={MsgListStyles.msgListCurrentServerTimeDivStyle}>Current Server Time: {ARMMessageComponent._serverTime}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderMessageList() {
        var titleStyle: React.CSSProperties = {
            textAlign: 'center',
            fontSize: '12px',
            fontWeight: 'bolder',
            paddingTop: '5'
        };

        var msgBox = this.renderMsgBox();
        var topMessageComponents = this.renderTopMessageComponents();

        return (<LandingPageComponent ComponentClass={this.props.Name} {...this.props}>
            <ARMLandingPageHeaderWrapper
                title='Messages' height='412px' width='625px'
                helpUrl='/Support/Help/HELP_HomePage_MessageList.htm'
                isLegacy={true}  //TODO - Fix to get ARM.UI to Publish
            >
                <ContentColumnWrapper>
                    <DialogContainerTop>
                        {topMessageComponents}
                    </DialogContainerTop>
                    <MessageContentContainer>
                        {msgBox}
                    </MessageContentContainer>
                    <DialogContainerBottom>
                        <RefreshLink className="ReadMessagesLink" onClick={this.MessageGoToLink_onClick.bind(this)}>
                            <label style={MsgListStyles.msgListUnderline}>Read Messages</label><img src={blackArrowIcon} style={MsgListStyles.msgListBlackArrow} />
                        </RefreshLink>
                        <RefreshLink className="RefreshMessagesLink" onClick={this.MessagesRefreshButton_onClick.bind(this)}>
                            <img src={refreshIcon} />
                            <label style={MsgListStyles.msgListUnderline}>Refresh</label>
                        </RefreshLink>
                    </DialogContainerBottom>
                </ContentColumnWrapper>
            </ARMLandingPageHeaderWrapper>
        </LandingPageComponent>);
    }

    render() {
        if (this.state.errorCode || this.state.msgError || this.state.msgErrorInfo) {
            return this.renderMessagesError();
        } else {
            console.log('rendering msg list...');
            if (this.state.errorCode == 10101)
                throw "Crash Test, Dummies!";
            return (<ErrorBoundary>
                        {this.renderMessageList()}
                    </ErrorBoundary>);
        }
    };
};
