import * as React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import { LandingPageBody } from './styles/LandingPageStyles';
import LoadComponents from './LoadComponents';

export default class LandingPage extends React.Component<RouteComponentProps<{}>, {}>
{
    render() {
        return (
            <LandingPageBody>
                <LoadComponents {...this.props}/>
            </LandingPageBody>
        );
    }
};