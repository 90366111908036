import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// STATE - This defines the type of data maintained in the Redux store.

export interface IUserPreferencesUIState {
    deltaCrud: MCARMUserPreferencesDelta;
    deltaCrudInitialized: boolean;
    selectedSavedFilter: ISelectSavedFilterData;
    selectedFavoriteFilter: ISelectFavoriteFilterData;
    selectedHomePageFilter: ISelectHomePageFilterData;
    selectedTabIndex: number;
   
    selectedAllSavedFilters: string[];
    selectedAllFavoriteFilters: string[];
    selectedAllHomePageFilters: string[];

    defaultSavedFilters: MCUserPrefsFilter[];
    defaultFavoriteFilters: MCUserPrefsFilter[];
    defaultHomePageFilters: MCUserPrefsFavoriteHomeFilter[];

    sortConfigReleasedId: string;
    sortConfigUnreleasedId: string;
    sortConfigUser: string;
    email: string;
    immediate: string;

    selectedSchedule: MCUserPrefsEvent;
    selectedScheduleChanged: boolean;
    changed: boolean;
    maxHomePageFilters: number;

    removeScheduleConfirm?: string;
    userPrefsEmailError: string;
    favFilterErrorMsg: string;
    scheduleErrorMsg: string;
    homePageFilterErrorMsg: string;
    selectedClaimsAssigned: string;

    userPrefsPageDirty: boolean;
    showConfirmRedirectModal: boolean;
};

interface IUserPrefsUIError {
    value: string,
    id?: string
}

interface IUserPreferencesUiFieldUpdate {
    value: string;
    selection: SortConfigurationTypes;
}

interface IUserPreferencesUiUpdate {
    value: string;
}

export interface IUserPreferencesDayOfWeekUIData {
    index: string;
    value: boolean;
};

export interface IUserPreferencesUIData {
    id: string;
    text: string;
};

export interface ISelectSavedFilterData {
    index: number;
    value: string;
    text: string;
};

export interface ISelectFavoriteFilterData {
    index: number;
    value: string;
    text: string;
};

export interface ISelectHomePageFilterData {
    index: number;
    value: string;
    text: string;
};

export interface IUIAllSelectedSavedFilter {
    selected: string[];
}

export interface IUIAllSelectedFavoriteFilter {
    selected: string[];
}

export interface IUIAllSelectedHomePageFilter {
    selected: string[];
}

enum SortConfigurationTypes {
    Released,
    Unreleased,
    User
}

//export interface ISelectFlsGroup extends ICrudActionData<MCFieldLevelSecurity, ISelectFlsGroupData> { }
// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

//export interface ISelectFilterGroup extends ICrudActionData<MCARMUserPreferences, ISelectFilterGroupData> { }
export interface ISelectSavedFilter extends ICrudActionData<MCARMUserPreferences, ISelectSavedFilterData> { }
export interface ISelectFavoriteFilter extends ICrudActionData<MCARMUserPreferences, ISelectFavoriteFilterData> { }
export interface ISelectHomePageFilter extends ICrudActionData<MCARMUserPreferences, ISelectHomePageFilterData> { }

export interface ISelectFavoriteFiltersGroup extends ICrudActionData<MCARMUserPreferences, ISelectFavoriteFilterData> { }
export interface ISelectHomePageFiltersGroup extends ICrudActionData<MCARMUserPreferences, ISelectHomePageFilterData> { }

export interface ISelectSavedFilterList extends ICrudActionData<MCARMUserPreferences, IUIAllSelectedSavedFilter> { }
export interface ISelectFavoriteFilterList extends ICrudActionData<MCARMUserPreferences, IUIAllSelectedFavoriteFilter> { }
export interface ISelectHomePageFilterList extends ICrudActionData<MCARMUserPreferences, IUIAllSelectedHomePageFilter> { }

export interface IModifyFavoriteFilter extends ICrudActionData<MCARMUserPreferences, ISelectHomePageFilterData> { }
export interface IModifyHomePageFilter extends ICrudActionData<MCARMUserPreferences, ISelectHomePageFilterData> { }

export interface IModifyClaimOption extends ICrudActionData<MCARMUserPreferences, IUserPreferencesUiFieldUpdate> { }
export interface INotifyClaimOption extends ICrudActionData<MCARMUserPreferences, IUserPreferencesUiFieldUpdate> { }
export interface IUpdateEmail extends ICrudActionData<MCARMUserPreferences, IUserPreferencesUiUpdate> { }

export interface IUpdateImmediate extends ICrudActionData<MCARMUserPreferences, IUserPreferencesUiUpdate> { }
export interface ISelectSchedule extends ICrudActionData<MCARMUserPreferences, IUserPreferencesUIData> { }
export interface ISelectDayOfWeek extends ICrudActionData<MCARMUserPreferences, IUserPreferencesDayOfWeekUIData> { }
export interface IUpdateUserPreferencesField extends ICrudActionData<MCARMUserPreferences, IUserPreferencesUiUpdate> {}
export interface IAddModifyUserPrefsSchedule extends ICrudActionData<MCARMUserPreferences, MCUserPrefsEvent> {}

export interface IError extends ICrudActionData<MCAutoListType, IUserPrefsUIError> { }

// can't add or remove groups from this page: driven from GroupMaster
export const actionCreators = {
    selectSavedFilter: (selectSavedFilter: ISelectSavedFilter) => createDataAction('SELECT_USERPREF_SAVED_FILTER', selectSavedFilter),
    
    selectAllSavedFilters: (selectSavedFilters: ISelectSavedFilterList) => createDataAction('SELECT_USERPREF_ALLSAVEDFILTERS', selectSavedFilters),
    selectAllFavoriteFilters: (selectFavoriteFilters: ISelectFavoriteFilterList) => createDataAction('SELECT_USERPREF_ALLFAVORITEFILTERS', selectFavoriteFilters),
    selectAllHomePageFilters: (selectHomePageFilters: ISelectHomePageFilterList) => createDataAction('SELECT_USERPREF_ALLHOMEPAGEFILTERS', selectHomePageFilters),
  
    addFavoriteFilterFields: (favoriteFilterAddFields: ISelectFavoriteFiltersGroup) => createDataAction('ADD_USERPREF_FAV_FILTER_FIELDS', favoriteFilterAddFields),
    removeFavoriteFilterFields: (favoriteFilterRemoveFields: ISelectFavoriteFiltersGroup) => createDataAction('REMOVE_USERPREF_FAV_FILTER_FIELDS', favoriteFilterRemoveFields),
    addHomePageFilterFields: (homePageFilterAddFields: ISelectFavoriteFiltersGroup) => createDataAction('ADD_USERPREF_HOMEPAGE_FILTER_FIELDS', homePageFilterAddFields),
    removeHomePageFilterFields: (removeHomePageFilterFields: ISelectFavoriteFiltersGroup) => createDataAction('REMOVE_USERPREF_HOMEPAGE_FILTER_FIELDS', removeHomePageFilterFields),
    
    moveHomePagesUp: (filterId: IModifyHomePageFilter) => createDataAction('MOVE_USERPREF_HOMEPAGES_UP', filterId), // UP
    moveHomePagesDown: (filterId: IModifyHomePageFilter) => createDataAction('MOVE_USERPREF_HOMEPAGES_DOWN', filterId), // DOWN
    
    updateClaimOption: (selectClaimOption: IModifyClaimOption) => createDataAction('UPDATE_USERPREF_CLAIM_OPTION', selectClaimOption),
    updateNotifyOption: (updateNotifyOption: INotifyClaimOption) => createDataAction('UPDATE_USERPREF_NOTIFY_OPTION', updateNotifyOption),
    updateEmail: (updateEmail: IUpdateEmail) => createDataAction('UPDATE_USERPREF_EMAIL', updateEmail),
    updateImmediate: (fieldInfo: IUpdateImmediate) => createDataAction('UPDATE_USERPREF_IMMEDIATE', fieldInfo),

    selectDayOfWeek: (fieldInfo: ISelectDayOfWeek ) => createDataAction('UPDATE_USERPREF_DAYOFWEEK', fieldInfo),
    selectSchedule: (selectSchedule: ISelectSchedule) => createDataAction('SELECT_USERPREF_SCHEDULE', selectSchedule),
    
    updateSelectedScheduleHr: (fieldInfo: IUpdateUserPreferencesField) => createDataAction('UPDATE_USERPREF_SCHEDULE_HR', fieldInfo),
    updateSelectedScheduleMin: (fieldInfo: IUpdateUserPreferencesField) => createDataAction('UPDATE_USERPREF_SCHEDULE_MIN', fieldInfo),
    addUserPreferencesSchedule: (addInfo: IAddModifyUserPrefsSchedule) => createDataAction('ADD_USERPREFS_SCHEDULE', addInfo),
    updateUserPreferencesSchedule: (updateInfo: IAddModifyUserPrefsSchedule) => createDataAction('UPDATE_USERPREFS_SCHEDULE', updateInfo),
    removeUserPrefsSchedule: (fieldInfo: IUpdateUserPreferencesField) => createDataAction('REMOVE_USERPREFS_SCHEDULE', fieldInfo),

    errorUserPrefsRemoveScheduleConfirm: (fieldInfo: IUpdateUserPreferencesField) => createDataAction('ERROR_USERPREFS_REMOVE_SCHEDULE_CONFIRM', fieldInfo),
    removeErrorMsg: (fieldInfo: IUpdateUserPreferencesField) => createDataAction('USERPREFS_ALERT_CLOSE', fieldInfo),
    addFavFilterErrorMsg: (fieldInfo: IUpdateUserPreferencesField) => createDataAction('USERPREFS_FAVFILTER_ERROR', fieldInfo),
    addScheduleErrorMessage: (fieldInfo: IUpdateUserPreferencesField) => createDataAction('USERPREFS_SCHEDULE_ERROR', fieldInfo),

    emailErrorMessage: (fieldInfo: IError) => createDataAction('ERROR_USERPREFS_EMAIL', fieldInfo),
    toggleRedirectConfirmModal: (show: boolean) => createDataAction('TOGGLE_USERPREFS_CONFIRM_REDIRECT', show),
    selectTabIndex: (tabIndex: number) => createDataAction('SELECT_USERPREF_TAB_INDEX', tabIndex),
    setupUserPreferences: (info: IUpdateUserPreferencesField) => createDataAction('USERPREFS_SETUP', info),
    resetUserPreferences: (info: IUpdateUserPreferencesField) => createDataAction('USERPREFS_RESET', info),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultSavedFilter: ISelectSavedFilterData = {
    index: 0,
    value: '',
    text: '',
};

export const defaultHomePageFilter: ISelectHomePageFilterData = {
    index: 0,
    value: '',
    text: '',
};

export const defaultFavoriteFilter: ISelectFavoriteFilterData = {
    index: 0,
    value: '',
    text: '',
};

const emptyEvent: MCUserPrefsEvent = {    
    '@ID': '',
    '@Time': '00:00:00',
    '@Mon': '',
    '@Tue': '',
    '@Wed': '',
    '@Thu': '',
    '@Fri': '',
    '@Sat': '',
    '@Sun': ''
};

const emptyDelta: MCARMUserPreferencesDelta = 
{
    UserPreferencesMaintenanceInfo: 
    {
        FavoriteFilterMaintenanceInfo:
        {
            FavoriteFilters: {},
            ClaimSortInfo:
            {
                SortConfigurations: {}
            }
        },
        NotificationMaintenanceInfo: {
            Configuration: 
            {
                '@ID': '',
                '@Immediate': '',
                '@NotifyTypeId': '',
                '@EmailAddress': '',
                Events: {}
            }
        }

    }

}


export const defaultState: IUserPreferencesUIState = {
    deltaCrud: emptyDelta,
    deltaCrudInitialized: false,
    selectedSavedFilter: defaultSavedFilter,
    selectedHomePageFilter: defaultHomePageFilter,
    selectedFavoriteFilter: defaultFavoriteFilter,
    selectedAllHomePageFilters: [],
    selectedAllSavedFilters: [],
    selectedAllFavoriteFilters: [],
    defaultSavedFilters: [],
    defaultFavoriteFilters: [],
    defaultHomePageFilters: [],
    selectedTabIndex: 0,
    selectedClaimsAssigned: "0",
    sortConfigReleasedId: "0",
    sortConfigUnreleasedId: "0",
    sortConfigUser: "0",
    selectedSchedule: emptyEvent,
    selectedScheduleChanged: false,
    changed: false,
    email: "",
    immediate: "N",
    maxHomePageFilters: 5,
    userPrefsEmailError: "",
    favFilterErrorMsg: "",
    scheduleErrorMsg: "",
    homePageFilterErrorMsg: "",
    userPrefsPageDirty: false,
    showConfirmRedirectModal: false
};

const USER_PREFS_ERR_MSG_IMMOVABLE: string = 'One or more items could not be moved because they were assigned to you by a Supervisor or Group.';


function onAddFieldsToFavorites(crud: MCARMUserPreferences, deltaCrud: MCARMUserPreferencesDelta, fieldsToAdd: string[], 
                                defaultSavedFilters:  MCUserPrefsFilter[], defaultFavoriteFilters: MCUserPrefsFilter[], defaultHomePageFilters: MCUserPrefsFilter[]) {

    // keep crud updated (this drives the form instead of ui state)..
    let crudFavoriteFilters = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter;
    let newArray = crudFavoriteFilters.slice();
    let deltaArray: MCUserPrefsFavoriteFilterDelta[] = [];
    if(deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter)
       deltaArray = deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter.slice();
   


    if (crudFavoriteFilters) {
        //let newArray = crudFavoriteFilters.slice();
        fieldsToAdd.forEach((fieldElem) => {
            let newFilterField =
                crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter.find(ob => ob["@ID"] === fieldElem);

            if (newFilterField) {
                let newElem: MCUserPrefsFavoriteFilter = {
                    '@FavId': newFilterField["@ID"].substring(1),
                    '@ID': newFilterField['@ID'],
                    '@Name': newFilterField["@Name"],
                    '@MinPriority': 'N'
                };

                let crudDeltaItem = deltaArray.find(ob => ob["@ID"] === fieldElem);
                let inUnusedList = defaultSavedFilters.find(ob => ob["@ID"] === fieldElem);
                let inFavoriteFilters = defaultFavoriteFilters.find(ob => ob["@ID"] === fieldElem);
                let inHomePageFilters =  defaultHomePageFilters.find(ob => ob["@ID"] === fieldElem);

                if (crudDeltaItem && !inUnusedList) {
                    if(inFavoriteFilters || inHomePageFilters)
                        // it was originally here on one of them, delete it
                        deltaArray = deltaArray.filter((el) => { return fieldElem.indexOf(el['@ID']) === -1});
                    
                    if (inHomePageFilters) {
                        //mark as update to OMIT from Home page
                        let fromHomeDeltaItem: MCUserPrefsFavoriteFilterDelta = {
                            '@ID': newFilterField['@ID'],
                            '@Home': '0',
                            '@SeqNo': '0',
                            '@Update': 'true'
                        };
                        deltaArray.push(fromHomeDeltaItem);
                    }
                } else {
                    let newDeltaItem: MCUserPrefsFavoriteFilterDelta = {'@ID': newFilterField['@ID']}
                    deltaArray.push(newDeltaItem);
                }

                newArray.push(newElem);
                // remove it from the saved filter list
                if(crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter)
                    crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter = 
                            crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter.filter((el) => { return fieldElem.indexOf(el['@ID']) === -1});
            }
        });

    }
    crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = JSON.parse(JSON.stringify(newArray));
    deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = JSON.parse(JSON.stringify(deltaArray));
    
    //console.log(deltaCrud);
}

function onAddFieldsToHomePage(crud: MCARMUserPreferences, deltaCrud: MCARMUserPreferencesDelta, 
                                fieldsToAdd: string[], defaultHomeFilters:  MCUserPrefsFavoriteHomeFilter[]) {

    // keep crud updated (this drives the form instead of ui state)..
    let crudFavoriteFilters = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter;
    let crudHomePages = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter;
    let deltaArray: MCUserPrefsFavoriteFilterDelta[] = [];
    if(deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter)
        deltaArray = deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter.slice();
    let newArray = crudHomePages.slice();
    
    if (crudHomePages) {
        var sequenceVal = crudHomePages.length + 1;
        fieldsToAdd.forEach((fieldElem) => {
            let newFilterField =
                crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter.find(ob => ob["@ID"] === fieldElem);
            if (newFilterField) {
                let newElem: MCUserPrefsFavoriteHomeFilter = {
                    '@FavId': newFilterField["@ID"].substring(1),
                    '@ID': newFilterField['@ID'],
                    '@Name': newFilterField["@Name"],
                    '@MinPriority': 'N',
                    '@HomeSequence': sequenceVal.toString()
                };
                let newDeltaItem: MCUserPrefsFavoriteFilterDelta =
                {
                    '@ID': fieldElem,
                    '@Home': '1',
                    '@SeqNo': sequenceVal.toString(),
                    '@Update': 'true'
                };

                sequenceVal = sequenceVal + 1;

                newArray.push(newElem);
                crudFavoriteFilters = crudFavoriteFilters.filter(obj => obj !== newFilterField);
                
                //let crudDeltaItem = deltaArray.find(ob => ob["@ID"] === fieldElem);
                let wasOnHomepageUnMoved = false;
                let homeFilterObj = defaultHomeFilters.find(ob => ob["@ID"] === fieldElem);

                if (homeFilterObj) {
                    if (homeFilterObj["@HomeSequence"] === (sequenceVal-1).toString()) {
                        wasOnHomepageUnMoved = true;
                    }
                }

                //filter out the Home entries unless it was originally on the saved page
                deltaArray = deltaArray.filter((el) => { 
                     return (fieldElem.indexOf(el['@ID']) === -1 || (fieldElem.indexOf(el['@ID']) !== -1 && !el['@Home']))
                });
                
                if(!wasOnHomepageUnMoved)
                   deltaArray.push(newDeltaItem);
            }
          
        });

    }
    crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter = JSON.parse(JSON.stringify(newArray));
    crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = JSON.parse(JSON.stringify(crudFavoriteFilters));
    deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = JSON.parse(JSON.stringify(deltaArray));

    //console.log(deltaCrud);
}

function onRemoveFieldsFromFavorites(crud: MCARMUserPreferences, deltaCrud: MCARMUserPreferencesDelta, 
                                    fieldsToRemove: string[], defaultSavedFilters:  MCUserPrefsFilter[]) {
    let crudFavoriteFilters = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter;
    let deltaArray: MCUserPrefsFavoriteFilterDelta[] = [];
    let newArray = crudFavoriteFilters.slice();
    if(deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter)
        deltaArray = deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter.slice();
    let moveArray: MCUserPrefsFilter[] = [];

    let hasImmovable = false;

    if (crudFavoriteFilters) {
        fieldsToRemove.forEach((fieldElem) => {
            let filterToRemove = newArray.find(obj => obj["@ID"] === fieldElem);
            var name = '';
            if (filterToRemove) {
                if (filterToRemove['@MinPriority'] === 'F' || filterToRemove['@MinPriority'] === 'H')
                    hasImmovable = true;
                name = filterToRemove["@Name"];
            }
            newArray = newArray.filter((el) => { return fieldElem.indexOf(el['@ID']) === -1 });
            
            // delete all entries with that id if it was previously on favorites or home pages
                //if it came from home or favorites originally, delete it
                    deltaArray = deltaArray.filter((el) => { return fieldElem.indexOf(el['@ID']) === -1 });
                    // if it wasnt originally an unused filter, add it back in with delete attribute
                    if (!defaultSavedFilters.find(ob => ob["@ID"] === fieldElem)) {
                        let newDeltaItem: MCUserPrefsFavoriteFilterDelta =
                        {
                            '@ID': fieldElem,
                            '@Delete': 'true'
                        }
                        deltaArray.push(newDeltaItem);
                    }
                    let moveItem: MCUserPrefsFilter =
                    {
                        '@ID': fieldElem,
                        '@Name': name
                    }
                    moveArray.push(moveItem);
        });
    }
    
    if(hasImmovable)
        return USER_PREFS_ERR_MSG_IMMOVABLE;

    if (deltaArray)
        deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = deltaArray;

    if (newArray)
        crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = newArray;

    if (moveArray.length > 0) {
        crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter =
            crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter.concat(moveArray);
    }
    
    //console.log(deltaCrud);
    return '';

}

function onRemoveFieldsFromHomePage(crud: MCARMUserPreferences, deltaCrud: MCARMUserPreferencesDelta, 
                                    fieldsToRemove: string[], defaultSavedFilters:  MCUserPrefsFilter[], 
                                    favoriteFiltersList: MCUserPrefsFilter[], defaultHomeFilters: MCUserPrefsFavoriteHomeFilter[]) {
    let crudFavoriteFilters = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter;
    let homePageFilters = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter;
    let newHomeArray = homePageFilters.slice();
    let newFavoritesArray = crudFavoriteFilters.slice();

    let deltaArray: MCUserPrefsFavoriteFilterDelta[] = [];
    if(deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter)
        deltaArray = deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter.slice();
    

    let hasImmovable = false;
    if (crudFavoriteFilters) {
        fieldsToRemove.forEach((fieldElem) => {

           let filterToRemove = homePageFilters.find(obj => obj["@ID"] === fieldElem);

               if (filterToRemove) {
                       if (filterToRemove['@MinPriority'] === 'F' || filterToRemove['@MinPriority'] === 'H') 
                            hasImmovable = true;
               }

            let favFilter = homePageFilters.find(ob => ob["@ID"] === fieldElem);
            
            if (favFilter) {
                let newElem: MCUserPrefsFavoriteFilter = {
                    '@FavId': favFilter["@ID"].substring(1),
                    '@ID': favFilter['@ID'],
                    '@Name': favFilter["@Name"],
                    '@MinPriority': 'N'
                };
                newFavoritesArray.push(newElem);
                // check for value in deltacrud, move it to favs
                let crudDeltaItem = deltaArray.find(ob => ob["@ID"] === fieldElem);
                if (crudDeltaItem) {
                    deltaArray = deltaArray.filter((el) => { return fieldElem.indexOf(el['@ID']) === -1 });
                }
                let newDeltaItem: MCUserPrefsFavoriteFilterDelta = {
                    '@ID' : fieldElem
                };
                // if it was from the fav filters originally, don't add it back
                if (!favoriteFiltersList.find(ob => ob["@ID"] === fieldElem)){

                    if (!defaultSavedFilters.find(ob => ob["@ID"] === fieldElem)){
                        // means it was on the home page add in the other attributes
                        newDeltaItem["@Home"] = '0';
                        newDeltaItem["@SeqNo"] = '0';
                        newDeltaItem["@Update"] = 'true';
                    }
                    // if it was from the saved filters originally, add it as regular attribute 
                    deltaArray.push(newDeltaItem);
                }
            }
            newHomeArray = newHomeArray.filter((el) => { return fieldElem.indexOf(el['@ID']) === -1 });

        });
    }

    if (hasImmovable) {
        return USER_PREFS_ERR_MSG_IMMOVABLE;
    }

    if (newFavoritesArray)
        crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = newFavoritesArray;
    // resequence home filters if they changed
    if (newHomeArray && newHomeArray.length > 0) {
        let updatedSeqArr: MCUserPrefsFavoriteHomeFilter[] = [];
        let idx = 1;
        newHomeArray.forEach(homeElem => {
           let newElem: MCUserPrefsFavoriteHomeFilter = {
                    '@FavId': homeElem['@FavId'],   
                    '@ID': homeElem["@ID"],
                    '@Name': homeElem["@Name"],
                    '@MinPriority': 'N',
                    '@HomeSequence': idx.toString()
            };
            deltaArray = deltaArray.filter((el) => { return homeElem["@ID"].indexOf(el['@ID']) === -1 });
            if (homeElem["@HomeSequence"] !== idx.toString()) {
                // was the item in the original home list with the same sequence?

                let wasOnHomepageUnMoved = false;
                let homeFilterObj = defaultHomeFilters.find(ob => ob["@ID"] === homeElem['@ID']);

                if (homeFilterObj) {
                    if (homeFilterObj["@HomeSequence"] === (idx).toString()) {
                        wasOnHomepageUnMoved = true;
                    }
                }
                if (!wasOnHomepageUnMoved) {
                    let newDeltaItem: MCUserPrefsFavoriteFilterDelta =
                    {
                        '@ID': homeElem["@ID"],
                        '@Home': '1',
                        '@SeqNo': idx.toString(),
                        '@Update': 'true'
                    };
                    deltaArray.push(newDeltaItem);
                }
            }
            idx = idx + 1;
           updatedSeqArr.push(newElem);
           
            
        });
        newHomeArray = updatedSeqArr;
    }
        
    crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter = newHomeArray;
    deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = JSON.parse(JSON.stringify(deltaArray));
    //console.log(deltaCrud);

    return '';
}

function moveHomePages(up: boolean, crud: MCARMUserPreferences, deltaCrud: MCARMUserPreferencesDelta, selections: string[], defaultHomeFilters: MCUserPrefsFavoriteHomeFilter[] ) {
    let sortedArray: MCUserPrefsFavoriteHomeFilter[] = [];
    let tempArray = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter;
    let hasImmovable = false;
    
    let deltaArray: MCUserPrefsFavoriteFilterDelta[] = [];
    if(deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter)
       deltaArray = deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter.slice();


    if (tempArray) {
        if (!selections ||tempArray.length <= 1 || (selections && selections.length === 0 || selections.length === tempArray.length))
            return ''; // nothing selected or everything selected

        selections.forEach((fieldElem) => {
            let filterToRemove = tempArray.find(obj => obj["@ID"] === fieldElem);

            if (filterToRemove) {
                if (filterToRemove['@MinPriority'] === 'F' || filterToRemove['@MinPriority'] === 'H')
                    hasImmovable = true;
            }
        });


    }
    
    if(hasImmovable)
        return USER_PREFS_ERR_MSG_IMMOVABLE;
    
    // since my selections don't know the sequence, and they may not be in order, build a sorted list of ids
    let sequenceHolder: string[] = [...Array(tempArray.length)].map(x => '');

    tempArray.forEach((taObj) => {
        let taObjPos = Number(taObj['@HomeSequence']);
        sequenceHolder[taObjPos - 1] = taObj['@ID'];
    });
    // flip the selected items if moving down for sort multiple
    let sortSelections = selections.slice();
    if (!up && sortSelections.length > 1) {
        sortSelections = sortSelections.reverse();
    }
    let fails: boolean = false;
    sortSelections.forEach(id => {
        let crudOb = tempArray.find((x) => x['@ID'] === id);
        if (crudOb && !fails) {
            let orderOb = Number(crudOb['@HomeSequence']);

            if ((up && orderOb === 1) ||
                (!up && orderOb === tempArray.length))
                fails = true;
            else {
                let toFind = orderOb + (up ? -1 : 1);

                // swap them in the array
                let myTempOrderHolder = sequenceHolder[toFind - 1];
                sequenceHolder[toFind - 1] = sequenceHolder[orderOb - 1];
                sequenceHolder[orderOb - 1] = myTempOrderHolder;
            }
        }
    });

    if (fails)
        return ''; // can't move up or down so bail on everything, and leave it alone
    
    // if we made it here, then orderHolder now has the new order, build it and store it
    for (let idx = 0; idx < sequenceHolder.length; idx++) {
        if (sequenceHolder.length > 0) {
            var tempHomeFilter = tempArray.find(ob => ob["@ID"] === sequenceHolder[idx]);
            if (tempHomeFilter) {
                let newElem: MCUserPrefsFavoriteHomeFilter = {
                    '@FavId': tempHomeFilter['@FavId'],
                    '@ID': tempHomeFilter["@ID"],
                    '@Name': tempHomeFilter["@Name"],
                    '@MinPriority': 'N',
                    '@HomeSequence': (idx + 1).toString()


                };
                sortedArray.push(newElem);

                // check to see if the sequences changed and update the entries in deltaCrud
                if (tempHomeFilter['@HomeSequence'] !== (idx + 1).toString()) {
                    
                    deltaArray = deltaArray.filter((el) => { 
                        return (tempHomeFilter!["@ID"].indexOf(el['@ID']) === -1 || 
                               (tempHomeFilter!["@ID"].indexOf(el['@ID']) !== -1 && (!el['@Home'] || el['@Home'] === '0')))
                    });


                    let wasOnHomepageUnMoved = false;
                    let homeFilterObj = defaultHomeFilters.find(ob => ob["@ID"] === tempHomeFilter!['@ID']);

                    if (homeFilterObj) {
                        if (homeFilterObj["@HomeSequence"] === (idx + 1).toString()) {
                            wasOnHomepageUnMoved = true;
                        }
                    }

                    if (!wasOnHomepageUnMoved) {
                        let newDeltaObj: MCUserPrefsFavoriteFilterDelta = {
                            '@ID': tempHomeFilter['@ID'],
                            '@Home': '1',
                            '@SeqNo': (idx + 1).toString(),
                            '@Update': 'true'
                        };

                        deltaArray.push(newDeltaObj);
                    }
                }

            }

        }
    }

    crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter = sortedArray;
    deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = JSON.parse(JSON.stringify(deltaArray));

    //console.log(deltaCrud);
    return '';
}

function updateSelectList(crud: MCARMUserPreferences, deltaCrud: MCARMUserPreferencesDelta, selection: SortConfigurationTypes, value: string) {
    if (crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo) {
        var deltaObj: MCUserPrefsClaimSortTypeDelta = {
            '@ID': '#',
            '@ClaimSortTypeID': value
        };
        let newObj: MCUserPrefsClaimSortType = {
            '@ID': '#',
            '@ClaimSortTypeID': value
        };
        switch (selection) {
        case SortConfigurationTypes.Released:
            if (crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationReleased) {
                crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationReleased["@ClaimSortTypeID"] = value;
                // delta object needs the same id
                deltaObj["@ID"] = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationReleased["@ID"];
            }
            else {
                //otherwise it was 0 (and not in the original crud)  
                if (!crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations)
                    crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations = {};

                crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationReleased = newObj;
            }
            // update deltaCrud
            deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationReleased = deltaObj;
            break;
        case SortConfigurationTypes.Unreleased:
           if (crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased) {
                   crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased["@ClaimSortTypeID"] = value;
                    // delta object needs the same id
                    deltaObj["@ID"] = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased["@ID"];
                }
            else {
                //otherwise it was 0 (and not in the original crud)
               if (!crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations)
                   crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations = {};
               
               crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased = newObj;
            }
            // update deltaCrud    
            deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUnreleased = deltaObj;
            break;
        case SortConfigurationTypes.User:
            if (crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUser) {
                crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUser["@ClaimSortTypeID"] = value;
                // delta object needs the same id
                deltaObj["@ID"] = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUser["@ID"];
            }
            else {
                //otherwise it was 0 (and not in the original crud)
                if (!crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations)
                    crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations = {};

                crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUser = newObj;
            }
            // update deltaCrud
            deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations.SortConfigurationUser = deltaObj;
            break;
        default:
        }
    }
    //console.log(crud);
}
/* SCHEDULES */
function addSchedule(toAdd: MCUserPrefsEvent, crud: MCARMUserPreferences, deltaCrud: MCARMUserPreferencesDelta) {
    let newArray = crud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event.slice();
    let newDeltaArray: MCUserPrefsEventDelta[] = [];

    if(deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events &&
        deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event )
        newDeltaArray = deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event.slice();

    toAdd['@Mon'] = toAdd['@Mon'] === "" ? "N" :  toAdd['@Mon'];
    toAdd['@Tue'] = toAdd['@Tue'] === "" ? "N" :  toAdd['@Tue'];
    toAdd['@Wed'] = toAdd['@Wed'] === "" ? "N" :  toAdd['@Wed'];
    toAdd['@Thu'] = toAdd['@Thu'] === "" ? "N" :  toAdd['@Thu'];
    toAdd['@Fri'] = toAdd['@Fri'] === "" ? "N" :  toAdd['@Fri'];
    toAdd['@Sat'] = toAdd['@Sat'] === "" ? "N" :  toAdd['@Sat'];
    toAdd['@Sun'] = toAdd['@Sun'] === "" ? "N" :  toAdd['@Sun'];

    let olditem = newArray.find(obj => obj['@ID'] === toAdd['@ID']);
    if (olditem) {
        olditem['@Mon'] = toAdd['@Mon'];
        olditem['@Tue'] = toAdd['@Tue'];
        olditem['@Wed'] = toAdd['@Wed'];
        olditem['@Thu'] = toAdd['@Thu'];
        olditem['@Fri'] = toAdd['@Fri'];
        olditem['@Sat'] = toAdd['@Sat'];
        olditem['@Sun'] = toAdd['@Sun'];
        olditem['@Time'] = toAdd['@Time'];
    } else {
        newArray.push(toAdd);
    }

    // for deltas
    let oldDeltaitem = newDeltaArray.find(obj => obj['@ID'] === toAdd['@ID']);
    if (oldDeltaitem) {
        oldDeltaitem['@Mon'] = toAdd['@Mon'];
        oldDeltaitem['@Tue'] = toAdd['@Tue'];
        oldDeltaitem['@Wed'] = toAdd['@Wed'];
        oldDeltaitem['@Thu'] = toAdd['@Thu'];
        oldDeltaitem['@Fri'] = toAdd['@Fri'];
        oldDeltaitem['@Sat'] = toAdd['@Sat'];
        oldDeltaitem['@Sun'] = toAdd['@Sun'];
        oldDeltaitem['@Time'] = toAdd['@Time'];
    } else {
        newDeltaArray.push(toAdd);
    }

    crud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event = JSON.parse(JSON.stringify(newArray));
    if (deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events &&
        deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event)
        deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event = JSON.parse(JSON.stringify(newDeltaArray));

    else {
        let arr: MCUserPrefsEventDelta[] = [];
        arr.push(toAdd);

        deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event = arr;       
    }

    return newArray;
}

function defaultCrudDelta(crud: MCARMUserPreferences, deltaCrud: MCARMUserPreferencesDelta) {
  
    let filters: MCUserPrefsFilter[] = [];
    var favorites: MCUserPrefsFavoriteFilter[] = [];
    var homePages: MCUserPrefsFavoriteHomeFilter[] = [];

    if (crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList)
        filters = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter;

    if (crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters)
        favorites = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter;

    if (crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters)
        homePages = crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter;

    if (favorites && favorites.length > 0) {
        favorites.forEach(fObj => {
            filters = filters.filter(sfObj => sfObj["@ID"] !== fObj["@ID"]);
        });
    }
    if (homePages && homePages.length > 0) {
        homePages.forEach(hObj => {
            filters = filters.filter(sfObj => sfObj["@ID"] !== hObj["@ID"]);
        });
    }

    // copy over configuration, it's always sent
    let configArr = crud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration;

    // default deltacrud back if we've changed state (navigated to a different page, etc)
    deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration = JSON.parse(JSON.stringify(configArr));
    deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = []; 
    deltaCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.ClaimSortInfo.SortConfigurations = {};

    // these are in the other lists now and we need to modify this one.
    crud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter = filters;
    
    return filters;
}


function removeSchedule(toRemove: string, crud: MCARMUserPreferences, deltaCrud: MCARMUserPreferencesDelta) {
    
    let newArray = crud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event.slice();
    let newDeltaArray: MCUserPrefsEventDelta[] = [];

    if(deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events &&
        deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event )
        newDeltaArray = deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event.slice();

    let deltaItemtoDelete = newDeltaArray.find((el) => { return toRemove.indexOf(el['@ID']) !== -1 });

    newArray = newArray.filter((el) => { return toRemove.indexOf(el['@ID']) === -1 });
    var deltaObj: MCUserPrefsEventDelta = {'@ID': ''};

    // if this delta item was part of initial load, remove the old one and add one with delete attrib
    if (deltaItemtoDelete) {
        newDeltaArray = newDeltaArray.filter((el) => { return toRemove.indexOf(el['@ID']) === -1 });
        if (!deltaItemtoDelete["@ID"].includes('New')) {
            deltaObj = {
                '@ID': deltaItemtoDelete['@ID'],
                '@Delete': 'true'
            };
            newDeltaArray.push(deltaObj);
        }
    }

    crud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event = newArray;
    deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event = JSON.parse(JSON.stringify(newDeltaArray));

    // select last schedule in the list
    let len = 0;
    if (crud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event.length > 0) {
        len = crud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event.length;
        return crud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event[len-1];
    }
    return emptyEvent;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IUserPreferencesUIState, KnownActions> = (state: IUserPreferencesUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'ADD_USERPREF_FAV_FILTER_FIELDS':
                if (action.data.masterCrud &&
                    state.selectedAllSavedFilters.length > 0) {

                    let allFields = state.selectedAllSavedFilters.slice();
                    
                    onAddFieldsToFavorites(action.data.masterCrud, state.deltaCrud ,allFields, state.defaultSavedFilters,
                        state.defaultFavoriteFilters, state.defaultHomePageFilters);
                    return {
                        ...state,
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'REMOVE_USERPREF_FAV_FILTER_FIELDS':
                if (action.data.masterCrud &&
                    state.selectedAllFavoriteFilters.length > 0) {

                    let allFields = state.selectedAllFavoriteFilters.slice();
                    let filtersError = onRemoveFieldsFromFavorites(action.data.masterCrud, state.deltaCrud, allFields, state.defaultSavedFilters);
                    return {
                        ...state,
                        favFilterErrorMsg: filtersError,
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'ADD_USERPREF_HOMEPAGE_FILTER_FIELDS':
                if (action.data.masterCrud &&
                    state.selectedAllFavoriteFilters.length > 0) {

                    let allFields = state.selectedAllFavoriteFilters.slice();
                    onAddFieldsToHomePage(action.data.masterCrud, state.deltaCrud, allFields, state.defaultHomePageFilters);
                    return {
                        ...state,
                        favFilterErrorMsg: '',
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'REMOVE_USERPREF_HOMEPAGE_FILTER_FIELDS':
                if (action.data.masterCrud &&
                    state.selectedAllHomePageFilters.length > 0) {

                    let allFields = state.selectedAllHomePageFilters.slice();
                    let filtersError = onRemoveFieldsFromHomePage(action.data.masterCrud, state.deltaCrud, allFields, 
                                                                 state.defaultSavedFilters, state.defaultFavoriteFilters, state.defaultHomePageFilters);
                    return {
                        ...state,
                        favFilterErrorMsg: filtersError,
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'SELECT_USERPREF_ALLSAVEDFILTERS':
                if (action.data.masterCrud &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FilterList.Filter) {

                    return {
                        ...state,
                        selectedAllSavedFilters: action.data.uiData.selected,
                    }
                }
                break;
            case 'SELECT_USERPREF_ALLFAVORITEFILTERS':
                if (action.data.masterCrud &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter) {
                    
                    return {
                        ...state,
                        selectedAllFavoriteFilters: action.data.uiData.selected,
                    }
                }
                break;
            case 'SELECT_USERPREF_ALLHOMEPAGEFILTERS':
                if (action.data.masterCrud &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters &&
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter) {
                    
                    return {
                        ...state,
                        selectedAllHomePageFilters: action.data.uiData.selected,
                    }
                }
                break;
            case 'MOVE_USERPREF_HOMEPAGES_DOWN':
            case 'MOVE_USERPREF_HOMEPAGES_UP':
                if (action.data.masterCrud && state.selectedAllHomePageFilters.length > 0) {
                    let filtersError = moveHomePages( (action.type === 'MOVE_USERPREF_HOMEPAGES_UP'), action.data.masterCrud, state.deltaCrud, state.selectedAllHomePageFilters, state.defaultHomePageFilters)
                    return {
                        ...state,
                        homePageFilterErrorMsg: filtersError,
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'UPDATE_USERPREF_CLAIM_OPTION':
                if (action.data.masterCrud) {
                    updateSelectList(action.data.masterCrud, state.deltaCrud, action.data.uiData.selection, action.data.uiData.value);
                    return {
                        ...state,
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'UPDATE_USERPREF_NOTIFY_OPTION':
                if (action.data.masterCrud) {
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration["@NotifyTypeId"] = action.data.uiData.value;
                    state.deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration["@NotifyTypeId"] = action.data.uiData.value;
                    return {
                        ...state,
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'UPDATE_USERPREF_EMAIL':
                if (action.data.masterCrud) {
                   action.data.masterCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration["@EmailAddress"] = action.data.uiData.value;
                   state.deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration["@EmailAddress"] = action.data.uiData.value;
                    return {
                        ...state,
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'UPDATE_USERPREF_IMMEDIATE':
                if (action.data.masterCrud) {
                    action.data.masterCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration["@Immediate"] = action.data.uiData.value;
                    state.deltaCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration["@Immediate"] = action.data.uiData.value;
                    return {
                        ...state,
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'SELECT_USERPREF_SCHEDULE':
                if (action.data.masterCrud && state.selectedSchedule) {
                    let schData =
                        action.data.masterCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    return {
                        ...state,
                        selectedScheduleChanged: false,
                        selectedSchedule: schData !== undefined ? schData : emptyEvent,
                        userPrefsPageDirty: true
                    }    
                }
                break;
            case 'UPDATE_USERPREF_DAYOFWEEK':
                if (state.selectedSchedule) {
                    return {
                        ...state,
                        selectedScheduleChanged: true,
                        selectedSchedule: {
                            ...state.selectedSchedule,
                            [action.data.uiData.index] : (action.data.uiData.value ? "Y" : "N"),
                            userPrefsPageDirty: true
                        }
                    }
                }
                break;
            case 'UPDATE_USERPREF_SCHEDULE_HR':
                if (action.data.uiData.value &&
                    action.data.uiData.value.length === 2 &&
                    state.selectedSchedule &&
                    state.selectedSchedule['@Time'] &&
                    state.selectedSchedule['@Time'].length > 6)
                {
                    let valHr = action.data.uiData.value + state.selectedSchedule['@Time'].substring(2);
                    return {
                        ...state,
                        selectedScheduleChanged: true,
                        selectedSchedule: {
                            ...state.selectedSchedule,
                            '@Time': valHr
                        },
                        userPrefsPageDirty: true
                    }
                }
                break;

            case 'UPDATE_USERPREF_SCHEDULE_MIN':
                if (action.data.uiData.value &&
                    action.data.uiData.value.length === 2 &&
                    state.selectedSchedule &&
                    state.selectedSchedule['@Time'] &&
                    state.selectedSchedule['@Time'].length > 6)
                {
                    let tempvalMin = state.selectedSchedule['@Time'];
                    let valMin = tempvalMin.substring(0, 3) + action.data.uiData.value + tempvalMin.substring(5);
                    return {
                        ...state,
                        selectedScheduleChanged: true,
                        selectedSchedule: {
                            ...state.selectedSchedule,
                            '@Time': valMin
                        },
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'ADD_USERPREFS_SCHEDULE':
            case 'UPDATE_USERPREFS_SCHEDULE':
                if (action.data.masterCrud) { 
                    addSchedule(action.data.uiData, action.data.masterCrud, state.deltaCrud);
                    return {
                        ...state,
                        selectedSchedule: JSON.parse(JSON.stringify(action.data.uiData)),
                        changed: true,
                        scheduleErrorMsg: '',
                        userPrefsPageDirty: true
                    }
                    
                }
                break;
            case 'REMOVE_USERPREFS_SCHEDULE':
                if (action.data.masterCrud && state.selectedSchedule && action.data.uiData.value && action.data.uiData.value.length > 0) {
                    let newSelectedEvent =   removeSchedule(action.data.uiData.value, action.data.masterCrud, state.deltaCrud);
                    return {
                        ...state,
                        selectedSchedule: newSelectedEvent,
                        selectedScheduleChanged: false,
                        userPrefsPageDirty: true
                    }
                }
                break;
            case 'USERPREFS_SETUP':
                if (action.data.masterCrud) {
                    let arr = defaultCrudDelta(action.data.masterCrud, state.deltaCrud);
                    let favFilters = action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter.slice();
                    let homeFilters = action.data.masterCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter.slice();
                    return {
                        ...state,
                        deltaCrudInitialized: true,
                        defaultSavedFilters: arr,
                        defaultFavoriteFilters: favFilters,
                        defaultHomePageFilters: homeFilters,
                        userPrefsPageDirty: false
                    }
                }
                break;
            case 'USERPREFS_RESET':
                if (action.data.masterCrud) {
                    return { ...defaultState, userPrefsPageDirty: false }
                }
                break;
            case 'ERROR_USERPREFS_REMOVE_SCHEDULE_CONFIRM':
                return {
                    ...state,
                    removeScheduleConfirm: action.data.uiData.value,
                }
                break;
            case 'USERPREFS_FAVFILTER_ERROR':
                return {
                    ...state,
                    favFilterErrorMsg: action.data.uiData.value,
                }
                break;
            case 'USERPREFS_SCHEDULE_ERROR':
                console.log(action.data.uiData.value);
                return {
                    ...state,
                    scheduleErrorMsg: action.data.uiData.value,
                }
                break;
            case 'USERPREFS_ALERT_CLOSE':
                return {
                    ...state,
                    favFilterErrorMsg: action.data.uiData.value,
                }
                break;
            case 'ERROR_USERPREFS_EMAIL':
                return {
                    ...state,
                    userPrefsEmailError: action.data.uiData.value,
                }
            case 'TOGGLE_USERPREFS_CONFIRM_REDIRECT':
                console.log('action.data.uiData');
                return {
                    ...state,
                    showConfirmRedirectModal: action.data
                }
                break;
            case 'SELECT_USERPREF_TAB_INDEX':
                return {
                    ...state,
                    selectedTabIndex: action.data,
                }
                break;
        }
    }

    return state || defaultState;
}
