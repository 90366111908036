import * as React from 'react';
import { RouteComponentProps, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll, isEqual } from 'lodash';
import { Location } from 'history';
import { OKCancelButtons, DialogWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { TabContainer } from 'ui-core';
import styled from 'styled-components';
import { ModalConfirmation, leaveMessage } from '@common/ModalConfirmation';
import CrudTypes from '../../../commonResources/CrudTypes';
import { ICookies_Config, ICookies_Container } from '@store/ConfigData';
import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';
import { IFormModalState } from '../../../store/ui/BaseCrudUI';
import { ApplicationState } from '@store/index';
import { IClaimOptionsUIState, IClaimOptionsActionProps, actionCreators, validationCallback } from
    '@store/ClaimOptions';
import { handleChange, pageLeave } from '@commonResources/userModified';
import {
    IModifyClaimOptions, IRelatedClaimsData, IRelatedClaimsUpdateRequest,
    IDuplicateClaimsData, IDuplicateClaimsUpdateRequest, IPayerSelectionUpdateRequest,
    IMainClaimOptionsUpdateRequest, IMNPolicySelectionUpdateRequest,
    IGeneralUpdateRequest, IMNGeneralOptionsLocalState,
    ICCIGeneralOptionsLocalState, ICCIPayerSelectionLocalState,
    IMNPayerSelectionLocalState,
    IMNPolicySelectionLocalState,enforceArray
} from '../../../store/ui/ClaimOptionsUI';
import { RelatedClaimsOptions } from './RelatedClaimsOptions';
import { DuplicateClaimsOptions } from './DuplicateClaimsOptions';
import { MainClaimsOptions } from './MainClaimsOptions';
import { MedicalNecessityOptions } from './MedicalNecessityOptions';
import { CCIOptions } from './CCIOptions';


interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    canDelete:boolean,
    canSecCheck1: boolean,  //canViewRelatedClaims & canViewDeletedClaims 
    canSecCheck2: boolean // canEditRelatedClaims & canEditDeletedClaims
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IClaimOptionsProps = IMergeCrudComponentProps<IClaimOptionsUIState, IClaimOptionsActionProps, IOwnProps>;

export const ContentContainer = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    font-size: 11px;
    height: 750px;
    width: 1049px;`

interface ITabItem {
    label: string;
    domID: string;
}

interface IComponentState extends IFormModalState {
    tabIndex: number,
    cciRadioIndex: number,
    mnRadioIndex: number,
    showCancel: boolean, 
    cciGeneralOptionsLocalState?: ICCIGeneralOptionsLocalState,
    cciPayerSelectionLocalState?: ICCIPayerSelectionLocalState,
    mnGeneralOptionsLocalState?: IMNGeneralOptionsLocalState,
    mnPayerSelectionLocalState?: IMNPayerSelectionLocalState,
}

export class ClaimOptions extends React.Component<IClaimOptionsProps, IComponentState> {
    private instructions = <React.Fragment>Use this page to customize the way your claims are affected by product processing. The options are organized in tabs that let you set general items, Medicare compliance, duplicate claim checking, and state data reporting.<i>(See help for more information.)</i></React.Fragment>;

    public componentDidMount() {

        this.props.action.crud.get({ crudId: CrudTypes.mctiClaimOptions },
            (result: MCClaimOptions) => {
                if (validationCallback(result)) {
                    this.props.action.configData.getConfigData({ cookies: [{ name: "PropensityToDenyEnabled" }, { name: "EnhancedClaimStatusEnabled" }], "config":[] },
                        (configResult: ICookies_Container) => {
                            this.props.action.ui.initializeOptions({
                                masterCrud: this.props.dataStore.crud.data,
                                uiData: {
                                    ...result,
                                    PropensityToDenyEnabled: this.getConfigValue(configResult, "PropensityToDenyEnabled"),
                                    ECSEnabledValue: this.getConfigValue(configResult, "EnhancedClaimStatusEnabled"),
                                }
                            });
                        }
                    );
                    return true;
                }
            });
        if (sessionStorage.getItem('userModified') === 'true') {
            sessionStorage.setItem('userModified', 'false')
        }
    }

    private getConfigValue(configResult: ICookies_Container, config: string): string {
        let configObject = configResult?.cookies?.find((o: ICookies_Config) => o.name === config);
        if (configObject?.value) {
            return configObject.value;
        }
        return "";
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onDialogOk = () => {
        if (!(this.validateForm())) {
            return;
        }
        this.setState({ navigationConfirmed: true, saveOnNavigate: true },
            () => {
                // theres an issue with xmlDeltaCrud with a missing original item property. So if the new data has something in it, let's make sure the original does too.
                if (this.props.dataStore.crud.data?.ClaimOptionsMaintenanceInfo?.CustomEdits?.RelatedClaimSearchConfig &&
                    this.props.dataStore.crud.data?.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig['@PatientControlMaskDelimiterUB'] &&
                    this.props.dataStore.crud.data?.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig['@PatientControlMaskDelimiterUB'].length > 0) {
                    if (this.props.dataStore.crud.originalData?.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig) {
                        if (!this.props.dataStore.crud.originalData.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig['@PatientControlMaskDelimiterUB'])
                            this.props.dataStore.crud.originalData.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig['@PatientControlMaskDelimiterUB'] = "";
                    }
                }
                if (this.props.dataStore.crud.data?.ClaimOptionsMaintenanceInfo?.CustomEdits?.RelatedClaimSearchConfig &&
                    this.props.dataStore.crud.data?.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig['@PatientControlMaskDelimiter1500'] &&
                    this.props.dataStore.crud.data?.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig['@PatientControlMaskDelimiter1500'].length > 0) {
                    if (this.props.dataStore.crud.originalData?.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig) {
                        if (!this.props.dataStore.crud.originalData.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig['@PatientControlMaskDelimiter1500'])
                            this.props.dataStore.crud.originalData.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig['@PatientControlMaskDelimiter1500'] = "";
                    }
                }

                // We need to add the MN policy changes to the crud. We could not do that earlier because the changes could be discarded when user changes policy page using page controls or search button
                if (this.props.dataStore.ui.MNPolicySelectionOptions?.policyPageChanges)
                    this.mapMNPolicySelectionUiStateToCrud(this.props.dataStore.crud.data?.ClaimOptionsMaintenanceInfo, this.props.dataStore.ui.MNPolicySelectionOptions?.policyPageChanges);


                //Remove nodes that dont need updating
                if (this.props.dataStore.crud.originalData?.ClaimOptionsMaintenanceInfo) {
                    this.props.dataStore.crud.originalData.ClaimOptionsMaintenanceInfo.LOBList = { LOB: [] };
                    this.props.dataStore.crud.originalData.ClaimOptionsMaintenanceInfo.PayerList = { Payer: [] };
                    this.props.dataStore.crud.originalData.ClaimOptionsMaintenanceInfo.AliasList = { Alias: [] };
                    this.props.dataStore.crud.originalData.ClaimOptionsMaintenanceInfo.FIList = { FI: [] };
                    this.props.dataStore.crud.originalData.ClaimOptionsMaintenanceInfo.CPTGroupList = { CPTGroup: [] };                    
                }
                if (this.props.dataStore.crud.data?.ClaimOptionsMaintenanceInfo) {
                    this.props.dataStore.crud.data.ClaimOptionsMaintenanceInfo.LOBList = { LOB: [] };
                    this.props.dataStore.crud.data.ClaimOptionsMaintenanceInfo.PayerList = { Payer: [] };
                    this.props.dataStore.crud.data.ClaimOptionsMaintenanceInfo.AliasList = { Alias: [] };
                    this.props.dataStore.crud.data.ClaimOptionsMaintenanceInfo.FIList = { FI: [] };
                    this.props.dataStore.crud.data.ClaimOptionsMaintenanceInfo.CPTGroupList = { CPTGroup: [] };
                }

                this.props.action.crud.updateWithDelta(this.props.dataStore.crud);

                this.props.history.push("/LandingPage");
            });
    }

    private mapMNPolicySelectionUiStateToCrud(crudData: MCClaimOptionsInfo | undefined, policyPageChanges: MCClaimOptionsMNClientPolicySeverity[]) {
        if (!crudData || policyPageChanges.length == 0)
            return;

        if (!crudData.MNClientPolicySeverities)
            crudData.MNClientPolicySeverities = { MNClientPolicySeverity: [] };
        else
            crudData.MNClientPolicySeverities.MNClientPolicySeverity = enforceArray<MCClaimOptionsMNClientPolicySeverity>(crudData.MNClientPolicySeverities.MNClientPolicySeverity);

        let updatedCrudData = [...crudData.MNClientPolicySeverities.MNClientPolicySeverity];
        let originalCrudData = this.props.dataStore.crud.originalData?.ClaimOptionsMaintenanceInfo?.MNClientPolicySeverities?.MNClientPolicySeverity;

        policyPageChanges.forEach((changedPolicy: MCClaimOptionsMNClientPolicySeverity) => {
            const updatedSeverity = updatedCrudData.find((item) => { return item['@ID'] === changedPolicy['@PolicyID'] });
            if (updatedSeverity) {
                updatedSeverity['@Severity'] = changedPolicy['@Severity'];
                updatedSeverity['@PolicyID'] = changedPolicy['@PolicyID'];

                // Because PolicyID is not on the original crud, let's add it (but in crudDelta in RH.API)
                if (originalCrudData) {
                    const originalSeverity = originalCrudData.find((item) => { return item['@ID'] === changedPolicy['@PolicyID'] });
                    if (originalSeverity)
                        originalSeverity['@PolicyID'] = ""; // again, I have to put a different value else crudDelta in RH.API will discard that attribute if it has not changed....
                }
            } else {
                updatedCrudData.push({ '@ID': changedPolicy['@PolicyID'], '@Severity': changedPolicy['@Severity'], "@PolicyID": changedPolicy['@PolicyID'], });
            }
        });
        crudData.MNClientPolicySeverities.MNClientPolicySeverity = JSON.parse(JSON.stringify(updatedCrudData));
    }

    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        const hasChanges = sessionStorage.getItem('userModified');
        if (hasChanges === 'true') {
            this.setState({ showCancel: true })
        }
        else {
            pageLeave();
            this.props.history.push("/LandingPage");
        }
    }

    public onModalOk = () => {
        this.setState({ navigationConfirmed: true, saveOnNavigate: false },
            () => {
                this.props.history.push(this.state.navDestination?.pathname || '/LandingPage');
            });
    }

    public onModalClose = () => {
        this.setState({ navigationConfirmed: false, saveOnNavigate: false },
            () => {
                this.props.action.confirm.closeConfirm();
            });
    }

    isBusy = () => {
        return (this.props.dataStore.crud.dataStatus === 'REQUEST');
    }

    private okCancelButtons = <OKCancelButtons onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel} disableOK={!this.props.canEdit} />;


    // https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
    showRouteChangeConfirm(nextLocation: Location) {
        this.setState({ saveOnNavigate: false, navDestination: nextLocation },
            () => {
                this.props.action.confirm.openConfirm({ message: '' });
            }
        );
        //suppresses native prompt modal
        return false;
    }

    public onAlert(msg: string) {
        this.props.action.ui.sendAlertMessage(msg);
        //this.setState({ disableBtn: false });
    }

    public onClearAlert(e: any) {
        this.onAlert("");
    }

    public sendError(id: string, msg: string, type: string) {
        if (!id || (id && id.length === 0) || !msg || (msg && msg.length === 0))
            return;

        this.props.action.ui.sendErrorField({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { id: id, value: msg, type: type }
        });
    }

    public validateForm(): boolean {
        if (!this.validateRelatedClaimsOptions())
            return false;

        if (!this.validateDuplicateClaimsOptions())
            return false;

        return true;
    }

    private validateDuplicateClaimsOptions(): boolean {
        if (!this.props.canSecCheck2)
            return true;

        if (!this.props.dataStore.ui.DuplicateClaimsOptionsUB.isEnabled && !this.props.dataStore.ui.DuplicateClaimsOptions1500.isEnabled)
            return true;

        let bReturn = true;
        if (!this.validateDuplicateClaimsOptionsFor("UB"))
            bReturn = false;

        if (!this.validateDuplicateClaimsOptionsFor("1500"))
            bReturn = false;

        return bReturn;
    }

    private validateRelatedClaimsOptions(): boolean {
        if (!this.props.canSecCheck2)
            return true;

        if (!this.props.dataStore.ui.RelatedClaimsOptionsUB.isEnabled && !this.props.dataStore.ui.RelatedClaimsOptions1500.isEnabled)
            return true;

        let bReturn = true;
        if (!this.validateRelatedClaimsOptionsFor("UB"))
            bReturn = false;

        if (!this.validateRelatedClaimsOptionsFor("1500"))
            bReturn = false;

        return bReturn;
    }

    private validateDuplicateClaimsOptionsFor(formType: "UB" | "1500"): boolean {
        let bReturn = true;
        let dupClaimOptions: IDuplicateClaimsData = (formType == 'UB' ? this.props.dataStore.ui.DuplicateClaimsOptionsUB : this.props.dataStore.ui.DuplicateClaimsOptions1500);

        if (dupClaimOptions.isEnabled) {
            if (!dupClaimOptions.isMedRecNbEnabled && !dupClaimOptions.isPCNEnabled) {
                this.sendError('DuplicateClaimsMedRecNorPCNSelected', 'Medical Record Number or Patient Control Number must be checked.', formType);
                bReturn = false;
            }
        }
        return bReturn;
    }

    private validateRelatedClaimsOptionsFor(formType: "UB" | "1500"): boolean {
        let bReturn = true;
        let relClaimOptions: IRelatedClaimsData = (formType == 'UB' ? this.props.dataStore.ui.RelatedClaimsOptionsUB : this.props.dataStore.ui.RelatedClaimsOptions1500);

        if (relClaimOptions.isEnabled) {
            if (!relClaimOptions.isMedRecNbEnabled && !relClaimOptions.isPCNEnabled &&
                !relClaimOptions.isServiceDatesEnabled && !relClaimOptions.isDateOfBirthEnabled) {
                this.sendError('RelatedClaimsNoOptionsSelected', 'No ' + formType + ' Fields Selected', formType)
                bReturn = false;
            }
            if (relClaimOptions.isPCNEnabled) {
                if (relClaimOptions.PCNMatchType == "1" && relClaimOptions.PCNMaskFixedLength != "2" && relClaimOptions.PCNMaskFixedLength != "3" && relClaimOptions.PCNMaskFixedLength != "4") {
                    this.sendError('RelatedClaimsSequenceMatchNotSelected', 'Must Select ' + formType + ' Patient Control Number Sequence Match', formType);
                    bReturn = false;
                }
                if (relClaimOptions.PCNMatchType == "2" && relClaimOptions.PCNDelim == "") {
                    this.sendError('RelatedClaimsDelimEmpty', 'Must Select ' + formType + ' Patient Control Number Match Delimiter', formType);
                    bReturn = false;
                }
                var reInvalidDelimiters = /[<>%)(_\]\['"]/;
                if (relClaimOptions.PCNMatchType == "2" && reInvalidDelimiters.test(relClaimOptions.PCNDelim)) {
                    this.sendError('RelatedClaimsDelimEmpty', 'Must Specify a Valid ' + formType + ' Patient Control Number Match Delimiter', formType);
                    bReturn = false;
                }
            }

            if (!bReturn)
                return bReturn;

            if (!relClaimOptions.isMedRecNbEnabled && !relClaimOptions.isPCNEnabled) {
                this.sendError('RelatedClaimsMedRecNorPCNSelected', 'For the ' + formType + ' Form, either the Medical Record Number or Patient Control Number must be checked.', formType);
                bReturn = false;
            }
        }
        return bReturn;
    }

    constructor(props: IClaimOptionsProps) {
        super(props);
        this.state = {
            tabIndex: 0,
            cciRadioIndex: 0,
            mnRadioIndex: 0,
            showCancel: false, 
            cciPayerSelectionLocalState: undefined,
            cciGeneralOptionsLocalState: undefined,
            mnGeneralOptionsLocalState: undefined,
            mnPayerSelectionLocalState: undefined,
        }
        bindAll(this, [/*methods here*/]);
    }

    public onSelectTab(e: React.MouseEvent<HTMLButtonElement>, selObj: any) {
        if (selObj.tabIndex == 0)
            // This is what the asp code was doing.....I am guessing in case they changed the suppress values.
            this.setState({ tabIndex: selObj.tabIndex, cciPayerSelectionLocalState: undefined, mnPayerSelectionLocalState: undefined });
        else
            this.setState({ tabIndex: selObj.tabIndex })
    }

    public renderTab() {
        switch (this.state.tabIndex) {
            case 0:
                return (<MainClaimsOptions
                    mainClaimsData={this.props.dataStore.ui.MainClaimsData}
                    updateMainClaimsOptions={
                        (request: IMainClaimOptionsUpdateRequest) => {
                            handleChange();
                            this.props.action.ui.updateMainClaimsOptions({ masterCrud: this.props.dataStore.crud.data, uiData: request });
                        }
                    }
                />)
            case 1:
                return (<CCIOptions
                    {...this.props}
                    canView={this.props.canView}
                    canEdit={this.props.canEdit}
                    canDelete={this.props.canDelete}
                    displayAlertMessage={(msg: string) => { this.onAlert(msg); }}
                    radioIndex={this.state.cciRadioIndex}
                    updateRadioIndex={(index: number) => { this.setState({ cciRadioIndex: index }); }}

                    generalOptions={this.props.dataStore.ui.CCIGeneralOptions}
                    updateCCIGeneralOptions={
                        (request: IGeneralUpdateRequest) => {
                            handleChange();
                            this.props.action.ui.updateCCIGeneralOptions({ masterCrud: this.props.dataStore.crud.data, uiData: request });
                        }
                    }
                    generalOptionsLocalState={this.state.cciGeneralOptionsLocalState}
                    onGeneralOptionsLocalStateChange={(request) => { this.setState({ cciGeneralOptionsLocalState: request }); }}

                    payerSelectionLocalState={this.state.cciPayerSelectionLocalState}
                    onPayerSelectionLocalStateChange={(request) => { this.setState({ cciPayerSelectionLocalState: request }); }}
                    payerSelectionOptions={this.props.dataStore.ui.CCIPayerSelectionOptions}
                    updateCCIPayerSelectionOptions={
                        (request: IPayerSelectionUpdateRequest) => {
                            handleChange();
                            this.props.action.ui.updateCCIPayerSelection({ masterCrud: this.props.dataStore.crud.data, uiData: request });
                        }
                    }
                />)
            case 2:
                return (<MedicalNecessityOptions
                    {...this.props}
                    canView={this.props.canView}
                    canEdit={this.props.canEdit}
                    canDelete={this.props.canDelete}
                    displayAlertMessage={(msg: string) => { this.onAlert(msg); }}
                    radioIndex={this.state.mnRadioIndex}
                    updateRadioIndex={(index: number) => { this.setState({ mnRadioIndex: index }); }}

                    generalOptions={this.props.dataStore.ui.MNGeneralOptions}
                    updateMNGeneralOptions={
                        (request: IGeneralUpdateRequest) => {
                            handleChange();
                            this.props.action.ui.updateMNGeneralOptions({ masterCrud: this.props.dataStore.crud.data, uiData: request });
                        }
                    }
                    generalOptionsLocalState={this.state.mnGeneralOptionsLocalState}
                    onGeneralOptionsLocalStateChange={(request) => { this.setState({ mnGeneralOptionsLocalState: request }); }}

                    payerSelectionLocalState={this.state.mnPayerSelectionLocalState}
                    onPayerSelectionLocalStateChange={(request) => { this.setState({ mnPayerSelectionLocalState: request }); }}
                    payerSelectionOptions={this.props.dataStore.ui.MNPayerSelectionOptions}
                    updateMNPayerSelectionOptions={
                        (request: IPayerSelectionUpdateRequest) => {
                            handleChange();
                            this.props.action.ui.updateMNPayerSelection({ masterCrud: this.props.dataStore.crud.data, uiData: request });
                        }
                    }
                    policySelectionOptions={this.props.dataStore.ui.MNPolicySelectionOptions}
                    updateMNPolicySelectionOptions={
                        (request: IMNPolicySelectionUpdateRequest) => {
                            handleChange();
                            this.props.action.ui.updateRows({ masterCrud: this.props.dataStore.crud.data, uiData: request });
                        }
                    }
                    searchMNPolicyDescription={this.props.action.ui.searchAndUpdateRows}
                    onPolicySelectionLocalStateChange={
                        (request: IMNPolicySelectionLocalState) => {
                            this.props.action.ui.updateMNPolicyLocalState(request);
                        }
                    }
                />)
            case 3:
                return (<DuplicateClaimsOptions
                    canViewDuplicateClaimsOptions={this.props.canSecCheck1}
                    canEditDuplicateClaimsOptions={this.props.canSecCheck2}
                    optionsUB={this.props.dataStore.ui.DuplicateClaimsOptionsUB}
                    options1500={this.props.dataStore.ui.DuplicateClaimsOptions1500}
                    updateDuplicateClaimsOptions={
                        (request: IDuplicateClaimsUpdateRequest) => {
                            if (this.props.canSecCheck2)
                                handleChange();
                                this.props.action.ui.updateDuplicateClaims({ masterCrud: this.props.dataStore.crud.data, uiData: request });
                        }
                    }
                />)

            case 4:
                return (<RelatedClaimsOptions
                    canViewRelatedClaimsOptions={this.props.canSecCheck1}
                    canEditRelatedClaimsOptions={this.props.canSecCheck2}
                    optionsUB={this.props.dataStore.ui.RelatedClaimsOptionsUB}
                    options1500={this.props.dataStore.ui.RelatedClaimsOptions1500}
                    updateRelatedClaimsOptions={
                        (request: IRelatedClaimsUpdateRequest) => {
                            if (this.props.canSecCheck2)
                                handleChange();
                                this.props.action.ui.updateRelatedClaims({ masterCrud: this.props.dataStore.crud.data, uiData: request });
                        }
                    }
                />)

            default:
                return <div>
                    <p> default </p>
                </div>
        }
    }

    public render() {
        const { tabIndex } = this.state;
        const { /*props*/ isDirty } = this.props.dataStore.ui;
        let tabList: ITabItem[] = [{ label: 'Main', domID: 'id-Search' }, { label: 'CCI', domID: 'id-Results' }, { label: 'Medical Necessity', domID: 'id-medNecessity' },
        { label: 'Duplicate Claims', domID: 'id-duplicate' }]; 

        if (this.props.canSecCheck1)
            tabList.push({ label: 'Related Claims', domID: 'id-related' });

        return (<DialogWrapper
            paddingTop={'0px'}
            title='Claim Options Maintenance'
            instruction={this.instructions}
            helpUrl='/Support/Help/HELP_Maint_ClaimOptions.htm'
            buttons={this.okCancelButtons}
            isBusy={this.isBusy()}>
            <Prompt when={isDirty && !this.state.navigationConfirmed} message={this.showRouteChangeConfirm} />
            <ContentContainer>
                <TabContainer
                    style={{ boxShadow: 'none' }}
                    tabs={tabList}
                    onTabChange={(e: React.MouseEvent<HTMLButtonElement>, selObj: any) => this.onSelectTab(e, selObj)}
                    selectedTabIndex={tabIndex}>
                </TabContainer>
                <ContentColumnWrapper style={{ marginTop: "0px"}}>

                    {this.renderTab()}
                </ContentColumnWrapper>
            </ContentContainer>
            <ModalConfirmation
                isOpen={!!this.props.dataStore.ui.alertMessage && this.props.dataStore.ui.alertMessage.length > 0}
                onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                message={this.props.dataStore.ui.alertMessage}
                alertMode={true}
                onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
            />
            <ModalConfirmation
                isOpen={this.state.showCancel}
                alertMode={false}
                formattedMessage={leaveMessage}
                onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                    pageLeave();
                    this.props.history.push('/LandingPage');

                }}
                onDeny={() => this.setState({showCancel: false })}
            />
        </DialogWrapper>);
    }
}

const connectedHoc = connect<IClaimOptionsUIState,
    IClaimOptionsActionProps,
    IOwnProps,
    IClaimOptionsProps,
    ApplicationState>(
        createCrudMapStateToProps('claimOptions'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(ClaimOptions);

export default withRouter(connectedHoc);