import { ContentColumnWrapper, ContentRowWrapper } from "./DialogWrapper";
import React from "react";
import { ImageButton, IImageButtonImage } from "./ImageButton";
import styled from "styled-components";

import ArrowLeftUp from '../../content/images/Buttons/But_ArrowLeft_creamUp.png';
import ArrowLeftDown from '../../content/images/Buttons/But_ArrowLeft_creamDown.png';
import ArrowLeftOff from '../../content/images/Buttons/But_ArrowLeft_creamOff.png';
import ArrowLeftOver from '../../content/images/Buttons/But_ArrowLeft_creamOver.png';

import ArrowRightOff from '../../content/images/Buttons/But_ArrowRight_creamOff.png';
import ArrowRightOver from '../../content/images/Buttons/But_ArrowRight_creamOver.png';
import ArrowRightUp from '../../content/images/Buttons/But_ArrowRight_creamUp.png';
import ArrowRightDown from '../../content/images/Buttons/But_ArrowRight_creamDown.png';

const FooterInstruction = styled.div`   
    width:100%;
    margin: auto;
    padding: 3px;
    display: flex;

    #nav-ctrl {
       margin-left: auto; 
       display: flex;
    }
`;

interface IFooterInstructionProps {
    footerMsgs: string[];
    pageCount: number;
    pageTotal: number;
    diskSpace: number;
    width?: string;
    toolTipLeft?: string;                                   // tooltip?
    toolTipRight?: string;                                  // tooltip?
    disabled?: boolean;
    onClick?: React.ChangeEventHandler<HTMLButtonElement>;
    onLeftBtnClick?: React.ChangeEventHandler<HTMLButtonElement>;
    onRightBtnClick?: React.ChangeEventHandler<HTMLButtonElement>;
}

export class FooterInstructions extends React.Component<IFooterInstructionProps> {
    static defaultProps: IFooterInstructionProps = {
        toolTipLeft: 'Previous Page',
        toolTipRight: 'Next Page',
        disabled: false,
        footerMsgs: [],
        pageCount: 1,
        pageTotal: 1,
        diskSpace: 0,
        width: "100%",
    };

    constructor(props: IFooterInstructionProps) {
        super(props);
    }
    
    static arrowLeftIcon: IImageButtonImage = {
        Off: ArrowLeftOff,
        Over: ArrowLeftOver,
        Up: ArrowLeftUp,
        Down: ArrowLeftDown,
    }

    static arrowRightIcon: IImageButtonImage = {
        Off: ArrowRightOff,
        Over: ArrowRightOver,
        Up: ArrowRightUp,
        Down: ArrowRightDown,
    }

    public getArrowLeftIcon() {
        return (
            <ImageButton
                Title='leftArrow'
                tooltip={this.props.toolTipLeft}
                altText={this.props.toolTipLeft}
                image={FooterInstructions.arrowLeftIcon}
                className="arrow-button"
                disabled={(this.props.pageCount > 1) ? false : true}
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    if (this.props.onClick !== undefined) {
                        this.props.onClick(e);
                    } else {
                        if (this.props.onLeftBtnClick !== undefined) {
                            this.props.onLeftBtnClick(e);
                        }
                    }
                }}
            />
        );
    }

    public getArrowRightIcon() {
        return (
            <ImageButton
                Title='rightArrow'
                tooltip={this.props.toolTipRight}
                altText={this.props.toolTipRight}
                image={FooterInstructions.arrowRightIcon}
                className="arrow-button"
                disabled={this.props.pageCount < this.props.pageTotal ? false : true}
                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                    if (this.props.onClick !== undefined) {
                        this.props.onClick(e);
                    } else {
                        if (this.props.onRightBtnClick !== undefined) {
                            this.props.onRightBtnClick(e);
                        }
                    }
                }}
            />
        );
    }

    render() {

        let arrowLeftIcon = this.getArrowLeftIcon();
        let arrowRightIcon = this.getArrowRightIcon();
        let pageInstruction: string = this.props.pageCount.toString() + '/' + this.props.pageTotal.toString();
        
        return (
            <FooterInstruction>
                {this.props.footerMsgs.map((item) => {
                    return (
                        <ContentColumnWrapper>
                            <ContentRowWrapper>
                                <div key={item} style={{ paddingLeft: "450px" }}>
                                    {item}
                                </div>
                                <div id="nav-ctrl">
                                    {arrowLeftIcon}
                                    {pageInstruction}
                                    {arrowRightIcon}
                                </div>
                            </ContentRowWrapper>
                        </ContentColumnWrapper>
                    );
                })}
            </FooterInstruction>
        );
    }
}