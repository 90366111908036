import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IDashboardContactsUiState {
    selectedContact: ISelectContactData;
    contactName: string;
    contactEmail: string;
    contactNameError?: string;
    contactEmailError?: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectContactData {
    index: number;
    value: string;
    text: string;
};

export const defaultContact: ISelectContactData = {
    index: 0,
    value: '',
    text: '',
};

export interface ISelectContact extends ICrudActionData<MCDashboardMaintenance, ISelectContactData>{ }

export interface IModifyContact extends ICrudActionData<MCDashboardMaintenance, MCDashboardContact> {
    delta: MCARMDashboardMaintenanceDelta
}

export const actionCreators = {
    selectContact: (selectInfo: ISelectContact) => createDataAction('SELECT_CONTACT', selectInfo),
    updateContactName: (contactName: string) => createDataAction('UPDATE_CONTACT_NAME', contactName),
    updateContactEmail: (updateContactEmail: string) => createDataAction('UPDATE_CONTACT_EMAIL', updateContactEmail),
    addContact: (updateInfo: IModifyContact) => createDataAction('ADD_CONTACT', updateInfo),
    updateContact: (updateInfo: IModifyContact) => createDataAction('UPDATE_CONTACT', updateInfo),
    removeContact: (updateInfo: IModifyContact) => createDataAction('REMOVE_CONTACT', updateInfo),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IDashboardContactsUiState = {
    selectedContact: defaultContact,
    contactName: '',
    contactEmail: '',
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IDashboardContactsUiState, KnownActions> = (state: IDashboardContactsUiState = defaultState, action: KnownActions) => {

    var newIndex: number;
    switch (action.type) {
        case 'SELECT_CONTACT':
        {
            if (action.data.masterCrud) {
                //uiData.index is the UI-based index from the Select component; it may not be a 1:1 match to the index of the array of items in CRUD since some might have @Deleted = 1.
                //Need to look up by text since new items could have been added with an @ID not yet set.
                //var contactData = action.data.masterCrud.DashboardMaintenanceInfo.Contacts.Contact[action.data.uiData.index - 1];
                let dashboardContactData = action.data.masterCrud.DashboardMaintenanceInfo.ContactList.ContactEmail.find(existingContact => existingContact["@Name"] === action.data.uiData.text);
                return {
                    ...state,
                    selectedContact: action.data.uiData,
                    contactName: dashboardContactData ? dashboardContactData["@Name"] || '' : '',
                    contactEmail: dashboardContactData ? dashboardContactData["@Email"] || '' : '',
                    contactNameError: undefined,
                    contactEmailError: undefined,
                }
            }
            break;
        }
        case 'UPDATE_CONTACT_NAME':
            return {
                ...state,
                contactName: action.data,
            }
        case 'UPDATE_CONTACT_EMAIL':
            
            return {
                ...state,
                contactEmail: action.data,
            }

        case 'ADD_CONTACT':
        case 'UPDATE_CONTACT':
        {
            if (action.data.masterCrud) {
                let selectInfo: ISelectContactData = state.selectedContact;
                let contact: MCDashboardContact = action.data.uiData;
                let newStateData: IDashboardContactsUiState = { ...state };
                newIndex = state.selectedContact.index;
                newStateData.contactNameError = undefined;
                newStateData.contactEmailError = undefined;
                if (contact["@Name"] === '') {
                    newStateData.contactNameError = 'Please enter a display name.';
                    return newStateData;
                }
                if (contact["@Email"] === '') {
                    // allow updating email to blank, but not adding
                    if (action.type === 'ADD_CONTACT') {
                        newStateData.contactEmailError = 'Please enter an e-mail address.';
                        return newStateData;
                    }
                } else {
                    let mailformat = /\S+@\S+(\.\w{2,4})+$/;
                    if (contact["@Email"] !== '' && !contact["@Email"].match(mailformat)) {
                        newStateData.contactEmailError = 'Please enter a valid e-mail address.';
                        return newStateData;
                    }
                }
                var duplicate = action.data.masterCrud!.DashboardMaintenanceInfo.ContactList.ContactEmail.find(existingContact => ((existingContact["@Name"] === contact["@Name"]) && (existingContact["@ID"] !== contact["@ID"])));
                if (duplicate) {
                    if (duplicate["@Delete"] === 'true') {
                        // Should already be handled by the component, this is just a failsafe.
                        newStateData.contactNameError = "A deleted contact by that name already exists.";
                        return newStateData;
                    }
                    else {
                        newStateData.contactNameError = "A contact by that name already exists. Please type in a different name.";
                        return newStateData;
                    }
                }
                if (!(newStateData.contactNameError || newStateData.contactEmailError))
                {
                    if (action.type === 'ADD_CONTACT')
                    {
                        action.data.masterCrud!.DashboardMaintenanceInfo.ContactList.ContactEmail.push(JSON.parse(JSON.stringify(contact)));
                        newIndex = action.data.masterCrud!.DashboardMaintenanceInfo.ContactList.ContactEmail.length;
                        action.data.delta.DashboardMaintenanceInfo.ContactList.ContactEmail.push(JSON.parse(JSON.stringify(contact)));
                    }
                    else
                    {
                        let selectedContact = action.data.masterCrud!.DashboardMaintenanceInfo.ContactList.ContactEmail.find(existingContact => existingContact["@Name"] === selectInfo.text);
                        if (selectedContact) {
                            selectedContact["@Name"] = contact["@Name"];
                            selectedContact["@Email"] = contact["@Email"];
                            var existingDelta = action.data.delta.DashboardMaintenanceInfo.ContactList.ContactEmail.find(existingContact => existingContact['@ID'] === contact["@ID"]);
                            if (existingDelta) {
                                existingDelta['@Name'] = contact['@Name'];
                                existingDelta['@Email'] = contact['@Email'];
                            } else {
                                action.data.delta.DashboardMaintenanceInfo.ContactList.ContactEmail.push(JSON.parse(JSON.stringify(contact)));
                            }
                        }
                        else
                        {
                            return { ...state };
                        }
                    }
                    newStateData.selectedContact = {
                        index: newIndex,
                        value: contact["@ID"],
                        text: contact["@Name"]
                    };
                    newStateData.contactName = contact["@Name"];
                    newStateData.contactEmail = contact["@Email"];
                }
                return newStateData;
            }
            break;
        }
        case 'REMOVE_CONTACT':
        {
            if (action.data.masterCrud) {
                let contact: MCDashboardContact = action.data.uiData;
                // remove from delta
                if (('' + contact['@ID']).indexOf('New[') > -1) {
                    var existingDeltaIndex = action.data.delta.DashboardMaintenanceInfo.ContactList.ContactEmail.findIndex(existingContact => existingContact['@ID'] === contact['@ID']);
                    if (existingDeltaIndex > -1) {
                        action.data.delta.DashboardMaintenanceInfo.ContactList.ContactEmail.splice(existingDeltaIndex, 1);
                    }
                } else {
                    var contactToDelete = action.data.delta.DashboardMaintenanceInfo.ContactList.ContactEmail.find(existingContact => existingContact['@ID'] === contact['@ID']);
                    if (contactToDelete) {
                        contactToDelete['@Delete'] = 'true';
                    } else {
                        contact['@Delete'] = 'true';
                        action.data.delta.DashboardMaintenanceInfo.ContactList.ContactEmail.push(JSON.parse(JSON.stringify(contact)));
                    }
                }

                // remove from mastercrud data
                newIndex = state.selectedContact.index - 1;
                if (state.selectedContact.index < action.data.masterCrud.DashboardMaintenanceInfo.ContactList.ContactEmail.length) {
                    newIndex = state.selectedContact.index;
                }
                if (newIndex < 0) {
                    newIndex = 0;
                }

                action.data.masterCrud.DashboardMaintenanceInfo.ContactList.ContactEmail.splice(state.selectedContact.index - 1, 1);

                return {
                    ...state,
                    selectedContact: {
                        index: newIndex,
                        value: newIndex ? action.data.masterCrud!.DashboardMaintenanceInfo.ContactList.ContactEmail[newIndex - 1]["@ID"] : '',
                        text: newIndex ? action.data.masterCrud!.DashboardMaintenanceInfo.ContactList.ContactEmail[newIndex - 1]["@Name"] : '',
                    },
                    contactName: newIndex ? action.data.masterCrud!.DashboardMaintenanceInfo.ContactList.ContactEmail[newIndex - 1]["@Name"] : '',
                    contactEmail: newIndex ? action.data.masterCrud!.DashboardMaintenanceInfo.ContactList.ContactEmail[newIndex - 1]["@Email"] : '',
                };
            }
            break;
        }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
            return state;
    }

    return state;
}
