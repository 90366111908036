export const loadStateFromSession = () => {
    try {
        const serializedState = sessionStorage.getItem('state');
        if (serializedState === null) {
            return {};
        }
        return { ...JSON.parse(serializedState) };
    } catch (err) {
        return undefined;
    }
};

export const saveStateToSession = (state:any) => {
    try {
        const serializedState = JSON.stringify(state);
        sessionStorage.setItem('state', serializedState);
    } catch (err) {
        // Ignore write errors.
    }
};

export const destroyStateInSession = () => {
    try {
        sessionStorage.removeItem('state');
    } catch (err) {
        // Ignore write errors.
    }
};