import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import _ from 'lodash';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IDashboardMaintenanceUIState {
    deltaCrud: MCARMDashboardMaintenanceDelta;
    activeTab: number;
    isHoveringOverAddContacts: boolean;
    isHoveringOverRemoveContacts: boolean;
    isValidationModalOpen: boolean;
    isRemoveNotificationEventModalOpen: boolean;
    validationModalMessage: string;
    moderateOutput: string;
    dashboardMaintenanceInfo: MCDashboardMaintenanceInfo;
    gaugeSelection: number;
    eventSelection: MCDashboardEvent;
    contactSelection: MCDashboardContact;
    eventContactSelection: MCDashboardEventContact;
    allContactsKey: any;
    thisEventKey: any;
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const defualtEventSelection: MCDashboardEvent = {
    '@ID': "",
    '@Name': "",
    '@EventId': "",
    '@OperatorId': "",
    '@Threshold': "",
    '@Body': "",
    '@Time': "",
    '@Mon': "",
    '@Tue': "",
    '@Wed': "",
    '@Thu': "",
    '@Fri': "",
    '@Sat': "",
    '@Sun': "",
    Contacts: { Contact: [] },
    '@Delete': "",
}

const emptyDelta: MCARMDashboardMaintenanceDelta = 
{
    DashboardMaintenanceInfo: 
    {
        DashboardChartList: {
            Chart: [],
        },
        Events: {
            Event: [],
        },
        ContactList: {
            ContactEmail: [],
        }
    }

}

export const defaultDashboardMaintenance: IDashboardMaintenanceUIState = {
    deltaCrud: emptyDelta,
    activeTab: 0,
    isHoveringOverAddContacts: false,
    isHoveringOverRemoveContacts: false,
    isValidationModalOpen: false,
    isRemoveNotificationEventModalOpen: false,
    validationModalMessage: "",
    moderateOutput: "",
    dashboardMaintenanceInfo: {
        DashboardChartList: {
            Chart: []
        },
        Events: {
            Event: []
        },
        ContactList: {
            ContactEmail: []
        },
        NotifyList: {
            Notify: []
        }
    },
    gaugeSelection: 0,
    eventSelection: defualtEventSelection,
    contactSelection: {
        '@ID': "",
        '@Name': "",
        '@Email': "",
        '@Delete': "",
    },
    eventContactSelection: {
        '@ID': "",
        '@Delete': "",
    },
    allContactsKey: _.uniqueId(),
    thisEventKey: _.uniqueId(),
};

export interface IDashboardMaintenance extends ICrudActionData<MCDashboardMaintenance, IDashboardMaintenanceUIState> { }
export interface ISelectEvent extends ICrudActionData<MCDashboardMaintenance, IDashboardMaintenanceUIState> { }

export const actionCreators = {
    updateDashboardMaintenance: (updateInfo: IDashboardMaintenance) => createDataAction('UPDATE_DASHBOARD_MAINTENANCE', updateInfo),
    selectEvent: (selectInfo: ISelectEvent) => createDataAction('SELECT_EVENT', selectInfo)
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IDashboardMaintenanceUIState = defaultDashboardMaintenance;

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IDashboardMaintenanceUIState, KnownActions> = (state: IDashboardMaintenanceUIState = defaultState, action: KnownActions) => {

    switch (action.type) {
        case 'UPDATE_DASHBOARD_MAINTENANCE':
            {
                return {
                    ...state
                }
            }
        case 'SELECT_EVENT':
            {
                return {
                    ...state
                }
            }
        default:
            return state;
    }
}
