import React from "react";


interface IComponentProps {
    fileLink: string,
    name: any,
    key: any,
};

interface IComponentState {
    clicked: boolean
}
export const DEFAULT_STATE: IComponentState = { clicked: false }

export default class FileViewer extends React.Component<IComponentProps, IComponentState> {
    static defaultProps: IComponentProps = {
        fileLink: '',
        name: '',
        key: 0,
    };

    constructor(props: IComponentProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public render() {
        let fileClicked: any = sessionStorage.getItem("fileClicked")
        let items = []
        if (fileClicked !== null) {
            items = fileClicked.split(',')
        }

        return (
            <span>
                <a style={(typeof (this.props.name)) === 'string' && (items.filter((item: any) => item === this.props.fileLink).length > 0) ? {
                    borderBottom: '1px solid', color: 'blue', textDecoration: 'line-through'
                } : typeof (this.props.name) === 'string' ? { borderBottom: '1px solid', color: 'blue' } : {}} onClick={() => {
                    this.setState({ clicked: true })

                    let fileClicked: any = sessionStorage.getItem("fileClicked")
                    if (fileClicked === null)
                        fileClicked = ''
                    fileClicked = fileClicked + ',' + this.props.fileLink
                    sessionStorage.setItem('fileClicked', fileClicked)
                    window.document.open(this.props.fileLink, 'newwindow', 'width=1273,height=1250');
                }}
                >{this.props.name}</a>
            </span>
        )
    }
}