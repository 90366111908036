import { combineReducers } from "redux";

import {
  IApiComponentState,
  IApiComponentActionProps,
  createApiComponentActions,
  createApiComponentReducers,
} from "@scripts/util/ApiDataHelpers";
import { isPlainObject, keys as _keys } from "lodash";
import { ValidationCallback } from "@scripts/util/ActionHelpers";
import * as SiteTemplateTransferUI from "@store/ui/SiteTemplateTransferUI";

export type ISiteTemplateTransferState = IApiComponentState<
  MCSiteTemplateTransferType,
  SiteTemplateTransferUI.ISiteTemplateTransferUIState
>;

export const actionCreators =
  createApiComponentActions<SiteTemplateTransferUI.ActionCreators>(
    SiteTemplateTransferUI.actionCreators
  );

export type ISiteTemplateTransferActions = typeof actionCreators;
export type ISiteTemplateTransferActionProps =
  IApiComponentActionProps<ISiteTemplateTransferActions>;

export const reducers = createApiComponentReducers(
  "siteTemplateTransfer",
  SiteTemplateTransferUI.reducer
);

export const siteTranferValidationCallback: ValidationCallback<any> = (
  response: any
): response is any => {
  try {
    if (response) {
      const testResponse: any = JSON.parse(response);
      return !!testResponse && isPlainObject(testResponse);
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export const siteTranferValidationCallbackExt: ValidationCallback<any> = (
  response: any
): response is any => {
  try {
    return response && Array.isArray(response) ? true : false;
  } catch (error) {
    return false;
  }
};

export const reducer = combineReducers<ISiteTemplateTransferState>(reducers);
