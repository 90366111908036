import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';

import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';

import { UserPasswordHintsState, UserPasswordHintsActionProps, actionCreators } from
    '@store/UserPasswordHints';
import { SecurityQuestionAnswer, DropdownOption } from './SecurityQuestionAnswer';
import { UphControlId } from '../../../store/ui/UserPasswordHintsUI';
import { DialogLegend2, DialogFieldset } from '@common/DialogStyles';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '@common/AssuranceMenu';

//
//HARD-CODED STYLES
//
// Need to include width 100% when setting "display: flex" for IE 11
//https://stackoverflow.com/questions/21600345/flexbox-and-internet-explorer-11-displayflex-in-html
const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 100%;
    padding-left: 0.8em;
    padding-right: 0.8em;
`;

const Instructions = styled.div`   
    width:98%;
    margin: auto;
    border: 1px solid #9ba1a9;
    background-color: #e1e2e6;
    padding: 5px;
    text-aligh: left;
    color: #000000;
`;

const Notice = styled.div`
    color: #d84040;
    display: inline;
    font-weight: bold;
`;

const Bold = styled.div`
    font-weight: bold;
    display: inline;
`;

const Inline = styled.div`
    display: inline;
`;

//
// INTERFACE, TYPE, AND CLASS DEFINITIONS
//

//
// Default User Password Hint Control States
//

// Originally had the questions/answers and the error text in a single object, but encapsulating an object for at most
// two properties didn't seem worth it when we can just keep everything as a primitive
interface IComponentState {
    question1SelectedIndex: number,
    question2SelectedIndex: number,
    question3SelectedIndex: number,
    answer1Text: string,
    answer2Text: string,
    answer3Text: string,
    submitted: boolean,
    cancelLeave: boolean
}

interface IComponentProps {
    canView: boolean,
    canEdit: boolean,
    title: string,

}

//The only MasterCrud piece UserPasswordHints uses is the QuestionMaintenanceInfo
export const DEFAULT_STATE: IComponentState = {
    question1SelectedIndex: 0,
    question2SelectedIndex: 0,
    question3SelectedIndex: 0,
    answer1Text: "",
    answer2Text: "",
    answer3Text: "",
    submitted: false,
    cancelLeave: false
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type UserPasswordHintsProps = IMergeCrudComponentProps<UserPasswordHintsState, UserPasswordHintsActionProps, IOwnProps>;


export class UserPasswordHints extends React.Component<UserPasswordHintsProps, IComponentState> {
    static defaultProps: IComponentProps = {
        canView: true,
        canEdit: true,
        title: "User Password Hints"
    };

    constructor(props: UserPasswordHintsProps) {
        super(props);
        this.state = { ...DEFAULT_STATE };
    }


    public componentDidMount() {
        // Get the User Password Questions table data from the Master CRUD. Table ID is 67
        pageLeave();
        this.props.action.crud.get({ crudId: CrudTypes.mctiUserPasswordQuestions });
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
        pageLeave();
    }

    /**
     * Updates the data store's CRUD with the current questions and answers.
     * Used post-validation and immediately picked up on update, so should
     * not have to worry about state timing or validation issues.
     */
    private updateCrudWithAnswers() {
        if (this.props.dataStore.crud.data) {
            this.props.dataStore.crud.data.QuestionMaintenanceInfo.Answers = {
                Answer: [
                    {
                        "@ID": "#",
                        "@QuestionId": this.state.question1SelectedIndex,
                        "@Answer": this.state.answer1Text,
                    },
                    {
                        "@ID": "#",
                        "@QuestionId": this.state.question2SelectedIndex,
                        "@Answer": this.state.answer2Text,
                    },
                    {
                        "@ID": "#",
                        "@QuestionId": this.state.question3SelectedIndex,
                        "@Answer": this.state.answer3Text,
                    }]
            };
        }
    }


    //Kick off the validate -> submit -> redirect action chain.
    // ReSharper disable once InconsistentNaming
    /**
     * Kicks off the validation workflow (validation -> submisison -> redirect) once the user clicks OK.
     */
    public onOK() {
        // Tuple array with item format [question number, selected index]
        const currentQuestions: [UphControlId, number][] = [
            ["question1", this.state.question1SelectedIndex],
            ["question2", this.state.question2SelectedIndex],
            ["question3", this.state.question3SelectedIndex]
        ];

        // Tuple array with item format [answer number, answer text]
        const currentAnswers: [UphControlId, string][] = [
            ["answer1", this.state.answer1Text],
            ["answer2", this.state.answer2Text],
            ["answer3", this.state.answer3Text]
        ];

        // Pass our current questions and answers down to the redux state.
        //this.props.action.ui.validatePage(currentQuestions, currentAnswers,  this.props.dataStore.crud.data);
        this.props.action.ui.validateSubmitRedirect(currentQuestions,
            currentAnswers,
            this.props.dataStore.crud,
            this.props.history);
    }

    /**
     * Redirects the user to the landing page if they click the cancel button.
     */
    public onCancel() {
        debugger
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public render() {
        //Generic password instruction for bottom of the page.
        const bottomInstructions =
            <React.Fragment>Select questions, enter answers, and click OK to save or click Cancel to set your
                password hints at another time.</React.Fragment>;

        // Initial list of question to appear in the dropdown box.
        // Uses index 0 since the numbering in the DB starts at 1.
        const initialQuestionList = [{ label: "- Select -", value: "Select a Question", index: 0 }];

        // Pulls the list of questions from the Master CRUD and pushes them onto the questions list if the CRUD data 
        // is present. Otherwise just uses the initial list of questions. This hides the Master CRUD loading and control pop-in.
        // For optimization purposes, could try and put this in the Redux store at some point, but probably not worth the
        // effort for a page with this low of usage.
        const questionList = this.props.dataStore.crud.data
            ? initialQuestionList.concat(this.props.dataStore.crud.data.QuestionMaintenanceInfo.QuestionList.Question.map(question => ({
                label: question['@Name'],
                value: question['@Name'],
                index: question['@ID'],
            })))
            : initialQuestionList;

        //Buttons in the dialog wrapper footer.
        const buttons =
            <OKCancelButtons
                onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK()}
                onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel()} />;

        return (
            <DialogWrapper title="User Password Hints" width="44em" instruction={bottomInstructions} buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <Instructions>
                        <Notice>Notice!</Notice>
                        <Inline> In order to establish personal log-in security, </Inline>
                        <Bold>you must select three separate questions from the drop-down menus on each line below and answer with information specific to you.</Bold>
                        <br />
                        <br />
                        <Inline>
                            If you opt to click "Cancel", you will be brought to this page each time you log-in.
                        </Inline>
                    </Instructions>
                    <br />
                    <DialogFieldset id={'password_hints_fieldset'} >
                        <DialogLegend2>Password Hints</DialogLegend2>
                        <SecurityQuestionAnswer
                            questionNumber={1}
                            questionErrorMessage={this.props.dataStore.ui.question1Error}
                            initialQuestion={questionList.length > 0 ? questionList[this.state.question1SelectedIndex] : undefined}
                            initialAnswer={this.state.answer1Text}
                            answerErrorMessage={this.props.dataStore.ui.answer1Error}
                            questions={questionList}
                            onQuestionChange={(newOption: DropdownOption) => {
                                this.props.action.ui.clearError("question1");
                                this.setState({ question1SelectedIndex: newOption.index });
                                handleChange();
                            }}
                            onAnswerChange={(newAnswer: string) => {
                                this.props.action.ui.clearError("answer1");
                                this.setState({ answer1Text: newAnswer.trim() });
                                handleChange();

                            }} />
                        <SecurityQuestionAnswer
                            questionNumber={2}
                            questionErrorMessage={this.props.dataStore.ui.question2Error}
                            initialQuestion={questionList.length > 0 ? questionList[this.state.question2SelectedIndex] : undefined}
                            initialAnswer={this.state.answer2Text}
                            answerErrorMessage={this.props.dataStore.ui.answer2Error}
                            questions={questionList}
                            onQuestionChange={(newOption: DropdownOption) => {
                                this.props.action.ui.clearError("question2");
                                this.setState({ question2SelectedIndex: newOption.index });
                                handleChange();
                            }}
                            onAnswerChange={(newAnswer: string) => {
                                this.props.action.ui.clearError("answer2");
                                this.setState({ answer2Text: newAnswer.trim() });
                                handleChange();
                            }} />
                        <SecurityQuestionAnswer
                            questionNumber={3}
                            lastQuestion={true}
                            questionErrorMessage={this.props.dataStore.ui.question3Error}
                            initialQuestion={questionList.length > 0 ? questionList[this.state.question3SelectedIndex] : undefined}
                            initialAnswer={this.state.answer3Text}
                            answerErrorMessage={this.props.dataStore.ui.answer3Error}
                            questions={questionList}
                            onQuestionChange={(newOption: DropdownOption) => {
                                this.props.action.ui.clearError("question3");
                                this.setState({ question3SelectedIndex: newOption.index });
                                handleChange();
                            }}
                            onAnswerChange={(newAnswer: string) => {
                                this.props.action.ui.clearError("answer3");
                                this.setState({ answer3Text: newAnswer.trim() });
                                handleChange();
                            }} />
                    </DialogFieldset>
                </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<UserPasswordHintsState,
    UserPasswordHintsActionProps,
    IOwnProps,
    UserPasswordHintsProps,
    ApplicationState>(
        createCrudMapStateToProps('userPasswordHints'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(UserPasswordHints);

export default withRouter(connectedHoc);