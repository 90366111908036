import * as React from 'react';
import { DigitalColors } from './Colors';

export const CustomPoint = ({
    style,
    selectedPoint,
    setState,
    clickHandler,
    color,
    id,
    x,
    y,
    datum,
    displayLine,
    disableDataEvents,
    ...rest

}: any) => {

    const [hovered, setHovered] = React.useState(false);
    // Note: conditional rendering causes issues so, best solution is to set fill of points to "none" to dismiss them
    let newColor = displayLine ? color : "none";

    const mouseProps: any = {};

    //console.log("Are component level events disabled? ", disableDataEvents);

    // Only Emit if Not Disabled
    if (!disableDataEvents) {
        mouseProps.onClick = () => {
            // We call setState on the parent in order to have only one selected Point at any one time
            //console.log("Clicked point ID: ", datum.id);
            if (selectedPoint === datum.id) {
                setState({ selectedPoint: null })
            } else {
                setState({ selectedPoint: datum.id })
            }
            clickHandler(datum.actionPayload);
        }

        mouseProps.onMouseEnter = () => {
            setHovered(true);
            setState({ visible: true, datum: datum });
        }

        mouseProps.onMouseLeave = () => {
            setHovered(false);
            setState({ visible: false });
        }
    }

    return (
        <>
            <circle
                cx={x}
                cy={y}
                r={selectedPoint === datum.id ? 7 : 5}
                // Note: setState is a callback to set State on parent, not local component

                // Conditionally Render if graph is not clickable
                {...mouseProps}

                style={{
                    ...style,
                    fill: (selectedPoint === datum.id) ? DigitalColors.digitalRed100 : newColor,
                    stroke: (hovered ? DigitalColors.digitalRed100 : "none"),
                    strokeWidth: 3,

                }}
            />
            {selectedPoint === datum.id &&
                <circle
                    cx={x}
                    cy={y}
                    r={3}
                    style={{ fill: "white" }}
                />
            }
       </>
    )
}