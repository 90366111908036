import {
    Facility, UB92AlternateEditMaster, HCFAAlternateEditMaster,
    CopyAddressToClaim, BillingNumber, PhysicianAccountNo, TaxonomyCode,
    PayToAddress, PayToCity, PayToCopyAddressToClaim, LOBElement, TStatus, FacilityDelta, MCFacilityMasterTypeDelta
} from "@store/api/FacilityMasterType";

export class DefaultFacilityGenerator {

    static generateDefaultFacility() {
        return {
            '@ID': '0',
            '@Name': '',
            '@Description': '',
            '@Address': '',
            '@Address2': '',
            '@City': '',
            '@StateID': '',
            '@ZipCode': '',
            '@CountryID': '',
            '@TelephoneNo': '',
            '@FaxNo': '',
            '@ServiceStateID': '',
            '@ServiceZipCode': '',
            '@FacilitySubId': '',
            '@FedTaxId': '',
            '@FedTaxSubId': '',
            '@IDTypeID': '',
            '@FacilityTypeID': '',
            '@EnrollmentTypeID': '',
            '@PaperClaimsPrintingOptionTypeID': '',
            '@UB92AlternateEditMaster': UB92AlternateEditMaster.Empty,
            '@HCFAAlternateEditMaster': HCFAAlternateEditMaster.Empty,
            '@CopyAddressToClaim': CopyAddressToClaim.Y,
            '@BillingNumber': BillingNumber.Empty,
            '@PhysicianAccountNo': PhysicianAccountNo.Empty,
            '@SubmitterCode': '',
            '@SLSort': CopyAddressToClaim.Y,
            '@FacilityNPI': '',
            '@TaxonomyCode': TaxonomyCode.Empty,
            '@PayToName': '',
            '@PayToAddress': PayToAddress.Empty,
            '@PayToAddress2': '',
            '@PayToCity': PayToCity.Empty,
            '@PayToStateID': '',
            '@PayToZipCode': '',
            '@PayToCopyAddressToClaim': CopyAddressToClaim.Y,
            LOB: [] //[DefaultFacilityGenerator.getDefaultPayerInfo()]
        } as Facility;
    }

    static generateDefaultPayerInfo() {
        return {
            '@ID': '0',
            '@ProviderNo': '',
            '@PayerID': '',
            '@LOBID': '',
            '@Description': '',
            '@PlanCodeID': '',
            '@EnrollmentTypeID': '',
            '@AccountStatus': 'T',
            '@RemitStatus': 'L',
            '@SetToDefault': 'N',
            '@NPI': '',
            '@Taxonomy': '',
            '@SuppressCCIAI': 'N',
            '@SuppressCCIBI': 'N',
            '@SuppressCCIBP': 'N',
            '@SuppressMNAI': 'N',
            '@SuppressMNBI': 'N',
            '@SuppressMNBP': 'N',
            '@NPIFlag': '',
        } as LOBElement
    }

    static generateDefaultDelta() : MCFacilityMasterTypeDelta {
        return {
            FacilityMaintenanceInfo: {
                Facilities: {
                    Facility: new Array<FacilityDelta>()
                }
            }
        }
    }
}
