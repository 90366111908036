import { JobDetailsTableBuilder } from './JobDetailsTableBuilder';
import { JobDetailsDAL } from './JobDetailsDAL';

export class JobDetailsViewModelBuilder {
    public async BuildViewModel(jobId: string) {
        const apiData = await new JobDetailsDAL().GetJobDetailsData(jobId);
        let jdvm: JobDetailsViewModel | undefined;
        if (apiData) {
            try {
                const jdtb = new JobDetailsTableBuilder();
                jdvm = jdtb.buildTable(apiData) as JobDetailsViewModel;
            } catch (error) {
            }
        } else {
            throw Error('ERROR: JobDetailsViewModelBuilder Failed');
        }
        return jdvm;
    }
}
