import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, Button } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IAllowedIpMaintenanceState, IAllowedIpMaintenanceActionProps, actionCreators, validationCallback } from '@store/AllowedIpMaintenance';
import { URLs } from '@commonDevResources/constants';
import { handleChange, pageLeave } from '@commonResources/userModified';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    #ClaimNoteDescription {
        width: 350px;
        height: 100px;
    }
`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    margin-top: 11px;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const IpLabel = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    font-size: ${Typography.small};
    padding: 5px;
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
};

interface IComponentState {
};

export const DEFAULT_STATE: IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IAllowedIpMaintenanceProps = IMergeCrudComponentProps<IAllowedIpMaintenanceState, IAllowedIpMaintenanceActionProps, IOwnProps>;

enum eModifyButtonType {
    Add,
    Update
}

export class AllowedIpMaintenance extends React.Component<IAllowedIpMaintenanceProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
    };

    insertDeltaIndex: number = 0;

    changeList: MCRemoteIpAddress = {
        RemoteAddressesMaintenanceInfo: {
            RemoteAddresses: {
                RemoteAddress: []
            }
        }
    };

    currentIp: string = '';

    constructor(props: IAllowedIpMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        pageLeave();
        var self = this;
        fetch(`${URLs.api}/api/data/info/clientip`)
            .then(response => response.json()
                .then(ipText => { self.currentIp = ipText; }));

        this.props.action.crud.get({ crudId: CrudTypes.mctiRemoteAddressesMaster }, validationCallback);
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onSelectRemoteAddress(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectRemoteAddress(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    value: e.target.options[e.target.options.selectedIndex].value,
                    text: e.target.options[e.target.options.selectedIndex].textContent
                },
                deltaIndex: this.insertDeltaIndex,
                delta: this.changeList
            });
    }

    isCurrentIpInList() {
        if (this.props.dataStore &&
            this.props.dataStore.crud &&
            this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.RemoteAddressesMaintenanceInfo &&
            this.props.dataStore.crud.data.RemoteAddressesMaintenanceInfo.RemoteAddresses) {
            var addresses = this.props.dataStore.crud.data.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress;
            if (addresses) {
                if (addresses.length === 0) {       // empty list?
                     return true;
                }
                const currentIpElements = this.currentIp.split('.');
                for (var index = 0; index < addresses.length; ++index) {
                    if (this.currentIp === addresses[index]['@IP']) {  // exact address in list?
                        return true;
                    }
                    // check if currentIp is covered by wildcard address
                    if (currentIpElements.length > 0) {
                        const wildCardIndex = addresses[index]['@IP'].indexOf('.*');
                        if (wildCardIndex > 1) {
                            let isCoveredByWildcard = true;
                            const leadingElements = addresses[index]['@IP'].substr(0, wildCardIndex).split('.');
                            for (var leadingIndex = 0; leadingIndex < leadingElements.length; ++leadingIndex) {
                                isCoveredByWildcard = isCoveredByWildcard &&
                                    (currentIpElements[leadingIndex] === leadingElements[leadingIndex]);
                            }
                            if (isCoveredByWildcard) { return true; }
                        }
                    }
                }
            }
        }
        return false;
    }

    commitChanges() {
        for (var item of this.changeList.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress) {
            if (('' + item['@ID']).indexOf('New[') > -1) {
                item['@ID'] = '#';
            }
        }
        this.props.dataStore.crud.data = this.changeList;
        this.props.action.crud.update(this.props.dataStore.crud);

        this.gotoLandingPage();
    }

    gotoLandingPage() {
        this.props.history.push("/LandingPage");
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.isCurrentIpInList()) {
            this.commitChanges();
            return;
        }

        this.onToggleCommitWithoutClientIpConfirm(true);
    }

    confirmedCancel() {
        this.gotoLandingPage();
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.isChangePending()) {
            this.onToggleCommitDirtyLeaveThePageConfirm(true);
            return;
        }
        this.confirmedCancel();
    }

    public getModifyButtonType(): eModifyButtonType {
        switch (this.props.dataStore.ui.selectedAddress.index) {
            case 0:
                return eModifyButtonType.Add;
            default:
                return eModifyButtonType.Update;
        }
    }

    public getModifyButtonText() {
        switch (this.getModifyButtonType()) {
            case eModifyButtonType.Add:
                return 'Add';
            default:
                return 'Update';
        }
    }

    public isRemoveButtonDisabled() {
        return (this.props.dataStore.ui.selectedAddress.index === 0);
    }

    onToggleCommitWithoutClientIpConfirm(show: boolean) {
        this.props.action.ui.toggleCommitWithoutClientIpConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: show } });
    } 

    onToggleCommitDirtyLeaveThePageConfirm(show: boolean) {
        this.props.action.ui.toggleCommitDirtyLeaveThePageConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: show } });
    } 
            
    public onClickModifyButton(e: React.MouseEvent<HTMLButtonElement>) {
        if (this.props.dataStore.crud.data) {
            switch (this.getModifyButtonType()) {
                case eModifyButtonType.Add:
                    this.props.action.ui.addRemoteAddress({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            '@ID': 'New[' + (this.insertDeltaIndex++) + ']',
                            '@IP': this.props.dataStore.ui.remoteIpAddress,
                        },
                        delta: this.changeList
                    });
                    break;
                default:
                    this.props.action.ui.updateRemoteAddress({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: {
                            '@ID': this.props.dataStore.ui.selectedAddress.index,
                            '@IP': this.props.dataStore.ui.remoteIpAddress,
                        },
                        delta: this.changeList
                    });
            }
        }
    }

    public onClickRemoveButton(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.action.confirm.openConfirm("Do you want to remove " + this.props.dataStore.ui.remoteIpAddress + "? This action cannot be undone.");
    }

    public onClickConfirmRemoveButton(e: React.MouseEvent<HTMLButtonElement>) {
        handleChange();
        this.props.action.ui.removeRemoteAddress({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                '@ID': this.props.dataStore.ui.selectedAddress.value,
                '@IP': this.props.dataStore.ui.remoteIpAddress
            },
            delta: this.changeList
        });
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    isChangePending() {
        if (this.changeList && 
            this.changeList.RemoteAddressesMaintenanceInfo && 
            this.changeList.RemoteAddressesMaintenanceInfo.RemoteAddresses && 
            this.changeList.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress &&
            this.changeList.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress.length > 0 ) {
            return true;
        }
        return false;
    }

    public render() {
        var width: React.CSSProperties = {
            width: '300px'
        };
        var instruction = <React.Fragment>Use this page to add and remove Allowed IP addresses.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.isChangePending() || !this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        var addItem: MCRemoteAddress[] = [
            {
                '@ID': '',
                '@IP': '- ADD A NEW IP ADDRESS -',
            }
        ];

        var data = addItem;
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.RemoteAddressesMaintenanceInfo &&
            this.props.dataStore.crud.data.RemoteAddressesMaintenanceInfo.RemoteAddresses &&
            this.props.dataStore.crud.data.RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress) {
            data = addItem.concat(this.props.dataStore.crud.data
                .RemoteAddressesMaintenanceInfo.RemoteAddresses.RemoteAddress);
        }

        return (
            <DialogWrapper title="Allowed IP Addresses Maintenance" instruction={instruction} helpUrl='/Support/Help/HELP_Maint_SetupAllowedIPAddresses.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                <ContentWrapper>
                    <SelectList>
                        <DialogFieldset>
                            <DialogLegend>Allowed IP Addresses</DialogLegend>
                            <SelectComponent
                                title='AllowedIpAddresses'
                                size={10}
                                width='300px'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectRemoteAddress(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@IP"
                                }}
                                records={data}
                                selectedValue={this.props.dataStore.ui.selectedAddress.value}
                            >
                            </SelectComponent>
                        </DialogFieldset>
                    </SelectList>
                    <SelectActions>
                        <Input
                            domID="remote-ip-address"
                            className="text-input"
                            label="*IP Address:"
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateRemoteAddressValue(e.target.value)}
                            initialValue={this.props.dataStore.ui.remoteIpAddress}
                            hasError={this.props.dataStore.ui.remoteIpAddressError && this.props.dataStore.ui.remoteIpAddressError !== ''}
                            errorMessage={this.props.dataStore.ui.remoteIpAddressError}
                        />
                        <SelectButtons>
                            <Button
                                domID="addButton"
                                name={this.getModifyButtonText()}
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canEdit}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    handleChange();
                                    this.onClickModifyButton(e);
                                }}
                            />
                            <Button
                                domID="removeButton"
                                name="Remove"
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canEdit || this.isRemoveButtonDisabled()}
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickRemoveButton(e)} />
                        </SelectButtons>
                        <IpLabel>Example: Your machine IP is {this.currentIp}</IpLabel>
                    </SelectActions>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirmRemoveButton(e)}
                />
                <ModalConfirmation
                    isOpen={this.props.dataStore.ui.showCommitWithoutClientIp}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleCommitWithoutClientIpConfirm(false)}
                    formattedMessage={(
                        <div>
                            <p>Your current IP ({this.currentIp}) is not in the list. This will stop you from logging in the next time. Are you sure you want to continue?</p>
                        </div>)}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.commitChanges()}
                />
                <ModalConfirmation
                    isOpen={this.props.dataStore.ui.showCommitDirtyLeaveThePage}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleCommitDirtyLeaveThePageConfirm(false)}
                    formattedMessage={(
                        <div>
                            <b>Are you sure you want to leave this page?</b> <br /> <p> Your changes may NOT be saved.</p>
                        </div>)}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.confirmedCancel()}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IAllowedIpMaintenanceState, IAllowedIpMaintenanceActionProps, IOwnProps, IAllowedIpMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps('allowedIpMaintenance'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(AllowedIpMaintenance);

export default withRouter(connectedHoc);
