import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from "styled-components";
import { IAEIState, IAEIActionProps, actionCreators, validationCallback } from "@store/ManageHelpNotesForEdits";
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { DialogWrapper, ContentRowWrapper, ContentColumnWrapper } from "@common/DialogWrapper";
import { Input, TextArea, Button, SelectDropdownV2, TabContainer, Grid } from 'ui-core';
import { pageLeave } from '@commonResources/userModified';
import { AssuranceMenu } from '@common/AssuranceMenu';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import CrudTypes from '@commonResources/CrudTypes';
import Colors from '@commonResources/colorVariables';
import Typography from '@commonResources/typography';
import { isEmpty } from 'lodash';
import { ARMGridPager } from '@common/UICLWrappers/ARMGridPager';
import { IModalConfirmationProps, ModalConfirmation, leaveMessage } from '@common/ModalConfirmation';
import { SortHelper, ISortIndicator, SortDataType, SortOrder } from "@scripts/util/SortHelper"
import { ICookies_Config } from "@store/ConfigData";

const IMG = styled.img`
    margin-left:5px;
    padding-top: 7px !important;
    cursor: pointer;
`;

const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    #WONTabContent
    {
        padding-bottom: 0.3em !important;
    }

    #TabContainerResponse
    {
        width:1200px !important;
        line-height:3px;
    }

    // Tab 1 Without Notes Control Styles
    #WON-FormFilter 
    {
        margin-right:3px;
        min-width:100px;
        max-width:100px;
    }

    #WON-PayerFilter {
       margin-right: 3px;
       min-width:230px;
       max-width:230px; 
    }

    #WON-FieldFilter {
       margin-right: 3px;
       min-width:150px;
       max-width:150px; 
    }

    #WON-Clear-Link {
       padding-left: 0.2em !important;
    }
    
    #WON-Filter-Link {
        padding-left: 0.2em !important;
    }

    #WN-Clear-Link {
       padding-left: 0.2em !important;
    }
    
    #WN-Filter-Link {
        padding-left: 0.2em !important;
    }

    #remove-error {
        margin-top: 5px;
    }

    #WON-EditNameFilter {
       min-width:120px;
       max-width:120px; 
    }
   
     #WON-CriteriaField {
        margin-left:355px;
        align:left;
        width: 175px;    
    }

    #WON-CriteriaExpression {
        margin-left:5px;
        align:left;
        width: 120px;    
    }

    #WON-AdditionalInfo {
        line-height: 1.5 !important;
        margin-left:305px;
        width: 350px;
        height: 100px;
    }
    
    label[for="WON-AdditionalInfo"]
    {
        margin-left: 305px;
    }

    #WON-CriteriaExpressionValue {
        margin-left:5px;
    }


    // Tab 2 WithNotes Control Styles
    #WN-FormFilter
    {
        margin-right: 3px;
        min-width:80px;
        max-width:80px;
    }

    #WN-PayerFilter
    {
       margin-right: 3px;
       min-width:180px;
       max-width:180px; 
    }

    #WN-FieldFilter
    {
       margin-right: 3px;
       min-width:100px;
       max-width:100px; 
    }

    #WN-EditNameFilter
    {
       min-width:120px;
       max-width:120px; 
    }

    #WN-CriteriaField {
        margin-left:525px;
        align:right;
        width: 175px;    
    }

    #WN-CriteriaExpression {
        margin-left:5px;
        align:right;
        width: 120px;    
    }
    
    #WN-AdditionalInfoDesc {
        line-height: 1.5 !important;
        margin-left:0px;
        width: 350px;
        height: 100px;
    }
   
    #WN-CriteriaExpressionValue {
        margin-left:5px;
    }
    
`;

export const SelectButtons = styled.div`
    padding-left: 20px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

const GridWrapper = styled.div`
    #grid-wrapper-WON-Grid {
        height:400px;
    }

    #grid-wrapper-WN-Grid {
        height:400px;
    }

    .empty-row{
        background-color:white;
    }

    thead tr th{
        background-color:#9BA1A9;
        height:12px;
    }

    table tbody tr > td{
        height:12px !important;
        line-height:12px;
    }

    table tbody tr td div{
        min-height:12px;
    }

    .FormType {
        min-width:80px;
        max-width:80px;
    }

    .Payer {
        min-width:180px;
        max-width:180px;
    }

    .Field {
        min-width:120px;
        max-width:120px;
    }

    .EditName {
        min-width:120px;
        max-width:120px;
    }

    .Message {
        min-width:350px;
        max-width:350px;
        overflow: hidden;
    }

    .AdditionalMsg {
        min-width:350px;
        max-width:350px;
        overflow: hidden;
    }
`;

const GridWrapperWONotes = styled.div`
    margin-top: 0.3em;
    width: 100% !important;
    border: black 1px solid;
    background-color: white;
`;

const MSGDiv = styled.div`
    overflow: hidden;
    white-space: nowrap;
`;

const GridWrapperWNotes = styled.div`
    margin-top: 0.3em;
    width: 100% !important;
    border: black 1px solid;
    background-color: white;
`;

export const FormRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    width:100%;
    .margin-padding {
        margin-left: 5px;
        margin-right: 5px;
        color:black; 
    }
    .selectDropDown
    {
        margin-left: 5px;
        margin-right: 5px;
        color:black; 
        width:400px;
    }
    #TabContainerResponse
    {
        width:1200px !important;
        line-height:3px;
    }
                
`;

export const SelectionErrorMessage = styled.span`
  width: 300px;
  display: block;
  color: ${Colors.digitalRed130};
  ${Typography.small};
  ${Typography.defaultLineHeight};
`;

enum eModifyButtonType {
    Add,
    Update
}

export interface IRowProps {
    record: {
        index: string,
        FormType: string,
        Payer: string,
        Field: string,
        EditName: string,
        Message: string,
        AdditionalMsg: string,
    }
}

interface SelectedIndicator {
    pageNumber: number,
    index: string,
}

interface IComponentProps {
    // Manage Help NotesForEdits Security Bits
    canView: boolean;   // SecurityBits.FN_VIEW_ERROR_ADDITIONAL
    canEdit: boolean;   // SecurityBits.FN_EDIT_ERROR_ADDITIONAL
    title: string;
};


export class FormType {
    private id: string = '';
    private name: string = '';

    constructor(id: string, name: string) {
        this.id = id;
        this.name = name;
    }
}

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IAEIProps = IMergeCrudComponentProps<IAEIState, IAEIActionProps, IOwnProps>;

interface IComponentState {
    cancelLeave: boolean,

    mouseOverWONFormType: string,
    mouseOverWNFormType: string,
    selectedIndicators: SelectedIndicator[],

    allFormTypes: FormType[],
    allWONotes: MCAEIWONError[],
    filteredWONotes: MCAEIWONError[],
    selectedWONotes: MCAEIWONError[],

    allWONCriteriaFields: IDropDownItem[],
    allWONExpressionList: IDropDownItem[],

    allWNotes: MCAEIWNError[],
    filteredWNotes: MCAEIWNError[],
    selectedWNotes: MCAEIWNError[],

    allWNCriteriaFields: IDropDownItem[],
    allWNExpressionList: IDropDownItem[],

    selectedWNCriteriaField: IDropDownItem,
    selectedWNCriteriaExp: IDropDownItem,
    selectedWNCriteriaValue: string,
    selectedWNAdditionalInfo: string,
    selectedCriteriaEditNote: string,

    gridWONPaging: {
        currentPageNumber: number,
        numberOfPages: number,
        recordsPerPage: number,
        totalRecordCount: number
    },

    gridWNPaging: {
        currentPageNumber: number,
        numberOfPages: number,
        recordsPerPage: number,
        totalRecordCount: number
    },

    sortIndicator: {
        sortColumn: string,
        sortDirection: string
    };

    pageIndex: number,
    pageTotal: number,

    selectedWONColumnName: string,
    selectedWNColumnName: string,

    editNoteCriterias: MCAEICriteria[],
    selectedEditCriteria?: MCAEICriteria,
    isLoading: boolean,
    isCriteriaFieldDisabled: boolean
}

export const DEFAULT_STATE: IComponentState = {
    cancelLeave: false,
    mouseOverWONFormType: '',
    mouseOverWNFormType: '',

    gridWONPaging: {
        currentPageNumber: 1,
        numberOfPages: 1,
        recordsPerPage: 20,
        totalRecordCount: 0
    },

    gridWNPaging: {
        currentPageNumber: 1,
        numberOfPages: 1,
        recordsPerPage: 20,
        totalRecordCount: 0
    },

    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
    },

    selectedIndicators: [],

    allFormTypes: [],
    allWONotes: [],
    filteredWONotes: [],
    selectedWONotes: [],

    allWONCriteriaFields: [],
    allWONExpressionList: [],

    allWNotes: [],
    filteredWNotes: [],
    selectedWNotes: [],

    pageIndex: 1,
    pageTotal: 1,

    allWNCriteriaFields: [],
    allWNExpressionList: [],
    selectedWONColumnName: 'FormType',
    selectedWNColumnName: 'FormType',

    selectedWNCriteriaField: {
        label: '',
        value: ''
    },
    selectedWNCriteriaExp: {
        label: '',
        value: ''
    },

    selectedWNCriteriaValue: '',
    selectedWNAdditionalInfo: '',
    editNoteCriterias: [],
    selectedCriteriaEditNote: '',
    isLoading: false,
    isCriteriaFieldDisabled: false
}

interface FilterParam {
    Name: string,
    Value: string,
    Type?: string
}

interface IDropDownItem {
    label: string,
    value: string,
}

interface FilterRequest {
    Param: FilterParam[]
}

export class ManangeHelpNotesForEdits extends React.Component<IAEIProps, IComponentState> {

    readonly MAX_MSG_LENGTH = 500;

    static defaultProps: IComponentProps = {
        // Manage Help NotesForEdits Security Bits
        canView: false, // SecurityBits.FN_VIEW_ERROR_ADDITIONAL
        canEdit: false, // SecurityBits.FN_EDIT_ERROR_ADDITIONAL

        title: "Additional Error Information",
    };

    constructor(props: IAEIProps) {
        super(props);
        this.state = DEFAULT_STATE;

        this.applyWONFilter = this.applyWONFilter.bind(this);
        this.initializeWONGrid = this.initializeWONGrid.bind(this);
        this.initializeWNGrid = this.initializeWNGrid.bind(this);
        this.applyWNFilter = this.applyWNFilter.bind(this);
        this.handleWNPaging = this.handleWNPaging.bind(this);
        this.handleWONPaging = this.handleWONPaging.bind(this);
    }

    public componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }

        this.props.action.configData.getConfigData({
            cookies: [],
            config: [{ name: "MCASimpleRefreshMinutes" }, { name: "EditAdditionalInfoQueryDays" }]
        });

        pageLeave();
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    getTabs() {
        var tabJson = [
            {
                domID: 'WONTab',
                label: 'Errors Without Notes',
                tabContent: this.WONTab()
            },
            {
                domID: 'WNTab',
                label: 'Errors With Notes',
                tabContent: this.WNTab()
            },
        ];
        return tabJson;
    }

    public getModifyButtonType(): eModifyButtonType {
        const { selectedEditCriteria, selectedWNotes } = this.state;
        const { dataSearch } = this.props.dataStore.crud;
        let selectedCriteriaIndex: number = -1;
        if (selectedWNotes && selectedWNotes.length > 0 &&
            selectedEditCriteria && selectedEditCriteria['@ErrorClientAdditionalId'] !== "" &&
            selectedEditCriteria['@ErrorClientAdditionalMessageId'] !== "") {
            if (dataSearch && dataSearch.ErrorsWithNotes &&
                dataSearch.ErrorsWithNotes.CriteriaList &&
                dataSearch.ErrorsWithNotes.CriteriaList.Criteria &&
                dataSearch.ErrorsWithNotes.CriteriaList.Criteria.length > 0) {
                selectedCriteriaIndex = dataSearch.ErrorsWithNotes.CriteriaList.Criteria.findIndex(clst =>
                    (clst['@ErrorClientAdditionalId'] === selectedEditCriteria['@ErrorClientAdditionalId'] &&
                        clst['@ErrorClientAdditionalMessageId'] === selectedEditCriteria['@ErrorClientAdditionalMessageId']));
            }

            if (selectedCriteriaIndex !== -1) {
                if (isEmpty(selectedEditCriteria?.['@FieldName']) && isEmpty(selectedEditCriteria?.['@FieldDVARName'])) {
                    selectedCriteriaIndex = 0;
                } else if (selectedEditCriteria?.['@FieldName'] === "No Criteria" || selectedEditCriteria?.['@FieldDVARName'] === "NoCriteria") {
                    selectedCriteriaIndex = 1;
                }
            }
        }

        return (selectedCriteriaIndex < 0) ? eModifyButtonType.Add : eModifyButtonType.Update;
    }

    public getModifyButtonText(): string {
        return this.getModifyButtonType() == eModifyButtonType.Add ? 'Add' : 'Update';
    }

    isWONAddNoteEnabled = (): boolean => {
        // Need to Add another logic to select Errors without Note
        return (this.props.dataStore.ui.selectedENCriteriaFieldNameForWON === "");
    }

    public handleSort(sortIndicator: ISortIndicator) {
        const { filteredWONotes } = this.state;

        let sortedRecords: MCAEIWONError[] = [];

        switch (sortIndicator.sortColumn) {
            case 'FromType': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => (a.FormType.localeCompare(b.FormType))) :
                    filteredWONotes.sort((a, b) => (b.FormType.localeCompare(a.FormType)));
            }
                break;
            case 'Payer': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => a.Payer.localeCompare(b.Payer)) :
                    filteredWONotes.sort((a, b) => b.Payer.localeCompare(a.Payer));
            }
                break;
            case 'Field': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => a.Field.localeCompare(b.Field)) :
                    filteredWONotes.sort((a, b) => b.Field.localeCompare(a.Field));
            }
                break;
            case 'EditName': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => a.EditName.localeCompare(b.EditName)) :
                    filteredWONotes.sort((a, b) => b.EditName.localeCompare(a.EditName));
            }
                break;
            case 'Message': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => a.Message.localeCompare(b.Message)) :
                    filteredWONotes.sort((a, b) => b.Message.localeCompare(a.Message));
            }
                break;
        }

        this.setState({ sortIndicator, filteredWONotes: sortedRecords });
    }

    public onSelectWONotes(e: any, record: any) {

        const {
            selectedWONotes,
        } = this.state;

        if (record) {
            if (e.currentTarget.className === 'selected') {
                e.currentTarget.className = '';
                const idx = record['@Idx'];
                const index = selectedWONotes.findIndex(selNote => selNote['@Idx'] === idx);
                if (index > -1) {
                    selectedWONotes.splice(index, 1);
                }
            } else {
                e.currentTarget.className = 'selected';
                selectedWONotes.push(record);
            }
        }

        if (selectedWONotes && selectedWONotes.length > 0) {
            this.errorWONValidationMessage("");
        }
    }

    onChangeWONPayerFilter = (payer: string) => {
        this.props.action.ui.updateWONPayerFilter(payer.toUpperCase());
    }

    WONTab = () => {
        const { allWONotes, allWONCriteriaFields, allWONExpressionList, gridWONPaging } = this.state;
        const { selectedFilterFormForWON,
            selectedFilterPayerForWON, selectedFilterFieldForWON, selectedFilterEditForWON, additionalMsg_Validation_ErrorForWON,
            selectedENCriteriaExpressionForWON, selectedENCriteriaDVARFieldNameForWON,
            selectedAdditionalInfoForWON, selectedENCriteriaExpressionValueForWON, criteriaValue_Validation_ErrorForWON
        } = this.props.dataStore.ui;
        let isAddNoteEnabled: boolean = this.isWONAddNoteEnabled();
        let isWONCriteriaExpDisabled: boolean = this.isWONCriteriaExpDisabled();
        let isWONEditNoteSectionDisabled: boolean = allWONotes.length <= 0;

        return (
            <React.Fragment>
                <ContentWrapper>
                    <ContentColumnWrapper>
                        <ContentRowWrapper>
                            <SelectDropdownV2
                                domID="WON-FormFilter"
                                className="dropdown"
                                size='small'
                                isClearable={false}
                                isSearchable={true}
                                onChange={(e: any) => this.onChangeFilterForm(e.value)}
                                options={this.getFormList()}
                                initialValue={this.getInitialValue(this.getFormList(), selectedFilterFormForWON)}
                                disabled={false}
                            />
                            <Input
                                domID="WON-PayerFilter"
                                className="text-input"
                                maxLength={20}
                                width='150px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeWONPayerFilter(e.target.value)}
                                initialValue={selectedFilterPayerForWON}
                            />
                            <Input
                                domID="WON-FieldFilter"
                                className="text-input"
                                maxLength={20}
                                width='150px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateWONFieldFilter(e.target.value)}
                                initialValue={selectedFilterFieldForWON}
                            />
                            <Input
                                domID="WON-EditNameFilter"
                                className="text-input"
                                maxLength={10}
                                width='125px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateWONEditFilter(e.target.value)}
                                initialValue={selectedFilterEditForWON}
                            />
                            <React.Fragment>
                                <span>
                                    <IMG src='/shared/images/Icons/RefreshArrow.png' onClick={() => this.applyWONFilter(true)} />
                                </span>
                                <Button
                                    domID="WON-Filter-Link"
                                    name="Filter"
                                    buttonType="link"
                                    size="small"
                                    type="link"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.applyWONFilter(true)}>
                                </Button>
                            </React.Fragment>
                            <Button
                                domID="WON-Clear-Link"
                                name="Clear"
                                buttonType="link"
                                size="small"
                                type="link"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.ClearFilter()}>
                            </Button>
                        </ContentRowWrapper>
                    </ContentColumnWrapper>
                    <ContentColumnWrapper>
                        <ContentRowWrapper>
                            <GridWrapperWONotes>
                                <GridWrapper>
                                    <Grid
                                        domID="WON-Grid"
                                        isFixedHeader={true}
                                        maxHeight='385px'
                                        columns={this.WONGridColumns()}
                                        records={allWONotes}
                                        onClickThrough={(e: any, record: any) => { this.onSelectWONotes(e, record) }}
                                        selectionKey='FormType'
                                    />
                                </GridWrapper>
                            </GridWrapperWONotes>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <div style={{ marginTop: 5, flexGrow: 1, textAlign: 'right', position: 'relative', left: 20, top: -7 }}>
                                <ARMGridPager
                                    currentPageNumber={gridWONPaging.currentPageNumber}
                                    numberOfPages={gridWONPaging.numberOfPages}
                                    pagingHandler={this.handleWONPaging}
                                />
                            </div>
                        </ContentRowWrapper>
                    </ContentColumnWrapper>
                    <DialogFieldset id='WON-EditNote-Fieldset'>
                        <DialogLegend>Edit Note</DialogLegend>
                        <ContentRowWrapper>
                            <TextArea
                                domID="WON-AdditionalInfo"
                                label="Additional Information:"
                                initialValue={selectedAdditionalInfoForWON}
                                maxLength={500}
                                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => this.props.action.ui.updateWONAddInfoDesc(e.target.value)}
                                disabled={isWONEditNoteSectionDisabled}
                                hasError={additionalMsg_Validation_ErrorForWON && additionalMsg_Validation_ErrorForWON !== ""}
                            />
                            <SelectButtons>
                                <Button
                                    domID="WON-AddInfo"
                                    name="Add"
                                    buttonType="standard"
                                    size="small"
                                    type="button"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.AddWONEditNotes(e)}
                                    disabled={this.props.canEdit && isAddNoteEnabled}
                                />
                            </SelectButtons>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <SelectDropdownV2
                                domID="WON-CriteriaField"
                                className="dropdown"
                                size='small'
                                isClearable={false}
                                isSearchable={true}
                                onChange={(e: any) => this.onChangeCriteriaField(e)}
                                options={allWONCriteriaFields}
                                initialValue={this.getInitialValue(allWONCriteriaFields, selectedENCriteriaDVARFieldNameForWON)}
                                disabled={isWONEditNoteSectionDisabled}
                            />
                            <SelectDropdownV2
                                domID="WON-CriteriaExpression"
                                className="dropdown"
                                size='small'
                                isClearable={false}
                                isSearchable={true}
                                onChange={(e: any) => this.onChangeCriteriaExp(e.value)}
                                options={allWONExpressionList}
                                initialValue={this.getInitialExpressionValue(allWONExpressionList, selectedENCriteriaExpressionForWON)}
                                disabled={isWONCriteriaExpDisabled}
                            />
                            {
                                !isWONCriteriaExpDisabled ? (
                                    <Input
                                        domID="WON-CriteriaExpressionValue"
                                        className="text-input"
                                        maxLength={20}
                                        width='150px'
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { this.props.action.ui.updateWONCriteriaValue(e.target.value) }}
                                        initialValue={selectedENCriteriaExpressionValueForWON}
                                        hasError={criteriaValue_Validation_ErrorForWON && criteriaValue_Validation_ErrorForWON !== ""}
                                    />
                                ) : ""
                            }
                        </ContentRowWrapper>
                    </DialogFieldset>
                </ContentWrapper>
            </React.Fragment>);
    }

    public RemoveWNRecordsConfirm() {
        const { selectedWNotes, allWNotes } = this.state;
        const { dataSearch } = this.props.dataStore.crud;

        let selectedNotes: MCAEISaveNotes = {
            EditNotesMaintenanceInfo: {
                '@Action': "SaveErrorNotes",
                CriteriaList: { Criteria: [] },
                Errors: { Error: [] },
            }
        };

        if (selectedWNotes && selectedWNotes.length > 0) {
            selectedWNotes.forEach(note => {
                let error: MCRemoveError = {
                    '@ErrorClientAdditionalId': note['@ErrorClientAdditionalId'],
                    '@ID': note['@ErrorClientAdditionalId'],
                    '@Delete': 'true',
                }
                selectedNotes.EditNotesMaintenanceInfo.Errors.Error.push(error);
            });

            this.props.action.crud.update({
                crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
                data: selectedNotes
            });

            let filteredNotes: MCAEIWNError[] = allWNotes.filter(function (allNotesElement) {
                return selectedWNotes.filter(function (selectedElement) {
                    return selectedElement['@ErrorClientAdditionalId'] === allNotesElement['@ErrorClientAdditionalId'];
                }).length == 0
            });

            this.deselectAllRows();
            this.clearWNTab();
            this.ClearFilter();
            this.setState({ allWNotes: [...filteredNotes], selectedWNotes: [], editNoteCriterias: [] });
        }
    }

    public RemoveCriteriaConfirm() {
        let request: FilterRequest = this.buildWNRequest(false);

        this.props.action.crud.search({
            crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
            data: JSON.stringify(request)
        });

        const { selectedEditCriteria, selectedWNotes, editNoteCriterias } = this.state;

        let selectedCriteria: MCAEISaveNotes = {
            EditNotesMaintenanceInfo: {
                '@Action': "SaveErrorNotes",
                CriteriaList: { Criteria: [] },
                Errors: []
            }
        };

        if (selectedEditCriteria) {
            let criteria: MCRemoveCriteria = {
                '@ErrorClientAdditionalMessageId': selectedEditCriteria['@ErrorClientAdditionalMessageId'],
                '@Delete': 'true',
            }

            selectedCriteria.EditNotesMaintenanceInfo.CriteriaList.Criteria.push(criteria);

            this.props.action.crud.update({
                crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
                data: selectedCriteria
            });
            if (selectedWNotes) {

                let matchedNotes = selectedWNotes.find(swn => swn['@ErrorClientAdditionalId'] === selectedEditCriteria['@ErrorClientAdditionalId']);
                if (matchedNotes) {
                    let editNoteCriteriasRemove = editNoteCriterias.filter((item) => {
                        return item["@ErrorClientAdditionalMessageId"] === selectedEditCriteria['@ErrorClientAdditionalMessageId'];
                    });
                    if (editNoteCriteriasRemove !== undefined && editNoteCriteriasRemove && editNoteCriteriasRemove.length > 0) {

                        for (let i = 0; i < editNoteCriterias.length; i++) {
                            if (selectedEditCriteria !== undefined) {
                                if (editNoteCriterias[i]['@ErrorClientAdditionalMessageId'] === selectedEditCriteria['@ErrorClientAdditionalMessageId']) {
                                    editNoteCriterias.splice(i, 1);
                                }
                            }
                        }
                    }

                    this.setState({ editNoteCriterias });
                    this.ResetEditNoteCriteria();
                    if (editNoteCriterias && editNoteCriterias.length === 0) {
                        this.applyWNFilter(true);
                    }
                }
            }

            this.clearWNTab();
            this.ClearFilter();
        }

    }

    isWONCriteriaExpDisabled = (): boolean => {
        const { dataSearch } = this.props.dataStore.crud;
        return ((!dataSearch ||
            !dataSearch.ErrorsWithoutNotes ||
            !dataSearch.ErrorsWithoutNotes.ExpressionList ||
            !dataSearch.ErrorsWithoutNotes.ExpressionList.Expression) ||
            this.props.dataStore.ui.selectedENCriteriaFieldNameForWON.trim() === "" || this.props.dataStore.ui.selectedENCriteriaFieldNameForWON.trim() === "No Criteria");
    }

    isWNCriteriaExpDisabled = (): boolean => {
        const { dataSearch } = this.props.dataStore.crud;
        return ((!dataSearch ||
            !dataSearch.ErrorsWithNotes ||
            !dataSearch.ErrorsWithNotes.ExpressionList ||
            !dataSearch.ErrorsWithNotes.ExpressionList.Expression) ||
            this.props.dataStore.ui.selectedENCriteriaFieldNameForWN.trim() === "" || this.props.dataStore.ui.selectedENCriteriaFieldNameForWN.trim() === "No Criteria");
    }

    isWNAddEditNoteEnabled = (): boolean => {
        const { selectedENCriteriaDVARFieldNameForWN } = this.props.dataStore.ui;
        return this.props.canEdit && selectedENCriteriaDVARFieldNameForWN.trim() === "";
    }

    isWNRemoveEditNoteEnabled = (): boolean => {
        const { selectedENCriteriaDVARFieldNameForWN } = this.props.dataStore.ui;
        return this.props.canEdit && selectedENCriteriaDVARFieldNameForWN.trim() === "";
    }

    public onClickModifyButton(e: React.ChangeEvent<HTMLButtonElement>) {
        const { selectedWNotes, editNoteCriterias } = this.state;
        const { dataSearch } = this.props.dataStore.crud;
        this.setState({ isLoading: true });
        if (!dataSearch ||
            !dataSearch.ErrorsWithNotes ||
            !dataSearch.ErrorsWithNotes.Errors ||
            !dataSearch.ErrorsWithNotes.Errors.Error) return;

        const {
            selectedENCriteriaExpressionForWN,
            selectedENCriteriaDVARFieldNameForWN,
            selectedENCriteriaFieldNameForWN,
            selectedENCriteriaExpressionValueForWN,
            selectedAdditionalInfoForWN,
        } = this.props.dataStore.ui;

        let selectedNotes: MCAEISaveNotes = {
            EditNotesMaintenanceInfo: {
                '@Action': "SaveErrorNotes",
                CriteriaList: { Criteria: [] },
                Errors: []
            }
        };

        if (dataSearch) {
            switch (this.getModifyButtonType()) {
                case eModifyButtonType.Add: {

                    this.props.action.ui.addEditNoteCriteria({
                        masterCrud: dataSearch,
                        uiData: {
                            selectedWNotes: selectedWNotes,
                            editNoteCriterias: editNoteCriterias,
                            '@ErrorClientAdditionalId': '#' + (dataSearch.ErrorsWithNotes.Errors.Error.length),
                            '@CriteriaFieldName': selectedENCriteriaFieldNameForWN,
                            '@FieldName': selectedENCriteriaFieldNameForWN,
                            '@CriteriaValue': selectedENCriteriaExpressionValueForWN,
                            '@FieldDVARName': selectedENCriteriaDVARFieldNameForWN,
                            '@CriteriaExpression': selectedENCriteriaExpressionForWN,
                            '@AdditionalMsg': selectedAdditionalInfoForWN,
                        }
                    });

                    if (this.ValidateEditNoteIsPopulatedForWN(true)) {

                        selectedWNotes.forEach(wn => {
                            let criteria: MCAEICriteria = {
                                '@ErrorClientAdditionalId': wn['@ErrorClientAdditionalId'],
                                '@FieldName': selectedENCriteriaFieldNameForWN,
                                '@CriteriaData': selectedENCriteriaExpressionValueForWN,
                                '@FieldDVARName': selectedENCriteriaDVARFieldNameForWN,
                                '@Expression': selectedENCriteriaExpressionForWN,
                                '@AdditionalMsg': selectedAdditionalInfoForWN,
                                '@ErrorClientAdditionalMessageId': '',
                            }

                            selectedNotes.EditNotesMaintenanceInfo.CriteriaList.Criteria.push(criteria);
                        });

                        if (selectedNotes && selectedNotes.EditNotesMaintenanceInfo.CriteriaList.Criteria.length > 0) {
                            this.props.action.crud.update({
                                crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
                                data: selectedNotes
                            }, (result: unknown) => {
                                if (result) {
                                    this.props.action.crud.search({
                                        crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
                                        data: JSON.stringify(request)
                                    });
                                }
                            });
                            let request: FilterRequest = this.buildWNRequest(false);

                            setTimeout(async () => {

                                const { dataSearch } = this.props.dataStore.crud;
                                if (selectedWNotes) {
                                    selectedWNotes.forEach(swn => {

                                        let errorClientAdditionalId: string = swn['@ErrorClientAdditionalId'];
                                        let criterias: MCAEICriteria[] = [];

                                        if (dataSearch && dataSearch.ErrorsWithNotes &&
                                            dataSearch.ErrorsWithNotes.CriteriaList && dataSearch.ErrorsWithNotes.CriteriaList.Criteria) {
                                            let criteriaList = dataSearch.ErrorsWithNotes.CriteriaList.Criteria.filter(crt => crt['@ErrorClientAdditionalId'] === errorClientAdditionalId);

                                            criteriaList.forEach(ctr => {
                                                let newCriteria: MCAEICriteria = {
                                                    '@ErrorClientAdditionalId': ctr['@ErrorClientAdditionalId'],
                                                    '@CriteriaData': isEmpty(ctr['@FieldDVARName']) ? "No Criteria" : this.criteriaFieldName(ctr['@FieldDVARName']) + `-${ctr['@Expression'].split(" ").join("")}-${ctr['@CriteriaData']}`,
                                                    "@FieldName": ctr['@FieldName'],
                                                    "@FieldDVARName": ctr['@FieldDVARName'],
                                                    "@AdditionalMsg": ctr['@AdditionalMsg'],
                                                    "@ErrorClientAdditionalMessageId": ctr['@ErrorClientAdditionalMessageId'],
                                                    "@Expression": ctr['@Expression'].split(" ").join(""),
                                                };
                                                criterias.push(newCriteria);

                                            });

                                            this.setState({ editNoteCriterias: criterias });
                                        }
                                    });
                                }
                                this.setState({ isLoading: false });
                                this.ResetEditNoteCriteria();
                            }, 3000);
                        }
                        else {
                            this.setState({ isLoading: false });
                        }
                    }
                    else {
                        this.setState({ isLoading: false });
                    }
                }
                    break;
                default: {
                    const { selectedEditCriteria } = this.state;
                    if (selectedEditCriteria) {
                        this.props.action.ui.updateEditNoteCriteria({
                            masterCrud: dataSearch,
                            uiData: {
                                selectedWNotes: selectedWNotes,
                                editNoteCriterias: editNoteCriterias,
                                '@ErrorClientAdditionalId': selectedEditCriteria['@ErrorClientAdditionalId'],
                                '@CriteriaFieldName': selectedENCriteriaFieldNameForWN,
                                '@FieldName': selectedENCriteriaFieldNameForWN,
                                '@CriteriaValue': selectedENCriteriaExpressionValueForWN,
                                '@FieldDVARName': selectedENCriteriaDVARFieldNameForWN,
                                '@CriteriaExpression': selectedENCriteriaExpressionForWN,
                                '@AdditionalMsg': selectedAdditionalInfoForWN,
                                '@ErrorClientAdditionalMessageId': selectedEditCriteria['@ErrorClientAdditionalMessageId'],
                            }
                        });

                        if (this.ValidateEditNoteIsPopulatedForWN(false)) {
                            selectedWNotes.forEach(wn => {
                                let criteria: MCAEICriteria = {
                                    '@ErrorClientAdditionalId': wn['@ErrorClientAdditionalId'],
                                    '@FieldName': selectedENCriteriaFieldNameForWN,
                                    '@CriteriaData': selectedENCriteriaExpressionValueForWN,
                                    '@FieldDVARName': selectedENCriteriaDVARFieldNameForWN,
                                    '@Expression': selectedENCriteriaExpressionForWN,
                                    '@AdditionalMsg': selectedAdditionalInfoForWN,
                                    '@ErrorClientAdditionalMessageId': selectedEditCriteria['@ErrorClientAdditionalMessageId'],
                                }

                                selectedNotes.EditNotesMaintenanceInfo.CriteriaList.Criteria.push(criteria);
                            });

                            if (selectedNotes && selectedNotes.EditNotesMaintenanceInfo.CriteriaList.Criteria.length > 0) {
                                this.props.action.crud.update({
                                    crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
                                    data: selectedNotes
                                }, (result: unknown) => {
                                    if (result) {
                                        this.props.action.crud.search({
                                            crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
                                            data: JSON.stringify(request)
                                        });
                                    }
                                });
                                let request: FilterRequest = this.buildWNRequest(false);
                                setTimeout(async () => {
                                    const { dataSearch } = this.props.dataStore.crud;
                                    if (selectedWNotes) {
                                        selectedWNotes.forEach(swn => {

                                            let errorClientAdditionalId: string = swn['@ErrorClientAdditionalId'];
                                            let criterias: MCAEICriteria[] = [];

                                            if (dataSearch && dataSearch.ErrorsWithNotes &&
                                                dataSearch.ErrorsWithNotes.CriteriaList && dataSearch.ErrorsWithNotes.CriteriaList.Criteria) {
                                                if (Array.isArray(dataSearch.ErrorsWithNotes.CriteriaList.Criteria)) {
                                                    let criteriaList = dataSearch.ErrorsWithNotes.CriteriaList.Criteria.filter(crt => crt['@ErrorClientAdditionalId'] === errorClientAdditionalId);
                                                    criteriaList.forEach(ctr => {
                                                        let newCriteria: MCAEICriteria = {
                                                            '@ErrorClientAdditionalId': ctr['@ErrorClientAdditionalId'],
                                                            '@CriteriaData': isEmpty(ctr['@FieldDVARName']) ? "No Criteria" : this.criteriaFieldName(ctr['@FieldDVARName']) + `-${ctr['@Expression'].split(" ").join("")}-${ctr['@CriteriaData']}`,
                                                            "@FieldName": ctr['@FieldName'],
                                                            "@FieldDVARName": ctr['@FieldDVARName'],
                                                            "@AdditionalMsg": ctr['@AdditionalMsg'],
                                                            "@ErrorClientAdditionalMessageId": ctr['@ErrorClientAdditionalMessageId'],
                                                            "@Expression": ctr['@Expression'].split(" ").join(""),
                                                        };
                                                        criterias.push(newCriteria);
                                                    });
                                                } else {
                                                    let newCriteria: MCAEICriteria = {
                                                        '@ErrorClientAdditionalId': dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@ErrorClientAdditionalId'],
                                                        '@CriteriaData': isEmpty(dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@FieldDVARName']) ? "No Criteria" : this.criteriaFieldName(dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@FieldDVARName']) + `-${dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@Expression'].split(" ").join("")}-${dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@CriteriaData']}`,
                                                        "@FieldName": dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@FieldName'],
                                                        "@FieldDVARName": dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@FieldDVARName'],
                                                        "@AdditionalMsg": dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@AdditionalMsg'],
                                                        "@ErrorClientAdditionalMessageId": dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@ErrorClientAdditionalMessageId'],
                                                        "@Expression": dataSearch.ErrorsWithNotes.CriteriaList.Criteria['@Expression'].split(" ").join(""),
                                                    };
                                                    criterias.push(newCriteria);
                                                }

                                                let selectedENoteIndex: any;
                                                if (Array.isArray(dataSearch.ErrorsWithNotes.CriteriaList.Criteria)) {
                                                    selectedENoteIndex = dataSearch.ErrorsWithNotes.CriteriaList.Criteria.findIndex(crt =>
                                                        crt['@ErrorClientAdditionalId'] === errorClientAdditionalId &&
                                                        crt['@ErrorClientAdditionalMessageId'] === selectedEditCriteria['@ErrorClientAdditionalMessageId']);
                                                }

                                                let criteria: MCAEICriteria = Array.isArray(dataSearch.ErrorsWithNotes.CriteriaList.Criteria) ? dataSearch.ErrorsWithNotes.CriteriaList.Criteria[selectedENoteIndex] : dataSearch.ErrorsWithNotes.CriteriaList.Criteria;
                                                let criteriaExp: IDropDownItem = { label: selectedEditCriteria['@Expression'], value: selectedEditCriteria['@Expression'] }
                                                let criteriaValue: string = selectedEditCriteria['@CriteriaData'];
                                                let additionalMsg: string = selectedEditCriteria['@AdditionalMsg'];

                                                if (criteria) {
                                                    criteriaExp = { label: criteria['@Expression'], value: criteria['@Expression'] }
                                                    criteriaValue = criteria['@CriteriaData'];
                                                    additionalMsg = criteria['@AdditionalMsg'];
                                                }
                                                swn.AdditionalMsg = additionalMsg;

                                                this.setState({
                                                    editNoteCriterias: criterias,
                                                });

                                                this.props.action.ui.selectedWNCriteriaExp({
                                                    masterCrud: this.props.dataStore.crud.dataSearch,
                                                    uiData: {
                                                        value: criteria['@Expression'],
                                                    }
                                                });
                                                this.props.action.ui.updateWNCriteriaValue(criteriaValue);
                                                this.props.action.ui.updateWNAddInfoDesc(additionalMsg);
                                            }
                                        });
                                    }

                                    this.setState({ isLoading: false });
                                }, 3000);
                            }
                            else {
                                this.setState({ isLoading: false });
                            }
                        }
                        else {
                            this.setState({ isLoading: false });
                        }
                    }
                }
                    break;
            }
        }
    }

    isWNAddNoteEnabled = (): boolean => {
        const { selectedENCriteriaFieldNameForWN } = this.props.dataStore.ui;
        return selectedENCriteriaFieldNameForWN.trim() === "";
    }

    private criteriaDVarFieldName(fieldName: string): string {
        let dVarFieldName: string = "";

        if (!isEmpty(fieldName)) {
            const { dataSearch } = this.props.dataStore.crud;

            if (dataSearch && dataSearch.ErrorsWithNotes &&
                dataSearch.ErrorsWithNotes.CriteriaFieldList && dataSearch.ErrorsWithNotes.CriteriaFieldList?.CriteriaField) {

                let index: number = dataSearch.ErrorsWithNotes.CriteriaFieldList?.CriteriaField.findIndex(cfld => cfld['@FieldName'] === fieldName);
                if (index !== -1) {
                    dVarFieldName = dataSearch.ErrorsWithNotes.CriteriaFieldList?.CriteriaField[index]['@DVARName'];
                }

            }
        }
        return dVarFieldName;
    }

    private criteriaFieldName(dVarFieldName: string): string {

        let criteriaFieldName: string = "";

        if (!isEmpty(dVarFieldName)) {
            const { dataSearch } = this.props.dataStore.crud;

            if (dataSearch && dataSearch.ErrorsWithNotes &&
                dataSearch.ErrorsWithNotes.CriteriaFieldList && dataSearch.ErrorsWithNotes.CriteriaFieldList?.CriteriaField) {

                let index: number = dataSearch.ErrorsWithNotes.CriteriaFieldList?.CriteriaField.findIndex(cfld => cfld['@DVARName'] === dVarFieldName);
                if (index !== -1) {
                    criteriaFieldName = dataSearch.ErrorsWithNotes.CriteriaFieldList?.CriteriaField[index]['@FieldName'];
                }

            }
        }
        return criteriaFieldName;
    }

    public onSelectWNotes(e: any, record: MCAEIWNError) {

        const {
            selectedWNotes,
        } = this.state;

        this.setState({ editNoteCriterias: [] });

        this.props.dataStore.ui.additionalMsg_Validation_ErrorForWN = '';
        this.props.dataStore.ui.criteriaField_Validation_ErrorForWN = '';
        this.props.dataStore.ui.criteriaExpression_Validation_ErrorForWN = '';
        this.props.dataStore.ui.criteriaValue_Validation_ErrorForWN = '';
        this.props.dataStore.ui.onlyOneNoCriteriaFieldAllowed_ForWN = '';
        this.props.dataStore.ui.errorWithNote_Validation_ErrorForWN = '';

        if (record) {
            if (e.currentTarget.className === 'selected') {
                e.currentTarget.className = '';
                const id = record['@ID'];
                const index = selectedWNotes.findIndex(selNote => selNote['@ID'] === id);
                if (index > -1) {
                    selectedWNotes.splice(index, 1);
                }
            } else {
                e.currentTarget.className = 'selected';
                selectedWNotes.push(record);
            }
        }

        if (selectedWNotes && selectedWNotes.length > 0) {
            this.errorWNValidationMessage("");
        }

        if (selectedWNotes && selectedWNotes.length === 0 || selectedWNotes.length > 1) {
            this.props.action.ui.selectedWNCriteriaField({
                masterCrud: this.props.dataStore.crud.dataSearch,
                uiData: {
                    text: '',
                    value: '',
                }
            });
            this.onChangeCriteriaExp('');
            this.props.action.ui.updateWNCriteriaValue('');
            this.props.action.ui.updateWNAddInfoDesc('');
            this.ResetEditNoteCriteria();
        }

        const { dataSearch } = this.props.dataStore.crud;

        if (selectedWNotes.length === 1) {
            let selectedNote = selectedWNotes[0];
            if (selectedNote) {
                let errorClientAdditionalId: string = selectedNote['@ErrorClientAdditionalId'];
                let criterias: MCAEICriteria[] = [];
                if (dataSearch && dataSearch.ErrorsWithNotes &&
                    dataSearch.ErrorsWithNotes.CriteriaList &&
                    dataSearch.ErrorsWithNotes.CriteriaList.Criteria) {
                    if (Array.isArray(dataSearch.ErrorsWithNotes.CriteriaList.Criteria)) {
                        let criteriaList = dataSearch.ErrorsWithNotes.CriteriaList.Criteria.filter(crt => crt['@ErrorClientAdditionalId'] === errorClientAdditionalId);
                        let hasNoCriteria: boolean = false;
                        criteriaList.forEach(ctr => {
                            let newCriteria: MCAEICriteria = {
                                '@ErrorClientAdditionalId': ctr['@ErrorClientAdditionalId'],
                                '@CriteriaData': isEmpty(ctr['@FieldDVARName']) ? "No Criteria" : this.criteriaFieldName(ctr['@FieldDVARName']) + `-${ctr['@Expression'].split(" ").join("")}-${ctr['@CriteriaData']}`,
                                "@FieldName": ctr['@FieldName'],
                                "@FieldDVARName": ctr['@FieldDVARName'],
                                "@AdditionalMsg": ctr['@AdditionalMsg'],
                                "@ErrorClientAdditionalMessageId": ctr['@ErrorClientAdditionalMessageId'],
                                "@Expression": ctr['@Expression'].split(" ").join(""),
                            };

                            if (!isEmpty(newCriteria['@FieldDVARName']) || !hasNoCriteria) {
                                criterias.push(newCriteria);
                                hasNoCriteria = true;
                            }
                        });
                    } else {
                        let criteria = dataSearch.ErrorsWithNotes.CriteriaList.Criteria;
                        let hasNoCriteria: boolean = false;
                        let newCriteria: MCAEICriteria = {
                            '@ErrorClientAdditionalId': criteria['@ErrorClientAdditionalId'],
                            '@CriteriaData': isEmpty(criteria['@FieldDVARName']) ? "No Criteria" : criteria['@FieldDVARName'],
                            "@FieldName": criteria['@FieldName'],
                            "@FieldDVARName": criteria['@FieldDVARName'],
                            "@AdditionalMsg": criteria['@AdditionalMsg'],
                            "@ErrorClientAdditionalMessageId": criteria['@ErrorClientAdditionalMessageId'],
                            "@Expression": criteria['@Expression'],
                        };

                        if (!isEmpty(newCriteria['@FieldDVARName']) || !hasNoCriteria) {
                            criterias.push(newCriteria);
                            hasNoCriteria = true;
                        }
                    }

                    this.setState({ editNoteCriterias: [...criterias] });
                }
            }
        }
    }

    WNTab = () => {
        let criterias: MCAEICriteria[] = [];
        const { selectedFilterFormForWN,
            selectedFilterPayerForWN, selectedFilterFieldForWN, selectedFilterEditForWN, additionalMsg_Validation_ErrorForWN,
            selectedAdditionalInfoForWN, selectedENCriteriaExpressionForWN, selectedENCriteriaExpressionValueForWN,
            criteriaValue_Validation_ErrorForWN, selectedENCriteriaDVARFieldNameForWN, selectedENCriteriaFieldNameForWN,
        } = this.props.dataStore.ui;

        const { allWNotes, allWNCriteriaFields, allWNExpressionList, selectedWNotes, editNoteCriterias, gridWNPaging } = this.state;

        let isWNCriteriaExpEnabled: boolean = this.isWNCriteriaExpDisabled();
        let isWNAddEditNoteEnabled: boolean = this.isWNAddEditNoteEnabled();
        let isWNRemoveEditNoteEnabled: boolean = this.isWNRemoveEditNoteEnabled();
        let isWNEditNoteSectionDisabled: boolean = selectedWNotes.length <= 0;
        if (allWNotes.length > 0) {
            criterias = [
                {
                    '@ErrorClientAdditionalMessageId': '',
                    '@ErrorClientAdditionalId': '',
                    '@AdditionalMsg': '',
                    "@FieldName": '',
                    '@FieldDVARName': '',
                    '@CriteriaData': '-- Add New Criteria --',
                    '@Expression': 'INLIST'

                }
            ];
            editNoteCriterias.forEach((crt: any) => {
                if (crt !== undefined && !crt.hasOwnProperty('@Delete')) {
                    criterias.push(crt);
                }
                else if (crt['@Delete'] !== 'true') {
                    criterias.push(crt);
                }
            });
        }

        return (
            <React.Fragment>
                <ContentWrapper>
                    <ContentColumnWrapper>
                        <ContentRowWrapper>
                            <SelectDropdownV2
                                domID="WN-FormFilter"
                                className="dropdown"
                                size='small'
                                isClearable={false}
                                isSearchable={true}
                                onChange={(e: any) => this.onChangeFilterForm(e.value)}
                                options={this.getFormList()}
                                initialValue={this.getInitialValue(this.getFormList(), selectedFilterFormForWN)}
                                disabled={false}
                            />
                            <Input
                                domID="WN-PayerFilter"
                                className="text-input"
                                maxLength={20}
                                width='150px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateWNPayerFilter(e.target.value.toUpperCase())}
                                initialValue={selectedFilterPayerForWN}
                            />
                            <Input
                                domID="WN-FieldFilter"
                                className="text-input"
                                maxLength={20}
                                width='150px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateWNFieldFilter(e.target.value.toUpperCase())}
                                initialValue={selectedFilterFieldForWN}
                            />
                            <Input
                                domID="WN-EditNameFilter"
                                className="text-input"
                                maxLength={10}
                                width='125px'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateWNEditFilter(e.target.value.toUpperCase())}
                                initialValue={selectedFilterEditForWN}
                            />
                            <React.Fragment>
                                <span>
                                    <IMG src='/shared/images/Icons/RefreshArrow.png' onClick={() => this.applyWNFilter(true)} />
                                </span>
                                <Button
                                    domID="WN-Filter-Link"
                                    name="Filter"
                                    buttonType="link"
                                    size="small"
                                    type="link"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.applyWNFilter(true)}>
                                </Button>
                            </React.Fragment>
                            <Button
                                domID="WN-Clear-Link"
                                name="Clear"
                                buttonType="link"
                                size="small"
                                type="link"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.ClearFilter()}>
                            </Button>
                        </ContentRowWrapper>
                    </ContentColumnWrapper>
                    <ContentColumnWrapper>
                        <ContentColumnWrapper>
                            <GridWrapperWNotes>
                                <GridWrapper>
                                    <Grid
                                        domID="WN-Grid"
                                        isFixedHeader={true}
                                        maxHeight='385px'
                                        columns={this.WNGridColumns()}
                                        records={allWNotes}
                                        onClickThrough={(e: any, record: MCAEIWNError) => { this.onSelectWNotes(e, record) }}
                                        selectionKey='FormTypeForWN'
                                    />
                                </GridWrapper>
                            </GridWrapperWNotes>
                        </ContentColumnWrapper>
                        <ContentRowWrapper>
                            <ContentColumnWrapper>
                                <ContentRowWrapper>
                                    <React.Fragment>
                                        <Button
                                            domID="remove-error"
                                            name="Remove"
                                            buttonType="standard"
                                            size="small"
                                            type="standard"
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveWNRecords(e)}
                                            disabled={isWNEditNoteSectionDisabled}
                                        />
                                    </React.Fragment>
                                    <div style={{ marginTop: 5, flexGrow: 1, textAlign: 'right', position: 'relative', left: 20, top: -7 }}>
                                        <ARMGridPager
                                            currentPageNumber={gridWNPaging.currentPageNumber}
                                            numberOfPages={gridWNPaging.numberOfPages}
                                            pagingHandler={this.handleWNPaging}
                                        />
                                    </div>
                                </ContentRowWrapper>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                    </ContentColumnWrapper>
                    <ContentColumnWrapper>
                        <DialogFieldset id='WN-EditNote-Fieldset'>
                            <DialogLegend>Edit Note</DialogLegend>
                            <ContentRowWrapper>
                                <SelectComponent
                                    title="NoteCriterias"
                                    size={6}
                                    width="400px"
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectEditNoteCriteria(e)}
                                    optionFields={{
                                        value: "@ErrorClientAdditionalMessageId",
                                        text: "@CriteriaData"
                                    }}
                                    records={criterias}
                                    selectedValue={this.props?.dataStore?.ui?.selectedEditNote !== undefined ? this.props?.dataStore?.ui?.selectedEditNote.value : ''}
                                    disabled={isWNEditNoteSectionDisabled}
                                >
                                </SelectComponent>
                                <TextArea
                                    domID="WN-AdditionalInfoDesc"
                                    label="&nbsp;&nbsp;Additional Information:"
                                    initialValue={selectedAdditionalInfoForWN}
                                    maxLength={500}
                                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => { this.props.action.ui.updateWNAddInfoDesc(e.target.value) }}
                                    hasError={!isEmpty(additionalMsg_Validation_ErrorForWN)}
                                    disabled={isWNEditNoteSectionDisabled}
                                />
                                <SelectButtons>
                                    <Button
                                        domID="WN-AddInfo"
                                        name={this.props?.dataStore?.ui?.selectedEditNote?.value?.length > 0 ? 'Update' : 'Add'}
                                        size="small"
                                        buttonType="standard"
                                        disabled={isWNAddEditNoteEnabled}
                                        type="button"
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                            this.onClickModifyButton(e)
                                        }}
                                    />
                                    <Button
                                        domID="WN-RemoveInfo"
                                        name="Remove"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { this.RemoveNoteCriteria(e) }}
                                        disabled={isWNRemoveEditNoteEnabled}
                                    />
                                </SelectButtons>
                            </ContentRowWrapper>
                            <ContentRowWrapper>
                                <SelectDropdownV2
                                    domID="WN-CriteriaField"
                                    className="dropdown"
                                    size='small'
                                    isClearable={false}
                                    isSearchable={true}
                                    onChange={(e: any) => this.onChangeCriteriaField(e)}
                                    options={allWNCriteriaFields}
                                    initialValue={this.getInitialValue(allWNCriteriaFields, selectedENCriteriaDVARFieldNameForWN)}
                                    disabled={this.state.isCriteriaFieldDisabled}
                                />
                                <SelectDropdownV2
                                    domID="WN-CriteriaExpression"
                                    className="dropdown"
                                    size='small'
                                    isClearable={false}
                                    isSearchable={true}
                                    onChange={(e: any) => this.onChangeCriteriaExp(e.value)}
                                    options={allWNExpressionList}
                                    initialValue={this.getInitialExpressionValue(allWNExpressionList, selectedENCriteriaExpressionForWN)}
                                    disabled={this.props.canEdit && isWNCriteriaExpEnabled}
                                />
                                {
                                    !isWNCriteriaExpEnabled ? (
                                        <Input
                                            domID="WN-CriteriaExpressionValue"
                                            className="text-input"
                                            maxLength={20}
                                            width='150px'
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { this.props.action.ui.updateWNCriteriaValue(e.target.value) }}
                                            initialValue={selectedENCriteriaExpressionValueForWN}
                                            hasError={!isEmpty(criteriaValue_Validation_ErrorForWN)}
                                        />
                                    ) : ""
                                }
                            </ContentRowWrapper>
                        </DialogFieldset>
                    </ContentColumnWrapper>
                </ContentWrapper>
            </React.Fragment>);
    }

    private ResetWNTab() {
        this.setState({
            selectedWNotes: [],
            editNoteCriterias: [],
            selectedEditCriteria: {
                "@CriteriaData": '',
                "@ErrorClientAdditionalMessageId": '',
                "@ErrorClientAdditionalId": '',
                "@Expression": '',
                "@FieldDVARName": '',
                "@AdditionalMsg": '',
                "@FieldName": ''
            },
            selectedWNCriteriaExp: { value: 'INLIST', label: 'INLIST' },
            selectedWNCriteriaField: { value: '', label: '' },
            selectedWNCriteriaValue: '',
            selectedWNAdditionalInfo: '',
        });
    }

    public onSelectTab(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) {
        e.preventDefault();
        this.ResetWNTab();
        this.props.action.ui.selectTabIndex(selectInfo.tabIndex);
    }

    // Form Methods
    public onChangeFilterForm(form: string) {
        const { selectedTabIndex } = this.props.dataStore.ui;

        switch (selectedTabIndex) {
            case 1:
                this.props.action.ui.updateWNFormFilter({
                    masterCrud: this.props.dataStore.crud.dataSearch,
                    uiData: { value: form }
                });
                break;
            case 0:
            default:
                this.props.action.ui.updateWONFormFilter({
                    masterCrud: this.props.dataStore.crud.dataSearch,
                    uiData: { value: form }
                });
                break;
        }
    }

    public getFormList() {
        return [{ value: '', label: '' }, { value: '1011', label: 'UB' }, { value: '1001', label: '1500' }];
    }

    selectedCriteriaField = (idToFind: string): IDropDownItem => {
        const { dataSearch } = this.props.dataStore.crud;
        const { selectedTabIndex } = this.props.dataStore.ui;

        let result: IDropDownItem = { value: '', label: '' };

        if (!isEmpty(idToFind)) {
            switch (selectedTabIndex) {
                case 1: {
                    let isCriteriaFieldLoadedForWN = (dataSearch && dataSearch.ErrorsWithNotes &&
                        dataSearch.ErrorsWithNotes.CriteriaFieldList && dataSearch.ErrorsWithNotes.CriteriaFieldList.CriteriaField) ? true : false;

                    if (isCriteriaFieldLoadedForWN) {

                        let index = dataSearch?.ErrorsWithNotes.CriteriaFieldList?.CriteriaField.findIndex(cfl => cfl['@FieldName'] === idToFind);

                        if (index && index > -1) {
                            if (dataSearch?.ErrorsWithNotes.CriteriaFieldList?.CriteriaField[index]) {
                                result.label = dataSearch?.ErrorsWithNotes.CriteriaFieldList?.CriteriaField[index]['@DVARName'];
                                result.value = dataSearch?.ErrorsWithNotes.CriteriaFieldList?.CriteriaField[index]['@FieldName'];
                            }
                        }
                    }
                }
                    break;
                case 0:
                default: {
                    let isCriteriaFieldLoadedForWON = (dataSearch && dataSearch.ErrorsWithoutNotes &&
                        dataSearch.ErrorsWithoutNotes.CriteriaFieldList && dataSearch.ErrorsWithoutNotes.CriteriaFieldList.CriteriaField) ? true : false;

                    if (isCriteriaFieldLoadedForWON) {

                        let index = dataSearch?.ErrorsWithoutNotes.CriteriaFieldList?.CriteriaField.findIndex(cfl => cfl['@FieldName'] === idToFind);

                        if (index && index > -1) {
                            if (dataSearch?.ErrorsWithoutNotes.CriteriaFieldList?.CriteriaField[index]) {
                                result.label = dataSearch?.ErrorsWithoutNotes.CriteriaFieldList?.CriteriaField[index]['@DVARName'];
                                result.value = dataSearch?.ErrorsWithoutNotes.CriteriaFieldList?.CriteriaField[index]['@FieldName'];
                            }
                        }
                    }
                }
                    break;
            }
        }
        return result;
    }

    selectedCriteriaExpression = (idToFind: string): IDropDownItem => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        const { dataSearch } = this.props.dataStore.crud;
        let result: IDropDownItem = { value: '', label: '' };

        if (!isEmpty(idToFind)) {
            switch (selectedTabIndex) {
                case 1: {
                    let isCriteriaExpressionLoadedForWN = (dataSearch && dataSearch.ErrorsWithNotes &&
                        dataSearch.ErrorsWithNotes.ExpressionList && dataSearch.ErrorsWithNotes.ExpressionList.Expression) ? true : false;

                    if (isCriteriaExpressionLoadedForWN) {

                        let index = dataSearch?.ErrorsWithNotes.ExpressionList?.Expression.findIndex(exp => exp['@Expression'] === idToFind);

                        if (index && index > -1) {
                            if (dataSearch?.ErrorsWithNotes.ExpressionList?.Expression[index]) {
                                result.label = dataSearch?.ErrorsWithNotes.ExpressionList?.Expression[index]['@Expression'];
                                result.value = dataSearch?.ErrorsWithNotes.ExpressionList?.Expression[index]['@Expression'].split(" ").join("");
                            }
                        }
                    }
                }
                    break;
                case 0:
                default: {
                    let isCriteriaExpressionLoadedForWON = (dataSearch && dataSearch.ErrorsWithoutNotes &&
                        dataSearch.ErrorsWithoutNotes.ExpressionList && dataSearch.ErrorsWithoutNotes.ExpressionList.Expression) ? true : false;

                    if (isCriteriaExpressionLoadedForWON) {

                        let index = dataSearch?.ErrorsWithoutNotes.ExpressionList?.Expression.findIndex(exp => exp['@Expression'] === idToFind);

                        if (index && index > -1) {
                            if (dataSearch?.ErrorsWithoutNotes.ExpressionList?.Expression[index]) {
                                result.label = dataSearch?.ErrorsWithoutNotes.ExpressionList?.Expression[index]['@Expression'];
                                result.value = dataSearch?.ErrorsWithoutNotes.ExpressionList?.Expression[index]['@Expression'].split(" ").join("");
                            }
                        }
                    }
                }
                    break;
            }
        }

        return result;
    }

    public onChangeCriteriaField(field: any) {
        const { selectedTabIndex } = this.props.dataStore.ui;

        const { dataSearch } = this.props.dataStore.crud;

        switch (selectedTabIndex) {
            case 1: {
                this.props.action.ui.selectedWNCriteriaField({
                    masterCrud: dataSearch,
                    uiData: {
                        text: field.label,
                        value: field.value,
                    }
                });
                this.isWNAddNoteEnabled();
                this.isWNCriteriaExpDisabled();
            }
                break;
            case 0:
            default: {
                this.props.action.ui.selectedWONCriteriaField({
                    masterCrud: dataSearch,
                    uiData: {
                        text: field.label,
                        value: field.value,
                    }
                });
                this.isWONAddNoteEnabled();
                this.isWONCriteriaExpDisabled();
            }
                break;
        }
    }

    onChangeCriteriaExp(criteriaExp: string) {
        const { selectedTabIndex } = this.props.dataStore.ui;
        const { dataSearch } = this.props.dataStore.crud;
        switch (selectedTabIndex) {
            case 1: {
                this.props.action.ui.selectedWNCriteriaExp({
                    masterCrud: dataSearch,
                    uiData: {
                        value: criteriaExp,
                    }
                });
            }
                break;
            case 0:
            default: {
                this.props.action.ui.selectedWONCriteriaExp({
                    masterCrud: dataSearch,
                    uiData: {
                        value: criteriaExp,
                    }
                });
            }
                break;
        }
    }

    ClearFilter = () => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        const { dataSearch } = this.props.dataStore.crud;
        switch (selectedTabIndex) {
            case 1: {
                this.props.action.ui.clearWNFilters({
                    masterCrud: dataSearch,
                    uiData: {
                        selectedFilterFormForWN: '',
                        selectedFilterPayerForWN: '',
                        selectedFilterFieldForWN: '',
                        selectedFilterEditForWN: '',
                    }
                });
            }
                break;
            case 0:
            default: {
                this.props.action.ui.clearWONFilters({
                    masterCrud: dataSearch,
                    uiData: {
                        selectedFilterFormForWON: '',
                        selectedFilterPayerForWON: '',
                        selectedFilterFieldForWON: '',
                        selectedFilterEditForWON: '',
                    }
                });
            }
                break;
        }
    }

    ValidateEditNoteIsPopulatedForWON = (): boolean => {
        var retVal = false;
        const { selectedWONotes } = this.state;
        const {
            selectedAdditionalInfoForWON,
            selectedENCriteriaFieldNameForWON,
            selectedENCriteriaExpressionValueForWON,
            selectedFilterFieldForWON,
            selectedFilterEditForWON,
        } = this.props.dataStore.ui;

        //we need additional information populated
        if (selectedAdditionalInfoForWON && selectedAdditionalInfoForWON.trim().length > 0) {
            retVal = true;
        } else retVal = false;
        if (!retVal) return retVal;

        //we need either one grid item selected or the filter boxes for field or edit name to be populated for rollup 
        if (selectedWONotes.length === 0 && selectedFilterEditForWON.length === 0 && selectedFilterFieldForWON.length === 0) {
            retVal = false;
        } else retVal = true;

        if (!retVal) return retVal;

        //we need a criteria dropdown item to be selected
        if (selectedENCriteriaFieldNameForWON && selectedENCriteriaFieldNameForWON.trim().length > 0)
            retVal = true;
        else retVal = false;

        if (!retVal) return retVal;

        //when something other than NoCriteria is selected, we need a value for the criteria text
        if (selectedENCriteriaFieldNameForWON.trim() != "No Criteria")
            retVal = selectedENCriteriaExpressionValueForWON.trim().length > 0
        return retVal;
    }

    ValidateEditNoteIsPopulatedForWN = (isAdd: boolean): boolean => {
        var retVal = false;
        const { selectedWNotes, editNoteCriterias } = this.state;
        const {
            selectedAdditionalInfoForWN,
            selectedENCriteriaFieldNameForWN,
            selectedENCriteriaExpressionForWN,
            selectedENCriteriaExpressionValueForWN,
            selectedFilterFieldForWN,
            selectedFilterEditForWN,
        } = this.props.dataStore.ui;

        //we need additional information populated
        retVal = (selectedAdditionalInfoForWN && selectedAdditionalInfoForWN.trim().length > 0) ? true : false;
        if (!retVal) return retVal;

        //we need either one grid item selected or the filter boxes for field or edit name to be populated for rollup 
        retVal = (selectedWNotes.length === 0 && selectedFilterEditForWN.length === 0 && selectedFilterFieldForWN.length === 0) ? false : true;
        if (!retVal) return retVal;

        //we need a criteria dropdown item to be selected
        retVal = (selectedENCriteriaFieldNameForWN && selectedENCriteriaFieldNameForWN.trim().length > 0) ? true : false;
        if (!retVal) return retVal;

        let noteId: string = selectedWNotes[0]['@ErrorClientAdditionalId'];

        // Check SelectedENCriteriaFieldName is No Criteira 
        if (noteId && isAdd) {
            if (selectedENCriteriaFieldNameForWN.trim() === "No Criteria") {

                if (editNoteCriterias && editNoteCriterias.length > 0) {
                    let noCriteriaIndex = editNoteCriterias.findIndex(crt => crt['@ErrorClientAdditionalId'] === noteId &&
                        crt['@CriteriaData'] === selectedENCriteriaFieldNameForWN);

                    return noCriteriaIndex === -1 ? true : false;
                }
            }
        }

        //when something other than NoCriteria is selected, we need a value for the criteria text
        retVal = (selectedENCriteriaFieldNameForWN.trim() != "No Criteria") ? selectedENCriteriaExpressionValueForWN.trim().length > 0 : (selectedENCriteriaFieldNameForWN.trim() === "No Criteria") ? true : false;
        if (!retVal) return retVal;

        if (noteId && isAdd) {

            if (editNoteCriterias && editNoteCriterias.length > 0) {

                let criteriaIndex = editNoteCriterias.findIndex(crt => crt['@ErrorClientAdditionalId'] === noteId &&
                    crt['@FieldDVARName'] === this.criteriaDVarFieldName(selectedENCriteriaFieldNameForWN) &&
                    crt['@Expression'] === selectedENCriteriaExpressionForWN &&
                    crt['@CriteriaData'].indexOf('-') > 0 ? crt['@CriteriaData'].split('-')[2] === selectedENCriteriaExpressionValueForWN : "");

                return criteriaIndex === -1 ? true : false;
            }
        }

        return retVal;
    }

    AddWONEditNotes = async (e: any) => {
        //e.preventDefault();
        const { allWONotes, selectedWONotes } = this.state;
        const { dataSearch } = this.props.dataStore.crud;

        const {
            selectedAdditionalInfoForWON,
            selectedENCriteriaFieldNameForWON,
            selectedENCriteriaDVARFieldNameForWON,
            selectedENCriteriaExpressionForWON,
            selectedENCriteriaExpressionValueForWON,
            selectedFilterEditForWON,
            selectedFilterFieldForWON,
        } = this.props.dataStore.ui;

        this.props.action.ui.addWONEditNote({
            masterCrud: dataSearch,
            uiData: {
                selectedFilterEditForWON: selectedFilterEditForWON,
                selectedFilterFieldForWON: selectedFilterFieldForWON,
                selectedWONotes: selectedWONotes,
                '@AdditionalInfo': selectedAdditionalInfoForWON,
                '@CriteriaFieldName': selectedENCriteriaFieldNameForWON,
                '@CriteriaDVARFieldName': selectedENCriteriaDVARFieldNameForWON,
                '@CriteriaExpression': selectedENCriteriaExpressionForWON,
                '@CriteriaValue': selectedENCriteriaExpressionValueForWON,
            }
        });

        let selectedNotes: MCAEISaveNotes = {
            EditNotesMaintenanceInfo: {
                '@Action': "SaveErrorNotes",
                CriteriaList: { Criteria: [] },
                Errors: []
            }
        };

        let idxCount: number = 0;
        //rollup rule - nothing selected from grid but something in field or edit box
        if (this.ValidateEditNoteIsPopulatedForWON()) {
            if (selectedWONotes && selectedWONotes.length === 0) {
                if (selectedFilterEditForWON.length == 0) {
                    this.errorWONValidationMessage('EditName or (EditName and FieldName) is required for rollup.');
                } else {
                    this.props.action.ui.verifyEditField(selectedFilterEditForWON, selectedFilterFieldForWON);
                }
            } else {
                //non-rollup regular rule
                selectedWONotes.forEach(selNote => {
                    idxCount += 1;

                    let newError: MCAEINewError = {
                        '@ErrorClientAdditionalId': '#' + idxCount,
                        '@DataForm': selNote['@DataForm'],
                        '@FieldId': selNote['@FieldId'],
                        '@EditName': selNote['@EditName'],
                        '@PayerName': selNote['@PayerName'],
                        '@MsgLev1': selNote['@MsgLev1'],
                        '@FieldName': selNote['@FieldName'],
                        '@AdditionalInfo': selectedAdditionalInfoForWON,
                        '@ErrCode': selNote['@ErrCode'] ? selNote['@ErrCode'] : "",
                        '@CPTGroupId': selNote['@CPTGroupId'] ? selNote['@CPTGroupId'] : "",
                        '@ID': '#' + idxCount,
                        '@CriteriaFieldName': selectedENCriteriaFieldNameForWON,
                        '@CriteriaFieldDVARName': selectedENCriteriaDVARFieldNameForWON,
                        '@CriteriaExpression': selectedENCriteriaExpressionForWON,
                        '@CriteriaData': selectedENCriteriaExpressionValueForWON,
                    };

                    let saveNewError: MCAEISaveNewError = {
                        NewError: newError,
                    }

                    selectedNotes.EditNotesMaintenanceInfo.Errors.push(saveNewError);

                });

                this.props.action.crud.update({
                    crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
                    data: selectedNotes
                });

                //remove the notes that were successfully processed.
                let filteredNotes: MCAEIWONError[] = [];
                filteredNotes = allWONotes.filter(function (allNotesElement) {
                    return selectedWONotes.filter(function (selectedElement) {
                        return selectedElement["@Idx"] === allNotesElement["@Idx"];
                    }).length == 0
                });
                this.deselectAllRows();
                this.clearWONTab();
                this.ClearFilter();
                this.setState({ allWONotes: [...filteredNotes], selectedWONotes: [] });
            }
        }
    }

    onRemoveWNRecords = async (e: any) => {
        const { selectedWNotes } = this.state;
        const { dataSearch } = this.props.dataStore.crud;

        let selectedNotes: MCAEISaveNotes = {
            EditNotesMaintenanceInfo: {
                '@Action': "SaveErrorNotes",
                CriteriaList: { Criteria: [] },
                Errors: { Error: [] }
            }
        };

        if (selectedWNotes && selectedWNotes.length > 0) {
            selectedWNotes.forEach(note => {
                let error: MCRemoveError = {
                    '@ErrorClientAdditionalId': note['@ErrorClientAdditionalId'],
                    '@ID': note['@ErrorClientAdditionalId'],
                    '@Delete': 'true',
                }
                selectedNotes.EditNotesMaintenanceInfo.Errors.Error.push(error);
            });

            this.props.action.ui.removeErrors({
                masterCrud: dataSearch,
                uiData: {
                    selectedWNotes: selectedWNotes,
                }
            });
        }

        this.ResetEditNoteCriteria();
    }

    createRollUpRule() {
        const {
            selectedAdditionalInfoForWON,
            selectedENCriteriaFieldNameForWON,
            selectedENCriteriaDVARFieldNameForWON,
            selectedENCriteriaExpressionForWON,
            selectedENCriteriaExpressionValueForWON,
            selectedFilterEditForWON,
            selectedFilterFieldForWON,
            fieldIdForRollUpRule
        } = this.props.dataStore.ui;

        let selectedNotes: MCAEISaveNotes = {
            EditNotesMaintenanceInfo: {
                '@Action': "SaveErrorNotes",
                CriteriaList: { Criteria: [] },
                Errors: []
            }
        };
        let fieldId: string = fieldIdForRollUpRule === undefined ? "" : fieldIdForRollUpRule;
        let newError: MCAEINewError = {
            '@ErrorClientAdditionalId': '#' + 0,
            '@DataForm': "",
            '@FieldId': fieldId,
            '@EditName': selectedFilterEditForWON,
            '@PayerName': "",
            '@MsgLev1': "",
            '@FieldName': selectedFilterFieldForWON,
            '@AdditionalInfo': selectedAdditionalInfoForWON,
            '@CPTGroupId': "",
            '@ErrCode': "",
            '@ID': '#' + 0,
            '@CriteriaFieldName': selectedENCriteriaFieldNameForWON,
            '@CriteriaFieldDVARName': selectedENCriteriaDVARFieldNameForWON,
            '@CriteriaExpression': selectedENCriteriaExpressionForWON,
            '@CriteriaData': selectedENCriteriaExpressionValueForWON,
        };

        let saveNewError: MCAEISaveNewError = {
            NewError: newError,
        }

        selectedNotes.EditNotesMaintenanceInfo.Errors.push(saveNewError);
        this.props.action.crud.update({
            crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
            data: selectedNotes
        });

        //remove the notes that were successfully processed.

        let filteredNotes: MCAEIWONError[] = [];
        this.deselectAllRows();
        this.clearWONTab();
        this.ClearFilter();
        this.setState({ allWONotes: [...filteredNotes], selectedWONotes: [] });
    }

    deselectAllRows() {
        const { selectedTabIndex } = this.props.dataStore.ui;
        switch (selectedTabIndex) {
            case 1: {
                // clear selected row highlights from ui
                var wnGrid = document.getElementById("WN-Grid");
                if (wnGrid != null) {
                    var selectedRows = wnGrid.querySelectorAll('.selected');
                    for (var i = 0; i < selectedRows.length; i++) {
                        selectedRows[i].classList.remove('selected');
                    }
                }
            }
                break;
            case 0:
            default: {
                // clear selected row highlights from ui
                var wonGrid = document.getElementById("WON-Grid");
                if (wonGrid != null) {
                    var selectedRows = wonGrid.querySelectorAll('.selected');
                    for (var i = 0; i < selectedRows.length; i++) {
                        selectedRows[i].classList.remove('selected');
                    }
                }
            }
                break;
        }
    }

    RemoveNoteCriteria = async (e: any) => {
        let request: FilterRequest = this.buildWNRequest(false);

        this.props.action.crud.search({
            crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
            data: JSON.stringify(request)
        });
        const { dataSearch } = this.props.dataStore.crud;
        const { selectedEditCriteria, selectedWNotes, editNoteCriterias } = this.state;

        let selectedNotes: MCAEISaveNotes = {
            EditNotesMaintenanceInfo: {
                '@Action': "SaveErrorNotes",
                CriteriaList: { Criteria: [] },
                Errors: []
            }
        };

        if (selectedEditCriteria) {
            let criteria: MCRemoveCriteria = {
                '@ErrorClientAdditionalMessageId': selectedEditCriteria['@ErrorClientAdditionalMessageId'],
                '@Delete': 'true',
            }

            selectedNotes.EditNotesMaintenanceInfo.CriteriaList.Criteria.push(criteria);

            this.props.action.ui.removeEditNoteCriteria({
                masterCrud: dataSearch,
                uiData: {
                    selectedCriteria: selectedNotes,
                    selectedNoteErrorClientAdditionalId: selectedWNotes[0]['@ErrorClientAdditionalId'],
                    selectedCriteriaClientAdditionalMsgId: selectedEditCriteria['@ErrorClientAdditionalMessageId']
                }
            });
        }
    }

    ResetEditNoteCriteria = () => {
        this.props.action.ui.selectedWNCriteriaField({
            masterCrud: this.props.dataStore.crud.dataSearch,
            uiData: {
                text: '',
                value: '',
            }
        });
        this.props.action.ui.selectedWNCriteriaExp({
            masterCrud: this.props.dataStore.crud.dataSearch,
            uiData: {
                value: '',
            }
        });
        this.props.action.ui.updateWNCriteriaValue('');
        this.props.action.ui.updateWNAddInfoDesc('');
        this.props.action.ui.selectedWNENCriteria({
            masterCrud: this.props.dataStore.crud.dataSearch,
            uiData: {
                index: '',
                value: '',
                text: "-- Add New Criteria --",
                id: '0',
                editNote: {
                    label: '',
                    value: ''
                },
                additionalMsg: ''
            }
        });
    }

    WONGridColumns = () => {

        let headers = new Set();

        const FormElement = {
            dataName: "FormType",
            text: 'Form',
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(FormElement);

        const PayerElement = {
            dataName: "Payer",
            text: "Payer",
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(PayerElement);

        const FieldElement = {
            dataName: "Field",
            text: "Field",
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(FieldElement);

        const EditNameElement = {
            dataName: "EditName",
            text: "Edit Name",
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(EditNameElement);

        const MessageElement = {
            dataName: "Message",
            text: "Message",
            sortable: true,
            cellType: 'text',
            isSorted: 0,
        };

        headers.add(MessageElement);

        return headers;
    }

    WNGridColumns = () => {

        let headers = new Set();

        const FormElement = {
            dataName: "FormType",
            text: 'Form',
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(FormElement);

        const PayerElement = {
            dataName: "Payer",
            text: "Payer",
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(PayerElement);

        const FieldElement = {
            dataName: "Field",
            text: "Field",
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(FieldElement);

        const EditNameElement = {
            dataName: "EditName",
            text: "Edit Name",
            sortable: true,
            cellType: "text",
            isSorted: 0,
        };

        headers.add(EditNameElement);

        const MessageElement = {
            dataName: "Message",
            text: "Message",
            sortable: true,
            cellType: 'text',
            isSorted: 0,
        };

        headers.add(MessageElement);

        const AdditionalInfoElement = {
            dataName: "AdditionalMsg",
            text: "Additional Info",
            sortable: false,
            cellType: 'text',
            isSorted: 0,
        }

        headers.add(AdditionalInfoElement);

        return headers;
    }

    public onSelectNoteCriteria(e: React.ChangeEvent<HTMLSelectElement>) {

        const { dataSearch } = this.props.dataStore.crud;

        this.props.action.ui.selectEditNoteCriteria({
            masterCrud: dataSearch,
            uiData: {
                index: e.target.options.selectedIndex,
                value: e.target.value,
                text: e.target.options[e.target.options.selectedIndex].textContent
            }
        });
    }

    private createFilterParam(name: string, value: string, type?: string): FilterParam {
        return type ? { Name: name, Value: value, Type: type } : { Name: name, Value: value };
    }

    getConfigKey = (key: string): string => {
        let value = this.props.dataStore.configData?.container?.config?.find((o: ICookies_Config) => o.name === key)?.value;
        return value ? value : ""
    }
    /* 
     <Params>
      <Param Name='@intPageSize' Value='20' Type='int' />
      <Param Name='@intStartPage' Value='1' Type='int' />
      <Param Name='@intPageCount' Value='1' Type='int' />
      <Param Name='@intFilterDataForm' Value='1011' Type='int' />
      <Param Name='@intQueryDays' Value='30'  Type='int' />
      <Param Name='@intRefreshMinutes' Value='720'  Type='int' />
      <Param Name='@chrRefreshState' Value='Y'   />
    </Params>
    */
    buildWONRequest = (resetStartPage: boolean): FilterRequest => {
        const { gridWONPaging } = this.state;

        let pageSize: string = "20";
        let startPage: string = "1";
        let pageCount: string = "1";
        let refreshMinutes: string = "720";
        let queryDays: string = "30";

        if (gridWONPaging.totalRecordCount !== 0) {
            pageSize = gridWONPaging.recordsPerPage.toString();
            startPage = gridWONPaging.currentPageNumber.toString();
        }

        if (resetStartPage)
            startPage = "1";

        let configSimpleRefreshMinutes: string = this.getConfigKey("MCASimpleRefreshMinutes");

        if (!isEmpty(configSimpleRefreshMinutes)) {

            refreshMinutes = configSimpleRefreshMinutes;

            if (+refreshMinutes > 1440 || +refreshMinutes <= 14) {
                refreshMinutes = "720";
            }
        }

        let configQueryDays: string = this.getConfigKey("EditAdditionalInfoQueryDays");

        if (!isEmpty(configQueryDays)) {

            queryDays = configQueryDays;

            if (+queryDays > 999 || +queryDays <= 0) {
                queryDays = "30";
            }
        }

        const filterRequest: FilterRequest = {
            Param: [
                this.createFilterParam("@intQueryDays", queryDays, "int"),
                this.createFilterParam("@intPageSize", pageSize, "int"),
                this.createFilterParam("@intStartPage", startPage, "int"),
                this.createFilterParam("@intPageCount", pageCount, "int"),
                this.createFilterParam("@intRefreshMinutes", refreshMinutes, "int"),
                this.createFilterParam("@chrRefreshState", "Y"),
            ]
        }

        const {
            selectedFilterFormForWON,
            selectedFilterPayerForWON,
            selectedFilterFieldForWON,
            selectedFilterEditForWON
        } = this.props.dataStore.ui;

        if (!isEmpty(selectedFilterFormForWON)) {
            filterRequest.Param.push(this.createFilterParam("@intFilterDataForm", selectedFilterFormForWON.trim(), "int"));
        }
        if (!isEmpty(selectedFilterPayerForWON)) {
            filterRequest.Param.push(this.createFilterParam("@chvFilterPayer", selectedFilterPayerForWON.trim()));
        }
        if (!isEmpty(selectedFilterFieldForWON)) {
            filterRequest.Param.push(this.createFilterParam("@chvFilterField", selectedFilterFieldForWON.trim()));
        }
        if (!isEmpty(selectedFilterEditForWON)) {
            filterRequest.Param.push(this.createFilterParam("@chvFilterEdit", selectedFilterEditForWON.trim()));
        }

        return filterRequest;
    }

    initializeWONGrid = (): MCAEIWONError[] => {

        let withoutErrorNotes: MCAEIWONError[] = [];
        const { dataSearch } = this.props.dataStore.crud;

        if (dataSearch && dataSearch.ErrorsWithoutNotes &&
            dataSearch.ErrorsWithoutNotes.Errors && dataSearch.ErrorsWithoutNotes.Errors.Error) {
            withoutErrorNotes = withoutErrorNotes.concat(dataSearch.ErrorsWithoutNotes.Errors.Error);
        }

        withoutErrorNotes.forEach(won => {
            let fullmsg: string = won['@MsgLev1'];
            let msg: string = won['@MsgLev1'].length > this.MAX_MSG_LENGTH ? won['@MsgLev1'].substring(0, this.MAX_MSG_LENGTH).replace(/[\$,]/g, "") : won['@MsgLev1'];
            let formType: string = this.setFormType(won['@DataForm']);
            won.FormType = <React.Fragment><MSGDiv title={formType}>{formType}</MSGDiv></React.Fragment >;
            won.Payer = <React.Fragment><MSGDiv title={won['@PayerName']}>{won['@PayerName']}</MSGDiv></React.Fragment >;
            won.Field = <React.Fragment><MSGDiv title={won['@FieldName']}>{won['@FieldName']}</MSGDiv></React.Fragment >;
            won.EditName = <React.Fragment><MSGDiv title={won['@EditName']}>{won['@EditName']}</MSGDiv></React.Fragment >;
            won.Message = <React.Fragment><MSGDiv title={fullmsg}>{msg}</MSGDiv></React.Fragment >;
            won._PageNumber = won['@PageNumber'];
            won._ErrCode = won['@ErrCode'];
            won._CPTGroupId = won['@CPTGroupId'];
            won._EditErrorIdx = won['@EditErrorIdx'];
            won._FieldId = won['@FieldId'];
            won.Index = won['@Idx'];
            won._ErrorCount = won['@ErrorCount'];
        });

        return withoutErrorNotes;
    }

    applyWONFilter(resetStartPage: boolean) {
        const { gridWONPaging } = this.state;
        this.clearWONTab();
        this.setState({ isLoading: true });

        let request: FilterRequest = this.buildWONRequest(resetStartPage ? true : false);

        this.props.action.crud.search({
            crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
            data: JSON.stringify(request)
        });

        setTimeout(async () => {
            const gridData = this.initializeWONGrid();
            if (gridData.length === 0) {
                this.errorWONValidationMessage('No unassigned errors found.');
            }
            const criteriaFieldList = this.initializeCriteriaFieldForWONDropDown();
            const expressionList = this.initializeExpressionListForWONDropDown();
            let numberOfPages = 1;
            numberOfPages = this.getTotalPagesForGrid();
            if (numberOfPages < 1) numberOfPages = 1;

            this.setState({
                isLoading: false,
                allWONotes: [...gridData], filteredWONotes: [...gridData],
                selectedWONotes: [],
                allWONCriteriaFields: [...criteriaFieldList], allWONExpressionList: [...expressionList],
                gridWONPaging: {
                    currentPageNumber: resetStartPage ? 1 : gridWONPaging.currentPageNumber,
                    numberOfPages: numberOfPages,
                    recordsPerPage: gridWONPaging.recordsPerPage,
                    totalRecordCount: gridData.length
                }
            });
        }, 1000)
    }

    handleWONPaging(pageNumber: number) {
        const {
            filteredWONotes,
            gridWONPaging,
        } = this.state;

        // get the requested page of records
        const indexStart = (pageNumber - 1) * gridWONPaging.recordsPerPage;
        const indexEnd = indexStart + gridWONPaging.recordsPerPage;
        const page = filteredWONotes.slice(indexStart, indexEnd);

        this.setState({
            filteredWONotes: page,
            gridWONPaging: {
                currentPageNumber: pageNumber,
                numberOfPages: gridWONPaging.numberOfPages,
                recordsPerPage: gridWONPaging.recordsPerPage,
                totalRecordCount: gridWONPaging.totalRecordCount
            },
        }, () => {
            this.applyWONFilter(false);
        });
    }

    handleWNPaging(pageNumber: number) {
        const {
            filteredWNotes,
            gridWNPaging,
        } = this.state;

        // get the requested page of records
        const indexStart = (pageNumber - 1) * gridWNPaging.recordsPerPage;
        const indexEnd = indexStart + gridWNPaging.recordsPerPage;
        const page = filteredWNotes.slice(indexStart, indexEnd);

        this.setState({
            filteredWNotes: page,
            gridWNPaging: {
                currentPageNumber: pageNumber,
                numberOfPages: gridWNPaging.numberOfPages,
                recordsPerPage: gridWNPaging.recordsPerPage,
                totalRecordCount: gridWNPaging.totalRecordCount
            },
        }, () => {
            this.applyWNFilter(false);
        });
    }
    /*
     <Params>
      <Param Name='@intPageSize' Value='20' Type='int' />
      <Param Name='@intStartPage' Value='1' Type='int' />
      <Param Name='@intPageCount' Value='1' Type='int' />
      <Param Name='@intQueryDays' Value='0' Type='int' />
      <Param Name='@intRefreshMinutes' Value='720'  Type='int' />
      <Param Name='@chrRefreshState' Value='Y'   />
    </Params>
    */
    buildWNRequest = (resetStartPage: boolean): FilterRequest => {
        const { gridWNPaging } = this.state;

        let pageSize: string = "20";
        let startPage: string = "1";
        let pageCount: string = "1";
        let refreshMinutes: string = "720";
        let queryDays: string = "0";

        if (gridWNPaging.totalRecordCount !== 0) {
            pageSize = gridWNPaging.recordsPerPage.toString();
            startPage = gridWNPaging.currentPageNumber.toString();
        }

        if (resetStartPage)
            startPage = "1";

        let configSimpleRefreshMinutes: string = this.getConfigKey("MCASimpleRefreshMinutes");
        if (!isEmpty(configSimpleRefreshMinutes)) {

            refreshMinutes = configSimpleRefreshMinutes;

            if (+refreshMinutes > 1440 || +refreshMinutes <= 14) {
                refreshMinutes = "720";
            }
        }

        let configQueryDays: string = this.getConfigKey("EditAdditionalInfoQueryDays");
        if (!isEmpty(configQueryDays)) {

            queryDays = configQueryDays;

            if (+queryDays > 999 || +queryDays <= 0) {
                queryDays = "0";
            }
        }

        const filterRequest: FilterRequest = {
            Param: [
                this.createFilterParam("@intQueryDays", queryDays, "int"),
                this.createFilterParam("@intPageSize", pageSize, "int"),
                this.createFilterParam("@intStartPage", startPage, "int"),
                this.createFilterParam("@intPageCount", pageCount, "int"),
                this.createFilterParam("@intRefreshMinutes", refreshMinutes, "int"),
                this.createFilterParam("@chrRefreshState", "Y"),
            ]
        }

        const {
            selectedFilterFormForWN,
            selectedFilterPayerForWN,
            selectedFilterFieldForWN,
            selectedFilterEditForWN,
        } = this.props.dataStore.ui;


        if (!isEmpty(selectedFilterFormForWN)) {
            filterRequest.Param.push(this.createFilterParam("@intFilterDataForm", selectedFilterFormForWN.trim(), "int"));
        }
        if (!isEmpty(selectedFilterPayerForWN)) {
            filterRequest.Param.push(this.createFilterParam("@chvFilterPayer", selectedFilterPayerForWN.trim()));
        }
        if (!isEmpty(selectedFilterFieldForWN)) {
            filterRequest.Param.push(this.createFilterParam("@chvFilterField", selectedFilterFieldForWN.trim()));
        }
        if (!isEmpty(selectedFilterEditForWN)) {
            filterRequest.Param.push(this.createFilterParam("@chvFilterEdit", selectedFilterEditForWN.trim()));
        }

        return filterRequest;
    }

    applyWNFilter(resetStartPage: boolean) {
        const { gridWNPaging } = this.state;

        this.clearWNTab();

        this.setState({ isLoading: true });

        let request: FilterRequest = this.buildWNRequest(resetStartPage ? true : false);

        this.props.action.crud.search({
            crudId: CrudTypes.mctiErrorLvlAdditonalInfo,
            data: JSON.stringify(request)
        }, validationCallback);

        setTimeout(async () => {
            const gridData = this.initializeWNGrid();

            if (gridData.length === 0) {
                this.errorWNValidationMessage('No assigned errors found.');
            }

            const criteriaFieldList = this.initializeCriteriaFieldForWNDropDown();
            const expressionList = this.initializeExpressionListForWNDropDown();

            let numberOfPages = 1;
            numberOfPages = this.getTotalPagesForGrid();
            if (numberOfPages < 1) numberOfPages = 1;

            this.deselectAllRows();
            this.ResetEditNoteCriteria();

            this.setState({
                isLoading: false,
                allWNotes: [...gridData], filteredWNotes: [...gridData], selectedWNotes: [], editNoteCriterias: [],
                allWNCriteriaFields: [...criteriaFieldList], allWNExpressionList: [...expressionList],
                gridWNPaging: {
                    currentPageNumber: resetStartPage ? 1 : gridWNPaging.currentPageNumber,
                    numberOfPages: numberOfPages,
                    recordsPerPage: gridWNPaging.recordsPerPage,
                    totalRecordCount: gridData.length
                }
            });
        }, 1000);
    }

    clearWNTab = () => {
        const { selectedWNCriteriaField, selectedWNCriteriaExp,
            selectedWNCriteriaValue, selectedWNAdditionalInfo } = this.state;

        if (selectedWNCriteriaField && !isEmpty(selectedWNCriteriaField.label) || !isEmpty(selectedWNCriteriaField.value)) {
            this.props.action.ui.selectedWNCriteriaField({
                masterCrud: this.props.dataStore.crud.dataSearch,
                uiData: {
                    text: '',
                    value: '',
                }
            });
        }

        if (selectedWNCriteriaExp && !isEmpty(selectedWNCriteriaExp.label) || !isEmpty(selectedWNCriteriaExp.value)) {
            this.props.action.ui.selectedWNCriteriaExp({
                masterCrud: this.props.dataStore.crud.dataSearch,
                uiData: {
                    value: '',
                }
            });
        }

        if (selectedWNCriteriaValue && !isEmpty(selectedWNCriteriaValue)) {
            this.props.action.ui.updateWNCriteriaValue('');
        }

        if (selectedWNAdditionalInfo && !isEmpty(selectedWNAdditionalInfo)) {
            this.props.action.ui.updateWNAddInfoDesc('');
        }
    }

    clearWONTab = () => {
        this.props.action.ui.selectedWONCriteriaField({
            masterCrud: this.props.dataStore.crud.dataSearch,
            uiData: {
                text: '',
                value: '',
            }
        });
        this.props.action.ui.selectedWONCriteriaExp({
            masterCrud: this.props.dataStore.crud.dataSearch,
            uiData: {
                value: '',
            }
        });
        this.props.action.ui.updateWONCriteriaValue('');
        this.props.action.ui.updateWONAddInfoDesc('');
    }

    initializeCriteriaFieldForWONDropDown = (): IDropDownItem[] => {
        const { dataSearch } = this.props.dataStore.crud;
        let result: IDropDownItem[] = [
            { value: '', label: '' },
            { value: '0', label: 'No Criteria' }
        ];

        let criteriaFields: MCAEICriteriaField[] = [];

        if (dataSearch && dataSearch.ErrorsWithoutNotes &&
            dataSearch.ErrorsWithoutNotes.CriteriaFieldList &&
            dataSearch.ErrorsWithoutNotes.CriteriaFieldList.CriteriaField) {
            criteriaFields = criteriaFields.concat(dataSearch.ErrorsWithoutNotes.CriteriaFieldList.CriteriaField);
        }

        criteriaFields.forEach(cfl => {
            if (cfl['@FieldName'])
                result.push({ label: cfl['@FieldName'], value: cfl['@DVARName'] });
        });

        return result;
    }

    initializeExpressionListForWONDropDown = (): IDropDownItem[] => {
        const { dataSearch } = this.props.dataStore.crud;
        let result: IDropDownItem[] = [];

        let expressions: MCAEIExpression[] = [];

        if (dataSearch && dataSearch.ErrorsWithoutNotes &&
            dataSearch.ErrorsWithoutNotes.ExpressionList && dataSearch.ErrorsWithoutNotes.ExpressionList.Expression) {
            expressions = expressions.concat(dataSearch.ErrorsWithoutNotes.ExpressionList.Expression);
        }

        expressions.forEach(exp => {
            if (exp['@Expression'])
                result.push({ label: exp['@Expression'], value: exp['@Expression'].split(" ").join("") });
        });

        return result;
    }

    getTotalPagesForGrid = (): number => {
        const { selectedTabIndex } = this.props.dataStore.ui;
        let gridTotal: number = 0;
        let tp: string = "";
        const { dataSearch } = this.props.dataStore.crud;

        switch (selectedTabIndex) {
            //WN
            case 1:
                {
                    if (dataSearch && dataSearch.ErrorsWithNotes &&
                        dataSearch.ErrorsWithNotes["@TotalPages"]) {
                        tp = dataSearch.ErrorsWithNotes["@TotalPages"];
                        if (tp && tp.length > 0)
                            gridTotal = Number(tp);
                    }
                }
                break;
            //WON
            case 0:
            default:
                {
                    if (dataSearch && dataSearch.ErrorsWithoutNotes &&
                        dataSearch.ErrorsWithoutNotes.Summary) {
                        tp = dataSearch.ErrorsWithoutNotes.Summary["@TotalPages"];
                        if (tp && tp.length > 0)
                            gridTotal = Number(tp);
                    }
                }
                break;
        }
        return gridTotal;
    }

    initializeExpressionListForWNDropDown = (): IDropDownItem[] => {
        const { dataSearch } = this.props.dataStore.crud;
        let result: IDropDownItem[] = [];
        let expressions: MCAEIExpression[] = [];

        if (dataSearch && dataSearch.ErrorsWithNotes &&
            dataSearch.ErrorsWithNotes.ExpressionList &&
            dataSearch.ErrorsWithNotes.ExpressionList.Expression) {
            expressions = expressions.concat(dataSearch.ErrorsWithNotes.ExpressionList.Expression);
        }

        expressions.forEach(exp => {
            if (exp['@Expression'])
                result.push({ label: exp['@Expression'], value: exp['@Expression'].split(" ").join("") });
        });

        return result;
    }

    initializeCriteriaFieldForWNDropDown = (): IDropDownItem[] => {
        const { dataSearch } = this.props.dataStore.crud;
        let result: IDropDownItem[] = [
            { value: '', label: '' },
            { value: 'NoCriteria', label: 'No Criteria' }
        ];

        let criteriaFields: MCAEICriteriaField[] = [];

        if (dataSearch && dataSearch.ErrorsWithNotes &&
            dataSearch.ErrorsWithNotes.CriteriaFieldList &&
            dataSearch.ErrorsWithNotes.CriteriaFieldList.CriteriaField) {
            criteriaFields = criteriaFields.concat(dataSearch.ErrorsWithNotes.CriteriaFieldList.CriteriaField);
        }

        criteriaFields.forEach(cfl => {
            if (cfl['@FieldName'])
                result.push({ label: cfl['@FieldName'], value: cfl['@DVARName'] });
        });

        return result;
    }

    selectedFilterType = (dataForm: string): IDropDownItem => {

        let result = { value: '', label: '' };

        if (!isEmpty(dataForm)) {
            if (dataForm === "1011") {
                result = { label: "UB", value: "1011" };
            } else if (dataForm === "1001") {
                result = { label: "1500", value: "1001" };
            }
        }

        return result;
    }

    setFormType = (dataForm: string): string => {
        let result: string = "";
        if (!isEmpty(dataForm)) {
            if (dataForm === "1011") {
                result = "UB";
            } else if (dataForm === "1001") {
                result = "1500";
            }
        }
        return result;
    }

    public getInitialValue(options: any, selectedValue: any) {
        let getValue = options.filter((res: any) => {
            if (selectedValue != undefined) {
                return res.value == selectedValue
            } else {
                return { label: '', value: '0' };
            }
        });
        return getValue;
    }

    public getInitialExpressionValue(options: any, selectedValue: any) {

        let getValue = options.filter((res: any) => {
            if (selectedValue != undefined) {
                return res.value == selectedValue
            }
        });

        return getValue;
    }

    getFormType = (dataForm: string): string => {
        let result: string = "";
        if (!isEmpty(dataForm)) {
            if (dataForm === "UB") {
                result = "1011";
            } else if (dataForm === "1500") {
                result = "1001";
            }
        }

        return result;
    }

    additionalMessage = (errorId: string): string => {
        let additionalMessage: string = "";
        const { dataSearch } = this.props.dataStore.crud;
        let criteriaList: MCAEICriteria[] = [];

        if (dataSearch && dataSearch.ErrorsWithNotes &&
            dataSearch.ErrorsWithNotes.CriteriaList &&
            dataSearch.ErrorsWithNotes.CriteriaList.Criteria) {
            criteriaList = criteriaList.concat(dataSearch.ErrorsWithNotes.CriteriaList.Criteria);
        }

        let criteriaIndex: number = criteriaList.findIndex(criteria => criteria['@ErrorClientAdditionalId'] === errorId);

        if (criteriaIndex !== -1) {
            additionalMessage = criteriaList[criteriaIndex]['@AdditionalMsg'];
        }

        return additionalMessage.length > this.MAX_MSG_LENGTH ? additionalMessage.substring(0, this.MAX_MSG_LENGTH) : additionalMessage;
    }

    initializeWNGrid = (): MCAEIWNError[] => {
        const { dataSearch } = this.props.dataStore.crud;
        let withErrorNotes: MCAEIWNError[] = [];

        if (dataSearch && dataSearch.ErrorsWithNotes &&
            dataSearch.ErrorsWithNotes.Errors &&
            dataSearch.ErrorsWithNotes.Errors.Error) {
            withErrorNotes = withErrorNotes.concat(dataSearch.ErrorsWithNotes.Errors.Error);
        }

        withErrorNotes.forEach(wn => {
            let msg: string = wn['@MsgLev1'].length > 45 ? (wn['@MsgLev1'].substring(0, 45).replace(/[\$,]/g, "")) + "..." : wn['@MsgLev1'];
            let additionalMsg: string = this.additionalMessage(wn['@ErrorClientAdditionalId']);
            let formType: string = !isEmpty(wn['@DataForm']) ? this.setFormType(wn['@DataForm']) : "";
            wn.FormType = <React.Fragment><MSGDiv title={formType}>{formType}</MSGDiv></React.Fragment >;
            wn.Field = <React.Fragment><MSGDiv title={wn['@FieldName']}>{wn['@FieldName']}</MSGDiv></React.Fragment >;
            wn.EditName = <React.Fragment><MSGDiv title={wn['@EditName']}>{wn['@EditName']}</MSGDiv></React.Fragment >;
            wn.Message = <React.Fragment><MSGDiv title={wn['@MsgLev1']}>{msg}</MSGDiv></React.Fragment >;
            wn.Payer = <React.Fragment><MSGDiv title={wn['@PayerName']}>{wn['@PayerName']}</MSGDiv></React.Fragment >;
            wn._PageNumber = wn['@PageNumber'];
            wn.AdditionalMsg = <React.Fragment><MSGDiv title={additionalMsg}>{additionalMsg}</MSGDiv></React.Fragment >;
        });

        return withErrorNotes;
    }

    onSelectEditNoteCriteria(e: React.ChangeEvent<HTMLSelectElement>): void {
        const { dataSearch } = this.props.dataStore.crud;
        const { selectedWNotes, allWNCriteriaFields, allWNExpressionList } = this.state;
        if (e.target.options[e.target.options.selectedIndex].textContent) {

            let selectedEditNoteCriteria: MCAEICriteria = {
                '@ErrorClientAdditionalId': '',
                '@FieldName': '',
                '@Expression': '',
                '@CriteriaData': '',
                '@FieldDVARName': '',
                '@ErrorClientAdditionalMessageId': '',
                '@AdditionalMsg': ''
            };

            let critriaEditNote: any = {};
            let criteriaFieldString = "";
            let criteriaDvarString = "";
            let criteriaExpString = "";
            let criteriaExpValueString = "";
            let additionalMsg: string = "";

            let criteriaField: IDropDownItem = { label: "", value: "" };
            let criteriaExp: IDropDownItem = { label: "INLIST", value: "INLIST" };
            let criteriaExpValue: string = "";

            const criteriaText = e.target.options[e.target.options.selectedIndex].textContent;

            if (criteriaText && !isEmpty(criteriaText)) {

                this.setState({ selectedCriteriaEditNote: criteriaText });

                if (criteriaText.indexOf('-') > 0) {
                    let criteriaInfo: Array<string> = criteriaText.split('-');
                    criteriaFieldString = criteriaInfo[0];
                    selectedEditNoteCriteria['@FieldName'] = criteriaFieldString;
                    criteriaDvarString = this.criteriaDVarFieldName(criteriaFieldString);
                    selectedEditNoteCriteria['@FieldDVARName'] = criteriaDvarString;
                    criteriaField = { label: criteriaFieldString, value: criteriaDvarString };
                    criteriaExpString = criteriaInfo[1];
                    criteriaExp = { label: criteriaExpString, value: criteriaExpString };
                    criteriaExpValueString = criteriaInfo[2];
                    criteriaExpValue = criteriaExpValueString;
                    this.setState({ isCriteriaFieldDisabled: true });
                } else if (criteriaText === 'No Criteria') {
                    criteriaFieldString = criteriaText;
                    selectedEditNoteCriteria['@FieldName'] = criteriaFieldString;
                    criteriaDvarString = "NoCriteria";
                    selectedEditNoteCriteria['@FieldDVARName'] = criteriaDvarString;
                    criteriaField = { label: criteriaText, value: criteriaDvarString };
                    criteriaExp = { label: "INLIST", value: "INLIST" };
                    criteriaExpValue = "";
                    this.setState({ isCriteriaFieldDisabled: true });
                } else if (criteriaText === '-- Add New Criteria --') {
                    selectedEditNoteCriteria['@FieldName'] = '';
                    selectedEditNoteCriteria['@FieldDVARName'] = '';
                    selectedEditNoteCriteria['@ErrorClientAdditionalId'] = '';
                    selectedEditNoteCriteria['@Expression'] = 'INLIST';
                    selectedEditNoteCriteria['@CriteriaData'] = '';
                    selectedEditNoteCriteria['@ErrorClientAdditionalMessageId'] = '';
                    selectedEditNoteCriteria['@AdditionalMsg'] = '';
                    this.setState({ isCriteriaFieldDisabled: false });
                }

                critriaEditNote = allWNCriteriaFields.filter((item) => {
                    if (item.label === criteriaField.label)
                        return item;
                });

                let criteriaFieldDetails = allWNCriteriaFields.find((ob) => { return ob.label === criteriaFieldString });
                if (criteriaFieldDetails != undefined) {
                    this.props.action.ui.selectedWNCriteriaField({
                        masterCrud: dataSearch,
                        uiData: {
                            text: criteriaFieldDetails.label,
                            value: criteriaFieldDetails.value,
                        }
                    });
                }

                let criteriaFieldExpDetails = allWNExpressionList.find((ob) => { return ob.value === criteriaExpString });
                if (criteriaFieldExpDetails != undefined) {
                    this.props.action.ui.selectedWNCriteriaExp({
                        masterCrud: dataSearch,
                        uiData: {
                            value: criteriaFieldExpDetails.label,
                        }
                    });
                }

                this.setState({
                    selectedWNCriteriaField: criteriaField,
                    selectedWNCriteriaExp: criteriaExp,
                    selectedWNCriteriaValue: criteriaExpValue,
                    selectedWNAdditionalInfo: additionalMsg,
                });

                if (criteriaText !== '-- Add New Criteria --' && selectedWNotes && selectedWNotes.length === 1 &&
                    dataSearch && dataSearch.ErrorsWithNotes && dataSearch.ErrorsWithNotes.CriteriaList &&
                    dataSearch.ErrorsWithNotes.CriteriaList.Criteria) {
                    let errorClientAdditionalId = selectedWNotes[0]['@ErrorClientAdditionalId']
                    selectedEditNoteCriteria['@ErrorClientAdditionalId'] = errorClientAdditionalId;

                    let errorNoteCriteria: any = {}
                    if (Array.isArray(dataSearch.ErrorsWithNotes.CriteriaList.Criteria))
                        errorNoteCriteria = dataSearch.ErrorsWithNotes.CriteriaList.Criteria.find(clst => clst['@ErrorClientAdditionalId'] === errorClientAdditionalId
                            && clst['@FieldDVARName'] === criteriaDvarString && clst['@CriteriaData'] === criteriaExpValue);
                    else
                        errorNoteCriteria = dataSearch.ErrorsWithNotes.CriteriaList.Criteria;

                    if (errorNoteCriteria !== undefined && errorNoteCriteria !== null) {
                        critriaEditNote = errorNoteCriteria?.['@ErrorClientAdditionalMessageId'];
                        selectedEditNoteCriteria['@ErrorClientAdditionalMessageId'] = critriaEditNote;
                        additionalMsg = errorNoteCriteria?.['@AdditionalMsg'];
                        selectedEditNoteCriteria['@AdditionalMsg'] = additionalMsg;
                    } else {
                        errorNoteCriteria = dataSearch.ErrorsWithNotes.CriteriaList.Criteria.find(clst => clst['@ErrorClientAdditionalId'] === errorClientAdditionalId);
                        if (errorNoteCriteria) {
                            critriaEditNote = errorNoteCriteria?.['@ErrorClientAdditionalMessageId'];
                            selectedEditNoteCriteria['@ErrorClientAdditionalMessageId'] = critriaEditNote;
                            additionalMsg = errorNoteCriteria?.['@AdditionalMsg'];
                            selectedEditNoteCriteria['@AdditionalMsg'] = additionalMsg;
                        }
                    }
                }

                this.setState({ selectedEditCriteria: selectedEditNoteCriteria });

                this.props.action.ui.selectedWNENCriteria({
                    masterCrud: dataSearch,
                    uiData: {
                        index: e.target.options.selectedIndex,
                        value: e.target.value,
                        text: e.target.options[e.target.options.selectedIndex].textContent,
                        id: critriaEditNote !== undefined ? critriaEditNote[0].value : '0',
                        editNote: {
                            label: e.target.options[e.target.options.selectedIndex].textContent,
                            value: e.target.options[e.target.options.selectedIndex].value
                        },
                        additionalMsg: additionalMsg
                    }
                });
            }
        }
    }

    public handleWONSort(sortIndicator: ISortIndicator) {
        const { filteredWONotes } = this.state;

        let sortedRecords: MCAEIWONError[] = [];

        switch (sortIndicator.sortColumn) {
            case 'FormType': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => (this.getFormType(a.FormType).localeCompare(this.getFormType(b.FormType)))) :
                    filteredWONotes.sort((a, b) => (this.getFormType(b.FormType).localeCompare(this.getFormType(a.FormType))));
            }
                break;
            case 'Payer': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => a.Payer.localeCompare(b.Payer)) :
                    filteredWONotes.sort((a, b) => b.Payer.localeCompare(a.Payer));
            }
                break;
            case 'Field': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => a.Field.localeCompare(b.Field)) :
                    filteredWONotes.sort((a, b) => b.Field.localeCompare(a.Field));
            }
                break;
            case 'EditName': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => a.EditName.localeCompare(b.EditName)) :
                    filteredWONotes.sort((a, b) => b.EditName.localeCompare(a.EditName));
            }
                break;
            case 'Message': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWONotes.sort((a, b) => a.Message.localeCompare(b.Message)) :
                    filteredWONotes.sort((a, b) => b.Message.localeCompare(a.Message));
            }
                break;
        }

        this.setState({ sortIndicator, filteredWONotes: sortedRecords });
    }

    public handleWNSort(sortIndicator: ISortIndicator) {
        const { filteredWNotes } = this.state;

        let sortedRecords: MCAEIWNError[] = [];

        switch (sortIndicator.sortColumn) {
            case 'FormType': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWNotes.sort((a, b) => (this.getFormType(a.FormType).localeCompare(this.getFormType(b.FormType)))) :
                    filteredWNotes.sort((a, b) => (this.getFormType(b.FormType).localeCompare(this.getFormType(a.FormType))));
            }
                break;
            case 'Payer': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWNotes.sort((a, b) => a.Payer.localeCompare(b.Payer)) :
                    filteredWNotes.sort((a, b) => b.Payer.localeCompare(a.Payer));
            }
                break;
            case 'Field': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWNotes.sort((a, b) => a.Field.localeCompare(b.Field)) :
                    filteredWNotes.sort((a, b) => b.Field.localeCompare(a.Field));
            }
                break;
            case 'EditName': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWNotes.sort((a, b) => a.EditName.localeCompare(b.EditName)) :
                    filteredWNotes.sort((a, b) => b.EditName.localeCompare(a.EditName));
            }
                break;
            case 'Message': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWNotes.sort((a, b) => a.Message.localeCompare(b.Message)) :
                    filteredWNotes.sort((a, b) => b.Message.localeCompare(a.Message));
            }
                break;
            case 'AdditionalMsg': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    filteredWNotes.sort((a, b) => a.AdditionalMsg.localeCompare(b.AdditionalMsg)) :
                    filteredWNotes.sort((a, b) => b.AdditionalMsg.localeCompare(a.AdditionalMsg));
            }
                break;
        }

        this.setState({ sortIndicator, filteredWNotes: sortedRecords });
    }

    public errorWONValidationMessage = (message: string) => {
        this.props.action.ui.errorWONValidationMessage(message);
    }

    public errorWNValidationMessage = (message: string) => {
        this.props.action.ui.errorWNValidationMessage(message);
    }

    setRollUpConfirmationMessage(message: string) {
        this.props.action.ui.setRollUpConfirmationMessage(message);
    }

    setRemoveWNRecordsConfirmationMessage(message: string) {
        this.props.action.ui.setRemoveWNRecordsConfirmationMessage(message);
    }

    setRemoveCriteriaConfirmationMessage(message: string) {
        this.props.action.ui.setRemoveCriteriaConfirmationMessage(message);
    }

    public render() {
        const instruction = <React.Fragment>Search for errors that have been encountered, and assign Custom Help Notes.(For more information, click the help button.)</React.Fragment>;
        const { validation_ErrorForWON, validation_ErrorForWN, modalViewed, rollUpConfirmationMessage,
            removeWNRecordsConfirmationMessage, removeCriteriaConfirmationMessage } = this.props.dataStore.ui;
        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_AdditionalInfo.htm' width='1250px' isBusy={this.state.isLoading}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper>
                    <FormRow>
                        <TabContainer
                            domID="TabContainerResponse"
                            initialTab={this.props.dataStore?.ui.selectedTabIndex}
                            className='tabcss'
                            onTabChange={(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) => this.onSelectTab(e, selectInfo)}
                            tabs={this.getTabs()}
                            width='900px'
                        />
                    </FormRow>
                </ContentWrapper>
                <ModalConfirmation alertMode={true}
                    isOpen={!!validation_ErrorForWON && !modalViewed}
                    message={validation_ErrorForWON}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.errorWONValidationMessage("")}
                />
                <ModalConfirmation alertMode={true}
                    isOpen={!!validation_ErrorForWN && !modalViewed}
                    message={validation_ErrorForWN}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.errorWNValidationMessage("")}
                />
                <ModalConfirmation
                    isOpen={!!removeWNRecordsConfirmationMessage}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setRemoveWNRecordsConfirmationMessage("")}
                    message={removeWNRecordsConfirmationMessage}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.RemoveWNRecordsConfirm()}
                />
                <ModalConfirmation
                    isOpen={!!rollUpConfirmationMessage}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setRollUpConfirmationMessage("")}
                    message={rollUpConfirmationMessage}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.createRollUpRule()}
                />
                <ModalConfirmation
                    isOpen={!!removeCriteriaConfirmationMessage}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setRemoveCriteriaConfirmationMessage("")}
                    message={removeCriteriaConfirmationMessage}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.RemoveCriteriaConfirm()}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IAEIState, IAEIActionProps, IOwnProps, IAEIProps, ApplicationState>(
    createCrudMapStateToProps('manageHelpNotesForEdits'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ManangeHelpNotesForEdits);

export default withRouter(connectedHoc);