import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";

// STATE - This defines the type of data maintained in the Redux store.

export interface IFavoriteFilterUIState {
    selectedFilterUser: ISelectFilterUserData;
    selectedFilterGroup: ISelectFilterGroupData;
    selectedSavedFilter: ISelectSavedFilterData;

    selectedAllSavedFilters: string[];
    selectedAllFavoriteFilters: string[];
    selectedAllHomePageFilters: string[];

    filterType: FilterType;
    maxHomePageFilters: number;
    filterErrorMsg: string;
    putFavorites: MCPutAssignedFavorites;
    
    clearUser: number;
    clearGroup: number;
};

interface IUIAllSelectedSavedFilter {
    selected: string[];
}

interface IUIAllSelectedFavoriteFilter {
    selected: string[];
}

interface IUIAllSelectedHomePageFilter {
    selected: string[];
}

export interface ISelectFilterUserData {
    index: number;
    id?: string;
    text: string;
    Favorites: MCFavoriteFilters;
    HomePages: MCHomePageFilters;
};

export interface ISelectFilterGroupData {
    index?: number;
    id?: string;
    text: string;
    Favorites: MCFavoriteFilters;
    HomePages: MCHomePageFilters;
};

export interface ISelectSavedFilterData {
    index: number;
    value: string;
    text: string;
};

export interface ISelectFavoriteFilterData {
    index: number;
    value: string;
    text: string;
};

export interface ISelectHomePageFilterData {
    index: number;
    value: string;
    text: string;
};

interface IFilterUiUpdate {
    value: string;
}

export const defaultFilterUser: ISelectFilterUserData = {
    index: -1,
    id: undefined,
    text: '',
    Favorites: { Favorite: [] },
    HomePages: { HomePage: [] },
};

export const defaultFilterGroup: ISelectFilterGroupData = {
    index: undefined,
    id: undefined,
    text: '',
    Favorites: { Favorite: [] },
    HomePages: { HomePage: [] },
};

export const defaultSavedFilter: ISelectSavedFilterData = {
    index: 0,
    value: '',
    text: '',
};

export const defaultFavoriteFilter: ISelectFavoriteFilterData = {
    index: 0,
    value: '',
    text: '',
};

export const defaultHomePageFilter: ISelectHomePageFilterData = {
    index: 0,
    value: '',
    text: '',
};

export const defaultPutFavorites: MCPutAssignedFavorites = {
    AssignFavorites: {
        Favorites: {
            Favorite: [
                {
                    "@AssignType": '',
                    "@AssignID": '',
                    '@FilterID': '',
                    "@FilterName": '',
                    "@Delete": '',
                    "@Sequence": ''
                }]
        }
    }
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface ISelectFilterUser extends ICrudActionData<MCARMFilter, ISelectFilterUserData> { }
export interface ISelectFilterGroup extends ICrudActionData<MCARMFilter, ISelectFilterGroupData> { }
export interface ISelectSavedFilter extends ICrudActionData<MCARMFilter, ISelectSavedFilterData> { }

export interface IModifySavedFilter extends ICrudActionData<MCARMFilter, MCSavedFilter> { }
export interface IModifyFavoriteFilter extends ICrudActionData<MCARMFilter, MCFavoriteFilter> { }
export interface IModifyHomePageFilter extends ICrudActionData<MCARMFilter, MCHomePageFilter> { }

export interface IFilterError extends ICrudActionData<MCARMFilter, IFilterUiUpdate> {}

export interface ISelectSavedFilterList extends ICrudActionData<MCARMFilter, IUIAllSelectedSavedFilter> { }
export interface ISelectFavoriteFilterList extends ICrudActionData<MCARMFilter, IUIAllSelectedFavoriteFilter> { }
export interface ISelectHomePageFilterList extends ICrudActionData<MCARMFilter, IUIAllSelectedHomePageFilter> { }

export const actionCreators = {
    selectFilterUser: (selectFilterUserInfo: ISelectFilterUser) => createDataAction('SELECT_FILTER_USER', selectFilterUserInfo),
    selectFilterGroup: (selectFilterGroupInfo: ISelectFilterGroup) => createDataAction('SELECT_FILTER_GROUP', selectFilterGroupInfo),
    
    addFavorites: (filterId: IModifySavedFilter) => createDataAction('ADD_FAVORITES', filterId), // RIGHT
    removeFavorites: (filterId: IModifyFavoriteFilter) => createDataAction('REMOVE_FAVORITES', filterId), // LEFT

    addHomePages: (filterId: IModifyFavoriteFilter) => createDataAction('ADD_HOMEPAGES', filterId), // DOWN
    removeHomePages: (filterId: IModifyHomePageFilter) => createDataAction('REMOVE_HOMEPAGES', filterId), //UP

    moveHomePagesUp: (filterId: IModifyHomePageFilter) => createDataAction('MOVE_HOMEPAGES_UP', filterId), // UP
    moveHomePagesDown: (filterId: IModifyHomePageFilter) => createDataAction('MOVE_HOMEPAGES_DOWN', filterId), // DOWN

    selectAllSavedFilters: (selectSavedFilters: ISelectSavedFilterList) => createDataAction('SELECT_ALLSAVEDFILTERS', selectSavedFilters),
    selectAllFavoriteFilters: (selectFavoriteFilters: ISelectFavoriteFilterList) => createDataAction('SELECT_ALLFAVORITEFILTERS', selectFavoriteFilters),
    selectAllHomePageFilters: (selectHomePageFilters: ISelectHomePageFilterList) => createDataAction('SELECT_ALLHOMEPAGEFILTERS', selectHomePageFilters),

    addFilterErrorMsg: (fieldInfo: IFilterError) => createDataAction('FILTER_ERROR', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export enum FilterType {
    NoFilter = 0,
    UserFilter = 1,
    GroupFilter,
}

export const defaultState: IFavoriteFilterUIState = {
    selectedFilterUser: defaultFilterUser,
    selectedFilterGroup: defaultFilterGroup,
    selectedSavedFilter: defaultSavedFilter,
   
    selectedAllSavedFilters: [],
    selectedAllFavoriteFilters: [],
    selectedAllHomePageFilters: [],
    maxHomePageFilters: 5,   
    filterType: FilterType.NoFilter,
    filterErrorMsg: "",
    putFavorites: defaultPutFavorites,
    
    clearUser: 0,
    clearGroup: 0
};

function moveHomePages(filterType: FilterType, up: boolean, selector: any, crud: MCARMFilter, selections: string[]): MCHomePageFilter[] {

    let sortedArray: MCHomePageFilter[] = [];

    if (filterType) {
        switch (filterType) {
            case FilterType.UserFilter:
                {
                    let user = selector as ISelectFilterUserData;
                    if (user) {
                        if (crud && crud.AssignFavorites &&
                            crud.AssignFavorites.Users && crud.AssignFavorites.Users.User) {

                            let selectedUser = crud.AssignFavorites.Users.User.find(suser => suser["@ID"] === user.id);

                            if (!selectedUser || !selectedUser.HomePages ||
                                !selectedUser.HomePages.HomePage ||
                                selectedUser.HomePages.HomePage.length <= 0) {
                                return [];
                            }

                            let tempArray: MCHomePageFilter[] = JSON.parse(JSON.stringify(selectedUser.HomePages!.HomePage));

                            if (!selections || tempArray.length <= 1 || (selections && selections.length === 0 ||
                                selections.length === tempArray.length))
                                return tempArray;  // nothing selected or everything selected

                            // since my selections don't know the sequence, and they may not be in order, build a sorted list of ids
                            let sequenceHolder: string[] = [...Array(tempArray.length)].map(x => '');

                            tempArray.forEach((taObj) => {
                                
                                let taObjPos = Number(taObj['@Sequence']);
                                sequenceHolder[taObjPos - 1] = taObj['@ID'];
                            });

                            // flip the selected items if moving down for sort multiple
                            let sortSelections = selections.slice();
                            if (!up && sortSelections.length > 1) {
                                sortSelections = sortSelections.reverse();
                            }
                            
                            let fails: boolean = false;
                            sortSelections.forEach(id => {
                                let crudOb = tempArray.find((x) => x['@ID'] === id);
                                if (crudOb && !fails) {
                                    let orderOb = Number(crudOb['@Sequence']);

                                    if ((up && orderOb === 1) ||
                                        (!up && orderOb === tempArray.length))
                                        fails = true;
                                    else {
                                        let toFind = orderOb + (up ? -1 : 1);

                                        // swap them in the array
                                        let myTempOrderHolder = sequenceHolder[toFind - 1];
                                        sequenceHolder[toFind - 1] = sequenceHolder[orderOb - 1];
                                        sequenceHolder[orderOb - 1] = myTempOrderHolder;
                                    }
                                }
                            });

                            if (fails)
                                return tempArray;   // can't move up or down so bail on everything, and leave it alone

                            // if we made it here, then orderHolder now has the new order, build it and store it

                            for (let idx = 0; idx < sequenceHolder.length; idx++) {
                                if (sequenceHolder.length > 0) {
                                    sortedArray.push({
                                        '@ID': sequenceHolder[idx],
                                        '@Name': sequenceHolder[idx],
                                        '@Sequence': (idx + 1).toString()
                                    });
                                }
                            }

                            // keep crud updated
                            selectedUser!.HomePages!.HomePage = sortedArray;
                        }
                    }
                }
                break;
            case FilterType.GroupFilter:
                {
                    let group = selector as ISelectFilterGroupData;
                    if (group) {
                        if (crud && crud.AssignFavorites &&
                            crud.AssignFavorites.Groups && crud.AssignFavorites.Groups.Group) {

                            let selectedGroup = crud.AssignFavorites.Groups.Group.find(sgroup => sgroup["@Name"] === group.text);

                            if (!selectedGroup || !selectedGroup.HomePages ||
                                !selectedGroup.HomePages.HomePage ||
                                selectedGroup.HomePages.HomePage.length <= 0) {
                                return [];
                            }

                            let tempArray: MCHomePageFilter[] = JSON.parse(JSON.stringify(selectedGroup.HomePages!.HomePage));
                            if (!selections || tempArray.length <= 1 || (selections && selections.length === 0 ||
                                selections.length === tempArray.length))
                                return tempArray;  // nothing selected or everything selected

                            // since my selections don't know the sequence, and they may not be in order, build a sorted list of ids
                            let orderHolder: string[] = [...Array(tempArray.length)].map(x => '');
                            tempArray.forEach((taOb) => {
                                let taPos = Number(taOb['@Sequence']);
                                orderHolder[taPos - 1] = taOb['@ID'];
                            });

                            // flip the selected items if moving down for sort multiple
                            let sortSelections = selections.slice();
                            if (!up && sortSelections.length > 1) {
                                sortSelections = sortSelections.reverse();
                            }

                            let fails: boolean = false;
                            sortSelections.forEach(id => {
                                let crudOb = tempArray.find((x) => x['@ID'] === id);
                                if (crudOb && !fails) {
                                    let orderOb = Number(crudOb['@Sequence']);

                                    if ((up && orderOb === 1) ||
                                        (!up && orderOb === tempArray.length))
                                        fails = true;
                                    else {
                                        let toFind = orderOb + (up ? -1 : 1);

                                        // swap them in the array
                                        let myTempOrderHolder = orderHolder[toFind - 1];
                                        orderHolder[toFind - 1] = orderHolder[orderOb - 1];
                                        orderHolder[orderOb - 1] = myTempOrderHolder;
                                    }
                                }
                            });

                            if (fails)
                                return tempArray;   // can't move up or down so bail on everything, and leave it alone

                            // if we made it here, then orderHolder now has the new order, build it and store it
                            for (let idx = 0; idx < orderHolder.length; idx++) {
                                if (orderHolder.length > 0) {
                                    sortedArray.push({
                                        '@ID': orderHolder[idx],
                                        '@Name': orderHolder[idx],
                                        '@Sequence': (idx + 1).toString()
                                    });
                                }
                            }

                            // keep crud updated
                            selectedGroup!.HomePages!.HomePage = sortedArray;
                        }
                    }
                }
                break;
        }
    }

    return sortedArray;
}

function getFavorites(filters: MCFavoriteFilter[]): string[] {
    let result: string[] = [];
    if (filters && filters.length > 0) {
        filters.forEach(function (filterObj) {
            if (filterObj && filterObj["@ID"] !== "") {
                result.push(filterObj["@ID"]);
            }
        })
    }
    return result;
}

// get Filter Name by Passing Filter Id
function getFavoriteFilterNameById(filterId: string, crud: MCARMFilter): string {

    let result: string = '';

    if (filterId && crud && crud.AssignFavorites && crud.AssignFavorites.FilterList &&
        crud.AssignFavorites.FilterList.Filter && crud.AssignFavorites.FilterList.Filter.length > 0) {
        let filterIndex: number = crud.AssignFavorites.FilterList.Filter.findIndex(fObj => fObj["@ID"] === filterId);
        if (filterIndex !== -1) {
            let filterObj = crud.AssignFavorites.FilterList.Filter[filterIndex];
            if (filterObj && filterObj["@ID"] !== "" && filterObj["@Name"] !== "") {
                result = filterObj["@Name"];
            }
        }
    }

    return result;
}

function getFavoriteObject(filterId: string, crud: MCARMFilter): MCFavoriteFilter {
    let result: MCFavoriteFilter = {
        '@ID': '',
        "@Name": '',
    };

    if (filterId) {
        if (crud && crud.AssignFavorites && crud.AssignFavorites.FilterList &&
            crud.AssignFavorites.FilterList.Filter && crud.AssignFavorites.FilterList.Filter.length > 0) {
            let savedFilterObj = crud.AssignFavorites.FilterList.Filter.find(filterObj => filterObj["@ID"] === filterId);
            if (savedFilterObj) {
                let favObject: MCFavoriteFilter = {
                    '@ID': savedFilterObj["@ID"],
                    "@Name": savedFilterObj["@Name"],
                };
                result = favObject;
            }
        }
    }

    return result;
}

function getFavoriteObjects(filterIds: string[], crud: MCARMFilter): MCFavoriteFilter[] {

    let result: MCFavoriteFilter[] = [];

    if (filterIds && filterIds.length > 0) {
        filterIds.forEach(function (id) {
            if (id && id !== "") {
                result.push(getFavoriteObject(id, crud));
            }
        });
    }

    return result;
}

function getHomePages(filters: MCHomePageFilter[]): string[] {
    let result: string[] = [];
    if (filters && filters.length > 0) {
        filters.forEach(function (filterObj) {
            if (filterObj && filterObj["@ID"] !== "" && filterObj["@Name"] !== "") {
                result.push(filterObj["@ID"]);
            }
        })
    }
    return result;
}

function getHomePageObject(filterId: string, sequence: number, crud: MCARMFilter): MCHomePageFilter {
    let page: MCHomePageFilter = {
        '@ID': '',
        '@Name': '',
        '@Sequence': '',
    };

    if (filterId) {
        if (crud && crud.AssignFavorites && crud.AssignFavorites.FilterList &&
            crud.AssignFavorites.FilterList.Filter && crud.AssignFavorites.FilterList.Filter.length > 0) {

            let savedFilterObj = crud.AssignFavorites.FilterList.Filter.find(filterObj => filterObj["@ID"] === filterId);

            if (savedFilterObj) {
                let pageObject: MCHomePageFilter = {
                    '@ID': savedFilterObj["@ID"],
                    '@Name': savedFilterObj["@Name"],
                    '@Sequence': sequence ? (sequence).toString(): '',
                };
                page = pageObject;
            }
        }
    }

    return page;
}

function getHomePageObjects(filterIds: string[], crud: MCARMFilter): MCHomePageFilter[] {

    let pages: MCHomePageFilter[] = [];

    if (filterIds && filterIds.length > 0) {
        let sequence: number = 1;
        filterIds.forEach(function (id) {
            if (id && id !== "") {
                pages.push(getHomePageObject(id, sequence, crud));
                sequence++;
            }
        });
    }
    
    return pages;
}

function addFavorites(filterType: FilterType, selector: any, filters: string[], crud: MCARMFilter): MCFavoriteFilter[] {

    let newFilters: MCFavoriteFilter[] = [];

    if (filterType) {
        switch (filterType) {
            case FilterType.UserFilter:
                let user = selector as ISelectFilterUserData;
                newFilters = addUserFavorites(user, filters, crud);
                break;
            case FilterType.GroupFilter:
                let group = selector as ISelectFilterGroupData;
                newFilters = addGroupFavorites(group, filters, crud);
                break;
        }
    }

    return newFilters;
}

function addUserFavorites(selectedUser: ISelectFilterUserData, toAddFilters: string[], crud: MCARMFilter): MCFavoriteFilter[] {

    let newFilters: MCFavoriteFilter[] = [];

    if (selectedUser && toAddFilters && toAddFilters.length > 0) {

        if (!selectedUser.Favorites)
            selectedUser.Favorites = { Favorite: [] };

        if (!selectedUser.Favorites.Favorite || (selectedUser.Favorites.Favorite && !Array.isArray(selectedUser.Favorites.Favorite)))
            selectedUser.Favorites.Favorite = [];

        // Look for Crud User
        let crudUserLookup = crud.AssignFavorites.Users.User.find(usr => usr["@ID"] === selectedUser.id);

        if (crudUserLookup) {

            // Check Crud has any Favorites
            let selectedCrudUserFavorites = crudUserLookup.Favorites.Favorite;

            // Update Crud user favorites to selected user
            if (selectedCrudUserFavorites && selectedCrudUserFavorites.length > 0) {
                crudUserLookup.Favorites.Favorite.forEach(function (xfilter) {
                    let index: number = selectedUser.Favorites.Favorite.findIndex(filterObj => filterObj["@ID"] === xfilter["@ID"]);
                    let filterIndex: number = crud.AssignFavorites.FilterList.Filter.findIndex(flt => flt["@ID"] === xfilter["@ID"]);
                    if (index === -1 && xfilter && xfilter["@ID"] !== "") {
                        xfilter["@Name"] = (filterIndex !== -1) ? crud.AssignFavorites.FilterList.Filter[filterIndex]["@Name"] : "";
                        selectedUser.Favorites.Favorite.push(xfilter);
                    }
                });   
            } 

            // Add New Favorite Filters to Selected User
            toAddFilters.forEach((addedFilter) => {
                let addedFilterObject: MCFavoriteFilter = getFavoriteObject(addedFilter, crud);
                let index: number = selectedUser.Favorites.Favorite.findIndex(filterObj => filterObj === addedFilterObject);
                if (index === -1 && addedFilterObject && addedFilterObject["@ID"] !== "") {
                    let filterIndex: number = crud.AssignFavorites.FilterList.Filter.findIndex(flt => flt["@ID"] === addedFilterObject["@ID"]);
                    addedFilterObject["@Name"] = (filterIndex !== -1) ? crud.AssignFavorites.FilterList.Filter[filterIndex]["@Name"] : "";
                    selectedUser.Favorites.Favorite.push(addedFilterObject);
                }
            });

            // Update Crud with new favorite filters
            newFilters = selectedUser.Favorites.Favorite;

            if (crudUserLookup) {

                if (!crudUserLookup.Favorites)
                    crudUserLookup.Favorites = { Favorite: [] };

                if (!crudUserLookup.Favorites.Favorite || (crudUserLookup.Favorites.Favorite && !Array.isArray(crudUserLookup.Favorites.Favorite)))
                    crudUserLookup.Favorites.Favorite = [];

                crudUserLookup.Favorites!.Favorite = selectedUser.Favorites.Favorite; // JSON.parse(JSON.stringify(newFilters));
            }
        }
    }
    
    return newFilters;
}

function addGroupFavorites(selectedGroup: ISelectFilterGroupData, toAddFilters: string[], crud: MCARMFilter): MCFavoriteFilter[] {

    let newFilters: MCFavoriteFilter[] = [];

    if (selectedGroup && toAddFilters && toAddFilters.length > 0) {

        if (!selectedGroup.Favorites)
            selectedGroup.Favorites = { Favorite: [] };

        if (!selectedGroup.Favorites.Favorite || (selectedGroup.Favorites.Favorite && !Array.isArray(selectedGroup.Favorites.Favorite)))
            selectedGroup.Favorites.Favorite = [];

        // Look for Crud User
        let crudGroupLookup = crud.AssignFavorites.Groups.Group.find(grp => grp["@Name"] === selectedGroup.text);

        if (crudGroupLookup) {

            // Check Crud has any Favorites
            let selectedCrudGroupFavorites = crudGroupLookup.Favorites.Favorite;
            
            // Update Crud group favorites to selected group
            if (selectedCrudGroupFavorites && selectedCrudGroupFavorites.length > 0) {
                crudGroupLookup.Favorites.Favorite.forEach(function (xfilter) {
                    let index: number = selectedGroup.Favorites.Favorite.findIndex(filterObj => filterObj["@ID"] === xfilter["@ID"]);
                    let filterIndex: number = crud.AssignFavorites.FilterList.Filter.findIndex(flt => flt["@ID"] === xfilter["@ID"]);
                    if (index === -1 && xfilter && xfilter["@ID"] !== "") {
                        xfilter["@Name"] = (filterIndex !== -1) ? crud.AssignFavorites.FilterList.Filter[filterIndex]["@Name"] : "";
                        selectedGroup.Favorites.Favorite.push(xfilter);
                    }
                });
            }
            
            // Add New Favorite Filters to Selected Group
            toAddFilters.forEach((addedFilter) => {
                let addedFilterObject: MCFavoriteFilter = getFavoriteObject(addedFilter, crud);
                let index: number = selectedGroup.Favorites.Favorite.findIndex(filterObj => filterObj === addedFilterObject);
                if (index === -1 && addedFilterObject && addedFilterObject["@ID"] !== "") {
                    let filterIndex: number = crud.AssignFavorites.FilterList.Filter.findIndex(flt => flt["@ID"] === addedFilterObject["@ID"]);
                    addedFilterObject["@Name"] = (filterIndex !== -1) ? crud.AssignFavorites.FilterList.Filter[filterIndex]["@Name"] : "";
                    selectedGroup.Favorites.Favorite.push(addedFilterObject);
                }
            });

            // Update Crud with new favorite filters
            newFilters = selectedGroup.Favorites.Favorite;

            if (crudGroupLookup) {

                if (!crudGroupLookup.Favorites)
                    crudGroupLookup.Favorites = { Favorite: [] };

                if (!crudGroupLookup.Favorites.Favorite || (crudGroupLookup.Favorites.Favorite && !Array.isArray(crudGroupLookup.Favorites.Favorite)))
                    crudGroupLookup.Favorites.Favorite = [];

                crudGroupLookup.Favorites!.Favorite = selectedGroup.Favorites.Favorite; //JSON.parse(JSON.stringify(newFilters));
            }
        }
    }
    return newFilters;
}

function removeFavorites(filterType: FilterType, selector: any, filters: string[], crud: MCARMFilter): string[] {

    let reminingFavoriteFilters: string[] = [];

    if (filterType && filters && filters.length > 0) {
        switch (filterType) {
            case FilterType.UserFilter:
                let user = selector as ISelectFilterUserData;
                let remUserFavObjects: MCFavoriteFilter[] = [];

                if (user) {
                    // Check user has any Favorite Filters to Remove
                    remUserFavObjects = removeUserFilter(user, filters, crud);
                }

                if (remUserFavObjects && remUserFavObjects.length > 0) {
                    remUserFavObjects.forEach(function (deltaFavObj) {
                        if (reminingFavoriteFilters) {
                            if (reminingFavoriteFilters.length > 0) {
                                let index: number = reminingFavoriteFilters.findIndex(remFavObj => remFavObj === deltaFavObj["@ID"]);
                                if (index === -1 && deltaFavObj && deltaFavObj["@ID"] !== "") {
                                    reminingFavoriteFilters.push(deltaFavObj["@ID"]);
                                }
                            } else {
                                if (deltaFavObj && deltaFavObj["@ID"] !== "") {
                                    reminingFavoriteFilters.push(deltaFavObj["@ID"]);
                                }
                            }
                        }
                    });
                }
                break;
            case FilterType.GroupFilter:
                let group = selector as ISelectFilterGroupData;
                let remGroupFavObjects: MCFavoriteFilter[] = [];

                if (group) {
                    // Check Group has any Favorite Filters to Remove
                    remGroupFavObjects = removeGroupFilter(group, filters, crud);
                }

                if (remGroupFavObjects && remGroupFavObjects.length > 0) {
                    remGroupFavObjects.forEach(function (deltaFavObj) {
                        if (reminingFavoriteFilters && reminingFavoriteFilters.length > 0) {
                            let index: number = reminingFavoriteFilters.findIndex(remFavObj => remFavObj === deltaFavObj["@ID"]);
                            if (index === -1 && deltaFavObj && deltaFavObj["@ID"] !== "") {
                                reminingFavoriteFilters.push(deltaFavObj["@ID"]);
                            }
                        } else {
                            if (deltaFavObj && deltaFavObj["@ID"] !== "") {
                                reminingFavoriteFilters.push(deltaFavObj["@ID"]);
                            }
                        }
                    });
                }
                break;
        }
    }

    return reminingFavoriteFilters;
    
}

function removeUserFilter(selectedUser: ISelectFilterUserData, toRemoveFilters: string[], crud: MCARMFilter): MCFavoriteFilter[] {

    let reminingFavoriteFilters: MCFavoriteFilter[] = [];

    if (toRemoveFilters && toRemoveFilters.length > 0) {
        // Check selected User Has any Favorites then remove them
        if (selectedUser && selectedUser.Favorites &&
            selectedUser.Favorites.Favorite && selectedUser.Favorites.Favorite.length > 0) {

            // Remove all selected Favorites 
            reminingFavoriteFilters = selectedUser.Favorites.Favorite.filter(fObj => toRemoveFilters.indexOf(fObj["@ID"]) < 0);

            // Update Crud Saved Filter List
            toRemoveFilters.forEach(function (rfObj) {
                let index = crud.AssignFavorites.FilterList.Filter.findIndex(fObj => fObj["@ID"] === rfObj);
                if (index === -1 && rfObj && rfObj !== "") {
                    let filterName: string = getFavoriteFilterNameById(rfObj, crud);
                    let filterObject: MCSavedFilter = {
                        '@ID': rfObj,
                        '@Name': filterName,
                    }
                    crud.AssignFavorites.FilterList.Filter.push(filterObject);
                }
            });

            // Update Crud User Favorite Filter List
            let selectedCrudUserIndex: number = crud.AssignFavorites.Users.User.findIndex(cusrObj => cusrObj["@ID"] === selectedUser.id);
            if (selectedCrudUserIndex !== -1) {
                let userIndex: number = crud.AssignFavorites.Users.User.findIndex(usrObj => usrObj["@ID"] === selectedUser.id);
                if (userIndex !== -1) {
                    if (!crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite || !(crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite instanceof Array)) {
                        crud.AssignFavorites.Users.User[userIndex].Favorites = { Favorite: [] }
                    }
                    crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite = JSON.parse(JSON.stringify((reminingFavoriteFilters)));
                }
            }
        } else {
            // Crud User Lookup
            let selectedCrudUserIndex: number = crud.AssignFavorites.Users.User.findIndex(cusrObj => cusrObj["@ID"] === selectedUser.id);
            if (selectedCrudUserIndex !== -1) {
                let userIndex = crud.AssignFavorites.Users.User.findIndex(usrObj => usrObj["@ID"] === selectedUser.id);
                if (userIndex !== -1) {
                    crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite = crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite.filter(fObj => toRemoveFilters.indexOf(fObj["@ID"]) < 0);
                    reminingFavoriteFilters = crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite;

                    // Update Crud Saved Filter List
                    toRemoveFilters.forEach(function (rfObj) {
                        let index = crud.AssignFavorites.FilterList.Filter.findIndex(fObj => fObj["@ID"] === rfObj);
                        if (index === -1 && rfObj && rfObj !== "") {
                            let filterName: string = getFavoriteFilterNameById(rfObj, crud);
                            let filterObject: MCSavedFilter = {
                                '@ID': rfObj,
                                '@Name': filterName,
                            }
                            crud.AssignFavorites.FilterList.Filter.push(filterObject);
                        }
                    });
                }
            }
        }
    } else {
        // Nothing to Remove so just pass what selected user Favorites are
        if (selectedUser && selectedUser.Favorites && selectedUser.Favorites.Favorite) {
            reminingFavoriteFilters = selectedUser.Favorites.Favorite;
        }
    }

    return reminingFavoriteFilters;
}

function removeGroupFilter(selectedGroup: ISelectFilterGroupData, toRemoveFilters: string[], crud: MCARMFilter): MCFavoriteFilter[] {

    let reminingFavoriteFilters: MCFavoriteFilter[] = [];

    if (toRemoveFilters && toRemoveFilters.length > 0) {
        // Check selected User Has any Favorites then remove them
        if (selectedGroup && selectedGroup.Favorites && selectedGroup.Favorites.Favorite && selectedGroup.Favorites.Favorite.length > 0) {

            // Remove all selected Favorite Filters
            reminingFavoriteFilters = selectedGroup.Favorites.Favorite.filter(fObj => toRemoveFilters.indexOf(fObj["@ID"]) < 0);

            // Update Crud Saved Filter List
            toRemoveFilters.forEach(function (rfObj) {
                let index = crud.AssignFavorites.FilterList.Filter.findIndex(fObj => fObj["@ID"] === rfObj);
                if (index === -1 && rfObj !== "") {
                    let filterName: string = getFavoriteFilterNameById(rfObj, crud);
                    let filterObject: MCSavedFilter = {
                        '@ID': rfObj,
                        '@Name': filterName,
                    }
                    crud.AssignFavorites.FilterList.Filter.push(filterObject);
                }
            });

            // Update Crud Group Favorite Filter List
            let selectedCrudGroupIndex: number = crud.AssignFavorites.Groups.Group.findIndex(cgrpObj => cgrpObj["@Name"] === selectedGroup.text);
            if (selectedCrudGroupIndex !== -1) {
                let groupIndex = crud.AssignFavorites.Groups.Group.findIndex(grpObj => grpObj["@Name"] === selectedGroup.text);
                
                if (groupIndex !== -1) {
                    if (!crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite || !(crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite instanceof Array)) {
                        crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite = [];
                    }

                    //crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite = reminingFavoriteFilters;
                    crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite = JSON.parse(JSON.stringify(getFavorites(reminingFavoriteFilters)));
                }
            }
        } else {
            // Crud Group Lookup
            let selectedCrudGroupIndex: number = crud.AssignFavorites.Groups.Group.findIndex(cusrObj => cusrObj["@Name"] === selectedGroup.text);
            if (selectedCrudGroupIndex !== -1) {
                let groupIndex = crud.AssignFavorites.Groups.Group.findIndex(usrObj => usrObj["@Name"] === selectedGroup.text);
                if (groupIndex !== -1) {
                    crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite = crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite.filter(fObj => toRemoveFilters.indexOf(fObj["@ID"]) < 0);

                    reminingFavoriteFilters = crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite;

                    // Update Crud Saved Filter List
                    toRemoveFilters.forEach(function (rfObj) {
                        let index = crud.AssignFavorites.FilterList.Filter.findIndex(fObj => fObj["@ID"] === rfObj);
                        if (index === -1 && rfObj && rfObj !== "") {
                            let filterName: string = getFavoriteFilterNameById(rfObj, crud);
                            let filterObject: MCSavedFilter = {
                                '@ID': rfObj,
                                '@Name': filterName,
                            }
                            crud.AssignFavorites.FilterList.Filter.push(filterObject);
                        }
                    });
                }
            }
        }
    } else {
        // Nothing to Remove so just pass what selected user Favorites are
        if (selectedGroup && selectedGroup.Favorites && selectedGroup.Favorites.Favorite) {
            reminingFavoriteFilters = selectedGroup.Favorites.Favorite;
        }
    }

    return reminingFavoriteFilters;
}

function addHomePages(filterType: FilterType, selector: any, filters: string[], crud: MCARMFilter): MCHomePageFilter[] {

    let newPages: MCHomePageFilter[] = [];

    if (filterType && filters && filters.length > 0) {
        switch (filterType) {
            case FilterType.UserFilter:
                let user = selector as ISelectFilterUserData;
                newPages = addUserHomePages(user, filters, crud);
                break;
            case FilterType.GroupFilter:
                let group = selector as ISelectFilterGroupData;
                newPages = addGroupHomePages(group, filters, crud);
                break;
        }
    }

    return newPages;
}

function addUserHomePages(selectedUser: ISelectFilterUserData, toAddPages: string[], crud: MCARMFilter): MCHomePageFilter[] {
    
    let newPages: MCHomePageFilter[] = [];

    if (selectedUser && toAddPages && toAddPages.length > 0) {

        let selectedCrudUserIndex: number = crud.AssignFavorites.Users.User.findIndex(usr => usr["@ID"] === selectedUser.id);

        if (selectedCrudUserIndex !== -1) {
            let homePagesData: any = crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages.HomePage
            if (homePagesData && homePagesData['@ID']) {
                crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages = { HomePage: [homePagesData] }
            }
            if (!crud.AssignFavorites.Users.User[selectedCrudUserIndex].Favorites.Favorite || !(crud.AssignFavorites.Users.User[selectedCrudUserIndex].Favorites.Favorite instanceof Array)) {
                crud.AssignFavorites.Users.User[selectedCrudUserIndex].Favorites = { Favorite:[] }
            }
            // Remove Favoirte Filter 
            crud.AssignFavorites.Users.User[selectedCrudUserIndex].Favorites.Favorite =
                crud.AssignFavorites.Users.User[selectedCrudUserIndex].Favorites.Favorite.filter(fav => toAddPages.indexOf(fav["@ID"]) < 0);
            if (!crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages.HomePage || !(crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages.HomePage instanceof Array)) {
                crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages = { HomePage: [] }
            }
            let crudHomePageLength = crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages.HomePage.length;
            let seqArray = crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages.HomePage.map(key => { return key["@Sequence"] });

            // Add Home Page Filter
            toAddPages.forEach(addPage => {
                let seqId = 1;
                seqArray.map(value => {
                    if (value === seqId.toString()) {
                        seqId++
                    }
                })
                seqArray.push(seqId.toString());
                let addedPageObject: MCHomePageFilter = getHomePageObject(addPage, seqId, crud);
                let index: number = crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages.HomePage.findIndex(pageObj => pageObj === addedPageObject);
                if (index === -1 && addedPageObject && addedPageObject["@ID"] !== "") {
                    crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages.HomePage.push(addedPageObject);
                }
            });
        }

        newPages = crud.AssignFavorites.Users.User[selectedCrudUserIndex].HomePages.HomePage;
    }

    return newPages;
}

function addGroupHomePages(selectedGroup: any, toAddPages: string[], crud: MCARMFilter): MCHomePageFilter[] {

    let newPages: MCHomePageFilter[] = [];

    if (selectedGroup && toAddPages && toAddPages.length > 0) {
        
        let selectedCrudGroupIndex: number = crud.AssignFavorites.Groups.Group.findIndex(grp => grp["@ID"] === selectedGroup.id);

        if (selectedCrudGroupIndex !== -1) {

            if (!crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].Favorites.Favorite || !(crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].Favorites.Favorite instanceof Array)) {
                crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].Favorites = { Favorite: [] }
            }
            // Remove Favoirte Filter 
            crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].Favorites.Favorite =
                crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].Favorites.Favorite.filter(fav => toAddPages.indexOf(fav["@ID"]) < 0);
            if (!crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].HomePages.HomePage || !(crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].HomePages.HomePage instanceof Array)) {
                crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].HomePages = { HomePage: [] }
            }
            let crudHomePageLength = crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].HomePages.HomePage.length;
            let seqArray = crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].HomePages.HomePage.map(key => { return key["@Sequence"] });
            // Add Home Page Filter
            toAddPages.forEach(addPage => {
                let seqId = 1;
                seqArray.map(value => {
                    if (value === seqId.toString()) {
                        seqId++
                    }
                })
                seqArray.push(seqId.toString());                
                let addedPageObject: MCHomePageFilter = getHomePageObject(addPage, seqId, crud);
                let index: number = crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].HomePages.HomePage.findIndex(pageObj => pageObj === addedPageObject);
                if (index === -1 && addedPageObject && addedPageObject["@ID"] !== "") {
                    crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].HomePages.HomePage.push(addedPageObject);
                }
            });
        }

        newPages = crud.AssignFavorites.Groups.Group[selectedCrudGroupIndex].HomePages.HomePage;
    }

    return newPages;
}

function removeHomePages(filterType: FilterType, selector: any, filters: string[], crud: MCARMFilter): string[] {

    let reminingHomePageFilters: string[] = [];

    if (filterType && filters && filters.length > 0) {
        switch (filterType) {
            case FilterType.UserFilter:
                let user = selector as ISelectFilterUserData;
                let userFavoriteFilters: MCFavoriteFilter[] = removeUserHomePageFavoirteFilters(user, filters, crud);
                if (userFavoriteFilters && userFavoriteFilters.length > 0) {
                    for (let index = 0; index < userFavoriteFilters.length; index++) {
                        reminingHomePageFilters.push(userFavoriteFilters[index]["@ID"]);
                    }
                }
                break;
            case FilterType.GroupFilter:
                let group = selector as ISelectFilterGroupData;
                let groupFavFilters: MCFavoriteFilter[] = removeGroupHomePageFavoirteFilters(group, filters, crud);
                if (groupFavFilters && groupFavFilters.length > 0) {
                    for (let index = 0; index < groupFavFilters.length; index++) {
                        reminingHomePageFilters.push(groupFavFilters[index]["@ID"]);
                    }
                }
                break;
        }
    }
    return reminingHomePageFilters;
}

function removeUserHomePageFavoirteFilters(selectedUser: ISelectFilterUserData, toRemoveFilters: string[], crud: MCARMFilter): MCFavoriteFilter[] {
    let favoirteFilters: MCFavoriteFilter[] = [];
    if (toRemoveFilters && toRemoveFilters.length > 0 && selectedUser) {

        let userIndex: number = crud.AssignFavorites.Users.User.findIndex(cusrObj => cusrObj["@ID"] === selectedUser.id);

        if (userIndex !== -1) {
            // Remove All selected Home Page Filter 

            if (!crud.AssignFavorites.Users.User[userIndex].HomePages.HomePage || !(crud.AssignFavorites.Users.User[userIndex].HomePages.HomePage instanceof Array)) {
                crud.AssignFavorites.Users.User[userIndex].HomePages = { HomePage: [] }
            }
            crud.AssignFavorites.Users.User[userIndex].HomePages.HomePage = crud.AssignFavorites.Users.User[userIndex].HomePages.HomePage.filter(fObj => toRemoveFilters.indexOf(fObj["@ID"]) < 0);
            let toRemoveFilterOjbcts = getFavoriteObjects(toRemoveFilters, crud);
            if (toRemoveFilterOjbcts && toRemoveFilterOjbcts.length > 0) {
                if (!crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite || !(crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite instanceof Array)) {
                    crud.AssignFavorites.Users.User[userIndex].Favorites = { Favorite: [] }
                }
                for (let index = 0; index < toRemoveFilterOjbcts.length; index++) {
                    let isExist: boolean = crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite.findIndex(fav => fav["@ID"] === toRemoveFilterOjbcts[index]["@ID"]) !== -1;
                    if (!isExist) {
                        crud.AssignFavorites.Users.User[userIndex].Favorites.Favorite.push(toRemoveFilterOjbcts[index]);
                    }
                }
                favoirteFilters = crud.AssignFavorites.Users.User[userIndex].HomePages.HomePage;
            }

        }
    }
    return favoirteFilters;
}

function removeGroupHomePageFavoirteFilters(selectedGroup: any, toRemoveFilters: string[], crud: MCARMFilter): MCFavoriteFilter[] {
    let favoirteFilters: MCFavoriteFilter[] = [];
    if (toRemoveFilters && toRemoveFilters.length > 0 && selectedGroup) {
        
        let groupIndex: number = crud.AssignFavorites.Groups.Group.findIndex(cusrObj => cusrObj["@ID"] === selectedGroup.id);

        if (groupIndex !== -1) {
            // Remove All selected Home Page Filter 
            if (!crud.AssignFavorites.Groups.Group[groupIndex].HomePages.HomePage || !(crud.AssignFavorites.Groups.Group[groupIndex].HomePages.HomePage instanceof Array)) {
                crud.AssignFavorites.Groups.Group[groupIndex].HomePages = { HomePage: [] }
            }
            crud.AssignFavorites.Groups.Group[groupIndex].HomePages.HomePage = crud.AssignFavorites.Groups.Group[groupIndex].HomePages.HomePage.filter(fObj => toRemoveFilters.indexOf(fObj["@ID"]) < 0);
            let toRemoveFilterOjbcts = getFavoriteObjects(toRemoveFilters, crud);
            if (toRemoveFilterOjbcts && toRemoveFilterOjbcts.length > 0) {
                if (!crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite || !(crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite instanceof Array)) {
                    crud.AssignFavorites.Groups.Group[groupIndex].Favorites = { Favorite:[] }
                }
                for (let index = 0; index < toRemoveFilterOjbcts.length; index++) {
                    let isExist: boolean = crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite.findIndex(fav => fav["@ID"] === toRemoveFilterOjbcts[index]["@ID"]) !== -1;
                    if (!isExist) {
                        crud.AssignFavorites.Groups.Group[groupIndex].Favorites.Favorite.push(toRemoveFilterOjbcts[index]);
                    }
                }
                favoirteFilters = crud.AssignFavorites.Groups.Group[groupIndex].HomePages.HomePage;
            }
        }
    }
    return favoirteFilters;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IFavoriteFilterUIState, KnownActions> = (state: IFavoriteFilterUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_FILTER_USER':
                {                    
                    if (action.data.masterCrud &&
                        action.data.masterCrud.AssignFavorites &&
                        action.data.masterCrud.AssignFavorites.Users &&
                        action.data.masterCrud.AssignFavorites.Users.User) {

                        let clearGroupKey = (state.clearGroup === 0 && state.selectedFilterGroup.index === -1) ? 0 : Date.now();
                        if (action.data.uiData.index !== -1) {
                            let user = action.data.masterCrud.AssignFavorites.Users.User[action.data.uiData.index];
                            return {
                                ...state,
                                filterErrorMsg: '',
                                selectedFilterUser: action.data.uiData,
                                selectedFilterGroup: JSON.parse(JSON.stringify(defaultFilterGroup)),
                                filterType: FilterType.UserFilter,
                                userName: user ? user['@Name'] || '' : '',
                                selectedAllSavedFilters: [],
                                selectedAllFavoriteFilters: [],
                                selectedAllHomePageFilters: [],
                                clearGroup: clearGroupKey
                            }
                        }
                    }
                }
                break;
            case 'SELECT_FILTER_GROUP':
                {
                    if (action.data.masterCrud &&
                        action.data.masterCrud.AssignFavorites &&
                        action.data.masterCrud.AssignFavorites.Groups &&
                        action.data.masterCrud.AssignFavorites.Groups.Group) {
                        
                        let clearUserKey = (state.clearUser === 0 && state.selectedFilterUser.index === -1) ? 0 : Date.now();
                        if (action.data.uiData.index !== -1) {
                            let group = action.data.masterCrud.AssignFavorites.Groups.Group[action.data.uiData.index!];

                            return {
                                ...state,
                                filterErrorMsg: '',
                                selectedFilterGroup: action.data.uiData,
                                selectedFilterUser: JSON.parse(JSON.stringify(defaultFilterUser)),
                                filterType: FilterType.GroupFilter,
                                groupName: group ? group['@Name'] || '' : '',
                                selectedAllFavoriteFilters: [],
                                selectedAllHomePageFilters: [],
                                selectedAllSavedFilters: [],
                                clearUser: clearUserKey
                            }
                        }
                    }
                }
                break;
            case 'SELECT_ALLSAVEDFILTERS':
                {
                    if (action.data.masterCrud &&
                        action.data.masterCrud.AssignFavorites &&
                        action.data.masterCrud.AssignFavorites.FilterList &&
                        action.data.masterCrud.AssignFavorites.FilterList.Filter) {

                        return {
                            ...state,
                            selectedAllSavedFilters: action.data.uiData.selected,
                        }
                    }
                }
                break;
            case 'ADD_FAVORITES':
                {
                    if (action.data.masterCrud &&
                        action.data.masterCrud.AssignFavorites &&
                        action.data.masterCrud.AssignFavorites.FilterList &&
                        action.data.masterCrud.AssignFavorites.FilterList.Filter) {
                        state.selectedAllSavedFilters.forEach(function (filterObj) {
                            if (filterObj && filterObj !== "") {
                                let index = state.selectedAllSavedFilters.findIndex(fObj => fObj === filterObj);
                                if (index !== -1) {
                                    state.selectedAllFavoriteFilters.push(filterObj);
                                } else {
                                    state.selectedAllSavedFilters = state.selectedAllSavedFilters.filter(fObj => fObj !== filterObj);
                                }
                            }
                        });

                        if (state.filterType) {
                            switch (state.filterType) {
                                case FilterType.UserFilter:
                                    {
                                        let newUserFilters: MCFavoriteFilter[] = addFavorites(state.filterType, state.selectedFilterUser, state.selectedAllSavedFilters, action.data.masterCrud);
                                        return {
                                            ...state,
                                            filterErrorMsg: '',
                                            selectedFilterUser: {
                                                ...state.selectedFilterUser,
                                                Favorites: {
                                                    ...state.selectedFilterUser.Favorites,
                                                    Favorite: newUserFilters
                                                }
                                            }
                                        }
                                    }
                                    break;
                                case FilterType.GroupFilter:
                                    {
                                        let newGroupFilters: MCFavoriteFilter[] = addFavorites(state.filterType, state.selectedFilterGroup, state.selectedAllSavedFilters, action.data.masterCrud);
                                        return {
                                            ...state,
                                            filterErrorMsg: '',
                                            selectedFilterGroup: {
                                                ...state.selectedFilterGroup,
                                                Favorites: {
                                                    ...state.selectedFilterGroup.Favorites,
                                                    Favorite: newGroupFilters
                                                }
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                }
                break;
            case 'REMOVE_FAVORITES':
                {
                    if (action.data.masterCrud &&
                        action.data.masterCrud.AssignFavorites &&
                        state.selectedAllFavoriteFilters &&
                        state.selectedAllFavoriteFilters.length > 0) {

                        let reminingFavoriteFilters: string[] = [];
                        let favoriteFilterObjects: MCFavoriteFilter[] = [];
                        if (state.filterType && state.selectedFilterUser) {
                            switch (state.filterType) {
                                case FilterType.UserFilter:
                                    {
                                        reminingFavoriteFilters = removeFavorites(state.filterType, state.selectedFilterUser, state.selectedAllFavoriteFilters, action.data.masterCrud);
                                        if (reminingFavoriteFilters && reminingFavoriteFilters.length > 0) {
                                            favoriteFilterObjects = getFavoriteObjects(reminingFavoriteFilters, action.data.masterCrud);
                                        }

                                        if (reminingFavoriteFilters.length > 0) {

                                            if (!state.selectedFilterUser.Favorites) {
                                                state.selectedFilterUser.Favorites = { Favorite: [] }
                                            }

                                            if (!state.selectedFilterUser.Favorites.Favorite) {
                                                state.selectedFilterUser.Favorites.Favorite = [];
                                            }

                                            state.selectedFilterUser.Favorites.Favorite = getFavoriteObjects(reminingFavoriteFilters, action.data.masterCrud);
                                        }

                                        if (reminingFavoriteFilters.length === 0 && state.selectedFilterUser) {
                                            state.selectedAllFavoriteFilters = [];
                                        }

                                        return {
                                            ...state,
                                            filterErrorMsg: '',
                                            selectedFilterUser: {
                                                ...state.selectedFilterUser,
                                                Favorites: {
                                                    ...state.selectedFilterUser.Favorites,
                                                    Favorite: favoriteFilterObjects,
                                                }
                                            }
                                        }
                                    }
                                    break;
                                case FilterType.GroupFilter:
                                    {
                                        reminingFavoriteFilters = removeFavorites(state.filterType, state.selectedFilterGroup, state.selectedAllFavoriteFilters, action.data.masterCrud);
                                        if (reminingFavoriteFilters && reminingFavoriteFilters.length > 0) {
                                            favoriteFilterObjects = getFavoriteObjects(reminingFavoriteFilters, action.data.masterCrud);
                                        }

                                        if (reminingFavoriteFilters.length > 0) {

                                            if (!state.selectedFilterGroup.Favorites) {
                                                state.selectedFilterGroup.Favorites = { Favorite: [] }
                                            }

                                            if (!state.selectedFilterGroup.Favorites.Favorite) {
                                                state.selectedFilterGroup.Favorites.Favorite = [];
                                            }

                                            state.selectedFilterGroup.Favorites.Favorite = getFavoriteObjects(reminingFavoriteFilters, action.data.masterCrud);
                                        }

                                        if (reminingFavoriteFilters.length === 0 && state.selectedFilterGroup) {
                                            state.selectedAllFavoriteFilters = [];
                                        }

                                        return {
                                            ...state,
                                            filterErrorMsg: '',
                                            selectedFilterGroup: {
                                                ...state.selectedFilterGroup,
                                                Favorites: {
                                                    ...state.selectedFilterGroup.Favorites,
                                                    Favorite: favoriteFilterObjects,
                                                }
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                }
                break;
            case 'ADD_HOMEPAGES':
                {
                    if (action.data.masterCrud &&
                        action.data.masterCrud.AssignFavorites &&
                        action.data.masterCrud.AssignFavorites.FilterList &&
                        action.data.masterCrud.AssignFavorites.FilterList.Filter) {

                        if (state.selectedAllFavoriteFilters.length < 5) {
                            state.selectedAllFavoriteFilters.forEach(function (favObj) {
                                let index = state.selectedAllHomePageFilters.findIndex(pObj => pObj === favObj);
                                if (index === -1 && favObj !== "") {
                                    state.selectedAllHomePageFilters.push(favObj);
                                } else {
                                    state.selectedAllFavoriteFilters = state.selectedAllFavoriteFilters.filter(filterObj => filterObj !== favObj);
                                }
                            });
                        }

                        switch (state.filterType) {
                            case FilterType.UserFilter:
                                {
                                    let newUserPages: MCHomePageFilter[] = addHomePages(state.filterType, state.selectedFilterUser, state.selectedAllFavoriteFilters, action.data.masterCrud);
                                    return {
                                        ...state,
                                        filterErrorMsg: '',
                                        selectedAllHomePageFilters: [],
                                        selectedFilterUser: {
                                            ...state.selectedFilterUser,
                                            HomePages: {
                                                ...state.selectedFilterUser.HomePages,
                                                HomePage: newUserPages, //JSON.parse(JSON.stringify(getHomePages(userHomePageObjects))),
                                            }
                                        }
                                    }
                                }
                                break;
                            case FilterType.GroupFilter:
                                {
                                    let newGroupPages: MCHomePageFilter[] = addHomePages(state.filterType, state.selectedFilterGroup, state.selectedAllFavoriteFilters, action.data.masterCrud);
                                    
                                    return {
                                        ...state,
                                        filterErrorMsg: '',
                                        selectedAllHomePageFilters: [],
                                        selectedFilterGroup: {
                                            ...state.selectedFilterGroup,
                                            HomePages: {
                                                ...state.selectedFilterGroup.HomePages,
                                                HomePage: newGroupPages, // getHomePageObjects(newGroupPages, action.data.masterCrud)
                                            }
                                        }
                                    }
                                }
                                break;
                        }
                    }
                }
                break;
            case 'REMOVE_HOMEPAGES':
                {
                    
                    if (action.data.masterCrud &&
                        state.selectedAllHomePageFilters.length > 0) {

                        let reminingHomePages: string[] = [];
                        let reminingFavoirteFilters: string[] = [];
                        let homePageObjects: MCHomePageFilter[] = [];
                        let favoriteFilterObjects: MCFavoriteFilter[] = [];
                       
                        if (state.filterType && state.selectedFilterUser) {
                            switch (state.filterType) {
                                case FilterType.UserFilter:
                                    {
                                       // reminingFavoirteFilters = removeHomePages(state.filterType, state.selectedFilterUser, state.selectedAllHomePageFilters, action.data.masterCrud);

                                        reminingHomePages = removeHomePages(state.filterType, state.selectedFilterUser, state.selectedAllHomePageFilters, action.data.masterCrud);

                                        if (reminingHomePages && reminingHomePages.length > 0) {
                                            homePageObjects = getHomePageObjects(reminingHomePages, action.data.masterCrud);
                                        }

                                        if (reminingHomePages.length > 0) {

                                            if (!state.selectedFilterUser.HomePages) {
                                                state.selectedFilterUser.HomePages = { HomePage: [] }
                                            }

                                            if (!state.selectedFilterUser.HomePages.HomePage) {
                                                state.selectedFilterUser.HomePages.HomePage = [];
                                            }

                                            state.selectedFilterUser.HomePages.HomePage = getHomePageObjects(reminingHomePages, action.data.masterCrud);
                                        }

                                        if (reminingHomePages.length === 0 && state.selectedFilterUser) {
                                            state.selectedAllHomePageFilters = [];
                                        }

                                        return {
                                            ...state,
                                            filterErrorMsg: '',
                                            selectedAllHomePageFilters: [],
                                            selectedFilterUser: {
                                                ...state.selectedFilterUser,
                                                HomePages: {
                                                    ...state.selectedFilterUser.HomePages,
                                                    HomePage: homePageObjects,
                                                },
                                                Favoirte: {
                                                    ...state.selectedFilterUser.Favorites,
                                                }
                                            },
                                            
                                        }
                                    }
                                    break;
                                case FilterType.GroupFilter:
                                    {
                                        reminingHomePages = removeHomePages(state.filterType, state.selectedFilterGroup, state.selectedAllHomePageFilters, action.data.masterCrud);

                                        if (reminingHomePages && reminingHomePages.length > 0) {
                                            homePageObjects = getHomePageObjects(reminingHomePages, action.data.masterCrud);
                                        }

                                        if (reminingHomePages.length > 0) {

                                            if (!state.selectedFilterGroup.HomePages) {
                                                state.selectedFilterGroup.HomePages = { HomePage: [] }
                                            }

                                            if (!state.selectedFilterGroup.HomePages.HomePage) {
                                                state.selectedFilterGroup.HomePages.HomePage = [];
                                            }

                                            state.selectedFilterGroup.HomePages.HomePage = getHomePageObjects(reminingHomePages, action.data.masterCrud);
                                        }

                                        if (reminingHomePages.length === 0 && state.selectedFilterGroup) {
                                            state.selectedAllHomePageFilters = [];
                                        }
                                        return {
                                            ...state,
                                            filterErrorMsg: '',
                                            selectedAllHomePageFilters: [],
                                            selectedFilterGroup: {
                                                ...state.selectedFilterGroup,
                                                HomePages: {
                                                    ...state.selectedFilterGroup.HomePages,
                                                    HomePage: homePageObjects,
                                                },
                                                Favoirte: {
                                                    ...state.selectedFilterGroup.Favorites,
                                                }
                                            }
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                }
                break;
            case 'SELECT_ALLFAVORITEFILTERS':
                {
                    if (action.data.masterCrud && action.data.masterCrud.AssignFavorites) {
                        if (state.filterType) {
                            switch (state.filterType) {
                                case FilterType.UserFilter:
                                    {
                                        if (state &&
                                            state.selectedFilterUser &&
                                            state.selectedFilterUser.Favorites &&
                                            state.selectedFilterUser.Favorites.Favorite) {
                                            let userFavorites: string[] = getFavorites(state.selectedFilterUser.Favorites.Favorite);
                                            if (userFavorites) {
                                                userFavorites.forEach(function (favObj) {
                                                    let index: number = state.selectedAllFavoriteFilters.findIndex(fObj => fObj === favObj);
                                                    if (index === -1 && favObj !== "") {
                                                        state.selectedAllFavoriteFilters.push(favObj);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                    break;
                                case FilterType.GroupFilter:
                                    {
                                        if (state &&
                                            state.selectedFilterGroup &&
                                            state.selectedFilterGroup.Favorites &&
                                            state.selectedFilterGroup.Favorites.Favorite) {
                                            let groupFavorites: string[] = getFavorites(state.selectedFilterGroup.Favorites.Favorite);
                                            if (groupFavorites) {
                                                groupFavorites.forEach(function (favObj) {
                                                    let index: number = state.selectedAllFavoriteFilters.findIndex(fObj => fObj === favObj);
                                                    if (index === -1 && favObj !== "") {
                                                        state.selectedAllFavoriteFilters.push(favObj);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                    break;
                            }

                            return {
                                ...state,
                                filterErrorMsg: '',
                                selectedAllFavoriteFilters: action.data.uiData.selected,
                            }
                        }
                    }
                }
                break;
            case 'SELECT_ALLHOMEPAGEFILTERS':
                {
                    if (action.data.masterCrud && action.data.masterCrud.AssignFavorites) {
                        if (state.filterType) {
                            switch (state.filterType) {
                                case FilterType.UserFilter:
                                    {
                                        if (state &&
                                            state.selectedFilterUser &&
                                            state.selectedFilterUser.HomePages &&
                                            state.selectedFilterUser.HomePages.HomePage) {
                                            let userHomePages: string[] = getHomePages(state.selectedFilterUser.HomePages.HomePage);
                                            if (userHomePages) {
                                                userHomePages.forEach(function (homeObj) {
                                                    let index = state.selectedAllHomePageFilters.findIndex(ho => ho === homeObj);
                                                    if (index === -1 && homeObj !== "") {
                                                        state.selectedAllHomePageFilters.push(homeObj);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                    break;
                                case FilterType.GroupFilter:
                                    {
                                        if (state &&
                                            state.selectedFilterGroup &&
                                            state.selectedFilterGroup.HomePages &&
                                            state.selectedFilterGroup.HomePages.HomePage) {
                                            let groupHomePages: string[] = getHomePages(state.selectedFilterGroup.HomePages.HomePage);
                                            if (groupHomePages) {
                                                groupHomePages.forEach(function (homeObj) {
                                                    let index = state.selectedAllHomePageFilters.findIndex(ho => ho === homeObj);
                                                    if (index === -1 && homeObj !== "") {
                                                        state.selectedAllHomePageFilters.push(homeObj);
                                                    }
                                                });
                                            }
                                        }
                                    }
                                    break;
                            }

                            return {
                                ...state,
                                filterErrorMsg: '',
                                selectedAllHomePageFilters: action.data.uiData.selected,
                            }
                        }
                    }
                }
                break;
            case 'MOVE_HOMEPAGES_DOWN':
            case 'MOVE_HOMEPAGES_UP':
                {
                    if (action.data.masterCrud && state.selectedAllHomePageFilters.length > 0) {
                        if (state.filterType) {
                            switch (state.filterType) {
                                case FilterType.UserFilter:
                                    {
                                        let newUserPages = moveHomePages(state.filterType,
                                            (action.type === 'MOVE_HOMEPAGES_UP'),
                                            state.selectedFilterUser, action.data.masterCrud,
                                            state.selectedAllHomePageFilters);
                                        return {
                                            ...state,
                                            filterErrorMsg: '',
                                            selectedFilterUser: {
                                                ...state.selectedFilterUser,
                                                HomePages: {
                                                    ...state.selectedFilterUser.HomePages,
                                                    HomePage: newUserPages,
                                                }
                                            }
                                        }
                                    }
                                    break;
                                case FilterType.GroupFilter:
                                    {
                                        let newGroupPages = moveHomePages(state.filterType,
                                            (action.type === 'MOVE_HOMEPAGES_UP'), state.selectedFilterGroup,
                                            action.data.masterCrud, state.selectedAllHomePageFilters);
                                        return {
                                            ...state,
                                            filterErrorMsg: '',
                                            selectedFilterGroup:
                                            {
                                                ...state.selectedFilterGroup,
                                                HomePages: {
                                                    ...state.selectedFilterGroup.HomePages,
                                                    HomePage: newGroupPages
                                                }
                                            },
                                        }
                                    }
                                    break;
                            }
                        }
                    }
                }
                break;
            case 'FILTER_ERROR':
                {
                    return {
                        ...state,
                        filterErrorMsg: action.data.uiData.value,
                    }
                }
                break;
        }
    }

    return state || defaultState;
}