import * as React from 'react';
import styled, { css }  from 'styled-components';
import { Colors } from '../../../commonResources/colorVariables';
import { Typography } from '../../../commonResources/typography';
import { displayFlex, flexRowWrap, flexRowNoWrap, flexFill, flexRigid, flexContainerWrap, flexContainerNoWrap, ARMExceptionStyling, ARMExceptionInput } from './FlexStyles';
import { DropdownButton } from 'ui-core';


//
// Quick search input elements
//
// These control styling for entering data for patient name and control number.
// There is provision for ARM Exceptions for the landing page view to reduce the size of the elements from the standard UICL versions
// to maintain the existing look and feel.
//

// Define propoerties for manipulating input styles in landing page and legacy home page versions.
interface IQuickSearchInputProps {
    inputWidth?: string;    // Permit overriding the size of the input elements for the legacy home page.
    reducedStyle?: boolean; // If true, incorporate exceptions to UICL standard sizes for the landing page.
}

// Define the styles for inclusion in both landing page and legacy home page views.
const quickSearchInput = css`
    .quickSearchInput {
        #height: 25px;
        width: 300px;
        margin-left: 3px;
        margin-right: 3px;
        width: ${(props: IQuickSearchInputProps) => props.inputWidth ? props.inputWidth : `190px`};
        border: 1px #737d85;
        border-radius: 3px;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 2px;
        margin-bottom: 2px;
        ${ARMExceptionStyling };
    };

    ${(props: IQuickSearchInputProps) => props.reducedStyle && ARMExceptionInput};

    input {
        text-transform: uppercase;
        font-size: 11px !important;
        font-weight: bold;
    }
`;

// Define styles for spacing the quick search buttons.
const quickSearchButton = css`
    .quickSearchButton {
        margin-top: 6px;
        margin-left: 2px;
        margin-right: 2px;
        margin-bottom: 6px;
        ${ARMExceptionStyling};
    };
`;

//
//
// Landing page definitions
//
//

// Parent Wrapper for entire quick search options on the landing page.
export const ARMQuickSearchOptionsBar = styled.div`
    ${flexContainerNoWrap};
    align-items: center;
    justify-content: space-between;
    width: 960px;
    height: 100%;
    padding-left: 8px;
    padding-right: 8px;
`;

// Wrapper for quick search entry elements on landing page (input/buttons); used for (flex) positioning and alignment.
export const ARMQuickSearchEntry = styled.div<IQuickSearchInputProps>`
    ${flexContainerNoWrap};
    height: 100%;
    text-align: left;
    align-items: center;
    ${quickSearchInput};
    ${quickSearchButton};
`;

// Default reduced style to true for the landing page.
ARMQuickSearchEntry.defaultProps =
{
    reducedStyle: true
}

// Wrapper for text labels for style in landing page
export const ARMQuickSearchOptionsLabel = styled.div`
    padding-left: 4px;
    padding-right: 4px;
    ${Typography.bold};
    white-space: nowrap;
`;

// Wrapper for filter options (dropdown); used for (flex) positioning and alignment.
export const ARMQuickSearchFilterOptions = styled.div`
    ${displayFlex};
    ${flexFill};
    height: 100%;
    text-align: right;
    align-items: center;
    justify-content: flex-end;
`;

// Wrapper for filter dropdown. Used to provide white background (UICL de-emphasised dropdown style uses transparent background)
export const SavedFilterDropdownWrapper = styled.div`
    background-color: ${Colors.white};
    border-radius: 3px;

    .dropdown-list {
        max-height: 350px;
        overflow-y: auto;
    }
`;

//
//
// Panel definitions
//
// The following styled component definitions lay out the quick search panels for the legacy home rendered version.
//
//

// Parent container for legacy home page rendition.
export const ARMQuickSearchOptionsPanel = styled.div`
    ${flexContainerWrap};
    position: relative;
    align-items: flex-start;
    justify-content: space-between;
    min-width: 390px;
    min-height: 236px;
    width: 390px;
    height: 300px;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    background-color: ${Colors.darkestSecondaryGrey};
    color: ${Colors.white};
`;

// Interface for generic row container with properties to adjust styling and positioning.
interface IQuickSearchPanelRowProps {
    wrap?: boolean;
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
    widthValue?: string;
    paddingTop?: string;
    paddingBottom?: string;
}

// Generic row container with properties to adjust styling and positioning.
export const ARMQuickSearchOptionsPanelRow = styled.div`
    ${(props: IQuickSearchPanelRowProps) => ((props.wrap === true) ? flexContainerWrap : flexContainerNoWrap)};
    ${flexFill};
    align-items: ${(props: IQuickSearchPanelRowProps) => props.alignItems ? props.alignItems : `center`};
    justify-content: ${(props: IQuickSearchPanelRowProps) => props.justifyContent ? props.justifyContent : `space-between`};
    ${(props: IQuickSearchPanelRowProps) => props.widthValue && `width: ${props.widthValue }`};
    ${(props: IQuickSearchPanelRowProps) => props.paddingTop && `padding-top: ${props.paddingTop}`};
    ${(props: IQuickSearchPanelRowProps) => props.paddingBottom && `padding-bottom: ${props.paddingBottom}`};
`;

// Title wrapper for style and positioning.
export const ARMQuickSearchOptionsPanelTitle = styled.div`
    ${displayFlex};
    ${flexFill};
    align-items: center;
    justify-content: center;
    ${Typography.bold};
`;

// Help button wrapper for style and positioning.
export const ARMQuickSearchOptionsPanelHelp = styled.div`
    ${displayFlex};
    ${flexRigid};
    align-items: center;
    justify-content: space-between;

    .imageButton
    {
        line-height: unset;
        padding: 0px 0px;
    }
`;

// HR styling for divider lines in legacy home page.
export const ARMQuickSearchOptionsPanelRule = styled.hr`
        width: 40%;
`;

// Styling for text labels in legacy home page.
export const ARMQuickSearchOptionsPanelLabel = styled.div`
    padding-left: 8px;
    padding-right: 8px;
    ${Typography.bold};
`;

// Container for quick search input elements to control flow and positioning of children.
export const ARMQuickSearchEntryPanel = styled.div`
    ${flexContainerWrap};
    ${flexFill};
    align-items: center;
    justify-content: center;
   ${(props: IQuickSearchInputProps) => ` ${quickSearchInput};`}
`;

// Container for quick search button elements to control flow and positioning of children.
export const ARMQuickSearchEntryPanelButtons = styled.div`
    ${flexContainerWrap};
    ${flexFill};
    align-items: center;
    justify-content: center;
    ${quickSearchButton};
`;
