import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import AssuranceMenu from '../../common/AssuranceMenu';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { connect } from 'react-redux';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { ApplicationState } from '@store/index';
import { CrudTypes } from '@commonResources/CrudTypes';
import { LeftRightListsComponent, ILeftRightContentItem } from '../../common/LeftRightListsComponent';
import { IClaimTrackTypeState, IClaimTrackTypeActionProps, actionCreators, validationCallback } from '../../../store/ClaimTrackingType';
import { handleChange, pageLeave } from '@commonResources/userModified';
import { ModalConfirmation, leaveMessage, IModalConfirmationProps } from '@common/ModalConfirmation';
import { defaultFormModalState, IFormModalState } from '../../../store/ui/BaseCrudUI';
import { bindAll } from 'lodash';

// replaces functionality of ChangeTracking.asp

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
};

interface IComponentState extends IFormModalState {
    modalProps: Partial<IModalConfirmationProps>;
};

//export const defaultState: IComponentState = { cancelLeave: false, filterList: [] };

type OwnProps = IComponentProps & RouteComponentProps<{}>;

type ClaimTrackTypeProps = IMergeCrudComponentProps<IClaimTrackTypeState, IClaimTrackTypeActionProps, OwnProps>


export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

interface IComponentProps {
    canView: boolean;
};

interface ClaimTrackingState {
    isBusy: boolean;
    isAlertOpen: boolean;
    alertMessage: string;
    filterString: string;
    modalProps: Partial<IModalConfirmationProps>;
};

interface ClaimTrackingProps { };

const defaultModalProps: Partial<IModalConfirmationProps> = {
    isOpen: false,
    showDefaultClose: false,
    alertMode: false,
};

export const DEFAULT_STATE: ClaimTrackingState = {
    ...defaultFormModalState,
    isBusy: false,
    isAlertOpen: false,
    alertMessage: '',
    filterString: '',
    modalProps: { ...defaultModalProps },
};

export class ClaimTracking extends React.Component<ClaimTrackTypeProps, IComponentState> {



    constructor(props: ClaimTrackTypeProps) {
        super(props);
        this.state = DEFAULT_STATE;

    //    console.log('Constructing ClaimTracking with following props');
    //    console.dir(props);
        bindAll(this, ['onSelectLeftItems', 'onMoveRightButtonClicked', 'onSelectRightItems', 'onMoveLeftButtonClicked',
        ]);
    }

    public componentDidMount() {
        this.props.action.crud.get({ crudId: CrudTypes.mctiClaimChangesConfig },
            (result: MCHISFieldConfiguration) => {
                // TO DO: verify that the validationCallback for the crud is working and replace it with "true"
                if (validationCallback(result)) {
                    this.props.action.ui.initalizeData({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: { rawApiReturn: result, pathname: this.props.location.pathname } ,
                    });
                    return true;
                }
            }
        );
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public buildDelta(): MCClaimTrackingDelta {
        const formTypeId = this.props.dataStore.ui.formTypeId;
        const fieldRecords = this.props.dataStore.ui.fieldRecords.filter(i => i.wasTracked !== i.tracked);
        const Change: MCClaimTrackingChangesDelta[] = fieldRecords.map(i => {
            if (i.tracked) {
                return { '@ID': i.id, '@FormTypeID': formTypeId }
            } else {
                return { '@ID': i.id, '@FormTypeID': formTypeId, '@Delete': 'true' }
            }
        })
        const ClaimChangesMaintenanceInfo: MCClaimChangesMaintenanceDelta = {
            Changes: { Change }
        }
        return { ClaimChangesMaintenanceInfo }
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        const isDirty: boolean = this.props?.dataStore?.ui?.isDirty ?? false;
        if (isDirty) {
            const delta = this.buildDelta();
            this.props.action.crud.update({ crudId: CrudTypes.mctiClaimChangesConfig, data: delta });
        }
        this.props.history.push("/LandingPage");
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        const isDirty: boolean = this.props?.dataStore?.ui?.isDirty ?? false;
        if (isDirty) {
            this.props.action.confirm.openConfirm({ message: '' });
        }
        else
            this.props.history.push('/LandingPage');
    }

    // closes are you sure you want to leave without saving and navigates
    public onModalOk = () => {
        this.props.history.push('/LandingPage');
    }

    // closes internal modal
    public onModalClose = () => {
        this.setState({ modalProps: { ...defaultModalProps } });
    }

    onSelectLeftItems(items: ILeftRightContentItem[]): void {
        this.props.action.ui.selectLeftItems(items);
    }

    onMoveRightButtonClicked(): void {
        this.props.action.ui.moveSelectionToRight();
        handleChange();
    }

    onSelectRightItems(items: ILeftRightContentItem[]): void {
        this.props.action.ui.selectRightItems(items);
    }

    onMoveLeftButtonClicked(): void {
        this.props.action.ui.moveSelectionToLeft();
        handleChange();
    }

    instructions = <React.Fragment>Hold down CTRL and click on each field you want to monitor. Click the &gt; button, then click OK. The Claim Changes Summary and Claim Changes History reports will show changes to the fields you select.</React.Fragment>;
    okCancelButtons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

    public render() {
        //console.log(this.props, " -----> this.props.dataStore");
        //console.dir(this.props.dataStore.ui.fieldRecords);
        const formName = this.props?.dataStore?.ui?.formTypeName ? ' >> ' + this.props.dataStore.ui.formTypeName : '';
        const title = 'Claim Tracking' + formName
        const fieldRecords = this.props.dataStore.ui.fieldRecords
        const leftContents: ILeftRightContentItem[] = fieldRecords.filter(i => i.tracked === false).map(i => {
            return { id: i.id, text: i.text, selected: i.selected }
        })
        const rightContents: ILeftRightContentItem[] = fieldRecords.filter(i => i.tracked === true).map(i => {
            return { id: i.id, text: i.text, selected: i.selected }
        })
        const { openConfirm, closeConfirm } = this.props.action.confirm;
        let modalProps = this.state.modalProps;

        return (
            <DialogWrapper title={title}
                instruction={this.instructions}
                helpUrl='/Support/Help/HELP_ClaimChanges.htm'
                buttons={this.okCancelButtons}
                isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'} >
                <ContentWrapper>
                    <LeftRightListsComponent
                        className='FieldsComponent'
                        leftTitle='Untracked Fields'
                        leftContents={leftContents}
                        rightTitle='Tracked Fields'
                        rightContents={rightContents}
                        allowMovement={true}
                        sideHeight='400px'
                        sideWidth='300px'
                        onSelectLeftItems={(items: ILeftRightContentItem[]) => this.onSelectLeftItems(items)}
                        onMoveRightButtonClickedCallback={this.onMoveRightButtonClicked}
                        onSelectRightItems={(items: ILeftRightContentItem[]) => this.onSelectRightItems(items)}
                        onMoveLeftButtonClickedCallback={this.onMoveLeftButtonClicked}
                    />
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={closeConfirm}
                    formattedMessage={leaveMessage}
                    okText='Leave'
                    cancelText='Stay'
                    onConfirm={this.onModalOk}
                />
                <ModalConfirmation
                    {...modalProps}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IClaimTrackTypeState, IClaimTrackTypeActionProps, OwnProps, ClaimTrackTypeProps, ApplicationState>(
    createCrudMapStateToProps('claimTrackingType'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ClaimTracking);

export default withRouter(connectedHoc);
