// Reference: https://patrickdesjardins.com/blog/how-to-create-a-typed-redux-middleware-in-typescript
// Updated for Redux 4.0 typings.

import { fetch, addTask } from 'domain-task';
import { Middleware, MiddlewareAPI, Dispatch, Action, AnyAction } from "redux";
import { IApiAction, createApiStatusAction, TApiStatus } from "../util/ActionHelpers";


export const ApiMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch<AnyAction>) =>
        async <A extends IApiAction<string, any>>(action: A) => {
            const result = next(action);
            //console.log(action.type);
            if (action.status) {
                switch (action.status.status) {
                    case "VALIDATE":
                        {
                            let status: TApiStatus = { ...action.status };
                            if (action.validateData && !action.validateData()) {
                                status.status = "FAIL";
                                api.dispatch(createApiStatusAction(action, status, data));
                            }
                            else {
                                status.status = "REQUEST";
                                api.dispatch(createApiStatusAction(action, status, data));
                            }
                        }
                        break;
                    case "REQUEST":
                        {
                        // TODO: Add handlers for errors. Ref? https://www.tjvantoll.com/2015/09/13/fetch-and-errors/
                        // TODO: Make this module hot-loadable.
                        // TODO: Add timeout handler?
                        const fetchTask = fetch(action.url, action.requestData);
                        addTask(fetchTask); // Ensure server-side prerendering waits for this to complete. More info: https://github.com/aspnet/JavaScriptServices/issues/1187
                        const response = await fetchTask;
                        // Question on performance - which is faster, async await on json() or async await on text() with try JSON.parse(), no check on content-type?
                        // JSON.parse is synchronous, so this could be a factor, but we're already in an async function, too. Example below.
                        // const text = await response.text();
                        // try { const json = JSON.parse(text); data = json; } catch(e) { data = text; }
                        const contentType = response.headers.get("content-type");
                        var data = null;
                        if (contentType && contentType.startsWith('application/json')) {
                            data = await response.json();
                        } else {
                            data = await response.text();
                        }
                            if (action.responseHandler) {
                                action.responseHandler(api, action, response, data);
                            }
                        let status: TApiStatus = {
                            status: "FAIL",
                            responseCode: response.status as number
                        };
                        if  (response.ok &&
                            (action.validationCallback == undefined || (action.validationCallback && action.validationCallback(data)))) {
                            status.status = "SUCCESS";
                        }
                            if (response.ok === false && action.errorCallback) {
                            action.errorCallback((data && data.message) ? data.message : "Unknown error occurred.");
                        }

                        api.dispatch(createApiStatusAction(action, status, data));
                       
                        }
                        break;
                    case "SUCCESS":
                        break;
                    case "FAIL":
                        break;
                    default:
                        const exhaustiveCheck = (action.status.status) as never;
                }
            }
            // Possible here to do additional post-dispatch items, using functions such as api.getState()
            return result;
        };