import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ConfigureSubmitterUI from './ui/ConfigureSubmitterUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IConfigureSubmitterState = ICrudComponentState<ConfigureSubmitterInfoType, ConfigureSubmitterUI.IConfigureSubmitterUIState>;
export const actionCreators = createCrudComponentActions<ConfigureSubmitterUI.ActionCreators>(ConfigureSubmitterUI.actionCreators);
export type IConfigureSubmitterActions = typeof actionCreators;
export type IConfigureSubmitterActionProps = ICrudComponentActionProps<IConfigureSubmitterActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiConfigureSubmitter, ConfigureSubmitterUI.reducer);
export const reducer = combineReducers<IConfigureSubmitterState>(reducers);

export interface IConfigureSubmitterResponse {
    status: number,
    data?: string,
}

export const validationCallback: ValidationCallback<ConfigureSubmitterInfoType> = (crud: any): crud is ConfigureSubmitterInfoType => {
    let getCrud: ConfigureSubmitterInfoType = crud as ConfigureSubmitterInfoType;
    let validShape: boolean = ValidateJSONSchema(getCrud, "MCFileTypeSettingsMaintenanceInfo");
    if (validShape) {
        VerifyArray(getCrud?.ConfigureSubmitter?.ProductProfileList, "ProductProfile");
        VerifyArray(getCrud?.ConfigureSubmitter?.SDRTypeList, "SDRType");
    }
    return validShape;
}