import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { DialogWrapper, DeleteCancelButtons } from '@common/DialogWrapper';
import { SelectGroupComponent } from '@common/SelectGroupComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IDeleteFilterState, IDeleteFilterActionProps, actionCreators } from '@store/DeleteFilter';
import { URLs } from '@commonDevResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

interface MCOption {
    'value': string;
    'label': string;
};

interface MCOptionGroup {
    'label': string; 
    'items': MCOption[];
};

interface MCAllFilters {
    filterList: MCOptionGroup[];
}

interface IComponentProps {
    canDelete: boolean; // SecurityBits.FN_DELETE_FILTERS
    ncsIsEnabled: boolean,
    ncsSkipPrompt: boolean,
    ncsPromptText: string,
    ncsListType: string,
};

interface IComponentState {
};

export const DEFAULT_STATE: IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IDeleteFilterProps = IMergeCrudComponentProps<IDeleteFilterState, IDeleteFilterActionProps, IOwnProps>;

export interface ISingleSelectGroupComponentProps {
    filters: Array<any>;
    onFilterSelect?: any;
};

export interface IDeleteFilterContentProps {
    singleSelectGroup: ISingleSelectGroupComponentProps;
    onFilterSelect?: any;
};

export class DeleteFilter extends React.Component<IDeleteFilterProps, IComponentState> {

    protected dataForDeleteFilters: any;
    protected allFilters: MCAllFilters;
    protected advClaimFilters: MCOption[];
    protected payerFilters: MCOption[];
    protected remitFilters: MCOption[];
    protected eligibilityFilters: MCOption[];
    protected selectedFilterIndex: number;
    protected selectedFilterID: string;
    protected selectedFilterName: string;

    readonly filterType_ACF: string = 'Advanced Claim Filter';
    readonly filterType_PSF: string = 'Payer Status Filter';
    readonly filterType_RF: string = 'Remit Filter';
    readonly filterType_EF: string = 'Eligibility Filter';

    readonly NoFilterSelection: string = 'Please select a saved filter.';
    readonly FilterQuestion: string = 'Are you sure that you want to delete ';

    static defaultProps: IComponentProps = {
        canDelete: false,
        ncsIsEnabled: false,
        ncsSkipPrompt: false,
        ncsPromptText: "",
        ncsListType: "",
    };

    constructor(props: IDeleteFilterProps) {
        super(props);
        this.dataForDeleteFilters = '';
        this.allFilters = { filterList: [] };
        this.advClaimFilters = [];
        this.payerFilters = [];
        this.remitFilters = [];
        this.eligibilityFilters = [];
        this.selectedFilterIndex = -1;
        this.selectedFilterID = '';
        this.selectedFilterName = '';
        this.state = DEFAULT_STATE;

        this.loadClaimFiltersFromServer();
    }

    loadClaimFiltersFromServer() {
        var clientUrl = URLs.api + '/api/data/GetClaimFilters';
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    this.dataForDeleteFilters = JSON.parse(responseData);
                } else {
                    console.warn(xhr.status + " response handling " + clientUrl);
                    this.setState({ errorCode: xhr.status });
                }
            }
        };
        xhr.open('get', clientUrl, false);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();

        this.buildClaimFilterList();
    }

    isFilterAssigned(filterId: string): boolean {
        let isAssigned: boolean = false;
        if (this.dataForDeleteFilters instanceof Array) {
            let filters = this.dataForDeleteFilters[0].FilterList;
            if (this.dataForDeleteFilters[0].FilterList && filters.length > 0) {
                let filterIndex: number = this.dataForDeleteFilters[0].FilterList.findIndex((flt: { Id: string; }) => flt.Id === filterId);
                if (filterIndex !== -1) {
                    isAssigned = (this.dataForDeleteFilters[0].FilterList[filterIndex]['FilterAssigned'] === '1');
                }
            }
        }
        return isAssigned;
    }

    buildClaimFilterList() {
        if (this.dataForDeleteFilters instanceof Array && 
                this.dataForDeleteFilters.length > 0 ) {
            let filters = this.dataForDeleteFilters[0].FilterList;
            if (this.dataForDeleteFilters[0].FilterList && filters.length > 0) {
                filters.map((listItem: any) => {
                    let filterInfo: MCOption = { 'value': listItem.Id, 'label': listItem.Name };
                    
                    if (this.advClaimFilters && listItem.FilterType === 'C') {
                        this.advClaimFilters.push(filterInfo);
                    }

                    if (this.payerFilters && listItem.FilterType === 'S') {
                        this.payerFilters.push(filterInfo);
                    }

                    if (this.remitFilters && listItem.FilterType === 'R') {
                        this.remitFilters.push(filterInfo);
                    }

                    if (this.eligibilityFilters && listItem.FilterType === 'E') {
                            this.eligibilityFilters.push(filterInfo);
                    }
                });

                if (this.advClaimFilters && this.advClaimFilters.length > 0) {
                    this.allFilters.filterList.push({ label: this.filterType_ACF, items: this.advClaimFilters });
                }
                if (this.advClaimFilters && this.payerFilters.length > 0) {
                    this.allFilters.filterList.push({ label: this.filterType_PSF, items: this.payerFilters });
                }
                if (this.advClaimFilters && this.remitFilters.length > 0) {
                    this.allFilters.filterList.push({ label: this.filterType_RF, items: this.remitFilters });
                }
                if (this.advClaimFilters && this.eligibilityFilters.length > 0) {
                    this.allFilters.filterList.push({ label: this.filterType_EF, items: this.eligibilityFilters });
                }
            }
        }
    }

    public buildClaimFilterRecords(): MCAllFilters {
        return this.allFilters;
    }

    public onSelectFilter(e: React.ChangeEvent<HTMLSelectElement>) {
        this.selectedFilterID = e.target.value;
        this.selectedFilterIndex = e.target.options.selectedIndex;
        if (e.target.options && e.target.options.length > 0 &&
            e.target.options[e.target.options.selectedIndex] !== null &&
            e.target.options[e.target.options.selectedIndex].text &&
            e.target.options.selectedIndex !== -1) {
            this.selectedFilterName = e.target.options[e.target.options.selectedIndex].text;
        }

        this.props.action.ui.selectFilter(
        {
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                index: e.target.options.selectedIndex,
                value: e.target.value,
                text: e.target.options[e.target.options.selectedIndex].textContent
            }
        });
    }

    async deleteClaimFiltersFromNetworkedClients() {
        // TBD: SYS_DELETECLAIMFILTER = 34, we need to implement SYS_DELETEREMITFILTER = 35 when that page is done... (should be here??)
        // so, after the remit filters are added, the numbers 34 and 35 shouldn't be hard coded (like it is below)...
        // and after the remit filters are added, the Type has to be Remit or Claim, not hard coded...
        let filterXml = '<Filter ID="' + this.selectedFilterID + '" Name="' + this.selectedFilterName + '" Type="Claim" />';
        console.log('deleteClaimFiltersFromNetworkedClients: [' + filterXml + ']');
        var clientUrl = URLs.api + '/api/data/InsertFilterNCS?ncsId=34'; 
        await fetch(clientUrl, {
            method: 'POST',
            headers: {
                'Accept': 'application/xml',
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `${getRawToken()}`
            },
            body: filterXml
        }).then(response => {
            if (response.status === 200) {
                console.log('deleted filters from networked clients');
                this.deleteProcess(this.selectedFilterID, this.selectedFilterName);
            }
        })
            .catch(error => {
                console.log("Unable to Delete Claim Filter: " + error);
            });
    }

    public onSubmitNCS(e: any) {
        console.log('onSubmitNCS::DeleteFilter');
        // debuke says: needed? check autolist: this.onAlert("");
        this.deleteClaimFiltersFromNetworkedClients();
        //this.props.history.push('/LandingPage');
    }

    public onClearNCS(e: any) {
        console.log('onClearNCS::DeleteFilter');
        this.props.action.ui.sendSubmitNCSAlert({ uiData: { value: '' } });
    }

    public onDenyNCS(e: any) {
        console.log('onDenyNCS::DeleteFilter');
        this.deleteProcess(this.selectedFilterID, this.selectedFilterName);
        this.props.action.ui.sendSubmitNCSAlert({ uiData: { value: '' } });
    }

    async deleteProcess(filterId: string, filter: string) {
        var clientUrl = URLs.api + '/api/data/DeleteClaimFilter?filterId=' + filterId + '&filter=' + filter;
        console.log(clientUrl);
        await fetch(clientUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
        }).then(response => {
            if (response.status === 200) {
                this.props.history.push('/LandingPage');
            }
        })
        .catch(error => {
            console.log("Unable to Delete Claim Filter: " + error);
         });
    }


    public deleteClaimFiltersFromServer(filterId: string, filter: string)
    {
        if (this.props.ncsIsEnabled) {
            this.props.action.ui.sendSubmitNCSAlert({ uiData: { value: this.props.ncsPromptText } });
        } else {
            this.deleteProcess(filterId, filter);
        }
    }

    public onOKSubmitDeleteFilter(e: React.ChangeEvent<HTMLButtonElement>) {
        if (this.selectedFilterIndex !== -1) {
            if (this.selectedFilterID !== '' && this.selectedFilterName !== '') {
                console.log("Selected Filter:[" + this.selectedFilterName + "] with Id:[" + this.selectedFilterID +  "]");
                let isFilterAssigned: boolean = this.isFilterAssigned(this.selectedFilterID);
                console.log(this.selectedFilterName + "[" + this.selectedFilterID + "] has Assignment: [" + isFilterAssigned + "]");
                if (isFilterAssigned) {
                    this.props.action.confirm.openConfirm(this.FilterQuestion +
                        this.selectedFilterName + ' ? The filter ' +
                        this.selectedFilterName + ' has Assignments.', () => this.deleteClaimFiltersFromServer(this.selectedFilterID, this.selectedFilterName));
                } else {
                    this.props.action.confirm.openConfirm(this.FilterQuestion + 
                        this.selectedFilterName + ' ?', () => this.deleteClaimFiltersFromServer(this.selectedFilterID, this.selectedFilterName));
                }
            }
        } else {
            this.props.action.confirm.openConfirm(this.NoFilterSelection);
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public render() {
        var width: React.CSSProperties = {
            width: '300px'
        }
        var instruction = <React.Fragment>Use this page to remove stored filters that you no longer need. Remember that deleting filters used by auto-modules will cause the auto-modules to fail.</React.Fragment>;
        var buttons = <DeleteCancelButtons disableOK={!this.props.canDelete || !this.dataForDeleteFilters || (this.dataForDeleteFilters && this.dataForDeleteFilters.length == 0)} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKSubmitDeleteFilter(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        var data: any = this.buildClaimFilterRecords();
        let okText = "Yes";
        let cancelText = "No";
        
        return (
            <DialogWrapper title="Delete Claim Filter" instruction={instruction} helpUrl='/Support/Help/HELP_FilterDelete.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                <ContentWrapper>
                    <SelectList>
                        <SelectGroupComponent
                            size={20}
                            width='350px'
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFilter(e)}
                            optionGroups={{
                                label: "value",
                                items: [{
                                    value: "value",
                                    text: "text",
                                }]
                            }}
                            records={data.filterList}
                        >
                        </SelectGroupComponent>
                    </SelectList>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.submitNCSAlert && this.props.dataStore.ui.submitNCSAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearNCS(e)}
                    formattedMessage={(<div>
                        <p>Do you want to remove any filter with the same name from all enterprise sites?</p>
                        <p>Note: Filters will not be removed from any site where the filter is being used by an Auto-Module.</p>
                    </div>)}
                    okText={okText}
                    cancelText={cancelText}
                    showDefaultClose={true}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onSubmitNCS(e)}
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => this.onDenyNCS(e)}
                />
                <ModalConfirmation
                    isOpen={this.props.dataStore.confirm.isOpen}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}
                />
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IDeleteFilterState, IDeleteFilterActionProps, IOwnProps, IDeleteFilterProps, ApplicationState>(
    createCrudMapStateToProps('deleteFilter'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(DeleteFilter);

export default withRouter(connectedHoc);
