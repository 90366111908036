import { Location } from 'history';

export interface IFormUIState {
    isDirty: boolean;
}

// TODO: Try to figure out how to just make this prop optional on IFormUIState
export interface ICanHazErrors <T> {
    errors?: T | undefined;
}

export interface IFormModalState {
    navigationConfirmed?: boolean;
    saveOnNavigate?: boolean;
    navDestination?: Location;
}

export const defaultFormModalState:IFormModalState = {
    navigationConfirmed: false,
    saveOnNavigate: false,
    navDestination: undefined
}

export const defaultFormState: IFormUIState = {
    isDirty: false
};

export const defaultModalState = {
    showModal: false,
}

