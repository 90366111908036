import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Help, Checkbox } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonOK, DialogButtonCancel } from '@common/DialogWrapper';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IHoldCodeProcessingState, IHoldCodeProcessingActionProps, actionCreators } from '@store/HoldCodeProcessing';
import { Input } from '../../common/UICLWrappers/ARMInput';

export const DialogCaptionHelp = styled.div`
                ${Typography.ARMFontFamily};
                ${Typography.bold};
                ${Typography.large};
                ${Typography.defaultLineHeight};
                color: ${Colors.white};
                width:786px;
                padding: 0px;
                text-align: right;
                justify-content:flex-end;
                .help-button {
                                padding: 0px;
                                padding-top: 10px;
                                padding-right: 10px; 
                }
`;

export const InputWrapper = styled.div`
                margin-left: 5px;
                margin-right: 5px;
                #dialog-container>div>div{
                    margin-left:auto;
                }
               div[class*='DialogInstruction-'] 
                { width:1022px;
                    }
                
`;
export const LabelWrapper = styled.label`
                   display: block;
                    margin: 0.1rem;
                    margin-bottom: 0.3rem;
                    color: #37474F;
                    font-size: 12px;
                    line-height: 1.4em;
                    font-weight: 600;
                    letter-spacing: 0.5px;
                    
}
`;
export const LabelWrapperExtended = styled(LabelWrapper)`
                    text-transform: uppercase;
                    font-size: 12px;
}
`;

export const ContentWrapper = styled.div`
                display: flex;
                flex:1 1 auto;
                flex-direction: column;
                width:1015px;
                align-items:center;
                 
`;
export const ContentRowWrapper = styled.div`
                display: flex;
                flex:1 1 auto;
                flex-direction: row;
                margin:5px;
`;

export const ButtonWrapper = styled.div`
                display: flex;
                flex:1 1 auto;
                flex-direction: row;
                justify-content:flex-end;
`;


export const FormRow = styled.div`
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                margin:8px;
`;

interface IComponentProps {
    canView: boolean,
    process: string,
    parameter: string,
    apiType: string
    title:string,

};

interface IComponentState {

};


export const DEFAULT_STATE: IComponentState = {

};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IHoldCodeProcessingProps = IMergeCrudComponentProps<IHoldCodeProcessingState, IHoldCodeProcessingActionProps, IOwnProps>;



export class HoldCodeProcessing extends React.Component<IHoldCodeProcessingProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canView: false,
        process: "holdcodeprocessing",
        parameter: '',
        apiType: "ClaimFilter",
        title: "Hold Code Processing",
    };

    constructor(props: IHoldCodeProcessingProps) {
        super(props);


    }

    public componentDidMount() {
        if (!this.props.canView) 
         this.props.history.push("/LandingPage");
    }


    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onOKViewResponse(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }


    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }


    public render() {
        var width: React.CSSProperties = {
            width: '300px'
        }

        var instruction = <React.Fragment>Select an action to change the Hold Code, Hold Note, or both fields.Type a new value if you want to Overwrite or Add information.Then, click OK to use the filter to target  <br />the working group of claims for the action.<br /></React.Fragment>;
        let okButtonDisable = !(
            ((this.props.dataStore.ui.addCodeChecked || this.props.dataStore.ui.overwriteCodeChecked && this.props.dataStore.ui.holdCode != '')
                         || this.props.dataStore.ui.clearCodeChecked) ||
            ((this.props.dataStore.ui.addNoteChecked || this.props.dataStore.ui.overwriteNoteChecked && this.props.dataStore.ui.holdNote != '')
            || this.props.dataStore.ui.clearNoteChecked)
           );

        var buttons = <ButtonWrapper>

            <DialogButtonCancel onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />
            <DialogButtonOK disable={okButtonDisable} onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} />
            
        </ButtonWrapper>;

    return (

            <InputWrapper>
            <DialogWrapper title="Hold Code Processing" instruction={instruction} helpUrl='/Support/Help/HoldCodeProcessing.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                    <ContentWrapper>
                        <FormRow>
                            <LabelWrapperExtended>Hold Code </LabelWrapperExtended>
                            <Input
                                className='margin-padding'
                                domID="holdCode"

                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateHoldCode(e.target.value)}
                                disabled={this.props.dataStore.ui.codeOperation === 'C'}
                                initialValue={this.props.dataStore.ui.holdCode}
                                maxLength={4}
                            />
                            <ContentRowWrapper>
                                <Checkbox
                                    id="clear"
                                    name="Clear"
                                    value={this.props.dataStore.ui.clearCodeChecked}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.props.action.ui.updateCodeOperation({ code: 'C', checked: e.target.checked });
                                        console.log(e.target.value);
                                        this.props.action.ui.updateClearCodeChecked(e.target.checked);
                                    }} />
                                <LabelWrapper>Clear</LabelWrapper>

                                <Checkbox
                                    id="overwrite"
                                    name="Overwrite"
                                    value={this.props.dataStore.ui.overwriteCodeChecked}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.props.action.ui.updateCodeOperation({code:'O', checked: e.target.checked });
                                this.props.action.ui.updateOverwriteCodeChecked(e.target.checked);
                            }}
                        />
                                <LabelWrapper>Overwrite</LabelWrapper>
                                <Checkbox
                                    id="add"
                                    name="Add"
                                    value={this.props.dataStore.ui.addCodeChecked}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.props.action.ui.updateCodeOperation({code:'A', checked: e.target.checked });
                                this.props.action.ui.updateAddCodeChecked(e.target.checked);
                            }}
                        />
                                <LabelWrapper>Add</LabelWrapper>
                            </ContentRowWrapper>
                        </FormRow>
                        <FormRow>
                            <LabelWrapperExtended>Hold Note </LabelWrapperExtended>
                            <Input
                                className='margin-padding'
                                domID="holdNote"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateHoldNote(e.target.value)}
                                initialValue={this.props.dataStore.ui.holdNote}
                                disabled={this.props.dataStore.ui.noteOperation === 'C'}
                                maxLength={80}
                            />
                            <ContentRowWrapper>
                                <Checkbox
                                    id="nclear"
                                    label="Clear"
                                    value={this.props.dataStore.ui.clearNoteChecked}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.props.action.ui.updateNoteOperation({ code: 'C', checked: e.target.checked });
                                        this.props.action.ui.updateClearNoteChecked(e.target.checked);
                                    }} />
                                <LabelWrapper>Clear</LabelWrapper>
                                <Checkbox
                                    id="nOverwrite"
                                    label="Overwrite"
                                    value={this.props.dataStore.ui.overwriteNoteChecked}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.props.action.ui.updateNoteOperation({code:'O', checked: e.target.checked })
                                console.log(e.target.checked);
                                this.props.action.ui.updateOverwriteNoteChecked(e.target.checked);
                            }}
                                /> <LabelWrapper>Overwrite</LabelWrapper>
                                
                                <Checkbox
                                    id="add"
                                    name="Add"
                                    value={this.props.dataStore.ui.addNoteChecked}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.props.action.ui.updateNoteOperation({code:'A', checked: e.target.checked })
                                this.props.action.ui.updateAddNoteChecked(e.target.checked);
                            }} />
                                <LabelWrapper>Add</LabelWrapper>
                            </ContentRowWrapper>
                        </FormRow>

                    </ContentWrapper>
                    <ModalConfirmation
                        isOpen={this.props.dataStore?.confirm.isOpen}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                        message={this.props.dataStore?.confirm.message}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}
                    />
                </DialogWrapper>
            </InputWrapper>
        );
    }
            
    onOK(e: React.ChangeEvent<HTMLButtonElement>): void {
       
        var delim = ':param:';
        let parameterValue = '';

        parameterValue = delim + this.props.dataStore.ui.codeOperation + delim +  ( this.props.dataStore.ui.holdCode || ' ') 
            + delim + this.props.dataStore.ui.noteOperation + delim + (this.props.dataStore.ui.holdNote || ' ').toUpperCase() + delim; 
        console.log(parameterValue);
        this.props.history.push('/Claims/Filter/HoldCodeProcessing', {
            process: this.props.process,
            parameter: parameterValue,
            title: this.props.title
            ,
        });
    }
    onCancel(e: React.ChangeEvent<HTMLButtonElement>): void {
        this.props.history.push('/LandingPage');
    }
};

var connectedHoc = connect<IHoldCodeProcessingState, IHoldCodeProcessingActionProps, IOwnProps, IHoldCodeProcessingProps, ApplicationState>(
    createCrudMapStateToProps('holdCodeProcessing'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(HoldCodeProcessing);

export default withRouter(connectedHoc);
