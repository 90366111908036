import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, createAction, ActionTypes } from '@scripts/util/ActionHelpers';

export interface IRunBridgeRoutinesUIState {
    Rtn307Checked: boolean;
    Rtn327Checked: boolean;
    Rtn328Checked: boolean;
    Rtn329Checked: boolean;
    Rtn330Checked: boolean;
    ValidateChecked: boolean;
    errorPopup: boolean;
}

// From ActionTypes, Actioneators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IRunBridgeRoutinesUIState = {
    Rtn307Checked: false,
    Rtn327Checked: false,
    Rtn328Checked: false,
    Rtn329Checked: false,
    Rtn330Checked: false,
    ValidateChecked: false,
    errorPopup: false,
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    updateRtn307: (checked: boolean) => createDataAction('UPDATE_RTN_307', checked),
    updateRtn327: (checked: boolean) => createDataAction('UPDATE_RTN_327', checked),
    updateRtn328: (checked: boolean) => createDataAction('UPDATE_RTN_328', checked),
    updateRtn329: (checked: boolean) => createDataAction('UPDATE_RTN_329', checked),
    updateRtn330: (checked: boolean) => createDataAction('UPDATE_RTN_330', checked),
    updateValidate: (checked: boolean) => createDataAction('UPDATE_VALIDATE', checked),
    errorPopup: (res: boolean) => createDataAction('ERROR_POPUP', res),
};


// ----------------
// REDUCER - For a given state and actio returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IRunBridgeRoutinesUIState, KnownActions> = (state: IRunBridgeRoutinesUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'UPDATE_RTN_307':
                return {
                    ...state,
                    Rtn307Checked: action.data,
                }
                break;
            case 'UPDATE_RTN_327':
                return {
                    ...state,
                    Rtn327Checked: action.data,
                }
                break;
            case 'UPDATE_RTN_328':
                return {
                    ...state,
                    Rtn328Checked: action.data,
                }
                break;
            case 'UPDATE_RTN_329':
                return {
                    ...state,
                    Rtn329Checked: action.data,
                }
                break;
            case 'UPDATE_RTN_330':
                return {
                    ...state,
                    Rtn330Checked: action.data,
                }
                break;
            case 'UPDATE_VALIDATE':
                return {
                    ...state,
                    ValidateChecked: action.data,
                }
                break;
            case 'ERROR_POPUP':
                return {
                    ...state,
                    errorPopup: action.data,
                }
                break;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                return state;
        }
    }

    return state || defaultState;
}
