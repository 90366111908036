import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ISpinOffUIState {
    FromFormId: string; 
    ToFormId: string; 
    PostFunc: string;
    IsLoading: boolean;
}

export interface ISpinOffUIData {
    value: boolean;
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
interface ISelectSpinOffData {
    Id: string;
    Name: string;
    Used: string;
};

export const defaultNote: ISelectSpinOffData = {
    Id: '',
    Name: '',
    Used: '',
};

interface IClickOKData {
    goToFilter: boolean;
};

export const defaultClickOK: IClickOKData = {
    goToFilter: false
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: ISpinOffUIState = {
    FromFormId: "",
    ToFormId: "",
    PostFunc: "",
    IsLoading: false
};

export interface ISelectSpinOff extends IApiActionData<SOFormType, ISelectSpinOffData> { }

export interface ISO_FieldData extends IApiActionData<APICF_ListPopulationContainer, ISpinOffUIData> { }

export const actionCreators = {
    selectFormType: (selectInfo: ISelectSpinOff) => createDataAction('SELECT_FROM_TYPE', selectInfo), 
    selectToType: (selectInfo: ISelectSpinOff) => createDataAction('SELECT_TO_TYPE', selectInfo),
    selectPostFunc: (selectInfo: ISelectSpinOff) => createDataAction('SELECT_POST_FUNC', selectInfo),
    setBusy: (fieldInfo: ISO_FieldData) => createDataAction('SET_BUSY', fieldInfo),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ISpinOffUIState, KnownActions> = (state: ISpinOffUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {

            case 'SELECT_FROM_TYPE':
                let newStateDataFromType: ISpinOffUIState = { ...state };
                newStateDataFromType.FromFormId = action.data.uiData.Id;
                return newStateDataFromType;
            case 'SELECT_TO_TYPE':
                let newStateDataToType: ISpinOffUIState = { ...state };
                newStateDataToType.ToFormId = action.data.uiData.Id;
                return newStateDataToType;
            case 'SELECT_POST_FUNC':
                let newStateDataPostFunc: ISpinOffUIState = { ...state };
                newStateDataPostFunc.PostFunc = action.data.uiData.Name;
                return newStateDataPostFunc;

            case 'SET_BUSY':
                return {
                    ...state,
                    isBusy: action.data.uiData.value,
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}

