import { combineReducers, Reducer } from 'redux';
import {isPlainObject, has} from 'lodash';
import * as BackfeedConfigUI from '@store/ui/BackfeedConfigUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from
    '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import {VerifyParentAndArray} from '@scripts/util/ValidationHelpers';
import { Verify } from 'crypto';

export type IBackfeedConfigUIState = ICrudComponentState<MCHISFieldConfiguration, BackfeedConfigUI.IBackfeedConfigUIState>;
export const actionCreators = createCrudComponentActions<BackfeedConfigUI.ActionCreators>(BackfeedConfigUI.actionCreators);
export type IBackfeedConfigActions = typeof actionCreators;
export type IBackfeedConfigActionProps = ICrudComponentActionProps<IBackfeedConfigActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiHISFieldConfiguration, BackfeedConfigUI.backfeedConfigUIReducer);
export const reducer = combineReducers<IBackfeedConfigUIState>(reducers);

export const validationCallback: ValidationCallback<MCHISFieldConfiguration> =
    (crud: any): crud is MCHISFieldConfiguration => {
        let testCrud: MCHISFieldConfiguration = crud as MCHISFieldConfiguration;
        const validObject = isPlainObject(testCrud) && has(testCrud, 'HISFieldConfiguration');
        if (validObject) {
            VerifyParentAndArray(testCrud.HISFieldConfiguration, 'TypeList', 'Type');
            VerifyParentAndArray(testCrud.HISFieldConfiguration, 'FormTypeList', 'FormType');
            VerifyParentAndArray(testCrud.HISFieldConfiguration, 'FieldList', 'Field');
        }
        return validObject;
    };

