import * as React from 'react';
import { Input as UICLInput } from 'ui-core';
import styled from 'styled-components';
import { ContentColumnWrapper } from '@common/DialogWrapper';

export const LabelWrapper = styled.div`
    display: inline;
    margin-bottom: 0.3rem;
    color: #37474F;
    font-family: 'Core Sans C','Century Gothic',AppleGothic,sans-serif;
    font-size: 11px;
    line-height: 1.4em;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`;

export const DivWrapper = styled.div`
.medium{
width: 402px;
padding: 9px 0 8px 10px;
border: 1px solid #9BA1A9;
border-radius: 3px;
box-shadow: inset 0 2px 2px 0 rgb(155 161 169 / 25 %);
font-size: 13px;
color: #37474F;
background-color: #FFF;
outline: none;
padding-right: 8px;
margin: 9px 12px 10px 5px;
}
`;

const BrowseButton = styled.label`
    padding:10px !important;
    outline: none;
    font-size: 11px;
    margin: 0;
    text-align: left;
    background: none;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 600;
    border-radius: 3px;
    padding: 0px 1em;
    max-width: 100%;
    display: inline-block;
    background-color: #37474F;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-family: 'Core Sans C',sans-serif;
    border: 1px solid transparent;
`;

export interface IARMNarrowBrowseHandlerProps {
    onChange?: React.ChangeEventHandler<HTMLInputElement>, //Do we need to have this as a prop when it's already in UICL Input?
    inputChange?: React.ChangeEventHandler<HTMLInputElement>,
    labelValue?: string,
    inputId?: string,
    classNameValue?: string,
    maxLength?: Number,
    initialValue?: string,
    disabledInput?: boolean,
    browseId?: string,
    browseInputId?: string,
    styleValue?: string,
    canEdit?: boolean,
}

export class ARMNarrowBrowse extends React.Component<IARMNarrowBrowseHandlerProps | any, {}>
{
    static defaultProps: IARMNarrowBrowseHandlerProps = {
    };

    constructor(props: IARMNarrowBrowseHandlerProps) {
        super(props);
        this.state = {
            onChange: this.props.onChange,
            labelValue: this.props.labelValue,
            inputId: this.props.inputId,
            classNameValue: this.props.classNameValue,
            maxLength: this.props.maxLength,
            initialValue: this.props.initialValue,
            disabledInput: this.props.disabledInput,
            browseId: this.props.browseId,
            browseInputId: this.props.browseInputId,
            styleValue: this.props.styleValue,
            canEdit: this.props.canEdit,
            inputChange: this.props.inputChange
        }
    }

    render() {
        return (
            <>
                <LabelWrapper style={this.props.styleValue}>
                    {this.props.labelValue}
                </LabelWrapper>
                <DivWrapper>
                    <UICLInput
                        domID={this.props.inputId}
                        className={this.props.classNameValue}
                        maxLength={this.props.maxLength}
                        initialValue={this.props.initialValue}
                        disabled={this.props.disabledInput}
                        onChange={this.props.inputChange}
                    />
                </DivWrapper>
                <div style={this.props.divBrowseStyle}>
                    <BrowseButton id={this.props.browseId} htmlFor={this.props.browseInputId}>Browse</BrowseButton>
                    <UICLInput id={this.props.browseInputId} type='file' accept={this.props.acceptType} style={{ display: "none" }} disabled={!this.props.canEdit} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.onChange(e)} />
                </div>
            </>
        );
    }
};
