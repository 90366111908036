
import * as React from 'react';
import { TabContainer } from 'ui-core';
import styled from 'styled-components';
import { DialogWrapper } from '@common/DialogWrapper';
import { Typography } from '@commonResources/typography';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { actionCreators, IDashboardDefaultState } from '@store/OperationalDashboard/DashboardDefault';

import ScrollToTop from '@common/ScrollToTop';

import DashboardClaim from './DashboardClaim';
import DashboardEligibility from './DashboardEligibility';
import DashboardAssignedClaims from './DashboardAssignedClaims';
import { createMapDispatchToProps, IMergeComponentProps, mergeComponentProps, IActionCreatorMap, mapStateToProps, IApplicationStateMap } from '@scripts/util/ReduxHelpers';
import {destroyStateInSession} from "../../../SessionStorage";
import { getSecurityToken } from '../../../scripts/session/SecurityToken';
import { isNull, isUndefined } from 'lodash';


const pageWidth = 1050;
const pageHeight = undefined;
const tabWidthOffset = 60;

export const ContentWrapper = styled.div`
                display: flex;
                flex:1 auto;
                flex-direction: row;
                width:${pageWidth+"px"};
                height: 100%;
               
                .tooltip-wrapper{
                    button{
                        box-shadow: 0 0 0 0 black!important;
                        padding-top: 1px;
                        padding-bottom: 1px;
                        pointer-events:all;
                        > label{
                            margin-bottom: 0px;
                            ${ Typography.small };
                        }
                    }
                    button#EligibiltyDisabled{
                        pointer-events:none;
                    }
                    button#AssignedClaimsDisabled{
                        pointer-events:none;
                    }
                }
`;

export const SampleComponentDiv = styled.div`
    border: solid black 1px;
    margin: 10px;
    padding: 10px;
`

export const InputWrapper = styled.div`
    margin-left: 5px;
    margin-right: 5px;
`;

interface IComponentProps {
   
    canView: boolean;
    canEdit: boolean;
    canSecCheck1: boolean;
    title: string;

};


interface IComponentState {
};


export const DEFAULT_STATE: IComponentState = {
}


type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IDashboardDefaultProps = IMergeComponentProps<IDashboardDefaultState, IActionCreatorMap<"dashboardDefault" | "dashboardAssignedClaims" | "dashboardEligibility" | "dashboardClaim">, IOwnProps>;


export class OperationalDashboard extends React.Component<IDashboardDefaultProps, IComponentState> {

    static defaultProps: any = {
        canView: false,
        canEdit: false,
        canSecCheck1: false,
        title: 'DashBoard'
    };

    constructor(props: any) {
        super(props);
        this.state = DEFAULT_STATE;


    }

    public componentDidMount() {
        const { location } = this.props;
        destroyStateInSession();
        if( location.search !== "?cfi" ){
            this.toDefaultState();
        }
    }

    getTabs() {
        let securityToken = getSecurityToken();
        console.log(`eligibility: ${securityToken.Eligibility}`);
        let canViewEligibility: boolean = (!isUndefined(securityToken) && !isUndefined(securityToken.Eligibility) &&
                                        !isNull(securityToken.Eligibility) && securityToken.Eligibility === '1') ? true : false;
        console.log(`canViewEligibility: ${canViewEligibility}`);
        // canSecCheck1 -> FN_VIEW_PRODUCTIVITY
        const { canView, canEdit, canSecCheck1 } = this.props;
        const tabJson = [
            {
                domID: 'Claim',
                label: 'Claim',
                tabContent: <DashboardClaim canView={canView} canEdit={canEdit} pageWidth={pageWidth - tabWidthOffset} />
            },
            {
                domID: canViewEligibility ? 'Eligibility' : 'EligibiltyDisabled',
                label: 'Eligibility',
                tabContent: <DashboardEligibility canView={canView} canEdit={canEdit} pageWidth={pageWidth - tabWidthOffset} pageHeight={pageHeight ?? '100%'} />
            },
            {
                domID: canSecCheck1 ? 'Assigned Claims' : 'AssignedClaimsDisabled',
                label: 'Assigned Claims',
                tabContent: <DashboardAssignedClaims canView={canView} canEdit={canEdit} pageWidth={pageWidth - tabWidthOffset} pageHeight={pageHeight ?? '100%'}/>
            },
        ];
        return tabJson;
    }

    public componentWillUnmount() {
        this.toDefaultState();
    }
    
    public toDefaultState() {
        let { dashboardDefault, dashboardAssignedClaims, dashboardClaim, dashboardEligibility } = this.props.action

        dashboardDefault && dashboardDefault.resetState();
        dashboardAssignedClaims && dashboardAssignedClaims.resetState();
        dashboardClaim && dashboardClaim.resetState();
        dashboardEligibility && dashboardEligibility.resetState();
    }

    public render() {
        const { dashboardDefault } = this.props.action;

        return (
            <>
                <ScrollToTop />
            <InputWrapper>
                <DialogWrapper title="Dashboard" helpUrl='/Support/Help/HELP_Dashboard_OpsClaim.htm '>
                    <ContentWrapper className="eligibilityTab">
                        <div style={{ width: '100%' }}>
                            <TabContainer

                                domID="TabContainerResponse"
                                initialTab={this.props.dataStore.selectedTabIndex}
                                onTabChange={(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) => {
                                    dashboardDefault && dashboardDefault.selectTab(selectInfo.tabIndex)
                                }}
                                tabs={this.getTabs()}
                                width={pageWidth + "px"}
                                height={pageHeight + "px"}
                            />
                        </div>
                    </ContentWrapper>
                </DialogWrapper>
                </InputWrapper>
                </ >
        );
    }
};

var connectedHoc = connect(
    mapStateToProps("dashboardDefault"), // Selects which state properties are merged into the component's props
    createMapDispatchToProps([
        "dashboardDefault",
        "dashboardAssignedClaims",
        "dashboardEligibility",
        "dashboardClaim"
    ]), // Selects which action creators are merged into the component's props
    mergeComponentProps
)(OperationalDashboard);

export default withRouter(connectedHoc);
