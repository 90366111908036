import { Middleware, Dispatch, AnyAction, MiddlewareAPI } from "redux";
import { IThunkApiAction, IThunkResult } from "../util/ThunkHelpers";

export const ArmThunkMiddleware: Middleware = (api: MiddlewareAPI<Dispatch, any>) =>
    (next: Dispatch<AnyAction>) =>
        <A extends IThunkApiAction<string, any, any>>(action: A) => {
            // If this is not a thunk action OR if it is a thunk API action that doesn't have the right status, skip it.
            if ((action?.thunk || action.nextAction) && action?.status?.status !== "REQUEST") {

                // Default to true so that an action without a thunk can kick off another action.
                let result: IThunkResult<any> = { success: true }
                let middlewareResult: any = undefined;

                if (action.thunk) {
                    // If we are a thunk action that needs to fire, pass in the current action and any
                    // extra args.
                    // TODO - Need to clean this up and clarify var names for exactly what is getting passed in.
                    result = action.thunk(api.dispatch, action, action.extraArgs);
                }

                // Need to pass this action on to the chain and capture the result to return later.
                middlewareResult = next(action);

                // If the call was successful and there is a next action in the chain, update the next action with the
                // appropriate value from the last result and kick it to the start of the pipeline.
                // TODO - Add in onError handling. - WMR
                if (result?.success && action?.nextAction) {
                    if(result?.value)
                        action.nextAction.lastResult = result.value;

                    // Kick the next action to the start of the pipeline.
                    return api.dispatch(action.nextAction);
                }

                return middlewareResult;
            }
        else {
            // Continue on if we're not a Thunk Action.
            return next(action);
        }
    };