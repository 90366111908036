import { combineReducers, Reducer } from 'redux';
import * as ClaimOptionsUI from '@store/ui/ClaimOptionsUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type IClaimOptionsUIState = ICrudComponentState<MCClaimOptions, ClaimOptionsUI.IClaimOptionsUIState>;
export const actionCreators = createCrudComponentActions<ClaimOptionsUI.ActionCreators>(ClaimOptionsUI.actionCreators);
export type IClaimOptionsActions = typeof actionCreators;
export type IClaimOptionsActionProps = ICrudComponentActionProps<IClaimOptionsActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiClaimOptions, ClaimOptionsUI.claimOptionsUIReducer);
export const reducer = combineReducers<IClaimOptionsUIState>(reducers);

export const validationCallback: ValidationCallback<MCClaimOptions> = (crud: any): crud is MCClaimOptions => {
    let testCrud: MCClaimOptions = crud as MCClaimOptions;
    let validShape = ValidateJSONSchema(testCrud, "MCClaimOptions");
    if (validShape) {
        const info = testCrud.ClaimOptionsMaintenanceInfo;
        if (info.CustomEdits as any === "") {
            let customEdits = {} as any;
            info.CustomEdits = customEdits;
        }
        if (info.CustomEdits.RelatedClaimSearchConfig == undefined || info.CustomEdits.RelatedClaimSearchConfig as any === "") {
            let relConfig = {} as any;
            info.CustomEdits.RelatedClaimSearchConfig = relConfig;
        }
        if (info.CustomEdits.DuplicateConfig == undefined || info.CustomEdits.DuplicateConfig as any === "") {
            let dupConfig = {} as any;
            info.CustomEdits.DuplicateConfig = dupConfig;
        }
        if (info.CCIExclusions as any === "") {
            info.CCIExclusions = { CCIExclusion: [] };
        }
        if (info.CCIExclusions &&
            !Array.isArray(info.CCIExclusions.CCIExclusion)) {
            let arr: MCClaimOptionsCCIExclusion[] = [];
            arr.push(JSON.parse(JSON.stringify(info.CCIExclusions.CCIExclusion)));
            info.CCIExclusions.CCIExclusion = arr;
        }

        if (info.CCIEdits as any === "") {
            info.CCIEdits = { CCIEdit: [] };
        }
        if (info.CCIEdits &&
            !Array.isArray(info.CCIEdits.CCIEdit)) {
            let arr: MCClaimOptionsCCIEdit[] = [];
            arr.push(JSON.parse(JSON.stringify(info.CCIEdits.CCIEdit)));
            info.CCIEdits.CCIEdit = arr;
        }

        if (info.MNExclusions as any === "") {
            info.MNExclusions = { MNExclusion: [] };
        }
        if (info.MNExclusions &&
            !Array.isArray(info.MNExclusions.MNExclusion)) {
            let arr: MCClaimOptionsMNExclusion[] = [];
            arr.push(JSON.parse(JSON.stringify(info.MNExclusions.MNExclusion)));
            info.MNExclusions.MNExclusion = arr;
        }

        if (info.MNEdits as any === "") {
            info.MNEdits = { MNEdit: [] };
        }
        if (info.MNEdits &&
            !Array.isArray(info.MNEdits.MNEdit)) {
            let arr: MCClaimOptionsMNEdit[] = [];
            arr.push(JSON.parse(JSON.stringify(info.MNEdits.MNEdit)));
            info.MNEdits.MNEdit = arr;
        }

        if (info.MNClientPolicySeverities as any === "") {
            info.MNClientPolicySeverities = { MNClientPolicySeverity: [] };
        }
        if (info.MNClientPolicySeverities &&
            !Array.isArray(info.MNClientPolicySeverities.MNClientPolicySeverity)) {
            let arr: MCClaimOptionsMNClientPolicySeverity[] = [];
            arr.push(JSON.parse(JSON.stringify(info.MNClientPolicySeverities.MNClientPolicySeverity)));
            info.MNClientPolicySeverities.MNClientPolicySeverity = arr;
        }
    }
    return validShape;
};