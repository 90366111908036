import * as React from 'react';
import * as PropTypes from 'prop-types';
import { Grid } from 'ui-core';

export interface IARMGridProps {
    scrollTop: number
}

interface IComponentState {
    scrollTop: number
}

export const DEFAULT_STATE: IComponentState = {
    scrollTop: 0
};

export class ARMGrid extends React.Component<IARMGridProps | any, IComponentState>{

    static propTypes: {};
    public scrollTop: number = 0;

    constructor(props: IARMGridProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
    }

    public componentDidUpdate() {
        const {
            scrollToTop
        } = this.props;
   
        // if scrollToTop is undefined then no special scroll handling has been requested
        if (scrollToTop == undefined) return;

        // if scrollToTop has been requested then reset user scroll pos
        if (scrollToTop) {
            this.scrollTop = 0;
            return;
        }

        // else scrollToTop is false so persist user scroll pos
        let grid = document.getElementById(this.props.domID);
        if (grid && grid.parentElement) {
            let parentDiv = grid.parentElement;

            // bind scroll event handler once
            if (!parentDiv.onscroll) {
                parentDiv.onscroll = this.persistScroll.bind(this);
            }
            
            // maintain user scroll pos
            if (parentDiv.scrollTop === 0 && this.scrollTop > 0) {
                parentDiv.scrollTop = this.scrollTop;
            }
        }
    }

    public persistScroll(e: Event) {
        const newScrollTop = (e.target as Element).scrollTop;
        this.scrollTop = newScrollTop;
    }

    render() {
        return (
            <Grid
                // must change key every render in order to reflect updated row sorting
			    // due to uicl grid implementation
                key={"ui-core-grid-" + Date.now()}
                {...this.props}
            >
            </Grid>
        );
    }
}

ARMGrid.propTypes = {
    scrollbarTopPos: PropTypes.number,
};
