import * as React from 'react';
import { bindAll, noop, isEqual } from 'lodash';
import { Button } from 'ui-core';

import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { Input } from '@common/UICLWrappers/ARMInput';
import {
    ContentColumnWrapper,
    ContentRowWrapper,
    FlexBoxModifier,
} from '@common/DialogWrapper';
import { SelectComponent } from '@common/SelectComponent';

export interface IFilterListProps {
    disableSaveBtn?: boolean;
    newFilterName: string;
    savedFilters: APICF_SimpleList[];
    selectedFilter?: APICF_SimpleList;

    onDeleteSavedFilter(filter: APICF_SimpleList):void;
    onLoadSavedFilter(filter: APICF_SimpleList | undefined): void;
    onNewNameChange(value:string): void;
    onSavedFilterSelect(filter:APICF_SimpleList | undefined): void;
    onSaveFilter(filter: APICF_SimpleList | undefined): void;
}

export interface IFilterListState {
    
}

export default class FilterList extends React.PureComponent<IFilterListProps & React.HTMLAttributes<HTMLFieldSetElement>, IFilterListState> {
    static defaultProps = {
        disableSaveBtn: false,
        newFilterName: '',
        savedFilters: [],
        selectedFilterName: '',
        onDeleteSavedFilter: noop,
        onLoadSavedFilter: noop,
        onNewNameChange: noop,
        onSavedFilterSelect: noop,
        onSaveFilter: noop,
    }
    constructor(props: IFilterListProps) {
        super(props);
        bindAll(this, ['onSelectedFilterNameChange', 'onNewFilterNameChange', 'onClickLoad', 'onClickSave', 'onClickDelete', 'getSavedFilter']);
    }

    private getSavedFilter() {
        return this.props.savedFilters.find((filter) => isEqual(filter, this.props.selectedFilter));
    }
    public onSelectedFilterNameChange(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedOption = this.props.savedFilters.find((filter) => filter.ID === e.target.value);
        this.props.onSavedFilterSelect(selectedOption);
        //new filter and saved filter mutually exclusive
        this.props.onNewNameChange('');
    }
    public onNewFilterNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.onNewNameChange(e.target.value);
    }
    public onClickLoad(e: React.SyntheticEvent<HTMLButtonElement>) {
        this.props.onLoadSavedFilter(this.getSavedFilter());
    }
    public onClickSave(e: React.SyntheticEvent<HTMLButtonElement>) {
        if (!!this.props.newFilterName) return this.props.onSaveFilter({ID: '-1', Name: this.props.newFilterName });
        const savedFilter = this.getSavedFilter();
        if (savedFilter) return this.props.onSaveFilter(savedFilter);
        this.props.onSaveFilter(undefined);
    }
    public onClickDelete(e: React.SyntheticEvent<HTMLButtonElement>) {
        const savedFilter = this.getSavedFilter();
        if (savedFilter) this.props.onDeleteSavedFilter(savedFilter);
    }
    public render() {
        const { savedFilters, newFilterName, className, disableSaveBtn } = this.props;
        const options = { value: 'ID', text: 'Name' };
        const selectedFilterOption = this.getSavedFilter();
        const isLoadEnabled = !!selectedFilterOption;
        const isSaveEnabled = !disableSaveBtn;
        const isDeleteEnabled = isLoadEnabled;
        return (
        <DialogFieldset widthValue={545} className={className}>
               <DialogLegend >Filters</DialogLegend>
               <FlexBoxModifier as={ContentColumnWrapper} flexGap="4px">
                   <Input
                       label="New:"
                       domID="fl-new-filter-name-input"
                       size="small"
                       initialValue={newFilterName}
                       maxLength={95}
                       onChange={this.onNewFilterNameChange}/>
                   <SelectComponent
                       className="dropdown"
                       title="fl-saved-filter-select"
                       selectedValue={selectedFilterOption?.ID}
                       label="Saved:"
                       onSelect={this.onSelectedFilterNameChange}
                       optionFields={options}
                       records={savedFilters}
                       size={4}
                       width="100%" />
                    <FlexBoxModifier as={ContentRowWrapper} flexGap="4px" justifyContent="center">
                        <Button name="Load" onClick={this.onClickLoad} domID="fl-load-btn" disabled={!isLoadEnabled} />
                        <Button name="Save" onClick={this.onClickSave} domID="fl-save-btn" disabled={!isSaveEnabled} />
                        <Button name="DELETE" onClick={this.onClickDelete} domID="fl-delete-btn" disabled={!isDeleteEnabled} />
                    </FlexBoxModifier>
               </FlexBoxModifier>
        </DialogFieldset>
        );
    }
};

