import { Reducer, AnyAction, Dispatch } from 'redux';
import { ActionTypes, createDataAction, createAction } from '@scripts/util/ActionHelpers';
import { IThunkAction, IThunkApiAction } from '@scripts/util/ThunkHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { ComplexAction, ComplexApiAction, IThunkResult } from '@scripts/util/ThunkHelpers';
import { History } from 'history';
import { MasterCrudState, actionCreators as masterCrudCreators } from '../MasterCrud';


/*type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;*/

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
// Name format should be "{Page Name}UiState"
export interface UserPasswordHintsUiState {
    answer1Error: string;
    answer2Error: string;
    answer3Error: string;
    question1Error: string;
    question2Error: string;
    question3Error: string;
    validated: boolean;
}

export type UphControlId = "question1" | "question2" | "question3" | "answer1" | "answer2" | "answer3";

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

//DEFAULTS

export const defaultState: UserPasswordHintsUiState = {
    answer1Error: "",
    answer2Error: "",
    answer3Error: "",
    question1Error: "",
    question2Error: "",
    question3Error: "",
    validated: false,
};

// THUNK FUNCTIONS

/**
 * Method to validate questions and answers. Uses a more generic set of items to avoid duplication
 * since we use the same general algorithm to compare questions and answers.
 *
 * @param itemsToValidate The list of items to validate. [0] is index of the control, [1] is the submitted question or answer.
 * @param itemType A string enum that says whether this list pertains to questions or answers.
 * @param checkInvalidItem A function that returns a bool representing if an item is an invalid selection or entry.
 * @param checkEqual A function that returns a bool saying whether or not the two different items provided are equivalent.
 */
function validateItems(
    itemsToValidate: [UphControlId, number | string][],
    itemType: "question" | "answer",
    checkInvalidItem: (val: number | string) => boolean,
    checkEqual: (val1: number | string, val2: number | string) => boolean): [UphControlId, string][] {

    // Tuple array with item format [question number, error message]
    const errorsList: [UphControlId, string][] = [];

    // Check our questions and check the following:
    // 1) Ensure each one is an actual selection.
    // 2) Ensure each question index is unique.
    // Doing it through this loop and if-else statement ensures we won't get multiple error
    // messages on the same question.
    // Yes, it's inefficient, but it's a small list and easy-to-understand code.
    for (let item of itemsToValidate) {
        // Ensure the chosen question is not our default "Select" option
        if (checkInvalidItem(item[1])) {
            errorsList.push(
                [
                    item[0],
                    `You must ${itemType === "question" ? "select" : "enter"} a valid ${itemType}.`
                ]);
        }
        // Ensure each other question does not match this one.
        else {
            for (let otherItem of itemsToValidate) {
                // Skip the match check if it is the same question we're checking; otherwise we're
                // looking for index matches on our selected questions.
                if (item[0] !== otherItem[0] &&
                    checkEqual(item[1], otherItem[1])) {
                    errorsList.push([item[0], `Please choose a unique ${itemType}.`]);
                }
            }
        }
    }

    return errorsList;
}

/**
 * Checks the questions and answers to ensure the page is valid before submission.
 * @param currentQuestions
 * @param currentAnswers
 */
function validatePage(currentQuestions: [UphControlId, number][],
    currentAnswers: [UphControlId, string][]): [UphControlId, string][] {

    // Make sure all our questions are not the default selection or duplicated.
    // Make sure all our answers are not blank or duplicated.
    const errorList: [UphControlId, string][] =
        validateItems(
                currentQuestions,
                "question",
                (val: number | string) => { return val === 0 },
                (val1: number | string, val2: number | string) => { return val1 === val2 })
            .concat(
                validateItems(
                    currentAnswers,
                    "answer",
                    (val: number | string) => { return !(val && Boolean((val as string).trim())) },
                    (val1: number | string, val2: number | string) => {
                        return (val1 as string).toLowerCase() === (val2 as string).toLowerCase();
                    }));
    // If both of our arrays are empty, we do not have any errors and can return as validated.
    return errorList;
}

/**
 * Updates the Master data object with the appropriate values before it is submitted.
 * @param currentQuestions
 * @param currentAnswers
 * @param crud
 */
function updateCrudWithAnswers(
    currentQuestions: [UphControlId, number][],
    currentAnswers: [UphControlId, string][],
    crud: MasterCrudState<MCUserPasswordHints>): MasterCrudState<MCUserPasswordHints>
{
    const selectedQuestions: number[] = currentQuestions.map((q: [UphControlId, number]) => q[1]);
    const selectedAnswers: string[] = currentAnswers.map((a: [UphControlId, string]) => a[1]);
    const newCrud = crud;
    if (newCrud.data && selectedAnswers.length === 3 && selectedQuestions.length === 3) {
        newCrud.data.QuestionMaintenanceInfo.Answers = {
            Answer: [
                {
                    "@ID": "#",
                    "@QuestionId": selectedQuestions[0],
                    "@Answer": selectedAnswers[0],
                },
                {
                    "@ID": "#",
                    "@QuestionId": selectedQuestions[1],
                    "@Answer": selectedAnswers[1],
                },
                {
                    "@ID": "#",
                    "@QuestionId": selectedQuestions[2],
                    "@Answer": selectedAnswers[2],
                }
            ]
        };
    }
    return newCrud;
}

//ACTION DEFINITIONS
export interface ValidatePageInfo extends ICrudActionData<MCUserPasswordHints, UserPasswordHintsUiState> {
}

// Prefixing all actions with UPH for User Password Hints.
export const actionCreators = {
    // Page validation action. Stick the BL logic in here to make the UI a little cleaner and slimmer.
    //validatePage: (currentQuestions: [number, number][], currentAnswers: [number, string][]) =>
    //    createDataAction('UPH_VALIDATE_PAGE', { questions: currentQuestions, answers: currentAnswers }),
    validateSubmitRedirect: (
        currentQuestions: [UphControlId, number][],
        currentAnswers: [UphControlId, string][],
        crud: MasterCrudState<MCUserPasswordHints>,
        history: History<any>)
        : IThunkAction<'UPH_VALIDATE_SUBMIT_REDIRECT', boolean> =>
    {
        return new ComplexAction('UPH_VALIDATE_SUBMIT_REDIRECT')
            .addThunk(
                (dispatch: Dispatch<AnyAction>): IThunkResult<boolean> => {
                    // Check the page for errors.
                    const errors = validatePage(currentQuestions, currentAnswers);
                    // If the questions & answers are valid, dispatch an API action.
                    if (errors.length === 0) {
                        return {
                            success: true,
                            value: true
                        };
                    }
                    // If we find errors in the validation process, set those errors to the appropriate controls.
                    else {
                        for (let error of errors) {
                            dispatch(actionCreators.setError(error[0], error[1]));
                        }
                        return { success: false };
                    }
                })
            .then(ComplexApiAction
                .fromAction(
                    masterCrudCreators.update(
                        updateCrudWithAnswers(currentQuestions, currentAnswers, crud)))
                .addThunk(
                    (dispatch: Dispatch<AnyAction>, thisAction: IThunkApiAction<string, any, any>)
                        : IThunkResult<boolean> => {
                        return thisAction.status.status === 'SUCCESS'
                            ? { success: true }
                            : { success: false };
                    }))
            .thenRedirect("/LandingPage", history)
            .finish();
    },
    clearError: (itemToClear: UphControlId) =>
        createDataAction('UPH_CLEAR_ERROR', { itemToClear: itemToClear }),
    setError:
        (itemToSet: UphControlId, message: string) =>
            createDataAction('UPH_SET_ERROR', { itemToSet: itemToSet, message: message }),
    clearValidation:
        () => createAction('UPH_CLEAR_VALIDATION_FLAG'),
};


// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<UserPasswordHintsUiState, KnownActions> =
    (state: UserPasswordHintsUiState | undefined, action: KnownActions) => {

        function setErrorMessage(id: UphControlId, message: string, newState: UserPasswordHintsUiState) {
            // Just explicitly check for each item.
            // Making it any cleverer for only 6 items is overkill.
            switch (id) {
                case "question1":
                    newState.question1Error = message;
                    break;
                case "question2":
                    newState.question2Error = message;
                    break;
                case "question3":
                    newState.question3Error = message;
                    break;
                case "answer1":
                    newState.answer1Error = message;
                    break;
                case "answer2":
                    newState.answer2Error = message;
                    break;
                case "answer3":
                    newState.answer3Error = message;
                    break;
            }
        }

        if (state != undefined) {
            const newState: UserPasswordHintsUiState = {
                ...state
            };

            switch (action.type) {
                case "UPH_CLEAR_ERROR":
                    setErrorMessage(action.data.itemToClear, "", newState);
                    return newState;

                case "UPH_SET_ERROR":
                    setErrorMessage(action.data.itemToSet, action.data.message, newState);
                    return newState;

                case "UPH_CLEAR_VALIDATION_FLAG":
                    newState.validated = false;
                    return newState;

                default:
                    // The following line guarantees that every action in the KnownAction union has been covered by a case above
                    return state;
            }
        }

        return defaultState;
    };