import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as IMCASimpleErrorRuleUIState from './ui/ManageClaimAssignmentsSimpleErrorRulesUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IMCASimpleErrorRuleState = ICrudComponentState<MCASimpleErrorRuleType, IMCASimpleErrorRuleUIState.IMCASimpleErrorRuleUIState>;
export const actionCreators = createCrudComponentActions<IMCASimpleErrorRuleUIState.ActionCreators>(IMCASimpleErrorRuleUIState.actionCreators);
export type IMCASimpleErrorRuleActions = typeof actionCreators;
export type IMCASimpleErrorRuleActionProps = ICrudComponentActionProps<IMCASimpleErrorRuleActions>
export type IMCAErrorRuleActionProps = ICrudComponentActionProps<IMCASimpleErrorRuleActionProps>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimAssignmentErrorSimple, IMCASimpleErrorRuleUIState.reducer);
export const reducer = combineReducers<IMCASimpleErrorRuleState>(reducers);
