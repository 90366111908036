import { Reducer, Dispatch, AnyAction } from 'redux';
import { batch } from 'react-redux';
import { differenceBy, keyBy, uniqueId, without } from 'lodash';
import { ComplexApiAction, IThunkResult, ComplexAction, ComplexDataAction, IThunkApiAction } from '@scripts/util/ThunkHelpers';
import { createAction, createDataAction, ActionTypes, createApiStatusAction, createApiBodyAction } from '@scripts/util/ActionHelpers';
import { MasterCrudState, actionCreators as masterCrudCreators } from '../MasterCrud';
import { IConfigDataState, ICookies_Container, actionCreators as configCreators, ICookies_Config } from '@store/ConfigData';
import { CrudTypes } from '@commonResources/CrudTypes';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

/**
 * An object representing a single search parameter in a selective get xml request
 * for the Physician Maintenance page. Property names must match properties on
 * existing API objects.
 * @property Name The name of the parameter
 * @property Value The string value of the property.
 */
export interface PmSearchParameters {
    // Only acceptable strings are the expected names of search parameters.
    Name: "@chvDataType" | "@chvMaxRows" | "@chvLastNameFrom" | "@chvLastNameTo" |
    "@chvFirstNameFrom" | "@chvFirstNameTo" | "@chvTaxIDFrom" | "@chvTaxIDTo" |
    "@chvKeyNoFrom" | "@chvKeyNoTo" | "@chvPhysicianNoFrom" | "@chvPhysicianNoTo",
    Value: string,
}

export interface PmDropdownOption {
    label: string,
    value: string,
}

export const DEFAULT_DROPDOWN: PmDropdownOption = {
    label: "Select",
    value: "Select",
}

export const DEFAULT_ID_TYPE_DROPDOWN: PmDropdownOption = {
    value: "-1",
    label: "- Select ID Type -"
}

export const DEFAULT_STATE_DROPDOWN: PmDropdownOption = {
    value: "-1",
    label: "- Select State-"
}

export interface PmPayerDropdownOption extends PmDropdownOption {
    lobId: number;
    facilityPayerStatus: string;
    enrollmentTypeId: string;
}

export interface PmFacilityDropdownOption extends PmDropdownOption {
    payerDropdowns: PmPayerDropdownOption[];
    enrollmentTypeId: string;
}

export interface FlWaFields {
    groupNumber: string,
    groupSuffix: string,
    doctorNumber: string,
    locationCode: string,
}

export const DEFAULT_FLWA_FIELDS: FlWaFields = {
    groupNumber: "",
    groupSuffix: "",
    doctorNumber: "",
    locationCode: "",
}

export interface IlNyFields {
    billingDept: string,
    physicianAccountNumber: string,
    idLocCode: string,
    deptFlag: string,
}

export const DEFAULT_ILNY_FIELDS: IlNyFields = {
    billingDept: "",
    physicianAccountNumber: "",
    idLocCode: "",
    deptFlag: "",
}

export interface Lob {
    id: string,
    keyNo: string,
    physicianNo: string,
    facilityId: string,
    payerId: string,
    lobId: string,
    specialtyCodeId: string,
    description: string,
    setToDefault: string,
    npi: string,
    accountStatus: string,
    suppressCCIAI: string,
    suppressCCIBI: string,
    suppressCCIBP: string,
    suppressMNAI: string,
    suppressMNBI: string,
    suppressMNBP: string,
    taxonomy: string,
    displayName: string,
    delete?: string,
    isNew?: boolean;
    enrollmentTypeId: string;
}

export const DEFAULT_LOB: Lob = {
    id: '',
    keyNo: '',
    physicianNo: '',
    facilityId: '',
    payerId: '',
    lobId: '',
    specialtyCodeId: '',
    description: '',
    setToDefault: '',
    npi: '',
    accountStatus: '',
    suppressCCIAI: '',
    suppressCCIBI: '',
    suppressCCIBP: '',
    suppressMNAI: '',
    suppressMNBI: '',
    suppressMNBP: '',
    taxonomy: '',
    displayName: '',
    enrollmentTypeId: '',
};

export interface PhysicianInfo {
    firstName: string,
    middleInitial: string,
    lastName: string,
    address1: string,
    address2: string,
    city: string,
    state: PmDropdownOption,
    zip: string,
    telephone: string,
    npi: string,
    taxonomy: string,
    taxId: string,
    ssn: string,
    internalId: string,
    idType: PmDropdownOption,
    flwaInfo: FlWaFields,
    ilnyInfo: IlNyFields,
    displayName: string,
    lobList?: Lob[],
    isDirty?: Boolean;
    delete?: string;
}

export const DEFAULT_PHYSICIAN_INFO: PhysicianInfo = {
    firstName: "",
    middleInitial: "",
    lastName: "",
    address1: "",
    address2: "",
    city: "",
    state: { ...DEFAULT_STATE_DROPDOWN },
    zip: "",
    telephone: "",
    npi: "",
    taxonomy: "",
    taxId: "",
    ssn: "",
    idType: { ...DEFAULT_ID_TYPE_DROPDOWN },
    flwaInfo: { ...DEFAULT_FLWA_FIELDS },
    ilnyInfo: { ...DEFAULT_ILNY_FIELDS },
    internalId: "",
    displayName: "",
}

/**
 * An object representing a list of search parameters for a selective get xml request
 * for the Physician Maintenance page. Property name must match the expected property
 * name on existing API objects.
 *
 * @property Param The list of parameters.
 */
export interface PmSearchRequest {
    Param: PmSearchParameters[],
}

export function getBasicSearchRequest(maxRows: string | number, requestDataType: string = "1"): PmSearchRequest {
    return {
        Param: [
            {
                Name: "@chvDataType",
                Value: requestDataType,
            },
            {
                Name: "@chvMaxRows",
                Value: String(maxRows),
            },
        ]
    }
}

// TODO: could we create a hash of these? By the time we get here in the parsing the cyclomatic complexity of these loops is pretty high
export function getPayerOption({ facilityId, payerId, facilityOptions }: {facilityId: string, payerId: string, facilityOptions: PmFacilityDropdownOption[], defaultValue?: string}): PmPayerDropdownOption | undefined {
    const facilityOption = facilityOptions.find(option => option.value===facilityId);
    if (!!facilityOption) {
        const payerOption = facilityOption.payerDropdowns.find((option) => option.value === payerId) as PmPayerDropdownOption;
        if (payerOption) return payerOption;
    }
}

export function mapCrudToLob(crudLob: MCMR_Physician_LOB, lobList: PmDropdownOption[], facilityOptions: PmFacilityDropdownOption[]): Lob {
    const lobId: string = crudLob['@LOBID'];
    const description = crudLob['@Description'];
    const physicianNo = crudLob['@PhysicianNo'];
    const name = `${lobList.find(l => l.value === lobId)?.label ?? ""} - ${description} - ${physicianNo}`;
    const payerOption = getPayerOption({
            facilityId: crudLob['@FacilityID'],
            payerId: crudLob['@PayerID'],
            facilityOptions,
        });
    return {
        id: crudLob['@ID'],
        keyNo: crudLob['@KeyNo'],
        physicianNo: physicianNo,
        facilityId: crudLob['@FacilityID'],
        payerId: crudLob['@PayerID'],
        lobId: lobId,
        specialtyCodeId: crudLob['@SpecialtyCodeID'],
        description: description,
        setToDefault: crudLob['@SetToDefault'],
        npi: crudLob['@NPI'],
        accountStatus: crudLob['@AccountStatus'],
        suppressCCIAI: crudLob['@SuppressCCIAI'],
        suppressCCIBI: crudLob['@SuppressCCIBI'],
        suppressCCIBP: crudLob['@SuppressCCIBP'],
        suppressMNAI: crudLob['@SuppressMNAI'],
        suppressMNBI: crudLob['@SuppressMNBI'],
        suppressMNBP: crudLob['@SuppressMNBP'],
        taxonomy: crudLob['@Taxonomy'],
        delete: crudLob['@Delete'],
        displayName: name,
        enrollmentTypeId: payerOption?.enrollmentTypeId ?? '',
    };
}

/**
 * Maps one MCMR_Physician to a PhysicianInfo object.
 * @param crudPhy The incoming master CRUD physician.
 */
function mapCrudToPhysician(crudPhy: MCMR_Physician, lobOptions: PmDropdownOption[],facilityOptions: PmFacilityDropdownOption[],
    stateOptions: PmDropdownOption[], idTypeOptions: PmDropdownOption[]): PhysicianInfo {
    const trimmedMidInit: string = crudPhy['@MiddleInitial'].trim();
    const formattedMiddleInitial: string =
        trimmedMidInit.length > 0 ? ` ${trimmedMidInit}.` : "";
    const displayName: string =
        `${crudPhy['@LastName']}, ${crudPhy['@FirstName']}${formattedMiddleInitial}`;

    let lobList: Lob[] | undefined = undefined;
    if (crudPhy['LOB']) {
        lobList = ForceArray(crudPhy['LOB']).map(lob => mapCrudToLob(lob, lobOptions, facilityOptions));
    }

    // Need to look up and map the dropdown options onto the selected option in our list.
    const state: PmDropdownOption = stateOptions?.find(opt => opt.value == (crudPhy['@StateID'])) ?? DEFAULT_STATE_DROPDOWN;
    const idType: PmDropdownOption = idTypeOptions?.find(opt => opt.value == crudPhy['@IdTypeID']) ?? DEFAULT_ID_TYPE_DROPDOWN;
    

    return {
        firstName: crudPhy['@FirstName'],
        middleInitial: trimmedMidInit,
        lastName: crudPhy['@LastName'],
        address1: crudPhy['@Address'],
        address2: crudPhy['@Address2'],
        city: crudPhy['@City'],
        state: { ...state},
        zip: crudPhy['@ZipCode'],
        telephone: crudPhy['@TelephoneNo'],
        npi: crudPhy['@PhysicianNPI'],
        taxonomy: crudPhy['@TaxonomyCode'],
        taxId: crudPhy['@TaxId'],
        ssn: crudPhy['@SSN'],
        idType: {...idType},
        flwaInfo: {
            groupNumber: crudPhy['@GroupNo'],
            groupSuffix: crudPhy['@GroupSuffix'],
            doctorNumber: crudPhy['@DoctorNo'],
            locationCode: crudPhy['@LocationCode'],
        },
        ilnyInfo: {
            billingDept: crudPhy['@BillingDept'],
            physicianAccountNumber: crudPhy['@PhysicianAccountNo'],
            idLocCode: crudPhy['@IdLocCode'],
            deptFlag: crudPhy['@DeptFlag'],
        },
        internalId: crudPhy['@ID'],
        displayName: displayName,
        lobList: lobList,
    }
}

/**
 * Maps the MCMR_Physician objects to the appropriate PhysicianInfo object.
 * @param data The incoming crud data.
 */
function mapPhysiciansToState(data: MCMR_PhysicianMaintenanceInfo, lobOptions: PmDropdownOption[], facilityOptions: PmFacilityDropdownOption[], stateOptions: PmDropdownOption[], idTypeOptions: PmDropdownOption[]): PhysicianInfoList {
    let resultsList: PhysicianInfoList = {
        physicians: [{
            ...DEFAULT_PHYSICIAN_INFO,
            displayName: "- ADD A NEW PHYSICIAN -",
        }]
    }

    if (data.Physicians?.Physician) {
        const incomingPhysicians: MCMR_Physician[] = ForceArray(data.Physicians.Physician);
        resultsList.physicians = resultsList.physicians.concat(
            incomingPhysicians.map((p) => {
                return mapCrudToPhysician(p, lobOptions, facilityOptions, stateOptions, idTypeOptions);
            })
        );
    };
    return resultsList;
}

function getPhysicianDisplayName(physician: PhysicianInfo): string {
    const trimmedMidInit: string = physician.middleInitial.trim();
    const formattedMiddleInitial: string = trimmedMidInit.length > 0 ? ` ${trimmedMidInit}.` : "";
    return `${physician.lastName}, ${physician.firstName}${formattedMiddleInitial}`;
}

export interface PhysicianInfoList {
    physicians: PhysicianInfo[],
}

export interface IPhysicianMaintenanceUIState {
    changed: boolean;
    leave: boolean;
    physicianList: PhysicianInfoList;
    selectedPhysician: PhysicianInfo;
    stateDropdownOptions: PmDropdownOption[];
    idDropdownOptions: PmDropdownOption[];
    lobDropdownOptions: PmDropdownOption[];
    facilityDropdownOptions: PmFacilityDropdownOption[];
    specialtyCodeDropdownOptions: PmDropdownOption[];
    selectedTab: number;
    loading: boolean;
    physicianCount: number;
    lobCount: number;
    physicianLimit: number;
    showTooManyResultsModal: boolean;
    deltaPhysicians: PhysicianInfo[];
    physiciansAdded: number;
}
/*const defaultpayerSearchData: MCMR_PhysicianPayer = {
    Payer: []
}*/

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
// TODO - Use in reducer to ensure array object.
function ForceArray<T>(item: T[] | T): T[] {
    if (Array.isArray(item))
        return item as T[];
    else
        return [{ ...item }];
}

export const actionCreators = {
    isUpdate: (res: boolean) => createDataAction('IS_UPDATE', res),
    isLeave: (res: boolean) => createDataAction('IS_LEAVE', res),
    showPopup: (fieldInfo: boolean) => createDataAction('SHOW_POPUP', fieldInfo),
    hidePopup: (fieldInfo: boolean) => createDataAction('HIDE_POPUP', fieldInfo),

    // First get the physician count limit in the config store. Then get the mastercrud for physician maintenance.
    // Process the control setup options and set the physicians up if the total amount is less than our physician
    // count limit.
    loadPageOptions: () =>
        // Set loading flag
        ComplexDataAction
            .fromAction(actionCreators.setLoading(true))
            .withNoThunk()
            // Then look up the physician count limit in the config data.
            .then(
                ComplexApiAction.fromAction(
                    configCreators.getConfigData(
                        {
                            cookies: [], config: [{ name: "PhysicianCountLimit" }]
                        }
                    )
                )
            )
            // Dispatch an action to set the limit if we received one.
            .addThunk(
                (dispatch: Dispatch<AnyAction>, apiResult: IThunkApiAction<'GET_CONFIGDATA', ICookies_Container, void, ICookies_Container>)
                    : IThunkResult<number> => {

                    let physicianLimit: number = -1;
                    if (apiResult?.status?.status === 'SUCCESS' && apiResult.responseData) {

                        // Set the physician limit if it was found in our cookies. IF not we will use the default.
                        physicianLimit = Number(apiResult?.responseData?.config?.find((o: ICookies_Config) =>
                            o.name === "PhysicianCountLimit"
                        )?.value ?? -1);
                    }

                    batch(() => {
                        dispatch(actionCreators.setPhysicianCountLimit(physicianLimit));
                        dispatch(actionCreators.newSearchRequested(getBasicSearchRequest(physicianLimit, "0")));
                    });
                    return { success: true, value: physicianLimit };
                }
            )
            .finish(),

    // Action that will hit the MasterCRUD for a search request and immediately kick off a Redux action to process the results.
    newSearchRequested: (request: PmSearchRequest) =>
        ComplexApiAction
            .fromAction(masterCrudCreators.search({ crudId: CrudTypes.mctiPhysician2, data: JSON.stringify(request) }))
            .changeType('PM_SEARCH_PHYSICIAN_CRUD')
            .then(
                ComplexDataAction.fromAction(actionCreators.selectCurrentPhysician('')).withNoThunk()
            )
            .finish(),

    updatePhysiciansWithResults: (crud: MCMR_PhysicianMaintenanceInfo) => createDataAction('PM_UPDATE_PHYSICIANS_WITH_RESULTS', crud),
    selectCurrentPhysician: (id: string) => createDataAction('PM_SELECT_CURRENT_PHYSICIAN', id),
    updateSelectedTab: (newTabIndex: number) => createDataAction('PM_SELECT_TAB', newTabIndex),
    setLoading: (newFlag: boolean) => createDataAction('PM_SET_LOADING', newFlag),
    updatePhysicianAndLobCount: (physicianCount: number, lobCount: number) =>
        createDataAction('PM_SET_COUNT_INFO', { physicianCount: physicianCount, lobCount: lobCount }),
    setPhysicianCountLimit: (newLimit: number) => createDataAction('PM_SET_PHYSICIAN_COUNT_LIMIT', newLimit),
    setTooManyResultsFlag: (newFlag: boolean) => createDataAction('PM_SET_TOO_MANY_RESULTS_FLAG', newFlag),
    addMultiplePayers: (newPayers: Lob[]) => createDataAction('PM_ADD_MULTIPLE_PAYERS', newPayers),
    addSinglePayer: (newPayer: Lob) => createDataAction('PM_ADD_SINGLE_PAYER', newPayer), /* also copies */
    deletePayer: (payer:Lob) => createDataAction('PM_DELETE_PAYER', payer),
    updatePayer: (payer: Lob) => createDataAction('PM_UPDATE_PAYER', payer),
    addPhysician: (physician: PhysicianInfo) => createDataAction('PM_ADD_PHYSICIAN', physician),
    deletePhysician: (physician: PhysicianInfo) => createDataAction('PM_DELETE_PHYSICIAN', physician),
    updatePhysician: (physician: PhysicianInfo) => createDataAction('PM_UPDATE_PHYSICIAN', physician)
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultstate: IPhysicianMaintenanceUIState = {

    leave: false,
    changed: false,
    physicianList: {
        physicians: [{
            ...DEFAULT_PHYSICIAN_INFO,
            displayName: "- ADD A NEW PHYSICIAN -",
        }]
    },
    selectedPhysician: DEFAULT_PHYSICIAN_INFO,
    stateDropdownOptions: [{ value: "-1", label: "- Select State -" }],
    idDropdownOptions: [{ value: "-1", label: "- Select ID Type -" }],
    lobDropdownOptions: [{ value: "-1", label: "- Select LOB -" }],
    facilityDropdownOptions: [{ value: "-1", label: "- Select Facility -", enrollmentTypeId: '1', payerDropdowns: [] }],
    specialtyCodeDropdownOptions: [{ value: "-1", label: "- Select Specialty Code -" }],
    selectedTab: 0,
    loading: true, // so you don't get a flash of modal
    physicianCount: 0,
    lobCount: 0,
    physicianLimit: -1,
    showTooManyResultsModal: false,
    deltaPhysicians: [],
    physiciansAdded: 0,
};

interface CrudOption {
    '@ID': string,
    '@Name': string
}

/**
 * Maps master CRUD values to dropdown options for the UI.
 * @param data The incoming master CRUD data
 * @param initialLabel The label on the initial item in the dropdown list.
 */
export function mapControlOptions<T extends CrudOption>(data: T[] | T | undefined, initialLabel: string): PmDropdownOption[] {
    let options: PmDropdownOption[] = [{ value: "-1", label: initialLabel }];
    if (data) {
        data = ForceArray(data);
        data = data.filter(option => !!option['@Name'].trim());
        options = options.concat(
            data.map(option => ({
                value: option['@ID'],
                label: option['@Name'],
            }))
        )
    };
    return options;
}

/**
 * Maps the facility and associated payers to dropdown options for the UI.
 * @param data The MCMR_Facility array on the original master CRUD object.
 */
export function mapFacilityPayerOptions(data: MCMR_Facility[]): PmFacilityDropdownOption[] {
    // Get our dropdown options for facilities and the payers they could possibly populate.
    let facilityOptions: PmFacilityDropdownOption[] =
        [{
            value: "-1",
            label: "- Select Facility -",
            payerDropdowns: [], 
            enrollmentTypeId: '1',
        }];

    if (data) {
        data = ForceArray(data);

        // If we have facilities to display, add them on to the default -Select- option by processing
        // the Master CRUD response.
        facilityOptions = facilityOptions.concat(
            data.map(facility => {

                // Nest the payer dropdowns under the facility options so that when a facility is
                // selected, we can immediately pass it the relevant payers.
                // note default option added on ui side for this control
                let payerOptions: PmPayerDropdownOption[] = [];
                let payersList;
                if (typeof facility.Payers === 'object') {
                    // TODO: should we switch to lodash castArray here?
                    payersList = ForceArray(facility?.Payers?.Payer);
                }
                if (payersList) {
                    payerOptions = payerOptions.concat(
                        payersList.map(payer => ({
                            value: payer['@ID'],
                            lobId: Number(payer['@LOBID']),
                            label: payer['@Name'],
                            facilityPayerStatus: payer['@FacilityPayerStatus'],
                            enrollmentTypeId: payer['@EnrollmentTypeId'],
                        }))
                    )
                }

                return {
                    value: facility['@ID'],
                    label: `${facility['@Name']} (${facility['@FacSubID']})`,
                    payerDropdowns: payerOptions,
                    enrollmentTypeId: facility["@EnrollmentTypeId"],
                }

            })
        )
    }

    return facilityOptions;
}

function checkForErrorResponse(returnedEntriesCount: number, physicianCount: number, lobCount: number): boolean {
    return ((physicianCount > 0 || lobCount > 0) && returnedEntriesCount <= 1);
}

/*  This makes sure if you click off this physician and come back, you still
 *  see the change (until you do a new search)
 */
function updateSelectedPhysicianInPhysiciansList(newState: IPhysicianMaintenanceUIState) {
    const physicians = newState.physicianList.physicians.map(physician => physician.internalId===newState.selectedPhysician.internalId? newState.selectedPhysician: physician);
    newState = {
        ...newState, physicianList: {physicians}};
    return newState;
}

// Note if you're doing an add/copy/update/remove on the physician, then call this with an empty LOB list
export function updateDelta(updatedPhysician: PhysicianInfo, state: IPhysicianMaintenanceUIState): IPhysicianMaintenanceUIState {
    const oldDelta = state.deltaPhysicians;
    const existingPhysicianDelta = oldDelta.find(physician => physician.internalId === updatedPhysician.internalId);
    // this physician was added in the UI, but hasn't been saved to the BE
    if (updatedPhysician.delete === 'true' && updatedPhysician.internalId.search(/\bNew\[\w+]\w+\b/g) > -1) {
        return {...state, deltaPhysicians: state.deltaPhysicians.filter(physician => physician.internalId !== updatedPhysician.internalId)};
    }
    if (existingPhysicianDelta) {
        // create a new, merged loblist
        if (updatedPhysician.lobList?.length) {
            //We're doing this simply to avoid an inner loop to find each LOB (loop through only once here)
            const lobsById = keyBy(updatedPhysician.lobList || [], 'id');
            // replace any lobs we already edited with their updated versions
            const newLobList = existingPhysicianDelta.lobList?.map(lob => lobsById[lob.id] || lob) || [];
            // need to add anything we didn't already have
            const whatsLeft = differenceBy(updatedPhysician.lobList, newLobList, 'id');
            // put the 2 lists together in a new, edited physician
            const fullList = [...newLobList, ...whatsLeft];
            // remove any LOBs that don't exist in the BE that user wants to delete
            const lobList = fullList.filter(lob => !(lob.isNew && lob.delete === 'true'));
            updatedPhysician = { ...updatedPhysician, isDirty: existingPhysicianDelta.isDirty, lobList };
        } else {
            // keep lobs we have edited
            if (existingPhysicianDelta.lobList?.length) {
                updatedPhysician = { ...updatedPhysician, lobList: existingPhysicianDelta.lobList };
            }
            // and the clicked update from the physician pane, save details
            updatedPhysician = { ...updatedPhysician, isDirty: true };
        }
        //create a new array where we replaced the old version w the updated version
        return { ...state, deltaPhysicians: [...oldDelta.map(physician => physician.internalId === updatedPhysician.internalId ? updatedPhysician : physician)] };
    }
    // this physician was not in our delta before
    // create a new array where we add our physician to the end
    if (!updatedPhysician.lobList?.length) {
        // sent from physician pane
        updatedPhysician = {...updatedPhysician, isDirty: true};
    }
    return { ...state, deltaPhysicians: [...oldDelta, updatedPhysician]};
}

function newToPound(value:string): string {
    const result = value.replace(/\bNew\[\w+]\w+\b/g, '#');
    return result;
}
function getValidPayerId(payerId: string): string {
    const guidParts = payerId.split('-');
    if (guidParts.length>1) return payerId;
    return '';
}
type MCMR_Update_Physician = Omit<Partial<MCMR_Physician>, '@ID' | 'LOB'> & {'@ID': string, LOB?: Partial<MCMR_Physician_LOB>[], '@Delete'?: string};
export function mapDeltaToXmlIsh(delta: PhysicianInfo[]): MCMR_Update_Physician[] {
    return delta.map((physician, i) => {

        let crudPhysician: MCMR_Update_Physician = {'@ID': newToPound(physician.internalId)};
        if (physician.isDirty) {
            const stateId = parseInt(physician.state.value) > 0 ? physician.state.value : '0';
            crudPhysician = {
                ...crudPhysician,
                '@FirstName': physician.firstName,
                '@MiddleInitial': physician.middleInitial,
                '@LastName': physician.lastName,
                '@Address': physician.address1,
                '@Address2': physician.address2,
                '@City': physician.city,
                '@StateID': stateId,
                '@ZipCode': physician.zip,
                '@TelephoneNo': physician.telephone,
                '@PhysicianNPI': physician.npi,
                '@TaxonomyCode': physician.taxonomy,
                '@TaxId': physician.taxId,
                '@SSN': physician.ssn,
                '@IdTypeID': physician.idType.value,
                '@GroupNo': physician.flwaInfo.groupNumber,
                '@GroupSuffix': physician.flwaInfo.groupSuffix,
                '@DoctorNo': physician.flwaInfo.doctorNumber,
                '@LocationCode': physician.flwaInfo.locationCode,
                '@BillingDept': physician.ilnyInfo.billingDept,
                '@PhysicianAccountNo': physician.ilnyInfo.physicianAccountNumber,
                '@IdLocCode': physician.ilnyInfo.idLocCode,
                '@DeptFlag': physician.ilnyInfo.deptFlag,
                '@CustomerKey': '',
            }
            if (!!physician.delete) crudPhysician['@Delete'] = physician.delete;
        }
        if (physician.lobList) {
            crudPhysician.LOB = physician.lobList.map(lob => ({
                "@ID": newToPound(lob.id),
                "@KeyNo": lob.keyNo,
                "@PhysicianNo": lob.physicianNo,
                "@Description": lob.description,
                "@NPI": lob.npi,
                "@Taxonomy": lob.taxonomy,
                "@AccountStatus": lob.accountStatus,
                "@PayerID": getValidPayerId(lob.payerId),
                "@LOBID": lob.lobId,
                "@FacilityID": lob.facilityId,
                "@SpecialtyCodeID": parseInt(lob.specialtyCodeId) >= 0? lob.specialtyCodeId: '0',
                "@SetToDefault": lob.setToDefault,
                "@SuppressCCIAI": lob.suppressCCIAI,
                "@SuppressCCIBP": lob.suppressCCIBP,
                "@SuppressMNAI": lob.suppressMNAI,
                "@SuppressMNBP": lob.suppressMNBP,
                ...{'@Delete': lob.delete},
            }));
        }
        //console.log(`-------i--------`);
        //console.log(crudPhysician);
        return crudPhysician;
    });
}

export function getNewPayerDisplayName(newPayer: Lob, lobOptions: PmDropdownOption[]): string {
    const lobName = lobOptions.find(option => option.value===newPayer.lobId)?.label;
    return `${lobName} - ${newPayer.description} - ${newPayer.physicianNo}`;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IPhysicianMaintenanceUIState, KnownActions> = (state: IPhysicianMaintenanceUIState | undefined, action: KnownActions) => {



    //-----------------------------------
    // REDUCER START
    //-----------------------------------
    if (state != undefined) {
        let newState = { ...state };
        switch (action.type) {

            case 'IS_UPDATE':
                return {
                    ...state,
                    changed: false
                }
                break;

            case 'IS_LEAVE':
                return {
                    ...state,
                    leave: action.data
                }
                break;

            case 'PM_UPDATE_PHYSICIANS_WITH_RESULTS':
                let resultsList: PhysicianInfoList = {
                    physicians: [{
                        ...DEFAULT_PHYSICIAN_INFO,
                        displayName: "- ADD A NEW PHYSICIAN -",
                    }]
                }
                let incomingPhysicians: MCMR_Physician[] = [];
                if (action.data.Physicians?.Physician) {
                    incomingPhysicians = ForceArray(action.data.Physicians.Physician);
                    if (!action.data.Physicians.Physician.length) {
                        incomingPhysicians.push((action.data.Physicians.Physician as any as MCMR_Physician));

                    } else {
                        incomingPhysicians = action.data.Physicians.Physician;
                    }

                    resultsList.physicians = resultsList.physicians.concat(
                        incomingPhysicians.map((p, index) => {
                            const trimmedMidInit: string = p['@MiddleInitial'].trim();
                            const formattedMiddleInitial: string =
                                trimmedMidInit.length > 0 ? ` ${trimmedMidInit}.` : "";
                            const displayName: string =
                                `${p['@LastName']}, ${p['@FirstName']}${formattedMiddleInitial}`;

                            return {
                                firstName: p['@FirstName'],
                                middleInitial: trimmedMidInit,
                                lastName: p['@LastName'],
                                address1: p['@Address'],
                                address2: p['@Address2'],
                                city: p['@City'],
                                state: {
                                    label: p['@StateID'],
                                    value: p['@StateID']
                                },
                                zip: p['@ZipCode'],
                                telephone: p['@TelephoneNo'],
                                npi: p['@PhysicianNPI'],
                                taxonomy: p['@TaxonomyCode'],
                                taxId: p['@TaxId'],
                                ssn: p['@SSN'],
                                idType: {
                                    label: p['@IdTypeID'],
                                    value: p['@IdTypeID']
                                },
                                flwaInfo: {
                                    groupNumber: p['@GroupNo'],
                                    groupSuffix: p['@GroupSuffix'],
                                    doctorNumber: p['@DoctorNo'],
                                    locationCode: p['@LocationCode'],
                                },
                                ilnyInfo: {
                                    billingDept: p['@BillingDept'],
                                    physicianAccountNumber: p['@PhysicianAccountNo'],
                                    idLocCode: p['@IdLocCode'],
                                    deptFlag: p['@DeptFlag'],
                                },
                                internalId: p['@ID'],
                                index: index,
                                displayName: displayName,
                            }
                        }
                        )
                    )

                    // Set the mapped results to our new UI state and direct the search tab over 
                    // to the results tab to display the new results. 
                    newState.physicianList = resultsList;
                newState.selectedTab = 1;
                }

                // If we do not have any physicians, we want to preserve the previous list.
                return newState;

            case 'PM_SELECT_CURRENT_PHYSICIAN':
                // Need to not reset the physician if the Too Many Results modal is showing.
                if (action.data !== null && action.data !== undefined && !state.showTooManyResultsModal) {
                    const selectedPhysician = state.physicianList.physicians.find
                        (
                            phys => phys.internalId == action.data
                        );
                    if (selectedPhysician) {
                        newState.selectedPhysician = selectedPhysician;
                    }
                    else {
                        console.log("Could not find a physician with the given internal id in the list! Action was:");
                        console.log(action);
                    }
                }
                return newState;

            case 'PM_SELECT_TAB':
                newState.selectedTab = action.data !== undefined ? action.data : state.selectedTab;
                return newState;

            case 'PM_SET_LOADING':
                // Need to specifically check for undefined since this is a raw bool.
                newState.loading = action.data !== undefined ? action.data : state.loading;
                console.log("Loading set to ");
                console.log(newState);
                return newState;

            case 'PM_SET_COUNT_INFO':
                newState.physicianCount = action.data.physicianCount;
                newState.lobCount = action.data.lobCount;
                return newState;

            case 'PM_SET_PHYSICIAN_COUNT_LIMIT':
                newState.physicianLimit = action?.data ?? -1;
                return newState;

            case 'PM_SET_TOO_MANY_RESULTS_FLAG':
                if (action.data !== undefined)
                    newState.showTooManyResultsModal = action.data;
                return newState;

            case 'PM_SEARCH_PHYSICIAN_CRUD':
                switch (action.status.status) {
                    case 'REQUEST':
                        newState.loading = true;
                        return newState;

                    case 'SUCCESS':

                        // Look up the data type for this request.
                        let requestDataType: string = "-1";
                        try {
                            requestDataType = (JSON.parse(action.data.data) as PmSearchRequest).Param.find(p => {
                                return p.Name === "@chvDataType"
                            })?.Value ?? "-1";
                        }
                        catch (ex) {
                            console.log("An exception occurred while trying to look up the physician search data type.", ex);
                        }

                        const pmInfo: MCMR_PhysicianMaintenanceInfo = action?.responseData?.PhysicianMaintenanceInfo;

                        // If data type 0, this is an initial data request. We need to map and set up control options.
                        if (requestDataType === "0") {

                            // Setup state selection options.
                            newState.stateDropdownOptions = mapControlOptions(pmInfo?.StateList?.State, "- Select State -");

                            // Setup id dropdown options.
                            newState.idDropdownOptions = mapControlOptions(pmInfo?.IDTypeList?.IDType, "- Select ID Type -");

                            // Setup LOB Dropdown options
                            newState.lobDropdownOptions = mapControlOptions(pmInfo?.LOBList?.LOB, "- Select LOB -");
                            // Setup Specialty Code Dropdown options
                            newState.specialtyCodeDropdownOptions = mapControlOptions(pmInfo?.SpecialtyCodeList?.SpecialtyCode, "- Select Specialty Code -");

                            newState.facilityDropdownOptions = mapFacilityPayerOptions(pmInfo?.FacilityList?.Facility);
                        }

                        const newPhysicianList: PhysicianInfoList = mapPhysiciansToState(pmInfo, newState.lobDropdownOptions, newState.facilityDropdownOptions, newState.stateDropdownOptions, newState.idDropdownOptions);
                        const newPhysCount: number = pmInfo?.Physicians['@Count'] ?? 0;
                        const newLobCount: number = newState.lobCount = pmInfo?.Physicians['@LOBCount'] ?? 0;
                        const badResponseFlag = checkForErrorResponse(newPhysicianList.physicians.length, newPhysCount, newLobCount);

                        // Set the physician and LOB count for this search.
                        newState.physicianCount = (pmInfo?.Physicians['@Count'] ?? 0) * 1;
                        newState.lobCount = (pmInfo?.Physicians['@LOBCount'] ?? 0) * 1;
                        if (!badResponseFlag) {
                        // Map the physician list to the new state.
                            newState.physicianList = newPhysicianList;
                        }

                        // If this is the initial data load, we need to see if we should display the results or search tab.
                        // The search tab is only displayed if we exceeded the physician limit in the request.
                        if (newState.physicianList.physicians.length <= 1 && ((newState.physicianCount > 0 || newState.lobCount > 0) || requestDataType === "0"))
                            newState.selectedTab = 0;
                        else
                            newState.selectedTab = 1;

                        // If we are not in an initial data load, have a count higher than 0, and not physicians in our 
                        // actual list, we need to show the error modal so users can be warned they exceeded their count.
                        if (requestDataType !== "0" && badResponseFlag) {
                            newState.showTooManyResultsModal = true;

                            // Need to rationalize the previous if statements into a more consolidated block.
                            newState.selectedTab = 0;
                        }

                        newState.loading = false;
                        return newState;

                    case 'FAIL':
                        // TODO - Insert API failure message if amigos deem necessary.
                        const messageSuffix: string = action?.status?.responseCode
                            ? `status code ${action?.status?.responseCode}.`
                            : "no response code!";
                        console.log(`The response to the Physician Search was a FAIL with ${messageSuffix}`);
                        newState.loading = false;
                        return newState;
                }

            case 'PM_ADD_MULTIPLE_PAYERS':
                // If we have valid data and a physician actually selected, init the lobList if needed and add on the new payers.
                if (action.data && action.data.length > 0) {
                    if (newState.selectedPhysician) {
                        newState.selectedPhysician = {...newState.selectedPhysician};
                        if (newState.selectedPhysician?.lobList === undefined)
                            newState.selectedPhysician.lobList = [];

                        newState.selectedPhysician.lobList = newState.selectedPhysician.lobList.concat(action.data);
                        newState = updateDelta({...newState.selectedPhysician, lobList: action.data}, newState);
        }
    }
                return updateSelectedPhysicianInPhysiciansList(newState);
            case 'PM_ADD_SINGLE_PAYER':
                if (action.data && newState.selectedPhysician) {
                    const newPayer = action.data;
                    newPayer.id = `New[${uniqueId()}]LOB`;
                    // TODO: do we need this check?
                    if (!newPayer.displayName?.trim() || newPayer.displayName === '- ADD A NEW PAYER -') {
                        newPayer.displayName = getNewPayerDisplayName(newPayer, state.lobDropdownOptions);
                    }
                    const lobList = newState.selectedPhysician.lobList ?? [];
                    newState.selectedPhysician = {...newState.selectedPhysician, lobList: [...lobList, newPayer]};
                    newState = updateDelta({...newState.selectedPhysician, lobList: [newPayer]}, newState);
                }
                return updateSelectedPhysicianInPhysiciansList(newState);
            case 'PM_DELETE_PAYER':
                if (action.data && newState.selectedPhysician) {
                    let lobList = newState.selectedPhysician.lobList ?? [];
                    action.data.delete = 'true';
                    lobList = without(lobList, action.data);
                    newState = updateDelta({ ...newState.selectedPhysician, lobList: [{...action.data}]}, newState);
                    newState.selectedPhysician = {...newState.selectedPhysician, lobList };
                }

                return updateSelectedPhysicianInPhysiciansList(newState);
            case 'PM_UPDATE_PAYER':
                if (action.data && newState.selectedPhysician) {
                    const lobList = newState.selectedPhysician.lobList?.map(payer => payer.id===action.data.id? action.data: payer) || [];
                    newState.selectedPhysician = {...newState.selectedPhysician, lobList};
                        newState = updateDelta({ ...newState.selectedPhysician, lobList: [action.data]}, newState);

                }
                return updateSelectedPhysicianInPhysiciansList(newState);

            case 'PM_ADD_PHYSICIAN':
                if (action.data) {
                    newState.physiciansAdded++;
                    // edited to ensure we don't change that first dropdown item
                    const newPhysician = {...action.data};
                    newPhysician.displayName = getPhysicianDisplayName(newPhysician);

                    newPhysician.internalId = `New[${uniqueId()}]Physician`;
                    //TODO - CONFIRM FUNCTIONALITY
                    newPhysician.lobList = [];
                    // edited to ensure we don't have any hanging references to the old array
                    newState.physicianList = {physicians: newState.physicianList.physicians.concat(newPhysician)};
                    newState.selectedPhysician = newPhysician;
                    newState = updateDelta(newPhysician, newState);
                    newState.selectedTab = 1;
                }
                return newState;
            case 'PM_DELETE_PHYSICIAN':
                if (action.data) {
                    const deletedPhysician = {...action.data, lobList: [], delete: 'true'};
                    newState.physicianList = {physicians: newState.physicianList.physicians.filter((physician) => physician.internalId !== deletedPhysician.internalId)};
                    newState.selectedPhysician = {...newState.physicianList.physicians?.[0]};
                    newState = updateDelta(deletedPhysician, newState);
                }
                return newState;

            case 'PM_UPDATE_PHYSICIAN':
                if (action.data) {
                    const physician = {...action.data, displayName: getPhysicianDisplayName(action.data)};
                    newState.physicianList = {physicians: newState.physicianList.physicians.map(p => p.internalId === physician.internalId? physician: p)};
                    newState.selectedPhysician = physician;
                    newState = updateDelta({...physician, lobList: []}, newState);
                }
                return newState;
        }
    }
    return state || defaultstate;
}