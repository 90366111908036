import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as PreBillFilterUI from './ui/PreBillFilterUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IPreBillFilterState = ICrudComponentState<MCPreBillFilter, PreBillFilterUI.IPreBillFilterUIState>;

export const actionCreators = createCrudComponentActions<PreBillFilterUI.ActionCreators>(PreBillFilterUI.actionCreators);
export type IPreBillFilterActions = typeof actionCreators;
export type IPreBillFilterActionProps = ICrudComponentActionProps<IPreBillFilterActions>
export const reducers = createCrudComponentReducers(CrudTypes.mctiPreBillIE, PreBillFilterUI.reducer);
export const  reducer = combineReducers<IPreBillFilterState>(reducers);

