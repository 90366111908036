import { Action, Reducer } from 'redux';
import { createAction, ActionTypes } from '../scripts/util/ActionHelpers';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface CounterState {
    count: number;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    increment: () => createAction('INCREMENT_COUNT'),
    decrement: () => createAction('DECREMENT_COUNT'),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<CounterState, KnownActions> = (state: CounterState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'INCREMENT_COUNT':
                return { count: state.count + 1 };
            case 'DECREMENT_COUNT':
                return { count: state.count - 1 };
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
        }
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || { count: 0 };
};
