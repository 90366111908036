import * as React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

import { URLs } from '../../../commonResources/constants';
import styled from 'styled-components';

import { Button, Typography } from 'ui-core';
import { Colors } from '../../../commonResources/colorVariables';

import { ARMLoginMessages } from '../Logon/ARMLoginMessages';
import { ARMMessage, IARMMessageProps } from '../Logon/ARMMessage';

import { ARMLoginWrapper, ARMLoginBody, ARMLoginForm } from '../Logon/styles/ARMLoginForm';
import { ARMLogoHeader } from '../Logon/ARMLogoHeader';
import { ARMCopyright } from '../Logon/ARMCopyright';

import { ModalConfirmation } from '../../common/ModalConfirmation';
import { ClearSecuritySession } from '../../../commonResources/window';



const TitleWrapperDiv = styled.div`
    color: ${Colors.chBlue100};
    ${Typography.headingSmall};
    ${Typography.largeLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
    ${Typography.fontFamily};
`;

const SSOLogoffWrapperDiv = styled.div`
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
`;

export const LogOffMessage = styled.span`

    display: block;
    margin-top: 40px;
    color: ${Colors.black};
   ${Typography.headingSmall};
    ${Typography.largeLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};

    text-align: center;
`;


const CenterDiv = styled.div`
    margin: auto;
    padding: 1px;
    text-align: center;

    #custom-link-button {
        margin-top: 40px;
        color: ${Colors.black};
        ${Typography.bold};
    }

    #non-sso-button {
        margin-top: 120px;
        color: ${Colors.black};
    }
`;

type SSOLogoffProps =
    IComponentProps
    & RouteComponentProps<{}>;

type SSOLogoffState = {
    ssoUrl: string;
    ssoLinkText: string;
}

interface IComponentProps {

}

const DEFAULT_STATE = {
    ssoUrl: "",
    ssoLinkText: "",

}



export class SSOLogoff extends React.Component<SSOLogoffProps, SSOLogoffState> {

    static defaultProps: IComponentProps = {

    }

    protected errorMessage: IARMMessageProps = ARMLoginMessages.instructions;

    constructor(props: SSOLogoffProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
     
        this.getSSOURL();
        this.getSSOURLText();

        ClearSecuritySession();
    }

    public componentDidUpdate = (prevProps: any) => {

    }

    setMessage(error: IARMMessageProps) {
        this.errorMessage = error;
    }

    public redirectToLogon(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        this.props.history.push("/Transactions/Logon");
    }

    public followLink(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (this.state.ssoUrl?.length > 0) {
            var lc = this.state.ssoUrl.toLowerCase();
            if (this.state.ssoUrl.startsWith("//"))   
                this.props.history.push(this.state.ssoUrl);
            else
                location.replace(this.state.ssoUrl);
            
            
        } else 
            this.props.history.push("/Transactions/Logon");

    }

    public getSSOURL = async () => {
        const url = URLs.api + '/api/data/securityInfo/getClientSSOReturnURL';
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ ssoUrl: jsonData });
            })
    }

    public getSSOURLText = async () => {
        const url = URLs.api + '/api/data/securityInfo/getClientSSOReturnURLText';
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ ssoLinkText: jsonData });
            })
    }

    public forceSingleClick = (e: React.MouseEvent<HTMLButtonElement>, funct: (e: React.MouseEvent<HTMLButtonElement>) => any) => {
        e.preventDefault();
        if (!(e.detail > 1)) { // makes sure this only fires on the first mouse click
            funct(e);
        }
    }



    public render() {
        const { ssoLinkText } = this.state;

        return (
            <SSOLogoffWrapperDiv>
                <ARMLoginWrapper>
                    <ARMLoginBody>
                        <ARMLogoHeader title={'ASSURANCE\u000D\u000A REIMBURSEMENT MANAGEMENT\u2122'} />
                        <ARMLoginForm id={'ssologoff'}>

                            <LogOffMessage>You have been logged off.</LogOffMessage>


                            <CenterDiv>

                                <Button
                                    domID="custom-link-button"
                                    type="button"
                                    size="large"
                                    buttonType="link"
                                    className="link-button align-top"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.followLink(e)}
                                >
                                    <span>{ssoLinkText && ssoLinkText?.length > 0 ? ssoLinkText : "Custom Link from Client"}</span>
                                </Button>
                                
                            </CenterDiv>

                            <CenterDiv>
                                <Button
                                    domID="non-sso-button"
                                    type="button"
                                    size="medium"
                                    buttonType="link"
                                    className="link-button align-top"
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.redirectToLogon(e)}
                                >
                                    <span>Return to Login Page without Single Sign-On</span>
                                </Button>
                            </CenterDiv>
                        </ARMLoginForm>
                        <ARMCopyright />
                    </ARMLoginBody>
                </ARMLoginWrapper>
            </SSOLogoffWrapperDiv>
        )
    }
}


