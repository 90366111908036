import { combineReducers, Reducer } from 'redux';
import * as ADRCoverLetterUI from '@store/ui/ADRCoverLetterUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from
    '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type IADRCoverLetterUIState = ICrudComponentState<MCADRCoverLetter, ADRCoverLetterUI.IADRCoverLetterUIState>;
export const actionCreators =
    createCrudComponentActions<ADRCoverLetterUI.ActionCreators>(ADRCoverLetterUI.actionCreators);
export type IADRCoverLetterActions = typeof actionCreators;
export type IADRCoverLetterActionProps = ICrudComponentActionProps<IADRCoverLetterActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiADRCoverLetter, ADRCoverLetterUI.adrCoverLetterInfoUIReducer);
export const reducer = combineReducers<IADRCoverLetterUIState>(reducers);

export const validationCallback: ValidationCallback<MCADRCoverLetter> = (crud: any): crud is MCADRCoverLetter => {
    let testCrud: MCADRCoverLetter = crud as MCADRCoverLetter;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCADRCoverLetter");
    return validShape;
};