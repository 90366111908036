import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as FormsUsedUI from './ui/FormsUsedUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IFormsUsedState = ICrudComponentState<MCFormsUsedType, FormsUsedUI.IFormsUsedUIState>;

export const actionCreators = createCrudComponentActions<FormsUsedUI.ActionCreators>(FormsUsedUI.actionCreators);
export type IFormsUsedActions = typeof actionCreators;
export type IFormsUsedActionProps = ICrudComponentActionProps<IFormsUsedActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiFormsUsed, FormsUsedUI.reducer);
export const reducer = combineReducers<IFormsUsedState>(reducers);

export const validationCallback: ValidationCallback<MCFormsUsedType> = (crud: any): crud is MCFormsUsedType => {
    let testCrud: MCFormsUsedType = crud as MCFormsUsedType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCFormsUsedType");
    validShape = validShape && ValidateJSONSchema(testCrud?.FormsUsedMaintenanceInfo, "MCFormsUsedMaintenanceInfo");
    validShape = validShape && ValidateJSONSchema(testCrud?.FormsUsedMaintenanceInfo?.Forms, "MCFormsUseds");
    if (validShape) {
        VerifyArray(testCrud?.FormsUsedMaintenanceInfo?.Forms, "Form");
        validShape = ValidateArray(testCrud?.FormsUsedMaintenanceInfo?.Forms?.Form, "MCFormsUsed");
    }

    return validShape;
}