import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as AEIUI from './ui/ManageHelpNotesForEditsUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IAEIState = ICrudComponentState<MCAEIErrorType, AEIUI.IAEIUIState>;
export const actionCreators = createCrudComponentActions<AEIUI.ActionCreators>(AEIUI.actionCreators);
export type IAEIActions = typeof actionCreators;
export type IAEIActionProps = ICrudComponentActionProps<IAEIActions>
export const reducers = createCrudComponentReducers(CrudTypes.mctiErrorLvlAdditonalInfo, AEIUI.reducer);
export const reducer = combineReducers<IAEIState>(reducers);

export const validationCallback: ValidationCallback<MCAEIErrorType> = (crud: any): crud is MCAEIErrorType => {
    let testCrud: MCAEIErrorType = crud as MCAEIErrorType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCAEIErrorType");
    if (validShape) {
        if (testCrud.ErrorsWithNotes?.CriteriaList === null) {
            testCrud.ErrorsWithNotes.CriteriaList = { Criteria: new Array<MCAEICriteria>() };
        }

        if (testCrud.ErrorsWithNotes.CriteriaList?.Criteria &&
            !Array.isArray(testCrud.ErrorsWithNotes.CriteriaList.Criteria)) {
            let arr = new Array<MCAEICriteria>();
            arr.push(JSON.parse(JSON.stringify(testCrud.ErrorsWithNotes.CriteriaList.Criteria)));
            testCrud.ErrorsWithNotes.CriteriaList.Criteria = arr;
        }
    }
    return validShape;
}