
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tabs, TabContainer, ITabSelectInfo, Button, Grid, Caret, Help } from 'ui-core'; 
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonOK } from '@common/DialogWrapper';
import { ImageButton, IImageButtonImage } from '@common/ImageButton';
import { DialogFieldset, DialogLegend, DialogLabel } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { Input } from '@common/UICLWrappers/ARMInput';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell'
import { TextArea } from '@common/UICLWrappers/ARMTextArea';
import { RadioButtonComponent } from '@common/RadioButton';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IViewResponsesState, IViewResponsesActionProps, actionCreators } from '@store/ViewResponses';
import { getRawToken } from '@scripts/session/SecurityToken';
import * as wind from '@commonResources/window';
import { URLs } from '@commonDevResources/constants';
import { ARMHeaderCell } from '../../common/UICLWrappers/ARMHeaderCell';
import { ResponseGridData, CategoryItem } from '../../../store/ui/ViewResponsesUI';
import { Log, LogMessage } from '../../../scripts/util/LogHelper';
import { ISortIndicator } from '@scripts/util/SortHelper'

export const GridWrapper = styled.div`
                #grid-wrapper-ViewResponses-available-grid {

        height:200px;

        table tbody tr > td:first-child:not(.select-checkbox){
            padding-left:30px;
        }

        table > thead > tr > td {
            height:32px;
            background-color:#9BA1A9 !important;
            font-weight:bold;
            font-family:"Core Sans C","Century Gothic","AppleGothic",sans-serif;
            position:sticky;
            top:0;
        }

        #thPayer{
            padding-bottom:3.5px;
        }

        table tbody tr > td{
            height:32px !important;
            padding-top:8px;
        }

        /* border-bottom: 0 !important; */
    }

    table tbody tr:hover{
        background-color:#edf9ff !important;
        cursor:pointer !important;
    }

    max-height:500px;
    overflow-y:scroll;
    background-color:white;
    border: black 1px solid;

    .empty-row{
        background-color:white !important;
    }

    .PayerName {
        min-width:300px;
        max-width:300px;
        padding-bottom:5px !important;
    }
    
    table tbody tr > td{
        height:25px !important;
    }

    table tbody tr td div{
        min-height:1px;
    }
#ViewResponses-available-grid thead tr th 
 {
    background-color: lightgrey;
    display: table-header;
}
#theadcol,#theadcol2
{
vertical-align:middle;
text-align:center;
color:#37474F;
padding-left:40px;
padding-right:18px;
border:1px solid white;
border-bootom:0px;
}
.oldest,.newest,.statusCode,.categoryCode
{
border:1px solid white;
border-bootom:0px;
}
`;

export const ContentWrapper = styled.div`
                display: flex;
                flex:1 1 auto;
                flex-direction: row;
`;

export const ContentRowWrapper = styled.div`
                display: flex;
                flex:3;
                flex-direction: row
`;

export const ContentGridColumnWrapper = styled.div`
                display: flex;
                flex: 1;
                height:230px;

                #ViewResponses-available-grid thead tr th 
                {
                                background-color: lightgrey;
                                display: table-header;
                }

                #ViewResponses-available-grid tbody tr
                {
                                background-color: white;
                                padding-right:10px !important;
                }

                #ViewResponses-available-grid tbody tr td
                {
                                width:14% !important;
                }

                #ViewResponses-available-grid tbody tr td:hover{ 
                background-color: lightyellow;
                }
`;

export const ContentColumnWrapper = styled.div`
                display: flex;
                flex: 1;
                width:fit-content;
                height:30px;
                flex-direction: column;
                padding:0px 5px 0px 5px !important;
                #datePosted
                {
                                margin-top:0px;
                                padding-top:0px;
                                height:15px;
                
                }
                #datePosted_label
                {
                                margin-top:5px;
                                padding-top:0px;
                                height:15px;
                }
                #dateEffective
                {
                                margin-top:0px;
                                padding-top:0px;
                                height:15px; 
                }
                #dateEffective_label
                {
                                margin-top:5px;
                                padding-top:0px;
                                height:15px;
                }
`;

export const DialogCaptionHelp = styled.div`
                ${Typography.ARMFontFamily};
                ${Typography.bold};
                ${Typography.large};
                ${Typography.defaultLineHeight};
                color: ${Colors.white};
                padding: 0px;
                text-align: right;
                .help-button {
                                padding: 0px;
                                padding-top: 10px;
                                padding-right: 10px; 
                }
`;

export const FormRowBottom = styled.div`
                display: flex;
                flex: 1 1 auto; 
                width:100%;
                height:70px;
                flex-direction:row;
                .text-area#Category ,.text-area#Status
                {
                height:50px !important;
                width:700px !important;
                line-height:15px !important;
                }
                `;

export const FormRow = styled.div`
                display: flex;
                flex: 1 1 auto;
                flex-direction: row;
                width:100%;
                .margin-padding {
                                margin-left: 5px;
                                margin-right: 5px;
                                color:black; 
                }
                .selectDropDown
                {
                                margin-left: 5px;
                                margin-right: 5px;
                                color:black; 
                                width:400px;
}
                #TabContainerResponse
                {
                                width:780px !important;
                                line-height:3px;
                }
                
`;

export const FormRowRightAlign = styled.div`
                display: flex;
                flex: 1 1 auto;
                justify-content:flex-end;
`;
export const FormRowHelp = styled.div`
                display: flex;
                flex: 1 auto; 
                padding-top:18px;
                justify-content:flex-start;
                #btnCategory,#btnCategory:hover,#btnStatus,#btnStatus:hover
                {
                    padding-left:0px !important;
                    width:35px !important;
                    background-color:#ebeced !important;
                    border:1px solid #ebeced;
                }
                #btnCategory svg,#btnStatus svg
                {
                    width:35px;
                    height:30px;
                }
`;

export const InputWrapper = styled.div`
                margin-left: 5px;
                margin-right: 5px;
                .jQubAi
                {
                width:800px;
                height:500px;
}
`;

export const SelectButtons = styled.div`
                display: flex;
                flex: 1 1 auto;
                flex-direction: row;
                padding-top:10px;
                button {
                                margin: 4px;
                                width: 85px;
                                text-align: center;
                }
`;
type param = {
    'Name': string;
    'Value': string;
};
interface IComponentProps {
    canView: boolean
};

interface HeaderProps {
    columns: {

    }
}

interface IComponentState {
    sortIndicator: ISortIndicator;
    filterRecords: any[];
};


export const DEFAULT_STATE: IComponentState = {
    sortIndicator: { sortDirection: '', sortColumn: '' },
    filterRecords: []
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IViewResponsesProps = IMergeCrudComponentProps<IViewResponsesState, IViewResponsesActionProps, IOwnProps>;


interface MCViewRespParam {
    "Name": string
    "Value": string
}
interface MCViewRespParamL1 {
    Param: MCViewRespParam[]
}

interface MCViewRespParamList {
    "@ID": string
    "@Name": any
}
interface PayerStatusResponseInfo_Search {
    PayerStatusResponseInfo_Search: MCViewRespParamL1
}

export class ViewResponses extends React.Component<IViewResponsesProps, IComponentState> {
 
    static defaultProps: IComponentProps = {
        canView: false
    };

    constructor(props: IViewResponsesProps) {
        super(props);

        this.handleSort.bind(this);
        this.handleSort.bind(this);
        

        this.state = {
            filterRecords: [],
            sortIndicator: {
                sortColumn: '',
                sortDirection: ''
            }
        }
    }

    public componentDidMount() {
        if (this.props.canView) {

            this.prepareCategoryCodeAndStatusCodeList();

        }
        else
            this.props.history.push("/LandingPage");


    }


    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public prepareSearch() {
        let Params = [] as MCViewRespParam[];
        let Level1Ip = {} as MCViewRespParamL1;

        let categoryFrom = this.props.dataStore.ui.categoryFrom;
        let categoryTo = this.props.dataStore.ui.categoryTo;
        let statusFrom = this.props.dataStore.ui.statusFrom;
        let statusTo = this.props.dataStore.ui.statusTo;
        let clientId = this.props.dataStore.ui.clientId;

        if (categoryTo === "")
            categoryTo = categoryFrom;
        else
            if (categoryTo === "")
                categoryTo = "";

        if (statusTo === "")
            statusTo = statusFrom;
        else
            if (statusFrom === "")
                statusTo = "";

        this.props.action.ui.updateStatusTo(statusTo);
        this.props.action.ui.updateCategoryTo(categoryTo);

        Params.push({ Name: '@chvClientId', Value: clientId });
        Params.push({ Name: '@chvDirection', Value: '0' });
        Params.push({ Name: '@chvColumn', Value: '2' });
        Params.push({ Name: '@chvDateType', Value: this.props.dataStore.ui.datePostedOrEffective === '1' ? 'E' : 'P' });
        Params.push({ Name: '@chvCategoryCodeFrom', Value: categoryFrom });
        Params.push({ Name: '@chvCategoryCodeTo', Value: categoryTo });
        Params.push({ Name: '@chvStatusCodeFrom', Value: statusFrom });
        Params.push({ Name: '@chvStatusCodeTo', Value: statusTo });
        //Params.push({ Name: "@chvPayerList", Value: "HUMANA" });
        Params.push({ Name: "@chvPayerList", Value: this.props.dataStore.ui.selectedPayerNameList.join(',') });

        Level1Ip.Param = Params;

        let payerStatusResponseSearchInfo = {} as PayerStatusResponseInfo_Search;
        payerStatusResponseSearchInfo.PayerStatusResponseInfo_Search = Level1Ip;

        this.props.action.crud.search({
            crudId: CrudTypes.mcti277ResponsesResults, data: JSON.stringify(Level1Ip)
        });
        LogMessage("debug", JSON.stringify(Level1Ip));
        setTimeout(() => {
            const record = this.buildViewResponseGridData()

            this.setState({ filterRecords: [...record] })
        }, 500);
    }


    public onOKViewResponse(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    public onSearchViewResponse(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.clearResponse();
        this.prepareSearch();
        this.props.action.ui.selectTab(1);

    }


    public onClearViewResponse(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.clearViewResponse();
        setTimeout(() => {
           this.setState({ filterRecords: [] })
        }, 500);
    }

    public onClickPrintButton(e: React.ChangeEvent<HTMLButtonElement>) {

        let data: any[] = [...this.state.filterRecords]; 
        
        let { categoryList} = this.props.dataStore.ui;
        let responseList: ResponseGridData[] = data;
        let categoryCodeList = responseList.map((d: ResponseGridData) => d.categoryCode);
      
        let filteredCategoryList = categoryList.CategoryList.Category.filter((cate: CategoryItem) => categoryCodeList.indexOf(cate["@ID"]) >= 0);
        
        let statusCodeList = responseList.map((d: ResponseGridData) => d.statusCode);
        
        let filteredStatusList = categoryList.StatusList.Status.filter((stat: CategoryItem) => statusCodeList.indexOf(stat["@ID"]) >= 0);
       
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);

        iframe?.contentWindow?.document.write('<table>' + this.buildTable(data).innerHTML + '</table>');
        let div = document.createElement('div') as HTMLDivElement;
        let brk = document.createElement('br') as HTMLBRElement;
        let brk2 = document.createElement('br') as HTMLBRElement;
        let brk3 = document.createElement('br') as HTMLBRElement;
        div.appendChild(brk);
        div.appendChild(brk2);
        div.appendChild(brk3);
        iframe?.contentWindow?.document.write('<div>' + div.innerHTML + '</div>');
        iframe?.contentWindow?.document.write('<table>' + this.buildTableCategory(filteredCategoryList).innerHTML + '</table>');
        iframe?.contentWindow?.document.write('<table>' + this.buildTableStatus(filteredStatusList).innerHTML + '</table>');
        iframe.style.width = '500px';
        iframe.style.height = '700px';
        iframe?.contentWindow?.document.close();
        iframe?.contentWindow?.focus();
        iframe?.contentWindow?.print();

    }

    public buildTable(data:any[]): HTMLDivElement {
        var div = document.createElement("usertablediv") as HTMLDivElement;
        div.style.height = '400px';
        var table = document.createElement("usertable") as HTMLTableElement;

        var thead = document.createElement("thead");
        var tbody = document.createElement("tbody");


        var headRow = document.createElement("tr");
        let th = document.createElement("th");
        th.style.backgroundColor = '#DADCE0';
        th.style.border = '1px solid';
        th.style.borderSpacing = '2px';
        th.style.fontSize = '20px';
        th.style.width = '350px';
        th.appendChild(document.createTextNode("Payer Name"));
        headRow.appendChild(th);
        ["From", "Thru", "CategoryCode", "StatusCode", "Count"].forEach(function (el) {
            var thc = document.createElement("th");
            thc.style.backgroundColor = '#DADCE0';
            thc.style.border = '1px solid';
            thc.style.borderSpacing = '2px';
            thc.style.fontSize = '20px';
            thc.style.width = '230px';
            thc.appendChild(document.createTextNode(el));
            headRow.appendChild(thc);
        });
        thead.appendChild(headRow);
        table.appendChild(thead); 

        data.forEach(function (el) {
            let tr = document.createElement("tr");
            tr.style.border = '1px solid';
            tr.style.borderSpacing = '5px';
            for (let o in el) {
                let td = document.createElement("td");
                td.style.color = '#000000';
                td.appendChild(document.createTextNode(el[o]))
                tr.appendChild(td);
            }
            tbody.appendChild(tr);
            table.appendChild(tbody);
        });
        div.appendChild(table);
        //div.appendChild(brk); div.appendChild(brk);
        //div.appendChild(brk); 
        return div;
    }

    public buildTableCategory(categoryData: CategoryItem[]): HTMLTableElement {
        document.createElement("catgorytable");
        var table = document.createElement("catgorytable") as HTMLTableElement;
        table.style.border = '1px solid';
        var thead = document.createElement("thead");
        var tbody = document.createElement("tbody");

        var headRow = document.createElement("tr");
        var th = document.createElement("th");
        th.style.backgroundColor = '#DADCE0';
        th.style.border = '1px solid';
        th.style.borderSpacing = '2px';
        th.style.fontSize = '20px';
        th.style.width = '230px';
        th.appendChild(document.createTextNode('Category'));
        headRow.appendChild(th);
        var thdesc = document.createElement("th");
        thdesc.style.backgroundColor = '#DADCE0';
        thdesc.style.border = '1px solid';
        thdesc.style.borderSpacing = '2px';
        thdesc.style.fontSize = '20px';
        thdesc.style.width = '500px';
        thdesc.appendChild(document.createTextNode('Description'));
        headRow.appendChild(th);
        headRow.appendChild(thdesc);
        thead.appendChild(headRow);
        table.appendChild(thead);
         
        
        categoryData.map((cat: CategoryItem) => {
            let tr = document.createElement("tr");
            tr.style.border = '1px solid';
            tr.style.borderSpacing = '2px';
            let td = document.createElement("td");
            td.style.color = '#000000';
            td.appendChild(document.createTextNode(cat["@ID"] || ""));
            let tddesc = document.createElement("td");
            tddesc.style.color = '#000000';
            tddesc.appendChild(document.createTextNode(cat["@Name"]));
            tr.appendChild(td);
            tr.appendChild(tddesc);
            tbody.appendChild(tr);

        });

        table.appendChild(tbody);

        return table;
    }

    public buildTableStatus(statusData: CategoryItem[]): HTMLTableElement {
        document.createElement("statustable");
        var table = document.createElement("statustable") as HTMLTableElement;
        var thead = document.createElement("thead");
        var tbody = document.createElement("tbody");

        var headRow = document.createElement("tr");
        var th = document.createElement("th");
        th.style.backgroundColor = '#DADCE0';
        th.style.border = '1px solid';
        th.style.borderSpacing = '2px';
        th.style.fontSize = '20px';
        th.style.width = '200px';
        th.appendChild(document.createTextNode('Status'));
        headRow.appendChild(th);

        var thdesc = document.createElement("th");
        thdesc.style.backgroundColor = '#DADCE0';
        thdesc.style.border = '1px solid';
        thdesc.style.borderSpacing = '2px';
        thdesc.style.fontSize = '20px';
        thdesc.style.width = '500px';
        thdesc.appendChild(document.createTextNode('Description'));
        headRow.appendChild(th);
        headRow.appendChild(thdesc);
        thead.appendChild(headRow);
        table.appendChild(thead);
        statusData.map((stat: CategoryItem) => {
            let tr = document.createElement("tr");
            tr.style.border = '1px solid';
            tr.style.borderSpacing = '2px';
            let td = document.createElement("td");
            td.style.color = '#000000';
            td.appendChild(document.createTextNode(stat["@ID"]));
            let tddesc = document.createElement("td");
            tddesc.style.color = '#000000';
            tddesc.appendChild(document.createTextNode(stat["@Name"]));
            tr.appendChild(td);
            tr.appendChild(tddesc);
            tbody.appendChild(tr);
        });
        table.appendChild(tbody);
        return table;
    }

    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    prepareCategoryCodeAndStatusCodeList = async () => {
        this.props.dataStore.ui.isViewResponseLoaded = false;
        
        var url = URLs.api + '/api/data/getView277ResponsesList'; 

        const response = await fetch(url, {
            method: 'GET',
            headers: {  
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        }).then(response => {
            if (response.status === 200) {
                response.json().then(body => { this.processResponse(body); return body; });
            } else {
                throw new Error(response.statusText);
            }
        })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error",`promise rejected: URL:${url} with Error: ${error}`);
            });
    }
    processResponse = (body: any) => {
        this.props.dataStore.ui.isViewResponseLoaded = true;
        setTimeout(() => { this.props.action.ui.loadCategoryStatusList(body);  }, 10);

    }


    getSearchTab() {
        return (
            <React.Fragment>

                <ContentColumnWrapper>
                    <FormRow>
                        <DialogLabel id='search-type-label'>Date:</DialogLabel>
                        <RadioButtonComponent
                            id="datePosted"
                            label="Posted"
                            checked={this.props.dataStore.ui.datePostedOrEffective === "0"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateDatePostedOrEffective("0")}
                            disabled={false}
                        />
                        <RadioButtonComponent
                            id="dateEffective"
                            label="Effective"
                            checked={this.props.dataStore.ui.datePostedOrEffective === "1"}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateDatePostedOrEffective("1")}
                            disabled={false}
                        />
                    </FormRow>
                </ContentColumnWrapper>

                <FormRow>
                    <Input
                        className='margin-padding'
                        domID="CategoryCodeFrom"
                        label="Category:"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateCategoryFrom(e.target.value)}
                        initialValue={this.props.dataStore.ui.categoryFrom}
                    />
                    <Input
                        className='margin-padding'
                        domID="CategoryCodeTo"
                        label="Thru:"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateCategoryTo(e.target.value)}
                        initialValue={this.props.dataStore.ui.categoryTo}
                    />
                    <FormRowHelp>
                        <Button
                            domID="btnCategory"
                            Title={'HelpIcon'}
                            className="help-button"

                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickHelpIcon(e, '/Support/Help/HELP_DYN.asp?4')}
                        ><Help
                                className="test"
                                fillColor="#37474F"
                                title="title"
                            /></Button> 
                    </FormRowHelp>
                </FormRow>
                <FormRow>
                    <Input
                        className='margin-padding'
                        domID="StatusCodeFrom"
                        label="Status:"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateStatusFrom(e.target.value)}
                        initialValue={this.props.dataStore.ui.statusFrom}
                    />
                    <Input
                        className='margin-padding'
                        domID="StatusCodeTo"
                        label="Thru:"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateStatusTo(e.target.value)}
                        initialValue={this.props.dataStore.ui.statusTo}
                    />
                    <FormRowHelp>
                        <Button
                            domID ="btnStatus"
                            Title={'HelpIcon'} 
                            className="help-button"

                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickHelpIcon(e, '/Support/Help/HELP_DYN.asp?5')}
                        ><Help
                                className="test"
                                fillColor="#37474F"
                                title="title"
                            /></Button>
                    </FormRowHelp>
                </FormRow>
                <FormRow>
                    <SelectComponent
                        key={"@ID"}
                        className='selectDropDown'
                        label='Payer:'
                        title='Payer'
                        size={10}
                        width='60%'
                        multiple='true'
                        onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPayerList(e)}
                        optionFields={{
                            value: "ID",
                            text: "Name"
                        }}
                        records={this.props.dataStore.ui.payerList}
                        selectedMultiValue={this.props.dataStore.ui.selectedPayerList}
                    />
                </FormRow>
                <FormRow>
                    <SelectButtons>
                        <Button
                            domID="Search"
                            name="Search"
                            buttonType="standard"
                            size="small"
                            type="button"
                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onSearchViewResponse(e)}
                        />
                        <Button
                            domID="Search"
                            name="Clear"
                            buttonType="standard"
                            size="small"
                            type="button"
                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClearViewResponse(e)}
                        />
                    </SelectButtons>
                </FormRow>
            </React.Fragment>
        );
    }



    public onClickHelpIcon(e: React.ChangeEvent<HTMLButtonElement>, url: string) {
        e.preventDefault();
        wind.BigHelp(url);
    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0) {
            return [];
        };
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i]);
        }
        return result;
    }

    onSelectPayerList(e: React.ChangeEvent<HTMLSelectElement>) {
        let selectedValue = this.getSelectedOptions(e);
        this.props.action.ui.selectPayerList(
            {

                uiData: {
                    selected: selectedValue.map(sel => sel.value),
                    selectedPayerName: selectedValue.map(sel => sel.text),
                }
            });
    }

    getViewResponsesGridColumnHeaders = () => {
        return new Set([
           
            {
                dataName: "payerName",
                text: 'Payor Name',
                sortable: true,
                cellType: "text",
                isSorted: 0
            },
            {
                dataName: "oldest",
                text: "First",
                sortable: true,
                cellType: "text",
                isSorted: 0
            },
            {
                dataName: "newest",
                text: "Last",
                sortable: true,
                cellType: "text",
                isSorted: 0
            },
            {
                dataName: "categoryCode",
                text: "Category",
                sortable: true,
                cellType: "text",
                isSorted: 0
            },
            {
                dataName: "statusCode",
                text: "Status",
                sortable: true,
                cellType: "text",
                isSorted: 0
            },

            {
                dataName: "count",
                text: "Count",
                sortable: true,
                cellType: "text",
                isSorted: 0
            }
        ]);
    };

    handleSort = (sortIndicator: ISortIndicator) => {

        let sortedRecords: any[] = [];




        if (sortIndicator.sortDirection == 'up') {
            switch (sortIndicator.sortColumn) {
              
                case "oldest":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['oldest'] > b['oldest'])
                            return -1
                        else
                            return 1
                        //a['ID'] - b['ID']
                    });

                    break;

                case "newest":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['newest'] > b['newest'])
                            return -1
                        else
                            return 1
                        //a['ID'] - b['ID']
                    });

                    break;
                case "categoryCode":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['categoryCode'] > b['categoryCode'])
                            return -1
                        else
                            return 1
                        //a['ID'] - b['ID']
                    });

                    break;
                case "statusCode":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['statusCode'] > b['statusCode'])
                            return -1
                        else
                            return 1
                        //a['ID'] - b['ID']
                    });

                    break;
                case "count":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        return (a['count'] - b['count']) 
                    });

                    break;
                case "payerName":

                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['payerName'].toLowerCase() > b['payerName'].toLowerCase())
                            return -1
                        else
                            return 1
                    });
                    break;
            }

        } else if (sortIndicator.sortDirection == 'down') {
            switch (sortIndicator.sortColumn) {
                case "ID":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['ID'] > b['ID'])
                            return 1
                        else
                            return -1
                        //a['ID'] - b['ID']
                    });

                    break;
                case "oldest":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['oldest'] > b['oldest'])
                            return 1
                        else
                            return -1
                        //a['ID'] - b['ID']
                    });

                    break;
                case "newest":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['newest'] > b['newest'])
                            return 1
                        else
                            return -1
                        //a['ID'] - b['ID']
                    });

                    break;
                case "count":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        return (b['count'] - a['count']) 
                    });

                    break;
                case "categoryCode":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['categoryCode'] > b['categoryCode'])
                            return 1
                        else
                            return -1
                        //a['ID'] - b['ID']
                    });

                    break;
                case "statusCode":


                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['statusCode'] > b['statusCode'])
                            return 1
                        else
                            return -1
                        //a['ID'] - b['ID']
                    });

                    break;
                case "payerName":
                    sortedRecords = this.state.filterRecords.sort((a, b) => {
                        if (a['payerName'].toLowerCase() > b['payerName'].toLowerCase())
                            return 1
                        else
                            return -1
                    });
                    break;
                //// insert descending sorting routines here

            }
        }

        this.props.action.ui.loadGridPayerList(sortedRecords);
        this.props.action.ui.loadGridSorted(true);
        this.setState({ filterRecords: sortedRecords, sortIndicator: sortIndicator });

    }

    public onSelectAvailableViewResponses(e: any, cat: string, stat: string) {

        this.props.action.ui.loadCategoryDescription(cat);
        this.props.action.ui.loadStatusDescription(stat);
    }

    getResultsTab() {



        const header = ({ columns }: HeaderProps) => <thead>
            {
                <React.Fragment>
                    <tr>
                        
                        <th></th>
                        <th id="theadcol" colSpan={2}>
                            Date Range
                        </th>
                        <th id="theadcol2" colSpan={2}>  Code Set </th>
                        <th></th>
                    </tr>
                    <tr>
                        
                        <ARMHeaderCell
                            dataName="payerName"
                            text="Payer Name"
                            cellType="text"
                            sortable={true}
                            isSorted={1}
                            sortHandler={this.handleSort}
                            sortIndicator={this.state.sortIndicator}

                        />
                        <ARMHeaderCell
                            dataName="oldest"
                            text="First"
                            cellType="text"
                            sortable={true}
                            isSorted={1}
                            sortHandler={this.handleSort}
                            sortIndicator={this.state.sortIndicator}

                        />
                        <ARMHeaderCell
                            dataName="newest"
                            text="Last"
                            cellType="text"
                            sortable={true}
                            isSorted={1}
                            sortHandler={this.handleSort}
                            sortIndicator={this.state.sortIndicator}

                        />
                        <ARMHeaderCell
                            dataName="categoryCode"
                            text="Category"
                            cellType="text"
                            sortable={true}
                            isSorted={1}
                            sortHandler={this.handleSort}
                            sortIndicator={this.state.sortIndicator}

                        />
                        <ARMHeaderCell
                            dataName="statusCode"
                            text="status"
                            cellType="text"
                            sortable={true}
                            isSorted={1}
                            sortHandler={this.handleSort}
                            sortIndicator={this.state.sortIndicator}

                        />
                        <ARMHeaderCell
                            dataName="count"
                            text="Count"
                            cellType="text"
                            sortable={true}
                            isSorted={1}
                            sortHandler={this.handleSort}
                            sortIndicator={this.state.sortIndicator}

                        />

                    </tr>
                </React.Fragment>
            }
        </thead>


        const row = ({ record }: ViewResponseRecord) =>
            <tr className="onMouseOver" role="button"
                onMouseOver={(e) => this.onSelectAvailableViewResponses(e, record.categoryCode, record.statusCode)}>
                {
                    <React.Fragment>
                        <ARMRowCell value={record.payerName} />
                        <ARMRowCell value={record.oldest} />
                        <ARMRowCell value={record.newest} />
                        <ARMRowCell value={record.categoryCode} />
                        <ARMRowCell value={record.statusCode} />
                        <ARMRowCell value={record.count} />
                    </React.Fragment>
                }
            </tr>
        return (
            <React.Fragment>
                <GridWrapper id="ViewResponses12">
                    <Grid
                        label='All ViewResponses'
                        columns={this.getViewResponsesGridColumnHeaders()}
                        domID='ViewResponses-available-grid'
                        isFixedHeader={true}
                        maxHeight='220px'
                        width='800px'
                        selectionKey='ID'
                        records={[...this.state.filterRecords]}
                        initialSortingKey='ID'
                        rowComponent={row}
                        headerComponent={header}
                    />
                </GridWrapper>
                <FormRowRightAlign>
                    <Button
                        domID="addButton"
                        name="Print"
                        buttonType="standard"
                        size="small"
                        type="button"
                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickPrintButton(e)}
                    />
                </FormRowRightAlign>
                <DialogFieldset>
                    <DialogLegend>Code Set Details</DialogLegend>
                    <FormRowBottom>
                        <TextArea
                            className='text-area'
                            domID="Category"
                            label="Category:"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateStatusFrom(e.target.value)}
                            initialValue={this.props.dataStore.ui.selectedCategoryDescription}
                            width={"800px"} 
                        />
                    </FormRowBottom>
                    <FormRowBottom>
                        <TextArea
                            className='text-area'
                            domID="Status"
                            label="Status:"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.action.ui.updateStatusTo(e.target.value)}
                            initialValue={this.props.dataStore.ui.selectedStatusDescription}
                            width={"800px"} 
                        />
                    </FormRowBottom>
                </DialogFieldset>
            </React.Fragment>
        );
    }
    GridData() {
        return this.props.dataStore.ui.filterRecords;
        //return this.state.filterRecords;
    }


    getViewResponseGridRecords(ViewResponse: MCViewResponseItem) {

        if (!ViewResponse) return;

        return {
            payerName: ViewResponse["@PayerName"],
            oldest: ViewResponse["@Oldest"],
            newest: ViewResponse["@Newest"],
            categoryCode: ViewResponse["@CategoryCode"],
            statusCode: ViewResponse["@StatusCode"],
            count: ViewResponse["@Count"],
        };
    }


    buildViewResponseGridData() {
        let records = [];
        let ViewResponses:any = this.props.dataStore.crud.dataSearch?.Responses.Response;

        if (ViewResponses) {
            if (!Array.isArray(ViewResponses)) {
                records.push(this.getViewResponseGridRecords(ViewResponses));

            }
            else {
                for (let viewresp of ViewResponses) {
                    records.push(this.getViewResponseGridRecords(viewresp));
                }
            }
        }
        
        return records;
    }

    getTabs() {
        var tabJson = [
            {
                domID: 'ViewResponsesSearch',
                label: 'Search',
                tabContent: this.getSearchTab()
            },
            {
                domID: 'ViewResponsesResults',
                label: 'Results',
                tabContent: this.getResultsTab()
            },
        ];
        return tabJson;
    }

    onTabSelect(e: React.MouseEvent<HTMLElement>, selectInfo: ITabSelectInfo) {
        e.preventDefault(); 
        this.props.action.ui.selectTab(selectInfo.selectedTabIndex);
        
    }

    onTabChange(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) {
        e.preventDefault(); 
        this.props.action.ui.selectTab(selectInfo.tabIndex);
       
    }

    public render() {
        var width: React.CSSProperties = {
            width: '300px'
        }

        var instruction = <React.Fragment>Set up search criteria for 277 responses in the Search tab. Click the Results tab to view, sort, and print results.<br /><br /><i>Click the question mark in the title bar for more details.</i></React.Fragment>;
        var buttons = <DialogButtonOK onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKViewResponse(e)} />

        return (

            <InputWrapper>
                <DialogWrapper title="View 277 Responses" instruction={instruction} helpUrl='/Support/Help/HELP_Reports_View277Responses.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                    <ContentWrapper>
                        <FormRow>
                            <TabContainer
                                domID="TabContainerResponse"
                                initialTab={this.props.dataStore?.ui.selectedTabIndex}
                                className='tabcss'
                                onTabChange={(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) => this.onTabChange(e, selectInfo)}
                                tabs={this.getTabs()}
                                width='780px'
                            />
                        </FormRow>
                    </ContentWrapper>
                    <ModalConfirmation
                        isOpen={this.props.dataStore?.confirm.isOpen}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                        message={this.props.dataStore?.confirm.message}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}
                    />
                </DialogWrapper>
            </InputWrapper>
        );
    }
};

var connectedHoc = connect<IViewResponsesState, IViewResponsesActionProps, IOwnProps, IViewResponsesProps, ApplicationState>(
    createCrudMapStateToProps('viewResponses'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ViewResponses);

export default withRouter(connectedHoc);
