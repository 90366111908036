import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';


import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';

import { IClaimFilterState, IClaimFilterActions, IClaimFilterActionProps, actionCreators/*, validationCallback */ } from '@store/ClaimFilter';

import { ClaimFilter, IClaimFilterComponentProps } from './ClaimFilter';
import { SecurityBits } from '../../../commonResources/SecurityFunctionList';



export interface IExport28FilterComponentState {
};


//---------------------------------------------------------------


export const DEFAULT_STATE: IExport28FilterComponentState = {};


type IOwnProps = IClaimFilterComponentProps & RouteComponentProps<{}>;

type IClaimFilterProps = IMergeApiComponentProps<IClaimFilterState, IClaimFilterActionProps, IOwnProps>;




export class Export28Filter extends React.Component<IClaimFilterProps, IExport28FilterComponentState>
{

    static defaultProps: IClaimFilterComponentProps = {
        canEdit: false,
        canView: false,
        canCreate: false,
        ncsIsEnabled: false,

        process: "Export28",
        parameter: {},
        apiType: "ClaimFilter",
        title: "Export Claim Changes >> Siemens 28 File",
        executeJS: undefined,
        execNode: "ClaimChange",
        execAction: "ExportSiemens",
        execProcess: "Export28",
        extraInstruction: "Click Continue to create the report without viewing the claim list.",
    };

    constructor(props: IClaimFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }

        //     console.log('can edit ' + this.props.canEdit + ' can view ' + this.props.canView);

    }


    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }


    public onOk = (baseThis: any, param1: any): boolean => {
       // alert(baseThis.props.apiType + ':' + param1 + ':' + this.props.apiType);
        return true;
    }

    public render() {
        return (
            <div>
                <ClaimFilter
                    {...this.props}
                    
                />
            </div>

        );
    }
};



var connectedHoc = connect<IClaimFilterState, IClaimFilterActionProps, IOwnProps, IClaimFilterProps, ApplicationState>(
    createApiMapStateToProps('claimFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(Export28Filter);

export default withRouter(connectedHoc);
