export class DivWidthCalculator {
    //getDivWidthByMap(): number {
    //}

    getDivWidth(tableMetadata: TableMetadata): number {

        // get width estimate from data
        const widthEstimateFromData = this.getWidthEstimateFromData(tableMetadata);
        return widthEstimateFromData;
    }

    getWidthEstimateFromData(tableMetadata: TableMetadata) {

        interface maxColumnLengthMap {
            columnNumber: number,
            maxFieldNameLength: number,
            maxFieldValueLength: number,
        }

        let columnMaps: maxColumnLengthMap[] = [];
        let columnMapRef: maxColumnLengthMap;
        let columnMapsFilter: maxColumnLengthMap[] = [];
        let columnMapExists = false;
        let currentColumn: maxColumnLengthMap;

        // get max field name and field value length per cell
        tableMetadata.Items.forEach(x => {
            columnMapsFilter = columnMaps.filter(y => y.columnNumber === x.ColumnNumber);
            columnMapExists = columnMapsFilter.length > 0;

            // initialize width map for row with current item values if necessary
            if (!columnMapExists) {
                columnMaps.push({
                    columnNumber: x.ColumnNumber,
                    maxFieldNameLength: x.FieldName.length,
                    maxFieldValueLength: x.FieldValue && x.FieldValue.length || 0,
                });
            } else {
                // get the reference for current column
                currentColumn = columnMapsFilter[0];

                // eval maxFieldNameLength for max update
                if (x.FieldName.length > currentColumn.maxFieldNameLength) {
                    currentColumn.maxFieldNameLength = x.FieldName.length;
                }

                // eval maxFieldValueLength for max update
                if (x.FieldValue) {
                    // eval field width class names for max update
                    const fieldWidthByClassNames = this.getFieldWidthByClassNames(x.ClassNames);
                    // transpose css px to character count
                    const pxToCharacterCount = fieldWidthByClassNames * 0.10;
                    if (pxToCharacterCount > currentColumn.maxFieldValueLength) {
                        currentColumn.maxFieldValueLength = pxToCharacterCount;
                    }else if (x.FieldValue.length > currentColumn.maxFieldValueLength) {
                        currentColumn.maxFieldValueLength = x.FieldValue.length;
                    }
                }else if(x.FieldTable) {
                    const fieldTableDataWidth = this.getMaxFieldTableWidth(tableMetadata);
                    if (fieldTableDataWidth > currentColumn.maxFieldValueLength) {
                        currentColumn.maxFieldValueLength = fieldTableDataWidth;
                    }
                }

                // update the column in columnMaps
                columnMapRef = columnMaps.filter(z => z.columnNumber === x.ColumnNumber)[0];
                columnMapRef.maxFieldNameLength = currentColumn.maxFieldNameLength;
                columnMapRef.maxFieldValueLength = currentColumn.maxFieldValueLength;
            }
        });

        // aggregate the lengths of all column field names and column field values
        const aggregateLength = columnMaps.reduce((a, c) => {
            return a + c.maxFieldNameLength + c.maxFieldValueLength
        }, 0);

        return aggregateLength * 10;
    }

    getFieldWidthByClassNames(classNames: string | undefined) : number {

        // if no class names then no class name widths
        if (!classNames) return 0;

        // define the 'width' class names
        const widthClassMap = [
            {
                widthStyleName: 'widthSmall',
                width: 250
            },
            {
                widthStyleName: 'widthMedium',
                width: 500
            },
            {
                widthStyleName: 'widthLarge',
                width: 750
            },
        ];

        // det if field value has width class name
        const fieldWidthClassName =
            widthClassMap.map(x => x.widthStyleName)
                .filter(x => classNames?.split(' ').includes(x));

        // if no field width class name then no class name width
        if (!fieldWidthClassName.length) return 0;

        // return the width for the class name
        return widthClassMap.find(x => x.widthStyleName === fieldWidthClassName[0])?.width || 0;
    }

    getMaxFieldTableWidth(tableMetadata: TableMetadata) {
        const maxFieldTableNameWidth = this.getMaxFieldTableNameWidth(tableMetadata);
        const maxFieldTableValueWidth = this.getMaxFieldTableValueWidth(tableMetadata);
        const maxTableWidth = Math.max(...[maxFieldTableNameWidth, maxFieldTableValueWidth]);
        return maxTableWidth;
    }

    getMaxFieldTableNameWidth(tableMetadata: TableMetadata) {
        // get field tables if they exist
        const itemsWithFieldTables = tableMetadata.Items.filter(x => x.FieldTable && x.FieldTable?.length);
        let fieldTableMaxNameLengthSingle = 0;
        let fieldTableMaxNameLengthSet = 0;
        let fieldTableNameLengths: number[] = [];

        // calculate max aggregate text length in field tables
        if (itemsWithFieldTables && itemsWithFieldTables.length) {
            const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
            itemsWithFieldTables.forEach(x => {
                fieldTableNameLengths = (x.FieldTable && x.FieldTable.map(y => y.FieldName && y.FieldName.length || 0)) || [];
                fieldTableMaxNameLengthSingle = fieldTableNameLengths.reduce(reducer);
                if (fieldTableMaxNameLengthSingle > fieldTableMaxNameLengthSet) {
                    fieldTableMaxNameLengthSet = fieldTableMaxNameLengthSingle;
                }
            });
        }
        return fieldTableMaxNameLengthSet;
    }

    getMaxFieldTableValueWidth(tableMetadata: TableMetadata) {
        // get field tables if they exist
        const itemsWithFieldTables = tableMetadata.Items.filter(x => x.FieldTable && x.FieldTable?.length);
        let fieldTableMaxValueLengthSingle = 0;
        let fieldTableMaxValueLengthSet = 0;
        let fieldTableValueLengths: number[] = [];

        // calculate max aggregate text length in field tables
        if (itemsWithFieldTables && itemsWithFieldTables.length) {
            const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
            itemsWithFieldTables.forEach(x => {
                fieldTableValueLengths = (x.FieldTable && x.FieldTable.map(y => y.FieldValue && y.FieldValue.length || 0)) || [];
                fieldTableMaxValueLengthSingle = fieldTableValueLengths.reduce(reducer);
                if (fieldTableMaxValueLengthSingle > fieldTableMaxValueLengthSet) {
                    fieldTableMaxValueLengthSet = fieldTableMaxValueLengthSingle;
                }
            });
        }
        return fieldTableMaxValueLengthSet;
    }
}
