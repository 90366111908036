import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers'; 
import * as SelectClientStore from './api/SelectClientStore';

export type ISelectClientState = IApiComponentState<any, SelectClientStore.ISelectClientUIState>;

export const actionCreators = createApiComponentActions<SelectClientStore.ActionCreators>(SelectClientStore.actionCreators);
export type ISelectClientActions = typeof actionCreators;
export type ISelectClientActionProps = IApiComponentActionProps<ISelectClientActions>

export const reducers = createApiComponentReducers("addNote", SelectClientStore.reducer);
export const reducer = combineReducers<ISelectClientState>(reducers);