import { combineReducers, Reducer } from "redux";
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from "@scripts/util/CrudComponentHelpers";
import * as PaperClaimsPrintingMaintenanceUI from "./ui/PaperClaimsPrintingMaintenanceUI";
import { CrudTypes } from '@commonResources/CrudTypes';

export type IPaperClaimsPrintingMaintenanceState = ICrudComponentState<any, any>;

export const actionCreators = createCrudComponentActions<PaperClaimsPrintingMaintenanceUI.ActionCreators>(PaperClaimsPrintingMaintenanceUI.actionCreators);
export type IPaperClaimsPrintingMaintenanceActions = typeof actionCreators;
export type IPaperClaimsPrintingMaintenanceActionProps = ICrudComponentActionProps<IPaperClaimsPrintingMaintenanceActions>


export const reducers = createCrudComponentReducers(CrudTypes.mctiPaperClaimsConfig, PaperClaimsPrintingMaintenanceUI.reducer);
export const reducer = combineReducers<IPaperClaimsPrintingMaintenanceState>(reducers);