import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, createAction, ActionTypes } from '@scripts/util/ActionHelpers'; 
import { ValidateObject, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers'; 
import { HoldCode } from '../../components/Administration/HoldCode/HoldCode';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IViewResponseUIData {
    id: string;
    name: string;
};
export interface IHoldCodeProcessingUIState {
    changed: boolean;
    holdCode?: string;
    holdNote?: string;
    codeOperation: string;
    noteOperation: string;
    clearCodeChecked: boolean;
    addCodeChecked: boolean;
    overwriteCodeChecked: boolean;

    clearNoteChecked: boolean;
    addNoteChecked: boolean;
    overwriteNoteChecked: boolean;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    updateHoldCode: (updateHoldCode: string) => createDataAction('UPDATE_HOLD_CODE', updateHoldCode),
    updateHoldNote: (updateHoldNote: string) => createDataAction('UPDATE_HOLD_NOTE', updateHoldNote),
    updateClearCodeChecked: (checked: boolean) => createDataAction('UPDATE_CLEAR_CODE_CHECKED', checked),
    updateClearNoteChecked: (checked: boolean) => createDataAction('UPDATE_CLEAR_NOTE_CHECKED', checked),
    updateAddCodeChecked: (checked: boolean) => createDataAction('UPDATE_ADD_CODE_CHECKED', checked),
    updateAddNoteChecked: (checked: boolean) => createDataAction('UPDATE_ADD_NOTE_CHECKED', checked),
    updateOverwriteCodeChecked: (checked: boolean) => createDataAction('UPDATE_OVERWRITE_CODE_CHECKED', checked),
    updateOverwriteNoteChecked: (checked: boolean) => createDataAction('UPDATE_OVERWRITE_NOTE_CHECKED', checked),
    updateCodeOperation: (operation: {code:string ,checked:boolean}) => createDataAction('UPDATE_CODE_OPERATION', operation),
    updateNoteOperation: (operation: { code: string, checked: boolean }) => createDataAction('UPDATE_NOTE_OPERATION', operation),
    
};

// From ActionTypes, Actioneators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IHoldCodeProcessingUIState = {
    holdCode: undefined,
    holdNote: undefined,
    codeOperation: ' ',
    noteOperation: ' ',
    clearCodeChecked: false,
    clearNoteChecked: false,
    addCodeChecked: false,
    addNoteChecked: false,
    overwriteCodeChecked: false,
    overwriteNoteChecked: false,
    changed:false
};


// ----------------
// REDUCER - For a given state and actio returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IHoldCodeProcessingUIState, KnownActions> = (state: IHoldCodeProcessingUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            
            case 'UPDATE_HOLD_CODE':
                return {
                    ...state,
                    holdCode: action.data,
                }
                break;
            case 'UPDATE_HOLD_NOTE':
                return {
                    ...state,
                    holdNote: action.data,
                }
                break;
            case 'UPDATE_CLEAR_CODE_CHECKED':
                return {
                    ...state,
                    clearCodeChecked: action.data,
                    overwriteCodeChecked: false,
                    addCodeChecked:false
                }
                break;
            case 'UPDATE_CLEAR_NOTE_CHECKED':
                return {
                    ...state,
                    clearNoteChecked: action.data,
                    overwriteNoteChecked: false,
                    addNoteChecked: false
                }
                break;
            case 'UPDATE_ADD_CODE_CHECKED':
                return {
                    ...state,
                    addCodeChecked: action.data,
                    overwriteCodeChecked: false,
                    clearCodeChecked: false
                }
                break;
            case 'UPDATE_ADD_NOTE_CHECKED':
                return {
                    ...state,
                    addNoteChecked: action.data,
                    overwriteNoteChecked: false,
                    clearNoteChecked:false
                }
                break;
            case 'UPDATE_OVERWRITE_CODE_CHECKED':
                return {
                    ...state,
                    overwriteCodeChecked: action.data,
                    clearCodeChecked: false,
                    addCodeChecked: false
                }
                break;
            case 'UPDATE_OVERWRITE_NOTE_CHECKED':
                return {
                    ...state,
                    overwriteNoteChecked: action.data,
                    clearNoteChecked: false,
                    addNoteChecked: false
                }
                break;
            case 'UPDATE_CODE_OPERATION':
                console.log(action);
                let stholdCode = state.holdCode;
                let codeOperation = action.data.code;
                console.log(action.data.checked && action.data.code === 'C', action.data.checked , action.data.code === 'C')
                if (action.data.checked && action.data.code === 'C')
                    stholdCode = '';
                if (!action.data.checked)
                    codeOperation = '';
                console.log(stholdCode)
                return {
                    ...state,
                    codeOperation: codeOperation,
                    holdCode:stholdCode
                }
                break;
            case 'UPDATE_NOTE_OPERATION':
                console.log(action);
                let stholdNote = state.holdNote;
                let stnoteOperation = action.data.code;
               
                if (action.data.checked && action.data.code === 'C')
                    stholdNote = '';
                if (!action.data.checked)
                    stnoteOperation = '';
                console.log(stholdCode)
                return {
                    ...state,
                    noteOperation: stnoteOperation,
                    holdNote: stholdNote,
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
