import { Action, Reducer } from "redux";
import { createAction, createDataAction, ActionTypes } from "@scripts/util/ActionHelpers";
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { defaultstate } from "./RemitImportOptionsUI";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export function enforceArray<T>(node: T[]) : T[] {

    if (Array.isArray(node))
        return node;

    const array: T[] = [];
    return array.concat(node);
}

export interface IAliasCredentialUIModel {
    id: string;
    aliasId: string;
    aliasName: string;
    userName: string;
    password: string;
}

export interface IProviderUIModel {
    providerId: string;
    name: string;
    selected: boolean;
}


export interface IDirectEntryAccountUIState {
    selectedItem: IDirectEntryAccountUIData;
    accountName: string;
    fiId: string;
    checkEligibility: boolean;
    checkEdits: boolean;
    sendClaims: boolean;
    checkStatus: boolean;
    enabled: boolean;
    showEnableConfirm: boolean;
    accountOtherClient: boolean;
    chkLinked: boolean;
    dateDisabled: string;
    aliasCredentials: IAliasCredentialUIModel[];
    autoGeneratePwd : boolean;
    generateDays: string;
    lastChangeDate: string;

    providers: IProviderUIModel[];

    // Select box values
    selectedThisFi: string[];
    selectedThisAccount: string[];

    showRemoveAccountConfirm: boolean;

    // State check for password changes
    fakePassword: string;
    // ID for inserted elements
    nextId: number;

    accountNameError?: string;
    fiSelectError?: string;
    generateDaysError?: string;
    linkedCount: number;
}

interface IDirectEntryAccountUIFieldUpdate {
    id: string;
    value: string;
    isValid: boolean;
}

interface IDirectEntryAccountUICheckboxUpdate {
    value: boolean;
}

interface IDirectEntryAccountSelection {
    selected: string[];
}

export const newAccountValue = "<NEW>";
export const newFiValue = "<NEW>";
export const newUserValue = "<NEW>";
export const emptyAccountNameError = "Please enter an account name.";
export const emptyFiSelectError = "Please select a Fiscal Intermediary.";
export const badDaysError = "Please enter a frequency.";

function newAccount(): MCDea_Account {
    return {
        "@ID": "",
        "@Name": "",
        "@FIId": "",
        "@OtherClient": "",
        Functions: {
            "@Eligibility": "0",
            "@Validate": "0",
            "@Release": "0",
            "@Inquiry": "0",
            "@Enabled": "1",
            "@DateDisabled": "",
            "@Linked": "1",
        },
        UserNames: {
            "@AutoUpdateEnabled": "",
            "@AutoUpdateFrequency": "",
            "@LastChangeDate": "",
            UserName: [],
        },
        Providers: {
            Provider: [],
        },
    };
}

export interface IDirectEntryAccountUIData {
    id: string;
    name: string;
};

export const defaultSelection: IDirectEntryAccountUIData = {
    id: newAccountValue,
    name: "- ADD A NEW ACCOUNT -",
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectAccount extends ICrudActionData<MCDirectEntryAccount, IDirectEntryAccountUIData> { }

export interface IModifyAccount extends ICrudActionData<MCDirectEntryAccount, IDirectEntryAccountUIData> { }

export interface IUpdateDirectEntryAccountField extends ICrudActionData<MCDirectEntryAccount, IDirectEntryAccountUIFieldUpdate> { }
export interface IUpdateDirectEntryAccountCheck extends ICrudActionData<MCDirectEntryAccount, IDirectEntryAccountUICheckboxUpdate> { }


export interface IDeaSelectListSelection extends ICrudActionData<MCDirectEntryAccount, IDirectEntryAccountSelection> { }
export interface IAddRemoveButtons extends ICrudActionData<MCDirectEntryAccount, boolean> { }

export const actionCreators = {
    selectAccount: (selectInfo: ISelectAccount) => createDataAction("SELECT_ACCOUNT", selectInfo),
    updateAccountName: (fieldInfo: IUpdateDirectEntryAccountField) => createDataAction("UPDATE_ACCOUNT_NAME", fieldInfo),
    updateAccountFi: (fieldInfo: IUpdateDirectEntryAccountField) => createDataAction("UPDATE_ACCOUNT_FI", fieldInfo),
    updateAccountCheckEligibility: (fieldInfo: IUpdateDirectEntryAccountCheck) => createDataAction("UPDATE_ACCOUNT_CHECK_ELIGIBILITY", fieldInfo),
    updateAccountCheckEdits: (fieldInfo: IUpdateDirectEntryAccountCheck) => createDataAction("UPDATE_ACCOUNT_CHECK_EDITS", fieldInfo),
    updateAccountCheckStatus: (fieldInfo: IUpdateDirectEntryAccountCheck) => createDataAction("UPDATE_ACCOUNT_CHECK_STATUS", fieldInfo),
    updateAccountSendClaims: (fieldInfo: IUpdateDirectEntryAccountCheck) => createDataAction("UPDATE_ACCOUNT_SEND_CLAIMS", fieldInfo),
    updateAccountEnabled: (fieldInfo: IUpdateDirectEntryAccountCheck) => createDataAction("UPDATE_ACCOUNT_ENABLED", fieldInfo),
    updateAccountLinked: (fieldInfo: IUpdateDirectEntryAccountCheck) => createDataAction("UPDATE_ACCOUNT_LINKED", fieldInfo),
    toggleAccountEnableConfirm: (fieldInfo: IUpdateDirectEntryAccountCheck) => createDataAction("TOGGLE_ACCOUNT_ENABLE_CONFIRM", fieldInfo),
    toggleGenerateDaysError: (fieldInfo: IUpdateDirectEntryAccountField) => createDataAction("TOGGLE_GENERATE_DAYS_ERROR", fieldInfo),
    updateAuthAutoGenPwd: (fieldInfo: IUpdateDirectEntryAccountCheck) => createDataAction("UPDATE_ACCOUNT_AUTH_AUTO_GEN", fieldInfo),
    updateAuthAutoGenDays: (fieldInfo: IUpdateDirectEntryAccountField) => createDataAction("UPDATE_ACCOUNT_AUTH_DAYS", fieldInfo),
    selectThisFi: (fieldInfo: IDeaSelectListSelection) => createDataAction("SELECT_THIS_FI", fieldInfo),
    selectThisAccount: (fieldInfo: IDeaSelectListSelection) => createDataAction("SELECT_THIS_ACCOUNT", fieldInfo),
    removeProviders: (nothing: IAddRemoveButtons) => createDataAction("REMOVE_PROVIDERS", nothing),
    addProviders: (nothing: IAddRemoveButtons) => createDataAction("ADD_PROVIDERS", nothing),
    updateUserName: (fieldInfo: IUpdateDirectEntryAccountField) => createDataAction("UPDATE_ACCOUNT_USERNAME", fieldInfo),
    updatePassword: (fieldInfo: IUpdateDirectEntryAccountField) => createDataAction("UPDATE_ACCOUNT_PASSWORD", fieldInfo),
    modifyAccount: (nothing: IAddRemoveButtons) => createDataAction("MODIFY_ACCOUNT", nothing),
    removeAccount: (nothing: IAddRemoveButtons) => createDataAction("REMOVE_ACCOUNT", nothing),
    toggleRemoveAccountConfirm: (fieldInfo: IUpdateDirectEntryAccountCheck) => createDataAction("TOGGLE_REMOVE_ACCOUNT_CONFIRM", fieldInfo),
    updateAccountLastChangeDate: (fieldInfo: IUpdateDirectEntryAccountField) => createDataAction("UPDATE_ACCOUNT_LAST_CHANGED_DATE", fieldInfo),
    resetDefaultState: (fieldInfo: IAddRemoveButtons) => createDataAction("RESET_DEFAULT_STATE", fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>["type"];

export const defaultState: IDirectEntryAccountUIState = {
    selectedItem: defaultSelection,
    accountName: "",
    fiId: newFiValue,
    checkEligibility: false,
    checkEdits: false,
    sendClaims: false,
    checkStatus: false,
    enabled: true,
    showEnableConfirm: false,
    dateDisabled: "",
    accountOtherClient: false,
    chkLinked: false,
    aliasCredentials: [],
    autoGeneratePwd: false,
    generateDays: "0",
    lastChangeDate: "",
    providers: [],
    selectedThisFi: [],
    selectedThisAccount: [],
    showRemoveAccountConfirm: false,

    nextId: 1,
    fakePassword: Math.round(Math.random() * 1000000000).toString(),
    linkedCount: 0
};

function determineCredentials(fiId: string, masterCrud: MCDirectEntryAccount, accountId: string) : IAliasCredentialUIModel[] {

    const selectedAccount = findAccountById(masterCrud, accountId).account;

    let credentials: IAliasCredentialUIModel[] = [];

    const fi = enforceArray<MCDea_Fi>(masterCrud.DDEAccountMaintenanceInfo.FIList.FI).find(ob => ob["@ID"] === fiId);
    const fiList = fi ? enforceArray<MCDea_FiAlias>(fi.AliasList.Alias) : [];

    enforceArray<MCDea_FiAlias>(fiList).forEach(
        (fiAlias: MCDea_FiAlias) => {
            var alias = masterCrud.DDEAccountMaintenanceInfo.AliasList.Alias.find((obj) => obj["@ID"] === fiAlias["@ID"]);

            if (!alias) return;

            credentials.push({
                id: newUserValue,
                aliasId: alias["@ID"],
                aliasName: alias["@Name"],
                userName: "",
                password: "",
            });
        }
    );

    if (selectedAccount && selectedAccount.UserNames.UserName) {
        const userNames = enforceArray(selectedAccount.UserNames.UserName);
        credentials.forEach((credItem: IAliasCredentialUIModel) => {
            const user = userNames.find((obj: MCDea_UserName) => obj["@AliasId"] === credItem.aliasId);
            if (!user)
                return;
            credItem.id = user["@ID"];
            if (user["@Name"] && user["@Name"] !== "") {
                credItem.userName = user["@Name"];
                credItem.password = defaultState.fakePassword;
            };
        });
    }

    return credentials;
}

function determineProviders(fiId: string, masterCrud: MCDirectEntryAccount, accountId: string, chkLinked: boolean): IProviderUIModel[] {

    const providers: IProviderUIModel[] = [];

    const selectedAccount = findAccountById(masterCrud, accountId).account;
    
    const fi = enforceArray<MCDea_Fi>(masterCrud.DDEAccountMaintenanceInfo.FIList.FI).find(ob => ob["@ID"] === fiId);

    let cc = masterCrud.DDEAccountMaintenanceInfo["@ClientCount"];
    let ccn: number = 99999;
    if (cc && cc.length > 0) ccn = Number(cc);

    if (!fi)
        return providers;

    const fiPayers = enforceArray<MCDea_Payer>(fi.PayerList.Payer);

    if (fiPayers.length === 0)
        return providers;

    var acctProviders: MCDea_AccountProvider[] = [];
    if (selectedAccount && selectedAccount.Providers.Provider)
        acctProviders = enforceArray(selectedAccount.Providers.Provider);

    enforceArray<MCDea_Provider>(masterCrud.DDEAccountMaintenanceInfo.ProviderList.Provider).forEach(
        (provider: MCDea_Provider) => {
            var fiPayer = fiPayers.find((obj) => obj["@ID"] === provider["@PayerID"]);
            var otherClient = provider["@OtherClient"] === "1";
            if (!fiPayer)
                return;
            if (ccn > 1) {
                if (!chkLinked && otherClient)
                    return;
            }

            var selectedPayer = acctProviders.find((obj) => obj["@ID"] === provider["@ID"]);

            providers.push({
                providerId: provider["@ID"],
                name: provider["@Name"] + " - " + provider["@ProviderNo"],
                selected: (selectedPayer != undefined)
            });
        });

    return providers;
}

function getDefaultAccountState(state: IDirectEntryAccountUIState): IDirectEntryAccountUIState {
    return {
        ...state,
        selectedItem: defaultState.selectedItem,
        accountName: defaultState.accountName,
        fiId: defaultState.fiId,
        checkEligibility: defaultState.checkEligibility,
        checkEdits: defaultState.checkEdits,
        sendClaims: defaultState.sendClaims,
        checkStatus: defaultState.checkStatus,
        enabled: defaultState.enabled,
        accountOtherClient: defaultState.accountOtherClient,
        dateDisabled: defaultState.dateDisabled,
        autoGeneratePwd: defaultState.autoGeneratePwd,
        generateDays: defaultState.generateDays,
        lastChangeDate: defaultState.lastChangeDate,
        aliasCredentials: defaultState.aliasCredentials,
        providers: defaultState.providers,
        linkedCount: 0,
        chkLinked: false,
        accountNameError: defaultState.accountNameError,
        fiSelectError: defaultState.fiSelectError,
        generateDaysError: defaultState.generateDaysError
    };
}


export interface IAccountSearchResult {
    account?: MCDea_Account;
}

function findAccountById(crud: MCDirectEntryAccount, id: string): IAccountSearchResult {

    if (!crud ||
        !crud.DDEAccountMaintenanceInfo.Accounts ||
        !crud.DDEAccountMaintenanceInfo.Accounts.Account)
        return { account: undefined };

    return {
        account: enforceArray(crud.DDEAccountMaintenanceInfo.Accounts.Account).find(ob => { return ob["@ID"] === id })
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IDirectEntryAccountUIState, KnownActions> = (state: IDirectEntryAccountUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
        case "SELECT_ACCOUNT":
            if (action.data.masterCrud) {
                let cc = action.data.masterCrud.DDEAccountMaintenanceInfo["@ClientCount"];
                let ccn: number = 99999;
                if (cc && cc.length > 0) ccn = Number(cc);
                
                let selectedAccount = findAccountById(action.data.masterCrud, action.data.uiData.id).account;
                let linkedCheckBoxValue: boolean = selectedAccount && selectedAccount.Functions["@Linked"] ? selectedAccount.Functions["@Linked"] === "1" : false;
                let fiId = selectedAccount && selectedAccount["@FIId"] ? selectedAccount["@FIId"] : defaultState.fiId;
                let credentials = fiId === defaultState.fiId
                    ? []
                    : determineCredentials(fiId, action.data.masterCrud, action.data.uiData.id);
                let providers = fiId === defaultState.fiId
                    ? []
                    : determineProviders(fiId, action.data.masterCrud, action.data.uiData.id, linkedCheckBoxValue);

                return selectedAccount
                    ? {
                        ...state,
                        selectedItem: action.data.uiData,
                        accountName: selectedAccount["@Name"] ? selectedAccount["@Name"] : defaultState.accountName,
                        fiId: fiId,
                        accountOtherClient: selectedAccount["@OtherClient"] ? selectedAccount["@OtherClient"] === "1" : defaultState.accountOtherClient,
                        chkLinked: selectedAccount.Functions && selectedAccount.Functions["@Linked"]
                            ? selectedAccount.Functions["@Linked"] === "1"
                            : defaultState.chkLinked,
                        checkEligibility: selectedAccount.Functions && selectedAccount.Functions["@Eligibility"]
                            ? selectedAccount.Functions["@Eligibility"] === "1"
                            : defaultState.checkEligibility,
                        checkEdits: selectedAccount.Functions && selectedAccount.Functions["@Validate"]
                            ? selectedAccount.Functions["@Validate"] === "1"
                            : defaultState.checkEdits,
                        sendClaims: selectedAccount.Functions && selectedAccount.Functions["@Release"]
                            ? selectedAccount.Functions["@Release"] === "1"
                            : defaultState.sendClaims,
                        checkStatus: selectedAccount.Functions && selectedAccount.Functions["@Inquiry"]
                            ? selectedAccount.Functions["@Inquiry"] === "1"
                            : defaultState.checkStatus,
                        linkedCount: ccn,
                        enabled: selectedAccount.Functions && selectedAccount.Functions["@Enabled"]
                            ? selectedAccount.Functions["@Enabled"] === "1"
                            : defaultState.enabled,
                        dateDisabled: selectedAccount.Functions && selectedAccount.Functions["@DateDisabled"]
                            ? selectedAccount.Functions["@DateDisabled"]
                            : defaultState.dateDisabled,
                        aliasCredentials: credentials,
                        providers: providers,
                        autoGeneratePwd: selectedAccount.UserNames && selectedAccount.UserNames["@AutoUpdateEnabled"]
                            ? selectedAccount.UserNames["@AutoUpdateEnabled"] === "1"
                            : defaultState.autoGeneratePwd,
                        generateDays: selectedAccount.UserNames && selectedAccount.UserNames["@AutoUpdateFrequency"]
                            ? selectedAccount.UserNames["@AutoUpdateFrequency"]
                            : defaultState.generateDays,
                        lastChangeDate: selectedAccount.UserNames && selectedAccount.UserNames["@LastChangeDate"]
                            ? selectedAccount.UserNames["@LastChangeDate"]
                            : defaultState.lastChangeDate,
                        accountNameError: selectedAccount["@Name"] == undefined || selectedAccount["@Name"] === ""
                            ? defaultState.accountNameError
                            : undefined,
                        fiSelectError: fiId === defaultState.fiId ? defaultState.fiSelectError : undefined
                        //generateDaysError: selectedAccount.UserNames && selectedAccount.UserNames["@AutoUpdateFrequency"] && selectedAccount.UserNames["@AutoUpdateFrequency"] === "" ? badDaysError : undefined
                    }
                    : getDefaultAccountState(state);
            }
            break;
        case "UPDATE_ACCOUNT_NAME":
            if (action.data.masterCrud) {
                let accountName = action.data.uiData.value;
                return {
                    ...state,
                    accountName: accountName.toUpperCase()
                };
            }
            break;
        case "UPDATE_ACCOUNT_FI":
            if (action.data.masterCrud) {
                let fiId = action.data.uiData.value;
                let credentials = fiId === defaultState.fiId
                    ? []
                    : determineCredentials(fiId, action.data.masterCrud, state.selectedItem.id);
                let providers = fiId === defaultState.fiId
                    ? []
                    : determineProviders(fiId, action.data.masterCrud, state.selectedItem.id, state.chkLinked);

                return {
                    ...state,
                    fiId: fiId,
                    aliasCredentials: credentials,
                    providers: providers,
                    fiSelectError: fiId === defaultState.fiId ? defaultState.fiSelectError : undefined
                };
            }
            break;
        case "UPDATE_ACCOUNT_CHECK_ELIGIBILITY":
            if (action.data.masterCrud) {
                return {
                    ...state,
                    checkEligibility: action.data.uiData.value
                }
            }
            break;
        case "UPDATE_ACCOUNT_CHECK_STATUS":
            if (action.data.masterCrud) {
                return {
                    ...state,
                    checkStatus: action.data.uiData.value
                }
            }
            break;
        case "UPDATE_ACCOUNT_CHECK_EDITS":
            if (action.data.masterCrud) {
                return {
                    ...state,
                    checkEdits: action.data.uiData.value
                }
            }
            break;
        case "TOGGLE_ACCOUNT_ENABLE_CONFIRM":
            if (action.data.masterCrud) {
                return {
                    ...state,
                    showEnableConfirm: action.data.uiData.value
                }
            }
            break;
        case "TOGGLE_GENERATE_DAYS_ERROR":
            if (action.data.masterCrud) {
                return {
                    ...state,
                    generateDays: "",
                    generateDaysError: action.data.uiData.value
                }
            }
            break;
        case "UPDATE_ACCOUNT_ENABLED":
            if (action.data.masterCrud) {
                return {
                    ...state,
                    enabled: action.data.uiData.value
                }
            }
            break;
        case "UPDATE_ACCOUNT_LINKED":
            if (action.data.masterCrud) {
                let fiId = state.fiId;
                let credentials = fiId === defaultState.fiId
                    ? []
                    : determineCredentials(fiId, action.data.masterCrud, state.selectedItem.id);
                let providers = fiId === defaultState.fiId
                    ? []
                    : determineProviders(fiId, action.data.masterCrud, state.selectedItem.id, action.data.uiData.value);

                return {
                    ...state,
                    fiId: fiId,
                    aliasCredentials: credentials,
                    providers: providers,
                    fiSelectError: fiId === defaultState.fiId ? defaultState.fiSelectError : undefined,
                    chkLinked: action.data.uiData.value
                };
            }
            break;
        case "UPDATE_ACCOUNT_SEND_CLAIMS":
            if (action.data.masterCrud) {
                return {
                    ...state,
                    sendClaims: action.data.uiData.value
                }
            }
            break;
        case "UPDATE_ACCOUNT_AUTH_AUTO_GEN":
        {

            if (action.data.masterCrud) {
                if (action.data.uiData.value)
                    return {
                        ...state,
                        autoGeneratePwd: action.data.uiData.value
                    }

                const selectedAccount = findAccountById(action.data.masterCrud, state.selectedItem.id).account;

                return {
                    ...state,
                    autoGeneratePwd: action.data.uiData.value,
                    generateDays: selectedAccount &&
                        selectedAccount.UserNames &&
                        selectedAccount.UserNames["@AutoUpdateFrequency"]
                        ? selectedAccount.UserNames["@AutoUpdateFrequency"]
                        : defaultState.generateDays,
                    generateDaysError: undefined,
                };
            }
            break;
        }
        case "UPDATE_ACCOUNT_AUTH_DAYS":
            if (action.data.masterCrud) {
                const daysVal = action.data.uiData.value.replace(/\D/g, "");

                return {
                    ...state,
                    generateDays: daysVal,
                    //generateDaysError: daysVal === "" ? badDaysError : undefined
                };
            }
            break;
        case "UPDATE_ACCOUNT_USERNAME":
            if (action.data.masterCrud) {
                const credentials = state.aliasCredentials;
                let credential = credentials.find(obj => obj.aliasId === action.data.uiData.id);
                if (credential) credential.userName = action.data.uiData.value;

                return {
                    ...state,
                    aliasCredentials: credentials
                };
            }
            break;
        case "UPDATE_ACCOUNT_PASSWORD":
            if (action.data.masterCrud) {
                const credentials = state.aliasCredentials;
                let credential = credentials.find(obj => obj.aliasId === action.data.uiData.id);
                if (credential) credential.password = action.data.uiData.value;

                return {
                    ...state,
                    aliasCredentials: credentials
                };
            }
            break;
        case "SELECT_THIS_FI":
            return {
                ...state,
                selectedThisFi: action.data.uiData.selected,
            };
        case "SELECT_THIS_ACCOUNT":
            return {
                ...state,
                selectedThisAccount: action.data.uiData.selected,
            };
        case "ADD_PROVIDERS":
            if (action.data.masterCrud &&
                state.selectedThisFi.length > 0) {
                // const providers = state.providers;
                state.selectedThisFi.forEach(value => {
                    const prov = state.providers.find(obj => obj.providerId === value);
                    if (prov)
                        prov.selected = true;
                });

                return {
                    ...state,
                    selectedThisFi: [],
                    selectedThisAccount: [],
                    // providers: providers,
                };
            }
            break;

        case "REMOVE_PROVIDERS":
            if (action.data.masterCrud &&
                state.selectedThisAccount.length > 0) {
                // const providers = state.providers;
                state.selectedThisAccount.forEach(value => {
                    const prov = state.providers.find(obj => obj.providerId === value);
                    if (prov)
                        prov.selected = false;
                });

                return {
                    ...state,
                    selectedThisFi: [],
                    selectedThisAccount: [],
                    // providers: providers,
                };
            }
            break;
            case "MODIFY_ACCOUNT":
                if (action.data.masterCrud) {
                    state.accountNameError = undefined;
                    state.fiSelectError = undefined;

                    //account name
                    let accountNameError = "";
                    let accountName = state.accountName;
                    if (accountName === defaultState.accountName)
                        accountNameError = emptyAccountNameError;
                    if (action.data.masterCrud &&
                        action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts &&
                        action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts.Account &&
                        enforceArray(action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts.Account)
                            .find(acct => acct["@Name"] === accountName && acct["@ID"] !== state.selectedItem.id))
                        accountNameError = "Account name already exists.";
                    if (accountNameError.length > 0)
                        return {
                            ...state,
                            accountName: accountName.toUpperCase(),
                            accountNameError: accountNameError !== "" ? accountNameError : undefined
                        };

                    //FI selection
                    let fiId = state.fiId;
                    let fiSelectError = "";
                    let credentials = fiId === defaultState.fiId
                        ? []
                        : determineCredentials(fiId, action.data.masterCrud, state.selectedItem.id);
                    let providers = fiId === defaultState.fiId
                        ? []
                        : determineProviders(fiId, action.data.masterCrud, state.selectedItem.id, state.chkLinked);
                    if (fiId === defaultState.fiId) {
                        fiSelectError = emptyFiSelectError;
                        return {
                            ...state,
                            fiId: fiId,
                            aliasCredentials: credentials,
                            providers: providers,
                            fiSelectError: fiSelectError != "" ? fiSelectError : undefined
                        };
                    }

                    let selectedAccount = findAccountById(action.data.masterCrud, state.selectedItem.id).account;

                    if (!selectedAccount)
                        selectedAccount = newAccount();

                    selectedAccount["@Name"] = state.accountName;
                    selectedAccount["@FIId"] = state.fiId;
                    //selectedAccount.Functions["@DateDisabled"] = state.
                    selectedAccount.Functions["@Eligibility"] = state.checkEligibility ? "1" : "0";
                    selectedAccount.Functions["@Validate"] = state.checkEdits ? "1" : "0";
                    selectedAccount.Functions["@Release"] = state.sendClaims ? "1" : "0";
                    selectedAccount.Functions["@Inquiry"] = state.checkStatus ? "1" : "0";
                    selectedAccount.Functions["@Enabled"] = state.enabled ? "1" : "0";

                    let cc = action.data.masterCrud.DDEAccountMaintenanceInfo["@ClientCount"];
                    let ccn: number = 99999;
                    if (cc && cc.length > 0) ccn = Number(cc);
                    if (ccn > 1)
                        selectedAccount.Functions["@Linked"] = state.chkLinked ? "1" : "0";

                    state.generateDaysError = undefined;
                    let generateDaysError = "";
                    let generateDays = state.generateDays;
                    generateDays = generateDays.replace(/\D/g, "");
                    if (generateDays === "")
                        generateDaysError = badDaysError;
                    if (generateDaysError.length > 0)
                        return {
                            ...state,
                            generateDays: generateDays,
                            generateDaysError: generateDaysError !== "" ? generateDaysError : undefined
                        };                    



                    selectedAccount.UserNames = {
                        "@AutoUpdateEnabled": state.autoGeneratePwd ? "1" : "0",
                        "@AutoUpdateFrequency": state.generateDays.toString(),
                        "@LastChangeDate": state.lastChangeDate,
                        UserName: []
                    };

                    let newIndex = 1;
                
                    selectedAccount.UserNames.UserName = state.aliasCredentials.filter(cred => cred.userName !== "").map(cred => {
                        return {
                            "@ID": cred.id === newUserValue ? `#${newIndex++}` : cred.id,
                            "@AliasId": cred.aliasId,
                            "@Name": cred.userName,
                            "@Password": cred.password === state.fakePassword ? "" : cred.password,
                        };
                    });

                    selectedAccount.Providers = { Provider: [] };
                    selectedAccount.Providers.Provider = state.providers.filter(prov => prov.selected).map(prov => {
                        return {
                            "@ID": prov.providerId,
                        };
                    });
                
                    if (state.selectedItem.id === newAccountValue) {
                        selectedAccount["@ID"] = `#${state.nextId}`;
                    
                        if (!action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts ||
                            !action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts.Account)
                            action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts = {
                                Account: []
                            };
    
                        const accounts = enforceArray(action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts.Account)
                        accounts.push(selectedAccount);
                        action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts.Account = accounts;
                    }

                    return {
                        ...state,
                        nextId: state.nextId + 1,
                        selectedItem: {
                            id: selectedAccount["@ID"],
                            name: selectedAccount["@Name"]
                        },
                        // providers: providers,
                    };
                }
            break;
        case "TOGGLE_REMOVE_ACCOUNT_CONFIRM":
            return {
                ...state,
                showRemoveAccountConfirm: action.data.uiData.value
            };
            break;
        case "UPDATE_ACCOUNT_LAST_CHANGED_DATE":
            //let d = new Date(); //today

            //let changedDate = new Date(action.data.uiData.value);
            //let formattedDate = "";
            //if (changedDate.getFullYear() === d.getFullYear() &&
            //    changedDate.getMonth() === d.getMonth() &&
            //    changedDate.getDate() >= d.getDate() ||
            //    changedDate.getFullYear() === d.getFullYear() && changedDate.getMonth() > d.getMonth() ||
            //    changedDate.getFullYear() > d.getFullYear())
            //    formattedDate = `${(`0${changedDate.getMonth() + 1}`).slice(-2)}/${(`0${changedDate.getDate()}`).slice(-2)}/${changedDate.getFullYear()}`;

            return {
                ...state,
                lastChangeDate: action.data.uiData.value
            };
        case "REMOVE_ACCOUNT":
            if (action.data.masterCrud && state.selectedItem.id !== newAccountValue) {
                const accounts = enforceArray(action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts.Account)
                                    .filter((el) => { return el["@ID"] !== state.selectedItem.id; });

                action.data.masterCrud.DDEAccountMaintenanceInfo.Accounts.Account = accounts;

                return getDefaultAccountState(state);
            };
            break;

        case "RESET_DEFAULT_STATE":
            return defaultState;
            /*
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
            return defaultState;
            */
        }
    }

    return state || defaultState;
}
