import { combineReducers, Reducer } from 'redux';
import {isPlainObject, has} from 'lodash';
import * as ManageClaimAssignmentsErrorRulesUI from '@store/ui/ManageClaimAssignmentsErrorRulesUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from
    '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import {VerifyParentAndArray} from '@scripts/util/ValidationHelpers';
import { Verify } from 'crypto';

export type IManageClaimAssignmentsErrorRulesUIState = ICrudComponentState<MCManageClaimAssignmentError,
    ManageClaimAssignmentsErrorRulesUI.IManageClaimAssignmentsErrorRulesUIState>;
export const actionCreators =
    createCrudComponentActions<ManageClaimAssignmentsErrorRulesUI.ActionCreators>(ManageClaimAssignmentsErrorRulesUI
        .actionCreators);
export type IManageClaimAssignmentsErrorRulesActions = typeof actionCreators;
export type IManageClaimAssignmentsErrorRulesActionProps =
    ICrudComponentActionProps<IManageClaimAssignmentsErrorRulesActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiClaimAssignmentError, ManageClaimAssignmentsErrorRulesUI.manageClaimAssignmentsErrorRulesUIReducer);
export const reducer = combineReducers<IManageClaimAssignmentsErrorRulesUIState>(reducers);

export const validationCallback: ValidationCallback<MCManageClaimAssignmentError> =
    (crud: any): crud is MCManageClaimAssignmentError => {
        let testCrud: MCManageClaimAssignmentError = crud as MCManageClaimAssignmentError;
        const validObject = isPlainObject(testCrud) && has(testCrud, 'MCAErrorMaintenanceInfo');
        if (validObject) {
            VerifyParentAndArray(testCrud.MCAErrorMaintenanceInfo, 'PayerList', 'Payer');
            VerifyParentAndArray(testCrud.MCAErrorMaintenanceInfo, 'FieldList', 'Field');
            VerifyParentAndArray(testCrud.MCAErrorMaintenanceInfo, 'ErrorCategoryList', 'ErrorCategory');
            VerifyParentAndArray(testCrud.MCAErrorMaintenanceInfo, 'LOBList', 'LOB');
            VerifyParentAndArray(testCrud.MCAErrorMaintenanceInfo, 'FacilityList', 'Facility');
            VerifyParentAndArray(testCrud.MCAErrorMaintenanceInfo, 'DepartmentList', 'Department');
            VerifyParentAndArray(testCrud.MCAErrorMaintenanceInfo, 'UserList', 'User');
            VerifyParentAndArray(testCrud.MCAErrorMaintenanceInfo, 'Rules', 'Rule');
            testCrud.MCAErrorMaintenanceInfo.Rules.Rule.forEach(Rule => {
                VerifyParentAndArray(Rule, 'Users', 'User');
                VerifyParentAndArray(Rule, 'Departments', 'Department');
            })
        }
        return validObject;
    };

export const searchValidationCallback: ValidationCallback<MCAErrorSearchResultInfo> =
    (crud: any): crud is MCAErrorSearchResultInfo => {
        let testCrud: MCAErrorSearchResultInfo = crud as MCAErrorSearchResultInfo;
        const validObject = isPlainObject(testCrud) && has(testCrud, 'MCAResultsMaintenanceInfo');
        if (validObject) {
            VerifyParentAndArray(testCrud.MCAResultsMaintenanceInfo, 'SearchSummary', 'Summary');
            testCrud.MCAResultsMaintenanceInfo.SearchSummary.Summary.forEach(summary => {
                VerifyParentAndArray(summary, 'Details', 'Detail');
            });
        }
        return validObject;
    };

