import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as UserPasswordHintsUI from "@store/ui/UserPasswordHintsUI";
import { CrudTypes } from '@commonResources/CrudTypes';

export type UserPasswordHintsState = ICrudComponentState<MCUserPasswordHints, UserPasswordHintsUI.UserPasswordHintsUiState>;

export const actionCreators = createCrudComponentActions<UserPasswordHintsUI.ActionCreators>(UserPasswordHintsUI.actionCreators);
export type UserPasswordHintsActions = typeof actionCreators;
export type UserPasswordHintsActionProps = ICrudComponentActionProps<UserPasswordHintsActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiUserPasswordQuestions, UserPasswordHintsUI.reducer);
export const reducer = combineReducers<UserPasswordHintsState>(reducers);