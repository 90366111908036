

var MSXML = [
 "Msxml2.DOMDocument.6.0",
 "Msxml2.DOMDocument.5.0",
 "Msxml2.DOMDocument.4.0",
 "Msxml2.DOMDocument.3.0",
 "MSXML2.DOMDocument",
 "Microsoft.XMLDOM"
];

declare var ActiveXObject: (type: string) => any;

export function createDocument() {
    var doc;
    if (document.implementation && document.implementation.createDocument) {
        doc = document.implementation.createDocument("", "", null);
    } else if (ActiveXObject) {
        for (var i = 0; i < MSXML.length; i++) {
            try {
                doc = ActiveXObject('MSXML[i]');
            } catch (e) { }
        }
        if (!doc) {
            alert("Cannot create XMLDocument object");
            return false;
        }
    }
    return doc;
}

export function parseXML(str:any) {
    if (str == null) return null;
    if ($.trim(str) == "") return createDocument();

    var dom = str;

    if (typeof (DOMParser) != "undefined") {
        var parser = new DOMParser();
        try {
            dom = parser.parseFromString(str, "text/xml");
        } catch (e) { };
    } else {
        return;
    }

    var errorMsg = null;
    if (dom.parseError && dom.parseError.errorCode != 0) {
        errorMsg = "XML Parsing Error: " + dom.parseError.reason
                          + " at line " + dom.parseError.line
                          + " at position " + dom.parseError.linepos;
    }
    else {
        if (dom.documentElement) {
            if (dom.documentElement.nodeName == "parsererror") {
                errorMsg = 0; //xmlDoc.documentElement.childNodes[0].nodeValue;
            }
        }
        else {
            errorMsg = "XML Parsing Error!";
        }
    }

    if (errorMsg) {
        //alert(errorMsg);
        return false;
    }

    return dom;
}

export function parseXMLUrl(xmlURL:any) {
    var doc = createDocument();

    if (doc && typeof doc.load != 'undefined') {
        doc.async = false;
        doc.load(xmlURL);
        return doc;
    } else {
        var request = new XMLHttpRequest();
        request.open("GET", xmlURL, false);
        request.send("");
        return request.responseXML;
    }
}

export function getXMLAsString(xmlDoc:any) {
    if (xmlDoc == null) return null;
    return (typeof (DOMParser) != "undefined") ? (new XMLSerializer()).serializeToString(xmlDoc) : xmlDoc.xml;
}

export function xmlLibDesensitizeFilters(xpath:any)
{
    //var newXpath = xpath.replace(/[@](\S*?)[\s]*?=[\s]*?["'](.*?)["']|[@](\S*?)[\s]*?\]/g, export function (match, p1, p2, p3)
    var newXpath = xpath.replace(/[@](\S*?)[\s]*?=[\s]*?["'](.*?)["']|[@](\S*?)[\s]*?(?=\])/g, 
        (match:any, p1:any, p2:any, p3:any) =>
    {
        // https://github.com/google/wicked-good-xpath/issues/46
        // name() may return upper, lower, or proper case, dependent upon browser implementation.
        //return "/@*[name() = '" + p1.toLowerCase() + "']";
		if( p2 )
		{
			return "@*[translate(name(),'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz') = '" + (typeof(p1) != "undefined" ? p1.toLowerCase() : "") + "' and . = '" + (typeof(p2) != "undefined" ? p2 : "") + "']";
		}
		else
		{
			return "@*[translate(name(),'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz') = '" + (typeof(p3) != "undefined" ? p3.toLowerCase() : "") + "']";
		}
    });
    var newXpath = newXpath.replace(/\/[@]([^\/]*)$/g, (match:any, p1:any) => 
    {
        // https://github.com/google/wicked-good-xpath/issues/46
        // name() may return upper, lower, or proper case, dependent upon browser implementation.
        //return "/@*[name() = '" + p1.toLowerCase() + "']";
        return "/@*[translate(name(),'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz') = '" + (typeof (p1) != "undefined" ? p1.toLowerCase() : "") + "']";
    });
    return newXpath.length == 0 ? xpath : newXpath;
}

export function xmlLibEvalXPath(dataRef: any, xpath: any)
{
	xpath = xmlLibDesensitizeFilters(xpath)
    if (dataRef && xpath)
    {
        var node = [];
        try
        {
            //var daterPath = "/Claims/Claim/Responses277/Response277[@*[translate(name(),'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz') = 'inx' and . = '1']]/Claim277/ClaimStatuses277/ClaimStatus277[@*[translate(name(),'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz') = 'inx' and . = '0']]/@*[translate(name(),'ABCDEFGHIJKLMNOPQRSTUVWXYZ','abcdefghijklmnopqrstuvwxyz') = 'statuscategorycode']";
            //wgxpath.install();
            //node = dataRef.evaluate(daterPath, dataRef, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;

            //wgxpath.install();
			var docRef = ((dataRef instanceof Element) ? dataRef.ownerDocument : dataRef);
			var nodeList = docRef.evaluate(xpath, dataRef, null, XPathResult.ORDERED_NODE_ITERATOR_TYPE, null);
			var nodeItem = nodeList.iterateNext();
			while (nodeItem)
			{
				node.push(nodeItem);
				nodeItem = nodeList.iterateNext();
			}
        }
        catch (err)
        {
            node = [];
        }
        if (node != null)
        {
            return node;
        }
    }
    return null;
}

export function selectNodes(xmlDoc:any, xPath:any) {
	var result = xmlLibEvalXPath(xmlDoc, xPath);
	if( result )
	{
		return result;
	}
	return [];
}



export function selectSingleNode(xmlDoc:any, xPath:any) {
    if (xmlDoc == null) return null;
    var nodes = selectNodes(xmlDoc, xPath);
    if (nodes.length > 0) {
        return nodes[0];
    }
    return null;
}

export function transformNode(xmlDoc: any, xsltDoc: any) {
    // 1.
    if (typeof (XSLTProcessor) != "undefined") {
        var xsltProcessor = new XSLTProcessor();
        xsltProcessor.importStylesheet(xsltDoc);
        var xmlFragment = xsltProcessor.transformToFragment(xmlDoc, document);
        return xmlFragment;
    }
    // 2.
    if (typeof (xmlDoc.transformNode) != "undefined") {
        return xmlDoc.transformNode(xsltDoc);
    }
    else {

        try {
            // 3
            if (ActiveXObject || ActiveXObject !== undefined) {
				// Note XSL compatibility changes: https://msdn.microsoft.com/en-us/library/hh180178(v=vs.85).aspx

                var xslt = ActiveXObject("Msxml2.XSLTemplate.6.0");
                var xslDoc = ActiveXObject("Msxml2.FreeThreadedDOMDocument.6.0");
                xslDoc.loadXML((new XMLSerializer()).serializeToString(xsltDoc));
                xslt.stylesheet = xslDoc;
                var xslProc = xslt.createProcessor();
                xslProc.input = xmlDoc;
                xslProc.transform();
                return xslProc.output;
            }
        }
        catch (e) {
            // 4
            alert("The type [XSLTProcessor] and the export function [XmlDocument.transformNode] are not supported by this browser, can't transform XML document to HTML string!");
            return null;
        }

    }

}
