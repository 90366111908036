import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, TextArea, Button } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';

import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { SelectComponent } from '../../common/SelectComponent';

import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';

import { IManualRunState, IManualRunActions, IManualRunActionProps, actionCreators, validationCallback } from '@store/ManualRun';
//import { defaultState as defaultUIState } from '@store/ui/ManualRunUI';


import { ModalConfirmation } from '@common/ModalConfirmation';
import { ICookies_Config } from '@store/ConfigData';



export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;

`;

export const ManualRunContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding: 5px;

    min-height: 310px;
    min-width: 745px !important;
    padding: 10px;


`;


interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    title: string,
};

interface IComponentState {

}

export const DEFAULT_STATE: IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IManualRunProps = IMergeCrudComponentProps<IManualRunState, IManualRunActionProps, IOwnProps>;



export class ManualRun extends React.Component<IManualRunProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        title: "Manual Run",
    };

    constructor(props: IManualRunProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }



    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }

        //     console.log('can edit ' + this.props.canEdit + ' can view ' + this.props.canView);
        this.props.action.configData.getConfigData({ cookies: [{ name: "EnhancedClaimStatusEnabled" }, {name: "DirectEntry"}], config: [{ name: "EnableTenetFeatures" }] });
        this.props.action.crud.get({ crudId: CrudTypes.mctiManualRun }, validationCallback);




    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public componentDidUpdate(prevProps:any, prevState:any) {
        // crud is getting first load...lets select first item in our list
        if (prevProps.dataStore && prevProps.dataStore.crud && !prevProps.dataStore.crud.data &&
            this.crudLoaded() ) {

            // have to build the valid list of autolists, so that the correct first item can be found....ugh
            let allowTenet = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "EnableTenetFeatures");
            let directEntry = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "DirectEntry");
            let ecs = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "EnhancedClaimStatusEnabled");
            
            var al_list = this.loadAutoLists(allowTenet, directEntry, ecs);

            var item = (al_list && al_list.length >= 0) ? al_list[0] : undefined;
            if (item) {
                this.props.action.ui.selectAutoList({
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        id:item['@ID'],
                        text: item['@Name']
                    }
                });
            }
        }
    }

    ///////////////////////////////////
    //   Helper methods
    ///////////////////////////////////



    public crudLoaded(): boolean {
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.ManualRun &&
            this.props.dataStore.crud.data.ManualRun.AutoModuleList &&
            this.props.dataStore.crud.data.ManualRun.AutoProcesses &&
            this.props.dataStore.crud.data.ManualRun.AutoProcesses.AutoProcess)
            return true;

        return false;
    }

    



    /* -----------------------------------  */
    /* --------  ACTION METHODS ----------  */
    /* -----------------------------------  */
    public getSelectedOptions( e : React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }
        return result;
    }

 
    public onSelectAutoModules(e: React.ChangeEvent<HTMLSelectElement>) {
        
        this.props.action.ui.selectAutoModules(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selected: this.getSelectedOptions(e),
                }
            });
    }

    public onSelectAutoList(e: React.ChangeEvent<HTMLSelectElement>) {

        this.props.action.ui.selectAutoList(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    //                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    text: e.target.value !== "" ? e.target.options[e.target.options.selectedIndex].textContent : ""
                }
            });
    }


    public validateForm(): boolean {

        if (!this.props.dataStore.ui.selectedItem ||
            !this.props.dataStore.ui.selectedItem['@ID'] ||
            this.props.dataStore.ui.selectedItem['@ID'] == "")
            return false;

        if (!this.props.dataStore.ui.selectedAutoModules || this.props.dataStore.ui.selectedAutoModules.length == 0)
            return false;

        return true;

    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {

        if (!this.validateForm())
            return;

        const toSubmit: MCManualRunType_Submit = {
            ManualRun: {
                AutoProcesses: {
                    AutoProcess: [{
                        '@ID': this.props.dataStore.ui.selectedItem['@ID'],
                        '@Name': this.props.dataStore.ui.selectedItem['@Name'],
                        AutoModules: {
                            AutoModule: []
                        }
                    }]
                }
            }
        };
        var arrayLength = this.props.dataStore.ui.selectedAutoModules ?  this.props.dataStore.ui.selectedAutoModules.length : 0;
        for (var i = 0; i < arrayLength; i++) {
            let item: any = {};
            item['@ID'] = this.props.dataStore.ui.selectedAutoModules[i];
            toSubmit.ManualRun.AutoProcesses.AutoProcess[0].AutoModules.AutoModule.push(item);
        }
        
     //   console.log(toSubmit);
       
        this.props.action.crud.update({ crudId: CrudTypes.mctiManualRun, data: toSubmit });

        const location = {
            pathname: '/LandingPage',
            state: { fromDashboard: true }
        }

        this.props.history.push('/LandingPage');
    }


    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }
    public onClearAlert(e: any) {
        this.onAlert("");
    }
    public onAlert(msg: string) {
        this.props.action.ui.sendManualRunAlert({ masterCrud: this.props.dataStore.crud.data, uiData: { value: msg } });
    }


    //////////////////////////////////////////////////////////////////////////////
    // data helper methods
    /////////////////////////////////////////////////////////////////////////////
    public loadAutoLists(allowTenet: ICookies_Config, directEntry: ICookies_Config, ecs: ICookies_Config) {
        if (!this.crudLoaded() )
            return [];

        let container: MCMR_AutoProcess[] = [];

        var arrayLength =  this.props.dataStore.crud.data?.ManualRun?.AutoProcesses?.AutoProcess ?  this.props.dataStore.crud.data?.ManualRun?.AutoProcesses?.AutoProcess.length : 0;
        for (var i = 0; i < arrayLength; i++) {
            if( this.props.dataStore.crud.data?.ManualRun?.AutoProcesses?.AutoProcess[i]?.AutoModules?.AutoModule)
            {
                let ams = this.getAutoModulesFromArray(
                                this.props.dataStore.crud.data?.ManualRun?.AutoProcesses?.AutoProcess[i]?.AutoModules?.AutoModule, 
                                allowTenet, directEntry, ecs);
                
                if (ams && ams.length > 0) {
                    container.push(this.props.dataStore.crud.data?.ManualRun?.AutoProcesses?.AutoProcess[i]);
                }
            }
        }

        return container;
    }

    public setSelectedValueAutoList() {
        if (!this.crudLoaded())
            return "";

        if (this.props.dataStore.ui.selectedAutoList && this.props.dataStore.ui.selectedAutoList.id != '')
            return this.props.dataStore.ui.selectedAutoList.id;

        
    }

    private getAutoModulesFromArray(arr : MCMR_AutoModule[], allowTenet: ICookies_Config, directEntry: ICookies_Config, ecs: ICookies_Config) {
        let result: any[] = [];


        var arrayLength = arr ? arr.length : 0;
        var disable = false;

        for (var i = 0; i < arrayLength && disable == false; i++) {
            var item = this.props.dataStore.crud.data?.ManualRun.AutoModuleList?.AutoModule.find(ob2 => { return arr[i]["@ID"] === ob2["@ID"] });

            if (item && disable == false) {
                if (allowTenet && allowTenet.value === "0" && (item['@ModuleType'] === "11" || item['@ModuleType'] === "12")) {
                    disable = true;
                    continue;
                }
                if (directEntry && directEntry.value === "0" && item['@ModuleType'] === "13") {
                    disable = true;
                    continue;
                }
                if (ecs && (ecs.value === "0" || ecs.value === "2") && item['@ModuleType'] === "23") {
                    disable = true;
                    continue;
                }

                result.push(item);   
            }
        }

        return disable == false ? result : [];
    }

    public loadAutoModules(allowTenet: ICookies_Config, directEntry: ICookies_Config, ecs: ICookies_Config) {

        if (!this.crudLoaded() || !this.props.dataStore.ui.selectedItem || !this.props.dataStore.ui.selectedItem.AutoModules || 
                !this.props.dataStore.ui.selectedItem.AutoModules.AutoModule || this.props.dataStore.ui.selectedItem.AutoModules.AutoModule.length == 0 )
            return [];

        let result: any[] = [];
        

            this.props.dataStore.ui.selectedItem.AutoModules.AutoModule.forEach((ob: any) => {
                var item = this.props.dataStore.crud.data?.ManualRun.AutoModuleList?.AutoModule.find(ob2 => { return ob["@ID"] === ob2["@ID"] });

                if (item) {
                    result.push(item);   // { label: item['@Name'], value: item['@ID'] });
                }
            });

        return result;
    }



    public render() {
        var instruction = <React.Fragment>Use this page to force an auto-list to run. Choose the auto-list. You can run all of the auto-modules on the list or select only some to run. When you click OK, the auto-list runs.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={!this.props.dataStore.ui.selectedAutoModules || this.props.dataStore.ui.selectedAutoModules.length == 0 || !this.props.canView} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        let allowTenet = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "EnableTenetFeatures");
        let directEntry = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "DirectEntry");
        let ecs = this.props.dataStore.configData.container.cookies.find((o: ICookies_Config) => o.name === "EnhancedClaimStatusEnabled");

        var data_al = this.loadAutoLists(allowTenet, directEntry, ecs);
        var selected_al = this.setSelectedValueAutoList(); 
        var data_am = this.loadAutoModules(allowTenet, directEntry, ecs);

        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_Maint_RunAutoList.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                <ContentWrapper id={"content_wrapper_ManualRun"}>
                    <ManualRunContent>

                        <DialogFieldset id='autolists-fieldset'>
                            <DialogLegend>Auto Lists</DialogLegend>
                            <SelectComponent
                                height='310px'
                                title='autolists'
                                size={15}
                                width='350px'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAutoList(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@Name"
                                }}
                                records={data_al}
                                selectedValue={selected_al}

                            ></SelectComponent>
                        </DialogFieldset>

                        <DialogFieldset id='automodules-fieldset'>
                            <DialogLegend>Auto Modules</DialogLegend>
                            <SelectComponent
                                height='310px'
                                className='manualRun_select'
                                title='automodules'
                                size={15}
                                width='350px'
                                multiple='true'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectAutoModules(e)}
                                optionFields={{
                                    value: "@ID",
                                    text: "@Name"
                                }}
                                records={data_am}
                                selectedMultiValue={this.props.dataStore.ui.selectedAutoModules}
                            ></SelectComponent>
                        </DialogFieldset>


                    </ManualRunContent>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={!!this.props.dataStore.ui.ManualRunAlert && this.props.dataStore.ui.ManualRunAlert.length > 0}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearAlert(e)}
                    message={this.props.dataStore.ui.ManualRunAlert}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearAlert(e)}
                />
            </DialogWrapper>
        );
    }
};



var connectedHoc = connect<IManualRunState, IManualRunActionProps, IOwnProps, IManualRunProps, ApplicationState>(
    createCrudMapStateToProps('manualRun'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(ManualRun);

export default withRouter(connectedHoc);
