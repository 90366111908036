import { Action, Reducer } from "redux";
import { createAction, createDataAction, ActionTypes} from "@scripts/util/ActionHelpers";
import _ from "lodash";
import { createApiAction, ValidationCallback } from "@scripts/util/ActionHelpers";
import { URLs } from "@commonDevResources/constants";
import { IApiActionData } from "@scripts/util/ApiDataHelpers";
import { Dictionary, NumericDictionary, isEmpty } from "lodash";
import { deepCopyFunction } from "../../commonResources/validations";


const SYS_FACILITY: number = 0; //&H00000001&
const SYS_PHYSICIAN: number = 1; //&H00000002&
const SYS_ALTSPELLINGS: number = 2; //&H00000004& -> PayerAliasAuditInfo
const SYS_BRIDGEMASTER: number = 3; //&H00000008& -> BridgeMasterAuditInfo
const SYS_USER: number = 4; //&H00000010&
const SYS_GROUP: number = 5; //&H00000020& -> GroupMasterAuditInfo
const SYS_SECONDARYBILL: number = 6; //&H00000040&
const SYS_ERRORSEVERITY: number = 7; //&H00000080&
const SYS_CLAIMCHANGES: number = 8; //&H00000100&
const SYS_FILETYPES: number = 9; //&H00000200&
const SYS_UB92PHYSAE: number = 10; //&H00000400&
const SYS_PAYERADDRAE: number = 11; //&H00000800&
const SYS_PAPERCMDS: number = 12; //&H00001000& -> PaperMapsAuditInfo
const SYS_CLAIMOPTIONS: number = 13; //&H00002000&
const SYS_HOLDCODES: number = 14; //&H00004000& -> HoldCodeAuditInfo
const SYS_CSVOPTIONS: number = 15; //&H00008000& -> CSVOptionsAuditInfo
const SYS_REMITIMPORT: number = 16; //&H00010000&
const SYS_NOTETYPES: number = 17; //&H00020000&
const SYS_FIELDLEVELSECURITY: number = 18; //&H00040000&
const SYS_FORMSUSED: number = 19; //&H00080000& -> FormsUsedAuditInfo
const SYS_ACCOUNTPOLICY: number = 20; //&H00100000& -> SecurityAccountPolicyAuditInfo
const SYS_CLAIMFILTER: number = 21; //&H00200000& -> ClaimFilterAuditInfo
const SYS_REMITFILTER: number = 22; //&H00400000& -> RemitFilterAuditInfo
const SYS_AUTOMODULES: number = 24; //&H01000000& - > AutomoduleAuditInfo
const SYS_AUTOPROCESS: number = 25; //&H02000000& -> AutolistsAuditInfo
const SYS_SPINOFFMAP: number = 26; //&H04000000& -> SpinoffFormMapAuditInfo
const SYS_SPLITCLAIMMAP: number = 27; //&H08000000& -> SplitClaimFieldMapAuditInfo
const SYS_ELIGIBILITYPROFILE: number = 28; //&H10000000& -> EligibilityProfileAuditInfo
const SYS_CLAIMEVENTNOTIFY: number = 29; //&H20000000&
const SYS_PARALLONMANAGECROSSOVERS: number = 30; //&H40000000&
const SYS_PREBILL: number = 31; //&H80000000&
const OTHER_SYS_WORKFLOW: number = 32; //&H100000000&


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ReapplySiteTemplateRow {

        Flags : number ,
        Process :string ,  // this is called Data on your pages
        DestinationClientID : number //18,
        ClientID : number ,//= 17,  // this is called TemplateClientId on your pages
        JobTypeId: number //= 201
        displayFlags: string
        DateLastApplied: string,
        LogonAlias : string,
        ClientName: string,
        hasFlags: boolean,
        hasPas: boolean,
        selected : boolean
}

export interface IReapplySiteTemplateUIState {
    initionalPageSet: boolean,
    reapplySiteTemplateAlert?: string,
    reapplySiteTemplateSelectAllAlert?: string,
    isIntialDataLoaded: boolean,
    TableRows: ReapplySiteTemplateRow[],
    infoRow: { '@Flags': string, '@HasFlags': boolean, '@HasPas': boolean },
    allRowsSelected: boolean,
    selectedCount: number,
}

interface IReapplySiteTemplateUIFieldUpdate {
    value: string;
}

interface IReapplySiteTemplateFieldData {
    data: any;
}

//export const defaultReapplySiteTemplate: IReapplySiteTemplateUIData = {};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export interface IReapplyTemplate_LoadFilter extends IApiActionData<MCRST_ReapplySiteTemplateType, IReapplySiteTemplateFieldData> { }
export interface ISelectReapplySiteTemplate extends IApiActionData<MCRST_ReapplySiteTemplateInfo, ReapplySiteTemplateRow[]> { }
export interface IUpdateReapplySiteTemplateField extends IApiActionData< MCRST_ReapplySiteTemplateType, IReapplySiteTemplateUIFieldUpdate> { }

export const actionCreators = {
    loadInitialData: (selectInfo: any) => createDataAction("LOAD_DATA", selectInfo), 
    updateSelectedRows: (selectInfo: any) => createDataAction("UPDATE_SELECTED_ROWS", selectInfo), 
    updateAllSelected: (selectInfo: any) => createDataAction("UPDATE_ALL_SELECTED", selectInfo), 
    sendReapplySiteTemplateAlert: (fieldInfo: IUpdateReapplySiteTemplateField) => createDataAction("SEND_ALERT", fieldInfo),
    updateInitialPage: (selectInfo: any) => createDataAction("UPDATE_INITIAL_PAGE", selectInfo), 
    resetUiState: () => createAction("RESET_UI_STATE"),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>["type"];

function IsFlag(flags :number, source : number) {
    if (source < 32)
        return (flags & Math.pow(2, source));

    flags = (flags / Math.pow(2, 32));
    source -= 32;
    return (flags & Math.pow(2, source));
}

function getFlags(incomingFlags:string, Data: number) {

    var Flags: number = parseInt(incomingFlags);
    var hasPas: boolean = false;
    var hasFlags: boolean = false;

    var Functions: string = '';
    if (IsFlag(Flags, SYS_FACILITY))
        Functions += "Fac, "
    if (IsFlag(Flags, SYS_PHYSICIAN))
        Functions += "Phys, "
    if (IsFlag(Flags, SYS_ALTSPELLINGS))
        Functions += "AltSp, "
    if (IsFlag(Flags, SYS_BRIDGEMASTER))
        Functions += "Br, "
    if (IsFlag(Flags, SYS_USER))
        Functions += "User, "
    if (IsFlag(Flags, SYS_GROUP))
        Functions += "Grp, "
    if (IsFlag(Flags, SYS_SECONDARYBILL))
        Functions += "2nd, "
    if (IsFlag(Flags, SYS_ERRORSEVERITY))
        Functions += "Sev, "
    if (IsFlag(Flags, SYS_CLAIMCHANGES))
        Functions += "CC, "
    if (IsFlag(Flags, SYS_FILETYPES))
        Functions += "FT, "
    if (IsFlag(Flags, SYS_UB92PHYSAE))
        Functions += "UBAE, "
    if (IsFlag(Flags, SYS_PAYERADDRAE))
        Functions += "PAAE, "
    if (IsFlag(Flags, SYS_PAPERCMDS))
        Functions += "Pap, "
    if (IsFlag(Flags, SYS_CLAIMOPTIONS))
        Functions += "ClmO, "
    if (IsFlag(Flags, SYS_HOLDCODES))
        Functions += "HC, "
    if (IsFlag(Flags, SYS_CSVOPTIONS))
        Functions += "CSV, "
    if (IsFlag(Flags, SYS_REMITIMPORT))
        Functions += "Rmt, "
    if (IsFlag(Flags, SYS_NOTETYPES))
        Functions += "Note, "
    if (IsFlag(Flags, SYS_FIELDLEVELSECURITY))
        Functions += "FLS, "
    if (IsFlag(Flags, SYS_FORMSUSED))
        Functions += "Frm, "
    if (IsFlag(Flags, SYS_ACCOUNTPOLICY))
        Functions += "Pol, "
    if (IsFlag(Flags, SYS_CLAIMFILTER))
        Functions += "CFlt, "
    if (IsFlag(Flags, SYS_REMITFILTER))
        Functions += "RFlt, "
    if (IsFlag(Flags, SYS_AUTOMODULES))
        Functions += "AM, "
    if (IsFlag(Flags, SYS_AUTOPROCESS))
        Functions += "AP, "
    if (IsFlag(Flags, SYS_SPINOFFMAP))
        Functions += "Spin, "
    if (IsFlag(Flags, SYS_SPLITCLAIMMAP))
        Functions += "Splt, "
    if (IsFlag(Flags, SYS_ELIGIBILITYPROFILE))
        Functions += "EL, "
    if (IsFlag(Flags, SYS_CLAIMEVENTNOTIFY))
        Functions += "CEN, "


    if (Data & 0x1)
        Functions += "TBL, "
    if (Data & 0x2)
        Functions += "307, "

    if (Functions.length > 0) hasFlags = true;

    if (IsFlag(Flags, OTHER_SYS_WORKFLOW)) { // IsFlag(Flags,OTHER_SYS_WORKFLOW)) {
        Functions += "WKFW[PAS], "
        hasPas = true;
    }

    if (Functions.length > 2)
        Functions = Functions.substring(0, Functions.length - 2);

    return {
        '@Flags': Functions, '@HasFlags': hasFlags, '@HasPas': hasPas
    } 

}

export const defaultState: IReapplySiteTemplateUIState = {
    initionalPageSet: false,
    reapplySiteTemplateAlert: "",
    reapplySiteTemplateSelectAllAlert: "",
    isIntialDataLoaded: false,
    TableRows: [], 
    infoRow: { '@Flags': '', '@HasFlags': false, '@HasPas': false },
    allRowsSelected: false,
    selectedCount: 0,
    
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IReapplySiteTemplateUIState, KnownActions> = (
    state: IReapplySiteTemplateUIState | undefined,
    action: KnownActions
) => {
    if (state != undefined) {
        switch (action.type) {

            case "LOAD_DATA":
                var dataLoaded = false;
                var newRows = { ...state.TableRows };
          
                if (!dataLoaded && !isEmpty(action.data.uiData)) {
                    dataLoaded = true;
                    var temp: MCRST_ReapplySiteTemplateType = action.data.uiData;
                    if (temp.SiteTemplatesInfo && temp.SiteTemplatesInfo.LastSiteTemplateInfo &&
                        temp.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate) {
                        var LastSiteTemplate: MCRST_LastSiteTemplate[] = temp.SiteTemplatesInfo.LastSiteTemplateInfo.LastSiteTemplate;

                        newRows = LastSiteTemplate.map(row => {
                            state.infoRow = getFlags((row["@Flags"]), parseInt(row["@Data"]));
                            var passThruFlags: number = +row["@Flags"];
                            return {
                                Flags: passThruFlags,
                                Process: row["@Data"],
                                DestinationClientID: parseInt(row["@DestinationClientId"]),
                                ClientID: parseInt(row["@SourceClientId"]),
                                JobTypeId: parseInt(row["@JobId"]),
                                displayFlags: state.infoRow["@Flags"],
                                DateLastApplied: row["@DateApplied"],
                                LogonAlias: row["@LogonAlias"],
                                ClientName: row["@ClientName"],
                                hasFlags: state.infoRow["@HasFlags"],
                                hasPas: state.infoRow["@HasPas"],
                                selected: false,
                            }
                        });
                    } else {
                        newRows = [];
                    }
                }
                //console.log(newRows);
                return {
                    ...state,
                    isIntialDataLoaded: dataLoaded,
                    initionalPageSet: false,
                    TableRows: newRows,
                };

                break;

            case "UPDATE_SELECTED_ROWS":

                var row: ReapplySiteTemplateRow = action.data.uiData

                var newRows = { ...state.TableRows };

                row.selected = !row.selected;

                if (row.selected) {
                    state.selectedCount++
                } else {
                    state.selectedCount--
                }

                return {
                    ...state,

                    TableRows: newRows,
                };

                break;

            case "UPDATE_ALL_SELECTED":

                var newRows = { ...state.TableRows };

                newRows =  _.map(
                    state.TableRows,
                    (row: ReapplySiteTemplateRow) => {

                    return {
                        ...row,
                        selected: action.data,
                    }
                });

                return {
                    ...state,
                    TableRows: newRows
                };

                break;

            case "UPDATE_INITIAL_PAGE":

                return {
                    ...state,
                    initionalPageSet: action.data
                };

                break;

            case "SEND_ALERT":
                return {
                    ...state,
                    reapplySiteTemplateAlert: action.data.uiData.value,
                    allRowsSelected: action.data.uiData.value == "" ? false : state.allRowsSelected,
                };
                break;

            case "RESET_UI_STATE":
                return {
                    ...state,
                    initionalPageSet: false,
                    isIntialDataLoaded: false,
                    TableRows: [],
                    allRowsSelected: false,
                };

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
};
