import * as React from 'react';
import styled, { css } from 'styled-components';
import { Colors } from '../../../commonResources/colorVariables';
import { Typography } from '../../../commonResources/typography';
import { displayFlex, flexRowWrap, flexRowNoWrap, flexFill, flexRigid, flexContainerWrap, flexContainerNoWrap, ARMExceptionStyling, ARMExceptionInput } from './FlexStyles';

//
//
// Panel definitions
//
// The following styled component definitions lay out the quick search panels for the legacy home rendered version.
//
//

// Parent container for legacy home page rendition.
export const ARMLegacyHomePanel = styled.div`
    ${flexContainerWrap};
    align-items: flex-start;
    justify-content: space-between;
    min-width: 390px;
    min-height: 236px;
    width: 397px;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    background-color: ${Colors.darkestSecondaryGrey};
    color: ${Colors.white};
`;

// Interface for generic row container with properties to adjust styling and positioning.
interface ILegacyHomePanelRowProps {
    wrap?: boolean;
    justifyContent?: "flex-start" | "flex-end" | "center" | "space-between" | "space-around" | "space-evenly";
    alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline";
    widthValue?: string;
}

// Generic row container with properties to adjust styling and positioning.
export const ARMLegacyHomePanelRow = styled.div`
    ${(props: ILegacyHomePanelRowProps) => props.wrap ? flexContainerWrap : flexContainerNoWrap};
    ${flexFill};
    align-items: ${(props: ILegacyHomePanelRowProps) => props.alignItems ? props.alignItems : `center`};
    justify-content: ${(props: ILegacyHomePanelRowProps) => props.justifyContent ? props.justifyContent : `space-between`};
    ${(props: ILegacyHomePanelRowProps) => props.widthValue && `width: ${props.widthValue}`};
`;

// Title wrapper for style and positioning.
export const ARMLegacyHomePanelTitle = styled.div`
    ${displayFlex};
    ${flexFill};
    align-items: center;
    justify-content: center;
    ${Typography.bold};
`;
export const ARMLegacyHomePanelSubTitle = styled.div`
    ${displayFlex};
    ${flexFill};
    align-items: center;
    justify-content: center;
    ${Typography.bold};
    width: 100%;
`;
