import * as React from "react";
import Colors from '@commonResources/colorVariables';

interface IComponentProps {
    fieldId: string,
    fieldName: string,
    trigger: boolean,
    message: string,
    style: Object
    validationAggregationRoutine: Function,
    validationAggregation: Map<string, boolean>,
};

export class GenericValidator extends React.Component<IComponentProps>{
    constructor(props: IComponentProps) {
        super(props);
    }

    render() {
        const {
            trigger,
            message,
            style,
            fieldId,
            validationAggregation,
        } = this.props;

        let isValid = !trigger;

        // need to propogate field isvalid ref to the top parent so details are propogated from top to bottom 
        this.props.validationAggregationRoutine(fieldId, isValid);

        // above code will update state and next passthrough will flow through to this next check
        if (validationAggregation.size > 0 && validationAggregation.get(fieldId) == false) {
            isValid = false;
        }

        if (isValid) return null;

        return (
            trigger &&
            <tr>
                <td></td>
                <td></td>
                <td style={{ color: Colors.digitalRed130 }}>
                    <div style={style}>{message}</div>
                </td>
            </tr>
        )
    }
}
