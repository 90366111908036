import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Button, SelectDropdown } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { SelectComponent } from '@common/SelectComponent';
import { CheckBoxComponent } from '@common/CheckBox';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IPaperClaimsPrintingMaintenanceState , IPaperClaimsPrintingMaintenanceActionProps, actionCreators } from '@store/PaperClaimsPrintingMaintenance';

//import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import {URLs} from "@commonResources/constants";
import {getRawToken} from "@scripts/session/SecurityToken";
//import { IPaperClaimListActionProps } from '@store/PaperClaimList';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    width: 700px;
    flex-wrap:wrap;
`;
export const AccountName = styled.div`
    width: 200px;
`;

export const HiddenSpan = styled.span`
    display: none;
`;

export const AccountDisabled = styled.div`
    color: ${Colors.darkGrey}
    ${Typography.small}
    padding-top: 20px;
    padding-left: 10px;
    font-weight: bold;
`;

export const PrintOptionsWrapper = styled.div`
    width: 400px;
    padding-left:80px;
    .previewButton{
        margin:15px;
    }
`;

export const PaperOptions = styled.div`
    width: 600px;
    padding-left:20px;
    display :block;
`;

export const DateOptions = styled.div`
    width: 600px;
    padding-left:20px;
    display :block;
    padding-top:10px;
    padding-bottom:10px;
    span.date-title{
        position:relative;
        top:25px;
        font-weight:bold;
    }
`

export const GenerateDays = styled.div`
    width: 80px;
`;

export const FunctionSection = styled.div`
    display: flex;
    flex: 1 1 auto;
    width: 100%;
    margin-bottom: 5px;
`;

export const CheckBoxWrapper = styled.div`
    float: left;
    width: 160px;
    .arm_checkbox_label {
        padding-left:0px;
    }
`;

export const CheckBoxRightWrapper = styled.div`
    float: right;
    width: 160px;
    margin-right:50px;
    .arm_checkbox_label {
        padding-left:0px;
    }
`;

export const FunctionHideGutter = styled.div`
    height: 35px;
`;

export const FunctionLabel = styled.label`
    font-weight: 600;
    text-transform: uppercase;
    ${Typography.small};
    color: ${Colors.default};
    letter-spacing: 0.5px;
    margin-bottom: 0.3rem;
`;

export const EmptyHeader = styled.div``;

export const SelectList = styled.div`
    padding: 5px;
    padding-left:10px;
    fieldset{
        width:125px;
        height:165px;
        select{
            width:120px;
            height:140px;
        }
    }
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    float: center;
`;
export const AccountButtonActions = styled(SelectActions)`
`;

export const SelectButtons = styled.div`
    display: flex;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const ScheduleHiddenGutter = styled.div`
    height: 30px;
`;

export const ScheduleOverflow = styled.div`
    overflow: auto;
    height: 40px;
    width: 445px;
`;
export const FiOverflow = styled.div`
    overflow: auto;
    height: 100px;
    width: 445px;
`;


export const FiHeaderRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    font-size: ${Typography.small};
    color: ${Colors.white};
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 2px;
`;

export const FiAliasHeader = styled.div`
    background-color: ${Colors.middleSecondaryGrey};
    padding-left: 1px;
    width: 100px;
    margin-right: 2px;
`;

export const FiUserPwdHeader = styled.div`
    background-color: ${Colors.middleSecondaryGrey};
    padding-left: 1px;
    width: 150px;
    margin-right: 2px;
`;

export const FiItemRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    font-size: ${Typography.small};
    margin-bottom: 2px;
`;

export const FiAliasItem = styled.div`
    width: 100px;
    margin-right: 2px;
`;

export const FiUserPwdItem = styled.div`
    width: 150px;
    margin-right: 2px;
`;

export const DatePickerWrapper = styled.div`
    margin-left:5px;
    display:inline;
    .cal_style{
        display: inline-flex;
    }
    
   
`;

export const DatePickerContainer = styled.div`
     display: inline-grid;
     label{
         font-weight:bold;
     }
`;

export const DialogFieldset = styled.fieldset`
    width: 670px;
    border: 1px solid ${Colors.darkGrey};
    padding: 4px;
    
`;

export const DialogLegend = styled.legend`
    width: initial;
    border: initial;
    margin-bottom: initial;
    color: ${Colors.darkGrey};
    ${Typography.medium};
    ${Typography.ARMFontFamily};
`;

export const GenericInputRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom: 2px;
`;

export const ElementWrapper = styled.div`
    float: left;
    padding: 0px 4px 0px 4px
`;

export const GenericLabel = styled.div`
    ${Typography.default}
    ${Typography.bold}
    padding-top: 2px;
    float: left;
`;

export const PasswordErrorGutter = styled.div`
    height: 12px;
`;

export const ErrorMessageForInputWithHeading = styled.div`
    margin-top: 15px;
    margin-left: 10px;
    color: ${Colors.digitalRed130};
    ${Typography.small}
`;

export const ErrorMessageForInput = styled.div`
    margin-top: 5px;
    margin-left: 10px;
    color: ${Colors.digitalRed130};
    ${Typography.small}
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    canSecCheck1: boolean,
    canSecCheck2: boolean,

    title: string,
};


interface IComponentState {
}

export const DEFAULT_STATE: IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IPaperClaimsPrintingMaintenanceProps = IMergeCrudComponentProps<IPaperClaimsPrintingMaintenanceState, IPaperClaimsPrintingMaintenanceActionProps, IOwnProps>;




export class PaperClaimsPrintingMaintenance extends React.Component<IPaperClaimsPrintingMaintenanceProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canSecCheck2: false,
        canSecCheck1 : false,
        title: "Print Maintenance",
    };

    
    minDate: string;

    constructor(props: IPaperClaimsPrintingMaintenanceProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.minDate = this.getDateInMMDDYYYYHHMMSSFormat(new Date());

    }

    public componentWillMount() {
        //this.props.action.ui.reset(13);
        this.props.action.crud.get({ crudId: CrudTypes.mctiPaperClaimsConfig });
        // this.setInitialLoad();
    }

    public componentDidMount() {
       
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        //if (!this.validateForm())
        //    return;

        let PrintOptionList = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.PrintOptionList;
        let DateOption = this.props.dataStore.ui.DateOptions.DateOption;
        let PutPaperClaimsPrintingMaintenanceInfo:any = {
            PaperClaimsPrintingMaintenanceInfo: {
            PrintOptionList: {
            }
        }
        };
       let putPrintOptions = PrintOptionList.PrintOption.map((element:any) => {
            let obj:any = {'@ID':element['@ID'], '@FontFaceID':element['@FontFaceID'], '@FontSizeID':element['@FontSizeID'], '@PrintTypeID':element['@PrintTypeID']}
            obj['@bitBatchClaimPrompt'] = DateOption['@BatchClaimPrompt'];
            obj['@bitSingleClaimPrompt'] = DateOption['@SingleClaimPrompt'];
            obj['@CMSPrintDate'] = DateOption['@CMSPrintDate'] || '';
            obj['@CMSClsPrintDate'] = DateOption['@CMSClsPrintDate'] || '';
            obj['@CMSViewDate'] = DateOption['@CMSViewDate'] || '';
            return obj;
        });
        console.log(this.props.dataStore.crud.data);
        PutPaperClaimsPrintingMaintenanceInfo.PaperClaimsPrintingMaintenanceInfo.PrintOptionList.PrintOption = putPrintOptions;
        
        this.props.action.crud.update({crudId: CrudTypes.mctiPaperClaimsConfig, data: PutPaperClaimsPrintingMaintenanceInfo });
        // this.props.action.ui.resetDefaultState();


        this.props.history.push('/LandingPage');
    }


    public dateAdd(date: any, type: string, amount: number) {
        try {
            var y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();
            if (type === 'y') {
                y += amount;
            };
            if (type === 'm') {
                m += amount;
            };
            if (type === 'd') {
                d += amount;
            };
            var xx = new Date(y, m, d);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateAdd');
        }
        return "00/00/0000";
    }

    public getDateInMMDDYYYYFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var year = input.getFullYear();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000";
    }

    public onSelectClaimType(e: React.ChangeEvent<HTMLSelectElement>) {
        let selectedPrintOption = this.props.dataStore.ui.printOptionList.PrintOption.find(
            (ob:any) => ob['@ClaimTypeID'] ===  e.target.value);
        this.props.action.ui.selectPrintOptions({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                printOption: selectedPrintOption
            }
        });
    }

    public updatePrintType(e: React.ChangeEvent<HTMLSelectElement>) {
        let selectedPrintOptionIndex = this.props.dataStore.ui.printOptionList.PrintOption.findIndex(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption['@ID']);
        let printOptionList = this.props.dataStore.ui.printOptionList;
        printOptionList.PrintOption[selectedPrintOptionIndex]['@PrintTypeID'] = e.target.value;
        this.props.action.ui.updatePrintType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                printOptionList: printOptionList
            }
        });
    }

    public updateFontSize(e: React.ChangeEvent<HTMLSelectElement>) {
        let selectedPrintOptionIndex = this.props.dataStore.ui.printOptionList.PrintOption.findIndex(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption['@ID']);
        let printOptionList = this.props.dataStore.ui.printOptionList;
        printOptionList.PrintOption[selectedPrintOptionIndex]['@FontSizeID'] = e.target.value;
        this.props.action.ui.updatePrintType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                printOptionList: printOptionList
            }
        });
    }

    public updateFontFace(e: React.ChangeEvent<HTMLSelectElement>) {
        let selectedPrintOptionIndex = this.props.dataStore.ui.printOptionList.PrintOption.findIndex(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption['@ID']);
        let printOptionList = this.props.dataStore.ui.printOptionList;
        printOptionList.PrintOption[selectedPrintOptionIndex]['@FontFaceID'] = e.target.value;
        this.props.action.ui.updatePrintType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                printOptionList: printOptionList
            }
        });
    }

    public getSelectedFormType() {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.ui.selectPrintOption || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType
        ) return { value: '', label: '' };

        let item = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType.find(
            (ob: any) => ob['@ID'] === this.props.dataStore.ui.selectPrintOption["@StateID"]);
        let result = { value: '', label: '' };
        if (item) {
            let label = item['@Name'];
            if (item['@ID'] == "0") {
                label = "Select State";
            }
            result.label = label;
            result.value = item['@ID'];
        } else {
            result.value = "Select State";
            result.label = '- Select State -';
        }

        return result;
    }

    public getFormTypesList() {

        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType
        ) return [];

        let result: any[] = [];

        if (!this.props.dataStore.ui.initialValuesLoaded) {
            this.setInitialPrintOption();
        }



        this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType.forEach((ob:any) => {
            if (ob['@Name'] && ob['@ID'] !== "0")
                result.push({ name: ob['@Name'], id: ob['@ID'] });
        });

       
       

        return result;

    }

    public setInitialPrintOption() {

        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.PrintOptionList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.PrintOptionList.PrintOption
        ) return ;

        this.updatePrintOption(this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.PrintOptionList.PrintOption[0])
        
    }

    public updatePrintOption(printOption:any) {
        this.props.action.ui.updatePrintOptions({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
               printOption
            }
        });
    }

    public getDateOptions(){
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.DateOptions ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.DateOptions.DateOption
        ) return {batchClaimReport:false, singleClaimReport: false};

        let dateOption = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.DateOptions.DateOption;
        if ( dateOption['@ID'] !== "0")
            return {batchClaimReport: dateOption['@BatchClaimPrompt'] == '1'? true:false, singleClaimReport: dateOption['@SingleClaimPrompt'] == '1' ? true:false}
            

    }

    public getPrintTypeList(){
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType
        ) return [];

        let result: any[] = [];

        this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType.forEach((ob:any) => {
            const ClaimTypeID = this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"]
            const redFormOnlyArray = ["3004"]
            const grayFormOnlyArray = ["3010", "3011", "3012", "3013", "3014"]

            const isRedFormOnly = redFormOnlyArray.includes(ClaimTypeID)
            const isGrayFormOnly = grayFormOnlyArray.includes(ClaimTypeID)
            const isAllForms = !isRedFormOnly && !isGrayFormOnly

            const isRedForm = ob['@Name'] === "Red Form"
            const isGrayForm = ob['@Name'] === "Gray Form"

            if ((isRedFormOnly && isRedForm) || (isGrayFormOnly && isGrayForm) || isAllForms) {
                result.push({ label: ob['@Name'], value: ob['@ID'] });
            }
        });
        return result;
    }

    public getFontFaceList(){
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace
        ) return [];

        let result: any[] = [];

        this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace.forEach((ob:any) => {
            if (ob['@Name'] && ob['@ID'] !== "0")
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getFontSizeList(){
        if (!this.props.dataStore.crud.data || !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize
        ) return [];

        let result: any[] = [];

        this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize.forEach((ob:any) => {
            if (ob['@Name'] && ob['@ID'] !== "0")
                result.push({ label: ob['@Name'], value: ob['@ID'] });
        });
        return result;
    }

    public getInitialPrintTypeActionSelectedItem() {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.ui.selectedPrintOption ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType)
            return { value: '', label: '' };


        let result = { value: '', label: '' }; 
        let selectedPrintType = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@PrintTypeID"]);
        let formType = this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"]
        
        var dentalForm = (formType == '3010' || formType == '3011' || formType == '3012' || formType == '3013'|| formType == '3014' );
        var ca251 = (formType == '3004')

        if(dentalForm){
            result.value = '1';
        } else if(ca251){
            result.value = '2';
        } else if (selectedPrintType) {
           
            result.value = selectedPrintType['@ID'];
        } else {
            result.value = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FormTypeList.FormType[0]['@ID'];
        }

        return result.value;
    }

    public getInitialFontFaceActionSelectedItem() {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.ui.selectedPrintOption ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace)
            return { value: '', label: '' };


        let result = { value: '', label: '' };

        let selectedFontFace = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@FontFaceID"]);
        if (selectedFontFace) {
           
            result.value = selectedFontFace['@ID'];
        } else {
            result.value = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace[0]['@ID'];
        }

        return result.value;
    }

    public getInitialFontSizeActionSelectedItem() {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.ui.selectedPrintOption ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize)
            return { value: '', label: '' };


        let result = { value: '', label: '' };

        let selectedFontSize = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@FontSizeID"]);
        if (selectedFontSize) {
            result.value = selectedFontSize['@ID'];
           
        }else{
            result.value = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize[0]['@ID'];
        }
        return result.value;
    }


    public getInitialClaimTypeActionSelectedItem() {
        if (!this.props.dataStore.crud.data || !this.props.dataStore.ui.selectedPrintOption ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList ||
            !this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType)
            return '';


        let result = '';

        let selectedClaimType = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"]);
        if (selectedClaimType) {
          
            result = selectedClaimType['@ID'];
           
        }else{
          
            result = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.ClaimTypeList.ClaimType[0]['@ID'];
        }
        return result;
    }

    public onToggleBatchClaimReport(checked: boolean) {
        let DateOptions =  this.props.dataStore.ui.DateOptions;
        DateOptions.DateOption['@BatchClaimPrompt'] = checked ? '1' : '0';
        this.props.action.ui.onToggleBatchClaimReport({ masterCrud: this.props.dataStore.crud.data, uiData: { DateOptions: DateOptions } });
    }

    public onToggleSingleClaimReport(checked: boolean) {
        let DateOptions =  this.props.dataStore.ui.DateOptions;
        DateOptions.DateOption['@SingleClaimPrompt'] = checked ? '1' : '0';
        this.props.action.ui.onToggleSingleClaimReport({ masterCrud: this.props.dataStore.crud.data, uiData: { DateOptions: DateOptions } });
    }

    public CheckRecievedDateFrom(): string {
        var twoYearsAgo = new Date();
        twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

        var year = '2014';

        return '04' + '/' + '01' + '/' + year;
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
            this.props.history.push('/LandingPage');
    }

    public getDateInMMDDYYYYHHMMSSFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var hour = input.getHours();
            var year = input.getFullYear();
            var minute = input.getMinutes();
            var seconds = input.getSeconds();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year + ' ' + hour + ':' + minute + ':' + seconds; 
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000 00:00:00";
    }

    public onPreviewClick(){

        let selectedFontFace = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontFaceList.FontFace.find(
            (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@FontFaceID"]);

            let selectedFontSize = this.props.dataStore.crud.data.PaperClaimsPrintingMaintenanceInfo.FontSizeList.FontSize.find(
                (ob:any) => ob['@ID'] ===  this.props.dataStore.ui.selectedPrintOption["@FontSizeID"]);

        const ClaimTypeID = this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"]
        const redFormOnlyArray = ["3004"]
        const grayFormOnlyArray = ["3010", "3011", "3012", "3013", "3014"]

        const isRedFormOnly = redFormOnlyArray.includes(ClaimTypeID)
        const isGrayFormOnly = grayFormOnlyArray.includes(ClaimTypeID)

        const PrintTypeID = parseInt(this.props.dataStore.ui.selectedPrintOption['@PrintTypeID'])

        let PrintID

        if (isGrayFormOnly) {
            PrintID = 1
        } else if (isRedFormOnly) {
            PrintID = 2
        } else {
            PrintID = PrintTypeID
        }
        
        var params:any = {
                Form: parseInt(ClaimTypeID),
                Print: PrintID,
                Font: selectedFontFace['@Name'],
                Size: parseInt(selectedFontSize['@Name']),
                Adjust:Number(selectedFontSize['@FontAdjust'])
        }
        if (params.Form == 1001)
            params.Form = 3001;
        if (params.Form == 1011)
            params.Form = 2011;
        let query = Object.keys(params)
             .map((k:any) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
             .join('&');
        let mypath = ''; 
        fetch(URLs.api + `/api/data/checkDataAlignmentWithParams`,
                {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `${getRawToken()}`
                },
                body: JSON.stringify(params),
            }).then(async (response) => {
                if (response.status === 200) {
                     return response.json();
                } else {
                    throw new Error(response.statusText);
                }
            }).then((data: any) => {
                mypath = data.PrintResponses[0].Path;
                console.log("PaperClaimsPrinting - CheckDataAlignment - path=" + mypath);
                window.document.open("/shared/Open%3D" + mypath, 'FormAlignment', 'width=1273,height=1250');
            })
            .catch(error => {
                console.log("PaperClaimsPrinting - CheckDataAlignment - Error");
                alert('Preview Request Failed. Please try again. If the issue persists, please contact Support.');
                //sessionStorage.setItem('SystemErrorNotification', 'Check Data Alignment request failed. Please try again. If the issue persists, please contact Support.');
            })
            .finally(() => {
                this.setState({ funcCallBusy: false });
                //this.props.history.push('/ui/LandingPage');
            });
    }

    public onViewEditDateChange(e: string) {
    
    let DateOptions =  this.props.dataStore.ui.DateOptions;
    DateOptions.DateOption['@CMSViewDate'] = e;
        this.props.action.ui.updateViewEditDate({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                DateOptions: DateOptions
            }       
    })
}

public onLocalPrintDateChange(e: string) {
    let DateOptions = this.props.dataStore.ui.DateOptions;
    DateOptions.DateOption['@CMSPrintDate'] = e;
    this.props.action.ui.updateLocatPrintDate({
        masterCrud: this.props.dataStore.crud.data,
        uiData: {
            DateOptions: DateOptions
        }       
})
}

public onCLSPrintDateChange(e: string) {
    let DateOptions =  this.props.dataStore.ui.DateOptions;
    DateOptions.DateOption['@CMSClsPrintDate'] = e;
    this.props.action.ui.updateCLSPrintDate({
        masterCrud: this.props.dataStore.crud.data,
        uiData: {
            DateOptions: DateOptions
        }       
})
}

public isFontDisabled(){
    let formType = this.props.dataStore.ui && this.props.dataStore.ui.selectedPrintOption && this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"];
    var dentalForm = (formType == '3010' || formType == '3011' || formType == '3012' || formType == '3013'|| formType == '3014' );
        var ca251 = (formType == '3004')
    return !dentalForm && !ca251 &&  this.props.dataStore.ui && this.props.dataStore.ui.selectedPrintOption && (this.props.dataStore.ui.selectedPrintOption['@PrintTypeID'] === '3' ||this.props.dataStore.ui.selectedPrintOption['@PrintTypeID'] === '4' )

}



public render() {
    var instruction = <React.Fragment>Use this page to set up a printing option for each type of claim form you print. Select the claim form, then select the print option and click OK.</React.Fragment>;
    var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e) } />;
        let formTypesList = this.getFormTypesList();
        let printTypeList = this.getPrintTypeList();
        let fontFaceList = this.getFontFaceList();
        let fontSizeList = this.getFontSizeList();

        return (
            <DialogWrapper title="Print Options" instruction={instruction} buttons={buttons}  helpUrl='/Support/Help/HELP_Maint_PaperClaims.htm' >
                <ContentWrapper>
                <FunctionSection>
                <SelectList>
                        <DialogFieldset>
                            <DialogLegend>Form Types</DialogLegend>
                            <SelectComponent
                                title='Print Options'
                                size={10}
                                width='200px'
                                optionFields={{
                                    value: "id",
                                    text: "name"
                                }}
                                records={formTypesList}
                                selectedValue={this.getInitialClaimTypeActionSelectedItem()}
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectClaimType(e)}
                            >
                            </SelectComponent>
                        </DialogFieldset>
                    </SelectList>
                    <PrintOptionsWrapper>
                    <SelectDropdown
                                domID="fiName-select"
                                label="Print Type:"
                                options={printTypeList}
                                className="fiName-class"
                                size="large"
                                initialValue={this.getInitialPrintTypeActionSelectedItem()}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.updatePrintType(e)}
                                errorMessage=""
                                    />
                    <SelectDropdown
                                domID="fiName-select"
                                label="Font Face:"
                                options={fontFaceList}
                                className="fiName-class"
                                size="large"
                                initialValue={this.getInitialFontFaceActionSelectedItem()}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateFontFace(e)}
                                errorMessage=""
                                disabled = {this.isFontDisabled()}  />
                    <SelectDropdown
                                domID="fiName-select"
                                label="Font Size:"
                                options={fontSizeList}
                                className="fiName-class"
                                initialValue={this.getInitialFontSizeActionSelectedItem()}
                                size="large"
                                errorMessage=""
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateFontSize(e)}
                                disabled = {this.isFontDisabled()}
                                    />
                     <Button
                            domID="PaperClaimPrintingMaintance_PreviewButton"
                            name="Preview"
                            buttonType="standard"
                            size="small"
                            type="button"
                            className="previewButton"
                            disabled = {this.isFontDisabled() || this.props.dataStore.ui.selectedPrintOption["@ClaimTypeID"] === '1000'}
                            onClick={() => this.onPreviewClick()}
                                                        
                        />
                     </PrintOptionsWrapper>
                     </FunctionSection>
                    
                     <PaperOptions>
                     <DialogFieldset>
                            <DialogLegend>Prompt For paper options</DialogLegend>
                            <CheckBoxWrapper>
                                    <CheckBoxComponent
                                        id='batchClaim'
                                        label='Batch Claim Printing'
                                        width='200px'
                                        checked={ this.props.dataStore.ui.batchClaimReport === '1' ? true: false }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>  this.onToggleBatchClaimReport(e.target.checked)}
                                    />
                                </CheckBoxWrapper>
                                <CheckBoxRightWrapper>
                                    <CheckBoxComponent
                                        id='singleClaim'
                                        label='Single Claim Printing'
                                        width='200px'
                                        checked={ this.props.dataStore.ui.singleClaimReport === '1' ? true: false }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>  this.onToggleSingleClaimReport(e.target.checked)}
                                    />
                                </CheckBoxRightWrapper>
                     </DialogFieldset>
                     </PaperOptions>
                    {/* <DateOptions>
                     <DialogFieldset>
                     <DialogLegend>Start Dates for new form</DialogLegend>
                    <span className="date-title"> 1500-2012:</span>
                     <DatePickerWrapper>
                         <DatePickerContainer>
                      <label> View/Edit</label>
                     <ARMDatePicker
                                    domID="viewedit_PaperClaimsPrintingMaintainance"
                                    className="cal_style"
                                    onInputChange={(e: any) => this.onViewEditDateChange(e)}
                                    allowTime={false}
                                    minDate={this.minDate || this.getDateInMMDDYYYYHHMMSSFormat(new Date())}
                                    assocTo={this.props.dataStore.ui.viewEditDate}
                                    initialDate={this.props.dataStore.ui.viewEditDate}
                                    disableHowLong={true}
                                />
                                </DatePickerContainer>
                                    </DatePickerWrapper>

                                    <DatePickerWrapper>
                                        <DatePickerContainer>
                                    <label> Local Print</label>
                                    <ARMDatePicker
                                    domID="localprint_PaperClaimsPrintingMaintainance"
                                    className="cal_style"
                                    onInputChange={(e: any) => this.onLocalPrintDateChange(e)}
                                    allowTime={false}
                                    minDate={this.minDate || this.getDateInMMDDYYYYHHMMSSFormat(new Date())}
                                    assocTo={this.props.dataStore.ui.localPrintDate}
                                    initialDate={this.props.dataStore.ui.localPrintDate }
                                    disableHowLong={true}
                                />
                                </DatePickerContainer>
                                    </DatePickerWrapper>
                                    <DatePickerWrapper>
                                        <DatePickerContainer>
                                    <label> CLS Print</label>
                                    <ARMDatePicker
                                    domID="clsprint_PaperClaimsPrintingMaintainance"
                                    className="cal_style"
                                    onInputChange={(e: any) => this.onCLSPrintDateChange(e)}
                                    allowTime={false}
                                    minDate={this.minDate || this.getDateInMMDDYYYYHHMMSSFormat(new Date())}
                                    assocTo={this.props.dataStore.ui.CLSPrintDate}
                                    initialDate={this.props.dataStore.ui.CLSPrintDate }
                                    disableHowLong={true}
                                />
                                </DatePickerContainer>
                                    </DatePickerWrapper>
                     </DialogFieldset>
                     </DateOptions> */}

                     </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IPaperClaimsPrintingMaintenanceState, IPaperClaimsPrintingMaintenanceActionProps, IOwnProps, IPaperClaimsPrintingMaintenanceProps, ApplicationState>(
    createCrudMapStateToProps('PaperClaimsPrintingMaintenance'),             // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(PaperClaimsPrintingMaintenance);

export default withRouter(connectedHoc);
