import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as FieldLevelSecurityUI from './ui/FieldLevelSecurityUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IFieldLevelSecurityState = ICrudComponentState<MCFieldLevelSecurity, FieldLevelSecurityUI.IFieldLevelSecurityUIState>;

export const actionCreators = createCrudComponentActions<FieldLevelSecurityUI.ActionCreators>(FieldLevelSecurityUI.actionCreators);
export type IFieldLevelSecurityActions = typeof actionCreators;
export type IFieldLevelSecurityActionProps = ICrudComponentActionProps<IFieldLevelSecurityActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiFieldLevelSecurity, FieldLevelSecurityUI.reducer);
export const reducer = combineReducers<IFieldLevelSecurityState>(reducers);