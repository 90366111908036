import { createStore, applyMiddleware, compose, combineReducers, StoreEnhancer, Store, StoreEnhancerStoreCreator, ReducersMapObject, AnyAction } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import * as StoreModule from './store';
import { ApplicationState, reducers, ReducerTypes } from './store';
import { History } from 'history';
import { ApiMiddleware } from './scripts/middleware/ApiMiddleware';
import { ArmThunkMiddleware } from '@scripts/middleware/ArmThunkMiddleware'
import { logger } from './scripts/middleware/Log';

export default function configureStore(history: History, initialState?: ApplicationState) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    // If devTools is installed, connect to it
    const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__ as () => StoreEnhancer;
    //const createStoreWithMiddleware = compose(
    //    applyMiddleware(thunk, routerMiddleware(history)),
    //    devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next
    //)(createStore);
    
    // Combine all reducers and instantiate the app-wide store instance
    // TODO: See https://github.com/reduxjs/redux/issues/2709
    // Actions with payloads do not map to Action<any>. If the payload is made optional, this goes away, but then the definition of the IDataActiona and IApiAction do not require them.
    // This is actually corrected with AnyAction, which allows one extraProps field. Thus, for IApiAction, url, response, etc., would all need to go in data.
    // Perhaps just rename data as payload, specify a parameters member, then for IApiAction, add an object within payload for the api called "api".
    const allReducers = buildRootReducer(reducers, history);
    //const store = createStoreWithMiddleware(allReducers, initialState) as Store<ApplicationState>;
    //const store = createStore(allReducers, initialState as any, compose(applyMiddleware(thunk, asyncDispatchMiddleware, routerMiddleware(history)), devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next));
    const store = createStore(allReducers, initialState as any, compose(applyMiddleware(ArmThunkMiddleware, thunk, ApiMiddleware, routerMiddleware(history), logger), devToolsExtension ? devToolsExtension() : <S>(next: StoreEnhancerStoreCreator<S>) => next));

    return store;
}


// Changed function signature to use actual (inferred) type from declared reducers object in store.
// This is because AnyAction, which should allow any other actions with properties other than "type", does not compile and errors out with
// some language that certain actions cannot be assigned to AnyAction because the members on those actions do not exist on AnyAction,
// even though AnyAction includes an indexed property that is intended to overcome that.
// So, using the actual type from the file avoids the need to use AnyAction here.
// See also an issue others have with this: https://github.com/reduxjs/redux/issues/2709
//function buildRootReducer(allReducers: ReducersMapObject<ApplicationState, AnyAction>, history: History) {
function buildRootReducer(allReducers: ReducerTypes, history: History) {
    return combineReducers<ApplicationState>(Object.assign({}, allReducers, { router: connectRouter(history) }));
}
