import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as PhysicianMaintenanceUI from './ui/PhysicianMaintenanceUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IPhysicianMaintenanceState = ICrudComponentState<MCPhysicianType, PhysicianMaintenanceUI.IPhysicianMaintenanceUIState>;

export const actionCreators = createCrudComponentActions<PhysicianMaintenanceUI.ActionCreators>(PhysicianMaintenanceUI.actionCreators);
export type IPhysicianMaintenanceActions = typeof actionCreators;
export type IPhysicianMaintenanceProps = ICrudComponentActionProps<IPhysicianMaintenanceActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiPhysician2, PhysicianMaintenanceUI.reducer);
export const reducer = combineReducers<IPhysicianMaintenanceState>(reducers);


export const validationCallback: ValidationCallback<MCPhysicianType> = (crud: any): crud is MCPhysicianType => {
    let testCrud: MCPhysicianType = crud as MCPhysicianType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCPhysicianType");
    if (validShape) {

        if (testCrud.PhysicianMaintenanceInfo?.PayerList as any === "") {
            testCrud.PhysicianMaintenanceInfo.PayerList = { Payer: new Array<MCMR_Payer>() };
        }
        if (testCrud.PhysicianMaintenanceInfo.PayerList &&
            !Array.isArray(testCrud.PhysicianMaintenanceInfo.PayerList.Payer)) {
            let arr = new Array<MCMR_Payer>();
            arr.push(JSON.parse(JSON.stringify(testCrud.PhysicianMaintenanceInfo.PayerList.Payer)));
            testCrud.PhysicianMaintenanceInfo.PayerList.Payer = arr;
        }

        if (testCrud.PhysicianMaintenanceInfo?.FacilityList as any === "") {
            testCrud.PhysicianMaintenanceInfo.FacilityList = { Facility: new Array<MCMR_Facility>() };
        }
        if (testCrud.PhysicianMaintenanceInfo.FacilityList &&
            !Array.isArray(testCrud.PhysicianMaintenanceInfo.FacilityList.Facility)) {
            let arr = new Array<MCMR_Facility>();
            arr.push(JSON.parse(JSON.stringify(testCrud.PhysicianMaintenanceInfo.FacilityList.Facility)));
            testCrud.PhysicianMaintenanceInfo.FacilityList.Facility = arr;
        }

        if (testCrud.PhysicianMaintenanceInfo?.LOBList as any === "") {
            testCrud.PhysicianMaintenanceInfo.LOBList = { LOB: new Array<MCMR_LOB>() };
        }
        if (testCrud.PhysicianMaintenanceInfo.LOBList &&
            !Array.isArray(testCrud.PhysicianMaintenanceInfo.LOBList.LOB)) {
            let arr = new Array<MCMR_LOB>();
            arr.push(JSON.parse(JSON.stringify(testCrud.PhysicianMaintenanceInfo.LOBList.LOB)));
            testCrud.PhysicianMaintenanceInfo.LOBList.LOB = arr;
        }

        if (testCrud.PhysicianMaintenanceInfo?.StateList as any === "") {
            testCrud.PhysicianMaintenanceInfo.StateList = { State: new Array<MCMR_State>() };
        }
        if (testCrud.PhysicianMaintenanceInfo.StateList &&
            !Array.isArray(testCrud.PhysicianMaintenanceInfo.StateList.State)) {
            let arr = new Array<MCMR_State>();
            arr.push(JSON.parse(JSON.stringify(testCrud.PhysicianMaintenanceInfo.StateList.State)));
            testCrud.PhysicianMaintenanceInfo.StateList.State = arr;
        }

        if (testCrud.PhysicianMaintenanceInfo?.IDTypeList as any === "") {
            testCrud.PhysicianMaintenanceInfo.IDTypeList = { IDType: new Array<MCMR_IDType>() };
        }
        if (testCrud.PhysicianMaintenanceInfo.IDTypeList &&
            !Array.isArray(testCrud.PhysicianMaintenanceInfo.IDTypeList.IDType)) {
            let arr = new Array<MCMR_IDType>();
            arr.push(JSON.parse(JSON.stringify(testCrud.PhysicianMaintenanceInfo.IDTypeList.IDType)));
            testCrud.PhysicianMaintenanceInfo.IDTypeList.IDType = arr;
        }

        if (testCrud.PhysicianMaintenanceInfo?.SpecialtyCodeList as any === "") {
            testCrud.PhysicianMaintenanceInfo.SpecialtyCodeList = { SpecialtyCode: new Array<MCMR_SpecialtyCode>() };
        }
        if (testCrud.PhysicianMaintenanceInfo.SpecialtyCodeList &&
            !Array.isArray(testCrud.PhysicianMaintenanceInfo.SpecialtyCodeList.SpecialtyCode)) {
            let arr = new Array<MCMR_SpecialtyCode>();
            arr.push(JSON.parse(JSON.stringify(testCrud.PhysicianMaintenanceInfo.SpecialtyCodeList.SpecialtyCode)));
            testCrud.PhysicianMaintenanceInfo.SpecialtyCodeList.SpecialtyCode = arr;
        }

        if (testCrud.PhysicianMaintenanceInfo?.Physicians as any === "") {
            testCrud.PhysicianMaintenanceInfo.Physicians = { Physician: new Array<MCMR_Physician>() };
        }
        if (testCrud.PhysicianMaintenanceInfo.Physicians &&
            !Array.isArray(testCrud.PhysicianMaintenanceInfo.Physicians.Physician)) {
            let arr = new Array<MCMR_Physician>();
            arr.push(JSON.parse(JSON.stringify(testCrud.PhysicianMaintenanceInfo.Physicians.Physician)));
            testCrud.PhysicianMaintenanceInfo.Physicians.Physician = arr;
        }
    }

    return validShape;
}