export class TableViewModelBuilder {

    ConvertMetadataToViewModel(tableMetadata: TableMetadata) {

        // identify the number of table rows represented in the table metadata
        const rowCount = tableMetadata.Items.reduce(function (prev, current) {
            return (prev.RowNumber > current.RowNumber) ? prev : current
        }).RowNumber;

        // identify the number of table cells represented in the table metadata
        const cellCount = tableMetadata.Items.reduce(function (prev, current) {
            return (prev.ColumnNumber > current.ColumnNumber) ? prev : current
        }).ColumnNumber;

        // init loop vars
        let currentRowItems: TableMetadataItem[] = [];
        let tableRowView: TableRowViewModel[] = [];
        let vmRow: TableRowViewModel;
        let isOneColumnRow = false;
        let isLastColumnCell = false;
        let currentColumnNumber = 0;
        let nextColumnNumber = 0;
        let columnNumberDiff = 0;
        let isLastRow = false;
        let nextRowNumber = 0;
        let rowNumberDiff = 0;
        let colSpan = 1;
        let rowSpan = 1;

        // for each row represented in the table builder metadata
        for (let i = 1; i <= rowCount; i++) {

            // reinit currentRow loop var
            vmRow = { RowNumber: i, Cells: [] }

            // get all table cells for current row
            currentRowItems = tableMetadata.Items.filter(x => x.RowNumber == i);

            // for each table metadata item for the current row
            currentRowItems.forEach((x, j, currentRowItems) => {

                // determine if row has only column
                isOneColumnRow = currentRowItems.length === 1;

                // determine if is last column cell
                isLastColumnCell = (currentRowItems.length === (j + 1));

                // eval for colspan
                colSpan = 1;
                if (isOneColumnRow) {
                    colSpan = (((cellCount - 1) * 4) + 1);
                } else if (!isLastColumnCell) {
                    currentColumnNumber = currentRowItems[j].ColumnNumber;
                    nextColumnNumber = currentRowItems[j + 1].ColumnNumber;
                    columnNumberDiff = (nextColumnNumber - currentColumnNumber);
                    if (columnNumberDiff > 1) {
                        colSpan = ((columnNumberDiff * 4) + 1);
                    }
                }

                // eval for rowspan
                rowSpan = 1;
                isLastRow = (i == Math.max(...currentRowItems.map(y => y.RowNumber)));
                if (!isLastRow) {
                    // find next row number
                    let nextRowFound: TableMetadataItem[] = [];
                    while (!nextRowFound.length) {
                        nextRowFound = tableMetadata.Items.filter(x => x.RowNumber == (i + 1));
                        nextRowNumber = i + 1;
                    }
                    rowSpan = nextRowNumber - i;
                }

                // push item to vm row as vm cell
                vmRow.Cells.push({
                    Sequence: x.ColumnNumber,
                    FieldName: x.FieldName,
                    FieldValue: x.FieldValue,
                    ColSpan: colSpan,
                    RowSpan: rowSpan,
                    Nowrap: x.Nowrap,
                    // add fieldtable if one exists in metadata
                    FieldTable: (x.FieldTable ? this.BuildFieldTable(x) : undefined),
                    OnClick: x.OnClick,
                    ClassNames: x.ClassNames,
                });
            });

            // add additional empty/filler cells to row as needed to accomodate colspan
            while (vmRow.Cells.length < cellCount) {
                vmRow.Cells.push({
                    Sequence: vmRow.Cells.length + 1,
                    FieldName: '',
                    FieldValue: '',
                    ColSpan: 1,
                    RowSpan: 1,
                    Nowrap: false,
                    FieldTable: undefined,
                    OnClick: undefined,
                    ClassNames: undefined
                });
            }

            tableRowView.push(vmRow);
        }
        return tableRowView;
    }

    BuildFieldTable(tableMetadataItem: TableMetadataItem) {
        // validate parameter
        if (!tableMetadataItem || !tableMetadataItem.FieldTable) return;

        // ensure that fieldtable is ordered by RowNumber
        tableMetadataItem.FieldTable.sort((a, b) => a.RowNumber - b.RowNumber);

        // init loop vars
        let tableRowViewModels: TableRowViewModel[] = [];
        let tableRowViewModel: TableRowViewModel | undefined;

        // for each cell fieldtable item
        tableMetadataItem.FieldTable.forEach(x => {

            // get row from lookup
            tableRowViewModel = tableRowViewModels.find(y => y.RowNumber === x.RowNumber);

            // if row for current row number is not in lookup
            if (!tableRowViewModel) {
                // create new row for current row number
                tableRowViewModel = { RowNumber: x.RowNumber, Cells: [] }

                // push the row to the collection for reference
                tableRowViewModels.push(tableRowViewModel);
            }

            // transform cells
            tableRowViewModel.Cells.push({
                Sequence: x.ColumnNumber,
                FieldName: x.FieldName,
                FieldValue: x.FieldValue,
                ColSpan: 1,
                RowSpan: 1,
                Nowrap: x.Nowrap,
                FieldTable: undefined,
                OnClick: undefined,
                ClassNames: undefined,
            })
        });

        // ensure subtable row cells are sorted by sequence
        tableRowViewModels.forEach(x => {
            x.Cells.sort((a, b) => a.Sequence - b.Sequence);
        });

        return tableRowViewModels;
    }
}
