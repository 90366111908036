import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';

export interface IOptionComponentProps {
    value: string;
    text: string;
    deleted?: string;
    selected?: string;
    fav?: boolean;
};

export interface IOptionGroupComponentProps {
    label: string;
    items: IOptionComponentProps[];
};

export interface ISelectGroupComponentProps {
    title: string;
    records: Array<{ label: string, items: Array<any> }>;
    optionGroups: IOptionGroupComponentProps;
    size?: number;
    width?: string;
    height?: string;
    disabled?: boolean;
    onSelect?: React.ChangeEventHandler<HTMLSelectElement>;
    className?: string;
    selectedValue?: string;
    selectedMultiValue?: string[];
    label?: string;
    multiple?: string;
    fontSize?: any;
};

export interface ISelectGroupComponentState {
    disabled: boolean;
};

export interface ISelectWrapperProps {
    widthValue?: string;
    heightValue?: string;
    fontSize?: any;
};

export interface ISelectWrapperDivProps {
    className?: string;
};

const SelectWrapperDiv = styled.div`
    flex: 1 1 auto;
    ${(props: ISelectWrapperDivProps) => props.className && `className: ${props.className}`};
`;

export const SelectWrapper = styled.select`
    ${(props: ISelectWrapperProps) => props.widthValue && `width: ${props.widthValue}`};
    ${(props: ISelectWrapperProps) => props.heightValue && `height: ${props.heightValue}`};

    overflow-y: auto;
    overflow-x: auto;

    .optgroup-class {
        color: ${Colors.grey100};
          ${Typography.ARMFontFamily};
          ${Typography.mediumLarge};
          ${(props: ISelectWrapperProps) => props.fontSize && `${props.fontSize}`};
          ${Typography.defaultLineHeight};
          margin: 0.1rem;
    }

    .option-class{
          color: ${Colors.grey100};
          ${Typography.ARMFontFamily};
          ${(props: ISelectWrapperProps) => props.fontSize && `${props.fontSize}`};
          ${Typography.defaultLineHeight};
          margin: 0.1rem;
    }

    .option-fav {
        color: blue !important;
    }
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 0.3rem;
  color: ${Colors.grey100};
  ${Typography.ARMFontFamily};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  ${Typography.bold};
  letter-spacing: 0.5px;
  text-transform: uppercase;
`;

export class SelectGroupComponent extends React.Component<ISelectGroupComponentProps, ISelectGroupComponentState>
{
    static defaultProps: ISelectGroupComponentProps = {
        title: "SelectGroupComponent",
        records: [{label: '', items:[]}],
        optionGroups: {
            label: "value",
            items: [{
                value: "value",
                text: "text",
                selected: "selected",
                fav: false,
            }]
        },
        size: 10,
        disabled: false,
        label: '',
        fontSize: Typography.large
    };

    constructor(props: ISelectGroupComponentProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
    }

    isMultiple(): boolean {
        if (this.props.multiple && this.props.multiple.length > 0 && this.props.multiple !== "false")
            return true;
        return false;
    }

    renderOptionGroup() {       
        if (this.props.records && this.props.records.length > 0) {
            let optionGroups = this.props.records.map((record) => {
                let hasLabel = record.label != '';
                let options = record.items.map((itm) => {
                    if(hasLabel)
                        if (!(itm.deleted && (itm.deleted == "1"))) {
                            return <option className={'option-class ' + (itm.fav == true ? 'option-fav' : '') } key={itm.value} value={itm.value}>{itm.label}</option>
                        }
                    return <option className='option-class' key={itm.value} value={itm.value}>{itm.label}</option>
                });
                if(hasLabel)
                    return (<optgroup key={record.label + "_optkey"} className='optgroup-class' label={record.label}>{options}</optgroup>);
                return options
            });

            return (
                <React.Fragment>
                    {optionGroups}
                </React.Fragment>
            );
        }
    }

    render() {
        return (
            <SelectWrapperDiv className={this.props.className} >
                {this.props.label && this.props.title ? <InputLabel htmlFor={this.props.title}>{this.props.label}</InputLabel> : null}
                <SelectWrapper
                    id={this.props.title}
                    name={this.props.title}
                    widthValue={this.props.width}
                    heightValue={this.props.height}
                    {...(this.isMultiple() ? { multiple: true } : null)}
                    size={this.props.size}
                    fontSize={this.props.fontSize}
                    {...(this.isMultiple() ? { value: this.props.selectedMultiValue } : { value: this.props.selectedValue })}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { if (this.props.onSelect) this.props.onSelect(e) }}
                >
                    {this.renderOptionGroup()}
                </SelectWrapper>
            </SelectWrapperDiv>
        );
    }
};