import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as FileUploadUI from './ui/FileUploadUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IFileUploadState = ICrudComponentState<MCClaimNoteType, FileUploadUI.IFileUploadUIState>;

export const actionCreators = createCrudComponentActions<FileUploadUI.ActionCreators>(FileUploadUI.actionCreators);
export type IFileUploadActions = typeof actionCreators;
export type IFileUploadActionProps = ICrudComponentActionProps<IFileUploadActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimNotes, FileUploadUI.reducer);
export const reducer = combineReducers<IFileUploadState>(reducers);
