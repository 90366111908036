import moment from "moment";

export function FormatCurrency(DollarAmount:any)
{
    DollarAmount = DollarAmount.toString();

    var pos = DollarAmount.lastIndexOf(".");

    if(pos == -1)
    {
        DollarAmount += ".00";
    }
    else
    {
        if(pos == DollarAmount.length - 2)
        {
            DollarAmount += "0";
        }
        DollarAmount = DollarAmount.substr(0,pos+3);
    }

    var pos = DollarAmount.lastIndexOf(".");
        
    while (pos > 3)
    {
        var right = DollarAmount.substr(pos - 3);
        var left = DollarAmount.substr(0, pos - 3);
        DollarAmount = left + "," + right;
        pos = pos - 3;
    }

    return("$" + DollarAmount);
}

/**
 * Returns a date as string using standard ARM format "MM/DD/YYYY"
 * @param date - input date, which can be a Date object or string value.
 */
export function FormatDateAsString(date: string | Date): string {
    const ourEmptyDate = "00/00/0000";
    let dateObject: Date | undefined;
    let formattedDateString = "";

    if ((typeof date === 'string' || date instanceof String)) {
        if (date?.length < 6) {
            return ourEmptyDate;
        }

        let tmp = moment(date);
        //NOTE: moment.js is deprecated, and the newer, recommended library is Luxon. See https://momentjs.com/docs/ 
        //  and https://momentjs.com/docs/#/-project-status/recommendations/ and https://moment.github.io/luxon/#/
        dateObject = tmp.isValid() ? moment(date).toDate() : undefined;
    }
    else {
        dateObject = date;
    }

    //must +1 to .getMonth() because it returns zero-indexed value
    formattedDateString = dateObject ? `${dateObject.getMonth()+1}/${dateObject.getDate()}/${dateObject.getFullYear()}` : ourEmptyDate;

    return formattedDateString;
}
