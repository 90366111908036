import * as React from 'react';
import styled from 'styled-components';

const InlineInstruction = styled.div`   
    width:98%;
    margin: auto;
    border: 1px solid #0f0f59;
    background-color: #fff5db;
    padding: 3px;
`;

export interface IInlineInstructionProps {
    instructions: string[];
    width?: string;
}

export class InlineInstructions extends React.Component<IInlineInstructionProps> {
    static defaultProps: IInlineInstructionProps= {
        instructions: [],
        width: "98%",
    };

    constructor(props: IInlineInstructionProps) {
        super(props);
    }

    render() {
        return(
            <InlineInstruction>
                {this.props.instructions.map((item) => {
                    return (
                        <React.Fragment key={item}>
                            {item}
                            <br/>
                        </React.Fragment>);
                })}
            </InlineInstruction>
        );
    }
}