import { Action, Reducer } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface IMCAPayerStatusRulesUIState {
    payerType: string;
    assignmentUser: MCAPSR_UserLookupList[];
    statusRules: MCAPSR_StatusRulesList[];
    isLoading: boolean;
    IndexId: number;
    Id: number;
    Payer: string;
    PayerName: string;
    ExpiresOn: string;
    Code: string;
    CodeType: string;
    Departments: [];
    Users: any[];
    Facilities: [];
    FacilityLookup: string;
    Facility: string;
    StatusRules: any[];
    StatusRulesModified: [];
    StatusCode: string;
    Category277Code: string;
    RemitCode: string;
    ReconLevelCode: string;
    DDEErrorCode: string;
    Department: string;

}

const emptyItem: MCA_PayerStatusRule = {
    "@ID": '0',
    "@PayerType": '0',
    "@StatusCode": '0',
    "@Category277Code": '0',
    "@ReconLevel": '0',
    "@RemitStatusCode": '0',
    "@DDEErrorCode": '0',
    "@UserList": [],
    "@Department": '',
    '@ExpiresOn': '',
    "@Facility": '0',
    "@FacilityLookup": '0'
};

interface IMCAPayerStatusRuleUIFieldUpdate {
    value: string;
}

interface IMCAPayerStatusRuleUIFieldDelete {
    data: [];
}

interface IMCAPayerStatusRuleUIUserUpdate {
    id: string;
    alphaSplit: string;
}

export const defaultState: IMCAPayerStatusRulesUIState = {
    payerType: '',
    assignmentUser: [],
    statusRules: [],
    isLoading: false,
    IndexId: 0,
    Id: 0,
    Payer: "0",
    PayerName: "",
    ExpiresOn: "",
    Code: "",
    CodeType: "",
    Departments: [],
    Users: [],
    Facilities: [],
    FacilityLookup: "",
    Facility: "0",
    StatusRules: [],
    StatusRulesModified: [],
    StatusCode: "0",
    Category277Code: "0",
    RemitCode: "0",
    ReconLevelCode: "0",
    DDEErrorCode: "0",
    Department: "0"
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface IMCAPayerStatusRulesUIData {
    id: string;
    text: string;
};

interface IMCAPayerStatusRulesUI {
    value: MCAPSR_StatusRulesList[];
}

export interface IUpdateMCAPayerStatusRuleField extends ICrudActionData<MCAPayerStatusRulesType, IMCAPayerStatusRuleUIFieldUpdate> { }
export interface IUpdateMCAPayerStatusRuleUserField extends ICrudActionData<MCAPayerStatusRulesType, IMCAPayerStatusRuleUIUserUpdate> { }
export interface IUpdateMCAPayerStatusRuleArrayField extends ICrudActionData<MCAPayerStatusRulesType, IMCAPayerStatusRulesUI> { }
export interface IUpdateMCAPayerStatusRuleFieldDelete extends ICrudActionData<MCAPayerStatusRulesType, IMCAPayerStatusRuleUIFieldDelete> { }

export const actionCreators = {
    updatePayer: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_MCAPSR_PAYER', selectInfo),
    updateStatusCode: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_MCAPSR_STATUS_CODE', selectInfo),
    updateCategory277Code: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_MCAPSR_CATEGORY_277_CODE', selectInfo),
    updateReconLevel: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_MCAPSR_RECON_LEVEL', selectInfo),
    updateRemitCode: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_MCAPSR_REMIT_CODE', selectInfo),
    updateFacilityLookup: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_MCAPSR_FACILITY_LOOKUP', selectInfo),
    updateFacility: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_MCAPSR_FACILITY', selectInfo),
    updateId: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_ID', selectInfo),
    updateIndexId: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_INDEX_ID', selectInfo),
    updateDDEErrorCode: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_MCAPSR_DDE_ERROR_CODE', selectInfo),
    updateUserCheckList: (selectInfo: IUpdateMCAPayerStatusRuleUserField) => createDataAction('CHECK_MCAPSR_USER', selectInfo),
    updateUserUnCheckList: (selectInfo: IUpdateMCAPayerStatusRuleUserField) => createDataAction('UNCHECK_MCAPSR_USER', selectInfo),
    updateAlphaSplit: (selectInfo: IUpdateMCAPayerStatusRuleUserField) => createDataAction('UPDATE_ALPHA_SPLIT', selectInfo),
    updateDepartment: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_DEPARTMENT', selectInfo),
    updateExpiresOn: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_EXPIRES_ON', selectInfo),
    addpayerStatusRules: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('ADD_PAYER_STATUS_RULES', selectInfo),
    updatePayerStatusRules: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('UPDATE_PAYER_STATUS_RULES', selectInfo),
    initialPayerStatusRules: (selectInfo: IUpdateMCAPayerStatusRuleArrayField) => createDataAction('INITIAL_PAYER_STATUS_RULES', selectInfo),
    clearCurrentSelectedRules: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('CLEAR_CURRENT_STATUS_RULES', selectInfo),
    clearUserList: (selectInfo: IUpdateMCAPayerStatusRuleUserField) => createDataAction('CLEAR_USER_LIST', selectInfo),
    clearStatusRules: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('CLEAR_STATUS_RULES', selectInfo),
    deletePayerStatusRules: (selectInfo: IUpdateMCAPayerStatusRuleFieldDelete) => createDataAction('DELETE_PAYER_STATUS_RULES', selectInfo),
    clearAll: (selectInfo: IUpdateMCAPayerStatusRuleField) => createDataAction('CLEAR_ALL', selectInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];


function upsertStatusRule(array: any, item: any) {

    let newArray = array !== undefined ? array : [];
    if (newArray.length > 0) {
        let oldItem: any = {};
        oldItem = newArray.find((obj: any) => obj['@IndexId'] === item['@IndexId']);
        if (!oldItem) {
            let itemObject: any = {};
            itemObject = item;
            newArray.splice(array !== undefined ? array.length : 0, 0, JSON.parse(JSON.stringify(itemObject)));
        } else {
            oldItem['@IndexId'] = item['@IndexId'];
            oldItem['@ID'] = item['@ID'];
            oldItem['@Code'] = item['@Code'];
            oldItem['@CodeType'] = item['@CodeType'];
            oldItem['@Expires'] = item['@Expires'];
            oldItem['@Payer'] = item['@Payer'];
            oldItem['Users'] = item['Users'];
            oldItem['Departments'] = item['Departments'];
            oldItem['Facilities'] = item['Facilities'];
        }
    }
    else {
        newArray.splice(array !== undefined ? array.length : 0, 0, JSON.parse(JSON.stringify(item)));
    }

    return newArray;
}

function upsertStatusRuleDelete(array: MCAPSR_StatusRulesList[] | undefined, item: MCAPSR_StatusRulesList) {

    let newArray = array !== undefined ? array : [];
    if (newArray.length > 0) {
        var oldItem = newArray.find(obj => obj['@ID'] === item['@ID']);
        if (!oldItem) {
            newArray.splice(array !== undefined ? array.length : 0, 0, JSON.parse(JSON.stringify(item)));
        } else {
            oldItem['@ID'] = item['@ID'];
            oldItem['@Code'] = item['@Code'];
            oldItem['@CodeType'] = item['@CodeType'];
            oldItem['@Expires'] = item['@Expires'];
            oldItem['@Payer'] = item['@Payer'];
            oldItem['Users'] = item['Users'];
            oldItem['Departments'] = item['Departments'];
            oldItem['Facilities'] = item['Facilities'];
        }
    }
    else {
        newArray.splice(array !== undefined ? array.length : 0, 0, JSON.parse(JSON.stringify(item)));
    }

    return newArray;
}

function upsertStatusRuleUpdate(array: any, item: any) {

    let newArray = array !== undefined ? array : [];
    if (newArray.length > 0) {
        var oldItem: any = {};
        oldItem = newArray.find((obj: any) => obj['@IndexId'] === item['@IndexId']);
        if (!oldItem) {
            newArray.splice(array !== undefined ? array.length : 0, 0, JSON.parse(JSON.stringify(item)));
        } else {
            oldItem['@ID'] = item['@ID'];
            oldItem['@Code'] = item['@Code'];
            oldItem['@CodeType'] = item['@CodeType'];
            oldItem['@Expires'] = item['@Expires'];
            oldItem['@Payer'] = item['@Payer'];
            oldItem['Users'] = item['Users'];
            oldItem['Departments'] = item['Departments'];
            oldItem['Facilities'] = item['Facilities'];
        }
    }
    else {
        newArray.splice(array !== undefined ? array.length : 0, 0, JSON.parse(JSON.stringify(item)));
    }

    return newArray;
}

function removeStatusRule(array: MCAPSR_StatusRulesList[] | undefined, item: MCAPSR_StatusRulesList) {
    let newArray = array != undefined ? array.slice() : [];
    var oldItem = newArray.filter(obj => obj['@CurrentStatusRuleId'] !== item['@CurrentStatusRuleId']);
    if (!oldItem) {
        console.log('attempt to remove an invalid entry ' + item['@ID']);
    }
    return oldItem;
}

function checkUsers(stateArray: any, selectedArray: any) {

    let newArray: any = [];
    stateArray.map((obj: any) => {
        if (obj["@ID"] !== "#") {
            let userDetails = selectedArray.find((ob: any) => ob["@ID"] === "#" ? ob["@UserID"] === obj["@ID"] : ob["@ID"] === obj["@ID"]);
            if (userDetails !== undefined) {
                if (obj["@IsChecked"] === true) {
                    newArray.push({ "@UserID": obj["@ID"], "@ID": obj["@ID"], "@AlphaSplit": obj["@AlphaSplit"], "@IsChecked": true });
                }
                else {
                    if (obj["@ID"] !== "#")
                        newArray.push({ "@UserID": obj["@ID"], "@ID": obj["@ID"], "@AlphaSplit": obj["@AlphaSplit"], "@IsChecked": false, "@Delete": true });
                }
            }
            else {
                newArray.push({ "@UserID": obj["@ID"], "@ID": obj["@ID"], "@AlphaSplit": obj["@AlphaSplit"], "@IsChecked": true });
            }
        }
        else {
            let userDetails = selectedArray.find((ob: any) => ob["@UserID"] === obj["@UserID"]);

            if (userDetails !== undefined) {
                if (obj["@IsChecked"] === true) {
                    newArray.push({ "@UserID": obj["@ID"], "@ID": obj["@ID"], "@AlphaSplit": obj["@AlphaSplit"], "@IsChecked": true });
                }
                else {
                    if (obj["@ID"] !== "#")
                        newArray.push({ "@UserID": obj["@ID"], "@ID": obj["@ID"], "@AlphaSplit": obj["@AlphaSplit"], "@IsChecked": false, "@Delete": true });
                }
            }
            else {
                newArray.push({ "@UserID": obj["@ID"], "@ID": obj["@ID"], "@AlphaSplit": obj["@AlphaSplit"], "@IsChecked": true });
            }
        }

    })

    let j = 0;
    newArray?.map((itemDetail: any) => {
        itemDetail["@IndexId"] = j + 1;
        j++;
    })
    return newArray;
}



// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IMCAPayerStatusRulesUIState, KnownActions> = (state: IMCAPayerStatusRulesUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'UPDATE_MCAPSR_PAYER':
                if (state.Payer !== action.data.uiData.value) {
                    let payerDetail = action.data.masterCrud?.ClaimAssignmentInfo?.PayerList?.Payer?.find(payer => payer["@ID"] === action.data.uiData.value);
                    return {
                        ...state,
                        Payer: action.data.uiData.value,
                        PayerName: payerDetail !== undefined ? payerDetail["@Name"] : ""
                    }
                }
                break;
            case 'UPDATE_ID':
                if (state.Id !== parseInt(action.data.uiData.value)) {
                    return {
                        ...state,
                        Id: parseInt(action.data.uiData.value),
                    }
                }
                break;
            case 'UPDATE_INDEX_ID':
                if (state.IndexId !== parseInt(action.data.uiData.value)) {
                    return {
                        ...state,
                        IndexId: parseInt(action.data.uiData.value),
                    }
                }
                break;
            case 'UPDATE_MCAPSR_STATUS_CODE':
                if (state.StatusCode !== action.data.uiData.value) {
                    return {
                        ...state,
                        StatusCode: action.data.uiData.value,
                        Category277Code: "0",
                        RemitCode: "0",
                        ReconLevelCode: "0",
                        DDEErrorCode: "0"
                    }
                }
                break;
            case 'UPDATE_MCAPSR_CATEGORY_277_CODE':
                if (state.Category277Code !== action.data.uiData.value) {
                    return {
                        ...state,
                        StatusCode: "0",
                        Category277Code: action.data.uiData.value,
                        RemitCode: "0",
                        ReconLevelCode: "0",
                        DDEErrorCode: "0"
                    }
                }
                break;
            case 'UPDATE_MCAPSR_RECON_LEVEL':
                if (state.ReconLevelCode !== action.data.uiData.value) {
                    return {
                        ...state,
                        StatusCode: "0",
                        Category277Code: "0",
                        RemitCode: "0",
                        ReconLevelCode: action.data.uiData.value,
                        DDEErrorCode: "0"
                    }
                }
                break;
            case 'UPDATE_MCAPSR_REMIT_CODE':
                if (state.RemitCode !== action.data.uiData.value) {
                    return {
                        ...state,
                        StatusCode: "0",
                        Category277Code: "0",
                        RemitCode: action.data.uiData.value,
                        ReconLevelCode: "0",
                        DDEErrorCode: "0"
                    }
                }
                break;
            case 'UPDATE_MCAPSR_DDE_ERROR_CODE':
                if (state.DDEErrorCode !== action.data.uiData.value) {
                    return {
                        ...state,
                        StatusCode: "0",
                        Category277Code: "0",
                        RemitCode: "0",
                        ReconLevelCode: "0",
                        DDEErrorCode: action.data.uiData.value
                    }
                }
                break;
            case 'CHECK_MCAPSR_USER':

                if (action.data.masterCrud) {
                    var data = action.data.masterCrud.ClaimAssignmentInfo.UserList.User.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    var dataIsAlreadyPresent = state.Users != undefined ? state.Users.find(ob => { return ob["@ID"] === action.data.uiData.id }) : undefined;
                    let newAssignmentUserData: any = {};
                    let newArray = [];

                    if (data != undefined) {
                        if (state.Users != undefined && state.Users.length > 0) {
                            if (dataIsAlreadyPresent === undefined) {
                                newAssignmentUserData = data;
                                newAssignmentUserData["@AlphaSplit"] = action.data.uiData.alphaSplit != undefined ? "" : action.data.uiData.alphaSplit;
                                newAssignmentUserData["@IsChecked"] = true;
                                newArray.push(newAssignmentUserData);
                            }
                            for (let i = 0; i < state.Users.length; i++) {
                                if (state.Users[i]["@ID"] !== action.data.uiData.id) {
                                    newArray.push(state.Users[i]);
                                }
                                else {
                                    state.Users[i]["@AlphaSplit"] = action.data.uiData.alphaSplit != undefined ? "" : action.data.uiData.alphaSplit;
                                    state.Users[i]["@IsChecked"] = true;
                                    newArray.push(state.Users[i]);
                                }
                            }

                        }
                        else {
                            newAssignmentUserData = data;
                            newAssignmentUserData["@AlphaSplit"] = action.data.uiData.alphaSplit != undefined ? "" : action.data.uiData.alphaSplit;
                            newAssignmentUserData["@IsChecked"] = true;
                            newArray.push(newAssignmentUserData);
                        }

                        let j = 0;
                        newArray?.map((itemDetail: any) => {
                            itemDetail["@IndexId"] = j + 1;
                            j++;
                        })
                    }
                    return {
                        ...state,
                        Users: newArray
                    }
                }
                break;
            case 'UNCHECK_MCAPSR_USER':

                if (action.data.masterCrud) {
                    let newArray: any = [];
                    if (state.Users != undefined) {
                        for (let i = 0; i < state.Users.length; i++) {
                            if (state.Users[i]["@ID"] !== action.data.uiData.id) {
                                newArray.push(state.Users[i]);
                            }
                            else {
                                state.Users[i]["@AlphaSplit"] = "";
                                state.Users[i]["@IsChecked"] = false;
                                newArray.push(state.Users[i]);
                            }
                        }
                        let j = 0;
                        newArray?.map((itemDetail: any) => {
                            itemDetail["@IndexId"] = j + 1;
                            j++;
                        })

                    }

                    return {
                        ...state,
                        Users: newArray
                    }
                }
                break;
            case 'UPDATE_ALPHA_SPLIT':
                if (action.data.masterCrud) {

                    var data = action.data.masterCrud.ClaimAssignmentInfo.UserList.User.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    var dataIsAlreadyPresent = state.Users != undefined ? state.Users.find(ob => { return ob["@ID"] === action.data.uiData.id }) : undefined;
                    let newAssignmentUserData: any = {};
                    let newArray = [];

                    if (data != undefined) {
                        if (state.Users != undefined && state.Users.length > 0) {

                            for (let i = 0; i < state.Users.length; i++) {
                                if (state.Users[i]["@ID"] !== action.data.uiData.id) {
                                    newArray.push(state.Users[i]);
                                }
                                else {
                                    state.Users[i]["@AlphaSplit"] = action.data.uiData.alphaSplit != undefined ? action.data.uiData.alphaSplit : "";

                                    newArray.push(state.Users[i]);
                                }
                            }

                        }
                        else {
                            newAssignmentUserData = data;
                            newAssignmentUserData["@AlphaSplit"] = action.data.uiData.alphaSplit != undefined ? "" : action.data.uiData.alphaSplit;

                            newArray.push(newAssignmentUserData);
                        }
                    }
                    let j = 0;
                    newArray?.map((itemDetail: any) => {
                        itemDetail["@IndexId"] = j + 1;
                        j++;
                    })
                    return {
                        ...state,
                        Users: newArray
                    }
                }
                break;
            case 'UPDATE_DEPARTMENT':
                if (state.Department !== action.data.uiData.value) {

                    return {
                        ...state,
                        Department: action.data.uiData.value
                    }
                }
                break;
            case 'UPDATE_EXPIRES_ON':
                if (state.ExpiresOn !== action.data.uiData.value) {
                    return {
                        ...state,
                        ExpiresOn: action.data.uiData.value
                    }
                }
                break;
            case 'ADD_PAYER_STATUS_RULES':
                if (action.data.uiData.value === "add") {
                    let departmentList: any = { "@DepartmentID": state.Department, "@ID": state.Department };
                    let facilityList: any = { "@FacilityID": state.Facility, "@ID": state.Facility };
                    let UserList: any = [];
                    state.Users?.map((item) => {
                        if (item["@IsChecked"])
                            UserList.push({ "@UserID": item["@ID"], "@ID": "#", "@AlphaSplit": item["@AlphaSplit"], "@IsChecked": true });
                    })
                    let codeType = "";
                    let code = "";
                    let payerName = action.data?.masterCrud?.ClaimAssignmentInfo?.PayerList?.Payer.find(obj => { return obj["@ID"] === state.Payer });
                    if (state.StatusCode !== "0") {
                        codeType = "3";
                        code = state.StatusCode !== undefined ? state.StatusCode : "0";
                    }
                    else if (state.Category277Code !== "0") {
                        codeType = "2";
                        code = state.Category277Code !== undefined ? state.Category277Code : "0";
                    }
                    else if (state.ReconLevelCode !== "0") {
                        codeType = "1";
                        code = state.ReconLevelCode !== undefined ? state.ReconLevelCode : "0";
                    }
                    else if (state.RemitCode !== "0") {
                        codeType = "4";
                        code = state.RemitCode !== undefined ? state.RemitCode : "0";
                    }
                    else if (state.DDEErrorCode !== "0") {
                        codeType = "5";
                        code = state.DDEErrorCode !== undefined ? state.DDEErrorCode : "0";
                    }
                    let data = {
                        "@IndexId": 0,
                        "@ID": "#",
                        "@Code": code,
                        "@Expires": state.ExpiresOn !== undefined ? state.ExpiresOn : "",
                        "@CodeType": codeType,
                        "@Payer": payerName !== undefined ? payerName["@Name"] : "",
                        Departments: departmentList,
                        Users: UserList,
                        Facilities: facilityList
                    }
                    let updatedData = [];
                    var oldData = state.StatusRules;
                    oldData?.push(data);
                    let k = 0;
                    oldData?.map((itemDetail: any) => {
                        itemDetail["@IndexId"] = k + 1;
                        k++;
                    })
                    data["@IndexId"] = oldData.length;
                    updatedData = upsertStatusRule(state.StatusRulesModified, data);

                    return {
                        ...state,
                        StatusRules: oldData,
                        StatusRulesModified: updatedData,
                        Id: 0,
                        IndexId: 0,
                        Payer: "0",
                        PayerName: "",
                        StatusCode: "0",
                        ReconLevelCode: "0",
                        RemitCode: "0",
                        Category277Code: "0",
                        DDEErrorCode: "0",
                        Department: "0",
                        ExpiresOn: "",
                        Facility: "0",
                        Users: []
                    }

                }
                break;
            case 'UPDATE_PAYER_STATUS_RULES':
                if (action.data.uiData.value !== "") {

                    let selectedObj = state.StatusRules?.find(obj => { return obj["@IndexId"] === state.IndexId });
                    if (selectedObj !== undefined) {
                        let departmentList: any = { "@DepartmentID": state.Department, "@ID": state.Department };
                        let facilityList: any = state.FacilityLookup !== undefined && state.FacilityLookup !== "0" ? { "@FacilityID": state.Facility, "@ID": state.Facility } : { "@FacilityID": '0', "@ID": '0' };
                        var UserList: any = [];
                        UserList = checkUsers(state.Users, selectedObj?.["Users"]);
                        let codeType = "";
                        let code = "";
                        let payerDetail = action.data?.masterCrud?.ClaimAssignmentInfo?.PayerList?.Payer.find(obj => { return obj["@ID"] === state.Payer });
                        if (state.StatusCode !== "0") {
                            codeType = "3";
                            code = state.StatusCode !== undefined ? state.StatusCode : "0";
                        }
                        else if (state.Category277Code !== "0") {
                            codeType = "2";
                            code = state.Category277Code !== undefined ? state.Category277Code : "0";
                        }
                        else if (state.ReconLevelCode !== "0") {
                            codeType = "1";
                            code = state.ReconLevelCode !== undefined ? state.ReconLevelCode : "0";
                        }
                        else if (state.RemitCode !== "0") {
                            codeType = "4";
                            code = state.RemitCode !== undefined ? state.RemitCode : "0";
                        }
                        else if (state.DDEErrorCode !== "0") {
                            codeType = "5";
                            code = state.DDEErrorCode !== undefined ? state.DDEErrorCode : "0";
                        }
                        let data = {
                            "@IndexId": state.IndexId,
                            "@ID": selectedObj?.["@ID"] !== undefined ? selectedObj?.["@ID"] : "",
                            "@Code": code,
                            "@Expires": state.ExpiresOn !== undefined ? state.ExpiresOn : "",
                            "@CodeType": codeType,
                            "@Payer": payerDetail !== undefined ? payerDetail["@Name"] : "",
                            Departments: departmentList,
                            Users: UserList,
                            Facilities: facilityList
                        };
                        let updatedData = [];

                        var oldUpdateData = state.StatusRules;

                        let oldData = upsertStatusRuleUpdate(oldUpdateData, data);
                        updatedData = upsertStatusRuleUpdate(state.StatusRulesModified, data);
                        return {
                            ...state,
                            StatusRules: oldData,
                            StatusRulesModified: updatedData,
                            Id: 0,
                            IndexId: 0,
                            Payer: "0",
                            PayerName: "",
                            StatusCode: "0",
                            ReconLevelCode: "0",
                            RemitCode: "0",
                            Category277Code: "0",
                            DDEErrorCode: "0",
                            Department: "0",
                            ExpiresOn: "",
                            Facility: "0",
                            Users: []
                        }
                    }
                    else {
                        return { ...state };
                    }

                }
                break;
            case 'DELETE_PAYER_STATUS_RULES':
                if (action.data.uiData.data !== undefined && action.data.uiData.data.length !== 0) {
                    let updatedData: any = [];
                    let updatedNewData: any = [];
                    action.data.uiData.data.map((item) => {
                        let deletedData: any = {};
                        //updatedData = state.selectedItem["@StatusRule"] !== undefined ? state.selectedItem["@StatusRule"].filter(ob => ob["@CurrentStatusRuleId"] !== item) : "";

                        deletedData = state.StatusRules !== undefined ? state.StatusRules.find(ob => ob["@IndexId"] === item) : {};
                        let data: any = {};
                        if (deletedData !== undefined) {
                            data = {
                                "@ID": deletedData["@ID"],
                                "@Delete": true,
                                Departments: [],
                                Users: [],
                                Facilities: []
                            }
                            let dataTobeUpdated = [];
                            if (deletedData["@ID"] !== "#")
                                dataTobeUpdated = upsertStatusRuleDelete(state.statusRules, data);
                            else {
                                dataTobeUpdated = removeStatusRule(state.statusRules, deletedData);
                            }

                            state.statusRules = dataTobeUpdated;
                            state.StatusRules = removeStatusRule(state.StatusRules, deletedData);
                        }

                    })
                    updatedData = state.StatusRules;
                    updatedNewData = state.statusRules;
                    let j = 0;
                    updatedData?.map((itemDetail: any) => {
                        itemDetail["@CurrentStatusRuleId"] = j + 1;
                        j++;
                    })
                    if (updatedData !== "") {
                        return {
                            ...state,
                            StatusRules: updatedData,
                            StatusRulesModified: updatedNewData,
                            Id: 0,
                            IndexId: 0,
                            Payer: "0",
                            PayerName: "",
                            StatusCode: "0",
                            ReconLevelCode: "0",
                            RemitCode: "0",
                            Category277Code: "0",
                            DDEErrorCode: "0",
                            Department: "0",
                            ExpiresOn: "",
                            Facility: "0",
                            Users: []
                        }
                    }
                    else {
                        return { ...state };
                    }
                }
                break;
            case 'INITIAL_PAYER_STATUS_RULES':
                if (action.data.uiData.value !== undefined && action.data.uiData.value.length > 0) {
                    let j = 0;
                    action.data.uiData.value?.map((itemDetail: any) => {
                        itemDetail["@IndexId"] = j + 1;
                        j++;
                    })
                    return {
                        ...state,
                        StatusRules: action.data.uiData.value,
                        StatusRulesModified: []
                    }
                }
                break;
            case 'UPDATE_MCAPSR_FACILITY_LOOKUP':
                if (state.FacilityLookup !== action.data.uiData.value) {
                    return {
                        ...state,
                        FacilityLookup: action.data.uiData.value
                    }
                }
                break;
            case 'UPDATE_MCAPSR_FACILITY':
                if (state.Facility !== action.data.uiData.value) {
                    return {
                        ...state,
                        Facility: action.data.uiData.value
                    }
                }
                break;
            case 'CLEAR_USER_LIST':
                return {
                    ...state,
                    Users: []
                }
            case 'CLEAR_CURRENT_STATUS_RULES':
                if (action.data.uiData.value === "clear") {
                    return {
                        ...state,
                        Id: 0,
                        IndexId: 0,
                        Payer: "0",
                        PayerName: "",
                        StatusCode: "0",
                        ReconLevelCode: "0",
                        RemitCode: "0",
                        Category277Code: "0",
                        DDEErrorCode: "0",
                        Department: "0",
                        ExpiresOn: "",
                        Facility: "0",
                        Users: []
                    }
                }
                break;
            case 'CLEAR_STATUS_RULES':
                if (action.data.uiData.value === "clear") {
                    return {
                        ...state,
                        StatusRulesModified: []
                    }
                }
                break;
            case 'CLEAR_ALL':
                if (action.data.uiData.value === "clear") {
                    return {
                        ...state,
                        StatusRulesModified: [],
                        Id: 0,
                        IndexId: 0,
                        Payer: "0",
                        PayerName: "",
                        StatusCode: "0",
                        ReconLevelCode: "0",
                        RemitCode: "0",
                        Category277Code: "0",
                        DDEErrorCode: "0",
                        Department: "0",
                        ExpiresOn: "",
                        Facility: "0",
                        Users: [],
                        StatusRules: []
                    }
                }
                break;


            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
