import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers'; 
import * as ViewManualEligibilityStore from './api/ViewManualEligibilityStore';

export type IViewManualEligibilityState = IApiComponentState<any, ViewManualEligibilityStore.IViewManualEligibilityUIState>;

export const actionCreators = createApiComponentActions<ViewManualEligibilityStore.ActionCreators>(ViewManualEligibilityStore.actionCreators); 
export type IViewManualEligibilityActions = typeof actionCreators;
export type IViewManualEligibilityActionProps = IApiComponentActionProps<IViewManualEligibilityActions>

export const reducers = createApiComponentReducers("ViewManualEligibility", ViewManualEligibilityStore.reducer); 
export const reducer = combineReducers<IViewManualEligibilityState>(reducers);
