import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ManualRunUI from './ui/ManualRunUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IManualRunState = ICrudComponentState<MCManualRunType, ManualRunUI.IManualRunUIState>;

export const actionCreators = createCrudComponentActions<ManualRunUI.ActionCreators>(ManualRunUI.actionCreators);
export type IManualRunActions = typeof actionCreators;
export type IManualRunActionProps = ICrudComponentActionProps<IManualRunActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiManualRun, ManualRunUI.reducer);
export const reducer = combineReducers<IManualRunState>(reducers);

export const validationCallback: ValidationCallback<MCManualRunType> = (crud: any): crud is MCManualRunType => {
    let testCrud: MCManualRunType = crud as MCManualRunType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCManualRunType");
    if (validShape) {
        VerifyArray(testCrud?.ManualRun?.AutoModuleList, "AutoModule");  // do your arrays have data

        VerifyArray(testCrud?.ManualRun?.AutoProcesses, "AutoProcess");
        validShape = ValidateArray(testCrud?.ManualRun?.AutoProcesses?.AutoProcess, "MCMR_AutoProcess");

        if (validShape && testCrud?.ManualRun?.AutoProcesses?.AutoProcess) {
            for (let i = 0; i < testCrud?.ManualRun?.AutoProcesses?.AutoProcess.length; i++) {
                VerifyParentAndArray(testCrud?.ManualRun?.AutoProcesses?.AutoProcess[i], "AutoModules", "AutoModule");          
            }
        }
    }

    return validShape;
}