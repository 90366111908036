export interface ISecurityTokenModel {
    SecurityCredentials: string,
    SecurityResponseMessage: string,
    ClientCount: Number,
    LoginType: string,
    CID: string,
    Server: string,
    DirectEntry: string,
    Dashboard: string,
    Eligibility: string,
    Reconciliation: string,
    BI: string,
    MCAMethod: string,
    MCAPayerConfigMethod: string,
    SDREnabled: string,
    EnhancedClaimStatusEnabled: string,
    ClientMobileEnabled: string,
    AnticipatedPaymentEnabled: string,
    PropensityToDenyEnabled: string,
    Client: string,
    FormTypes: string,
    Duration: string,
    Recipient: string,
    PlatformUrl: string,
}

export function getSecurityToken(): ISecurityTokenModel {
    const token = sessionStorage.getItem('SecurityInfo');
    try {
        if (token != null && token != "") {
            var tokenData = atob(token.substr(("ePREMISSessionID ").length));
            var jsonToken = JSON.parse(tokenData) as ISecurityTokenModel;
            return jsonToken;
        }
    }
    catch{}
    return {} as ISecurityTokenModel;
}

export function getRawToken(): string {
    const token = sessionStorage?.getItem('SecurityInfo');
    return !token ? "" : token;
}

export function setJsonToken(encodedToken: string) {
    sessionStorage.setItem('SecurityInfo', encodedToken);
}

export function extractTokenFromResponse(response: Response) {
    return response.headers.get('WWW-Authenticate') === null
        ? response.headers.get('Authorization')
        : response.headers.get('WWW-Authenticate');
}