import React, { useState, useEffect } from "react";
import { AnyAction } from "redux";


export interface HtmlAttributes {
    id?: string;
    name?: string;
    classes?: string;
    className?: string;
}
export interface CheckboxAttributes extends HtmlAttributes {
    ischecked?: boolean; //must use all lowercase for custom prop name.  Why? Well, because... it's React.  (It will complain if camelCase is used)
    checked?: any;
}

export interface Option { //for Select elements
    text: string;
    value: string;
    selected?: boolean;
};

//ReactJS function components by design
export function Checkbox(checkboxAttributes: CheckboxAttributes, onClick?: React.ChangeEventHandler) {
    //see example in CheckBox.tsx

    return (
        <input
            {...checkboxAttributes}
            type="checkbox"
            checked={checkboxAttributes.checked}
            onChange={onClick}
        />
    );

}
export const Select = (htmlAttributes: HtmlAttributes, options: Array<Option>, onChange?: React.ChangeEventHandler) => {
    let selectedIndex = options.findIndex(o => o.selected === true);
    let selectedValue = selectedIndex > 0 ? options[selectedIndex].value : '';
    //use conditional for selected item
    let i: number = 0;
    let optionKey = (htmlAttributes.id || 'option') + '_';

    //React butchers standard HTML, so must use "value" prop on select-element instead of selected attribute on option-element
    return (
        <select {...htmlAttributes} value={selectedValue} onChange={onChange}>
            {options.map(o => (
                <option key={optionKey + i++} value={o.value}>{o.text}</option>
            ))}
        </select>
    );
}

