import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as SubmitRemitsUI from "@store/ui/SubmitRemitsUI";
import { CrudTypes } from '@commonResources/CrudTypes';

export type SubmitRemitsUiState = ICrudComponentState<MCRemitImportOptionsType_Submit, SubmitRemitsUI.SubmitRemitsUiState>;

export const actionCreators = createCrudComponentActions<SubmitRemitsUI.ActionCreators>(SubmitRemitsUI.actionCreators);
export type SubmitRemitsActions = typeof actionCreators;
export type SubmitRemitsActionProps = ICrudComponentActionProps<SubmitRemitsActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiUsers, SubmitRemitsUI.reducer);
export const reducer = combineReducers<SubmitRemitsUiState>(reducers);