import React from "react";
import { DialogWrapper } from "../../common/DialogWrapper";
import { TreeViewDataHelper } from './TreeViewDataHelper';
import { SampleGridWithRowTreeView } from './SampleGridWithRowTreeView';

export class Demo extends React.Component{
    render() {
        const sampleTreeViewData = new TreeViewDataHelper().getSampleTreeViewData();
        return (
            <DialogWrapper
                title='DEMO - ENABLER - REMOVE REMITS RESULTS GRID'
                isBusy={false}
            >
                <SampleGridWithRowTreeView
                    treeviewData={sampleTreeViewData}
                />
            </DialogWrapper>
        )
    }
}
