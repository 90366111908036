import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { URLs } from '@commonResources/constants';
import { getRawToken } from '@scripts/session/SecurityToken';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { ISubmitClaimsState, ISubmitClaimsActionProps, actionCreators } from '@store/SubmitClaims';
import { Input, SelectDropdownV2 } from 'ui-core';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper } from '@common/DialogWrapper';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';

const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

const BrowseButton = styled.label`
    margin-left: 4% !important;
    margin-top: 22% !important;
    padding-top: 7% !important;
    height: 26px;
    outline: none;
    font-size: 11px;
    margin: 0;
    text-align: left;
    background: none;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 600;
    border-radius: 3px;
    padding: 0px 1em;
    max-width: 100%;
    display: inline-block;
    background-color: #37474F;
    color: #FFF;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-family: 'Core Sans C',sans-serif;
    border: 1px solid transparent;
`;

const SubmitClaimsContent = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    min-height: 180px;
    width: 680px; 
    padding: 10px;

    div.text-input input[type='text']{
        height: 30px;
    }

    #submit-claims-center {
        margin-left: 170px;
    } 
    #submission {
         width: 300px;
         margin-right: 18px;
        
    }
    #file-type {
         width: 300px;
         margin-right: 18px;
         margin-top: 5px;
         margin-bottom: 6px;
    }
    #unique-identifier {
         width: 300px;
         margin-bottom: 4px;
    }
    #file-upload {
         width: 300px;
         margin-top: -2px;
         margin-bottom: 4px;
    }
`;

interface IComponentProps {
    canView: boolean,
    title: string,
};

interface IComponentState {
    error: boolean,
    submission: { label: any, value: any },
    fileType: { label: any, value: any },
    uniqueIdentifier: string,
    upload: any,
    files: any,
    cancelLeave: boolean
}

export const DEFAULT_STATE: IComponentState = {
    error: false,
    submission: { label: 'Single', value: 'Single' },
    fileType: { label: '- Select File Type -', value: '' },
    uniqueIdentifier: '',
    upload: [{ label: 'CLAIM FILE:', fileName: '', file: new FormData() }],
    files: new FormData(),
    cancelLeave: false
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type ISubmitClaimsProps = IMergeApiComponentProps<ISubmitClaimsState, ISubmitClaimsActionProps, IOwnProps>;

export class SubmitClaims extends React.Component<ISubmitClaimsProps, IComponentState> {
    static addItemCount: number = 0;

    static defaultProps: IComponentProps = {
        canView: false,
        title: "Submit Claims",
    };

    constructor(props: ISubmitClaimsProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {

        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        this.setState({
            upload: [{ label: 'CLAIM FILE:', fileName: '', file: new FormData() }],
            files: new FormData()
        })
        pageLeave();
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "EnableTenetFeatures" }] });
        this.props.action.api.loadData(URLs.api + '/api/data/formData/submitClaims', this.validationCallback.bind(this), this.errorCallback.bind(this));
    }

    public componentWillUnmount() {
        pageLeave();
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public wait(ms: any) {
        var start = new Date().getTime();
        var end = start;
        while (end < start + ms) {
            end = new Date().getTime();
        }
    }
    /* -----------------------------------  */
    /* ------  BUTTON RELATED METHODS ----  */
    /* -----------------------------------  */
    public validationCallback(data: any): boolean {
        return true;
    }

    public errorCallback(dataStatus: string): boolean {
        var str = 'An error occurred retrieving the call for file types. Error: ' + dataStatus;
        console.error(str);
        return true;
    }
    public onOKSubmitClaims(e: React.ChangeEvent<HTMLButtonElement>) {
        let error = false

        if (this.state.submission?.label === '' || this.state.fileType?.label === '' || this.state.uniqueIdentifier === '') {
            error = true
        }
        if (this.state.submission?.value !== 'McKesson') {
            this.state.upload.map((item: any) => {
                if (item.fileName === '')
                    error = true
            })
        }
        else {
            if (this.state.upload[this.state.upload.length - 1].fileName === '') {
                this.state.upload.map((item: any) => {
                    if ((item.label.includes('*')) && item.fileName === '')
                        error = true
                })
            }
        }
        if (error) {
            this.setState({ error: true })
        }
        else {

            const fileLabel: any = [];
            this.state.upload.map((item: any) => {
                if (item.fileName !== '') {
                    fileLabel.push(item.labelKey)
                }
            });
            if (this.state.submission.value !== 'Single') {
                let fileurl = URLs.api + '/api/data/UploadClaimFiles?submission=' + this.state.submission?.label +
                    '&bridgeName=' + this.state.fileType?.label +
                    '&bridgeKey=' + this.state.fileType?.value +
                    '&clientSequence=' + this.state.uniqueIdentifier +
                    '&fileLabels=' + fileLabel;
                fetch(fileurl, {
                    method: 'POST',
                    headers: {
                        'Authorization': `${getRawToken()}`
                    },
                    body: this.state.files
                })
            }
            else {
                Array.from(this.state.files)?.map((item: any, index: number) => {
                    let fileurl = URLs.api + '/api/data/UploadClaimFiles?submission=' + this.state.submission?.label +
                        '&bridgeName=' + this.state.fileType?.label +
                        '&bridgeKey=' + this.state.fileType?.value +
                        '&clientSequence=' + this.state.uniqueIdentifier +
                        '&fileLabels=' + fileLabel;
                    var fileData = new FormData()
                    fileData.append('file', this.state.files.get(`file[${index}]`))
                    fetch(fileurl, {
                        method: 'POST',
                        headers: {
                            'Authorization': `${getRawToken()}`
                        },
                        body: fileData
                    })
                    this.wait(1100);
                });
            }


            this.props.history.push('/LandingPage');
        }
    }

    public onCancelSubmitClaims(e: React.ChangeEvent<HTMLButtonElement>) {
        const userModified: any = sessionStorage.getItem("userModified")
        if (userModified === 'true') {
            this.setState({ cancelLeave: true })
        }
        else
            this.props.history.push('/LandingPage');
    }

    public uploadFile(uploadedfile: any, index: any) {
        handleChange()
        const uploadData = new FormData()
        uploadData.append('file', uploadedfile.target.files)
        const files = this.state.files;
        var combinedFileName = "";
        let browsedFiles = uploadedfile.target.files;
        if (this.state.submission.value === 'Single') {
            Array.from(browsedFiles)?.forEach(function (e: any, i: any) {
                files.append(`file[${i}]`, e)
                if (i == 0) {
                    combinedFileName = e?.name;
                }
                else {
                    combinedFileName = combinedFileName + ',' + e?.name;
                }
            });
        }
        else {
            files?.delete(`file[${index}]`)
            files.append(`file[${index}]`, uploadedfile.target.files[0])
        }
        let upload = this.state.upload
        upload[index].file = uploadData
        if (this.state.submission.value === 'Single') {
            upload[index].fileName = combinedFileName;
        }
        else {
            upload[index].fileName = uploadedfile.target.files[0].name
        }
        if (upload[index].label === '-or- ZIP File:')
            upload[index].labelKey = 'ZIPFILE'
        else if (upload[index].label === '*PAT File:')
            upload[index].labelKey = 'BRIDGEFILE'
        else
            upload[index].labelKey = (upload[index].label.toUpperCase().replace(/[*]/g, "")).replace(/\:$/, "").replace(" ", "")
                .replace(" ", "")
        if (this.state.submission?.value === 'McKesson' && upload[index].label === '-or- ZIP File:')
            this.setState({
                upload: upload, error: false, files: files
            })
        else
            this.setState({ upload: upload, files: files })
    }


    /* -----------------------------------  */
    /* -------------  RENDER -------------  */
    /* -----------------------------------  */
    public render() {
        var instruction = <React.Fragment>Use this page to send claim files or Aii files that are not automatically submitted. All files must be submitted manually unless you use the Agent to set up an automatic schedule.</React.Fragment>;
        var buttons = <OKCancelButtons disableOK={false} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKSubmitClaims(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancelSubmitClaims(e)} />;

        const fileType = [{ label: '- Select File Type -', value: '' }]
        var fileTypes: any = this.props.dataStore.api.data;
        if (fileTypes)
            fileTypes.map(item => fileType.push({ label: item.Name, value: item.BridgeKey }));

        return (
            <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_ClaimsSubmit.htm' buttons={buttons} isBusy={this.props.dataStore.api.dataStatus === 'REQUEST'}>
                {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                <ContentWrapper id={"content_wrapper_submitclaims"}>
                    {
                    }
                    <ContentRowWrapper>
                        <SubmitClaimsContent>
                            <div id='submit-claims-center'>
                                <ContentRowWrapper>
                                    <SelectDropdownV2
                                        domID="submission"
                                        className="dropdown"
                                        label="Submission:"
                                        isClearable={false}
                                        isSearchable={true}
                                        size='large'

                                        onChange={(e: any) => {
                                            this.setState({ submission: { label: e.label, value: e.value }, error: false })
                                            if (e.value === 'Single') {
                                                this.setState({
                                                    upload: [
                                                        { label: 'CLAIM FILE:', fileName: '', file: new FormData() }]
                                                    , files: new FormData()
                                                })
                                            }
                                            if (e.value === 'Series') {
                                                this.setState({
                                                    upload: [
                                                        { label: 'CLAIM FILE:', fileName: '', file: new FormData(), labelKey: 'CLAIMFILE' },
                                                        { label: 'Job Level File:', fileName: '', file: new FormData() },
                                                        { label: 'Bill Level File:', fileName: '', file: new FormData() },
                                                        { label: 'Claim Level File:', fileName: '', file: new FormData() }]
                                                    , files: new FormData()
                                                })
                                            }
                                            if (e.value === 'Star') {
                                                this.setState({
                                                    upload: [
                                                        { label: 'CLAIM FILE:', fileName: '', file: new FormData(), labelKey: 'CLAIMFILE' },
                                                        { label: 'Data File:', fileName: '', file: new FormData() }]
                                                    , files: new FormData()
                                                })
                                            }
                                            if (e.value === 'Tpa') {
                                                this.setState({
                                                    upload: [
                                                        { label: 'CLAIM FILE:', fileName: '', file: new FormData(), labelKey: 'CLAIMFILE' },
                                                        { label: 'Claim Data File:', fileName: '', file: new FormData() },
                                                        { label: 'Service Data File:', fileName: '', file: new FormData() }]
                                                    , files: new FormData()
                                                })
                                            }
                                            if (e.value === 'McKesson') {
                                                this.setState({
                                                    files: new FormData(),
                                                    upload: [
                                                        { label: '*PAT File:', fileName: '', file: new FormData(), labelKey: 'BRIDGEFILE' },
                                                        { label: '*RES File:', fileName: '', file: new FormData() },
                                                        { label: '*INS File:', fileName: '', file: new FormData() },
                                                        { label: '*EMP File:', fileName: '', file: new FormData() },
                                                        { label: '*PHY File:', fileName: '', file: new FormData() },
                                                        { label: '*MIS File:', fileName: '', file: new FormData() },
                                                        { label: '*CHG File:', fileName: '', file: new FormData() },
                                                        { label: '*ASC File:', fileName: '', file: new FormData() },
                                                        { label: '*VAR File:', fileName: '', file: new FormData() },
                                                        { label: 'TPA File:', fileName: '', file: new FormData() },
                                                        { label: '*ADD File:', fileName: '', file: new FormData() },
                                                        { label: 'ATM File:', fileName: '', file: new FormData() },
                                                        { label: 'REM File:', fileName: '', file: new FormData() },
                                                        { label: 'NDC File:', fileName: '', file: new FormData() },
                                                        { label: 'FFI File:', fileName: '', file: new FormData() },
                                                        { label: 'PROV File:', fileName: '', file: new FormData() },
                                                        { label: 'DEN File:', fileName: '', file: new FormData() },
                                                        { label: 'EPRM File:', fileName: '', file: new FormData() },
                                                        { label: 'CHA File:', fileName: '', file: new FormData() },
                                                        { label: 'ADJ File:', fileName: '', file: new FormData() },
                                                        { label: '-or- ZIP File:', fileName: '', file: new FormData() }
                                                    ]
                                                })
                                            }
                                        }
                                        }

                                        options={[
                                            { label: 'Single', value: 'Single' },
                                            { label: 'Series HIS System', value: 'Series' },
                                            { label: 'Star/PBAR HIS System', value: 'Star' },
                                            { label: 'TPA HIS System', value: 'Tpa' },
                                            { label: 'McKesson Proprietary', value: 'McKesson' }]}
                                        initialValue={this.state.submission}
                                    />

                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <SelectDropdownV2
                                        domID="file-type"
                                        className="dropdown"
                                        label="File Type:"
                                        isClearable={false}
                                        isSearchable={true}
                                        size='large'
                                        hasError={this.state.error && this.state.fileType.value === ''}
                                        errorMessage={'Please select a file type.'}
                                        initialValue={this.state.fileType}

                                        onChange={(e: any) => {
                                            this.setState({ uniqueIdentifier: e.value === '' ? '' : e.label, fileType: { label: e.label, value: e.value } })
                                        }
                                        }
                                        options={fileType}
                                    />

                                </ContentRowWrapper>
                                <ContentRowWrapper>
                                    <Input
                                        domID="unique-identifier"
                                        className="text-input"
                                        label="Unique Identifier:"
                                        maxLength={25}
                                        initialValue={this.state.uniqueIdentifier}
                                        value={this.state.uniqueIdentifier}
                                        hasError={this.state.error && this.state.uniqueIdentifier === ''}
                                        errorMessage={'Please enter a unique identifier.'}
                                        onChange={(e: any) => {
                                            this.setState({ uniqueIdentifier: e.target.value })
                                        }
                                        }
                                    />
                                </ContentRowWrapper>

                                {this.state.upload.map((item: any, i: any) => {
                                    const id = 'fileupload' + i;
                                    return (
                                        <ContentRowWrapper>
                                            <Input
                                                domID="file-upload"
                                                className="text-input"
                                                label={item.label}
                                                maxLength={21}
                                                initialValue={item.fileName}
                                                hasError={this.state.error && this.state.submission?.value !== 'McKesson' ? item.fileName === '' :
                                                    this.state.error && (item.label.includes('*') && item.fileName === '' && this.state.upload.filter((item1: any) => { return item1.label.includes('-or- ZIP File:') && item1.fileName !== '' }).length === 0 ||
                                                        this.state.upload.filter((item1: any) => { return item1.label.includes('*') && item1.fileName !== '' }).length === 0 && item.label === '-or- ZIP File:' && item.fileName === '')}
                                                errorMessage={item.label === '-or- ZIP File:' ? 'Please select a zip file  on your computer.' : 'Please select a ' + (item.label.toLowerCase().replace(/[*]/g, "")).replace(/\:$/, "") + ' on your computer.'}
                                                onChange={(e: any) => {
                                                    const upload = this.state.upload
                                                    upload[i].fileName = e.target.value
                                                    this.setState({
                                                        upload: upload
                                                    })
                                                }}

                                            />
                                            <div id={i} style={{ marginTop: '3px' }}>
                                                <BrowseButton id="secondary-claims-ID" htmlFor={id} >Browse</BrowseButton>
                                                <Input id={id} type='file' style={{ display: 'none' }} multiple={this.state.submission.value === 'Single' ? true : false} onChange={(e: any) => this.uploadFile(e, i)} onClick={(e: any) => {
                                                    e.target.value = null//Resetting the fileupload to accomodate different values;
                                                }} />
                                            </div>
                                        </ContentRowWrapper>
                                    )
                                })}

                            </div>

                        </SubmitClaimsContent>
                    </ContentRowWrapper>
                </ContentWrapper>

            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<ISubmitClaimsState, ISubmitClaimsActionProps, IOwnProps, ISubmitClaimsProps, ApplicationState>(
    createApiMapStateToProps('submitClaims'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(SubmitClaims);


export default withRouter(connectedHoc);