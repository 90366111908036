import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, createAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import PayerAlias from '../../components/Maintenance/MasterSetup/PayerAlias';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IPayerAliasUIState {
    payerList?: MCPALIAS_PayerList;
    aliasList?: MCPALIAS_Aliases;
    idAliasList?: MCPALIAS_IdAliases;
    customAliasList?: MCPALIAS_CustomAliases;

    filteredPayerList?: MCPALIAS_PayerList;
    filteredAliasList?: MCPALIAS_Aliases;
}

export enum PayerAliasTypeEnum {
    "Payer Name",
    "Payer ID",
    "Custom ID",
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).


export interface IPayerAliasInit extends ICrudActionData<MCPayerAliasType, MCPayerAliasType> { }
export interface IPayerAliasAdd extends ICrudActionData<MCPayerAliasType, MCPALIAS_Alias> { }
export interface IPayerAliasDelete extends ICrudActionData<MCPayerAliasType, MCPALIAS_Alias> { }
export interface IPayerAliasUpdate extends ICrudActionData<MCPayerAliasType, MCPALIAS_Alias> { }

//TypeScript can't handle enums (even enums cast to string values) or object strings, must use magical strings
export const actionCreators = {
    initPayerAlias: (payerAliasInfo: IPayerAliasInit) => createDataAction("INIT_PAYER_ALIAS_MAINTENANCE", payerAliasInfo),
    addPayerAlias: (payerAlias: IPayerAliasAdd) => createDataAction("ADD_PAYER_ALIAS", payerAlias),
    deletePayerAlias: (payerAlias: IPayerAliasDelete) => createDataAction("DELETE_PAYER_ALIAS", payerAlias),
    updatePayerAlias: (payerAlias: IPayerAliasUpdate) => createDataAction("UPDATE_PAYER_ALIAS", payerAlias),
}

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IPayerAliasUIState = {
    payerList: {
        Payer: []
    },
    aliasList: {
        Alias: []
    },
    idAliasList: {
        Alias: []
    },
    customAliasList: {
        Alias: []
    },

    filteredPayerList: {
        Payer: []
    },
    filteredAliasList: {
        Alias: []
    },
};

export const reducer: Reducer<IPayerAliasUIState, KnownActions> = (state: IPayerAliasUIState|undefined, action: KnownActions) => {

    if (state != undefined) {
        //for some stupid reason, TypeScript requires a minimum of TWO (2) case options in a switch statement, otherwise the "exhaustiveCheck: never" throws a compiler error
        switch (action.type) {
            case "INIT_PAYER_ALIAS_MAINTENANCE":
                {
                    return {
                        ...state
                    };
                }
            case "ADD_PAYER_ALIAS":
                {
                    //let uiDataType = action.data.uiData['@AliasType'];
                    //if (uiDataType) { //must check because of TypeScript
                    //    let payerAliasType = (<any>PayerAliasTypeEnum)[uiDataType]; //because TypeScript...
                    //    switch (payerAliasType) {
                    //        case PayerAliasTypeEnum['Payer Name']:
                    //            //state.aliasList.Alias.push();
                    //            break;
                    //        case PayerAliasTypeEnum['Payer ID']:
                    //            break;
                    //        case PayerAliasTypeEnum['Custom ID']:
                    //            break;
                    //        default:
                    //            break;
                    //    }
                    //}

                    return {
                        ...state,
                        aliasList: action.data.masterCrud?.PayerAliasMaintenanceInfo.Aliases,
                        idAliasList: action.data.masterCrud?.PayerAliasMaintenanceInfo.IDAliases,
                        customAliasList: action.data.masterCrud?.PayerAliasMaintenanceInfo.CustomAliases,
                    };
                }
            case "DELETE_PAYER_ALIAS":
                {
                    //let uiDataType = action.data.uiData['@AliasType'];
                    //if (uiDataType) { //must check because of TypeScript
                    //    let payerAliasType = (<any>PayerAliasTypeEnum)[uiDataType]; //because TypeScript...
                    //    switch (payerAliasType) {
                    //        case PayerAliasTypeEnum['Payer Name']:
                    //            //state.aliasList.Alias.push();
                    //            break;
                    //        case PayerAliasTypeEnum['Payer ID']:
                    //            break;
                    //        case PayerAliasTypeEnum['Custom ID']:
                    //            break;
                    //        default:
                    //            break;
                    //    }
                    //}

                    return {
                        ...state,
                        aliasList: action.data.masterCrud?.PayerAliasMaintenanceInfo.Aliases,
                        idAliasList: action.data.masterCrud?.PayerAliasMaintenanceInfo.IDAliases,
                        customAliasList: action.data.masterCrud?.PayerAliasMaintenanceInfo.CustomAliases,
                    };
                }
            case "UPDATE_PAYER_ALIAS":
                {
                    //let uiDataType = action.data.uiData['@AliasType'];
                    //if (uiDataType) { //must check because of TypeScript
                    //    let payerAliasType = (<any>PayerAliasTypeEnum)[uiDataType]; //because TypeScript...
                    //    switch (payerAliasType) {
                    //        case PayerAliasTypeEnum['Payer Name']:
                    //            //state.aliasList.Alias.push();
                    //            break;
                    //        case PayerAliasTypeEnum['Payer ID']:
                    //            break;
                    //        case PayerAliasTypeEnum['Custom ID']:
                    //            break;
                    //        default:
                    //            break;
                    //    }
                    //}

                    return {
                        ...state,
                        aliasList: action.data.masterCrud?.PayerAliasMaintenanceInfo.Aliases,
                        idAliasList: action.data.masterCrud?.PayerAliasMaintenanceInfo.IDAliases,
                        customAliasList: action.data.masterCrud?.PayerAliasMaintenanceInfo.CustomAliases,
                    };
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return exhaustiveCheck;
        }
    }

    return state || defaultState;
}