import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { bindAll } from 'lodash';
import styled from 'styled-components';
import {
    IMergeCrudComponentProps,
    
} from '@scripts/util/CrudComponentHelpers';
import { IFormModalState } from '../../../store/ui/BaseCrudUI';
import { IClaimOptionsUIState, IClaimOptionsActionProps } from '@store/ClaimOptions';
import {
    ICCIPayerSelectionData, IPayerSelectionUpdateRequest, ICCIPayerSelectionLocalState,
    ICCIGeneralOptionsData, IGeneralUpdateRequest, ICCIGeneralOptionsLocalState
}   from '../../../store/ui/ClaimOptionsUI';
import { CCIPayerSelectionOptions } from './CCIPayerSelectionOptions';
import { CCIGeneralSelectionOptions } from './CCIGeneralSelectionOptions';
import { RadioListComponent } from '@common/RadioListComponent';

interface IContentWrapperProps {
    height: string;
}

const ContentWrapper = styled.div<IContentWrapperProps>`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    height: ${(p) => `${p.height}`};

    #general-type_wrapper {
        padding-right: 20px;
    }
`;

interface IComponentProps {
    canView: boolean;
    canEdit: boolean;
    canDelete: boolean;

    displayAlertMessage(msg: string): void,

    radioIndex: number,
    updateRadioIndex(index: number): void,

    generalOptions: ICCIGeneralOptionsData,
    updateCCIGeneralOptions(request: IGeneralUpdateRequest): void,
    generalOptionsLocalState?: ICCIGeneralOptionsLocalState,
    onGeneralOptionsLocalStateChange(request: ICCIGeneralOptionsLocalState): void,

    payerSelectionOptions: ICCIPayerSelectionData,
    updateCCIPayerSelectionOptions(request: IPayerSelectionUpdateRequest): void,
    payerSelectionLocalState?: ICCIPayerSelectionLocalState,
    onPayerSelectionLocalStateChange(request: ICCIPayerSelectionLocalState): void,
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IClaimOptionsProps = IMergeCrudComponentProps<IClaimOptionsUIState, IClaimOptionsActionProps, IOwnProps>;

interface IComponentState extends IFormModalState {
}

export class CCIOptions extends React.Component<IClaimOptionsProps, IComponentState> {
    constructor(props: IClaimOptionsProps) {
        super(props);
        bindAll(this, [/*methods here*/]);
    }

    isBusy = () => {
        return (this.props.dataStore.crud.dataStatus === 'REQUEST');
    }

    public onSelectTab(e: React.MouseEvent<HTMLButtonElement>, selObj: any) {
        this.props.updateRadioIndex(selObj.radioIndex);
    }

    public renderContent(index: number) {
        switch (index) {
            case 0:
                return (
                    <CCIGeneralSelectionOptions
                        canView={this.props.canView}
                        canEdit={this.props.canEdit}
                        canDelete={this.props.canDelete}
                        generalOptions={this.props.generalOptions}
                        updateCCIGeneralOptions={this.props.updateCCIGeneralOptions}
                        displayAlertMessage={this.props.displayAlertMessage}
                        localState={this.props.generalOptionsLocalState}
                        onLocalStateChange={this.props.onGeneralOptionsLocalStateChange}
                    />
                )

            case 1:
                return (<div>
                    <CCIPayerSelectionOptions
                        canView={this.props.canView}
                        canEdit={this.props.canEdit}
                        canDelete={this.props.canDelete}
                        payerSelectionOptions={this.props.payerSelectionOptions}
                        updateCCIPayerSelectionOptions={this.props.updateCCIPayerSelectionOptions}
                        displayAlertMessage={this.props.displayAlertMessage}
                        localState={this.props.payerSelectionLocalState}
                        onLocalStateChange={this.props.onPayerSelectionLocalStateChange}
                    />
                </div>)

            default:
                return <div>
                    <p> radio 1 </p>
                </div>
        }
    }

    public setRadioIndexState(selectedIndex: number) {
        this.props.updateRadioIndex(selectedIndex);
    }

    public render() {
        const { radioIndex } = this.props;
        const { /*props*/ isDirty } = this.props.dataStore.ui;
        return (
            <ContentWrapper height='600px'>
                    <RadioListComponent
                        dialogTitle={'Configuration'}
                        selectedIndex={radioIndex}
                        hasDialog={true}
                        setRadioSelectedState={this.setRadioIndexState.bind(this)}
                        radioListItems={[{
                            domID: 'general-type',
                            label: 'General', disabled: false,
                            index: 0,

                        },
                        { domID: 'payer-type', label: 'Payer Selection', disabled: false, index: 1 }]}
                        width={'33%'}
                >
                    {this.renderContent(radioIndex)}
                </RadioListComponent>               
            </ContentWrapper>);
    }
}

