import * as React from 'react';
import { DialogWrapper } from '../common/DialogWrapper';
//import ReadOnlyList from '@commonResources/ReadOnlyList';
import styled from 'styled-components';
import _ from 'lodash';

import ReadOnlyList from '@commonResources/ReadOnlyList';

const reallyLongString = "(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?"

const ListContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

interface IComponentProps {
};

interface IComponentState {
};



export default class ReadOnlyListDemo extends React.Component<IComponentProps, IComponentState> {
    getDateString = () => {
        const soruceDate = new Date();
        soruceDate.setDate(soruceDate.getDate() - (Math.random() *Number(_.uniqueId())))
        return soruceDate.toDateString()
    }
    render() {
        return (
            <DialogWrapper title="Read Only List Demo" >
                <ListContainerDiv>
                    <ReadOnlyList
                        subTitle="" // note that this optional subHeader prop if passed an empty string will still display a subHearder populated by white space
                        columns={[ // this prop determins what the headers say and how they behave
                            {
                                dataName: "@data1", //required name of row data key for the header
                                text: "Hello World!", //required display text for the header,
                                width: 0 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
                            },
                            {
                                dataName: "@data2", //required name of row data key for the header
                                text: "Hello World!", //required display text for the header
                                rightAlignText: true,
                                width: 200 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
                            },
                            {
                                dataName: "@data3", //required name of row data key for the header
                                text: "time", //required display text for the header
                                rightAlignText: true
                            }
                        ]}
                        records={[ // this prop determins what data appears in the rows and how the rows behave
                            {
                                "@data1": {
                                    text: reallyLongString,
                                    onClick: () => console.log("Hello World! row 1 col 1 onClick"), //note that this optional onClick for this table data will be overriden by the global onClick if one is provided
                                },
                                "@data2": { // note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + 1
                                }, 
                                "@data3": { // note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + Date.now() + Number(_.uniqueId())
                                },  
                            },
                            {
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {
                                    text: reallyLongString,
                                    //onClick: () => console.log("Hello World! row 2 col 2 onClick"), //note that if no global or table data onClick is provided then the table data will not be clickable
                                },
                                //"@data3":  { // note that if a key listed in the columns object is missing in the record provided it will simply be left blank on the table
                                    //text: none
                                //}, 
                            },
                            {
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": { // note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + Number(_.uniqueId())
                                }, 
                                "@data3": {
                                    text: reallyLongString,
                                    onClick: () => console.log("Hello World! row 3 col 3 onClick"), //note that this optional onClick for this table data will be overriden by the global onClick if one is provided
                                },
                            }
                        ]}
                    />
                    <ReadOnlyList
                        onClick={() => console.log("Hello World! table onClick")} //note that this optional global onClick overrides any onClick callback provided as a key in the records prop
                        columns={[ // this prop determins what the headers say and how they behave
                            {
                                dataName: "@data1", //required name of row data key for the header
                                text: "pneumonoultramicroscopicsilicovolcanoconiosis", //required display text for the header
                                width: 0 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
                            },
                            {
                                dataName: "@data2", //required name of row data key for the header
                                text: "Payer Search Ranking", //required display text for the header
                                width: 0 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
                            },
                            {
                                dataName: "@data3", //required name of row data key for the header
                                text: "time", //required display text for the header
                                rightAlignText: true,
                                width: 0 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
                            }
                        ]}
                        records={[ // this prop determins what data appears in the rows and how the rows behave
                            {
                                "@data1": {
                                    text: reallyLongString,
                                    onClick: () => console.log("Hello World! row 1 onClick"), //note that this optional onClick for this row will be overriden by the global onClick if one is provided
                                },
                                "@data2": { // note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + 1,
                                    onClick: () => console.log("Hello World! row 1 onClick"), //note that this optional onClick for this row will be overriden by the global onClick if one is provided
                                },
                                "@data3": { // note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + Date.now() + Number(_.uniqueId()),
                                    onClick: () => console.log("Hello World! row 1 onClick"), //note that this optional onClick for this row will be overriden by the global onClick if one is provided
                                }, 
                            },
                            {
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {
                                    text: reallyLongString
                                },
                                "@data3": { // note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + Date.now() + Number(_.uniqueId())
                                }, 
                            },
                            {
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": { // note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + Number(_.uniqueId())
                                }, // note that all display data must be cast to a string so that sorting can work properly
                                "@data3": {
                                    text: reallyLongString
                                },
                            }
                        ]}
                    />
                    <ReadOnlyList
                        title="Hello World! Title" // optional header prop
                        subTitle={this.getDateString()} // note that this optional subHeader prop if passed an empty string will still display a subHearder populated by white space
                        maxHeight="150px" // optional maxHeight prop
                        domID="Test@123" // recommened but optional domID
                        tooltip="Hello World! Tooltip" // optional tooltip prop
                        //onClick={() => console.log("Hello World! table onClick")} //note that this optional global onClick overrides any onClick callback provided as a key in the records prop
                        columns={[ // this prop determins what the headers say and how they behave
                            {
                                dataName: "@data1", //required name of row data key for the header
                                text: "pneumonoultramicroscopicsilicovolcanoconiosis", //required display text for the header
                                rightAlignText: true,
                                width: 300 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
                            },
                            {
                                dataName: "@data2", //required name of row data key for the header
                                text: "Payer Search Ranking", //required display text for the header
                                sortable: true, // enables sorting for this column
                                SortDataType: "Number", // required only when sorting is enabled. Valid entries are 'Date' or 'String' or 'Number'. Sorting my not work properly without this
                                width: 100 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
                            },
                            {
                                dataName: "@data3", //required name of row data key for the header
                                text: "time", //required display text for the header
                                sortable: true, // enables sorting for this column. Remember to also set a SortDataType or sorting will not work properly
                                SortDataType: "Date", // required when sorting is enabled. The only valid entries are 'Date' or 'String' or 'Number'. Sorting may not work properly without this.
                                width: 200 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
                            }
                        ]}
                        records={[ // this prop determins what data appears in the rows and how the rows behave
                            {
                                "@data0": {
                                    text: "" + _.uniqueId()
                                }, //note that this is not displayed because it is not specified in the columns prop
                                "@data1": {
                                    text: reallyLongString
                                },
                                "@data2": { // note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + (Math.random() * Number(_.uniqueId()))
                                },
                                "@data3":  { // note that if a key listed in the columns object is missing in the record provided it will simply be left blank on the table
                                    text: this.getDateString()
                                }, 
                            },
                            {
                                "@data0": { //note that this is not displayed because it is not specified in the columns prop
                                    text: "" + _.uniqueId()
                                },
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + (Math.random() * Number(_.uniqueId()))
                                }, 
                                "@data3": {
                                    text: this.getDateString()
                                },
                            },
                            {
                                "@data0": {//note that this is not displayed because it is not specified in the columns prop
                                    text: "" + _.uniqueId()
                                }, 
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {
                                    text: "" + (Math.random() * Number(_.uniqueId()))
                                },
                                "@data3": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: this.getDateString()
                                }, 
                            },
                            {
                                "@data0": {//note that this is not displayed because it is not specified in the columns prop
                                    text: "" + _.uniqueId()
                                }, 
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + (Math.random() * Number(_.uniqueId()))
                                }, 
                                "@data3": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: this.getDateString()
                                }, 
                            },
                            {
                                "@data0": {//note that this is not displayed because it is not specified in the columns prop
                                    text: "" + _.uniqueId()
                                }, 
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + (Math.random() * Number(_.uniqueId()))
                                }, 
                                "@data3": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: this.getDateString()
                                }, 
                            },
                            {
                                "@data0": {//note that this is not displayed because it is not specified in the columns prop
                                    text: "" + _.uniqueId()
                                }, 
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + (Math.random() * Number(_.uniqueId()))
                                }, 
                                "@data3": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: this.getDateString()
                                }, 
                            },
                            {
                                "@data0": {//note that this is not displayed because it is not specified in the columns prop
                                    text: "" + _.uniqueId()
                                }, 
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + (Math.random() * Number(_.uniqueId()))
                                }, 
                                "@data3": { // note that all display data must be cast to a string so that sorting can work properly
                                    text: this.getDateString()
                                },
                            },
                            {
                                "@data0": {//note that this is not displayed because it is not specified in the columns prop
                                    text: "" + _.uniqueId()
                                }, 
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + (Math.random() * Number(_.uniqueId()))
                                }, 
                                "@data3": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: this.getDateString()
                                }, 
                            },
                            {
                                "@data0": {//note that this is not displayed because it is not specified in the columns prop
                                    text: "" + _.uniqueId()
                                }, 
                                "@data1": {
                                    text: this.getDateString()
                                },
                                "@data2": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: "" + (Math.random() * Number(_.uniqueId()))
                                }, 
                                "@data3": {// note that all display data must be cast to a string so that sorting can work properly
                                    text: this.getDateString()
                                }, 
                            },
                        ]} />
                </ListContainerDiv>
            </ DialogWrapper>
        )
    }
}