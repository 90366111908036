import * as React from 'react';
import { bindAll, noop, isEqual } from 'lodash';
import { Button, SelectDropdownV2, Grid, Typography } from 'ui-core';
import styled from 'styled-components';
import { ARMRowCell } from '@common/UICLWrappers/ARMRowCell';
import { ARMGridPager } from '../../common/UICLWrappers/ARMGridPager';
import {  DialogFieldset, DialogLegend3 } from '@common/DialogStyles';

import { ModalConfirmation } from '@common/ModalConfirmation';
import { Input } from '@common/UICLWrappers/ARMInput';
import {
    ContentColumnWrapper,
    ContentRowWrapper
} from '@common/DialogWrapper';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { SelectComponent } from '../../common/SelectComponent';
import { IMNPolicySelectionData, IMNPolicySelectionUpdateRequest, IMNPolicySelectionLocalState, IModifyClaimOptions, MNSearchRequest, MNSearchParam, enforceArray } from '../../../store/ui/ClaimOptionsUI';

interface UiclColumn {
    dataName: string,
    text: string,
    sortable?: boolean,
    cellType?: string,
    isSorted?: number,
}

interface RowProps {
    record: {
        '@PolicyId': string;
        '@PolicyNumber': string; 
        '@Description': string;
        '@Severity': string;
        '@Reference': string; 
    }
}

const GridWrapper = styled.div`
    height: 350px;
    width: 1000px;
    #grid-wrapper-id-mn-payer-selection-options-grid {
        height:170px;
    }

    .empty-row{
       display: none;
    }

    thead tr th{
        background-color:#9BA1A9;
        height:20px;
    }

    table tbody tr > td{
       height: auto;
    }

    table tbody tr td div{
        min-height:15px;
        ${Typography.small};
    }

   .LOB {
        width:150px;
    }

    .PayerOrAlias {
        width:345px;
    }

    .FIDataContent {
        width: 405px;
    }

    .Form {
        min-width:30px;
        max-width:30px;
    }
.selectedRow{
        background-color:yellow !important;
    }

    .unselectedRow{
        background-color:white;
    }

    #grid-wrapper-claim-list-grid {

        height:200px;

        table tbody tr > td:first-child:not(.select-checkbox){
            padding-left:30px;
        }
         thead tr th{
        background-color:#9BA1A9;
        height:20px;
    }

        table > thead > tr > th {
/*
            height:32px;
            background-color:#9BA1A9 !important;
            font-weight:bold;
            font-family:"Core Sans C","Century Gothic","AppleGothic",sans-serif;
            position:sticky;
            top:0;
*/
        }


        #thPayer{
            padding-bottom:3.5px;
        }

        table tbody tr > td{
            height:32px !important;
            padding-top:8px;
        }
    }

    table tbody tr:hover{
        background-color:#edf9ff !important;
        cursor:pointer !important;
    }

    max-height:500px;
    overflow-y:scroll;
    background-color:white;
    border: black 1px solid;

    table tbody tr > td{
        height:25px !important;
    }

    table tbody tr td div{
        min-height:1px;
    }
 `;


export interface IComponentProps {
    updateMNPolicySelectionOptions(request: IMNPolicySelectionUpdateRequest): void,
    searchMNPolicyDescription(request: MNSearchRequest): void;
    searchOptionPolicyResults: any | undefined;
    policySelectionOptions: IMNPolicySelectionData;//any;
    onLocalStateChange(request: IMNPolicySelectionLocalState): void,
}

interface GridPaging {
    currentPageNumber: number;
    recordsPerPage: number;
    totalRecordCount: number;
}


export interface IComponentState {
    policySelectionOptions: IMNPolicySelectionData;
    description: string;
    gridPaging: GridPaging;
    severity: any;
    showModalConfirm: boolean;
    showTooltip: boolean; 
    scrollToTop: boolean;
    zeroResults: 'initial' | 'active' | 'closed'; 
    totalPages: string; 
}
export default class MedNecPolicySelectionOptions extends React.PureComponent<IComponentProps, IComponentState> {
    static defaultProps = {
        severity: '',
        searchOptionPolicyResults: [],
    }
    pager: React.RefObject<any>;
    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            policySelectionOptions: this.props.policySelectionOptions,
            zeroResults: 'initial', 
            scrollToTop: false, 
            totalPages : '0',
            description: props.policySelectionOptions?.description ? props.policySelectionOptions.description : '',
            severity: props.policySelectionOptions?.selectedSeverity ?
                (props.policySelectionOptions.selectedSeverity === 'W' ? { label: 'WARNING', value: 'W' } :
                    (props.policySelectionOptions.selectedSeverity === 'B' ? { label: 'BOTH', value: 'B' } : { label: 'ERROR', value: 'E' })):
                { label: 'ERROR', value: 'E' },
            gridPaging: {
                currentPageNumber: 1,
                recordsPerPage: 50,
                totalRecordCount: 0
            },
            showModalConfirm: false,
            showTooltip: false
        }
        bindAll(this, ['handlePaging', 'handlePagingConfirm']);
        this.pager = React.createRef();
    }

    getSnapshotBeforeUpdate(prevProps: IComponentProps, prevState: IComponentState) {
        return { propsSnapshot: prevProps , stateSnapshot: prevState}
    }

    componentDidUpdate(prevProps: IComponentProps, prevState: IComponentState, snapshot: { propsSnapshot: IComponentProps, stateSnapshot: IComponentState }) {
        const { policySelectionOptions: { totalPages, currentPage } } = this.props;
        const { propsSnapshot, stateSnapshot } = snapshot; 
        if (this.props.policySelectionOptions.totalPages != '0') {
            this.setState({ policySelectionOptions: this.props.policySelectionOptions, totalPages: this.props.policySelectionOptions.totalPages });
            return null;
        }
        else if (totalPages === '0' && this.state.zeroResults !=='closed') {
            this.setState({zeroResults: 'active', policySelectionOptions: stateSnapshot.policySelectionOptions, totalPages: stateSnapshot.totalPages})
        }
           
        return null
    }

  

    /**
     * Take in the current name, short description, and long description values in the search inputs and
     * pass them along in a SelectiveGetXml request to the master crud controller.
     */

    private createSearchParam(name: string, value: string | undefined, type?: string): MNSearchParam {
        return type
            ? { Name: name, Value: value, Type: type }
            : { Name: name, Value: value };
    }
    crudSearch(severity: string, description: string, pageNo: number): MNSearchRequest {
        const { gridPaging } = this.state;
        const submitPage = this.props.policySelectionOptions.totalPages === '0' ? this.state.policySelectionOptions.currentPage : pageNo;
        // Create default search request with standard values.
        const searchRequest: MNSearchRequest = {
            Param: [

            ]
        };
        searchRequest.Param.push(this.createSearchParam("@chvSeverity", severity));
        searchRequest.Param.push(this.createSearchParam("@chvDescription", description));
        searchRequest.Param.push(this.createSearchParam("@intPageNo", pageNo.toString(), 'int'));
        searchRequest.Param.push(this.createSearchParam("@intRecordsPerPage", "50", "int"));
        searchRequest.Param.push(this.createSearchParam("@intMaxPages", "100", "int"));
        this.setState({
            zeroResults: 'initial',
            gridPaging: {
                currentPageNumber: pageNo ,
                recordsPerPage: 50,
                totalRecordCount: 1
            },
            scrollToTop: true
        }, () => {
                this.props.updateMNPolicySelectionOptions({ Action: "UPDATE", Value: {currentPage: submitPage}})
                this.setState({scrollToTop: false })
        });

        return searchRequest;
    }


    public getGridColumnHeaders() {
        const columns: UiclColumn[] = [
            {
                dataName: "patientName",
                text: 'Patient name',
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "controlNo",
                text: "Control no",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "totalCharges",
                text: "Total charges",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            }
        ];
        return new Set(columns);
    }

    public highlightSelectedRow(target: any) {
        if (target != null) {
            this.deselectAllRows();
            // set selected class to grid row
            target.className = 'selected';
        }
    }

   public  deselectAllRows() {
        // clear selected row highlights from ui
        var mnPayerSelectionGrid = document.getElementById("id-mn-policy-selection-options-grid");
        if (mnPayerSelectionGrid != null) {
            var selectedRows = mnPayerSelectionGrid.querySelectorAll('.selected');
            for (var i = 0; i < selectedRows.length; i++) {
                selectedRows[i].classList.remove('selected');
            }
        }
    }

    public getCustomHeader() {

        const columnNames = [{ value: 'policyId', text: 'PolicyId' },
        { value: 'description', text: 'Description' },
        { value: 'severity', text: 'Severity' }]

        const options = columnNames.map(x => <option key={x.value} value={x.value}>
            {x.text}
        </option>);

        const header = () => <thead><tr key={Math.random()}>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="policyId"
                        text="PolicyId"
                        cellType="text"
                    // choose from String, Number or Date
                    />
                    <ARMHeaderCell
                        dataName="description"
                        text="Description"
                        cellType="text"
                    />
                    <ARMHeaderCell
                        dataName="severity"
                        text="Severity"
                        cellType="text"
                    />
                </React.Fragment>
            }
        </tr></thead>

        return header;
    }

    public updateSeverity(e: any, record: any) {
        const newRecord = Object.assign({}, record);
        newRecord["@Severity"] = e.target.value
        this.props.updateMNPolicySelectionOptions({ Action: 'UPDATE', Value: { record: newRecord, currentPage: this.state.gridPaging.currentPageNumber } })
    }
    
    

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        if (event && event.currentTarget)
            this.highlightSelectedRow(event.currentTarget);
    }

    public getCustomRow() {
        const row = ({ record }: RowProps, index: number) =>
            <tr id={index.toString()} onClick={(x) => this.onRowClick(x)}>
                {
                    <React.Fragment>
                        <ARMRowCell value={<a target="_blank" href={record['@Reference']}> {record['@PolicyNumber'].slice(0, 9).trim()} </a>} />
                        <ARMRowCell displayTooltip={true} value={record['@Description']} style={{ width: 'fit-content', height: 15, overflow: 'hidden', wordBreak: 'break-all' }} />
                        <SelectComponent
                            width={'95px'}
                            size={1}
                            selectedValue={this.formatDropdownVal(record['@Severity']).value}
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateSeverity(e, record)}
                            records={[{ text: 'ERROR', value: 'E' }, { text: 'WARNING', value: 'W' }]}
                            />
                    </React.Fragment>
                }
            </tr>
        return row;
    }

    public formatDropdownVal(severity: string) {
        return {
            text: severity === 'W' ? 'WARNING' : 'ERROR',
            value: severity === 'W' ? 'W' : 'E'
        }
    }

    public handlePagingConfirm() {
        const { policyPageChanges } = this.props.policySelectionOptions;
        if (policyPageChanges && policyPageChanges.length > 0)
            return true;

        return false;
    }
     

    public handlePaging(pageNumber: number, confirmedFromModal?: boolean) {
        const {
            gridPaging,
            totalPages
        } = this.state;

        if (this.handlePagingConfirm() && !confirmedFromModal) {
            this.setState({ showModalConfirm: true })
        }
        else if (this.props.policySelectionOptions.totalPages != '0' || totalPages != '0') {

            this.setState({
                zeroResults: 'initial',
                gridPaging: {
                    currentPageNumber: pageNumber,
                    recordsPerPage: gridPaging.recordsPerPage,
                    totalRecordCount: gridPaging.totalRecordCount
                },
                scrollToTop: true
                // scroll to top of new grid page
            }, () => {
                    this.props.updateMNPolicySelectionOptions({ Action: "UPDATE", Value: { currentPage: pageNumber, totalPages } })
                    this.props.searchMNPolicyDescription(
                        this.crudSearch(this.state.severity.value,
                            this.state.description,
                            pageNumber));
                this.setState({ scrollToTop: false })
            });
        }
        else if (Number(this.props.policySelectionOptions.totalPages) <= 1) {
            return
        }
        
    }
      

      
    


    public render() {
        const { gridPaging, severity, totalPages, policySelectionOptions } = this.state;
        const pageCount = this.props.policySelectionOptions.totalPages === '0' ? totalPages : this.state.policySelectionOptions.totalPages;
        let recordsToRender: any[] = this.state.policySelectionOptions.policySearchResults.MNPolicy;
        return (
            <DialogFieldset width={'900px'}>
                <DialogLegend3 helpUrl={'/Support/Help/HELP_Maint_ClaimOptions_MNTab.htm'}> Policy Selection </DialogLegend3>
                <ContentColumnWrapper>
                    <ContentRowWrapper style={{ alignItems: 'center', marginBottom: '5px' }}>
                        <p style={{
                            position: 'relative',
                            right: '5px',
                            display: 'block',
                            marginBottom: '0.3rem',
                            color: '#37474F',
                            fontSize: '11px',
                            lineHeight: '1.4em',
                            fontWeight: 'bold',
                            marginLeft: '5px',
                            letterSpacing: '0.05em',
                            textTransform: 'uppercase',
                            fontFamily: 'Arial'
                        }}>
                            DESCRIPTION:
                                    </p>
                        <div style={{ width: '175px', flex: 'initial' }}>

                            <Input
                                domID="description-input"
                                forceuppercase="true"
                                maxLength={128}
                                initialValue={this.state.description}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    this.props.onLocalStateChange({
                                        description: e.target.value,
                                        selectedSeverity: this.state.severity
                                    });
                                    this.setState({ description: e.target.value });
                                }
                                }
                            />
                        </div>
                        <ContentRowWrapper style={{ flexDirection: 'row-reverse', position: 'absolute', width: '318px', alignItems: 'center', right: '15%' }}>
                            <Button
                                domID="clear-id"
                                name="CLEAR"
                                size="small"
                                buttonType="standard"
                                type="button"
                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                    this.props.onLocalStateChange({ description: '', selectedSeverity: this.state.severity });
                                    this.setState({ description: '' })
                                }
                                }
                            />
                            <div style={{ marginLeft: '5px', marginRight: '5px' }}
                            >
                                <Button
                                    domID="searchbutton-id"
                                    name='SEARCH'
                                    size="small"
                                    buttonType="standard"
                                    type="button"
                                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                        this.props.searchMNPolicyDescription(
                                            this.crudSearch(this.state.severity.value,
                                                this.state.description,
                                                1),
                                        )
                                    }}
                                />
                            </div>
                            <div style={{ width: '270px' }}>
                                <SelectDropdownV2
                                    domID="severity-dropdown"
                                    className="dropdown"
                                    isClearable={false}
                                    isSearchable={false}
                                    width={'115px'}
                                    onChange={(e: any /*React.ChangeEvent<HTMLSelectElement>*/) => {
                                        this.props.onLocalStateChange({
                                            description: this.state.description,
                                            selectedSeverity: e.value,
                                        });
                                        this.setState({ severity: e })
                                    }
                                    }
                                    options={[{ label: 'ERROR', value: 'E' }, { label: 'WARNING', value: 'W' }, { label: 'BOTH', value: 'B' }]}
                                    value={severity}
                                    initialValue={severity}
                                />
                            </div>
                            <p style={{
                                position: 'relative',
                                right: '5px',
                                display: 'block',
                                marginBottom: '0.3rem',
                                color: '#37474F',
                                fontSize: '11px',
                                lineHeight: '1.4em',
                                fontWeight: 'bold',
                                marginLeft: '5px',
                                letterSpacing: '0.05em',
                                textTransform: 'uppercase',
                                fontFamily: 'Arial'
                            }}>
                                SEVERITY:
                                    </p>
                        </ContentRowWrapper>
                    </ContentRowWrapper>
                    <GridWrapper>
                        <Grid
                            records={recordsToRender}
                            columns={this.getGridColumnHeaders()}
                            domID='id-mn-policy-selection-options-grid'
                            maxHeight='400px'
                            headerComponent={this.getCustomHeader()}
                            rowComponent={this.getCustomRow()}
                            emptyGridMessage=' '
                            // grid works without selectionKey
                            // but uicl grid would throw warning
                            selectionKey='@PolicyId'
                            scrollToTop={this.state.scrollToTop}
                        />
                    </GridWrapper>
                    <div style={{ marginTop: 5, flexGrow: 1, textAlign: 'right', position: 'relative', left: 20, top: -7 }}>
                        {recordsToRender.length ? <ARMGridPager
                            ref={this.pager}
                            currentPageNumber={this.props.policySelectionOptions.currentPage}
                            numberOfPages={pageCount}
                            pagingHandler={this.handlePaging}
                        /> : null }
                    </div>
                </ContentColumnWrapper>
                <ModalConfirmation
                    isOpen={this.state.showModalConfirm}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ showModalConfirm: false })}
                    formattedMessage={(
                        <div style={{ textAlign : 'center'}}>
                            <p> Do you want to search? The content of the page has changed. If you search, the changes will be gone.</p>
                        </div>)
                    }
                  
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.pager.current.props.pagingHandler(this.handlePaging(gridPaging.currentPageNumber, true))
                        const currentPageNumber = this.pager.current.state.forwardClicked ? gridPaging.currentPageNumber + 1 : gridPaging.currentPageNumber - 1
                        this.props.searchMNPolicyDescription(
                            this.crudSearch(this.state.severity.value,
                                this.state.description,
                                currentPageNumber));
                        this.props.updateMNPolicySelectionOptions({ Action: 'UPDATE', Value: {currentPage: currentPageNumber}})
                        this.setState({
                            showModalConfirm: false, 
                            gridPaging: {
                                currentPageNumber,
                                recordsPerPage: gridPaging.recordsPerPage,
                                totalRecordCount: gridPaging.totalRecordCount
                            }})
                    }}
                />
                <ModalConfirmation
                    isOpen={this.state.zeroResults === 'active'}
                    alertMode={true}
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ zeroResults: 'closed' })}
                    formattedMessage={(
                        <div style={{ textAlign: 'center' }}>
                            <p> No policies found. </p> 
                        </div>)
                    }
                    onOK={() => {
                        this.setState({ zeroResults: 'closed' }, () => {
                            this.props.updateMNPolicySelectionOptions({ Action: "UPDATE", Value: { currentPage: this.state.policySelectionOptions.currentPage, totalPages: this.state.totalPages}})
                        })
                    }}
                    okText='OK'
                  
                />
             
            </DialogFieldset >
        );
    }
};
