import * as React from 'react';
import { CheckBoxComponent } from '../../common/CheckBox';
import styled from 'styled-components';
import { Colors } from '@commonResources/colorVariables';
import { Typography } from '@commonResources/typography';


const SelectLabel = styled.label`
  display: block;
  margin-bottom: 0.3rem;
  margin-top:0px;
  color: ${Colors.grey100};
  ${Typography.ARMFontFamily};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  ${Typography.bold};
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: left;
  padding-right: 2px;
  padding-top: 3px;

  ${(props: IArmNarrowCheckProps) => props.labelWidth && `width: ${props.labelWidth} `};


`;


export const ARMNarrowCheckWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin: 0 auto;
    cursor: pointer;
    min-width: 20px;
    min-height:25px;
    max-height:35px;    
   
    
    #enable > input{
        padding-top: 10px;
        height: 20px !important;
        padding-bottom: 1px;
    }

      
    .narrow_input {
        width: 140px;
    }


    

    ${(props: IArmNarrowCheckProps) => props.width && `width: ${props.width}  `};
    ${(props: IArmNarrowCheckProps) => props.labelWidth == '160px' && `.narrow_input { width: 280px;} `};
    


`;

export interface IArmNarrowCheckProps {
    id: string;
    label?: string;
    width?: string;
    //    disabled: boolean;
    //    hasError: boolean;
    labelWidth?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}



export class ARMNarrowCheck extends React.Component<IArmNarrowCheckProps | any, {}>
{
    static defaultProps: IArmNarrowCheckProps = {
        id: 'narrow_check_default_id'
    };

    constructor(props: IArmNarrowCheckProps) {
        super(props);
        this.state = {
        }
    }

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.onChangeSelect) {
            this.props.onChangeSelect(e);
        }
    }

    render() {


        return (

            <ARMNarrowCheckWrapper
                id={this.props.id + "_wrapper"}
                width={this.props.width}
                labelWidth={this.props.labelWidth}
            >
                <SelectLabel
                    id={this.props.id + "_label"}
                    className={this.props.classNameValue}
                    labelWidth={this.props.labelWidth}
                >
                    {this.props.label}
                </SelectLabel>
                <CheckBoxComponent
                    id={this.props.id}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChange(e)}
                    checked={this.props.checked}
                />

            </ARMNarrowCheckWrapper>
        );
    }
};
