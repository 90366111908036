import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as UserPreferencesUI from './ui/UserPreferencesUI';
import { CrudTypes } from '@commonResources/CrudTypes';

import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IUserPreferencesState = ICrudComponentState<MCARMUserPreferences, UserPreferencesUI.IUserPreferencesUIState>;

export const actionCreators = createCrudComponentActions<UserPreferencesUI.ActionCreators>(UserPreferencesUI.actionCreators);
export type IUserPreferencesActions = typeof actionCreators;
export type IUserPreferencesActionProps = ICrudComponentActionProps<IUserPreferencesActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiUserPreferences, UserPreferencesUI.reducer);
export const reducer = combineReducers<IUserPreferencesState>(reducers);

export const validationCallback: ValidationCallback<MCARMUserPreferences> = (crud: any): crud is MCARMUserPreferences => {
    let testCrud: MCARMUserPreferences = crud as MCARMUserPreferences;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCARMUserPreferences");
    validShape = validShape && ValidateJSONSchema(testCrud?.UserPreferencesMaintenanceInfo?.NotificationMaintenanceInfo?.Configuration?.Events, "MCUserPrefsEvents");

    if (validShape) {
        // VerifyArray - VerifyObject blowing up when we try to check "" type.. 
        // Favorite Filters: no leaf array element
        if (testCrud.UserPreferencesMaintenanceInfo?.FavoriteFilterMaintenanceInfo?.FavoriteFilters as any === "") {
            testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters =
                {Filter: new Array<MCUserPrefsFavoriteFilter>()};
        }
        // Favorite Filters: leaf element is not an array
        if (testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter &&
            !Array.isArray(testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter)) 
        {
            let arr = new Array<MCUserPrefsFavoriteFilter>();
            arr.push(JSON.parse(JSON.stringify(testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter)));
            testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteFilters.Filter = arr;
        }
        // Homepage Filters: no leaf array element
        if (testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters as any === "") {
            testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters =
                {Filter: new Array<MCUserPrefsFavoriteHomeFilter>()};
        }
        // Homepage Filters: leaf element is not an array
        if (testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter &&
            !Array.isArray(testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter)) 
        {
            let arr = new Array<MCUserPrefsFavoriteHomeFilter>();
            arr.push(JSON.parse(JSON.stringify(testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter)));
            testCrud.UserPreferencesMaintenanceInfo.FavoriteFilterMaintenanceInfo.FavoriteHomeFilters.Filter = arr;
        }   
        // Schedules: no leaf array element
        if (testCrud?.UserPreferencesMaintenanceInfo?.NotificationMaintenanceInfo?.Configuration?.Events as any === "") {
            testCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events =
                { Event: new Array<MCUserPrefsEvent>() };
        }
        // Schedules: leaf element is not an array
        if (testCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event &&
            !Array.isArray(testCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event)) 
        {
            let arr = new Array<MCUserPrefsEvent>();
            arr.push(JSON.parse(JSON.stringify(testCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event)));
            testCrud.UserPreferencesMaintenanceInfo.NotificationMaintenanceInfo.Configuration.Events.Event = arr;
        }    
    }
    return validShape;
}