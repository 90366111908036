export const columnsv1: any[] = [ // this prop determins what the headers say and how they behave
    {
        dataName: "@data1", //required name of row data key for the header
        text: "Hello World1!", //required display text for the header,
        width: 0 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
    },
    {
        dataName: "@data2", //required name of row data key for the header
        text: "Hello World2!", //required display text for the header
        rightAlignText: true,
        width: 200 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
    },
    {
        dataName: "@data3", //required name of row data key for the header
        text: "time", //required display text for the header
        rightAlignText: true
    }
];
export const recordsv1: any[] = [
    {
        '@data1': {
            text: '(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?',
            onClick: () => console.log("Hello World! row 1 col 1 onClick")
        },
        '@data2': { text: '1' },
        '@data3': { text: 'Fri Jun 06 1997' }
    },
    {
        '@data1': { text: 'Tue Oct 22 2024' },
        '@data2': {
            text: '(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?'
        }
    },
    {
        '@data1': { text: 'Sat Mar 09 2052' },
        '@data2': { text: 'Wed Jul 26 2079' },
        '@data3': {
            text: '(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?',
            onClick: () => console.log("Hello World! row 3 col 3 onClick")
        }
    }
];

export const columnsv2: any[] = [ // this prop determins what the headers say and how they behave
    {
        dataName: "caseId", //required name of row data key for the header
        text: "Case Id", //required display text for the header
        rightAlignText: true,
        width: 100
    },
    {
        dataName: "patientName", //required name of row data key for the header
        text: "Patient Name", //required display text for the header
        width: 200
    },
    {
        dataName: "providerName", //required name of row data key for the header
        text: "Provider Name", //required display text for the header
        width: 200
    },
    {
        dataName: "serviceFromDate", //required name of row data key for the header
        text: "Service From Date", //required display text for the header
        width: 300
    },
    {
        dataName: "varianceAmount", //required name of row data key for the header
        text: "Variance", //required display text for the header
        width: 100
    }
];
export const recordsv2: any[] = [
    {
        caseId: {
            text: '123'
        },
        patientName: {
            text: 'patient 1'
        },
        providerName: {
            text: 'provider 1'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.5,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '456'
        },
        patientName: {
            text: 'patient 2'
        },
        providerName: {
            text: 'provider 2'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.25,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '789'
        },
        patientName: {
            text: 'patient 3'
        },
        providerName: {
            text: 'provider 3'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.75,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '012'
        },
        patientName: {
            text: 'patient 4'
        },
        providerName: {
            text: 'provider 4'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.15,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '345'
        },
        patientName: {
            text: 'patient 5'
        },
        providerName: {
            text: 'provider 5'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.125,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '678'
        },
        patientName: {
            text: 'patient 6'
        },
        providerName: {
            text: 'provider 6'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.55,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '324'
        },
        patientName: {
            text: 'patient 5'
        },
        providerName: {
            text: 'provider 5'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.125,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '830'
        },
        patientName: {
            text: 'patient 5'
        },
        providerName: {
            text: 'provider 5'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.125,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '976'
        },
        patientName: {
            text: 'patient 5'
        },
        providerName: {
            text: 'provider 5'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.125,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '476'
        },
        patientName: {
            text: 'patient 5'
        },
        providerName: {
            text: 'provider 5'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.125,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '064'
        },
        patientName: {
            text: 'patient 5'
        },
        providerName: {
            text: 'provider 5'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.125,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '078'
        },
        patientName: {
            text: 'patient 5'
        },
        providerName: {
            text: 'provider 5'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.125,
            formatter: "Pie"
        }
    },
    {
        caseId: {
            text: '672'
        },
        patientName: {
            text: 'patient 5'
        },
        providerName: {
            text: 'provider 5'
        },
        serviceFromDate: {
            text: 1645122841232,
            formatter: "Date",
        },
        varianceAmount: {
            text: 0.125,
            formatter: "Pie"
        }
    }
];

export const columnsv3: any[] = [ // this prop determins what the headers say and how they behave
    {
        dataName: "@data1", //required name of row data key for the header
        text: "pneumonoultramicroscopicsilicovolcanoconiosis", //required display text for the header
        sortable: true, // enables sorting for this column
        SortDataType: "String", // required only when sorting is enabled. Valid entries are 'Date' or 'String' or 'Number'. Sorting my not work properly without this
        rightAlignText: true,
        width: 300 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
    },
    {
        dataName: "@data2", //required name of row data key for the header
        text: "Payer Search Ranking", //required display text for the header
        sortable: true, // enables sorting for this column
        SortDataType: "Number", // required only when sorting is enabled. Valid entries are 'Date' or 'String' or 'Number'. Sorting my not work properly without this
        width: 100 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
    },
    {
        dataName: "@data3", //required name of row data key for the header
        text: "time", //required display text for the header
        sortable: true, // enables sorting for this column. Remember to also set a SortDataType or sorting will not work properly
        SortDataType: "Date", // required when sorting is enabled. The only valid entries are 'Date' or 'String' or 'Number'. Sorting may not work properly without this.
        width: 200 // this optional key lets you manually set the width of the column but header text length overrules this control. So make sure your header isn't too long
    }
];
export const recordsv3: any[] = [
    {
        '@data0': { text: '1' },
        '@data1': {
            text: '(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?(the) quick & brown fox, * jumped over[the] lazy dog.!?'
        },
        '@data2': { text: 0.25551503216192684 },
        '@data3': { text: 'Fri Jun 06 1997' }
    },
    {
        '@data0': { text: '3' },
        '@data1': { text: '5Tue Oct 22 2024' },
        '@data2': { text: 3.0823536149562445 },
        '@data3': { text: 'Sat Mar 09 2052' }
    },
    {
        '@data0': { text: '5' },
        '@data1': { text: 'Wed Jul 26 2079' },
        '@data2': { text: 5.049963853859925 },
        '@data3': { text: 'Sun Dec 12 2106' }
    },
    {
        '@data0': { text: '7' },
        '@data1': { text: '3Thu Apr 29 2134' },
        '@data2': { text: 1.024476241244951 },
        '@data3': { text: 'Mon Sep 14 2161' }
    },
    {
        '@data0': { text: '9' },
        '@data1': { text: 'Fri Jan 30 2189' },
        '@data2': { text: 6.462326435438075 },
        '@data3': { text: 'Tue Jun 18 2216' }
    },
    {
        '@data0': { text: '11' },
        '@data1': { text: '@Sat Nov 04 2243' },
        '@data2': { text: 10.707370350859915 },
        '@data3': { text: 'Wed Mar 22 2271' }
    },
    {
        '@data0': { text: '13' },
        '@data1': { text: 'Sun Aug 07 2298' },
        '@data2': { text: 6.149817312373628 },
        '@data3': { text: 'Thu Dec 24 2325' }
    },
    {
        '@data0': { text: '15' },
        '@data1': { text: 'Mon May 11 2353' },
        '@data2': { text: 0.6682197948072748 },
        '@data3': { text: 'Fri Sep 26 2380' }
    },
    {
        '@data0': { text: '17' },
        '@data1': { text: 'Tue Feb 12 2408' },
        '@data2': { text: 8.826094211435073 },
        '@data3': { text: 'Sat Jun 30 2435' }
    }
];