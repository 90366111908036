import {combineReducers} from 'redux';
import {
    createCrudComponentActions,
    createCrudComponentReducers,
    ICrudComponentActionProps,
    ICrudComponentState
} from '@scripts/util/CrudComponentHelpers';
import * as FavoriteFilterUI from './ui/FavoriteFilterUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type IFavoriteFilterState = ICrudComponentState<MCARMFilter, FavoriteFilterUI.IFavoriteFilterUIState>;

export const actionCreators = createCrudComponentActions<FavoriteFilterUI.ActionCreators>(FavoriteFilterUI.actionCreators);
export type IFavoriteFilterActions = typeof actionCreators;
export type IFavoriteFilterActionProps = ICrudComponentActionProps<IFavoriteFilterActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiAssignFavoriteFilterMaster, FavoriteFilterUI.reducer);
export const reducer = combineReducers<IFavoriteFilterState>(reducers);


export const validationCallback: ValidationCallback<MCARMFilter> = (crud: any): crud is MCARMFilter => {
    let testCrud: MCARMFilter = crud as MCARMFilter;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCARMFilter");
    if (validShape) {

        if (testCrud.AssignFavorites?.Users as any === "") {
            testCrud.AssignFavorites.Users = { User: new Array<MCFilterUser>() };
        }

        if (testCrud.AssignFavorites.Users &&
            !Array.isArray(testCrud.AssignFavorites.Users.User)) {
            let arr = new Array<MCFilterUser>();
            arr.push(JSON.parse(JSON.stringify(testCrud.AssignFavorites.Users.User)));
            testCrud.AssignFavorites.Users.User = arr;
        }

        if (testCrud.AssignFavorites?.Groups as any === "") {
            testCrud.AssignFavorites.Groups = { Group: new Array<MCFilterGroup>() };
        }

        if (testCrud.AssignFavorites.Groups &&
            !Array.isArray(testCrud.AssignFavorites.Groups.Group)) {
            let arr = new Array<MCFilterGroup>();
            arr.push(JSON.parse(JSON.stringify(testCrud.AssignFavorites.Groups.Group)));
            testCrud.AssignFavorites.Groups.Group = arr;
        }

        if (testCrud.AssignFavorites?.FilterList as any === "") {
            testCrud.AssignFavorites.FilterList = { Filter: new Array<MCSavedFilter>() };
        }

        if (testCrud.AssignFavorites.FilterList &&
            !Array.isArray(testCrud.AssignFavorites.FilterList.Filter)) {
            let arr = new Array<MCSavedFilter>();
            arr.push(JSON.parse(JSON.stringify(testCrud.AssignFavorites.FilterList.Filter)));
            testCrud.AssignFavorites.FilterList.Filter = arr;
        }
        
        // each user object - favorites and homepages check structure
        testCrud.AssignFavorites.Users.User.forEach(user => {
            // favorites, not an element
            if(user.Favorites as any === "") {
                user.Favorites = {Favorite: new Array<MCFavoriteFilter>()};
            }
            // favorites: not an array
            if(!Array.isArray(user.Favorites.Favorite)) {
                let arr = new Array<MCFavoriteFilter>();
                arr.push(JSON.parse(JSON.stringify(user.Favorites.Favorite)));
                user.Favorites.Favorite = arr;
            }
            
            
            // homepages, not an element
            if(user.HomePages as any === "") {
                user.HomePages = {HomePage: new Array<MCHomePageFilter>()};
            }
            // homepages: not an array
            if(!Array.isArray(user.HomePages.HomePage)) {
                let arr = new Array<MCHomePageFilter>();
                arr.push(JSON.parse(JSON.stringify(user.HomePages.HomePage)));
                    user.HomePages.HomePage = arr;
            }
         
        });
        
        // each group object - favorites and homepages check structure
        testCrud.AssignFavorites.Groups.Group.forEach(group => {
            // favorites, not an element
            if(group.Favorites as any === "") {
                group.Favorites = {Favorite: new Array<MCFavoriteFilter>()};
            }
            // favorites: not an array
            if(!Array.isArray(group.Favorites.Favorite)) {
                let arr = new Array<MCFavoriteFilter>();
                arr.push(JSON.parse(JSON.stringify(group.Favorites.Favorite)));
                group.Favorites.Favorite = arr;
            }

            // homepages, not an element
            if(group.HomePages as any === "") {
                group.HomePages = {HomePage: new Array<MCHomePageFilter>()};
            }
            // homepages: not an array
            if(!Array.isArray(group.HomePages.HomePage)) {
                let arr = new Array<MCHomePageFilter>();
                arr.push(JSON.parse(JSON.stringify(group.HomePages.HomePage)));
                group.HomePages.HomePage = arr;
            }

        });
    }
    return validShape;
}