import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ISelectClientUIState {
    selectedClientId: string;
    selectedClientName: string;

    isBusy: boolean;
    changed: boolean;
}

export interface ISelectClientUIData {
    index: string;
    value: boolean;
};

interface ClientInfo {
    '@ClientId': string;
    '@Name': string;
};

interface UserClients {
    ClientsInfo: ClientInfo[];
}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

interface ISelectClientData {
    clientId: string;
    clientName: string;
};

export const defaultSelectClientData: ISelectClientData = {
    clientId: '',
    clientName: '',
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: ISelectClientUIState = {
    selectedClientId: '',
    selectedClientName: '',
    isBusy: false,
    changed: false,
};

export interface ISelectClient extends IApiActionData<UserClients, ISelectClientData> { }

export interface ISelectClient_FieldData extends IApiActionData<ISelectClientUIData> { }

export const actionCreators = {
    selectClient: (selectInfo: ISelectClient) => createDataAction('SELECT_CLIENT', selectInfo),
    setBusy: (fieldInfo: ISelectClient_FieldData) => createDataAction('SETBUSY', fieldInfo),
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ISelectClientUIState, KnownActions> = (state: ISelectClientUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {

            case 'SELECT_CLIENT':
                let newStateData: ISelectClientUIState = { ...state };
                newStateData.selectedClientId = action.data.uiData.clientId;
                newStateData.selectedClientName = action.data.uiData.clientName;
                return newStateData;

            case 'SETBUSY':
                return {
                    ...state,
                    isBusy: action.data.uiData.value,
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}

