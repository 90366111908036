import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '../../commonResources/typography';
import { Colors } from '../../commonResources/colorVariables';
//import { BoxShadows } from '../../commonResources/boxShadows';
import { Borders } from '../../commonResources/borders';
import { Button } from 'ui-core';
import { IImageButtonImage, ImageButton } from '../common/ImageButton';
import { ModalLoading } from '@common/ModalLoading'
import { ICheckBoxComponentProps } from '@common/CheckBox';
import * as wind from '../../commonResources/window';
import { getRawToken } from '@scripts/session/SecurityToken';

import ARMHelpIconOff from '../../content/images/Buttons/But_HelpOff.png';
import ARMHelpIconUp from '../../content/images/Buttons/But_HelpUp.png';
import ARMHelpIconOver from '../../content/images/Buttons/But_HelpOver.png';
import ARMHelpIconDown from '../../content/images/Buttons/But_HelpDown.png';

import { parseXML } from '@commonResources/xBrowserXMLLib';
import { URLs } from '@commonDevResources/constants';

interface IARMLandingPageHeaderContainerProps {
    domId?: string;
    widthValue?: string;
    heightValue?: string;
}

interface IARMLandingPageBodyContainerProps {

    isLandingPage?: boolean;
    isBillerView?: boolean;
}

export const CenteredContent = styled.div`
    display: block;
    text-align: center;
`;

//${BoxShadows.lightFade};
export const ARMLandingPageHeaderContainer = styled.div.attrs((props: IARMLandingPageHeaderContainerProps) => {
    var idObj = (props.domId) ?
        {
            id: props.domId
        } : {};
    return { ...idObj };
})`
    position: relative;
    display: inline-flex;
    flex-flow: column nowrap;
    align-items: stretch;
    //margin-top: 40px;
    text-align: left;
    cursor: pointer;
    ${(props: IARMLandingPageHeaderContainerProps) => props.widthValue && `width: ${props.widthValue}`};
    ${(props: IARMLandingPageHeaderContainerProps) => props.heightValue && `height: ${props.heightValue}`};
`;

export const ARMLandingPageHeaderCaption = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: ${Colors.darkestSecondaryGrey};
`;

export const ARMLandingPageHeaderCaptionText = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    ${Typography.mediumLarge};
    ${Typography.defaultLineHeight};
    color: ${Colors.white};
    padding: 10px;
    text-align: left;
    letter-spacing: 1px;
    padding-right: 70px
`;

export const ARMLandingPageHeaderCaptionMiddle = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.default};
    ${Typography.defaultLineHeight};
    color: ${Colors.transparentWhite};
    padding: 10px;
    text-align: left;
    letter-spacing: 1px;
`;

export const ARMLandingPageHeaderCaptionRightSide = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: flex-end;
    background-color: ${Colors.darkestSecondaryGrey};
`;

export const ARMLandingPageHeaderFooterRightSide = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: flex-end;
    background-color: ${Colors.darkestSecondaryGrey};
`;

export const ARMLandingPageHeaderCaptionHelp = styled.div`
    ${Typography.ARMFontFamily};
    ${Typography.bold};
    ${Typography.large};
    ${Typography.defaultLineHeight};
    color: ${Colors.white};
    padding: 0px;
    text-align: right;

    .help-button {
        padding: 0px;
        padding-top: 10px;
        padding-right: 10px;
    }
`;


export const ARMLandingPageHeaderBody = styled.div` 
    ${Typography.ARMFontFamily};
    padding: 5px 5px 0px 5px;
    
    display: flex;
    justify-content: center;
    border-collapse: collapse;
  
   
    ${(props: IARMLandingPageBodyContainerProps) => (props.isLandingPage == undefined || props.isLandingPage == false) && `border: 1px solid #0F0F59;`};
    ${(props: IARMLandingPageBodyContainerProps) => (props.isLandingPage != undefined && props.isLandingPage == true) && `border: 1px solid #c3c5cd;`};


    ${(props: IARMLandingPageBodyContainerProps) => (props.isLandingPage == undefined || props.isLandingPage == false) && `padding: 5px;`};
    ${(props: IARMLandingPageBodyContainerProps) => props.isLandingPage && props.isLandingPage == true && props.isBillerView && props.isBillerView == true && `padding: 0px !important;`};


    ${(props: IARMLandingPageBodyContainerProps) => (props.isLandingPage == undefined || props.isLandingPage == false) && `${Borders.darkestSecondaryGrey}`};
    ${(props: IARMLandingPageBodyContainerProps) => props.isLandingPage && props.isLandingPage == true && `border-style: none; border-width: 0px 0px 1px 1px;border-color: ${Colors.grey30};`};

    ${(props: IARMLandingPageBodyContainerProps) => (props.isLandingPage == undefined || props.isLandingPage == false) && `background-color: ${Colors.grey10}`};
    ${(props: IARMLandingPageBodyContainerProps) => props.isLandingPage && props.isLandingPage == true && `background-color: ${Colors.white}`};


`;

export const ARMLandingPageHeaderFooter = styled.div`
    ${Typography.ARMFontFamily};
    background-color: ${Colors.darkestSecondaryGrey};
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: center;
    padding: 1px;
    height: 10px;
    max-height: 10px;
    width: 100%;

    button {
        margin: 4px;
        width: 110px;
        text-align: center;
    }
`;

export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const ContentColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

export const ARMLandingPageHeaderInstruction = styled.div`
    display: inline-block;
    ${Typography.ARMFontFamily};
    ${Typography.mediumLarge};
    color: ${Colors.black};
    background-color: ${Colors.grey15};
    ${Borders.grey50}
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 2px;
    text-align: left;
    width: 80%;

    .instructionRed{
        color: ${ Colors.darkRed};
        ${ Typography.ARMFontFamily};
        ${ Typography.bold};
    }
`;


interface IARMLandingPageHeaderWrapperProps {
    title: string;
    instruction?: string | JSX.Element;
    buttons?: JSX.Element;
    checkboxes?: JSX.Element;
    titleButtons?: JSX.Element;
    link?: JSX.Element;
    helpUrl?: string;
    helpCallback?(): void;
    width?: string;
    height?: string;
    isBusy?: boolean;
    domId?: string;
    isLandingPage?: boolean;
    isBillerView?: boolean;
    isLegacy?: boolean; //TODO - Fix with ARMMessageComponent's render
};

interface IARMLandingPageHeaderWrapperState {
    ebError?: any | null;
    ebInfo?: any | null;
    productUpdateShow: boolean;
    errorCode?: number;
    xmlRSSList: Document | null;
    displayProductUpdatesBadge: boolean;
    //Loading: boolean;
};


export interface IARMLandingPageHeaderButtonProps {
    domID: string;
    name: string;
    onClick: React.ChangeEventHandler<HTMLButtonElement>;
    disable: boolean;
};

export interface IARMLandingPageHeaderCheckboxProps extends ICheckBoxComponentProps {
};

export const ARMLandingPageHeaderButton: React.FunctionComponent<IARMLandingPageHeaderButtonProps> = ({ domID, name, disable, onClick/* will this work?, ...restProps*/ }) => {
    return (
        <Button
            domID={domID}
            name={name}
            disabled={disable}
            buttonType="deEmphasizedReversed"
            size="small"
            type="button"
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => onClick(e)} />
    );
};

export const ARMLandingPageHeaderButtonOK: React.FunctionComponent<Partial<IARMLandingPageHeaderButtonProps>> = ({ domID = "okButton", name = "OK", disable = false, onClick }) => {
    return (
        <ARMLandingPageHeaderButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export const ARMLandingPageHeaderButtonCancel: React.FunctionComponent<Partial<IARMLandingPageHeaderButtonProps>> = ({ domID = "cancelButton", name = "Cancel", disable = false, onClick }) => {
    return (
        <ARMLandingPageHeaderButton
            domID={domID}
            name={name}
            disable={disable}
            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => { if (onClick) onClick(e) }} />
    );
};

export interface IDefaultButtonProps {
    onClickReadMessages: React.ChangeEventHandler<HTMLButtonElement>;
    onClickRefresh: React.ChangeEventHandler<HTMLButtonElement>;
    disableReadMessages?: boolean;
    disableRefresh?: boolean;
};

export const OKCancelButtons: React.FunctionComponent<IDefaultButtonProps> = (props) => {
    return (
        <React.Fragment>
            <ARMLandingPageHeaderButtonOK onClick={props.onClickReadMessages} disable={props.disableReadMessages || false} />
            <ARMLandingPageHeaderButtonCancel onClick={props.onClickRefresh} disable={props.disableRefresh || false} />
        </React.Fragment>
    );
}

export class ARMLandingPageHeaderWrapper extends React.Component<IARMLandingPageHeaderWrapperProps, IARMLandingPageHeaderWrapperState>
{
    componentDidMount() {
        this.getRSSConfigKeys();
    }

    componentDidCatch(ebError: any, ebInfo: any) {
        // Catch errors in any components below and re-render with error message
        console.log('debuke says: componentDidCatch()');
        this.setState({
            ebError: ebError,
            ebInfo: ebInfo,
            productUpdateShow: true
        })
        // You can also log error messages to an error reporting service here
    }

    static defaultProps: IARMLandingPageHeaderWrapperProps = {
        title: '',
    };

    static helpIcon: IImageButtonImage = {
        Off: ARMHelpIconOff,
        Up: ARMHelpIconUp,
        Over: ARMHelpIconOver,
        Down: ARMHelpIconDown,
    }

    constructor(props: IARMLandingPageHeaderWrapperProps) {
        super(props);
        this.state = {
            ebError: null,
            ebInfo: null,
            productUpdateShow: false,
            errorCode: undefined,
            xmlRSSList: null,
            displayProductUpdatesBadge: false,
        };
    }

    public getInstruction() {
        return this.props.instruction ? <ARMLandingPageHeaderInstruction>{this.props.instruction}</ARMLandingPageHeaderInstruction> : null;
    }

    public getHelpIcon() {
        if (this.props.helpUrl || this.props.helpCallback) {
            return (
                <ARMLandingPageHeaderCaptionHelp>
                    <ImageButton
                        Title={this.props.title + 'HelpIcon'}
                        image={ARMLandingPageHeaderWrapper.helpIcon}
                        className="help-button"
                        onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickHelpIcon(e)} />
                </ARMLandingPageHeaderCaptionHelp>
            );
        }
        return null;
    }

    public onClickHelpIcon(e: React.ChangeEvent<HTMLButtonElement>) {
        e.preventDefault();
        if (this.props.helpUrl) wind.BigHelp(this.props.helpUrl);
        if (this.props.helpCallback) this.props.helpCallback();
    }

    getRSSConfigKeys() {
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    var rssConfig = JSON.parse(responseData);
                    this.BuildFeedUrl(rssConfig.RSSURLConfig, rssConfig.RSSDBScale, rssConfig.RSSInternal, rssConfig.RSSSilo, rssConfig.RSSDaysLimit, rssConfig.RSSClient);
                }
                else {
                    console.warn(xhr.status + " response handling /api/data/securityInfo/rssConfiguration");
                    this.setState({ errorCode: xhr.status });
                }
            }
        };
        xhr.open('get', URLs.api + '/api/data/securityInfo/rssConfiguration', true);
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send();
    }

    BuildFeedUrl(url: string, dbscale: string, rssinternal: string, rsssilo: string, rssdayslimit: string, rssclient: string) {

        let data = "strurl=" + escape(url) + "&rssdayslimit=" + rssdayslimit + "&dbscale=" + dbscale + "&rssinternal=" + rssinternal + "&rsssilo=" + rsssilo + "&rssclient=" + rssclient;
        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = () => {
            if (xhr.readyState == xhr.DONE) {
                if (xhr.status == 200) {
                    var responseData = xhr.responseText;
                    if (responseData != null) {
                        this.checkForRSSFeeds(responseData);
                    }
                }
                else {
                    console.warn(xhr.status + " response handling /common/Shared/Includes/CacheRss.aspx");
                    this.setState({ errorCode: xhr.status });
                }
            }
        };

        xhr.open('POST', '/common/Shared/Includes/CacheRss.aspx', true);
        xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xhr.setRequestHeader('Authorization', getRawToken());
        xhr.send(data);

    }

    checkForRSSFeeds(responseData: string) {
        var xmlDoc = parseXML(responseData);
        let NodeList = xmlDoc.evaluate("/rss/channel/item", xmlDoc, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
        this.setState({
            xmlRSSList: xmlDoc,
            displayProductUpdatesBadge: (NodeList.snapshotLength > 0) ? true : false,
            productUpdateShow: (NodeList.snapshotLength > 0) ? true : false,
        });
        const pageChanged: any = sessionStorage.getItem("PageChanged")
        if (pageChanged === 'true')
            this.setState({ productUpdateShow: false })
        else if ((NodeList.snapshotLength > 0))
            this.setState({ productUpdateShow: true })

        sessionStorage.setItem('PageChanged', 'true')
    }

    modalData() {
        if (this.state.xmlRSSList) {
            let NodeList = this.state.xmlRSSList.evaluate("/rss/channel/item", this.state.xmlRSSList, null, XPathResult.ORDERED_NODE_SNAPSHOT_TYPE, null);
            let genHtml = [];

            if (NodeList.snapshotLength > 0) {
                for (let i = 0; i < NodeList.snapshotLength; i++) {
                    let node = NodeList.snapshotItem(i);
                    if (node) {
                        var title = '';
                        var dDate = '';
                        var desc = '';
                        for (var j = 0; j < node.childNodes.length; j++) {
                            switch (node.childNodes.item(j).nodeName) {
                                case "title":
                                    title = this.htmlDecode(node.childNodes.item(j).textContent);
                                    break;
                                case "dDate":
                                    dDate = this.htmlDecode(node.childNodes.item(j).textContent);
                                    break;
                                case "description":
                                    desc = this.htmlDecode(node.childNodes.item(j).textContent);
                                    break;
                            }
                        }
                        genHtml.push(<tr><td><b>{title}</b></td><td>{dDate}</td></tr>);
                        genHtml.push(<tr><td colSpan={2}>{desc}</td></tr>);
                    }
                }
                return genHtml;
            }
            else
                return <tr>No updates found, please try again later.</tr>;
        } else
            return <tr>No updates found, please try again later.</tr>;
    }

    htmlDecode(input: string | null): string {
        if (input) {
            let e = document.createElement('div');
            e.innerHTML = input;
            return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue || '';
        }
        return '';
    }

    public render() {
        if (this.state.ebError) {
            return (
                <div>
                    <h2>Something went wrong.</h2>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.ebError && this.state.ebError.toString()}
                        <br />
                        {this.state.ebInfo.componentStack}
                    </details>
                </div>
            );
        } else {
            var instruction = this.getInstruction();
            var helpIcon = this.getHelpIcon();
            let domIdName = this.props.domId ? this.props.domId : 'armLandingPageHeader-container';
            var domId =
            {
                domId: domIdName
            }
            return (
                <CenteredContent>
                    <ARMLandingPageHeaderContainer {...domId} widthValue={this.props.width} heightValue={this.props.height}>

                        <ARMLandingPageHeaderCaption className="componentTop">
                            <ARMLandingPageHeaderCaptionText onClick={() => this.setState({ productUpdateShow: false })}>{this.props.title}</ARMLandingPageHeaderCaptionText>
                            {this.props.title?.includes('Message') &&
                                < ARMLandingPageHeaderCaptionText onClick={() => {
                                    if (this.state.displayProductUpdatesBadge)
                                        this.setState({ productUpdateShow: true })
                                }}>{this.state.displayProductUpdatesBadge ? <div>Product Updates</div> : <div style={{ color: '#737d85' }}>Product Updates</div>}
                                </ARMLandingPageHeaderCaptionText>
                            }

                            <ARMLandingPageHeaderCaptionMiddle>
                                {this.props.link}
                            </ARMLandingPageHeaderCaptionMiddle>
                            <ARMLandingPageHeaderCaptionRightSide>
                                {this.props.checkboxes}
                                {this.props.titleButtons}
                                {helpIcon}
                            </ARMLandingPageHeaderCaptionRightSide>
                        </ARMLandingPageHeaderCaption>

                        {this.state.productUpdateShow && this.props.title?.includes('Message') ?
                            <div>
                                <div className="popupScrollingList" style={{ marginBottom: '2%', marginTop: '2%' }}>
                                    <table className="productUpdatesTable">
                                        <tbody>{this.modalData()}</tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <>
                                <ARMLandingPageHeaderBody isLandingPage={this.props.isLandingPage} isBillerView={this.props.isBillerView}>
                                    {this.props.children}
                                </ARMLandingPageHeaderBody>
                                {(this.props.isLandingPage == undefined || this.props.isLandingPage == false) &&
                                    <ARMLandingPageHeaderFooter>
                                        {this.props.buttons}
                                    </ARMLandingPageHeaderFooter>
                                }
                            </>
                        }

                        <ModalLoading isOpen={this.props.isBusy} />
                    </ARMLandingPageHeaderContainer>
                    <ContentColumnWrapper />
                    {instruction}
                </CenteredContent>
            );
        }
    }
};
