import * as React from 'react';
import { RouteComponentProps, Prompt, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindAll } from 'lodash';
import { Location } from 'history';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { OKCancelButtons, DialogWrapper } from '@common/DialogWrapper';
import CrudTypes from '../../../commonResources/CrudTypes';
import { ARMNarrowDropdown } from '../../common/UICLWrappers/ARMNarrowDropdown';
import { ARMNarrowInput } from '../../common/UICLWrappers/ARMNarrowInput';
import { ARMNarrowCheck } from '../../common/UICLWrappers/ARMNarrowCheckbox';
import { ISecurityAccountPolicyActions } from '@store/SecurityAccountPolicy';
import styled from 'styled-components';
import {
    IMergeCrudComponentProps,
    createCrudMapStateToProps,
    createCrudMapDispatchToProps,
    mergeCrudComponentProps,
    resetCrudComponentState
} from '@scripts/util/CrudComponentHelpers';
import { ApplicationState } from '@store/index';
import { ISecurityAccountPolicyUIState, ISecurityAccountPolicyActionProps, actionCreators, validationCallback } from
    '@store/SecurityAccountPolicy';
import { IModifySecurityAccountPolicy } from '../../../store/ui/SecurityAccountPolicyUI';
import { ICookies_Config } from "@store/ConfigData";
import { ModalConfirmation } from '../../common/ModalConfirmation';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const ContentColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

export const SelectList = styled.div`
    padding: 5px;
`;

export const SelectActions = styled.div`
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        padding: 5px;
`;
export const FormRow = styled.div`
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        width:100%;
        .margin-padding {
            margin-left: 5px;
            margin-right: 5px;
            color:black; 
        }
        .selectDropDown
        {
            margin-left: 5px;
            margin-right: 5px;
            color:black; 
            width:400px;
        }
        #TabContainerResponse
        {
            width:780px !important;
            line-height:3px;
        }
                
        `;

export const FormRowRightAlign = styled.div`
            display: flex;
            flex: 1 1 auto;
            width: 'max-content';
            justify-content:flex-end;
`;
export const FormRowLeftAlign = styled.div`
            display: flex;
            flex: 1 1 auto;
            width: 'max-content';
            justify-content:flex-start;
`;
export const FormRowHelp = styled.div`
            display: flex;
            flex: 1 auto; 
            padding-top:18px;
            justify-content:flex-start;
            #btnCategory,#btnCategory:hover,#btnStatus,#btnStatus:hover
            {
                padding-left:0px !important;
                width:35px !important;
                background-color:#ebeced !important;
                border:1px solid #ebeced;
            }
            #btnCategory svg,#btnStatus svg
            {
                width:35px;
                height:30px;
            }
`;

export const InputWrapper = styled.div`
            margin-right: 5px;
            .jQubAi
            {
            width:800px;
            height:500px;
}
`;
export const SelectButtons = styled.div`
            display: flex;
            flex: 1 1 auto;
            flex-direction: row;
            justify-content: center;
            margin-top: 11px;

            button {
                margin: 4px;
                width: 85px;
                text-align: center;
            `


interface IComponentProps {
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type ISecurityAccountPolicyProps =
    IMergeCrudComponentProps<ISecurityAccountPolicyUIState, ISecurityAccountPolicyActionProps, IOwnProps>;

interface ISelectionOption {
    label: string;
    value: string;
}

interface IRecord {
    text: string;
    value: string;
}

interface IDropDownRecords {
    [key: string]: IRecord
}

interface IClientAccountPolicy {
    '@AlphaNumeric': string;
    '@EmailRequired': string;
    '@ExpirationInterval': string;
    '@ExpirationWarning': string;
    '@IdleTimeout': string;
    '@InactivityThreshold': string;
    '@LockoutDuration': string;
    '@LockoutThreshold': string;
    '@MinLength': string;
    '@NDCIdleTimeout': string;
    '@PWHistoryCount': string;
    '@PasswordReset': string;
    '@SessionExpiration': string;
    '@SpecialChar': string;
    '@UpperLowerCase': string;
}

interface IComponentState {
    ClientAccountPolicy: IClientAccountPolicy;
    navigationConfirmed?: boolean;
    saveOnNavigate?: boolean;
    navDestination?: Location;
    checked: boolean,
    automaticLogoff: boolean
}
const defaultState: IComponentState = {
    ClientAccountPolicy: {
        '@AlphaNumeric': "0",
        '@EmailRequired': "",
        '@ExpirationInterval': "90",
        '@ExpirationWarning': "5",
        '@IdleTimeout': "90",
        '@InactivityThreshold': "90",
        '@LockoutDuration': "",
        '@LockoutThreshold': "5",
        '@MinLength': "8",
        '@NDCIdleTimeout': "90",
        '@PWHistoryCount': "5",
        '@PasswordReset': "",
        '@SessionExpiration': "",
        '@SpecialChar': "",
        '@UpperLowerCase': ""
    },
    checked: false,
    automaticLogoff: false
};

const inputStyle = { minWidth: '35px', width: '45px', paddingRight: '10px' }

const defaultSettings = {
    '@ExpirationInterval': "90",
    '@ExpirationWarning': "5",
    '@MinLength': "8",
    '@SessionExpiration': "90",
    '@PWHistoryCount': "5"
}

export class SecurityAccountPolicy extends React.PureComponent<ISecurityAccountPolicyProps, IComponentState> {
    originalData: IClientAccountPolicy | null;
    constructor(props: ISecurityAccountPolicyProps) {
        super(props);
        this.state = defaultState;
        this.hasErrors.bind(this)
        bindAll(this, [/*methods here*/]);
        this.originalData = null;
    }
    private instructions = <React.Fragment>N/A</React.Fragment>;

    public componentDidMount() {
        this.props.action.configData.getConfigData({ cookies: [], config: [{ name: "INACTIVITY_TIMEOUT_15" }] });
        this.props.action.crud.get({ crudId: CrudTypes.mctiUserAccountPolicy },
            (result: MCSecurityAccountPolicy) => {
                if (validationCallback(result)) {
                    console.log(result.ClientAccountPolicy, 'result')
                    this.originalData = result.ClientAccountPolicy
                    const checked = this.originalData['@AlphaNumeric'] == "1" ? true : false; 
                    this.setState({ ClientAccountPolicy: result.ClientAccountPolicy, checked })
                    return true;
                }
            });
    }

    public componentWillUnmount() {
        this.setState(defaultState)
    }

    // for some reason manual bind and bindAll did not work with these functions, so using alternate syntax
    public onDialogOk = () => {
        
        const hasInactivity15Flag = this.props.dataStore.configData.container.config.find((o: ICookies_Config) => o.name === "INACTIVITY_TIMEOUT_15")?.value == "1"; 
        if (hasInactivity15Flag) {
            if (parseInt(this.state.ClientAccountPolicy["@IdleTimeout"]) >= 1 && parseInt(this.state.ClientAccountPolicy["@IdleTimeout"]) <= 15)
                this.setState({ automaticLogoff: false })
            else {
                this.setState({ automaticLogoff: true })
                return;
            }
        }

        this.setState({ navigationConfirmed: true, saveOnNavigate: true },
            () => {
                if (this.hasErrors()) {
                    this.props.history.push(this.state.navDestination?.pathname || '/LandingPage');
                } else {
                    const submitData = this.validateFields();
                    submitData['@AlphaNumeric'] = this.state.checked ? '1' : '0';
                    if (this.props.dataStore.ui) {
                        this.props.action.crud.update({ crudId: CrudTypes.mctiUserAccountPolicy, data: { ClientAccountPolicy: submitData } });
                        this.props.history.push("/LandingPage");
                    }
                }
            });
    }
                 
                     

    public onDialogCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        // This will trigger the prompt to open the modal
        this.props.history.push('/LandingPage');
    }

    public onModalOk = () => {
        this.setState({ navigationConfirmed: true, saveOnNavigate: false },
            () => {
                this.props.history.push(this.state.navDestination?.pathname || '/LandingPage');
            });
    }

    public onModalClose = () => {
        this.setState({ navigationConfirmed: false, saveOnNavigate: false },
            () => {
                this.props.action.confirm.closeConfirm();
            });
    }

    private createModifyActionObject(data: string): IModifySecurityAccountPolicy {
        return {
            masterCrud: this.props.dataStore.crud.data,
            uiData: data?.trim() || '',
        }
    }

    //methods for UI gestures here
    //Customize control name, reduz method to your screen
    /*
    public onControlNameChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.action.ui.editFieldName(this.createModifyActionObject(e.target.value));
    }
    */

    isBusy = () => {
        return (this.props.dataStore.crud.dataStatus === 'REQUEST');
    }

    private okCancelButtons = <OKCancelButtons onClickOK={this.onDialogOk} onClickCancel={this.onDialogCancel} />;


    // https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
    showRouteChangeConfirm(nextLocation: Location) {
        this.setState({ saveOnNavigate: false, navDestination: nextLocation },
            () => {
                this.props.action.confirm.openConfirm({ message: '' });
            }
        );
        //suppresses native prompt modal
        return false;
    }

    public handleInputChange(e: React.ChangeEvent<HTMLInputElement>, name: string) {
        const newData: any = Object.assign({}, this.state.ClientAccountPolicy);
        newData[name] = e.currentTarget.value
        this.setState({ ClientAccountPolicy: newData });
    }

    public getDropdownOption(record: IRecord) {
        if (!record) {
            return
        }
        const selectedValue = this.state.ClientAccountPolicy['@LockoutThreshold'];
        return { text: record.text, value: record.value, selected: 'selected', disabled: undefined, title: undefined, fav: undefined }
    }

    public validateFields() {
        const { ClientAccountPolicy } = this.state;
        const validResult = Object.assign({}, ClientAccountPolicy)
        if (Number(validResult['@MinLength']) < 8) {
            validResult['@MinLength'] = '8'
        }
        if (Number(validResult['@ExpirationInterval']) > 90) {
            validResult['@ExpirationInterval'] = '90';
        } 
        return validResult;
    }
    
    public hasErrors = (): boolean | any => {
        const { ClientAccountPolicy } = this.state;
        // create dummy test object to skip the edge case where the unchecked checkbox value is '0'
        const dummyObject: any = Object.assign({}, ClientAccountPolicy);
        return Object.values(dummyObject).some((value: any) => {
            return !/^\d+$/.test(value) || value === ''
        })
    }


    public render() {
        const { ClientAccountPolicy, checked } = this.state;
        const dropDownRecords: IDropDownRecords = {
            '5': { text: '5', value: '5' },
            '6': { text: '6', value: '6' },
            '7': { text: '7', value: '7' },
            '8': { text: '8', value: '8' },
            '9': { text: '9', value: '9' },
            '10': { text: '10', value: '10' }
        }
        const { /*props*/ isDirty } = this.props.dataStore.ui;
        var instruction = <React.Fragment> <span style={{ color: 'red' }}>Note! </span> The options above do not apply to Customer Identity Access Management users. </React.Fragment>;
        return (
            <DialogWrapper title="Set Account Policy" instructionWidth={'fit-content'} instruction={instruction} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}
                helpUrl='/Support/Help/HELP_Maint_SetAccountPolicy.htm'
                buttons={this.okCancelButtons}
            >
                <ContentWrapper>
                    <ContentColumnWrapper>
                        <DialogFieldset height='100%'>
                            <DialogLegend>Password</DialogLegend>
                            <ContentRowWrapper>
                                <ContentColumnWrapper>
                                    <InputWrapper>
                                        <FormRowLeftAlign style={{ paddingLeft: '17%' }}>
                                            <ARMNarrowInput
                                                domID="minlen-input"
                                                className="text-input"
                                                label='Minimum Length:'
                                                id="min-leng"
                                                style={inputStyle}
                                                maxLength={2}
                                                initialValue={ClientAccountPolicy["@MinLength"]}
                                                onChange={(e: any) => this.handleInputChange(e, '@MinLength')}
                                            />
                                        </FormRowLeftAlign>
                                        <FormRowLeftAlign style={{ display: 'inline-flex', paddingLeft: '13%' }}>
                                            <ARMNarrowDropdown
                                                fontSize={'12px'}
                                                className="dropdown"
                                                label='Do Not Repeat For:'
                                                key="lockout-dropdown"
                                                selectedValue={ClientAccountPolicy['@PWHistoryCount']}
                                                multiple="false"
                                                height={'20px'}
                                                onSelect={(e: any) => {
                                                    this.handleInputChange(e, '@PWHistoryCount');
                                                }}
                                                records={[
                                                { text: '5', value: '5' },
                                                { text: '6', value: '6' },
                                                { text: '7', value: '7' },
                                                { text: '8', value: '8' },
                                                { text: '9', value: '9' },
                                                { text: '10', value: '10' }
                                                ]
                                                }
                                            />
                                            <p style={{ paddingLeft: '3px', fontSize: '11px', margin: '0', alignSelf: 'center ' }}> cycles </p>
                                        </FormRowLeftAlign>
                                        <FormRowLeftAlign style={{ paddingLeft: "7%" }}
>
                                            <ARMNarrowCheck
                                                id="alphanumeric"
                                                label='Force Alphanumeric:'
                                                labelWidth={'max-content'}
                                                disabled={false}
                                                checked={this.state.checked}
                                                onChangeSelect={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    this.setState({ checked: !this.state.checked })

                                                }}

                                            />
                                        </FormRowLeftAlign>
                                    </InputWrapper>
                                </ContentColumnWrapper>
                                <ContentColumnWrapper >
                                    <InputWrapper>
                                        <FormRowRightAlign>
                                            <ARMNarrowInput
                                                style={inputStyle}
                                                domID="expireAfter-input"
                                                className="text-input"
                                                id='expire-after'
                                                label='Expires After:'
                                                maxLength={3}
                                                initialValue={ClientAccountPolicy["@ExpirationInterval"]}
                                                onChange={(e: any) => this.handleInputChange(e, '@ExpirationInterval')}
                                            />
                                            <p style={{ paddingLeft: '3px', fontSize: '11px', margin: '0' }}> days </p>
                                        </FormRowRightAlign>
                                        <FormRowRightAlign>
                                            <ARMNarrowInput
                                                style={inputStyle}
                                                domID="expireWarning-input"
                                                id='expire-warn'
                                                className="text-input"
                                                label='Expiration Warning:'
                                                maxLength={2}
                                                initialValue={ClientAccountPolicy["@ExpirationWarning"]}
                                                onChange={(e: any) => this.handleInputChange(e, '@ExpirationWarning')}
                                            />
                                            <p style={{ paddingLeft: '3px', fontSize: '11px', margin: '0', alignSelf: 'center ' }}> days </p>
                                        </FormRowRightAlign>

                                    </InputWrapper>
                                </ContentColumnWrapper>

                            </ContentRowWrapper>
                        </DialogFieldset>
                        <DialogFieldset height='100%'>
                            <DialogLegend>Lockout/Inactivity</DialogLegend>
                            <ContentRowWrapper>
                                <ContentColumnWrapper>
                                    <InputWrapper>
                                        <FormRowRightAlign style={{ display: 'inline-flex', paddingLeft: '24%' }}>
                                            <ARMNarrowDropdown
                                                fontSize={'12px'}
                                                className="dropdown"
                                                label='Lockout After:'
                                                height={'20px'}
                                                key="lockout-dropdown"
                                                selectedValue={ClientAccountPolicy['@LockoutThreshold']}
                                                multiple="false"
                                                onSelect={(e: any) => {
                                                    this.handleInputChange(e, '@LockoutThreshold');
                                                }}
                                                records={[{ text: '1', value: '1' },
                                                { text: '2', value: '2' },
                                                { text: '3', value: '3' },
                                                { text: '4', value: '4' },
                                                { text: '5', value: '5' },
                                                ]}
                                            />
                                            <p style={{ paddingLeft: '3px', fontSize: '11px', margin: '0', alignSelf: 'center ' }}> Failed logins </p>
                                        </FormRowRightAlign>
                                        <FormRowRightAlign>
                                            <ARMNarrowInput
                                                labelWidth={'max-content'}
                                                style={inputStyle}
                                                domID="autologout-input"
                                                className="text-input"
                                                label='Automatic Logoff After:'
                                                maxLength={3}
                                                initialValue={ClientAccountPolicy["@IdleTimeout"]}
                                                onChange={(e: any) => this.handleInputChange(e, '@IdleTimeout')}
                                            />
                                            <p style={{ paddingLeft: '3px', fontSize: '11px', width: 'max-content', margin: '0', alignSelf: 'center ' }}> idle minutes </p>
                                        </FormRowRightAlign>

                                    </InputWrapper>
                                </ContentColumnWrapper>
                                <ContentColumnWrapper >
                                    <InputWrapper>

                                        <FormRowRightAlign>
                                            <ARMNarrowInput
                                                labelWidth={'max-content'}

                                                style={inputStyle}
                                                domID="expireWarning-input"
                                                id='expire-warn'
                                                className="text-input"
                                                label='Account Inactivity Limit:'
                                                maxLength={2}
                                                initialValue={ClientAccountPolicy["@InactivityThreshold"]}
                                                onChange={(e: any) => this.handleInputChange(e, '@InactivityThreshold')}
                                            />
                                            <p style={{ paddingLeft: '3px', fontSize: '11px', margin: '0', alignSelf: 'center ' }}> days </p>
                                        </FormRowRightAlign>


                                    </InputWrapper>
                                </ContentColumnWrapper>
                            </ContentRowWrapper>
                        </DialogFieldset>
                    </ContentColumnWrapper>
                </ContentWrapper>

                <ModalConfirmation
                    isOpen={this.state.automaticLogoff}
                    alertMode
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ automaticLogoff: !this.state.automaticLogoff })}
                    formattedMessage={(
                        <div>
                            <p>Automatic logoff must be a number between 1 and 15.</p>
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.setState({ automaticLogoff: !this.state.automaticLogoff })}
                />

            </DialogWrapper>
        );
    }
}



const connectedHoc = connect<ISecurityAccountPolicyUIState,
    ISecurityAccountPolicyActionProps,
    IOwnProps,
    ISecurityAccountPolicyProps,
    ApplicationState>(
        createCrudMapStateToProps('securityAccountPolicy'),
        createCrudMapDispatchToProps(actionCreators),
        mergeCrudComponentProps
    )(SecurityAccountPolicy);

export default withRouter(connectedHoc);
                           