import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
//import { IMergeApiComponentProps } from '../../scripts/util/ApiDataHelpers';
//import { IWorkingGroupDisplayActionProps, IWorkingGroupDisplayState } from '../../store/WorkingGroupDisplay';
import FileViewer from '../../common/FileViewer';
import { DownloadDocumentPt2 } from '@commonResources/window';
import _ from 'lodash';

interface plusContentObj {
    plusText: string,
    plusContent: string,
    id: number
}

interface IMessageBodyContentProps {
    message: string,
}

interface IComponentState {
    plusContentSelectionArray: number[],
}

export const DEFAULT_STATE: IComponentState = {
    plusContentSelectionArray: []
}

const FileUrlTextDiv = styled.div`
    cursor: pointer;
    color: blue; 
    text-decoration: underline;
    display: inline;
`;

const PlusButtontDiv = styled.div`
    cursor: pointer;
`;

export class MessageBodyContent extends React.Component<IMessageBodyContentProps, IComponentState> {
    constructor(props: IMessageBodyContentProps) {
        super(props)
        this.state = {
            ...DEFAULT_STATE
        }
    }

    extractPlusSignData = (message: string, plusIndex: number) => {
        const plusTextStartIndex = message.indexOf('/>', plusIndex) + 2
        const plusTextEndIndex = message.indexOf('<br>', plusTextStartIndex)
        const plusText = message.slice(plusTextStartIndex, plusTextEndIndex)

        const plusStartIndex = message.indexOf('<span', plusIndex)

        const plusContentStartIndex = message.indexOf('>', plusStartIndex)
        const plusContentEndIndex = message.indexOf('</span>', plusIndex)
        const plusContent = '<span' + message.slice(plusContentStartIndex, plusContentEndIndex + 7)

        return { plusText, plusContent, leftoverContentIndex: plusContentEndIndex + 7 }
    }

    getPlusSignDataArray = (message: string, plusIndex: number) => {
        let plusFound = plusIndex !== -1
        let leftoverIndex = plusIndex
        let plusContentArray: plusContentObj[] = []

        while (!!plusFound) {
            let { plusText, plusContent, leftoverContentIndex } = this.extractPlusSignData(message, leftoverIndex)
            plusContentArray = [...plusContentArray, { plusText, plusContent, id: plusContentArray.length }]
            leftoverIndex = leftoverContentIndex
            plusFound = message.indexOf('/Shared/Images/Msg_Plus.gif', leftoverIndex) !== -1
        }

        return plusContentArray
    }

    getPlusSignJSXArray = (message: string, plusIndex: number) => {
        const { plusContentSelectionArray } = this.state
        const plusContentArray = this.getPlusSignDataArray(message, plusIndex)
        return plusContentArray.map((plusContent) => {
            const plusContentSelectionArrayIndex = plusContentSelectionArray.indexOf(plusContent.id)
            if (plusContentSelectionArrayIndex !== -1) {
                const arrayWithoutSelected = [
                    ...plusContentSelectionArray.slice(0, plusContentSelectionArrayIndex),
                    ...plusContentSelectionArray.slice(plusContentSelectionArrayIndex + 1)
                ]
                return (<div key={_.uniqueId()}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <PlusButtontDiv><img src='/Shared/Images/Msg_Minus.gif' onClick={() => this.setState({ plusContentSelectionArray: arrayWithoutSelected })} /></PlusButtontDiv>
                        < div dangerouslySetInnerHTML={{ __html: plusContent.plusText }}></div>
                    </div>
                    < div dangerouslySetInnerHTML={{ __html: plusContent.plusContent }}></div>
                </div>)
            } else {
                const arrayWithSelected = [...plusContentSelectionArray, plusContent.id]
                return (<div key={_.uniqueId()} style={{ display: "flex", flexDirection: "row" }}>
                    <PlusButtontDiv><img src='/Shared/Images/Msg_Plus.gif' onClick={() => this.setState({ plusContentSelectionArray: arrayWithSelected })} /></PlusButtontDiv>
                    < div dangerouslySetInnerHTML={{ __html: plusContent.plusText }}></div>
                </div>)
            }
        })
    }

    getFileUrl = (message: string, downloadCallIndex: number, openCallIndex: number) => {
        const hasOpenCall = openCallIndex !== -1
        const hasDownloadCall = downloadCallIndex !== -1
        let fileMethodCallIndex = -1
        if (hasOpenCall) {
            fileMethodCallIndex = openCallIndex
        }
        if (hasDownloadCall) {
            fileMethodCallIndex = downloadCallIndex
        }
        const hasFileMethodCall = fileMethodCallIndex !== -1

        if (hasFileMethodCall) {
            const anchorStartTagIndex = message.indexOf('<a') !== -1 ? message.indexOf('<a') : message.indexOf('<A')
            const anchorEndTagIndex = message.indexOf('</a>') !== -1 ? message.indexOf('</a>') : message.indexOf('</A>')
            const textBeforeLink = message.slice(0, anchorStartTagIndex)
            const textAfterLink = message.slice(anchorEndTagIndex + 4)

            // ">" represents the ">" in "<A ... >"
            const fileUrlTextStartIndex = message.indexOf(">", fileMethodCallIndex)
            const fileUrlText = message.slice(fileUrlTextStartIndex + 1, anchorEndTagIndex)

            const fileUrlStartIndex = message.indexOf("'", fileMethodCallIndex)
            const fileUrlEndIndex = message.indexOf("'", fileUrlStartIndex + 1)

            if (hasOpenCall) {
                const fileUrl = '/shared/Open%3D\/' + message.slice(fileUrlStartIndex + 1, fileUrlEndIndex)
                return (
                    <div>
                        <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: textBeforeLink }} />
                        <FileViewer
                            name={
                                <FileUrlTextDiv
                                    dangerouslySetInnerHTML={{ __html: fileUrlText }}
                                />
                            }
                            key={_.uniqueId()}
                            fileLink={fileUrl}
                        />
                        <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: textAfterLink }} />
                    </div>
                )
            } else {
                const fileUrl = message.slice(fileUrlStartIndex + 1, fileUrlEndIndex)
                return (
                    <div >
                        <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: textBeforeLink }} />
                        <FileUrlTextDiv
                            style={{ display: "inline" }}
                            onClick={() => DownloadDocumentPt2(fileUrl)}
                            dangerouslySetInnerHTML={{ __html: fileUrlText }}
                        />
                        <div style={{ display: "inline" }} dangerouslySetInnerHTML={{ __html: textAfterLink }} />
                    </div >
                )
            }

        } else {
            return (< div dangerouslySetInnerHTML={{ __html: message }}></div>)
        }

    }


    render() {
        const {
            message
        } = this.props;

        const plusIndex = message.indexOf('/Shared/Images/Msg_Plus.gif')
        const messageHasPlus = plusIndex !== -1

        const downloadCallIndex = message.indexOf('DownloadDocument(\'')
        const openCallIndex = message.indexOf('OpenDocument(\'')
        const messageHasUrl = downloadCallIndex !== -1 || openCallIndex !== -1

        if (messageHasPlus) {
            const messagePlusElementIndex = message.lastIndexOf('<br>', plusIndex)
            const messageWithoutPlus = message.slice(0, messagePlusElementIndex)

            return (
                <div>
                    < div dangerouslySetInnerHTML={{ __html: messageWithoutPlus }}></div>
                    <div>
                        {this.getPlusSignJSXArray(message, plusIndex)}
                    </div>
                </div>
            )
        } else if (messageHasUrl) {
            return this.getFileUrl(message, downloadCallIndex, openCallIndex)
        } else {
            return (< div dangerouslySetInnerHTML={{ __html: message }}></div>)
        }
    }
}
