import React, { useEffect, useState, useMemo } from 'react';
import { DialogWrapper, DialogButton, FilterFourButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { Input, Typography, Button } from 'ui-core';
import { Colors } from '@commonResources/colorVariables';
import styled from 'styled-components';
import { get, uniqueId, isEmpty, has } from 'lodash';
import { CheckBoxComponent } from '@common/CheckBox';

const UpdateRequestFieldsModalContainerDiv = styled.div`
    width: 950px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
`;
const ContentWrapper = styled.div`
     width: 100%;`

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    /*padding-top: 10px;*/
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
        font-size: 10px !important;
    }
`;
const LabelDiv = styled.label`
    font-size:12px;
    margin-top: 20px;
    margin-right: 20px;
`;

interface IComponentProps {
    closeModal: () => void;
    typeOfBill: any;
    onSave: (printCommandSetInfo: any) => void;
};

export const AddTypeOfBillModal = ({ onSave, typeOfBill, closeModal }: IComponentProps) => {

    //const [isDefault, setisDefault] = useState<any>("0");
    const handleSave = (e: React.ChangeEvent<HTMLButtonElement>,) => {
        if (typeOfBill["@Name"] !== "") {
            onSave(typeOfBill)
            closeModal();
        }
        else {
            return;
        }
    }

    const handleCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        closeModal();
    }
    const AddTypeOfBill = (name: string) => {

        if (!isEmpty(name)) {
            typeOfBill["@Name"] = name;
            typeOfBill["@ID"] = name;

        }
        else {
            typeOfBill["@Name"] = "";
            typeOfBill["@ID"] = "";
        }
    }

    return (
        <DialogWrapper
            title=""

            marginTop={`0px`}
            width={`100%`}
        >
            <UpdateRequestFieldsModalContainerDiv>
                <ContentRowWrapper>
                    <LabelDiv>
                        <React.Fragment>Please enter a value.</React.Fragment>
                    </LabelDiv>
                </ContentRowWrapper>
                <ContentRowWrapper style={{ marginTop: "10px"}}>
                    <Input style={{ minWidth: "490px" }}

                        domID="enterNewValue_TextBox"
                        id="enterNewValue_TextBox"
                        className="text-input"
                        label=""
                        //maxLength={3}
                        initialValue=""
                        onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
                        }}
                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => AddTypeOfBill(e.target.value.trim())}
                    />
                </ContentRowWrapper>
                <ContentRowWrapper style={{ marginTop: "30px" }}>
                    <SelectButtons style={{ width: "246px" }}>
                        <Button
                            domID="saveNew-btn"
                            name={"OK"}
                            buttonType="standard"
                            size="small"
                            type="button"
                            onClick={handleSave}
                        />
                        <Button
                            domID="cancel-btn"
                            name={"CANCEL"}
                            buttonType="standard"
                            size="small"
                            type="button"
                            onClick={handleCancel}
                        />
                    </SelectButtons>
                </ContentRowWrapper>
            </UpdateRequestFieldsModalContainerDiv>
        </DialogWrapper >
    )
}

export default AddTypeOfBillModal;

