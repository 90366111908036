import * as React from 'react';
import styled from 'styled-components';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { Button } from 'ui-core';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { ContentColumnWrapper, ContentRowWrapper, FlexBoxModifier } from '@common/DialogWrapper';
import { bindAll } from 'lodash';
import { SelectComponent } from './SelectComponent';
import { ArrowButtons } from './ArrowButtons';

export const ArrowButtonsAlignmentAdjustment = styled.div`
    position: relative;
    left: -15px;
`;

export interface ILeftRightContentItem {
    id: string,
    text: string,
    selected: boolean,
}

export interface ILeftRightListsComponentProps {
    className?: string,
    containerTitle?: string,
    sideHeight?: string,
    sideWidth?: string,
    leftTitle: string,
    leftContents: ILeftRightContentItem[],
    rightTitle: string,
    rightContents: ILeftRightContentItem[],
    allowMovement: boolean,         // controls whether left/right buttons are allowed to be enabled
    onSelectLeftItems?(items: ILeftRightContentItem[]) : void,
    onMoveRightButtonClickedCallback?(): void,
    onSelectRightItems?(items: ILeftRightContentItem[]) : void,
    onMoveLeftButtonClickedCallback?(): void,
}

export interface ILeftRightListsComponentState {

}

export const LeftRightListsComponentContainerDiv = styled.div<Partial<ILeftRightListsComponentProps>>`
    className?: classname
    display: flex;
    flex: 1 1 auto;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
    display: flex;
    flex-direction: column;
`;

export const ButtonRow = styled.div`
    padding-bottom:2px;
`;

export class LeftRightListsComponent extends React.Component<ILeftRightListsComponentProps, ILeftRightListsComponentState> {
    constructor(props: ILeftRightListsComponentProps) {
        super(props)
        //console.log('LeftRightListsComponent props: '); 
        //console.dir(props);

        bindAll(this, ['onClickMoveLeft', 'onClickMoveRight', 'onSelectLeft', 'onSelectRight', 'getSelectedOptions']);
    }

    public onClickMoveLeft(e: React.SyntheticEvent<HTMLButtonElement>) {
        if (this.props?.onMoveLeftButtonClickedCallback) {
            this.props.onMoveLeftButtonClickedCallback();
        }
    }

    public onClickMoveRight(e: React.SyntheticEvent<HTMLButtonElement>) {
        if (this.props?.onMoveRightButtonClickedCallback) {
            this.props.onMoveRightButtonClickedCallback();
        }
    }

    public onSelectLeft(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedInLeft = this.getSelectedOptions(e);
        if (this.props?.onSelectLeftItems) {
            this.props.onSelectLeftItems(selectedInLeft);
        }
    }

    public onSelectRight(e: React.ChangeEvent<HTMLSelectElement>) {
        const selectedInRight = this.getSelectedOptions(e);
        if (this.props?.onSelectRightItems) {
            this.props.onSelectRightItems(selectedInRight);
        }
    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>): ILeftRightContentItem[] {
        if (!e.target.options || e.target.options.length === 0) return [];
        let result: ILeftRightContentItem[] = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected) {
                const newItem: ILeftRightContentItem = {
                    id: e.target.options[i].value,
                    text: e.target.options[i].text,
                    selected: e.target.options[i].selected
                }

                result.push(newItem);
            }
        }
        return result;
    }

    public renderLegend() {
        const containerTitle: string | undefined = this.props?.containerTitle;
        if (containerTitle) {
            return ( <DialogLegend>{containerTitle}</DialogLegend> )
        } else {
            return ( <div></div> )
        }
    }

    public render() {
        const leftTitle = this.props?.leftTitle ?? 'left';
        const leftContents = this.props?.leftContents ?? [];
        const selectedInLeft = leftContents.filter(i => i.selected).map(i => i.id);
        const rightTitle = this.props?.rightTitle ?? 'right';
        const rightContents = this.props?.rightContents ?? [];
        const selectedInRight = rightContents.filter(i => i.selected).map(i => i.id);
        const allowMovement = this.props?.allowMovement ?? false;
        const sideHeight = this.props?.sideHeight ?? '125px';
        const sideWidth = this.props?.sideWidth ?? '415px';
        const disabledToRight = !allowMovement || selectedInLeft.length < 1;
        const disabledToLeft = !allowMovement || selectedInRight.length < 1;

        return (
            <LeftRightListsComponentContainerDiv className={this.props.className}>
                <DialogFieldset>
                    {this.renderLegend()}
                    <FlexBoxModifier as={ContentColumnWrapper} flexGap="8px">
                        <FlexBoxModifier as={ContentRowWrapper} flexGap="8px" justifyContent="center">
                            <SelectComponent
                                label={leftTitle}
                                title='functions'
                                size={10}
                                width={sideWidth}
                                height={sideHeight}
                                optionFields={{
                                    value: "id",
                                    text: "text",
                                }}
                                records={leftContents}
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectLeft(e)}
                                selectedMultiValue={selectedInLeft}
                                multiple='true'
                            ></SelectComponent>
                            <ArrowButtonsAlignmentAdjustment>
                                <ArrowButtons
                                    paddingTop='50px'
                                    width='1px'
                                    margin='0 0 0 0'
                                    toolTipLeft=' Remove Functions(s) from Selected Group'
                                    toolTipRight=' Add Functions(s) to Selected Group'
                                    onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickMoveLeft(e)}
                                    onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickMoveRight(e)}
                                    disabled={!allowMovement}
                                    disabledToRightButton={disabledToRight}
                                    disabledToLeftButton={disabledToLeft}
                                ></ArrowButtons>
                            </ArrowButtonsAlignmentAdjustment>
                            <SelectComponent
                                label={rightTitle}
                                title='functions'
                                size={10}
                                width={sideWidth}
                                height={sideHeight}
                                optionFields={{
                                    value: "id",
                                    text: "text",
                                }}
                                records={rightContents}
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectRight(e)}
                                selectedMultiValue={selectedInRight}
                                multiple='true'
                            ></SelectComponent>
                        </FlexBoxModifier>
                    </FlexBoxModifier>
                </DialogFieldset>
            </LeftRightListsComponentContainerDiv>
        );
    };
}
