import moment from "moment";
import {padStart} from 'lodash';
import { IModalConfirmationProps } from "@components/common/ModalConfirmation";
import jsonSchema from "@scripts/../data/JSONSchema/MasterCrud.json"
import { JSONSchema7, JSONSchema7Definition } from "json-schema"
import { FormatDateAsString } from './FormatHelpers';


export function ValidateObject<T extends object>(crud: T | any, crudKeys: (keyof T)[]): crud is T {
    var result = true;
    // Limitation: Does not check for optional props.
    crudKeys.forEach((value: keyof T) => {
        result = result && (value in crud);
    });
    return result;
}

export function ValidateJSONSchema<T extends object>(crud: T | any, jsonSchemaProp: ValidMasterCrudInterfaces | ValidApiInterfaces): crud is T {
    var result = true;
    // Limitation: Does not check for optional props.
    let schema: JSONSchema7 = jsonSchema as JSONSchema7;
    var crudSchema = schema?.definitions?.[jsonSchemaProp];
    if (IsSchemaDefinition(crudSchema)) {
        result = true;
        for (let property in crudSchema?.properties) {
            if (IsPropertyRequired(crudSchema, property)) {
                result = result && (property in crud);
            }
        }
    }
    return result;
}

export function IsSchemaDefinition(schema: JSONSchema7Definition | undefined): schema is JSONSchema7 {
    return (schema as JSONSchema7)?.properties !== undefined;
}

export function IsPropertyRequired(crudSchema: JSONSchema7, property: string): boolean {
    return ((crudSchema?.required !== undefined) && (crudSchema?.required?.indexOf(property) > -1));
}

export function VerifyArray<T extends any, K extends keyof T>(crud: T, testArray: K) {
    if (crud && crud[testArray] === undefined) {
        crud[testArray] = [] as unknown as T[K];
    }
    else if (crud && !Array.isArray(crud[testArray])) {
        let obj: any = { ...crud[testArray] };
        crud[testArray] = [] as any;
        (crud[testArray] as any).push(obj);
    }
}
export function VerifyParentAndArray<T extends any, J extends keyof T, K extends keyof T[J]>(crud: T, testParent: J, testArray: K) {
    
    if (crud && (crud[testParent] === undefined || crud[testParent] as any == '')) {
        crud[testParent] = {} as any;
    }
    if (crud && crud[testParent] && crud[testParent][testArray] === undefined) {
        crud[testParent][testArray] = [] as any;
    }
    else if (crud && crud[testParent] && !Array.isArray(crud[testParent][testArray])) {
        let obj = { ...crud[testParent][testArray] };
        crud[testParent][testArray] = [] as any;
        (crud[testParent][testArray] as any).push(obj);
    }
    
}

export function ValidateArray<T extends object, >(crud: T[] | any, crudKeys: ValidMasterCrudInterfaces | ValidApiInterfaces): crud is T[] {
    var result = false;
    if (Array.isArray(crud)) {
        result = true;
        crud.forEach((value: T) => {
            result = result && ValidateJSONSchema(value, crudKeys);
        });
    }
    return result;
}

export function enforceArray<T>(node: T[] | T ): T[] {

    if (Array.isArray(node))
        return node;

    const array: T[] = [];
    return array.concat(node);
}


//switching to discriminated union
//https://www.typescriptlang.org/docs/handbook/typescript-in-5-minutes-func.html#discriminated-unions
export interface IDateRangeError {
    kind: 'dateRangeError';
    message: string;
}

export interface ICorrectedDateRangeResult {
    kind: 'correctedDateRangeError';
    newFromValue:string;
    newToValue: string;
    fieldName?: string;
}

export interface ICorrectableDateRangeResult {
    kind: 'correctableDateRangeResult';
    message: string | Function;
    modalOptions: Partial<IModalConfirmationProps>;
    newFromValue: string;
    newToValue: string;
}

export interface InoErrorResult {
    kind: 'noError';
}

export type DateRangeValidationResult =
    IDateRangeError | ICorrectedDateRangeResult | ICorrectableDateRangeResult | InoErrorResult;

export type DateRangeValidator = (dateFrom: string, dateTo: string) => IDateRangeError | ICorrectedDateRangeResult | ICorrectableDateRangeResult | InoErrorResult | false; //false indicates no problems

export const ARMSpecialDates = {
    TODAY: '00/00/0000',
    YESTERDAY: '00/01/0000',
    THREE_DAYS_AGO: '00/03/0000',
    SEVEN_DAYS_AGO: '00/07/0000',
    THIRTY_DAYS_AGO: '01/00/0000',
}

export const validateDateRange = (from: string, to: string, validators: DateRangeValidator[]): DateRangeValidationResult =>
{
    // get first result that says we need to change something based on this input
    const oops = validators.find((validator) => validator(from, to));
    let oopsResult: DateRangeValidationResult | undefined | false; 
    //that just found it, now we run it
    if (oops) {
        oopsResult = oops(from, to);
    }
    if (!oopsResult) oopsResult = { kind: 'noError' };
    // need to tell TS that for sure this is not undefined or false bc of previous line, so use as here
    return oopsResult as DateRangeValidationResult;
}

export const validateDateRangeNotEmpty: DateRangeValidator = function (dateFrom, dateTo) {
    if (dateFrom + dateTo === '') {
        return (
            {
                kind: 'dateRangeError',
                message: 'Please enter a date range.',
            }
        );
    }
    return false;
}


//note we assume we already passed validateDateRangeNotEmpty or bailed at validateDateRangeIsEmpty to get here
export const validateBothDatesPopulated:DateRangeValidator = function(dateFrom: string, dateTo: string) {
    if (!!dateFrom && !!dateTo) return false; // have values in both fields
    if (!!dateFrom) return { kind: 'correctedDateRangeError', newFromValue: dateFrom, newToValue: dateFrom }; //we have date from, use its value in both fields
    return { kind: 'correctedDateRangeError', newFromValue: dateTo, newToValue: dateTo }; //use dateTo in both fields
}

// use in cases where the date range is ambiguous, to address the specific field
export const validateBothDatesPopulatedForRange = (fieldName: string) => {
    return (dateFrom: string, dateTo: string) => {
        const result = validateBothDatesPopulated(dateFrom, dateTo);
        if (result && result.kind === 'correctedDateRangeError') return {...result, fieldName};
        return result;
    }
}

export const validateDatesInLogicalOrder:DateRangeValidator = (dateFrom, dateTo) => {
    const fromDate = ConvertStringToDate(dateFrom);
    const toDate = ConvertStringToDate(dateTo);
    if (fromDate > toDate) {
        return {
            kind: 'dateRangeError',
            message: 'From date cannot be after the thru date.'
        }
    }
    return false;
}

export const validateDatesBetweenMinAndMax = (minDate:Date, maxDate:Date, fieldName:string = ''): DateRangeValidator => {
    return function validateWithinRange(dateFrom, dateTo) {
        const fromDate = ConvertStringToDate(dateFrom);
        const toDate = ConvertStringToDate(dateTo);
        const customization = !!fieldName ? `${fieldName} dates` : 'Dates';
        if (fromDate < minDate || toDate > moment().add(1, 'days').toDate()) {
            return {
                kind: 'dateRangeError',
                message: `${customization} must be between ${moment(minDate).format('MM/DD/YYYY')} and ${moment(maxDate).format('MM/DD/YYYY')}`
            }
        }
        return false;
    }
}

const millisecsPerDay = 24 * 60 * 60 * 1000;

export const validateDateRangeSmallerThanLimit = (maxDays: number, message: string = `Please select a date range less than ${maxDays} days.`): DateRangeValidator => {
    return function(dateFrom: string, dateTo: string) {
        const fromDate = ConvertStringToDate(dateFrom);
        const toDate = ConvertStringToDate(dateTo);
        const diff = (toDate.getTime() - fromDate.getTime()) / millisecsPerDay;       // difference in days
        if (diff > maxDays - 1) {
            return { kind: 'dateRangeError', message };
        }
        return false;
    }
}

export const validateUserReallyWantsEmptyDateRangeFactory = (message: string | Function, modalOptions: Partial<IModalConfirmationProps>): DateRangeValidator => {
    return function validateUserReallyWantsEmptyDateRange(dateFrom: string, dateTo: string)
    {
        if (!dateFrom && !dateTo) {
            return {
                kind: 'correctableDateRangeResult',
                message,
                modalOptions,
                newFromValue: ARMSpecialDates.TODAY,
                newToValue: ARMSpecialDates.TODAY,
            }
        }
        return false;
    }
}

/*  Use in cases where it's valid for the date range to be empty, but if it is not
    then we need to run validateBothDatesPopulated so if one is empty we fill it in
    based on the one with data.

    BE CAREFUL, BECAUSE THIS WILL SHORT-CIRCUIT ANY OTHER VALIDATION ON THE FIELD WHEN EITHER CONDITION IS MET!!!
 */
export const validateDateRangeIsEmptyOrFull: DateRangeValidator = (dateFrom: string, dateTo: string) => {
    if (!dateFrom && !dateTo) return { kind: 'noError' } as InoErrorResult;
    if (!!dateFrom && !!dateTo) return { kind: 'noError' } as InoErrorResult;
    return false;
}

/**
 * Check against current date formats (as strings) that are aceptable, according to business rules
 * @param input
 */
export function ValidateDateString (input: string): boolean{
    let isValidDateFormat = false;

    isValidDateFormat = ValidateDateFormat(input, "M/D/YY") ||
                        ValidateDateFormat(input, "M/D/YYYY") ||
                        ValidateDateFormat(input, "MM/DD/YY") ||
                        ValidateDateFormat(input, "MM/DD/YYYY");

    return isValidDateFormat;
}

export function ValidateDateFormat (input: string, format: string): boolean {
    let isValidDateFormat = false;
    let _input = input?.trim() || "",
        _format = format?.trim() || "";

    if (_input.length < 6 || _format.length < 6) {
        return false;
    }

    //NOTE: moment.js is deprecated, and the newer, recommended library is Luxon. See https://momentjs.com/docs/ 
    //  and https://momentjs.com/docs/#/-project-status/recommendations/ and https://moment.github.io/luxon/#/
    // also note that 04/08/21 passes even if the format is M/D/YY, etc., so you might want to use other means
    // to validate your date if you need to be that picky. I didn't do a full suite of tests for all the ways to fail
    isValidDateFormat = moment(_input, _format, true).isValid();
    
    return isValidDateFormat;
}

// Create a Date from a string in the "MM/DD/YYYY" format.  Also supports ARM specific strings:
// 'Today', value: '00/00/0000' },
// 'Yesterday', value: '00/01/0000' },
// '3 days ago', value: '00/03/0000' },
// '7 days ago', value: '00/07/0000' },
// '30 days ago', value: '01/00/0000' },
export function ConvertStringToDate(input: string): Date {
    var returnDate = new Date(input);
    if (input != "") {
        if (returnDate.valueOf() != 0) {
            var today = new Date();
            var yearOffset = input.lastIndexOf("/");
            var monthOffset = input.indexOf("/");
            var dayOffset = input.indexOf("/", monthOffset + 1);

            if (yearOffset == -1 || monthOffset == -1 || dayOffset == -1) {
                return returnDate;
            }

            // need to build an actual date
            if (input.substr(yearOffset + 1) == "0000") {
                var month = input.substr(0, monthOffset);
                var day = input.substr(monthOffset + 1, dayOffset - monthOffset - 1);

                var timeDiff = Math.abs(today.getTime() - Math.abs((+month) * 30 * millisecsPerDay + (+day) * millisecsPerDay));

                // reset the value of FromDate to the actual date
                returnDate = new Date(timeDiff);
            }
        }
    }
    return returnDate;
}

// For APIs that don't understand ARM Date Format
export function convertPossibleARMDateStringToDateString(input: string): string {
    const dateParts = input.split('/');
    if (dateParts.length<3) return input; //IDK what this is
    if (dateParts[2] !== '0000') return input; //assume valid
    const armDate = ConvertStringToDate(input);
    const mm = padStart((armDate.getMonth() + 1).toFixed(), 2, '0');
    const dd = padStart(armDate.getDate().toFixed(), 2, '0');
    const yyyy = armDate.getFullYear().toFixed();
    return `${mm}/${dd}/${yyyy}`;
}


/**
 *  Adapted from legacy functions beacuse of requirement by business to be EXACTLY the same, or as close to it as possible.
 *  
 *  This function only checks the date portion (day, month, and year) of a date string or object.
 * @param dateString string representation of a date in business requested format, which is not necessarily any known standard date format
 * @param today optional date that should represent just a date without a time, or time = 00:00:00.000
 * 
 */
export function LegacyValidateDate (dateString: string, today: Date = new Date()): IValidationResponse {
    let validationResponse: IValidationResponse = {
        isValid: false,
        message: ""
    };

    var strValue = dateString?.trim() || '';

    if (strValue == "") {
        //legacy just does an empty return when the string is empty; an empty string really should be checked before invoking this function, however, an empty 
        //  string here should not trigger a failure
        return ({
            isValid: true,
            message: ''
        });
    }

    var arrDates = new Array();
    arrDates = strValue.split("/");

    if (strValue == "//" || arrDates.length != 3) {
        var intLength = strValue.length;
        if (intLength == 1 && strValue.indexOf("/") < 0) {
            dateString = "00/0" + strValue + "/0000";
            return ({
                isValid: true,
                message: dateString
            });
        }
        else if (intLength == 2 && strValue.indexOf("/") < 0) {
            if (parseInt(strValue) < 32) {
                dateString = "00/" + strValue + "/0000";
                return ({
                    isValid: true,
                    message: dateString
                });
            }
            else {
                dateString = "00/31/0000";
                return ({
                    isValid: true,
                    message: dateString
                });
            }
        }
        else if (intLength == 6 && strValue.indexOf("/") < 0) {
            dateString = strValue.substr(0, 2) + "/" + strValue.substr(2, 2) + "/" + strValue.substr(4, 2);
            let formattedDate = FormatDateAsString(dateString);
            return LegacyValidateDate(formattedDate, today); //yes, this is an actual recursive call, from legacy
        }
        else if (intLength == 8) {
            if (strValue.indexOf("/") < 0) {
                dateString = strValue.substr(0, 2) + "/" + strValue.substr(2, 2) + "/" + strValue.substr(4, 4);
                let formattedDate = FormatDateAsString(dateString);
                return ({
                    isValid: true,
                    message: formattedDate
                });
            }
            else if (strValue.charAt(2) == "/" && strValue.charAt(5) == "/") {
                if (strValue.charAt(6) == "0" && strValue.charAt(7) == "0") {
                    dateString = strValue.substr(0, 6) + "00" + strValue.substr(6, 2);
                    let formattedDate = FormatDateAsString(dateString);
                    return ({
                        isValid: true,
                        message: formattedDate
                    });
                }
                else {
                    dateString = strValue.substr(0, 6) + "20" + strValue.substr(6, 2);
                    let formattedDate = FormatDateAsString(dateString);
                    return ({
                        isValid: true,
                        message: formattedDate
                    });
                }
            }
        }
        else {
            return ({
                isValid: false,
                message: 'Please enter a valid date or clear this date field.'
            });
        }
    }
    else {
        //ValidateAgainstTodaysDate(objField, strToday, arrDates, strTime);
        //inputDate: string, today: Date
        let formattedDate = LegacyValidateAgainstTodaysDate(arrDates);
        return ({
            isValid: true,
            message: formattedDate
        });
    }

    return validationResponse;
}

export function LegacyValidateDateRange (dateString: string): IValidationResponse {
    if (dateString.length == 0) {
        return ({
            isValid: true,
            message: ''
        });
    }

    if (!LegacyIsAValidDate(dateString).isValid) {
        //alert("Invalid date.");
        //objField.focus();
        return ({
            isValid: false,
            message: 'Invalid date.'
        });
    }

    var RangeDate = new Date(dateString);
    var todaysDate = new Date();
    if (RangeDate >= todaysDate) {
        //alert("Date cannot be a future date.");
        //objField.focus();
        return ({
            isValid: false,
            message: 'Date cannot be a future date.'
        });
    }

    return ({
        isValid: true,
        message: ''
    });
}

export function LegacyIsAValidDate (strDateValue: string): IValidationResponse  {
    var datePattern = /^([1-9]|0[1-9]|1[012])\/([1-9]|0[1-9]|[12][0-9]|3[01])\/(18|19|20)\d\d$/;

    if (!strDateValue.match(datePattern)) {
        return ({
            isValid: false,
            message: ''
        });
    }

    let dateParts = strDateValue.split('/');

    let vMonth = Number(dateParts[0]);
    let vDay = Number(dateParts[1]);
    let vYear = Number(dateParts[2]);
    let leapYear = false;
    if (((vYear % 100 != 0) && (vYear % 4 == 0))
        || (vYear % 400 == 0)) {
        leapYear = true;
    }

    switch (vMonth) {
        case 2:
            if (leapYear && vDay > 29) {
                return ({
                    isValid: false,
                    message: 'Day is invalid'
                });
            }
            else if (!leapYear && vDay > 28) {
                return ({
                    isValid: false,
                    message: 'Day is invalid'
                });
            }
            break;
        case 4:
        case 6:
        case 9:
        case 11:
            if (vDay > 30) {
                return ({
                    isValid: false,
                    message: 'Day is invalid'
                });
            }
            break;
        default:
            break;
    }

    return ({
        isValid: true,
        message: ''
    });
}

/**
 *  Adapted from legacy functions beacuse of requirement by business to be EXACTLY the same, or as close to it as possible.
 *
 *  This function only checks the date portion (day, month, and year) of a date string or object.  It doesn't actually compare or validate against the current
 *  date.  Rather, it just manipulates the day, month, and year, and returns the input date as a formatted string
 * @param dateString
 * @param arrDates
 * @param today default of current date
 */
export function LegacyValidateAgainstTodaysDate (arrDates: Array<string>, today: string | Date = new Date()): string {
    let strToday = today instanceof Date ? today.toLocaleDateString() : today;
    let intYear = parseInt(arrDates[2], 10);
    let intCurrentYear = parseInt(strToday.substr(strToday.length - 2, strToday.length), 10);
    let formattedDate: string = '';

    if (intYear < 10000) {
        if (intYear < 1000) {
            if (intYear < 100) {
                if (intYear < 10) {
                    if (intYear < 1) {
                        if (arrDates[2] == "00" && arrDates[0] != "00" && arrDates[1] != "00" && arrDates[0] != "0" && arrDates[1] != "0")
                            formattedDate = arrDates[0] + "/" + arrDates[1] + "/20" + arrDates[2];
                        else
                            formattedDate = arrDates[0] + "/" + arrDates[1] + "/0000";
                    }
                    else {
                        if (intYear > intCurrentYear) {
                            formattedDate = arrDates[0] + "/" + arrDates[1] + "/190" + intYear;
                        }
                        else {
                            formattedDate = arrDates[0] + "/" + arrDates[1] + "/200" + intYear;
                        }
                    }
                }
                else {
                    if (intYear > intCurrentYear) {
                        formattedDate = arrDates[0] + "/" + arrDates[1] + "/19" + intYear;
                    }
                    else {
                        formattedDate = arrDates[0] + "/" + arrDates[1] + "/20" + intYear;
                    }
                }
            }
            else {
                formattedDate = arrDates[0] + "/" + arrDates[1] + "/2" + intYear;
            }
        }
        else {
            formattedDate = arrDates[0] + "/" + arrDates[1] + "/" + intYear;
        }
    }
    else {
        formattedDate = arrDates[0] + "/" + arrDates[1] + "/9999";
    }

    return formattedDate;
}

