import * as React from 'react';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { GridConfig, IHeaderProps, IRowProps } from './GridConfig';
import { SortHelper } from '@scripts/util/SortHelper';
import { ISortIndicator, SortDataType } from '@scripts/util/SortHelper';
import { ISampleTreeviewData } from './ISampleTreeviewData';
import styled from 'styled-components';
import { ARMHeaderCell } from '../../common/UICLWrappers/ARMHeaderCell';
import plusSymbol from './icons/plus.gif';
import minusSymbol from './icons/minus.gif';
import i from './icons/i.gif';
import l_middle from './icons/l-middle.gif';
import l_end from './icons/l-end.gif';

export const GridWrapper = styled.div`

    /* STANDARD OUTER GRID STYLE CONFIGURATION */

    div{
        display:inline;
    }
    background-color:white;
    width: 800px;
    height: 400px;
    border: black 1px solid;
    overflow-y: scroll;

    #sample-treeview-grid{

        /* STANDARD INNER GRID STYLE CONFIGURATION */

        background-color:white;
        .empty-row{
            background-color:white !important;
        }
        overflow-y:scroll;
        overflow-x:scroll;

        table tbody tr > td{
            height:32px !important;
        }

        /* ROW HOVER CONFIGURATION */

        tr:hover{
            background-color:#edf9ff;
            cursor:pointer;
        }

        /* OTHER CONFIGURATION */

        .child-header {
            background-color: white;
            pointer-events: none;
            th > div {
                white-space: nowrap;
                float: left;
                img {
                    float: left;
                }
            }
        }

        .child-row {
            cursor: default;
            img {
                float: left;
            }
        }

        .cell-style {
            height: 32px;
            display: flex;
            align-items: center;
        }

        /* primary grid row headers */

        .fileName {
            width: 200px;
        }

        .primary-header-remove {
            width: 600px;
        }

        /* primary grid rows */
        .primary-row-file-name {
            width: 200px;
        }

        .primary-row-remove {
            width: 600px;
        }

        /* child grid row headers */

        .child-header-payment-date{
            width: 195px;
        }

        .child-header-remove {
            width: 605px;
            white-space: nowrap;
        }

        /* child grid rows */
        .child-row-payment-date{
            width: 198px;
        }

        .child-row-remove{
            width: 602px;
            white-space: nowrap;
        }
    }
`;

interface ISampleHierarchyGridProps {
    treeviewData: ISampleTreeviewData,
}

interface IComponentState {
    treeviewData: ISampleTreeviewData | undefined,
    expandClicked: boolean,
    parentRemoveCheckedIds: string[],
    childRemoveCheckedIds: string[],
    parentRemoveDisabledIds: string[],
    childRemoveDisabledIds: string[],
    expandedParentIds: string[],
    inputValue: string,
    sortIndicator: ISortIndicator,
}

export const DEFAULT_STATE: IComponentState = {
    treeviewData: undefined,
    expandClicked: false,
    parentRemoveCheckedIds: [],
    childRemoveCheckedIds: [],
    parentRemoveDisabledIds: [],
    childRemoveDisabledIds: [],
    expandedParentIds: [],
    inputValue: '',
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
        sortDataType: SortDataType.String
    },
}

export class SampleGridWithRowTreeView extends React.Component<ISampleHierarchyGridProps, IComponentState> {

    constructor(props: ISampleHierarchyGridProps) {
        super(props)
        this.state = {
            ...DEFAULT_STATE,
            treeviewData: props.treeviewData
        }
        this.handleSorting = this.handleSorting.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onParentRemoveClicked = this.onParentRemoveClicked.bind(this);
    }

    handleSorting(sortIndicator: ISortIndicator) {
        const {
            treeviewData
        } = this.state;

        if (!treeviewData) return;

        let sortedTreeviewParentData = SortHelper.Sort(treeviewData.parentData, sortIndicator);

        this.setState({
            treeviewData: {
                parentData: sortedTreeviewParentData,
                childData: treeviewData.childData,
            },
            sortIndicator,
            expandedParentIds: []
        });
    }

    onRowClick(event: React.MouseEvent<HTMLTableRowElement, MouseEvent>) {
        const {
            expandedParentIds
        } = this.state;

        let newExpandedParentIds = expandedParentIds;
        const clickedId = event.currentTarget.id;

        if (newExpandedParentIds.includes(clickedId)) {
            newExpandedParentIds = newExpandedParentIds.filter(x => x != clickedId)
        } else {
            newExpandedParentIds.push(clickedId);
        }

        this.setState({ expandedParentIds: newExpandedParentIds });
    }

    getCustomHeader() {
        const {
            sortIndicator,
        } = this.state;

        const header = () => <thead><tr key={Math.random()} style={{ display: 'block' }}>
            {
                <>
                    <ARMHeaderCell
                        dataName="fileName"
                        text="Filename"
                        cellType="text"
                        sortable={true}
                        sortDataType={SortDataType.String}
                        sortHandler={this.handleSorting}
                        sortIndicator={sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="primary-header-remove"
                        text="Remove"
                    />
                </>
            }
        </tr></thead>
        return header;
    }

    onParentRemoveClicked(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        const {
            treeviewData,
            parentRemoveCheckedIds,
            childRemoveCheckedIds,
            childRemoveDisabledIds,
        } = this.state;

        // create a local copy of state vars
        let newParentRemoveCheckedIds = parentRemoveCheckedIds;
        let newChildRemoveCheckedIds = childRemoveCheckedIds;
        let newChildRemoveDisabledIds = childRemoveDisabledIds;

        // get parent id
        const clickedRemoveParentId = e.currentTarget.id;

        // get child id's
        const children = treeviewData?.childData.filter(x => ('cbxRemove-parent-' + x.parentId.toString()) === clickedRemoveParentId);
        let childIdStrings: string[] = [];
        children?.forEach(x => {
            childIdStrings.push('cbxRemove-child-' + x.id);
        });

        // handle click event for parent
        if (newParentRemoveCheckedIds.includes(clickedRemoveParentId)) {
            // uncheck parent
            newParentRemoveCheckedIds = newParentRemoveCheckedIds.filter(x => x != clickedRemoveParentId);

            // for each child
            childIdStrings.forEach(x => {

                // uncheck child
                newChildRemoveCheckedIds = newChildRemoveCheckedIds.filter(y => y != x);

                // enable child
                newChildRemoveDisabledIds = newChildRemoveDisabledIds.filter(y => y != x);
            });
        } else {
            // check parent
            newParentRemoveCheckedIds.push(clickedRemoveParentId);

            // for each child
            childIdStrings.forEach(x => {

                // check child
                newChildRemoveCheckedIds.push(x);

                // disable child
                newChildRemoveDisabledIds.push(x);
            });
        }

        this.setState({
            parentRemoveCheckedIds: newParentRemoveCheckedIds,
            childRemoveCheckedIds: newChildRemoveCheckedIds,
            childRemoveDisabledIds: newChildRemoveDisabledIds,
        });
    }

    onChildRemoveClicked(e: React.MouseEvent<HTMLInputElement, MouseEvent>) {
        const {
            childRemoveCheckedIds,
            parentRemoveDisabledIds,
            treeviewData
        } = this.state;

        let newChildRemoveCheckedIds = childRemoveCheckedIds;
        let newParentRemoveDisabledIds = parentRemoveDisabledIds;

        const clickedRemoveChildId = e.currentTarget.id;
        const parentIdNumber = treeviewData?.childData.find(x => ('cbxRemove-child-' + x.id.toString()) === clickedRemoveChildId)?.parentId || 0;
        const parentIdString = 'cbxRemove-parent-' + parentIdNumber;

        if (newChildRemoveCheckedIds.includes(clickedRemoveChildId)) {

            // remove the unchecked child id
            newChildRemoveCheckedIds = newChildRemoveCheckedIds.filter(x => x != clickedRemoveChildId);

            // det if sibling remove child checkboxes are checked
            const siblings = treeviewData?.childData.filter(x => x.parentId == parentIdNumber);

            // validate if any child siblings are checked
            let childSiblingChecked = false;
            siblings?.forEach(x => {
                if (newChildRemoveCheckedIds.includes('cbxRemove-child-' + x.id.toString())) {
                    childSiblingChecked = true;
                }
            });

            // if no child remove siblings are checked
            if (!childSiblingChecked) {
                // re-enable parent checkbox 
                newParentRemoveDisabledIds = newParentRemoveDisabledIds.filter(x => x != parentIdString);
            }
        } else {
            newChildRemoveCheckedIds.push(clickedRemoveChildId);

            // disable parent checkbox
            newParentRemoveDisabledIds.push(parentIdString);
        }

        this.setState({
            childRemoveCheckedIds: newChildRemoveCheckedIds,
            parentRemoveDisabledIds: newParentRemoveDisabledIds
        })
    }

    getCustomRow() {
        const row = ({ record }: IRowProps) => {
            const {
                expandedParentIds,
                treeviewData,
                parentRemoveCheckedIds,
                childRemoveCheckedIds,
                parentRemoveDisabledIds,
                childRemoveDisabledIds,
            } = this.state;

            let recordId = record.id.toString();

            const childRecords = treeviewData?.childData.filter(x => x.parentId.toString() == recordId);

            return (
                <>
                    <tr id={recordId} key={recordId} role="button" onClick={(x) => { this.onRowClick(x) }} style={{ display: 'block'}}>
                        {
                            <>
                                <td className="primary-row-file-name">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style">
                                            {expandedParentIds.includes(recordId) &&
                                                <img src={minusSymbol} />
                                            }
                                            {!expandedParentIds.includes(recordId) &&
                                                <img src={plusSymbol} />
                                            }
                                            {record.fileName}
                                        </div>
                                    </div>
                                </td>
                                <td className="primary-row-remove">
                                    <div className="cell-component-wrapper cell-style">
                                        <div className="no-margin cell-style">
                                            <input id={'cbxRemove-parent-' + record.id} type="checkbox"
                                                onClick={(x) => {
                                                    // prevent row click event
                                                    x.stopPropagation();
                                                    this.onParentRemoveClicked(x);
                                                }}
                                                checked={
                                                    parentRemoveCheckedIds.includes('cbxRemove-parent-' + record.id)
                                                }
                                                disabled={parentRemoveDisabledIds.includes('cbxRemove-parent-' + record.id)}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </>
                        }
                    </tr>
                    { expandedParentIds.includes(recordId) &&
                        <>
                            <tr className="child-header" style={{ display: 'block'}}>
                                <th className="child-header-payment-date">
                                    <div>
                                        <img src={i} />
                                        <button>
                                            <td>
                                                Payment date
                                            </td>
                                        </button>
                                    </div>
                                </th>
                                <th class-name="child-header-remove" >
                                    <div>
                                        <button>
                                            <td>
                                                Remove
                                            </td>
                                        </button>
                                    </div>
                                </th>
                            </tr>

                            { childRecords && childRecords.map((childRecord, i, childRecords) => {
                                return (
                                    <tr className="child-row" style={{ display: 'block' }}>
                                        <td className="child-row-payment-date">
                                            {childRecord.id == childRecords[childRecords.length - 1].id &&
                                                < img src={l_middle} />
                                            }
                                            {childRecord.id != childRecords[childRecords.length - 1].id &&
                                                < img src={l_end} />
                                            }

                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style">
                                                    {childRecord.paymentDate}
                                                </div>
                                            </div>
                                        </td>
                                        <td className="child-row-remove">
                                            <div className="cell-component-wrapper cell-style">
                                                <div className="no-margin cell-style">
                                                    <input id={"cbxRemove-child-" + childRecord.id} type="checkbox"
                                                        onClick={(x) => { this.onChildRemoveClicked(x); }}
                                                        checked={childRemoveCheckedIds.includes('cbxRemove-child-' + childRecord.id)}
                                                        disabled={childRemoveDisabledIds.includes('cbxRemove-child-' + childRecord.id)}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })
                            }
                        </>
                    }
                </>
            );
        }
        return row;
    }

    render() {
        const {
            treeviewData
        } = this.state;

        const data = treeviewData?.parentData;

        const columns = GridConfig.getColumnsConfig();
        const row = this.getCustomRow();
        const header = this.getCustomHeader();

        return (
            <GridWrapper>
                {treeviewData?.parentData &&
                    <ARMGrid
                        domID='sample-treeview-grid'
                        maxHeight={'350px'}
                        isFixedHeader={true}
                        columns={columns}
                        headerComponent={header}
                        rowComponent={row}
                        records={data}
                        emptyGridMessage=' '
                        selectionKey={'id'} />
                }
            </GridWrapper>
        )
    }
}
