import * as React from 'react';
import styled from 'styled-components';
//import { InputLabel } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';


export interface IOptionComponentProps {
    title?: string;
    value: string;
    text: string;
    deleted?: string;
    selected?: string;
    disabled?: string;
    fav?: string;
};

export interface IARMNarrowDropdownProps {
    title: string;
    records: Array<any>;
    optionFields: IOptionComponentProps;
    size?: number;
    width?: string;
    height?: string;
    disabled?: boolean;
    onSelect?: React.ChangeEventHandler<HTMLSelectElement>;
    onDoubleClick?: any;
    className?: string;
    selectedValue?: string;
    selectedMultiValue?: string[];
    label?: string;
    multiple?: string;
    tooltip?: boolean;
    isLabel?: boolean;
    fontSize: string; 
};

export interface ISelectComponentState {
    disabled: boolean;
};

export interface ISelectWrapperProps {
    widthValue?: string;
    heightValue?: string;
    onDoubleClick?: any;
};

export interface ISelectWrapperDivProps {
    className?: string;
    fontSize: string; 
};

const SelectWrapperDiv = styled.div <ISelectWrapperDivProps>`
     display: flex;
    flex: 1 auto;
    flex-direction: row;
    ${(p) => p.className && `className: ${p.className}`};

    .select-label-class{
      color: ${Colors.grey100};  
      ${Typography.ARMFontFamily};
      ${Typography.small};
      ${Typography.defaultLineHeight};
      ${Typography.bold};
      letter-spacing: 0.5px;
      height: 21px;
      text-transform: uppercase;
      min-width: 158px;
margin-left:4px;
    }

    .select-class{
     font-size:  ${(p) => p.fontSize ? `${p.fontSize}` : 'inherit'};
     min-height:20px;
     max-height:35px;
    

margin-left:4px;
    }

`;

export const SelectWrapper = styled.select`
    ${(props: ISelectWrapperProps) => props.widthValue && `width: ${props.widthValue} !important`};
    ${(props: ISelectWrapperProps) => props.heightValue && `height: ${props.heightValue}`};

    overflow-y: auto;
    overflow-x: auto;

    border-color: ${Colors.grey50};
    border-radius: 3px;

    .option-class{
          color: ${Colors.grey100};
          ${Typography.ARMFontFamily};
          ${Typography.medium};
          ${Typography.defaultLineHeight};
          

          margin: 0.1rem;
    }


    .option-label-class{
      color: ${Colors.grey100};  
      ${Typography.ARMFontFamily};
      ${Typography.small};
      ${Typography.defaultLineHeight};
      ${Typography.bold};
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .option-fav {
        color: blue !important;
    }
`;

export const InputLabel = styled.label`
  display: block;
  margin-bottom: 0.3rem;
  color: ${Colors.grey100};
  ${Typography.ARMFontFamily};
  ${Typography.small};
  ${Typography.defaultLineHeight};
  ${Typography.bold};
  letter-spacing: 0.5px;
  text-transform: uppercase;
    margin-top:4px;
`;

export class ARMNarrowDropdown extends React.Component<IARMNarrowDropdownProps, ISelectComponentState>
{
    static defaultProps: IARMNarrowDropdownProps = {
        title: "ARMNarrowDropdown",
        records: [],
        optionFields: {
            value: "value",
            text: "text",
            selected: "selected",
            disabled: undefined,
            title: undefined,
            fav: undefined,
        },
        size: 1,
        disabled: false,
        tooltip: false,
        label: '',
        isLabel: false,
        selectedValue: '',
        fontSize: 'inherit'
    };

    constructor(props: IARMNarrowDropdownProps) {
        super(props);
        this.state = {
            disabled: this.props.disabled || false,
        }
    }

    isMultiple(): boolean {
        if (this.props.multiple && this.props.multiple.length > 0 && this.props.multiple !== "false")
            return true;
        return false;
    }

    getData(record: any, index: any): boolean {
        if (index === undefined)
            return false;
        return record[index];
    }

    renderOptions() {
        var selected = false;
      //  console.log("WidthValue", this.props.width);
        var options = this.props.records.map((record) => {
            if (!(this.props.optionFields.deleted && (record[this.props.optionFields.deleted] == "1"))) {
                let isDisabled: boolean = false;
                if (record.disabled && record.disabled == 'true') {
                    isDisabled = true;
                }


                return <option
                    className={(this.props.isLabel ? 'option-label-class' : 'option-class') + (this.getData(record, this.props.optionFields.fav) == true ? ' option-fav' : '')}
                    disabled={isDisabled}
                    onDoubleClick={(e: any) => { if (!isDisabled && this.props.onDoubleClick) { this.props.onDoubleClick(e) } }}
                    key={record[this.props.optionFields.value]}
                    title={this.props.tooltip === true ? record[this.props.optionFields.text] : ''} // add an option field for the title?
                    value={record[this.props.optionFields.value]}>{record[this.props.optionFields.text]}</option>
            }
        });
        return (
            <React.Fragment>
                {options}
            </React.Fragment>
        );
    }

    render() {
        return (
            <SelectWrapperDiv key={this.props.title} className={(this.props.isLabel ? 'select-label-class ' : '') + this.props.className} fontSize={this.props.fontSize}>
                <InputLabel htmlFor={this.props.title}>{this.props.label}</InputLabel>
                <SelectWrapper
                    className={(this.props.isLabel ? 'select-label-class' : ' select-class')}
                    id={this.props.title}
                    name={this.props.title}
                    widthValue={this.props.width}
                    heightValue={this.props.height}
                    disabled={this.props.disabled}
                    {...(this.isMultiple() ? { multiple: true } : null)}
                    size={this.props.size}
                    {...(this.isMultiple() ? { value: this.props.selectedMultiValue } : { value: this.props.selectedValue })}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { if (this.props.onSelect) this.props.onSelect(e) }}
                    value={this.props.selectedValue}
                >
                    {this.renderOptions()}
                </SelectWrapper>
            </SelectWrapperDiv>
        );
    }
};
