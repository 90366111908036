import * as React from 'react';
import { VictoryPie } from 'victory';
import { Colors } from "@commonResources/colorVariables";


const Formatter = (type: string, value: any, params: any) => {
    params = { width: 50, height: 50, color: Colors.grey100, ...params }

    const percentage = value * 100;
    const complement = 100 - percentage;

    switch (type) {
        case "Date": {
            return new Date(value).toISOString().split('T')[0];
        }
        case "Pie": {
            const { width, height, color } = params;
            return <svg width={width} height={height} viewBox="0,0,300,300">
                <VictoryPie
                    colorScale={[color, "transparent"]}
                    standalone={false}
                    width={300} height={300}
                    data={[
                        { x: " ", y: percentage },
                        { x: " ", y: complement }
                    ]}
                />
                <circle cx={150} cy={150} r={100} fill="transparent" stroke={color} strokeWidth={10} />
            </svg>
        }
 
    }
}

export default Formatter;