import { Action, Reducer } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { FieldLevelSecurity } from '../../components/Administration/FieldLevelSecurity/FieldLevelSecurity';
import { deepCopyFunction } from '@commonResources/validations';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export const emptyItem: MCCIF_ClaimInquiryFormType = {

    ClaimInquiryFormMaintenanceInfo: {

        PatientName: {
            Field: [{ '@Order': '1', '@ID': '1' }, { '@Order': '2', '@ID': '2' }, { '@Order': '3', '@ID': '3' }]
        },
        CheckDigit: { '@Value': '' },
        Remarks: { '@Value': '' },
        ServiceLines: { '@HCFA': '1', '@UB92': '1' }

    }
}

export interface IClaimInquiryFormUIState {
    
    claimInquiryForm: MCCIF_ClaimInquiryFormType,
    changed: boolean,
    genericAlertOK: any,
    submitNCSAlert?: string,
    cancelWOSave?: boolean,
    patientNameRecordPointer: string,
    patientRecords: any[],
    keyValue: number,
    patientNameIDValue: any[],
    isIntialDataLoaded: boolean,
    formSubmitValid: boolean,

}


export interface IClaimInquiryFormUIData {
    id: string;
    value: string;
};

interface IClaimInquiryFormUIFieldUpdate {
    value: string;
}

export const defaultClaimInquiryForm: IClaimInquiryFormUIData = {
    id: '',
    value: '',
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface IUpdateClaimInquiryFormField2 extends ICrudActionData<MCCIF_CIFData, IClaimInquiryFormUIFieldUpdate> { }
export interface ISelectClaimInquiryForm extends ICrudActionData<MCCIF_ClaimInquiryFormType, IClaimInquiryFormUIData> { }
export interface IUpdateClaimInquiryFormField extends ICrudActionData<MCCIF_ClaimInquiryFormType, IClaimInquiryFormUIFieldUpdate> { }

export const actionCreators = {
    callAlert: (e: any) => createDataAction('ERROR_ALERT_CONFIRM', e),
    updateRadioHCFA: (fieldData: IUpdateClaimInquiryFormField) => createDataAction('UPDATE_RADIO_HCFA', fieldData),
    updateRadioUB: (fieldData: IUpdateClaimInquiryFormField) => createDataAction('UPDATE_RADIO_UB', fieldData),
    updateRemarks: (fieldData: string) => createDataAction('UPDATE_REMARKS', fieldData),
    loadPatientBox: (fieldData: IUpdateClaimInquiryFormField2) => createDataAction('LOAD_PATIENT_BOX', fieldData),
    movePatientRecord: (fieldData: string) => createDataAction('MOVE_PATIENT_RECORD', fieldData),
    setPatientRecordIndex: (fieldData: string) => createDataAction('SET_PATIENT_RECORD_INDEX', fieldData),
    updateCrudData: (fieldData: IUpdateClaimInquiryFormField2) => createDataAction('UPDATE_CRUD_DATA', fieldData),

};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IClaimInquiryFormUIState = {

    claimInquiryForm: emptyItem,
    changed: false,
    genericAlertOK: undefined,
    submitNCSAlert: '',
    cancelWOSave: false,
    patientNameRecordPointer: "",
    patientRecords: [],
    keyValue: 1,
    patientNameIDValue: [
        { '@ID': '1', '@Value': 'Patient Control Number' },
        { '@ID': '2', '@Value': 'Patient Last Name' },
        { '@ID': '3', '@Value': '*Medical Record Number' },
    ],
    isIntialDataLoaded: false,
    formSubmitValid: false,
};

function getData(ob: any, field: string): string {
    if (!ob)
        return '';

    return ob[field] ?? '';

}

function getRecords(state: IClaimInquiryFormUIState, tmpFieldAry: MCCIF_PatientFieldList[] | undefined) {

    if (!tmpFieldAry || tmpFieldAry && tmpFieldAry.length === 0) {
        tmpFieldAry = state?.claimInquiryForm?.ClaimInquiryFormMaintenanceInfo?.PatientName?.Field;
    }

    let result: any[] = ["", "", ""];
    if (tmpFieldAry) {
        tmpFieldAry.forEach(ob => {
            result.splice(parseInt(ob['@Order']) - 1, 1, {
                value: ob['@Order'],
                label: state.patientNameIDValue.find(ob1 => ob1['@ID'] === ob['@ID'])['@Value']
            });
        });
    }

    return result;
}

function swapRecords(state: IClaimInquiryFormUIState, action: string,) {

    var newCurrentRecordValue = action === 'UP' ? (parseInt(state.patientNameRecordPointer) - 1) + '' : (parseInt(state.patientNameRecordPointer) + 1) + '';

    let tmpFieldAry: any[] = deepCopyFunction(state.patientRecords);
    let result: any[] = [];
    tmpFieldAry.splice(parseInt(state.patientNameRecordPointer) - 1, 1, {
        value: state.patientNameRecordPointer,
        label: state.patientRecords.find(ob1 => ob1['value'] === newCurrentRecordValue)['label']
    });
    tmpFieldAry.splice(parseInt(newCurrentRecordValue) - 1, 1, {
        value: newCurrentRecordValue,
        label: state.patientRecords.find(ob1 => ob1['value'] === state.patientNameRecordPointer)['label']
    });

    return tmpFieldAry;

}

// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IClaimInquiryFormUIState, KnownActions> = (state: IClaimInquiryFormUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {

            
            case 'UPDATE_RADIO_HCFA':

                return {
                    ...state,
                    claimInquiryForm: {
                        ...state.claimInquiryForm,
                        ClaimInquiryFormMaintenanceInfo: {
                            ...state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo,
                            ServiceLines: { '@HCFA': action.data.uiData.value, '@UB92': state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines['@UB92'] }
                        }
                    }
                    
                }
                break;

            case 'UPDATE_RADIO_UB':

                return {
                    ...state,
                    claimInquiryForm: {
                        ...state.claimInquiryForm,
                        ClaimInquiryFormMaintenanceInfo: {
                            ...state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo,
                            ServiceLines: { '@HCFA': state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines['@HCFA'], '@UB92': action.data.uiData.value }
                        }
                    }
                }
                break;

            case 'UPDATE_REMARKS':

                return {
                    ...state,
                    claimInquiryForm: {
                        ...state.claimInquiryForm,
                        ClaimInquiryFormMaintenanceInfo: {
                            ...state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo,
                            Remarks: { '@Value': action.data },
                        }
                    }
                }
                break;

            case 'LOAD_PATIENT_BOX':

                var dataLoaded = false;
                var localClaimInquiryForm: MCCIF_ClaimInquiryFormType = emptyItem;
                var patientFieldList: MCCIF_PatientFieldList[] = [];
                var HCFA, UB92, remarks, checkDigit, patientName;

                if (action.data.masterCrud?.data?.ClaimInquiryFormMaintenanceInfo ) {
                    dataLoaded = true;
                    localClaimInquiryForm = deepCopyFunction(action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo);
                    HCFA = action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.ServiceLines['@HCFA'];
                    UB92 = action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.ServiceLines['@UB92'];
                    remarks = action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.Remarks['@Value'];
                    checkDigit = action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.CheckDigit['@Value'];
                    patientName = deepCopyFunction(action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.PatientName)

                    if (action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo?.PatientName?.Field) {
                        patientFieldList = action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.PatientName.Field;
                    }
                }

                let result: any[] = []
                result = getRecords(state, patientFieldList);

                return {
                    ...state,
                    patientRecords: result,
                    patientNameRecordPointer: result[0].value,
                    isIntialDataLoaded: dataLoaded,
                    claimInquiryForm: {
                        ...state.claimInquiryForm,
                        ClaimInquiryFormMaintenanceInfo: {
                            ...state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo,
                            Remarks: { '@Value': remarks ? remarks : "1" },
                            CheckDigit: { '@Value': checkDigit ? checkDigit:"" },
                            PatientName: patientName,
                            ServiceLines: {
                                '@HCFA': HCFA? HCFA: "1",
                                '@UB92': UB92? UB92: "1",
                            }
                        }
                    }
                }
                break;
                
            case 'MOVE_PATIENT_RECORD':

                let newPatientArray = swapRecords(state, action.data);
                var newpatientPointer = action.data === 'UP' ? (parseInt(state.patientNameRecordPointer) - 1) + '' : (parseInt(state.patientNameRecordPointer) + 1) + '';

                return {
                    ...state,
                    patientRecords: newPatientArray,
                    patientNameRecordPointer: newpatientPointer,
                    claimInquiryForm: {
                        ...state.claimInquiryForm,
                        ClaimInquiryFormMaintenanceInfo: {
                            ...state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo,
                            PatientName: {
                                Field: newPatientArray,
                            }
                        } 
                    }
                }
                break;

            case 'SET_PATIENT_RECORD_INDEX':

                var patientIndex = action.data + '';

                return {
                    ...state,
                    patientNameRecordPointer: patientIndex,
                }
                break;

            case 'UPDATE_CRUD_DATA':

                var validOK = false;

                if (action.data.masterCrud?.data?.ClaimInquiryFormMaintenanceInfo) {

                    action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.ServiceLines['@HCFA'] = state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines['@HCFA'];
                    action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.ServiceLines['@UB92'] = state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.ServiceLines['@UB92'];
                    action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.Remarks['@Value'] = state.claimInquiryForm.ClaimInquiryFormMaintenanceInfo.Remarks['@Value'];
                    /* At this time there is no way to change the checkdigit on the form and no point in updating it
                    //action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.CheckDigit['@Value'];*/

                    delete action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.PatientName['Field'];
                    let newField: any[] = [];
                    //{ '@Order': '1', '@ID': '1' }, { '@Order': '2', '@ID': '2' }, { '@Order': '3', '@ID': '3' }
                    newField.push({ '@ID': state.patientNameIDValue.find(ob1 => ob1['@Value'] == state.patientRecords[0]['label'])['@ID'], '@Order': '1' })
                    newField.push({ '@ID': state.patientNameIDValue.find(ob1 => ob1['@Value'] == state.patientRecords[1]['label'])['@ID'], '@Order': '2' })
                    newField.push({ '@ID': state.patientNameIDValue.find(ob1 => ob1['@Value'] == state.patientRecords[2]['label'])['@ID'], '@Order': '3' })
                    action.data.masterCrud.data.ClaimInquiryFormMaintenanceInfo.PatientName['Field'] = newField;
                    validOK = true;
                }

                return {
                    ...state,
                    formSubmitValid: validOK,
                }
                break;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
