import * as React from 'react';
import { LoadingIndicator } from 'ui-core';
import styled, { css } from 'styled-components';
import { ARMComponentItemContainer, ARMComponentItemTop, ARMComponentItemContent, ARMComponentItemBottom } from './styles/LandingPageStyles';

export class ARMComponentLoading extends React.PureComponent<{}>
{
    render() {
        return (
            <ARMComponentItemContainer>
                <ARMComponentItemTop />
                <ARMComponentItemContent>
                    <LoadingIndicator domID="test-id" className="test-indicator" length="32px" />
                </ARMComponentItemContent>
                <ARMComponentItemBottom />
            </ARMComponentItemContainer>
        );
    }
}
