import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IDownloadFilesState, IDownloadFilesActionProps, actionCreators, validationCallback } from '@store/DownloadFiles';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper, DialogButton, DialogButtonOK } from '@common/DialogWrapper';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { ARMHeaderCell } from '@common/UICLWrappers/ARMHeaderCell';
import { DialogLegend, DialogFieldset, DialogLabel } from '@common/DialogStyles';
import { CheckBoxComponent } from '@common/CheckBox';
import { handleChange, pageLeave } from '@commonResources/userModified';
import AssuranceMenu from '../../common/AssuranceMenu';
import { ISortIndicator } from '@scripts/util/SortHelper';
import { FooterDownloadFiles } from '@common/FooterDownloadFiles';
import { ARMDatePicker } from '../../common/DateOrTimeRelated/ARMDatePicker';
import { Input } from '../../common/UICLWrappers/ARMInput';
import _ from 'lodash';
import { Button, Tabs } from 'ui-core';
import { CustomCheckBox } from '../../common/CustomCheckBox';
import { SupportDownloadFilesData, SupportDownloadDataSelect, ReportDownloadFilesData, ReportDownloadDataSelect, } from '../DownloadFiles/DownloadListEntities';
import { ModalConfirmation } from '../../common/ModalConfirmation';
import ApiClient from '@commonResources/ApiClient';
import { FileDownloader } from '../../SupportTools/SiteFile/FileDownloader';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
      
    #fieldset {
       width:1008px;
    }
    #from-label {
        margin-top:8px;
    }
    #from-date {
        margin-left:4px;
    }
    #to-label {
        margin-top:8px;
        margin-left: 9px;
    }
    #to-date {
        margin-left:4px;
    }
    #search-filename {
        margin-left:7px;
        width: 274px;
    }
    #search-filetype {
        margin-left:14px;
    }
    #report-id {
        overflow: 'auto',
        padding: '1px',
    }
    #support-id {
        overflow: 'auto',
        padding: '1px',
    }

    .date-report {
        vertical-align: inherit;
        width:150px;
    }
    .name-report {
        vertical-align: inherit;
        width:573px;
    }
    .type-report {
        vertical-align: inherit;
        width:149px;
    }
    .filters-report {
        display:flex;
        align-items:center;
        width:149px;
    }


    .Created {
        vertical-align: inherit;
        width:166px;
    }
    .Name {
        vertical-align: inherit;
        width:463px;
    }
    .Size {
        vertical-align: inherit;
        width:110px;
    }
    .Files {
        vertical-align: inherit;
        width:152px;
    }
    .Operations {
        vertical-align: inherit;
        width:85px;
    }

    .selectDropdown {
        vertical-align: inherit;
    }
    th.Download { 
        z-index: 20;
    }
    select {
        font-size: 12px;
        font-weight: 600;
    }
    #encrypt-download {
        margin-left:0px;
    }
    .date {
        width:170px;
    };
    .fileName {
        width:550px;
    };
    .fileTypeName {
        width:127px;
    };
    
   
`;

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    canDelete: boolean;
    canSecCheck1: boolean,
    title: string,
};

interface IComponentState {
    isUpdate: boolean,
    updating: boolean,
    cancelLeave: boolean,
    reportDownloadFilesData: ReportDownloadFilesData[];
    supportDownloadFilesAPIData: any;
    reportDownloadFilesAPIData: any;
    supportDownloadFilesData: SupportDownloadFilesData[];
    selectedFilterType: string;
    selectedSupportDownloadFiles: SupportDownloadDataSelect[];
    selectedReportDownloadFiles: ReportDownloadDataSelect[];
    sortIndicator: ISortIndicator;
    pageTotalSupportDownload: number;
    pageCountSupportDownload: number;
    pageTotalReportDownload: number;
    pageCountReportDownload: number;
    diskSpace: number;
    ReportdiskSpace: number;
    selectedOperation: string;
    selectedFromDate: string;
    selectedToDate: string;
    inEncryptDownload: boolean,
    dirty: boolean;
    isOpen: boolean;
}

interface HeaderProps { columns: {} }

export const DEFAULT_STATE: IComponentState = {
    isUpdate: false,
    updating: false,
    cancelLeave: false,
    reportDownloadFilesData: [],
    supportDownloadFilesAPIData: [],
    reportDownloadFilesAPIData: [],
    selectedFilterType: 'All Files',
    supportDownloadFilesData: [],
    selectedSupportDownloadFiles: [],
    selectedReportDownloadFiles: [],
    sortIndicator: {
        sortColumn: '',
        sortDirection: '',
    },
    pageTotalSupportDownload: 1,
    pageCountSupportDownload: 1,
    pageTotalReportDownload: 1,
    pageCountReportDownload: 1,
    diskSpace: 0,
    ReportdiskSpace: 0,
    selectedOperation: 'download',
    selectedFromDate: '',
    selectedToDate: '',
    dirty: false,
    isOpen: false,
    inEncryptDownload: false,
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IDownloadFilesProps = IMergeCrudComponentProps<IDownloadFilesState, IDownloadFilesActionProps, IOwnProps>;

export class DownloadFiles extends React.Component<IDownloadFilesProps, IComponentState> {
    static addItemCount: number = 0;
    protected selectedOperation: string = '';
    protected selectedSupportDownloadFiles: SupportDownloadDataSelect[] = [];
    protected selectedReportDownloadFiles: ReportDownloadDataSelect[] = [];
    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canDelete: false,
        canSecCheck1: false,
        title: "Download Files",

    };
    minDate: string;
    maxDate: string;
    readonly DELETE_REPORT_ALERT = <React.Fragment>
        <p>Are you sure you want to delete file(s)?</p>
        <p>This action cannot be undone.</p></React.Fragment>;
    readonly PAGE_SIZE = 100;
   

    constructor(props: IDownloadFilesProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.handleSort = this.handleSort.bind(this);
        this.handleSortReport = this.handleSortReport.bind(this);
        this.minDate = this.dateAdd(new Date(), 'm', -1);
        this.maxDate = this.getDateInMMDDYYYYHHMMSSFormat(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
        this.buildSupportDownloadFilesGridData = this.buildSupportDownloadFilesGridData.bind(this);
        this.buildReportDownloadFilesGridData = this.buildReportDownloadFilesGridData.bind(this);
    }

    public async componentDidMount() {
        if (!this.props.canView) {
            this.props.history.push('/LandingPage');
        }
        this.props.action.ui.getDeIDEncryptEnabled();
        let request = {
            'FromDate': this.props.dataStore.ui.sendFromDate != '' ? this.props.dataStore.ui.sendFromDate : this.Today(),
            'ThruDate': this.props.dataStore.ui.sendToDate != '' ? this.props.dataStore.ui.sendToDate : this.Today(),
            'FileName': this.props.dataStore.ui.sendFileName,
            'FileType': this.props.dataStore.ui.sendFileType,
        }
        await this.props.action.ui.getSupportDownloadFiles(request);
        await this.props.action.ui.getReportDownloadFiles(request);
        this.getSupportDownloadFiles();
        this.getReportDownloadFiles();
    }

    public componentWillUnmount() {
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    /* -----------------------------------  */
    /* --------  ACTION METHODS ----------  */
    /* -----------------------------------  */

    public async onSelectTab(e: React.MouseEvent<HTMLButtonElement>, selObj: any) {
        this.props.action.ui.selectTabIndex(selObj.selectedTabIndex);
       
        this.setState({ updating: true });
        let request = {
            'FromDate': this.props.dataStore.ui.sendFromDate != '' ? this.props.dataStore.ui.sendFromDate : this.Today(),
            'ThruDate': this.props.dataStore.ui.sendToDate != '' ? this.props.dataStore.ui.sendToDate : this.Today(),
            'FileName': this.props.dataStore.ui.sendFileName != '' ? this.props.dataStore.ui.sendFileName + '*' : this.props.dataStore.ui.sendFileName,
            'FileType': this.props.dataStore.ui.sendFileType != '' ? this.props.dataStore.ui.sendFileType + '*' : this.props.dataStore.ui.sendFileType
        }
        if (selObj.selectedTabIndex == 1) {
            this.setState({ isOpen: false });
            this.setState({ supportDownloadFilesData: [] })
            await this.props.action.ui.getSupportDownloadFiles(request);
            this.getSupportDownloadFiles()
        } else {
            this.setState({ isOpen: false }); this.setState({ isOpen: false });
            this.setState({ reportDownloadFilesData: [] })
            await this.props.action.ui.getReportDownloadFiles(request);
            this.getReportDownloadFiles();
        }

        this.setState({ selectedOperation: selObj.selectedTabIndex == 1 ? "delete" : "download" })
        this.setState({ inEncryptDownload: false });
    }

    public onChangeSearchReportFromDate(value: string) {
        const dates = value.split('/');
        if (dates[2] == '0000') {
            let dateObj = new Date();
            if (dates[1] != '00') {
                dateObj.setDate(dateObj.getDate() - parseInt(dates[1]))
            } else if (dates[0] != '00') {
                dateObj.setMonth(dateObj.getMonth() - parseInt(dates[0]))
            }
            value = ((dateObj.getMonth() + 1).toString().padStart(2, '0')) + '/' + dateObj.getDate().toString().padStart(2, '0') + '/' + dateObj.getFullYear();
        }
        this.props.action.ui.selectedSearchReportFromDate(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    fromDate: value
                }
            });
    }

    public onChangeSearchReportToDate(value: string) {
        this.props.action.ui.selectedSearchReportToDate(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    toDate: value
                }
            });
    }

    public onChangeSearchReportFileName(value: string) {
        this.props.action.ui.selectedSearchReportFileName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                fileName: value.trim()
            }
        });
    }

    public onChangeSearchReportFileType(value: string) {
        this.props.action.ui.selectedSearchReportFileType({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                fileType: value.trim()
            }
        });
    }

    public onCheckEncryptDownload = () => {
        this.setState({ inEncryptDownload: !this.state.inEncryptDownload })
    }

    public onDateChange(e: string, fromField: boolean) {
        var data: string = e ?? '';

        if (fromField && this.state.selectedFromDate === data) return;
        if (!fromField && this.state.selectedToDate === data) return;

        if (fromField === true) {
            this.setState({ selectedFromDate: data, dirty: true });
        }

        if (fromField === false) {
            this.setState({ selectedToDate: data, dirty: true });
        }
    }

    public getDateInMMDDYYYYHHMMSSFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var hour = input.getHours();
            var year = input.getFullYear();
            var minute = input.getMinutes();
            var seconds = input.getSeconds();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year + ' ' + hour + ':' + minute + ':' + seconds;
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000 00:00:00";
    }

    public getDateInMMDDYYYYFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var year = input.getFullYear();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000";
    }

    Today = (): string => {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0');
        var yyyy = today.getFullYear();
        return mm + '/' + dd + '/' + yyyy;
    }

    public dateAdd(date: any, type: string, amount: number) {
        try {
            var y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();
            if (type === 'y') {
                y += amount;
            };
            if (type === 'm') {
                m += amount;
                if (m <= 0) {
                    m += 12;
                    y--;
                }
                else if (m > 12) {
                    m -= 12;
                    y++;
                }
            };
            if (type === 'd') {
                d += amount;
            };
            var xx = new Date(y, m, d);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateAdd');
        }
        return "00/00/0000";
    }

    /* -----------------------------------  */
    /* ------  BUTTON RELATED METHODS ----  */
    /* -----------------------------------  */


    public onOKDownloadFileReport(e: React.ChangeEvent<HTMLButtonElement>) {

        let selectedOperation: string = this.state.selectedOperation;
        console.log("selected Operation: " + selectedOperation);
        if (this.props.dataStore.ui.selectedTabIndex == 1) {
            if (this.selectedSupportDownloadFiles.length === 0 && this.state.supportDownloadFilesData.length !== 0)
                this.setState({ isOpen: true })
            else
                if (selectedOperation !== "") {
                    switch (selectedOperation) {
                        case "delete":
                            this.setState({ isOpen: true, selectedSupportDownloadFiles: this.selectedSupportDownloadFiles })
                            const request = { Files: this.selectedSupportDownloadFiles }
                            this.props.action.ui.deleteSupportDownloadFiles(request);
                            break;
                        case "download":
                            this.setState({ isOpen: true, selectedSupportDownloadFiles: this.selectedSupportDownloadFiles })
                            const request1 = { Files: this.selectedSupportDownloadFiles }
                            this.props.action.ui.encryptSupportDownloadFiles(request1);
                            break;
                    }
                }
        }
        else if (this.props.dataStore.ui.selectedTabIndex !== 1) {

            if (this.selectedReportDownloadFiles.length === 0 && this.state.reportDownloadFilesData.length !== 0) {
               // this.setState({ isOpen: true })
            } else {

                if (selectedOperation !== "") {
                    switch (selectedOperation) {
                        case "delete":
                            this.setState({ isOpen: true, selectedReportDownloadFiles: this.selectedReportDownloadFiles })
                            let request = { fileList: this.selectedReportDownloadFiles }                            
                            break;
                        case "download":
                            this.setState({ isOpen: true, selectedReportDownloadFiles: this.selectedReportDownloadFiles })
                            let request1 = { fileList: this.selectedReportDownloadFiles }
                            this.download(request1);
                            this.selectedReportDownloadFiles = [];
                            this.setState({ selectedReportDownloadFiles: [] })
                            break;
                    }
                }
            }
        }

    }




    getSelectedOperation(): string {
        return this.selectedOperation.length === 0 ? "delete" : this.selectedOperation;
    }

    setSelectedOperation(opIndex: number) {
        this.selectedOperation = (opIndex === 0) ? "download" : "delete";
        this.setState({ selectedOperation: (opIndex === 0) ? "download" : "delete" })
    }

    public async onClickModifyButton(e: React.ChangeEvent<HTMLButtonElement>) {
        this.setState({ updating: true });
        let request = {
            'FromDate': this.props.dataStore.ui.sendFromDate != '' ? this.props.dataStore.ui.sendFromDate : this.Today(),
            'ThruDate': this.props.dataStore.ui.sendToDate != '' ? this.props.dataStore.ui.sendToDate : this.Today(),
            'FileName': this.props.dataStore.ui.sendFileName != '' ? this.props.dataStore.ui.sendFileName + '*' : this.props.dataStore.ui.sendFileName,
            'FileType': this.props.dataStore.ui.sendFileType != '' ? this.props.dataStore.ui.sendFileType + '*' : this.props.dataStore.ui.sendFileType
        }

        if (this.props.dataStore.ui.selectedTabIndex == 1) {
            this.setState({ supportDownloadFilesData: [] })
            await this.props.action.ui.getSupportDownloadFiles(request);
            this.getSupportDownloadFiles()
        } else {
            this.setState({ reportDownloadFilesData: [] })
            await this.props.action.ui.getReportDownloadFiles(request);
            this.getReportDownloadFiles();
        }
    }

    public getSupportDownloadFiles() {
        if (this.props.dataStore.ui.SupportDownloadFiles?.DownloadFiles.Files !== undefined && this.props.dataStore.ui.SupportDownloadFiles?.DownloadFiles.Files.length > 0) {
            this.setState({ updating: false });
            this.setState({ supportDownloadFilesAPIData: this.props.dataStore.ui.SupportDownloadFiles?.DownloadFiles.Files }, () => { this.buildSupportDownloadFilesGridData(); });
        } else {
            this.setState({ updating: false });
        }
    }

    public getReportDownloadFiles() {
        if (this.props.dataStore.ui.ReportDownloadFiles?.downloadFiles.fileList !== undefined && this.props.dataStore.ui.ReportDownloadFiles?.downloadFiles.fileList.length > 0) {
            this.setState({ updating: false });
            this.setState({ reportDownloadFilesAPIData: this.props.dataStore.ui.ReportDownloadFiles?.downloadFiles.fileList }, () => { this.buildReportDownloadFilesGridData(); });
        } else {
            this.setState({ updating: false });
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {

        this.props.history.push('/LandingPage');
    }

    /* -----------------------------------  */
    /* -----  DATA HELP METHODS ----------  */
    /* -----------------------------------  */

    getReportDownloadFilesGridColumnHeaders() {
        return new Set([
            {
                dataName: "Created",
                text: 'Date',
                sortable: true,
                cellType: "text",
                isSorted: 1,
            },
            {
                dataName: "Name",
                text: "Name",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Type",
                text: "Type",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Operation",
                text: "Download",
                sortable: false,
                cellType: "custom",
                isSorted: 0,
            }
        ]);
    }

    getSupportDownloadFilesGridColumnHeaders() {
        return new Set([
            {
                dataName: "Created",
                text: 'Created',
                sortable: true,
                cellType: "text",
                isSorted: 1,
            },
            {
                dataName: "Name",
                text: "Name",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Size",
                text: "Size",
                sortable: true,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Filters",
                text: "Type",
                sortable: false,
                cellType: "text",
                isSorted: 0,
            },
            {
                dataName: "Operation",
                text: "Delete",
                sortable: false,
                cellType: "custom",
                isSorted: 0,
            }
        ]);
    }

    public handleSort(sortIndicator: ISortIndicator) {
        const { supportDownloadFilesData } = this.state;
        let sortedRecords: SupportDownloadFilesData[] = [];

        switch (sortIndicator.sortColumn) {
            case 'Created': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    supportDownloadFilesData.sort((a, b) => b.UTC - a.UTC) :
                    supportDownloadFilesData.sort((a, b) => a.UTC - b.UTC);
            }
                break;
            case 'Name': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    supportDownloadFilesData.sort((a, b) => (a.SupportDownloadFileName).toString().localeCompare((b.SupportDownloadFileName).toString())) :
                    supportDownloadFilesData.sort((a, b) => (b.SupportDownloadFileName).toString().localeCompare((a.SupportDownloadFileName).toString()));
            }
                break;
            case 'Size': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    supportDownloadFilesData.sort((a, b) => this.getSizeAsNumber(a.Size) - this.getSizeAsNumber(b.Size)) :
                    supportDownloadFilesData.sort((a, b) => this.getSizeAsNumber(b.Size) - this.getSizeAsNumber(a.Size));
            }
            case 'Operations': {
                sortedRecords = supportDownloadFilesData;
            }
                break;
        }
        this.setState({ sortIndicator, supportDownloadFilesData: sortedRecords });
    }

    public handleSortReport(sortIndicator: ISortIndicator) {

        const { reportDownloadFilesData } = this.state;
        let sortedRecords: ReportDownloadFilesData[] = [];

        switch (sortIndicator.sortColumn) {
            case 'date': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    reportDownloadFilesData.sort((a, b) => b.DateSort - a.DateSort) :
                    reportDownloadFilesData.sort((a, b) => a.DateSort - b.DateSort);
            }
                break;
            case 'fileName': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    reportDownloadFilesData.sort((a, b) => (a.ReportDownloadFileName).toString().localeCompare((b.ReportDownloadFileName).toString())) :
                    reportDownloadFilesData.sort((a, b) => (b.ReportDownloadFileName).toString().localeCompare((a.ReportDownloadFileName).toString()));
            }
                break;
            case 'fileTypeName': {
                sortedRecords = sortIndicator.sortDirection == 'up' ?
                    reportDownloadFilesData.sort((a, b) => (a.Type).toString().localeCompare((b.Type).toString())) :
                    reportDownloadFilesData.sort((a, b) => (b.Type).toString().localeCompare((a.Type).toString()));
            }
                break;


        }
        this.setState({ sortIndicator, reportDownloadFilesData: sortedRecords });
    }

    buildSupportDownloadFilesGridData = (supportDownloadType: string = "All Files"): SupportDownloadFilesData[] => {
        const { supportDownloadFilesAPIData } = this.state;
        let supportDownloadFilesData: SupportDownloadFilesData[] = this.buildSupportDownloadFilesGridDataWithParam(supportDownloadType, supportDownloadFilesAPIData);

        this.setState({ supportDownloadFilesData }, () => {
            this.setState({
                pageCountSupportDownload: 1,
                pageTotalSupportDownload: Math.ceil(supportDownloadFilesData.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(supportDownloadFilesData.length / this.PAGE_SIZE),
                diskSpace: this.computeDiskSpace(),
            },
                () => {
                    let sortIndicator: ISortIndicator = {
                        sortColumn: 'Created',
                        sortDirection: 'down'
                    };
                    this.handleSort(sortIndicator);
                });
        });
        return supportDownloadFilesData;
    }

    buildReportDownloadFilesGridData = (): ReportDownloadFilesData[] => {
        const { reportDownloadFilesAPIData } = this.state;
        let reportDownloadFilesData: ReportDownloadFilesData[] = this.buildReportDownloadFilesGridDataWithParam(reportDownloadFilesAPIData);

        this.setState({ reportDownloadFilesData }, () => {
            this.setState({
                pageCountSupportDownload: 1,
                pageTotalSupportDownload: Math.ceil(reportDownloadFilesData.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(reportDownloadFilesData.length / this.PAGE_SIZE),
                ReportdiskSpace: this.computeReportDiskSpace(),
            },
                () => {
                    let sortIndicator: ISortIndicator = {
                        sortColumn: 'date',
                        sortDirection: 'down'
                    };
                    this.handleSortReport(sortIndicator);
                });
        });
        return reportDownloadFilesData;
    }

    buildSupportDownloadFilesGridDataWithParam = (supportDownloadType: string, supportDownloadFilesAPIData: any): SupportDownloadFilesData[] => {

        let supportDownloadFilesData: SupportDownloadFilesData[] = [];
        let supportDownloadInfo: SupportDownloadFilesData[] = this.buildSelectedReportFileInfo(supportDownloadType, supportDownloadFilesAPIData);

        if (supportDownloadInfo && supportDownloadInfo.length > 0) {
            for (let idx = 0; idx < supportDownloadInfo.length; idx++) {
                let supportDownload: SupportDownloadFilesData = new SupportDownloadFilesData();
                if (supportDownloadInfo[idx]) {
                    supportDownload.Created = supportDownloadInfo[idx].Modified;
                    supportDownload.UTC = supportDownloadInfo[idx].UTC;
                    let fileType: string = "";
                    fileType = supportDownloadInfo[idx].Type.split(" ")[0];

                    supportDownload.Name = <a style={{ cursor: "pointer", textDecoration: 'underline' }}
                        href={void (0)}
                        onClick={async (x) => {
                            x.stopPropagation();
                            this.getOperationDownloadOrEncryption(this.state.inEncryptDownload, supportDownloadInfo[idx].ParentFolder, supportDownloadInfo[idx].Name)
                        }} > {supportDownloadInfo[idx].Name} </a >

                    supportDownload.SupportDownloadFileName = supportDownloadInfo[idx].Name;
                    let supportDownloadType = supportDownloadInfo[idx].ParentFolder.replace('_', ' ');
                    supportDownload.Size = supportDownloadInfo[idx].Size + " KB";
                    supportDownload.Filters = supportDownloadType + " (" + fileType + ")";
                    supportDownload.ParentFolder = supportDownloadInfo[idx].ParentFolder;

                    supportDownload.Operation = new CustomCheckBox("checkbox", idx);
                    if (supportDownloadInfo[idx].Type !== "$$$ File" &&
                        supportDownloadInfo[idx].ParentFolder !== "" &&
                        supportDownload &&
                        supportDownload.Name &&
                        supportDownload.Name !== "") {
                        supportDownloadFilesData.push(supportDownload);
                    }
                }
            }
            this.computeDiskSpace();
        }
        return supportDownloadFilesData;
    }

    buildReportDownloadFilesGridDataWithParam = (reportDownloadFilesAPIData: any): ReportDownloadFilesData[] => {

        let reportDownloadFilesData: ReportDownloadFilesData[] = [];
        let reportDownloadInfo = reportDownloadFilesAPIData;

        if (reportDownloadInfo && reportDownloadInfo.length > 0) {
            for (let idx = 0; idx < reportDownloadInfo.length; idx++) {
                let reportDownload: ReportDownloadFilesData = new ReportDownloadFilesData();
                if (reportDownloadInfo[idx]) {
                    reportDownload.Created = reportDownloadInfo[idx].date?.dateTime;
                    reportDownload.Type = reportDownloadInfo[idx].fileTypeName;
                    reportDownload.DateSort = reportDownloadInfo[idx].date?.dateSort;
                    reportDownload.FileName = reportDownloadInfo[idx].fileName;
                    reportDownload.FileID = reportDownloadInfo[idx].id;
                    reportDownload.Name = <a style={{ cursor: "pointer", textDecoration: 'underline' }}
                        href={void (0)}
                        onClick={async (x) => {
                            x.stopPropagation();
                            this.getOperationReportDownloadEncryption(this.state.inEncryptDownload, reportDownload.FileID, reportDownload.FileName)
                        }} > {reportDownloadInfo[idx].fileName} </a >

                    reportDownload.ReportDownloadFileName = reportDownloadInfo[idx].fileName;
                    reportDownload.Size = reportDownloadInfo[idx].Size + " KB";
                    reportDownload.Operation = new CustomCheckBox("checkbox", idx);
                    reportDownloadFilesData.push(reportDownload);
                }
            }
            this.computeReportDiskSpace();
        }
        return reportDownloadFilesData;
    }

    //Support
    async getOperationDownloadOrEncryption(isEncryptionEnabled: any, parentFolder: string, fileName: string) {
        if (isEncryptionEnabled) {

            const request = { "Files": [{ "Name": fileName, "ParentFolder": parentFolder }] }
            console.log('Encrypt request', request)
            await this.props.action.ui.encryptSupportDownloadFiles(request);
            console.log('zipFileName', this.props.dataStore.ui.zipFileName)

            if (this.props.dataStore.ui.zipFileName.indexOf(".zip") > 0) {
                let uri = this.props.dataStore.ui.zipFileName;
                if (uri.startsWith('"')) {
                    uri = uri.substring(1);
                }
                if (uri.endsWith('"')) {
                    uri = uri.substring(0, uri.length - 1);
                }
                let segments = this.props.dataStore.ui.zipFileName.split("\\");
                let fileName = segments[segments.length - 1];
                console.log("fileName:[" + fileName + "]uri:[" + uri + "]");
                ApiClient.downloadZIP(uri, uri);
            }
        }

        else {
            console.log(fileName)
            await new FileDownloader().downloadFile(`${'\\Download'}` + "\\" + `${parentFolder}` + "\\" + `${fileName}`);
        }
    }

    //Report
    async getOperationReportDownloadEncryption(isEncryptionEnabled: any, id: string, fileName: string) {
        if (isEncryptionEnabled) {
           
            let clientSidePathDetails = id + '/' + fileName;
            const request = { "fileList": [{ "clientSidePath": clientSidePathDetails }] }

            await this.props.action.ui.encryptReportDownloadFiles(request);
            console.log('zipReportFileName1', this.props.dataStore.ui.zipReportFileName)

            if (this.props.dataStore.ui.zipReportFileName.indexOf(".zip") > 0) {
                let uri = this.props.dataStore.ui.zipReportFileName;
                if (uri.startsWith('"')) {
                    uri = uri.substring(1);
                }
                if (uri.endsWith('"')) {
                    uri = uri.substring(0, uri.length - 1);
                }
                let segments = this.props.dataStore.ui.zipReportFileName.split("\\");
                let fileName = segments[segments.length - 1];
                console.log("fileName:[" + fileName + "]uri:[" + uri + "]");
                ApiClient.downloadZIP(uri, uri);
            }
        }
        else {
            console.log(fileName)
            let uri = id + '/' + fileName
            await new FileDownloader().downloadFile(`${uri}`)
        }
    }

    async download(request: any) {
        await this.props.action.ui.downloadReportDownloadFiles(request);
        this.selectedReportDownloadFiles = [];
        
        if (this.props.dataStore.ui.zipDownloadFilePath.indexOf(".zip") > 0) {
            let uri = this.props.dataStore.ui.zipDownloadFilePath;
            if (uri.startsWith('"')) {
                uri = uri.substring(1);
            }
            if (uri.endsWith('"')) {
                uri = uri.substring(0, uri.length - 1);
            }
            let segments = this.props.dataStore.ui.zipDownloadFilePath.split("\\");
            let fileName = segments[segments.length - 1];
            console.log("fileName:[" + fileName + "]uri:[" + uri + "]");
            ApiClient.downloadZIP(uri, uri);

        }

    }

    buildSelectedReportFileInfo(selectedSupportDownloadType: string = "all files", supportDownloadFilesAPIData: any): SupportDownloadFilesData[] {
        let supportDownloadInfo: SupportDownloadFilesData[] = [];

        if (selectedSupportDownloadType && selectedSupportDownloadType.length > 0) {
            if (supportDownloadFilesAPIData && supportDownloadFilesAPIData instanceof Array && supportDownloadFilesAPIData.length > 0) {
                for (let idx = 0; idx < supportDownloadFilesAPIData.length; idx++) {

                    let reportFileInfo: SupportDownloadFilesData = supportDownloadFilesAPIData[idx];
                    let files = reportFileInfo.ParentFolder?.replace('_', ' ').toLowerCase()
                    if (files != "" &&
                        (selectedSupportDownloadType.toLowerCase() === "all files" || files.toLowerCase() === selectedSupportDownloadType.toLowerCase())) {
                        supportDownloadInfo.push(reportFileInfo);
                    }
                }
            }
        }
        return supportDownloadInfo;
    }

    getSelectedReportType(): string {
        let filter: string = "all files";
        this.getSupportDownloadFilesGridColumnHeaders().forEach(function (header) {
            if (header.text.toLowerCase() === "claim export" || header.text.toLowerCase() === "system data") {
                filter = header.text.toLowerCase();
            }
        });
        return filter;
    }

    public previousPage(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            pageTotalSupportDownload,
            pageCountSupportDownload,
            pageTotalReportDownload,
            pageCountReportDownload
        } = this.state;

        if (pageTotalSupportDownload >= pageCountSupportDownload) {
            this.setState({
                pageCountSupportDownload: pageCountSupportDownload - 1,
                supportDownloadFilesData: this.buildSupportDownloadFilesGridData(this.getSelectedReportType()),
                diskSpace: this.computeDiskSpace()
            });
            e.target.value = pageCountSupportDownload.toString();
        }
        else if (pageTotalReportDownload >= pageCountReportDownload) {
            this.setState({
                pageCountReportDownload: pageCountReportDownload - 1,
                reportDownloadFilesData: this.buildReportDownloadFilesGridData(),
                ReportdiskSpace: this.computeReportDiskSpace()
            });
            e.target.value = pageCountReportDownload.toString();
        }
    }

    public nextPage(e: React.ChangeEvent<HTMLButtonElement>) {
        const {
            supportDownloadFilesData,
            pageCountSupportDownload,
            pageTotalSupportDownload,
            pageCountReportDownload,
            pageTotalReportDownload,
            reportDownloadFilesData
        } = this.state;

        if (pageCountSupportDownload < pageTotalSupportDownload) {
            this.setState({
                pageCountSupportDownload: pageCountSupportDownload + 1,
                supportDownloadFilesData: supportDownloadFilesData.slice(this.PAGE_SIZE),
                diskSpace: this.computeDiskSpace(),
            });
            e.target.value = pageCountSupportDownload.toString();
        }
        else if (pageCountReportDownload < pageTotalReportDownload) {
            this.setState({
                pageCountReportDownload: pageCountReportDownload + 1,
                reportDownloadFilesData: reportDownloadFilesData.slice(this.PAGE_SIZE),
                ReportdiskSpace: this.computeReportDiskSpace(),
            });
            e.target.value = pageCountSupportDownload.toString();
        }
    }

    private computeDiskSpace = (): number => {
        const { supportDownloadFilesData } = this.state;
        let diskSpace: number = 0;
        if (supportDownloadFilesData) {
            for (let idx = 0; idx < supportDownloadFilesData.length; idx++) {
                let kbIndex: number = supportDownloadFilesData[idx].Size.indexOf(" KB");
                if (kbIndex != -1) {
                    diskSpace += +(supportDownloadFilesData[idx].Size.substring(0, kbIndex));
                }
            }
        }

        return diskSpace;
    }
    private computeReportDiskSpace = (): number => {
        const { reportDownloadFilesData } = this.state;
        let ReportdiskSpace: number = 0;
        if (reportDownloadFilesData) {
            for (let idx = 0; idx < reportDownloadFilesData.length; idx++) {
                let kbIndex: number = reportDownloadFilesData[idx].Size.indexOf(" KB");
                if (kbIndex != -1) {
                    ReportdiskSpace += +(reportDownloadFilesData[idx].Size.substring(0, kbIndex));
                }
            }
        }

        return ReportdiskSpace
            ;
    }

    private getSizeAsNumber(size: string): number {
        let sizeAsNumber: number = 0;
        if (size) {
            let kbIndex: number = size.indexOf(" KB");
            if (kbIndex != -1) {
                sizeAsNumber = +(size.substring(0, kbIndex));
            }
        }
        return sizeAsNumber;
    }


    private getReportNameWithPath(record: any): SupportDownloadFilesData {
        let selectedData: SupportDownloadFilesData = new SupportDownloadFilesData();

        if (record) {
            selectedData = record;
        }
        return selectedData;
    }
    private getReportDownloadNameWithPath(record: any): ReportDownloadFilesData {
        let selectedData: ReportDownloadFilesData = new ReportDownloadFilesData();

        if (record) {
            selectedData = record;
        }
        return selectedData;
    }

    // builds Selected Reports
    public onSelectReportSupportDownloadFiles(isSelected: boolean, record: any) {

        let selectedRow: SupportDownloadFilesData = this.getReportNameWithPath(record);

        if (isSelected !== undefined) {
            if (selectedRow) {
                if (isSelected === true) {
                    console.log("add [", selectedRow, "] to the selected report list.");
                    let supportDownloadDataSelect: SupportDownloadDataSelect = new SupportDownloadDataSelect();
                    supportDownloadDataSelect.Name = selectedRow.SupportDownloadFileName;
                    supportDownloadDataSelect.ParentFolder = selectedRow.ParentFolder;
                    this.selectedSupportDownloadFiles.push(supportDownloadDataSelect);
                } else if (isSelected === false) {
                    if (this.selectedSupportDownloadFiles && this.selectedSupportDownloadFiles.length > 0) {
                        let index = this.selectedSupportDownloadFiles.findIndex(name => name.Name === selectedRow.SupportDownloadFileName)
                        if (index > -1) {
                            this.selectedSupportDownloadFiles.splice(index, 1);
                        }
                        console.log("remove [", selectedRow.Name, "] from the list. Report list has ", this.selectedSupportDownloadFiles.length, " reports");
                    }
                }
                console.log("selected report list has[", this.selectedSupportDownloadFiles.length, "] reports for Operations");
            }
        }
    }

    public onSelectFileReportDownloadFiles(isSelected: boolean, record: any) {

        let selectedRow: ReportDownloadFilesData = this.getReportDownloadNameWithPath(record);

        if (this.state.selectedOperation == "download") {
            if (isSelected !== undefined) {
                if (selectedRow) {
                    if (isSelected === true) {
                        console.log("add [", selectedRow, "] to the selected report list.");

                        let reportDownloadDataSelect: ReportDownloadDataSelect = new ReportDownloadDataSelect();
                        reportDownloadDataSelect.id = selectedRow.FileID;
                        reportDownloadDataSelect.date.dateTime = selectedRow.Created;
                        reportDownloadDataSelect.date.dateSort = selectedRow.DateSort;
                        reportDownloadDataSelect.clientSidePath = null;
                        reportDownloadDataSelect.encryptedPath = null;
                        reportDownloadDataSelect.fileName = selectedRow.FileName;
                        reportDownloadDataSelect.fileTypeName = selectedRow.Type;
                        reportDownloadDataSelect.delete = "false";
                        reportDownloadDataSelect.deleteChecked = false;
                        reportDownloadDataSelect.downloadChecked = true;
                        this.selectedReportDownloadFiles.push(reportDownloadDataSelect);

                    } else if (isSelected === false) {
                        if (this.selectedReportDownloadFiles && this.selectedReportDownloadFiles.length > 0) {

                            let index = this.selectedReportDownloadFiles.findIndex(name => name.id === selectedRow.FileID)
                            if (index > -1) {
                                this.selectedReportDownloadFiles.splice(index, 1);
                            }
                            console.log("remove [", selectedRow.FileID, "] from the list. Report list has ", this.selectedReportDownloadFiles.length, " reports");
                        }
                    }
                    console.log("selected report list has[", this.selectedReportDownloadFiles.length, "] reports for Operations");
                }
            }
        }
        else if (this.state.selectedOperation == "delete") {
            if (isSelected !== undefined) {
                if (selectedRow) {
                    if (isSelected === true) {
                        console.log("add [", selectedRow, "] to the selected report list.");

                        let reportDownloadDataSelect: ReportDownloadDataSelect = new ReportDownloadDataSelect();
                        reportDownloadDataSelect.id = selectedRow.FileID;
                        reportDownloadDataSelect.date.dateTime = selectedRow.Created;
                        reportDownloadDataSelect.date.dateSort = selectedRow.DateSort;
                        reportDownloadDataSelect.clientSidePath = null;
                        reportDownloadDataSelect.encryptedPath = null;
                        reportDownloadDataSelect.fileName = selectedRow.FileName;
                        reportDownloadDataSelect.fileTypeName = selectedRow.Type;
                        reportDownloadDataSelect.delete = "false";
                        reportDownloadDataSelect.deleteChecked = true;
                        reportDownloadDataSelect.downloadChecked = false;
                        this.selectedReportDownloadFiles.push(reportDownloadDataSelect);


                    } else if (isSelected === false) {
                        if (this.selectedReportDownloadFiles && this.selectedReportDownloadFiles.length > 0) {

                            let index = this.selectedReportDownloadFiles.findIndex(name => name.id === selectedRow.FileID)
                            if (index > -1) {
                                this.selectedReportDownloadFiles.splice(index, 1);
                            }
                            console.log("remove [", selectedRow.FileID, "] from the list. Report list has ", this.selectedReportDownloadFiles.length, " reports");
                        }
                    }
                    console.log("selected report list has[", this.selectedReportDownloadFiles.length, "] reports for Operations");
                }
            }

        }
    }


    NumberFormat = (diskSpace: number): string => {
        return diskSpace.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    NumberFormater = (ReportdiskSpace: number): string => {
        return ReportdiskSpace.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    public moveCursorToEnd(id: any) {
        var el: any = document.getElementById(id)
        let trimmedEl = el.value.trim();
        el.focus()
        if (el.value.endsWith(' ')) {
            var len = trimmedEl.length;
            if (el.setSelectionRange && el.selectionStart > len) {
                el.focus();
                el.setSelectionRange(len, len);
            } else if (el.createTextRange) {
                var t = el.createTextRange();
                t.collapse(true);
                t.moveEnd('character', len);
                t.moveStart('character', len);
                t.select();
            }
        }
    }

    public trimOnBlur(id: string) {
        let el: any = document.getElementById(id)
        if (el.value.endsWith(' ')) {
            el.value = el.value.slice().trim();
            let newVal = el.value.slice().trim();
            el.setAttribute('value', newVal)
        }
        el.blur()
    }

    public showSupportDownloadFilesAlers() {
        if (this.props.dataStore.ui.selectedTabIndex == 1) {
            return (
                <ModalConfirmation
                    isOpen={this.state.isOpen}
                    formattedMessage={(
                        <React.Fragment>
                            {this.state.selectedOperation === 'delete' && this.state.selectedSupportDownloadFiles.length > 0 ? this.DELETE_REPORT_ALERT :
                                <p>Please select report(s) to {this.state.selectedOperation}.</p>}
                        </React.Fragment>)
                    }
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isOpen: false }) }}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.setState({ isOpen: false })
                        if (this.state.selectedOperation === 'delete' && this.state.selectedReportDownloadFiles.length > 0) {
                            const request = { Files: this.selectedSupportDownloadFiles }
                            this.props.action.ui.deleteSupportDownloadFiles(request);
                            this.props.history.push("/LandingPage");

                        } else {
                            const request = { Files: this.selectedSupportDownloadFiles }
                            this.props.action.ui.encryptSupportDownloadFiles(request);
                        }
                    }
                    }
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isOpen: false }) }}
                    alertMode={this.state.selectedSupportDownloadFiles.length === 0}
                />
            );
        }
        else if (this.props.dataStore.ui.selectedTabIndex == 0) {
            var downloadList = this.state;
            return (
                downloadList.selectedOperation != 'download' && downloadList.selectedReportDownloadFiles.length != 0 &&
                <ModalConfirmation
                    isOpen={this.state.isOpen}
                    formattedMessage={(
                        <React.Fragment>
                            {downloadList.selectedReportDownloadFiles.length > 0 ? this.DELETE_REPORT_ALERT :
                                <p>Please select report(s) to {this.state.selectedOperation}.</p>}
                        </React.Fragment>)
                    }
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => { this.setState({ isOpen: false }) }}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => {
                        this.setState({ isOpen: false })
                        if (downloadList.selectedOperation === 'delete' && downloadList.selectedReportDownloadFiles.length > 0) {
                            const request = { fileList: downloadList.selectedReportDownloadFiles };
                            this.props.action.ui.DeleteDownloadFiles(request);
                            this.props.history.go(0);
                        } else {
                            const request = { fileList: downloadList.selectedReportDownloadFiles }
                            this.download(request);
                        }
                    }
                    }
                    onDeny={(e: React.MouseEvent<HTMLButtonElement>) => { this.setState({ isOpen: false }) }}
                    alertMode={this.state.selectedReportDownloadFiles.length === 0}
                />
            );
        }
    }


    /* -----------------------------------  */
    /* -------------  RENDER -------------  */
    /* -----------------------------------  */

    public render() {
        let tabList: ITabItem[] = [{ label: 'Report', domID: 'id-Report' }, { label: 'Support', domID: 'id-Support' }];
        let encryptEnabled = this.props.dataStore.ui.deIDEncryptEnabled;

        var instruction = <React.Fragment>Click the file name to download it to your system. You will have the option of opening it or saving it to disk. Click the column headings to sort the file list.</React.Fragment>;
        var buttons = <OKCancelButtons
            nameOverride={this.state.selectedOperation}
            disableOK={!this.props.canEdit}
            onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOKDownloadFileReport(e)}
            onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        const reportFilterTypeData: { reportFilterType: string }[] = [
            { reportFilterType: 'All Files' },
            { reportFilterType: 'Claim Export' },
            { reportFilterType: 'System Data' }
        ]

        // Report Type Filter
        const options = reportFilterTypeData.map(flt => <option
            value={flt.reportFilterType}
            selected={this.state.selectedFilterType === flt.reportFilterType}>
            {flt.reportFilterType}
        </option>);

        const reportFilters =
            <select onChange={filter => this.setState(
                {
                    selectedFilterType: filter.target.value,
                    supportDownloadFilesData: this.buildSupportDownloadFilesGridData(filter.target.value),
                    reportDownloadFilesData: this.buildReportDownloadFilesGridData(),
                    pageCountSupportDownload: 1,
                    pageCountReportDownload: 1,
                    pageTotalSupportDownload: Math.ceil(this.state.supportDownloadFilesData.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(this.state.supportDownloadFilesData.length / this.PAGE_SIZE),
                    pageTotalReportDownload: Math.ceil(this.state.reportDownloadFilesData.length / this.PAGE_SIZE) == 0 ? 1 : Math.ceil(this.state.reportDownloadFilesData.length / this.PAGE_SIZE),
                    diskSpace: this.computeDiskSpace(),
                    ReportdiskSpace: this.computeReportDiskSpace(),
                })}>
                {options}
            </select>;

        const reportOperations =
            <select disabled={!this.props.canDelete} value={this.state.selectedOperation} onChange={operation => this.setSelectedOperation(operation.target.selectedIndex)}>
                <option value='download'>Download</option>
                <option value='delete'>Delete</option>
            </select>;

        const headerReport = ({ columns }: HeaderProps) => <thead><tr>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="date"
                        text="Date"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSortReport}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="fileName"
                        text="Name"
                        cellType="link"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSortReport}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="fileTypeName"
                        text="Type"
                        cellType="text"
                        sortable={true
                        }
                        isSorted={0}
                        sortHandler={this.handleSortReport}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="operations-report"
                        text="Download"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                        sortHandler={this.handleSortReport}
                        sortIndicator={this.state.sortIndicator}
                        select={reportOperations}
                    />
                </React.Fragment>
            }
        </tr></thead>

        const headerSupport = ({ columns }: HeaderProps) => <thead><tr>
            {
                <React.Fragment>
                    <ARMHeaderCell
                        dataName="Created"
                        text="Created"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Name"
                        text="Name"
                        cellType="link"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Size"
                        text="Size"
                        cellType="text"
                        sortable={true}
                        isSorted={1}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                    <ARMHeaderCell
                        dataName="Files"
                        text="All Files"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                        select={reportFilters}
                    />
                    <ARMHeaderCell
                        dataName="Operations"
                        text="Delete"
                        cellType="text"
                        sortable={false}
                        isSorted={0}
                        sortHandler={this.handleSort}
                        sortIndicator={this.state.sortIndicator}
                    />
                </React.Fragment>
            }
        </tr></thead>

        let footerMsg: string = '0 report(s) available taking 0 KB';
        if (this.props.dataStore.ui.selectedTabIndex == 1) {
            if (this.state.supportDownloadFilesData) {
                let diskSpace: number = this.computeDiskSpace();
                footerMsg = `${this.state.supportDownloadFilesData.length} report(s) available taking ${this.NumberFormat(diskSpace)} KB`;
            }
        }
        else if (this.props.dataStore.ui.selectedTabIndex == 0) {
            if (this.state.reportDownloadFilesData) {
                let ReportdiskSpace: number = this.computeReportDiskSpace();
                footerMsg = `${this.state.reportDownloadFilesData.length} report(s) available  `;
            }
        }


        return (
            <React.Fragment >
                <DialogWrapper title={this.props.title} instruction={instruction} helpUrl='/Support/Help/HELP_DownloadFiles.htm' buttons={buttons} isBusy={this.state.updating} >
                    {this.state.cancelLeave && <AssuranceMenu {...this.props} Cancel={true} MenuUrl='' stayEvent={() => this.setState({ cancelLeave: false })} />}
                    <ContentWrapper>
                        <ContentRowWrapper id={'column_downloadfiles'}>
                            <DialogFieldset id='fieldset'>
                                <DialogLegend>File Search</DialogLegend>
                                <ContentRowWrapper >
                                    <DialogLabel id='from-label'>From:</DialogLabel>
                                    <div id='from-date'>
                                        <ARMDatePicker
                                            domID="downloadFiles_FromDate"
                                            className="cal_style"
                                            onInputChange={(e: any) => this.onChangeSearchReportFromDate(e)}
                                            allowTime={true}
                                            minDate={this.minDate}
                                            maxDate={this.maxDate}
                                            assocTo={this.state.selectedToDate}
                                            initialDate={this.getDateInMMDDYYYYFormat(new Date())}
                                            disableHowLong={false}
                                        //value={this.props.dataStore.ui.sendFromDate}
                                        />
                                    </div>
                                    <DialogLabel id='to-label'>To:</DialogLabel>
                                    <div id='to-date'>
                                        <ARMDatePicker
                                            domID="downloadFiles_FromTo"
                                            className="cal_style"
                                            onInputChange={(e: any) => this.onChangeSearchReportToDate(e)}
                                            allowTime={true}
                                            minDate={this.minDate}
                                            maxDate={this.maxDate}
                                            assocFrom={this.state.selectedFromDate}
                                            initialDate={this.getDateInMMDDYYYYFormat(new Date())}
                                            disableHowLong={true}
                                        />
                                    </div>
                                    <Input
                                        id='search-filename'
                                        domID="search-filename"
                                        className="text-input"
                                        placeholder="File Name   e.g., c36*.*"
                                        maxLength={40}
                                        value={this.props.dataStore.ui.sendFileName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.onChangeSearchReportFileName(e.target.value.trim())
                                        }}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.onChangeSearchReportFileName(e.target.value.trim())
                                        }}
                                    />
                                    <Input
                                        id='search-filetype'
                                        domID="search-filetype"
                                        className="text-input"
                                        placeholder="File Type   e.g., 277*"
                                        maxLength={20}
                                        value={this.props.dataStore.ui.sendFileType}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.onChangeSearchReportFileType(e.target.value.trim())
                                        }}
                                        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            this.onChangeSearchReportFileType(e.target.value.trim())
                                        }}
                                    />
                                    <SelectButtons >
                                        <Button
                                            domID="searchButton"
                                            name={"Search"}
                                            buttonType="standard"
                                            size="small"
                                            type="button"
                                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                                this.onClickModifyButton(e)
                                            }}
                                        />
                                    </SelectButtons>
                                </ContentRowWrapper>
                            </DialogFieldset>
                        </ContentRowWrapper>

                        <ContentRowWrapper>
                            <DialogFieldset height='100%' width='1008px' >
                                <Tabs
                                    tabs={tabList}
                                    onTabSelect={(e: React.MouseEvent<HTMLButtonElement>, selObj: any) => this.onSelectTab(e, selObj)}
                                    selectedTabIndex={this.props.dataStore.ui.selectedTabIndex}>
                                </Tabs>
                                {this.props.dataStore.ui.selectedTabIndex !== 1 ?
                                    (
                                        <ContentWrapper>
                                            <ARMGrid
                                                dom-ID="report-id"
                                                onClickThrough={(e: React.ChangeEvent<HTMLInputElement>, record: any) => this.onSelectFileReportDownloadFiles(e.target.checked, record)}
                                                isFixedHeader={true}
                                                maxHeight='492px'
                                                columns={this.getReportDownloadFilesGridColumnHeaders()}
                                                headerComponent={headerReport}
                                                records={this.state.reportDownloadFilesData}
                                                selectionKey='empty'
                                            />
                                        </ContentWrapper>


                                    ) :
                                    (
                                        <ContentWrapper>
                                            <ARMGrid
                                                dom-ID="support-id"
                                                onClickThrough={(e: React.ChangeEvent<HTMLInputElement>, record: any) => this.onSelectReportSupportDownloadFiles(e.target.checked, record)}
                                                isFixedHeader={true}
                                                maxHeight='492px'
                                                columns={this.getSupportDownloadFilesGridColumnHeaders()}
                                                headerComponent={headerSupport}
                                                records={this.state.supportDownloadFilesData}
                                                selectionKey='empty'
                                            />
                                        </ContentWrapper>
                                    )
                                }
                                <br></br>
                                <ContentRowWrapper >
                                    <div id='encrypt-download'>
                                        {
                                            this.props.canSecCheck1 && this.props.dataStore.ui.deIDEncryptEnabled == "1" ? (
                                                <CheckBoxComponent
                                                    id="EncryptDownload"
                                                    checked={this.state.inEncryptDownload}
                                                    onChange={() => { this.onCheckEncryptDownload(); }}
                                                    label="Encrypt Download"
                                                    width='410'
                                                    disabled={false}
                                                    hasError={false}
                                                />
                                            ) : ""
                                        }
                                    </div>
                                    <div id='footer-instructions' >
                                        <FooterDownloadFiles
                                            footerMsgs={[footerMsg]}
                                            pageCount={this.props.dataStore.ui.selectedTabIndex == 1 ? this.state.pageCountSupportDownload : this.state.pageCountReportDownload}
                                            pageTotal={this.props.dataStore.ui.selectedTabIndex == 1 ? this.state.pageTotalSupportDownload : this.state.pageTotalReportDownload}
                                            onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.previousPage(e)}
                                            onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.nextPage(e)}
                                        />
                                    </div>
                                </ContentRowWrapper>
                            </DialogFieldset>
                        </ContentRowWrapper>
                    </ContentWrapper>
                    {this.showSupportDownloadFilesAlers()}
                </DialogWrapper>
            </React.Fragment >
        );
    }
};

var connectedHoc = connect<IDownloadFilesState, IDownloadFilesActionProps, IOwnProps, IDownloadFilesProps, ApplicationState>(
    createCrudMapStateToProps('downloadFiles'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(DownloadFiles);

export default withRouter(connectedHoc);
