import { Action, Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { deepCopyFunction } from "@commonResources/validations";

// -- Mc = MasterCRUD
// -- Fls = FieldLevelSecurity
//
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IFieldLevelSecurityUIState {
    FlsGroupList: ISelectFlsGroupData;
    FlsAllFields: IMCFLSGroup;
    FlsROFields: IMCFLSGroup;

    flsGroupName?: string;
    selectedAllFieldsFieldId?: string;
    selectedItem: MCFLSGroup;
    selectedAllFields: string[];
    selectedReadOnly: string[];

    deltaCrud: MCFieldLevelDeltaType; 

    changed: boolean;
}

interface IMCFLSGroup {
    '@ID': string;
    '@Name': string;
    Fields?: MCFLSSelectedFields;
};

const emptyGroup: MCFLSGroup = { 
    '@ID': '',
    '@Name': '',
    Fields: { Field: [] },
};

const emptyDelta: MCFieldLevelDeltaType = {
    FieldLevelSecurity: {
        Groups: {}
    }
}

const emptyItem: MCFLSGroup = {
    '@ID': '',
    '@Name': '',
    Fields: { Field: [] }
};

interface ISelectedFields {
    selected: string[];
}

export interface ISelectFlsGroupData {
    index: number;
    value: string;
    text: string;
};

export const defaultFlsGroupData: ISelectFlsGroupData = {
    index: 0,
    value: '',
    text: ''
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectFlsGroup extends ICrudActionData<MCFieldLevelSecurity, ISelectFlsGroupData> { }
export interface IModifyFlsGroup extends ICrudActionData<MCFieldLevelSecurity, ISelectFlsGroupData> { }
export interface ISelectFlsAllFieldsFieldList extends ICrudActionData<MCFieldLevelSecurity, ISelectedFields> { }
export interface ISelectFlsROFieldList extends ICrudActionData<MCFieldLevelSecurity, ISelectedFields> { }

// can't add or remove groups from this page: driven from GroupMaster
export const actionCreators = {
    selectFlsGroup: (selectFLSGroup: ISelectFlsGroup) => createDataAction('SELECT_FLS_GROUP', selectFLSGroup),
    //updateFlsGroup: (updateFLSGroup: string) => createDataAction('UPDATE_FLS_GROUP', updateFLSGroup),
    selectFlsAllFields: (nothing: ISelectFlsAllFieldsFieldList) => createDataAction('SELECT_FLS_ALLFIELDS', nothing),
    selectFlsReadOnly: (nothing: ISelectFlsROFieldList) => createDataAction('SELECT_FLS_READONLY', nothing),
    addFlsFields: (flsAddFields: ISelectFlsGroup) => createDataAction('ADD_FLS_FIELDS', flsAddFields),
    removeFlsFields: (flsRemoveFields: IModifyFlsGroup) => createDataAction('REMOVE_FLS_FIELDS', flsRemoveFields),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IFieldLevelSecurityUIState = {
    FlsGroupList: defaultFlsGroupData,
    FlsAllFields: emptyGroup,
    FlsROFields: emptyGroup,

    flsGroupName: defaultFlsGroupData.value,
    selectedAllFieldsFieldId: '-1',

    selectedAllFields: [],
    selectedReadOnly: [],
    selectedItem: emptyItem,
    changed: false,
    deltaCrud: deepCopyFunction(emptyDelta)
}


/*
 *
 */
function onAddAllFieldsFieldsToReadOnly(crud: MCFieldLevelSecurity, fieldsToAdd: string[], item: IMCFLSGroup) {
    if (!item.Fields)
        item.Fields = { Field: [] };
    if (item.Fields.Field === undefined && (!item.Fields.Field || (item.Fields.Field && !Array.isArray(item.Fields.Field))))
        item.Fields.Field = [];

    let details = JSON.parse(JSON.stringify(item.Fields.Field));
    let flsRoArray = details !== undefined ? details["@ID"] !== undefined ? [{"@ID": details["@ID"]}] : item.Fields.Field.slice(): [];
    for (var flsIndex = 0; flsIndex < fieldsToAdd.length; flsIndex++) {
        console.log('adding ID[' + fieldsToAdd[flsIndex] + ']');
        flsRoArray.push({ '@ID': fieldsToAdd[flsIndex] });
    }

    // keep crud updated...
    let crudFls = crud.FieldLevelSecurity.Groups.Group.find(ob => ob["@ID"] === item['@ID']);
    if (crudFls) {
        if (!crudFls.Fields)
            crudFls.Fields = { Field: [] };
        if (!crudFls.Fields.Field || (crudFls.Fields.Field && !Array.isArray(crudFls.Fields.Field)))
            crudFls.Fields.Field = [];
        crudFls.Fields!.Field = JSON.parse(JSON.stringify(flsRoArray));
    }


    // debuks says: right here:
    // - do the delta, maybe...

    return flsRoArray;
}

/*
 *
 */
function onRemoveAllFieldsFieldsFromReadOnly(crud: MCFieldLevelSecurity, fieldsToRemove: string[], item: IMCFLSGroup) {
    if (!item.Fields)
        item.Fields = { Field: [] };
    if (!item.Fields.Field || (item.Fields.Field && !Array.isArray(item.Fields.Field)))
        item.Fields.Field = [];

    let flsRoArray = item.Fields.Field.slice();
    for (var flsIndex = 0; flsIndex < fieldsToRemove.length; flsIndex++) {
        console.log('removing ID[' + fieldsToRemove[flsIndex] + ']');
        flsRoArray = flsRoArray.filter((el) => { return fieldsToRemove.indexOf(el['@ID']) === -1 });
    }

    // keep crud updated...
    let crudFls = crud.FieldLevelSecurity.Groups.Group.find(ob => ob["@ID"] === item['@ID']);
    if (crudFls) {
        if (!crudFls.Fields)
            crudFls.Fields = { Field: [] };
        if (!crudFls.Fields.Field || (crudFls.Fields.Field && !Array.isArray(crudFls.Fields.Field)))
            crudFls.Fields.Field = [];
        crudFls.Fields!.Field = JSON.parse(JSON.stringify(flsRoArray));
    }

    // debuks says: right here:
    // - do the delta, maybe...

    return flsRoArray;
}

function removeFieldsDelta(existingGroup: MCFLSGroup, removeGroupFields: string[], deltaGroups: MCFLSGroupDelta[] | undefined) {
    if (!deltaGroups)
        deltaGroups = [];
    let existingDeltaGroup = deltaGroups.find(group => group['@ID'] === existingGroup['@ID']);

    if (existingDeltaGroup) {
        let deltaGroupFields = existingDeltaGroup?.Fields?.Field.slice();
        //let newDeltaUsers = deepCopyFunction(deltaGroupUsers);
        deltaGroupFields = deltaGroupFields ? deltaGroupFields : [];
        for (let i = 0; i < removeGroupFields.length; i++) {
            let existingDeltaUser = deltaGroupFields.find(field => field["@ID"] === removeGroupFields[i]);
            if (existingDeltaUser) {
                // was it added and removed in delta - just remove it
                deltaGroupFields = deltaGroupFields.slice().filter(field => field["@ID"] !== removeGroupFields[i]);
            }
            else
                deltaGroupFields.push(
                    {
                        "@ID": removeGroupFields[i],
                        "@Delete": "true"
                    });
        }
        if (deltaGroupFields.length > 0) {
            if (!existingDeltaGroup.Fields)
                existingDeltaGroup.Fields = { Field: [] };

            existingDeltaGroup.Fields.Field = deepCopyFunction(deltaGroupFields);
        }
        else {
            if (existingDeltaGroup.Fields)
                delete existingDeltaGroup.Fields;
        }

    }
    else {
        let newDeltaGroup: MCFLSGroupDelta = {
            "@ID": existingGroup["@ID"]
        }
        newDeltaGroup.Fields = { Field: [] };
        let newDeltaGroupFields: MCFieldLevelDelta[] = [];
        for (let i = 0; i < removeGroupFields.length; i++) {
            newDeltaGroupFields.push({
                "@ID": removeGroupFields[i],
                "@Delete": "true"
            });
        }
        newDeltaGroup.Fields.Field = newDeltaGroupFields;
        deltaGroups.push(newDeltaGroup);
    }

    return deltaGroups;
}

function addFieldsDelta(existingGroup: MCFLSGroup, newGroupFields: string[], deltaGroups: MCFLSGroupDelta[] | undefined) {
    if (!deltaGroups)
        deltaGroups = [];
    let existingDeltaGroup = deltaGroups?.find(group => group['@ID'] === existingGroup['@ID']);

    if (existingDeltaGroup) {
        let deltaGroupFields = existingDeltaGroup.Fields?.Field.slice();
        deltaGroupFields = deltaGroupFields ? deltaGroupFields : [];
        for (let i = 0; i < newGroupFields.length; i++) {
            let existingDeltaField = deltaGroupFields.find(field => field["@ID"] === newGroupFields[i]);
            if (existingDeltaField) // it was deleted and added back; remove it
                deltaGroupFields = deltaGroupFields.slice().filter(field => field["@ID"] !== newGroupFields[i]);
            else
                deltaGroupFields.push({
                    '@ID': newGroupFields[i]
                });
        }
        if (!existingDeltaGroup.Fields)
            existingDeltaGroup.Fields = { Field: [] };
        existingDeltaGroup.Fields.Field = deepCopyFunction(deltaGroupFields);
    }
    else {
        let newDeltaGroup: MCFLSGroupDelta = {
            "@ID": existingGroup["@ID"]
        }
        newDeltaGroup.Fields = { Field: [] };
        let newDeltaGroupFields: MCFieldLevelDelta[] = [];
        for (let i = 0; i < newGroupFields.length; i++) {
            newDeltaGroupFields.push({
                '@ID': newGroupFields[i]
            });
        }
        newDeltaGroup.Fields.Field = newDeltaGroupFields;
        deltaGroups.push(newDeltaGroup);
    }

    return deltaGroups;
}


function trimDeltaGroups(deltaCrud: MCFieldLevelDeltaType) {
    // if the function and group lists are empty, remove the group if it's not new and the name wasn't updated
    let deltaGroups = deltaCrud.FieldLevelSecurity.Groups.Group;
    if (!deltaGroups)
        return;

    let trimmedDelta = [];

    for (let i = 0; i < deltaGroups.length; i++) {
        let validGroup = deltaGroups[i]["@Delete"] || deltaGroups[i]["@OldName"] || deltaGroups[i]["@ID"].indexOf("#") > -1;
        let hasFields = deltaGroups[i].Fields && deltaGroups[i].Fields?.Field && deltaGroups[i]?.Fields?.Field.length > 0;

        if (validGroup || hasFields) {
            trimmedDelta.push(deepCopyFunction(deltaGroups[i]));
        }
    }
    return trimmedDelta;
}


// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IFieldLevelSecurityUIState, KnownActions> = (state: IFieldLevelSecurityUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_FLS_GROUP':
                if (action.data.masterCrud &&
                    action.data.masterCrud.FieldLevelSecurity &&
                    action.data.masterCrud.FieldLevelSecurity.Groups &&
                    action.data.masterCrud.FieldLevelSecurity.Groups.Group) {

                    //var flsGroup = action.data.masterCrud.FieldLevelSecurity.Groups.Group[action.data.uiData.index];
                    var selectedGroup = action.data.masterCrud.FieldLevelSecurity.Groups.Group[action.data.uiData.index];
                    return {
                        ...state,
                        FlsGroupList: action.data.uiData,
                        flsGroupName: selectedGroup ? selectedGroup['@Name'] || '' : '',
                        FlsROFields: selectedGroup,
                    }
                } else { console.log("FieldLevelSecurity::Reducer::SELECT_FLS_GROUP - data.masterCrud=undefined"); }
                break;
            case 'SELECT_FLS_ALLFIELDS':
                return {
                    ...state,
                    selectedAllFields: action.data.uiData.selected,
                    selectedReadOnly: [],
                    //FlsROFields: roFields,
                }
                break;
            case 'SELECT_FLS_READONLY':
                return {
                    ...state,
                    selectedAllFields: [],
                    selectedReadOnly: action.data.uiData.selected,
                }
                break;
            case 'ADD_FLS_FIELDS':
                //console.log('moving right! from the store...');
                if (action.data.masterCrud &&
                    state.selectedAllFields.length > 0) {
                    let allFields = state.selectedAllFields.slice();
                    let roFields = onAddAllFieldsFieldsToReadOnly(action.data.masterCrud, allFields, state.FlsROFields);

                    //let fieldList = action.data.masterCrud.FieldLevelSecurity.FieldList.Field;
                    //let itemFound = fieldList.find((existingField) => existingField["@ID"] == selectedAllFieldsItem['@ID']);
                    //FlsROFields.push(itemFound);
                    state.deltaCrud.FieldLevelSecurity.Groups.Group = addFieldsDelta(state.FlsROFields, allFields, state.deltaCrud.FieldLevelSecurity.Groups.Group);
                    state.deltaCrud.FieldLevelSecurity.Groups.Group = trimDeltaGroups(state.deltaCrud);

                    return {
                        ...state,
                        selectedAllFields: [],
                        selectedItem:
                        {
                            ...state.selectedAllFields,
                            Fields: {
                                ...state.FlsAllFields.Fields,
                                Field: roFields
                            }
                        },
                        changed: true,
                    }
                }
                break;
            case 'REMOVE_FLS_FIELDS':
                //console.log('moving left! from the store...');
                if (action.data.masterCrud &&
                    state.selectedReadOnly.length > 0) {
                    let roFields = state.selectedReadOnly.slice();
                    let allFields = onRemoveAllFieldsFieldsFromReadOnly(action.data.masterCrud, roFields, state.FlsROFields);

                    //let fieldList = action.data.masterCrud.FieldLevelSecurity.FieldList.Field;
                    //let itemFound = fieldList.find((existingField) => existingField["@ID"] == selectedAllFieldsItem['@ID']);
                    //FlsROFields.push(itemFound);

                    state.deltaCrud.FieldLevelSecurity.Groups.Group = removeFieldsDelta(state.FlsROFields, roFields, state.deltaCrud.FieldLevelSecurity.Groups.Group);
                    state.deltaCrud.FieldLevelSecurity.Groups.Group = trimDeltaGroups(state.deltaCrud);

                    return {
                        ...state,
                        selectedReadOnly: [],
                        selectedItem:
                        {
                            ...state.selectedReadOnly,
                            Fields: {
                                ...state.FlsROFields.Fields,
                                Field: allFields
                            }
                        },
                        changed: true,
                    }
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return deepCopyFunction(defaultState);

            //default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                //return defaultState;
        }
    }

    //return state || defaultState;
    return state || deepCopyFunction(defaultState);
}

