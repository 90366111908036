import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as RemitFilterStore from './api/RemitFilterStore';

export type IRemitFilterState = IApiComponentState<any, RemitFilterStore.IRemitFilterUIState>;
export type IRemitFilterActions = typeof actionCreators;
export type IRemitFilterActionProps = IApiComponentActionProps<IRemitFilterActions>

export const actionCreators = createApiComponentActions<RemitFilterStore.ActionCreators>(RemitFilterStore.actionCreators);
export const reducers = createApiComponentReducers("remitFilter", RemitFilterStore.reducer);
export const reducer = combineReducers<IRemitFilterState>(reducers);