import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, createAction, ActionTypes } from '@scripts/util/ActionHelpers'; 
import { act } from 'react-dom/test-utils';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.


export interface IViewProductivityUIState {
    selectedTabIndex: Number;
    initialValuesLoaded:boolean;
    UserProductivityDashboardInfo:any;
    masterUserList:any;
    displayedUserTask:any;
    dateRange:any,
    selectedUserList:any[],
    userList:any[],
    groupList:any[],
    selectedUserIds:any[],
    shouldRefreshUserList:boolean,
    resultObj:any
}



// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    updateInitialData: (initialData: any) => createDataAction('UPDATE_INITIAL_DATA', initialData),
    updateMasterUserList:(mastetUserList:any) => createDataAction('UPDATE_MASTER_USER_LIST',mastetUserList),
    updateDisplayedUserTask:(displayedUserTask:any) => createDataAction('UPDATE_DISPLAYED_USER_TASK',displayedUserTask),
    updateDateRange:(dateRange:any) => createDataAction('UPDATE_DATE_RANGE',dateRange),
    updateUserList:(userList:any) => createDataAction('UPDATE_USER_LIST',userList),
    updateSelectedUserList:(selectedUserList:any) => createDataAction('UPDATE_SELECTED_USER_LIST',selectedUserList),
    updateGroupList:(groupList:any) => createDataAction('UPDATE_GROUP_LIST',groupList),
    resetCrud:(reset:any) => createDataAction('RESET_CRUD',reset),
    updateSelectedUserIds:(selectedUserIds:any) => createDataAction('UPDATE_SELECTED_USE_IDS',selectedUserIds),
    updateResultObject: (resultObject: any) => createDataAction('UPDATE_RESULT_OBJ', resultObject),
    updateProductivityData: (currentPayload: any) => createDataAction('UPDATE_PRODUCTIVITY_DATA', currentPayload)

};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IViewProductivityUIState = {
    selectedTabIndex: 0,
    initialValuesLoaded:false,
    UserProductivityDashboardInfo:{},
    masterUserList: [],
    displayedUserTask:{
        all:false,
        printedClaims:false,
        deletedClaims:false,
        changedClaims:false,
        viewedClaims:false,
        validatedClaims:false,
        createdClaims:false
    },
    dateRange:{
        startDate:'',
        endDate:''
    },
    selectedUserList:[],
    selectedUserIds:[],
    userList:[],
    groupList:[],
    shouldRefreshUserList:false,
    resultObj:{}
};


// ----------------
// REDUCER - For a given state and actio returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IViewProductivityUIState, KnownActions> = (state: IViewProductivityUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
           
            case 'UPDATE_INITIAL_DATA':
                if(action.data.masterCrud){
                return {
                    ...state,
                    initialValuesLoaded: true,
                    UserProductivityDashboardInfo: action.data.masterCrud.UserProductivityDashboardInfo
                }
            }
            break;
            case 'UPDATE_MASTER_USER_LIST':
                if(action.data.masterCrud){
                    return {
                        ...state,
                       masterUserList:action.data.masterCrud
                    }
                }
                break;
            case 'UPDATE_DISPLAYED_USER_TASK':
                if(action.data.masterCrud){
                    return {
                        ...state,
                       displayedUserTask:action.data.masterCrud
                    }
                }
                break;
                case 'UPDATE_DATE_RANGE':
                    if(action.data.masterCrud){
                        return {
                            ...state,
                           dateRange:action.data.masterCrud
                        }
                    }
                    break;
                case 'UPDATE_SELECTED_USE_IDS':
                    if(action.data.masterCrud){
                        return {
                            ...state,
                            dateRange:action.data.masterCrud
                        }
                    }
                        break;
                 case 'UPDATE_USER_LIST':
                    if(action.data.masterCrud){
                        return {
                            ...state,
                            userList:action.data.masterCrud
                        }
                    }
                    break;
                    case 'UPDATE_SELECTED_USER_LIST':
                    if(action.data.masterCrud){
                        return {
                            ...state,
                            selectedUserList:action.data.masterCrud
                        }
                    }
                    break;
                    case 'UPDATE_GROUP_LIST':
                        if(action.data.masterCrud){
                            return {
                                ...state,
                                groupList:action.data.masterCrud
                            }
                        }
                        break;
                    case 'RESET_CRUD':
                        if(action.data.masterCrud){
                            return {
                                ...action.data.masterCrud
                            }
                        }
                        break;
                    case 'UPDATE_RESULT_OBJ':
                        if(action.data.masterCrud){
                            return {
                                ...state,
                                resultObj: action.data.masterCrud
                            }
                        }
                        break;
                    case 'UPDATE_PRODUCTIVITY_DATA':
                        if (action.data.masterCrud) {
                            return {
                                ...state,
                                filterData: action.data.masterCrud
                            }
                        }
                        break;   
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
              //  const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
