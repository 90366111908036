import * as React from 'react';
import { bindAll } from 'lodash';
import ReportFilter, { validateReportFilterDatesAreWithinRange } from "@components/Reports/Filter/ReportFilter";
import { validateDateRangeNotEmpty, validateBothDatesPopulated, validateDateRangeSmallerThanLimit, validateUserReallyWantsEmptyDateRangeFactory, validateDateRangeIsEmptyOrFull, validateDatesInLogicalOrder } from '@scripts/util/ValidationHelpers';


export interface IDateRangeFilterConfigProps {

}

export interface IDateRangeFilterConfigState {
    activeTab: number;
}

export default class DateRangeFilterConfig extends React.PureComponent<IDateRangeFilterConfigProps,
    IDateRangeFilterConfigState> {
    constructor(props: IDateRangeFilterConfigProps) {
        super(props);
        this.state = { activeTab: 0 };
        bindAll(this, ['onActiveTabChange']);
    }

    public onActiveTabChange(activeTab: number) {
        this.setState({activeTab});
    }

    public render() {
        const { activeTab } = this.state;
        let mainDateRangeValidators = [validateDateRangeNotEmpty, validateDatesInLogicalOrder, validateReportFilterDatesAreWithinRange, validateBothDatesPopulated];
        const pmtDateRangeValidators = [validateDateRangeIsEmptyOrFull, validateBothDatesPopulated];
        const showFilterList = true;
        const showUserList = activeTab > 1;
        const showFacilitiesList = activeTab < 1;
        const showLobList = activeTab < 2;
        const showRemitFields = activeTab === 1;

        if (showRemitFields) {
            const messageTemplate =
                ({ product, continueAction, returnDestination }: {product: string; continueAction: string; returnDestination: string} ) =>
                    `The ${product} will be limited to payments imported today because not enough filter criteria have been specified. Click Yes to ${continueAction}. Click No to return${returnDestination}.`;
            const modalOptions = {
                alertMode: false,
                okText: 'Yes',
                cancelText: 'No',
                title: 'Confirm Empty Import Dates',
            }
            /*  Remit reports allow empty date range, but will prompt user for confirmation.
                if confirmation is given (Yes selected), both dates are corrected to today
                before report is run or the filter is saved. If the choice is not confirmed,
                user is returned to the filter to update the entry.
                This takes the place of the default validateDateRangeNotEmpty.
             */
            mainDateRangeValidators[0] = validateUserReallyWantsEmptyDateRangeFactory(messageTemplate, modalOptions);
        }

        if (showUserList) {
            mainDateRangeValidators.push(
                validateDateRangeSmallerThanLimit(31, 'You can only report against up to 31 days worth of data at a time.')
            );
        }

        return (<ReportFilter 
            {...{
                ...this.props,
                mainDateRangeValidators,
                pmtDateRangeValidators,
                showFacilitiesList,
                showFilterList,
                showLobList,
                showRemitFields,
                showUserList,
                onActiveTabChange: this.onActiveTabChange,
            }}
        />);
    }
};