// Color Shades
export const colorShades: string[] = [
    'blue', 
    'brightBlue', 
    'green', 
    'magenta', 
    'mint',
    'orange', 
    'periwinkle',
    'purple', 
    'sunset',
    'teal',
    'yellow'
];
interface ColorData {
    [index: string]: string;
}
// Color Constants
export const Colors: ColorData = 
{
    // Deep Blue
    blue15: "#d5eff7",
    blue30: "#b3d9ec",
    blue50: "#6eb3d4",
    blue70: "#3c92bc",
    blue100: "#1978a5",
    blue115: "#0a638f",
    blue130: "#025077",
    blue150: "#014161",

    // Bright Blue
    brightBlue15: "#e5f8ff",
    brightBlue30: "#caf0ff",
    brightBlue50: "#a6e4ff",
    brightBlue70: "#82d8ff",
    brightBlue100: "#4cc6ff",
    brightBlue115: "#2cadea",
    brightBlue130: "#1188c1",
    brightBlue150: "#0e75a4",

    //Green
    green15: "#d9fae9",
    green30: "#b1f6d3",
    green50: "#85edb8",
    green70: "#54db96",
    green100: "#16d373",
    green115: "#05b75d",
    green130: "#039d4f",
    green150: "#007b3d",

    //Magenta
    magenta15: "#fce2f2",
    magenta30: "#f8c5e4",
    magenta50: "#f49fd4",
    magenta70: "#f079c4",
    magenta100: "#ea40ac",
    magenta115: "#cd2b92",
    magenta130: "#a41670",
    magenta150: "#7f0d55",

    //Mint
    mint15: "#e5fcf3",
    mint30: "#cbf8e6",
    mint50: "#a5f4d4",
    mint70: "#7ff0c2",
    mint100: "#46eaa7",
    mint115: "#3bc78e",
    mint130: "#30a474",
    mint150: "#26805b",

    //Orange
    orange15: "#ffefde",
    orange30: "#ffddb7",
    orange50: "#ffc889",
    orange70: "#ffb969",
    orange100: "#ffa843",
    orange115: "#e6973c",
    orange130: "#bf7d32",
    orange150: "#8c5c24",

    // Periwinkle
    periwinkle15: "#edefff",
    periwinkle30: "#d6daff",
    periwinkle50: "#b0b9ff",
    periwinkle70: "#8b98ff",
    periwinkle100: "#5c6fff",
    periwinkle115: "#4e5ed9",
    periwinkle130: "#404db3",
    periwinkle150: "#323c8c",

    // Purple
    purple15: "#ecdbfa",
    purple30: "#d8b6f5",
    purple50: "#c082ed",
    purple70: "#a84ee5",
    purple100: "#8400d9",
    purple115: "#6a00ae",
    purple130: "#4d007e",
    purple150: "#3d0064",
     
    // Sunset
    sunset15: "#ffe8e2",
    sunset30: "#ffd3c8",
    sunset50: "#feb09c",
    sunset70: "#ff8d6f",
    sunset100: "#fe6a43",
    sunset115: "#d85a38",
    sunset130: "#b2492e",
    sunset150: "#8b3a24",

    // Teal
    teal15: "#d6f3f3",
    teal30: "#b5eaea",
    teal50: "#80d9d9",
    teal70: "#43c6c6",
    teal100: "#00b1b1",
    teal115: "#009696",
    teal130: "#007777",
    teal150: "#016363",

    // Yellow
    yellow15: "#fcf8d9",
    yellow30: "#fff6ac",
    yellow50: "#fff184",
    yellow70: "#ffef77",
    yellow100: "#ffea4c",
    yellow115: "#f9df19",
    yellow130: "#f9dc06",
    yellow150: "#e0c606",
}

export const shades: number[] = [15, 30, 50, 70, 100, 115, 130, 150];

export const DigitalColors:ColorData = {
    digitalGreen15: "e4f2db",
    digitalGreen30: "#c9e5b6",
    digitalGreen50: "#a3d582",
    digitalGreen70: "#7dc54e",
    digitalGreen100: "#44ad00",
    digitalGreen115: "#358600",

    digitalRed15: "#fae2e2",
    digitalRed30: "#f4c5c5",
    digitalRed50: "#ec9f9f",
    digitalRed70: "#e47979",
    digitalRed100: "#d63e3e",
    digitalRed115: "#bf1616",

    digitalGrey15: "#e1e2e6",
    digitalGrey30: "#c3c5cd",
    digitalGrey50: "#9ba1a9",
    digitalGrey70: "#737d85",
    digitalGrey100: "#37474f",

    digitalOrange15: "#fff5db",
    digitalOrange30: "#ffebb6",
    digitalOrange50: "#ffdf84",
    digitalOrange70: "#ffd352",
    digitalOrange100: "#ffC107",

}