import * as React from 'react';
import {bindAll, find, isEqual, noop, pick} from 'lodash';
import { ContentRowWrapper, } from
    '@common/DialogWrapper';
import styled from 'styled-components';
import { DialogLegend, DialogFieldset } from '@common/DialogStyles';
import { SelectComponent } from '../../common/SelectComponent';
import { IImageButtonImage, ImageButton } from '../../common/ImageButton';
import * as wnd from '@commonResources/window';
import { RowWrapper, SelectButtons, CheckBoxContainer, ToolTip} from './PhysicianStyles';
import { PhysicianPayerDetails } from './PhysicianPayerDetails';
import {
    PhysicianInfo, PmDropdownOption, DEFAULT_PHYSICIAN_INFO,
    Lob, DEFAULT_LOB, PmFacilityDropdownOption
} from'@store/ui/PhysicianMaintenanceUI';
import { Button } from 'ui-core';
import { handleChange } from '@commonResources/userModified';
import {fieldIsNotValue, fieldIsFilled, fieldIsAlphaNumeric, ControlValidation, dynamicFieldIsFilled} from '@commonResources/validations';
import { IModalConfirmationProps, ModalConfirmation } from '@common/ModalConfirmation';


//-----------------
// PAGE NOTES
// This component collects all the physician 
//-----------------
//STYLES - Put custom style elements up here.
// e.g.
//const Spacer = styled.div`
//    height: 0.25rem;
//
const StyleName = styled.div`
    
`;

// ReSharper disable IdentifierTypo

// ReSharper restore IdentifierTypo

//-----------------
// OBJECT DEFINITIONS - Put any interface or small class definitions here.
enum RequiredInputs
{
    KeyNo,
    PhysicianNo,
    Description,
    Lob,
    Facility,
}

interface CurrentControlErrors
{
    keyNoError?: string,
    lobError?: string,
    physicianNoError?: string,
    facilityError?: string,
    descriptionError?: string,
    npiError?: string,
    taxonomyError?: string,
}


export interface FieldError {id: ControlId; failureMessage: string}
const DEFAULT_CONTROL_ERRORS: FieldError[] = [];

//-----------------
// STATE AND PROPS - Define the local component state and any component props here.
interface IComponentState
{
    actualPhysician: PhysicianInfo;
    selectedPayer: Lob;
    draftPayer: Lob;
    errors: FieldError[];
    showErrors: boolean;
    modalProps?: Partial<IModalConfirmationProps>;
}

interface IComponentProps
{
    currentPhysician: PhysicianInfo;
    lobOptions: PmDropdownOption[];
    facilityOptions: PmFacilityDropdownOption[];
    specialtyCodeOptions: PmDropdownOption[];
    helpIcon: IImageButtonImage;
    addIcon: IImageButtonImage;
    canAdd: boolean;
    canEdit: boolean;
    canCopy: boolean;
    canRemove: boolean;
    onPayerSelect(e: React.ChangeEvent<HTMLSelectElement>): void;
    addPayers(newPayers: Lob[]): void;
    onClickModifyButton: Function;
    onClickPayerRemoveButton: Function;
    onCopyPayerClick: Function;
}

//-----------------
// DEFAULTS - Define any default object values (including the state) here.
export const DEFAULT_STATE: IComponentState = {
    actualPhysician: DEFAULT_PHYSICIAN_INFO,
    selectedPayer: DEFAULT_LOB,
    // TODO: is this the best way to get the payer out of the subcomponent and into scope with the add/copy/delete btns?
    draftPayer: DEFAULT_LOB,
    errors: DEFAULT_CONTROL_ERRORS,
    showErrors: false,
};

type ChangeEvent = React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>;
type ControlId = "keyNo" | "physicianNo" | "facilityId" | "payerId" | "lobId" | "specialtyCodeId" | "description"
                 | "setToDefault" | "npi" | "taxonomy" | "suppressCCIAI" | "suppressCCIBI" | "suppressCCIBP"
                 | "suppressMNAI" | "suppressMNBI" | "suppressMNBP";


export class PhysicianPayerInputs extends React.Component<IComponentProps, IComponentState>
{
    state = {
        ...DEFAULT_STATE, actualPhysician: { ...this.props.currentPhysician } };
    // TODO: dynamically get all id values from map items instead of hard-coded ControId
    private validationMap: ControlValidation<ControlId>[] = [
                { id: "keyNo", displayName: 'Key No', validations: [fieldIsFilled] },
                { id: "physicianNo", displayName: 'Physician No', validations: [fieldIsFilled, fieldIsAlphaNumeric] },
                { id: "description", displayName: 'Description', validations: [fieldIsFilled] },
                { id: "npi", displayName: 'NPI', validations: [fieldIsAlphaNumeric] },
                { id: "taxonomy", displayName: 'Taxonomy', validations: [fieldIsAlphaNumeric] },
                { id: "lobId", displayName: '', validations: [fieldIsNotValue('-1', 'You must enter an LOB.'), dynamicFieldIsFilled('You must enter an LOB.')] },
                { id: "facilityId", displayName: '', validations: [fieldIsNotValue('-1', 'You must enter a facility.'), dynamicFieldIsFilled('You must enter an LOB.')] },
            ];

    constructor(props: IComponentProps) {
        super(props);
        this.state = {
            ...DEFAULT_STATE, actualPhysician: { ...this.props.currentPhysician }, modalProps: {},
        };
        this.getPayersSelectList = this.getPayersSelectList.bind(this);
        this.removePotentialDuplicates = this.removePotentialDuplicates.bind(this);
        bindAll(this, ['addPayer', 'copyPayer', 'deletePayer', 'finishDeletePayer', 'getDraftPayerErrors', 'resetPayerState', 'resetModalProps', 'updatePayer', 'validateNewPayer']);
    }

    componentDidMount() {
        this.getDraftPayerErrors();
    }

    static getDerivedStateFromProps(props: IComponentProps, state: IComponentState) {
        /*  When we update the selected physician in Redux but don't change to a different physician,
            this lets the change propagate into this component. We don't need to make a copy, because
            we're careful in the Redux store never to mutate, only replace.
            The change then only gets saved (in the UI and the delta) when we click Add/Update/Copy/Remove.
        */
        if (!isEqual(props.currentPhysician, state.actualPhysician)) {
            return ({
                actualPhysician: props.currentPhysician, errors: {}
            });
        }
        return null;
    }

    // TODO - consolidate payer generation logic in PayerInputs and pass it down into PayerDetails
    private removePotentialDuplicates(newPayer: Lob): PmFacilityDropdownOption[] {
        const currentPayerNames = this.getPayersSelectList().map(p => p.displayName);
        const facilitiesList = this.props.facilityOptions;
        const potentialPayerNames = facilitiesList.map((fac) => {
            return {
                name: `${this.props.lobOptions.find(l => l.value === newPayer.lobId)?.label ?? ""} - ${fac.label} - ${newPayer.physicianNo}`,
                facility: fac,
            };
        });
        //debugger;
        const validOptions = potentialPayerNames.filter((option) => !currentPayerNames.includes(option.name));
        return validOptions.map((opt) => opt.facility);
    }

    private getDraftPayerErrors(): FieldError[] {
        const errors = this.validationMap.reduce((errList, validationItem) => {
            const value = this.state.draftPayer[validationItem.id];
            const errorValidator = validationItem.validations.find(validator => !validator.check(value));
            if (errorValidator) {
                let {failureMessage} = errorValidator;
                if (validationItem.displayName) {failureMessage = validationItem.displayName + ' ' + failureMessage};
                errList.push({id:validationItem.id, failureMessage});
            }
            return errList;
        }, [] as FieldError[]);
        // I don't really like having this side effect here, but I don't have a better place for it.
        this.setState({errors});
        return errors;
    }

    private resetPayerState() {
        const noPayer = {
                ...DEFAULT_LOB,
                displayName: '- ADD A NEW PAYER -',
            };
        this.setState({selectedPayer: noPayer, draftPayer: noPayer, showErrors: false}, this.getDraftPayerErrors);
    }

    private resetModalProps() {
        this.setState({ modalProps: {}});
    }

    private validateNewPayer(isUpdate:boolean = false): boolean {
        const searchCriteria = pick(this.state.draftPayer, ['lobId', 'description', 'physicianNo']);
        const payers = this.state.actualPhysician.lobList?.filter(payer => isUpdate? payer.id !== this.state.draftPayer.id : true) || [];
        //console.log(searchCriteria);
        //console.table(payers, ['lobId', 'description', 'physicianNo']);
        const duplicatePayer = find(payers, searchCriteria) as Lob;
        // Can't save this duplicate
        if (!!duplicatePayer && duplicatePayer.id) {
            this.setState({
                modalProps: {
                    alertMode: true,
                    isOpen: true,
                    message: 'The LOB you wish to add already exists with this LOB, Description, and Physician No.',
                    onModalToggle: this.resetModalProps,
                }
            });
            return false;
        }
        return true;
    }

    private addPayer() {
        //failsafe only, button should be disabled if this happens
        if (!this.state.actualPhysician || !this.state.draftPayer) return;
        if (this.getDraftPayerErrors().length) {
            this.setState({showErrors:true});
            return;
        }
        const searchCriteria = pick(this.state.draftPayer, ['lobId', 'description', 'physicianNo']);
        const duplicatePayer = find(this.state.actualPhysician.lobList, searchCriteria);
        // Can't save this duplicate
        if (this.validateNewPayer()){
            handleChange();
            //TODO: we only have copy, but add and copy do the exact same thing in legacy
            //Should change to add to match action?
            this.props.onCopyPayerClick(this.state.draftPayer);
            this.resetPayerState();
        }
    }

    private copyPayer() {
        this.addPayer();
    }

    private updatePayer() {
        if (!this.state.actualPhysician || !this.state.draftPayer) return;
        if (this.getDraftPayerErrors().length) {
            this.setState({showErrors:true});
            return;
        }
        if (this.validateNewPayer(true)) {
            handleChange();
            this.props.onClickModifyButton(this.state.draftPayer);
        }
    }

    private deletePayer() {
        if (!this.state.actualPhysician || !this.state.draftPayer) return;

        this.setState({
            modalProps: {
                isOpen: true,
                message: `Do you want to remove ${this.state.draftPayer.description}? This action cannot be undone.`,
                onModalToggle: this.resetModalProps,
                onConfirm: this.finishDeletePayer,
            }
        });
    }

    private finishDeletePayer() {
        handleChange();
        this.props.onClickPayerRemoveButton(this.state.draftPayer);
        this.resetPayerState();
    }


    /**
     * Get the list of options for the Payers Select Box.
     */
    private getPayersSelectList(): Lob[] {
        let payerData: Lob[] = [
            {
                ...DEFAULT_LOB,
                displayName: '- ADD A NEW PAYER -',
            }
        ];
        const payerLobList: Lob[] | undefined = this.state.actualPhysician?.lobList;
        if (payerLobList && payerLobList.length !== 0) {
            payerData = payerData.concat(payerLobList);
        }
        return payerData;
    }

    public render() {

        let payerData: Lob[] = this.getPayersSelectList();
        const payerDetailsKey = `${this.state.selectedPayer?.id ?? "NO-ID"}-details`;
        const {modalProps} = this.state;
        return (
            <ContentRowWrapper>
               <DialogFieldset>
               <DialogLegend>Payers</DialogLegend>
               <RowWrapper>
                   <SelectComponent
                       title='add-a-new-payer'
                       size={6}
                       width='985px'
                       height='100px'
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                const newPayer = payerData.find(x => {
                                    return x.id === e.target.value;
                                }) ?? payerData[0];
                                this.setState({
                                    selectedPayer: newPayer,
                                    draftPayer: newPayer,
                                }, this.getDraftPayerErrors);
                            }}
                       optionFields={{
                           value: "id",
                           text: "displayName",
                       }}
                       disabled={false}
                       records={payerData}
                       selectedValue={this.state.selectedPayer.id}/>
               </RowWrapper>
                    <ContentRowWrapper>
                        <PhysicianPayerDetails
                            key={payerDetailsKey}
                            currentPayer={this.state.selectedPayer}
                            errors={this.state.errors}
                            lobOptions={this.props.lobOptions}
                            facilityOptions={this.props.facilityOptions}
                            specialtyCodeOptions={this.props.specialtyCodeOptions}
                            helpIcon={this.props.helpIcon}
                            addIcon={this.props.addIcon}
                            removePotentialDuplicates={this.removePotentialDuplicates}
                            addPayers={(newPayers: Lob[]) => {
                                // Reset the selected payer to the -ADD NEW PAYER- entry and kick off the addPayers action.
                                this.setState(
                                    { selectedPayer: payerData[0], draftPayer: payerData[0] },
                                    () => this.props.addPayers(newPayers)
                                );
                            }}
                            onChange={(payer: Lob) => { this.setState({ draftPayer: payer }) }}
                            getLatestFieldErrors={this.getDraftPayerErrors}
                            showErrors={this.state.showErrors}
                            canAddMultiPayer={this.props.canAdd}
                        />
                    </ContentRowWrapper>
                <SelectButtons> 
                <ContentRowWrapper>
                    <SelectButtons>
                                {!this.state.selectedPayer.id ? ( 
									//See if we need update or add button.
                                    <ToolTip>
                                        <Button
                                domID="payer-add-button"
                                name="Add"
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canAdd || !this.state.actualPhysician.lastName}
                                onClick={this.addPayer}
                            />
                                        <span className="text">Add a New LOB</span>
                                    </ToolTip>
                                ) : (
                                        <ToolTip>
                                            <Button
                                domID="physician-update-button"
                                name="Update"
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canEdit}
                                // onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickModifyButton(e)}
                                onClick={this.updatePayer}
                            />
                                            <span className="text">Update an LOB</span>
                                        </ToolTip>
                                    )}
                                <ToolTip>
                        <Button
                            domID="payer-remove-button"
                            name="Remove"
                            buttonType="standard"
                            size="small"
                            type="button"
                            disabled={!this.props.canRemove || !this.state.selectedPayer.id}
                            onClick={this.deletePayer}
                        />
                                    <span className="text">Remove Selected LOB</span>
                                </ToolTip>
                                <ToolTip>
                        <Button
                            domID="payer-copy-button"
                            name="Copy"
                            buttonType="standard"
                            size="small"
                            type="button"
                            disabled={!this.props.canAdd || !this.state.selectedPayer.id}
                            onClick={this.copyPayer}
                        />
                                    <span className="text">Copy the LOB Information</span>
                                </ToolTip>
                    </SelectButtons>
                </ContentRowWrapper>
               </SelectButtons>
               </DialogFieldset>
                <ModalConfirmation
                    isOpen={modalProps?.isOpen ?? false}
                    alertMode={modalProps?.alertMode ?? false}
                    message={modalProps?.message}
                    onModalToggle={modalProps?.onModalToggle ?? noop}
                    onConfirm={modalProps?.onConfirm ?? noop}
                />
            </ContentRowWrapper>
        );
    }
}