import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "@store/index";

import {
  IMergeApiComponentProps,
  createApiMapStateToProps,
  createApiMapDispatchToProps,
  mergeApiComponentProps,
  resetApiComponentState
} from "@scripts/util/ApiDataHelpers";

import {
  IClaimFilterState,
  IClaimFilterActions,
  IClaimFilterActionProps,
  actionCreators
} from "@store/ClaimFilter";

import { ClaimFilter, IClaimFilterComponentProps } from "./ClaimFilter";
//import { SecurityBits } from "../../../commonResources/SecurityFunctionList";

export interface IPaperClaimsFilterComponentState {}

//---------------------------------------------------------------

export const DEFAULT_STATE: IPaperClaimsFilterComponentState = {};

type IOwnProps = IClaimFilterComponentProps & RouteComponentProps<{}>;

type IClaimFilterProps = IMergeApiComponentProps<
  IClaimFilterState,
  IClaimFilterActionProps,
  IOwnProps
>;

export class PaperClaimsFilter extends React.Component<IClaimFilterProps, IPaperClaimsFilterComponentState>
{
  static defaultProps: IClaimFilterComponentProps = {
    canEdit: false,
    canView: false,
    canCreate: false,
    ncsIsEnabled: false,

    process: "print",
    parameter: {},
    apiType: "ClaimFilter",
    title: "Print Claims", 
    execNode: "MultiClaim",
    execAction: "PrintClaim",
    execProcess: "Print",
    extraInstruction:"",
  };

  constructor(props: IClaimFilterProps) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  public componentDidMount() {
    if (!this.props.canView) {
      console.log("user did not have rights to " + this.props.title);
      this.props.history.push("/LandingPage");
    }
  }

  public componentWillUnmount() {
    resetApiComponentState(this.props.action, this.props.dataStore);
  }

  public onOk = (baseThis: any, param1: any): boolean => {
    //      alert(baseThis.props.apiType + ':' + param1 + ':' + this.props.apiType);
    return true;
  };

  public render() {
    var parameterValue = this.props.history.location.state?.parameter;
    return (
      <div>
            <ClaimFilter {...this.props} parameter={parameterValue} />
      </div>
    );
  }
}

var connectedHoc = connect<
  IClaimFilterState,
  IClaimFilterActionProps,
  IOwnProps,
  IClaimFilterProps,
  ApplicationState
>(
  createApiMapStateToProps("claimFilter"),
  createApiMapDispatchToProps(actionCreators),
  mergeApiComponentProps
)(PaperClaimsFilter);

export default withRouter(connectedHoc);
