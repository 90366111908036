import { Reducer, Action } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"  
import { deepCopyFunction } from '@commonResources/validations';


const DATE_RELEASED_ID: string = "9";
const DATE_ASS_STATUS_DATE_EFF_ID: string = "10";
const DATE_SUBMITDATE_ID: string = "4";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IClaimFilterUIState {

    filter: APICF_FilterWrapper;  // the visual data of filter
    filterName: string;  // this is used to change the title when a filter is loaded/saved
    filterId: number;
    loaded: boolean; // has a filter been loaded replaces the following check in old asp : xmlFilter.documentElement != null && xmlFilter.documentElement.nodeName != 'Filter'
    dataRefreshed: boolean;  // to handle loadFilter needing setFilter called...
    checkClaimState: boolean; // to handle account number change...
    postAction: string; // post action to a successful validateFilter call

    activeTab: number; // (0-, 1-advance, 2-payerstatus, 3-elgibility)
    allowTime: boolean;
    activeSavedFilterTab: number;
    focusField: string;

    // oddball controls
    disabledQuickstart: string[]; // list of quickstart options to disable
    quickStart: string;  // this is SetDialogButtonState - 0: LastClaimList, 1: LastFilter, 2: QuickClaimFilter(implementing?), 3: SavedFilter
    filterNameQs: string;  // this is the selection in QS - may not be loaded yet
    filterIdQs: string;    // this is the selection in QS - may not be loaded yet
    filterIndexQs: number;

    claimStateSwitch: boolean; // for switching between the radio controls and check boxes
    
    // for disabling
    disableFormType: boolean;
    disableLobs: boolean;
    disableDeleted: boolean;
    disableUnreleased: boolean;
    disableReleased: boolean;
    disableStateOnly: boolean;
    
    disableRejected: boolean;   
    disableDisplayOrder: boolean;
    disableElectronic: boolean;
    disablePaper: boolean;
    disableClearinghousePrint: boolean;
    disableDirect: boolean;
    disableOutsourced: boolean;
    disableHCD: boolean;

    // Eligibility Status Category 
    disableDuplicateId: boolean;
    disableInvalidOrMissingInfo: boolean;
    disablePatInsMatch: boolean;
    disableServiceDate: boolean;
    disableProvider: boolean;
    disbleNeverRequested: boolean;
    disableRequestPending: boolean;
    disableRequestFailed: boolean;
    // Eligibility Status Category


    // Direct Entry Category 
    disableNotQueried: boolean;
    disableDenied: boolean;
    disableException: boolean;
    disablePended: boolean;
    disablePaid: boolean;
    // Direct Entry Category

    disableSubmittedFile: boolean;

    isBusy: boolean;

    // for alert messages
    filterAlert?: string;
    filterAlertModeOn?: string;
    claimFilterErrorHandler?: string;
    claimFilterErrorHandlerForNotFound?: string;

    // filter list support
    isFilterListOpen: boolean; 
    // ncs support on save filter
    saveFilterNcsAlert?: string;

    // disable State Data Reporting
    disableStateDataReportingSentToState: boolean;
    disableStateDataReportingNotSentToState: boolean;

    // disable Billing Option
    disableBillingOptionPrimary: boolean;
    disableBillingOptionSecondaryManual: boolean;
    disableBillingOptionSecondaryAutomated: boolean;
    disableBillingOptionTertiaryManual: boolean;
    disableBillingOptionTertiaryAutomated: boolean;
    disableBillingOptionRebill: boolean;

    //disable Released Batch
    disableReleaseBatch: boolean;
    process_lowercase?: string;
}


export interface IClaimFilterUIData {
    index: string;
    value: boolean;
};


interface IClaimFilterUIFieldUpdate {
    value: string;
}

interface IClaimFilterUIArrayUpdate {
    value: string[];
}

interface IClaimFilterUISimpleIDArray {
    selected: APICF_FilterSimpleID[];
}

interface IClaimFilterUISimpleID {
    selected: APICF_FilterSimpleID;
}

interface IClaimFilterUISimpleListArray {
    selected: APICF_FilterSimpleList[]
}

interface IClaimFilterUISimpleList {
    selected: APICF_FilterSimpleList
}

interface IClaimFilterUIJSONData {
    data: any
}

interface IClaimFilterUIIdValue {
    id: number;
    value: string;
}

interface IClaimFilterUISelect {
    index: number;
    value: string;
    text: string;
}

export const defaultFilter: IClaimFilterUIData = {
    index: '',
    value: false,
};

interface IClaimFilterUIFieldData {
    data: any;
    index: number;
}

interface IClaimFilterDateData {
    data: APICF_FilterDateRange;
    value?: boolean;
}

interface IClaimFilterUserFieldData {
    id: any;
    value: string;
    index: number;
}

interface IClaimFilterUIFieldDataEx {
    id: string;
    value?: boolean;
    value2?: boolean;
}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ICF_LoadFilter extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIFieldData> { }
export interface ICF_SelectSimpleIds extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUISimpleIDArray> { }  
export interface ICF_SelectSimpleId extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUISimpleID> { }  
export interface ICF_SelectSimpleLists extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUISimpleListArray> { } 
export interface ICF_SelectSimpleList extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUISimpleList> { } 

export interface ICF_IdValue extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIIdValue> { }  
export interface ICF_FieldValue extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIFieldUpdate> { }
export interface ICF_FieldData extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIData> { }
export interface ICF_SelectData extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUISelect> { }
export interface ICF_FieldArray extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIArrayUpdate> { }
export interface ICF_DateField extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterDateData> { }
export interface ICF_JsonData extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIJSONData> { }
export interface ICF_UserFieldSpc extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUserFieldData> { }
export interface ICF_FieldDataEx extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIFieldDataEx> { }
export interface ICF_UnDelete extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIFieldDataEx> { }

export const actionCreators = {
    loadFilter: (filterInfo: ICF_LoadFilter) => createDataAction('CF_LOAD_FILTER', filterInfo),
    clearDataRefreshed:() => createAction('CF_CLEAR_DATA_REFRESHED'),
    setDataRefreshed: () => createAction('CF_SET_DATA_REFRESHED'),
    setPostAction: (data: string) => createDataAction('CF_SET_POSTACTION', data),

    setActiveTab: (tabInfo: ICF_LoadFilter) => createDataAction('CF_SET_ACTIVE_TAB', tabInfo),
    setLoaded: (loaded: ICF_FieldData) => createDataAction('CF_SET_LOADED', loaded),
    setActiveSFTab: (tabInfo: ICF_LoadFilter) => createDataAction('CF_SET_ACTIVE_SF_TAB', tabInfo),

    selectFormTypes: (selectFormTypes: ICF_SelectSimpleIds) => createDataAction('CF_SELECT_FORMTYPES', selectFormTypes),
    selectLobs: (selectLobs: ICF_SelectSimpleIds) => createDataAction('CF_SELECT_LOBS', selectLobs),
    selectDisplayOrder: (selectDisplayOrder: ICF_SelectSimpleId) => createDataAction('CF_SELECT_DISPLAYORDER', selectDisplayOrder),
    selectReleaseBatch: (selectReleaseBatch: ICF_SelectSimpleId) => createDataAction('CF_SELECT_RELEASEBATCH', selectReleaseBatch),
    selectSubmittedFile: (selectSubmittedFile: ICF_SelectSimpleId) => createDataAction('CF_SELECT_SUBMITTEDFILE', selectSubmittedFile),
    selectUser: (selectUser: ICF_SelectSimpleId) => createDataAction('CF_SELECT_USER', selectUser),
    selectFacility: (selectFacility: ICF_SelectSimpleLists) => createDataAction('CF_SELECT_FACILITY', selectFacility),
    selectSDRsentToState: (selectedSDR: ICF_LoadFilter) => createDataAction('CF_SELECT_SDR_SENT_TO_STATE', selectedSDR),
    selectLastAttachment: (selectedLastAttachment: ICF_LoadFilter) => createDataAction('CF_SELECT_LAST_ATTACHMENT', selectedLastAttachment),    
    selectClaimState: (selectedClaimState: ICF_SelectData) => createDataAction('CF_SELECT_CLAIM_STATE', selectedClaimState),
    selectClaimStatus: (status: ICF_LoadFilter) => createDataAction('CF_SELECT_CLAIM_STATUS', status),
    selectTransmitType: (selectedTransmitType: ICF_FieldDataEx) => createDataAction('CF_SELECT_TRANSMIT_TYPE', selectedTransmitType),
    selectServiceType: (selectedServiceType: ICF_LoadFilter) => createDataAction('CF_SELECT_SERVICE_TYPE', selectedServiceType),
    selectClaimsWithHoldCode: (selectedClaimsWithHoldCode: ICF_LoadFilter) => createDataAction('CF_SELECT_CLAIMS_WITH_HOLDCODE', selectedClaimsWithHoldCode),
    selectBillingOption: (selectedBillingOption: ICF_LoadFilter) => createDataAction('CF_SELECT_BILLING_OPTION', selectedBillingOption),
    selectAiiStatus: (selectedAiiStatus: ICF_LoadFilter) => createDataAction('CF_SELECT_AIISTATUS', selectedAiiStatus),
    selectPayerStatusCategory: (selectedPayerStatusCategory: ICF_LoadFilter) => createDataAction('CF_SELECT_PAYER_STATUS_CATEGORY', selectedPayerStatusCategory),
    selectEligibilityStatusCategory: (selectedEligibilityStatusCategory: ICF_LoadFilter) => createDataAction('CF_SELECT_ELIGIBILITY_STATUS_CATEGORY', selectedEligibilityStatusCategory),
    selectAppeals: (selectedAppeals: ICF_LoadFilter) => createDataAction('CF_SELECT_APPEALS', selectedAppeals),
    selectPayerType: (selectedPayerType: ICF_LoadFilter) => createDataAction('CF_SELECT_PAYER_TYPE', selectedPayerType),
    selectPayers: (selectPayers: ICF_SelectSimpleIds) => createDataAction('CF_SELECT_PAYERS', selectPayers),
    selectEligibilityPayers: (selectEligibilityPayers: ICF_SelectSimpleLists) => createDataAction('CF_SELECT_ELIGIBILITY_PAYERS', selectEligibilityPayers),
    selectErrorCatagories: (selectErrorCatagoriees: ICF_LoadFilter) => createDataAction('CF_SELECT_ERROR_CATAGORIES', selectErrorCatagoriees),

    updateAccountNumber: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_ACCOUNTNUMBER', data),
    updatePatientName: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_PATIENTNAME', data),
    updateUserField: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_USERFIELD', data),
    updateUserField2: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_USERFIELD2', data),
    updateUserField3: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_USERFIELD3', data),
    updateUserField4: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_USERFIELD4', data),
    updateUserFieldId: (data: ICF_UserFieldSpc) => createDataAction('CF_UPDATE_USERFIELD_ID', data),
    updateDateField: (data: ICF_DateField) => createDataAction('CF_UPDATE_DATEFIELD', data),

    updateFinancialClass: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_FCLASS', data),
    updateAmount: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_AMOUNT', data),
    updateTypeOfBill: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_TOB', data),
    updatePlanCode: (data: ICF_FieldValue) => createDataAction('CF_UPDATE_PLANCODE', data),

    updateClaimNote: (data: ICF_JsonData) => createDataAction('CF_UPDATE_CLAIMNOTE', data),

    selectFilterQs: (selection: ICF_SelectData) => createDataAction('CF_SELECT_FILTERQS', selection),
    setDialogButtonState: (selection: ICF_FieldValue) => createDataAction('CF_SELECT_QUICKSTART', selection),
    disableDialogButton: (selection: ICF_FieldArray ) => createDataAction('CF_DISABLE_QUICKSTART', selection),

    clickOnClaimState: (value1: boolean, value2: boolean, value3: boolean, process: string) => createDataAction('CF_CLICKON_CLAIMSTATE', { value1: value1, value2: value2, value3: value3, process: process }),
    selectSpecificClaimState: (type: string, value: boolean, process: string) => createDataAction('CF_SELECT_SPECIFIC_CLAIMSTATE', {type: type, value: value, process: process}),

    disableOption: (type: string, value: boolean) => createDataAction('CF_DISABLE_OPTION', { type: type, value: value }),
    disableOptions: (type: string[], value: boolean) => createDataAction('CF_DISABLE_OPTIONS', { type: type, value: value }),
    disableStatusOptions: (type: string[], value: boolean) => createDataAction('CF_DISABLE_STATUS_OPTIONS', { type: type, value: value }),
    disableDirectEntryOptions: (type: string[], value: boolean) => createDataAction('CF_DISABLE_DIRECT_ENTRY_OPTIONS', { type: type, value: value }),
    setClaimStateSwitch: (value: boolean) => createDataAction('CF_SET_CLAIMSTATESWITCH', value),
    setFocusField : (field: string) => createDataAction('CF_SET_FOCUSFIELD', field),

    //sendFilterAlert: (fieldInfo: IUpdateFormsUsedField) => createDataAction('SEND_ALERT', fieldInfo),
    sendClaimFilterAlert: (fieldInfo: string) => createDataAction('CF_SEND_ALERT', fieldInfo),
    sendClaimFilterAlertModeOn: (fieldInfo: string) => createDataAction('CF_SEND_ALERT_MODE_ON', fieldInfo),
    displayErrorMessage: (fieldInfo: ICF_FieldValue) => createDataAction('CF_ERROR_HANDLER_CONFIRM', fieldInfo),
    setBusy: (fieldInfo: ICF_FieldData) => createDataAction('CF_SETBUSY', fieldInfo),  
   
    // filter list support
    selectFilter: (filterInfo: ICF_IdValue) => createDataAction('CF_SELECT_FILTER', filterInfo),
    reloadFilterList: (filterInfo: ICF_LoadFilter) => createDataAction('CF_RELOAD_FILTERLIST', filterInfo),
    openFilterList: () => createAction('CF_OPEN_FILTERLIST'),
    closeFilterList: () => createAction('CF_CLOSE_FILTERLIST'),

    //changeCurrentTabPointer: (fieldInfo: number) => createDataAction('CF_CHANGE_TAB', fieldInfo),
    
    sendSaveFilterNCSAlert: (fieldInfo: ICF_FieldValue) => createDataAction('CF_NCS_ALERT', fieldInfo),

    // disable State Data Reporting
    disableStateDataReporting: (type: string[], value: boolean) => createDataAction('CF_DISABLE_STATE_DATA_REPORTING', { type: type, value: value }),

    // disable Billing Option
    disableBillingOption: (type: string[], value: boolean) => createDataAction('CF_DISABLE_BILLING_OPTION', { type: type, value: value }),

    //disable Release Batch
    disableReleaseBatch: (type: string, value: boolean) => createDataAction('CF_DISABLE_RELEASE_BATCH', { type: type, value: value }),

    clearClaimFilterErrorHandlerForNotFound: (type: string, value: string) => createDataAction('CF_CLEAR_CLAIM_FILTER_FOR_NOT_FOUND', {type: type, value: value}),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const emptyFilter: APICF_FilterWrapper = {
    Filter: {
        '@ID': '0',
        '@Name': '',
        '@Type': 'Claim',
        AccountNumber: { '@Value': '' },
        PatientName: { '@Value': '' },
        DateField: { '@ID': '', '@From': '', '@To': '' },
        UserField: { '@ID': '', '@Value': '' },
        UserField2: { '@ID': '', '@Value': '' },
        UserField3: { '@ID': '', '@Value': '' },
        UserField4: { '@ID': '', '@Value': '' },
        FinancialClass: { '@Value': '' },
        Amount: { '@Value': '' },
        TypeOfBill: { '@Value': '' },
        PlanCode: { '@Value': '' },

        SDR: {
            '@SentToState': '', '@NotSentToState': '', '@All': ''
        },

        LastAttachment: {
            '@None': '',
            '@Created': '',
            '@Pending': '',
            '@Error': '',
            '@Sent': '',
            '@Acknowledged': '',
            '@Rejected': '',
            '@From': '',
            '@To': ''
        },
        AppealStatus: {
            '@Created': '',
            '@Succeeded': '',
            '@Filed': '',
            '@Denied': '',
            '@From': '',
            '@To': ''
        },
        ClaimNote: {
            '@Type': '',
            '@Author': '',
            '@From': '',
            '@To': ''
        },

        ClaimState: {'@ID':'1'},
        ClaimStatus: {
            '@Accepted': '', '@Warning': '', '@Rejected': '', '@All': '', '@Unreported': ''
        },
        TransmitType: {
            '@Electronic': '',
            '@Paper': '',
            '@ClearinghousePrint': '',
            '@Direct': '',
            '@Outsourced': '',
            '@HCD': '',
        },
        ServiceType: { '@Inpatient': '', '@Outpatient': '', '@All': '' },
        ClaimsWithHoldCodeYes: { '@ClaimsWithHoldCodeYes': ''},
        ClaimsWithHoldCodeNo: { '@ClaimsWithHoldCodeNo': '' },
        BillingOption: {
            '@Primary':'',
            '@Rebill': '',
            '@Secondary':'',
            '@SecondaryFromRemit': '',
            '@Tertiary': '',
            '@TertiaryFromRemit': '',
            '@All': '',
        },
        StatusCategory: {
            '@NotFound': '',
            '@Error': '',
            '@Pending': '',
            '@FinalPaid': '',
            '@FinalDenied': '',
            '@FinalOther': '',
            '@Acknowledged': '',
            '@ReqInfo': '',
            '@Processing': '',
            '@Returned': '',
            '@NoStatus': '',
            '@All': '',
            '@Type': '',
            '@From': '',
            '@To': '',
            '@StatusType': ''
        },
        EligibilityCategory: {
            '@DuplicateId': '',
            '@InvalidOrMissingInfo': '',
            '@PatInsMatch': '',
            '@ServiceDate': '',
            '@Provider': '',
            '@NeverRequested': '',
            '@RequestPending': '',
            '@RequestFailed': '',
            '@All': '',
        },
        AiiStatus: {
            '@NotQueried':'',
            '@AiiPended': '',
            '@AiiDenied': '',
            '@AiiPaid': '',
            '@AiiException':'',
            '@All': ''
        },
        
        FormTypes: { FormType: [] },
        Facilities: { Facility: [] },
        ClaimLOBs: { ClaimLOB: [] },
        
        DisplayOrder: { '@ID': '1' },  
        ReleaseBatch: { '@ID': '' },
        SubmittedFile: { '@ID': '' },
        User: { '@ID': '' },
        PayerType: {'@Type': ''},
        Payers: { Payer: [] },
        EligibilityPayers: { EligibilityPayer: [] },
        ErrorCategories: { ErrorCategory: [] },

    }
};


export const defaultState: IClaimFilterUIState = {
    filter: deepCopyFunction({ ...emptyFilter }),
    filterName: '',
    filterId: 0,
    loaded: false,
    dataRefreshed: false,
    checkClaimState: false,
    claimStateSwitch: false, 
    activeTab: 0,
    allowTime: false,
    activeSavedFilterTab: 0,
    focusField: '',

    disabledQuickstart: [],
    quickStart: '0',
    filterNameQs: '',
    filterIdQs: '0',
    filterIndexQs: -1,

    disableFormType: false,
    disableLobs: false,
    disableDeleted: false,
    disableUnreleased: false,
    disableReleased: false,
    disableStateOnly: false,

    disableRejected: false,
    disableDisplayOrder: false,
    disableElectronic: false,
    disablePaper: false,
    disableClearinghousePrint: false,
    disableDirect: false,
    disableOutsourced: false,
    disableHCD: false,

    disableDuplicateId: false,
    disableInvalidOrMissingInfo: false,
    disablePatInsMatch: false,
    disableServiceDate: false,
    disableProvider: false,
    disbleNeverRequested: false,
    disableRequestPending: false,
    disableRequestFailed: false, 

    disableNotQueried: false,
    disableDenied: false,
    disableException: false,
    disablePended: false,
    disablePaid: false,

    disableSubmittedFile: false,

    isBusy: false,
    filterAlert: '',

    isFilterListOpen: false,
    saveFilterNcsAlert: '',
    postAction: '',
    disableStateDataReportingSentToState: false,
    disableStateDataReportingNotSentToState: false,
    disableBillingOptionPrimary: false,
    disableBillingOptionSecondaryManual: false,
    disableBillingOptionSecondaryAutomated: false,
    disableBillingOptionTertiaryManual: false,
    disableBillingOptionTertiaryAutomated: false,
    disableBillingOptionRebill: false,
    disableReleaseBatch: false,
    claimFilterErrorHandlerForNotFound: ""
};

export function determineNextAvailableQuickstart(val: string[], current: string)
{
    if (val && val.length > 0) {
        if (val.indexOf(current) > -1) {
            var possibles = ["0", "1", "2", "3"]; 
            for (let i = 0; i < possibles.length; i++) {
                if (current != possibles[i] && val.indexOf(possibles[i]) == -1) {
                    return possibles[i];
                }
            }
        }
    }
    return current;
}

export function getData(ob: any, field: string):string {
    if (!ob)
        return '';

    return ob[field] ?? '';

}


    



export function removeItemFromCSL(csl: string, item: string): string {
    var strArr = csl.split(',');
    const index = strArr.indexOf(item);
    if (index > -1) {
        strArr.splice(index, 1);
    }  
    return strArr.toString();
}

export function addItemToCSL(csl: string, item: string): string {
    return csl + (item && item.length > 0 && csl.length > 0 ? "," : "") + item;
}

export const getBar = (state: IClaimFilterUIState | undefined) => state?.filter.Filter?.ClaimState['@ID'];

export const getDataSelector : any = (state: IClaimFilterUIState | undefined, parent: string, field: string) => {
    console.log('in getDataSelector');
    if (state && state.filter && state.filter.Filter) {
        switch (parent) {
            case 'ClaimState':
                return state.filter.Filter?.ClaimState['@ID'];
        }

    }


    return "";
}; 

export function getClaimStatus(state: any, e: any) {

    var Accepted, Warning, Rejected, Unreported, All;

    if (!state.filter.Filter.ClaimStatus) {
        Accepted = Warning = Rejected = Unreported = All = '';
    } else {
        Accepted = getData(state.filter.Filter.ClaimStatus, "@Accepted");
        Warning = getData(state.filter.Filter.ClaimStatus, "@Warning");
        Rejected = getData(state.filter.Filter.ClaimStatus, "@Rejected");
        Unreported = getData(state.filter.Filter.ClaimStatus, "@Unreported");
        All = getData(state.filter.Filter.ClaimStatus, "@All");
    }
    switch (e) {
        case 'Accepted':
            if (All === "true") {
                Accepted = '';
                Warning = 'W';
                Rejected = 'R';
                Unreported = 'U';
                All = '';
            } else {
                if (Accepted === '') {
                    Accepted = 'A'
                } else Accepted = '';
            }
            break;
        case 'Warning':
            if (All === "true") {
                Accepted = 'A';
                Warning = '';
                Rejected = 'R';
                Unreported = 'U';
                All = '';
            } else {
                if (Warning === '') {
                    Warning = 'W'
                } else Warning = '';
            }
            break;
        case 'Rejected':
            if (All === "true") {
                Accepted = 'A';
                Warning = 'W';
                Rejected = '';
                Unreported = 'U';
                All = '';
            } else {
                if (Rejected === '') {
                    Rejected = 'R'
                } else Rejected = '';
            }
            break;

        case 'Unreported':
            if (All === "true") {
                Accepted = 'A';
                Warning = 'W';
                Rejected = 'R';
                Unreported = '';
                All = '';
            } else {
                if (Unreported === '') {
                    Unreported = 'U'
                } else Unreported = '';
            }
            break;
    }

    if (Accepted === "A" && Warning === "W" && Rejected === "R") { //Nir -- todo when is unreported visible && Unreported === "U"
        Accepted = Warning = Rejected = "";
        All = "true"
    }

    return {
        '@Accepted': Accepted, '@Warning': Warning, '@Rejected': Rejected, '@All': All, '@Unreported': Unreported
    }

}


 export function getTransmitTypeOption(state: any, e: string, enable: boolean, flip: boolean) {
    var Clearing, Direct, Electronic, HCD, Outsourced, Paper;
    if (!state.filter.Filter.TransmitType) {
        Clearing = Direct = Electronic = HCD = Outsourced = Paper = '';
    } else {
        Clearing = getData(state.filter.Filter.TransmitType, "@ClearinghousePrint");
        Direct = getData(state.filter.Filter.TransmitType, "@Direct");
        Electronic = getData(state.filter.Filter.TransmitType, "@Electronic");
        HCD = getData(state.filter.Filter.TransmitType, "@HCD");
        Outsourced = getData(state.filter.Filter.TransmitType, "@Outsourced");
        Paper = getData(state.filter.Filter.TransmitType, "@Paper");
    }

    switch (e) {
        case 'Clearing':
            if (Clearing === "" && (flip || (!flip && enable))) {
                Clearing = 'A';
            
            }
            else if (Clearing !== "" && (flip || (!flip && !enable))) {
                Clearing = '';
            
            }
            break;
        case 'Direct':
            if (Direct === "" && (flip || (!flip && enable))) {
                Direct = 'M';
            
            }
            else if (Direct !== "" && (flip || (!flip && !enable))) {
                Direct = '';
               
            }
            break;
        case 'Electronic':
            if (Electronic === "" && (flip || (!flip && enable))) {
                Electronic = 'Y';
               
            }
            else if (Electronic !== "" && (flip || (!flip && !enable))) {
                Electronic = '';
                
            }
            break;
        case 'HCD':
            if (HCD === "" && (flip || (!flip && enable))) {
                HCD = 'H';
                
            }
            else if (HCD !== "" && (flip || (!flip && !enable))) {
                HCD = '';
                
            }
            break;
        case 'Outsourced':
            if (Outsourced === "" && (flip || (!flip && enable))) {
                Outsourced = 'O';
                
            }
            else if (Outsourced !== "" && (flip || (!flip && !enable))) {
                Outsourced = '';
                
            }
            break;
        case 'Paper':
            if (Paper === "" && (flip || (!flip && enable))) {
                Paper = 'N';
                
            }
            else if (Paper !== "" && (flip || (!flip && !enable))) {
                Paper = '';
                
            }
            break;

    }

    return {
        '@Electronic': Electronic,
        '@Paper': Paper,
        '@ClearinghousePrint': Clearing,
        '@Direct': Direct,
        '@Outsourced': Outsourced,
        '@HCD': HCD,
    };

}

export function setClaimStateFromSwitch(state: any) {
    var newClaimState = "";
    var cs = getData(state?.filter.Filter.ClaimState, "@ID");
    var unreleased = isUnreleasedChecked(cs);
    var released = isReleasedChecked(cs);
    if (unreleased)
        newClaimState = "1";
    else if (released)
        newClaimState = "3";
    else {
        newClaimState = "1";
    }
    if (cs == newClaimState)
        newClaimState = "";

    return newClaimState;
}

export function isUnreleasedChecked(cs: string): boolean {
    var strArr = cs.split(',');
    return (strArr.indexOf('1') > -1 || strArr.indexOf('-1') > -1);
}

export function isReleasedChecked(cs: string): boolean {
    var strArr = cs.split(',');
    return (strArr.indexOf('3') > -1 || strArr.indexOf('-3') > -1);
}

export function isStateOnlyChecked(cs: string): boolean {
    var strArr = cs.split(',');
    return (strArr.indexOf('11') > -1 || strArr.indexOf('-11') > -1);
}

export function isDeletedChecked(cs: string): boolean {
    return cs.indexOf('-') > -1;
}

type ClickOnParam = {
    unreleased: boolean;
    released: boolean;
    deleted: boolean;
};

type SelectClaimStateResult = {
    data: string; // of numeric values that map to isUnreleasedChecked, isReleasedChecked, etc.( but will be overridden by clickOn and e)
    disableStateOnly: boolean; // turns on disableStateOnly in larger state if true (no effect if false)
    setRejectedFalse: boolean; // if true, updates ClaimStatus on state based on filter ClaimStatus[@All] value
    dateField: string; // date field id to preselect
    dateTo: string; // date to to preset
    dateFrom: string; // date from to preset
};

export function onSelectClaimState(state: IClaimFilterUIState, e: string, process_lowercase: string, clickOn: ClickOnParam | undefined = undefined): SelectClaimStateResult {
    var result = {
        data: '',
        disableStateOnly: false,
        setRejectedFalse: false,
        dateField: '',
        dateTo: '',
        dateFrom: '',
    };

    var checkSubmitDate = false;
    var clearReleaseDate = false;
    var isRadioButtons = !state.claimStateSwitch;

    var cs = getData(state.filter.Filter.ClaimState, "@ID");
    var unreleased = isUnreleasedChecked(cs);
    var released = isReleasedChecked(cs);
    var stateOnly = isStateOnlyChecked(cs);
    var deleted = isDeletedChecked(cs);

    if (clickOn) {
        unreleased = clickOn.unreleased;
        released = clickOn.released;
        deleted = clickOn.deleted;

    } else {
        switch (e) {
            case 'Unreleased':
                unreleased = !unreleased;

                if (isRadioButtons) {
                    released = stateOnly = !unreleased;  // flip them
                }

                break;
            case 'Released':
                released = !released;

                if (isRadioButtons) {
                    unreleased = stateOnly = !released;  // flip them
                }

                if (state.disableUnreleased) {
                    stateOnly = false;
                    result.disableStateOnly = true;
                    //this.disableOptions(["stateonly"], true);
                }

                break;
            case 'StateOnly':
                stateOnly = !stateOnly;

                if (isRadioButtons) {
                    released = unreleased = !stateOnly;  // flip them
                } else

                    if (state.disableUnreleased) {
                        released = true;
                        unreleased = false;
                    }
                break;

            case 'Deleted':
                deleted = !deleted;

                break;
        }
    }

    // bClaimStateChecked = true;
    // check deleted checkbox logic
    if (!isRadioButtons) {
        if (!unreleased && !released && !stateOnly) {
            if (!state.disableUnreleased) {
                unreleased = true;
            } else released = true;
        }
    }


    if (unreleased || stateOnly) {
        if (process_lowercase === "reprocessuserprofiles" || process_lowercase === "reprocesspayerstatusrules") {
            checkSubmitDate = true;

        } else {
            clearReleaseDate = true;
        }
    }
    if (stateOnly) {
        if (process_lowercase == "statereport") {
            result.setRejectedFalse = true;
            //this.onSelectClaimStatus('RejectedFalse');
        }
    }
    if (released) {
        if ((getData(state.filter.Filter?.DateField, "@ID") == "" || getData(state.filter.Filter?.DateField, "@ID") == "0") &&
            (getData(state.filter.Filter?.ClaimNote, "@From") == "" && getData(state.filter.Filter?.ClaimNote, "@To") == "") && 
            (getData(state.filter.Filter?.PayerStatus, "@From") == "" && getData(state.filter.Filter?.PayerStatus, "@To") == "") && 
            (getData(state.filter.Filter?.ReleaseBatch, "@ID") == "" || getData(state.filter.Filter?.ReleaseBatch, "@ID") == "0") &&
            getData(state.filter.Filter?.AccountNumber, "@Value").length < 5) {

            result.dateField = process_lowercase == "deslstatus" ? DATE_ASS_STATUS_DATE_EFF_ID : DATE_RELEASED_ID;
            result.dateFrom = (state.activeTab === 3 ? "01/00/0000" : (state.process_lowercase == "reprocesspayerstatusrules" || state.process_lowercase == "reprocessuserprofiles") ? "00/30/0000" : "00/00/0000");
            result.dateTo = "00/00/0000";
        }
    }


    if (checkSubmitDate) {
        if (getData(state.filter.Filter?.DateField, "@ID") == "" &&
            getData(state.filter.Filter?.DateField, "@From") === "" &&
            getData(state.filter.Filter?.DateField, "@To") === "" &&
            getData(state.filter.Filter?.ClaimNote, "@From") === "" &&
            getData(state.filter.Filter?.ClaimNote, "@To") === "" &&
            getData(state.filter.Filter?.StatusCategory, "@From") === "" &&
            getData(state.filter.Filter?.StatusCategory, "@To") === "") {

            result.dateField = DATE_SUBMITDATE_ID;
            result.dateFrom = "00/30/0000";
            result.dateTo = "00/00/0000";
        }       
    }

    if (state.dataRefreshed == false && clearReleaseDate) {
        if (getData(state?.filter.Filter?.DateField, "@ID") == DATE_RELEASED_ID) {
            result.dateField = "<<clear>>";
            result.dateFrom = "";
            result.dateTo = "";   
        }
        
    }


    var data: string[] = [];

    if (unreleased)
        data.push(deleted ? '-1' : '1');
    if (released)
        data.push(deleted ? '-3' : '3');
    if (stateOnly)
        data.push(deleted ? '-11' : '11');

    
    result.data = data.toString();
    
    return result;
}

export function checkReleaseDatePopulated(state: any) {
    if ((getData(state?.filter.Filter?.DateField, "@ID") == DATE_RELEASED_ID) &&
        ((state?.activeTab == 3 &&
            getData(state?.filter.Filter?.DateField, "@From") === "01/00/0000") ||
            getData(state?.filter.Filter?.DateField, "@From") === "00/00/0000") &&
        getData(state?.filter.Filter?.DateField, "@To") === "00/00/0000") {
        return true;
    }
    return false;
} 


function removeBadFilterData(action: any, filter: any) {
    // this used to be called xmlFormFill
    let errors: any[] = [];
    const listPopulation = action.data.api?.ListPopulation;
    const watchList = ["FormTypeList", "LOBList", "PayerList", "FacilityList", "SubmittedFileList", "DisplayOrderList", "ReleaseBatchList", "ErrorCategoryList"];
    for (let key in listPopulation) {
        if (watchList.includes(key)) {
            const obj = deepCopyFunction(listPopulation)[key]
            let availableFilters = obj?.map((data: any) => data.ID)
            let list: any;
            switch (key) {
                case "FormTypeList": // this is the UI label -> LOB 
                    list = filter.Filter?.FormTypes?.FormType
                    if (list) {
                        filter.Filter.FormTypes.FormType = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                if (errors.length > 1) {
                                    errors.push({ name: 'FormType', value: data["@ID"] })
                                }
                                else {
                                    errors.push({ name: 'FormType2', value: data["@ID"] })
                                    errors.push({ name: 'FormType', value: data["@ID"] })
                                }
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                case "LOBList": 
                    list = filter.Filter?.ClaimLOBs?.ClaimLOB
                    if (list) {
                        filter.Filter.ClaimLOBs.ClaimLOB = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                if (errors.length > 1) {
                                    errors.push({ name: 'ClaimLOB', value: data["@ID"] })
                                }
                                else {
                                    errors.push({ name: 'ClaimLOB2', value: data["@ID"] })
                                    errors.push({ name: 'ClaimLOB', value: data["@ID"] })
                                }
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                case "FacilityList":
                    list = filter.Filter?.Facilities?.Facility
                    if (list) {
                        filter.Filter.Facilities.Facility = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                if (errors.length > 1) {
                                    errors.push({ name: 'Facility', value: data["@ID"] })
                                }
                                else {
                                    errors.push({ name: 'Facility2', value: data["@ID"] })
                                    errors.push({ name: 'Facility', value: data["@ID"] })
                                }
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                case "SubmittedFileList":
                    list = filter.Filter?.SubmittedFile?.File;
                    if (list) {
                        filter.Filter.SubmittedFile.File = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                if (errors.length > 1) {
                                    errors.push({ name: 'File', value: data["@ID"] })
                                }
                                else {
                                    errors.push({ name: 'File2', value: data["@ID"] })
                                    errors.push({ name: 'File', value: data["@ID"] })
                                }
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                case "DisplayOrderList":
                    list = filter.Filter?.DisplayOrder?.DisplayOrder;
                    if (list) {
                        filter.Filter.DisplayOrder.DisplayOrder = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                if (errors.length > 1) {
                                    errors.push({ name: 'DisplayOrder', value: data["@ID"] })
                                }
                                else {
                                    errors.push({ name: 'DisplayOrder2', value: data["@ID"] })
                                    errors.push({ name: 'DisplayOrder', value: data["@ID"] })
                                }
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                case "ReleaseBatchList":
                    list = filter.Filter?.ReleaseBatch?.ReleaseBatch;
                    if (list) {
                        filter.Filter.ReleaseBatch = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                if (errors.length > 1) {
                                    errors.push({ name: 'ReleaseBatch', value: data["@ID"] })
                                }
                                else {

                                    errors.push({ name: 'ReleaseBatch2', value: data["@ID"] })
                                    errors.push({ name: 'ReleaseBatch', value: data["@ID"] })
                                }
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                case "ErrorCategoryList":
                    list = filter.Filter?.ErrorCategories?.ErrorCategory;
                    if (list) {
                        filter.Filter.ErrorCategories.ErrorCategory = list.filter((data: any, index: number) => {
                            if (!availableFilters.includes(data["@ID"])) {
                                if (errors.length > 1) {
                                    errors.push({ name: 'ErrorCategory', value: data["@ID"] });
                                }
                                else {
                                    errors.push({ name: 'ErrorCategory2', value: data["@ID"] });
                                    errors.push({ name: 'ErrorCategory', value: data["@ID"] });
                                }
                            } else {
                                return data
                            }
                        })
                    }
                    break;
                default:
                    break;
            }
        }

    }

    let message = null;
    if (errors.length) {
        let warningMessage = "";
        errors.forEach((data: any, index: number) => warningMessage += `${!index ? "(" : ""}${data.name}=${data.value}${errors.length - 1 !== index ? ", " : ")"}`)
        message = `The saved filter contains options that are no longer available ${warningMessage}. Unavailable options have not been loaded. Check your saved filter options.`
    }

    return { filter, message }
}



// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<IClaimFilterUIState, KnownActions> = (state: IClaimFilterUIState | undefined, action: KnownActions) => {

    if (state != undefined) {

        // handle stupid focus issues
        switch (action.type) {
            case 'CF_SEND_ALERT':
            case 'CF_SEND_ALERT_MODE_ON':
            case 'CF_ERROR_HANDLER_CONFIRM':
            case 'CF_SETBUSY':
            case 'CF_NCS_ALERT':
                break;
            default:
                state.focusField = '';
        }


        switch (action.type) {

            case 'CF_LOAD_FILTER':
                // console.log('setting filter to ' + JSON.stringify(action.data.uiData.data));
                const oldFilter = action.data.uiData.data !== undefined ? deepCopyFunction(action.data.uiData.data) : deepCopyFunction({ ...emptyFilter })  // copy of the object
                const { message, filter } = removeBadFilterData(action, oldFilter);
                return {
                    ...state,
                    filter: filter,
                    loaded: true,
                    dataRefreshed: true,

                    activeTab: action.data.uiData.index,
                    isBusy: false,
                    filterAlert: undefined,
                    focusField: '',
                    claimFilterErrorHandlerForNotFound: message !== undefined ? message: ""
                }
                break;


            case 'CF_SET_DATA_REFRESHED':
                return {
                    ...state,
                    dataRefreshed: true
                }
                break;

            case 'CF_CLEAR_DATA_REFRESHED':
                var initClaimState = '';
                if (state.filter?.Filter?.ClaimState && state.filter?.Filter?.ClaimState['@ID'] === '') {
                    if (state.activeTab == 0 || state.activeTab == 1)
                        initClaimState = '1';

                    else
                        initClaimState = '3';
                }


                return {
                    ...state,
                    dataRefreshed: false,
                    checkClaimState: false,
                    ...(initClaimState !== '' && {
                        filter: {
                            ...state.filter,
                            Filter: {
                                ...state.filter.Filter,
                                ClaimState: { '@ID': initClaimState },
                            }
                        }
                    }),
                }
                break;

            case 'CF_SET_ACTIVE_TAB':
                var clearPayers = action.data.uiData.data !== state.activeTab && (action.data.uiData.data == 1 || action.data.uiData.data == 2);
                var newClaimState = action.data.uiData.data == 0 ? setClaimStateFromSwitch(state) : '';  // if moving to qs, we now have radiobuttons, enforce them
                var currentTab = state.activeTab;

                return {
                    ...state,
                    activeTab: action.data.uiData.data,
                    ...(currentTab == 0 && action.data.uiData.data !== 0 && {checkClaimState: true}),

                    focusField: '',
                    ...(currentTab == 0 && action.data.uiData.data !== 0 && { checkClaimState: true }),
                    ...((action.data.uiData.data == 2 || action.data.uiData.data == 3) && { disableSubmittedFile: true }),
                    ...((action.data.uiData.data !==  2 && action.data.uiData.data !== 3) && { disableSubmittedFile: false }),
                    ...((action.data.uiData.data == 2 || action.data.uiData.data == 3) && {
                        filter: {
                            ...state.filter,
                            Filter: {
                                ...state.filter.Filter,
                                SubmittedFile: { '@ID': '' },
                            }
                        } }),
                    ...(clearPayers === true && {
                        filter: {
                            ...state.filter,
                            Filter: {
                                ...state.filter.Filter,
                                Payers: {
                                    Payer: [],
                                }
                            }
                        }
                    }),
                    ...(newClaimState !== "" && {
                        filter: {
                            ...state.filter,
                            Filter: {
                                ...state.filter.Filter,
                                ClaimState: { '@ID': newClaimState }
                            }
                        }
                    }),
                }
                break;


            case 'CF_SET_ACTIVE_SF_TAB':

                return {
                    ...state,
                    activeSavedFilterTab: action.data.uiData.data,
                }
                break;

            case 'CF_SET_LOADED':
                return {
                    ...state,
                    loaded: action.data.uiData.value
                }
                break;

            case 'CF_SELECT_FORMTYPES':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            FormTypes: {
                                FormType: action.data.uiData.selected,
                            }
                        }
                    }
                }
                break;

            case 'CF_SELECT_LOBS':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ClaimLOBs: {
                                ClaimLOB: action.data.uiData.selected,
                            }
                        }
                    }
                }
                break;



            case 'CF_UPDATE_DATEFIELD':
                //       console.log('allowTime ' + action.data.uiData.value);

                return {
                    ...state,
                    allowTime: action.data.uiData.value ?? false,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            DateField: action.data.uiData.data,

                        }
                    }
                }
                break;


            case 'CF_SELECT_DISPLAYORDER':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            DisplayOrder: action.data.uiData.selected
                        }
                    }
                }
                break;
            case 'CF_SELECT_RELEASEBATCH':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ReleaseBatch: action.data.uiData.selected
                        }
                    }
                }
                break;
            case 'CF_SELECT_SUBMITTEDFILE':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            SubmittedFile: action.data.uiData.selected
                        }
                    }
                }
                break;
            case 'CF_SELECT_USER':

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            User: action.data.uiData.selected
                        }
                    }
                }
                break;

            case 'CF_UPDATE_CLAIMNOTE':
                var clearReleaseDate = false;
                var defaultReleaseDate = false;
                
                if (action.data.uiData.data) {
                    if (action.data.uiData.data['@From'] !== '') {
                        if (checkReleaseDatePopulated(state))
                            clearReleaseDate = true;
                    } else {
                        var cs = getData(state?.filter.Filter.ClaimState, "@ID");

                        if (isReleasedChecked(cs) && (getData(state?.filter.Filter?.DateField, "@From") === "" ||
                            getData(state.filter.Filter?.DateField, "@To") === "")) {
                            defaultReleaseDate = true;
                        }
                    }
                }


                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ClaimNote: action.data.uiData.data,
                            ...(clearReleaseDate == true && {
                                DateField: { '@ID': '', '@From': '', '@To': '' }
                            }),
                            ...(defaultReleaseDate === true && {
                                DateField: { '@ID': DATE_RELEASED_ID, '@From': '00/00/0000', '@To': '00/00/0000' }
                            }),
                        }
                    }
                }
                break;


            case 'CF_SELECT_PAYER_TYPE':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            PayerType: action.data.uiData.data,
                        }
                    }
                }
                break;

            case 'CF_SELECT_PAYERS':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Payers: {
                                Payer: action.data.uiData.selected,
                            }
                        }
                    }
                }
                break;

            case 'CF_SELECT_ELIGIBILITY_PAYERS':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            EligibilityPayers: {
                                EligibilityPayer: action.data.uiData.selected,
                            }
                        }
                    }
                }
                break;


            case 'CF_SELECT_ERROR_CATAGORIES':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ErrorCategories: {
                                ErrorCategory: action.data.uiData.data,
                            }
                        }
                    }
                }
                break;

            case 'CF_SELECT_FACILITY':
                // console.log(action.data.uiData.selected);
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Facilities: {
                                Facility: action.data.uiData.selected,
                            }
                        }
                    }
                }
                break;
            case 'CF_SELECT_SDR_SENT_TO_STATE':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            SDR: action.data.uiData.data
                        }
                    }
                }
                break;

            case 'CF_SELECT_LAST_ATTACHMENT':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            LastAttachment: action.data.uiData.data
                        }
                    }
                }
                break;

            case 'CF_SELECT_APPEALS':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            AppealStatus: action.data.uiData.data
                        }
                    }
                }
                break;

            //  clickOnClaimState: (value1: boolean, value2: boolean, value3: boolean) => createDataAction('CF_CLICKON_CLAIMSTATE', { value1: value1, value2: value2, value3: value3 }),
            case 'CF_CLICKON_CLAIMSTATE':
                {
                    var result = onSelectClaimState(state, '', action.data.process, { unreleased: action.data.value1, released: action.data.value2, deleted: action.data.value3 });

                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            Filter: {
                                ...state.filter.Filter,
                                ClaimState: { '@ID': result.data },

                                ...(result.disableStateOnly === true && { disableStateOnly: true }),

                                ...(result.dateField === "<<clear>>" && {
                                    DateField: { '@ID': '', '@From': '', '@To': '' }
                                }),
                                ...(result.dateField !== "<<clear>>" && result.dateField !== '' && {
                                    DateField: { '@ID': result.dateField, '@From': result.dateFrom, '@To': result.dateTo }
                                }),
                                ...(result.setRejectedFalse == true &&
                                    (state.filter.Filter?.ClaimStatus['@All'] === 'true' ?
                                        { ClaimStatus: { '@Accepted': 'A', '@Warning': 'W', '@Rejected': '', '@All': '', '@Unreported': 'U' } }
                                        : { ClaimStatus: { '@Rejected': '' } })
                                )

                            }
                        }
                    }
                }
                break;

            case 'CF_SELECT_SPECIFIC_CLAIMSTATE':

                var fallThru = false;
                var cs = getData(state.filter.Filter.ClaimState, "@ID");
                if (action.data.type == 'Deleted') {
                    if ((!isDeletedChecked(cs) && action.data.value) || (isDeletedChecked(cs) && !action.data.value))
                        fallThru = true;
                } else if (action.data.type == 'Unreleased') {
                    if ((!isUnreleasedChecked(cs) && action.data.value) || (isUnreleasedChecked(cs) && !action.data.value))
                        fallThru = true;
                } else if (action.data.type == 'Released') {
                    if ((!isReleasedChecked(cs) && action.data.value) || (isReleasedChecked(cs) && !action.data.value))
                        fallThru = true;
                } else if (action.data.type == 'StateOnly') {
                    if ((!isStateOnlyChecked(cs) && action.data.value) || (isStateOnlyChecked(cs) && !action.data.value))
                        fallThru = true;
                }

                if (fallThru) {
                    var result = onSelectClaimState(state, action.data.type, action.data.process);

                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            Filter: {
                                ...state.filter.Filter,
                                ClaimState: { '@ID': result.data },

                                ...(result.disableStateOnly === true && { disableStateOnly: true }),

                                ...(result.dateField === "<<clear>>" && {
                                    DateField: { '@ID': '', '@From': '', '@To': '' }
                                }),
                                ...(result.dateField !== "<<clear>>" && result.dateField !== '' && {
                                    DateField: { '@ID': result.dateField, '@From': result.dateFrom, '@To': result.dateTo }
                                }),
                                ...(result.setRejectedFalse == true &&
                                    (state.filter.Filter?.ClaimStatus['@All'] === 'true' ?
                                        { ClaimStatus: { '@Accepted': 'A', '@Warning': 'W', '@Rejected': '', '@All': '', '@Unreported': 'U' } }
                                        : { ClaimStatus: { '@Rejected': '' } })
                                )

                            }
                        }
                    }
                    // couldn't get fall thru to work, copy/paste ftl
                }
                break;
            case 'CF_SELECT_CLAIM_STATE':
                //       console.log('select claim state ' + action.data.uiData.selected);
                var result = onSelectClaimState(state, action.data.uiData.value, action.data.uiData.text);


                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ClaimState: { '@ID': result.data },

                            ...(result.disableStateOnly === true && { disableStateOnly: true }),

                            ...(result.dateField === "<<clear>>" && {
                                DateField: { '@ID': '', '@From': '', '@To': '' }
                            }),
                            ...(result.dateField !== "<<clear>>" && result.dateField !== '' && {
                                DateField: { '@ID': result.dateField, '@From': result.dateFrom, '@To': result.dateTo }
                            }),
                            ...(result.setRejectedFalse == true &&
                                (state.filter.Filter?.ClaimStatus['@All'] === 'true' ?
                                    { ClaimStatus: { '@Accepted': 'A', '@Warning': 'W', '@Rejected': '', '@All': '', '@Unreported': 'U' } }
                                    : { ClaimStatus: { '@Rejected': '' } })
                            )

                        }
                    }
                }
                break;

            case 'CF_SELECT_CLAIM_STATUS':
                // console.log(action.data.uiData.selected);
                var csData = getClaimStatus(state, action.data.uiData.data);
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ClaimStatus: csData
                        }
                    }
                }
                break;

            case 'CF_SELECT_TRANSMIT_TYPE':
                {
                    // console.log(action.data.uiData.selected);
                    var newData: any = getTransmitTypeOption(state, action.data.uiData.id, action.data.uiData.value ?? false, action.data.uiData.value2 ?? false);

                    return {
                        ...state,
                        filter: {
                            ...state.filter,
                            Filter: {
                                ...state.filter.Filter,
                                TransmitType: newData
                            }
                        }
                    }
                }

                break;

            case 'CF_SELECT_SERVICE_TYPE':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            ServiceType: action.data.uiData.data
                        }
                    }
                }
                break;

            case 'CF_SELECT_CLAIMS_WITH_HOLDCODE':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,

                            ClaimsWithHoldCodeYes: { '@ClaimsWithHoldCodeYes': (action.data.uiData.data['@ClaimsWithHoldCodeYes'] ?? '') },
                            ClaimsWithHoldCodeNo: { '@ClaimsWithHoldCodeNo': action.data.uiData.data['@ClaimsWithHoldCodeNo'] ?? '' },
                        }
                    }
                }
                break;

            case 'CF_SELECT_BILLING_OPTION':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            BillingOption: action.data.uiData.data
                        }
                    }
                }
                break;
            case 'CF_SELECT_PAYER_STATUS_CATEGORY':
                var clearReleaseDate = false;
                var defaultReleaseDate = false;
                
                if (action.data.uiData.data) {
                    if (action.data.uiData.data['@From'] !== '') {
                        if (checkReleaseDatePopulated(state))
                            clearReleaseDate = true;
                    } else {
                        var cs = getData(state?.filter.Filter.ClaimState, "@ID");
                        
                        if (isReleasedChecked(cs) && (getData(state?.filter.Filter?.DateField, "@From") === "" ||
                            getData(state.filter.Filter?.DateField, "@To") === "")) {
                            defaultReleaseDate = true;
                        }
                    }
                }


                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            StatusCategory: action.data.uiData.data,
                            ...(clearReleaseDate === true && {
                                DateField: { '@ID': '', '@From': '', '@To': '' }
                            }),
                            ...(defaultReleaseDate === true && {
                                DateField: { '@ID': DATE_RELEASED_ID, '@From': '00/00/0000', '@To': '00/00/0000' }
                            }),
                        }
                    }
                }
                break;

            case 'CF_SELECT_ELIGIBILITY_STATUS_CATEGORY':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            EligibilityCategory: action.data.uiData.data
                        }
                    }
                }
                break;

            case 'CF_SELECT_AIISTATUS':
                // console.log(action.data.uiData.selected);

                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            AiiStatus: action.data.uiData.data
                        }
                    }
                }
                break;

            case 'CF_UPDATE_PLANCODE':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            PlanCode: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;
            case 'CF_UPDATE_FCLASS':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            FinancialClass: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;


            case 'CF_UPDATE_AMOUNT':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            Amount: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'CF_UPDATE_TOB':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            TypeOfBill: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;

            case 'CF_UPDATE_PATIENTNAME':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            PatientName: { '@Value': action.data.uiData.value },
                        }
                    }
                }
                break;
            case 'CF_UPDATE_USERFIELD':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            UserField: {
                                ...state.filter.Filter.UserField,
                                '@Value': action.data.uiData.value
                            },
                        }
                    }
                }
                break;
            case 'CF_UPDATE_USERFIELD2':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            UserField2: {
                                ...state.filter.Filter.UserField2,
                                '@Value': action.data.uiData.value
                            },
                        }
                    }
                }
                break;
            case 'CF_UPDATE_USERFIELD3':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            UserField3: {
                                ...state.filter.Filter.UserField3,
                                '@Value': action.data.uiData.value
                            },
                        }
                    }
                }
                break;
            case 'CF_UPDATE_USERFIELD4':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            UserField4: {
                                ...state.filter.Filter.UserField4,
                                '@Value': action.data.uiData.value
                            },
                        }
                    }
                }
                break;


            // id: e.value, value: isSpc? "true" : "", index : index
            case 'CF_UPDATE_USERFIELD_ID':
                return {
                    ...state,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,

                            ...(action.data.uiData.index == 1 && {
                                UserField: {
                                    ...state.filter.Filter.UserField,
                                    '@ID': action.data.uiData.id,
                                    '@Value': action.data.uiData.id === '' ? '' : action.data.uiData.value !== "" ? action.data.uiData.value : getData(state.filter.Filter.UserField, '@Value')
                                }
                            }),

                            ...(action.data.uiData.index == 2 && {
                                UserField2: {
                                    ...state.filter.Filter.UserField2,
                                    '@ID': action.data.uiData.id,
                                    '@Value': action.data.uiData.id === '' ? '' : action.data.uiData.value !== "" ? action.data.uiData.value : getData(state.filter.Filter.UserField2, '@Value')
                                }
                            }),

                            ...(action.data.uiData.index == 3 && {
                                UserField3: {
                                    ...state.filter.Filter.UserField3,
                                    '@ID': action.data.uiData.id,
                                    '@Value': action.data.uiData.id === '' ? '' : action.data.uiData.value !== "" ? action.data.uiData.value : getData(state.filter.Filter.UserField3, '@Value')
                                }
                            }),
                            ...(action.data.uiData.index == 4 && {
                                UserField4: {
                                    ...state.filter.Filter.UserField4,
                                    '@ID': action.data.uiData.id,
                                    '@Value': action.data.uiData.id === '' ? '' : action.data.uiData.value !== "" ? action.data.uiData.value : getData(state.filter.Filter.UserField4, '@Value')
                                }
                            }),

                        }
                    }
                }
                break;


            case 'CF_UPDATE_ACCOUNTNUMBER':

                var bClearDate = false;
                var bDefaultDate = false;
                var bDefaultDateElig = false;
                var bClaimStateChanged = false;
                var claimStateData = "";

                if (action.data.uiData.value) {
                    if (state.activeTab === 0) { // quick start - this is the OnChangeAccountNumber2 logic
                        if (action.data.uiData.value.length > 4 &&
                            ((getData(state.filter.Filter.DateField, '@From') == "00/00/0000" || getData(state.filter.Filter.DateField, '@From') == "0/0/0000") &&
                                (getData(state.filter.Filter.DateField, '@To') == "00/00/0000" || getData(state.filter.Filter.DateField, '@To') == "0/0/0000") &&
                                getData(state.filter.Filter.DateField, '@ID') === DATE_RELEASED_ID))    // 9 == "Date Released"       
                        {
                            bClearDate = true;
                        }
                        else if (action.data.uiData.value.length < 5 && getData(state.filter.Filter.ClaimState, "@ID").indexOf('3') > -1 &&
                            (getData(state.filter.Filter.DateField, '@From') == "" || getData(state.filter.Filter.DateField, '@To') == "")) {
                            bDefaultDate = true;
                        }
                    } else { // NOT quick start - this is the OnChangeAccountNumber logic
                        if (action.data.uiData.value.length < 5 && getData(state.filter.Filter.ClaimState, "@ID").indexOf('3') > -1 &&
                            (getData(state.filter.Filter.DateField, '@From') == "" || getData(state.filter.Filter.DateField, '@To') == "")) {
                            state.activeTab === 3 ? bDefaultDateElig = true : bDefaultDate = true;
                        }
                        if (state.claimStateSwitch && (state.disableUnreleased === true || state.disableReleased === true)) {
                            var cs = getData(state.filter.Filter.ClaimState, "@ID");
                            if (state.disableUnreleased === true) {
                                claimStateData = removeItemFromCSL(cs, "1");  // uncheck unreleased
                                if(!isReleasedChecked(claimStateData))
                                    claimStateData = addItemToCSL(claimStateData, "3");  // check released

                            } else if (state.disableReleased === true) {
                                claimStateData = removeItemFromCSL(cs, "3");  // uncheck released
                                if (getData(state.filter.Filter.ClaimState, "@ID").indexOf('11') === -1) {  // state only not selected
                                    if(!isUnreleasedChecked(claimStateData))
                                        claimStateData = addItemToCSL(claimStateData, "1");  // check unreleased
                                }
                            }
                            if (cs != claimStateData)
                                bClaimStateChanged = true;
                        }
                    }

                }


                return {
                    ...state,
                    checkClaimState: true,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            AccountNumber: { '@Value': action.data.uiData.value },
                            ...(bClaimStateChanged === true && {
                                ClaimState: { '@ID': claimStateData }
                            }),
                            ...(bClearDate === true && {
                                DateField: { '@ID': '', '@From': '', '@To': '' }
                            }),
                            ...(bDefaultDateElig === true && {
                                DateField: { '@ID': '9', '@From': '01/00/0000', '@To': '00/00/0000' }
                            }),
                            ...(bDefaultDate === true && {
                                DateField: { '@ID': '9', '@From': '00/00/0000', '@To': '00/00/0000' }
                            }),
                        }
                    }
                }
                break;

            case 'CF_DISABLE_OPTIONS':
                return {
                    ...state,
                    ...(action.data.type.indexOf("deleted") > -1 && { disableDeleted: action.data.value }),
                    ...(action.data.type.indexOf("unreleased") > -1 && { disableUnreleased: action.data.value }),
                    ...(action.data.type.indexOf("released") > -1 && { disableReleased: action.data.value }),
                    ...(action.data.type.indexOf("stateonly") > -1 && { disableStateOnly: action.data.value }),
                    ...(action.data.type.indexOf("formtype") > -1 && { disableFormType: action.data.value }),
                    ...(action.data.type.indexOf("claimlob") > -1 && { disableLobs: action.data.value }),
                    ...(action.data.type.indexOf("rejected") > -1 && { disableRejected: action.data.value }),
                    ...(action.data.type.indexOf("displayorder") > -1 && { disableDisplayOrder: action.data.value }),
                    ...(action.data.type.indexOf("electronic") > -1 && { disableElectronic: action.data.value }),
                    ...(action.data.type.indexOf("paper") > -1 && { disablePaper: action.data.value }),
                    ...(action.data.type.indexOf("clearinghouseprint") > -1 && { disableClearinghousePrint: action.data.value }),
                    ...(action.data.type.indexOf("direct") > -1 && { disableDirect: action.data.value }),
                    ...(action.data.type.indexOf("outsourced") > -1 && { disableOutsourced: action.data.value }),
                    ...(action.data.type.indexOf("hcd") > -1 && { disableHCD: action.data.value }),
                }
                break;
            case 'CF_DISABLE_STATUS_OPTIONS':
                return {
                    ...state,
                    ...(action.data.type.indexOf("duplicateId") > -1 && { disableDuplicateId: action.data.value }),
                    ...(action.data.type.indexOf("invalidOrMissingInfo") > -1 && { disableInvalidOrMissingInfo: action.data.value }),
                    ...(action.data.type.indexOf("patInsMatch") > -1 && { disablePatInsMatch: action.data.value }),
                    ...(action.data.type.indexOf("serviceDate") > -1 && { disableServiceDate: action.data.value }),
                    ...(action.data.type.indexOf("provider") > -1 && { disableProvider: action.data.value }),
                    ...(action.data.type.indexOf("neverRequested") > -1 && { disbleNeverRequested: action.data.value }),
                    ...(action.data.type.indexOf("requestPending") > -1 && { disableRequestPending: action.data.value }),
                    ...(action.data.type.indexOf("requestFailed") > -1 && { disableRequestFailed: action.data.value })
                }
                break;
            case 'CF_DISABLE_DIRECT_ENTRY_OPTIONS':
                return {
                    ...state,
                    ...(action.data.type.indexOf("notQueried") > -1 && { disableNotQueried: action.data.value }),
                    ...(action.data.type.indexOf("denied") > -1 && { disableDenied: action.data.value }),
                    ...(action.data.type.indexOf("exception") > -1 && { disableException: action.data.value }),
                    ...(action.data.type.indexOf("pended") > -1 && { disablePended: action.data.value }),
                    ...(action.data.type.indexOf("paid") > -1 && { disablePaid: action.data.value }), 
                }
                break; 

            case 'CF_DISABLE_OPTION':
                return {
                    ...state,
                    ...(action.data.type == "deleted" && { disableDeleted: action.data.value }),
                    ...(action.data.type == "unreleased" && { disableUnreleased: action.data.value }),
                    ...(action.data.type == "released" && { disableReleased: action.data.value }),
                    ...(action.data.type == "stateonly" && { disableStateOnly: action.data.value }),
                    ...(action.data.type == "formtype" && { disableFormType: action.data.value }),
                    ...(action.data.type == "claimlob" && { disableLobs: action.data.value }),

                    ...(action.data.type == "rejected" && { disableRejected: action.data.value }),
                    ...(action.data.type == "displayorder" && { disableDisplayOrder: action.data.value }),
                    ...(action.data.type == "electronic" && { disableElectronic: action.data.value }),
                    ...(action.data.type == "paper" && { disablePaper: action.data.value }),
                    ...(action.data.type == "clearinghouseprint" && { disableClearinghousePrint: action.data.value }),
                    ...(action.data.type == "direct" && { disableDirect: action.data.value }),
                    ...(action.data.type == "outsourced" && { disableOutsourced: action.data.value }),
                    ...(action.data.type == "hcd" && { disableHCD: action.data.value }),
                }
                break;

            case 'CF_SET_CLAIMSTATESWITCH':
                var newClaimState = '';
                if (action.data == false) {
                    newClaimState = setClaimStateFromSwitch(state);
                }

                return {
                    ...state,
                    claimStateSwitch: action.data,
                    ...(newClaimState !== "" && {
                        filter: {
                            ...state.filter,
                            Filter: {
                                ...state.filter.Filter,
                                ClaimState: { '@ID': newClaimState }
                            }
                        }
                    })
                }
                break;

            case 'CF_SET_POSTACTION':
                return {
                    ...state,
                    postAction: action.data
                }
                break;

            case 'CF_SET_FOCUSFIELD':
                return {
                    ...state,
                    focusField: action.data

                }
                break;

            case 'CF_DISABLE_QUICKSTART':
                // console.log(action.data.uiData.value);
                let qs = determineNextAvailableQuickstart(action.data.uiData.value, state.quickStart);
                return {
                    ...state,
                    disabledQuickstart: action.data.uiData.value,
                    quickStart: qs,
                }
                break;

            case 'CF_SELECT_QUICKSTART':
                return {
                    ...state,
                    quickStart: action.data.uiData.value,
                }
                break;
          
            case 'CF_SEND_ALERT':
                return {
                    ...state,
                    filterAlert: action.data,
                }
                break;
            case 'CF_SEND_ALERT_MODE_ON':
                return {
                    ...state,
                    filterAlertModeOn: action.data,
                }
                break;
            case 'CF_ERROR_HANDLER_CONFIRM':
                return {
                    ...state,
                    claimFilterErrorHandler: action.data.uiData.value,
                }
                break;
            case 'CF_SETBUSY':
                return {
                    ...state,
                    isBusy: action.data.uiData.value,
                }
                break;

                
            case 'CF_SELECT_FILTERQS': 
                return {
                    ...state,
                    quickStart: "3",
                    filterIndexQs: action.data.uiData.index,
                    filterNameQs: action.data.uiData.text,
                    filterIdQs: action.data.uiData.value,
                }
                break;


            case 'CF_SELECT_FILTER': 
                // debugger;
                return {
                    ...state,
                    filterName: action.data.uiData.value,
                    filterId: action.data.uiData.id,
                    focusField: '',
                }
                break;

                

            case 'CF_RELOAD_FILTERLIST':
            {
                    var newFilter: any = {};
                    newFilter.ID = "0";
                    newFilter.Name = "";
                    newFilter.Favorite = "9999";
                    newFilter.DESLStatus = "N";
                    newFilter.FilterType = "Claim";
                   

                if (action.data.api && action.data.api.ListPopulation && action.data.uiData.data) {
                    var lp = JSON.parse(action.data.uiData.data);
                    var filterId = lp.ListPopulation.FilterList['@Current'];

                    newFilter.ID = filterId;
                    
                    if (filterId != "0" && lp.ListPopulation.FilterList.Filter) {
                        for (var i = 0; i < lp.ListPopulation.FilterList.Filter.length; i++) {
                            if (lp.ListPopulation.FilterList.Filter[i].ID === filterId) {
                                newFilter.Name = lp.ListPopulation.FilterList.Filter[i].Name;
                                newFilter.ClaimState = lp.ListPopulation.FilterList.Filter[i].ClaimState;
                                newFilter.ClaimStatus = lp.ListPopulation.FilterList.Filter[i].ClaimStatus;
                                if (lp.ListPopulation.FilterList.Filter[i].FilterType === "S")
                                    newFilter.FilterType = "Status";
                                if (lp.ListPopulation.FilterList.Filter[i].FilterType === "E")
                                    newFilter.FilterType = "Eligibility";

                                if (!action.data.api.ListPopulation.FilterList.Filter.find(obj => obj['Name'] === newFilter.Name))
                                    action.data.api.ListPopulation.FilterList.Filter.push(newFilter);
                                break;
                            }
                        }
                    }
                }

                return {
                    ...state,
                    filterId: Number(newFilter.ID),
                    filterName: newFilter.Name,
                    filter: {
                        ...state.filter,
                        Filter: {
                            ...state.filter.Filter,
                            '@ID': newFilter.ID,
                            '@Name': newFilter.Name,
                        }
                    }
                }
                break;

            }

            case 'CF_OPEN_FILTERLIST':
            {
                return {
                    ...state,
                    isFilterListOpen: true,
                    filterName: '',
                    filterId: 0,
                    }
                break;
            }
            case 'CF_CLOSE_FILTERLIST':
            {
                return {
                    ...state,
                    isFilterListOpen: false,
                }
                break;
                }

            case 'CF_NCS_ALERT':
                return {
                    ...state,
                    saveFilterNcsAlert: action.data.uiData.value,
                }
                break;
            case 'CF_DISABLE_STATE_DATA_REPORTING':
                return {
                    ...state,
                    ...(action.data.type.indexOf("senttostate") > -1 && { disableStateDataReportingSentToState: action.data.value }),
                    ...(action.data.type.indexOf("notsettostate") > -1 && { disableStateDataReportingNotSentToState: action.data.value })
                }
                break;
            case 'CF_DISABLE_BILLING_OPTION':
                return {
                    ...state,
                    ...(action.data.type.indexOf("primary") > -1 && { disableBillingOptionPrimary: action.data.value }),
                    ...(action.data.type.indexOf("secondarymanual") > -1 && { disableBillingOptionSecondaryManual: action.data.value }),
                    ...(action.data.type.indexOf("secondaryautomated") > -1 && { disableBillingOptionSecondaryAutomated: action.data.value }),
                    ...(action.data.type.indexOf("tertiaryautomated") > -1 && { disableBillingOptionTertiaryAutomated: action.data.value }),
                    ...(action.data.type.indexOf("tertiarymanual") > -1 && { disableBillingOptionTertiaryManual: action.data.value }),
                    ...(action.data.type.indexOf("rebill") > -1 && { disableBillingOptionRebill: action.data.value })
                }
                break;
            case 'CF_DISABLE_RELEASE_BATCH':
                return {
                    ...state,
                    ...(action.data.type == "releasebatch" && { disableReleaseBatch: action.data.value })
                }
                break;
            case 'CF_CLEAR_CLAIM_FILTER_FOR_NOT_FOUND':
                return {
                    ...state,
                    claimFilterErrorHandlerForNotFound: action.data.value
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;

        }
    }

    return state || deepCopyFunction(defaultState);
}

