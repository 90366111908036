import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as RunBridgeRoutinesUI from './ui/RunBridgeRoutinesUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IRunBridgeRoutinesState = ICrudComponentState<RunBridgeRoutines, RunBridgeRoutinesUI.IRunBridgeRoutinesUIState>;

export const actionCreators = createCrudComponentActions<RunBridgeRoutinesUI.ActionCreators>(RunBridgeRoutinesUI.actionCreators);
export type IRunBridgeRoutinesActions = typeof actionCreators;
export type IRunBridgeRoutinesActionProps = ICrudComponentActionProps<IRunBridgeRoutinesActions>
export const reducers = createCrudComponentReducers(CrudTypes.mcti277ResponsesResults, RunBridgeRoutinesUI.reducer);
export const reducer = combineReducers<IRunBridgeRoutinesState>(reducers);