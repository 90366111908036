import {Reducer} from 'redux';
import {ActionTypes, createDataAction} from '@scripts/util/ActionHelpers';
import {ICrudActionData} from "@scripts/util/CrudComponentHelpers"
import { AUTOMODULE_PROPS } from '@store/AutoModule';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IAutoModuleUIState {

    selectedAutoModule: IAutoModuleUIData; // left side selection
    selectedItem: MCAM_AutoModule; // the node selected by above
    reportData: {}; // formdata report response
    //start form fields disabled/enabled
    filterLabel: string;
    filterDisabled: boolean;
    workflowCodesHidden: boolean;
    exportChangesFormatDisabled: boolean;
    spinOffFormTypeDisabled: boolean;
    reportFormatDisabled: boolean;
    reportGroupingDisabled: boolean;
    csvOptionsDelimiterDisabled: boolean;
    csvOptionsQualifierDisabled: boolean;
    printOptionsFormTypeDisabled: boolean;
    printOptionsPrintTypeDisabled: boolean;
    printOptionsFontFaceDisabled: boolean;
    printOptionsFontSizeDisabled: boolean;
    actionDisabled: boolean;
    printerDisabled: boolean;
    destinationDisabled: boolean;
    //end form fields disabled/enabled
    selectedWorkflowCode?: string;
    selectedExportChangesFormat?: string;
    selectedSpinOffFormType?: string;
    selectedReportFormat?: string;
    selectedReportGrouping?: string;
    selectedDelimiter?: string;
    selectedQualifier?: string;
    selectedPrintFormType?: string;
    selectedReportAction?: string;
    selectedPrintType?: string;
    selectedFontFace?: string;
    selectedFontSize?: string;
    instPaperCommandSetConfigId?: string;
    profPaperCommandSetConfigId?: string;
    selectedPrinter?: string;
    
    changed: boolean;
    selectedModule?: MCAM_Module;
    filterList: MCAM_Filter[];
    formatList: any[];
    reportActionList: MCAM_ReportAction[];
    groupingList: any[];
    
    autoModuleNameError?: string;
    autoModuleAutoModuleError?: string;
    autoModuleAlert?: string;
    autoModuleRemoveConfirm?: string;
    submitNCSAlert?: string;
}

interface IAutoModuleUIFieldUpdate {
    value: string;
}

interface IAutoModuleUIObjUpdate{
    reportData: object;
}

interface IAutoModuleUIError {
    value: string;
    id?: string;
}

interface IAutoModuleUIAllGroupsSelected {
    selected: string[];
}

interface IAutoModuleUIAutoModulesSelected {
    selected: string[];
}

export interface IAutoModuleUIData {
    //index: string;
    id: string;
    text: string;
};

export interface IAutoModuleDayOfWeekUIData {
    index: string;
    value: boolean;
};


export const defaultAutoModule: IAutoModuleUIData = {
    //  index: '',
    id: '',
    text: '',
};

const emptyItem: MCAM_AutoModule = {
    '@ID' : '',
    '@Name': '',
    '@ModuleID' : '',
    '@FilterID' : '',
    '@Text' : '',
    '@ReportActionID' : '',
    '@Destination' : '',
    '@Parameters' : '',
}

const emptyAutoModule: MCAM_AutoModule = {
    "@ID" : '',
    "@Name" : ''
};

const emptyModule : MCAM_Module = {
    "@ID": '',
    "@Name": ''
}

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

// data action
export interface ISelectAutoModule extends ICrudActionData<MCAutoModuleType, IAutoModuleUIData> { }
export interface IUpdateAutoModuleField extends ICrudActionData<MCAutoModuleType, IAutoModuleUIFieldUpdate> {}
export interface IUpdateAutoModuleObj extends ICrudActionData<MCAutoModuleType, IAutoModuleUIObjUpdate> {} 

export interface ISelectSchedule extends ICrudActionData<MCAutoModuleType, IAutoModuleUIData> { }
export interface ISelectDayOfWeek extends ICrudActionData<MCAutoModuleType, IAutoModuleDayOfWeekUIData> { }
export interface IAddModifySchedule extends ICrudActionData<MCAutoModuleType, MCAL_Event> {}

export interface IError extends ICrudActionData<MCAutoModuleType, IAutoModuleUIError> { }

export interface ISelectGroupList extends ICrudActionData<MCAutoModuleType, IAutoModuleUIAllGroupsSelected> { }
export interface ISelectAutoModulesList extends ICrudActionData<MCAutoModuleType, IAutoModuleUIAutoModulesSelected> { }

// mess with crud actions
export interface IAddModifyAutoModule extends ICrudActionData<MCAutoModuleType, MCAL_AutoProcess> { }
export interface IRemoveAutoModule extends ICrudActionData<MCAutoModuleType, MCAL_AutoProcess> { }
export interface IAddRemoveButtons extends ICrudActionData<MCAutoModuleType, boolean> { }

export const actionCreators = {

    selectAutoModule: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE', selectInfo),
    selectProcess: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_PROCESS', selectInfo),
    setReportFormData: (formDataInfo : IUpdateAutoModuleObj) => createDataAction('SET_AUTOMODULE_REPORT_DATA', formDataInfo),
    updateAutoModuleName: (fieldInfo: IUpdateAutoModuleField) => createDataAction('UPDATE_AUTOMODULE_NAME', fieldInfo),
    updateAutoModuleDesc: (fieldInfo: IUpdateAutoModuleField) => createDataAction('UPDATE_AUTOMODULE_DESC', fieldInfo),
    selectFilter: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_FILTER', selectInfo),
    selectWorkflowCode: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_WORKFLOW', selectInfo),
    selectExportChanges: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_EXPORTCHANGES', selectInfo),
    selectSpinOffFormType: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_SPINOFF', selectInfo),
    selectReportFormat: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_REPORTFORMAT', selectInfo),
    selectReportGrouping: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_REPORTGROUPING', selectInfo),
    selectDelimiter: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_DELIMITER', selectInfo),
    selectQualifier: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_QUALIFIER', selectInfo),
    selectPrintFormType: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_PRINTFORM', selectInfo),
    selectPrintType: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_PRINTTYPE', selectInfo),
    selectFontFace: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_FONTFACE', selectInfo),
    selectFontSize: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_FONTSIZE', selectInfo),
    selectINameFieldSet: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_INAMESET', selectInfo),
    selectPNameFieldSet: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_PNAMESET', selectInfo),
    selectPrintAction: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_PRINTACTION', selectInfo),
    selectPrinter: (selectInfo: ISelectAutoModule) => createDataAction('SELECT_AUTOMODULE_PRINTER', selectInfo),
    updateAutoModuleDest: (fieldInfo: IUpdateAutoModuleField) => createDataAction('UPDATE_AUTOMODULE_DEST', fieldInfo),
    
    
    errorAutoModuleField: (fieldInfo: IError) => createDataAction('ERROR_AUTOMODULE_FIELD', fieldInfo),
    errorAutoModuleAlert: (fieldInfo: IUpdateAutoModuleField) => createDataAction('ERROR_AUTOMODULE_ALERT', fieldInfo),
    errorAutoModuleRemoveConfirm: (fieldInfo: IUpdateAutoModuleField) => createDataAction('ERROR_AUTOMODULE_REMOVE_CONFIRM', fieldInfo),

    addAutoModule: (addInfo: IAddModifyAutoModule) => createDataAction('ADD_AUTOMODULE', addInfo),
    updateAutoModule: (updateInfo: IAddModifyAutoModule) => createDataAction('UPDATE_AUTOMODULE', updateInfo),
    removeAutoModule: (removeInfo: IRemoveAutoModule) => createDataAction('REMOVE_AUTOMODULE', removeInfo),
    //ncs not supported on legacy
    sendSubmitNCSAlert: (fieldInfo: IUpdateAutoModuleField) => createDataAction('NCS_ALERT', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IAutoModuleUIState = {
    selectedAutoModule: defaultAutoModule,
    selectedItem: emptyAutoModule,
    selectedModule: emptyModule,

    changed: false,
    
    reportData: {},
    // start form field enabled/disabled section
    filterLabel: "Filter:",
    filterDisabled: true,
    workflowCodesHidden: true,
    exportChangesFormatDisabled: true,
    spinOffFormTypeDisabled: true,
    reportFormatDisabled: true,
    reportGroupingDisabled: true,
    csvOptionsDelimiterDisabled: true,
    csvOptionsQualifierDisabled: true,
    printOptionsFormTypeDisabled: true,
    printOptionsPrintTypeDisabled: true,
    printOptionsFontFaceDisabled: true,
    printOptionsFontSizeDisabled: true,
    actionDisabled: true,
    printerDisabled: true,
    destinationDisabled: true,

    selectedWorkflowCode: '',
    selectedExportChangesFormat: '',
    selectedSpinOffFormType: '',
    selectedReportFormat: '',
    selectedReportGrouping: '',
    selectedDelimiter: '',
    selectedQualifier: '',
    selectedPrintFormType: '',
    selectedReportAction: '',
    selectedPrintType: '',
    selectedFontFace: '',
    selectedFontSize: '',
    instPaperCommandSetConfigId: '',
    profPaperCommandSetConfigId: '',
    selectedPrinter: '',
    
    filterList: [],
    formatList: [] = [{"Type": "- Select -"}],
    reportActionList: [] = [{"@ID": "", "@Name": "- Select Action -"}],
    groupingList: [] =  [{value: '', label: "- Select -"}],
    
    autoModuleNameError: undefined,
    autoModuleAutoModuleError: undefined,
    autoModuleAlert: undefined,
    autoModuleRemoveConfirm: undefined,
    submitNCSAlert: '',
};

const NAME_ERROR_1: string = "An auto module by that name already exists.  Please type in a different name.";

function getParameter(parameters : string, flag : string) {
    flag = "\\" + flag + "\\s+";
    let re = eval("/" + flag + "/i");
   
    if(re.test(parameters))
    {
        re = eval("/" + flag + "(\\w*)/i");
        let matches = re.exec(parameters);
        return matches[1];
    }
    else
    {
        return "";
    }
}

function isCsvOverrideDisabled(reptType: string, reptId : string) : boolean {
    return reptType != 'CSV' || reptId == "2250" ||
        reptId == "2251" || reptId == "2252" || reptId == "2253" || reptId == "2351" || reptId == "2352" || reptId == "2353";
}

function IsEpicCrdReport(reportId : string) : boolean {
    return reportId == "2251" || reportId == "2250" || reportId == "2252" || 
        reportId == "2253" || reportId == "2254" || reportId == "2255" ||
        reportId == "2350" || reportId == "2351" || reportId == "2352" || reportId == "2353" || reportId == "2354" || reportId == "2355"; // asrn-156
}

function getProcessFormState(currentItem : MCAM_AutoModule, crud : MCAutoModuleMaintenanceInfo, id: string, reportData : any, processChanged : boolean) : any {
    let moduleData = crud.ModuleList.Module.find(ob => { return ob["@ID"] === id });
    // copy of the process form to modify
    let processFormState: IAutoModuleUIState = {
        changed: false, 
        reportData: {},
        selectedAutoModule: {id: '', text: ''},
        selectedItem: currentItem,
        selectedModule: {"@ID": '', "@Name": ''},
        filterLabel : 'Filter:',
        filterDisabled : true,
        workflowCodesHidden : true,
        exportChangesFormatDisabled : true,
        spinOffFormTypeDisabled : true,
        reportFormatDisabled : true,
        reportGroupingDisabled : true,
        csvOptionsDelimiterDisabled : true,
        csvOptionsQualifierDisabled : true,
        printOptionsFormTypeDisabled : true,
        printOptionsPrintTypeDisabled : true,
        printOptionsFontFaceDisabled : true,
        printOptionsFontSizeDisabled : true,
        actionDisabled : true,
        printerDisabled : true,
        destinationDisabled : true,
        filterList : [],
        formatList : [{'Type': '- Select -'}],
        reportActionList : [{'@Name': '- Select Action -', '@ID' : ''}],
        groupingList : [{value: '', label: "- Select -"}],
        selectedWorkflowCode: '',
        selectedExportChangesFormat: '',
        selectedSpinOffFormType: '',
        selectedReportFormat: '',
        selectedReportGrouping: '',
        selectedDelimiter: '',
        selectedQualifier: '',
        selectedPrintFormType: '',
        selectedReportAction: '',
        selectedPrintType: '',
        selectedFontFace: '',
        selectedFontSize: '',
        instPaperCommandSetConfigId: '',
        profPaperCommandSetConfigId: '',
        selectedPrinter: ''
    }

    // refine the filters based on filterType/processType
    let currentModuleType = moduleData ? moduleData["@ModuleType"] : '';
    let filterType = moduleData ? moduleData["@FilterType"] : '';
    let moduleName = moduleData ? moduleData["@Name"] : '';
    let dataFormat : any = moduleData ? moduleData["@ModuleDataFormat"] : '';
    
    let filterList: MCAM_Filter[] = [];
    let checkList: MCAM_Filter[] = [];
    let finalFilters: MCAM_Filter[] = [];
    
    let currentReportId : string = "";
         
    switch(currentModuleType){
        case AUTOMODULE_PROPS.MODULETYPE_REPORT:
        case AUTOMODULE_PROPS.MODULETYPE_DIRECTENTRY:
        case AUTOMODULE_PROPS.MODULETYPE_OUTSOURCE:
        case AUTOMODULE_PROPS.MODULETYPE_CLAIMVISION:
            let startIdx = dataFormat.indexOf("-i") + 3;
            let endIdx = startIdx + 4;
            currentReportId = dataFormat.substring(startIdx, endIdx).trim();
            //console.log(reportData);
            let reportArr : any = Object.values(reportData);
            let reportFound : boolean = false;
            let i : number = 0;
            let reportType : string = ''
            if(currentReportId) {
                while (!reportFound && (!reportFound && i <= reportArr.length - 1)) {
                    let reportItem = reportArr[i].find((x: { ID: string; }) => x.ID == currentReportId);
                    if (reportItem) {
                        reportFound = true;

                        processFormState.formatList = processFormState.formatList.concat(reportItem.ReportInfo.Formats);
                    }
                    i++;
                }
                if (processFormState.formatList.length > 1) { // exclude the initial value
                    processFormState.reportFormatDisabled = false;
                    processFormState.actionDisabled = false;
                    processFormState.printerDisabled = false;
                    processFormState.destinationDisabled = false; 
                }
                // check if you need workflow codes
                if(IsEpicCrdReport(currentReportId)) {
                    processFormState.workflowCodesHidden = false;
                }
                
            }
            
        break;
        case AUTOMODULE_PROPS.MODULETYPE_PAPERCLAIMS:

            processFormState.printOptionsFormTypeDisabled = false;
            processFormState.printOptionsPrintTypeDisabled = false;
            processFormState.printOptionsFontFaceDisabled = false;
            processFormState.printOptionsFontSizeDisabled = false;
            processFormState.actionDisabled = false;
            processFormState.printerDisabled = false;
            processFormState.destinationDisabled = false;
        break;
        case AUTOMODULE_PROPS.MODULETYPE_PAPERCLAIMSALT:
        case AUTOMODULE_PROPS.MODULETYPE_IMACS:

            processFormState.printOptionsFormTypeDisabled = false;
            processFormState.actionDisabled = false;
            processFormState.printerDisabled = false;
            processFormState.destinationDisabled = false;
        break;
        case AUTOMODULE_PROPS.MODULETYPE_SPINOFF:
            
            processFormState.spinOffFormTypeDisabled = false;
            break;
        case AUTOMODULE_PROPS.MODULETYPE_EXPORT:
            processFormState.actionDisabled = false;
            processFormState.printerDisabled = false;
            processFormState.destinationDisabled = false;
            processFormState.exportChangesFormatDisabled = false;
            break;
        case AUTOMODULE_PROPS.MODULETYPE_RRT_FILE:
        case AUTOMODULE_PROPS.MODULETYPE_REMITPOSTINGFILE:
            
            processFormState.actionDisabled = false;
            processFormState.printerDisabled = true;
            processFormState.destinationDisabled = false;
            
            crud.ReportActionList.ReportAction.forEach(rptAction => {
                if(!(rptAction["@Name"] == "Download Only") && !(rptAction["@Name"] == "None")){
                    rptAction.disabled = 'true';
                }
                processFormState.reportActionList.push(rptAction);
            });
            break;            
    }
    
    if(!processFormState.actionDisabled && processFormState.reportActionList.length == 1){
        crud.ReportActionList.ReportAction.forEach(ob => { delete ob["disabled"]; })
        processFormState.reportActionList = processFormState.reportActionList.concat(crud.ReportActionList.ReportAction);
    }
    
    switch (filterType) {
        case 'C':
             switch (currentModuleType) {
                case AUTOMODULE_PROPS.MODULETYPE_RELEASE:

                    checkList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                        && (!ob["@ClaimState"] || ob["@ClaimState"] == '1' ));
                    checkList.forEach(item => {
                        if ( item["@ClaimState"] == '1' && (item["@ClaimStatus"] ? item["@ClaimStatus"] : '').indexOf('R') > -1){
                            // ignore it
                        }
                        else {
                            filterList.push(item);
                        }
                    })
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_RELEASESDR:
                    checkList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                        && (!ob["@ClaimState"] || ob["@ClaimState"] == '3' || ob["@ClaimState"] == '11'));
                    checkList.forEach(item => {
                        if ( item["@ClaimState"] == '11' && (item["@ClaimStatus"] ? item["@ClaimStatus"] : '').indexOf('R') > -1){
                            // ignore it
                        }
                        else {
                            filterList.push(item);
                        }
                    })
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_RELEASEHCD:
                    filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                        && (!ob["@ClaimState"] || ob["@ClaimState"] == '1') && ob["@ClaimStatus"] != 'R' && ob["@HCD"] == 'H');
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_REBILL:
                case AUTOMODULE_PROPS.MODULETYPE_SECONDARY:
                    filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                        && (!ob["@ClaimState"] || ob["@ClaimState"] == '3') );
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_VALIDATE:
                    filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                        && (!ob["@ClaimState"] || ob["@ClaimState"] == '1' || ob["@ClaimState"] == '11' ) );
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_CHECKELIG:
                case AUTOMODULE_PROPS.MODULETYPE_REPROCESSMCA:
                    filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                        && (!ob["@ClaimState"] || ob["@ClaimState"] == '1') );
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_REPORT:
                    let parameters : string = '';
                    if(moduleData)
                        parameters = moduleData["@ModuleDataFormat"] ? moduleData["@ModuleDataFormat"] : '';
                    let filterTypeFlag = getParameter(parameters, AUTOMODULE_PROPS.PARAM_FILTERTYPEFLAG);
                    switch (filterTypeFlag)
                    {
                        case "21":	// Unreleased
                        case "91":	// Unreleased Deleted
                            filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                                && (ob["@ClaimState"] == '1' || ob["@ClaimState"] == '11') );
                            break;
                        case "23":	// Released
                        case "93":	// Released Deleted
                            filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                                && (ob["@ClaimState"] == '3' || ob["@ClaimState"] == '11') );
                            break;
                        default:
                            filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E'));
                    }
                    break;
                case AUTOMODULE_PROPS.MODULETYPE_ECSBATCHSTATUS:
                    filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                        && (!ob["@ClaimState"] || ob["@ClaimState"] == '3') );
                    break;
                default:
                    if( moduleName == 'DE Check Status + Service Lines'){
                        filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E')
                            && (ob["@ClaimState"] == '3' && ob["@DESLStatus"] == 'Y') );
                    }
                    else{
                        filterList = crud.FilterList.Filter.filter(ob => (ob["@FilterType"] == 'C' || ob["@FilterType"] == 'S' || ob["@FilterType"] == 'E'));
                    }
                    break;
            }
            processFormState.filterDisabled = false;
            break; // end case: 'C'
        case 'R':
            filterList = crud.FilterList.Filter.filter(ob => ob["@FilterType"] == 'R');
            processFormState.filterDisabled = false;
            break;
        case 'A':
            filterList = crud.FilterList.Filter.filter(ob => ob["@FilterType"] == 'A');
            processFormState.filterDisabled = false;            
            break;
        case 'G':
            filterList = crud.FilterList.Filter.filter(ob => ob["@FilterType"] == 'G');
            processFormState.filterDisabled = false;
            processFormState.filterLabel = "Profile:";
            break;           
    }
    let filterGroupLabel : string = '';
    if(!processFormState.filterDisabled) {
        let currentType: string = '';
        filterList.forEach(filter => {

            if (filter["@FilterType"] != currentType) {
                switch (filter["@FilterType"]) {
                    case 'C':
                        filterGroupLabel = '- Select Advanced Claim Filter -';
                        break;
                    case 'E':
                        filterGroupLabel = '- Select Eligibility Filter -';
                        break;
                    case 'S':
                        filterGroupLabel = '- Select Payer Status Filter -';
                        break;
                    case 'R':
                        filterGroupLabel = '- Select Remit Filter -';
                        break;
                    case 'G':
                        filterGroupLabel = '- Select Profile -';
                        break;
                }
                currentType = filter["@FilterType"] ? filter["@FilterType"] : '';
                // we have to give this a unique id, check against it and make sure
                // its a number for a 'legit' id
                finalFilters.push({"@Name": filterGroupLabel, "@ID": currentType});
            }
            finalFilters.push(filter);
        });
    }
    processFormState.filterList = finalFilters;
    // set the form details if we're selecting it from the list
    if(currentItem && !processChanged) {
        let parameters : string = ''
        if(currentItem["@Parameters"])
            parameters = currentItem["@Parameters"] ? currentItem["@Parameters"] : '';
        
        if(!processFormState.exportChangesFormatDisabled)
            processFormState.selectedExportChangesFormat = getParameter(parameters, AUTOMODULE_PROPS.PARAM_EXPORTFORMAT);
        if(!processFormState.spinOffFormTypeDisabled)
            processFormState.selectedSpinOffFormType = getParameter(parameters, AUTOMODULE_PROPS.PARAM_FORMTYPE);
        if(!processFormState.printOptionsFormTypeDisabled)
            processFormState.selectedPrintFormType = getParameter(parameters, AUTOMODULE_PROPS.PARAM_PRINTFORMTYPE);
        if(!processFormState.workflowCodesHidden)
            processFormState.selectedWorkflowCode = getParameter(parameters, AUTOMODULE_PROPS.PARAM_WORKFLOWFILTER);
        
        if(!processFormState.printOptionsPrintTypeDisabled)
            processFormState.selectedPrintType = getParameter(parameters, AUTOMODULE_PROPS.PARAM_PRINTTYPE); 
        
        if(!processFormState.printOptionsFontFaceDisabled)
            processFormState.selectedFontFace = getParameter(parameters, AUTOMODULE_PROPS.PARAM_FONTFACE); 
        if(!processFormState.printOptionsFontSizeDisabled) 
            processFormState.selectedFontSize = getParameter(parameters, AUTOMODULE_PROPS.PARAM_FONTSIZE); 
        if(!processFormState.reportFormatDisabled)
            processFormState.selectedReportFormat = getParameter(parameters, AUTOMODULE_PROPS.PARAM_REPORTFORMAT); 
        
        if(processFormState.selectedPrintFormType != '') {
            processFormState.instPaperCommandSetConfigId = getParameter(parameters, AUTOMODULE_PROPS.PARAM_IPAPERCOMMANDSET);
            processFormState.profPaperCommandSetConfigId = getParameter(parameters, AUTOMODULE_PROPS.PARAM_PPAPERCOMMANDSET);
        }
        
        if(processFormState.selectedReportFormat) {
            processFormState.csvOptionsDelimiterDisabled = isCsvOverrideDisabled(processFormState.selectedReportFormat, currentReportId);
            processFormState.csvOptionsQualifierDisabled = isCsvOverrideDisabled(processFormState.selectedReportFormat, currentReportId);
        }
        if(!processFormState.reportGroupingDisabled)
            processFormState.selectedReportGrouping = getParameter(parameters, AUTOMODULE_PROPS.PARAM_REPORTGROUPING);
        if(!processFormState.csvOptionsDelimiterDisabled)
            processFormState.selectedDelimiter = getParameter(parameters, AUTOMODULE_PROPS.PARAM_DELIMITER);
        if(!processFormState.csvOptionsQualifierDisabled)
            processFormState.selectedQualifier = getParameter(parameters, AUTOMODULE_PROPS.PARAM_QUALIFIER);
        if(!processFormState.actionDisabled)    
            processFormState.selectedReportAction = currentItem["@ReportActionID"] ? currentItem["@ReportActionID"] : '';
        if(!processFormState.printerDisabled)
            processFormState.selectedPrinter = currentItem["@PrinterID"] ? currentItem["@PrinterID"] : '';
    }
    return processFormState;
}

function getGrouping() : any []{
    return [{value: '', label: "- Select -"}];
}

function findInArr(array: MCAM_AutoModule[], value :string ) : number {
    for(let i = 0; i < array.length; i += 1) {
        if(array[i]["@ID"] === value) {
            return i;
        }
    }
    return -1;
}

function upsertAutoModule(array: MCAM_AutoModule[], item: MCAM_AutoModule){
    let newArray = array.slice();
    var olditem = newArray.find(obj => obj['@ID'] === item['@ID']);
    if (!olditem) {        
        newArray.splice(array.length, 0, JSON.parse(JSON.stringify(item)));
    } else {
        // if we have a printer and the old crud didnt have it as an attr as all, add it back in in order
        //  if(!olditem["@PrinterID"] && item["@PrinterID"] != '') {
        //       let oldIdx = findInArr(newArray, item["@ID"]);
        //
        //  }
        //     let newObj : MCAM_AutoModule = {
        //         "@ID" : item['@ID'],
        //         "@Name" : item['@Name'],
        //         "@ModuleID" : item['@ModuleID'],
        //         "@FilterID" : item['@FilterID'],
        //         "@Text" : item['@Text'],
        //         "@ReportActionID" : item['@ReportActionID'],
        //         "@PrinterID" : item['@PrinterID'],
        //         "@Destination" : item['@Destination'],
        //         "@Parameters" : item['@Parameters']
        //     }
      //      newArray.splice(oldIdx, 1, JSON.parse(JSON.stringify(newObj)));
      //  }
        //else {
            olditem['@Name'] = item['@Name'];
            olditem['@ModuleID'] = item['@ModuleID'];
            olditem['@FilterID'] = item['@FilterID'];
            olditem['@Text'] = item['@Text'];
            olditem['@ReportActionID'] = item['@ReportActionID'];
            olditem['@PrinterID'] = item['@PrinterID'];
            olditem['@Destination'] = item['@Destination'];
            olditem['@Parameters'] = item['@Parameters'];
        //}
    }

    return newArray;
}

function removeAutoModule(array: MCAM_AutoModule[], item: MCAM_AutoModule)
{
    let newArray = array.slice();
    var oldItem = newArray.filter(obj => obj['@ID'] !== item['@ID']);
    if (!oldItem) {
        console.log('attempt to remove an invalid entry ' + item['@ID'] + ' ' + item['@Name']);
    }

    return oldItem;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IAutoModuleUIState, KnownActions> = (state: IAutoModuleUIState | undefined, action: KnownActions) => {
    if (state != undefined) {

        switch (action.type) {
            case 'SELECT_AUTOMODULE_PROCESS':
                if(action.data.masterCrud) {
                    let data = action.data.masterCrud.AutoModuleMaintenanceInfo.ModuleList.Module.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    let item = state.selectedItem;
                    let module = state.selectedAutoModule;
                    item["@ModuleID"] = action.data.uiData.id;
                    item["@FilterID"] = '';
                    item["@Destination"] = '';
                    let newFormState = getProcessFormState(item, action.data.masterCrud.AutoModuleMaintenanceInfo, action.data.uiData.id, state.reportData, true);
                    return {
                        ...state, 
                        ...newFormState,
                        selectedAutoModule: module,
                        selectedItem: item,
                        reportData: state.reportData,
                        changed: state.changed
                    }
                }
                else {
                    return {
                        ...state,
                        selectedItem: JSON.parse(JSON.stringify(emptyItem))
                    }
                }
                break;
            case 'SELECT_AUTOMODULE':
                if (action.data.masterCrud) {
                    let data = action.data.masterCrud.AutoModuleMaintenanceInfo.AutoModules.AutoModule.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    let moduleId = '';
                    if(data)
                        moduleId = data["@ModuleID"] ? data["@ModuleID"] : '';
                    let newItem = data !== undefined ? JSON.parse(JSON.stringify(data)) : JSON.parse(JSON.stringify(emptyItem));
                    let newFormState = getProcessFormState(newItem, action.data.masterCrud.AutoModuleMaintenanceInfo, moduleId, state.reportData, false);
                    return {
                        ...state,
                        ...newFormState,
                        reportData: state.reportData,
                        selectedAutoModule: action.data.uiData,
                        selectedItem: newItem,
                        changed: state.changed
                    }
                }
                break;
            case 'SET_AUTOMODULE_REPORT_DATA':
                if(action.data.uiData){
                    return{
                        ...state,
                        reportData : action.data.uiData.reportData
                    }
                }
                break;
            case 'UPDATE_AUTOMODULE_NAME':
                if (state.selectedItem['@Name'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            "@Name" : action.data.uiData.value
                        }
                    }
                }
                break;

            case 'UPDATE_AUTOMODULE_DESC':
                if (state.selectedItem['@Text'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            "@Text" : action.data.uiData.value
                        }
                    }
                }
                break;               
            case 'SELECT_AUTOMODULE_FILTER':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            '@FilterID': action.data.uiData.id
                        }
                    }
                }
              break;
            case 'SELECT_AUTOMODULE_WORKFLOW':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedWorkflowCode: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_EXPORTCHANGES':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedExportChangesFormat: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_SPINOFF':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedSpinOffFormType: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_REPORTFORMAT':
                if(action.data.uiData) {
                   if (action.data.masterCrud){
                       let module = action.data.masterCrud.AutoModuleMaintenanceInfo.ModuleList.Module.find(ob => ob["@ID"] == state.selectedItem["@ModuleID"]);
                       let groupingDisabled: boolean = true;
                       let groupings: any[] = [{value: '', label: "- Select -"}];   
                       let reportFound: boolean = false;
                       let i : number = 0;
                       let reportArr: any = Object.values(state.reportData);
                       let currentReportId: string = "";
                       let csvOverrideDisabled : boolean = true;
                       
                       if(action.data.uiData.id.indexOf("Select") == -1) {
                           if (module && (module["@ModuleType"] == AUTOMODULE_PROPS.MODULETYPE_REPORT)) {
                               if (module["@ModuleDataFormat"]) {
                                   let startIdx = module["@ModuleDataFormat"].indexOf("-i") + 3;
                                   let endIdx = startIdx + 4;
                                   currentReportId = module["@ModuleDataFormat"].substring(startIdx, endIdx).trim();

                                   while (!reportFound && (!reportFound && i <= reportArr.length - 1)) {
                                       let reportItem = reportArr[i].find((x: { ID: string; }) => x.ID == currentReportId);
                                       if (reportItem) {
                                           reportFound = true;
                                           let groupArr = reportItem.ReportInfo.Formats.find((ob: { Type: string; }) => ob.Type == action.data.uiData.id).Groupings;
                                           if (groupArr && groupArr.length > 0) {
                                               groupingDisabled = false;
                                               groupArr.forEach((gr: { Name: any; }) => {
                                                   groupings.push({value: gr.Name, label: gr.Name})
                                               });
                                           }
                                       }
                                       i++;
                                   }

                               }
                           }
                           
                       }
                       return {
                           ...state,
                           selectedReportFormat: action.data.uiData.id,
                           reportGroupingDisabled: groupingDisabled,
                           csvOptionsQualifierDisabled: isCsvOverrideDisabled(action.data.uiData.id, currentReportId),
                           csvOptionsDelimiterDisabled: isCsvOverrideDisabled(action.data.uiData.id, currentReportId),
                           groupingList: groupings,
                           
                       }
                   }
                }                
                break;
            case 'SELECT_AUTOMODULE_REPORTGROUPING':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedReportGrouping: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_DELIMITER':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedDelimiter: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_QUALIFIER':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedQualifier: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_PRINTFORM':
                if(action.data.masterCrud) {
                    if (action.data.uiData.id) {
                        //get the default paper command set config for I and P
                        let printTypes = action.data.masterCrud?.AutoModuleMaintenanceInfo.PrintOptionsFormTypeList.PrintOptionsFormType
                        let printType = printTypes?.find(ob => ob["@Value"] == action.data.uiData.id)

                        let paperCommands = action.data.masterCrud?.AutoModuleMaintenanceInfo.PaperCommandSetList.PaperCommandSet;

                        let iCmds = paperCommands?.filter(pc => pc["@ClaimTypeId"] === (printType ? printType["@IClaimType"] : ''));
                        let pCmds = paperCommands?.filter(pc => pc["@ClaimTypeId"] === (printType ? printType["@PClaimType"] : ''));

                        // legacy automodule does not select "IsDefault" but it looks like they tried to
                        // let iDefault = iCmds?.find(ob => ob["@IsDefault"] == '1')
                        // let pDefault = pCmds?.find(ob => ob["@IsDefault"] == '1');
                        //
                        //let iDefaultId = iDefault ? iDefault["@ConfigurationId"] : '';
                        //let pDefaultId = pDefault ? pDefault["@ConfigurationId"] : '';

                        return {
                            ...state,
                            selectedPrintFormType: action.data.uiData.id,
                            instPaperCommandSetConfigId: '',
                            profPaperCommandSetConfigId: ''
                        }
                    } else {
                        return {
                            ...state,
                            selectedPrintFormType: undefined,
                            instPaperCommandSetConfigId: undefined,
                            profPaperCommandSetConfigId: undefined
                        }
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_PRINTTYPE':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedPrintType: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_FONTFACE':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedFontFace: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_FONTSIZE':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedFontSize: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_INAMESET':
                if(action.data.uiData) {
                    return {
                        ...state,
                        instPaperCommandSetConfigId: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_PNAMESET':
                if(action.data.uiData) {
                    return {
                        ...state,
                        profPaperCommandSetConfigId: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_PRINTACTION':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedReportAction: action.data.uiData.id
                    }
                }
                break;
            case 'SELECT_AUTOMODULE_PRINTER':
                if(action.data.uiData) {
                    return {
                        ...state,
                        selectedPrinter: action.data.uiData.id
                    }
                }
                break;
            case 'UPDATE_AUTOMODULE_DEST':
                //console.log('setting selectedItem[name] to ' + action.data.uiData.value + ' from ' + state.selectedItem['@Name']);
                if (state.selectedItem['@Name'] !== action.data.uiData.value) {
                    return {
                        ...state,
                        selectedItem: {
                            ...state.selectedItem,
                            "@Destination" : action.data.uiData.value
                        }
                    }
                }
                break;                
            case 'ERROR_AUTOMODULE_REMOVE_CONFIRM':
                return {
                    ...state,
                    autoModuleRemoveConfirm: action.data.uiData.value,
                }
                break;
            case 'ERROR_AUTOMODULE_ALERT':
                return {
                    ...state,
                    autoModuleAlert: action.data.uiData.value,
                }
                break;                

            case 'ERROR_AUTOMODULE_FIELD':
                // var errorMsg = "";
                return{
                    ...state
                }

            case 'ADD_AUTOMODULE':
            case 'UPDATE_AUTOMODULE':
                if (action.data.masterCrud) {
                    action.data.masterCrud.AutoModuleMaintenanceInfo.AutoModules.AutoModule =
                        upsertAutoModule(action.data.masterCrud.AutoModuleMaintenanceInfo.AutoModules.AutoModule, action.data.uiData);
                    let newItem = JSON.parse(JSON.stringify(emptyItem));
                    let newFormState = getProcessFormState(newItem, action.data.masterCrud.AutoModuleMaintenanceInfo, '', state.reportData, false);
                    return {
                        ...state,
                        ...newFormState,
                        reportData: state.reportData,
                        selectedItem: newItem,
                        changed: true,
                        autoModuleNameError: undefined,
                    }
                }
                break;

            case 'REMOVE_AUTOMODULE':
                if (action.data.masterCrud) {
                    action.data.masterCrud.AutoModuleMaintenanceInfo.AutoModules.AutoModule =
                        removeAutoModule( action.data.masterCrud.AutoModuleMaintenanceInfo.AutoModules.AutoModule, action.data.uiData);

                    let newItem = JSON.parse(JSON.stringify(emptyItem));
                    let newFormState = getProcessFormState(newItem, action.data.masterCrud.AutoModuleMaintenanceInfo, '', state.reportData, false);
                    return {
                        ...state,
                        ...newFormState,
                        reportData: state.reportData,
                        selectedItem: newItem,
                        changed: true
                    }
                }
                break;

            // case 'NCS_ALERT':
            //     return {
            //         ...state,
            //         submitNCSAlert: action.data.uiData.value,
            //     }
            //     break;

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
