import * as React from 'react';
import { Input, SelectDropdownV2, Button, Help } from 'ui-core';
import { IImageButtonImage, ImageButton } from '../../common/ImageButton';
import { handleChange } from '@commonResources/userModified';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper, DialogButton } from '@common/DialogWrapper';
import styled from 'styled-components';
import { PhysicianInfo, PmDropdownOption, DEFAULT_PHYSICIAN_INFO } from '@store/ui/PhysicianMaintenanceUI';
import { Helpwrapper, RowWrapper, SelectButtons, ToolTip } from './PhysicianStyles';
import { dynamicFieldIsFilled, fieldIsNotValue, fieldIsFilled, fieldIsAlphaNumeric, fieldIsAlpha, fieldIsNumeric, Validation, ControlValidation } from '@commonResources/validations';
import * as wnd from '@commonResources/window';

//-----------------
// PAGE NOTES
// This component collects all the physician 
//-----------------
//STYLES - Put custom style elements up here.
// e.g.
//const Spacer = styled.div`
//    height: 0.25rem;
//
const StyleName = styled.div`
    
`;

// ReSharper disable IdentifierTypo

// ReSharper restore IdentifierTypo


enum eModifyButtonType {
    Add,
    Update
}

//-----------------
// STATE AND PROPS - Define the local component state and any component props here.
interface IComponentState {
    actualPhysician: PhysicianInfo,
    currentErrors: InputError[],
}

interface IComponentProps {
    currentPhysician: PhysicianInfo,
    changeValue(field: string, newValue: string): void,
    canUpdate: boolean,
    canAdd: boolean,
    canRemove: boolean,
    canCopy: boolean,
    helpIcon: IImageButtonImage,
    addButtonOnlyFlag: boolean,
    idTypeList: Array<PmDropdownOption>,
    stateList: Array<PmDropdownOption>,
    onClickAddButton: Function,
    onClickUpdateButton: Function,
    onClickRemoveButton: Function,
}

type PhysicianInfoProperty = "firstName" | "middleInitial" | "lastName" | "address1" | "address2" | "city" | "state" | "zip" | "telephone" | "npi" |
    "taxonomy" | "taxId" | "ssn" | "idType";

//-----------------
// Custom Validations for the physician input pane.
const zipLengthValidation: Validation = {
    check: (value: string) => {
        const length = value?.length ?? 0;
        return length == 0 || length == 5 || length == 9;
    },
    failureMessage: 'Must consist of either 5 numbers or 9 numbers.'
}

const zipNumericValidation: Validation = {
    check: fieldIsNumeric.check,
    failureMessage: 'Zip Code must consist of only numbers.'
}

const telephoneLengthValidation: Validation = {
    check: (value: string) => {
        const length = value?.length ?? 0;
        return length == 0 || length == 10;
    },
    failureMessage: 'Telephone number must consist of ten numbers.'
}

// Have to do a custom one since we can't use a display name on length validation.
const telephoneNumericValidation: Validation = {
    check: fieldIsNumeric.check,
    failureMessage: 'Telephone must consist of only numbers.'
}

interface InputError { id: PhysicianInfoProperty; failureMessage: string }
const DEFAULT_INPUT_ERRORS: InputError[] = [];


//-----------------
// COMPONENT CLASS - Define the component class here.
export class PhysicianDataInputs extends React.Component<IComponentProps, IComponentState> {

    private validationMap: ControlValidation<PhysicianInfoProperty>[] = [
        { id: "firstName", displayName: 'First Name', validations: [fieldIsFilled, fieldIsAlphaNumeric] },
        { id: "middleInitial", displayName: 'Middle Initial', validations: [fieldIsAlpha] },
        { id: "lastName", displayName: 'Last Name', validations: [fieldIsFilled, fieldIsAlphaNumeric] },
        { id: "city", displayName: 'City', validations: [fieldIsAlphaNumeric] },
        { id: "zip", displayName: '', validations: [zipNumericValidation, zipLengthValidation] },
        { id: "telephone", displayName: '', validations: [telephoneNumericValidation, telephoneLengthValidation] },
        { id: "npi", displayName: 'NPI', validations: [fieldIsAlphaNumeric] },
        { id: "taxonomy", displayName: 'Taxonomy', validations: [fieldIsAlphaNumeric] },
        { id: "taxId", displayName: 'Tax ID', validations: [fieldIsFilled] },
        { id: "ssn", displayName: 'SSN', validations: [fieldIsNumeric] },
        { id: "idType", displayName: '', validations: [fieldIsNotValue('- Select ID Type -', 'You must enter an ID Type.'), dynamicFieldIsFilled('You must enter an ID Type.')] },
    ];

    /**
     *  Validation function for the physician information pane. Utilizes the validation pattern
     *  set up in the Payers pane.
     */
    private validatePhysician() {
        const errors = this.validationMap.reduce((errList, validationItem) => {

            // Need to grab the label if we're checking the dropdown - otherwise we go kaboom.
            // Note that even though we're checking for state, it will never in validation, so we can default to id type.
            // TODO - max a subset of physician control ids that are required.
            const value: string = validationItem.id !== 'idType' && validationItem.id !== 'state'
                ? this.state.actualPhysician[validationItem.id]
                : this.state.actualPhysician[validationItem.id]?.label ?? '-Select ID Type -';

            const errorValidator = validationItem.validations.find(validator => !validator.check(value));
            if (errorValidator) {
                let { failureMessage } = errorValidator;
                if (validationItem.displayName) { failureMessage = validationItem.displayName + ' ' + failureMessage };
                errList.push({ id: validationItem.id, failureMessage });
            }
            return errList;
        }, [] as InputError[]);
        return errors;
    }

    state = {
        actualPhysician: { ...this.props.currentPhysician, state: this.props.currentPhysician.state, idType: this.props.currentPhysician.idType },
        currentErrors: DEFAULT_INPUT_ERRORS
    };

    constructor(props: IComponentProps) {
        super(props);
        this.validatePhysician = this.validatePhysician.bind(this);
        this.openTaxonomyHelp = this.openTaxonomyHelp.bind(this);
        this.updatePhysicianInput = this.updatePhysicianInput.bind(this);
    }

    //Help Window Methods
    private openTaxonomyHelp() {
        wnd.BigHelp('/Support/Help/HELP_TaxonomyCodes.htm');
    }

    /**
     * Updates physician data input fields and ensures they are trimmed and forced to upper case.
     * @param id The actualPhysician property to update
     * @param val The raw incoming string value.
     */
    private updatePhysicianInput(id: PhysicianInfoProperty, val: string): void {
        const currentVal = this.state.actualPhysician[id];
        // Guard clause to make sure we're not trying to update a value that is unchanged.
        if (currentVal === val)
            return;

        // Format the new value to what we need it to be
        const newValue = val.trim().toUpperCase();

        // Check if we need to do a double render in order to get the leading/trailing spaces trimmed.
        if (newValue !== currentVal)
            this.setState({
                actualPhysician: {
                    ...this.state.actualPhysician,
                    [id]: newValue,
                }
            });

        // This looks wonky, but we need to force a component re-render, so we're going to set the state to what
        // the unformatted value was, then set it to the formatted value. We know we don't have a value that is just
        // the same since our initial guard clause would have returned it already.
        else
            this.setState({
                actualPhysician: {
                    ...this.state.actualPhysician,
                    [id]: val,
                }
            }, () => this.setState({
                actualPhysician: {
                    ...this.state.actualPhysician,
                    [id]: newValue,
                }
            }));
    }

    //TODO - Componetize the input fields and their styles into a single React component
    //Use the existing pieces in the master PM component as a starting point.
    public render() {

        // Need to check for errors on our inputs and make it simpler to reference them.
        const errors = {
            firstNameError: this.state.currentErrors.find(error => error.id === 'firstName')?.failureMessage,
            middleInitialError: this.state.currentErrors.find(error => error.id === 'middleInitial')?.failureMessage,
            lastNameError: this.state.currentErrors.find(error => error.id === 'lastName')?.failureMessage,
            cityError: this.state.currentErrors.find(error => error.id === 'city')?.failureMessage,
            zipError: this.state.currentErrors.find(error => error.id === 'zip')?.failureMessage,
            telephoneError: this.state.currentErrors.find(error => error.id === 'telephone')?.failureMessage,
            npiError: this.state.currentErrors.find(error => error.id === 'npi')?.failureMessage,
            taxonomyError: this.state.currentErrors.find(error => error.id === 'taxonomy')?.failureMessage,
            taxIdError: this.state.currentErrors.find(error => error.id === 'taxId')?.failureMessage,
            ssnError: this.state.currentErrors.find(error => error.id === 'ssn')?.failureMessage,
            idTypeError: this.state.currentErrors.find(error => error.id === 'idType')?.failureMessage,
        };

        return (
            <React.Fragment>
                <ContentRowWrapper>
                    <ContentColumnWrapper>
                        <ContentRowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="first-name"
                                    className="text-input"
                                    label="*First Name:"
                                    maxLength={12}
                                    initialValue={this.state.actualPhysician.firstName}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('firstName', e.target.value)
                                            }
                                    hasError={Boolean(errors.firstNameError)}
                                    errorMessage={errors.firstNameError ?? ''}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="middle-initial"
                                    className="text-input"
                                    label="MI:"
                                    maxLength={1}
                                    initialValue={this.state.actualPhysician.middleInitial}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.setState({
                                        actualPhysician: {
                                            ...this.state.actualPhysician,
                                            middleInitial: e.target.value.trim().toUpperCase(),
                                        }
                                    })}
                                    hasError={Boolean(errors.middleInitialError)}
                                    errorMessage={errors.middleInitialError ?? ''}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="last-name"
                                    className="text-input"
                                    label="*Last Name:"
                                    maxLength={21}
                                    initialValue={this.state.actualPhysician.lastName}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('lastName', e.target.value)}
                                    hasError={Boolean(errors.lastNameError)}
                                    errorMessage={errors.lastNameError ?? ''}
                                />
                            </RowWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="address-type"
                                    className="text-input"
                                    label="Address:"
                                    maxLength={55}
                                    initialValue={this.state.actualPhysician.address1}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('address1', e.target.value)}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="address-type2"
                                    className="text-input"
                                    label="Address2:"
                                    maxLength={55}
                                    initialValue={this.state.actualPhysician.address2}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('address2', e.target.value)}
                                />
                            </RowWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="city-type"
                                    className="text-input"
                                    label="City:"
                                    maxLength={30}
                                    initialValue={this.state.actualPhysician.city}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('city', e.target.value)}
                                    hasError={Boolean(errors.cityError)}
                                    errorMessage={errors.cityError ?? ''}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <SelectDropdownV2
                                    autoComplete="off"
                                    domID="state-type"
                                    className="dropdown"
                                    label="State:"
                                    size='small'
                                    isClearable={false}
                                    isSearchable={true}
                                    onChange={(selectedOption: ISelectOption) => this.setState({
                                        actualPhysician: {
                                            ...this.state.actualPhysician,
                                            state: this.props.stateList.find((opt: PmDropdownOption) => opt.value === selectedOption.value) ?? this.state.actualPhysician.state
                                        }
                                    })} 
                                    options={this.props.stateList}
                                    initialValue={this.state.actualPhysician.state}
                                    disabled={false}                                    
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="zip-type"
                                    className="text-input"
                                    label="Zip:"
                                    maxLength={10}
                                    initialValue={this.state.actualPhysician.zip}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('zip', e.target.value)}
                                    hasError={Boolean(errors.zipError)}
                                    errorMessage={errors.zipError ?? ''}
                                />
                            </RowWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="telephone"
                                    label="Telephone:"
                                    className="text-input"
                                    maxLength={10}
                                    initialValue={this.state.actualPhysician.telephone}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('telephone', e.target.value)}
                                    hasError={Boolean(errors.telephoneError)}
                                    errorMessage={errors.telephoneError ?? ''}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="npi-value"
                                    label="NPI:"
                                    className="text-input"
                                    maxLength={10}
                                    initialValue={this.state.actualPhysician.npi}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('npi', e.target.value)}
                                    hasError={Boolean(errors.npiError)}
                                    errorMessage={errors.npiError ?? ''}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="taxonomy-value"
                                    label="Taxonomy:"
                                    className="text-input"
                                    maxLength={10}
                                    initialValue={this.state.actualPhysician.taxonomy}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('taxonomy', e.target.value)}
                                    hasError={Boolean(errors.taxonomyError)}
                                    errorMessage={errors.taxonomyError ?? ''}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Helpwrapper>
                                    <Button
                                        domID="btnCategory"
                                        Title={'HelpIcon'}
                                        image={this.props.helpIcon}
                                        onClick={this.openTaxonomyHelp}
                                    ><Help
                                            className="test"
                                            fillColor="#37474F"
                                            title="title"
                                        />
                                    </Button>
                                </Helpwrapper>
                            </RowWrapper>
                        </ContentRowWrapper>
                        <ContentRowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="taxid-value"
                                    className="text-input"
                                    label="*Tax ID:"
                                    maxLength={10}
                                    initialValue={this.state.actualPhysician.taxId}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('taxId', e.target.value)}
                                    hasError={Boolean(errors.taxIdError)}
                                    errorMessage={errors.taxIdError ?? ''}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <Input
                                    autoComplete="off"
                                    domID="ssn-value"
                                    className="text-input"
                                    label="SSN:"
                                    maxLength={10}
                                    initialValue={this.state.actualPhysician.ssn}
                                    onBlur={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                        this.updatePhysicianInput('ssn', e.target.value)}
                                    hasError={Boolean(errors.ssnError)}
                                    errorMessage={errors.ssnError ?? ''}
                                />
                            </RowWrapper>
                            <RowWrapper>
                                <SelectDropdownV2
                                    autoComplete="off"
                                    domID="id-type"
                                    className="dropdown"
                                    label="*Id Type:"
                                    size='small'
                                    isClearable={false}
                                    isSearchable={true}
                                    onChange={(selectedOption: ISelectOption) => this.setState({
                                        actualPhysician: {
                                            ...this.state.actualPhysician,
                                            idType: this.props.idTypeList.find((opt: PmDropdownOption) => opt.value === selectedOption.value) ?? this.state.actualPhysician.idType,
                                        }
                                    })} 
                                    options={this.props.idTypeList}
                                    initialValue={this.state.actualPhysician.idType}
                                    disabled={false}
                                    hasError={Boolean(errors.idTypeError)}
                                    errorMessage={errors.idTypeError ?? ''}
                                />
                            </RowWrapper>
                        </ContentRowWrapper>
                    </ContentColumnWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper>
                    <SelectButtons>
                        {!this.props.currentPhysician.internalId ? ( //See if we need update or add button.
                            <ToolTip>
                                <Button
                                    domID="physician-add-button"
                                    name="Add"
                                    buttonType="standard"
                                    size="small"
                                    type="button"
                                    disabled={!this.props.canAdd}
                                    //onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickModifyButton(e)}
                                    onClick={() => {
                                        //this.setState({ isUpdate: true })
                                        handleChange();
                                        const validationErrors = this.validatePhysician() ?? [];
                                        if (validationErrors.length === 0)
                                            this.setState(
                                                { currentErrors: [] },
                                                () => this.props.onClickAddButton(this.state.actualPhysician));
                                        else
                                            this.setState({ currentErrors: validationErrors });
                                    }}
                                />
                                <span className="text">Add a New Physician</span>
                            </ToolTip>
                        ) : (
                                <ToolTip>
                                    <Button
                                        domID="physician-update-button"
                                        name="Update"
                                        buttonType="standard"
                                        size="small"
                                        type="button"
                                    disabled={!this.props.canUpdate}
                                        //onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClickModifyButton(e)}
                                        onClick={() => {
                                            //this.setState({ isUpdate: true })
                                            handleChange();
                                            const validationErrors = this.validatePhysician() ?? [];
                                            if (validationErrors.length === 0)
                                            this.setState(
                                                { currentErrors: [] },
                                                this.props.onClickUpdateButton(this.state.actualPhysician));
                                            else
                                                this.setState({ currentErrors: validationErrors });
                                        }}
                                    />
                                    <span className="text">Update a Physician</span>
                                </ToolTip>
                            )}
                        <ToolTip>
                            <Button
                                domID="physician-remove-button"
                                name="Remove"
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canRemove || this.props.addButtonOnlyFlag}
                                onClick={() => {
                                        handleChange();
                                        this.props.onClickRemoveButton(this.state.actualPhysician);
                                    }
                                }
                            />
                            <span className="text">Remove Selected Physician</span>
                        </ToolTip>
                        <ToolTip>
                            <Button
                                domID="physician-copy-button"
                                name="Copy"
                                buttonType="standard"
                                size="small"
                                type="button"
                                disabled={!this.props.canCopy || this.props.addButtonOnlyFlag}
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => {
                                    handleChange();
                                    const validationErrors = this.validatePhysician() ?? [];
                                    if (validationErrors.length === 0)
                                        this.setState(
                                            { currentErrors: [] },
                                            this.props.onClickAddButton(this.state.actualPhysician));
                                    else
                                        this.setState({ currentErrors: validationErrors });
                                }}
                            />
                            <span className="text">Copy the Physician Information</span>
                        </ToolTip>
                    </SelectButtons>
                </ContentRowWrapper>

            </React.Fragment>
        );
    }
};