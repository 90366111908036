import * as React from 'react';
import styled, { css } from 'styled-components';

export const displayFlex = css`
    display: flex;
`;

export const flexRowWrap = css`
    flex-flow: row wrap;
`;

export const flexRowNoWrap = css`
    flex-flow: row nowrap;
`;

export const flexFill = css`
    flex: 1 1 auto;
`;

export const flexRigid = css`
    flex: 0 0 auto;
`;

export const flexContainerWrap = css`
    ${displayFlex};
    ${flexRowWrap};
`;

export const flexContainerNoWrap = css`
    ${displayFlex};
    ${flexRowNoWrap};
`;

export const ARMExceptionStyling = css`
    font-family: "Core Sans C","Century Gothic","AppleGothic",sans-serif;
`;

export const ARMExceptionInput = css`
    input {
        height: 25px;
        font-size: 12px;
        padding-left: 7px;
        padding-right: 7px;

        
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            > * {
                padding-top: 1px !important;
            }
        }
        text-transform: uppercase;
    }
`;
