/**
 * Date:    03-Apr-19
 * Author:  Luis-Carlos Rodriguez
 * Module:  AssuranceHeader.txs
 * Purpose: this module is a copy of the legacy AssuranceHeader.jxs
 *          with a translaltion to TS
 *          Created:
 *          1. created the interfaces for the file
 *          
 * Testing: this module is included with common.js for testing with the new components being
 *          integrated into the ARM.UI clientApp solution
 *          1. conversion in progress
 *
 */
import * as React from 'react'
// import { Navbar, Container } from 'react-bootstrap'
import * as ReactBootstrap from 'react-bootstrap'

import { LandingPageComponent, IComponentProps } from './LandingPageComponent';

import AssurancePageTitle from './AssurancePageTitle'
//import AssuranceMenu from './assurance.Menu'
import { URLs } from '@commonDevResources/constants';

interface IProps {
    Name?: string

}

interface IState {
    data: null
}

//const evalDivStyle = () => { return { fontSize: 24 }; }  //LCR-002

export default class AssuranceHeader extends React.Component<IProps, IState>
{
    constructor(props: IProps) {
        super(props);
        this.state =
            {
                data: null
            }
    }
    componentDidMount() {
        // TODO: Load data.
        this.setState(
            {
                data: null
            });
    }


    getClassName() {
        return this.props.Name + " flex-componentcontainer";
    }

    render() {
//        return <div id="AssuranceHeader" className="assuranceHeader" >
        return (
            <LandingPageComponent ComponentClass={this.getClassName()} {...this.props}>
              <AssurancePageTitle Name="Header" MenuUrl="{URLs.api + '/api/data/GetHeaderMenu'}" />
            </LandingPageComponent >
        )
    }
};


