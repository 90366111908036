import * as React from 'react';
import styled from 'styled-components';
import errorIcon from '../../content/images/errorIcon.gif';
import { LandingPageComponent } from './LandingPageComponent';

interface IComponentProps {
    Name: string;
    Order: number;
};

export type IAssurancePlaceholderProps = IComponentProps;// & RouteComponentProps<{}>;

const AssurancePlaceholderContainer = styled.div`
  id: "${(props: IComponentProps) => props.Name}";
  order: ${(props: IComponentProps) => props.Order};
  border = "1px solid #e1e2e6";
  fontFamily = "Tahoma"; 
  fontSize = "13px"; 
  color = "#d84040";
  backgroundColor = "#FDF6E3";
  className = "${(props: IComponentProps) => props.Name + " flex-item " + props.Name + props.Order}";
`;

export default class AssurancePlaceholder extends React.Component<IAssurancePlaceholderProps, {}>
{
    constructor(props: IAssurancePlaceholderProps)
    {
        super(props);
    }

    render() {
        return (<LandingPageComponent {...this.props}><AssurancePlaceholderContainer {...this.props}><img src={errorIcon} />Unable to load element</AssurancePlaceholderContainer></LandingPageComponent>)
    }
};