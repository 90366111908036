import { Reducer } from 'redux';
import { createDataAction, ActionTypes, createAction } from '@scripts/util/ActionHelpers';
import { IApiActionData } from "@scripts/util/ApiDataHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IManualEligibilityCheckUIState {

}

export interface IManualEligibilityCheckUIData {

};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
//export interface IAN_LoadManualEligibilityCheck extends IApiActionData<APICF_ListPopulationContainer, IClaimFilterUIFieldData> { }


export const defaultState: IManualEligibilityCheckUIState = {

};

//----------------------------------------------------------
export const actionCreators = {
    //selectNote: (selectInfo: ISelectNote) => createDataAction('AN_SELECT_NOTE', selectInfo), 
    //updateNoteDescription: (noteDescription: ISelectNote) => createDataAction('AN_UPDATE_DESCRIPTION', noteDescription),
    setBusy: () => createAction('SETBUSY'),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

//----------------------------------------------------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IManualEligibilityCheckUIState, KnownActions> = (state: IManualEligibilityCheckUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SETBUSY':
                return {
                    ...state,
                    isBusy: false, 
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}

