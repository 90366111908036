import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";

interface IComponentProps {
  domID: string;
  width: number;
  height: number;
  records: any[];
  optionFields?: { id: string; value: string; text: string };
  setSelectedRecords?: Function;
  multiSelect?: boolean | undefined;
  compRef?: any;
  searchKey?: string;
  disabled?: boolean;
  preselectRecords?: any[];
  setSelections?: Function;
  value?: any[];
}

let searchID = 0;
let selectionsOutOfView: any[] = [];

const SelectComponent: React.FC<IComponentProps> = ({
  domID,
  width,
  height,
  records,
  preselectRecords,
  optionFields,
  setSelections,
  setSelectedRecords,
  multiSelect,
  compRef,
  searchKey,
  disabled,
  value,
}) => {
  // Determine whether list is single or multi-select
  const selectabilityProps = {
    ...(!!multiSelect ? { multiple: true } : { size: 10 }),
  };
  const [filteredRecords, setFilteredRecords] = useState<any[]>([]);
  const [optionRecords, setOptionRecords] = useState<any[]>([]);

  const selectRef = useRef<HTMLSelectElement>(null);

  const removeRecord = (record: HTMLSpanElement) => {
    let options = records;
    if (options && optionFields) {
      options = options.map((r: any) => {
        return {
          ...r,
          id: r[optionFields.id],
          value: r[optionFields.value],
          text: r[optionFields.text],
        };
      });
    }

    let elementToRemove: string = record.id;

    let selections: any = _.map(
      selectRef.current?.selectedOptions,
      (r: any) => {
        return r.attributes.value.value;
      }
    );

    let newArr = selections.filter((ele: any) => ele != elementToRemove);
    //console.log("I am setting the records with: ", newArr);
    //setSelections(newArr);
    setSelectedRecords &&
      setSelectedRecords(
        newArr.map((recId: any) => {
          const rec = options.find((record: any) => {
            return record.id == recId; // a number is being compared to a string here. so strict equality won't work
          });
          return rec;
        })
      );
  };

  // Runs only on Component Mount
  useEffect(() => {
    let options: any = records;

    if (optionFields) {
      options = options.map((r: any) => {
        return {
          ...r,
          id: r[optionFields.id],
          value: r[optionFields.value],
          text: r[optionFields.text],
        };
      });
    }

    compRef.current.removeRecord = removeRecord;
    //compRef.current.setSelections = setSelections;
    compRef.current.value = value;
    setOptionRecords(options);
  }, []);
  // Run when Filtering
  useEffect(() => {
    let searchedRecords: any[] = optionRecords;
    if (!((searchKey && searchKey.trim() === "") || !searchKey)) {
      searchedRecords = optionRecords.reduce((currArry: any, currentValue) => {
        let result = currArry;
        if (
          JSON.stringify(currentValue.text.toLowerCase()).includes(
            searchKey.toLowerCase()
          )
        ) {
          result = [...result, currentValue];
        }
        return result;
      }, []);
    }
    setFilteredRecords(searchedRecords);
  }, [optionRecords, searchKey]);

  const handleChange = (e: any) => {
    let tempSelections: any = [];
    let selectedRecords: any = [];

    let selectedOptions = e.target.selectedOptions;

    if (multiSelect) {
      tempSelections = _.map(selectedOptions, (option: any) => {
        const selectedRecord = filteredRecords.find((record: any) => {
          return record.id == option.attributes.value.value; // a number is being compared to a string here. so strict equality won't work
        });

        selectedRecords = [...selectedRecords, selectedRecord];
        return option.attributes.value.value;
      });
    } else {
      selectedRecords = [
        filteredRecords.find((record: any) => {
          return record.id == e.target.value; // a number is being compared to a string here. so strict equality won't work
        }),
      ];
      tempSelections = [e.target.value];
    }

    setSelections && setSelections(tempSelections);

    setSelectedRecords &&
      setSelectedRecords(
        optionRecords.filter((opRec) => {
          return !![...selectionsOutOfView, ...tempSelections].find((sel) => {
            return sel == opRec.id;
          });
        })
      );
  }; // END handleChange ----//

  return (
    <select
      key={domID + "-ListComponentSelect-" + searchID}
      id={domID + "-ListComponentSelect"}
      name={domID + "-ListComponentSelect"}
      {...selectabilityProps}
      onChange={(e) => handleChange(e)}
      style={{ width: `${width}px`, height: `${height}px` }}
      // use the store to get the selections
      value={value}
      ref={selectRef}
      disabled={disabled}
    >
      {filteredRecords &&
        filteredRecords?.map((option: any) => {
          return (
            <option key={option.id} id={option.id} value={option.id}>
              {option.text}
            </option>
          );
        })}
    </select>
  );
};

export default function useSelect(props: IComponentProps) {
  //const [selectedRecords, setSelectedRecords] = useState<any | []>([]);
  const compRef = useRef({});

  return [
    <SelectComponent
      {...{
        ...props,
        domID: props.domID ?? "",
        optionFields: props.optionFields ?? undefined,
        multiSelect: props.multiSelect ?? false,
        searchKey: props.searchKey ?? "",
        disabled: props.disabled ?? false,
        preselectRecords: props.preselectRecords ?? [],
      }}
      //setSelectedRecords={setSelectedRecords}
      compRef={compRef}
    />,
    compRef,
  ];
}
