import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as GroupMasterUI from './ui/GroupMasterUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type IGroupMasterState = ICrudComponentState<MCGroupMasterType, GroupMasterUI.IGroupMasterUIState>;

export const actionCreators = createCrudComponentActions<GroupMasterUI.ActionCreators>(GroupMasterUI.actionCreators);
export type IGroupMasterActions = typeof actionCreators;
export type IGroupMasterActionProps = ICrudComponentActionProps<IGroupMasterActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiGroups, GroupMasterUI.reducer);
export const reducer = combineReducers<IGroupMasterState>(reducers);

export const validationCallback: ValidationCallback<MCGroupMasterType> = (crud: any): crud is MCGroupMasterType => {
    ensureEntityInitialization(crud);
    return validateEntityInitialization(crud);
}

function ensureEntityInitialization(crud: MCGroupMasterType) {
    ensureEntityGroupsArray(crud);
    ensureEntityUserListArray(crud);
    ensureEntityFunctionListArray(crud);

    // each groupMaster object - users and funcs check structure
    crud.GroupMaintenanceInfo.Groups.Group.forEach(group => {
        // users: not an element
        if(group.Users as any === "") {
            group.Users = {User: new Array<MCGroupUser>()};
        }
        // users: not an array
        if(!Array.isArray(group.Users.User)) {
            let arr = new Array<MCGroupUser>();
            arr.push(JSON.parse(JSON.stringify(group.Users.User)));
            group.Users.User = arr;
        }

        // functions: not an element
        if(group.Functions as any === "") {
            group.Functions = {Function: new Array<MCGroupFunction>()};
        }
        // functions: not an array
        if(!Array.isArray(group.Functions.Function)) {
            let arr = new Array<MCGroupFunction>();
            arr.push(JSON.parse(JSON.stringify(group.Functions.Function)));
            group.Functions.Function = arr;
        }

    });
}

function ensureEntityGroupsArray(crud: MCGroupMasterType) {

    // ensure Groups
    let info = crud.GroupMaintenanceInfo;
    let groups = info.Groups;
    if (!groups) {
        info.Groups = { Group: [] };
        return;
    }

    // ensure Groups.Group
    if (!groups.Group) {
        info.Groups.Group = [];
        return;
    }

    // ensure Groups.Group as array
    if (groups.Group.constructor !== Array) {
        let arr = [];
        arr.push(JSON.parse(JSON.stringify(groups.Group)));
        groups.Group = arr;
        return;
    }
}

function ensureEntityUserListArray(crud: MCGroupMasterType) {

    // ensure UserList
    let info = crud.GroupMaintenanceInfo;
    let users = info.UserList;
    if (!users) {
        info.UserList = { User: [] };
        return;
    }

    // ensure UserList.User
    const needsUsers = !users.hasOwnProperty("User");
    if (needsUsers) {
        users.User = [];
        return;
    }

    // ensure UserList.User as array
    if (users.User.constructor !== Array) {
        let arr = [];
        arr.push(JSON.parse(JSON.stringify(users.User)));
        users.User = arr;
        return;
    }
}

function ensureEntityFunctionListArray(crud: MCGroupMasterType) {

    // ensure FunctionList
    let info = crud.GroupMaintenanceInfo;
    let functions = info.FunctionList;
    if (!functions) {
        info.FunctionList = { Function: [] };
        return;
    }

    // ensure FunctionList.Function
    const needsFunctions = !functions.hasOwnProperty("Function");
    if (needsFunctions) {
        functions.Function = [];
        return;
    }

    // ensure FunctionList.Function as array
    if (functions.Function.constructor !== Array) {
        let arr = [];
        arr.push(JSON.parse(JSON.stringify(functions.Function)));
        functions.Function = arr;
        return;
    }
}

function validateEntityInitialization(crud: MCGroupMasterType) {
    if (!ValidateJSONSchema(crud, "MCGroupMasterType")) return false;
    return true;
}
