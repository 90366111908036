import { combineReducers, Reducer } from 'redux';
import * as InternalUserUI from '@store/ui/InternalUserUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '../scripts/util/ValidationHelpers';

export type IInternalUserUIState = ICrudComponentState<MCARMInternalUser, InternalUserUI.IInternalUserUIState>;
export const actionCreators = createCrudComponentActions<InternalUserUI.ActionCreators>(InternalUserUI.actionCreators);
export type IInternalUserActions = typeof actionCreators;
export type IInternalUserActionProps = ICrudComponentActionProps<IInternalUserActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiNdcUserMaster, InternalUserUI.reducer);
export const reducer = combineReducers<IInternalUserUIState>(reducers);

export const validationCallback: ValidationCallback<MCARMInternalUser> = (crud: any): crud is MCARMInternalUser => {
    let testCrud: MCARMInternalUser = crud as MCARMInternalUser;
    let validShape = ValidateJSONSchema(testCrud, "MCUserMaintenanceInfo");
    return validShape;
};