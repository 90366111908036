import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, TextArea, Button, SelectDropdown, SelectDropdownV2, DatePicker } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { ArrowButtons } from '@common/ArrowButtons';
import { ARMDatePicker } from '@common/DateOrTimeRelated/ARMDatePicker';
import { SelectComponent } from '@common/SelectComponent';
import { CheckBoxComponent } from '@common/CheckBox';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { ReadOnlySchedule, ISchedule } from '@common/DateOrTimeRelated/ReadOnlySchedule';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IDirectEntryAccountState, IDirectEntryAccountActions, IDirectEntryAccountActionProps, actionCreators } from '@store/DirectEntryAccount';
import { defaultState as defaultUIState, newAccountValue, newFiValue, IAliasCredentialUIModel, IDirectEntryAccountUIData } from '@store/ui/DirectEntryAccountUI';

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    height: 710px;
    width: 750px;
`;
export const AccountName = styled.div`
    width: 200px;
`;

export const HiddenSpan = styled.span`
    display: none;
`;

export const AccountDisabled = styled.div`
    color: ${Colors.darkGrey}
    ${Typography.small}
    padding-top: 20px;
    padding-left: 10px;
    font-weight: bold;
`;

export const FiSelect = styled.div`
    width: 200px;
`;

export const GenerateDays = styled.div`
    width: 50px;
`;

export const FunctionSection = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 5px;
`;

export const CheckBoxWrapper = styled.div`
    float: left;
    width: 160px;

    div .arm_checkbox_label {
        padding-left: 4px;
    }
`;

export const FunctionHideGutter = styled.div`
    height: 35px;
`;

export const FunctionLabel = styled.label`
    font-weight: 600;
    text-transform: uppercase;
    ${Typography.small};
    color: ${Colors.default};
    letter-spacing: 0.5px;
    margin-bottom: 0.3rem;
`;

export const EmptyHeader = styled.div``;

export const SelectList = styled.div`
    padding: 5px;
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    float: center;
`;
export const AccountButtonActions = styled(SelectActions)`
`;

export const InputContainer = styled.div`
    height:250px;
`;

export const SelectButtons = styled.div`
    display: flex;
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;

    button {
        margin: 4px;
        width: 85px;
        text-align: center;
    }
`;

export const ScheduleHiddenGutter = styled.div`
    height: 30px;
`;

export const ScheduleOverflow = styled.div`
    overflow: auto;
    height: 40px;
    width: 445px;
`;
export const FiOverflow = styled.div`
    overflow: auto;
    height: 100px;
    width: 445px;
`;


export const FiHeaderRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    font-size: 14px;
    font-weight: bold;
    color: ${Colors.white};
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin-bottom: 2px;
    padding-top: 5px;
`;

export const FiAliasHeader = styled.div`
    background-color: ${Colors.middleSecondaryGrey};
    padding-left: 1px;
    width: 100px;
    margin-right: 2px;
`;

export const FiUserPwdHeader = styled.div`
    background-color: ${Colors.middleSecondaryGrey};
    padding-left: 1px;
    width: 150px;
    margin-right: 2px;
`;

export const FiItemRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    font-size: ${Typography.small};
    margin-bottom: 2px;
`;

export const FiAliasItem = styled.div`
    width: 100px;
    margin-right: 2px;
`;

export const FiUserPwdItem = styled.div`
    width: 150px;
    margin-right: 2px;
`;


export const GenericInputRow = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    margin-bottom: 2px;
`;

export const GenericInputRowPaddedBottom = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    padding-bottom: 5px;
`;

export const ElementWrapper = styled.div`
    float: left;
    padding: 0px 4px 0px 4px
    div .arm_checkbox_label {
        padding-left: 4px;
    }

    #genPwdCheckbox_wrapper{
        width: 197px;
        padding-top: 10px;
        padding-left: 4px;
    }
`;

export const GenericLabel = styled.div`
    ${Typography.default}
    ${Typography.bold}
    padding-top: 10px;
    float: left;
`;

export const PasswordErrorGutter = styled.div`
    height: 12px;
`;

export const ErrorMessageForInputWithHeading = styled.div`
    margin-top: 15px;
    margin-left: 10px;
    color: ${Colors.digitalRed130};
    ${Typography.small}
`;

export const ErrorMessageForInput = styled.div`
    margin-top: 5px;
    margin-left: 10px;
    color: ${Colors.digitalRed130};
    ${Typography.small}
`;

interface IComponentProps {
    canEdit: boolean,
    canView: boolean,
    canSecCheck1: boolean,
    canSecCheck2: boolean,

    title: string,
};


interface IComponentState {
}

export const DEFAULT_STATE: IComponentState = {};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IDirectEntryAccountProps = IMergeCrudComponentProps<IDirectEntryAccountState, IDirectEntryAccountActionProps, IOwnProps>;

enum eModifyButtonType {
    Add,
    Update
}

interface IDeaScheduleListProps {
    events: MCDea_Event[],
    display: boolean
}

interface IDeaScheduleListState {
}

export class DeaScheduleList extends React.Component<IDeaScheduleListProps, IDeaScheduleListState> {
    renderList() {
        return this.props.events.map((deaSchedule: MCDea_Event) => {
            var schedule: ISchedule = {
                Sun: deaSchedule["@Sun"],
                Mon: deaSchedule["@Mon"],
                Tue: deaSchedule["@Tue"],
                Wed: deaSchedule["@Wed"],
                Thu: deaSchedule["@Thu"],
                Fri: deaSchedule["@Fri"],
                Sat: deaSchedule["@Sat"],
                FromTime: deaSchedule["@FromTime"],
                ThruTime: deaSchedule["@ThruTime"],
            };

            return <ReadOnlySchedule key={deaSchedule["@ID"]} schedule={schedule}/>;
        });
    }

    public render() {
        if (this.props.display)
            return (<div><FunctionLabel>Schedule:</FunctionLabel><ScheduleOverflow>{this.renderList()}</ScheduleOverflow></div>);

        return (<ScheduleHiddenGutter></ScheduleHiddenGutter>);
    }
}


type AliasCredentialCallback = (aliasId: string, value: string) => any;

interface IAliasCredentialProps {
    key: string;
    aliasId: string;
    aliasName: string;
    userName: string;
    password: string;
    accountOtherClient: boolean;
    onUserNameChange: AliasCredentialCallback;
    onPasswordChange: AliasCredentialCallback;
}

interface IDeaUserNameState {
}

class DeaUserName extends React.Component<IAliasCredentialProps, IDeaUserNameState> {
    render() {
        const domNameId = `fiUserName${this.props.aliasId}`;
        const domPwdId = `fiPassword${this.props.aliasId}`;
        return (
            <FiItemRow>
                <FiAliasItem>{this.props.aliasName}</FiAliasItem>
                <FiUserPwdItem>
                    <Input
                        domID={domNameId}
                        autoComplete="new-password"
                        className='text-input'
                        initialValue={this.props.userName}
                        maxLength={25}
                        size="small"
                        disabled={this.props.accountOtherClient}
                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.onUserNameChange(this.props.aliasId, e.target.value)}
                    />
                </FiUserPwdItem>
                <FiUserPwdItem>
                    <Input
                        domID={domPwdId}
                        autoComplete="new-password"
                        type="password"
                        className="text-input"
                        maxLength={32}
                        size="small"
                        initialValue={this.props.password}
                        disabled={this.props.accountOtherClient}
                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.onPasswordChange(this.props.aliasId, e.target.value)}
                    />
                </FiUserPwdItem>
            </FiItemRow>
            );
    }
}
interface IDeaUserNameListProps {
    showFields: boolean;
    users: IAliasCredentialUIModel[];
    accountOtherClient: boolean;
    onUserNameChange: AliasCredentialCallback;
    onPasswordChange: AliasCredentialCallback;
}

interface IDeaUserNameListState {

}

export class DeaUserNameList extends React.Component<IDeaUserNameListProps, IDeaUserNameListState> {
    renderList() {
        if (!this.props.showFields)
            return (<div></div>);
        
        return this.props.users.map((credentials: IAliasCredentialUIModel) => {
            return <DeaUserName 
                        key={credentials.aliasId}
                        aliasId={credentials.aliasId}
                        aliasName={credentials.aliasName}
                        userName={credentials.userName}
                        password={credentials.password}
                        accountOtherClient={this.props.accountOtherClient}
                        onUserNameChange={(aliasId : string, value: string) => this.props.onUserNameChange(aliasId, value)}
                        onPasswordChange={(aliasId: string, value: string) => this.props.onPasswordChange(aliasId, value)} />;  
        });
    }

    public render() {
        return (<FiOverflow><FiHeaderRow><FiAliasHeader>Alias</FiAliasHeader><FiUserPwdHeader>User Name</FiUserPwdHeader><FiUserPwdHeader>Password</FiUserPwdHeader></FiHeaderRow>
            {this.renderList()}</FiOverflow>);
    }
}

export class DirectEntryAccount extends React.Component<IDirectEntryAccountProps, IComponentState> {

    static defaultProps: IComponentProps = {
        canEdit: false,
        canView: false,
        canSecCheck1: false,
        canSecCheck2: false,
        title: "Direct Entry Accounts",
    };

    minDate: string;
    maxDate: string;

    public canDeleteAccount() : boolean {
        return this.props.canSecCheck1;
    }

    public canCreateAccount(): boolean {
        return this.props.canSecCheck2;
    }

    constructor(props: IDirectEntryAccountProps) {
        super(props);
        this.state = DEFAULT_STATE;
        var myDate = new Date();        
        this.minDate = this.getDateInMMDDYYYYFormat(myDate);
        this.maxDate = this.dateAdd(myDate, 'y', 1);
    }

    public componentDidMount() {
        this.props.action.crud.get({ crudId: CrudTypes.mctiDDEAccountMaint });
        // this.setInitialLoad();
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public getDateInMMDDYYYYFormat(input: any): string {
        try {
            var month = input.getMonth() + 1;
            var day = input.getDate();
            var year = input.getFullYear();

            return (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;
        }
        catch (e) {
            console.error('Unable to properly format date');
        }
        return "00/00/0000";
    }

    public dateAdd(date: any, type: string, amount: number) {
        try {
            var y = date.getFullYear(),
                m = date.getMonth(),
                d = date.getDate();
            if (type === 'y') {
                y += amount;
            };
            if (type === 'm') {
                m += amount;
            };
            if (type === 'd') {
                d += amount;
            };
            var xx = new Date(y, m, d);
            return this.getDateInMMDDYYYYFormat(xx);
        }
        catch (e) {
            console.error('Unable to properly format date, dateAdd');
        }
        return "00/00/0000";
    }

    public onSelectDirectEntryAccount(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectAccount(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    id: e.target.value,
                    name: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
        //}
    }

    public updateAccount_UserName(aliasId: string, value: string) {
        this.props.action.ui.updateUserName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: aliasId,
                value: value
            }
        });
    }

    public updateAccount_Password(aliasId: string, value: string) {
        this.props.action.ui.updatePassword({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                id: aliasId,
                value: value
            }
        });
    }


    public updateAccount_Name(val: string) {
        this.props.action.ui.updateAccountName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }

    public updateAccount_Fi(e: React.ChangeEvent<HTMLSelectElement>) {
        var val = e.currentTarget.options[e.currentTarget.selectedIndex].value;

        //if (this.validate_TriggerEventChange(val)) {
            this.props.action.ui.updateAccountFi({
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    value: val
                }
            });
        //}
    }

    public updateAccount_CheckEligibility(val: boolean) {
        this.props.action.ui.updateAccountCheckEligibility({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateAccount_SendClaims(val: boolean) {
        this.props.action.ui.updateAccountSendClaims({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateAccount_Enabled(val: boolean) {
        this.props.action.ui.updateAccountEnabled({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateAccount_CheckStatus(val: boolean) {
        this.props.action.ui.updateAccountCheckStatus({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateAccount_CheckEdits(val: boolean) {
        this.props.action.ui.updateAccountCheckEdits({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateAccount_Linked(val: boolean) {
        this.props.action.ui.updateAccountLinked({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateAccount_AutoGenPwd(val: boolean) {
        this.props.action.ui.updateAuthAutoGenPwd({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    public updateAccount_AutoGenDays(val: string) {
        this.props.action.ui.updateAuthAutoGenDays({
            masterCrud: this.props.dataStore.crud.data,
            uiData: {
                value: val
            }
        });
    }
    
    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {
        //if (!this.validateForm())
        //    return;

        console.log(this.props.dataStore.crud.data);

        this.props.action.crud.updateWithDelta(this.props.dataStore.crud);
        this.props.action.ui.resetDefaultState();


        const location = {
            pathname: '/LandingPage',
            state: { fromDashboard: true }
        }

        this.props.history.push('/LandingPage');
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.resetDefaultState();

        this.props.history.push("/LandingPage");
    }

    public enforceArray<T>(node: T[]) : T[] {

        if (Array.isArray(node))
            return node;

        const array: T[] = [];
        return array.concat(node);
    }
    
    populateAccountSelect() {
        const selectList: IDirectEntryAccountUIData[] = [defaultUIState.selectedItem];

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.DDEAccountMaintenanceInfo ||
            !this.props.dataStore.crud.data.DDEAccountMaintenanceInfo.Accounts ||
            !this.props.dataStore.crud.data.DDEAccountMaintenanceInfo.Accounts.Account)
            return selectList;


        return selectList.concat(this.enforceArray<MCDea_Account>(this.props.dataStore.crud.data.DDEAccountMaintenanceInfo.Accounts.Account).map(
            (account: MCDea_Account) => {
                return {
                    id: account["@ID"],
                    name: account["@Name"]
                };
            }
        ));
    }

    public readEvents() {
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.DDEAccountMaintenanceInfo ||
            !this.props.dataStore.crud.data.DDEAccountMaintenanceInfo.FIList ||
            !this.props.dataStore.crud.data.DDEAccountMaintenanceInfo.FIList.FI)
            return [];
        
        const fi = this.enforceArray<MCDea_Fi>(this.props.dataStore.crud.data.DDEAccountMaintenanceInfo.FIList.FI).find(ob => ob["@ID"] === this.props.dataStore.ui.fiId);
        if (!fi)
            return [];

        if (!fi.Events || !fi.Events.Event)
            return [];

        return this.enforceArray<MCDea_Event>(fi.Events.Event);
    }

    public populateFiSelect() {
        const selectList = [
            {
                value: newFiValue,
                label: "- Select FI Name -"
            }
        ];

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.DDEAccountMaintenanceInfo ||
            !this.props.dataStore.crud.data.DDEAccountMaintenanceInfo.FIList ||
            !this.props.dataStore.crud.data.DDEAccountMaintenanceInfo.FIList.FI)
            return selectList;

        return selectList.concat(this
                .enforceArray<MCDea_Fi>(this.props.dataStore.crud.data.DDEAccountMaintenanceInfo.FIList.FI)
                .map(
                    (fi: MCDea_Fi) => {
                        return {
                            value: fi["@ID"],
                            label: fi["@Name"]
                        };
                    }))
            .sort((a, b) => {
                if ((!a || !a.label) && (!b || !b.label))
                    return 0;
                if (!a || !a.label)
                    return 1;
                if (!b || !b.label)
                    return -1;

                return a.label.localeCompare(b.label);
            });
    }

    public onSelectThisFi(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectThisFi(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selected: [].slice.call(e.target.selectedOptions).map((o: any) => { return o.value; }),
                }
            });
    }

    public onSelectThisAccount(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectThisAccount(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selected: [].slice.call(e.target.selectedOptions).map((o: any) => { return o.value; }),
                }
            });
    }


    public onRemoveProviders(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.removeProviders({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public onAddProviders(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.addProviders({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public getModifyButtonText() {
        return this.props.dataStore.ui.selectedItem.id === newAccountValue ? "ADD" : "UPDATE";
    }

    public isRemoveButtonDisabled() {
        if (!this.canDeleteAccount())
            return true;

        return this.props.dataStore.ui.selectedItem.id === newAccountValue;
    }

    public isModifyAccountDisabled() : boolean {
        if (this.props.dataStore.ui.selectedItem.id === newAccountValue && !this.canCreateAccount())
            return true;

        if (this.props.dataStore.ui.selectedItem.id !== newAccountValue && !this.props.canEdit)
            return true;

        return false;
    }

    public isLinkedCheckBoxDisabled(): boolean {
        if (this.props.dataStore.ui.accountOtherClient) {
            if (this.props.dataStore.ui.linkedCount > 1)
                return true;
        }
        return false;
    }

    public onModifyAccount() {
        this.props.action.ui.modifyAccount({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }
    public onRemoveAccount() {
        this.props.action.ui.removeAccount({ masterCrud: this.props.dataStore.crud.data, uiData: true });
    }

    public onClearGenerateDaysAlert(value: string) {
        this.props.dataStore.ui.generateDays = "";
        this.props.action.ui.toggleGenerateDaysError({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { value: value }
        });
    }
    

    public onToggleAccountEnableConfirm(show: boolean) {
        this.props.action.ui.toggleAccountEnableConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: show } });
    }

    public onToggleRemoveAccountConfirm(show: boolean) {
        this.props.action.ui.toggleRemoveAccountConfirm({ masterCrud: this.props.dataStore.crud.data, uiData: { value: show } });
    } 
    
    public onAccountLastChangeDateChanged(value: string) {

        this.props.action.ui.updateAccountLastChangeDate(
            { masterCrud: this.props.dataStore.crud.data, uiData: { value: value} });
    }

    public getLastChangeDate(val: string) {
        let lcDate: Date = new Date(val);
        return this.getDateInMMDDYYYYFormat(lcDate);
    }

    public renderLinkedControl() {
        let cc: string = "";
        let ccn: number = 0;
        if (this.props.dataStore.crud.data &&
            this.props.dataStore.crud.data.DDEAccountMaintenanceInfo)
        {
            cc = this.props.dataStore.crud.data.DDEAccountMaintenanceInfo["@ClientCount"];
            if (cc && cc.length > 0) ccn = Number(cc);
        }
        
        if (ccn <= 1)
            return (<div></div>);

        return (
            <CheckBoxWrapper>
                <CheckBoxComponent
                    id="linkAcct"
                    label="Shared"
                    width='160px'
                    checked={this.props.dataStore.ui.chkLinked}
                    disabled={this.isLinkedCheckBoxDisabled()}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccount_Linked(e.target.checked)}
                />
            </CheckBoxWrapper>);
    }

    public renderSelectButtons() {
        return (
            <SelectButtons>
                <Button
                    domID='automation-id'
                    name={this.getModifyButtonText()}
                    size='small'
                    buttonType="standard"
                    disabled={this.isModifyAccountDisabled() || this.props.dataStore.ui.accountOtherClient}
                    type='button'
                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onModifyAccount()}/>
                <Button
                    domID='automation-id'
                    name='REMOVE'
                    size='small'
                    buttonType="standard"
                    type='button'
                    disabled={this.isRemoveButtonDisabled() || this.props.dataStore.ui.accountOtherClient}
                    onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onToggleRemoveAccountConfirm(true)}/>
            </SelectButtons >
        );
    } 

    public renderDisabledText() {
        if (!this.props.dataStore.ui.enabled)
            return <AccountDisabled>Disabled {this.props.dataStore.ui.dateDisabled}</AccountDisabled>;
        return <div></div>;
    }   
    
    public render() {
        var instruction = <React.Fragment>Set up all dedicated Direct Entry accounts and add configuration information for the accounts.</React.Fragment>;
        var buttons = <OKCancelButtons onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;

        let selectList = this.populateAccountSelect();
        let events = this.readEvents();
        let fiSelectList = this.populateFiSelect();

        if (!this.props.canView) return (<div>User rights not established to view this activity.</div>);

        return (
            <DialogWrapper title="Direct Entry Accounts" instruction={instruction} helpUrl='/Support/Help/HELP_Maint_DirectEntry.htm' buttons={buttons} isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                <ContentWrapper>
                    <SelectList>
                        <DialogFieldset>
                            <DialogLegend>Accounts</DialogLegend>
                            <SelectComponent
                                title='directEntryAccount'
                                size={31}
                                width='200px'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectDirectEntryAccount(e)}
                                optionFields={{
                                    value: "id",
                                    text: "name"
                                }}
                                records={selectList}
                                selectedValue={this.props.dataStore.ui.selectedItem.id}
                            >
                            </SelectComponent>
                        </DialogFieldset>
                    </SelectList>
                    <SelectActions>
                        <GenericInputRow>
                            <AccountName>
                                <Input
                                    domID='loginName-input'
                                    autoComplete="new-password"
                                    label='Account Name:'
                                    className="text-input"
                                    maxLength={64}
                                    size="small"
                                    initialValue={this.props.dataStore.ui.accountName}
                                    hasError={this.props.dataStore.ui.accountNameError && this.props.dataStore.ui.accountNameError != ""}
                                    errorMessage={this.props.dataStore.ui.accountNameError}
                                    disabled={this.props.dataStore.ui.accountOtherClient}
                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateAccount_Name(e.target.value)}
                                    />
                            </AccountName>
                            {this.renderDisabledText()}
                        </GenericInputRow>
                        <div>
                            <FunctionLabel>Function:</FunctionLabel>
                            <FunctionSection>
                                <CheckBoxWrapper>
                                    <CheckBoxComponent
                                        id='checkElig'
                                        label='Check Eligibility'
                                        width='160px'
                                        checked={this.props.dataStore.ui.checkEligibility}
                                        disabled={this.props.dataStore.ui.accountOtherClient}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccount_CheckEligibility(e.target.checked)}
                                    />
                                </CheckBoxWrapper>
                                <CheckBoxWrapper>
                                    <CheckBoxComponent
                                        id='sendClaims'
                                        label='Send Claims'
                                        width='160px'
                                        checked={this.props.dataStore.ui.sendClaims}
                                        disabled={this.props.dataStore.ui.accountOtherClient}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccount_SendClaims(e.target.checked)}
                                    />
                                </CheckBoxWrapper>
                                <CheckBoxWrapper>
                                    <CheckBoxComponent
                                        id="acctEnabled"
                                        label="Enabled"
                                        width='160px'
                                        disabled={this.props.dataStore.ui.enabled || this.props.dataStore.ui.accountOtherClient}
                                        checked={this.props.dataStore.ui.enabled}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onToggleAccountEnableConfirm(e.target.checked)}
                                    />
                                </CheckBoxWrapper>
                                <CheckBoxWrapper>
                                    <CheckBoxComponent
                                        id="checkEdits"
                                        label="Check Edits"
                                        width='160px'
                                        checked={this.props.dataStore.ui.checkEdits}
                                        disabled={this.props.dataStore.ui.accountOtherClient}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccount_CheckEdits(e.target.checked)}
                                    />
                                </CheckBoxWrapper>
                                <CheckBoxWrapper>
                                    <CheckBoxComponent
                                        id="checkStatus"
                                        label="Check Status"
                                        width='160px'
                                        checked={this.props.dataStore.ui.checkStatus}
                                        disabled={this.props.dataStore.ui.accountOtherClient}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccount_CheckStatus(e.target.checked)}
                                    />
                                </CheckBoxWrapper>
                                {this.renderLinkedControl()}
                            </FunctionSection>
                        </div>
                        <DialogFieldset>
                            <DialogLegend>Fiscal Intermediary</DialogLegend>
                            <GenericInputRow>
                                <FiSelect>
                                    <SelectDropdown
                                        domID="fiName-select"
                                        label="Name:"
                                        options={fiSelectList}
                                        initialValue={this.props.dataStore.ui.fiId}
                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.updateAccount_Fi(e)}
                                        className="fiName-class"
                                        size="small"
                                        disabled={this.props.dataStore.ui.accountOtherClient}
                                        hasError={this.props.dataStore.ui.fiSelectError && this.props.dataStore.ui.fiSelectError != ""}
                                        errorMessage={this.props.dataStore.ui.fiSelectError}
                                    />
                                </FiSelect>
                            </GenericInputRow>
                            <DeaScheduleList events={events} display={true} />
                        </DialogFieldset>
                        <DialogFieldset>
                            <DialogLegend>Authorization</DialogLegend>
                            <GenericInputRowPaddedBottom>
                                <ElementWrapper>
                                    <CheckBoxComponent
                                        id="genPwdCheckbox"
                                        label="Generate password every"
                                        width="205px"
                                        checked={this.props.dataStore.ui.autoGeneratePwd}
                                        disabled={this.props.dataStore.ui.accountOtherClient}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccount_AutoGenPwd(e.target.checked)}
                                    />
                                </ElementWrapper>
                                <ElementWrapper>
                                    <GenerateDays>
                                        <Input
                                            domID='genPassDays-input'
                                            label=''
                                            className="text-input"
                                            maxLength={3}
                                            size={3}
                                            width="30px"
                                            initialValue={this.props.dataStore.ui.generateDays}
                                            hasError={this.props.dataStore.ui.autoGeneratePwd && this.props.dataStore.ui.generateDays.length <= 0}
                                            disabled={this.props.dataStore.ui.accountOtherClient}
                                            onBlur={(e: React.ChangeEvent<HTMLInputElement>) => this.updateAccount_AutoGenDays(e.target.value)}
                                        />
                                    </GenerateDays>
                                </ElementWrapper>
                                <GenericLabel>days</GenericLabel>
                            </GenericInputRowPaddedBottom>
                            <GenericInputRowPaddedBottom>
                                <GenericLabel>Password last changed on</GenericLabel>
                                <ElementWrapper>
                                    <ARMDatePicker
                                        domID="dea_pwd_last_changed"
                                        className="cal_style"
                                        onInputChange={(e: any) => this.onAccountLastChangeDateChanged(e)}
                                        allowTime={true}
                                        minDate={this.minDate}
                                        maxDate={this.maxDate}
                                        initialDate={this.getLastChangeDate(this.props.dataStore.ui.lastChangeDate)}
                                        disabled={this.props.dataStore.ui.accountOtherClient}
                                        disableHowLong={true}
                                    />
                                </ElementWrapper>
                            </GenericInputRowPaddedBottom>
                            <DeaUserNameList
                                showFields={true}
                                users={this.props.dataStore.ui.aliasCredentials}
                                accountOtherClient={this.props.dataStore.ui.accountOtherClient}
                                onUserNameChange={(aliasId: string, value: string) => this.updateAccount_UserName(aliasId, value)}
                                onPasswordChange={(aliasId: string, value: string) => this.updateAccount_Password(aliasId, value)}
                            />
                        </DialogFieldset>
                        <DialogFieldset>
                            <DialogLegend>Provider Numbers</DialogLegend>
                            <FunctionSection>
                                <SelectComponent
                                    title="thisFi"
                                    size={5}
                                    width="210px"
                                    label="This Fiscal Intermediary:"
                                    optionFields={{
                                        value: "providerId",
                                        text: "name"
                                    }}
                                    records={this.props.dataStore.ui.providers.filter(prov => !prov.selected).sort((a, b) => a.name.localeCompare(b.name))}
                                    multiple="true"
                                    disabled={this.props.dataStore.ui.accountOtherClient}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectThisFi(e)}
                                    selectedMultiValue={this.props.dataStore.ui.selectedThisFi}
                                    >
                                </SelectComponent>
                                <ArrowButtons
                                    disabled={!this.props.canEdit || this.props.dataStore.ui.accountOtherClient}
                                    paddingTop='30px'
                                    margin='0 15px 0 0'
                                    toolTipLeft="Remove Provider(s) from Selected Account"
                                    toolTipRight="Add Providers(s) to Selected Account"
                                    onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRemoveProviders(e)}
                                    onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onAddProviders(e)}
                                />
                                <SelectComponent
                                    title='thisAccount'
                                    size={5}
                                    width="210px"
                                    label="This Account:"
                                    optionFields={{
                                        value: "providerId",
                                        text: "name"
                                    }}
                                    records={this.props.dataStore.ui.providers.filter(prov => prov.selected).sort((a, b) => a.name.localeCompare(b.name))}
                                    multiple='true'
                                    disabled={this.props.dataStore.ui.accountOtherClient}
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectThisAccount(e)}
                                    selectedMultiValue={this.props.dataStore.ui.selectedThisAccount}
                                    >
                                </SelectComponent>
                            </FunctionSection>
                        </DialogFieldset>
                        {this.renderSelectButtons()}
                    </SelectActions>
                    <ModalConfirmation
                        isOpen={this.props.dataStore.ui.showEnableConfirm}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleAccountEnableConfirm(false)}
                        formattedMessage={(
                            <div>
                                <p>The system has disabled this account due to a password issue.  Contact your Medicare FI to update the password and then update this Direct Entry Account before selecting to enable.</p>
                                <p>Click <b>CONFIRM</b> if you have completed these steps and are ready to enable this account, or click <b>CANCEL</b> to cancel.</p>
                            </div>)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.updateAccount_Enabled(true)}
                    />
                    <ModalConfirmation
                        isOpen={this.props.dataStore.ui.showRemoveAccountConfirm}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleRemoveAccountConfirm(false)}
                        formattedMessage={(
                            <div>
                                <p>Do you want to remove <b>{this.props.dataStore.ui.selectedItem.name}</b>? This action cannot be undone.</p>
                            </div>)}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onRemoveAccount()}
                    />
                    <ModalConfirmation
                        isOpen={this.props.dataStore.ui.generateDaysError && this.props.dataStore.ui.generateDaysError.length > 0 ? true : false}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onClearGenerateDaysAlert("")}
                        message={this.props.dataStore.ui.generateDaysError}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClearGenerateDaysAlert("")}
                    />
                </ContentWrapper>
            </DialogWrapper>
        );
    }
};

var connectedHoc = connect<IDirectEntryAccountState, IDirectEntryAccountActionProps, IOwnProps, IDirectEntryAccountProps, ApplicationState>(
    createCrudMapStateToProps('directEntryAccount'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(DirectEntryAccount);

export default withRouter(connectedHoc);
