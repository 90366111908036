import React from "react";
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { DialogFieldset } from '@common/DialogStyles';
import { Typography } from '@commonResources/typography';
import { CreateCifDAL } from './CreateCifDAL';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { DownloadDocumentPt2 } from '@commonResources/window';
import { withRouter } from "react-router";
import CreateCIFScreen from './CreateCIFScreen';
import { VerifyClaimsScreen } from './VerifyClaimsScreen';
import { ProgressiveHeadings } from './ProgressiveHeadings';
import { CreateCCNScreen } from './CreateCNNScreen';
import { data as localData} from './formData';

type ICreateCIFProps = {
    match: any,
    history: any,
    location: any,
    child: any,
};

interface IComponentState {
    activeItemIndex: number,
    data?: CifFormData | null,
    areAllClaimsVerified: boolean,
    isYesClicked: boolean,
    redirect: string | null,
    isPrinted: boolean,
    CCN: string,
    formData?: CifFormDataDetails,
    modalAlertTitle: string,
    modalAlertMessage: string,
    isModalOpen: boolean,
    isSelectedLinesValid: false,
    
};
       
type IComponentProps = {};

interface IClaimVerified {
    label: string,
    verified: boolean;
};

export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

export const ScreenWrapper = styled.div`
    display: flex;
    flex-direction: column;
    text-align: left;
    font-family: ${Typography.ARMFontFamily};
    font-size: 11px;
    padding: 10px;
    font-weight: 600;
`;

export const VerifiedClaimItem = styled.div`
    font-family: ${Typography.ARMFontFamily};'
`;


export const ProgressiveHeadingsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    font-size: 11px;
    padding: 5px;
`;


export const DEFAULT_STATE: IComponentState = {
    activeItemIndex: 0,
    areAllClaimsVerified: false,
    isYesClicked: false,
    isPrinted: false,
    redirect: null,
    CCN: '',
    modalAlertTitle: '',
    modalAlertMessage: '',
    isModalOpen: false,
    formData: undefined,
    data: undefined,
    isSelectedLinesValid: false,
};


interface ICreateFormProps {
    data: CifFormData,
    modalHandler: any,
    child: any,
};
  

/**
 * 
 * CREATE CIF - THE MAIN COMPONENT 
 * This is the base component that composites the above screens and handles the business logic
 * for switching between screens according to where we are in the creation flow 
 *
 */


export class BaseCreateCIF extends React.Component<ICreateCIFProps, IComponentState> {

    // Class Globals
    referrerPage = '/cfi/default';
    child : any = undefined; 

    //  We fetch the form data and check whether the requirements pass verification
    public componentDidMount() {

        // set flat to consume local data or API
        let useLocal = false;

        const data: Promise<CifFormData | undefined> = new CreateCifDAL().GetCifFormData();

        data.then((response) => {

            //@ts-ignore
            const data: CifFormData = useLocal == true ? localData : response as CifFormData;

            // Set Component state with  data
            this.setState({ data: data });
            
            // Verify the incoming claims
            if (this.verifyClaims()) {
               // console.log("*** ALL CHECKS PASS! ***");
                this.setState({ areAllClaimsVerified: true });
                this.setState({ activeItemIndex: 1 });
            }

        })
    }

    constructor(props: ICreateCIFProps) {
        super(props);
        this.state = DEFAULT_STATE;
        this.modalHandler.bind(this);
        this.dataHandler.bind(this);
        this.child = React.createRef();
    }

    public modalHandler = (modalTitle: string, modalMessage:string,  modalVisible: boolean, ) => {
        this.setState({ modalAlertTitle: modalTitle });
        this.setState({ modalAlertMessage: modalMessage });
        this.setState({ isModalOpen: modalVisible });
    }

    public dataHandler = (data: CifFormDataDetails) => {
        this.setState({formData: data})
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {

        // The OK Button has two modes depending on whether the form has been printed
        // This is currently being handled by the child screen
        // OK is disabled while in the CCN screen but leaving here as well to accomodate potential future changes
       
        // Has state been printed?
        if (this.state.isPrinted) {

            //We persist the control data
            let controlURL = `/api/data/cif/record/${this.state.data?.formData.claimID}/${this.state.CCN}`
            const ccnGet: Promise<string | undefined > = new CreateCifDAL().GetCnnNumber(controlURL);
          
            ccnGet.then((response) => {
            
                this.props.history.push(this.referrerPage);
            })

        } else {

            // We check to make sure at least one service line has been selected
            if (this.child.current.validateServiceLines()) {

                console.log("Services lines are valid!");
               
                // We reconstruct data payload from state in child before sending to API
                const dataObject: CifFormDataDetails = this.child.current.assembleDataObject();

                //console.log("The data object is:", dataObject);

                const post: Promise<CifFormDataDetails | undefined> = new CreateCifDAL().PostCifFormData(dataObject);

                post.then((response) => {

                    // Data returned is in the following format:
                    // Sites/7/PaperClaims/Single/{689e7059-1d62-448b-8950-0d4fdf378e53}.cif

                    // Uncomment below lines to get Modal confirmation 
                    //let link = response;
                    //this.setState({ modalAlertTitle: `Claim Sucessfully Saved` })
                    //this.setState({ isModalOpen: true });
                    //this.setState({ modalAlertMessage: `${link} has been created` });


                    this.setState({ isPrinted: true });
                    this.setState({ activeItemIndex: 3 });

                    DownloadDocumentPt2(`/${response}`);
                })

            } 
              
            
        }
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        e.preventDefault();

        // Are we in the last screen?
        if (this.state.activeItemIndex === 3) {
            this.setState({ isPrinted: false });
            this.setState({ activeItemIndex: 1 });
        } else {
            this.props.history.push(this.referrerPage);
        }  
    }

    public verifyClaims() {

        if (this.state.data) {
            const { requirementChecks } = this.state.data;
         
            if (requirementChecks) {

                // A failed check causes us to display the "Verification" pane
                const failedChecks = requirementChecks.filter(check => check.success === false);
                return failedChecks.length > 0 ? false : true;
            }
        }

    }

    public checkIsOK = () => {

    
        if (this.state.activeItemIndex < 2) {

            return (!this.state.areAllClaimsVerified && !this.state.isYesClicked);
               
        } else {
            // isPrinted will be true at this point so will disable the OK button
        
            //console.log("Is Printed ==> ", this.state.isPrinted );
            return this.state.isPrinted;
        }
    }

    public render() {

        const { match, location, history } = this.props;

        const progressiveHeadings = [
            { label: "Verify Claim Requirements", ID: '1' },
            { label: "Create CIF", ID: '2' },
            { label: "Print CIF", ID: '3' },
            { label: "Log CIF CCN", ID: '4' },
        ];

        const instruction = <React.Fragment>Use this page to set up and create California CIF file. Make changes to the data as needed. When you click OK the CIF displays in a separate window. Print the CIF from that window. Click the ? to read more details.</React.Fragment>;

        const buttons = <OKCancelButtons
            disableOK={this.checkIsOK()}
            onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)}
            onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)
            } />

        var content = null;
        if (this.state.data && this.state.data.formData && this.state.data.requirementChecks) {
            content =
                <React.Fragment>
                    <ContentWrapper>
                        <ProgressiveHeadingsWrapper>
                            <ProgressiveHeadings progressiveHeadings={progressiveHeadings} activeItemIndex={this.state.activeItemIndex} parent={this} />
                        </ProgressiveHeadingsWrapper>
                        <DialogFieldset>
                            <ScreenWrapper>
                                {this.state.activeItemIndex === 0 && < VerifyClaimsScreen parent={this} />}
                                {this.state.activeItemIndex === 1 && <CreateCIFScreen data={this.state.data} modalHandler={this.modalHandler} ref={this.child} />}
                                {this.state.activeItemIndex === 3 && <CreateCCNScreen parent={this} />}
                            </ScreenWrapper>

                        </DialogFieldset>
                    </ContentWrapper>
                    <ModalConfirmation
                        title={this.state.modalAlertTitle}
                        isOpen={this.state.isModalOpen}
                        alertMode={true}
                        onModalToggle={() => this.setState({ isModalOpen: false })}
                        formattedMessage={this.state.modalAlertMessage}
                        onConfirm={() => { }}
                    />
                </React.Fragment>;
        }
        return (

            <DialogWrapper
                title="Claim Display >> Create California CIF"
                instruction={instruction}
                helpUrl="/Support/Help/HELP_Claim_Create_CIF.htm"
                buttons={buttons}
                // we use fixed width to prevent fieldsets from going out bounds
                width={'1280px'}
            >
                { content }
            </DialogWrapper>
        )
    }
};

export default withRouter(BaseCreateCIF);