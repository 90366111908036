import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as JobQueueUI from './ui/JobQueueUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IJobQueueState = ICrudComponentState<MCARMRLReport, JobQueueUI.IJobQueueUIState>;

export const actionCreators = createCrudComponentActions<JobQueueUI.ActionCreators>(JobQueueUI.actionCreators);
export type IJobQueueActions = typeof actionCreators;
export type IJobQueueActionProps = ICrudComponentActionProps<IJobQueueActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimNotes, JobQueueUI.reducer);
export const reducer = combineReducers<IJobQueueState>(reducers);
