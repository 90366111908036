import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IModalConfirmationState {
    isOpen: boolean;
    message: string;
    confirmCallback?: () => void;
    cancelCallback?: () => void;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface IModalConfirmationData {
    message: string | JSX.Element;
    confirmCallback?: () => void;
    cancelCallback?: () => void;
};

export const actionCreators = {
    openConfirm: (message: string, confirmCallback?: () => void, cancelCallback?: () => void) => createDataAction('OPEN_MODAL_CONFIRMATION', { message: message, confirmCallback: confirmCallback, cancelCallback: cancelCallback }),
    closeConfirm: () => createAction('CLOSE_MODAL_CONFIRMATION'),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IModalConfirmationState = {
    isOpen: false,
    message: '',
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IModalConfirmationState, KnownActions> = (state: IModalConfirmationState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'OPEN_MODAL_CONFIRMATION':
            {
                let openState: IModalConfirmationState = {
                    ...state,
                    isOpen: true,
                    message: action.data.message,
                    confirmCallback: action.data.confirmCallback,
                    cancelCallback: action.data.cancelCallback
                };
                return openState;
            }
            case 'CLOSE_MODAL_CONFIRMATION':
            {
                let closeState: IModalConfirmationState = {
                    ...state,
                    isOpen: false,
                    message: '',
                };
                return closeState;
            }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}
