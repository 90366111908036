import * as React from 'react';
//import { useState, useEffect, useRef, useCallback } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { CrudTypes } from '@commonResources/CrudTypes';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import styled from 'styled-components';
import {
    Buttons,
    ButtonGroupStyle,
    Labels as LabelStyles,
    CenterFormItemsStyle
} from '@common/component-styles';
import { pageLeave, handleChange } from '@commonResources/userModified';
import { Input, Button, Tabs, Radio } from 'ui-core';
import { CheckBoxComponent } from '@common/CheckBox';
import { RadioButtonComponent } from '@common/RadioButton';

import { IModalConfirmationProps, ModalConfirmation } from '@common/ModalConfirmation';
import { DialogWrapper, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { PayerAliasTypeEnum } from '@store/ui/PayerAliasUI';
import { IPayerAliasState, IPayerAliasActionProps, actionCreators, validationCallback } from '@store/PayerAlias';
//import { Input } from '../../common/UICLWrappers/ARMInput';

const ContentWrapper = styled.div`
    /* display: block; */
    flex: 1 1 auto;
    flex-direction: row;
    width: 950px;
    min-height: 610px;
`;


//because TypeScript, can't just create and use dynamic objects, must jump through TypeScript hoops (see https://stackoverflow.com/questions/12710905/how-do-i-dynamically-assign-properties-to-an-object-in-typescript)
const DefaultProps: Record<string, any> = {};
DefaultProps.leftWidth = {
    width: '100%'
}
DefaultProps.SelectComponent = {
    size: 12,
    ...DefaultProps.leftWidth,
    height: "442px",
    multiple: "false",
};
DefaultProps.InputComponent = {
    className: "text-input",
    size: "small",
    maxLength: 21,
    ...DefaultProps.leftWidth,
};


interface ITabID {
    domID: string;
    label: string;
}

interface UIListSelectedPayerLOB {
    value: string;
    text: string;
};

interface IButtonActionState {
    name: string;
    disabled?: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
};

interface IComponentProps { //confirmed from legacy
    canView: boolean;
    canCreate: boolean;
    canEdit: boolean;
    canDelete: boolean;
    ncsIsEnabled: boolean;
    ncsSkipPrompt: boolean;
    ncsPromptText: string;
};

interface IPayerAliasCRUD {
    crudId: CrudTypes;
    data: MCPayerAliasType;
}

interface IComponentState {
    isPageBusy: boolean;
    componentMessages: Array<UIMessage> | undefined;
    modalProps: IModalConfirmationProps;
    btnPayerAliasActionState: IButtonActionState;
    isRemovePayerAliasDisabled: boolean;
    isContinueDisabled: boolean;

    inputSearchPayersByCPID: string;
    inputSearchPayers: string;
    inputSearchPayerAliases: string;

    filteredPayerList: Array<MCPALIAS_Payer> | undefined;
    selectedPayerOption: MCPALIAS_Payer | undefined;
    listPayerAlias: Array<MCPALIAS_Alias> | undefined; //is strictly alias list for a specific payer
    selectedListPayerAliasItem: MCPALIAS_Alias | undefined;  //from listPayerAlias
    selectedPayerAliasItemType: string;

    filteredAliasList: Array<MCPALIAS_Alias> | undefined;
    selectedPayerAliasOption: MCPALIAS_Alias | undefined;
    aliasPayerName: MCPALIAS_Payer | undefined;

    inputPayerAliasName: string;
    isPayerAliasTypeDisabled: boolean;
    isShowOnClaimFilterChecked: boolean;

    listSelectedPayerLOB: Array<UIListSelectedPayerLOB> | undefined;
    payerAliasDelta: IPayerAliasCRUD;
    displayGroup0: 'none' | 'block';
    displayGroup1: 'none' | 'block';
    pageStateCleared: boolean;
    payersFound: boolean;
    removedData: any
};


const DEFAULT_STATE: IComponentState = {
    isPageBusy: true,
    componentMessages: undefined,
    
    modalProps: {
        isOpen: false,
        showDefaultClose: true,
        onModalToggle: (() => {
            return false;
        }),
        message: "",
        formattedMessage: undefined,
        onConfirm: undefined,
        onDeny: undefined,
        onOK: undefined,
        okText: "OK",
        cancelText: "Cancel"
    },
    btnPayerAliasActionState: {
        name: "Add",
        disabled: false,
        onClick: () => { },
    },
    isRemovePayerAliasDisabled: true,
    isContinueDisabled: true,

    inputSearchPayersByCPID: "",
    inputSearchPayers: "",
    inputSearchPayerAliases: "",

    filteredPayerList: undefined,
    selectedPayerOption: undefined,
    listPayerAlias: undefined, //is strictly alias list for a specific payer
    selectedListPayerAliasItem: undefined,
    selectedPayerAliasItemType: "",

    filteredAliasList: undefined,
    selectedPayerAliasOption: undefined,
    aliasPayerName: undefined,

    inputPayerAliasName: "",
    isPayerAliasTypeDisabled: false,
    isShowOnClaimFilterChecked: false,

    listSelectedPayerLOB: undefined,
    payerAliasDelta: {
        crudId: CrudTypes.mctiPayerAlias,
        data: {
            PayerAliasMaintenanceInfo: {
                PayerList: {
                    Payer: []
                },
                Aliases: {
                    Alias: []
                },
                IDAliases: {
                    Alias: []
                },
                CustomAliases: {
                    Alias: []
                },
            }
        }
    },

    displayGroup0: 'none',
    displayGroup1: 'none',
    pageStateCleared: false,
    payersFound: false,
    removedData: [],
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IPayerAliasProps = IMergeCrudComponentProps<IPayerAliasState, IPayerAliasActionProps, IOwnProps>;

const Page = {
    Selectors: {
        idSelectPayers: "selectOptionsPayers",
        idButtonContinue: "btnContinue",
        idButtonCancel: "btnCancel",
        idTabByPayer: "tabByPayer",
        idTabByAlias: "tabByAlias",
        idInputSearchByCPID: 'idInputSearchByCPID',
        idInputSearchPayers: 'idInputSearchPayers',
        idTextboxPayerName: 'idTextboxPayerName',
        idButtonSearch: 'idButtonSearch',
        idButtonClear: 'idButtonClear',
        namePayerAliasType: 'namePayerAliasType',
        idTextPayerAliasName: 'idTextPayerAliasName',
    }
};
Object.freeze(Page);
Object.freeze(Page.Selectors);

class PayerAlias extends React.Component<IPayerAliasProps, IComponentState>  { //temp software intervention because TyepScript

    //private targetLanguage:ISO_LANG = ISO_LANG.EN;
    private readonly text = {
        pageTitle: "Payer Alias Maintenance",
        OK: "OK",
        Cancel: "Cancel",
        "Search": "Search",
        "Clear": "Clear",
        "Add Payer Alias": "- ADD A NEW PAYER ALIAS -",
        "Payer Alias Information": "Payer Alias Information",
        "search legend title": "Search for Payers by CPID",
        footerInstructions: "Use this page to cross-reference payer names used on your claims to official ECARMAST payer names. If the payer name on a claim is not recognized, the claim will be treated as a paper claim. To add the alias to the Payer list in the Advanced Filter, check 'Show on Claim Filter'.",
        typePayerName: "Payer Name",
        typePayerID: "Payer ID",
        typeCustomID: "Custom ID",
        NCSPromptToSave: "Do you want to update all enterprise sites?",
    };

    private readonly tabs: Array<ITabID> = [
        {
            domID: Page.Selectors.idTabByPayer,
            label: "By Payer"
        },
        {
            domID: Page.Selectors.idTabByAlias,
            label: "By Alias"
        },
    ];

    constructor(props: IPayerAliasProps) {
        super(props);
        //this.state = {
        //    errorMessages: new Array<IMessage>()
        //}
        this.state = DEFAULT_STATE;
        //this.clientId = sessionStorage.getItem("Client")?.split(' ')[0];
    }

    //shouldComponentUpdate(nextProps: any, nextState: any) {
    //
    //}

    public componentDidMount() {
        pageLeave();
        this.resetPageState(() => {
            this.refreshData()
                .then((isValid: boolean) => {
                    if (isValid) {
                        console.log(`crud data is valid`);
                    }
                    else {
                        console.log(`crud data is invalid`);
                    }
                })
                .catch((message: string) => {
                    console.log(`Error while refreshing data: ${message}`);
                })
                .finally(() => {
                    const firstTab = document.getElementById(this.tabs[0].domID) as HTMLElement;
                    firstTab.click();
                });
        });
    }

    public componentWillUnmount() {
        if (this.state.pageStateCleared === false) {
            this.setState(
                {
                    pageStateCleared: true
                },
                () => {
                    this.resetPageState();
                }
            );

        }
        pageLeave();
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    private promptToSaveChangesBeforeLeaving() {
        if (this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.Aliases.Alias.length > 0 ||
            this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.IDAliases.Alias.length > 0 ||
            this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.CustomAliases.Alias.length > 0) {

            const promptToLeave = <><div><b>Are you sure you want to leave this page?</b> <br /> <p> Your changes may NOT be saved.</p></div ></>;
            let modalProps: IModalConfirmationProps = { ...this.state.modalProps };
            modalProps.isOpen = true;
            modalProps.formattedMessage = promptToLeave;
            modalProps.onConfirm = () => this.redirectToHome();
            modalProps.onDeny = () => this.closeModal();
            modalProps.okText = 'Leave';
            modalProps.cancelText = 'Stay';
            //
            this.setState({
                isPageBusy: true,
                modalProps: modalProps
            });
        }
        else {
            //TO-DO: refactor
            this.redirectToHome();
        }
    }

    private refreshData = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            if (this.props.canView) {
                this.props.action.crud.get({ crudId: CrudTypes.mctiPayerAlias },
                    async (crud: any) => {
                        let isValid = await validationCallback(crud); //NOTE: VERY IMPORTANT to have "await" here, otherwise objects below will not be populated

                        //must use .map() to clone array object, otherwise original array source would be updated because array elements are copied by reference.
                        let filteredPayerList = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.PayerList?.Payer?.map(o => ({ ...o })); //?.map(o => ({ ...o }));
                        let filteredAliasList = this.getPayerAliasList();

                        this.setState(
                            {
                                isPageBusy: false,
                                filteredPayerList: filteredPayerList,
                                selectedPayerOption: filteredPayerList?.[0],
                                filteredAliasList: filteredAliasList,
                                selectedPayerAliasOption: filteredAliasList[0],
                            },
                            () => {
                                this.selectPayerListOption(0);
                                if (isValid) {
                                    resolve(true);
                                }
                                else {
                                    reject("failed in validationCallback(crud)");
                                }
                            }
                        );

                        return isValid;
                    });
            }
            else {
                reject("no permission to view");
            }
        });
    };

    private redirectToHome = () => {
        this.setState(
            {
                pageStateCleared: true
            },
            () => {
                this.resetPageState(() => {
                    window.history.replaceState(null, 'Private', '/LandingPage'); //remove from history 
                    this.props.history.push("/LandingPage", null);
                    //window.location.href = '/LandingPage';
                });
            }
        );
    }

    private resetPageState = (callback?: () => void) => {
        this.setState(
            {
                isPageBusy: true,
                selectedPayerOption: undefined,
                selectedListPayerAliasItem: undefined,
                selectedPayerAliasOption: undefined,
                listSelectedPayerLOB: undefined,
                filteredAliasList: undefined,
                filteredPayerList: undefined,
                listPayerAlias: undefined,
                payerAliasDelta: {
                    crudId: CrudTypes.mctiPayerAlias,
                    data: {
                        PayerAliasMaintenanceInfo: {
                            PayerList: {
                                Payer: []
                            },
                            Aliases: {
                                Alias: []
                            },
                            IDAliases: {
                                Alias: []
                            },
                            CustomAliases: {
                                Alias: []
                            },
                        }
                    }
                },
                modalProps: {
                    isOpen: false,
                    showDefaultClose: true,
                    onModalToggle: (() => {
                        return false;
                    }),
                    message: "",
                    formattedMessage: undefined,
                    onConfirm: undefined,
                    onDeny: undefined,
                    onOK: undefined,
                    okText: "OK",
                    cancelText: "Cancel"
                },
                inputSearchPayersByCPID: "",
                inputSearchPayers: "",
                inputSearchPayerAliases: "",
            },
            () => {
                if (typeof (callback) === 'function') {
                    callback();
                }
            }
        );
    }

    //----------------------------------------------
    //supporting, private functions

    /**
     * return true if valid, otherwise false
     * @param aliasName
     */
    private validatePayerAliasName = (aliasType: string, aliasName: string): { isValid: boolean; message: string; } => {
        let isValid = true;
        let errorMessage = '';
        if (!this.state.selectedPayerOption?.["@ID"]) {
            errorMessage = `Payer not selected.`;
            isValid = false;
        }

        //from legacy, there is no restriciton on character entry, can be anything
        if (aliasName.length === 0) {
            errorMessage = `Please enter a payer alias.`;
            isValid = false;
        }
        //business logic, check for duplicate Payer Alias Name and Type across ALL Payers
        if (isValid) {
            let duplicatePayerAlias: MCPALIAS_Alias | undefined;
            let payerAliasAlreadyDeleted: MCPALIAS_Alias | undefined;
            switch (aliasType) {
                case PayerAliasTypeEnum[PayerAliasTypeEnum["Payer Name"]]:
                    duplicatePayerAlias = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.Aliases.Alias.find(o => o["@Name"] === aliasName && o["@Delete"] !== "true");
                    payerAliasAlreadyDeleted = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.Aliases.Alias.find(o => o["@Name"] === aliasName && o["@Delete"] === "true");
                    break;
                case PayerAliasTypeEnum[PayerAliasTypeEnum["Payer ID"]]:
                    duplicatePayerAlias = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.IDAliases.Alias.find(o => o["@Name"] === aliasName && o["@Delete"] !== "true");
                    payerAliasAlreadyDeleted = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.IDAliases.Alias.find(o => o["@Name"] === aliasName && o["@Delete"] === "true");
                    break;
                case PayerAliasTypeEnum[PayerAliasTypeEnum["Custom ID"]]:
                    duplicatePayerAlias = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.CustomAliases.Alias.find(o => o["@Name"] === aliasName && o["@Delete"] !== "true");
                    payerAliasAlreadyDeleted = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.CustomAliases.Alias.find(o => o["@Name"] === aliasName && o["@Delete"] === "true");
                    break;
                default:
                    duplicatePayerAlias = undefined;
                    break;
            }
            if (duplicatePayerAlias) {
                if (this.state.removedData?.filter((o: any) => o["@reactKey"] === duplicatePayerAlias!["@reactKey"])?.length > 0) {
                    const dataRemoved = this.state.removedData?.filter((o: any) => o["@reactKey"] !== duplicatePayerAlias!["@reactKey"])
                    this.setState({ removedData: dataRemoved })
                }
                else {
                    const foundPayer = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.PayerList?.Payer.find(o => o["@ID"] === duplicatePayerAlias?.["@PayerID"]);
                    errorMessage = `The alias '${aliasName}' already exists for Payer '${foundPayer!["@Name"]}'. Please enter a different payer alias.`;
                    isValid = false;
                }
            }
            else {
                if (this.state.removedData?.filter((o: any) => o["@Name"] === aliasName)?.length > 0) {
                    const dataRemoved = this.state.removedData?.filter((o: any) => o["@Name"] !== aliasName)
                    this.setState({ removedData: dataRemoved })
                }
                if (payerAliasAlreadyDeleted) {
                    let duplicatePayerAlias: MCPALIAS_Alias | undefined;
                    switch (aliasType) {
                        case PayerAliasTypeEnum[PayerAliasTypeEnum["Payer Name"]]:
                            duplicatePayerAlias = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.Aliases.Alias.find(o => o["@Name"] === aliasName && payerAliasAlreadyDeleted?.["@Name"] !== aliasName);
                            break;
                        case PayerAliasTypeEnum[PayerAliasTypeEnum["Payer ID"]]:
                            duplicatePayerAlias = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.IDAliases.Alias.find(o => o["@Name"] === aliasName && payerAliasAlreadyDeleted?.["@Name"] !== aliasName);
                            break;
                        case PayerAliasTypeEnum[PayerAliasTypeEnum["Custom ID"]]:
                            duplicatePayerAlias = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.CustomAliases.Alias.find(o => o["@Name"] === aliasName && payerAliasAlreadyDeleted?.["@Name"] !== aliasName);
                            break;
                        default:
                            duplicatePayerAlias = undefined;
                            break;
                    }
                    if (duplicatePayerAlias) {
                        const foundPayer = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.PayerList?.Payer.find(o => o["@ID"] === duplicatePayerAlias?.["@PayerID"]);
                        errorMessage = `The alias '${aliasName}' already exists for Payer '${foundPayer!["@Name"]}'. Please enter a different payer alias.`;
                        isValid = false;
                    }
                }
                else {
                    let duplicatePayerAlias: MCPALIAS_Alias | undefined;
                    switch (aliasType) {
                        case PayerAliasTypeEnum[PayerAliasTypeEnum["Payer Name"]]:
                            duplicatePayerAlias = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.Aliases.Alias.find(o => o["@Name"] === aliasName);
                            break;
                        case PayerAliasTypeEnum[PayerAliasTypeEnum["Payer ID"]]:
                            duplicatePayerAlias = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.IDAliases.Alias.find(o => o["@Name"] === aliasName);
                            break;
                        case PayerAliasTypeEnum[PayerAliasTypeEnum["Custom ID"]]:
                            duplicatePayerAlias = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.CustomAliases.Alias.find(o => o["@Name"] === aliasName);
                            break;
                        default:
                            duplicatePayerAlias = undefined;
                            break;
                    }
                    if (duplicatePayerAlias) {
                        const foundPayer = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.PayerList?.Payer.find(o => o["@ID"] === duplicatePayerAlias?.["@PayerID"]);
                        errorMessage = `The alias '${aliasName}' already exists for Payer '${foundPayer!["@Name"]}'. Please enter a different payer alias.`;
                        isValid = false;
                    }
                }
            }
        }

        return {
            isValid: isValid,
            message: errorMessage
        };
    }

    private closeModal = () => {
        let modalProps: IModalConfirmationProps = { ...this.state.modalProps };
        modalProps.isOpen = false;
        modalProps.formattedMessage = undefined;
        modalProps.onConfirm = undefined;
        modalProps.onDeny = undefined;
        modalProps.onOK = undefined;
        modalProps.okText = 'OK';
        modalProps.cancelText = 'Cancel';
        //
        this.setState({
            isPageBusy: false,
            modalProps: modalProps
        });
    }

    /**
     * Business logic pulled from legacy code; used to display list of formatted formatted text to user.  Formatted text 
     * is concatenated array for Payer Alias by Payer Name, Payer ID, and Custom ID.  NOTE: MUST USE .map() javascript
     * array method to create a clone of an array otherwise elements are passed by reference, which means any array updates
     * outside of state will still update the source array in the state store!
     */
    private getPayerAliasList = (): Array<MCPALIAS_Alias> => {
        //Payer Alias, Payer Name
        let aliases: Array<MCPALIAS_Alias> = [];
        let aliasList = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.Aliases.Alias.filter(o => o["@Delete"] != 'true');

        let filteredList = aliasList?.map(o => ({ ...o })) || [];
        if (filteredList.length > 0) {
            //add prefix and react key...
            filteredList.forEach((i, index) => {
                i["@reactKey"] = `${i["@PayerID"]}_${i["@ID"]}_${i["@Name"]}`;
                i["@DisplayName"] = `(${this.text.typePayerName}) ${i["@Name"]}`;
                i["@AliasType"] = `${this.text.typePayerName}`;
            });
            aliases = aliases.concat(filteredList);
        }
        //
        //Payer Alias, Payer ID
        aliasList = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.IDAliases.Alias.filter(o => o["@Delete"] != 'true');
        filteredList = aliasList?.map(o => ({ ...o })) || [];
        if (filteredList) {
            //add prefix and react key...
            filteredList?.forEach((i, index) => {
                i["@reactKey"] = `${i["@PayerID"]}_${i["@ID"]}_${i["@Name"]}`;
                i["@DisplayName"] = `(${this.text.typePayerID}) ${i["@Name"]}`;
                i["@AliasType"] = `${this.text.typePayerID}`;
            });
            aliases = aliases.concat(filteredList);
        }
        //
        //Payer Alias, Custom ID
        aliasList = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.CustomAliases.Alias.filter(o => o["@Delete"] != 'true');
        filteredList = aliasList?.map(o => ({ ...o })) || [];
        if (filteredList) {
            //add prefix and react key...
            filteredList?.forEach((i, index) => {
                i["@reactKey"] = `${i["@PayerID"]}_${i["@ID"]}_${i["@Name"]}`;
                i["@DisplayName"] = `(${this.text.typeCustomID}) ${i["@Name"]}`;
                i["@AliasType"] = `${this.text.typeCustomID}`;
            });
            aliases = aliases.concat(filteredList);
        }

        // sometimes we call refresh data not on page load and need to keep the local delta
        let payerAliasDelta = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.Aliases.Alias.filter(o => o["@Delete"] != 'true')
        if (payerAliasDelta && payerAliasDelta.length > 0) {
            aliases = aliases.concat(payerAliasDelta)
        }

        //sort 
        aliases.sort((o1: MCPALIAS_Alias, o2: MCPALIAS_Alias) => {
            let a = o1["@DisplayName"], b = o2["@DisplayName"];
            if (!a || !b) {
                return 0;
            }
            return a.localeCompare(b);
            //if (!o1["@DisplayName"] || !o2["@DisplayName"]) {
            //    return 0;
            //}
            //if (o1["@DisplayName"] < o2["@DisplayName"]) {
            //    return -1;
            //}

            //if (o1["@DisplayName"] > o2["@DisplayName"]) {
            //    return 1;
            //}

            //return 0;
        });
        //insert into index 0; must do after sort, otherwise this will show at bottom of list ("(" appears before "-" )
        aliases.splice(0, 0, {
            '@reactKey': `0_0_${this.text["Add Payer Alias"]}`,
            '@ID': '0',
            '@Name': '',
            '@DisplayName': `${this.text["Add Payer Alias"]}`,
            '@AliasType': `${this.text.typePayerName}`,
        });

        return aliases;
    }

    private handleChange = (): void => {
        if (this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.Aliases.Alias.length > 0 ||
            this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.IDAliases.Alias.length > 0 ||
            this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.CustomAliases.Alias.length > 0) {
            handleChange();
        }
        else {
            pageLeave();
        }
    }

    private payerAliasAdd = () => {
        const newPayerAliasName = this.state.inputPayerAliasName.trim();
        const newPayerAliasType = this.state.selectedPayerAliasItemType;
        const validated = this.validatePayerAliasName(newPayerAliasType, newPayerAliasName);
        if (validated.isValid === false) {
            this.setState({
                isPageBusy: false,
            });
            console.log(validated.message);
            alert(validated.message);
            return;
        }

        //from legacy: must use '#' for ID to indicate new alias
        let newPayerAlias: MCPALIAS_Alias = {
            "@reactKey": `${this.state.selectedPayerOption?.["@ID"]}_0_${newPayerAliasType}_${newPayerAliasName}`,
            "@ID": "#",
            "@Name": `${newPayerAliasName}`,
            "@DisplayName": `(${newPayerAliasType}) ${newPayerAliasName}`,
            "@PayerID": `${this.state.selectedPayerOption?.["@ID"]}`,
            "@ShowInFilter": this.state.isShowOnClaimFilterChecked ? 'Y' : 'N',
            "@AliasType": `${newPayerAliasType}`,
        };

        let arrayRef: Array<MCPALIAS_Alias> | undefined;
        let storeRef: Array<MCPALIAS_Alias> | undefined;
        switch (newPayerAlias["@AliasType"]) {
            case "Payer Name":
                arrayRef = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.Aliases.Alias;
                storeRef = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.Aliases.Alias;
                break;
            case "Payer ID":
                arrayRef = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.IDAliases.Alias;
                storeRef = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.IDAliases.Alias;
                break;
            case "Custom ID":
                arrayRef = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.CustomAliases.Alias;
                storeRef = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.CustomAliases.Alias;
                break;
            default:
                console.log("Unknown type for Payer Alias.");
                break;
        }

        if (arrayRef) {
            arrayRef.push(newPayerAlias);
        }
        if (storeRef) {
            storeRef.push({
                "@ID": newPayerAlias["@ID"],
                "@reactKey": newPayerAlias["@reactKey"],
                "@Name": newPayerAlias["@Name"],
                "@PayerID": newPayerAlias["@PayerID"],
                "@ShowInFilter": newPayerAlias["@ShowInFilter"]
            }); //replace at index
        }
        let refFilteredAliasList = this.state.filteredAliasList;
        if (refFilteredAliasList) {
            refFilteredAliasList.push(newPayerAlias);
        }
        let refListPayerAlias = this.state.listPayerAlias;
        if (refListPayerAlias) {
            refListPayerAlias.push(newPayerAlias);
        }

        if (this.props.canCreate) {

            let selectedPayerAlias: MCPALIAS_Alias = this.state.listPayerAlias.filter(o => o["@Delete"] != 'true')[0];
            this.setState(
                {
                    filteredAliasList: refFilteredAliasList,
                    listPayerAlias: refListPayerAlias,
                    payerAliasDelta: this.state.payerAliasDelta,
                    selectedListPayerAliasItem: selectedPayerAlias,
                    selectedPayerAliasOption: selectedPayerAlias,
                },
                () => {
                    this.handleChange();
                    this.props.action.ui.addPayerAlias({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: newPayerAlias
                    });

                    this.updatePayerAliasInformationUI(selectedPayerAlias);
                    //self.forceUpdate();
                }
            );
        }
    }

    private payerAliasRemove = () => {
        let self = this;
        let selectedPayerAlias = this.state.selectedListPayerAliasItem;
        if (selectedPayerAlias && selectedPayerAlias['@DisplayName'] !== this.text["Add Payer Alias"]) {
            const promptForDeletion = <div><p>Do you want to remove {selectedPayerAlias['@Name']}? This action cannot be undone.</p></div>;
            let modalProps: IModalConfirmationProps = { ...this.state.modalProps };
            modalProps.isOpen = true;
            modalProps.formattedMessage = promptForDeletion;
            modalProps.onConfirm = (onConfirmed.bind(this));
            modalProps.onDeny = undefined;
            modalProps.onOK = (onConfirmed.bind(this))
            modalProps.okText = 'OK';
            modalProps.cancelText = 'Cancel';
            //
            this.setState({
                modalProps: modalProps
            });
        }

        return;

        //private hoisted function via closure
        function onConfirmed() {
            self.closeModal();

            let selectedPayerAlias = self.state.selectedListPayerAliasItem;
            if (selectedPayerAlias && selectedPayerAlias['@Name'] !== self.text["Add Payer Alias"]) {
                let arrayRef: Array<MCPALIAS_Alias> | undefined;
                let storeRef: Array<MCPALIAS_Alias> | undefined;
                switch (selectedPayerAlias["@AliasType"]) {
                    case "Payer Name":
                        arrayRef = self.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.Aliases.Alias;
                        storeRef = self.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.Aliases.Alias;
                        break;
                    case "Payer ID":
                        arrayRef = self.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.IDAliases.Alias;
                        storeRef = self.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.IDAliases.Alias;
                        break;
                    case "Custom ID":
                        arrayRef = self.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.CustomAliases.Alias;
                        storeRef = self.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.CustomAliases.Alias;
                        break;
                    default:
                        console.log("Unknown type for Payer Alias.");
                        break;
                }

                let index = -1;
                if (arrayRef) {
                    index = arrayRef.findIndex(o => o['@ID'] == selectedPayerAlias!['@ID'] && o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);
                    if (index > -1) {
                        arrayRef[index]['@Delete'] = "true"; //from legacy: just an initial soft delete
                    }
                    else {
                        selectedPayerAlias["@Delete"] = "true"; //from legacy: just an initial soft delete
                        arrayRef.push(selectedPayerAlias);
                    }
                }
                if (storeRef) {
                    index = storeRef.findIndex(o => o['@ID'] == selectedPayerAlias!['@ID'] && o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);
                    if (index >= 0) {
                        storeRef.splice(index, 1, {
                            "@ID": selectedPayerAlias["@ID"],
                            "@reactKey": selectedPayerAlias["@reactKey"],
                            "@Name": selectedPayerAlias["@Name"],
                            "@PayerID": selectedPayerAlias["@PayerID"],
                            "@ShowInFilter": selectedPayerAlias["@ShowInFilter"],
                            "@Delete": "true"
                        }); //replace at index
                    }
                    else {
                        console.log("no object found for deleting");
                    }
                }
                let filterIndex = -1
                let refListPayerAliasFiltered = self.state.listPayerAlias?.filter(o => o['@Delete'] !== "true" || o['@ID'] == selectedPayerAlias!['@ID']);
                if (refListPayerAliasFiltered) {
                    filterIndex = refListPayerAliasFiltered.findIndex(o => o['@ID'] == selectedPayerAlias!['@ID'] && o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);
                }

                let indexCopy = -1
                let refFilteredAliasListValue = self.state.filteredAliasList?.filter(o => o['@Delete'] !== "true" || o['@ID'] == selectedPayerAlias!['@ID']);
                if (refFilteredAliasListValue) {
                    indexCopy = refFilteredAliasListValue.findIndex(o => o['@ID'] == selectedPayerAlias!['@ID'] && o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);

                }
                index = -1;
                let refFilteredAliasList = self.state.filteredAliasList;
                const removedDataUpdated = self.state.removedData
                if (refFilteredAliasList) {
                    index = refFilteredAliasList.findIndex(o => o['@ID'] == selectedPayerAlias!['@ID'] && o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);
                    removedDataUpdated.push(refFilteredAliasList[index])
                    if (refFilteredAliasList[index]['@ID'] !== '#') {
                        refFilteredAliasList[index]['@Delete'] = "true";

                    }
                    else {

                        refFilteredAliasList.splice(index, 1); //remove at index
                    }
                }
                index = -1;
                let refListPayerAlias = self.state.listPayerAlias;
                if (refListPayerAlias) {
                    index = refListPayerAlias.findIndex(o => o['@ID'] == selectedPayerAlias!['@ID'] && o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);
                    removedDataUpdated.push(refListPayerAlias[index])
                    if (refListPayerAlias[index]['@ID'] !== '#') {
                        refListPayerAlias[index]['@Delete'] = "true"; //from legacy: just an initial soft delete
                    }
                    else {
                        refListPayerAlias.splice(index, 1); //remove at index
                    }
                }

                self.setState(
                    {
                        isPageBusy: false,
                        filteredAliasList: refFilteredAliasList,
                        listPayerAlias: refListPayerAlias,
                        selectedListPayerAliasItem: refListPayerAliasFiltered?.[filterIndex - 1],
                        //selectedPayerAliasOption: refFilteredAliasListValue?.[indexCopy - 1],
                        payerAliasDelta: self.state.payerAliasDelta,
                        isPayerAliasTypeDisabled: false,
                        removedData: removedDataUpdated
                    },
                    () => {
                        self.handleChange();
                        self.props.action.ui.deletePayerAlias({
                            masterCrud: self.props.dataStore.crud.data,
                            uiData: selectedPayerAlias
                        });
                        //self.forceUpdate();
                    }
                );
                if (filterIndex === -1)
                    self.selectAliasListOption(indexCopy - 1)
                else
                    self.selectListPayerAliasItem(filterIndex - 1);
            }
        }
    }

    private payerAliasUpdate = () => {
        const newPayerAliasName = this.state.inputPayerAliasName.trim();
        const existingPayerAlias = this.state.selectedListPayerAliasItem;
        const validated = this.validatePayerAliasName(existingPayerAlias?.["@AliasType"] ?? this.state.selectedPayerAliasItemType, newPayerAliasName);
        if (validated.isValid === false && existingPayerAlias?.["@Name"] !== newPayerAliasName) {
            this.setState({
                isPageBusy: false,
            });
            console.log(validated.message);
            alert(validated.message);
            return;
        }

        let selectedPayerAlias = this.state.selectedListPayerAliasItem;
        if (selectedPayerAlias && selectedPayerAlias['@DisplayName'] !== this.text["Add Payer Alias"]) {

            const payerAliasType = this.state.selectedPayerAliasItemType;
            let updatedPayerAlias: MCPALIAS_Alias = {
                "@reactKey": `${this.state.selectedPayerOption?.["@ID"]}_0_${payerAliasType}_${this.state.inputPayerAliasName}`,
                "@ID": `${selectedPayerAlias['@ID']}`,
                "@Name": `${this.state.inputPayerAliasName}`,
                "@OriginalName": `${existingPayerAlias?.["@Name"]}`,
                "@DisplayName": `(${payerAliasType}) ${this.state.inputPayerAliasName}`,
                "@PayerID": `${selectedPayerAlias['@PayerID']}`,
                "@ShowInFilter": this.state.isShowOnClaimFilterChecked ? 'Y' : 'N',
                "@AliasType": `${payerAliasType}`,
            }

            let arrayRef: Array<MCPALIAS_Alias> | undefined;
            let storeRef: Array<MCPALIAS_Alias> | undefined;
            switch (selectedPayerAlias["@AliasType"]) {
                case "Payer Name":
                    arrayRef = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.Aliases.Alias;
                    storeRef = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.Aliases.Alias;
                    break;
                case "Payer ID":
                    arrayRef = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.IDAliases.Alias;
                    storeRef = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.IDAliases.Alias;
                    break;
                case "Custom ID":
                    arrayRef = this.state.payerAliasDelta.data.PayerAliasMaintenanceInfo.CustomAliases.Alias;
                    storeRef = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo.CustomAliases.Alias;
                    break;
                default:
                    console.log("Unknown type for Payer Alias.");
                    break;
            }

            let index = -1;
            if (arrayRef) {
                index = arrayRef.findIndex(o => o['@ID'] == selectedPayerAlias!['@ID'] && o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);
                if (index < 0) {
                    arrayRef.push(updatedPayerAlias);
                }
                else {
                    arrayRef.splice(index, 1, updatedPayerAlias); //replace at index
                }
            }
            if (storeRef) {
                index = storeRef.findIndex(o => o['@ID'] == selectedPayerAlias!['@ID'] && o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);
                if (index >= 0) {
                    storeRef.splice(index, 1, {
                        "@ID": updatedPayerAlias["@ID"],
                        "@reactKey": updatedPayerAlias["@reactKey"],
                        "@Name": updatedPayerAlias["@Name"],
                        "@OriginalName": updatedPayerAlias["@OriginalName"],
                        "@PayerID": updatedPayerAlias["@PayerID"],
                        "@ShowInFilter": updatedPayerAlias["@ShowInFilter"]
                    }); //replace at index
                }
                else {
                    console.log("no object found for updating");
                }
            }
            index = -1;
            let refFilteredAliasList = this.state.filteredAliasList;
            if (refFilteredAliasList) {
                index = refFilteredAliasList.findIndex(o => o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);
                refFilteredAliasList.splice(index, 1, updatedPayerAlias); //replace at index
            }
            index = -1;
            let refListPayerAlias = this.state.listPayerAlias;
            if (refListPayerAlias) {
                index = refListPayerAlias.findIndex(o => o['@reactKey'] == selectedPayerAlias!['@reactKey'] && o['@PayerID'] == selectedPayerAlias!['@PayerID']);
                refListPayerAlias.splice(index, 1, updatedPayerAlias); //replace at index
            }

            this.setState(
                {
                    filteredAliasList: refFilteredAliasList,
                    listPayerAlias: refListPayerAlias,
                    selectedListPayerAliasItem: updatedPayerAlias,
                    selectedPayerAliasOption: updatedPayerAlias,
                    payerAliasDelta: this.state.payerAliasDelta,
                },
                () => {
                    this.handleChange();
                    this.props.action.ui.updatePayerAlias({
                        masterCrud: this.props.dataStore.crud.data,
                        uiData: updatedPayerAlias
                    });

                    this.updatePayerAliasInformationUI(updatedPayerAlias);
                    //self.forceUpdate();
                }
            );
        }
    }

    /**
     * Business logic pulled from legacy code; used to display formatted text to user.  Formatted text
     * is the concatenated output of LOB: PatientType (claim type); SOP; Emast; a substring (single character)
     * of Emast; and any and all IDs that have values; and Notes.  (Not sure if the specific order of the IDs 
     * is required, but maintaining it from legacy just in case.)  And as usual, need "software intervention" 
     * for TypeScript...
     * @param listPayerLOB
     */
    private parsePayerLOBInfo = (listPayerLOB: Array<MCPALIAS_LOB>): Array<UIListSelectedPayerLOB> => {
        let parsedListPayerLOB: Array<UIListSelectedPayerLOB> = [];
        //to compensate for TypeScript epic fail to enforce array argument, need to do this...
        let definitelyAnArray = Array.isArray(listPayerLOB) ? listPayerLOB : [].concat(listPayerLOB);

        let formattedText: string;
        let tmpString: string; //used to check types for conditional string building of formattedText
        for (var i = 0, length = definitelyAnArray.length; i < length; i++) {
            formattedText = '';
            //form type
            tmpString = definitelyAnArray[i]["@Emast"].substring(0, 1);
            if (tmpString.toUpperCase() === "H") {
                formattedText = "HOSP";
            }
            else if (tmpString.toUpperCase() === "P") {
                formattedText = "PHYS";
            }
            //SOP
            tmpString = definitelyAnArray[i]["@SOP"];
            switch (tmpString.toUpperCase()) {
                case 'C':
                    formattedText = formattedText + " MEDICARE";
                    break;
                case 'D':
                    formattedText = formattedText + " MEDICAID";
                    break;
                case 'F':
                    formattedText = formattedText + " COMMERCIAL";
                    break;
                case 'G':
                    formattedText = formattedText + " BC/BS";
                    break;
                case 'H':
                    formattedText = formattedText + " CHAMPUS";
                    break;
                case 'I':
                    formattedText = formattedText + " OTHER";
                    break;
                default:
                    break;
            }
            //claim type (PatientType)
            tmpString = definitelyAnArray[i]["@PatientType"];
            switch (tmpString.toUpperCase()) {
                case "I":
                    formattedText = formattedText + " INPAT ";
                    break;
                case "O":
                    formattedText = formattedText + " OUTPAT ";
                    break;
                case "A": //do nothing (fallthrough intended)
                default:
                    break;
            }
            //Emast substring
            formattedText = formattedText + " " + definitelyAnArray[i]["@Emast"].substr(3, 4);
            //any IDs, and in this specific order
            if (definitelyAnArray[i]["@PayerID"].length > 0)
                formattedText = formattedText + " ID=" + definitelyAnArray[i]["@PayerID"];
            if (definitelyAnArray[i]["@NHPID"].length > 0)
                formattedText = formattedText + " NHPID=" + definitelyAnArray[i]["@NHPID"];
            if (definitelyAnArray[i]["@NEICID"].length > 0)
                formattedText = formattedText + " NEIC=" + definitelyAnArray[i]["@NEICID"]; //legacy: NEIC in string, but NEICID is attribute
            if (definitelyAnArray[i]["@NAICID"].length > 0)
                formattedText = formattedText + " NAIC=" + definitelyAnArray[i]["@NAICID"]; //legacy: NEIC in string, but NEICID is attribute
            if (definitelyAnArray[i]["@RTECPID"].length > 0)
                formattedText = formattedText + " RTECPID=" + definitelyAnArray[i]["@RTECPID"];
            if (definitelyAnArray[i]["@CPID"].length > 0)
                formattedText = formattedText + " CPID=" + definitelyAnArray[i]["@CPID"];
            if (definitelyAnArray[i]["@Note"].length > 0)
                formattedText = formattedText + " " + definitelyAnArray[i]["@Note"];

            //finally, add to array
            if (formattedText.length > 0) {
                //TypeScript doesn't do implied (string to number) conversions, so must explicitly force conversion
                parsedListPayerLOB.push({
                    value: '' + i,
                    text: formattedText
                });
            }
        }

        return parsedListPayerLOB;
    }

    private promptToSaveAllChanges = () => {
        if (this.props.ncsIsEnabled) {
            if (!this.props.ncsSkipPrompt) {
                const promptForDeletion = <div><p>{this.text.NCSPromptToSave}</p></div>;
                let modalProps: IModalConfirmationProps = { ...this.state.modalProps };
                modalProps.isOpen = true;
                modalProps.formattedMessage = promptForDeletion;
                modalProps.onConfirm = () => this.saveAllChanges(true);
                modalProps.onDeny = () => this.saveAllChanges(false);
                modalProps.okText = 'Yes';
                modalProps.cancelText = 'No';
                //
                this.setState({
                    isPageBusy: true,
                    modalProps: modalProps
                });
            }
            else {
                this.saveAllChanges(false);
            }
        }
        else {
            this.saveAllChanges(false);
        }
    }

    private scrollSelectedOptionIntoComponentView(optionIndex: number, optionValue: string, optionText: string) {
        //try {
        let selectedElement = document.querySelectorAll(`option[value="${optionValue}"]`);
        if (selectedElement) {
            let singleElement: Element | undefined; //because TypeScript...
            singleElement = selectedElement.length > 1 ? Array.from(selectedElement).find(e => e.textContent === optionText) : selectedElement[0]; //because TypeScript...
            if (singleElement) {
                let parentClientHeight = singleElement?.parentElement?.clientHeight;
                let yOffset = singleElement ? optionIndex * singleElement.clientHeight : 0;
                if (parentClientHeight && yOffset > parentClientHeight) {
                    singleElement!.parentElement!.scroll(0, yOffset);
                }
                else {
                    singleElement!.parentElement!.scroll(0, 0);
                }
            }
        }
        //} catch (error) {
        //    debugger;
        //    console.log(`optionIndex = ${optionIndex}`);
        //    console.log(`optionValue = ${optionValue}`);
        //    console.log(error);
        //}
    }

    saveAllChanges = async (saveAcrossEnterprise: boolean) => {
        this.closeModal();
        pageLeave();
        if (saveAcrossEnterprise) {
            this.props.action.crud.updateWithNcs(this.state.payerAliasDelta);
            this.redirectToHome();
        }
        else {
            await this.props.action.crud.update(this.state.payerAliasDelta);
            this.redirectToHome();
        }
    }

    /**
     * Alias list used in "By Alias" tab
     * @param selectedIndex
     */
    private selectAliasListOption = (selectedIndex: number) => {
        if (!this.state.filteredAliasList || selectedIndex < 0) {
            this.setState({
                isPageBusy: false,
            });
            return;
        }

        const filteredAliasList = this.state.filteredAliasList?.filter(o => o['@Delete'] != 'true');
        let selectedPayerAlias: MCPALIAS_Alias = filteredAliasList[selectedIndex];
        let payerList = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.PayerList?.Payer?.map(o => ({ ...o }));
        let selectedPayer = selectedPayerAlias ? payerList?.find(o => o['@ID'] == selectedPayerAlias['@PayerID']) : {} as MCPALIAS_Payer;
        let listSelectedPayerLOB = this.state.listSelectedPayerLOB
        let payer = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.PayerList?.Payer.find(p => p["@ID"] === selectedPayerAlias["@PayerID"]);
        if (selectedPayerAlias['@ID'] !== '0') {
            listSelectedPayerLOB = selectedPayer?.LOB ? this.parsePayerLOBInfo(selectedPayer.LOB) : [];
        }
        else if (this.state.selectedListPayerAliasItem !== undefined) {
            let payerId = this.state.selectedListPayerAliasItem["@PayerID"];
            payer = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.PayerList?.Payer.find(p => p["@ID"] === payerId);
        }

        if (selectedPayerAlias) {
            this.setState(
                {
                    selectedListPayerAliasItem: selectedPayerAlias,
                    selectedPayerAliasOption: selectedPayerAlias,
                    listSelectedPayerLOB: listSelectedPayerLOB,
                },
                () => {

                    this.setState(
                        {
                            selectedPayerOption: payer ?? this.state.selectedPayerOption,
                        }
                    );
                    this.updatePayerAliasInformationUI(selectedPayerAlias);
                }
            );
        }
    }

    /**
     * This is the specific subset for a selected Payer
     * @param selectedIndex
     */
    private selectListPayerAliasItem = (selectedIndex: number) => {
        if (!this.state.listPayerAlias || selectedIndex < 0) {
            this.setState({
                isPageBusy: false,
            });
            return;
        }

        let selectedPayerAlias: MCPALIAS_Alias = this.state.listPayerAlias.filter(o => o["@Delete"] != 'true')[selectedIndex];
        if (selectedPayerAlias) {
            this.setState(
                {
                    selectedListPayerAliasItem: selectedPayerAlias,
                    selectedPayerAliasOption: selectedPayerAlias,
                }
            );
            this.updatePayerAliasInformationUI(selectedPayerAlias);
        }
    }

    private selectPayerListOption = (selectedIndex: number) => {
        if (!this.state.filteredPayerList || selectedIndex < 0) {
            this.setState({
                isPageBusy: false,
            });
            return;
        }

        //this.setState({
        //    isPageBusy: true
        //});

        let selectedPayer: MCPALIAS_Payer = this.state.filteredPayerList[selectedIndex];
        let listSelectedPayerLOB = selectedPayer?.LOB ? this.parsePayerLOBInfo(selectedPayer.LOB) : [];
        //get aliases (default alias type to payer name for add alias option)
        let listPayerAlias = this.state.filteredAliasList?.filter(o => o["@PayerID"] === selectedPayer["@ID"] && o['@Delete'] != 'true')?.map(o => ({ ...o })) || []; // this.GetAliasListForPayer(selectedPayer);

        listPayerAlias.sort((o1: MCPALIAS_Alias, o2: MCPALIAS_Alias) => {
            const a = o1["@DisplayName"], b = o2["@DisplayName"];
            if (!a || !b) {
                return 0;
            }
            return a.localeCompare(b); //TO-DO: check performance of this sort to traditional sort
        });

        listPayerAlias.splice(0, 0,
            {
                "@reactKey": `0_0_${this.text["Add Payer Alias"]}`,
                '@ID': '0',
                '@Name': '',
                '@DisplayName': `${this.text["Add Payer Alias"]}`,
                '@AliasType': `${this.text.typePayerName}`,
            }
        )
        let selectedListPayerAliasIndex = 0;
        let selectedListPayerAliasItem = this.state.selectedListPayerAliasItem;
        if (selectedListPayerAliasItem) {
            selectedListPayerAliasIndex = listPayerAlias.findIndex(o => o['@PayerID'] == selectedListPayerAliasItem?.['@PayerID'] && o['@ID'] == selectedListPayerAliasItem?.['@ID'] && o['@reactKey'] == selectedListPayerAliasItem?.['@reactKey']);
            selectedListPayerAliasIndex = (!selectedListPayerAliasIndex || selectedListPayerAliasIndex < 0) ? 0 : selectedListPayerAliasIndex;
            //scroll option into view in the Select component without moving the page
            this.scrollSelectedOptionIntoComponentView(selectedListPayerAliasIndex, `${selectedListPayerAliasItem["@reactKey"]}`, `${selectedListPayerAliasItem["@DisplayName"]}`);
        }
        else {
            selectedListPayerAliasItem = listPayerAlias[0];
        }

        this.setState(
            {
                selectedPayerOption: selectedPayer,
                listPayerAlias: listPayerAlias,
                listSelectedPayerLOB: listSelectedPayerLOB,
            },
            () => {
                this.selectListPayerAliasItem(selectedListPayerAliasIndex);
            }
        );
    }

    private updatePayerAliasInformationUI = (payerAlias: MCPALIAS_Alias) => {
        let isRemovePayerAliasDisabled = true;
        let buttonActionState = this.state.btnPayerAliasActionState;
        let isPayerAliasTypeDisabled = false;

        //change options/state for...
        if (payerAlias["@DisplayName"] === this.text["Add Payer Alias"]) {
            //... "Add"
            isRemovePayerAliasDisabled = true;
            buttonActionState.name = "Add";
            buttonActionState.disabled = !this.props.canCreate; //NOT disabled if has canCreate permission
            buttonActionState.onClick = this.onClickPayerAliasAdd;
            isPayerAliasTypeDisabled = !this.props.canCreate; //NOT disabled if has canCreate permission
        }
        else {
            //... "Update"
            isRemovePayerAliasDisabled = !this.props.canDelete; //NOT disabled if has canDelete permission
            buttonActionState.name = "Update";
            buttonActionState.disabled = !this.props.canEdit; //NOT disabled if has canEdit permission
            buttonActionState.onClick = this.onClickPayerAliasUpdate;
            isPayerAliasTypeDisabled = true;
        }

        this.setState({
            isPageBusy: false,
            btnPayerAliasActionState: buttonActionState,
            isRemovePayerAliasDisabled: isRemovePayerAliasDisabled,
            selectedListPayerAliasItem: payerAlias,
            selectedPayerAliasItemType: `${payerAlias["@AliasType"]}`,
            inputPayerAliasName: `${payerAlias["@Name"]}`,
            isPayerAliasTypeDisabled: isPayerAliasTypeDisabled,
            isShowOnClaimFilterChecked: (payerAlias["@ShowInFilter"] === "Y"),
        });
    }


    //----------------------------------------------
    //event handlers

    onChangeInputPayerAliasName = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({
            inputPayerAliasName: e.target.value.toUpperCase()
        });
    }

    onChangeAliasType = (e: React.ChangeEvent<HTMLInputElement>, aliasType: string) => {
        this.setState({
            selectedPayerAliasItemType: aliasType,
            isShowOnClaimFilterChecked: this.state.isShowOnClaimFilterChecked && (aliasType === this.text.typePayerName)
        });
    }

    /**
     * Need this function because we are using some weird UICL-implemented component that requires state
     * @param e
     */
    onChangeInputSearchPayersByCPID = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            inputSearchPayersByCPID: e.target.value
        });
    }

    onChangeInputSearchPayers = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.toUpperCase();
        this.setState({
            inputSearchPayers: value
        });

        //logic from legacy code, just select first match (case insensitive)
        let payerList: Array<MCPALIAS_Payer> | undefined = this.state.filteredPayerList;
        let locatedIndex = payerList?.findIndex((item) => {
            return item["@Name"].toUpperCase().startsWith(value);
        });

        if (locatedIndex && locatedIndex > -1) {
            this.selectPayerListOption(locatedIndex);
        }
        //else error, not found
    }

    onClickCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
        //TO-DO: make sure components' props are cleared, in WillUnmount function
        this.promptToSaveChangesBeforeLeaving();
    }

    onClickClearSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({
            isPageBusy: true,
            inputSearchPayersByCPID: ''
        });
        this.refreshData();
    }

    onClickContinue = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        this.promptToSaveAllChanges();
    }

    onClickPayerAliasAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.payerAliasAdd();
    }

    onClickPayerAliasRemove = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.payerAliasRemove();
    }

    onClickPayerAliasUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.payerAliasUpdate();
    }

    /**
     * logic is from legacy: search for Payer with an LOB that has CPID that "starts-with" user input value.
     * @param e
     */
    onClickSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
        let inputSearch = document.getElementById(`${Page.Selectors.idInputSearchByCPID}`) as HTMLInputElement;
        let searchString = inputSearch.value;
        if (searchString.length < 1) {
            // clicking Search with no value entered should act the same way clicking "Clear" does:
            this.setState({
                isPageBusy: true
            });
            this.refreshData();
            return;
        }
        if (!searchString.match(/[0-9]{1,4}/)) {
            this.setState({ payersFound: true })
            return;
        }
        let inputCPID: number = parseInt(inputSearch.value);
        if (isNaN(inputCPID) || inputCPID < 1) {
            this.setState({ payersFound: true })
            return;
        }

        this.setState({ isPageBusy: true });
        let foundPayers = this.props.dataStore.crud.data?.PayerAliasMaintenanceInfo?.PayerList?.Payer?.filter((item, index) => {
            if (!item.LOB || item.LOB.length === 0) {
                return false;
            }
            if (Array.isArray(item.LOB)) {
                return (item.LOB.filter((lobItem) => {
                    return lobItem["@CPID"].startsWith(`${inputCPID}`);
                }).length > 0);
            }

            return (item.LOB["@CPID"] as string).startsWith(`${inputCPID}`);
        });
        let foundPayerAliases: Array<MCPALIAS_Alias> = []
        foundPayers?.forEach(selectedPayer => {
            let filteredAliasList = this.getPayerAliasList();
            let payerAliasesForFoundPayer = filteredAliasList?.filter(o => o["@PayerID"] === selectedPayer["@ID"] && o['@Delete'] != 'true')?.map(o => ({ ...o })) || []; // this.GetAliasListForPayer(selectedPayer);
            foundPayerAliases = foundPayerAliases.concat(payerAliasesForFoundPayer);
        })

        foundPayerAliases.splice(0, 0, {
            '@reactKey': `0_0_${this.text["Add Payer Alias"]}`,
            '@ID': '0',
            '@Name': '',
            '@DisplayName': `${this.text["Add Payer Alias"]}`,
            '@AliasType': `${this.text.typePayerName}`,
        });

        this.setState({ isPageBusy: false });

        if (!foundPayers || foundPayers.length === 0) {
            this.setState({ payersFound: true })
            return;
        }

        this.setState(
            {
                filteredPayerList: foundPayers.map(o => ({ ...o })),
                filteredAliasList: foundPayerAliases
            },
            () => this.selectPayerListOption(0)
        );
    }

    onClickShowOnClaimFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            isShowOnClaimFilterChecked: e.currentTarget.checked
        });
    }

    public onSelectTab(event: React.MouseEvent<HTMLElement>, index: number) {
        //not trusting the second parameter of the component that raised the event
        //e.preventDefault();

        let selectedTabId = event.currentTarget.id;
        let selectedIndex = this.tabs.findIndex(t => t.domID === selectedTabId);
        //clear input filter
        this.setState(
            {
                isPageBusy: true,
                inputSearchPayers: "",
                displayGroup0: selectedIndex === 0 ? "block" : "none",
                displayGroup1: selectedIndex === 1 ? "block" : "none",
            },
            () => {
                //set focus
                if (selectedIndex === 0) {
                    let selectedPayerIndex = 0;
                    let selectedPayer = this.state.selectedPayerOption;
                    if (selectedPayer) {
                        selectedPayerIndex = this.state.filteredPayerList?.findIndex(o => o["@ID"] === selectedPayer!["@ID"]) || 0;
                        selectedPayerIndex = (!selectedPayerIndex || selectedPayerIndex < 0) ? 0 : selectedPayerIndex;
                        //scroll option into view in the Select component without moving the page
                        this.scrollSelectedOptionIntoComponentView(selectedPayerIndex, `${selectedPayer["@ID"]}`, `${selectedPayer["@DisplayName"]}`);
                    }
                    this.selectPayerListOption(selectedPayerIndex);
                }
                else { //selectedIndex === 1
                    let selectedAliasIndex = 0;
                    let selectedPayerAlias = this.state.selectedPayerAliasOption;
                    if (selectedPayerAlias) {
                        const filteredAliasList = this.state.filteredAliasList?.filter(o => o['@Delete'] != 'true')
                        selectedAliasIndex = filteredAliasList?.findIndex(o => o["@PayerID"] === selectedPayerAlias!["@PayerID"] && o["@reactKey"] === selectedPayerAlias!["@reactKey"] && o["@ID"] === selectedPayerAlias!["@ID"]) || 0;
                        selectedAliasIndex = (!selectedAliasIndex || selectedAliasIndex < 0) ? 0 : selectedAliasIndex;
                        //scroll option into view in the Select component without moving the page
                        this.scrollSelectedOptionIntoComponentView(selectedAliasIndex, `${selectedPayerAlias["@reactKey"]}`, `${selectedPayerAlias["@DisplayName"]}`);
                    }
                    this.selectAliasListOption(selectedAliasIndex);
                }
            }
        )
    }

    onSelectAliasListOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedIndex = e.currentTarget.selectedIndex;
        this.setState(
            {
                isPageBusy: true,
                selectedPayerOption: undefined,
                listPayerAlias: undefined
            },
            () => this.selectAliasListOption(selectedIndex)
        );
    }

    onSelectListPayerAliasItem = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedIndex = e.currentTarget.selectedIndex;
        this.setState({
            isPageBusy: true,
        });

        this.selectListPayerAliasItem(selectedIndex);
    }

    onSelectPayerListOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedIndex = e.currentTarget.selectedIndex;
        this.setState(
            {
                isPageBusy: true,
                selectedPayerAliasOption: undefined,
                listPayerAlias: undefined
            }
        );

        this.selectPayerListOption(selectedIndex)
    }


    //----------------------------------------------
    //rendering

    renderModal = () => {
        return (
            <ModalConfirmation
                isOpen={this.state.modalProps.isOpen}
                showDefaultClose={true}
                formattedMessage={
                    <p>{this.state.modalProps.formattedMessage || this.state.modalProps.message}</p>
                }
                onModalToggle={this.closeModal}
                onOK={this.state.modalProps.onOK || this.closeModal}
                onConfirm={this.state.modalProps.onConfirm}
                onDeny={this.state.modalProps.onDeny || this.closeModal}
                alertMode={this.state.modalProps.alertMode || false}
                okText={this.state.modalProps.okText || this.text.OK}
                cancelText={this.state.modalProps.cancelText || this.text.Cancel}
            />
        );
    }

    renderPageButtons = () => {
        return (
            <>
                <Button
                    domID={Page.Selectors.idButtonContinue}
                    name={this.text.OK}
                    {...Buttons.Props.Default}
                    onClick={this.onClickContinue}
                    disabled={!this.props.canEdit}
                />
                <Button
                    domID={Page.Selectors.idButtonCancel}
                    name={this.text.Cancel}
                    {...Buttons.Props.Default}
                    onClick={this.onClickCancel}
                />
            </>
        );
    }

    renderPayerAliasInformation = (): JSX.Element => {

        //checked={this.props.dataStore.ui.searchType === "0"}
        let rowStyle: React.CSSProperties = {
            paddingBottom: "3px"
        };
        let buttonActionState: IButtonActionState = this.state.btnPayerAliasActionState;

        return (
            <DialogFieldset>
                <DialogLegend>{this.text["Payer Alias Information"]}</DialogLegend>
                <ContentRowWrapper style={rowStyle}>{/* radio button group*/}
                    <RadioButtonComponent
                        id="idPayerNameType"
                        name={Page.Selectors.namePayerAliasType}
                        label="Payer Name"
                        className="radio-button"
                        checked={this.state.selectedPayerAliasItemType === this.text.typePayerName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAliasType(e, this.text.typePayerName)}
                        disabled={this.state.isPayerAliasTypeDisabled}
                    />
                    <RadioButtonComponent
                        id="idPayerIDType"
                        name={Page.Selectors.namePayerAliasType}
                        label="Payer ID"
                        className="radio-button"
                        checked={this.state.selectedPayerAliasItemType === this.text.typePayerID}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAliasType(e, this.text.typePayerID)}
                        disabled={this.state.isPayerAliasTypeDisabled}
                    />
                    <RadioButtonComponent
                        id="idCustomIDType"
                        name={Page.Selectors.namePayerAliasType}
                        label="Custom ID"
                        className="radio-button"
                        checked={this.state.selectedPayerAliasItemType === this.text.typeCustomID}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onChangeAliasType(e, this.text.typeCustomID)}
                        disabled={this.state.isPayerAliasTypeDisabled}
                    />
                </ContentRowWrapper>
                <ContentRowWrapper style={rowStyle}>
                    <LabelStyles.Default>Payer Alias: &nbsp;</LabelStyles.Default>
                    <ContentColumnWrapper style={{ maxWidth: "67%" }}>
                        <Input
                            domID={Page.Selectors.idTextPayerAliasName}
                            className="text-input"
                            size='small'
                            value={this.state.inputPayerAliasName}
                            onChange={this.onChangeInputPayerAliasName}
                        />
                    </ContentColumnWrapper>
                </ContentRowWrapper>
                <ContentRowWrapper style={rowStyle}>
                    <CheckBoxComponent
                        id='autolist-enabled'
                        label='Show on claim filter'
                        checked={this.state.isShowOnClaimFilterChecked && (this.state.selectedPayerAliasItemType === this.text.typePayerName)}
                        disabled={this.state.selectedPayerAliasItemType !== this.text.typePayerName}
                        onChange={this.onClickShowOnClaimFilter} />
                </ContentRowWrapper>

                <CenterFormItemsStyle>
                    <ButtonGroupStyle size={"small"} justify={false}>
                        <Button
                            domID={"btnAddUpdatePayerAlias"}
                            {...Buttons.Props.Form.small}
                            name={buttonActionState.name}
                            disabled={buttonActionState.disabled}
                            onClick={buttonActionState.onClick}
                        />
                        <Button
                            domID={"btnRemovePayerAlias"}
                            name={"Remove"}
                            disabled={this.state.isRemovePayerAliasDisabled}
                            {...Buttons.Props.Form.small}
                            onClick={this.payerAliasRemove}
                        />
                    </ButtonGroupStyle>
                </CenterFormItemsStyle>

            </DialogFieldset>
        );
    }

    renderSearchButtons = (options: { disableSearch?: Boolean; }) => {

        let isSearchDisabled: boolean = false; // options.disableSearch || this.state.disableSearch

        return (
            <>
                <Button
                    domID={Page.Selectors.idButtonSearch}
                    name={this.text["Search"]}
                    disabled={isSearchDisabled}
                    {...Buttons.Props.Form.small}
                    onClick={this.onClickSearch}
                />
                <Button
                    domID={Page.Selectors.idButtonClear}
                    name={this.text["Clear"]}
                    {...Buttons.Props.Form.small}
                    onClick={this.onClickClearSearch}
                />
            </>
        );
    }


    //----------------------------------------------
    public render() {
        const helpUrl: string = `/Support/Help/HELP_Maint_AltSpelling.htm`;

        let listSelectedPayerLOB: Array<UIListSelectedPayerLOB> | undefined = this.state.listSelectedPayerLOB;

        //const mainListSize: number = 15; //component height
        let pageButtons = this.renderPageButtons();
        let searchButtons = this.renderSearchButtons({});

        let modalFragment = this.renderModal();
        //let isBusy = this.state.isPageBusy;

        let payerAliasInformation = this.renderPayerAliasInformation();

        //REMEMBER!!! React butchers standard HTML...
        return (
            <DialogWrapper title={this.text.pageTitle} instruction={this.text.footerInstructions} helpUrl={helpUrl} buttons={pageButtons} isBusy={this.state.isPageBusy} >
                <ContentWrapper>
                    <DialogFieldset>
                        <DialogLegend >{this.text["search legend title"]}</DialogLegend>
                        <ContentRowWrapper>
                            <ContentColumnWrapper style={{ maxWidth: "200px" }}>
                                {/* HTML5 pattern attribute does not appear to be supported by the ui-core to limit input to 4 digits only */}
                                <Input type="text" id={Page.Selectors.idInputSearchByCPID}
                                    maxLength={4}
                                    pattern="[0-9]{4,4}"
                                    value={this.state.inputSearchPayersByCPID || ""}
                                    onChange={this.onChangeInputSearchPayersByCPID} />
                            </ContentColumnWrapper>
                            <ContentColumnWrapper>
                                <ButtonGroupStyle size={"small"} justify={false}>{searchButtons}</ButtonGroupStyle>
                            </ContentColumnWrapper>
                        </ContentRowWrapper>
                    </DialogFieldset>
                    <br />
                    <Tabs
                        tabs={this.tabs}
                        onTabSelect={(e: React.MouseEvent<HTMLElement>, index: number) => { this.onSelectTab(e, index) }}
                        initialTab={0}
                    />
                    <ContentRowWrapper>
                        <ContentColumnWrapper style={{ maxWidth: "50%" }}>
                            {/* group 0 */}
                            <DialogFieldset name={"group_0"} style={{ display: this.state.displayGroup0 }}>
                                <DialogLegend>Payers</DialogLegend>
                                <Input id={Page.Selectors.idInputSearchPayers}
                                    {...DefaultProps.InputComponent}
                                    value={this.state.inputSearchPayers || ""}
                                    onChange={this.onChangeInputSearchPayers}
                                /> { /* ... REMEMBER THAT REACT BUTCHERS HTML */}
                                <SelectComponent
                                    title={Page.Selectors.idSelectPayers /* title is assigned to both id and name atrtibutes of the rendered element */}
                                    {...DefaultProps.SelectComponent}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@DisplayName"
                                    }}
                                    records={this.state.filteredPayerList}
                                    onSelect={this.onSelectPayerListOption}
                                    selectedValue={this.state.selectedPayerOption?.["@ID"]}
                                ></SelectComponent>
                            </DialogFieldset>

                            {/* group 1 */}
                            <DialogFieldset name={"group_1"} style={{ display: this.state.displayGroup1 }}>
                                <DialogLegend>Payer Aliases</DialogLegend>
                                <Input
                                    {...DefaultProps.InputComponent}
                                    disabled={true} />
                                <SelectComponent
                                    {...DefaultProps.SelectComponent}
                                    optionFields={{
                                        value: "@reactKey",
                                        text: "@DisplayName"
                                    }}
                                    records={this.state.filteredAliasList?.filter(o => o['@Delete'] != 'true')}
                                    onSelect={this.onSelectAliasListOption}
                                    selectedValue={this.state.selectedPayerAliasOption?.["@reactKey"]}
                                ></SelectComponent>
                            </DialogFieldset>
                        </ContentColumnWrapper>
                        <ContentColumnWrapper>
                            <div style={{ position: "relative", height: "100%" }}>
                                <div>
                                    {/* group 0 */}
                                    <DialogFieldset name={"group_0"} style={{ display: this.state.displayGroup0 }}>
                                        <DialogLegend>Payer Alias</DialogLegend>
                                        <SelectComponent
                                            size={16}
                                            width='100%'
                                            multiple='false'

                                            optionFields={{
                                                value: "@reactKey",
                                                text: "@DisplayName"
                                            }}
                                            records={this.state.listPayerAlias?.filter(o => o['@Delete'] != 'true')}
                                            onSelect={this.onSelectListPayerAliasItem}
                                            selectedValue={this.state.selectedListPayerAliasItem?.["@reactKey"]}
                                        ></SelectComponent>
                                    </DialogFieldset>

                                    {/* group 1 */}
                                    <DialogFieldset name={"group_1"} style={{ display: this.state.displayGroup1 }}>
                                        <DialogLegend>Payer Name</DialogLegend>
                                        <Input type="text" id={Page.Selectors.idTextboxPayerName}
                                            readOnly="readonly"
                                            value={this.state.selectedPayerOption?.["@DisplayName"] || ''}
                                        />
                                    </DialogFieldset>
                                </div>

                                <div style={{ position: "absolute", bottom: "0", right: "0", left: "0" }}>
                                    {payerAliasInformation}
                                </div>
                            </div>
                        </ContentColumnWrapper>
                    </ContentRowWrapper>

                    <DialogFieldset>
                        <DialogLegend>Payer Information</DialogLegend>
                        <SelectComponent
                            size={3}
                            width='100%'
                            multiple='false'
                            optionFields={{
                                value: "value",
                                text: "text"
                            }}
                            records={listSelectedPayerLOB}
                        ></SelectComponent>
                    </DialogFieldset>
                </ContentWrapper>
                <ModalConfirmation
                    isOpen={this.state.payersFound}
                    alertMode
                    onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.setState({ payersFound: !this.state.payersFound })}
                    formattedMessage={(
                        <div>
                            <p>No Matching Payers found</p>
                        </div>)
                    }
                    message={this.props.dataStore.confirm.message}
                    onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.setState({ payersFound: !this.state.payersFound })}
                />
                {modalFragment}
            </DialogWrapper>

        );
    }
}


var connectedHoc = connect<IPayerAliasState, IPayerAliasActionProps, IOwnProps, IPayerAliasProps, ApplicationState>(
    createCrudMapStateToProps('payerAlias'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(PayerAlias);
export default withRouter(connectedHoc);

/*
 * <ModalLoading isOpen={isBusy} />
 */