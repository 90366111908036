import { Action, Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import { Select } from '../../components/common/FunctionComponents';

// -- Mc = MasterCRUD
//
// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IFileTypeUIState {
    FileTypeList: ISelectFileTypeUIData;

    selectedActionItem: IMCFileType;
    selectedPrinterItem: MCFileTypePrinter;

    selectedFileTypeAction?: string;
    selectedFileTypePrinter?: string;
    selectedDestination?: string;

    changed: boolean;

    printerItemError: string;
    isPrinterRequiredModalShow: boolean;
}

interface IFileTypeUIUpdate {
    value: string;
}

interface IMCFileType {
    '@ID': string;
    '@Name': string;
    '@Extension': string;
    '@ReportActionID': string;
    '@PrinterID': string;
    '@Destination': string;
}

interface IPrinterUIError {
    value: string;
    id?: string;
}

export interface ISelectFileTypeUIData {
    index: number;
    id: string;
    text: string;
};

export const defaultFileTypeUIData: ISelectFileTypeUIData = {
    index: 0,
    id: '',
    text: ''
}

const emptyItem: MCFileTypeList = {
    ReportActionList: { ReportAction: [] },
    PrinterList: { Printer: [] },
    FileSettings: { FileSetting: [] },
};

const emptyFileType: MCFileSetting = {
    '@ID': '',
    '@Name': '',
    '@Extension': '',
    '@ReportActionID': '- Select Printer -',
    '@PrinterID': '',
    '@Destination': '',
};

const emptyAction: MCReportAction = {
    '@ID': '',
    '@Name': '',
};

const emptyPrinter: MCFileTypePrinter = {
    '@ID': '',
    '@Name': '',
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectFileType extends ICrudActionData<MCFileTypeSettingsMaintenanceInfo, ISelectFileTypeUIData> { }
export interface IFileTypeUpdateAction extends ICrudActionData<MCFileTypeSettingsMaintenanceInfo, IFileTypeUIUpdate> { }
export interface IError extends ICrudActionData<MCAutoListType, IPrinterUIError> { }

// can't add or remove groups from this page: driven from GroupMaster
export const actionCreators = {
    selectFileType: (selectFileType: ISelectFileType) => createDataAction('SELECT_FILE_TYPE', selectFileType),

    selectFileTypeAction: (fieldInfo: IFileTypeUpdateAction) => createDataAction('SELECT_FILETYPE_ACTION', fieldInfo),
    selectFileTypePrinter: (fieldInfo: IFileTypeUpdateAction) => createDataAction('SELECT_FILETYPE_PRINTER', fieldInfo),
    updateFileTypeDestination: (fieldInfo: IFileTypeUpdateAction) => createDataAction('UPDATE_FILETYPE_DESTINATION', fieldInfo),
    updateCrudData: (fieldInfo: IFileTypeUpdateAction) => createDataAction('UPDATE_FILETYPE_CRUD', fieldInfo),
    errorPrinterField: (fieldInfo: IError) => createDataAction('ERROR_PRINTER_ITEM_FIELD', fieldInfo),
    openPrinterRequiredModal: (fieldInfo: IError) => createDataAction('OPEN_PRINTER_REQUIRED_MODAL', fieldInfo)
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IFileTypeUIState = {
    FileTypeList: defaultFileTypeUIData,
    selectedActionItem: emptyFileType,
    selectedPrinterItem: emptyPrinter,

    selectedFileTypeAction: '',
    selectedFileTypePrinter: '',
    selectedDestination: '',

    changed: false,

    printerItemError: '',
    isPrinterRequiredModalShow: false
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IFileTypeUIState, KnownActions> = (state: IFileTypeUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'UPDATE_FILETYPE_CRUD':
                if (action.data.masterCrud &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting) {
                    let selectedFileType = action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting[state.FileTypeList.index];
                    if (selectedFileType != null)
                    {
                        selectedFileType['@ReportActionID'] = state.selectedActionItem['@ID'];
                        selectedFileType['@PrinterID'] = state.selectedPrinterItem != null ? state.selectedPrinterItem['@ID'] : '';
                        selectedFileType['@Destination'] = state.selectedDestination !== undefined ? state.selectedDestination : '';
                    }
                }
                break;
            case 'SELECT_FILE_TYPE':
                if (action.data.masterCrud &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings && 
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting) {

                    let selectedFileType;
                    if (action.data.uiData.index && action.data.uiData.index > -1) {
                        selectedFileType = action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting[action.data.uiData.index];
                    } else {
                        selectedFileType = action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting[0];
                    }

                    if (selectedFileType) {
                        let reportActionId = selectedFileType['@ReportActionID'];
                        let printerId = selectedFileType['@PrinterID'];
                        let destinationPath = selectedFileType['@Destination'];
                        console.log('reportActionID[' + reportActionId + '] printerId[' + printerId + '] destinationPath[' + destinationPath + ']');

                        let selectedAction = action.data.masterCrud.FileDownloadMaintenanceInfo.ReportActionList.ReportAction.find(ob => { return ob["@ID"] === reportActionId });
                        let selectedPrinter;
                        if (printerId) {
                            selectedPrinter = action.data.masterCrud.FileDownloadMaintenanceInfo.PrinterList.Printer.find(ob => { return ob["@ID"] === printerId });
                        }
                        

                        return {
                            ...state,
                            FileTypeList: action.data.uiData,
                            selectedActionItem: selectedAction !== undefined ? JSON.parse(JSON.stringify(selectedAction)) : JSON.parse(JSON.stringify(emptyAction)),
                            selectedPrinterItem: selectedPrinter !== undefined ? JSON.parse(JSON.stringify(selectedPrinter)) : JSON.parse(JSON.stringify(emptyPrinter)),
                            selectedDestination: destinationPath
                            }
                    }
                } else { console.log("FileType::Reducer::SELECT_FILE_TYPE - data.masterCrud=undefined"); }
                break;
            case 'SELECT_FILETYPE_ACTION':
                console.log('FileTypeUI: SELECT_FILETYPE_ACTION');
                if (action.data.masterCrud &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting) {

                    let selectedAction = action.data.masterCrud.FileDownloadMaintenanceInfo.ReportActionList.ReportAction.find(ob => { return ob["@ID"] === action.data.uiData.value });
                    //console.log('setting selectedActionItem[TriggerEventID] to ' + action.data.uiData.value + ' from ' + state.selectedActionItem['@TriggerEventID']);                        let enablePrinter = selectedAction?.['@Name'].indexOf("Print") > -1 ?
                    let enablePrinter = null;
                    if (state.selectedActionItem['@ReportActionID'] !== action.data.uiData.value) {
                        if (selectedAction != null) {
                            let selectedFileType = action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting[state.FileTypeList.index];
                            if (selectedFileType != null) {
                                selectedFileType['@ReportActionID'] = selectedAction['@ID'];
                                selectedFileType['@Destination'] = '';
                            }
                            return {
                                ...state,
                                FileTypeList: state.FileTypeList,
                                selectedActionItem: selectedAction !== undefined ? JSON.parse(JSON.stringify(selectedAction)) : JSON.parse(JSON.stringify(emptyAction)),
                                selectedPrinterItem: selectedAction['@Name'].indexOf('Print') > -1 ? state.selectedPrinterItem : JSON.parse(JSON.stringify(emptyPrinter)),  //enablePrinter !== undefined ? JSON.parse(JSON.stringify(enablePrinter)) :
                                selectedDestination: selectedAction['@Name'].indexOf('Download') > -1 ? state.selectedDestination : '',
                                changed: true,
                                printerItemError: ''
                            }
                        } 
                    }
                }
                break;
            case 'SELECT_FILETYPE_PRINTER':
                console.log('FileTypeUI: SELECT_FILETYPE_ACTION');
                if (action.data.masterCrud &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.PrinterList &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.PrinterList.Printer) {
                   
                    let selectedPrinter = action.data.masterCrud.FileDownloadMaintenanceInfo.PrinterList.Printer.find(ob => { return ob["@ID"] === action.data.uiData.value });
                    
                    if (state.selectedPrinterItem['@ID'] !== action.data.uiData.value) {
                        let selectedFileType = action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting[state.FileTypeList.index];
                        if (selectedFileType != null) {
                            selectedFileType['@PrinterID'] = action.data.uiData.value;
                        }
                        if (selectedPrinter != null)                            
                            return {
                                ...state,
                                selectedPrinterItem : selectedPrinter !== undefined ? JSON.parse(JSON.stringify(selectedPrinter)) : JSON.parse(JSON.stringify(emptyPrinter)),                               
                                changed: true                               
                            }
                    }
                }
                break;
            case 'UPDATE_FILETYPE_DESTINATION':
                if (action.data.masterCrud &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings &&
                    action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting) {
                    if (state.selectedDestination !== action.data.uiData.value) {
                        let selectedFileType = action.data.masterCrud.FileDownloadMaintenanceInfo.FileSettings.FileSetting[state.FileTypeList.index];
                        if (selectedFileType != null) {
                            selectedFileType['@Destination'] = action.data.uiData.value;
                        }
                        return {
                            ...state,
                            selectedDestination: action.data.uiData.value,
                            changed: true
                        }
                    }
                }
                
                break;
            case 'ERROR_PRINTER_ITEM_FIELD':
                switch (action.data.uiData.id) {
                    case 'printerItemError':
                        return {
                            ...state,
                            printerItemError: action.data.uiData.value,
                        }
                        break;
                }
                break;
            case 'OPEN_PRINTER_REQUIRED_MODAL':
                return {
                    ...state,
                    isPrinterRequiredModalShow: action.data.uiData.value === 'true' ? true : false
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}
