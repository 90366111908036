import { combineReducers } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ManageCrossovers from './ui/ManageCrossoversUI'
import { CrudTypes } from '@commonResources/CrudTypes';
import { isPlainObject, has } from 'lodash';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';

export type IManageCrossoversState = ICrudComponentState<MCCrossoverPayerManagement, ManageCrossovers.IManageCrossoversUIState>;

export const actionCreators = createCrudComponentActions<ManageCrossovers.ActionCreators>(ManageCrossovers.actionCreators);
export type IManageCrossovers = typeof actionCreators;
export type IManageCrossoversActionProps = ICrudComponentActionProps<IManageCrossovers>

export const reducers = createCrudComponentReducers(CrudTypes.mctiParallonManageCrossovers, ManageCrossovers.manageCrossoversUIReducer);
export const reducer = combineReducers<IManageCrossoversState>(reducers);

export const validationCallback: ValidationCallback<MCCrossoverPayerManagement> = (crud: any): crud is MCCrossoverPayerManagement => {
    let testCrud: MCCrossoverPayerManagement = crud as MCCrossoverPayerManagement;
    let validShape: boolean = isPlainObject(testCrud) && has(testCrud, 'CrossOverPayerManagement');
    if (validShape) {
        VerifyParentAndArray(testCrud.CrossOverPayerManagement, "PayerList", "Payer");
        VerifyParentAndArray(testCrud.CrossOverPayerManagement, "EnabledPayers", "EnabledPayer");
    }

    return validShape;
}