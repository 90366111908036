import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as PcgPayerMaintenanceStore from './api/PcgPayerMaintenanceStore';
import CrudTypes from '../commonResources/CrudTypes';

export type IPcgPayerMaintenanceState = ICrudComponentState<any, PcgPayerMaintenanceStore.IPayerMaintainceUIState>;

export const actionCreators = createCrudComponentActions<PcgPayerMaintenanceStore.ActionCreators>(PcgPayerMaintenanceStore.actionCreators);
export type IPcgPayerMaintenanceActions = typeof actionCreators;
export type IPcgPayerMaintenanceActionProps = ICrudComponentActionProps<IPcgPayerMaintenanceActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctipcgPayerMaintenance, PcgPayerMaintenanceStore.reducer);
export const reducer = combineReducers<IPcgPayerMaintenanceState>(reducers);
