import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IMergeApiComponentProps, createApiMapStateToProps, createApiMapDispatchToProps, mergeApiComponentProps, resetApiComponentState } from '@scripts/util/ApiDataHelpers';
import { IClaimFilterState, IClaimFilterActionProps, actionCreators} from '@store/ClaimFilter';
import { ClaimFilter, IClaimFilterComponentProps } from './ClaimFilter';

enum DeleteClaimsSecurityBits {
    FN_DELETE_CLAIMS_UNRELEASED,
    FN_DELETE_CLAIMS_RELEASED
}

interface IComponentProps {
    accessList: Array<boolean>
}

export interface IDeleteClaimFilterComponentState {};

export const DEFAULT_STATE: IDeleteClaimFilterComponentState = {};

type IOwnProps = IComponentProps & IClaimFilterComponentProps & RouteComponentProps<{}>;

type IClaimFilterProps = IMergeApiComponentProps<IClaimFilterState, IClaimFilterActionProps, IOwnProps>;

export class DeleteClaimFilter extends React.Component<IClaimFilterProps, IDeleteClaimFilterComponentState>
{
    constructor(props: IClaimFilterProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }
    }

    public componentWillUnmount() {
        resetApiComponentState(this.props.action, this.props.dataStore);
    }

    public onOk = (baseThis: any, param1: any) : boolean => {
        return true;
    }

    public render() {
        let parameterValue = this.props.history.location.state.parameter ? this.props.history.location.state.parameter : '';
        let accessList = this.props.accessList ? this.props.accessList : undefined;
        let modifiedProps: IClaimFilterComponentProps =
        {
            canView: this.props.canView,
            canEdit: this.props.canEdit,
            canCreate: this.props.canCreate,
            ncsIsEnabled: this.props.ncsIsEnabled,
            process: "delete",
            execNode: "MultiClaim",
            execAction: "DeleteClaims",
            execProcess: "Delete",
            parameter: "",
            apiType: "ClaimFilter",
            title: "Delete Claims",
            executeJS: undefined,
            extraInstruction: "",
            canDeleteUnreleased: this.props.canDeleteUnreleased,
            canDeleteReleased: this.props.canDeleteReleased
        };
        if (accessList && accessList.length > 0) {
            modifiedProps.canDeleteUnreleased = accessList[DeleteClaimsSecurityBits.FN_DELETE_CLAIMS_UNRELEASED];
            modifiedProps.canDeleteReleased = accessList[DeleteClaimsSecurityBits.FN_DELETE_CLAIMS_RELEASED];
            modifiedProps.canEdit = modifiedProps.canDeleteReleased || modifiedProps.canDeleteUnreleased;

            let allProps = { ...this.props, ...modifiedProps }

            return (
                <div>
                    <ClaimFilter {...allProps} parameter={parameterValue} executeJS={this.onOk} />
                </div>

            );
        }
        else return null;
    }
};

var connectedHoc = connect<IClaimFilterState, IClaimFilterActionProps, IOwnProps, IClaimFilterProps, ApplicationState>(
    createApiMapStateToProps('claimFilter'),
    createApiMapDispatchToProps(actionCreators),
    mergeApiComponentProps
)(DeleteClaimFilter);

export default withRouter(connectedHoc);
