import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Input, Button, Typography } from 'ui-core';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import { SecurityBits } from '@commonResources/SecurityFunctionList';
import styled from 'styled-components';

import { DialogWrapper, OKCancelButtons } from '@common/DialogWrapper';
import { SelectComponent } from '@common/SelectComponent';
import { ArrowButtons } from '@common/ArrowButtons';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';

import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IFieldLevelSecurityState, IFieldLevelSecurityActionProps, actionCreators } from '@store/FieldLevelSecurity';
import { defaultState as defaultUIState } from '@store/ui/FieldLevelSecurityUI';
import { AuthCheck } from '../../../scripts/session/Security';
import { ARMComponentLoading } from '@components/LandingPage/ARMComponentLoading';

export const ContentRowWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const ContentColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
`;

export const ContentArrowColumnWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    width: 34px
`;

export const SelectActions = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;

    #Groups {
        height: 100%;
    }
    .text-input {
        width: 335px;
    }
    #AllFields {
        height: 410px;
        margin: 5px;
    }
    #ReadOnlyFields {
        height: 410px;
        margin: 5px;
    }
    #lblAllfields {
        font-size: 12px;
        font-weight: bold;
        margin-right: 289px;
        margin-left: 8px;
        margin-bottom: 0px;
    }
    #lblReadOnlyfields {
        font-size: 12px;
        font-weight: bold;
    }
    #rightArrow{
        padding: 0px 4px;
    }
    #leftArrow{
        padding: 0px 4px;
    }
`;

export const SelectButtons = styled.div`
    display: flex;
    flex: 0;
    flex-direction: column;
    padding-top: 65px;

    button {
        margin-left: 2px;
        width: 45px;
    }
    
`;

export const DialogFieldset = styled.fieldset`
    width: initial;
    border: 1px solid ${Colors.darkGrey};
    padding: 4px;
    margin: 5px;

    #FieldLevelSecurityGroups {
        height: 485px;
    }
`;

export const DialogColumnFieldset = styled.fieldset`
    width: 675px;
    border: 1px solid ${Colors.darkGrey};
    padding: 4px;
/*
    #AllFields {
        flex: 0 0 50%;
        margin: 5px;
    }
    #ReadOnlyFields {
        flex: 1;
        margin: 5px;
    }
*/
`;

export const DialogLegend = styled.legend`
    width: initial;
    border: initial;
    margin-bottom: initial;
    color: ${Colors.darkGrey};
    ${Typography.medium};
    ${Typography.ARMFontFamily};
`;

export const DialogLabel = styled.div`
`;

export const InputBox = styled.div`
display:flex;
.text-input {
  display: flex;
  width: 315px;
}
label{
  width:40%;
  margin-top:9px;
}

`;

interface IComponentProps {
    secCheck1: boolean,
    canEdit: boolean,
    canView: boolean,
    title: string,
};

interface IComponentState {
    isLoading: boolean
}

export const DEFAULT_STATE: IComponentState = { isLoading: true };

type IOwnProps = IComponentProps & RouteComponentProps<{}>;
type IFieldLevelSecurityProps = IMergeCrudComponentProps<IFieldLevelSecurityState, IFieldLevelSecurityActionProps, IOwnProps>;

/*
 * class definition...
 *
 */
export class FieldLevelSecurity extends React.Component<IFieldLevelSecurityProps, IComponentState> {

    //var currentGroupIndex = 0;

    static defaultProps: IComponentProps = {
        secCheck1: false, //AuthCheck(SecurityBits.FN_EDIT_GROUPS),
        canEdit: false,//  AuthCheck(SecurityBits.FN_EDIT_FIELDLEVELSECURITY),
        canView: false, // AuthCheck(SecurityBits.FN_VIEW_FIELDLEVELSECURITY),
        title: "Field Level Security",
    };

    constructor(props: IFieldLevelSecurityProps) {
        super(props);
        this.state = DEFAULT_STATE;
    }

    private setInitialLoad() {
        // timeout needed or do we find another way...
        setTimeout(() => {
            this.props.action.ui.selectFlsGroup(
                {
                    masterCrud: this.props.dataStore.crud.data,
                    uiData: {
                        index: 0,
                        id: '',
                        name: ''
                    }
                });
            this.setState({ isLoading: false });
        }, 4000);
    }

    public componentWillMount() {
        //this.props.action.crud.get({ crudId: CrudTypes.mctiFieldLevelSecurity });
        this.setState({ isLoading: true });
        console.log("FLS : WILL Mount");
        this.setInitialLoad();
    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('FLS: user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }

        this.props.action.crud.get({ crudId: CrudTypes.mctiFieldLevelSecurity });
        console.log("FLS : DID Mount");
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    private initFieldLevelSecurityGroups() {
        this.props.action.ui.selectFieldLevelSecurityGroup(
            {
                index: 0,
                id: '',
                name: ''
            });
    }

    public onSelectFieldLevelSecurityGroup(e: React.ChangeEvent<HTMLSelectElement>) {
        //this.currentGroupIndex = e.target.options.selectedIndex;
        this.props.action.ui.selectFlsGroup(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    index: e.target.options.selectedIndex,
                    id: e.target.value,
                    name: e.target.options[e.target.options.selectedIndex].textContent
                }
            });
    }

    public onSelectFieldLevelSecurityAllFields(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectFlsAllFields(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selected: [].slice.call(e.target.selectedOptions).map((o: any) => { return o.value; }),
                }
            });
        //alert('SelectAllFields: uiData.index[' + e.target.options.selectedIndex + '] uiData.id[' + e.target.value + '] uiData.name[' + e.target.options[e.target.options.selectedIndex].textContent + ']');
    }

    public onSelectFieldLevelSecurityReadOnlyFields(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.action.ui.selectFlsReadOnly(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: {
                    selected: [].slice.call(e.target.selectedOptions).map((o: any) => { return o.value; }),
                }
            });
        //alert('SelectReadOnly: I have selected an ReadOnlyFields...');
    }

    public onAddFlsFields(e: React.ChangeEvent<HTMLButtonElement>) {
        //console.log('RIGHT arrow click!');

        this.props.action.ui.addFlsFields(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: true,
            });
    }

    public onRemoveFlsFields(e: React.ChangeEvent<HTMLButtonElement>) {
        //console.log('LEFT arrow click!');

        this.props.action.ui.removeFlsFields(
            {
                masterCrud: this.props.dataStore.crud.data,
                uiData: true,
            });
    }

    public getFieldLevelSecuritySelectedChoicesArrayItem(ob: any): MCFLSSelectedField[] {
        if (ob.constructor === Array)
            return ob;
        else {
            if (ob && ob !== undefined) {
                let arr = new Array<MCFLSSelectedField>();
                arr.push(ob);
                return arr;
            }
        }
        return ob;
    }

    /*
        removes fields being used from fieldlist...
    */
    public getAllFieldsFiltered(): MCFLSField[] {
        let result: MCFLSField[] = [];
        console.log('getAllFieldsFiltered()');

        //var allFields = props.dataStore.crud.data ? flsAllFieldsItem.concat(this.props.dataStore.crud.data.FieldLevelSecurity.FieldList.Field) : flsAllFieldsItem;

        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.FieldLevelSecurity) return [];

        /*
        if (this.props.dataStore.ui.FlsROFields) {
            console.log('1');
            if (this.props.dataStore.ui.FlsROFields.Fields) {
                console.log('2');
                if (this.props.dataStore.ui.FlsROFields.Fields.Field) {
                    console.log('3');
                    if (this.props.dataStore.ui.FlsROFields.Fields.Field.length > 0) {
                        console.log('4');
                    } else {
                        console.log('NOT 4');
                    }
                } else {
                    console.log('NOT 3');
                }
            } else {
                console.log('NOT 2');
            }
        } else {
            console.log('NOT 1');
        }
        
        if (this.props.dataStore.ui.FlsAllFields) {
            console.log('5');
            if (this.props.dataStore.ui.FlsAllFields.Fields) {
                console.log('6');
                if (this.props.dataStore.ui.FlsAllFields.Fields.Field) {
                    console.log('7');
                    if (this.props.dataStore.ui.FlsAllFields.Fields.Field.length > 0) {
                        console.log('8');
                    } else {
                        console.log('NOT 8');
                    }
                } else {
                    console.log('NOT 7');
                }
            } else {
                console.log('NOT 6');
            }
        } else {
            console.log('NOT 5');
        }
        */

        if (this.props.dataStore.crud.data.FieldLevelSecurity.FieldList) {
            console.log('8');
            if (this.props.dataStore.crud.data.FieldLevelSecurity.FieldList.Field.length)
                console.log('9');
            else
                console.log('NOT 9');
        } else {
            console.log('NOT 8');
        }

        if (this.props.dataStore.crud.data.FieldLevelSecurity.FieldList)
            result = this.props.dataStore.crud.data.FieldLevelSecurity.FieldList.Field;
        
        if (this.props.dataStore.ui.FlsROFields &&
            this.props.dataStore.ui.FlsROFields.Fields &&
            this.props.dataStore.ui.FlsROFields.Fields.Field) {

            if (this.props.dataStore.ui.FlsROFields.Fields.Field.length === undefined) {
                let fieldData = JSON.parse(JSON.stringify(this.props.dataStore.ui.FlsROFields?.Fields?.Field));
                let fieldId = Number(fieldData['@ID']);
                console.log('getAllFieldsFiltered: removing selectedID[' + fieldId + ']');

                result = result.filter(function (fieldItem) { return Number(fieldItem['@ID']) != fieldId; });
            }
            else {
                console.log('getAllFieldsFiltered: all in!');

                this.props.dataStore.ui.FlsROFields?.Fields?.Field?.forEach(function (fieldObj: any) {
                    let fieldId = Number(fieldObj['@ID']);
                    console.log('getAllFieldsFiltered: removing selectedID[' + fieldId + ']');

                    result = result.filter(function (fieldItem) { return Number(fieldItem['@ID']) != fieldId; });
                });
            }
        }

        return result;
    }

    /*
        gets fields from fieldlist...
    */
    public getReadOnlyFields(): MCFLSSelectedField[] {

        let result: MCFLSSelectedField[] = [];
        console.log('getReadOnlyFields()');
        if (!this.props.dataStore.crud.data ||
            !this.props.dataStore.crud.data.FieldLevelSecurity) return [];

        let inArray: string[] = [];
        console.log("Data", this.props.dataStore.ui?.FlsROFields?.Fields?.Field);
        if (this.props.dataStore.ui.FlsROFields &&
            this.props.dataStore.ui.FlsROFields.Fields &&
            this.props.dataStore.ui.FlsROFields.Fields.Field &&
            this.props.dataStore.crud.data.FieldLevelSecurity.FieldList &&
            this.props.dataStore.crud.data.FieldLevelSecurity.FieldList.Field.length > 0) {

            let fieldList = this.props.dataStore.crud.data.FieldLevelSecurity.FieldList.Field;
            if (this.props.dataStore.ui?.FlsROFields?.Fields?.Field !== undefined && typeof (this.props.dataStore.ui?.FlsROFields?.Fields?.Field) !== "object") {
                let FieldsData = JSON.parse(JSON.stringify(this.props.dataStore.ui?.FlsROFields?.Fields?.Field));
                FieldsData.forEach((fieldObj: any) => {
                    let fieldId = Number(fieldObj['@ID']);
                    console.log('getReadOnlyFields: selectedID[' + fieldId + ']');

                    let itemFound = fieldList.find((existingField) => Number(existingField["@ID"]) === fieldId);
                    if (itemFound) {
                        result.push(itemFound);
                    }
                });
            }
            else {
                if (this.props.dataStore.ui?.FlsROFields?.Fields?.Field.length === undefined) {
                    let FieldsData = JSON.parse(JSON.stringify(this.props.dataStore.ui?.FlsROFields?.Fields?.Field));
                    let fieldId = Number(FieldsData['@ID']);
                    console.log('getReadOnlyFields: selectedID[' + fieldId + ']');

                    let itemFound = fieldList.find((existingField) => Number(existingField["@ID"]) === fieldId);
                    if (itemFound) {
                        result.push(itemFound);
                    }
                }
                else {
                    let FieldsData = JSON.parse(JSON.stringify(this.props.dataStore.ui?.FlsROFields?.Fields?.Field));

                    FieldsData.forEach((fieldObj: any) => {
                        let fieldId = Number(fieldObj['@ID']);
                        console.log('getReadOnlyFields: selectedID[' + fieldId + ']');

                        let itemFound = fieldList.find((existingField) => Number(existingField["@ID"]) === fieldId);
                        if (itemFound) {
                            result.push(itemFound);
                        }
                    });
                }
            }
        }

        return result;
    }

    public onOK(e: React.ChangeEvent<HTMLButtonElement>) {

        //pass delta
        this.props.action.crud.update({ crudId: CrudTypes.mctiFieldLevelSecurity, data: this.props.dataStore.ui.deltaCrud });
        const location = {
            pathname: '/LandingPage',
            state: { fromDashboard: true }
        }

        this.props.history.push('/LandingPage');
    }

    public onCancel(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    public render() {
        var helpButtons = <OKCancelButtons disableOK={!this.props.dataStore.ui.changed || !this.props.canEdit} onClickOK={(e: React.ChangeEvent<HTMLButtonElement>) => this.onOK(e)} onClickCancel={(e: React.ChangeEvent<HTMLButtonElement>) => this.onCancel(e)} />;
        var instruction = <React.Fragment>This is part of setting up field level security. Users cannot edit fields that are set to read-only for their group. Select a group and choose the fields that should be read-only for this group.</React.Fragment>;
        var allFields = <React.Fragment>All Fields:</React.Fragment>;
        var readOnlyFields = <React.Fragment>Read-Only for this Group:</React.Fragment>;

        var flsAllFieldsItem: MCFLSField[] = [];
        var flsGroupItem: MCFLSGroup[] = [];
        var flsSelectedItem: MCFLSSelectedField[] = [];

        let flsGroups, flsAllFields;
        if (typeof this.props.dataStore.crud.data == 'undefined' ||
            !this.props.dataStore.crud.data.FieldLevelSecurity ||
            !this.props.dataStore.crud.data.FieldLevelSecurity.Groups ||
            !this.props.dataStore.crud.data.FieldLevelSecurity.Groups.Group) {
            console.log('render: empty list...');
            flsAllFieldsItem.concat([{ '@ID': '', '@Name': '' }]);
            flsGroupItem.concat([{ '@ID': '', '@Name': '', Fields: { Field: [] } }]);
            flsSelectedItem.concat([{ '@ID': '', '@Name': '' }]);
        } else {
            console.log('render: getting data...');
            flsGroups = this.props.dataStore.crud.data ? flsGroupItem.concat(this.props.dataStore.crud.data.FieldLevelSecurity.Groups.Group) : flsGroupItem;
            flsSelectedItem = this.getReadOnlyFields();
            flsAllFields = this.getAllFieldsFiltered();
            //this.props.dataStore.ui.selectedAllFieldsFieldId = '-1'; // all done, clear selected...
        }

        return (
            <DialogWrapper title={this.props.title} width='975px' instruction={instruction} helpUrl='/Support/Help/HELP_Maint_SetupFieldSecurity.htm' buttons={helpButtons} isBusy={!this.props.dataStore.crud.data || this.state.isLoading}>
                <ContentRowWrapper>
                    <DialogFieldset>
                        <DialogLegend>Groups</DialogLegend>
                        <SelectComponent
                            title='FieldLevelSecurityGroups'
                            size={10}
                            width='250px'
                            multiple='false'
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFieldLevelSecurityGroup(e)}
                            optionFields={{
                                value: "@ID",
                                text: "@Name"
                            }}
                            records={flsGroups}
                            selectedValue={this.props.dataStore.ui.FlsGroupList.value}
                        // selectedValue, check if null, if so, return an empty value, e.g. : this || ''
                        >
                        </SelectComponent>
                    </DialogFieldset>
                    <SelectActions>
                        <InputBox>
                            <Input
                                domID="fls-group-name"
                                className="text-input"
                                label="Group Name:"
                                disabled={true}
                                initialValue={this.props.dataStore.ui.flsGroupName}
                            //onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateFlsGroup(e.target.value)}
                            //deferState={{ value: this.state.username }}
                            //onChange=={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.CHANGENOTPOSSIBLEONTHISFIELD(e.target.value)}
                            //disabled={this.state.disabled}
                            //initialValue={this.props.dataStore.ui.selectedFieldLevelSecurityGroup.text}
                            />
                        </InputBox>
                        <DialogColumnFieldset>
                            <ContentRowWrapper>
                                <DialogLabel id='lblAllfields'>{allFields}</DialogLabel>
                                <DialogLabel id='lblReadOnlyfields'>{readOnlyFields}</DialogLabel>
                            </ContentRowWrapper>
                            <DialogLegend>Fields</DialogLegend>
                            <ContentRowWrapper>
                                <SelectComponent
                                    title='AllFields'
                                    size={10}
                                    width='300px'
                                    multiple='true'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFieldLevelSecurityAllFields(e)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name"
                                    }}
                                    records={flsAllFields}
                                    selectedMultiValue={this.props.dataStore.ui.selectedAllFields}
                                    onDoubleClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onAddFlsFields(e)}
                                //selectedValue={this.props.dataStore.ui.FlsAllFields.value}

                                ></SelectComponent>
                                <ArrowButtons
                                    toolTipLeft=' Remove Fields(s) from Selected Group'
                                    toolTipRight=' Add Field(s) to Selected Group'
                                    // don't need but cna use (see IArrowButtonsProps): width='10px'
                                    onLeftBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRemoveFlsFields(e)}
                                    onRightBtnClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onAddFlsFields(e)}
                                    paddingTop='165px'
                                    disabled={!this.props.canEdit}
                                />
                                <SelectComponent
                                    title='ReadOnlyFields'
                                    size={10}
                                    width='300px'
                                    multiple='true'
                                    onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectFieldLevelSecurityReadOnlyFields(e)}
                                    optionFields={{
                                        value: "@ID",
                                        text: "@Name"
                                    }}
                                    //selectedValue={this.props.dataStore.ui.selectedFlsField.value}
                                    //records={this.props.dataStore.ui.selectedFlsReadOnly} // make sure the structure matches...
                                    records={flsSelectedItem}                                    selectedMultiValue={this.props.dataStore.ui.selectedReadOnly}
                                    onDoubleClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onRemoveFlsFields(e)}

                                ></SelectComponent>
                            </ContentRowWrapper>
                        </DialogColumnFieldset>
                    </SelectActions>
                </ContentRowWrapper>
            </DialogWrapper>
        );
    }
};

function mapStateToProps(state: ApplicationState) {
    return state.fieldLevelSecurity;
}

var connectedHoc = connect<IFieldLevelSecurityState, IFieldLevelSecurityActionProps, IOwnProps, IFieldLevelSecurityProps, ApplicationState>(
    createCrudMapStateToProps('fieldLevelSecurity'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(FieldLevelSecurity);

export default withRouter(connectedHoc);
