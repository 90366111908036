import { Reducer } from 'redux';
import { createApiAction, ActionTypes } from '../scripts/util/ActionHelpers';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface WeatherForecastsState {
    isLoading: boolean;
    startDateIndex?: number;
    forecasts: WeatherForecast[];
}

// Data model for result data from API
export interface WeatherForecast {
    dateFormatted: string;
    temperatureC: number;
    temperatureF: number;
    summary: string;
}

const unloadedState: WeatherForecastsState = { forecasts: [], isLoading: false };

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestWeatherForecasts: (startDateIndex: number) => createApiAction('REQUEST_WEATHER_FORECASTS', `api/SampleData/WeatherForecasts?startDateIndex=${startDateIndex}`, { startDateIndex: startDateIndex }),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

function requestWeatherForecasts(state: WeatherForecastsState, action: KnownActions) {
    if (action.type == 'REQUEST_WEATHER_FORECASTS') {
        switch (action.status.status) {
            case "VALIDATE":
                return state;
            case "REQUEST":
                return {
                    startDateIndex: action.data.startDateIndex,
                    forecasts: state.forecasts,
                    isLoading: true
                };
            case "SUCCESS":
            case "FAIL":
                return {
                    startDateIndex: action.data.startDateIndex,
                    forecasts: <WeatherForecast[]>action.responseData,
                    isLoading: false
                };
                break;
            default:
                const exhaustiveCheck: never = action.status.status;
        }
    }
    return state;
}

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<WeatherForecastsState, KnownActions> = (state: WeatherForecastsState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'REQUEST_WEATHER_FORECASTS':
                return requestWeatherForecasts(state, action);
            // For some reason, when there is only one action, the exhaustive check fails.
            //default:
            //    // The following line guarantees that every action in the KnownAction union has been covered by a case above
            //    const exhaustiveCheck: never = action;
        }
    }

    return state || unloadedState;
}
