import { Reducer, ActionCreatorsMapObject, Dispatch, AnyAction } from "redux";
import { ICrudActionData } from "../../scripts/util/CrudComponentHelpers";
import { IFormUIState, defaultFormState } from "@store/ui/BaseCrudUI";
import { URLs } from "@commonDevResources/constants";
import {
  createDataAction,
  ActionTypes,
  createApiAction,
  IApiAction,
  createAction,
  createApiStatusAction,
} from "../../scripts/util/ActionHelpers";
import {
  MasterCrudState,
  actionCreators as masterCrudCreators,
} from "../MasterCrud";
import {
  ComplexApiAction,
  IThunkResult,
  IThunkApiAction,
} from "@scripts/util/ThunkHelpers";
import { CrudTypes } from "@commonResources/CrudTypes";

import { forceToArray } from "@commonResources/utilityDataParsers";

export interface IClaimOptionsFIState {}

export interface IClaimOptionsFIProps {}

interface IClaimOptionsUIError {
  value: string;
  id?: string;
  type?: string;
}

interface IClaimOptionsAlertMessage {
  value: string;
}
export interface IRelatedClaimsUpdateRequest {
  Type: "UB" | "1500";
  Name:
    | "@isEnabled"
    | "@isMedRecNbEnabled"
    | "@isPCNEnabled"
    | "@isServiceDatesEnabled"
    | "@isDateOfBirthEnabled"
    | "@PCNMatchTypeExact"
    | "@PCNMatchTypeSeq"
    | "@PCNMatchTypeDelim"
    | "@PCNMaskFixedLength"
    | "@PCNDelim";
  Value: any;
}

export interface IRelatedClaimsData {
  isEnabled: boolean;
  isMedRecNbEnabled: boolean;
  isPCNEnabled: boolean;
  isServiceDatesEnabled: boolean;
  isDateOfBirthEnabled: boolean;
  PCNMatchType: string;
  PCNMaskFixedLength: string;
  PCNDelim: string;

  medRecNbEnabledError?: string;
  pcnEnabledError?: string;
  serviceDatesEnabledError?: string;
  dateOfBirthEnabledError?: string;
  maskFixedLengthError?: string;
  delimError?: string;
}

export interface IDuplicateClaimsUpdateRequest {
  Type: "UB" | "1500";
  Name:
    | "@isEnabled"
    | "@isMedRecNbEnabled"
    | "@isPCNEnabled"
    | "@isServiceDatesEnabled"
    | "@isPatLastNameEnabled"
    | "@isPayerEnabled"
    | "@isPayerIndEnabled"
    | "@isBillTypeEnabled"
    | "@isTotAmountEnabled"
    | "@isPrinDiagCodeEnabled"
    | "@isPrinProcCodeEnabled"
    | "@isFacSubIdEnabled"  
    | "@isFrequencyEnabled";
  Value: any;
}

export interface IDuplicateClaimsData {
  isEnabled: boolean;
  isMedRecNbEnabled: boolean;
  isPCNEnabled: boolean;
  isServiceDatesEnabled: boolean;
  isPatLastNameEnabled: boolean;
  isPayerEnabled: boolean;
  isPayerIndEnabled: boolean;
  isBillTypeEnabled?: boolean;
  isTotAmountEnabled: boolean;
  isPrinDiagCodeEnabled: boolean;
  isPrinProcCodeEnabled: boolean;
  isFacSubIdEnabled: boolean;
  isFrequencyEnabled: boolean;

  medRecNbEnabledError?: string;
  pcnEnabledError?: string;
}

export interface IGeneralUpdateRequest {
  Action: "ADD" | "UPDATE" | "REMOVE" | "UPDATEFIELD";
  Name: any;
  Value: any;
}

export interface IMNGeneralOptionsData {
  revenueException: string;
  occurrenceException: string;
  conditionException: string;
  financialClassException: string;
  typeOfBillException: string;

  revenueSLException: string;
  instSLModifier: string;
  instSLProcedure: string;
  profSLModifier: string;
  profSLProcedure: string;
  nonCoveredException: string;

  exclusionList: MCClaimOptionsMNExclusion[];
}

export interface IMNGeneralOptionsLocalState {
  selectedMNExclusionId?: string;
  revenueCodes?: string;
  procedureCodes?: string;
}

export interface ICCIGeneralOptionsData {
  billType: string;
  customModifier: string;
  financialClass: string;
  billTypeMed: string;
  customModifierMed: string;
  financialClassMed: string;

  exclusionList: MCClaimOptionsCCIExclusion[];
}

export interface ICCIGeneralOptionsLocalState {
  selectedCCIExclusionId?: string;
  revenueCodes?: string;
  procedureCodes?: string;
  lobType?: string;
}

export interface IPayerSelectionUpdateRequest {
  Action: "ADD" | "UPDATE" | "REMOVE";
  Value: any;
}

export interface IMNPayerSelectionData {
  LOBList: any[];
  PayerList: any[];
  AliasList: any[];
  FIList: any[];
  FIDetailList: any[];
  SuppressMNBP: boolean;
  SuppressMNAI: boolean;
  MNEdits: MCClaimOptionsMNEdit[];
}

export interface IMNPayerSelectionLocalState {
  selectedMNID?: string;
  selectedFormType?: string;
  selectedPayerOption?: string;
  selectedLOBs?: string[];
  selectedPayers?: string[];
  selectedAliases?: string[];
  selectedFI?: string;
}

export interface IMNPolicySelectionUpdateRequest {
  Action: "UPDATE";
  Value: any;
}
export interface IMNPolicyUpdateSearchRequest {
  Action: "SEARCH";
  Value: any;
}

export interface ICCIPayerSelectionData {
  LOBList: any[];
  PayerList: any[];
  AliasList: any[];
  CPTGroupList: any[];
  SuppressCCIBP: boolean;
  SuppressCCIAI: boolean;
  CCIEdits: MCClaimOptionsCCIEdit[];
}

export interface ICCIPayerSelectionLocalState {
  selectedCCIID?: string;
  selectedFormType?: string;
  selectedPayerOption?: string;
  selectedLOBs?: string[];
  selectedPayers?: string[];
  selectedAliases?: string[];
  selectedCPTGroups?: string[];
}

interface RowProps {
  record: {
    "@ID": string;
    "@Description": string;
    "@Severity": string;
  };
}

// To keep local state when switching tabs
export interface IMNPolicySelectionData {
  totalPages: string;
  description: string;
  selectedSeverity: string;
  policySearchResults: MNPolicies;
  policyPageChanges: MCClaimOptionsMNClientPolicySeverity[];
  currentPage: number;
}

export interface IMNPolicySelectionLocalState {
  description?: string;
  selectedSeverity?: string;
}

export interface IMainClaimsData {
  //AddServiceChangeData: any,
  CCIEdits: any;
  ClaimStatusDaysChangeData: any;
  ComplianceDetailSuppression: any;
  ComplianceGlobalSuppression: any;
  //HisSourceSystemChangeData: any,
  HISSourceSystemList: any[];
  HISSourceSystemOption: any;
  LinkBridgedSecondaryTertiary: any;
  MNEdits: any;
  OtherOptions: any;
  ServiceLineDescription: any;
  StateReportingEdits: any;
  IsClaimStatusUserConfigurationVisible: boolean;
  IsHoldClaimsPendingPropensityToDenyVisible: boolean;
  RequestRetryDelayHours: any;
}

export interface IMainClaimOptionsUpdateRequest {
  Name: string;
  Value: any;
}

// Properties of action creators specific to the ClaimOptions view
export interface IClaimOptionsData {
  uiData: any;
  RelatedClaimsOptionsUB: IRelatedClaimsData;
  RelatedClaimsOptions1500: IRelatedClaimsData;
  DuplicateClaimsOptionsUB: IDuplicateClaimsData;
  DuplicateClaimsOptions1500: IDuplicateClaimsData;
  MainClaimsData: IMainClaimsData;
  MNGeneralOptions: IMNGeneralOptionsData;
  MNPayerSelectionOptions: IMNPayerSelectionData;
  MNPolicySelectionOptions: IMNPolicySelectionData;
  CCIGeneralOptions: ICCIGeneralOptionsData;
  CCIPayerSelectionOptions: ICCIPayerSelectionData;
}

export interface MNSearchParam {
  Name: string;
  Value: string | undefined;
  Type?: string;
}

interface MNPolicy {
  "@Description": string;
  "@PolicyID": string;
  "@PolicyNumber": string;
  "@Reference": string;
  "@Severity": string;
}

interface MNPolicyTotalPageCount {
  "@TotalPageCount": string;
}

interface MNPolicyTotalPages {
  TotalPageCount: MNPolicyTotalPageCount;
}

interface MNPolicies {
  MNPolicy: MNPolicy[];
}

interface MNPolicySearch {
  MNPolicies: MNPolicies;
  TotalPages: MNPolicyTotalPages;
}

export interface MNSearchRequest {
  Param: MNSearchParam[];
}

interface MNPolicies {
  "@PolicyID": string;
  "@Description": string;
  "@Reference": string;
}

interface PolicyMNSearchCriteria {
  Action: "GetMNPolicySearchResults";
  Severity: string;
  Description?: string;
}

// UI representation of CRUD data.
export interface IClaimOptionsUIState extends IClaimOptionsData, IFormUIState {
  alertMessage?: string;
}

// merge our custom action data interface with CRUD boilerplate
export interface IModifyClaimOptions
  extends ICrudActionData<MCClaimOptions, string> {}

export interface IResetCrudFlag
  extends ICrudActionData<MCClaimOptions, boolean> {}

export interface IError
  extends ICrudActionData<MCClaimOptions, IClaimOptionsUIError> {}
export interface IAlertMessage
  extends ICrudActionData<MCClaimOptions, IClaimOptionsAlertMessage> {}
export interface MCClaimOptionsAndConfig extends MCClaimOptions {
  PropensityToDenyEnabled: string;
  ECSEnabledValue: string;
}
export interface IInitializeClaimOptions
  extends ICrudActionData<MCClaimOptions, MCClaimOptionsAndConfig> {}

export interface IRelatedClaimsUpdateRequestData
  extends ICrudActionData<MCClaimOptions, IRelatedClaimsUpdateRequest> {}

export interface IDuplicateClaimsUpdateRequestData
  extends ICrudActionData<MCClaimOptions, IDuplicateClaimsUpdateRequest> {}

export interface IGeneralUpdateRequestData
  extends ICrudActionData<MCClaimOptions, IGeneralUpdateRequest> {}

export interface IPayerSelectionUpdateRequestData
  extends ICrudActionData<MCClaimOptions, IPayerSelectionUpdateRequest> {}

export interface IMNPolicySelectionUpdateRequestData
  extends ICrudActionData<MCClaimOptions, IMNPolicySelectionUpdateRequest> {}

export interface IMainClaimOptionsUpdateRequestData
  extends ICrudActionData<MCClaimOptions, IMainClaimOptionsUpdateRequest> {}

export interface IMNPolicyUpdateSearchRequestData
  extends ICrudActionData<MCClaimOptions, MNPolicySearch> {
  MNPolicySearch: MNPolicySearch;
}

// exported for if I get time to do tests, otherwise does not need export
export const actionCreators = {
  initializeOptions: (rawApiReturn: IInitializeClaimOptions) =>
    createDataAction("INIT_CLAIM_OPTIONS", rawApiReturn),
  ignoreSearchCrud: (apiReturn: any) =>
    createDataAction("IGNORE_CRUD_UPDATE", apiReturn),
  getFiData: () =>
    createApiAction("GET_FI_DATA", `${URLs.api}/api/data/utility/fi`),
  editUiData: (claimOptionsInfo: IModifyClaimOptions) =>
    createDataAction("EDIT_CLAIM_OPTIONS_DATA", claimOptionsInfo),
  resetDirtyFlag: (claimOptionsInfo: IResetCrudFlag) =>
    createDataAction("RESET_CLAIM_OPTIONS_DIRTY", claimOptionsInfo),
  sendErrorField: (fieldInfo: IError) =>
    createDataAction("ERROR_CLAIM_OPTIONS_FIELD", fieldInfo),
  sendAlertMessage: (msg: string) =>
    createDataAction("CLAIM_OPTIONS_SEND_ALERT", msg),
  updateRelatedClaims: (claimOptionsInfo: IRelatedClaimsUpdateRequestData) =>
    createDataAction("UPDATE_RELATED_CLAIMS_OPTIONS", claimOptionsInfo),
  updateDuplicateClaims: (
    claimOptionsInfo: IDuplicateClaimsUpdateRequestData
  ) => createDataAction("UPDATE_DUPLICATE_CLAIMS_OPTIONS", claimOptionsInfo),
  updateMNGeneralOptions: (claimOptionsInfo: IGeneralUpdateRequestData) =>
    createDataAction("UPDATE_MN_GENERAL_OPTIONS", claimOptionsInfo),
  updateMNPayerSelection: (
    claimOptionsInfo: IPayerSelectionUpdateRequestData
  ) => createDataAction("UPDATE_MN_PAYER_SELECTION_OPTIONS", claimOptionsInfo),
  updateMNPolicySelection: (
    claimOptionsInfo: IMNPolicySelectionUpdateRequestData
  ) => createDataAction("UPDATE_MN_POLICY_SELECTION_OPTIONS", claimOptionsInfo),
  updateCCIGeneralOptions: (claimOptionsInfo: IGeneralUpdateRequestData) =>
    createDataAction("UPDATE_CCI_GENERAL_OPTIONS", claimOptionsInfo),
  updateCCIPayerSelection: (
    claimOptionsInfo: IPayerSelectionUpdateRequestData
  ) => createDataAction("UPDATE_CCI_PAYER_SELECTION_OPTIONS", claimOptionsInfo),
  updateMainClaimsOptions: (
    claimOptionsInfo: IMainClaimOptionsUpdateRequestData
  ) => createDataAction("UPDATE_MAIN_CLAIMS_OPTIONS", claimOptionsInfo),
  updateMNPolicyLocalState: (request: IMNPolicySelectionLocalState) =>
    createDataAction("UPDATE_MN_POLICY_LOCAL_STATE", request),
  updateRows: (claimOptionsInfo: IMNPolicySelectionUpdateRequestData) =>
    createDataAction("UPDATE_MNPOLICY_ROWS", claimOptionsInfo),
  searchInfo: (claimOptionsInfo: IMNPolicyUpdateSearchRequestData) =>
    createDataAction("UPDATE_MNPOLICY_SEARCH_RESULTS", claimOptionsInfo),
  searchAndUpdateRows: (searchRequest: MNSearchRequest) =>
    ComplexApiAction.fromAction(
      masterCrudCreators.search({
        crudId: CrudTypes.mctiClaimOptions,
        data: JSON.stringify(searchRequest),
      })
    )
      .addThunk(
        (
          dispatch: Dispatch<AnyAction>,
          actionResult: IThunkApiAction<"SEARCH_CRUD", PolicyMNSearchCriteria>
        ): IThunkResult<void> => {
          const searchResults: MNPolicySearch[] | undefined =
            actionResult?.responseData;
          // If everything looks good, dispatch an action to update the rows and return.
          if (actionResult.status.status === "SUCCESS" && searchResults) {
            dispatch(actionCreators.searchInfo(actionResult.responseData));
            return { success: true };
          }
          // TODO - Remove this before committing to QA - WMR.
          else {
            return { success: false };
          }
        }
      )
      .changeType("IGNORE_CRUD_UPDATE")
      .finish(),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>["type"];

const defaultRelatedClaimOptions: IRelatedClaimsData = {
  isEnabled: false,
  isMedRecNbEnabled: false,
  isPCNEnabled: false,
  isServiceDatesEnabled: false,
  isDateOfBirthEnabled: false,
  PCNMatchType: "0",
  PCNMaskFixedLength: "0",
  PCNDelim: "",
};

const defaultDuplicateClaimOptions: IDuplicateClaimsData = {
  isEnabled: false,
  isMedRecNbEnabled: false,
  isPCNEnabled: false,
  isServiceDatesEnabled: false,
  isPatLastNameEnabled: false,
  isPayerEnabled: false,
  isPayerIndEnabled: false,
  isBillTypeEnabled: false,
  isTotAmountEnabled: false,
  isPrinDiagCodeEnabled: false,
  isPrinProcCodeEnabled: false,
  isFacSubIdEnabled: false,
  isFrequencyEnabled: false,
};

const defaultMNGeneralOptions: IMNGeneralOptionsData = {
  revenueException: "",
  occurrenceException: "",
  conditionException: "",
  financialClassException: "",
  typeOfBillException: "",

  revenueSLException: "",
  instSLModifier: "",
  instSLProcedure: "",
  profSLModifier: "",
  profSLProcedure: "",
  nonCoveredException: "",

  exclusionList: [],
};

const defaultCCIGeneralOptions: ICCIGeneralOptionsData = {
  billType: "",
  customModifier: "",
  financialClass: "",
  billTypeMed: "",
  customModifierMed: "",
  financialClassMed: "",

  exclusionList: [],
};

const defaultMNPayerSelectionOptions: IMNPayerSelectionData = {
  LOBList: [],
  PayerList: [],
  AliasList: [],
  FIList: [],
  FIDetailList: [],
  SuppressMNBP: false,
  SuppressMNAI: false,
  MNEdits: [],
};

const defaultCCIPayerSelectionOptions: ICCIPayerSelectionData = {
  LOBList: [],
  PayerList: [],
  AliasList: [],
  CPTGroupList: [],
  SuppressCCIBP: false,
  SuppressCCIAI: false,
  CCIEdits: [],
};

const defaultMNPolicySelectionOptions: IMNPolicySelectionData = {
  totalPages: "1",
  description: "",
  selectedSeverity: "E",
  policySearchResults: {
    MNPolicy: [],
    "@PolicyID": "",
    "@Description": "",
    "@Reference": "",
  },
  policyPageChanges: [],
  currentPage: 1,
};

const defaultMainClaimsOptions: IMainClaimsData = {
  //AddServiceChangeData: [],
  CCIEdits: [],
  ClaimStatusDaysChangeData: [],
  ComplianceDetailSuppression: {},
  ComplianceGlobalSuppression: {},
  //HisSourceSystemChangeData: [],
  HISSourceSystemList: [],
  HISSourceSystemOption: "",
  LinkBridgedSecondaryTertiary: "",
  MNEdits: [],
  OtherOptions: "",
  ServiceLineDescription: "",
  StateReportingEdits: {},
  IsClaimStatusUserConfigurationVisible: false,
  IsHoldClaimsPendingPropensityToDenyVisible: false,
  RequestRetryDelayHours: "",
};

export const defaultClaimOptionsState: IClaimOptionsUIState = {
  ...defaultFormState,
  uiData: {},
  RelatedClaimsOptionsUB: defaultRelatedClaimOptions,
  RelatedClaimsOptions1500: defaultRelatedClaimOptions,
  DuplicateClaimsOptionsUB: defaultDuplicateClaimOptions,
  DuplicateClaimsOptions1500: defaultDuplicateClaimOptions,
  MNGeneralOptions: defaultMNGeneralOptions,
  MNPayerSelectionOptions: defaultMNPayerSelectionOptions,
  MNPolicySelectionOptions: defaultMNPolicySelectionOptions,
  CCIGeneralOptions: defaultCCIGeneralOptions,
  CCIPayerSelectionOptions: defaultCCIPayerSelectionOptions,
  MainClaimsData: defaultMainClaimsOptions,
};

export function enforceArray<T>(node: T[]): T[] {
  if (Array.isArray(node)) return node;

  const array: T[] = [];
  if (node) return array.concat(node);
  return array;
}

export function enforceArrayForMNEdit(
  node: MCClaimOptionsMNEdit[]
): MCClaimOptionsMNEdit[] {
  let newArray = enforceArray<MCClaimOptionsMNEdit>(node);
  newArray.forEach((mnEdit) => {
    if (mnEdit.LOBs.LOB)
      mnEdit.LOBs.LOB = enforceArray<MCClaimOptionsID>(mnEdit.LOBs.LOB);
    if (mnEdit.Payers.Payer)
      mnEdit.Payers.Payer = enforceArray<MCClaimOptionsID>(mnEdit.Payers.Payer);
    if (mnEdit.Aliases.Alias)
      mnEdit.Aliases.Alias = enforceArray<MCClaimOptionsID>(
        mnEdit.Aliases.Alias
      );
  });
  return newArray;
}

export function enforceArrayForCCIEdit(
  node: MCClaimOptionsCCIEdit[]
): MCClaimOptionsCCIEdit[] {
  let newArray = enforceArray<MCClaimOptionsCCIEdit>(node);
  newArray.forEach((edit) => {
    if (edit.LOBs.LOB)
      edit.LOBs.LOB = enforceArray<MCClaimOptionsID>(edit.LOBs.LOB);
    if (edit.Payers.Payer)
      edit.Payers.Payer = enforceArray<MCClaimOptionsID>(edit.Payers.Payer);
    if (edit.Aliases.Alias)
      edit.Aliases.Alias = enforceArray<MCClaimOptionsID>(edit.Aliases.Alias);
    if (edit.CPTGroups.CPTGroup)
      edit.CPTGroups.CPTGroup = enforceArray<MCClaimOptionsID>(
        edit.CPTGroups.CPTGroup
      );
  });
  return newArray;
}

function mapYesNoAttributeToBoolean(ob: any, attributeName: string) {
  return ob && ob[attributeName] ? ob[attributeName] == "Y" : false;
}
function mapStringAttribute(ob: any, attributeName: string) {
  return ob && ob[attributeName] ? ob[attributeName] : "";
}
function mapPNCMatchTypeToInt(
  ob: any,
  ctrlNbAttr: string,
  fixedLenAttr: string,
  maskDelimAttr: string
) {
  if (!ob) return "-1";

  if (ob[ctrlNbAttr] && ob[ctrlNbAttr] == "Y") return "0";
  if (ob[fixedLenAttr] && ob[fixedLenAttr] != "0") return "1";
  if (ob[maskDelimAttr] && ob[maskDelimAttr] != "") return "2";
  return "-1";
}
function mapPCNEnabled(
  ob: any,
  ctrlNbAttr: string,
  fixedLenAttr: string,
  maskDelimAttr: string
) {
  if (!ob) return false;
  if (ob[ctrlNbAttr] && ob[ctrlNbAttr] == "Y") return true;
  if (
    ob[fixedLenAttr] &&
    (ob[fixedLenAttr] == "2" ||
      ob[fixedLenAttr] == "3" ||
      ob[fixedLenAttr] == "4")
  )
    return true;
  if (ob[maskDelimAttr] && ob[maskDelimAttr] != "") return true;
  return false;
}

/* ===============================================================================================================================================================*/
/*                                  CRUD TO UI  (Initialization)
/* ===============================================================================================================================================================*/
export function mapCrudToUiState(
  crud: MCClaimOptionsAndConfig
): IClaimOptionsUIState {
  let relatedClaimsConfig: MCClaimOptionsRelatedClaimSearchConfig =
    crud.ClaimOptionsMaintenanceInfo.CustomEdits.RelatedClaimSearchConfig;
  let duplicateClaimsConfig: MCClaimOptionsDuplicateConfig =
    crud.ClaimOptionsMaintenanceInfo.CustomEdits.DuplicateConfig;

  return {
    isDirty: false,
    uiData: crud,
    RelatedClaimsOptionsUB: {
      isEnabled: mapYesNoAttributeToBoolean(relatedClaimsConfig, "@CheckUB"),
      isMedRecNbEnabled: mapYesNoAttributeToBoolean(
        relatedClaimsConfig,
        "@MedicalRecordNumberUB"
      ),
      isServiceDatesEnabled: mapYesNoAttributeToBoolean(
        relatedClaimsConfig,
        "@ServiceDatesUB"
      ),
      isDateOfBirthEnabled: mapYesNoAttributeToBoolean(
        relatedClaimsConfig,
        "@PatientDateOfBirthUB"
      ),
      PCNMaskFixedLength: mapStringAttribute(
        relatedClaimsConfig,
        "@PatientControlMaskFixedLenUB"
      ),
      PCNDelim: mapStringAttribute(
        relatedClaimsConfig,
        "@PatientControlMaskDelimiterUB"
      ),
      PCNMatchType: mapPNCMatchTypeToInt(
        relatedClaimsConfig,
        "@PatientControlNumberUB",
        "@PatientControlMaskFixedLenUB",
        "@PatientControlMaskDelimiterUB"
      ),
      isPCNEnabled: mapPCNEnabled(
        relatedClaimsConfig,
        "@PatientControlNumberUB",
        "@PatientControlMaskFixedLenUB",
        "@PatientControlMaskDelimiterUB"
      ),
    },
    RelatedClaimsOptions1500: {
      isEnabled: mapYesNoAttributeToBoolean(relatedClaimsConfig, "@Check1500"),
      isMedRecNbEnabled: mapYesNoAttributeToBoolean(
        relatedClaimsConfig,
        "@MedicalRecordNumber1500"
      ),
      isServiceDatesEnabled: mapYesNoAttributeToBoolean(
        relatedClaimsConfig,
        "@ServiceDates1500"
      ),
      isDateOfBirthEnabled: mapYesNoAttributeToBoolean(
        relatedClaimsConfig,
        "@PatientDateOfBirth1500"
      ),
      PCNMaskFixedLength: mapStringAttribute(
        relatedClaimsConfig,
        "@PatientControlMaskFixedLen1500"
      ),
      PCNDelim: mapStringAttribute(
        relatedClaimsConfig,
        "@PatientControlMaskDelimiter1500"
      ),
      PCNMatchType: mapPNCMatchTypeToInt(
        relatedClaimsConfig,
        "@PatientControlNumber1500",
        "@PatientControlMaskFixedLen1500",
        "@PatientControlMaskDelimiter1500"
      ),
      isPCNEnabled: mapPCNEnabled(
        relatedClaimsConfig,
        "@PatientControlNumber1500",
        "@PatientControlMaskFixedLen1500",
        "@PatientControlMaskDelimiter1500"
      ),
    },
    //bob
    DuplicateClaimsOptionsUB: {
      isEnabled: mapYesNoAttributeToBoolean(duplicateClaimsConfig, "@CheckUB"), //crud.ClaimOptionsMaintenanceInfo.CustomEdits.DuplicateConfig['@CheckUB'] == 'Y',
      isMedRecNbEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@MedicalRecordNumberUB"
      ),
      isPCNEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PatientControlNumberUB"
      ),
      isServiceDatesEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@ServiceDatesUB"
      ),
      isPatLastNameEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PatientLastNameUB"
      ),
      isPayerEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PayerNameUB"
      ),
      isPayerIndEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PayerIndicatorUB"
      ),
      isBillTypeEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@BillTypeUB"
      ),
      isTotAmountEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@TotalAmountUB"
      ),
      isPrinDiagCodeEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PrincipalDiagnosisCodeUB"
      ),
      isPrinProcCodeEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PrincipalProcedureCodeUB"
      ),
      isFacSubIdEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@FacilitySubIdUB"
        ),
      isFrequencyEnabled: false,
    },
    DuplicateClaimsOptions1500: {
      isEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@Check1500"
      ),
      isMedRecNbEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@MedicalRecordNumber1500"
      ),
      isPCNEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PatientControlNumber1500"
      ),
      isServiceDatesEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@ServiceDates1500"
      ),
      isPatLastNameEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PatientLastName1500"
      ),
      isPayerEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PayerName1500"
      ),
      isPayerIndEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PayerIndicator1500"
      ),
      //isBillTypeEnabled: crud.ClaimOptionsMaintenanceInfo.CustomEdits.DuplicateConfig['@BillType1500'] == 'Y',
      isTotAmountEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@TotalAmount1500"
      ),
      isPrinDiagCodeEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PrincipalDiagnosisCode1500"
      ),
      isPrinProcCodeEnabled: mapYesNoAttributeToBoolean(
        duplicateClaimsConfig,
        "@PrincipalProcedureCode1500"
      ),
      isFacSubIdEnabled: mapYesNoAttributeToBoolean(
          duplicateClaimsConfig,
          "@FacilitySubId1500"
      ),
      isFrequencyEnabled: mapYesNoAttributeToBoolean(
          duplicateClaimsConfig,
          "@FrequencyEnabled1500"
      ),
    },

    MNGeneralOptions: {
      revenueException:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@RevenueException"
        ],
      occurrenceException:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@OccurrenceException"
        ],
      conditionException:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@ConditionException"
        ],
      financialClassException:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@FinancialClassException"
        ],
      typeOfBillException:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@TypeOfBillException"
        ],

      revenueSLException:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@RevenueSLException"
        ],
      instSLModifier:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@InstitutionalSLModifier"
        ],
      instSLProcedure:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@InstitutionalSLProcedure"
        ],
      profSLModifier:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@ProfessionalSLModifier"
        ],
      profSLProcedure:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@ProfessionalSLProcedure"
        ],
      nonCoveredException:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@NonCoveredException"
        ],

      exclusionList: enforceArray<MCClaimOptionsMNExclusion>(
        crud.ClaimOptionsMaintenanceInfo.MNExclusions.MNExclusion
      ),
    },
    MNPayerSelectionOptions: {
      LOBList: enforceArray<MCClaimOptionsIDName>(
        crud.ClaimOptionsMaintenanceInfo.LOBList.LOB
      ),
      PayerList: enforceArray<MCClaimOptionsIDNameType>(
        crud.ClaimOptionsMaintenanceInfo.PayerList.Payer
      ),
      AliasList: enforceArray<MCClaimOptionsIDNameType>(
        crud.ClaimOptionsMaintenanceInfo.AliasList.Alias
      ),
      FIList: enforceArray<MCClaimOptionsIDNameType>(
        crud.ClaimOptionsMaintenanceInfo.FIList.FI
      ),
      FIDetailList: [],
      SuppressMNBP:
        crud.ClaimOptionsMaintenanceInfo.ComplianceGlobalSuppression[
          "@SuppressMNBP"
        ] == "Y",
      SuppressMNAI:
        crud.ClaimOptionsMaintenanceInfo.ComplianceGlobalSuppression[
          "@SuppressMNAI"
        ] == "Y",
      MNEdits: enforceArrayForMNEdit(
        crud.ClaimOptionsMaintenanceInfo.MNEdits.MNEdit
      ),
    },
    MNPolicySelectionOptions: {
      totalPages: "1",
      description: "",
      selectedSeverity: "E",
      policySearchResults: {
        MNPolicy: [],
        "@PolicyID": "",
        "@Description": "",
        "@Reference": "",
      },
      policyPageChanges: [],
      currentPage: 1,
    },
    CCIGeneralOptions: {
      billType:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@CCIBillType"
        ],
      customModifier:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@CCIModifiers"
        ],
      financialClass:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@CCIFinancialClass"
        ],
      billTypeMed:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@CCIBillTypeMedicaid"
        ],
      customModifierMed:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@CCIModifiersMedicaid"
        ],
      financialClassMed:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression[
          "@CCIFinancialClassMedicaid"
        ],

      exclusionList: enforceArray<MCClaimOptionsCCIExclusion>(
        crud.ClaimOptionsMaintenanceInfo.CCIExclusions.CCIExclusion
      ),
    },
    CCIPayerSelectionOptions: {
      LOBList: enforceArray<MCClaimOptionsIDName>(
        crud.ClaimOptionsMaintenanceInfo.LOBList.LOB
      ),
      PayerList: enforceArray<MCClaimOptionsIDNameType>(
        crud.ClaimOptionsMaintenanceInfo.PayerList.Payer
      ),
      AliasList: enforceArray<MCClaimOptionsIDNameType>(
        crud.ClaimOptionsMaintenanceInfo.AliasList.Alias
      ),
      CPTGroupList: enforceArray<MCClaimOptionsIDName>(
        crud.ClaimOptionsMaintenanceInfo.CPTGroupList.CPTGroup
      ),
      SuppressCCIBP:
        crud.ClaimOptionsMaintenanceInfo.ComplianceGlobalSuppression[
          "@SuppressCCIBP"
        ] == "Y",
      SuppressCCIAI:
        crud.ClaimOptionsMaintenanceInfo.ComplianceGlobalSuppression[
          "@SuppressCCIAI"
        ] == "Y",
      CCIEdits: enforceArrayForCCIEdit(
        crud.ClaimOptionsMaintenanceInfo.CCIEdits.CCIEdit
      ),
    },
    MainClaimsData: {
      //AddServiceChangeData: {'@AddServiceChange': '2' },
      CCIEdits: crud.ClaimOptionsMaintenanceInfo.CCIEdits,
      ClaimStatusDaysChangeData: { "@ClaimStatusDaysChanges": "0" },
      ComplianceDetailSuppression:
        crud.ClaimOptionsMaintenanceInfo.ComplianceDetailSuppression,
      ComplianceGlobalSuppression:
        crud.ClaimOptionsMaintenanceInfo.ComplianceGlobalSuppression,
      //HisSourceSystemChangeData: {'@HisSourceSystemChanges': '0'},
      LinkBridgedSecondaryTertiary:
        crud.ClaimOptionsMaintenanceInfo.LinkBridgedSecondaryTertiary,
      ServiceLineDescription:
        crud.ClaimOptionsMaintenanceInfo.ServiceLineDescription,
      HISSourceSystemList:
        crud.ClaimOptionsMaintenanceInfo.HISSourceSystemList.HISSourceSystem,
      HISSourceSystemOption:
        crud.ClaimOptionsMaintenanceInfo.HISSourceSystemOption,
      MNEdits: crud.ClaimOptionsMaintenanceInfo.MNEdits,
      StateReportingEdits: crud.ClaimOptionsMaintenanceInfo.StateReportingEdits,
      OtherOptions: crud.ClaimOptionsMaintenanceInfo.OtherOptions,
      IsClaimStatusUserConfigurationVisible:
        crud.ECSEnabledValue == "1" || crud.ECSEnabledValue == "2"
          ? true
          : false,
      IsHoldClaimsPendingPropensityToDenyVisible:
        crud.PropensityToDenyEnabled == "1",
      RequestRetryDelayHours:
        crud.ClaimOptionsMaintenanceInfo.RequestRetryDelayHours,
    },
  };
}

/* ===============================================================================================================================================================*/
/*                                  UI/STATE TO CRUD  (Update)
/* ===============================================================================================================================================================*/
export function mapDuplicateClaimsUiStateToCrud<T extends IDuplicateClaimsData>(
  state: T,
  crudData: MCClaimOptionsDuplicateConfig,
  requestType: "UB" | "1500"
) {
  if (requestType == "UB") {
    crudData["@CheckUB"] = state.isEnabled ? "Y" : "N";
    crudData["@MedicalRecordNumberUB"] =
      state.isEnabled && state.isMedRecNbEnabled ? "Y" : "N";
    crudData["@PatientControlNumberUB"] =
      state.isEnabled && state.isPCNEnabled ? "Y" : "N";
    crudData["@ServiceDatesUB"] =
      state.isEnabled && state.isServiceDatesEnabled ? "Y" : "N";
    crudData["@PatientLastNameUB"] =
      state.isEnabled && state.isPatLastNameEnabled ? "Y" : "N";
    crudData["@PayerNameUB"] =
      state.isEnabled && state.isPayerEnabled ? "Y" : "N";
    crudData["@PayerIndicatorUB"] =
      state.isEnabled && state.isPayerIndEnabled ? "Y" : "N";
    crudData["@BillTypeUB"] =
      state.isEnabled && state.isBillTypeEnabled ? "Y" : "N";
    crudData["@TotalAmountUB"] =
      state.isEnabled && state.isTotAmountEnabled ? "Y" : "N";
    crudData["@PrincipalDiagnosisCodeUB"] =
      state.isEnabled && state.isPrinDiagCodeEnabled ? "Y" : "N";
    crudData["@PrincipalProcedureCodeUB"] =
      state.isEnabled && state.isPrinProcCodeEnabled ? "Y" : "N";
    crudData["@FacilitySubIdUB"] =
          state.isEnabled && state.isFacSubIdEnabled ? "Y" : "N";
    //crudData["@FrequencyEnabled1500"] = "N";
  } else if (requestType == "1500") {
    crudData["@Check1500"] = state.isEnabled ? "Y" : "N";
    crudData["@MedicalRecordNumber1500"] =
      state.isEnabled && state.isMedRecNbEnabled ? "Y" : "N";
    crudData["@PatientControlNumber1500"] =
      state.isEnabled && state.isPCNEnabled ? "Y" : "N";
    crudData["@ServiceDates1500"] =
      state.isEnabled && state.isServiceDatesEnabled ? "Y" : "N";
    crudData["@PatientLastName1500"] =
      state.isEnabled && state.isPatLastNameEnabled ? "Y" : "N";
    crudData["@PayerName1500"] =
      state.isEnabled && state.isPayerEnabled ? "Y" : "N";
    crudData["@PayerIndicator1500"] =
      state.isEnabled && state.isPayerIndEnabled ? "Y" : "N";
    //crudData['@BillType1500'] = (state.isEnabled && state.isBillTypeEnabled ? "Y" : "N");
    crudData["@TotalAmount1500"] =
      state.isEnabled && state.isTotAmountEnabled ? "Y" : "N";
    crudData["@PrincipalDiagnosisCode1500"] =
      state.isEnabled && state.isPrinDiagCodeEnabled ? "Y" : "N";
    crudData["@PrincipalProcedureCode1500"] =
      state.isEnabled && state.isPrinProcCodeEnabled ? "Y" : "N";
    crudData["@FacilitySubId1500"] =
      state.isEnabled && state.isFacSubIdEnabled ? "Y" : "N";
    crudData["@FrequencyEnabled1500"] =
      state.isEnabled && state.isFrequencyEnabled ? "Y" : "N";
  }
}

export function mapRelatedClaimsUiStateToCrud<T extends IRelatedClaimsData>(
  state: T,
  crudData: MCClaimOptionsRelatedClaimSearchConfig,
  requestType: "UB" | "1500"
) {
  if (requestType == "UB") {
    crudData["@CheckUB"] = state.isEnabled ? "Y" : "N";
    crudData["@MedicalRecordNumberUB"] =
      state.isEnabled && state.isMedRecNbEnabled ? "Y" : "N";
    crudData["@PatientControlNumberUB"] =
      state.isEnabled && state.isPCNEnabled && state.PCNMatchType == "0"
        ? "Y"
        : "N";
    crudData["@PatientControlMaskFixedLenUB"] =
      state.PCNMatchType == "1" && state.isEnabled && state.isPCNEnabled
        ? state.PCNMaskFixedLength
        : "0";
    crudData["@PatientControlMaskDelimiterUB"] =
      state.PCNMatchType == "2" && state.isEnabled && state.isPCNEnabled
        ? state.PCNDelim
        : "";
    crudData["@ServiceDatesUB"] =
      state.isEnabled && state.isServiceDatesEnabled ? "Y" : "N";
    crudData["@PatientDateOfBirthUB"] =
      state.isEnabled && state.isDateOfBirthEnabled ? "Y" : "N";
  } else if (requestType == "1500") {
    crudData["@Check1500"] = state.isEnabled ? "Y" : "N";
    crudData["@MedicalRecordNumber1500"] =
      state.isEnabled && state.isMedRecNbEnabled ? "Y" : "N";
    crudData["@PatientControlNumber1500"] =
      state.isEnabled && state.isPCNEnabled && state.PCNMatchType == "0"
        ? "Y"
        : "N";
    crudData["@PatientControlMaskFixedLen1500"] =
      state.PCNMatchType == "1" && state.isEnabled && state.isPCNEnabled
        ? state.PCNMaskFixedLength
        : "0";
    crudData["@PatientControlMaskDelimiter1500"] =
      state.PCNMatchType == "2" && state.isEnabled && state.isPCNEnabled
        ? state.PCNDelim
        : "";
    crudData["@ServiceDates1500"] =
      state.isEnabled && state.isServiceDatesEnabled ? "Y" : "N";
    crudData["@PatientDateOfBirth1500"] =
      state.isEnabled && state.isDateOfBirthEnabled ? "Y" : "N";
  }
}

function updateRelatedClaimsOptionsState(
  state: IClaimOptionsUIState,
  request: IRelatedClaimsUpdateRequest,
  crudData: MCClaimOptionsRelatedClaimSearchConfig | undefined
): IClaimOptionsUIState {
  switch (request.Type) {
    case "UB": {
      let newRelatedClaimsState = updateRelatedClaimsOptionsHandler(
        state.RelatedClaimsOptionsUB,
        request,
        crudData
      );
      return { ...state, RelatedClaimsOptionsUB: newRelatedClaimsState };
    }
    case "1500": {
      let newRelatedClaimsState = updateRelatedClaimsOptionsHandler(
        state.RelatedClaimsOptions1500,
        request,
        crudData
      );
      return { ...state, RelatedClaimsOptions1500: newRelatedClaimsState };
    }
    default:
      break;
  }
  return state;
}

function updateDuplicateClaimsOptionsState(
  state: IClaimOptionsUIState,
  request: IDuplicateClaimsUpdateRequest,
  crudData: MCClaimOptionsDuplicateConfig | undefined
): IClaimOptionsUIState {
  switch (request.Type) {
    case "UB": {
      let newDuplicateClaimsState = updateDuplicateClaimsOptionsHandler(
        state.DuplicateClaimsOptionsUB,
        request,
        crudData
      );
      return { ...state, DuplicateClaimsOptionsUB: newDuplicateClaimsState };
    }
    case "1500": {
      let newDuplicateClaimsState = updateDuplicateClaimsOptionsHandler(
        state.DuplicateClaimsOptions1500,
        request,
        crudData
      );
      return { ...state, DuplicateClaimsOptions1500: newDuplicateClaimsState };
    }
    default:
      break;
  }
  return state;
}

function updateRelatedClaimsOptionsHandler(
  relatedClaimsState: IRelatedClaimsData,
  request: IRelatedClaimsUpdateRequest,
  crudData: MCClaimOptionsRelatedClaimSearchConfig | undefined
): IRelatedClaimsData {
  let resultingState = relatedClaimsState;

  resultingState.medRecNbEnabledError = "";
  resultingState.pcnEnabledError = "";
  resultingState.serviceDatesEnabledError = "";
  resultingState.dateOfBirthEnabledError = "";
  resultingState.maskFixedLengthError = "";
  resultingState.delimError = "";

  switch (request.Name) {
    case "@isEnabled":
      resultingState.isEnabled = request.Value;
      break;
    case "@isMedRecNbEnabled":
      resultingState.isMedRecNbEnabled = request.Value;
      break;
    case "@isPCNEnabled":
      resultingState.isPCNEnabled = request.Value;
      if (
        request.Value &&
        relatedClaimsState.PCNMatchType != "0" &&
        relatedClaimsState.PCNMatchType != "1" &&
        relatedClaimsState.PCNMatchType != "2"
      )
        resultingState.PCNMatchType = "0";
      break;
    case "@isServiceDatesEnabled":
      resultingState.isServiceDatesEnabled = request.Value;
      break;
    case "@isDateOfBirthEnabled":
      resultingState.isDateOfBirthEnabled = request.Value;
      break;
    case "@PCNMatchTypeExact":
      if (request.Value) resultingState.PCNMatchType = "0";
      break;
    case "@PCNMatchTypeSeq":
      if (request.Value) resultingState.PCNMatchType = "1";
      break;
    case "@PCNMatchTypeDelim":
      if (request.Value) resultingState.PCNMatchType = "2";
      break;
    case "@PCNMaskFixedLength":
      resultingState.PCNMaskFixedLength = request.Value;
      break;
    case "@PCNDelim":
      resultingState.PCNDelim = request.Value;
      break;
    default:
      break;
  }

  if (crudData) {
    mapRelatedClaimsUiStateToCrud(resultingState, crudData, request.Type);
  }
  return resultingState;
}

function updateDuplicateClaimsOptionsHandler(
  duplicateClaimsState: IDuplicateClaimsData,
  request: IDuplicateClaimsUpdateRequest,
  crudData: MCClaimOptionsDuplicateConfig | undefined
): IDuplicateClaimsData {
  let resultingState = duplicateClaimsState;

  resultingState.medRecNbEnabledError = "";
  resultingState.pcnEnabledError = "";

  switch (request.Name) {
    case "@isEnabled":
      resultingState.isEnabled = request.Value;
      resultingState.isServiceDatesEnabled = request.Value;
      break;
    case "@isMedRecNbEnabled":
      resultingState.isMedRecNbEnabled = request.Value;
      break;
    case "@isPCNEnabled":
      resultingState.isPCNEnabled = request.Value;
      break;
    case "@isServiceDatesEnabled":
      resultingState.isServiceDatesEnabled = request.Value;
      break;
    case "@isPatLastNameEnabled":
      resultingState.isPatLastNameEnabled = request.Value;
      break;
    case "@isPayerEnabled":
      resultingState.isPayerEnabled = request.Value;
      break;
    case "@isPayerIndEnabled":
      resultingState.isPayerIndEnabled = request.Value;
      break;
    case "@isBillTypeEnabled":
      resultingState.isBillTypeEnabled = request.Value;
      break;
    case "@isTotAmountEnabled":
      resultingState.isTotAmountEnabled = request.Value;
      break;
    case "@isPrinDiagCodeEnabled":
      resultingState.isPrinDiagCodeEnabled = request.Value;
      break;
    case "@isPrinProcCodeEnabled":
      resultingState.isPrinProcCodeEnabled = request.Value;
      break;
    case "@isFacSubIdEnabled":
      resultingState.isFacSubIdEnabled = request.Value;
      break;
    case "@isFrequencyEnabled":
      resultingState.isFrequencyEnabled = request.Value;
      break;

    default:
      break;
  }

  if (crudData) {
    mapDuplicateClaimsUiStateToCrud(resultingState, crudData, request.Type);
  }
  return resultingState;
}

function mapMainClaimsUiStateToCrud(
  state: IMainClaimsData,
  crudData: MCClaimOptionsInfo
) {
  crudData.ComplianceGlobalSuppression["@SuppressCCIAI"] =
    state.ComplianceGlobalSuppression["@SuppressCCIAI"];
  crudData.ComplianceGlobalSuppression["@SuppressCCIBP"] =
    state.ComplianceGlobalSuppression["@SuppressCCIBP"];
  crudData.ComplianceGlobalSuppression["@SuppressMNBP"] =
    state.ComplianceGlobalSuppression["@SuppressMNBP"];
  crudData.ComplianceGlobalSuppression["@SuppressMNAI"] =
    state.ComplianceGlobalSuppression["@SuppressMNAI"];
  crudData.ServiceLineDescription["@Action"] =
    state.ServiceLineDescription["@Action"];
  crudData.OtherOptions["@DeleteWarningFlag"] =
    state.OtherOptions["@DeleteWarningFlag"];
  crudData.LinkBridgedSecondaryTertiary["@Enabled"] =
    state.LinkBridgedSecondaryTertiary["@Enabled"];
  crudData.ComplianceDetailSuppression["@HoldClaimsWithPropensityToDeny"] =
    state.ComplianceDetailSuppression["@HoldClaimsWithPropensityToDeny"];
  crudData.ComplianceDetailSuppression["@HoldClaimsWithHighP2DScore"] =
    state.ComplianceDetailSuppression["@HoldClaimsWithHighP2DScore"];
  crudData.StateReportingEdits["@Enabled"] =
    state.StateReportingEdits["@Enabled"];
  crudData.RequestRetryDelayHours["@Hours"] =
    state.RequestRetryDelayHours["@Hours"];
}

function updateMainClaimsOptions(
  state: IClaimOptionsUIState,
  request: IMainClaimOptionsUpdateRequest,
  crudData: MCClaimOptionsInfo | undefined
): IClaimOptionsUIState {
  let newState: IClaimOptionsUIState = state;
  switch (request.Name) {
    case "@ActionService":
      {
        newState.MainClaimsData.ServiceLineDescription["@Action"] =
          request.Value;
      }
      break;
    case "@RequestHours":
      {
        newState.MainClaimsData.RequestRetryDelayHours["@Hours"] =
          request.Value;
      }
      break;
    case "@ActionHisSystem":
      {
        newState.MainClaimsData.HISSourceSystemOption["@Action"] =
          request.Value;
      }
      break;
    case "@SuppressCCIBP":
      {
        newState.MainClaimsData.ComplianceGlobalSuppression["@SuppressCCIBP"] =
          request.Value;
        newState.CCIPayerSelectionOptions.SuppressCCIBP = request.Value == "Y";
      }
      break;
    case "@SuppressMNBP":
      {
        newState.MainClaimsData.ComplianceGlobalSuppression["@SuppressMNBP"] =
          request.Value;
        newState.MNPayerSelectionOptions.SuppressMNBP = request.Value == "Y";
      }
      break;
    case "@SuppressCCIAI":
      {
        newState.MainClaimsData.ComplianceGlobalSuppression["@SuppressCCIAI"] =
          request.Value;
        newState.CCIPayerSelectionOptions.SuppressCCIAI = request.Value == "Y";
      }
      break;
    case "@SuppressMNAI":
      {
        newState.MainClaimsData.ComplianceGlobalSuppression["@SuppressMNAI"] =
          request.Value;
        newState.MNPayerSelectionOptions.SuppressMNAI = request.Value == "Y";
      }
      break;
    case "@StateReportingEditsEnabled":
      {
        newState.MainClaimsData.StateReportingEdits["@Enabled"] = request.Value;
      }
      break;
    case "@DeleteWarningFlag":
      {
        newState.MainClaimsData.OtherOptions["@DeleteWarningFlag"] =
          request.Value;
      }
      break;
    case "@HoldClaimsWithPropensityToDeny":
      {
        newState.MainClaimsData.ComplianceDetailSuppression[
          "@HoldClaimsWithPropensityToDeny"
        ] = request.Value;
      }
      break;
    case "@HoldClaimsWithHighP2DScore":
      {
        newState.MainClaimsData.ComplianceDetailSuppression[
          "@HoldClaimsWithHighP2DScore"
        ] = request.Value;
      }
      break;
    case "@RemitEnabled": {
      newState.MainClaimsData.LinkBridgedSecondaryTertiary["@Enabled"] =
        request.Value;
    }
  }

  if (crudData) {
    mapMainClaimsUiStateToCrud(newState.MainClaimsData, crudData);
  }

  return newState;
}

function setRelatedClaimsErrorFields(
  state: IClaimOptionsUIState,
  uiData: IClaimOptionsUIError
) {
  let newRelatedClaimsOptionsState =
    uiData.type == "UB"
      ? state.RelatedClaimsOptionsUB
      : state.RelatedClaimsOptions1500;
  switch (uiData.id) {
    case "RelatedClaimsNoOptionsSelected":
      {
        newRelatedClaimsOptionsState.medRecNbEnabledError = uiData.value;
        newRelatedClaimsOptionsState.pcnEnabledError = uiData.value;
        newRelatedClaimsOptionsState.serviceDatesEnabledError = uiData.value;
        newRelatedClaimsOptionsState.dateOfBirthEnabledError = uiData.value;
      }
      break;
    case "RelatedClaimsSequenceMatchNotSelected":
      {
        newRelatedClaimsOptionsState.maskFixedLengthError = uiData.value;
      }
      break;
    case "RelatedClaimsDelimEmpty":
      {
        newRelatedClaimsOptionsState.delimError = uiData.value;
      }
      break;
    case "RelatedClaimsMedRecNorPCNSelected":
      {
        newRelatedClaimsOptionsState.medRecNbEnabledError = uiData.value;
        newRelatedClaimsOptionsState.pcnEnabledError = uiData.value;
      }
      break;
  }

  if (uiData.type == "UB")
    return {
      ...state,
      RelatedClaimsOptionsUB: newRelatedClaimsOptionsState,
    };
  else
    return {
      ...state,
      RelatedClaimsOptions1500: newRelatedClaimsOptionsState,
    };
}

function setDuplicateClaimsErrorFields(
  state: IClaimOptionsUIState,
  uiData: IClaimOptionsUIError
) {
  let newDuplicateClaimsOptionsState =
    uiData.type == "UB"
      ? state.DuplicateClaimsOptionsUB
      : state.DuplicateClaimsOptions1500;
  switch (uiData.id) {
    case "DuplicateClaimsNoOptionsSelected":
      {
        newDuplicateClaimsOptionsState.medRecNbEnabledError = uiData.value;
        newDuplicateClaimsOptionsState.pcnEnabledError = uiData.value;
        //newDuplicateClaimsOptionsState.serviceDatesEnabledError = uiData.value;
        //newDuplicateClaimsOptionsState.dateOfBirthEnabledError = uiData.value;
      }
      break;
    case "DuplicateClaimsMedRecNorPCNSelected":
      {
        newDuplicateClaimsOptionsState.medRecNbEnabledError = uiData.value;
        newDuplicateClaimsOptionsState.pcnEnabledError = uiData.value;
      }
      break;
  }

  if (uiData.type == "UB")
    return {
      ...state,
      DuplicateClaimsOptionsUB: newDuplicateClaimsOptionsState,
    };
  else
    return {
      ...state,
      DuplicateClaimsOptions1500: newDuplicateClaimsOptionsState,
    };
}

function updateMNGeneralOptionsState(
  state: IClaimOptionsUIState,
  request: IGeneralUpdateRequest,
  crudData: MCClaimOptionsInfo | undefined
): IClaimOptionsUIState {
  if (
    request.Action == "ADD" ||
    request.Action == "UPDATE" ||
    request.Action == "REMOVE"
  ) {
    let newArray = state.MNGeneralOptions.exclusionList.slice();
    if (request.Action == "REMOVE") {
      newArray = newArray.filter((item) => item["@ID"] != request.Value);
    } else if (request.Action == "ADD") {
      let INX = 1;
      while (
        state.MNGeneralOptions.exclusionList.find(
          (item) => item["@ID"] == "#" + INX.toString()
        )
      )
        INX++;
      request.Value["@ID"] = "#" + INX.toString();
      newArray.push(request.Value);
    } else if (request.Action == "UPDATE") {
      let updatedItemIndex = newArray.findIndex(
        (item) => item["@ID"] == request.Value["@ID"]
      );
      newArray[updatedItemIndex] = request.Value;
    }

    // keep crud updated
    if (crudData) mapMNExclusionsUiStateToCrud(crudData.MNExclusions, newArray);

    return {
      ...state,
      MNGeneralOptions: { ...state.MNGeneralOptions, exclusionList: newArray },
    };
  } else if (request.Action == "UPDATEFIELD") {
    let resultingState: IClaimOptionsUIState = state;
    switch (request.Name) {
      case "@RevenueException": {
        resultingState.MNGeneralOptions.revenueException = request.Value;
        break;
      }
      case "@OccurrenceException": {
        resultingState.MNGeneralOptions.occurrenceException = request.Value;
        break;
      }
      case "@ConditionException": {
        resultingState.MNGeneralOptions.conditionException = request.Value;
        break;
      }
      case "@FinancialClassException": {
        resultingState.MNGeneralOptions.financialClassException = request.Value;
        break;
      }
      case "@TypeOfBillException": {
        resultingState.MNGeneralOptions.typeOfBillException = request.Value;
        break;
      }
      case "@RevenueSLException": {
        resultingState.MNGeneralOptions.revenueSLException = request.Value;
        break;
      }
      case "@InstitutionalSLModifier": {
        resultingState.MNGeneralOptions.instSLModifier = request.Value;
        break;
      }
      case "@InstitutionalSLProcedure": {
        resultingState.MNGeneralOptions.instSLProcedure = request.Value;
        break;
      }
      case "@ProfessionalSLModifier": {
        resultingState.MNGeneralOptions.profSLModifier = request.Value;
        break;
      }
      case "@ProfessionalSLProcedure": {
        resultingState.MNGeneralOptions.profSLProcedure = request.Value;
        break;
      }
      case "@NonCoveredException": {
        resultingState.MNGeneralOptions.nonCoveredException = request.Value;
        break;
      }
      default:
        break;
    }
    if (crudData) {
      mapMNGeneralOptionsUiStateToCrud(resultingState, crudData);
    }
    return resultingState;
  }

  return state;
}

function mapMNGeneralOptionsUiStateToCrud(
  state: IClaimOptionsUIState,
  crudData: MCClaimOptionsInfo
) {
  crudData.ComplianceDetailSuppression["@RevenueException"] =
    state.MNGeneralOptions.revenueException;
  crudData.ComplianceDetailSuppression["@OccurrenceException"] =
    state.MNGeneralOptions.occurrenceException;
  crudData.ComplianceDetailSuppression["@ConditionException"] =
    state.MNGeneralOptions.conditionException;
  crudData.ComplianceDetailSuppression["@FinancialClassException"] =
    state.MNGeneralOptions.financialClassException;
  crudData.ComplianceDetailSuppression["@TypeOfBillException"] =
    state.MNGeneralOptions.typeOfBillException;
  crudData.ComplianceDetailSuppression["@RevenueSLException"] =
    state.MNGeneralOptions.revenueSLException;
  crudData.ComplianceDetailSuppression["@InstitutionalSLModifier"] =
    state.MNGeneralOptions.instSLModifier;
  crudData.ComplianceDetailSuppression["@InstitutionalSLProcedure"] =
    state.MNGeneralOptions.instSLProcedure;
  crudData.ComplianceDetailSuppression["@ProfessionalSLModifier"] =
    state.MNGeneralOptions.profSLModifier;
  crudData.ComplianceDetailSuppression["@ProfessionalSLProcedure"] =
    state.MNGeneralOptions.profSLProcedure;
  crudData.ComplianceDetailSuppression["@NonCoveredException"] =
    state.MNGeneralOptions.nonCoveredException;
}

function mapMNExclusionsUiStateToCrud(
  crudData: MCClaimOptionsMNExclusions | undefined,
  newMNExclusions: MCClaimOptionsMNExclusion[]
) {
  if (!crudData) crudData = { MNExclusion: [] };
  if (
    !crudData.MNExclusion ||
    (crudData.MNExclusion && !Array.isArray(crudData.MNExclusion))
  )
    crudData.MNExclusion = [];
  crudData!.MNExclusion = JSON.parse(JSON.stringify(newMNExclusions));
}

function updateMNPayerSelectionState(
  state: IClaimOptionsUIState,
  request: IPayerSelectionUpdateRequest,
  crudData: MCClaimOptionsMNEdits | undefined
): IClaimOptionsUIState {
  switch (request.Action) {
    case "REMOVE": {
      let newArray = state.MNPayerSelectionOptions.MNEdits.slice();
      newArray = newArray.filter((mnEdit) => mnEdit["@ID"] != request.Value);

      // keep crud updated
      mapMNPayerselectionUiStateToCrud(crudData, newArray);

      return {
        ...state,
        MNPayerSelectionOptions: {
          ...state.MNPayerSelectionOptions,
          MNEdits: newArray,
        },
      };
    }
    case "UPDATE": {
      let newArray = state.MNPayerSelectionOptions.MNEdits.slice();
      let updatedMNEditIndex = newArray.findIndex(
        (mnEdit) => mnEdit["@ID"] == request.Value["@ID"]
      );
      newArray[updatedMNEditIndex] = request.Value;

      // keep crud updated
      mapMNPayerselectionUiStateToCrud(crudData, newArray);

      return {
        ...state,
        MNPayerSelectionOptions: {
          ...state.MNPayerSelectionOptions,
          MNEdits: newArray,
        },
      };
    }
    case "ADD": {
      let INX = 1;
      while (
        state.MNPayerSelectionOptions.MNEdits.find(
          (mnEdit) => mnEdit["@ID"] == "#" + INX.toString()
        )
      )
        INX++;
      request.Value["@ID"] = "#" + INX.toString();
      let newArray = state.MNPayerSelectionOptions.MNEdits.slice();
      newArray.push(request.Value);

      // keep crud updated
      mapMNPayerselectionUiStateToCrud(crudData, newArray);

      return {
        ...state,
        MNPayerSelectionOptions: {
          ...state.MNPayerSelectionOptions,
          MNEdits: newArray,
        },
      };
    }
    default:
      break;
  }
  return state;
}

function mapMNPayerselectionUiStateToCrud(
  crudData: MCClaimOptionsMNEdits | undefined,
  newMNEdits: MCClaimOptionsMNEdit[]
) {
  if (!crudData) crudData = { MNEdit: [] };
  if (!crudData.MNEdit || (crudData.MNEdit && !Array.isArray(crudData.MNEdit)))
    crudData.MNEdit = [];
  crudData!.MNEdit = JSON.parse(JSON.stringify(newMNEdits));
}

function updateCCIGeneralOptionsState(
  state: IClaimOptionsUIState,
  request: IGeneralUpdateRequest,
  crudData: MCClaimOptionsInfo | undefined
): IClaimOptionsUIState {
  if (
    request.Action == "ADD" ||
    request.Action == "UPDATE" ||
    request.Action == "REMOVE"
  ) {
    let newArray = state.CCIGeneralOptions.exclusionList.slice();
    if (request.Action == "REMOVE") {
      newArray = newArray.filter((item) => item["@ID"] != request.Value);
    } else if (request.Action == "ADD") {
      let INX = 1;
      while (
        state.CCIGeneralOptions.exclusionList.find(
          (item) => item["@ID"] == "#" + INX.toString()
        )
      )
        INX++;
      request.Value["@ID"] = "#" + INX.toString();
      newArray.push(request.Value);
    } else if (request.Action == "UPDATE") {
      let updatedItemIndex = newArray.findIndex(
        (item) => item["@ID"] == request.Value["@ID"]
      );
      newArray[updatedItemIndex] = request.Value;
    }

    console.log(
      "vi - updateCCIGeneralOptionsState - newArray=" + JSON.stringify(newArray)
    );
    // keep crud updated
    if (crudData)
      mapCCIExclusionsUiStateToCrud(crudData.CCIExclusions, newArray);

    return {
      ...state,
      CCIGeneralOptions: {
        ...state.CCIGeneralOptions,
        exclusionList: newArray,
      },
    };
  } else if (request.Action == "UPDATEFIELD") {
    let resultingState: IClaimOptionsUIState = state;
    switch (request.Name) {
      case "@BillType": {
        resultingState.CCIGeneralOptions.billType = request.Value;
        break;
      }
      case "@CustomModifier": {
        resultingState.CCIGeneralOptions.customModifier = request.Value;
        break;
      }
      case "@FinancialClass": {
        resultingState.CCIGeneralOptions.financialClass = request.Value;
        break;
      }
      case "@BillTypeMedicaid": {
        resultingState.CCIGeneralOptions.billTypeMed = request.Value;
        break;
      }
      case "@CustomModifierMedicaid": {
        resultingState.CCIGeneralOptions.customModifierMed = request.Value;
        break;
      }
      case "@FinancialClassMedicaid": {
        resultingState.CCIGeneralOptions.financialClassMed = request.Value;
        break;
      }
      default:
        break;
    }
    if (crudData) {
      mapCCIGeneralOptionsUiStateToCrud(resultingState, crudData);
    }
    return resultingState;
  }

  return state;
}

function mapCCIGeneralOptionsUiStateToCrud(
  state: IClaimOptionsUIState,
  crudData: MCClaimOptionsInfo
) {
  crudData.ComplianceDetailSuppression["@CCIBillType"] =
    state.CCIGeneralOptions.billType;
  crudData.ComplianceDetailSuppression["@CCIModifiers"] =
    state.CCIGeneralOptions.customModifier;
  crudData.ComplianceDetailSuppression["@CCIFinancialClass"] =
    state.CCIGeneralOptions.financialClass;
  crudData.ComplianceDetailSuppression["@CCIBillTypeMedicaid"] =
    state.CCIGeneralOptions.billTypeMed;
  crudData.ComplianceDetailSuppression["@CCIModifiersMedicaid"] =
    state.CCIGeneralOptions.customModifierMed;
  crudData.ComplianceDetailSuppression["@CCIFinancialClassMedicaid"] =
    state.CCIGeneralOptions.financialClassMed;
}

function mapCCIExclusionsUiStateToCrud(
  crudData: MCClaimOptionsCCIExclusions | undefined,
  newExclusions: MCClaimOptionsCCIExclusion[]
) {
  if (!crudData) crudData = { CCIExclusion: [] };
  if (
    !crudData.CCIExclusion ||
    (crudData.CCIExclusion && !Array.isArray(crudData.CCIExclusion))
  )
    crudData.CCIExclusion = [];
  crudData!.CCIExclusion = JSON.parse(JSON.stringify(newExclusions));
}

function updateCCIPayerSelectionState(
  state: IClaimOptionsUIState,
  request: IPayerSelectionUpdateRequest,
  crudData: MCClaimOptionsCCIEdits | undefined
): IClaimOptionsUIState {
  let newArray: MCClaimOptionsCCIEdit[] = [];
  switch (request.Action) {
    case "REMOVE": {
      newArray = state.CCIPayerSelectionOptions.CCIEdits.slice();
      newArray = newArray.filter((edit) => edit["@ID"] != request.Value);
      break;
    }
    case "UPDATE": {
      newArray = state.CCIPayerSelectionOptions.CCIEdits.slice();
      let updateEditIndex = newArray.findIndex(
        (edit) => edit["@ID"] == request.Value["@ID"]
      );
      newArray[updateEditIndex] = request.Value;
      break;
    }
    case "ADD": {
      let INX = 1;
      while (
        state.CCIPayerSelectionOptions.CCIEdits.find(
          (edit) => edit["@ID"] == "#" + INX.toString()
        )
      )
        INX++;
      request.Value["@ID"] = "#" + INX.toString();
      newArray = state.CCIPayerSelectionOptions.CCIEdits.slice();
      newArray.push(request.Value);
      break;
    }
    default:
      return state;
  }

  // keep crud updated
  mapCCIPayerselectionUiStateToCrud(crudData, newArray);

  return {
    ...state,
    CCIPayerSelectionOptions: {
      ...state.CCIPayerSelectionOptions,
      CCIEdits: newArray,
    },
  };
}

function mapCCIPayerselectionUiStateToCrud(
  crudData: MCClaimOptionsCCIEdits | undefined,
  newCCIEdits: MCClaimOptionsCCIEdit[]
) {
  if (!crudData) crudData = { CCIEdit: [] };
  if (
    !crudData.CCIEdit ||
    (crudData.CCIEdit && !Array.isArray(crudData.CCIEdit))
  )
    crudData.CCIEdit = [];
  crudData!.CCIEdit = JSON.parse(JSON.stringify(newCCIEdits));
}

function updateMNPolicySelectionState(
  state: IClaimOptionsUIState,
  request: IMNPolicySelectionUpdateRequest,
  crudData: MCClaimOptionsMNClientPolicySeverities | undefined
): IClaimOptionsUIState {
  let newData =
    state.MNPolicySelectionOptions.policySearchResults.MNPolicy.slice();
  let newSelectionOptions = state.MNPolicySelectionOptions;
  if (request.Value.record) {
    let updatedMNPolicyIndex = newData.findIndex(
      (policy: any) => policy["@PolicyId"] == request.Value.record["@PolicyId"]
    );
    newData[updatedMNPolicyIndex]["@Severity"] =
      request.Value.record["@Severity"];

    const length = newSelectionOptions.policySearchResults.MNPolicy.length;
    newSelectionOptions.policySearchResults.MNPolicy =
      newSelectionOptions.policySearchResults.MNPolicy.splice(
        0,
        length,
        ...newData
      );
    // !!! Here we are actually not going to store it straight into the crud because when they use the paging buttons to go to another page and they click YES in the dialog box ,
    // the changes they previously made should be erased. We will add the data to the crud just before saving when user clicks on the OK button.
    let updatedRecordState = newSelectionOptions.policyPageChanges.find(
      (record: MCClaimOptionsMNClientPolicySeverity) =>
        record["@PolicyID"] === request.Value.record["@PolicyId"]
    );
    if (!updatedRecordState) {
      newSelectionOptions.policyPageChanges.push({
        "@ID": request.Value.record["@PolicyId"],
        "@PolicyID": request.Value.record["@PolicyId"],
        "@Severity": request.Value.record["@Severity"],
      });
    } else {
      updatedRecordState["@Severity"] = request.Value.record["@Severity"];
    }
    // keep crud updated
    //mapMNPolicyselectionUiStateToCrud(crudData, request.Value);
  }
  if (request.Value.totalPages) {
    newSelectionOptions.totalPages = request.Value.totalPages;
  }
  if (request.Value.currentPage) {
    newSelectionOptions.currentPage = request.Value.currentPage;
  }
  return {
    ...state,
    MNPolicySelectionOptions: newSelectionOptions,
  };
}

//function mapMNPolicyselectionUiStateToCrud(crudData: MCClaimOptionsMNClientPolicySeverities | undefined, request: any) {
//    if (!crudData) crudData = { MNClientPolicySeverity: [] };
//    if (!crudData.MNClientPolicySeverity || (crudData?.MNClientPolicySeverity && !Array.isArray(crudData.MNClientPolicySeverity))) crudData.MNClientPolicySeverity = [];
//    let updatedCrudData = [...crudData?.MNClientPolicySeverity]
//    const updatedSeverity = updatedCrudData?.find((item) => {
//       return item['@PolicyID'] === request['@PolicyId']
//    });
//    if (updatedSeverity) {
//        updatedSeverity['@Severity'] = request['@Severity']
//    } else {
//        updatedCrudData?.push({ "@PolicyID": request['@PolicyId'], '@ID': request['@PolicyId'], '@Severity': request['@Severity'],   })
//    }
//    crudData!.MNClientPolicySeverity = JSON.parse(JSON.stringify(updatedCrudData));
//}

function updateMNPolicySearchState(
  state: IClaimOptionsUIState,
  request: IMNPolicyUpdateSearchRequestData,
  crudData: MNPolicySearch | undefined
): IClaimOptionsUIState {
  let mnPolicies = request.MNPolicySearch.MNPolicies;
  if (mnPolicies == undefined || (mnPolicies as any) === "") {
    mnPolicies = {
      MNPolicy: [],
      "@PolicyID": "",
      "@Description": "",
      "@Reference": "",
    };
  } else
    mnPolicies.MNPolicy = enforceArray<MNPolicy>(
      request.MNPolicySearch.MNPolicies.MNPolicy
    );

  const newState = Object.assign({}, state);
  // First clean-up any previous changes, because when they change page (and thus do a new search, their previous policy changes are discarded)
  newState.MNPolicySelectionOptions.policyPageChanges = [];
  newState.MNPolicySelectionOptions.totalPages =
    request.MNPolicySearch?.TotalPages?.TotalPageCount &&
    request.MNPolicySearch.TotalPages.TotalPageCount["@TotalPageCount"]
      ? request.MNPolicySearch.TotalPages.TotalPageCount["@TotalPageCount"]
      : "1";
  console.log(mnPolicies, "POLICIES in updateMNPOLICY SEARCH STATe");
  if (newState.MNPolicySelectionOptions.totalPages != "0") {
    newState.MNPolicySelectionOptions.policySearchResults = mnPolicies;
  }

  return state;
}

export const claimOptionsUIReducer: Reducer<
  IClaimOptionsUIState,
  KnownActions
> = (state: IClaimOptionsUIState | undefined, action: KnownActions) => {
  const isDirty = true;
  let newState = state && { ...state };

  if (state) {
    switch (action.type) {
      case "INIT_CLAIM_OPTIONS": {
        const crud = action.data.uiData;
        if (crud) return mapCrudToUiState(crud);
        break;
      }
      case "EDIT_CLAIM_OPTIONS_DATA": {
        newState = { ...state, uiData: action.data.uiData, isDirty };
        //                if (crudData) crudData.ChildNode = mapUiStateToCrud(newState);
        break;
      }
      case "RESET_CLAIM_OPTIONS_DIRTY":
        newState = { ...state, isDirty: false };
        break;

      case "ERROR_CLAIM_OPTIONS_FIELD":
        if (action.data.uiData.id && action.data.uiData.id.length > 0) {
          switch (action.data.uiData.id) {
            case "RelatedClaimsNoOptionsSelected":
            case "RelatedClaimsSequenceMatchNotSelected":
            case "RelatedClaimsDelimEmpty":
            case "RelatedClaimsMedRecNorPCNSelected":
              let newState = setRelatedClaimsErrorFields(
                state,
                action.data.uiData
              );
              return newState;
            case "DuplicateClaimsNoOptionsSelected":
            case "DuplicateClaimsMedRecNorPCNSelected":
              let dupState = setDuplicateClaimsErrorFields(
                state,
                action.data.uiData
              );
              return dupState;
          }
        }
        break;
      case "GET_FI_DATA":
        switch (action.status.status) {
          case "VALIDATE":
          case "REQUEST":
            console.log("Retrieving FI data...");
          case "SUCCESS":
            console.log("Retrieving FI data Successful!");
            let newState: IClaimOptionsUIState = state;
            newState.MNPayerSelectionOptions.FIDetailList = forceToArray(action.responseData);
            return {
              ...newState,
            };
          case "FAIL":
            console.log("Retrieving FI data Failed!");
        }
        break;

      case "CLAIM_OPTIONS_SEND_ALERT":
        return {
          ...state,
          alertMessage: action.data,
        };

      case "UPDATE_RELATED_CLAIMS_OPTIONS": {
        const crudData = action.data?.masterCrud?.ClaimOptionsMaintenanceInfo;
        let newState = updateRelatedClaimsOptionsState(
          state,
          action.data.uiData,
          crudData?.CustomEdits?.RelatedClaimSearchConfig
        );
        return newState;
      }

      case "UPDATE_DUPLICATE_CLAIMS_OPTIONS": {
        const crudData = action.data?.masterCrud?.ClaimOptionsMaintenanceInfo;
        let newState = updateDuplicateClaimsOptionsState(
          state,
          action.data.uiData,
          crudData?.CustomEdits?.DuplicateConfig
        );
        return newState;
      }

      case "UPDATE_MN_GENERAL_OPTIONS": {
        const crudData = action.data?.masterCrud?.ClaimOptionsMaintenanceInfo;
        let newState = updateMNGeneralOptionsState(
          state,
          action.data.uiData,
          crudData
        );
        return { ...newState };
      }
      case "UPDATE_MN_PAYER_SELECTION_OPTIONS": {
        const crudData = action.data?.masterCrud?.ClaimOptionsMaintenanceInfo;
        let newState = updateMNPayerSelectionState(
          state,
          action.data.uiData,
          crudData?.MNEdits
        );
        return newState;
      }

      case "UPDATE_CCI_GENERAL_OPTIONS": {
        const crudData = action.data?.masterCrud?.ClaimOptionsMaintenanceInfo;
        let newState = updateCCIGeneralOptionsState(
          state,
          action.data.uiData,
          crudData
        );
        return { ...newState };
      }

      case "UPDATE_CCI_PAYER_SELECTION_OPTIONS": {
        const crudData = action.data?.masterCrud?.ClaimOptionsMaintenanceInfo;
        let newState = updateCCIPayerSelectionState(
          state,
          action.data.uiData,
          crudData?.CCIEdits
        );
        return newState;
      }

      case "UPDATE_MAIN_CLAIMS_OPTIONS": {
        const crudData = action.data?.masterCrud?.ClaimOptionsMaintenanceInfo;
        let newState = updateMainClaimsOptions(
          state,
          action.data.uiData,
          crudData
        );
        return { ...newState };
      }
      case "UPDATE_MNPOLICY_ROWS": {
        const crudData = action.data?.masterCrud?.ClaimOptionsMaintenanceInfo;
        newState = updateMNPolicySelectionState(
          state,
          action.data.uiData,
          crudData?.MNClientPolicySeverities
        );
        return { ...newState };
      }
      case "UPDATE_MNPOLICY_SEARCH_RESULTS": {
        const crudData = action.data?.MNPolicySearch;
        newState = updateMNPolicySearchState(state, action.data, crudData);
        return { ...newState };
      }
      case "IGNORE_CRUD_UPDATE": {
        return { ...state };
      }
      case "UPDATE_MN_POLICY_LOCAL_STATE": {
        let newState: IClaimOptionsUIState = state;
        const desc = action.data?.description;
        const selectedSeverity = action.data?.selectedSeverity;
        newState.MNPolicySelectionOptions.description = desc ? desc : "";
        newState.MNPolicySelectionOptions.selectedSeverity = selectedSeverity
          ? selectedSeverity
          : "E";
        return { ...newState };
      }

      default:
        // The following line guarantees that every action in the KnownAction union has been covered by a case above
        //const exhaustiveCheck: never = action;
        return state;
    }
  }
  return newState || defaultClaimOptionsState;
};
