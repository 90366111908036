import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as DashboardMaintenanceUI from './ui/DashboardMaintenanceUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray } from '@scripts/util/ValidationHelpers';

export type IDashboardMaintenanceState = ICrudComponentState<MCDashboardMaintenance, DashboardMaintenanceUI.IDashboardMaintenanceUIState>;

export const actionCreators = createCrudComponentActions<DashboardMaintenanceUI.ActionCreators>(DashboardMaintenanceUI.actionCreators);
export type IDashboardMaintenanceActions = typeof actionCreators;
export type IDashboardMaintenanceActionProps = ICrudComponentActionProps<IDashboardMaintenanceActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiDashboard, DashboardMaintenanceUI.reducer);
export const reducer = combineReducers<IDashboardMaintenanceState>(reducers);

export const validationCallback: ValidationCallback<MCDashboardMaintenance> = (crud: any): crud is MCDashboardMaintenance => {
    let testCrud: MCDashboardMaintenance = crud as MCDashboardMaintenance;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCDashboardMaintenance");
    if (validShape) {

        if (testCrud.DashboardMaintenanceInfo?.Events as any === "") {
            testCrud.DashboardMaintenanceInfo.Events = { Event: new Array<MCDashboardEvent>() };
        }

        if (testCrud.DashboardMaintenanceInfo.ContactList as any === "") {
            testCrud.DashboardMaintenanceInfo.ContactList = { ContactEmail: new Array<MCDashboardContact>() };
        }

        if (testCrud.DashboardMaintenanceInfo.DashboardChartList as any === "") {
            testCrud.DashboardMaintenanceInfo.DashboardChartList = { Chart: new Array<MCDashboardChart>() };
        }

        if (testCrud.DashboardMaintenanceInfo.DashboardChartList &&
            !Array.isArray(testCrud.DashboardMaintenanceInfo.DashboardChartList.Chart)) {
            let arr = new Array<MCDashboardChart>();
            arr.push(JSON.parse(JSON.stringify(testCrud.DashboardMaintenanceInfo.DashboardChartList.Chart)));
            testCrud.DashboardMaintenanceInfo.DashboardChartList.Chart = arr;
        }

        if (testCrud.DashboardMaintenanceInfo.ContactList &&
            !Array.isArray(testCrud.DashboardMaintenanceInfo.ContactList.ContactEmail)) {
            let arr = new Array<MCDashboardContact>();
            arr.push(JSON.parse(JSON.stringify(testCrud.DashboardMaintenanceInfo.ContactList.ContactEmail)));
            testCrud.DashboardMaintenanceInfo.ContactList.ContactEmail = arr;
        }

        if (testCrud.DashboardMaintenanceInfo.Events &&
            !Array.isArray(testCrud.DashboardMaintenanceInfo.Events.Event)) {
            let arr = new Array<MCDashboardEvent>();
            arr.push(JSON.parse(JSON.stringify(testCrud.DashboardMaintenanceInfo.Events.Event)));
            testCrud.DashboardMaintenanceInfo.Events.Event = arr;
        }

    }
    return validShape;
}
