import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as SpinOffStore from './api/SpinOffStore';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyArray, ValidateArray, VerifyParentAndArray } from '@scripts/util/ValidationHelpers';


export type ISpinOffState = IApiComponentState<any, SpinOffStore.ISpinOffUIState>;

export const actionCreators = createApiComponentActions<SpinOffStore.ActionCreators>(SpinOffStore.actionCreators);
export type ISpinOffActions = typeof actionCreators;
export type ISpinOffActionProps = IApiComponentActionProps<ISpinOffActions>

export const reducers = createApiComponentReducers("spinoff", SpinOffStore.reducer);
export const reducer = combineReducers<ISpinOffState>(reducers);

export const validationCallback: ValidationCallback<SOSelectionInfoType> = (crud: any): crud is SOSelectionInfoType => {
    let testCrud: SOSelectionInfoType = crud as SOSelectionInfoType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "SOSelectionInfoType");
    if (validShape) {

        if (testCrud.SpinOffSelectionInfo.SpinOffFormMaps &&
            !Array.isArray(testCrud.SpinOffSelectionInfo.SpinOffFormMaps.SpinOffFormMap)) {
            let arr = new Array<FormMapList>();
            arr.push(JSON.parse(JSON.stringify(testCrud.SpinOffSelectionInfo.SpinOffFormMaps.SpinOffFormMap)));
            testCrud.SpinOffSelectionInfo.SpinOffFormMaps.SpinOffFormMap = arr;
        }
    }

    return validShape;
}
