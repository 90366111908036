import { combineReducers, Reducer } from 'redux';
import { IApiComponentState, IApiComponentActionProps, createApiComponentActions, createApiComponentReducers } from '@scripts/util/ApiDataHelpers';
import * as SelectReasonCodeStore from './api/SelectReasonCodeStore';

export type ISelectReasonCodeState = IApiComponentState<any, SelectReasonCodeStore.ISelectReasonCodeUIState>;

export const actionCreators = createApiComponentActions<SelectReasonCodeStore.ActionCreators>(SelectReasonCodeStore.actionCreators);
export type ISelectReasonCodeActions = typeof actionCreators;
export type ISelectReasonCodeActionProps = IApiComponentActionProps<ISelectReasonCodeActions>

export const reducers = createApiComponentReducers("selectReasonCode", SelectReasonCodeStore.reducer);
export const reducer = combineReducers<ISelectReasonCodeState>(reducers);
