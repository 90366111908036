import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ApplicationState } from "@store/index";

import {
  IMergeApiComponentProps,
  createApiMapStateToProps,
  createApiMapDispatchToProps,
  mergeApiComponentProps,
  resetApiComponentState
} from "@scripts/util/ApiDataHelpers";

import {
  IClaimFilterState,
  IClaimFilterActions,
  IClaimFilterActionProps,
  actionCreators /*, validationCallback */
} from "@store/ClaimFilter";

import { ClaimFilter, IClaimFilterComponentProps } from "./ClaimFilter";
import { SecurityBits } from "../../../commonResources/SecurityFunctionList";

export interface IViewEditFilterComponentState {}

//---------------------------------------------------------------

export const DEFAULT_STATE: IViewEditFilterComponentState = {};

type IOwnProps = IClaimFilterComponentProps & RouteComponentProps<{}>;

type IClaimFilterProps = IMergeApiComponentProps<
  IClaimFilterState,
  IClaimFilterActionProps,
  IOwnProps
>;

export class DirectValidate extends React.Component<
  IClaimFilterProps,
  IViewEditFilterComponentState
> {
  static defaultProps: IClaimFilterComponentProps = {
    canEdit: false,
    canView: false,
    canCreate: false,
    ncsIsEnabled: false,

    process: "Validate",
    parameter: {},
    apiType: "ClaimFilter",
    title: "Direct Entry >> Check Edits", // ClaimTitle
    execNode: "MultiClaim", // ExecNode
    execAction: "MedicareDirect",
    execProcess: "Validate", 
    extraInstruction: "Click Continue to begin the DE job without viewing the claim list.", // ExtraInstruction
    claimStateRestrictions: ["", "1"], 
  };

  constructor(props: IClaimFilterProps) {
    super(props);
    this.state = DEFAULT_STATE;
  }

  public componentDidMount() {
    if (!this.props.canView) {
      console.log("user did not have rights to " + this.props.title);
      this.props.history.push("/LandingPage");
    }

    //     console.log('can edit ' + this.props.canEdit + ' can view ' + this.props.canView);
  }

  public componentWillUnmount() {
    resetApiComponentState(this.props.action, this.props.dataStore);
  }

  public onOk = (baseThis: any, param1: any): boolean => {
    //      alert(baseThis.props.apiType + ':' + param1 + ':' + this.props.apiType);
    return true;
  };

  public render() {
    return (
      <div>
        <ClaimFilter {...this.props} />
      </div>
    );
  }
}

var connectedHoc = connect<
  IClaimFilterState,
  IClaimFilterActionProps,
  IOwnProps,
  IClaimFilterProps,
  ApplicationState
>(
  createApiMapStateToProps("claimFilter"),
  createApiMapDispatchToProps(actionCreators),
  mergeApiComponentProps
)(DirectValidate);

export default withRouter(connectedHoc);
