import * as React from 'react';
import { ARMGrid } from '@common/UICLWrappers/ARMGrid';
import { Typography} from 'ui-core';
import { Colors } from '@commonResources/colorVariables';
import styled from 'styled-components';
import _ from 'lodash';
import { ISortIndicator, SortDataType, SortHelper } from '@scripts/util/SortHelper';

const textHeight = 13
const textPadding = 8

const tableEdgePaddingForHeaderAndData = 10

const Tip = styled.div`
    overflow: hidden;
    white-space: nowrap;
`;

interface IStyledComponentProps {
    styleArry: string[];
    cursor: string;
}

const ListContainerDiv = styled.div`
    cursor:${(props:IStyledComponentProps) => props.cursor}
    color: ${Colors.black};
    ${Typography.default};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .TableListData{
        ${Typography.small};
    }
    /*remove empty header created by UICL*/
    *>th:last-child{ 
        display: none; 
    }
    ${(props: IStyledComponentProps) => props.styleArry.join("")} 
`;



const TableListHeaderDiv = styled.div`
    color: ${Colors.black};
    ${Typography.default};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
    ${Typography.defaultLineHeight};
    ${Typography.defaultHeight};
    margin-bottom: ${textPadding * 2}px;
    white-space: pre-wrap;
    text-align: center;
`;

const SubTableListHeaderDiv = styled.div`
    color: ${Colors.black};
    ${Typography.default};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
    ${Typography.defaultLineHeight};
    ${Typography.defaultHeight};
    margin-bottom: ${textPadding}px;
    height: ${textHeight}px;
    white-space: pre-wrap;
    text-align: center;
`;

interface IComponentProps {
    title?: any;
    subTitle?: string;
    maxHeight?: string;
    columns: any[];
    records: any[];
    onClick?: () => any;
    domID?: string;
    tooltip?: string;
    backgroundColor?: string;
    width?: number;
};

interface IComponentState {
    styleArry: string[];
    sortOrder: string;
};

export default class ReadOnlyList extends React.Component<IComponentProps, IComponentState> {

    constructor(props: IComponentProps) {
        super(props);

        const sortableColumns = this.props.columns.filter((c) => { return c.sortable })
        this.state = {
            styleArry: [],
            sortOrder: ""
        };
    }

    public getCustomRow = () => {
        const row = ({ record }: any) => {
            const recordId = this.props.domID + "" + record.id;

            return (
                <tr
                    id={recordId}
                    key={recordId}
                >
                    {this.getCustomDataArry(record)}
                </tr>
            );
        }

        return row
    }

    public getCustomDataArry = (record: any) => {
        const { columns, domID, onClick, records, width } = this.props;
        const globalOnClick = onClick;
        const widthOffset = (14 / records.length)
        return columns.map((c: any) => {
            const recordDataId = domID + "" + record.id + c.dataName;

            let tableDataOnClick;
            let cursor = "pointer";
            let textDecorationLine = "none";
            if (globalOnClick) {
                tableDataOnClick = () => { };
            } else if (record[c.dataName] && record[c.dataName].onClick) {
                tableDataOnClick = record[c.dataName].onClick;
                textDecorationLine = "underline";
            } else {
                tableDataOnClick = () => { };
                cursor = "default";
            };

            let tableDataText="";
            if (record[c.dataName] && record[c.dataName].text) {
                tableDataText = record[c.dataName].text;
            };

            return (
                <td
                    onClick={tableDataOnClick}
                    id={recordDataId}
                    key={recordDataId}
                    className={"TableListData"}
                    style={{
                        maxWidth: width ? undefined : (c.width > widthOffset ? c.width - widthOffset : c.width),
                        minWidth: width ? undefined : (c.width > widthOffset ? c.width - widthOffset : c.width),
                        height: textHeight,
                        whiteSpace: record[c.dataName] && record[c.dataName].isWrap ? "none" : "nowrap",
                        textAlign: record[c.dataName] && record[c.dataName].isWrap ? "left" : "",
                        overflow: "hidden",
                        verticalAlign: "middle",
                        padding: "4px 0px 4px 4px",
                        cursor,
                        textDecorationLine
                    }}
                >
                    {tableDataText}
                </td>
            );
        });

    };

    getRightAlignTextStyleFromProps = (props: any) => {
        return (`
    *>th:nth-child(${props.nthElement}){
        padding: 0;
        span{
            text-align: right;
            padding: 0;
        }
        button{
            float: right;
            padding: 0;
        }
    }
    *>tr{
        td:nth-child(${props.nthElement}){
            text-align: right;
        }
    }
    `)
    }

    getColumnStyleFromProps = (index: any, columns: any) => {
        const tableWidth = this.props.width
        const standardDataPadding = 2;
        const width = columns.length && tableWidth ? (tableWidth / columns.length) : (columns[index].width ?? "")
        const paddingRight = (index === columns.length - 1) ? tableEdgePaddingForHeaderAndData : standardDataPadding
        const paddingLeft = (index === 0) ? tableEdgePaddingForHeaderAndData : standardDataPadding;

        const tableChildStyle = `:nth-child(${index + 1}){
                padding: ${standardDataPadding}px !important;
                padding-left: ${paddingLeft}px !important;
                padding-right: ${paddingRight}px !important;
                min-width: ${width}px !important;
                max-width: ${width}px !important;
            }`

        return (`*>th${tableChildStyle}*>td${tableChildStyle}`)
    }

    sortingComparator = (sortDataName: string, sortDirectionString: string, records: any[]) => {
        const columnSortDataType = this.props.columns.find(c => c.dataName === sortDataName)?.SortDataType 
        const sortIndicator = {
            sortColumn: sortDataName,
            sortDirection: sortDirectionString === 'SORT_ASCENDING' ? 'down' : 'up',
            sortDataType: columnSortDataType ?? "String"
        }

        let sortedRecords: any[] = records;

        // sort by string
        if (sortIndicator.sortDataType === "String") {
            if (sortIndicator.sortDirection == 'up') {
                sortedRecords = records.sort((a, b) => {
                    const x = a[sortIndicator.sortColumn]?.text ?? ""
                    const y = b[sortIndicator.sortColumn]?.text ?? ""
                    return x.toString().localeCompare(y.toString())
                });
            } else {
                sortedRecords = records.sort((a, b) => {
                    const x = a[sortIndicator.sortColumn]?.text ?? ""
                    const y = b[sortIndicator.sortColumn]?.text ?? ""
                    return y.toString().localeCompare(x.toString())
                });
            }

            // sort by number
        } else if (sortIndicator.sortDataType === "Number") {
            if (sortIndicator.sortDirection == 'up') {
                sortedRecords = records.sort((a, b) => {
                    const x = (a[sortIndicator.sortColumn]?.text ?? "").replace(/[^0-9.]/g, '')
                    const y = (b[sortIndicator.sortColumn]?.text ?? "").replace(/[^0-9.]/g, '')
                    return parseFloat(x.toString()) - parseFloat(y.toString())
                });
            } else {
                sortedRecords = records.sort((a, b) => {
                    const x = (a[sortIndicator.sortColumn]?.text ?? "").replace(/[^0-9.]/g, '')
                    const y = (b[sortIndicator.sortColumn]?.text ?? "").replace(/[^0-9.]/g, '')
                    return parseFloat(y.toString()) - parseFloat(x.toString())
                });
            }

            // sort by date
        } else if (sortIndicator.sortDataType === "Date") {
            const defaultDateString = '01/01/0001';
            if (sortIndicator.sortDirection == 'up') {
                sortedRecords = records.sort((a, b) => {
                    const x = a[sortIndicator.sortColumn]?.text ?? defaultDateString
                    const y = b[sortIndicator.sortColumn]?.text ?? defaultDateString
                    return new Date((x || defaultDateString).toString()).getTime() -
                        new Date((y || defaultDateString).toString()).getTime()
            });
            } else {
                sortedRecords = records.sort((a, b) => {
                    const x = a[sortIndicator.sortColumn]?.text ?? defaultDateString
                    const y = b[sortIndicator.sortColumn]?.text ?? defaultDateString
                    return new Date((y || defaultDateString).toString()).getTime() -
                        new Date((x || defaultDateString).toString()).getTime()
                });
            }
        }

        return sortedRecords;
    }

    render() {
        const { maxHeight, columns, title, subTitle, tooltip, records, onClick, backgroundColor } = this.props
        const listWidth = this.props.width
        const domID = (this.props.domID ?? "") + Date.now() + "" + Math.random() + "" + _.uniqueId()
        const sortableColumns = columns.filter((c) => { return c.sortable })
        let globalOnClick;
        let cursor = "pointer";
        if (onClick) {
            globalOnClick = onClick;
        } else {
            globalOnClick = () => { };
            cursor = "default";
        }
        let styleArry: string[] = ["",""];
        if (!this.state.styleArry.length && columns.length) {
            columns.map((column, index) => {
                styleArry = [...styleArry, this.getColumnStyleFromProps(index, columns)];
                if (column.rightAlignText) {
                    styleArry = [...styleArry, this.getRightAlignTextStyleFromProps({ nthElement: (index + 1) })];
                };
            });
            this.setState({ styleArry });
        }

        const reconstructedListWidth: number | undefined = columns.length ? columns.reduce((result, value) => {
            if (value.width && (result !== undefined)) {
                return result + value.width;
            } else {
                return undefined;
            }
        }, 0) : undefined;

        const componentContent = (
            <ListContainerDiv key={ domID +"-ListContainerDiv"} styleArry={this.state.styleArry} cursor={cursor} onClick={globalOnClick}>
                {title && <TableListHeaderDiv>{title}</TableListHeaderDiv>}
                {typeof subTitle === "string" && <SubTableListHeaderDiv>{subTitle}</SubTableListHeaderDiv>}
                <div key={domID + "-ARMGridWrapperDiv"} style={{
                    padding: backgroundColor ? "10px" : 0,
                    backgroundColor,
                    overflow: "hidden",
                    width: listWidth ? listWidth+"" : reconstructedListWidth+"",
                }}>
                <ARMGrid
                        columns={new Set(columns)}
                        isFixedHeader
                        maxHeight={maxHeight ? maxHeight : "100%"}
                        domID={domID + "-ARMGrid"}
                        key={domID + "-ARMGrid"}
                        selectionKey={sortableColumns.length ? sortableColumns[0].dataName : ""}
                        initialSortingKey={this.state.sortOrder}
                        rowComponent={this.getCustomRow()}
                        records={records}
                        onSortGridColumn={(e: React.MouseEvent<HTMLTableHeaderCellElement>, sortData: { sortingKey: string /*todo there are other props we may need*/ }) => {
                            this.setState({ sortOrder: sortData.sortingKey });
                        }}
                        sortingComparator={sortableColumns.length ? this.sortingComparator : () => records}
                    />
                    </div>
            </ListContainerDiv>
        )

        if (tooltip) {
            return (
                <Tip key={domID + "-ARMTooltipDiv"} title={tooltip}>{componentContent}</Tip>
            )
        } else {
            return componentContent
        }
        
    }
}