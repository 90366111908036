import { Reducer, ActionCreatorsMapObject } from 'redux';
import { mapKeys, hasIn } from 'lodash';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { IFormUIState, defaultFormState, ICanHazErrors } from "@store/ui/BaseCrudUI";
import { createDataAction, createApiBodyAction, ActionTypes, IApiAction, ValidationCallback } from "../../scripts/util/ActionHelpers";
import { URLs } from '@commonDevResources/constants';

// Properties of action creators specific to the PhysicionMnLetter view
export interface IPhysicianMnLetterData {
    contactName: string;
    contactNum: string;
    contactFax: string;
    subject: string;
    comments: string;
}

// shape of the errors (needed for FormUIState generic optional errors prop)
export interface IPhysicianMnLetterErrors {
    incorrectCommentLength?: string;
    pdfWriteError?: string;
}

const physicianMnLetterError = {
    incorrectCommentLength: 'Please enter a shorter comment as it is limited to 2048 characters.',
}

// UI representation of CRUD data. 
export interface IPhysicianMnLetterUIState extends IPhysicianMnLetterData, IFormUIState, ICanHazErrors<IPhysicianMnLetterErrors> {
    isBusy: boolean;
    pdfPath: string;
};

// merge our custom action data interface with CRUD boilerplate
export interface IModifyPhysicianMnLetter extends ICrudActionData<MCPhysicianMnLetter, string> {
};

export interface IResetCrudFlag extends ICrudActionData<MCPhysicianMnLetter, boolean> {
};

export interface IInitializePhysicianMnLetter extends ICrudActionData<MCPhysicianMnLetter, MCPhysMnLetter> {
};

export const actionCreators = {
    initalizeOptions: (rawApiReturn: IInitializePhysicianMnLetter) => createDataAction('INIT_PHYSICIAN_MN_LETTER', rawApiReturn),
    editContactName: (physicianMnLetterInfo: IModifyPhysicianMnLetter) => createDataAction('EDIT_PHYSICIAN_MN_LETTER_CONTACT_NAME', physicianMnLetterInfo),
    editContactNum: (physicianMnLetterInfo: IModifyPhysicianMnLetter) => createDataAction('EDIT_PHYSICIAN_MN_LETTER_CONTACT_NUM', physicianMnLetterInfo),
    editContactFax: (physicianMnLetterInfo: IModifyPhysicianMnLetter) => createDataAction('EDIT_PHYSICIAN_MN_LETTER_CONTACT_FAX', physicianMnLetterInfo),
    editSubject: (physicianMnLetterInfo: IModifyPhysicianMnLetter) => createDataAction('EDIT_PHYSICIAN_MN_LETTER_SUBJECT', physicianMnLetterInfo),
    editComments: (physicianMnLetterInfo: IModifyPhysicianMnLetter) => createDataAction('EDIT_PHYSICIAN_MN_LETTER_COMMENTS', physicianMnLetterInfo),
    previewPdf: (pdfData: MCPhysMnLetter, callback:ValidationCallback<IPreviewReportResponse>) => createApiBodyAction('GENERATE_PHYSICIAN_MN_PREVIEW',
        `${URLs.api}/api/data/letter/PhysicianMN`,
        pdfData,
        'POST',
        JSON.stringify(mapKeys(pdfData, (value, key) => key.replace('@', ''))),
        callback
    ),
    resetDirtyFlag: (physicianMnLetterInfo: IResetCrudFlag) => createDataAction('RESET_PHYSICIAN_MN_LETTER_DIRTY', physicianMnLetterInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultPhysicianMnLetterState: IPhysicianMnLetterUIState = {
    ...defaultFormState,
    contactName: '',
    contactNum: '',
    contactFax: '',
    subject: '',
    comments: '',
    isBusy: true,
    pdfPath: '',
}

export function mapUiStateToCrud<T extends IPhysicianMnLetterData>(state: T): MCPhysMnLetter {
    const physMnLetter: MCPhysMnLetter = {
        '@ContactName': state.contactName,
        '@ContactNum': state.contactNum,
        '@ContactFax': state.contactFax,
        '@Subject': state.subject,
        '@Comments': state.comments,
    };
    return physMnLetter;
};

export function mapCrudToUiState(crud: MCPhysMnLetter): IPhysicianMnLetterUIState {
    return {
        isDirty: false,
        contactName: crud['@ContactName'],
        contactNum: crud['@ContactNum'],
        contactFax: crud['@ContactFax'],
        subject: crud['@Subject'],
        comments: crud['@Comments'],
        isBusy: false,
        pdfPath: '',
    }
}

export const physicianMnLetterUIReducer: Reducer<IPhysicianMnLetterUIState, KnownActions> = (state: IPhysicianMnLetterUIState | undefined, action: KnownActions) => {
    /*  Repetition of the code to set this is necessary bc one action 
        in this reducer is not an ICrudDataAction. So we can only access 
        its properties in case statements that refer to actions of that type. 
        Yay typescript.
    */
    let crudData: PhysMnLetterMaintenanceInfo | undefined;
    const isDirty = true;

    let newState = state && { ...state };
    let errors: IPhysicianMnLetterErrors = {};

    if (state) {
        switch (action.type) {
            case 'INIT_PHYSICIAN_MN_LETTER':
            {
                const crud = action.data.uiData;
                if (crud) {
                    return mapCrudToUiState(crud);
                } else {
                   // need to repair masterCrud or else our edits to PhysMNLetter in mapUIStateToCrud have nowhere to go
                   if (hasIn(action.data, 'masterCrud')) {
                       action.data.masterCrud!.PhysMNLetterMaintenanceInfo = {
                           PhysMNLetter: mapUiStateToCrud(defaultPhysicianMnLetterState)
                       }
                   } 
                   return {...state, isBusy: false} 
                }
                break;
            }
            case 'EDIT_PHYSICIAN_MN_LETTER_CONTACT_NAME':
            {
                crudData = action.data?.masterCrud?.PhysMNLetterMaintenanceInfo;
                newState = { ...state, contactName: action.data.uiData, isDirty, errors };
                if (crudData) crudData.PhysMNLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_PHYSICIAN_MN_LETTER_CONTACT_NUM':
            {
                crudData = action.data?.masterCrud?.PhysMNLetterMaintenanceInfo;
                newState = { ...state, contactNum: action.data.uiData, isDirty, errors };
                if (crudData) crudData.PhysMNLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_PHYSICIAN_MN_LETTER_CONTACT_FAX':
            {
                crudData = action.data?.masterCrud?.PhysMNLetterMaintenanceInfo;
                newState = { ...state, contactFax: action.data.uiData, isDirty, errors };
                if (crudData) crudData.PhysMNLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_PHYSICIAN_MN_LETTER_SUBJECT':
            {
                crudData = action.data?.masterCrud?.PhysMNLetterMaintenanceInfo;
                newState = { ...state, subject: action.data.uiData, isDirty, errors };
                if (crudData) crudData.PhysMNLetter = mapUiStateToCrud(newState);
                break;
            }
            case 'EDIT_PHYSICIAN_MN_LETTER_COMMENTS':
            {
                newState = { ...state, comments: action.data.uiData, isDirty, errors };
                if (action.data.uiData?.length > 2048) {
                    newState = {...newState, errors: physicianMnLetterError };
                } else {
                    crudData = action.data?.masterCrud?.PhysMNLetterMaintenanceInfo;
                    if (crudData) crudData.PhysMNLetter = mapUiStateToCrud(newState);
                }
                break;
            }
            case 'GENERATE_PHYSICIAN_MN_PREVIEW':
            {
                switch (action.status.status) {
                    case 'REQUEST':
                        newState = { ...state, isBusy: true }
                        break;
                    case 'SUCCESS':
                        const pdfPath = action.responseData.path;
                        /* This error gets propagated to the newly opened window
                        const error = action.responseData.error; */
                        newState = { ...state, isBusy: false, pdfPath}
                        break;
                    case 'FAIL':
                        newState = { ...state, isBusy: false, errors: {pdfWriteError: 'PDF preview could not be created due to server error.'} }
                        break;
                }
                break;
            }
            case 'RESET_PHYSICIAN_MN_LETTER_DIRTY':
                newState = { ...state, isDirty: false, errors };
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
            }
    }
    return newState || defaultPhysicianMnLetterState;
}