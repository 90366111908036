import { combineReducers, Reducer } from 'redux';
import * as SecurityAccountPolicyUI from '@store/ui/SecurityAccountPolicyUI';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from
    '@scripts/util/CrudComponentHelpers';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type ISecurityAccountPolicyUIState =
    ICrudComponentState<MCSecurityAccountPolicy, SecurityAccountPolicyUI.ISecurityAccountPolicyUIState>;
export const actionCreators =
    createCrudComponentActions<SecurityAccountPolicyUI.ActionCreators>(SecurityAccountPolicyUI.actionCreators);
export type ISecurityAccountPolicyActions = typeof actionCreators;
export type ISecurityAccountPolicyActionProps = ICrudComponentActionProps<ISecurityAccountPolicyActions>;

const reducers = createCrudComponentReducers(CrudTypes.mctiUserAccountPolicy, SecurityAccountPolicyUI.securityAccountPolicyUIReducer);
export const reducer = combineReducers<ISecurityAccountPolicyUIState>(reducers);

export const validationCallback: ValidationCallback<MCSecurityAccountPolicy> =
    (crud: any): crud is MCSecurityAccountPolicy => {
        let testCrud: MCSecurityAccountPolicy = crud as MCSecurityAccountPolicy;
        let validShape = ValidateJSONSchema(testCrud, "MCSecurityAccountPolicy");
        return validShape;
    };