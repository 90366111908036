import * as React from 'react';
import { Grid } from 'ui-core';
import { ModalComponentWrapper } from '@common/ModalComponentWrapper';
import { PmDropdownOption } from '../../../store/ui/PhysicianMaintenanceUI';
import {
    GridWrapper
} from './PhysicianStyles'

export interface AddFacilityModalProps {
    facilitiesList: PmDropdownOption[];
    onModalToggle: React.MouseEventHandler<HTMLElement>;
    onConfirm(facilitiesToAdd: string[]): void;
    isOpen: boolean;
    onDeny(): void;
};

interface IComponentState {
    visible: boolean;
}

export class AddFacilityModal extends React.Component<AddFacilityModalProps, IComponentState> {

    private facilitiesToAdd: string[];

    static defaultProps: AddFacilityModalProps = {
        onModalToggle: (e: React.MouseEvent<HTMLElement>) => false,
        onConfirm: (facilitiesToAdd: string[]) => false,
        onDeny: () => { },
        facilitiesList: [],
        isOpen: true,
    };

    constructor(props: AddFacilityModalProps) {
        super(props);

        this.state = {
            visible: true,
        };
        this.facilitiesToAdd = [];
        this.getRecords = this.getRecords.bind(this);
    }

    closeModal() {
        this.setState({ visible: false });
    }

    getColumns() {
        return new Set([
            { dataName: 'label', text: 'Facility Name', sortable: false, isSorted: 0 }            
        ]);
    }

    /** Parse out the facilities in the propos list, remove the -ADD NEW- default option, and return.
     * 
     */
    getRecords(): PmDropdownOption[] {
        if (!!this.props.facilitiesList) {
            if (this.props.facilitiesList.length > 0)
                return this.props.facilitiesList.slice(1, this.props.facilitiesList.length);
        }

        return [];
    }

    toggleAllFacilities(addAllFlag: boolean): void {
        if (addAllFlag)
            this.facilitiesToAdd = this.getRecords().map(({ value }) => value);
        else
            this.facilitiesToAdd = [];

        console.log(this.facilitiesToAdd);
    }

    toggleFacilityInList(facilityId: string): void {
        const index = this.facilitiesToAdd.indexOf(facilityId);

        // If we found the facility, remove it.
        if (index > -1) {
            this.facilitiesToAdd.splice(index, 1);
        }

        // If we did not find the facility, add it.
        else {
            this.facilitiesToAdd.push(facilityId);
        }
    }

    getFacilitiesGrid(): React.ReactNode {
        return (
            <GridWrapper style={{ color: "blue" }}>
                <Grid
                    columns={this.getColumns()}
                    domID="add-facility-multiselect-grd"
                    initialSelectedItemIds={null}
                    onRowSelect={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        this.toggleFacilityInList(
                            e.target.name.replace("-row-select-checkbox", "")
                        );
                    }}
                    onSelectAll={(e: React.ChangeEvent<HTMLInputElement>) => {
                        this.toggleAllFacilities(e.target.checked);
                    }}
                    records={this.getRecords()}
                    selectAllCheckboxDomID="select-all-facilities"
                    selectionKey="value"
                    maxHeight="33em"
                    supportSelection
                    isFixedHeader
                />
            </GridWrapper>
        );
    }

    //public onClickX(e: React.ChangeEvent<HTMLButtonElement>) {
    //    this.props.isOpen = false;
    //}

    // debuke says: button: onClick: { (e: React.ChangeEvent<HTMLButtonElement>) => this.onClickX(e) }
    render() {
        return (

            <ModalComponentWrapper
                isOpen={this.props.isOpen}
                showDefaultClose={true}
                component={this.getFacilitiesGrid()}
                onModalToggle={this.props.onModalToggle}
                onConfirm={() => {
                    this.props.onConfirm(this.facilitiesToAdd);
                }}
                onDeny={() => {
                    this.facilitiesToAdd = [];
                    this.props.onDeny();
                }}
            />
        );
    }
}
