import * as React from 'react';
import { LoadingIndicator } from 'ui-core';
import styled, { css } from 'styled-components';
import { ARMComponentItemContainer, ARMComponentItemTop, ARMComponentItemContent, ARMComponentItemBottom } from './styles/LandingPageStyles';
import messageIcon from '../../content/images/messageIcon.png';

export const ARMComponentMessageText = styled.div.attrs(() => {
})`
    font-size: 13px;
`;

interface IARMComponentMessageProps {
    message: string;
};

export class ARMComponentMessage extends React.PureComponent<IARMComponentMessageProps>
{
    render() {
        return (
            <ARMComponentItemContainer>
                <ARMComponentItemTop />
                <ARMComponentItemContent disabled>
                    <img src={messageIcon} />
                </ARMComponentItemContent>
                <ARMComponentMessageText>{this.props.message}</ARMComponentMessageText>
                <ARMComponentItemBottom />
            </ARMComponentItemContainer>
        );
    }
}
