import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../store';
import * as AuthStore from '../../../store/Authentication';
import { URLs } from '../../../commonResources/constants';
import styled from 'styled-components';
import { Button, LoadingButton, Input, Typography, Mail, Warning, CircleCheck, CircleX, Circle} from 'ui-core';
import { Colors } from '../../../commonResources/colorVariables';
import { ARMLoginMessages } from '../Logon/ARMLoginMessages';
import { ARMMessage, IARMMessageProps } from '../Logon/ARMMessage';
import { ARMLoginWrapper, ARMLoginBody, ARMLoginForm, FormRow } from '../Logon/styles/ARMLoginForm';
import { ARMLogoHeader } from '../Logon/ARMLogoHeader';
import { ARMCopyright } from '../Logon/ARMCopyright';
import CiamPortal from '../Ciam/CiamPortal';
import { LocationDescriptorObject } from 'history';
import { submitLogin, ILoginModel } from '@scripts/session/Security';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IsPasswordValid } from '../Utilty/Validation';
import { ClearSecuritySession } from '../../../commonResources/window';
import { getRawToken } from '@scripts/session/SecurityToken';


const failedResetAttemptMsg = <>{"Something went wrong."}<br />{"Please try again."}</>


const TitleWrapperDiv = styled.div`
    color: ${Colors.chBlue100};
    ${Typography.headingSmall};
    ${Typography.largeLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
    ${Typography.fontFamily};
`;

const MailAnimDiv = styled.div`
    @keyframes slide {
        0% {
            opacity: 0;
            transform: skewX(20deg) translateX(-40px);
        }

        80% {
           opacity: 1;
           transform: skewX(-10deg) translateX(5px);
        }

        100% {
            opacity: 1;
            transform: skewX(0) translateX(0);
        }
    }

    animation-name: slide;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-iteration-count: 1;
`;

const WarningAnimDiv = styled.div`
    @keyframes jitter {
        0% {
            transform: translateX(0);
         }
        25% {
            transform: translateX(5px);
        }
        50% {
            transform: translateX(0);
        }
        75% {
            transform: translateX(-5px);
        }
        100% {
            transform: translateX(0);
        }
    }

    animation-name: jitter;
    animation-duration: .3s;
    animation-timing-function: linear;
    animation-iteration-count: 2;

`;

const SecQuestWrapperDiv = styled.div`
    color: ${Colors.chBlue100};
    ${Typography.extraLarge};
    ${Typography.largeLineHeight};
    ${Typography.bold};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
`;

const LableWrapperDiv = styled.div`
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
    ${Typography.fontFamily};
`;

const ARMAuthWrapperDiv = styled.div`
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.fontFamily};
`;

const ValidationMsgDiv = styled.div<ValidationProps>`
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
    ${Typography.fontFamily};

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    color: ${p => p.color};
    margin-bottom: 10px;    
`;


interface ValidationProps {
    color: string;
}

interface FormData {
    [key: string]: string | undefined;
}

interface ValidationCriteria {
    minLength: number;
    alphaNumeric: number;
    upperLowerCase: number;
    specialChar: number;
}

type AuthProps =
    IComponentProps
    & AuthStore.AuthenticationState
    & AuthStore.ActionCreators
    & RouteComponentProps<{}>;

type AuthState = {
    message: IARMMessageProps;
    formData: FormData;
    validationCriteria: ValidationCriteria;
    verificationResponseMessage: string | JSX.Element;
    verificationSuccess: boolean;
    isDisabled: boolean;
    privacyUrl: string;
    resetID: string;
    secQuestion: string;
    questionID: string;
    isValidationModalOpen: boolean;
    validationModalMessage: any;
}

interface IComponentProps {
    forgotPasswordUrl: string;
    privacyPolicyUrl: string;
    termsAndCondsUrl: string;
}

const ARMAUTH_DEFAULT_STATE = {
    message: ARMLoginMessages.instructions,
    formData: {
        username: "",
        password: "",
        client: "",
        secQuestAns: "",
        newPassword: "",
        newPasswordConfirmation: "",
    },
    validationCriteria: {
        minLength: 64,
        alphaNumeric: 1,
        upperLowerCase: 1,
        specialChar: 1,
    },
    verificationResponseMessage: "",
    verificationSuccess: false,
    isDisabled: false,
    privacyUrl: "",
    resetID: "",
    secQuestion: "",
    questionID: "",
    isValidationModalOpen: false,
    validationModalMessage: "",
}

export const StringWithNewLine = (props:any) => {
    return <>{props.string}<br /></>
}

export class ARMAuthentication extends React.Component<AuthProps, AuthState> {

    static defaultProps: IComponentProps = {
        forgotPasswordUrl: '/ForgotPassword',
        privacyPolicyUrl: "/Content/PrivacyNotice.pdf",
        termsAndCondsUrl: "/Content/TermsAndConditionsOfUse.pdf"
    }

    // UI-CL elements don't have a ref prop so we use selectors to obtain a reference
    protected usernameInput: HTMLTextAreaElement | null = null;
    protected forgotPasswordButton: HTMLButtonElement | null = null;
    protected viewPrivacyPolicyButton: HTMLButtonElement | null = null;
    protected viewTermsAndConditionsButton: HTMLButtonElement | null = null;
    protected errorMessage: IARMMessageProps = ARMLoginMessages.instructions;

    constructor(props:AuthProps) {
        super(props);
        this.state = ARMAUTH_DEFAULT_STATE;
    }

    public componentDidMount() {
        const ciamPrompt: any = sessionStorage.getItem("ciamPrompt");
        let message : IARMMessageProps = ARMLoginMessages.instructions;
        if(ciamPrompt === '1')
            message = ARMLoginMessages.ciamInfo;
        if(ciamPrompt === '2')
            message = ARMLoginMessages.ciamInfoLogon;
        
        this.setState({isDisabled: false, message: message}, () => this.props.transition(AuthStore.AuthType.LOGON_READY))
        this.getResetId();
        sessionStorage.setItem('ciamPrompt', '');
        if (this.props.current === AuthStore.AuthType.LOGON_READY) {
            this.applyDOMChanges(true);
        }
        ClearSecuritySession();

    }

    public componentDidUpdate = (prevProps: any) => {
        if (this.props.current === AuthStore.AuthType.LOGON_READY) {
            this.applyDOMChanges(false);
        }
    }

    applyDOMChanges(init: boolean) {
        // This is to update the tab order because the UICL components do not pass on extra props or the tabIndex prop.
        // Direct DOM manipulation is used here for Input components because the underlying Input element has a Wrapper component which is a
        // styled.div, so any ref would reference that div, not the input element. To assume the structure of the underlying Input component is weak.
        // The buttons should work as long as it is not a dropdown button (which uses a wrapper), but there is also an issue with stateless styled-components throwing a warning.
        // Also, the login button needs to have type='submit', which is not supported in UICL as of v4.0.3.
        this.usernameInput = document.getElementById('username-input') as HTMLTextAreaElement;
        this.forgotPasswordButton = document.getElementById('forgot-password-button') as HTMLButtonElement;
        this.viewPrivacyPolicyButton = document.getElementById('privacy-policy-button') as HTMLButtonElement;
        this.viewTermsAndConditionsButton = document.getElementById('terms-conditions-button') as HTMLButtonElement;

        if (this.usernameInput) {
            if (init) this.usernameInput.focus();
        }
        if (this.forgotPasswordButton) {
            this.forgotPasswordButton.tabIndex = -1;
        }
        if (this.viewPrivacyPolicyButton) {
            this.viewPrivacyPolicyButton.tabIndex = 6;
        }
        if (this.viewTermsAndConditionsButton) {
            this.viewTermsAndConditionsButton.tabIndex = 7;
        }
    }

    public getResetId = () => {
        const setTransition = () => {
            this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, }, () => {
                this.props.transition(AuthStore.AuthType.REVERIFY)
            })
        }
        const searchParams = new URLSearchParams(this.props.location.search);
        const resetID = searchParams.toString().replace(/\=$/, '')

        this.setState({
            resetID: resetID ?? ""
        }, () => { this.state.resetID && setTransition() })
    }

    // Get Path for redirect on successful logon
    public getLoginSuccessDestination(): LocationDescriptorObject {
        const result: LocationDescriptorObject = {
            pathname: '/LandingPage'
        }

        const searchParams = new URLSearchParams(this.props.location.search);
        if (searchParams.has('URL')) {
            result.pathname = searchParams.get('URL') ?? ''
            result.pathname = result.pathname.replace(/\.asp$/, '');
        }
        if (searchParams.has('Process')) {
            result.state = searchParams.get('Process') ?? '';
        }
        return result;
    }
  

    public handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        let formData = { ...this.state.formData };
        formData[e.target.name] = e.target.value;
        if (e.target.name === 'username') {
            formData['username'] = e.target.value.replace(/[^\w '\.\-\\?']/g, '');
        }
        if (e.target.name === 'client'){
            formData['client'] = e.target.value.toUpperCase();
        }
        this.setState({ formData });
    }

    validate() {
        const { username = "", password = "", client = "" } = this.state.formData

        const isValid = this.validateValue(username, ARMLoginMessages.enterUserName) &&
            this.validateValue(password, ARMLoginMessages.enterPassword) &&
            this.validateValue(client, ARMLoginMessages.enterClient)

        this.setState({ isDisabled: true, message: this.errorMessage });

        if (!isValid) {
            if (this.usernameInput) this.usernameInput.focus();
        }

        return isValid;
    }

    validateValue(value: string, error: IARMMessageProps) {
        if (!value) {
            this.setMessage(error);
            return false;
        }
        return true;
    }

    setMessage(error: IARMMessageProps) {
        this.errorMessage = error;
    }

    public verifyUser = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const { AuthType } = AuthStore;
        const { username, client } = this.state.formData;

        var url = URLs.api + "/api/data/email/sendForgotPassword";
        let data = {
            "UserName": `${username}`,
            "ClientAlias": `${client}`,
            "NativeMode": true
        };

        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(data)
        })
        .then(async (response) => {
                let msg = "";

                const setTransition = async () => {
                    await this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, }, async () => {
                        await this.props.transition(AuthType.EMAIL_REQUEST_RESPONSE)
                    })
                }

                if (response.status === 306) {
                    msg = "An e-mail has been sent to you, please check your e-mail and click the link.";
                    await this.setState({ verificationResponseMessage: msg, verificationSuccess: true }, setTransition);
                   
                } else {
                    switch (response.status) {
                        case 316:
                            msg = "Password hints have not been set up or your email needs to be verified.";
                            break;

                        case 500:
                            msg = "An error occurred while processing your request.";
                            break;

                        default:
                            msg = "Unexpected error.";
                    }
                    await this.setState({ verificationResponseMessage: msg, verificationSuccess: false }, setTransition);
                }
                
            })
           
    }


    public getSecQuestion = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const { resetID } = this.state;
        const { username, client } = this.state.formData;

        const payload = { resetId: resetID, userName: username, clientAlias: client };

        const url = URLs.api + "/api/data/securityInfo/question";
        
        await fetch( url, {
            method: 'POST',
             headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(payload)   
        })
        .then((response)=>{
            return response.json();
        }).then(async (jsonData) => {
            if (jsonData.questionText) {
                await this.setState({
                    secQuestion: jsonData.questionText,
                    questionID: jsonData.questionId,
                    validationCriteria: {
                        minLength: jsonData.minLength,
                        alphaNumeric: jsonData.alphaNumeric,
                        upperLowerCase: jsonData.upperLowerCase,
                        specialChar: jsonData.specialChar,
                    }
                },
                    async () => {
                        await this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, }, async () => {
                            await this.props.transition(AuthStore.AuthType.SECURITY_QUESTION)
                        })
                    }
                )
            } else {
                await this.setState({
                    verificationResponseMessage: failedResetAttemptMsg,
                    verificationSuccess: false
                }, async () => {
                    await this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, }, async () => {
                        this.props.history.push("/Transactions/Logon");
                        await this.props.transition(AuthStore.AuthType.EMAIL_REQUEST_RESPONSE);
                    });
                });
            }
        })

    }

    public getSecQuestionAnsResp = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const { username, client, secQuestAns } = this.state.formData;
        const { resetID, questionID } = this.state

        const payload = {
            resetId: resetID,
            userName: username,
            clientAlias: client,
            questionId: questionID,
            answer: secQuestAns,
        };

        const url = URLs.api + "/api/data/securityInfo/answer";

        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(payload)
        })
            .then((response) => {
                if (response.status !== 306) {
                    return response.json()
                } 
            }).then(async (response) => {
                if (!response) {
                    await this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, }, async () => {
                        await this.props.transition(AuthStore.AuthType.PASSWORD_RESET);
                    })
                } else {
                    await this.setState({
                        verificationResponseMessage: response.message,
                        verificationSuccess: false,
                        isDisabled: false,
                        message: ARMLoginMessages.instructions
                    }, async () => {
                            this.props.history.push("/Transactions/Logon")
                            await this.props.transition(AuthStore.AuthType.EMAIL_REQUEST_RESPONSE)
                    });
                }
            })

    }

    public resetPassword = async () => {
        const { username, client, newPassword="" } = this.state.formData;
        const { resetID } = this.state

        const payload = {
            resetId: resetID,
            userName: username,
            clientAlias: client,
            password: newPassword,
        };

        const url = URLs.api + "/api/data/securityInfo/reset";

        await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(payload)
        })
            .then(async (response) => {
                switch (response.status) {
                    case 200:
                        await this.setState({
                            isDisabled: false,
                            message: ARMLoginMessages.instructions,
                            formData: { ...this.state.formData, password: newPassword }
                        },
                            async () => {
                                    await this.props.transition(AuthStore.AuthType.LOGON_READY)
                                    await this.logonToApplication()
                            }
                        )
                        break;
                    case 316:
                        return response.json()
                    case 326:
                        return response.json()
                    default:
                        await this.setState({
                            verificationResponseMessage: failedResetAttemptMsg,
                            isDisabled: false,
                            message: ARMLoginMessages.instructions,
                            verificationSuccess: false
                        }, async () => {
                                this.props.history.push("/Transactions/Logon")
                                await this.props.transition(AuthStore.AuthType.EMAIL_REQUEST_RESPONSE)
                        });
                }
            }).then(async (response) => {
                if (response) {
                    if ((response === "Invalid sequence of events.") ||
                        (response === "The new password reset was not done in a timely fashion.") || 
                        (response === "Your account is currently locked out. Contact your local product site administrator.") ||
                        (response === "Your account is currently disabled. Contact your local product site administrator.")) {
                        await this.setState({
                            verificationResponseMessage: response,
                            isDisabled: false,
                            message: ARMLoginMessages.instructions,
                            verificationSuccess: false
                        }, async () => {
                            this.props.history.push("/Transactions/Logon")
                            await this.props.transition(AuthStore.AuthType.EMAIL_REQUEST_RESPONSE)
                        });
                    } else {
                        await this.setState({
                            validationModalMessage: response,
                            isValidationModalOpen: true,
                            isDisabled: false,
                        });
                    }
                }
            })
    }

    public validateNewPassword = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const { newPassword = "", newPasswordConfirmation } = this.state.formData;
        const confirmationMessage = newPassword === newPasswordConfirmation ? "" : "Make sure your new password and new password confirmation match."

        const { minLength, alphaNumeric, upperLowerCase, specialChar } = this.state.validationCriteria
        let validationErrorMsgs: string[] = []
        if (confirmationMessage) {
            validationErrorMsgs = [confirmationMessage]
        } else {
            validationErrorMsgs = IsPasswordValid(newPassword, minLength, alphaNumeric, upperLowerCase, specialChar)
        }

        const validationErrorMsgsJSX = <ul> {validationErrorMsgs.map((msg) => (<li> {msg} </li>))} </ul>

        if (validationErrorMsgs.length) {
            await this.setState({
                validationModalMessage: validationErrorMsgsJSX,
                isValidationModalOpen: true,
                isDisabled: false,
            });
        } else {
            await this.resetPassword()
        }
    }

    loginModel = {
        Username: '',
        Password: '',
        ClientAlias: '',
        LoginType: '',
        CiamEnabled: false
    }

    public logonToApplication = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        const { username, password, client } = this.state.formData;
        
        this.setMessage(ARMLoginMessages.verifyingCredentials);

        if (this.validate()) {
            this.loginModel.Username = username || '';
            this.loginModel.Password = password || '';
            this.loginModel.ClientAlias = client || '';
            this.loginModel.LoginType = "Regular";
            this.loginModel.CiamEnabled = true;
            // Call login API Method
            await this.login();
        } else {
            await this.setState({ isDisabled: false });
        }
    }

    public async login() {
        let loginResult;
        try {
            loginResult = await submitLogin(this.loginModel);
        }
        catch (expection) {
            loginResult = { status: -1 };
        }
        switch (loginResult.status) {
            case 200:
                // redirect to /LandingPage
                this.props.history.push(this.getLoginSuccessDestination());
                break;

            case 306:
                // Password expired or reset
                this.props.history.push({
                    pathname: "/Home/ChangePassword",
                    state: { warningMessage: loginResult.body }
                });
                break;

            case 316:
                // Password questions needed
                this.props.history.push("/Transactions/UserPasswordHints?" + loginResult.body);
                break;

            case 317:
                // Email verification needed
                this.props.history.push('/UserEmailConfirm');
                break;


            default:
                const responseMessage: IARMMessageProps = {
                    message: !loginResult.body
                        ? "Your credentials could not be verified."
                        : loginResult.body,
                    isBold: true,
                    isError: true,
                };
                const formData = { username: '', password: '', client: '' };
                this.loginModel.Username = "";
                this.loginModel.Password = "";
                this.loginModel.LoginType = "";
                this.loginModel.ClientAlias = "";
                await this.setState({ isDisabled: false, message: responseMessage, formData });

        }
    }


    public viewPrivacyPolicy(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        this.getEndPointURL()
            .then(() => {
                let siteUrl = this.state.privacyUrl + ARMAuthentication.defaultProps.privacyPolicyUrl;
                let newTab = window.open();
                newTab && (newTab.location.href = siteUrl);
            });
    }

    public viewTermsAndConditions(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        this.getEndPointURL()
            .then(() => {
                let siteUrl = this.state.privacyUrl + ARMAuthentication.defaultProps.termsAndCondsUrl;
                let newTab = window.open();
                newTab && (newTab.location.href = siteUrl);
            });
    }

    public getEndPointURL = async () => {
        const url = URLs.api + '/api/data/securityInfo/getPortalEndpointURL';
        await fetch(url)
            .then((response) => response.json())
            .then((jsonData) => {
                this.setState({ privacyUrl: jsonData });
            })
    }

    public forceSingleClick = async (e: React.MouseEvent<HTMLButtonElement>, funct: (e: React.MouseEvent<HTMLButtonElement>) => any) => {
        e.preventDefault();
        if (!(e.detail > 1) && !this.state.isDisabled) { // makes sure this only fires on the first mouse click
            await this.setState({ isDisabled: true },
                async () => await funct(e)
            );
        }
    }
   

    /**
     * COMPONENT UIS - We define each UI that corresponds to the possible AuthTypes
     * */

    public getDefaultLoginUI = () => {
        const { username, client, password } = this.state.formData;
        const { AuthType } = AuthStore;

        return (
            <>
                <CiamPortal />
                <ARMMessage {...this.state.message} />
                <Input
                    domID="username-input"
                    className="text-input"
                    label="User Name"
                    name="username"
                    initialValue={username}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <FormRow>
                    <div>
                        <label
                            id="password-input-label"
                            htmlFor="password-input"
                        >
                            <span className="text-input">Password</span>
                        </label>
                    </div>
                    <div>
                        <Button
                            domID="forgot-password-button"
                            type="button"
                            size="medium"
                            buttonType="link"
                            className="link-button"
                            tabIndex={-1}
                            onClick={() => { this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, },()=>this.props.transition(AuthType.FORGOT_PASSWORD)) }}
                        >
                            <span>Forgot your password?</span>
                        </Button>
                    </div>
                </FormRow>
                <Input
                    domID="password-input"
                    name="password"
                    type="password"
                    className="text-input"
                    initialValue={password}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <Input
                    domID="cid-input"
                    name="client"
                    className="text-input"
                    label="Client"
                    initialValue={client}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <FormRow>
                    <div>
                        <Button
                            domID="privacy-policy-button"
                            type="button"
                            size="medium"
                            buttonType="link"
                            className="link-button align-top"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.viewPrivacyPolicy(e)}
                        >
                            <span>View Privacy Policy</span>
                        </Button>
                    </div>
                </FormRow>
                <FormRow>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Button
                            domID="terms-conditions-button"
                            type="button"
                            size="medium"
                            buttonType="link"
                            className="link-button align-top"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.viewTermsAndConditions(e)}
                        >
                            <span>View Terms and Conditions</span>
                        </Button>
                    </div>
                    <div>
                        <Button
                            domID="login-button"
                            type="submit"
                            name="login"
                            size="large"
                            buttonType="emphasized"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.forceSingleClick(e, this.logonToApplication)}
                            disable={this.state.isDisabled}
                        />
                    </div>
                </FormRow>

            </>
        )
    }

    public getEmailRequestResponseUI = () => {
        const { AuthType } = AuthStore;
        const { verificationResponseMessage, verificationSuccess } = this.state;
        return (
            <>
                <FormRow>
                    <div style={{
                        display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '12px'
                    }} >
                        {verificationSuccess ?
                            (<MailAnimDiv style={{ marginLeft: '-10px' }}><Mail fillColor={Colors.chBlue100} width={'120'} /></MailAnimDiv>) :
                            (<WarningAnimDiv style={{ marginLeft: '-10px' }}><Warning fillColor={Colors.chBlue100} width={'120'} /></WarningAnimDiv>)
                            }
                        <div >{verificationResponseMessage}</div>
                    </div>
                </FormRow>
                <FormRow>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <Button
                            domID="privacy-policy-button"
                            type="button"
                            size="medium"
                            buttonType="link"
                            className="link-button align-top"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.viewPrivacyPolicy(e)}
                        >
                            <span>View Privacy Policy</span>
                        </Button>
                        <Button
                            domID="ok-button"
                            type="button"
                            name="OK"
                            size="large"
                            buttonType="emphasized"
                            onClick={() => {
                                this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, },
                                    () => this.props.transition(AuthType.LOGON_READY)
                                )
                            }}
                        />
                    </div>
                </FormRow>
            </>
        )
    }

    public getVerifyUI = () => {
      
        const { AuthType } = AuthStore;
        const { username, client } = this.state.formData;

        return (
            <>
                <TitleWrapperDiv>{ "Reverify" }</TitleWrapperDiv>
                <ARMMessage {...{
                    isBold: false,
                    isError: false,
                    message: "Please re-verify your credentials."
                }} />
                <Input
                    domID="username-input"
                    name="username"
                    maxLength={"20"}
                    placeholder="your username"
                    className="text-input"
                    label="User Name"
                    initialValue={username}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <Input
                    domID="cid-input"
                    name="client"
                    maxLength={"64"}
                    placeholder="0000"
                    className="text-input"
                    label="Client"
                    initialValue={client}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <FormRow>
                    <div style={{marginBottom:"15px"}}>
                        <Button
                            domID="privacy-policy-button"
                            type="button"
                            size="medium"
                            buttonType="link"
                            className="link-button align-top"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.viewPrivacyPolicy(e)}
                        >
                            <span>View Privacy Policy</span>
                        </Button>
                    </div>
                </FormRow>
                <FormRow>
                    <div>
                        <Button
                            domID="login-button"
                            type="button"
                            name="Cancel"
                            size="large"
                            buttonType="destroy"
                            onClick={() => { this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, }, () =>this.props.transition(AuthType.LOGON_READY)) }}
                        />
                    </div>
                    <div>
                        <Button
                            domID="verify-button"
                            type="submit"
                            name="Verify"
                            size="large"
                            buttonType="emphasized"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => { this.forceSingleClick(e, this.verifyUser) }}
                            disabled={(this.state.isDisabled || !(username && client))}
                        />
                    </div>
                </FormRow>
            </>
            )
    }

    public getReVerifyUI = () => {
        const { username, client } = this.state.formData;

        return (
            <>
                <TitleWrapperDiv>{ "Password Reset" }</TitleWrapperDiv>
                <ARMMessage {...{
                    isBold: false,
                    isError: false,
                    message: "Please re-verify your credentials."
                }} />
                <Input
                    domID="username-input"
                    name="username"
                    maxLength={"20"}
                    placeholder="your username"
                    className="text-input"
                    label="User Name"
                    initialValue={username}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <Input
                    domID="cid-input"
                    name="client"
                    maxLength={"64"}
                    placeholder="0000"
                    className="text-input"
                    label="Client"
                    initialValue={client}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <FormRow>
                    <div style={{ marginBottom: "15px" }}>
                        <Button
                            domID="privacy-policy-button"
                            type="button"
                            size="medium"
                            buttonType="link"
                            className="link-button align-top"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.viewPrivacyPolicy(e)}
                        >
                            <span>View Privacy Policy</span>
                        </Button>
                    </div>
                </FormRow>
                <FormRow>
                    <div>
                        <Button
                            domID="login-button"
                            type="button"
                            name="Cancel"
                            size="large"
                            buttonType="destroy"
                            onClick={() => {
                                this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, }, () => {
                                    this.props.history.push("/Transactions/Logon")
                                    this.props.transition(AuthStore.AuthType.LOGON_READY)
                                })
                            }
                            }
                        />
                    </div>
                    <div>
                        <Button
                            domID="verify-button"
                            type="submit"
                            name="Verify"
                            size="large"
                            buttonType="emphasized"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => { this.forceSingleClick(e, this.getSecQuestion) }}
                            disabled={(this.state.isDisabled || !(username && client))}
                        />
                    </div>
                </FormRow>
            </>
        )
    }

    public getSecQuestionUI = () => {
        const { username, client, secQuestAns } = this.state.formData;
        const { secQuestion } = this.state

        return (
            <>
                <TitleWrapperDiv>{"Password Reset"}</TitleWrapperDiv>
                <ARMMessage {...{
                    isBold: false,
                    isError: false,
                    message: "Please Answer Your Security Question."
                }} />
                <Input
                    domID="username-input"
                    name="username"
                    maxLength={"25"}
                    placeholder="your username"
                    className="text-input"
                    label="User Name"
                    initialValue={username}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <Input

                    domID="cid-input"
                    name="client"
                    maxLength={"64"}
                    placeholder="0000"
                    className="text-input"
                    label="Client"
                    initialValue={client}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <LableWrapperDiv>Security Question</LableWrapperDiv>
                <SecQuestWrapperDiv>{secQuestion}</SecQuestWrapperDiv>
                <Input

                    domID="cid-input"
                    name="secQuestAns"
                    maxLength={"64"}
                    placeholder="your answer"
                    autoComplete="new-password"
                    type="password"
                    className="text-input"
                    label="Answer"
                    initialValue={secQuestAns}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <FormRow>
                    <div style={{ marginBottom: "15px" }}>
                        <Button
                            domID="privacy-policy-button"
                            type="button"
                            size="medium"
                            buttonType="link"
                            className="link-button align-top"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.viewPrivacyPolicy(e)}
                        >
                            <span>View Privacy Policy</span>
                        </Button>
                    </div>
                </FormRow>
                <FormRow>
                    <div>
                        <Button
                            domID="login-button"
                            type="button"
                            name="Cancel"
                            size="large"
                            buttonType="destroy"
                            onClick={() => {
                                this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, }, () => {
                                    this.props.history.push("/Transactions/Logon")
                                    this.props.transition(AuthStore.AuthType.LOGON_READY)
                                })
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            domID="verify-button"
                            type="submit"
                            name="Verify"
                            size="large"
                            buttonType="emphasized"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.forceSingleClick(e, this.getSecQuestionAnsResp)
                            }}
                            disabled={(this.state.isDisabled || !(username && client && secQuestAns))}
                        />
                    </div>
                </FormRow>
            </>
        )
    }

    public getPasswordResetUI = () => {
        const { newPassword = "", newPasswordConfirmation="" } = this.state.formData;
        const { minLength, alphaNumeric, upperLowerCase, specialChar } = this.state.validationCriteria

        const passwordMustMatchMsg = "Passwords must match"

        const validationMsgs = IsPasswordValid("", minLength, alphaNumeric, upperLowerCase, specialChar)
        const validationErrorMsgs = IsPasswordValid(newPassword, minLength, alphaNumeric, upperLowerCase, specialChar)

        const getValidationMsgJSX = (msg: string, status: string = "default") => {
            let message:any = msg;
            if (msg.search("\n") !== -1) {
                message = msg.split("\n")
                message = <>{message[0]}<br/>{message[1]}</>
            }
            switch (status) {
                case "valid":
                    return (
                        <ValidationMsgDiv color={Colors.digitalGreen130}>
                            <div style={{display: 'flex', alignItems: 'center'}}><CircleCheck width={15} fillColor={Colors.digitalGreen130} /></div>
                            <div style={{ marginLeft: '5px' }}>{message}</div>
                        </ValidationMsgDiv>
                    )
                case "invalid":
                    return (
                        <ValidationMsgDiv color={Colors.digitalRed130}>
                            <div style={{ display: 'flex', alignItems: 'center' }}><CircleX width={15} fillColor={Colors.digitalRed130} /></div>
                            <div style={{ marginLeft: '5px' }}>{message}</div>
                        </ValidationMsgDiv>
                    )
                default:
                    return (
                        <ValidationMsgDiv color={Colors.grey70}>
                            <div style={{ display: 'flex', alignItems: 'center' }}><Circle width={15} fillColor={Colors.grey70} /></div>
                            <div style={{ marginLeft: '5px' }}>{message}</div>
                        </ValidationMsgDiv>
                    )
            }
            
        }

        let passwordValidationMsg
        if ((newPassword === "") && (newPasswordConfirmation === "")) {
            passwordValidationMsg = getValidationMsgJSX(passwordMustMatchMsg)
        } else if (newPassword === newPasswordConfirmation) {
            passwordValidationMsg = getValidationMsgJSX(passwordMustMatchMsg, "valid")
        } else {
            passwordValidationMsg = getValidationMsgJSX(passwordMustMatchMsg, "invalid")
        }

        const validationMsgsJSX = [passwordValidationMsg, ...validationMsgs.map((msg, index) => {
            if (
                ((newPassword === "") && (newPasswordConfirmation === ""))
                || (newPassword !== newPasswordConfirmation)
            ) {
                return getValidationMsgJSX(msg)
            } else {
                const hasErrorMsg = validationErrorMsgs.includes(msg)
                if (hasErrorMsg) {
                    return getValidationMsgJSX(msg, "invalid")
                } else {
                    return getValidationMsgJSX(msg, "valid")
                }
            }
        })]
       

        return (
            <>
                <TitleWrapperDiv>{"Password Reset"}</TitleWrapperDiv>
                <ARMMessage {...{
                    isBold: false,
                    isError: false,
                    message: "Please Enter Your New Password. \n"
                }} />
                <Input
                    domID="username-input"
                    name="newPassword"
                    maxLength={"64"}
                    autoComplete="new-password"
                    type="password"
                    className="text-input"
                    label="New Password"
                    initialValue={newPassword}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <Input
                    domID="cid-input"
                    name="newPasswordConfirmation"
                    maxLength={"64"}
                    autoComplete="new-password"
                    type="password"
                    className="text-input"
                    label="Confirm New Password"
                    initialValue={newPasswordConfirmation}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleInputChange(e) }}
                />
                <FormRow>
                    <div style={{ marginBottom:"20px" }}>
                        {validationMsgsJSX}
                    </div>
                </FormRow>
                <FormRow>
                    <div style={{ marginBottom: "15px" }}>
                        <Button
                            domID="privacy-policy-button"
                            type="button"
                            size="medium"
                            buttonType="link"
                            className="link-button align-top"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.viewPrivacyPolicy(e)}
                        >
                            <span>View Privacy Policy</span>
                        </Button>
                    </div>
                </FormRow>
                <FormRow>
                    <div>
                        <Button
                            domID="login-button"
                            type="button"
                            name="Cancel"
                            size="large"
                            buttonType="destroy"
                            onClick={() => {
                                this.setState({ isDisabled: false, message: ARMLoginMessages.instructions, }, () => {
                                    this.props.history.push("/Transactions/Logon")
                                    this.props.transition(AuthStore.AuthType.LOGON_READY)
                                })
                            }}
                        />
                    </div>
                    <div>
                        <Button
                            domID="verify-button"
                            type="submit"
                            name="Verify"
                            size="large"
                            buttonType="emphasized"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.forceSingleClick(e, this.validateNewPassword)}
                            disabled={(this.state.isDisabled || (validationErrorMsgs.length > 0) || (newPassword !== newPasswordConfirmation))}
                        />
                    </div>
                </FormRow>
            </>
        )
    }

    public getUI() {
        const { AuthType } = AuthStore
        switch (this.props.current) {

            case AuthType.FORGOT_PASSWORD:
                return this.getVerifyUI();

            case AuthType.LOGON_READY:
                return this.getDefaultLoginUI();

            case AuthType.EMAIL_REQUEST_RESPONSE:
                return this.getEmailRequestResponseUI();

            case AuthType.REVERIFY:
                return this.getReVerifyUI();

            case AuthType.SECURITY_QUESTION:
                return this.getSecQuestionUI();

            case AuthType.PASSWORD_RESET:
                return this.getPasswordResetUI();
                 
            default:
                return <h1>Default</h1>
        }
       
    }

    public render() {
        const { validationModalMessage, isValidationModalOpen } = this.state

        return (
            <ARMAuthWrapperDiv>
                <ARMLoginWrapper>
                    <ARMLoginBody>
                        <ARMLogoHeader title={'ASSURANCE\u000D\u000A REIMBURSEMENT MANAGEMENT\u2122'} />
                        <ARMLoginForm id={'Assurance-Authentication-Form'}>
                            {this.getUI()}
                        </ARMLoginForm>
                        <ARMCopyright />
                    </ARMLoginBody>
                </ARMLoginWrapper>

                <ModalConfirmation
                    title={"Something went wrong!"}
                    isOpen={isValidationModalOpen}
                    alertMode={true}
                    onModalToggle={() => this.setState({
                        isValidationModalOpen: false,
                        validationModalMessage: ""
                    })}
                    formattedMessage={validationModalMessage}
                />
            </ARMAuthWrapperDiv>
        )
    }
}

function mapStateToProps(state: ApplicationState) {
    return state.authentication;
}

export default withRouter(connect(
    mapStateToProps,
    AuthStore.actionCreators
)(
    ARMAuthentication
));
