import * as React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';



const RowCell = styled.div`
.tooltip {
  position: relative;
  display:inline-block;
  padding: 3px;
 
}
.tooltip .tooltiptext {
  visibility: hidden;
    border: 1px solid black;
    bottom: 105%;
    left: 0%;
    background: white;
    overflow:visible;
  min-width: 100px;
max-width: 500px;
font-size:10pt;
left: 10%;
  background-color: white;
  text-align: left;
  padding: 5px;
    
  /* Position the tooltip */
  position: absolute;
  z-index: 9999999;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.tooltip-supplementeditcriteria .tooltip-inner {
    max-width: 500px !important;
    padding: 3px 8px !important;
    color: #000 !important;
    text-align: left !important;
    background-color: #fff !important;
    border-radius: 4px !important;
}
`

export interface IGridRowCellProps {
}

export class ARMRowCellToolTip extends React.Component<IGridRowCellProps | any, { show: any }>
{
    constructor(props: IGridRowCellProps) {
        super(props);
        this.state = {
            show: false
        }
    }

    public handleMouseHover(status: string) {
        if (status === 'in') {
            setTimeout(this.setState({ show: true }), 10000)
        } else {
            this.setState({ show: false })
        }
    }

    static propTypes: {};

    public renderTooltip = (props: any) => {
        return <Tooltip id={this.props.id} className="tooltip-supplementeditcriteria" {...props}>{this.props.toolTip}</Tooltip>
    }
    render() {
        const {
            style,
            tooltip
        } = this.props;
        

        return (
            <RowCell>
                <OverlayTrigger
                    key={this.props.id}
                    placement={"top"}
                    delayShow={250}
                    delayHide={400}
                    overlay={(<Tooltip id={this.props.id} className="tooltip-supplementeditcriteria">{this.props.toolTip}</Tooltip>)}>
                    <span>{this.props.value}</span>
                </OverlayTrigger>
            </RowCell>
        );
    }
};



ARMRowCellToolTip.propTypes = {
    value: PropTypes.string,
    style: PropTypes.object,
    displayTooltip: PropTypes.bool
}
