import { Action, Reducer } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IHoldCodeUIState {
    selectedHoldCode: IHoldCodeUIData;
    holdCodeName: string;
    holdCodeDescription: string;
    holdCodeNameError?: string;
    holdCodeDescriptionError?: string;
    submitNCSAlert?: string;
    holdCodeRemoveConfirm?: string;
}


export interface IHoldCodeUIData {
    index: number;
    value: string;
    text: string;
};

export const defaultHoldCode: IHoldCodeUIData = {
    index: 0,
    value: '',
    text: '',
};

interface IHoldCodeUIFieldUpdate {
    value: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectHoldCode extends ICrudActionData<MCHoldCodeType, IHoldCodeUIData>{ }

export interface IModifyHoldCode extends ICrudActionData<MCHoldCodeType, MCHoldCode> { }

export interface IHoldCodeField extends ICrudActionData<MCHoldCodeType, IHoldCodeUIFieldUpdate> { }


export const actionCreators = {
    selectHoldCode: (selectInfo: ISelectHoldCode) => createDataAction('SELECT_HOLD_CODE', selectInfo),
    updateHoldCodeName: (holdCodeName: string) => createDataAction('UPDATE_HOLD_CODE_NAME', holdCodeName),
    updateHoldCodeDescription: (updateHoldCodeDescription: string) => createDataAction('UPDATE_HOLD_CODE_DESCRIPTION', updateHoldCodeDescription),
    addHoldCode: (data: IModifyHoldCode) => createDataAction('ADD_HOLD_CODE', data),
    updateHoldCode: (data: IModifyHoldCode) => createDataAction('UPDATE_HOLD_CODE', data),
    removeHoldCode: (data: IModifyHoldCode) => createDataAction('REMOVE_HOLD_CODE', data),
    sendSubmitNCSAlert: (selectInfo: ISelectHoldCode) => createDataAction('NCS_ALERT', selectInfo),
    promptHoldCodeRemoveConfirm: (holdCodeRemoveConfirm: IHoldCodeField) => createDataAction('CONFIRM_REMOVE_HOLD_CODE', holdCodeRemoveConfirm),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IHoldCodeUIState = {
    selectedHoldCode: defaultHoldCode,
    holdCodeName: '',
    holdCodeDescription: '',
    submitNCSAlert: '',
    holdCodeRemoveConfirm: '',
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IHoldCodeUIState, KnownActions> = (state: IHoldCodeUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'SELECT_HOLD_CODE':
                if (action.data.masterCrud) {
                    var holdCodeData = action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode[action.data.uiData.index - 1];
                    return {
                        ...state,
                        selectedHoldCode: action.data.uiData,
                        holdCodeName: holdCodeData ? holdCodeData["@Name"] || '' : '',
                        holdCodeDescription: holdCodeData ? holdCodeData["@Description"] || '' : '',
                        holdCodeNameError: undefined,
                        holdCodeDescriptionError: undefined,
                        submitNCSAlert: undefined,
                    }
                }
                break;
            case 'ADD_HOLD_CODE':
            case 'UPDATE_HOLD_CODE':
                if (action.data.masterCrud) {
                    var holdCode: MCHoldCode = action.data.uiData;
                    var newStateData: IHoldCodeUIState = { ...state };
                    newStateData.holdCodeNameError = undefined;
                    newStateData.holdCodeDescriptionError = undefined;
                    if (holdCode["@Name"] === '') {
                        newStateData.holdCodeNameError = 'Please enter a hold code name.';
                    }
                    if (holdCode["@Description"] === '') {
                        newStateData.holdCodeDescriptionError = 'Please enter a description.';
                    }
                    if (holdCode["@Description"].length > 256) {
                        newStateData.holdCodeDescriptionError = 'Please enter a shorter description as it is limited to 256 characters.';
                    }
                    
                    let arr = action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode;
                    for (var i = 0; i < arr.length; i++) {
                         if ((action.type == 'ADD_HOLD_CODE' && arr[i]['@Name'] === holdCode['@Name']) ||
                             (action.type == 'UPDATE_HOLD_CODE' &&  arr[i]['@Name'] === holdCode['@Name'] && i !== state.selectedHoldCode.index - 1)) {
                             newStateData.holdCodeNameError = 'A hold code by that name already exists. Please type in a different name.';
                             continue;
                         }
                     }
                    
                    if(!( newStateData.holdCodeNameError || newStateData.holdCodeDescriptionError )) {
                        var newIndex = state.selectedHoldCode.index;
                        if ((action.type == 'ADD_HOLD_CODE') && (action.data.masterCrud)) {
                            action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode.push(holdCode);
                            newIndex = action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode.length;
                        }
                        else if (action.data.masterCrud) {
                            action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode[state.selectedHoldCode.index - 1] = holdCode;
                            newIndex = state.selectedHoldCode.index;
                        }
                        newStateData.selectedHoldCode = {
                            index: newIndex,
                            value: holdCode["@ID"],
                            text: holdCode["@Name"]
                        };
                        newStateData.holdCodeName = holdCode["@Name"];
                        newStateData.holdCodeDescription = holdCode["@Description"];
                    }
                    return newStateData;
                }
                break;
            case 'UPDATE_HOLD_CODE_NAME':
                return {
                    ...state,
                    holdCodeName: action.data.toUpperCase(),
                }
                break;
            case 'UPDATE_HOLD_CODE_DESCRIPTION':
                return {
                    ...state,
                    holdCodeDescription: action.data,
                }
                break;
            case 'REMOVE_HOLD_CODE':
                if (action.data.masterCrud) {
                    var holdCode: MCHoldCode = action.data.uiData;
                    var index = state.selectedHoldCode.index - 1;
                    if (index > -1) {
                        var newIndex = state.selectedHoldCode.index - 1;
                        if (state.selectedHoldCode.index <
                            action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode.length) {
                            newIndex = state.selectedHoldCode.index;
                        }
                        if (newIndex < 0) {
                            newIndex = 0;
                        }

                        action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode.splice(index, 1);

                        var selectedHoldCode: IHoldCodeUIData = {
                            index: newIndex,
                            value: newIndex > 0 ? action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode[newIndex - 1]["@ID"] : '',
                            text: newIndex > 0 ? action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode[newIndex - 1]["@Name"] : '',
                        }
                        var holdCodeData =
                            action.data.masterCrud.HoldCodeMaintenanceInfo.HoldCodes.HoldCode[newIndex - 1];
                        var newState =
                        {
                            ...state,
                            selectedHoldCode: selectedHoldCode,
                            holdCodeName: newIndex > 0 ? holdCodeData["@Name"] : '',
                            holdCodeDescription: newIndex > 0 ? holdCodeData["@Description"] : '',
                            holdCodeRemoveConfirm: '',
                        }
                        return newState;
                    }
                    return {
                        ...state,
                    }
                }
                break;
            case 'NCS_ALERT':
                return {
                    ...state,
                    submitNCSAlert: action.data.uiData.value,
                }
                break;
            case 'CONFIRM_REMOVE_HOLD_CODE':
                return {
                    ...state,
                    holdCodeRemoveConfirm: action.data.uiData.value,
                }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return defaultState;
        }
    }

    return state || defaultState;
}
