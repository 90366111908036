import * as React from 'react';

//import { ErrorMessage } from 'ui-core';
import { DialogFieldset, DialogLegend, ErrorMessage } from '@common/DialogStyles';


export interface IErrorableFieldsetComponentProps {
    domID : string;
    legend? : string;
    hasError? : boolean;
    errorMessage? : string;
};

export interface IErrorableFieldsetComponentState {
};



export class ErrorableFieldsetComponent extends React.Component<IErrorableFieldsetComponentProps, IErrorableFieldsetComponentState>
{
    static defaultProps: IErrorableFieldsetComponentProps = {
        domID: "errorablefieldset",
        legend: "",
        hasError: false,
        errorMessage: "",
    
    };

    constructor(props: IErrorableFieldsetComponentProps) {
        super(props);
        this.state = {}
    }

    
    render() {
        return (
            <DialogFieldset
                id={this.props.domID}
                >
                {this.props.legend && this.props.legend.length > 0 &&
                    <DialogLegend>{this.props.legend}</DialogLegend>
                    }
                {this.props.hasError && this.props.errorMessage &&
                    <ErrorMessage>{this.props.errorMessage}</ErrorMessage>
                }
                {this.props.children}
            </DialogFieldset>
        );
    }
};