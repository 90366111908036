
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Tabs, TabContainer, ITabSelectInfo, Button, Grid, Caret, Help } from 'ui-core';
import { Typography } from '@commonResources/typography';
import { Colors } from '@commonResources/colorVariables';
import { CrudTypes } from '@commonResources/CrudTypes';
import styled from 'styled-components';
import { DialogWrapper, DialogButtonOK } from '@common/DialogWrapper';
import { ImageButton, IImageButtonImage } from '@common/ImageButton';
import { DialogFieldset, DialogLegend, DialogLabel } from '@common/DialogStyles';
import { SelectComponent } from '@common/SelectComponent';
import { ModalConfirmation } from '@common/ModalConfirmation';
import { IMergeCrudComponentProps, createCrudMapStateToProps, createCrudMapDispatchToProps, mergeCrudComponentProps, resetCrudComponentState } from '@scripts/util/CrudComponentHelpers';
import { Input } from '@common/UICLWrappers/ARMInput';
import { SelectDropdownV2 } from 'ui-core';
import { RadioButtonComponent } from '@common/RadioButton';
import { connect } from 'react-redux';
import { ApplicationState } from '@store/index';
import { IPreBillFilterState, IPreBillFilterActionProps, actionCreators } from '@store/PreBillFilter';
import { getRawToken } from '@scripts/session/SecurityToken';
import { URLs } from '@commonDevResources/constants';
import { PreBillEditFilterGridData, Phasefilters } from '../../../store/ui/PreBillFilterUI';
import { Log, LogMessage } from '../../../scripts/util/LogHelper';
import { PrebillEditFilterGrid } from './Grid';



export const ContentWrapper = styled.div`
                display: flex;
                flex:1 auto;
                flex-direction: row;
                width:900px;
`;
export const MainContentWrapper = styled.div`
                width:870px !important;
                height:550px !important;
                display: flex;
                flex:1 auto;
                flex-direction:row;
                select
                {
                    border-style:inset;
                    border-width:2px;
                    border-color:aliceblue;
                }
                overflow:auto;
`;
export const ContentLeftDivWrapper = styled.div`
                
                width:230px !important;
                padding:10px !important;
                .selectDropDown
                {
                   padding-top:30px !important; 
                   width:160px !important;
                }
                .selectDropDown label
                {
                    padding-top:15px;
                    padding-bottom:10px;
                }
                 select
                {
                    width:100% !important;
                }
                 
`;
export const ContentRightDivWrapper = styled.div`
                display:flex;
                flex-direction:row;
                width:480px !important;
                border:1px #808080 solid;
                .selectDropDown
                {
                   padding-top:30px !important;
                   
                }
                .selectDropDown label
                {
                    padding-top:15px;
                    padding-bottom:10px;
                }
               
`;
export const ContentRight1DivWrapper = styled.div`
                
                width:200px !important;
                padding:15px !important;
                .selectDropDown
                {
                   padding-top:30px !important; 
                }
                select
                {
                    width:100% !important;
                    
                }
`;

export const ContentRight2DivWrapper = styled.div`
                
                width:280px !important;
                padding:15px !important;
                .selectDropDown
                {
                   padding-top:30px !important; 
                }
                select
                {
                    width:100%;
                
                }
`;

export const ContentRowWrapper = styled.div`
                display: flex;
                flex:3;
                flex-direction: row
`;



export const ContentColumnWrapper = styled.div`
                display: flex;
                flex: 1 auto;
                width:fit-content;
                height:30px;
                flex-direction: column;
                padding:0px 5px 0px 5px !important;
               
`;

export const DialogCaptionHelp = styled.div`
                ${Typography.ARMFontFamily};
                ${Typography.bold};
                ${Typography.large};
                ${Typography.defaultLineHeight};
                color: ${Colors.white};
                padding: 0px;
                text-align: right;
                .help-button {
                                padding: 0px;
                                padding-top: 10px;
                                padding-right: 10px; 
                }
`
export const Tab2MainWrapper = styled.div`
                width:900px !important;
                height:100px !important;
                display: table;
                flex:1 auto;
                flex-direction:row;
                select
                {
                    border-style:inset;
                    border-width:2px;
                    border-color:aliceblue;
                }
                
`;
export const TableRow1Wrapper = styled.div`
                display: table-row;
                width:300px !important;
                height:65px;
                flex-direction:row;
                flex-wrap:wrap;
`;
export const TableRow2Wrapper = styled.div`
                display: table-row;
                width:100%;
                height:45px;
                flex-wrap:wrap;
                flex-direction:row;

`;
export const TableCellWrapper = styled.div`
                display:table-cell;
                width:170px;
                height:35px;
                padding:10px;
                vertical-align:top;
                #Search
                {
                 margin:5px;  
                }
                #select-Category-id
                {
                    width:210px !important;
                }
                #select-Phase-id
                {
                    width:180px !important;
                }
                #editname-input,#field-input
                {
                    width:160px !important;
                }
                #form-Type_wrapper,#form-Type2_wrapper
                {
                 width:160px !important;   
                }
                #form-Type_label,#form-Type2_label
                {
                   margin-top:8px;
                   text-transform:uppercase;
                   font-size:12px;
                }
`;
export const TableCellWrapperExtended = styled(TableCellWrapper)`
                vertical-align:middle;
                padding:0px !important;
                padding-top:20px !important;
                #Search
                {
                 margin:0px;  
                }
      `;
export const TableCellWrapperExtended2 = styled(TableCellWrapper)`
              display:flex;
      `;
export const FormRow = styled.div`
                display: flex;
                flex: 1 1 auto;
                flex-direction: column;
                
                .margin-padding {
                                margin-left: 5px;
                                margin-right: 5px;
                                color:black; 
                }
                #search-type_label,#search-type2_label
                {
                   padding-top:10px !important;     
                   text-transform:uppercase;
                   font-size:12px;
                }
                #search-type
                {
                    margin-right:5px;
                }
               #search-type,#search-type2
               {
                padding-left:10px !important;
                width:16px !important;
                }
                #search-type_wrapper,#search-type2_wrapper
                {
                    width:130px !important;
                }
                #TabContainerResponse
                {
                                width:900px !important;
                                line-height:3px;
                }
                #PhaseFilter,
                #PreBillFilter
                {
                    box-shadow: 0 0 0 0 black!important;
                    padding-top: 1px;
                    padding-bottom: 1px;
                }
                 #PhaseFilter > label,
                    #PreBillFilter > label
                     {
                         margin-bottom: 0px;
                     }
                }
                
`;

export const FormRowRightAlign = styled.div`
                display: flex;
                flex: 1 1 auto;
                justify-content:flex-end;
`;
export const FormRowHelp = styled.div`
                display: flex;
                flex: 1 auto; 
                padding-top:18px;
                justify-content:flex-start;
                #btnCategory,#btnCategory:hover,#btnStatus,#btnStatus:hover
                {
                    padding-left:0px !important;
                    width:35px !important;
                    background-color:#ebeced !important;
                    border:1px solid #ebeced;
                }
                #btnCategory svg,#btnStatus svg
                {
                    width:35px;
                    height:30px;
                }
`;

export const InputWrapper = styled.div`
                margin-left: 5px;
                margin-right: 5px;
}
`;

export const SelectButtons = styled.div`
                display: flex;
                flex: 1 1 auto;
                flex-direction: row;
                padding-top:10px;
                justify-content:center;
                button {
                                margin: 4px;
                                width: 85px;
                                text-align: center;
                }
`;
type param = {
    'Name': string;
    'Value': string;
};
interface IComponentProps {
    canView: boolean,
    title: string,
    canEdit: boolean,
};

interface SearchParams {
    formtype: string
    categoryId: string
    level: string
    dvarName: string
    editName: string
    phaseId: string
}



interface ExpandParams {
    formtype: string
    categoryId: string
    level: string
    dvarName: string
}



interface IComponentState {
};


export const DEFAULT_STATE: IComponentState = {
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

type IPreBillFilterProps = IMergeCrudComponentProps<IPreBillFilterState, IPreBillFilterActionProps, IOwnProps>;


export class PreBillFilter extends React.Component<IPreBillFilterProps, IComponentState> {

    processExpandGridData(body: any, index: Number) {
        
        setTimeout(() => { this.props.action.ui.getExpandOrCollapseState({ dat: { isExpanded: true, index } }) }, 10);
        setTimeout(() => { this.props.action.ui.updatePrebilEditFilter({ dat: { body, index } }) }, 10);
    }

    static defaultProps: IComponentProps = {
        canView: false,
        canEdit: false,
        title: 'Pre-bill Filter'
    };

    constructor(props: IPreBillFilterProps) {
        super(props);

        this.MapPhase.bind(this);
        this.ManagePrebillPhaseMapping.bind(this);
        this.RemovePhase.bind(this);
        this.collaspePrebillFilterGrid.bind(this);

        this.props.action.ui.onChangeFormType(1011);

    }

    public componentDidMount() {
        if (!this.props.canView) {
            console.log('user did not have rights to ' + this.props.title);
            this.props.history.push('/LandingPage');
        }
        this.LoadPreBillPhase(false);
        this.LoadCategories();
        this.LoadPhases();
        this.SearchPrebillFilter();
    }

    collaspePrebillFilterGrid = (category: string, dvarName: string, index: number) => {
        
        setTimeout(() => { this.props.action.ui.getExpandOrCollapseState({ dat: { isExpanded: false, index } }) }, 10);
        this.props.action.ui.removePrebillEditFilter({ dat: { category: category, dvarName: dvarName, index: index } });
    }

    expandPrebillFilterGrid = async (category: string, dvarName: string, index: Number) => {

        let level: string = '2';
        if (dvarName != '') {
            level = '3';
        }
        let formType: string = this.props.dataStore.ui.filterFormType;

        let url = URLs.api + '/api/data/prebillEditFilter/LoadExpansion';
        let ipstring: ExpandParams = { formtype: formType, level: level, categoryId: category, dvarName: dvarName };
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(ipstring)
        }).then(response => {
            if (response.status === 200) {
                
                response.json().then(body => { this.processExpandGridData(body, index); return body; });
            } else {
                throw new Error(response.statusText);
            }
        })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    }

    SearchPrebillFilter = async () => {

        let url = URLs.api + '/api/data/prebillEditFilter/search';
        let categoryId = this.props.dataStore.ui.filterCategory;
        let phaseId = this.props.dataStore.ui.filterPhase;
        
        let field = this.props.dataStore.ui.filterFieldName;
        let edit = this.props.dataStore.ui.filterEditName;
        let level = -1;
        if ((categoryId === -1 || categoryId == null) && field == '' && edit == '' && (phaseId.toString() === '' || phaseId.toString() === '-1'))
            level = 1;
        else {

            if (phaseId.toString() !== '' && phaseId.toString() !== '-1')
                level = 0;
            else if (edit != '')
                level = 3;
            else if (field != '')
                level = 2;
            else if (categoryId != -1)
                level = 1;
        }

        let ipstring: SearchParams = {
            formtype: this.props.dataStore.ui.filterFormType, level: level.toString(), categoryId: categoryId === -1 ? '' : categoryId.toString(),
            phaseId: phaseId == '-1' ? '' : phaseId, dvarName: field
            , editName: edit
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(ipstring)
        }).then(response => {
            if (response.status === 200) {
                
                response.json().then(body => { this.processLoadGridData(body); return body; });
            } else {
                throw new Error(response.statusText);
            }
        })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    }
    processLoadGridData = (body: any) => {
        
        if (body.length === 0 || (body.length === 1 && (body[0].categoryId == "the" &&
            body[0].category == "field" &&
            body[0].dvarId == "id" &&
            body[0].dvarName == "was" &&
            body[0].editName == "not" &&
            body[0].phaseId == "a" &&
            body[0].phase == "valid" &&
            body[0].inheritedFrom == "field"))) {

            alert("Nothing found using the search criteria provided!");
        }
        setTimeout(() => { this.props.action.ui.searchPrebilEditFilter(body) }, 10);

    }

    MapPhase = async (row: PreBillEditFilterGridData, index: number) => {


        let url = URLs.api + '/api/data/prebillEditFilter/mapPhase';
        let ipstring = {
            formtype: this.props.dataStore.ui.filterFormType, categoryId: row.categoryId//  this.props.dataStore.ui.filterCategory.toString()
            , phaseId: row.phaseId,
            dvarName: row.dvarName, editName: row.editName
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(ipstring)
        }).then(response => {
            if (response.status === 200) {
                
                response.json().then(body => {  this.processMapData(row, body, index); return body; });
            } else {
                alert('Unable to map entry, please try again. [category=' + row.category + ', field=' + row.dvarName + ', edit=' + row.editName + ', phaseId=' + row.phase); // x[' + x.status + '] e[' + e + ']');
                //throw new Error(response.statusText);
            }
        })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    }
    processMapData = (row: PreBillEditFilterGridData, body: boolean, index: number) => {
     
        if (body) {

            setTimeout(() => { this.props.action.ui.updateFilterPhaseList({ dat: { index: index, phase: row.phase, phaseId: row.phaseId } }) }, 10);
        }

        else
            alert('Unable to map entry, please try again. [category=' + row.category + ', field=' + row.dvarName + ', edit=' + row.editName + ', phaseId=' + row.phase); // x[' + x.status + '] e[' + e + ']');
    }

    RemovePhase = async (row: PreBillEditFilterGridData, index: number) => {

        let url = URLs.api + '/api/data/prebillEditFilter/RemovePhase';
        let categoryId = row.categoryId;
        let dvarName = row.dvarName;
        if (row.editName !== '') {
            categoryId = '';
            dvarName = '';
        }
        else if (dvarName !== '') {
            categoryId = '';
        }
        let ipstring = {
            formtype: 'Institutional', categoryId: categoryId
            , phaseId: row.phaseId,
            dvarName: dvarName, editName: row.editName
        };
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(ipstring)
        }).then(response => {
            if (response.status === 200) {
                
                response.json().then(body => { this.processUnMapData(row, body, index); return body; });
            } else {
                // throw new Error(response.statusText);
                alert('Unable to map entry, please try again. [category=' + row.category + ', field=' + row.dvarName + ', edit=' + row.editName + ', phaseId=' + row.phase); // x[' + x.status + '] e[' + e + ']');
            }
        })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    }
    processUnMapData = (row: PreBillEditFilterGridData, body: boolean, index: number) => {
        if (body) {

            setTimeout(() => { this.props.action.ui.updateFilterPhaseList({ dat: { index: index, phase: 'Not Assigned', phaseId: '' } }) }, 10);
            setTimeout(() => {if(row.editName ==='') this.props.action.ui.getExpandOrCollapseState({ dat: { isExpanded: false, index } }) }, 10);
            setTimeout(() => { if (row.editName === '') this.props.action.ui.removePrebillEditFilter({ dat: { category: row.category, dvarName: row.dvarName, index: index } }) }, 10);
        }
        else
            alert('Unable to map entry, please try again. [category=' + row.category + ', field=' + row.dvarName + ', edit=' + row.editName + ', phaseId=' + row.phase); // x[' + x.status + '] e[' + e + ']');


    }

    ManagePrebillPhaseMapping = (row: PreBillEditFilterGridData, id: string, index: number) => {
        if (this.props.canEdit) {

            if (id === '-1')
                return this.RemovePhase(row, index);
            else if (id !== '0') {
                let phaseList: any[] = this.props.dataStore.ui.filterPhaseList.filter(item => item.value === id);
                
                row.phaseId = phaseList[0].value;
                row.phase = phaseList[0].label;
                return this.MapPhase(row, index);
            }
        }
    }

    public componentWillUnmount() {
        resetCrudComponentState(this.props.action, this.props.dataStore);
    }

    public prepareSearch() {
    }


    public onOKViewResponse(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.history.push("/LandingPage");
    }

    public onSearchViewResponse(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.clearResponse();
        this.prepareSearch();
        this.props.action.ui.selectTab(1);

    }


    public onClearViewResponse(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.clearViewResponse();
        setTimeout(() => {

            this.setState({ filterRecords: [] })
        }, 500);
    }


    onToggleConfirmation(e: React.MouseEvent<HTMLElement>) {
        this.props.action.confirm.closeConfirm();
    }

    public onClickConfirm(e: React.MouseEvent<HTMLButtonElement>) {
        this.props.dataStore.confirm.confirmCallback();
    }

    ApplyPreBillPhase = async (preBillPhaseData: any[]) => {

        var url = URLs.api + '/api/data/prebilldata';

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            },
            body: JSON.stringify(preBillPhaseData)
        }).then(response => {
            if (response.status === 200) {
               this.LoadPreBillPhase(true);
            } else {
                throw new Error(response.statusText);
            }
        })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    }

    LoadPreBillPhase = async (prebillFilterOnly: boolean) => {


        var url = URLs.api + '/api/data/prebilldata';

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        }).then(response => {
            if (response.status === 200) {
                
                response.json().then(body => { this.processResponse(body,prebillFilterOnly); return body; });
            } else {
                throw new Error(response.statusText);
            }
        })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    }
    processResponse = (body: any, prebillFilterOnly: boolean) => {

        
        if (prebillFilterOnly)
            setTimeout(() => { this.props.action.ui.loadPrebillPhaseData(body) }, 10);
            else
        setTimeout(() => { this.props.action.ui.loadMasterData(body) }, 10);

    }

    LoadCategories = async () => {

        let url = URLs.api + '/api/data/prebillEditFilter/loadCategories';

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        }).then(response => {
            if (response.status === 200) {
                
                response.json().then(body => { this.processLoadCategories(body); return body; });
            } else {
                throw new Error(response.statusText);
            }
        })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    }
    processLoadCategories = (body: any) => {

        
        setTimeout(() => { this.props.action.ui.loadCategories(body) }, 10);

    }

    LoadPhases = async () => {

        let url = URLs.api + '/api/data/prebillEditFilter/loadPhases';

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `${getRawToken()}`
            }
        }).then(response => {
            if (response.status === 200) {
                
                response.json().then(body => { this.processLoadPhases(body); return body; });
            } else {
                throw new Error(response.statusText);
            }
        })
            .catch(error => {
                console.error(`promise rejected: URL:${url} with Error: ${error}`);
                LogMessage("error", `promise rejected: URL:${url} with Error: ${error}`);
            });
    }
    processLoadPhases = (body: any) => {

        
        setTimeout(() => { this.props.action.ui.loadPhases(body) }, 10);

    }

    getSearchTab() {
        return (
            <React.Fragment>
                <MainContentWrapper>
                    <ContentLeftDivWrapper id="LeftDiv">
                        <ContentColumnWrapper>
                            <FormRow>
                                <RadioButtonComponent
                                    id="search-type"
                                    label="Institutional"
                                    checked={this.props.dataStore.ui.formType === 1011}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.onChangeFormType(1011);
                                        this.props.action.ui.updateLevelActivities({
                                            formType: 1011,
                                            phase: this.props.dataStore.ui.selectedPhase
                                        });
                                    }}
                                    disabled={false}
                                />
                            </FormRow>
                        </ContentColumnWrapper>
                        <ContentColumnWrapper>
                            <FormRow>
                                <RadioButtonComponent
                                    id="search-type2"
                                    label="Professional"
                                    checked={this.props.dataStore.ui.formType === 1001}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.onChangeFormType(1001);
                                        this.props.action.ui.updateLevelActivities({
                                            formType:1001,
                                            phase: this.props.dataStore.ui.selectedPhase
                                        });
                                    }}
                                    disabled={false}
                                />
                            </FormRow>
                        </ContentColumnWrapper>
                        <SelectComponent
                            key={"@ID"}
                            className='selectDropDown'
                            label='Pre-Bill Phase :'
                            title='Pre-Bill Phase'
                            size={3}
                            multiple='false'
                            onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectPhase(e)}
                            optionFields={{
                                value: "id",
                                text: "name"
                            }}
                            records={this.props.dataStore.ui.phaseList}
                            selectedValue={(this.props.dataStore.ui.selectedPhase ||'').toString()}
                        />

                    </ContentLeftDivWrapper>
                    <ContentRightDivWrapper>
                        <ContentRight1DivWrapper >
                            <SelectComponent
                                key={"@ID"}
                                //className='selectDropDown'
                                label='Edit Levels :'
                                title='Edit Levels'
                                size={this.props.dataStore.ui.levelList.length}

                                multiple='false'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectLevel(e)}
                                optionFields={{
                                    value: "id",
                                    text: "name"
                                }}
                                records={this.props.dataStore.ui.levelList}
                                selectedValue={(this.props.dataStore.ui.selectedLevel || '').toString()}
                            />
                        </ContentRight1DivWrapper>
                        <ContentRight2DivWrapper>
                            <SelectComponent
                                key={"id"}
                                //   className='selectDropDown'
                                label='Edit Activity :'
                                title='Edit Activity'
                                size={this.props.dataStore.ui.activityList.length}
                                multiple='true'
                                onSelect={(e: React.ChangeEvent<HTMLSelectElement>) => this.onSelectActivityList(e)}
                                optionFields={{
                                    value: "id",
                                    text: "name"
                                }}
                                records={this.props.dataStore.ui.activityList}
                                selectedMultiValue={this.props.dataStore.ui.selectedActivity}
                            />
                        </ContentRight2DivWrapper >
                    </ContentRightDivWrapper>
                </MainContentWrapper>

                <FormRow>
                    <SelectButtons>

                        <Button
                            domID="Search"
                            name="Clear"
                            buttonType="standard"
                            size="small"
                            type="button"
                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onClearPhaseFilter(e)}
                        />
                        <Button
                            domID="Apply"
                            name="Apply"
                            buttonType="standard"
                            size="small"
                            type="button"
                            onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onApplyPhaseFilter(e)}
                        />
                    </SelectButtons>
                </FormRow>
            </React.Fragment>
        );
    }
    onSelectPhase(e: React.ChangeEvent<HTMLSelectElement>): void {
        
        this.props.action.ui.onChangePhase(e.target.value);
        this.props.action.ui.updateLevelActivities({ formType: this.props.dataStore.ui.formType, phase: Number(e.target.value) });//, //level: this.props.dataStore.ui.selectedLevel });
    }
    onClearPhaseFilter(e: React.ChangeEvent<HTMLButtonElement>) {
        this.props.action.ui.clear();
    }
    onApplyPhaseFilter(e: React.ChangeEvent<HTMLButtonElement>) {
        let phaseFilter:any =
        {
            formType: this.props.dataStore.ui.formType.toString(),
            phaseId: this.props.dataStore.ui.selectedPhase.toString(),
            editLevelId: this.props.dataStore.ui.selectedLevel.toString(),
            editActivityIds: this.props.dataStore.ui.selectedActivity
        };
        let phaseFilterArr: any = [];
        phaseFilterArr.push(phaseFilter)

        this.ApplyPreBillPhase(phaseFilterArr);
    }

    public getSelectedOptions(e: React.ChangeEvent<HTMLSelectElement>) {
        if (!e.target.options || e.target.options.length === 0) {
            return [];
        };
        let result = [];

        for (let i = 0; i < e.target.options.length; i++) {
            if (e.target.options[i].selected)
                result.push(e.target.options[i].value);
        }
        return result;
    }


    onSelectActivityList(e: React.ChangeEvent<HTMLSelectElement>): void {
        let selectedValue = this.getSelectedOptions(e);
        this.props.action.ui.onChangeActivities(
             selectedValue);

    }
    onSelectLevel(e: React.ChangeEvent<HTMLSelectElement>): void {
        
        this.props.action.ui.onChangeLevel(e.target.value);
    }
    onChangeFormType(formType: number): void {
        this.props.action.ui.onChangeFormType(formType);

    }

    getResultsTab() {
        return (
            <React.Fragment>
                <Tab2MainWrapper>

                    <TableRow1Wrapper>
                        <TableCellWrapperExtended2>
                            <TableCellWrapperExtended2>
                                <RadioButtonComponent
                                    id="form-Type"
                                    label="Institutional"
                                    checked={this.props.dataStore.ui.filterFormType === "Institutional"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.props.action.ui.ChangeFilterFormType("Institutional");
                                        setTimeout(() => { this.SearchPrebillFilter() }, 10);
                                    }

                                    }
                                    disabled={false}
                                />
                            </TableCellWrapperExtended2>
                            <TableCellWrapperExtended2>

                                <RadioButtonComponent
                                    id="form-Type2"
                                    label="Professional"
                                    checked={this.props.dataStore.ui.filterFormType === "Professional"}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        this.props.action.ui.ChangeFilterFormType("Professional");
                                        setTimeout(() => { this.SearchPrebillFilter() }, 10);
                                    }}
                                    disabled={false}
                                />

                            </TableCellWrapperExtended2>
                        </TableCellWrapperExtended2>

                    </TableRow1Wrapper>


                    <TableRow2Wrapper>
                        <TableCellWrapper>
                            <SelectDropdownV2
                                domID="select-Category-id"
                                className="dropdown"
                                label="Category:"
                                isClearable={false}
                                onChange={(e: any) => this.props.action.ui.updateFilterCategory(e.value)}
                                options={this.props.dataStore.ui.filterCategoryList}
                                initialValue={this.props.dataStore.ui.filterCategoryList.filter(item => item.value === this.props.dataStore.ui.filterCategory)}
                            />
                        </TableCellWrapper>
                        <TableCellWrapper>
                            <Input
                                domID="field-input"
                                label="Field Name:"
                                maxLength={30}
                                initialValue={this.props.dataStore.ui.filterFieldName}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateFieldName(e.target.value.toUpperCase())}
                            />
                        </TableCellWrapper>
                        <TableCellWrapper>
                            <Input
                                domID="editname-input"
                                label="Edit Name:"
                                maxLength={10}
                                initialValue={this.props.dataStore.ui.filterEditName}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateEditName(e.target.value.toUpperCase())}
                            />
                        </TableCellWrapper>
                        <TableCellWrapper>
                            <SelectDropdownV2
                                domID="select-Phase-id"
                                className="dropdown"
                                label="Pre-Bill Phase:"
                                isClearable={false}
                                isSearchable={true}
                                size='small'
                                onChange={(e: any) => this.props.action.ui.updateFilterPhase(e.value)}
                                options={this.props.dataStore.ui.filterPhaseList}
                                initialValue={this.props.dataStore.ui.filterPhaseList.filter(item => item.value === this.props.dataStore.ui.filterPhase)}

                            />
                        </TableCellWrapper>
                        <TableCellWrapperExtended>
                            <Button
                                domID="Search"
                                name="Search"
                                buttonType="standard"
                                size="small"
                                type="button"
                                onClick={(e: React.ChangeEvent<HTMLButtonElement>) => this.onSearchPrebillFilter(e)}
                            />
                        </TableCellWrapperExtended>


                    </TableRow2Wrapper>

                </Tab2MainWrapper>
                <MainContentWrapper>
                    <PrebillEditFilterGrid rows={this.props.dataStore.ui.searchPrebillFilter}
                        expandPrebillFilterGrid={this.expandPrebillFilterGrid}
                        collaspePrebillFilterGrid={this.collaspePrebillFilterGrid}
                        enablePhaseList={this.enablePhaseList}
                        ManagePrebillPhaseMapping={this.ManagePrebillPhaseMapping}
                        loadPhaseList={this.props.dataStore.ui.filterPhaseList}
                        sortGrid={this.props.action.ui.sortFilterPhaseList}
                        sortedFlag={this.props.dataStore.ui.sorted}
                    />
                </MainContentWrapper>
            </React.Fragment>

        );
    }
    enablePhaseList = (enable: boolean, index: number): void => {
        this.props.action.ui.enablePhaseList({ dat: { enable: enable, index: index } });
    }
    onCategoryChange(e: any) {
        throw new Error("Method not implemented.");
    }
    onSearchPrebillFilter(e: React.ChangeEvent<HTMLButtonElement>) {
        this.SearchPrebillFilter();
    }

    categoryList() {
        let lookupList = this.props.dataStore.ui.filterCategoryList;
        if (!lookupList)
            return [];

        let result: any[] = [];

        lookupList.forEach(ob => {
            if (ob['value'])
                result.push({ label: ob['value'], value: ob['id'] });
        });
        return result;

    }

    getTabs() {
        var tabJson = [
            {
                domID: 'PhaseFilter',
                label: 'Phase Filter',
                tabContent: this.getSearchTab()
            },
            {
                domID: 'PreBillFilter',
                label: 'Pre-Bill Filter',
                tabContent: this.getResultsTab()
            },
        ];
        return tabJson;
    }

    onTabSelect(e: React.MouseEvent<HTMLElement>, selectInfo: ITabSelectInfo) {
        e.preventDefault();
        if (selectInfo.selectedTabIndex === 1)
            this.prepareSearch();
        this.props.action.ui.selectTab(selectInfo.selectedTabIndex);

    }

    onTabChange(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) {
        e.preventDefault();
        if (selectInfo.tabIndex === 1)
            this.prepareSearch();

        this.props.action.ui.selectTab(selectInfo.tabIndex);

    }

    public render() {
        var width: React.CSSProperties = {
            width: '300px'
        }


        return (

            <InputWrapper>
                <DialogWrapper title="Pre-Bill Filter" helpUrl='/Support/Help/HELP_Maint_PreBillFilter.htm' isBusy={this.props.dataStore.crud.dataStatus === 'REQUEST'}>
                    <ContentWrapper>
                        <FormRow>
                            <TabContainer
                                domID="TabContainerResponse"
                                initialTab={this.props.dataStore?.ui.selectedTabIndex}
                                className='tabs'
                                onTabChange={(e: React.MouseEvent<HTMLElement>, selectInfo: ITabChangeInfo) => this.onTabChange(e, selectInfo)}
                                tabs={this.getTabs()}
                                width='900px'
                                height='600px'
                            />
                        </FormRow>
                    </ContentWrapper>
                    <ModalConfirmation
                        isOpen={this.props.dataStore?.confirm.isOpen}
                        onModalToggle={(e: React.MouseEvent<HTMLElement>) => this.onToggleConfirmation(e)}
                        message={this.props.dataStore?.confirm.message}
                        onConfirm={(e: React.MouseEvent<HTMLButtonElement>) => this.onClickConfirm(e)}
                    />
                </DialogWrapper>
            </InputWrapper>
        );
    }
};

var connectedHoc = connect<IPreBillFilterState, IPreBillFilterActionProps, IOwnProps, IPreBillFilterProps, ApplicationState>(
    createCrudMapStateToProps('preBillFilter'),            // Selects which state properties are merged into the component's props
    createCrudMapDispatchToProps(actionCreators),
    mergeCrudComponentProps
)(PreBillFilter);

export default withRouter(connectedHoc);
