import { Reducer, ActionCreatorsMapObject } from 'redux';
import { ICrudActionData } from "../../scripts/util/CrudComponentHelpers";
import { IFormUIState, defaultFormState } from "@store/ui/BaseCrudUI";
import { createDataAction, ActionTypes, IApiAction } from "../../scripts/util/ActionHelpers";

// Properties of action creators specific to the ReportTypeOptions view
export interface IReportOptionsData {
    delimiter: string;
    qualifier: string;
    dateFormat: string;
}
// UI representation of CRUD data. 
/*
 Even though we discussed maybe only keeping the UI state in the UI,
 per Office Hours and https://github.com/reduxjs/redux/issues/1287,
 you have to update the current CRUD data with every user gesture 
 (or do a separate action with one big update before calling updateWithDelta),
 so might as well go ahead and update local state through redux at the same time.
 */
export interface IReportOptionsUIState extends IReportOptionsData, IFormUIState {};

// merge our custom action data interface with CRUD boilerplate
export interface IModifyReportOptions extends ICrudActionData<MCReportOptions, string> { };
export interface IResetCrudFlag extends ICrudActionData<MCReportOptions, boolean> { };
export interface IInitializeReportOptions extends ICrudActionData<MCReportOptions, MCReportOption> {};

// exported for if I get time to do tests, otherwise does not need export
export const actionCreators = {
    initalizeOptions: (rawApiReturn: IInitializeReportOptions) => createDataAction('INIT_RO_OPTIONS', rawApiReturn),
    editDateFormat: (reportOptionsInfo: IModifyReportOptions) => createDataAction('EDIT_RO_DATE_FORMAT', reportOptionsInfo),
    editDelimiter: (reportOptionsInfo: IModifyReportOptions) => createDataAction('EDIT_RO_DELIMITER', reportOptionsInfo),
    editQualifier: (reportOptionsInfo: IModifyReportOptions) => createDataAction('EDIT_RO_TEXT_QUALIFIER', reportOptionsInfo),
    resetDirtyFlag: (reportOptionsInfo: IResetCrudFlag) => createDataAction('RESET_RO_DIRTY', reportOptionsInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultReportOptionsState: IReportOptionsUIState = {
    ...defaultFormState,
    delimiter: '',
    qualifier: '',
    dateFormat: 'LONG',
}

export function mapUiStateToCrud<T extends IReportOptionsData>(state: T):MCReportOption {
    const reportOption: MCReportOption = {
        '@Delimiter': state.delimiter,
        '@Qualifier': state.qualifier,
        '@DateFormat': state.dateFormat,
    };
    return reportOption;
};

export function mapCrudToUiState(crud: MCReportOption): IReportOptionsUIState {
    return {
        isDirty: false,
        delimiter: crud['@Delimiter'],
        qualifier: crud['@Qualifier'],
        dateFormat: crud['@DateFormat'],
    }
}

export const reportOptionsUIReducer: Reducer<IReportOptionsUIState, KnownActions> = (state: IReportOptionsUIState | undefined, action: KnownActions) => {
    const crudData = action.data?.masterCrud?.ReportOptionMaintenanceInfo;
    const isDirty = true;
    let newState = state && { ...state };

    if (state) {
        switch (action.type) {
            case 'INIT_RO_OPTIONS':
            {
                    const crud = action.data.uiData;
                    if (crud) return mapCrudToUiState(crud);
                    break;
            }
            case 'EDIT_RO_DATE_FORMAT':
            {
                    newState = { ...state, dateFormat: action.data.uiData, isDirty };
                    if (crudData) crudData.ReportOption = mapUiStateToCrud(newState);
                    break;
            }
            case 'EDIT_RO_DELIMITER':
            {
                    newState = { ...state, delimiter: action.data.uiData, isDirty };
                    if (crudData) crudData.ReportOption = mapUiStateToCrud(newState);
                    break;
            }
            case 'EDIT_RO_TEXT_QUALIFIER':
            {
                newState = { ...state, qualifier: action.data.uiData, isDirty };
                if (crudData) crudData.ReportOption = mapUiStateToCrud(newState);
                break;
            }
            case 'RESET_RO_DIRTY':
                newState = { ...state, isDirty: false };
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }
    return newState || defaultReportOptionsState;
}