import { Action, Reducer } from 'redux';
import { createAction, createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IManualRunUIState {
    selectedAutoList: IAutoListUIData;

    selectedItem: MCMR_AutoProcess;
    selectedAutoModules: string[];
    
    ManualRunAlert?: string;
}


export const defaultAutoList: IAutoListUIData = {
    id: '',
    text: '',
};

export interface IAutoListUIData {
    id: string;
    text: string;
};

interface IManualRunUIAutoModulesSelected {
    selected: string[];
}
interface IManualRunUIFieldUpdate {
    value: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectAutoList extends ICrudActionData<MCManualRunType, IAutoListUIData> { }
export interface ISelectAutoModulesList extends ICrudActionData<MCManualRunType, IManualRunUIAutoModulesSelected> { }
export interface IUpdateManualRunField extends ICrudActionData<MCManualRunType, IManualRunUIFieldUpdate> { }

export const actionCreators = {
    selectAutoList: (selectInfo: ISelectAutoList) => createDataAction('MANUALRUN_SELECT_AUTOLIST', selectInfo),
    selectAutoModules: (selectInfo: ISelectAutoModulesList) => createDataAction('MANUALRUN_SELECT_AUTOMODULES', selectInfo),

    sendManualRunAlert: (fieldInfo: IUpdateManualRunField) => createDataAction('MANUALRUN_SEND_ALERT', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];




const emptyItem: MCMR_AutoProcess = {
    '@ID': '',
    '@Name' : '',
    AutoModules: { AutoModule: []},
};


export const defaultState: IManualRunUIState = {
    selectedAutoList: defaultAutoList,
    selectedItem: emptyItem,
    selectedAutoModules: [],

    ManualRunAlert: '',

};



// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<IManualRunUIState, KnownActions> = (state: IManualRunUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'MANUALRUN_SELECT_AUTOLIST':
                if (action.data.masterCrud) {

                    let data = emptyItem;
                    let allSelected: any[] = []; 
                    
                    let item = action.data.masterCrud?.ManualRun?.AutoProcesses?.AutoProcess.find(ob => { return ob["@ID"] === action.data.uiData.id });
                    
                    if (item) {
                        data = item;

                        // now select all      
                        data?.AutoModules?.AutoModule.forEach((ob: any) => { allSelected.push(ob['@ID']); });
                    }

                    
             //       console.log(allSelected);
                    
                    return {
                        ...state,
                        selectedAutoList: action.data.uiData,
                        selectedItem: JSON.parse(JSON.stringify(data)),  // copy of the object
                        selectedAutoModules: JSON.parse(JSON.stringify(allSelected)),   // when you select an item, autoselect all automodules of the item

                        ManualRunAlert: '',
                    }
                }
                break;
            case 'MANUALRUN_SELECT_AUTOMODULES':

//                console.log(action.data.uiData.selected);

                return {
                    ...state,
                    selectedAutoModules: action.data.uiData.selected,
                }
                break;
            case 'MANUALRUN_SEND_ALERT':
                return {
                    ...state,
                    ManualRunAlert: action.data.uiData.value,
                }
                break;
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //    const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
