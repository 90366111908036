import { combineReducers, Reducer } from 'redux';
import {has, isPlainObject} from 'lodash';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '../scripts/util/CrudComponentHelpers';
import * as ClaimEventNotificationUI from './ui/ClaimEventNotificationUI';
import { CrudTypes } from '../commonResources/CrudTypes';
import { ValidationCallback } from '../scripts/util/ActionHelpers';
import { ValidateJSONSchema, VerifyParentAndArray } from '../scripts/util/ValidationHelpers';

//  TYPE DEFINITIONS ARE INCOMPLETE: REPLACE <any> WITH APPROPRIATE DEFINITIONS in the .d.ts file
export type IClaimEventNotificationState = ICrudComponentState<any, ClaimEventNotificationUI.IClaimEventUIState>;

export const actionCreators = createCrudComponentActions<ClaimEventNotificationUI.ActionCreators>(ClaimEventNotificationUI.actionCreators);
export type IClaimEventNotificationTypeActions = typeof actionCreators;
export type IClaimEventNotificationActionProps = ICrudComponentActionProps<IClaimEventNotificationTypeActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimEventNotification, ClaimEventNotificationUI.reducer);
export const reducer = combineReducers<IClaimEventNotificationState>(reducers);

export const validationCallback: ValidationCallback<any> = (crud: any): crud is any => {
    if (!isPlainObject(crud) || !has(crud, 'ClaimEventNotificationMaintenanceInfo')) return false;
    VerifyParentAndArray(crud.ClaimEventNotificationMaintenanceInfo, 'StatusHistories', 'StatusHistory');
    VerifyParentAndArray(crud.ClaimEventNotificationMaintenanceInfo, 'ClaimNotes', 'ClaimNote');
    VerifyParentAndArray(crud.ClaimEventNotificationMaintenanceInfo, 'Facilities', 'Facility');
    VerifyParentAndArray(crud.ClaimEventNotificationMaintenanceInfo, 'SpecificPayers', 'Payer');
    VerifyParentAndArray(crud.ClaimEventNotificationMaintenanceInfo, 'PayerSpecificCodes', 'Code');
    VerifyParentAndArray(crud.ClaimEventNotificationMaintenanceInfo, 'ClaimEventNotifications', 'ClaimEventNotification');
    const notifications = crud.ClaimEventNotificationMaintenanceInfo.ClaimEventNotifications.ClaimEventNotification as MCCenClaimEventNotificationItem[];
    notifications.forEach(item => {
        VerifyParentAndArray(item, 'StatusHistories', 'StatusHistory');
        VerifyParentAndArray(item, 'ClaimNotes', 'ClaimNote');
        VerifyParentAndArray(item, 'Facilities', 'Facility');
        VerifyParentAndArray(item, 'SpecificPayers', 'Payer');
        VerifyParentAndArray(item, 'PayerSpecificCodes', 'Code');
    });
    return true;
}

