import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, createAction, ActionTypes } from '@scripts/util/ActionHelpers'; 

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface IViewResponseUIData {
    id: string;
    name: string;
};
export interface IPreBillFilterUIState {
    filterCategory: Number;
    filterPhase: string;
    prePhaseFilters: Phasefilters[];
    selectedTabIndex: Number;
    phaseList: any[];
    levelList: any[];
    activityList: any[];
    selectedPhase: number;
    selectedLevel: number;
    selectedActivity: string[];
    filterRecords: any[];
    formType: number;
    filterCategoryList: any[];
    filterPhaseList: any[];
    filterFormType: string;
    filterFieldName: string;
    filterEditName: string;
    filterPhases: string;
    filterLevel: string;
    sorted: boolean; 
    searchPrebillFilter: PreBillEditFilterGridData[];
}


export interface Phasefilters {
    editActivityIds: string[];
    formType: number;
    phaseId: number;
    editLevelId: number;
  
}
export interface PhaseFormTypeFilter {
    formType: number;
    phase: number;
}
export interface PreBillEditFilterGridData {
    category: string;
    categoryId: string;
    dvarId: string;
    dvarName: string;
    editName: string;
    inheritedFrom: number;
    phase: string;
    phaseId: string;
    phaseLinkClicked?: boolean;
    selectedPhase?: string;
    expandClicked?: boolean;
}


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data). 
export const actionCreators = {
    selectTab: (tabIndex: Number) => createDataAction('SELECT_PREBILL_TAB', tabIndex),
    onChangeFormType: (formType: number) => createDataAction('CHANGE_FORM_TYPE', formType),
    onChangePhase: (phaseType: number) => createDataAction('CHANGE_PHASE_TYPE', phaseType),
    onChangeLevel: (level: number) => createDataAction('CHANGE_LEVEL_TYPE', level),
    onChangeActivities: (activities: string[]) => createDataAction('CHANGE_ACTIVITIES', activities),
    updateLevelActivities: (phaseFilter: PhaseFormTypeFilter)  => createDataAction('UPDATE_LEVEL_ACTIVITIES', phaseFilter),
    loadMasterData: (masterData: any) => createDataAction('LOAD_MASTER_DATA', masterData),
    loadPrebillPhaseData: (masterData: any) => createDataAction('LOAD_PREPHASE_FILTER_DATA', masterData),
    
    loadCategories: (masterData: any) => createDataAction('LOAD_CATEGORIES_DATA', masterData),
    loadPhases: (masterData: any) => createDataAction('LOAD_PHASES_DATA', masterData),
    ChangeFilterFormType: (formType: any) => createDataAction('CHANGE_FILTER_FORM_TYPE', formType),
    updateFilterCategory: (filterCategory: any) => createDataAction('UPDATE_FILTER_CATEGORY', filterCategory),
    updateFieldName: (filterFieldName: any) => createDataAction('UPDATE_FILTER_FIELD_NAME', filterFieldName),
    updateEditName: (filterEditName: any) => createDataAction('UPDATE_FILTER_EDIT_NAME', filterEditName),
    updateFilterPhase: (filterPhase: any) => createDataAction('UPDATE_FILTER_PHASE', filterPhase),
    searchPrebilEditFilter: (gridData: any) => createDataAction('SEARCH_PREBILL_FILTER', gridData),
    updatePrebilEditFilter: (data: any) => createDataAction('UPDATE_PREBILL_FILTER', data),
    removePrebillEditFilter: (data: any) => createDataAction('REMOVE_PREBILL_FILTER', data),
    enablePhaseList: (data: any) => createDataAction('ENABLE_PHASE_LIST', data),
    getExpandOrCollapseState: (data: any) => createDataAction('GET_EXPAND_COLLAPSE_STATE', data),
    updateFilterPhaseList: (data: any) => createDataAction('UPDATE_FILTER_PHASE_LIST', data),
    sortFilterPhaseList: (data: any) => createDataAction('SORT_FILTER_PHASE_LIST', data),
    apply: () => createAction('APPLY'),
    clear: () => createAction('CLEAR'),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IPreBillFilterUIState = {
    selectedTabIndex: 0,
    phaseList: [],
    levelList: [],
    activityList: [],
    selectedPhase: 0,
    selectedLevel: 0,
    selectedActivity: [],
    filterRecords: [],
    sorted: false,
    formType: 1011,
    prePhaseFilters: [],
    filterFormType: 'Institutional',
    filterCategoryList: [],
    filterCategory:-1,
    filterPhaseList:[],
    filterFieldName: '',
    filterEditName: '',
    filterPhases: '',
    filterLevel: '',
    filterPhase: '',
    searchPrebillFilter:[]
};

function sortAscById_category(a:PreBillEditFilterGridData , b:PreBillEditFilterGridData) {
    var x = a.category;
    var y = b.category;
    var result = ((x < y) ? -1 : ((x > y) ? 1 : 0));

    return result;
}
function sortAscById_field(a: PreBillEditFilterGridData, b: PreBillEditFilterGridData) {
    var x = a.dvarName;
    var y = b.dvarName;
    var result = ((x < y) ? -1 : ((x > y) ? 1 : 0));

    return result;
}

function sortAscById_edit(a: PreBillEditFilterGridData, b: PreBillEditFilterGridData) {
    let x = a.editName;
    let y = b.editName;
    var result = ((x < y) ? -1 : ((x > y) ? 1 : 0));

    return result;
}

function sortDescById_category(a: PreBillEditFilterGridData, b: PreBillEditFilterGridData) {
    return (sortAscById_category(a, b) * -1);
}

function sortDescById_field(a: PreBillEditFilterGridData, b: PreBillEditFilterGridData) {
    return (sortAscById_field(a, b) * -1);
}

function sortDescById_edit(a: PreBillEditFilterGridData, b: PreBillEditFilterGridData) {
    return (sortAscById_edit(a, b) * -1);
}

function sortGrid(data: PreBillEditFilterGridData[], sortCol: string, ascending: boolean) {
    console.log(ascending, sortCol);
    if (ascending === true) {
        
        data = data.sort(sortCol === 'Category' ? sortAscById_category : sortCol === 'FieldName' ? sortAscById_field : sortAscById_edit);
    } else {
        data = data.sort(sortCol === 'Category' ? sortDescById_category : sortCol === 'FieldName' ? sortDescById_field : sortDescById_edit);
    }

    return data;
}


//.sortingCol, data.sortDirection);

// ----------------
// REDUCER - For a given state and actio returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IPreBillFilterUIState, KnownActions> = (state: IPreBillFilterUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
           
            case 'SELECT_PREBILL_TAB':
                return {
                    ...state,
                    selectedTabIndex: action.data,
                }
                break;
            case 'LOAD_PREPHASE_FILTER_DATA':
                return {
                    ...state,
                    prePhaseFilters: action.data!.phaseFilters,
                }
                break;
            case 'LOAD_MASTER_DATA':
                let formType = action.data!.options.formTypes[0];
                let phase = action.data!.options.phases[0].id;
                
                let levelActivites = action.data!.phaseFilters.filter((item: Phasefilters) => item.phaseId.toString() === phase && item.formType === formType)
                
                let level = undefined;
                let activity = [];
                if (levelActivites.length > 0) {
                    level = levelActivites[0]!.editLevelId;
                    activity = levelActivites[0]!.editActivityIds;
                }
                return {
                    ...state,
                    prePhaseFilters: action.data!.phaseFilters,
                    phaseList: action.data!.options.phases,
                    levelList: action.data!.options.editLevels,
                    activityList: action.data!.options.editActivities,
                    selectedPhase: phase,
                    selectedLevel:level,
                    selectedActivity: activity,
                }
                break;
            case 'LOAD_CATEGORIES_DATA':

                let categoryData: any[] = action.data.map((item: { value: string, id: Number }) => { return { label: item.value, value: item.id } });
                categoryData = [{label:'',value:-1},...categoryData]
                return {
                    ...state,
                    filterCategoryList: categoryData
                }
                break;
            case 'LOAD_PHASES_DATA':

                let phaseData: any[] = action.data.map((item: { value: string, id: Number }) => { return { label: item.value, value: item.id } });
                phaseData = [{ label: '', value: -1 }, ...phaseData]
                return {
                    ...state,
                    filterPhaseList: phaseData
                }
                break;
            case 'CHANGE_FILTER_FORM_TYPE':
                return {
                    ...state,
                    filterFormType: action.data
                }
                break;
            case 'UPDATE_FILTER_CATEGORY':
               
                return {
                    ...state,
                    filterCategory: action.data
                }
                break;
            case 'UPDATE_FILTER_PHASE':
                console.log(action.data);
                return {
                    ...state,
                    filterPhase: action.data
                }
                break;
            case 'SEARCH_PREBILL_FILTER':
                 
                return {
                    ...state
                    ,sorted: false,
                    searchPrebillFilter: action.data
                }
                break;
            case 'UPDATE_PREBILL_FILTER':
                 
                return {
                    ...state,
                    searchPrebillFilter: [
                        ...state.searchPrebillFilter.slice(0, action.data.dat.index + 1)
                        , ...action.data.dat.body,
                        ...state.searchPrebillFilter.slice(action.data.dat.index + 1)
                    ]
                }
                break;
                
            case 'GET_EXPAND_COLLAPSE_STATE':


                let stateData = state.searchPrebillFilter;
                let inxData = stateData[action.data.dat.index];
                inxData.expandClicked = action.data.dat.isExpanded;
                return {
                    ...state,
                    searchPrebillFilter: [
                        ...state.searchPrebillFilter.slice(0, action.data.dat.index)
                        , inxData,
                        ...state.searchPrebillFilter.slice(action.data.dat.index + 1)
                    ]
                }
                break;

            case 'ENABLE_PHASE_LIST':
                 
                let ind: number = action.data.dat.index;
                let data: PreBillEditFilterGridData[] = [...state.searchPrebillFilter]
                let changedRow: PreBillEditFilterGridData = data[action.data.dat.index];
                changedRow.phaseLinkClicked = action.data.dat.enable;

                return {
                    ...state,
                    searchPrebillFilter: [
                        ...state.searchPrebillFilter.slice(0, ind )
                        , changedRow,
                        ...state.searchPrebillFilter.slice(ind + 1)
                    ]
                }
                break;
            case 'SORT_FILTER_PHASE_LIST':
                let unstortedData = state.searchPrebillFilter.map(row => {
                    return { ...row, phaseLinkClicked: false}});
                
                return {
                    ...state,
                    sorted:true,
                    searchPrebillFilter: sortGrid(unstortedData, action.data.sortCriteria.sortCol, action.data.sortCriteria.isAscendingSort)//.sortingCol, data.sortDirection);

                }
                break;  
            case 'UPDATE_FILTER_PHASE_LIST':
                 
                ind = action.data.dat.index;
                let gridData = state.searchPrebillFilter;
                changedRow = state.searchPrebillFilter[action.data.dat.index];
                changedRow.phase = action.data.dat.phase;
                changedRow.phaseId = action.data.dat.phaseId;
                changedRow.phaseLinkClicked = false;
                data = [...state.searchPrebillFilter.slice(0, ind)
                    , changedRow,
                ...state.searchPrebillFilter.slice(ind + 1)
                ] 
                return {
                    ...state,
                    searchPrebillFilter: data
                      
                }
                break;   
            case 'REMOVE_PREBILL_FILTER':
                 
                 data = state.searchPrebillFilter;
                let count = 0;
                ind = action.data.dat.index;

                let filterData:PreBillEditFilterGridData[] = [];


                if (action.data.dat.dvarName === '')
                    filterData = data.filter((item) => item.category === action.data.dat.category && item.dvarName !== '');
                else if (action.data.dat.dvarName !== '')
                    filterData = data.filter(item => item.category === action.data.dat.category && item.dvarName === action.data.dat.dvarName && item.editName !== '');
                const myArrayFiltered = data.filter((el) => {
                    return !filterData.some((f) => {
                        return f.categoryId === el.categoryId && f.dvarId === el.dvarId && f.editName === el.editName;
                    });
                }); 
                return {
                    ...state,
                    searchPrebillFilter: myArrayFiltered
                }
                break;
                
            case 'UPDATE_FILTER_FIELD_NAME': 
                return {
                    ...state,
                    filterFieldName: action.data
                }
                break;
            case 'UPDATE_FILTER_EDIT_NAME': 
                return {
                    ...state,
                    filterEditName: action.data
                }
                break;

            case 'UPDATE_LEVEL_ACTIVITIES': { ;
                let levelActivites = state.prePhaseFilters.filter((item: Phasefilters) => item.phaseId === Number(action.data.phase)
                    && item.formType === action.data.formType);// && item.editLevelId == action.data.level) ;
                
                return {
                    ...state,
                    selectedLevel: levelActivites.length === 0 ? 1 : levelActivites[0].editLevelId,
                    selectedActivity: levelActivites.length === 0 ? [] : levelActivites[0].editActivityIds,
                }
            }
                break;
         
            case 'CHANGE_FORM_TYPE':

                return {
                    ...state,
                    formType:action.data,
                }
                break;
            case 'CHANGE_LEVEL_TYPE':
                return {
                    ...state,
                    selectedLevel: action.data,
                }
                break;
            case 'CHANGE_PHASE_TYPE':
                let selectedLevel = state.levelList[0].id; 
                if (!state.selectedLevel || state.selectedLevel !== 0)
                    selectedLevel = state.selectedLevel
                return {
                    ...state,
                    selectedPhase: action.data,
                    selectedLevel:selectedLevel
                }
                break;
            case 'CHANGE_ACTIVITIES':
                return {
                    ...state,
                    selectedActivity: action.data,
                }
                break;
           
            case 'APPLY':
                return state;
            case 'CLEAR':
                return {
                    ...state,
                    selectedLevel: 1,
                    selectedActivity:[]
                };
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
              //  const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
