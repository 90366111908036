import React, { useEffect, useState, useMemo } from 'react';
import { DialogWrapper, DialogButton, FilterFourButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { Input, Typography, Button } from 'ui-core';
import { Colors } from '@commonResources/colorVariables';
import styled from 'styled-components';
import { get, uniqueId, isEmpty, has } from 'lodash';
import { CheckBoxComponent } from '@common/CheckBox';

const UpdateRequestFieldsModalContainerDiv = styled.div`
    width: 950px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: ${Colors.grey100};
    ${Typography.small};
    ${Typography.defaultLineHeight};
    ${Typography.normal};
    ${Typography.LetterSpacing};
    ${Typography.uppercase};
`;
const ContentWrapper = styled.div`
     width: 100%;`

const SelectButtons = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
    justify-content: center;
    /*padding-top: 10px;*/
    
    button {
        margin: 4px;
        width: 85px;
        text-align: center;
        font-size: 10px !important;
    }
`;

interface PrintCommandSet {
    '@ID': string;
    '@ClaimTypeId': string;
    '@Name': string;
    '@IsDefault': string;
}

interface IComponentProps {
    closeModal: () => void;
    printCommandSet: any;
    onSave: (printCommandSetInfo: any) => void;
};

export const AddPrintCommandModal = ({ onSave, printCommandSet, closeModal }: IComponentProps) => {

    const [isDefault, setisDefault] = useState<any>("0");
    const handleSave = (e: React.ChangeEvent<HTMLButtonElement>,) => {
        if (printCommandSet["@Name"] !== "") {
            onSave(printCommandSet)
            closeModal();
        }
        else {
            return;
        }
    }

    const handleCancel = (e: React.ChangeEvent<HTMLButtonElement>) => {
        closeModal();
    }

    const UpdateIsDefaultCheckboxDetails = (isChecked: boolean) => {
        if (isChecked) {
            setisDefault("-1")
            printCommandSet["@IsDefault"] = "-1";
        }
        else {
            setisDefault("0")
            printCommandSet["@IsDefault"] = "0";
        }
    }
    const AddPrinCommandSetName = (name: string) => {
        
        if (!isEmpty(name)) {
            printCommandSet["@Name"] = name;
            printCommandSet["@IsDefault"] = isDefault;

        }
        else {
            printCommandSet["@Name"] = "";
            printCommandSet["@IsDefault"] = isDefault;
        }
    }

    return (
        <DialogWrapper
            title="Paper Command Set Entry"

            marginTop={`0px`}
            width={`100%`}
        >
            <UpdateRequestFieldsModalContainerDiv>
                <ContentRowWrapper style={{ marginTop: "10px" }}>
                    <Input
                        domID="bridge-key"
                        className="text-input"
                        label="Please enter a name for this Paper Command Set."
                        maxLength={25}
                        initialValue={printCommandSet["@Name"]}
                        onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => AddPrinCommandSetName(e.target.value.trim())}
                    />
                </ContentRowWrapper>
                <ContentRowWrapper style={{ marginTop: "10px", marginLeft: "-14px" }}>
                    <CheckBoxComponent
                        id="isDefaultModel_CheckBox"
                        labelBefore='Default'
                        checked={isDefault === "-1" ? true : false}

                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => UpdateIsDefaultCheckboxDetails(e.target.checked)}
                    />
                </ContentRowWrapper>
                <ContentRowWrapper style={{ marginTop: "30px" }}>
                    <SelectButtons style={{ width: "246px" }}>
                        <Button
                            domID="saveNew-btn"
                            name={"SAVE"}
                            buttonType="standard"
                            size="small"
                            type="button"
                            onClick={handleSave}
                        />
                        <Button
                            domID="cancel-btn"
                            name={"CANCEL"}
                            buttonType="standard"
                            size="small"
                            type="button"
                            onClick={handleCancel}
                        />
                    </SelectButtons>
                </ContentRowWrapper>
            </UpdateRequestFieldsModalContainerDiv>
        </DialogWrapper >
    )
}

export default AddPrintCommandModal;

