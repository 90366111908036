import { Reducer, ActionCreatorsMapObject } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers";
import { SubmitClaimUpdateType, SubmitClaimUpdateData } from '@scripts/@types/MasterCrud/SubmitClaimUpdateFile';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ISubmitClaimUpdateFileUIState {
    uploadSubmitClaimUpdate: string;
}

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface IUploadSubmitClaimUpdate {
    value: string;
};

export const defaultUploadSubmitClaim: IUploadSubmitClaimUpdate = {
    value: '',
};

export interface ISubmitClaimUpdate extends ICrudActionData<SubmitClaimUpdateType, IUploadSubmitClaimUpdate> { }

export const actionCreators = {
    selectSubmitClaimUpdateFile: (selectInfo: ISubmitClaimUpdate) => createDataAction('CHANGE_CLAIM_UPDATE_FILE', selectInfo),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: ISubmitClaimUpdateFileUIState = {
    uploadSubmitClaimUpdate: '',
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<ISubmitClaimUpdateFileUIState, KnownActions> = (state: ISubmitClaimUpdateFileUIState | undefined, action: KnownActions) => {

    if (state != undefined) {
        switch (action.type) {
            case 'CHANGE_CLAIM_UPDATE_FILE':
                {
                    return {
                        ...state,
                        uploadSubmitClaimUpdate: action.data.uiData.value
                    }
                    break;
                }
            default:
                return state;
        }
    }

    return state || defaultState;
}
