import * as React from 'react';
import styled from 'styled-components';
import { CheckBoxComponent } from './CheckBox';
import { Input } from './UICLWrappers/ARMInput';

const TableContent = styled.div`
    height:225px !important;
    overflow: auto;
    background-color: white;

    table {
        table-layout: auto
    }
    #alphasplit-user{
        text-transform:uppercase;
    }
`;

export interface IARMUserData {
    userId: string;
    selected: boolean;
    user: string;
    alphaSplit: string;
    isDisabled?: boolean;
}

export interface IARMHeadingInfo {
    title: string;
    maxWidth?: string;      // expect something like '10 px', if undefined or '', then maxWidth will not be set for that column
}

export interface IARMUserComponentProps {
    headings: IARMHeadingInfo[];
    users: IARMUserData[];
}

export interface ISelectARMUserComponentProps {
    users: IARMUserComponentProps;
    onSelectCallback?(items: IARMUserData[]): void,
    onAlphaSplitChangedCallback?(item: IARMUserData, newValue: string): void,
    selectedARUsers: []
}

export interface ISelectARMUserComponentState {
    heading: string[];
    users: IARMUserData[];
    selectedUsers: IARMUserData[];
}

export class ARMUserComponent extends React.Component<ISelectARMUserComponentProps, ISelectARMUserComponentState> {

    static defaultProps: ISelectARMUserComponentProps = {
        users: {
            headings: [],
            users: []
        },
        onSelectCallback: undefined,
        onAlphaSplitChangedCallback: undefined,
        selectedARUsers: []
    }

    constructor(props: ISelectARMUserComponentProps) {
        super(props);
    }

    readonly tableStyle: React.CSSProperties = {
        backgroundColor: "white",
        width: "100%",
        padding: "5px"
    };

    readonly tabelHeaderStyle: React.CSSProperties = {
        backgroundColor: '#9ba1a9',
        padding: "6px",
        color: "white",
    };

    readonly tableHeaderCellStyle: React.CSSProperties = {
        padding: "10px",
        textAlign: "left",
        fontSize: "12px",
    };

    readonly spanStyle: React.CSSProperties = {
        fontSize: "11px"
    };

    readonly spanStyleDisabledUser: React.CSSProperties = {
        fontSize: "11px",
        color: "red",
    };

    AssignmentChanged = () => {
        if (this.props.onSelectCallback) {
            this.props.onSelectCallback(this.props.users.users.filter(item => item.selected === true));
        }
    }

    AlphaSplitChanged = (item: IARMUserData, newValue: string) => {
        if (this.props.onAlphaSplitChangedCallback) {
            this.props.onAlphaSplitChangedCallback(item, newValue);
        }
    }

    public handleUserAssignmentChange(e: React.ChangeEvent<HTMLInputElement>, user: IARMUserData) {

        let userAssignments: IARMUserData[] = [];

        // add checkbox value to set if checked
        if (e.currentTarget.checked) {
            user.selected = e.currentTarget.checked;
            userAssignments.push(user);
        } else {
            // remove checkbox value from set if unchecked
            userAssignments = this.props.users.users.filter(usr => { return usr.userId === user.userId; });
            if (userAssignments) {
                userAssignments.map(item => {
                    item.selected = e.currentTarget.checked; 
                    return item;
                })
            }
        }

        this.setState({ selectedUsers: [...userAssignments] }, this.AssignmentChanged);
    }

    public updateCheckAssignmentsUser(user: IARMUserData) {
        let userInfo = this.props.users.users.find(usr => usr.userId === user.userId);
        let userAssignments: IARMUserData[] = [];

        if (userInfo !== undefined) {
            if (!userInfo.selected) {
                userAssignments.push(userInfo);
            } else {
                userAssignments = userAssignments.filter(usr => { return usr.userId !== user.userId; });
                if (userAssignments) {
                   user.selected = false;
                }
            }
        }
        else {
            userAssignments = userAssignments.filter(usr => { return usr.userId !== user.userId; });
            if (userAssignments) {
                user.selected = false;
            }
        }

        this.setState({ selectedUsers: [...userAssignments] }, this.AssignmentChanged);
    }

    getHeaderElementStyle(info: IARMHeadingInfo) {
        var headerStyle: React.CSSProperties = {
            padding: "10px",
            textAlign: "left",
            fontSize: "12px",
            maxWidth: 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        };

        if (info?.maxWidth && info.maxWidth.length > 0) {
            headerStyle.maxWidth = info.maxWidth?.toString();
        }

        return (headerStyle);
    }

    private getCheckBoxWidth(): string | undefined {
        const headings = this.props?.users?.headings;
        if (headings?.length > 0 && headings[0].maxWidth && headings[0].maxWidth.length > 0) {
            return headings[0].maxWidth;
        }

        return '20px';
    }

    private getLabelWidth(): string | undefined {
        const headings = this.props?.users?.headings;
        if (headings?.length > 1 && headings[1].maxWidth && headings[1].maxWidth.length > 0) {
            return headings[1].maxWidth;
        }

        return '100%';
    }

    private getAlphaSplitWidth(): string | undefined {
        const headings = this.props?.users?.headings;
        if (headings?.length > 2 && headings[2].maxWidth && headings[2].maxWidth.length > 0) {
            return headings[2].maxWidth;
        }

        return '100%';
    }

    render() {
        const checkboxWidth = this.getCheckBoxWidth();
        const labelWidth = this.getLabelWidth();
        const alphaSplitWidth = this.getAlphaSplitWidth();

        return (
            <TableContent>
                <table id='ARMUserComponent' style={this.tableStyle}>
                    <thead style={this.tabelHeaderStyle}>
                        <tr>
                            {this.props.users.headings.map(head => (
                                <th key={'headerCell' + head.title} style={this.getHeaderElementStyle(head)}>
                                    {head.title}
                                </th> ))}
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.users.users.map(user => <tr id={'row' + user.userId + '_' + Math.random().toString(36).substr(2, 9)} key={'row' + user.userId + '_' + Math.random().toString(36).substr(2, 9)}>
                            <td>
                                <CheckBoxComponent
                                    id={user.userId}
                                    label=''
                                    width={checkboxWidth}
                                    checked={user.selected}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => { this.handleUserAssignmentChange(e, user) }}
                                />
                            </td>
                            <td width={labelWidth} onClick={(e) => this.updateCheckAssignmentsUser(user)}>
                                <span style={user.isDisabled ? this.spanStyleDisabledUser : this.spanStyle}>{user.user}</span>
                            </td>
                            <td width={alphaSplitWidth} >
                                <Input
                                    domID="alphasplit-user"
                                    maxLength={40}
                                    initialValue={user.alphaSplit}
                                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => { this.AlphaSplitChanged(user, e.target.value)}}
                                />
                            </td>
                        </tr>)}
                    </tbody>
                </table> 
            </TableContent>
        );
    }
}