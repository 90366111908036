import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ClaimNoteTypeUI from './ui/ClaimNoteTypeUI';
import { CrudTypes } from '@commonResources/CrudTypes';
import { ValidationCallback } from '@scripts/util/ActionHelpers';
import { ValidateJSONSchema } from '@scripts/util/ValidationHelpers';

export type IClaimNoteTypeState = ICrudComponentState<MCClaimNoteType, ClaimNoteTypeUI.IClaimNoteTypeUIState>;

export const actionCreators = createCrudComponentActions<ClaimNoteTypeUI.ActionCreators>(ClaimNoteTypeUI.actionCreators);
export type IClaimNoteTypeActions = typeof actionCreators;
export type IClaimNoteTypeActionProps = ICrudComponentActionProps<IClaimNoteTypeActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimNotes, ClaimNoteTypeUI.reducer);
export const reducer = combineReducers<IClaimNoteTypeState>(reducers);

export const validationCallback: ValidationCallback<MCClaimNoteType> = (crud: any): crud is MCClaimNoteType => {
    let testCrud: MCClaimNoteType = crud as MCClaimNoteType;
    let validShape: boolean = ValidateJSONSchema(testCrud, "MCClaimNoteType");
    if (validShape) {
        
        if (testCrud.ClaimNoteTypeMaintenanceInfo?.NoteTypes as any === "") {
            testCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes = { NoteType: new Array<MCNoteType>() };
        }
       
        if (testCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes &&
            !Array.isArray(testCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType)) {
            let arr = new Array<MCNoteType>();
            arr.push(JSON.parse(JSON.stringify(testCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType)));
            testCrud.ClaimNoteTypeMaintenanceInfo.NoteTypes.NoteType = arr;
        }
    }
    /*
    // 9/10/21 - ts-transformer-keys replaced with typescript-json-schema approach using JSON schema. Avoids CRA issues with custom transforms and adds optional parameter support.
    // ts-transformer-keys is one approach for validation. It is not strictly required, and in some cases be undesirable. Once instance where it would be undesireable is when
    // working with optional data. The keys<>() function includes optional fields in the array it returns, but doesn't indicate if the field is optional or not, yet it is acceptable
    // for raw data to exclude such field information. In these cases, an approach without ts-transformer-keys might be desired (or perhaps ts-transformer-keys-2 would work, or
    // our own custom transformer)
    // Note that keys<>() could still be used to return an array of fields, and optional fields removed here.
    // Alternatively, keys<>() could be left unused and an array of fields provided to ValidateObject, as well.
    // Approach without ts-transformer-keys
    // Other options include:
    //   - using converting TS to JSON Schema and using JSON Schema
    //   - using ts-io, ajv, or runtypes; however, allof these replace the interface declaraction in TypeScript with their own,
    //     leaving the interface to be derived from their implementation, vs deriving what is needed at runtime from TypeScript.
    //   - using ts-transformer-keys-2, which includes support for optional parameters and types. however, this is simply a github project and does not appear to be maintained.
    //   - using typescript-runtime-types, but does not seem very active.
    let testCrud: MCClaimNoteType = crud as MCClaimNoteType;
    let validShape: boolean =
        (testCrud?.ClaimNoteTypeMaintenanceInfo !== undefined)
    &&  (testCrud?.ClaimNoteTypeMaintenanceInfo?.NoteTypes !== undefined);
    if (validShape) {
        VerifyArray(testCrud?.ClaimNoteTypeMaintenanceInfo?.NoteTypes, "NoteType");
    }
    */
    return validShape;
}
