import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as ViewResponsesUI from './ui/ViewResponsesUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type IViewResponsesState = ICrudComponentState<MCViewResponses, ViewResponsesUI.IViewResponsesUIState>;

export const actionCreators = createCrudComponentActions<ViewResponsesUI.ActionCreators>(ViewResponsesUI.actionCreators);
export type IViewResponsesActions = typeof actionCreators;
export type IViewResponsesActionProps = ICrudComponentActionProps<IViewResponsesActions>
export const reducers = createCrudComponentReducers(CrudTypes.mcti277ResponsesResults, ViewResponsesUI.reducer);
export const  reducer = combineReducers<IViewResponsesState>(reducers);

