import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindAll } from 'lodash';
import styled from 'styled-components';
import { DialogWrapper, OKCancelButtons, ContentRowWrapper, ContentColumnWrapper } from '@common/DialogWrapper';
import { Input, Button, DataItem, SelectDropdownV2 } from 'ui-core';
import { DialogFieldset, DialogLegend } from '@common/DialogStyles';
import { handleChange, pageLeave } from '@commonResources/userModified';
export const ContentWrapper = styled.div`
    display: flex;
    flex: 1 1 auto;
    flex-direction: row;
`;

export const CustomContentWrapper = styled.div`
    width :300px;
`;

export const SelectActions = styled.div`
    margin: 5px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    .formItem {
        width  :120px;
        &.firstName{
            margin-top: 15px;
        }
       
        label {
            font-weight:bold;
            color:#37474F;
            text-align: left;
        }

    }
    .formValue {        
        word-break: break-word;
        width: 290px;
        margin-left: 10px;
        label {
            text-transform:none;
            color:#37474F;
            font-size:13px;
            font-weight: 300;
        }
    }
    fieldset{
        .formItem{
            width:150px;
            label{
                text-align:left;
            }
        }
    }
    #loginName-input {
        width: 300px;
    }
    #ciamUserName-button {
        margin-top: 10px;
    }
`;

export const Inputs = styled.div`
    span {
        width: 300px;
    }
`;

interface IComponentProps {
    canView: boolean;
};

interface IComponentState {
    files: any;
    isBusy: boolean;
    isAlertOpen: boolean;
    alertMessage: string;
};

export const DEFAULT_STATE: IComponentState = {
    files: new FormData(),
    isBusy: false,
    isAlertOpen: false,
    alertMessage: "",
};

type IOwnProps = IComponentProps & RouteComponentProps<{}>;

export class CiamAccount extends React.Component<any, any> {

    static defaultProps: IComponentProps = {
        canView: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    public onClickCiamUserNameButton(e: React.MouseEvent<HTMLButtonElement>) {
        let confirmMsg = '';
        confirmMsg = "Changing this email address may require email address changes in other CIAM-enabled applications.~";
        confirmMsg += "This email address will be replicated across all the CIDs where this user is linked to CIAM.";
        this.props.action.ui.updateCiamUserNameConfirmationModal(true);
        this.props.action.ui.updateCiamUserNameConfirmationText(confirmMsg);
    }

    public onChangeCiamUserName(ciamUserName: string) {
        this.props.action.ui.verifyCiamUserName({
            masterCrud: this.props.dataStore.crud.data,
            uiData: { text: this.props.dataStore.ui.loginName, value: ciamUserName }
        });
    }

    public render() {

        return (
            <div>
                <SelectActions>
                        <Inputs>

                            <Input
                            domID="loginName-input"
                            label="*User Name:"
                            autoComplete="new-password"
                            className="text-input"
                            maxLength={12}
                            size="small"
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.props.action.ui.updateLoginName(e.target.value)}
                            initialValue={this.props.dataStore.ui.loginName}
                            hasError={this.props.dataStore.ui.loginName_userError && this.props.dataStore.ui.loginName_userError !== ""}
                            errorMessage={this.props.dataStore.ui.loginName_userError}
                        />
                        <Input
                            domID="email-input"
                            label="*User Email:"
                            autoComplete="new-password"
                            className="text-input"
                            maxLength={100}
                            size="small"
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.onChangeCiamUserName(e.target.value)}
                            initialValue={this.props.dataStore.ui.ciamUserName == "" ? this.props.dataStore.ui.eMail : this.props.dataStore.ui.ciamUserName}
                            hasError={this.props.dataStore.ui.ciamUserName_userError && this.props.dataStore.ui.ciamUserName_userError !== ""}
                            errorMessage={this.props.dataStore.ui.ciamUserName_userError}
                        />
                        <Button
                            domID="ciamUserName-button"
                            name="UPDATE CIAM EMAIL"
                            size="small"
                            buttonType="standard"
                            disabled={!this.props.dataStore.ui.ciamUserNameButtonEnabled || !this.props.dataStore.ui.retrievedCiamUserRecord}
                            type="button"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                this.onClickCiamUserNameButton(e)
                            }}
                        />


                        <CustomContentWrapper>
                            <ContentRowWrapper style={{ maxHeight: '35px' }}>
                                    <DataItem className="formItem firstName"
                                        label="First Name:&nbsp;&nbsp;"
                                />
                            </ContentRowWrapper>
                            <ContentRowWrapper style={{ minHeight: '20px' }}>
                                    <DataItem className="formValue firstName"
                                        label={this.props.dataStore.ui.ciamFirstName == "" ? this.props.dataStore.ui.firstName : this.props.dataStore.ui.ciamFirstName}
                                    />
                             </ContentRowWrapper>
                            <ContentRowWrapper style={{ maxHeight: '20px' }}>
                                    <DataItem className="formItem"
                                        label="Last Name:&nbsp;&nbsp;"
                                />
                            </ContentRowWrapper>
                            <ContentRowWrapper style={{ minHeight: '20px' }}>
                                    <DataItem className="formValue"
                                        label={this.props.dataStore.ui.ciamLastName == "" ? this.props.dataStore.ui.lastName : this.props.dataStore.ui.ciamLastName}
                                    />
                            </ContentRowWrapper>
                            <ContentRowWrapper style={{ maxHeight: '20px' }}>
                                    <DataItem className="formItem"
                                        label="MDM ID:&nbsp;&nbsp;"
                                />
                            </ContentRowWrapper>
                            <ContentRowWrapper style={{ minHeight: '20px' }}>
                                    <DataItem className="formValue"
                                        label={this.props.dataStore.ui.ciamMdmId}
                                    />
                            </ContentRowWrapper>
                                    
                            </CustomContentWrapper>
                        </Inputs>
                        </SelectActions>
            </div>

        );
    }
};


export default CiamAccount;
