import { Reducer, Dispatch, AnyAction } from 'redux';
import {cloneDeep, flatMap, groupBy, intersectionWith, keyBy, partition, uniqBy, uniqueId, Dictionary, update} from 'lodash';
import { ISelectableItem } from '@components/common/SelectAllOrNoneComponent';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"
import CrudTypes from '@commonResources/CrudTypes';
import { ComplexApiAction, IThunkApiAction } from '@scripts/util/ThunkHelpers';
import {actionCreators as masterCrudCreators} from '../MasterCrud';
import { History } from 'history';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.


export interface ICheckedCodeItem extends ISelectableItem {
    wasSelected: boolean;
    isDisabled?: boolean;
    cpid?: string;
    isHighlighted?: boolean;
    dbId?: string;
};

export interface IClaimEventConfiguration {
    id: string;
    // temporary name we use until config is added
    displayName?: string;
    claimNotes: ICheckedCodeItem[];
    description: string,
    originalDescription?: string;
    facilities: ICheckedCodeItem[];
    payerSpecificCodes: ICheckedCodeItem[];
    visiblePayerSpecificCodes: ICheckedCodeItem[];
    specificPayers: ICheckedCodeItem[];
    statusHistories: {
        LocalCodes: ICheckedCodeItem[];
        ClearinghouseCodes: ICheckedCodeItem[];
        PayerCodes: ICheckedCodeItem[];
        Status277Codes: ICheckedCodeItem[];
    };
}


export interface IClaimEventUIState {
    claimEventConfigurations: IClaimEventConfiguration[];
    draftClaimEventConfiguration: IClaimEventConfiguration;
    isBusy: boolean;
    isDirty: boolean;
    originalClaimEventConfigurationIds: string[];
    selectedClaimConfiguration: IClaimEventConfiguration;
    tabIndex: number;
    searchTerm: string;
    selectedConfigAction: 'ADD' | 'REMOVE' | 'NONE';
    crudSaveError?: string;
}

function getEmptyStatusHistories() {
    return {
        LocalCodes: [],
        ClearinghouseCodes: [],
        PayerCodes: [],
        Status277Codes: [],
    };
}

export const defaultSelectionData: IClaimEventConfiguration = {
    id: '',
    claimNotes: [],
    description: '-ADD A NEW CONFIG-',
    facilities: [],
    payerSpecificCodes: [],
    specificPayers: [],
    visiblePayerSpecificCodes: [],
    statusHistories: getEmptyStatusHistories(),
};

// crud stuff
type BorkedCrudSave = { SystemError?: { '@Message'?: string } };


// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export interface IStatusHistoryItemChangeRequest {section: keyof IClaimEventConfiguration['statusHistories'], id: string};
export interface IStatusHistoryItemsChangeRequest {section: keyof IClaimEventConfiguration['statusHistories'], toggleValue: boolean};

export interface IChangeClaimEventNotificationOptionByIndex extends ICrudActionData<MCClaimEventNotification, number>{ }
export interface IChangeClaimEventNotificationByReference extends ICrudActionData<MCClaimEventNotification, IClaimEventConfiguration> {};
export interface IToggleSelectedItems extends ICrudActionData<MCClaimEventNotification, boolean> {};
export interface IToggleSelectedItem extends ICrudActionData<MCClaimEventNotification, string> {};
export interface IToggleStatusHistorySectionItem extends ICrudActionData<MCClaimEventNotification, IStatusHistoryItemChangeRequest> {};
export interface IToggleStatusHistorySectionItems extends ICrudActionData<MCClaimEventNotification, IStatusHistoryItemsChangeRequest> {};
export interface IToggleFacilityItems extends IToggleSelectedItems {}
export interface IModifyClaimEventType extends ICrudActionData<any, any> { }

export const actionCreators = {
    initializeClaimEventNotif: (rawApiReturn: MCClaimEventNotification) => createDataAction('INIT_CLAIM_EVENT_NOTIF', rawApiReturn),
    selectClaimEventNotificationOption: (selectedConfigInfo: IChangeClaimEventNotificationOptionByIndex) => createDataAction('SELECT_CLAIM_EVENT_NOTIFICATION_OPTION', selectedConfigInfo),
    updateClaimEventNotificationDisplayName: (displayName: string) => createDataAction('UPDATE_CLAIM_EVENT_NOTIFICATION_OPTION_DISPLAYNAME', displayName.toUpperCase()),
    addClaimEventNotificationOption: (addedOptionData: IChangeClaimEventNotificationByReference) => createDataAction('ADD_CLAIM_EVENT_NOTIFICATION_OPTION', addedOptionData),
    removeClaimEventNotificationOption: (removedOptionData: IChangeClaimEventNotificationOptionByIndex) => createDataAction('REMOVE_CLAIM_EVENT_NOTIFICATION_OPTION', removedOptionData),
    toggleStatusHistoryItem: (itemData:IToggleStatusHistorySectionItem) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_STATUS_HISTORY_ITEM', itemData),
    toggleStatusHistoryItems: (itemsData:IToggleStatusHistorySectionItems) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_STATUS_HISTORY_ITEMS', itemsData),
    toggleClaimNotesItem: (itemData:IToggleSelectedItem) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_CLAIM_NOTES_ITEM', itemData),
    toggleClaimNotesItems: (itemsData:IToggleSelectedItems) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_CLAIM_NOTES_ITEMS', itemsData),
    toggleFacilitiesItem: (itemData:string) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_FACILITIES_ITEM', itemData),
    toggleFacilitiesItems: (itemsData:boolean) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_FACILITIES_ITEMS', itemsData),
    togglePayerSpecificCodesItem: (itemData:IToggleSelectedItem) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_PAYER_SPECIFIC_CODES_ITEM', itemData),
    togglePayerSpecificCodesItems: (itemsData:IToggleSelectedItems) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_PAYER_SPECIFIC_CODES_ITEMS', itemsData),
    toggleSpecificPayersItem: (itemData:IToggleSelectedItem) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_SPECIFIC_PAYERS_ITEM', itemData),
    toggleSpecificPayersItems: (itemsData:IToggleSelectedItems) => createDataAction('TOGGLE_CLAIM_EVENT_NOTIFICATION_SPECIFIC_PAYERS_ITEMS', itemsData),
    selectTab: (tabIndex: 0 | 1) => createDataAction('SELECT_CLAIM_EVENT_NOTIFICATION_UI_TAB', tabIndex),
    setSelectedConfigAction: (action: 'ADD' | 'REMOVE' | 'NONE') => createDataAction('SET_CLAIM_EVENT_NOTIFICATION_SELECTED_CONFIG_ACTION', action),
    setSearchTerm: (term: string) => createDataAction('SET_CLAIM_EVENT_NOTIFICATION_SEARCH_TERM', term),
    doSearch: () => createDataAction('DO_CLAIM_EVENT_NOTIFICATION_SEARCH', null),
    updateClaimEventNotificationConfigs: (state: IClaimEventUIState, history: History<any>) => {
        const {data, isDeleteRisk} = createCrudSaveJsonFromState(state);
        const payload = {
            crudId: CrudTypes.mctiClaimEventNotification,
            data
        };
        return ComplexApiAction.fromAction(masterCrudCreators.update(payload))
            .changeType('SHOW_SAVING_CLAIM_EVENT_NOTIFICATION')
            /*.addThunk((dispatch: Dispatch<AnyAction>, apiResult: IThunkApiAction<'SHOW_SAVING_CLAIM_EVENT_NOTIFICATION', MCClaimEventNotification_CrudUpdateBody, unknown>) => {
                console.log(apiResult);
                if (!!(apiResult?.responseData as BorkedCrudSave).SystemError) {
                    const errType = isDeleteRisk ? 'deleteRisk': 'unknown';
                    dispatch(actionCreators.setCrudSaveError(errType));
                    return {success: false};
                }
                return {success: true};
            })*/
            .thenRedirect('/LandingPage', history).finish();
    },
    setCrudSaveError: (errorType:string | undefined) => createDataAction('SET_CLAIM_EVENT_NOTIFICATION_CRUD_SAVE_ERROR', errorType),
};

// From ActionTypes, ActionCreators is represented as an ActionCreatorsMapObject.
export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: IClaimEventUIState = {
    claimEventConfigurations: [],
    draftClaimEventConfiguration: defaultSelectionData,
    isDirty: false,
    isBusy: true,
    originalClaimEventConfigurationIds: [],
    selectedClaimConfiguration: {...defaultSelectionData},
    tabIndex: 0,
    searchTerm: '',
    selectedConfigAction: 'ADD',
};

export type ItemDescriptionFormater = (item: MCCenItemDescription) => string;

const defaultDescriptionFormatter: ItemDescriptionFormater = (item: MCCenItemDescription): string => {
    return item['@Description'];
};

export function idDelimDescription(id: string, delimiter: string, description:string): string {
    return `${id}${delimiter}${description}`;
}
export function createIdDelimiterDescriptionFormatter(delimiter: string): ItemDescriptionFormater {
    return (item) => idDelimDescription(item['@ID'], delimiter, item['@Description']);
}

export const facilitiesFormatter = createIdDelimiterDescriptionFormatter(' - ');
export const statusHistoriesFormatter = createIdDelimiterDescriptionFormatter(': ');
export const payerSpecificCodeFormatter = (item:MCCenItemDescription) =>`${item['@Code']} (${item['@CPID']}): ${item['@Description']}`;

export function createSelectListItems(descriptions: MCCenItemDescription[], selections: MCCenItemSelected[], descriptionFormatter:ItemDescriptionFormater = defaultDescriptionFormatter):ICheckedCodeItem[] {
    const selectionsByCode = keyBy(selections, '@Code');
    return descriptions.map(description => {
        const matchingSelection = selectionsByCode[description['@ID']];
        const selected = !!matchingSelection;
        const tempCpidInfo = {cpid: description['@CPID']};
        return {
            id: description['@ID'], 
            dbId: matchingSelection?.['@ID'],
            description: descriptionFormatter(description), 
            isSelected: selected,
            isDisabled: false,
            wasSelected: selected,
            ...tempCpidInfo,
        };
    });
}

export function createPayerItems(descriptions: MCCenSpecificPayerDescription[], selections: MCSpecificPayerSelection[]): ICheckedCodeItem[] {
    const selectionsByCpid = keyBy(selections, '@CPID');
    return descriptions.map(description => {
        const matchingSelection = selectionsByCpid[description['@CPID']];
        const selected = !!matchingSelection;
        return {
            id: description['@CPID'], 
            dbId: matchingSelection?.['@ID'],
            description: `${description['@CPID']} ${description['@Name']}`, 
            isSelected: selected,
            wasSelected: selected,
        };
    });
}

export function getVisiblePayerSpecificCodes(payers: ICheckedCodeItem[], codes: ICheckedCodeItem[]): ICheckedCodeItem[] {
    const payerCodes = payers.filter(payer => payer.isSelected).map(payer => payer.id);//CPID

    return codes.filter(option => payerCodes.includes(option.cpid || '-999')); //-999 should never be in there
}


export const dummyConfigCrud = {
    "@ID": "",
    "@Description": "-ADD A NEW CONFIG-",
    "StatusHistories": {
        "StatusHistory": [],
    },
    "ClaimNotes": {
        "ClaimNote": [],
    },
    "Facilities": {
        "Facility": [],
    },
    "SpecificPayers": {
        "Payer": [],
    },
    "PayerSpecificCodes": {
        "Code": [],
    }

};

export const manualClaimNote = { '@ID': 'USER NOTE', '@Description': 'Manually entered user notes' }


// unfortunately lodash makes assumptions about how you'll use groupBy, so we can't type this as 
// IClaimEventConfiguration['statusHistories'], which would prevent typos
export function groupStatusCodesByInterval(codes: ICheckedCodeItem[]): Dictionary<ICheckedCodeItem[]> {
    const sanitisedCodes = codes.filter(code => code['id'] !== '168');
    return groupBy(sanitisedCodes, function (code) {
        const codeInt = Number(code['id']); 
        if (isNaN(codeInt)){
            return 'Status277Codes';
        }
        if (codeInt > 0 && codeInt < 1000 && codeInt !== 168) {
            return 'LocalCodes'
        }
        if (codeInt >= 1000 && codeInt < 5000) {
            return 'ClearinghouseCodes'
        }
        if (codeInt >= 5000 && codeInt < 9999) {
            return 'PayerCodes'
        } 
    });
}

export function mapCrudToUI(crud: MCClaimEventNotification): IClaimEventUIState {
    const cmi = crud.ClaimEventNotificationMaintenanceInfo;
    const { StatusHistories, ClaimNotes, Facilities, SpecificPayers, PayerSpecificCodes, ClaimEventNotifications } = cmi;
    const originalClaimEventConfigurationIds = ClaimEventNotifications.ClaimEventNotification.map(config => config['@ID']);

    const claimEventConfigurations = [{...dummyConfigCrud}, ...ClaimEventNotifications.ClaimEventNotification].map((CrudNotification, i) => {
        const facilities = createSelectListItems(Facilities.Facility, CrudNotification.Facilities.Facility, facilitiesFormatter);
        const claimNotes = createSelectListItems([manualClaimNote, ...ClaimNotes.ClaimNote], CrudNotification.ClaimNotes.ClaimNote, statusHistoriesFormatter);
        const newManualDesc = claimNotes[0].description.replace(/USER NOTE: /g, '');
        claimNotes[0].description = newManualDesc;
        const payerSpecificCodes = createSelectListItems(PayerSpecificCodes.Code, CrudNotification.PayerSpecificCodes.Code, payerSpecificCodeFormatter);
        const specificPayers = createPayerItems(SpecificPayers.Payer, CrudNotification.SpecificPayers.Payer);
        const visiblePayerSpecificCodes = getVisiblePayerSpecificCodes(specificPayers, payerSpecificCodes);
        const tempItems = createSelectListItems(StatusHistories.StatusHistory, CrudNotification.StatusHistories.StatusHistory, statusHistoriesFormatter);
        const groupedItems = groupStatusCodesByInterval(tempItems);       
        const statusHistories = {
            LocalCodes: [...groupedItems.LocalCodes],
            ClearinghouseCodes: [...groupedItems.ClearinghouseCodes],
            PayerCodes: [...groupedItems.PayerCodes],
            Status277Codes: [...groupedItems.Status277Codes],
        };
        const configName = CrudNotification['@Description'];
        const originalNameInfo = {
            originalDescription: (i> 0) ? configName: undefined
        }
        return {
            id: CrudNotification['@ID'] || uniqueId('new-'),
            description: configName,
            displayName: i> 0 ? configName: '',
            ...originalNameInfo,
            claimNotes,
            facilities,
            payerSpecificCodes,
            specificPayers,
            statusHistories,
            visiblePayerSpecificCodes,
        };
    });
    const facilities = claimEventConfigurations.map(config => (config.facilities));
    const mappedFacilities = facilities.map((facilityList, index) => {
        const otherFacilityLists = facilities.filter((facility) => facilities.indexOf(facility) !== index)
        return enableFacilityItems(facilityList, otherFacilityLists, !index);
    });
    const CEConfigsWithUpdatedFacilities = claimEventConfigurations.map((config, index) => {
        const newConfig = Object.assign({}, config);
        newConfig.facilities = !!index ? mappedFacilities[index] : mappedFacilities[index].map(facility => ({...facility, isSelected: !facility.isDisabled}));
        return newConfig
    });
    const longEnough = claimEventConfigurations.length > 1;
    const selectedClaimConfiguration = {...CEConfigsWithUpdatedFacilities[longEnough ? 1:0]};
    return {...defaultState, 
                selectedClaimConfiguration, 
                claimEventConfigurations: CEConfigsWithUpdatedFacilities,  
                originalClaimEventConfigurationIds,
                draftClaimEventConfiguration: cloneDeep(claimEventConfigurations[0]), // deliberately not including disabled info
                isBusy: false,
                selectedConfigAction: longEnough? 'REMOVE': 'ADD',
            };
}


export function enableFacilityItems(facilityList: ICheckedCodeItem[], otherFacilityLists: ICheckedCodeItem[][], selectIfEnabled:boolean): ICheckedCodeItem[] {
    const flattenedFacilities = otherFacilityLists.flat()
    const groupedFacilities = groupBy(flattenedFacilities, (facility) => {
        return facility.id;
    })
    const mappedFacilities = facilityList.map((facility: ICheckedCodeItem) => {
        if (!groupedFacilities[facility['id']]) {
            return {...facility, isDisabled: false}
        }
        const isDisabled =groupedFacilities[facility['id']].some(facility => facility.isSelected);
        return {...facility, isDisabled, isSelected: selectIfEnabled? !isDisabled: facility.isSelected};
    })
    return mappedFacilities;
}



export function mapEnabledFacilitiesToConfigs(configs : IClaimEventConfiguration[]): IClaimEventConfiguration[] {
    const facilities = configs.map(config => (config.facilities))
    const mappedFacilities = facilities.map((facilityList, index) => {
        // facilities are checked if they are enabled in "ADD A CONFIG", but this does not affect real configs
        const otherFacilityLists = facilities.filter((facility, i) => i > 0 && facilities.indexOf(facility) !== index)
        return enableFacilityItems(facilityList, otherFacilityLists, !index);
    })
    const CEConfigsWithUpdatedFacilities = configs.map((config, index) => {
        return {...config, facilities: mappedFacilities[index]};
    })
    return CEConfigsWithUpdatedFacilities
}

/*  The selected configuration and the one in the list are actually
    two independent objects (usually in these CRUD pages people need 
    to click update to change the "master" object, so this follows that
    pattern even though here that's not necessary). Synchronize the "master"
    with the selected.
*/
export function updateSelectedOptionInList(state: IClaimEventUIState, selectedClaimConfiguration: IClaimEventConfiguration): IClaimEventUIState {
    return { ...state, isDirty: true, claimEventConfigurations: state.claimEventConfigurations.map(option => option.id === selectedClaimConfiguration.id ? {...selectedClaimConfiguration}: option ), selectedClaimConfiguration};
}

function highlightMatchingItems(items: ICheckedCodeItem[], searchTerm: string): ICheckedCodeItem[] {
    searchTerm = searchTerm.toUpperCase();
    return items.map(item => {
        return {...item, isHighlighted: searchTerm? item.description.toUpperCase().includes(searchTerm): false}
    });
}

function isNewId(id: string): boolean {
    return id.search(/new-[0-9+]/) > -1;
}

function createNewListItem(id: string): MCClaimeEventNotification_AddSelection {
    return {"@ID":'#', "@Code": id};
}

function createDeletedListItem(item: ICheckedCodeItem): MCClaimEventNotification_DeleteSelection {
    return {"@ID": item.dbId?? '', "@Code": '_deleted', "@DeletedCode": item.id, "@Delete": 'true'};
}

//new vs edited are down to how TS sees all this
function newSelectionsToCrud(items: ICheckedCodeItem[]): MCClaimeEventNotification_AddSelection[] {
    return items.filter(item => item.isSelected).map(item => createNewListItem(item.id));
}

function editedSelectionsToCrud(items: ICheckedCodeItem[]): MCClaimEventNotification_EditSelection[] {
    // selected items are in idx 0, unselected in idx 1
    const itemsBySelected = partition(items, 'isSelected');
    const newSelections = itemsBySelected[0].map(item => createNewListItem(item.id));
    const removedSelections = itemsBySelected[1].map(createDeletedListItem);
    const result: MCClaimEventNotification_EditSelection[] = [...newSelections, ...removedSelections];
    return result;
}

function createNewSpecificPayerItem(item: ICheckedCodeItem): MCClaimEventNotifaction_AddSpecificPayer {
    return {"@ID":'#',"@CPID": item.id};
}

function createDeletedSpecificPayerItem(item: ICheckedCodeItem): MCClaimEventNotification_DeleteSpecificPayer {
    return {"@ID": item.dbId ?? '', "@CPID": '_deleted', '@DeletedCPID': item.id, "@Delete": 'true' };
}

function newSelectedPayersToCrud(items: ICheckedCodeItem[]): MCClaimEventNotifaction_AddSpecificPayer[] {
    // multiple payers have the same cpid, filter out dupes
    return uniqBy(items.filter(item => item.isSelected).map(createNewSpecificPayerItem), '@DeletedCPID');
}

function editedSpecifigPayersToCrud(items: ICheckedCodeItem[]): MCClaimEventNotification_EditPayerSelection[] {
    // selected items are in idx 0, unselected in idx 1
    const itemsBySelected = partition(items, 'isSelected');
    const newSelections = itemsBySelected[0].map(createNewSpecificPayerItem);
    const removedSelections = itemsBySelected[1].map(createDeletedSpecificPayerItem);
    const result = uniqBy([...newSelections, ...removedSelections], '@DeletedCPID');
    return result;
}

function selectedHasChanged(item: ICheckedCodeItem): boolean {
    return item.isSelected !== item.wasSelected;
}

export function createCrudSaveJsonFromState(state: IClaimEventUIState): {data: MCClaimEventNotification_CrudUpdateBody, isDeleteRisk:boolean} {
    // first (default) config does not get saved, so strip it out (don't remove defaultConfig destructured const)
    const [defaultConfig, ...configs] = state.claimEventConfigurations;
    const newAndUpdatedConfigs = configs.reduce<MCClaimEventNotification_ConfigCrudTypes[]>((configCrud, currentConfig): MCClaimEventNotification_ConfigCrudTypes[] => {
        if (isNewId(currentConfig.id)) {
            const StatusHistory = newSelectionsToCrud(flatMap(currentConfig.statusHistories));
            const ClaimNote = newSelectionsToCrud(currentConfig.claimNotes);
            const Payer = newSelectedPayersToCrud(currentConfig.specificPayers);
            const Code = newSelectionsToCrud(currentConfig.payerSpecificCodes);
            const newConfig: MCClaimEventNotification_AddConfig = {
                "@ID": '#',
                "@Description": currentConfig.description,
                Facilities: { Facility: newSelectionsToCrud(currentConfig.facilities) },
                StatusHistories: StatusHistory.length > 0 ? { StatusHistory } : null,
                ClaimNotes: ClaimNote.length > 0 ? { ClaimNote } : null,
                SpecificPayers: Payer.length > 0 ? { Payer } : null,
                PayerSpecificCodes: Code.length > 0 ? { Code } : null,
            }
            return [...configCrud, newConfig];
        } else {
            const changedStatusHistories = flatMap(currentConfig.statusHistories).filter(selectedHasChanged);
            const changedClaimNotes = currentConfig.claimNotes.filter(selectedHasChanged);
            const changedSpecificPayers = currentConfig.specificPayers.filter(selectedHasChanged);
            const changedPayerSpecificCodes = currentConfig.payerSpecificCodes.filter(selectedHasChanged);
            const changedFacilities = currentConfig.facilities.filter(selectedHasChanged);
            const selectionsChanged = changedStatusHistories.length + changedClaimNotes.length + changedSpecificPayers.length + changedPayerSpecificCodes.length + changedFacilities.length > 0;
            const descriptionChanged = currentConfig.originalDescription !== currentConfig.description;
            if (selectionsChanged || descriptionChanged) {
                const Facility = editedSelectionsToCrud(changedFacilities);
                const StatusHistory = editedSelectionsToCrud(changedStatusHistories);
                const ClaimNote = editedSelectionsToCrud(changedClaimNotes);
                const Payer = editedSpecifigPayersToCrud(changedSpecificPayers);
                const Code = editedSelectionsToCrud(changedPayerSpecificCodes);
                const updatedConfig: MCClaimEventNotification_EditConfig = {
                    '@ID': currentConfig.id,
                    '@Description': currentConfig.description,
                    Facilities: Facility.length > 0 ? {Facility} : null,
                    StatusHistories: StatusHistory.length > 0? {StatusHistory}: null ,
                    ClaimNotes: ClaimNote.length > 0? {ClaimNote}: null,
                    SpecificPayers: Payer.length > 0? {Payer}: null,
                    PayerSpecificCodes: Code.length > 0? {Code}: null,
                };
                return [...configCrud, updatedConfig];
            }
        }
        return configCrud;
    }, []);
    const deletedConfigs = state.originalClaimEventConfigurationIds.filter(configId => !configs.find(config => config.id === configId)).map(id => ({'@ID': id, '@Delete': 'true' as 'true'}));
    // If you try to delete a config that has payers, that violates the FK constraint on the table and causes an error
    // unfortunately we can't see at this point if that config had saved payers so we just check for deleting configs
    const isDeleteRisk = deletedConfigs.length > 0;
    return {data: {
        ClaimEventNotificationMaintenanceInfo: {
            ClaimEventNotifications: {
                ClaimEventNotification: [...newAndUpdatedConfigs, ...deletedConfigs],
            }
        }
    }, isDeleteRisk};
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.
export const reducer: Reducer<IClaimEventUIState, KnownActions> = (state: IClaimEventUIState | undefined, action: KnownActions) => {
    if (state != undefined) {
        switch (action.type) {
            case 'INIT_CLAIM_EVENT_NOTIF': {
                return {...mapCrudToUI(action.data)};
            }
            case 'SELECT_CLAIM_EVENT_NOTIFICATION_OPTION': {
                const claimEventConfigurations = mapEnabledFacilitiesToConfigs(state.claimEventConfigurations);
                if (action.data.uiData < state.claimEventConfigurations.length) {
                    return { ...state, claimEventConfigurations, selectedConfigAction: action.data.uiData===0 ? 'ADD': 'REMOVE', selectedClaimConfiguration: {...claimEventConfigurations[action.data.uiData]}};
                }
                break;
            }
            case 'SET_CLAIM_EVENT_NOTIFICATION_SELECTED_CONFIG_ACTION': {
                return {...state, selectedConfigAction: action.data};
            }
            case 'UPDATE_CLAIM_EVENT_NOTIFICATION_OPTION_DISPLAYNAME': {
                // only immediately change the description of existing configs, new ones need to wait to be added
                const description = state.selectedClaimConfiguration.id.search(/new-[0-9+]/) === -1 ? action.data: state.selectedClaimConfiguration.description;
                const selectedClaimNoteOption = {...state.selectedClaimConfiguration, displayName: action.data, description};
                return updateSelectedOptionInList(state, selectedClaimNoteOption);
            }
            case 'ADD_CLAIM_EVENT_NOTIFICATION_OPTION': {//bracket allows us to directly define claimEventConfigurations per block
                const configToAdd = {...action.data.uiData, description: action.data.uiData.displayName || ''};
                const newDefaultConfig = {...state.draftClaimEventConfiguration, id: uniqueId('new-')};
                const draftClaimConfigurations = mapEnabledFacilitiesToConfigs([...state.claimEventConfigurations.map((option, i) => i === 0 ? newDefaultConfig : option), configToAdd]);
                const claimEventConfigurations = mapEnabledFacilitiesToConfigs(draftClaimConfigurations);
                const selectedClaimConfiguration = { ...claimEventConfigurations[claimEventConfigurations.length-1]};
                return {...state, claimEventConfigurations, selectedClaimConfiguration, isDirty: true, selectedConfigAction: 'REMOVE'};
            }
            case 'REMOVE_CLAIM_EVENT_NOTIFICATION_OPTION': {
                const claimEventConfigurations = mapEnabledFacilitiesToConfigs(state.claimEventConfigurations.filter((option, i) => i !== action.data.uiData));
                const selectedClaimNoteOption = { ...claimEventConfigurations[0], facilities: claimEventConfigurations[0].facilities.map(facility => ({...facility, isSelected: ! facility.isDisabled}))};
                return updateSelectedOptionInList({...state, claimEventConfigurations, selectedConfigAction: 'ADD'}, selectedClaimNoteOption);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_STATUS_HISTORY_ITEM': {
                /*  StatusHistories is divided into sections for display. We replace the entire 
                    statusHistories prop with a new object where we've replaced just the
                    prop that matches the section specified in the action with a new 
                    Array. That Array is composed of all the original items, except
                    when we come to the one whose id matches the id of our action, and then 
                    we create a new item with all the props of the original item, but the isSelected is reversed.
                 */
                const selectedClaimNoteOption = {
                    ...state.selectedClaimConfiguration, 
                    statusHistories: {
                        ...state.selectedClaimConfiguration.statusHistories,
                        [action.data.uiData.section]: state.selectedClaimConfiguration.statusHistories[action.data.uiData.section].map(option => {
                                if (option.id !== action.data.uiData.id) return option;
                                return {...option, isSelected: !option.isSelected};
                            })
                        }
                };
                /*  The selected configuration and the one in the list are actually
                    two independent objects (usually in these CRUD pages people need 
                    to click update to change the "master" object, so this follows that
                    pattern even though here that's not necessary). Synchronize the "master"
                    with the selected.
                */
                return updateSelectedOptionInList(state, selectedClaimNoteOption);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_STATUS_HISTORY_ITEMS': {
                /*  StatusHistories is divided into sections for display. We replace the entire 
                    statusHistories prop with a new object where we've replaced just the
                    prop that matches the section specified in the action with a new 
                    Array. That Array is composed of all the original items, except
                    we set isSelected on all to the incoming toggle value.
                 */
                const selectedClaimNoteOption = {
                    ...state.selectedClaimConfiguration, 
                    statusHistories: {
                        ...state.selectedClaimConfiguration.statusHistories,
                            [action.data.uiData.section]: state.selectedClaimConfiguration.statusHistories[action.data.uiData.section].map(option => {
                            return {...option, isSelected: action.data.uiData.toggleValue};
                        })
                    }
                };
                return updateSelectedOptionInList(state, selectedClaimNoteOption);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_CLAIM_NOTES_ITEM': {
                // create a new array containing all the original elements except for
                // the one that matches the action.data, and we replace that one with a new one 
                // where isSelected is the opposite of what it was before
                const selectedClaimNoteOption = {
                    ...state.selectedClaimConfiguration,
                    claimNotes: state.selectedClaimConfiguration.claimNotes.map(option => {
                        if (option.id !== action.data.uiData) return option;
                        return { ...option, isSelected: !option.isSelected };
                    })
                };
                return updateSelectedOptionInList(state, selectedClaimNoteOption);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_CLAIM_NOTES_ITEMS': {
                // create a new array containing new elements with the same properties as the original elements
                // except we set isSelected to the incoming toggle value
                const selectedClaimNoteOption = {
                    ...state.selectedClaimConfiguration,
                    claimNotes: state.selectedClaimConfiguration.claimNotes.map(option => {
                        return { ...option, isSelected: action.data.uiData };
                    })
                };
                return updateSelectedOptionInList(state, selectedClaimNoteOption);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_FACILITIES_ITEM': {
                // create a new array containing new elements with the same properties as the original elements
                // except we set isSelected to the incoming toggle value
                const selectedClaimNoteOption = {
                    ...state.selectedClaimConfiguration,
                    facilities: state.selectedClaimConfiguration.facilities.map(option => {
                        if (option.id !== action.data) return option;
                        return { ...option, isSelected: !option.isSelected };
                    })
                };
                return updateSelectedOptionInList(state, selectedClaimNoteOption);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_FACILITIES_ITEMS': {
                // set all facilities on selected config to selected/unselected based on incoming action
                // note that this creates a new array with brand new items
                const selectedClaimNoteOption = {
                    ...state.selectedClaimConfiguration,
                    facilities: state.selectedClaimConfiguration.facilities.map(option => {
                        return { ...option, isSelected: !option.isDisabled? action.data: false};
                    })
                };
                return updateSelectedOptionInList(state, selectedClaimNoteOption);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_PAYER_SPECIFIC_CODES_ITEM': {
                const visiblePayerSpecificCodes = state.selectedClaimConfiguration.visiblePayerSpecificCodes.map(option => {
                        if (option.id !== action.data.uiData) return option;
                        option = { ...option, isSelected: !option.isSelected };
                        return option;
                    }
                );
                const visiblePayersById = keyBy(visiblePayerSpecificCodes, 'id');

                const selectedClaimConfiguration = {
                    ...state.selectedClaimConfiguration,
                    visiblePayerSpecificCodes,
                    payerSpecificCodes: state.selectedClaimConfiguration.payerSpecificCodes.map(option => visiblePayersById[option.id]? visiblePayersById[option.id] : option),
                };
                return updateSelectedOptionInList(state, selectedClaimConfiguration);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_PAYER_SPECIFIC_CODES_ITEMS': {
                const visiblePayerSpecificCodes = state.selectedClaimConfiguration.visiblePayerSpecificCodes.map(option => ({...option, isSelected: action.data.uiData}));
                const visiblePayersById = keyBy(visiblePayerSpecificCodes, 'id');

                const selectedClaimConfiguration = {
                    ...state.selectedClaimConfiguration,
                    payerSpecificCodes: state.selectedClaimConfiguration.payerSpecificCodes.map(option => {
                        return { ...option, isSelected: !!visiblePayersById[option.id] ?action.data.uiData: false };
                    }),
                    visiblePayerSpecificCodes,
                };
                return updateSelectedOptionInList(state, selectedClaimConfiguration);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_SPECIFIC_PAYERS_ITEM': {
                const specificPayers = state.selectedClaimConfiguration.specificPayers.map(option => {
                    if (option.id !== action.data.uiData) return option;
                    return { ...option, isSelected: !option.isSelected };
                });
                const visiblePayerSpecificCodes = getVisiblePayerSpecificCodes(specificPayers, state.selectedClaimConfiguration.payerSpecificCodes);
                const payerSpecificCodes = state.selectedClaimConfiguration.payerSpecificCodes.map(option => ({...option, isSelected: visiblePayerSpecificCodes.includes(option)? option.isSelected: false}));
                const selectedClaimNoteOption = {
                    ...state.selectedClaimConfiguration,
                    specificPayers,
                    visiblePayerSpecificCodes,
                    payerSpecificCodes,
                };
                return updateSelectedOptionInList(state, selectedClaimNoteOption);
            }
            case 'TOGGLE_CLAIM_EVENT_NOTIFICATION_SPECIFIC_PAYERS_ITEMS': {
                const specificPayers= state.selectedClaimConfiguration.specificPayers.map(option => {
                    return { ...option, isSelected: action.data.uiData };
                });
                let payerSpecificCodes = state.selectedClaimConfiguration.payerSpecificCodes
                if (!action.data.uiData) {
                    payerSpecificCodes = payerSpecificCodes.map(option => ({...option, isSelected: false}));
                }
                const visiblePayerSpecificCodes = getVisiblePayerSpecificCodes(specificPayers, payerSpecificCodes);

                const selectedClaimNoteOption = {
                    ...state.selectedClaimConfiguration,
                    specificPayers,
                    payerSpecificCodes,
                    visiblePayerSpecificCodes,
                };
                return updateSelectedOptionInList(state, selectedClaimNoteOption);
            }
            case 'SELECT_CLAIM_EVENT_NOTIFICATION_UI_TAB':
                return {...state, tabIndex: action.data};
            case 'SET_CLAIM_EVENT_NOTIFICATION_SEARCH_TERM':
                return {...state, searchTerm: action.data};
            case 'DO_CLAIM_EVENT_NOTIFICATION_SEARCH': {
                const selectedClaimConfiguration = {...state.selectedClaimConfiguration};
                const searchTerm = state.searchTerm.toUpperCase();
                return {
                    ...state, 
                    searchTerm, 
                    selectedClaimConfiguration: {
                        ...selectedClaimConfiguration, 
                        specificPayers: highlightMatchingItems(selectedClaimConfiguration.specificPayers, searchTerm)
                    }
                }
            }
            case 'SHOW_SAVING_CLAIM_EVENT_NOTIFICATION': {
                switch (action.status.status) {
                    case 'REQUEST':
                        return {...state, isBusy: true};
                    case 'SUCCESS':
                        return {...state, isBusy: false};
                }
                break;
            }
            case 'SET_CLAIM_EVENT_NOTIFICATION_CRUD_SAVE_ERROR': {
                return {...state, crudSaveError: action.data};
            }
            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
