import { combineReducers, Reducer } from 'redux';
import { ICrudComponentState, ICrudComponentActionProps, createCrudComponentActions, createCrudComponentReducers } from '@scripts/util/CrudComponentHelpers';
import * as SubmitClaimUpdateFileUI from './ui/SubmitClaimUpdateFileUI';
import { CrudTypes } from '@commonResources/CrudTypes';

export type ISubmitClaimUpdateFileState = ICrudComponentState<MCClaimNoteType, SubmitClaimUpdateFileUI.ISubmitClaimUpdateFileUIState>;

export const actionCreators = createCrudComponentActions<SubmitClaimUpdateFileUI.ActionCreators>(SubmitClaimUpdateFileUI.actionCreators);
export type ISubmitClaimUpdateFileActions = typeof actionCreators;
export type ISubmitClaimUpdateFileActionProps = ICrudComponentActionProps<ISubmitClaimUpdateFileActions>

export const reducers = createCrudComponentReducers(CrudTypes.mctiClaimNotes, SubmitClaimUpdateFileUI.reducer);
export const reducer = combineReducers<ISubmitClaimUpdateFileState>(reducers);
