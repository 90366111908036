import { Reducer } from 'redux';
import { createDataAction, ActionTypes } from '@scripts/util/ActionHelpers';
import { ICrudActionData } from "@scripts/util/CrudComponentHelpers"


// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ISweepTimeUiState {
    selectedSweepTime: ISweepTimeUiData;
    initialValuesLoaded: boolean;
    sweepTimeDestination: string;
    sweepTimeEnabled: string;
    sweepTimeSun: string;
    sweepTimeMon: string;
    sweepTimeTue: string;
    sweepTimeWed: string;
    sweepTimeThu: string;
    sweepTimeFri: string;
    sweepTimeSat: string;
    sweepTimeFrom: string;
    sweepTimeThrough: string;
    sweepTimeInterval: string;
    sweepTimeDestinationError: string;

    removeScheduleConfirm?: string;
}

export interface ISweepTimeUiData {
    index: number;
    value: string;
    text: string;
};
interface ISweepTimeUiFieldUpdate {
    value: string;
}
export interface ISweepTimeDayOfWeekUiData {
    index: string;
    value: boolean;
};
interface ISweepTimeUiError {
    value: string;
    id?: string;
}
export const defaultSweepTime: ISweepTimeUiData = {
    index: 0,
    value: '-ADD A NEW TIME-',
    text: '-ADD A NEW TIME-',
};

const emptySchedule: MCST_Event = {
    '@ID': '',
    '@Mon': 'N',
    '@Tue': 'N',
    '@Wed': 'N',
    '@Thu': 'N',
    '@Fri': 'N',
    '@Sat': 'N',
    '@Sun': 'N',
    '@FromTime': '00:00:00',
    '@ThruTime': '00:00:00',
    '@CheckInterval': '0',
    '@Delete': '',
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export interface ISelectSweepTime extends ICrudActionData<MCSweepTimeType, ISweepTimeUiData>{ }

export interface IUpdateSweepTimeField extends ICrudActionData<MCSweepTimeType, ISweepTimeUiFieldUpdate> { }

export interface ISelectDayOfWeek extends ICrudActionData<MCAutoListType, ISweepTimeDayOfWeekUiData> { }

export interface IModifySweepTime extends ICrudActionData<MCSweepTimeType, MCST_Event> {
    delta: MCSweepTimeType;
}

export interface IError extends ICrudActionData<MCAutoListType, ISweepTimeUiError> { }


export const actionCreators = {
    selectSweepTime: (selectInfo: ISelectSweepTime) => createDataAction('SELECT_SWEEP_TIME', selectInfo),
    updateSweepTimeFrom: (sweepTimeFrom: IUpdateSweepTimeField) => createDataAction('UPDATE_SWEEP_TIME_FROM', sweepTimeFrom),
    updateSweepTimeThrough: (sweepTimeThrough: IUpdateSweepTimeField) => createDataAction('UPDATE_SWEEP_TIME_THROUGH', sweepTimeThrough),
    updateSweepTimeInterval: (updateSweepTimeInterval: IUpdateSweepTimeField) => createDataAction('UPDATE_SWEEP_TIME_INTERVAL', updateSweepTimeInterval),
    
    updateSweepTimeDestination: (destination: IUpdateSweepTimeField) => createDataAction('UPDATE_SWEEP_TIME_DESTINATION', destination),
    updateSweepTimeEnabled: (enabled: IUpdateSweepTimeField) => createDataAction('UPDATE_SWEEP_TIME_ENABLED', enabled),
    selectDayOfWeek: (fieldInfo: ISelectDayOfWeek) => createDataAction('UPDATE_DAYOFWEEK', fieldInfo),
    updateSweepTime: (data: IModifySweepTime) => createDataAction('UPDATE_SWEEP_TIME', data),
    removeSweepTime: (data: IModifySweepTime) => createDataAction('REMOVE_SWEEP_TIME', data),
    addSweepTime: (data: IModifySweepTime) => createDataAction('ADD_SWEEP_TIME', data),
    errorSweepTimeField: (fieldInfo: IError) => createDataAction('ERROR_SWEEP_TIME_FIELD', fieldInfo),
    errorSweepTimeRemoveScheduleConfirm: (fieldInfo: IUpdateSweepTimeField) => createDataAction('ERROR_SWEEP_TIME_REMOVE_SCHEDULE_CONFIRM', fieldInfo),
};

export type ActionCreators = typeof actionCreators;
export type KnownActions = ActionTypes<ActionCreators>;
export type KnownTypes = ActionTypes<ActionCreators>['type'];

export const defaultState: ISweepTimeUiState = {
    selectedSweepTime: defaultSweepTime,
    initialValuesLoaded: false,
    sweepTimeDestination: '',
    sweepTimeEnabled: '',
    sweepTimeSun: 'N',
    sweepTimeMon: 'N',
    sweepTimeTue: 'N',
    sweepTimeWed: 'N',
    sweepTimeThu: 'N',
    sweepTimeFri: 'N',
    sweepTimeSat: 'N',
    sweepTimeFrom: '00:00:00',
    sweepTimeThrough: '00:00:00',
    sweepTimeInterval: '0',
    sweepTimeDestinationError: '',
};

/* SCHEDULES */
function getScheduleName(obj: MCST_Event): string {
    let fromTime = '';
    let thruTime = '';
    if (obj['@FromTime'] && obj['@FromTime'].length > 0)
        fromTime = obj['@FromTime'].substring(0, 5);

    if (obj['@ThruTime'] && obj['@ThruTime'].length > 0)
        thruTime = obj['@ThruTime'].substring(0, 5);

    let days = '';
    if (obj['@Sun'] && obj['@Sun'] === "Y")
        days = days + "Su";
    if (obj['@Mon'] && obj['@Mon'] === "Y")
        days = days + "Mo";
    if (obj['@Tue'] && obj['@Tue'] === "Y")
        days = days + "Tu";
    if (obj['@Wed'] && obj['@Wed'] === "Y")
        days = days + "We";
    if (obj['@Thu'] && obj['@Thu'] === "Y")
        days = days + "Th";
    if (obj['@Fri'] && obj['@Fri'] === "Y")
        days = days + "Fr";
    if (obj['@Sat'] && obj['@Sat'] === "Y")
        days = days + "Sa";

    return fromTime + '-' + thruTime + ' ' + days;
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

// It is necessary to use Action and cast it to KnownActions here to avoid an error in configureStore.
export const reducer: Reducer<ISweepTimeUiState, KnownActions> = (state: ISweepTimeUiState | undefined, action: KnownActions) => {

    if (state != undefined) {
        var data: MCST_Event;
        var newIndex: number;
        switch (action.type) {

            case 'SELECT_SWEEP_TIME':
                if (action.data.masterCrud && action.data.uiData.index > 0 && Array.isArray(action.data.masterCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event))
                    data = action.data.masterCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event[action.data.uiData.index - 1];
                else
                    data = emptySchedule;

                return {
                    ...state,
                    selectedSweepTime: action.data.uiData,
                    sweepTimeSun: data["@Sun"],
                    sweepTimeMon: data["@Mon"],
                    sweepTimeTue: data["@Tue"],
                    sweepTimeWed: data["@Wed"],
                    sweepTimeThu: data["@Thu"],
                    sweepTimeFri: data["@Fri"],
                    sweepTimeSat: data["@Sat"],
                    sweepTimeFrom: data["@FromTime"],
                    sweepTimeThrough: data["@ThruTime"],
                    sweepTimeInterval: data["@CheckInterval"],
                }

            case 'ADD_SWEEP_TIME':
            case 'UPDATE_SWEEP_TIME':
                if (action.data.masterCrud) {
                    let event: MCST_Event = action.data.uiData;
                    newIndex = state.selectedSweepTime.index;
                    if (action.type === 'ADD_SWEEP_TIME')
                    {
                        action.data.masterCrud!.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event.push(JSON.parse(JSON.stringify(event)));
                        newIndex = action.data.masterCrud!.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event.length;
                        action.data.delta.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event.push(JSON.parse(JSON.stringify(event)));
                    }
                    else {
                        action.data.masterCrud!.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event[newIndex - 1] = JSON.parse(JSON.stringify(event));
                        var existingDelta = action.data.delta.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event.find(existingContact => existingContact['@ID'] === event["@ID"]);
                        if (existingDelta) {
                            existingDelta = JSON.parse(JSON.stringify(event));
                        } else {
                            action.data.delta.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event.push(JSON.parse(JSON.stringify(event)));
                        }
                    }
                    
                    return {
                        ...state,
                        selectedSweepTime: {
                            index: newIndex,
                            value: event["@ID"],
                            text: getScheduleName(event)
                        },
                    }
                }
                break;
            case 'UPDATE_SWEEP_TIME_DESTINATION':
                if (state.sweepTimeDestination !== action.data.uiData.value) {
                    if (action.data.masterCrud)
                        action.data.masterCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@Destination"] = action.data.uiData.value.trim();
                    return {
                        ...state,
                        sweepTimeDestination: action.data.uiData.value.trim(),
                        sweepTimeDestinationError: '',
                        initialValuesLoaded: true,
                    }
                }
                break;
            case 'UPDATE_SWEEP_TIME_ENABLED':
                if (state.sweepTimeEnabled !== action.data.uiData.value) {
                    if (action.data.masterCrud)
                        action.data.masterCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting["@Enabled"] = action.data.uiData.value;
                    return {
                        ...state,
                        sweepTimeEnabled: action.data.uiData.value,
                        initialValuesLoaded: true,
                    }
                }
                break;
            case 'UPDATE_SWEEP_TIME_FROM':
                    return {
                        ...state,
                        sweepTimeFrom: action.data.uiData.value
                    }
            case 'UPDATE_SWEEP_TIME_THROUGH':
                    return {
                        ...state,
                        sweepTimeThrough: action.data.uiData.value
                    }
            case 'UPDATE_SWEEP_TIME_INTERVAL':
                    return {
                        ...state,
                        sweepTimeInterval: action.data.uiData.value
                    }
            case 'UPDATE_DAYOFWEEK':
                switch (action.data.uiData.index) {
                case '@Sun':
                    return {
                        ...state,
                        sweepTimeSun: (action.data.uiData.value ? "Y" : "N")
                    }
                case '@Mon':
                    return {
                        ...state,
                        sweepTimeMon: (action.data.uiData.value ? "Y" : "N")
                    }
                case '@Tue':
                    return {
                        ...state,
                        sweepTimeTue: (action.data.uiData.value ? "Y" : "N")
                    }
                case '@Wed':
                    return {
                        ...state,
                        sweepTimeWed: (action.data.uiData.value ? "Y" : "N")
                    }
                case '@Thu':
                    return {
                        ...state,
                        sweepTimeThu: (action.data.uiData.value ? "Y" : "N")
                    }
                case '@Fri':
                    return {
                        ...state,
                        sweepTimeFri: (action.data.uiData.value ? "Y" : "N")
                    }
                case '@Sat':
                    return {
                        ...state,
                        sweepTimeSat: (action.data.uiData.value ? "Y" : "N")
                    }
                }
                break;

            case 'REMOVE_SWEEP_TIME':
                if (action.data.masterCrud) {

                    let removeEvent: MCST_Event = action.data.uiData;
                    let deltaEvents = action.data.delta.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events;
                    // remove from delta
                    if (('' + removeEvent['@ID']).indexOf('New[') > -1) {
                        var existingDeltaIndex = deltaEvents.Event.findIndex(existingEvent => existingEvent['@ID'] === removeEvent['@ID']);
                        if (existingDeltaIndex > -1) {
                            deltaEvents.Event.splice(existingDeltaIndex, 1);
                        }
                    } else {
                        var eventToDelete = deltaEvents.Event.find(existingEvent => existingEvent['@ID'] === removeEvent['@ID']);
                        if (eventToDelete) {
                            eventToDelete['@Delete'] = 'true';
                        } else {
                            removeEvent['@Delete'] = 'true';
                            deltaEvents.Event.push(JSON.parse(JSON.stringify(removeEvent)));
                        }
                    }

                    // remove from mastercrud data
                    newIndex = state.selectedSweepTime.index - 1;
                    if (state.selectedSweepTime.index < action.data.masterCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event.length) {
                        newIndex = state.selectedSweepTime.index;
                    }
                    if (newIndex < 0) {
                        newIndex = 0;
                    }

                    action.data.masterCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event.splice(state.selectedSweepTime.index - 1, 1);

                    if (newIndex > 0 && Array.isArray(action.data.masterCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event))
                        data = action.data.masterCrud.AutoPrintMaintenanceInfo.AutoPrintSettings.AutoPrintSetting.Events.Event[newIndex - 1];
                    else
                        data = emptySchedule;

                    return {
                        ...state,
                        selectedSweepTime: {
                            index: newIndex,
                            value: data["@ID"],
                            text: ''
                        },
                        sweepTimeSun: data["@Sun"],
                        sweepTimeMon: data["@Mon"],
                        sweepTimeTue: data["@Tue"],
                        sweepTimeWed: data["@Wed"],
                        sweepTimeThu: data["@Thu"],
                        sweepTimeFri: data["@Fri"],
                        sweepTimeSat: data["@Sat"],
                        sweepTimeFrom: data["@FromTime"],
                        sweepTimeThrough: data["@ThruTime"],
                        sweepTimeInterval: data["@CheckInterval"],
                    }
                }
                break;

            case 'ERROR_SWEEP_TIME_FIELD':
                switch (action.data.uiData.id) {
                    case 'sweepTimeDestinationError':
                        return {
                            ...state,
                            sweepTimeDestinationError: action.data.uiData.value,
                        }
                }
                break;

            case 'ERROR_SWEEP_TIME_REMOVE_SCHEDULE_CONFIRM':
                return {
                    ...state,
                    removeScheduleConfirm: action.data.uiData.value
                }

            default:
                // The following line guarantees that every action in the KnownAction union has been covered by a case above
                //const exhaustiveCheck: never = action;
                return state;
        }
    }

    return state || defaultState;
}
